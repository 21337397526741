import React, { Component } from 'react';
import styled from "styled-components";

const Bound = styled.div`
    img{
        cursor: pointer;
        position: absolute;
        z-index:9;
    }
`

class FingerPrint extends Component {
    showFingerInfoCustomer = () => {
        this.props.showFingerInfoCustomer(this.props.typeFinger)
    }
    render() {
        return (
            <Bound className = 'finger-control'>
                <img style = {{bottom:  this.props.bottom  ? this.props.bottom + 'px' : 'unset', left: this.props.left ? this.props.left + 'px' : 'unset', right: this.props.right ? this.props.right + 'px' : 'unset'}}
                    onClick = {()=>this.showFingerInfoCustomer(this.props.typeFinger)}
                    src = {this.props.img_finger} alt = 'fingers'/>
            </Bound>
        );
    }
}

export default FingerPrint;