import * as types from "../actions/types";

export const lstStatusSocket = {
    CONNECTING: 'CONNECTING',
    CONNECT_FAILED: 'CONNECT_FAILED',
    CONNECT_SUCCESS: 'CONNECT_SUCCESS'
}

export const lstStatusDevice = {
    RUNNING: "RUNNING",
    WAITING_START: "WAITING_START",
}

export const initialState = {
    isLoading: false,
    statusSocket: lstStatusSocket.CONNECTING,
    statusDevice: lstStatusDevice.RUNNING
}

export default function commonReducer(state = initialState, action) {
    switch (action.type) {
    case types.GET_CLIENT_NAME:
        return {
            ...state,
            clientName: action.clientName
        }
    case types.SET_STATUS_DEVICE:
        return {
            ...state,
            statusDevice: action.status
        }
    case types.CONNECTTING_SOCKET:
        return {
            ...state,
            statusSocket: lstStatusSocket.CONNECTING
        }
    case types.FAILED_TO_CONNECT_SOCKET:
        return {
            ...state,
            statusSocket: lstStatusSocket.CONNECT_FAILED
        }
    case types.SUCCESS_TO_CONNECT_SOCKET:
        return {
            ...state,
            statusSocket: lstStatusSocket.CONNECT_SUCCESS
        }
    case types.RUN_LOADING: 
        return {
            ...state,
            isLoading: true
        }
    case types.REMOVE_LOADING: 
        return {
            ...state,
            isLoading: false
        }
    case types.ALERT_LOGOUT:
        return {
            ...state,
            isLogout: true
        }
    default:
        return state
    }
}