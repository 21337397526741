import styled from 'styled-components'

const WrapFingerQuery = styled.div`
    width: 192px;
    min-height: 192px;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 5px;
    background: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img.ic-finger-default {
        margin-top: 25px;
        width: 56px;
        height: 56px;
    }

    .desc {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 110%;
        color: #AAAAAA;
        margin-top: 23px;
    }

    button {
        margin: 24px 0 15px 0;
        padding: 8px 12px;
        background: #005993;
        border-radius: 18px;
        border: none;
        outline: none;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 110%;
        color: #FFFFFF;
        &:hover {
            cursor: pointer;
        }
        &:disabled {
            opacity: 0.4;
            cursor: initial;
        }
    }

    .wrap-loading {

        .desc-loading {
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 110%;
            color: #000000;
        }
    }
`;

export {
    WrapFingerQuery
}