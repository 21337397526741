export const REGISTER = "REGISTER"
export const CONFIRM = "CONFIRM"
export const REMOVE = "REMOVE"
export const AUTH_TRANS = "usedToAuthenticateTransaction"
export const VALIDATE_DATA = "validatedData"
export const LIST_ID = "listId"
export const ISHANG = "isHang"

///// FACEID
export const CONFIRM_FACEID_SUCCESS = 0;
export const CONFIRM_FACEID_FAIL = 101;

export const CONFIRM_FINGER_SUCCESS = 0;
export const CONFIRM_FINGER_FAIL = 100;