import React, { Component } from "react";
import styled from "styled-components";
import { connect } from 'react-redux';

import * as typesAction from "../../actions/types";
//Image
import close from "../../images/ic_close_gray.svg";
import ic_search from '../../images/Icons_Search.svg';
import imgUserGrayBg from "../../images/img_user_gray_bg.svg";
import iconNotificationClose from "../../images/ic_counter_notification_close.svg";

// helper
import { buildErrorContent } from "../../helper/tool";
import isDisableEventWithCORPCust from "../../helper/isDisableEventWithCORPCust";
import autoOpenPopupWhenGetTicket from "../../helper/autoOpenPopupWhenGetTicket";

//component
import ButtonConfirmControl from "../Control/ButtonConfirmControl";
import LoadingControl from "../Control/LoadingControl";
import ErrorGetTicket76 from "../../helper/ErrorGetTicket76";

// action
import {
    // requestEnroll, 
    getInfoCustomer,
    createNewTicket,
    addCustomerInfos,
    addBiometricExistFromCustomerInfos,
    addInprogressBioExistFromCustomerInfo,
    clearCustomerInfosInStore
} from "../../actions/counterAction";
import {
    openPopup,
    closePopup
} from "../../actions/popupBioAction";
import {
    completeBio,
    apiGetBioFaceImage,
    getCurrentBioEnroll
} from "../../actions/biometricAction";
import {
    completeBioCollector,
    getCustomerInfos,
    apiGetBioFaceImageCollector,
    getCurrentBioEnrollCollector
} from "../../actions/biometricWithInBioCollectorAction";
import { clearError } from "../../actions/commonAction";

// data
import { INBIOCOLLECTOR, TELLER } from "../../data/userRole";
import { popupBioTypes } from "../../data/PopupTypes";
import typesKeyDetailPopup from "../../data/typesKeyDetailPopup";


const Bound = styled.div`
  position: relative;
  top: 40%;
  transform: translate(0, -50%);
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  width: 1100px;
  height: 516px;
  border-radius: 10px;
  background-color: #fafafa;
  font-family: "SVN-Gilroy";
  color: #000000;
  overflow: hidden;
  z-index: 9;
  box-shadow: 0 0 20px #7d7d7d;
  @keyframes fadeInPopupUse {
    0%   { top:40%; }
    100% { top:50%; }
  }
  .popup-title {
    background-color: #ffffff;
    z-index: 99;
    &__title {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      padding: 0 20px;
      position: relative;
      .p-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.08;
      }
      img {
        position: absolute;
        right: 10px;
        cursor: pointer;
      }
    }
    .search-title {
      color: #005993;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #005993;
      margin: 9px 0 12px 40px;
    }
    .popup-step {
      width: 100%;
      position: absolute;
      top: 55px;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 14px;
      color: #222222;
      background-color: #ffffff;
      .step-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 28px;
          padding: 0 20px;
        .tab_content{
          display: flex;
          align-items: center;
          flex-direction:row;
          .step {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 140px;
            line-height: 1.2;
            cursor: pointer;
          }
        }
          .cif {
            /* position: absolute; */
            top: 58px;
            right: 32px;
            font-family: SVN-Gilroy;
            font-size: 14px;
            font-weight: 500;
            .type {
              color: #005993;
            }
            .id {
              color: #222222;
            }
          }
      }
      .mark-step {
        padding: 0 20px;
        display: flex;
        height: 3px;
        flex-direction: row;
        background-color: #efefef;
        .step {
          display: flex;
          width: 140px;
        }
      }
    }
  }
  .popup-container {
    display: flex;
    flex: 1;
    &__search-cifs {
      width:100%;
      background-color:#fafafa;
      font-family: SVN-Gilroy;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      padding:16px 40px 0 40px;   
      .block_radio{
        display: flex;
        justify-content:start;
        align-items: center;
        margin: 0 0 17px 0;
        .radio-field {
          display: flex;
          align-items: center;
          margin-right: 7px;
          input[type="radio"]{
            appearance: none;
            outline: none;
            width: 16px;
            height: 16px;
            position:relative;
            background: #fff;
            border-radius: 50%;
            border: 0.75px solid #D6D6D6;
            margin-right: 5px;

            ::before{
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #fff;
              display: none;
            }

            &:checked::before {
              background: #D71249;
              display: block;
            }
          }
        }
      } 
      .input_check{
        width: 356px;
        margin: 10px 0 0 0;
        position: relative;
        display:flex;
        align-items: center;
        input{
          width:100%;
          padding: 11px 50px 11px 12px;
          height: 18px;
          border-radius: 4px;
          border: solid 0.5px #c8c8c8;
          background-color: #ffffff;
          outline:none;
          &::placeholder{
              opacity: 0.25;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.2;
              color: #222222;
          }
        }
        .btn_check{
          width: 44px;
          height: 40px;
          background-color: #005993;
          display:flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right:0;
          cursor: pointer;
          border:none;
          outline:none;
          border-radius: 0 4px 4px 0;
        }
      }
      .error{
        margin: 12px 0 15px 0;
        height: 17px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        color: #f31919;
      }
      .search{
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        color: #979797;
        margin-bottom: 8px;
      }
      .customer-info {
        display:flex;
        /*align-items: center;*/
        justify-content: space-between;
        padding-right: 38px;
        /*height:100px;*/
        border-radius: 4px;
        background: #fff;
        padding-bottom: 9px;
        & > * {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        &__col {
          display: flex;
          flex-direction: column;
          height: 100%;
          &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .item {
              display: flex;
              margin-top: 14px;
              .title, .value {
                font-family: "SVN-Gilroy";
                font-size: 14px;
                line-height: 125%;
              }

              .title {
                color: #005993;
                margin-right: 5px;
              }
            }
            .name {
              text-transform: uppercase;
              font-family: "SVN-Gilroy";
              font-weight: bold;
              font-size: 20px;
              line-height: 22px;
              margin-right: 10px;
              max-width: 12rem;
            }
            .type-cus {
              background: #FFB400;
              border-radius: 10px;
              font-family: "SVN-Gilroy";
              font-size: 12px;
              line-height: 130%;
              padding: 3px 12px;
              max-width: 12rem;
            }
            .gender {
              margin-left: 21px;
            }
          }
          button {
            /*width: unset;*/
            padding: 0 10px;
            margin-bottom: 8px;
          }
        }
        .left {
          align-items: flex-start;
        }

        .center {
          align-items: center;
        }

        .fit-content-item {
          width: fit-content;
          /*padding-right: 14px;*/
          padding-left: 14px;
          height: auto;
        }

        .line-clamp {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }

        .wrap-img {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          overflow: hidden;
          flex-shrink: 0;
          margin: 14px 18px auto 18px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }   
    }
  }
  .popup-action {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-end;
    align-items: center;
    background-color: #ffffff;
    padding: 20px 20px 24px 20px;
    .block_PrintAndHistoryBio{
      display: flex;
    }
    .p-registration {
      font-size: 14px;
      font-weight: 500;
      color: #f31919;
      border-bottom: solid 0.5px #f31919;
      cursor: pointer;
    }
    img {
      margin-right: 16px;
    }

    .btn-group {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
    }
    .title_show_loading {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #979797;
      display: flex;
      align-items: center;
      margin: 0 40px 0 0;
      flex: 2;
      justify-content: flex-end;
    }
  }
  .loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 30px 40px;
    p {
      font-family: "SVN-Gilroy";
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      margin-left: 5px;
      line-height: 18px;
    }
  }
`;


class PopupSearchCifs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchResult: null,
            error: '',
            cifTmp: "",
            typeSearch: "1"
        };
    }

    renderLoading = isLoading => {
        this.setState({ isLoading });
    };

    onSubmit = async (e) => {
        const { counterReducer } = this.props;
        e.preventDefault();
        // e.stopPropogation()
        if (!this.state.cifTmp.trim()) {
            this.setState({ searchResult: null, error: 'Vui lòng nhập giá trị' })
            return;
        }
        await this.renderLoading(true)
        const { typeSearch } = this.state;
        switch (true) {
        case counterReducer.dataLogin.Roles.includes(TELLER):
        // debugger;
            await this.props.getInfoCustomer(
                this.props.counterReducer.dataConnectDesktop.CounterID,
                this.state.cifTmp.trim(),
                Number(typeSearch)
            );
            // await this.props.addBiometricExistFromCustomerInfos()
            break;
        case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR):
            this.props.getCustomerInfosByCollector(
                counterReducer.dataConnectDesktop.CounterID,
                this.state.cifTmp.trim(),
                Number(typeSearch)
            )
            break;

        default:
            break;
        }
        await this.renderLoading(false)
    }

    renderListUserAdd = () => {
    // let { searchResult } = this.state;
        const isTeller = this.props.counterReducer.dataLogin.Roles.includes(TELLER);
        const linkImg = isTeller ? apiGetBioFaceImage : apiGetBioFaceImageCollector;
        let searchResult = this.state.searchResult[0]
        return (
        // searchResult
        //(searchResult.CustomerNumber === this.state.cifTmp) &&
            <div>
                <p className='search'>Kết quả tìm kiếm:</p>
                <div className="customer-info">
                    <div className="customer-info__col wrap-img">
                        <img
                            src={
                                searchResult.FaceID ?
                                    linkImg(searchResult.FaceID)
                                    :
                                    imgUserGrayBg
                            }
                            alt="user"
                        />
                    </div>
                    <div className="customer-info__col left ">
                        <div className="customer-info__col__row">
                            <div className="item name line-clamp">{
                                searchResult.FullNameVi === "" ?
                                    searchResult.Name
                                    :
                                    searchResult.FullNameVi
                            }</div>
                            <div className="item type-cus line-clamp">{searchResult.SegmentDesc}</div>
                        </div>
                        <div className="customer-info__col__row">
                            <div className="item">
                                <div className="title">GTTT:</div>
                                <div className="value">{searchResult.IDNumber || searchResult.IdNumber}</div>
                            </div>
                            <div className="item gender">
                                <div className="title">Giới tính:</div>
                                <div className="value">{searchResult.Gender}</div>
                            </div>
                        </div>
                        <div className="customer-info__col__row">
                            <div className="item">
                                <div className="title">Địa chỉ:</div>
                                <div className="value">{searchResult.Address}</div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-info__col center fit-content-item">
                        <div className="customer-info__col__row">
                            <div className="item">
                                <div className="title">CIF:</div>
                                <div className="value">{searchResult.CustomerNumber}</div>
                            </div>
                        </div>
                        <ButtonConfirmControl
                            onSubmit={() => { this.showInforTicket() }}
                            bg_color="#005993"
                            titleConfirm="Xác nhận"
                            width="100px"
                        />
                    </div>
                </div>
            </div>
        )
    }

    showInforTicket = async () => {

        // this.props.addCustomerInfos(this.props.counterReducer.customerInfo.Cifs[0], this.props.dataGetTicket.TicketID);
        if (this.props.ticketId) {
            if (this.props.counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR)) {
                const { listData } = this.props
                const { CustomerNumber, Name } = this.props.counterReducer.customerInfo.Cifs[0]

                const someEqualCifNum = listData.find(data =>
                    data.CustomerInfos.CustomerNumber === CustomerNumber
                )
                if (someEqualCifNum) {
                    this.props.openPopup(popupBioTypes.PopupAccect, 2, {
                        image_noti: iconNotificationClose,
                        title: <>
                            <div>Khách hàng <span className="specific">{CustomerNumber}</span> - <span className="specific">{Name}</span></div>
                            <div>đang trong hàng chờ. Vui lòng gọi từ hàng chờ.</div>
                        </>,
                        widthPopup: "353px",
                        titleConfirm: "Xác nhận",
                    })
                    return
                }

                await this.props.addCustomerInfos(
                    this.props.counterReducer.customerInfo.Cifs[0],
                    this.props.ticketId,
                    false,
                    [INBIOCOLLECTOR]
                )
                await this.props.addBiometricExistFromCustomerInfos(
                    this.props.counterReducer.customerInfo.BioInfos,
                    this.props.ticketId,
                );
                this.props.closePopup(1)
                return;
            }

            const ticketCurrent = this.props.counterReducer.listCurrentTicket.find(ticket => ticket.TicketID === this.props.ticketId);
            if (!ticketCurrent) return;
            const dataCurrentTicket = {
                numberTicketCurrent: ticketCurrent.Number,
                customerNumberTicketCurrent: ticketCurrent.CustomerInfos && ticketCurrent.CustomerInfos.CustomerNumber,
                nameOfCustomerInTicketCurrent: ticketCurrent.CustomerInfos && ticketCurrent.CustomerInfos.Name
            }
            const DataContent = ({ props }) =>
                ticketCurrent.CustomerInfos ?
                    (
                        <React.Fragment>
              Số vé <span style={{ color: "#005993" }}>{dataCurrentTicket.numberTicketCurrent}</span> của khách hàng <span style={{ color: "#005993" }}>{dataCurrentTicket.customerNumberTicketCurrent + " - " + dataCurrentTicket.nameOfCustomerInTicketCurrent}</span> sẽ được kết thúc giao dịch. Đồng thời khách hàng <span style={{ color: "#005993" }}>{this.state.searchResult[0].CustomerNumber + " - " + this.state.searchResult[0].Name}</span> sẽ được gắn với số vé mới là <span style={{ color: "#005993" }}>{String(dataCurrentTicket.numberTicketCurrent) + 1}</span>
                        </React.Fragment>
                    )
                    :
                    (
                        <React.Fragment>
              Bạn có muốn đưa thông tin khách hàng <span style={{ color: "#005993" }}>{this.state.searchResult[0].CustomerNumber + " - " + this.state.searchResult[0].Name}</span> áp dụng cho số vé <span style={{ color: "#005993" }}>{dataCurrentTicket.numberTicketCurrent}</span> không?
                        </React.Fragment>
                    )
            const POPUP_LEVEL_2 = 2;
            const dataTransferToPopup = {
                key: typesKeyDetailPopup.applyCifsForTicketAlreadyHaveCifs,
                content: <DataContent />,
                width: "274px",
                ticketId: this.props.ticketId,
                customerInfo: this.state.searchResult[0],
                bioInfos: this.props.counterReducer.customerInfo.BioInfos
            }
            this.props.openPopup(
                popupBioTypes.PopupConfirm,
                POPUP_LEVEL_2,
                dataTransferToPopup
            )
            return;
        }

        await this.props.createNewTicket(
            this.props.counterReducer.dataConnectDesktop.CounterID,
            this.state.searchResult[0].CustomerNumber
        )

        const errorCreateNewTicket = this.props.counterReducer.err[typesAction.CREATE_NEW_TICKET_ERR];
        if (errorCreateNewTicket) {
            switch (true) {
            case !!errorCreateNewTicket && errorCreateNewTicket.Code === 76: {
                const OPEN_POPUP_LEVEL_1 = 1;
                const { CustomerNumber, CustomerName, TicketNumber, TellerName, OfficeName } = errorCreateNewTicket
                // data follow props in PopupAccept component
                const dataTranferFollowPopup = {
                    image_noti: iconNotificationClose,
                    title: <ErrorGetTicket76
                        CustomerNumber={CustomerNumber}
                        CustomerName={CustomerName}
                        TicketNumber={TicketNumber}
                        TellerName={TellerName}
                        OfficeName={OfficeName}
                    />,
                    widthPopup: "363px",
                    titleConfirm: "Xác nhận"
                }

                this.props.clearError(typesAction.CREATE_NEW_TICKET_ERR)

                this.props.openPopup(
                    popupBioTypes.PopupAccect,
                    OPEN_POPUP_LEVEL_1,
                    dataTranferFollowPopup
                )
                break;
            }
            default:
                break;
            }
            return;
        }

        if (this.props.counterReducer.customerInfo.InprogressBio) {
            await this.props.addInprogressBioExistFromCustomerInfo(
                this.props.counterReducer.customerInfo.InprogressBio,
                this.props.counterReducer.dataGetTicket.TicketData.TicketID
            );
            // const {InprogressBio: inprogressBio} = this.props.counterReducer.customerInfo;
        } else
            await this.props.addBiometricExistFromCustomerInfos(
                this.props.counterReducer.customerInfo.BioInfos,
                this.props.counterReducer.dataGetTicket.TicketData.TicketID
            );
        await this.props.closePopup(1)
        if (!isDisableEventWithCORPCust(
            this.props.counterReducer.dataGetTicket.TicketData.TicketID,
            this.props.counterReducer)) {
            autoOpenPopupWhenGetTicket(
                this,
                this.props.counterReducer.dataGetTicket.TicketData.TicketID,
            );
        }

    }

    onChange = (e) => {
        this.setState({
            error: '',
            [e.target.name]: e.target.value.trim()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.counterReducer.err && Object.keys(this.props.counterReducer.err).includes(typesAction.GET_CUSTOMER_INFORMATION_ERR)) {
            this.setState(
                {
                    searchResult: null,
                    error: buildErrorContent(typesAction.GET_CUSTOMER_INFORMATION_ERR, this.props.counterReducer.err[typesAction.GET_CUSTOMER_INFORMATION_ERR])
                },
                () =>
                    this.props.clearError(
                        typesAction.GET_CUSTOMER_INFORMATION_ERR
                    )
            )
        }

        if (this.props.counterReducer.customerInfo !== prevProps.counterReducer.customerInfo) {
            this.setState({
                searchResult: this.props.counterReducer.customerInfo.Cifs,
                error: ""
            })
        }
    }


    render() {
    // console.log(this.props.flowUpdate);

        // const { counterReducer } = this.props;

        return (
            <Bound>
                <div className="popup-title">
                    <div className="popup-title__title">
                        <p className="p-title">Thông tin khách hàng</p>
                        <img
                            src={close}
                            alt="close"
                            onClick={() => {
                                if (this.props.cancelAction) {
                                    this.props.cancelAction()
                                } else if (this.props.onClosePopup)
                                    this.props.onClosePopup()
                            }}
                        />
                    </div>
                    <p className="search-title">
            Tìm kiếm thông tin khách hàng
                    </p>
                </div>
                <div className="popup-container">
                    <div className="popup-container__search-cifs">
                        <div className="block_radio">
                            <label className="radio-field">
                                <input
                                    id="type-search"
                                    type="radio"
                                    name="type-search"
                                    value="1"
                                    checked={this.state.typeSearch === '1'}
                                    onChange={() =>
                                        this.setState({
                                            typeSearch: '1',
                                            error: '',
                                        })
                                    }
                                />
                                <div className="text_label">GTTT</div>
                            </label>
                            <label className="radio-field">
                                <input
                                    id="type-search"
                                    type="radio"
                                    name="type-search"
                                    value="0"
                                    checked={this.state.typeSearch === '0'}
                                    onChange={() =>
                                        this.setState({
                                            typeSearch: '0',
                                            error: '',
                                        })
                                    }
                                />
                                <div className="text_label">Thông tin CIF</div>
                            </label>
                        </div>
                        <div>
                            <form
                                className="input_check"
                                onSubmit={this.onSubmit}
                            >
                                <input
                                    onChange={this.onChange}
                                    type="text"
                                    name="cifTmp"
                                    onKeyUp={(e) => {
                                        // debugger;
                                        if (e.charCode === 13) {
                                            e.preventDefault()
                                            this.onSubmit(e)
                                        }
                                        return false
                                    }}
                                    placeholder="Nhập thông tin tìm kiếm"
                                />
                                <button className="btn_check" type="submit">
                                    <img src={ic_search} alt="ic_search" />
                                </button>
                            </form>
                            <div className="error">{this.state.error}</div>
                            {this.state.searchResult &&
                this.renderListUserAdd()}
                        </div>
                    </div>
                </div>
                {this.state.isLoading ? (
                    <div className="loading">
                        <LoadingControl loadingPage={false} size="24px" />
                        <p>Đang tải...</p>
                    </div>
                ) : null}
            </Bound>
        )
    }

    componentDidMount() {
        this.props.clearCustomerInfosInStore()
    }


    componentWillUnmount() {
        this.props.clearCustomerInfosInStore()
    }

}


const mapStateToProps = state => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
    listData: state.inBioCollector.listDataSentSupervisor.listData
})

const mapDispatchToProps = dispatch => ({
    // requestEnroll: (ticketId, cusInfo) => dispatch(requestEnroll(ticketId, cusInfo)),
    openPopup: (type, levelPopup, data) => dispatch(openPopup(type, levelPopup, data)),
    closePopup: (level) => dispatch(closePopup(level)),
    completeBio: (enrollId, ticketId, forceComplete = true) => dispatch(completeBio(enrollId, ticketId, forceComplete)),
    getInfoCustomer: (counterId, customerNumber, typeSearch = 0) => dispatch(getInfoCustomer(counterId, customerNumber, typeSearch)),
    getCustomerInfosByCollector: (counterId, cifsNum, typeSearch = 0) => dispatch(getCustomerInfos(counterId, cifsNum, typeSearch)),
    getCurrentBioEnroll: (counterId, ticketId, customerNumber = undefined) =>
        dispatch(getCurrentBioEnroll(counterId, ticketId, customerNumber)),
    clearError: (typeError) => dispatch(clearError(typeError)),
    createNewTicket: (counterId, customerNumber) => dispatch(createNewTicket(counterId, customerNumber)),
    addBiometricExistFromCustomerInfos: (bioInfos, ticketId) => dispatch(addBiometricExistFromCustomerInfos(bioInfos, ticketId)),
    addInprogressBioExistFromCustomerInfo: (inprogressBio, ticketId) =>
        dispatch(addInprogressBioExistFromCustomerInfo(inprogressBio, ticketId)),
    clearCustomerInfosInStore: () =>
        dispatch(clearCustomerInfosInStore()),
    ///// BioCollector
    completeBioCollector: (enrollId, forceComplete = true) =>
        dispatch(completeBioCollector(enrollId, forceComplete)),
    addCustomerInfos: (customerInfos, ticketID, isChangeTicket = false, roles = ["TELLER"]) =>
        dispatch(addCustomerInfos(customerInfos, ticketID, isChangeTicket, roles)),
    getCurrentBioEnrollCollector: (counterId) =>
        dispatch(getCurrentBioEnrollCollector(counterId)),

})


export default connect(mapStateToProps, mapDispatchToProps)(PopupSearchCifs)