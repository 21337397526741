import React, { useState } from 'react'
import Axios from 'axios'
import { useSelector } from 'react-redux'

// from ADMIN last commit: d0e503d1

// styles
import { WrapQueries } from './index.styles'

// component
import Modal from '../../Control/Modal'
import MainQueries from './MainQueries'
import GTTTQueries from './GTTTQueries'
import QueryByBioImage from './QueryByBioImage'
import DuplicateBio from './DuplicateBio'

// action
import { apiGetCifs as apiGetCifsCounter, apiGetDuplBioDetails } from '../../../actions/counterAction'
import { apiGetCifs as apiGetCifsCollector } from '../../../actions/biometricWithInBioCollectorAction'

// helper
import useIsRole from '../../../helper/hooks/useIsRole'

// constant
import { TELLER } from '../../../data/userRole'

const QUERIES_MAIN_STEP = 'QUERIES_MAIN_STEP'
const GTTT_STEP = 'GTTT_STEP'
const DUP_BIO_STEP = 'DUP_BIO_STEP'
const BIO_IMG_STEP = 'BIO_IMG_STEP'

const ERR_NOT_FOUND_USER = 'Không tìm thấy thông tin khách hàng'

const Queries = ({ closePopup }) => {
    const [step, setStep] = useState(QUERIES_MAIN_STEP);
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [defaultDataQueryUser, setDefaultDataQueryUser] = useState(null);
    const [defaultQueryCode, setDefaultQueryCode] = useState("");
    const [defaultActiveQueryMethod, setDefaultActiveQueryMethod] = useState(null);
    const isTeller = useIsRole(TELLER)
    const apiGetCifs = isTeller ? apiGetCifsCounter : apiGetCifsCollector

    const { dataConnectDesktop } = useSelector(state => ({
        dataConnectDesktop: state.counterReducer.dataConnectDesktop
    }))

    const _handleCloseMainQueries = () => {
        closePopup()
    }

    const _handleQueryByBioImage = () => {
        setStep(BIO_IMG_STEP)
    }

    const _handleQueryUserByGTTTorCif = async (custKey, queryType) => {
        setError('')
        setLoading(true)
        const dataRequest = JSON.stringify({
            CounterID: dataConnectDesktop.CounterID,
            CifsNum: custKey,
            Type: queryType
        })

        try {
            const res = await Axios.post(apiGetCifs, dataRequest)
            const { data } = res;
            const isSuccess = data.Code === 0 && data.Cifs;
            if (isSuccess) {
                console.log('data:', data)
                setData(data)
                setStep(GTTT_STEP)
            }
            else {
                console.log('error:', data)
                if (data.Code === 0) {
                    setError(ERR_NOT_FOUND_USER)
                }
            }
        }
        catch (err) {
            console.dir(err)
        }
        setLoading(false)
    }

    const _handleQueryDuplicateBio = async (queryCode) => {
        setError('')
        setLoading(true)
        const dataQuery = JSON.stringify({
            CodeName: queryCode
        })
        try {
            const res = await Axios.post(apiGetDuplBioDetails, dataQuery)
            const { data } = res;
            const isSuccess = data.Code === 0
            if (isSuccess) {
                setData(data.Details)
                setStep(DUP_BIO_STEP)
            }
            else {
                console.log('error:', data)
            }

        }
        catch (err) {
            console.dir(err)
            const code = err?.response?.data?.Code
            if (code === 3) {
                setError(ERR_NOT_FOUND_USER)
            }
        }
        setLoading(false)
    }

    const _handleCloseStep = () => {
        setStep(QUERIES_MAIN_STEP)
        setData(null)
    }

    return (
        <WrapQueries>
            {
                step === QUERIES_MAIN_STEP &&
                <MainQueries
                    handleClose={_handleCloseMainQueries}
                    handleQueryUser={_handleQueryUserByGTTTorCif}
                    handleQueryDuplicateBio={_handleQueryDuplicateBio}
                    handleQueryByBioImage={_handleQueryByBioImage}
                    error={error}
                    loading={loading}
                    permissionQuery={{
                        queryByGTTT: true,
                        queryByCif: true,
                        queryByCodeDupl: true,
                        queryByBioImage: true
                    }}
                    defaultDataQueryUser={defaultDataQueryUser}
                    setDefaultDataQueryUser={setDefaultDataQueryUser}
                    defaultQueryCode={defaultQueryCode}
                    setDefaultQueryCode={setDefaultQueryCode}
                    defaultActiveQueryMethod={defaultActiveQueryMethod}
                    setDefaultActiveQueryMethod={setDefaultActiveQueryMethod}
                />
            }
            {
                step === GTTT_STEP &&
                <GTTTQueries
                    data={data}
                    handleClose={_handleCloseStep}
                />
            }
            {
                step === DUP_BIO_STEP &&
                <DuplicateBio
                    data={data}
                    handleClose={_handleCloseStep}
                />
            }
            {
                step === BIO_IMG_STEP &&
                <QueryByBioImage
                    handleClose={_handleCloseStep}
                />
            }
        </WrapQueries>
    )
}

export default Queries
