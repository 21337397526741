import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'

// styles
import { WrapPopupStyles } from '../../WrapPopupStyles'
import { WrapQueryFingerResults } from './index.styles'

// image
import icCloseGray from '../../../../../images/ic_close_gray.svg'

// helper
import getFromChannel from '../../../../../helper/getFromChannel'
import useIsRole from '../../../../../helper/hooks/useIsRole'
import getChannelName from '../../../../../helper/getChannelName'

// constant
import { TELLER } from '../../../../../data/userRole'
import { popupBioTypes } from '../../../../../data/PopupTypes'
import { FACE, nameFingerPrint } from '../../../../../data/bioTypes'
// api
import { apiGetBioFaceImage, apiGetBioFingerImage } from '../../../../../actions/biometricAction'
import { apiGetBioFaceImageCollector, apiGetBioFingerImageCollector } from '../../../../../actions/biometricWithInBioCollectorAction'

// action
import { openPopup } from '../../../../../actions/popupBioAction'
import { clearDataUsersFromTablet } from '../../../../../actions/queriesAction'

// hooks
import useMoveElement from '../../../../../helper/hooks/useMoveElement'

const MOCK_IMG = 'https://picsum.photos/200/300'

const CustomerTypes = {
    EXCUST: 'Thông thường',
    INCUST: 'CBNH',
}

const QueryFingerResults = ({ handleClose, dataUsers }) => {
    const queryResultsRef = useRef(null)
    const dispatch = useDispatch()

    useMoveElement(queryResultsRef, ".wrap-title")

    const isTeller = useIsRole(TELLER)
    const apiGetFace = isTeller ? apiGetBioFaceImage : apiGetBioFaceImageCollector
    const apiGetFinger = isTeller ? apiGetBioFingerImage : apiGetBioFingerImageCollector

    const _handleShowDetailsUser = (e, cifInfos) => {
        e.preventDefault()
        if (!cifInfos) return;
        dispatch(openPopup(popupBioTypes.GTTTQueries, 3, cifInfos))
    }

    useEffect(() => {
        return () => {
            dispatch(clearDataUsersFromTablet())
        }
    }, []);

    return (
        <WrapPopupStyles>
            <WrapQueryFingerResults ref={queryResultsRef}>
                <div className="wrap-title">
                    <div>
                        <div className="title">Kết quả vấn tin hình ảnh vân tay</div>
                        <img className="ic-close" src={icCloseGray} alt="close" onClick={handleClose} />
                    </div>
                </div>
                <div className="wrap-list-customer">
                    {
                        dataUsers.map((user) => {
                            const { BioType, CustomerNumber, Fingers, cifInfos, History } = user
                            const {
                                BioInfos: { BioDatas, CustomerType } = { BioDatas: [] },
                                Cifs: [cif] = [{}]
                            } = cifInfos || {}
                            const {
                                FaceID,
                                Name,
                                FullNameVi,
                                Gender,
                                SegmentDesc,
                                Email,
                                MobileNumber,
                                IdNumber,
                                IdIssueDate,
                                IdIssuePlace,
                                Address
                            } = cif
                            const finger = BioDatas.find(bio => bio.BioType === BioType)
                            const face = BioDatas.find(bio => bio.BioType === FACE)
                            // const { FromChannel } = finger || {}
                            // const [fingerIdImg] = Fingers
                            const history = (() => {
                                if(!History) return {}
                                const {
                                    BranchCode,
                                    OfficeName,
                                    TellerInfos,
                                    RequestAt,
                                    SupervisorInfos,
                                    AcceptAt
                                } = History
                                return {
                                    office: `${BranchCode} - ${OfficeName}`,
                                    teller: TellerInfos?.Name,
                                    timeRequest: moment(RequestAt).format('DD/MM/YYYY HH:mm:ss'),
                                    supervisor: SupervisorInfos?.Name,
                                    timeAccept: moment(AcceptAt).format('DD/MM/YYYY HH:mm:ss')
                                }
                            })()
                            const {
                                office,
                                teller,
                                timeRequest,
                                supervisor,
                                timeAccept
                            } = history
                            return (
                                <div className="wrap-customer" key={CustomerNumber}>
                                    {/* <div className="side left-side">
                                        <div className="field"><span>Số CIF: </span>{CustomerNumber}</div>
                                        <div className="wrap-bio-img face">
                                            <img src={apiGetFace(FaceID)} alt="bio-face" />
                                        </div>
                                        <div className="wrap-bio-img finger">
                                            <img src={apiGetFinger(fingerIdImg)} alt="bio-finger" />
                                        </div>
                                        <div className="finger-name">{nameFingerPrint[BioType]?.name}</div>
                                        <div className="field chanel"><span>Kênh thu thập: </span>{getFromChannel(FromChannel)}</div>
                                    </div>
                                    <div className="side right-side">
                                        <div className="cust-name">{Name}</div>
                                        <div className="field"><span>Tên có dấu: </span>{FullNameVi}</div>
                                        <div className="field"><span>Giới tính: </span>{Gender}</div>
                                        <div className="field"><span>Loại khách hàng: </span>{CustomerTypes[CustomerType]}</div>
                                        <div className="field"><span>Hạng khách hàng: </span>{SegmentDesc}</div>
                                        <div className="field"><span>Email: </span>{Email}</div>
                                        <div className="field"><span>Số điện thoại: </span>{MobileNumber}</div>
                                        <div className="field"><span>GTTT: </span>{IdNumber}</div>
                                        <div className="field"><span>Ngày cấp: </span>{IdIssueDate}</div>
                                        <div className="field"><span>Nơi cấp: </span>{IdIssuePlace}</div>
                                        <div className="field"><span>Địa chỉ: </span>{Address}</div>
                                    </div> */}
                                    <div className="side">
                                        <div className="field"><span>Số CIF: </span>{CustomerNumber}</div>
                                        <div className="wrap-bio-img face-img">
                                            <img src={apiGetFace(FaceID)} alt="face-bio" />
                                        </div>
                                        <div className="field chanel"><span>Kênh thu thập: </span>{getChannelName(getFromChannel(face?.FromChannel))}</div>
                                        <div className="wrap-bio-img finger-img">
                                            {
                                                Fingers.map(fingerId => (
                                                    <img key={fingerId} src={apiGetFinger(fingerId)} alt="finger-bio" />
                                                ))
                                            }
                                        </div>
                                        <div className="finger-name">{nameFingerPrint[BioType]?.name}</div>
                                        <div className="field chanel"><span>Kênh thu thập: </span>{getChannelName(getFromChannel(finger?.FromChannel))}</div>
                                    </div>
                                    <div className="side">
                                        <div className="cust-name">{Name}</div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>Tên có dấu: </span>{FullNameVi}</div>
                                            <div className="field"><span>Loại khách hàng: </span>{CustomerTypes[CustomerType]}</div>
                                        </div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>Hạng khách hàng: </span>{SegmentDesc}</div>
                                            <div className="field"><span>Giới tính: </span>{Gender}</div>
                                        </div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>GTTT: </span>{IdNumber}</div>
                                            <div className="field"><span>Số điện thoại: </span>{MobileNumber}</div>
                                        </div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>Ngày cấp: </span>{IdIssueDate}</div>
                                            <div className="field"><span>Email: </span>{Email}</div>
                                        </div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>Nơi cấp: </span>{IdIssuePlace}</div>
                                            <div className="field"><span>Địa chỉ: </span>{Address}</div>
                                        </div>

                                        <div className="wrap-approved-infos">
                                            <div className="cust-name">Thông tin tác nghiệp STH vân tay:</div>
                                            <div className="field"><span>CN/PGD: </span>{office}</div>
                                            <div className="field"><span>GDV/Điện toán: </span>{teller}</div>
                                            <div className="field"><span>Thời gian tác nghiệp: </span>{timeRequest}</div>
                                            <div className="field"><span>KSV/BGĐ: </span>{supervisor}</div>
                                            <div className="field"><span>Thời gian phê duyệt: </span>{timeAccept}</div>
                                        </div>
                                        <div className="wrap-btn-show-details">
                                            <a onClick={(e) => _handleShowDetailsUser(e, cifInfos)}>Xem chi tiết</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="wrap-footer">
                    <button onClick={handleClose}>Xác nhận</button>
                </div>
            </WrapQueryFingerResults>
        </WrapPopupStyles>
    )
}

export default QueryFingerResults
