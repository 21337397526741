///////////// Type showPopup Bio ////////////////////
export const popupBioTypes = {
    RegisterFingerIDControl: 0,
    ShowFaceImage: 1,
    HistoryCustomer: 2,
    PopupConfirm: 3, ////////// show when click to list button animation
    PoupNotRegister: 4, //// show when chose "không đăng kí BIO"
    PopupHistoryFinger: 5, ///// show when click to HistoryCustomer (popup third)
    CheckCustomerActions: 6,
    PopupUserManage: 7,
    PopupAccect: 8,
    PopupCounterFail: 9, ///// Use for click to button "Ngung GD",
    PopupPassTicket: 10,
    PopupCouterLogin: 11,
    PopupCouterSuccess: 12,
    PopupShowNotice: 13,
    PopupPrintText: 14,
    PopupBioFingerTransaction: 17,
    PopupSearchCifs: 18,
    PopupFillReasonCusNotAuth: 19,
    PopupShowReasonRejectBio: 20,
    PopupShowErrorExistedDataBiometric: 21,
    PopupWaitingEvalueateQualityService: 22,
    PopupFaceUpdateFlow: 23,
    PopupNotify: 24,
    PopupMixResultConfirmFace: 25,
    PopupNotRegisterFingerButFace: "PopupNotRegisterFingerButFace",
    PopupRefuseRegFinger: "PopupRefuseRegFinger",
    PopupControlTablet: "PopupControlTablet",
    PopupFillClientName: "PopupFillClientName",
    confirmReLoadPage: 'confirmReLoadPage',
    PopupQueries: 'PopupQueries',
    PopupShowFaceQueries: 'PopupShowFaceQueries',
    PopupShowFingerQueries: 'PopupShowFingerQueries',
    PopupShowUserFoundByBioImage: 'PopupShowUserFoundByBioImage',
    PopupDetailBiometric: 'PopupDetailBiometric',
    GTTTQueries: 'GTTTQueries',
    PopupShowCustDetailsBl: 'PopupShowCustDetailsBl',
    QueryFingerResults: 'QueryFingerResults'
}