import React, { Component } from 'react'
import styled from "styled-components";
import { connect } from 'react-redux';

// import component
import SingleChoiceSelectBox from '../Control/SingleChoiceSelectBox';

// import data
import { listReasonPassTicket } from "../../data/data"
import { 
    MOVE_TICKET_TO_OTHER_COUNTER_ERR
} from "../../actions/types";
import { popupBioTypes } from "../../data/PopupTypes";

// import image
import icClose from "../../images/ic_close_gray.svg"
import ic_ticket_success from '../../images/ic-counter-notification-success.svg'
import iconNotificationClose from "../../images/ic_counter_notification_close.svg"

import { getOtherCounterInfos, moveTicketToOtherCounter } from '../../actions/counterAction';
import { openPopup } from '../../actions/popupBioAction'
import { clearError } from "../../actions/commonAction"

const StyledPopupPassTicket = styled.div`
  background: #fafafa;
  width: 522px;
  border-radius: 10px;
  position: absolute;
  padding-bottom: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* opacity: .5; */
  /* animation: fadeIn .5s forwards;
  @keyframes fadeIn {
    0%   { opacity:.6; }
    100% { opacity:1; }
  } */
  &, & * {
    box-sizing: border-box;
  }

  .popup-header {
    width: 100%;
    height: 84px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 20px;
    border-bottom: 3px solid #e9e9e9;
    background: #ffffff;
    display: flex;
    font-family: 'SVN-Gilroy';
    .wrap {
      flex-grow: 1;
      .popup-header__title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.08;
        color: #000000;
        margin-top: 13px;
      }
      .popup-header__info {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        color: #005993;
        margin-top: 12px;
        padding-bottom: 13px;
      }
    }
    img {
      width: 19.8px;
      height: 19.8px;
      position: absolute;
      top: 10.1px;
      right: 10.2px;
      cursor: pointer;
    }
  }

  .popup__content {
    padding: 17px 40px 24px 40px;
    .select-count-pass{

      .note {
        font-family: 'SVN-Gilroy';
        font-size: 12px;
        font-weight: 500;
        line-height: 1.3;
        color: #979797;
        margin-top: 9px;
      }
    }

    .select-content-pass {
      .select-content-pass__title{
        font-family: 'SVN-Gilroy';
        font-size: 11px;
        font-weight: bold;
        line-height: 1.26;
        color: #005993;
        text-transform: uppercase;
        margin-top: 26px;
        margin-bottom: 1px;
      }

      .radio-field {
        display: flex;
        align-items: center;
        margin-top: 17px;
        position: relative;

        input[type="radio"] {
          position: absolute;
          height: 0;
          width: 0;
          display: none;
        }
        
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 16px;
          width: 16px;
          border: solid 0.8px #c8c8c8;
          background: #ffffff;
          border-radius: 50%;
        }

        /* input[type="radio"]:checked {
          background-color: #d71249;
        } */
        
        /* input[type="radio"]:checked ~ .checkmark {
          background-color: #d71249;
        } */

        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        input[type="radio"]:checked ~ .checkmark:after {
          display: block;
        }

        .checkmark::after {
          /* left: 3.8px;
          top: 0;
          width: 4px;
          height: 8px;
          border: solid #d71249;
          border-width: 0 3px 3px 0;    
          transform: rotate(45deg); */
          top: 50%;
          left: 50%;
          width: 8px;
          height: 8px;
          background: #d71249;
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }
        

        div {
          margin-left: 26px;
          font-family: 'SVN-Gilroy';
          font-size: 14px;
          font-weight: 500;
          line-height: 1.2;
          color: #222222;
        }
      }
    }

    .content-pass {
      /* display:none; */
      &__title{
        font-family: 'SVN-Gilroy';
        font-size: 11px;
        font-weight: bold;
        line-height: 1.26;
        color: #005993;
        text-transform: uppercase;
        margin-top: 21px;
      }
      textarea {
        margin-top: 5px;
        resize: none;
        height: 84px;
        outline: none;
        width: 100%;
        border: solid 0.5px #c8c8c8;
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;   
        padding: 10px 9px;
        line-height: 1.2;
        color: #222222;
        background-color: #efefef;
        &::placeholder {
          color: #BBBBBB;
        }
      }
    }
  }
  .btn-submit {
    margin-top: 5px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
    button {
      bottom: 24px;
      right: 40px;
      border:none;
      outline: none;
      width: 100px;
      height: 36px;
      border-radius: 18px;
      background-color: #005993;
      text-transform: uppercase;
      font-family: 'SVN-Gilroy';
      font-size: 13px;
      font-weight: bold;
      color: #ffffff;
      cursor: ${props => props.cursor || "pointer"};
      opacity: ${props => props.opactity || "unset"};
    }

  }
`;

// const MAX_CHARACTERS = 200;

class PopupPassTicket extends Component {
    constructor(props) {
        super(props)

        this.state = {
            idShow: '',
            data: {
                counterPass: "",
                content: "",
                otherReason: ""
            },
            enableTextArea: false,
            isShowBtnSubmit: false
        }
    }

    handleCheckShowBtn = async () => {
        const {
            data,
            enableTextArea 
        } = this.state;
        if (data.counterPass && data.content && (!enableTextArea || (enableTextArea && data.otherReason.trim()))) {
            await this.setState({
                isShowBtnSubmit: true
            })
            return;
        }
        await this.setState({
            isShowBtnSubmit: false
        })
    }

    getText = async e => {
        await this.setState({ 
            data: { 
                ...this.state.data, 
                counterPass: e 
            } 
        }, () => this.handleCheckShowBtn())
    }

    setIdShow = (id) => {
        this.setState({ idShow: id })
    }

    handleEventSubmit = async () => {
        const { counterReducer } = this.props;
        let { data } = this.state;
        let fromcounterId = counterReducer.dataConnectDesktop.CounterID;
        let { TicketID } = this.props.data

        if (data.counterPass !== '' && data.content !== '') {
            const otherReason = data.otherReason.trim()
            const contentSubmit = otherReason? data.content + ": " + otherReason : data.content;
            // debugger;
            // return;
            await this.props.moveTicketToOtherCounter(TicketID, data.counterPass, contentSubmit, fromcounterId)
            let { err } = await this.props.counterReducer
            if(err[MOVE_TICKET_TO_OTHER_COUNTER_ERR] && err[MOVE_TICKET_TO_OTHER_COUNTER_ERR] === 11){
                this.props.clearError(MOVE_TICKET_TO_OTHER_COUNTER_ERR);
                const POPUP_LEVEL_1 = 1;
                const dataTranferToPopup = {
                    image_noti: iconNotificationClose,
                    title: <React.Fragment>Chuyển quầy không thành công do quầy cần chuyển đến đang <span style={{color: "#F31919"}}>Ngừng giao dịch</span></React.Fragment>,
                    widthPopup: "190px",
                    titleConfirm: "Xác nhận",
                }
                this.props.openPopup(
                    popupBioTypes.PopupAccect,
                    POPUP_LEVEL_1,
                    dataTranferToPopup
                )
                return;
            }
            if (err === null || err === undefined || err === '') {
                let data = {
                    image_noti: ic_ticket_success,
                    title: 'Chuyển vé thành công !',
                    titleConfirm: 'Xác nhận'
                }
                // this.props.onAccept()
                this.props.openPopup(8, 1, data)
                return;
            }
            this.props.onAccept()
        }
    }
    onChangeTextArea = event => {
        let content = event.target.value
        this.setState({ 
            data: { 
                ...this.state.data, 
                otherReason: content 
            }
        }, () => this.handleCheckShowBtn())
    }

    render() {
        const listOtherCounterInfos = this.props.counterReducer.listOtherCounterInfos;
    
        return (
            <StyledPopupPassTicket
                cursor={this.state.isShowBtnSubmit ? 'pointer' : 'default'}
                opactity={this.state.isShowBtnSubmit ? 'unset' : '0.5'}
            >
                <div className="popup-header">
                    <div className="wrap">
                        <div className="popup-header__title">Chuyển quầy</div>
                        <div className="popup-header__info">Thông tin cần chuyển</div>
                    </div>
                    <img src={icClose} alt="close" onClick={() => { this.props.onClosePopup() }} />
                </div>
                <div className="popup__content">
                    <div className="select-count-pass">
                        <SingleChoiceSelectBox
                            id="sel-counter-pass"
                            getText={this.getText}
                            data={listOtherCounterInfos}
                            title="Quầy đến"
                            titleChoise="Chọn quầy cần chuyển"
                            idShow={this.state.idShow}
                            setIdShow={this.setIdShow}
                            width="320px"
                        />
                        {/* <div className="note">(*) Danh sách chỉ hiển thị các quầy có giao dịch phù hợp trên vé</div> */}
                    </div>
                    <div className="select-content-pass">
                        <div className="select-content-pass__title">
              Lý do chuyển quầy
                        </div>
                        {listReasonPassTicket.map((reason, key) => (
                            <label className="radio-field" key={key}>
                                <input onClick={this.checkToOpenTextArea} type="radio" name="content" value={reason} />
                                <span className="checkmark"></span>
                                <div>{reason}</div>
                            </label>
                        ))}
                        <label className="radio-field">
                            <input onClick={this.checkToOpenTextArea} type="radio" name="content" value="Lý do khác" />
                            <span className="checkmark"></span>
                            <div>Lý do khác</div>
                        </label>
                    </div>
                    <div className="content-pass">
                        <div className="content-pass__title">Nội dung</div>
                        <textarea
                            placeholder="Nội dung không quá 200 ký tự"
                            maxLength="200"
                            disabled={!this.state.enableTextArea}
                            onChange={this.onChangeTextArea}
                            style={{
                                backgroundColor: this.state.enableTextArea ? "#ffffff" : "#efefef",
                                color: this.state.enableTextArea ? "#000000": "#BBBBBB"

                            }}
                        ></textarea>
                    </div>
                </div>
                <div className="btn-submit">

                    <button className="button" onClick={this.state.isShowBtnSubmit ?
                        this.handleEventSubmit : () => { }
                    }>Xác nhận</button>
                </div>
            </StyledPopupPassTicket>
        )
    }

    checkToOpenTextArea = async () => {
        const lastRadio = document.querySelector(".radio-field:last-child input")
        const content = document.querySelector('input[name="content"]:checked').value;
        await this.setState({ enableTextArea: lastRadio.checked ? lastRadio.checked : false })
        await this.setState({ data: { ...this.state.data, content } })
        await this.handleCheckShowBtn();
    }


    componentDidMount() {
        const { counterReducer } = this.props;
        let counterId = counterReducer.dataConnectDesktop.CounterID;
        let officeId = counterReducer.dataConnectDesktop.OfficeID;
        this.props.getOtherCounterInfos(counterId, officeId);
    }
}


const mapStateFromProps = state => ({
    counterReducer: state.counterReducer,
});

const mapDispatchFromProps = dispatch => ({
    openPopup: (typePopup, levelPopup, data) => dispatch(openPopup(typePopup, levelPopup, data)),
    getOtherCounterInfos: (counterId, officeId) => dispatch(getOtherCounterInfos(counterId, officeId)),
    moveTicketToOtherCounter: (ticketId, toCounterId, moveNote, fromCounterId) => dispatch(moveTicketToOtherCounter(ticketId, toCounterId, moveNote, fromCounterId)),
    clearError: (errType) => dispatch(clearError(errType))
});

export default connect(
    mapStateFromProps,
    mapDispatchFromProps,
    null,
    { forwardRef: true }
)(PopupPassTicket);