import * as types from "../../actions/inBioCollector/types";
import { CLEAR_ERR } from "../../actions/types";

export const initialState = {
    listData: [],
    filters: {
        CounterID: undefined,
        TellerName: undefined,
        SupervisorName: undefined,
        CustomerID: undefined,
        Status: undefined
    },
    err: ''
}


export default function listDataSentSupervisor(state = initialState, action) {
    switch (action.type) {
    case CLEAR_ERR: {
        const err = JSON.parse(JSON.stringify(state.err))
        delete err[action.typeErr]
        return {
            ...state,
            err,
        }
    }
    case types.GET_LIST_DATA_SENT_SUPERVISOR: {
        return {
            ...state,
            ...action.payload
        }
    }
    case types.GET_LIST_DATA_SENT_SUPERVISOR_ERR: {
        return {
            ...action,
            err: action.err
        }
    }
    default:
        return state;
    }
}