import React, { Component } from "react";
import styled from "styled-components";
import { connect } from 'react-redux'

import {
    // listReasonNotRegisterFaceID,
    // listReasonNotRegisterFingerID,
    listReasonNotRegisterBioDatas
} from "../../data/data";
import * as bioTypes from "../../data/bioTypes";
import { REASON_NOT_REGISTER_ERR } from "../../actions/types";

/////images
import ic_close from "../../images/ic_close_gray.svg";
import iconNotificationSuccess from "../../images/ic_counter_notification_success.svg"

// helper
import { openPopupNotifyErrorCompleteTicket } from "../../helper/actionOpenPopup";

// action
import { openPopup } from "../../actions/popupBioAction";
import { 
    reasonNotRegister,
    // requestEnroll, 
    requestNewEnrollIdToUpdateRejectBio, 
    // requestUpDateBio 
} from "../../actions/counterAction";
// import { TELLER } from "../../data/userRole";
import { setStatusBiometricWithTicket } from "../../actions/biometricAction";


const Bound = styled.div`
  background: #fafafa;
  /* width: 400px; */
  max-height: auto;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 10px 25px 20px;
  font-family: "SVN-Gilroy";
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  z-index: 999;
  box-shadow: 0 0 20px #00000070;
  & > figure {
    width: 100%;
    text-align: right;
    .ic_close {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title,
  .title2 {
    font-size: 14px;
    font-weight: bold;
    color: var(--black);
    &.title2 {
      margin: 27px 0 0 0;
    }
  }

  .btn-submit {
    margin-top: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .warning {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: var(--vermillion);
    }
    button {
      bottom: 24px;
      right: 40px;
      border: none;
      outline: none;
      width: 100px;
      height: 36px;
      border-radius: 18px;
      background-color: #005993;
      text-transform: uppercase;
      font-family: SVN-Gilroy;
      font-size: 13px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
`;

const ReasonNotRegister = styled.div`
  .select-reason {
    margin-top: 16px;
    .radio-field,
    .radio-field2 {
      display: flex;
      align-items: center;
      margin-top: 17px;
      position: relative;
      input[type="radio"] {
        position: absolute;
        height: 0;
        width: 0;
        display: none;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0px;
        height: 16px;
        width: 16px;
        border: solid 0.8px #c8c8c8;
        background: #ffffff;
        border-radius: 50%;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      input[type="radio"]:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark::after {
        /* left: 3.9px;
        top: 0.3px;
        width: 6px;
        height: 9px; */
        /* border: solid #d71249; */
        /* border-width: 0 2px 2px 0; */
        /* transform: rotate(45deg); */
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: #d71249;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
      div {
        margin-left: 26px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        color: #222222;
      }
    }
  }
  .content-reason {
    margin-top: 16px;
    .title {
      font-size: 11px;
      font-weight: bold;
      line-height: 1.26;
      color: #005993;
      text-transform: uppercase;
      margin-bottom: 3px;
    }
    textarea {
      margin-top: 1px;
      resize: none;
      outline: none;
      width: 375px;
      height: 46px;
      border: solid 0.5px #c8c8c8;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 10px;
      line-height: 1.2;
      color: #222222;
    }
    textarea:disabled {
      background: #efefef;
      color: #2222228a;
    }
    textarea::placeholder {
      color: #222222;
      opacity: 0.25;
    }
  }
`;

class PoupNotRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableTextAreaFaceID: false,
            enableTextAreaFingerID: false,
            dataFaceID: {
                content: "",
                otherReason: ""
            },
            dataFingerID: {
                content: "",
                otherReason: ""
            },
            cannotConfirm: false
        };
    }

    checkToOpenTextAreaFaceID = () => {
        const lastRadio = document.querySelector(".radio-field:last-child input");
        const content = document.querySelector(
            'input[name="contentFaceID"]:checked'
        ).value;
        this.setState({
            cannotConfirm: false,
            enableTextAreaFaceID: lastRadio.checked,
            dataFaceID: {
                ...this.state.dataFaceID, 
                content, 
                // otherReason: "" 
            }
        });
    };
    checkToOpenTextAreaFingerID = () => {
        const lastRadio = document.querySelector(".radio-field2:last-child input");
        const content = document.querySelector(
            'input[name="contentFingerID"]:checked'
        ).value;
        this.setState({
            cannotConfirm: false,
            enableTextAreaFingerID: lastRadio.checked,
            dataFingerID: { 
                ...this.state.dataFingerID, 
                content,
                // otherReason: "" 
            }
        });
    };

    otherReasonFaceID = e => {
        let value = e.target.value;
        this.setState({
            cannotConfirm: false,
            dataFaceID: { ...this.state.dataFaceID, otherReason: value }
        });
    };

    otherReasonFingerID = e => {
        let value = e.target.value;
        this.setState({
            cannotConfirm: false,
            dataFingerID: { ...this.state.dataFingerID, otherReason: value }
        });
    };

    // handleReGetEnrollId = async (ticketID) => {
    //   let { counterReducer } = this.props;
    //   const currentTicket = counterReducer.listCurrentTicket.find(item => item.TicketID === ticketID);
    //   let enrollId = counterReducer.enrollId[ticketID];
    //   if(!enrollId) return;
    //   // debugger;
    
    //   await this.props.setStatusBiometricWithTicket({
    //     TicketID: ticketID,
    //     PendingBioID: enrollId,
    //     PendingStatus: String(undefined)
    //   });

    //   if(currentTicket.bioInfosType === "NOTREGISTER"){
    //     const isNotHaveBio = currentTicket.dataWasAuthen &&
    //      !Number(currentTicket.dataWasAuthen.BiometricActive)
    //     isNotHaveBio && await this.props.requestEnroll(
    //       ticketID,
    //       currentTicket.CustomerInfos
    //     )
    //     !isNotHaveBio && await this.props.requestUpDateBio(
    //       ticketID, 
    //       currentTicket.CustomerInfos.CustomerNumber
    //     )
    //   }

    //   if(counterReducer.dataLogin.Role === TELLER){
    //     await this.props.requestUpDateBio(
    //       ticketID, 
    //       currentTicket.CustomerInfos.CustomerNumber
    //     )
    //     return;
    //   }
    //   // this.props.requestNewEnrollIdToUpdateRejectBioCollector(
    //   //   counterReducer.dataConnectDesktop.CounterID,
    //   //   enrollId,
    //   //   ticketID
    //   // )
    // }

    ////// submit
    handleEventSubmit = async () => {
        const {
            content: contentFaceID,
            otherReason: otherReasonFaceID
        } = this.state.dataFaceID
        // const {
        //   content: contentFingerID,
        //   otherReason: otherReasonFingerID
        // } = this.state.dataFingerID

        const {
            isNoneRegisterFaceBio,
            // isNoneRegisterFingerBio,
            // enableTextAreaFingerID,
            // enableTextAreaFaceID
        } = this.state;

        const isEmptyContentReasonFace = isNoneRegisterFaceBio && !contentFaceID.trim();
        const isEmptyContentOtherReasonFace = isNoneRegisterFaceBio && !otherReasonFaceID.trim();

        // const isEmptyContentReasonFinger = isNoneRegisterFingerBio && !contentFingerID.trim();
        // const isEmptyContentOtherReasonFinger = isNoneRegisterFingerBio && !otherReasonFingerID.trim();

        const errorEmptyReason = isEmptyContentReasonFace /* || isEmptyContentReasonFinger */;
        const errorEmptyOtherReason = isEmptyContentOtherReasonFace /* ||
      isEmptyContentOtherReasonFinger; */

        if (errorEmptyReason) {
            this.setState({ 
                cannotConfirm: true,
                errorEmptyReason: "Vui lòng nhập lý do",
                errorEmptyOtherReason: ""
            });
            return;
        }

        if(errorEmptyOtherReason){
            this.setState({ 
                cannotConfirm: true,
                errorEmptyReason: "",
                errorEmptyOtherReason: "Vui lòng nhập nội dung"
            });
            return;
        }

        const { 
            isHandleRejectFlow,
        } = this.props.data.dataTicket;
        // isHandleRejectFlow && await this.handleReGetEnrollId(ticketId);

        //////////////////// Call API ReasonNotRegister ////////////////////
        const dataGetTicket = this.props.data.dataTicket;
        const { counterReducer } = this.props;
        const { 
            dataFaceID, 
       
        } = this.state;
        const dataNotRegister = {
            ticketID: dataGetTicket.TicketID,
            cif: dataGetTicket.CustomerInfos.CustomerNumber,
            enrollId: !isHandleRejectFlow? 
                counterReducer.enrollId[dataGetTicket.TicketID]
                :
                undefined
        }

        dataNotRegister.reasonNRFace = dataFaceID.content + "<br>Chi tiết: " + dataFaceID.otherReason;
        // dataNotRegister.reasonNRFinger = dataFingerID.content + ". " + dataFingerID.otherReason
        dataNotRegister.reasonNRFinger = dataFaceID.content + "<br>Chi tiết: " + dataFaceID.otherReason;
        // debugger;
        // return;

        await this.props.reasonNotRegister(dataNotRegister)

        this.props.acceptAction();

        const { err } = this.props.counterReducer;
        if(!err || (typeof(err) === "object" && !Object.keys(err).includes(REASON_NOT_REGISTER_ERR))){
            const dataTranferToPopup = {
                image_noti: iconNotificationSuccess,
                title: "Gửi KSV phê duyệt thành công. Vui lòng chờ để được xử lý.",
                widthPopup: "236px",
                titleConfirm: "Xác nhận"
            }
            openPopupNotifyErrorCompleteTicket(this, dataTranferToPopup, "handleEventSubmit - PopupNotRegister.js")
        }
    };

    componentDidMount() {
        const {
            biometricReducer,
            popupBioManagerReducer
        } = this.props;
        const isReducerIncludeTicket = biometricReducer[popupBioManagerReducer.dataLv1.ticketId || popupBioManagerReducer.dataLv2.ticketId];
        const isNoneRegisterFaceBio =
      !isReducerIncludeTicket ||
      (
          isReducerIncludeTicket &&
        !Object.keys(isReducerIncludeTicket)
            .includes(bioTypes.FACE)
      );
        const isNoneRegisterFingerBio =
      !isReducerIncludeTicket ||
      (
          isReducerIncludeTicket &&
        Object.keys(isReducerIncludeTicket).filter(
            item => item !== "waitingConfirm" && item !== bioTypes.FACE
        ).length < 0
      )

        this.setState({
            isNoneRegisterFaceBio,
            isNoneRegisterFingerBio
        })
    }

    render() {
    // const {
    //   isNoneRegisterFaceBio,
    //   isNoneRegisterFingerBio
    // } = this.state;
        return (
            <Bound>
                <figure>
                    <img
                        data-testid="ic_close"
                        onClick={() => {
                            this.props.cancelAction();
                        }}
                        className="ic_close"
                        src={ic_close}
                        alt="ic_close"
                    />
                </figure>
                <div className="header">
                    <p className="title" data-testid="title">Khách hàng không đăng ký sinh trắc học</p>
                </div>
                <ReasonNotRegister>
                    <div className="select-reason">
                        {listReasonNotRegisterBioDatas.map((reason, key) => (
                            <label className="radio-field" htmlFor={"reason-" + key} key={key} data-testid="reason">
                                <input
                                    onClick={this.checkToOpenTextAreaFaceID}
                                    type="radio"
                                    id={"reason-" + key}
                                    name="contentFaceID"
                                    value={reason}
                                />
                                <span className="checkmark" />
                                <div>{reason}</div>
                            </label>
                        ))}
                    </div>
                    <div className="content-reason">
                        <div className="title">Chi tiết lý do không đăng ký</div>
                        <textarea
                            data-testid="content-other-reason"
                            onChange={e => this.otherReasonFaceID(e)}
                            placeholder="Nhập nội dung...không quá 200 ký tự"
                            maxLength="200"
                            //disabled={!this.state.enableTextAreaFaceID}
                        />
                    </div>
                </ReasonNotRegister>
                {/* <React.Fragment>
          <p className="title2">Khách hàng không đăng ký sinh trắc học - vân tay</p>
          <ReasonNotRegister>
            <div className="select-reason">
              {listReasonNotRegisterFingerID.map((reason, key) => (
                <label className="radio-field2" key={key}>
                  <input
                    onClick={this.checkToOpenTextAreaFingerID}
                    type="radio"
                    name="contentFingerID"
                    value={reason}
                  />
                  <span className="checkmark" />
                  <div>{reason}</div>
                </label>
              ))}
            </div>
            <div className="content-reason">
              <div className="title">Nội dung</div>
              <textarea
                onChange={e => this.otherReasonFingerID(e)}
                placeholder="Nhập nội dung...không quá 200 ký tự"
                maxLength="200"
                //disabled={!this.state.enableTextAreaFingerID}
              />
            </div>
          </ReasonNotRegister>
        </React.Fragment> */}
                <div className="btn-submit">
                    <p className="warning" data-testid='warning'>
                        {
                            this.state.cannotConfirm ?
                                this.state.errorEmptyReason || this.state.errorEmptyOtherReason
                                :
                                null
                        }
                    </p>
                    <button onClick={this.handleEventSubmit}>Gửi KSV</button>
                </div>
            </Bound>
        );
    }
}

const mapStateToProps = state => ({
    popupBioManagerReducer: state.popupBioManagerReducer,
    biometricReducer: state.biometricReducer,
    counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
    reasonNotRegister: (dataNotRegister) => 
        dispatch(reasonNotRegister(dataNotRegister)),
    openPopup: (type, levelPopup, data) => 
        dispatch(openPopup(type, levelPopup, data)),
    setStatusBiometricWithTicket: (data) => 
        dispatch(setStatusBiometricWithTicket(data)),
    // requestEnroll: (ticketId, cusInfo) => 
    //   dispatch(requestEnroll(ticketId, cusInfo)),
    requestNewEnrollIdToUpdateRejectBio: (counterId, enrollId, ticketId) => 
        dispatch(requestNewEnrollIdToUpdateRejectBio(counterId, enrollId, ticketId)),
    // requestUpDateBio: (ticketId, cusNumber) => 
    //   dispatch(requestUpDateBio(ticketId, cusNumber)),

})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PoupNotRegister);
