import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Styles } from './index.styles'

import ic_counter_notification_close from '../../../images/ic_counter_notification_close.svg'

import { tellerLogoutCounter } from '../../../actions/counterAction'

import ButtonConfirmControl from '../../Control/ButtonConfirmControl'

const ShowErrorConnectSocket = () => {

    const dispatch = useDispatch();

    const { counterReducer } = useSelector((state) => ({
        counterReducer: state.counterReducer
    }))

    useEffect(() => {
        window.onbeforeunload = () => true
        return () => {
            window.onbeforeunload = undefined
        }
    }, [])

    const handleLogout = (e) => {
        const { dataLogin, dataConnectDesktop } = counterReducer
        const { UID } = dataLogin
        const { CounterID, DeviceID } = dataConnectDesktop
        const dataLogout = {
            UID,
            CounterID,
            DeviceID,
        }
        dispatch(tellerLogoutCounter(dataLogout))
    }

    return (
        <Styles>
            <div className="popup">
                <img src={ic_counter_notification_close} alt="error" />
                <div className="title">Phiên làm việc bị gián đoạn.</div>
                <div className="title-flow">
                    Nhấn nút "Xác nhận" để đăng nhập lại vào hệ thống
                </div>
                <ButtonConfirmControl
                    titleConfirm="XÁC NHẬN"
                    bg_color="#005993"
                    onSubmit={handleLogout}
                />
            </div>
        </Styles>
    )
}

export default ShowErrorConnectSocket
