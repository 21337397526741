export const NV = "NV" //NotVerify 
export const PV = "PV" //PendingVeriFied
export const SV = "SV" //SeenVerified === SuccessVerified + SkipVerify      
export const SKV = "SKV" //SkipVerify      
export const SUV = "SUV" //SuccessVerified  

const verifyFingerStatusTypes = {
    NV,
    PV,
    SV,
    SUV
}

export const verifyFingerStatusDetail = {
    [NV]: {
        detail: "Chưa xác thực",
        color: "var(--warm-grey)"
    },
    [PV]: {
        detail: "Đang chờ KSV phê duyệt",
        color: "rgb(233, 160, 17)"
    },
    [SV]: {
        detail: "KSV đã xem",
        color: "rgb(15, 177, 31)"
    },
    [SUV]: {
        detail: "Thành công",
        color: "rgb(15, 177, 31)"
    }
}

export default verifyFingerStatusTypes;