import React, { Component } from 'react';
import { connect } from 'react-redux';
import PagingControl from "./PagningControl";

//Images
import ic_close from '../../../../../images/ic_close_gray.svg';
import close from '../../../../../images/ic_counter_notification_close.svg';
import { apiGetBioFile } from '../../../../../actions/biometricAction';
import { formatDateTimeToSpecificFormat, formatDateTime } from '../../../../../helper/formatTime';

// styles
import { Bound } from './PopupMatchFace.styles';

// helper
import getFromChannel from '../../../../../helper/getFromChannel';
import getChannelName from '../../../../../helper/getChannelName';
import { apiGetBioFileCollector } from '../../../../../actions/biometricWithInBioCollectorAction';
import { TELLER } from '../../../../../data/userRole';
// import SortControl from '../../MainScreen/Control/SortControl';


const limitPerPage = 2
class PopupMatchFace extends Component {
    state = {
        currentPage: 1,
        startIndex: 0,
        endIndex: 2
    }

    paginationPage() {
        let endIndex = this.state.currentPage * limitPerPage
        let startIndex = endIndex - limitPerPage
        this.setState({
            startIndex, endIndex
        })
    }

    onPageChange = page => {
        let currentPage = page;
        let endIndex = currentPage * limitPerPage;
        let startIndex = endIndex - limitPerPage;
        this.setState({ currentPage, startIndex, endIndex });
    }
    onSubmit = () => {
        // const {data} = this.props
        this.props.onClosePopup()
    }

    render() {
        let { startIndex, endIndex } = this.state
        const { DuplicateFaces, DuplicateFaceNote, CustomerCif } = this.props.data
        let duplicateFaceFilter = DuplicateFaces.filter(item => item.CustomerNumber !== CustomerCif)

        let dataSlice = duplicateFaceFilter.slice(startIndex, endIndex)

        const { counterReducer } = this.props
        const isTeller = counterReducer.dataLogin.Roles.includes(TELLER)
        const apiGetFace = isTeller ? apiGetBioFile : apiGetBioFileCollector

        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 999
                }}>
                <Bound>
                    <div className="ic_close">
                        <img src={ic_close} alt="ic_close" onClick={this.props.onClosePopup} />
                        {/* <h3>Hình ảnh khuôn mặt trùng khớp</h3> */}
                    </div>
                    <div className="content">
                        <div className='d-center'>
                            <img src={close} alt='close' />
                            <p className='title'>Hình ảnh STH khuôn mặt của KH đang giao dịch trùng với KH khác trong hệ thống</p>
                        </div>
                        <div className='scrollbar'>
                            {
                                dataSlice.map((item, i) => {
                                    return (
                                        <div className='inform-match' key={i}>
                                            <div className='match-left'>
                                                <div className='content-verify'>
                                                    <div className='type-inform-blue'>CIF:</div>
                                                    <span className='type-cif'>&nbsp;{item.CustomerNumber}  </span>
                                                </div>
                                                <img src={apiGetFace(item.FaceID)} alt='user1' />
                                                <div className="channel">Kênh tác nghiệp: {getChannelName(getFromChannel(item.FromChannel))}</div>
                                                {/* <p>{formatDateTimeToSpecificFormat(item.CreateAt, formatDateTime.HHmmDDMMYY)}</p> */}
                                            </div>
                                            <div className='match-right'>
                                                <div className='cover'>
                                                    <span className='type-name'>{item.Name}</span>
                                                </div>
                                                <div className='cover-flex'>
                                                    <div className='cover-left'>
                                                        <div className='content-verify'>
                                                            <div className='type-inform-blue'>GTTT:</div>
                                                            <span className='type-gtt style-same'>&nbsp;{item.IdNumber}</span>
                                                        </div>
                                                        <div className='content-verify'>
                                                            <span className='type-inform-blue'>Ngày cấp:</span>
                                                            <span className='type-date style-same'>&nbsp;{item.IdIssueDate}</span>
                                                        </div>
                                                        <div className='content-verify'>
                                                            <div className='cover-ellipst' style={{ cursor: item.IdIssuePlace !== '' && 'pointer' }}>
                                                                <span className='type-inform-blue'>Nơi cấp:&nbsp;</span>
                                                                <div className='text-ellipst type-place style-same'>{item.IdIssuePlace}</div>
                                                                {
                                                                    item.IdIssuePlace !== '' && <span id='tooltip_'>{item.IdIssuePlace}</span>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='content-verify'>
                                                            <span className='type-inform-blue'>SĐT:</span>
                                                            <span className='type-date style-same'>&nbsp;{item.MobileNumber}</span>
                                                        </div>
                                                    </div>
                                                    <div className='cover-right'>
                                                        <div className='content-verify'>
                                                            <div className='cover-ellipst' style={{ cursor: item.OfficeName !== '' && 'pointer' }}>
                                                                <span className='type-inform-blue'>PGD:&nbsp;</span>
                                                                <div className='text-ellipst type-pgd style-same'>{item.OfficeName}</div>
                                                                {
                                                                    item.OfficeName !== '' && <span id='tooltip_'>{item.OfficeName}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='content-verify'>
                                                            <div className='cover-ellipst' style={{ cursor: item.TellerName !== '' && 'pointer' }}>
                                                                <span className='type-inform-blue'>GDV/Điện toán:&nbsp;</span>
                                                                <div className='text-ellipst type-place style-same'>{item.TellerName}</div>
                                                                {
                                                                    item.TellerName !== '' && <span id='tooltip_'>{item.TellerName}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='content-verify'>
                                                            <div className='cover-ellipst' style={{ cursor: item.SupervisorName !== '' && 'pointer' }}>
                                                                <span className='type-inform-blue'>KSV/BGD:&nbsp;</span>
                                                                <div className='text-ellipst type-ksv style-same'>{item.SupervisorName}</div>
                                                                {
                                                                    item.SupervisorName !== '' && <span id='tooltip_'>{item.SupervisorName}</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='content-verify'>
                                                            <span className='type-inform-blue'>Thời gian phê duyệt:</span>
                                                            <span className='type-place style-same'> &nbsp;{formatDateTimeToSpecificFormat(item.CreateAt, formatDateTime.HHmmDDMMYY)}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='content-verify' style={{ margin: 0 }}>
                                                    <span className='type-inform-blue'>Địa chỉ:&nbsp;</span>
                                                    <div className='type-address style-same'>{item.Address}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            duplicateFaceFilter.length > limitPerPage &&
                            <PagingControl
                                currentPage={this.state.currentPage}
                                totalItem={duplicateFaceFilter.length}
                                limitPerPage={limitPerPage}
                                onChangePage={page => this.onPageChange(page)}
                            />
                        }

                        {/* <div className='text-note' style={{ marginTop: DuplicateFaces.length > limitPerPage ? '50px' : '12px' }}>
                            <p className='note'>nội dung ghi chú</p>
                            <p className='content-note'>{DuplicateFaceNote}</p>
                        </div> */}
                    </div>
                    <div className="btn_ok"
                        onClick={this.onSubmit}>
                        Xác nhận
                    </div>
                </Bound>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        counterReducer: state.counterReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PopupMatchFace)