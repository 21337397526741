import React, { Component } from 'react'
import styled from 'styled-components'

const Bound = styled.div`
  width: 100%;
  height: 100vh;
  background-color:#22222282;
  .container {
    z-index:999;
    position:absolute;
    top:25%;
    left:50%;
    transform:translate(-50%,-50%);
    padding: 20px 40px;
    width: ${props => props.width || "200px"};
    height: ${props => props.heigth || "auto"};
    border-radius: 5px;
    background-color: #fafafa;
    animation: counterFail .3s ease-out;
    @keyframes counterFail {
      from {top: 0px;}
      to {top: 25%;}
    }
    .ic_question{
      display:flex;
      justify-content: center;
      img{
        width:52px;
        height:52px;
        border-radius:50%;
        object-fit:contain;
      }
    }
    .title{
      margin: 20px 0 0 0;
      text-align: center;
      font-family: SVN-Gilroy;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #222222;
    }
    .btn{
      margin: 32px 0 0 0;
      display:flex;
      justify-content: center;
      .btn_submit{
        display:flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
 
`

export default class PopupCouterFail extends Component {
    render() {
        return (
            <Bound
                width={this.props.width}
                height={this.props.heigth}
            >
                <div className='container'>
                    <div className='ic_question'>
                        <img src={this.props.icon} alt="icon" />
                    </div>
                    <p className='title'>{this.props.label}</p>
                    <div className="btn">
                        <div className="btn_submit" onClick={() => this.props.onClosePopup()}>
              XÁC NHẬN
                        </div>
                    </div>
                </div>
            </Bound>
        )
    }
}
