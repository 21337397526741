import styled from 'styled-components'

const WrapFaceQuery = styled.div`
    width: 192px;
    min-height: 192px;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 5px;
    background: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img.ic-user-default {
        margin-top: 25px;
        width: 52px;
        height: 56px;
    }

    .desc {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 110%;
        color: #AAAAAA;
        margin-top: 23px;
    }

    .wrap-loading {

        .desc-loading {
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 110%;
            color: #000000;
        }
    }

    .wrap-face {
        display: inline-block;
        margin-top: 25px;
        img {
            width: 150px;
        }
    }
    
    .wrap-btn {
        margin: 24px 0 15px 0;
        & > *:last-child {
            margin-left: 4px;
        }

        button {
            padding: 8px 12px;
            background: #005993;
            border-radius: 18px;
            border: none;
            outline: none;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 110%;
            color: #FFFFFF;
            &:hover {
                cursor: pointer;
            }
            &:disabled {
                opacity: 0.4;
                cursor: initial;
            }
        }
    }
`;

export {
    WrapFaceQuery
}