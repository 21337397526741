import styled from 'styled-components';

const Bound = styled.div`
  /* padding: 16px 40px 20px 40px;
  width: calc(100% - 80px); */

  padding: 16px 10px 20px 40px;
  width: calc(100% - 50px);
  height: calc(100% - 62px);
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .title_1 {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.26;
    color: #005993;
    text-transform:uppercase;
  }
  .userName {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.08;
    color: #222222;
    margin: 14px 0 0 0;
    font-family: SVN-Gilroy;
  }
  .block_info {
    margin: 16px 0 0 0;
    .name_type {
      .content {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.25;
        color: #979797;
        margin: 14px 0 0 0;
        /*width: 232px;*/
        span {
          color: #222222;
        }
      }
    }
    .name_type.block_col_2{
      /*.row.content:first-child{
        color: rgba(215,18,73,1) !important;
        font-weight: bold;
      }
      .row.content:nth-child(2){
        color: rgba(215,18,73,1) !important;
        font-weight: bold;
      }*/
    }
  }
  .text-ellipst {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    pointer-events:none;
  }
  .text-ellipst:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 17px;
    z-index: 1;
    pointer-events: initial;
  }

  .text-gdv{
    color: #222222;
  }

  .w-90{
    width: 160px;
  }

  .cover{
    position: relative;
    width: 100%;
    /* height: 20px; */
    display: flex;

    & > p {
      flex-shrink: 0;
    }
  }

  #tooltip_ {
    position: absolute;
    white-space: pre-line;
    /* width: 55%; */
    left: 30%;
    top: -66%;
    z-index: 999;
    visibility: hidden;
    background-color: #fff;
    padding: 11px;
    box-shadow: 0 0 50px 0 rgba(0,0,0,0.3);
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .text-ellipst:hover + #tooltip_, #tooltip_:hover{
    visibility: visible;
    transition: opacity 0.5s ease;
    opacity: 1;
  }
  .address{
    margin-top: 5px !important;
  }
`
export {
    Bound
}