import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

// import image
import icClose from '../../images/ic_close_gray.svg'
import { fingerID } from '../../data/data'
import FingerControl from '../Control/FingerControl'
import ButtonConfirmControl from '../Control/ButtonConfirmControl'
import {
    requestGetBioData,
    requestConfirmBioData,
    apiGetBioFile,
    requestRemoveBioData,
    resetConfirmBioData,
    removeStatusWaitingBioData,
    updateFingerInfoInRegisterThread,
    apiGetBioFingerImage,
} from '../../actions/biometricAction'
import LoadingControl from '../Control/LoadingControl'
import {
    REGISTER,
    CONFIRM,
    CONFIRM_FINGER_FAIL,
    AUTH_TRANS,
    VALIDATE_DATA,
    // AUTH_TRANS,
} from '../../actions/typeRequestBio'
import { nameFingerPrint, FACE } from '../../data/bioTypes'
import CheckConfirmFinger from '../Popup/PopupRegisterFingerID/CheckConfirmFinger'
import WaittingConfirmFinger from '../Popup/PopupRegisterFingerID/WaittingConfirmFinger'
import { requestEnroll, requestUpDateBio } from '../../actions/counterAction'
import { TELLER, INBIOCOLLECTOR } from '../../data/userRole'
import {
    requestEnrollCollector,
    requestGetBioDataCollector,
    requestConfirmBioDataCollector,
    apiGetBioFileCollector,
    removeBioDataCollector,
    setAuthenticateFingerCollector,
    removeStatusWaitingBioDataCollector,
    requestUpDateBioCollector,
} from '../../actions/biometricWithInBioCollectorAction'
import { openPopup, closePopup } from '../../actions/popupBioAction'

// helper
import isDisableEventClick from '../../helper/isDisableEventWhenHaveSupervisor'
import isErrorWhenGetEnroll from '../../helper/isErrorWhenGetEnroll'
import { clearError } from '../../actions/commonAction'

const FirstCheck = 1
const LastCheck = 2

const REGISTER_FINGER_FIRST = 1
const REGISTER_FINGER_LOADING = 2
const REGISTER_FINGER_LAST = 3
const CONFIRM_FINGER = 4
const CONFIRM_FINGER_LOADING = 5
const SHOW_ERR_FINGER_NOT_MATCHED = 6
const CONFIRM_FINGER_LOADING_MATCHED = 7
const SHOW_SUCCESS_FINGER = 8
const SHOW_ERR_FINGER_MATCHED = 9

const Bound = styled.div`
    background: #ffffff;
    width: ${(props) =>
        props.step === SHOW_ERR_FINGER_NOT_MATCHED ? 'unset' : '290px'};
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: ${(props) => props.posLeft || '55%'};
    transform: translate(-50%, -50%);
    z-index: 99;
    font-family: 'SVN-Gilroy';
    transition: 0.2s all ease-in-out;
    &,
    & * {
        box-sizing: border-box;
    }
    box-shadow: 5px 6px 20px black;
    animation: fadeInFinger 0.5s forwards;
    @keyframes fadeInFinger {
        0% {
            opacity: 0.85;
            top: 45%;
        }
        100% {
            opacity: 1;
            top: 50%;
        }
    }
    .popup-header {
        width: 100%;
        border-radius: 10px;
        background: #ffffff;
        display: flex;
        padding: 16px 0 0 20px;
        .wrap {
            flex-grow: 1;
            .popup-header__title {
                font-size: 16px;
                font-weight: bold;
                line-height: 1.25;
                color: #000000;
            }
        }
        img {
            width: 19.8px;
            height: 19.8px;
            position: absolute;
            top: 10.1px;
            right: 10.2px;
            cursor: pointer;
        }
    }
    .finger {
        display: flex;
        justify-content: space-evenly;
        padding: 16px 10px 0 10px;
    }
    .stepActions {
        width: 100%;
        height: 35px;
        margin: 15px 0 15px 0;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn_accept,
        .close_register,
        .content_info,
        .loading_control {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .closing-get-bio {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
        }
        .close_register {
            p {
                text-decoration: underline;
                font-size: 14px;
                font-weight: 500;
                color: #f31919;
                cursor: pointer;
                text-align: center;
            }
        }
        .content_info {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.3;
            color: #005993;
        }
        .loading_control {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.3;
            color: #222222;
        }
    }
`

const ContentError = styled.div`
    padding: 16px 32px;

    .row {
        span.target-data {
            font-weight: bold;
            color: var(--vermillion);
        }
    }
`

class PopupRegisterFingerIDControl extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: REGISTER_FINGER_FIRST,
            isLoad: false,
            isRemoveFinger: false,
            isLoading: {
                load_finger_first: null,
                load_finger_last: null,
            },
            img_fingerBio: {
                img_fingerBio_first: null,
                img_fingerBio_last: null,
            },
            closingGetBio: false,
        }
    }

    handleAutoConfirmTransaction = async () => {
        const { counterReducer, biometricReducer, data } = this.props
        const TicketID = data.dataGetTicket.TicketID

        if (isDisableEventClick(TicketID, counterReducer, this)) return

        if (this.props.counterReducer.dataLogin.Roles.includes(TELLER)) {
            await this.props.fingerAuthTrans(
                TicketID,
                counterReducer.enrollId[TicketID],
                data.typeBio,
                true
            )
            return
        }

        //
        const thisBio = biometricReducer[TicketID]
        const bioFingerIsConfirmFinger = Object.keys(thisBio).filter((item) => {
            const itemIsFinger = item !== FACE
            const itemIsNotRemove = thisBio[item].bioActionType !== 'REMOVE'
            const haveValidatedData = !!thisBio[item][VALIDATE_DATA]
            const isConfirmFingerRegister =
                Object.keys(thisBio[item]).includes(AUTH_TRANS) &&
                thisBio[item][AUTH_TRANS]
            const isConfirmFingerUpdate =
                !Object.keys(thisBio[item]).includes(AUTH_TRANS) &&
                haveValidatedData &&
                thisBio[item][VALIDATE_DATA][AUTH_TRANS]

            const isConfirmFinger =
                isConfirmFingerRegister || isConfirmFingerUpdate

            return itemIsNotRemove && itemIsFinger && isConfirmFinger
        })

        if (bioFingerIsConfirmFinger.length < 2)
            this.props.setAuthenticateFingerCollector(
                this.props.counterReducer.enrollId[TicketID],
                TicketID,
                data.typeBio,
                true
            )
        else
            this.props.setAuthenticateFingerCollector(
                this.props.counterReducer.enrollId[TicketID],
                TicketID,
                data.typeBio,
                false
            )
    }

    /////// Register FingerID
    registerFingerID = async (numberCheck) => {
        const { counterReducer, data } = this.props
        const TicketID = data.dataGetTicket.TicketID

        if (isDisableEventClick(TicketID, counterReducer, this)) return

        const userCheckListCurrent = counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === TicketID
        )
        // debugger
        if (!userCheckListCurrent) return
        if (!userCheckListCurrent.CustomerInfos) return
        const {
            BiometricFace,
            BiometricFinger,
            BiometricActive,
        } = userCheckListCurrent.CustomerInfos

        switch (true) {
        case counterReducer.dataLogin.Roles.includes(TELLER) &&
                !Number(BiometricFace) &&
                !Number(BiometricFinger) &&
                !Number(BiometricActive):
            if (!counterReducer.enrollId[TicketID]) {
                await this.props.requestEnroll(
                    TicketID,
                    userCheckListCurrent.CustomerInfos
                )
                const isError = isErrorWhenGetEnroll(
                    this.props.counterReducer,
                    this.props.openPopup,
                    this.props.clearError
                )
                if (isError) return
            }
            if (
                data.dataGetTicket &&
                    Object.keys(counterReducer.enrollId).lenght !== 0
            ) {
                await this.props.requestGetBioData({
                    CounterID: counterReducer.dataConnectDesktop.CounterID,
                    TicketID: TicketID,
                    EnrollID: this.props.counterReducer.enrollId[TicketID],
                    BioType: data.typeBio,
                })
            }
            break
        case counterReducer.dataLogin.Roles.includes(TELLER) &&
                !!(
                    Number(BiometricFace) ||
                    Number(BiometricFinger) ||
                    Number(BiometricActive)
                ):
            if (!counterReducer.enrollId[data.dataGetTicket.TicketID]) {
                await this.props.requestUpDateBio(
                    data.dataGetTicket.TicketID,
                    userCheckListCurrent.CustomerInfos.CustomerNumber
                )
                const isError = isErrorWhenGetEnroll(
                    this.props.counterReducer,
                    this.props.openPopup,
                    this.props.clearError
                )
                if (isError) return
            }
            if (
                data.dataGetTicket &&
                    Object.keys(counterReducer.enrollId).lenght !== 0
            ) {
                await this.props.requestGetBioData({
                    CounterID: counterReducer.dataConnectDesktop.CounterID,
                    TicketID: TicketID,
                    EnrollID: this.props.counterReducer.enrollId[TicketID],
                    BioType: data.typeBio,
                })
            }
            break

        case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR) &&
                !Number(BiometricFace) &&
                !Number(BiometricFinger) &&
                !Number(BiometricActive):
            if (!counterReducer.enrollId[TicketID]) {
                await this.props.requestEnrollCollector(
                    userCheckListCurrent.CustomerInfos.CustomerNumber,
                    TicketID
                )
                const isError = isErrorWhenGetEnroll(
                    this.props.counterReducer,
                    this.props.openPopup,
                    this.props.clearError
                )
                if (isError) return
            }
            if (
                data.dataGetTicket &&
                    Object.keys(counterReducer.enrollId).lenght !== 0
            ) {
                await this.props.requestGetBioDataCollector(
                    {
                        CounterID:
                                counterReducer.dataConnectDesktop.CounterID,
                        EnrollID: this.props.counterReducer.enrollId[
                            TicketID
                        ],
                        BioType: data.typeBio, //// type send request to table get file bio
                    },
                    TicketID
                )
            }
            break
        case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR) &&
                !!(
                    Number(BiometricFace) ||
                    Number(BiometricFinger) ||
                    Number(BiometricActive)
                ):
            // this.props.flowUpdate:
            if (!counterReducer.enrollId[data.dataGetTicket.TicketID]) {
                await this.props.requestUpDateBioCollector(
                    data.dataGetTicket.TicketID,
                    userCheckListCurrent.CustomerInfos.CustomerNumber
                )
                const isError = isErrorWhenGetEnroll(
                    this.props.counterReducer,
                    this.props.openPopup,
                    this.props.clearError
                )
                if (isError) return
            }
            if (
                data.dataGetTicket &&
                    Object.keys(counterReducer.enrollId).lenght !== 0
            ) {
                await this.props.requestGetBioDataCollector(
                    {
                        CounterID:
                                counterReducer.dataConnectDesktop.CounterID,
                        TicketID,
                        EnrollID: this.props.counterReducer.enrollId[
                            TicketID
                        ],
                        BioType: data.typeBio,
                    },
                    TicketID
                )
            }
            break
        default:
            break
        }

        this.setState({
            isRemoveFinger: false,
        })
        if (numberCheck === 1)
            this.setState({
                isLoading: {
                    load_finger_first: true,
                },
            })
        else
            this.setState({
                isLoading: {
                    load_finger_last: true,
                },
            })
    }

    requestConfirmBioData = () => {
        const { counterReducer, data } = this.props

        if (
            isDisableEventClick(
                data.dataGetTicket.TicketID,
                counterReducer,
                this
            )
        )
            return

        this.setState({
            isLoad: true,
        })
        if (data.dataGetTicket) {
            const TicketID = data.dataGetTicket.TicketID

            switch (true) {
            case counterReducer.dataLogin.Roles.includes(TELLER):
                if (data.dataGetTicket && counterReducer.enrollId) {
                    this.props.requestConfirmBioData(
                        counterReducer.enrollId[TicketID],
                        data.typeBio,
                        TicketID
                    )
                }
                break
            case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR):
                this.props.requestConfirmBioDataCollector(
                    counterReducer.enrollId[TicketID],
                    data.typeBio,
                    TicketID
                )
                break
            default:
                break
            }
        }
    }

    acceptFailFinger = () => {
        const { data } = this.props
        const TicketID = data.dataGetTicket.TicketID
        const bioType = data.typeBio
        this.props.resetConfirmBioData(TicketID, bioType)
        this.setState({
            step: CONFIRM_FINGER,
            isLoad: false,
        })
    }

    actionFailTryAgain = () => {
        const { data } = this.props
        const TicketID = data.dataGetTicket.TicketID
        const bioType = data.typeBio
        this.props.resetConfirmBioData(TicketID, bioType)
        this.setState({
            isLoad: false,
            step: CONFIRM_FINGER,
        })
    }

    requestRemoveBioData = (numberCheck) => {
        const { data, counterReducer, biometricReducer } = this.props

        if (
            isDisableEventClick(
                data.dataGetTicket.TicketID,
                counterReducer,
                this
            )
        )
            return

        const index = numberCheck === FirstCheck ? 0 : 1

        if (!this.state.isRemoveFinger || !data.dataGetTicket) return
        const bioId =
            biometricReducer[data.dataGetTicket.TicketID][data.typeBio][
                REGISTER
            ][index]
        switch (true) {
        case counterReducer.dataLogin.Roles.includes(TELLER):
            this.props.requestRemoveBioData(
                counterReducer.enrollId[data.dataGetTicket.TicketID],
                data.typeBio,
                bioId,
                data.dataGetTicket.TicketID,
                index
            )
            break
        case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR):
            this.props.removeBioDataCollector(
                counterReducer.enrollId[data.dataGetTicket.TicketID],
                data.typeBio,
                bioId,
                data.dataGetTicket.TicketID,
                index
            )
            break
        default:
            break
        }
    }

    finishActionBio = () => {
        const { data } = this.props
        const TicketID = data.dataGetTicket.TicketID
        const isTeller = this.props.counterReducer.dataLogin.Roles.includes(
            TELLER
        )
        if (isTeller) this.props.removeStatusWaitingBioData(TicketID)
        else this.props.removeStatusWaitingBioDataCollector(TicketID)
        this.setState({
            isLoad: false,
            isLoading: {
                load_finger_first: null,
                load_finger_last: null,
            },
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.step !== this.state.step) {
            this.setState({
                closingGetBio: false,
                isVisibleFinishBtn: false,
            })
        }

        const { data, biometricReducer, counterReducer } = this.props
        const dataGetTicket = data.dataGetTicket
        const typeBioFinger = data.typeBio
        let RoleTeller = false
        let RoleInbio = false
        if (counterReducer.dataLogin.Roles.includes(TELLER)) RoleTeller = true
        else if (counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR))
            RoleInbio = true

        if (
            JSON.stringify(prevProps.biometricReducer) !==
            JSON.stringify(biometricReducer)
        ) {

            const checkWaitting = /waitingConfirm/g.test(
                JSON.stringify(biometricReducer[dataGetTicket.TicketID])
            )
            const isHaveTypeBio = new RegExp(typeBioFinger).test(
                JSON.stringify(biometricReducer[dataGetTicket.TicketID])
            )

            isHaveTypeBio === false &&
                !checkWaitting &&
                this.setState({
                    step: REGISTER_FINGER_FIRST,
                    isRemoveFinger: true,
                    img_fingerBio: {
                        img_fingerBio_first: null,
                        img_fingerBio_last: null,
                    },
                })
            if (
                checkWaitting &&
                !biometricReducer[dataGetTicket.TicketID][typeBioFinger]
            ) {
                const waitingCheck =
                    biometricReducer[dataGetTicket.TicketID].waitingConfirm
                if (waitingCheck.typeBio === data.typeBio) {
                    this.setState({
                        isLoading: {
                            load_finger_first: true,
                        },
                    })
                }
            } else if (
                checkWaitting &&
                biometricReducer[dataGetTicket.TicketID][typeBioFinger][
                    REGISTER
                ]
            ) {
                if (
                    biometricReducer[dataGetTicket.TicketID][typeBioFinger][
                        REGISTER
                    ].length === 1
                ) {
                    const waitingCheck =
                        biometricReducer[dataGetTicket.TicketID].waitingConfirm
                    if (waitingCheck.typeBio === data.typeBio) {
                        this.setState({
                            isLoading: {
                                load_finger_last: true,
                            },
                        })
                    }
                }
            } else if (
                checkWaitting &&
                biometricReducer[dataGetTicket.TicketID][typeBioFinger][
                    REGISTER
                ]
            ) {
                if (
                    biometricReducer[dataGetTicket.TicketID][typeBioFinger][
                        REGISTER
                    ].length > 1
                ) {
                    const img_fingerBio_register =
                        biometricReducer[dataGetTicket.TicketID][typeBioFinger][
                            REGISTER
                        ]
                    if (!img_fingerBio_register[0]) {
                        this.setState({
                            isLoading: {
                                load_finger_first: true,
                            },
                        })
                    } else
                        this.setState({
                            isLoading: {
                                load_finger_last: true,
                            },
                        })
                }
            }
            if (biometricReducer[dataGetTicket.TicketID]) {
                if (this.state.step === CONFIRM_FINGER_LOADING_MATCHED) {
                    if (
                        biometricReducer[dataGetTicket.TicketID]
                            .waitingConfirm &&
                        biometricReducer[dataGetTicket.TicketID][CONFIRM] ===
                        prevProps.biometricReducer[dataGetTicket.TicketID][
                            CONFIRM
                        ]
                    )
                        return
                    const codeConfirm =
                        biometricReducer[dataGetTicket.TicketID][typeBioFinger][
                            CONFIRM
                        ]

                    if (codeConfirm > CONFIRM_FINGER_FAIL) {
                        this.setState({
                            step: SHOW_ERR_FINGER_MATCHED,
                            isLoad: false,
                        })
                        return
                    }
                    if (codeConfirm === 0) {
                        this.setState({
                            step: SHOW_SUCCESS_FINGER,
                            isLoad: false,
                        })
                        this.handleAutoConfirmTransaction()
                        return
                    }
                }
                if (biometricReducer[dataGetTicket.TicketID].waitingConfirm) {
                    const loadingFingerID =
                        biometricReducer[dataGetTicket.TicketID].waitingConfirm
                            .typeRequestBio
                    if (
                        biometricReducer[dataGetTicket.TicketID].waitingConfirm
                            .typeBio === typeBioFinger
                    ) {
                        if (loadingFingerID === REGISTER) {
                            this.setState(
                                {
                                    step: REGISTER_FINGER_LOADING,
                                },
                                () => {
                                    setTimeout(() => {
                                        this.setState({
                                            isVisibleFinishBtn: true,
                                        })
                                    }, 1500)
                                }
                            )
                            return
                        }
                    }
                    if (this.state.step === CONFIRM_FINGER) {
                        this.setState({
                            step: CONFIRM_FINGER_LOADING_MATCHED,
                            isLoad: false,
                        })
                        return
                    }
                } else {
                    if (
                        biometricReducer[dataGetTicket.TicketID][typeBioFinger]
                    ) {
                        const img_fingerBio_register =
                            biometricReducer[dataGetTicket.TicketID][
                                typeBioFinger
                            ][REGISTER]
                        if (!img_fingerBio_register) {
                            this.setState({
                                isRemoveFinger: true,
                                step: REGISTER_FINGER_FIRST,
                                img_fingerBio: {
                                    img_fingerBio_first: null,
                                    img_fingerBio_last: null,
                                },
                            })
                        }
                        if (
                            img_fingerBio_register &&
                            img_fingerBio_register.length === 1
                        ) {
                            this.setState({
                                step: REGISTER_FINGER_LAST,
                                isRemoveFinger: true,
                                img_fingerBio: {
                                    img_fingerBio_first: RoleTeller
                                        ? apiGetBioFile(
                                            img_fingerBio_register[0]
                                        )
                                        : RoleInbio
                                            ? apiGetBioFileCollector(
                                                img_fingerBio_register[0]
                                            )
                                            : this.props.flowUpdate &&
                                            RoleTeller &&
                                            apiGetBioFingerImage(
                                                img_fingerBio_register[0]
                                            ),

                                    img_fingerBio_last: img_fingerBio_register[1]
                                        ? RoleTeller
                                            ? apiGetBioFile(
                                                img_fingerBio_register[1]
                                            )
                                            : RoleInbio
                                                ? apiGetBioFileCollector(
                                                    img_fingerBio_register[1]
                                                )
                                                : this.props.flowUpdate &&
                                                RoleTeller &&
                                                apiGetBioFingerImage(
                                                    img_fingerBio_register[1]
                                                )
                                        : null,
                                },
                            })
                        }
                        if (
                            img_fingerBio_register &&
                            img_fingerBio_register.length === 2 &&
                            !Object.keys(
                                biometricReducer[dataGetTicket.TicketID][
                                    typeBioFinger
                                ]
                            ).includes(CONFIRM)
                        ) {
                            this.setState({
                                isRemoveFinger: true,
                                img_fingerBio: {
                                    img_fingerBio_first: img_fingerBio_register[0]
                                        ? RoleTeller
                                            ? apiGetBioFile(
                                                img_fingerBio_register[0]
                                            )
                                            : RoleInbio
                                                ? apiGetBioFileCollector(
                                                    img_fingerBio_register[0]
                                                )
                                                : this.props.flowUpdate &&
                                                RoleTeller &&
                                                apiGetBioFingerImage(
                                                    img_fingerBio_register[0]
                                                )
                                        : null,
                                    img_fingerBio_last: img_fingerBio_register[1]
                                        ? RoleTeller
                                            ? apiGetBioFile(
                                                img_fingerBio_register[1]
                                            )
                                            : RoleInbio
                                                ? apiGetBioFileCollector(
                                                    img_fingerBio_register[1]
                                                )
                                                : this.props.flowUpdate &&
                                                RoleTeller &&
                                                apiGetBioFingerImage(
                                                    img_fingerBio_register[1]
                                                )
                                        : null,
                                },
                            })
                            if (!img_fingerBio_register[0]) {
                                this.setState({
                                    step: REGISTER_FINGER_FIRST,
                                })
                            } else if (!img_fingerBio_register[1]) {
                                this.setState({
                                    step: REGISTER_FINGER_LAST,
                                })
                            }
                            if (
                                img_fingerBio_register[0] &&
                                img_fingerBio_register[1]
                            ) {
                                this.setState({
                                    step: CONFIRM_FINGER,
                                })
                            }
                        }
                        if (
                            biometricReducer[dataGetTicket.TicketID][
                                typeBioFinger
                            ]
                        ) {
                            const codeConfirm =
                                biometricReducer[dataGetTicket.TicketID][
                                    typeBioFinger
                                ][CONFIRM]
                            if (
                                codeConfirm === 0 &&
                                this.state.step !== SHOW_SUCCESS_FINGER
                            ) {
                                this.setState({
                                    step: SHOW_SUCCESS_FINGER,
                                    isLoad: false,
                                })
                                this.handleAutoConfirmTransaction()
                                return
                            }
                            if (
                                this.state.step === CONFIRM_FINGER &&
                                codeConfirm > CONFIRM_FINGER_FAIL
                            ) {
                                this.setState(
                                    {
                                        step: CONFIRM_FINGER_LOADING,
                                    },
                                    () => {
                                        this.setState({
                                            step: SHOW_ERR_FINGER_NOT_MATCHED,
                                            isLoad: false,
                                        })
                                    }
                                )
                            }
                            return
                        }
                    }
                }
            } else {
            }
        }
    }

    componentDidMount() {

        const { data, biometricReducer, counterReducer } = this.props
        const dataGetTicket = data.dataGetTicket
        const typeBioFinger = data.typeBio
        const checkBioRegister = /REGISTER/g.test(
            JSON.stringify(biometricReducer[dataGetTicket.TicketID])
        )
        const checkWaitting = /waitingConfirm/g.test(
            JSON.stringify(biometricReducer[dataGetTicket.TicketID])
        )
        let RoleTeller = false
        let RoleInbio = false
        if (counterReducer.dataLogin.Roles.includes(TELLER)) RoleTeller = true
        else if (counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR))
            RoleInbio = true

        if (biometricReducer[dataGetTicket.TicketID]) {
            if (!checkBioRegister && !checkWaitting) {
                this.setState({
                    step: REGISTER_FINGER_FIRST,
                    isRemoveFinger: true,
                    img_fingerBio: {
                        img_fingerBio_first: null,
                        img_fingerBio_last: null,
                    },
                })
            }
            if (
                checkWaitting &&
                !biometricReducer[dataGetTicket.TicketID][typeBioFinger]
            ) {
                const waitingCheck =
                    biometricReducer[dataGetTicket.TicketID].waitingConfirm
                if (waitingCheck.typeBio === data.typeBio) {
                    this.setState({
                        isLoading: {
                            load_finger_first: true,
                        },
                    })
                }
            } else if (
                checkWaitting &&
                biometricReducer[dataGetTicket.TicketID][typeBioFinger]
            ) {
                const waitingCheck =
                    biometricReducer[dataGetTicket.TicketID].waitingConfirm
                if (waitingCheck.typeBio === data.typeBio) {
                    const img_fingerBio_register =
                        biometricReducer[dataGetTicket.TicketID][typeBioFinger][
                            REGISTER
                        ]
                    this.setState({
                        isLoading: {
                            load_finger_last: true,
                        },
                        img_fingerBio: {
                            img_fingerBio_first: apiGetBioFile(
                                img_fingerBio_register[0]
                            )
                                ? RoleTeller
                                    ? apiGetBioFile(img_fingerBio_register[0])
                                    : RoleInbio
                                        ? apiGetBioFileCollector(
                                            img_fingerBio_register[0]
                                        )
                                        : this.props.flowUpdate &&
                                        RoleTeller &&
                                        apiGetBioFingerImage(
                                            img_fingerBio_register[0]
                                        )
                                : null,
                            img_fingerBio_last: apiGetBioFile(
                                img_fingerBio_register[1]
                            )
                                ? RoleTeller
                                    ? apiGetBioFile(img_fingerBio_register[1])
                                    : RoleInbio
                                        ? apiGetBioFileCollector(
                                            img_fingerBio_register[1]
                                        )
                                        : this.props.flowUpdate &&
                                        RoleTeller &&
                                        apiGetBioFingerImage(
                                            img_fingerBio_register[1]
                                        )
                                : null,
                        },
                    })
                }
            }
            if (biometricReducer[dataGetTicket.TicketID]) {
                if (this.state.step === CONFIRM_FINGER_LOADING_MATCHED) {
                    if (
                        biometricReducer[dataGetTicket.TicketID]
                            .waitingConfirm &&
                        biometricReducer[dataGetTicket.TicketID][CONFIRM]
                    )
                        return
                    const codeConfirm =
                        biometricReducer[dataGetTicket.TicketID][typeBioFinger][
                            CONFIRM
                        ]

                    if (codeConfirm > CONFIRM_FINGER_FAIL) {
                        this.setState({
                            step: SHOW_ERR_FINGER_MATCHED,
                        })
                        return
                    }
                    if (codeConfirm === 0) {
                        this.setState({
                            step: SHOW_SUCCESS_FINGER,
                        })
                        return
                    }
                }
                if (biometricReducer[dataGetTicket.TicketID].waitingConfirm) {
                    const loadingFingerID =
                        biometricReducer[dataGetTicket.TicketID].waitingConfirm
                            .typeRequestBio
                    if (
                        biometricReducer[dataGetTicket.TicketID].waitingConfirm
                            .typeBio === typeBioFinger
                    ) {
                        if (loadingFingerID === REGISTER) {
                            this.setState(
                                {
                                    step: REGISTER_FINGER_LOADING,
                                },
                                () => {
                                    setTimeout(() => {
                                        this.setState({
                                            isVisibleFinishBtn: true,
                                        })
                                    }, 1500)
                                }
                            )
                            return
                        }
                    }

                    if (this.state.step === CONFIRM_FINGER) {
                        this.setState({
                            step: CONFIRM_FINGER_LOADING_MATCHED,
                        })
                        return
                    }
                    if (
                        biometricReducer[dataGetTicket.TicketID].waitingConfirm
                            .typeRequestBio === CONFIRM &&
                        biometricReducer[dataGetTicket.TicketID].waitingConfirm
                            .typeBio === typeBioFinger
                    )
                        this.setState({
                            step: CONFIRM_FINGER_LOADING_MATCHED,
                        })
                } else {
                    if (
                        biometricReducer[dataGetTicket.TicketID][typeBioFinger]
                    ) {
                        const img_fingerBio_register =
                            biometricReducer[dataGetTicket.TicketID][
                                typeBioFinger
                            ][REGISTER]
                        if (
                            img_fingerBio_register &&
                            img_fingerBio_register.length === 1
                        ) {
                            this.setState({
                                step: REGISTER_FINGER_LAST,
                                isRemoveFinger: true,
                                img_fingerBio: {
                                    img_fingerBio_first: RoleTeller
                                        ? apiGetBioFile(
                                            img_fingerBio_register[0]
                                        )
                                        : RoleInbio
                                            ? apiGetBioFileCollector(
                                                img_fingerBio_register[0]
                                            )
                                            : this.props.flowUpdate &&
                                            RoleTeller &&
                                            apiGetBioFingerImage(
                                                img_fingerBio_register[0]
                                            ),

                                    img_fingerBio_last: img_fingerBio_register[1]
                                        ? RoleTeller
                                            ? apiGetBioFile(
                                                img_fingerBio_register[1]
                                            )
                                            : RoleInbio
                                                ? apiGetBioFileCollector(
                                                    img_fingerBio_register[1]
                                                )
                                                : this.props.flowUpdate &&
                                                RoleTeller &&
                                                apiGetBioFingerImage(
                                                    img_fingerBio_register[1]
                                                )
                                        : null,
                                },
                            })
                        }
                        if (
                            img_fingerBio_register &&
                            img_fingerBio_register.length === 2
                        ) {
                            this.setState({
                                isRemoveFinger: true,
                                img_fingerBio: {
                                    img_fingerBio_first: img_fingerBio_register[0]
                                        ? RoleTeller
                                            ? apiGetBioFile(
                                                img_fingerBio_register[0]
                                            )
                                            : RoleInbio
                                                ? apiGetBioFileCollector(
                                                    img_fingerBio_register[0]
                                                )
                                                : this.props.flowUpdate &&
                                                RoleTeller &&
                                                apiGetBioFingerImage(
                                                    img_fingerBio_register[0]
                                                )
                                        : null,

                                    img_fingerBio_last: img_fingerBio_register[1]
                                        ? RoleTeller
                                            ? apiGetBioFile(
                                                img_fingerBio_register[1]
                                            )
                                            : RoleInbio
                                                ? apiGetBioFileCollector(
                                                    img_fingerBio_register[1]
                                                )
                                                : this.props.flowUpdate &&
                                                RoleTeller &&
                                                apiGetBioFingerImage(
                                                    img_fingerBio_register[1]
                                                )
                                        : null,
                                },
                            })
                            if (!img_fingerBio_register[0]) {
                                this.setState({
                                    step: REGISTER_FINGER_FIRST,
                                })
                            } else if (!img_fingerBio_register[1]) {
                                this.setState({
                                    step: REGISTER_FINGER_LAST,
                                })
                            }
                            if (
                                img_fingerBio_register[0] &&
                                img_fingerBio_register[1]
                            ) {
                                this.setState({
                                    step: CONFIRM_FINGER,
                                })
                            }
                        }
                        if (
                            biometricReducer[dataGetTicket.TicketID][
                                typeBioFinger
                            ]
                        ) {
                            const codeConfirm =
                                biometricReducer[dataGetTicket.TicketID][
                                    typeBioFinger
                                ][CONFIRM]
                            if (
                                this.state.step === CONFIRM_FINGER &&
                                codeConfirm > CONFIRM_FINGER_FAIL
                            ) {
                                this.setState(
                                    {
                                        step: CONFIRM_FINGER_LOADING,
                                    },
                                    () => {
                                        this.setState({
                                            step: SHOW_ERR_FINGER_NOT_MATCHED,
                                        })
                                    }
                                )
                            }
                            return
                        }
                    }
                }
            } else {
            }
        }
    }

    renderError = (pos = 'first') => {
        const { data, biometricReducer } = this.props
        const dataGetTicket = data.dataGetTicket
        const typeBioFinger = data.typeBio
        const codeConfirm =
            biometricReducer[dataGetTicket.TicketID][typeBioFinger][CONFIRM]
        let content = ''
        switch (codeConfirm) {
        case 101:
            content = 'Cập nhật dữ liệu thất bại, vui lòng thử lại'
            break
        case 102:
            content =
                    'Dấu vân tay không đủ điều kiện để lấy mẫu, vui lòng thử lại'
            break
        case 103:
            content = 'Vị trí dấu vân tay không hợp lệ'
            break
        case 104:
            content = 'Dấu vân tay xác nhận của khách hàng không hợp lệ'
            break
        case 105:
            content = 'Không tìm thấy thông tin khách hàng trong hệ thống'
            break
        case 106:
            content = (
                <>
                    <div className="row">
                            Hệ thống không thể xử lý yêu cầu vào lúc này,
                    </div>
                    <div className="row">vui lòng thử lại</div>
                </>
            )
            break
        case 107:
            content = (
                <>
                    <div className="row">
                            Hệ thống không thể hiện thị dấu vân tay vào lúc này,
                    </div>
                    <div className="row">vui lòng thử lại</div>
                </>
            )
            break
        case 108:
            content =
                    'Lấy mẫu dấu vân tay không trùng khớp, vui lòng thử lại'
            break
        case 109:
            content = 'Không tìm thấy dấu vân tay trong hệ thống'
            break
        case 110:
            content = 'Kích thước dấu vân tay không đủ điều kiện lấy mẫu'
            break
        case 111:
            content = 'Hệ thống không thể xử lý dấu vân tay'
            break
        case 112: {
            const currentTicket = this.props.counterReducer.listCurrentTicket.find(
                (ticket) => ticket.TicketID === dataGetTicket.TicketID
            )
            const CustomerInfos = currentTicket.CustomerInfos
            content = (
                <>
                    <div className="row">
                            Khách hàng{' '}
                        <span className="target-data">
                            {CustomerInfos.IdNumber} - {CustomerInfos.Name}
                        </span>
                    </div>
                    <div className="row">đã tồn tại dấu vân tay</div>
                </>
            )
            break
        }
        case 113: {
            content = (
                <>
                    <div className="row">
                            Vân tay không đủ điều kiện lấy mẫu.
                    </div>
                    <div className="row">
                            Vui lòng chọn ngón khác để đăng ký
                    </div>
                </>
            )
            break
        }
        case 114: {
            const currentTicket = this.props.counterReducer.listCurrentTicket.find(
                (item) => item.TicketID === dataGetTicket.TicketID
            )
            const {
                cifs,
                // invalidFingerPrint,
                bioType,
            } = currentTicket.duplicateFingers
            if (!cifs) {
                content = (
                    <>
                        <div className="row">Dấu vân tay đang lấy </div>
                        <div className="row">
                                bị trùng với dữ liệu trong hệ thống
                        </div>
                    </>
                )
                break
            }

            if (
                cifs.CustomerNumber ===
                    currentTicket.CustomerInfos.CustomerNumber
            ) {
                if (!bioType) {
                    content = (
                        <>
                            <div className="row">Dấu vân tay đang lấy</div>
                            <div className="row">
                                    bị trùng với dấu vân tay của quý khách
                            </div>
                            <div className="row">
                                    đã thực hiện lấy mẫu trước đó
                            </div>
                        </>
                    )
                    break
                }
                content = (
                    <>
                        <div className="row">
                                Dấu vân tay đang lấy bị trùng với dấu vân tay
                        </div>
                        <div className="row">
                            <span className="target-data">
                                {nameFingerPrint[bioType].nameInVerify}
                            </span>{' '}
                                của quý khách
                        </div>
                        <div className="row">
                                đã thực hiện lấy mẫu trước đó
                        </div>
                    </>
                )
                break
            }
            if (!bioType) {
                content = (
                    <>
                        <div className="row">
                                Dấu vân tay đang lấy bị trùng với dấu vân tay
                        </div>
                        <div className="row">
                                của khách hàng{' '}
                            <span>
                                {cifs.CustomerNumber} -{' '}
                                {cifs.FullNameVi || cifs.Name}
                            </span>
                        </div>
                        <div className="row">đang thực hiện lấy mẫu</div>
                    </>
                )
                break
            }
            content = (
                <>
                    <div class="row">
                            Dấu vân tay đang lấy bị trùng với dấu vân tay
                    </div>
                    <div class="row">
                        <span className="target-data">
                            {nameFingerPrint[bioType].nameInVerify}
                        </span>{' '}
                            của khách hàng
                    </div>
                    <div class="row">
                        <span className="target-data">
                            {cifs.CustomerNumber} -{' '}
                            {cifs.FullNameVi || cifs.Name}
                        </span>
                    </div>
                    <div class="row">đang thực hiện lấy mẫu</div>
                </>
            )
            break
        }
        default:
            content = <>Lỗi không xác định</>
            break
        }
        if (!content) return null
        return (
            <CheckConfirmFinger
                success={false}
                bg_color="#005993"
                content={<ContentError>{content}</ContentError>}
                btnName="Xác nhận"
                onSubmit={() =>
                    pos === 'first'
                        ? this.acceptFailFinger()
                        : this.actionFailTryAgain()
                }
            />
        )

    }

    handleClosePopup = () => {
        const { biometricReducer } = this.props
        const { dataGetTicket } = this.props.data
        if (
            Object.keys(biometricReducer).includes(dataGetTicket.TicketID) &&
            Object.keys(biometricReducer[dataGetTicket.TicketID]).includes(
                'waitingConfirm'
            )
        )
            this.finishActionBio(dataGetTicket.TicketID)
        this.props.onClose()
    }

    handleClosingGetBio = () => {
        this.setState(
            {
                closingGetBio: true,
            },
            () => {
                setTimeout(() => {
                    this.finishActionBio()
                }, 5000)
            }
        )
    }

    render() {
        const { step } = this.state
        return (
            <Bound
                id="popup_register-finger"
                posLeft={this.props.data.posLeft}
                step={step}
            >
                {step === SHOW_ERR_FINGER_NOT_MATCHED ? (
                    this.renderError()
                ) : (
                    <div className="mainContainer">
                        <div className="popup-header">
                            <div className="wrap">
                                <div className="popup-header__title">
                                    {
                                        nameFingerPrint[
                                            this.props.data &&
                                            this.props.data.typeBio
                                        ].name
                                    }
                                </div>
                            </div>
                            <img
                                src={icClose}
                                alt="close"
                                onClick={this.handleClosePopup}
                            />
                        </div>
                        {step === CONFIRM_FINGER_LOADING_MATCHED ? (
                            <WaittingConfirmFinger
                                title="Đang lấy hình ảnh vân tay..."
                                actionName="Hủy bỏ"
                                handleAction={() => {
                                    this.finishActionBio()
                                }}
                            />
                        ) : step === SHOW_SUCCESS_FINGER ? (
                            <CheckConfirmFinger
                                success={true}
                                bg_color="#0fb11f"
                                content="Đăng ký vân tay thành công"
                                btnName="Hoàn tất"
                                onSubmit={() => {
                                    this.props.onClose()
                                    this.props.closePopup(2)
                                }}
                            />
                        ) : step === SHOW_ERR_FINGER_MATCHED ? (
                            this.renderError('last')
                        ) : (
                            <React.Fragment>
                                <div className="finger">
                                    <FingerControl
                                        isRemoveFinger={
                                            this.state.isRemoveFinger
                                        }
                                        img_fingerBio={
                                            this.state.img_fingerBio
                                                .img_fingerBio_first
                                        }
                                        dataFingerID={{
                                            ...this.props.data,
                                            stepRegister: REGISTER_FINGER_FIRST,
                                        }}
                                        dataMockup={fingerID[0]}
                                        registerFingerID={
                                            step === REGISTER_FINGER_FIRST
                                                ? () =>
                                                    this.registerFingerID(
                                                        FirstCheck
                                                    )
                                                : null
                                        }
                                        isLoading={
                                            this.state.isLoading
                                                .load_finger_first
                                        }
                                        removeFingerID={() => {
                                            this.requestRemoveBioData(
                                                FirstCheck
                                            )
                                        }}
                                    />
                                    <FingerControl
                                        isRemoveFinger={
                                            this.state.isRemoveFinger
                                        }
                                        img_fingerBio={
                                            this.state.img_fingerBio
                                                .img_fingerBio_last
                                        }
                                        dataFingerID={{
                                            ...this.props.data,
                                            stepRegister:
                                                step === REGISTER_FINGER_LAST
                                                    ? REGISTER_FINGER_LAST
                                                    : null,
                                        }}
                                        dataMockup={fingerID[1]}
                                        registerFingerID={
                                            step === REGISTER_FINGER_LAST
                                                ? () =>
                                                    this.registerFingerID(
                                                        LastCheck
                                                    )
                                                : null
                                        }
                                        isLoading={
                                            this.state.isLoading
                                                .load_finger_last
                                        }
                                        removeFingerID={() => {
                                            this.requestRemoveBioData(LastCheck)
                                        }}
                                        finishRegister={() =>
                                            this.finishRegister()
                                        }
                                    />
                                </div>
                                <div className="stepActions">
                                    {step === REGISTER_FINGER_FIRST && (
                                        <div className="content_info">
                                            <p>
                                                Bấm vào hình ảnh vân tay số 1 để
                                                bắt đầu
                                            </p>
                                        </div>
                                    )}
                                    {step === REGISTER_FINGER_LOADING ? (
                                        this.state.closingGetBio ? (
                                            <div className="closing-get-bio">
                                                <LoadingControl
                                                    size="25px"
                                                    loadingPage={false}
                                                />
                                                Vui lòng chờ...
                                            </div>
                                        ) : this.state.isVisibleFinishBtn ? (
                                            <div
                                                className="close_register"
                                                onClick={
                                                    this.handleClosingGetBio
                                                }
                                            >
                                                <p>Kết thúc</p>
                                            </div>
                                        ) : (
                                            <div className="close_register">
                                                <p></p>
                                            </div>
                                        )
                                    ) : null}
                                    {step === REGISTER_FINGER_LAST && (
                                        <div className="content_info">
                                            <p>
                                                Xin mời đăng ký hình ảnh vân tay
                                                số 2...
                                            </p>
                                        </div>
                                    )}
                                    {this.state.isLoad === true ? (
                                        <div className="loading_control">
                                            <LoadingControl
                                                size="30px"
                                                loadingPage={false}
                                            />
                                            <p>Đang kiểm tra...</p>
                                        </div>
                                    ) : step === CONFIRM_FINGER &&
                                        this.state.isLoad === false ? (
                                            <div className="btn_accept">
                                                <ButtonConfirmControl
                                                    onSubmit={() =>
                                                        this.requestConfirmBioData()
                                                    }
                                                    bg_color="#005993"
                                                    titleConfirm="xác thực"
                                                />
                                            </div>
                                        ) : null}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                )}
            </Bound>
        )
    }
}

const mappStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
})

const mapDispatchToProps = (dispatch) => ({
    openPopup: (type, levelPopup, data) =>
        dispatch(openPopup(type, levelPopup, data)),
    closePopup: (levelPopup) => dispatch(openPopup, closePopup(levelPopup)),
    requestGetBioData: (dataRequest) =>
        dispatch(requestGetBioData(dataRequest)),
    requestConfirmBioData: (enrollId, bioType, ticketId) =>
        dispatch(requestConfirmBioData(enrollId, bioType, ticketId)),
    requestRemoveBioData: (
        enrollId,
        bioType,
        bioId,
        ticketId,
        index = undefined
    ) =>
        dispatch(
            requestRemoveBioData(enrollId, bioType, bioId, ticketId, index)
        ),
    resetConfirmBioData: (ticketId, bioType) =>
        dispatch(resetConfirmBioData(ticketId, bioType)),
    removeStatusWaitingBioData: (ticketId, justDispatch = false) =>
        dispatch(removeStatusWaitingBioData(ticketId, justDispatch)),
    removeStatusWaitingBioDataCollector: (ticketId, justDispatch = false) =>
        dispatch(removeStatusWaitingBioDataCollector(ticketId, justDispatch)),
    requestEnroll: (ticketId, cusInfo) =>
        dispatch(requestEnroll(ticketId, cusInfo)),
    fingerAuthTrans: (ticketId, enrollId, bioType, isAuthenticate) =>
        dispatch(
            updateFingerInfoInRegisterThread(
                ticketId,
                enrollId,
                bioType,
                isAuthenticate
            )
        ),
    //// flowUpdate
    requestUpDateBio: (ticketId, cusNumber) =>
        dispatch(requestUpDateBio(ticketId, cusNumber)),
    ////////////// BioCollector
    requestEnrollCollector: (cifs, ticketId) =>
        dispatch(requestEnrollCollector(cifs, ticketId)),
    requestGetBioDataCollector: (dataRequest, ticketId) =>
        dispatch(requestGetBioDataCollector(dataRequest, ticketId)),
    requestConfirmBioDataCollector: (enrollId, bioType, ticketId) =>
        dispatch(requestConfirmBioDataCollector(enrollId, bioType, ticketId)),
    removeBioDataCollector: (
        enrollId,
        bioType,
        bioId,
        ticketId,
        index = undefined
    ) =>
        dispatch(
            removeBioDataCollector(enrollId, bioType, bioId, ticketId, index)
        ),
    setAuthenticateFingerCollector: (
        enrollId,
        ticketId,
        bioType,
        isAuthenticate
    ) =>
        dispatch(
            setAuthenticateFingerCollector(
                enrollId,
                ticketId,
                bioType,
                isAuthenticate
            )
        ),
    requestUpDateBioCollector: (ticketId, cusNumber) =>
        dispatch(requestUpDateBioCollector(ticketId, cusNumber)),
    clearError: (typeError) => dispatch(clearError(typeError)),
})

export default connect(mappStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(PopupRegisterFingerIDControl)
