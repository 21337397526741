import React, { Component } from 'react'
import styled from "styled-components";
import { connect } from 'react-redux'

// import image
// import icLoading from "../../images/ic_loading.svg";
import icUnNotification from "../../images/ic_un_notification.svg"

// import data
import { /*notices,*/ typeNotice } from "../../data/data";

// import component
import LoadingControl from "../Control/LoadingControl";
const widthPopup = 280;
const paddingIConBell = 5;

const StyledPopupShowNotice = styled.div`
  width: ${widthPopup}px;  
  /* background: aqua; */
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left + widthPopup + paddingIConBell}px;
  /*right: ${props => props.right}px;*/
  transform: translate(-100%, 0);
  box-shadow: rgba(0, 0, 0, 0.3) 4px 4px 20px 0px;
  /*height: 368px;*/
  .notice-header {
    margin-bottom: 13px;
    div {
      margin-left: 12px;
      padding-top: 16px;
      font-family: 'SVN-Gilroy';
      font-size: 16px;
      font-weight: bold;
      line-height: 1.19;
      color: #222222;
    }
  }

  .notice-content {
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: scroll;
    height: ${props => props.heightPopup || "250px"};
    /*min-height: 250px;*/

    ::-webkit-scrollbar {
      width: 3px;
      visibility: hidden;
    }

    ::-webkit-scrollbar-thumb {
      background: #c4c4c4; 
      border-radius: 2px;
    }


    .notice-item {
      border-bottom: 0.3px solid #c8c8c8;
      padding-top: 16px;

      &  span, & > .beyond-time {
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;
        line-height: 1.68;
      }

      .pass-ticket {
        span:nth-child(1) {
          color: #005993;
        }
        span:nth-child(2){
          color: #979797;
        }
        span:nth-child(3){
          color: #222222;
        }
      }

      .beyond-time {
        color: #d71249;
      }

      .time {
        font-family: 'SVN-Gilroy';
        font-size: 11px;
        font-weight: 500;
        line-height: 1.3;
        color: #979797;
        margin-top: 8px;
        margin-bottom: 4.8px;
        text-align: right;
      }

      
    }

    .notice-item:first-child {
        padding: 0;
    }

    .notice-content-empty {
      img {
        width: 72px;
        height: 72px;
        display: block;
        margin: 0 auto;
      }

      div {
        margin-top: 15px;
        text-align: center;
        font-family: "SVN-Gilroy";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        line-height: 1.25;
        color: #d6d6d6;
        padding-left: 36px;
        padding-right: 36px;
        padding-bottom: 35px;
      }
    }
    
    .loading {
      width: 100%;
      padding-top: 16px;
      padding-bottom: 8px;
      background: white;
      display: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      text-align: center;
      /*img {
        display: block;
        margin: 0 auto;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% { transform: rotate(360deg); }
        100% { transform: rotate(0deg); }
      }*/
    }
  }
`;

class PopupShowNotice extends Component {
    constructor(props) {
        super(props)

        this.state = {
            notices: []
        }
        this._isMounted = false;
    }

    handleEventScroll = (e) => {
        e.preventDefault();
        let parentDiv = e.target
        const imgLoading = document.querySelector(".loading");
        if (parentDiv.scrollTop + parentDiv.clientHeight >= parentDiv.scrollHeight) {
            imgLoading.style.display = "block"
            parentDiv.scrollTop = parentDiv.scrollHeight;
            // debugger;
            const newNotice = this.props.notifyReducer.data.slice(this.state.notices.length, this.state.notices.length + 3)
            // const newNotice = notices;
            // debugger;
            this.timeOut = setTimeout(() => {
                this._isMounted && this.setState(
                    prevStates =>
                        ({
                            notices: [...prevStates.notices, ...newNotice]
                        }),
                    () => {
                        if (!newNotice.length) {
                            document.querySelector(".notice-content").removeEventListener("scroll", this.handleEventScroll)
                        }
                        imgLoading.style.display = "none"
                    }
                )
            }, 2000);
        }
    // else{
    //   imgLoading.style.display = "none"
    // }
    }

    render() {
        return (
            <div
                style={{
                    // background: "#ffffff",
                    width: "100%",
                    height: "100%",
                    zIndex: 9999
                }}
            >
                <StyledPopupShowNotice
                    top={this.props.locationComponent.top}
                    left={this.props.locationComponent.left}
                    //right={this.props.locationComponent.right}
                    heightPopup={this.state.notices.length !== 0 ? "250px" : "unset"}
                >
                    <div className="notice-header">
                        <div>Thông báo</div>
                    </div>
                    <div className="notice-content">
                        {/*{this.props.notifyReducer.data.map((notice, key) => */}
                        {
                            this.state.notices.length !== 0 ?
                                this.state.notices.map((notice, key) =>
                                    notice.type === typeNotice.passTicket ?
                                        (
                                            <div className="notice-item" key={key}>
                                                <div className="pass-ticket">
                                                    <span>{`${notice.name} (Quầy số ${notice.counterNum})`}</span> <span>chuyển vé giao dịch {notice.ticketNumber}: </span><span>“{`${notice.content}`}"</span>
                                                </div>
                                                <div className="time">{notice.time}</div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="notice-item" key={key}>
                                                <div className="beyond-time">
                                                    {`Vé số ${notice.ticketNum} hiện đang quá thời gian chờ quy định`}
                                                </div>
                                                <div className="time">{notice.time}</div>
                                            </div>
                                        )
                                )
                                :
                                (
                                    <div className="notice-content-empty">
                                        <img src={icUnNotification} alt="unnotification" />
                                        <div>Chưa có vé được chuyển đến quầy</div>
                                    </div>
                                )
                        }
                        <div className="loading">
                            {/*<img src={icLoading} alt="loading" />*/}
                            <LoadingControl
                                size={25} loadingPage={false} />
                        </div>
                    </div>

                </StyledPopupShowNotice>
            </div>

        )
    }

    // handleResize = function (e) {
    //   console.log(this)
    //   console.log(e);
    // }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            notices: this.props.notifyReducer.data.slice(0, 5)
            // notices
        })
        // console.log(this.props)
        document.querySelector(".notice-content").addEventListener("scroll", this.handleEventScroll)
    // window.addEventListener("resize", this.handleResize)
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.querySelector(".notice-content").removeEventListener("scroll", this.handleEventScroll)
        // window.removeEventListener("resize", this.handleResize)
        clearTimeout(this.timeOut)
    }


}

const mapStateToProps = state => ({
    notifyReducer: state.notifyReducer
})

export default connect(
    mapStateToProps
)(PopupShowNotice)
