import React, { Component } from 'react'
import moment from "moment";
import styled from 'styled-components'

// image
import ic_question from '../../../images/ic_counter_notification_question.svg'

// component
import ButtonConfirmControl from '../../Control/ButtonConfirmControl';

const Bound = styled.div`
    padding: ${props => props.paddingPopup || '20px 32px 15px 32px'};
    background-color: #fafafa;
    width: ${props => props.width || '220px'};
    height: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-40%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    z-index: 999;
    border-radius: ${props => props.borderRadius || '10px'};
    box-shadow: 0px 0px 10px 0px #0000008a;
    animation: fadeInConfirm .6s;
    @keyframes fadeInConfirm {
        0%   { opacity:.7; top: 25% }
        100% { opacity:1; top: 40% }
    }
    .row_question{
        display:flex;
        align-items: center;
        justify-content: center;
        .img_question{
            background-image: url(${ic_question});
            background-position:center center;
            background-repeat: no-repeat;
            background-size:cover;
            width: 52px;
            height: 52px;
            object-fit: contain;
        }
    }
    .row_title{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0 0;
        text-align:center;
        flex-direction:column;
        font-size: 16px;
        .title{
            line-height: 1.13;
            color: #222222;
            text-align: center;
        }
        b {
            width: 100%;
            overflow:hidden;
            text-align: center;
            /*display: flex;*/
            /*justify-content: center;*/
        }
        .contentAlert{
            font-size: ${props => props.fontSizeNote || '14px'};
            font-weight: 500;
            line-height: 1.2;
            color: ${props => props.colorContent || '#f31919'};
            text-align: center;
            margin: 12px 0 0 0;
            text-align: center;
    }
    }
    .row_btn_confirm{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0 0 0;
        .btn_exit{
            margin: 0 20px 0 0;
        }
    }
`;

const WAIT_TIME_IN_SECONDS = 30
export default class PopupConfirm extends Component {
    state = {
        time: ""
    }
    componentDidMount() {
        if(this.props.data.callAt) {
            const nowTime = moment();
            const timeCall = moment(this.props.data.callAt);
            const duration = moment.duration(nowTime.diff(timeCall));
            const hrs = duration.hours();
            const mins = duration.minutes();
            const secs = duration.seconds();
            let range = WAIT_TIME_IN_SECONDS - secs;
            if(hrs > 0 || mins > 0 || range <= 0) {
                this.setState({time: 0})
                return;
            }
            this.setState({time: range})
            const setTimeOutCall = this.setTimeOutCall = setInterval(() => {
                const range = this.state.time - 1;
                if(range <= 0) {
                    this.setState({time: 0})
                    clearInterval(setTimeOutCall);
                    return;
                }
                this.setState({time: range})
            }, 1000)
        }
    }

    componentWillUnmount() {
        clearInterval(this.setTimeOutCall)
    }

    render() {
        const { data } = this.props
        return (
            <Bound
                colorContent={this.props.colorContent || this.props.data.colorContent}
                width={this.props.width}
                borderRadius={this.props.borderRadius}
                fontSizeNote={this.props.fontSizeNote}
                paddingPopup={this.props.data.paddingPopup}
            >
                <div className="row_question">
                    <span className='img_question'></span>
                </div>
                <div className="row_title">
                    <p className='title'>{this.props.title}</p>
                    <b>
                        {
                            this.props.nameData ?
                                `"${this.props.nameData}"`
                                : this.props.content
                        }
                    </b>
                    <p className='contentAlert'>
                        {
                            this.props.data.callAt?
                                `${this.props.contentDelete} ${this.state.time}`
                                :
                                this.props.contentDelete
                        }
                    </p>
                </div>
                <p></p>
                <div className="row_btn_confirm">
                    <div className="btn_exit">
                        <ButtonConfirmControl
                            titleConfirm={
                                this.props.btnLeft ?
                                    this.props.btnLeft.title
                                    :
                                    'HỦY BỎ'
                            }
                            bg_color={
                                this.props.bg_color_close ?
                                    this.props.bg_color_close
                                    :
                                    this.props.btnLeft &&
                                        this.props.btnLeft.bgColor ?
                                        this.props.btnLeft.bgColor
                                        :
                                        '#d71249'
                            }
                            fontSize={
                                this.props.btnLeft ?
                                    this.props.btnLeft.fontSize
                                    :
                                    null
                            }
                            onSubmit={() => { this.props.cancelAction() }}
                        />
                    </div>
                    <div className="btn_accept">
                        <ButtonConfirmControl
                            titleConfirm={
                                this.props.btnRight ?
                                    this.props.btnRight.title
                                    :
                                    'XÁC NHẬN'
                            }
                            bg_color={
                                this.props.data.callAt?
                                    this.state.time === "" || 
                                        this.state.time > 0?
                                        "rgba(0, 89, 147, 0.5)"
                                        :
                                        '#005993'
                                    :
                                    this.props.btnRight &&
                                        this.props.btnRight.bgColor ?
                                        this.props.btnRight.bgColor
                                        :
                                        '#005993'
                            }
                            fontSize={
                                this.props.btnRight ?
                                    this.props.btnRight.fontSize
                                    :
                                    null
                            }
                            onSubmit={() => { 
                                if(this.props.data.callAt &&
                                    (this.state.time === "" || 
                                        this.state.time > 0))
                                    return;
                                this.props.acceptAction(data) 
                            }}
                        />
                    </div>
                </div>
            </Bound>
        )
    }

}
