// import unregister_finger from "../images/unregister-finger.svg";
import btn_fingerPrint from '../images/btn_fingerPrint.svg'
import fingerprint from '../images/fingerprint.svg'
import btn_Misscall from '../images/btn_Misscall.svg'
import btn_Recall from '../images/btn_Recall.svg'
import btn_TranferTicket from '../images/btn_TranferTicket.svg'
///////// finger
import finger_white from '../images/finger_white.svg'
import finger_success from '../images/finger_green.svg'
import finger_confirm from '../images/finger_Success.svg'

export const dataFunction = [
    {
        type: 0,
        content: 'Đã có STH',
    },
    {
        type: 1,
        content: 'Cập nhật',
    },
    {
        type: 2,
        content: 'Xóa',
    },
    {
        type: 3,
        content: 'Đăng ký mới',
    },
]

export const transaction = [
    {
        id: 1,
        name: 'Nộp tiền',
    },
    {
        id: 2,
        name: 'Rút tiền mặt',
    },
    {
        id: 3,
        name: 'Gửi tiết kiệm',
    },
    {
        id: 4,
        name: 'Nộp tiền vào tài khoản ngân hàng khác',
    },
    {
        id: 5,
        name: 'Chuyển khoản trong hệ thống',
    },
    {
        id: 6,
        name: 'Chuyển khoản ngoài hệ thống thông thường',
    },
    {
        id: 7,
        name: 'Chuyển khoản ngoài hệ thống nhanh đến số thẻ',
    },
    {
        id: 8,
        name: 'Chuyển khoản ngoài hệ thống nhanh đến số tài khoản',
    },
    {
        id: 9,
        name: 'Giao dịch ngoại tệ',
    },
    {
        id: 10,
        name: 'Thẻ',
    },
    {
        id: 11,
        name: 'Giao dịch khác',
    },
]

export const priorType = [
    {
        type: '0',
        name: 'Khách thông thường',
    },
    {
        type: '1',
        name: 'KHUT Kim Cương',
    },
    {
        type: '2',
        name: 'KHUT Vàng',
    },
    {
        type: '3',
        name: 'KHUT Bạc',
    },
    {
        type: '4',
        name: 'KHUT Đồng',
    },
]

export const popupType = {
    configCouterLogin: 0,
    configCouterSuccess: 1,
    configCouterFail: 2,
    passTicket: 3,
    fingerCheckFail: 4,
    fingerPrintCheck: 5,
    showNotice: 6,
    configAccept: 7,
    fingerPrintInfo: 8,
    configHistoryCustomer: 9,
    configNotRegister: 10,
    configCheckCustomer: 11,
    configCheck: 12,
    configConfirm: 13,
}

export const fingerID = [
    {
        img_finger: fingerprint,
        finger: 'Hình ảnh 1',
        loading: 'Đang lấy vân tay...',
    },
    {
        img_finger: fingerprint,
        finger: 'Hình ảnh 2',
        loading: 'Đang lấy vân tay...',
    },
]
export const biometricID = [
    {
        img_finger: finger_white,
        img_finger_success: finger_success,
        img_finger_confirm: finger_confirm,
    },
]

export const biometrictFingerDataCollectorInQueue = [
    // {
    //   typeFinger: "FACE",
    //   bottom: "82",
    //   left: "24"
    // },
    {
        typeFinger: 'THUMB_LEFT',
        bottom: '82',
        left: '24',
    },
    {
        typeFinger: 'INDEX_LEFT',
        bottom: '205',
        left: '57',
    },
    {
        typeFinger: 'MIDDLE_LEFT',
        bottom: '232',
        left: '91',
    },
    {
        typeFinger: 'RING_LEFT',
        bottom: '231',
        left: '145',
    },
    {
        typeFinger: 'PINKY_LEFT',
        bottom: '207',
        left: '200',
    },
    {
        typeFinger: 'THUMB_RIGHT',
        bottom: '83',
        right: '44',
    },
    {
        typeFinger: 'INDEX_RIGHT',
        bottom: '205',
        right: '73',
    },
    {
        typeFinger: 'MIDDLE_RIGHT',
        bottom: '232',
        right: '109',
    },
    {
        typeFinger: 'RING_RIGHT',
        bottom: '231',
        right: '163',
    },
    {
        typeFinger: 'PINKY_RIGHT',
        bottom: '207',
        right: '218',
    },
]

export const biometrictFingerData = [
    {
        typeFinger: 'THUMB_LEFT',
        bottom: '116',
        left: '38',
    },
    {
        typeFinger: 'INDEX_LEFT',
        bottom: '300',
        left: '88',
    },
    {
        typeFinger: 'MIDDLE_LEFT',
        bottom: '338',
        left: '140',
    },
    {
        typeFinger: 'RING_LEFT',
        bottom: '337',
        left: '220',
    },
    {
        typeFinger: 'PINKY_LEFT',
        bottom: '303',
        left: '302',
    },
    {
        typeFinger: 'PINKY_RIGHT',
        bottom: '302',
        // right: "39"
        left: '432',
    },
    {
        typeFinger: 'RING_RIGHT',
        bottom: '336',
        // right: "76"
        left: '514',
    },
    {
        typeFinger: 'MIDDLE_RIGHT',
        bottom: '338',
        // right: "117"
        left: '592',
    },
    {
        typeFinger: 'INDEX_RIGHT',
        bottom: '299',
        // right: "180"
        left: '646',
    },
    {
        typeFinger: 'THUMB_RIGHT',
        bottom: '116',
        // right: "243"
        left: '692',
    },
]

export const biometrictFingerDataUpdate = [
    {
        typeFinger: 'INDEX_LEFT',
        bottom: '186',
        left: '229',
    },
    {
        typeFinger: 'THUMB_LEFT',
        bottom: '73',
        left: '198',
    },
    {
        typeFinger: 'MIDDLE_LEFT',
        bottom: '211',
        left: '262',
    },
    {
        typeFinger: 'RING_LEFT',
        bottom: '210',
        left: '310',
    },
    {
        typeFinger: 'PINKY_LEFT',
        bottom: '188',
        left: '361',
    },
    {
        typeFinger: 'INDEX_RIGHT',
        bottom: '186',
        right: '126',
    },
    {
        typeFinger: 'THUMB_RIGHT',
        bottom: '74',
        right: '96',
    },
    {
        typeFinger: 'MIDDLE_RIGHT',
        bottom: '211',
        right: '158',
    },
    {
        typeFinger: 'RING_RIGHT',
        bottom: '209',
        right: '207',
    },
    {
        typeFinger: 'PINKY_RIGHT',
        bottom: '188',
        right: '258',
    },
]

export const biometrictFingerDataHistory = [
    {
        typeFinger: 'THUMB_LEFT',
        bottom: '62',
        left: '116',
    },
    {
        typeFinger: 'INDEX_LEFT',
        bottom: '175',
        left: '146',
    },
    {
        typeFinger: 'MIDDLE_LEFT',
        bottom: '200',
        left: '179',
    },
    {
        typeFinger: 'RING_LEFT',
        bottom: '198',
        left: '228',
    },
    {
        typeFinger: 'PINKY_LEFT',
        bottom: '176',
        left: '281',
    },
    {
        typeFinger: 'THUMB_RIGHT',
        bottom: '61',
        right: '14',
    },
    {
        typeFinger: 'INDEX_RIGHT',
        bottom: '176',
        right: '44',
    },
    {
        typeFinger: 'MIDDLE_RIGHT',
        bottom: '199',
        right: '77',
    },
    {
        typeFinger: 'RING_RIGHT',
        bottom: '199',
        right: '126',
    },
    {
        typeFinger: 'PINKY_RIGHT',
        bottom: '177',
        right: '177',
    },
]

export const followInform = [
    {
        type: 1,
        content: 'Xoá',
        color: 'var(--vermillion)',
    },
    {
        type: 2,
        content: 'Hoạt động',
        color: '#0FB11F',
    },
    {
        type: 4,
        content: 'Đóng',
        color: '#FF5815',
    },
    {
        type: 3,
        content: 'Treo',
        color: '#E9A011',
    },
]
export const listCounter = [
    {
        id: 2,
        name: 'Nguyễn Thanh Hoa',
    },
    {
        id: 2,
        name: 'Nguyễn Thanh Hoa',
    },
    {
        id: 2,
        name: 'Nguyễn Thanh Hoa',
    },
    {
        id: 2,
        name: 'Nguyễn Thanh Hoa',
    },
]

export const listReasonPassTicket = [
    'Quá hạn mức giao dịch',
    'Khách hàng muốn làm giao dịch khác',
    // "Lý do khác"
]
export const listBankCustomer = ['KH ngân hàng', 'KH nội bộ']
export const listReasonNotRegisterBioDatas = [
    'Khách hàng không đồng ý cung cấp thông tin sinh trắc học',
    'Khách hàng đăng ký vào lần giao dịch tiếp theo',
    'Khác',
]
export const listReasonNotRegisterFaceID = [
    'Khách hàng không có nhận dạng khuôn mặt rõ ràng',
    'Khách hàng không muốn đăng ký khuôn mặt',
    'Lý do khác',
]
export const listReasonNotRegisterFingerID = [
    'Khách hàng không đồng ý đăng ký',
    'Khách hàng không có dấu vân tay',
    'Khách hàng đăng ký vào lần giao dịch tiếp theo',
    'Khác',
]
export const taskControlButton = {
    fingerPrintBtn: {
        icon: btn_fingerPrint,
        tooltip: 'Xác thực vân tay',
        key: 'finger-print',
        widthTitle: 50,
    },
    missCallBtn: {
        icon: btn_Misscall,
        tooltip: 'Chuyển vé vào gọi nhỡ',
        key: 'miss-call',
        widthTitle: 50,
    },
    reCallBtn: {
        icon: btn_Recall,
        tooltip: 'Gọi lại',
        key: 're-call',
        widthTitle: 60,
    },
    tranferTicketBtn: {
        icon: btn_TranferTicket,
        tooltip: 'Chuyển quầy',
        key: 'tranfer-ticket',
        widthTitle: 50,
    },
}

export const typeNotice = {
    passTicket: 'chuyển vé',
    beyondTime: 'quá thời gian chờ',
}

export const notices = [
    {
        type: 'chuyển vé',
        name: 'Nguyễn Vân Anh',
        counterNum: 4,
        content: 'Quá hạn mức giao dịch cho phép tại quầy',
    },
    {
        type: 'quá thời gian chờ',
        ticketNum: 202,
    },
    {
        type: 'chuyển vé',
        name: 'Nguyễn Vân Anh',
        counterNum: 4,
        content: 'Quá hạn mức giao dịch cho phép tại quầy',
    },
    {
        type: 'quá thời gian chờ',
        ticketNum: 202,
    },
    {
        type: 'chuyển vé',
        name: 'Nguyễn Vân Anh',
        counterNum: 4,
        content: 'Quá hạn mức giao dịch cho phép tại quầy',
    },
    {
        type: 'quá thời gian chờ',
        ticketNum: 202,
    },
    {
        type: 'chuyển vé',
        name: 'Nguyễn Vân Anh',
        counterNum: 4,
        content: 'Quá hạn mức giao dịch cho phép tại quầy',
    },
    {
        type: 'quá thời gian chờ',
        ticketNum: 202,
    },
    {
        type: 'chuyển vé',
        name: 'Nguyễn Vân Anh',
        counterNum: 4,
        content: 'Quá hạn mức giao dịch cho phép tại quầy',
    },
    {
        type: 'quá thời gian chờ',
        ticketNum: 202,
    },
    {
        type: 'chuyển vé',
        name: 'Nguyễn Vân Anh',
        counterNum: 4,
        content: 'Quá hạn mức giao dịch cho phép tại quầy',
    },
    {
        type: 'quá thời gian chờ',
        ticketNum: 202,
    },
]

export const histories = [
    {
        typeAction: 'register',
        typeBiometric: 'đã đăng ký dữ liệu sinh trắc học.',
        name: 'Nguyễn Vân Anh',
        atOffice: 'PGD Bến Thành',
    },
    {
        typeAction: 'unlock',
        typeBiometric: 'đã mở khóa dữ liệu sinh trắc học.',
        name: 'Nguyễn Vân Anh',
        atOffice: 'PGD Bến Thành',
    },
    {
        typeAction: 'lock',
        typeBiometric: 'đã khóa dữ liệu sinh trắc học.',
        name: 'Nguyễn Vân Anh',
        atOffice: 'PGD Bến Thành',
    },
    {
        typeAction: 'remove',
        typeBiometric: 'đã xóa dữ liệu sinh trắc học.',
        name: 'Nguyễn Vân Anh',
        atOffice: 'PGD Bến Thành',
    },
    {
        typeAction: 'update',
        typeBiometric: 'đã cập nhật dữ liệu sinh trắc học.',
        name: 'Nguyễn Vân Anh',
        atOffice: 'PGD Bến Thành',
    },
    {
        typeAction: 'unlock',
        typeBiometric: 'đã mở khóa dữ liệu sinh trắc học.',
        name: 'Nguyễn Vân Anh',
        atOffice: 'PGD Bến Thành',
    },
    {
        typeAction: 'lock',
        typeBiometric: 'đã khóa dữ liệu sinh trắc học.',
        name: 'Nguyễn Vân Anh',
        atOffice: 'PGD Bến Thành',
    },
    {
        typeAction: 'remove',
        typeBiometric: 'đã xóa dữ liệu sinh trắc học.',
        name: 'Nguyễn Vân Anh',
        atOffice: 'PGD Bến Thành',
    },
    {
        typeAction: 'update',
        typeBiometric: 'đã cập nhật dữ liệu sinh trắc học.',
        name: 'Nguyễn Vân Anh',
        atOffice: 'PGD Bến Thành',
    },
]
