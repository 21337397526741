import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// styles
import { WrapPopupRefuseRegFinger } from './index.styles'

// images
import ic_close from "../../../images/ic_close_gray.svg";
import iconNotificationSuccess from "../../../images/ic_counter_notification_success.svg"

// action
import { addReasonRefuseGetFinger, addReasonRefuseGetFingerHaveUpdate } from '../../../actions/counterAction';
import { openPopup } from '../../../actions/popupBioAction';

// data
import { popupBioTypes } from '../../../data/PopupTypes';
import { typeStatusBiometrics } from '../../../data/statusBiometricWithTicket';

const PopupRefuseRegFinger = ({ onClosePopup, data: { currentTicket, haveUpdate } }) => {

    const dispatch = useDispatch()

    const { vtbReasonRefuseRegFinger, reasonRefuseRegFinger } = useSelector(state => ({
        vtbReasonRefuseRegFinger: state.counterReducer.listCurrentTicket
            .find(item => item.TicketID === currentTicket.TicketID)
            ?.dataWasAuthen?.vtbReasonRefuseRegFinger,
        reasonRefuseRegFinger: state.counterReducer.listCurrentTicket
            .find(item => item.TicketID === currentTicket.TicketID)
            ?.reasonRefuseRegFinger
    }))

    const [reason, setReason] = useState("");
    const [error, setError] = useState("");

    const disabled = String(currentTicket.statusBiometric) !== typeStatusBiometrics.UNDEFINED

    const _handleFilterReason = (e) => {
        setReason(e.target.value)
    }

    const _handleSuccess = () => {
        const dataTransferToPopup = {
            image_noti: iconNotificationSuccess,
            title: "Gửi KSV phê duyệt thành công. Vui lòng chờ để được xử lý.",
            widthPopup: "236px",
            titleConfirm: "Xác nhận"
        }
        dispatch(openPopup(popupBioTypes.PopupAccect, 1, dataTransferToPopup))
    }

    const _handleEventSubmit = () => {
        if(disabled) {
            onClosePopup()
            return;
        }
        const reasonRefuse = reason.trim()
        if(!reasonRefuse) {
            setError("Vui lòng nhập nội dung")
            return
        }

        if(haveUpdate) {
            dispatch(addReasonRefuseGetFingerHaveUpdate(
                currentTicket.TicketID, 
                reason,
            ))
            onClosePopup()
            return;
        }

        dispatch(addReasonRefuseGetFinger(
            currentTicket.TicketID, 
            currentTicket.CustomerInfos.CustomerNumber,
            reason,
            _handleSuccess
        ))
        onClosePopup()
    }

    useEffect(() => {
        setReason(reasonRefuseRegFinger || vtbReasonRefuseRegFinger)
    }, [reasonRefuseRegFinger]);


    return (
        <WrapPopupRefuseRegFinger>
            <figure>
                <img
                    onClick={onClosePopup}
                    className="ic_close"
                    src={ic_close}
                    alt="ic_close"
                />
            </figure>
            <div className="header">
                <p className="title">Khách hàng từ chối đăng ký sinh trắc học vân tay</p>
            </div>
            <div className="content-reason">
                <div className="title">Chi tiết lý do không đăng ký</div>
                <textarea
                    onChange={_handleFilterReason}
                    placeholder="Nhập nội dung...không quá 200 ký tự"
                    maxLength="200"
                    value={reason}
                    disabled={disabled}
                />
            </div>
            <div className="btn-submit">
                <p className="warning">{error}</p>
                <button onClick={_handleEventSubmit}>Xác nhận</button>
            </div>
        </WrapPopupRefuseRegFinger>
    )
}

export default PopupRefuseRegFinger
