import React, { Component } from 'react'
import styled from "styled-components";
import moment from "moment";
import { connect } from 'react-redux'


// component
import LoadingControl from "../../../Control/LoadingControl";

// action
import { tellerLogoutCounter } from '../../../../actions/counterAction';

const Bound = styled.div`
    z-index: 99999999999999999;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    background: #FAFAFA;
    border-radius: 10px;
    /* box-shadow: 0 0 20px #000; */
    padding: 0 63px;
    text-align: center;
    box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.23);

    & * {
        text-align: center;
        font-family: SVN-Gilroy;
    } 

    .title {
        /* margin-top: 28px; */
        /* margin-bottom: 32px; */
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: #005993;
        white-space: nowrap;
        /* width: 19ch; */
    }

    .title:first-child {
        margin-top: 28px;
    }

    .title.last {
        margin-bottom: 32px;
    }

    .content {
        margin-bottom: 39px;

        .time-count {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            color: gray;

            span {
                color: #222222;
            }
        }
    }

    button.continue {
        border: none;
        background: var(--peacock-blue);
        margin-bottom: 22px;
        padding: 7px 16px;
        border-radius: 18px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        outline: none;

        &:hover {
            cursor: pointer;
        }
    }
`;

const WAIT_TIME_IN_SECONDS = 30;

class PopupTimeoutModal extends Component {
    state = {
        time: ""
    }

    _handleLogout = () => {
        const { dataLogin, dataConnectDesktop } = this.props.counterReducer;
        const { UID } = dataLogin;
        const { CounterID, DeviceID } = dataConnectDesktop;
        const dataLogout = {
            UID,
            CounterID,
            DeviceID
        }
        this.props.tellerLogoutCounter(dataLogout)
    }

    render() {
        return (
            <Bound>
                <div className="title">Quầy đang trong trạng thái chờ,</div>
                <div className="title last">vui lòng xác nhận tiếp tục</div>
                <div className="content">
                    <LoadingControl size="36px" loadingPage={false} />
                    <div className="time-count">Thời gian chờ: <span>{` 00:${this.state.time}`}</span></div>
                </div>
                <button className="continue" onClick={this.props.onAccept}>Tiếp tục</button>
            </Bound>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.time !== this.state.time && this.state.time === "00") {
            this._handleLogout();
            // this.props.onAccept();
        }
        // if(this.props.counterReducer.listCurrentTicket !== prevProps.counterReducer.listCurrentTicket){
        //     // debugger;
        //     // const currentTicket = this.props.counterReducer.listCurrentTicket.find(
        //     //     item => item.TicketID === this.props.ticketId
        //     // )
        //     // if(currentTicket && currentTicket.isFinishWaitingEvalueate)
        //     this.props.closePopup();
        // }
    }


    componentDidMount() {
        const nowTime = moment();
        const timeCall = moment();
        const duration = moment.duration(nowTime.diff(timeCall));
        const hrs = duration.hours();
        const mins = duration.minutes();
        const secs = duration.seconds();
        let range = WAIT_TIME_IN_SECONDS - secs;
        if (hrs > 0 || mins > 0 || range <= 0) {
            this.setState({ time: "00" })
            return;
        }
        this.setState({ time: ("0" + range).slice(-2) })
        const setTimeOutCall = this.setTimeOutCall = setInterval(() => {
            const range = this.state.time - 1;
            if (range <= 0) {
                this.setState({ time: "00" })
                clearInterval(setTimeOutCall);
                return;
            }
            this.setState({ time: ("0" + range).slice(-2) })
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.setTimeOutCall);
    }

}

const mapStateToProps = state => ({
    counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
    tellerLogoutCounter: (dataLogout) => 
        dispatch(tellerLogoutCounter(dataLogout))
})

export default connect(mapStateToProps, mapDispatchToProps)(PopupTimeoutModal)
