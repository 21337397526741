import { errorData } from '../data/errorData'

const FirstNameData = ["Nguyễn", "Trần", "Lê", "Phạm", "Hoàng", "Huỳnh", "Phan", "Vũ", "Võ",
    "Đặng", "Bùi", "Đỗ", "Hồ", "Ngô", "Dương", "Lý"]

const MiddleNameData = ["Gia", "Minh", "Đức", "Kha", "Thúy", "Thùy", "Tùng", "Diệp", "Linh",
    "Bảo", "Hoàng", "Trần", "Thanh", "Tường", "Hoài"]

const LastNameData = ["Linh", "Quỳnh", "Nhi", "Thư", "Anh", "Đức", "Phú", "Khoa", "Kha",
    "Bảo", "Ngân", "Phúc", "Phước", "Lâm", "Toàn", "Minh", "Châu", "Diệp", "Tuấn", "Hiền", "Thọ", "Nghĩa", "Duyên",
    "Phương", "Hà", "Chương", "Dương", "Quân", "Oanh"]

const randomString = (list) => {
    let i = Math.floor(Math.random() * list.length);
    return list[i];
}

export const randomDisplayName = () => {
    return randomString(FirstNameData) + ' ' + randomString(MiddleNameData) + ' ' + randomString(LastNameData);
}


export const initialFormat = {
    dmyyyy_HHmmss: "D/M/YYYY HH:mm:ss",
    HHmmss: "HH : mm : ss"
}

/**
 * 
 * @param {String} data datetime data pass
 * @param {String} format format of data parameter
 */
export const formatDateAndTime = (data, format) => {
    switch (format) {
    case initialFormat.dmyyyy_HHmmss:
        const arrDayTime = data.split(" ");
        return ({
            //[D,M,YYYY]
            getDate: arrDayTime[0].split("/"),
            //[HH,mm,ss]
            getTime: arrDayTime[1].split(":")
        });

    default:
        return null;
    }
}

export const Nzero = n => {
    if (n > 0) {
        return n > 9 ? n : "0" + n
    } else return n
}

export const buildErrorContent = (actionType, code) => {
    if (!actionType || actionType.length === 0) {
        return 'Lỗi không xác định'
    }
    if (code === undefined) {
        return 'Lỗi không xác định'
    }

    if(!Object.keys(errorData[actionType]).includes(String(code))){
        if(code)
            return 'Lỗi không xác định. Code: ' + code
        return 'Lỗi không xác định.'
    }

    return errorData[actionType][code]
}

/**
 * 
 * @param {Array} original 
 */
export function countDuplicated(original) {

    var compressed = [];
    // make a copy of the input array
    
    if (original === null) {
        return
    }
    var copy = original.slice(0);

    // first loop goes over every element
    for (var i = 0; i < original.length; i++) {

        var myCount = 0;
        for (var w = 0; w < copy.length; w++) {
            if (original[i] === copy[w]) {
                myCount++;
                delete copy[w];
            }
        }

        if (myCount > 0) {
            var a = {};
            a.value = original[i];
            a.count = myCount;
            compressed.push(a);
        }
    }

    return compressed;
    
};