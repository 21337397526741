import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

// component
import ButtonConfirmControl from '../../Control/ButtonConfirmControl'

// images
import icClose from '../../../images/ic_close_gray.svg'
import ic_delete from '../../../images/Icons_Delete.svg'
import ic_update from '../../../images/Icons_Edit.svg'
import icRefreshBlue from '../../../images/Icons_Dashboard_Refresh.svg'
import icRefreshRed from '../../../images/ic_refresh_red.svg'

// data
import typesKeyDetailPopup from '../../../data/typesKeyDetailPopup'
import { nameFingerPrint } from '../../../data/bioTypes'
import {
    AUTH_TRANS,
    REGISTER,
    CONFIRM,
    VALIDATE_DATA,
    LIST_ID,
    ISHANG,
} from '../../../actions/typeRequestBio'
import { TELLER, INBIOCOLLECTOR } from '../../../data/userRole'
import { popupBioTypes } from '../../../data/PopupTypes'
import bioActionTypes from '../../../data/bioActionTypes'

// action
import { openPopup } from '../../../actions/popupBioAction'
import {
    updateFingerInfoInRegisterThread,
    updateFingerInfoInUpdateThread,
    apiGetBioFile,
    apiGetBioFingerImage,
    updateRevertRemoveBio,
} from '../../../actions/biometricAction'
import { requestUpDateBio } from '../../../actions/counterAction'
import {
    apiGetBioFileCollector,
    setAuthenticateFingerCollector,
    apiGetBioFingerImageCollector,
    requestUpDateBioCollector,
    updateRevertRemoveBioCollector,
    setAuthenticateFingerCollectorUpdateThread,
} from '../../../actions/biometricWithInBioCollectorAction'

// import helper
import {
    openPopupDeleteBioRegistered,
    openPopupAddReasonDeleteBioData,
} from '../../../helper/actionOpenPopup'
import isDisableEventClick from '../../../helper/isDisableEventWhenHaveSupervisor'
import { typeStatusBiometrics } from '../../../data/statusBiometricWithTicket'
import isErrorWhenGetEnroll from '../../../helper/isErrorWhenGetEnroll'
import { clearError } from '../../../actions/commonAction'
import getChannelName from '../../../helper/getChannelName'

const Bound = styled.div`
    background: #ffffff;
    width: 240px;
    padding: 12px 16px 14px 20px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    transition: 0.2s all ease-in-out;
    animation: fadeInFingerTrans 0.5s forwards;
    @keyframes fadeInFingerTrans {
        0% {
            opacity: 0.85;
            top: 45%;
        }
        100% {
            opacity: 1;
            top: 50%;
        }
    }
    /* /// Header Popup /// */
    .popup_header {
        width: 100%;
        background: #ffffff;
        display: flex;
        &__title {
            font-size: 16px;
            font-weight: bold;
            color: #000000;
        }
        img {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 10.1px;
            right: 10.2px;
            cursor: pointer;
        }
    }
    .channel {
        margin-top: 5px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 130%;
        color: #717171;
    }
    .block_img {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 16px 0 0 0;
        .img_Finger {
            width: 100%;
            height: 100%;
        }
    }
    .checkBox {
        .radio-field {
            display: flex;
            align-items: center;
            position: relative;
            input[type='checkbox'] {
                position: absolute;
                height: 0;
                width: 0;
                opacity: 0;
            }
            .checkmark {
                height: 16px;
                width: 16px;
                border: solid 0.8px #c8c8c8;
                background: #ffffff;
            }
            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }
            input[type='checkbox']:checked ~ .checkmark:after {
                display: block;
            }
            .checkmark::after {
                left: 5.5px;
                top: 2px;
                width: 4px;
                height: 8px;
                border: solid #d71249;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
            .text_label {
                margin: 0 0 0 4px;
                font-size: 14px;
                font-weight: 500;
                color: #000000;
            }
        }
    }

    .btn {
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        & > * {
            font-size: 14px;
        }

        &__title {
            line-height: 0;
        }

        .delete {
            color: var(--vermillion);
        }

        .re-get {
            color: var(--peacock-blue);
        }
    }
`

const StyleFinger = styled.div`
    width: 84px;
    height: 84px;
    border-radius: 5px;
    background-color: #ffffff;
    border: solid 0.5px #c8c8c8;
    padding: 10px;
    position: relative;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &:hover {
        .overlay {
            visibility: visible;
        }
    }
    .overlay {
        width: 100%;
        height: 100%;
        visibility: hidden;
        background-color: rgba(34, 34, 34, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        cursor: default;
        .content {
            text-align: center;
            width: 72px;
            font-size: 12px;
            font-weight: 500;
            color: #ffffff;
            position: absolute;
            top: 50%;
            left: 47%;
            transform: translate(-47%, -50%);
        }
    }
`

const ActionStepRegister = styled.div`
    margin: 18px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .checkBox {
        width: 100%;
    }
    .row-2 {
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .block_radioBtn {
        display: flex;
        justify-content: start;
        align-items: center;
        margin: 0 0 17px 0;
        .checkBox2 {
            margin: 0 20px 0 0;
            .radio-field {
                display: flex;
                align-items: center;
                position: relative;
                input[type='radio'] {
                    appearance: none;
                    position: absolute;
                    height: 0;
                    width: 0;
                    opacity: 0;
                }
                .checkmark {
                    height: 16px;
                    width: 16px;
                    border: solid 0.8px #c8c8c8;
                    background: #ffffff;
                    border-radius: 50%;
                    position: relative;
                }
                .checkmark:after {
                    content: '';
                    position: absolute;
                    display: none;
                }
                input[type='radio']:checked ~ .checkmark:after {
                    display: block;
                }
                .checkmark::after {
                    width: 70%;
                    height: 70%;
                    background-color: #d71249;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .text_label {
                    margin: 0 0 0 4px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000000;
                }
            }
        }
    }
`
const ActionStepUpdate = styled.div`
    margin: 18px 0 0 0;
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #222222;
    .block_radioBtn {
        display: flex;
        justify-content: start;
        align-items: center;
        margin: 0 0 17px 0;
        .checkBox2 {
            margin: 0 20px 0 0;
            .radio-field {
                display: flex;
                align-items: center;
                position: relative;
                input[type='radio'] {
                    appearance: none;
                    position: absolute;
                    height: 0;
                    width: 0;
                    opacity: 0;
                }
                .checkmark {
                    height: 16px;
                    width: 16px;
                    border: solid 0.8px #c8c8c8;
                    background: #ffffff;
                    border-radius: 50%;
                    position: relative;
                }
                .checkmark:after {
                    content: '';
                    position: absolute;
                    display: none;
                }
                input[type='radio']:checked ~ .checkmark:after {
                    display: block;
                }
                .checkmark::after {
                    width: 70%;
                    height: 70%;
                    background-color: #d71249;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                .text_label {
                    margin: 0 0 0 4px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #000000;
                }
            }
        }
    }
    .checkBox2 {
        margin: 0 20px 0 0;
        .radio-field {
            display: flex;
            align-items: center;
            position: relative;
            input[type='radio'] {
                appearance: none;
                position: absolute;
                height: 0;
                width: 0;
                opacity: 0;
            }
            .checkmark {
                height: 16px;
                width: 16px;
                border: solid 0.8px #c8c8c8;
                background: #ffffff;
                border-radius: 50%;
                position: relative;
            }
            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }
            input[type='radio']:checked ~ .checkmark:after {
                display: block;
            }
            .checkmark::after {
                width: 70%;
                height: 70%;
                background-color: #d71249;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .text_label {
                margin: 0 0 0 4px;
                font-size: 14px;
                font-weight: 500;
                color: #000000;
            }
        }
    }
    .box2 {
        margin: 13px 0 0 0;
    }
    .Block_action {
        margin: 26px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
            cursor: pointer;
            &:last-child {
                margin: 0 0 0 12px;
            }
            &:first-child {
                margin: 0;
            }
        }
    }
`

const StyleDeleteBio = styled.div`
    /* margin-bottom: 18px; */
    border-radius: 0 0 5px 5px;

    .detail {
        margin-top: 10px;
        /* padding: 0 39px; */
        color: #f31919;
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        line-height: 128%;
        font-weight: 500;
        text-align: center;
    }

    .btn-group {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding: 0 20px; */

        .btn-fallback {
            display: flex;
            align-items: center;

            & > div {
                font-family: 'SVN-Gilroy';
                font-size: 13px;
                line-height: 14px;
                font-weight: bold;
                text-transform: uppercase;
                color: #f31919;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
`

const BIO_INFO_REGISTER = typesKeyDetailPopup.fingerBioInfoResgister
const BIO_INFO_UPDATE = typesKeyDetailPopup.fingerBioInfoUpdate
const UPDATE_BIO_REMOVE_ACCEPT = 'UPDATE_BIO_REMOVE_ACCEPT'
const UPDATE_BIO_REMOVE_PENDING = 'UPDATE_BIO_REMOVE_PENDING'
const UPDATE_BIO_REMOVE_REJECT = 'UPDATE_BIO_REMOVE_REJECT'
const UPDATE_BIO_REMOVE = 'UPDATE_BIO_REMOVE'
// const CONTENT_FINGER = "Vân tay chưa được xác thực"
const CONTENT_FINGER = ''

const textUpdateRemove = {
    [UPDATE_BIO_REMOVE]:
        'Vân tay đang ở trạng thái xoá và đang chờ gửi KSV phê duyệt',
    [UPDATE_BIO_REMOVE_ACCEPT]: 'Vân tay đã được phê duyệt xoá bởi KSV',
    [UPDATE_BIO_REMOVE_PENDING]: 'Vân tay đang chờ KSV phê duyệt',
    [UPDATE_BIO_REMOVE_REJECT]: 'Vân tay đã bị từ chối xoá bởi KSV',
}

class PopupBioFingerTransaction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // step: this.props.data.typeDetail,
            step: BIO_INFO_REGISTER,
            img_finger: {
                finger_first: null,
                finger_last: null,
            },
            confirmFingerTransaction: false,
            fingerprintHanging: false,
            onCloseTransaction: false,
            fromChannel: undefined
        }
    }

    confirmFingerTransaction = (numberCheck) => {
        const ticketId = this.props.data.dataGetTicket.TicketID
        if (isDisableEventClick(ticketId, this.props.counterReducer, this))
            return

        //// 0: flowRegister, 1 - 2: flowUpdate
        if (numberCheck === 2)
            this.setState({
                confirmFingerTransaction: false,
                fingerprintHanging: false,
                onCloseTransaction: true,
            })
        else if (numberCheck === 1)
            this.setState({
                confirmFingerTransaction: true,
                fingerprintHanging: false,
                onCloseTransaction: false,
            })
        else if (numberCheck === 0)
            this.setState({
                confirmFingerTransaction: !this.state.confirmFingerTransaction,
            })
    }

    fingerprintHanging = () => {
        const ticketId = this.props.data.dataGetTicket.TicketID
        if (isDisableEventClick(ticketId, this.props.counterReducer, this))
            return

        this.setState({
            fingerprintHanging: !this.state.fingerprintHanging,
        })
    }

    popupConfirmDeleteBio = () => {
        const ticketId = this.props.data.dataGetTicket.TicketID
        if (isDisableEventClick(ticketId, this.props.counterReducer, this))
            return

        this.props.openPopup(popupBioTypes.PopupConfirm, 3, {
            width: '274px',
            content: 'Xóa dữ liệu sinh trắc học - vân tay?',
            contentDelete:
                '(*) Yêu cầu xoá phải được KSV phê duyệt. Dữ liệu sau khi xoá sẽ không thể phục hồi',
            key: typesKeyDetailPopup.removeFingerFlowUpdate,
            data: {
                ticketId,
                typeBio: this.props.data.typeBio,
            },
        })
    }

    popupConfirmUpdateBio = () => {
        const ticketId = this.props.data.dataGetTicket.TicketID
        if (isDisableEventClick(ticketId, this.props.counterReducer, this))
            return

        this.props.openPopup(popupBioTypes.PopupConfirm, 3, {
            width: '274px',
            content: 'Cập nhật dữ liệu sinh trắc học',
            contentDelete:
                '(*) Dữ liệu sẽ được thay đổi sau khi được KSV phê duyệt',
            key: typesKeyDetailPopup.registerFingerFlowUpdate,
            data: {
                dataGetTicket: this.props.data.dataGetTicket,
                flowUpdate: this.props.flowUpdate,
                typeBio: this.props.data.typeBio,
            },
        })
    }

    ////// Confirm Finger to Auth Transaction
    handleConfirmTrans = async () => {
        const { dataGetTicket, typeBio } = this.props.data
        const { counterReducer } = this.props

        // const ticketId = this.props.data.dataGetTicket.TicketID;
        if (isDisableEventClick(dataGetTicket.TicketID, counterReducer, this))
            return

        let enrollId = counterReducer.enrollId[dataGetTicket.TicketID]
        // debugger;
        switch (true) {
        case this.props.counterReducer.dataLogin.Roles.includes(TELLER):
            await this.props.fingerAuthTrans(
                dataGetTicket.TicketID,
                enrollId,
                typeBio,
                this.state.confirmFingerTransaction
            )
            break
        case this.props.counterReducer.dataLogin.Roles.includes(
            INBIOCOLLECTOR
        ):
            await this.props.setAuthenticateFingerCollector(
                enrollId,
                dataGetTicket.TicketID,
                typeBio,
                this.state.confirmFingerTransaction
            )
            break
        default:
            break
        }
        await this.props.onClose()
    }
    handleUpdateTrans = async () => {
        const { dataGetTicket, typeBio } = this.props.data
        const { counterReducer } = this.props

        // const ticketId = this.props.data.dataGetTicket.TicketID;
        if (isDisableEventClick(dataGetTicket.TicketID, counterReducer, this))
            return

        const enrollId = counterReducer.enrollId[dataGetTicket.TicketID]
        const userCurrent = counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === dataGetTicket.TicketID
        )
        const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER)
        if (!enrollId) {
            userIsTeller &&
                (await this.props.requestUpDateBio(
                    userCurrent.TicketID,
                    userCurrent.CustomerInfos.CustomerNumber
                ))

            !userIsTeller &&
                (await this.props.requestUpDateBioCollector(
                    userCurrent.TicketID,
                    userCurrent.CustomerInfos.CustomerNumber
                ))

            const isError = isErrorWhenGetEnroll(
                this.props.counterReducer,
                this.props.openPopup,
                this.props.clearError
            )
            if (isError) return
        }
        userIsTeller &&
            (await this.props.fingerUpdateTrans(
                dataGetTicket.TicketID,
                this.props.counterReducer.enrollId[dataGetTicket.TicketID],
                typeBio,
                this.state.confirmFingerTransaction,
                this.state.confirmFingerTransaction
                    ? this.state.fingerprintHanging
                    : true
            ))
        !userIsTeller &&
            (await this.props.setAuthenticateFingerCollectorUpdateThread(
                this.props.counterReducer.enrollId[dataGetTicket.TicketID],
                dataGetTicket.TicketID,
                typeBio,
                this.state.confirmFingerTransaction,
                this.state.confirmFingerTransaction
                    ? this.state.fingerprintHanging
                    : true
            ))
        await this.props.onClose()
    }

    handleResetDataFinger = (e) => {
        e.preventDefault()

        const { dataGetTicket, typeBio } = this.props.data
        const { counterReducer } = this.props

        // const ticketId = this.props.data.dataGetTicket.TicketID;
        if (isDisableEventClick(dataGetTicket.TicketID, counterReducer, this))
            return

        const data = {
            enrollId: counterReducer.enrollId[dataGetTicket.TicketID],
            bioType: typeBio,
            ticketId: dataGetTicket.TicketID,
        }
        openPopupDeleteBioRegistered(
            this,
            data,
            'handleResetDataFinger - PopupBioFingerTransaction.js'
        )
    }

    handleUpdateState = () => {
        const { biometricReducer, counterReducer } = this.props
        const { dataGetTicket, typeBio } = this.props.data
        const ticketId = dataGetTicket.TicketID

        const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER)

        // 1. isTicketHaveFingerBioDelete
        // 2. isTicketHaveFingerConfirmSuccess
        // 3. isTicketHaveFingerValidatedData
        const isTicketHaveBio = Object.keys(biometricReducer).includes(ticketId)
        const isTicketHaveThisFingerBio =
            isTicketHaveBio &&
            Object.keys(biometricReducer[ticketId]).includes(typeBio)

        // isTicketHaveFingerBioDelete
        const isTicketHaveThisFingerBioDelete =
            isTicketHaveThisFingerBio &&
            biometricReducer[ticketId][typeBio].bioActionType ===
            bioActionTypes.REMOVE
        // debugger;

        // isTicketHaveFingerConfirmSuccess
        const isTicketHaveThisFingerBioConfirm =
            isTicketHaveThisFingerBio &&
            Object.keys(biometricReducer[ticketId][typeBio]).includes(CONFIRM)
        const isTicketHaveThisFingerBioConfirmSuccess =
            isTicketHaveThisFingerBioConfirm &&
            biometricReducer[ticketId][typeBio][CONFIRM] === 0

        // isTicketHaveFingerValidatedData
        const isTicketHaveThisFingerBioValidatedData =
            isTicketHaveThisFingerBio &&
            Object.keys(biometricReducer[ticketId][typeBio]).includes(
                'validatedData'
            )

        switch (true) {
        case isTicketHaveThisFingerBioDelete: {
            const currentTicket = counterReducer.listCurrentTicket.find(
                (item) => item.TicketID === ticketId
            )
            if (!currentTicket) break
            const { statusBiometric } = currentTicket

            let step = UPDATE_BIO_REMOVE

            // 1. delete and PENDING
            // 2. delete and REJECT
            // 3. delete and ACCEPT
            // 4. delete
            switch (true) {
            case statusBiometric === typeStatusBiometrics.PENDING:
                step = UPDATE_BIO_REMOVE_PENDING
                break
            case statusBiometric === typeStatusBiometrics.REJECT:
                step = UPDATE_BIO_REMOVE_REJECT
                break
            case statusBiometric === typeStatusBiometrics.ACCEPT:
                step = UPDATE_BIO_REMOVE_ACCEPT
                break
            default:
                break
            }

            const thisBioData = biometricReducer[ticketId][typeBio]
            const apiGetImage = userIsTeller
                ? apiGetBioFingerImage
                : apiGetBioFingerImageCollector
            this.setState({
                step,
                // confirmFingerTransaction: thisBioData[AUTH_TRANS],
                img_finger: {
                    finger_first: apiGetImage(thisBioData[REGISTER][0]),
                    finger_last: apiGetImage(thisBioData[REGISTER][1]),
                },
                fromChannel: thisBioData.fromChannel
            })
            // debugger;
            break
        }
        case isTicketHaveThisFingerBioConfirmSuccess: {
            const thisBioData = biometricReducer[ticketId][typeBio]
            const apiGetImage = userIsTeller
                ? apiGetBioFile
                : apiGetBioFileCollector
            this.setState({
                step: BIO_INFO_REGISTER,
                confirmFingerTransaction: thisBioData[AUTH_TRANS],
                img_finger: {
                    finger_first: apiGetImage(thisBioData[REGISTER][0]),
                    finger_last: apiGetImage(thisBioData[REGISTER][1]),
                },
                fromChannel: thisBioData.fromChannel
            })
            break
        }
        case isTicketHaveThisFingerBioValidatedData: {
            const thisFingerBio = biometricReducer[ticketId][typeBio]
            const thisFingerWasUpdatedConfirmFinger = Object.keys(
                thisFingerBio
            ).includes(AUTH_TRANS)
            const validatedData = thisFingerBio[VALIDATE_DATA]
            const apiGetImage = userIsTeller
                ? apiGetBioFingerImage
                : apiGetBioFingerImageCollector
                // debugger
            const confirmFingerTransaction = thisFingerWasUpdatedConfirmFinger
                ? thisFingerBio[AUTH_TRANS]
                : validatedData[AUTH_TRANS]
            const fingerprintHanging = thisFingerWasUpdatedConfirmFinger
                ? thisFingerBio[ISHANG]
                : validatedData[ISHANG]
            this.setState({
                step: BIO_INFO_UPDATE,
                confirmFingerTransaction,
                fingerprintHanging: confirmFingerTransaction
                    ? fingerprintHanging
                    : false,
                img_finger: {
                    finger_first: apiGetImage(validatedData[LIST_ID][0]),
                    finger_last: apiGetImage(validatedData[LIST_ID][1]),
                },
                fromChannel: validatedData.fromChannel
            })
            break
        }
        default:
            break
        }
    }

    handleShowNoteDelete = () => {
        const { counterReducer } = this.props
        const { dataGetTicket, typeBio } = this.props.data
        const ticketId = dataGetTicket.TicketID
        if (isDisableEventClick(ticketId, counterReducer, this)) return

        openPopupAddReasonDeleteBioData(
            this,
            {
                ticketId,
                bioType: typeBio,
            },
            'handleShowNoteDelete - PopupBioFingerTransaction.js'
        )
    }

    handleFallBack = async () => {
        const { counterReducer } = this.props
        const { dataGetTicket, typeBio } = this.props.data
        const ticketId = dataGetTicket.TicketID
        if (isDisableEventClick(ticketId, counterReducer, this)) return

        const enrollId = counterReducer.enrollId[ticketId]
        const userIsTeller = counterReducer.dataLogin.Roles.includes(TELLER)
        if (!enrollId) {
            const currentTicket = counterReducer.listCurrentTicket.find(
                (item) => item.TicketID === ticketId
            )
            if (!currentTicket) return
            if (!currentTicket.CustomerInfos) return
            userIsTeller &&
                (await this.props.requestUpDateBio(
                    ticketId,
                    currentTicket.CustomerInfos.CustomerNumber
                ))

            !userIsTeller &&
                (await this.props.requestUpDateBioCollector(
                    ticketId,
                    currentTicket.CustomerInfos.CustomerNumber
                ))

            const isError = isErrorWhenGetEnroll(
                this.props.counterReducer,
                this.props.openPopup,
                this.props.clearError
            )
            if (isError) return
        }

        userIsTeller &&
            this.props.updateRevertRemoveBio(
                this.props.counterReducer.enrollId[ticketId],
                typeBio
            )
        !userIsTeller &&
            this.props.updateRevertRemoveBioCollector(
                this.props.counterReducer.enrollId[ticketId],
                typeBio
            )
    }

    handleChangeStatusConfirmFinger = (confirmFingerTransaction) => {
        const ticketId = this.props.data.dataGetTicket.TicketID
        if (isDisableEventClick(ticketId, this.props.counterReducer, this))
            return

        this.setState({ confirmFingerTransaction })
    }

    render() {
        const {
            step,
            img_finger,
            confirmFingerTransaction,
            fingerprintHanging,
            // onCloseTransaction
        } = this.state
        const { data } = this.props
        return (
            <Bound>
                <div className="popup_header">
                    <div className="popup_header__title">
                        {nameFingerPrint[data && data.typeBio].name}
                    </div>
                    <img
                        src={icClose}
                        alt="close"
                        onClick={() => {
                            this.props.onClose()
                        }}
                    />
                </div>
                <div className="channel">Kênh tác nghiệp: {getChannelName(this.state.fromChannel)}</div>
                <div className="block_img">
                    <StyleFinger id="block_finger">
                        <div className="img_Finger">
                            <img
                                src={img_finger.finger_first}
                                alt="img_finger"
                            ></img>
                        </div>
                        {step === BIO_INFO_REGISTER && (
                            <div className="overlay">
                                <div className="content">{CONTENT_FINGER}</div>
                            </div>
                        )}
                    </StyleFinger>
                    <StyleFinger id="block_finger">
                        <div className="img_Finger">
                            <img
                                src={img_finger.finger_last}
                                alt="img_finger"
                            ></img>
                        </div>
                        {step === BIO_INFO_REGISTER && (
                            <div className="overlay">
                                <div className="content">{CONTENT_FINGER}</div>
                            </div>
                        )}
                    </StyleFinger>
                </div>
                {step === UPDATE_BIO_REMOVE ||
                    step === UPDATE_BIO_REMOVE_ACCEPT ||
                    step === UPDATE_BIO_REMOVE_REJECT ||
                    step === UPDATE_BIO_REMOVE_PENDING ? (
                        <StyleDeleteBio>
                            <div className="detail">{textUpdateRemove[step]}</div>
                            <div className="btn-group">
                                <img
                                    src={ic_update}
                                    alt="reason-delete"
                                    onClick={this.handleShowNoteDelete}
                                />
                                <div
                                    className="btn-fallback"
                                    onClick={this.handleFallBack}
                                >
                                    <img src={icRefreshRed} alt="fallback" />
                                    <div>Hoàn tác</div>
                                </div>
                                <ButtonConfirmControl
                                    width="100px"
                                    onSubmit={this.props.onClose}
                                    titleConfirm="Xác nhận"
                                />
                            </div>
                        </StyleDeleteBio>
                    ) : null}
                {step === BIO_INFO_REGISTER && (
                    <ActionStepRegister>
                        <div className="block_radioBtn">
                            <div className="checkBox2">
                                <label className="radio-field">
                                    <input
                                        type="radio"
                                        name="content"
                                        value="Xác thực giao dịch"
                                        checked={confirmFingerTransaction}
                                        onChange={() =>
                                            this.handleChangeStatusConfirmFinger(
                                                true
                                            )
                                        }
                                    />
                                    <div className="checkmark"></div>
                                    <div className="text_label">Hoạt động</div>
                                </label>
                            </div>
                            <div className="checkBox2">
                                <label className="radio-field">
                                    <input
                                        type="radio"
                                        name="content"
                                        value="Xác thực giao dịch"
                                        checked={!confirmFingerTransaction}
                                        onChange={() =>
                                            this.handleChangeStatusConfirmFinger(
                                                false
                                            )
                                        }
                                    />
                                    <div className="checkmark"></div>
                                    <div className="text_label">Đóng</div>
                                </label>
                            </div>
                        </div>
                        <div className="row-2">
                            <div
                                className="btn"
                                onClick={this.handleResetDataFinger}
                            >
                                <img src={icRefreshBlue} alt="remove-finger" />
                                <div className="btn__title re-get">Lấy lại</div>
                            </div>
                            <ButtonConfirmControl
                                width="88px"
                                onSubmit={() => {
                                    this.handleConfirmTrans()
                                }}
                                titleConfirm="Xác nhận"
                            />
                        </div>
                    </ActionStepRegister>
                )}
                {step === BIO_INFO_UPDATE && (
                    <ActionStepUpdate>
                        <div className="block_radioBtn">
                            <div className="checkBox2">
                                <label className="radio-field">
                                    <input
                                        type="radio"
                                        name="content"
                                        value="Xác thực giao dịch"
                                        checked={
                                            confirmFingerTransaction &&
                                            !fingerprintHanging
                                        }
                                        onChange={() =>
                                            this.confirmFingerTransaction(1)
                                        }
                                    />
                                    <div className="checkmark"></div>
                                    <div className="text_label">Hoạt động</div>
                                </label>
                            </div>
                            <div className="checkBox2">
                                <label className="radio-field">
                                    <input
                                        type="radio"
                                        name="content"
                                        value="Xác thực giao dịch"
                                        checked={!confirmFingerTransaction}
                                        onChange={() =>
                                            this.confirmFingerTransaction(2)
                                        }
                                    />
                                    <div className="checkmark"></div>
                                    <div className="text_label">Đóng</div>
                                </label>
                            </div>
                        </div>
                        <div className="checkBox2">
                            <label className="radio-field">
                                <input
                                    type="radio"
                                    name="content"
                                    value="Treo vân tay giao dịch"
                                    checked={
                                        confirmFingerTransaction &&
                                        fingerprintHanging
                                    }
                                    onChange={
                                        confirmFingerTransaction &&
                                            !fingerprintHanging
                                            ? this.fingerprintHanging
                                            : () => { }
                                    }
                                />
                                <div
                                    className="checkmark"
                                    style={{
                                        backgroundColor:
                                            !confirmFingerTransaction &&
                                            '#efefef',
                                        border:
                                            !confirmFingerTransaction &&
                                            'solid 0.8px #d6d6d6',
                                    }}
                                ></div>
                                <div
                                    className="text_label"
                                    style={{
                                        color:
                                            !confirmFingerTransaction &&
                                            '#d6d6d6',
                                    }}
                                >
                                    Treo vân tay giao dịch
                                </div>
                            </label>
                        </div>
                        <div className="Block_action">
                            <div className="div_bio">
                                <div
                                    className="btn"
                                    onClick={() => {
                                        this.popupConfirmDeleteBio()
                                    }}
                                >
                                    <img src={ic_delete} alt="img_finger" />
                                    <div className="btn__title delete">Xóa</div>
                                </div>
                                <div></div>
                            </div>
                            <ButtonConfirmControl
                                width="100px"
                                onSubmit={() => {
                                    this.handleUpdateTrans()
                                }}
                                titleConfirm="Xác nhận"
                            />
                        </div>
                    </ActionStepUpdate>
                )}
            </Bound>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(prevProps.biometricReducer) !==
            JSON.stringify(this.props.biometricReducer)
        ) {
            this.handleUpdateState()
        }
    }

    componentDidMount() {
        this.handleUpdateState()
    }
}

const mapStateToProps = (state) => ({
    biometricReducer: state.biometricReducer,
    counterReducer: state.counterReducer,
})

const mapDispatchToProps = (dispatch) => ({
    openPopup: (type, levelPopup, data) =>
        dispatch(openPopup(type, levelPopup, data)),
    fingerAuthTrans: (ticketId, enrollId, bioType, isAuthenticate) =>
        dispatch(
            updateFingerInfoInRegisterThread(
                ticketId,
                enrollId,
                bioType,
                isAuthenticate
            )
        ),
    setAuthenticateFingerCollector: (
        enrollId,
        ticketId,
        bioType,
        isAuthenticate
    ) =>
        dispatch(
            setAuthenticateFingerCollector(
                enrollId,
                ticketId,
                bioType,
                isAuthenticate
            )
        ),
    requestUpDateBio: (ticketId, cusNumber) =>
        dispatch(requestUpDateBio(ticketId, cusNumber)),
    fingerUpdateTrans: (ticketId, enrollId, bioType, isAuthenticate, isHang) =>
        dispatch(
            updateFingerInfoInUpdateThread(
                ticketId,
                enrollId,
                bioType,
                isAuthenticate,
                isHang
            )
        ),
    updateRevertRemoveBio: (enrollId, bioType) =>
        dispatch(updateRevertRemoveBio(enrollId, bioType)),
    // COLLECTOR
    requestUpDateBioCollector: (ticketId, cusNumber) =>
        dispatch(requestUpDateBioCollector(ticketId, cusNumber)),
    updateRevertRemoveBioCollector: (enrollId, bioType) =>
        dispatch(updateRevertRemoveBioCollector(enrollId, bioType)),
    setAuthenticateFingerCollectorUpdateThread: (
        enrollId,
        ticketId,
        bioType,
        isAuthenticate,
        isHang
    ) =>
        dispatch(
            setAuthenticateFingerCollectorUpdateThread(
                enrollId,
                ticketId,
                bioType,
                isAuthenticate,
                isHang
            )
        ),
    clearError: (typeError) => dispatch(clearError(typeError)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(PopupBioFingerTransaction)
