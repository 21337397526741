import Axios from 'axios'

// data
import * as types from './types'
import * as typeCodeResponses from './typeCodeResponses'
import { host } from '../host'
import * as typesStatusCode from '../data/typesStatusCode'

// helper funtion
import setAuth from '../helper/setAuth'
import catchErrAction from '../helper/catchErrAction'

// action
import { apiFinishWaitingSocketFinger } from './biometricAction'
import { addLoading, removeLoading } from './commonAction'

// api
const apiConnectDesktop = host + 'admin/counter/connect-desktop'
const apiGetListTypeCounterServices = host + 'counter/counter-services/getAll'
const apiTellerLoginCounter = host + 'counter/login'
const apiTellerLogoutCounter = host + 'counter/logout'
const apiGetTicket = host + 'counter/get-ticket'
const apiGetTicketInQueue = host + 'counter/get-tickets-in-queue'
export const apiGetCifs = host + 'counter/get-cifs'
const apiAddCustomerInfosToTicket = host + 'counter/update-ticket-customerinfos'
const apiCompleteTicket = host + 'counter/complete-ticket'
const apiRequestEnroll = host + 'counter/request-enroll'
const apiRequestUpdateBio = host + 'counter/request-update-bio'
const apiEditRejectBio = host + 'counter/edit-rejected-bio'

const apiGetCurrentTickets = host + 'counter/get-current-tickets'
const apiPauseTicket = host + 'counter/pause-ticket'
const apiUnpauseTicket = host + 'counter/unpause-ticket'
const apiStartOrStopCounter = host + 'counter/change-serving-state'
const apiMoveToMissCall = host + 'counter/move-to-misscall'
const apiGetOtherCounterInfos = host + 'counter/get-other-counter-infos'
const apiMoveTicketToOtherCounter = host + 'counter/move-ticket'
const apiCallTicket = host + 'counter/call-ticket'
const apiRecallTicket = host + 'counter/recall-ticket'
const apiCreateNewTicket = host + 'counter/create-new-ticket'
const apiUpdateBioInfo = host + 'counter/update-bio-info'
const apiReasonNotRegisterBio = host + 'counter/add-reason-not-register-bio'
const apiVerifyFinger = host + 'counter/verify-finger'
const apiReportVerifyFingerFailedToSupervisor =
    host + 'counter/add-verify-finger-failed'
const apiContinueGetBio = host + 'counter/continue-get-bio'
const apiAddNotVerifyFingerReason =
    host + 'counter/add-not-verify-finger-reason'
const apiGetBioHistoryList = host + 'counter/get-bio-history-list'
const apiAddReasonNotVerifyFingerButFace =
    host + 'counter/update-reason-nr-finger'
const apiAddReasonRefuseGetFinger = host + 'counter/refuse-get-finger'
const apiGetCounterDetails = host + 'counter/get-details'
export const apiGetDuplBioDetails = host + 'counter/get-dupbio-details'
export const apiFindUserByFace = host + 'counter/findUserByFace'
export const apiRequestFindUserByBio = host + 'counter/requestFindUserByBio'

export const getCounterDetails = () => async (dispatch, getState) => {
    const store = getState()
    const { commonReducer } = store

    const dataRequestFormat = JSON.stringify({
        DeviceMac: commonReducer.clientName,
    })

    try {
        const res = await Axios.post(apiGetCounterDetails, dataRequestFormat)
        const { data } = res
        if (data.Code === typeCodeResponses.CODE_SUCCESS) {
            localStorage.setItem('dataConnectDesktop', JSON.stringify(data))
            dispatch(setDataConnectDesktop(data))
        }
    } catch (err) {
        const getCode = err?.response?.data?.Code
        if (getCode === 3) {
            localStorage.removeItem('dataConnectDesktop')
            dispatch({
                type: types.GET_COUNTER_DETAILS_ERR,
                code: 3,
            })
            return
        }

        catchErrAction(err, dispatch, types.GET_COUNTER_DETAILS_ERR)
    }
}

export const addReasonNotVerifyFingerButFace = (
    ticketId,
    cusNum,
    reasonText,
    otherReasonText
) => async (dispatch, getState) => {
    dispatch(addLoading())
    const store = getState()
    const enrollId = Object.values(store.counterReducer.enrollId).find(
        (item) => store.counterReducer.enrollId[ticketId] === item
    )
    if (!enrollId) {
        dispatch(removeLoading())
        return
    }

    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        ReasonNRFinger: reasonText + '<br>Chi tiết: ' + otherReasonText,
    })

    try {
        const res = await Axios.post(
            apiAddReasonNotVerifyFingerButFace,
            queryDataFormat
        )
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch(
                reasonNotRegisterFingerButFace(
                    ticketId,
                    reasonText,
                    otherReasonText
                )
            )
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.GET_LIST_HISTORY_CUST_ERR, false)
    }
}

export const addReasonRefuseGetFinger = (
    ticketId,
    cifs,
    reasonRefuseRegFinger,
    callbackSuccess
) => async (dispatch, getState) => {
    dispatch(addLoading())
    const store = getState()
    const enrollId = Object.values(store.counterReducer.enrollId).find(
        (item) => store.counterReducer.enrollId[ticketId] === item
    )

    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        Cifs: cifs,
        EnrollID: enrollId,
        ReasonNR: reasonRefuseRegFinger,
    })

    try {
        const res = await Axios.post(
            apiAddReasonRefuseGetFinger,
            queryDataFormat
        )
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess){
            dispatch({
                type: types.REASON_REFUSE_REG_FINGER,
                payload: {
                    ticketId,
                    reasonRefuseRegFinger
                }
            })
            callbackSuccess && callbackSuccess()
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.GET_LIST_HISTORY_CUST_ERR, false)
    }
}

export const addReasonRefuseGetFingerHaveUpdate = (
    ticketId,
    reasonRefuseRegFinger,
    callbackSuccess
) => async (dispatch, getState) => {
    dispatch(addLoading())
    const store = getState()
    const enrollId = Object.values(store.counterReducer.enrollId).find(
        (item) => store.counterReducer.enrollId[ticketId] === item
    )

    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        ReasonNRFinger: reasonRefuseRegFinger,
        Type: "Update"
    })

    try {
        const res = await Axios.post(
            apiAddReasonNotVerifyFingerButFace,
            queryDataFormat
        )
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess){
            dispatch({
                type: types.REASON_REFUSE_REG_FINGER,
                payload: {
                    ticketId,
                    reasonRefuseRegFinger
                }
            })
            callbackSuccess && callbackSuccess()
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.GET_LIST_HISTORY_CUST_ERR, false)
    }
}

export const reasonNotRegisterFingerButFace = (
    ticketId,
    reasonText,
    otherReasonText
) => ({
    type: types.REASON_NOT_REGISTER_FINGER_BUT_FACE,
    payload: {
        ticketId,
        notVerifyFingerReason: reasonText,
        notVerifyFingerReason_otherReason: otherReasonText,
    },
})

export const getBioHistoryList = (
    ticketId,
    cusNum,
    pageSize,
    pageNum,
    lestThanCreate
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CustomerNumber: cusNum,
        PageSize: pageSize,
        PageNumber: pageNum,
        LessthanCreateAt: undefined,
    })

    try {
        const res = await Axios.post(apiGetBioHistoryList, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.GET_LIST_HISTORY_CUST,
                ticketId,
                historyDetails: data,
            })
    } catch (err) {
        dispatch(removeLoading())
        console.log(err)
        catchErrAction(err, dispatch, types.GET_LIST_HISTORY_CUST_ERR, false)
    }
}

export const getBioHistoryItemInfo = () => async (dispatch) => { }

export const addReasonCusNotAuth = (
    ticketId,
    customerNumber,
    reasonText
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        CustomerNumber: customerNumber,
        NotVerifyFingerReason: reasonText,
    })
    try {
        const res = await Axios.post(
            apiAddNotVerifyFingerReason,
            queryDataFormat
        )
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR,
                ticketId,
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.CONTINUE_GET_BIO_ERR, false)
    }
}

export const continueGetBio = (enrollId, ticketId) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        TicketID: ticketId,
    })

    try {
        const res = await Axios.post(apiContinueGetBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res

        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.CONTINUE_GET_BIO,
                ticketId,
            })
        } else {
            dispatch({
                type: types.CONTINUE_GET_BIO_ERR,
                err: {
                    [types.CONTINUE_GET_BIO_ERR]: data.Code,
                },
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.CONTINUE_GET_BIO_ERR, false)
    }
}

/**
 *
 */
export const requestNewEnrollIdToUpdateRejectBio = (
    counterId,
    enrollId,
    ticketId
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        EnrollID: enrollId,
        TicketID: ticketId,
    })

    try {
        const res = await Axios.post(apiEditRejectBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO,
                newEnrollId: data.EnrollID,
                ticketId,
            })
        } else {
            dispatch({
                type: types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO_ERR,
                err: {
                    [types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO_ERR]: data.Code,
                },
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(
            err,
            dispatch,
            types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO,
            false
        )
    }
}

export const verifyFinger = (ticketId, customerNumber) => async (
    dispatch,
    getState
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        CustomerNumber: customerNumber,
    })

    try {
        const res = await Axios.post(apiVerifyFinger, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.VERIFY_FINGER,
                ticketId,
                verifyFingers: data.VerifyFingers || [],
            })
        } else
            dispatch({
                type: types.VERIFY_FINGER_ERR,
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.VERIFY_FINGER_ERR)
    }
}

export const removeStatusWaitingVerifyFinger = (
    ticketId,
    isNotCallApi = false
) => async (dispatch, getState) => {
    if (isNotCallApi) {
        dispatch({
            type: types.REMOVE_STATUS_WAITING_VERIFY_FINGER,
            ticketId,
        })
        return
    }
    dispatch(addLoading())
    const currentState = getState()
    const counterId = currentState.counterReducer.dataConnectDesktop.CounterID
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        CounterID: counterId,
    })
    try {
        const res = await Axios.post(
            apiFinishWaitingSocketFinger,
            queryDataFormat
        )
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.REMOVE_STATUS_WAITING_VERIFY_FINGER,
                ticketId,
            })
        } else
            dispatch({
                type: types.REMOVE_STATUS_WAITING_VERIFY_FINGER_ERR,
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(
            err,
            dispatch,
            types.REMOVE_STATUS_WAITING_VERIFY_FINGER_ERR,
            false
        )
    }
}

/**
 * [description]
 * @param  {string} ticketId [description]
 * @param  {{
 *         Code*: string,
 *         CustomerNumber*: string,
 *         TicketID*: string,
 *         ValidFingerType: null \| array
 * }} detail   [description]
 * @return {[type]}          [description]
 */
export const verifyCustomerResult = (detail) => ({
    type: types.VERIFY_CUSTOMER_RESULT,
    detail,
})

export const reportVerifyFingerFailedToSupervisor = (
    ticketId,
    customerNumber,
    note
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        CustomerNumber: customerNumber,
        Note: note,
    })

    try {
        const res = await Axios.post(
            apiReportVerifyFingerFailedToSupervisor,
            queryDataFormat
        )
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR,
                ticketId,
            })
        else
            dispatch({
                type: types.REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR_ERR,
                err: {
                    [types.REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR_ERR]:
                        data.Code,
                },
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(
            err,
            dispatch,
            types.REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR_ERR,
            false
        )
    }
}

/**
 *
 * @param {string} deviceMac identif of desktop
 * @param {string} connectId connect id of counter was generate in admin dashboard
 * @param {string} pwd connect password of counter was generate in admin dashboard
 */
export const connectDesktop = (deviceMac, connectId, pwd) => async (
    dispatch,
    getState
) => {
    const store = getState()
    const { commonReducer } = store
    const queryDataFormat = JSON.stringify({
        Name: commonReducer.clientName,
        DeviceMac: commonReducer.clientName,
        Pwd: pwd,
        ConnectID: connectId,
    })

    try {
        const res = await Axios.post(apiConnectDesktop, queryDataFormat)

        const { data } = res
        if (data.Code === typeCodeResponses.CODE_SUCCESS) {
            localStorage.setItem('dataConnectDesktop', JSON.stringify(data))
            dispatch(setDataConnectDesktop(data))
        } else
            dispatch({
                type: types.CONNECT_DESKTOP_ERR,
                err: data,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.CONNECT_DESKTOP_ERR)
    }
}

export const setDataConnectDesktop = (data) => ({
    type: types.CONNECT_DESKTOP,
    data,
})

/**
 *
 * @param {string} counterId id of counter need disconnect
 * @param {string} officeId id of office contain this counter
 * @param {string} deviceId id of device request disconnect
 */
export const disconnectDesktop = (counterId, officeId, deviceId) => async (
    dispatch
) => {
    // const queryDataFormat = JSON.stringify({
    //   CounterID: counterId,
    //   OfficeID: officeId,
    //   DeviceID: deviceId
    // });

    dispatch({
        type: types.DISCONNECT_DESKTOP,
    })

    // try {
    //   const res = await Axios.post(apiDisconnectDesktop, queryDataFormat);
    // console.log(res);
    //   const { data } = res;

    //   if (data.Code === typeCodeResponses.CODE_SUCCESS) {

    //     dispatch({
    //       type: types.DISCONNECT_DESKTOP
    //     });
    //   } else
    //     dispatch({
    //       type: types.DISCONNECT_DESKTOP_ERR,
    //       err: data
    //     });
    // } catch (err) {
    // console.dir(err);
    //   dispatch({
    //     type: types.DISCONNECT_DESKTOP_ERR,
    //     err
    //   });
    // }
}

/**
 *
 * @param {{
 * Email*: string, //email of teller login
 * Pwd*: string,  //password of teller login
 * CounterID*: string, //id of counter that reponsed when connect to desktop
 * OfficeID*: string, //id of office that reponsed when connect to desktop
 * DeviceID*: string //id of device that reponsed when connect to desktop
 * }} dataLogin
 */
export const tellerLoginCounter = (dataLogin) => async (dispatch) => {
    const queryDataFormat = JSON.stringify({
        ...dataLogin,
    })
    try {
        Axios.defaults.withCredentials =
            process.env.NODE_ENV === 'production' ? false : true
        const res = await Axios.post(apiTellerLoginCounter, queryDataFormat)
        const { data } = res
        const isLoginSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isLoginSuccess) {
            localStorage.setItem('dataLogin', JSON.stringify(data))
            setAuth(data)
            dispatch(setCurrentTeller(data))
        } else
            dispatch({
                type: types.COUNTER_LOGIN_ERR,
                err: data,
            })
    } catch (err) {
        if (
            err.response &&
            err.response.status === typesStatusCode.CODE_BAD_REQUEST &&
            err.response.data &&
            err.response.data.Code
        ) {
            dispatch({
                type: types.COUNTER_LOGIN_ERR,
                err: {
                    [types.COUNTER_LOGIN_ERR]: err.response.data.Code,
                },
            })
            return
        }
        dispatch({
            type: types.COUNTER_LOGIN_ERR,
            err: {
                [types.COUNTER_LOGIN_ERR]: 100,
            },
        })
    }
}

export const setCurrentTeller = (data) => ({
    type: types.COUNTER_LOGIN,
    data,
})

/**
 *
 * @param {{
 * UID*: id of user loged in
 * CounterID*: string, //id of counter that reponsed when connect to desktop
 * DeviceID*: string //id of device that reponsed when connect to desktop
 * }} dataLogout
 */
export const tellerLogoutCounter = (dataLogout) => async (dispatch) => {
    dispatch(resetStore())
    const queryDataFormat = JSON.stringify({
        ...dataLogout,
    })
    try {
        await Axios.post(apiTellerLogoutCounter, queryDataFormat)
    } catch (err) {
        catchErrAction(err, dispatch, types.COUNTER_LOGOUT_ERR)
    }
    Axios.defaults.withCredentials = false
    setAuth(false)
}

export const resetStore = () => ({
    type: types.COUNTER_LOGOUT,
})

/**
 *
 * @param {string*} userId id of user was loged in to web
 */
export const getListTypeCounterServices = (userId) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        UID: userId,
    })

    try {
        const res = await Axios.post(
            apiGetListTypeCounterServices,
            queryDataFormat
        )
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.GET_LIST_TYPE_COUNTER_SERVICES,
                listService: data.ListService ? data.ListService : [],
            })
        else
            dispatch({
                type: types.GET_LIST_TYPE_COUNTER_SERVICES_ERR,
                err: data,
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.GET_LIST_TYPE_COUNTER_SERVICES_ERR)
    }
}

/**
 *
 * @param {object} ticket data of ticket enqueue to waiting ticket queue
 */
export const enqueueWaitingTicket = (ticket) => (dispatch) => {
    // console.log(ticket)
    dispatch({
        type: types.SOCKET_ENQUEUE_TICKET,
        ticket,
    })
}

/**
 *
 * @param {string} ticketId data of ticket dequeue from waiting ticket queue
 */
export const dequeueWaitingTicket = (ticketId) => (dispatch) => {
    // console.log(ticketId);
    dispatch({
        type: types.SOCKET_DEQUEUE_TICKET,
        ticketId,
    })
}

/**
 *
 * @param {string} counterId id of counter loged in
 * @param {string} officeId id of office which counter login
 */
export const getAllTicketWaiting = (counterId, officeId) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        OfficeID: officeId,
    })

    try {
        const res = await Axios.post(apiGetTicketInQueue, queryDataFormat)
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        dispatch(removeLoading())
        if (isSuccess)
            dispatch({
                type: types.GET_ALL_TICKET_WAITING,
                listTicket: data.ListTicket ? data.ListTicket : [],
                listMissCalled: data.ListMissCalled || [],
                listMoveTo: data.ListMoveTo || [],
                listVipTicket: data.ListVIPTicket || [],
            })
        else
            dispatch({
                type: types.GET_ALL_TICKET_WAITING_ERR,
                err: data,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.GET_ALL_TICKET_WAITING_ERR)
    }
}

/**
 *
 * @param {string} counterId id of counter loged in
 * @param {string} customerNumber number identify of customer
 * @param {string} typeSearch ticketid of ticket
 */
export const getInfoCustomer = (
    counterId,
    customerNumber,
    typeSearch = 0
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        CifsNum: customerNumber,
        Type: typeSearch,
    })

    try {
        const res = await Axios.post(apiGetCifs, queryDataFormat)
        const { data } = res
        const isSuccess =
            data.Code === typeCodeResponses.CODE_SUCCESS &&
            data.Cifs &&
            data.Cifs.length > 0
        dispatch(removeLoading())
        if (isSuccess) {
            dispatch({
                type: types.GET_CUSTOMER_INFORMATION,
                data,
            })
        } else
            dispatch({
                type: types.GET_CUSTOMER_INFORMATION_ERR,
                err: {
                    [types.GET_CUSTOMER_INFORMATION_ERR]: 0,
                },
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.GET_CUSTOMER_INFORMATION_ERR)
    }
}

/**
 *
 * @param {string} counterId id of counter
 * @param {string} ticketId id of ticket
 * @param {boolean} forceComplete true if ignore biometric data haven't confirmed yet.
 */
export const completeTicket = (
    counterId,
    ticketId,
    forceComplete = false
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        TicketID: ticketId,
        ForceComplete: forceComplete,
    })

    try {
        const res = await Axios.post(apiCompleteTicket, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.COMPLETE_TICKET,
                ticketId,
            })
        else
            dispatch({
                type: types.COMPLETE_TICKET_ERR,
                err: {
                    [types.COMPLETE_TICKET_ERR]: data.Code,
                },
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.COMPLETE_TICKET_ERR)
    }
}

// export const deleteTicketInProgress = ticketId => dispatch => {
//   dispatch({
//     type: types.DELETE_TICKET_IN_PROGRESS,
//     ticketId
//   })
// }

///////////////////////////////////////////////////
//////////// T.Anh test API ^^ ///////////////////
/////////////////////////////////////////////////
/**
 *
 * @param {
 * CounterID* : String //id of counter that reponsed when connect to desktop
 * DeviceID* : String // //id of device that reponsed when connect to desktop
 * }
 */

/**
 *
 * @param {string*} ticketId id of ticket was called
 * @param {object*} cusInfo info of customer from api /counter/get-cifs
 */
export const requestEnroll = (ticketId, cusInfo) => async (
    dispatch,
    getState
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        CustomerInfos: JSON.parse(JSON.stringify(cusInfo)),
    })

    const currentState = getState()
    const currentTicket = currentState.counterReducer.listCurrentTicket.find(
        (item) => item.TicketID === ticketId
    )

    try {
        const res = await Axios.post(apiRequestEnroll, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS

        if (isSuccess) {
            if (currentTicket.bioInfosType === 'NOTREGISTER') {
                dispatch({
                    type: types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO,
                    newEnrollId: data.EnrollID,
                    ticketId,
                })
                return
            }
            dispatch({
                type: types.REQUEST_ENROLL,
                enrollId: data.EnrollID,
                ticketId,
            })
        } else
            dispatch({
                type: types.REQUEST_ENROLL_ERR,
                err: data,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.REQUEST_ENROLL_ERR)
    }
}

/**
 *
 * @param {string*} ticketId id of ticket was called
 * @param {number*} cusNumber info of customer from api /counter/get-cifs
 */
export const requestUpDateBio = (ticketId, cusNumber) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        CustomerNumber: cusNumber,
    })

    try {
        const res = await Axios.post(apiRequestUpdateBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS

        if (isSuccess)
            dispatch({
                type: types.REQUEST_UPDATE_BIO,
                enrollId: data.EnrollID,
                ticketId,
            })
        else
            dispatch({
                type: types.REQUEST_UPDATE_BIO_ERR,
                err: data,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.REQUEST_UPDATE_BIO_ERR, false)
    }
}

export const getTicket = (counterId, deviceId) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        DeviceID: deviceId,
    })

    try {
        const res = await Axios.post(apiGetTicket, queryDataFormat)
        dispatch(removeLoading())
        let data = res.data
        const isSuccess =
            data.Code === typeCodeResponses.CODE_SUCCESS && data.TicketData
        if (isSuccess) {
            dispatch({
                type: types.GET_TICKET,
                data,
            })
        } else {
            dispatch({
                type: types.GET_TICKET_ERR,
                err: {
                    [types.GET_TICKET_ERR]: data.Code,
                },
            })
        }
    } catch (err) {
        catchErrAction(err, dispatch, types.GET_TICKET_ERR)
    }
}

//////////////////////// GET CURRENTS TICKETS //////////////
/**
 * @param {string*} CounterID
 * @param {string*} OfficeID
 * @private {apiGetCurrentTickets} api getCurrentTickets
 */

export const getCurrentTickets = (CounterID, OfficeID) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: CounterID,
        OfficeID: OfficeID,
    })

    try {
        const res = await Axios.post(apiGetCurrentTickets, queryDataFormat)
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        dispatch(removeLoading())
        if (isSuccess) {
            dispatch({
                type: types.GET_CURRENT_TICKETS,
                data,
            })
        } else {
            dispatch({
                type: types.GET_CURRENT_TICKETS_ERR,
                err: data,
            })
        }
    } catch (err) {
        catchErrAction(err, dispatch, types.GET_CURRENT_TICKETS_ERR)
    }
}

export const pauseTicket = (counterId, ticketId) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        TicketID: ticketId,
    })

    try {
        const res = await Axios.post(apiPauseTicket, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.PAUSE_TICKET,
                ticketId,
                totalTime: data.TotalTime,
            })
        else
            dispatch({
                type: types.PAUSE_TICKET_ERR,
                err: data,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.PAUSE_TICKET_ERR)
    }
}

export const unpauseTicket = (counterId, ticketId) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        TicketID: ticketId,
    })

    try {
        const res = await Axios.post(apiUnpauseTicket, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.UNPAUSE_TICKET,
                ticketId,
                totalTime: data.TotalTime,
            })
        else
            dispatch({
                type: types.UNPAUSE_TICKET_ERR,
                err: data,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.UNPAUSE_TICKET_ERR)
    }
}

/**
 * [description]
 * @param  {string} counterId    id of counter
 * @param  {"O" \| "S"} stateCounter state was set for counter: "O"-open, "S"-stop
 * @return {void}
 */
export const startOrStopCounter = (counterId, stateCounter) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        State: stateCounter,
    })

    try {
        const res = await Axios.post(apiStartOrStopCounter, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) dispatch(changeStateCounter())
        else
            dispatch({
                type: types.CHANGE_STATE_COUNTER_ERR,
                err: data,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.CHANGE_STATE_COUNTER_ERR)
    }
}

export const changeStateCounter = (status = undefined) => ({
    type: types.CHANGE_STATE_COUNTER,
    status,
})

/**
 * [description]
 * @param  {object} customerInfos
 * @param  {string} ticketID
 * Use for "KH vãng lai", when you check CIF success ---> CustomerInfos will update to listCurrentTicket for user
 */

export const addCustomerInfos = (
    customerInfos,
    ticketID,
    isChangeTicket = false,
    roles = ['TELLER']
) => async (dispatch) => {
    if (!roles.includes('TELLER')) {
        dispatch({
            type: types.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET,
            ticketID,
            customerInfos,
        })
        return
    }
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketID,
        CustomerNumber: customerInfos.CustomerNumber,
    })
    try {
        const res = await Axios.post(
            apiAddCustomerInfosToTicket,
            queryDataFormat
        )
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            if (isChangeTicket) {
                dispatch({
                    type: types.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET,
                    oldTicketId: ticketID,
                    ticketData: data.TicketData,
                    isChangeTicket,
                })
                return
            }
            dispatch({
                type: types.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET,
                ticketID,
                customerInfos,
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(
            err,
            dispatch,
            types.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR
        )
    }
}

export const addInprogressBioExistFromCustomerInfo = (
    inprogressBio,
    ticketId
) => (dispatch) => {
    // debugger;
    // add biometric inprogress
    dispatch({
        type: types.GET_CURRENT_BIO_ENROLL,
        payload: {
            ticketId,
            enrollId: null,
            customerInfo: null,
            bioDatas: inprogressBio.BioDatas || [],
        },
    })

    // add CurrentBioInfos - biometric was accept by supervisor
    dispatch(
        addBiometricExistFromCustomerInfos(
            { BioDatas: inprogressBio.CurrentBioInfos },
            ticketId
        )
    )

    // add enrollId
    dispatch({
        type: types.ADD_INPROGRESS_BIO_EXIST,
        enrollId: inprogressBio.ID,
        ticketId,
        currentBioInfos: inprogressBio.CurrentBioInfos,
    })
}

export const addBiometricExistFromCustomerInfos = (
    bioInfos,
    ticketId,
    statusBiometric
) => (dispatch) => {
    dispatch({
        type: types.ADD_BIOMETRIC_EXIST,
        bioInfos,
        ticketId,
    })
}

export const moveToMissCall = (counterId, ticketId) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        TicketID: ticketId,
    })

    try {
        const res = await Axios.post(apiMoveToMissCall, queryDataFormat)
        dispatch(removeLoading())
        const isSuccess = res.data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.MOVE_TICKET_TO_MISS_CALL,
                ticketId,
            })
        else
            dispatch({
                type: types.MOVE_TICKET_TO_MISS_CALL_ERR,
                err: {
                    [types.MOVE_TICKET_TO_MISS_CALL_ERR]: res.Data.Code,
                },
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.MOVE_TICKET_TO_MISS_CALL_ERR)
    }
}

export const getOtherCounterInfos = (counterId, officeId) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        OfficeID: officeId,
    })

    try {
        const res = await Axios.post(apiGetOtherCounterInfos, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.GET_OTHER_COUNTER_INFOS,
                listOtherCounterInfos: data.Infos || [],
            })
        else
            dispatch({
                type: types.GET_OTHER_COUNTER_INFOS_ERR,
                err: {
                    [types.GET_OTHER_COUNTER_INFOS_ERR]: data.Code,
                },
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.GET_OTHER_COUNTER_INFOS_ERR)
    }
}

export const moveTicketToOtherCounter = (
    ticketId,
    toCounterId,
    moveNote,
    fromCounterId
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        ToCounterID: toCounterId,
        MoveNote: moveNote.trim(),
        FromCounterID: fromCounterId,
    })

    try {
        const res = await Axios.post(
            apiMoveTicketToOtherCounter,
            queryDataFormat
        )
        dispatch(removeLoading())
        // console.log(res)
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.MOVE_TICKET_TO_OTHER_COUNTER,
                ticketId,
            })
        else
            dispatch({
                type: types.MOVE_TICKET_TO_OTHER_COUNTER_ERR,
                err: {
                    [types.MOVE_TICKET_TO_OTHER_COUNTER_ERR]: data.Code,
                },
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.MOVE_TICKET_TO_OTHER_COUNTER_ERR)
    }
}

export const callTicket = (
    ticketID,
    counterID,
    officeID,
    ticketStatus
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketID,
        CounterID: counterID,
        OfficeID: officeID,
    })

    try {
        const res = await Axios.post(apiCallTicket, queryDataFormat)
        dispatch(removeLoading())

        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.CALL_TICKET,
                data,
                ticketStatus,
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.CALL_TICKET_ERR)
    }
}

export const enqueueTicketMoveTo = (ticket) => (dispatch) => {
    dispatch({
        type: types.SOCKET_RECEIVE_TICKET_FROM_OTHER_COUNTER,
        ticket,
    })
}

/**
 *
 * @param {string*} enrollId
 * @param {string} newFullNameVi
 * @param {string} newMobile
 */

export const updateBioInfo = (
    enrollId,
    newFullNameVi,
    newMobile,
    ticketId = null
) => async (dispatch) => {
    dispatch(addLoading())
    const data = {}
    if (!enrollId || !ticketId) {
        dispatch(removeLoading())
        return
    }
    data.EnrollID = enrollId
    if (typeof newFullNameVi === 'string')
        data.NewFullNameVi = newFullNameVi.trim()
    if (typeof newMobile === 'string') data.NewMobileNumber = newMobile.trim()
    const queryDataFormat = JSON.stringify(data)
    try {
        const res = await Axios.post(apiUpdateBioInfo, queryDataFormat)
        dispatch(removeLoading())

        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.UPDATE_BIO_INFO,
                newFullNameVi: newFullNameVi && newFullNameVi.trim(),
                newMobile: newMobile && newMobile.trim(),
                ticketId,
            })
        else
            dispatch({
                type: types.UPDATE_BIO_INFO_ERR,
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.UPDATE_BIO_INFO_ERR)
    }
}

/**
 *
 * @param {{
 * ticketID*: {string} // ticketID when counter call ticket
 * cif*: {string} // cif go with customer (unique with customer)
 * reasonNRFace: {string} // Why customers don't want to register face
 * reasonNRFinger: {string} // Why customers don't want to register finger
 * }} dataNotRegister
 */
export const reasonNotRegister = (dataNotRegister) => async (dispatch) => {
    dispatch(addLoading())
    const data = {}
    data.TicketID = dataNotRegister.ticketID
    data.Cifs = dataNotRegister.cif
    data.EnrollID = dataNotRegister.enrollId

    if (dataNotRegister.reasonNRFace && dataNotRegister.reasonNRFace.trim())
        data.ReasonNR = dataNotRegister.reasonNRFace.trim()

    const queryDataFormat = JSON.stringify(data)

    try {
        const res = await Axios.post(apiReasonNotRegisterBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.REASON_NOT_REGISTER,
                ticketId: dataNotRegister.ticketID,
            })
            if (data.SkipReview) {
                setTimeout(() => {
                    dispatch(
                        setStatusSeenAuthOfTicket(dataNotRegister.ticketID)
                    )
                }, 1000)
            }
        } else
            dispatch({
                type: types.REASON_NOT_REGISTER_ERR,
                err: {
                    [types.REASON_NOT_REGISTER_ERR]: data.Code,
                },
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.REASON_NOT_REGISTER_ERR)
    }
}

export const requestAuthenticateClient = (ticketId) => (dispatch) => {
    dispatch({
        type: types.REQUEST_AUTHENTICATE_CLIENT,
        ticketId,
    })
}

/**
 * [description]
 * @param  {{
 *        ticketId: string,
 *        code: number,
 *        listBioTypes: array,
 *        dataOtherClient: {
 *          CustomerNumber: string,
 *          Name: string,
 *          IdNumber: string,
 *          MobileNumber: string,
 *          officeRegister: string,
 *          TellerRegister: string,
 *          supvisorAuthen: string,
 *          registerAt: string
 *        }
 * }} data [description]
 * @return {[type]}      [description]
 */
// listCurrentTicket: [
//   {
// authenticateTicket: {
//    waitingAuthentication: boolean || false,
//  }
//   }
// ]

export const receiveResultAuthenticateClient = (data) => (dispatch) => {
    // const CODE_SUCCESS_AUTHENTICATE = 0;
    // const CODE_FAIL_AUTHENTICATE = 101;
    // const CODE_FAIL_SAME_OTHER_CLIENT = 201;
    // set waitingAuthentication = false

    dispatch({
        type: types.SOCKET_RECEIVE_RESULT_AUTHENTICATE,
        payload: {
            ...data,
        },
    })
}

export const recallTicket = (ticketId) => async (dispatch) => {
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
    })

    try {
        const res = await Axios.post(apiRecallTicket, queryDataFormat)

        const isSuccess = res.data.Code === typeCodeResponses.CODE_SUCCESS
        if (!isSuccess)
            dispatch({
                type: types.RECALL_TICKET_ERR,
                err: {
                    [types.RECALL_TICKET_ERR]: res.data.Code,
                },
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.RECALL_TICKET_ERR)
    }
}

export const createNewTicket = (counterId, customerNumber) => async (
    dispatch, getState
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        CustomerNumber: customerNumber,
    })
    try {
        const res = await Axios.post(apiCreateNewTicket, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess){
            const store = getState()
            const { counterReducer: { customerInfo: { LatestHistoryType } } } = store
            const extendData = {
                LatestHistoryType
            }
            dispatch(createTicket(data.TicketData, extendData))
        }
        else
            dispatch({
                type: types.CREATE_NEW_TICKET_ERR,
                err: {
                    [types.CREATE_NEW_TICKET_ERR]: data.Code,
                },
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.CREATE_NEW_TICKET_ERR)
    }
}

export const createTicket = (ticketData, extendData) => ({
    type: types.CREATE_NEW_TICKET,
    ticketData,
    extendData
})

export const clearCustomerInfosInStore = () => ({
    type: types.CLEAR_CUST_INFOS_IN_STORE,
})

export const finishWaitingEvalueate = (ticketId) => ({
    type: types.FINISH_WAITING_EVALUEATE,
    ticketId,
})

export const setStatusSeenAuthOfTicket = (ticketId) => ({
    type: types.SOCKET_VERIFY_FINGER_SEEN,
    ticketId,
})

export const clearHistoryCustomer = (ticketId) => ({
    type: types.CLEAR_LIST_HISTORY_CUST,
    ticketId,
})

export const updateTicketInfos = (
    ticketId,
    {
        FullNameVi,
        MobileNumber,
        BiometricFace,
        BiometricFinger,
        BiometricActive,
    }
) => ({
    type: types.UPDATE_TICKET_INFOS,
    payload: {
        ticketId,
        dataChanged: {
            FullNameVi,
            MobileNumber,
            BiometricFace,
            BiometricFinger,
            BiometricActive,
        },
    },
})

export const confirmUserPrinted = (ticketId) => ({
    type: types.CONFIRM_USER_PRINTED,
    ticketId,
})

export const clearStatusPrinted = (ticketId) => ({
    type: types.CLEAR_STATUS_PRINTED,
    ticketId,
})
