import styled from 'styled-components'

const WrapListWaitingCollector = styled.div`
    font-family: 'SVN-Gilroy';
    margin-top: 31px;
    .title {
        margin-left: 25px;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 128%;
        color: #005993;
        text-align: center;
        text-transform: uppercase;
    }

    .notice {
        margin-top: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 12px;
            height: 12px;
        }
        div {
            margin-left: 4px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 90%;
            color: var(--vermillion);
        }
    }

    .wrap-tools {
        margin: 0 25px 0 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;

        /* & > *:not(.notice):not(:nth-child(2)) {
            flex-grow: 1;
        } */

        & > * {
            margin-top: 17px;
        }
    }

    .wrap-table {
        /* max-height: calc((5 * 40px) + (5 * 4px)); */
        overflow-y: overlay;
        box-sizing: border-box;

        ::-webkit-scrollbar {
            width: 10px;
            background: rgba(120,120,120,0.2);
        }
        ::-webkit-scrollbar-thumb {
            background: gray;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: /* 1005 */
            calc(5% - 15px)                    /* STT */
            calc(15% - 15px)                   /* Họ tên KH */
            calc(9% + 2% - 15px)                    /* CMND/ CCCD/ HC */
            /* calc(9% - 15px)                    Số CIF */
            calc(7% + 2% - 15px)                    /* Thời gian chờ */
            calc(7% + 2% - 15px)                    /* User tác nghiệp */
            calc(12% + 1% - 15px)                   /* Thời gian tác nghiệp */
            calc(8% + 2% - 15px)                    /* Người phê duyệt */
            calc(12% + 1% - 15px)                   /* Thời gian phê duyệt */
            15%;                               /* Trạng thái dữ liệu */
        grid-column-gap: 15px;
        min-height: 40px;
        align-items: center;

        &.grid-title {
            margin: 30px 0 0;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 110%;
            color: #005993;
        }

        &.grid-item {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 128%;
            color: #000000;
            background: #FFFFFF;
            border-radius: 5px;

            &:not(:first-child) {
                margin-top: 4px;
            }

            & > *:not(.data-status) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .auto-approved {
                color: rgb(15,177,31);
                font-weight: bold;
            }

            .data-status {
                display: flex;
                justify-content: space-between;

                .wrap-action {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .btn-show-cust-infos {
                        color: var(--peacock-blue);
                        text-decoration: underline;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .call-arrow {
                        margin-left: 10px;
                        clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
                        width: 28px;
                        height: 19px;
                        background-color: #005993;
                        cursor: pointer;
                    }
                }
                
            }

            /* &:hover {
                cursor: pointer;
            } */

        }

        & > *:first-child {
            padding-left: 13px;
        }

        & > *:last-child {
            padding-right: 20px;
        }
    }
`;

export {
    WrapListWaitingCollector
}