import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReactToPrint from 'react-to-print'
import moment from 'moment'

// styles
import { PopupPrintV2Styles } from './index.styles'

// data
import { bioTypes, nameFingerPrint } from '../../../data/bioTypes'
import {
    AUTH_TRANS,
    REGISTER,
    VALIDATE_DATA,
    ISHANG,
} from '../../../actions/typeRequestBio'

// helper
import getDataAdministrativeUnitsByCode, { fieldAdministrativeUnits } from "../../../helper/getDataAdministrativeUnitsByCode"


// action
import { confirmUserPrinted } from '../../../actions/counterAction';

// component
import RegisterForm from './RegisterForm'
import ButtonConfirmControl, { Bound } from '../../Control/ButtonConfirmControl'
import UpdateForm from './UpdateForm'

export const registerTypes = {
    face: 'FACE',
    faceAndFinger: 'FACE_AND_FINGER',
}

export const updateTypes = {
    changeImgFace: 'CHANGE_IMG_FACE',
    closedFace: 'CLOSED_FACE',
    activedFace: 'ACTIVE_FACE',
}

const PopupPrintV2 = ({ onClose, data }) => {
    const { ticketId } = data

    const dispatch = useDispatch();
    const componentPrintRef = useRef(null)

    const { counterReducer, biometricReducer } = useSelector((state) => ({
        counterReducer: state.counterReducer,
        biometricReducer: state.biometricReducer,
    }))

    const { listCurrentTicket, dataLogin } = counterReducer

    const currentTicket = listCurrentTicket.find(
        (item) => item.TicketID === ticketId
    )
    if (!currentTicket) return null
    if (!currentTicket.CustomerInfos) return null

    const getDataPrintRegisterForm = (biometricActive, biometricFace) => {
        if (biometricActive === 1 && biometricFace !== 0)
            return {
                registerType: null,
                arrFingerRegister: null,
            }
        const { ticketId } = data
        const bioOfTicket = biometricReducer[ticketId]
        if (!bioOfTicket)
            return {
                registerType: '',
                arrFingerRegister: new Array(5)
                    .fill(null)
                    .map((item) => new Array(2).fill('')),
            }
        const biometricTypeOfTicket = Object.keys(bioOfTicket)
        const bioHaveFace = biometricTypeOfTicket.some(
            (item) => item === bioTypes.FACE
        )
        const bioHaveFinger = biometricTypeOfTicket.some(
            (item) => item !== bioTypes.FACE
        )
        const registerType =
            bioHaveFace && bioHaveFinger
                ? registerTypes.faceAndFinger
                : registerTypes.face
        // const bioFingerOfTicket = biometricTypeOfTicket.filter(item => item !== bioTypes.FACE);
        const arrFingerRegister = new Array(5)
            .fill(null)
            .map((item) => new Array(2).fill(''))
        const checkFinger = {
            [bioTypes.THUMB_RIGHT]: () => {
                const item = bioTypes.THUMB_RIGHT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[0][1] = 'NO_CHECK_Ngón cái'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[0][1] = 'Ngón cái (Vân tay giao dịch)'
                else arrFingerRegister[0][1] = 'Ngón cái'
            },
            [bioTypes.THUMB_LEFT]: () => {
                const item = bioTypes.THUMB_LEFT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[0][0] = 'NO_CHECK_Ngón cái'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[0][0] = 'Ngón cái (Vân tay giao dịch)'
                else arrFingerRegister[0][0] = 'Ngón cái'
            },
            [bioTypes.INDEX_RIGHT]: () => {
                const item = bioTypes.INDEX_RIGHT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[1][1] = 'NO_CHECK_Ngón trỏ'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[1][1] = 'Ngón trỏ (Vân tay giao dịch)'
                else arrFingerRegister[1][1] = 'Ngón trỏ'
            },
            [bioTypes.INDEX_LEFT]: () => {
                const item = bioTypes.INDEX_LEFT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[1][0] = 'NO_CHECK_Ngón trỏ'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[1][0] = 'Ngón trỏ (Vân tay giao dịch)'
                else arrFingerRegister[1][0] = 'Ngón trỏ'
            },
            [bioTypes.MIDDLE_RIGHT]: () => {
                const item = bioTypes.MIDDLE_RIGHT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[2][1] = 'NO_CHECK_Ngón giữa'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[2][1] = 'Ngón giữa (Vân tay giao dịch)'
                else arrFingerRegister[2][1] = 'Ngón giữa'
            },
            [bioTypes.MIDDLE_LEFT]: () => {
                const item = bioTypes.MIDDLE_LEFT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[2][0] = 'NO_CHECK_Ngón giữa'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[2][0] = 'Ngón giữa (Vân tay giao dịch)'
                else arrFingerRegister[2][0] = 'Ngón giữa'
            },
            [bioTypes.RING_RIGHT]: () => {
                const item = bioTypes.RING_RIGHT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[3][1] = 'NO_CHECK_Ngón áp út'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[3][1] = 'Ngón áp út (Vân tay giao dịch)'
                else arrFingerRegister[3][1] = 'Ngón áp út'
            },
            [bioTypes.RING_LEFT]: () => {
                const item = bioTypes.RING_LEFT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[3][0] = 'NO_CHECK_Ngón áp út'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[3][0] = 'Ngón áp út (Vân tay giao dịch)'
                else arrFingerRegister[3][0] = 'Ngón áp út'
            },
            [bioTypes.PINKY_RIGHT]: () => {
                const item = bioTypes.PINKY_RIGHT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[4][1] = 'NO_CHECK_Ngón út'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[4][1] = 'Ngón út (Vân tay giao dịch)'
                else arrFingerRegister[4][1] = 'Ngón út'
            },
            [bioTypes.PINKY_LEFT]: () => {
                const item = bioTypes.PINKY_LEFT
                if (!bioOfTicket[item]) {
                    arrFingerRegister[4][0] = 'NO_CHECK_Ngón út'
                    return
                }
                if (bioOfTicket[item][AUTH_TRANS])
                    arrFingerRegister[4][0] = 'Ngón út (Vân tay giao dịch)'
                else arrFingerRegister[4][0] = 'Ngón út'
            },
        }

        const lstBioFingerType = Object.keys(bioTypes).filter(
            (item) => item !== bioTypes.FACE
        )

        lstBioFingerType.forEach((item, i) => {
            if (checkFinger[item]) {
                checkFinger[item]()
            }
        })

        return {
            registerType,
            arrFingerRegister,
        }
    }

    const getDataPrintUpdateForm = (biometricActive) => {
        if (biometricActive !== 1)
            return {
                bioFaceContentUpdate: null,
                arrFingerUpdate: null,
            }
        const { ticketId } = data
        const bioOfTicket = biometricReducer[ticketId]
        // if(!bioOfTicket)
        //     return {
        //         registerType: "",
        //         arrFingerRegister: new Array(5).fill(null).map(item => new Array(2).fill(""))
        //     }
        const biometricTypeOfTicket = Object.keys(bioOfTicket)
        const bioHaveFace = biometricTypeOfTicket.some(
            (item) => item === bioTypes.FACE
        )
        const validateFaceData =
            bioHaveFace && bioOfTicket[bioTypes.FACE][VALIDATE_DATA]

        const isUpdateImgFace =
            bioHaveFace && !!bioOfTicket[bioTypes.FACE][REGISTER]
        const isUpdateClosedFace =
            bioHaveFace &&
            !!validateFaceData &&
            !validateFaceData[ISHANG] &&
            bioOfTicket[bioTypes.FACE][ISHANG]
        const isActivedFace =
            bioHaveFace &&
            !!validateFaceData &&
            validateFaceData[ISHANG] &&
            Object.keys(bioOfTicket[bioTypes.FACE]).includes(ISHANG) &&
            !bioOfTicket[bioTypes.FACE][ISHANG]
        const bioFaceContentUpdate = isUpdateImgFace
            ? updateTypes.changeImgFace
            : isUpdateClosedFace
                ? updateTypes.closedFace
                : isActivedFace
                    ? updateTypes.activedFace
                    : ''

        // const bioHaveFinger = biometricTypeOfTicket.some(item => item !== bioTypes.FACE)
        // const bioFinger = biometricTypeOfTicket.filter(item => item !== bioTypes.FACE)
        const checkFinger = (typeFinger) => {
            const fingerItem = bioOfTicket[typeFinger]
            if (!fingerItem)
                return [nameFingerPrint[typeFinger].nameNormal, '.'.repeat(30)]

            const haveValidateData = fingerItem[VALIDATE_DATA]
            if (!!haveValidateData && Object.keys(fingerItem).length === 1)
                return [nameFingerPrint[typeFinger].nameNormal, '.'.repeat(30)]

            switch (true) {
            case !haveValidateData: {
                if (fingerItem[AUTH_TRANS])
                    return [
                        nameFingerPrint[typeFinger].nameNormal,
                        'Vân tay giao dịch',
                    ]
                return [nameFingerPrint[typeFinger].nameNormal, 'Đóng']
                // return [nameFingerPrint[typeFinger].nameNormal, "Đăng ký bổ sung"]
            }
            case !!haveValidateData &&
                    fingerItem.bioActionType === 'REMOVE':
                return [nameFingerPrint[typeFinger].nameNormal, 'Xoá']
            case !!haveValidateData: {
                // const validateAuth = haveValidateData[AUTH_TRANS];
                // const validateHang = haveValidateData[ISHANG];

                const updateAuth = Object.keys(fingerItem).includes(
                    AUTH_TRANS
                )
                const updateHang = Object.keys(fingerItem).includes(ISHANG)
                // debugger
                switch (true) {
                case updateAuth &&
                            fingerItem[AUTH_TRANS] &&
                            updateHang &&
                            fingerItem[ISHANG] &&
                            haveValidateData[AUTH_TRANS] ===
                                fingerItem[AUTH_TRANS] &&
                            haveValidateData[ISHANG] === fingerItem[ISHANG]:
                    return [
                        nameFingerPrint[typeFinger].nameNormal,
                        '.'.repeat(30),
                    ]
                    // treo
                case updateAuth &&
                            fingerItem[AUTH_TRANS] &&
                            updateHang &&
                            fingerItem[
                                ISHANG
                            ] /* ) ||
                            (validateAuth && !validateHang) */:
                    return [
                        nameFingerPrint[typeFinger].nameNormal,
                        'Treo',
                    ]
                    //  hoạt động
                case updateAuth &&
                            fingerItem[
                                AUTH_TRANS
                            ] /*  &&
                            !validateAuth */:
                    return [
                        nameFingerPrint[typeFinger].nameNormal,
                        'Vân tay giao dịch',
                    ]
                case updateAuth &&
                            !fingerItem[
                                AUTH_TRANS
                            ] /*  &&
                            validateAuth */:
                    return [
                        nameFingerPrint[typeFinger].nameNormal,
                        'Đóng',
                    ]
                default:
                    return [
                        nameFingerPrint[typeFinger].nameNormal,
                        '.'.repeat(30),
                    ]
                }
                // break;
            }
            default:
                break
            }
        }

        // const allTypeFingerBio = Object.keys(bioTypes).filter(item => item !== bioTypes.FACE)
        const arrFingerUpdate = new Array(5)
            .fill(null)
            .map((item) => new Array(4).fill(''))

        const thumbRightData = checkFinger(bioTypes.THUMB_RIGHT)
        const thumbLeftData = checkFinger(bioTypes.THUMB_LEFT)
        arrFingerUpdate[0] = [...thumbLeftData, ...thumbRightData]

        const indexRight = checkFinger(bioTypes.INDEX_RIGHT)
        const indexLeft = checkFinger(bioTypes.INDEX_LEFT)
        arrFingerUpdate[1] = [...indexLeft, ...indexRight]

        const middleRight = checkFinger(bioTypes.MIDDLE_RIGHT)
        const middleLeft = checkFinger(bioTypes.MIDDLE_LEFT)
        arrFingerUpdate[2] = [...middleLeft, ...middleRight]

        const ringRight = checkFinger(bioTypes.RING_RIGHT)
        const ringLeft = checkFinger(bioTypes.RING_LEFT)
        arrFingerUpdate[3] = [...ringLeft, ...ringRight]

        const pinkyRight = checkFinger(bioTypes.PINKY_RIGHT)
        // debugger
        const pinkyLeft = checkFinger(bioTypes.PINKY_LEFT)
        arrFingerUpdate[4] = [...pinkyLeft, ...pinkyRight]

        return {
            bioFaceContentUpdate,
            arrFingerUpdate,
        }
    }

    const initialBiometricActive =
        !!currentTicket.dataWasAuthen &&
        Number(currentTicket.dataWasAuthen.BiometricActive)
    const initialBiometricFace =
        !!currentTicket.dataWasAuthen &&
        Number(currentTicket.dataWasAuthen.BiometricFace)
    const officeName = dataLogin.OfficeName

    const nowDate = moment()
    const nowDay = nowDate.format('DD')
    const nowMonth = nowDate.format('MM')
    const nowYear = nowDate.format('YYYY')
    const nowTime = nowDate.format('HH:mm')

    const { 
        registerType, 
        arrFingerRegister 
    } = getDataPrintRegisterForm(
        initialBiometricActive,
        initialBiometricFace
    )
    const {
        bioFaceContentUpdate,
        arrFingerUpdate,
    } = getDataPrintUpdateForm(initialBiometricActive)
    const city = getDataAdministrativeUnitsByCode(
        fieldAdministrativeUnits.city,
        dataLogin.OfficeProvince,
        'name'
    )

    return (
        <PopupPrintV2Styles>
            {initialBiometricActive === 1 && initialBiometricFace !== 0 ? (
                <UpdateForm
                    ref={componentPrintRef}
                    city={city}
                    nowTime={nowTime}
                    nowDay={nowDay}
                    nowMonth={nowMonth}
                    nowYear={nowYear}
                    officeName={officeName}
                    currentTicket={currentTicket}
                    bioFaceContentUpdate={bioFaceContentUpdate}
                    arrFingerUpdate={arrFingerUpdate}
                />
            ) : (
                <RegisterForm
                    ref={componentPrintRef}
                    city={city}
                    nowTime={nowTime}
                    nowDay={nowDay}
                    nowMonth={nowMonth}
                    nowYear={nowYear}
                    officeName={officeName}
                    currentTicket={currentTicket}
                    registerType={registerType}
                    arrFingerRegister={arrFingerRegister}
                />
            )}
            <div className="row_btn_confirm">
                <div className="btn_exit">
                    <ButtonConfirmControl
                        titleConfirm="ĐÓNG"
                        bg_color="#f31919"
                        onSubmit={() => onClose()}
                    />
                </div>
                <div className="btn_accept">
                    <ReactToPrint
                        trigger={() => (
                            <Bound bg_color="var(--peacock-blue)">
                                <p className="titleConfirm">IN</p>
                            </Bound>
                        )}
                        content={() =>
                            componentPrintRef ? componentPrintRef.current : null
                        }
                        onAfterPrint={() =>
                            dispatch(confirmUserPrinted(ticketId))
                        }
                        removeAfterPrint={true}
                    />
                </div>
            </div>
        </PopupPrintV2Styles>
    )
}

export default PopupPrintV2
