import React from 'react'

// styles
import { WrapPopupShowFinger } from './index.styles'

// images
import icCloseGray from "../../../../../../images/ic_close_gray.svg";

// constant
import { dataFingerPrint } from '../../../../../../data/infosType';
import { TELLER } from '../../../../../../data/userRole';

// api
import { apiGetBioFingerImage } from '../../../../../../actions/biometricAction';
import { apiGetBioFingerImageCollector } from '../../../../../../actions/biometricWithInBioCollectorAction';

// helper
import useIsRole from '../../../../../../helper/hooks/useIsRole';
import getChannelName from '../../../../../../helper/getChannelName';

const MOCK_IMG = 'https://picsum.photos/200/300'

const PopupShowFinger = ({ dataFinger, handleClose }) => {

    const isTeller = useIsRole(TELLER)
    const apiGetFinger = isTeller ? apiGetBioFingerImage : apiGetBioFingerImageCollector

    if (!dataFinger)
        return null
    return (
        <WrapPopupShowFinger>
            <div className="wrap-header">
                <div>{dataFingerPrint[dataFinger.BioType].label}</div>
                <img src={icCloseGray} alt="close" onClick={handleClose} />
            </div>
            <div className="channel">Kênh tác nghiệp: {getChannelName(dataFinger.fromChannel)}</div>
            <div className="wrap-content">
                {
                    dataFinger.EnrollData.map(fingerId => (
                        <div className="wrap-finger" key={fingerId}>
                            <img src={apiGetFinger(fingerId)} alt="finger" />
                        </div>
                    ))
                }
            </div>
            <div className="wrap-footer">
                <label htmlFor="active">
                    <input
                        type="radio"
                        id="active"
                        name="status"
                        defaultChecked={dataFinger.IsActive && dataFinger.IsConfirmFinger}
                        onClick={e => e.preventDefault()}
                    />
                    Hoạt động
                </label>
                <label htmlFor="closed">
                    <input
                        type="radio"
                        id="closed"
                        name="status"
                        defaultChecked={!dataFinger.IsActive && !dataFinger.IsConfirmFinger}
                        onClick={e => e.preventDefault()}
                    />
                    Đóng
                </label>
                <label htmlFor="hang">
                    <input
                        type="radio"
                        id="hang"
                        name="status"
                        defaultChecked={!dataFinger.IsActive && dataFinger.IsConfirmFinger}
                        onClick={e => e.preventDefault()}
                    />
                    Treo vân tay giao dịch
                </label>
            </div>
        </WrapPopupShowFinger>
    )
}

export default PopupShowFinger
