import React, { Component } from 'react';
import styled from "styled-components";


const Bound = styled.div`
    display: inline-block;
    .btn-action {
        width: ${(props) => props.width || '134px'};
        height: ${(props) => props.height};
        background-color: ${(props) => props.bgColor};
        opacity: ${(props) => (props.isDisable === true ? 0.1 : 'unset')};
        display: flex;
        align-items: center;
        border-radius: 22px;
        padding: ${(props) => props.padding || '8px 12px 8px 8px'};
        margin: ${(props) => (props.margin ? props.margin : 'unset')};
        cursor: ${(props) =>
        props.isDisable === true ? 'default' : 'pointer'};
        img {
            width: 28px;
            height: 28px;
            padding: 0 !important;
            margin-right: ${(props) => props.mr_img || '6px'};
        }
        p {
            font-family: 'SVN-Gilroy';
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            line-height: 1.2;
            color: #ffffff;
            text-transform: uppercase;
        }
    }

    ${props => props.extendStyles}
`

class ButtonControl extends Component {
    render() {
        let {
            bgColor,
            margin,
            height,
            width,
            padding,
            mr_img,
            extendStyles,
        } = this.props
        return (
            <Bound
                /*cursor = {this.props.cursor}*/
                width={width}
                height={height}
                padding={padding}
                mr_img={mr_img}
                bgColor={bgColor}
                isDisable={this.props.isDisable}
                margin={margin}
                extendStyles={extendStyles}
                onClick={
                    !this.props.isDisable
                        ? this.props.onSubmit
                            ? this.props.onSubmit
                            : () => {}
                        : () => {}
                }
            >
                <div className="btn-action">
                    <img src={this.props.icon} alt="ic_ticket_success" />
                    <p>{this.props.content}</p>
                </div>
            </Bound>
        )
    }
}

export default ButtonControl;