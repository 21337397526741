import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

//Image
import bg_finger from "../../../images/bg_finger.svg";
import finger_colorless from "../../../images/finger_colorless.svg";
import ic_cancel from '../../../images/icon_cancel.svg'
// import finger_white from "../../../images/finger_white.svg";

//Control
// import FaceIDControl from "../../Control/FaceIDControl";
import FaceIDControlV3 from "../../Control/FaceIDControlV3";

//Data
import { biometricID, followInform, biometrictFingerData } from "../../../data/data";
import FingerPrint from "../../Control/FingerPrint";
import { requestGetBioData, requestConfirmBioData, requestRemoveBioData, removeStatusWaitingBioData } from "../../../actions/biometricAction";
import { FACE } from "../../../data/bioTypes";
import { openPopup } from "../../../actions/popupBioAction";
import {
    REGISTER,/* , CONFIRM, AUTH_TRANS */
    CONFIRM,
    AUTH_TRANS,
    ISHANG,
    VALIDATE_DATA
} from "../../../actions/typeRequestBio";
import { requestEnroll, requestUpDateBio } from "../../../actions/counterAction";
import { popupBioTypes } from "../../../data/PopupTypes";
import typesKeyDetailPopup from "../../../data/typesKeyDetailPopup";
import { TELLER, INBIOCOLLECTOR } from "../../../data/userRole";
import {
    requestEnrollCollector,
    requestGetBioDataCollector,
    requestConfirmBioDataCollector,
    removeStatusWaitingBioDataCollector,
    removeBioDataCollector,
    requestUpDateBioCollector
} from "../../../actions/biometricWithInBioCollectorAction";
// import { clearTimeout } from "timers";

// helper
import { openPopupShowErrorExistedDataBiometricFace } from "../../../helper/actionOpenPopup"
import isErrorWhenGetEnroll from "../../../helper/isErrorWhenGetEnroll";
import { clearError } from "../../../actions/commonAction";
import LoadingControl from "../../Control/LoadingControl";
import getChannelName from "../../../helper/getChannelName";
import isDisableEventWithCORPCust from "../../../helper/isDisableEventWithCORPCust";

const faceStatuses = {
    activity: {
        type: "activity",
        textDetail: "Hoạt động"
    },
    del: {
        type: "del",
        textDetail: "Xoá"
    },
    closed: {
        type: "closed",
        textDetail: "Đóng"
    }
}

const Bound = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .biometric-face,
  .biometric-finger {
    p {
      font-family: SVN-Gilroy;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.3;
      color: #979797;
    }
  }
  .biometric-face {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* max-width: 200px; */
    padding: 24px 17px 20px;
    position: relative;
    .face-status{
      margin-top: 10px;
      font-size: 14px;
      font-family: "SVN-Gilroy";
      text-align: center;
      span {
        &.activity {
          color: rgb(15, 177, 31);
        }
        &.del {
          color: var(--vermillion);
        }
        &.closed {
          color: rgb(255, 88, 21);
        }
      }
    }
    .channel {
      margin-top: 10px;
      font-family: 'SVN-Gilroy';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 110%;
      color: #222222;
      text-align: center;
    }
    .warning {
      padding-top: 12px;
      text-align: center;
    }
    .close_register {
      /*width: 60px;*/
      text-align: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
      }

      p{
        text-decoration:underline;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.68;
        color: #f31919;
        cursor: pointer;
        margin:12px 0 0 0;
      }
    }

    .face-in-complete {
      color: var(--warm-grey);
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      /* background: #ffffff; */
    }

    .update-last {
      position: absolute;
      bottom: 12px;
      font-family: "SVN-Gilroy";
      font-size: 12px;
      font-weight: 500;
      p {
        color: #222222;
        font-size: 13px;
      }
      span {
        color: #979797;
      }
    }
  }
  .biometric-finger {
    font-family: "SVN-Gilroy";
    /* flex: 4; */

    .show-finger {
      position: relative;
      width: 784px;
      .bg_finger {
        width: 100%;
        height: 100%;
      }
      .handle-finger {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 15px;
        span {
          opacity: 0.5;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.3;
          color: #ffffff;
        }
      }
      .finger {
        position: absolute;
        bottom: 12px;
        left: 12px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        p {
          font-family: "SVN-Gilroy";
          font-size: 12px;
          font-weight: 500;
          line-height: 1.3;
          opacity: 0.5;
          color: #ffffff;
        }
        p:nth-child(2) {
          padding-right: 31px;
        }
      }
      .status-register {
        display: flex;
        position: absolute;
        top: 13px;
        right: 20px;
        .update {
          display: flex;
          align-items: center;
          &:not(:nth-child(1)) {
            padding-left: 10px;
          }
        }
        .circle {
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
        p {
          padding-left: 4px;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.3;
          color: #ffffff;
        }
      }
    }
  }
`;

class UserBiometric extends Component {
    state = {
        data: biometricID,
        stepFinish: false,
        lastUpdate: false,
        finger_status: finger_colorless, ///// Image status: white, green, bg_green + mainColor:green 
        // finger_status: finger_white, 
        closingGetBio: '',
        fromChannel: undefined
    };


    onShowRegisterFinger = (item) => {
        const { biometricReducer, dataGetTicket, counterReducer } = this.props;

        if (!dataGetTicket) return

        const typeBio = item.typeFinger;
        const isUpdateFinger = /validatedData/.test(JSON.stringify(biometricReducer[dataGetTicket.TicketID]))

        //  prevent case:
        //  --  customer not have bio
        //  --  bio contain face, user in process update face and have not finished yet
        //  --  bio not contain face
        //  --  bio contain face have not confirmed yet
        const ticketId = dataGetTicket.TicketID;
        const isHaveBio = Object.keys(biometricReducer).includes(ticketId)
        const isBioContainFace = isHaveBio && !!biometricReducer[ticketId][FACE];
        const isFaceRegister = isBioContainFace && !!biometricReducer[ticketId][FACE][REGISTER];
        const isFaceConfirm =
      isFaceRegister && Object.keys(biometricReducer[ticketId][FACE]).includes(CONFIRM);
        const isFaceConfirmSuccess =
      isFaceConfirm && biometricReducer[ticketId][FACE][CONFIRM] === 0;
        const isExistedFace =
      isBioContainFace &&
      Object.keys(biometricReducer[ticketId][FACE]).includes(VALIDATE_DATA)
        // debugger
        const isPrevent = !isBioContainFace ||
      (!isExistedFace && !isFaceConfirmSuccess) ||
      (isExistedFace && isFaceRegister && !isFaceConfirmSuccess);
        if (isPrevent && !isDisableEventWithCORPCust(ticketId, counterReducer)) {
            this.props.openPopup(popupBioTypes.PopupCounterFail, 2, {
                icon: ic_cancel,
                label:
          'Đề nghị hoàn thành lấy mẫu khuôn mặt cho khách hàng trước khi lấy mẫu vân tay',
                width: '226px',
            })
            return;
        }

        if (JSON.stringify(biometricReducer)) {

            if (!biometricReducer[dataGetTicket.TicketID]) {
                this.props.openPopup(
                    popupBioTypes.RegisterFingerIDControl, 2, {
                        typeBio: item.typeFinger,
                        dataGetTicket: this.props.dataGetTicket,
                        flowUpdate: this.props.flowUpdate
                    });
            }
            if (biometricReducer[dataGetTicket.TicketID]) {
                let fingerCheck = biometricReducer[dataGetTicket.TicketID][typeBio]

                if (fingerCheck) {
                    switch (true) {
                    case fingerCheck[REGISTER] && fingerCheck.CONFIRM !== 0:
                        this.props.openPopup(
                            popupBioTypes.RegisterFingerIDControl, 2, {
                                typeBio: item.typeFinger,
                                dataGetTicket: this.props.dataGetTicket,
                                flowUpdate: this.props.flowUpdate
                            });
                        break;

                    case fingerCheck.CONFIRM === 0:
                        this.props.openPopup(popupBioTypes.PopupBioFingerTransaction, 2, {
                            typeDetail: typesKeyDetailPopup.fingerBioInfoResgister,
                            typeBio: item.typeFinger,
                            dataGetTicket: this.props.dataGetTicket,
                            flowUpdate: this.props.flowUpdate
                        });
                        break;
                    case isUpdateFinger:
                        this.props.openPopup(popupBioTypes.PopupBioFingerTransaction, 2, {
                            typeDetail: typesKeyDetailPopup.fingerBioInfoUpdate,
                            typeBio: item.typeFinger,
                            dataGetTicket: this.props.dataGetTicket,
                            flowUpdate: this.props.flowUpdate
                        });
                        break;

                    default:
                        this.props.openPopup(popupBioTypes.PopupBioFingerTransaction, 2, {
                            typeDetail: typesKeyDetailPopup.fingerBioInfoUpdate,
                            typeBio: item.typeFinger,
                            dataGetTicket: this.props.dataGetTicket,
                            flowUpdate: true
                        });
                        break;
                    }
                }
                ///////// Ticket isNotHave FingerCheck but isHave another Finger
                else {
                    this.props.openPopup(popupBioTypes.RegisterFingerIDControl, 2, {
                        typeBio: item.typeFinger,
                        dataGetTicket: this.props.dataGetTicket,
                        flowUpdate: this.props.flowUpdate
                    });
                    // alert("is not have fingerBio but have another")
                    return;
                }
            }
        }

    };

    ///////// REGISTER FACEID
    registerFaceID = async () => {
        let { dataGetTicket, counterReducer } = this.props;
        /////////// Check Enroll ////////////
        const userCheckListCurrent = counterReducer.listCurrentTicket.find(item => item.TicketID === dataGetTicket.TicketID);
        if (!userCheckListCurrent) return;
        if (!userCheckListCurrent.CustomerInfos) return;
        const {
            BiometricFace,
            BiometricFinger,
            BiometricActive
        } = userCheckListCurrent.CustomerInfos;

        /////// Check permission 

        switch (true) {
        case counterReducer.dataLogin.Roles.includes(TELLER) &&
        !Number(BiometricFace) && !Number(BiometricFinger) && !Number(BiometricActive):
            // !this.props.flowUpdate:
            if (!counterReducer.enrollId[dataGetTicket.TicketID]) {
                await this.props.requestEnroll(dataGetTicket.TicketID, userCheckListCurrent.CustomerInfos)
                const isError = isErrorWhenGetEnroll(
                    this.props.counterReducer,
                    this.props.openPopup,
                    this.props.clearError
                )
                if (isError) return
            }
            if (dataGetTicket && counterReducer.enrollId && Object.keys(counterReducer.enrollId).lenght !== 0) {
                await this.props.requestGetBioData(
                    {
                        CounterID: counterReducer.dataConnectDesktop.CounterID,
                        TicketID: dataGetTicket.TicketID,
                        EnrollID: this.props.counterReducer.enrollId[dataGetTicket.TicketID],
                        BioType: FACE //// type send request to table get file bio
                    });
            }
            // alert('enrrol - face - register')
            break;

        case counterReducer.dataLogin.Roles.includes(TELLER) &&
        !!(Number(BiometricFace) || Number(BiometricFinger) || Number(BiometricActive)):
            // this.props.flowUpdate:
            if (!counterReducer.enrollId[dataGetTicket.TicketID]) {
                await this.props.requestUpDateBio(dataGetTicket.TicketID, userCheckListCurrent.CustomerInfos.CustomerNumber)
                const isError = isErrorWhenGetEnroll(this.props.counterReducer, this.props.openPopup, this.props.clearError)
                if (isError) return;
            }
            if (dataGetTicket && counterReducer.enrollId && Object.keys(counterReducer.enrollId).lenght !== 0) {
                await this.props.requestGetBioData(
                    {
                        CounterID: counterReducer.dataConnectDesktop.CounterID,
                        TicketID: dataGetTicket.TicketID,
                        EnrollID: this.props.counterReducer.enrollId[dataGetTicket.TicketID],
                        BioType: FACE //// type send request to table get file bio
                    });
            }
            // alert('enrrol - face -update')
            break;

        case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR) &&
        !Number(BiometricFace) && !Number(BiometricFinger) && !Number(BiometricActive):
            if (!counterReducer.enrollId[dataGetTicket.TicketID]) {
                await this.props.requestEnrollCollector(
                    userCheckListCurrent.CustomerInfos.CustomerNumber,
                    dataGetTicket.TicketID
                )
                const isError = isErrorWhenGetEnroll(
                    this.props.counterReducer,
                    this.props.openPopup,
                    this.props.clearError
                )
                if (isError) return
            }
            if (dataGetTicket && counterReducer.enrollId && Object.keys(counterReducer.enrollId).lenght !== 0) {
                await this.props.requestGetBioDataCollector(
                    {
                        CounterID: counterReducer.dataConnectDesktop.CounterID,
                        EnrollID: this.props.counterReducer.enrollId[dataGetTicket.TicketID],
                        BioType: FACE //// type send request to table get file bio
                    },
                    dataGetTicket.TicketID
                );
            }
            break;
        case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR) &&
        !!(Number(BiometricFace) || Number(BiometricFinger) || Number(BiometricActive)):
            // this.props.flowUpdate:
            if (!counterReducer.enrollId[dataGetTicket.TicketID]) {
                await this.props.requestUpDateBioCollector(
                    dataGetTicket.TicketID,
                    userCheckListCurrent.CustomerInfos.CustomerNumber
                )

                const isError = isErrorWhenGetEnroll(
                    this.props.counterReducer,
                    this.props.openPopup,
                    this.props.clearError
                )
                if (isError) return

            }
            if (dataGetTicket && counterReducer.enrollId && Object.keys(counterReducer.enrollId).lenght !== 0) {
                await this.props.requestGetBioDataCollector(
                    {
                        CounterID: counterReducer.dataConnectDesktop.CounterID,
                        EnrollID: this.props.counterReducer.enrollId[dataGetTicket.TicketID],
                        BioType: FACE
                    },
                    dataGetTicket.TicketID
                );
            }
            break;
        default:
            break;
        }
    }
    ///////// CONFIRM FACEID
    confirmFaceID = async () => {
        let { dataGetTicket, counterReducer } = this.props;
        if (dataGetTicket) {
            switch (true) {
            case counterReducer.dataLogin.Roles.includes(TELLER):
                await this.props.requestConfirmBioData(
                    counterReducer.enrollId[dataGetTicket.TicketID],
                    FACE,
                    dataGetTicket.TicketID
                )
                break;
            case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR):
                await this.props.requestConfirmBioDataCollector(
                    counterReducer.enrollId[dataGetTicket.TicketID],
                    FACE,
                    dataGetTicket.TicketID
                )
                break;
            default:
                break;
            }
        }
    // this.setState({
    //   stepFinish: true,
    // })
    }

    ////// REMOVE ---> REGISTER FACEID
    SubmitRemoveFaceID = async () => {
        let { dataGetTicket, counterReducer, biometricReducer } = this.props;
        if (dataGetTicket) {
            let bioId = biometricReducer[dataGetTicket.TicketID][FACE][REGISTER];
            const isTeller = counterReducer.dataLogin.Roles.includes(TELLER);
            isTeller && this.props.requestRemoveBioData(
                counterReducer.enrollId[dataGetTicket.TicketID],
                FACE,
                bioId,
                dataGetTicket.TicketID,
            )
            !isTeller && this.props.removeBioDataCollector(
                counterReducer.enrollId[dataGetTicket.TicketID],
                FACE,
                bioId,
                dataGetTicket.TicketID,
            )
        }
        this.registerFaceID();
    }

    finishAction = () => {
        const isTeller = this.props.counterReducer.dataLogin.Roles.includes(TELLER);
        // debugger;
        if (isTeller)
            this.props.removeStatusWaitingBioData(this.props.dataGetTicket.TicketID);
        else
        // this.props.removeStatusWaitingBioData(
        //   this.props.dataGetTicket.TicketID,
        //   true
        // );
            this.props.removeStatusWaitingBioDataCollector(this.props.dataGetTicket.TicketID);
        this.setState({
            stepFinish: false
        });
    // localStorage.removeItem("biometricData");
    }

    handleBeforeClosingGetBio = () => {
        this.setState({
            closingGetBio:
        <>
            <LoadingControl size="25px" loadingPage={false} />
          Vui lòng chờ...
        </>
        }, () => {
            setTimeout(() => {
                this.finishAction()
            }, 5000)
        })
    }

    handleSetStateWaitingBio = async () => {
        await this.setState({ isVisibleFinishBtn: false })
        const { dataGetTicket, biometricReducer } = this.props;
        const ticketId = dataGetTicket.TicketID;
        const isTicketHaveBio = Object.keys(biometricReducer).includes(ticketId);
        const isTicketWaitingGetBio = isTicketHaveBio &&
      Object.keys(biometricReducer[ticketId]).includes("waitingConfirm");
        const isTicketWaitingGetBioFace = isTicketWaitingGetBio &&
      biometricReducer[ticketId].waitingConfirm.typeBio === FACE;
        // check validatedData FACE
        const isTicketHaveFaceBio = isTicketHaveBio &&
      Object.keys(biometricReducer[ticketId]).includes(FACE);
        const isTicketHaveFaceValidatedData = isTicketHaveFaceBio &&
      Object.keys(biometricReducer[ticketId][FACE]).includes("validatedData");

        if (isTicketWaitingGetBioFace && !isTicketHaveFaceValidatedData) {
            this.setState({
                stepFinish: true
            }, () => {
                setTimeout(() => {
                    this.setState({ isVisibleFinishBtn: true })
                }, 1500)
            })
            return;
        }
        this.setState({
            stepFinish: false,
            closingGetBio: ''
        })
    }

    handleShowFaceStatus = () => {
        const { dataGetTicket, biometricReducer } = this.props;
        const ticketId = dataGetTicket.TicketID;
        const isTicketHaveBio = Object.keys(biometricReducer).includes(ticketId);
        // check validatedData FACE
        const isTicketHaveFaceBio = isTicketHaveBio &&
      Object.keys(biometricReducer[ticketId]).includes(FACE);

        const isTicketHaveFaceConfirm = isTicketHaveFaceBio &&
      Object.keys(biometricReducer[ticketId][FACE]).includes(CONFIRM);
        const isTicketHaveFaceConfirmSuccess = isTicketHaveFaceConfirm &&
      biometricReducer[ticketId][FACE][CONFIRM] === 0;

        const isTicketHaveFaceValidatedData = isTicketHaveFaceBio &&
      Object.keys(biometricReducer[ticketId][FACE]).includes("validatedData");

        // delete
        // register - have confirm success already
        // update
        switch (true) {
        case isTicketHaveFaceBio && biometricReducer[ticketId][FACE].bioActionType === "REMOVE":
            this.setState({ faceStatus: faceStatuses.del })
            break;
        case isTicketHaveFaceConfirmSuccess:
            this.setState({ faceStatus: faceStatuses.activity })
            break;
        case isTicketHaveFaceValidatedData: {
            const bioWasUpdatedStatus = Object.keys(biometricReducer[ticketId][FACE]).includes(AUTH_TRANS);
            if (bioWasUpdatedStatus) {
                if (!biometricReducer[ticketId][FACE][ISHANG]) {
                    this.setState({ faceStatus: faceStatuses.activity })
                    break;
                }
                this.setState({ faceStatus: faceStatuses.closed })
            } else {
                if (!biometricReducer[ticketId][FACE][VALIDATE_DATA][ISHANG]) {
                    this.setState({ faceStatus: faceStatuses.activity })
                    break;
                }
                this.setState({ faceStatus: faceStatuses.closed })
            }
            this.setState({ faceStatus: faceStatuses.closed })
            break;
        }
        default:
            this.setState({ faceStatus: null })
            break;
        }
    }

    handleShowFaceChannel = () => {
        const { dataGetTicket, biometricReducer } = this.props;
        const ticketId = dataGetTicket.TicketID;
        const isTicketHaveBio = Object.keys(biometricReducer).includes(ticketId);
        const isTicketHaveFaceBio = isTicketHaveBio &&
      Object.keys(biometricReducer[ticketId]).includes(FACE);

        const isTicketHaveFaceConfirm = isTicketHaveFaceBio &&
      Object.keys(biometricReducer[ticketId][FACE]).includes(CONFIRM);
        const isTicketHaveFaceConfirmSuccess = isTicketHaveFaceConfirm &&
      biometricReducer[ticketId][FACE][CONFIRM] === 0;

        const isTicketHaveFaceValidatedData = isTicketHaveFaceBio &&
      Object.keys(biometricReducer[ticketId][FACE]).includes("validatedData");

        switch (true) {
        case isTicketHaveFaceConfirmSuccess:
            this.setState({ fromChannel: biometricReducer[ticketId][FACE].fromChannel })
            break;
        case isTicketHaveFaceValidatedData:
            this.setState({ fromChannel: biometricReducer[ticketId][FACE].validatedData.fromChannel })
            break;
        default:
            this.setState({ fromChannel: undefined })
            break;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { /* dataGetTicket, */ biometricReducer } = this.props;
        if (prevProps.biometricReducer !== biometricReducer) {
            this.handleSetStateWaitingBio()
            this.handleShowFaceStatus()
            this.handleShowFaceChannel()
        }
    }

    componentDidMount() {
        this.handleSetStateWaitingBio()
        this.handleShowFaceStatus()
        this.handleShowFaceChannel()
    }

    render() {
    // console.log(this.props.flowUpdate);
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === this.props.dataGetTicket.TicketID);
        const { biometricReducer } = this.props;
        const bioOfTicket = biometricReducer[currentTicket.TicketID];
        const ticketHaveFaceInComplete = !!bioOfTicket &&
      Object.keys(bioOfTicket).includes(FACE) &&
      Object.keys(bioOfTicket[FACE]).includes(REGISTER) &&
      (!Object.keys(bioOfTicket[FACE]).includes(CONFIRM)
        || bioOfTicket[FACE][CONFIRM] !== 0)
        return (
            <Bound>
                <div className="biometric-face">
                    {/* <FaceIDControl
            registerFaceID={() => { this.registerFaceID() }}
            confirmFaceID={() => { this.confirmFaceID() }}
            SubmitRemoveFaceID={this.SubmitRemoveFaceID}
            ///////// Check permission to REGISTER/UPDATE OR REVIEW
            dataGetTicket={this.props.dataGetTicket}
            flowUpdate={this.props.flowUpdate}
          /> */}
                    <FaceIDControlV3
                        registerFaceID={() => { this.registerFaceID() }}
                        confirmFaceID={() => { this.confirmFaceID() }}
                        SubmitRemoveFaceID={this.SubmitRemoveFaceID}
                        ticketId={this.props.dataGetTicket.TicketID}
                    />
                    {
                        this.state.faceStatus &&
            <div className="face-status">Trạng thái: <span className={this.state.faceStatus.type}>{this.state.faceStatus.textDetail}</span></div>
                    }
                    <div className="channel">Kênh tác nghiệp: {getChannelName(this.state.fromChannel)}</div>
                    <p className="warning">(*) Hình ảnh dùng để nhận diện khách hàng tại thiết bị Kiosk</p>
                    {
                        this.state.stepFinish ?
                            this.state.isVisibleFinishBtn ?
                                <div
                                    onClick={() => this.handleBeforeClosingGetBio()}
                                    className='close_register'>
                                    {
                                        this.state.closingGetBio ?
                                            <div>{this.state.closingGetBio}</div>
                                            :
                                            <p>Kết thúc</p>
                                    }
                                </div>
                                :
                                <div className='close_register'></div>
                            :
                            null
                    }
                    {
                        currentTicket.duplicateFaces &&
            !!currentTicket.duplicateFaces.length &&
            <div
                onClick={() => openPopupShowErrorExistedDataBiometricFace(
                    this,
                    {
                        typeExistedData: "FACE",
                        data: currentTicket.duplicateFaces,
                        ticketId: currentTicket.TicketID
                    },
                    "UserBiometric.js"
                )
                }
                className='close_register'>
                <p>Thông tin KH trùng khuôn mặt</p>
            </div>
                    }
                    {
                        ticketHaveFaceInComplete &&
            <div className="face-in-complete">Khuôn mặt chưa hoàn tất đăng ký</div>
                    }
                    {
                        this.state.lastUpdate &&
            <div className="update-last">
                <p>Cập nhật lần cuối:</p>
                <span>08:36 18/06/19</span>
            </div>
                    }
                </div>
                <div className="biometric-finger">
                    <div className="show-finger">
                        <img src={bg_finger} alt="bg_finger" className="bg_finger" />
                        <div className="finger">
                            <p>Bàn tay trái</p>
                            <p>Bàn tay phải</p>
                        </div>
                        <div className="handle-finger">
                            <div className="g-finger">
                                {biometrictFingerData.map((item, i) => (
                                    <FingerPrint
                                        flowUpdate={this.props.flowUpdate}
                                        key={i}
                                        onShowRegisterFinger={() => this.onShowRegisterFinger(item)}
                                        dataGetTicket={this.props.dataGetTicket}
                                        img_finger={this.state.finger_status}
                                        alt="finger"
                                        {...item}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="status-register">
                            {followInform.map((item, i) => {
                                return (
                                    <div className="update" key={i}>
                                        <div
                                            className="circle"
                                            style={{
                                                backgroundColor: item.color
                                            }}
                                        />
                                        <p>{item.content}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Bound>
        );
    }
}

const mapStateToProps = state => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer
});

const mapDispatchToProps = dispatch => ({
    requestGetBioData: dataRequest => dispatch(requestGetBioData(dataRequest)),
    openPopup: (type, levelPopup, data) => dispatch(openPopup(type, levelPopup, data)),
    requestConfirmBioData: (enrollId, bioType, ticketId) => dispatch(requestConfirmBioData(enrollId, bioType, ticketId)),
    requestRemoveBioData: (enrollId, bioType, bioId, ticketId, index = undefined) => dispatch(requestRemoveBioData(enrollId, bioType, bioId, ticketId, index)),
    removeStatusWaitingBioData: (ticketId, justDispatch = false) => dispatch(removeStatusWaitingBioData(ticketId, justDispatch)),
    requestEnroll: (ticketId, cifs) => dispatch(requestEnroll(ticketId, cifs)),
    //// flowUpdate
    requestUpDateBio: (ticketId, cusNumber) => dispatch(requestUpDateBio(ticketId, cusNumber)),

    ///////////// BioCollector
    requestEnrollCollector: (cifs, ticketId) => dispatch(requestEnrollCollector(cifs, ticketId)),
    requestGetBioDataCollector: (dataRequest, ticketId) => dispatch(requestGetBioDataCollector(dataRequest, ticketId)),
    requestConfirmBioDataCollector: (enrollId, bioType, ticketId) =>
        dispatch(requestConfirmBioDataCollector(enrollId, bioType, ticketId)),
    removeStatusWaitingBioDataCollector: (ticketId, justDispatch = false) =>
        dispatch(removeStatusWaitingBioDataCollector(ticketId, justDispatch)),
    removeBioDataCollector: (enrollId, bioType, bioId, ticketId, index = undefined) =>
        dispatch(removeBioDataCollector(enrollId, bioType, bioId, ticketId, index)),
    requestUpDateBioCollector: (ticketId, cusNumber) =>
        dispatch(requestUpDateBioCollector(ticketId, cusNumber)),
    clearError: (typeError) => dispatch(clearError(typeError))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(UserBiometric);
