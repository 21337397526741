import styled from "styled-components";

const StyleFaceUpdateFlow = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    box-shadow: 0 0 8px #bbafafb0;

    .btn {
        display: flex;
        align-items: center;
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        &:hover {
            cursor: pointer;
        }

        & > * {
            font-size: 14px;
        }

        &__title {
            line-height: 0;
        }

        .delete {
            color: var(--vermillion);
        }

        .re-get {
            color: var(--peacock-blue);
        }
    }

    /* padding: 11px 10px; */

    .popup__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 11px 10px 0 10px;

        .title {
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            font-weight: bold;
            line-height: 128%;
        }

        img {
            :hover {
                cursor: pointer;
            }
        }
    }

    .popup__content {
        position: relative;
        /* width: 240px; */
        /* height: 240px; */
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* margin: 0 10px; */

        img {
            /* width: 100%; */
            height: 100%;
        }

        .loading {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

    }

    .popup__footer {
        border-top: 0.5px solid #D6D6D6;
        /* text-align: center; */
        padding: 15px 0;
        display: flex;
        justify-content: center;

        .closing-get-bio {
            font-family: "SVN-Gilroy";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-finish-waiting {
            color: var(--vermillion);
            text-decoration: underline;
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            :hover {
                cursor: pointer;
            }
        }

        &__confirm {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 7px;

            img {
                :hover {
                    cursor: pointer;
                }
            }
        }

    }
`;

export default StyleFaceUpdateFlow;