import React, { useState, useCallback, useEffect, useRef } from 'react'

// styles
import { Wrapper, Select, DropDown, Search } from './index.styles'

// images
import icon_down from '../../../images/ic_arrow_down_gray.svg'
import icon_search from '../../../images/ic_search_gray.svg'
import check_blue from '../../../images/check_blue.svg'

// hooks
import useOutsideClick from '../../../helper/hooks/useOutsideClick'
import useDebounce from '../../../helper/hooks/useDebounce'


const DropDownItem = ({ children, isChecked, onClick }) => (
    <div className="item" title={children} onClick={onClick}>
        <div>{children}</div>
        {
            isChecked &&
            <img src={check_blue} alt="checked" />
        }
    </div>
)

// item dropdown = {key: '', value: ''}

const DropDownSearchObj = ({
    lstItemFixed,
    lstItems,
    onChange,
    defaultValue,
    placeHolder,
    placeHolderSelect,
    onSearch,
    haveSearch = true,
    extendStylesSelect,
    extendStylesDropdown,
    maxNumShowDropdown
}) => {
    const ref = useRef(null)
    const [isShow, setIsShow] = useState(false)
    const [checkedData, setCheckedData] = useState(defaultValue || null)
    const [lstItemFlex, setLstItemFlex] = useState(lstItems);
    const [keySearch, setKeySearch] = useState('')
    const keySearchDebounce = useDebounce(keySearch, 1000)

    useEffect(() => {
        if (!haveSearch) return
        onSearch(keySearchDebounce)
    }, [keySearchDebounce, haveSearch])

    useEffect(() => {
        setCheckedData(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        if (!haveSearch) return
        if (!isShow) {
            onSearch('')
        }
    }, [isShow])

    useEffect(() => {
        setLstItemFlex(lstItems)
    }, [lstItems])

    useOutsideClick(ref, () => {
        setIsShow(false)
    })

    // useEffect(() => {
    //     if (!checkedData) return;
    //     onChange(checkedData)
    // }, [checkedData])

    const _handleChecked = useCallback(
        (val) => {
            setIsShow(!isShow)
            setCheckedData(val)
            onChange(val)
        },
        [isShow],
    )

    const _handleSearch = useCallback(
        (e) => {
            const { value } = e.target
            if (!value.trim()) {
                setKeySearch('')
                return;
            }
            setKeySearch(value.trim().replace(/\s+/, ' '))
        },
        [lstItems],
    )

    return (
        <Wrapper ref={ref}>
            <Select onClick={() => setIsShow(!isShow)} extendStyles={extendStylesSelect}>
                <div title={checkedData?.value}>{checkedData?.value || placeHolderSelect}</div>
                <img src={icon_down} alt="open-drop-down" />
            </Select>
            {
                isShow &&
                <DropDown
                    extendStyles={extendStylesDropdown}
                    maxNumShowDropdown={maxNumShowDropdown}
                >
                    {
                        haveSearch &&
                        <Search>
                            <img src={icon_search} alt="search" />
                            <input
                                autoFocus="autofocus"
                                type="text"
                                placeholder={placeHolder}
                                onChange={_handleSearch}
                            />
                        </Search>
                    }
                    <div className="lst-fixed-item">
                        {
                            lstItemFixed.map((item, key) =>
                                <DropDownItem
                                    key={key}
                                    onClick={() => _handleChecked(item)}
                                    isChecked={checkedData?.key === item.key}
                                >
                                    {item.value}
                                </DropDownItem>
                            )
                        }
                    </div>
                    <div className="lst-scroll-item">
                        {
                            lstItemFlex.map((item, key) =>
                                <DropDownItem
                                    key={key}
                                    onClick={() => _handleChecked(item)}
                                    isChecked={checkedData?.key === item.key}
                                >
                                    {item.value}
                                </DropDownItem>
                            )
                        }
                    </div>
                </DropDown>
            }
        </Wrapper>
    )
}

export default DropDownSearchObj
