import styled from 'styled-components'

const WrapMainQueries = styled.div`
    min-width: 478px;
    /* min-height: 330px; */
    background: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'SVN-Gilroy';
    box-sizing: border-box;

    .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        color: #222222;
        padding: 18px 20px 20px;
        border-bottom: 3px solid #EFEFEF;

        :hover {
            cursor: move;
        }
    }

    .ic-close {
        position: absolute;
        right: 8px;
        top: 8px;

        &:hover {
            cursor: pointer;
        }
    }

    .content {
        padding: 18px 20px 21px;
        position: relative;

        .target-search {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 128%;
            color: #005993;
        }

        .row {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
                user-select: none;
            }

            label {
                display: flex;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 128%;
                color: #222222;

                input[type="radio"] {
                    outline: none;
                    appearance: none;
                    width: 16px;
                    height: 16px;
                    border: 0.75px solid #D6D6D6;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 6px;

                    &::before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        background: #D71249;
                        border-radius: 50%;
                        display: none;
                    }

                    &:checked {
                        &::before {
                            display: block;
                        }
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .wrap-search {
                display: flex;
                border: 0.5px solid #D6D6D6;
                border-radius: 4px;
                width: 258px;

                &.disabled {
                    pointer-events: none;
                }

                input {
                    box-sizing: border-box;
                    height: 40px;
                    border: none;
                    outline: none;
                    padding: 11px 9px;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 128%;
                    border-radius: 4px 0 0 4px;
                    flex-grow: 1;
                    color: #222222;

                    &:disabled {
                        background: #80808033;
                    }
                }

                figure {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    background: #005993;
                    border-radius: 0px 4px 4px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    pointer-events: none;
                    opacity: 0.4;

                    &.enable {
                        pointer-events: initial;
                        opacity: initial;
                    }

                    img {
                        width: 24px;
                        height: 24px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
                
            }

            button {
                border: none;
                outline: none;
                padding: 8px 12px;
                min-width: 128px;
                background: #005993;
                border-radius: 18px;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 120%;
                text-transform: uppercase;
                color: #FFFFFF;

                &:hover {
                    cursor: pointer;
                }

                &:disabled {
                    pointer-events: none;
                    opacity: 0.4;
                }
            }
        }
        .error {
            margin-top: 15px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 128%;
            color: #F31919;
            min-height: 15px;
        }
        .wrap-loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 0 0 10px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export {
    WrapMainQueries
}