import React from 'react'
import ReactDOM from 'react-dom'
import PopupTimeoutModal from '../PopupTimeoutModal'

const IdleTimeoutModal = ({ onAccept, isShowModal }) => {
    if (!isShowModal) return null;
    
    return ReactDOM.createPortal(
        <div className="modal-timeout">
            <PopupTimeoutModal onAccept={onAccept} />
        </div>,
        document.body
    )
}

export default IdleTimeoutModal
