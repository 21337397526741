import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux'
// images
import ic_search from '../../../images/Icons_Search.svg';
// import ic_ticket_success from '../../../images/ic_ticket_success_green.svg';
import imgUserGrayBg from "../../../images/img_user_gray_bg.svg";
import iconNotificationClose from "../../../images/ic_counter_notification_close.svg"

// Component
import UserInfomation from './UserInfomation';
import ButtonConfirmControl from "../../Control/ButtonConfirmControl";
import * as typesAction from "../../../actions/types";
import ErrorGetTicket76 from '../../../helper/ErrorGetTicket76';

// import action
import { 
    getInfoCustomer, 
    addCustomerInfos,
    addBiometricExistFromCustomerInfos,
    clearCustomerInfosInStore
} from '../../../actions/counterAction';
import { 
    clearError
} from "../../../actions/commonAction";
import { apiGetBioFaceImage } from "../../../actions/biometricAction"

// helper
import { buildErrorContent } from "../../../helper/tool";
import { TELLER, INBIOCOLLECTOR } from '../../../data/userRole';
import { getCustomerInfos } from '../../../actions/biometricWithInBioCollectorAction';
import isDisableEventWithCORPCust from '../../../helper/isDisableEventWithCORPCust';
import autoOpenPopupWhenGetTicket from '../../../helper/autoOpenPopupWhenGetTicket';
import { openPopup } from '../../../actions/popupBioAction';

// data
import { popupBioTypes } from "../../../data/PopupTypes";

const Bound = styled.div`
    /* width:100%; */
    background-color:#fafafa;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    padding:16px 40px 20px 40px;
    .block_radio {
        display: flex;
        justify-content:start;
        align-items: center;
        margin: 0 0 17px 0;
        .radio-field {
            display: flex;
            align-items: center;
            margin-right: 7px;
            input[type="radio"]{
                appearance: none;
                outline: none;
                width: 16px;
                height: 16px;
                position:relative;
                background: #fff;
                border-radius: 50%;
                border: 0.75px solid #D6D6D6;
                margin-right: 5px;

                ::before{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    display: none;
                }

                &:checked::before {
                    background: #D71249;
                    display: block;
                }
            }
        }
    }     
    .input_check{
        width: 356px;
        margin: 10px 0 0 0;
        position: relative;
        display:flex;
        align-items: center;
        input{
            width:100%;
            padding: 11px 50px 11px 12px;
            height: 18px;
            border-radius: 4px;
            border: solid 0.5px #c8c8c8;
            background-color: #ffffff;
            outline:none;
            &::placeholder{
                opacity: 0.25;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.2;
                color: #222222;
            }
        }
        .btn_check{
            width: 44px;
            height: 40px;
            background-color: #005993;
            display:flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right:0;
            cursor: pointer;
            border:none;
            outline:none;
            border-radius: 0 4px 4px 0;
        }
    }
    .error{
        margin: 12px 0 15px 0;
        height: 17px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        color: #f31919;
    }
    .search{
        font-family: SVN-Gilroy;
        font-size: 14px;
        font-weight: 500;
        color: #979797;
        margin-bottom: 8px;
    }
    .customer-info {
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 38px;
        /* height:100px; */
        border-radius: 4px;
        background: #fff;
        padding-bottom: 9px;
        & > * {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        &__col {
            display: flex;
            flex-direction: column;
            height: 100%;
            &__row {
                display: flex;
                justify-content: space-between;
                .item {
                    display: flex;
                    margin-top: 14px;
                    .title, .value {
                        font-family: "SVN-Gilroy";
                        font-size: 14px;
                        line-height: 125%;
                    }

                    .title {
                        color: #005993;
                        margin-right: 5px;
                    }
                    .value {

                    }
                }
                .name {
                    text-transform: uppercase;
                    font-family: "SVN-Gilroy";
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 22px;
                    margin-right: 10px;
                }
                .type-cus {
                    background: #FFB400;
                    border-radius: 10px;
                    font-family: "SVN-Gilroy";
                    font-size: 12px;
                    line-height: 130%;
                    padding: 3px 12px;
                }
                .gender {
                    margin-left: 21px;
                }

            }

            button {
                /* width: unset; */
                padding: 0 10px;
                margin-bottom: 8px;
            }
            
        }
        .left {
            align-items: flex-start;
        }

        .center {
            align-items: center;
        }

        .fit-content-item {
            width: fit-content;
            /*padding-right: 14px;*/
            padding-left: 14px;
        }

        .line-clamp {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }

        .wrap-img {
            width: 54px;
            height: 54px;
            border-radius: 50%;
            overflow: hidden;
            flex-shrink: 0;
            margin: 14px 18px auto 18px;
            img {
                width: 100%;
                height: 100%;
            }
        }


    }    
`
class UserCheckCIF extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cifTmp: '',
            error: '',
            searchResult: null,
            step: 1,
            isRegistration: this.props.isRegistration,
            // typeSearch: "0"
            typeSearch: "1"
        }
    }

    UNSAFE_componentWillReceiveProps(n) {
        if (n.counterReducer.customerInfo.Cifs) {
            this.setState({
                searchResult: n.counterReducer.customerInfo.Cifs
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.counterReducer.err && Object.keys(this.props.counterReducer.err).includes(typesAction.GET_CUSTOMER_INFORMATION_ERR)) {
            this.setState(
                {
                    searchResult: null,
                    error: buildErrorContent(typesAction.GET_CUSTOMER_INFORMATION_ERR, this.props.counterReducer.err[typesAction.GET_CUSTOMER_INFORMATION_ERR])
                },
                () =>
                    this.props.clearError(
                        typesAction.GET_CUSTOMER_INFORMATION_ERR
                    )
            )
        }
    }

    onChange = (e) => {
        this.setState({
            error: '',
            [e.target.name]: e.target.value.trim()
        })
    }

    onSubmit = async (e) => {
        const { counterReducer } = this.props;
        e.preventDefault();
        // e.stopPropogation()
        if (this.state.cifTmp.trim() === '') {
            await this.setState({ searchResult: null, error: 'Vui lòng nhập giá trị' })
            return;
        }
        await this.props.renderLoading(true)
        const { typeSearch } = this.state;
        switch (true) {
        case counterReducer.dataLogin.Roles.includes(TELLER):
            // debugger;
            await this.props.getInfoCustomerByCIF(this.props.counterReducer.dataConnectDesktop.CounterID, this.state.cifTmp.trim(), Number(typeSearch));
            // await this.props.addBiometricExistFromCustomerInfos()
            break;
        case counterReducer.dataLogin.Roles.includes(INBIOCOLLECTOR):
            this.props.getCustomerInfosCollector(counterReducer.dataConnectDesktop.CounterID, this.state.cifTmp.trim())
            break;

        default:
            break;
        }
        await this.props.renderLoading(false)
    }
    renderListUserAdd = () => {
        // let { searchResult } = this.state;
        
        let searchResult = this.state.searchResult[0]
        return (
            // searchResult
            //(searchResult.CustomerNumber === this.state.cifTmp) &&
            <div>
                <p className='search'>Kết quả tìm kiếm:</p>
                <div className="customer-info">
                    <div className="customer-info__col wrap-img">
                        <img 
                            src={
                                searchResult.FaceID? 
                                    apiGetBioFaceImage(searchResult.FaceID)
                                    :
                                    imgUserGrayBg
                            } 
                            alt="user" 
                        />
                    </div>
                    <div className="customer-info__col left ">
                        <div className="customer-info__col__row">
                            <div className="item name line-clamp">{
                                searchResult.FullNameVi === "" ? 
                                    searchResult.Name 
                                    : 
                                    searchResult.FullNameVi
                            }</div>
                            <div className="item type-cus line-clamp">{searchResult.SegmentDesc}</div>
                        </div>
                        <div className="customer-info__col__row">
                            <div className="item">
                                <div className="title">GTTT:</div>
                                <div className="value">{searchResult.IDNumber}</div>
                            </div>
                            <div className="item gender">
                                <div className="title">Giới tính:</div>
                                <div className="value">{searchResult.Gender}</div>
                            </div>
                        </div>
                        <div className="customer-info__col__row">
                            <div className="item">
                                <div className="title">Địa chỉ:</div>
                                <div className="value">{searchResult.Address}</div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-info__col center fit-content-item">
                        <div className="customer-info__col__row">
                            <div className="item">
                                <div className="title">CIF:</div>
                                <div className="value">{searchResult.CustomerNumber}</div>
                            </div>
                        </div>
                        <ButtonConfirmControl
                            onSubmit={() => { this.showInforTicket() }}
                            bg_color="#005993"
                            titleConfirm="Xác nhận"
                        />
                    </div>
                </div>
            </div>
        )
    }

    showInforTicket = async () => {
        this.setState({
            step: 2,
        })
        await this.props.isRegistration(true, this.state.searchResult)
        await this.props.addCustomerInfos(
            this.props.counterReducer.customerInfo.Cifs[0], 
            this.props.dataGetTicket.TicketID, 
            false, 
            this.props.counterReducer.dataLogin.Roles
        );
        const errorAddCusInfos = this.props.counterReducer.err[typesAction.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR]
        if (errorAddCusInfos && errorAddCusInfos.Code === 76) {
            const OPEN_POPUP_LEVEL_2 = 2;
            const { CustomerNumber, CustomerName, TicketNumber, TellerName, OfficeName } = errorAddCusInfos
            const dataTranferFollowPopup = {
                image_noti: iconNotificationClose,
                title: <ErrorGetTicket76
                    CustomerNumber={CustomerNumber}
                    CustomerName={CustomerName}
                    TicketNumber={TicketNumber}
                    TellerName={TellerName}
                    OfficeName={OfficeName}
                />,
                widthPopup: "363px",
                titleConfirm: "Xác nhận"
            }

            this.props.clearError(typesAction.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR)

            this.props.openPopup(
                popupBioTypes.PopupAccect,
                OPEN_POPUP_LEVEL_2,
                dataTranferFollowPopup
            )
            return;
        }
        await this.props.addBiometricExistFromCustomerInfos(
            this.props.counterReducer.customerInfo.BioInfos, 
            this.props.dataGetTicket.TicketID
        );
        
        if(!isDisableEventWithCORPCust(
            this.props.dataGetTicket.TicketID, 
            this.props.counterReducer)){
            autoOpenPopupWhenGetTicket(
                this, 
                this.props.dataGetTicket.TicketID
            );
        }

    }
    render() {
        let listCurrentTicket = this.props.counterReducer.listCurrentTicket
        let dataGetTicket = this.props.dataGetTicket;
        let information;
        information = listCurrentTicket.find(item => item.TicketID === dataGetTicket.TicketID);
        // if (this.props.counterReducer.dataLogin.Role === INBIOCOLLECTOR) {
        //     information = listCurrentTicket[0]
        // }
        // console.log(this.props.flowUpdate);
        
        return (
            <Bound>
                {information.CustomerInfos ? (
                    <UserInfomation
                        flowUpdate={this.props.flowUpdate}
                        data={information}
                        dataGetTicket={this.props.dataGetTicket}
                    />
                ) : (
                    <React.Fragment>
                        <div className="block_radio">
                            <label className="radio-field">
                                <input
                                    id="type-search"
                                    type="radio"
                                    name="type-search"
                                    value="1"
                                    checked={this.state.typeSearch === '1'}
                                    onChange={() =>
                                        this.setState({
                                            typeSearch: '1',
                                            error: '',
                                        })
                                    }
                                />
                                <div className="text_label">GTTT</div>
                            </label>
                            <label className="radio-field">
                                <input
                                    id="type-search"
                                    type="radio"
                                    name="type-search"
                                    value="0"
                                    checked={this.state.typeSearch === '0'}
                                    onChange={() =>
                                        this.setState({
                                            typeSearch: '0',
                                            error: '',
                                        })
                                    }
                                />
                                <div className="text_label">Thông tin CIF</div>
                            </label>
                        </div>
                        <div>
                            <form
                                className="input_check"
                                onSubmit={this.onSubmit}
                            >
                                <input
                                    onChange={this.onChange}
                                    type="text"
                                    name="cifTmp"
                                    onKeyUp={(e) => {
                                        // debugger;
                                        if (e.charCode === 13) {
                                            e.preventDefault()
                                            this.onSubmit(e)
                                        }
                                        return false
                                    }}
                                    placeholder="Nhập thông tin tìm kiếm"
                                />
                                <button className="btn_check" type="submit">
                                    <img src={ic_search} alt="ic_search" />
                                </button>
                            </form>
                            <div className="error">{this.state.error}</div>
                            {this.state.searchResult &&
                                this.renderListUserAdd()}
                        </div>
                    </React.Fragment>
                )}
            </Bound>
        )
    }

    componentDidMount() {
        this.props.clearCustomerInfosInStore()
    }
    

    componentWillUnmount() {
        this.props.clearCustomerInfosInStore();
    }
    
}

const mapStateToProps = state => ({
    counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
    /// customerNumber: CIF
    getInfoCustomerByCIF: (counterId, customerNumber, typeSearch=0) => 
        dispatch(getInfoCustomer(counterId, customerNumber,typeSearch)),
    clearError: (typeErr) => dispatch(clearError(typeErr)),
    addCustomerInfos: (customerInfos, ticketID, isChangeTicket=false,  roles=["TELLER"]) => 
        dispatch(addCustomerInfos(customerInfos, ticketID, isChangeTicket, roles)),
    getCustomerInfosCollector: (counterId, cifsNum) => 
        dispatch(getCustomerInfos(counterId, cifsNum)),
    addBiometricExistFromCustomerInfos: (bioInfos, ticketId) =>
        dispatch(addBiometricExistFromCustomerInfos(bioInfos, ticketId)),
    clearCustomerInfosInStore: () => 
        dispatch(clearCustomerInfosInStore()),
    openPopup: (type, levelPopup, data) => 
        dispatch(openPopup(type, levelPopup, data)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UserCheckCIF)