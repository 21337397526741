import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { connect } from 'react-redux'

import * as typesAction from "../../../actions/types";

//Image
// import ic_close_noti from "../../../images/ic_counter_notification_close.svg";
import ic_cancel from "../../../images/icon_cancel.svg";
//data
import { branch } from "../../../data/mockData";
import { TELLER } from "../../../data/userRole";
// import { transaction } from "../../../data/data";
// import * as ticketStatuses from "../../../data/ticketStatuses";

// import action
import { startOrStopCounter, getCurrentTickets } from "../../../actions/counterAction"

// import type
import * as counterStates from "../../../data/counterStates";
import { openPopup } from "../../../actions/popupBioAction";

// helper
import getDataAdministrativeUnitsByCode, { fieldAdministrativeUnits } from "../../../helper/getDataAdministrativeUnitsByCode"
import { buildErrorContent } from "../../../helper/tool";
import { clearError } from "../../../actions/commonAction";
import isStopCounter from "../../../helper/handleStopCounter";


const Bound = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  font-family: "SVN-Gilroy";
  font-size: 20px;
  font-weight: bold;
  justify-content: space-between;
  padding: 17px 0 28px 0;
  .branch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 24px;
    .branch-name {
      color: #005993;
      margin-bottom: 10px;
    }
    .branch-address {
      font-size: 14px;
      font-weight: 500;
    }
    .address {
      max-width: 450px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .transaction-status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 24px;
    .system-status {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 0 80px 0 0;
      .active-status {
        margin-bottom: 10px;
      }
      .time-wrapper {
        display: flex;
        flex-direction: row;
        .time-title {
          font-size: 14px;
          font-weight: bold;
        }
        .time {
          width: 55px;
          font-size: 14px;
          font-weight: 500;
          /* padding-left: 15px; */
          text-align: end;
        }
      }
    }
    .button-active {
      font-size: 12px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 36px;
      text-transform: uppercase;
      border-radius: 18px;
      color: #ffffff;
      cursor: pointer;
      margin: 10px 0 0 0;
      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 6px;
        line-height: 0;
      }
    }
    .button-active2 {
        width: 110px;
        height: 36px;
        margin: 10px 0 0 0;
    }
  }
`;
class Header extends Component {
    state = {
    // isActive: this.props.isActive,
        time: "",
        branchName: branch.name,
        branchAddress: branch.address,
        counterName: "",
        counterTransaction: ""
    };
    componentDidMount() {
        this.getTimeNow = setInterval(() => {
            this.setState({ time: moment().format("HH:mm:ss") });
        }, 1000);
        let counterName = "";
        const { dataLogin } = this.props.counterReducer;
        let counterTransaction = 
      dataLogin.Roles.includes(TELLER)?
          dataLogin.ServAllService ?
              "Tất cả nghiệp vụ"
              :
              `Nghiệp vụ thực hiện (${("0" + dataLogin.ServiceList.length).slice(-2)})`
          :
          "Đăng ký STH cho cán bộ VietinBank";
        // if (this.props.counter) {
        //   counterName = this.props.counter.name;
        //   if (this.props.counter.transaction.length === transaction.length) {
        //     counterTransaction = "Thực hiện tất cả giao dịch";
        //   }
        // }
        // 


        this.setState({ counterName, counterTransaction });
    }

    componentWillUnmount() {
        clearInterval(this.getTimeNow);
    }

    toggleCounterStatus = () => {
        const { 
            counterState
        } = this.props.counterReducer;
    
        if(!isStopCounter(this, this.props.counterReducer)) return;

        // this.props.toggleCounterStatus();
        const { dataConnectDesktop } = this.props.counterReducer;
        // if (this.props.counterReducer.listCurrentTicket.length > 0) {
        //   return
        // } else {
        if (counterState === counterStates.STOP_COUNTER) {
            this.props.startOrStopCounter(dataConnectDesktop.CounterID, counterStates.OPEN_COUNTER)
            // this.props.getCurrentTickets(dataConnectDesktop.CounterID, dataConnectDesktop.OfficeID)
            return;
        }
        this.props.startOrStopCounter(dataConnectDesktop.CounterID, counterStates.STOP_COUNTER)
    // }
    };
    render() {
    // console.log('dataas', this.props.data)
        const { 
            dataLogin, 
            dataConnectDesktop,
            counterState,
            // listMissCalled,
            // listMoveTo
        } = this.props.counterReducer;
        const wardName = getDataAdministrativeUnitsByCode(fieldAdministrativeUnits.ward, dataLogin.OfficeWard, "name")
        const districtName = getDataAdministrativeUnitsByCode(fieldAdministrativeUnits.district, dataLogin.OfficeDistrict, "name")
        const cityName = getDataAdministrativeUnitsByCode(fieldAdministrativeUnits.city, dataLogin.OfficeProvince, "name")

        return (
            <Bound>
                <div className="branch">
                    <p className="branch-name">
                        {
                            dataLogin.Roles.includes(TELLER) ? 
                                dataLogin.OfficeName 
                                : 
                                dataConnectDesktop.OfficeName || dataLogin.OfficeName || ""
                        }
                    </p>
                    <p className="branch-address address">{
                        dataLogin.Roles.includes(TELLER) ? 
                            [dataLogin.OfficeAddress, wardName, districtName, cityName].join(', ')
                            : 
                            !!dataLogin.OfficeWard && 
              !!dataLogin.OfficeDistrict && 
              !!dataLogin.OfficeProvince ?
                                [dataLogin.OfficeAddress, wardName, districtName, cityName].join(', ')
                                :
                                ""
                    }</p>
                </div>
                <div className="branch" style={{alignItems: "center"}}>
                    <p className="branch-name">{`Quầy số ${
                        dataConnectDesktop.CounterNum
                    }`}</p>
                    <p className="branch-address">{this.state.counterTransaction}</p>
                </div>
                <div className="transaction-status">
                    <div className="system-status">
                        <p
                            className="active-status"
                            style={{ color: (counterState === counterStates.OPEN_COUNTER || this.props.data.length > 0) ? "#0fb11f" : "#f31919" }}>
                            {
                                dataLogin.Roles.includes(TELLER) ?
                                    (counterState === counterStates.OPEN_COUNTER || this.props.data.length > 0) ? "Đang giao dịch" : "Ngừng phục vụ"
                                    :
                                    ""
                            }
                        </p>
                        <div className="time-wrapper">
                            <div className="time-title">Thời gian hệ thống:</div>
                            <div className="time">{this.state.time}</div>
                        </div>
                    </div>
                    <div>
                        {
                            dataLogin.Roles.includes(TELLER) &&
              <React.Fragment>
                  <div
                      className="button-active"
                      style={{
                          backgroundColor: (counterState === counterStates.OPEN_COUNTER || this.props.data.length > 0) ? "#f31919" : "#0fb11f",
                          // opacity: this.props.data.length > 0 ? 0.1: "unset",
                          // cursor: this.props.data.length > 0 ? "default": "pointer"
                      }}
                      onClick={
                    
                          this.toggleCounterStatus
                      }
                  >
                      <div className="status">
                          <div>{(counterState === counterStates.OPEN_COUNTER || this.props.data.length > 0) ? "ngừng gd" : "bắt đầu gd"}</div>
                      </div>
                  </div>
                  <div 
                      className="button-active"
                      style={{
                          backgroundColor: 'var(--peacock-blue)'
                      }}
                      onClick={() => window.location.reload()}
                  >Refresh</div>
                  {/*<div className="button-active2">
                  {
                    (counterState === counterStates.OPEN_COUNTER || this.props.data.length > 0) &&
                    <div
                      className="button-active"
                      style={{
                        backgroundColor: "#005993"
                      }}
                      onClick={() => { }}>
                      <div className="status">
                        <div>Lịch sử GD</div>
                      </div>
                    </div>
                  }
                </div>*/}
              </React.Fragment>
                        }
                    </div>
                </div>
            </Bound>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.counterReducer !== this.props.counterReducer) {
            if (this.props.counterReducer.err[typesAction.CHANGE_STATE_COUNTER_ERR] === 5) {

                let err = buildErrorContent(typesAction.CHANGE_STATE_COUNTER_ERR, this.props.counterReducer.err[typesAction.CHANGE_STATE_COUNTER_ERR]);
                this.props.openPopup(9, 1, { icon: ic_cancel, label: err })
                this.props.clearError(typesAction.CHANGE_STATE_COUNTER_ERR)
                return;
            }
        }
    }

}
const mapStateToProps = state => ({
    counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
    startOrStopCounter: (counterId, stateCounter) =>
        dispatch(startOrStopCounter(counterId, stateCounter)),
    getCurrentTickets: (counterId, officeId) =>
        dispatch(getCurrentTickets(counterId, officeId)),
    openPopup: (type, levelPopup, data) => dispatch(openPopup(type, levelPopup, data)),
    clearError: (typeErr) => dispatch(clearError(typeErr)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
