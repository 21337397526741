import React from 'react'
import { css } from 'styled-components'

// component
import DropDownSearchObj from '../../../../../Control/DropDownSearchObj'

// styles
import { WrapFilterComboBox } from './index.styles'

const FilterComboBox = ({ title, ...propsFilter }) => {
    return (
        <WrapFilterComboBox>
            <div className="title-dropdown">{title}</div>
            <DropDownSearchObj {...propsFilter} extendStylesSelect={css`height: 36px;`} />
        </WrapFilterComboBox>
    )
}

export default FilterComboBox
