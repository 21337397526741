import React, { useRef, useState, useEffect } from 'react'
import IdleTimer from 'react-idle-timer'
import IdleTimeoutModal from './IdleTimeoutModal';

// 30 * 60 * 1000
const MILISECONDS_1_HOUR = 1 * 60 * 60 * 1000;
const MILISECONDS_TIMEOUT = 4 * MILISECONDS_1_HOUR;

const LIST_EVT_DEFAULT = [
    "mousemove",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mouseWheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove",
    "visibilitychange"
]

const TimeoutContainer = ({ target }) => {

    const [isTimeout, setIsTimeout] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);

    const idleTimer = useRef(null);

    useEffect(() => {
        // console.dir(idleTimer)
        return () => {
            // console.log("unmount")
            clearTimeout(idleTimer.current.tId)
            const targetEle = target || document;
            LIST_EVT_DEFAULT.forEach(item => {
                targetEle.removeEventListener(item, _onActive)
                targetEle.removeEventListener(item, _onIdle)
                targetEle.removeEventListener(item, _onAction)
            })
        }
    }, [])

    const _onActive = (e) => {
        // console.log('user is active', e)
        setIsTimeout(false)
    }

    const _onIdle = (e) => {
        // console.log('user is idle', e)

        if (isTimeout) {
            // console.log("timeout")
            return;
        }

        setIsShowModal(true)
        idleTimer.current.reset();
        setIsTimeout(true)
    }

    const _onAction = (e) => {
        // console.log('user did something', e)
        setIsTimeout(false)
    }

    const _handleAccept = () => {
        setIsShowModal(false)
    }

    return (
        <>
            <IdleTimer
                ref={idleTimer}
                element={target || document}
                onActive={_onActive}
                onIdle={_onIdle}
                onAction={_onAction}
                // debounce={500}
                timeout={MILISECONDS_TIMEOUT}
            />
            
            <IdleTimeoutModal 
                isShowModal={isShowModal}
                onAccept={_handleAccept}
            />
        </>
    )
}

export default TimeoutContainer
