import Axios from 'axios'
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// host
import { host } from '../../../host'

// styles
import { Styles, extendStyleButton, Footer } from './index.styles'

// component
import ButtonControl from '../../MainPage/Mainscreen/Task/ButtonControl'

// image
import restart_device from '../../../images/restart_device.svg'
import restart_app from '../../../images/restart_app.svg'
import shutdown_device from '../../../images/shutdown_device.svg'
import ic_close_gray from '../../../images/ic_close_gray.svg'
import LoadingControl from '../../Control/LoadingControl'

// action
import { setStatusDevice } from '../../../actions/commonAction'

// constant
import { lstStatusDevice } from '../../../reducers/commonReducer'

const apiControlTablet = `${host}counter/notify-tablet`

const statusCodes = {
    restartDevice: 1,
    restartApp: 2,
    shutdownDevice: 3
}

const timeWaitingControl = 3 * 60 * 1000;
const timeWaitingShutdown = 10 * 1000;

const Loading = () => (
    <Footer>
        <LoadingControl size="25px" loadingPage={false} />
        <div className="loading-text">Vui lòng chờ, hệ thống đang xử lý yêu cầu</div>
    </Footer>
)

const Error = () => <Footer>Không thể xử lý yêu cầu tới thiết bị vào lúc này</Footer>

const PopupControlTablet = ({ closePopup }) => {
    const watingRunningApp = useRef(null);
    const dispatch = useDispatch();
    const { commonReducer } = useSelector((state) => ({
        commonReducer: state.commonReducer,
    }))
    
    const [footer, setFooter] = useState(<Footer />);
    const [isDisableClick, setIsDisableClick] = useState(false);
    const [isClosePopup, setIsClosePopup] = useState(false);
    const _setLoading = () => setFooter(<Loading />)
    const _setError = () => setFooter(<Error />)
    const _setEmptyFooter = () => setFooter(<Footer />)

    useEffect(() => {
        if (commonReducer.statusDevice === lstStatusDevice.RUNNING) {
            clearTimeout(watingRunningApp.current)
            _setEmptyFooter()
            setIsDisableClick(false)
            // closePopup()
        }
        return () => {
            clearTimeout(watingRunningApp.current)
        }
    }, [commonReducer.statusDevice])

    useEffect(() => {
        if (isClosePopup) closePopup()
    }, [isClosePopup, closePopup])

    const _callApi = async (StatusCode) => {
        if(isDisableClick) return;

        dispatch(setStatusDevice(lstStatusDevice.WAITING_START))
        _setLoading()
        setIsDisableClick(true)
        const dataRequest = JSON.stringify({
            StatusCode
        })
        try {
            const res = await Axios.post(apiControlTablet, dataRequest);
            const { data } = res;
            const isSuccess = data.Code === 0;
            if(isSuccess){
                const timeWaiting =
                    StatusCode === statusCodes.shutdownDevice
                        ? timeWaitingShutdown
                        : timeWaitingControl
                // console.log(timeWaiting)
                /* watingRunningApp.current =  */setTimeout(() => {
                    // _setEmptyFooter()
                    // setIsDisableClick(false)
                    dispatch(setStatusDevice(lstStatusDevice.RUNNING))
                    setIsClosePopup(true)
                }, timeWaiting)
            }else{
                _setError()
                setIsDisableClick(false)
            }
        }
        catch(err) {
            _setError()
            setIsDisableClick(false)
        }
    }

    const _handleRestartDevice = (e) => {
        _callApi(statusCodes.restartDevice)
    }

    const _handleRestartApp = (e) => {
        _callApi(statusCodes.restartApp)
    }

    const _handleShutDownDevice = (e) => {
        _callApi(statusCodes.shutdownDevice)
    }

    return (
        <Styles>
            <div className="popup">
                <div className="header">
                    <div className="title">Quản lý thiết bị</div>
                    <img
                        src={ic_close_gray}
                        alt="close-popup"
                        onClick={isDisableClick ? null : closePopup}
                    />
                </div>
                <div className="main">
                    <ButtonControl
                        width="263px"
                        margin="0 0 16px 0"
                        icon={restart_device}
                        bgColor="var(--peacock-blue)"
                        content="restart thiết bị"
                        extendStyles={extendStyleButton}
                        // isDisable={isDisableClick}
                        onSubmit={_handleRestartDevice}
                    />
                    <ButtonControl
                        width="263px"
                        margin="0 0 16px 0"
                        icon={restart_app}
                        bgColor="var(--peacock-blue)"
                        content="restart ứng dụng"
                        extendStyles={extendStyleButton}
                        // isDisable={isDisableClick}
                        onSubmit={_handleRestartApp}
                    />
                    <ButtonControl
                        width="263px"
                        margin="0"
                        icon={shutdown_device}
                        bgColor="var(--lipstick)"
                        content="shutdown thiết bị"
                        extendStyles={extendStyleButton}
                        // isDisable={isDisableClick}
                        onSubmit={_handleShutDownDevice}
                    />
                    {footer}
                </div>
            </div>
        </Styles>
    )
}

export default PopupControlTablet
