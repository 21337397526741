import React, { Component } from 'react'
import { connect } from 'react-redux';
import IpValidate from 'ip-validator';


//import data
import { user } from '../../data/mockData';

// images
import logo_viettin from '../../images/logo_vietinbank.svg'
import ic_counter_notification_close from '../../images/ic_counter_notification_close.svg'
import setting from '../../images/ic_setting_login.svg'

// import action
import { tellerLoginCounter, getCounterDetails } from '../../actions/counterAction';
import { clearError, getNameOfClient } from "../../actions/commonAction";

// import type
import * as typesAction from "../../actions/types";
import { popupBioTypes } from '../../data/PopupTypes'

// import helper 
import { buildErrorContent } from "../../helper/tool"

//import component
import LoadingControl from "../Control/LoadingControl";
import { openPopup } from '../../actions/popupBioAction';

// styles
import { LoginStyles } from './index.styles';


class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userStore: user,
            userTmp: '',
            passTmp: '',
            counter: null,
            err: '',
            isLoading: false,
        }
        this.oldUser = ''
        this.oldPass = ''
        this._isMounted = false;
    }
  
    onClear = () => {
        this.setState({ userTmp: '', passTmp: '' })
    }

    handleUserInput = (e) => {
        let regex = e
            .target
            .value
            .replace(/\s/g, "")
        this.oldUser = regex
        this.setState({
            userTmp: this.oldUser,
            err: ''
        })
    }

    handlePassInput = (e) => {
        let regex = e
            .target
            .value
            .replace(/\s/g, "")
        this.oldPass = regex
        this.setState({
            passTmp: this.oldPass,
            err: ''
        })
    }
    onSubmit = async (e) => {
        e.preventDefault();
        await this.setState({ err: '' })
        let { userTmp, passTmp } = this.state;
        const { dataConnectDesktop } = this.props.counterReducer;
        if (Object.keys(dataConnectDesktop).length > 0) {
            if (!userTmp.trim() || !passTmp.trim()) {
                this.setState({ err: 'Tài khoản và mật khẩu không được để trống!' });
                return;
            }
            await this._isMounted && this.setState({ isLoading: true })
            await this.props.tellerLoginCounter({
                Email: userTmp.toLowerCase(),
                Pwd: passTmp,
                CounterID: dataConnectDesktop.CounterID,
                OfficeID: dataConnectDesktop.OfficeID,
                DeviceID: dataConnectDesktop.DeviceID
            })
            await this._isMounted && this.setState({ isLoading: false })
        }
        else {
            this.props.openPopup(9, 1, {
                label: 'Quầy chưa được cấu hình vào hệ thống',
                icon: ic_counter_notification_close,
                width: '230px'
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
    
        if (
            prevProps.commonReducer.clientName !==
        this.props.commonReducer.clientName
        ) {
            if (this.props.commonReducer.clientName === 'EMPTY') {
                this.props.openPopup(popupBioTypes.PopupFillClientName, 1, {})
                return
            }

            const clientNameIsIp =
            IpValidate.ipv4(this.props.commonReducer.clientName) ||
            IpValidate.ipv6(this.props.commonReducer.clientName)
            if (clientNameIsIp) {
                this.props.openPopup(popupBioTypes.PopupFillClientName, 1, {})
                return
            }
            this.props.getCounterDetails()
        }

        // console.log(this.props.counterReducer)
        if (prevProps.counterReducer !== this.props.counterReducer) {

            if (this.props.counterReducer.err[typesAction.COUNTER_LOGIN_ERR]) {
                // console.clear();
                this._isMounted && this.setState({
                    err: buildErrorContent(typesAction.COUNTER_LOGIN_ERR, this.props.counterReducer.err[typesAction.COUNTER_LOGIN_ERR])
                }, () => this.props.clearError(typesAction.COUNTER_LOGIN_ERR))
                return;
            }
        }

    }


    render() {
        const { dataConnectDesktop } = this.props.counterReducer;
        return (
            <LoginStyles>
                <div className="loginContainer">
                    <div className="rowHeader">
                        <div
                            className="settings"
                            onClick={() =>
                                Object.keys(dataConnectDesktop).length > 0 ?
                                    null
                                    :
                                    this.props.openPopup(11, 1, {})
                            } >
                            <p className="title">
                                {
                                    Object.keys(dataConnectDesktop).length > 0 ?
                                        `${dataConnectDesktop.OfficeName} - Quầy số ${dataConnectDesktop.CounterNum}`
                                        :
                                        'Quầy chưa cấu hình...'
                                }
                            </p>
                            <img
                                src={setting} alt="asd" />
                            {/* </div> */}
                        </div>
                        <div className="logoVietinBank">
                            <img src={logo_viettin} alt="logoViettinBank" />
                        </div>
                    </div>
                    <div className="rowLinear"></div>
                    <div className="rowMain">
                        <div className='col_login'>
                            <div className="title">
                                <p>VietinBank SDB Counter</p>
                            </div>
                            <div className="content">
                Đăng nhập hệ thống
                            </div>
                            <form onSubmit={(e) => this.onSubmit(e)} autoComplete="off">
                                <div className="form_login">
                                    <label>TÀI KHOẢN</label>
                                    <input
                                        name='userName'
                                        onChange={(e) => this.handleUserInput(e)}
                                        className='userName'
                                        autoComplete="false"
                                        value={this.state.userTmp || ''}
                                        placeholder='Nhập tài khoản'></input>
                                    <label>MẬT KHẨU</label>
                                    <input
                                        type='password'
                                        onChange={(e) => this.handlePassInput(e)}
                                        className='password'
                                        autoComplete="new-password"
                                        value={this.state.passTmp || ''}
                                        placeholder='Nhập mật khẩu'></input>
                                    <div className="notify">
                                        {

                                            this._isMounted && this.state.err ?
                                                this.state.err
                                                :
                                                this._isMounted && this.state.isLoading ?
                                                    <div className="loading">
                                                        <LoadingControl size={20} loadingPage={false} />
                            Đang tải...
                                                    </div>
                                                    :
                                                    null
                                        }
                                    </div>
                                    <div className='blockBtn'>
                                        <button type='submit' className='btn_login'>ĐĂNG NHẬP</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="rowLinear lineTwo"></div>
            </LoginStyles>
        )
    }
    async componentDidMount() {
        this._isMounted = true;
        await this.props.getNameOfClient();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
}

const mapStateFromProps = state => ({
    counterReducer: state.counterReducer,
    commonReducer: state.commonReducer,
})

const mapDispatchFromProps = (dispatch) => ({
    tellerLoginCounter: (dataLogin) => dispatch(tellerLoginCounter(dataLogin)),
    clearError: (typeErr) => dispatch(clearError(typeErr)),
    openPopup: (type, levelPopup, data) =>
        dispatch(openPopup(type, levelPopup, data)),
    getNameOfClient: (excuteNameIsIP) =>
        dispatch(getNameOfClient(excuteNameIsIP)),
    getCounterDetails: () => dispatch(getCounterDetails()),
})

export default connect(mapStateFromProps, mapDispatchFromProps, null, { forwardRef: true })(LoginPage) 