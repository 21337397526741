import * as types from '../actions/types';

/**
 * @param {number} typePopup ex: number defined exact popup to show
 * @param {number} levelPopup ex: 1 / 2 / 3 
 * @param {data}  data data folow with popup
 */
export const openPopup = (typePopup, levelPopup, data) => dispatch => dispatch({
    type: levelPopup === 1 ? types.OPEN_POPUPLV1 : levelPopup === 2 ? types.OPEN_POPUPLV2 : levelPopup === 3 && types.OPEN_POPUPLV3,
    typePopup,
    data,
    levelPopup,
})
/**
 * @param {number} levelPopup ex: 1 / 2 / 3 
 */
export const closePopup = (levelPopup) => dispatch => dispatch({
    type: levelPopup === 1 ? types.CLOSE_POPUPLV1 : levelPopup === 2 ? types.CLOSE_POPUPLV2 : levelPopup === 3 && types.CLOSE_POPUPLV3,
})

export const resetPopup = (dispatch) => dispatch({
    type: types.RESET_POPUP
})