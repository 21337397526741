import React, { useState, useRef } from 'react'

// styles
import { WrapPopupStyles } from '../WrapPopupStyles'
import { WrapQueryByBioImage } from './index.styles'

// image
import icCloseGray from '../../../../images/ic_close_gray.svg'

// component
import FaceQuery from './FaceQuery'
import FingerQuery from './FingerQuery'

// hooks
import useMoveElement from '../../../../helper/hooks/useMoveElement'

const QueryByBioImage = ({ handleClose }) => {

    const queryByBioImageRef = useRef(null)

    const [loadingQueryByFace, setLoadingQueryByFace] = useState(false);
    const [loadingQueryByFinger, setLoadingQueryByFinger] = useState(false);
    const [disabledQueryByFinger, setDisabledQueryByFinger] = useState(false);
    const [error, setError] = useState('');

    useMoveElement(queryByBioImageRef, ".title")

    const _handleClosePopup = () => {
        if (loadingQueryByFace || loadingQueryByFinger) return;
        handleClose()
    }

    return (
        <WrapPopupStyles>
            <WrapQueryByBioImage ref={queryByBioImageRef}>
                <div className="title">Vấn tin sinh trắc học</div>
                <img className="ic-close" src={icCloseGray} alt="close" onClick={_handleClosePopup} />
                <div className="content">
                    <FaceQuery
                        setDisabledQueryByFinger={setDisabledQueryByFinger}
                        loadingQueryByFinger={loadingQueryByFinger}
                        setLoadingQueryByFace={setLoadingQueryByFace}
                        setError={setError}
                    />
                    <FingerQuery
                        disabledQueryByFinger={disabledQueryByFinger}
                        loadingQueryByFace={loadingQueryByFace}
                        setLoadingQueryByFinger={setLoadingQueryByFinger}
                        setError={setError}
                    />
                </div>
                <div className="error">{error}</div>
            </WrapQueryByBioImage>
        </WrapPopupStyles>
    )
}

export default QueryByBioImage
