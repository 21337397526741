import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

// image
import logo_vietinbank from '../../../images/logo_vietinbank.svg'
import ic_notification_not_new from '../../../images/ic_notification.svg'
import ic_notification from '../../../images/ic_notification_v2.svg'
import ic_logout from '../../../images/Icons_Logout.svg'
import ic_line from '../../../images/Pattern_VTB-03.png'
import ic_close_noti from '../../../images/ic_counter_notification_close.svg'
import logoVtb from '../../../images/logo.png'

// import action
import { setStatusSeen } from '../../../actions/notifyAction'
import { openPopup } from '../../../actions/popupBioAction'
import typesKeyDetailPopup from '../../../data/typesKeyDetailPopup'

// data
import { TELLER } from '../../../data/userRole'
import * as counterStates from '../../../data/counterStates'

// import helper
import isStopCounter from '../../../helper/handleStopCounter'

const BoundStyle = styled.div`
    .main-head {
        display: flex;
        align-items: center;
        height: 78px;
        background-color: #7ed3f7;
        padding: 0 90px 0px 31px;
        .header-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 1;
            font-family: 'SVN-Gilroy';

            .btn-logout {
                display: flex;
                align-items: center;
                cursor: pointer;
                span {
                    margin-left: 5px;
                    font-weight: bold;
                    line-height: 24px;
                    color: var(--lipstick);
                    font-size: 15px;
                }
            }

            .content-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-right: 36px;
                .full-name {
                    font-size: 16px;
                    font-weight: bold;
                    font-style: normal;
                    line-height: 1.19;
                    color: #222222;
                }
                .email {
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 1.3;
                    color: #005993;
                }
            }
            img.notification {
                width: 24px;
                height: 24px;
                object-fit: contain;
                cursor: pointer;
            }
            img.img-avatar {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: #c8c8c8;
                margin: 0 6px 0 50px;
            }
        }
    }

    .rowLine {
        width: 100%;
        height: 12px;
        background-image: url(${ic_line});
        background-position: center;
        background-size: cover;
    }
`
class HeaderContainer extends Component {
    handleShowNotice = (e) => {
        e.preventDefault()
        this.props.setStatusSeen()
        const bellNoticeEleLocation = document
            .querySelector('img.notification')
            .getBoundingClientRect()

        const offset = {
            top: bellNoticeEleLocation.top + bellNoticeEleLocation.height,
            left: bellNoticeEleLocation.left,
        }
        this.props.openPopup(13, 1, { offset })
    }

    handleLogoutCounter = () => {
        const { counterState } = this.props.counterReducer
        if (counterState === counterStates.OPEN_COUNTER) {
            this.props.openPopup(9, 1, {
                label:
                    'Vui lòng ngừng giao dịch trước khi đăng xuất khỏi hệ thống',
                icon: ic_close_noti,
                width: '248px',
            })
            return
        }
        if (!isStopCounter(this, this.props.counterReducer)) return

        this.props.openPopup(3, 1, {
            content: 'Xác nhận đăng xuất hệ thống?',
            contentDelete: '',
            width: '256px',
            key: typesKeyDetailPopup.logOutCounterWeb,
        })
    }

    render() {
        const { dataLogin } = this.props.counterReducer
        return (
            <BoundStyle>
                <div className="main-head">
                    <div className="header-left">
                        <div className="btn-logout" onClick={this.handleLogoutCounter}>
                            <img
                                className="ic-down"
                                src={ic_logout}
                                alt="ic-arrow-down"
                            />
                            <span>Đăng xuất</span>
                        </div>
                        <img className="img-avatar" src={logoVtb} alt="" />
                        <div className="content-info">
                            <div className="full-name">
                                {dataLogin.FullName}
                            </div>
                            <div className="email">{dataLogin.Email}</div>
                        </div>
                        {this.props.counterReducer.dataLogin.Roles.includes(
                            TELLER
                        ) && (
                            <img
                                className="notification"
                                src={
                                    this.props.notifyReducer.isNew
                                        ? ic_notification
                                        : ic_notification_not_new
                                }
                                alt="ic-notification"
                                onClick={this.handleShowNotice}
                            />
                        )}
                    </div>
                    <img src={logo_vietinbank} alt="ic-logo-vietinbank" />
                </div>

                <div className="rowLine"></div>
            </BoundStyle>
        )
    }
}
const mapStateFromProps = (state) => ({
    counterReducer: state.counterReducer,
    notifyReducer: state.notifyReducer,
})

const mapDispatchToProps = (dispatch) => ({
    setStatusSeen: () => dispatch(setStatusSeen()),
    openPopup: (type, levelPopup, data) =>
        dispatch(openPopup(type, levelPopup, data)),
})

export default connect(mapStateFromProps, mapDispatchToProps, null, {
    forwardRef: true,
})(HeaderContainer)
