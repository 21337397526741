import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'

// image
import icPause from '../../images/ic_pause.svg'
import icPlay from '../../images/ic_play.svg'
import iconNotificationFailed from '../../images/ic_counter_notification_close.svg'

// data
import * as ticketStatuses from '../../data/ticketStatuses'

// action
import { unpauseTicket, pauseTicket } from '../../actions/counterAction'
import { openPopup } from '../../actions/popupBioAction'
import { openPopupNotifyErrorLv3 } from '../../helper/actionOpenPopup'

const StyledCountTimeServe = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .pause-status {
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 128%;
        color: #f31919;
        width: 160px;
    }

    .time {
        font-family: 'SVN-Gilroy';
        font-size: 28px;
        font-weight: 500;
        line-height: 0.93;
        letter-spacing: 1px;
        color: #000000;
        width: 160px;
    }
    img {
        margin-left: 15px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
    }
`

class CountTimeServeV2 extends Component {
    state = {
        time: '00:00:00',
    }

    setCountTime = () => {
        const { ticketId } = this.props
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === ticketId
        )
        if (!currentTicket) return false

        const formatNumber = (num) => (num < 10 ? `0${num}` : num)
        const currentTime = moment()

        const duration = moment.duration(currentTime.diff(currentTicket.CallAt))

        const hrs = duration.hours()
        const mins = duration.minutes()
        const secs = duration.seconds()
        this.setState({
            time: `${formatNumber(hrs)} : ${formatNumber(
                mins
            )} : ${formatNumber(secs)}`,
        })
        return true
    }

    handleShowTime = () => {
        clearInterval(this.countTime)
        if (!this.setCountTime()) return
        this.countTime = setInterval(() => {
            this.setCountTime()
        }, 1000)
    }

    handleBtn = (e) => {
        e.preventDefault()
        const { dataConnectDesktop } = this.props.counterReducer
        const { ticketId } = this.props
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === ticketId
        )
        if (!currentTicket) return

        const ticketIsPaused = currentTicket.Status === ticketStatuses.PAUSED
        if (ticketIsPaused) {
            const ticketInprocessing = this.props.counterReducer.listCurrentTicket.find(
                (item) => item.Status === ticketStatuses.INPROCESSING
            )
            if (ticketInprocessing) {
                const dataNotify = {
                    image_noti: iconNotificationFailed,
                    title: `Bạn đang thực hiện giao dịch với KH số ${ticketInprocessing.Number}, vui lòng Tạm dừng hoặc Hoàn tất GD trước khi tiếp tục giao dịch với KH ${currentTicket.Number}`,
                    widthPopup: '266px',
                    titleConfirm: 'Xác nhận',
                }
                openPopupNotifyErrorLv3(
                    this,
                    dataNotify,
                    'handleBtn - CountTimeServeV2.js'
                )
                return
            }
            this.props.unpauseTicket(dataConnectDesktop.CounterID, ticketId)
            return
        }

        this.props.pauseTicket(dataConnectDesktop.CounterID, ticketId)
    }

    render() {
        const { ticketId } = this.props
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(
            (item) => item.TicketID === ticketId
        )
        return (
            <StyledCountTimeServe>
                {currentTicket.Status === ticketStatuses.PAUSED ? (
                    <div className="pause-status">Đang tạm dừng</div>
                ) : (
                    <div className="time">{this.state.time}</div>
                )}
                {!!currentTicket && (
                    <img
                        onClick={this.handleBtn}
                        src={
                            currentTicket.Status === ticketStatuses.PAUSED
                                ? icPlay
                                : icPause
                        }
                        alt="btn"
                    />
                )}
            </StyledCountTimeServe>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.ticketId !== prevProps.ticketId) this.handleShowTime()
    }

    componentDidMount() {
        this.handleShowTime()
    }

    componentWillUnmount() {
        clearInterval(this.countTime)
    }
}

const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
})

const mapDispatchToProps = (dispatch) => ({
    openPopup: (typePopup, levelPopup, data) =>
        dispatch(openPopup(typePopup, levelPopup, data)),
    pauseTicket: (counterId, ticketId) =>
        dispatch(pauseTicket(counterId, ticketId)),
    unpauseTicket: (counterId, ticketId) =>
        dispatch(unpauseTicket(counterId, ticketId)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CountTimeServeV2)
