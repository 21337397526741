export const dataWard = {
    "25640": {
        "name": "Long Hoa",
        "type": "xa",
        "slug": "long-hoa",
        "name_with_type": "Xã Long Hoa",
        "path": "Long Hoa, Hòa Thành, Tây Ninh",
        "path_with_type": "Xã Long Hoa, Huyện Hòa Thành, Tỉnh Tây Ninh",
        "code": "25640",
        "parent_code": "709"
    },
    "30280": {
        "name": "Mỹ Bình",
        "type": "phuong",
        "slug": "my-binh",
        "name_with_type": "Phường Mỹ Bình",
        "path": "Mỹ Bình, Long Xuyên, An Giang",
        "path_with_type": "Phường Mỹ Bình, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30280",
        "parent_code": "883"
    },
    "30283": {
        "name": "Mỹ Long",
        "type": "phuong",
        "slug": "my-long",
        "name_with_type": "Phường Mỹ Long",
        "path": "Mỹ Long, Long Xuyên, An Giang",
        "path_with_type": "Phường Mỹ Long, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30283",
        "parent_code": "883"
    },
    "30285": {
        "name": "Đông Xuyên",
        "type": "phuong",
        "slug": "dong-xuyen",
        "name_with_type": "Phường Đông Xuyên",
        "path": "Đông Xuyên, Long Xuyên, An Giang",
        "path_with_type": "Phường Đông Xuyên, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30285",
        "parent_code": "883"
    },
    "30286": {
        "name": "Mỹ Xuyên",
        "type": "phuong",
        "slug": "my-xuyen",
        "name_with_type": "Phường Mỹ Xuyên",
        "path": "Mỹ Xuyên, Long Xuyên, An Giang",
        "path_with_type": "Phường Mỹ Xuyên, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30286",
        "parent_code": "883"
    },
    "30289": {
        "name": "Bình Đức",
        "type": "phuong",
        "slug": "binh-duc",
        "name_with_type": "Phường Bình Đức",
        "path": "Bình Đức, Long Xuyên, An Giang",
        "path_with_type": "Phường Bình Đức, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30289",
        "parent_code": "883"
    },
    "30292": {
        "name": "Bình Khánh",
        "type": "phuong",
        "slug": "binh-khanh",
        "name_with_type": "Phường Bình Khánh",
        "path": "Bình Khánh, Long Xuyên, An Giang",
        "path_with_type": "Phường Bình Khánh, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30292",
        "parent_code": "883"
    },
    "30295": {
        "name": "Mỹ Phước",
        "type": "phuong",
        "slug": "my-phuoc",
        "name_with_type": "Phường Mỹ Phước",
        "path": "Mỹ Phước, Long Xuyên, An Giang",
        "path_with_type": "Phường Mỹ Phước, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30295",
        "parent_code": "883"
    },
    "30298": {
        "name": "Mỹ Quý",
        "type": "phuong",
        "slug": "my-quy",
        "name_with_type": "Phường Mỹ Quý",
        "path": "Mỹ Quý, Long Xuyên, An Giang",
        "path_with_type": "Phường Mỹ Quý, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30298",
        "parent_code": "883"
    },
    "30301": {
        "name": "Mỹ Thới",
        "type": "phuong",
        "slug": "my-thoi",
        "name_with_type": "Phường Mỹ Thới",
        "path": "Mỹ Thới, Long Xuyên, An Giang",
        "path_with_type": "Phường Mỹ Thới, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30301",
        "parent_code": "883"
    },
    "30304": {
        "name": "Mỹ Thạnh",
        "type": "phuong",
        "slug": "my-thanh",
        "name_with_type": "Phường Mỹ Thạnh",
        "path": "Mỹ Thạnh, Long Xuyên, An Giang",
        "path_with_type": "Phường Mỹ Thạnh, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30304",
        "parent_code": "883"
    },
    "30307": {
        "name": "Mỹ Hòa",
        "type": "phuong",
        "slug": "my-hoa",
        "name_with_type": "Phường Mỹ Hòa",
        "path": "Mỹ Hòa, Long Xuyên, An Giang",
        "path_with_type": "Phường Mỹ Hòa, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30307",
        "parent_code": "883"
    },
    "30310": {
        "name": "Mỹ Khánh",
        "type": "xa",
        "slug": "my-khanh",
        "name_with_type": "Xã Mỹ Khánh",
        "path": "Mỹ Khánh, Long Xuyên, An Giang",
        "path_with_type": "Xã Mỹ Khánh, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30310",
        "parent_code": "883"
    },
    "30313": {
        "name": "Mỹ Hoà Hưng",
        "type": "xa",
        "slug": "my-hoa-hung",
        "name_with_type": "Xã Mỹ Hoà Hưng",
        "path": "Mỹ Hoà Hưng, Long Xuyên, An Giang",
        "path_with_type": "Xã Mỹ Hoà Hưng, Thành phố Long Xuyên, Tỉnh An Giang",
        "code": "30313",
        "parent_code": "883"
    },
    "30316": {
        "name": "Châu Phú B",
        "type": "phuong",
        "slug": "chau-phu-b",
        "name_with_type": "Phường Châu Phú B",
        "path": "Châu Phú B, Châu Đốc, An Giang",
        "path_with_type": "Phường Châu Phú B, Thành phố Châu Đốc, Tỉnh An Giang",
        "code": "30316",
        "parent_code": "884"
    },
    "30319": {
        "name": "Châu Phú A",
        "type": "phuong",
        "slug": "chau-phu-a",
        "name_with_type": "Phường Châu Phú A",
        "path": "Châu Phú A, Châu Đốc, An Giang",
        "path_with_type": "Phường Châu Phú A, Thành phố Châu Đốc, Tỉnh An Giang",
        "code": "30319",
        "parent_code": "884"
    },
    "30322": {
        "name": "Vĩnh Mỹ",
        "type": "phuong",
        "slug": "vinh-my",
        "name_with_type": "Phường Vĩnh Mỹ",
        "path": "Vĩnh Mỹ, Châu Đốc, An Giang",
        "path_with_type": "Phường Vĩnh Mỹ, Thành phố Châu Đốc, Tỉnh An Giang",
        "code": "30322",
        "parent_code": "884"
    },
    "30325": {
        "name": "Núi Sam",
        "type": "phuong",
        "slug": "nui-sam",
        "name_with_type": "Phường Núi Sam",
        "path": "Núi Sam, Châu Đốc, An Giang",
        "path_with_type": "Phường Núi Sam, Thành phố Châu Đốc, Tỉnh An Giang",
        "code": "30325",
        "parent_code": "884"
    },
    "30328": {
        "name": "Vĩnh Ngươn",
        "type": "phuong",
        "slug": "vinh-nguon",
        "name_with_type": "Phường Vĩnh Ngươn",
        "path": "Vĩnh Ngươn, Châu Đốc, An Giang",
        "path_with_type": "Phường Vĩnh Ngươn, Thành phố Châu Đốc, Tỉnh An Giang",
        "code": "30328",
        "parent_code": "884"
    },
    "30331": {
        "name": "Vĩnh Tế",
        "type": "xa",
        "slug": "vinh-te",
        "name_with_type": "Xã Vĩnh Tế",
        "path": "Vĩnh Tế, Châu Đốc, An Giang",
        "path_with_type": "Xã Vĩnh Tế, Thành phố Châu Đốc, Tỉnh An Giang",
        "code": "30331",
        "parent_code": "884"
    },
    "30334": {
        "name": "Vĩnh Châu",
        "type": "xa",
        "slug": "vinh-chau",
        "name_with_type": "Xã Vĩnh Châu",
        "path": "Vĩnh Châu, Châu Đốc, An Giang",
        "path_with_type": "Xã Vĩnh Châu, Thành phố Châu Đốc, Tỉnh An Giang",
        "code": "30334",
        "parent_code": "884"
    },
    "30337": {
        "name": "An Phú",
        "type": "thi-tran",
        "slug": "an-phu",
        "name_with_type": "Thị trấn An Phú",
        "path": "An Phú, An Phú, An Giang",
        "path_with_type": "Thị trấn An Phú, Huyện An Phú, Tỉnh An Giang",
        "code": "30337",
        "parent_code": "886"
    },
    "30340": {
        "name": "Khánh An",
        "type": "xa",
        "slug": "khanh-an",
        "name_with_type": "Xã Khánh An",
        "path": "Khánh An, An Phú, An Giang",
        "path_with_type": "Xã Khánh An, Huyện An Phú, Tỉnh An Giang",
        "code": "30340",
        "parent_code": "886"
    },
    "30341": {
        "name": "Long Bình",
        "type": "thi-tran",
        "slug": "long-binh",
        "name_with_type": "Thị Trấn Long Bình",
        "path": "Long Bình, An Phú, An Giang",
        "path_with_type": "Thị Trấn Long Bình, Huyện An Phú, Tỉnh An Giang",
        "code": "30341",
        "parent_code": "886"
    },
    "30343": {
        "name": "Khánh Bình",
        "type": "xa",
        "slug": "khanh-binh",
        "name_with_type": "Xã Khánh Bình",
        "path": "Khánh Bình, An Phú, An Giang",
        "path_with_type": "Xã Khánh Bình, Huyện An Phú, Tỉnh An Giang",
        "code": "30343",
        "parent_code": "886"
    },
    "30346": {
        "name": "Quốc Thái",
        "type": "xa",
        "slug": "quoc-thai",
        "name_with_type": "Xã Quốc Thái",
        "path": "Quốc Thái, An Phú, An Giang",
        "path_with_type": "Xã Quốc Thái, Huyện An Phú, Tỉnh An Giang",
        "code": "30346",
        "parent_code": "886"
    },
    "30349": {
        "name": "Nhơn Hội",
        "type": "xa",
        "slug": "nhon-hoi",
        "name_with_type": "Xã Nhơn Hội",
        "path": "Nhơn Hội, An Phú, An Giang",
        "path_with_type": "Xã Nhơn Hội, Huyện An Phú, Tỉnh An Giang",
        "code": "30349",
        "parent_code": "886"
    },
    "30352": {
        "name": "Phú Hữu",
        "type": "xa",
        "slug": "phu-huu",
        "name_with_type": "Xã Phú Hữu",
        "path": "Phú Hữu, An Phú, An Giang",
        "path_with_type": "Xã Phú Hữu, Huyện An Phú, Tỉnh An Giang",
        "code": "30352",
        "parent_code": "886"
    },
    "30355": {
        "name": "Phú Hội",
        "type": "xa",
        "slug": "phu-hoi",
        "name_with_type": "Xã Phú Hội",
        "path": "Phú Hội, An Phú, An Giang",
        "path_with_type": "Xã Phú Hội, Huyện An Phú, Tỉnh An Giang",
        "code": "30355",
        "parent_code": "886"
    },
    "30358": {
        "name": "Phước Hưng",
        "type": "xa",
        "slug": "phuoc-hung",
        "name_with_type": "Xã Phước Hưng",
        "path": "Phước Hưng, An Phú, An Giang",
        "path_with_type": "Xã Phước Hưng, Huyện An Phú, Tỉnh An Giang",
        "code": "30358",
        "parent_code": "886"
    },
    "30361": {
        "name": "Vĩnh Lộc",
        "type": "xa",
        "slug": "vinh-loc",
        "name_with_type": "Xã Vĩnh Lộc",
        "path": "Vĩnh Lộc, An Phú, An Giang",
        "path_with_type": "Xã Vĩnh Lộc, Huyện An Phú, Tỉnh An Giang",
        "code": "30361",
        "parent_code": "886"
    },
    "30364": {
        "name": "Vĩnh Hậu",
        "type": "xa",
        "slug": "vinh-hau",
        "name_with_type": "Xã Vĩnh Hậu",
        "path": "Vĩnh Hậu, An Phú, An Giang",
        "path_with_type": "Xã Vĩnh Hậu, Huyện An Phú, Tỉnh An Giang",
        "code": "30364",
        "parent_code": "886"
    },
    "30367": {
        "name": "Vĩnh Trường",
        "type": "xa",
        "slug": "vinh-truong",
        "name_with_type": "Xã Vĩnh Trường",
        "path": "Vĩnh Trường, An Phú, An Giang",
        "path_with_type": "Xã Vĩnh Trường, Huyện An Phú, Tỉnh An Giang",
        "code": "30367",
        "parent_code": "886"
    },
    "30370": {
        "name": "Vĩnh Hội Đông",
        "type": "xa",
        "slug": "vinh-hoi-dong",
        "name_with_type": "Xã Vĩnh Hội Đông",
        "path": "Vĩnh Hội Đông, An Phú, An Giang",
        "path_with_type": "Xã Vĩnh Hội Đông, Huyện An Phú, Tỉnh An Giang",
        "code": "30370",
        "parent_code": "886"
    },
    "30373": {
        "name": "Đa Phước",
        "type": "xa",
        "slug": "da-phuoc",
        "name_with_type": "Xã Đa Phước",
        "path": "Đa Phước, An Phú, An Giang",
        "path_with_type": "Xã Đa Phước, Huyện An Phú, Tỉnh An Giang",
        "code": "30373",
        "parent_code": "886"
    },
    "30376": {
        "name": "Long Thạnh",
        "type": "phuong",
        "slug": "long-thanh",
        "name_with_type": "Phường Long Thạnh",
        "path": "Long Thạnh, Tân Châu, An Giang",
        "path_with_type": "Phường Long Thạnh, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30376",
        "parent_code": "887"
    },
    "30377": {
        "name": "Long Hưng",
        "type": "phuong",
        "slug": "long-hung",
        "name_with_type": "Phường Long Hưng",
        "path": "Long Hưng, Tân Châu, An Giang",
        "path_with_type": "Phường Long Hưng, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30377",
        "parent_code": "887"
    },
    "30378": {
        "name": "Long Châu",
        "type": "phuong",
        "slug": "long-chau",
        "name_with_type": "Phường Long Châu",
        "path": "Long Châu, Tân Châu, An Giang",
        "path_with_type": "Phường Long Châu, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30378",
        "parent_code": "887"
    },
    "30379": {
        "name": "Phú Lộc",
        "type": "xa",
        "slug": "phu-loc",
        "name_with_type": "Xã Phú Lộc",
        "path": "Phú Lộc, Tân Châu, An Giang",
        "path_with_type": "Xã Phú Lộc, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30379",
        "parent_code": "887"
    },
    "30382": {
        "name": "Vĩnh Xương",
        "type": "xa",
        "slug": "vinh-xuong",
        "name_with_type": "Xã Vĩnh Xương",
        "path": "Vĩnh Xương, Tân Châu, An Giang",
        "path_with_type": "Xã Vĩnh Xương, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30382",
        "parent_code": "887"
    },
    "30385": {
        "name": "Vĩnh Hòa",
        "type": "xa",
        "slug": "vinh-hoa",
        "name_with_type": "Xã Vĩnh Hòa",
        "path": "Vĩnh Hòa, Tân Châu, An Giang",
        "path_with_type": "Xã Vĩnh Hòa, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30385",
        "parent_code": "887"
    },
    "30387": {
        "name": "Tân Thạnh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thạnh",
        "path": "Tân Thạnh, Tân Châu, An Giang",
        "path_with_type": "Xã Tân Thạnh, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30387",
        "parent_code": "887"
    },
    "30388": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Tân Châu, An Giang",
        "path_with_type": "Xã Tân An, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30388",
        "parent_code": "887"
    },
    "30391": {
        "name": "Long An",
        "type": "xa",
        "slug": "long-an",
        "name_with_type": "Xã Long An",
        "path": "Long An, Tân Châu, An Giang",
        "path_with_type": "Xã Long An, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30391",
        "parent_code": "887"
    },
    "30394": {
        "name": "Long Phú",
        "type": "phuong",
        "slug": "long-phu",
        "name_with_type": "Phường Long Phú",
        "path": "Long Phú, Tân Châu, An Giang",
        "path_with_type": "Phường Long Phú, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30394",
        "parent_code": "887"
    },
    "30397": {
        "name": "Châu Phong",
        "type": "xa",
        "slug": "chau-phong",
        "name_with_type": "Xã Châu Phong",
        "path": "Châu Phong, Tân Châu, An Giang",
        "path_with_type": "Xã Châu Phong, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30397",
        "parent_code": "887"
    },
    "30400": {
        "name": "Phú Vĩnh",
        "type": "xa",
        "slug": "phu-vinh",
        "name_with_type": "Xã Phú Vĩnh",
        "path": "Phú Vĩnh, Tân Châu, An Giang",
        "path_with_type": "Xã Phú Vĩnh, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30400",
        "parent_code": "887"
    },
    "30403": {
        "name": "Lê Chánh",
        "type": "xa",
        "slug": "le-chanh",
        "name_with_type": "Xã Lê Chánh",
        "path": "Lê Chánh, Tân Châu, An Giang",
        "path_with_type": "Xã Lê Chánh, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30403",
        "parent_code": "887"
    },
    "30412": {
        "name": "Long Sơn",
        "type": "phuong",
        "slug": "long-son",
        "name_with_type": "Phường Long Sơn",
        "path": "Long Sơn, Tân Châu, An Giang",
        "path_with_type": "Phường Long Sơn, Thị xã Tân Châu, Tỉnh An Giang",
        "code": "30412",
        "parent_code": "887"
    },
    "30406": {
        "name": "Phú Mỹ",
        "type": "thi-tran",
        "slug": "phu-my",
        "name_with_type": "Thị trấn Phú Mỹ",
        "path": "Phú Mỹ, Phú Tân, An Giang",
        "path_with_type": "Thị trấn Phú Mỹ, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30406",
        "parent_code": "888"
    },
    "30409": {
        "name": "Chợ Vàm",
        "type": "thi-tran",
        "slug": "cho-vam",
        "name_with_type": "Thị trấn Chợ Vàm",
        "path": "Chợ Vàm, Phú Tân, An Giang",
        "path_with_type": "Thị trấn Chợ Vàm, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30409",
        "parent_code": "888"
    },
    "30415": {
        "name": "Long Hoà",
        "type": "xa",
        "slug": "long-hoa",
        "name_with_type": "Xã Long Hoà",
        "path": "Long Hoà, Phú Tân, An Giang",
        "path_with_type": "Xã Long Hoà, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30415",
        "parent_code": "888"
    },
    "30418": {
        "name": "Phú Long",
        "type": "xa",
        "slug": "phu-long",
        "name_with_type": "Xã Phú Long",
        "path": "Phú Long, Phú Tân, An Giang",
        "path_with_type": "Xã Phú Long, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30418",
        "parent_code": "888"
    },
    "30421": {
        "name": "Phú Lâm",
        "type": "xa",
        "slug": "phu-lam",
        "name_with_type": "Xã Phú Lâm",
        "path": "Phú Lâm, Phú Tân, An Giang",
        "path_with_type": "Xã Phú Lâm, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30421",
        "parent_code": "888"
    },
    "30424": {
        "name": "Phú Hiệp",
        "type": "xa",
        "slug": "phu-hiep",
        "name_with_type": "Xã Phú Hiệp",
        "path": "Phú Hiệp, Phú Tân, An Giang",
        "path_with_type": "Xã Phú Hiệp, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30424",
        "parent_code": "888"
    },
    "30427": {
        "name": "Phú Thạnh",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thạnh",
        "path": "Phú Thạnh, Phú Tân, An Giang",
        "path_with_type": "Xã Phú Thạnh, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30427",
        "parent_code": "888"
    },
    "30430": {
        "name": "Hoà Lạc",
        "type": "xa",
        "slug": "hoa-lac",
        "name_with_type": "Xã Hoà Lạc",
        "path": "Hoà Lạc, Phú Tân, An Giang",
        "path_with_type": "Xã Hoà Lạc, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30430",
        "parent_code": "888"
    },
    "30433": {
        "name": "Phú Thành",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thành",
        "path": "Phú Thành, Phú Tân, An Giang",
        "path_with_type": "Xã Phú Thành, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30433",
        "parent_code": "888"
    },
    "30436": {
        "name": "Phú An",
        "type": "xa",
        "slug": "phu-an",
        "name_with_type": "Xã Phú An",
        "path": "Phú An, Phú Tân, An Giang",
        "path_with_type": "Xã Phú An, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30436",
        "parent_code": "888"
    },
    "30439": {
        "name": "Phú Xuân",
        "type": "xa",
        "slug": "phu-xuan",
        "name_with_type": "Xã Phú Xuân",
        "path": "Phú Xuân, Phú Tân, An Giang",
        "path_with_type": "Xã Phú Xuân, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30439",
        "parent_code": "888"
    },
    "30442": {
        "name": "Hiệp Xương",
        "type": "xa",
        "slug": "hiep-xuong",
        "name_with_type": "Xã Hiệp Xương",
        "path": "Hiệp Xương, Phú Tân, An Giang",
        "path_with_type": "Xã Hiệp Xương, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30442",
        "parent_code": "888"
    },
    "30445": {
        "name": "Phú Bình",
        "type": "xa",
        "slug": "phu-binh",
        "name_with_type": "Xã Phú Bình",
        "path": "Phú Bình, Phú Tân, An Giang",
        "path_with_type": "Xã Phú Bình, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30445",
        "parent_code": "888"
    },
    "30448": {
        "name": "Phú Thọ",
        "type": "xa",
        "slug": "phu-tho",
        "name_with_type": "Xã Phú Thọ",
        "path": "Phú Thọ, Phú Tân, An Giang",
        "path_with_type": "Xã Phú Thọ, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30448",
        "parent_code": "888"
    },
    "30451": {
        "name": "Phú Hưng",
        "type": "xa",
        "slug": "phu-hung",
        "name_with_type": "Xã Phú Hưng",
        "path": "Phú Hưng, Phú Tân, An Giang",
        "path_with_type": "Xã Phú Hưng, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30451",
        "parent_code": "888"
    },
    "30454": {
        "name": "Bình Thạnh Đông",
        "type": "xa",
        "slug": "binh-thanh-dong",
        "name_with_type": "Xã Bình Thạnh Đông",
        "path": "Bình Thạnh Đông, Phú Tân, An Giang",
        "path_with_type": "Xã Bình Thạnh Đông, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30454",
        "parent_code": "888"
    },
    "30457": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Phú Tân, An Giang",
        "path_with_type": "Xã Tân Hòa, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30457",
        "parent_code": "888"
    },
    "30460": {
        "name": "Tân Trung",
        "type": "xa",
        "slug": "tan-trung",
        "name_with_type": "Xã Tân Trung",
        "path": "Tân Trung, Phú Tân, An Giang",
        "path_with_type": "Xã Tân Trung, Huyện Phú Tân, Tỉnh An Giang",
        "code": "30460",
        "parent_code": "888"
    },
    "30463": {
        "name": "Cái Dầu",
        "type": "thi-tran",
        "slug": "cai-dau",
        "name_with_type": "Thị trấn Cái Dầu",
        "path": "Cái Dầu, Châu Phú, An Giang",
        "path_with_type": "Thị trấn Cái Dầu, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30463",
        "parent_code": "889"
    },
    "30466": {
        "name": "Khánh Hòa",
        "type": "xa",
        "slug": "khanh-hoa",
        "name_with_type": "Xã Khánh Hòa",
        "path": "Khánh Hòa, Châu Phú, An Giang",
        "path_with_type": "Xã Khánh Hòa, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30466",
        "parent_code": "889"
    },
    "30469": {
        "name": "Mỹ Đức",
        "type": "xa",
        "slug": "my-duc",
        "name_with_type": "Xã Mỹ Đức",
        "path": "Mỹ Đức, Châu Phú, An Giang",
        "path_with_type": "Xã Mỹ Đức, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30469",
        "parent_code": "889"
    },
    "30472": {
        "name": "Mỹ Phú",
        "type": "xa",
        "slug": "my-phu",
        "name_with_type": "Xã Mỹ Phú",
        "path": "Mỹ Phú, Châu Phú, An Giang",
        "path_with_type": "Xã Mỹ Phú, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30472",
        "parent_code": "889"
    },
    "30475": {
        "name": "Ô Long Vỹ",
        "type": "xa",
        "slug": "o-long-vy",
        "name_with_type": "Xã Ô Long Vỹ",
        "path": "Ô Long Vỹ, Châu Phú, An Giang",
        "path_with_type": "Xã Ô Long Vỹ, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30475",
        "parent_code": "889"
    },
    "30478": {
        "name": "Vĩnh Thạnh Trung",
        "type": "xa",
        "slug": "vinh-thanh-trung",
        "name_with_type": "Xã Vĩnh Thạnh Trung",
        "path": "Vĩnh Thạnh Trung, Châu Phú, An Giang",
        "path_with_type": "Xã Vĩnh Thạnh Trung, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30478",
        "parent_code": "889"
    },
    "30481": {
        "name": "Thạnh Mỹ Tây",
        "type": "xa",
        "slug": "thanh-my-tay",
        "name_with_type": "Xã Thạnh Mỹ Tây",
        "path": "Thạnh Mỹ Tây, Châu Phú, An Giang",
        "path_with_type": "Xã Thạnh Mỹ Tây, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30481",
        "parent_code": "889"
    },
    "30484": {
        "name": "Bình Long",
        "type": "xa",
        "slug": "binh-long",
        "name_with_type": "Xã Bình Long",
        "path": "Bình Long, Châu Phú, An Giang",
        "path_with_type": "Xã Bình Long, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30484",
        "parent_code": "889"
    },
    "30487": {
        "name": "Bình Mỹ",
        "type": "xa",
        "slug": "binh-my",
        "name_with_type": "Xã Bình Mỹ",
        "path": "Bình Mỹ, Châu Phú, An Giang",
        "path_with_type": "Xã Bình Mỹ, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30487",
        "parent_code": "889"
    },
    "30490": {
        "name": "Bình Thủy",
        "type": "xa",
        "slug": "binh-thuy",
        "name_with_type": "Xã Bình Thủy",
        "path": "Bình Thủy, Châu Phú, An Giang",
        "path_with_type": "Xã Bình Thủy, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30490",
        "parent_code": "889"
    },
    "30493": {
        "name": "Đào Hữu Cảnh",
        "type": "xa",
        "slug": "dao-huu-canh",
        "name_with_type": "Xã Đào Hữu Cảnh",
        "path": "Đào Hữu Cảnh, Châu Phú, An Giang",
        "path_with_type": "Xã Đào Hữu Cảnh, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30493",
        "parent_code": "889"
    },
    "30496": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Châu Phú, An Giang",
        "path_with_type": "Xã Bình Phú, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30496",
        "parent_code": "889"
    },
    "30499": {
        "name": "Bình Chánh",
        "type": "xa",
        "slug": "binh-chanh",
        "name_with_type": "Xã Bình Chánh",
        "path": "Bình Chánh, Châu Phú, An Giang",
        "path_with_type": "Xã Bình Chánh, Huyện Châu Phú, Tỉnh An Giang",
        "code": "30499",
        "parent_code": "889"
    },
    "30502": {
        "name": "Nhà Bàng",
        "type": "thi-tran",
        "slug": "nha-bang",
        "name_with_type": "Thị trấn Nhà Bàng",
        "path": "Nhà Bàng, Tịnh Biên, An Giang",
        "path_with_type": "Thị trấn Nhà Bàng, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30502",
        "parent_code": "890"
    },
    "30505": {
        "name": "Chi Lăng",
        "type": "thi-tran",
        "slug": "chi-lang",
        "name_with_type": "Thị trấn Chi Lăng",
        "path": "Chi Lăng, Tịnh Biên, An Giang",
        "path_with_type": "Thị trấn Chi Lăng, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30505",
        "parent_code": "890"
    },
    "30508": {
        "name": "Núi Voi",
        "type": "xa",
        "slug": "nui-voi",
        "name_with_type": "Xã Núi Voi",
        "path": "Núi Voi, Tịnh Biên, An Giang",
        "path_with_type": "Xã Núi Voi, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30508",
        "parent_code": "890"
    },
    "30511": {
        "name": "Nhơn Hưng",
        "type": "xa",
        "slug": "nhon-hung",
        "name_with_type": "Xã Nhơn Hưng",
        "path": "Nhơn Hưng, Tịnh Biên, An Giang",
        "path_with_type": "Xã Nhơn Hưng, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30511",
        "parent_code": "890"
    },
    "30514": {
        "name": "An Phú",
        "type": "xa",
        "slug": "an-phu",
        "name_with_type": "Xã An Phú",
        "path": "An Phú, Tịnh Biên, An Giang",
        "path_with_type": "Xã An Phú, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30514",
        "parent_code": "890"
    },
    "30517": {
        "name": "Thới Sơn",
        "type": "xa",
        "slug": "thoi-son",
        "name_with_type": "Xã Thới Sơn",
        "path": "Thới Sơn, Tịnh Biên, An Giang",
        "path_with_type": "Xã Thới Sơn, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30517",
        "parent_code": "890"
    },
    "30520": {
        "name": "Tịnh Biên",
        "type": "thi-tran",
        "slug": "tinh-bien",
        "name_with_type": "Thị trấn Tịnh Biên",
        "path": "Tịnh Biên, Tịnh Biên, An Giang",
        "path_with_type": "Thị trấn Tịnh Biên, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30520",
        "parent_code": "890"
    },
    "30523": {
        "name": "Văn Giáo",
        "type": "xa",
        "slug": "van-giao",
        "name_with_type": "Xã Văn Giáo",
        "path": "Văn Giáo, Tịnh Biên, An Giang",
        "path_with_type": "Xã Văn Giáo, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30523",
        "parent_code": "890"
    },
    "30526": {
        "name": "An Cư",
        "type": "xa",
        "slug": "an-cu",
        "name_with_type": "Xã An Cư",
        "path": "An Cư, Tịnh Biên, An Giang",
        "path_with_type": "Xã An Cư, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30526",
        "parent_code": "890"
    },
    "30529": {
        "name": "An Nông",
        "type": "xa",
        "slug": "an-nong",
        "name_with_type": "Xã An Nông",
        "path": "An Nông, Tịnh Biên, An Giang",
        "path_with_type": "Xã An Nông, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30529",
        "parent_code": "890"
    },
    "30532": {
        "name": "Vĩnh Trung",
        "type": "xa",
        "slug": "vinh-trung",
        "name_with_type": "Xã Vĩnh Trung",
        "path": "Vĩnh Trung, Tịnh Biên, An Giang",
        "path_with_type": "Xã Vĩnh Trung, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30532",
        "parent_code": "890"
    },
    "30535": {
        "name": "Tân Lợi",
        "type": "xa",
        "slug": "tan-loi",
        "name_with_type": "Xã Tân Lợi",
        "path": "Tân Lợi, Tịnh Biên, An Giang",
        "path_with_type": "Xã Tân Lợi, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30535",
        "parent_code": "890"
    },
    "30538": {
        "name": "An Hảo",
        "type": "xa",
        "slug": "an-hao",
        "name_with_type": "Xã An Hảo",
        "path": "An Hảo, Tịnh Biên, An Giang",
        "path_with_type": "Xã An Hảo, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30538",
        "parent_code": "890"
    },
    "30541": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Tịnh Biên, An Giang",
        "path_with_type": "Xã Tân Lập, Huyện Tịnh Biên, Tỉnh An Giang",
        "code": "30541",
        "parent_code": "890"
    },
    "30544": {
        "name": "Tri Tôn",
        "type": "thi-tran",
        "slug": "tri-ton",
        "name_with_type": "Thị trấn Tri Tôn",
        "path": "Tri Tôn, Tri Tôn, An Giang",
        "path_with_type": "Thị trấn Tri Tôn, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30544",
        "parent_code": "891"
    },
    "30547": {
        "name": "Ba Chúc",
        "type": "thi-tran",
        "slug": "ba-chuc",
        "name_with_type": "Thị trấn Ba Chúc",
        "path": "Ba Chúc, Tri Tôn, An Giang",
        "path_with_type": "Thị trấn Ba Chúc, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30547",
        "parent_code": "891"
    },
    "30550": {
        "name": "Lạc Quới",
        "type": "xa",
        "slug": "lac-quoi",
        "name_with_type": "Xã Lạc Quới",
        "path": "Lạc Quới, Tri Tôn, An Giang",
        "path_with_type": "Xã Lạc Quới, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30550",
        "parent_code": "891"
    },
    "30553": {
        "name": "Lê Trì",
        "type": "xa",
        "slug": "le-tri",
        "name_with_type": "Xã Lê Trì",
        "path": "Lê Trì, Tri Tôn, An Giang",
        "path_with_type": "Xã Lê Trì, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30553",
        "parent_code": "891"
    },
    "30556": {
        "name": "Vĩnh Gia",
        "type": "xa",
        "slug": "vinh-gia",
        "name_with_type": "Xã Vĩnh Gia",
        "path": "Vĩnh Gia, Tri Tôn, An Giang",
        "path_with_type": "Xã Vĩnh Gia, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30556",
        "parent_code": "891"
    },
    "30559": {
        "name": "Vĩnh Phước",
        "type": "xa",
        "slug": "vinh-phuoc",
        "name_with_type": "Xã Vĩnh Phước",
        "path": "Vĩnh Phước, Tri Tôn, An Giang",
        "path_with_type": "Xã Vĩnh Phước, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30559",
        "parent_code": "891"
    },
    "30562": {
        "name": "Châu Lăng",
        "type": "xa",
        "slug": "chau-lang",
        "name_with_type": "Xã Châu Lăng",
        "path": "Châu Lăng, Tri Tôn, An Giang",
        "path_with_type": "Xã Châu Lăng, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30562",
        "parent_code": "891"
    },
    "30565": {
        "name": "Lương Phi",
        "type": "xa",
        "slug": "luong-phi",
        "name_with_type": "Xã Lương Phi",
        "path": "Lương Phi, Tri Tôn, An Giang",
        "path_with_type": "Xã Lương Phi, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30565",
        "parent_code": "891"
    },
    "30568": {
        "name": "Lương An Trà",
        "type": "xa",
        "slug": "luong-an-tra",
        "name_with_type": "Xã Lương An Trà",
        "path": "Lương An Trà, Tri Tôn, An Giang",
        "path_with_type": "Xã Lương An Trà, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30568",
        "parent_code": "891"
    },
    "30571": {
        "name": "Tà Đảnh",
        "type": "xa",
        "slug": "ta-danh",
        "name_with_type": "Xã Tà Đảnh",
        "path": "Tà Đảnh, Tri Tôn, An Giang",
        "path_with_type": "Xã Tà Đảnh, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30571",
        "parent_code": "891"
    },
    "30574": {
        "name": "Núi Tô",
        "type": "xa",
        "slug": "nui-to",
        "name_with_type": "Xã Núi Tô",
        "path": "Núi Tô, Tri Tôn, An Giang",
        "path_with_type": "Xã Núi Tô, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30574",
        "parent_code": "891"
    },
    "30577": {
        "name": "An Tức",
        "type": "xa",
        "slug": "an-tuc",
        "name_with_type": "Xã An Tức",
        "path": "An Tức, Tri Tôn, An Giang",
        "path_with_type": "Xã An Tức, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30577",
        "parent_code": "891"
    },
    "30580": {
        "name": "Cô Tô",
        "type": "xa",
        "slug": "co-to",
        "name_with_type": "Xã Cô Tô",
        "path": "Cô Tô, Tri Tôn, An Giang",
        "path_with_type": "Xã Cô Tô, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30580",
        "parent_code": "891"
    },
    "30583": {
        "name": "Tân Tuyến",
        "type": "xa",
        "slug": "tan-tuyen",
        "name_with_type": "Xã Tân Tuyến",
        "path": "Tân Tuyến, Tri Tôn, An Giang",
        "path_with_type": "Xã Tân Tuyến, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30583",
        "parent_code": "891"
    },
    "30586": {
        "name": "Ô Lâm",
        "type": "xa",
        "slug": "o-lam",
        "name_with_type": "Xã Ô Lâm",
        "path": "Ô Lâm, Tri Tôn, An Giang",
        "path_with_type": "Xã Ô Lâm, Huyện Tri Tôn, Tỉnh An Giang",
        "code": "30586",
        "parent_code": "891"
    },
    "30589": {
        "name": "An Châu",
        "type": "thi-tran",
        "slug": "an-chau",
        "name_with_type": "Thị trấn An Châu",
        "path": "An Châu, Châu Thành, An Giang",
        "path_with_type": "Thị trấn An Châu, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30589",
        "parent_code": "892"
    },
    "30592": {
        "name": "An Hòa",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hòa",
        "path": "An Hòa, Châu Thành, An Giang",
        "path_with_type": "Xã An Hòa, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30592",
        "parent_code": "892"
    },
    "30595": {
        "name": "Cần Đăng",
        "type": "xa",
        "slug": "can-dang",
        "name_with_type": "Xã Cần Đăng",
        "path": "Cần Đăng, Châu Thành, An Giang",
        "path_with_type": "Xã Cần Đăng, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30595",
        "parent_code": "892"
    },
    "30598": {
        "name": "Vĩnh Hanh",
        "type": "xa",
        "slug": "vinh-hanh",
        "name_with_type": "Xã Vĩnh Hanh",
        "path": "Vĩnh Hanh, Châu Thành, An Giang",
        "path_with_type": "Xã Vĩnh Hanh, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30598",
        "parent_code": "892"
    },
    "30601": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Châu Thành, An Giang",
        "path_with_type": "Xã Bình Thạnh, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30601",
        "parent_code": "892"
    },
    "30604": {
        "name": "Vĩnh Bình",
        "type": "xa",
        "slug": "vinh-binh",
        "name_with_type": "Xã Vĩnh Bình",
        "path": "Vĩnh Bình, Châu Thành, An Giang",
        "path_with_type": "Xã Vĩnh Bình, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30604",
        "parent_code": "892"
    },
    "30607": {
        "name": "Bình Hòa",
        "type": "xa",
        "slug": "binh-hoa",
        "name_with_type": "Xã Bình Hòa",
        "path": "Bình Hòa, Châu Thành, An Giang",
        "path_with_type": "Xã Bình Hòa, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30607",
        "parent_code": "892"
    },
    "30610": {
        "name": "Vĩnh An",
        "type": "xa",
        "slug": "vinh-an",
        "name_with_type": "Xã Vĩnh An",
        "path": "Vĩnh An, Châu Thành, An Giang",
        "path_with_type": "Xã Vĩnh An, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30610",
        "parent_code": "892"
    },
    "30613": {
        "name": "Hòa Bình Thạnh",
        "type": "xa",
        "slug": "hoa-binh-thanh",
        "name_with_type": "Xã Hòa Bình Thạnh",
        "path": "Hòa Bình Thạnh, Châu Thành, An Giang",
        "path_with_type": "Xã Hòa Bình Thạnh, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30613",
        "parent_code": "892"
    },
    "30616": {
        "name": "Vĩnh Lợi",
        "type": "xa",
        "slug": "vinh-loi",
        "name_with_type": "Xã Vĩnh Lợi",
        "path": "Vĩnh Lợi, Châu Thành, An Giang",
        "path_with_type": "Xã Vĩnh Lợi, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30616",
        "parent_code": "892"
    },
    "30619": {
        "name": "Vĩnh Nhuận",
        "type": "xa",
        "slug": "vinh-nhuan",
        "name_with_type": "Xã Vĩnh Nhuận",
        "path": "Vĩnh Nhuận, Châu Thành, An Giang",
        "path_with_type": "Xã Vĩnh Nhuận, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30619",
        "parent_code": "892"
    },
    "30622": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Châu Thành, An Giang",
        "path_with_type": "Xã Tân Phú, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30622",
        "parent_code": "892"
    },
    "30625": {
        "name": "Vĩnh Thành",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thành",
        "path": "Vĩnh Thành, Châu Thành, An Giang",
        "path_with_type": "Xã Vĩnh Thành, Huyện Châu Thành, Tỉnh An Giang",
        "code": "30625",
        "parent_code": "892"
    },
    "30628": {
        "name": "Chợ Mới",
        "type": "thi-tran",
        "slug": "cho-moi",
        "name_with_type": "Thị trấn Chợ Mới",
        "path": "Chợ Mới, Chợ Mới, An Giang",
        "path_with_type": "Thị trấn Chợ Mới, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30628",
        "parent_code": "893"
    },
    "30631": {
        "name": "Mỹ Luông",
        "type": "thi-tran",
        "slug": "my-luong",
        "name_with_type": "Thị trấn Mỹ Luông",
        "path": "Mỹ Luông, Chợ Mới, An Giang",
        "path_with_type": "Thị trấn Mỹ Luông, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30631",
        "parent_code": "893"
    },
    "30634": {
        "name": "Kiến An",
        "type": "xa",
        "slug": "kien-an",
        "name_with_type": "Xã Kiến An",
        "path": "Kiến An, Chợ Mới, An Giang",
        "path_with_type": "Xã Kiến An, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30634",
        "parent_code": "893"
    },
    "30637": {
        "name": "Mỹ Hội Đông",
        "type": "xa",
        "slug": "my-hoi-dong",
        "name_with_type": "Xã Mỹ Hội Đông",
        "path": "Mỹ Hội Đông, Chợ Mới, An Giang",
        "path_with_type": "Xã Mỹ Hội Đông, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30637",
        "parent_code": "893"
    },
    "30640": {
        "name": "Long Điền A",
        "type": "xa",
        "slug": "long-dien-a",
        "name_with_type": "Xã Long Điền A",
        "path": "Long Điền A, Chợ Mới, An Giang",
        "path_with_type": "Xã Long Điền A, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30640",
        "parent_code": "893"
    },
    "30643": {
        "name": "Tấn Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tấn Mỹ",
        "path": "Tấn Mỹ, Chợ Mới, An Giang",
        "path_with_type": "Xã Tấn Mỹ, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30643",
        "parent_code": "893"
    },
    "30646": {
        "name": "Long Điền B",
        "type": "xa",
        "slug": "long-dien-b",
        "name_with_type": "Xã Long Điền B",
        "path": "Long Điền B, Chợ Mới, An Giang",
        "path_with_type": "Xã Long Điền B, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30646",
        "parent_code": "893"
    },
    "30649": {
        "name": "Kiến Thành",
        "type": "xa",
        "slug": "kien-thanh",
        "name_with_type": "Xã Kiến Thành",
        "path": "Kiến Thành, Chợ Mới, An Giang",
        "path_with_type": "Xã Kiến Thành, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30649",
        "parent_code": "893"
    },
    "30652": {
        "name": "Mỹ Hiệp",
        "type": "xa",
        "slug": "my-hiep",
        "name_with_type": "Xã Mỹ Hiệp",
        "path": "Mỹ Hiệp, Chợ Mới, An Giang",
        "path_with_type": "Xã Mỹ Hiệp, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30652",
        "parent_code": "893"
    },
    "30655": {
        "name": "Mỹ An",
        "type": "xa",
        "slug": "my-an",
        "name_with_type": "Xã Mỹ An",
        "path": "Mỹ An, Chợ Mới, An Giang",
        "path_with_type": "Xã Mỹ An, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30655",
        "parent_code": "893"
    },
    "30658": {
        "name": "Nhơn Mỹ",
        "type": "xa",
        "slug": "nhon-my",
        "name_with_type": "Xã Nhơn Mỹ",
        "path": "Nhơn Mỹ, Chợ Mới, An Giang",
        "path_with_type": "Xã Nhơn Mỹ, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30658",
        "parent_code": "893"
    },
    "30661": {
        "name": "Long Giang",
        "type": "xa",
        "slug": "long-giang",
        "name_with_type": "Xã Long Giang",
        "path": "Long Giang, Chợ Mới, An Giang",
        "path_with_type": "Xã Long Giang, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30661",
        "parent_code": "893"
    },
    "30664": {
        "name": "Long Kiến",
        "type": "xa",
        "slug": "long-kien",
        "name_with_type": "Xã Long Kiến",
        "path": "Long Kiến, Chợ Mới, An Giang",
        "path_with_type": "Xã Long Kiến, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30664",
        "parent_code": "893"
    },
    "30667": {
        "name": "Bình Phước Xuân",
        "type": "xa",
        "slug": "binh-phuoc-xuan",
        "name_with_type": "Xã Bình Phước Xuân",
        "path": "Bình Phước Xuân, Chợ Mới, An Giang",
        "path_with_type": "Xã Bình Phước Xuân, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30667",
        "parent_code": "893"
    },
    "30670": {
        "name": "An Thạnh Trung",
        "type": "xa",
        "slug": "an-thanh-trung",
        "name_with_type": "Xã An Thạnh Trung",
        "path": "An Thạnh Trung, Chợ Mới, An Giang",
        "path_with_type": "Xã An Thạnh Trung, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30670",
        "parent_code": "893"
    },
    "30673": {
        "name": "Hội An",
        "type": "xa",
        "slug": "hoi-an",
        "name_with_type": "Xã Hội An",
        "path": "Hội An, Chợ Mới, An Giang",
        "path_with_type": "Xã Hội An, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30673",
        "parent_code": "893"
    },
    "30676": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Chợ Mới, An Giang",
        "path_with_type": "Xã Hòa Bình, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30676",
        "parent_code": "893"
    },
    "30679": {
        "name": "Hòa An",
        "type": "xa",
        "slug": "hoa-an",
        "name_with_type": "Xã Hòa An",
        "path": "Hòa An, Chợ Mới, An Giang",
        "path_with_type": "Xã Hòa An, Huyện Chợ Mới, Tỉnh An Giang",
        "code": "30679",
        "parent_code": "893"
    },
    "30682": {
        "name": "Núi Sập",
        "type": "thi-tran",
        "slug": "nui-sap",
        "name_with_type": "Thị trấn Núi Sập",
        "path": "Núi Sập, Thoại Sơn, An Giang",
        "path_with_type": "Thị trấn Núi Sập, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30682",
        "parent_code": "894"
    },
    "30685": {
        "name": "Phú Hoà",
        "type": "thi-tran",
        "slug": "phu-hoa",
        "name_with_type": "Thị trấn Phú Hoà",
        "path": "Phú Hoà, Thoại Sơn, An Giang",
        "path_with_type": "Thị trấn Phú Hoà, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30685",
        "parent_code": "894"
    },
    "30688": {
        "name": "Óc Eo",
        "type": "thi-tran",
        "slug": "oc-eo",
        "name_with_type": "Thị Trấn Óc Eo",
        "path": "Óc Eo, Thoại Sơn, An Giang",
        "path_with_type": "Thị Trấn Óc Eo, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30688",
        "parent_code": "894"
    },
    "30691": {
        "name": "Tây Phú",
        "type": "xa",
        "slug": "tay-phu",
        "name_with_type": "Xã Tây Phú",
        "path": "Tây Phú, Thoại Sơn, An Giang",
        "path_with_type": "Xã Tây Phú, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30691",
        "parent_code": "894"
    },
    "30692": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Thoại Sơn, An Giang",
        "path_with_type": "Xã An Bình, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30692",
        "parent_code": "894"
    },
    "30694": {
        "name": "Vĩnh Phú",
        "type": "xa",
        "slug": "vinh-phu",
        "name_with_type": "Xã Vĩnh Phú",
        "path": "Vĩnh Phú, Thoại Sơn, An Giang",
        "path_with_type": "Xã Vĩnh Phú, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30694",
        "parent_code": "894"
    },
    "30697": {
        "name": "Vĩnh Trạch",
        "type": "xa",
        "slug": "vinh-trach",
        "name_with_type": "Xã Vĩnh Trạch",
        "path": "Vĩnh Trạch, Thoại Sơn, An Giang",
        "path_with_type": "Xã Vĩnh Trạch, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30697",
        "parent_code": "894"
    },
    "30700": {
        "name": "Phú Thuận",
        "type": "xa",
        "slug": "phu-thuan",
        "name_with_type": "Xã Phú Thuận",
        "path": "Phú Thuận, Thoại Sơn, An Giang",
        "path_with_type": "Xã Phú Thuận, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30700",
        "parent_code": "894"
    },
    "30703": {
        "name": "Vĩnh Chánh",
        "type": "xa",
        "slug": "vinh-chanh",
        "name_with_type": "Xã Vĩnh Chánh",
        "path": "Vĩnh Chánh, Thoại Sơn, An Giang",
        "path_with_type": "Xã Vĩnh Chánh, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30703",
        "parent_code": "894"
    },
    "30706": {
        "name": "Định Mỹ",
        "type": "xa",
        "slug": "dinh-my",
        "name_with_type": "Xã Định Mỹ",
        "path": "Định Mỹ, Thoại Sơn, An Giang",
        "path_with_type": "Xã Định Mỹ, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30706",
        "parent_code": "894"
    },
    "30709": {
        "name": "Định Thành",
        "type": "xa",
        "slug": "dinh-thanh",
        "name_with_type": "Xã Định Thành",
        "path": "Định Thành, Thoại Sơn, An Giang",
        "path_with_type": "Xã Định Thành, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30709",
        "parent_code": "894"
    },
    "30712": {
        "name": "Mỹ Phú Đông",
        "type": "xa",
        "slug": "my-phu-dong",
        "name_with_type": "Xã Mỹ Phú Đông",
        "path": "Mỹ Phú Đông, Thoại Sơn, An Giang",
        "path_with_type": "Xã Mỹ Phú Đông, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30712",
        "parent_code": "894"
    },
    "30715": {
        "name": "Vọng Đông",
        "type": "xa",
        "slug": "vong-dong",
        "name_with_type": "Xã Vọng Đông",
        "path": "Vọng Đông, Thoại Sơn, An Giang",
        "path_with_type": "Xã Vọng Đông, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30715",
        "parent_code": "894"
    },
    "30718": {
        "name": "Vĩnh Khánh",
        "type": "xa",
        "slug": "vinh-khanh",
        "name_with_type": "Xã Vĩnh Khánh",
        "path": "Vĩnh Khánh, Thoại Sơn, An Giang",
        "path_with_type": "Xã Vĩnh Khánh, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30718",
        "parent_code": "894"
    },
    "30721": {
        "name": "Thoại Giang",
        "type": "xa",
        "slug": "thoai-giang",
        "name_with_type": "Xã Thoại Giang",
        "path": "Thoại Giang, Thoại Sơn, An Giang",
        "path_with_type": "Xã Thoại Giang, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30721",
        "parent_code": "894"
    },
    "30724": {
        "name": "Bình Thành",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thành",
        "path": "Bình Thành, Thoại Sơn, An Giang",
        "path_with_type": "Xã Bình Thành, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30724",
        "parent_code": "894"
    },
    "30727": {
        "name": "Vọng Thê",
        "type": "xa",
        "slug": "vong-the",
        "name_with_type": "Xã Vọng Thê",
        "path": "Vọng Thê, Thoại Sơn, An Giang",
        "path_with_type": "Xã Vọng Thê, Huyện Thoại Sơn, Tỉnh An Giang",
        "code": "30727",
        "parent_code": "894"
    },
    "23281": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Kon Tum, Kon Tum",
        "path_with_type": "Phường Quang Trung, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23281",
        "parent_code": "608"
    },
    "23284": {
        "name": "Duy Tân",
        "type": "phuong",
        "slug": "duy-tan",
        "name_with_type": "Phường Duy Tân",
        "path": "Duy Tân, Kon Tum, Kon Tum",
        "path_with_type": "Phường Duy Tân, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23284",
        "parent_code": "608"
    },
    "23287": {
        "name": "Quyết Thắng",
        "type": "phuong",
        "slug": "quyet-thang",
        "name_with_type": "Phường Quyết Thắng",
        "path": "Quyết Thắng, Kon Tum, Kon Tum",
        "path_with_type": "Phường Quyết Thắng, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23287",
        "parent_code": "608"
    },
    "23290": {
        "name": "Trường Chinh",
        "type": "phuong",
        "slug": "truong-chinh",
        "name_with_type": "Phường Trường Chinh",
        "path": "Trường Chinh, Kon Tum, Kon Tum",
        "path_with_type": "Phường Trường Chinh, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23290",
        "parent_code": "608"
    },
    "23293": {
        "name": "Thắng Lợi",
        "type": "phuong",
        "slug": "thang-loi",
        "name_with_type": "Phường Thắng Lợi",
        "path": "Thắng Lợi, Kon Tum, Kon Tum",
        "path_with_type": "Phường Thắng Lợi, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23293",
        "parent_code": "608"
    },
    "23296": {
        "name": "Ngô Mây",
        "type": "phuong",
        "slug": "ngo-may",
        "name_with_type": "Phường Ngô Mây",
        "path": "Ngô Mây, Kon Tum, Kon Tum",
        "path_with_type": "Phường Ngô Mây, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23296",
        "parent_code": "608"
    },
    "23299": {
        "name": "Thống Nhất",
        "type": "phuong",
        "slug": "thong-nhat",
        "name_with_type": "Phường Thống Nhất",
        "path": "Thống Nhất, Kon Tum, Kon Tum",
        "path_with_type": "Phường Thống Nhất, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23299",
        "parent_code": "608"
    },
    "23302": {
        "name": "Lê Lợi",
        "type": "phuong",
        "slug": "le-loi",
        "name_with_type": "Phường Lê Lợi",
        "path": "Lê Lợi, Kon Tum, Kon Tum",
        "path_with_type": "Phường Lê Lợi, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23302",
        "parent_code": "608"
    },
    "23305": {
        "name": "Nguyễn Trãi",
        "type": "phuong",
        "slug": "nguyen-trai",
        "name_with_type": "Phường Nguyễn Trãi",
        "path": "Nguyễn Trãi, Kon Tum, Kon Tum",
        "path_with_type": "Phường Nguyễn Trãi, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23305",
        "parent_code": "608"
    },
    "23308": {
        "name": "Trần Hưng Đạo",
        "type": "phuong",
        "slug": "tran-hung-dao",
        "name_with_type": "Phường Trần Hưng Đạo",
        "path": "Trần Hưng Đạo, Kon Tum, Kon Tum",
        "path_with_type": "Phường Trần Hưng Đạo, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23308",
        "parent_code": "608"
    },
    "23311": {
        "name": "Đắk Cấm",
        "type": "xa",
        "slug": "dak-cam",
        "name_with_type": "Xã Đắk Cấm",
        "path": "Đắk Cấm, Kon Tum, Kon Tum",
        "path_with_type": "Xã Đắk Cấm, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23311",
        "parent_code": "608"
    },
    "23314": {
        "name": "Kroong",
        "type": "xa",
        "slug": "kroong",
        "name_with_type": "Xã Kroong",
        "path": "Kroong, Kon Tum, Kon Tum",
        "path_with_type": "Xã Kroong, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23314",
        "parent_code": "608"
    },
    "23317": {
        "name": "Ngọk Bay",
        "type": "xa",
        "slug": "ngok-bay",
        "name_with_type": "Xã Ngọk Bay",
        "path": "Ngọk Bay, Kon Tum, Kon Tum",
        "path_with_type": "Xã Ngọk Bay, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23317",
        "parent_code": "608"
    },
    "23320": {
        "name": "Vinh Quang",
        "type": "xa",
        "slug": "vinh-quang",
        "name_with_type": "Xã Vinh Quang",
        "path": "Vinh Quang, Kon Tum, Kon Tum",
        "path_with_type": "Xã Vinh Quang, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23320",
        "parent_code": "608"
    },
    "23323": {
        "name": "Đắk Blà",
        "type": "xa",
        "slug": "dak-bla",
        "name_with_type": "Xã Đắk Blà",
        "path": "Đắk Blà, Kon Tum, Kon Tum",
        "path_with_type": "Xã Đắk Blà, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23323",
        "parent_code": "608"
    },
    "23326": {
        "name": "Ia Chim",
        "type": "xa",
        "slug": "ia-chim",
        "name_with_type": "Xã Ia Chim",
        "path": "Ia Chim, Kon Tum, Kon Tum",
        "path_with_type": "Xã Ia Chim, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23326",
        "parent_code": "608"
    },
    "23327": {
        "name": "Đăk Năng",
        "type": "xa",
        "slug": "dak-nang",
        "name_with_type": "Xã Đăk Năng",
        "path": "Đăk Năng, Kon Tum, Kon Tum",
        "path_with_type": "Xã Đăk Năng, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23327",
        "parent_code": "608"
    },
    "23329": {
        "name": "Đoàn Kết",
        "type": "xa",
        "slug": "doan-ket",
        "name_with_type": "Xã Đoàn Kết",
        "path": "Đoàn Kết, Kon Tum, Kon Tum",
        "path_with_type": "Xã Đoàn Kết, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23329",
        "parent_code": "608"
    },
    "23332": {
        "name": "Chư Hreng",
        "type": "xa",
        "slug": "chu-hreng",
        "name_with_type": "Xã Chư Hreng",
        "path": "Chư Hreng, Kon Tum, Kon Tum",
        "path_with_type": "Xã Chư Hreng, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23332",
        "parent_code": "608"
    },
    "23335": {
        "name": "Đắk Rơ Wa",
        "type": "xa",
        "slug": "dak-ro-wa",
        "name_with_type": "Xã Đắk Rơ Wa",
        "path": "Đắk Rơ Wa, Kon Tum, Kon Tum",
        "path_with_type": "Xã Đắk Rơ Wa, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23335",
        "parent_code": "608"
    },
    "23338": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Kon Tum, Kon Tum",
        "path_with_type": "Xã Hòa Bình, Thành phố Kon Tum, Tỉnh Kon Tum",
        "code": "23338",
        "parent_code": "608"
    },
    "23341": {
        "name": "Đắk Glei",
        "type": "thi-tran",
        "slug": "dak-glei",
        "name_with_type": "Thị trấn Đắk Glei",
        "path": "Đắk Glei, Đắk Glei, Kon Tum",
        "path_with_type": "Thị trấn Đắk Glei, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23341",
        "parent_code": "610"
    },
    "23344": {
        "name": "Đắk Plô",
        "type": "xa",
        "slug": "dak-plo",
        "name_with_type": "Xã Đắk Plô",
        "path": "Đắk Plô, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Đắk Plô, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23344",
        "parent_code": "610"
    },
    "23347": {
        "name": "Đắk Man",
        "type": "xa",
        "slug": "dak-man",
        "name_with_type": "Xã Đắk Man",
        "path": "Đắk Man, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Đắk Man, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23347",
        "parent_code": "610"
    },
    "23350": {
        "name": "Đắk Nhoong",
        "type": "xa",
        "slug": "dak-nhoong",
        "name_with_type": "Xã Đắk Nhoong",
        "path": "Đắk Nhoong, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Đắk Nhoong, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23350",
        "parent_code": "610"
    },
    "23353": {
        "name": "Đắk Pék",
        "type": "xa",
        "slug": "dak-pek",
        "name_with_type": "Xã Đắk Pék",
        "path": "Đắk Pék, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Đắk Pék, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23353",
        "parent_code": "610"
    },
    "23356": {
        "name": "Đắk Choong",
        "type": "xa",
        "slug": "dak-choong",
        "name_with_type": "Xã Đắk Choong",
        "path": "Đắk Choong, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Đắk Choong, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23356",
        "parent_code": "610"
    },
    "23359": {
        "name": "Xốp",
        "type": "xa",
        "slug": "xop",
        "name_with_type": "Xã Xốp",
        "path": "Xốp, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Xốp, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23359",
        "parent_code": "610"
    },
    "23362": {
        "name": "Mường Hoong",
        "type": "xa",
        "slug": "muong-hoong",
        "name_with_type": "Xã Mường Hoong",
        "path": "Mường Hoong, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Mường Hoong, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23362",
        "parent_code": "610"
    },
    "23365": {
        "name": "Ngọc Linh",
        "type": "xa",
        "slug": "ngoc-linh",
        "name_with_type": "Xã Ngọc Linh",
        "path": "Ngọc Linh, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Ngọc Linh, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23365",
        "parent_code": "610"
    },
    "23368": {
        "name": "Đắk Long",
        "type": "xa",
        "slug": "dak-long",
        "name_with_type": "Xã Đắk Long",
        "path": "Đắk Long, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Đắk Long, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23368",
        "parent_code": "610"
    },
    "23371": {
        "name": "Đắk KRoong",
        "type": "xa",
        "slug": "dak-kroong",
        "name_with_type": "Xã Đắk KRoong",
        "path": "Đắk KRoong, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Đắk KRoong, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23371",
        "parent_code": "610"
    },
    "23374": {
        "name": "Đắk Môn",
        "type": "xa",
        "slug": "dak-mon",
        "name_with_type": "Xã Đắk Môn",
        "path": "Đắk Môn, Đắk Glei, Kon Tum",
        "path_with_type": "Xã Đắk Môn, Huyện Đắk Glei, Tỉnh Kon Tum",
        "code": "23374",
        "parent_code": "610"
    },
    "23377": {
        "name": "Plei Cần",
        "type": "thi-tran",
        "slug": "plei-can",
        "name_with_type": "Thị trấn Plei Cần",
        "path": "Plei Cần, Ngọc Hồi, Kon Tum",
        "path_with_type": "Thị trấn Plei Cần, Huyện Ngọc Hồi, Tỉnh Kon Tum",
        "code": "23377",
        "parent_code": "611"
    },
    "23380": {
        "name": "Đắk Ang",
        "type": "xa",
        "slug": "dak-ang",
        "name_with_type": "Xã Đắk Ang",
        "path": "Đắk Ang, Ngọc Hồi, Kon Tum",
        "path_with_type": "Xã Đắk Ang, Huyện Ngọc Hồi, Tỉnh Kon Tum",
        "code": "23380",
        "parent_code": "611"
    },
    "23383": {
        "name": "Đắk Dục",
        "type": "xa",
        "slug": "dak-duc",
        "name_with_type": "Xã Đắk Dục",
        "path": "Đắk Dục, Ngọc Hồi, Kon Tum",
        "path_with_type": "Xã Đắk Dục, Huyện Ngọc Hồi, Tỉnh Kon Tum",
        "code": "23383",
        "parent_code": "611"
    },
    "23386": {
        "name": "Đắk Nông",
        "type": "xa",
        "slug": "dak-nong",
        "name_with_type": "Xã Đắk Nông",
        "path": "Đắk Nông, Ngọc Hồi, Kon Tum",
        "path_with_type": "Xã Đắk Nông, Huyện Ngọc Hồi, Tỉnh Kon Tum",
        "code": "23386",
        "parent_code": "611"
    },
    "23389": {
        "name": "Đắk Xú",
        "type": "xa",
        "slug": "dak-xu",
        "name_with_type": "Xã Đắk Xú",
        "path": "Đắk Xú, Ngọc Hồi, Kon Tum",
        "path_with_type": "Xã Đắk Xú, Huyện Ngọc Hồi, Tỉnh Kon Tum",
        "code": "23389",
        "parent_code": "611"
    },
    "23392": {
        "name": "Đắk Kan",
        "type": "xa",
        "slug": "dak-kan",
        "name_with_type": "Xã Đắk Kan",
        "path": "Đắk Kan, Ngọc Hồi, Kon Tum",
        "path_with_type": "Xã Đắk Kan, Huyện Ngọc Hồi, Tỉnh Kon Tum",
        "code": "23392",
        "parent_code": "611"
    },
    "23395": {
        "name": "Pờ Y",
        "type": "xa",
        "slug": "po-y",
        "name_with_type": "Xã Pờ Y",
        "path": "Pờ Y, Ngọc Hồi, Kon Tum",
        "path_with_type": "Xã Pờ Y, Huyện Ngọc Hồi, Tỉnh Kon Tum",
        "code": "23395",
        "parent_code": "611"
    },
    "23398": {
        "name": "Sa Loong",
        "type": "xa",
        "slug": "sa-loong",
        "name_with_type": "Xã Sa Loong",
        "path": "Sa Loong, Ngọc Hồi, Kon Tum",
        "path_with_type": "Xã Sa Loong, Huyện Ngọc Hồi, Tỉnh Kon Tum",
        "code": "23398",
        "parent_code": "611"
    },
    "23401": {
        "name": "Đắk Tô",
        "type": "thi-tran",
        "slug": "dak-to",
        "name_with_type": "Thị trấn Đắk Tô",
        "path": "Đắk Tô, Đắk Tô, Kon Tum",
        "path_with_type": "Thị trấn Đắk Tô, Huyện Đắk Tô, Tỉnh Kon Tum",
        "code": "23401",
        "parent_code": "612"
    },
    "23427": {
        "name": "Đắk Rơ Nga",
        "type": "xa",
        "slug": "dak-ro-nga",
        "name_with_type": "Xã Đắk Rơ Nga",
        "path": "Đắk Rơ Nga, Đắk Tô, Kon Tum",
        "path_with_type": "Xã Đắk Rơ Nga, Huyện Đắk Tô, Tỉnh Kon Tum",
        "code": "23427",
        "parent_code": "612"
    },
    "23428": {
        "name": "Ngọk Tụ",
        "type": "xa",
        "slug": "ngok-tu",
        "name_with_type": "Xã Ngọk Tụ",
        "path": "Ngọk Tụ, Đắk Tô, Kon Tum",
        "path_with_type": "Xã Ngọk Tụ, Huyện Đắk Tô, Tỉnh Kon Tum",
        "code": "23428",
        "parent_code": "612"
    },
    "23430": {
        "name": "Đắk Trăm",
        "type": "xa",
        "slug": "dak-tram",
        "name_with_type": "Xã Đắk Trăm",
        "path": "Đắk Trăm, Đắk Tô, Kon Tum",
        "path_with_type": "Xã Đắk Trăm, Huyện Đắk Tô, Tỉnh Kon Tum",
        "code": "23430",
        "parent_code": "612"
    },
    "23431": {
        "name": "Văn Lem",
        "type": "xa",
        "slug": "van-lem",
        "name_with_type": "Xã Văn Lem",
        "path": "Văn Lem, Đắk Tô, Kon Tum",
        "path_with_type": "Xã Văn Lem, Huyện Đắk Tô, Tỉnh Kon Tum",
        "code": "23431",
        "parent_code": "612"
    },
    "23434": {
        "name": "Kon Đào",
        "type": "xa",
        "slug": "kon-dao",
        "name_with_type": "Xã Kon Đào",
        "path": "Kon Đào, Đắk Tô, Kon Tum",
        "path_with_type": "Xã Kon Đào, Huyện Đắk Tô, Tỉnh Kon Tum",
        "code": "23434",
        "parent_code": "612"
    },
    "23437": {
        "name": "Tân Cảnh",
        "type": "xa",
        "slug": "tan-canh",
        "name_with_type": "Xã Tân Cảnh",
        "path": "Tân Cảnh, Đắk Tô, Kon Tum",
        "path_with_type": "Xã Tân Cảnh, Huyện Đắk Tô, Tỉnh Kon Tum",
        "code": "23437",
        "parent_code": "612"
    },
    "23440": {
        "name": "Diên Bình",
        "type": "xa",
        "slug": "dien-binh",
        "name_with_type": "Xã Diên Bình",
        "path": "Diên Bình, Đắk Tô, Kon Tum",
        "path_with_type": "Xã Diên Bình, Huyện Đắk Tô, Tỉnh Kon Tum",
        "code": "23440",
        "parent_code": "612"
    },
    "23443": {
        "name": "Pô Kô",
        "type": "xa",
        "slug": "po-ko",
        "name_with_type": "Xã Pô Kô",
        "path": "Pô Kô, Đắk Tô, Kon Tum",
        "path_with_type": "Xã Pô Kô, Huyện Đắk Tô, Tỉnh Kon Tum",
        "code": "23443",
        "parent_code": "612"
    },
    "23452": {
        "name": "Đắk Nên",
        "type": "xa",
        "slug": "dak-nen",
        "name_with_type": "Xã Đắk Nên",
        "path": "Đắk Nên, Kon Plông, Kon Tum",
        "path_with_type": "Xã Đắk Nên, Huyện Kon Plông, Tỉnh Kon Tum",
        "code": "23452",
        "parent_code": "613"
    },
    "23455": {
        "name": "Đắk Ring",
        "type": "xa",
        "slug": "dak-ring",
        "name_with_type": "Xã Đắk Ring",
        "path": "Đắk Ring, Kon Plông, Kon Tum",
        "path_with_type": "Xã Đắk Ring, Huyện Kon Plông, Tỉnh Kon Tum",
        "code": "23455",
        "parent_code": "613"
    },
    "23458": {
        "name": "Măng Buk",
        "type": "xa",
        "slug": "mang-buk",
        "name_with_type": "Xã Măng Buk",
        "path": "Măng Buk, Kon Plông, Kon Tum",
        "path_with_type": "Xã Măng Buk, Huyện Kon Plông, Tỉnh Kon Tum",
        "code": "23458",
        "parent_code": "613"
    },
    "23461": {
        "name": "Đắk Tăng",
        "type": "xa",
        "slug": "dak-tang",
        "name_with_type": "Xã Đắk Tăng",
        "path": "Đắk Tăng, Kon Plông, Kon Tum",
        "path_with_type": "Xã Đắk Tăng, Huyện Kon Plông, Tỉnh Kon Tum",
        "code": "23461",
        "parent_code": "613"
    },
    "23464": {
        "name": "Ngok Tem",
        "type": "xa",
        "slug": "ngok-tem",
        "name_with_type": "Xã Ngok Tem",
        "path": "Ngok Tem, Kon Plông, Kon Tum",
        "path_with_type": "Xã Ngok Tem, Huyện Kon Plông, Tỉnh Kon Tum",
        "code": "23464",
        "parent_code": "613"
    },
    "23467": {
        "name": "Pờ Ê",
        "type": "xa",
        "slug": "po-e",
        "name_with_type": "Xã Pờ Ê",
        "path": "Pờ Ê, Kon Plông, Kon Tum",
        "path_with_type": "Xã Pờ Ê, Huyện Kon Plông, Tỉnh Kon Tum",
        "code": "23467",
        "parent_code": "613"
    },
    "23470": {
        "name": "Măng Cành",
        "type": "xa",
        "slug": "mang-canh",
        "name_with_type": "Xã Măng Cành",
        "path": "Măng Cành, Kon Plông, Kon Tum",
        "path_with_type": "Xã Măng Cành, Huyện Kon Plông, Tỉnh Kon Tum",
        "code": "23470",
        "parent_code": "613"
    },
    "23473": {
        "name": "Đắk Long",
        "type": "xa",
        "slug": "dak-long",
        "name_with_type": "Xã Đắk Long",
        "path": "Đắk Long, Kon Plông, Kon Tum",
        "path_with_type": "Xã Đắk Long, Huyện Kon Plông, Tỉnh Kon Tum",
        "code": "23473",
        "parent_code": "613"
    },
    "23476": {
        "name": "Hiếu",
        "type": "xa",
        "slug": "hieu",
        "name_with_type": "Xã Hiếu",
        "path": "Hiếu, Kon Plông, Kon Tum",
        "path_with_type": "Xã Hiếu, Huyện Kon Plông, Tỉnh Kon Tum",
        "code": "23476",
        "parent_code": "613"
    },
    "23479": {
        "name": "Đắk Rve",
        "type": "thi-tran",
        "slug": "dak-rve",
        "name_with_type": "Thị trấn Đắk Rve",
        "path": "Đắk Rve, Kon Rẫy, Kon Tum",
        "path_with_type": "Thị trấn Đắk Rve, Huyện Kon Rẫy, Tỉnh Kon Tum",
        "code": "23479",
        "parent_code": "614"
    },
    "23482": {
        "name": "Đắk Kôi",
        "type": "xa",
        "slug": "dak-koi",
        "name_with_type": "Xã Đắk Kôi",
        "path": "Đắk Kôi, Kon Rẫy, Kon Tum",
        "path_with_type": "Xã Đắk Kôi, Huyện Kon Rẫy, Tỉnh Kon Tum",
        "code": "23482",
        "parent_code": "614"
    },
    "23485": {
        "name": "Đắk Tơ Lung",
        "type": "xa",
        "slug": "dak-to-lung",
        "name_with_type": "Xã Đắk Tơ Lung",
        "path": "Đắk Tơ Lung, Kon Rẫy, Kon Tum",
        "path_with_type": "Xã Đắk Tơ Lung, Huyện Kon Rẫy, Tỉnh Kon Tum",
        "code": "23485",
        "parent_code": "614"
    },
    "23488": {
        "name": "Đắk Ruồng",
        "type": "xa",
        "slug": "dak-ruong",
        "name_with_type": "Xã Đắk Ruồng",
        "path": "Đắk Ruồng, Kon Rẫy, Kon Tum",
        "path_with_type": "Xã Đắk Ruồng, Huyện Kon Rẫy, Tỉnh Kon Tum",
        "code": "23488",
        "parent_code": "614"
    },
    "23491": {
        "name": "Đắk Pne",
        "type": "xa",
        "slug": "dak-pne",
        "name_with_type": "Xã Đắk Pne",
        "path": "Đắk Pne, Kon Rẫy, Kon Tum",
        "path_with_type": "Xã Đắk Pne, Huyện Kon Rẫy, Tỉnh Kon Tum",
        "code": "23491",
        "parent_code": "614"
    },
    "23494": {
        "name": "Đắk Tờ Re",
        "type": "xa",
        "slug": "dak-to-re",
        "name_with_type": "Xã Đắk Tờ Re",
        "path": "Đắk Tờ Re, Kon Rẫy, Kon Tum",
        "path_with_type": "Xã Đắk Tờ Re, Huyện Kon Rẫy, Tỉnh Kon Tum",
        "code": "23494",
        "parent_code": "614"
    },
    "23497": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Kon Rẫy, Kon Tum",
        "path_with_type": "Xã Tân Lập, Huyện Kon Rẫy, Tỉnh Kon Tum",
        "code": "23497",
        "parent_code": "614"
    },
    "23500": {
        "name": "Đắk Hà",
        "type": "thi-tran",
        "slug": "dak-ha",
        "name_with_type": "Thị trấn Đắk Hà",
        "path": "Đắk Hà, Đắk Hà, Kon Tum",
        "path_with_type": "Thị trấn Đắk Hà, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23500",
        "parent_code": "615"
    },
    "23503": {
        "name": "Đắk PXi",
        "type": "xa",
        "slug": "dak-pxi",
        "name_with_type": "Xã Đắk PXi",
        "path": "Đắk PXi, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Đắk PXi, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23503",
        "parent_code": "615"
    },
    "23504": {
        "name": "Đăk Long",
        "type": "xa",
        "slug": "dak-long",
        "name_with_type": "Xã Đăk Long",
        "path": "Đăk Long, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Đăk Long, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23504",
        "parent_code": "615"
    },
    "23506": {
        "name": "Đắk HRing",
        "type": "xa",
        "slug": "dak-hring",
        "name_with_type": "Xã Đắk HRing",
        "path": "Đắk HRing, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Đắk HRing, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23506",
        "parent_code": "615"
    },
    "23509": {
        "name": "Đắk Ui",
        "type": "xa",
        "slug": "dak-ui",
        "name_with_type": "Xã Đắk Ui",
        "path": "Đắk Ui, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Đắk Ui, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23509",
        "parent_code": "615"
    },
    "23510": {
        "name": "Đăk Ngọk",
        "type": "xa",
        "slug": "dak-ngok",
        "name_with_type": "Xã Đăk Ngọk",
        "path": "Đăk Ngọk, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Đăk Ngọk, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23510",
        "parent_code": "615"
    },
    "23512": {
        "name": "Đắk Mar",
        "type": "xa",
        "slug": "dak-mar",
        "name_with_type": "Xã Đắk Mar",
        "path": "Đắk Mar, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Đắk Mar, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23512",
        "parent_code": "615"
    },
    "23515": {
        "name": "Ngok Wang",
        "type": "xa",
        "slug": "ngok-wang",
        "name_with_type": "Xã Ngok Wang",
        "path": "Ngok Wang, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Ngok Wang, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23515",
        "parent_code": "615"
    },
    "23518": {
        "name": "Ngok Réo",
        "type": "xa",
        "slug": "ngok-reo",
        "name_with_type": "Xã Ngok Réo",
        "path": "Ngok Réo, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Ngok Réo, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23518",
        "parent_code": "615"
    },
    "23521": {
        "name": "Hà Mòn",
        "type": "xa",
        "slug": "ha-mon",
        "name_with_type": "Xã Hà Mòn",
        "path": "Hà Mòn, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Hà Mòn, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23521",
        "parent_code": "615"
    },
    "23524": {
        "name": "Đắk La",
        "type": "xa",
        "slug": "dak-la",
        "name_with_type": "Xã Đắk La",
        "path": "Đắk La, Đắk Hà, Kon Tum",
        "path_with_type": "Xã Đắk La, Huyện Đắk Hà, Tỉnh Kon Tum",
        "code": "23524",
        "parent_code": "615"
    },
    "23527": {
        "name": "Sa Thầy",
        "type": "thi-tran",
        "slug": "sa-thay",
        "name_with_type": "Thị trấn Sa Thầy",
        "path": "Sa Thầy, Sa Thầy, Kon Tum",
        "path_with_type": "Thị trấn Sa Thầy, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23527",
        "parent_code": "616"
    },
    "23530": {
        "name": "Rơ Kơi",
        "type": "xa",
        "slug": "ro-koi",
        "name_with_type": "Xã Rơ Kơi",
        "path": "Rơ Kơi, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Rơ Kơi, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23530",
        "parent_code": "616"
    },
    "23533": {
        "name": "Sa Nhơn",
        "type": "xa",
        "slug": "sa-nhon",
        "name_with_type": "Xã Sa Nhơn",
        "path": "Sa Nhơn, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Sa Nhơn, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23533",
        "parent_code": "616"
    },
    "23534": {
        "name": "Hơ Moong",
        "type": "xa",
        "slug": "ho-moong",
        "name_with_type": "Xã Hơ Moong",
        "path": "Hơ Moong, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Hơ Moong, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23534",
        "parent_code": "616"
    },
    "23536": {
        "name": "Mô Rai",
        "type": "xa",
        "slug": "mo-rai",
        "name_with_type": "Xã Mô Rai",
        "path": "Mô Rai, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Mô Rai, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23536",
        "parent_code": "616"
    },
    "23539": {
        "name": "Sa Sơn",
        "type": "xa",
        "slug": "sa-son",
        "name_with_type": "Xã Sa Sơn",
        "path": "Sa Sơn, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Sa Sơn, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23539",
        "parent_code": "616"
    },
    "23542": {
        "name": "Sa Nghĩa",
        "type": "xa",
        "slug": "sa-nghia",
        "name_with_type": "Xã Sa Nghĩa",
        "path": "Sa Nghĩa, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Sa Nghĩa, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23542",
        "parent_code": "616"
    },
    "23545": {
        "name": "Sa Bình",
        "type": "xa",
        "slug": "sa-binh",
        "name_with_type": "Xã Sa Bình",
        "path": "Sa Bình, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Sa Bình, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23545",
        "parent_code": "616"
    },
    "23548": {
        "name": "Ya Xiêr",
        "type": "xa",
        "slug": "ya-xier",
        "name_with_type": "Xã Ya Xiêr",
        "path": "Ya Xiêr, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Ya Xiêr, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23548",
        "parent_code": "616"
    },
    "23551": {
        "name": "Ya Tăng",
        "type": "xa",
        "slug": "ya-tang",
        "name_with_type": "Xã Ya Tăng",
        "path": "Ya Tăng, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Ya Tăng, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23551",
        "parent_code": "616"
    },
    "23554": {
        "name": "Ya ly",
        "type": "xa",
        "slug": "ya-ly",
        "name_with_type": "Xã Ya ly",
        "path": "Ya ly, Sa Thầy, Kon Tum",
        "path_with_type": "Xã Ya ly, Huyện Sa Thầy, Tỉnh Kon Tum",
        "code": "23554",
        "parent_code": "616"
    },
    "23404": {
        "name": "Ngọk Lây",
        "type": "xa",
        "slug": "ngok-lay",
        "name_with_type": "Xã Ngọk Lây",
        "path": "Ngọk Lây, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Ngọk Lây, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23404",
        "parent_code": "617"
    },
    "23407": {
        "name": "Đắk Na",
        "type": "xa",
        "slug": "dak-na",
        "name_with_type": "Xã Đắk Na",
        "path": "Đắk Na, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Đắk Na, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23407",
        "parent_code": "617"
    },
    "23410": {
        "name": "Măng Ri",
        "type": "xa",
        "slug": "mang-ri",
        "name_with_type": "Xã Măng Ri",
        "path": "Măng Ri, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Măng Ri, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23410",
        "parent_code": "617"
    },
    "23413": {
        "name": "Ngok Yêu",
        "type": "xa",
        "slug": "ngok-yeu",
        "name_with_type": "Xã Ngok Yêu",
        "path": "Ngok Yêu, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Ngok Yêu, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23413",
        "parent_code": "617"
    },
    "23416": {
        "name": "Đắk Sao",
        "type": "xa",
        "slug": "dak-sao",
        "name_with_type": "Xã Đắk Sao",
        "path": "Đắk Sao, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Đắk Sao, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23416",
        "parent_code": "617"
    },
    "23417": {
        "name": "Đắk Rơ Ông",
        "type": "xa",
        "slug": "dak-ro-ong",
        "name_with_type": "Xã Đắk Rơ Ông",
        "path": "Đắk Rơ Ông, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Đắk Rơ Ông, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23417",
        "parent_code": "617"
    },
    "23419": {
        "name": "Đắk Tơ Kan",
        "type": "xa",
        "slug": "dak-to-kan",
        "name_with_type": "Xã Đắk Tơ Kan",
        "path": "Đắk Tơ Kan, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Đắk Tơ Kan, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23419",
        "parent_code": "617"
    },
    "23422": {
        "name": "Tu Mơ Rông",
        "type": "xa",
        "slug": "tu-mo-rong",
        "name_with_type": "Xã Tu Mơ Rông",
        "path": "Tu Mơ Rông, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Tu Mơ Rông, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23422",
        "parent_code": "617"
    },
    "23425": {
        "name": "Đắk Hà",
        "type": "xa",
        "slug": "dak-ha",
        "name_with_type": "Xã Đắk Hà",
        "path": "Đắk Hà, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Đắk Hà, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23425",
        "parent_code": "617"
    },
    "23446": {
        "name": "Tê Xăng",
        "type": "xa",
        "slug": "te-xang",
        "name_with_type": "Xã Tê Xăng",
        "path": "Tê Xăng, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Tê Xăng, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23446",
        "parent_code": "617"
    },
    "23449": {
        "name": "Văn Xuôi",
        "type": "xa",
        "slug": "van-xuoi",
        "name_with_type": "Xã Văn Xuôi",
        "path": "Văn Xuôi, Tu Mơ Rông, Kon Tum",
        "path_with_type": "Xã Văn Xuôi, Huyện Tu Mơ Rông, Tỉnh Kon Tum",
        "code": "23449",
        "parent_code": "617"
    },
    "23535": {
        "name": "Ia Dal",
        "type": "xa",
        "slug": "ia-dal",
        "name_with_type": "Xã Ia Dal",
        "path": "Ia Dal, Ia H' Drai, Kon Tum",
        "path_with_type": "Xã Ia Dal, Huyện Ia H' Drai, Tỉnh Kon Tum",
        "code": "23535",
        "parent_code": "618"
    },
    "23537": {
        "name": "Ia Dom",
        "type": "xa",
        "slug": "ia-dom",
        "name_with_type": "Xã Ia Dom",
        "path": "Ia Dom, Ia H' Drai, Kon Tum",
        "path_with_type": "Xã Ia Dom, Huyện Ia H' Drai, Tỉnh Kon Tum",
        "code": "23537",
        "parent_code": "618"
    },
    "23538": {
        "name": "Ia Tơi",
        "type": "xa",
        "slug": "ia-toi",
        "name_with_type": "Xã Ia Tơi",
        "path": "Ia Tơi, Ia H' Drai, Kon Tum",
        "path_with_type": "Xã Ia Tơi, Huyện Ia H' Drai, Tỉnh Kon Tum",
        "code": "23538",
        "parent_code": "618"
    },
    "24611": {
        "name": "Nghĩa Đức",
        "type": "phuong",
        "slug": "nghia-duc",
        "name_with_type": "Phường Nghĩa Đức",
        "path": "Nghĩa Đức, Gia Nghĩa, Đắk Nông",
        "path_with_type": "Phường Nghĩa Đức, Thị xã Gia Nghĩa, Tỉnh Đắk Nông",
        "code": "24611",
        "parent_code": "660"
    },
    "24612": {
        "name": "Nghĩa Thành",
        "type": "phuong",
        "slug": "nghia-thanh",
        "name_with_type": "Phường Nghĩa Thành",
        "path": "Nghĩa Thành, Gia Nghĩa, Đắk Nông",
        "path_with_type": "Phường Nghĩa Thành, Thị xã Gia Nghĩa, Tỉnh Đắk Nông",
        "code": "24612",
        "parent_code": "660"
    },
    "24614": {
        "name": "Nghĩa Phú",
        "type": "phuong",
        "slug": "nghia-phu",
        "name_with_type": "Phường Nghĩa Phú",
        "path": "Nghĩa Phú, Gia Nghĩa, Đắk Nông",
        "path_with_type": "Phường Nghĩa Phú, Thị xã Gia Nghĩa, Tỉnh Đắk Nông",
        "code": "24614",
        "parent_code": "660"
    },
    "24615": {
        "name": "Nghĩa Tân",
        "type": "phuong",
        "slug": "nghia-tan",
        "name_with_type": "Phường Nghĩa Tân",
        "path": "Nghĩa Tân, Gia Nghĩa, Đắk Nông",
        "path_with_type": "Phường Nghĩa Tân, Thị xã Gia Nghĩa, Tỉnh Đắk Nông",
        "code": "24615",
        "parent_code": "660"
    },
    "24617": {
        "name": "Nghĩa Trung",
        "type": "phuong",
        "slug": "nghia-trung",
        "name_with_type": "Phường Nghĩa Trung",
        "path": "Nghĩa Trung, Gia Nghĩa, Đắk Nông",
        "path_with_type": "Phường Nghĩa Trung, Thị xã Gia Nghĩa, Tỉnh Đắk Nông",
        "code": "24617",
        "parent_code": "660"
    },
    "24618": {
        "name": "Đăk R'Moan",
        "type": "xa",
        "slug": "dak-r-moan",
        "name_with_type": "Xã Đăk R'Moan",
        "path": "Đăk R'Moan, Gia Nghĩa, Đắk Nông",
        "path_with_type": "Xã Đăk R'Moan, Thị xã Gia Nghĩa, Tỉnh Đắk Nông",
        "code": "24618",
        "parent_code": "660"
    },
    "24619": {
        "name": "Quảng Thành",
        "type": "xa",
        "slug": "quang-thanh",
        "name_with_type": "Xã Quảng Thành",
        "path": "Quảng Thành, Gia Nghĩa, Đắk Nông",
        "path_with_type": "Xã Quảng Thành, Thị xã Gia Nghĩa, Tỉnh Đắk Nông",
        "code": "24619",
        "parent_code": "660"
    },
    "24628": {
        "name": "Đắk Nia",
        "type": "xa",
        "slug": "dak-nia",
        "name_with_type": "Xã Đắk Nia",
        "path": "Đắk Nia, Gia Nghĩa, Đắk Nông",
        "path_with_type": "Xã Đắk Nia, Thị xã Gia Nghĩa, Tỉnh Đắk Nông",
        "code": "24628",
        "parent_code": "660"
    },
    "24616": {
        "name": "Quảng Sơn",
        "type": "xa",
        "slug": "quang-son",
        "name_with_type": "Xã Quảng Sơn",
        "path": "Quảng Sơn, Đăk Glong, Đắk Nông",
        "path_with_type": "Xã Quảng Sơn, Huyện Đăk Glong, Tỉnh Đắk Nông",
        "code": "24616",
        "parent_code": "661"
    },
    "24620": {
        "name": "Quảng Hoà",
        "type": "xa",
        "slug": "quang-hoa",
        "name_with_type": "Xã Quảng Hoà",
        "path": "Quảng Hoà, Đăk Glong, Đắk Nông",
        "path_with_type": "Xã Quảng Hoà, Huyện Đăk Glong, Tỉnh Đắk Nông",
        "code": "24620",
        "parent_code": "661"
    },
    "24622": {
        "name": "Đắk Ha",
        "type": "xa",
        "slug": "dak-ha",
        "name_with_type": "Xã Đắk Ha",
        "path": "Đắk Ha, Đăk Glong, Đắk Nông",
        "path_with_type": "Xã Đắk Ha, Huyện Đăk Glong, Tỉnh Đắk Nông",
        "code": "24622",
        "parent_code": "661"
    },
    "24625": {
        "name": "Đắk R'Măng",
        "type": "xa",
        "slug": "dak-r-mang",
        "name_with_type": "Xã Đắk R'Măng",
        "path": "Đắk R'Măng, Đăk Glong, Đắk Nông",
        "path_with_type": "Xã Đắk R'Măng, Huyện Đăk Glong, Tỉnh Đắk Nông",
        "code": "24625",
        "parent_code": "661"
    },
    "24631": {
        "name": "Quảng Khê",
        "type": "xa",
        "slug": "quang-khe",
        "name_with_type": "Xã Quảng Khê",
        "path": "Quảng Khê, Đăk Glong, Đắk Nông",
        "path_with_type": "Xã Quảng Khê, Huyện Đăk Glong, Tỉnh Đắk Nông",
        "code": "24631",
        "parent_code": "661"
    },
    "24634": {
        "name": "Đắk Plao",
        "type": "xa",
        "slug": "dak-plao",
        "name_with_type": "Xã Đắk Plao",
        "path": "Đắk Plao, Đăk Glong, Đắk Nông",
        "path_with_type": "Xã Đắk Plao, Huyện Đăk Glong, Tỉnh Đắk Nông",
        "code": "24634",
        "parent_code": "661"
    },
    "24637": {
        "name": "Đắk Som",
        "type": "xa",
        "slug": "dak-som",
        "name_with_type": "Xã Đắk Som",
        "path": "Đắk Som, Đăk Glong, Đắk Nông",
        "path_with_type": "Xã Đắk Som, Huyện Đăk Glong, Tỉnh Đắk Nông",
        "code": "24637",
        "parent_code": "661"
    },
    "24640": {
        "name": "Ea T'Ling",
        "type": "thi-tran",
        "slug": "ea-t-ling",
        "name_with_type": "Thị trấn Ea T'Ling",
        "path": "Ea T'Ling, Cư Jút, Đắk Nông",
        "path_with_type": "Thị trấn Ea T'Ling, Huyện Cư Jút, Tỉnh Đắk Nông",
        "code": "24640",
        "parent_code": "662"
    },
    "24643": {
        "name": "Đắk Wil",
        "type": "xa",
        "slug": "dak-wil",
        "name_with_type": "Xã Đắk Wil",
        "path": "Đắk Wil, Cư Jút, Đắk Nông",
        "path_with_type": "Xã Đắk Wil, Huyện Cư Jút, Tỉnh Đắk Nông",
        "code": "24643",
        "parent_code": "662"
    },
    "24646": {
        "name": "Ea Pô",
        "type": "xa",
        "slug": "ea-po",
        "name_with_type": "Xã Ea Pô",
        "path": "Ea Pô, Cư Jút, Đắk Nông",
        "path_with_type": "Xã Ea Pô, Huyện Cư Jút, Tỉnh Đắk Nông",
        "code": "24646",
        "parent_code": "662"
    },
    "24649": {
        "name": "Nam Dong",
        "type": "xa",
        "slug": "nam-dong",
        "name_with_type": "Xã Nam Dong",
        "path": "Nam Dong, Cư Jút, Đắk Nông",
        "path_with_type": "Xã Nam Dong, Huyện Cư Jút, Tỉnh Đắk Nông",
        "code": "24649",
        "parent_code": "662"
    },
    "24652": {
        "name": "Đắk DRông",
        "type": "xa",
        "slug": "dak-drong",
        "name_with_type": "Xã Đắk DRông",
        "path": "Đắk DRông, Cư Jút, Đắk Nông",
        "path_with_type": "Xã Đắk DRông, Huyện Cư Jút, Tỉnh Đắk Nông",
        "code": "24652",
        "parent_code": "662"
    },
    "24655": {
        "name": "Tâm Thắng",
        "type": "xa",
        "slug": "tam-thang",
        "name_with_type": "Xã Tâm Thắng",
        "path": "Tâm Thắng, Cư Jút, Đắk Nông",
        "path_with_type": "Xã Tâm Thắng, Huyện Cư Jút, Tỉnh Đắk Nông",
        "code": "24655",
        "parent_code": "662"
    },
    "24658": {
        "name": "Cư Knia",
        "type": "xa",
        "slug": "cu-knia",
        "name_with_type": "Xã Cư Knia",
        "path": "Cư Knia, Cư Jút, Đắk Nông",
        "path_with_type": "Xã Cư Knia, Huyện Cư Jút, Tỉnh Đắk Nông",
        "code": "24658",
        "parent_code": "662"
    },
    "24661": {
        "name": "Trúc Sơn",
        "type": "xa",
        "slug": "truc-son",
        "name_with_type": "Xã Trúc Sơn",
        "path": "Trúc Sơn, Cư Jút, Đắk Nông",
        "path_with_type": "Xã Trúc Sơn, Huyện Cư Jút, Tỉnh Đắk Nông",
        "code": "24661",
        "parent_code": "662"
    },
    "24664": {
        "name": "Đắk Mil",
        "type": "thi-tran",
        "slug": "dak-mil",
        "name_with_type": "Thị trấn Đắk Mil",
        "path": "Đắk Mil, Đắk Mil, Đắk Nông",
        "path_with_type": "Thị trấn Đắk Mil, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24664",
        "parent_code": "663"
    },
    "24667": {
        "name": "Đắk Lao",
        "type": "xa",
        "slug": "dak-lao",
        "name_with_type": "Xã  Đắk Lao",
        "path": "Đắk Lao, Đắk Mil, Đắk Nông",
        "path_with_type": "Xã  Đắk Lao, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24667",
        "parent_code": "663"
    },
    "24670": {
        "name": "Đắk R'La",
        "type": "xa",
        "slug": "dak-r-la",
        "name_with_type": "Xã Đắk R'La",
        "path": "Đắk R'La, Đắk Mil, Đắk Nông",
        "path_with_type": "Xã Đắk R'La, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24670",
        "parent_code": "663"
    },
    "24673": {
        "name": "Đắk Gằn",
        "type": "xa",
        "slug": "dak-gan",
        "name_with_type": "Xã Đắk Gằn",
        "path": "Đắk Gằn, Đắk Mil, Đắk Nông",
        "path_with_type": "Xã Đắk Gằn, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24673",
        "parent_code": "663"
    },
    "24676": {
        "name": "Đức Mạnh",
        "type": "xa",
        "slug": "duc-manh",
        "name_with_type": "Xã Đức Mạnh",
        "path": "Đức Mạnh, Đắk Mil, Đắk Nông",
        "path_with_type": "Xã Đức Mạnh, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24676",
        "parent_code": "663"
    },
    "24677": {
        "name": "Đắk N'Drót",
        "type": "xa",
        "slug": "dak-n-drot",
        "name_with_type": "Xã Đắk N'Drót",
        "path": "Đắk N'Drót, Đắk Mil, Đắk Nông",
        "path_with_type": "Xã Đắk N'Drót, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24677",
        "parent_code": "663"
    },
    "24678": {
        "name": "Long Sơn",
        "type": "xa",
        "slug": "long-son",
        "name_with_type": "Xã Long Sơn",
        "path": "Long Sơn, Đắk Mil, Đắk Nông",
        "path_with_type": "Xã Long Sơn, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24678",
        "parent_code": "663"
    },
    "24679": {
        "name": "Đắk Sắk",
        "type": "xa",
        "slug": "dak-sak",
        "name_with_type": "Xã Đắk Sắk",
        "path": "Đắk Sắk, Đắk Mil, Đắk Nông",
        "path_with_type": "Xã Đắk Sắk, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24679",
        "parent_code": "663"
    },
    "24682": {
        "name": "Thuận An",
        "type": "xa",
        "slug": "thuan-an",
        "name_with_type": "Xã Thuận An",
        "path": "Thuận An, Đắk Mil, Đắk Nông",
        "path_with_type": "Xã Thuận An, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24682",
        "parent_code": "663"
    },
    "24685": {
        "name": "Đức Minh",
        "type": "xa",
        "slug": "duc-minh",
        "name_with_type": "Xã Đức Minh",
        "path": "Đức Minh, Đắk Mil, Đắk Nông",
        "path_with_type": "Xã Đức Minh, Huyện Đắk Mil, Tỉnh Đắk Nông",
        "code": "24685",
        "parent_code": "663"
    },
    "24688": {
        "name": "Đắk Mâm",
        "type": "thi-tran",
        "slug": "dak-mam",
        "name_with_type": "Thị trấn Đắk Mâm",
        "path": "Đắk Mâm, Krông Nô, Đắk Nông",
        "path_with_type": "Thị trấn Đắk Mâm, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24688",
        "parent_code": "664"
    },
    "24691": {
        "name": "Đắk Sôr",
        "type": "xa",
        "slug": "dak-sor",
        "name_with_type": "Xã Đắk Sôr",
        "path": "Đắk Sôr, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Đắk Sôr, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24691",
        "parent_code": "664"
    },
    "24692": {
        "name": "Nam Xuân",
        "type": "xa",
        "slug": "nam-xuan",
        "name_with_type": "Xã Nam Xuân",
        "path": "Nam Xuân, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Nam Xuân, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24692",
        "parent_code": "664"
    },
    "24694": {
        "name": "Buôn Choah",
        "type": "xa",
        "slug": "buon-choah",
        "name_with_type": "Xã Buôn Choah",
        "path": "Buôn Choah, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Buôn Choah, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24694",
        "parent_code": "664"
    },
    "24697": {
        "name": "Nam Đà",
        "type": "xa",
        "slug": "nam-da",
        "name_with_type": "Xã Nam Đà",
        "path": "Nam Đà, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Nam Đà, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24697",
        "parent_code": "664"
    },
    "24699": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Tân Thành, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24699",
        "parent_code": "664"
    },
    "24700": {
        "name": "Đắk Drô",
        "type": "xa",
        "slug": "dak-dro",
        "name_with_type": "Xã Đắk Drô",
        "path": "Đắk Drô, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Đắk Drô, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24700",
        "parent_code": "664"
    },
    "24703": {
        "name": "Nâm Nung",
        "type": "xa",
        "slug": "nam-nung",
        "name_with_type": "Xã Nâm Nung",
        "path": "Nâm Nung, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Nâm Nung, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24703",
        "parent_code": "664"
    },
    "24706": {
        "name": "Đức Xuyên",
        "type": "xa",
        "slug": "duc-xuyen",
        "name_with_type": "Xã Đức Xuyên",
        "path": "Đức Xuyên, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Đức Xuyên, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24706",
        "parent_code": "664"
    },
    "24709": {
        "name": "Đắk Nang",
        "type": "xa",
        "slug": "dak-nang",
        "name_with_type": "Xã Đắk Nang",
        "path": "Đắk Nang, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Đắk Nang, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24709",
        "parent_code": "664"
    },
    "24712": {
        "name": "Quảng Phú",
        "type": "xa",
        "slug": "quang-phu",
        "name_with_type": "Xã Quảng Phú",
        "path": "Quảng Phú, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Quảng Phú, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24712",
        "parent_code": "664"
    },
    "24715": {
        "name": "Nâm N'Đir",
        "type": "xa",
        "slug": "nam-n-dir",
        "name_with_type": "Xã Nâm N'Đir",
        "path": "Nâm N'Đir, Krông Nô, Đắk Nông",
        "path_with_type": "Xã Nâm N'Đir, Huyện Krông Nô, Tỉnh Đắk Nông",
        "code": "24715",
        "parent_code": "664"
    },
    "24717": {
        "name": "Đức An",
        "type": "thi-tran",
        "slug": "duc-an",
        "name_with_type": "Thị trấn Đức An",
        "path": "Đức An, Đắk Song, Đắk Nông",
        "path_with_type": "Thị trấn Đức An, Huyện Đắk Song, Tỉnh Đắk Nông",
        "code": "24717",
        "parent_code": "665"
    },
    "24718": {
        "name": "Đắk Môl",
        "type": "xa",
        "slug": "dak-mol",
        "name_with_type": "Xã Đắk Môl",
        "path": "Đắk Môl, Đắk Song, Đắk Nông",
        "path_with_type": "Xã Đắk Môl, Huyện Đắk Song, Tỉnh Đắk Nông",
        "code": "24718",
        "parent_code": "665"
    },
    "24719": {
        "name": "Đắk Hòa",
        "type": "xa",
        "slug": "dak-hoa",
        "name_with_type": "Xã Đắk Hòa",
        "path": "Đắk Hòa, Đắk Song, Đắk Nông",
        "path_with_type": "Xã Đắk Hòa, Huyện Đắk Song, Tỉnh Đắk Nông",
        "code": "24719",
        "parent_code": "665"
    },
    "24721": {
        "name": "Nam Bình",
        "type": "xa",
        "slug": "nam-binh",
        "name_with_type": "Xã Nam Bình",
        "path": "Nam Bình, Đắk Song, Đắk Nông",
        "path_with_type": "Xã Nam Bình, Huyện Đắk Song, Tỉnh Đắk Nông",
        "code": "24721",
        "parent_code": "665"
    },
    "24722": {
        "name": "Thuận Hà",
        "type": "xa",
        "slug": "thuan-ha",
        "name_with_type": "Xã Thuận Hà",
        "path": "Thuận Hà, Đắk Song, Đắk Nông",
        "path_with_type": "Xã Thuận Hà, Huyện Đắk Song, Tỉnh Đắk Nông",
        "code": "24722",
        "parent_code": "665"
    },
    "24724": {
        "name": "Thuận Hạnh",
        "type": "xa",
        "slug": "thuan-hanh",
        "name_with_type": "Xã Thuận Hạnh",
        "path": "Thuận Hạnh, Đắk Song, Đắk Nông",
        "path_with_type": "Xã Thuận Hạnh, Huyện Đắk Song, Tỉnh Đắk Nông",
        "code": "24724",
        "parent_code": "665"
    },
    "24727": {
        "name": "Đắk N'Dung",
        "type": "xa",
        "slug": "dak-n-dung",
        "name_with_type": "Xã Đắk N'Dung",
        "path": "Đắk N'Dung, Đắk Song, Đắk Nông",
        "path_with_type": "Xã Đắk N'Dung, Huyện Đắk Song, Tỉnh Đắk Nông",
        "code": "24727",
        "parent_code": "665"
    },
    "24728": {
        "name": "Nâm N'Jang",
        "type": "xa",
        "slug": "nam-n-jang",
        "name_with_type": "Xã Nâm N'Jang",
        "path": "Nâm N'Jang, Đắk Song, Đắk Nông",
        "path_with_type": "Xã Nâm N'Jang, Huyện Đắk Song, Tỉnh Đắk Nông",
        "code": "24728",
        "parent_code": "665"
    },
    "24730": {
        "name": "Trường Xuân",
        "type": "xa",
        "slug": "truong-xuan",
        "name_with_type": "Xã Trường Xuân",
        "path": "Trường Xuân, Đắk Song, Đắk Nông",
        "path_with_type": "Xã Trường Xuân, Huyện Đắk Song, Tỉnh Đắk Nông",
        "code": "24730",
        "parent_code": "665"
    },
    "24733": {
        "name": "Kiến Đức",
        "type": "thi-tran",
        "slug": "kien-duc",
        "name_with_type": "Thị trấn Kiến Đức",
        "path": "Kiến Đức, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Thị trấn Kiến Đức, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24733",
        "parent_code": "666"
    },
    "24745": {
        "name": "Quảng Tín",
        "type": "xa",
        "slug": "quang-tin",
        "name_with_type": "Xã Quảng Tín",
        "path": "Quảng Tín, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Quảng Tín, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24745",
        "parent_code": "666"
    },
    "24750": {
        "name": "Đắk Wer",
        "type": "xa",
        "slug": "dak-wer",
        "name_with_type": "Xã Đắk Wer",
        "path": "Đắk Wer, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Đắk Wer, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24750",
        "parent_code": "666"
    },
    "24751": {
        "name": "Nhân Cơ",
        "type": "xa",
        "slug": "nhan-co",
        "name_with_type": "Xã Nhân Cơ",
        "path": "Nhân Cơ, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Nhân Cơ, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24751",
        "parent_code": "666"
    },
    "24754": {
        "name": "Kiến Thành",
        "type": "xa",
        "slug": "kien-thanh",
        "name_with_type": "Xã Kiến Thành",
        "path": "Kiến Thành, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Kiến Thành, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24754",
        "parent_code": "666"
    },
    "24756": {
        "name": "Nghĩa Thắng",
        "type": "xa",
        "slug": "nghia-thang",
        "name_with_type": "Xã Nghĩa Thắng",
        "path": "Nghĩa Thắng, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Nghĩa Thắng, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24756",
        "parent_code": "666"
    },
    "24757": {
        "name": "Đạo Nghĩa",
        "type": "xa",
        "slug": "dao-nghia",
        "name_with_type": "Xã Đạo Nghĩa",
        "path": "Đạo Nghĩa, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Đạo Nghĩa, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24757",
        "parent_code": "666"
    },
    "24760": {
        "name": "Đắk Sin",
        "type": "xa",
        "slug": "dak-sin",
        "name_with_type": "Xã Đắk Sin",
        "path": "Đắk Sin, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Đắk Sin, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24760",
        "parent_code": "666"
    },
    "24761": {
        "name": "Hưng Bình",
        "type": "xa",
        "slug": "hung-binh",
        "name_with_type": "Xã Hưng Bình",
        "path": "Hưng Bình, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Hưng Bình, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24761",
        "parent_code": "666"
    },
    "24763": {
        "name": "Đắk Ru",
        "type": "xa",
        "slug": "dak-ru",
        "name_with_type": "Xã Đắk Ru",
        "path": "Đắk Ru, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Đắk Ru, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24763",
        "parent_code": "666"
    },
    "24766": {
        "name": "Nhân Đạo",
        "type": "xa",
        "slug": "nhan-dao",
        "name_with_type": "Xã Nhân Đạo",
        "path": "Nhân Đạo, Đắk R'Lấp, Đắk Nông",
        "path_with_type": "Xã Nhân Đạo, Huyện Đắk R'Lấp, Tỉnh Đắk Nông",
        "code": "24766",
        "parent_code": "666"
    },
    "24736": {
        "name": "Quảng Trực",
        "type": "xa",
        "slug": "quang-truc",
        "name_with_type": "Xã Quảng Trực",
        "path": "Quảng Trực, Tuy Đức, Đắk Nông",
        "path_with_type": "Xã Quảng Trực, Huyện Tuy Đức, Tỉnh Đắk Nông",
        "code": "24736",
        "parent_code": "667"
    },
    "24739": {
        "name": "Đắk Búk So",
        "type": "xa",
        "slug": "dak-buk-so",
        "name_with_type": "Xã Đắk Búk So",
        "path": "Đắk Búk So, Tuy Đức, Đắk Nông",
        "path_with_type": "Xã Đắk Búk So, Huyện Tuy Đức, Tỉnh Đắk Nông",
        "code": "24739",
        "parent_code": "667"
    },
    "24740": {
        "name": "Quảng Tâm",
        "type": "xa",
        "slug": "quang-tam",
        "name_with_type": "Xã Quảng Tâm",
        "path": "Quảng Tâm, Tuy Đức, Đắk Nông",
        "path_with_type": "Xã Quảng Tâm, Huyện Tuy Đức, Tỉnh Đắk Nông",
        "code": "24740",
        "parent_code": "667"
    },
    "24742": {
        "name": "Đắk R'Tíh",
        "type": "xa",
        "slug": "dak-r-tih",
        "name_with_type": "Xã Đắk R'Tíh",
        "path": "Đắk R'Tíh, Tuy Đức, Đắk Nông",
        "path_with_type": "Xã Đắk R'Tíh, Huyện Tuy Đức, Tỉnh Đắk Nông",
        "code": "24742",
        "parent_code": "667"
    },
    "24746": {
        "name": "Đắk Ngo",
        "type": "xa",
        "slug": "dak-ngo",
        "name_with_type": "Xã Đắk Ngo",
        "path": "Đắk Ngo, Tuy Đức, Đắk Nông",
        "path_with_type": "Xã Đắk Ngo, Huyện Tuy Đức, Tỉnh Đắk Nông",
        "code": "24746",
        "parent_code": "667"
    },
    "24748": {
        "name": "Quảng Tân",
        "type": "xa",
        "slug": "quang-tan",
        "name_with_type": "Xã Quảng Tân",
        "path": "Quảng Tân, Tuy Đức, Đắk Nông",
        "path_with_type": "Xã Quảng Tân, Huyện Tuy Đức, Tỉnh Đắk Nông",
        "code": "24748",
        "parent_code": "667"
    },
    "31498": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 5, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31498",
        "parent_code": "941"
    },
    "31501": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 7, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31501",
        "parent_code": "941"
    },
    "31504": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 8, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31504",
        "parent_code": "941"
    },
    "31507": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 6, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31507",
        "parent_code": "941"
    },
    "31510": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 2, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31510",
        "parent_code": "941"
    },
    "31513": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 1, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31513",
        "parent_code": "941"
    },
    "31516": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 4, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31516",
        "parent_code": "941"
    },
    "31519": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 3, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31519",
        "parent_code": "941"
    },
    "31522": {
        "name": "9",
        "type": "phuong",
        "slug": "9",
        "name_with_type": "Phường 9",
        "path": "9, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 9, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31522",
        "parent_code": "941"
    },
    "31525": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, Sóc Trăng, Sóc Trăng",
        "path_with_type": "Phường 10, Thành phố Sóc Trăng, Tỉnh Sóc Trăng",
        "code": "31525",
        "parent_code": "941"
    },
    "31569": {
        "name": "Châu Thành",
        "type": "thi-tran",
        "slug": "chau-thanh",
        "name_with_type": "Thị trấn Châu Thành",
        "path": "Châu Thành, Châu Thành, Sóc Trăng",
        "path_with_type": "Thị trấn Châu Thành, Huyện Châu Thành, Tỉnh Sóc Trăng",
        "code": "31569",
        "parent_code": "942"
    },
    "31570": {
        "name": "Hồ Đắc Kiện",
        "type": "xa",
        "slug": "ho-dac-kien",
        "name_with_type": "Xã Hồ Đắc Kiện",
        "path": "Hồ Đắc Kiện, Châu Thành, Sóc Trăng",
        "path_with_type": "Xã Hồ Đắc Kiện, Huyện Châu Thành, Tỉnh Sóc Trăng",
        "code": "31570",
        "parent_code": "942"
    },
    "31573": {
        "name": "Phú Tâm",
        "type": "xa",
        "slug": "phu-tam",
        "name_with_type": "Xã Phú Tâm",
        "path": "Phú Tâm, Châu Thành, Sóc Trăng",
        "path_with_type": "Xã Phú Tâm, Huyện Châu Thành, Tỉnh Sóc Trăng",
        "code": "31573",
        "parent_code": "942"
    },
    "31576": {
        "name": "Thuận Hòa",
        "type": "xa",
        "slug": "thuan-hoa",
        "name_with_type": "Xã Thuận Hòa",
        "path": "Thuận Hòa, Châu Thành, Sóc Trăng",
        "path_with_type": "Xã Thuận Hòa, Huyện Châu Thành, Tỉnh Sóc Trăng",
        "code": "31576",
        "parent_code": "942"
    },
    "31582": {
        "name": "Phú Tân",
        "type": "xa",
        "slug": "phu-tan",
        "name_with_type": "Xã Phú Tân",
        "path": "Phú Tân, Châu Thành, Sóc Trăng",
        "path_with_type": "Xã Phú Tân, Huyện Châu Thành, Tỉnh Sóc Trăng",
        "code": "31582",
        "parent_code": "942"
    },
    "31585": {
        "name": "Thiện Mỹ",
        "type": "xa",
        "slug": "thien-my",
        "name_with_type": "Xã Thiện Mỹ",
        "path": "Thiện Mỹ, Châu Thành, Sóc Trăng",
        "path_with_type": "Xã Thiện Mỹ, Huyện Châu Thành, Tỉnh Sóc Trăng",
        "code": "31585",
        "parent_code": "942"
    },
    "31594": {
        "name": "An Hiệp",
        "type": "xa",
        "slug": "an-hiep",
        "name_with_type": "Xã An Hiệp",
        "path": "An Hiệp, Châu Thành, Sóc Trăng",
        "path_with_type": "Xã An Hiệp, Huyện Châu Thành, Tỉnh Sóc Trăng",
        "code": "31594",
        "parent_code": "942"
    },
    "31600": {
        "name": "An Ninh",
        "type": "xa",
        "slug": "an-ninh",
        "name_with_type": "Xã An Ninh",
        "path": "An Ninh, Châu Thành, Sóc Trăng",
        "path_with_type": "Xã An Ninh, Huyện Châu Thành, Tỉnh Sóc Trăng",
        "code": "31600",
        "parent_code": "942"
    },
    "31528": {
        "name": "Kế Sách",
        "type": "thi-tran",
        "slug": "ke-sach",
        "name_with_type": "Thị trấn Kế Sách",
        "path": "Kế Sách, Kế Sách, Sóc Trăng",
        "path_with_type": "Thị trấn Kế Sách, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31528",
        "parent_code": "943"
    },
    "31531": {
        "name": "An Lạc Thôn",
        "type": "thi-tran",
        "slug": "an-lac-thon",
        "name_with_type": "Thị trấn An Lạc Thôn",
        "path": "An Lạc Thôn, Kế Sách, Sóc Trăng",
        "path_with_type": "Thị trấn An Lạc Thôn, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31531",
        "parent_code": "943"
    },
    "31534": {
        "name": "Xuân Hòa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hòa",
        "path": "Xuân Hòa, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã Xuân Hòa, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31534",
        "parent_code": "943"
    },
    "31537": {
        "name": "Phong Nẫm",
        "type": "xa",
        "slug": "phong-nam",
        "name_with_type": "Xã Phong Nẫm",
        "path": "Phong Nẫm, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã Phong Nẫm, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31537",
        "parent_code": "943"
    },
    "31540": {
        "name": "An Lạc Tây",
        "type": "xa",
        "slug": "an-lac-tay",
        "name_with_type": "Xã An Lạc Tây",
        "path": "An Lạc Tây, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã An Lạc Tây, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31540",
        "parent_code": "943"
    },
    "31543": {
        "name": "Trinh Phú",
        "type": "xa",
        "slug": "trinh-phu",
        "name_with_type": "Xã Trinh Phú",
        "path": "Trinh Phú, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã Trinh Phú, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31543",
        "parent_code": "943"
    },
    "31546": {
        "name": "Ba Trinh",
        "type": "xa",
        "slug": "ba-trinh",
        "name_with_type": "Xã Ba Trinh",
        "path": "Ba Trinh, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã Ba Trinh, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31546",
        "parent_code": "943"
    },
    "31549": {
        "name": "Thới An Hội",
        "type": "xa",
        "slug": "thoi-an-hoi",
        "name_with_type": "Xã Thới An Hội",
        "path": "Thới An Hội, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã Thới An Hội, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31549",
        "parent_code": "943"
    },
    "31552": {
        "name": "Nhơn Mỹ",
        "type": "xa",
        "slug": "nhon-my",
        "name_with_type": "Xã Nhơn Mỹ",
        "path": "Nhơn Mỹ, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã Nhơn Mỹ, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31552",
        "parent_code": "943"
    },
    "31555": {
        "name": "Kế Thành",
        "type": "xa",
        "slug": "ke-thanh",
        "name_with_type": "Xã Kế Thành",
        "path": "Kế Thành, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã Kế Thành, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31555",
        "parent_code": "943"
    },
    "31558": {
        "name": "Kế An",
        "type": "xa",
        "slug": "ke-an",
        "name_with_type": "Xã Kế An",
        "path": "Kế An, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã Kế An, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31558",
        "parent_code": "943"
    },
    "31561": {
        "name": "Đại Hải",
        "type": "xa",
        "slug": "dai-hai",
        "name_with_type": "Xã Đại Hải",
        "path": "Đại Hải, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã Đại Hải, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31561",
        "parent_code": "943"
    },
    "31564": {
        "name": "An Mỹ",
        "type": "xa",
        "slug": "an-my",
        "name_with_type": "Xã An Mỹ",
        "path": "An Mỹ, Kế Sách, Sóc Trăng",
        "path_with_type": "Xã An Mỹ, Huyện Kế Sách, Tỉnh Sóc Trăng",
        "code": "31564",
        "parent_code": "943"
    },
    "31567": {
        "name": "Huỳnh Hữu Nghĩa",
        "type": "thi-tran",
        "slug": "huynh-huu-nghia",
        "name_with_type": "Thị trấn Huỳnh Hữu Nghĩa",
        "path": "Huỳnh Hữu Nghĩa, Mỹ Tú, Sóc Trăng",
        "path_with_type": "Thị trấn Huỳnh Hữu Nghĩa, Huyện Mỹ Tú, Tỉnh Sóc Trăng",
        "code": "31567",
        "parent_code": "944"
    },
    "31579": {
        "name": "Long Hưng",
        "type": "xa",
        "slug": "long-hung",
        "name_with_type": "Xã Long Hưng",
        "path": "Long Hưng, Mỹ Tú, Sóc Trăng",
        "path_with_type": "Xã Long Hưng, Huyện Mỹ Tú, Tỉnh Sóc Trăng",
        "code": "31579",
        "parent_code": "944"
    },
    "31588": {
        "name": "Hưng Phú",
        "type": "xa",
        "slug": "hung-phu",
        "name_with_type": "Xã Hưng Phú",
        "path": "Hưng Phú, Mỹ Tú, Sóc Trăng",
        "path_with_type": "Xã Hưng Phú, Huyện Mỹ Tú, Tỉnh Sóc Trăng",
        "code": "31588",
        "parent_code": "944"
    },
    "31591": {
        "name": "Mỹ Hương",
        "type": "xa",
        "slug": "my-huong",
        "name_with_type": "Xã Mỹ Hương",
        "path": "Mỹ Hương, Mỹ Tú, Sóc Trăng",
        "path_with_type": "Xã Mỹ Hương, Huyện Mỹ Tú, Tỉnh Sóc Trăng",
        "code": "31591",
        "parent_code": "944"
    },
    "31597": {
        "name": "Mỹ Tú",
        "type": "xa",
        "slug": "my-tu",
        "name_with_type": "Xã Mỹ Tú",
        "path": "Mỹ Tú, Mỹ Tú, Sóc Trăng",
        "path_with_type": "Xã Mỹ Tú, Huyện Mỹ Tú, Tỉnh Sóc Trăng",
        "code": "31597",
        "parent_code": "944"
    },
    "31603": {
        "name": "Mỹ Phước",
        "type": "xa",
        "slug": "my-phuoc",
        "name_with_type": "Xã Mỹ Phước",
        "path": "Mỹ Phước, Mỹ Tú, Sóc Trăng",
        "path_with_type": "Xã Mỹ Phước, Huyện Mỹ Tú, Tỉnh Sóc Trăng",
        "code": "31603",
        "parent_code": "944"
    },
    "31606": {
        "name": "Thuận Hưng",
        "type": "xa",
        "slug": "thuan-hung",
        "name_with_type": "Xã Thuận Hưng",
        "path": "Thuận Hưng, Mỹ Tú, Sóc Trăng",
        "path_with_type": "Xã Thuận Hưng, Huyện Mỹ Tú, Tỉnh Sóc Trăng",
        "code": "31606",
        "parent_code": "944"
    },
    "31609": {
        "name": "Mỹ Thuận",
        "type": "xa",
        "slug": "my-thuan",
        "name_with_type": "Xã Mỹ Thuận",
        "path": "Mỹ Thuận, Mỹ Tú, Sóc Trăng",
        "path_with_type": "Xã Mỹ Thuận, Huyện Mỹ Tú, Tỉnh Sóc Trăng",
        "code": "31609",
        "parent_code": "944"
    },
    "31612": {
        "name": "Phú Mỹ",
        "type": "xa",
        "slug": "phu-my",
        "name_with_type": "Xã Phú Mỹ",
        "path": "Phú Mỹ, Mỹ Tú, Sóc Trăng",
        "path_with_type": "Xã Phú Mỹ, Huyện Mỹ Tú, Tỉnh Sóc Trăng",
        "code": "31612",
        "parent_code": "944"
    },
    "31615": {
        "name": "Cù Lao Dung",
        "type": "thi-tran",
        "slug": "cu-lao-dung",
        "name_with_type": "Thị trấn Cù Lao Dung",
        "path": "Cù Lao Dung, Cù Lao Dung, Sóc Trăng",
        "path_with_type": "Thị trấn Cù Lao Dung, Huyện Cù Lao Dung, Tỉnh Sóc Trăng",
        "code": "31615",
        "parent_code": "945"
    },
    "31618": {
        "name": "An Thạnh 1",
        "type": "xa",
        "slug": "an-thanh-1",
        "name_with_type": "Xã An Thạnh 1",
        "path": "An Thạnh 1, Cù Lao Dung, Sóc Trăng",
        "path_with_type": "Xã An Thạnh 1, Huyện Cù Lao Dung, Tỉnh Sóc Trăng",
        "code": "31618",
        "parent_code": "945"
    },
    "31621": {
        "name": "An Thạnh Tây",
        "type": "xa",
        "slug": "an-thanh-tay",
        "name_with_type": "Xã An Thạnh Tây",
        "path": "An Thạnh Tây, Cù Lao Dung, Sóc Trăng",
        "path_with_type": "Xã An Thạnh Tây, Huyện Cù Lao Dung, Tỉnh Sóc Trăng",
        "code": "31621",
        "parent_code": "945"
    },
    "31624": {
        "name": "An Thạnh Đông",
        "type": "xa",
        "slug": "an-thanh-dong",
        "name_with_type": "Xã An Thạnh Đông",
        "path": "An Thạnh Đông, Cù Lao Dung, Sóc Trăng",
        "path_with_type": "Xã An Thạnh Đông, Huyện Cù Lao Dung, Tỉnh Sóc Trăng",
        "code": "31624",
        "parent_code": "945"
    },
    "31627": {
        "name": "Đại Ân 1",
        "type": "xa",
        "slug": "dai-an-1",
        "name_with_type": "Xã Đại Ân 1",
        "path": "Đại Ân 1, Cù Lao Dung, Sóc Trăng",
        "path_with_type": "Xã Đại Ân 1, Huyện Cù Lao Dung, Tỉnh Sóc Trăng",
        "code": "31627",
        "parent_code": "945"
    },
    "31630": {
        "name": "An Thạnh 2",
        "type": "xa",
        "slug": "an-thanh-2",
        "name_with_type": "Xã An Thạnh 2",
        "path": "An Thạnh 2, Cù Lao Dung, Sóc Trăng",
        "path_with_type": "Xã An Thạnh 2, Huyện Cù Lao Dung, Tỉnh Sóc Trăng",
        "code": "31630",
        "parent_code": "945"
    },
    "31633": {
        "name": "An Thạnh 3",
        "type": "xa",
        "slug": "an-thanh-3",
        "name_with_type": "Xã An Thạnh 3",
        "path": "An Thạnh 3, Cù Lao Dung, Sóc Trăng",
        "path_with_type": "Xã An Thạnh 3, Huyện Cù Lao Dung, Tỉnh Sóc Trăng",
        "code": "31633",
        "parent_code": "945"
    },
    "31636": {
        "name": "An Thạnh Nam",
        "type": "xa",
        "slug": "an-thanh-nam",
        "name_with_type": "Xã An Thạnh Nam",
        "path": "An Thạnh Nam, Cù Lao Dung, Sóc Trăng",
        "path_with_type": "Xã An Thạnh Nam, Huyện Cù Lao Dung, Tỉnh Sóc Trăng",
        "code": "31636",
        "parent_code": "945"
    },
    "31639": {
        "name": "Long Phú",
        "type": "thi-tran",
        "slug": "long-phu",
        "name_with_type": "Thị trấn Long Phú",
        "path": "Long Phú, Long Phú, Sóc Trăng",
        "path_with_type": "Thị trấn Long Phú, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31639",
        "parent_code": "946"
    },
    "31642": {
        "name": "Song Phụng",
        "type": "xa",
        "slug": "song-phung",
        "name_with_type": "Xã Song Phụng",
        "path": "Song Phụng, Long Phú, Sóc Trăng",
        "path_with_type": "Xã Song Phụng, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31642",
        "parent_code": "946"
    },
    "31645": {
        "name": "Đại Ngãi",
        "type": "thi-tran",
        "slug": "dai-ngai",
        "name_with_type": "Thị trấn Đại Ngãi",
        "path": "Đại Ngãi, Long Phú, Sóc Trăng",
        "path_with_type": "Thị trấn Đại Ngãi, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31645",
        "parent_code": "946"
    },
    "31648": {
        "name": "Hậu Thạnh",
        "type": "xa",
        "slug": "hau-thanh",
        "name_with_type": "Xã Hậu Thạnh",
        "path": "Hậu Thạnh, Long Phú, Sóc Trăng",
        "path_with_type": "Xã Hậu Thạnh, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31648",
        "parent_code": "946"
    },
    "31651": {
        "name": "Long Đức",
        "type": "xa",
        "slug": "long-duc",
        "name_with_type": "Xã Long Đức",
        "path": "Long Đức, Long Phú, Sóc Trăng",
        "path_with_type": "Xã Long Đức, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31651",
        "parent_code": "946"
    },
    "31654": {
        "name": "Trường Khánh",
        "type": "xa",
        "slug": "truong-khanh",
        "name_with_type": "Xã Trường Khánh",
        "path": "Trường Khánh, Long Phú, Sóc Trăng",
        "path_with_type": "Xã Trường Khánh, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31654",
        "parent_code": "946"
    },
    "31657": {
        "name": "Phú Hữu",
        "type": "xa",
        "slug": "phu-huu",
        "name_with_type": "Xã Phú Hữu",
        "path": "Phú Hữu, Long Phú, Sóc Trăng",
        "path_with_type": "Xã Phú Hữu, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31657",
        "parent_code": "946"
    },
    "31660": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Long Phú, Sóc Trăng",
        "path_with_type": "Xã Tân Hưng, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31660",
        "parent_code": "946"
    },
    "31663": {
        "name": "Châu Khánh",
        "type": "xa",
        "slug": "chau-khanh",
        "name_with_type": "Xã Châu Khánh",
        "path": "Châu Khánh, Long Phú, Sóc Trăng",
        "path_with_type": "Xã Châu Khánh, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31663",
        "parent_code": "946"
    },
    "31666": {
        "name": "Tân Thạnh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thạnh",
        "path": "Tân Thạnh, Long Phú, Sóc Trăng",
        "path_with_type": "Xã Tân Thạnh, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31666",
        "parent_code": "946"
    },
    "31669": {
        "name": "Long Phú",
        "type": "xa",
        "slug": "long-phu",
        "name_with_type": "Xã Long Phú",
        "path": "Long Phú, Long Phú, Sóc Trăng",
        "path_with_type": "Xã Long Phú, Huyện Long Phú, Tỉnh Sóc Trăng",
        "code": "31669",
        "parent_code": "946"
    },
    "31684": {
        "name": "Mỹ Xuyên",
        "type": "thi-tran",
        "slug": "my-xuyen",
        "name_with_type": "Thị trấn Mỹ Xuyên",
        "path": "Mỹ Xuyên, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Thị trấn Mỹ Xuyên, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31684",
        "parent_code": "947"
    },
    "31690": {
        "name": "Đại Tâm",
        "type": "xa",
        "slug": "dai-tam",
        "name_with_type": "Xã Đại Tâm",
        "path": "Đại Tâm, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Đại Tâm, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31690",
        "parent_code": "947"
    },
    "31693": {
        "name": "Tham Đôn",
        "type": "xa",
        "slug": "tham-don",
        "name_with_type": "Xã Tham Đôn",
        "path": "Tham Đôn, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Tham Đôn, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31693",
        "parent_code": "947"
    },
    "31708": {
        "name": "Thạnh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thạnh Phú",
        "path": "Thạnh Phú, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Thạnh Phú, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31708",
        "parent_code": "947"
    },
    "31711": {
        "name": "Ngọc Đông",
        "type": "xa",
        "slug": "ngoc-dong",
        "name_with_type": "Xã Ngọc Đông",
        "path": "Ngọc Đông, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Ngọc Đông, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31711",
        "parent_code": "947"
    },
    "31714": {
        "name": "Thạnh Quới",
        "type": "xa",
        "slug": "thanh-quoi",
        "name_with_type": "Xã Thạnh Quới",
        "path": "Thạnh Quới, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Thạnh Quới, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31714",
        "parent_code": "947"
    },
    "31717": {
        "name": "Hòa Tú 1",
        "type": "xa",
        "slug": "hoa-tu-1",
        "name_with_type": "Xã Hòa Tú 1",
        "path": "Hòa Tú 1, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Hòa Tú 1, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31717",
        "parent_code": "947"
    },
    "31720": {
        "name": "Gia Hòa 1",
        "type": "xa",
        "slug": "gia-hoa-1",
        "name_with_type": "Xã Gia Hòa 1",
        "path": "Gia Hòa 1, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Gia Hòa 1, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31720",
        "parent_code": "947"
    },
    "31723": {
        "name": "Ngọc Tố",
        "type": "xa",
        "slug": "ngoc-to",
        "name_with_type": "Xã Ngọc Tố",
        "path": "Ngọc Tố, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Ngọc Tố, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31723",
        "parent_code": "947"
    },
    "31726": {
        "name": "Gia Hòa 2",
        "type": "xa",
        "slug": "gia-hoa-2",
        "name_with_type": "Xã Gia Hòa 2",
        "path": "Gia Hòa 2, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Gia Hòa 2, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31726",
        "parent_code": "947"
    },
    "31729": {
        "name": "Hòa Tú II",
        "type": "xa",
        "slug": "hoa-tu-ii",
        "name_with_type": "Xã Hòa Tú II",
        "path": "Hòa Tú II, Mỹ Xuyên, Sóc Trăng",
        "path_with_type": "Xã Hòa Tú II, Huyện Mỹ Xuyên, Tỉnh Sóc Trăng",
        "code": "31729",
        "parent_code": "947"
    },
    "31732": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Ngã Năm, Sóc Trăng",
        "path_with_type": "Phường 1, Thị xã Ngã Năm, Tỉnh Sóc Trăng",
        "code": "31732",
        "parent_code": "948"
    },
    "31735": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Ngã Năm, Sóc Trăng",
        "path_with_type": "Phường 2, Thị xã Ngã Năm, Tỉnh Sóc Trăng",
        "code": "31735",
        "parent_code": "948"
    },
    "31738": {
        "name": "Vĩnh Quới",
        "type": "xa",
        "slug": "vinh-quoi",
        "name_with_type": "Xã Vĩnh Quới",
        "path": "Vĩnh Quới, Ngã Năm, Sóc Trăng",
        "path_with_type": "Xã Vĩnh Quới, Thị xã Ngã Năm, Tỉnh Sóc Trăng",
        "code": "31738",
        "parent_code": "948"
    },
    "31741": {
        "name": "Tân Long",
        "type": "xa",
        "slug": "tan-long",
        "name_with_type": "Xã Tân Long",
        "path": "Tân Long, Ngã Năm, Sóc Trăng",
        "path_with_type": "Xã Tân Long, Thị xã Ngã Năm, Tỉnh Sóc Trăng",
        "code": "31741",
        "parent_code": "948"
    },
    "31744": {
        "name": "Long Bình",
        "type": "xa",
        "slug": "long-binh",
        "name_with_type": "Xã Long Bình",
        "path": "Long Bình, Ngã Năm, Sóc Trăng",
        "path_with_type": "Xã Long Bình, Thị xã Ngã Năm, Tỉnh Sóc Trăng",
        "code": "31744",
        "parent_code": "948"
    },
    "31747": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Ngã Năm, Sóc Trăng",
        "path_with_type": "Phường 3, Thị xã Ngã Năm, Tỉnh Sóc Trăng",
        "code": "31747",
        "parent_code": "948"
    },
    "31750": {
        "name": "Mỹ Bình",
        "type": "xa",
        "slug": "my-binh",
        "name_with_type": "Xã Mỹ Bình",
        "path": "Mỹ Bình, Ngã Năm, Sóc Trăng",
        "path_with_type": "Xã Mỹ Bình, Thị xã Ngã Năm, Tỉnh Sóc Trăng",
        "code": "31750",
        "parent_code": "948"
    },
    "31753": {
        "name": "Mỹ Quới",
        "type": "xa",
        "slug": "my-quoi",
        "name_with_type": "Xã Mỹ Quới",
        "path": "Mỹ Quới, Ngã Năm, Sóc Trăng",
        "path_with_type": "Xã Mỹ Quới, Thị xã Ngã Năm, Tỉnh Sóc Trăng",
        "code": "31753",
        "parent_code": "948"
    },
    "31756": {
        "name": "Phú Lộc",
        "type": "thi-tran",
        "slug": "phu-loc",
        "name_with_type": "Thị trấn Phú Lộc",
        "path": "Phú Lộc, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Thị trấn Phú Lộc, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31756",
        "parent_code": "949"
    },
    "31757": {
        "name": "Hưng Lợi",
        "type": "thi-tran",
        "slug": "hung-loi",
        "name_with_type": "Thị trấn Hưng Lợi",
        "path": "Hưng Lợi, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Thị trấn Hưng Lợi, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31757",
        "parent_code": "949"
    },
    "31759": {
        "name": "Lâm Tân",
        "type": "xa",
        "slug": "lam-tan",
        "name_with_type": "Xã Lâm Tân",
        "path": "Lâm Tân, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Xã Lâm Tân, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31759",
        "parent_code": "949"
    },
    "31762": {
        "name": "Thạnh Tân",
        "type": "xa",
        "slug": "thanh-tan",
        "name_with_type": "Xã Thạnh Tân",
        "path": "Thạnh Tân, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Xã Thạnh Tân, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31762",
        "parent_code": "949"
    },
    "31765": {
        "name": "Lâm Kiết",
        "type": "xa",
        "slug": "lam-kiet",
        "name_with_type": "Xã Lâm Kiết",
        "path": "Lâm Kiết, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Xã Lâm Kiết, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31765",
        "parent_code": "949"
    },
    "31768": {
        "name": "Tuân Tức",
        "type": "xa",
        "slug": "tuan-tuc",
        "name_with_type": "Xã Tuân Tức",
        "path": "Tuân Tức, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Xã Tuân Tức, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31768",
        "parent_code": "949"
    },
    "31771": {
        "name": "Vĩnh Thành",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thành",
        "path": "Vĩnh Thành, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Xã Vĩnh Thành, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31771",
        "parent_code": "949"
    },
    "31774": {
        "name": "Thạnh Trị",
        "type": "xa",
        "slug": "thanh-tri",
        "name_with_type": "Xã Thạnh Trị",
        "path": "Thạnh Trị, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Xã Thạnh Trị, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31774",
        "parent_code": "949"
    },
    "31777": {
        "name": "Vĩnh Lợi",
        "type": "xa",
        "slug": "vinh-loi",
        "name_with_type": "Xã Vĩnh Lợi",
        "path": "Vĩnh Lợi, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Xã Vĩnh Lợi, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31777",
        "parent_code": "949"
    },
    "31780": {
        "name": "Châu Hưng",
        "type": "xa",
        "slug": "chau-hung",
        "name_with_type": "Xã Châu Hưng",
        "path": "Châu Hưng, Thạnh Trị, Sóc Trăng",
        "path_with_type": "Xã Châu Hưng, Huyện Thạnh Trị, Tỉnh Sóc Trăng",
        "code": "31780",
        "parent_code": "949"
    },
    "31783": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Phường 1, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31783",
        "parent_code": "950"
    },
    "31786": {
        "name": "Hòa Đông",
        "type": "xa",
        "slug": "hoa-dong",
        "name_with_type": "Xã Hòa Đông",
        "path": "Hòa Đông, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Xã Hòa Đông, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31786",
        "parent_code": "950"
    },
    "31789": {
        "name": "Khánh Hòa",
        "type": "phuong",
        "slug": "khanh-hoa",
        "name_with_type": "Phường Khánh Hòa",
        "path": "Khánh Hòa, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Phường Khánh Hòa, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31789",
        "parent_code": "950"
    },
    "31792": {
        "name": "Vĩnh Hiệp",
        "type": "xa",
        "slug": "vinh-hiep",
        "name_with_type": "Xã Vĩnh Hiệp",
        "path": "Vĩnh Hiệp, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Xã Vĩnh Hiệp, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31792",
        "parent_code": "950"
    },
    "31795": {
        "name": "Vĩnh Hải",
        "type": "xa",
        "slug": "vinh-hai",
        "name_with_type": "Xã Vĩnh Hải",
        "path": "Vĩnh Hải, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Xã Vĩnh Hải, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31795",
        "parent_code": "950"
    },
    "31798": {
        "name": "Lạc Hòa",
        "type": "xa",
        "slug": "lac-hoa",
        "name_with_type": "Xã Lạc Hòa",
        "path": "Lạc Hòa, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Xã Lạc Hòa, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31798",
        "parent_code": "950"
    },
    "31801": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Phường 2, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31801",
        "parent_code": "950"
    },
    "31804": {
        "name": "Vĩnh Phước",
        "type": "phuong",
        "slug": "vinh-phuoc",
        "name_with_type": "Phường Vĩnh Phước",
        "path": "Vĩnh Phước, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Phường Vĩnh Phước, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31804",
        "parent_code": "950"
    },
    "31807": {
        "name": "Vĩnh Tân",
        "type": "xa",
        "slug": "vinh-tan",
        "name_with_type": "Xã Vĩnh Tân",
        "path": "Vĩnh Tân, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Xã Vĩnh Tân, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31807",
        "parent_code": "950"
    },
    "31810": {
        "name": "Lai Hòa",
        "type": "xa",
        "slug": "lai-hoa",
        "name_with_type": "Xã Lai Hòa",
        "path": "Lai Hòa, Vĩnh Châu, Sóc Trăng",
        "path_with_type": "Xã Lai Hòa, Thị xã Vĩnh Châu, Tỉnh Sóc Trăng",
        "code": "31810",
        "parent_code": "950"
    },
    "31672": {
        "name": "Đại Ân  2",
        "type": "xa",
        "slug": "dai-an-2",
        "name_with_type": "Xã Đại Ân  2",
        "path": "Đại Ân  2, Trần Đề, Sóc Trăng",
        "path_with_type": "Xã Đại Ân  2, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31672",
        "parent_code": "951"
    },
    "31673": {
        "name": "Trần Đề",
        "type": "thi-tran",
        "slug": "tran-de",
        "name_with_type": "Thị trấn Trần Đề",
        "path": "Trần Đề, Trần Đề, Sóc Trăng",
        "path_with_type": "Thị trấn Trần Đề, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31673",
        "parent_code": "951"
    },
    "31675": {
        "name": "Liêu Tú",
        "type": "xa",
        "slug": "lieu-tu",
        "name_with_type": "Xã Liêu Tú",
        "path": "Liêu Tú, Trần Đề, Sóc Trăng",
        "path_with_type": "Xã Liêu Tú, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31675",
        "parent_code": "951"
    },
    "31678": {
        "name": "Lịch Hội Thượng",
        "type": "xa",
        "slug": "lich-hoi-thuong",
        "name_with_type": "Xã Lịch Hội Thượng",
        "path": "Lịch Hội Thượng, Trần Đề, Sóc Trăng",
        "path_with_type": "Xã Lịch Hội Thượng, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31678",
        "parent_code": "951"
    },
    "31679": {
        "name": "Lịch Hội Thượng",
        "type": "thi-tran",
        "slug": "lich-hoi-thuong",
        "name_with_type": "Thị trấn Lịch Hội Thượng",
        "path": "Lịch Hội Thượng, Trần Đề, Sóc Trăng",
        "path_with_type": "Thị trấn Lịch Hội Thượng, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31679",
        "parent_code": "951"
    },
    "31681": {
        "name": "Trung Bình",
        "type": "xa",
        "slug": "trung-binh",
        "name_with_type": "Xã Trung Bình",
        "path": "Trung Bình, Trần Đề, Sóc Trăng",
        "path_with_type": "Xã Trung Bình, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31681",
        "parent_code": "951"
    },
    "31687": {
        "name": "Tài Văn",
        "type": "xa",
        "slug": "tai-van",
        "name_with_type": "Xã Tài Văn",
        "path": "Tài Văn, Trần Đề, Sóc Trăng",
        "path_with_type": "Xã Tài Văn, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31687",
        "parent_code": "951"
    },
    "31696": {
        "name": "Viên An",
        "type": "xa",
        "slug": "vien-an",
        "name_with_type": "Xã Viên An",
        "path": "Viên An, Trần Đề, Sóc Trăng",
        "path_with_type": "Xã Viên An, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31696",
        "parent_code": "951"
    },
    "31699": {
        "name": "Thạnh Thới An",
        "type": "xa",
        "slug": "thanh-thoi-an",
        "name_with_type": "Xã Thạnh Thới An",
        "path": "Thạnh Thới An, Trần Đề, Sóc Trăng",
        "path_with_type": "Xã Thạnh Thới An, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31699",
        "parent_code": "951"
    },
    "31702": {
        "name": "Thạnh Thới Thuận",
        "type": "xa",
        "slug": "thanh-thoi-thuan",
        "name_with_type": "Xã Thạnh Thới Thuận",
        "path": "Thạnh Thới Thuận, Trần Đề, Sóc Trăng",
        "path_with_type": "Xã Thạnh Thới Thuận, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31702",
        "parent_code": "951"
    },
    "31705": {
        "name": "Viên Bình",
        "type": "xa",
        "slug": "vien-binh",
        "name_with_type": "Xã Viên Bình",
        "path": "Viên Bình, Trần Đề, Sóc Trăng",
        "path_with_type": "Xã Viên Bình, Huyện Trần Đề, Tỉnh Sóc Trăng",
        "code": "31705",
        "parent_code": "951"
    },
    "25216": {
        "name": "Thác Mơ",
        "type": "phuong",
        "slug": "thac-mo",
        "name_with_type": "Phường Thác Mơ",
        "path": "Thác Mơ, Phước Long, Bình Phước",
        "path_with_type": "Phường Thác Mơ, Thị xã Phước Long, Tỉnh Bình Phước",
        "code": "25216",
        "parent_code": "688"
    },
    "25217": {
        "name": "Long Thủy",
        "type": "phuong",
        "slug": "long-thuy",
        "name_with_type": "Phường Long Thủy",
        "path": "Long Thủy, Phước Long, Bình Phước",
        "path_with_type": "Phường Long Thủy, Thị xã Phước Long, Tỉnh Bình Phước",
        "code": "25217",
        "parent_code": "688"
    },
    "25219": {
        "name": "Phước Bình",
        "type": "phuong",
        "slug": "phuoc-binh",
        "name_with_type": "Phường Phước Bình",
        "path": "Phước Bình, Phước Long, Bình Phước",
        "path_with_type": "Phường Phước Bình, Thị xã Phước Long, Tỉnh Bình Phước",
        "code": "25219",
        "parent_code": "688"
    },
    "25220": {
        "name": "Long Phước",
        "type": "phuong",
        "slug": "long-phuoc",
        "name_with_type": "Phường Long Phước",
        "path": "Long Phước, Phước Long, Bình Phước",
        "path_with_type": "Phường Long Phước, Thị xã Phước Long, Tỉnh Bình Phước",
        "code": "25220",
        "parent_code": "688"
    },
    "25237": {
        "name": "Sơn Giang",
        "type": "phuong",
        "slug": "son-giang",
        "name_with_type": "Phường Sơn Giang",
        "path": "Sơn Giang, Phước Long, Bình Phước",
        "path_with_type": "Phường Sơn Giang, Thị xã Phước Long, Tỉnh Bình Phước",
        "code": "25237",
        "parent_code": "688"
    },
    "25245": {
        "name": "Long Giang",
        "type": "xa",
        "slug": "long-giang",
        "name_with_type": "Xã Long Giang",
        "path": "Long Giang, Phước Long, Bình Phước",
        "path_with_type": "Xã Long Giang, Thị xã Phước Long, Tỉnh Bình Phước",
        "code": "25245",
        "parent_code": "688"
    },
    "25249": {
        "name": "Phước Tín",
        "type": "xa",
        "slug": "phuoc-tin",
        "name_with_type": "Xã Phước Tín",
        "path": "Phước Tín, Phước Long, Bình Phước",
        "path_with_type": "Xã Phước Tín, Thị xã Phước Long, Tỉnh Bình Phước",
        "code": "25249",
        "parent_code": "688"
    },
    "25195": {
        "name": "Tân Phú",
        "type": "phuong",
        "slug": "tan-phu",
        "name_with_type": "Phường Tân Phú",
        "path": "Tân Phú, Đồng Xoài, Bình Phước",
        "path_with_type": "Phường Tân Phú, Thị xã Đồng Xoài, Tỉnh Bình Phước",
        "code": "25195",
        "parent_code": "689"
    },
    "25198": {
        "name": "Tân Đồng",
        "type": "phuong",
        "slug": "tan-dong",
        "name_with_type": "Phường Tân Đồng",
        "path": "Tân Đồng, Đồng Xoài, Bình Phước",
        "path_with_type": "Phường Tân Đồng, Thị xã Đồng Xoài, Tỉnh Bình Phước",
        "code": "25198",
        "parent_code": "689"
    },
    "25201": {
        "name": "Tân Bình",
        "type": "phuong",
        "slug": "tan-binh",
        "name_with_type": "Phường Tân Bình",
        "path": "Tân Bình, Đồng Xoài, Bình Phước",
        "path_with_type": "Phường Tân Bình, Thị xã Đồng Xoài, Tỉnh Bình Phước",
        "code": "25201",
        "parent_code": "689"
    },
    "25204": {
        "name": "Tân Xuân",
        "type": "phuong",
        "slug": "tan-xuan",
        "name_with_type": "Phường Tân Xuân",
        "path": "Tân Xuân, Đồng Xoài, Bình Phước",
        "path_with_type": "Phường Tân Xuân, Thị xã Đồng Xoài, Tỉnh Bình Phước",
        "code": "25204",
        "parent_code": "689"
    },
    "25205": {
        "name": "Tân Thiện",
        "type": "phuong",
        "slug": "tan-thien",
        "name_with_type": "Phường Tân Thiện",
        "path": "Tân Thiện, Đồng Xoài, Bình Phước",
        "path_with_type": "Phường Tân Thiện, Thị xã Đồng Xoài, Tỉnh Bình Phước",
        "code": "25205",
        "parent_code": "689"
    },
    "25207": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Đồng Xoài, Bình Phước",
        "path_with_type": "Xã Tân Thành, Thị xã Đồng Xoài, Tỉnh Bình Phước",
        "code": "25207",
        "parent_code": "689"
    },
    "25210": {
        "name": "Tiến Thành",
        "type": "xa",
        "slug": "tien-thanh",
        "name_with_type": "Xã Tiến Thành",
        "path": "Tiến Thành, Đồng Xoài, Bình Phước",
        "path_with_type": "Xã Tiến Thành, Thị xã Đồng Xoài, Tỉnh Bình Phước",
        "code": "25210",
        "parent_code": "689"
    },
    "25213": {
        "name": "Tiến Hưng",
        "type": "xa",
        "slug": "tien-hung",
        "name_with_type": "Xã Tiến Hưng",
        "path": "Tiến Hưng, Đồng Xoài, Bình Phước",
        "path_with_type": "Xã Tiến Hưng, Thị xã Đồng Xoài, Tỉnh Bình Phước",
        "code": "25213",
        "parent_code": "689"
    },
    "25320": {
        "name": "Hưng Chiến",
        "type": "phuong",
        "slug": "hung-chien",
        "name_with_type": "Phường Hưng Chiến",
        "path": "Hưng Chiến, Bình Long, Bình Phước",
        "path_with_type": "Phường Hưng Chiến, Thị xã Bình Long, Tỉnh Bình Phước",
        "code": "25320",
        "parent_code": "690"
    },
    "25324": {
        "name": "An Lộc",
        "type": "phuong",
        "slug": "an-loc",
        "name_with_type": "Phường An Lộc",
        "path": "An Lộc, Bình Long, Bình Phước",
        "path_with_type": "Phường An Lộc, Thị xã Bình Long, Tỉnh Bình Phước",
        "code": "25324",
        "parent_code": "690"
    },
    "25325": {
        "name": "Phú Thịnh",
        "type": "phuong",
        "slug": "phu-thinh",
        "name_with_type": "Phường Phú Thịnh",
        "path": "Phú Thịnh, Bình Long, Bình Phước",
        "path_with_type": "Phường Phú Thịnh, Thị xã Bình Long, Tỉnh Bình Phước",
        "code": "25325",
        "parent_code": "690"
    },
    "25326": {
        "name": "Phú Đức",
        "type": "phuong",
        "slug": "phu-duc",
        "name_with_type": "Phường Phú Đức",
        "path": "Phú Đức, Bình Long, Bình Phước",
        "path_with_type": "Phường Phú Đức, Thị xã Bình Long, Tỉnh Bình Phước",
        "code": "25326",
        "parent_code": "690"
    },
    "25333": {
        "name": "Thanh Lương",
        "type": "xa",
        "slug": "thanh-luong",
        "name_with_type": "Xã Thanh Lương",
        "path": "Thanh Lương, Bình Long, Bình Phước",
        "path_with_type": "Xã Thanh Lương, Thị xã Bình Long, Tỉnh Bình Phước",
        "code": "25333",
        "parent_code": "690"
    },
    "25336": {
        "name": "Thanh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thanh Phú",
        "path": "Thanh Phú, Bình Long, Bình Phước",
        "path_with_type": "Xã Thanh Phú, Thị xã Bình Long, Tỉnh Bình Phước",
        "code": "25336",
        "parent_code": "690"
    },
    "25222": {
        "name": "Bù Gia Mập",
        "type": "xa",
        "slug": "bu-gia-map",
        "name_with_type": "Xã Bù Gia Mập",
        "path": "Bù Gia Mập, Bù Gia Mập, Bình Phước",
        "path_with_type": "Xã Bù Gia Mập, Huyện Bù Gia Mập, Tỉnh Bình Phước",
        "code": "25222",
        "parent_code": "691"
    },
    "25225": {
        "name": "Đak Ơ",
        "type": "xa",
        "slug": "dak-o",
        "name_with_type": "Xã Đak Ơ",
        "path": "Đak Ơ, Bù Gia Mập, Bình Phước",
        "path_with_type": "Xã Đak Ơ, Huyện Bù Gia Mập, Tỉnh Bình Phước",
        "code": "25225",
        "parent_code": "691"
    },
    "25228": {
        "name": "Đức Hạnh",
        "type": "xa",
        "slug": "duc-hanh",
        "name_with_type": "Xã Đức Hạnh",
        "path": "Đức Hạnh, Bù Gia Mập, Bình Phước",
        "path_with_type": "Xã Đức Hạnh, Huyện Bù Gia Mập, Tỉnh Bình Phước",
        "code": "25228",
        "parent_code": "691"
    },
    "25229": {
        "name": "Phú Văn",
        "type": "xa",
        "slug": "phu-van",
        "name_with_type": "Xã Phú Văn",
        "path": "Phú Văn, Bù Gia Mập, Bình Phước",
        "path_with_type": "Xã Phú Văn, Huyện Bù Gia Mập, Tỉnh Bình Phước",
        "code": "25229",
        "parent_code": "691"
    },
    "25231": {
        "name": "Đa Kia",
        "type": "xa",
        "slug": "da-kia",
        "name_with_type": "Xã Đa Kia",
        "path": "Đa Kia, Bù Gia Mập, Bình Phước",
        "path_with_type": "Xã Đa Kia, Huyện Bù Gia Mập, Tỉnh Bình Phước",
        "code": "25231",
        "parent_code": "691"
    },
    "25232": {
        "name": "Phước Minh",
        "type": "xa",
        "slug": "phuoc-minh",
        "name_with_type": "Xã Phước Minh",
        "path": "Phước Minh, Bù Gia Mập, Bình Phước",
        "path_with_type": "Xã Phước Minh, Huyện Bù Gia Mập, Tỉnh Bình Phước",
        "code": "25232",
        "parent_code": "691"
    },
    "25234": {
        "name": "Bình Thắng",
        "type": "xa",
        "slug": "binh-thang",
        "name_with_type": "Xã Bình Thắng",
        "path": "Bình Thắng, Bù Gia Mập, Bình Phước",
        "path_with_type": "Xã Bình Thắng, Huyện Bù Gia Mập, Tỉnh Bình Phước",
        "code": "25234",
        "parent_code": "691"
    },
    "25267": {
        "name": "Phú Nghĩa",
        "type": "xa",
        "slug": "phu-nghia",
        "name_with_type": "Xã Phú Nghĩa",
        "path": "Phú Nghĩa, Bù Gia Mập, Bình Phước",
        "path_with_type": "Xã Phú Nghĩa, Huyện Bù Gia Mập, Tỉnh Bình Phước",
        "code": "25267",
        "parent_code": "691"
    },
    "25270": {
        "name": "Lộc Ninh",
        "type": "thi-tran",
        "slug": "loc-ninh",
        "name_with_type": "Thị trấn Lộc Ninh",
        "path": "Lộc Ninh, Lộc Ninh, Bình Phước",
        "path_with_type": "Thị trấn Lộc Ninh, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25270",
        "parent_code": "692"
    },
    "25273": {
        "name": "Lộc Hòa",
        "type": "xa",
        "slug": "loc-hoa",
        "name_with_type": "Xã Lộc Hòa",
        "path": "Lộc Hòa, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Hòa, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25273",
        "parent_code": "692"
    },
    "25276": {
        "name": "Lộc An",
        "type": "xa",
        "slug": "loc-an",
        "name_with_type": "Xã Lộc An",
        "path": "Lộc An, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc An, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25276",
        "parent_code": "692"
    },
    "25279": {
        "name": "Lộc Tấn",
        "type": "xa",
        "slug": "loc-tan",
        "name_with_type": "Xã Lộc Tấn",
        "path": "Lộc Tấn, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Tấn, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25279",
        "parent_code": "692"
    },
    "25280": {
        "name": "Lộc Thạnh",
        "type": "xa",
        "slug": "loc-thanh",
        "name_with_type": "Xã Lộc Thạnh",
        "path": "Lộc Thạnh, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Thạnh, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25280",
        "parent_code": "692"
    },
    "25282": {
        "name": "Lộc Hiệp",
        "type": "xa",
        "slug": "loc-hiep",
        "name_with_type": "Xã Lộc Hiệp",
        "path": "Lộc Hiệp, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Hiệp, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25282",
        "parent_code": "692"
    },
    "25285": {
        "name": "Lộc Thiện",
        "type": "xa",
        "slug": "loc-thien",
        "name_with_type": "Xã Lộc Thiện",
        "path": "Lộc Thiện, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Thiện, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25285",
        "parent_code": "692"
    },
    "25288": {
        "name": "Lộc Thuận",
        "type": "xa",
        "slug": "loc-thuan",
        "name_with_type": "Xã Lộc Thuận",
        "path": "Lộc Thuận, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Thuận, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25288",
        "parent_code": "692"
    },
    "25291": {
        "name": "Lộc Quang",
        "type": "xa",
        "slug": "loc-quang",
        "name_with_type": "Xã Lộc Quang",
        "path": "Lộc Quang, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Quang, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25291",
        "parent_code": "692"
    },
    "25292": {
        "name": "Lộc Phú",
        "type": "xa",
        "slug": "loc-phu",
        "name_with_type": "Xã Lộc Phú",
        "path": "Lộc Phú, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Phú, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25292",
        "parent_code": "692"
    },
    "25294": {
        "name": "Lộc Thành",
        "type": "xa",
        "slug": "loc-thanh",
        "name_with_type": "Xã Lộc Thành",
        "path": "Lộc Thành, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Thành, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25294",
        "parent_code": "692"
    },
    "25297": {
        "name": "Lộc Thái",
        "type": "xa",
        "slug": "loc-thai",
        "name_with_type": "Xã Lộc Thái",
        "path": "Lộc Thái, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Thái, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25297",
        "parent_code": "692"
    },
    "25300": {
        "name": "Lộc Điền",
        "type": "xa",
        "slug": "loc-dien",
        "name_with_type": "Xã Lộc Điền",
        "path": "Lộc Điền, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Điền, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25300",
        "parent_code": "692"
    },
    "25303": {
        "name": "Lộc Hưng",
        "type": "xa",
        "slug": "loc-hung",
        "name_with_type": "Xã Lộc Hưng",
        "path": "Lộc Hưng, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Hưng, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25303",
        "parent_code": "692"
    },
    "25305": {
        "name": "Lộc Thịnh",
        "type": "xa",
        "slug": "loc-thinh",
        "name_with_type": "Xã Lộc Thịnh",
        "path": "Lộc Thịnh, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Thịnh, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25305",
        "parent_code": "692"
    },
    "25306": {
        "name": "Lộc Khánh",
        "type": "xa",
        "slug": "loc-khanh",
        "name_with_type": "Xã Lộc Khánh",
        "path": "Lộc Khánh, Lộc Ninh, Bình Phước",
        "path_with_type": "Xã Lộc Khánh, Huyện Lộc Ninh, Tỉnh Bình Phước",
        "code": "25306",
        "parent_code": "692"
    },
    "25308": {
        "name": "Thanh Bình",
        "type": "thi-tran",
        "slug": "thanh-binh",
        "name_with_type": "Thị trấn Thanh Bình",
        "path": "Thanh Bình, Bù Đốp, Bình Phước",
        "path_with_type": "Thị trấn Thanh Bình, Huyện Bù Đốp, Tỉnh Bình Phước",
        "code": "25308",
        "parent_code": "693"
    },
    "25309": {
        "name": "Hưng Phước",
        "type": "xa",
        "slug": "hung-phuoc",
        "name_with_type": "Xã Hưng Phước",
        "path": "Hưng Phước, Bù Đốp, Bình Phước",
        "path_with_type": "Xã Hưng Phước, Huyện Bù Đốp, Tỉnh Bình Phước",
        "code": "25309",
        "parent_code": "693"
    },
    "25310": {
        "name": "Phước Thiện",
        "type": "xa",
        "slug": "phuoc-thien",
        "name_with_type": "Xã Phước Thiện",
        "path": "Phước Thiện, Bù Đốp, Bình Phước",
        "path_with_type": "Xã Phước Thiện, Huyện Bù Đốp, Tỉnh Bình Phước",
        "code": "25310",
        "parent_code": "693"
    },
    "25312": {
        "name": "Thiện Hưng",
        "type": "xa",
        "slug": "thien-hung",
        "name_with_type": "Xã Thiện Hưng",
        "path": "Thiện Hưng, Bù Đốp, Bình Phước",
        "path_with_type": "Xã Thiện Hưng, Huyện Bù Đốp, Tỉnh Bình Phước",
        "code": "25312",
        "parent_code": "693"
    },
    "25315": {
        "name": "Thanh Hòa",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thanh Hòa",
        "path": "Thanh Hòa, Bù Đốp, Bình Phước",
        "path_with_type": "Xã Thanh Hòa, Huyện Bù Đốp, Tỉnh Bình Phước",
        "code": "25315",
        "parent_code": "693"
    },
    "25318": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Bù Đốp, Bình Phước",
        "path_with_type": "Xã Tân Thành, Huyện Bù Đốp, Tỉnh Bình Phước",
        "code": "25318",
        "parent_code": "693"
    },
    "25321": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Bù Đốp, Bình Phước",
        "path_with_type": "Xã Tân Tiến, Huyện Bù Đốp, Tỉnh Bình Phước",
        "code": "25321",
        "parent_code": "693"
    },
    "25327": {
        "name": "Thanh An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thanh An",
        "path": "Thanh An, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Thanh An, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25327",
        "parent_code": "694"
    },
    "25330": {
        "name": "An Khương",
        "type": "xa",
        "slug": "an-khuong",
        "name_with_type": "Xã An Khương",
        "path": "An Khương, Hớn Quản, Bình Phước",
        "path_with_type": "Xã An Khương, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25330",
        "parent_code": "694"
    },
    "25339": {
        "name": "An Phú",
        "type": "xa",
        "slug": "an-phu",
        "name_with_type": "Xã An Phú",
        "path": "An Phú, Hớn Quản, Bình Phước",
        "path_with_type": "Xã An Phú, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25339",
        "parent_code": "694"
    },
    "25342": {
        "name": "Tân Lợi",
        "type": "xa",
        "slug": "tan-loi",
        "name_with_type": "Xã Tân Lợi",
        "path": "Tân Lợi, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Tân Lợi, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25342",
        "parent_code": "694"
    },
    "25345": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Tân Hưng, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25345",
        "parent_code": "694"
    },
    "25348": {
        "name": "Minh Đức",
        "type": "xa",
        "slug": "minh-duc",
        "name_with_type": "Xã Minh Đức",
        "path": "Minh Đức, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Minh Đức, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25348",
        "parent_code": "694"
    },
    "25349": {
        "name": "Minh Tâm",
        "type": "xa",
        "slug": "minh-tam",
        "name_with_type": "Xã Minh Tâm",
        "path": "Minh Tâm, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Minh Tâm, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25349",
        "parent_code": "694"
    },
    "25351": {
        "name": "Phước An",
        "type": "xa",
        "slug": "phuoc-an",
        "name_with_type": "Xã Phước An",
        "path": "Phước An, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Phước An, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25351",
        "parent_code": "694"
    },
    "25354": {
        "name": "Thanh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thanh Bình",
        "path": "Thanh Bình, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Thanh Bình, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25354",
        "parent_code": "694"
    },
    "25357": {
        "name": "Tân Khai",
        "type": "xa",
        "slug": "tan-khai",
        "name_with_type": "Xã Tân Khai",
        "path": "Tân Khai, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Tân Khai, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25357",
        "parent_code": "694"
    },
    "25360": {
        "name": "Đồng Nơ",
        "type": "xa",
        "slug": "dong-no",
        "name_with_type": "Xã Đồng Nơ",
        "path": "Đồng Nơ, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Đồng Nơ, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25360",
        "parent_code": "694"
    },
    "25361": {
        "name": "Tân Hiệp",
        "type": "xa",
        "slug": "tan-hiep",
        "name_with_type": "Xã Tân Hiệp",
        "path": "Tân Hiệp, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Tân Hiệp, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25361",
        "parent_code": "694"
    },
    "25438": {
        "name": "Tân Quan",
        "type": "xa",
        "slug": "tan-quan",
        "name_with_type": "Xã Tân Quan",
        "path": "Tân Quan, Hớn Quản, Bình Phước",
        "path_with_type": "Xã Tân Quan, Huyện Hớn Quản, Tỉnh Bình Phước",
        "code": "25438",
        "parent_code": "694"
    },
    "25363": {
        "name": "Tân Phú",
        "type": "thi-tran",
        "slug": "tan-phu",
        "name_with_type": "Thị trấn Tân Phú",
        "path": "Tân Phú, Đồng Phú, Bình Phước",
        "path_with_type": "Thị trấn Tân Phú, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25363",
        "parent_code": "695"
    },
    "25366": {
        "name": "Thuận Lợi",
        "type": "xa",
        "slug": "thuan-loi",
        "name_with_type": "Xã Thuận Lợi",
        "path": "Thuận Lợi, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Thuận Lợi, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25366",
        "parent_code": "695"
    },
    "25369": {
        "name": "Đồng Tâm",
        "type": "xa",
        "slug": "dong-tam",
        "name_with_type": "Xã Đồng Tâm",
        "path": "Đồng Tâm, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Đồng Tâm, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25369",
        "parent_code": "695"
    },
    "25372": {
        "name": "Tân Phước",
        "type": "xa",
        "slug": "tan-phuoc",
        "name_with_type": "Xã Tân Phước",
        "path": "Tân Phước, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Tân Phước, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25372",
        "parent_code": "695"
    },
    "25375": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Tân Hưng, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25375",
        "parent_code": "695"
    },
    "25378": {
        "name": "Tân Lợi",
        "type": "xa",
        "slug": "tan-loi",
        "name_with_type": "Xã Tân Lợi",
        "path": "Tân Lợi, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Tân Lợi, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25378",
        "parent_code": "695"
    },
    "25381": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Tân Lập, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25381",
        "parent_code": "695"
    },
    "25384": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Tân Hòa, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25384",
        "parent_code": "695"
    },
    "25387": {
        "name": "Thuận Phú",
        "type": "xa",
        "slug": "thuan-phu",
        "name_with_type": "Xã Thuận Phú",
        "path": "Thuận Phú, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Thuận Phú, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25387",
        "parent_code": "695"
    },
    "25390": {
        "name": "Đồng Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đồng Tiến",
        "path": "Đồng Tiến, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Đồng Tiến, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25390",
        "parent_code": "695"
    },
    "25393": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Đồng Phú, Bình Phước",
        "path_with_type": "Xã Tân Tiến, Huyện Đồng Phú, Tỉnh Bình Phước",
        "code": "25393",
        "parent_code": "695"
    },
    "25396": {
        "name": "Đức Phong",
        "type": "thi-tran",
        "slug": "duc-phong",
        "name_with_type": "Thị trấn Đức Phong",
        "path": "Đức Phong, Bù Đăng, Bình Phước",
        "path_with_type": "Thị trấn Đức Phong, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25396",
        "parent_code": "696"
    },
    "25398": {
        "name": "Đường 10",
        "type": "xa",
        "slug": "duong-10",
        "name_with_type": "Xã Đường 10",
        "path": "Đường 10, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Đường 10, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25398",
        "parent_code": "696"
    },
    "25399": {
        "name": "Đak Nhau",
        "type": "xa",
        "slug": "dak-nhau",
        "name_with_type": "Xã Đak Nhau",
        "path": "Đak Nhau, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Đak Nhau, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25399",
        "parent_code": "696"
    },
    "25400": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Phú Sơn, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25400",
        "parent_code": "696"
    },
    "25402": {
        "name": "Thọ Sơn",
        "type": "xa",
        "slug": "tho-son",
        "name_with_type": "Xã Thọ Sơn",
        "path": "Thọ Sơn, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Thọ Sơn, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25402",
        "parent_code": "696"
    },
    "25404": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Bình Minh, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25404",
        "parent_code": "696"
    },
    "25405": {
        "name": "Bom Bo",
        "type": "xa",
        "slug": "bom-bo",
        "name_with_type": "Xã Bom Bo",
        "path": "Bom Bo, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Bom Bo, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25405",
        "parent_code": "696"
    },
    "25408": {
        "name": "Minh Hưng",
        "type": "xa",
        "slug": "minh-hung",
        "name_with_type": "Xã Minh Hưng",
        "path": "Minh Hưng, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Minh Hưng, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25408",
        "parent_code": "696"
    },
    "25411": {
        "name": "Đoàn Kết",
        "type": "xa",
        "slug": "doan-ket",
        "name_with_type": "Xã Đoàn Kết",
        "path": "Đoàn Kết, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Đoàn Kết, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25411",
        "parent_code": "696"
    },
    "25414": {
        "name": "Đồng Nai",
        "type": "xa",
        "slug": "dong-nai",
        "name_with_type": "Xã Đồng Nai",
        "path": "Đồng Nai, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Đồng Nai, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25414",
        "parent_code": "696"
    },
    "25417": {
        "name": "Đức Liễu",
        "type": "xa",
        "slug": "duc-lieu",
        "name_with_type": "Xã Đức Liễu",
        "path": "Đức Liễu, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Đức Liễu, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25417",
        "parent_code": "696"
    },
    "25420": {
        "name": "Thống Nhất",
        "type": "xa",
        "slug": "thong-nhat",
        "name_with_type": "Xã Thống Nhất",
        "path": "Thống Nhất, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Thống Nhất, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25420",
        "parent_code": "696"
    },
    "25423": {
        "name": "Nghĩa Trung",
        "type": "xa",
        "slug": "nghia-trung",
        "name_with_type": "Xã Nghĩa Trung",
        "path": "Nghĩa Trung, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Nghĩa Trung, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25423",
        "parent_code": "696"
    },
    "25424": {
        "name": "Nghĩa Bình",
        "type": "xa",
        "slug": "nghia-binh",
        "name_with_type": "Xã Nghĩa Bình",
        "path": "Nghĩa Bình, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Nghĩa Bình, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25424",
        "parent_code": "696"
    },
    "25426": {
        "name": "Đăng Hà",
        "type": "xa",
        "slug": "dang-ha",
        "name_with_type": "Xã Đăng Hà",
        "path": "Đăng Hà, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Đăng Hà, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25426",
        "parent_code": "696"
    },
    "25429": {
        "name": "Phước Sơn",
        "type": "xa",
        "slug": "phuoc-son",
        "name_with_type": "Xã Phước Sơn",
        "path": "Phước Sơn, Bù Đăng, Bình Phước",
        "path_with_type": "Xã Phước Sơn, Huyện Bù Đăng, Tỉnh Bình Phước",
        "code": "25429",
        "parent_code": "696"
    },
    "25432": {
        "name": "Chơn Thành",
        "type": "thi-tran",
        "slug": "chon-thanh",
        "name_with_type": "Thị trấn Chơn Thành",
        "path": "Chơn Thành, Chơn Thành, Bình Phước",
        "path_with_type": "Thị trấn Chơn Thành, Huyện Chơn Thành, Tỉnh Bình Phước",
        "code": "25432",
        "parent_code": "697"
    },
    "25433": {
        "name": "Thành Tâm",
        "type": "xa",
        "slug": "thanh-tam",
        "name_with_type": "Xã Thành Tâm",
        "path": "Thành Tâm, Chơn Thành, Bình Phước",
        "path_with_type": "Xã Thành Tâm, Huyện Chơn Thành, Tỉnh Bình Phước",
        "code": "25433",
        "parent_code": "697"
    },
    "25435": {
        "name": "Minh Lập",
        "type": "xa",
        "slug": "minh-lap",
        "name_with_type": "Xã Minh Lập",
        "path": "Minh Lập, Chơn Thành, Bình Phước",
        "path_with_type": "Xã Minh Lập, Huyện Chơn Thành, Tỉnh Bình Phước",
        "code": "25435",
        "parent_code": "697"
    },
    "25439": {
        "name": "Quang Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quang Minh",
        "path": "Quang Minh, Chơn Thành, Bình Phước",
        "path_with_type": "Xã Quang Minh, Huyện Chơn Thành, Tỉnh Bình Phước",
        "code": "25439",
        "parent_code": "697"
    },
    "25441": {
        "name": "Minh Hưng",
        "type": "xa",
        "slug": "minh-hung",
        "name_with_type": "Xã Minh Hưng",
        "path": "Minh Hưng, Chơn Thành, Bình Phước",
        "path_with_type": "Xã Minh Hưng, Huyện Chơn Thành, Tỉnh Bình Phước",
        "code": "25441",
        "parent_code": "697"
    },
    "25444": {
        "name": "Minh Long",
        "type": "xa",
        "slug": "minh-long",
        "name_with_type": "Xã Minh Long",
        "path": "Minh Long, Chơn Thành, Bình Phước",
        "path_with_type": "Xã Minh Long, Huyện Chơn Thành, Tỉnh Bình Phước",
        "code": "25444",
        "parent_code": "697"
    },
    "25447": {
        "name": "Minh Thành",
        "type": "xa",
        "slug": "minh-thanh",
        "name_with_type": "Xã Minh Thành",
        "path": "Minh Thành, Chơn Thành, Bình Phước",
        "path_with_type": "Xã Minh Thành, Huyện Chơn Thành, Tỉnh Bình Phước",
        "code": "25447",
        "parent_code": "697"
    },
    "25450": {
        "name": "Nha Bích",
        "type": "xa",
        "slug": "nha-bich",
        "name_with_type": "Xã Nha Bích",
        "path": "Nha Bích, Chơn Thành, Bình Phước",
        "path_with_type": "Xã Nha Bích, Huyện Chơn Thành, Tỉnh Bình Phước",
        "code": "25450",
        "parent_code": "697"
    },
    "25453": {
        "name": "Minh Thắng",
        "type": "xa",
        "slug": "minh-thang",
        "name_with_type": "Xã Minh Thắng",
        "path": "Minh Thắng, Chơn Thành, Bình Phước",
        "path_with_type": "Xã Minh Thắng, Huyện Chơn Thành, Tỉnh Bình Phước",
        "code": "25453",
        "parent_code": "697"
    },
    "25240": {
        "name": "Long Bình",
        "type": "xa",
        "slug": "long-binh",
        "name_with_type": "Xã Long Bình",
        "path": "Long Bình, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Long Bình, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25240",
        "parent_code": "698"
    },
    "25243": {
        "name": "Bình Tân",
        "type": "xa",
        "slug": "binh-tan",
        "name_with_type": "Xã Bình Tân",
        "path": "Bình Tân, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Bình Tân, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25243",
        "parent_code": "698"
    },
    "25244": {
        "name": "Bình Sơn",
        "type": "xa",
        "slug": "binh-son",
        "name_with_type": "Xã Bình Sơn",
        "path": "Bình Sơn, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Bình Sơn, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25244",
        "parent_code": "698"
    },
    "25246": {
        "name": "Long Hưng",
        "type": "xa",
        "slug": "long-hung",
        "name_with_type": "Xã Long Hưng",
        "path": "Long Hưng, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Long Hưng, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25246",
        "parent_code": "698"
    },
    "25250": {
        "name": "Phước Tân",
        "type": "xa",
        "slug": "phuoc-tan",
        "name_with_type": "Xã Phước Tân",
        "path": "Phước Tân, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Phước Tân, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25250",
        "parent_code": "698"
    },
    "25252": {
        "name": "Bù Nho",
        "type": "xa",
        "slug": "bu-nho",
        "name_with_type": "Xã Bù Nho",
        "path": "Bù Nho, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Bù Nho, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25252",
        "parent_code": "698"
    },
    "25255": {
        "name": "Long Hà",
        "type": "xa",
        "slug": "long-ha",
        "name_with_type": "Xã Long Hà",
        "path": "Long Hà, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Long Hà, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25255",
        "parent_code": "698"
    },
    "25258": {
        "name": "Long Tân",
        "type": "xa",
        "slug": "long-tan",
        "name_with_type": "Xã Long Tân",
        "path": "Long Tân, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Long Tân, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25258",
        "parent_code": "698"
    },
    "25261": {
        "name": "Phú Trung",
        "type": "xa",
        "slug": "phu-trung",
        "name_with_type": "Xã Phú Trung",
        "path": "Phú Trung, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Phú Trung, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25261",
        "parent_code": "698"
    },
    "25264": {
        "name": "Phú Riềng",
        "type": "xa",
        "slug": "phu-rieng",
        "name_with_type": "Xã Phú Riềng",
        "path": "Phú Riềng, Phú Riềng, Bình Phước",
        "path_with_type": "Xã Phú Riềng, Huyện Phú Riềng, Tỉnh Bình Phước",
        "code": "25264",
        "parent_code": "698"
    },
    "11950": {
        "name": "Lam Sơn",
        "type": "phuong",
        "slug": "lam-son",
        "name_with_type": "Phường Lam Sơn",
        "path": "Lam Sơn, Hưng Yên, Hưng Yên",
        "path_with_type": "Phường Lam Sơn, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11950",
        "parent_code": "323"
    },
    "11953": {
        "name": "Hiến Nam",
        "type": "phuong",
        "slug": "hien-nam",
        "name_with_type": "Phường Hiến Nam",
        "path": "Hiến Nam, Hưng Yên, Hưng Yên",
        "path_with_type": "Phường Hiến Nam, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11953",
        "parent_code": "323"
    },
    "11956": {
        "name": "An Tảo",
        "type": "phuong",
        "slug": "an-tao",
        "name_with_type": "Phường An Tảo",
        "path": "An Tảo, Hưng Yên, Hưng Yên",
        "path_with_type": "Phường An Tảo, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11956",
        "parent_code": "323"
    },
    "11959": {
        "name": "Lê Lợi",
        "type": "phuong",
        "slug": "le-loi",
        "name_with_type": "Phường Lê Lợi",
        "path": "Lê Lợi, Hưng Yên, Hưng Yên",
        "path_with_type": "Phường Lê Lợi, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11959",
        "parent_code": "323"
    },
    "11962": {
        "name": "Minh Khai",
        "type": "phuong",
        "slug": "minh-khai",
        "name_with_type": "Phường Minh Khai",
        "path": "Minh Khai, Hưng Yên, Hưng Yên",
        "path_with_type": "Phường Minh Khai, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11962",
        "parent_code": "323"
    },
    "11965": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Hưng Yên, Hưng Yên",
        "path_with_type": "Phường Quang Trung, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11965",
        "parent_code": "323"
    },
    "11968": {
        "name": "Hồng Châu",
        "type": "phuong",
        "slug": "hong-chau",
        "name_with_type": "Phường Hồng Châu",
        "path": "Hồng Châu, Hưng Yên, Hưng Yên",
        "path_with_type": "Phường Hồng Châu, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11968",
        "parent_code": "323"
    },
    "11971": {
        "name": "Trung Nghĩa",
        "type": "xa",
        "slug": "trung-nghia",
        "name_with_type": "Xã Trung Nghĩa",
        "path": "Trung Nghĩa, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Trung Nghĩa, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11971",
        "parent_code": "323"
    },
    "11974": {
        "name": "Liên Phương",
        "type": "xa",
        "slug": "lien-phuong",
        "name_with_type": "Xã Liên Phương",
        "path": "Liên Phương, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Liên Phương, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11974",
        "parent_code": "323"
    },
    "11977": {
        "name": "Hồng Nam",
        "type": "xa",
        "slug": "hong-nam",
        "name_with_type": "Xã Hồng Nam",
        "path": "Hồng Nam, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Hồng Nam, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11977",
        "parent_code": "323"
    },
    "11980": {
        "name": "Quảng Châu",
        "type": "xa",
        "slug": "quang-chau",
        "name_with_type": "Xã Quảng Châu",
        "path": "Quảng Châu, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Quảng Châu, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11980",
        "parent_code": "323"
    },
    "11983": {
        "name": "Bảo Khê",
        "type": "xa",
        "slug": "bao-khe",
        "name_with_type": "Xã Bảo Khê",
        "path": "Bảo Khê, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Bảo Khê, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "11983",
        "parent_code": "323"
    },
    "12331": {
        "name": "Phú Cường",
        "type": "xa",
        "slug": "phu-cuong",
        "name_with_type": "Xã Phú Cường",
        "path": "Phú Cường, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Phú Cường, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "12331",
        "parent_code": "323"
    },
    "12334": {
        "name": "Hùng Cường",
        "type": "xa",
        "slug": "hung-cuong",
        "name_with_type": "Xã Hùng Cường",
        "path": "Hùng Cường, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Hùng Cường, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "12334",
        "parent_code": "323"
    },
    "12382": {
        "name": "Phương Chiểu",
        "type": "xa",
        "slug": "phuong-chieu",
        "name_with_type": "Xã Phương Chiểu",
        "path": "Phương Chiểu, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Phương Chiểu, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "12382",
        "parent_code": "323"
    },
    "12385": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Tân Hưng, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "12385",
        "parent_code": "323"
    },
    "12388": {
        "name": "Hoàng Hanh",
        "type": "xa",
        "slug": "hoang-hanh",
        "name_with_type": "Xã Hoàng Hanh",
        "path": "Hoàng Hanh, Hưng Yên, Hưng Yên",
        "path_with_type": "Xã Hoàng Hanh, Thành phố Hưng Yên, Tỉnh Hưng Yên",
        "code": "12388",
        "parent_code": "323"
    },
    "11986": {
        "name": "Như Quỳnh",
        "type": "thi-tran",
        "slug": "nhu-quynh",
        "name_with_type": "Thị trấn Như Quỳnh",
        "path": "Như Quỳnh, Văn Lâm, Hưng Yên",
        "path_with_type": "Thị trấn Như Quỳnh, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "11986",
        "parent_code": "325"
    },
    "11989": {
        "name": "Lạc Đạo",
        "type": "xa",
        "slug": "lac-dao",
        "name_with_type": "Xã Lạc Đạo",
        "path": "Lạc Đạo, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Lạc Đạo, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "11989",
        "parent_code": "325"
    },
    "11992": {
        "name": "Chỉ Đạo",
        "type": "xa",
        "slug": "chi-dao",
        "name_with_type": "Xã Chỉ Đạo",
        "path": "Chỉ Đạo, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Chỉ Đạo, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "11992",
        "parent_code": "325"
    },
    "11995": {
        "name": "Đại Đồng",
        "type": "xa",
        "slug": "dai-dong",
        "name_with_type": "Xã Đại Đồng",
        "path": "Đại Đồng, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Đại Đồng, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "11995",
        "parent_code": "325"
    },
    "11998": {
        "name": "Việt Hưng",
        "type": "xa",
        "slug": "viet-hung",
        "name_with_type": "Xã Việt Hưng",
        "path": "Việt Hưng, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Việt Hưng, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "11998",
        "parent_code": "325"
    },
    "12001": {
        "name": "Tân Quang",
        "type": "xa",
        "slug": "tan-quang",
        "name_with_type": "Xã Tân Quang",
        "path": "Tân Quang, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Tân Quang, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "12001",
        "parent_code": "325"
    },
    "12004": {
        "name": "Đình Dù",
        "type": "xa",
        "slug": "dinh-du",
        "name_with_type": "Xã Đình Dù",
        "path": "Đình Dù, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Đình Dù, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "12004",
        "parent_code": "325"
    },
    "12007": {
        "name": "Minh Hải",
        "type": "xa",
        "slug": "minh-hai",
        "name_with_type": "Xã Minh Hải",
        "path": "Minh Hải, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Minh Hải, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "12007",
        "parent_code": "325"
    },
    "12010": {
        "name": "Lương Tài",
        "type": "xa",
        "slug": "luong-tai",
        "name_with_type": "Xã Lương Tài",
        "path": "Lương Tài, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Lương Tài, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "12010",
        "parent_code": "325"
    },
    "12013": {
        "name": "Trưng Trắc",
        "type": "xa",
        "slug": "trung-trac",
        "name_with_type": "Xã Trưng Trắc",
        "path": "Trưng Trắc, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Trưng Trắc, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "12013",
        "parent_code": "325"
    },
    "12016": {
        "name": "Lạc Hồng",
        "type": "xa",
        "slug": "lac-hong",
        "name_with_type": "Xã Lạc Hồng",
        "path": "Lạc Hồng, Văn Lâm, Hưng Yên",
        "path_with_type": "Xã Lạc Hồng, Huyện Văn Lâm, Tỉnh Hưng Yên",
        "code": "12016",
        "parent_code": "325"
    },
    "12019": {
        "name": "Văn Giang",
        "type": "thi-tran",
        "slug": "van-giang",
        "name_with_type": "Thị trấn Văn Giang",
        "path": "Văn Giang, Văn Giang, Hưng Yên",
        "path_with_type": "Thị trấn Văn Giang, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12019",
        "parent_code": "326"
    },
    "12022": {
        "name": "Xuân Quan",
        "type": "xa",
        "slug": "xuan-quan",
        "name_with_type": "Xã Xuân Quan",
        "path": "Xuân Quan, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Xuân Quan, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12022",
        "parent_code": "326"
    },
    "12025": {
        "name": "Cửu Cao",
        "type": "xa",
        "slug": "cuu-cao",
        "name_with_type": "Xã Cửu Cao",
        "path": "Cửu Cao, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Cửu Cao, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12025",
        "parent_code": "326"
    },
    "12028": {
        "name": "Phụng Công",
        "type": "xa",
        "slug": "phung-cong",
        "name_with_type": "Xã Phụng Công",
        "path": "Phụng Công, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Phụng Công, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12028",
        "parent_code": "326"
    },
    "12031": {
        "name": "Nghĩa Trụ",
        "type": "xa",
        "slug": "nghia-tru",
        "name_with_type": "Xã Nghĩa Trụ",
        "path": "Nghĩa Trụ, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Nghĩa Trụ, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12031",
        "parent_code": "326"
    },
    "12034": {
        "name": "Long Hưng",
        "type": "xa",
        "slug": "long-hung",
        "name_with_type": "Xã Long Hưng",
        "path": "Long Hưng, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Long Hưng, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12034",
        "parent_code": "326"
    },
    "12037": {
        "name": "Vĩnh Khúc",
        "type": "xa",
        "slug": "vinh-khuc",
        "name_with_type": "Xã Vĩnh Khúc",
        "path": "Vĩnh Khúc, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Vĩnh Khúc, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12037",
        "parent_code": "326"
    },
    "12040": {
        "name": "Liên Nghĩa",
        "type": "xa",
        "slug": "lien-nghia",
        "name_with_type": "Xã Liên Nghĩa",
        "path": "Liên Nghĩa, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Liên Nghĩa, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12040",
        "parent_code": "326"
    },
    "12043": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Tân Tiến, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12043",
        "parent_code": "326"
    },
    "12046": {
        "name": "Thắng Lợi",
        "type": "xa",
        "slug": "thang-loi",
        "name_with_type": "Xã Thắng Lợi",
        "path": "Thắng Lợi, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Thắng Lợi, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12046",
        "parent_code": "326"
    },
    "12049": {
        "name": "Mễ Sở",
        "type": "xa",
        "slug": "me-so",
        "name_with_type": "Xã Mễ Sở",
        "path": "Mễ Sở, Văn Giang, Hưng Yên",
        "path_with_type": "Xã Mễ Sở, Huyện Văn Giang, Tỉnh Hưng Yên",
        "code": "12049",
        "parent_code": "326"
    },
    "12052": {
        "name": "Yên Mỹ",
        "type": "thi-tran",
        "slug": "yen-my",
        "name_with_type": "Thị trấn Yên Mỹ",
        "path": "Yên Mỹ, Yên Mỹ, Hưng Yên",
        "path_with_type": "Thị trấn Yên Mỹ, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12052",
        "parent_code": "327"
    },
    "12055": {
        "name": "Giai Phạm",
        "type": "xa",
        "slug": "giai-pham",
        "name_with_type": "Xã Giai Phạm",
        "path": "Giai Phạm, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Giai Phạm, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12055",
        "parent_code": "327"
    },
    "12058": {
        "name": "Nghĩa Hiệp",
        "type": "xa",
        "slug": "nghia-hiep",
        "name_with_type": "Xã Nghĩa Hiệp",
        "path": "Nghĩa Hiệp, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Nghĩa Hiệp, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12058",
        "parent_code": "327"
    },
    "12061": {
        "name": "Đồng Than",
        "type": "xa",
        "slug": "dong-than",
        "name_with_type": "Xã Đồng Than",
        "path": "Đồng Than, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Đồng Than, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12061",
        "parent_code": "327"
    },
    "12064": {
        "name": "Ngọc Long",
        "type": "xa",
        "slug": "ngoc-long",
        "name_with_type": "Xã Ngọc Long",
        "path": "Ngọc Long, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Ngọc Long, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12064",
        "parent_code": "327"
    },
    "12067": {
        "name": "Liêu Xá",
        "type": "xa",
        "slug": "lieu-xa",
        "name_with_type": "Xã Liêu Xá",
        "path": "Liêu Xá, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Liêu Xá, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12067",
        "parent_code": "327"
    },
    "12070": {
        "name": "Hoàn Long",
        "type": "xa",
        "slug": "hoan-long",
        "name_with_type": "Xã Hoàn Long",
        "path": "Hoàn Long, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Hoàn Long, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12070",
        "parent_code": "327"
    },
    "12073": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Tân Lập, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12073",
        "parent_code": "327"
    },
    "12076": {
        "name": "Thanh Long",
        "type": "xa",
        "slug": "thanh-long",
        "name_with_type": "Xã Thanh Long",
        "path": "Thanh Long, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Thanh Long, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12076",
        "parent_code": "327"
    },
    "12079": {
        "name": "Yên Phú",
        "type": "xa",
        "slug": "yen-phu",
        "name_with_type": "Xã Yên Phú",
        "path": "Yên Phú, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Yên Phú, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12079",
        "parent_code": "327"
    },
    "12082": {
        "name": "Việt Cường",
        "type": "xa",
        "slug": "viet-cuong",
        "name_with_type": "Xã Việt Cường",
        "path": "Việt Cường, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Việt Cường, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12082",
        "parent_code": "327"
    },
    "12085": {
        "name": "Trung Hòa",
        "type": "xa",
        "slug": "trung-hoa",
        "name_with_type": "Xã Trung Hòa",
        "path": "Trung Hòa, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Trung Hòa, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12085",
        "parent_code": "327"
    },
    "12088": {
        "name": "Yên Hòa",
        "type": "xa",
        "slug": "yen-hoa",
        "name_with_type": "Xã Yên Hòa",
        "path": "Yên Hòa, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Yên Hòa, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12088",
        "parent_code": "327"
    },
    "12091": {
        "name": "Minh Châu",
        "type": "xa",
        "slug": "minh-chau",
        "name_with_type": "Xã Minh Châu",
        "path": "Minh Châu, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Minh Châu, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12091",
        "parent_code": "327"
    },
    "12094": {
        "name": "Trung Hưng",
        "type": "xa",
        "slug": "trung-hung",
        "name_with_type": "Xã Trung Hưng",
        "path": "Trung Hưng, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Trung Hưng, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12094",
        "parent_code": "327"
    },
    "12097": {
        "name": "Lý Thường Kiệt",
        "type": "xa",
        "slug": "ly-thuong-kiet",
        "name_with_type": "Xã Lý Thường Kiệt",
        "path": "Lý Thường Kiệt, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Lý Thường Kiệt, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12097",
        "parent_code": "327"
    },
    "12100": {
        "name": "Tân Việt",
        "type": "xa",
        "slug": "tan-viet",
        "name_with_type": "Xã Tân Việt",
        "path": "Tân Việt, Yên Mỹ, Hưng Yên",
        "path_with_type": "Xã Tân Việt, Huyện Yên Mỹ, Tỉnh Hưng Yên",
        "code": "12100",
        "parent_code": "327"
    },
    "12103": {
        "name": "Bần Yên Nhân",
        "type": "thi-tran",
        "slug": "ban-yen-nhan",
        "name_with_type": "Thị trấn Bần Yên Nhân",
        "path": "Bần Yên Nhân, Mỹ Hào, Hưng Yên",
        "path_with_type": "Thị trấn Bần Yên Nhân, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12103",
        "parent_code": "328"
    },
    "12106": {
        "name": "Phan Đình Phùng",
        "type": "xa",
        "slug": "phan-dinh-phung",
        "name_with_type": "Xã Phan Đình Phùng",
        "path": "Phan Đình Phùng, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Phan Đình Phùng, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12106",
        "parent_code": "328"
    },
    "12109": {
        "name": "Cẩm Xá",
        "type": "xa",
        "slug": "cam-xa",
        "name_with_type": "Xã Cẩm Xá",
        "path": "Cẩm Xá, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Cẩm Xá, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12109",
        "parent_code": "328"
    },
    "12112": {
        "name": "Dương Quang",
        "type": "xa",
        "slug": "duong-quang",
        "name_with_type": "Xã Dương Quang",
        "path": "Dương Quang, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Dương Quang, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12112",
        "parent_code": "328"
    },
    "12115": {
        "name": "Hòa Phong",
        "type": "xa",
        "slug": "hoa-phong",
        "name_with_type": "Xã Hòa Phong",
        "path": "Hòa Phong, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Hòa Phong, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12115",
        "parent_code": "328"
    },
    "12118": {
        "name": "Nhân Hòa",
        "type": "xa",
        "slug": "nhan-hoa",
        "name_with_type": "Xã Nhân Hòa",
        "path": "Nhân Hòa, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Nhân Hòa, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12118",
        "parent_code": "328"
    },
    "12121": {
        "name": "Dị Sử",
        "type": "xa",
        "slug": "di-su",
        "name_with_type": "Xã Dị Sử",
        "path": "Dị Sử, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Dị Sử, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12121",
        "parent_code": "328"
    },
    "12124": {
        "name": "Bạch Sam",
        "type": "xa",
        "slug": "bach-sam",
        "name_with_type": "Xã Bạch Sam",
        "path": "Bạch Sam, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Bạch Sam, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12124",
        "parent_code": "328"
    },
    "12127": {
        "name": "Minh Đức",
        "type": "xa",
        "slug": "minh-duc",
        "name_with_type": "Xã Minh Đức",
        "path": "Minh Đức, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Minh Đức, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12127",
        "parent_code": "328"
    },
    "12130": {
        "name": "Phùng Chí Kiên",
        "type": "xa",
        "slug": "phung-chi-kien",
        "name_with_type": "Xã Phùng Chí Kiên",
        "path": "Phùng Chí Kiên, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Phùng Chí Kiên, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12130",
        "parent_code": "328"
    },
    "12133": {
        "name": "Xuân Dục",
        "type": "xa",
        "slug": "xuan-duc",
        "name_with_type": "Xã Xuân Dục",
        "path": "Xuân Dục, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Xuân Dục, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12133",
        "parent_code": "328"
    },
    "12136": {
        "name": "Ngọc Lâm",
        "type": "xa",
        "slug": "ngoc-lam",
        "name_with_type": "Xã Ngọc Lâm",
        "path": "Ngọc Lâm, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Ngọc Lâm, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12136",
        "parent_code": "328"
    },
    "12139": {
        "name": "Hưng Long",
        "type": "xa",
        "slug": "hung-long",
        "name_with_type": "Xã Hưng Long",
        "path": "Hưng Long, Mỹ Hào, Hưng Yên",
        "path_with_type": "Xã Hưng Long, Huyện Mỹ Hào, Tỉnh Hưng Yên",
        "code": "12139",
        "parent_code": "328"
    },
    "12142": {
        "name": "Ân Thi",
        "type": "thi-tran",
        "slug": "an-thi",
        "name_with_type": "Thị trấn Ân Thi",
        "path": "Ân Thi, Ân Thi, Hưng Yên",
        "path_with_type": "Thị trấn Ân Thi, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12142",
        "parent_code": "329"
    },
    "12145": {
        "name": "Phù Ủng",
        "type": "xa",
        "slug": "phu-ung",
        "name_with_type": "Xã Phù Ủng",
        "path": "Phù Ủng, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Phù Ủng, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12145",
        "parent_code": "329"
    },
    "12148": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Bắc Sơn, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12148",
        "parent_code": "329"
    },
    "12151": {
        "name": "Bãi Sậy",
        "type": "xa",
        "slug": "bai-say",
        "name_with_type": "Xã Bãi Sậy",
        "path": "Bãi Sậy, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Bãi Sậy, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12151",
        "parent_code": "329"
    },
    "12154": {
        "name": "Đào Dương",
        "type": "xa",
        "slug": "dao-duong",
        "name_with_type": "Xã Đào Dương",
        "path": "Đào Dương, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Đào Dương, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12154",
        "parent_code": "329"
    },
    "12157": {
        "name": "Tân Phúc",
        "type": "xa",
        "slug": "tan-phuc",
        "name_with_type": "Xã Tân Phúc",
        "path": "Tân Phúc, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Tân Phúc, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12157",
        "parent_code": "329"
    },
    "12160": {
        "name": "Vân Du",
        "type": "xa",
        "slug": "van-du",
        "name_with_type": "Xã Vân Du",
        "path": "Vân Du, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Vân Du, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12160",
        "parent_code": "329"
    },
    "12163": {
        "name": "Quang Vinh",
        "type": "xa",
        "slug": "quang-vinh",
        "name_with_type": "Xã Quang Vinh",
        "path": "Quang Vinh, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Quang Vinh, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12163",
        "parent_code": "329"
    },
    "12166": {
        "name": "Xuân Trúc",
        "type": "xa",
        "slug": "xuan-truc",
        "name_with_type": "Xã Xuân Trúc",
        "path": "Xuân Trúc, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Xuân Trúc, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12166",
        "parent_code": "329"
    },
    "12169": {
        "name": "Hoàng Hoa Thám",
        "type": "xa",
        "slug": "hoang-hoa-tham",
        "name_with_type": "Xã Hoàng Hoa Thám",
        "path": "Hoàng Hoa Thám, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Hoàng Hoa Thám, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12169",
        "parent_code": "329"
    },
    "12172": {
        "name": "Quảng Lãng",
        "type": "xa",
        "slug": "quang-lang",
        "name_with_type": "Xã Quảng Lãng",
        "path": "Quảng Lãng, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Quảng Lãng, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12172",
        "parent_code": "329"
    },
    "12175": {
        "name": "Văn Nhuệ",
        "type": "xa",
        "slug": "van-nhue",
        "name_with_type": "Xã Văn Nhuệ",
        "path": "Văn Nhuệ, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Văn Nhuệ, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12175",
        "parent_code": "329"
    },
    "12178": {
        "name": "Đặng Lễ",
        "type": "xa",
        "slug": "dang-le",
        "name_with_type": "Xã Đặng Lễ",
        "path": "Đặng Lễ, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Đặng Lễ, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12178",
        "parent_code": "329"
    },
    "12181": {
        "name": "Cẩm Ninh",
        "type": "xa",
        "slug": "cam-ninh",
        "name_with_type": "Xã Cẩm Ninh",
        "path": "Cẩm Ninh, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Cẩm Ninh, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12181",
        "parent_code": "329"
    },
    "12184": {
        "name": "Nguyễn Trãi",
        "type": "xa",
        "slug": "nguyen-trai",
        "name_with_type": "Xã Nguyễn Trãi",
        "path": "Nguyễn Trãi, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Nguyễn Trãi, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12184",
        "parent_code": "329"
    },
    "12187": {
        "name": "Đa Lộc",
        "type": "xa",
        "slug": "da-loc",
        "name_with_type": "Xã Đa Lộc",
        "path": "Đa Lộc, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Đa Lộc, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12187",
        "parent_code": "329"
    },
    "12190": {
        "name": "Hồ Tùng Mậu",
        "type": "xa",
        "slug": "ho-tung-mau",
        "name_with_type": "Xã Hồ Tùng Mậu",
        "path": "Hồ Tùng Mậu, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Hồ Tùng Mậu, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12190",
        "parent_code": "329"
    },
    "12193": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Tiền Phong, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12193",
        "parent_code": "329"
    },
    "12196": {
        "name": "Hồng Vân",
        "type": "xa",
        "slug": "hong-van",
        "name_with_type": "Xã Hồng Vân",
        "path": "Hồng Vân, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Hồng Vân, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12196",
        "parent_code": "329"
    },
    "12199": {
        "name": "Hồng Quang",
        "type": "xa",
        "slug": "hong-quang",
        "name_with_type": "Xã Hồng Quang",
        "path": "Hồng Quang, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Hồng Quang, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12199",
        "parent_code": "329"
    },
    "12202": {
        "name": "Hạ Lễ",
        "type": "xa",
        "slug": "ha-le",
        "name_with_type": "Xã Hạ Lễ",
        "path": "Hạ Lễ, Ân Thi, Hưng Yên",
        "path_with_type": "Xã Hạ Lễ, Huyện Ân Thi, Tỉnh Hưng Yên",
        "code": "12202",
        "parent_code": "329"
    },
    "12205": {
        "name": "Khoái Châu",
        "type": "thi-tran",
        "slug": "khoai-chau",
        "name_with_type": "Thị trấn Khoái Châu",
        "path": "Khoái Châu, Khoái Châu, Hưng Yên",
        "path_with_type": "Thị trấn Khoái Châu, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12205",
        "parent_code": "330"
    },
    "12208": {
        "name": "Đông Tảo",
        "type": "xa",
        "slug": "dong-tao",
        "name_with_type": "Xã Đông Tảo",
        "path": "Đông Tảo, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Đông Tảo, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12208",
        "parent_code": "330"
    },
    "12211": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Bình Minh, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12211",
        "parent_code": "330"
    },
    "12214": {
        "name": "Dạ Trạch",
        "type": "xa",
        "slug": "da-trach",
        "name_with_type": "Xã Dạ Trạch",
        "path": "Dạ Trạch, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Dạ Trạch, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12214",
        "parent_code": "330"
    },
    "12217": {
        "name": "Hàm Tử",
        "type": "xa",
        "slug": "ham-tu",
        "name_with_type": "Xã Hàm Tử",
        "path": "Hàm Tử, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Hàm Tử, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12217",
        "parent_code": "330"
    },
    "12220": {
        "name": "Ông Đình",
        "type": "xa",
        "slug": "ong-dinh",
        "name_with_type": "Xã Ông Đình",
        "path": "Ông Đình, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Ông Đình, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12220",
        "parent_code": "330"
    },
    "12223": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Tân Dân, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12223",
        "parent_code": "330"
    },
    "12226": {
        "name": "Tứ Dân",
        "type": "xa",
        "slug": "tu-dan",
        "name_with_type": "Xã Tứ Dân",
        "path": "Tứ Dân, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Tứ Dân, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12226",
        "parent_code": "330"
    },
    "12229": {
        "name": "An Vĩ",
        "type": "xa",
        "slug": "an-vi",
        "name_with_type": "Xã An Vĩ",
        "path": "An Vĩ, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã An Vĩ, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12229",
        "parent_code": "330"
    },
    "12232": {
        "name": "Đông Kết",
        "type": "xa",
        "slug": "dong-ket",
        "name_with_type": "Xã Đông Kết",
        "path": "Đông Kết, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Đông Kết, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12232",
        "parent_code": "330"
    },
    "12235": {
        "name": "Bình Kiều",
        "type": "xa",
        "slug": "binh-kieu",
        "name_with_type": "Xã Bình Kiều",
        "path": "Bình Kiều, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Bình Kiều, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12235",
        "parent_code": "330"
    },
    "12238": {
        "name": "Dân Tiến",
        "type": "xa",
        "slug": "dan-tien",
        "name_with_type": "Xã Dân Tiến",
        "path": "Dân Tiến, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Dân Tiến, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12238",
        "parent_code": "330"
    },
    "12241": {
        "name": "Đồng Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đồng Tiến",
        "path": "Đồng Tiến, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Đồng Tiến, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12241",
        "parent_code": "330"
    },
    "12244": {
        "name": "Hồng Tiến",
        "type": "xa",
        "slug": "hong-tien",
        "name_with_type": "Xã Hồng Tiến",
        "path": "Hồng Tiến, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Hồng Tiến, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12244",
        "parent_code": "330"
    },
    "12247": {
        "name": "Tân Châu",
        "type": "xa",
        "slug": "tan-chau",
        "name_with_type": "Xã Tân Châu",
        "path": "Tân Châu, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Tân Châu, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12247",
        "parent_code": "330"
    },
    "12250": {
        "name": "Liên Khê",
        "type": "xa",
        "slug": "lien-khe",
        "name_with_type": "Xã Liên Khê",
        "path": "Liên Khê, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Liên Khê, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12250",
        "parent_code": "330"
    },
    "12253": {
        "name": "Phùng Hưng",
        "type": "xa",
        "slug": "phung-hung",
        "name_with_type": "Xã Phùng Hưng",
        "path": "Phùng Hưng, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Phùng Hưng, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12253",
        "parent_code": "330"
    },
    "12256": {
        "name": "Việt Hòa",
        "type": "xa",
        "slug": "viet-hoa",
        "name_with_type": "Xã Việt Hòa",
        "path": "Việt Hòa, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Việt Hòa, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12256",
        "parent_code": "330"
    },
    "12259": {
        "name": "Đông Ninh",
        "type": "xa",
        "slug": "dong-ninh",
        "name_with_type": "Xã Đông Ninh",
        "path": "Đông Ninh, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Đông Ninh, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12259",
        "parent_code": "330"
    },
    "12262": {
        "name": "Đại Tập",
        "type": "xa",
        "slug": "dai-tap",
        "name_with_type": "Xã Đại Tập",
        "path": "Đại Tập, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Đại Tập, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12262",
        "parent_code": "330"
    },
    "12265": {
        "name": "Chí Tân",
        "type": "xa",
        "slug": "chi-tan",
        "name_with_type": "Xã Chí Tân",
        "path": "Chí Tân, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Chí Tân, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12265",
        "parent_code": "330"
    },
    "12268": {
        "name": "Đại Hưng",
        "type": "xa",
        "slug": "dai-hung",
        "name_with_type": "Xã Đại Hưng",
        "path": "Đại Hưng, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Đại Hưng, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12268",
        "parent_code": "330"
    },
    "12271": {
        "name": "Thuần Hưng",
        "type": "xa",
        "slug": "thuan-hung",
        "name_with_type": "Xã Thuần Hưng",
        "path": "Thuần Hưng, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Thuần Hưng, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12271",
        "parent_code": "330"
    },
    "12274": {
        "name": "Thành Công",
        "type": "xa",
        "slug": "thanh-cong",
        "name_with_type": "Xã Thành Công",
        "path": "Thành Công, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Thành Công, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12274",
        "parent_code": "330"
    },
    "12277": {
        "name": "Nhuế Dương",
        "type": "xa",
        "slug": "nhue-duong",
        "name_with_type": "Xã Nhuế Dương",
        "path": "Nhuế Dương, Khoái Châu, Hưng Yên",
        "path_with_type": "Xã Nhuế Dương, Huyện Khoái Châu, Tỉnh Hưng Yên",
        "code": "12277",
        "parent_code": "330"
    },
    "12280": {
        "name": "Lương Bằng",
        "type": "thi-tran",
        "slug": "luong-bang",
        "name_with_type": "Thị trấn Lương Bằng",
        "path": "Lương Bằng, Kim Động, Hưng Yên",
        "path_with_type": "Thị trấn Lương Bằng, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12280",
        "parent_code": "331"
    },
    "12283": {
        "name": "Nghĩa Dân",
        "type": "xa",
        "slug": "nghia-dan",
        "name_with_type": "Xã Nghĩa Dân",
        "path": "Nghĩa Dân, Kim Động, Hưng Yên",
        "path_with_type": "Xã Nghĩa Dân, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12283",
        "parent_code": "331"
    },
    "12286": {
        "name": "Toàn Thắng",
        "type": "xa",
        "slug": "toan-thang",
        "name_with_type": "Xã Toàn Thắng",
        "path": "Toàn Thắng, Kim Động, Hưng Yên",
        "path_with_type": "Xã Toàn Thắng, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12286",
        "parent_code": "331"
    },
    "12289": {
        "name": "Vĩnh Xá",
        "type": "xa",
        "slug": "vinh-xa",
        "name_with_type": "Xã Vĩnh Xá",
        "path": "Vĩnh Xá, Kim Động, Hưng Yên",
        "path_with_type": "Xã Vĩnh Xá, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12289",
        "parent_code": "331"
    },
    "12292": {
        "name": "Phạm Ngũ Lão",
        "type": "xa",
        "slug": "pham-ngu-lao",
        "name_with_type": "Xã Phạm Ngũ Lão",
        "path": "Phạm Ngũ Lão, Kim Động, Hưng Yên",
        "path_with_type": "Xã Phạm Ngũ Lão, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12292",
        "parent_code": "331"
    },
    "12295": {
        "name": "Thọ Vinh",
        "type": "xa",
        "slug": "tho-vinh",
        "name_with_type": "Xã Thọ Vinh",
        "path": "Thọ Vinh, Kim Động, Hưng Yên",
        "path_with_type": "Xã Thọ Vinh, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12295",
        "parent_code": "331"
    },
    "12298": {
        "name": "Đồng Thanh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đồng Thanh",
        "path": "Đồng Thanh, Kim Động, Hưng Yên",
        "path_with_type": "Xã Đồng Thanh, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12298",
        "parent_code": "331"
    },
    "12301": {
        "name": "Song Mai",
        "type": "xa",
        "slug": "song-mai",
        "name_with_type": "Xã Song Mai",
        "path": "Song Mai, Kim Động, Hưng Yên",
        "path_with_type": "Xã Song Mai, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12301",
        "parent_code": "331"
    },
    "12304": {
        "name": "Chính Nghĩa",
        "type": "xa",
        "slug": "chinh-nghia",
        "name_with_type": "Xã Chính Nghĩa",
        "path": "Chính Nghĩa, Kim Động, Hưng Yên",
        "path_with_type": "Xã Chính Nghĩa, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12304",
        "parent_code": "331"
    },
    "12307": {
        "name": "Nhân La",
        "type": "xa",
        "slug": "nhan-la",
        "name_with_type": "Xã Nhân La",
        "path": "Nhân La, Kim Động, Hưng Yên",
        "path_with_type": "Xã Nhân La, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12307",
        "parent_code": "331"
    },
    "12310": {
        "name": "Phú Thịnh",
        "type": "xa",
        "slug": "phu-thinh",
        "name_with_type": "Xã Phú Thịnh",
        "path": "Phú Thịnh, Kim Động, Hưng Yên",
        "path_with_type": "Xã Phú Thịnh, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12310",
        "parent_code": "331"
    },
    "12313": {
        "name": "Mai Động",
        "type": "xa",
        "slug": "mai-dong",
        "name_with_type": "Xã Mai Động",
        "path": "Mai Động, Kim Động, Hưng Yên",
        "path_with_type": "Xã Mai Động, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12313",
        "parent_code": "331"
    },
    "12316": {
        "name": "Đức Hợp",
        "type": "xa",
        "slug": "duc-hop",
        "name_with_type": "Xã Đức Hợp",
        "path": "Đức Hợp, Kim Động, Hưng Yên",
        "path_with_type": "Xã Đức Hợp, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12316",
        "parent_code": "331"
    },
    "12319": {
        "name": "Hùng An",
        "type": "xa",
        "slug": "hung-an",
        "name_with_type": "Xã Hùng An",
        "path": "Hùng An, Kim Động, Hưng Yên",
        "path_with_type": "Xã Hùng An, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12319",
        "parent_code": "331"
    },
    "12322": {
        "name": "Ngọc Thanh",
        "type": "xa",
        "slug": "ngoc-thanh",
        "name_with_type": "Xã Ngọc Thanh",
        "path": "Ngọc Thanh, Kim Động, Hưng Yên",
        "path_with_type": "Xã Ngọc Thanh, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12322",
        "parent_code": "331"
    },
    "12325": {
        "name": "Vũ Xá",
        "type": "xa",
        "slug": "vu-xa",
        "name_with_type": "Xã Vũ Xá",
        "path": "Vũ Xá, Kim Động, Hưng Yên",
        "path_with_type": "Xã Vũ Xá, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12325",
        "parent_code": "331"
    },
    "12328": {
        "name": "Hiệp Cường",
        "type": "xa",
        "slug": "hiep-cuong",
        "name_with_type": "Xã Hiệp Cường",
        "path": "Hiệp Cường, Kim Động, Hưng Yên",
        "path_with_type": "Xã Hiệp Cường, Huyện Kim Động, Tỉnh Hưng Yên",
        "code": "12328",
        "parent_code": "331"
    },
    "12337": {
        "name": "Vương",
        "type": "thi-tran",
        "slug": "vuong",
        "name_with_type": "Thị trấn Vương",
        "path": "Vương, Tiên Lữ, Hưng Yên",
        "path_with_type": "Thị trấn Vương, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12337",
        "parent_code": "332"
    },
    "12340": {
        "name": "Hưng Đạo",
        "type": "xa",
        "slug": "hung-dao",
        "name_with_type": "Xã Hưng Đạo",
        "path": "Hưng Đạo, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Hưng Đạo, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12340",
        "parent_code": "332"
    },
    "12343": {
        "name": "Ngô Quyền",
        "type": "xa",
        "slug": "ngo-quyen",
        "name_with_type": "Xã Ngô Quyền",
        "path": "Ngô Quyền, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Ngô Quyền, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12343",
        "parent_code": "332"
    },
    "12346": {
        "name": "Nhật Tân",
        "type": "xa",
        "slug": "nhat-tan",
        "name_with_type": "Xã Nhật Tân",
        "path": "Nhật Tân, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Nhật Tân, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12346",
        "parent_code": "332"
    },
    "12349": {
        "name": "Dị Chế",
        "type": "xa",
        "slug": "di-che",
        "name_with_type": "Xã Dị Chế",
        "path": "Dị Chế, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Dị Chế, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12349",
        "parent_code": "332"
    },
    "12352": {
        "name": "Lệ Xá",
        "type": "xa",
        "slug": "le-xa",
        "name_with_type": "Xã Lệ Xá",
        "path": "Lệ Xá, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Lệ Xá, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12352",
        "parent_code": "332"
    },
    "12355": {
        "name": "An Viên",
        "type": "xa",
        "slug": "an-vien",
        "name_with_type": "Xã An Viên",
        "path": "An Viên, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã An Viên, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12355",
        "parent_code": "332"
    },
    "12358": {
        "name": "Đức Thắng",
        "type": "xa",
        "slug": "duc-thang",
        "name_with_type": "Xã Đức Thắng",
        "path": "Đức Thắng, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Đức Thắng, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12358",
        "parent_code": "332"
    },
    "12361": {
        "name": "Trung Dũng",
        "type": "xa",
        "slug": "trung-dung",
        "name_with_type": "Xã Trung Dũng",
        "path": "Trung Dũng, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Trung Dũng, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12361",
        "parent_code": "332"
    },
    "12364": {
        "name": "Hải Triều",
        "type": "xa",
        "slug": "hai-trieu",
        "name_with_type": "Xã Hải Triều",
        "path": "Hải Triều, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Hải Triều, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12364",
        "parent_code": "332"
    },
    "12367": {
        "name": "Thủ Sỹ",
        "type": "xa",
        "slug": "thu-sy",
        "name_with_type": "Xã Thủ Sỹ",
        "path": "Thủ Sỹ, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Thủ Sỹ, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12367",
        "parent_code": "332"
    },
    "12370": {
        "name": "Thiện Phiến",
        "type": "xa",
        "slug": "thien-phien",
        "name_with_type": "Xã Thiện Phiến",
        "path": "Thiện Phiến, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Thiện Phiến, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12370",
        "parent_code": "332"
    },
    "12373": {
        "name": "Thụy Lôi",
        "type": "xa",
        "slug": "thuy-loi",
        "name_with_type": "Xã Thụy Lôi",
        "path": "Thụy Lôi, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Thụy Lôi, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12373",
        "parent_code": "332"
    },
    "12376": {
        "name": "Cương Chính",
        "type": "xa",
        "slug": "cuong-chinh",
        "name_with_type": "Xã Cương Chính",
        "path": "Cương Chính, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Cương Chính, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12376",
        "parent_code": "332"
    },
    "12379": {
        "name": "Minh Phương",
        "type": "xa",
        "slug": "minh-phuong",
        "name_with_type": "Xã Minh Phương",
        "path": "Minh Phương, Tiên Lữ, Hưng Yên",
        "path_with_type": "Xã Minh Phương, Huyện Tiên Lữ, Tỉnh Hưng Yên",
        "code": "12379",
        "parent_code": "332"
    },
    "12391": {
        "name": "Trần Cao",
        "type": "thi-tran",
        "slug": "tran-cao",
        "name_with_type": "Thị trấn Trần Cao",
        "path": "Trần Cao, Phù Cừ, Hưng Yên",
        "path_with_type": "Thị trấn Trần Cao, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12391",
        "parent_code": "333"
    },
    "12394": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Minh Tân, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12394",
        "parent_code": "333"
    },
    "12397": {
        "name": "Phan Sào Nam",
        "type": "xa",
        "slug": "phan-sao-nam",
        "name_with_type": "Xã Phan Sào Nam",
        "path": "Phan Sào Nam, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Phan Sào Nam, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12397",
        "parent_code": "333"
    },
    "12400": {
        "name": "Quang Hưng",
        "type": "xa",
        "slug": "quang-hung",
        "name_with_type": "Xã Quang Hưng",
        "path": "Quang Hưng, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Quang Hưng, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12400",
        "parent_code": "333"
    },
    "12403": {
        "name": "Minh Hoàng",
        "type": "xa",
        "slug": "minh-hoang",
        "name_with_type": "Xã Minh Hoàng",
        "path": "Minh Hoàng, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Minh Hoàng, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12403",
        "parent_code": "333"
    },
    "12406": {
        "name": "Đoàn Đào",
        "type": "xa",
        "slug": "doan-dao",
        "name_with_type": "Xã Đoàn Đào",
        "path": "Đoàn Đào, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Đoàn Đào, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12406",
        "parent_code": "333"
    },
    "12409": {
        "name": "Tống Phan",
        "type": "xa",
        "slug": "tong-phan",
        "name_with_type": "Xã Tống Phan",
        "path": "Tống Phan, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Tống Phan, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12409",
        "parent_code": "333"
    },
    "12412": {
        "name": "Đình Cao",
        "type": "xa",
        "slug": "dinh-cao",
        "name_with_type": "Xã Đình Cao",
        "path": "Đình Cao, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Đình Cao, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12412",
        "parent_code": "333"
    },
    "12415": {
        "name": "Nhật Quang",
        "type": "xa",
        "slug": "nhat-quang",
        "name_with_type": "Xã Nhật Quang",
        "path": "Nhật Quang, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Nhật Quang, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12415",
        "parent_code": "333"
    },
    "12418": {
        "name": "Tiền Tiến",
        "type": "xa",
        "slug": "tien-tien",
        "name_with_type": "Xã Tiền Tiến",
        "path": "Tiền Tiến, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Tiền Tiến, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12418",
        "parent_code": "333"
    },
    "12421": {
        "name": "Tam Đa",
        "type": "xa",
        "slug": "tam-da",
        "name_with_type": "Xã Tam Đa",
        "path": "Tam Đa, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Tam Đa, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12421",
        "parent_code": "333"
    },
    "12424": {
        "name": "Minh Tiến",
        "type": "xa",
        "slug": "minh-tien",
        "name_with_type": "Xã Minh Tiến",
        "path": "Minh Tiến, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Minh Tiến, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12424",
        "parent_code": "333"
    },
    "12427": {
        "name": "Nguyên Hòa",
        "type": "xa",
        "slug": "nguyen-hoa",
        "name_with_type": "Xã Nguyên Hòa",
        "path": "Nguyên Hòa, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Nguyên Hòa, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12427",
        "parent_code": "333"
    },
    "12430": {
        "name": "Tống Trân",
        "type": "xa",
        "slug": "tong-tran",
        "name_with_type": "Xã Tống Trân",
        "path": "Tống Trân, Phù Cừ, Hưng Yên",
        "path_with_type": "Xã Tống Trân, Huyện Phù Cừ, Tỉnh Hưng Yên",
        "code": "12430",
        "parent_code": "333"
    },
    "14755": {
        "name": "Hàm Rồng",
        "type": "phuong",
        "slug": "ham-rong",
        "name_with_type": "Phường Hàm Rồng",
        "path": "Hàm Rồng, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Hàm Rồng, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14755",
        "parent_code": "380"
    },
    "14758": {
        "name": "Đông Thọ",
        "type": "phuong",
        "slug": "dong-tho",
        "name_with_type": "Phường Đông Thọ",
        "path": "Đông Thọ, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Đông Thọ, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14758",
        "parent_code": "380"
    },
    "14761": {
        "name": "Nam Ngạn",
        "type": "phuong",
        "slug": "nam-ngan",
        "name_with_type": "Phường Nam Ngạn",
        "path": "Nam Ngạn, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Nam Ngạn, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14761",
        "parent_code": "380"
    },
    "14764": {
        "name": "Trường Thi",
        "type": "phuong",
        "slug": "truong-thi",
        "name_with_type": "Phường Trường Thi",
        "path": "Trường Thi, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Trường Thi, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14764",
        "parent_code": "380"
    },
    "14767": {
        "name": "Điện Biên",
        "type": "phuong",
        "slug": "dien-bien",
        "name_with_type": "Phường Điện Biên",
        "path": "Điện Biên, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Điện Biên, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14767",
        "parent_code": "380"
    },
    "14770": {
        "name": "Phú Sơn",
        "type": "phuong",
        "slug": "phu-son",
        "name_with_type": "Phường Phú Sơn",
        "path": "Phú Sơn, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Phú Sơn, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14770",
        "parent_code": "380"
    },
    "14773": {
        "name": "Lam Sơn",
        "type": "phuong",
        "slug": "lam-son",
        "name_with_type": "Phường Lam Sơn",
        "path": "Lam Sơn, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Lam Sơn, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14773",
        "parent_code": "380"
    },
    "14776": {
        "name": "Ba Đình",
        "type": "phuong",
        "slug": "ba-dinh",
        "name_with_type": "Phường Ba Đình",
        "path": "Ba Đình, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Ba Đình, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14776",
        "parent_code": "380"
    },
    "14779": {
        "name": "Ngọc Trạo",
        "type": "phuong",
        "slug": "ngoc-trao",
        "name_with_type": "Phường Ngọc Trạo",
        "path": "Ngọc Trạo, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Ngọc Trạo, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14779",
        "parent_code": "380"
    },
    "14782": {
        "name": "Đông Vệ",
        "type": "phuong",
        "slug": "dong-ve",
        "name_with_type": "Phường Đông Vệ",
        "path": "Đông Vệ, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Đông Vệ, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14782",
        "parent_code": "380"
    },
    "14785": {
        "name": "Đông Sơn",
        "type": "phuong",
        "slug": "dong-son",
        "name_with_type": "Phường Đông Sơn",
        "path": "Đông Sơn, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Đông Sơn, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14785",
        "parent_code": "380"
    },
    "14788": {
        "name": "Tân Sơn",
        "type": "phuong",
        "slug": "tan-son",
        "name_with_type": "Phường Tân Sơn",
        "path": "Tân Sơn, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Tân Sơn, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14788",
        "parent_code": "380"
    },
    "14791": {
        "name": "Đông Cương",
        "type": "phuong",
        "slug": "dong-cuong",
        "name_with_type": "Phường Đông Cương",
        "path": "Đông Cương, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Đông Cương, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14791",
        "parent_code": "380"
    },
    "14794": {
        "name": "Đông Hương",
        "type": "phuong",
        "slug": "dong-huong",
        "name_with_type": "Phường Đông Hương",
        "path": "Đông Hương, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Đông Hương, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14794",
        "parent_code": "380"
    },
    "14797": {
        "name": "Đông Hải",
        "type": "phuong",
        "slug": "dong-hai",
        "name_with_type": "Phường Đông Hải",
        "path": "Đông Hải, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Đông Hải, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14797",
        "parent_code": "380"
    },
    "14800": {
        "name": "Quảng Hưng",
        "type": "phuong",
        "slug": "quang-hung",
        "name_with_type": "Phường Quảng Hưng",
        "path": "Quảng Hưng, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Quảng Hưng, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14800",
        "parent_code": "380"
    },
    "14803": {
        "name": "Quảng Thắng",
        "type": "phuong",
        "slug": "quang-thang",
        "name_with_type": "Phường Quảng Thắng",
        "path": "Quảng Thắng, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Quảng Thắng, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14803",
        "parent_code": "380"
    },
    "14806": {
        "name": "Quảng Thành",
        "type": "phuong",
        "slug": "quang-thanh",
        "name_with_type": "Phường Quảng Thành",
        "path": "Quảng Thành, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Quảng Thành, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "14806",
        "parent_code": "380"
    },
    "15850": {
        "name": "Thiệu Vân",
        "type": "xa",
        "slug": "thieu-van",
        "name_with_type": "Xã Thiệu Vân",
        "path": "Thiệu Vân, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Vân, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "15850",
        "parent_code": "380"
    },
    "15856": {
        "name": "Thiệu Khánh",
        "type": "xa",
        "slug": "thieu-khanh",
        "name_with_type": "Xã Thiệu Khánh",
        "path": "Thiệu Khánh, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Khánh, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "15856",
        "parent_code": "380"
    },
    "15859": {
        "name": "Thiệu Dương",
        "type": "xa",
        "slug": "thieu-duong",
        "name_with_type": "Xã Thiệu Dương",
        "path": "Thiệu Dương, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Dương, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "15859",
        "parent_code": "380"
    },
    "15868": {
        "name": "Tào Xuyên",
        "type": "phuong",
        "slug": "tao-xuyen",
        "name_with_type": "Phường Tào Xuyên",
        "path": "Tào Xuyên, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường Tào Xuyên, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "15868",
        "parent_code": "380"
    },
    "15913": {
        "name": "Hoằng Lý",
        "type": "xa",
        "slug": "hoang-ly",
        "name_with_type": "Xã Hoằng Lý",
        "path": "Hoằng Lý, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Lý, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "15913",
        "parent_code": "380"
    },
    "15922": {
        "name": "Hoằng Long",
        "type": "xa",
        "slug": "hoang-long",
        "name_with_type": "Xã Hoằng Long",
        "path": "Hoằng Long, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Long, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "15922",
        "parent_code": "380"
    },
    "15925": {
        "name": "Hoằng Quang",
        "type": "xa",
        "slug": "hoang-quang",
        "name_with_type": "Xã Hoằng Quang",
        "path": "Hoằng Quang, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Quang, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "15925",
        "parent_code": "380"
    },
    "15970": {
        "name": "Hoằng Đại",
        "type": "xa",
        "slug": "hoang-dai",
        "name_with_type": "Xã Hoằng Đại",
        "path": "Hoằng Đại, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Đại, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "15970",
        "parent_code": "380"
    },
    "16009": {
        "name": "Hoằng Anh",
        "type": "xa",
        "slug": "hoang-anh",
        "name_with_type": "Xã Hoằng Anh",
        "path": "Hoằng Anh, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Anh, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16009",
        "parent_code": "380"
    },
    "16379": {
        "name": "An Hoạch",
        "type": "phuong",
        "slug": "an-hoach",
        "name_with_type": "Phường An Hoạch",
        "path": "An Hoạch, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Phường An Hoạch, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16379",
        "parent_code": "380"
    },
    "16396": {
        "name": "Đông Lĩnh",
        "type": "xa",
        "slug": "dong-linh",
        "name_with_type": "Xã Đông Lĩnh",
        "path": "Đông Lĩnh, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Đông Lĩnh, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16396",
        "parent_code": "380"
    },
    "16429": {
        "name": "Đông Vinh",
        "type": "xa",
        "slug": "dong-vinh",
        "name_with_type": "Xã Đông Vinh",
        "path": "Đông Vinh, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Đông Vinh, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16429",
        "parent_code": "380"
    },
    "16432": {
        "name": "Đông Tân",
        "type": "xa",
        "slug": "dong-tan",
        "name_with_type": "Xã Đông Tân",
        "path": "Đông Tân, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Đông Tân, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16432",
        "parent_code": "380"
    },
    "16435": {
        "name": "Đông Hưng",
        "type": "xa",
        "slug": "dong-hung",
        "name_with_type": "Xã Đông Hưng",
        "path": "Đông Hưng, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Đông Hưng, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16435",
        "parent_code": "380"
    },
    "16441": {
        "name": "Quảng Thịnh",
        "type": "xa",
        "slug": "quang-thinh",
        "name_with_type": "Xã Quảng Thịnh",
        "path": "Quảng Thịnh, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Quảng Thịnh, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16441",
        "parent_code": "380"
    },
    "16459": {
        "name": "Quảng Đông",
        "type": "xa",
        "slug": "quang-dong",
        "name_with_type": "Xã Quảng Đông",
        "path": "Quảng Đông, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Quảng Đông, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16459",
        "parent_code": "380"
    },
    "16507": {
        "name": "Quảng Cát",
        "type": "xa",
        "slug": "quang-cat",
        "name_with_type": "Xã Quảng Cát",
        "path": "Quảng Cát, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Quảng Cát, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16507",
        "parent_code": "380"
    },
    "16522": {
        "name": "Quảng Phú",
        "type": "xa",
        "slug": "quang-phu",
        "name_with_type": "Xã Quảng Phú",
        "path": "Quảng Phú, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Quảng Phú, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16522",
        "parent_code": "380"
    },
    "16525": {
        "name": "Quảng Tâm",
        "type": "xa",
        "slug": "quang-tam",
        "name_with_type": "Xã Quảng Tâm",
        "path": "Quảng Tâm, Thanh Hóa, Thanh Hóa",
        "path_with_type": "Xã Quảng Tâm, Thành phố Thanh Hóa, Tỉnh Thanh Hóa",
        "code": "16525",
        "parent_code": "380"
    },
    "14809": {
        "name": "Bắc Sơn",
        "type": "phuong",
        "slug": "bac-son",
        "name_with_type": "Phường Bắc Sơn",
        "path": "Bắc Sơn, Bỉm Sơn, Thanh Hóa",
        "path_with_type": "Phường Bắc Sơn, Thị xã Bỉm Sơn, Tỉnh Thanh Hóa",
        "code": "14809",
        "parent_code": "381"
    },
    "14812": {
        "name": "Ba Đình",
        "type": "phuong",
        "slug": "ba-dinh",
        "name_with_type": "Phường Ba Đình",
        "path": "Ba Đình, Bỉm Sơn, Thanh Hóa",
        "path_with_type": "Phường Ba Đình, Thị xã Bỉm Sơn, Tỉnh Thanh Hóa",
        "code": "14812",
        "parent_code": "381"
    },
    "14815": {
        "name": "Lam Sơn",
        "type": "phuong",
        "slug": "lam-son",
        "name_with_type": "Phường Lam Sơn",
        "path": "Lam Sơn, Bỉm Sơn, Thanh Hóa",
        "path_with_type": "Phường Lam Sơn, Thị xã Bỉm Sơn, Tỉnh Thanh Hóa",
        "code": "14815",
        "parent_code": "381"
    },
    "14818": {
        "name": "Ngọc Trạo",
        "type": "phuong",
        "slug": "ngoc-trao",
        "name_with_type": "Phường Ngọc Trạo",
        "path": "Ngọc Trạo, Bỉm Sơn, Thanh Hóa",
        "path_with_type": "Phường Ngọc Trạo, Thị xã Bỉm Sơn, Tỉnh Thanh Hóa",
        "code": "14818",
        "parent_code": "381"
    },
    "14821": {
        "name": "Đông Sơn",
        "type": "phuong",
        "slug": "dong-son",
        "name_with_type": "Phường Đông Sơn",
        "path": "Đông Sơn, Bỉm Sơn, Thanh Hóa",
        "path_with_type": "Phường Đông Sơn, Thị xã Bỉm Sơn, Tỉnh Thanh Hóa",
        "code": "14821",
        "parent_code": "381"
    },
    "14823": {
        "name": "Phú Sơn",
        "type": "phuong",
        "slug": "phu-son",
        "name_with_type": "Phường Phú Sơn",
        "path": "Phú Sơn, Bỉm Sơn, Thanh Hóa",
        "path_with_type": "Phường Phú Sơn, Thị xã Bỉm Sơn, Tỉnh Thanh Hóa",
        "code": "14823",
        "parent_code": "381"
    },
    "14824": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Bỉm Sơn, Thanh Hóa",
        "path_with_type": "Xã Quang Trung, Thị xã Bỉm Sơn, Tỉnh Thanh Hóa",
        "code": "14824",
        "parent_code": "381"
    },
    "14827": {
        "name": "Hà Lan",
        "type": "xa",
        "slug": "ha-lan",
        "name_with_type": "Xã Hà Lan",
        "path": "Hà Lan, Bỉm Sơn, Thanh Hóa",
        "path_with_type": "Xã Hà Lan, Thị xã Bỉm Sơn, Tỉnh Thanh Hóa",
        "code": "14827",
        "parent_code": "381"
    },
    "14830": {
        "name": "Trung Sơn",
        "type": "phuong",
        "slug": "trung-son",
        "name_with_type": "Phường Trung Sơn",
        "path": "Trung Sơn, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Phường Trung Sơn, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "14830",
        "parent_code": "382"
    },
    "14833": {
        "name": "Bắc Sơn",
        "type": "phuong",
        "slug": "bac-son",
        "name_with_type": "Phường Bắc Sơn",
        "path": "Bắc Sơn, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Phường Bắc Sơn, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "14833",
        "parent_code": "382"
    },
    "14836": {
        "name": "Trường Sơn",
        "type": "phuong",
        "slug": "truong-son",
        "name_with_type": "Phường Trường Sơn",
        "path": "Trường Sơn, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Phường Trường Sơn, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "14836",
        "parent_code": "382"
    },
    "14839": {
        "name": "Quảng Cư",
        "type": "phuong",
        "slug": "quang-cu",
        "name_with_type": "Phường Quảng Cư",
        "path": "Quảng Cư, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Phường Quảng Cư, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "14839",
        "parent_code": "382"
    },
    "14842": {
        "name": "Quảng Tiến",
        "type": "phuong",
        "slug": "quang-tien",
        "name_with_type": "Phường Quảng Tiến",
        "path": "Quảng Tiến, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Phường Quảng Tiến, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "14842",
        "parent_code": "382"
    },
    "16513": {
        "name": "Quảng Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quảng Minh",
        "path": "Quảng Minh, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Xã Quảng Minh, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "16513",
        "parent_code": "382"
    },
    "16516": {
        "name": "Quảng Hùng",
        "type": "xa",
        "slug": "quang-hung",
        "name_with_type": "Xã Quảng Hùng",
        "path": "Quảng Hùng, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Xã Quảng Hùng, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "16516",
        "parent_code": "382"
    },
    "16528": {
        "name": "Quảng Thọ",
        "type": "phuong",
        "slug": "quang-tho",
        "name_with_type": "Phường Quảng Thọ",
        "path": "Quảng Thọ, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Phường Quảng Thọ, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "16528",
        "parent_code": "382"
    },
    "16531": {
        "name": "Quảng Châu",
        "type": "phuong",
        "slug": "quang-chau",
        "name_with_type": "Phường Quảng Châu",
        "path": "Quảng Châu, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Phường Quảng Châu, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "16531",
        "parent_code": "382"
    },
    "16534": {
        "name": "Quảng Vinh",
        "type": "phuong",
        "slug": "quang-vinh",
        "name_with_type": "Phường Quảng Vinh",
        "path": "Quảng Vinh, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Phường Quảng Vinh, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "16534",
        "parent_code": "382"
    },
    "16537": {
        "name": "Quảng Đại",
        "type": "xa",
        "slug": "quang-dai",
        "name_with_type": "Xã Quảng Đại",
        "path": "Quảng Đại, Sầm Sơn, Thanh Hóa",
        "path_with_type": "Xã Quảng Đại, Thành phố Sầm Sơn, Tỉnh Thanh Hóa",
        "code": "16537",
        "parent_code": "382"
    },
    "14845": {
        "name": "Mường Lát",
        "type": "thi-tran",
        "slug": "muong-lat",
        "name_with_type": "Thị trấn Mường Lát",
        "path": "Mường Lát, Mường Lát, Thanh Hóa",
        "path_with_type": "Thị trấn Mường Lát, Huyện Mường Lát, Tỉnh Thanh Hóa",
        "code": "14845",
        "parent_code": "384"
    },
    "14848": {
        "name": "Tam Chung",
        "type": "xa",
        "slug": "tam-chung",
        "name_with_type": "Xã Tam Chung",
        "path": "Tam Chung, Mường Lát, Thanh Hóa",
        "path_with_type": "Xã Tam Chung, Huyện Mường Lát, Tỉnh Thanh Hóa",
        "code": "14848",
        "parent_code": "384"
    },
    "14851": {
        "name": "Tén Tằn",
        "type": "xa",
        "slug": "ten-tan",
        "name_with_type": "Xã Tén Tằn",
        "path": "Tén Tằn, Mường Lát, Thanh Hóa",
        "path_with_type": "Xã Tén Tằn, Huyện Mường Lát, Tỉnh Thanh Hóa",
        "code": "14851",
        "parent_code": "384"
    },
    "14854": {
        "name": "Mường Lý",
        "type": "xa",
        "slug": "muong-ly",
        "name_with_type": "Xã Mường Lý",
        "path": "Mường Lý, Mường Lát, Thanh Hóa",
        "path_with_type": "Xã Mường Lý, Huyện Mường Lát, Tỉnh Thanh Hóa",
        "code": "14854",
        "parent_code": "384"
    },
    "14857": {
        "name": "Trung Lý",
        "type": "xa",
        "slug": "trung-ly",
        "name_with_type": "Xã Trung Lý",
        "path": "Trung Lý, Mường Lát, Thanh Hóa",
        "path_with_type": "Xã Trung Lý, Huyện Mường Lát, Tỉnh Thanh Hóa",
        "code": "14857",
        "parent_code": "384"
    },
    "14860": {
        "name": "Quang Chiểu",
        "type": "xa",
        "slug": "quang-chieu",
        "name_with_type": "Xã Quang Chiểu",
        "path": "Quang Chiểu, Mường Lát, Thanh Hóa",
        "path_with_type": "Xã Quang Chiểu, Huyện Mường Lát, Tỉnh Thanh Hóa",
        "code": "14860",
        "parent_code": "384"
    },
    "14863": {
        "name": "Pù Nhi",
        "type": "xa",
        "slug": "pu-nhi",
        "name_with_type": "Xã Pù Nhi",
        "path": "Pù Nhi, Mường Lát, Thanh Hóa",
        "path_with_type": "Xã Pù Nhi, Huyện Mường Lát, Tỉnh Thanh Hóa",
        "code": "14863",
        "parent_code": "384"
    },
    "14864": {
        "name": "Nhi Sơn",
        "type": "xa",
        "slug": "nhi-son",
        "name_with_type": "Xã Nhi Sơn",
        "path": "Nhi Sơn, Mường Lát, Thanh Hóa",
        "path_with_type": "Xã Nhi Sơn, Huyện Mường Lát, Tỉnh Thanh Hóa",
        "code": "14864",
        "parent_code": "384"
    },
    "14866": {
        "name": "Mường Chanh",
        "type": "xa",
        "slug": "muong-chanh",
        "name_with_type": "Xã Mường Chanh",
        "path": "Mường Chanh, Mường Lát, Thanh Hóa",
        "path_with_type": "Xã Mường Chanh, Huyện Mường Lát, Tỉnh Thanh Hóa",
        "code": "14866",
        "parent_code": "384"
    },
    "14869": {
        "name": "Quan Hóa",
        "type": "thi-tran",
        "slug": "quan-hoa",
        "name_with_type": "Thị trấn Quan Hóa",
        "path": "Quan Hóa, Quan Hóa, Thanh Hóa",
        "path_with_type": "Thị trấn Quan Hóa, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14869",
        "parent_code": "385"
    },
    "14872": {
        "name": "Thành Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thành Sơn",
        "path": "Thành Sơn, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Thành Sơn, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14872",
        "parent_code": "385"
    },
    "14875": {
        "name": "Trung Sơn",
        "type": "xa",
        "slug": "trung-son",
        "name_with_type": "Xã Trung Sơn",
        "path": "Trung Sơn, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Trung Sơn, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14875",
        "parent_code": "385"
    },
    "14878": {
        "name": "Phú Thanh",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thanh",
        "path": "Phú Thanh, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Phú Thanh, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14878",
        "parent_code": "385"
    },
    "14881": {
        "name": "Trung Thành",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thành",
        "path": "Trung Thành, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Trung Thành, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14881",
        "parent_code": "385"
    },
    "14884": {
        "name": "Phú Lệ",
        "type": "xa",
        "slug": "phu-le",
        "name_with_type": "Xã Phú Lệ",
        "path": "Phú Lệ, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Phú Lệ, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14884",
        "parent_code": "385"
    },
    "14887": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Phú Sơn, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14887",
        "parent_code": "385"
    },
    "14890": {
        "name": "Phú Xuân",
        "type": "xa",
        "slug": "phu-xuan",
        "name_with_type": "Xã Phú Xuân",
        "path": "Phú Xuân, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Phú Xuân, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14890",
        "parent_code": "385"
    },
    "14893": {
        "name": "Thanh Xuân",
        "type": "xa",
        "slug": "thanh-xuan",
        "name_with_type": "Xã Thanh Xuân",
        "path": "Thanh Xuân, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Thanh Xuân, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14893",
        "parent_code": "385"
    },
    "14896": {
        "name": "Hiền Chung",
        "type": "xa",
        "slug": "hien-chung",
        "name_with_type": "Xã Hiền Chung",
        "path": "Hiền Chung, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Hiền Chung, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14896",
        "parent_code": "385"
    },
    "14899": {
        "name": "Hiền Kiệt",
        "type": "xa",
        "slug": "hien-kiet",
        "name_with_type": "Xã Hiền Kiệt",
        "path": "Hiền Kiệt, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Hiền Kiệt, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14899",
        "parent_code": "385"
    },
    "14902": {
        "name": "Nam Tiến",
        "type": "xa",
        "slug": "nam-tien",
        "name_with_type": "Xã Nam Tiến",
        "path": "Nam Tiến, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Nam Tiến, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14902",
        "parent_code": "385"
    },
    "14905": {
        "name": "Hồi Xuân",
        "type": "xa",
        "slug": "hoi-xuan",
        "name_with_type": "Xã Hồi Xuân",
        "path": "Hồi Xuân, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Hồi Xuân, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14905",
        "parent_code": "385"
    },
    "14908": {
        "name": "Thiên Phủ",
        "type": "xa",
        "slug": "thien-phu",
        "name_with_type": "Xã Thiên Phủ",
        "path": "Thiên Phủ, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiên Phủ, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14908",
        "parent_code": "385"
    },
    "14911": {
        "name": "Phú Nghiêm",
        "type": "xa",
        "slug": "phu-nghiem",
        "name_with_type": "Xã Phú Nghiêm",
        "path": "Phú Nghiêm, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Phú Nghiêm, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14911",
        "parent_code": "385"
    },
    "14914": {
        "name": "Nam Xuân",
        "type": "xa",
        "slug": "nam-xuan",
        "name_with_type": "Xã Nam Xuân",
        "path": "Nam Xuân, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Nam Xuân, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14914",
        "parent_code": "385"
    },
    "14917": {
        "name": "Nam Động",
        "type": "xa",
        "slug": "nam-dong",
        "name_with_type": "Xã Nam Động",
        "path": "Nam Động, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Nam Động, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14917",
        "parent_code": "385"
    },
    "14920": {
        "name": "Xuân Phú",
        "type": "xa",
        "slug": "xuan-phu",
        "name_with_type": "Xã Xuân Phú",
        "path": "Xuân Phú, Quan Hóa, Thanh Hóa",
        "path_with_type": "Xã Xuân Phú, Huyện Quan Hóa, Tỉnh Thanh Hóa",
        "code": "14920",
        "parent_code": "385"
    },
    "14923": {
        "name": "Cành Nàng",
        "type": "thi-tran",
        "slug": "canh-nang",
        "name_with_type": "Thị trấn Cành Nàng",
        "path": "Cành Nàng, Bá Thước, Thanh Hóa",
        "path_with_type": "Thị trấn Cành Nàng, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14923",
        "parent_code": "386"
    },
    "14926": {
        "name": "Điền Thượng",
        "type": "xa",
        "slug": "dien-thuong",
        "name_with_type": "Xã Điền Thượng",
        "path": "Điền Thượng, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Điền Thượng, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14926",
        "parent_code": "386"
    },
    "14929": {
        "name": "Điền Hạ",
        "type": "xa",
        "slug": "dien-ha",
        "name_with_type": "Xã Điền Hạ",
        "path": "Điền Hạ, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Điền Hạ, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14929",
        "parent_code": "386"
    },
    "14932": {
        "name": "Điền Quang",
        "type": "xa",
        "slug": "dien-quang",
        "name_with_type": "Xã Điền Quang",
        "path": "Điền Quang, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Điền Quang, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14932",
        "parent_code": "386"
    },
    "14935": {
        "name": "Điền Trung",
        "type": "xa",
        "slug": "dien-trung",
        "name_with_type": "Xã Điền Trung",
        "path": "Điền Trung, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Điền Trung, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14935",
        "parent_code": "386"
    },
    "14938": {
        "name": "Thành Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thành Sơn",
        "path": "Thành Sơn, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Thành Sơn, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14938",
        "parent_code": "386"
    },
    "14941": {
        "name": "Lương Ngoại",
        "type": "xa",
        "slug": "luong-ngoai",
        "name_with_type": "Xã Lương Ngoại",
        "path": "Lương Ngoại, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Lương Ngoại, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14941",
        "parent_code": "386"
    },
    "14944": {
        "name": "Ái Thượng",
        "type": "xa",
        "slug": "ai-thuong",
        "name_with_type": "Xã Ái Thượng",
        "path": "Ái Thượng, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Ái Thượng, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14944",
        "parent_code": "386"
    },
    "14947": {
        "name": "Lương Nội",
        "type": "xa",
        "slug": "luong-noi",
        "name_with_type": "Xã Lương Nội",
        "path": "Lương Nội, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Lương Nội, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14947",
        "parent_code": "386"
    },
    "14950": {
        "name": "Điền Lư",
        "type": "xa",
        "slug": "dien-lu",
        "name_with_type": "Xã Điền Lư",
        "path": "Điền Lư, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Điền Lư, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14950",
        "parent_code": "386"
    },
    "14953": {
        "name": "Lương Trung",
        "type": "xa",
        "slug": "luong-trung",
        "name_with_type": "Xã Lương Trung",
        "path": "Lương Trung, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Lương Trung, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14953",
        "parent_code": "386"
    },
    "14956": {
        "name": "Lũng Niêm",
        "type": "xa",
        "slug": "lung-niem",
        "name_with_type": "Xã Lũng Niêm",
        "path": "Lũng Niêm, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Lũng Niêm, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14956",
        "parent_code": "386"
    },
    "14959": {
        "name": "Lũng Cao",
        "type": "xa",
        "slug": "lung-cao",
        "name_with_type": "Xã Lũng Cao",
        "path": "Lũng Cao, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Lũng Cao, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14959",
        "parent_code": "386"
    },
    "14962": {
        "name": "Hạ Trung",
        "type": "xa",
        "slug": "ha-trung",
        "name_with_type": "Xã Hạ Trung",
        "path": "Hạ Trung, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Hạ Trung, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14962",
        "parent_code": "386"
    },
    "14965": {
        "name": "Cổ Lũng",
        "type": "xa",
        "slug": "co-lung",
        "name_with_type": "Xã Cổ Lũng",
        "path": "Cổ Lũng, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Cổ Lũng, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14965",
        "parent_code": "386"
    },
    "14968": {
        "name": "Thành Lâm",
        "type": "xa",
        "slug": "thanh-lam",
        "name_with_type": "Xã Thành Lâm",
        "path": "Thành Lâm, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Thành Lâm, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14968",
        "parent_code": "386"
    },
    "14971": {
        "name": "Ban Công",
        "type": "xa",
        "slug": "ban-cong",
        "name_with_type": "Xã Ban Công",
        "path": "Ban Công, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Ban Công, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14971",
        "parent_code": "386"
    },
    "14974": {
        "name": "Kỳ Tân",
        "type": "xa",
        "slug": "ky-tan",
        "name_with_type": "Xã Kỳ Tân",
        "path": "Kỳ Tân, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Kỳ Tân, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14974",
        "parent_code": "386"
    },
    "14977": {
        "name": "Văn Nho",
        "type": "xa",
        "slug": "van-nho",
        "name_with_type": "Xã Văn Nho",
        "path": "Văn Nho, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Văn Nho, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14977",
        "parent_code": "386"
    },
    "14980": {
        "name": "Thiết Ống",
        "type": "xa",
        "slug": "thiet-ong",
        "name_with_type": "Xã Thiết Ống",
        "path": "Thiết Ống, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Thiết Ống, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14980",
        "parent_code": "386"
    },
    "14983": {
        "name": "Lâm Sa",
        "type": "xa",
        "slug": "lam-sa",
        "name_with_type": "Xã Lâm Sa",
        "path": "Lâm Sa, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Lâm Sa, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14983",
        "parent_code": "386"
    },
    "14986": {
        "name": "Thiết Kế",
        "type": "xa",
        "slug": "thiet-ke",
        "name_with_type": "Xã Thiết Kế",
        "path": "Thiết Kế, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Thiết Kế, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14986",
        "parent_code": "386"
    },
    "14989": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Bá Thước, Thanh Hóa",
        "path_with_type": "Xã Tân Lập, Huyện Bá Thước, Tỉnh Thanh Hóa",
        "code": "14989",
        "parent_code": "386"
    },
    "14992": {
        "name": "Quan Sơn",
        "type": "thi-tran",
        "slug": "quan-son",
        "name_with_type": "Thị trấn Quan Sơn",
        "path": "Quan Sơn, Quan Sơn, Thanh Hóa",
        "path_with_type": "Thị trấn Quan Sơn, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "14992",
        "parent_code": "387"
    },
    "14995": {
        "name": "Trung Xuân",
        "type": "xa",
        "slug": "trung-xuan",
        "name_with_type": "Xã Trung Xuân",
        "path": "Trung Xuân, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Trung Xuân, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "14995",
        "parent_code": "387"
    },
    "14998": {
        "name": "Trung Thượng",
        "type": "xa",
        "slug": "trung-thuong",
        "name_with_type": "Xã Trung Thượng",
        "path": "Trung Thượng, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Trung Thượng, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "14998",
        "parent_code": "387"
    },
    "14999": {
        "name": "Trung Tiến",
        "type": "xa",
        "slug": "trung-tien",
        "name_with_type": "Xã Trung Tiến",
        "path": "Trung Tiến, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Trung Tiến, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "14999",
        "parent_code": "387"
    },
    "15001": {
        "name": "Trung Hạ",
        "type": "xa",
        "slug": "trung-ha",
        "name_with_type": "Xã Trung Hạ",
        "path": "Trung Hạ, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Trung Hạ, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "15001",
        "parent_code": "387"
    },
    "15004": {
        "name": "Sơn Hà",
        "type": "xa",
        "slug": "son-ha",
        "name_with_type": "Xã Sơn Hà",
        "path": "Sơn Hà, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Sơn Hà, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "15004",
        "parent_code": "387"
    },
    "15007": {
        "name": "Tam Thanh",
        "type": "xa",
        "slug": "tam-thanh",
        "name_with_type": "Xã Tam Thanh",
        "path": "Tam Thanh, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Tam Thanh, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "15007",
        "parent_code": "387"
    },
    "15010": {
        "name": "Sơn Thủy",
        "type": "xa",
        "slug": "son-thuy",
        "name_with_type": "Xã Sơn Thủy",
        "path": "Sơn Thủy, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Sơn Thủy, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "15010",
        "parent_code": "387"
    },
    "15013": {
        "name": "Na Mèo",
        "type": "xa",
        "slug": "na-meo",
        "name_with_type": "Xã Na Mèo",
        "path": "Na Mèo, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Na Mèo, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "15013",
        "parent_code": "387"
    },
    "15016": {
        "name": "Sơn Lư",
        "type": "xa",
        "slug": "son-lu",
        "name_with_type": "Xã Sơn Lư",
        "path": "Sơn Lư, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Sơn Lư, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "15016",
        "parent_code": "387"
    },
    "15019": {
        "name": "Tam Lư",
        "type": "xa",
        "slug": "tam-lu",
        "name_with_type": "Xã Tam Lư",
        "path": "Tam Lư, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Tam Lư, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "15019",
        "parent_code": "387"
    },
    "15022": {
        "name": "Sơn Điện",
        "type": "xa",
        "slug": "son-dien",
        "name_with_type": "Xã Sơn Điện",
        "path": "Sơn Điện, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Sơn Điện, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "15022",
        "parent_code": "387"
    },
    "15025": {
        "name": "Mường Mìn",
        "type": "xa",
        "slug": "muong-min",
        "name_with_type": "Xã Mường Mìn",
        "path": "Mường Mìn, Quan Sơn, Thanh Hóa",
        "path_with_type": "Xã Mường Mìn, Huyện Quan Sơn, Tỉnh Thanh Hóa",
        "code": "15025",
        "parent_code": "387"
    },
    "15028": {
        "name": "Lang Chánh",
        "type": "thi-tran",
        "slug": "lang-chanh",
        "name_with_type": "Thị trấn Lang Chánh",
        "path": "Lang Chánh, Lang Chánh, Thanh Hóa",
        "path_with_type": "Thị trấn Lang Chánh, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15028",
        "parent_code": "388"
    },
    "15031": {
        "name": "Yên Khương",
        "type": "xa",
        "slug": "yen-khuong",
        "name_with_type": "Xã Yên Khương",
        "path": "Yên Khương, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Yên Khương, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15031",
        "parent_code": "388"
    },
    "15034": {
        "name": "Yên Thắng",
        "type": "xa",
        "slug": "yen-thang",
        "name_with_type": "Xã Yên Thắng",
        "path": "Yên Thắng, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Yên Thắng, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15034",
        "parent_code": "388"
    },
    "15037": {
        "name": "Trí Nang",
        "type": "xa",
        "slug": "tri-nang",
        "name_with_type": "Xã Trí Nang",
        "path": "Trí Nang, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Trí Nang, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15037",
        "parent_code": "388"
    },
    "15040": {
        "name": "Giao An",
        "type": "xa",
        "slug": "giao-an",
        "name_with_type": "Xã Giao An",
        "path": "Giao An, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Giao An, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15040",
        "parent_code": "388"
    },
    "15043": {
        "name": "Giao Thiện",
        "type": "xa",
        "slug": "giao-thien",
        "name_with_type": "Xã Giao Thiện",
        "path": "Giao Thiện, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Giao Thiện, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15043",
        "parent_code": "388"
    },
    "15046": {
        "name": "Tân Phúc",
        "type": "xa",
        "slug": "tan-phuc",
        "name_with_type": "Xã Tân Phúc",
        "path": "Tân Phúc, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Tân Phúc, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15046",
        "parent_code": "388"
    },
    "15049": {
        "name": "Tam Văn",
        "type": "xa",
        "slug": "tam-van",
        "name_with_type": "Xã Tam Văn",
        "path": "Tam Văn, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Tam Văn, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15049",
        "parent_code": "388"
    },
    "15052": {
        "name": "Lâm Phú",
        "type": "xa",
        "slug": "lam-phu",
        "name_with_type": "Xã Lâm Phú",
        "path": "Lâm Phú, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Lâm Phú, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15052",
        "parent_code": "388"
    },
    "15055": {
        "name": "Quang Hiến",
        "type": "xa",
        "slug": "quang-hien",
        "name_with_type": "Xã Quang Hiến",
        "path": "Quang Hiến, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Quang Hiến, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15055",
        "parent_code": "388"
    },
    "15058": {
        "name": "Đồng Lương",
        "type": "xa",
        "slug": "dong-luong",
        "name_with_type": "Xã Đồng Lương",
        "path": "Đồng Lương, Lang Chánh, Thanh Hóa",
        "path_with_type": "Xã Đồng Lương, Huyện Lang Chánh, Tỉnh Thanh Hóa",
        "code": "15058",
        "parent_code": "388"
    },
    "15061": {
        "name": "Ngọc Lặc",
        "type": "thi-tran",
        "slug": "ngoc-lac",
        "name_with_type": "Thị Trấn Ngọc Lặc",
        "path": "Ngọc Lặc, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Thị Trấn Ngọc Lặc, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15061",
        "parent_code": "389"
    },
    "15064": {
        "name": "Lam Sơn",
        "type": "xa",
        "slug": "lam-son",
        "name_with_type": "Xã Lam Sơn",
        "path": "Lam Sơn, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Lam Sơn, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15064",
        "parent_code": "389"
    },
    "15067": {
        "name": "Mỹ Tân",
        "type": "xa",
        "slug": "my-tan",
        "name_with_type": "Xã Mỹ Tân",
        "path": "Mỹ Tân, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Mỹ Tân, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15067",
        "parent_code": "389"
    },
    "15070": {
        "name": "Thúy Sơn",
        "type": "xa",
        "slug": "thuy-son",
        "name_with_type": "Xã Thúy Sơn",
        "path": "Thúy Sơn, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Thúy Sơn, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15070",
        "parent_code": "389"
    },
    "15073": {
        "name": "Thạch Lập",
        "type": "xa",
        "slug": "thach-lap",
        "name_with_type": "Xã Thạch Lập",
        "path": "Thạch Lập, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Thạch Lập, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15073",
        "parent_code": "389"
    },
    "15076": {
        "name": "Vân Âm",
        "type": "xa",
        "slug": "van-am",
        "name_with_type": "Xã Vân Âm",
        "path": "Vân Âm, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Vân Âm, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15076",
        "parent_code": "389"
    },
    "15079": {
        "name": "Cao Ngọc",
        "type": "xa",
        "slug": "cao-ngoc",
        "name_with_type": "Xã Cao Ngọc",
        "path": "Cao Ngọc, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Cao Ngọc, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15079",
        "parent_code": "389"
    },
    "15082": {
        "name": "Ngọc Khê",
        "type": "xa",
        "slug": "ngoc-khe",
        "name_with_type": "Xã Ngọc Khê",
        "path": "Ngọc Khê, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Ngọc Khê, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15082",
        "parent_code": "389"
    },
    "15085": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Quang Trung, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15085",
        "parent_code": "389"
    },
    "15088": {
        "name": "Đồng Thịnh",
        "type": "xa",
        "slug": "dong-thinh",
        "name_with_type": "Xã Đồng Thịnh",
        "path": "Đồng Thịnh, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Đồng Thịnh, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15088",
        "parent_code": "389"
    },
    "15091": {
        "name": "Ngọc Liên",
        "type": "xa",
        "slug": "ngoc-lien",
        "name_with_type": "Xã Ngọc Liên",
        "path": "Ngọc Liên, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Ngọc Liên, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15091",
        "parent_code": "389"
    },
    "15094": {
        "name": "Ngọc Sơn",
        "type": "xa",
        "slug": "ngoc-son",
        "name_with_type": "Xã Ngọc Sơn",
        "path": "Ngọc Sơn, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Ngọc Sơn, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15094",
        "parent_code": "389"
    },
    "15097": {
        "name": "Lộc Thịnh",
        "type": "xa",
        "slug": "loc-thinh",
        "name_with_type": "Xã Lộc Thịnh",
        "path": "Lộc Thịnh, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Lộc Thịnh, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15097",
        "parent_code": "389"
    },
    "15100": {
        "name": "Cao Thịnh",
        "type": "xa",
        "slug": "cao-thinh",
        "name_with_type": "Xã Cao Thịnh",
        "path": "Cao Thịnh, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Cao Thịnh, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15100",
        "parent_code": "389"
    },
    "15103": {
        "name": "Ngọc Trung",
        "type": "xa",
        "slug": "ngoc-trung",
        "name_with_type": "Xã Ngọc Trung",
        "path": "Ngọc Trung, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Ngọc Trung, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15103",
        "parent_code": "389"
    },
    "15106": {
        "name": "Phùng Giáo",
        "type": "xa",
        "slug": "phung-giao",
        "name_with_type": "Xã Phùng Giáo",
        "path": "Phùng Giáo, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Phùng Giáo, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15106",
        "parent_code": "389"
    },
    "15109": {
        "name": "Phùng Minh",
        "type": "xa",
        "slug": "phung-minh",
        "name_with_type": "Xã Phùng Minh",
        "path": "Phùng Minh, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Phùng Minh, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15109",
        "parent_code": "389"
    },
    "15112": {
        "name": "Phúc Thịnh",
        "type": "xa",
        "slug": "phuc-thinh",
        "name_with_type": "Xã Phúc Thịnh",
        "path": "Phúc Thịnh, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Phúc Thịnh, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15112",
        "parent_code": "389"
    },
    "15115": {
        "name": "Nguyệt Ấn",
        "type": "xa",
        "slug": "nguyet-an",
        "name_with_type": "Xã Nguyệt Ấn",
        "path": "Nguyệt Ấn, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Nguyệt Ấn, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15115",
        "parent_code": "389"
    },
    "15118": {
        "name": "Kiên Thọ",
        "type": "xa",
        "slug": "kien-tho",
        "name_with_type": "Xã Kiên Thọ",
        "path": "Kiên Thọ, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Kiên Thọ, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15118",
        "parent_code": "389"
    },
    "15121": {
        "name": "Minh Tiến",
        "type": "xa",
        "slug": "minh-tien",
        "name_with_type": "Xã Minh Tiến",
        "path": "Minh Tiến, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Minh Tiến, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15121",
        "parent_code": "389"
    },
    "15124": {
        "name": "Minh Sơn",
        "type": "xa",
        "slug": "minh-son",
        "name_with_type": "Xã Minh Sơn",
        "path": "Minh Sơn, Ngọc Lặc, Thanh Hóa",
        "path_with_type": "Xã Minh Sơn, Huyện Ngọc Lặc, Tỉnh Thanh Hóa",
        "code": "15124",
        "parent_code": "389"
    },
    "15127": {
        "name": "Cẩm Thủy",
        "type": "thi-tran",
        "slug": "cam-thuy",
        "name_with_type": "Thị trấn Cẩm Thủy",
        "path": "Cẩm Thủy, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Thị trấn Cẩm Thủy, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15127",
        "parent_code": "390"
    },
    "15130": {
        "name": "Phúc Do",
        "type": "xa",
        "slug": "phuc-do",
        "name_with_type": "Xã Phúc Do",
        "path": "Phúc Do, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Phúc Do, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15130",
        "parent_code": "390"
    },
    "15133": {
        "name": "Cẩm Thành",
        "type": "xa",
        "slug": "cam-thanh",
        "name_with_type": "Xã Cẩm Thành",
        "path": "Cẩm Thành, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Thành, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15133",
        "parent_code": "390"
    },
    "15136": {
        "name": "Cẩm Quý",
        "type": "xa",
        "slug": "cam-quy",
        "name_with_type": "Xã Cẩm Quý",
        "path": "Cẩm Quý, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Quý, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15136",
        "parent_code": "390"
    },
    "15139": {
        "name": "Cẩm Lương",
        "type": "xa",
        "slug": "cam-luong",
        "name_with_type": "Xã Cẩm Lương",
        "path": "Cẩm Lương, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Lương, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15139",
        "parent_code": "390"
    },
    "15142": {
        "name": "Cẩm Thạch",
        "type": "xa",
        "slug": "cam-thach",
        "name_with_type": "Xã Cẩm Thạch",
        "path": "Cẩm Thạch, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Thạch, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15142",
        "parent_code": "390"
    },
    "15145": {
        "name": "Cẩm Liên",
        "type": "xa",
        "slug": "cam-lien",
        "name_with_type": "Xã Cẩm Liên",
        "path": "Cẩm Liên, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Liên, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15145",
        "parent_code": "390"
    },
    "15148": {
        "name": "Cẩm Giang",
        "type": "xa",
        "slug": "cam-giang",
        "name_with_type": "Xã Cẩm Giang",
        "path": "Cẩm Giang, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Giang, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15148",
        "parent_code": "390"
    },
    "15151": {
        "name": "Cẩm Bình",
        "type": "xa",
        "slug": "cam-binh",
        "name_with_type": "Xã Cẩm Bình",
        "path": "Cẩm Bình, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Bình, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15151",
        "parent_code": "390"
    },
    "15154": {
        "name": "Cẩm Tú",
        "type": "xa",
        "slug": "cam-tu",
        "name_with_type": "Xã Cẩm Tú",
        "path": "Cẩm Tú, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Tú, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15154",
        "parent_code": "390"
    },
    "15157": {
        "name": "Cẩm Sơn",
        "type": "xa",
        "slug": "cam-son",
        "name_with_type": "Xã Cẩm Sơn",
        "path": "Cẩm Sơn, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Sơn, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15157",
        "parent_code": "390"
    },
    "15160": {
        "name": "Cẩm Châu",
        "type": "xa",
        "slug": "cam-chau",
        "name_with_type": "Xã Cẩm Châu",
        "path": "Cẩm Châu, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Châu, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15160",
        "parent_code": "390"
    },
    "15163": {
        "name": "Cẩm Tâm",
        "type": "xa",
        "slug": "cam-tam",
        "name_with_type": "Xã Cẩm Tâm",
        "path": "Cẩm Tâm, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Tâm, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15163",
        "parent_code": "390"
    },
    "15166": {
        "name": "Cẩm Phong",
        "type": "xa",
        "slug": "cam-phong",
        "name_with_type": "Xã Cẩm Phong",
        "path": "Cẩm Phong, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Phong, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15166",
        "parent_code": "390"
    },
    "15169": {
        "name": "Cẩm Ngọc",
        "type": "xa",
        "slug": "cam-ngoc",
        "name_with_type": "Xã Cẩm Ngọc",
        "path": "Cẩm Ngọc, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Ngọc, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15169",
        "parent_code": "390"
    },
    "15172": {
        "name": "Cẩm Long",
        "type": "xa",
        "slug": "cam-long",
        "name_with_type": "Xã Cẩm Long",
        "path": "Cẩm Long, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Long, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15172",
        "parent_code": "390"
    },
    "15175": {
        "name": "Cẩm Yên",
        "type": "xa",
        "slug": "cam-yen",
        "name_with_type": "Xã Cẩm Yên",
        "path": "Cẩm Yên, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Yên, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15175",
        "parent_code": "390"
    },
    "15178": {
        "name": "Cẩm Tân",
        "type": "xa",
        "slug": "cam-tan",
        "name_with_type": "Xã Cẩm Tân",
        "path": "Cẩm Tân, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Tân, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15178",
        "parent_code": "390"
    },
    "15181": {
        "name": "Cẩm Phú",
        "type": "xa",
        "slug": "cam-phu",
        "name_with_type": "Xã Cẩm Phú",
        "path": "Cẩm Phú, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Phú, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15181",
        "parent_code": "390"
    },
    "15184": {
        "name": "Cẩm Vân",
        "type": "xa",
        "slug": "cam-van",
        "name_with_type": "Xã Cẩm Vân",
        "path": "Cẩm Vân, Cẩm Thủy, Thanh Hóa",
        "path_with_type": "Xã Cẩm Vân, Huyện Cẩm Thủy, Tỉnh Thanh Hóa",
        "code": "15184",
        "parent_code": "390"
    },
    "15187": {
        "name": "Kim Tân",
        "type": "thi-tran",
        "slug": "kim-tan",
        "name_with_type": "Thị trấn Kim Tân",
        "path": "Kim Tân, Thạch Thành, Thanh Hóa",
        "path_with_type": "Thị trấn Kim Tân, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15187",
        "parent_code": "391"
    },
    "15190": {
        "name": "Vân Du",
        "type": "thi-tran",
        "slug": "van-du",
        "name_with_type": "Thị trấn Vân Du",
        "path": "Vân Du, Thạch Thành, Thanh Hóa",
        "path_with_type": "Thị trấn Vân Du, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15190",
        "parent_code": "391"
    },
    "15193": {
        "name": "Thạch Tân",
        "type": "xa",
        "slug": "thach-tan",
        "name_with_type": "Xã Thạch Tân",
        "path": "Thạch Tân, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Tân, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15193",
        "parent_code": "391"
    },
    "15196": {
        "name": "Thạch Lâm",
        "type": "xa",
        "slug": "thach-lam",
        "name_with_type": "Xã Thạch Lâm",
        "path": "Thạch Lâm, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Lâm, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15196",
        "parent_code": "391"
    },
    "15199": {
        "name": "Thạch Quảng",
        "type": "xa",
        "slug": "thach-quang",
        "name_with_type": "Xã Thạch Quảng",
        "path": "Thạch Quảng, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Quảng, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15199",
        "parent_code": "391"
    },
    "15202": {
        "name": "Thạch Tượng",
        "type": "xa",
        "slug": "thach-tuong",
        "name_with_type": "Xã Thạch Tượng",
        "path": "Thạch Tượng, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Tượng, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15202",
        "parent_code": "391"
    },
    "15205": {
        "name": "Thạch Cẩm",
        "type": "xa",
        "slug": "thach-cam",
        "name_with_type": "Xã Thạch Cẩm",
        "path": "Thạch Cẩm, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Cẩm, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15205",
        "parent_code": "391"
    },
    "15208": {
        "name": "Thạch Sơn",
        "type": "xa",
        "slug": "thach-son",
        "name_with_type": "Xã Thạch Sơn",
        "path": "Thạch Sơn, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Sơn, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15208",
        "parent_code": "391"
    },
    "15211": {
        "name": "Thạch Bình",
        "type": "xa",
        "slug": "thach-binh",
        "name_with_type": "Xã Thạch Bình",
        "path": "Thạch Bình, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Bình, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15211",
        "parent_code": "391"
    },
    "15214": {
        "name": "Thạch Định",
        "type": "xa",
        "slug": "thach-dinh",
        "name_with_type": "Xã Thạch Định",
        "path": "Thạch Định, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Định, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15214",
        "parent_code": "391"
    },
    "15217": {
        "name": "Thạch Đồng",
        "type": "xa",
        "slug": "thach-dong",
        "name_with_type": "Xã Thạch Đồng",
        "path": "Thạch Đồng, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Đồng, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15217",
        "parent_code": "391"
    },
    "15220": {
        "name": "Thạch Long",
        "type": "xa",
        "slug": "thach-long",
        "name_with_type": "Xã Thạch Long",
        "path": "Thạch Long, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thạch Long, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15220",
        "parent_code": "391"
    },
    "15223": {
        "name": "Thành Mỹ",
        "type": "xa",
        "slug": "thanh-my",
        "name_with_type": "Xã Thành Mỹ",
        "path": "Thành Mỹ, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Mỹ, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15223",
        "parent_code": "391"
    },
    "15226": {
        "name": "Thành Yên",
        "type": "xa",
        "slug": "thanh-yen",
        "name_with_type": "Xã Thành Yên",
        "path": "Thành Yên, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Yên, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15226",
        "parent_code": "391"
    },
    "15229": {
        "name": "Thành Vinh",
        "type": "xa",
        "slug": "thanh-vinh",
        "name_with_type": "Xã Thành Vinh",
        "path": "Thành Vinh, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Vinh, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15229",
        "parent_code": "391"
    },
    "15232": {
        "name": "Thành Minh",
        "type": "xa",
        "slug": "thanh-minh",
        "name_with_type": "Xã Thành Minh",
        "path": "Thành Minh, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Minh, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15232",
        "parent_code": "391"
    },
    "15235": {
        "name": "Thành Công",
        "type": "xa",
        "slug": "thanh-cong",
        "name_with_type": "Xã Thành Công",
        "path": "Thành Công, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Công, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15235",
        "parent_code": "391"
    },
    "15238": {
        "name": "Thành Tân",
        "type": "xa",
        "slug": "thanh-tan",
        "name_with_type": "Xã Thành Tân",
        "path": "Thành Tân, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Tân, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15238",
        "parent_code": "391"
    },
    "15241": {
        "name": "Thành Trực",
        "type": "xa",
        "slug": "thanh-truc",
        "name_with_type": "Xã Thành Trực",
        "path": "Thành Trực, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Trực, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15241",
        "parent_code": "391"
    },
    "15244": {
        "name": "Thành Vân",
        "type": "xa",
        "slug": "thanh-van",
        "name_with_type": "Xã Thành Vân",
        "path": "Thành Vân, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Vân, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15244",
        "parent_code": "391"
    },
    "15247": {
        "name": "Thành Tâm",
        "type": "xa",
        "slug": "thanh-tam",
        "name_with_type": "Xã Thành Tâm",
        "path": "Thành Tâm, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Tâm, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15247",
        "parent_code": "391"
    },
    "15250": {
        "name": "Thành An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thành An",
        "path": "Thành An, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành An, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15250",
        "parent_code": "391"
    },
    "15253": {
        "name": "Thành Thọ",
        "type": "xa",
        "slug": "thanh-tho",
        "name_with_type": "Xã Thành Thọ",
        "path": "Thành Thọ, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Thọ, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15253",
        "parent_code": "391"
    },
    "15256": {
        "name": "Thành Tiến",
        "type": "xa",
        "slug": "thanh-tien",
        "name_with_type": "Xã Thành Tiến",
        "path": "Thành Tiến, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Tiến, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15256",
        "parent_code": "391"
    },
    "15259": {
        "name": "Thành Long",
        "type": "xa",
        "slug": "thanh-long",
        "name_with_type": "Xã Thành Long",
        "path": "Thành Long, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Long, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15259",
        "parent_code": "391"
    },
    "15262": {
        "name": "Thành Kim",
        "type": "xa",
        "slug": "thanh-kim",
        "name_with_type": "Xã Thành Kim",
        "path": "Thành Kim, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Kim, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15262",
        "parent_code": "391"
    },
    "15265": {
        "name": "Thành Hưng",
        "type": "xa",
        "slug": "thanh-hung",
        "name_with_type": "Xã Thành Hưng",
        "path": "Thành Hưng, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Thành Hưng, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15265",
        "parent_code": "391"
    },
    "15268": {
        "name": "Ngọc Trạo",
        "type": "xa",
        "slug": "ngoc-trao",
        "name_with_type": "Xã Ngọc Trạo",
        "path": "Ngọc Trạo, Thạch Thành, Thanh Hóa",
        "path_with_type": "Xã Ngọc Trạo, Huyện Thạch Thành, Tỉnh Thanh Hóa",
        "code": "15268",
        "parent_code": "391"
    },
    "15271": {
        "name": "Hà Trung",
        "type": "thi-tran",
        "slug": "ha-trung",
        "name_with_type": "Thị trấn Hà Trung",
        "path": "Hà Trung, Hà Trung, Thanh Hóa",
        "path_with_type": "Thị trấn Hà Trung, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15271",
        "parent_code": "392"
    },
    "15274": {
        "name": "Hà Long",
        "type": "xa",
        "slug": "ha-long",
        "name_with_type": "Xã Hà Long",
        "path": "Hà Long, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Long, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15274",
        "parent_code": "392"
    },
    "15277": {
        "name": "Hà Vinh",
        "type": "xa",
        "slug": "ha-vinh",
        "name_with_type": "Xã Hà Vinh",
        "path": "Hà Vinh, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Vinh, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15277",
        "parent_code": "392"
    },
    "15280": {
        "name": "Hà Bắc",
        "type": "xa",
        "slug": "ha-bac",
        "name_with_type": "Xã Hà Bắc",
        "path": "Hà Bắc, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Bắc, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15280",
        "parent_code": "392"
    },
    "15283": {
        "name": "Hà Vân",
        "type": "xa",
        "slug": "ha-van",
        "name_with_type": "Xã Hà Vân",
        "path": "Hà Vân, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Vân, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15283",
        "parent_code": "392"
    },
    "15286": {
        "name": "Hà Yên",
        "type": "xa",
        "slug": "ha-yen",
        "name_with_type": "Xã Hà Yên",
        "path": "Hà Yên, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Yên, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15286",
        "parent_code": "392"
    },
    "15289": {
        "name": "Hà Thanh",
        "type": "xa",
        "slug": "ha-thanh",
        "name_with_type": "Xã Hà Thanh",
        "path": "Hà Thanh, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Thanh, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15289",
        "parent_code": "392"
    },
    "15292": {
        "name": "Hà Giang",
        "type": "xa",
        "slug": "ha-giang",
        "name_with_type": "Xã Hà Giang",
        "path": "Hà Giang, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Giang, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15292",
        "parent_code": "392"
    },
    "15295": {
        "name": "Hà Dương",
        "type": "xa",
        "slug": "ha-duong",
        "name_with_type": "Xã Hà Dương",
        "path": "Hà Dương, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Dương, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15295",
        "parent_code": "392"
    },
    "15298": {
        "name": "Hà Phú",
        "type": "xa",
        "slug": "ha-phu",
        "name_with_type": "Xã Hà Phú",
        "path": "Hà Phú, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Phú, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15298",
        "parent_code": "392"
    },
    "15301": {
        "name": "Hà Phong",
        "type": "xa",
        "slug": "ha-phong",
        "name_with_type": "Xã Hà Phong",
        "path": "Hà Phong, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Phong, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15301",
        "parent_code": "392"
    },
    "15304": {
        "name": "Hà Ngọc",
        "type": "xa",
        "slug": "ha-ngoc",
        "name_with_type": "Xã Hà Ngọc",
        "path": "Hà Ngọc, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Ngọc, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15304",
        "parent_code": "392"
    },
    "15307": {
        "name": "Hà Ninh",
        "type": "xa",
        "slug": "ha-ninh",
        "name_with_type": "Xã Hà Ninh",
        "path": "Hà Ninh, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Ninh, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15307",
        "parent_code": "392"
    },
    "15310": {
        "name": "Hà Lâm",
        "type": "xa",
        "slug": "ha-lam",
        "name_with_type": "Xã Hà Lâm",
        "path": "Hà Lâm, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Lâm, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15310",
        "parent_code": "392"
    },
    "15313": {
        "name": "Hà Sơn",
        "type": "xa",
        "slug": "ha-son",
        "name_with_type": "Xã Hà Sơn",
        "path": "Hà Sơn, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Sơn, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15313",
        "parent_code": "392"
    },
    "15316": {
        "name": "Hà Lĩnh",
        "type": "xa",
        "slug": "ha-linh",
        "name_with_type": "Xã Hà Lĩnh",
        "path": "Hà Lĩnh, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Lĩnh, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15316",
        "parent_code": "392"
    },
    "15319": {
        "name": "Hà Đông",
        "type": "xa",
        "slug": "ha-dong",
        "name_with_type": "Xã Hà Đông",
        "path": "Hà Đông, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Đông, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15319",
        "parent_code": "392"
    },
    "15322": {
        "name": "Hà Tân",
        "type": "xa",
        "slug": "ha-tan",
        "name_with_type": "Xã Hà Tân",
        "path": "Hà Tân, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Tân, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15322",
        "parent_code": "392"
    },
    "15325": {
        "name": "Hà Tiến",
        "type": "xa",
        "slug": "ha-tien",
        "name_with_type": "Xã Hà Tiến",
        "path": "Hà Tiến, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Tiến, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15325",
        "parent_code": "392"
    },
    "15328": {
        "name": "Hà Bình",
        "type": "xa",
        "slug": "ha-binh",
        "name_with_type": "Xã Hà Bình",
        "path": "Hà Bình, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Bình, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15328",
        "parent_code": "392"
    },
    "15331": {
        "name": "Hà Lai",
        "type": "xa",
        "slug": "ha-lai",
        "name_with_type": "Xã Hà Lai",
        "path": "Hà Lai, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Lai, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15331",
        "parent_code": "392"
    },
    "15334": {
        "name": "Hà Châu",
        "type": "xa",
        "slug": "ha-chau",
        "name_with_type": "Xã Hà Châu",
        "path": "Hà Châu, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Châu, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15334",
        "parent_code": "392"
    },
    "15337": {
        "name": "Hà Toại",
        "type": "xa",
        "slug": "ha-toai",
        "name_with_type": "Xã Hà Toại",
        "path": "Hà Toại, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Toại, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15337",
        "parent_code": "392"
    },
    "15340": {
        "name": "Hà Thái",
        "type": "xa",
        "slug": "ha-thai",
        "name_with_type": "Xã Hà Thái",
        "path": "Hà Thái, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Thái, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15340",
        "parent_code": "392"
    },
    "15343": {
        "name": "Hà Hải",
        "type": "xa",
        "slug": "ha-hai",
        "name_with_type": "Xã Hà Hải",
        "path": "Hà Hải, Hà Trung, Thanh Hóa",
        "path_with_type": "Xã Hà Hải, Huyện Hà Trung, Tỉnh Thanh Hóa",
        "code": "15343",
        "parent_code": "392"
    },
    "15346": {
        "name": "Vĩnh Lộc",
        "type": "thi-tran",
        "slug": "vinh-loc",
        "name_with_type": "Thị trấn Vĩnh Lộc",
        "path": "Vĩnh Lộc, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Thị trấn Vĩnh Lộc, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15346",
        "parent_code": "393"
    },
    "15349": {
        "name": "Vĩnh Thành",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thành",
        "path": "Vĩnh Thành, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Thành, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15349",
        "parent_code": "393"
    },
    "15352": {
        "name": "Vĩnh Quang",
        "type": "xa",
        "slug": "vinh-quang",
        "name_with_type": "Xã Vĩnh Quang",
        "path": "Vĩnh Quang, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Quang, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15352",
        "parent_code": "393"
    },
    "15355": {
        "name": "Vĩnh Yên",
        "type": "xa",
        "slug": "vinh-yen",
        "name_with_type": "Xã Vĩnh Yên",
        "path": "Vĩnh Yên, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Yên, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15355",
        "parent_code": "393"
    },
    "15358": {
        "name": "Vĩnh Tiến",
        "type": "xa",
        "slug": "vinh-tien",
        "name_with_type": "Xã Vĩnh Tiến",
        "path": "Vĩnh Tiến, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Tiến, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15358",
        "parent_code": "393"
    },
    "15361": {
        "name": "Vĩnh Long",
        "type": "xa",
        "slug": "vinh-long",
        "name_with_type": "Xã Vĩnh Long",
        "path": "Vĩnh Long, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Long, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15361",
        "parent_code": "393"
    },
    "15364": {
        "name": "Vĩnh Phúc",
        "type": "xa",
        "slug": "vinh-phuc",
        "name_with_type": "Xã Vĩnh Phúc",
        "path": "Vĩnh Phúc, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Phúc, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15364",
        "parent_code": "393"
    },
    "15367": {
        "name": "Vĩnh Hưng",
        "type": "xa",
        "slug": "vinh-hung",
        "name_with_type": "Xã Vĩnh Hưng",
        "path": "Vĩnh Hưng, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Hưng, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15367",
        "parent_code": "393"
    },
    "15370": {
        "name": "Vĩnh Minh",
        "type": "xa",
        "slug": "vinh-minh",
        "name_with_type": "Xã Vĩnh Minh",
        "path": "Vĩnh Minh, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Minh, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15370",
        "parent_code": "393"
    },
    "15373": {
        "name": "Vĩnh Khang",
        "type": "xa",
        "slug": "vinh-khang",
        "name_with_type": "Xã Vĩnh Khang",
        "path": "Vĩnh Khang, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Khang, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15373",
        "parent_code": "393"
    },
    "15376": {
        "name": "Vĩnh Hòa",
        "type": "xa",
        "slug": "vinh-hoa",
        "name_with_type": "Xã Vĩnh Hòa",
        "path": "Vĩnh Hòa, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Hòa, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15376",
        "parent_code": "393"
    },
    "15379": {
        "name": "Vĩnh Hùng",
        "type": "xa",
        "slug": "vinh-hung",
        "name_with_type": "Xã Vĩnh Hùng",
        "path": "Vĩnh Hùng, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Hùng, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15379",
        "parent_code": "393"
    },
    "15382": {
        "name": "Vĩnh Tân",
        "type": "xa",
        "slug": "vinh-tan",
        "name_with_type": "Xã Vĩnh Tân",
        "path": "Vĩnh Tân, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Tân, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15382",
        "parent_code": "393"
    },
    "15385": {
        "name": "Vĩnh Ninh",
        "type": "xa",
        "slug": "vinh-ninh",
        "name_with_type": "Xã Vĩnh Ninh",
        "path": "Vĩnh Ninh, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Ninh, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15385",
        "parent_code": "393"
    },
    "15388": {
        "name": "Vĩnh Thịnh",
        "type": "xa",
        "slug": "vinh-thinh",
        "name_with_type": "Xã Vĩnh Thịnh",
        "path": "Vĩnh Thịnh, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh Thịnh, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15388",
        "parent_code": "393"
    },
    "15391": {
        "name": "Vĩnh An",
        "type": "xa",
        "slug": "vinh-an",
        "name_with_type": "Xã Vĩnh An",
        "path": "Vĩnh An, Vĩnh Lộc, Thanh Hóa",
        "path_with_type": "Xã Vĩnh An, Huyện Vĩnh Lộc, Tỉnh Thanh Hóa",
        "code": "15391",
        "parent_code": "393"
    },
    "15394": {
        "name": "Quán Lào",
        "type": "thi-tran",
        "slug": "quan-lao",
        "name_with_type": "Thị trấn Quán Lào",
        "path": "Quán Lào, Yên Định, Thanh Hóa",
        "path_with_type": "Thị trấn Quán Lào, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15394",
        "parent_code": "394"
    },
    "15397": {
        "name": "Thống Nhất",
        "type": "thi-tran",
        "slug": "thong-nhat",
        "name_with_type": "Thị trấn Thống Nhất",
        "path": "Thống Nhất, Yên Định, Thanh Hóa",
        "path_with_type": "Thị trấn Thống Nhất, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15397",
        "parent_code": "394"
    },
    "15400": {
        "name": "Yên Phú",
        "type": "xa",
        "slug": "yen-phu",
        "name_with_type": "Xã Yên Phú",
        "path": "Yên Phú, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Phú, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15400",
        "parent_code": "394"
    },
    "15403": {
        "name": "Yên Lâm",
        "type": "xa",
        "slug": "yen-lam",
        "name_with_type": "Xã Yên Lâm",
        "path": "Yên Lâm, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Lâm, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15403",
        "parent_code": "394"
    },
    "15406": {
        "name": "Yên Tâm",
        "type": "xa",
        "slug": "yen-tam",
        "name_with_type": "Xã Yên Tâm",
        "path": "Yên Tâm, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Tâm, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15406",
        "parent_code": "394"
    },
    "15409": {
        "name": "Yên Giang",
        "type": "xa",
        "slug": "yen-giang",
        "name_with_type": "Xã Yên Giang",
        "path": "Yên Giang, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Giang, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15409",
        "parent_code": "394"
    },
    "15412": {
        "name": "Quí Lộc",
        "type": "xa",
        "slug": "qui-loc",
        "name_with_type": "Xã Quí Lộc",
        "path": "Quí Lộc, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Quí Lộc, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15412",
        "parent_code": "394"
    },
    "15415": {
        "name": "Yên Thọ",
        "type": "xa",
        "slug": "yen-tho",
        "name_with_type": "Xã Yên Thọ",
        "path": "Yên Thọ, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Thọ, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15415",
        "parent_code": "394"
    },
    "15418": {
        "name": "Yên Trung",
        "type": "xa",
        "slug": "yen-trung",
        "name_with_type": "Xã Yên Trung",
        "path": "Yên Trung, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Trung, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15418",
        "parent_code": "394"
    },
    "15421": {
        "name": "Yên Trường",
        "type": "xa",
        "slug": "yen-truong",
        "name_with_type": "Xã Yên Trường",
        "path": "Yên Trường, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Trường, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15421",
        "parent_code": "394"
    },
    "15424": {
        "name": "Yên Bái",
        "type": "xa",
        "slug": "yen-bai",
        "name_with_type": "Xã Yên Bái",
        "path": "Yên Bái, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Bái, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15424",
        "parent_code": "394"
    },
    "15427": {
        "name": "Yên Phong",
        "type": "xa",
        "slug": "yen-phong",
        "name_with_type": "Xã Yên Phong",
        "path": "Yên Phong, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Phong, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15427",
        "parent_code": "394"
    },
    "15430": {
        "name": "Yên Thái",
        "type": "xa",
        "slug": "yen-thai",
        "name_with_type": "Xã Yên Thái",
        "path": "Yên Thái, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Thái, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15430",
        "parent_code": "394"
    },
    "15433": {
        "name": "Yên Hùng",
        "type": "xa",
        "slug": "yen-hung",
        "name_with_type": "Xã Yên Hùng",
        "path": "Yên Hùng, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Hùng, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15433",
        "parent_code": "394"
    },
    "15436": {
        "name": "Yên Thịnh",
        "type": "xa",
        "slug": "yen-thinh",
        "name_with_type": "Xã Yên Thịnh",
        "path": "Yên Thịnh, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Thịnh, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15436",
        "parent_code": "394"
    },
    "15439": {
        "name": "Yên Ninh",
        "type": "xa",
        "slug": "yen-ninh",
        "name_with_type": "Xã Yên Ninh",
        "path": "Yên Ninh, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Ninh, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15439",
        "parent_code": "394"
    },
    "15442": {
        "name": "Yên Lạc",
        "type": "xa",
        "slug": "yen-lac",
        "name_with_type": "Xã Yên Lạc",
        "path": "Yên Lạc, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Yên Lạc, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15442",
        "parent_code": "394"
    },
    "15445": {
        "name": "Định Tăng",
        "type": "xa",
        "slug": "dinh-tang",
        "name_with_type": "Xã Định Tăng",
        "path": "Định Tăng, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Tăng, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15445",
        "parent_code": "394"
    },
    "15448": {
        "name": "Định Hòa",
        "type": "xa",
        "slug": "dinh-hoa",
        "name_with_type": "Xã Định Hòa",
        "path": "Định Hòa, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Hòa, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15448",
        "parent_code": "394"
    },
    "15451": {
        "name": "Định Thành",
        "type": "xa",
        "slug": "dinh-thanh",
        "name_with_type": "Xã Định Thành",
        "path": "Định Thành, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Thành, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15451",
        "parent_code": "394"
    },
    "15454": {
        "name": "Định Công",
        "type": "xa",
        "slug": "dinh-cong",
        "name_with_type": "Xã Định Công",
        "path": "Định Công, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Công, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15454",
        "parent_code": "394"
    },
    "15457": {
        "name": "Định Tân",
        "type": "xa",
        "slug": "dinh-tan",
        "name_with_type": "Xã Định Tân",
        "path": "Định Tân, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Tân, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15457",
        "parent_code": "394"
    },
    "15460": {
        "name": "Định Tiến",
        "type": "xa",
        "slug": "dinh-tien",
        "name_with_type": "Xã Định Tiến",
        "path": "Định Tiến, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Tiến, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15460",
        "parent_code": "394"
    },
    "15463": {
        "name": "Định Long",
        "type": "xa",
        "slug": "dinh-long",
        "name_with_type": "Xã Định Long",
        "path": "Định Long, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Long, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15463",
        "parent_code": "394"
    },
    "15466": {
        "name": "Định Liên",
        "type": "xa",
        "slug": "dinh-lien",
        "name_with_type": "Xã Định Liên",
        "path": "Định Liên, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Liên, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15466",
        "parent_code": "394"
    },
    "15469": {
        "name": "Định Tường",
        "type": "xa",
        "slug": "dinh-tuong",
        "name_with_type": "Xã Định Tường",
        "path": "Định Tường, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Tường, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15469",
        "parent_code": "394"
    },
    "15472": {
        "name": "Định Hưng",
        "type": "xa",
        "slug": "dinh-hung",
        "name_with_type": "Xã Định Hưng",
        "path": "Định Hưng, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Hưng, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15472",
        "parent_code": "394"
    },
    "15475": {
        "name": "Định Hải",
        "type": "xa",
        "slug": "dinh-hai",
        "name_with_type": "Xã Định Hải",
        "path": "Định Hải, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Hải, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15475",
        "parent_code": "394"
    },
    "15478": {
        "name": "Định Bình",
        "type": "xa",
        "slug": "dinh-binh",
        "name_with_type": "Xã Định Bình",
        "path": "Định Bình, Yên Định, Thanh Hóa",
        "path_with_type": "Xã Định Bình, Huyện Yên Định, Tỉnh Thanh Hóa",
        "code": "15478",
        "parent_code": "394"
    },
    "15481": {
        "name": "Thọ Xuân",
        "type": "thi-tran",
        "slug": "tho-xuan",
        "name_with_type": "Thị trấn Thọ Xuân",
        "path": "Thọ Xuân, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Thị trấn Thọ Xuân, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15481",
        "parent_code": "395"
    },
    "15484": {
        "name": "Lam Sơn",
        "type": "thi-tran",
        "slug": "lam-son",
        "name_with_type": "Thị trấn Lam Sơn",
        "path": "Lam Sơn, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Thị trấn Lam Sơn, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15484",
        "parent_code": "395"
    },
    "15487": {
        "name": "Sao Vàng",
        "type": "thi-tran",
        "slug": "sao-vang",
        "name_with_type": "Thị trấn Sao Vàng",
        "path": "Sao Vàng, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Thị trấn Sao Vàng, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15487",
        "parent_code": "395"
    },
    "15490": {
        "name": "Xuân Khánh",
        "type": "xa",
        "slug": "xuan-khanh",
        "name_with_type": "Xã Xuân Khánh",
        "path": "Xuân Khánh, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Khánh, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15490",
        "parent_code": "395"
    },
    "15493": {
        "name": "Thọ Nguyên",
        "type": "xa",
        "slug": "tho-nguyen",
        "name_with_type": "Xã Thọ Nguyên",
        "path": "Thọ Nguyên, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Nguyên, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15493",
        "parent_code": "395"
    },
    "15496": {
        "name": "Xuân Thành",
        "type": "xa",
        "slug": "xuan-thanh",
        "name_with_type": "Xã Xuân Thành",
        "path": "Xuân Thành, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Thành, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15496",
        "parent_code": "395"
    },
    "15499": {
        "name": "Hạnh Phúc",
        "type": "xa",
        "slug": "hanh-phuc",
        "name_with_type": "Xã Hạnh Phúc",
        "path": "Hạnh Phúc, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Hạnh Phúc, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15499",
        "parent_code": "395"
    },
    "15502": {
        "name": "Bắc Lương",
        "type": "xa",
        "slug": "bac-luong",
        "name_with_type": "Xã Bắc Lương",
        "path": "Bắc Lương, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Bắc Lương, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15502",
        "parent_code": "395"
    },
    "15505": {
        "name": "Nam Giang",
        "type": "xa",
        "slug": "nam-giang",
        "name_with_type": "Xã Nam Giang",
        "path": "Nam Giang, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Nam Giang, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15505",
        "parent_code": "395"
    },
    "15508": {
        "name": "Xuân Phong",
        "type": "xa",
        "slug": "xuan-phong",
        "name_with_type": "Xã Xuân Phong",
        "path": "Xuân Phong, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Phong, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15508",
        "parent_code": "395"
    },
    "15511": {
        "name": "Thọ Lộc",
        "type": "xa",
        "slug": "tho-loc",
        "name_with_type": "Xã Thọ Lộc",
        "path": "Thọ Lộc, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Lộc, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15511",
        "parent_code": "395"
    },
    "15514": {
        "name": "Xuân Trường",
        "type": "xa",
        "slug": "xuan-truong",
        "name_with_type": "Xã Xuân Trường",
        "path": "Xuân Trường, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Trường, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15514",
        "parent_code": "395"
    },
    "15517": {
        "name": "Xuân Hòa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hòa",
        "path": "Xuân Hòa, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Hòa, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15517",
        "parent_code": "395"
    },
    "15520": {
        "name": "Thọ Hải",
        "type": "xa",
        "slug": "tho-hai",
        "name_with_type": "Xã Thọ Hải",
        "path": "Thọ Hải, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Hải, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15520",
        "parent_code": "395"
    },
    "15523": {
        "name": "Tây Hồ",
        "type": "xa",
        "slug": "tay-ho",
        "name_with_type": "Xã Tây Hồ",
        "path": "Tây Hồ, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Tây Hồ, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15523",
        "parent_code": "395"
    },
    "15526": {
        "name": "Xuân Giang",
        "type": "xa",
        "slug": "xuan-giang",
        "name_with_type": "Xã Xuân Giang",
        "path": "Xuân Giang, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Giang, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15526",
        "parent_code": "395"
    },
    "15529": {
        "name": "Xuân Quang",
        "type": "xa",
        "slug": "xuan-quang",
        "name_with_type": "Xã Xuân Quang",
        "path": "Xuân Quang, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Quang, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15529",
        "parent_code": "395"
    },
    "15532": {
        "name": "Xuân Sơn",
        "type": "xa",
        "slug": "xuan-son",
        "name_with_type": "Xã Xuân Sơn",
        "path": "Xuân Sơn, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Sơn, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15532",
        "parent_code": "395"
    },
    "15535": {
        "name": "Xuân Hưng",
        "type": "xa",
        "slug": "xuan-hung",
        "name_with_type": "Xã Xuân Hưng",
        "path": "Xuân Hưng, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Hưng, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15535",
        "parent_code": "395"
    },
    "15538": {
        "name": "Thọ Diên",
        "type": "xa",
        "slug": "tho-dien",
        "name_with_type": "Xã Thọ Diên",
        "path": "Thọ Diên, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Diên, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15538",
        "parent_code": "395"
    },
    "15541": {
        "name": "Thọ Lâm",
        "type": "xa",
        "slug": "tho-lam",
        "name_with_type": "Xã Thọ Lâm",
        "path": "Thọ Lâm, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Lâm, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15541",
        "parent_code": "395"
    },
    "15544": {
        "name": "Thọ Xương",
        "type": "xa",
        "slug": "tho-xuong",
        "name_with_type": "Xã Thọ Xương",
        "path": "Thọ Xương, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Xương, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15544",
        "parent_code": "395"
    },
    "15547": {
        "name": "Xuân Bái",
        "type": "xa",
        "slug": "xuan-bai",
        "name_with_type": "Xã Xuân Bái",
        "path": "Xuân Bái, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Bái, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15547",
        "parent_code": "395"
    },
    "15550": {
        "name": "Xuân Phú",
        "type": "xa",
        "slug": "xuan-phu",
        "name_with_type": "Xã Xuân Phú",
        "path": "Xuân Phú, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Phú, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15550",
        "parent_code": "395"
    },
    "15553": {
        "name": "Xuân Thắng",
        "type": "xa",
        "slug": "xuan-thang",
        "name_with_type": "Xã Xuân Thắng",
        "path": "Xuân Thắng, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Thắng, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15553",
        "parent_code": "395"
    },
    "15556": {
        "name": "Xuân Lam",
        "type": "xa",
        "slug": "xuan-lam",
        "name_with_type": "Xã Xuân Lam",
        "path": "Xuân Lam, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Lam, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15556",
        "parent_code": "395"
    },
    "15559": {
        "name": "Xuân Thiên",
        "type": "xa",
        "slug": "xuan-thien",
        "name_with_type": "Xã Xuân Thiên",
        "path": "Xuân Thiên, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Thiên, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15559",
        "parent_code": "395"
    },
    "15562": {
        "name": "Thọ Minh",
        "type": "xa",
        "slug": "tho-minh",
        "name_with_type": "Xã Thọ Minh",
        "path": "Thọ Minh, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Minh, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15562",
        "parent_code": "395"
    },
    "15565": {
        "name": "Xuân Châu",
        "type": "xa",
        "slug": "xuan-chau",
        "name_with_type": "Xã Xuân Châu",
        "path": "Xuân Châu, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Châu, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15565",
        "parent_code": "395"
    },
    "15568": {
        "name": "Thọ Lập",
        "type": "xa",
        "slug": "tho-lap",
        "name_with_type": "Xã Thọ Lập",
        "path": "Thọ Lập, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Lập, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15568",
        "parent_code": "395"
    },
    "15571": {
        "name": "Quảng Phú",
        "type": "xa",
        "slug": "quang-phu",
        "name_with_type": "Xã Quảng Phú",
        "path": "Quảng Phú, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Quảng Phú, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15571",
        "parent_code": "395"
    },
    "15574": {
        "name": "Xuân Tín",
        "type": "xa",
        "slug": "xuan-tin",
        "name_with_type": "Xã Xuân Tín",
        "path": "Xuân Tín, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Tín, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15574",
        "parent_code": "395"
    },
    "15577": {
        "name": "Phú Yên",
        "type": "xa",
        "slug": "phu-yen",
        "name_with_type": "Xã Phú Yên",
        "path": "Phú Yên, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Phú Yên, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15577",
        "parent_code": "395"
    },
    "15580": {
        "name": "Xuân Yên",
        "type": "xa",
        "slug": "xuan-yen",
        "name_with_type": "Xã Xuân Yên",
        "path": "Xuân Yên, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Yên, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15580",
        "parent_code": "395"
    },
    "15583": {
        "name": "Xuân Lai",
        "type": "xa",
        "slug": "xuan-lai",
        "name_with_type": "Xã Xuân Lai",
        "path": "Xuân Lai, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Lai, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15583",
        "parent_code": "395"
    },
    "15586": {
        "name": "Xuân Lập",
        "type": "xa",
        "slug": "xuan-lap",
        "name_with_type": "Xã Xuân Lập",
        "path": "Xuân Lập, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Lập, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15586",
        "parent_code": "395"
    },
    "15589": {
        "name": "Thọ Thắng",
        "type": "xa",
        "slug": "tho-thang",
        "name_with_type": "Xã Thọ Thắng",
        "path": "Thọ Thắng, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Thắng, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15589",
        "parent_code": "395"
    },
    "15592": {
        "name": "Xuân Minh",
        "type": "xa",
        "slug": "xuan-minh",
        "name_with_type": "Xã Xuân Minh",
        "path": "Xuân Minh, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Minh, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15592",
        "parent_code": "395"
    },
    "15595": {
        "name": "Xuân Tân",
        "type": "xa",
        "slug": "xuan-tan",
        "name_with_type": "Xã Xuân Tân",
        "path": "Xuân Tân, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Tân, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15595",
        "parent_code": "395"
    },
    "15598": {
        "name": "Xuân Vinh",
        "type": "xa",
        "slug": "xuan-vinh",
        "name_with_type": "Xã Xuân Vinh",
        "path": "Xuân Vinh, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Vinh, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15598",
        "parent_code": "395"
    },
    "15601": {
        "name": "Thọ Trường",
        "type": "xa",
        "slug": "tho-truong",
        "name_with_type": "Xã Thọ Trường",
        "path": "Thọ Trường, Thọ Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Trường, Huyện Thọ Xuân, Tỉnh Thanh Hóa",
        "code": "15601",
        "parent_code": "395"
    },
    "15604": {
        "name": "Thường Xuân",
        "type": "thi-tran",
        "slug": "thuong-xuan",
        "name_with_type": "Thị trấn Thường Xuân",
        "path": "Thường Xuân, Thường Xuân, Thanh Hóa",
        "path_with_type": "Thị trấn Thường Xuân, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15604",
        "parent_code": "396"
    },
    "15607": {
        "name": "Bát Mọt",
        "type": "xa",
        "slug": "bat-mot",
        "name_with_type": "Xã Bát Mọt",
        "path": "Bát Mọt, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Bát Mọt, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15607",
        "parent_code": "396"
    },
    "15610": {
        "name": "Yên Nhân",
        "type": "xa",
        "slug": "yen-nhan",
        "name_with_type": "Xã Yên Nhân",
        "path": "Yên Nhân, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Yên Nhân, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15610",
        "parent_code": "396"
    },
    "15619": {
        "name": "Xuân Lẹ",
        "type": "xa",
        "slug": "xuan-le",
        "name_with_type": "Xã Xuân Lẹ",
        "path": "Xuân Lẹ, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Lẹ, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15619",
        "parent_code": "396"
    },
    "15622": {
        "name": "Vạn Xuân",
        "type": "xa",
        "slug": "van-xuan",
        "name_with_type": "Xã Vạn Xuân",
        "path": "Vạn Xuân, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Vạn Xuân, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15622",
        "parent_code": "396"
    },
    "15628": {
        "name": "Lương Sơn",
        "type": "xa",
        "slug": "luong-son",
        "name_with_type": "Xã Lương Sơn",
        "path": "Lương Sơn, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Lương Sơn, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15628",
        "parent_code": "396"
    },
    "15631": {
        "name": "Xuân Cao",
        "type": "xa",
        "slug": "xuan-cao",
        "name_with_type": "Xã Xuân Cao",
        "path": "Xuân Cao, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Cao, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15631",
        "parent_code": "396"
    },
    "15634": {
        "name": "Luận Thành",
        "type": "xa",
        "slug": "luan-thanh",
        "name_with_type": "Xã Luận Thành",
        "path": "Luận Thành, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Luận Thành, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15634",
        "parent_code": "396"
    },
    "15637": {
        "name": "Luận Khê",
        "type": "xa",
        "slug": "luan-khe",
        "name_with_type": "Xã Luận Khê",
        "path": "Luận Khê, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Luận Khê, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15637",
        "parent_code": "396"
    },
    "15640": {
        "name": "Xuân Thắng",
        "type": "xa",
        "slug": "xuan-thang",
        "name_with_type": "Xã Xuân Thắng",
        "path": "Xuân Thắng, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Thắng, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15640",
        "parent_code": "396"
    },
    "15643": {
        "name": "Xuân Lộc",
        "type": "xa",
        "slug": "xuan-loc",
        "name_with_type": "Xã Xuân Lộc",
        "path": "Xuân Lộc, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Lộc, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15643",
        "parent_code": "396"
    },
    "15646": {
        "name": "Xuân Cẩm",
        "type": "xa",
        "slug": "xuan-cam",
        "name_with_type": "Xã Xuân Cẩm",
        "path": "Xuân Cẩm, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Cẩm, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15646",
        "parent_code": "396"
    },
    "15649": {
        "name": "Xuân Dương",
        "type": "xa",
        "slug": "xuan-duong",
        "name_with_type": "Xã Xuân Dương",
        "path": "Xuân Dương, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Dương, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15649",
        "parent_code": "396"
    },
    "15652": {
        "name": "Thọ Thanh",
        "type": "xa",
        "slug": "tho-thanh",
        "name_with_type": "Xã Thọ Thanh",
        "path": "Thọ Thanh, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Thọ Thanh, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15652",
        "parent_code": "396"
    },
    "15655": {
        "name": "Ngọc Phụng",
        "type": "xa",
        "slug": "ngoc-phung",
        "name_with_type": "Xã Ngọc Phụng",
        "path": "Ngọc Phụng, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Ngọc Phụng, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15655",
        "parent_code": "396"
    },
    "15658": {
        "name": "Xuân Chinh",
        "type": "xa",
        "slug": "xuan-chinh",
        "name_with_type": "Xã Xuân Chinh",
        "path": "Xuân Chinh, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Chinh, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15658",
        "parent_code": "396"
    },
    "15661": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Thường Xuân, Thanh Hóa",
        "path_with_type": "Xã Tân Thành, Huyện Thường Xuân, Tỉnh Thanh Hóa",
        "code": "15661",
        "parent_code": "396"
    },
    "15664": {
        "name": "Triệu Sơn",
        "type": "thi-tran",
        "slug": "trieu-son",
        "name_with_type": "Thị trấn Triệu Sơn",
        "path": "Triệu Sơn, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Thị trấn Triệu Sơn, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15664",
        "parent_code": "397"
    },
    "15667": {
        "name": "Thọ Sơn",
        "type": "xa",
        "slug": "tho-son",
        "name_with_type": "Xã Thọ Sơn",
        "path": "Thọ Sơn, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Sơn, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15667",
        "parent_code": "397"
    },
    "15670": {
        "name": "Thọ Bình",
        "type": "xa",
        "slug": "tho-binh",
        "name_with_type": "Xã Thọ Bình",
        "path": "Thọ Bình, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Bình, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15670",
        "parent_code": "397"
    },
    "15673": {
        "name": "Thọ Tiến",
        "type": "xa",
        "slug": "tho-tien",
        "name_with_type": "Xã Thọ Tiến",
        "path": "Thọ Tiến, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Tiến, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15673",
        "parent_code": "397"
    },
    "15676": {
        "name": "Hợp Lý",
        "type": "xa",
        "slug": "hop-ly",
        "name_with_type": "Xã Hợp Lý",
        "path": "Hợp Lý, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Hợp Lý, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15676",
        "parent_code": "397"
    },
    "15679": {
        "name": "Hợp Tiến",
        "type": "xa",
        "slug": "hop-tien",
        "name_with_type": "Xã Hợp Tiến",
        "path": "Hợp Tiến, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Hợp Tiến, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15679",
        "parent_code": "397"
    },
    "15682": {
        "name": "Hợp Thành",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thành",
        "path": "Hợp Thành, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Hợp Thành, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15682",
        "parent_code": "397"
    },
    "15685": {
        "name": "Triệu Thành",
        "type": "xa",
        "slug": "trieu-thanh",
        "name_with_type": "Xã Triệu Thành",
        "path": "Triệu Thành, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Triệu Thành, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15685",
        "parent_code": "397"
    },
    "15688": {
        "name": "Hợp Thắng",
        "type": "xa",
        "slug": "hop-thang",
        "name_with_type": "Xã Hợp Thắng",
        "path": "Hợp Thắng, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Hợp Thắng, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15688",
        "parent_code": "397"
    },
    "15691": {
        "name": "Minh Sơn",
        "type": "xa",
        "slug": "minh-son",
        "name_with_type": "Xã Minh Sơn",
        "path": "Minh Sơn, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Minh Sơn, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15691",
        "parent_code": "397"
    },
    "15694": {
        "name": "Minh Dân",
        "type": "xa",
        "slug": "minh-dan",
        "name_with_type": "Xã Minh Dân",
        "path": "Minh Dân, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Minh Dân, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15694",
        "parent_code": "397"
    },
    "15697": {
        "name": "Minh Châu",
        "type": "xa",
        "slug": "minh-chau",
        "name_with_type": "Xã Minh Châu",
        "path": "Minh Châu, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Minh Châu, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15697",
        "parent_code": "397"
    },
    "15700": {
        "name": "Dân Lực",
        "type": "xa",
        "slug": "dan-luc",
        "name_with_type": "Xã Dân Lực",
        "path": "Dân Lực, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Dân Lực, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15700",
        "parent_code": "397"
    },
    "15703": {
        "name": "Dân Lý",
        "type": "xa",
        "slug": "dan-ly",
        "name_with_type": "Xã Dân Lý",
        "path": "Dân Lý, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Dân Lý, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15703",
        "parent_code": "397"
    },
    "15706": {
        "name": "Dân Quyền",
        "type": "xa",
        "slug": "dan-quyen",
        "name_with_type": "Xã Dân Quyền",
        "path": "Dân Quyền, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Dân Quyền, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15706",
        "parent_code": "397"
    },
    "15709": {
        "name": "An Nông",
        "type": "xa",
        "slug": "an-nong",
        "name_with_type": "Xã An Nông",
        "path": "An Nông, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã An Nông, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15709",
        "parent_code": "397"
    },
    "15712": {
        "name": "Văn Sơn",
        "type": "xa",
        "slug": "van-son",
        "name_with_type": "Xã Văn Sơn",
        "path": "Văn Sơn, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Văn Sơn, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15712",
        "parent_code": "397"
    },
    "15715": {
        "name": "Thái Hòa",
        "type": "xa",
        "slug": "thai-hoa",
        "name_with_type": "Xã Thái Hòa",
        "path": "Thái Hòa, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thái Hòa, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15715",
        "parent_code": "397"
    },
    "15718": {
        "name": "Tân Ninh",
        "type": "xa",
        "slug": "tan-ninh",
        "name_with_type": "Xã Tân Ninh",
        "path": "Tân Ninh, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Tân Ninh, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15718",
        "parent_code": "397"
    },
    "15721": {
        "name": "Đồng Lợi",
        "type": "xa",
        "slug": "dong-loi",
        "name_with_type": "Xã Đồng Lợi",
        "path": "Đồng Lợi, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Đồng Lợi, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15721",
        "parent_code": "397"
    },
    "15724": {
        "name": "Đồng Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đồng Tiến",
        "path": "Đồng Tiến, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Đồng Tiến, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15724",
        "parent_code": "397"
    },
    "15727": {
        "name": "Đồng Thắng",
        "type": "xa",
        "slug": "dong-thang",
        "name_with_type": "Xã Đồng Thắng",
        "path": "Đồng Thắng, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Đồng Thắng, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15727",
        "parent_code": "397"
    },
    "15730": {
        "name": "Tiến Nông",
        "type": "xa",
        "slug": "tien-nong",
        "name_with_type": "Xã Tiến Nông",
        "path": "Tiến Nông, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Tiến Nông, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15730",
        "parent_code": "397"
    },
    "15733": {
        "name": "Khuyến Nông",
        "type": "xa",
        "slug": "khuyen-nong",
        "name_with_type": "Xã Khuyến Nông",
        "path": "Khuyến Nông, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Khuyến Nông, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15733",
        "parent_code": "397"
    },
    "15736": {
        "name": "Xuân Thịnh",
        "type": "xa",
        "slug": "xuan-thinh",
        "name_with_type": "Xã Xuân Thịnh",
        "path": "Xuân Thịnh, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Xuân Thịnh, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15736",
        "parent_code": "397"
    },
    "15739": {
        "name": "Xuân Lộc",
        "type": "xa",
        "slug": "xuan-loc",
        "name_with_type": "Xã Xuân Lộc",
        "path": "Xuân Lộc, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Xuân Lộc, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15739",
        "parent_code": "397"
    },
    "15742": {
        "name": "Thọ Dân",
        "type": "xa",
        "slug": "tho-dan",
        "name_with_type": "Xã Thọ Dân",
        "path": "Thọ Dân, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Dân, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15742",
        "parent_code": "397"
    },
    "15745": {
        "name": "Xuân Thọ",
        "type": "xa",
        "slug": "xuan-tho",
        "name_with_type": "Xã Xuân Thọ",
        "path": "Xuân Thọ, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Xuân Thọ, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15745",
        "parent_code": "397"
    },
    "15748": {
        "name": "Thọ Tân",
        "type": "xa",
        "slug": "tho-tan",
        "name_with_type": "Xã Thọ Tân",
        "path": "Thọ Tân, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Tân, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15748",
        "parent_code": "397"
    },
    "15751": {
        "name": "Thọ Ngọc",
        "type": "xa",
        "slug": "tho-ngoc",
        "name_with_type": "Xã Thọ Ngọc",
        "path": "Thọ Ngọc, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Ngọc, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15751",
        "parent_code": "397"
    },
    "15754": {
        "name": "Thọ Cường",
        "type": "xa",
        "slug": "tho-cuong",
        "name_with_type": "Xã Thọ Cường",
        "path": "Thọ Cường, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Cường, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15754",
        "parent_code": "397"
    },
    "15757": {
        "name": "Thọ Phú",
        "type": "xa",
        "slug": "tho-phu",
        "name_with_type": "Xã Thọ Phú",
        "path": "Thọ Phú, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Phú, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15757",
        "parent_code": "397"
    },
    "15760": {
        "name": "Thọ Vực",
        "type": "xa",
        "slug": "tho-vuc",
        "name_with_type": "Xã Thọ Vực",
        "path": "Thọ Vực, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Vực, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15760",
        "parent_code": "397"
    },
    "15763": {
        "name": "Thọ Thế",
        "type": "xa",
        "slug": "tho-the",
        "name_with_type": "Xã Thọ Thế",
        "path": "Thọ Thế, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Thọ Thế, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15763",
        "parent_code": "397"
    },
    "15766": {
        "name": "Nông Trường",
        "type": "xa",
        "slug": "nong-truong",
        "name_with_type": "Xã Nông Trường",
        "path": "Nông Trường, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Nông Trường, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15766",
        "parent_code": "397"
    },
    "15769": {
        "name": "Bình Sơn",
        "type": "xa",
        "slug": "binh-son",
        "name_with_type": "Xã Bình Sơn",
        "path": "Bình Sơn, Triệu Sơn, Thanh Hóa",
        "path_with_type": "Xã Bình Sơn, Huyện Triệu Sơn, Tỉnh Thanh Hóa",
        "code": "15769",
        "parent_code": "397"
    },
    "15772": {
        "name": "Vạn Hà",
        "type": "thi-tran",
        "slug": "van-ha",
        "name_with_type": "Thị trấn Vạn Hà",
        "path": "Vạn Hà, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Thị trấn Vạn Hà, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15772",
        "parent_code": "398"
    },
    "15775": {
        "name": "Thiệu Ngọc",
        "type": "xa",
        "slug": "thieu-ngoc",
        "name_with_type": "Xã Thiệu Ngọc",
        "path": "Thiệu Ngọc, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Ngọc, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15775",
        "parent_code": "398"
    },
    "15778": {
        "name": "Thiệu Vũ",
        "type": "xa",
        "slug": "thieu-vu",
        "name_with_type": "Xã Thiệu Vũ",
        "path": "Thiệu Vũ, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Vũ, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15778",
        "parent_code": "398"
    },
    "15781": {
        "name": "Thiệu Phúc",
        "type": "xa",
        "slug": "thieu-phuc",
        "name_with_type": "Xã Thiệu Phúc",
        "path": "Thiệu Phúc, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Phúc, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15781",
        "parent_code": "398"
    },
    "15784": {
        "name": "Thiệu Tiến",
        "type": "xa",
        "slug": "thieu-tien",
        "name_with_type": "Xã Thiệu Tiến",
        "path": "Thiệu Tiến, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Tiến, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15784",
        "parent_code": "398"
    },
    "15787": {
        "name": "Thiệu Công",
        "type": "xa",
        "slug": "thieu-cong",
        "name_with_type": "Xã Thiệu Công",
        "path": "Thiệu Công, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Công, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15787",
        "parent_code": "398"
    },
    "15790": {
        "name": "Thiệu Phú",
        "type": "xa",
        "slug": "thieu-phu",
        "name_with_type": "Xã Thiệu Phú",
        "path": "Thiệu Phú, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Phú, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15790",
        "parent_code": "398"
    },
    "15793": {
        "name": "Thiệu Long",
        "type": "xa",
        "slug": "thieu-long",
        "name_with_type": "Xã Thiệu Long",
        "path": "Thiệu Long, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Long, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15793",
        "parent_code": "398"
    },
    "15796": {
        "name": "Thiệu Giang",
        "type": "xa",
        "slug": "thieu-giang",
        "name_with_type": "Xã Thiệu Giang",
        "path": "Thiệu Giang, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Giang, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15796",
        "parent_code": "398"
    },
    "15799": {
        "name": "Thiệu Duy",
        "type": "xa",
        "slug": "thieu-duy",
        "name_with_type": "Xã Thiệu Duy",
        "path": "Thiệu Duy, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Duy, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15799",
        "parent_code": "398"
    },
    "15802": {
        "name": "Thiệu Nguyên",
        "type": "xa",
        "slug": "thieu-nguyen",
        "name_with_type": "Xã Thiệu Nguyên",
        "path": "Thiệu Nguyên, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Nguyên, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15802",
        "parent_code": "398"
    },
    "15805": {
        "name": "Thiệu Hợp",
        "type": "xa",
        "slug": "thieu-hop",
        "name_with_type": "Xã Thiệu Hợp",
        "path": "Thiệu Hợp, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Hợp, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15805",
        "parent_code": "398"
    },
    "15808": {
        "name": "Thiệu Thịnh",
        "type": "xa",
        "slug": "thieu-thinh",
        "name_with_type": "Xã Thiệu Thịnh",
        "path": "Thiệu Thịnh, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Thịnh, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15808",
        "parent_code": "398"
    },
    "15811": {
        "name": "Thiệu Quang",
        "type": "xa",
        "slug": "thieu-quang",
        "name_with_type": "Xã Thiệu Quang",
        "path": "Thiệu Quang, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Quang, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15811",
        "parent_code": "398"
    },
    "15814": {
        "name": "Thiệu Thành",
        "type": "xa",
        "slug": "thieu-thanh",
        "name_with_type": "Xã Thiệu Thành",
        "path": "Thiệu Thành, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Thành, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15814",
        "parent_code": "398"
    },
    "15817": {
        "name": "Thiệu Toán",
        "type": "xa",
        "slug": "thieu-toan",
        "name_with_type": "Xã Thiệu Toán",
        "path": "Thiệu Toán, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Toán, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15817",
        "parent_code": "398"
    },
    "15820": {
        "name": "Thiệu Chính",
        "type": "xa",
        "slug": "thieu-chinh",
        "name_with_type": "Xã Thiệu Chính",
        "path": "Thiệu Chính, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Chính, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15820",
        "parent_code": "398"
    },
    "15823": {
        "name": "Thiệu Hòa",
        "type": "xa",
        "slug": "thieu-hoa",
        "name_with_type": "Xã Thiệu Hòa",
        "path": "Thiệu Hòa, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Hòa, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15823",
        "parent_code": "398"
    },
    "15826": {
        "name": "Thiệu Minh",
        "type": "xa",
        "slug": "thieu-minh",
        "name_with_type": "Xã Thiệu Minh",
        "path": "Thiệu Minh, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Minh, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15826",
        "parent_code": "398"
    },
    "15829": {
        "name": "Thiệu Tâm",
        "type": "xa",
        "slug": "thieu-tam",
        "name_with_type": "Xã Thiệu Tâm",
        "path": "Thiệu Tâm, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Tâm, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15829",
        "parent_code": "398"
    },
    "15832": {
        "name": "Thiệu Viên",
        "type": "xa",
        "slug": "thieu-vien",
        "name_with_type": "Xã Thiệu Viên",
        "path": "Thiệu Viên, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Viên, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15832",
        "parent_code": "398"
    },
    "15835": {
        "name": "Thiệu Lý",
        "type": "xa",
        "slug": "thieu-ly",
        "name_with_type": "Xã Thiệu Lý",
        "path": "Thiệu Lý, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Lý, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15835",
        "parent_code": "398"
    },
    "15838": {
        "name": "Thiệu Vận",
        "type": "xa",
        "slug": "thieu-van",
        "name_with_type": "Xã Thiệu Vận",
        "path": "Thiệu Vận, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Vận, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15838",
        "parent_code": "398"
    },
    "15841": {
        "name": "Thiệu Trung",
        "type": "xa",
        "slug": "thieu-trung",
        "name_with_type": "Xã Thiệu Trung",
        "path": "Thiệu Trung, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Trung, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15841",
        "parent_code": "398"
    },
    "15844": {
        "name": "Thiệu Đô",
        "type": "xa",
        "slug": "thieu-do",
        "name_with_type": "Xã Thiệu Đô",
        "path": "Thiệu Đô, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Đô, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15844",
        "parent_code": "398"
    },
    "15847": {
        "name": "Thiệu Châu",
        "type": "xa",
        "slug": "thieu-chau",
        "name_with_type": "Xã Thiệu Châu",
        "path": "Thiệu Châu, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Châu, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15847",
        "parent_code": "398"
    },
    "15853": {
        "name": "Thiệu Giao",
        "type": "xa",
        "slug": "thieu-giao",
        "name_with_type": "Xã Thiệu Giao",
        "path": "Thiệu Giao, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Giao, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15853",
        "parent_code": "398"
    },
    "15862": {
        "name": "Thiệu Tân",
        "type": "xa",
        "slug": "thieu-tan",
        "name_with_type": "Xã Thiệu Tân",
        "path": "Thiệu Tân, Thiệu Hóa, Thanh Hóa",
        "path_with_type": "Xã Thiệu Tân, Huyện Thiệu Hóa, Tỉnh Thanh Hóa",
        "code": "15862",
        "parent_code": "398"
    },
    "15865": {
        "name": "Bút Sơn",
        "type": "thi-tran",
        "slug": "but-son",
        "name_with_type": "Thị trấn Bút Sơn",
        "path": "Bút Sơn, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Thị trấn Bút Sơn, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15865",
        "parent_code": "399"
    },
    "15871": {
        "name": "Hoằng Giang",
        "type": "xa",
        "slug": "hoang-giang",
        "name_with_type": "Xã Hoằng Giang",
        "path": "Hoằng Giang, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Giang, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15871",
        "parent_code": "399"
    },
    "15874": {
        "name": "Hoằng Xuân",
        "type": "xa",
        "slug": "hoang-xuan",
        "name_with_type": "Xã Hoằng Xuân",
        "path": "Hoằng Xuân, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Xuân, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15874",
        "parent_code": "399"
    },
    "15877": {
        "name": "Hoằng Khánh",
        "type": "xa",
        "slug": "hoang-khanh",
        "name_with_type": "Xã Hoằng Khánh",
        "path": "Hoằng Khánh, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Khánh, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15877",
        "parent_code": "399"
    },
    "15880": {
        "name": "Hoằng Phượng",
        "type": "xa",
        "slug": "hoang-phuong",
        "name_with_type": "Xã Hoằng Phượng",
        "path": "Hoằng Phượng, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Phượng, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15880",
        "parent_code": "399"
    },
    "15883": {
        "name": "Hoằng Phú",
        "type": "xa",
        "slug": "hoang-phu",
        "name_with_type": "Xã Hoằng Phú",
        "path": "Hoằng Phú, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Phú, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15883",
        "parent_code": "399"
    },
    "15886": {
        "name": "Hoằng Quỳ",
        "type": "xa",
        "slug": "hoang-quy",
        "name_with_type": "Xã Hoằng Quỳ",
        "path": "Hoằng Quỳ, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Quỳ, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15886",
        "parent_code": "399"
    },
    "15889": {
        "name": "Hoằng Kim",
        "type": "xa",
        "slug": "hoang-kim",
        "name_with_type": "Xã Hoằng Kim",
        "path": "Hoằng Kim, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Kim, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15889",
        "parent_code": "399"
    },
    "15892": {
        "name": "Hoằng Trung",
        "type": "xa",
        "slug": "hoang-trung",
        "name_with_type": "Xã Hoằng Trung",
        "path": "Hoằng Trung, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Trung, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15892",
        "parent_code": "399"
    },
    "15895": {
        "name": "Hoằng Trinh",
        "type": "xa",
        "slug": "hoang-trinh",
        "name_with_type": "Xã Hoằng Trinh",
        "path": "Hoằng Trinh, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Trinh, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15895",
        "parent_code": "399"
    },
    "15898": {
        "name": "Hoằng Sơn",
        "type": "xa",
        "slug": "hoang-son",
        "name_with_type": "Xã Hoằng Sơn",
        "path": "Hoằng Sơn, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Sơn, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15898",
        "parent_code": "399"
    },
    "15901": {
        "name": "Hoằng Lương",
        "type": "xa",
        "slug": "hoang-luong",
        "name_with_type": "Xã Hoằng Lương",
        "path": "Hoằng Lương, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Lương, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15901",
        "parent_code": "399"
    },
    "15904": {
        "name": "Hoằng Xuyên",
        "type": "xa",
        "slug": "hoang-xuyen",
        "name_with_type": "Xã Hoằng Xuyên",
        "path": "Hoằng Xuyên, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Xuyên, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15904",
        "parent_code": "399"
    },
    "15907": {
        "name": "Hoằng Cát",
        "type": "xa",
        "slug": "hoang-cat",
        "name_with_type": "Xã Hoằng Cát",
        "path": "Hoằng Cát, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Cát, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15907",
        "parent_code": "399"
    },
    "15910": {
        "name": "Hoằng Khê",
        "type": "xa",
        "slug": "hoang-khe",
        "name_with_type": "Xã Hoằng Khê",
        "path": "Hoằng Khê, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Khê, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15910",
        "parent_code": "399"
    },
    "15916": {
        "name": "Hoằng Quý",
        "type": "xa",
        "slug": "hoang-quy",
        "name_with_type": "Xã Hoằng Quý",
        "path": "Hoằng Quý, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Quý, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15916",
        "parent_code": "399"
    },
    "15919": {
        "name": "Hoằng Hợp",
        "type": "xa",
        "slug": "hoang-hop",
        "name_with_type": "Xã Hoằng Hợp",
        "path": "Hoằng Hợp, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Hợp, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15919",
        "parent_code": "399"
    },
    "15928": {
        "name": "Hoằng Minh",
        "type": "xa",
        "slug": "hoang-minh",
        "name_with_type": "Xã Hoằng Minh",
        "path": "Hoằng Minh, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Minh, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15928",
        "parent_code": "399"
    },
    "15931": {
        "name": "Hoằng Phúc",
        "type": "xa",
        "slug": "hoang-phuc",
        "name_with_type": "Xã Hoằng Phúc",
        "path": "Hoằng Phúc, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Phúc, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15931",
        "parent_code": "399"
    },
    "15934": {
        "name": "Hoằng Đức",
        "type": "xa",
        "slug": "hoang-duc",
        "name_with_type": "Xã Hoằng Đức",
        "path": "Hoằng Đức, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Đức, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15934",
        "parent_code": "399"
    },
    "15937": {
        "name": "Hoằng Hà",
        "type": "xa",
        "slug": "hoang-ha",
        "name_with_type": "Xã Hoằng Hà",
        "path": "Hoằng Hà, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Hà, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15937",
        "parent_code": "399"
    },
    "15940": {
        "name": "Hoằng Đạt",
        "type": "xa",
        "slug": "hoang-dat",
        "name_with_type": "Xã Hoằng Đạt",
        "path": "Hoằng Đạt, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Đạt, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15940",
        "parent_code": "399"
    },
    "15943": {
        "name": "Hoằng Vinh",
        "type": "xa",
        "slug": "hoang-vinh",
        "name_with_type": "Xã Hoằng Vinh",
        "path": "Hoằng Vinh, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Vinh, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15943",
        "parent_code": "399"
    },
    "15946": {
        "name": "Hoằng Đạo",
        "type": "xa",
        "slug": "hoang-dao",
        "name_with_type": "Xã Hoằng Đạo",
        "path": "Hoằng Đạo, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Đạo, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15946",
        "parent_code": "399"
    },
    "15949": {
        "name": "Hoằng Thắng",
        "type": "xa",
        "slug": "hoang-thang",
        "name_with_type": "Xã Hoằng Thắng",
        "path": "Hoằng Thắng, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Thắng, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15949",
        "parent_code": "399"
    },
    "15952": {
        "name": "Hoằng Đồng",
        "type": "xa",
        "slug": "hoang-dong",
        "name_with_type": "Xã Hoằng Đồng",
        "path": "Hoằng Đồng, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Đồng, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15952",
        "parent_code": "399"
    },
    "15955": {
        "name": "Hoằng Thái",
        "type": "xa",
        "slug": "hoang-thai",
        "name_with_type": "Xã Hoằng Thái",
        "path": "Hoằng Thái, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Thái, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15955",
        "parent_code": "399"
    },
    "15958": {
        "name": "Hoằng Thịnh",
        "type": "xa",
        "slug": "hoang-thinh",
        "name_with_type": "Xã Hoằng Thịnh",
        "path": "Hoằng Thịnh, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Thịnh, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15958",
        "parent_code": "399"
    },
    "15961": {
        "name": "Hoằng Thành",
        "type": "xa",
        "slug": "hoang-thanh",
        "name_with_type": "Xã Hoằng Thành",
        "path": "Hoằng Thành, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Thành, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15961",
        "parent_code": "399"
    },
    "15964": {
        "name": "Hoằng Lộc",
        "type": "xa",
        "slug": "hoang-loc",
        "name_with_type": "Xã Hoằng Lộc",
        "path": "Hoằng Lộc, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Lộc, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15964",
        "parent_code": "399"
    },
    "15967": {
        "name": "Hoằng Trạch",
        "type": "xa",
        "slug": "hoang-trach",
        "name_with_type": "Xã Hoằng Trạch",
        "path": "Hoằng Trạch, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Trạch, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15967",
        "parent_code": "399"
    },
    "15973": {
        "name": "Hoằng Phong",
        "type": "xa",
        "slug": "hoang-phong",
        "name_with_type": "Xã Hoằng Phong",
        "path": "Hoằng Phong, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Phong, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15973",
        "parent_code": "399"
    },
    "15976": {
        "name": "Hoằng Lưu",
        "type": "xa",
        "slug": "hoang-luu",
        "name_with_type": "Xã Hoằng Lưu",
        "path": "Hoằng Lưu, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Lưu, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15976",
        "parent_code": "399"
    },
    "15979": {
        "name": "Hoằng Châu",
        "type": "xa",
        "slug": "hoang-chau",
        "name_with_type": "Xã Hoằng Châu",
        "path": "Hoằng Châu, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Châu, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15979",
        "parent_code": "399"
    },
    "15982": {
        "name": "Hoằng Tân",
        "type": "xa",
        "slug": "hoang-tan",
        "name_with_type": "Xã Hoằng Tân",
        "path": "Hoằng Tân, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Tân, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15982",
        "parent_code": "399"
    },
    "15985": {
        "name": "Hoằng Yến",
        "type": "xa",
        "slug": "hoang-yen",
        "name_with_type": "Xã Hoằng Yến",
        "path": "Hoằng Yến, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Yến, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15985",
        "parent_code": "399"
    },
    "15988": {
        "name": "Hoằng Tiến",
        "type": "xa",
        "slug": "hoang-tien",
        "name_with_type": "Xã Hoằng Tiến",
        "path": "Hoằng Tiến, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Tiến, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15988",
        "parent_code": "399"
    },
    "15991": {
        "name": "Hoằng Hải",
        "type": "xa",
        "slug": "hoang-hai",
        "name_with_type": "Xã Hoằng Hải",
        "path": "Hoằng Hải, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Hải, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15991",
        "parent_code": "399"
    },
    "15994": {
        "name": "Hoằng Ngọc",
        "type": "xa",
        "slug": "hoang-ngoc",
        "name_with_type": "Xã Hoằng Ngọc",
        "path": "Hoằng Ngọc, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Ngọc, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15994",
        "parent_code": "399"
    },
    "15997": {
        "name": "Hoằng Đông",
        "type": "xa",
        "slug": "hoang-dong",
        "name_with_type": "Xã Hoằng Đông",
        "path": "Hoằng Đông, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Đông, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "15997",
        "parent_code": "399"
    },
    "16000": {
        "name": "Hoằng Thanh",
        "type": "xa",
        "slug": "hoang-thanh",
        "name_with_type": "Xã Hoằng Thanh",
        "path": "Hoằng Thanh, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Thanh, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "16000",
        "parent_code": "399"
    },
    "16003": {
        "name": "Hoằng Phụ",
        "type": "xa",
        "slug": "hoang-phu",
        "name_with_type": "Xã Hoằng Phụ",
        "path": "Hoằng Phụ, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Phụ, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "16003",
        "parent_code": "399"
    },
    "16006": {
        "name": "Hoằng Trường",
        "type": "xa",
        "slug": "hoang-truong",
        "name_with_type": "Xã Hoằng Trường",
        "path": "Hoằng Trường, Hoằng Hóa, Thanh Hóa",
        "path_with_type": "Xã Hoằng Trường, Huyện Hoằng Hóa, Tỉnh Thanh Hóa",
        "code": "16006",
        "parent_code": "399"
    },
    "16012": {
        "name": "Hậu Lộc",
        "type": "thi-tran",
        "slug": "hau-loc",
        "name_with_type": "Thị trấn Hậu Lộc",
        "path": "Hậu Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Thị trấn Hậu Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16012",
        "parent_code": "400"
    },
    "16015": {
        "name": "Đồng Lộc",
        "type": "xa",
        "slug": "dong-loc",
        "name_with_type": "Xã Đồng Lộc",
        "path": "Đồng Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Đồng Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16015",
        "parent_code": "400"
    },
    "16018": {
        "name": "Đại Lộc",
        "type": "xa",
        "slug": "dai-loc",
        "name_with_type": "Xã Đại Lộc",
        "path": "Đại Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Đại Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16018",
        "parent_code": "400"
    },
    "16021": {
        "name": "Triệu Lộc",
        "type": "xa",
        "slug": "trieu-loc",
        "name_with_type": "Xã Triệu Lộc",
        "path": "Triệu Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Triệu Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16021",
        "parent_code": "400"
    },
    "16024": {
        "name": "Châu Lộc",
        "type": "xa",
        "slug": "chau-loc",
        "name_with_type": "Xã Châu Lộc",
        "path": "Châu Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Châu Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16024",
        "parent_code": "400"
    },
    "16027": {
        "name": "Tiến Lộc",
        "type": "xa",
        "slug": "tien-loc",
        "name_with_type": "Xã Tiến Lộc",
        "path": "Tiến Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Tiến Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16027",
        "parent_code": "400"
    },
    "16030": {
        "name": "Lộc Sơn",
        "type": "xa",
        "slug": "loc-son",
        "name_with_type": "Xã Lộc Sơn",
        "path": "Lộc Sơn, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Lộc Sơn, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16030",
        "parent_code": "400"
    },
    "16033": {
        "name": "Cầu Lộc",
        "type": "xa",
        "slug": "cau-loc",
        "name_with_type": "Xã Cầu Lộc",
        "path": "Cầu Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Cầu Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16033",
        "parent_code": "400"
    },
    "16036": {
        "name": "Thành Lộc",
        "type": "xa",
        "slug": "thanh-loc",
        "name_with_type": "Xã Thành Lộc",
        "path": "Thành Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Thành Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16036",
        "parent_code": "400"
    },
    "16039": {
        "name": "Tuy Lộc",
        "type": "xa",
        "slug": "tuy-loc",
        "name_with_type": "Xã Tuy Lộc",
        "path": "Tuy Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Tuy Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16039",
        "parent_code": "400"
    },
    "16042": {
        "name": "Phong Lộc",
        "type": "xa",
        "slug": "phong-loc",
        "name_with_type": "Xã Phong Lộc",
        "path": "Phong Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Phong Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16042",
        "parent_code": "400"
    },
    "16045": {
        "name": "Mỹ Lộc",
        "type": "xa",
        "slug": "my-loc",
        "name_with_type": "Xã Mỹ Lộc",
        "path": "Mỹ Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Mỹ Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16045",
        "parent_code": "400"
    },
    "16048": {
        "name": "Văn Lộc",
        "type": "xa",
        "slug": "van-loc",
        "name_with_type": "Xã Văn Lộc",
        "path": "Văn Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Văn Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16048",
        "parent_code": "400"
    },
    "16051": {
        "name": "Thuần Lộc",
        "type": "xa",
        "slug": "thuan-loc",
        "name_with_type": "Xã Thuần Lộc",
        "path": "Thuần Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Thuần Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16051",
        "parent_code": "400"
    },
    "16054": {
        "name": "Lộc Tân",
        "type": "xa",
        "slug": "loc-tan",
        "name_with_type": "Xã Lộc Tân",
        "path": "Lộc Tân, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Lộc Tân, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16054",
        "parent_code": "400"
    },
    "16057": {
        "name": "Xuân Lộc",
        "type": "xa",
        "slug": "xuan-loc",
        "name_with_type": "Xã Xuân Lộc",
        "path": "Xuân Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Xuân Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16057",
        "parent_code": "400"
    },
    "16060": {
        "name": "Thịnh Lộc",
        "type": "xa",
        "slug": "thinh-loc",
        "name_with_type": "Xã Thịnh Lộc",
        "path": "Thịnh Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Thịnh Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16060",
        "parent_code": "400"
    },
    "16063": {
        "name": "Hoa Lộc",
        "type": "xa",
        "slug": "hoa-loc",
        "name_with_type": "Xã Hoa Lộc",
        "path": "Hoa Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Hoa Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16063",
        "parent_code": "400"
    },
    "16066": {
        "name": "Liên Lộc",
        "type": "xa",
        "slug": "lien-loc",
        "name_with_type": "Xã Liên Lộc",
        "path": "Liên Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Liên Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16066",
        "parent_code": "400"
    },
    "16069": {
        "name": "Quang Lộc",
        "type": "xa",
        "slug": "quang-loc",
        "name_with_type": "Xã Quang Lộc",
        "path": "Quang Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Quang Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16069",
        "parent_code": "400"
    },
    "16072": {
        "name": "Phú Lộc",
        "type": "xa",
        "slug": "phu-loc",
        "name_with_type": "Xã Phú Lộc",
        "path": "Phú Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Phú Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16072",
        "parent_code": "400"
    },
    "16075": {
        "name": "Hòa Lộc",
        "type": "xa",
        "slug": "hoa-loc",
        "name_with_type": "Xã Hòa Lộc",
        "path": "Hòa Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Hòa Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16075",
        "parent_code": "400"
    },
    "16078": {
        "name": "Minh Lộc",
        "type": "xa",
        "slug": "minh-loc",
        "name_with_type": "Xã Minh Lộc",
        "path": "Minh Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Minh Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16078",
        "parent_code": "400"
    },
    "16081": {
        "name": "Hưng Lộc",
        "type": "xa",
        "slug": "hung-loc",
        "name_with_type": "Xã Hưng Lộc",
        "path": "Hưng Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Hưng Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16081",
        "parent_code": "400"
    },
    "16084": {
        "name": "Hải Lộc",
        "type": "xa",
        "slug": "hai-loc",
        "name_with_type": "Xã Hải Lộc",
        "path": "Hải Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Hải Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16084",
        "parent_code": "400"
    },
    "16087": {
        "name": "Đa Lộc",
        "type": "xa",
        "slug": "da-loc",
        "name_with_type": "Xã Đa Lộc",
        "path": "Đa Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Đa Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16087",
        "parent_code": "400"
    },
    "16090": {
        "name": "Ngư Lộc",
        "type": "xa",
        "slug": "ngu-loc",
        "name_with_type": "Xã Ngư Lộc",
        "path": "Ngư Lộc, Hậu Lộc, Thanh Hóa",
        "path_with_type": "Xã Ngư Lộc, Huyện Hậu Lộc, Tỉnh Thanh Hóa",
        "code": "16090",
        "parent_code": "400"
    },
    "16093": {
        "name": "Nga Sơn",
        "type": "thi-tran",
        "slug": "nga-son",
        "name_with_type": "Thị trấn Nga Sơn",
        "path": "Nga Sơn, Nga Sơn, Thanh Hóa",
        "path_with_type": "Thị trấn Nga Sơn, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16093",
        "parent_code": "401"
    },
    "16096": {
        "name": "Ba Đình",
        "type": "xa",
        "slug": "ba-dinh",
        "name_with_type": "Xã Ba Đình",
        "path": "Ba Đình, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Ba Đình, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16096",
        "parent_code": "401"
    },
    "16099": {
        "name": "Nga Vịnh",
        "type": "xa",
        "slug": "nga-vinh",
        "name_with_type": "Xã Nga Vịnh",
        "path": "Nga Vịnh, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Vịnh, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16099",
        "parent_code": "401"
    },
    "16102": {
        "name": "Nga Văn",
        "type": "xa",
        "slug": "nga-van",
        "name_with_type": "Xã Nga Văn",
        "path": "Nga Văn, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Văn, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16102",
        "parent_code": "401"
    },
    "16105": {
        "name": "Nga Thiện",
        "type": "xa",
        "slug": "nga-thien",
        "name_with_type": "Xã Nga Thiện",
        "path": "Nga Thiện, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Thiện, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16105",
        "parent_code": "401"
    },
    "16108": {
        "name": "Nga Tiến",
        "type": "xa",
        "slug": "nga-tien",
        "name_with_type": "Xã Nga Tiến",
        "path": "Nga Tiến, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Tiến, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16108",
        "parent_code": "401"
    },
    "16111": {
        "name": "Nga Lĩnh",
        "type": "xa",
        "slug": "nga-linh",
        "name_with_type": "Xã Nga Lĩnh",
        "path": "Nga Lĩnh, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Lĩnh, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16111",
        "parent_code": "401"
    },
    "16114": {
        "name": "Nga Nhân",
        "type": "xa",
        "slug": "nga-nhan",
        "name_with_type": "Xã Nga Nhân",
        "path": "Nga Nhân, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Nhân, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16114",
        "parent_code": "401"
    },
    "16117": {
        "name": "Nga Trung",
        "type": "xa",
        "slug": "nga-trung",
        "name_with_type": "Xã Nga Trung",
        "path": "Nga Trung, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Trung, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16117",
        "parent_code": "401"
    },
    "16120": {
        "name": "Nga Bạch",
        "type": "xa",
        "slug": "nga-bach",
        "name_with_type": "Xã Nga Bạch",
        "path": "Nga Bạch, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Bạch, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16120",
        "parent_code": "401"
    },
    "16123": {
        "name": "Nga Thanh",
        "type": "xa",
        "slug": "nga-thanh",
        "name_with_type": "Xã Nga Thanh",
        "path": "Nga Thanh, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Thanh, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16123",
        "parent_code": "401"
    },
    "16126": {
        "name": "Nga Hưng",
        "type": "xa",
        "slug": "nga-hung",
        "name_with_type": "Xã Nga Hưng",
        "path": "Nga Hưng, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Hưng, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16126",
        "parent_code": "401"
    },
    "16129": {
        "name": "Nga Mỹ",
        "type": "xa",
        "slug": "nga-my",
        "name_with_type": "Xã Nga Mỹ",
        "path": "Nga Mỹ, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Mỹ, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16129",
        "parent_code": "401"
    },
    "16132": {
        "name": "Nga Yên",
        "type": "xa",
        "slug": "nga-yen",
        "name_with_type": "Xã Nga Yên",
        "path": "Nga Yên, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Yên, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16132",
        "parent_code": "401"
    },
    "16135": {
        "name": "Nga Giáp",
        "type": "xa",
        "slug": "nga-giap",
        "name_with_type": "Xã Nga Giáp",
        "path": "Nga Giáp, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Giáp, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16135",
        "parent_code": "401"
    },
    "16138": {
        "name": "Nga Hải",
        "type": "xa",
        "slug": "nga-hai",
        "name_with_type": "Xã Nga Hải",
        "path": "Nga Hải, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Hải, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16138",
        "parent_code": "401"
    },
    "16141": {
        "name": "Nga Thành",
        "type": "xa",
        "slug": "nga-thanh",
        "name_with_type": "Xã Nga Thành",
        "path": "Nga Thành, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Thành, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16141",
        "parent_code": "401"
    },
    "16144": {
        "name": "Nga An",
        "type": "xa",
        "slug": "nga-an",
        "name_with_type": "Xã Nga An",
        "path": "Nga An, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga An, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16144",
        "parent_code": "401"
    },
    "16147": {
        "name": "Nga Phú",
        "type": "xa",
        "slug": "nga-phu",
        "name_with_type": "Xã Nga Phú",
        "path": "Nga Phú, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Phú, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16147",
        "parent_code": "401"
    },
    "16150": {
        "name": "Nga Điền",
        "type": "xa",
        "slug": "nga-dien",
        "name_with_type": "Xã Nga Điền",
        "path": "Nga Điền, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Điền, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16150",
        "parent_code": "401"
    },
    "16153": {
        "name": "Nga Tân",
        "type": "xa",
        "slug": "nga-tan",
        "name_with_type": "Xã Nga Tân",
        "path": "Nga Tân, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Tân, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16153",
        "parent_code": "401"
    },
    "16156": {
        "name": "Nga Thủy",
        "type": "xa",
        "slug": "nga-thuy",
        "name_with_type": "Xã Nga Thủy",
        "path": "Nga Thủy, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Thủy, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16156",
        "parent_code": "401"
    },
    "16159": {
        "name": "Nga Liên",
        "type": "xa",
        "slug": "nga-lien",
        "name_with_type": "Xã Nga Liên",
        "path": "Nga Liên, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Liên, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16159",
        "parent_code": "401"
    },
    "16162": {
        "name": "Nga Thái",
        "type": "xa",
        "slug": "nga-thai",
        "name_with_type": "Xã Nga Thái",
        "path": "Nga Thái, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Thái, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16162",
        "parent_code": "401"
    },
    "16165": {
        "name": "Nga Thạch",
        "type": "xa",
        "slug": "nga-thach",
        "name_with_type": "Xã Nga Thạch",
        "path": "Nga Thạch, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Thạch, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16165",
        "parent_code": "401"
    },
    "16168": {
        "name": "Nga Thắng",
        "type": "xa",
        "slug": "nga-thang",
        "name_with_type": "Xã Nga Thắng",
        "path": "Nga Thắng, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Thắng, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16168",
        "parent_code": "401"
    },
    "16171": {
        "name": "Nga Trường",
        "type": "xa",
        "slug": "nga-truong",
        "name_with_type": "Xã Nga Trường",
        "path": "Nga Trường, Nga Sơn, Thanh Hóa",
        "path_with_type": "Xã Nga Trường, Huyện Nga Sơn, Tỉnh Thanh Hóa",
        "code": "16171",
        "parent_code": "401"
    },
    "16174": {
        "name": "Yên Cát",
        "type": "thi-tran",
        "slug": "yen-cat",
        "name_with_type": "Thị trấn Yên Cát",
        "path": "Yên Cát, Như Xuân, Thanh Hóa",
        "path_with_type": "Thị trấn Yên Cát, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16174",
        "parent_code": "402"
    },
    "16177": {
        "name": "Bãi Trành",
        "type": "xa",
        "slug": "bai-tranh",
        "name_with_type": "Xã Bãi Trành",
        "path": "Bãi Trành, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Bãi Trành, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16177",
        "parent_code": "402"
    },
    "16180": {
        "name": "Xuân Hòa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hòa",
        "path": "Xuân Hòa, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Hòa, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16180",
        "parent_code": "402"
    },
    "16183": {
        "name": "Xuân Bình",
        "type": "xa",
        "slug": "xuan-binh",
        "name_with_type": "Xã Xuân Bình",
        "path": "Xuân Bình, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Bình, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16183",
        "parent_code": "402"
    },
    "16186": {
        "name": "Hóa Quỳ",
        "type": "xa",
        "slug": "hoa-quy",
        "name_with_type": "Xã Hóa Quỳ",
        "path": "Hóa Quỳ, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Hóa Quỳ, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16186",
        "parent_code": "402"
    },
    "16189": {
        "name": "Xuân Quỳ",
        "type": "xa",
        "slug": "xuan-quy",
        "name_with_type": "Xã Xuân Quỳ",
        "path": "Xuân Quỳ, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Xuân Quỳ, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16189",
        "parent_code": "402"
    },
    "16192": {
        "name": "Yên Lễ",
        "type": "xa",
        "slug": "yen-le",
        "name_with_type": "Xã Yên Lễ",
        "path": "Yên Lễ, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Yên Lễ, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16192",
        "parent_code": "402"
    },
    "16195": {
        "name": "Cát Vân",
        "type": "xa",
        "slug": "cat-van",
        "name_with_type": "Xã Cát Vân",
        "path": "Cát Vân, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Cát Vân, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16195",
        "parent_code": "402"
    },
    "16198": {
        "name": "Cát Tân",
        "type": "xa",
        "slug": "cat-tan",
        "name_with_type": "Xã Cát Tân",
        "path": "Cát Tân, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Cát Tân, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16198",
        "parent_code": "402"
    },
    "16201": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Tân Bình, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16201",
        "parent_code": "402"
    },
    "16204": {
        "name": "Bình Lương",
        "type": "xa",
        "slug": "binh-luong",
        "name_with_type": "Xã Bình Lương",
        "path": "Bình Lương, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Bình Lương, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16204",
        "parent_code": "402"
    },
    "16207": {
        "name": "Thanh Quân",
        "type": "xa",
        "slug": "thanh-quan",
        "name_with_type": "Xã Thanh Quân",
        "path": "Thanh Quân, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Thanh Quân, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16207",
        "parent_code": "402"
    },
    "16210": {
        "name": "Thanh Xuân",
        "type": "xa",
        "slug": "thanh-xuan",
        "name_with_type": "Xã Thanh Xuân",
        "path": "Thanh Xuân, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Thanh Xuân, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16210",
        "parent_code": "402"
    },
    "16213": {
        "name": "Thanh Hòa",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thanh Hòa",
        "path": "Thanh Hòa, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Thanh Hòa, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16213",
        "parent_code": "402"
    },
    "16216": {
        "name": "Thanh Phong",
        "type": "xa",
        "slug": "thanh-phong",
        "name_with_type": "Xã Thanh Phong",
        "path": "Thanh Phong, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Thanh Phong, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16216",
        "parent_code": "402"
    },
    "16219": {
        "name": "Thanh Lâm",
        "type": "xa",
        "slug": "thanh-lam",
        "name_with_type": "Xã Thanh Lâm",
        "path": "Thanh Lâm, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Thanh Lâm, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16219",
        "parent_code": "402"
    },
    "16222": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Thanh Sơn, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16222",
        "parent_code": "402"
    },
    "16225": {
        "name": "Thượng Ninh",
        "type": "xa",
        "slug": "thuong-ninh",
        "name_with_type": "Xã Thượng Ninh",
        "path": "Thượng Ninh, Như Xuân, Thanh Hóa",
        "path_with_type": "Xã Thượng Ninh, Huyện Như Xuân, Tỉnh Thanh Hóa",
        "code": "16225",
        "parent_code": "402"
    },
    "16228": {
        "name": "Bến Sung",
        "type": "thi-tran",
        "slug": "ben-sung",
        "name_with_type": "Thị trấn Bến Sung",
        "path": "Bến Sung, Như Thanh, Thanh Hóa",
        "path_with_type": "Thị trấn Bến Sung, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16228",
        "parent_code": "403"
    },
    "16231": {
        "name": "Cán Khê",
        "type": "xa",
        "slug": "can-khe",
        "name_with_type": "Xã Cán Khê",
        "path": "Cán Khê, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Cán Khê, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16231",
        "parent_code": "403"
    },
    "16234": {
        "name": "Xuân Du",
        "type": "xa",
        "slug": "xuan-du",
        "name_with_type": "Xã Xuân Du",
        "path": "Xuân Du, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Xuân Du, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16234",
        "parent_code": "403"
    },
    "16237": {
        "name": "Xuân Thọ",
        "type": "xa",
        "slug": "xuan-tho",
        "name_with_type": "Xã Xuân Thọ",
        "path": "Xuân Thọ, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Xuân Thọ, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16237",
        "parent_code": "403"
    },
    "16240": {
        "name": "Phượng Nghi",
        "type": "xa",
        "slug": "phuong-nghi",
        "name_with_type": "Xã Phượng Nghi",
        "path": "Phượng Nghi, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Phượng Nghi, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16240",
        "parent_code": "403"
    },
    "16243": {
        "name": "Mậu Lâm",
        "type": "xa",
        "slug": "mau-lam",
        "name_with_type": "Xã Mậu Lâm",
        "path": "Mậu Lâm, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Mậu Lâm, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16243",
        "parent_code": "403"
    },
    "16246": {
        "name": "Xuân Khang",
        "type": "xa",
        "slug": "xuan-khang",
        "name_with_type": "Xã Xuân Khang",
        "path": "Xuân Khang, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Xuân Khang, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16246",
        "parent_code": "403"
    },
    "16249": {
        "name": "Phú Nhuận",
        "type": "xa",
        "slug": "phu-nhuan",
        "name_with_type": "Xã Phú Nhuận",
        "path": "Phú Nhuận, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Phú Nhuận, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16249",
        "parent_code": "403"
    },
    "16252": {
        "name": "Hải Long",
        "type": "xa",
        "slug": "hai-long",
        "name_with_type": "Xã Hải Long",
        "path": "Hải Long, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Hải Long, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16252",
        "parent_code": "403"
    },
    "16255": {
        "name": "Hải Vân",
        "type": "xa",
        "slug": "hai-van",
        "name_with_type": "Xã Hải Vân",
        "path": "Hải Vân, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Hải Vân, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16255",
        "parent_code": "403"
    },
    "16258": {
        "name": "Xuân Thái",
        "type": "xa",
        "slug": "xuan-thai",
        "name_with_type": "Xã Xuân Thái",
        "path": "Xuân Thái, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Xuân Thái, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16258",
        "parent_code": "403"
    },
    "16261": {
        "name": "Xuân Phúc",
        "type": "xa",
        "slug": "xuan-phuc",
        "name_with_type": "Xã Xuân Phúc",
        "path": "Xuân Phúc, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Xuân Phúc, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16261",
        "parent_code": "403"
    },
    "16264": {
        "name": "Yên Thọ",
        "type": "xa",
        "slug": "yen-tho",
        "name_with_type": "Xã Yên Thọ",
        "path": "Yên Thọ, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Yên Thọ, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16264",
        "parent_code": "403"
    },
    "16267": {
        "name": "Yên Lạc",
        "type": "xa",
        "slug": "yen-lac",
        "name_with_type": "Xã Yên Lạc",
        "path": "Yên Lạc, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Yên Lạc, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16267",
        "parent_code": "403"
    },
    "16270": {
        "name": "Phúc Đường",
        "type": "xa",
        "slug": "phuc-duong",
        "name_with_type": "Xã Phúc Đường",
        "path": "Phúc Đường, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Phúc Đường, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16270",
        "parent_code": "403"
    },
    "16273": {
        "name": "Thanh Tân",
        "type": "xa",
        "slug": "thanh-tan",
        "name_with_type": "Xã Thanh Tân",
        "path": "Thanh Tân, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Thanh Tân, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16273",
        "parent_code": "403"
    },
    "16276": {
        "name": "Thanh Kỳ",
        "type": "xa",
        "slug": "thanh-ky",
        "name_with_type": "Xã Thanh Kỳ",
        "path": "Thanh Kỳ, Như Thanh, Thanh Hóa",
        "path_with_type": "Xã Thanh Kỳ, Huyện Như Thanh, Tỉnh Thanh Hóa",
        "code": "16276",
        "parent_code": "403"
    },
    "16279": {
        "name": "Nông Cống",
        "type": "thi-tran",
        "slug": "nong-cong",
        "name_with_type": "Thị trấn Nông Cống",
        "path": "Nông Cống, Nông Cống, Thanh Hóa",
        "path_with_type": "Thị trấn Nông Cống, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16279",
        "parent_code": "404"
    },
    "16282": {
        "name": "Tân Phúc",
        "type": "xa",
        "slug": "tan-phuc",
        "name_with_type": "Xã Tân Phúc",
        "path": "Tân Phúc, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tân Phúc, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16282",
        "parent_code": "404"
    },
    "16285": {
        "name": "Tân Thọ",
        "type": "xa",
        "slug": "tan-tho",
        "name_with_type": "Xã Tân Thọ",
        "path": "Tân Thọ, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tân Thọ, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16285",
        "parent_code": "404"
    },
    "16288": {
        "name": "Hoàng Sơn",
        "type": "xa",
        "slug": "hoang-son",
        "name_with_type": "Xã Hoàng Sơn",
        "path": "Hoàng Sơn, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Hoàng Sơn, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16288",
        "parent_code": "404"
    },
    "16291": {
        "name": "Tân Khang",
        "type": "xa",
        "slug": "tan-khang",
        "name_with_type": "Xã Tân Khang",
        "path": "Tân Khang, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tân Khang, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16291",
        "parent_code": "404"
    },
    "16294": {
        "name": "Hoàng Giang",
        "type": "xa",
        "slug": "hoang-giang",
        "name_with_type": "Xã Hoàng Giang",
        "path": "Hoàng Giang, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Hoàng Giang, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16294",
        "parent_code": "404"
    },
    "16297": {
        "name": "Trung Chính",
        "type": "xa",
        "slug": "trung-chinh",
        "name_with_type": "Xã Trung Chính",
        "path": "Trung Chính, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Trung Chính, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16297",
        "parent_code": "404"
    },
    "16300": {
        "name": "Trung Ý",
        "type": "xa",
        "slug": "trung-y",
        "name_with_type": "Xã Trung Ý",
        "path": "Trung Ý, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Trung Ý, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16300",
        "parent_code": "404"
    },
    "16303": {
        "name": "Trung Thành",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thành",
        "path": "Trung Thành, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Trung Thành, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16303",
        "parent_code": "404"
    },
    "16306": {
        "name": "Tế Tân",
        "type": "xa",
        "slug": "te-tan",
        "name_with_type": "Xã Tế Tân",
        "path": "Tế Tân, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tế Tân, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16306",
        "parent_code": "404"
    },
    "16309": {
        "name": "Tế Thắng",
        "type": "xa",
        "slug": "te-thang",
        "name_with_type": "Xã Tế Thắng",
        "path": "Tế Thắng, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tế Thắng, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16309",
        "parent_code": "404"
    },
    "16315": {
        "name": "Tế Lợi",
        "type": "xa",
        "slug": "te-loi",
        "name_with_type": "Xã Tế Lợi",
        "path": "Tế Lợi, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tế Lợi, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16315",
        "parent_code": "404"
    },
    "16318": {
        "name": "Tế Nông",
        "type": "xa",
        "slug": "te-nong",
        "name_with_type": "Xã Tế Nông",
        "path": "Tế Nông, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tế Nông, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16318",
        "parent_code": "404"
    },
    "16321": {
        "name": "Minh Nghĩa",
        "type": "xa",
        "slug": "minh-nghia",
        "name_with_type": "Xã Minh Nghĩa",
        "path": "Minh Nghĩa, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Minh Nghĩa, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16321",
        "parent_code": "404"
    },
    "16324": {
        "name": "Minh Khôi",
        "type": "xa",
        "slug": "minh-khoi",
        "name_with_type": "Xã Minh Khôi",
        "path": "Minh Khôi, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Minh Khôi, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16324",
        "parent_code": "404"
    },
    "16327": {
        "name": "Vạn Hòa",
        "type": "xa",
        "slug": "van-hoa",
        "name_with_type": "Xã Vạn Hòa",
        "path": "Vạn Hòa, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Vạn Hòa, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16327",
        "parent_code": "404"
    },
    "16330": {
        "name": "Trường Trung",
        "type": "xa",
        "slug": "truong-trung",
        "name_with_type": "Xã Trường Trung",
        "path": "Trường Trung, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Trường Trung, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16330",
        "parent_code": "404"
    },
    "16333": {
        "name": "Vạn Thắng",
        "type": "xa",
        "slug": "van-thang",
        "name_with_type": "Xã Vạn Thắng",
        "path": "Vạn Thắng, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Vạn Thắng, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16333",
        "parent_code": "404"
    },
    "16336": {
        "name": "Trường Giang",
        "type": "xa",
        "slug": "truong-giang",
        "name_with_type": "Xã Trường Giang",
        "path": "Trường Giang, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Trường Giang, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16336",
        "parent_code": "404"
    },
    "16339": {
        "name": "Vạn Thiện",
        "type": "xa",
        "slug": "van-thien",
        "name_with_type": "Xã Vạn Thiện",
        "path": "Vạn Thiện, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Vạn Thiện, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16339",
        "parent_code": "404"
    },
    "16342": {
        "name": "Thăng Long",
        "type": "xa",
        "slug": "thang-long",
        "name_with_type": "Xã Thăng Long",
        "path": "Thăng Long, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Thăng Long, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16342",
        "parent_code": "404"
    },
    "16345": {
        "name": "Trường Minh",
        "type": "xa",
        "slug": "truong-minh",
        "name_with_type": "Xã Trường Minh",
        "path": "Trường Minh, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Trường Minh, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16345",
        "parent_code": "404"
    },
    "16348": {
        "name": "Trường Sơn",
        "type": "xa",
        "slug": "truong-son",
        "name_with_type": "Xã Trường Sơn",
        "path": "Trường Sơn, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Trường Sơn, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16348",
        "parent_code": "404"
    },
    "16351": {
        "name": "Thăng Bình",
        "type": "xa",
        "slug": "thang-binh",
        "name_with_type": "Xã Thăng Bình",
        "path": "Thăng Bình, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Thăng Bình, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16351",
        "parent_code": "404"
    },
    "16354": {
        "name": "Công Liêm",
        "type": "xa",
        "slug": "cong-liem",
        "name_with_type": "Xã Công Liêm",
        "path": "Công Liêm, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Công Liêm, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16354",
        "parent_code": "404"
    },
    "16357": {
        "name": "Tượng Văn",
        "type": "xa",
        "slug": "tuong-van",
        "name_with_type": "Xã Tượng Văn",
        "path": "Tượng Văn, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tượng Văn, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16357",
        "parent_code": "404"
    },
    "16360": {
        "name": "Thăng Thọ",
        "type": "xa",
        "slug": "thang-tho",
        "name_with_type": "Xã Thăng Thọ",
        "path": "Thăng Thọ, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Thăng Thọ, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16360",
        "parent_code": "404"
    },
    "16363": {
        "name": "Tượng Lĩnh",
        "type": "xa",
        "slug": "tuong-linh",
        "name_with_type": "Xã Tượng Lĩnh",
        "path": "Tượng Lĩnh, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tượng Lĩnh, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16363",
        "parent_code": "404"
    },
    "16366": {
        "name": "Tượng Sơn",
        "type": "xa",
        "slug": "tuong-son",
        "name_with_type": "Xã Tượng Sơn",
        "path": "Tượng Sơn, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Tượng Sơn, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16366",
        "parent_code": "404"
    },
    "16369": {
        "name": "Công Chính",
        "type": "xa",
        "slug": "cong-chinh",
        "name_with_type": "Xã Công Chính",
        "path": "Công Chính, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Công Chính, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16369",
        "parent_code": "404"
    },
    "16372": {
        "name": "Công Bình",
        "type": "xa",
        "slug": "cong-binh",
        "name_with_type": "Xã Công Bình",
        "path": "Công Bình, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Công Bình, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16372",
        "parent_code": "404"
    },
    "16375": {
        "name": "Yên Mỹ",
        "type": "xa",
        "slug": "yen-my",
        "name_with_type": "Xã Yên Mỹ",
        "path": "Yên Mỹ, Nông Cống, Thanh Hóa",
        "path_with_type": "Xã Yên Mỹ, Huyện Nông Cống, Tỉnh Thanh Hóa",
        "code": "16375",
        "parent_code": "404"
    },
    "16378": {
        "name": "Rừng Thông",
        "type": "thi-tran",
        "slug": "rung-thong",
        "name_with_type": "Thị trấn Rừng Thông",
        "path": "Rừng Thông, Đông Sơn, Thanh Hóa",
        "path_with_type": "Thị trấn Rừng Thông, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16378",
        "parent_code": "405"
    },
    "16381": {
        "name": "Đông Hoàng",
        "type": "xa",
        "slug": "dong-hoang",
        "name_with_type": "Xã Đông Hoàng",
        "path": "Đông Hoàng, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Hoàng, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16381",
        "parent_code": "405"
    },
    "16384": {
        "name": "Đông Ninh",
        "type": "xa",
        "slug": "dong-ninh",
        "name_with_type": "Xã Đông Ninh",
        "path": "Đông Ninh, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Ninh, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16384",
        "parent_code": "405"
    },
    "16387": {
        "name": "Đông Khê",
        "type": "xa",
        "slug": "dong-khe",
        "name_with_type": "Xã Đông Khê",
        "path": "Đông Khê, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Khê, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16387",
        "parent_code": "405"
    },
    "16390": {
        "name": "Đông Hòa",
        "type": "xa",
        "slug": "dong-hoa",
        "name_with_type": "Xã Đông Hòa",
        "path": "Đông Hòa, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Hòa, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16390",
        "parent_code": "405"
    },
    "16393": {
        "name": "Đông Yên",
        "type": "xa",
        "slug": "dong-yen",
        "name_with_type": "Xã Đông Yên",
        "path": "Đông Yên, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Yên, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16393",
        "parent_code": "405"
    },
    "16399": {
        "name": "Đông Minh",
        "type": "xa",
        "slug": "dong-minh",
        "name_with_type": "Xã Đông Minh",
        "path": "Đông Minh, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Minh, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16399",
        "parent_code": "405"
    },
    "16402": {
        "name": "Đông Thanh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thanh",
        "path": "Đông Thanh, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Thanh, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16402",
        "parent_code": "405"
    },
    "16405": {
        "name": "Đông Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đông Tiến",
        "path": "Đông Tiến, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Tiến, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16405",
        "parent_code": "405"
    },
    "16408": {
        "name": "Đông Anh",
        "type": "xa",
        "slug": "dong-anh",
        "name_with_type": "Xã Đông Anh",
        "path": "Đông Anh, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Anh, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16408",
        "parent_code": "405"
    },
    "16414": {
        "name": "Đông Thịnh",
        "type": "xa",
        "slug": "dong-thinh",
        "name_with_type": "Xã Đông Thịnh",
        "path": "Đông Thịnh, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Thịnh, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16414",
        "parent_code": "405"
    },
    "16417": {
        "name": "Đông Văn",
        "type": "xa",
        "slug": "dong-van",
        "name_with_type": "Xã Đông Văn",
        "path": "Đông Văn, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Văn, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16417",
        "parent_code": "405"
    },
    "16420": {
        "name": "Đông Phú",
        "type": "xa",
        "slug": "dong-phu",
        "name_with_type": "Xã Đông Phú",
        "path": "Đông Phú, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Phú, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16420",
        "parent_code": "405"
    },
    "16423": {
        "name": "Đông Nam",
        "type": "xa",
        "slug": "dong-nam",
        "name_with_type": "Xã Đông Nam",
        "path": "Đông Nam, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Nam, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16423",
        "parent_code": "405"
    },
    "16426": {
        "name": "Đông Quang",
        "type": "xa",
        "slug": "dong-quang",
        "name_with_type": "Xã Đông Quang",
        "path": "Đông Quang, Đông Sơn, Thanh Hóa",
        "path_with_type": "Xã Đông Quang, Huyện Đông Sơn, Tỉnh Thanh Hóa",
        "code": "16426",
        "parent_code": "405"
    },
    "16438": {
        "name": "Quảng Xương",
        "type": "thi-tran",
        "slug": "quang-xuong",
        "name_with_type": "Thị trấn Quảng Xương",
        "path": "Quảng Xương, Quảng Xương, Thanh Hóa",
        "path_with_type": "Thị trấn Quảng Xương, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16438",
        "parent_code": "406"
    },
    "16444": {
        "name": "Quảng Tân",
        "type": "xa",
        "slug": "quang-tan",
        "name_with_type": "Xã Quảng Tân",
        "path": "Quảng Tân, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Tân, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16444",
        "parent_code": "406"
    },
    "16447": {
        "name": "Quảng Trạch",
        "type": "xa",
        "slug": "quang-trach",
        "name_with_type": "Xã Quảng Trạch",
        "path": "Quảng Trạch, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Trạch, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16447",
        "parent_code": "406"
    },
    "16450": {
        "name": "Quảng Phong",
        "type": "xa",
        "slug": "quang-phong",
        "name_with_type": "Xã Quảng Phong",
        "path": "Quảng Phong, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Phong, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16450",
        "parent_code": "406"
    },
    "16453": {
        "name": "Quảng Đức",
        "type": "xa",
        "slug": "quang-duc",
        "name_with_type": "Xã Quảng Đức",
        "path": "Quảng Đức, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Đức, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16453",
        "parent_code": "406"
    },
    "16456": {
        "name": "Quảng Định",
        "type": "xa",
        "slug": "quang-dinh",
        "name_with_type": "Xã Quảng Định",
        "path": "Quảng Định, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Định, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16456",
        "parent_code": "406"
    },
    "16462": {
        "name": "Quảng Nhân",
        "type": "xa",
        "slug": "quang-nhan",
        "name_with_type": "Xã Quảng Nhân",
        "path": "Quảng Nhân, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Nhân, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16462",
        "parent_code": "406"
    },
    "16465": {
        "name": "Quảng Ninh",
        "type": "xa",
        "slug": "quang-ninh",
        "name_with_type": "Xã Quảng Ninh",
        "path": "Quảng Ninh, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Ninh, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16465",
        "parent_code": "406"
    },
    "16468": {
        "name": "Quảng Bình",
        "type": "xa",
        "slug": "quang-binh",
        "name_with_type": "Xã Quảng Bình",
        "path": "Quảng Bình, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Bình, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16468",
        "parent_code": "406"
    },
    "16471": {
        "name": "Quảng Hợp",
        "type": "xa",
        "slug": "quang-hop",
        "name_with_type": "Xã Quảng Hợp",
        "path": "Quảng Hợp, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Hợp, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16471",
        "parent_code": "406"
    },
    "16474": {
        "name": "Quảng Văn",
        "type": "xa",
        "slug": "quang-van",
        "name_with_type": "Xã Quảng Văn",
        "path": "Quảng Văn, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Văn, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16474",
        "parent_code": "406"
    },
    "16477": {
        "name": "Quảng Long",
        "type": "xa",
        "slug": "quang-long",
        "name_with_type": "Xã Quảng Long",
        "path": "Quảng Long, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Long, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16477",
        "parent_code": "406"
    },
    "16480": {
        "name": "Quảng Yên",
        "type": "xa",
        "slug": "quang-yen",
        "name_with_type": "Xã Quảng Yên",
        "path": "Quảng Yên, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Yên, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16480",
        "parent_code": "406"
    },
    "16483": {
        "name": "Quảng Hòa",
        "type": "xa",
        "slug": "quang-hoa",
        "name_with_type": "Xã Quảng Hòa",
        "path": "Quảng Hòa, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Hòa, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16483",
        "parent_code": "406"
    },
    "16486": {
        "name": "Quảng Lĩnh",
        "type": "xa",
        "slug": "quang-linh",
        "name_with_type": "Xã Quảng Lĩnh",
        "path": "Quảng Lĩnh, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Lĩnh, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16486",
        "parent_code": "406"
    },
    "16489": {
        "name": "Quảng Khê",
        "type": "xa",
        "slug": "quang-khe",
        "name_with_type": "Xã Quảng Khê",
        "path": "Quảng Khê, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Khê, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16489",
        "parent_code": "406"
    },
    "16492": {
        "name": "Quảng Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quảng Trung",
        "path": "Quảng Trung, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Trung, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16492",
        "parent_code": "406"
    },
    "16495": {
        "name": "Quảng Chính",
        "type": "xa",
        "slug": "quang-chinh",
        "name_with_type": "Xã Quảng Chính",
        "path": "Quảng Chính, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Chính, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16495",
        "parent_code": "406"
    },
    "16498": {
        "name": "Quảng Ngọc",
        "type": "xa",
        "slug": "quang-ngoc",
        "name_with_type": "Xã Quảng Ngọc",
        "path": "Quảng Ngọc, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Ngọc, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16498",
        "parent_code": "406"
    },
    "16501": {
        "name": "Quảng Trường",
        "type": "xa",
        "slug": "quang-truong",
        "name_with_type": "Xã Quảng Trường",
        "path": "Quảng Trường, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Trường, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16501",
        "parent_code": "406"
    },
    "16504": {
        "name": "Quảng Phúc",
        "type": "xa",
        "slug": "quang-phuc",
        "name_with_type": "Xã Quảng Phúc",
        "path": "Quảng Phúc, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Phúc, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16504",
        "parent_code": "406"
    },
    "16510": {
        "name": "Quảng Vọng",
        "type": "xa",
        "slug": "quang-vong",
        "name_with_type": "Xã Quảng Vọng",
        "path": "Quảng Vọng, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Vọng, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16510",
        "parent_code": "406"
    },
    "16519": {
        "name": "Quảng Giao",
        "type": "xa",
        "slug": "quang-giao",
        "name_with_type": "Xã Quảng Giao",
        "path": "Quảng Giao, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Giao, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16519",
        "parent_code": "406"
    },
    "16540": {
        "name": "Quảng Hải",
        "type": "xa",
        "slug": "quang-hai",
        "name_with_type": "Xã Quảng Hải",
        "path": "Quảng Hải, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Hải, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16540",
        "parent_code": "406"
    },
    "16543": {
        "name": "Quảng Lưu",
        "type": "xa",
        "slug": "quang-luu",
        "name_with_type": "Xã Quảng Lưu",
        "path": "Quảng Lưu, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Lưu, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16543",
        "parent_code": "406"
    },
    "16546": {
        "name": "Quảng Lộc",
        "type": "xa",
        "slug": "quang-loc",
        "name_with_type": "Xã Quảng Lộc",
        "path": "Quảng Lộc, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Lộc, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16546",
        "parent_code": "406"
    },
    "16549": {
        "name": "Quảng Lợi",
        "type": "xa",
        "slug": "quang-loi",
        "name_with_type": "Xã Quảng Lợi",
        "path": "Quảng Lợi, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Lợi, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16549",
        "parent_code": "406"
    },
    "16552": {
        "name": "Quảng Nham",
        "type": "xa",
        "slug": "quang-nham",
        "name_with_type": "Xã Quảng Nham",
        "path": "Quảng Nham, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Nham, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16552",
        "parent_code": "406"
    },
    "16555": {
        "name": "Quảng Thạch",
        "type": "xa",
        "slug": "quang-thach",
        "name_with_type": "Xã Quảng Thạch",
        "path": "Quảng Thạch, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Thạch, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16555",
        "parent_code": "406"
    },
    "16558": {
        "name": "Quảng Thái",
        "type": "xa",
        "slug": "quang-thai",
        "name_with_type": "Xã Quảng Thái",
        "path": "Quảng Thái, Quảng Xương, Thanh Hóa",
        "path_with_type": "Xã Quảng Thái, Huyện Quảng Xương, Tỉnh Thanh Hóa",
        "code": "16558",
        "parent_code": "406"
    },
    "16561": {
        "name": "Tĩnh Gia",
        "type": "thi-tran",
        "slug": "tinh-gia",
        "name_with_type": "Thị trấn Tĩnh Gia",
        "path": "Tĩnh Gia, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Thị trấn Tĩnh Gia, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16561",
        "parent_code": "407"
    },
    "16564": {
        "name": "Hải Châu",
        "type": "xa",
        "slug": "hai-chau",
        "name_with_type": "Xã Hải Châu",
        "path": "Hải Châu, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Châu, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16564",
        "parent_code": "407"
    },
    "16567": {
        "name": "Thanh Thủy",
        "type": "xa",
        "slug": "thanh-thuy",
        "name_with_type": "Xã Thanh Thủy",
        "path": "Thanh Thủy, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Thanh Thủy, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16567",
        "parent_code": "407"
    },
    "16570": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Thanh Sơn, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16570",
        "parent_code": "407"
    },
    "16573": {
        "name": "Triêu Dương",
        "type": "xa",
        "slug": "trieu-duong",
        "name_with_type": "Xã Triêu Dương",
        "path": "Triêu Dương, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Triêu Dương, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16573",
        "parent_code": "407"
    },
    "16576": {
        "name": "Hải Ninh",
        "type": "xa",
        "slug": "hai-ninh",
        "name_with_type": "Xã Hải Ninh",
        "path": "Hải Ninh, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Ninh, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16576",
        "parent_code": "407"
    },
    "16579": {
        "name": "Anh Sơn",
        "type": "xa",
        "slug": "anh-son",
        "name_with_type": "Xã Anh Sơn",
        "path": "Anh Sơn, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Anh Sơn, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16579",
        "parent_code": "407"
    },
    "16582": {
        "name": "Ngọc Lĩnh",
        "type": "xa",
        "slug": "ngoc-linh",
        "name_with_type": "Xã Ngọc Lĩnh",
        "path": "Ngọc Lĩnh, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Ngọc Lĩnh, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16582",
        "parent_code": "407"
    },
    "16585": {
        "name": "Hải An",
        "type": "xa",
        "slug": "hai-an",
        "name_with_type": "Xã Hải An",
        "path": "Hải An, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải An, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16585",
        "parent_code": "407"
    },
    "16588": {
        "name": "Hùng Sơn",
        "type": "xa",
        "slug": "hung-son",
        "name_with_type": "Xã Hùng Sơn",
        "path": "Hùng Sơn, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hùng Sơn, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16588",
        "parent_code": "407"
    },
    "16591": {
        "name": "Các Sơn",
        "type": "xa",
        "slug": "cac-son",
        "name_with_type": "Xã Các Sơn",
        "path": "Các Sơn, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Các Sơn, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16591",
        "parent_code": "407"
    },
    "16594": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Tân Dân, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16594",
        "parent_code": "407"
    },
    "16597": {
        "name": "Hải Lĩnh",
        "type": "xa",
        "slug": "hai-linh",
        "name_with_type": "Xã Hải Lĩnh",
        "path": "Hải Lĩnh, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Lĩnh, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16597",
        "parent_code": "407"
    },
    "16600": {
        "name": "Định Hải",
        "type": "xa",
        "slug": "dinh-hai",
        "name_with_type": "Xã Định Hải",
        "path": "Định Hải, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Định Hải, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16600",
        "parent_code": "407"
    },
    "16603": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Phú Sơn, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16603",
        "parent_code": "407"
    },
    "16606": {
        "name": "Ninh Hải",
        "type": "xa",
        "slug": "ninh-hai",
        "name_with_type": "Xã Ninh Hải",
        "path": "Ninh Hải, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Ninh Hải, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16606",
        "parent_code": "407"
    },
    "16609": {
        "name": "Nguyên Bình",
        "type": "xa",
        "slug": "nguyen-binh",
        "name_with_type": "Xã Nguyên Bình",
        "path": "Nguyên Bình, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Nguyên Bình, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16609",
        "parent_code": "407"
    },
    "16612": {
        "name": "Hải Nhân",
        "type": "xa",
        "slug": "hai-nhan",
        "name_with_type": "Xã Hải Nhân",
        "path": "Hải Nhân, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Nhân, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16612",
        "parent_code": "407"
    },
    "16615": {
        "name": "Hải Hòa",
        "type": "xa",
        "slug": "hai-hoa",
        "name_with_type": "Xã Hải Hòa",
        "path": "Hải Hòa, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Hòa, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16615",
        "parent_code": "407"
    },
    "16618": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Bình Minh, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16618",
        "parent_code": "407"
    },
    "16621": {
        "name": "Hải Thanh",
        "type": "xa",
        "slug": "hai-thanh",
        "name_with_type": "Xã Hải Thanh",
        "path": "Hải Thanh, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Thanh, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16621",
        "parent_code": "407"
    },
    "16624": {
        "name": "Phú Lâm",
        "type": "xa",
        "slug": "phu-lam",
        "name_with_type": "Xã Phú Lâm",
        "path": "Phú Lâm, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Phú Lâm, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16624",
        "parent_code": "407"
    },
    "16627": {
        "name": "Xuân Lâm",
        "type": "xa",
        "slug": "xuan-lam",
        "name_with_type": "Xã Xuân Lâm",
        "path": "Xuân Lâm, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Xuân Lâm, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16627",
        "parent_code": "407"
    },
    "16630": {
        "name": "Trúc Lâm",
        "type": "xa",
        "slug": "truc-lam",
        "name_with_type": "Xã Trúc Lâm",
        "path": "Trúc Lâm, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Trúc Lâm, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16630",
        "parent_code": "407"
    },
    "16633": {
        "name": "Hải Bình",
        "type": "xa",
        "slug": "hai-binh",
        "name_with_type": "Xã Hải Bình",
        "path": "Hải Bình, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Bình, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16633",
        "parent_code": "407"
    },
    "16636": {
        "name": "Tân Trường",
        "type": "xa",
        "slug": "tan-truong",
        "name_with_type": "Xã Tân Trường",
        "path": "Tân Trường, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Tân Trường, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16636",
        "parent_code": "407"
    },
    "16639": {
        "name": "Tùng Lâm",
        "type": "xa",
        "slug": "tung-lam",
        "name_with_type": "Xã Tùng Lâm",
        "path": "Tùng Lâm, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Tùng Lâm, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16639",
        "parent_code": "407"
    },
    "16642": {
        "name": "Tĩnh Hải",
        "type": "xa",
        "slug": "tinh-hai",
        "name_with_type": "Xã Tĩnh Hải",
        "path": "Tĩnh Hải, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Tĩnh Hải, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16642",
        "parent_code": "407"
    },
    "16645": {
        "name": "Mai Lâm",
        "type": "xa",
        "slug": "mai-lam",
        "name_with_type": "Xã Mai Lâm",
        "path": "Mai Lâm, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Mai Lâm, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16645",
        "parent_code": "407"
    },
    "16648": {
        "name": "Trường Lâm",
        "type": "xa",
        "slug": "truong-lam",
        "name_with_type": "Xã Trường Lâm",
        "path": "Trường Lâm, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Trường Lâm, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16648",
        "parent_code": "407"
    },
    "16651": {
        "name": "Hải Yến",
        "type": "xa",
        "slug": "hai-yen",
        "name_with_type": "Xã Hải Yến",
        "path": "Hải Yến, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Yến, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16651",
        "parent_code": "407"
    },
    "16654": {
        "name": "Hải Thượng",
        "type": "xa",
        "slug": "hai-thuong",
        "name_with_type": "Xã Hải Thượng",
        "path": "Hải Thượng, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Thượng, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16654",
        "parent_code": "407"
    },
    "16657": {
        "name": "Nghi Sơn",
        "type": "xa",
        "slug": "nghi-son",
        "name_with_type": "Xã Nghi Sơn",
        "path": "Nghi Sơn, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Nghi Sơn, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16657",
        "parent_code": "407"
    },
    "16660": {
        "name": "Hải Hà",
        "type": "xa",
        "slug": "hai-ha",
        "name_with_type": "Xã Hải Hà",
        "path": "Hải Hà, Tĩnh Gia, Thanh Hóa",
        "path_with_type": "Xã Hải Hà, Huyện Tĩnh Gia, Tỉnh Thanh Hóa",
        "code": "16660",
        "parent_code": "407"
    },
    "19330": {
        "name": "Đông Giang",
        "type": "phuong",
        "slug": "dong-giang",
        "name_with_type": "Phường Đông Giang",
        "path": "Đông Giang, Đông Hà, Quảng Trị",
        "path_with_type": "Phường Đông Giang, Thành phố Đông Hà, Tỉnh Quảng Trị",
        "code": "19330",
        "parent_code": "461"
    },
    "19333": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Đông Hà, Quảng Trị",
        "path_with_type": "Phường 1, Thành phố Đông Hà, Tỉnh Quảng Trị",
        "code": "19333",
        "parent_code": "461"
    },
    "19336": {
        "name": "Đông Lễ",
        "type": "phuong",
        "slug": "dong-le",
        "name_with_type": "Phường Đông Lễ",
        "path": "Đông Lễ, Đông Hà, Quảng Trị",
        "path_with_type": "Phường Đông Lễ, Thành phố Đông Hà, Tỉnh Quảng Trị",
        "code": "19336",
        "parent_code": "461"
    },
    "19339": {
        "name": "Đông Thanh",
        "type": "phuong",
        "slug": "dong-thanh",
        "name_with_type": "Phường Đông Thanh",
        "path": "Đông Thanh, Đông Hà, Quảng Trị",
        "path_with_type": "Phường Đông Thanh, Thành phố Đông Hà, Tỉnh Quảng Trị",
        "code": "19339",
        "parent_code": "461"
    },
    "19342": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Đông Hà, Quảng Trị",
        "path_with_type": "Phường 2, Thành phố Đông Hà, Tỉnh Quảng Trị",
        "code": "19342",
        "parent_code": "461"
    },
    "19345": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Đông Hà, Quảng Trị",
        "path_with_type": "Phường 4, Thành phố Đông Hà, Tỉnh Quảng Trị",
        "code": "19345",
        "parent_code": "461"
    },
    "19348": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Đông Hà, Quảng Trị",
        "path_with_type": "Phường 5, Thành phố Đông Hà, Tỉnh Quảng Trị",
        "code": "19348",
        "parent_code": "461"
    },
    "19351": {
        "name": "Đông Lương",
        "type": "phuong",
        "slug": "dong-luong",
        "name_with_type": "Phường Đông Lương",
        "path": "Đông Lương, Đông Hà, Quảng Trị",
        "path_with_type": "Phường Đông Lương, Thành phố Đông Hà, Tỉnh Quảng Trị",
        "code": "19351",
        "parent_code": "461"
    },
    "19354": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Đông Hà, Quảng Trị",
        "path_with_type": "Phường 3, Thành phố Đông Hà, Tỉnh Quảng Trị",
        "code": "19354",
        "parent_code": "461"
    },
    "19357": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Quảng Trị, Quảng Trị",
        "path_with_type": "Phường 1, Thị xã Quảng Trị, Tỉnh Quảng Trị",
        "code": "19357",
        "parent_code": "462"
    },
    "19358": {
        "name": "An Đôn",
        "type": "phuong",
        "slug": "an-don",
        "name_with_type": "Phường An Đôn",
        "path": "An Đôn, Quảng Trị, Quảng Trị",
        "path_with_type": "Phường An Đôn, Thị xã Quảng Trị, Tỉnh Quảng Trị",
        "code": "19358",
        "parent_code": "462"
    },
    "19360": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Quảng Trị, Quảng Trị",
        "path_with_type": "Phường 2, Thị xã Quảng Trị, Tỉnh Quảng Trị",
        "code": "19360",
        "parent_code": "462"
    },
    "19361": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Quảng Trị, Quảng Trị",
        "path_with_type": "Phường 3, Thị xã Quảng Trị, Tỉnh Quảng Trị",
        "code": "19361",
        "parent_code": "462"
    },
    "19705": {
        "name": "Hải Lệ",
        "type": "xa",
        "slug": "hai-le",
        "name_with_type": "Xã Hải Lệ",
        "path": "Hải Lệ, Quảng Trị, Quảng Trị",
        "path_with_type": "Xã Hải Lệ, Thị xã Quảng Trị, Tỉnh Quảng Trị",
        "code": "19705",
        "parent_code": "462"
    },
    "19363": {
        "name": "Hồ Xá",
        "type": "thi-tran",
        "slug": "ho-xa",
        "name_with_type": "Thị trấn Hồ Xá",
        "path": "Hồ Xá, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Thị trấn Hồ Xá, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19363",
        "parent_code": "464"
    },
    "19366": {
        "name": "Bến Quan",
        "type": "thi-tran",
        "slug": "ben-quan",
        "name_with_type": "Thị trấn Bến Quan",
        "path": "Bến Quan, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Thị trấn Bến Quan, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19366",
        "parent_code": "464"
    },
    "19369": {
        "name": "Vĩnh Thái",
        "type": "xa",
        "slug": "vinh-thai",
        "name_with_type": "Xã Vĩnh Thái",
        "path": "Vĩnh Thái, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Thái, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19369",
        "parent_code": "464"
    },
    "19372": {
        "name": "Vĩnh Tú",
        "type": "xa",
        "slug": "vinh-tu",
        "name_with_type": "Xã Vĩnh Tú",
        "path": "Vĩnh Tú, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Tú, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19372",
        "parent_code": "464"
    },
    "19375": {
        "name": "Vĩnh Chấp",
        "type": "xa",
        "slug": "vinh-chap",
        "name_with_type": "Xã Vĩnh Chấp",
        "path": "Vĩnh Chấp, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Chấp, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19375",
        "parent_code": "464"
    },
    "19378": {
        "name": "Vĩnh Trung",
        "type": "xa",
        "slug": "vinh-trung",
        "name_with_type": "Xã Vĩnh Trung",
        "path": "Vĩnh Trung, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Trung, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19378",
        "parent_code": "464"
    },
    "19381": {
        "name": "Vĩnh Kim",
        "type": "xa",
        "slug": "vinh-kim",
        "name_with_type": "Xã Vĩnh Kim",
        "path": "Vĩnh Kim, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Kim, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19381",
        "parent_code": "464"
    },
    "19384": {
        "name": "Vĩnh Thạch",
        "type": "xa",
        "slug": "vinh-thach",
        "name_with_type": "Xã Vĩnh Thạch",
        "path": "Vĩnh Thạch, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Thạch, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19384",
        "parent_code": "464"
    },
    "19387": {
        "name": "Vĩnh Long",
        "type": "xa",
        "slug": "vinh-long",
        "name_with_type": "Xã Vĩnh Long",
        "path": "Vĩnh Long, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Long, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19387",
        "parent_code": "464"
    },
    "19390": {
        "name": "Vĩnh Nam",
        "type": "xa",
        "slug": "vinh-nam",
        "name_with_type": "Xã Vĩnh Nam",
        "path": "Vĩnh Nam, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Nam, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19390",
        "parent_code": "464"
    },
    "19393": {
        "name": "Vĩnh Khê",
        "type": "xa",
        "slug": "vinh-khe",
        "name_with_type": "Xã Vĩnh Khê",
        "path": "Vĩnh Khê, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Khê, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19393",
        "parent_code": "464"
    },
    "19396": {
        "name": "Vĩnh Hòa",
        "type": "xa",
        "slug": "vinh-hoa",
        "name_with_type": "Xã Vĩnh Hòa",
        "path": "Vĩnh Hòa, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Hòa, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19396",
        "parent_code": "464"
    },
    "19399": {
        "name": "Vĩnh Hiền",
        "type": "xa",
        "slug": "vinh-hien",
        "name_with_type": "Xã Vĩnh Hiền",
        "path": "Vĩnh Hiền, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Hiền, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19399",
        "parent_code": "464"
    },
    "19402": {
        "name": "Vĩnh Thủy",
        "type": "xa",
        "slug": "vinh-thuy",
        "name_with_type": "Xã Vĩnh Thủy",
        "path": "Vĩnh Thủy, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Thủy, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19402",
        "parent_code": "464"
    },
    "19405": {
        "name": "Vĩnh Lâm",
        "type": "xa",
        "slug": "vinh-lam",
        "name_with_type": "Xã Vĩnh Lâm",
        "path": "Vĩnh Lâm, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Lâm, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19405",
        "parent_code": "464"
    },
    "19408": {
        "name": "Vĩnh Thành",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thành",
        "path": "Vĩnh Thành, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Thành, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19408",
        "parent_code": "464"
    },
    "19411": {
        "name": "Vĩnh Tân",
        "type": "xa",
        "slug": "vinh-tan",
        "name_with_type": "Xã Vĩnh Tân",
        "path": "Vĩnh Tân, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Tân, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19411",
        "parent_code": "464"
    },
    "19414": {
        "name": "Cửa Tùng",
        "type": "thi-tran",
        "slug": "cua-tung",
        "name_with_type": "Thị trấn Cửa Tùng",
        "path": "Cửa Tùng, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Thị trấn Cửa Tùng, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19414",
        "parent_code": "464"
    },
    "19417": {
        "name": "Vĩnh Hà",
        "type": "xa",
        "slug": "vinh-ha",
        "name_with_type": "Xã Vĩnh Hà",
        "path": "Vĩnh Hà, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Hà, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19417",
        "parent_code": "464"
    },
    "19420": {
        "name": "Vĩnh Sơn",
        "type": "xa",
        "slug": "vinh-son",
        "name_with_type": "Xã Vĩnh Sơn",
        "path": "Vĩnh Sơn, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Sơn, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19420",
        "parent_code": "464"
    },
    "19423": {
        "name": "Vĩnh Giang",
        "type": "xa",
        "slug": "vinh-giang",
        "name_with_type": "Xã Vĩnh Giang",
        "path": "Vĩnh Giang, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Giang, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19423",
        "parent_code": "464"
    },
    "19426": {
        "name": "Vĩnh Ô",
        "type": "xa",
        "slug": "vinh-o",
        "name_with_type": "Xã Vĩnh Ô",
        "path": "Vĩnh Ô, Vĩnh Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Ô, Huyện Vĩnh Linh, Tỉnh Quảng Trị",
        "code": "19426",
        "parent_code": "464"
    },
    "19429": {
        "name": "Khe Sanh",
        "type": "thi-tran",
        "slug": "khe-sanh",
        "name_with_type": "Thị trấn Khe Sanh",
        "path": "Khe Sanh, Hướng Hóa, Quảng Trị",
        "path_with_type": "Thị trấn Khe Sanh, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19429",
        "parent_code": "465"
    },
    "19432": {
        "name": "Lao Bảo",
        "type": "thi-tran",
        "slug": "lao-bao",
        "name_with_type": "Thị trấn Lao Bảo",
        "path": "Lao Bảo, Hướng Hóa, Quảng Trị",
        "path_with_type": "Thị trấn Lao Bảo, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19432",
        "parent_code": "465"
    },
    "19435": {
        "name": "Hướng Lập",
        "type": "xa",
        "slug": "huong-lap",
        "name_with_type": "Xã Hướng Lập",
        "path": "Hướng Lập, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Hướng Lập, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19435",
        "parent_code": "465"
    },
    "19438": {
        "name": "Hướng Việt",
        "type": "xa",
        "slug": "huong-viet",
        "name_with_type": "Xã Hướng Việt",
        "path": "Hướng Việt, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Hướng Việt, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19438",
        "parent_code": "465"
    },
    "19441": {
        "name": "Hướng Phùng",
        "type": "xa",
        "slug": "huong-phung",
        "name_with_type": "Xã Hướng Phùng",
        "path": "Hướng Phùng, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Hướng Phùng, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19441",
        "parent_code": "465"
    },
    "19444": {
        "name": "Hướng Sơn",
        "type": "xa",
        "slug": "huong-son",
        "name_with_type": "Xã Hướng Sơn",
        "path": "Hướng Sơn, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Hướng Sơn, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19444",
        "parent_code": "465"
    },
    "19447": {
        "name": "Hướng Linh",
        "type": "xa",
        "slug": "huong-linh",
        "name_with_type": "Xã Hướng Linh",
        "path": "Hướng Linh, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Hướng Linh, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19447",
        "parent_code": "465"
    },
    "19450": {
        "name": "Tân Hợp",
        "type": "xa",
        "slug": "tan-hop",
        "name_with_type": "Xã Tân Hợp",
        "path": "Tân Hợp, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Tân Hợp, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19450",
        "parent_code": "465"
    },
    "19453": {
        "name": "Hướng Tân",
        "type": "xa",
        "slug": "huong-tan",
        "name_with_type": "Xã Hướng Tân",
        "path": "Hướng Tân, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Hướng Tân, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19453",
        "parent_code": "465"
    },
    "19456": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Tân Thành, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19456",
        "parent_code": "465"
    },
    "19459": {
        "name": "Tân Long",
        "type": "xa",
        "slug": "tan-long",
        "name_with_type": "Xã Tân Long",
        "path": "Tân Long, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Tân Long, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19459",
        "parent_code": "465"
    },
    "19462": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Tân Lập, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19462",
        "parent_code": "465"
    },
    "19465": {
        "name": "Tân Liên",
        "type": "xa",
        "slug": "tan-lien",
        "name_with_type": "Xã Tân Liên",
        "path": "Tân Liên, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Tân Liên, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19465",
        "parent_code": "465"
    },
    "19468": {
        "name": "Húc",
        "type": "xa",
        "slug": "huc",
        "name_with_type": "Xã Húc",
        "path": "Húc, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Húc, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19468",
        "parent_code": "465"
    },
    "19471": {
        "name": "Thuận",
        "type": "xa",
        "slug": "thuan",
        "name_with_type": "Xã Thuận",
        "path": "Thuận, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Thuận, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19471",
        "parent_code": "465"
    },
    "19474": {
        "name": "Hướng Lộc",
        "type": "xa",
        "slug": "huong-loc",
        "name_with_type": "Xã Hướng Lộc",
        "path": "Hướng Lộc, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Hướng Lộc, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19474",
        "parent_code": "465"
    },
    "19477": {
        "name": "Ba Tầng",
        "type": "xa",
        "slug": "ba-tang",
        "name_with_type": "Xã Ba Tầng",
        "path": "Ba Tầng, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Ba Tầng, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19477",
        "parent_code": "465"
    },
    "19480": {
        "name": "Thanh",
        "type": "xa",
        "slug": "thanh",
        "name_with_type": "Xã Thanh",
        "path": "Thanh, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Thanh, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19480",
        "parent_code": "465"
    },
    "19483": {
        "name": "A Dơi",
        "type": "xa",
        "slug": "a-doi",
        "name_with_type": "Xã  A Dơi",
        "path": "A Dơi, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã  A Dơi, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19483",
        "parent_code": "465"
    },
    "19486": {
        "name": "A Xing",
        "type": "xa",
        "slug": "a-xing",
        "name_with_type": "Xã A Xing",
        "path": "A Xing, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã A Xing, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19486",
        "parent_code": "465"
    },
    "19489": {
        "name": "A Túc",
        "type": "xa",
        "slug": "a-tuc",
        "name_with_type": "Xã A Túc",
        "path": "A Túc, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã A Túc, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19489",
        "parent_code": "465"
    },
    "19492": {
        "name": "Xy",
        "type": "xa",
        "slug": "xy",
        "name_with_type": "Xã Xy",
        "path": "Xy, Hướng Hóa, Quảng Trị",
        "path_with_type": "Xã Xy, Huyện Hướng Hóa, Tỉnh Quảng Trị",
        "code": "19492",
        "parent_code": "465"
    },
    "19495": {
        "name": "Gio Linh",
        "type": "thi-tran",
        "slug": "gio-linh",
        "name_with_type": "Thị trấn Gio Linh",
        "path": "Gio Linh, Gio Linh, Quảng Trị",
        "path_with_type": "Thị trấn Gio Linh, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19495",
        "parent_code": "466"
    },
    "19496": {
        "name": "Cửa Việt",
        "type": "thi-tran",
        "slug": "cua-viet",
        "name_with_type": "Thị trấn Cửa Việt",
        "path": "Cửa Việt, Gio Linh, Quảng Trị",
        "path_with_type": "Thị trấn Cửa Việt, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19496",
        "parent_code": "466"
    },
    "19498": {
        "name": "Trung Giang",
        "type": "xa",
        "slug": "trung-giang",
        "name_with_type": "Xã Trung Giang",
        "path": "Trung Giang, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Trung Giang, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19498",
        "parent_code": "466"
    },
    "19501": {
        "name": "Trung Hải",
        "type": "xa",
        "slug": "trung-hai",
        "name_with_type": "Xã Trung Hải",
        "path": "Trung Hải, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Trung Hải, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19501",
        "parent_code": "466"
    },
    "19504": {
        "name": "Trung Sơn",
        "type": "xa",
        "slug": "trung-son",
        "name_with_type": "Xã Trung Sơn",
        "path": "Trung Sơn, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Trung Sơn, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19504",
        "parent_code": "466"
    },
    "19507": {
        "name": "Gio Phong",
        "type": "xa",
        "slug": "gio-phong",
        "name_with_type": "Xã Gio Phong",
        "path": "Gio Phong, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Phong, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19507",
        "parent_code": "466"
    },
    "19510": {
        "name": "Gio Mỹ",
        "type": "xa",
        "slug": "gio-my",
        "name_with_type": "Xã Gio Mỹ",
        "path": "Gio Mỹ, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Mỹ, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19510",
        "parent_code": "466"
    },
    "19513": {
        "name": "Vĩnh Trường",
        "type": "xa",
        "slug": "vinh-truong",
        "name_with_type": "Xã Vĩnh Trường",
        "path": "Vĩnh Trường, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Vĩnh Trường, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19513",
        "parent_code": "466"
    },
    "19516": {
        "name": "Gio Bình",
        "type": "xa",
        "slug": "gio-binh",
        "name_with_type": "Xã Gio Bình",
        "path": "Gio Bình, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Bình, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19516",
        "parent_code": "466"
    },
    "19519": {
        "name": "Gio Hải",
        "type": "xa",
        "slug": "gio-hai",
        "name_with_type": "Xã Gio Hải",
        "path": "Gio Hải, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Hải, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19519",
        "parent_code": "466"
    },
    "19522": {
        "name": "Gio An",
        "type": "xa",
        "slug": "gio-an",
        "name_with_type": "Xã Gio An",
        "path": "Gio An, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio An, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19522",
        "parent_code": "466"
    },
    "19525": {
        "name": "Gio Châu",
        "type": "xa",
        "slug": "gio-chau",
        "name_with_type": "Xã Gio Châu",
        "path": "Gio Châu, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Châu, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19525",
        "parent_code": "466"
    },
    "19528": {
        "name": "Gio Thành",
        "type": "xa",
        "slug": "gio-thanh",
        "name_with_type": "Xã Gio Thành",
        "path": "Gio Thành, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Thành, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19528",
        "parent_code": "466"
    },
    "19531": {
        "name": "Gio Việt",
        "type": "xa",
        "slug": "gio-viet",
        "name_with_type": "Xã Gio Việt",
        "path": "Gio Việt, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Việt, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19531",
        "parent_code": "466"
    },
    "19534": {
        "name": "Linh Thượng",
        "type": "xa",
        "slug": "linh-thuong",
        "name_with_type": "Xã Linh Thượng",
        "path": "Linh Thượng, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Linh Thượng, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19534",
        "parent_code": "466"
    },
    "19537": {
        "name": "Gio Sơn",
        "type": "xa",
        "slug": "gio-son",
        "name_with_type": "Xã Gio Sơn",
        "path": "Gio Sơn, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Sơn, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19537",
        "parent_code": "466"
    },
    "19540": {
        "name": "Gio Hòa",
        "type": "xa",
        "slug": "gio-hoa",
        "name_with_type": "Xã Gio Hòa",
        "path": "Gio Hòa, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Hòa, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19540",
        "parent_code": "466"
    },
    "19543": {
        "name": "Gio Mai",
        "type": "xa",
        "slug": "gio-mai",
        "name_with_type": "Xã Gio Mai",
        "path": "Gio Mai, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Mai, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19543",
        "parent_code": "466"
    },
    "19546": {
        "name": "Hải Thái",
        "type": "xa",
        "slug": "hai-thai",
        "name_with_type": "Xã Hải Thái",
        "path": "Hải Thái, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Hải Thái, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19546",
        "parent_code": "466"
    },
    "19549": {
        "name": "Linh Hải",
        "type": "xa",
        "slug": "linh-hai",
        "name_with_type": "Xã Linh Hải",
        "path": "Linh Hải, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Linh Hải, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19549",
        "parent_code": "466"
    },
    "19552": {
        "name": "Gio Quang",
        "type": "xa",
        "slug": "gio-quang",
        "name_with_type": "Xã Gio Quang",
        "path": "Gio Quang, Gio Linh, Quảng Trị",
        "path_with_type": "Xã Gio Quang, Huyện Gio Linh, Tỉnh Quảng Trị",
        "code": "19552",
        "parent_code": "466"
    },
    "19555": {
        "name": "Krông Klang",
        "type": "thi-tran",
        "slug": "krong-klang",
        "name_with_type": "Thị trấn Krông Klang",
        "path": "Krông Klang, Đa Krông, Quảng Trị",
        "path_with_type": "Thị trấn Krông Klang, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19555",
        "parent_code": "467"
    },
    "19558": {
        "name": "Mò Ó",
        "type": "xa",
        "slug": "mo-o",
        "name_with_type": "Xã Mò Ó",
        "path": "Mò Ó, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Mò Ó, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19558",
        "parent_code": "467"
    },
    "19561": {
        "name": "Hướng Hiệp",
        "type": "xa",
        "slug": "huong-hiep",
        "name_with_type": "Xã Hướng Hiệp",
        "path": "Hướng Hiệp, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Hướng Hiệp, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19561",
        "parent_code": "467"
    },
    "19564": {
        "name": "Đa Krông",
        "type": "xa",
        "slug": "da-krong",
        "name_with_type": "Xã Đa Krông",
        "path": "Đa Krông, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Đa Krông, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19564",
        "parent_code": "467"
    },
    "19567": {
        "name": "Triệu Nguyên",
        "type": "xa",
        "slug": "trieu-nguyen",
        "name_with_type": "Xã Triệu Nguyên",
        "path": "Triệu Nguyên, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Triệu Nguyên, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19567",
        "parent_code": "467"
    },
    "19570": {
        "name": "Ba Lòng",
        "type": "xa",
        "slug": "ba-long",
        "name_with_type": "Xã Ba Lòng",
        "path": "Ba Lòng, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Ba Lòng, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19570",
        "parent_code": "467"
    },
    "19573": {
        "name": "Hải Phúc",
        "type": "xa",
        "slug": "hai-phuc",
        "name_with_type": "Xã Hải Phúc",
        "path": "Hải Phúc, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Hải Phúc, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19573",
        "parent_code": "467"
    },
    "19576": {
        "name": "Ba Nang",
        "type": "xa",
        "slug": "ba-nang",
        "name_with_type": "Xã Ba Nang",
        "path": "Ba Nang, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Ba Nang, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19576",
        "parent_code": "467"
    },
    "19579": {
        "name": "Tà Long",
        "type": "xa",
        "slug": "ta-long",
        "name_with_type": "Xã Tà Long",
        "path": "Tà Long, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Tà Long, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19579",
        "parent_code": "467"
    },
    "19582": {
        "name": "Húc Nghì",
        "type": "xa",
        "slug": "huc-nghi",
        "name_with_type": "Xã Húc Nghì",
        "path": "Húc Nghì, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Húc Nghì, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19582",
        "parent_code": "467"
    },
    "19585": {
        "name": "A Vao",
        "type": "xa",
        "slug": "a-vao",
        "name_with_type": "Xã A Vao",
        "path": "A Vao, Đa Krông, Quảng Trị",
        "path_with_type": "Xã A Vao, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19585",
        "parent_code": "467"
    },
    "19588": {
        "name": "Tà Rụt",
        "type": "xa",
        "slug": "ta-rut",
        "name_with_type": "Xã Tà Rụt",
        "path": "Tà Rụt, Đa Krông, Quảng Trị",
        "path_with_type": "Xã Tà Rụt, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19588",
        "parent_code": "467"
    },
    "19591": {
        "name": "A Bung",
        "type": "xa",
        "slug": "a-bung",
        "name_with_type": "Xã A Bung",
        "path": "A Bung, Đa Krông, Quảng Trị",
        "path_with_type": "Xã A Bung, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19591",
        "parent_code": "467"
    },
    "19594": {
        "name": "A Ngo",
        "type": "xa",
        "slug": "a-ngo",
        "name_with_type": "Xã A Ngo",
        "path": "A Ngo, Đa Krông, Quảng Trị",
        "path_with_type": "Xã A Ngo, Huyện Đa Krông, Tỉnh Quảng Trị",
        "code": "19594",
        "parent_code": "467"
    },
    "19597": {
        "name": "Cam Lộ",
        "type": "thi-tran",
        "slug": "cam-lo",
        "name_with_type": "Thị trấn Cam Lộ",
        "path": "Cam Lộ, Cam Lộ, Quảng Trị",
        "path_with_type": "Thị trấn Cam Lộ, Huyện Cam Lộ, Tỉnh Quảng Trị",
        "code": "19597",
        "parent_code": "468"
    },
    "19600": {
        "name": "Cam Tuyền",
        "type": "xa",
        "slug": "cam-tuyen",
        "name_with_type": "Xã Cam Tuyền",
        "path": "Cam Tuyền, Cam Lộ, Quảng Trị",
        "path_with_type": "Xã Cam Tuyền, Huyện Cam Lộ, Tỉnh Quảng Trị",
        "code": "19600",
        "parent_code": "468"
    },
    "19603": {
        "name": "Cam An",
        "type": "xa",
        "slug": "cam-an",
        "name_with_type": "Xã Cam An",
        "path": "Cam An, Cam Lộ, Quảng Trị",
        "path_with_type": "Xã Cam An, Huyện Cam Lộ, Tỉnh Quảng Trị",
        "code": "19603",
        "parent_code": "468"
    },
    "19606": {
        "name": "Cam Thủy",
        "type": "xa",
        "slug": "cam-thuy",
        "name_with_type": "Xã Cam Thủy",
        "path": "Cam Thủy, Cam Lộ, Quảng Trị",
        "path_with_type": "Xã Cam Thủy, Huyện Cam Lộ, Tỉnh Quảng Trị",
        "code": "19606",
        "parent_code": "468"
    },
    "19609": {
        "name": "Cam Thanh",
        "type": "xa",
        "slug": "cam-thanh",
        "name_with_type": "Xã Cam Thanh",
        "path": "Cam Thanh, Cam Lộ, Quảng Trị",
        "path_with_type": "Xã Cam Thanh, Huyện Cam Lộ, Tỉnh Quảng Trị",
        "code": "19609",
        "parent_code": "468"
    },
    "19612": {
        "name": "Cam Thành",
        "type": "xa",
        "slug": "cam-thanh",
        "name_with_type": "Xã Cam Thành",
        "path": "Cam Thành, Cam Lộ, Quảng Trị",
        "path_with_type": "Xã Cam Thành, Huyện Cam Lộ, Tỉnh Quảng Trị",
        "code": "19612",
        "parent_code": "468"
    },
    "19615": {
        "name": "Cam Hiếu",
        "type": "xa",
        "slug": "cam-hieu",
        "name_with_type": "Xã Cam Hiếu",
        "path": "Cam Hiếu, Cam Lộ, Quảng Trị",
        "path_with_type": "Xã Cam Hiếu, Huyện Cam Lộ, Tỉnh Quảng Trị",
        "code": "19615",
        "parent_code": "468"
    },
    "19618": {
        "name": "Cam Chính",
        "type": "xa",
        "slug": "cam-chinh",
        "name_with_type": "Xã Cam Chính",
        "path": "Cam Chính, Cam Lộ, Quảng Trị",
        "path_with_type": "Xã Cam Chính, Huyện Cam Lộ, Tỉnh Quảng Trị",
        "code": "19618",
        "parent_code": "468"
    },
    "19621": {
        "name": "Cam Nghĩa",
        "type": "xa",
        "slug": "cam-nghia",
        "name_with_type": "Xã Cam Nghĩa",
        "path": "Cam Nghĩa, Cam Lộ, Quảng Trị",
        "path_with_type": "Xã Cam Nghĩa, Huyện Cam Lộ, Tỉnh Quảng Trị",
        "code": "19621",
        "parent_code": "468"
    },
    "19624": {
        "name": "Ái Tử",
        "type": "thi-tran",
        "slug": "ai-tu",
        "name_with_type": "Thị Trấn Ái Tử",
        "path": "Ái Tử, Triệu Phong, Quảng Trị",
        "path_with_type": "Thị Trấn Ái Tử, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19624",
        "parent_code": "469"
    },
    "19627": {
        "name": "Triệu An",
        "type": "xa",
        "slug": "trieu-an",
        "name_with_type": "Xã Triệu An",
        "path": "Triệu An, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu An, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19627",
        "parent_code": "469"
    },
    "19630": {
        "name": "Triệu Vân",
        "type": "xa",
        "slug": "trieu-van",
        "name_with_type": "Xã Triệu Vân",
        "path": "Triệu Vân, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Vân, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19630",
        "parent_code": "469"
    },
    "19633": {
        "name": "Triệu Phước",
        "type": "xa",
        "slug": "trieu-phuoc",
        "name_with_type": "Xã Triệu Phước",
        "path": "Triệu Phước, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Phước, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19633",
        "parent_code": "469"
    },
    "19636": {
        "name": "Triệu Độ",
        "type": "xa",
        "slug": "trieu-do",
        "name_with_type": "Xã Triệu Độ",
        "path": "Triệu Độ, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Độ, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19636",
        "parent_code": "469"
    },
    "19639": {
        "name": "Triệu Trạch",
        "type": "xa",
        "slug": "trieu-trach",
        "name_with_type": "Xã Triệu Trạch",
        "path": "Triệu Trạch, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Trạch, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19639",
        "parent_code": "469"
    },
    "19642": {
        "name": "Triệu Thuận",
        "type": "xa",
        "slug": "trieu-thuan",
        "name_with_type": "Xã Triệu Thuận",
        "path": "Triệu Thuận, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Thuận, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19642",
        "parent_code": "469"
    },
    "19645": {
        "name": "Triệu Đại",
        "type": "xa",
        "slug": "trieu-dai",
        "name_with_type": "Xã Triệu Đại",
        "path": "Triệu Đại, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Đại, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19645",
        "parent_code": "469"
    },
    "19648": {
        "name": "Triệu Hòa",
        "type": "xa",
        "slug": "trieu-hoa",
        "name_with_type": "Xã Triệu Hòa",
        "path": "Triệu Hòa, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Hòa, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19648",
        "parent_code": "469"
    },
    "19651": {
        "name": "Triệu Lăng",
        "type": "xa",
        "slug": "trieu-lang",
        "name_with_type": "Xã Triệu Lăng",
        "path": "Triệu Lăng, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Lăng, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19651",
        "parent_code": "469"
    },
    "19654": {
        "name": "Triệu Sơn",
        "type": "xa",
        "slug": "trieu-son",
        "name_with_type": "Xã Triệu Sơn",
        "path": "Triệu Sơn, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Sơn, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19654",
        "parent_code": "469"
    },
    "19657": {
        "name": "Triệu Long",
        "type": "xa",
        "slug": "trieu-long",
        "name_with_type": "Xã Triệu Long",
        "path": "Triệu Long, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Long, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19657",
        "parent_code": "469"
    },
    "19660": {
        "name": "Triệu Tài",
        "type": "xa",
        "slug": "trieu-tai",
        "name_with_type": "Xã Triệu Tài",
        "path": "Triệu Tài, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Tài, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19660",
        "parent_code": "469"
    },
    "19663": {
        "name": "Triệu Đông",
        "type": "xa",
        "slug": "trieu-dong",
        "name_with_type": "Xã Triệu Đông",
        "path": "Triệu Đông, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Đông, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19663",
        "parent_code": "469"
    },
    "19666": {
        "name": "Triệu Trung",
        "type": "xa",
        "slug": "trieu-trung",
        "name_with_type": "Xã Triệu Trung",
        "path": "Triệu Trung, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Trung, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19666",
        "parent_code": "469"
    },
    "19669": {
        "name": "Triệu Ái",
        "type": "xa",
        "slug": "trieu-ai",
        "name_with_type": "Xã Triệu Ái",
        "path": "Triệu Ái, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Ái, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19669",
        "parent_code": "469"
    },
    "19672": {
        "name": "Triệu Thượng",
        "type": "xa",
        "slug": "trieu-thuong",
        "name_with_type": "Xã Triệu Thượng",
        "path": "Triệu Thượng, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Thượng, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19672",
        "parent_code": "469"
    },
    "19675": {
        "name": "Triệu Giang",
        "type": "xa",
        "slug": "trieu-giang",
        "name_with_type": "Xã Triệu Giang",
        "path": "Triệu Giang, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Giang, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19675",
        "parent_code": "469"
    },
    "19678": {
        "name": "Triệu Thành",
        "type": "xa",
        "slug": "trieu-thanh",
        "name_with_type": "Xã Triệu Thành",
        "path": "Triệu Thành, Triệu Phong, Quảng Trị",
        "path_with_type": "Xã Triệu Thành, Huyện Triệu Phong, Tỉnh Quảng Trị",
        "code": "19678",
        "parent_code": "469"
    },
    "19681": {
        "name": "Hải Lăng",
        "type": "thi-tran",
        "slug": "hai-lang",
        "name_with_type": "Thị trấn Hải Lăng",
        "path": "Hải Lăng, Hải Lăng, Quảng Trị",
        "path_with_type": "Thị trấn Hải Lăng, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19681",
        "parent_code": "470"
    },
    "19684": {
        "name": "Hải An",
        "type": "xa",
        "slug": "hai-an",
        "name_with_type": "Xã Hải An",
        "path": "Hải An, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải An, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19684",
        "parent_code": "470"
    },
    "19687": {
        "name": "Hải Ba",
        "type": "xa",
        "slug": "hai-ba",
        "name_with_type": "Xã Hải Ba",
        "path": "Hải Ba, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Ba, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19687",
        "parent_code": "470"
    },
    "19690": {
        "name": "Hải Xuân",
        "type": "xa",
        "slug": "hai-xuan",
        "name_with_type": "Xã Hải Xuân",
        "path": "Hải Xuân, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Xuân, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19690",
        "parent_code": "470"
    },
    "19693": {
        "name": "Hải Quy",
        "type": "xa",
        "slug": "hai-quy",
        "name_with_type": "Xã Hải Quy",
        "path": "Hải Quy, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Quy, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19693",
        "parent_code": "470"
    },
    "19696": {
        "name": "Hải Quế",
        "type": "xa",
        "slug": "hai-que",
        "name_with_type": "Xã Hải Quế",
        "path": "Hải Quế, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Quế, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19696",
        "parent_code": "470"
    },
    "19699": {
        "name": "Hải Vĩnh",
        "type": "xa",
        "slug": "hai-vinh",
        "name_with_type": "Xã Hải Vĩnh",
        "path": "Hải Vĩnh, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Vĩnh, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19699",
        "parent_code": "470"
    },
    "19702": {
        "name": "Hải Phú",
        "type": "xa",
        "slug": "hai-phu",
        "name_with_type": "Xã Hải Phú",
        "path": "Hải Phú, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Phú, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19702",
        "parent_code": "470"
    },
    "19708": {
        "name": "Hải Thượng",
        "type": "xa",
        "slug": "hai-thuong",
        "name_with_type": "Xã Hải Thượng",
        "path": "Hải Thượng, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Thượng, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19708",
        "parent_code": "470"
    },
    "19711": {
        "name": "Hải Dương",
        "type": "xa",
        "slug": "hai-duong",
        "name_with_type": "Xã Hải Dương",
        "path": "Hải Dương, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Dương, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19711",
        "parent_code": "470"
    },
    "19714": {
        "name": "Hải Thiện",
        "type": "xa",
        "slug": "hai-thien",
        "name_with_type": "Xã Hải Thiện",
        "path": "Hải Thiện, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Thiện, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19714",
        "parent_code": "470"
    },
    "19717": {
        "name": "Hải Lâm",
        "type": "xa",
        "slug": "hai-lam",
        "name_with_type": "Xã Hải Lâm",
        "path": "Hải Lâm, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Lâm, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19717",
        "parent_code": "470"
    },
    "19720": {
        "name": "Hải Thành",
        "type": "xa",
        "slug": "hai-thanh",
        "name_with_type": "Xã Hải Thành",
        "path": "Hải Thành, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Thành, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19720",
        "parent_code": "470"
    },
    "19723": {
        "name": "Hải Hòa",
        "type": "xa",
        "slug": "hai-hoa",
        "name_with_type": "Xã Hải Hòa",
        "path": "Hải Hòa, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Hòa, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19723",
        "parent_code": "470"
    },
    "19726": {
        "name": "Hải Tân",
        "type": "xa",
        "slug": "hai-tan",
        "name_with_type": "Xã Hải Tân",
        "path": "Hải Tân, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Tân, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19726",
        "parent_code": "470"
    },
    "19729": {
        "name": "Hải Trường",
        "type": "xa",
        "slug": "hai-truong",
        "name_with_type": "Xã Hải Trường",
        "path": "Hải Trường, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Trường, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19729",
        "parent_code": "470"
    },
    "19732": {
        "name": "Hải Thọ",
        "type": "xa",
        "slug": "hai-tho",
        "name_with_type": "Xã Hải Thọ",
        "path": "Hải Thọ, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Thọ, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19732",
        "parent_code": "470"
    },
    "19735": {
        "name": "Hải Sơn",
        "type": "xa",
        "slug": "hai-son",
        "name_with_type": "Xã Hải Sơn",
        "path": "Hải Sơn, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Sơn, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19735",
        "parent_code": "470"
    },
    "19738": {
        "name": "Hải Chánh",
        "type": "xa",
        "slug": "hai-chanh",
        "name_with_type": "Xã Hải Chánh",
        "path": "Hải Chánh, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Chánh, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19738",
        "parent_code": "470"
    },
    "19741": {
        "name": "Hải Khê",
        "type": "xa",
        "slug": "hai-khe",
        "name_with_type": "Xã Hải Khê",
        "path": "Hải Khê, Hải Lăng, Quảng Trị",
        "path_with_type": "Xã Hải Khê, Huyện Hải Lăng, Tỉnh Quảng Trị",
        "code": "19741",
        "parent_code": "470"
    },
    "02200": {
        "name": "Phan Thiết",
        "type": "phuong",
        "slug": "phan-thiet",
        "name_with_type": "Phường Phan Thiết",
        "path": "Phan Thiết, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Phường Phan Thiết, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02200",
        "parent_code": "070"
    },
    "02203": {
        "name": "Minh Xuân",
        "type": "phuong",
        "slug": "minh-xuan",
        "name_with_type": "Phường Minh Xuân",
        "path": "Minh Xuân, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Phường Minh Xuân, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02203",
        "parent_code": "070"
    },
    "02206": {
        "name": "Tân Quang",
        "type": "phuong",
        "slug": "tan-quang",
        "name_with_type": "Phường Tân Quang",
        "path": "Tân Quang, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Phường Tân Quang, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02206",
        "parent_code": "070"
    },
    "02209": {
        "name": "Tràng Đà",
        "type": "xa",
        "slug": "trang-da",
        "name_with_type": "Xã Tràng Đà",
        "path": "Tràng Đà, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Xã Tràng Đà, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02209",
        "parent_code": "070"
    },
    "02212": {
        "name": "Nông Tiến",
        "type": "phuong",
        "slug": "nong-tien",
        "name_with_type": "Phường Nông Tiến",
        "path": "Nông Tiến, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Phường Nông Tiến, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02212",
        "parent_code": "070"
    },
    "02215": {
        "name": "Ỷ La",
        "type": "phuong",
        "slug": "y-la",
        "name_with_type": "Phường Ỷ La",
        "path": "Ỷ La, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Phường Ỷ La, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02215",
        "parent_code": "070"
    },
    "02216": {
        "name": "Tân Hà",
        "type": "phuong",
        "slug": "tan-ha",
        "name_with_type": "Phường Tân Hà",
        "path": "Tân Hà, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Phường Tân Hà, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02216",
        "parent_code": "070"
    },
    "02218": {
        "name": "Hưng Thành",
        "type": "phuong",
        "slug": "hung-thanh",
        "name_with_type": "Phường Hưng Thành",
        "path": "Hưng Thành, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Phường Hưng Thành, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02218",
        "parent_code": "070"
    },
    "02503": {
        "name": "An Khang",
        "type": "xa",
        "slug": "an-khang",
        "name_with_type": "Xã An Khang",
        "path": "An Khang, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Xã An Khang, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02503",
        "parent_code": "070"
    },
    "02512": {
        "name": "An Tường",
        "type": "xa",
        "slug": "an-tuong",
        "name_with_type": "Xã An Tường",
        "path": "An Tường, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Xã An Tường, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02512",
        "parent_code": "070"
    },
    "02515": {
        "name": "Lưỡng Vượng",
        "type": "xa",
        "slug": "luong-vuong",
        "name_with_type": "Xã Lưỡng Vượng",
        "path": "Lưỡng Vượng, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Xã Lưỡng Vượng, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02515",
        "parent_code": "070"
    },
    "02521": {
        "name": "Thái Long",
        "type": "xa",
        "slug": "thai-long",
        "name_with_type": "Xã Thái Long",
        "path": "Thái Long, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Xã Thái Long, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02521",
        "parent_code": "070"
    },
    "02524": {
        "name": "Đội Cấn",
        "type": "xa",
        "slug": "doi-can",
        "name_with_type": "Xã Đội Cấn",
        "path": "Đội Cấn, Tuyên Quang, Tuyên Quang",
        "path_with_type": "Xã Đội Cấn, Thành phố Tuyên Quang, Tỉnh Tuyên Quang",
        "code": "02524",
        "parent_code": "070"
    },
    "02233": {
        "name": "Phúc Yên",
        "type": "xa",
        "slug": "phuc-yen",
        "name_with_type": "Xã Phúc Yên",
        "path": "Phúc Yên, Lâm Bình, Tuyên Quang",
        "path_with_type": "Xã Phúc Yên, Huyện Lâm Bình, Tỉnh Tuyên Quang",
        "code": "02233",
        "parent_code": "071"
    },
    "02242": {
        "name": "Xuân Lập",
        "type": "xa",
        "slug": "xuan-lap",
        "name_with_type": "Xã Xuân Lập",
        "path": "Xuân Lập, Lâm Bình, Tuyên Quang",
        "path_with_type": "Xã Xuân Lập, Huyện Lâm Bình, Tỉnh Tuyên Quang",
        "code": "02242",
        "parent_code": "071"
    },
    "02251": {
        "name": "Khuôn Hà",
        "type": "xa",
        "slug": "khuon-ha",
        "name_with_type": "Xã Khuôn Hà",
        "path": "Khuôn Hà, Lâm Bình, Tuyên Quang",
        "path_with_type": "Xã Khuôn Hà, Huyện Lâm Bình, Tỉnh Tuyên Quang",
        "code": "02251",
        "parent_code": "071"
    },
    "02266": {
        "name": "Lăng Can",
        "type": "xa",
        "slug": "lang-can",
        "name_with_type": "Xã Lăng Can",
        "path": "Lăng Can, Lâm Bình, Tuyên Quang",
        "path_with_type": "Xã Lăng Can, Huyện Lâm Bình, Tỉnh Tuyên Quang",
        "code": "02266",
        "parent_code": "071"
    },
    "02269": {
        "name": "Thượng Lâm",
        "type": "xa",
        "slug": "thuong-lam",
        "name_with_type": "Xã Thượng Lâm",
        "path": "Thượng Lâm, Lâm Bình, Tuyên Quang",
        "path_with_type": "Xã Thượng Lâm, Huyện Lâm Bình, Tỉnh Tuyên Quang",
        "code": "02269",
        "parent_code": "071"
    },
    "02290": {
        "name": "Bình An",
        "type": "xa",
        "slug": "binh-an",
        "name_with_type": "Xã Bình An",
        "path": "Bình An, Lâm Bình, Tuyên Quang",
        "path_with_type": "Xã Bình An, Huyện Lâm Bình, Tỉnh Tuyên Quang",
        "code": "02290",
        "parent_code": "071"
    },
    "02293": {
        "name": "Hồng Quang",
        "type": "xa",
        "slug": "hong-quang",
        "name_with_type": "Xã Hồng Quang",
        "path": "Hồng Quang, Lâm Bình, Tuyên Quang",
        "path_with_type": "Xã Hồng Quang, Huyện Lâm Bình, Tỉnh Tuyên Quang",
        "code": "02293",
        "parent_code": "071"
    },
    "02296": {
        "name": "Thổ Bình",
        "type": "xa",
        "slug": "tho-binh",
        "name_with_type": "Xã Thổ Bình",
        "path": "Thổ Bình, Lâm Bình, Tuyên Quang",
        "path_with_type": "Xã Thổ Bình, Huyện Lâm Bình, Tỉnh Tuyên Quang",
        "code": "02296",
        "parent_code": "071"
    },
    "02221": {
        "name": "Nà Hang",
        "type": "thi-tran",
        "slug": "na-hang",
        "name_with_type": "Thị trấn Nà Hang",
        "path": "Nà Hang, Nà Hang, Tuyên Quang",
        "path_with_type": "Thị trấn Nà Hang, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02221",
        "parent_code": "072"
    },
    "02227": {
        "name": "Sinh Long",
        "type": "xa",
        "slug": "sinh-long",
        "name_with_type": "Xã Sinh Long",
        "path": "Sinh Long, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Sinh Long, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02227",
        "parent_code": "072"
    },
    "02230": {
        "name": "Thượng Giáp",
        "type": "xa",
        "slug": "thuong-giap",
        "name_with_type": "Xã Thượng Giáp",
        "path": "Thượng Giáp, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Thượng Giáp, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02230",
        "parent_code": "072"
    },
    "02239": {
        "name": "Thượng Nông",
        "type": "xa",
        "slug": "thuong-nong",
        "name_with_type": "Xã Thượng Nông",
        "path": "Thượng Nông, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Thượng Nông, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02239",
        "parent_code": "072"
    },
    "02245": {
        "name": "Côn Lôn",
        "type": "xa",
        "slug": "con-lon",
        "name_with_type": "Xã Côn Lôn",
        "path": "Côn Lôn, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Côn Lôn, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02245",
        "parent_code": "072"
    },
    "02248": {
        "name": "Yên Hoa",
        "type": "xa",
        "slug": "yen-hoa",
        "name_with_type": "Xã Yên Hoa",
        "path": "Yên Hoa, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Yên Hoa, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02248",
        "parent_code": "072"
    },
    "02254": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Hồng Thái, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02254",
        "parent_code": "072"
    },
    "02260": {
        "name": "Đà Vị",
        "type": "xa",
        "slug": "da-vi",
        "name_with_type": "Xã Đà Vị",
        "path": "Đà Vị, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Đà Vị, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02260",
        "parent_code": "072"
    },
    "02263": {
        "name": "Khau Tinh",
        "type": "xa",
        "slug": "khau-tinh",
        "name_with_type": "Xã Khau Tinh",
        "path": "Khau Tinh, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Khau Tinh, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02263",
        "parent_code": "072"
    },
    "02275": {
        "name": "Sơn Phú",
        "type": "xa",
        "slug": "son-phu",
        "name_with_type": "Xã Sơn Phú",
        "path": "Sơn Phú, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Sơn Phú, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02275",
        "parent_code": "072"
    },
    "02281": {
        "name": "Năng Khả",
        "type": "xa",
        "slug": "nang-kha",
        "name_with_type": "Xã Năng Khả",
        "path": "Năng Khả, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Năng Khả, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02281",
        "parent_code": "072"
    },
    "02284": {
        "name": "Thanh Tương",
        "type": "xa",
        "slug": "thanh-tuong",
        "name_with_type": "Xã Thanh Tương",
        "path": "Thanh Tương, Nà Hang, Tuyên Quang",
        "path_with_type": "Xã Thanh Tương, Huyện Nà Hang, Tỉnh Tuyên Quang",
        "code": "02284",
        "parent_code": "072"
    },
    "02287": {
        "name": "Vĩnh Lộc",
        "type": "thi-tran",
        "slug": "vinh-loc",
        "name_with_type": "Thị trấn Vĩnh Lộc",
        "path": "Vĩnh Lộc, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Thị trấn Vĩnh Lộc, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02287",
        "parent_code": "073"
    },
    "02299": {
        "name": "Phúc Sơn",
        "type": "xa",
        "slug": "phuc-son",
        "name_with_type": "Xã Phúc Sơn",
        "path": "Phúc Sơn, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Phúc Sơn, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02299",
        "parent_code": "073"
    },
    "02302": {
        "name": "Minh Quang",
        "type": "xa",
        "slug": "minh-quang",
        "name_with_type": "Xã Minh Quang",
        "path": "Minh Quang, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Minh Quang, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02302",
        "parent_code": "073"
    },
    "02305": {
        "name": "Trung Hà",
        "type": "xa",
        "slug": "trung-ha",
        "name_with_type": "Xã Trung Hà",
        "path": "Trung Hà, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Trung Hà, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02305",
        "parent_code": "073"
    },
    "02308": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Tân Mỹ, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02308",
        "parent_code": "073"
    },
    "02311": {
        "name": "Hà Lang",
        "type": "xa",
        "slug": "ha-lang",
        "name_with_type": "Xã Hà Lang",
        "path": "Hà Lang, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Hà Lang, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02311",
        "parent_code": "073"
    },
    "02314": {
        "name": "Hùng Mỹ",
        "type": "xa",
        "slug": "hung-my",
        "name_with_type": "Xã Hùng Mỹ",
        "path": "Hùng Mỹ, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Hùng Mỹ, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02314",
        "parent_code": "073"
    },
    "02317": {
        "name": "Yên Lập",
        "type": "xa",
        "slug": "yen-lap",
        "name_with_type": "Xã Yên Lập",
        "path": "Yên Lập, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Yên Lập, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02317",
        "parent_code": "073"
    },
    "02320": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Tân An, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02320",
        "parent_code": "073"
    },
    "02323": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Bình Phú, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02323",
        "parent_code": "073"
    },
    "02326": {
        "name": "Xuân Quang",
        "type": "xa",
        "slug": "xuan-quang",
        "name_with_type": "Xã Xuân Quang",
        "path": "Xuân Quang, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Xuân Quang, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02326",
        "parent_code": "073"
    },
    "02329": {
        "name": "Ngọc Hội",
        "type": "xa",
        "slug": "ngoc-hoi",
        "name_with_type": "Xã Ngọc Hội",
        "path": "Ngọc Hội, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Ngọc Hội, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02329",
        "parent_code": "073"
    },
    "02332": {
        "name": "Phú Bình",
        "type": "xa",
        "slug": "phu-binh",
        "name_with_type": "Xã Phú Bình",
        "path": "Phú Bình, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Phú Bình, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02332",
        "parent_code": "073"
    },
    "02335": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Hòa Phú, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02335",
        "parent_code": "073"
    },
    "02338": {
        "name": "Phúc Thịnh",
        "type": "xa",
        "slug": "phuc-thinh",
        "name_with_type": "Xã Phúc Thịnh",
        "path": "Phúc Thịnh, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Phúc Thịnh, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02338",
        "parent_code": "073"
    },
    "02341": {
        "name": "Kiên Đài",
        "type": "xa",
        "slug": "kien-dai",
        "name_with_type": "Xã Kiên Đài",
        "path": "Kiên Đài, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Kiên Đài, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02341",
        "parent_code": "073"
    },
    "02344": {
        "name": "Tân Thịnh",
        "type": "xa",
        "slug": "tan-thinh",
        "name_with_type": "Xã Tân Thịnh",
        "path": "Tân Thịnh, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Tân Thịnh, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02344",
        "parent_code": "073"
    },
    "02347": {
        "name": "Trung Hòa",
        "type": "xa",
        "slug": "trung-hoa",
        "name_with_type": "Xã Trung Hòa",
        "path": "Trung Hòa, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Trung Hòa, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02347",
        "parent_code": "073"
    },
    "02350": {
        "name": "Kim Bình",
        "type": "xa",
        "slug": "kim-binh",
        "name_with_type": "Xã Kim Bình",
        "path": "Kim Bình, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Kim Bình, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02350",
        "parent_code": "073"
    },
    "02353": {
        "name": "Hòa An",
        "type": "xa",
        "slug": "hoa-an",
        "name_with_type": "Xã Hòa An",
        "path": "Hòa An, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Hòa An, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02353",
        "parent_code": "073"
    },
    "02356": {
        "name": "Vinh Quang",
        "type": "xa",
        "slug": "vinh-quang",
        "name_with_type": "Xã Vinh Quang",
        "path": "Vinh Quang, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Vinh Quang, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02356",
        "parent_code": "073"
    },
    "02359": {
        "name": "Tri Phú",
        "type": "xa",
        "slug": "tri-phu",
        "name_with_type": "Xã Tri Phú",
        "path": "Tri Phú, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Tri Phú, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02359",
        "parent_code": "073"
    },
    "02362": {
        "name": "Nhân Lý",
        "type": "xa",
        "slug": "nhan-ly",
        "name_with_type": "Xã Nhân Lý",
        "path": "Nhân Lý, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Nhân Lý, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02362",
        "parent_code": "073"
    },
    "02365": {
        "name": "Yên Nguyên",
        "type": "xa",
        "slug": "yen-nguyen",
        "name_with_type": "Xã Yên Nguyên",
        "path": "Yên Nguyên, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Yên Nguyên, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02365",
        "parent_code": "073"
    },
    "02368": {
        "name": "Linh Phú",
        "type": "xa",
        "slug": "linh-phu",
        "name_with_type": "Xã Linh Phú",
        "path": "Linh Phú, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Linh Phú, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02368",
        "parent_code": "073"
    },
    "02371": {
        "name": "Bình Nhân",
        "type": "xa",
        "slug": "binh-nhan",
        "name_with_type": "Xã Bình Nhân",
        "path": "Bình Nhân, Chiêm Hóa, Tuyên Quang",
        "path_with_type": "Xã Bình Nhân, Huyện Chiêm Hóa, Tỉnh Tuyên Quang",
        "code": "02371",
        "parent_code": "073"
    },
    "02374": {
        "name": "Tân Yên",
        "type": "thi-tran",
        "slug": "tan-yen",
        "name_with_type": "Thị trấn Tân Yên",
        "path": "Tân Yên, Hàm Yên, Tuyên Quang",
        "path_with_type": "Thị trấn Tân Yên, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02374",
        "parent_code": "074"
    },
    "02377": {
        "name": "Yên Thuận",
        "type": "xa",
        "slug": "yen-thuan",
        "name_with_type": "Xã Yên Thuận",
        "path": "Yên Thuận, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Yên Thuận, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02377",
        "parent_code": "074"
    },
    "02380": {
        "name": "Bạch Xa",
        "type": "xa",
        "slug": "bach-xa",
        "name_with_type": "Xã Bạch Xa",
        "path": "Bạch Xa, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Bạch Xa, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02380",
        "parent_code": "074"
    },
    "02383": {
        "name": "Minh Khương",
        "type": "xa",
        "slug": "minh-khuong",
        "name_with_type": "Xã Minh Khương",
        "path": "Minh Khương, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Minh Khương, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02383",
        "parent_code": "074"
    },
    "02386": {
        "name": "Yên Lâm",
        "type": "xa",
        "slug": "yen-lam",
        "name_with_type": "Xã Yên Lâm",
        "path": "Yên Lâm, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Yên Lâm, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02386",
        "parent_code": "074"
    },
    "02389": {
        "name": "Minh Dân",
        "type": "xa",
        "slug": "minh-dan",
        "name_with_type": "Xã Minh Dân",
        "path": "Minh Dân, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Minh Dân, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02389",
        "parent_code": "074"
    },
    "02392": {
        "name": "Phù Lưu",
        "type": "xa",
        "slug": "phu-luu",
        "name_with_type": "Xã Phù Lưu",
        "path": "Phù Lưu, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Phù Lưu, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02392",
        "parent_code": "074"
    },
    "02395": {
        "name": "Minh Hương",
        "type": "xa",
        "slug": "minh-huong",
        "name_with_type": "Xã Minh Hương",
        "path": "Minh Hương, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Minh Hương, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02395",
        "parent_code": "074"
    },
    "02398": {
        "name": "Yên Phú",
        "type": "xa",
        "slug": "yen-phu",
        "name_with_type": "Xã Yên Phú",
        "path": "Yên Phú, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Yên Phú, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02398",
        "parent_code": "074"
    },
    "02401": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Tân Thành, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02401",
        "parent_code": "074"
    },
    "02404": {
        "name": "Bình Xa",
        "type": "xa",
        "slug": "binh-xa",
        "name_with_type": "Xã Bình Xa",
        "path": "Bình Xa, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Bình Xa, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02404",
        "parent_code": "074"
    },
    "02407": {
        "name": "Thái Sơn",
        "type": "xa",
        "slug": "thai-son",
        "name_with_type": "Xã Thái Sơn",
        "path": "Thái Sơn, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Thái Sơn, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02407",
        "parent_code": "074"
    },
    "02410": {
        "name": "Nhân Mục",
        "type": "xa",
        "slug": "nhan-muc",
        "name_with_type": "Xã Nhân Mục",
        "path": "Nhân Mục, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Nhân Mục, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02410",
        "parent_code": "074"
    },
    "02413": {
        "name": "Thành Long",
        "type": "xa",
        "slug": "thanh-long",
        "name_with_type": "Xã Thành Long",
        "path": "Thành Long, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Thành Long, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02413",
        "parent_code": "074"
    },
    "02416": {
        "name": "Bằng Cốc",
        "type": "xa",
        "slug": "bang-coc",
        "name_with_type": "Xã Bằng Cốc",
        "path": "Bằng Cốc, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Bằng Cốc, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02416",
        "parent_code": "074"
    },
    "02419": {
        "name": "Thái Hòa",
        "type": "xa",
        "slug": "thai-hoa",
        "name_with_type": "Xã Thái Hòa",
        "path": "Thái Hòa, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Thái Hòa, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02419",
        "parent_code": "074"
    },
    "02422": {
        "name": "Đức Ninh",
        "type": "xa",
        "slug": "duc-ninh",
        "name_with_type": "Xã Đức Ninh",
        "path": "Đức Ninh, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Đức Ninh, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02422",
        "parent_code": "074"
    },
    "02425": {
        "name": "Hùng Đức",
        "type": "xa",
        "slug": "hung-duc",
        "name_with_type": "Xã Hùng Đức",
        "path": "Hùng Đức, Hàm Yên, Tuyên Quang",
        "path_with_type": "Xã Hùng Đức, Huyện Hàm Yên, Tỉnh Tuyên Quang",
        "code": "02425",
        "parent_code": "074"
    },
    "02428": {
        "name": "Tân Bình",
        "type": "thi-tran",
        "slug": "tan-binh",
        "name_with_type": "Thị trấn Tân Bình",
        "path": "Tân Bình, Yên Sơn, Tuyên Quang",
        "path_with_type": "Thị trấn Tân Bình, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02428",
        "parent_code": "075"
    },
    "02431": {
        "name": "Quí Quân",
        "type": "xa",
        "slug": "qui-quan",
        "name_with_type": "Xã Quí Quân",
        "path": "Quí Quân, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Quí Quân, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02431",
        "parent_code": "075"
    },
    "02434": {
        "name": "Lực Hành",
        "type": "xa",
        "slug": "luc-hanh",
        "name_with_type": "Xã Lực Hành",
        "path": "Lực Hành, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Lực Hành, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02434",
        "parent_code": "075"
    },
    "02437": {
        "name": "Kiến Thiết",
        "type": "xa",
        "slug": "kien-thiet",
        "name_with_type": "Xã Kiến Thiết",
        "path": "Kiến Thiết, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Kiến Thiết, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02437",
        "parent_code": "075"
    },
    "02440": {
        "name": "Trung Minh",
        "type": "xa",
        "slug": "trung-minh",
        "name_with_type": "Xã Trung Minh",
        "path": "Trung Minh, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Trung Minh, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02440",
        "parent_code": "075"
    },
    "02443": {
        "name": "Chiêu Yên",
        "type": "xa",
        "slug": "chieu-yen",
        "name_with_type": "Xã Chiêu Yên",
        "path": "Chiêu Yên, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Chiêu Yên, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02443",
        "parent_code": "075"
    },
    "02446": {
        "name": "Trung Trực",
        "type": "xa",
        "slug": "trung-truc",
        "name_with_type": "Xã Trung Trực",
        "path": "Trung Trực, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Trung Trực, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02446",
        "parent_code": "075"
    },
    "02449": {
        "name": "Xuân Vân",
        "type": "xa",
        "slug": "xuan-van",
        "name_with_type": "Xã Xuân Vân",
        "path": "Xuân Vân, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Xuân Vân, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02449",
        "parent_code": "075"
    },
    "02452": {
        "name": "Phúc Ninh",
        "type": "xa",
        "slug": "phuc-ninh",
        "name_with_type": "Xã Phúc Ninh",
        "path": "Phúc Ninh, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Phúc Ninh, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02452",
        "parent_code": "075"
    },
    "02455": {
        "name": "Hùng Lợi",
        "type": "xa",
        "slug": "hung-loi",
        "name_with_type": "Xã Hùng Lợi",
        "path": "Hùng Lợi, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Hùng Lợi, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02455",
        "parent_code": "075"
    },
    "02458": {
        "name": "Trung Sơn",
        "type": "xa",
        "slug": "trung-son",
        "name_with_type": "Xã Trung Sơn",
        "path": "Trung Sơn, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Trung Sơn, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02458",
        "parent_code": "075"
    },
    "02461": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Tân Tiến, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02461",
        "parent_code": "075"
    },
    "02464": {
        "name": "Tứ Quận",
        "type": "xa",
        "slug": "tu-quan",
        "name_with_type": "Xã Tứ Quận",
        "path": "Tứ Quận, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Tứ Quận, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02464",
        "parent_code": "075"
    },
    "02467": {
        "name": "Đạo Viện",
        "type": "xa",
        "slug": "dao-vien",
        "name_with_type": "Xã Đạo Viện",
        "path": "Đạo Viện, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Đạo Viện, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02467",
        "parent_code": "075"
    },
    "02470": {
        "name": "Tân Long",
        "type": "xa",
        "slug": "tan-long",
        "name_with_type": "Xã Tân Long",
        "path": "Tân Long, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Tân Long, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02470",
        "parent_code": "075"
    },
    "02473": {
        "name": "Thắng Quân",
        "type": "xa",
        "slug": "thang-quan",
        "name_with_type": "Xã Thắng Quân",
        "path": "Thắng Quân, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Thắng Quân, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02473",
        "parent_code": "075"
    },
    "02476": {
        "name": "Kim Quan",
        "type": "xa",
        "slug": "kim-quan",
        "name_with_type": "Xã Kim Quan",
        "path": "Kim Quan, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Kim Quan, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02476",
        "parent_code": "075"
    },
    "02479": {
        "name": "Lang Quán",
        "type": "xa",
        "slug": "lang-quan",
        "name_with_type": "Xã Lang Quán",
        "path": "Lang Quán, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Lang Quán, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02479",
        "parent_code": "075"
    },
    "02482": {
        "name": "Phú Thịnh",
        "type": "xa",
        "slug": "phu-thinh",
        "name_with_type": "Xã Phú Thịnh",
        "path": "Phú Thịnh, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Phú Thịnh, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02482",
        "parent_code": "075"
    },
    "02485": {
        "name": "Công Đa",
        "type": "xa",
        "slug": "cong-da",
        "name_with_type": "Xã Công Đa",
        "path": "Công Đa, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Công Đa, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02485",
        "parent_code": "075"
    },
    "02488": {
        "name": "Trung Môn",
        "type": "xa",
        "slug": "trung-mon",
        "name_with_type": "Xã Trung Môn",
        "path": "Trung Môn, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Trung Môn, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02488",
        "parent_code": "075"
    },
    "02491": {
        "name": "Chân Sơn",
        "type": "xa",
        "slug": "chan-son",
        "name_with_type": "Xã Chân Sơn",
        "path": "Chân Sơn, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Chân Sơn, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02491",
        "parent_code": "075"
    },
    "02494": {
        "name": "Thái Bình",
        "type": "xa",
        "slug": "thai-binh",
        "name_with_type": "Xã Thái Bình",
        "path": "Thái Bình, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Thái Bình, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02494",
        "parent_code": "075"
    },
    "02497": {
        "name": "Kim Phú",
        "type": "xa",
        "slug": "kim-phu",
        "name_with_type": "Xã Kim Phú",
        "path": "Kim Phú, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Kim Phú, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02497",
        "parent_code": "075"
    },
    "02500": {
        "name": "Tiến Bộ",
        "type": "xa",
        "slug": "tien-bo",
        "name_with_type": "Xã Tiến Bộ",
        "path": "Tiến Bộ, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Tiến Bộ, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02500",
        "parent_code": "075"
    },
    "02506": {
        "name": "Mỹ Bằng",
        "type": "xa",
        "slug": "my-bang",
        "name_with_type": "Xã Mỹ Bằng",
        "path": "Mỹ Bằng, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Mỹ Bằng, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02506",
        "parent_code": "075"
    },
    "02509": {
        "name": "Phú Lâm",
        "type": "xa",
        "slug": "phu-lam",
        "name_with_type": "Xã Phú Lâm",
        "path": "Phú Lâm, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Phú Lâm, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02509",
        "parent_code": "075"
    },
    "02518": {
        "name": "Hoàng Khai",
        "type": "xa",
        "slug": "hoang-khai",
        "name_with_type": "Xã Hoàng Khai",
        "path": "Hoàng Khai, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Hoàng Khai, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02518",
        "parent_code": "075"
    },
    "02527": {
        "name": "Nhữ Hán",
        "type": "xa",
        "slug": "nhu-han",
        "name_with_type": "Xã Nhữ Hán",
        "path": "Nhữ Hán, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Nhữ Hán, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02527",
        "parent_code": "075"
    },
    "02530": {
        "name": "Nhữ Khê",
        "type": "xa",
        "slug": "nhu-khe",
        "name_with_type": "Xã Nhữ Khê",
        "path": "Nhữ Khê, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Nhữ Khê, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02530",
        "parent_code": "075"
    },
    "02533": {
        "name": "Đội Bình",
        "type": "xa",
        "slug": "doi-binh",
        "name_with_type": "Xã Đội Bình",
        "path": "Đội Bình, Yên Sơn, Tuyên Quang",
        "path_with_type": "Xã Đội Bình, Huyện Yên Sơn, Tỉnh Tuyên Quang",
        "code": "02533",
        "parent_code": "075"
    },
    "02536": {
        "name": "Sơn Dương",
        "type": "thi-tran",
        "slug": "son-duong",
        "name_with_type": "Thị trấn Sơn Dương",
        "path": "Sơn Dương, Sơn Dương, Tuyên Quang",
        "path_with_type": "Thị trấn Sơn Dương, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02536",
        "parent_code": "076"
    },
    "02539": {
        "name": "Trung Yên",
        "type": "xa",
        "slug": "trung-yen",
        "name_with_type": "Xã Trung Yên",
        "path": "Trung Yên, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Trung Yên, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02539",
        "parent_code": "076"
    },
    "02542": {
        "name": "Minh Thanh",
        "type": "xa",
        "slug": "minh-thanh",
        "name_with_type": "Xã Minh Thanh",
        "path": "Minh Thanh, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Minh Thanh, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02542",
        "parent_code": "076"
    },
    "02545": {
        "name": "Tân Trào",
        "type": "xa",
        "slug": "tan-trao",
        "name_with_type": "Xã Tân Trào",
        "path": "Tân Trào, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Tân Trào, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02545",
        "parent_code": "076"
    },
    "02548": {
        "name": "Vĩnh Lợi",
        "type": "xa",
        "slug": "vinh-loi",
        "name_with_type": "Xã Vĩnh Lợi",
        "path": "Vĩnh Lợi, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Vĩnh Lợi, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02548",
        "parent_code": "076"
    },
    "02551": {
        "name": "Thượng Ấm",
        "type": "xa",
        "slug": "thuong-am",
        "name_with_type": "Xã Thượng Ấm",
        "path": "Thượng Ấm, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Thượng Ấm, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02551",
        "parent_code": "076"
    },
    "02554": {
        "name": "Bình Yên",
        "type": "xa",
        "slug": "binh-yen",
        "name_with_type": "Xã Bình Yên",
        "path": "Bình Yên, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Bình Yên, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02554",
        "parent_code": "076"
    },
    "02557": {
        "name": "Lương Thiện",
        "type": "xa",
        "slug": "luong-thien",
        "name_with_type": "Xã Lương Thiện",
        "path": "Lương Thiện, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Lương Thiện, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02557",
        "parent_code": "076"
    },
    "02560": {
        "name": "Tú Thịnh",
        "type": "xa",
        "slug": "tu-thinh",
        "name_with_type": "Xã Tú Thịnh",
        "path": "Tú Thịnh, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Tú Thịnh, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02560",
        "parent_code": "076"
    },
    "02563": {
        "name": "Cấp Tiến",
        "type": "xa",
        "slug": "cap-tien",
        "name_with_type": "Xã Cấp Tiến",
        "path": "Cấp Tiến, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Cấp Tiến, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02563",
        "parent_code": "076"
    },
    "02566": {
        "name": "Hợp Thành",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thành",
        "path": "Hợp Thành, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Hợp Thành, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02566",
        "parent_code": "076"
    },
    "02569": {
        "name": "Phúc Ứng",
        "type": "xa",
        "slug": "phuc-ung",
        "name_with_type": "Xã Phúc Ứng",
        "path": "Phúc Ứng, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Phúc Ứng, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02569",
        "parent_code": "076"
    },
    "02572": {
        "name": "Đông Thọ",
        "type": "xa",
        "slug": "dong-tho",
        "name_with_type": "Xã Đông Thọ",
        "path": "Đông Thọ, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Đông Thọ, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02572",
        "parent_code": "076"
    },
    "02575": {
        "name": "Kháng Nhật",
        "type": "xa",
        "slug": "khang-nhat",
        "name_with_type": "Xã Kháng Nhật",
        "path": "Kháng Nhật, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Kháng Nhật, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02575",
        "parent_code": "076"
    },
    "02578": {
        "name": "Hợp Hòa",
        "type": "xa",
        "slug": "hop-hoa",
        "name_with_type": "Xã Hợp Hòa",
        "path": "Hợp Hòa, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Hợp Hòa, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02578",
        "parent_code": "076"
    },
    "02581": {
        "name": "Thanh Phát",
        "type": "xa",
        "slug": "thanh-phat",
        "name_with_type": "Xã Thanh Phát",
        "path": "Thanh Phát, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Thanh Phát, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02581",
        "parent_code": "076"
    },
    "02584": {
        "name": "Quyết Thắng",
        "type": "xa",
        "slug": "quyet-thang",
        "name_with_type": "Xã Quyết Thắng",
        "path": "Quyết Thắng, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Quyết Thắng, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02584",
        "parent_code": "076"
    },
    "02587": {
        "name": "Đồng Quý",
        "type": "xa",
        "slug": "dong-quy",
        "name_with_type": "Xã Đồng Quý",
        "path": "Đồng Quý, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Đồng Quý, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02587",
        "parent_code": "076"
    },
    "02590": {
        "name": "Tuân Lộ",
        "type": "xa",
        "slug": "tuan-lo",
        "name_with_type": "Xã Tuân Lộ",
        "path": "Tuân Lộ, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Tuân Lộ, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02590",
        "parent_code": "076"
    },
    "02593": {
        "name": "Vân Sơn",
        "type": "xa",
        "slug": "van-son",
        "name_with_type": "Xã Vân Sơn",
        "path": "Vân Sơn, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Vân Sơn, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02593",
        "parent_code": "076"
    },
    "02596": {
        "name": "Văn Phú",
        "type": "xa",
        "slug": "van-phu",
        "name_with_type": "Xã Văn Phú",
        "path": "Văn Phú, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Văn Phú, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02596",
        "parent_code": "076"
    },
    "02599": {
        "name": "Chi Thiết",
        "type": "xa",
        "slug": "chi-thiet",
        "name_with_type": "Xã Chi Thiết",
        "path": "Chi Thiết, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Chi Thiết, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02599",
        "parent_code": "076"
    },
    "02602": {
        "name": "Đông Lợi",
        "type": "xa",
        "slug": "dong-loi",
        "name_with_type": "Xã Đông Lợi",
        "path": "Đông Lợi, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Đông Lợi, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02602",
        "parent_code": "076"
    },
    "02605": {
        "name": "Thiện Kế",
        "type": "xa",
        "slug": "thien-ke",
        "name_with_type": "Xã Thiện Kế",
        "path": "Thiện Kế, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Thiện Kế, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02605",
        "parent_code": "076"
    },
    "02608": {
        "name": "Hồng Lạc",
        "type": "xa",
        "slug": "hong-lac",
        "name_with_type": "Xã Hồng Lạc",
        "path": "Hồng Lạc, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Hồng Lạc, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02608",
        "parent_code": "076"
    },
    "02611": {
        "name": "Phú Lương",
        "type": "xa",
        "slug": "phu-luong",
        "name_with_type": "Xã Phú Lương",
        "path": "Phú Lương, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Phú Lương, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02611",
        "parent_code": "076"
    },
    "02614": {
        "name": "Ninh Lai",
        "type": "xa",
        "slug": "ninh-lai",
        "name_with_type": "Xã Ninh Lai",
        "path": "Ninh Lai, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Ninh Lai, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02614",
        "parent_code": "076"
    },
    "02617": {
        "name": "Đại Phú",
        "type": "xa",
        "slug": "dai-phu",
        "name_with_type": "Xã Đại Phú",
        "path": "Đại Phú, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Đại Phú, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02617",
        "parent_code": "076"
    },
    "02620": {
        "name": "Sơn Nam",
        "type": "xa",
        "slug": "son-nam",
        "name_with_type": "Xã Sơn Nam",
        "path": "Sơn Nam, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Sơn Nam, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02620",
        "parent_code": "076"
    },
    "02623": {
        "name": "Hào Phú",
        "type": "xa",
        "slug": "hao-phu",
        "name_with_type": "Xã Hào Phú",
        "path": "Hào Phú, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Hào Phú, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02623",
        "parent_code": "076"
    },
    "02626": {
        "name": "Tam Đa",
        "type": "xa",
        "slug": "tam-da",
        "name_with_type": "Xã Tam Đa",
        "path": "Tam Đa, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Tam Đa, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02626",
        "parent_code": "076"
    },
    "02629": {
        "name": "Sầm Dương",
        "type": "xa",
        "slug": "sam-duong",
        "name_with_type": "Xã Sầm Dương",
        "path": "Sầm Dương, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Sầm Dương, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02629",
        "parent_code": "076"
    },
    "02632": {
        "name": "Lâm Xuyên",
        "type": "xa",
        "slug": "lam-xuyen",
        "name_with_type": "Xã Lâm Xuyên",
        "path": "Lâm Xuyên, Sơn Dương, Tuyên Quang",
        "path_with_type": "Xã Lâm Xuyên, Huyện Sơn Dương, Tỉnh Tuyên Quang",
        "code": "02632",
        "parent_code": "076"
    },
    "21010": {
        "name": "Lê Hồng Phong",
        "type": "phuong",
        "slug": "le-hong-phong",
        "name_with_type": "Phường Lê Hồng Phong",
        "path": "Lê Hồng Phong, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Phường Lê Hồng Phong, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21010",
        "parent_code": "522"
    },
    "21013": {
        "name": "Trần Phú",
        "type": "phuong",
        "slug": "tran-phu",
        "name_with_type": "Phường Trần Phú",
        "path": "Trần Phú, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Phường Trần Phú, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21013",
        "parent_code": "522"
    },
    "21016": {
        "name": "Quảng Phú",
        "type": "phuong",
        "slug": "quang-phu",
        "name_with_type": "Phường Quảng Phú",
        "path": "Quảng Phú, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Phường Quảng Phú, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21016",
        "parent_code": "522"
    },
    "21019": {
        "name": "Nghĩa Chánh",
        "type": "phuong",
        "slug": "nghia-chanh",
        "name_with_type": "Phường Nghĩa Chánh",
        "path": "Nghĩa Chánh, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Phường Nghĩa Chánh, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21019",
        "parent_code": "522"
    },
    "21022": {
        "name": "Trần Hưng Đạo",
        "type": "phuong",
        "slug": "tran-hung-dao",
        "name_with_type": "Phường Trần Hưng Đạo",
        "path": "Trần Hưng Đạo, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Phường Trần Hưng Đạo, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21022",
        "parent_code": "522"
    },
    "21025": {
        "name": "Nguyễn Nghiêm",
        "type": "phuong",
        "slug": "nguyen-nghiem",
        "name_with_type": "Phường Nguyễn Nghiêm",
        "path": "Nguyễn Nghiêm, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Phường Nguyễn Nghiêm, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21025",
        "parent_code": "522"
    },
    "21028": {
        "name": "Nghĩa Lộ",
        "type": "phuong",
        "slug": "nghia-lo",
        "name_with_type": "Phường Nghĩa Lộ",
        "path": "Nghĩa Lộ, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Phường Nghĩa Lộ, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21028",
        "parent_code": "522"
    },
    "21031": {
        "name": "Chánh Lộ",
        "type": "phuong",
        "slug": "chanh-lo",
        "name_with_type": "Phường Chánh Lộ",
        "path": "Chánh Lộ, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Phường Chánh Lộ, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21031",
        "parent_code": "522"
    },
    "21034": {
        "name": "Nghĩa Dũng",
        "type": "xa",
        "slug": "nghia-dung",
        "name_with_type": "Xã Nghĩa Dũng",
        "path": "Nghĩa Dũng, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Dũng, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21034",
        "parent_code": "522"
    },
    "21037": {
        "name": "Nghĩa Dõng",
        "type": "xa",
        "slug": "nghia-dong",
        "name_with_type": "Xã Nghĩa Dõng",
        "path": "Nghĩa Dõng, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Dõng, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21037",
        "parent_code": "522"
    },
    "21172": {
        "name": "Trương Quang Trọng",
        "type": "phuong",
        "slug": "truong-quang-trong",
        "name_with_type": "Phường Trương Quang Trọng",
        "path": "Trương Quang Trọng, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Phường Trương Quang Trọng, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21172",
        "parent_code": "522"
    },
    "21187": {
        "name": "Tịnh Hòa",
        "type": "xa",
        "slug": "tinh-hoa",
        "name_with_type": "Xã Tịnh Hòa",
        "path": "Tịnh Hòa, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Hòa, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21187",
        "parent_code": "522"
    },
    "21190": {
        "name": "Tịnh Kỳ",
        "type": "xa",
        "slug": "tinh-ky",
        "name_with_type": "Xã Tịnh Kỳ",
        "path": "Tịnh Kỳ, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Kỳ, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21190",
        "parent_code": "522"
    },
    "21199": {
        "name": "Tịnh Thiện",
        "type": "xa",
        "slug": "tinh-thien",
        "name_with_type": "Xã Tịnh Thiện",
        "path": "Tịnh Thiện, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Thiện, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21199",
        "parent_code": "522"
    },
    "21202": {
        "name": "Tịnh Ấn Đông",
        "type": "xa",
        "slug": "tinh-an-dong",
        "name_with_type": "Xã Tịnh Ấn Đông",
        "path": "Tịnh Ấn Đông, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Ấn Đông, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21202",
        "parent_code": "522"
    },
    "21208": {
        "name": "Tịnh Châu",
        "type": "xa",
        "slug": "tinh-chau",
        "name_with_type": "Xã Tịnh Châu",
        "path": "Tịnh Châu, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Châu, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21208",
        "parent_code": "522"
    },
    "21211": {
        "name": "Tịnh Khê",
        "type": "xa",
        "slug": "tinh-khe",
        "name_with_type": "Xã Tịnh Khê",
        "path": "Tịnh Khê, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Khê, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21211",
        "parent_code": "522"
    },
    "21214": {
        "name": "Tịnh Long",
        "type": "xa",
        "slug": "tinh-long",
        "name_with_type": "Xã Tịnh Long",
        "path": "Tịnh Long, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Long, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21214",
        "parent_code": "522"
    },
    "21223": {
        "name": "Tịnh Ấn Tây",
        "type": "xa",
        "slug": "tinh-an-tay",
        "name_with_type": "Xã Tịnh Ấn Tây",
        "path": "Tịnh Ấn Tây, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Ấn Tây, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21223",
        "parent_code": "522"
    },
    "21232": {
        "name": "Tịnh An",
        "type": "xa",
        "slug": "tinh-an",
        "name_with_type": "Xã Tịnh An",
        "path": "Tịnh An, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Tịnh An, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21232",
        "parent_code": "522"
    },
    "21253": {
        "name": "Nghĩa Phú",
        "type": "xa",
        "slug": "nghia-phu",
        "name_with_type": "Xã Nghĩa Phú",
        "path": "Nghĩa Phú, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Phú, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21253",
        "parent_code": "522"
    },
    "21256": {
        "name": "Nghĩa Hà",
        "type": "xa",
        "slug": "nghia-ha",
        "name_with_type": "Xã Nghĩa Hà",
        "path": "Nghĩa Hà, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Hà, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21256",
        "parent_code": "522"
    },
    "21262": {
        "name": "Nghĩa An",
        "type": "xa",
        "slug": "nghia-an",
        "name_with_type": "Xã Nghĩa An",
        "path": "Nghĩa An, Quảng Ngãi, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa An, Thành phố Quảng Ngãi, Tỉnh Quảng Ngãi",
        "code": "21262",
        "parent_code": "522"
    },
    "21040": {
        "name": "Châu Ổ",
        "type": "thi-tran",
        "slug": "chau-o",
        "name_with_type": "Thị Trấn Châu Ổ",
        "path": "Châu Ổ, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Thị Trấn Châu Ổ, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21040",
        "parent_code": "524"
    },
    "21043": {
        "name": "Bình Thuận",
        "type": "xa",
        "slug": "binh-thuan",
        "name_with_type": "Xã Bình Thuận",
        "path": "Bình Thuận, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Thuận, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21043",
        "parent_code": "524"
    },
    "21046": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Thạnh, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21046",
        "parent_code": "524"
    },
    "21049": {
        "name": "Bình Đông",
        "type": "xa",
        "slug": "binh-dong",
        "name_with_type": "Xã Bình Đông",
        "path": "Bình Đông, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Đông, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21049",
        "parent_code": "524"
    },
    "21052": {
        "name": "Bình Chánh",
        "type": "xa",
        "slug": "binh-chanh",
        "name_with_type": "Xã Bình Chánh",
        "path": "Bình Chánh, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Chánh, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21052",
        "parent_code": "524"
    },
    "21055": {
        "name": "Bình Nguyên",
        "type": "xa",
        "slug": "binh-nguyen",
        "name_with_type": "Xã Bình Nguyên",
        "path": "Bình Nguyên, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Nguyên, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21055",
        "parent_code": "524"
    },
    "21058": {
        "name": "Bình Khương",
        "type": "xa",
        "slug": "binh-khuong",
        "name_with_type": "Xã Bình Khương",
        "path": "Bình Khương, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Khương, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21058",
        "parent_code": "524"
    },
    "21061": {
        "name": "Bình Trị",
        "type": "xa",
        "slug": "binh-tri",
        "name_with_type": "Xã Bình Trị",
        "path": "Bình Trị, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Trị, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21061",
        "parent_code": "524"
    },
    "21064": {
        "name": "Bình An",
        "type": "xa",
        "slug": "binh-an",
        "name_with_type": "Xã Bình An",
        "path": "Bình An, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình An, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21064",
        "parent_code": "524"
    },
    "21067": {
        "name": "Bình Hải",
        "type": "xa",
        "slug": "binh-hai",
        "name_with_type": "Xã Bình Hải",
        "path": "Bình Hải, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Hải, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21067",
        "parent_code": "524"
    },
    "21070": {
        "name": "Bình Dương",
        "type": "xa",
        "slug": "binh-duong",
        "name_with_type": "Xã Bình Dương",
        "path": "Bình Dương, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Dương, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21070",
        "parent_code": "524"
    },
    "21073": {
        "name": "Bình Phước",
        "type": "xa",
        "slug": "binh-phuoc",
        "name_with_type": "Xã Bình Phước",
        "path": "Bình Phước, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Phước, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21073",
        "parent_code": "524"
    },
    "21076": {
        "name": "Bình Thới",
        "type": "xa",
        "slug": "binh-thoi",
        "name_with_type": "Xã Bình Thới",
        "path": "Bình Thới, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Thới, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21076",
        "parent_code": "524"
    },
    "21079": {
        "name": "Bình Hòa",
        "type": "xa",
        "slug": "binh-hoa",
        "name_with_type": "Xã Bình Hòa",
        "path": "Bình Hòa, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Hòa, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21079",
        "parent_code": "524"
    },
    "21082": {
        "name": "Bình Trung",
        "type": "xa",
        "slug": "binh-trung",
        "name_with_type": "Xã Bình Trung",
        "path": "Bình Trung, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Trung, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21082",
        "parent_code": "524"
    },
    "21085": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Minh, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21085",
        "parent_code": "524"
    },
    "21088": {
        "name": "Bình Long",
        "type": "xa",
        "slug": "binh-long",
        "name_with_type": "Xã Bình Long",
        "path": "Bình Long, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Long, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21088",
        "parent_code": "524"
    },
    "21091": {
        "name": "Bình Thanh Tây",
        "type": "xa",
        "slug": "binh-thanh-tay",
        "name_with_type": "Xã Bình Thanh Tây",
        "path": "Bình Thanh Tây, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Thanh Tây, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21091",
        "parent_code": "524"
    },
    "21094": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Phú, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21094",
        "parent_code": "524"
    },
    "21097": {
        "name": "Bình Thanh Đông",
        "type": "xa",
        "slug": "binh-thanh-dong",
        "name_with_type": "Xã Bình Thanh Đông",
        "path": "Bình Thanh Đông, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Thanh Đông, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21097",
        "parent_code": "524"
    },
    "21100": {
        "name": "Bình Chương",
        "type": "xa",
        "slug": "binh-chuong",
        "name_with_type": "Xã Bình Chương",
        "path": "Bình Chương, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Chương, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21100",
        "parent_code": "524"
    },
    "21103": {
        "name": "Bình Hiệp",
        "type": "xa",
        "slug": "binh-hiep",
        "name_with_type": "Xã Bình Hiệp",
        "path": "Bình Hiệp, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Hiệp, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21103",
        "parent_code": "524"
    },
    "21106": {
        "name": "Bình Mỹ",
        "type": "xa",
        "slug": "binh-my",
        "name_with_type": "Xã Bình Mỹ",
        "path": "Bình Mỹ, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Mỹ, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21106",
        "parent_code": "524"
    },
    "21109": {
        "name": "Bình Tân",
        "type": "xa",
        "slug": "binh-tan",
        "name_with_type": "Xã Bình Tân",
        "path": "Bình Tân, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Tân, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21109",
        "parent_code": "524"
    },
    "21112": {
        "name": "Bình Châu",
        "type": "xa",
        "slug": "binh-chau",
        "name_with_type": "Xã Bình Châu",
        "path": "Bình Châu, Bình Sơn, Quảng Ngãi",
        "path_with_type": "Xã Bình Châu, Huyện Bình Sơn, Tỉnh Quảng Ngãi",
        "code": "21112",
        "parent_code": "524"
    },
    "21115": {
        "name": "Trà Xuân",
        "type": "thi-tran",
        "slug": "tra-xuan",
        "name_with_type": "Thị trấn Trà Xuân",
        "path": "Trà Xuân, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Thị trấn Trà Xuân, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21115",
        "parent_code": "525"
    },
    "21118": {
        "name": "Trà Giang",
        "type": "xa",
        "slug": "tra-giang",
        "name_with_type": "Xã Trà Giang",
        "path": "Trà Giang, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Xã Trà Giang, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21118",
        "parent_code": "525"
    },
    "21121": {
        "name": "Trà Thủy",
        "type": "xa",
        "slug": "tra-thuy",
        "name_with_type": "Xã Trà Thủy",
        "path": "Trà Thủy, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Xã Trà Thủy, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21121",
        "parent_code": "525"
    },
    "21124": {
        "name": "Trà Hiệp",
        "type": "xa",
        "slug": "tra-hiep",
        "name_with_type": "Xã Trà Hiệp",
        "path": "Trà Hiệp, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Xã Trà Hiệp, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21124",
        "parent_code": "525"
    },
    "21127": {
        "name": "Trà Bình",
        "type": "xa",
        "slug": "tra-binh",
        "name_with_type": "Xã Trà Bình",
        "path": "Trà Bình, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Xã Trà Bình, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21127",
        "parent_code": "525"
    },
    "21130": {
        "name": "Trà Phú",
        "type": "xa",
        "slug": "tra-phu",
        "name_with_type": "Xã Trà Phú",
        "path": "Trà Phú, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Xã Trà Phú, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21130",
        "parent_code": "525"
    },
    "21133": {
        "name": "Trà Lâm",
        "type": "xa",
        "slug": "tra-lam",
        "name_with_type": "Xã Trà Lâm",
        "path": "Trà Lâm, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Xã Trà Lâm, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21133",
        "parent_code": "525"
    },
    "21136": {
        "name": "Trà Tân",
        "type": "xa",
        "slug": "tra-tan",
        "name_with_type": "Xã Trà Tân",
        "path": "Trà Tân, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Xã Trà Tân, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21136",
        "parent_code": "525"
    },
    "21139": {
        "name": "Trà Sơn",
        "type": "xa",
        "slug": "tra-son",
        "name_with_type": "Xã Trà Sơn",
        "path": "Trà Sơn, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Xã Trà Sơn, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21139",
        "parent_code": "525"
    },
    "21142": {
        "name": "Trà Bùi",
        "type": "xa",
        "slug": "tra-bui",
        "name_with_type": "Xã Trà Bùi",
        "path": "Trà Bùi, Trà Bồng, Quảng Ngãi",
        "path_with_type": "Xã Trà Bùi, Huyện Trà Bồng, Tỉnh Quảng Ngãi",
        "code": "21142",
        "parent_code": "525"
    },
    "21145": {
        "name": "Trà Thanh",
        "type": "xa",
        "slug": "tra-thanh",
        "name_with_type": "Xã Trà Thanh",
        "path": "Trà Thanh, Tây Trà, Quảng Ngãi",
        "path_with_type": "Xã Trà Thanh, Huyện Tây Trà, Tỉnh Quảng Ngãi",
        "code": "21145",
        "parent_code": "526"
    },
    "21148": {
        "name": "Trà Khê",
        "type": "xa",
        "slug": "tra-khe",
        "name_with_type": "Xã Trà Khê",
        "path": "Trà Khê, Tây Trà, Quảng Ngãi",
        "path_with_type": "Xã Trà Khê, Huyện Tây Trà, Tỉnh Quảng Ngãi",
        "code": "21148",
        "parent_code": "526"
    },
    "21151": {
        "name": "Trà Quân",
        "type": "xa",
        "slug": "tra-quan",
        "name_with_type": "Xã Trà Quân",
        "path": "Trà Quân, Tây Trà, Quảng Ngãi",
        "path_with_type": "Xã Trà Quân, Huyện Tây Trà, Tỉnh Quảng Ngãi",
        "code": "21151",
        "parent_code": "526"
    },
    "21154": {
        "name": "Trà Phong",
        "type": "xa",
        "slug": "tra-phong",
        "name_with_type": "Xã Trà Phong",
        "path": "Trà Phong, Tây Trà, Quảng Ngãi",
        "path_with_type": "Xã Trà Phong, Huyện Tây Trà, Tỉnh Quảng Ngãi",
        "code": "21154",
        "parent_code": "526"
    },
    "21157": {
        "name": "Trà Lãnh",
        "type": "xa",
        "slug": "tra-lanh",
        "name_with_type": "Xã Trà Lãnh",
        "path": "Trà Lãnh, Tây Trà, Quảng Ngãi",
        "path_with_type": "Xã Trà Lãnh, Huyện Tây Trà, Tỉnh Quảng Ngãi",
        "code": "21157",
        "parent_code": "526"
    },
    "21160": {
        "name": "Trà Nham",
        "type": "xa",
        "slug": "tra-nham",
        "name_with_type": "Xã Trà Nham",
        "path": "Trà Nham, Tây Trà, Quảng Ngãi",
        "path_with_type": "Xã Trà Nham, Huyện Tây Trà, Tỉnh Quảng Ngãi",
        "code": "21160",
        "parent_code": "526"
    },
    "21163": {
        "name": "Trà Xinh",
        "type": "xa",
        "slug": "tra-xinh",
        "name_with_type": "Xã Trà Xinh",
        "path": "Trà Xinh, Tây Trà, Quảng Ngãi",
        "path_with_type": "Xã Trà Xinh, Huyện Tây Trà, Tỉnh Quảng Ngãi",
        "code": "21163",
        "parent_code": "526"
    },
    "21166": {
        "name": "Trà Thọ",
        "type": "xa",
        "slug": "tra-tho",
        "name_with_type": "Xã Trà Thọ",
        "path": "Trà Thọ, Tây Trà, Quảng Ngãi",
        "path_with_type": "Xã Trà Thọ, Huyện Tây Trà, Tỉnh Quảng Ngãi",
        "code": "21166",
        "parent_code": "526"
    },
    "21169": {
        "name": "Trà Trung",
        "type": "xa",
        "slug": "tra-trung",
        "name_with_type": "Xã Trà Trung",
        "path": "Trà Trung, Tây Trà, Quảng Ngãi",
        "path_with_type": "Xã Trà Trung, Huyện Tây Trà, Tỉnh Quảng Ngãi",
        "code": "21169",
        "parent_code": "526"
    },
    "21175": {
        "name": "Tịnh Thọ",
        "type": "xa",
        "slug": "tinh-tho",
        "name_with_type": "Xã Tịnh Thọ",
        "path": "Tịnh Thọ, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Thọ, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21175",
        "parent_code": "527"
    },
    "21178": {
        "name": "Tịnh Trà",
        "type": "xa",
        "slug": "tinh-tra",
        "name_with_type": "Xã Tịnh Trà",
        "path": "Tịnh Trà, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Trà, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21178",
        "parent_code": "527"
    },
    "21181": {
        "name": "Tịnh Phong",
        "type": "xa",
        "slug": "tinh-phong",
        "name_with_type": "Xã Tịnh Phong",
        "path": "Tịnh Phong, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Phong, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21181",
        "parent_code": "527"
    },
    "21184": {
        "name": "Tịnh Hiệp",
        "type": "xa",
        "slug": "tinh-hiep",
        "name_with_type": "Xã Tịnh Hiệp",
        "path": "Tịnh Hiệp, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Hiệp, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21184",
        "parent_code": "527"
    },
    "21193": {
        "name": "Tịnh Bình",
        "type": "xa",
        "slug": "tinh-binh",
        "name_with_type": "Xã Tịnh Bình",
        "path": "Tịnh Bình, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Bình, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21193",
        "parent_code": "527"
    },
    "21196": {
        "name": "Tịnh Đông",
        "type": "xa",
        "slug": "tinh-dong",
        "name_with_type": "Xã Tịnh Đông",
        "path": "Tịnh Đông, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Đông, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21196",
        "parent_code": "527"
    },
    "21205": {
        "name": "Tịnh Bắc",
        "type": "xa",
        "slug": "tinh-bac",
        "name_with_type": "Xã Tịnh Bắc",
        "path": "Tịnh Bắc, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Bắc, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21205",
        "parent_code": "527"
    },
    "21217": {
        "name": "Tịnh Sơn",
        "type": "xa",
        "slug": "tinh-son",
        "name_with_type": "Xã Tịnh Sơn",
        "path": "Tịnh Sơn, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Sơn, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21217",
        "parent_code": "527"
    },
    "21220": {
        "name": "Tịnh Hà",
        "type": "xa",
        "slug": "tinh-ha",
        "name_with_type": "Xã Tịnh Hà",
        "path": "Tịnh Hà, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Hà, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21220",
        "parent_code": "527"
    },
    "21226": {
        "name": "Tịnh Giang",
        "type": "xa",
        "slug": "tinh-giang",
        "name_with_type": "Xã Tịnh Giang",
        "path": "Tịnh Giang, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Giang, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21226",
        "parent_code": "527"
    },
    "21229": {
        "name": "Tịnh Minh",
        "type": "xa",
        "slug": "tinh-minh",
        "name_with_type": "Xã Tịnh Minh",
        "path": "Tịnh Minh, Sơn Tịnh, Quảng Ngãi",
        "path_with_type": "Xã Tịnh Minh, Huyện Sơn Tịnh, Tỉnh Quảng Ngãi",
        "code": "21229",
        "parent_code": "527"
    },
    "21235": {
        "name": "La Hà",
        "type": "thi-tran",
        "slug": "la-ha",
        "name_with_type": "Thị trấn La Hà",
        "path": "La Hà, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Thị trấn La Hà, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21235",
        "parent_code": "528"
    },
    "21238": {
        "name": "Sông Vệ",
        "type": "thi-tran",
        "slug": "song-ve",
        "name_with_type": "Thị trấn Sông Vệ",
        "path": "Sông Vệ, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Thị trấn Sông Vệ, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21238",
        "parent_code": "528"
    },
    "21241": {
        "name": "Nghĩa Lâm",
        "type": "xa",
        "slug": "nghia-lam",
        "name_with_type": "Xã Nghĩa Lâm",
        "path": "Nghĩa Lâm, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Lâm, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21241",
        "parent_code": "528"
    },
    "21244": {
        "name": "Nghĩa Thắng",
        "type": "xa",
        "slug": "nghia-thang",
        "name_with_type": "Xã Nghĩa Thắng",
        "path": "Nghĩa Thắng, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Thắng, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21244",
        "parent_code": "528"
    },
    "21247": {
        "name": "Nghĩa Thuận",
        "type": "xa",
        "slug": "nghia-thuan",
        "name_with_type": "Xã Nghĩa Thuận",
        "path": "Nghĩa Thuận, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Thuận, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21247",
        "parent_code": "528"
    },
    "21250": {
        "name": "Nghĩa Kỳ",
        "type": "xa",
        "slug": "nghia-ky",
        "name_with_type": "Xã Nghĩa Kỳ",
        "path": "Nghĩa Kỳ, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Kỳ, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21250",
        "parent_code": "528"
    },
    "21259": {
        "name": "Nghĩa Sơn",
        "type": "xa",
        "slug": "nghia-son",
        "name_with_type": "Xã Nghĩa Sơn",
        "path": "Nghĩa Sơn, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Sơn, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21259",
        "parent_code": "528"
    },
    "21265": {
        "name": "Nghĩa Thọ",
        "type": "xa",
        "slug": "nghia-tho",
        "name_with_type": "Xã Nghĩa Thọ",
        "path": "Nghĩa Thọ, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Thọ, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21265",
        "parent_code": "528"
    },
    "21268": {
        "name": "Nghĩa Hòa",
        "type": "xa",
        "slug": "nghia-hoa",
        "name_with_type": "Xã Nghĩa Hòa",
        "path": "Nghĩa Hòa, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Hòa, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21268",
        "parent_code": "528"
    },
    "21271": {
        "name": "Nghĩa Điền",
        "type": "xa",
        "slug": "nghia-dien",
        "name_with_type": "Xã Nghĩa Điền",
        "path": "Nghĩa Điền, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Điền, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21271",
        "parent_code": "528"
    },
    "21274": {
        "name": "Nghĩa Thương",
        "type": "xa",
        "slug": "nghia-thuong",
        "name_with_type": "Xã Nghĩa Thương",
        "path": "Nghĩa Thương, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Thương, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21274",
        "parent_code": "528"
    },
    "21277": {
        "name": "Nghĩa Trung",
        "type": "xa",
        "slug": "nghia-trung",
        "name_with_type": "Xã Nghĩa Trung",
        "path": "Nghĩa Trung, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Trung, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21277",
        "parent_code": "528"
    },
    "21280": {
        "name": "Nghĩa Hiệp",
        "type": "xa",
        "slug": "nghia-hiep",
        "name_with_type": "Xã Nghĩa Hiệp",
        "path": "Nghĩa Hiệp, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Hiệp, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21280",
        "parent_code": "528"
    },
    "21283": {
        "name": "Nghĩa Phương",
        "type": "xa",
        "slug": "nghia-phuong",
        "name_with_type": "Xã Nghĩa Phương",
        "path": "Nghĩa Phương, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Phương, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21283",
        "parent_code": "528"
    },
    "21286": {
        "name": "Nghĩa Mỹ",
        "type": "xa",
        "slug": "nghia-my",
        "name_with_type": "Xã Nghĩa Mỹ",
        "path": "Nghĩa Mỹ, Tư Nghĩa, Quảng Ngãi",
        "path_with_type": "Xã Nghĩa Mỹ, Huyện Tư Nghĩa, Tỉnh Quảng Ngãi",
        "code": "21286",
        "parent_code": "528"
    },
    "21289": {
        "name": "Di Lăng",
        "type": "thi-tran",
        "slug": "di-lang",
        "name_with_type": "Thị trấn Di Lăng",
        "path": "Di Lăng, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Thị trấn Di Lăng, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21289",
        "parent_code": "529"
    },
    "21292": {
        "name": "Sơn Hạ",
        "type": "xa",
        "slug": "son-ha",
        "name_with_type": "Xã Sơn Hạ",
        "path": "Sơn Hạ, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Hạ, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21292",
        "parent_code": "529"
    },
    "21295": {
        "name": "Sơn Thành",
        "type": "xa",
        "slug": "son-thanh",
        "name_with_type": "Xã Sơn Thành",
        "path": "Sơn Thành, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Thành, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21295",
        "parent_code": "529"
    },
    "21298": {
        "name": "Sơn Nham",
        "type": "xa",
        "slug": "son-nham",
        "name_with_type": "Xã Sơn Nham",
        "path": "Sơn Nham, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Nham, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21298",
        "parent_code": "529"
    },
    "21301": {
        "name": "Sơn Bao",
        "type": "xa",
        "slug": "son-bao",
        "name_with_type": "Xã Sơn Bao",
        "path": "Sơn Bao, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Bao, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21301",
        "parent_code": "529"
    },
    "21304": {
        "name": "Sơn Linh",
        "type": "xa",
        "slug": "son-linh",
        "name_with_type": "Xã Sơn Linh",
        "path": "Sơn Linh, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Linh, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21304",
        "parent_code": "529"
    },
    "21307": {
        "name": "Sơn Giang",
        "type": "xa",
        "slug": "son-giang",
        "name_with_type": "Xã Sơn Giang",
        "path": "Sơn Giang, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Giang, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21307",
        "parent_code": "529"
    },
    "21310": {
        "name": "Sơn Trung",
        "type": "xa",
        "slug": "son-trung",
        "name_with_type": "Xã Sơn Trung",
        "path": "Sơn Trung, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Trung, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21310",
        "parent_code": "529"
    },
    "21313": {
        "name": "Sơn Thượng",
        "type": "xa",
        "slug": "son-thuong",
        "name_with_type": "Xã Sơn Thượng",
        "path": "Sơn Thượng, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Thượng, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21313",
        "parent_code": "529"
    },
    "21316": {
        "name": "Sơn Cao",
        "type": "xa",
        "slug": "son-cao",
        "name_with_type": "Xã Sơn Cao",
        "path": "Sơn Cao, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Cao, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21316",
        "parent_code": "529"
    },
    "21319": {
        "name": "Sơn Hải",
        "type": "xa",
        "slug": "son-hai",
        "name_with_type": "Xã Sơn Hải",
        "path": "Sơn Hải, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Hải, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21319",
        "parent_code": "529"
    },
    "21322": {
        "name": "Sơn Thủy",
        "type": "xa",
        "slug": "son-thuy",
        "name_with_type": "Xã Sơn Thủy",
        "path": "Sơn Thủy, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Thủy, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21322",
        "parent_code": "529"
    },
    "21325": {
        "name": "Sơn Kỳ",
        "type": "xa",
        "slug": "son-ky",
        "name_with_type": "Xã Sơn Kỳ",
        "path": "Sơn Kỳ, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Kỳ, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21325",
        "parent_code": "529"
    },
    "21328": {
        "name": "Sơn Ba",
        "type": "xa",
        "slug": "son-ba",
        "name_with_type": "Xã Sơn Ba",
        "path": "Sơn Ba, Sơn Hà, Quảng Ngãi",
        "path_with_type": "Xã Sơn Ba, Huyện Sơn Hà, Tỉnh Quảng Ngãi",
        "code": "21328",
        "parent_code": "529"
    },
    "21331": {
        "name": "Sơn Bua",
        "type": "xa",
        "slug": "son-bua",
        "name_with_type": "Xã Sơn Bua",
        "path": "Sơn Bua, Sơn Tây, Quảng Ngãi",
        "path_with_type": "Xã Sơn Bua, Huyện Sơn Tây, Tỉnh Quảng Ngãi",
        "code": "21331",
        "parent_code": "530"
    },
    "21334": {
        "name": "Sơn Mùa",
        "type": "xa",
        "slug": "son-mua",
        "name_with_type": "Xã Sơn Mùa",
        "path": "Sơn Mùa, Sơn Tây, Quảng Ngãi",
        "path_with_type": "Xã Sơn Mùa, Huyện Sơn Tây, Tỉnh Quảng Ngãi",
        "code": "21334",
        "parent_code": "530"
    },
    "21335": {
        "name": "Sơn Liên",
        "type": "xa",
        "slug": "son-lien",
        "name_with_type": "Xã Sơn Liên",
        "path": "Sơn Liên, Sơn Tây, Quảng Ngãi",
        "path_with_type": "Xã Sơn Liên, Huyện Sơn Tây, Tỉnh Quảng Ngãi",
        "code": "21335",
        "parent_code": "530"
    },
    "21337": {
        "name": "Sơn Tân",
        "type": "xa",
        "slug": "son-tan",
        "name_with_type": "Xã Sơn Tân",
        "path": "Sơn Tân, Sơn Tây, Quảng Ngãi",
        "path_with_type": "Xã Sơn Tân, Huyện Sơn Tây, Tỉnh Quảng Ngãi",
        "code": "21337",
        "parent_code": "530"
    },
    "21338": {
        "name": "Sơn Màu",
        "type": "xa",
        "slug": "son-mau",
        "name_with_type": "Xã Sơn Màu",
        "path": "Sơn Màu, Sơn Tây, Quảng Ngãi",
        "path_with_type": "Xã Sơn Màu, Huyện Sơn Tây, Tỉnh Quảng Ngãi",
        "code": "21338",
        "parent_code": "530"
    },
    "21340": {
        "name": "Sơn Dung",
        "type": "xa",
        "slug": "son-dung",
        "name_with_type": "Xã Sơn Dung",
        "path": "Sơn Dung, Sơn Tây, Quảng Ngãi",
        "path_with_type": "Xã Sơn Dung, Huyện Sơn Tây, Tỉnh Quảng Ngãi",
        "code": "21340",
        "parent_code": "530"
    },
    "21341": {
        "name": "Sơn Long",
        "type": "xa",
        "slug": "son-long",
        "name_with_type": "Xã Sơn Long",
        "path": "Sơn Long, Sơn Tây, Quảng Ngãi",
        "path_with_type": "Xã Sơn Long, Huyện Sơn Tây, Tỉnh Quảng Ngãi",
        "code": "21341",
        "parent_code": "530"
    },
    "21343": {
        "name": "Sơn Tinh",
        "type": "xa",
        "slug": "son-tinh",
        "name_with_type": "Xã Sơn Tinh",
        "path": "Sơn Tinh, Sơn Tây, Quảng Ngãi",
        "path_with_type": "Xã Sơn Tinh, Huyện Sơn Tây, Tỉnh Quảng Ngãi",
        "code": "21343",
        "parent_code": "530"
    },
    "21346": {
        "name": "Sơn Lập",
        "type": "xa",
        "slug": "son-lap",
        "name_with_type": "Xã Sơn Lập",
        "path": "Sơn Lập, Sơn Tây, Quảng Ngãi",
        "path_with_type": "Xã Sơn Lập, Huyện Sơn Tây, Tỉnh Quảng Ngãi",
        "code": "21346",
        "parent_code": "530"
    },
    "21349": {
        "name": "Long Sơn",
        "type": "xa",
        "slug": "long-son",
        "name_with_type": "Xã Long Sơn",
        "path": "Long Sơn, Minh Long, Quảng Ngãi",
        "path_with_type": "Xã Long Sơn, Huyện Minh Long, Tỉnh Quảng Ngãi",
        "code": "21349",
        "parent_code": "531"
    },
    "21352": {
        "name": "Long Mai",
        "type": "xa",
        "slug": "long-mai",
        "name_with_type": "Xã Long Mai",
        "path": "Long Mai, Minh Long, Quảng Ngãi",
        "path_with_type": "Xã Long Mai, Huyện Minh Long, Tỉnh Quảng Ngãi",
        "code": "21352",
        "parent_code": "531"
    },
    "21355": {
        "name": "Thanh An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thanh An",
        "path": "Thanh An, Minh Long, Quảng Ngãi",
        "path_with_type": "Xã Thanh An, Huyện Minh Long, Tỉnh Quảng Ngãi",
        "code": "21355",
        "parent_code": "531"
    },
    "21358": {
        "name": "Long Môn",
        "type": "xa",
        "slug": "long-mon",
        "name_with_type": "Xã Long Môn",
        "path": "Long Môn, Minh Long, Quảng Ngãi",
        "path_with_type": "Xã Long Môn, Huyện Minh Long, Tỉnh Quảng Ngãi",
        "code": "21358",
        "parent_code": "531"
    },
    "21361": {
        "name": "Long Hiệp",
        "type": "xa",
        "slug": "long-hiep",
        "name_with_type": "Xã Long Hiệp",
        "path": "Long Hiệp, Minh Long, Quảng Ngãi",
        "path_with_type": "Xã Long Hiệp, Huyện Minh Long, Tỉnh Quảng Ngãi",
        "code": "21361",
        "parent_code": "531"
    },
    "21364": {
        "name": "Chợ Chùa",
        "type": "thi-tran",
        "slug": "cho-chua",
        "name_with_type": "Thị trấn Chợ Chùa",
        "path": "Chợ Chùa, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Thị trấn Chợ Chùa, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21364",
        "parent_code": "532"
    },
    "21367": {
        "name": "Hành Thuận",
        "type": "xa",
        "slug": "hanh-thuan",
        "name_with_type": "Xã Hành Thuận",
        "path": "Hành Thuận, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Thuận, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21367",
        "parent_code": "532"
    },
    "21370": {
        "name": "Hành Dũng",
        "type": "xa",
        "slug": "hanh-dung",
        "name_with_type": "Xã Hành Dũng",
        "path": "Hành Dũng, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Dũng, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21370",
        "parent_code": "532"
    },
    "21373": {
        "name": "Hành Trung",
        "type": "xa",
        "slug": "hanh-trung",
        "name_with_type": "Xã Hành Trung",
        "path": "Hành Trung, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Trung, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21373",
        "parent_code": "532"
    },
    "21376": {
        "name": "Hành Nhân",
        "type": "xa",
        "slug": "hanh-nhan",
        "name_with_type": "Xã Hành Nhân",
        "path": "Hành Nhân, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Nhân, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21376",
        "parent_code": "532"
    },
    "21379": {
        "name": "Hành Đức",
        "type": "xa",
        "slug": "hanh-duc",
        "name_with_type": "Xã Hành Đức",
        "path": "Hành Đức, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Đức, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21379",
        "parent_code": "532"
    },
    "21382": {
        "name": "Hành Minh",
        "type": "xa",
        "slug": "hanh-minh",
        "name_with_type": "Xã Hành Minh",
        "path": "Hành Minh, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Minh, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21382",
        "parent_code": "532"
    },
    "21385": {
        "name": "Hành Phước",
        "type": "xa",
        "slug": "hanh-phuoc",
        "name_with_type": "Xã Hành Phước",
        "path": "Hành Phước, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Phước, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21385",
        "parent_code": "532"
    },
    "21388": {
        "name": "Hành Thiện",
        "type": "xa",
        "slug": "hanh-thien",
        "name_with_type": "Xã Hành Thiện",
        "path": "Hành Thiện, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Thiện, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21388",
        "parent_code": "532"
    },
    "21391": {
        "name": "Hành Thịnh",
        "type": "xa",
        "slug": "hanh-thinh",
        "name_with_type": "Xã Hành Thịnh",
        "path": "Hành Thịnh, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Thịnh, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21391",
        "parent_code": "532"
    },
    "21394": {
        "name": "Hành Tín Tây",
        "type": "xa",
        "slug": "hanh-tin-tay",
        "name_with_type": "Xã Hành Tín Tây",
        "path": "Hành Tín Tây, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Tín Tây, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21394",
        "parent_code": "532"
    },
    "21397": {
        "name": "Hành Tín  Đông",
        "type": "xa",
        "slug": "hanh-tin-dong",
        "name_with_type": "Xã Hành Tín  Đông",
        "path": "Hành Tín  Đông, Nghĩa Hành, Quảng Ngãi",
        "path_with_type": "Xã Hành Tín  Đông, Huyện Nghĩa Hành, Tỉnh Quảng Ngãi",
        "code": "21397",
        "parent_code": "532"
    },
    "21400": {
        "name": "Mộ Đức",
        "type": "thi-tran",
        "slug": "mo-duc",
        "name_with_type": "Thị trấn Mộ Đức",
        "path": "Mộ Đức, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Thị trấn Mộ Đức, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21400",
        "parent_code": "533"
    },
    "21403": {
        "name": "Đức Lợi",
        "type": "xa",
        "slug": "duc-loi",
        "name_with_type": "Xã Đức Lợi",
        "path": "Đức Lợi, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Lợi, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21403",
        "parent_code": "533"
    },
    "21406": {
        "name": "Đức Thắng",
        "type": "xa",
        "slug": "duc-thang",
        "name_with_type": "Xã Đức Thắng",
        "path": "Đức Thắng, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Thắng, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21406",
        "parent_code": "533"
    },
    "21409": {
        "name": "Đức Nhuận",
        "type": "xa",
        "slug": "duc-nhuan",
        "name_with_type": "Xã Đức Nhuận",
        "path": "Đức Nhuận, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Nhuận, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21409",
        "parent_code": "533"
    },
    "21412": {
        "name": "Đức Chánh",
        "type": "xa",
        "slug": "duc-chanh",
        "name_with_type": "Xã Đức Chánh",
        "path": "Đức Chánh, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Chánh, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21412",
        "parent_code": "533"
    },
    "21415": {
        "name": "Đức Hiệp",
        "type": "xa",
        "slug": "duc-hiep",
        "name_with_type": "Xã Đức Hiệp",
        "path": "Đức Hiệp, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Hiệp, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21415",
        "parent_code": "533"
    },
    "21418": {
        "name": "Đức Minh",
        "type": "xa",
        "slug": "duc-minh",
        "name_with_type": "Xã Đức Minh",
        "path": "Đức Minh, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Minh, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21418",
        "parent_code": "533"
    },
    "21421": {
        "name": "Đức Thạnh",
        "type": "xa",
        "slug": "duc-thanh",
        "name_with_type": "Xã Đức Thạnh",
        "path": "Đức Thạnh, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Thạnh, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21421",
        "parent_code": "533"
    },
    "21424": {
        "name": "Đức Hòa",
        "type": "xa",
        "slug": "duc-hoa",
        "name_with_type": "Xã Đức Hòa",
        "path": "Đức Hòa, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Hòa, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21424",
        "parent_code": "533"
    },
    "21427": {
        "name": "Đức Tân",
        "type": "xa",
        "slug": "duc-tan",
        "name_with_type": "Xã Đức Tân",
        "path": "Đức Tân, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Tân, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21427",
        "parent_code": "533"
    },
    "21430": {
        "name": "Đức Phú",
        "type": "xa",
        "slug": "duc-phu",
        "name_with_type": "Xã Đức Phú",
        "path": "Đức Phú, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Phú, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21430",
        "parent_code": "533"
    },
    "21433": {
        "name": "Đức Phong",
        "type": "xa",
        "slug": "duc-phong",
        "name_with_type": "Xã Đức Phong",
        "path": "Đức Phong, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Phong, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21433",
        "parent_code": "533"
    },
    "21436": {
        "name": "Đức Lân",
        "type": "xa",
        "slug": "duc-lan",
        "name_with_type": "Xã Đức Lân",
        "path": "Đức Lân, Mộ Đức, Quảng Ngãi",
        "path_with_type": "Xã Đức Lân, Huyện Mộ Đức, Tỉnh Quảng Ngãi",
        "code": "21436",
        "parent_code": "533"
    },
    "21439": {
        "name": "Đức Phổ",
        "type": "thi-tran",
        "slug": "duc-pho",
        "name_with_type": "Thị trấn Đức Phổ",
        "path": "Đức Phổ, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Thị trấn Đức Phổ, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21439",
        "parent_code": "534"
    },
    "21442": {
        "name": "Phổ An",
        "type": "xa",
        "slug": "pho-an",
        "name_with_type": "Xã Phổ An",
        "path": "Phổ An, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ An, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21442",
        "parent_code": "534"
    },
    "21445": {
        "name": "Phổ Phong",
        "type": "xa",
        "slug": "pho-phong",
        "name_with_type": "Xã Phổ Phong",
        "path": "Phổ Phong, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Phong, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21445",
        "parent_code": "534"
    },
    "21448": {
        "name": "Phổ Thuận",
        "type": "xa",
        "slug": "pho-thuan",
        "name_with_type": "Xã Phổ Thuận",
        "path": "Phổ Thuận, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Thuận, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21448",
        "parent_code": "534"
    },
    "21451": {
        "name": "Phổ Văn",
        "type": "xa",
        "slug": "pho-van",
        "name_with_type": "Xã Phổ Văn",
        "path": "Phổ Văn, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Văn, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21451",
        "parent_code": "534"
    },
    "21454": {
        "name": "Phổ Quang",
        "type": "xa",
        "slug": "pho-quang",
        "name_with_type": "Xã Phổ Quang",
        "path": "Phổ Quang, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Quang, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21454",
        "parent_code": "534"
    },
    "21457": {
        "name": "Phổ Nhơn",
        "type": "xa",
        "slug": "pho-nhon",
        "name_with_type": "Xã Phổ Nhơn",
        "path": "Phổ Nhơn, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Nhơn, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21457",
        "parent_code": "534"
    },
    "21460": {
        "name": "Phổ Ninh",
        "type": "xa",
        "slug": "pho-ninh",
        "name_with_type": "Xã Phổ Ninh",
        "path": "Phổ Ninh, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Ninh, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21460",
        "parent_code": "534"
    },
    "21463": {
        "name": "Phổ Minh",
        "type": "xa",
        "slug": "pho-minh",
        "name_with_type": "Xã Phổ Minh",
        "path": "Phổ Minh, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Minh, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21463",
        "parent_code": "534"
    },
    "21466": {
        "name": "Phổ Vinh",
        "type": "xa",
        "slug": "pho-vinh",
        "name_with_type": "Xã Phổ Vinh",
        "path": "Phổ Vinh, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Vinh, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21466",
        "parent_code": "534"
    },
    "21469": {
        "name": "Phổ Hòa",
        "type": "xa",
        "slug": "pho-hoa",
        "name_with_type": "Xã Phổ Hòa",
        "path": "Phổ Hòa, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Hòa, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21469",
        "parent_code": "534"
    },
    "21472": {
        "name": "Phổ Cường",
        "type": "xa",
        "slug": "pho-cuong",
        "name_with_type": "Xã Phổ Cường",
        "path": "Phổ Cường, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Cường, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21472",
        "parent_code": "534"
    },
    "21475": {
        "name": "Phổ Khánh",
        "type": "xa",
        "slug": "pho-khanh",
        "name_with_type": "Xã Phổ Khánh",
        "path": "Phổ Khánh, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Khánh, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21475",
        "parent_code": "534"
    },
    "21478": {
        "name": "Phổ Thạnh",
        "type": "xa",
        "slug": "pho-thanh",
        "name_with_type": "Xã Phổ Thạnh",
        "path": "Phổ Thạnh, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Thạnh, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21478",
        "parent_code": "534"
    },
    "21481": {
        "name": "Phổ Châu",
        "type": "xa",
        "slug": "pho-chau",
        "name_with_type": "Xã Phổ Châu",
        "path": "Phổ Châu, Đức Phổ, Quảng Ngãi",
        "path_with_type": "Xã Phổ Châu, Huyện Đức Phổ, Tỉnh Quảng Ngãi",
        "code": "21481",
        "parent_code": "534"
    },
    "21484": {
        "name": "Ba Tơ",
        "type": "thi-tran",
        "slug": "ba-to",
        "name_with_type": "Thị trấn Ba Tơ",
        "path": "Ba Tơ, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Thị trấn Ba Tơ, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21484",
        "parent_code": "535"
    },
    "21487": {
        "name": "Ba Điền",
        "type": "xa",
        "slug": "ba-dien",
        "name_with_type": "Xã Ba Điền",
        "path": "Ba Điền, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Điền, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21487",
        "parent_code": "535"
    },
    "21490": {
        "name": "Ba Vinh",
        "type": "xa",
        "slug": "ba-vinh",
        "name_with_type": "Xã Ba Vinh",
        "path": "Ba Vinh, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Vinh, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21490",
        "parent_code": "535"
    },
    "21493": {
        "name": "Ba Thành",
        "type": "xa",
        "slug": "ba-thanh",
        "name_with_type": "Xã Ba Thành",
        "path": "Ba Thành, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Thành, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21493",
        "parent_code": "535"
    },
    "21496": {
        "name": "Ba Động",
        "type": "xa",
        "slug": "ba-dong",
        "name_with_type": "Xã Ba Động",
        "path": "Ba Động, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Động, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21496",
        "parent_code": "535"
    },
    "21499": {
        "name": "Ba Dinh",
        "type": "xa",
        "slug": "ba-dinh",
        "name_with_type": "Xã Ba Dinh",
        "path": "Ba Dinh, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Dinh, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21499",
        "parent_code": "535"
    },
    "21500": {
        "name": "Ba Giang",
        "type": "xa",
        "slug": "ba-giang",
        "name_with_type": "Xã Ba Giang",
        "path": "Ba Giang, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Giang, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21500",
        "parent_code": "535"
    },
    "21502": {
        "name": "Ba Liên",
        "type": "xa",
        "slug": "ba-lien",
        "name_with_type": "Xã Ba Liên",
        "path": "Ba Liên, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Liên, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21502",
        "parent_code": "535"
    },
    "21505": {
        "name": "Ba Ngạc",
        "type": "xa",
        "slug": "ba-ngac",
        "name_with_type": "Xã Ba Ngạc",
        "path": "Ba Ngạc, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Ngạc, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21505",
        "parent_code": "535"
    },
    "21508": {
        "name": "Ba Khâm",
        "type": "xa",
        "slug": "ba-kham",
        "name_with_type": "Xã Ba Khâm",
        "path": "Ba Khâm, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Khâm, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21508",
        "parent_code": "535"
    },
    "21511": {
        "name": "Ba Cung",
        "type": "xa",
        "slug": "ba-cung",
        "name_with_type": "Xã Ba Cung",
        "path": "Ba Cung, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Cung, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21511",
        "parent_code": "535"
    },
    "21514": {
        "name": "Ba Chùa",
        "type": "xa",
        "slug": "ba-chua",
        "name_with_type": "Xã Ba Chùa",
        "path": "Ba Chùa, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Chùa, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21514",
        "parent_code": "535"
    },
    "21517": {
        "name": "Ba Tiêu",
        "type": "xa",
        "slug": "ba-tieu",
        "name_with_type": "Xã Ba Tiêu",
        "path": "Ba Tiêu, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Tiêu, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21517",
        "parent_code": "535"
    },
    "21520": {
        "name": "Ba Trang",
        "type": "xa",
        "slug": "ba-trang",
        "name_with_type": "Xã Ba Trang",
        "path": "Ba Trang, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Trang, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21520",
        "parent_code": "535"
    },
    "21523": {
        "name": "Ba Tô",
        "type": "xa",
        "slug": "ba-to",
        "name_with_type": "Xã Ba Tô",
        "path": "Ba Tô, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Tô, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21523",
        "parent_code": "535"
    },
    "21526": {
        "name": "Ba Bích",
        "type": "xa",
        "slug": "ba-bich",
        "name_with_type": "Xã Ba Bích",
        "path": "Ba Bích, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Bích, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21526",
        "parent_code": "535"
    },
    "21529": {
        "name": "Ba Vì",
        "type": "xa",
        "slug": "ba-vi",
        "name_with_type": "Xã Ba Vì",
        "path": "Ba Vì, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Vì, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21529",
        "parent_code": "535"
    },
    "21532": {
        "name": "Ba Lế",
        "type": "xa",
        "slug": "ba-le",
        "name_with_type": "Xã Ba Lế",
        "path": "Ba Lế, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Lế, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21532",
        "parent_code": "535"
    },
    "21535": {
        "name": "Ba Nam",
        "type": "xa",
        "slug": "ba-nam",
        "name_with_type": "Xã Ba Nam",
        "path": "Ba Nam, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Nam, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21535",
        "parent_code": "535"
    },
    "21538": {
        "name": "Ba Xa",
        "type": "xa",
        "slug": "ba-xa",
        "name_with_type": "Xã Ba Xa",
        "path": "Ba Xa, Ba Tơ, Quảng Ngãi",
        "path_with_type": "Xã Ba Xa, Huyện Ba Tơ, Tỉnh Quảng Ngãi",
        "code": "21538",
        "parent_code": "535"
    },
    "21541": {
        "name": "An Vĩnh",
        "type": "xa",
        "slug": "an-vinh",
        "name_with_type": "Xã An Vĩnh",
        "path": "An Vĩnh, Lý Sơn, Quảng Ngãi",
        "path_with_type": "Xã An Vĩnh, Huyện Lý Sơn, Tỉnh Quảng Ngãi",
        "code": "21541",
        "parent_code": "536"
    },
    "21544": {
        "name": "An Hải",
        "type": "xa",
        "slug": "an-hai",
        "name_with_type": "Xã An Hải",
        "path": "An Hải, Lý Sơn, Quảng Ngãi",
        "path_with_type": "Xã An Hải, Huyện Lý Sơn, Tỉnh Quảng Ngãi",
        "code": "21544",
        "parent_code": "536"
    },
    "21547": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Lý Sơn, Quảng Ngãi",
        "path_with_type": "Xã An Bình, Huyện Lý Sơn, Tỉnh Quảng Ngãi",
        "code": "21547",
        "parent_code": "536"
    },
    "00001": {
        "name": "Phúc Xá",
        "type": "phuong",
        "slug": "phuc-xa",
        "name_with_type": "Phường Phúc Xá",
        "path": "Phúc Xá, Ba Đình, Hà Nội",
        "path_with_type": "Phường Phúc Xá, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00001",
        "parent_code": "001"
    },
    "00004": {
        "name": "Trúc Bạch",
        "type": "phuong",
        "slug": "truc-bach",
        "name_with_type": "Phường Trúc Bạch",
        "path": "Trúc Bạch, Ba Đình, Hà Nội",
        "path_with_type": "Phường Trúc Bạch, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00004",
        "parent_code": "001"
    },
    "00006": {
        "name": "Vĩnh Phúc",
        "type": "phuong",
        "slug": "vinh-phuc",
        "name_with_type": "Phường Vĩnh Phúc",
        "path": "Vĩnh Phúc, Ba Đình, Hà Nội",
        "path_with_type": "Phường Vĩnh Phúc, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00006",
        "parent_code": "001"
    },
    "00007": {
        "name": "Cống Vị",
        "type": "phuong",
        "slug": "cong-vi",
        "name_with_type": "Phường Cống Vị",
        "path": "Cống Vị, Ba Đình, Hà Nội",
        "path_with_type": "Phường Cống Vị, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00007",
        "parent_code": "001"
    },
    "00008": {
        "name": "Liễu Giai",
        "type": "phuong",
        "slug": "lieu-giai",
        "name_with_type": "Phường Liễu Giai",
        "path": "Liễu Giai, Ba Đình, Hà Nội",
        "path_with_type": "Phường Liễu Giai, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00008",
        "parent_code": "001"
    },
    "00010": {
        "name": "Nguyễn Trung Trực",
        "type": "phuong",
        "slug": "nguyen-trung-truc",
        "name_with_type": "Phường Nguyễn Trung Trực",
        "path": "Nguyễn Trung Trực, Ba Đình, Hà Nội",
        "path_with_type": "Phường Nguyễn Trung Trực, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00010",
        "parent_code": "001"
    },
    "00013": {
        "name": "Quán Thánh",
        "type": "phuong",
        "slug": "quan-thanh",
        "name_with_type": "Phường Quán Thánh",
        "path": "Quán Thánh, Ba Đình, Hà Nội",
        "path_with_type": "Phường Quán Thánh, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00013",
        "parent_code": "001"
    },
    "00016": {
        "name": "Ngọc Hà",
        "type": "phuong",
        "slug": "ngoc-ha",
        "name_with_type": "Phường Ngọc Hà",
        "path": "Ngọc Hà, Ba Đình, Hà Nội",
        "path_with_type": "Phường Ngọc Hà, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00016",
        "parent_code": "001"
    },
    "00019": {
        "name": "Điện Biên",
        "type": "phuong",
        "slug": "dien-bien",
        "name_with_type": "Phường Điện Biên",
        "path": "Điện Biên, Ba Đình, Hà Nội",
        "path_with_type": "Phường Điện Biên, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00019",
        "parent_code": "001"
    },
    "00022": {
        "name": "Đội Cấn",
        "type": "phuong",
        "slug": "doi-can",
        "name_with_type": "Phường Đội Cấn",
        "path": "Đội Cấn, Ba Đình, Hà Nội",
        "path_with_type": "Phường Đội Cấn, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00022",
        "parent_code": "001"
    },
    "00025": {
        "name": "Ngọc Khánh",
        "type": "phuong",
        "slug": "ngoc-khanh",
        "name_with_type": "Phường Ngọc Khánh",
        "path": "Ngọc Khánh, Ba Đình, Hà Nội",
        "path_with_type": "Phường Ngọc Khánh, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00025",
        "parent_code": "001"
    },
    "00028": {
        "name": "Kim Mã",
        "type": "phuong",
        "slug": "kim-ma",
        "name_with_type": "Phường Kim Mã",
        "path": "Kim Mã, Ba Đình, Hà Nội",
        "path_with_type": "Phường Kim Mã, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00028",
        "parent_code": "001"
    },
    "00031": {
        "name": "Giảng Võ",
        "type": "phuong",
        "slug": "giang-vo",
        "name_with_type": "Phường Giảng Võ",
        "path": "Giảng Võ, Ba Đình, Hà Nội",
        "path_with_type": "Phường Giảng Võ, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00031",
        "parent_code": "001"
    },
    "00034": {
        "name": "Thành Công",
        "type": "phuong",
        "slug": "thanh-cong",
        "name_with_type": "Phường Thành Công",
        "path": "Thành Công, Ba Đình, Hà Nội",
        "path_with_type": "Phường Thành Công, Quận Ba Đình, Thành phố Hà Nội",
        "code": "00034",
        "parent_code": "001"
    },
    "00037": {
        "name": "Phúc Tân",
        "type": "phuong",
        "slug": "phuc-tan",
        "name_with_type": "Phường Phúc Tân",
        "path": "Phúc Tân, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Phúc Tân, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00037",
        "parent_code": "002"
    },
    "00040": {
        "name": "Đồng Xuân",
        "type": "phuong",
        "slug": "dong-xuan",
        "name_with_type": "Phường Đồng Xuân",
        "path": "Đồng Xuân, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Đồng Xuân, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00040",
        "parent_code": "002"
    },
    "00043": {
        "name": "Hàng Mã",
        "type": "phuong",
        "slug": "hang-ma",
        "name_with_type": "Phường Hàng Mã",
        "path": "Hàng Mã, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Hàng Mã, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00043",
        "parent_code": "002"
    },
    "00046": {
        "name": "Hàng Buồm",
        "type": "phuong",
        "slug": "hang-buom",
        "name_with_type": "Phường Hàng Buồm",
        "path": "Hàng Buồm, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Hàng Buồm, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00046",
        "parent_code": "002"
    },
    "00049": {
        "name": "Hàng Đào",
        "type": "phuong",
        "slug": "hang-dao",
        "name_with_type": "Phường Hàng Đào",
        "path": "Hàng Đào, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Hàng Đào, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00049",
        "parent_code": "002"
    },
    "00052": {
        "name": "Hàng Bồ",
        "type": "phuong",
        "slug": "hang-bo",
        "name_with_type": "Phường Hàng Bồ",
        "path": "Hàng Bồ, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Hàng Bồ, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00052",
        "parent_code": "002"
    },
    "00055": {
        "name": "Cửa Đông",
        "type": "phuong",
        "slug": "cua-dong",
        "name_with_type": "Phường Cửa Đông",
        "path": "Cửa Đông, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Cửa Đông, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00055",
        "parent_code": "002"
    },
    "00058": {
        "name": "Lý Thái Tổ",
        "type": "phuong",
        "slug": "ly-thai-to",
        "name_with_type": "Phường Lý Thái Tổ",
        "path": "Lý Thái Tổ, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Lý Thái Tổ, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00058",
        "parent_code": "002"
    },
    "00061": {
        "name": "Hàng Bạc",
        "type": "phuong",
        "slug": "hang-bac",
        "name_with_type": "Phường Hàng Bạc",
        "path": "Hàng Bạc, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Hàng Bạc, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00061",
        "parent_code": "002"
    },
    "00064": {
        "name": "Hàng Gai",
        "type": "phuong",
        "slug": "hang-gai",
        "name_with_type": "Phường Hàng Gai",
        "path": "Hàng Gai, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Hàng Gai, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00064",
        "parent_code": "002"
    },
    "00067": {
        "name": "Chương Dương ��ộ",
        "type": "phuong",
        "slug": "chuong-duong-do",
        "name_with_type": "Phường Chương Dương Độ",
        "path": "Chương Dương Độ, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Chương Dương Độ, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00067",
        "parent_code": "002"
    },
    "00070": {
        "name": "Hàng Trống",
        "type": "phuong",
        "slug": "hang-trong",
        "name_with_type": "Phường Hàng Trống",
        "path": "Hàng Trống, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Hàng Trống, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00070",
        "parent_code": "002"
    },
    "00073": {
        "name": "Cửa Nam",
        "type": "phuong",
        "slug": "cua-nam",
        "name_with_type": "Phường Cửa Nam",
        "path": "Cửa Nam, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Cửa Nam, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00073",
        "parent_code": "002"
    },
    "00076": {
        "name": "Hàng Bông",
        "type": "phuong",
        "slug": "hang-bong",
        "name_with_type": "Phường Hàng Bông",
        "path": "Hàng Bông, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Hàng Bông, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00076",
        "parent_code": "002"
    },
    "00079": {
        "name": "Tràng Tiền",
        "type": "phuong",
        "slug": "trang-tien",
        "name_with_type": "Phường Tràng Tiền",
        "path": "Tràng Tiền, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Tràng Tiền, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00079",
        "parent_code": "002"
    },
    "00082": {
        "name": "Trần Hưng Đạo",
        "type": "phuong",
        "slug": "tran-hung-dao",
        "name_with_type": "Phường Trần Hưng Đạo",
        "path": "Trần Hưng Đạo, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Trần Hưng Đạo, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00082",
        "parent_code": "002"
    },
    "00085": {
        "name": "Phan Chu Trinh",
        "type": "phuong",
        "slug": "phan-chu-trinh",
        "name_with_type": "Phường Phan Chu Trinh",
        "path": "Phan Chu Trinh, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Phan Chu Trinh, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00085",
        "parent_code": "002"
    },
    "00088": {
        "name": "Hàng Bài",
        "type": "phuong",
        "slug": "hang-bai",
        "name_with_type": "Phường Hàng Bài",
        "path": "Hàng Bài, Hoàn Kiếm, Hà Nội",
        "path_with_type": "Phường Hàng Bài, Quận Hoàn Kiếm, Thành phố Hà Nội",
        "code": "00088",
        "parent_code": "002"
    },
    "00091": {
        "name": "Phú Thượng",
        "type": "phuong",
        "slug": "phu-thuong",
        "name_with_type": "Phường Phú Thượng",
        "path": "Phú Thượng, Tây Hồ, Hà Nội",
        "path_with_type": "Phường Phú Thượng, Quận Tây Hồ, Thành phố Hà Nội",
        "code": "00091",
        "parent_code": "003"
    },
    "00094": {
        "name": "Nhật Tân",
        "type": "phuong",
        "slug": "nhat-tan",
        "name_with_type": "Phường Nhật Tân",
        "path": "Nhật Tân, Tây Hồ, Hà Nội",
        "path_with_type": "Phường Nhật Tân, Quận Tây Hồ, Thành phố Hà Nội",
        "code": "00094",
        "parent_code": "003"
    },
    "00097": {
        "name": "Tứ Liên",
        "type": "phuong",
        "slug": "tu-lien",
        "name_with_type": "Phường Tứ Liên",
        "path": "Tứ Liên, Tây Hồ, Hà Nội",
        "path_with_type": "Phường Tứ Liên, Quận Tây Hồ, Thành phố Hà Nội",
        "code": "00097",
        "parent_code": "003"
    },
    "00100": {
        "name": "Quảng An",
        "type": "phuong",
        "slug": "quang-an",
        "name_with_type": "Phường Quảng An",
        "path": "Quảng An, Tây Hồ, Hà Nội",
        "path_with_type": "Phường Quảng An, Quận Tây Hồ, Thành phố Hà Nội",
        "code": "00100",
        "parent_code": "003"
    },
    "00103": {
        "name": "Xuân La",
        "type": "phuong",
        "slug": "xuan-la",
        "name_with_type": "Phường Xuân La",
        "path": "Xuân La, Tây Hồ, Hà Nội",
        "path_with_type": "Phường Xuân La, Quận Tây Hồ, Thành phố Hà Nội",
        "code": "00103",
        "parent_code": "003"
    },
    "00106": {
        "name": "Yên Phụ",
        "type": "phuong",
        "slug": "yen-phu",
        "name_with_type": "Phường Yên Phụ",
        "path": "Yên Phụ, Tây Hồ, Hà Nội",
        "path_with_type": "Phường Yên Phụ, Quận Tây Hồ, Thành phố Hà Nội",
        "code": "00106",
        "parent_code": "003"
    },
    "00109": {
        "name": "Bưởi",
        "type": "phuong",
        "slug": "buoi",
        "name_with_type": "Phường Bưởi",
        "path": "Bưởi, Tây Hồ, Hà Nội",
        "path_with_type": "Phường Bưởi, Quận Tây Hồ, Thành phố Hà Nội",
        "code": "00109",
        "parent_code": "003"
    },
    "00112": {
        "name": "Thụy Khuê",
        "type": "phuong",
        "slug": "thuy-khue",
        "name_with_type": "Phường Thụy Khuê",
        "path": "Thụy Khuê, Tây Hồ, Hà Nội",
        "path_with_type": "Phường Thụy Khuê, Quận Tây Hồ, Thành phố Hà Nội",
        "code": "00112",
        "parent_code": "003"
    },
    "00115": {
        "name": "Thượng Thanh",
        "type": "phuong",
        "slug": "thuong-thanh",
        "name_with_type": "Phường Thượng Thanh",
        "path": "Thượng Thanh, Long Biên, Hà Nội",
        "path_with_type": "Phường Thượng Thanh, Quận Long Biên, Thành phố Hà Nội",
        "code": "00115",
        "parent_code": "004"
    },
    "00118": {
        "name": "Ngọc Thụy",
        "type": "phuong",
        "slug": "ngoc-thuy",
        "name_with_type": "Phường Ngọc Thụy",
        "path": "Ngọc Thụy, Long Biên, Hà Nội",
        "path_with_type": "Phường Ngọc Thụy, Quận Long Biên, Thành phố Hà Nội",
        "code": "00118",
        "parent_code": "004"
    },
    "00121": {
        "name": "Giang Biên",
        "type": "phuong",
        "slug": "giang-bien",
        "name_with_type": "Phường Giang Biên",
        "path": "Giang Biên, Long Biên, Hà Nội",
        "path_with_type": "Phường Giang Biên, Quận Long Biên, Thành phố Hà Nội",
        "code": "00121",
        "parent_code": "004"
    },
    "00124": {
        "name": "Đức Giang",
        "type": "phuong",
        "slug": "duc-giang",
        "name_with_type": "Phường Đức Giang",
        "path": "Đức Giang, Long Biên, Hà Nội",
        "path_with_type": "Phường Đức Giang, Quận Long Biên, Thành phố Hà Nội",
        "code": "00124",
        "parent_code": "004"
    },
    "00127": {
        "name": "Việt Hưng",
        "type": "phuong",
        "slug": "viet-hung",
        "name_with_type": "Phường Việt Hưng",
        "path": "Việt Hưng, Long Biên, Hà Nội",
        "path_with_type": "Phường Việt Hưng, Quận Long Biên, Thành phố Hà Nội",
        "code": "00127",
        "parent_code": "004"
    },
    "00130": {
        "name": "Gia Thụy",
        "type": "phuong",
        "slug": "gia-thuy",
        "name_with_type": "Phường Gia Thụy",
        "path": "Gia Thụy, Long Biên, Hà Nội",
        "path_with_type": "Phường Gia Thụy, Quận Long Biên, Thành phố Hà Nội",
        "code": "00130",
        "parent_code": "004"
    },
    "00133": {
        "name": "Ngọc Lâm",
        "type": "phuong",
        "slug": "ngoc-lam",
        "name_with_type": "Phường Ngọc Lâm",
        "path": "Ngọc Lâm, Long Biên, Hà Nội",
        "path_with_type": "Phường Ngọc Lâm, Quận Long Biên, Thành phố Hà Nội",
        "code": "00133",
        "parent_code": "004"
    },
    "00136": {
        "name": "Phúc Lợi",
        "type": "phuong",
        "slug": "phuc-loi",
        "name_with_type": "Phường Phúc Lợi",
        "path": "Phúc Lợi, Long Biên, Hà Nội",
        "path_with_type": "Phường Phúc Lợi, Quận Long Biên, Thành phố Hà Nội",
        "code": "00136",
        "parent_code": "004"
    },
    "00139": {
        "name": "Bồ Đề",
        "type": "phuong",
        "slug": "bo-de",
        "name_with_type": "Phường Bồ Đề",
        "path": "Bồ Đề, Long Biên, Hà Nội",
        "path_with_type": "Phường Bồ Đề, Quận Long Biên, Thành phố Hà Nội",
        "code": "00139",
        "parent_code": "004"
    },
    "00142": {
        "name": "Sài Đồng",
        "type": "phuong",
        "slug": "sai-dong",
        "name_with_type": "Phường Sài Đồng",
        "path": "Sài Đồng, Long Biên, Hà Nội",
        "path_with_type": "Phường Sài Đồng, Quận Long Biên, Thành phố Hà Nội",
        "code": "00142",
        "parent_code": "004"
    },
    "00145": {
        "name": "Long Biên",
        "type": "phuong",
        "slug": "long-bien",
        "name_with_type": "Phường Long Biên",
        "path": "Long Biên, Long Biên, Hà Nội",
        "path_with_type": "Phường Long Biên, Quận Long Biên, Thành phố Hà Nội",
        "code": "00145",
        "parent_code": "004"
    },
    "00148": {
        "name": "Thạch Bàn",
        "type": "phuong",
        "slug": "thach-ban",
        "name_with_type": "Phường Thạch Bàn",
        "path": "Thạch Bàn, Long Biên, Hà Nội",
        "path_with_type": "Phường Thạch Bàn, Quận Long Biên, Thành phố Hà Nội",
        "code": "00148",
        "parent_code": "004"
    },
    "00151": {
        "name": "Phúc Đồng",
        "type": "phuong",
        "slug": "phuc-dong",
        "name_with_type": "Phường Phúc Đồng",
        "path": "Phúc Đồng, Long Biên, Hà Nội",
        "path_with_type": "Phường Phúc Đồng, Quận Long Biên, Thành phố Hà Nội",
        "code": "00151",
        "parent_code": "004"
    },
    "00154": {
        "name": "Cự Khối",
        "type": "phuong",
        "slug": "cu-khoi",
        "name_with_type": "Phường Cự Khối",
        "path": "Cự Khối, Long Biên, Hà Nội",
        "path_with_type": "Phường Cự Khối, Quận Long Biên, Thành phố Hà Nội",
        "code": "00154",
        "parent_code": "004"
    },
    "00157": {
        "name": "Nghĩa Đô",
        "type": "phuong",
        "slug": "nghia-do",
        "name_with_type": "Phường Nghĩa Đô",
        "path": "Nghĩa Đô, Cầu Giấy, Hà Nội",
        "path_with_type": "Phường Nghĩa Đô, Quận Cầu Giấy, Thành phố Hà Nội",
        "code": "00157",
        "parent_code": "005"
    },
    "00160": {
        "name": "Nghĩa Tân",
        "type": "phuong",
        "slug": "nghia-tan",
        "name_with_type": "Phường Nghĩa Tân",
        "path": "Nghĩa Tân, Cầu Giấy, Hà Nội",
        "path_with_type": "Phường Nghĩa Tân, Quận Cầu Giấy, Thành phố Hà Nội",
        "code": "00160",
        "parent_code": "005"
    },
    "00163": {
        "name": "Mai Dịch",
        "type": "phuong",
        "slug": "mai-dich",
        "name_with_type": "Phường Mai Dịch",
        "path": "Mai Dịch, Cầu Giấy, Hà Nội",
        "path_with_type": "Phường Mai Dịch, Quận Cầu Giấy, Thành phố Hà Nội",
        "code": "00163",
        "parent_code": "005"
    },
    "00166": {
        "name": "Dịch Vọng",
        "type": "phuong",
        "slug": "dich-vong",
        "name_with_type": "Phường Dịch Vọng",
        "path": "Dịch Vọng, Cầu Giấy, Hà Nội",
        "path_with_type": "Phường Dịch Vọng, Quận Cầu Giấy, Thành phố Hà Nội",
        "code": "00166",
        "parent_code": "005"
    },
    "00167": {
        "name": "Dịch Vọng Hậu",
        "type": "phuong",
        "slug": "dich-vong-hau",
        "name_with_type": "Phường Dịch Vọng Hậu",
        "path": "Dịch Vọng Hậu, Cầu Giấy, Hà Nội",
        "path_with_type": "Phường Dịch Vọng Hậu, Quận Cầu Giấy, Thành phố Hà Nội",
        "code": "00167",
        "parent_code": "005"
    },
    "00169": {
        "name": "Quan Hoa",
        "type": "phuong",
        "slug": "quan-hoa",
        "name_with_type": "Phường Quan Hoa",
        "path": "Quan Hoa, Cầu Giấy, Hà Nội",
        "path_with_type": "Phường Quan Hoa, Quận Cầu Giấy, Thành phố Hà Nội",
        "code": "00169",
        "parent_code": "005"
    },
    "00172": {
        "name": "Yên Hoà",
        "type": "phuong",
        "slug": "yen-hoa",
        "name_with_type": "Phường Yên Hoà",
        "path": "Yên Hoà, Cầu Giấy, Hà Nội",
        "path_with_type": "Phường Yên Hoà, Quận Cầu Giấy, Thành phố Hà Nội",
        "code": "00172",
        "parent_code": "005"
    },
    "00175": {
        "name": "Trung Hoà",
        "type": "phuong",
        "slug": "trung-hoa",
        "name_with_type": "Phường Trung Hoà",
        "path": "Trung Hoà, Cầu Giấy, Hà Nội",
        "path_with_type": "Phường Trung Hoà, Quận Cầu Giấy, Thành phố Hà Nội",
        "code": "00175",
        "parent_code": "005"
    },
    "00178": {
        "name": "Cát Linh",
        "type": "phuong",
        "slug": "cat-linh",
        "name_with_type": "Phường Cát Linh",
        "path": "Cát Linh, Đống Đa, Hà Nội",
        "path_with_type": "Phường Cát Linh, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00178",
        "parent_code": "006"
    },
    "00181": {
        "name": "Văn Miếu",
        "type": "phuong",
        "slug": "van-mieu",
        "name_with_type": "Phường Văn Miếu",
        "path": "Văn Miếu, Đống Đa, Hà Nội",
        "path_with_type": "Phường Văn Miếu, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00181",
        "parent_code": "006"
    },
    "00184": {
        "name": "Quốc Tử Giám",
        "type": "phuong",
        "slug": "quoc-tu-giam",
        "name_with_type": "Phường Quốc Tử Giám",
        "path": "Quốc Tử Giám, Đống Đa, Hà Nội",
        "path_with_type": "Phường Quốc Tử Giám, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00184",
        "parent_code": "006"
    },
    "00187": {
        "name": "Láng Thượng",
        "type": "phuong",
        "slug": "lang-thuong",
        "name_with_type": "Phường Láng Thượng",
        "path": "Láng Thượng, Đống Đa, Hà Nội",
        "path_with_type": "Phường Láng Thượng, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00187",
        "parent_code": "006"
    },
    "00190": {
        "name": "Ô Chợ Dừa",
        "type": "phuong",
        "slug": "o-cho-dua",
        "name_with_type": "Phường Ô Chợ Dừa",
        "path": "Ô Chợ Dừa, Đống Đa, Hà Nội",
        "path_with_type": "Phường Ô Chợ Dừa, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00190",
        "parent_code": "006"
    },
    "00193": {
        "name": "Văn Chương",
        "type": "phuong",
        "slug": "van-chuong",
        "name_with_type": "Phường Văn Chương",
        "path": "Văn Chương, Đống Đa, Hà Nội",
        "path_with_type": "Phường Văn Chương, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00193",
        "parent_code": "006"
    },
    "00196": {
        "name": "Hàng Bột",
        "type": "phuong",
        "slug": "hang-bot",
        "name_with_type": "Phường Hàng Bột",
        "path": "Hàng Bột, Đống Đa, Hà Nội",
        "path_with_type": "Phường Hàng Bột, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00196",
        "parent_code": "006"
    },
    "00199": {
        "name": "Láng Hạ",
        "type": "phuong",
        "slug": "lang-ha",
        "name_with_type": "Phường Láng Hạ",
        "path": "Láng Hạ, Đống Đa, Hà Nội",
        "path_with_type": "Phường Láng Hạ, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00199",
        "parent_code": "006"
    },
    "00202": {
        "name": "Khâm Thiên",
        "type": "phuong",
        "slug": "kham-thien",
        "name_with_type": "Phường Khâm Thiên",
        "path": "Khâm Thiên, Đống Đa, Hà Nội",
        "path_with_type": "Phường Khâm Thiên, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00202",
        "parent_code": "006"
    },
    "00205": {
        "name": "Thổ Quan",
        "type": "phuong",
        "slug": "tho-quan",
        "name_with_type": "Phường Thổ Quan",
        "path": "Thổ Quan, Đống Đa, Hà Nội",
        "path_with_type": "Phường Thổ Quan, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00205",
        "parent_code": "006"
    },
    "00208": {
        "name": "Nam Đồng",
        "type": "phuong",
        "slug": "nam-dong",
        "name_with_type": "Phường Nam Đồng",
        "path": "Nam Đồng, Đống Đa, Hà Nội",
        "path_with_type": "Phường Nam Đồng, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00208",
        "parent_code": "006"
    },
    "00211": {
        "name": "Trung Phụng",
        "type": "phuong",
        "slug": "trung-phung",
        "name_with_type": "Phường Trung Phụng",
        "path": "Trung Phụng, Đống Đa, Hà Nội",
        "path_with_type": "Phường Trung Phụng, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00211",
        "parent_code": "006"
    },
    "00214": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Đống Đa, Hà Nội",
        "path_with_type": "Phường Quang Trung, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00214",
        "parent_code": "006"
    },
    "00217": {
        "name": "Trung Liệt",
        "type": "phuong",
        "slug": "trung-liet",
        "name_with_type": "Phường Trung Liệt",
        "path": "Trung Liệt, Đống Đa, Hà Nội",
        "path_with_type": "Phường Trung Liệt, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00217",
        "parent_code": "006"
    },
    "00220": {
        "name": "Phương Liên",
        "type": "phuong",
        "slug": "phuong-lien",
        "name_with_type": "Phường Phương Liên",
        "path": "Phương Liên, Đống Đa, Hà Nội",
        "path_with_type": "Phường Phương Liên, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00220",
        "parent_code": "006"
    },
    "00223": {
        "name": "Thịnh Quang",
        "type": "phuong",
        "slug": "thinh-quang",
        "name_with_type": "Phường Thịnh Quang",
        "path": "Thịnh Quang, Đống Đa, Hà Nội",
        "path_with_type": "Phường Thịnh Quang, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00223",
        "parent_code": "006"
    },
    "00226": {
        "name": "Trung Tự",
        "type": "phuong",
        "slug": "trung-tu",
        "name_with_type": "Phường Trung Tự",
        "path": "Trung Tự, Đống Đa, Hà Nội",
        "path_with_type": "Phường Trung Tự, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00226",
        "parent_code": "006"
    },
    "00229": {
        "name": "Kim Liên",
        "type": "phuong",
        "slug": "kim-lien",
        "name_with_type": "Phường Kim Liên",
        "path": "Kim Liên, Đống Đa, Hà Nội",
        "path_with_type": "Phường Kim Liên, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00229",
        "parent_code": "006"
    },
    "00232": {
        "name": "Phương Mai",
        "type": "phuong",
        "slug": "phuong-mai",
        "name_with_type": "Phường Phương Mai",
        "path": "Phương Mai, Đống Đa, Hà Nội",
        "path_with_type": "Phường Phương Mai, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00232",
        "parent_code": "006"
    },
    "00235": {
        "name": "Ngã Tư Sở",
        "type": "phuong",
        "slug": "nga-tu-so",
        "name_with_type": "Phường Ngã Tư Sở",
        "path": "Ngã Tư Sở, Đống Đa, Hà Nội",
        "path_with_type": "Phường Ngã Tư Sở, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00235",
        "parent_code": "006"
    },
    "00238": {
        "name": "Khương Thượng",
        "type": "phuong",
        "slug": "khuong-thuong",
        "name_with_type": "Phường Khương Thượng",
        "path": "Khương Thượng, Đống Đa, Hà Nội",
        "path_with_type": "Phường Khương Thượng, Quận Đống Đa, Thành phố Hà Nội",
        "code": "00238",
        "parent_code": "006"
    },
    "00241": {
        "name": "Nguyễn Du",
        "type": "phuong",
        "slug": "nguyen-du",
        "name_with_type": "Phường Nguyễn Du",
        "path": "Nguyễn Du, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Nguyễn Du, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00241",
        "parent_code": "007"
    },
    "00244": {
        "name": "Bạch Đằng",
        "type": "phuong",
        "slug": "bach-dang",
        "name_with_type": "Phường Bạch Đằng",
        "path": "Bạch Đằng, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Bạch Đằng, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00244",
        "parent_code": "007"
    },
    "00247": {
        "name": "Phạm Đình Hổ",
        "type": "phuong",
        "slug": "pham-dinh-ho",
        "name_with_type": "Phường Phạm Đình Hổ",
        "path": "Phạm Đình Hổ, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Phạm Đình Hổ, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00247",
        "parent_code": "007"
    },
    "00250": {
        "name": "Bùi Thị Xuân",
        "type": "phuong",
        "slug": "bui-thi-xuan",
        "name_with_type": "Phường Bùi Thị Xuân",
        "path": "Bùi Thị Xuân, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Bùi Thị Xuân, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00250",
        "parent_code": "007"
    },
    "00253": {
        "name": "Ngô Thì Nhậm",
        "type": "phuong",
        "slug": "ngo-thi-nham",
        "name_with_type": "Phường Ngô Thì Nhậm",
        "path": "Ngô Thì Nhậm, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Ngô Thì Nhậm, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00253",
        "parent_code": "007"
    },
    "00256": {
        "name": "Lê Đại Hành",
        "type": "phuong",
        "slug": "le-dai-hanh",
        "name_with_type": "Phường Lê Đại Hành",
        "path": "Lê Đại Hành, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Lê Đại Hành, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00256",
        "parent_code": "007"
    },
    "00259": {
        "name": "Đồng Nhân",
        "type": "phuong",
        "slug": "dong-nhan",
        "name_with_type": "Phường Đồng Nhân",
        "path": "Đồng Nhân, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Đồng Nhân, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00259",
        "parent_code": "007"
    },
    "00262": {
        "name": "Phố Huế",
        "type": "phuong",
        "slug": "pho-hue",
        "name_with_type": "Phường Phố Huế",
        "path": "Phố Huế, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Phố Huế, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00262",
        "parent_code": "007"
    },
    "00265": {
        "name": "Đống Mác",
        "type": "phuong",
        "slug": "dong-mac",
        "name_with_type": "Phường Đống Mác",
        "path": "Đống Mác, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Đống Mác, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00265",
        "parent_code": "007"
    },
    "00268": {
        "name": "Thanh Lương",
        "type": "phuong",
        "slug": "thanh-luong",
        "name_with_type": "Phường Thanh Lương",
        "path": "Thanh Lương, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Thanh Lương, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00268",
        "parent_code": "007"
    },
    "00271": {
        "name": "Thanh Nhàn",
        "type": "phuong",
        "slug": "thanh-nhan",
        "name_with_type": "Phường Thanh Nhàn",
        "path": "Thanh Nhàn, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Thanh Nhàn, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00271",
        "parent_code": "007"
    },
    "00274": {
        "name": "Cầu Dền",
        "type": "phuong",
        "slug": "cau-den",
        "name_with_type": "Phường Cầu Dền",
        "path": "Cầu Dền, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Cầu Dền, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00274",
        "parent_code": "007"
    },
    "00277": {
        "name": "Bách Khoa",
        "type": "phuong",
        "slug": "bach-khoa",
        "name_with_type": "Phường Bách Khoa",
        "path": "Bách Khoa, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Bách Khoa, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00277",
        "parent_code": "007"
    },
    "00280": {
        "name": "Đồng Tâm",
        "type": "phuong",
        "slug": "dong-tam",
        "name_with_type": "Phường Đồng Tâm",
        "path": "Đồng Tâm, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Đồng Tâm, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00280",
        "parent_code": "007"
    },
    "00283": {
        "name": "Vĩnh Tuy",
        "type": "phuong",
        "slug": "vinh-tuy",
        "name_with_type": "Phường Vĩnh Tuy",
        "path": "Vĩnh Tuy, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Vĩnh Tuy, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00283",
        "parent_code": "007"
    },
    "00286": {
        "name": "Bạch Mai",
        "type": "phuong",
        "slug": "bach-mai",
        "name_with_type": "Phường Bạch Mai",
        "path": "Bạch Mai, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Bạch Mai, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00286",
        "parent_code": "007"
    },
    "00289": {
        "name": "Quỳnh Mai",
        "type": "phuong",
        "slug": "quynh-mai",
        "name_with_type": "Phường Quỳnh Mai",
        "path": "Quỳnh Mai, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Quỳnh Mai, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00289",
        "parent_code": "007"
    },
    "00292": {
        "name": "Quỳnh Lôi",
        "type": "phuong",
        "slug": "quynh-loi",
        "name_with_type": "Phường Quỳnh Lôi",
        "path": "Quỳnh Lôi, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Quỳnh Lôi, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00292",
        "parent_code": "007"
    },
    "00295": {
        "name": "Minh Khai",
        "type": "phuong",
        "slug": "minh-khai",
        "name_with_type": "Phường Minh Khai",
        "path": "Minh Khai, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Minh Khai, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00295",
        "parent_code": "007"
    },
    "00298": {
        "name": "Trương Định",
        "type": "phuong",
        "slug": "truong-dinh",
        "name_with_type": "Phường Trương Định",
        "path": "Trương Định, Hai Bà Trưng, Hà Nội",
        "path_with_type": "Phường Trương Định, Quận Hai Bà Trưng, Thành phố Hà Nội",
        "code": "00298",
        "parent_code": "007"
    },
    "00301": {
        "name": "Thanh Trì",
        "type": "phuong",
        "slug": "thanh-tri",
        "name_with_type": "Phường Thanh Trì",
        "path": "Thanh Trì, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Thanh Trì, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00301",
        "parent_code": "008"
    },
    "00304": {
        "name": "Vĩnh Hưng",
        "type": "phuong",
        "slug": "vinh-hung",
        "name_with_type": "Phường Vĩnh Hưng",
        "path": "Vĩnh Hưng, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Vĩnh Hưng, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00304",
        "parent_code": "008"
    },
    "00307": {
        "name": "Định Công",
        "type": "phuong",
        "slug": "dinh-cong",
        "name_with_type": "Phường Định Công",
        "path": "Định Công, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Định Công, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00307",
        "parent_code": "008"
    },
    "00310": {
        "name": "Mai Động",
        "type": "phuong",
        "slug": "mai-dong",
        "name_with_type": "Phường Mai Động",
        "path": "Mai Động, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Mai Động, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00310",
        "parent_code": "008"
    },
    "00313": {
        "name": "Tương Mai",
        "type": "phuong",
        "slug": "tuong-mai",
        "name_with_type": "Phường Tương Mai",
        "path": "Tương Mai, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Tương Mai, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00313",
        "parent_code": "008"
    },
    "00316": {
        "name": "Đại Kim",
        "type": "phuong",
        "slug": "dai-kim",
        "name_with_type": "Phường Đại Kim",
        "path": "Đại Kim, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Đại Kim, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00316",
        "parent_code": "008"
    },
    "00319": {
        "name": "Tân Mai",
        "type": "phuong",
        "slug": "tan-mai",
        "name_with_type": "Phường Tân Mai",
        "path": "Tân Mai, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Tân Mai, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00319",
        "parent_code": "008"
    },
    "00322": {
        "name": "Hoàng Văn Thụ",
        "type": "phuong",
        "slug": "hoang-van-thu",
        "name_with_type": "Phường Hoàng Văn Thụ",
        "path": "Hoàng Văn Thụ, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Hoàng Văn Thụ, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00322",
        "parent_code": "008"
    },
    "00325": {
        "name": "Giáp Bát",
        "type": "phuong",
        "slug": "giap-bat",
        "name_with_type": "Phường Giáp Bát",
        "path": "Giáp Bát, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Giáp Bát, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00325",
        "parent_code": "008"
    },
    "00328": {
        "name": "Lĩnh Nam",
        "type": "phuong",
        "slug": "linh-nam",
        "name_with_type": "Phường Lĩnh Nam",
        "path": "Lĩnh Nam, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Lĩnh Nam, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00328",
        "parent_code": "008"
    },
    "00331": {
        "name": "Thịnh Liệt",
        "type": "phuong",
        "slug": "thinh-liet",
        "name_with_type": "Phường Thịnh Liệt",
        "path": "Thịnh Liệt, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Thịnh Liệt, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00331",
        "parent_code": "008"
    },
    "00334": {
        "name": "Trần Phú",
        "type": "phuong",
        "slug": "tran-phu",
        "name_with_type": "Phường Trần Phú",
        "path": "Trần Phú, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Trần Phú, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00334",
        "parent_code": "008"
    },
    "00337": {
        "name": "Hoàng Liệt",
        "type": "phuong",
        "slug": "hoang-liet",
        "name_with_type": "Phường Hoàng Liệt",
        "path": "Hoàng Liệt, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Hoàng Liệt, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00337",
        "parent_code": "008"
    },
    "00340": {
        "name": "Yên Sở",
        "type": "phuong",
        "slug": "yen-so",
        "name_with_type": "Phường Yên Sở",
        "path": "Yên Sở, Hoàng Mai, Hà Nội",
        "path_with_type": "Phường Yên Sở, Quận Hoàng Mai, Thành phố Hà Nội",
        "code": "00340",
        "parent_code": "008"
    },
    "00343": {
        "name": "Nhân Chính",
        "type": "phuong",
        "slug": "nhan-chinh",
        "name_with_type": "Phường Nhân Chính",
        "path": "Nhân Chính, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Nhân Chính, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00343",
        "parent_code": "009"
    },
    "00346": {
        "name": "Thượng Đình",
        "type": "phuong",
        "slug": "thuong-dinh",
        "name_with_type": "Phường Thượng Đình",
        "path": "Thượng Đình, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Thượng Đình, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00346",
        "parent_code": "009"
    },
    "00349": {
        "name": "Khương Trung",
        "type": "phuong",
        "slug": "khuong-trung",
        "name_with_type": "Phường Khương Trung",
        "path": "Khương Trung, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Khương Trung, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00349",
        "parent_code": "009"
    },
    "00352": {
        "name": "Khương Mai",
        "type": "phuong",
        "slug": "khuong-mai",
        "name_with_type": "Phường Khương Mai",
        "path": "Khương Mai, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Khương Mai, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00352",
        "parent_code": "009"
    },
    "00355": {
        "name": "Thanh Xuân Trung",
        "type": "phuong",
        "slug": "thanh-xuan-trung",
        "name_with_type": "Phường Thanh Xuân Trung",
        "path": "Thanh Xuân Trung, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Thanh Xuân Trung, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00355",
        "parent_code": "009"
    },
    "00358": {
        "name": "Phương Liệt",
        "type": "phuong",
        "slug": "phuong-liet",
        "name_with_type": "Phường Phương Liệt",
        "path": "Phương Liệt, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Phương Liệt, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00358",
        "parent_code": "009"
    },
    "00361": {
        "name": "Hạ Đình",
        "type": "phuong",
        "slug": "ha-dinh",
        "name_with_type": "Phường Hạ Đình",
        "path": "Hạ Đình, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Hạ Đình, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00361",
        "parent_code": "009"
    },
    "00364": {
        "name": "Khương Đình",
        "type": "phuong",
        "slug": "khuong-dinh",
        "name_with_type": "Phường Khương Đình",
        "path": "Khương Đình, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Khương Đình, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00364",
        "parent_code": "009"
    },
    "00367": {
        "name": "Thanh Xuân Bắc",
        "type": "phuong",
        "slug": "thanh-xuan-bac",
        "name_with_type": "Phường Thanh Xuân Bắc",
        "path": "Thanh Xuân Bắc, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Thanh Xuân Bắc, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00367",
        "parent_code": "009"
    },
    "00370": {
        "name": "Thanh Xuân Nam",
        "type": "phuong",
        "slug": "thanh-xuan-nam",
        "name_with_type": "Phường Thanh Xuân Nam",
        "path": "Thanh Xuân Nam, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Thanh Xuân Nam, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00370",
        "parent_code": "009"
    },
    "00373": {
        "name": "Kim Giang",
        "type": "phuong",
        "slug": "kim-giang",
        "name_with_type": "Phường Kim Giang",
        "path": "Kim Giang, Thanh Xuân, Hà Nội",
        "path_with_type": "Phường Kim Giang, Quận Thanh Xuân, Thành phố Hà Nội",
        "code": "00373",
        "parent_code": "009"
    },
    "00376": {
        "name": "Sóc Sơn",
        "type": "thi-tran",
        "slug": "soc-son",
        "name_with_type": "Thị trấn Sóc Sơn",
        "path": "Sóc Sơn, Sóc Sơn, Hà Nội",
        "path_with_type": "Thị trấn Sóc Sơn, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00376",
        "parent_code": "016"
    },
    "00379": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Bắc Sơn, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00379",
        "parent_code": "016"
    },
    "00382": {
        "name": "Minh Trí",
        "type": "xa",
        "slug": "minh-tri",
        "name_with_type": "Xã Minh Trí",
        "path": "Minh Trí, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Minh Trí, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00382",
        "parent_code": "016"
    },
    "00385": {
        "name": "Hồng Kỳ",
        "type": "xa",
        "slug": "hong-ky",
        "name_with_type": "Xã Hồng Kỳ",
        "path": "Hồng Kỳ, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Hồng Kỳ, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00385",
        "parent_code": "016"
    },
    "00388": {
        "name": "Nam Sơn",
        "type": "xa",
        "slug": "nam-son",
        "name_with_type": "Xã Nam Sơn",
        "path": "Nam Sơn, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Nam Sơn, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00388",
        "parent_code": "016"
    },
    "00391": {
        "name": "Trung Giã",
        "type": "xa",
        "slug": "trung-gia",
        "name_with_type": "Xã Trung Giã",
        "path": "Trung Giã, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Trung Giã, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00391",
        "parent_code": "016"
    },
    "00394": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Tân Hưng, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00394",
        "parent_code": "016"
    },
    "00397": {
        "name": "Minh Phú",
        "type": "xa",
        "slug": "minh-phu",
        "name_with_type": "Xã Minh Phú",
        "path": "Minh Phú, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Minh Phú, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00397",
        "parent_code": "016"
    },
    "00400": {
        "name": "Phù Linh",
        "type": "xa",
        "slug": "phu-linh",
        "name_with_type": "Xã Phù Linh",
        "path": "Phù Linh, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Phù Linh, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00400",
        "parent_code": "016"
    },
    "00403": {
        "name": "Bắc Phú",
        "type": "xa",
        "slug": "bac-phu",
        "name_with_type": "Xã Bắc Phú",
        "path": "Bắc Phú, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Bắc Phú, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00403",
        "parent_code": "016"
    },
    "00406": {
        "name": "Tân Minh",
        "type": "xa",
        "slug": "tan-minh",
        "name_with_type": "Xã Tân Minh",
        "path": "Tân Minh, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Tân Minh, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00406",
        "parent_code": "016"
    },
    "00409": {
        "name": "Quang Tiến",
        "type": "xa",
        "slug": "quang-tien",
        "name_with_type": "Xã Quang Tiến",
        "path": "Quang Tiến, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Quang Tiến, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00409",
        "parent_code": "016"
    },
    "00412": {
        "name": "Hiền Ninh",
        "type": "xa",
        "slug": "hien-ninh",
        "name_with_type": "Xã Hiền Ninh",
        "path": "Hiền Ninh, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Hiền Ninh, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00412",
        "parent_code": "016"
    },
    "00415": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Tân Dân, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00415",
        "parent_code": "016"
    },
    "00418": {
        "name": "Tiên Dược",
        "type": "xa",
        "slug": "tien-duoc",
        "name_with_type": "Xã Tiên Dược",
        "path": "Tiên Dược, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Tiên Dược, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00418",
        "parent_code": "016"
    },
    "00421": {
        "name": "Việt Long",
        "type": "xa",
        "slug": "viet-long",
        "name_with_type": "Xã Việt Long",
        "path": "Việt Long, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Việt Long, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00421",
        "parent_code": "016"
    },
    "00424": {
        "name": "Xuân Giang",
        "type": "xa",
        "slug": "xuan-giang",
        "name_with_type": "Xã Xuân Giang",
        "path": "Xuân Giang, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Xuân Giang, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00424",
        "parent_code": "016"
    },
    "00427": {
        "name": "Mai Đình",
        "type": "xa",
        "slug": "mai-dinh",
        "name_with_type": "Xã Mai Đình",
        "path": "Mai Đình, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Mai Đình, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00427",
        "parent_code": "016"
    },
    "00430": {
        "name": "Đức Hoà",
        "type": "xa",
        "slug": "duc-hoa",
        "name_with_type": "Xã Đức Hoà",
        "path": "Đức Hoà, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Đức Hoà, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00430",
        "parent_code": "016"
    },
    "00433": {
        "name": "Thanh Xuân",
        "type": "xa",
        "slug": "thanh-xuan",
        "name_with_type": "Xã Thanh Xuân",
        "path": "Thanh Xuân, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Thanh Xuân, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00433",
        "parent_code": "016"
    },
    "00436": {
        "name": "Đông Xuân",
        "type": "xa",
        "slug": "dong-xuan",
        "name_with_type": "Xã Đông Xuân",
        "path": "Đông Xuân, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Đông Xuân, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00436",
        "parent_code": "016"
    },
    "00439": {
        "name": "Kim Lũ",
        "type": "xa",
        "slug": "kim-lu",
        "name_with_type": "Xã Kim Lũ",
        "path": "Kim Lũ, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Kim Lũ, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00439",
        "parent_code": "016"
    },
    "00442": {
        "name": "Phú Cường",
        "type": "xa",
        "slug": "phu-cuong",
        "name_with_type": "Xã Phú Cường",
        "path": "Phú Cường, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Phú Cường, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00442",
        "parent_code": "016"
    },
    "00445": {
        "name": "Phú Minh",
        "type": "xa",
        "slug": "phu-minh",
        "name_with_type": "Xã Phú Minh",
        "path": "Phú Minh, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Phú Minh, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00445",
        "parent_code": "016"
    },
    "00448": {
        "name": "Phù Lỗ",
        "type": "xa",
        "slug": "phu-lo",
        "name_with_type": "Xã Phù Lỗ",
        "path": "Phù Lỗ, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Phù Lỗ, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00448",
        "parent_code": "016"
    },
    "00451": {
        "name": "Xuân Thu",
        "type": "xa",
        "slug": "xuan-thu",
        "name_with_type": "Xã Xuân Thu",
        "path": "Xuân Thu, Sóc Sơn, Hà Nội",
        "path_with_type": "Xã Xuân Thu, Huyện Sóc Sơn, Thành phố Hà Nội",
        "code": "00451",
        "parent_code": "016"
    },
    "00454": {
        "name": "Đông Anh",
        "type": "thi-tran",
        "slug": "dong-anh",
        "name_with_type": "Thị trấn Đông Anh",
        "path": "Đông Anh, Đông Anh, Hà Nội",
        "path_with_type": "Thị trấn Đông Anh, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00454",
        "parent_code": "017"
    },
    "00457": {
        "name": "Xuân Nộn",
        "type": "xa",
        "slug": "xuan-non",
        "name_with_type": "Xã Xuân Nộn",
        "path": "Xuân Nộn, Đông Anh, Hà Nội",
        "path_with_type": "Xã Xuân Nộn, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00457",
        "parent_code": "017"
    },
    "00460": {
        "name": "Thuỵ Lâm",
        "type": "xa",
        "slug": "thuy-lam",
        "name_with_type": "Xã Thuỵ Lâm",
        "path": "Thuỵ Lâm, Đông Anh, Hà Nội",
        "path_with_type": "Xã Thuỵ Lâm, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00460",
        "parent_code": "017"
    },
    "00463": {
        "name": "Bắc Hồng",
        "type": "xa",
        "slug": "bac-hong",
        "name_with_type": "Xã Bắc Hồng",
        "path": "Bắc Hồng, Đông Anh, Hà Nội",
        "path_with_type": "Xã Bắc Hồng, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00463",
        "parent_code": "017"
    },
    "00466": {
        "name": "Nguyên Khê",
        "type": "xa",
        "slug": "nguyen-khe",
        "name_with_type": "Xã Nguyên Khê",
        "path": "Nguyên Khê, Đông Anh, Hà Nội",
        "path_with_type": "Xã Nguyên Khê, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00466",
        "parent_code": "017"
    },
    "00469": {
        "name": "Nam Hồng",
        "type": "xa",
        "slug": "nam-hong",
        "name_with_type": "Xã Nam Hồng",
        "path": "Nam Hồng, Đông Anh, Hà Nội",
        "path_with_type": "Xã Nam Hồng, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00469",
        "parent_code": "017"
    },
    "00472": {
        "name": "Tiên Dương",
        "type": "xa",
        "slug": "tien-duong",
        "name_with_type": "Xã Tiên Dương",
        "path": "Tiên Dương, Đông Anh, Hà Nội",
        "path_with_type": "Xã Tiên Dương, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00472",
        "parent_code": "017"
    },
    "00475": {
        "name": "Vân Hà",
        "type": "xa",
        "slug": "van-ha",
        "name_with_type": "Xã Vân Hà",
        "path": "Vân Hà, Đông Anh, Hà Nội",
        "path_with_type": "Xã Vân Hà, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00475",
        "parent_code": "017"
    },
    "00478": {
        "name": "Uy Nỗ",
        "type": "xa",
        "slug": "uy-no",
        "name_with_type": "Xã Uy Nỗ",
        "path": "Uy Nỗ, Đông Anh, Hà Nội",
        "path_with_type": "Xã Uy Nỗ, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00478",
        "parent_code": "017"
    },
    "00481": {
        "name": "Vân Nội",
        "type": "xa",
        "slug": "van-noi",
        "name_with_type": "Xã Vân Nội",
        "path": "Vân Nội, Đông Anh, Hà Nội",
        "path_with_type": "Xã Vân Nội, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00481",
        "parent_code": "017"
    },
    "00484": {
        "name": "Liên Hà",
        "type": "xa",
        "slug": "lien-ha",
        "name_with_type": "Xã Liên Hà",
        "path": "Liên Hà, Đông Anh, Hà Nội",
        "path_with_type": "Xã Liên Hà, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00484",
        "parent_code": "017"
    },
    "00487": {
        "name": "Việt Hùng",
        "type": "xa",
        "slug": "viet-hung",
        "name_with_type": "Xã Việt Hùng",
        "path": "Việt Hùng, Đông Anh, Hà Nội",
        "path_with_type": "Xã Việt Hùng, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00487",
        "parent_code": "017"
    },
    "00490": {
        "name": "Kim Nỗ",
        "type": "xa",
        "slug": "kim-no",
        "name_with_type": "Xã Kim Nỗ",
        "path": "Kim Nỗ, Đông Anh, Hà Nội",
        "path_with_type": "Xã Kim Nỗ, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00490",
        "parent_code": "017"
    },
    "00493": {
        "name": "Kim Chung",
        "type": "xa",
        "slug": "kim-chung",
        "name_with_type": "Xã Kim Chung",
        "path": "Kim Chung, Đông Anh, Hà Nội",
        "path_with_type": "Xã Kim Chung, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00493",
        "parent_code": "017"
    },
    "00496": {
        "name": "Dục Tú",
        "type": "xa",
        "slug": "duc-tu",
        "name_with_type": "Xã Dục Tú",
        "path": "Dục Tú, Đông Anh, Hà Nội",
        "path_with_type": "Xã Dục Tú, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00496",
        "parent_code": "017"
    },
    "00499": {
        "name": "Đại Mạch",
        "type": "xa",
        "slug": "dai-mach",
        "name_with_type": "Xã Đại Mạch",
        "path": "Đại Mạch, Đông Anh, Hà Nội",
        "path_with_type": "Xã Đại Mạch, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00499",
        "parent_code": "017"
    },
    "00502": {
        "name": "Vĩnh Ngọc",
        "type": "xa",
        "slug": "vinh-ngoc",
        "name_with_type": "Xã Vĩnh Ngọc",
        "path": "Vĩnh Ngọc, Đông Anh, Hà Nội",
        "path_with_type": "Xã Vĩnh Ngọc, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00502",
        "parent_code": "017"
    },
    "00505": {
        "name": "Cổ Loa",
        "type": "xa",
        "slug": "co-loa",
        "name_with_type": "Xã Cổ Loa",
        "path": "Cổ Loa, Đông Anh, Hà Nội",
        "path_with_type": "Xã Cổ Loa, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00505",
        "parent_code": "017"
    },
    "00508": {
        "name": "Hải Bối",
        "type": "xa",
        "slug": "hai-boi",
        "name_with_type": "Xã Hải Bối",
        "path": "Hải Bối, Đông Anh, Hà Nội",
        "path_with_type": "Xã Hải Bối, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00508",
        "parent_code": "017"
    },
    "00511": {
        "name": "Xuân Canh",
        "type": "xa",
        "slug": "xuan-canh",
        "name_with_type": "Xã Xuân Canh",
        "path": "Xuân Canh, Đông Anh, Hà Nội",
        "path_with_type": "Xã Xuân Canh, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00511",
        "parent_code": "017"
    },
    "00514": {
        "name": "Võng La",
        "type": "xa",
        "slug": "vong-la",
        "name_with_type": "Xã Võng La",
        "path": "Võng La, Đông Anh, Hà Nội",
        "path_with_type": "Xã Võng La, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00514",
        "parent_code": "017"
    },
    "00517": {
        "name": "Tầm Xá",
        "type": "xa",
        "slug": "tam-xa",
        "name_with_type": "Xã Tầm Xá",
        "path": "Tầm Xá, Đông Anh, Hà Nội",
        "path_with_type": "Xã Tầm Xá, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00517",
        "parent_code": "017"
    },
    "00520": {
        "name": "Mai Lâm",
        "type": "xa",
        "slug": "mai-lam",
        "name_with_type": "Xã Mai Lâm",
        "path": "Mai Lâm, Đông Anh, Hà Nội",
        "path_with_type": "Xã Mai Lâm, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00520",
        "parent_code": "017"
    },
    "00523": {
        "name": "Đông Hội",
        "type": "xa",
        "slug": "dong-hoi",
        "name_with_type": "Xã Đông Hội",
        "path": "Đông Hội, Đông Anh, Hà Nội",
        "path_with_type": "Xã Đông Hội, Huyện Đông Anh, Thành phố Hà Nội",
        "code": "00523",
        "parent_code": "017"
    },
    "00526": {
        "name": "Yên Viên",
        "type": "thi-tran",
        "slug": "yen-vien",
        "name_with_type": "Thị trấn Yên Viên",
        "path": "Yên Viên, Gia Lâm, Hà Nội",
        "path_with_type": "Thị trấn Yên Viên, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00526",
        "parent_code": "018"
    },
    "00529": {
        "name": "Yên Thường",
        "type": "xa",
        "slug": "yen-thuong",
        "name_with_type": "Xã Yên Thường",
        "path": "Yên Thường, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Yên Thường, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00529",
        "parent_code": "018"
    },
    "00532": {
        "name": "Yên Viên",
        "type": "xa",
        "slug": "yen-vien",
        "name_with_type": "Xã Yên Viên",
        "path": "Yên Viên, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Yên Viên, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00532",
        "parent_code": "018"
    },
    "00535": {
        "name": "Ninh Hiệp",
        "type": "xa",
        "slug": "ninh-hiep",
        "name_with_type": "Xã Ninh Hiệp",
        "path": "Ninh Hiệp, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Ninh Hiệp, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00535",
        "parent_code": "018"
    },
    "00538": {
        "name": "Đình Xuyên",
        "type": "xa",
        "slug": "dinh-xuyen",
        "name_with_type": "Xã Đình Xuyên",
        "path": "Đình Xuyên, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Đình Xuyên, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00538",
        "parent_code": "018"
    },
    "00541": {
        "name": "Dương Hà",
        "type": "xa",
        "slug": "duong-ha",
        "name_with_type": "Xã Dương Hà",
        "path": "Dương Hà, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Dương Hà, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00541",
        "parent_code": "018"
    },
    "00544": {
        "name": "Phù Đổng",
        "type": "xa",
        "slug": "phu-dong",
        "name_with_type": "Xã Phù Đổng",
        "path": "Phù Đổng, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Phù Đổng, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00544",
        "parent_code": "018"
    },
    "00547": {
        "name": "Trung Mầu",
        "type": "xa",
        "slug": "trung-mau",
        "name_with_type": "Xã Trung Mầu",
        "path": "Trung Mầu, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Trung Mầu, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00547",
        "parent_code": "018"
    },
    "00550": {
        "name": "Lệ Chi",
        "type": "xa",
        "slug": "le-chi",
        "name_with_type": "Xã Lệ Chi",
        "path": "Lệ Chi, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Lệ Chi, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00550",
        "parent_code": "018"
    },
    "00553": {
        "name": "Cổ Bi",
        "type": "xa",
        "slug": "co-bi",
        "name_with_type": "Xã Cổ Bi",
        "path": "Cổ Bi, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Cổ Bi, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00553",
        "parent_code": "018"
    },
    "00556": {
        "name": "Đặng Xá",
        "type": "xa",
        "slug": "dang-xa",
        "name_with_type": "Xã Đặng Xá",
        "path": "Đặng Xá, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Đặng Xá, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00556",
        "parent_code": "018"
    },
    "00559": {
        "name": "Phú Thị",
        "type": "xa",
        "slug": "phu-thi",
        "name_with_type": "Xã Phú Thị",
        "path": "Phú Thị, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Phú Thị, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00559",
        "parent_code": "018"
    },
    "00562": {
        "name": "Kim Sơn",
        "type": "xa",
        "slug": "kim-son",
        "name_with_type": "Xã Kim Sơn",
        "path": "Kim Sơn, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Kim Sơn, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00562",
        "parent_code": "018"
    },
    "00565": {
        "name": "Trâu Quỳ",
        "type": "thi-tran",
        "slug": "trau-quy",
        "name_with_type": "Thị trấn Trâu Quỳ",
        "path": "Trâu Quỳ, Gia Lâm, Hà Nội",
        "path_with_type": "Thị trấn Trâu Quỳ, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00565",
        "parent_code": "018"
    },
    "00568": {
        "name": "Dương Quang",
        "type": "xa",
        "slug": "duong-quang",
        "name_with_type": "Xã Dương Quang",
        "path": "Dương Quang, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Dương Quang, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00568",
        "parent_code": "018"
    },
    "00571": {
        "name": "Dương Xá",
        "type": "xa",
        "slug": "duong-xa",
        "name_with_type": "Xã Dương Xá",
        "path": "Dương Xá, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Dương Xá, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00571",
        "parent_code": "018"
    },
    "00574": {
        "name": "Đông Dư",
        "type": "xa",
        "slug": "dong-du",
        "name_with_type": "Xã Đông Dư",
        "path": "Đông Dư, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Đông Dư, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00574",
        "parent_code": "018"
    },
    "00577": {
        "name": "Đa Tốn",
        "type": "xa",
        "slug": "da-ton",
        "name_with_type": "Xã Đa Tốn",
        "path": "Đa Tốn, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Đa Tốn, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00577",
        "parent_code": "018"
    },
    "00580": {
        "name": "Kiêu Kỵ",
        "type": "xa",
        "slug": "kieu-ky",
        "name_with_type": "Xã Kiêu Kỵ",
        "path": "Kiêu Kỵ, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Kiêu Kỵ, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00580",
        "parent_code": "018"
    },
    "00583": {
        "name": "Bát Tràng",
        "type": "xa",
        "slug": "bat-trang",
        "name_with_type": "Xã Bát Tràng",
        "path": "Bát Tràng, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Bát Tràng, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00583",
        "parent_code": "018"
    },
    "00586": {
        "name": "Kim Lan",
        "type": "xa",
        "slug": "kim-lan",
        "name_with_type": "Xã Kim Lan",
        "path": "Kim Lan, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Kim Lan, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00586",
        "parent_code": "018"
    },
    "00589": {
        "name": "Văn Đức",
        "type": "xa",
        "slug": "van-duc",
        "name_with_type": "Xã Văn Đức",
        "path": "Văn Đức, Gia Lâm, Hà Nội",
        "path_with_type": "Xã Văn Đức, Huyện Gia Lâm, Thành phố Hà Nội",
        "code": "00589",
        "parent_code": "018"
    },
    "00592": {
        "name": "Cầu Diễn",
        "type": "phuong",
        "slug": "cau-dien",
        "name_with_type": "Phường Cầu Diễn",
        "path": "Cầu Diễn, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Cầu Diễn, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00592",
        "parent_code": "019"
    },
    "00622": {
        "name": "Xuân Phương",
        "type": "phuong",
        "slug": "xuan-phuong",
        "name_with_type": "Phường Xuân Phương",
        "path": "Xuân Phương, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Xuân Phương, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00622",
        "parent_code": "019"
    },
    "00623": {
        "name": "Phương Canh",
        "type": "phuong",
        "slug": "phuong-canh",
        "name_with_type": "Phường Phương Canh",
        "path": "Phương Canh, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Phương Canh, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00623",
        "parent_code": "019"
    },
    "00625": {
        "name": "Mỹ Đình 1",
        "type": "phuong",
        "slug": "my-dinh-1",
        "name_with_type": "Phường Mỹ Đình 1",
        "path": "Mỹ Đình 1, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Mỹ Đình 1, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00625",
        "parent_code": "019"
    },
    "00626": {
        "name": "Mỹ Đình 2",
        "type": "phuong",
        "slug": "my-dinh-2",
        "name_with_type": "Phường Mỹ Đình 2",
        "path": "Mỹ Đình 2, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Mỹ Đình 2, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00626",
        "parent_code": "019"
    },
    "00628": {
        "name": "Tây Mỗ",
        "type": "phuong",
        "slug": "tay-mo",
        "name_with_type": "Phường Tây Mỗ",
        "path": "Tây Mỗ, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Tây Mỗ, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00628",
        "parent_code": "019"
    },
    "00631": {
        "name": "Mễ Trì",
        "type": "phuong",
        "slug": "me-tri",
        "name_with_type": "Phường Mễ Trì",
        "path": "Mễ Trì, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Mễ Trì, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00631",
        "parent_code": "019"
    },
    "00632": {
        "name": "Phú Đô",
        "type": "phuong",
        "slug": "phu-do",
        "name_with_type": "Phường Phú Đô",
        "path": "Phú Đô, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Phú Đô, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00632",
        "parent_code": "019"
    },
    "00634": {
        "name": "Đại Mỗ",
        "type": "phuong",
        "slug": "dai-mo",
        "name_with_type": "Phường Đại Mỗ",
        "path": "Đại Mỗ, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Đại Mỗ, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00634",
        "parent_code": "019"
    },
    "00637": {
        "name": "Trung Văn",
        "type": "phuong",
        "slug": "trung-van",
        "name_with_type": "Phường Trung Văn",
        "path": "Trung Văn, Nam Từ Liêm, Hà Nội",
        "path_with_type": "Phường Trung Văn, Quận Nam Từ Liêm, Thành phố Hà Nội",
        "code": "00637",
        "parent_code": "019"
    },
    "00640": {
        "name": "Văn Điển",
        "type": "thi-tran",
        "slug": "van-dien",
        "name_with_type": "Thị trấn Văn Điển",
        "path": "Văn Điển, Thanh Trì, Hà Nội",
        "path_with_type": "Thị trấn Văn Điển, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00640",
        "parent_code": "020"
    },
    "00643": {
        "name": "Tân Triều",
        "type": "xa",
        "slug": "tan-trieu",
        "name_with_type": "Xã Tân Triều",
        "path": "Tân Triều, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Tân Triều, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00643",
        "parent_code": "020"
    },
    "00646": {
        "name": "Thanh Liệt",
        "type": "xa",
        "slug": "thanh-liet",
        "name_with_type": "Xã Thanh Liệt",
        "path": "Thanh Liệt, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Thanh Liệt, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00646",
        "parent_code": "020"
    },
    "00649": {
        "name": "Tả Thanh Oai",
        "type": "xa",
        "slug": "ta-thanh-oai",
        "name_with_type": "Xã Tả Thanh Oai",
        "path": "Tả Thanh Oai, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Tả Thanh Oai, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00649",
        "parent_code": "020"
    },
    "00652": {
        "name": "Hữu Hoà",
        "type": "xa",
        "slug": "huu-hoa",
        "name_with_type": "Xã Hữu Hoà",
        "path": "Hữu Hoà, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Hữu Hoà, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00652",
        "parent_code": "020"
    },
    "00655": {
        "name": "Tam Hiệp",
        "type": "xa",
        "slug": "tam-hiep",
        "name_with_type": "Xã Tam Hiệp",
        "path": "Tam Hiệp, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Tam Hiệp, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00655",
        "parent_code": "020"
    },
    "00658": {
        "name": "Tứ Hiệp",
        "type": "xa",
        "slug": "tu-hiep",
        "name_with_type": "Xã Tứ Hiệp",
        "path": "Tứ Hiệp, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Tứ Hiệp, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00658",
        "parent_code": "020"
    },
    "00661": {
        "name": "Yên Mỹ",
        "type": "xa",
        "slug": "yen-my",
        "name_with_type": "Xã Yên Mỹ",
        "path": "Yên Mỹ, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Yên Mỹ, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00661",
        "parent_code": "020"
    },
    "00664": {
        "name": "Vĩnh Quỳnh",
        "type": "xa",
        "slug": "vinh-quynh",
        "name_with_type": "Xã Vĩnh Quỳnh",
        "path": "Vĩnh Quỳnh, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Vĩnh Quỳnh, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00664",
        "parent_code": "020"
    },
    "00667": {
        "name": "Ngũ Hiệp",
        "type": "xa",
        "slug": "ngu-hiep",
        "name_with_type": "Xã Ngũ Hiệp",
        "path": "Ngũ Hiệp, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Ngũ Hiệp, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00667",
        "parent_code": "020"
    },
    "00670": {
        "name": "Duyên Hà",
        "type": "xa",
        "slug": "duyen-ha",
        "name_with_type": "Xã Duyên Hà",
        "path": "Duyên Hà, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Duyên Hà, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00670",
        "parent_code": "020"
    },
    "00673": {
        "name": "Ngọc Hồi",
        "type": "xa",
        "slug": "ngoc-hoi",
        "name_with_type": "Xã Ngọc Hồi",
        "path": "Ngọc Hồi, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Ngọc Hồi, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00673",
        "parent_code": "020"
    },
    "00676": {
        "name": "Vạn Phúc",
        "type": "xa",
        "slug": "van-phuc",
        "name_with_type": "Xã Vạn Phúc",
        "path": "Vạn Phúc, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Vạn Phúc, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00676",
        "parent_code": "020"
    },
    "00679": {
        "name": "Đại áng",
        "type": "xa",
        "slug": "dai-ang",
        "name_with_type": "Xã Đại áng",
        "path": "Đại áng, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Đại áng, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00679",
        "parent_code": "020"
    },
    "00682": {
        "name": "Liên Ninh",
        "type": "xa",
        "slug": "lien-ninh",
        "name_with_type": "Xã Liên Ninh",
        "path": "Liên Ninh, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Liên Ninh, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00682",
        "parent_code": "020"
    },
    "00685": {
        "name": "Đông Mỹ",
        "type": "xa",
        "slug": "dong-my",
        "name_with_type": "Xã Đông Mỹ",
        "path": "Đông Mỹ, Thanh Trì, Hà Nội",
        "path_with_type": "Xã Đông Mỹ, Huyện Thanh Trì, Thành phố Hà Nội",
        "code": "00685",
        "parent_code": "020"
    },
    "00595": {
        "name": "Thượng Cát",
        "type": "phuong",
        "slug": "thuong-cat",
        "name_with_type": "Phường Thượng Cát",
        "path": "Thượng Cát, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Thượng Cát, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00595",
        "parent_code": "021"
    },
    "00598": {
        "name": "Liên Mạc",
        "type": "phuong",
        "slug": "lien-mac",
        "name_with_type": "Phường Liên Mạc",
        "path": "Liên Mạc, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Liên Mạc, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00598",
        "parent_code": "021"
    },
    "00601": {
        "name": "Đông Ngạc",
        "type": "phuong",
        "slug": "dong-ngac",
        "name_with_type": "Phường Đông Ngạc",
        "path": "Đông Ngạc, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Đông Ngạc, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00601",
        "parent_code": "021"
    },
    "00602": {
        "name": "Đức Thắng",
        "type": "phuong",
        "slug": "duc-thang",
        "name_with_type": "Phường Đức Thắng",
        "path": "Đức Thắng, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Đức Thắng, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00602",
        "parent_code": "021"
    },
    "00604": {
        "name": "Thụy Phương",
        "type": "phuong",
        "slug": "thuy-phuong",
        "name_with_type": "Phường Thụy Phương",
        "path": "Thụy Phương, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Thụy Phương, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00604",
        "parent_code": "021"
    },
    "00607": {
        "name": "Tây Tựu",
        "type": "phuong",
        "slug": "tay-tuu",
        "name_with_type": "Phường Tây Tựu",
        "path": "Tây Tựu, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Tây Tựu, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00607",
        "parent_code": "021"
    },
    "00610": {
        "name": "Xuân Đỉnh",
        "type": "phuong",
        "slug": "xuan-dinh",
        "name_with_type": "Phường Xuân Đỉnh",
        "path": "Xuân Đỉnh, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Xuân Đỉnh, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00610",
        "parent_code": "021"
    },
    "00611": {
        "name": "Xuân Tảo",
        "type": "phuong",
        "slug": "xuan-tao",
        "name_with_type": "Phường Xuân Tảo",
        "path": "Xuân Tảo, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Xuân Tảo, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00611",
        "parent_code": "021"
    },
    "00613": {
        "name": "Minh Khai",
        "type": "phuong",
        "slug": "minh-khai",
        "name_with_type": "Phường Minh Khai",
        "path": "Minh Khai, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Minh Khai, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00613",
        "parent_code": "021"
    },
    "00616": {
        "name": "Cổ Nhuế 1",
        "type": "phuong",
        "slug": "co-nhue-1",
        "name_with_type": "Phường Cổ Nhuế 1",
        "path": "Cổ Nhuế 1, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Cổ Nhuế 1, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00616",
        "parent_code": "021"
    },
    "00617": {
        "name": "Cổ Nhuế 2",
        "type": "phuong",
        "slug": "co-nhue-2",
        "name_with_type": "Phường Cổ Nhuế 2",
        "path": "Cổ Nhuế 2, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Cổ Nhuế 2, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00617",
        "parent_code": "021"
    },
    "00619": {
        "name": "Phú Diễn",
        "type": "phuong",
        "slug": "phu-dien",
        "name_with_type": "Phường Phú Diễn",
        "path": "Phú Diễn, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Phú Diễn, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00619",
        "parent_code": "021"
    },
    "00620": {
        "name": "Phúc Diễn",
        "type": "phuong",
        "slug": "phuc-dien",
        "name_with_type": "Phường Phúc Diễn",
        "path": "Phúc Diễn, Bắc Từ Liêm, Hà Nội",
        "path_with_type": "Phường Phúc Diễn, Quận Bắc Từ Liêm, Thành phố Hà Nội",
        "code": "00620",
        "parent_code": "021"
    },
    "08973": {
        "name": "Chi Đông",
        "type": "thi-tran",
        "slug": "chi-dong",
        "name_with_type": "Thị trấn Chi Đông",
        "path": "Chi Đông, Mê Linh, Hà Nội",
        "path_with_type": "Thị trấn Chi Đông, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08973",
        "parent_code": "250"
    },
    "08974": {
        "name": "Đại Thịnh",
        "type": "xa",
        "slug": "dai-thinh",
        "name_with_type": "Xã Đại Thịnh",
        "path": "Đại Thịnh, Mê Linh, Hà Nội",
        "path_with_type": "Xã Đại Thịnh, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08974",
        "parent_code": "250"
    },
    "08977": {
        "name": "Kim Hoa",
        "type": "xa",
        "slug": "kim-hoa",
        "name_with_type": "Xã Kim Hoa",
        "path": "Kim Hoa, Mê Linh, Hà Nội",
        "path_with_type": "Xã Kim Hoa, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08977",
        "parent_code": "250"
    },
    "08980": {
        "name": "Thạch Đà",
        "type": "xa",
        "slug": "thach-da",
        "name_with_type": "Xã Thạch Đà",
        "path": "Thạch Đà, Mê Linh, Hà Nội",
        "path_with_type": "Xã Thạch Đà, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08980",
        "parent_code": "250"
    },
    "08983": {
        "name": "Tiến Thắng",
        "type": "xa",
        "slug": "tien-thang",
        "name_with_type": "Xã Tiến Thắng",
        "path": "Tiến Thắng, Mê Linh, Hà Nội",
        "path_with_type": "Xã Tiến Thắng, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08983",
        "parent_code": "250"
    },
    "08986": {
        "name": "Tự Lập",
        "type": "xa",
        "slug": "tu-lap",
        "name_with_type": "Xã Tự Lập",
        "path": "Tự Lập, Mê Linh, Hà Nội",
        "path_with_type": "Xã Tự Lập, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08986",
        "parent_code": "250"
    },
    "08989": {
        "name": "Quang Minh",
        "type": "thi-tran",
        "slug": "quang-minh",
        "name_with_type": "Thị trấn Quang Minh",
        "path": "Quang Minh, Mê Linh, Hà Nội",
        "path_with_type": "Thị trấn Quang Minh, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08989",
        "parent_code": "250"
    },
    "08992": {
        "name": "Thanh Lâm",
        "type": "xa",
        "slug": "thanh-lam",
        "name_with_type": "Xã Thanh Lâm",
        "path": "Thanh Lâm, Mê Linh, Hà Nội",
        "path_with_type": "Xã Thanh Lâm, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08992",
        "parent_code": "250"
    },
    "08995": {
        "name": "Tam Đồng",
        "type": "xa",
        "slug": "tam-dong",
        "name_with_type": "Xã Tam Đồng",
        "path": "Tam Đồng, Mê Linh, Hà Nội",
        "path_with_type": "Xã Tam Đồng, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08995",
        "parent_code": "250"
    },
    "08998": {
        "name": "Liên Mạc",
        "type": "xa",
        "slug": "lien-mac",
        "name_with_type": "Xã Liên Mạc",
        "path": "Liên Mạc, Mê Linh, Hà Nội",
        "path_with_type": "Xã Liên Mạc, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "08998",
        "parent_code": "250"
    },
    "09001": {
        "name": "Vạn Yên",
        "type": "xa",
        "slug": "van-yen",
        "name_with_type": "Xã Vạn Yên",
        "path": "Vạn Yên, Mê Linh, Hà Nội",
        "path_with_type": "Xã Vạn Yên, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "09001",
        "parent_code": "250"
    },
    "09004": {
        "name": "Chu Phan",
        "type": "xa",
        "slug": "chu-phan",
        "name_with_type": "Xã Chu Phan",
        "path": "Chu Phan, Mê Linh, Hà Nội",
        "path_with_type": "Xã Chu Phan, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "09004",
        "parent_code": "250"
    },
    "09007": {
        "name": "Tiến Thịnh",
        "type": "xa",
        "slug": "tien-thinh",
        "name_with_type": "Xã Tiến Thịnh",
        "path": "Tiến Thịnh, Mê Linh, Hà Nội",
        "path_with_type": "Xã Tiến Thịnh, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "09007",
        "parent_code": "250"
    },
    "09010": {
        "name": "Mê Linh",
        "type": "xa",
        "slug": "me-linh",
        "name_with_type": "Xã Mê Linh",
        "path": "Mê Linh, Mê Linh, Hà Nội",
        "path_with_type": "Xã Mê Linh, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "09010",
        "parent_code": "250"
    },
    "09013": {
        "name": "Văn Khê",
        "type": "xa",
        "slug": "van-khe",
        "name_with_type": "Xã Văn Khê",
        "path": "Văn Khê, Mê Linh, Hà Nội",
        "path_with_type": "Xã Văn Khê, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "09013",
        "parent_code": "250"
    },
    "09016": {
        "name": "Hoàng Kim",
        "type": "xa",
        "slug": "hoang-kim",
        "name_with_type": "Xã Hoàng Kim",
        "path": "Hoàng Kim, Mê Linh, Hà Nội",
        "path_with_type": "Xã Hoàng Kim, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "09016",
        "parent_code": "250"
    },
    "09019": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Mê Linh, Hà Nội",
        "path_with_type": "Xã Tiền Phong, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "09019",
        "parent_code": "250"
    },
    "09022": {
        "name": "Tráng Việt",
        "type": "xa",
        "slug": "trang-viet",
        "name_with_type": "Xã Tráng Việt",
        "path": "Tráng Việt, Mê Linh, Hà Nội",
        "path_with_type": "Xã Tráng Việt, Huyện Mê Linh, Thành phố Hà Nội",
        "code": "09022",
        "parent_code": "250"
    },
    "09538": {
        "name": "Nguyễn Trãi",
        "type": "phuong",
        "slug": "nguyen-trai",
        "name_with_type": "Phường Nguyễn Trãi",
        "path": "Nguyễn Trãi, Hà Đông, Hà Nội",
        "path_with_type": "Phường Nguyễn Trãi, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09538",
        "parent_code": "268"
    },
    "09541": {
        "name": "Mộ Lao",
        "type": "phuong",
        "slug": "mo-lao",
        "name_with_type": "Phường Mộ Lao",
        "path": "Mộ Lao, Hà Đông, Hà Nội",
        "path_with_type": "Phường Mộ Lao, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09541",
        "parent_code": "268"
    },
    "09542": {
        "name": "Văn Quán",
        "type": "phuong",
        "slug": "van-quan",
        "name_with_type": "Phường Văn Quán",
        "path": "Văn Quán, Hà Đông, Hà Nội",
        "path_with_type": "Phường Văn Quán, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09542",
        "parent_code": "268"
    },
    "09544": {
        "name": "Vạn Phúc",
        "type": "phuong",
        "slug": "van-phuc",
        "name_with_type": "Phường Vạn Phúc",
        "path": "Vạn Phúc, Hà Đông, Hà Nội",
        "path_with_type": "Phường Vạn Phúc, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09544",
        "parent_code": "268"
    },
    "09547": {
        "name": "Yết Kiêu",
        "type": "phuong",
        "slug": "yet-kieu",
        "name_with_type": "Phường Yết Kiêu",
        "path": "Yết Kiêu, Hà Đông, Hà Nội",
        "path_with_type": "Phường Yết Kiêu, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09547",
        "parent_code": "268"
    },
    "09550": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Hà Đông, Hà Nội",
        "path_with_type": "Phường Quang Trung, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09550",
        "parent_code": "268"
    },
    "09551": {
        "name": "La Khê",
        "type": "phuong",
        "slug": "la-khe",
        "name_with_type": "Phường La Khê",
        "path": "La Khê, Hà Đông, Hà Nội",
        "path_with_type": "Phường La Khê, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09551",
        "parent_code": "268"
    },
    "09552": {
        "name": "Phú La",
        "type": "phuong",
        "slug": "phu-la",
        "name_with_type": "Phường Phú La",
        "path": "Phú La, Hà Đông, Hà Nội",
        "path_with_type": "Phường Phú La, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09552",
        "parent_code": "268"
    },
    "09553": {
        "name": "Phúc La",
        "type": "phuong",
        "slug": "phuc-la",
        "name_with_type": "Phường Phúc La",
        "path": "Phúc La, Hà Đông, Hà Nội",
        "path_with_type": "Phường Phúc La, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09553",
        "parent_code": "268"
    },
    "09556": {
        "name": "Hà Cầu",
        "type": "phuong",
        "slug": "ha-cau",
        "name_with_type": "Phường Hà Cầu",
        "path": "Hà Cầu, Hà Đông, Hà Nội",
        "path_with_type": "Phường Hà Cầu, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09556",
        "parent_code": "268"
    },
    "09562": {
        "name": "Yên Nghĩa",
        "type": "phuong",
        "slug": "yen-nghia",
        "name_with_type": "Phường Yên Nghĩa",
        "path": "Yên Nghĩa, Hà Đông, Hà Nội",
        "path_with_type": "Phường Yên Nghĩa, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09562",
        "parent_code": "268"
    },
    "09565": {
        "name": "Kiến Hưng",
        "type": "phuong",
        "slug": "kien-hung",
        "name_with_type": "Phường Kiến Hưng",
        "path": "Kiến Hưng, Hà Đông, Hà Nội",
        "path_with_type": "Phường Kiến Hưng, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09565",
        "parent_code": "268"
    },
    "09568": {
        "name": "Phú Lãm",
        "type": "phuong",
        "slug": "phu-lam",
        "name_with_type": "Phường Phú Lãm",
        "path": "Phú Lãm, Hà Đông, Hà Nội",
        "path_with_type": "Phường Phú Lãm, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09568",
        "parent_code": "268"
    },
    "09571": {
        "name": "Phú Lương",
        "type": "phuong",
        "slug": "phu-luong",
        "name_with_type": "Phường Phú Lương",
        "path": "Phú Lương, Hà Đông, Hà Nội",
        "path_with_type": "Phường Phú Lương, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09571",
        "parent_code": "268"
    },
    "09886": {
        "name": "Dương Nội",
        "type": "phuong",
        "slug": "duong-noi",
        "name_with_type": "Phường Dương Nội",
        "path": "Dương Nội, Hà Đông, Hà Nội",
        "path_with_type": "Phường Dương Nội, Quận Hà Đông, Thành phố Hà Nội",
        "code": "09886",
        "parent_code": "268"
    },
    "10117": {
        "name": "Đồng Mai",
        "type": "phuong",
        "slug": "dong-mai",
        "name_with_type": "Phường Đồng Mai",
        "path": "Đồng Mai, Hà Đông, Hà Nội",
        "path_with_type": "Phường Đồng Mai, Quận Hà Đông, Thành phố Hà Nội",
        "code": "10117",
        "parent_code": "268"
    },
    "10123": {
        "name": "Biên Giang",
        "type": "phuong",
        "slug": "bien-giang",
        "name_with_type": "Phường Biên Giang",
        "path": "Biên Giang, Hà Đông, Hà Nội",
        "path_with_type": "Phường Biên Giang, Quận Hà Đông, Thành phố Hà Nội",
        "code": "10123",
        "parent_code": "268"
    },
    "09574": {
        "name": "Lê Lợi",
        "type": "phuong",
        "slug": "le-loi",
        "name_with_type": "Phường Lê Lợi",
        "path": "Lê Lợi, Sơn Tây, Hà Nội",
        "path_with_type": "Phường Lê Lợi, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09574",
        "parent_code": "269"
    },
    "09577": {
        "name": "Phú Thịnh",
        "type": "phuong",
        "slug": "phu-thinh",
        "name_with_type": "Phường Phú Thịnh",
        "path": "Phú Thịnh, Sơn Tây, Hà Nội",
        "path_with_type": "Phường Phú Thịnh, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09577",
        "parent_code": "269"
    },
    "09580": {
        "name": "Ngô Quyền",
        "type": "phuong",
        "slug": "ngo-quyen",
        "name_with_type": "Phường Ngô Quyền",
        "path": "Ngô Quyền, Sơn Tây, Hà Nội",
        "path_with_type": "Phường Ngô Quyền, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09580",
        "parent_code": "269"
    },
    "09583": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Sơn Tây, Hà Nội",
        "path_with_type": "Phường Quang Trung, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09583",
        "parent_code": "269"
    },
    "09586": {
        "name": "Sơn Lộc",
        "type": "phuong",
        "slug": "son-loc",
        "name_with_type": "Phường Sơn Lộc",
        "path": "Sơn Lộc, Sơn Tây, Hà Nội",
        "path_with_type": "Phường Sơn Lộc, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09586",
        "parent_code": "269"
    },
    "09589": {
        "name": "Xuân Khanh",
        "type": "phuong",
        "slug": "xuan-khanh",
        "name_with_type": "Phường Xuân Khanh",
        "path": "Xuân Khanh, Sơn Tây, Hà Nội",
        "path_with_type": "Phường Xuân Khanh, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09589",
        "parent_code": "269"
    },
    "09592": {
        "name": "Đường Lâm",
        "type": "xa",
        "slug": "duong-lam",
        "name_with_type": "Xã Đường Lâm",
        "path": "Đường Lâm, Sơn Tây, Hà Nội",
        "path_with_type": "Xã Đường Lâm, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09592",
        "parent_code": "269"
    },
    "09595": {
        "name": "Viên Sơn",
        "type": "phuong",
        "slug": "vien-son",
        "name_with_type": "Phường Viên Sơn",
        "path": "Viên Sơn, Sơn Tây, Hà Nội",
        "path_with_type": "Phường Viên Sơn, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09595",
        "parent_code": "269"
    },
    "09598": {
        "name": "Xuân Sơn",
        "type": "xa",
        "slug": "xuan-son",
        "name_with_type": "Xã Xuân Sơn",
        "path": "Xuân Sơn, Sơn Tây, Hà Nội",
        "path_with_type": "Xã Xuân Sơn, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09598",
        "parent_code": "269"
    },
    "09601": {
        "name": "Trung Hưng",
        "type": "phuong",
        "slug": "trung-hung",
        "name_with_type": "Phường Trung Hưng",
        "path": "Trung Hưng, Sơn Tây, Hà Nội",
        "path_with_type": "Phường Trung Hưng, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09601",
        "parent_code": "269"
    },
    "09604": {
        "name": "Thanh Mỹ",
        "type": "xa",
        "slug": "thanh-my",
        "name_with_type": "Xã Thanh Mỹ",
        "path": "Thanh Mỹ, Sơn Tây, Hà Nội",
        "path_with_type": "Xã Thanh Mỹ, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09604",
        "parent_code": "269"
    },
    "09607": {
        "name": "Trung Sơn Trầm",
        "type": "phuong",
        "slug": "trung-son-tram",
        "name_with_type": "Phường Trung Sơn Trầm",
        "path": "Trung Sơn Trầm, Sơn Tây, Hà Nội",
        "path_with_type": "Phường Trung Sơn Trầm, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09607",
        "parent_code": "269"
    },
    "09610": {
        "name": "Kim Sơn",
        "type": "xa",
        "slug": "kim-son",
        "name_with_type": "Xã Kim Sơn",
        "path": "Kim Sơn, Sơn Tây, Hà Nội",
        "path_with_type": "Xã Kim Sơn, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09610",
        "parent_code": "269"
    },
    "09613": {
        "name": "Sơn Đông",
        "type": "xa",
        "slug": "son-dong",
        "name_with_type": "Xã Sơn Đông",
        "path": "Sơn Đông, Sơn Tây, Hà Nội",
        "path_with_type": "Xã Sơn Đông, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09613",
        "parent_code": "269"
    },
    "09616": {
        "name": "Cổ Đông",
        "type": "xa",
        "slug": "co-dong",
        "name_with_type": "Xã Cổ Đông",
        "path": "Cổ Đông, Sơn Tây, Hà Nội",
        "path_with_type": "Xã Cổ Đông, Thị xã Sơn Tây, Thành phố Hà Nội",
        "code": "09616",
        "parent_code": "269"
    },
    "09619": {
        "name": "Tây Đằng",
        "type": "thi-tran",
        "slug": "tay-dang",
        "name_with_type": "Thị trấn Tây Đằng",
        "path": "Tây Đằng, Ba Vì, Hà Nội",
        "path_with_type": "Thị trấn Tây Đằng, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09619",
        "parent_code": "271"
    },
    "09625": {
        "name": "Phú Cường",
        "type": "xa",
        "slug": "phu-cuong",
        "name_with_type": "Xã Phú Cường",
        "path": "Phú Cường, Ba Vì, Hà Nội",
        "path_with_type": "Xã Phú Cường, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09625",
        "parent_code": "271"
    },
    "09628": {
        "name": "Cổ Đô",
        "type": "xa",
        "slug": "co-do",
        "name_with_type": "Xã Cổ Đô",
        "path": "Cổ Đô, Ba Vì, Hà Nội",
        "path_with_type": "Xã Cổ Đô, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09628",
        "parent_code": "271"
    },
    "09631": {
        "name": "Tản Hồng",
        "type": "xa",
        "slug": "tan-hong",
        "name_with_type": "Xã Tản Hồng",
        "path": "Tản Hồng, Ba Vì, Hà Nội",
        "path_with_type": "Xã Tản Hồng, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09631",
        "parent_code": "271"
    },
    "09634": {
        "name": "Vạn Thắng",
        "type": "xa",
        "slug": "van-thang",
        "name_with_type": "Xã Vạn Thắng",
        "path": "Vạn Thắng, Ba Vì, Hà Nội",
        "path_with_type": "Xã Vạn Thắng, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09634",
        "parent_code": "271"
    },
    "09637": {
        "name": "Châu Sơn",
        "type": "xa",
        "slug": "chau-son",
        "name_with_type": "Xã Châu Sơn",
        "path": "Châu Sơn, Ba Vì, Hà Nội",
        "path_with_type": "Xã Châu Sơn, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09637",
        "parent_code": "271"
    },
    "09640": {
        "name": "Phong Vân",
        "type": "xa",
        "slug": "phong-van",
        "name_with_type": "Xã Phong Vân",
        "path": "Phong Vân, Ba Vì, Hà Nội",
        "path_with_type": "Xã Phong Vân, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09640",
        "parent_code": "271"
    },
    "09643": {
        "name": "Phú Đông",
        "type": "xa",
        "slug": "phu-dong",
        "name_with_type": "Xã Phú Đông",
        "path": "Phú Đông, Ba Vì, Hà Nội",
        "path_with_type": "Xã Phú Đông, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09643",
        "parent_code": "271"
    },
    "09646": {
        "name": "Phú Phương",
        "type": "xa",
        "slug": "phu-phuong",
        "name_with_type": "Xã Phú Phương",
        "path": "Phú Phương, Ba Vì, Hà Nội",
        "path_with_type": "Xã Phú Phương, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09646",
        "parent_code": "271"
    },
    "09649": {
        "name": "Phú Châu",
        "type": "xa",
        "slug": "phu-chau",
        "name_with_type": "Xã Phú Châu",
        "path": "Phú Châu, Ba Vì, Hà Nội",
        "path_with_type": "Xã Phú Châu, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09649",
        "parent_code": "271"
    },
    "09652": {
        "name": "Thái Hòa",
        "type": "xa",
        "slug": "thai-hoa",
        "name_with_type": "Xã Thái Hòa",
        "path": "Thái Hòa, Ba Vì, Hà Nội",
        "path_with_type": "Xã Thái Hòa, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09652",
        "parent_code": "271"
    },
    "09655": {
        "name": "Đồng Thái",
        "type": "xa",
        "slug": "dong-thai",
        "name_with_type": "Xã Đồng Thái",
        "path": "Đồng Thái, Ba Vì, Hà Nội",
        "path_with_type": "Xã Đồng Thái, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09655",
        "parent_code": "271"
    },
    "09658": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Ba Vì, Hà Nội",
        "path_with_type": "Xã Phú Sơn, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09658",
        "parent_code": "271"
    },
    "09661": {
        "name": "Minh Châu",
        "type": "xa",
        "slug": "minh-chau",
        "name_with_type": "Xã Minh Châu",
        "path": "Minh Châu, Ba Vì, Hà Nội",
        "path_with_type": "Xã Minh Châu, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09661",
        "parent_code": "271"
    },
    "09664": {
        "name": "Vật Lại",
        "type": "xa",
        "slug": "vat-lai",
        "name_with_type": "Xã Vật Lại",
        "path": "Vật Lại, Ba Vì, Hà Nội",
        "path_with_type": "Xã Vật Lại, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09664",
        "parent_code": "271"
    },
    "09667": {
        "name": "Chu Minh",
        "type": "xa",
        "slug": "chu-minh",
        "name_with_type": "Xã Chu Minh",
        "path": "Chu Minh, Ba Vì, Hà Nội",
        "path_with_type": "Xã Chu Minh, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09667",
        "parent_code": "271"
    },
    "09670": {
        "name": "Tòng Bạt",
        "type": "xa",
        "slug": "tong-bat",
        "name_with_type": "Xã Tòng Bạt",
        "path": "Tòng Bạt, Ba Vì, Hà Nội",
        "path_with_type": "Xã Tòng Bạt, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09670",
        "parent_code": "271"
    },
    "09673": {
        "name": "Cẩm Lĩnh",
        "type": "xa",
        "slug": "cam-linh",
        "name_with_type": "Xã Cẩm Lĩnh",
        "path": "Cẩm Lĩnh, Ba Vì, Hà Nội",
        "path_with_type": "Xã Cẩm Lĩnh, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09673",
        "parent_code": "271"
    },
    "09676": {
        "name": "Sơn Đà",
        "type": "xa",
        "slug": "son-da",
        "name_with_type": "Xã Sơn Đà",
        "path": "Sơn Đà, Ba Vì, Hà Nội",
        "path_with_type": "Xã Sơn Đà, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09676",
        "parent_code": "271"
    },
    "09679": {
        "name": "Đông Quang",
        "type": "xa",
        "slug": "dong-quang",
        "name_with_type": "Xã Đông Quang",
        "path": "Đông Quang, Ba Vì, Hà Nội",
        "path_with_type": "Xã Đông Quang, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09679",
        "parent_code": "271"
    },
    "09682": {
        "name": "Tiên Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiên Phong",
        "path": "Tiên Phong, Ba Vì, Hà Nội",
        "path_with_type": "Xã Tiên Phong, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09682",
        "parent_code": "271"
    },
    "09685": {
        "name": "Thụy An",
        "type": "xa",
        "slug": "thuy-an",
        "name_with_type": "Xã Thụy An",
        "path": "Thụy An, Ba Vì, Hà Nội",
        "path_with_type": "Xã Thụy An, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09685",
        "parent_code": "271"
    },
    "09688": {
        "name": "Cam Thượng",
        "type": "xa",
        "slug": "cam-thuong",
        "name_with_type": "Xã Cam Thượng",
        "path": "Cam Thượng, Ba Vì, Hà Nội",
        "path_with_type": "Xã Cam Thượng, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09688",
        "parent_code": "271"
    },
    "09691": {
        "name": "Thuần Mỹ",
        "type": "xa",
        "slug": "thuan-my",
        "name_with_type": "Xã Thuần Mỹ",
        "path": "Thuần Mỹ, Ba Vì, Hà Nội",
        "path_with_type": "Xã Thuần Mỹ, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09691",
        "parent_code": "271"
    },
    "09694": {
        "name": "Tản Lĩnh",
        "type": "xa",
        "slug": "tan-linh",
        "name_with_type": "Xã Tản Lĩnh",
        "path": "Tản Lĩnh, Ba Vì, Hà Nội",
        "path_with_type": "Xã Tản Lĩnh, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09694",
        "parent_code": "271"
    },
    "09697": {
        "name": "Ba Trại",
        "type": "xa",
        "slug": "ba-trai",
        "name_with_type": "Xã Ba Trại",
        "path": "Ba Trại, Ba Vì, Hà Nội",
        "path_with_type": "Xã Ba Trại, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09697",
        "parent_code": "271"
    },
    "09700": {
        "name": "Minh Quang",
        "type": "xa",
        "slug": "minh-quang",
        "name_with_type": "Xã Minh Quang",
        "path": "Minh Quang, Ba Vì, Hà Nội",
        "path_with_type": "Xã Minh Quang, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09700",
        "parent_code": "271"
    },
    "09703": {
        "name": "Ba Vì",
        "type": "xa",
        "slug": "ba-vi",
        "name_with_type": "Xã Ba Vì",
        "path": "Ba Vì, Ba Vì, Hà Nội",
        "path_with_type": "Xã Ba Vì, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09703",
        "parent_code": "271"
    },
    "09706": {
        "name": "Vân Hòa",
        "type": "xa",
        "slug": "van-hoa",
        "name_with_type": "Xã Vân Hòa",
        "path": "Vân Hòa, Ba Vì, Hà Nội",
        "path_with_type": "Xã Vân Hòa, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09706",
        "parent_code": "271"
    },
    "09709": {
        "name": "Yên Bài",
        "type": "xa",
        "slug": "yen-bai",
        "name_with_type": "Xã Yên Bài",
        "path": "Yên Bài, Ba Vì, Hà Nội",
        "path_with_type": "Xã Yên Bài, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09709",
        "parent_code": "271"
    },
    "09712": {
        "name": "Khánh Thượng",
        "type": "xa",
        "slug": "khanh-thuong",
        "name_with_type": "Xã Khánh Thượng",
        "path": "Khánh Thượng, Ba Vì, Hà Nội",
        "path_with_type": "Xã Khánh Thượng, Huyện Ba Vì, Thành phố Hà Nội",
        "code": "09712",
        "parent_code": "271"
    },
    "09715": {
        "name": "Phúc Thọ",
        "type": "thi-tran",
        "slug": "phuc-tho",
        "name_with_type": "Thị trấn Phúc Thọ",
        "path": "Phúc Thọ, Phúc Thọ, Hà Nội",
        "path_with_type": "Thị trấn Phúc Thọ, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09715",
        "parent_code": "272"
    },
    "09718": {
        "name": "Vân Hà",
        "type": "xa",
        "slug": "van-ha",
        "name_with_type": "Xã Vân Hà",
        "path": "Vân Hà, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Vân Hà, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09718",
        "parent_code": "272"
    },
    "09721": {
        "name": "Vân Phúc",
        "type": "xa",
        "slug": "van-phuc",
        "name_with_type": "Xã Vân Phúc",
        "path": "Vân Phúc, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Vân Phúc, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09721",
        "parent_code": "272"
    },
    "09724": {
        "name": "Vân Nam",
        "type": "xa",
        "slug": "van-nam",
        "name_with_type": "Xã Vân Nam",
        "path": "Vân Nam, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Vân Nam, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09724",
        "parent_code": "272"
    },
    "09727": {
        "name": "Xuân Phú",
        "type": "xa",
        "slug": "xuan-phu",
        "name_with_type": "Xã Xuân Phú",
        "path": "Xuân Phú, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Xuân Phú, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09727",
        "parent_code": "272"
    },
    "09730": {
        "name": "Phương Độ",
        "type": "xa",
        "slug": "phuong-do",
        "name_with_type": "Xã Phương Độ",
        "path": "Phương Độ, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Phương Độ, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09730",
        "parent_code": "272"
    },
    "09733": {
        "name": "Sen Chiểu",
        "type": "xa",
        "slug": "sen-chieu",
        "name_with_type": "Xã Sen Chiểu",
        "path": "Sen Chiểu, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Sen Chiểu, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09733",
        "parent_code": "272"
    },
    "09736": {
        "name": "Cẩm Đình",
        "type": "xa",
        "slug": "cam-dinh",
        "name_with_type": "Xã Cẩm Đình",
        "path": "Cẩm Đình, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Cẩm Đình, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09736",
        "parent_code": "272"
    },
    "09739": {
        "name": "Võng Xuyên",
        "type": "xa",
        "slug": "vong-xuyen",
        "name_with_type": "Xã Võng Xuyên",
        "path": "Võng Xuyên, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Võng Xuyên, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09739",
        "parent_code": "272"
    },
    "09742": {
        "name": "Thọ Lộc",
        "type": "xa",
        "slug": "tho-loc",
        "name_with_type": "Xã Thọ Lộc",
        "path": "Thọ Lộc, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Thọ Lộc, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09742",
        "parent_code": "272"
    },
    "09745": {
        "name": "Long Xuyên",
        "type": "xa",
        "slug": "long-xuyen",
        "name_with_type": "Xã Long Xuyên",
        "path": "Long Xuyên, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Long Xuyên, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09745",
        "parent_code": "272"
    },
    "09748": {
        "name": "Thượng Cốc",
        "type": "xa",
        "slug": "thuong-coc",
        "name_with_type": "Xã Thượng Cốc",
        "path": "Thượng Cốc, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Thượng Cốc, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09748",
        "parent_code": "272"
    },
    "09751": {
        "name": "Hát Môn",
        "type": "xa",
        "slug": "hat-mon",
        "name_with_type": "Xã Hát Môn",
        "path": "Hát Môn, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Hát Môn, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09751",
        "parent_code": "272"
    },
    "09754": {
        "name": "Tích Giang",
        "type": "xa",
        "slug": "tich-giang",
        "name_with_type": "Xã Tích Giang",
        "path": "Tích Giang, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Tích Giang, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09754",
        "parent_code": "272"
    },
    "09757": {
        "name": "Thanh Đa",
        "type": "xa",
        "slug": "thanh-da",
        "name_with_type": "Xã Thanh Đa",
        "path": "Thanh Đa, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Thanh Đa, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09757",
        "parent_code": "272"
    },
    "09760": {
        "name": "Trạch Mỹ Lộc",
        "type": "xa",
        "slug": "trach-my-loc",
        "name_with_type": "Xã Trạch Mỹ Lộc",
        "path": "Trạch Mỹ Lộc, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Trạch Mỹ Lộc, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09760",
        "parent_code": "272"
    },
    "09763": {
        "name": "Phúc Hòa",
        "type": "xa",
        "slug": "phuc-hoa",
        "name_with_type": "Xã Phúc Hòa",
        "path": "Phúc Hòa, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Phúc Hòa, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09763",
        "parent_code": "272"
    },
    "09766": {
        "name": "Ngọc Tảo",
        "type": "xa",
        "slug": "ngoc-tao",
        "name_with_type": "Xã Ngọc Tảo",
        "path": "Ngọc Tảo, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Ngọc Tảo, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09766",
        "parent_code": "272"
    },
    "09769": {
        "name": "Phụng Thượng",
        "type": "xa",
        "slug": "phung-thuong",
        "name_with_type": "Xã Phụng Thượng",
        "path": "Phụng Thượng, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Phụng Thượng, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09769",
        "parent_code": "272"
    },
    "09772": {
        "name": "Tam Thuấn",
        "type": "xa",
        "slug": "tam-thuan",
        "name_with_type": "Xã Tam Thuấn",
        "path": "Tam Thuấn, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Tam Thuấn, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09772",
        "parent_code": "272"
    },
    "09775": {
        "name": "Tam Hiệp",
        "type": "xa",
        "slug": "tam-hiep",
        "name_with_type": "Xã Tam Hiệp",
        "path": "Tam Hiệp, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Tam Hiệp, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09775",
        "parent_code": "272"
    },
    "09778": {
        "name": "Hiệp Thuận",
        "type": "xa",
        "slug": "hiep-thuan",
        "name_with_type": "Xã Hiệp Thuận",
        "path": "Hiệp Thuận, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Hiệp Thuận, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09778",
        "parent_code": "272"
    },
    "09781": {
        "name": "Liên Hiệp",
        "type": "xa",
        "slug": "lien-hiep",
        "name_with_type": "Xã Liên Hiệp",
        "path": "Liên Hiệp, Phúc Thọ, Hà Nội",
        "path_with_type": "Xã Liên Hiệp, Huyện Phúc Thọ, Thành phố Hà Nội",
        "code": "09781",
        "parent_code": "272"
    },
    "09784": {
        "name": "Phùng",
        "type": "thi-tran",
        "slug": "phung",
        "name_with_type": "Thị trấn Phùng",
        "path": "Phùng, Đan Phượng, Hà Nội",
        "path_with_type": "Thị trấn Phùng, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09784",
        "parent_code": "273"
    },
    "09787": {
        "name": "Trung Châu",
        "type": "xa",
        "slug": "trung-chau",
        "name_with_type": "Xã Trung Châu",
        "path": "Trung Châu, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Trung Châu, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09787",
        "parent_code": "273"
    },
    "09790": {
        "name": "Thọ An",
        "type": "xa",
        "slug": "tho-an",
        "name_with_type": "Xã Thọ An",
        "path": "Thọ An, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Thọ An, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09790",
        "parent_code": "273"
    },
    "09793": {
        "name": "Thọ Xuân",
        "type": "xa",
        "slug": "tho-xuan",
        "name_with_type": "Xã Thọ Xuân",
        "path": "Thọ Xuân, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Thọ Xuân, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09793",
        "parent_code": "273"
    },
    "09796": {
        "name": "Hồng Hà",
        "type": "xa",
        "slug": "hong-ha",
        "name_with_type": "Xã Hồng Hà",
        "path": "Hồng Hà, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Hồng Hà, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09796",
        "parent_code": "273"
    },
    "09799": {
        "name": "Liên Hồng",
        "type": "xa",
        "slug": "lien-hong",
        "name_with_type": "Xã Liên Hồng",
        "path": "Liên Hồng, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Liên Hồng, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09799",
        "parent_code": "273"
    },
    "09802": {
        "name": "Liên Hà",
        "type": "xa",
        "slug": "lien-ha",
        "name_with_type": "Xã Liên Hà",
        "path": "Liên Hà, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Liên Hà, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09802",
        "parent_code": "273"
    },
    "09805": {
        "name": "Hạ Mỗ",
        "type": "xa",
        "slug": "ha-mo",
        "name_with_type": "Xã Hạ Mỗ",
        "path": "Hạ Mỗ, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Hạ Mỗ, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09805",
        "parent_code": "273"
    },
    "09808": {
        "name": "Liên Trung",
        "type": "xa",
        "slug": "lien-trung",
        "name_with_type": "Xã Liên Trung",
        "path": "Liên Trung, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Liên Trung, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09808",
        "parent_code": "273"
    },
    "09811": {
        "name": "Phương Đình",
        "type": "xa",
        "slug": "phuong-dinh",
        "name_with_type": "Xã Phương Đình",
        "path": "Phương Đình, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Phương Đình, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09811",
        "parent_code": "273"
    },
    "09814": {
        "name": "Thượng Mỗ",
        "type": "xa",
        "slug": "thuong-mo",
        "name_with_type": "Xã Thượng Mỗ",
        "path": "Thượng Mỗ, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Thượng Mỗ, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09814",
        "parent_code": "273"
    },
    "09817": {
        "name": "Tân Hội",
        "type": "xa",
        "slug": "tan-hoi",
        "name_with_type": "Xã Tân Hội",
        "path": "Tân Hội, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Tân Hội, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09817",
        "parent_code": "273"
    },
    "09820": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Tân Lập, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09820",
        "parent_code": "273"
    },
    "09823": {
        "name": "Đan Phượng",
        "type": "xa",
        "slug": "dan-phuong",
        "name_with_type": "Xã Đan Phượng",
        "path": "Đan Phượng, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Đan Phượng, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09823",
        "parent_code": "273"
    },
    "09826": {
        "name": "Đồng Tháp",
        "type": "xa",
        "slug": "dong-thap",
        "name_with_type": "Xã Đồng Tháp",
        "path": "Đồng Tháp, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Đồng Tháp, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09826",
        "parent_code": "273"
    },
    "09829": {
        "name": "Song Phượng",
        "type": "xa",
        "slug": "song-phuong",
        "name_with_type": "Xã Song Phượng",
        "path": "Song Phượng, Đan Phượng, Hà Nội",
        "path_with_type": "Xã Song Phượng, Huyện Đan Phượng, Thành phố Hà Nội",
        "code": "09829",
        "parent_code": "273"
    },
    "09832": {
        "name": "Trạm Trôi",
        "type": "thi-tran",
        "slug": "tram-troi",
        "name_with_type": "Thị trấn Trạm Trôi",
        "path": "Trạm Trôi, Hoài Đức, Hà Nội",
        "path_with_type": "Thị trấn Trạm Trôi, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09832",
        "parent_code": "274"
    },
    "09835": {
        "name": "Đức Thượng",
        "type": "xa",
        "slug": "duc-thuong",
        "name_with_type": "Xã Đức Thượng",
        "path": "Đức Thượng, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Đức Thượng, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09835",
        "parent_code": "274"
    },
    "09838": {
        "name": "Minh Khai",
        "type": "xa",
        "slug": "minh-khai",
        "name_with_type": "Xã Minh Khai",
        "path": "Minh Khai, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Minh Khai, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09838",
        "parent_code": "274"
    },
    "09841": {
        "name": "Dương Liễu",
        "type": "xa",
        "slug": "duong-lieu",
        "name_with_type": "Xã Dương Liễu",
        "path": "Dương Liễu, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Dương Liễu, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09841",
        "parent_code": "274"
    },
    "09844": {
        "name": "Di Trạch",
        "type": "xa",
        "slug": "di-trach",
        "name_with_type": "Xã Di Trạch",
        "path": "Di Trạch, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Di Trạch, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09844",
        "parent_code": "274"
    },
    "09847": {
        "name": "Đức Giang",
        "type": "xa",
        "slug": "duc-giang",
        "name_with_type": "Xã Đức Giang",
        "path": "Đức Giang, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Đức Giang, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09847",
        "parent_code": "274"
    },
    "09850": {
        "name": "Cát Quế",
        "type": "xa",
        "slug": "cat-que",
        "name_with_type": "Xã Cát Quế",
        "path": "Cát Quế, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Cát Quế, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09850",
        "parent_code": "274"
    },
    "09853": {
        "name": "Kim Chung",
        "type": "xa",
        "slug": "kim-chung",
        "name_with_type": "Xã Kim Chung",
        "path": "Kim Chung, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Kim Chung, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09853",
        "parent_code": "274"
    },
    "09856": {
        "name": "Yên Sở",
        "type": "xa",
        "slug": "yen-so",
        "name_with_type": "Xã Yên Sở",
        "path": "Yên Sở, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Yên Sở, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09856",
        "parent_code": "274"
    },
    "09859": {
        "name": "Sơn Đồng",
        "type": "xa",
        "slug": "son-dong",
        "name_with_type": "Xã Sơn Đồng",
        "path": "Sơn Đồng, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Sơn Đồng, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09859",
        "parent_code": "274"
    },
    "09862": {
        "name": "Vân Canh",
        "type": "xa",
        "slug": "van-canh",
        "name_with_type": "Xã Vân Canh",
        "path": "Vân Canh, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Vân Canh, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09862",
        "parent_code": "274"
    },
    "09865": {
        "name": "Đắc Sở",
        "type": "xa",
        "slug": "dac-so",
        "name_with_type": "Xã Đắc Sở",
        "path": "Đắc Sở, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Đắc Sở, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09865",
        "parent_code": "274"
    },
    "09868": {
        "name": "Lại Yên",
        "type": "xa",
        "slug": "lai-yen",
        "name_with_type": "Xã Lại Yên",
        "path": "Lại Yên, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Lại Yên, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09868",
        "parent_code": "274"
    },
    "09871": {
        "name": "Tiền Yên",
        "type": "xa",
        "slug": "tien-yen",
        "name_with_type": "Xã Tiền Yên",
        "path": "Tiền Yên, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Tiền Yên, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09871",
        "parent_code": "274"
    },
    "09874": {
        "name": "Song Phương",
        "type": "xa",
        "slug": "song-phuong",
        "name_with_type": "Xã Song Phương",
        "path": "Song Phương, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Song Phương, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09874",
        "parent_code": "274"
    },
    "09877": {
        "name": "An Khánh",
        "type": "xa",
        "slug": "an-khanh",
        "name_with_type": "Xã An Khánh",
        "path": "An Khánh, Hoài Đức, Hà Nội",
        "path_with_type": "Xã An Khánh, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09877",
        "parent_code": "274"
    },
    "09880": {
        "name": "An Thượng",
        "type": "xa",
        "slug": "an-thuong",
        "name_with_type": "Xã An Thượng",
        "path": "An Thượng, Hoài Đức, Hà Nội",
        "path_with_type": "Xã An Thượng, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09880",
        "parent_code": "274"
    },
    "09883": {
        "name": "Vân Côn",
        "type": "xa",
        "slug": "van-con",
        "name_with_type": "Xã Vân Côn",
        "path": "Vân Côn, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Vân Côn, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09883",
        "parent_code": "274"
    },
    "09889": {
        "name": "La Phù",
        "type": "xa",
        "slug": "la-phu",
        "name_with_type": "Xã La Phù",
        "path": "La Phù, Hoài Đức, Hà Nội",
        "path_with_type": "Xã La Phù, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09889",
        "parent_code": "274"
    },
    "09892": {
        "name": "Đông La",
        "type": "xa",
        "slug": "dong-la",
        "name_with_type": "Xã Đông La",
        "path": "Đông La, Hoài Đức, Hà Nội",
        "path_with_type": "Xã Đông La, Huyện Hoài Đức, Thành phố Hà Nội",
        "code": "09892",
        "parent_code": "274"
    },
    "04939": {
        "name": "Đông Xuân",
        "type": "xa",
        "slug": "dong-xuan",
        "name_with_type": "Xã Đông Xuân",
        "path": "Đông Xuân, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Đông Xuân, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "04939",
        "parent_code": "275"
    },
    "09895": {
        "name": "Quốc Oai",
        "type": "thi-tran",
        "slug": "quoc-oai",
        "name_with_type": "Thị trấn Quốc Oai",
        "path": "Quốc Oai, Quốc Oai, Hà Nội",
        "path_with_type": "Thị trấn Quốc Oai, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09895",
        "parent_code": "275"
    },
    "09898": {
        "name": "Sài Sơn",
        "type": "xa",
        "slug": "sai-son",
        "name_with_type": "Xã Sài Sơn",
        "path": "Sài Sơn, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Sài Sơn, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09898",
        "parent_code": "275"
    },
    "09901": {
        "name": "Phượng Cách",
        "type": "xa",
        "slug": "phuong-cach",
        "name_with_type": "Xã Phượng Cách",
        "path": "Phượng Cách, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Phượng Cách, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09901",
        "parent_code": "275"
    },
    "09904": {
        "name": "Yên Sơn",
        "type": "xa",
        "slug": "yen-son",
        "name_with_type": "Xã Yên Sơn",
        "path": "Yên Sơn, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Yên Sơn, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09904",
        "parent_code": "275"
    },
    "09907": {
        "name": "Ngọc Liệp",
        "type": "xa",
        "slug": "ngoc-liep",
        "name_with_type": "Xã Ngọc Liệp",
        "path": "Ngọc Liệp, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Ngọc Liệp, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09907",
        "parent_code": "275"
    },
    "09910": {
        "name": "Ngọc Mỹ",
        "type": "xa",
        "slug": "ngoc-my",
        "name_with_type": "Xã Ngọc Mỹ",
        "path": "Ngọc Mỹ, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Ngọc Mỹ, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09910",
        "parent_code": "275"
    },
    "09913": {
        "name": "Liệp Tuyết",
        "type": "xa",
        "slug": "liep-tuyet",
        "name_with_type": "Xã Liệp Tuyết",
        "path": "Liệp Tuyết, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Liệp Tuyết, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09913",
        "parent_code": "275"
    },
    "09916": {
        "name": "Thạch Thán",
        "type": "xa",
        "slug": "thach-than",
        "name_with_type": "Xã Thạch Thán",
        "path": "Thạch Thán, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Thạch Thán, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09916",
        "parent_code": "275"
    },
    "09919": {
        "name": "Đồng Quang",
        "type": "xa",
        "slug": "dong-quang",
        "name_with_type": "Xã Đồng Quang",
        "path": "Đồng Quang, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Đồng Quang, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09919",
        "parent_code": "275"
    },
    "09922": {
        "name": "Phú Cát",
        "type": "xa",
        "slug": "phu-cat",
        "name_with_type": "Xã Phú Cát",
        "path": "Phú Cát, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Phú Cát, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09922",
        "parent_code": "275"
    },
    "09925": {
        "name": "Tuyết Nghĩa",
        "type": "xa",
        "slug": "tuyet-nghia",
        "name_with_type": "Xã Tuyết Nghĩa",
        "path": "Tuyết Nghĩa, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Tuyết Nghĩa, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09925",
        "parent_code": "275"
    },
    "09928": {
        "name": "Nghĩa Hương",
        "type": "xa",
        "slug": "nghia-huong",
        "name_with_type": "Xã Nghĩa Hương",
        "path": "Nghĩa Hương, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Nghĩa Hương, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09928",
        "parent_code": "275"
    },
    "09931": {
        "name": "Cộng Hòa",
        "type": "xa",
        "slug": "cong-hoa",
        "name_with_type": "Xã Cộng Hòa",
        "path": "Cộng Hòa, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Cộng Hòa, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09931",
        "parent_code": "275"
    },
    "09934": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Tân Phú, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09934",
        "parent_code": "275"
    },
    "09937": {
        "name": "Đại Thành",
        "type": "xa",
        "slug": "dai-thanh",
        "name_with_type": "Xã Đại Thành",
        "path": "Đại Thành, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Đại Thành, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09937",
        "parent_code": "275"
    },
    "09940": {
        "name": "Phú Mãn",
        "type": "xa",
        "slug": "phu-man",
        "name_with_type": "Xã Phú Mãn",
        "path": "Phú Mãn, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Phú Mãn, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09940",
        "parent_code": "275"
    },
    "09943": {
        "name": "Cấn Hữu",
        "type": "xa",
        "slug": "can-huu",
        "name_with_type": "Xã Cấn Hữu",
        "path": "Cấn Hữu, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Cấn Hữu, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09943",
        "parent_code": "275"
    },
    "09946": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Tân Hòa, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09946",
        "parent_code": "275"
    },
    "09949": {
        "name": "Hòa Thạch",
        "type": "xa",
        "slug": "hoa-thach",
        "name_with_type": "Xã Hòa Thạch",
        "path": "Hòa Thạch, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Hòa Thạch, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09949",
        "parent_code": "275"
    },
    "09952": {
        "name": "Đông Yên",
        "type": "xa",
        "slug": "dong-yen",
        "name_with_type": "Xã Đông Yên",
        "path": "Đông Yên, Quốc Oai, Hà Nội",
        "path_with_type": "Xã Đông Yên, Huyện Quốc Oai, Thành phố Hà Nội",
        "code": "09952",
        "parent_code": "275"
    },
    "04927": {
        "name": "Yên Trung",
        "type": "xa",
        "slug": "yen-trung",
        "name_with_type": "Xã Yên Trung",
        "path": "Yên Trung, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Yên Trung, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "04927",
        "parent_code": "276"
    },
    "04930": {
        "name": "Yên Bình",
        "type": "xa",
        "slug": "yen-binh",
        "name_with_type": "Xã Yên Bình",
        "path": "Yên Bình, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Yên Bình, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "04930",
        "parent_code": "276"
    },
    "04936": {
        "name": "Tiến Xuân",
        "type": "xa",
        "slug": "tien-xuan",
        "name_with_type": "Xã Tiến Xuân",
        "path": "Tiến Xuân, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Tiến Xuân, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "04936",
        "parent_code": "276"
    },
    "09955": {
        "name": "Liên Quan",
        "type": "thi-tran",
        "slug": "lien-quan",
        "name_with_type": "Thị trấn Liên Quan",
        "path": "Liên Quan, Thạch Thất, Hà Nội",
        "path_with_type": "Thị trấn Liên Quan, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09955",
        "parent_code": "276"
    },
    "09958": {
        "name": "Đại Đồng",
        "type": "xa",
        "slug": "dai-dong",
        "name_with_type": "Xã Đại Đồng",
        "path": "Đại Đồng, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Đại Đồng, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09958",
        "parent_code": "276"
    },
    "09961": {
        "name": "Cẩm Yên",
        "type": "xa",
        "slug": "cam-yen",
        "name_with_type": "Xã Cẩm Yên",
        "path": "Cẩm Yên, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Cẩm Yên, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09961",
        "parent_code": "276"
    },
    "09964": {
        "name": "Lại Thượng",
        "type": "xa",
        "slug": "lai-thuong",
        "name_with_type": "Xã Lại Thượng",
        "path": "Lại Thượng, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Lại Thượng, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09964",
        "parent_code": "276"
    },
    "09967": {
        "name": "Phú Kim",
        "type": "xa",
        "slug": "phu-kim",
        "name_with_type": "Xã Phú Kim",
        "path": "Phú Kim, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Phú Kim, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09967",
        "parent_code": "276"
    },
    "09970": {
        "name": "Hương Ngải",
        "type": "xa",
        "slug": "huong-ngai",
        "name_with_type": "Xã Hương Ngải",
        "path": "Hương Ngải, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Hương Ngải, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09970",
        "parent_code": "276"
    },
    "09973": {
        "name": "Canh Nậu",
        "type": "xa",
        "slug": "canh-nau",
        "name_with_type": "Xã Canh Nậu",
        "path": "Canh Nậu, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Canh Nậu, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09973",
        "parent_code": "276"
    },
    "09976": {
        "name": "Kim Quan",
        "type": "xa",
        "slug": "kim-quan",
        "name_with_type": "Xã Kim Quan",
        "path": "Kim Quan, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Kim Quan, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09976",
        "parent_code": "276"
    },
    "09979": {
        "name": "Dị Nậu",
        "type": "xa",
        "slug": "di-nau",
        "name_with_type": "Xã Dị Nậu",
        "path": "Dị Nậu, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Dị Nậu, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09979",
        "parent_code": "276"
    },
    "09982": {
        "name": "Bình Yên",
        "type": "xa",
        "slug": "binh-yen",
        "name_with_type": "Xã Bình Yên",
        "path": "Bình Yên, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Bình Yên, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09982",
        "parent_code": "276"
    },
    "09985": {
        "name": "Chàng Sơn",
        "type": "xa",
        "slug": "chang-son",
        "name_with_type": "Xã Chàng Sơn",
        "path": "Chàng Sơn, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Chàng Sơn, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09985",
        "parent_code": "276"
    },
    "09988": {
        "name": "Thạch Hoà",
        "type": "xa",
        "slug": "thach-hoa",
        "name_with_type": "Xã Thạch Hoà",
        "path": "Thạch Hoà, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Thạch Hoà, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09988",
        "parent_code": "276"
    },
    "09991": {
        "name": "Cần Kiệm",
        "type": "xa",
        "slug": "can-kiem",
        "name_with_type": "Xã Cần Kiệm",
        "path": "Cần Kiệm, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Cần Kiệm, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09991",
        "parent_code": "276"
    },
    "09994": {
        "name": "Hữu Bằng",
        "type": "xa",
        "slug": "huu-bang",
        "name_with_type": "Xã Hữu Bằng",
        "path": "Hữu Bằng, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Hữu Bằng, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09994",
        "parent_code": "276"
    },
    "09997": {
        "name": "Phùng Xá",
        "type": "xa",
        "slug": "phung-xa",
        "name_with_type": "Xã Phùng Xá",
        "path": "Phùng Xá, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Phùng Xá, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "09997",
        "parent_code": "276"
    },
    "10000": {
        "name": "Tân Xã",
        "type": "xa",
        "slug": "tan-xa",
        "name_with_type": "Xã Tân Xã",
        "path": "Tân Xã, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Tân Xã, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "10000",
        "parent_code": "276"
    },
    "10003": {
        "name": "Thạch Xá",
        "type": "xa",
        "slug": "thach-xa",
        "name_with_type": "Xã Thạch Xá",
        "path": "Thạch Xá, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Thạch Xá, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "10003",
        "parent_code": "276"
    },
    "10006": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Bình Phú, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "10006",
        "parent_code": "276"
    },
    "10009": {
        "name": "Hạ Bằng",
        "type": "xa",
        "slug": "ha-bang",
        "name_with_type": "Xã Hạ Bằng",
        "path": "Hạ Bằng, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Hạ Bằng, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "10009",
        "parent_code": "276"
    },
    "10012": {
        "name": "Đồng Trúc",
        "type": "xa",
        "slug": "dong-truc",
        "name_with_type": "Xã Đồng Trúc",
        "path": "Đồng Trúc, Thạch Thất, Hà Nội",
        "path_with_type": "Xã Đồng Trúc, Huyện Thạch Thất, Thành phố Hà Nội",
        "code": "10012",
        "parent_code": "276"
    },
    "10015": {
        "name": "Chúc Sơn",
        "type": "thi-tran",
        "slug": "chuc-son",
        "name_with_type": "Thị trấn Chúc Sơn",
        "path": "Chúc Sơn, Chương Mỹ, Hà Nội",
        "path_with_type": "Thị trấn Chúc Sơn, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10015",
        "parent_code": "277"
    },
    "10018": {
        "name": "Xuân Mai",
        "type": "thi-tran",
        "slug": "xuan-mai",
        "name_with_type": "Thị trấn Xuân Mai",
        "path": "Xuân Mai, Chương Mỹ, Hà Nội",
        "path_with_type": "Thị trấn Xuân Mai, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10018",
        "parent_code": "277"
    },
    "10021": {
        "name": "Phụng Châu",
        "type": "xa",
        "slug": "phung-chau",
        "name_with_type": "Xã Phụng Châu",
        "path": "Phụng Châu, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Phụng Châu, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10021",
        "parent_code": "277"
    },
    "10024": {
        "name": "Tiên Phương",
        "type": "xa",
        "slug": "tien-phuong",
        "name_with_type": "Xã Tiên Phương",
        "path": "Tiên Phương, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Tiên Phương, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10024",
        "parent_code": "277"
    },
    "10027": {
        "name": "Đông Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đông Sơn",
        "path": "Đông Sơn, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Đông Sơn, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10027",
        "parent_code": "277"
    },
    "10030": {
        "name": "Đông Phương Yên",
        "type": "xa",
        "slug": "dong-phuong-yen",
        "name_with_type": "Xã Đông Phương Yên",
        "path": "Đông Phương Yên, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Đông Phương Yên, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10030",
        "parent_code": "277"
    },
    "10033": {
        "name": "Phú Nghĩa",
        "type": "xa",
        "slug": "phu-nghia",
        "name_with_type": "Xã Phú Nghĩa",
        "path": "Phú Nghĩa, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Phú Nghĩa, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10033",
        "parent_code": "277"
    },
    "10039": {
        "name": "Trường Yên",
        "type": "xa",
        "slug": "truong-yen",
        "name_with_type": "Xã Trường Yên",
        "path": "Trường Yên, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Trường Yên, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10039",
        "parent_code": "277"
    },
    "10042": {
        "name": "Ngọc Hòa",
        "type": "xa",
        "slug": "ngoc-hoa",
        "name_with_type": "Xã Ngọc Hòa",
        "path": "Ngọc Hòa, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Ngọc Hòa, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10042",
        "parent_code": "277"
    },
    "10045": {
        "name": "Thủy Xuân Tiên",
        "type": "xa",
        "slug": "thuy-xuan-tien",
        "name_with_type": "Xã Thủy Xuân Tiên",
        "path": "Thủy Xuân Tiên, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Thủy Xuân Tiên, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10045",
        "parent_code": "277"
    },
    "10048": {
        "name": "Thanh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thanh Bình",
        "path": "Thanh Bình, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Thanh Bình, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10048",
        "parent_code": "277"
    },
    "10051": {
        "name": "Trung Hòa",
        "type": "xa",
        "slug": "trung-hoa",
        "name_with_type": "Xã Trung Hòa",
        "path": "Trung Hòa, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Trung Hòa, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10051",
        "parent_code": "277"
    },
    "10054": {
        "name": "Đại Yên",
        "type": "xa",
        "slug": "dai-yen",
        "name_with_type": "Xã Đại Yên",
        "path": "Đại Yên, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Đại Yên, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10054",
        "parent_code": "277"
    },
    "10057": {
        "name": "Thụy Hương",
        "type": "xa",
        "slug": "thuy-huong",
        "name_with_type": "Xã Thụy Hương",
        "path": "Thụy Hương, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Thụy Hương, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10057",
        "parent_code": "277"
    },
    "10060": {
        "name": "Tốt Động",
        "type": "xa",
        "slug": "tot-dong",
        "name_with_type": "Xã Tốt Động",
        "path": "Tốt Động, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Tốt Động, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10060",
        "parent_code": "277"
    },
    "10063": {
        "name": "Lam Điền",
        "type": "xa",
        "slug": "lam-dien",
        "name_with_type": "Xã Lam Điền",
        "path": "Lam Điền, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Lam Điền, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10063",
        "parent_code": "277"
    },
    "10066": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Tân Tiến, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10066",
        "parent_code": "277"
    },
    "10069": {
        "name": "Nam Phương Tiến",
        "type": "xa",
        "slug": "nam-phuong-tien",
        "name_with_type": "Xã Nam Phương Tiến",
        "path": "Nam Phương Tiến, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Nam Phương Tiến, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10069",
        "parent_code": "277"
    },
    "10072": {
        "name": "Hợp Đồng",
        "type": "xa",
        "slug": "hop-dong",
        "name_with_type": "Xã Hợp Đồng",
        "path": "Hợp Đồng, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Hợp Đồng, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10072",
        "parent_code": "277"
    },
    "10075": {
        "name": "Hoàng Văn Thụ",
        "type": "xa",
        "slug": "hoang-van-thu",
        "name_with_type": "Xã Hoàng Văn Thụ",
        "path": "Hoàng Văn Thụ, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Hoàng Văn Thụ, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10075",
        "parent_code": "277"
    },
    "10078": {
        "name": "Hoàng Diệu",
        "type": "xa",
        "slug": "hoang-dieu",
        "name_with_type": "Xã Hoàng Diệu",
        "path": "Hoàng Diệu, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Hoàng Diệu, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10078",
        "parent_code": "277"
    },
    "10081": {
        "name": "Hữu Văn",
        "type": "xa",
        "slug": "huu-van",
        "name_with_type": "Xã Hữu Văn",
        "path": "Hữu Văn, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Hữu Văn, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10081",
        "parent_code": "277"
    },
    "10084": {
        "name": "Quảng Bị",
        "type": "xa",
        "slug": "quang-bi",
        "name_with_type": "Xã Quảng Bị",
        "path": "Quảng Bị, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Quảng Bị, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10084",
        "parent_code": "277"
    },
    "10087": {
        "name": "Mỹ Lương",
        "type": "xa",
        "slug": "my-luong",
        "name_with_type": "Xã Mỹ Lương",
        "path": "Mỹ Lương, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Mỹ Lương, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10087",
        "parent_code": "277"
    },
    "10090": {
        "name": "Thượng Vực",
        "type": "xa",
        "slug": "thuong-vuc",
        "name_with_type": "Xã Thượng Vực",
        "path": "Thượng Vực, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Thượng Vực, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10090",
        "parent_code": "277"
    },
    "10093": {
        "name": "Hồng Phong",
        "type": "xa",
        "slug": "hong-phong",
        "name_with_type": "Xã Hồng Phong",
        "path": "Hồng Phong, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Hồng Phong, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10093",
        "parent_code": "277"
    },
    "10096": {
        "name": "Đồng Phú",
        "type": "xa",
        "slug": "dong-phu",
        "name_with_type": "Xã Đồng Phú",
        "path": "Đồng Phú, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Đồng Phú, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10096",
        "parent_code": "277"
    },
    "10099": {
        "name": "Trần Phú",
        "type": "xa",
        "slug": "tran-phu",
        "name_with_type": "Xã Trần Phú",
        "path": "Trần Phú, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Trần Phú, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10099",
        "parent_code": "277"
    },
    "10102": {
        "name": "Văn Võ",
        "type": "xa",
        "slug": "van-vo",
        "name_with_type": "Xã Văn Võ",
        "path": "Văn Võ, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Văn Võ, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10102",
        "parent_code": "277"
    },
    "10105": {
        "name": "Đồng Lạc",
        "type": "xa",
        "slug": "dong-lac",
        "name_with_type": "Xã Đồng Lạc",
        "path": "Đồng Lạc, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Đồng Lạc, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10105",
        "parent_code": "277"
    },
    "10108": {
        "name": "Hòa Chính",
        "type": "xa",
        "slug": "hoa-chinh",
        "name_with_type": "Xã Hòa Chính",
        "path": "Hòa Chính, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Hòa Chính, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10108",
        "parent_code": "277"
    },
    "10111": {
        "name": "Phú Nam An",
        "type": "xa",
        "slug": "phu-nam-an",
        "name_with_type": "Xã Phú Nam An",
        "path": "Phú Nam An, Chương Mỹ, Hà Nội",
        "path_with_type": "Xã Phú Nam An, Huyện Chương Mỹ, Thành phố Hà Nội",
        "code": "10111",
        "parent_code": "277"
    },
    "10114": {
        "name": "Kim Bài",
        "type": "thi-tran",
        "slug": "kim-bai",
        "name_with_type": "Thị trấn Kim Bài",
        "path": "Kim Bài, Thanh Oai, Hà Nội",
        "path_with_type": "Thị trấn Kim Bài, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10114",
        "parent_code": "278"
    },
    "10120": {
        "name": "Cự Khê",
        "type": "xa",
        "slug": "cu-khe",
        "name_with_type": "Xã Cự Khê",
        "path": "Cự Khê, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Cự Khê, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10120",
        "parent_code": "278"
    },
    "10126": {
        "name": "Bích Hòa",
        "type": "xa",
        "slug": "bich-hoa",
        "name_with_type": "Xã Bích Hòa",
        "path": "Bích Hòa, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Bích Hòa, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10126",
        "parent_code": "278"
    },
    "10129": {
        "name": "Mỹ Hưng",
        "type": "xa",
        "slug": "my-hung",
        "name_with_type": "Xã Mỹ Hưng",
        "path": "Mỹ Hưng, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Mỹ Hưng, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10129",
        "parent_code": "278"
    },
    "10132": {
        "name": "Cao Viên",
        "type": "xa",
        "slug": "cao-vien",
        "name_with_type": "Xã Cao Viên",
        "path": "Cao Viên, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Cao Viên, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10132",
        "parent_code": "278"
    },
    "10135": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Bình Minh, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10135",
        "parent_code": "278"
    },
    "10138": {
        "name": "Tam Hưng",
        "type": "xa",
        "slug": "tam-hung",
        "name_with_type": "Xã Tam Hưng",
        "path": "Tam Hưng, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Tam Hưng, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10138",
        "parent_code": "278"
    },
    "10141": {
        "name": "Thanh Cao",
        "type": "xa",
        "slug": "thanh-cao",
        "name_with_type": "Xã Thanh Cao",
        "path": "Thanh Cao, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Thanh Cao, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10141",
        "parent_code": "278"
    },
    "10144": {
        "name": "Thanh Thùy",
        "type": "xa",
        "slug": "thanh-thuy",
        "name_with_type": "Xã Thanh Thùy",
        "path": "Thanh Thùy, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Thanh Thùy, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10144",
        "parent_code": "278"
    },
    "10147": {
        "name": "Thanh Mai",
        "type": "xa",
        "slug": "thanh-mai",
        "name_with_type": "Xã Thanh Mai",
        "path": "Thanh Mai, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Thanh Mai, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10147",
        "parent_code": "278"
    },
    "10150": {
        "name": "Thanh Văn",
        "type": "xa",
        "slug": "thanh-van",
        "name_with_type": "Xã Thanh Văn",
        "path": "Thanh Văn, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Thanh Văn, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10150",
        "parent_code": "278"
    },
    "10153": {
        "name": "Đỗ Động",
        "type": "xa",
        "slug": "do-dong",
        "name_with_type": "Xã Đỗ Động",
        "path": "Đỗ Động, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Đỗ Động, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10153",
        "parent_code": "278"
    },
    "10156": {
        "name": "Kim An",
        "type": "xa",
        "slug": "kim-an",
        "name_with_type": "Xã Kim An",
        "path": "Kim An, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Kim An, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10156",
        "parent_code": "278"
    },
    "10159": {
        "name": "Kim Thư",
        "type": "xa",
        "slug": "kim-thu",
        "name_with_type": "Xã Kim Thư",
        "path": "Kim Thư, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Kim Thư, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10159",
        "parent_code": "278"
    },
    "10162": {
        "name": "Phương Trung",
        "type": "xa",
        "slug": "phuong-trung",
        "name_with_type": "Xã Phương Trung",
        "path": "Phương Trung, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Phương Trung, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10162",
        "parent_code": "278"
    },
    "10165": {
        "name": "Tân Ước",
        "type": "xa",
        "slug": "tan-uoc",
        "name_with_type": "Xã Tân Ước",
        "path": "Tân Ước, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Tân Ước, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10165",
        "parent_code": "278"
    },
    "10168": {
        "name": "Dân Hòa",
        "type": "xa",
        "slug": "dan-hoa",
        "name_with_type": "Xã Dân Hòa",
        "path": "Dân Hòa, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Dân Hòa, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10168",
        "parent_code": "278"
    },
    "10171": {
        "name": "Liên Châu",
        "type": "xa",
        "slug": "lien-chau",
        "name_with_type": "Xã Liên Châu",
        "path": "Liên Châu, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Liên Châu, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10171",
        "parent_code": "278"
    },
    "10174": {
        "name": "Cao Dương",
        "type": "xa",
        "slug": "cao-duong",
        "name_with_type": "Xã Cao Dương",
        "path": "Cao Dương, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Cao Dương, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10174",
        "parent_code": "278"
    },
    "10177": {
        "name": "Xuân Dương",
        "type": "xa",
        "slug": "xuan-duong",
        "name_with_type": "Xã Xuân Dương",
        "path": "Xuân Dương, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Xuân Dương, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10177",
        "parent_code": "278"
    },
    "10180": {
        "name": "Hồng Dương",
        "type": "xa",
        "slug": "hong-duong",
        "name_with_type": "Xã Hồng Dương",
        "path": "Hồng Dương, Thanh Oai, Hà Nội",
        "path_with_type": "Xã Hồng Dương, Huyện Thanh Oai, Thành phố Hà Nội",
        "code": "10180",
        "parent_code": "278"
    },
    "10183": {
        "name": "Thường Tín",
        "type": "thi-tran",
        "slug": "thuong-tin",
        "name_with_type": "Thị trấn Thường Tín",
        "path": "Thường Tín, Thường Tín, Hà Nội",
        "path_with_type": "Thị trấn Thường Tín, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10183",
        "parent_code": "279"
    },
    "10186": {
        "name": "Ninh Sở",
        "type": "xa",
        "slug": "ninh-so",
        "name_with_type": "Xã Ninh Sở",
        "path": "Ninh Sở, Thường Tín, Hà Nội",
        "path_with_type": "Xã Ninh Sở, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10186",
        "parent_code": "279"
    },
    "10189": {
        "name": "Nhị Khê",
        "type": "xa",
        "slug": "nhi-khe",
        "name_with_type": "Xã Nhị Khê",
        "path": "Nhị Khê, Thường Tín, Hà Nội",
        "path_with_type": "Xã Nhị Khê, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10189",
        "parent_code": "279"
    },
    "10192": {
        "name": "Duyên Thái",
        "type": "xa",
        "slug": "duyen-thai",
        "name_with_type": "Xã Duyên Thái",
        "path": "Duyên Thái, Thường Tín, Hà Nội",
        "path_with_type": "Xã Duyên Thái, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10192",
        "parent_code": "279"
    },
    "10195": {
        "name": "Khánh Hà",
        "type": "xa",
        "slug": "khanh-ha",
        "name_with_type": "Xã Khánh Hà",
        "path": "Khánh Hà, Thường Tín, Hà Nội",
        "path_with_type": "Xã Khánh Hà, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10195",
        "parent_code": "279"
    },
    "10198": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Thường Tín, Hà Nội",
        "path_with_type": "Xã Hòa Bình, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10198",
        "parent_code": "279"
    },
    "10201": {
        "name": "Văn Bình",
        "type": "xa",
        "slug": "van-binh",
        "name_with_type": "Xã Văn Bình",
        "path": "Văn Bình, Thường Tín, Hà Nội",
        "path_with_type": "Xã Văn Bình, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10201",
        "parent_code": "279"
    },
    "10204": {
        "name": "Hiền Giang",
        "type": "xa",
        "slug": "hien-giang",
        "name_with_type": "Xã Hiền Giang",
        "path": "Hiền Giang, Thường Tín, Hà Nội",
        "path_with_type": "Xã Hiền Giang, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10204",
        "parent_code": "279"
    },
    "10207": {
        "name": "Hồng Vân",
        "type": "xa",
        "slug": "hong-van",
        "name_with_type": "Xã Hồng Vân",
        "path": "Hồng Vân, Thường Tín, Hà Nội",
        "path_with_type": "Xã Hồng Vân, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10207",
        "parent_code": "279"
    },
    "10210": {
        "name": "Vân Tảo",
        "type": "xa",
        "slug": "van-tao",
        "name_with_type": "Xã Vân Tảo",
        "path": "Vân Tảo, Thường Tín, Hà Nội",
        "path_with_type": "Xã Vân Tảo, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10210",
        "parent_code": "279"
    },
    "10213": {
        "name": "Liên Phương",
        "type": "xa",
        "slug": "lien-phuong",
        "name_with_type": "Xã Liên Phương",
        "path": "Liên Phương, Thường Tín, Hà Nội",
        "path_with_type": "Xã Liên Phương, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10213",
        "parent_code": "279"
    },
    "10216": {
        "name": "Văn Phú",
        "type": "xa",
        "slug": "van-phu",
        "name_with_type": "Xã Văn Phú",
        "path": "Văn Phú, Thường Tín, Hà Nội",
        "path_with_type": "Xã Văn Phú, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10216",
        "parent_code": "279"
    },
    "10219": {
        "name": "Tự Nhiên",
        "type": "xa",
        "slug": "tu-nhien",
        "name_with_type": "Xã Tự Nhiên",
        "path": "Tự Nhiên, Thường Tín, Hà Nội",
        "path_with_type": "Xã Tự Nhiên, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10219",
        "parent_code": "279"
    },
    "10222": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Thường Tín, Hà Nội",
        "path_with_type": "Xã Tiền Phong, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10222",
        "parent_code": "279"
    },
    "10225": {
        "name": "Hà Hồi",
        "type": "xa",
        "slug": "ha-hoi",
        "name_with_type": "Xã Hà Hồi",
        "path": "Hà Hồi, Thường Tín, Hà Nội",
        "path_with_type": "Xã Hà Hồi, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10225",
        "parent_code": "279"
    },
    "10228": {
        "name": "Thư Phú",
        "type": "xa",
        "slug": "thu-phu",
        "name_with_type": "Xã Thư Phú",
        "path": "Thư Phú, Thường Tín, Hà Nội",
        "path_with_type": "Xã Thư Phú, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10228",
        "parent_code": "279"
    },
    "10231": {
        "name": "Nguyễn Trãi",
        "type": "xa",
        "slug": "nguyen-trai",
        "name_with_type": "Xã Nguyễn Trãi",
        "path": "Nguyễn Trãi, Thường Tín, Hà Nội",
        "path_with_type": "Xã Nguyễn Trãi, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10231",
        "parent_code": "279"
    },
    "10234": {
        "name": "Quất Động",
        "type": "xa",
        "slug": "quat-dong",
        "name_with_type": "Xã Quất Động",
        "path": "Quất Động, Thường Tín, Hà Nội",
        "path_with_type": "Xã Quất Động, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10234",
        "parent_code": "279"
    },
    "10237": {
        "name": "Chương Dương",
        "type": "xa",
        "slug": "chuong-duong",
        "name_with_type": "Xã Chương Dương",
        "path": "Chương Dương, Thường Tín, Hà Nội",
        "path_with_type": "Xã Chương Dương, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10237",
        "parent_code": "279"
    },
    "10240": {
        "name": "Tân Minh",
        "type": "xa",
        "slug": "tan-minh",
        "name_with_type": "Xã Tân Minh",
        "path": "Tân Minh, Thường Tín, Hà Nội",
        "path_with_type": "Xã Tân Minh, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10240",
        "parent_code": "279"
    },
    "10243": {
        "name": "Lê Lợi",
        "type": "xa",
        "slug": "le-loi",
        "name_with_type": "Xã Lê Lợi",
        "path": "Lê Lợi, Thường Tín, Hà Nội",
        "path_with_type": "Xã Lê Lợi, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10243",
        "parent_code": "279"
    },
    "10246": {
        "name": "Thắng Lợi",
        "type": "xa",
        "slug": "thang-loi",
        "name_with_type": "Xã Thắng Lợi",
        "path": "Thắng Lợi, Thường Tín, Hà Nội",
        "path_with_type": "Xã Thắng Lợi, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10246",
        "parent_code": "279"
    },
    "10249": {
        "name": "Dũng Tiến",
        "type": "xa",
        "slug": "dung-tien",
        "name_with_type": "Xã Dũng Tiến",
        "path": "Dũng Tiến, Thường Tín, Hà Nội",
        "path_with_type": "Xã Dũng Tiến, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10249",
        "parent_code": "279"
    },
    "10252": {
        "name": "Thống Nhất",
        "type": "xa",
        "slug": "thong-nhat",
        "name_with_type": "Xã Thống Nhất",
        "path": "Thống Nhất, Thường Tín, Hà Nội",
        "path_with_type": "Xã Thống Nhất, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10252",
        "parent_code": "279"
    },
    "10255": {
        "name": "Nghiêm Xuyên",
        "type": "xa",
        "slug": "nghiem-xuyen",
        "name_with_type": "Xã Nghiêm Xuyên",
        "path": "Nghiêm Xuyên, Thường Tín, Hà Nội",
        "path_with_type": "Xã Nghiêm Xuyên, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10255",
        "parent_code": "279"
    },
    "10258": {
        "name": "Tô Hiệu",
        "type": "xa",
        "slug": "to-hieu",
        "name_with_type": "Xã Tô Hiệu",
        "path": "Tô Hiệu, Thường Tín, Hà Nội",
        "path_with_type": "Xã Tô Hiệu, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10258",
        "parent_code": "279"
    },
    "10261": {
        "name": "Văn Tự",
        "type": "xa",
        "slug": "van-tu",
        "name_with_type": "Xã Văn Tự",
        "path": "Văn Tự, Thường Tín, Hà Nội",
        "path_with_type": "Xã Văn Tự, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10261",
        "parent_code": "279"
    },
    "10264": {
        "name": "Vạn Điểm",
        "type": "xa",
        "slug": "van-diem",
        "name_with_type": "Xã Vạn Điểm",
        "path": "Vạn Điểm, Thường Tín, Hà Nội",
        "path_with_type": "Xã Vạn Điểm, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10264",
        "parent_code": "279"
    },
    "10267": {
        "name": "Minh Cường",
        "type": "xa",
        "slug": "minh-cuong",
        "name_with_type": "Xã Minh Cường",
        "path": "Minh Cường, Thường Tín, Hà Nội",
        "path_with_type": "Xã Minh Cường, Huyện Thường Tín, Thành phố Hà Nội",
        "code": "10267",
        "parent_code": "279"
    },
    "10270": {
        "name": "Phú Minh",
        "type": "thi-tran",
        "slug": "phu-minh",
        "name_with_type": "Thị trấn Phú Minh",
        "path": "Phú Minh, Phú Xuyên, Hà Nội",
        "path_with_type": "Thị trấn Phú Minh, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10270",
        "parent_code": "280"
    },
    "10273": {
        "name": "Phú Xuyên",
        "type": "thi-tran",
        "slug": "phu-xuyen",
        "name_with_type": "Thị trấn Phú Xuyên",
        "path": "Phú Xuyên, Phú Xuyên, Hà Nội",
        "path_with_type": "Thị trấn Phú Xuyên, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10273",
        "parent_code": "280"
    },
    "10276": {
        "name": "Hồng Minh",
        "type": "xa",
        "slug": "hong-minh",
        "name_with_type": "Xã Hồng Minh",
        "path": "Hồng Minh, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Hồng Minh, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10276",
        "parent_code": "280"
    },
    "10279": {
        "name": "Phượng Dực",
        "type": "xa",
        "slug": "phuong-duc",
        "name_with_type": "Xã Phượng Dực",
        "path": "Phượng Dực, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Phượng Dực, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10279",
        "parent_code": "280"
    },
    "10282": {
        "name": "Văn Nhân",
        "type": "xa",
        "slug": "van-nhan",
        "name_with_type": "Xã Văn Nhân",
        "path": "Văn Nhân, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Văn Nhân, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10282",
        "parent_code": "280"
    },
    "10285": {
        "name": "Thụy Phú",
        "type": "xa",
        "slug": "thuy-phu",
        "name_with_type": "Xã Thụy Phú",
        "path": "Thụy Phú, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Thụy Phú, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10285",
        "parent_code": "280"
    },
    "10288": {
        "name": "Tri Trung",
        "type": "xa",
        "slug": "tri-trung",
        "name_with_type": "Xã Tri Trung",
        "path": "Tri Trung, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Tri Trung, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10288",
        "parent_code": "280"
    },
    "10291": {
        "name": "Đại Thắng",
        "type": "xa",
        "slug": "dai-thang",
        "name_with_type": "Xã Đại Thắng",
        "path": "Đại Thắng, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Đại Thắng, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10291",
        "parent_code": "280"
    },
    "10294": {
        "name": "Phú Túc",
        "type": "xa",
        "slug": "phu-tuc",
        "name_with_type": "Xã Phú Túc",
        "path": "Phú Túc, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Phú Túc, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10294",
        "parent_code": "280"
    },
    "10297": {
        "name": "Văn Hoàng",
        "type": "xa",
        "slug": "van-hoang",
        "name_with_type": "Xã Văn Hoàng",
        "path": "Văn Hoàng, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Văn Hoàng, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10297",
        "parent_code": "280"
    },
    "10300": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Hồng Thái, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10300",
        "parent_code": "280"
    },
    "10303": {
        "name": "Hoàng Long",
        "type": "xa",
        "slug": "hoang-long",
        "name_with_type": "Xã Hoàng Long",
        "path": "Hoàng Long, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Hoàng Long, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10303",
        "parent_code": "280"
    },
    "10306": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Quang Trung, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10306",
        "parent_code": "280"
    },
    "10309": {
        "name": "Nam Phong",
        "type": "xa",
        "slug": "nam-phong",
        "name_with_type": "Xã Nam Phong",
        "path": "Nam Phong, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Nam Phong, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10309",
        "parent_code": "280"
    },
    "10312": {
        "name": "Nam Triều",
        "type": "xa",
        "slug": "nam-trieu",
        "name_with_type": "Xã Nam Triều",
        "path": "Nam Triều, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Nam Triều, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10312",
        "parent_code": "280"
    },
    "10315": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Tân Dân, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10315",
        "parent_code": "280"
    },
    "10318": {
        "name": "Sơn Hà",
        "type": "xa",
        "slug": "son-ha",
        "name_with_type": "Xã Sơn Hà",
        "path": "Sơn Hà, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Sơn Hà, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10318",
        "parent_code": "280"
    },
    "10321": {
        "name": "Chuyên Mỹ",
        "type": "xa",
        "slug": "chuyen-my",
        "name_with_type": "Xã Chuyên Mỹ",
        "path": "Chuyên Mỹ, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Chuyên Mỹ, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10321",
        "parent_code": "280"
    },
    "10324": {
        "name": "Khai Thái",
        "type": "xa",
        "slug": "khai-thai",
        "name_with_type": "Xã Khai Thái",
        "path": "Khai Thái, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Khai Thái, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10324",
        "parent_code": "280"
    },
    "10327": {
        "name": "Phúc Tiến",
        "type": "xa",
        "slug": "phuc-tien",
        "name_with_type": "Xã Phúc Tiến",
        "path": "Phúc Tiến, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Phúc Tiến, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10327",
        "parent_code": "280"
    },
    "10330": {
        "name": "Vân Từ",
        "type": "xa",
        "slug": "van-tu",
        "name_with_type": "Xã Vân Từ",
        "path": "Vân Từ, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Vân Từ, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10330",
        "parent_code": "280"
    },
    "10333": {
        "name": "Tri Thủy",
        "type": "xa",
        "slug": "tri-thuy",
        "name_with_type": "Xã Tri Thủy",
        "path": "Tri Thủy, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Tri Thủy, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10333",
        "parent_code": "280"
    },
    "10336": {
        "name": "Đại Xuyên",
        "type": "xa",
        "slug": "dai-xuyen",
        "name_with_type": "Xã Đại Xuyên",
        "path": "Đại Xuyên, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Đại Xuyên, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10336",
        "parent_code": "280"
    },
    "10339": {
        "name": "Phú Yên",
        "type": "xa",
        "slug": "phu-yen",
        "name_with_type": "Xã Phú Yên",
        "path": "Phú Yên, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Phú Yên, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10339",
        "parent_code": "280"
    },
    "10342": {
        "name": "Bạch Hạ",
        "type": "xa",
        "slug": "bach-ha",
        "name_with_type": "Xã Bạch Hạ",
        "path": "Bạch Hạ, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Bạch Hạ, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10342",
        "parent_code": "280"
    },
    "10345": {
        "name": "Quang Lãng",
        "type": "xa",
        "slug": "quang-lang",
        "name_with_type": "Xã Quang Lãng",
        "path": "Quang Lãng, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Quang Lãng, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10345",
        "parent_code": "280"
    },
    "10348": {
        "name": "Châu Can",
        "type": "xa",
        "slug": "chau-can",
        "name_with_type": "Xã Châu Can",
        "path": "Châu Can, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Châu Can, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10348",
        "parent_code": "280"
    },
    "10351": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Phú Xuyên, Hà Nội",
        "path_with_type": "Xã Minh Tân, Huyện Phú Xuyên, Thành phố Hà Nội",
        "code": "10351",
        "parent_code": "280"
    },
    "10354": {
        "name": "Vân Đình",
        "type": "thi-tran",
        "slug": "van-dinh",
        "name_with_type": "Thị trấn Vân Đình",
        "path": "Vân Đình, Ứng Hòa, Hà Nội",
        "path_with_type": "Thị trấn Vân Đình, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10354",
        "parent_code": "281"
    },
    "10357": {
        "name": "Viên An",
        "type": "xa",
        "slug": "vien-an",
        "name_with_type": "Xã Viên An",
        "path": "Viên An, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Viên An, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10357",
        "parent_code": "281"
    },
    "10360": {
        "name": "Viên Nội",
        "type": "xa",
        "slug": "vien-noi",
        "name_with_type": "Xã Viên Nội",
        "path": "Viên Nội, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Viên Nội, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10360",
        "parent_code": "281"
    },
    "10363": {
        "name": "Hoa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hoa Sơn",
        "path": "Hoa Sơn, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Hoa Sơn, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10363",
        "parent_code": "281"
    },
    "10366": {
        "name": "Quảng Phú Cầu",
        "type": "xa",
        "slug": "quang-phu-cau",
        "name_with_type": "Xã Quảng Phú Cầu",
        "path": "Quảng Phú Cầu, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Quảng Phú Cầu, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10366",
        "parent_code": "281"
    },
    "10369": {
        "name": "Trường Thịnh",
        "type": "xa",
        "slug": "truong-thinh",
        "name_with_type": "Xã Trường Thịnh",
        "path": "Trường Thịnh, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Trường Thịnh, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10369",
        "parent_code": "281"
    },
    "10372": {
        "name": "Cao Thành",
        "type": "xa",
        "slug": "cao-thanh",
        "name_with_type": "Xã Cao Thành",
        "path": "Cao Thành, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Cao Thành, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10372",
        "parent_code": "281"
    },
    "10375": {
        "name": "Liên Bạt",
        "type": "xa",
        "slug": "lien-bat",
        "name_with_type": "Xã Liên Bạt",
        "path": "Liên Bạt, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Liên Bạt, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10375",
        "parent_code": "281"
    },
    "10378": {
        "name": "Sơn Công",
        "type": "xa",
        "slug": "son-cong",
        "name_with_type": "Xã Sơn Công",
        "path": "Sơn Công, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Sơn Công, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10378",
        "parent_code": "281"
    },
    "10381": {
        "name": "Đồng Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đồng Tiến",
        "path": "Đồng Tiến, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Đồng Tiến, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10381",
        "parent_code": "281"
    },
    "10384": {
        "name": "Phương Tú",
        "type": "xa",
        "slug": "phuong-tu",
        "name_with_type": "Xã Phương Tú",
        "path": "Phương Tú, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Phương Tú, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10384",
        "parent_code": "281"
    },
    "10387": {
        "name": "Trung Tú",
        "type": "xa",
        "slug": "trung-tu",
        "name_with_type": "Xã Trung Tú",
        "path": "Trung Tú, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Trung Tú, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10387",
        "parent_code": "281"
    },
    "10390": {
        "name": "Đồng Tân",
        "type": "xa",
        "slug": "dong-tan",
        "name_with_type": "Xã Đồng Tân",
        "path": "Đồng Tân, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Đồng Tân, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10390",
        "parent_code": "281"
    },
    "10393": {
        "name": "Tảo Dương Văn",
        "type": "xa",
        "slug": "tao-duong-van",
        "name_with_type": "Xã Tảo Dương Văn",
        "path": "Tảo Dương Văn, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Tảo Dương Văn, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10393",
        "parent_code": "281"
    },
    "10396": {
        "name": "Vạn Thái",
        "type": "xa",
        "slug": "van-thai",
        "name_with_type": "Xã Vạn Thái",
        "path": "Vạn Thái, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Vạn Thái, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10396",
        "parent_code": "281"
    },
    "10399": {
        "name": "Minh Đức",
        "type": "xa",
        "slug": "minh-duc",
        "name_with_type": "Xã Minh Đức",
        "path": "Minh Đức, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Minh Đức, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10399",
        "parent_code": "281"
    },
    "10402": {
        "name": "Hòa Lâm",
        "type": "xa",
        "slug": "hoa-lam",
        "name_with_type": "Xã Hòa Lâm",
        "path": "Hòa Lâm, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Hòa Lâm, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10402",
        "parent_code": "281"
    },
    "10405": {
        "name": "Hòa Xá",
        "type": "xa",
        "slug": "hoa-xa",
        "name_with_type": "Xã Hòa Xá",
        "path": "Hòa Xá, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Hòa Xá, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10405",
        "parent_code": "281"
    },
    "10408": {
        "name": "Trầm Lộng",
        "type": "xa",
        "slug": "tram-long",
        "name_with_type": "Xã Trầm Lộng",
        "path": "Trầm Lộng, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Trầm Lộng, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10408",
        "parent_code": "281"
    },
    "10411": {
        "name": "Kim Đường",
        "type": "xa",
        "slug": "kim-duong",
        "name_with_type": "Xã Kim Đường",
        "path": "Kim Đường, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Kim Đường, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10411",
        "parent_code": "281"
    },
    "10414": {
        "name": "Hòa Nam",
        "type": "xa",
        "slug": "hoa-nam",
        "name_with_type": "Xã Hòa Nam",
        "path": "Hòa Nam, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Hòa Nam, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10414",
        "parent_code": "281"
    },
    "10417": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Hòa Phú, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10417",
        "parent_code": "281"
    },
    "10420": {
        "name": "Đội Bình",
        "type": "xa",
        "slug": "doi-binh",
        "name_with_type": "Xã Đội Bình",
        "path": "Đội Bình, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Đội Bình, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10420",
        "parent_code": "281"
    },
    "10423": {
        "name": "Đại Hùng",
        "type": "xa",
        "slug": "dai-hung",
        "name_with_type": "Xã Đại Hùng",
        "path": "Đại Hùng, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Đại Hùng, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10423",
        "parent_code": "281"
    },
    "10426": {
        "name": "Đông Lỗ",
        "type": "xa",
        "slug": "dong-lo",
        "name_with_type": "Xã Đông Lỗ",
        "path": "Đông Lỗ, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Đông Lỗ, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10426",
        "parent_code": "281"
    },
    "10429": {
        "name": "Phù Lưu",
        "type": "xa",
        "slug": "phu-luu",
        "name_with_type": "Xã Phù Lưu",
        "path": "Phù Lưu, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Phù Lưu, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10429",
        "parent_code": "281"
    },
    "10432": {
        "name": "Đại Cường",
        "type": "xa",
        "slug": "dai-cuong",
        "name_with_type": "Xã Đại Cường",
        "path": "Đại Cường, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Đại Cường, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10432",
        "parent_code": "281"
    },
    "10435": {
        "name": "Lưu Hoàng",
        "type": "xa",
        "slug": "luu-hoang",
        "name_with_type": "Xã Lưu Hoàng",
        "path": "Lưu Hoàng, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Lưu Hoàng, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10435",
        "parent_code": "281"
    },
    "10438": {
        "name": "Hồng Quang",
        "type": "xa",
        "slug": "hong-quang",
        "name_with_type": "Xã Hồng Quang",
        "path": "Hồng Quang, Ứng Hòa, Hà Nội",
        "path_with_type": "Xã Hồng Quang, Huyện Ứng Hòa, Thành phố Hà Nội",
        "code": "10438",
        "parent_code": "281"
    },
    "10441": {
        "name": "Đại Nghĩa",
        "type": "thi-tran",
        "slug": "dai-nghia",
        "name_with_type": "Thị trấn Đại Nghĩa",
        "path": "Đại Nghĩa, Mỹ Đức, Hà Nội",
        "path_with_type": "Thị trấn Đại Nghĩa, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10441",
        "parent_code": "282"
    },
    "10444": {
        "name": "Đồng Tâm",
        "type": "xa",
        "slug": "dong-tam",
        "name_with_type": "Xã Đồng Tâm",
        "path": "Đồng Tâm, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Đồng Tâm, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10444",
        "parent_code": "282"
    },
    "10447": {
        "name": "Thượng Lâm",
        "type": "xa",
        "slug": "thuong-lam",
        "name_with_type": "Xã Thượng Lâm",
        "path": "Thượng Lâm, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Thượng Lâm, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10447",
        "parent_code": "282"
    },
    "10450": {
        "name": "Tuy Lai",
        "type": "xa",
        "slug": "tuy-lai",
        "name_with_type": "Xã Tuy Lai",
        "path": "Tuy Lai, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Tuy Lai, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10450",
        "parent_code": "282"
    },
    "10453": {
        "name": "Phúc Lâm",
        "type": "xa",
        "slug": "phuc-lam",
        "name_with_type": "Xã Phúc Lâm",
        "path": "Phúc Lâm, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Phúc Lâm, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10453",
        "parent_code": "282"
    },
    "10456": {
        "name": "Mỹ Thành",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thành",
        "path": "Mỹ Thành, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Mỹ Thành, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10456",
        "parent_code": "282"
    },
    "10459": {
        "name": "Bột Xuyên",
        "type": "xa",
        "slug": "bot-xuyen",
        "name_with_type": "Xã Bột Xuyên",
        "path": "Bột Xuyên, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Bột Xuyên, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10459",
        "parent_code": "282"
    },
    "10462": {
        "name": "An Mỹ",
        "type": "xa",
        "slug": "an-my",
        "name_with_type": "Xã An Mỹ",
        "path": "An Mỹ, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã An Mỹ, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10462",
        "parent_code": "282"
    },
    "10465": {
        "name": "Hồng Sơn",
        "type": "xa",
        "slug": "hong-son",
        "name_with_type": "Xã Hồng Sơn",
        "path": "Hồng Sơn, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Hồng Sơn, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10465",
        "parent_code": "282"
    },
    "10468": {
        "name": "Lê Thanh",
        "type": "xa",
        "slug": "le-thanh",
        "name_with_type": "Xã Lê Thanh",
        "path": "Lê Thanh, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Lê Thanh, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10468",
        "parent_code": "282"
    },
    "10471": {
        "name": "Xuy Xá",
        "type": "xa",
        "slug": "xuy-xa",
        "name_with_type": "Xã Xuy Xá",
        "path": "Xuy Xá, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Xuy Xá, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10471",
        "parent_code": "282"
    },
    "10474": {
        "name": "Phùng Xá",
        "type": "xa",
        "slug": "phung-xa",
        "name_with_type": "Xã Phùng Xá",
        "path": "Phùng Xá, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Phùng Xá, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10474",
        "parent_code": "282"
    },
    "10477": {
        "name": "Phù Lưu Tế",
        "type": "xa",
        "slug": "phu-luu-te",
        "name_with_type": "Xã Phù Lưu Tế",
        "path": "Phù Lưu Tế, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Phù Lưu Tế, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10477",
        "parent_code": "282"
    },
    "10480": {
        "name": "Đại Hưng",
        "type": "xa",
        "slug": "dai-hung",
        "name_with_type": "Xã Đại Hưng",
        "path": "Đại Hưng, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Đại Hưng, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10480",
        "parent_code": "282"
    },
    "10483": {
        "name": "Vạn Kim",
        "type": "xa",
        "slug": "van-kim",
        "name_with_type": "Xã Vạn Kim",
        "path": "Vạn Kim, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Vạn Kim, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10483",
        "parent_code": "282"
    },
    "10486": {
        "name": "Đốc Tín",
        "type": "xa",
        "slug": "doc-tin",
        "name_with_type": "Xã Đốc Tín",
        "path": "Đốc Tín, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Đốc Tín, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10486",
        "parent_code": "282"
    },
    "10489": {
        "name": "Hương Sơn",
        "type": "xa",
        "slug": "huong-son",
        "name_with_type": "Xã Hương Sơn",
        "path": "Hương Sơn, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Hương Sơn, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10489",
        "parent_code": "282"
    },
    "10492": {
        "name": "Hùng Tiến",
        "type": "xa",
        "slug": "hung-tien",
        "name_with_type": "Xã Hùng Tiến",
        "path": "Hùng Tiến, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Hùng Tiến, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10492",
        "parent_code": "282"
    },
    "10495": {
        "name": "An Tiến",
        "type": "xa",
        "slug": "an-tien",
        "name_with_type": "Xã An Tiến",
        "path": "An Tiến, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã An Tiến, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10495",
        "parent_code": "282"
    },
    "10498": {
        "name": "Hợp Tiến",
        "type": "xa",
        "slug": "hop-tien",
        "name_with_type": "Xã Hợp Tiến",
        "path": "Hợp Tiến, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Hợp Tiến, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10498",
        "parent_code": "282"
    },
    "10501": {
        "name": "Hợp Thanh",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thanh",
        "path": "Hợp Thanh, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã Hợp Thanh, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10501",
        "parent_code": "282"
    },
    "10504": {
        "name": "An Phú",
        "type": "xa",
        "slug": "an-phu",
        "name_with_type": "Xã An Phú",
        "path": "An Phú, Mỹ Đức, Hà Nội",
        "path_with_type": "Xã An Phú, Huyện Mỹ Đức, Thành phố Hà Nội",
        "code": "10504",
        "parent_code": "282"
    },
    "02635": {
        "name": "Duyên Hải",
        "type": "phuong",
        "slug": "duyen-hai",
        "name_with_type": "Phường Duyên Hải",
        "path": "Duyên Hải, Lào Cai, Lào Cai",
        "path_with_type": "Phường Duyên Hải, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02635",
        "parent_code": "080"
    },
    "02638": {
        "name": "Lào Cai",
        "type": "phuong",
        "slug": "lao-cai",
        "name_with_type": "Phường Lào Cai",
        "path": "Lào Cai, Lào Cai, Lào Cai",
        "path_with_type": "Phường Lào Cai, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02638",
        "parent_code": "080"
    },
    "02641": {
        "name": "Phố Mới",
        "type": "phuong",
        "slug": "pho-moi",
        "name_with_type": "Phường Phố Mới",
        "path": "Phố Mới, Lào Cai, Lào Cai",
        "path_with_type": "Phường Phố Mới, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02641",
        "parent_code": "080"
    },
    "02644": {
        "name": "Cốc Lếu",
        "type": "phuong",
        "slug": "coc-leu",
        "name_with_type": "Phường Cốc Lếu",
        "path": "Cốc Lếu, Lào Cai, Lào Cai",
        "path_with_type": "Phường Cốc Lếu, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02644",
        "parent_code": "080"
    },
    "02647": {
        "name": "Kim Tân",
        "type": "phuong",
        "slug": "kim-tan",
        "name_with_type": "Phường Kim Tân",
        "path": "Kim Tân, Lào Cai, Lào Cai",
        "path_with_type": "Phường Kim Tân, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02647",
        "parent_code": "080"
    },
    "02650": {
        "name": "Bắc Lệnh",
        "type": "phuong",
        "slug": "bac-lenh",
        "name_with_type": "Phường Bắc Lệnh",
        "path": "Bắc Lệnh, Lào Cai, Lào Cai",
        "path_with_type": "Phường Bắc Lệnh, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02650",
        "parent_code": "080"
    },
    "02653": {
        "name": "Pom Hán",
        "type": "phuong",
        "slug": "pom-han",
        "name_with_type": "Phường Pom Hán",
        "path": "Pom Hán, Lào Cai, Lào Cai",
        "path_with_type": "Phường Pom Hán, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02653",
        "parent_code": "080"
    },
    "02656": {
        "name": "Xuân Tăng",
        "type": "phuong",
        "slug": "xuan-tang",
        "name_with_type": "Phường Xuân Tăng",
        "path": "Xuân Tăng, Lào Cai, Lào Cai",
        "path_with_type": "Phường Xuân Tăng, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02656",
        "parent_code": "080"
    },
    "02658": {
        "name": "Bình Minh",
        "type": "phuong",
        "slug": "binh-minh",
        "name_with_type": "Phường Bình Minh",
        "path": "Bình Minh, Lào Cai, Lào Cai",
        "path_with_type": "Phường Bình Minh, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02658",
        "parent_code": "080"
    },
    "02659": {
        "name": "Thống Nhất",
        "type": "phuong",
        "slug": "thong-nhat",
        "name_with_type": "Phường Thống Nhất",
        "path": "Thống Nhất, Lào Cai, Lào Cai",
        "path_with_type": "Phường Thống Nhất, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02659",
        "parent_code": "080"
    },
    "02662": {
        "name": "Đồng Tuyển",
        "type": "xa",
        "slug": "dong-tuyen",
        "name_with_type": "Xã Đồng Tuyển",
        "path": "Đồng Tuyển, Lào Cai, Lào Cai",
        "path_with_type": "Xã Đồng Tuyển, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02662",
        "parent_code": "080"
    },
    "02665": {
        "name": "Vạn Hoà",
        "type": "xa",
        "slug": "van-hoa",
        "name_with_type": "Xã Vạn Hoà",
        "path": "Vạn Hoà, Lào Cai, Lào Cai",
        "path_with_type": "Xã Vạn Hoà, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02665",
        "parent_code": "080"
    },
    "02668": {
        "name": "Bắc Cường",
        "type": "phuong",
        "slug": "bac-cuong",
        "name_with_type": "Phường Bắc Cường",
        "path": "Bắc Cường, Lào Cai, Lào Cai",
        "path_with_type": "Phường Bắc Cường, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02668",
        "parent_code": "080"
    },
    "02671": {
        "name": "Nam Cường",
        "type": "phuong",
        "slug": "nam-cuong",
        "name_with_type": "Phường Nam Cường",
        "path": "Nam Cường, Lào Cai, Lào Cai",
        "path_with_type": "Phường Nam Cường, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02671",
        "parent_code": "080"
    },
    "02674": {
        "name": "Cam Đường",
        "type": "xa",
        "slug": "cam-duong",
        "name_with_type": "Xã Cam Đường",
        "path": "Cam Đường, Lào Cai, Lào Cai",
        "path_with_type": "Xã Cam Đường, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02674",
        "parent_code": "080"
    },
    "02677": {
        "name": "Tả Phời",
        "type": "xa",
        "slug": "ta-phoi",
        "name_with_type": "Xã Tả Phời",
        "path": "Tả Phời, Lào Cai, Lào Cai",
        "path_with_type": "Xã Tả Phời, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02677",
        "parent_code": "080"
    },
    "02680": {
        "name": "Hợp Thành",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thành",
        "path": "Hợp Thành, Lào Cai, Lào Cai",
        "path_with_type": "Xã Hợp Thành, Thành phố Lào Cai, Tỉnh Lào Cai",
        "code": "02680",
        "parent_code": "080"
    },
    "02683": {
        "name": "Bát Xát",
        "type": "thi-tran",
        "slug": "bat-xat",
        "name_with_type": "Thị trấn Bát Xát",
        "path": "Bát Xát, Bát Xát, Lào Cai",
        "path_with_type": "Thị trấn Bát Xát, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02683",
        "parent_code": "082"
    },
    "02686": {
        "name": "A Mú Sung",
        "type": "xa",
        "slug": "a-mu-sung",
        "name_with_type": "Xã A Mú Sung",
        "path": "A Mú Sung, Bát Xát, Lào Cai",
        "path_with_type": "Xã A Mú Sung, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02686",
        "parent_code": "082"
    },
    "02689": {
        "name": "Nậm Chạc",
        "type": "xa",
        "slug": "nam-chac",
        "name_with_type": "Xã Nậm Chạc",
        "path": "Nậm Chạc, Bát Xát, Lào Cai",
        "path_with_type": "Xã Nậm Chạc, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02689",
        "parent_code": "082"
    },
    "02692": {
        "name": "A Lù",
        "type": "xa",
        "slug": "a-lu",
        "name_with_type": "Xã A Lù",
        "path": "A Lù, Bát Xát, Lào Cai",
        "path_with_type": "Xã A Lù, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02692",
        "parent_code": "082"
    },
    "02695": {
        "name": "Trịnh Tường",
        "type": "xa",
        "slug": "trinh-tuong",
        "name_with_type": "Xã Trịnh Tường",
        "path": "Trịnh Tường, Bát Xát, Lào Cai",
        "path_with_type": "Xã Trịnh Tường, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02695",
        "parent_code": "082"
    },
    "02698": {
        "name": "Ngải Thầu",
        "type": "xa",
        "slug": "ngai-thau",
        "name_with_type": "Xã Ngải Thầu",
        "path": "Ngải Thầu, Bát Xát, Lào Cai",
        "path_with_type": "Xã Ngải Thầu, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02698",
        "parent_code": "082"
    },
    "02701": {
        "name": "Y Tý",
        "type": "xa",
        "slug": "y-ty",
        "name_with_type": "Xã Y Tý",
        "path": "Y Tý, Bát Xát, Lào Cai",
        "path_with_type": "Xã Y Tý, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02701",
        "parent_code": "082"
    },
    "02704": {
        "name": "Cốc Mỳ",
        "type": "xa",
        "slug": "coc-my",
        "name_with_type": "Xã Cốc Mỳ",
        "path": "Cốc Mỳ, Bát Xát, Lào Cai",
        "path_with_type": "Xã Cốc Mỳ, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02704",
        "parent_code": "082"
    },
    "02707": {
        "name": "Dền Sáng",
        "type": "xa",
        "slug": "den-sang",
        "name_with_type": "Xã Dền Sáng",
        "path": "Dền Sáng, Bát Xát, Lào Cai",
        "path_with_type": "Xã Dền Sáng, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02707",
        "parent_code": "082"
    },
    "02710": {
        "name": "Bản Vược",
        "type": "xa",
        "slug": "ban-vuoc",
        "name_with_type": "Xã Bản Vược",
        "path": "Bản Vược, Bát Xát, Lào Cai",
        "path_with_type": "Xã Bản Vược, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02710",
        "parent_code": "082"
    },
    "02713": {
        "name": "Sàng Ma Sáo",
        "type": "xa",
        "slug": "sang-ma-sao",
        "name_with_type": "Xã Sàng Ma Sáo",
        "path": "Sàng Ma Sáo, Bát Xát, Lào Cai",
        "path_with_type": "Xã Sàng Ma Sáo, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02713",
        "parent_code": "082"
    },
    "02716": {
        "name": "Bản Qua",
        "type": "xa",
        "slug": "ban-qua",
        "name_with_type": "Xã Bản Qua",
        "path": "Bản Qua, Bát Xát, Lào Cai",
        "path_with_type": "Xã Bản Qua, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02716",
        "parent_code": "082"
    },
    "02719": {
        "name": "Mường Vi",
        "type": "xa",
        "slug": "muong-vi",
        "name_with_type": "Xã Mường Vi",
        "path": "Mường Vi, Bát Xát, Lào Cai",
        "path_with_type": "Xã Mường Vi, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02719",
        "parent_code": "082"
    },
    "02722": {
        "name": "Dền Thàng",
        "type": "xa",
        "slug": "den-thang",
        "name_with_type": "Xã Dền Thàng",
        "path": "Dền Thàng, Bát Xát, Lào Cai",
        "path_with_type": "Xã Dền Thàng, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02722",
        "parent_code": "082"
    },
    "02725": {
        "name": "Bản Xèo",
        "type": "xa",
        "slug": "ban-xeo",
        "name_with_type": "Xã Bản Xèo",
        "path": "Bản Xèo, Bát Xát, Lào Cai",
        "path_with_type": "Xã Bản Xèo, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02725",
        "parent_code": "082"
    },
    "02728": {
        "name": "Mường Hum",
        "type": "xa",
        "slug": "muong-hum",
        "name_with_type": "Xã Mường Hum",
        "path": "Mường Hum, Bát Xát, Lào Cai",
        "path_with_type": "Xã Mường Hum, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02728",
        "parent_code": "082"
    },
    "02731": {
        "name": "Trung Lèng Hồ",
        "type": "xa",
        "slug": "trung-leng-ho",
        "name_with_type": "Xã Trung Lèng Hồ",
        "path": "Trung Lèng Hồ, Bát Xát, Lào Cai",
        "path_with_type": "Xã Trung Lèng Hồ, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02731",
        "parent_code": "082"
    },
    "02734": {
        "name": "Quang Kim",
        "type": "xa",
        "slug": "quang-kim",
        "name_with_type": "Xã Quang Kim",
        "path": "Quang Kim, Bát Xát, Lào Cai",
        "path_with_type": "Xã Quang Kim, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02734",
        "parent_code": "082"
    },
    "02737": {
        "name": "Pa Cheo",
        "type": "xa",
        "slug": "pa-cheo",
        "name_with_type": "Xã Pa Cheo",
        "path": "Pa Cheo, Bát Xát, Lào Cai",
        "path_with_type": "Xã Pa Cheo, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02737",
        "parent_code": "082"
    },
    "02740": {
        "name": "Nậm Pung",
        "type": "xa",
        "slug": "nam-pung",
        "name_with_type": "Xã Nậm Pung",
        "path": "Nậm Pung, Bát Xát, Lào Cai",
        "path_with_type": "Xã Nậm Pung, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02740",
        "parent_code": "082"
    },
    "02743": {
        "name": "Phìn Ngan",
        "type": "xa",
        "slug": "phin-ngan",
        "name_with_type": "Xã Phìn Ngan",
        "path": "Phìn Ngan, Bát Xát, Lào Cai",
        "path_with_type": "Xã Phìn Ngan, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02743",
        "parent_code": "082"
    },
    "02746": {
        "name": "Cốc San",
        "type": "xa",
        "slug": "coc-san",
        "name_with_type": "Xã Cốc San",
        "path": "Cốc San, Bát Xát, Lào Cai",
        "path_with_type": "Xã Cốc San, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02746",
        "parent_code": "082"
    },
    "02749": {
        "name": "Tòng Sành",
        "type": "xa",
        "slug": "tong-sanh",
        "name_with_type": "Xã Tòng Sành",
        "path": "Tòng Sành, Bát Xát, Lào Cai",
        "path_with_type": "Xã Tòng Sành, Huyện Bát Xát, Tỉnh Lào Cai",
        "code": "02749",
        "parent_code": "082"
    },
    "02752": {
        "name": "Pha Long",
        "type": "xa",
        "slug": "pha-long",
        "name_with_type": "Xã Pha Long",
        "path": "Pha Long, Mường Khương, Lào Cai",
        "path_with_type": "Xã Pha Long, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02752",
        "parent_code": "083"
    },
    "02755": {
        "name": "Tả Ngải Chồ",
        "type": "xa",
        "slug": "ta-ngai-cho",
        "name_with_type": "Xã Tả Ngải Chồ",
        "path": "Tả Ngải Chồ, Mường Khương, Lào Cai",
        "path_with_type": "Xã Tả Ngải Chồ, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02755",
        "parent_code": "083"
    },
    "02758": {
        "name": "Tung Chung Phố",
        "type": "xa",
        "slug": "tung-chung-pho",
        "name_with_type": "Xã Tung Chung Phố",
        "path": "Tung Chung Phố, Mường Khương, Lào Cai",
        "path_with_type": "Xã Tung Chung Phố, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02758",
        "parent_code": "083"
    },
    "02761": {
        "name": "Mường Khương",
        "type": "thi-tran",
        "slug": "muong-khuong",
        "name_with_type": "Thị trấn Mường Khương",
        "path": "Mường Khương, Mường Khương, Lào Cai",
        "path_with_type": "Thị trấn Mường Khương, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02761",
        "parent_code": "083"
    },
    "02764": {
        "name": "Dìn Chin",
        "type": "xa",
        "slug": "din-chin",
        "name_with_type": "Xã Dìn Chin",
        "path": "Dìn Chin, Mường Khương, Lào Cai",
        "path_with_type": "Xã Dìn Chin, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02764",
        "parent_code": "083"
    },
    "02767": {
        "name": "Tả Gia Khâu",
        "type": "xa",
        "slug": "ta-gia-khau",
        "name_with_type": "Xã Tả Gia Khâu",
        "path": "Tả Gia Khâu, Mường Khương, Lào Cai",
        "path_with_type": "Xã Tả Gia Khâu, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02767",
        "parent_code": "083"
    },
    "02770": {
        "name": "Nậm Chảy",
        "type": "xa",
        "slug": "nam-chay",
        "name_with_type": "Xã Nậm Chảy",
        "path": "Nậm Chảy, Mường Khương, Lào Cai",
        "path_with_type": "Xã Nậm Chảy, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02770",
        "parent_code": "083"
    },
    "02773": {
        "name": "Nấm Lư",
        "type": "xa",
        "slug": "nam-lu",
        "name_with_type": "Xã Nấm Lư",
        "path": "Nấm Lư, Mường Khương, Lào Cai",
        "path_with_type": "Xã Nấm Lư, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02773",
        "parent_code": "083"
    },
    "02776": {
        "name": "Lùng Khấu Nhin",
        "type": "xa",
        "slug": "lung-khau-nhin",
        "name_with_type": "Xã Lùng Khấu Nhin",
        "path": "Lùng Khấu Nhin, Mường Khương, Lào Cai",
        "path_with_type": "Xã Lùng Khấu Nhin, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02776",
        "parent_code": "083"
    },
    "02779": {
        "name": "Thanh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thanh Bình",
        "path": "Thanh Bình, Mường Khương, Lào Cai",
        "path_with_type": "Xã Thanh Bình, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02779",
        "parent_code": "083"
    },
    "02782": {
        "name": "Cao Sơn",
        "type": "xa",
        "slug": "cao-son",
        "name_with_type": "Xã Cao Sơn",
        "path": "Cao Sơn, Mường Khương, Lào Cai",
        "path_with_type": "Xã Cao Sơn, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02782",
        "parent_code": "083"
    },
    "02785": {
        "name": "Lùng Vai",
        "type": "xa",
        "slug": "lung-vai",
        "name_with_type": "Xã Lùng Vai",
        "path": "Lùng Vai, Mường Khương, Lào Cai",
        "path_with_type": "Xã Lùng Vai, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02785",
        "parent_code": "083"
    },
    "02788": {
        "name": "Bản Lầu",
        "type": "xa",
        "slug": "ban-lau",
        "name_with_type": "Xã Bản Lầu",
        "path": "Bản Lầu, Mường Khương, Lào Cai",
        "path_with_type": "Xã Bản Lầu, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02788",
        "parent_code": "083"
    },
    "02791": {
        "name": "La Pan Tẩn",
        "type": "xa",
        "slug": "la-pan-tan",
        "name_with_type": "Xã La Pan Tẩn",
        "path": "La Pan Tẩn, Mường Khương, Lào Cai",
        "path_with_type": "Xã La Pan Tẩn, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02791",
        "parent_code": "083"
    },
    "02794": {
        "name": "Tả Thàng",
        "type": "xa",
        "slug": "ta-thang",
        "name_with_type": "Xã Tả Thàng",
        "path": "Tả Thàng, Mường Khương, Lào Cai",
        "path_with_type": "Xã Tả Thàng, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02794",
        "parent_code": "083"
    },
    "02797": {
        "name": "Bản Sen",
        "type": "xa",
        "slug": "ban-sen",
        "name_with_type": "Xã Bản Sen",
        "path": "Bản Sen, Mường Khương, Lào Cai",
        "path_with_type": "Xã Bản Sen, Huyện Mường Khương, Tỉnh Lào Cai",
        "code": "02797",
        "parent_code": "083"
    },
    "02800": {
        "name": "Nàn Sán",
        "type": "xa",
        "slug": "nan-san",
        "name_with_type": "Xã Nàn Sán",
        "path": "Nàn Sán, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Nàn Sán, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02800",
        "parent_code": "084"
    },
    "02803": {
        "name": "Thào Chư Phìn",
        "type": "xa",
        "slug": "thao-chu-phin",
        "name_with_type": "Xã Thào Chư Phìn",
        "path": "Thào Chư Phìn, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Thào Chư Phìn, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02803",
        "parent_code": "084"
    },
    "02806": {
        "name": "Bản Mế",
        "type": "xa",
        "slug": "ban-me",
        "name_with_type": "Xã Bản Mế",
        "path": "Bản Mế, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Bản Mế, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02806",
        "parent_code": "084"
    },
    "02809": {
        "name": "Si Ma Cai",
        "type": "xa",
        "slug": "si-ma-cai",
        "name_with_type": "Xã Si Ma Cai",
        "path": "Si Ma Cai, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Si Ma Cai, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02809",
        "parent_code": "084"
    },
    "02812": {
        "name": "Sán Chải",
        "type": "xa",
        "slug": "san-chai",
        "name_with_type": "Xã Sán Chải",
        "path": "Sán Chải, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Sán Chải, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02812",
        "parent_code": "084"
    },
    "02815": {
        "name": "Mản Thẩn",
        "type": "xa",
        "slug": "man-than",
        "name_with_type": "Xã Mản Thẩn",
        "path": "Mản Thẩn, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Mản Thẩn, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02815",
        "parent_code": "084"
    },
    "02818": {
        "name": "Lùng Sui",
        "type": "xa",
        "slug": "lung-sui",
        "name_with_type": "Xã Lùng Sui",
        "path": "Lùng Sui, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Lùng Sui, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02818",
        "parent_code": "084"
    },
    "02821": {
        "name": "Cán Cấu",
        "type": "xa",
        "slug": "can-cau",
        "name_with_type": "Xã Cán Cấu",
        "path": "Cán Cấu, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Cán Cấu, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02821",
        "parent_code": "084"
    },
    "02824": {
        "name": "Sín Chéng",
        "type": "xa",
        "slug": "sin-cheng",
        "name_with_type": "Xã Sín Chéng",
        "path": "Sín Chéng, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Sín Chéng, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02824",
        "parent_code": "084"
    },
    "02827": {
        "name": "Cán Hồ",
        "type": "xa",
        "slug": "can-ho",
        "name_with_type": "Xã Cán Hồ",
        "path": "Cán Hồ, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Cán Hồ, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02827",
        "parent_code": "084"
    },
    "02830": {
        "name": "Quan Thần Sán",
        "type": "xa",
        "slug": "quan-than-san",
        "name_with_type": "Xã Quan Thần Sán",
        "path": "Quan Thần Sán, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Quan Thần Sán, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02830",
        "parent_code": "084"
    },
    "02833": {
        "name": "Lử Thẩn",
        "type": "xa",
        "slug": "lu-than",
        "name_with_type": "Xã Lử Thẩn",
        "path": "Lử Thẩn, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Lử Thẩn, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02833",
        "parent_code": "084"
    },
    "02836": {
        "name": "Nàn Xín",
        "type": "xa",
        "slug": "nan-xin",
        "name_with_type": "Xã Nàn Xín",
        "path": "Nàn Xín, Si Ma Cai, Lào Cai",
        "path_with_type": "Xã Nàn Xín, Huyện Si Ma Cai, Tỉnh Lào Cai",
        "code": "02836",
        "parent_code": "084"
    },
    "02839": {
        "name": "Bắc Hà",
        "type": "thi-tran",
        "slug": "bac-ha",
        "name_with_type": "Thị trấn Bắc Hà",
        "path": "Bắc Hà, Bắc Hà, Lào Cai",
        "path_with_type": "Thị trấn Bắc Hà, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02839",
        "parent_code": "085"
    },
    "02842": {
        "name": "Lùng Cải",
        "type": "xa",
        "slug": "lung-cai",
        "name_with_type": "Xã Lùng Cải",
        "path": "Lùng Cải, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Lùng Cải, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02842",
        "parent_code": "085"
    },
    "02845": {
        "name": "Bản Già",
        "type": "xa",
        "slug": "ban-gia",
        "name_with_type": "Xã Bản Già",
        "path": "Bản Già, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Bản Già, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02845",
        "parent_code": "085"
    },
    "02848": {
        "name": "Lùng Phình",
        "type": "xa",
        "slug": "lung-phinh",
        "name_with_type": "Xã Lùng Phình",
        "path": "Lùng Phình, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Lùng Phình, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02848",
        "parent_code": "085"
    },
    "02851": {
        "name": "Tả Van Chư",
        "type": "xa",
        "slug": "ta-van-chu",
        "name_with_type": "Xã Tả Van Chư",
        "path": "Tả Van Chư, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Tả Van Chư, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02851",
        "parent_code": "085"
    },
    "02854": {
        "name": "Tả Củ Tỷ",
        "type": "xa",
        "slug": "ta-cu-ty",
        "name_with_type": "Xã Tả Củ Tỷ",
        "path": "Tả Củ Tỷ, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Tả Củ Tỷ, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02854",
        "parent_code": "085"
    },
    "02857": {
        "name": "Thải Giàng Phố",
        "type": "xa",
        "slug": "thai-giang-pho",
        "name_with_type": "Xã Thải Giàng Phố",
        "path": "Thải Giàng Phố, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Thải Giàng Phố, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02857",
        "parent_code": "085"
    },
    "02860": {
        "name": "Lầu Thí Ngài",
        "type": "xa",
        "slug": "lau-thi-ngai",
        "name_with_type": "Xã Lầu Thí Ngài",
        "path": "Lầu Thí Ngài, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Lầu Thí Ngài, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02860",
        "parent_code": "085"
    },
    "02863": {
        "name": "Hoàng Thu Phố",
        "type": "xa",
        "slug": "hoang-thu-pho",
        "name_with_type": "Xã Hoàng Thu Phố",
        "path": "Hoàng Thu Phố, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Hoàng Thu Phố, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02863",
        "parent_code": "085"
    },
    "02866": {
        "name": "Bản Phố",
        "type": "xa",
        "slug": "ban-pho",
        "name_with_type": "Xã Bản Phố",
        "path": "Bản Phố, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Bản Phố, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02866",
        "parent_code": "085"
    },
    "02869": {
        "name": "Bản Liền",
        "type": "xa",
        "slug": "ban-lien",
        "name_with_type": "Xã Bản Liền",
        "path": "Bản Liền, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Bản Liền, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02869",
        "parent_code": "085"
    },
    "02872": {
        "name": "Tà Chải",
        "type": "xa",
        "slug": "ta-chai",
        "name_with_type": "Xã Tà Chải",
        "path": "Tà Chải, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Tà Chải, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02872",
        "parent_code": "085"
    },
    "02875": {
        "name": "Na Hối",
        "type": "xa",
        "slug": "na-hoi",
        "name_with_type": "Xã Na Hối",
        "path": "Na Hối, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Na Hối, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02875",
        "parent_code": "085"
    },
    "02878": {
        "name": "Cốc Ly",
        "type": "xa",
        "slug": "coc-ly",
        "name_with_type": "Xã Cốc Ly",
        "path": "Cốc Ly, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Cốc Ly, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02878",
        "parent_code": "085"
    },
    "02881": {
        "name": "Nậm Mòn",
        "type": "xa",
        "slug": "nam-mon",
        "name_with_type": "Xã Nậm Mòn",
        "path": "Nậm Mòn, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Nậm Mòn, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02881",
        "parent_code": "085"
    },
    "02884": {
        "name": "Nậm Đét",
        "type": "xa",
        "slug": "nam-det",
        "name_with_type": "Xã Nậm Đét",
        "path": "Nậm Đét, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Nậm Đét, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02884",
        "parent_code": "085"
    },
    "02887": {
        "name": "Nậm Khánh",
        "type": "xa",
        "slug": "nam-khanh",
        "name_with_type": "Xã Nậm Khánh",
        "path": "Nậm Khánh, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Nậm Khánh, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02887",
        "parent_code": "085"
    },
    "02890": {
        "name": "Bảo Nhai",
        "type": "xa",
        "slug": "bao-nhai",
        "name_with_type": "Xã Bảo Nhai",
        "path": "Bảo Nhai, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Bảo Nhai, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02890",
        "parent_code": "085"
    },
    "02893": {
        "name": "Nậm Lúc",
        "type": "xa",
        "slug": "nam-luc",
        "name_with_type": "Xã Nậm Lúc",
        "path": "Nậm Lúc, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Nậm Lúc, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02893",
        "parent_code": "085"
    },
    "02896": {
        "name": "Cốc Lầu",
        "type": "xa",
        "slug": "coc-lau",
        "name_with_type": "Xã Cốc Lầu",
        "path": "Cốc Lầu, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Cốc Lầu, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02896",
        "parent_code": "085"
    },
    "02899": {
        "name": "Bản Cái",
        "type": "xa",
        "slug": "ban-cai",
        "name_with_type": "Xã Bản Cái",
        "path": "Bản Cái, Bắc Hà, Lào Cai",
        "path_with_type": "Xã Bản Cái, Huyện Bắc Hà, Tỉnh Lào Cai",
        "code": "02899",
        "parent_code": "085"
    },
    "02902": {
        "name": "N.T Phong Hải",
        "type": "thi-tran",
        "slug": "n-t-phong-hai",
        "name_with_type": "Thị trấn N.T Phong Hải",
        "path": "N.T Phong Hải, Bảo Thắng, Lào Cai",
        "path_with_type": "Thị trấn N.T Phong Hải, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02902",
        "parent_code": "086"
    },
    "02905": {
        "name": "Phố Lu",
        "type": "thi-tran",
        "slug": "pho-lu",
        "name_with_type": "Thị trấn Phố Lu",
        "path": "Phố Lu, Bảo Thắng, Lào Cai",
        "path_with_type": "Thị trấn Phố Lu, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02905",
        "parent_code": "086"
    },
    "02908": {
        "name": "Tằng Loỏng",
        "type": "thi-tran",
        "slug": "tang-loong",
        "name_with_type": "Thị trấn Tằng Loỏng",
        "path": "Tằng Loỏng, Bảo Thắng, Lào Cai",
        "path_with_type": "Thị trấn Tằng Loỏng, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02908",
        "parent_code": "086"
    },
    "02911": {
        "name": "Bản Phiệt",
        "type": "xa",
        "slug": "ban-phiet",
        "name_with_type": "Xã Bản Phiệt",
        "path": "Bản Phiệt, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Bản Phiệt, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02911",
        "parent_code": "086"
    },
    "02914": {
        "name": "Bản Cầm",
        "type": "xa",
        "slug": "ban-cam",
        "name_with_type": "Xã Bản Cầm",
        "path": "Bản Cầm, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Bản Cầm, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02914",
        "parent_code": "086"
    },
    "02917": {
        "name": "Thái Niên",
        "type": "xa",
        "slug": "thai-nien",
        "name_with_type": "Xã Thái Niên",
        "path": "Thái Niên, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Thái Niên, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02917",
        "parent_code": "086"
    },
    "02920": {
        "name": "Phong Niên",
        "type": "xa",
        "slug": "phong-nien",
        "name_with_type": "Xã Phong Niên",
        "path": "Phong Niên, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Phong Niên, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02920",
        "parent_code": "086"
    },
    "02923": {
        "name": "Gia Phú",
        "type": "xa",
        "slug": "gia-phu",
        "name_with_type": "Xã Gia Phú",
        "path": "Gia Phú, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Gia Phú, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02923",
        "parent_code": "086"
    },
    "02926": {
        "name": "Xuân Quang",
        "type": "xa",
        "slug": "xuan-quang",
        "name_with_type": "Xã Xuân Quang",
        "path": "Xuân Quang, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Xuân Quang, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02926",
        "parent_code": "086"
    },
    "02929": {
        "name": "Sơn Hải",
        "type": "xa",
        "slug": "son-hai",
        "name_with_type": "Xã Sơn Hải",
        "path": "Sơn Hải, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Sơn Hải, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02929",
        "parent_code": "086"
    },
    "02932": {
        "name": "Xuân Giao",
        "type": "xa",
        "slug": "xuan-giao",
        "name_with_type": "Xã Xuân Giao",
        "path": "Xuân Giao, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Xuân Giao, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02932",
        "parent_code": "086"
    },
    "02935": {
        "name": "Trì Quang",
        "type": "xa",
        "slug": "tri-quang",
        "name_with_type": "Xã Trì Quang",
        "path": "Trì Quang, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Trì Quang, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02935",
        "parent_code": "086"
    },
    "02938": {
        "name": "Sơn Hà",
        "type": "xa",
        "slug": "son-ha",
        "name_with_type": "Xã Sơn Hà",
        "path": "Sơn Hà, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Sơn Hà, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02938",
        "parent_code": "086"
    },
    "02941": {
        "name": "Phố Lu",
        "type": "xa",
        "slug": "pho-lu",
        "name_with_type": "Xã Phố Lu",
        "path": "Phố Lu, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Phố Lu, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02941",
        "parent_code": "086"
    },
    "02944": {
        "name": "Phú Nhuận",
        "type": "xa",
        "slug": "phu-nhuan",
        "name_with_type": "Xã Phú Nhuận",
        "path": "Phú Nhuận, Bảo Thắng, Lào Cai",
        "path_with_type": "Xã Phú Nhuận, Huyện Bảo Thắng, Tỉnh Lào Cai",
        "code": "02944",
        "parent_code": "086"
    },
    "02947": {
        "name": "Phố Ràng",
        "type": "thi-tran",
        "slug": "pho-rang",
        "name_with_type": "Thị trấn Phố Ràng",
        "path": "Phố Ràng, Bảo Yên, Lào Cai",
        "path_with_type": "Thị trấn Phố Ràng, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02947",
        "parent_code": "087"
    },
    "02950": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Tân Tiến, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02950",
        "parent_code": "087"
    },
    "02953": {
        "name": "Nghĩa Đô",
        "type": "xa",
        "slug": "nghia-do",
        "name_with_type": "Xã Nghĩa Đô",
        "path": "Nghĩa Đô, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Nghĩa Đô, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02953",
        "parent_code": "087"
    },
    "02956": {
        "name": "Vĩnh Yên",
        "type": "xa",
        "slug": "vinh-yen",
        "name_with_type": "Xã Vĩnh Yên",
        "path": "Vĩnh Yên, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Vĩnh Yên, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02956",
        "parent_code": "087"
    },
    "02959": {
        "name": "Điện Quan",
        "type": "xa",
        "slug": "dien-quan",
        "name_with_type": "Xã Điện Quan",
        "path": "Điện Quan, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Điện Quan, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02959",
        "parent_code": "087"
    },
    "02962": {
        "name": "Xuân Hoà",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hoà",
        "path": "Xuân Hoà, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Xuân Hoà, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02962",
        "parent_code": "087"
    },
    "02965": {
        "name": "Tân Dương",
        "type": "xa",
        "slug": "tan-duong",
        "name_with_type": "Xã Tân Dương",
        "path": "Tân Dương, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Tân Dương, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02965",
        "parent_code": "087"
    },
    "02968": {
        "name": "Thượng Hà",
        "type": "xa",
        "slug": "thuong-ha",
        "name_with_type": "Xã Thượng Hà",
        "path": "Thượng Hà, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Thượng Hà, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02968",
        "parent_code": "087"
    },
    "02971": {
        "name": "Kim Sơn",
        "type": "xa",
        "slug": "kim-son",
        "name_with_type": "Xã Kim Sơn",
        "path": "Kim Sơn, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Kim Sơn, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02971",
        "parent_code": "087"
    },
    "02974": {
        "name": "Cam Cọn",
        "type": "xa",
        "slug": "cam-con",
        "name_with_type": "Xã Cam Cọn",
        "path": "Cam Cọn, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Cam Cọn, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02974",
        "parent_code": "087"
    },
    "02977": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Minh Tân, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02977",
        "parent_code": "087"
    },
    "02980": {
        "name": "Xuân Thượng",
        "type": "xa",
        "slug": "xuan-thuong",
        "name_with_type": "Xã Xuân Thượng",
        "path": "Xuân Thượng, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Xuân Thượng, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02980",
        "parent_code": "087"
    },
    "02983": {
        "name": "Việt Tiến",
        "type": "xa",
        "slug": "viet-tien",
        "name_with_type": "Xã Việt Tiến",
        "path": "Việt Tiến, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Việt Tiến, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02983",
        "parent_code": "087"
    },
    "02986": {
        "name": "Yên Sơn",
        "type": "xa",
        "slug": "yen-son",
        "name_with_type": "Xã Yên Sơn",
        "path": "Yên Sơn, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Yên Sơn, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02986",
        "parent_code": "087"
    },
    "02989": {
        "name": "Bảo Hà",
        "type": "xa",
        "slug": "bao-ha",
        "name_with_type": "Xã Bảo Hà",
        "path": "Bảo Hà, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Bảo Hà, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02989",
        "parent_code": "087"
    },
    "02992": {
        "name": "Lương Sơn",
        "type": "xa",
        "slug": "luong-son",
        "name_with_type": "Xã Lương Sơn",
        "path": "Lương Sơn, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Lương Sơn, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02992",
        "parent_code": "087"
    },
    "02995": {
        "name": "Long Phúc",
        "type": "xa",
        "slug": "long-phuc",
        "name_with_type": "Xã Long Phúc",
        "path": "Long Phúc, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Long Phúc, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02995",
        "parent_code": "087"
    },
    "02998": {
        "name": "Long Khánh",
        "type": "xa",
        "slug": "long-khanh",
        "name_with_type": "Xã Long Khánh",
        "path": "Long Khánh, Bảo Yên, Lào Cai",
        "path_with_type": "Xã Long Khánh, Huyện Bảo Yên, Tỉnh Lào Cai",
        "code": "02998",
        "parent_code": "087"
    },
    "03001": {
        "name": "Sa Pa",
        "type": "thi-tran",
        "slug": "sa-pa",
        "name_with_type": "Thị trấn Sa Pa",
        "path": "Sa Pa, Sa Pa, Lào Cai",
        "path_with_type": "Thị trấn Sa Pa, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03001",
        "parent_code": "088"
    },
    "03004": {
        "name": "Bản Khoang",
        "type": "xa",
        "slug": "ban-khoang",
        "name_with_type": "Xã Bản Khoang",
        "path": "Bản Khoang, Sa Pa, Lào Cai",
        "path_with_type": "Xã Bản Khoang, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03004",
        "parent_code": "088"
    },
    "03007": {
        "name": "Tả Giàng Phình",
        "type": "xa",
        "slug": "ta-giang-phinh",
        "name_with_type": "Xã Tả Giàng Phình",
        "path": "Tả Giàng Phình, Sa Pa, Lào Cai",
        "path_with_type": "Xã Tả Giàng Phình, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03007",
        "parent_code": "088"
    },
    "03010": {
        "name": "Trung Chải",
        "type": "xa",
        "slug": "trung-chai",
        "name_with_type": "Xã Trung Chải",
        "path": "Trung Chải, Sa Pa, Lào Cai",
        "path_with_type": "Xã Trung Chải, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03010",
        "parent_code": "088"
    },
    "03013": {
        "name": "Tả Phìn",
        "type": "xa",
        "slug": "ta-phin",
        "name_with_type": "Xã Tả Phìn",
        "path": "Tả Phìn, Sa Pa, Lào Cai",
        "path_with_type": "Xã Tả Phìn, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03013",
        "parent_code": "088"
    },
    "03016": {
        "name": "Sa Pả",
        "type": "xa",
        "slug": "sa-pa",
        "name_with_type": "Xã Sa Pả",
        "path": "Sa Pả, Sa Pa, Lào Cai",
        "path_with_type": "Xã Sa Pả, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03016",
        "parent_code": "088"
    },
    "03019": {
        "name": "San Sả Hồ",
        "type": "xa",
        "slug": "san-sa-ho",
        "name_with_type": "Xã San Sả Hồ",
        "path": "San Sả Hồ, Sa Pa, Lào Cai",
        "path_with_type": "Xã San Sả Hồ, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03019",
        "parent_code": "088"
    },
    "03022": {
        "name": "Bản Phùng",
        "type": "xa",
        "slug": "ban-phung",
        "name_with_type": "Xã Bản Phùng",
        "path": "Bản Phùng, Sa Pa, Lào Cai",
        "path_with_type": "Xã Bản Phùng, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03022",
        "parent_code": "088"
    },
    "03025": {
        "name": "Hầu Thào",
        "type": "xa",
        "slug": "hau-thao",
        "name_with_type": "Xã Hầu Thào",
        "path": "Hầu Thào, Sa Pa, Lào Cai",
        "path_with_type": "Xã Hầu Thào, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03025",
        "parent_code": "088"
    },
    "03028": {
        "name": "Lao Chải",
        "type": "xa",
        "slug": "lao-chai",
        "name_with_type": "Xã Lao Chải",
        "path": "Lao Chải, Sa Pa, Lào Cai",
        "path_with_type": "Xã Lao Chải, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03028",
        "parent_code": "088"
    },
    "03031": {
        "name": "Thanh Kim",
        "type": "xa",
        "slug": "thanh-kim",
        "name_with_type": "Xã Thanh Kim",
        "path": "Thanh Kim, Sa Pa, Lào Cai",
        "path_with_type": "Xã Thanh Kim, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03031",
        "parent_code": "088"
    },
    "03034": {
        "name": "Suối Thầu",
        "type": "xa",
        "slug": "suoi-thau",
        "name_with_type": "Xã Suối Thầu",
        "path": "Suối Thầu, Sa Pa, Lào Cai",
        "path_with_type": "Xã Suối Thầu, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03034",
        "parent_code": "088"
    },
    "03037": {
        "name": "Sử Pán",
        "type": "xa",
        "slug": "su-pan",
        "name_with_type": "Xã Sử Pán",
        "path": "Sử Pán, Sa Pa, Lào Cai",
        "path_with_type": "Xã Sử Pán, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03037",
        "parent_code": "088"
    },
    "03040": {
        "name": "Tả Van",
        "type": "xa",
        "slug": "ta-van",
        "name_with_type": "Xã Tả Van",
        "path": "Tả Van, Sa Pa, Lào Cai",
        "path_with_type": "Xã Tả Van, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03040",
        "parent_code": "088"
    },
    "03043": {
        "name": "Thanh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thanh Phú",
        "path": "Thanh Phú, Sa Pa, Lào Cai",
        "path_with_type": "Xã Thanh Phú, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03043",
        "parent_code": "088"
    },
    "03046": {
        "name": "Bản Hồ",
        "type": "xa",
        "slug": "ban-ho",
        "name_with_type": "Xã Bản Hồ",
        "path": "Bản Hồ, Sa Pa, Lào Cai",
        "path_with_type": "Xã Bản Hồ, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03046",
        "parent_code": "088"
    },
    "03049": {
        "name": "Nậm Sài",
        "type": "xa",
        "slug": "nam-sai",
        "name_with_type": "Xã Nậm Sài",
        "path": "Nậm Sài, Sa Pa, Lào Cai",
        "path_with_type": "Xã Nậm Sài, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03049",
        "parent_code": "088"
    },
    "03052": {
        "name": "Nậm Cang",
        "type": "xa",
        "slug": "nam-cang",
        "name_with_type": "Xã Nậm Cang",
        "path": "Nậm Cang, Sa Pa, Lào Cai",
        "path_with_type": "Xã Nậm Cang, Huyện Sa Pa, Tỉnh Lào Cai",
        "code": "03052",
        "parent_code": "088"
    },
    "03055": {
        "name": "Khánh Yên",
        "type": "thi-tran",
        "slug": "khanh-yen",
        "name_with_type": "Thị trấn Khánh Yên",
        "path": "Khánh Yên, Văn Bàn, Lào Cai",
        "path_with_type": "Thị trấn Khánh Yên, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03055",
        "parent_code": "089"
    },
    "03058": {
        "name": "Văn Sơn",
        "type": "xa",
        "slug": "van-son",
        "name_with_type": "Xã Văn Sơn",
        "path": "Văn Sơn, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Văn Sơn, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03058",
        "parent_code": "089"
    },
    "03061": {
        "name": "Võ Lao",
        "type": "xa",
        "slug": "vo-lao",
        "name_with_type": "Xã Võ Lao",
        "path": "Võ Lao, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Võ Lao, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03061",
        "parent_code": "089"
    },
    "03064": {
        "name": "Sơn Thuỷ",
        "type": "xa",
        "slug": "son-thuy",
        "name_with_type": "Xã Sơn Thuỷ",
        "path": "Sơn Thuỷ, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Sơn Thuỷ, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03064",
        "parent_code": "089"
    },
    "03067": {
        "name": "Nậm Mả",
        "type": "xa",
        "slug": "nam-ma",
        "name_with_type": "Xã Nậm Mả",
        "path": "Nậm Mả, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Nậm Mả, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03067",
        "parent_code": "089"
    },
    "03070": {
        "name": "Tân Thượng",
        "type": "xa",
        "slug": "tan-thuong",
        "name_with_type": "Xã Tân Thượng",
        "path": "Tân Thượng, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Tân Thượng, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03070",
        "parent_code": "089"
    },
    "03073": {
        "name": "Nậm Rạng",
        "type": "xa",
        "slug": "nam-rang",
        "name_with_type": "Xã Nậm Rạng",
        "path": "Nậm Rạng, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Nậm Rạng, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03073",
        "parent_code": "089"
    },
    "03076": {
        "name": "Nậm Chầy",
        "type": "xa",
        "slug": "nam-chay",
        "name_with_type": "Xã Nậm Chầy",
        "path": "Nậm Chầy, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Nậm Chầy, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03076",
        "parent_code": "089"
    },
    "03079": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Tân An, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03079",
        "parent_code": "089"
    },
    "03082": {
        "name": "Khánh Yên Thượng",
        "type": "xa",
        "slug": "khanh-yen-thuong",
        "name_with_type": "Xã Khánh Yên Thượng",
        "path": "Khánh Yên Thượng, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Khánh Yên Thượng, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03082",
        "parent_code": "089"
    },
    "03085": {
        "name": "Nậm Xé",
        "type": "xa",
        "slug": "nam-xe",
        "name_with_type": "Xã Nậm Xé",
        "path": "Nậm Xé, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Nậm Xé, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03085",
        "parent_code": "089"
    },
    "03088": {
        "name": "Dần Thàng",
        "type": "xa",
        "slug": "dan-thang",
        "name_with_type": "Xã Dần Thàng",
        "path": "Dần Thàng, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Dần Thàng, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03088",
        "parent_code": "089"
    },
    "03091": {
        "name": "Chiềng Ken",
        "type": "xa",
        "slug": "chieng-ken",
        "name_with_type": "Xã Chiềng Ken",
        "path": "Chiềng Ken, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Chiềng Ken, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03091",
        "parent_code": "089"
    },
    "03094": {
        "name": "Làng Giàng",
        "type": "xa",
        "slug": "lang-giang",
        "name_with_type": "Xã Làng Giàng",
        "path": "Làng Giàng, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Làng Giàng, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03094",
        "parent_code": "089"
    },
    "03097": {
        "name": "Hoà Mạc",
        "type": "xa",
        "slug": "hoa-mac",
        "name_with_type": "Xã Hoà Mạc",
        "path": "Hoà Mạc, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Hoà Mạc, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03097",
        "parent_code": "089"
    },
    "03100": {
        "name": "Khánh Yên Trung",
        "type": "xa",
        "slug": "khanh-yen-trung",
        "name_with_type": "Xã Khánh Yên Trung",
        "path": "Khánh Yên Trung, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Khánh Yên Trung, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03100",
        "parent_code": "089"
    },
    "03103": {
        "name": "Khánh Yên Hạ",
        "type": "xa",
        "slug": "khanh-yen-ha",
        "name_with_type": "Xã Khánh Yên Hạ",
        "path": "Khánh Yên Hạ, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Khánh Yên Hạ, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03103",
        "parent_code": "089"
    },
    "03106": {
        "name": "Dương Quỳ",
        "type": "xa",
        "slug": "duong-quy",
        "name_with_type": "Xã Dương Quỳ",
        "path": "Dương Quỳ, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Dương Quỳ, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03106",
        "parent_code": "089"
    },
    "03109": {
        "name": "Nậm Tha",
        "type": "xa",
        "slug": "nam-tha",
        "name_with_type": "Xã Nậm Tha",
        "path": "Nậm Tha, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Nậm Tha, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03109",
        "parent_code": "089"
    },
    "03112": {
        "name": "Minh Lương",
        "type": "xa",
        "slug": "minh-luong",
        "name_with_type": "Xã Minh Lương",
        "path": "Minh Lương, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Minh Lương, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03112",
        "parent_code": "089"
    },
    "03115": {
        "name": "Thẩm Dương",
        "type": "xa",
        "slug": "tham-duong",
        "name_with_type": "Xã Thẩm Dương",
        "path": "Thẩm Dương, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Thẩm Dương, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03115",
        "parent_code": "089"
    },
    "03118": {
        "name": "Liêm Phú",
        "type": "xa",
        "slug": "liem-phu",
        "name_with_type": "Xã Liêm Phú",
        "path": "Liêm Phú, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Liêm Phú, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03118",
        "parent_code": "089"
    },
    "03121": {
        "name": "Nậm Xây",
        "type": "xa",
        "slug": "nam-xay",
        "name_with_type": "Xã Nậm Xây",
        "path": "Nậm Xây, Văn Bàn, Lào Cai",
        "path_with_type": "Xã Nậm Xây, Huyện Văn Bàn, Tỉnh Lào Cai",
        "code": "03121",
        "parent_code": "089"
    },
    "29542": {
        "name": "9",
        "type": "phuong",
        "slug": "9",
        "name_with_type": "Phường 9",
        "path": "9, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Phường 9, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29542",
        "parent_code": "855"
    },
    "29545": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Phường 5, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29545",
        "parent_code": "855"
    },
    "29548": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Phường 1, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29548",
        "parent_code": "855"
    },
    "29551": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Phường 2, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29551",
        "parent_code": "855"
    },
    "29554": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Phường 4, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29554",
        "parent_code": "855"
    },
    "29557": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Phường 3, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29557",
        "parent_code": "855"
    },
    "29560": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Phường 8, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29560",
        "parent_code": "855"
    },
    "29563": {
        "name": "Tân Ngãi",
        "type": "xa",
        "slug": "tan-ngai",
        "name_with_type": "Xã Tân Ngãi",
        "path": "Tân Ngãi, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Xã Tân Ngãi, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29563",
        "parent_code": "855"
    },
    "29566": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Xã Tân Hòa, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29566",
        "parent_code": "855"
    },
    "29569": {
        "name": "Tân Hội",
        "type": "xa",
        "slug": "tan-hoi",
        "name_with_type": "Xã Tân Hội",
        "path": "Tân Hội, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Xã Tân Hội, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29569",
        "parent_code": "855"
    },
    "29572": {
        "name": "Trường An",
        "type": "xa",
        "slug": "truong-an",
        "name_with_type": "Xã Trường An",
        "path": "Trường An, Vĩnh Long, Vĩnh Long",
        "path_with_type": "Xã Trường An, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
        "code": "29572",
        "parent_code": "855"
    },
    "29575": {
        "name": "Long Hồ",
        "type": "thi-tran",
        "slug": "long-ho",
        "name_with_type": "Thị trấn Long Hồ",
        "path": "Long Hồ, Long Hồ, Vĩnh Long",
        "path_with_type": "Thị trấn Long Hồ, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29575",
        "parent_code": "857"
    },
    "29578": {
        "name": "Đồng Phú",
        "type": "xa",
        "slug": "dong-phu",
        "name_with_type": "Xã Đồng Phú",
        "path": "Đồng Phú, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Đồng Phú, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29578",
        "parent_code": "857"
    },
    "29581": {
        "name": "Bình Hòa Phước",
        "type": "xa",
        "slug": "binh-hoa-phuoc",
        "name_with_type": "Xã Bình Hòa Phước",
        "path": "Bình Hòa Phước, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Bình Hòa Phước, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29581",
        "parent_code": "857"
    },
    "29584": {
        "name": "Hòa Ninh",
        "type": "xa",
        "slug": "hoa-ninh",
        "name_with_type": "Xã Hòa Ninh",
        "path": "Hòa Ninh, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Hòa Ninh, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29584",
        "parent_code": "857"
    },
    "29587": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã An Bình, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29587",
        "parent_code": "857"
    },
    "29590": {
        "name": "Thanh Đức",
        "type": "xa",
        "slug": "thanh-duc",
        "name_with_type": "Xã Thanh Đức",
        "path": "Thanh Đức, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Thanh Đức, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29590",
        "parent_code": "857"
    },
    "29593": {
        "name": "Tân Hạnh",
        "type": "xa",
        "slug": "tan-hanh",
        "name_with_type": "Xã Tân Hạnh",
        "path": "Tân Hạnh, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Tân Hạnh, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29593",
        "parent_code": "857"
    },
    "29596": {
        "name": "Phước Hậu",
        "type": "xa",
        "slug": "phuoc-hau",
        "name_with_type": "Xã Phước Hậu",
        "path": "Phước Hậu, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Phước Hậu, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29596",
        "parent_code": "857"
    },
    "29599": {
        "name": "Long Phước",
        "type": "xa",
        "slug": "long-phuoc",
        "name_with_type": "Xã Long Phước",
        "path": "Long Phước, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Long Phước, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29599",
        "parent_code": "857"
    },
    "29602": {
        "name": "Phú Đức",
        "type": "xa",
        "slug": "phu-duc",
        "name_with_type": "Xã Phú Đức",
        "path": "Phú Đức, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Phú Đức, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29602",
        "parent_code": "857"
    },
    "29605": {
        "name": "Lộc Hòa",
        "type": "xa",
        "slug": "loc-hoa",
        "name_with_type": "Xã Lộc Hòa",
        "path": "Lộc Hòa, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Lộc Hòa, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29605",
        "parent_code": "857"
    },
    "29608": {
        "name": "Long An",
        "type": "xa",
        "slug": "long-an",
        "name_with_type": "Xã Long An",
        "path": "Long An, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Long An, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29608",
        "parent_code": "857"
    },
    "29611": {
        "name": "Phú Quới",
        "type": "xa",
        "slug": "phu-quoi",
        "name_with_type": "Xã Phú Quới",
        "path": "Phú Quới, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Phú Quới, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29611",
        "parent_code": "857"
    },
    "29614": {
        "name": "Thạnh Quới",
        "type": "xa",
        "slug": "thanh-quoi",
        "name_with_type": "Xã Thạnh Quới",
        "path": "Thạnh Quới, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Thạnh Quới, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29614",
        "parent_code": "857"
    },
    "29617": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Long Hồ, Vĩnh Long",
        "path_with_type": "Xã Hòa Phú, Huyện Long Hồ, Tỉnh Vĩnh Long",
        "code": "29617",
        "parent_code": "857"
    },
    "29620": {
        "name": "Cái Nhum",
        "type": "thi-tran",
        "slug": "cai-nhum",
        "name_with_type": "Thị trấn Cái Nhum",
        "path": "Cái Nhum, Mang Thít, Vĩnh Long",
        "path_with_type": "Thị trấn Cái Nhum, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29620",
        "parent_code": "858"
    },
    "29623": {
        "name": "Mỹ An",
        "type": "xa",
        "slug": "my-an",
        "name_with_type": "Xã Mỹ An",
        "path": "Mỹ An, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Mỹ An, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29623",
        "parent_code": "858"
    },
    "29626": {
        "name": "Mỹ Phước",
        "type": "xa",
        "slug": "my-phuoc",
        "name_with_type": "Xã Mỹ Phước",
        "path": "Mỹ Phước, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Mỹ Phước, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29626",
        "parent_code": "858"
    },
    "29629": {
        "name": "An Phước",
        "type": "xa",
        "slug": "an-phuoc",
        "name_with_type": "Xã An Phước",
        "path": "An Phước, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã An Phước, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29629",
        "parent_code": "858"
    },
    "29632": {
        "name": "Nhơn Phú",
        "type": "xa",
        "slug": "nhon-phu",
        "name_with_type": "Xã Nhơn Phú",
        "path": "Nhơn Phú, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Nhơn Phú, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29632",
        "parent_code": "858"
    },
    "29635": {
        "name": "Long Mỹ",
        "type": "xa",
        "slug": "long-my",
        "name_with_type": "Xã Long Mỹ",
        "path": "Long Mỹ, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Long Mỹ, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29635",
        "parent_code": "858"
    },
    "29638": {
        "name": "Hòa Tịnh",
        "type": "xa",
        "slug": "hoa-tinh",
        "name_with_type": "Xã Hòa Tịnh",
        "path": "Hòa Tịnh, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Hòa Tịnh, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29638",
        "parent_code": "858"
    },
    "29641": {
        "name": "Chánh Hội",
        "type": "xa",
        "slug": "chanh-hoi",
        "name_with_type": "Xã Chánh Hội",
        "path": "Chánh Hội, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Chánh Hội, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29641",
        "parent_code": "858"
    },
    "29644": {
        "name": "Bình Phước",
        "type": "xa",
        "slug": "binh-phuoc",
        "name_with_type": "Xã Bình Phước",
        "path": "Bình Phước, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Bình Phước, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29644",
        "parent_code": "858"
    },
    "29647": {
        "name": "Chánh An",
        "type": "xa",
        "slug": "chanh-an",
        "name_with_type": "Xã Chánh An",
        "path": "Chánh An, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Chánh An, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29647",
        "parent_code": "858"
    },
    "29650": {
        "name": "Tân An Hội",
        "type": "xa",
        "slug": "tan-an-hoi",
        "name_with_type": "Xã Tân An Hội",
        "path": "Tân An Hội, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Tân An Hội, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29650",
        "parent_code": "858"
    },
    "29653": {
        "name": "Tân Long",
        "type": "xa",
        "slug": "tan-long",
        "name_with_type": "Xã Tân Long",
        "path": "Tân Long, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Tân Long, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29653",
        "parent_code": "858"
    },
    "29656": {
        "name": "Tân Long Hội",
        "type": "xa",
        "slug": "tan-long-hoi",
        "name_with_type": "Xã Tân Long Hội",
        "path": "Tân Long Hội, Mang Thít, Vĩnh Long",
        "path_with_type": "Xã Tân Long Hội, Huyện Mang Thít, Tỉnh Vĩnh Long",
        "code": "29656",
        "parent_code": "858"
    },
    "29659": {
        "name": "Vũng Liêm",
        "type": "thi-tran",
        "slug": "vung-liem",
        "name_with_type": "Thị trấn Vũng Liêm",
        "path": "Vũng Liêm, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Thị trấn Vũng Liêm, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29659",
        "parent_code": "859"
    },
    "29662": {
        "name": "Tân Quới Trung",
        "type": "xa",
        "slug": "tan-quoi-trung",
        "name_with_type": "Xã Tân Quới Trung",
        "path": "Tân Quới Trung, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Tân Quới Trung, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29662",
        "parent_code": "859"
    },
    "29665": {
        "name": "Quới Thiện",
        "type": "xa",
        "slug": "quoi-thien",
        "name_with_type": "Xã Quới Thiện",
        "path": "Quới Thiện, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Quới Thiện, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29665",
        "parent_code": "859"
    },
    "29668": {
        "name": "Quới An",
        "type": "xa",
        "slug": "quoi-an",
        "name_with_type": "Xã Quới An",
        "path": "Quới An, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Quới An, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29668",
        "parent_code": "859"
    },
    "29671": {
        "name": "Trung Chánh",
        "type": "xa",
        "slug": "trung-chanh",
        "name_with_type": "Xã Trung Chánh",
        "path": "Trung Chánh, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Trung Chánh, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29671",
        "parent_code": "859"
    },
    "29674": {
        "name": "Tân An Luông",
        "type": "xa",
        "slug": "tan-an-luong",
        "name_with_type": "Xã Tân An Luông",
        "path": "Tân An Luông, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Tân An Luông, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29674",
        "parent_code": "859"
    },
    "29677": {
        "name": "Thanh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thanh Bình",
        "path": "Thanh Bình, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Thanh Bình, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29677",
        "parent_code": "859"
    },
    "29680": {
        "name": "Trung Thành Tây",
        "type": "xa",
        "slug": "trung-thanh-tay",
        "name_with_type": "Xã Trung Thành Tây",
        "path": "Trung Thành Tây, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Trung Thành Tây, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29680",
        "parent_code": "859"
    },
    "29683": {
        "name": "Trung Hiệp",
        "type": "xa",
        "slug": "trung-hiep",
        "name_with_type": "Xã Trung Hiệp",
        "path": "Trung Hiệp, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Trung Hiệp, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29683",
        "parent_code": "859"
    },
    "29686": {
        "name": "Hiếu Phụng",
        "type": "xa",
        "slug": "hieu-phung",
        "name_with_type": "Xã Hiếu Phụng",
        "path": "Hiếu Phụng, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Hiếu Phụng, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29686",
        "parent_code": "859"
    },
    "29689": {
        "name": "Trung Thành Đông",
        "type": "xa",
        "slug": "trung-thanh-dong",
        "name_with_type": "Xã Trung Thành Đông",
        "path": "Trung Thành Đông, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Trung Thành Đông, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29689",
        "parent_code": "859"
    },
    "29692": {
        "name": "Trung Thành",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thành",
        "path": "Trung Thành, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Trung Thành, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29692",
        "parent_code": "859"
    },
    "29695": {
        "name": "Trung Hiếu",
        "type": "xa",
        "slug": "trung-hieu",
        "name_with_type": "Xã Trung Hiếu",
        "path": "Trung Hiếu, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Trung Hiếu, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29695",
        "parent_code": "859"
    },
    "29698": {
        "name": "Trung Ngãi",
        "type": "xa",
        "slug": "trung-ngai",
        "name_with_type": "Xã Trung Ngãi",
        "path": "Trung Ngãi, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Trung Ngãi, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29698",
        "parent_code": "859"
    },
    "29701": {
        "name": "Hiếu Thuận",
        "type": "xa",
        "slug": "hieu-thuan",
        "name_with_type": "Xã Hiếu Thuận",
        "path": "Hiếu Thuận, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Hiếu Thuận, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29701",
        "parent_code": "859"
    },
    "29704": {
        "name": "Trung Nghĩa",
        "type": "xa",
        "slug": "trung-nghia",
        "name_with_type": "Xã Trung Nghĩa",
        "path": "Trung Nghĩa, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Trung Nghĩa, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29704",
        "parent_code": "859"
    },
    "29707": {
        "name": "Trung An",
        "type": "xa",
        "slug": "trung-an",
        "name_with_type": "Xã Trung An",
        "path": "Trung An, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Trung An, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29707",
        "parent_code": "859"
    },
    "29710": {
        "name": "Hiếu Nhơn",
        "type": "xa",
        "slug": "hieu-nhon",
        "name_with_type": "Xã Hiếu Nhơn",
        "path": "Hiếu Nhơn, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Hiếu Nhơn, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29710",
        "parent_code": "859"
    },
    "29713": {
        "name": "Hiếu Thành",
        "type": "xa",
        "slug": "hieu-thanh",
        "name_with_type": "Xã Hiếu Thành",
        "path": "Hiếu Thành, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Hiếu Thành, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29713",
        "parent_code": "859"
    },
    "29716": {
        "name": "Hiếu Nghĩa",
        "type": "xa",
        "slug": "hieu-nghia",
        "name_with_type": "Xã Hiếu Nghĩa",
        "path": "Hiếu Nghĩa, Vũng Liêm, Vĩnh Long",
        "path_with_type": "Xã Hiếu Nghĩa, Huyện  Vũng Liêm, Tỉnh Vĩnh Long",
        "code": "29716",
        "parent_code": "859"
    },
    "29719": {
        "name": "Tam Bình",
        "type": "thi-tran",
        "slug": "tam-binh",
        "name_with_type": "Thị trấn Tam Bình",
        "path": "Tam Bình, Tam Bình, Vĩnh Long",
        "path_with_type": "Thị trấn Tam Bình, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29719",
        "parent_code": "860"
    },
    "29722": {
        "name": "Tân Lộc",
        "type": "xa",
        "slug": "tan-loc",
        "name_with_type": "Xã Tân Lộc",
        "path": "Tân Lộc, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Tân Lộc, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29722",
        "parent_code": "860"
    },
    "29725": {
        "name": "Phú Thịnh",
        "type": "xa",
        "slug": "phu-thinh",
        "name_with_type": "Xã Phú Thịnh",
        "path": "Phú Thịnh, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Phú Thịnh, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29725",
        "parent_code": "860"
    },
    "29728": {
        "name": "Hậu Lộc",
        "type": "xa",
        "slug": "hau-loc",
        "name_with_type": "Xã Hậu Lộc",
        "path": "Hậu Lộc, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Hậu Lộc, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29728",
        "parent_code": "860"
    },
    "29731": {
        "name": "Hòa Thạnh",
        "type": "xa",
        "slug": "hoa-thanh",
        "name_with_type": "Xã Hòa Thạnh",
        "path": "Hòa Thạnh, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Hòa Thạnh, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29731",
        "parent_code": "860"
    },
    "29734": {
        "name": "Hoà Lộc",
        "type": "xa",
        "slug": "hoa-loc",
        "name_with_type": "Xã Hoà Lộc",
        "path": "Hoà Lộc, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Hoà Lộc, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29734",
        "parent_code": "860"
    },
    "29737": {
        "name": "Phú Lộc",
        "type": "xa",
        "slug": "phu-loc",
        "name_with_type": "Xã Phú Lộc",
        "path": "Phú Lộc, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Phú Lộc, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29737",
        "parent_code": "860"
    },
    "29740": {
        "name": "Song Phú",
        "type": "xa",
        "slug": "song-phu",
        "name_with_type": "Xã Song Phú",
        "path": "Song Phú, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Song Phú, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29740",
        "parent_code": "860"
    },
    "29743": {
        "name": "Hòa Hiệp",
        "type": "xa",
        "slug": "hoa-hiep",
        "name_with_type": "Xã Hòa Hiệp",
        "path": "Hòa Hiệp, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Hòa Hiệp, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29743",
        "parent_code": "860"
    },
    "29746": {
        "name": "Mỹ Lộc",
        "type": "xa",
        "slug": "my-loc",
        "name_with_type": "Xã Mỹ Lộc",
        "path": "Mỹ Lộc, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Mỹ Lộc, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29746",
        "parent_code": "860"
    },
    "29749": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Tân Phú, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29749",
        "parent_code": "860"
    },
    "29752": {
        "name": "Long Phú",
        "type": "xa",
        "slug": "long-phu",
        "name_with_type": "Xã Long Phú",
        "path": "Long Phú, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Long Phú, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29752",
        "parent_code": "860"
    },
    "29755": {
        "name": "Mỹ Thạnh Trung",
        "type": "xa",
        "slug": "my-thanh-trung",
        "name_with_type": "Xã Mỹ Thạnh Trung",
        "path": "Mỹ Thạnh Trung, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Mỹ Thạnh Trung, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29755",
        "parent_code": "860"
    },
    "29758": {
        "name": "Tường Lộc",
        "type": "xa",
        "slug": "tuong-loc",
        "name_with_type": "Xã Tường Lộc",
        "path": "Tường Lộc, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Tường Lộc, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29758",
        "parent_code": "860"
    },
    "29761": {
        "name": "Loan Mỹ",
        "type": "xa",
        "slug": "loan-my",
        "name_with_type": "Xã Loan Mỹ",
        "path": "Loan Mỹ, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Loan Mỹ, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29761",
        "parent_code": "860"
    },
    "29764": {
        "name": "Ngãi Tứ",
        "type": "xa",
        "slug": "ngai-tu",
        "name_with_type": "Xã Ngãi Tứ",
        "path": "Ngãi Tứ, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Ngãi Tứ, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29764",
        "parent_code": "860"
    },
    "29767": {
        "name": "Bình Ninh",
        "type": "xa",
        "slug": "binh-ninh",
        "name_with_type": "Xã Bình Ninh",
        "path": "Bình Ninh, Tam Bình, Vĩnh Long",
        "path_with_type": "Xã Bình Ninh, Huyện Tam Bình, Tỉnh Vĩnh Long",
        "code": "29767",
        "parent_code": "860"
    },
    "29770": {
        "name": "Cái Vồn",
        "type": "phuong",
        "slug": "cai-von",
        "name_with_type": "Phường Cái Vồn",
        "path": "Cái Vồn, Bình Minh, Vĩnh Long",
        "path_with_type": "Phường Cái Vồn, Thị xã Bình Minh, Tỉnh Vĩnh Long",
        "code": "29770",
        "parent_code": "861"
    },
    "29771": {
        "name": "Thành Phước",
        "type": "phuong",
        "slug": "thanh-phuoc",
        "name_with_type": "Phường Thành Phước",
        "path": "Thành Phước, Bình Minh, Vĩnh Long",
        "path_with_type": "Phường Thành Phước, Thị xã Bình Minh, Tỉnh Vĩnh Long",
        "code": "29771",
        "parent_code": "861"
    },
    "29806": {
        "name": "Thuận An",
        "type": "xa",
        "slug": "thuan-an",
        "name_with_type": "Xã Thuận An",
        "path": "Thuận An, Bình Minh, Vĩnh Long",
        "path_with_type": "Xã Thuận An, Thị xã Bình Minh, Tỉnh Vĩnh Long",
        "code": "29806",
        "parent_code": "861"
    },
    "29809": {
        "name": "Đông Thạnh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thạnh",
        "path": "Đông Thạnh, Bình Minh, Vĩnh Long",
        "path_with_type": "Xã Đông Thạnh, Thị xã Bình Minh, Tỉnh Vĩnh Long",
        "code": "29809",
        "parent_code": "861"
    },
    "29812": {
        "name": "Đông Bình",
        "type": "xa",
        "slug": "dong-binh",
        "name_with_type": "Xã Đông Bình",
        "path": "Đông Bình, Bình Minh, Vĩnh Long",
        "path_with_type": "Xã Đông Bình, Thị xã Bình Minh, Tỉnh Vĩnh Long",
        "code": "29812",
        "parent_code": "861"
    },
    "29813": {
        "name": "Đông Thuận",
        "type": "phuong",
        "slug": "dong-thuan",
        "name_with_type": "Phường Đông Thuận",
        "path": "Đông Thuận, Bình Minh, Vĩnh Long",
        "path_with_type": "Phường Đông Thuận, Thị xã Bình Minh, Tỉnh Vĩnh Long",
        "code": "29813",
        "parent_code": "861"
    },
    "29815": {
        "name": "Mỹ Hòa",
        "type": "xa",
        "slug": "my-hoa",
        "name_with_type": "Xã Mỹ Hòa",
        "path": "Mỹ Hòa, Bình Minh, Vĩnh Long",
        "path_with_type": "Xã Mỹ Hòa, Thị xã Bình Minh, Tỉnh Vĩnh Long",
        "code": "29815",
        "parent_code": "861"
    },
    "29818": {
        "name": "Đông Thành",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thành",
        "path": "Đông Thành, Bình Minh, Vĩnh Long",
        "path_with_type": "Xã Đông Thành, Thị xã Bình Minh, Tỉnh Vĩnh Long",
        "code": "29818",
        "parent_code": "861"
    },
    "29821": {
        "name": "Trà Ôn",
        "type": "thi-tran",
        "slug": "tra-on",
        "name_with_type": "Thị trấn Trà Ôn",
        "path": "Trà Ôn, Trà Ôn, Vĩnh Long",
        "path_with_type": "Thị trấn Trà Ôn, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29821",
        "parent_code": "862"
    },
    "29824": {
        "name": "Xuân Hiệp",
        "type": "xa",
        "slug": "xuan-hiep",
        "name_with_type": "Xã Xuân Hiệp",
        "path": "Xuân Hiệp, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Xuân Hiệp, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29824",
        "parent_code": "862"
    },
    "29827": {
        "name": "Nhơn Bình",
        "type": "xa",
        "slug": "nhon-binh",
        "name_with_type": "Xã Nhơn Bình",
        "path": "Nhơn Bình, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Nhơn Bình, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29827",
        "parent_code": "862"
    },
    "29830": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Hòa Bình, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29830",
        "parent_code": "862"
    },
    "29833": {
        "name": "Thới Hòa",
        "type": "xa",
        "slug": "thoi-hoa",
        "name_with_type": "Xã Thới Hòa",
        "path": "Thới Hòa, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Thới Hòa, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29833",
        "parent_code": "862"
    },
    "29836": {
        "name": "Trà Côn",
        "type": "xa",
        "slug": "tra-con",
        "name_with_type": "Xã Trà Côn",
        "path": "Trà Côn, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Trà Côn, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29836",
        "parent_code": "862"
    },
    "29839": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Tân Mỹ, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29839",
        "parent_code": "862"
    },
    "29842": {
        "name": "Hựu Thành",
        "type": "xa",
        "slug": "huu-thanh",
        "name_with_type": "Xã Hựu Thành",
        "path": "Hựu Thành, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Hựu Thành, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29842",
        "parent_code": "862"
    },
    "29845": {
        "name": "Vĩnh Xuân",
        "type": "xa",
        "slug": "vinh-xuan",
        "name_with_type": "Xã Vĩnh Xuân",
        "path": "Vĩnh Xuân, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Vĩnh Xuân, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29845",
        "parent_code": "862"
    },
    "29848": {
        "name": "Thuận Thới",
        "type": "xa",
        "slug": "thuan-thoi",
        "name_with_type": "Xã Thuận Thới",
        "path": "Thuận Thới, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Thuận Thới, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29848",
        "parent_code": "862"
    },
    "29851": {
        "name": "Phú Thành",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thành",
        "path": "Phú Thành, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Phú Thành, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29851",
        "parent_code": "862"
    },
    "29854": {
        "name": "Thiện Mỹ",
        "type": "xa",
        "slug": "thien-my",
        "name_with_type": "Xã Thiện Mỹ",
        "path": "Thiện Mỹ, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Thiện Mỹ, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29854",
        "parent_code": "862"
    },
    "29857": {
        "name": "Lục Sỹ Thành",
        "type": "xa",
        "slug": "luc-sy-thanh",
        "name_with_type": "Xã Lục Sỹ Thành",
        "path": "Lục Sỹ Thành, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Lục Sỹ Thành, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29857",
        "parent_code": "862"
    },
    "29860": {
        "name": "Tích Thiện",
        "type": "xa",
        "slug": "tich-thien",
        "name_with_type": "Xã Tích Thiện",
        "path": "Tích Thiện, Trà Ôn, Vĩnh Long",
        "path_with_type": "Xã Tích Thiện, Huyện Trà Ôn, Tỉnh Vĩnh Long",
        "code": "29860",
        "parent_code": "862"
    },
    "29773": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Tân Hưng, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29773",
        "parent_code": "863"
    },
    "29776": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Tân Thành, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29776",
        "parent_code": "863"
    },
    "29779": {
        "name": "Thành Trung",
        "type": "xa",
        "slug": "thanh-trung",
        "name_with_type": "Xã Thành Trung",
        "path": "Thành Trung, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Thành Trung, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29779",
        "parent_code": "863"
    },
    "29782": {
        "name": "Tân An Thạnh",
        "type": "xa",
        "slug": "tan-an-thanh",
        "name_with_type": "Xã Tân An Thạnh",
        "path": "Tân An Thạnh, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Tân An Thạnh, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29782",
        "parent_code": "863"
    },
    "29785": {
        "name": "Tân Lược",
        "type": "xa",
        "slug": "tan-luoc",
        "name_with_type": "Xã Tân Lược",
        "path": "Tân Lược, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Tân Lược, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29785",
        "parent_code": "863"
    },
    "29788": {
        "name": "Nguyễn Văn Thảnh",
        "type": "xa",
        "slug": "nguyen-van-thanh",
        "name_with_type": "Xã Nguyễn Văn Thảnh",
        "path": "Nguyễn Văn Thảnh, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Nguyễn Văn Thảnh, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29788",
        "parent_code": "863"
    },
    "29791": {
        "name": "Thành Đông",
        "type": "xa",
        "slug": "thanh-dong",
        "name_with_type": "Xã Thành Đông",
        "path": "Thành Đông, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Thành Đông, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29791",
        "parent_code": "863"
    },
    "29794": {
        "name": "Mỹ Thuận",
        "type": "xa",
        "slug": "my-thuan",
        "name_with_type": "Xã Mỹ Thuận",
        "path": "Mỹ Thuận, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Mỹ Thuận, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29794",
        "parent_code": "863"
    },
    "29797": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Tân Bình, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29797",
        "parent_code": "863"
    },
    "29800": {
        "name": "Thành Lợi",
        "type": "xa",
        "slug": "thanh-loi",
        "name_with_type": "Xã Thành Lợi",
        "path": "Thành Lợi, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Thành Lợi, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29800",
        "parent_code": "863"
    },
    "29803": {
        "name": "Tân Quới",
        "type": "xa",
        "slug": "tan-quoi",
        "name_with_type": "Xã Tân Quới",
        "path": "Tân Quới, Bình Tân, Vĩnh Long",
        "path_with_type": "Xã Tân Quới, Huyện Bình Tân, Tỉnh Vĩnh Long",
        "code": "29803",
        "parent_code": "863"
    },
    "24769": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 7, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24769",
        "parent_code": "672"
    },
    "24772": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 8, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24772",
        "parent_code": "672"
    },
    "24775": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 12, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24775",
        "parent_code": "672"
    },
    "24778": {
        "name": "9",
        "type": "phuong",
        "slug": "9",
        "name_with_type": "Phường 9",
        "path": "9, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 9, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24778",
        "parent_code": "672"
    },
    "24781": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 2, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24781",
        "parent_code": "672"
    },
    "24784": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 1, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24784",
        "parent_code": "672"
    },
    "24787": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 6, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24787",
        "parent_code": "672"
    },
    "24790": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 5, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24790",
        "parent_code": "672"
    },
    "24793": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 4, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24793",
        "parent_code": "672"
    },
    "24796": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 10, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24796",
        "parent_code": "672"
    },
    "24799": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 11, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24799",
        "parent_code": "672"
    },
    "24802": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Đà Lạt, Lâm Đồng",
        "path_with_type": "Phường 3, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24802",
        "parent_code": "672"
    },
    "24805": {
        "name": "Xuân Thọ",
        "type": "xa",
        "slug": "xuan-tho",
        "name_with_type": "Xã Xuân Thọ",
        "path": "Xuân Thọ, Đà Lạt, Lâm Đồng",
        "path_with_type": "Xã Xuân Thọ, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24805",
        "parent_code": "672"
    },
    "24808": {
        "name": "Tà Nung",
        "type": "xa",
        "slug": "ta-nung",
        "name_with_type": "Xã Tà Nung",
        "path": "Tà Nung, Đà Lạt, Lâm Đồng",
        "path_with_type": "Xã Tà Nung, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24808",
        "parent_code": "672"
    },
    "24810": {
        "name": "Trạm Hành",
        "type": "xa",
        "slug": "tram-hanh",
        "name_with_type": "Xã Trạm Hành",
        "path": "Trạm Hành, Đà Lạt, Lâm Đồng",
        "path_with_type": "Xã Trạm Hành, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24810",
        "parent_code": "672"
    },
    "24811": {
        "name": "Xuân Trường",
        "type": "xa",
        "slug": "xuan-truong",
        "name_with_type": "Xã Xuân Trường",
        "path": "Xuân Trường, Đà Lạt, Lâm Đồng",
        "path_with_type": "Xã Xuân Trường, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
        "code": "24811",
        "parent_code": "672"
    },
    "24814": {
        "name": "Lộc Phát",
        "type": "phuong",
        "slug": "loc-phat",
        "name_with_type": "Phường Lộc Phát",
        "path": "Lộc Phát, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Phường Lộc Phát, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24814",
        "parent_code": "673"
    },
    "24817": {
        "name": "Lộc Tiến",
        "type": "phuong",
        "slug": "loc-tien",
        "name_with_type": "Phường Lộc Tiến",
        "path": "Lộc Tiến, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Phường Lộc Tiến, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24817",
        "parent_code": "673"
    },
    "24820": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Phường 2, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24820",
        "parent_code": "673"
    },
    "24823": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Phường 1, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24823",
        "parent_code": "673"
    },
    "24826": {
        "name": "B'lao",
        "type": "phuong",
        "slug": "b-lao",
        "name_with_type": "Phường B'lao",
        "path": "B'lao, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Phường B'lao, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24826",
        "parent_code": "673"
    },
    "24829": {
        "name": "Lộc Sơn",
        "type": "phuong",
        "slug": "loc-son",
        "name_with_type": "Phường Lộc Sơn",
        "path": "Lộc Sơn, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Phường Lộc Sơn, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24829",
        "parent_code": "673"
    },
    "24832": {
        "name": "Đạm Bri",
        "type": "xa",
        "slug": "dam-bri",
        "name_with_type": "Xã Đạm Bri",
        "path": "Đạm Bri, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Xã Đạm Bri, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24832",
        "parent_code": "673"
    },
    "24835": {
        "name": "Lộc Thanh",
        "type": "xa",
        "slug": "loc-thanh",
        "name_with_type": "Xã Lộc Thanh",
        "path": "Lộc Thanh, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Xã Lộc Thanh, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24835",
        "parent_code": "673"
    },
    "24838": {
        "name": "Lộc Nga",
        "type": "xa",
        "slug": "loc-nga",
        "name_with_type": "Xã Lộc Nga",
        "path": "Lộc Nga, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Xã Lộc Nga, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24838",
        "parent_code": "673"
    },
    "24841": {
        "name": "Lộc Châu",
        "type": "xa",
        "slug": "loc-chau",
        "name_with_type": "Xã Lộc Châu",
        "path": "Lộc Châu, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Xã Lộc Châu, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24841",
        "parent_code": "673"
    },
    "24844": {
        "name": "Đại Lào",
        "type": "xa",
        "slug": "dai-lao",
        "name_with_type": "Xã Đại Lào",
        "path": "Đại Lào, Bảo Lộc, Lâm Đồng",
        "path_with_type": "Xã Đại Lào, Thành phố Bảo Lộc, Tỉnh Lâm Đồng",
        "code": "24844",
        "parent_code": "673"
    },
    "24853": {
        "name": "Đạ Tông",
        "type": "xa",
        "slug": "da-tong",
        "name_with_type": "Xã Đạ Tông",
        "path": "Đạ Tông, Đam Rông, Lâm Đồng",
        "path_with_type": "Xã Đạ Tông, Huyện Đam Rông, Tỉnh Lâm Đồng",
        "code": "24853",
        "parent_code": "674"
    },
    "24856": {
        "name": "Đạ Long",
        "type": "xa",
        "slug": "da-long",
        "name_with_type": "Xã Đạ Long",
        "path": "Đạ Long, Đam Rông, Lâm Đồng",
        "path_with_type": "Xã Đạ Long, Huyện Đam Rông, Tỉnh Lâm Đồng",
        "code": "24856",
        "parent_code": "674"
    },
    "24859": {
        "name": "Đạ M' Rong",
        "type": "xa",
        "slug": "da-m-rong",
        "name_with_type": "Xã Đạ M' Rong",
        "path": "Đạ M' Rong, Đam Rông, Lâm Đồng",
        "path_with_type": "Xã Đạ M' Rong, Huyện Đam Rông, Tỉnh Lâm Đồng",
        "code": "24859",
        "parent_code": "674"
    },
    "24874": {
        "name": "Liêng Srônh",
        "type": "xa",
        "slug": "lieng-sronh",
        "name_with_type": "Xã Liêng Srônh",
        "path": "Liêng Srônh, Đam Rông, Lâm Đồng",
        "path_with_type": "Xã Liêng Srônh, Huyện Đam Rông, Tỉnh Lâm Đồng",
        "code": "24874",
        "parent_code": "674"
    },
    "24875": {
        "name": "Đạ Rsal",
        "type": "xa",
        "slug": "da-rsal",
        "name_with_type": "Xã Đạ Rsal",
        "path": "Đạ Rsal, Đam Rông, Lâm Đồng",
        "path_with_type": "Xã Đạ Rsal, Huyện Đam Rông, Tỉnh Lâm Đồng",
        "code": "24875",
        "parent_code": "674"
    },
    "24877": {
        "name": "Rô Men",
        "type": "xa",
        "slug": "ro-men",
        "name_with_type": "Xã Rô Men",
        "path": "Rô Men, Đam Rông, Lâm Đồng",
        "path_with_type": "Xã Rô Men, Huyện Đam Rông, Tỉnh Lâm Đồng",
        "code": "24877",
        "parent_code": "674"
    },
    "24886": {
        "name": "Phi Liêng",
        "type": "xa",
        "slug": "phi-lieng",
        "name_with_type": "Xã Phi Liêng",
        "path": "Phi Liêng, Đam Rông, Lâm Đồng",
        "path_with_type": "Xã Phi Liêng, Huyện Đam Rông, Tỉnh Lâm Đồng",
        "code": "24886",
        "parent_code": "674"
    },
    "24889": {
        "name": "Đạ K' Nàng",
        "type": "xa",
        "slug": "da-k-nang",
        "name_with_type": "Xã Đạ K' Nàng",
        "path": "Đạ K' Nàng, Đam Rông, Lâm Đồng",
        "path_with_type": "Xã Đạ K' Nàng, Huyện Đam Rông, Tỉnh Lâm Đồng",
        "code": "24889",
        "parent_code": "674"
    },
    "24846": {
        "name": "Lạc Dương",
        "type": "thi-tran",
        "slug": "lac-duong",
        "name_with_type": "Thị trấn Lạc Dương",
        "path": "Lạc Dương, Lạc Dương, Lâm Đồng",
        "path_with_type": "Thị trấn Lạc Dương, Huyện Lạc Dương, Tỉnh Lâm Đồng",
        "code": "24846",
        "parent_code": "675"
    },
    "24847": {
        "name": "Đạ Chais",
        "type": "xa",
        "slug": "da-chais",
        "name_with_type": "Xã Đạ Chais",
        "path": "Đạ Chais, Lạc Dương, Lâm Đồng",
        "path_with_type": "Xã Đạ Chais, Huyện Lạc Dương, Tỉnh Lâm Đồng",
        "code": "24847",
        "parent_code": "675"
    },
    "24848": {
        "name": "Đạ Nhim",
        "type": "xa",
        "slug": "da-nhim",
        "name_with_type": "Xã Đạ Nhim",
        "path": "Đạ Nhim, Lạc Dương, Lâm Đồng",
        "path_with_type": "Xã Đạ Nhim, Huyện Lạc Dương, Tỉnh Lâm Đồng",
        "code": "24848",
        "parent_code": "675"
    },
    "24850": {
        "name": "Đưng KNớ",
        "type": "xa",
        "slug": "dung-kno",
        "name_with_type": "Xã Đưng KNớ",
        "path": "Đưng KNớ, Lạc Dương, Lâm Đồng",
        "path_with_type": "Xã Đưng KNớ, Huyện Lạc Dương, Tỉnh Lâm Đồng",
        "code": "24850",
        "parent_code": "675"
    },
    "24862": {
        "name": "Lát",
        "type": "xa",
        "slug": "lat",
        "name_with_type": "Xã Lát",
        "path": "Lát, Lạc Dương, Lâm Đồng",
        "path_with_type": "Xã Lát, Huyện Lạc Dương, Tỉnh Lâm Đồng",
        "code": "24862",
        "parent_code": "675"
    },
    "24865": {
        "name": "Đạ Sar",
        "type": "xa",
        "slug": "da-sar",
        "name_with_type": "Xã Đạ Sar",
        "path": "Đạ Sar, Lạc Dương, Lâm Đồng",
        "path_with_type": "Xã Đạ Sar, Huyện Lạc Dương, Tỉnh Lâm Đồng",
        "code": "24865",
        "parent_code": "675"
    },
    "24868": {
        "name": "Nam Ban",
        "type": "thi-tran",
        "slug": "nam-ban",
        "name_with_type": "Thị trấn Nam Ban",
        "path": "Nam Ban, Lâm Hà, Lâm Đồng",
        "path_with_type": "Thị trấn Nam Ban, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24868",
        "parent_code": "676"
    },
    "24871": {
        "name": "Đinh Văn",
        "type": "thi-tran",
        "slug": "dinh-van",
        "name_with_type": "Thị trấn Đinh Văn",
        "path": "Đinh Văn, Lâm Hà, Lâm Đồng",
        "path_with_type": "Thị trấn Đinh Văn, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24871",
        "parent_code": "676"
    },
    "24880": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Phú Sơn, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24880",
        "parent_code": "676"
    },
    "24883": {
        "name": "Phi Tô",
        "type": "xa",
        "slug": "phi-to",
        "name_with_type": "Xã Phi Tô",
        "path": "Phi Tô, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Phi Tô, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24883",
        "parent_code": "676"
    },
    "24892": {
        "name": "Mê Linh",
        "type": "xa",
        "slug": "me-linh",
        "name_with_type": "Xã Mê Linh",
        "path": "Mê Linh, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Mê Linh, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24892",
        "parent_code": "676"
    },
    "24895": {
        "name": "Đạ Đờn",
        "type": "xa",
        "slug": "da-don",
        "name_with_type": "Xã Đạ Đờn",
        "path": "Đạ Đờn, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Đạ Đờn, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24895",
        "parent_code": "676"
    },
    "24898": {
        "name": "Phúc Thọ",
        "type": "xa",
        "slug": "phuc-tho",
        "name_with_type": "Xã Phúc Thọ",
        "path": "Phúc Thọ, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Phúc Thọ, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24898",
        "parent_code": "676"
    },
    "24901": {
        "name": "Đông Thanh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thanh",
        "path": "Đông Thanh, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Đông Thanh, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24901",
        "parent_code": "676"
    },
    "24904": {
        "name": "Gia Lâm",
        "type": "xa",
        "slug": "gia-lam",
        "name_with_type": "Xã Gia Lâm",
        "path": "Gia Lâm, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Gia Lâm, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24904",
        "parent_code": "676"
    },
    "24907": {
        "name": "Tân Thanh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thanh",
        "path": "Tân Thanh, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Tân Thanh, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24907",
        "parent_code": "676"
    },
    "24910": {
        "name": "Tân Văn",
        "type": "xa",
        "slug": "tan-van",
        "name_with_type": "Xã Tân Văn",
        "path": "Tân Văn, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Tân Văn, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24910",
        "parent_code": "676"
    },
    "24913": {
        "name": "Hoài Đức",
        "type": "xa",
        "slug": "hoai-duc",
        "name_with_type": "Xã Hoài Đức",
        "path": "Hoài Đức, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Hoài Đức, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24913",
        "parent_code": "676"
    },
    "24916": {
        "name": "Tân Hà",
        "type": "xa",
        "slug": "tan-ha",
        "name_with_type": "Xã Tân Hà",
        "path": "Tân Hà, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Tân Hà, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24916",
        "parent_code": "676"
    },
    "24919": {
        "name": "Liên Hà",
        "type": "xa",
        "slug": "lien-ha",
        "name_with_type": "Xã Liên Hà",
        "path": "Liên Hà, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Liên Hà, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24919",
        "parent_code": "676"
    },
    "24922": {
        "name": "Đan Phượng",
        "type": "xa",
        "slug": "dan-phuong",
        "name_with_type": "Xã Đan Phượng",
        "path": "Đan Phượng, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Đan Phượng, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24922",
        "parent_code": "676"
    },
    "24925": {
        "name": "Nam Hà",
        "type": "xa",
        "slug": "nam-ha",
        "name_with_type": "Xã Nam Hà",
        "path": "Nam Hà, Lâm Hà, Lâm Đồng",
        "path_with_type": "Xã Nam Hà, Huyện Lâm Hà, Tỉnh Lâm Đồng",
        "code": "24925",
        "parent_code": "676"
    },
    "24928": {
        "name": "D'Ran",
        "type": "thi-tran",
        "slug": "d-ran",
        "name_with_type": "Thị trấn D'Ran",
        "path": "D'Ran, Đơn Dương, Lâm Đồng",
        "path_with_type": "Thị trấn D'Ran, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24928",
        "parent_code": "677"
    },
    "24931": {
        "name": "Thạnh Mỹ",
        "type": "thi-tran",
        "slug": "thanh-my",
        "name_with_type": "Thị trấn Thạnh Mỹ",
        "path": "Thạnh Mỹ, Đơn Dương, Lâm Đồng",
        "path_with_type": "Thị trấn Thạnh Mỹ, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24931",
        "parent_code": "677"
    },
    "24934": {
        "name": "Lạc Xuân",
        "type": "xa",
        "slug": "lac-xuan",
        "name_with_type": "Xã Lạc Xuân",
        "path": "Lạc Xuân, Đơn Dương, Lâm Đồng",
        "path_with_type": "Xã Lạc Xuân, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24934",
        "parent_code": "677"
    },
    "24937": {
        "name": "Đạ Ròn",
        "type": "xa",
        "slug": "da-ron",
        "name_with_type": "Xã Đạ Ròn",
        "path": "Đạ Ròn, Đơn Dương, Lâm Đồng",
        "path_with_type": "Xã Đạ Ròn, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24937",
        "parent_code": "677"
    },
    "24940": {
        "name": "Lạc Lâm",
        "type": "xa",
        "slug": "lac-lam",
        "name_with_type": "Xã Lạc Lâm",
        "path": "Lạc Lâm, Đơn Dương, Lâm Đồng",
        "path_with_type": "Xã Lạc Lâm, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24940",
        "parent_code": "677"
    },
    "24943": {
        "name": "Ka Đô",
        "type": "xa",
        "slug": "ka-do",
        "name_with_type": "Xã Ka Đô",
        "path": "Ka Đô, Đơn Dương, Lâm Đồng",
        "path_with_type": "Xã Ka Đô, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24943",
        "parent_code": "677"
    },
    "24946": {
        "name": "Quảng Lập",
        "type": "xa",
        "slug": "quang-lap",
        "name_with_type": "Xã Quảng Lập",
        "path": "Quảng Lập, Đơn Dương, Lâm Đồng",
        "path_with_type": "Xã Quảng Lập, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24946",
        "parent_code": "677"
    },
    "24949": {
        "name": "Ka Đơn",
        "type": "xa",
        "slug": "ka-don",
        "name_with_type": "Xã Ka Đơn",
        "path": "Ka Đơn, Đơn Dương, Lâm Đồng",
        "path_with_type": "Xã Ka Đơn, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24949",
        "parent_code": "677"
    },
    "24952": {
        "name": "Tu Tra",
        "type": "xa",
        "slug": "tu-tra",
        "name_with_type": "Xã Tu Tra",
        "path": "Tu Tra, Đơn Dương, Lâm Đồng",
        "path_with_type": "Xã Tu Tra, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24952",
        "parent_code": "677"
    },
    "24955": {
        "name": "Pró",
        "type": "xa",
        "slug": "pro",
        "name_with_type": "Xã Pró",
        "path": "Pró, Đơn Dương, Lâm Đồng",
        "path_with_type": "Xã Pró, Huyện Đơn Dương, Tỉnh Lâm Đồng",
        "code": "24955",
        "parent_code": "677"
    },
    "24958": {
        "name": "Liên Nghĩa",
        "type": "thi-tran",
        "slug": "lien-nghia",
        "name_with_type": "Thị trấn Liên Nghĩa",
        "path": "Liên Nghĩa, Đức Trọng, Lâm Đồng",
        "path_with_type": "Thị trấn Liên Nghĩa, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24958",
        "parent_code": "678"
    },
    "24961": {
        "name": "Hiệp An",
        "type": "xa",
        "slug": "hiep-an",
        "name_with_type": "Xã Hiệp An",
        "path": "Hiệp An, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Hiệp An, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24961",
        "parent_code": "678"
    },
    "24964": {
        "name": "Liên Hiệp",
        "type": "xa",
        "slug": "lien-hiep",
        "name_with_type": "Xã Liên Hiệp",
        "path": "Liên Hiệp, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Liên Hiệp, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24964",
        "parent_code": "678"
    },
    "24967": {
        "name": "Hiệp Thạnh",
        "type": "xa",
        "slug": "hiep-thanh",
        "name_with_type": "Xã Hiệp Thạnh",
        "path": "Hiệp Thạnh, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Hiệp Thạnh, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24967",
        "parent_code": "678"
    },
    "24970": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Bình Thạnh, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24970",
        "parent_code": "678"
    },
    "24973": {
        "name": "N'Thol Hạ",
        "type": "xa",
        "slug": "n-thol-ha",
        "name_with_type": "Xã N'Thol Hạ",
        "path": "N'Thol Hạ, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã N'Thol Hạ, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24973",
        "parent_code": "678"
    },
    "24976": {
        "name": "Tân Hội",
        "type": "xa",
        "slug": "tan-hoi",
        "name_with_type": "Xã Tân Hội",
        "path": "Tân Hội, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Tân Hội, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24976",
        "parent_code": "678"
    },
    "24979": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Tân Thành, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24979",
        "parent_code": "678"
    },
    "24982": {
        "name": "Phú Hội",
        "type": "xa",
        "slug": "phu-hoi",
        "name_with_type": "Xã Phú Hội",
        "path": "Phú Hội, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Phú Hội, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24982",
        "parent_code": "678"
    },
    "24985": {
        "name": "Ninh Gia",
        "type": "xa",
        "slug": "ninh-gia",
        "name_with_type": "Xã Ninh Gia",
        "path": "Ninh Gia, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Ninh Gia, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24985",
        "parent_code": "678"
    },
    "24988": {
        "name": "Tà Năng",
        "type": "xa",
        "slug": "ta-nang",
        "name_with_type": "Xã Tà Năng",
        "path": "Tà Năng, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Tà Năng, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24988",
        "parent_code": "678"
    },
    "24989": {
        "name": "Đa Quyn",
        "type": "xa",
        "slug": "da-quyn",
        "name_with_type": "Xã Đa Quyn",
        "path": "Đa Quyn, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Đa Quyn, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24989",
        "parent_code": "678"
    },
    "24991": {
        "name": "Tà Hine",
        "type": "xa",
        "slug": "ta-hine",
        "name_with_type": "Xã Tà Hine",
        "path": "Tà Hine, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Tà Hine, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24991",
        "parent_code": "678"
    },
    "24994": {
        "name": "Đà Loan",
        "type": "xa",
        "slug": "da-loan",
        "name_with_type": "Xã Đà Loan",
        "path": "Đà Loan, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Đà Loan, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24994",
        "parent_code": "678"
    },
    "24997": {
        "name": "Ninh Loan",
        "type": "xa",
        "slug": "ninh-loan",
        "name_with_type": "Xã Ninh Loan",
        "path": "Ninh Loan, Đức Trọng, Lâm Đồng",
        "path_with_type": "Xã Ninh Loan, Huyện Đức Trọng, Tỉnh Lâm Đồng",
        "code": "24997",
        "parent_code": "678"
    },
    "25000": {
        "name": "Di Linh",
        "type": "thi-tran",
        "slug": "di-linh",
        "name_with_type": "Thị trấn Di Linh",
        "path": "Di Linh, Di Linh, Lâm Đồng",
        "path_with_type": "Thị trấn Di Linh, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25000",
        "parent_code": "679"
    },
    "25003": {
        "name": "Đinh Trang Thượng",
        "type": "xa",
        "slug": "dinh-trang-thuong",
        "name_with_type": "Xã Đinh Trang Thượng",
        "path": "Đinh Trang Thượng, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Đinh Trang Thượng, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25003",
        "parent_code": "679"
    },
    "25006": {
        "name": "Tân Thượng",
        "type": "xa",
        "slug": "tan-thuong",
        "name_with_type": "Xã Tân Thượng",
        "path": "Tân Thượng, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Tân Thượng, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25006",
        "parent_code": "679"
    },
    "25007": {
        "name": "Tân Lâm",
        "type": "xa",
        "slug": "tan-lam",
        "name_with_type": "Xã Tân Lâm",
        "path": "Tân Lâm, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Tân Lâm, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25007",
        "parent_code": "679"
    },
    "25009": {
        "name": "Tân Châu",
        "type": "xa",
        "slug": "tan-chau",
        "name_with_type": "Xã Tân Châu",
        "path": "Tân Châu, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Tân Châu, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25009",
        "parent_code": "679"
    },
    "25012": {
        "name": "Tân Nghĩa",
        "type": "xa",
        "slug": "tan-nghia",
        "name_with_type": "Xã Tân Nghĩa",
        "path": "Tân Nghĩa, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Tân Nghĩa, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25012",
        "parent_code": "679"
    },
    "25015": {
        "name": "Gia Hiệp",
        "type": "xa",
        "slug": "gia-hiep",
        "name_with_type": "Xã Gia Hiệp",
        "path": "Gia Hiệp, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Gia Hiệp, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25015",
        "parent_code": "679"
    },
    "25018": {
        "name": "Đinh Lạc",
        "type": "xa",
        "slug": "dinh-lac",
        "name_with_type": "Xã Đinh Lạc",
        "path": "Đinh Lạc, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Đinh Lạc, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25018",
        "parent_code": "679"
    },
    "25021": {
        "name": "Tam Bố",
        "type": "xa",
        "slug": "tam-bo",
        "name_with_type": "Xã Tam Bố",
        "path": "Tam Bố, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Tam Bố, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25021",
        "parent_code": "679"
    },
    "25024": {
        "name": "Đinh Trang Hòa",
        "type": "xa",
        "slug": "dinh-trang-hoa",
        "name_with_type": "Xã Đinh Trang Hòa",
        "path": "Đinh Trang Hòa, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Đinh Trang Hòa, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25024",
        "parent_code": "679"
    },
    "25027": {
        "name": "Liên Đầm",
        "type": "xa",
        "slug": "lien-dam",
        "name_with_type": "Xã Liên Đầm",
        "path": "Liên Đầm, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Liên Đầm, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25027",
        "parent_code": "679"
    },
    "25030": {
        "name": "Gung Ré",
        "type": "xa",
        "slug": "gung-re",
        "name_with_type": "Xã Gung Ré",
        "path": "Gung Ré, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Gung Ré, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25030",
        "parent_code": "679"
    },
    "25033": {
        "name": "Bảo Thuận",
        "type": "xa",
        "slug": "bao-thuan",
        "name_with_type": "Xã Bảo Thuận",
        "path": "Bảo Thuận, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Bảo Thuận, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25033",
        "parent_code": "679"
    },
    "25036": {
        "name": "Hòa Ninh",
        "type": "xa",
        "slug": "hoa-ninh",
        "name_with_type": "Xã Hòa Ninh",
        "path": "Hòa Ninh, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Hòa Ninh, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25036",
        "parent_code": "679"
    },
    "25039": {
        "name": "Hòa Trung",
        "type": "xa",
        "slug": "hoa-trung",
        "name_with_type": "Xã Hòa Trung",
        "path": "Hòa Trung, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Hòa Trung, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25039",
        "parent_code": "679"
    },
    "25042": {
        "name": "Hòa Nam",
        "type": "xa",
        "slug": "hoa-nam",
        "name_with_type": "Xã Hòa Nam",
        "path": "Hòa Nam, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Hòa Nam, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25042",
        "parent_code": "679"
    },
    "25045": {
        "name": "Hòa Bắc",
        "type": "xa",
        "slug": "hoa-bac",
        "name_with_type": "Xã Hòa Bắc",
        "path": "Hòa Bắc, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Hòa Bắc, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25045",
        "parent_code": "679"
    },
    "25048": {
        "name": "Sơn Điền",
        "type": "xa",
        "slug": "son-dien",
        "name_with_type": "Xã Sơn Điền",
        "path": "Sơn Điền, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Sơn Điền, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25048",
        "parent_code": "679"
    },
    "25051": {
        "name": "Gia Bắc",
        "type": "xa",
        "slug": "gia-bac",
        "name_with_type": "Xã Gia Bắc",
        "path": "Gia Bắc, Di Linh, Lâm Đồng",
        "path_with_type": "Xã Gia Bắc, Huyện Di Linh, Tỉnh Lâm Đồng",
        "code": "25051",
        "parent_code": "679"
    },
    "25054": {
        "name": "Lộc Thắng",
        "type": "thi-tran",
        "slug": "loc-thang",
        "name_with_type": "Thị trấn Lộc Thắng",
        "path": "Lộc Thắng, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Thị trấn Lộc Thắng, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25054",
        "parent_code": "680"
    },
    "25057": {
        "name": "Lộc Bảo",
        "type": "xa",
        "slug": "loc-bao",
        "name_with_type": "Xã Lộc Bảo",
        "path": "Lộc Bảo, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Bảo, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25057",
        "parent_code": "680"
    },
    "25060": {
        "name": "Lộc Lâm",
        "type": "xa",
        "slug": "loc-lam",
        "name_with_type": "Xã Lộc Lâm",
        "path": "Lộc Lâm, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Lâm, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25060",
        "parent_code": "680"
    },
    "25063": {
        "name": "Lộc Phú",
        "type": "xa",
        "slug": "loc-phu",
        "name_with_type": "Xã Lộc Phú",
        "path": "Lộc Phú, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Phú, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25063",
        "parent_code": "680"
    },
    "25066": {
        "name": "Lộc Bắc",
        "type": "xa",
        "slug": "loc-bac",
        "name_with_type": "Xã Lộc Bắc",
        "path": "Lộc Bắc, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Bắc, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25066",
        "parent_code": "680"
    },
    "25069": {
        "name": "B' Lá",
        "type": "xa",
        "slug": "b-la",
        "name_with_type": "Xã B' Lá",
        "path": "B' Lá, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã B' Lá, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25069",
        "parent_code": "680"
    },
    "25072": {
        "name": "Lộc Ngãi",
        "type": "xa",
        "slug": "loc-ngai",
        "name_with_type": "Xã Lộc Ngãi",
        "path": "Lộc Ngãi, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Ngãi, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25072",
        "parent_code": "680"
    },
    "25075": {
        "name": "Lộc Quảng",
        "type": "xa",
        "slug": "loc-quang",
        "name_with_type": "Xã Lộc Quảng",
        "path": "Lộc Quảng, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Quảng, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25075",
        "parent_code": "680"
    },
    "25078": {
        "name": "Lộc Tân",
        "type": "xa",
        "slug": "loc-tan",
        "name_with_type": "Xã Lộc Tân",
        "path": "Lộc Tân, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Tân, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25078",
        "parent_code": "680"
    },
    "25081": {
        "name": "Lộc Đức",
        "type": "xa",
        "slug": "loc-duc",
        "name_with_type": "Xã Lộc Đức",
        "path": "Lộc Đức, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Đức, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25081",
        "parent_code": "680"
    },
    "25084": {
        "name": "Lộc An",
        "type": "xa",
        "slug": "loc-an",
        "name_with_type": "Xã Lộc An",
        "path": "Lộc An, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc An, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25084",
        "parent_code": "680"
    },
    "25087": {
        "name": "Tân Lạc",
        "type": "xa",
        "slug": "tan-lac",
        "name_with_type": "Xã Tân Lạc",
        "path": "Tân Lạc, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Tân Lạc, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25087",
        "parent_code": "680"
    },
    "25090": {
        "name": "Lộc Thành",
        "type": "xa",
        "slug": "loc-thanh",
        "name_with_type": "Xã Lộc Thành",
        "path": "Lộc Thành, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Thành, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25090",
        "parent_code": "680"
    },
    "25093": {
        "name": "Lộc Nam",
        "type": "xa",
        "slug": "loc-nam",
        "name_with_type": "Xã Lộc Nam",
        "path": "Lộc Nam, Bảo Lâm, Lâm Đồng",
        "path_with_type": "Xã Lộc Nam, Huyện Bảo Lâm, Tỉnh Lâm Đồng",
        "code": "25093",
        "parent_code": "680"
    },
    "25096": {
        "name": "Đạ M'ri",
        "type": "thi-tran",
        "slug": "da-m-ri",
        "name_with_type": "Thị trấn Đạ M'ri",
        "path": "Đạ M'ri, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Thị trấn Đạ M'ri, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25096",
        "parent_code": "681"
    },
    "25099": {
        "name": "Ma Đa Guôi",
        "type": "thi-tran",
        "slug": "ma-da-guoi",
        "name_with_type": "Thị trấn Ma Đa Guôi",
        "path": "Ma Đa Guôi, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Thị trấn Ma Đa Guôi, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25099",
        "parent_code": "681"
    },
    "25102": {
        "name": "Đạ M'ri",
        "type": "xa",
        "slug": "da-m-ri",
        "name_with_type": "Xã Đạ M'ri",
        "path": "Đạ M'ri, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Xã Đạ M'ri, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25102",
        "parent_code": "681"
    },
    "25105": {
        "name": "Hà Lâm",
        "type": "xa",
        "slug": "ha-lam",
        "name_with_type": "Xã Hà Lâm",
        "path": "Hà Lâm, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Xã Hà Lâm, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25105",
        "parent_code": "681"
    },
    "25108": {
        "name": "Đạ Tồn",
        "type": "xa",
        "slug": "da-ton",
        "name_with_type": "Xã Đạ Tồn",
        "path": "Đạ Tồn, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Xã Đạ Tồn, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25108",
        "parent_code": "681"
    },
    "25111": {
        "name": "Đạ Oai",
        "type": "xa",
        "slug": "da-oai",
        "name_with_type": "Xã Đạ Oai",
        "path": "Đạ Oai, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Xã Đạ Oai, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25111",
        "parent_code": "681"
    },
    "25114": {
        "name": "Đạ Ploa",
        "type": "xa",
        "slug": "da-ploa",
        "name_with_type": "Xã Đạ Ploa",
        "path": "Đạ Ploa, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Xã Đạ Ploa, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25114",
        "parent_code": "681"
    },
    "25117": {
        "name": "Ma Đa Guôi",
        "type": "xa",
        "slug": "ma-da-guoi",
        "name_with_type": "Xã Ma Đa Guôi",
        "path": "Ma Đa Guôi, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Xã Ma Đa Guôi, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25117",
        "parent_code": "681"
    },
    "25120": {
        "name": "Đoàn Kết",
        "type": "xa",
        "slug": "doan-ket",
        "name_with_type": "Xã Đoàn Kết",
        "path": "Đoàn Kết, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Xã Đoàn Kết, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25120",
        "parent_code": "681"
    },
    "25123": {
        "name": "Phước Lộc",
        "type": "xa",
        "slug": "phuoc-loc",
        "name_with_type": "Xã Phước Lộc",
        "path": "Phước Lộc, Đạ Huoai, Lâm Đồng",
        "path_with_type": "Xã Phước Lộc, Huyện Đạ Huoai, Tỉnh Lâm Đồng",
        "code": "25123",
        "parent_code": "681"
    },
    "25126": {
        "name": "Đạ Tẻh",
        "type": "thi-tran",
        "slug": "da-teh",
        "name_with_type": "Thị trấn Đạ Tẻh",
        "path": "Đạ Tẻh, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Thị trấn Đạ Tẻh, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25126",
        "parent_code": "682"
    },
    "25129": {
        "name": "An Nhơn",
        "type": "xa",
        "slug": "an-nhon",
        "name_with_type": "Xã An Nhơn",
        "path": "An Nhơn, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã An Nhơn, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25129",
        "parent_code": "682"
    },
    "25132": {
        "name": "Quốc Oai",
        "type": "xa",
        "slug": "quoc-oai",
        "name_with_type": "Xã Quốc Oai",
        "path": "Quốc Oai, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã Quốc Oai, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25132",
        "parent_code": "682"
    },
    "25135": {
        "name": "Mỹ Đức",
        "type": "xa",
        "slug": "my-duc",
        "name_with_type": "Xã Mỹ Đức",
        "path": "Mỹ Đức, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã Mỹ Đức, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25135",
        "parent_code": "682"
    },
    "25138": {
        "name": "Quảng Trị",
        "type": "xa",
        "slug": "quang-tri",
        "name_with_type": "Xã Quảng Trị",
        "path": "Quảng Trị, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã Quảng Trị, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25138",
        "parent_code": "682"
    },
    "25141": {
        "name": "Đạ Lây",
        "type": "xa",
        "slug": "da-lay",
        "name_with_type": "Xã Đạ Lây",
        "path": "Đạ Lây, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã Đạ Lây, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25141",
        "parent_code": "682"
    },
    "25144": {
        "name": "Hương Lâm",
        "type": "xa",
        "slug": "huong-lam",
        "name_with_type": "Xã Hương Lâm",
        "path": "Hương Lâm, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã Hương Lâm, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25144",
        "parent_code": "682"
    },
    "25147": {
        "name": "Triệu Hải",
        "type": "xa",
        "slug": "trieu-hai",
        "name_with_type": "Xã Triệu Hải",
        "path": "Triệu Hải, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã Triệu Hải, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25147",
        "parent_code": "682"
    },
    "25150": {
        "name": "Hà Đông",
        "type": "xa",
        "slug": "ha-dong",
        "name_with_type": "Xã Hà Đông",
        "path": "Hà Đông, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã Hà Đông, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25150",
        "parent_code": "682"
    },
    "25153": {
        "name": "Đạ Kho",
        "type": "xa",
        "slug": "da-kho",
        "name_with_type": "Xã Đạ Kho",
        "path": "Đạ Kho, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã Đạ Kho, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25153",
        "parent_code": "682"
    },
    "25156": {
        "name": "Đạ Pal",
        "type": "xa",
        "slug": "da-pal",
        "name_with_type": "Xã Đạ Pal",
        "path": "Đạ Pal, Đạ Tẻh, Lâm Đồng",
        "path_with_type": "Xã Đạ Pal, Huyện Đạ Tẻh, Tỉnh Lâm Đồng",
        "code": "25156",
        "parent_code": "682"
    },
    "25159": {
        "name": "Cát Tiên",
        "type": "thi-tran",
        "slug": "cat-tien",
        "name_with_type": "Thị trấn Cát Tiên",
        "path": "Cát Tiên, Cát Tiên, Lâm Đồng",
        "path_with_type": "Thị trấn Cát Tiên, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25159",
        "parent_code": "683"
    },
    "25162": {
        "name": "Tiên Hoàng",
        "type": "xa",
        "slug": "tien-hoang",
        "name_with_type": "Xã Tiên Hoàng",
        "path": "Tiên Hoàng, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Tiên Hoàng, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25162",
        "parent_code": "683"
    },
    "25165": {
        "name": "Phước Cát 2",
        "type": "xa",
        "slug": "phuoc-cat-2",
        "name_with_type": "Xã Phước Cát 2",
        "path": "Phước Cát 2, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Phước Cát 2, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25165",
        "parent_code": "683"
    },
    "25168": {
        "name": "Gia Viễn",
        "type": "xa",
        "slug": "gia-vien",
        "name_with_type": "Xã Gia Viễn",
        "path": "Gia Viễn, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Gia Viễn, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25168",
        "parent_code": "683"
    },
    "25171": {
        "name": "Nam Ninh",
        "type": "xa",
        "slug": "nam-ninh",
        "name_with_type": "Xã Nam Ninh",
        "path": "Nam Ninh, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Nam Ninh, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25171",
        "parent_code": "683"
    },
    "25174": {
        "name": "Mỹ Lâm",
        "type": "xa",
        "slug": "my-lam",
        "name_with_type": "Xã Mỹ Lâm",
        "path": "Mỹ Lâm, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Mỹ Lâm, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25174",
        "parent_code": "683"
    },
    "25177": {
        "name": "Tư Nghĩa",
        "type": "xa",
        "slug": "tu-nghia",
        "name_with_type": "Xã Tư Nghĩa",
        "path": "Tư Nghĩa, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Tư Nghĩa, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25177",
        "parent_code": "683"
    },
    "25180": {
        "name": "Phước Cát 1",
        "type": "xa",
        "slug": "phuoc-cat-1",
        "name_with_type": "Xã Phước Cát 1",
        "path": "Phước Cát 1, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Phước Cát 1, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25180",
        "parent_code": "683"
    },
    "25183": {
        "name": "Đức Phổ",
        "type": "xa",
        "slug": "duc-pho",
        "name_with_type": "Xã Đức Phổ",
        "path": "Đức Phổ, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Đức Phổ, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25183",
        "parent_code": "683"
    },
    "25189": {
        "name": "Quảng Ngãi",
        "type": "xa",
        "slug": "quang-ngai",
        "name_with_type": "Xã Quảng Ngãi",
        "path": "Quảng Ngãi, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Quảng Ngãi, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25189",
        "parent_code": "683"
    },
    "25192": {
        "name": "Đồng Nai Thượng",
        "type": "xa",
        "slug": "dong-nai-thuong",
        "name_with_type": "Xã Đồng Nai Thượng",
        "path": "Đồng Nai Thượng, Cát Tiên, Lâm Đồng",
        "path_with_type": "Xã Đồng Nai Thượng, Huyện Cát Tiên, Tỉnh Lâm Đồng",
        "code": "25192",
        "parent_code": "683"
    },
    "21550": {
        "name": "Nhơn Bình",
        "type": "phuong",
        "slug": "nhon-binh",
        "name_with_type": "Phường Nhơn Bình",
        "path": "Nhơn Bình, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Nhơn Bình, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21550",
        "parent_code": "540"
    },
    "21553": {
        "name": "Nhơn Phú",
        "type": "phuong",
        "slug": "nhon-phu",
        "name_with_type": "Phường Nhơn Phú",
        "path": "Nhơn Phú, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Nhơn Phú, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21553",
        "parent_code": "540"
    },
    "21556": {
        "name": "Đống Đa",
        "type": "phuong",
        "slug": "dong-da",
        "name_with_type": "Phường Đống Đa",
        "path": "Đống Đa, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Đống Đa, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21556",
        "parent_code": "540"
    },
    "21559": {
        "name": "Trần Quang Diệu",
        "type": "phuong",
        "slug": "tran-quang-dieu",
        "name_with_type": "Phường Trần Quang Diệu",
        "path": "Trần Quang Diệu, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Trần Quang Diệu, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21559",
        "parent_code": "540"
    },
    "21562": {
        "name": "Hải Cảng",
        "type": "phuong",
        "slug": "hai-cang",
        "name_with_type": "Phường Hải Cảng",
        "path": "Hải Cảng, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Hải Cảng, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21562",
        "parent_code": "540"
    },
    "21565": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Quang Trung, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21565",
        "parent_code": "540"
    },
    "21568": {
        "name": "Thị Nại",
        "type": "phuong",
        "slug": "thi-nai",
        "name_with_type": "Phường Thị Nại",
        "path": "Thị Nại, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Thị Nại, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21568",
        "parent_code": "540"
    },
    "21571": {
        "name": "Lê Hồng Phong",
        "type": "phuong",
        "slug": "le-hong-phong",
        "name_with_type": "Phường Lê Hồng Phong",
        "path": "Lê Hồng Phong, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Lê Hồng Phong, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21571",
        "parent_code": "540"
    },
    "21574": {
        "name": "Trần Hưng Đạo",
        "type": "phuong",
        "slug": "tran-hung-dao",
        "name_with_type": "Phường Trần Hưng Đạo",
        "path": "Trần Hưng Đạo, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Trần Hưng Đạo, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21574",
        "parent_code": "540"
    },
    "21577": {
        "name": "Ngô Mây",
        "type": "phuong",
        "slug": "ngo-may",
        "name_with_type": "Phường Ngô Mây",
        "path": "Ngô Mây, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Ngô Mây, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21577",
        "parent_code": "540"
    },
    "21580": {
        "name": "Lý Thường Kiệt",
        "type": "phuong",
        "slug": "ly-thuong-kiet",
        "name_with_type": "Phường Lý Thường Kiệt",
        "path": "Lý Thường Kiệt, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Lý Thường Kiệt, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21580",
        "parent_code": "540"
    },
    "21583": {
        "name": "Lê Lợi",
        "type": "phuong",
        "slug": "le-loi",
        "name_with_type": "Phường Lê Lợi",
        "path": "Lê Lợi, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Lê Lợi, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21583",
        "parent_code": "540"
    },
    "21586": {
        "name": "Trần Phú",
        "type": "phuong",
        "slug": "tran-phu",
        "name_with_type": "Phường Trần Phú",
        "path": "Trần Phú, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Trần Phú, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21586",
        "parent_code": "540"
    },
    "21589": {
        "name": "Bùi Thị Xuân",
        "type": "phuong",
        "slug": "bui-thi-xuan",
        "name_with_type": "Phường Bùi Thị Xuân",
        "path": "Bùi Thị Xuân, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Bùi Thị Xuân, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21589",
        "parent_code": "540"
    },
    "21592": {
        "name": "Nguyễn Văn Cừ",
        "type": "phuong",
        "slug": "nguyen-van-cu",
        "name_with_type": "Phường Nguyễn Văn Cừ",
        "path": "Nguyễn Văn Cừ, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Nguyễn Văn Cừ, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21592",
        "parent_code": "540"
    },
    "21595": {
        "name": "Ghềnh Ráng",
        "type": "phuong",
        "slug": "ghenh-rang",
        "name_with_type": "Phường Ghềnh Ráng",
        "path": "Ghềnh Ráng, Qui Nhơn, Bình Định",
        "path_with_type": "Phường Ghềnh Ráng, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21595",
        "parent_code": "540"
    },
    "21598": {
        "name": "Nhơn Lý",
        "type": "xa",
        "slug": "nhon-ly",
        "name_with_type": "Xã Nhơn Lý",
        "path": "Nhơn Lý, Qui Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Lý, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21598",
        "parent_code": "540"
    },
    "21601": {
        "name": "Nhơn Hội",
        "type": "xa",
        "slug": "nhon-hoi",
        "name_with_type": "Xã Nhơn Hội",
        "path": "Nhơn Hội, Qui Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Hội, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21601",
        "parent_code": "540"
    },
    "21604": {
        "name": "Nhơn Hải",
        "type": "xa",
        "slug": "nhon-hai",
        "name_with_type": "Xã Nhơn Hải",
        "path": "Nhơn Hải, Qui Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Hải, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21604",
        "parent_code": "540"
    },
    "21607": {
        "name": "Nhơn Châu",
        "type": "xa",
        "slug": "nhon-chau",
        "name_with_type": "Xã Nhơn Châu",
        "path": "Nhơn Châu, Qui Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Châu, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21607",
        "parent_code": "540"
    },
    "21991": {
        "name": "Phước Mỹ",
        "type": "xa",
        "slug": "phuoc-my",
        "name_with_type": "Xã Phước Mỹ",
        "path": "Phước Mỹ, Qui Nhơn, Bình Định",
        "path_with_type": "Xã Phước Mỹ, Thành phố Qui Nhơn, Tỉnh Bình Định",
        "code": "21991",
        "parent_code": "540"
    },
    "21609": {
        "name": "An Lão",
        "type": "thi-tran",
        "slug": "an-lao",
        "name_with_type": "Thị trấn An Lão",
        "path": "An Lão, An Lão, Bình Định",
        "path_with_type": "Thị trấn An Lão, Huyện An Lão, Tỉnh Bình Định",
        "code": "21609",
        "parent_code": "542"
    },
    "21610": {
        "name": "An Hưng",
        "type": "xa",
        "slug": "an-hung",
        "name_with_type": "Xã An Hưng",
        "path": "An Hưng, An Lão, Bình Định",
        "path_with_type": "Xã An Hưng, Huyện An Lão, Tỉnh Bình Định",
        "code": "21610",
        "parent_code": "542"
    },
    "21613": {
        "name": "An Trung",
        "type": "xa",
        "slug": "an-trung",
        "name_with_type": "Xã An Trung",
        "path": "An Trung, An Lão, Bình Định",
        "path_with_type": "Xã An Trung, Huyện An Lão, Tỉnh Bình Định",
        "code": "21613",
        "parent_code": "542"
    },
    "21616": {
        "name": "An Dũng",
        "type": "xa",
        "slug": "an-dung",
        "name_with_type": "Xã An Dũng",
        "path": "An Dũng, An Lão, Bình Định",
        "path_with_type": "Xã An Dũng, Huyện An Lão, Tỉnh Bình Định",
        "code": "21616",
        "parent_code": "542"
    },
    "21619": {
        "name": "An Vinh",
        "type": "xa",
        "slug": "an-vinh",
        "name_with_type": "Xã An Vinh",
        "path": "An Vinh, An Lão, Bình Định",
        "path_with_type": "Xã An Vinh, Huyện An Lão, Tỉnh Bình Định",
        "code": "21619",
        "parent_code": "542"
    },
    "21622": {
        "name": "An Toàn",
        "type": "xa",
        "slug": "an-toan",
        "name_with_type": "Xã An Toàn",
        "path": "An Toàn, An Lão, Bình Định",
        "path_with_type": "Xã An Toàn, Huyện An Lão, Tỉnh Bình Định",
        "code": "21622",
        "parent_code": "542"
    },
    "21625": {
        "name": "An Tân",
        "type": "xa",
        "slug": "an-tan",
        "name_with_type": "Xã An Tân",
        "path": "An Tân, An Lão, Bình Định",
        "path_with_type": "Xã An Tân, Huyện An Lão, Tỉnh Bình Định",
        "code": "21625",
        "parent_code": "542"
    },
    "21628": {
        "name": "An Hòa",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hòa",
        "path": "An Hòa, An Lão, Bình Định",
        "path_with_type": "Xã An Hòa, Huyện An Lão, Tỉnh Bình Định",
        "code": "21628",
        "parent_code": "542"
    },
    "21631": {
        "name": "An Quang",
        "type": "xa",
        "slug": "an-quang",
        "name_with_type": "Xã An Quang",
        "path": "An Quang, An Lão, Bình Định",
        "path_with_type": "Xã An Quang, Huyện An Lão, Tỉnh Bình Định",
        "code": "21631",
        "parent_code": "542"
    },
    "21634": {
        "name": "An Nghĩa",
        "type": "xa",
        "slug": "an-nghia",
        "name_with_type": "Xã An Nghĩa",
        "path": "An Nghĩa, An Lão, Bình Định",
        "path_with_type": "Xã An Nghĩa, Huyện An Lão, Tỉnh Bình Định",
        "code": "21634",
        "parent_code": "542"
    },
    "21637": {
        "name": "Tam Quan",
        "type": "thi-tran",
        "slug": "tam-quan",
        "name_with_type": "Thị trấn  Tam Quan",
        "path": "Tam Quan, Hoài Nhơn, Bình Định",
        "path_with_type": "Thị trấn  Tam Quan, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21637",
        "parent_code": "543"
    },
    "21640": {
        "name": "Bồng Sơn",
        "type": "thi-tran",
        "slug": "bong-son",
        "name_with_type": "Thị trấn Bồng Sơn",
        "path": "Bồng Sơn, Hoài Nhơn, Bình Định",
        "path_with_type": "Thị trấn Bồng Sơn, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21640",
        "parent_code": "543"
    },
    "21643": {
        "name": "Hoài Sơn",
        "type": "xa",
        "slug": "hoai-son",
        "name_with_type": "Xã Hoài Sơn",
        "path": "Hoài Sơn, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Sơn, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21643",
        "parent_code": "543"
    },
    "21646": {
        "name": "Hoài Châu Bắc",
        "type": "xa",
        "slug": "hoai-chau-bac",
        "name_with_type": "Xã Hoài Châu Bắc",
        "path": "Hoài Châu Bắc, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Châu Bắc, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21646",
        "parent_code": "543"
    },
    "21649": {
        "name": "Hoài Châu",
        "type": "xa",
        "slug": "hoai-chau",
        "name_with_type": "Xã Hoài Châu",
        "path": "Hoài Châu, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Châu, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21649",
        "parent_code": "543"
    },
    "21652": {
        "name": "Hoài Phú",
        "type": "xa",
        "slug": "hoai-phu",
        "name_with_type": "Xã Hoài Phú",
        "path": "Hoài Phú, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Phú, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21652",
        "parent_code": "543"
    },
    "21655": {
        "name": "Tam Quan Bắc",
        "type": "xa",
        "slug": "tam-quan-bac",
        "name_with_type": "Xã Tam Quan Bắc",
        "path": "Tam Quan Bắc, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Tam Quan Bắc, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21655",
        "parent_code": "543"
    },
    "21658": {
        "name": "Tam Quan Nam",
        "type": "xa",
        "slug": "tam-quan-nam",
        "name_with_type": "Xã Tam Quan Nam",
        "path": "Tam Quan Nam, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Tam Quan Nam, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21658",
        "parent_code": "543"
    },
    "21661": {
        "name": "Hoài Hảo",
        "type": "xa",
        "slug": "hoai-hao",
        "name_with_type": "Xã Hoài Hảo",
        "path": "Hoài Hảo, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Hảo, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21661",
        "parent_code": "543"
    },
    "21664": {
        "name": "Hoài Thanh Tây",
        "type": "xa",
        "slug": "hoai-thanh-tay",
        "name_with_type": "Xã Hoài Thanh Tây",
        "path": "Hoài Thanh Tây, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Thanh Tây, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21664",
        "parent_code": "543"
    },
    "21667": {
        "name": "Hoài Thanh",
        "type": "xa",
        "slug": "hoai-thanh",
        "name_with_type": "Xã Hoài Thanh",
        "path": "Hoài Thanh, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Thanh, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21667",
        "parent_code": "543"
    },
    "21670": {
        "name": "Hoài Hương",
        "type": "xa",
        "slug": "hoai-huong",
        "name_with_type": "Xã Hoài Hương",
        "path": "Hoài Hương, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Hương, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21670",
        "parent_code": "543"
    },
    "21673": {
        "name": "Hoài Tân",
        "type": "xa",
        "slug": "hoai-tan",
        "name_with_type": "Xã Hoài Tân",
        "path": "Hoài Tân, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Tân, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21673",
        "parent_code": "543"
    },
    "21676": {
        "name": "Hoài Hải",
        "type": "xa",
        "slug": "hoai-hai",
        "name_with_type": "Xã Hoài Hải",
        "path": "Hoài Hải, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Hải, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21676",
        "parent_code": "543"
    },
    "21679": {
        "name": "Hoài Xuân",
        "type": "xa",
        "slug": "hoai-xuan",
        "name_with_type": "Xã Hoài Xuân",
        "path": "Hoài Xuân, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Xuân, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21679",
        "parent_code": "543"
    },
    "21682": {
        "name": "Hoài Mỹ",
        "type": "xa",
        "slug": "hoai-my",
        "name_with_type": "Xã Hoài Mỹ",
        "path": "Hoài Mỹ, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Mỹ, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21682",
        "parent_code": "543"
    },
    "21685": {
        "name": "Hoài Đức",
        "type": "xa",
        "slug": "hoai-duc",
        "name_with_type": "Xã Hoài Đức",
        "path": "Hoài Đức, Hoài Nhơn, Bình Định",
        "path_with_type": "Xã Hoài Đức, Huyện Hoài Nhơn, Tỉnh Bình Định",
        "code": "21685",
        "parent_code": "543"
    },
    "21688": {
        "name": "Tăng Bạt Hổ",
        "type": "thi-tran",
        "slug": "tang-bat-ho",
        "name_with_type": "Thị trấn Tăng Bạt Hổ",
        "path": "Tăng Bạt Hổ, Hoài Ân, Bình Định",
        "path_with_type": "Thị trấn Tăng Bạt Hổ, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21688",
        "parent_code": "544"
    },
    "21690": {
        "name": "Ân Hảo Tây",
        "type": "xa",
        "slug": "an-hao-tay",
        "name_with_type": "Xã Ân Hảo Tây",
        "path": "Ân Hảo Tây, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Hảo Tây, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21690",
        "parent_code": "544"
    },
    "21691": {
        "name": "Ân Hảo Đông",
        "type": "xa",
        "slug": "an-hao-dong",
        "name_with_type": "Xã Ân Hảo Đông",
        "path": "Ân Hảo Đông, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Hảo Đông, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21691",
        "parent_code": "544"
    },
    "21694": {
        "name": "Ân Sơn",
        "type": "xa",
        "slug": "an-son",
        "name_with_type": "Xã Ân Sơn",
        "path": "Ân Sơn, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Sơn, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21694",
        "parent_code": "544"
    },
    "21697": {
        "name": "Ân Mỹ",
        "type": "xa",
        "slug": "an-my",
        "name_with_type": "Xã Ân Mỹ",
        "path": "Ân Mỹ, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Mỹ, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21697",
        "parent_code": "544"
    },
    "21700": {
        "name": "Dak Mang",
        "type": "xa",
        "slug": "dak-mang",
        "name_with_type": "Xã Dak Mang",
        "path": "Dak Mang, Hoài Ân, Bình Định",
        "path_with_type": "Xã Dak Mang, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21700",
        "parent_code": "544"
    },
    "21703": {
        "name": "Ân Tín",
        "type": "xa",
        "slug": "an-tin",
        "name_with_type": "Xã Ân Tín",
        "path": "Ân Tín, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Tín, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21703",
        "parent_code": "544"
    },
    "21706": {
        "name": "Ân Thạnh",
        "type": "xa",
        "slug": "an-thanh",
        "name_with_type": "Xã Ân Thạnh",
        "path": "Ân Thạnh, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Thạnh, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21706",
        "parent_code": "544"
    },
    "21709": {
        "name": "Ân Phong",
        "type": "xa",
        "slug": "an-phong",
        "name_with_type": "Xã Ân Phong",
        "path": "Ân Phong, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Phong, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21709",
        "parent_code": "544"
    },
    "21712": {
        "name": "Ân Đức",
        "type": "xa",
        "slug": "an-duc",
        "name_with_type": "Xã Ân Đức",
        "path": "Ân Đức, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Đức, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21712",
        "parent_code": "544"
    },
    "21715": {
        "name": "Ân Hữu",
        "type": "xa",
        "slug": "an-huu",
        "name_with_type": "Xã Ân Hữu",
        "path": "Ân Hữu, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Hữu, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21715",
        "parent_code": "544"
    },
    "21718": {
        "name": "Bok Tới",
        "type": "xa",
        "slug": "bok-toi",
        "name_with_type": "Xã Bok Tới",
        "path": "Bok Tới, Hoài Ân, Bình Định",
        "path_with_type": "Xã Bok Tới, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21718",
        "parent_code": "544"
    },
    "21721": {
        "name": "Ân Tường Tây",
        "type": "xa",
        "slug": "an-tuong-tay",
        "name_with_type": "Xã Ân Tường Tây",
        "path": "Ân Tường Tây, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Tường Tây, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21721",
        "parent_code": "544"
    },
    "21724": {
        "name": "Ân Tường Đông",
        "type": "xa",
        "slug": "an-tuong-dong",
        "name_with_type": "Xã Ân Tường Đông",
        "path": "Ân Tường Đông, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Tường Đông, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21724",
        "parent_code": "544"
    },
    "21727": {
        "name": "Ân Nghĩa",
        "type": "xa",
        "slug": "an-nghia",
        "name_with_type": "Xã Ân Nghĩa",
        "path": "Ân Nghĩa, Hoài Ân, Bình Định",
        "path_with_type": "Xã Ân Nghĩa, Huyện Hoài Ân, Tỉnh Bình Định",
        "code": "21727",
        "parent_code": "544"
    },
    "21730": {
        "name": "Phù Mỹ",
        "type": "thi-tran",
        "slug": "phu-my",
        "name_with_type": "Thị trấn Phù Mỹ",
        "path": "Phù Mỹ, Phù Mỹ, Bình Định",
        "path_with_type": "Thị trấn Phù Mỹ, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21730",
        "parent_code": "545"
    },
    "21733": {
        "name": "Bình Dương",
        "type": "thi-tran",
        "slug": "binh-duong",
        "name_with_type": "Thị trấn Bình Dương",
        "path": "Bình Dương, Phù Mỹ, Bình Định",
        "path_with_type": "Thị trấn Bình Dương, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21733",
        "parent_code": "545"
    },
    "21736": {
        "name": "Mỹ Đức",
        "type": "xa",
        "slug": "my-duc",
        "name_with_type": "Xã Mỹ Đức",
        "path": "Mỹ Đức, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Đức, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21736",
        "parent_code": "545"
    },
    "21739": {
        "name": "Mỹ Châu",
        "type": "xa",
        "slug": "my-chau",
        "name_with_type": "Xã Mỹ Châu",
        "path": "Mỹ Châu, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Châu, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21739",
        "parent_code": "545"
    },
    "21742": {
        "name": "Mỹ Thắng",
        "type": "xa",
        "slug": "my-thang",
        "name_with_type": "Xã Mỹ Thắng",
        "path": "Mỹ Thắng, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Thắng, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21742",
        "parent_code": "545"
    },
    "21745": {
        "name": "Mỹ Lộc",
        "type": "xa",
        "slug": "my-loc",
        "name_with_type": "Xã Mỹ Lộc",
        "path": "Mỹ Lộc, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Lộc, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21745",
        "parent_code": "545"
    },
    "21748": {
        "name": "Mỹ Lợi",
        "type": "xa",
        "slug": "my-loi",
        "name_with_type": "Xã Mỹ Lợi",
        "path": "Mỹ Lợi, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Lợi, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21748",
        "parent_code": "545"
    },
    "21751": {
        "name": "Mỹ An",
        "type": "xa",
        "slug": "my-an",
        "name_with_type": "Xã Mỹ An",
        "path": "Mỹ An, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ An, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21751",
        "parent_code": "545"
    },
    "21754": {
        "name": "Mỹ Phong",
        "type": "xa",
        "slug": "my-phong",
        "name_with_type": "Xã Mỹ Phong",
        "path": "Mỹ Phong, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Phong, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21754",
        "parent_code": "545"
    },
    "21757": {
        "name": "Mỹ Trinh",
        "type": "xa",
        "slug": "my-trinh",
        "name_with_type": "Xã Mỹ Trinh",
        "path": "Mỹ Trinh, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Trinh, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21757",
        "parent_code": "545"
    },
    "21760": {
        "name": "Mỹ Thọ",
        "type": "xa",
        "slug": "my-tho",
        "name_with_type": "Xã Mỹ Thọ",
        "path": "Mỹ Thọ, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Thọ, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21760",
        "parent_code": "545"
    },
    "21763": {
        "name": "Mỹ Hòa",
        "type": "xa",
        "slug": "my-hoa",
        "name_with_type": "Xã Mỹ Hòa",
        "path": "Mỹ Hòa, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Hòa, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21763",
        "parent_code": "545"
    },
    "21766": {
        "name": "Mỹ Thành",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thành",
        "path": "Mỹ Thành, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Thành, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21766",
        "parent_code": "545"
    },
    "21769": {
        "name": "Mỹ Chánh",
        "type": "xa",
        "slug": "my-chanh",
        "name_with_type": "Xã Mỹ Chánh",
        "path": "Mỹ Chánh, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Chánh, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21769",
        "parent_code": "545"
    },
    "21772": {
        "name": "Mỹ Quang",
        "type": "xa",
        "slug": "my-quang",
        "name_with_type": "Xã Mỹ Quang",
        "path": "Mỹ Quang, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Quang, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21772",
        "parent_code": "545"
    },
    "21775": {
        "name": "Mỹ Hiệp",
        "type": "xa",
        "slug": "my-hiep",
        "name_with_type": "Xã Mỹ Hiệp",
        "path": "Mỹ Hiệp, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Hiệp, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21775",
        "parent_code": "545"
    },
    "21778": {
        "name": "Mỹ Tài",
        "type": "xa",
        "slug": "my-tai",
        "name_with_type": "Xã Mỹ Tài",
        "path": "Mỹ Tài, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Tài, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21778",
        "parent_code": "545"
    },
    "21781": {
        "name": "Mỹ Cát",
        "type": "xa",
        "slug": "my-cat",
        "name_with_type": "Xã Mỹ Cát",
        "path": "Mỹ Cát, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Cát, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21781",
        "parent_code": "545"
    },
    "21784": {
        "name": "Mỹ Chánh Tây",
        "type": "xa",
        "slug": "my-chanh-tay",
        "name_with_type": "Xã Mỹ Chánh Tây",
        "path": "Mỹ Chánh Tây, Phù Mỹ, Bình Định",
        "path_with_type": "Xã Mỹ Chánh Tây, Huyện Phù Mỹ, Tỉnh Bình Định",
        "code": "21784",
        "parent_code": "545"
    },
    "21786": {
        "name": "Vĩnh Thạnh",
        "type": "thi-tran",
        "slug": "vinh-thanh",
        "name_with_type": "Thị trấn Vĩnh Thạnh",
        "path": "Vĩnh Thạnh, Vĩnh Thạnh, Bình Định",
        "path_with_type": "Thị trấn Vĩnh Thạnh, Huyện Vĩnh Thạnh, Tỉnh Bình Định",
        "code": "21786",
        "parent_code": "546"
    },
    "21787": {
        "name": "Vĩnh Sơn",
        "type": "xa",
        "slug": "vinh-son",
        "name_with_type": "Xã Vĩnh Sơn",
        "path": "Vĩnh Sơn, Vĩnh Thạnh, Bình Định",
        "path_with_type": "Xã Vĩnh Sơn, Huyện Vĩnh Thạnh, Tỉnh Bình Định",
        "code": "21787",
        "parent_code": "546"
    },
    "21790": {
        "name": "Vĩnh Kim",
        "type": "xa",
        "slug": "vinh-kim",
        "name_with_type": "Xã Vĩnh Kim",
        "path": "Vĩnh Kim, Vĩnh Thạnh, Bình Định",
        "path_with_type": "Xã Vĩnh Kim, Huyện Vĩnh Thạnh, Tỉnh Bình Định",
        "code": "21790",
        "parent_code": "546"
    },
    "21796": {
        "name": "Vĩnh Hiệp",
        "type": "xa",
        "slug": "vinh-hiep",
        "name_with_type": "Xã Vĩnh Hiệp",
        "path": "Vĩnh Hiệp, Vĩnh Thạnh, Bình Định",
        "path_with_type": "Xã Vĩnh Hiệp, Huyện Vĩnh Thạnh, Tỉnh Bình Định",
        "code": "21796",
        "parent_code": "546"
    },
    "21799": {
        "name": "Vĩnh Hảo",
        "type": "xa",
        "slug": "vinh-hao",
        "name_with_type": "Xã Vĩnh Hảo",
        "path": "Vĩnh Hảo, Vĩnh Thạnh, Bình Định",
        "path_with_type": "Xã Vĩnh Hảo, Huyện Vĩnh Thạnh, Tỉnh Bình Định",
        "code": "21799",
        "parent_code": "546"
    },
    "21801": {
        "name": "Vĩnh Hòa",
        "type": "xa",
        "slug": "vinh-hoa",
        "name_with_type": "Xã Vĩnh Hòa",
        "path": "Vĩnh Hòa, Vĩnh Thạnh, Bình Định",
        "path_with_type": "Xã Vĩnh Hòa, Huyện Vĩnh Thạnh, Tỉnh Bình Định",
        "code": "21801",
        "parent_code": "546"
    },
    "21802": {
        "name": "Vĩnh Thịnh",
        "type": "xa",
        "slug": "vinh-thinh",
        "name_with_type": "Xã Vĩnh Thịnh",
        "path": "Vĩnh Thịnh, Vĩnh Thạnh, Bình Định",
        "path_with_type": "Xã Vĩnh Thịnh, Huyện Vĩnh Thạnh, Tỉnh Bình Định",
        "code": "21802",
        "parent_code": "546"
    },
    "21804": {
        "name": "Vĩnh Thuận",
        "type": "xa",
        "slug": "vinh-thuan",
        "name_with_type": "Xã Vĩnh Thuận",
        "path": "Vĩnh Thuận, Vĩnh Thạnh, Bình Định",
        "path_with_type": "Xã Vĩnh Thuận, Huyện Vĩnh Thạnh, Tỉnh Bình Định",
        "code": "21804",
        "parent_code": "546"
    },
    "21805": {
        "name": "Vĩnh Quang",
        "type": "xa",
        "slug": "vinh-quang",
        "name_with_type": "Xã Vĩnh Quang",
        "path": "Vĩnh Quang, Vĩnh Thạnh, Bình Định",
        "path_with_type": "Xã Vĩnh Quang, Huyện Vĩnh Thạnh, Tỉnh Bình Định",
        "code": "21805",
        "parent_code": "546"
    },
    "21808": {
        "name": "Phú Phong",
        "type": "thi-tran",
        "slug": "phu-phong",
        "name_with_type": "Thị trấn Phú Phong",
        "path": "Phú Phong, Tây Sơn, Bình Định",
        "path_with_type": "Thị trấn Phú Phong, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21808",
        "parent_code": "547"
    },
    "21811": {
        "name": "Bình Tân",
        "type": "xa",
        "slug": "binh-tan",
        "name_with_type": "Xã Bình Tân",
        "path": "Bình Tân, Tây Sơn, Bình Định",
        "path_with_type": "Xã Bình Tân, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21811",
        "parent_code": "547"
    },
    "21814": {
        "name": "Tây Thuận",
        "type": "xa",
        "slug": "tay-thuan",
        "name_with_type": "Xã Tây Thuận",
        "path": "Tây Thuận, Tây Sơn, Bình Định",
        "path_with_type": "Xã Tây Thuận, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21814",
        "parent_code": "547"
    },
    "21817": {
        "name": "Bình Thuận",
        "type": "xa",
        "slug": "binh-thuan",
        "name_with_type": "Xã Bình Thuận",
        "path": "Bình Thuận, Tây Sơn, Bình Định",
        "path_with_type": "Xã Bình Thuận, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21817",
        "parent_code": "547"
    },
    "21820": {
        "name": "Tây Giang",
        "type": "xa",
        "slug": "tay-giang",
        "name_with_type": "Xã Tây Giang",
        "path": "Tây Giang, Tây Sơn, Bình Định",
        "path_with_type": "Xã Tây Giang, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21820",
        "parent_code": "547"
    },
    "21823": {
        "name": "Bình Thành",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thành",
        "path": "Bình Thành, Tây Sơn, Bình Định",
        "path_with_type": "Xã Bình Thành, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21823",
        "parent_code": "547"
    },
    "21826": {
        "name": "Tây An",
        "type": "xa",
        "slug": "tay-an",
        "name_with_type": "Xã Tây An",
        "path": "Tây An, Tây Sơn, Bình Định",
        "path_with_type": "Xã Tây An, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21826",
        "parent_code": "547"
    },
    "21829": {
        "name": "Bình Hòa",
        "type": "xa",
        "slug": "binh-hoa",
        "name_with_type": "Xã Bình Hòa",
        "path": "Bình Hòa, Tây Sơn, Bình Định",
        "path_with_type": "Xã Bình Hòa, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21829",
        "parent_code": "547"
    },
    "21832": {
        "name": "Tây Bình",
        "type": "xa",
        "slug": "tay-binh",
        "name_with_type": "Xã Tây Bình",
        "path": "Tây Bình, Tây Sơn, Bình Định",
        "path_with_type": "Xã Tây Bình, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21832",
        "parent_code": "547"
    },
    "21835": {
        "name": "Bình Tường",
        "type": "xa",
        "slug": "binh-tuong",
        "name_with_type": "Xã Bình Tường",
        "path": "Bình Tường, Tây Sơn, Bình Định",
        "path_with_type": "Xã Bình Tường, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21835",
        "parent_code": "547"
    },
    "21838": {
        "name": "Tây Vinh",
        "type": "xa",
        "slug": "tay-vinh",
        "name_with_type": "Xã Tây Vinh",
        "path": "Tây Vinh, Tây Sơn, Bình Định",
        "path_with_type": "Xã Tây Vinh, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21838",
        "parent_code": "547"
    },
    "21841": {
        "name": "Vĩnh An",
        "type": "xa",
        "slug": "vinh-an",
        "name_with_type": "Xã Vĩnh An",
        "path": "Vĩnh An, Tây Sơn, Bình Định",
        "path_with_type": "Xã Vĩnh An, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21841",
        "parent_code": "547"
    },
    "21844": {
        "name": "Tây Xuân",
        "type": "xa",
        "slug": "tay-xuan",
        "name_with_type": "Xã Tây Xuân",
        "path": "Tây Xuân, Tây Sơn, Bình Định",
        "path_with_type": "Xã Tây Xuân, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21844",
        "parent_code": "547"
    },
    "21847": {
        "name": "Bình Nghi",
        "type": "xa",
        "slug": "binh-nghi",
        "name_with_type": "Xã Bình Nghi",
        "path": "Bình Nghi, Tây Sơn, Bình Định",
        "path_with_type": "Xã Bình Nghi, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21847",
        "parent_code": "547"
    },
    "21850": {
        "name": "Tây Phú",
        "type": "xa",
        "slug": "tay-phu",
        "name_with_type": "Xã Tây Phú",
        "path": "Tây Phú, Tây Sơn, Bình Định",
        "path_with_type": "Xã Tây Phú, Huyện Tây Sơn, Tỉnh Bình Định",
        "code": "21850",
        "parent_code": "547"
    },
    "21853": {
        "name": "Ngô Mây",
        "type": "thi-tran",
        "slug": "ngo-may",
        "name_with_type": "Thị trấn Ngô Mây",
        "path": "Ngô Mây, Phù Cát, Bình Định",
        "path_with_type": "Thị trấn Ngô Mây, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21853",
        "parent_code": "548"
    },
    "21856": {
        "name": "Cát Sơn",
        "type": "xa",
        "slug": "cat-son",
        "name_with_type": "Xã Cát Sơn",
        "path": "Cát Sơn, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Sơn, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21856",
        "parent_code": "548"
    },
    "21859": {
        "name": "Cát Minh",
        "type": "xa",
        "slug": "cat-minh",
        "name_with_type": "Xã Cát Minh",
        "path": "Cát Minh, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Minh, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21859",
        "parent_code": "548"
    },
    "21862": {
        "name": "Cát Khánh",
        "type": "xa",
        "slug": "cat-khanh",
        "name_with_type": "Xã Cát Khánh",
        "path": "Cát Khánh, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Khánh, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21862",
        "parent_code": "548"
    },
    "21865": {
        "name": "Cát Tài",
        "type": "xa",
        "slug": "cat-tai",
        "name_with_type": "Xã Cát Tài",
        "path": "Cát Tài, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Tài, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21865",
        "parent_code": "548"
    },
    "21868": {
        "name": "Cát Lâm",
        "type": "xa",
        "slug": "cat-lam",
        "name_with_type": "Xã Cát Lâm",
        "path": "Cát Lâm, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Lâm, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21868",
        "parent_code": "548"
    },
    "21871": {
        "name": "Cát Hanh",
        "type": "xa",
        "slug": "cat-hanh",
        "name_with_type": "Xã Cát Hanh",
        "path": "Cát Hanh, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Hanh, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21871",
        "parent_code": "548"
    },
    "21874": {
        "name": "Cát Thành",
        "type": "xa",
        "slug": "cat-thanh",
        "name_with_type": "Xã Cát Thành",
        "path": "Cát Thành, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Thành, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21874",
        "parent_code": "548"
    },
    "21877": {
        "name": "Cát Trinh",
        "type": "xa",
        "slug": "cat-trinh",
        "name_with_type": "Xã Cát Trinh",
        "path": "Cát Trinh, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Trinh, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21877",
        "parent_code": "548"
    },
    "21880": {
        "name": "Cát Hải",
        "type": "xa",
        "slug": "cat-hai",
        "name_with_type": "Xã Cát Hải",
        "path": "Cát Hải, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Hải, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21880",
        "parent_code": "548"
    },
    "21883": {
        "name": "Cát Hiệp",
        "type": "xa",
        "slug": "cat-hiep",
        "name_with_type": "Xã Cát Hiệp",
        "path": "Cát Hiệp, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Hiệp, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21883",
        "parent_code": "548"
    },
    "21886": {
        "name": "Cát Nhơn",
        "type": "xa",
        "slug": "cat-nhon",
        "name_with_type": "Xã Cát Nhơn",
        "path": "Cát Nhơn, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Nhơn, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21886",
        "parent_code": "548"
    },
    "21889": {
        "name": "Cát Hưng",
        "type": "xa",
        "slug": "cat-hung",
        "name_with_type": "Xã Cát Hưng",
        "path": "Cát Hưng, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Hưng, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21889",
        "parent_code": "548"
    },
    "21892": {
        "name": "Cát Tường",
        "type": "xa",
        "slug": "cat-tuong",
        "name_with_type": "Xã Cát Tường",
        "path": "Cát Tường, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Tường, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21892",
        "parent_code": "548"
    },
    "21895": {
        "name": "Cát Tân",
        "type": "xa",
        "slug": "cat-tan",
        "name_with_type": "Xã Cát Tân",
        "path": "Cát Tân, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Tân, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21895",
        "parent_code": "548"
    },
    "21898": {
        "name": "Cát Tiến",
        "type": "xa",
        "slug": "cat-tien",
        "name_with_type": "Xã Cát Tiến",
        "path": "Cát Tiến, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Tiến, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21898",
        "parent_code": "548"
    },
    "21901": {
        "name": "Cát Thắng",
        "type": "xa",
        "slug": "cat-thang",
        "name_with_type": "Xã Cát Thắng",
        "path": "Cát Thắng, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Thắng, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21901",
        "parent_code": "548"
    },
    "21904": {
        "name": "Cát Chánh",
        "type": "xa",
        "slug": "cat-chanh",
        "name_with_type": "Xã Cát Chánh",
        "path": "Cát Chánh, Phù Cát, Bình Định",
        "path_with_type": "Xã Cát Chánh, Huyện Phù Cát, Tỉnh Bình Định",
        "code": "21904",
        "parent_code": "548"
    },
    "21907": {
        "name": "Bình Định",
        "type": "phuong",
        "slug": "binh-dinh",
        "name_with_type": "Phường Bình Định",
        "path": "Bình Định, An Nhơn, Bình Định",
        "path_with_type": "Phường Bình Định, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21907",
        "parent_code": "549"
    },
    "21910": {
        "name": "Đập Đá",
        "type": "phuong",
        "slug": "dap-da",
        "name_with_type": "Phường Đập Đá",
        "path": "Đập Đá, An Nhơn, Bình Định",
        "path_with_type": "Phường Đập Đá, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21910",
        "parent_code": "549"
    },
    "21913": {
        "name": "Nhơn Mỹ",
        "type": "xa",
        "slug": "nhon-my",
        "name_with_type": "Xã Nhơn Mỹ",
        "path": "Nhơn Mỹ, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Mỹ, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21913",
        "parent_code": "549"
    },
    "21916": {
        "name": "Nhơn Thành",
        "type": "phuong",
        "slug": "nhon-thanh",
        "name_with_type": "Phường Nhơn Thành",
        "path": "Nhơn Thành, An Nhơn, Bình Định",
        "path_with_type": "Phường Nhơn Thành, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21916",
        "parent_code": "549"
    },
    "21919": {
        "name": "Nhơn Hạnh",
        "type": "xa",
        "slug": "nhon-hanh",
        "name_with_type": "Xã Nhơn Hạnh",
        "path": "Nhơn Hạnh, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Hạnh, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21919",
        "parent_code": "549"
    },
    "21922": {
        "name": "Nhơn Hậu",
        "type": "xa",
        "slug": "nhon-hau",
        "name_with_type": "Xã Nhơn Hậu",
        "path": "Nhơn Hậu, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Hậu, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21922",
        "parent_code": "549"
    },
    "21925": {
        "name": "Nhơn Phong",
        "type": "xa",
        "slug": "nhon-phong",
        "name_with_type": "Xã Nhơn Phong",
        "path": "Nhơn Phong, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Phong, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21925",
        "parent_code": "549"
    },
    "21928": {
        "name": "Nhơn An",
        "type": "xa",
        "slug": "nhon-an",
        "name_with_type": "Xã Nhơn An",
        "path": "Nhơn An, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn An, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21928",
        "parent_code": "549"
    },
    "21931": {
        "name": "Nhơn Phúc",
        "type": "xa",
        "slug": "nhon-phuc",
        "name_with_type": "Xã Nhơn Phúc",
        "path": "Nhơn Phúc, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Phúc, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21931",
        "parent_code": "549"
    },
    "21934": {
        "name": "Nhơn Hưng",
        "type": "phuong",
        "slug": "nhon-hung",
        "name_with_type": "Phường Nhơn Hưng",
        "path": "Nhơn Hưng, An Nhơn, Bình Định",
        "path_with_type": "Phường Nhơn Hưng, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21934",
        "parent_code": "549"
    },
    "21937": {
        "name": "Nhơn Khánh",
        "type": "xa",
        "slug": "nhon-khanh",
        "name_with_type": "Xã Nhơn Khánh",
        "path": "Nhơn Khánh, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Khánh, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21937",
        "parent_code": "549"
    },
    "21940": {
        "name": "Nhơn Lộc",
        "type": "xa",
        "slug": "nhon-loc",
        "name_with_type": "Xã Nhơn Lộc",
        "path": "Nhơn Lộc, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Lộc, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21940",
        "parent_code": "549"
    },
    "21943": {
        "name": "Nhơn Hoà",
        "type": "phuong",
        "slug": "nhon-hoa",
        "name_with_type": "Phường Nhơn Hoà",
        "path": "Nhơn Hoà, An Nhơn, Bình Định",
        "path_with_type": "Phường Nhơn Hoà, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21943",
        "parent_code": "549"
    },
    "21946": {
        "name": "Nhơn Tân",
        "type": "xa",
        "slug": "nhon-tan",
        "name_with_type": "Xã Nhơn Tân",
        "path": "Nhơn Tân, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Tân, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21946",
        "parent_code": "549"
    },
    "21949": {
        "name": "Nhơn Thọ",
        "type": "xa",
        "slug": "nhon-tho",
        "name_with_type": "Xã Nhơn Thọ",
        "path": "Nhơn Thọ, An Nhơn, Bình Định",
        "path_with_type": "Xã Nhơn Thọ, Thị xã An Nhơn, Tỉnh Bình Định",
        "code": "21949",
        "parent_code": "549"
    },
    "21952": {
        "name": "Tuy Phước",
        "type": "thi-tran",
        "slug": "tuy-phuoc",
        "name_with_type": "Thị trấn Tuy Phước",
        "path": "Tuy Phước, Tuy Phước, Bình Định",
        "path_with_type": "Thị trấn Tuy Phước, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21952",
        "parent_code": "550"
    },
    "21955": {
        "name": "Diêu Trì",
        "type": "thi-tran",
        "slug": "dieu-tri",
        "name_with_type": "Thị trấn Diêu Trì",
        "path": "Diêu Trì, Tuy Phước, Bình Định",
        "path_with_type": "Thị trấn Diêu Trì, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21955",
        "parent_code": "550"
    },
    "21958": {
        "name": "Phước Thắng",
        "type": "xa",
        "slug": "phuoc-thang",
        "name_with_type": "Xã Phước Thắng",
        "path": "Phước Thắng, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Thắng, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21958",
        "parent_code": "550"
    },
    "21961": {
        "name": "Phước Hưng",
        "type": "xa",
        "slug": "phuoc-hung",
        "name_with_type": "Xã Phước Hưng",
        "path": "Phước Hưng, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Hưng, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21961",
        "parent_code": "550"
    },
    "21964": {
        "name": "Phước Quang",
        "type": "xa",
        "slug": "phuoc-quang",
        "name_with_type": "Xã Phước Quang",
        "path": "Phước Quang, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Quang, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21964",
        "parent_code": "550"
    },
    "21967": {
        "name": "Phước Hòa",
        "type": "xa",
        "slug": "phuoc-hoa",
        "name_with_type": "Xã Phước Hòa",
        "path": "Phước Hòa, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Hòa, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21967",
        "parent_code": "550"
    },
    "21970": {
        "name": "Phước Sơn",
        "type": "xa",
        "slug": "phuoc-son",
        "name_with_type": "Xã Phước Sơn",
        "path": "Phước Sơn, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Sơn, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21970",
        "parent_code": "550"
    },
    "21973": {
        "name": "Phước Hiệp",
        "type": "xa",
        "slug": "phuoc-hiep",
        "name_with_type": "Xã Phước Hiệp",
        "path": "Phước Hiệp, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Hiệp, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21973",
        "parent_code": "550"
    },
    "21976": {
        "name": "Phước Lộc",
        "type": "xa",
        "slug": "phuoc-loc",
        "name_with_type": "Xã Phước Lộc",
        "path": "Phước Lộc, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Lộc, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21976",
        "parent_code": "550"
    },
    "21979": {
        "name": "Phước Nghĩa",
        "type": "xa",
        "slug": "phuoc-nghia",
        "name_with_type": "Xã Phước Nghĩa",
        "path": "Phước Nghĩa, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Nghĩa, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21979",
        "parent_code": "550"
    },
    "21982": {
        "name": "Phước Thuận",
        "type": "xa",
        "slug": "phuoc-thuan",
        "name_with_type": "Xã Phước Thuận",
        "path": "Phước Thuận, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Thuận, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21982",
        "parent_code": "550"
    },
    "21985": {
        "name": "Phước An",
        "type": "xa",
        "slug": "phuoc-an",
        "name_with_type": "Xã Phước An",
        "path": "Phước An, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước An, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21985",
        "parent_code": "550"
    },
    "21988": {
        "name": "Phước Thành",
        "type": "xa",
        "slug": "phuoc-thanh",
        "name_with_type": "Xã Phước Thành",
        "path": "Phước Thành, Tuy Phước, Bình Định",
        "path_with_type": "Xã Phước Thành, Huyện Tuy Phước, Tỉnh Bình Định",
        "code": "21988",
        "parent_code": "550"
    },
    "21994": {
        "name": "Vân Canh",
        "type": "thi-tran",
        "slug": "van-canh",
        "name_with_type": "Thị trấn Vân Canh",
        "path": "Vân Canh, Vân Canh, Bình Định",
        "path_with_type": "Thị trấn Vân Canh, Huyện Vân Canh, Tỉnh Bình Định",
        "code": "21994",
        "parent_code": "551"
    },
    "21997": {
        "name": "Canh Liên",
        "type": "xa",
        "slug": "canh-lien",
        "name_with_type": "Xã Canh Liên",
        "path": "Canh Liên, Vân Canh, Bình Định",
        "path_with_type": "Xã Canh Liên, Huyện Vân Canh, Tỉnh Bình Định",
        "code": "21997",
        "parent_code": "551"
    },
    "22000": {
        "name": "Canh Hiệp",
        "type": "xa",
        "slug": "canh-hiep",
        "name_with_type": "Xã Canh Hiệp",
        "path": "Canh Hiệp, Vân Canh, Bình Định",
        "path_with_type": "Xã Canh Hiệp, Huyện Vân Canh, Tỉnh Bình Định",
        "code": "22000",
        "parent_code": "551"
    },
    "22003": {
        "name": "Canh Vinh",
        "type": "xa",
        "slug": "canh-vinh",
        "name_with_type": "Xã Canh Vinh",
        "path": "Canh Vinh, Vân Canh, Bình Định",
        "path_with_type": "Xã Canh Vinh, Huyện Vân Canh, Tỉnh Bình Định",
        "code": "22003",
        "parent_code": "551"
    },
    "22006": {
        "name": "Canh Hiển",
        "type": "xa",
        "slug": "canh-hien",
        "name_with_type": "Xã Canh Hiển",
        "path": "Canh Hiển, Vân Canh, Bình Định",
        "path_with_type": "Xã Canh Hiển, Huyện Vân Canh, Tỉnh Bình Định",
        "code": "22006",
        "parent_code": "551"
    },
    "22009": {
        "name": "Canh Thuận",
        "type": "xa",
        "slug": "canh-thuan",
        "name_with_type": "Xã Canh Thuận",
        "path": "Canh Thuận, Vân Canh, Bình Định",
        "path_with_type": "Xã Canh Thuận, Huyện Vân Canh, Tỉnh Bình Định",
        "code": "22009",
        "parent_code": "551"
    },
    "22012": {
        "name": "Canh Hòa",
        "type": "xa",
        "slug": "canh-hoa",
        "name_with_type": "Xã Canh Hòa",
        "path": "Canh Hòa, Vân Canh, Bình Định",
        "path_with_type": "Xã Canh Hòa, Huyện Vân Canh, Tỉnh Bình Định",
        "code": "22012",
        "parent_code": "551"
    },
    "16663": {
        "name": "Đông Vĩnh",
        "type": "phuong",
        "slug": "dong-vinh",
        "name_with_type": "Phường Đông Vĩnh",
        "path": "Đông Vĩnh, Vinh, Nghệ An",
        "path_with_type": "Phường Đông Vĩnh, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16663",
        "parent_code": "412"
    },
    "16666": {
        "name": "Hà Huy Tập",
        "type": "phuong",
        "slug": "ha-huy-tap",
        "name_with_type": "Phường Hà Huy Tập",
        "path": "Hà Huy Tập, Vinh, Nghệ An",
        "path_with_type": "Phường Hà Huy Tập, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16666",
        "parent_code": "412"
    },
    "16669": {
        "name": "Lê Lợi",
        "type": "phuong",
        "slug": "le-loi",
        "name_with_type": "Phường Lê Lợi",
        "path": "Lê Lợi, Vinh, Nghệ An",
        "path_with_type": "Phường Lê Lợi, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16669",
        "parent_code": "412"
    },
    "16670": {
        "name": "Quán Bàu",
        "type": "phuong",
        "slug": "quan-bau",
        "name_with_type": "Phường Quán Bàu",
        "path": "Quán Bàu, Vinh, Nghệ An",
        "path_with_type": "Phường Quán Bàu, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16670",
        "parent_code": "412"
    },
    "16672": {
        "name": "Hưng Bình",
        "type": "phuong",
        "slug": "hung-binh",
        "name_with_type": "Phường Hưng Bình",
        "path": "Hưng Bình, Vinh, Nghệ An",
        "path_with_type": "Phường Hưng Bình, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16672",
        "parent_code": "412"
    },
    "16673": {
        "name": "Hưng Phúc",
        "type": "phuong",
        "slug": "hung-phuc",
        "name_with_type": "Phường Hưng Phúc",
        "path": "Hưng Phúc, Vinh, Nghệ An",
        "path_with_type": "Phường Hưng Phúc, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16673",
        "parent_code": "412"
    },
    "16675": {
        "name": "Hưng Dũng",
        "type": "phuong",
        "slug": "hung-dung",
        "name_with_type": "Phường Hưng Dũng",
        "path": "Hưng Dũng, Vinh, Nghệ An",
        "path_with_type": "Phường Hưng Dũng, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16675",
        "parent_code": "412"
    },
    "16678": {
        "name": "Cửa Nam",
        "type": "phuong",
        "slug": "cua-nam",
        "name_with_type": "Phường Cửa Nam",
        "path": "Cửa Nam, Vinh, Nghệ An",
        "path_with_type": "Phường Cửa Nam, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16678",
        "parent_code": "412"
    },
    "16681": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Vinh, Nghệ An",
        "path_with_type": "Phường Quang Trung, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16681",
        "parent_code": "412"
    },
    "16684": {
        "name": "Đội Cung",
        "type": "phuong",
        "slug": "doi-cung",
        "name_with_type": "Phường Đội Cung",
        "path": "Đội Cung, Vinh, Nghệ An",
        "path_with_type": "Phường Đội Cung, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16684",
        "parent_code": "412"
    },
    "16687": {
        "name": "Lê Mao",
        "type": "phuong",
        "slug": "le-mao",
        "name_with_type": "Phường Lê Mao",
        "path": "Lê Mao, Vinh, Nghệ An",
        "path_with_type": "Phường Lê Mao, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16687",
        "parent_code": "412"
    },
    "16690": {
        "name": "Trường Thi",
        "type": "phuong",
        "slug": "truong-thi",
        "name_with_type": "Phường Trường Thi",
        "path": "Trường Thi, Vinh, Nghệ An",
        "path_with_type": "Phường Trường Thi, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16690",
        "parent_code": "412"
    },
    "16693": {
        "name": "Bến Thủy",
        "type": "phuong",
        "slug": "ben-thuy",
        "name_with_type": "Phường Bến Thủy",
        "path": "Bến Thủy, Vinh, Nghệ An",
        "path_with_type": "Phường Bến Thủy, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16693",
        "parent_code": "412"
    },
    "16696": {
        "name": "Hồng Sơn",
        "type": "phuong",
        "slug": "hong-son",
        "name_with_type": "Phường Hồng Sơn",
        "path": "Hồng Sơn, Vinh, Nghệ An",
        "path_with_type": "Phường Hồng Sơn, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16696",
        "parent_code": "412"
    },
    "16699": {
        "name": "Trung Đô",
        "type": "phuong",
        "slug": "trung-do",
        "name_with_type": "Phường Trung Đô",
        "path": "Trung Đô, Vinh, Nghệ An",
        "path_with_type": "Phường Trung Đô, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16699",
        "parent_code": "412"
    },
    "16702": {
        "name": "Nghi Phú",
        "type": "xa",
        "slug": "nghi-phu",
        "name_with_type": "Xã Nghi Phú",
        "path": "Nghi Phú, Vinh, Nghệ An",
        "path_with_type": "Xã Nghi Phú, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16702",
        "parent_code": "412"
    },
    "16705": {
        "name": "Hưng Đông",
        "type": "xa",
        "slug": "hung-dong",
        "name_with_type": "Xã Hưng Đông",
        "path": "Hưng Đông, Vinh, Nghệ An",
        "path_with_type": "Xã Hưng Đông, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16705",
        "parent_code": "412"
    },
    "16708": {
        "name": "Hưng Lộc",
        "type": "xa",
        "slug": "hung-loc",
        "name_with_type": "Xã Hưng Lộc",
        "path": "Hưng Lộc, Vinh, Nghệ An",
        "path_with_type": "Xã Hưng Lộc, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16708",
        "parent_code": "412"
    },
    "16711": {
        "name": "Hưng Hòa",
        "type": "xa",
        "slug": "hung-hoa",
        "name_with_type": "Xã Hưng Hòa",
        "path": "Hưng Hòa, Vinh, Nghệ An",
        "path_with_type": "Xã Hưng Hòa, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16711",
        "parent_code": "412"
    },
    "16714": {
        "name": "Vinh Tân",
        "type": "phuong",
        "slug": "vinh-tan",
        "name_with_type": "Phường Vinh Tân",
        "path": "Vinh Tân, Vinh, Nghệ An",
        "path_with_type": "Phường Vinh Tân, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "16714",
        "parent_code": "412"
    },
    "17908": {
        "name": "Nghi Liên",
        "type": "xa",
        "slug": "nghi-lien",
        "name_with_type": "Xã Nghi Liên",
        "path": "Nghi Liên, Vinh, Nghệ An",
        "path_with_type": "Xã Nghi Liên, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "17908",
        "parent_code": "412"
    },
    "17914": {
        "name": "Nghi Ân",
        "type": "xa",
        "slug": "nghi-an",
        "name_with_type": "Xã Nghi Ân",
        "path": "Nghi Ân, Vinh, Nghệ An",
        "path_with_type": "Xã Nghi Ân, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "17914",
        "parent_code": "412"
    },
    "17920": {
        "name": "Nghi Kim",
        "type": "xa",
        "slug": "nghi-kim",
        "name_with_type": "Xã Nghi Kim",
        "path": "Nghi Kim, Vinh, Nghệ An",
        "path_with_type": "Xã Nghi Kim, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "17920",
        "parent_code": "412"
    },
    "17923": {
        "name": "Nghi Đức",
        "type": "xa",
        "slug": "nghi-duc",
        "name_with_type": "Xã Nghi Đức",
        "path": "Nghi Đức, Vinh, Nghệ An",
        "path_with_type": "Xã Nghi Đức, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "17923",
        "parent_code": "412"
    },
    "18013": {
        "name": "Hưng Chính",
        "type": "xa",
        "slug": "hung-chinh",
        "name_with_type": "Xã Hưng Chính",
        "path": "Hưng Chính, Vinh, Nghệ An",
        "path_with_type": "Xã Hưng Chính, Thành phố Vinh, Tỉnh Nghệ An",
        "code": "18013",
        "parent_code": "412"
    },
    "16717": {
        "name": "Nghi Thuỷ",
        "type": "phuong",
        "slug": "nghi-thuy",
        "name_with_type": "Phường Nghi Thuỷ",
        "path": "Nghi Thuỷ, Cửa Lò, Nghệ An",
        "path_with_type": "Phường Nghi Thuỷ, Thị xã Cửa Lò, Tỉnh Nghệ An",
        "code": "16717",
        "parent_code": "413"
    },
    "16720": {
        "name": "Nghi Tân",
        "type": "phuong",
        "slug": "nghi-tan",
        "name_with_type": "Phường Nghi Tân",
        "path": "Nghi Tân, Cửa Lò, Nghệ An",
        "path_with_type": "Phường Nghi Tân, Thị xã Cửa Lò, Tỉnh Nghệ An",
        "code": "16720",
        "parent_code": "413"
    },
    "16723": {
        "name": "Thu Thuỷ",
        "type": "phuong",
        "slug": "thu-thuy",
        "name_with_type": "Phường Thu Thuỷ",
        "path": "Thu Thuỷ, Cửa Lò, Nghệ An",
        "path_with_type": "Phường Thu Thuỷ, Thị xã Cửa Lò, Tỉnh Nghệ An",
        "code": "16723",
        "parent_code": "413"
    },
    "16726": {
        "name": "Nghi Hòa",
        "type": "phuong",
        "slug": "nghi-hoa",
        "name_with_type": "Phường Nghi Hòa",
        "path": "Nghi Hòa, Cửa Lò, Nghệ An",
        "path_with_type": "Phường Nghi Hòa, Thị xã Cửa Lò, Tỉnh Nghệ An",
        "code": "16726",
        "parent_code": "413"
    },
    "16729": {
        "name": "Nghi Hải",
        "type": "phuong",
        "slug": "nghi-hai",
        "name_with_type": "Phường Nghi Hải",
        "path": "Nghi Hải, Cửa Lò, Nghệ An",
        "path_with_type": "Phường Nghi Hải, Thị xã Cửa Lò, Tỉnh Nghệ An",
        "code": "16729",
        "parent_code": "413"
    },
    "16732": {
        "name": "Nghi Hương",
        "type": "phuong",
        "slug": "nghi-huong",
        "name_with_type": "Phường Nghi Hương",
        "path": "Nghi Hương, Cửa Lò, Nghệ An",
        "path_with_type": "Phường Nghi Hương, Thị xã Cửa Lò, Tỉnh Nghệ An",
        "code": "16732",
        "parent_code": "413"
    },
    "16735": {
        "name": "Nghi Thu",
        "type": "phuong",
        "slug": "nghi-thu",
        "name_with_type": "Phường Nghi Thu",
        "path": "Nghi Thu, Cửa Lò, Nghệ An",
        "path_with_type": "Phường Nghi Thu, Thị xã Cửa Lò, Tỉnh Nghệ An",
        "code": "16735",
        "parent_code": "413"
    },
    "16939": {
        "name": "Hoà Hiếu",
        "type": "phuong",
        "slug": "hoa-hieu",
        "name_with_type": "Phường Hoà Hiếu",
        "path": "Hoà Hiếu, Thái Hoà, Nghệ An",
        "path_with_type": "Phường Hoà Hiếu, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "16939",
        "parent_code": "414"
    },
    "16993": {
        "name": "Quang Phong",
        "type": "phuong",
        "slug": "quang-phong",
        "name_with_type": "Phường Quang Phong",
        "path": "Quang Phong, Thái Hoà, Nghệ An",
        "path_with_type": "Phường Quang Phong, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "16993",
        "parent_code": "414"
    },
    "16994": {
        "name": "Quang Tiến",
        "type": "phuong",
        "slug": "quang-tien",
        "name_with_type": "Phường Quang Tiến",
        "path": "Quang Tiến, Thái Hoà, Nghệ An",
        "path_with_type": "Phường Quang Tiến, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "16994",
        "parent_code": "414"
    },
    "17002": {
        "name": "Nghĩa Hòa",
        "type": "xa",
        "slug": "nghia-hoa",
        "name_with_type": "Xã Nghĩa Hòa",
        "path": "Nghĩa Hòa, Thái Hoà, Nghệ An",
        "path_with_type": "Xã Nghĩa Hòa, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "17002",
        "parent_code": "414"
    },
    "17003": {
        "name": "Long Sơn",
        "type": "phuong",
        "slug": "long-son",
        "name_with_type": "Phường Long Sơn",
        "path": "Long Sơn, Thái Hoà, Nghệ An",
        "path_with_type": "Phường Long Sơn, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "17003",
        "parent_code": "414"
    },
    "17005": {
        "name": "Nghĩa Tiến",
        "type": "xa",
        "slug": "nghia-tien",
        "name_with_type": "Xã Nghĩa Tiến",
        "path": "Nghĩa Tiến, Thái Hoà, Nghệ An",
        "path_with_type": "Xã Nghĩa Tiến, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "17005",
        "parent_code": "414"
    },
    "17008": {
        "name": "Nghĩa Mỹ",
        "type": "xa",
        "slug": "nghia-my",
        "name_with_type": "Xã Nghĩa Mỹ",
        "path": "Nghĩa Mỹ, Thái Hoà, Nghệ An",
        "path_with_type": "Xã Nghĩa Mỹ, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "17008",
        "parent_code": "414"
    },
    "17011": {
        "name": "Tây Hiếu",
        "type": "xa",
        "slug": "tay-hieu",
        "name_with_type": "Xã Tây Hiếu",
        "path": "Tây Hiếu, Thái Hoà, Nghệ An",
        "path_with_type": "Xã Tây Hiếu, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "17011",
        "parent_code": "414"
    },
    "17014": {
        "name": "Nghĩa Thuận",
        "type": "xa",
        "slug": "nghia-thuan",
        "name_with_type": "Xã Nghĩa Thuận",
        "path": "Nghĩa Thuận, Thái Hoà, Nghệ An",
        "path_with_type": "Xã Nghĩa Thuận, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "17014",
        "parent_code": "414"
    },
    "17017": {
        "name": "Đông Hiếu",
        "type": "xa",
        "slug": "dong-hieu",
        "name_with_type": "Xã Đông Hiếu",
        "path": "Đông Hiếu, Thái Hoà, Nghệ An",
        "path_with_type": "Xã Đông Hiếu, Thị xã Thái Hoà, Tỉnh Nghệ An",
        "code": "17017",
        "parent_code": "414"
    },
    "16738": {
        "name": "Kim Sơn",
        "type": "thi-tran",
        "slug": "kim-son",
        "name_with_type": "Thị trấn Kim Sơn",
        "path": "Kim Sơn, Quế Phong, Nghệ An",
        "path_with_type": "Thị trấn Kim Sơn, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16738",
        "parent_code": "415"
    },
    "16741": {
        "name": "Thông Thụ",
        "type": "xa",
        "slug": "thong-thu",
        "name_with_type": "Xã Thông Thụ",
        "path": "Thông Thụ, Quế Phong, Nghệ An",
        "path_with_type": "Xã Thông Thụ, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16741",
        "parent_code": "415"
    },
    "16744": {
        "name": "Đồng Văn",
        "type": "xa",
        "slug": "dong-van",
        "name_with_type": "Xã Đồng Văn",
        "path": "Đồng Văn, Quế Phong, Nghệ An",
        "path_with_type": "Xã Đồng Văn, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16744",
        "parent_code": "415"
    },
    "16747": {
        "name": "Hạnh Dịch",
        "type": "xa",
        "slug": "hanh-dich",
        "name_with_type": "Xã Hạnh Dịch",
        "path": "Hạnh Dịch, Quế Phong, Nghệ An",
        "path_with_type": "Xã Hạnh Dịch, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16747",
        "parent_code": "415"
    },
    "16750": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Quế Phong, Nghệ An",
        "path_with_type": "Xã Tiền Phong, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16750",
        "parent_code": "415"
    },
    "16753": {
        "name": "Nậm Giải",
        "type": "xa",
        "slug": "nam-giai",
        "name_with_type": "Xã Nậm Giải",
        "path": "Nậm Giải, Quế Phong, Nghệ An",
        "path_with_type": "Xã Nậm Giải, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16753",
        "parent_code": "415"
    },
    "16756": {
        "name": "Tri Lễ",
        "type": "xa",
        "slug": "tri-le",
        "name_with_type": "Xã Tri Lễ",
        "path": "Tri Lễ, Quế Phong, Nghệ An",
        "path_with_type": "Xã Tri Lễ, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16756",
        "parent_code": "415"
    },
    "16759": {
        "name": "Châu Kim",
        "type": "xa",
        "slug": "chau-kim",
        "name_with_type": "Xã Châu Kim",
        "path": "Châu Kim, Quế Phong, Nghệ An",
        "path_with_type": "Xã Châu Kim, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16759",
        "parent_code": "415"
    },
    "16762": {
        "name": "Mường Nọc",
        "type": "xa",
        "slug": "muong-noc",
        "name_with_type": "Xã Mường Nọc",
        "path": "Mường Nọc, Quế Phong, Nghệ An",
        "path_with_type": "Xã Mường Nọc, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16762",
        "parent_code": "415"
    },
    "16763": {
        "name": "Quế Sơn",
        "type": "xa",
        "slug": "que-son",
        "name_with_type": "Xã Quế Sơn",
        "path": "Quế Sơn, Quế Phong, Nghệ An",
        "path_with_type": "Xã Quế Sơn, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16763",
        "parent_code": "415"
    },
    "16765": {
        "name": "Châu Thôn",
        "type": "xa",
        "slug": "chau-thon",
        "name_with_type": "Xã Châu Thôn",
        "path": "Châu Thôn, Quế Phong, Nghệ An",
        "path_with_type": "Xã Châu Thôn, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16765",
        "parent_code": "415"
    },
    "16768": {
        "name": "Nậm Nhoóng",
        "type": "xa",
        "slug": "nam-nhoong",
        "name_with_type": "Xã Nậm Nhoóng",
        "path": "Nậm Nhoóng, Quế Phong, Nghệ An",
        "path_with_type": "Xã Nậm Nhoóng, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16768",
        "parent_code": "415"
    },
    "16771": {
        "name": "Quang Phong",
        "type": "xa",
        "slug": "quang-phong",
        "name_with_type": "Xã Quang Phong",
        "path": "Quang Phong, Quế Phong, Nghệ An",
        "path_with_type": "Xã Quang Phong, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16771",
        "parent_code": "415"
    },
    "16774": {
        "name": "Căm Muộn",
        "type": "xa",
        "slug": "cam-muon",
        "name_with_type": "Xã Căm Muộn",
        "path": "Căm Muộn, Quế Phong, Nghệ An",
        "path_with_type": "Xã Căm Muộn, Huyện Quế Phong, Tỉnh Nghệ An",
        "code": "16774",
        "parent_code": "415"
    },
    "16777": {
        "name": "Tân Lạc",
        "type": "thi-tran",
        "slug": "tan-lac",
        "name_with_type": "Thị trấn Tân Lạc",
        "path": "Tân Lạc, Quỳ Châu, Nghệ An",
        "path_with_type": "Thị trấn Tân Lạc, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16777",
        "parent_code": "416"
    },
    "16780": {
        "name": "Châu Bính",
        "type": "xa",
        "slug": "chau-binh",
        "name_with_type": "Xã Châu Bính",
        "path": "Châu Bính, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Bính, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16780",
        "parent_code": "416"
    },
    "16783": {
        "name": "Châu Thuận",
        "type": "xa",
        "slug": "chau-thuan",
        "name_with_type": "Xã Châu Thuận",
        "path": "Châu Thuận, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Thuận, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16783",
        "parent_code": "416"
    },
    "16786": {
        "name": "Châu Hội",
        "type": "xa",
        "slug": "chau-hoi",
        "name_with_type": "Xã Châu Hội",
        "path": "Châu Hội, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Hội, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16786",
        "parent_code": "416"
    },
    "16789": {
        "name": "Châu Nga",
        "type": "xa",
        "slug": "chau-nga",
        "name_with_type": "Xã Châu Nga",
        "path": "Châu Nga, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Nga, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16789",
        "parent_code": "416"
    },
    "16792": {
        "name": "Châu Tiến",
        "type": "xa",
        "slug": "chau-tien",
        "name_with_type": "Xã Châu Tiến",
        "path": "Châu Tiến, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Tiến, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16792",
        "parent_code": "416"
    },
    "16795": {
        "name": "Châu Hạnh",
        "type": "xa",
        "slug": "chau-hanh",
        "name_with_type": "Xã Châu Hạnh",
        "path": "Châu Hạnh, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Hạnh, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16795",
        "parent_code": "416"
    },
    "16798": {
        "name": "Châu Thắng",
        "type": "xa",
        "slug": "chau-thang",
        "name_with_type": "Xã Châu Thắng",
        "path": "Châu Thắng, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Thắng, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16798",
        "parent_code": "416"
    },
    "16801": {
        "name": "Châu Phong",
        "type": "xa",
        "slug": "chau-phong",
        "name_with_type": "Xã Châu Phong",
        "path": "Châu Phong, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Phong, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16801",
        "parent_code": "416"
    },
    "16804": {
        "name": "Châu Bình",
        "type": "xa",
        "slug": "chau-binh",
        "name_with_type": "Xã Châu Bình",
        "path": "Châu Bình, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Bình, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16804",
        "parent_code": "416"
    },
    "16807": {
        "name": "Châu Hoàn",
        "type": "xa",
        "slug": "chau-hoan",
        "name_with_type": "Xã Châu Hoàn",
        "path": "Châu Hoàn, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Châu Hoàn, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16807",
        "parent_code": "416"
    },
    "16810": {
        "name": "Diên Lãm",
        "type": "xa",
        "slug": "dien-lam",
        "name_with_type": "Xã Diên Lãm",
        "path": "Diên Lãm, Quỳ Châu, Nghệ An",
        "path_with_type": "Xã Diên Lãm, Huyện Quỳ Châu, Tỉnh Nghệ An",
        "code": "16810",
        "parent_code": "416"
    },
    "16813": {
        "name": "Mường Xén",
        "type": "thi-tran",
        "slug": "muong-xen",
        "name_with_type": "Thị trấn Mường Xén",
        "path": "Mường Xén, Kỳ Sơn, Nghệ An",
        "path_with_type": "Thị trấn Mường Xén, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16813",
        "parent_code": "417"
    },
    "16816": {
        "name": "Mỹ Lý",
        "type": "xa",
        "slug": "my-ly",
        "name_with_type": "Xã Mỹ Lý",
        "path": "Mỹ Lý, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Mỹ Lý, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16816",
        "parent_code": "417"
    },
    "16819": {
        "name": "Bắc Lý",
        "type": "xa",
        "slug": "bac-ly",
        "name_with_type": "Xã Bắc Lý",
        "path": "Bắc Lý, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Bắc Lý, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16819",
        "parent_code": "417"
    },
    "16822": {
        "name": "Keng Đu",
        "type": "xa",
        "slug": "keng-du",
        "name_with_type": "Xã Keng Đu",
        "path": "Keng Đu, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Keng Đu, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16822",
        "parent_code": "417"
    },
    "16825": {
        "name": "Đoọc Mạy",
        "type": "xa",
        "slug": "dooc-may",
        "name_with_type": "Xã Đoọc Mạy",
        "path": "Đoọc Mạy, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Đoọc Mạy, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16825",
        "parent_code": "417"
    },
    "16828": {
        "name": "Huồi Tụ",
        "type": "xa",
        "slug": "huoi-tu",
        "name_with_type": "Xã Huồi Tụ",
        "path": "Huồi Tụ, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Huồi Tụ, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16828",
        "parent_code": "417"
    },
    "16831": {
        "name": "Mường Lống",
        "type": "xa",
        "slug": "muong-long",
        "name_with_type": "Xã Mường Lống",
        "path": "Mường Lống, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Mường Lống, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16831",
        "parent_code": "417"
    },
    "16834": {
        "name": "Na Loi",
        "type": "xa",
        "slug": "na-loi",
        "name_with_type": "Xã Na Loi",
        "path": "Na Loi, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Na Loi, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16834",
        "parent_code": "417"
    },
    "16837": {
        "name": "Nậm Cắn",
        "type": "xa",
        "slug": "nam-can",
        "name_with_type": "Xã Nậm Cắn",
        "path": "Nậm Cắn, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Nậm Cắn, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16837",
        "parent_code": "417"
    },
    "16840": {
        "name": "Bảo Nam",
        "type": "xa",
        "slug": "bao-nam",
        "name_with_type": "Xã Bảo Nam",
        "path": "Bảo Nam, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Bảo Nam, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16840",
        "parent_code": "417"
    },
    "16843": {
        "name": "Phà Đánh",
        "type": "xa",
        "slug": "pha-danh",
        "name_with_type": "Xã Phà Đánh",
        "path": "Phà Đánh, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Phà Đánh, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16843",
        "parent_code": "417"
    },
    "16846": {
        "name": "Bảo Thắng",
        "type": "xa",
        "slug": "bao-thang",
        "name_with_type": "Xã Bảo Thắng",
        "path": "Bảo Thắng, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Bảo Thắng, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16846",
        "parent_code": "417"
    },
    "16849": {
        "name": "Hữu Lập",
        "type": "xa",
        "slug": "huu-lap",
        "name_with_type": "Xã Hữu Lập",
        "path": "Hữu Lập, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Hữu Lập, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16849",
        "parent_code": "417"
    },
    "16852": {
        "name": "Tà Cạ",
        "type": "xa",
        "slug": "ta-ca",
        "name_with_type": "Xã Tà Cạ",
        "path": "Tà Cạ, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Tà Cạ, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16852",
        "parent_code": "417"
    },
    "16855": {
        "name": "Chiêu Lưu",
        "type": "xa",
        "slug": "chieu-luu",
        "name_with_type": "Xã Chiêu Lưu",
        "path": "Chiêu Lưu, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Chiêu Lưu, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16855",
        "parent_code": "417"
    },
    "16858": {
        "name": "Mường Típ",
        "type": "xa",
        "slug": "muong-tip",
        "name_with_type": "Xã Mường Típ",
        "path": "Mường Típ, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Mường Típ, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16858",
        "parent_code": "417"
    },
    "16861": {
        "name": "Hữu Kiệm",
        "type": "xa",
        "slug": "huu-kiem",
        "name_with_type": "Xã Hữu Kiệm",
        "path": "Hữu Kiệm, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Hữu Kiệm, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16861",
        "parent_code": "417"
    },
    "16864": {
        "name": "Tây Sơn",
        "type": "xa",
        "slug": "tay-son",
        "name_with_type": "Xã Tây Sơn",
        "path": "Tây Sơn, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Tây Sơn, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16864",
        "parent_code": "417"
    },
    "16867": {
        "name": "Mường Ải",
        "type": "xa",
        "slug": "muong-ai",
        "name_with_type": "Xã Mường Ải",
        "path": "Mường Ải, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Mường Ải, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16867",
        "parent_code": "417"
    },
    "16870": {
        "name": "Na Ngoi",
        "type": "xa",
        "slug": "na-ngoi",
        "name_with_type": "Xã Na Ngoi",
        "path": "Na Ngoi, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Na Ngoi, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16870",
        "parent_code": "417"
    },
    "16873": {
        "name": "Nậm Càn",
        "type": "xa",
        "slug": "nam-can",
        "name_with_type": "Xã Nậm Càn",
        "path": "Nậm Càn, Kỳ Sơn, Nghệ An",
        "path_with_type": "Xã Nậm Càn, Huyện Kỳ Sơn, Tỉnh Nghệ An",
        "code": "16873",
        "parent_code": "417"
    },
    "16876": {
        "name": "Hòa Bình",
        "type": "thi-tran",
        "slug": "hoa-binh",
        "name_with_type": "Thị trấn Hòa Bình",
        "path": "Hòa Bình, Tương Dương, Nghệ An",
        "path_with_type": "Thị trấn Hòa Bình, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16876",
        "parent_code": "418"
    },
    "16879": {
        "name": "Mai Sơn",
        "type": "xa",
        "slug": "mai-son",
        "name_with_type": "Xã Mai Sơn",
        "path": "Mai Sơn, Tương Dương, Nghệ An",
        "path_with_type": "Xã Mai Sơn, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16879",
        "parent_code": "418"
    },
    "16882": {
        "name": "Nhôn Mai",
        "type": "xa",
        "slug": "nhon-mai",
        "name_with_type": "Xã Nhôn Mai",
        "path": "Nhôn Mai, Tương Dương, Nghệ An",
        "path_with_type": "Xã Nhôn Mai, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16882",
        "parent_code": "418"
    },
    "16885": {
        "name": "Hữu Khuông",
        "type": "xa",
        "slug": "huu-khuong",
        "name_with_type": "Xã Hữu Khuông",
        "path": "Hữu Khuông, Tương Dương, Nghệ An",
        "path_with_type": "Xã Hữu Khuông, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16885",
        "parent_code": "418"
    },
    "16900": {
        "name": "Yên Tĩnh",
        "type": "xa",
        "slug": "yen-tinh",
        "name_with_type": "Xã Yên Tĩnh",
        "path": "Yên Tĩnh, Tương Dương, Nghệ An",
        "path_with_type": "Xã Yên Tĩnh, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16900",
        "parent_code": "418"
    },
    "16903": {
        "name": "Nga My",
        "type": "xa",
        "slug": "nga-my",
        "name_with_type": "Xã Nga My",
        "path": "Nga My, Tương Dương, Nghệ An",
        "path_with_type": "Xã Nga My, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16903",
        "parent_code": "418"
    },
    "16904": {
        "name": "Xiêng My",
        "type": "xa",
        "slug": "xieng-my",
        "name_with_type": "Xã Xiêng My",
        "path": "Xiêng My, Tương Dương, Nghệ An",
        "path_with_type": "Xã Xiêng My, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16904",
        "parent_code": "418"
    },
    "16906": {
        "name": "Lưỡng Minh",
        "type": "xa",
        "slug": "luong-minh",
        "name_with_type": "Xã Lưỡng Minh",
        "path": "Lưỡng Minh, Tương Dương, Nghệ An",
        "path_with_type": "Xã Lưỡng Minh, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16906",
        "parent_code": "418"
    },
    "16909": {
        "name": "Yên Hòa",
        "type": "xa",
        "slug": "yen-hoa",
        "name_with_type": "Xã Yên Hòa",
        "path": "Yên Hòa, Tương Dương, Nghệ An",
        "path_with_type": "Xã Yên Hòa, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16909",
        "parent_code": "418"
    },
    "16912": {
        "name": "Yên Na",
        "type": "xa",
        "slug": "yen-na",
        "name_with_type": "Xã Yên Na",
        "path": "Yên Na, Tương Dương, Nghệ An",
        "path_with_type": "Xã Yên Na, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16912",
        "parent_code": "418"
    },
    "16915": {
        "name": "Lưu Kiền",
        "type": "xa",
        "slug": "luu-kien",
        "name_with_type": "Xã Lưu Kiền",
        "path": "Lưu Kiền, Tương Dương, Nghệ An",
        "path_with_type": "Xã Lưu Kiền, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16915",
        "parent_code": "418"
    },
    "16918": {
        "name": "Thạch Giám",
        "type": "xa",
        "slug": "thach-giam",
        "name_with_type": "Xã Thạch Giám",
        "path": "Thạch Giám, Tương Dương, Nghệ An",
        "path_with_type": "Xã Thạch Giám, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16918",
        "parent_code": "418"
    },
    "16921": {
        "name": "Xá Lượng",
        "type": "xa",
        "slug": "xa-luong",
        "name_with_type": "Xã Xá Lượng",
        "path": "Xá Lượng, Tương Dương, Nghệ An",
        "path_with_type": "Xã Xá Lượng, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16921",
        "parent_code": "418"
    },
    "16924": {
        "name": "Tam Thái",
        "type": "xa",
        "slug": "tam-thai",
        "name_with_type": "Xã Tam Thái",
        "path": "Tam Thái, Tương Dương, Nghệ An",
        "path_with_type": "Xã Tam Thái, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16924",
        "parent_code": "418"
    },
    "16927": {
        "name": "Tam Đình",
        "type": "xa",
        "slug": "tam-dinh",
        "name_with_type": "Xã Tam Đình",
        "path": "Tam Đình, Tương Dương, Nghệ An",
        "path_with_type": "Xã Tam Đình, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16927",
        "parent_code": "418"
    },
    "16930": {
        "name": "Yên Thắng",
        "type": "xa",
        "slug": "yen-thang",
        "name_with_type": "Xã Yên Thắng",
        "path": "Yên Thắng, Tương Dương, Nghệ An",
        "path_with_type": "Xã Yên Thắng, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16930",
        "parent_code": "418"
    },
    "16933": {
        "name": "Tam Quang",
        "type": "xa",
        "slug": "tam-quang",
        "name_with_type": "Xã Tam Quang",
        "path": "Tam Quang, Tương Dương, Nghệ An",
        "path_with_type": "Xã Tam Quang, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16933",
        "parent_code": "418"
    },
    "16936": {
        "name": "Tam Hợp",
        "type": "xa",
        "slug": "tam-hop",
        "name_with_type": "Xã Tam Hợp",
        "path": "Tam Hợp, Tương Dương, Nghệ An",
        "path_with_type": "Xã Tam Hợp, Huyện Tương Dương, Tỉnh Nghệ An",
        "code": "16936",
        "parent_code": "418"
    },
    "16941": {
        "name": "Nghĩa Đàn",
        "type": "thi-tran",
        "slug": "nghia-dan",
        "name_with_type": "Thị trấn Nghĩa Đàn",
        "path": "Nghĩa Đàn, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Thị trấn Nghĩa Đàn, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16941",
        "parent_code": "419"
    },
    "16942": {
        "name": "Nghĩa Mai",
        "type": "xa",
        "slug": "nghia-mai",
        "name_with_type": "Xã Nghĩa Mai",
        "path": "Nghĩa Mai, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Mai, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16942",
        "parent_code": "419"
    },
    "16945": {
        "name": "Nghĩa Yên",
        "type": "xa",
        "slug": "nghia-yen",
        "name_with_type": "Xã Nghĩa Yên",
        "path": "Nghĩa Yên, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Yên, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16945",
        "parent_code": "419"
    },
    "16948": {
        "name": "Nghĩa Lạc",
        "type": "xa",
        "slug": "nghia-lac",
        "name_with_type": "Xã Nghĩa Lạc",
        "path": "Nghĩa Lạc, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Lạc, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16948",
        "parent_code": "419"
    },
    "16951": {
        "name": "Nghĩa Lâm",
        "type": "xa",
        "slug": "nghia-lam",
        "name_with_type": "Xã Nghĩa Lâm",
        "path": "Nghĩa Lâm, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Lâm, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16951",
        "parent_code": "419"
    },
    "16954": {
        "name": "Nghĩa Sơn",
        "type": "xa",
        "slug": "nghia-son",
        "name_with_type": "Xã Nghĩa Sơn",
        "path": "Nghĩa Sơn, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Sơn, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16954",
        "parent_code": "419"
    },
    "16957": {
        "name": "Nghĩa Lợi",
        "type": "xa",
        "slug": "nghia-loi",
        "name_with_type": "Xã Nghĩa Lợi",
        "path": "Nghĩa Lợi, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Lợi, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16957",
        "parent_code": "419"
    },
    "16960": {
        "name": "Nghĩa Bình",
        "type": "xa",
        "slug": "nghia-binh",
        "name_with_type": "Xã Nghĩa Bình",
        "path": "Nghĩa Bình, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Bình, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16960",
        "parent_code": "419"
    },
    "16963": {
        "name": "Nghĩa Thọ",
        "type": "xa",
        "slug": "nghia-tho",
        "name_with_type": "Xã Nghĩa Thọ",
        "path": "Nghĩa Thọ, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Thọ, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16963",
        "parent_code": "419"
    },
    "16966": {
        "name": "Nghĩa Minh",
        "type": "xa",
        "slug": "nghia-minh",
        "name_with_type": "Xã Nghĩa Minh",
        "path": "Nghĩa Minh, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Minh, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16966",
        "parent_code": "419"
    },
    "16969": {
        "name": "Nghĩa Phú",
        "type": "xa",
        "slug": "nghia-phu",
        "name_with_type": "Xã Nghĩa Phú",
        "path": "Nghĩa Phú, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Phú, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16969",
        "parent_code": "419"
    },
    "16972": {
        "name": "Nghĩa Hưng",
        "type": "xa",
        "slug": "nghia-hung",
        "name_with_type": "Xã Nghĩa Hưng",
        "path": "Nghĩa Hưng, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Hưng, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16972",
        "parent_code": "419"
    },
    "16975": {
        "name": "Nghĩa Hồng",
        "type": "xa",
        "slug": "nghia-hong",
        "name_with_type": "Xã Nghĩa Hồng",
        "path": "Nghĩa Hồng, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Hồng, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16975",
        "parent_code": "419"
    },
    "16978": {
        "name": "Nghĩa Thịnh",
        "type": "xa",
        "slug": "nghia-thinh",
        "name_with_type": "Xã Nghĩa Thịnh",
        "path": "Nghĩa Thịnh, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Thịnh, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16978",
        "parent_code": "419"
    },
    "16981": {
        "name": "Nghĩa Trung",
        "type": "xa",
        "slug": "nghia-trung",
        "name_with_type": "Xã Nghĩa Trung",
        "path": "Nghĩa Trung, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Trung, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16981",
        "parent_code": "419"
    },
    "16984": {
        "name": "Nghĩa Hội",
        "type": "xa",
        "slug": "nghia-hoi",
        "name_with_type": "Xã Nghĩa Hội",
        "path": "Nghĩa Hội, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Hội, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16984",
        "parent_code": "419"
    },
    "16987": {
        "name": "Nghĩa Tân",
        "type": "xa",
        "slug": "nghia-tan",
        "name_with_type": "Xã Nghĩa Tân",
        "path": "Nghĩa Tân, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Tân, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16987",
        "parent_code": "419"
    },
    "16990": {
        "name": "Nghĩa Thắng",
        "type": "xa",
        "slug": "nghia-thang",
        "name_with_type": "Xã Nghĩa Thắng",
        "path": "Nghĩa Thắng, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Thắng, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16990",
        "parent_code": "419"
    },
    "16996": {
        "name": "Nghĩa Hiếu",
        "type": "xa",
        "slug": "nghia-hieu",
        "name_with_type": "Xã Nghĩa Hiếu",
        "path": "Nghĩa Hiếu, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Hiếu, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16996",
        "parent_code": "419"
    },
    "16999": {
        "name": "Nghĩa Liên",
        "type": "xa",
        "slug": "nghia-lien",
        "name_with_type": "Xã Nghĩa Liên",
        "path": "Nghĩa Liên, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Liên, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "16999",
        "parent_code": "419"
    },
    "17020": {
        "name": "Nghĩa Đức",
        "type": "xa",
        "slug": "nghia-duc",
        "name_with_type": "Xã Nghĩa Đức",
        "path": "Nghĩa Đức, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Đức, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "17020",
        "parent_code": "419"
    },
    "17023": {
        "name": "Nghĩa An",
        "type": "xa",
        "slug": "nghia-an",
        "name_with_type": "Xã Nghĩa An",
        "path": "Nghĩa An, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa An, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "17023",
        "parent_code": "419"
    },
    "17026": {
        "name": "Nghĩa Long",
        "type": "xa",
        "slug": "nghia-long",
        "name_with_type": "Xã Nghĩa Long",
        "path": "Nghĩa Long, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Long, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "17026",
        "parent_code": "419"
    },
    "17029": {
        "name": "Nghĩa Lộc",
        "type": "xa",
        "slug": "nghia-loc",
        "name_with_type": "Xã Nghĩa Lộc",
        "path": "Nghĩa Lộc, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Lộc, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "17029",
        "parent_code": "419"
    },
    "17032": {
        "name": "Nghĩa Khánh",
        "type": "xa",
        "slug": "nghia-khanh",
        "name_with_type": "Xã Nghĩa Khánh",
        "path": "Nghĩa Khánh, Nghĩa Đàn, Nghệ An",
        "path_with_type": "Xã Nghĩa Khánh, Huyện Nghĩa Đàn, Tỉnh Nghệ An",
        "code": "17032",
        "parent_code": "419"
    },
    "17035": {
        "name": "Quỳ Hợp",
        "type": "thi-tran",
        "slug": "quy-hop",
        "name_with_type": "Thị trấn Quỳ Hợp",
        "path": "Quỳ Hợp, Quỳ Hợp, Nghệ An",
        "path_with_type": "Thị trấn Quỳ Hợp, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17035",
        "parent_code": "420"
    },
    "17038": {
        "name": "Yên Hợp",
        "type": "xa",
        "slug": "yen-hop",
        "name_with_type": "Xã Yên Hợp",
        "path": "Yên Hợp, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Yên Hợp, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17038",
        "parent_code": "420"
    },
    "17041": {
        "name": "Châu Tiến",
        "type": "xa",
        "slug": "chau-tien",
        "name_with_type": "Xã Châu Tiến",
        "path": "Châu Tiến, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Châu Tiến, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17041",
        "parent_code": "420"
    },
    "17044": {
        "name": "Châu Hồng",
        "type": "xa",
        "slug": "chau-hong",
        "name_with_type": "Xã Châu Hồng",
        "path": "Châu Hồng, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Châu Hồng, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17044",
        "parent_code": "420"
    },
    "17047": {
        "name": "Đồng Hợp",
        "type": "xa",
        "slug": "dong-hop",
        "name_with_type": "Xã Đồng Hợp",
        "path": "Đồng Hợp, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Đồng Hợp, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17047",
        "parent_code": "420"
    },
    "17050": {
        "name": "Châu Thành",
        "type": "xa",
        "slug": "chau-thanh",
        "name_with_type": "Xã Châu Thành",
        "path": "Châu Thành, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Châu Thành, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17050",
        "parent_code": "420"
    },
    "17053": {
        "name": "Liên Hợp",
        "type": "xa",
        "slug": "lien-hop",
        "name_with_type": "Xã Liên Hợp",
        "path": "Liên Hợp, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Liên Hợp, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17053",
        "parent_code": "420"
    },
    "17056": {
        "name": "Châu Lộc",
        "type": "xa",
        "slug": "chau-loc",
        "name_with_type": "Xã Châu Lộc",
        "path": "Châu Lộc, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Châu Lộc, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17056",
        "parent_code": "420"
    },
    "17059": {
        "name": "Tam Hợp",
        "type": "xa",
        "slug": "tam-hop",
        "name_with_type": "Xã Tam Hợp",
        "path": "Tam Hợp, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Tam Hợp, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17059",
        "parent_code": "420"
    },
    "17062": {
        "name": "Châu Cường",
        "type": "xa",
        "slug": "chau-cuong",
        "name_with_type": "Xã Châu Cường",
        "path": "Châu Cường, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Châu Cường, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17062",
        "parent_code": "420"
    },
    "17065": {
        "name": "Châu Quang",
        "type": "xa",
        "slug": "chau-quang",
        "name_with_type": "Xã Châu Quang",
        "path": "Châu Quang, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Châu Quang, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17065",
        "parent_code": "420"
    },
    "17068": {
        "name": "Thọ Hợp",
        "type": "xa",
        "slug": "tho-hop",
        "name_with_type": "Xã Thọ Hợp",
        "path": "Thọ Hợp, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Thọ Hợp, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17068",
        "parent_code": "420"
    },
    "17071": {
        "name": "Minh Hợp",
        "type": "xa",
        "slug": "minh-hop",
        "name_with_type": "Xã Minh Hợp",
        "path": "Minh Hợp, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Minh Hợp, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17071",
        "parent_code": "420"
    },
    "17074": {
        "name": "Nghĩa Xuân",
        "type": "xa",
        "slug": "nghia-xuan",
        "name_with_type": "Xã Nghĩa Xuân",
        "path": "Nghĩa Xuân, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Nghĩa Xuân, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17074",
        "parent_code": "420"
    },
    "17077": {
        "name": "Châu Thái",
        "type": "xa",
        "slug": "chau-thai",
        "name_with_type": "Xã Châu Thái",
        "path": "Châu Thái, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Châu Thái, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17077",
        "parent_code": "420"
    },
    "17080": {
        "name": "Châu Đình",
        "type": "xa",
        "slug": "chau-dinh",
        "name_with_type": "Xã Châu Đình",
        "path": "Châu Đình, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Châu Đình, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17080",
        "parent_code": "420"
    },
    "17083": {
        "name": "Văn Lợi",
        "type": "xa",
        "slug": "van-loi",
        "name_with_type": "Xã Văn Lợi",
        "path": "Văn Lợi, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Văn Lợi, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17083",
        "parent_code": "420"
    },
    "17086": {
        "name": "Nam Sơn",
        "type": "xa",
        "slug": "nam-son",
        "name_with_type": "Xã Nam Sơn",
        "path": "Nam Sơn, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Nam Sơn, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17086",
        "parent_code": "420"
    },
    "17089": {
        "name": "Châu Lý",
        "type": "xa",
        "slug": "chau-ly",
        "name_with_type": "Xã Châu Lý",
        "path": "Châu Lý, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Châu Lý, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17089",
        "parent_code": "420"
    },
    "17092": {
        "name": "Hạ Sơn",
        "type": "xa",
        "slug": "ha-son",
        "name_with_type": "Xã Hạ Sơn",
        "path": "Hạ Sơn, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Hạ Sơn, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17092",
        "parent_code": "420"
    },
    "17095": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Quỳ Hợp, Nghệ An",
        "path_with_type": "Xã Bắc Sơn, Huyện Quỳ Hợp, Tỉnh Nghệ An",
        "code": "17095",
        "parent_code": "420"
    },
    "17098": {
        "name": "Cầu Giát",
        "type": "thi-tran",
        "slug": "cau-giat",
        "name_with_type": "Thị trấn Cầu Giát",
        "path": "Cầu Giát, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Thị trấn Cầu Giát, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17098",
        "parent_code": "421"
    },
    "17101": {
        "name": "Quỳnh Thắng",
        "type": "xa",
        "slug": "quynh-thang",
        "name_with_type": "Xã Quỳnh Thắng",
        "path": "Quỳnh Thắng, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Thắng, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17101",
        "parent_code": "421"
    },
    "17119": {
        "name": "Quỳnh Tân",
        "type": "xa",
        "slug": "quynh-tan",
        "name_with_type": "Xã Quỳnh Tân",
        "path": "Quỳnh Tân, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Tân, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17119",
        "parent_code": "421"
    },
    "17122": {
        "name": "Quỳnh Châu",
        "type": "xa",
        "slug": "quynh-chau",
        "name_with_type": "Xã Quỳnh Châu",
        "path": "Quỳnh Châu, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Châu, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17122",
        "parent_code": "421"
    },
    "17140": {
        "name": "Tân Sơn",
        "type": "xa",
        "slug": "tan-son",
        "name_with_type": "Xã Tân Sơn",
        "path": "Tân Sơn, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Tân Sơn, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17140",
        "parent_code": "421"
    },
    "17143": {
        "name": "Quỳnh Văn",
        "type": "xa",
        "slug": "quynh-van",
        "name_with_type": "Xã Quỳnh Văn",
        "path": "Quỳnh Văn, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Văn, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17143",
        "parent_code": "421"
    },
    "17146": {
        "name": "Ngọc Sơn",
        "type": "xa",
        "slug": "ngoc-son",
        "name_with_type": "Xã Ngọc Sơn",
        "path": "Ngọc Sơn, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Ngọc Sơn, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17146",
        "parent_code": "421"
    },
    "17149": {
        "name": "Quỳnh Tam",
        "type": "xa",
        "slug": "quynh-tam",
        "name_with_type": "Xã Quỳnh Tam",
        "path": "Quỳnh Tam, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Tam, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17149",
        "parent_code": "421"
    },
    "17152": {
        "name": "Quỳnh Hoa",
        "type": "xa",
        "slug": "quynh-hoa",
        "name_with_type": "Xã Quỳnh Hoa",
        "path": "Quỳnh Hoa, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Hoa, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17152",
        "parent_code": "421"
    },
    "17155": {
        "name": "Quỳnh Thạch",
        "type": "xa",
        "slug": "quynh-thach",
        "name_with_type": "Xã Quỳnh Thạch",
        "path": "Quỳnh Thạch, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Thạch, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17155",
        "parent_code": "421"
    },
    "17158": {
        "name": "Quỳnh Bảng",
        "type": "xa",
        "slug": "quynh-bang",
        "name_with_type": "Xã Quỳnh Bảng",
        "path": "Quỳnh Bảng, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Bảng, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17158",
        "parent_code": "421"
    },
    "17161": {
        "name": "Quỳnh Mỹ",
        "type": "xa",
        "slug": "quynh-my",
        "name_with_type": "Xã Quỳnh Mỹ",
        "path": "Quỳnh Mỹ, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Mỹ, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17161",
        "parent_code": "421"
    },
    "17164": {
        "name": "Quỳnh Thanh",
        "type": "xa",
        "slug": "quynh-thanh",
        "name_with_type": "Xã Quỳnh Thanh",
        "path": "Quỳnh Thanh, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Thanh, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17164",
        "parent_code": "421"
    },
    "17167": {
        "name": "Quỳnh Hậu",
        "type": "xa",
        "slug": "quynh-hau",
        "name_with_type": "Xã Quỳnh Hậu",
        "path": "Quỳnh Hậu, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Hậu, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17167",
        "parent_code": "421"
    },
    "17170": {
        "name": "Quỳnh Lâm",
        "type": "xa",
        "slug": "quynh-lam",
        "name_with_type": "Xã Quỳnh Lâm",
        "path": "Quỳnh Lâm, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Lâm, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17170",
        "parent_code": "421"
    },
    "17173": {
        "name": "Quỳnh Đôi",
        "type": "xa",
        "slug": "quynh-doi",
        "name_with_type": "Xã Quỳnh Đôi",
        "path": "Quỳnh Đôi, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Đôi, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17173",
        "parent_code": "421"
    },
    "17176": {
        "name": "Quỳnh Lương",
        "type": "xa",
        "slug": "quynh-luong",
        "name_with_type": "Xã Quỳnh Lương",
        "path": "Quỳnh Lương, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Lương, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17176",
        "parent_code": "421"
    },
    "17179": {
        "name": "Quỳnh Hồng",
        "type": "xa",
        "slug": "quynh-hong",
        "name_with_type": "Xã Quỳnh Hồng",
        "path": "Quỳnh Hồng, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Hồng, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17179",
        "parent_code": "421"
    },
    "17182": {
        "name": "Quỳnh Yên",
        "type": "xa",
        "slug": "quynh-yen",
        "name_with_type": "Xã Quỳnh Yên",
        "path": "Quỳnh Yên, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Yên, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17182",
        "parent_code": "421"
    },
    "17185": {
        "name": "Quỳnh Bá",
        "type": "xa",
        "slug": "quynh-ba",
        "name_with_type": "Xã Quỳnh Bá",
        "path": "Quỳnh Bá, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Bá, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17185",
        "parent_code": "421"
    },
    "17188": {
        "name": "Quỳnh Minh",
        "type": "xa",
        "slug": "quynh-minh",
        "name_with_type": "Xã Quỳnh Minh",
        "path": "Quỳnh Minh, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Minh, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17188",
        "parent_code": "421"
    },
    "17191": {
        "name": "Quỳnh Diện",
        "type": "xa",
        "slug": "quynh-dien",
        "name_with_type": "Xã Quỳnh Diện",
        "path": "Quỳnh Diện, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Diện, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17191",
        "parent_code": "421"
    },
    "17194": {
        "name": "Quỳnh Hưng",
        "type": "xa",
        "slug": "quynh-hung",
        "name_with_type": "Xã Quỳnh Hưng",
        "path": "Quỳnh Hưng, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Hưng, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17194",
        "parent_code": "421"
    },
    "17197": {
        "name": "Quỳnh Giang",
        "type": "xa",
        "slug": "quynh-giang",
        "name_with_type": "Xã Quỳnh Giang",
        "path": "Quỳnh Giang, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Giang, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17197",
        "parent_code": "421"
    },
    "17200": {
        "name": "Quỳnh Ngọc",
        "type": "xa",
        "slug": "quynh-ngoc",
        "name_with_type": "Xã Quỳnh Ngọc",
        "path": "Quỳnh Ngọc, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Ngọc, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17200",
        "parent_code": "421"
    },
    "17203": {
        "name": "Quỳnh Nghĩa",
        "type": "xa",
        "slug": "quynh-nghia",
        "name_with_type": "Xã Quỳnh Nghĩa",
        "path": "Quỳnh Nghĩa, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Nghĩa, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17203",
        "parent_code": "421"
    },
    "17206": {
        "name": "An Hòa",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hòa",
        "path": "An Hòa, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã An Hòa, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17206",
        "parent_code": "421"
    },
    "17209": {
        "name": "Tiến Thủy",
        "type": "xa",
        "slug": "tien-thuy",
        "name_with_type": "Xã Tiến Thủy",
        "path": "Tiến Thủy, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Tiến Thủy, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17209",
        "parent_code": "421"
    },
    "17212": {
        "name": "Sơn Hải",
        "type": "xa",
        "slug": "son-hai",
        "name_with_type": "Xã Sơn Hải",
        "path": "Sơn Hải, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Sơn Hải, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17212",
        "parent_code": "421"
    },
    "17215": {
        "name": "Quỳnh Thọ",
        "type": "xa",
        "slug": "quynh-tho",
        "name_with_type": "Xã Quỳnh Thọ",
        "path": "Quỳnh Thọ, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Thọ, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17215",
        "parent_code": "421"
    },
    "17218": {
        "name": "Quỳnh Thuận",
        "type": "xa",
        "slug": "quynh-thuan",
        "name_with_type": "Xã Quỳnh Thuận",
        "path": "Quỳnh Thuận, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Thuận, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17218",
        "parent_code": "421"
    },
    "17221": {
        "name": "Quỳnh Long",
        "type": "xa",
        "slug": "quynh-long",
        "name_with_type": "Xã Quỳnh Long",
        "path": "Quỳnh Long, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Quỳnh Long, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17221",
        "parent_code": "421"
    },
    "17224": {
        "name": "Tân Thắng",
        "type": "xa",
        "slug": "tan-thang",
        "name_with_type": "Xã Tân Thắng",
        "path": "Tân Thắng, Quỳnh Lưu, Nghệ An",
        "path_with_type": "Xã Tân Thắng, Huyện Quỳnh Lưu, Tỉnh Nghệ An",
        "code": "17224",
        "parent_code": "421"
    },
    "17227": {
        "name": "Con Cuông",
        "type": "thi-tran",
        "slug": "con-cuong",
        "name_with_type": "Thị trấn Con Cuông",
        "path": "Con Cuông, Con Cuông, Nghệ An",
        "path_with_type": "Thị trấn Con Cuông, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17227",
        "parent_code": "422"
    },
    "17230": {
        "name": "Bình Chuẩn",
        "type": "xa",
        "slug": "binh-chuan",
        "name_with_type": "Xã Bình Chuẩn",
        "path": "Bình Chuẩn, Con Cuông, Nghệ An",
        "path_with_type": "Xã Bình Chuẩn, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17230",
        "parent_code": "422"
    },
    "17233": {
        "name": "Lạng Khê",
        "type": "xa",
        "slug": "lang-khe",
        "name_with_type": "Xã Lạng Khê",
        "path": "Lạng Khê, Con Cuông, Nghệ An",
        "path_with_type": "Xã Lạng Khê, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17233",
        "parent_code": "422"
    },
    "17236": {
        "name": "Cam Lâm",
        "type": "xa",
        "slug": "cam-lam",
        "name_with_type": "Xã Cam Lâm",
        "path": "Cam Lâm, Con Cuông, Nghệ An",
        "path_with_type": "Xã Cam Lâm, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17236",
        "parent_code": "422"
    },
    "17239": {
        "name": "Thạch Ngàn",
        "type": "xa",
        "slug": "thach-ngan",
        "name_with_type": "Xã Thạch Ngàn",
        "path": "Thạch Ngàn, Con Cuông, Nghệ An",
        "path_with_type": "Xã Thạch Ngàn, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17239",
        "parent_code": "422"
    },
    "17242": {
        "name": "Đôn Phục",
        "type": "xa",
        "slug": "don-phuc",
        "name_with_type": "Xã Đôn Phục",
        "path": "Đôn Phục, Con Cuông, Nghệ An",
        "path_with_type": "Xã Đôn Phục, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17242",
        "parent_code": "422"
    },
    "17245": {
        "name": "Mậu Đức",
        "type": "xa",
        "slug": "mau-duc",
        "name_with_type": "Xã Mậu Đức",
        "path": "Mậu Đức, Con Cuông, Nghệ An",
        "path_with_type": "Xã Mậu Đức, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17245",
        "parent_code": "422"
    },
    "17248": {
        "name": "Châu Khê",
        "type": "xa",
        "slug": "chau-khe",
        "name_with_type": "Xã Châu Khê",
        "path": "Châu Khê, Con Cuông, Nghệ An",
        "path_with_type": "Xã Châu Khê, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17248",
        "parent_code": "422"
    },
    "17251": {
        "name": "Chi Khê",
        "type": "xa",
        "slug": "chi-khe",
        "name_with_type": "Xã Chi Khê",
        "path": "Chi Khê, Con Cuông, Nghệ An",
        "path_with_type": "Xã Chi Khê, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17251",
        "parent_code": "422"
    },
    "17254": {
        "name": "Bồng Khê",
        "type": "xa",
        "slug": "bong-khe",
        "name_with_type": "Xã Bồng Khê",
        "path": "Bồng Khê, Con Cuông, Nghệ An",
        "path_with_type": "Xã Bồng Khê, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17254",
        "parent_code": "422"
    },
    "17257": {
        "name": "Yên Khê",
        "type": "xa",
        "slug": "yen-khe",
        "name_with_type": "Xã Yên Khê",
        "path": "Yên Khê, Con Cuông, Nghệ An",
        "path_with_type": "Xã Yên Khê, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17257",
        "parent_code": "422"
    },
    "17260": {
        "name": "Lục Dạ",
        "type": "xa",
        "slug": "luc-da",
        "name_with_type": "Xã Lục Dạ",
        "path": "Lục Dạ, Con Cuông, Nghệ An",
        "path_with_type": "Xã Lục Dạ, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17260",
        "parent_code": "422"
    },
    "17263": {
        "name": "Môn Sơn",
        "type": "xa",
        "slug": "mon-son",
        "name_with_type": "Xã Môn Sơn",
        "path": "Môn Sơn, Con Cuông, Nghệ An",
        "path_with_type": "Xã Môn Sơn, Huyện Con Cuông, Tỉnh Nghệ An",
        "code": "17263",
        "parent_code": "422"
    },
    "17266": {
        "name": "Tân Kỳ",
        "type": "thi-tran",
        "slug": "tan-ky",
        "name_with_type": "Thị trấn Tân Kỳ",
        "path": "Tân Kỳ, Tân Kỳ, Nghệ An",
        "path_with_type": "Thị trấn Tân Kỳ, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17266",
        "parent_code": "423"
    },
    "17269": {
        "name": "Tân Hợp",
        "type": "xa",
        "slug": "tan-hop",
        "name_with_type": "Xã Tân Hợp",
        "path": "Tân Hợp, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Tân Hợp, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17269",
        "parent_code": "423"
    },
    "17272": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Tân Phú, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17272",
        "parent_code": "423"
    },
    "17275": {
        "name": "Tân Xuân",
        "type": "xa",
        "slug": "tan-xuan",
        "name_with_type": "Xã Tân Xuân",
        "path": "Tân Xuân, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Tân Xuân, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17275",
        "parent_code": "423"
    },
    "17278": {
        "name": "Giai Xuân",
        "type": "xa",
        "slug": "giai-xuan",
        "name_with_type": "Xã Giai Xuân",
        "path": "Giai Xuân, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Giai Xuân, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17278",
        "parent_code": "423"
    },
    "17281": {
        "name": "Nghĩa Bình",
        "type": "xa",
        "slug": "nghia-binh",
        "name_with_type": "Xã Nghĩa Bình",
        "path": "Nghĩa Bình, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Nghĩa Bình, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17281",
        "parent_code": "423"
    },
    "17284": {
        "name": "Nghĩa Đồng",
        "type": "xa",
        "slug": "nghia-dong",
        "name_with_type": "Xã Nghĩa Đồng",
        "path": "Nghĩa Đồng, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Nghĩa Đồng, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17284",
        "parent_code": "423"
    },
    "17287": {
        "name": "Đồng Văn",
        "type": "xa",
        "slug": "dong-van",
        "name_with_type": "Xã Đồng Văn",
        "path": "Đồng Văn, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Đồng Văn, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17287",
        "parent_code": "423"
    },
    "17290": {
        "name": "Nghĩa Thái",
        "type": "xa",
        "slug": "nghia-thai",
        "name_with_type": "Xã Nghĩa Thái",
        "path": "Nghĩa Thái, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Nghĩa Thái, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17290",
        "parent_code": "423"
    },
    "17293": {
        "name": "Nghĩa Hợp",
        "type": "xa",
        "slug": "nghia-hop",
        "name_with_type": "Xã Nghĩa Hợp",
        "path": "Nghĩa Hợp, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Nghĩa Hợp, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17293",
        "parent_code": "423"
    },
    "17296": {
        "name": "Nghĩa Hoàn",
        "type": "xa",
        "slug": "nghia-hoan",
        "name_with_type": "Xã Nghĩa Hoàn",
        "path": "Nghĩa Hoàn, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Nghĩa Hoàn, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17296",
        "parent_code": "423"
    },
    "17299": {
        "name": "Nghĩa Phúc",
        "type": "xa",
        "slug": "nghia-phuc",
        "name_with_type": "Xã Nghĩa Phúc",
        "path": "Nghĩa Phúc, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Nghĩa Phúc, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17299",
        "parent_code": "423"
    },
    "17302": {
        "name": "Tiên Kỳ",
        "type": "xa",
        "slug": "tien-ky",
        "name_with_type": "Xã Tiên Kỳ",
        "path": "Tiên Kỳ, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Tiên Kỳ, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17302",
        "parent_code": "423"
    },
    "17305": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Tân An, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17305",
        "parent_code": "423"
    },
    "17308": {
        "name": "Nghĩa Dũng",
        "type": "xa",
        "slug": "nghia-dung",
        "name_with_type": "Xã Nghĩa Dũng",
        "path": "Nghĩa Dũng, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Nghĩa Dũng, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17308",
        "parent_code": "423"
    },
    "17311": {
        "name": "Tân Long",
        "type": "xa",
        "slug": "tan-long",
        "name_with_type": "Xã Tân Long",
        "path": "Tân Long, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Tân Long, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17311",
        "parent_code": "423"
    },
    "17314": {
        "name": "Kỳ Sơn",
        "type": "xa",
        "slug": "ky-son",
        "name_with_type": "Xã Kỳ Sơn",
        "path": "Kỳ Sơn, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Kỳ Sơn, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17314",
        "parent_code": "423"
    },
    "17317": {
        "name": "Hương Sơn",
        "type": "xa",
        "slug": "huong-son",
        "name_with_type": "Xã Hương Sơn",
        "path": "Hương Sơn, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Hương Sơn, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17317",
        "parent_code": "423"
    },
    "17320": {
        "name": "Kỳ Tân",
        "type": "xa",
        "slug": "ky-tan",
        "name_with_type": "Xã Kỳ Tân",
        "path": "Kỳ Tân, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Kỳ Tân, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17320",
        "parent_code": "423"
    },
    "17323": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Phú Sơn, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17323",
        "parent_code": "423"
    },
    "17325": {
        "name": "Tân Hương",
        "type": "xa",
        "slug": "tan-huong",
        "name_with_type": "Xã Tân Hương",
        "path": "Tân Hương, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Tân Hương, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17325",
        "parent_code": "423"
    },
    "17326": {
        "name": "Nghĩa Hành",
        "type": "xa",
        "slug": "nghia-hanh",
        "name_with_type": "Xã Nghĩa Hành",
        "path": "Nghĩa Hành, Tân Kỳ, Nghệ An",
        "path_with_type": "Xã Nghĩa Hành, Huyện Tân Kỳ, Tỉnh Nghệ An",
        "code": "17326",
        "parent_code": "423"
    },
    "17329": {
        "name": "Anh Sơn",
        "type": "thi-tran",
        "slug": "anh-son",
        "name_with_type": "Thị trấn Anh Sơn",
        "path": "Anh Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Thị trấn Anh Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17329",
        "parent_code": "424"
    },
    "17332": {
        "name": "Thọ Sơn",
        "type": "xa",
        "slug": "tho-son",
        "name_with_type": "Xã Thọ Sơn",
        "path": "Thọ Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Thọ Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17332",
        "parent_code": "424"
    },
    "17335": {
        "name": "Thành Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thành Sơn",
        "path": "Thành Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Thành Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17335",
        "parent_code": "424"
    },
    "17338": {
        "name": "Bình Sơn",
        "type": "xa",
        "slug": "binh-son",
        "name_with_type": "Xã Bình Sơn",
        "path": "Bình Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Bình Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17338",
        "parent_code": "424"
    },
    "17341": {
        "name": "Tam Sơn",
        "type": "xa",
        "slug": "tam-son",
        "name_with_type": "Xã Tam Sơn",
        "path": "Tam Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Tam Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17341",
        "parent_code": "424"
    },
    "17344": {
        "name": "Đỉnh Sơn",
        "type": "xa",
        "slug": "dinh-son",
        "name_with_type": "Xã Đỉnh Sơn",
        "path": "Đỉnh Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Đỉnh Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17344",
        "parent_code": "424"
    },
    "17347": {
        "name": "Hùng Sơn",
        "type": "xa",
        "slug": "hung-son",
        "name_with_type": "Xã Hùng Sơn",
        "path": "Hùng Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Hùng Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17347",
        "parent_code": "424"
    },
    "17350": {
        "name": "Cẩm Sơn",
        "type": "xa",
        "slug": "cam-son",
        "name_with_type": "Xã Cẩm Sơn",
        "path": "Cẩm Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Cẩm Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17350",
        "parent_code": "424"
    },
    "17353": {
        "name": "Đức Sơn",
        "type": "xa",
        "slug": "duc-son",
        "name_with_type": "Xã Đức Sơn",
        "path": "Đức Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Đức Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17353",
        "parent_code": "424"
    },
    "17356": {
        "name": "Tường Sơn",
        "type": "xa",
        "slug": "tuong-son",
        "name_with_type": "Xã Tường Sơn",
        "path": "Tường Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Tường Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17356",
        "parent_code": "424"
    },
    "17357": {
        "name": "Hoa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hoa Sơn",
        "path": "Hoa Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Hoa Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17357",
        "parent_code": "424"
    },
    "17359": {
        "name": "Tào Sơn",
        "type": "xa",
        "slug": "tao-son",
        "name_with_type": "Xã Tào Sơn",
        "path": "Tào Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Tào Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17359",
        "parent_code": "424"
    },
    "17362": {
        "name": "Vĩnh Sơn",
        "type": "xa",
        "slug": "vinh-son",
        "name_with_type": "Xã Vĩnh Sơn",
        "path": "Vĩnh Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Vĩnh Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17362",
        "parent_code": "424"
    },
    "17365": {
        "name": "Lạng Sơn",
        "type": "xa",
        "slug": "lang-son",
        "name_with_type": "Xã Lạng Sơn",
        "path": "Lạng Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Lạng Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17365",
        "parent_code": "424"
    },
    "17368": {
        "name": "Hội Sơn",
        "type": "xa",
        "slug": "hoi-son",
        "name_with_type": "Xã Hội Sơn",
        "path": "Hội Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Hội Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17368",
        "parent_code": "424"
    },
    "17371": {
        "name": "Thạch Sơn",
        "type": "xa",
        "slug": "thach-son",
        "name_with_type": "Xã Thạch Sơn",
        "path": "Thạch Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Thạch Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17371",
        "parent_code": "424"
    },
    "17374": {
        "name": "Phúc Sơn",
        "type": "xa",
        "slug": "phuc-son",
        "name_with_type": "Xã Phúc Sơn",
        "path": "Phúc Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Phúc Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17374",
        "parent_code": "424"
    },
    "17377": {
        "name": "Long Sơn",
        "type": "xa",
        "slug": "long-son",
        "name_with_type": "Xã Long Sơn",
        "path": "Long Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Long Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17377",
        "parent_code": "424"
    },
    "17380": {
        "name": "Khai Sơn",
        "type": "xa",
        "slug": "khai-son",
        "name_with_type": "Xã Khai Sơn",
        "path": "Khai Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Khai Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17380",
        "parent_code": "424"
    },
    "17383": {
        "name": "Lĩnh Sơn",
        "type": "xa",
        "slug": "linh-son",
        "name_with_type": "Xã Lĩnh Sơn",
        "path": "Lĩnh Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Lĩnh Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17383",
        "parent_code": "424"
    },
    "17386": {
        "name": "Cao Sơn",
        "type": "xa",
        "slug": "cao-son",
        "name_with_type": "Xã Cao Sơn",
        "path": "Cao Sơn, Anh Sơn, Nghệ An",
        "path_with_type": "Xã Cao Sơn, Huyện Anh Sơn, Tỉnh Nghệ An",
        "code": "17386",
        "parent_code": "424"
    },
    "17389": {
        "name": "Diễn Châu",
        "type": "thi-tran",
        "slug": "dien-chau",
        "name_with_type": "Thị trấn Diễn Châu",
        "path": "Diễn Châu, Diễn Châu, Nghệ An",
        "path_with_type": "Thị trấn Diễn Châu, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17389",
        "parent_code": "425"
    },
    "17392": {
        "name": "Diễn Lâm",
        "type": "xa",
        "slug": "dien-lam",
        "name_with_type": "Xã Diễn Lâm",
        "path": "Diễn Lâm, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Lâm, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17392",
        "parent_code": "425"
    },
    "17395": {
        "name": "Diễn Đoài",
        "type": "xa",
        "slug": "dien-doai",
        "name_with_type": "Xã Diễn Đoài",
        "path": "Diễn Đoài, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Đoài, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17395",
        "parent_code": "425"
    },
    "17398": {
        "name": "Diễn Trường",
        "type": "xa",
        "slug": "dien-truong",
        "name_with_type": "Xã Diễn Trường",
        "path": "Diễn Trường, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Trường, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17398",
        "parent_code": "425"
    },
    "17401": {
        "name": "Diễn Yên",
        "type": "xa",
        "slug": "dien-yen",
        "name_with_type": "Xã Diễn Yên",
        "path": "Diễn Yên, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Yên, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17401",
        "parent_code": "425"
    },
    "17404": {
        "name": "Diễn Hoàng",
        "type": "xa",
        "slug": "dien-hoang",
        "name_with_type": "Xã Diễn Hoàng",
        "path": "Diễn Hoàng, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Hoàng, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17404",
        "parent_code": "425"
    },
    "17407": {
        "name": "Diễn Hùng",
        "type": "xa",
        "slug": "dien-hung",
        "name_with_type": "Xã Diễn Hùng",
        "path": "Diễn Hùng, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Hùng, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17407",
        "parent_code": "425"
    },
    "17410": {
        "name": "Diễn Mỹ",
        "type": "xa",
        "slug": "dien-my",
        "name_with_type": "Xã Diễn Mỹ",
        "path": "Diễn Mỹ, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Mỹ, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17410",
        "parent_code": "425"
    },
    "17413": {
        "name": "Diễn Hồng",
        "type": "xa",
        "slug": "dien-hong",
        "name_with_type": "Xã Diễn Hồng",
        "path": "Diễn Hồng, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Hồng, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17413",
        "parent_code": "425"
    },
    "17416": {
        "name": "Diễn Phong",
        "type": "xa",
        "slug": "dien-phong",
        "name_with_type": "Xã Diễn Phong",
        "path": "Diễn Phong, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Phong, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17416",
        "parent_code": "425"
    },
    "17419": {
        "name": "Diễn Hải",
        "type": "xa",
        "slug": "dien-hai",
        "name_with_type": "Xã Diễn Hải",
        "path": "Diễn Hải, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Hải, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17419",
        "parent_code": "425"
    },
    "17422": {
        "name": "Diễn Tháp",
        "type": "xa",
        "slug": "dien-thap",
        "name_with_type": "Xã Diễn Tháp",
        "path": "Diễn Tháp, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Tháp, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17422",
        "parent_code": "425"
    },
    "17425": {
        "name": "Diễn Liên",
        "type": "xa",
        "slug": "dien-lien",
        "name_with_type": "Xã Diễn Liên",
        "path": "Diễn Liên, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Liên, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17425",
        "parent_code": "425"
    },
    "17428": {
        "name": "Diễn Vạn",
        "type": "xa",
        "slug": "dien-van",
        "name_with_type": "Xã Diễn Vạn",
        "path": "Diễn Vạn, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Vạn, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17428",
        "parent_code": "425"
    },
    "17431": {
        "name": "Diễn Kim",
        "type": "xa",
        "slug": "dien-kim",
        "name_with_type": "Xã Diễn Kim",
        "path": "Diễn Kim, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Kim, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17431",
        "parent_code": "425"
    },
    "17434": {
        "name": "Diễn Kỷ",
        "type": "xa",
        "slug": "dien-ky",
        "name_with_type": "Xã Diễn Kỷ",
        "path": "Diễn Kỷ, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Kỷ, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17434",
        "parent_code": "425"
    },
    "17437": {
        "name": "Diễn Xuân",
        "type": "xa",
        "slug": "dien-xuan",
        "name_with_type": "Xã Diễn Xuân",
        "path": "Diễn Xuân, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Xuân, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17437",
        "parent_code": "425"
    },
    "17440": {
        "name": "Diễn Thái",
        "type": "xa",
        "slug": "dien-thai",
        "name_with_type": "Xã Diễn Thái",
        "path": "Diễn Thái, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Thái, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17440",
        "parent_code": "425"
    },
    "17443": {
        "name": "Diễn Đồng",
        "type": "xa",
        "slug": "dien-dong",
        "name_with_type": "Xã Diễn Đồng",
        "path": "Diễn Đồng, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Đồng, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17443",
        "parent_code": "425"
    },
    "17446": {
        "name": "Diễn Bích",
        "type": "xa",
        "slug": "dien-bich",
        "name_with_type": "Xã Diễn Bích",
        "path": "Diễn Bích, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Bích, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17446",
        "parent_code": "425"
    },
    "17449": {
        "name": "Diễn Hạnh",
        "type": "xa",
        "slug": "dien-hanh",
        "name_with_type": "Xã Diễn Hạnh",
        "path": "Diễn Hạnh, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Hạnh, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17449",
        "parent_code": "425"
    },
    "17452": {
        "name": "Diễn Ngọc",
        "type": "xa",
        "slug": "dien-ngoc",
        "name_with_type": "Xã Diễn Ngọc",
        "path": "Diễn Ngọc, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Ngọc, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17452",
        "parent_code": "425"
    },
    "17455": {
        "name": "Diễn Quảng",
        "type": "xa",
        "slug": "dien-quang",
        "name_with_type": "Xã Diễn Quảng",
        "path": "Diễn Quảng, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Quảng, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17455",
        "parent_code": "425"
    },
    "17458": {
        "name": "Diễn Nguyên",
        "type": "xa",
        "slug": "dien-nguyen",
        "name_with_type": "Xã Diễn Nguyên",
        "path": "Diễn Nguyên, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Nguyên, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17458",
        "parent_code": "425"
    },
    "17461": {
        "name": "Diễn Hoa",
        "type": "xa",
        "slug": "dien-hoa",
        "name_with_type": "Xã Diễn Hoa",
        "path": "Diễn Hoa, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Hoa, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17461",
        "parent_code": "425"
    },
    "17464": {
        "name": "Diễn Thành",
        "type": "xa",
        "slug": "dien-thanh",
        "name_with_type": "Xã Diễn Thành",
        "path": "Diễn Thành, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Thành, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17464",
        "parent_code": "425"
    },
    "17467": {
        "name": "Diễn Phúc",
        "type": "xa",
        "slug": "dien-phuc",
        "name_with_type": "Xã Diễn Phúc",
        "path": "Diễn Phúc, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Phúc, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17467",
        "parent_code": "425"
    },
    "17470": {
        "name": "Diễn Minh",
        "type": "xa",
        "slug": "dien-minh",
        "name_with_type": "Xã Diễn Minh",
        "path": "Diễn Minh, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Minh, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17470",
        "parent_code": "425"
    },
    "17473": {
        "name": "Diễn Bình",
        "type": "xa",
        "slug": "dien-binh",
        "name_with_type": "Xã Diễn Bình",
        "path": "Diễn Bình, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Bình, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17473",
        "parent_code": "425"
    },
    "17476": {
        "name": "Diễn Cát",
        "type": "xa",
        "slug": "dien-cat",
        "name_with_type": "Xã Diễn Cát",
        "path": "Diễn Cát, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Cát, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17476",
        "parent_code": "425"
    },
    "17479": {
        "name": "Diễn Thịnh",
        "type": "xa",
        "slug": "dien-thinh",
        "name_with_type": "Xã Diễn Thịnh",
        "path": "Diễn Thịnh, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Thịnh, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17479",
        "parent_code": "425"
    },
    "17482": {
        "name": "Diễn Tân",
        "type": "xa",
        "slug": "dien-tan",
        "name_with_type": "Xã Diễn Tân",
        "path": "Diễn Tân, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Tân, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17482",
        "parent_code": "425"
    },
    "17485": {
        "name": "Diễn Thắng",
        "type": "xa",
        "slug": "dien-thang",
        "name_with_type": "Xã Diễn Thắng",
        "path": "Diễn Thắng, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Thắng, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17485",
        "parent_code": "425"
    },
    "17488": {
        "name": "Diễn Thọ",
        "type": "xa",
        "slug": "dien-tho",
        "name_with_type": "Xã Diễn Thọ",
        "path": "Diễn Thọ, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Thọ, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17488",
        "parent_code": "425"
    },
    "17491": {
        "name": "Diễn Lợi",
        "type": "xa",
        "slug": "dien-loi",
        "name_with_type": "Xã Diễn Lợi",
        "path": "Diễn Lợi, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Lợi, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17491",
        "parent_code": "425"
    },
    "17494": {
        "name": "Diễn Lộc",
        "type": "xa",
        "slug": "dien-loc",
        "name_with_type": "Xã Diễn Lộc",
        "path": "Diễn Lộc, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Lộc, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17494",
        "parent_code": "425"
    },
    "17497": {
        "name": "Diễn Trung",
        "type": "xa",
        "slug": "dien-trung",
        "name_with_type": "Xã Diễn Trung",
        "path": "Diễn Trung, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Trung, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17497",
        "parent_code": "425"
    },
    "17500": {
        "name": "Diễn An",
        "type": "xa",
        "slug": "dien-an",
        "name_with_type": "Xã Diễn An",
        "path": "Diễn An, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn An, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17500",
        "parent_code": "425"
    },
    "17503": {
        "name": "Diễn Phú",
        "type": "xa",
        "slug": "dien-phu",
        "name_with_type": "Xã Diễn Phú",
        "path": "Diễn Phú, Diễn Châu, Nghệ An",
        "path_with_type": "Xã Diễn Phú, Huyện Diễn Châu, Tỉnh Nghệ An",
        "code": "17503",
        "parent_code": "425"
    },
    "17506": {
        "name": "Yên Thành",
        "type": "thi-tran",
        "slug": "yen-thanh",
        "name_with_type": "Thị trấn Yên Thành",
        "path": "Yên Thành, Yên Thành, Nghệ An",
        "path_with_type": "Thị trấn Yên Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17506",
        "parent_code": "426"
    },
    "17509": {
        "name": "Mã Thành",
        "type": "xa",
        "slug": "ma-thanh",
        "name_with_type": "Xã Mã Thành",
        "path": "Mã Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Mã Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17509",
        "parent_code": "426"
    },
    "17510": {
        "name": "Tiến Thành",
        "type": "xa",
        "slug": "tien-thanh",
        "name_with_type": "Xã Tiến Thành",
        "path": "Tiến Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Tiến Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17510",
        "parent_code": "426"
    },
    "17512": {
        "name": "Lăng Thành",
        "type": "xa",
        "slug": "lang-thanh",
        "name_with_type": "Xã Lăng Thành",
        "path": "Lăng Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Lăng Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17512",
        "parent_code": "426"
    },
    "17515": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Tân Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17515",
        "parent_code": "426"
    },
    "17518": {
        "name": "Đức Thành",
        "type": "xa",
        "slug": "duc-thanh",
        "name_with_type": "Xã Đức Thành",
        "path": "Đức Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Đức Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17518",
        "parent_code": "426"
    },
    "17521": {
        "name": "Kim Thành",
        "type": "xa",
        "slug": "kim-thanh",
        "name_with_type": "Xã Kim Thành",
        "path": "Kim Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Kim Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17521",
        "parent_code": "426"
    },
    "17524": {
        "name": "Hậu Thành",
        "type": "xa",
        "slug": "hau-thanh",
        "name_with_type": "Xã Hậu Thành",
        "path": "Hậu Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Hậu Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17524",
        "parent_code": "426"
    },
    "17525": {
        "name": "Hùng Thành",
        "type": "xa",
        "slug": "hung-thanh",
        "name_with_type": "Xã Hùng Thành",
        "path": "Hùng Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Hùng Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17525",
        "parent_code": "426"
    },
    "17527": {
        "name": "Đô Thành",
        "type": "xa",
        "slug": "do-thanh",
        "name_with_type": "Xã Đô Thành",
        "path": "Đô Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Đô Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17527",
        "parent_code": "426"
    },
    "17530": {
        "name": "Thọ Thành",
        "type": "xa",
        "slug": "tho-thanh",
        "name_with_type": "Xã Thọ Thành",
        "path": "Thọ Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Thọ Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17530",
        "parent_code": "426"
    },
    "17533": {
        "name": "Quang Thành",
        "type": "xa",
        "slug": "quang-thanh",
        "name_with_type": "Xã Quang Thành",
        "path": "Quang Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Quang Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17533",
        "parent_code": "426"
    },
    "17536": {
        "name": "Tây Thành",
        "type": "xa",
        "slug": "tay-thanh",
        "name_with_type": "Xã Tây Thành",
        "path": "Tây Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Tây Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17536",
        "parent_code": "426"
    },
    "17539": {
        "name": "Phúc Thành",
        "type": "xa",
        "slug": "phuc-thanh",
        "name_with_type": "Xã Phúc Thành",
        "path": "Phúc Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Phúc Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17539",
        "parent_code": "426"
    },
    "17542": {
        "name": "Hồng Thành",
        "type": "xa",
        "slug": "hong-thanh",
        "name_with_type": "Xã Hồng Thành",
        "path": "Hồng Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Hồng Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17542",
        "parent_code": "426"
    },
    "17545": {
        "name": "Đồng Thành",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đồng Thành",
        "path": "Đồng Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Đồng Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17545",
        "parent_code": "426"
    },
    "17548": {
        "name": "Phú Thành",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thành",
        "path": "Phú Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Phú Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17548",
        "parent_code": "426"
    },
    "17551": {
        "name": "Hoa Thành",
        "type": "xa",
        "slug": "hoa-thanh",
        "name_with_type": "Xã Hoa Thành",
        "path": "Hoa Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Hoa Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17551",
        "parent_code": "426"
    },
    "17554": {
        "name": "Tăng Thành",
        "type": "xa",
        "slug": "tang-thanh",
        "name_with_type": "Xã Tăng Thành",
        "path": "Tăng Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Tăng Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17554",
        "parent_code": "426"
    },
    "17557": {
        "name": "Văn Thành",
        "type": "xa",
        "slug": "van-thanh",
        "name_with_type": "Xã Văn Thành",
        "path": "Văn Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Văn Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17557",
        "parent_code": "426"
    },
    "17560": {
        "name": "Thịnh Thành",
        "type": "xa",
        "slug": "thinh-thanh",
        "name_with_type": "Xã Thịnh Thành",
        "path": "Thịnh Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Thịnh Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17560",
        "parent_code": "426"
    },
    "17563": {
        "name": "Hợp Thành",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thành",
        "path": "Hợp Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Hợp Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17563",
        "parent_code": "426"
    },
    "17566": {
        "name": "Xuân Thành",
        "type": "xa",
        "slug": "xuan-thanh",
        "name_with_type": "Xã Xuân Thành",
        "path": "Xuân Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Xuân Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17566",
        "parent_code": "426"
    },
    "17569": {
        "name": "Bắc Thành",
        "type": "xa",
        "slug": "bac-thanh",
        "name_with_type": "Xã Bắc Thành",
        "path": "Bắc Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Bắc Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17569",
        "parent_code": "426"
    },
    "17572": {
        "name": "Nhân Thành",
        "type": "xa",
        "slug": "nhan-thanh",
        "name_with_type": "Xã Nhân Thành",
        "path": "Nhân Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Nhân Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17572",
        "parent_code": "426"
    },
    "17575": {
        "name": "Trung Thành",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thành",
        "path": "Trung Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Trung Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17575",
        "parent_code": "426"
    },
    "17578": {
        "name": "Long Thành",
        "type": "xa",
        "slug": "long-thanh",
        "name_with_type": "Xã Long Thành",
        "path": "Long Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Long Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17578",
        "parent_code": "426"
    },
    "17581": {
        "name": "Minh Thành",
        "type": "xa",
        "slug": "minh-thanh",
        "name_with_type": "Xã Minh Thành",
        "path": "Minh Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Minh Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17581",
        "parent_code": "426"
    },
    "17584": {
        "name": "Nam Thành",
        "type": "xa",
        "slug": "nam-thanh",
        "name_with_type": "Xã Nam Thành",
        "path": "Nam Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Nam Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17584",
        "parent_code": "426"
    },
    "17587": {
        "name": "Vĩnh Thành",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thành",
        "path": "Vĩnh Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Vĩnh Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17587",
        "parent_code": "426"
    },
    "17590": {
        "name": "Lý Thành",
        "type": "xa",
        "slug": "ly-thanh",
        "name_with_type": "Xã Lý Thành",
        "path": "Lý Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Lý Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17590",
        "parent_code": "426"
    },
    "17593": {
        "name": "Khánh Thành",
        "type": "xa",
        "slug": "khanh-thanh",
        "name_with_type": "Xã Khánh Thành",
        "path": "Khánh Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Khánh Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17593",
        "parent_code": "426"
    },
    "17596": {
        "name": "Viên Thành",
        "type": "xa",
        "slug": "vien-thanh",
        "name_with_type": "Xã Viên Thành",
        "path": "Viên Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Viên Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17596",
        "parent_code": "426"
    },
    "17599": {
        "name": "Đại Thành",
        "type": "xa",
        "slug": "dai-thanh",
        "name_with_type": "Xã Đại Thành",
        "path": "Đại Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Đại Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17599",
        "parent_code": "426"
    },
    "17602": {
        "name": "Liên Thành",
        "type": "xa",
        "slug": "lien-thanh",
        "name_with_type": "Xã Liên Thành",
        "path": "Liên Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Liên Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17602",
        "parent_code": "426"
    },
    "17605": {
        "name": "Bảo Thành",
        "type": "xa",
        "slug": "bao-thanh",
        "name_with_type": "Xã Bảo Thành",
        "path": "Bảo Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Bảo Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17605",
        "parent_code": "426"
    },
    "17608": {
        "name": "Mỹ Thành",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thành",
        "path": "Mỹ Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Mỹ Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17608",
        "parent_code": "426"
    },
    "17611": {
        "name": "Công Thành",
        "type": "xa",
        "slug": "cong-thanh",
        "name_with_type": "Xã Công Thành",
        "path": "Công Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Công Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17611",
        "parent_code": "426"
    },
    "17614": {
        "name": "Sơn Thành",
        "type": "xa",
        "slug": "son-thanh",
        "name_with_type": "Xã Sơn Thành",
        "path": "Sơn Thành, Yên Thành, Nghệ An",
        "path_with_type": "Xã Sơn Thành, Huyện Yên Thành, Tỉnh Nghệ An",
        "code": "17614",
        "parent_code": "426"
    },
    "17617": {
        "name": "Đô Lương",
        "type": "thi-tran",
        "slug": "do-luong",
        "name_with_type": "Thị trấn Đô Lương",
        "path": "Đô Lương, Đô Lương, Nghệ An",
        "path_with_type": "Thị trấn Đô Lương, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17617",
        "parent_code": "427"
    },
    "17619": {
        "name": "Giang Sơn Đông",
        "type": "xa",
        "slug": "giang-son-dong",
        "name_with_type": "Xã Giang Sơn Đông",
        "path": "Giang Sơn Đông, Đô Lương, Nghệ An",
        "path_with_type": "Xã Giang Sơn Đông, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17619",
        "parent_code": "427"
    },
    "17620": {
        "name": "Giang Sơn Tây",
        "type": "xa",
        "slug": "giang-son-tay",
        "name_with_type": "Xã Giang Sơn Tây",
        "path": "Giang Sơn Tây, Đô Lương, Nghệ An",
        "path_with_type": "Xã Giang Sơn Tây, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17620",
        "parent_code": "427"
    },
    "17623": {
        "name": "Lam Sơn",
        "type": "xa",
        "slug": "lam-son",
        "name_with_type": "Xã Lam Sơn",
        "path": "Lam Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Lam Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17623",
        "parent_code": "427"
    },
    "17626": {
        "name": "Bồi Sơn",
        "type": "xa",
        "slug": "boi-son",
        "name_with_type": "Xã Bồi Sơn",
        "path": "Bồi Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Bồi Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17626",
        "parent_code": "427"
    },
    "17629": {
        "name": "Hồng Sơn",
        "type": "xa",
        "slug": "hong-son",
        "name_with_type": "Xã Hồng Sơn",
        "path": "Hồng Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Hồng Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17629",
        "parent_code": "427"
    },
    "17632": {
        "name": "Bài Sơn",
        "type": "xa",
        "slug": "bai-son",
        "name_with_type": "Xã Bài Sơn",
        "path": "Bài Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Bài Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17632",
        "parent_code": "427"
    },
    "17635": {
        "name": "Ngọc Sơn",
        "type": "xa",
        "slug": "ngoc-son",
        "name_with_type": "Xã Ngọc Sơn",
        "path": "Ngọc Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Ngọc Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17635",
        "parent_code": "427"
    },
    "17638": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Bắc Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17638",
        "parent_code": "427"
    },
    "17641": {
        "name": "Tràng Sơn",
        "type": "xa",
        "slug": "trang-son",
        "name_with_type": "Xã Tràng Sơn",
        "path": "Tràng Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Tràng Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17641",
        "parent_code": "427"
    },
    "17644": {
        "name": "Thượng Sơn",
        "type": "xa",
        "slug": "thuong-son",
        "name_with_type": "Xã Thượng Sơn",
        "path": "Thượng Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Thượng Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17644",
        "parent_code": "427"
    },
    "17647": {
        "name": "Hòa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hòa Sơn",
        "path": "Hòa Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Hòa Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17647",
        "parent_code": "427"
    },
    "17650": {
        "name": "Đặng Sơn",
        "type": "xa",
        "slug": "dang-son",
        "name_with_type": "Xã Đặng Sơn",
        "path": "Đặng Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Đặng Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17650",
        "parent_code": "427"
    },
    "17653": {
        "name": "Đông Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đông Sơn",
        "path": "Đông Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Đông Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17653",
        "parent_code": "427"
    },
    "17656": {
        "name": "Nam Sơn",
        "type": "xa",
        "slug": "nam-son",
        "name_with_type": "Xã Nam Sơn",
        "path": "Nam Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Nam Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17656",
        "parent_code": "427"
    },
    "17659": {
        "name": "Lưu Sơn",
        "type": "xa",
        "slug": "luu-son",
        "name_with_type": "Xã Lưu Sơn",
        "path": "Lưu Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Lưu Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17659",
        "parent_code": "427"
    },
    "17662": {
        "name": "Yên Sơn",
        "type": "xa",
        "slug": "yen-son",
        "name_with_type": "Xã Yên Sơn",
        "path": "Yên Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Yên Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17662",
        "parent_code": "427"
    },
    "17665": {
        "name": "Văn Sơn",
        "type": "xa",
        "slug": "van-son",
        "name_with_type": "Xã Văn Sơn",
        "path": "Văn Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Văn Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17665",
        "parent_code": "427"
    },
    "17668": {
        "name": "Đà Sơn",
        "type": "xa",
        "slug": "da-son",
        "name_with_type": "Xã Đà Sơn",
        "path": "Đà Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Đà Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17668",
        "parent_code": "427"
    },
    "17671": {
        "name": "Lạc Sơn",
        "type": "xa",
        "slug": "lac-son",
        "name_with_type": "Xã Lạc Sơn",
        "path": "Lạc Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Lạc Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17671",
        "parent_code": "427"
    },
    "17674": {
        "name": "Tân Sơn",
        "type": "xa",
        "slug": "tan-son",
        "name_with_type": "Xã Tân Sơn",
        "path": "Tân Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Tân Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17674",
        "parent_code": "427"
    },
    "17677": {
        "name": "Thái Sơn",
        "type": "xa",
        "slug": "thai-son",
        "name_with_type": "Xã Thái Sơn",
        "path": "Thái Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Thái Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17677",
        "parent_code": "427"
    },
    "17680": {
        "name": "Quang Sơn",
        "type": "xa",
        "slug": "quang-son",
        "name_with_type": "Xã Quang Sơn",
        "path": "Quang Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Quang Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17680",
        "parent_code": "427"
    },
    "17683": {
        "name": "Thịnh Sơn",
        "type": "xa",
        "slug": "thinh-son",
        "name_with_type": "Xã Thịnh Sơn",
        "path": "Thịnh Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Thịnh Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17683",
        "parent_code": "427"
    },
    "17686": {
        "name": "Trung Sơn",
        "type": "xa",
        "slug": "trung-son",
        "name_with_type": "Xã Trung Sơn",
        "path": "Trung Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Trung Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17686",
        "parent_code": "427"
    },
    "17689": {
        "name": "Xuân Sơn",
        "type": "xa",
        "slug": "xuan-son",
        "name_with_type": "Xã Xuân Sơn",
        "path": "Xuân Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Xuân Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17689",
        "parent_code": "427"
    },
    "17692": {
        "name": "Minh Sơn",
        "type": "xa",
        "slug": "minh-son",
        "name_with_type": "Xã Minh Sơn",
        "path": "Minh Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Minh Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17692",
        "parent_code": "427"
    },
    "17695": {
        "name": "Thuận Sơn",
        "type": "xa",
        "slug": "thuan-son",
        "name_with_type": "Xã Thuận Sơn",
        "path": "Thuận Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Thuận Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17695",
        "parent_code": "427"
    },
    "17698": {
        "name": "Nhân Sơn",
        "type": "xa",
        "slug": "nhan-son",
        "name_with_type": "Xã Nhân Sơn",
        "path": "Nhân Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Nhân Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17698",
        "parent_code": "427"
    },
    "17701": {
        "name": "Hiến Sơn",
        "type": "xa",
        "slug": "hien-son",
        "name_with_type": "Xã Hiến Sơn",
        "path": "Hiến Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Hiến Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17701",
        "parent_code": "427"
    },
    "17704": {
        "name": "Mỹ Sơn",
        "type": "xa",
        "slug": "my-son",
        "name_with_type": "Xã Mỹ Sơn",
        "path": "Mỹ Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Mỹ Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17704",
        "parent_code": "427"
    },
    "17707": {
        "name": "Trù Sơn",
        "type": "xa",
        "slug": "tru-son",
        "name_with_type": "Xã Trù Sơn",
        "path": "Trù Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Trù Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17707",
        "parent_code": "427"
    },
    "17710": {
        "name": "Đại Sơn",
        "type": "xa",
        "slug": "dai-son",
        "name_with_type": "Xã Đại Sơn",
        "path": "Đại Sơn, Đô Lương, Nghệ An",
        "path_with_type": "Xã Đại Sơn, Huyện Đô Lương, Tỉnh Nghệ An",
        "code": "17710",
        "parent_code": "427"
    },
    "17713": {
        "name": "Thanh Chương",
        "type": "thi-tran",
        "slug": "thanh-chuong",
        "name_with_type": "Thị trấn Thanh Chương",
        "path": "Thanh Chương, Thanh Chương, Nghệ An",
        "path_with_type": "Thị trấn Thanh Chương, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17713",
        "parent_code": "428"
    },
    "17716": {
        "name": "Cát Văn",
        "type": "xa",
        "slug": "cat-van",
        "name_with_type": "Xã Cát Văn",
        "path": "Cát Văn, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Cát Văn, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17716",
        "parent_code": "428"
    },
    "17719": {
        "name": "Thanh Nho",
        "type": "xa",
        "slug": "thanh-nho",
        "name_with_type": "Xã Thanh Nho",
        "path": "Thanh Nho, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Nho, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17719",
        "parent_code": "428"
    },
    "17722": {
        "name": "Hạnh Lâm",
        "type": "xa",
        "slug": "hanh-lam",
        "name_with_type": "Xã Hạnh Lâm",
        "path": "Hạnh Lâm, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Hạnh Lâm, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17722",
        "parent_code": "428"
    },
    "17723": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Sơn, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17723",
        "parent_code": "428"
    },
    "17725": {
        "name": "Thanh Hòa",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thanh Hòa",
        "path": "Thanh Hòa, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Hòa, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17725",
        "parent_code": "428"
    },
    "17728": {
        "name": "Phong Thịnh",
        "type": "xa",
        "slug": "phong-thinh",
        "name_with_type": "Xã Phong Thịnh",
        "path": "Phong Thịnh, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Phong Thịnh, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17728",
        "parent_code": "428"
    },
    "17731": {
        "name": "Thanh Phong",
        "type": "xa",
        "slug": "thanh-phong",
        "name_with_type": "Xã Thanh Phong",
        "path": "Thanh Phong, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Phong, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17731",
        "parent_code": "428"
    },
    "17734": {
        "name": "Thanh Mỹ",
        "type": "xa",
        "slug": "thanh-my",
        "name_with_type": "Xã Thanh Mỹ",
        "path": "Thanh Mỹ, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Mỹ, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17734",
        "parent_code": "428"
    },
    "17737": {
        "name": "Thanh Tiên",
        "type": "xa",
        "slug": "thanh-tien",
        "name_with_type": "Xã Thanh Tiên",
        "path": "Thanh Tiên, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Tiên, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17737",
        "parent_code": "428"
    },
    "17740": {
        "name": "Thanh Hưng",
        "type": "xa",
        "slug": "thanh-hung",
        "name_with_type": "Xã Thanh Hưng",
        "path": "Thanh Hưng, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Hưng, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17740",
        "parent_code": "428"
    },
    "17743": {
        "name": "Thanh Liên",
        "type": "xa",
        "slug": "thanh-lien",
        "name_with_type": "Xã Thanh Liên",
        "path": "Thanh Liên, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Liên, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17743",
        "parent_code": "428"
    },
    "17746": {
        "name": "Thanh Tường",
        "type": "xa",
        "slug": "thanh-tuong",
        "name_with_type": "Xã Thanh Tường",
        "path": "Thanh Tường, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Tường, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17746",
        "parent_code": "428"
    },
    "17749": {
        "name": "Thanh Văn",
        "type": "xa",
        "slug": "thanh-van",
        "name_with_type": "Xã Thanh Văn",
        "path": "Thanh Văn, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Văn, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17749",
        "parent_code": "428"
    },
    "17752": {
        "name": "Thanh Đồng",
        "type": "xa",
        "slug": "thanh-dong",
        "name_with_type": "Xã Thanh Đồng",
        "path": "Thanh Đồng, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Đồng, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17752",
        "parent_code": "428"
    },
    "17755": {
        "name": "Thanh Ngọc",
        "type": "xa",
        "slug": "thanh-ngoc",
        "name_with_type": "Xã Thanh Ngọc",
        "path": "Thanh Ngọc, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Ngọc, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17755",
        "parent_code": "428"
    },
    "17758": {
        "name": "Thanh Hương",
        "type": "xa",
        "slug": "thanh-huong",
        "name_with_type": "Xã Thanh Hương",
        "path": "Thanh Hương, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Hương, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17758",
        "parent_code": "428"
    },
    "17759": {
        "name": "Ngọc Lâm",
        "type": "xa",
        "slug": "ngoc-lam",
        "name_with_type": "Xã Ngọc Lâm",
        "path": "Ngọc Lâm, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Ngọc Lâm, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17759",
        "parent_code": "428"
    },
    "17761": {
        "name": "Thanh Lĩnh",
        "type": "xa",
        "slug": "thanh-linh",
        "name_with_type": "Xã Thanh Lĩnh",
        "path": "Thanh Lĩnh, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Lĩnh, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17761",
        "parent_code": "428"
    },
    "17764": {
        "name": "Đồng Văn",
        "type": "xa",
        "slug": "dong-van",
        "name_with_type": "Xã Đồng Văn",
        "path": "Đồng Văn, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Đồng Văn, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17764",
        "parent_code": "428"
    },
    "17767": {
        "name": "Ngọc Sơn",
        "type": "xa",
        "slug": "ngoc-son",
        "name_with_type": "Xã Ngọc Sơn",
        "path": "Ngọc Sơn, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Ngọc Sơn, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17767",
        "parent_code": "428"
    },
    "17770": {
        "name": "Thanh Thịnh",
        "type": "xa",
        "slug": "thanh-thinh",
        "name_with_type": "Xã Thanh Thịnh",
        "path": "Thanh Thịnh, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Thịnh, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17770",
        "parent_code": "428"
    },
    "17773": {
        "name": "Thanh An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thanh An",
        "path": "Thanh An, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh An, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17773",
        "parent_code": "428"
    },
    "17776": {
        "name": "Thanh Chi",
        "type": "xa",
        "slug": "thanh-chi",
        "name_with_type": "Xã Thanh Chi",
        "path": "Thanh Chi, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Chi, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17776",
        "parent_code": "428"
    },
    "17779": {
        "name": "Xuân Tường",
        "type": "xa",
        "slug": "xuan-tuong",
        "name_with_type": "Xã Xuân Tường",
        "path": "Xuân Tường, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Xuân Tường, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17779",
        "parent_code": "428"
    },
    "17782": {
        "name": "Thanh Dương",
        "type": "xa",
        "slug": "thanh-duong",
        "name_with_type": "Xã Thanh Dương",
        "path": "Thanh Dương, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Dương, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17782",
        "parent_code": "428"
    },
    "17785": {
        "name": "Thanh Lương",
        "type": "xa",
        "slug": "thanh-luong",
        "name_with_type": "Xã Thanh Lương",
        "path": "Thanh Lương, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Lương, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17785",
        "parent_code": "428"
    },
    "17788": {
        "name": "Thanh Khê",
        "type": "xa",
        "slug": "thanh-khe",
        "name_with_type": "Xã Thanh Khê",
        "path": "Thanh Khê, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Khê, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17788",
        "parent_code": "428"
    },
    "17791": {
        "name": "Võ Liệt",
        "type": "xa",
        "slug": "vo-liet",
        "name_with_type": "Xã Võ Liệt",
        "path": "Võ Liệt, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Võ Liệt, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17791",
        "parent_code": "428"
    },
    "17794": {
        "name": "Thanh Long",
        "type": "xa",
        "slug": "thanh-long",
        "name_with_type": "Xã Thanh Long",
        "path": "Thanh Long, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Long, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17794",
        "parent_code": "428"
    },
    "17797": {
        "name": "Thanh Thủy",
        "type": "xa",
        "slug": "thanh-thuy",
        "name_with_type": "Xã Thanh Thủy",
        "path": "Thanh Thủy, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Thủy, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17797",
        "parent_code": "428"
    },
    "17800": {
        "name": "Thanh Khai",
        "type": "xa",
        "slug": "thanh-khai",
        "name_with_type": "Xã Thanh Khai",
        "path": "Thanh Khai, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Khai, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17800",
        "parent_code": "428"
    },
    "17803": {
        "name": "Thanh Yên",
        "type": "xa",
        "slug": "thanh-yen",
        "name_with_type": "Xã Thanh Yên",
        "path": "Thanh Yên, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Yên, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17803",
        "parent_code": "428"
    },
    "17806": {
        "name": "Thanh Hà",
        "type": "xa",
        "slug": "thanh-ha",
        "name_with_type": "Xã Thanh Hà",
        "path": "Thanh Hà, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Hà, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17806",
        "parent_code": "428"
    },
    "17809": {
        "name": "Thanh Giang",
        "type": "xa",
        "slug": "thanh-giang",
        "name_with_type": "Xã Thanh Giang",
        "path": "Thanh Giang, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Giang, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17809",
        "parent_code": "428"
    },
    "17812": {
        "name": "Thanh Tùng",
        "type": "xa",
        "slug": "thanh-tung",
        "name_with_type": "Xã Thanh Tùng",
        "path": "Thanh Tùng, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Tùng, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17812",
        "parent_code": "428"
    },
    "17815": {
        "name": "Thanh Lâm",
        "type": "xa",
        "slug": "thanh-lam",
        "name_with_type": "Xã Thanh Lâm",
        "path": "Thanh Lâm, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Lâm, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17815",
        "parent_code": "428"
    },
    "17818": {
        "name": "Thanh Mai",
        "type": "xa",
        "slug": "thanh-mai",
        "name_with_type": "Xã Thanh Mai",
        "path": "Thanh Mai, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Mai, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17818",
        "parent_code": "428"
    },
    "17821": {
        "name": "Thanh Xuân",
        "type": "xa",
        "slug": "thanh-xuan",
        "name_with_type": "Xã Thanh Xuân",
        "path": "Thanh Xuân, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Xuân, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17821",
        "parent_code": "428"
    },
    "17824": {
        "name": "Thanh Đức",
        "type": "xa",
        "slug": "thanh-duc",
        "name_with_type": "Xã Thanh Đức",
        "path": "Thanh Đức, Thanh Chương, Nghệ An",
        "path_with_type": "Xã Thanh Đức, Huyện Thanh Chương, Tỉnh Nghệ An",
        "code": "17824",
        "parent_code": "428"
    },
    "17827": {
        "name": "Quán Hành",
        "type": "thi-tran",
        "slug": "quan-hanh",
        "name_with_type": "Thị trấn Quán Hành",
        "path": "Quán Hành, Nghi Lộc, Nghệ An",
        "path_with_type": "Thị trấn Quán Hành, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17827",
        "parent_code": "429"
    },
    "17830": {
        "name": "Nghi Văn",
        "type": "xa",
        "slug": "nghi-van",
        "name_with_type": "Xã Nghi Văn",
        "path": "Nghi Văn, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Văn, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17830",
        "parent_code": "429"
    },
    "17833": {
        "name": "Nghi Yên",
        "type": "xa",
        "slug": "nghi-yen",
        "name_with_type": "Xã Nghi Yên",
        "path": "Nghi Yên, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Yên, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17833",
        "parent_code": "429"
    },
    "17836": {
        "name": "Nghi Tiến",
        "type": "xa",
        "slug": "nghi-tien",
        "name_with_type": "Xã Nghi Tiến",
        "path": "Nghi Tiến, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Tiến, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17836",
        "parent_code": "429"
    },
    "17839": {
        "name": "Nghi Hưng",
        "type": "xa",
        "slug": "nghi-hung",
        "name_with_type": "Xã Nghi Hưng",
        "path": "Nghi Hưng, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Hưng, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17839",
        "parent_code": "429"
    },
    "17842": {
        "name": "Nghi Đồng",
        "type": "xa",
        "slug": "nghi-dong",
        "name_with_type": "Xã Nghi Đồng",
        "path": "Nghi Đồng, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Đồng, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17842",
        "parent_code": "429"
    },
    "17845": {
        "name": "Nghi Thiết",
        "type": "xa",
        "slug": "nghi-thiet",
        "name_with_type": "Xã Nghi Thiết",
        "path": "Nghi Thiết, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Thiết, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17845",
        "parent_code": "429"
    },
    "17848": {
        "name": "Nghi Lâm",
        "type": "xa",
        "slug": "nghi-lam",
        "name_with_type": "Xã Nghi Lâm",
        "path": "Nghi Lâm, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Lâm, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17848",
        "parent_code": "429"
    },
    "17851": {
        "name": "Nghi Quang",
        "type": "xa",
        "slug": "nghi-quang",
        "name_with_type": "Xã Nghi Quang",
        "path": "Nghi Quang, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Quang, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17851",
        "parent_code": "429"
    },
    "17854": {
        "name": "Nghi Kiều",
        "type": "xa",
        "slug": "nghi-kieu",
        "name_with_type": "Xã Nghi Kiều",
        "path": "Nghi Kiều, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Kiều, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17854",
        "parent_code": "429"
    },
    "17857": {
        "name": "Nghi Mỹ",
        "type": "xa",
        "slug": "nghi-my",
        "name_with_type": "Xã Nghi Mỹ",
        "path": "Nghi Mỹ, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Mỹ, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17857",
        "parent_code": "429"
    },
    "17860": {
        "name": "Nghi Phương",
        "type": "xa",
        "slug": "nghi-phuong",
        "name_with_type": "Xã Nghi Phương",
        "path": "Nghi Phương, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Phương, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17860",
        "parent_code": "429"
    },
    "17863": {
        "name": "Nghi Thuận",
        "type": "xa",
        "slug": "nghi-thuan",
        "name_with_type": "Xã Nghi Thuận",
        "path": "Nghi Thuận, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Thuận, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17863",
        "parent_code": "429"
    },
    "17866": {
        "name": "Nghi Long",
        "type": "xa",
        "slug": "nghi-long",
        "name_with_type": "Xã Nghi Long",
        "path": "Nghi Long, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Long, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17866",
        "parent_code": "429"
    },
    "17869": {
        "name": "Nghi Xá",
        "type": "xa",
        "slug": "nghi-xa",
        "name_with_type": "Xã Nghi Xá",
        "path": "Nghi Xá, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Xá, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17869",
        "parent_code": "429"
    },
    "17872": {
        "name": "Nghi Hợp",
        "type": "xa",
        "slug": "nghi-hop",
        "name_with_type": "Xã Nghi Hợp",
        "path": "Nghi Hợp, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Hợp, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17872",
        "parent_code": "429"
    },
    "17875": {
        "name": "Nghi Hoa",
        "type": "xa",
        "slug": "nghi-hoa",
        "name_with_type": "Xã Nghi Hoa",
        "path": "Nghi Hoa, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Hoa, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17875",
        "parent_code": "429"
    },
    "17878": {
        "name": "Nghi Khánh",
        "type": "xa",
        "slug": "nghi-khanh",
        "name_with_type": "Xã Nghi Khánh",
        "path": "Nghi Khánh, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Khánh, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17878",
        "parent_code": "429"
    },
    "17881": {
        "name": "Nghi Thịnh",
        "type": "xa",
        "slug": "nghi-thinh",
        "name_with_type": "Xã Nghi Thịnh",
        "path": "Nghi Thịnh, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Thịnh, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17881",
        "parent_code": "429"
    },
    "17884": {
        "name": "Nghi Công Bắc",
        "type": "xa",
        "slug": "nghi-cong-bac",
        "name_with_type": "Xã Nghi Công Bắc",
        "path": "Nghi Công Bắc, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Công Bắc, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17884",
        "parent_code": "429"
    },
    "17887": {
        "name": "Nghi Công Nam",
        "type": "xa",
        "slug": "nghi-cong-nam",
        "name_with_type": "Xã Nghi Công Nam",
        "path": "Nghi Công Nam, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Công Nam, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17887",
        "parent_code": "429"
    },
    "17890": {
        "name": "Nghi Thạch",
        "type": "xa",
        "slug": "nghi-thach",
        "name_with_type": "Xã Nghi Thạch",
        "path": "Nghi Thạch, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Thạch, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17890",
        "parent_code": "429"
    },
    "17893": {
        "name": "Nghi Trung",
        "type": "xa",
        "slug": "nghi-trung",
        "name_with_type": "Xã Nghi Trung",
        "path": "Nghi Trung, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Trung, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17893",
        "parent_code": "429"
    },
    "17896": {
        "name": "Nghi Trường",
        "type": "xa",
        "slug": "nghi-truong",
        "name_with_type": "Xã Nghi Trường",
        "path": "Nghi Trường, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Trường, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17896",
        "parent_code": "429"
    },
    "17899": {
        "name": "Nghi Diên",
        "type": "xa",
        "slug": "nghi-dien",
        "name_with_type": "Xã Nghi Diên",
        "path": "Nghi Diên, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Diên, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17899",
        "parent_code": "429"
    },
    "17902": {
        "name": "Nghi Phong",
        "type": "xa",
        "slug": "nghi-phong",
        "name_with_type": "Xã Nghi Phong",
        "path": "Nghi Phong, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Phong, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17902",
        "parent_code": "429"
    },
    "17905": {
        "name": "Nghi Xuân",
        "type": "xa",
        "slug": "nghi-xuan",
        "name_with_type": "Xã Nghi Xuân",
        "path": "Nghi Xuân, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Xuân, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17905",
        "parent_code": "429"
    },
    "17911": {
        "name": "Nghi Vạn",
        "type": "xa",
        "slug": "nghi-van",
        "name_with_type": "Xã Nghi Vạn",
        "path": "Nghi Vạn, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Vạn, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17911",
        "parent_code": "429"
    },
    "17917": {
        "name": "Phúc Thọ",
        "type": "xa",
        "slug": "phuc-tho",
        "name_with_type": "Xã Phúc Thọ",
        "path": "Phúc Thọ, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Phúc Thọ, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17917",
        "parent_code": "429"
    },
    "17926": {
        "name": "Nghi Thái",
        "type": "xa",
        "slug": "nghi-thai",
        "name_with_type": "Xã Nghi Thái",
        "path": "Nghi Thái, Nghi Lộc, Nghệ An",
        "path_with_type": "Xã Nghi Thái, Huyện Nghi Lộc, Tỉnh Nghệ An",
        "code": "17926",
        "parent_code": "429"
    },
    "17929": {
        "name": "Nam Đàn",
        "type": "thi-tran",
        "slug": "nam-dan",
        "name_with_type": "Thị trấn Nam Đàn",
        "path": "Nam Đàn, Nam Đàn, Nghệ An",
        "path_with_type": "Thị trấn Nam Đàn, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17929",
        "parent_code": "430"
    },
    "17932": {
        "name": "Nam Hưng",
        "type": "xa",
        "slug": "nam-hung",
        "name_with_type": "Xã Nam Hưng",
        "path": "Nam Hưng, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Hưng, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17932",
        "parent_code": "430"
    },
    "17935": {
        "name": "Nam Nghĩa",
        "type": "xa",
        "slug": "nam-nghia",
        "name_with_type": "Xã Nam Nghĩa",
        "path": "Nam Nghĩa, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Nghĩa, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17935",
        "parent_code": "430"
    },
    "17938": {
        "name": "Nam Thanh",
        "type": "xa",
        "slug": "nam-thanh",
        "name_with_type": "Xã Nam Thanh",
        "path": "Nam Thanh, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Thanh, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17938",
        "parent_code": "430"
    },
    "17941": {
        "name": "Nam Anh",
        "type": "xa",
        "slug": "nam-anh",
        "name_with_type": "Xã Nam Anh",
        "path": "Nam Anh, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Anh, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17941",
        "parent_code": "430"
    },
    "17944": {
        "name": "Nam Xuân",
        "type": "xa",
        "slug": "nam-xuan",
        "name_with_type": "Xã Nam Xuân",
        "path": "Nam Xuân, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Xuân, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17944",
        "parent_code": "430"
    },
    "17947": {
        "name": "Nam Thái",
        "type": "xa",
        "slug": "nam-thai",
        "name_with_type": "Xã Nam Thái",
        "path": "Nam Thái, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Thái, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17947",
        "parent_code": "430"
    },
    "17950": {
        "name": "Vân Diên",
        "type": "xa",
        "slug": "van-dien",
        "name_with_type": "Xã Vân Diên",
        "path": "Vân Diên, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Vân Diên, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17950",
        "parent_code": "430"
    },
    "17953": {
        "name": "Nam Lĩnh",
        "type": "xa",
        "slug": "nam-linh",
        "name_with_type": "Xã Nam Lĩnh",
        "path": "Nam Lĩnh, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Lĩnh, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17953",
        "parent_code": "430"
    },
    "17956": {
        "name": "Nam Giang",
        "type": "xa",
        "slug": "nam-giang",
        "name_with_type": "Xã Nam Giang",
        "path": "Nam Giang, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Giang, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17956",
        "parent_code": "430"
    },
    "17959": {
        "name": "Xuân Hòa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hòa",
        "path": "Xuân Hòa, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Xuân Hòa, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17959",
        "parent_code": "430"
    },
    "17962": {
        "name": "Hùng Tiến",
        "type": "xa",
        "slug": "hung-tien",
        "name_with_type": "Xã Hùng Tiến",
        "path": "Hùng Tiến, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Hùng Tiến, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17962",
        "parent_code": "430"
    },
    "17965": {
        "name": "Nam Thượng",
        "type": "xa",
        "slug": "nam-thuong",
        "name_with_type": "Xã Nam Thượng",
        "path": "Nam Thượng, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Thượng, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17965",
        "parent_code": "430"
    },
    "17968": {
        "name": "Nam Tân",
        "type": "xa",
        "slug": "nam-tan",
        "name_with_type": "Xã Nam Tân",
        "path": "Nam Tân, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Tân, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17968",
        "parent_code": "430"
    },
    "17971": {
        "name": "Kim Liên",
        "type": "xa",
        "slug": "kim-lien",
        "name_with_type": "Xã Kim Liên",
        "path": "Kim Liên, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Kim Liên, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17971",
        "parent_code": "430"
    },
    "17974": {
        "name": "Nam Lộc",
        "type": "xa",
        "slug": "nam-loc",
        "name_with_type": "Xã Nam Lộc",
        "path": "Nam Lộc, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Lộc, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17974",
        "parent_code": "430"
    },
    "17977": {
        "name": "Hồng Long",
        "type": "xa",
        "slug": "hong-long",
        "name_with_type": "Xã Hồng Long",
        "path": "Hồng Long, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Hồng Long, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17977",
        "parent_code": "430"
    },
    "17980": {
        "name": "Xuân Lâm",
        "type": "xa",
        "slug": "xuan-lam",
        "name_with_type": "Xã Xuân Lâm",
        "path": "Xuân Lâm, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Xuân Lâm, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17980",
        "parent_code": "430"
    },
    "17983": {
        "name": "Nam Cát",
        "type": "xa",
        "slug": "nam-cat",
        "name_with_type": "Xã Nam Cát",
        "path": "Nam Cát, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Cát, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17983",
        "parent_code": "430"
    },
    "17986": {
        "name": "Khánh Sơn",
        "type": "xa",
        "slug": "khanh-son",
        "name_with_type": "Xã Khánh Sơn",
        "path": "Khánh Sơn, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Khánh Sơn, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17986",
        "parent_code": "430"
    },
    "17989": {
        "name": "Nam Phúc",
        "type": "xa",
        "slug": "nam-phuc",
        "name_with_type": "Xã Nam Phúc",
        "path": "Nam Phúc, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Phúc, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17989",
        "parent_code": "430"
    },
    "17992": {
        "name": "Nam Cường",
        "type": "xa",
        "slug": "nam-cuong",
        "name_with_type": "Xã Nam Cường",
        "path": "Nam Cường, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Cường, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17992",
        "parent_code": "430"
    },
    "17995": {
        "name": "Nam Trung",
        "type": "xa",
        "slug": "nam-trung",
        "name_with_type": "Xã Nam Trung",
        "path": "Nam Trung, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Trung, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17995",
        "parent_code": "430"
    },
    "17998": {
        "name": "Nam Kim",
        "type": "xa",
        "slug": "nam-kim",
        "name_with_type": "Xã Nam Kim",
        "path": "Nam Kim, Nam Đàn, Nghệ An",
        "path_with_type": "Xã Nam Kim, Huyện Nam Đàn, Tỉnh Nghệ An",
        "code": "17998",
        "parent_code": "430"
    },
    "18001": {
        "name": "Hưng Nguyên",
        "type": "thi-tran",
        "slug": "hung-nguyen",
        "name_with_type": "Thị trấn Hưng Nguyên",
        "path": "Hưng Nguyên, Hưng Nguyên, Nghệ An",
        "path_with_type": "Thị trấn Hưng Nguyên, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18001",
        "parent_code": "431"
    },
    "18004": {
        "name": "Hưng Trung",
        "type": "xa",
        "slug": "hung-trung",
        "name_with_type": "Xã Hưng Trung",
        "path": "Hưng Trung, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Trung, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18004",
        "parent_code": "431"
    },
    "18007": {
        "name": "Hưng Yên",
        "type": "xa",
        "slug": "hung-yen",
        "name_with_type": "Xã Hưng Yên",
        "path": "Hưng Yên, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Yên, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18007",
        "parent_code": "431"
    },
    "18008": {
        "name": "Hưng Yên Bắc",
        "type": "xa",
        "slug": "hung-yen-bac",
        "name_with_type": "Xã Hưng Yên Bắc",
        "path": "Hưng Yên Bắc, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Yên Bắc, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18008",
        "parent_code": "431"
    },
    "18010": {
        "name": "Hưng Tây",
        "type": "xa",
        "slug": "hung-tay",
        "name_with_type": "Xã Hưng Tây",
        "path": "Hưng Tây, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Tây, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18010",
        "parent_code": "431"
    },
    "18016": {
        "name": "Hưng Đạo",
        "type": "xa",
        "slug": "hung-dao",
        "name_with_type": "Xã Hưng Đạo",
        "path": "Hưng Đạo, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Đạo, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18016",
        "parent_code": "431"
    },
    "18019": {
        "name": "Hưng Mỹ",
        "type": "xa",
        "slug": "hung-my",
        "name_with_type": "Xã Hưng Mỹ",
        "path": "Hưng Mỹ, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Mỹ, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18019",
        "parent_code": "431"
    },
    "18022": {
        "name": "Hưng Thịnh",
        "type": "xa",
        "slug": "hung-thinh",
        "name_with_type": "Xã Hưng Thịnh",
        "path": "Hưng Thịnh, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Thịnh, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18022",
        "parent_code": "431"
    },
    "18025": {
        "name": "Hưng Lĩnh",
        "type": "xa",
        "slug": "hung-linh",
        "name_with_type": "Xã Hưng Lĩnh",
        "path": "Hưng Lĩnh, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Lĩnh, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18025",
        "parent_code": "431"
    },
    "18028": {
        "name": "Hưng Thông",
        "type": "xa",
        "slug": "hung-thong",
        "name_with_type": "Xã Hưng Thông",
        "path": "Hưng Thông, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Thông, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18028",
        "parent_code": "431"
    },
    "18031": {
        "name": "Hưng Tân",
        "type": "xa",
        "slug": "hung-tan",
        "name_with_type": "Xã Hưng Tân",
        "path": "Hưng Tân, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Tân, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18031",
        "parent_code": "431"
    },
    "18034": {
        "name": "Hưng Lợi",
        "type": "xa",
        "slug": "hung-loi",
        "name_with_type": "Xã Hưng Lợi",
        "path": "Hưng Lợi, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Lợi, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18034",
        "parent_code": "431"
    },
    "18037": {
        "name": "Hưng Thắng",
        "type": "xa",
        "slug": "hung-thang",
        "name_with_type": "Xã Hưng Thắng",
        "path": "Hưng Thắng, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Thắng, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18037",
        "parent_code": "431"
    },
    "18040": {
        "name": "Hưng Phúc",
        "type": "xa",
        "slug": "hung-phuc",
        "name_with_type": "Xã Hưng Phúc",
        "path": "Hưng Phúc, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Phúc, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18040",
        "parent_code": "431"
    },
    "18043": {
        "name": "Hưng Long",
        "type": "xa",
        "slug": "hung-long",
        "name_with_type": "Xã Hưng Long",
        "path": "Hưng Long, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Long, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18043",
        "parent_code": "431"
    },
    "18046": {
        "name": "Hưng Tiến",
        "type": "xa",
        "slug": "hung-tien",
        "name_with_type": "Xã Hưng Tiến",
        "path": "Hưng Tiến, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Tiến, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18046",
        "parent_code": "431"
    },
    "18049": {
        "name": "Hưng Xá",
        "type": "xa",
        "slug": "hung-xa",
        "name_with_type": "Xã Hưng Xá",
        "path": "Hưng Xá, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Xá, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18049",
        "parent_code": "431"
    },
    "18052": {
        "name": "Hưng Châu",
        "type": "xa",
        "slug": "hung-chau",
        "name_with_type": "Xã Hưng Châu",
        "path": "Hưng Châu, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Châu, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18052",
        "parent_code": "431"
    },
    "18055": {
        "name": "Hưng Xuân",
        "type": "xa",
        "slug": "hung-xuan",
        "name_with_type": "Xã Hưng Xuân",
        "path": "Hưng Xuân, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Xuân, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18055",
        "parent_code": "431"
    },
    "18058": {
        "name": "Hưng Nhân",
        "type": "xa",
        "slug": "hung-nhan",
        "name_with_type": "Xã Hưng Nhân",
        "path": "Hưng Nhân, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Nhân, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18058",
        "parent_code": "431"
    },
    "18061": {
        "name": "Hưng Phú",
        "type": "xa",
        "slug": "hung-phu",
        "name_with_type": "Xã Hưng Phú",
        "path": "Hưng Phú, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Phú, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18061",
        "parent_code": "431"
    },
    "18064": {
        "name": "Hưng Khánh",
        "type": "xa",
        "slug": "hung-khanh",
        "name_with_type": "Xã Hưng Khánh",
        "path": "Hưng Khánh, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Khánh, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18064",
        "parent_code": "431"
    },
    "18067": {
        "name": "Hưng Lam",
        "type": "xa",
        "slug": "hung-lam",
        "name_with_type": "Xã Hưng Lam",
        "path": "Hưng Lam, Hưng Nguyên, Nghệ An",
        "path_with_type": "Xã Hưng Lam, Huyện Hưng Nguyên, Tỉnh Nghệ An",
        "code": "18067",
        "parent_code": "431"
    },
    "17104": {
        "name": "Quỳnh Vinh",
        "type": "xa",
        "slug": "quynh-vinh",
        "name_with_type": "Xã Quỳnh Vinh",
        "path": "Quỳnh Vinh, Hoàng Mai, Nghệ An",
        "path_with_type": "Xã Quỳnh Vinh, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17104",
        "parent_code": "432"
    },
    "17107": {
        "name": "Quỳnh Lộc",
        "type": "xa",
        "slug": "quynh-loc",
        "name_with_type": "Xã Quỳnh Lộc",
        "path": "Quỳnh Lộc, Hoàng Mai, Nghệ An",
        "path_with_type": "Xã Quỳnh Lộc, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17107",
        "parent_code": "432"
    },
    "17110": {
        "name": "Quỳnh Thiện",
        "type": "phuong",
        "slug": "quynh-thien",
        "name_with_type": "Phường Quỳnh Thiện",
        "path": "Quỳnh Thiện, Hoàng Mai, Nghệ An",
        "path_with_type": "Phường Quỳnh Thiện, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17110",
        "parent_code": "432"
    },
    "17113": {
        "name": "Quỳnh Lập",
        "type": "xa",
        "slug": "quynh-lap",
        "name_with_type": "Xã Quỳnh Lập",
        "path": "Quỳnh Lập, Hoàng Mai, Nghệ An",
        "path_with_type": "Xã Quỳnh Lập, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17113",
        "parent_code": "432"
    },
    "17116": {
        "name": "Quỳnh Trang",
        "type": "xa",
        "slug": "quynh-trang",
        "name_with_type": "Xã Quỳnh Trang",
        "path": "Quỳnh Trang, Hoàng Mai, Nghệ An",
        "path_with_type": "Xã Quỳnh Trang, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17116",
        "parent_code": "432"
    },
    "17125": {
        "name": "Mai Hùng",
        "type": "phuong",
        "slug": "mai-hung",
        "name_with_type": "Phường Mai Hùng",
        "path": "Mai Hùng, Hoàng Mai, Nghệ An",
        "path_with_type": "Phường Mai Hùng, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17125",
        "parent_code": "432"
    },
    "17128": {
        "name": "Quỳnh Dị",
        "type": "phuong",
        "slug": "quynh-di",
        "name_with_type": "Phường Quỳnh Dị",
        "path": "Quỳnh Dị, Hoàng Mai, Nghệ An",
        "path_with_type": "Phường Quỳnh Dị, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17128",
        "parent_code": "432"
    },
    "17131": {
        "name": "Quỳnh Xuân",
        "type": "phuong",
        "slug": "quynh-xuan",
        "name_with_type": "Phường Quỳnh Xuân",
        "path": "Quỳnh Xuân, Hoàng Mai, Nghệ An",
        "path_with_type": "Phường Quỳnh Xuân, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17131",
        "parent_code": "432"
    },
    "17134": {
        "name": "Quỳnh Phương",
        "type": "phuong",
        "slug": "quynh-phuong",
        "name_with_type": "Phường Quỳnh Phương",
        "path": "Quỳnh Phương, Hoàng Mai, Nghệ An",
        "path_with_type": "Phường Quỳnh Phương, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17134",
        "parent_code": "432"
    },
    "17137": {
        "name": "Quỳnh Liên",
        "type": "xa",
        "slug": "quynh-lien",
        "name_with_type": "Xã Quỳnh Liên",
        "path": "Quỳnh Liên, Hoàng Mai, Nghệ An",
        "path_with_type": "Xã Quỳnh Liên, Thị xã Hoàng Mai, Tỉnh Nghệ An",
        "code": "17137",
        "parent_code": "432"
    },
    "30730": {
        "name": "Vĩnh Thanh Vân",
        "type": "phuong",
        "slug": "vinh-thanh-van",
        "name_with_type": "Phường Vĩnh Thanh Vân",
        "path": "Vĩnh Thanh Vân, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường Vĩnh Thanh Vân, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30730",
        "parent_code": "899"
    },
    "30733": {
        "name": "Vĩnh Thanh",
        "type": "phuong",
        "slug": "vinh-thanh",
        "name_with_type": "Phường Vĩnh Thanh",
        "path": "Vĩnh Thanh, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường Vĩnh Thanh, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30733",
        "parent_code": "899"
    },
    "30736": {
        "name": "Vĩnh Quang",
        "type": "phuong",
        "slug": "vinh-quang",
        "name_with_type": "Phường Vĩnh Quang",
        "path": "Vĩnh Quang, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường Vĩnh Quang, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30736",
        "parent_code": "899"
    },
    "30739": {
        "name": "Vĩnh Hiệp",
        "type": "phuong",
        "slug": "vinh-hiep",
        "name_with_type": "Phường Vĩnh Hiệp",
        "path": "Vĩnh Hiệp, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường Vĩnh Hiệp, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30739",
        "parent_code": "899"
    },
    "30742": {
        "name": "Vĩnh Bảo",
        "type": "phuong",
        "slug": "vinh-bao",
        "name_with_type": "Phường Vĩnh Bảo",
        "path": "Vĩnh Bảo, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường Vĩnh Bảo, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30742",
        "parent_code": "899"
    },
    "30745": {
        "name": "Vĩnh Lạc",
        "type": "phuong",
        "slug": "vinh-lac",
        "name_with_type": "Phường Vĩnh Lạc",
        "path": "Vĩnh Lạc, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường Vĩnh Lạc, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30745",
        "parent_code": "899"
    },
    "30748": {
        "name": "An Hòa",
        "type": "phuong",
        "slug": "an-hoa",
        "name_with_type": "Phường An Hòa",
        "path": "An Hòa, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường An Hòa, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30748",
        "parent_code": "899"
    },
    "30751": {
        "name": "An Bình",
        "type": "phuong",
        "slug": "an-binh",
        "name_with_type": "Phường An Bình",
        "path": "An Bình, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường An Bình, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30751",
        "parent_code": "899"
    },
    "30754": {
        "name": "Rạch Sỏi",
        "type": "phuong",
        "slug": "rach-soi",
        "name_with_type": "Phường Rạch Sỏi",
        "path": "Rạch Sỏi, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường Rạch Sỏi, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30754",
        "parent_code": "899"
    },
    "30757": {
        "name": "Vĩnh Lợi",
        "type": "phuong",
        "slug": "vinh-loi",
        "name_with_type": "Phường Vĩnh Lợi",
        "path": "Vĩnh Lợi, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường Vĩnh Lợi, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30757",
        "parent_code": "899"
    },
    "30760": {
        "name": "Vĩnh Thông",
        "type": "phuong",
        "slug": "vinh-thong",
        "name_with_type": "Phường Vĩnh Thông",
        "path": "Vĩnh Thông, Rạch Giá, Kiên Giang",
        "path_with_type": "Phường Vĩnh Thông, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30760",
        "parent_code": "899"
    },
    "30763": {
        "name": "Phi Thông",
        "type": "xa",
        "slug": "phi-thong",
        "name_with_type": "Xã Phi Thông",
        "path": "Phi Thông, Rạch Giá, Kiên Giang",
        "path_with_type": "Xã Phi Thông, Thành phố Rạch Giá, Tỉnh Kiên Giang",
        "code": "30763",
        "parent_code": "899"
    },
    "30766": {
        "name": "Tô Châu",
        "type": "phuong",
        "slug": "to-chau",
        "name_with_type": "Phường Tô Châu",
        "path": "Tô Châu, Hà Tiên, Kiên Giang",
        "path_with_type": "Phường Tô Châu, Thị xã Hà Tiên, Tỉnh Kiên Giang",
        "code": "30766",
        "parent_code": "900"
    },
    "30769": {
        "name": "Đông Hồ",
        "type": "phuong",
        "slug": "dong-ho",
        "name_with_type": "Phường Đông Hồ",
        "path": "Đông Hồ, Hà Tiên, Kiên Giang",
        "path_with_type": "Phường Đông Hồ, Thị xã Hà Tiên, Tỉnh Kiên Giang",
        "code": "30769",
        "parent_code": "900"
    },
    "30772": {
        "name": "Bình San",
        "type": "phuong",
        "slug": "binh-san",
        "name_with_type": "Phường Bình San",
        "path": "Bình San, Hà Tiên, Kiên Giang",
        "path_with_type": "Phường Bình San, Thị xã Hà Tiên, Tỉnh Kiên Giang",
        "code": "30772",
        "parent_code": "900"
    },
    "30775": {
        "name": "Pháo Đài",
        "type": "phuong",
        "slug": "phao-dai",
        "name_with_type": "Phường Pháo Đài",
        "path": "Pháo Đài, Hà Tiên, Kiên Giang",
        "path_with_type": "Phường Pháo Đài, Thị xã Hà Tiên, Tỉnh Kiên Giang",
        "code": "30775",
        "parent_code": "900"
    },
    "30778": {
        "name": "Mỹ Đức",
        "type": "xa",
        "slug": "my-duc",
        "name_with_type": "Xã Mỹ Đức",
        "path": "Mỹ Đức, Hà Tiên, Kiên Giang",
        "path_with_type": "Xã Mỹ Đức, Thị xã Hà Tiên, Tỉnh Kiên Giang",
        "code": "30778",
        "parent_code": "900"
    },
    "30781": {
        "name": "Tiên Hải",
        "type": "xa",
        "slug": "tien-hai",
        "name_with_type": "Xã Tiên Hải",
        "path": "Tiên Hải, Hà Tiên, Kiên Giang",
        "path_with_type": "Xã Tiên Hải, Thị xã Hà Tiên, Tỉnh Kiên Giang",
        "code": "30781",
        "parent_code": "900"
    },
    "30784": {
        "name": "Thuận Yên",
        "type": "xa",
        "slug": "thuan-yen",
        "name_with_type": "Xã Thuận Yên",
        "path": "Thuận Yên, Hà Tiên, Kiên Giang",
        "path_with_type": "Xã Thuận Yên, Thị xã Hà Tiên, Tỉnh Kiên Giang",
        "code": "30784",
        "parent_code": "900"
    },
    "30787": {
        "name": "Kiên Lương",
        "type": "thi-tran",
        "slug": "kien-luong",
        "name_with_type": "Thị trấn Kiên Lương",
        "path": "Kiên Lương, Kiên Lương, Kiên Giang",
        "path_with_type": "Thị trấn Kiên Lương, Huyện Kiên Lương, Tỉnh Kiên Giang",
        "code": "30787",
        "parent_code": "902"
    },
    "30790": {
        "name": "Kiên Bình",
        "type": "xa",
        "slug": "kien-binh",
        "name_with_type": "Xã Kiên Bình",
        "path": "Kiên Bình, Kiên Lương, Kiên Giang",
        "path_with_type": "Xã Kiên Bình, Huyện Kiên Lương, Tỉnh Kiên Giang",
        "code": "30790",
        "parent_code": "902"
    },
    "30802": {
        "name": "Hòa Điền",
        "type": "xa",
        "slug": "hoa-dien",
        "name_with_type": "Xã Hòa Điền",
        "path": "Hòa Điền, Kiên Lương, Kiên Giang",
        "path_with_type": "Xã Hòa Điền, Huyện Kiên Lương, Tỉnh Kiên Giang",
        "code": "30802",
        "parent_code": "902"
    },
    "30805": {
        "name": "Dương Hòa",
        "type": "xa",
        "slug": "duong-hoa",
        "name_with_type": "Xã Dương Hòa",
        "path": "Dương Hòa, Kiên Lương, Kiên Giang",
        "path_with_type": "Xã Dương Hòa, Huyện Kiên Lương, Tỉnh Kiên Giang",
        "code": "30805",
        "parent_code": "902"
    },
    "30808": {
        "name": "Bình An",
        "type": "xa",
        "slug": "binh-an",
        "name_with_type": "Xã Bình An",
        "path": "Bình An, Kiên Lương, Kiên Giang",
        "path_with_type": "Xã Bình An, Huyện Kiên Lương, Tỉnh Kiên Giang",
        "code": "30808",
        "parent_code": "902"
    },
    "30809": {
        "name": "Bình Trị",
        "type": "xa",
        "slug": "binh-tri",
        "name_with_type": "Xã Bình Trị",
        "path": "Bình Trị, Kiên Lương, Kiên Giang",
        "path_with_type": "Xã Bình Trị, Huyện Kiên Lương, Tỉnh Kiên Giang",
        "code": "30809",
        "parent_code": "902"
    },
    "30811": {
        "name": "Sơn Hải",
        "type": "xa",
        "slug": "son-hai",
        "name_with_type": "Xã Sơn Hải",
        "path": "Sơn Hải, Kiên Lương, Kiên Giang",
        "path_with_type": "Xã Sơn Hải, Huyện Kiên Lương, Tỉnh Kiên Giang",
        "code": "30811",
        "parent_code": "902"
    },
    "30814": {
        "name": "Hòn Nghệ",
        "type": "xa",
        "slug": "hon-nghe",
        "name_with_type": "Xã Hòn Nghệ",
        "path": "Hòn Nghệ, Kiên Lương, Kiên Giang",
        "path_with_type": "Xã Hòn Nghệ, Huyện Kiên Lương, Tỉnh Kiên Giang",
        "code": "30814",
        "parent_code": "902"
    },
    "30817": {
        "name": "Hòn Đất",
        "type": "thi-tran",
        "slug": "hon-dat",
        "name_with_type": "Thị trấn Hòn Đất",
        "path": "Hòn Đất, Hòn Đất, Kiên Giang",
        "path_with_type": "Thị trấn Hòn Đất, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30817",
        "parent_code": "903"
    },
    "30820": {
        "name": "Sóc Sơn",
        "type": "thi-tran",
        "slug": "soc-son",
        "name_with_type": "Thị trấn Sóc Sơn",
        "path": "Sóc Sơn, Hòn Đất, Kiên Giang",
        "path_with_type": "Thị trấn Sóc Sơn, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30820",
        "parent_code": "903"
    },
    "30823": {
        "name": "Bình Sơn",
        "type": "xa",
        "slug": "binh-son",
        "name_with_type": "Xã Bình Sơn",
        "path": "Bình Sơn, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Bình Sơn, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30823",
        "parent_code": "903"
    },
    "30826": {
        "name": "Bình Giang",
        "type": "xa",
        "slug": "binh-giang",
        "name_with_type": "Xã Bình Giang",
        "path": "Bình Giang, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Bình Giang, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30826",
        "parent_code": "903"
    },
    "30828": {
        "name": "Mỹ Thái",
        "type": "xa",
        "slug": "my-thai",
        "name_with_type": "Xã Mỹ Thái",
        "path": "Mỹ Thái, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Mỹ Thái, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30828",
        "parent_code": "903"
    },
    "30829": {
        "name": "Nam Thái Sơn",
        "type": "xa",
        "slug": "nam-thai-son",
        "name_with_type": "Xã Nam Thái Sơn",
        "path": "Nam Thái Sơn, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Nam Thái Sơn, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30829",
        "parent_code": "903"
    },
    "30832": {
        "name": "Mỹ Hiệp Sơn",
        "type": "xa",
        "slug": "my-hiep-son",
        "name_with_type": "Xã Mỹ Hiệp Sơn",
        "path": "Mỹ Hiệp Sơn, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Mỹ Hiệp Sơn, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30832",
        "parent_code": "903"
    },
    "30835": {
        "name": "Sơn Kiên",
        "type": "xa",
        "slug": "son-kien",
        "name_with_type": "Xã Sơn Kiên",
        "path": "Sơn Kiên, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Sơn Kiên, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30835",
        "parent_code": "903"
    },
    "30836": {
        "name": "Sơn Bình",
        "type": "xa",
        "slug": "son-binh",
        "name_with_type": "Xã Sơn Bình",
        "path": "Sơn Bình, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Sơn Bình, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30836",
        "parent_code": "903"
    },
    "30838": {
        "name": "Mỹ Thuận",
        "type": "xa",
        "slug": "my-thuan",
        "name_with_type": "Xã Mỹ Thuận",
        "path": "Mỹ Thuận, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Mỹ Thuận, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30838",
        "parent_code": "903"
    },
    "30840": {
        "name": "Lình Huỳnh",
        "type": "xa",
        "slug": "linh-huynh",
        "name_with_type": "Xã Lình Huỳnh",
        "path": "Lình Huỳnh, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Lình Huỳnh, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30840",
        "parent_code": "903"
    },
    "30841": {
        "name": "Thổ Sơn",
        "type": "xa",
        "slug": "tho-son",
        "name_with_type": "Xã Thổ Sơn",
        "path": "Thổ Sơn, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Thổ Sơn, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30841",
        "parent_code": "903"
    },
    "30844": {
        "name": "Mỹ Lâm",
        "type": "xa",
        "slug": "my-lam",
        "name_with_type": "Xã Mỹ Lâm",
        "path": "Mỹ Lâm, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Mỹ Lâm, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30844",
        "parent_code": "903"
    },
    "30847": {
        "name": "Mỹ Phước",
        "type": "xa",
        "slug": "my-phuoc",
        "name_with_type": "Xã Mỹ Phước",
        "path": "Mỹ Phước, Hòn Đất, Kiên Giang",
        "path_with_type": "Xã Mỹ Phước, Huyện Hòn Đất, Tỉnh Kiên Giang",
        "code": "30847",
        "parent_code": "903"
    },
    "30850": {
        "name": "Tân Hiệp",
        "type": "thi-tran",
        "slug": "tan-hiep",
        "name_with_type": "Thị trấn Tân Hiệp",
        "path": "Tân Hiệp, Tân Hiệp, Kiên Giang",
        "path_with_type": "Thị trấn Tân Hiệp, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30850",
        "parent_code": "904"
    },
    "30853": {
        "name": "Tân Hội",
        "type": "xa",
        "slug": "tan-hoi",
        "name_with_type": "Xã Tân Hội",
        "path": "Tân Hội, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Tân Hội, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30853",
        "parent_code": "904"
    },
    "30856": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Tân Thành, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30856",
        "parent_code": "904"
    },
    "30859": {
        "name": "Tân Hiệp B",
        "type": "xa",
        "slug": "tan-hiep-b",
        "name_with_type": "Xã Tân Hiệp B",
        "path": "Tân Hiệp B, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Tân Hiệp B, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30859",
        "parent_code": "904"
    },
    "30860": {
        "name": "Tân Hoà",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hoà",
        "path": "Tân Hoà, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Tân Hoà, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30860",
        "parent_code": "904"
    },
    "30862": {
        "name": "Thạnh Đông B",
        "type": "xa",
        "slug": "thanh-dong-b",
        "name_with_type": "Xã Thạnh Đông B",
        "path": "Thạnh Đông B, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Thạnh Đông B, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30862",
        "parent_code": "904"
    },
    "30865": {
        "name": "Thạnh Đông",
        "type": "xa",
        "slug": "thanh-dong",
        "name_with_type": "Xã Thạnh Đông",
        "path": "Thạnh Đông, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Thạnh Đông, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30865",
        "parent_code": "904"
    },
    "30868": {
        "name": "Tân Hiệp A",
        "type": "xa",
        "slug": "tan-hiep-a",
        "name_with_type": "Xã Tân Hiệp A",
        "path": "Tân Hiệp A, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Tân Hiệp A, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30868",
        "parent_code": "904"
    },
    "30871": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Tân An, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30871",
        "parent_code": "904"
    },
    "30874": {
        "name": "Thạnh Đông A",
        "type": "xa",
        "slug": "thanh-dong-a",
        "name_with_type": "Xã Thạnh Đông A",
        "path": "Thạnh Đông A, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Thạnh Đông A, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30874",
        "parent_code": "904"
    },
    "30877": {
        "name": "Thạnh Trị",
        "type": "xa",
        "slug": "thanh-tri",
        "name_with_type": "Xã Thạnh Trị",
        "path": "Thạnh Trị, Tân Hiệp, Kiên Giang",
        "path_with_type": "Xã Thạnh Trị, Huyện Tân Hiệp, Tỉnh Kiên Giang",
        "code": "30877",
        "parent_code": "904"
    },
    "30880": {
        "name": "Minh Lương",
        "type": "thi-tran",
        "slug": "minh-luong",
        "name_with_type": "Thị trấn Minh Lương",
        "path": "Minh Lương, Châu Thành, Kiên Giang",
        "path_with_type": "Thị trấn Minh Lương, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30880",
        "parent_code": "905"
    },
    "30883": {
        "name": "Mong Thọ A",
        "type": "xa",
        "slug": "mong-tho-a",
        "name_with_type": "Xã Mong Thọ A",
        "path": "Mong Thọ A, Châu Thành, Kiên Giang",
        "path_with_type": "Xã Mong Thọ A, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30883",
        "parent_code": "905"
    },
    "30886": {
        "name": "Mong Thọ B",
        "type": "xa",
        "slug": "mong-tho-b",
        "name_with_type": "Xã Mong Thọ B",
        "path": "Mong Thọ B, Châu Thành, Kiên Giang",
        "path_with_type": "Xã Mong Thọ B, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30886",
        "parent_code": "905"
    },
    "30887": {
        "name": "Mong Thọ",
        "type": "xa",
        "slug": "mong-tho",
        "name_with_type": "Xã Mong Thọ",
        "path": "Mong Thọ, Châu Thành, Kiên Giang",
        "path_with_type": "Xã Mong Thọ, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30887",
        "parent_code": "905"
    },
    "30889": {
        "name": "Giục Tượng",
        "type": "xa",
        "slug": "giuc-tuong",
        "name_with_type": "Xã Giục Tượng",
        "path": "Giục Tượng, Châu Thành, Kiên Giang",
        "path_with_type": "Xã Giục Tượng, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30889",
        "parent_code": "905"
    },
    "30892": {
        "name": "Vĩnh Hòa Hiệp",
        "type": "xa",
        "slug": "vinh-hoa-hiep",
        "name_with_type": "Xã Vĩnh Hòa Hiệp",
        "path": "Vĩnh Hòa Hiệp, Châu Thành, Kiên Giang",
        "path_with_type": "Xã Vĩnh Hòa Hiệp, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30892",
        "parent_code": "905"
    },
    "30893": {
        "name": "Vĩnh Hoà Phú",
        "type": "xa",
        "slug": "vinh-hoa-phu",
        "name_with_type": "Xã Vĩnh Hoà Phú",
        "path": "Vĩnh Hoà Phú, Châu Thành, Kiên Giang",
        "path_with_type": "Xã Vĩnh Hoà Phú, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30893",
        "parent_code": "905"
    },
    "30895": {
        "name": "Minh Hòa",
        "type": "xa",
        "slug": "minh-hoa",
        "name_with_type": "Xã Minh Hòa",
        "path": "Minh Hòa, Châu Thành, Kiên Giang",
        "path_with_type": "Xã Minh Hòa, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30895",
        "parent_code": "905"
    },
    "30898": {
        "name": "Bình An",
        "type": "xa",
        "slug": "binh-an",
        "name_with_type": "Xã Bình An",
        "path": "Bình An, Châu Thành, Kiên Giang",
        "path_with_type": "Xã Bình An, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30898",
        "parent_code": "905"
    },
    "30901": {
        "name": "Thạnh Lộc",
        "type": "xa",
        "slug": "thanh-loc",
        "name_with_type": "Xã Thạnh Lộc",
        "path": "Thạnh Lộc, Châu Thành, Kiên Giang",
        "path_with_type": "Xã Thạnh Lộc, Huyện Châu Thành, Tỉnh Kiên Giang",
        "code": "30901",
        "parent_code": "905"
    },
    "30904": {
        "name": "Giồng Riềng",
        "type": "thi-tran",
        "slug": "giong-rieng",
        "name_with_type": "Thị Trấn Giồng Riềng",
        "path": "Giồng Riềng, Giồng Riềng, Kiên Giang",
        "path_with_type": "Thị Trấn Giồng Riềng, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30904",
        "parent_code": "906"
    },
    "30907": {
        "name": "Thạnh Hưng",
        "type": "xa",
        "slug": "thanh-hung",
        "name_with_type": "Xã Thạnh Hưng",
        "path": "Thạnh Hưng, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Thạnh Hưng, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30907",
        "parent_code": "906"
    },
    "30910": {
        "name": "Thạnh Phước",
        "type": "xa",
        "slug": "thanh-phuoc",
        "name_with_type": "Xã Thạnh Phước",
        "path": "Thạnh Phước, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Thạnh Phước, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30910",
        "parent_code": "906"
    },
    "30913": {
        "name": "Thạnh Lộc",
        "type": "xa",
        "slug": "thanh-loc",
        "name_with_type": "Xã Thạnh Lộc",
        "path": "Thạnh Lộc, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Thạnh Lộc, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30913",
        "parent_code": "906"
    },
    "30916": {
        "name": "Thạnh Hòa",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thạnh Hòa",
        "path": "Thạnh Hòa, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Thạnh Hòa, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30916",
        "parent_code": "906"
    },
    "30917": {
        "name": "Thạnh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thạnh Bình",
        "path": "Thạnh Bình, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Thạnh Bình, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30917",
        "parent_code": "906"
    },
    "30919": {
        "name": "Bàn Thạch",
        "type": "xa",
        "slug": "ban-thach",
        "name_with_type": "Xã Bàn Thạch",
        "path": "Bàn Thạch, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Bàn Thạch, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30919",
        "parent_code": "906"
    },
    "30922": {
        "name": "Bàn Tân Định",
        "type": "xa",
        "slug": "ban-tan-dinh",
        "name_with_type": "Xã Bàn Tân Định",
        "path": "Bàn Tân Định, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Bàn Tân Định, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30922",
        "parent_code": "906"
    },
    "30925": {
        "name": "Ngọc Thành",
        "type": "xa",
        "slug": "ngoc-thanh",
        "name_with_type": "Xã Ngọc Thành",
        "path": "Ngọc Thành, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Ngọc Thành, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30925",
        "parent_code": "906"
    },
    "30928": {
        "name": "Ngọc Chúc",
        "type": "xa",
        "slug": "ngoc-chuc",
        "name_with_type": "Xã Ngọc Chúc",
        "path": "Ngọc Chúc, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Ngọc Chúc, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30928",
        "parent_code": "906"
    },
    "30931": {
        "name": "Ngọc Thuận",
        "type": "xa",
        "slug": "ngoc-thuan",
        "name_with_type": "Xã Ngọc Thuận",
        "path": "Ngọc Thuận, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Ngọc Thuận, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30931",
        "parent_code": "906"
    },
    "30934": {
        "name": "Hòa Hưng",
        "type": "xa",
        "slug": "hoa-hung",
        "name_with_type": "Xã Hòa Hưng",
        "path": "Hòa Hưng, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Hòa Hưng, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30934",
        "parent_code": "906"
    },
    "30937": {
        "name": "Hoà Lợi",
        "type": "xa",
        "slug": "hoa-loi",
        "name_with_type": "Xã Hoà Lợi",
        "path": "Hoà Lợi, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Hoà Lợi, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30937",
        "parent_code": "906"
    },
    "30940": {
        "name": "Hoà An",
        "type": "xa",
        "slug": "hoa-an",
        "name_with_type": "Xã Hoà An",
        "path": "Hoà An, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Hoà An, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30940",
        "parent_code": "906"
    },
    "30943": {
        "name": "Long Thạnh",
        "type": "xa",
        "slug": "long-thanh",
        "name_with_type": "Xã Long Thạnh",
        "path": "Long Thạnh, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Long Thạnh, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30943",
        "parent_code": "906"
    },
    "30946": {
        "name": "Vĩnh Thạnh",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thạnh",
        "path": "Vĩnh Thạnh, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Vĩnh Thạnh, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30946",
        "parent_code": "906"
    },
    "30947": {
        "name": "Vĩnh Phú",
        "type": "xa",
        "slug": "vinh-phu",
        "name_with_type": "Xã Vĩnh Phú",
        "path": "Vĩnh Phú, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Vĩnh Phú, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30947",
        "parent_code": "906"
    },
    "30949": {
        "name": "Hòa Thuận",
        "type": "xa",
        "slug": "hoa-thuan",
        "name_with_type": "Xã  Hòa Thuận",
        "path": "Hòa Thuận, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã  Hòa Thuận, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30949",
        "parent_code": "906"
    },
    "30950": {
        "name": "Ngọc Hoà",
        "type": "xa",
        "slug": "ngoc-hoa",
        "name_with_type": "Xã Ngọc Hoà",
        "path": "Ngọc Hoà, Giồng Riềng, Kiên Giang",
        "path_with_type": "Xã Ngọc Hoà, Huyện Giồng Riềng, Tỉnh Kiên Giang",
        "code": "30950",
        "parent_code": "906"
    },
    "30952": {
        "name": "Gò Quao",
        "type": "thi-tran",
        "slug": "go-quao",
        "name_with_type": "Thị trấn Gò Quao",
        "path": "Gò Quao, Gò Quao, Kiên Giang",
        "path_with_type": "Thị trấn Gò Quao, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30952",
        "parent_code": "907"
    },
    "30955": {
        "name": "Vĩnh Hòa Hưng Bắc",
        "type": "xa",
        "slug": "vinh-hoa-hung-bac",
        "name_with_type": "Xã Vĩnh Hòa Hưng Bắc",
        "path": "Vĩnh Hòa Hưng Bắc, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Vĩnh Hòa Hưng Bắc, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30955",
        "parent_code": "907"
    },
    "30958": {
        "name": "Định Hòa",
        "type": "xa",
        "slug": "dinh-hoa",
        "name_with_type": "Xã Định Hòa",
        "path": "Định Hòa, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Định Hòa, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30958",
        "parent_code": "907"
    },
    "30961": {
        "name": "Thới Quản",
        "type": "xa",
        "slug": "thoi-quan",
        "name_with_type": "Xã Thới Quản",
        "path": "Thới Quản, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Thới Quản, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30961",
        "parent_code": "907"
    },
    "30964": {
        "name": "Định An",
        "type": "xa",
        "slug": "dinh-an",
        "name_with_type": "Xã Định An",
        "path": "Định An, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Định An, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30964",
        "parent_code": "907"
    },
    "30967": {
        "name": "Thủy Liễu",
        "type": "xa",
        "slug": "thuy-lieu",
        "name_with_type": "Xã Thủy Liễu",
        "path": "Thủy Liễu, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Thủy Liễu, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30967",
        "parent_code": "907"
    },
    "30970": {
        "name": "Vĩnh Hòa Hưng Nam",
        "type": "xa",
        "slug": "vinh-hoa-hung-nam",
        "name_with_type": "Xã Vĩnh Hòa Hưng Nam",
        "path": "Vĩnh Hòa Hưng Nam, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Vĩnh Hòa Hưng Nam, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30970",
        "parent_code": "907"
    },
    "30973": {
        "name": "Vĩnh Phước A",
        "type": "xa",
        "slug": "vinh-phuoc-a",
        "name_with_type": "Xã Vĩnh Phước A",
        "path": "Vĩnh Phước A, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Vĩnh Phước A, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30973",
        "parent_code": "907"
    },
    "30976": {
        "name": "Vĩnh Phước B",
        "type": "xa",
        "slug": "vinh-phuoc-b",
        "name_with_type": "Xã Vĩnh Phước B",
        "path": "Vĩnh Phước B, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Vĩnh Phước B, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30976",
        "parent_code": "907"
    },
    "30979": {
        "name": "Vĩnh Tuy",
        "type": "xa",
        "slug": "vinh-tuy",
        "name_with_type": "Xã Vĩnh Tuy",
        "path": "Vĩnh Tuy, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Vĩnh Tuy, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30979",
        "parent_code": "907"
    },
    "30982": {
        "name": "Vĩnh Thắng",
        "type": "xa",
        "slug": "vinh-thang",
        "name_with_type": "Xã Vĩnh Thắng",
        "path": "Vĩnh Thắng, Gò Quao, Kiên Giang",
        "path_with_type": "Xã Vĩnh Thắng, Huyện Gò Quao, Tỉnh Kiên Giang",
        "code": "30982",
        "parent_code": "907"
    },
    "30985": {
        "name": "Thứ Ba",
        "type": "thi-tran",
        "slug": "thu-ba",
        "name_with_type": "Thị trấn Thứ Ba",
        "path": "Thứ Ba, An Biên, Kiên Giang",
        "path_with_type": "Thị trấn Thứ Ba, Huyện An Biên, Tỉnh Kiên Giang",
        "code": "30985",
        "parent_code": "908"
    },
    "30988": {
        "name": "Tây Yên",
        "type": "xa",
        "slug": "tay-yen",
        "name_with_type": "Xã Tây Yên",
        "path": "Tây Yên, An Biên, Kiên Giang",
        "path_with_type": "Xã Tây Yên, Huyện An Biên, Tỉnh Kiên Giang",
        "code": "30988",
        "parent_code": "908"
    },
    "30991": {
        "name": "Tây Yên A",
        "type": "xa",
        "slug": "tay-yen-a",
        "name_with_type": "Xã Tây Yên A",
        "path": "Tây Yên A, An Biên, Kiên Giang",
        "path_with_type": "Xã Tây Yên A, Huyện An Biên, Tỉnh Kiên Giang",
        "code": "30991",
        "parent_code": "908"
    },
    "30994": {
        "name": "Nam Yên",
        "type": "xa",
        "slug": "nam-yen",
        "name_with_type": "Xã Nam Yên",
        "path": "Nam Yên, An Biên, Kiên Giang",
        "path_with_type": "Xã Nam Yên, Huyện An Biên, Tỉnh Kiên Giang",
        "code": "30994",
        "parent_code": "908"
    },
    "30997": {
        "name": "Hưng Yên",
        "type": "xa",
        "slug": "hung-yen",
        "name_with_type": "Xã Hưng Yên",
        "path": "Hưng Yên, An Biên, Kiên Giang",
        "path_with_type": "Xã Hưng Yên, Huyện An Biên, Tỉnh Kiên Giang",
        "code": "30997",
        "parent_code": "908"
    },
    "31000": {
        "name": "Nam Thái",
        "type": "xa",
        "slug": "nam-thai",
        "name_with_type": "Xã Nam Thái",
        "path": "Nam Thái, An Biên, Kiên Giang",
        "path_with_type": "Xã Nam Thái, Huyện An Biên, Tỉnh Kiên Giang",
        "code": "31000",
        "parent_code": "908"
    },
    "31003": {
        "name": "Nam Thái A",
        "type": "xa",
        "slug": "nam-thai-a",
        "name_with_type": "Xã Nam Thái A",
        "path": "Nam Thái A, An Biên, Kiên Giang",
        "path_with_type": "Xã Nam Thái A, Huyện An Biên, Tỉnh Kiên Giang",
        "code": "31003",
        "parent_code": "908"
    },
    "31006": {
        "name": "Đông Thái",
        "type": "xa",
        "slug": "dong-thai",
        "name_with_type": "Xã Đông Thái",
        "path": "Đông Thái, An Biên, Kiên Giang",
        "path_with_type": "Xã Đông Thái, Huyện An Biên, Tỉnh Kiên Giang",
        "code": "31006",
        "parent_code": "908"
    },
    "31009": {
        "name": "Đông Yên",
        "type": "xa",
        "slug": "dong-yen",
        "name_with_type": "Xã Đông Yên",
        "path": "Đông Yên, An Biên, Kiên Giang",
        "path_with_type": "Xã Đông Yên, Huyện An Biên, Tỉnh Kiên Giang",
        "code": "31009",
        "parent_code": "908"
    },
    "31018": {
        "name": "Thứ Mười Một",
        "type": "thi-tran",
        "slug": "thu-muoi-mot",
        "name_with_type": "Thị trấn Thứ Mười Một",
        "path": "Thứ Mười Một, An Minh, Kiên Giang",
        "path_with_type": "Thị trấn Thứ Mười Một, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31018",
        "parent_code": "909"
    },
    "31021": {
        "name": "Thuận Hoà",
        "type": "xa",
        "slug": "thuan-hoa",
        "name_with_type": "Xã Thuận Hoà",
        "path": "Thuận Hoà, An Minh, Kiên Giang",
        "path_with_type": "Xã Thuận Hoà, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31021",
        "parent_code": "909"
    },
    "31024": {
        "name": "Đông Hòa",
        "type": "xa",
        "slug": "dong-hoa",
        "name_with_type": "Xã Đông Hòa",
        "path": "Đông Hòa, An Minh, Kiên Giang",
        "path_with_type": "Xã Đông Hòa, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31024",
        "parent_code": "909"
    },
    "31030": {
        "name": "Đông Thạnh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thạnh",
        "path": "Đông Thạnh, An Minh, Kiên Giang",
        "path_with_type": "Xã Đông Thạnh, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31030",
        "parent_code": "909"
    },
    "31031": {
        "name": "Tân Thạnh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thạnh",
        "path": "Tân Thạnh, An Minh, Kiên Giang",
        "path_with_type": "Xã Tân Thạnh, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31031",
        "parent_code": "909"
    },
    "31033": {
        "name": "Đông Hưng",
        "type": "xa",
        "slug": "dong-hung",
        "name_with_type": "Xã Đông Hưng",
        "path": "Đông Hưng, An Minh, Kiên Giang",
        "path_with_type": "Xã Đông Hưng, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31033",
        "parent_code": "909"
    },
    "31036": {
        "name": "Đông Hưng A",
        "type": "xa",
        "slug": "dong-hung-a",
        "name_with_type": "Xã Đông Hưng A",
        "path": "Đông Hưng A, An Minh, Kiên Giang",
        "path_with_type": "Xã Đông Hưng A, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31036",
        "parent_code": "909"
    },
    "31039": {
        "name": "Đông Hưng B",
        "type": "xa",
        "slug": "dong-hung-b",
        "name_with_type": "Xã Đông Hưng B",
        "path": "Đông Hưng B, An Minh, Kiên Giang",
        "path_with_type": "Xã Đông Hưng B, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31039",
        "parent_code": "909"
    },
    "31042": {
        "name": "Vân Khánh",
        "type": "xa",
        "slug": "van-khanh",
        "name_with_type": "Xã Vân Khánh",
        "path": "Vân Khánh, An Minh, Kiên Giang",
        "path_with_type": "Xã Vân Khánh, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31042",
        "parent_code": "909"
    },
    "31045": {
        "name": "Vân Khánh Đông",
        "type": "xa",
        "slug": "van-khanh-dong",
        "name_with_type": "Xã Vân Khánh Đông",
        "path": "Vân Khánh Đông, An Minh, Kiên Giang",
        "path_with_type": "Xã Vân Khánh Đông, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31045",
        "parent_code": "909"
    },
    "31048": {
        "name": "Vân Khánh Tây",
        "type": "xa",
        "slug": "van-khanh-tay",
        "name_with_type": "Xã Vân Khánh Tây",
        "path": "Vân Khánh Tây, An Minh, Kiên Giang",
        "path_with_type": "Xã Vân Khánh Tây, Huyện An Minh, Tỉnh Kiên Giang",
        "code": "31048",
        "parent_code": "909"
    },
    "31051": {
        "name": "Vĩnh Thuận",
        "type": "thi-tran",
        "slug": "vinh-thuan",
        "name_with_type": "Thị trấn Vĩnh Thuận",
        "path": "Vĩnh Thuận, Vĩnh Thuận, Kiên Giang",
        "path_with_type": "Thị trấn Vĩnh Thuận, Huyện Vĩnh Thuận, Tỉnh Kiên Giang",
        "code": "31051",
        "parent_code": "910"
    },
    "31060": {
        "name": "Vĩnh Bình Bắc",
        "type": "xa",
        "slug": "vinh-binh-bac",
        "name_with_type": "Xã Vĩnh Bình Bắc",
        "path": "Vĩnh Bình Bắc, Vĩnh Thuận, Kiên Giang",
        "path_with_type": "Xã Vĩnh Bình Bắc, Huyện Vĩnh Thuận, Tỉnh Kiên Giang",
        "code": "31060",
        "parent_code": "910"
    },
    "31063": {
        "name": "Vĩnh Bình Nam",
        "type": "xa",
        "slug": "vinh-binh-nam",
        "name_with_type": "Xã Vĩnh Bình Nam",
        "path": "Vĩnh Bình Nam, Vĩnh Thuận, Kiên Giang",
        "path_with_type": "Xã Vĩnh Bình Nam, Huyện Vĩnh Thuận, Tỉnh Kiên Giang",
        "code": "31063",
        "parent_code": "910"
    },
    "31064": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Vĩnh Thuận, Kiên Giang",
        "path_with_type": "Xã Bình Minh, Huyện Vĩnh Thuận, Tỉnh Kiên Giang",
        "code": "31064",
        "parent_code": "910"
    },
    "31069": {
        "name": "Vĩnh Thuận",
        "type": "xa",
        "slug": "vinh-thuan",
        "name_with_type": "Xã Vĩnh Thuận",
        "path": "Vĩnh Thuận, Vĩnh Thuận, Kiên Giang",
        "path_with_type": "Xã Vĩnh Thuận, Huyện Vĩnh Thuận, Tỉnh Kiên Giang",
        "code": "31069",
        "parent_code": "910"
    },
    "31072": {
        "name": "Tân Thuận",
        "type": "xa",
        "slug": "tan-thuan",
        "name_with_type": "Xã Tân Thuận",
        "path": "Tân Thuận, Vĩnh Thuận, Kiên Giang",
        "path_with_type": "Xã Tân Thuận, Huyện Vĩnh Thuận, Tỉnh Kiên Giang",
        "code": "31072",
        "parent_code": "910"
    },
    "31074": {
        "name": "Phong Đông",
        "type": "xa",
        "slug": "phong-dong",
        "name_with_type": "Xã Phong Đông",
        "path": "Phong Đông, Vĩnh Thuận, Kiên Giang",
        "path_with_type": "Xã Phong Đông, Huyện Vĩnh Thuận, Tỉnh Kiên Giang",
        "code": "31074",
        "parent_code": "910"
    },
    "31075": {
        "name": "Vĩnh Phong",
        "type": "xa",
        "slug": "vinh-phong",
        "name_with_type": "Xã Vĩnh Phong",
        "path": "Vĩnh Phong, Vĩnh Thuận, Kiên Giang",
        "path_with_type": "Xã Vĩnh Phong, Huyện Vĩnh Thuận, Tỉnh Kiên Giang",
        "code": "31075",
        "parent_code": "910"
    },
    "31078": {
        "name": "Dương Đông",
        "type": "thi-tran",
        "slug": "duong-dong",
        "name_with_type": "Thị trấn Dương Đông",
        "path": "Dương Đông, Phú Quốc, Kiên Giang",
        "path_with_type": "Thị trấn Dương Đông, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31078",
        "parent_code": "911"
    },
    "31081": {
        "name": "An Thới",
        "type": "thi-tran",
        "slug": "an-thoi",
        "name_with_type": "Thị trấn An Thới",
        "path": "An Thới, Phú Quốc, Kiên Giang",
        "path_with_type": "Thị trấn An Thới, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31081",
        "parent_code": "911"
    },
    "31084": {
        "name": "Cửa Cạn",
        "type": "xa",
        "slug": "cua-can",
        "name_with_type": "Xã Cửa Cạn",
        "path": "Cửa Cạn, Phú Quốc, Kiên Giang",
        "path_with_type": "Xã Cửa Cạn, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31084",
        "parent_code": "911"
    },
    "31087": {
        "name": "Gành Dầu",
        "type": "xa",
        "slug": "ganh-dau",
        "name_with_type": "Xã Gành Dầu",
        "path": "Gành Dầu, Phú Quốc, Kiên Giang",
        "path_with_type": "Xã Gành Dầu, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31087",
        "parent_code": "911"
    },
    "31090": {
        "name": "Cửa Dương",
        "type": "xa",
        "slug": "cua-duong",
        "name_with_type": "Xã Cửa Dương",
        "path": "Cửa Dương, Phú Quốc, Kiên Giang",
        "path_with_type": "Xã Cửa Dương, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31090",
        "parent_code": "911"
    },
    "31093": {
        "name": "Hàm Ninh",
        "type": "xa",
        "slug": "ham-ninh",
        "name_with_type": "Xã Hàm Ninh",
        "path": "Hàm Ninh, Phú Quốc, Kiên Giang",
        "path_with_type": "Xã Hàm Ninh, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31093",
        "parent_code": "911"
    },
    "31096": {
        "name": "Dương Tơ",
        "type": "xa",
        "slug": "duong-to",
        "name_with_type": "Xã Dương Tơ",
        "path": "Dương Tơ, Phú Quốc, Kiên Giang",
        "path_with_type": "Xã Dương Tơ, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31096",
        "parent_code": "911"
    },
    "31099": {
        "name": "Hòn Thơm",
        "type": "xa",
        "slug": "hon-thom",
        "name_with_type": "Xã Hòn Thơm",
        "path": "Hòn Thơm, Phú Quốc, Kiên Giang",
        "path_with_type": "Xã Hòn Thơm, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31099",
        "parent_code": "911"
    },
    "31102": {
        "name": "Bãi Thơm",
        "type": "xa",
        "slug": "bai-thom",
        "name_with_type": "Xã Bãi Thơm",
        "path": "Bãi Thơm, Phú Quốc, Kiên Giang",
        "path_with_type": "Xã Bãi Thơm, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31102",
        "parent_code": "911"
    },
    "31105": {
        "name": "Thổ Châu",
        "type": "xa",
        "slug": "tho-chau",
        "name_with_type": "Xã Thổ Châu",
        "path": "Thổ Châu, Phú Quốc, Kiên Giang",
        "path_with_type": "Xã Thổ Châu, Huyện Phú Quốc, Tỉnh Kiên Giang",
        "code": "31105",
        "parent_code": "911"
    },
    "31108": {
        "name": "Hòn Tre",
        "type": "xa",
        "slug": "hon-tre",
        "name_with_type": "Xã Hòn Tre",
        "path": "Hòn Tre, Kiên Hải, Kiên Giang",
        "path_with_type": "Xã Hòn Tre, Huyện Kiên Hải, Tỉnh Kiên Giang",
        "code": "31108",
        "parent_code": "912"
    },
    "31111": {
        "name": "Lại Sơn",
        "type": "xa",
        "slug": "lai-son",
        "name_with_type": "Xã Lại Sơn",
        "path": "Lại Sơn, Kiên Hải, Kiên Giang",
        "path_with_type": "Xã Lại Sơn, Huyện Kiên Hải, Tỉnh Kiên Giang",
        "code": "31111",
        "parent_code": "912"
    },
    "31114": {
        "name": "An Sơn",
        "type": "xa",
        "slug": "an-son",
        "name_with_type": "Xã An Sơn",
        "path": "An Sơn, Kiên Hải, Kiên Giang",
        "path_with_type": "Xã An Sơn, Huyện Kiên Hải, Tỉnh Kiên Giang",
        "code": "31114",
        "parent_code": "912"
    },
    "31115": {
        "name": "Nam Du",
        "type": "xa",
        "slug": "nam-du",
        "name_with_type": "Xã Nam Du",
        "path": "Nam Du, Kiên Hải, Kiên Giang",
        "path_with_type": "Xã Nam Du, Huyện Kiên Hải, Tỉnh Kiên Giang",
        "code": "31115",
        "parent_code": "912"
    },
    "31012": {
        "name": "Thạnh Yên",
        "type": "xa",
        "slug": "thanh-yen",
        "name_with_type": "Xã Thạnh Yên",
        "path": "Thạnh Yên, U Minh Thượng, Kiên Giang",
        "path_with_type": "Xã Thạnh Yên, Huyện U Minh Thượng, Tỉnh Kiên Giang",
        "code": "31012",
        "parent_code": "913"
    },
    "31015": {
        "name": "Thạnh Yên A",
        "type": "xa",
        "slug": "thanh-yen-a",
        "name_with_type": "Xã Thạnh Yên A",
        "path": "Thạnh Yên A, U Minh Thượng, Kiên Giang",
        "path_with_type": "Xã Thạnh Yên A, Huyện U Minh Thượng, Tỉnh Kiên Giang",
        "code": "31015",
        "parent_code": "913"
    },
    "31027": {
        "name": "An Minh Bắc",
        "type": "xa",
        "slug": "an-minh-bac",
        "name_with_type": "Xã An Minh Bắc",
        "path": "An Minh Bắc, U Minh Thượng, Kiên Giang",
        "path_with_type": "Xã An Minh Bắc, Huyện U Minh Thượng, Tỉnh Kiên Giang",
        "code": "31027",
        "parent_code": "913"
    },
    "31054": {
        "name": "Vĩnh Hòa",
        "type": "xa",
        "slug": "vinh-hoa",
        "name_with_type": "Xã Vĩnh Hòa",
        "path": "Vĩnh Hòa, U Minh Thượng, Kiên Giang",
        "path_with_type": "Xã Vĩnh Hòa, Huyện U Minh Thượng, Tỉnh Kiên Giang",
        "code": "31054",
        "parent_code": "913"
    },
    "31057": {
        "name": "Hoà Chánh",
        "type": "xa",
        "slug": "hoa-chanh",
        "name_with_type": "Xã Hoà Chánh",
        "path": "Hoà Chánh, U Minh Thượng, Kiên Giang",
        "path_with_type": "Xã Hoà Chánh, Huyện U Minh Thượng, Tỉnh Kiên Giang",
        "code": "31057",
        "parent_code": "913"
    },
    "31066": {
        "name": "Minh Thuận",
        "type": "xa",
        "slug": "minh-thuan",
        "name_with_type": "Xã Minh Thuận",
        "path": "Minh Thuận, U Minh Thượng, Kiên Giang",
        "path_with_type": "Xã Minh Thuận, Huyện U Minh Thượng, Tỉnh Kiên Giang",
        "code": "31066",
        "parent_code": "913"
    },
    "30791": {
        "name": "Vĩnh Phú",
        "type": "xa",
        "slug": "vinh-phu",
        "name_with_type": "Xã Vĩnh Phú",
        "path": "Vĩnh Phú, Giang Thành, Kiên Giang",
        "path_with_type": "Xã Vĩnh Phú, Huyện Giang Thành, Tỉnh Kiên Giang",
        "code": "30791",
        "parent_code": "914"
    },
    "30793": {
        "name": "Vĩnh Điều",
        "type": "xa",
        "slug": "vinh-dieu",
        "name_with_type": "Xã Vĩnh Điều",
        "path": "Vĩnh Điều, Giang Thành, Kiên Giang",
        "path_with_type": "Xã Vĩnh Điều, Huyện Giang Thành, Tỉnh Kiên Giang",
        "code": "30793",
        "parent_code": "914"
    },
    "30796": {
        "name": "Tân Khánh Hòa",
        "type": "xa",
        "slug": "tan-khanh-hoa",
        "name_with_type": "Xã Tân Khánh Hòa",
        "path": "Tân Khánh Hòa, Giang Thành, Kiên Giang",
        "path_with_type": "Xã Tân Khánh Hòa, Huyện Giang Thành, Tỉnh Kiên Giang",
        "code": "30796",
        "parent_code": "914"
    },
    "30797": {
        "name": "Phú Lợi",
        "type": "xa",
        "slug": "phu-loi",
        "name_with_type": "Xã Phú Lợi",
        "path": "Phú Lợi, Giang Thành, Kiên Giang",
        "path_with_type": "Xã Phú Lợi, Huyện Giang Thành, Tỉnh Kiên Giang",
        "code": "30797",
        "parent_code": "914"
    },
    "30799": {
        "name": "Phú Mỹ",
        "type": "xa",
        "slug": "phu-my",
        "name_with_type": "Xã Phú Mỹ",
        "path": "Phú Mỹ, Giang Thành, Kiên Giang",
        "path_with_type": "Xã Phú Mỹ, Huyện Giang Thành, Tỉnh Kiên Giang",
        "code": "30799",
        "parent_code": "914"
    },
    "00688": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Hà Giang, Hà Giang",
        "path_with_type": "Phường Quang Trung, Thành phố Hà Giang, Tỉnh Hà Giang",
        "code": "00688",
        "parent_code": "024"
    },
    "00691": {
        "name": "Trần Phú",
        "type": "phuong",
        "slug": "tran-phu",
        "name_with_type": "Phường Trần Phú",
        "path": "Trần Phú, Hà Giang, Hà Giang",
        "path_with_type": "Phường Trần Phú, Thành phố Hà Giang, Tỉnh Hà Giang",
        "code": "00691",
        "parent_code": "024"
    },
    "00692": {
        "name": "Ngọc Hà",
        "type": "phuong",
        "slug": "ngoc-ha",
        "name_with_type": "Phường Ngọc Hà",
        "path": "Ngọc Hà, Hà Giang, Hà Giang",
        "path_with_type": "Phường Ngọc Hà, Thành phố Hà Giang, Tỉnh Hà Giang",
        "code": "00692",
        "parent_code": "024"
    },
    "00694": {
        "name": "Nguyễn Trãi",
        "type": "phuong",
        "slug": "nguyen-trai",
        "name_with_type": "Phường Nguyễn Trãi",
        "path": "Nguyễn Trãi, Hà Giang, Hà Giang",
        "path_with_type": "Phường Nguyễn Trãi, Thành phố Hà Giang, Tỉnh Hà Giang",
        "code": "00694",
        "parent_code": "024"
    },
    "00697": {
        "name": "Minh Khai",
        "type": "phuong",
        "slug": "minh-khai",
        "name_with_type": "Phường Minh Khai",
        "path": "Minh Khai, Hà Giang, Hà Giang",
        "path_with_type": "Phường Minh Khai, Thành phố Hà Giang, Tỉnh Hà Giang",
        "code": "00697",
        "parent_code": "024"
    },
    "00700": {
        "name": "Ngọc Đường",
        "type": "xa",
        "slug": "ngoc-duong",
        "name_with_type": "Xã Ngọc Đường",
        "path": "Ngọc Đường, Hà Giang, Hà Giang",
        "path_with_type": "Xã Ngọc Đường, Thành phố Hà Giang, Tỉnh Hà Giang",
        "code": "00700",
        "parent_code": "024"
    },
    "00946": {
        "name": "Phương Độ",
        "type": "xa",
        "slug": "phuong-do",
        "name_with_type": "Xã Phương Độ",
        "path": "Phương Độ, Hà Giang, Hà Giang",
        "path_with_type": "Xã Phương Độ, Thành phố Hà Giang, Tỉnh Hà Giang",
        "code": "00946",
        "parent_code": "024"
    },
    "00949": {
        "name": "Phương Thiện",
        "type": "xa",
        "slug": "phuong-thien",
        "name_with_type": "Xã Phương Thiện",
        "path": "Phương Thiện, Hà Giang, Hà Giang",
        "path_with_type": "Xã Phương Thiện, Thành phố Hà Giang, Tỉnh Hà Giang",
        "code": "00949",
        "parent_code": "024"
    },
    "00712": {
        "name": "Phó Bảng",
        "type": "thi-tran",
        "slug": "pho-bang",
        "name_with_type": "Thị trấn Phó Bảng",
        "path": "Phó Bảng, Đồng Văn, Hà Giang",
        "path_with_type": "Thị trấn Phó Bảng, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00712",
        "parent_code": "026"
    },
    "00715": {
        "name": "Lũng Cú",
        "type": "xa",
        "slug": "lung-cu",
        "name_with_type": "Xã Lũng Cú",
        "path": "Lũng Cú, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Lũng Cú, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00715",
        "parent_code": "026"
    },
    "00718": {
        "name": "Má Lé",
        "type": "xa",
        "slug": "ma-le",
        "name_with_type": "Xã Má Lé",
        "path": "Má Lé, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Má Lé, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00718",
        "parent_code": "026"
    },
    "00721": {
        "name": "Đồng Văn",
        "type": "thi-tran",
        "slug": "dong-van",
        "name_with_type": "Thị trấn Đồng Văn",
        "path": "Đồng Văn, Đồng Văn, Hà Giang",
        "path_with_type": "Thị trấn Đồng Văn, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00721",
        "parent_code": "026"
    },
    "00724": {
        "name": "Lũng Táo",
        "type": "xa",
        "slug": "lung-tao",
        "name_with_type": "Xã Lũng Táo",
        "path": "Lũng Táo, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Lũng Táo, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00724",
        "parent_code": "026"
    },
    "00727": {
        "name": "Phố Là",
        "type": "xa",
        "slug": "pho-la",
        "name_with_type": "Xã Phố Là",
        "path": "Phố Là, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Phố Là, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00727",
        "parent_code": "026"
    },
    "00730": {
        "name": "Thài Phìn Tủng",
        "type": "xa",
        "slug": "thai-phin-tung",
        "name_with_type": "Xã Thài Phìn Tủng",
        "path": "Thài Phìn Tủng, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Thài Phìn Tủng, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00730",
        "parent_code": "026"
    },
    "00733": {
        "name": "Sủng Là",
        "type": "xa",
        "slug": "sung-la",
        "name_with_type": "Xã Sủng Là",
        "path": "Sủng Là, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Sủng Là, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00733",
        "parent_code": "026"
    },
    "00736": {
        "name": "Xà Phìn",
        "type": "xa",
        "slug": "xa-phin",
        "name_with_type": "Xã Xà Phìn",
        "path": "Xà Phìn, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Xà Phìn, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00736",
        "parent_code": "026"
    },
    "00739": {
        "name": "Tả Phìn",
        "type": "xa",
        "slug": "ta-phin",
        "name_with_type": "Xã Tả Phìn",
        "path": "Tả Phìn, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Tả Phìn, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00739",
        "parent_code": "026"
    },
    "00742": {
        "name": "Tả Lủng",
        "type": "xa",
        "slug": "ta-lung",
        "name_with_type": "Xã Tả Lủng",
        "path": "Tả Lủng, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Tả Lủng, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00742",
        "parent_code": "026"
    },
    "00745": {
        "name": "Phố Cáo",
        "type": "xa",
        "slug": "pho-cao",
        "name_with_type": "Xã Phố Cáo",
        "path": "Phố Cáo, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Phố Cáo, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00745",
        "parent_code": "026"
    },
    "00748": {
        "name": "Sính Lủng",
        "type": "xa",
        "slug": "sinh-lung",
        "name_with_type": "Xã Sính Lủng",
        "path": "Sính Lủng, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Sính Lủng, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00748",
        "parent_code": "026"
    },
    "00751": {
        "name": "Sảng Tủng",
        "type": "xa",
        "slug": "sang-tung",
        "name_with_type": "Xã Sảng Tủng",
        "path": "Sảng Tủng, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Sảng Tủng, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00751",
        "parent_code": "026"
    },
    "00754": {
        "name": "Lũng Thầu",
        "type": "xa",
        "slug": "lung-thau",
        "name_with_type": "Xã Lũng Thầu",
        "path": "Lũng Thầu, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Lũng Thầu, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00754",
        "parent_code": "026"
    },
    "00757": {
        "name": "Hố Quáng Phìn",
        "type": "xa",
        "slug": "ho-quang-phin",
        "name_with_type": "Xã Hố Quáng Phìn",
        "path": "Hố Quáng Phìn, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Hố Quáng Phìn, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00757",
        "parent_code": "026"
    },
    "00760": {
        "name": "Vần Chải",
        "type": "xa",
        "slug": "van-chai",
        "name_with_type": "Xã Vần Chải",
        "path": "Vần Chải, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Vần Chải, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00760",
        "parent_code": "026"
    },
    "00763": {
        "name": "Lũng Phìn",
        "type": "xa",
        "slug": "lung-phin",
        "name_with_type": "Xã Lũng Phìn",
        "path": "Lũng Phìn, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Lũng Phìn, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00763",
        "parent_code": "026"
    },
    "00766": {
        "name": "Sủng Trái",
        "type": "xa",
        "slug": "sung-trai",
        "name_with_type": "Xã Sủng Trái",
        "path": "Sủng Trái, Đồng Văn, Hà Giang",
        "path_with_type": "Xã Sủng Trái, Huyện Đồng Văn, Tỉnh Hà Giang",
        "code": "00766",
        "parent_code": "026"
    },
    "00769": {
        "name": "Mèo Vạc",
        "type": "thi-tran",
        "slug": "meo-vac",
        "name_with_type": "Thị trấn Mèo Vạc",
        "path": "Mèo Vạc, Mèo Vạc, Hà Giang",
        "path_with_type": "Thị trấn Mèo Vạc, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00769",
        "parent_code": "027"
    },
    "00772": {
        "name": "Thượng Phùng",
        "type": "xa",
        "slug": "thuong-phung",
        "name_with_type": "Xã Thượng Phùng",
        "path": "Thượng Phùng, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Thượng Phùng, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00772",
        "parent_code": "027"
    },
    "00775": {
        "name": "Pải Lủng",
        "type": "xa",
        "slug": "pai-lung",
        "name_with_type": "Xã Pải Lủng",
        "path": "Pải Lủng, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Pải Lủng, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00775",
        "parent_code": "027"
    },
    "00778": {
        "name": "Xín Cái",
        "type": "xa",
        "slug": "xin-cai",
        "name_with_type": "Xã Xín Cái",
        "path": "Xín Cái, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Xín Cái, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00778",
        "parent_code": "027"
    },
    "00781": {
        "name": "Pả Vi",
        "type": "xa",
        "slug": "pa-vi",
        "name_with_type": "Xã Pả Vi",
        "path": "Pả Vi, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Pả Vi, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00781",
        "parent_code": "027"
    },
    "00784": {
        "name": "Giàng Chu Phìn",
        "type": "xa",
        "slug": "giang-chu-phin",
        "name_with_type": "Xã Giàng Chu Phìn",
        "path": "Giàng Chu Phìn, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Giàng Chu Phìn, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00784",
        "parent_code": "027"
    },
    "00787": {
        "name": "Sủng Trà",
        "type": "xa",
        "slug": "sung-tra",
        "name_with_type": "Xã Sủng Trà",
        "path": "Sủng Trà, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Sủng Trà, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00787",
        "parent_code": "027"
    },
    "00790": {
        "name": "Sủng Máng",
        "type": "xa",
        "slug": "sung-mang",
        "name_with_type": "Xã Sủng Máng",
        "path": "Sủng Máng, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Sủng Máng, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00790",
        "parent_code": "027"
    },
    "00793": {
        "name": "Sơn Vĩ",
        "type": "xa",
        "slug": "son-vi",
        "name_with_type": "Xã Sơn Vĩ",
        "path": "Sơn Vĩ, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Sơn Vĩ, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00793",
        "parent_code": "027"
    },
    "00796": {
        "name": "Tả Lủng",
        "type": "xa",
        "slug": "ta-lung",
        "name_with_type": "Xã Tả Lủng",
        "path": "Tả Lủng, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Tả Lủng, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00796",
        "parent_code": "027"
    },
    "00799": {
        "name": "Cán Chu Phìn",
        "type": "xa",
        "slug": "can-chu-phin",
        "name_with_type": "Xã Cán Chu Phìn",
        "path": "Cán Chu Phìn, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Cán Chu Phìn, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00799",
        "parent_code": "027"
    },
    "00802": {
        "name": "Lũng Pù",
        "type": "xa",
        "slug": "lung-pu",
        "name_with_type": "Xã Lũng Pù",
        "path": "Lũng Pù, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Lũng Pù, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00802",
        "parent_code": "027"
    },
    "00805": {
        "name": "Lũng Chinh",
        "type": "xa",
        "slug": "lung-chinh",
        "name_with_type": "Xã Lũng Chinh",
        "path": "Lũng Chinh, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Lũng Chinh, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00805",
        "parent_code": "027"
    },
    "00808": {
        "name": "Tát Ngà",
        "type": "xa",
        "slug": "tat-nga",
        "name_with_type": "Xã Tát Ngà",
        "path": "Tát Ngà, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Tát Ngà, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00808",
        "parent_code": "027"
    },
    "00811": {
        "name": "Nậm Ban",
        "type": "xa",
        "slug": "nam-ban",
        "name_with_type": "Xã Nậm Ban",
        "path": "Nậm Ban, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Nậm Ban, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00811",
        "parent_code": "027"
    },
    "00814": {
        "name": "Khâu Vai",
        "type": "xa",
        "slug": "khau-vai",
        "name_with_type": "Xã Khâu Vai",
        "path": "Khâu Vai, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Khâu Vai, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00814",
        "parent_code": "027"
    },
    "00815": {
        "name": "Niêm Tòng",
        "type": "xa",
        "slug": "niem-tong",
        "name_with_type": "Xã Niêm Tòng",
        "path": "Niêm Tòng, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Niêm Tòng, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00815",
        "parent_code": "027"
    },
    "00817": {
        "name": "Niêm Sơn",
        "type": "xa",
        "slug": "niem-son",
        "name_with_type": "Xã Niêm Sơn",
        "path": "Niêm Sơn, Mèo Vạc, Hà Giang",
        "path_with_type": "Xã Niêm Sơn, Huyện Mèo Vạc, Tỉnh Hà Giang",
        "code": "00817",
        "parent_code": "027"
    },
    "00820": {
        "name": "Yên Minh",
        "type": "thi-tran",
        "slug": "yen-minh",
        "name_with_type": "Thị trấn Yên Minh",
        "path": "Yên Minh, Yên Minh, Hà Giang",
        "path_with_type": "Thị trấn Yên Minh, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00820",
        "parent_code": "028"
    },
    "00823": {
        "name": "Thắng Mố",
        "type": "xa",
        "slug": "thang-mo",
        "name_with_type": "Xã Thắng Mố",
        "path": "Thắng Mố, Yên Minh, Hà Giang",
        "path_with_type": "Xã Thắng Mố, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00823",
        "parent_code": "028"
    },
    "00826": {
        "name": "Phú Lũng",
        "type": "xa",
        "slug": "phu-lung",
        "name_with_type": "Xã Phú Lũng",
        "path": "Phú Lũng, Yên Minh, Hà Giang",
        "path_with_type": "Xã Phú Lũng, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00826",
        "parent_code": "028"
    },
    "00829": {
        "name": "Sủng Tráng",
        "type": "xa",
        "slug": "sung-trang",
        "name_with_type": "Xã Sủng Tráng",
        "path": "Sủng Tráng, Yên Minh, Hà Giang",
        "path_with_type": "Xã Sủng Tráng, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00829",
        "parent_code": "028"
    },
    "00832": {
        "name": "Bạch Đích",
        "type": "xa",
        "slug": "bach-dich",
        "name_with_type": "Xã Bạch Đích",
        "path": "Bạch Đích, Yên Minh, Hà Giang",
        "path_with_type": "Xã Bạch Đích, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00832",
        "parent_code": "028"
    },
    "00835": {
        "name": "Na Khê",
        "type": "xa",
        "slug": "na-khe",
        "name_with_type": "Xã Na Khê",
        "path": "Na Khê, Yên Minh, Hà Giang",
        "path_with_type": "Xã Na Khê, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00835",
        "parent_code": "028"
    },
    "00838": {
        "name": "Sủng Thài",
        "type": "xa",
        "slug": "sung-thai",
        "name_with_type": "Xã Sủng Thài",
        "path": "Sủng Thài, Yên Minh, Hà Giang",
        "path_with_type": "Xã Sủng Thài, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00838",
        "parent_code": "028"
    },
    "00841": {
        "name": "Hữu Vinh",
        "type": "xa",
        "slug": "huu-vinh",
        "name_with_type": "Xã Hữu Vinh",
        "path": "Hữu Vinh, Yên Minh, Hà Giang",
        "path_with_type": "Xã Hữu Vinh, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00841",
        "parent_code": "028"
    },
    "00844": {
        "name": "Lao Và Chải",
        "type": "xa",
        "slug": "lao-va-chai",
        "name_with_type": "Xã Lao Và Chải",
        "path": "Lao Và Chải, Yên Minh, Hà Giang",
        "path_with_type": "Xã Lao Và Chải, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00844",
        "parent_code": "028"
    },
    "00847": {
        "name": "Mậu Duệ",
        "type": "xa",
        "slug": "mau-due",
        "name_with_type": "Xã Mậu Duệ",
        "path": "Mậu Duệ, Yên Minh, Hà Giang",
        "path_with_type": "Xã Mậu Duệ, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00847",
        "parent_code": "028"
    },
    "00850": {
        "name": "Đông Minh",
        "type": "xa",
        "slug": "dong-minh",
        "name_with_type": "Xã Đông Minh",
        "path": "Đông Minh, Yên Minh, Hà Giang",
        "path_with_type": "Xã Đông Minh, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00850",
        "parent_code": "028"
    },
    "00853": {
        "name": "Mậu Long",
        "type": "xa",
        "slug": "mau-long",
        "name_with_type": "Xã Mậu Long",
        "path": "Mậu Long, Yên Minh, Hà Giang",
        "path_with_type": "Xã Mậu Long, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00853",
        "parent_code": "028"
    },
    "00856": {
        "name": "Ngam La",
        "type": "xa",
        "slug": "ngam-la",
        "name_with_type": "Xã Ngam La",
        "path": "Ngam La, Yên Minh, Hà Giang",
        "path_with_type": "Xã Ngam La, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00856",
        "parent_code": "028"
    },
    "00859": {
        "name": "Ngọc Long",
        "type": "xa",
        "slug": "ngoc-long",
        "name_with_type": "Xã Ngọc Long",
        "path": "Ngọc Long, Yên Minh, Hà Giang",
        "path_with_type": "Xã Ngọc Long, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00859",
        "parent_code": "028"
    },
    "00862": {
        "name": "Đường Thượng",
        "type": "xa",
        "slug": "duong-thuong",
        "name_with_type": "Xã Đường Thượng",
        "path": "Đường Thượng, Yên Minh, Hà Giang",
        "path_with_type": "Xã Đường Thượng, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00862",
        "parent_code": "028"
    },
    "00865": {
        "name": "Lũng Hồ",
        "type": "xa",
        "slug": "lung-ho",
        "name_with_type": "Xã Lũng Hồ",
        "path": "Lũng Hồ, Yên Minh, Hà Giang",
        "path_with_type": "Xã Lũng Hồ, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00865",
        "parent_code": "028"
    },
    "00868": {
        "name": "Du Tiến",
        "type": "xa",
        "slug": "du-tien",
        "name_with_type": "Xã Du Tiến",
        "path": "Du Tiến, Yên Minh, Hà Giang",
        "path_with_type": "Xã Du Tiến, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00868",
        "parent_code": "028"
    },
    "00871": {
        "name": "Du Già",
        "type": "xa",
        "slug": "du-gia",
        "name_with_type": "Xã Du Già",
        "path": "Du Già, Yên Minh, Hà Giang",
        "path_with_type": "Xã Du Già, Huyện Yên Minh, Tỉnh Hà Giang",
        "code": "00871",
        "parent_code": "028"
    },
    "00874": {
        "name": "Tam Sơn",
        "type": "thi-tran",
        "slug": "tam-son",
        "name_with_type": "Thị trấn Tam Sơn",
        "path": "Tam Sơn, Quản Bạ, Hà Giang",
        "path_with_type": "Thị trấn Tam Sơn, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00874",
        "parent_code": "029"
    },
    "00877": {
        "name": "Bát Đại Sơn",
        "type": "xa",
        "slug": "bat-dai-son",
        "name_with_type": "Xã Bát Đại Sơn",
        "path": "Bát Đại Sơn, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Bát Đại Sơn, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00877",
        "parent_code": "029"
    },
    "00880": {
        "name": "Nghĩa Thuận",
        "type": "xa",
        "slug": "nghia-thuan",
        "name_with_type": "Xã Nghĩa Thuận",
        "path": "Nghĩa Thuận, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Nghĩa Thuận, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00880",
        "parent_code": "029"
    },
    "00883": {
        "name": "Cán Tỷ",
        "type": "xa",
        "slug": "can-ty",
        "name_with_type": "Xã Cán Tỷ",
        "path": "Cán Tỷ, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Cán Tỷ, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00883",
        "parent_code": "029"
    },
    "00886": {
        "name": "Cao Mã Pờ",
        "type": "xa",
        "slug": "cao-ma-po",
        "name_with_type": "Xã Cao Mã Pờ",
        "path": "Cao Mã Pờ, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Cao Mã Pờ, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00886",
        "parent_code": "029"
    },
    "00889": {
        "name": "Thanh Vân",
        "type": "xa",
        "slug": "thanh-van",
        "name_with_type": "Xã Thanh Vân",
        "path": "Thanh Vân, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Thanh Vân, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00889",
        "parent_code": "029"
    },
    "00892": {
        "name": "Tùng Vài",
        "type": "xa",
        "slug": "tung-vai",
        "name_with_type": "Xã Tùng Vài",
        "path": "Tùng Vài, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Tùng Vài, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00892",
        "parent_code": "029"
    },
    "00895": {
        "name": "Đông Hà",
        "type": "xa",
        "slug": "dong-ha",
        "name_with_type": "Xã Đông Hà",
        "path": "Đông Hà, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Đông Hà, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00895",
        "parent_code": "029"
    },
    "00898": {
        "name": "Quản Bạ",
        "type": "xa",
        "slug": "quan-ba",
        "name_with_type": "Xã Quản Bạ",
        "path": "Quản Bạ, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Quản Bạ, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00898",
        "parent_code": "029"
    },
    "00901": {
        "name": "Lùng Tám",
        "type": "xa",
        "slug": "lung-tam",
        "name_with_type": "Xã Lùng Tám",
        "path": "Lùng Tám, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Lùng Tám, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00901",
        "parent_code": "029"
    },
    "00904": {
        "name": "Quyết Tiến",
        "type": "xa",
        "slug": "quyet-tien",
        "name_with_type": "Xã Quyết Tiến",
        "path": "Quyết Tiến, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Quyết Tiến, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00904",
        "parent_code": "029"
    },
    "00907": {
        "name": "Tả Ván",
        "type": "xa",
        "slug": "ta-van",
        "name_with_type": "Xã Tả Ván",
        "path": "Tả Ván, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Tả Ván, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00907",
        "parent_code": "029"
    },
    "00910": {
        "name": "Thái An",
        "type": "xa",
        "slug": "thai-an",
        "name_with_type": "Xã Thái An",
        "path": "Thái An, Quản Bạ, Hà Giang",
        "path_with_type": "Xã Thái An, Huyện Quản Bạ, Tỉnh Hà Giang",
        "code": "00910",
        "parent_code": "029"
    },
    "00703": {
        "name": "Kim Thạch",
        "type": "xa",
        "slug": "kim-thach",
        "name_with_type": "Xã Kim Thạch",
        "path": "Kim Thạch, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Kim Thạch, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00703",
        "parent_code": "030"
    },
    "00706": {
        "name": "Phú Linh",
        "type": "xa",
        "slug": "phu-linh",
        "name_with_type": "Xã Phú Linh",
        "path": "Phú Linh, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Phú Linh, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00706",
        "parent_code": "030"
    },
    "00709": {
        "name": "Kim Linh",
        "type": "xa",
        "slug": "kim-linh",
        "name_with_type": "Xã Kim Linh",
        "path": "Kim Linh, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Kim Linh, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00709",
        "parent_code": "030"
    },
    "00913": {
        "name": "Vị Xuyên",
        "type": "thi-tran",
        "slug": "vi-xuyen",
        "name_with_type": "Thị trấn Vị Xuyên",
        "path": "Vị Xuyên, Vị Xuyên, Hà Giang",
        "path_with_type": "Thị trấn Vị Xuyên, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00913",
        "parent_code": "030"
    },
    "00916": {
        "name": "Nông Trường Việt Lâm",
        "type": "thi-tran",
        "slug": "nong-truong-viet-lam",
        "name_with_type": "Thị trấn Nông Trường Việt Lâm",
        "path": "Nông Trường Việt Lâm, Vị Xuyên, Hà Giang",
        "path_with_type": "Thị trấn Nông Trường Việt Lâm, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00916",
        "parent_code": "030"
    },
    "00919": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Minh Tân, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00919",
        "parent_code": "030"
    },
    "00922": {
        "name": "Thuận Hoà",
        "type": "xa",
        "slug": "thuan-hoa",
        "name_with_type": "Xã Thuận Hoà",
        "path": "Thuận Hoà, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Thuận Hoà, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00922",
        "parent_code": "030"
    },
    "00925": {
        "name": "Tùng Bá",
        "type": "xa",
        "slug": "tung-ba",
        "name_with_type": "Xã Tùng Bá",
        "path": "Tùng Bá, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Tùng Bá, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00925",
        "parent_code": "030"
    },
    "00928": {
        "name": "Thanh Thủy",
        "type": "xa",
        "slug": "thanh-thuy",
        "name_with_type": "Xã Thanh Thủy",
        "path": "Thanh Thủy, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Thanh Thủy, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00928",
        "parent_code": "030"
    },
    "00931": {
        "name": "Thanh Đức",
        "type": "xa",
        "slug": "thanh-duc",
        "name_with_type": "Xã Thanh Đức",
        "path": "Thanh Đức, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Thanh Đức, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00931",
        "parent_code": "030"
    },
    "00934": {
        "name": "Phong Quang",
        "type": "xa",
        "slug": "phong-quang",
        "name_with_type": "Xã Phong Quang",
        "path": "Phong Quang, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Phong Quang, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00934",
        "parent_code": "030"
    },
    "00937": {
        "name": "Xín Chải",
        "type": "xa",
        "slug": "xin-chai",
        "name_with_type": "Xã Xín Chải",
        "path": "Xín Chải, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Xín Chải, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00937",
        "parent_code": "030"
    },
    "00940": {
        "name": "Phương Tiến",
        "type": "xa",
        "slug": "phuong-tien",
        "name_with_type": "Xã Phương Tiến",
        "path": "Phương Tiến, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Phương Tiến, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00940",
        "parent_code": "030"
    },
    "00943": {
        "name": "Lao Chải",
        "type": "xa",
        "slug": "lao-chai",
        "name_with_type": "Xã Lao Chải",
        "path": "Lao Chải, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Lao Chải, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00943",
        "parent_code": "030"
    },
    "00952": {
        "name": "Cao Bồ",
        "type": "xa",
        "slug": "cao-bo",
        "name_with_type": "Xã Cao Bồ",
        "path": "Cao Bồ, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Cao Bồ, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00952",
        "parent_code": "030"
    },
    "00955": {
        "name": "Đạo Đức",
        "type": "xa",
        "slug": "dao-duc",
        "name_with_type": "Xã Đạo Đức",
        "path": "Đạo Đức, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Đạo Đức, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00955",
        "parent_code": "030"
    },
    "00958": {
        "name": "Thượng Sơn",
        "type": "xa",
        "slug": "thuong-son",
        "name_with_type": "Xã Thượng Sơn",
        "path": "Thượng Sơn, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Thượng Sơn, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00958",
        "parent_code": "030"
    },
    "00961": {
        "name": "Linh Hồ",
        "type": "xa",
        "slug": "linh-ho",
        "name_with_type": "Xã Linh Hồ",
        "path": "Linh Hồ, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Linh Hồ, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00961",
        "parent_code": "030"
    },
    "00964": {
        "name": "Quảng Ngần",
        "type": "xa",
        "slug": "quang-ngan",
        "name_with_type": "Xã Quảng Ngần",
        "path": "Quảng Ngần, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Quảng Ngần, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00964",
        "parent_code": "030"
    },
    "00967": {
        "name": "Việt Lâm",
        "type": "xa",
        "slug": "viet-lam",
        "name_with_type": "Xã Việt Lâm",
        "path": "Việt Lâm, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Việt Lâm, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00967",
        "parent_code": "030"
    },
    "00970": {
        "name": "Ngọc Linh",
        "type": "xa",
        "slug": "ngoc-linh",
        "name_with_type": "Xã Ngọc Linh",
        "path": "Ngọc Linh, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Ngọc Linh, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00970",
        "parent_code": "030"
    },
    "00973": {
        "name": "Ngọc Minh",
        "type": "xa",
        "slug": "ngoc-minh",
        "name_with_type": "Xã Ngọc Minh",
        "path": "Ngọc Minh, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Ngọc Minh, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00973",
        "parent_code": "030"
    },
    "00976": {
        "name": "Bạch Ngọc",
        "type": "xa",
        "slug": "bach-ngoc",
        "name_with_type": "Xã Bạch Ngọc",
        "path": "Bạch Ngọc, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Bạch Ngọc, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00976",
        "parent_code": "030"
    },
    "00979": {
        "name": "Trung Thành",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thành",
        "path": "Trung Thành, Vị Xuyên, Hà Giang",
        "path_with_type": "Xã Trung Thành, Huyện Vị Xuyên, Tỉnh Hà Giang",
        "code": "00979",
        "parent_code": "030"
    },
    "00982": {
        "name": "Minh Sơn",
        "type": "xa",
        "slug": "minh-son",
        "name_with_type": "Xã Minh Sơn",
        "path": "Minh Sơn, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Minh Sơn, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "00982",
        "parent_code": "031"
    },
    "00985": {
        "name": "Giáp Trung",
        "type": "xa",
        "slug": "giap-trung",
        "name_with_type": "Xã Giáp Trung",
        "path": "Giáp Trung, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Giáp Trung, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "00985",
        "parent_code": "031"
    },
    "00988": {
        "name": "Yên Định",
        "type": "xa",
        "slug": "yen-dinh",
        "name_with_type": "Xã Yên Định",
        "path": "Yên Định, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Yên Định, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "00988",
        "parent_code": "031"
    },
    "00991": {
        "name": "Yên Phú",
        "type": "thi-tran",
        "slug": "yen-phu",
        "name_with_type": "Thị trấn Yên Phú",
        "path": "Yên Phú, Bắc Mê, Hà Giang",
        "path_with_type": "Thị trấn Yên Phú, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "00991",
        "parent_code": "031"
    },
    "00994": {
        "name": "Minh Ngọc",
        "type": "xa",
        "slug": "minh-ngoc",
        "name_with_type": "Xã Minh Ngọc",
        "path": "Minh Ngọc, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Minh Ngọc, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "00994",
        "parent_code": "031"
    },
    "00997": {
        "name": "Yên Phong",
        "type": "xa",
        "slug": "yen-phong",
        "name_with_type": "Xã Yên Phong",
        "path": "Yên Phong, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Yên Phong, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "00997",
        "parent_code": "031"
    },
    "01000": {
        "name": "Lạc Nông",
        "type": "xa",
        "slug": "lac-nong",
        "name_with_type": "Xã Lạc Nông",
        "path": "Lạc Nông, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Lạc Nông, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "01000",
        "parent_code": "031"
    },
    "01003": {
        "name": "Phú Nam",
        "type": "xa",
        "slug": "phu-nam",
        "name_with_type": "Xã Phú Nam",
        "path": "Phú Nam, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Phú Nam, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "01003",
        "parent_code": "031"
    },
    "01006": {
        "name": "Yên Cường",
        "type": "xa",
        "slug": "yen-cuong",
        "name_with_type": "Xã Yên Cường",
        "path": "Yên Cường, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Yên Cường, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "01006",
        "parent_code": "031"
    },
    "01009": {
        "name": "Thượng Tân",
        "type": "xa",
        "slug": "thuong-tan",
        "name_with_type": "Xã Thượng Tân",
        "path": "Thượng Tân, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Thượng Tân, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "01009",
        "parent_code": "031"
    },
    "01012": {
        "name": "Đường Âm",
        "type": "xa",
        "slug": "duong-am",
        "name_with_type": "Xã Đường Âm",
        "path": "Đường Âm, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Đường Âm, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "01012",
        "parent_code": "031"
    },
    "01015": {
        "name": "Đường Hồng",
        "type": "xa",
        "slug": "duong-hong",
        "name_with_type": "Xã Đường Hồng",
        "path": "Đường Hồng, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Đường Hồng, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "01015",
        "parent_code": "031"
    },
    "01018": {
        "name": "Phiêng Luông",
        "type": "xa",
        "slug": "phieng-luong",
        "name_with_type": "Xã Phiêng Luông",
        "path": "Phiêng Luông, Bắc Mê, Hà Giang",
        "path_with_type": "Xã Phiêng Luông, Huyện Bắc Mê, Tỉnh Hà Giang",
        "code": "01018",
        "parent_code": "031"
    },
    "01021": {
        "name": "Vinh Quang",
        "type": "thi-tran",
        "slug": "vinh-quang",
        "name_with_type": "Thị trấn Vinh Quang",
        "path": "Vinh Quang, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Thị trấn Vinh Quang, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01021",
        "parent_code": "032"
    },
    "01024": {
        "name": "Bản Máy",
        "type": "xa",
        "slug": "ban-may",
        "name_with_type": "Xã Bản Máy",
        "path": "Bản Máy, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Bản Máy, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01024",
        "parent_code": "032"
    },
    "01027": {
        "name": "Thàng Tín",
        "type": "xa",
        "slug": "thang-tin",
        "name_with_type": "Xã Thàng Tín",
        "path": "Thàng Tín, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Thàng Tín, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01027",
        "parent_code": "032"
    },
    "01030": {
        "name": "Thèn Chu Phìn",
        "type": "xa",
        "slug": "then-chu-phin",
        "name_with_type": "Xã Thèn Chu Phìn",
        "path": "Thèn Chu Phìn, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Thèn Chu Phìn, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01030",
        "parent_code": "032"
    },
    "01033": {
        "name": "Pố Lồ",
        "type": "xa",
        "slug": "po-lo",
        "name_with_type": "Xã Pố Lồ",
        "path": "Pố Lồ, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Pố Lồ, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01033",
        "parent_code": "032"
    },
    "01036": {
        "name": "Bản Phùng",
        "type": "xa",
        "slug": "ban-phung",
        "name_with_type": "Xã Bản Phùng",
        "path": "Bản Phùng, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Bản Phùng, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01036",
        "parent_code": "032"
    },
    "01039": {
        "name": "Túng Sán",
        "type": "xa",
        "slug": "tung-san",
        "name_with_type": "Xã Túng Sán",
        "path": "Túng Sán, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Túng Sán, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01039",
        "parent_code": "032"
    },
    "01042": {
        "name": "Chiến Phố",
        "type": "xa",
        "slug": "chien-pho",
        "name_with_type": "Xã Chiến Phố",
        "path": "Chiến Phố, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Chiến Phố, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01042",
        "parent_code": "032"
    },
    "01045": {
        "name": "Đản Ván",
        "type": "xa",
        "slug": "dan-van",
        "name_with_type": "Xã Đản Ván",
        "path": "Đản Ván, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Đản Ván, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01045",
        "parent_code": "032"
    },
    "01048": {
        "name": "Tụ Nhân",
        "type": "xa",
        "slug": "tu-nhan",
        "name_with_type": "Xã Tụ Nhân",
        "path": "Tụ Nhân, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Tụ Nhân, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01048",
        "parent_code": "032"
    },
    "01051": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Tân Tiến, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01051",
        "parent_code": "032"
    },
    "01054": {
        "name": "Nàng Đôn",
        "type": "xa",
        "slug": "nang-don",
        "name_with_type": "Xã Nàng Đôn",
        "path": "Nàng Đôn, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Nàng Đôn, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01054",
        "parent_code": "032"
    },
    "01057": {
        "name": "Pờ Ly Ngài",
        "type": "xa",
        "slug": "po-ly-ngai",
        "name_with_type": "Xã Pờ Ly Ngài",
        "path": "Pờ Ly Ngài, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Pờ Ly Ngài, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01057",
        "parent_code": "032"
    },
    "01060": {
        "name": "Sán Xả Hồ",
        "type": "xa",
        "slug": "san-xa-ho",
        "name_with_type": "Xã Sán Xả Hồ",
        "path": "Sán Xả Hồ, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Sán Xả Hồ, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01060",
        "parent_code": "032"
    },
    "01063": {
        "name": "Bản Luốc",
        "type": "xa",
        "slug": "ban-luoc",
        "name_with_type": "Xã Bản Luốc",
        "path": "Bản Luốc, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Bản Luốc, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01063",
        "parent_code": "032"
    },
    "01066": {
        "name": "Ngàm Đăng Vài",
        "type": "xa",
        "slug": "ngam-dang-vai",
        "name_with_type": "Xã Ngàm Đăng Vài",
        "path": "Ngàm Đăng Vài, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Ngàm Đăng Vài, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01066",
        "parent_code": "032"
    },
    "01069": {
        "name": "Bản Nhùng",
        "type": "xa",
        "slug": "ban-nhung",
        "name_with_type": "Xã Bản Nhùng",
        "path": "Bản Nhùng, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Bản Nhùng, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01069",
        "parent_code": "032"
    },
    "01072": {
        "name": "Tả Sử Choóng",
        "type": "xa",
        "slug": "ta-su-choong",
        "name_with_type": "Xã Tả Sử Choóng",
        "path": "Tả Sử Choóng, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Tả Sử Choóng, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01072",
        "parent_code": "032"
    },
    "01075": {
        "name": "Nậm Dịch",
        "type": "xa",
        "slug": "nam-dich",
        "name_with_type": "Xã Nậm Dịch",
        "path": "Nậm Dịch, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Nậm Dịch, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01075",
        "parent_code": "032"
    },
    "01078": {
        "name": "Bản Péo",
        "type": "xa",
        "slug": "ban-peo",
        "name_with_type": "Xã Bản Péo",
        "path": "Bản Péo, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Bản Péo, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01078",
        "parent_code": "032"
    },
    "01081": {
        "name": "Hồ Thầu",
        "type": "xa",
        "slug": "ho-thau",
        "name_with_type": "Xã Hồ Thầu",
        "path": "Hồ Thầu, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Hồ Thầu, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01081",
        "parent_code": "032"
    },
    "01084": {
        "name": "Nam Sơn",
        "type": "xa",
        "slug": "nam-son",
        "name_with_type": "Xã Nam Sơn",
        "path": "Nam Sơn, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Nam Sơn, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01084",
        "parent_code": "032"
    },
    "01087": {
        "name": "Nậm Tỵ",
        "type": "xa",
        "slug": "nam-ty",
        "name_with_type": "Xã Nậm Tỵ",
        "path": "Nậm Tỵ, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Nậm Tỵ, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01087",
        "parent_code": "032"
    },
    "01090": {
        "name": "Thông Nguyên",
        "type": "xa",
        "slug": "thong-nguyen",
        "name_with_type": "Xã Thông Nguyên",
        "path": "Thông Nguyên, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Thông Nguyên, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01090",
        "parent_code": "032"
    },
    "01093": {
        "name": "Nậm Khòa",
        "type": "xa",
        "slug": "nam-khoa",
        "name_with_type": "Xã Nậm Khòa",
        "path": "Nậm Khòa, Hoàng Su Phì, Hà Giang",
        "path_with_type": "Xã Nậm Khòa, Huyện Hoàng Su Phì, Tỉnh Hà Giang",
        "code": "01093",
        "parent_code": "032"
    },
    "01096": {
        "name": "Cốc Pài",
        "type": "thi-tran",
        "slug": "coc-pai",
        "name_with_type": "Thị trấn Cốc Pài",
        "path": "Cốc Pài, Xín Mần, Hà Giang",
        "path_with_type": "Thị trấn Cốc Pài, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01096",
        "parent_code": "033"
    },
    "01099": {
        "name": "Nàn Xỉn",
        "type": "xa",
        "slug": "nan-xin",
        "name_with_type": "Xã Nàn Xỉn",
        "path": "Nàn Xỉn, Xín Mần, Hà Giang",
        "path_with_type": "Xã Nàn Xỉn, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01099",
        "parent_code": "033"
    },
    "01102": {
        "name": "Bản Díu",
        "type": "xa",
        "slug": "ban-diu",
        "name_with_type": "Xã Bản Díu",
        "path": "Bản Díu, Xín Mần, Hà Giang",
        "path_with_type": "Xã Bản Díu, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01102",
        "parent_code": "033"
    },
    "01105": {
        "name": "Chí Cà",
        "type": "xa",
        "slug": "chi-ca",
        "name_with_type": "Xã Chí Cà",
        "path": "Chí Cà, Xín Mần, Hà Giang",
        "path_with_type": "Xã Chí Cà, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01105",
        "parent_code": "033"
    },
    "01108": {
        "name": "Xín Mần",
        "type": "xa",
        "slug": "xin-man",
        "name_with_type": "Xã Xín Mần",
        "path": "Xín Mần, Xín Mần, Hà Giang",
        "path_with_type": "Xã Xín Mần, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01108",
        "parent_code": "033"
    },
    "01111": {
        "name": "Trung Thịnh",
        "type": "xa",
        "slug": "trung-thinh",
        "name_with_type": "Xã Trung Thịnh",
        "path": "Trung Thịnh, Xín Mần, Hà Giang",
        "path_with_type": "Xã Trung Thịnh, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01111",
        "parent_code": "033"
    },
    "01114": {
        "name": "Thèn Phàng",
        "type": "xa",
        "slug": "then-phang",
        "name_with_type": "Xã Thèn Phàng",
        "path": "Thèn Phàng, Xín Mần, Hà Giang",
        "path_with_type": "Xã Thèn Phàng, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01114",
        "parent_code": "033"
    },
    "01117": {
        "name": "Ngán Chiên",
        "type": "xa",
        "slug": "ngan-chien",
        "name_with_type": "Xã Ngán Chiên",
        "path": "Ngán Chiên, Xín Mần, Hà Giang",
        "path_with_type": "Xã Ngán Chiên, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01117",
        "parent_code": "033"
    },
    "01120": {
        "name": "Pà Vầy Sủ",
        "type": "xa",
        "slug": "pa-vay-su",
        "name_with_type": "Xã Pà Vầy Sủ",
        "path": "Pà Vầy Sủ, Xín Mần, Hà Giang",
        "path_with_type": "Xã Pà Vầy Sủ, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01120",
        "parent_code": "033"
    },
    "01123": {
        "name": "Cốc Rế",
        "type": "xa",
        "slug": "coc-re",
        "name_with_type": "Xã Cốc Rế",
        "path": "Cốc Rế, Xín Mần, Hà Giang",
        "path_with_type": "Xã Cốc Rế, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01123",
        "parent_code": "033"
    },
    "01126": {
        "name": "Thu Tà",
        "type": "xa",
        "slug": "thu-ta",
        "name_with_type": "Xã Thu Tà",
        "path": "Thu Tà, Xín Mần, Hà Giang",
        "path_with_type": "Xã Thu Tà, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01126",
        "parent_code": "033"
    },
    "01129": {
        "name": "Nàn Ma",
        "type": "xa",
        "slug": "nan-ma",
        "name_with_type": "Xã Nàn Ma",
        "path": "Nàn Ma, Xín Mần, Hà Giang",
        "path_with_type": "Xã Nàn Ma, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01129",
        "parent_code": "033"
    },
    "01132": {
        "name": "Tả Nhìu",
        "type": "xa",
        "slug": "ta-nhiu",
        "name_with_type": "Xã Tả Nhìu",
        "path": "Tả Nhìu, Xín Mần, Hà Giang",
        "path_with_type": "Xã Tả Nhìu, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01132",
        "parent_code": "033"
    },
    "01135": {
        "name": "Bản Ngò",
        "type": "xa",
        "slug": "ban-ngo",
        "name_with_type": "Xã Bản Ngò",
        "path": "Bản Ngò, Xín Mần, Hà Giang",
        "path_with_type": "Xã Bản Ngò, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01135",
        "parent_code": "033"
    },
    "01138": {
        "name": "Chế Là",
        "type": "xa",
        "slug": "che-la",
        "name_with_type": "Xã Chế Là",
        "path": "Chế Là, Xín Mần, Hà Giang",
        "path_with_type": "Xã Chế Là, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01138",
        "parent_code": "033"
    },
    "01141": {
        "name": "Nấm Dẩn",
        "type": "xa",
        "slug": "nam-dan",
        "name_with_type": "Xã Nấm Dẩn",
        "path": "Nấm Dẩn, Xín Mần, Hà Giang",
        "path_with_type": "Xã Nấm Dẩn, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01141",
        "parent_code": "033"
    },
    "01144": {
        "name": "Quảng Nguyên",
        "type": "xa",
        "slug": "quang-nguyen",
        "name_with_type": "Xã Quảng Nguyên",
        "path": "Quảng Nguyên, Xín Mần, Hà Giang",
        "path_with_type": "Xã Quảng Nguyên, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01144",
        "parent_code": "033"
    },
    "01147": {
        "name": "Nà Chì",
        "type": "xa",
        "slug": "na-chi",
        "name_with_type": "Xã Nà Chì",
        "path": "Nà Chì, Xín Mần, Hà Giang",
        "path_with_type": "Xã Nà Chì, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01147",
        "parent_code": "033"
    },
    "01150": {
        "name": "Khuôn Lùng",
        "type": "xa",
        "slug": "khuon-lung",
        "name_with_type": "Xã Khuôn Lùng",
        "path": "Khuôn Lùng, Xín Mần, Hà Giang",
        "path_with_type": "Xã Khuôn Lùng, Huyện Xín Mần, Tỉnh Hà Giang",
        "code": "01150",
        "parent_code": "033"
    },
    "01153": {
        "name": "Việt Quang",
        "type": "thi-tran",
        "slug": "viet-quang",
        "name_with_type": "Thị trấn Việt Quang",
        "path": "Việt Quang, Bắc Quang, Hà Giang",
        "path_with_type": "Thị trấn Việt Quang, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01153",
        "parent_code": "034"
    },
    "01156": {
        "name": "Vĩnh Tuy",
        "type": "thi-tran",
        "slug": "vinh-tuy",
        "name_with_type": "Thị trấn Vĩnh Tuy",
        "path": "Vĩnh Tuy, Bắc Quang, Hà Giang",
        "path_with_type": "Thị trấn Vĩnh Tuy, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01156",
        "parent_code": "034"
    },
    "01159": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Tân Lập, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01159",
        "parent_code": "034"
    },
    "01162": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Tân Thành, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01162",
        "parent_code": "034"
    },
    "01165": {
        "name": "Đồng Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đồng Tiến",
        "path": "Đồng Tiến, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Đồng Tiến, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01165",
        "parent_code": "034"
    },
    "01168": {
        "name": "Đồng Tâm",
        "type": "xa",
        "slug": "dong-tam",
        "name_with_type": "Xã Đồng Tâm",
        "path": "Đồng Tâm, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Đồng Tâm, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01168",
        "parent_code": "034"
    },
    "01171": {
        "name": "Tân Quang",
        "type": "xa",
        "slug": "tan-quang",
        "name_with_type": "Xã Tân Quang",
        "path": "Tân Quang, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Tân Quang, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01171",
        "parent_code": "034"
    },
    "01174": {
        "name": "Thượng Bình",
        "type": "xa",
        "slug": "thuong-binh",
        "name_with_type": "Xã Thượng Bình",
        "path": "Thượng Bình, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Thượng Bình, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01174",
        "parent_code": "034"
    },
    "01177": {
        "name": "Hữu Sản",
        "type": "xa",
        "slug": "huu-san",
        "name_with_type": "Xã Hữu Sản",
        "path": "Hữu Sản, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Hữu Sản, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01177",
        "parent_code": "034"
    },
    "01180": {
        "name": "Kim Ngọc",
        "type": "xa",
        "slug": "kim-ngoc",
        "name_with_type": "Xã Kim Ngọc",
        "path": "Kim Ngọc, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Kim Ngọc, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01180",
        "parent_code": "034"
    },
    "01183": {
        "name": "Việt Vinh",
        "type": "xa",
        "slug": "viet-vinh",
        "name_with_type": "Xã Việt Vinh",
        "path": "Việt Vinh, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Việt Vinh, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01183",
        "parent_code": "034"
    },
    "01186": {
        "name": "Bằng Hành",
        "type": "xa",
        "slug": "bang-hanh",
        "name_with_type": "Xã Bằng Hành",
        "path": "Bằng Hành, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Bằng Hành, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01186",
        "parent_code": "034"
    },
    "01189": {
        "name": "Quang Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quang Minh",
        "path": "Quang Minh, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Quang Minh, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01189",
        "parent_code": "034"
    },
    "01192": {
        "name": "Liên Hiệp",
        "type": "xa",
        "slug": "lien-hiep",
        "name_with_type": "Xã Liên Hiệp",
        "path": "Liên Hiệp, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Liên Hiệp, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01192",
        "parent_code": "034"
    },
    "01195": {
        "name": "Vô Điếm",
        "type": "xa",
        "slug": "vo-diem",
        "name_with_type": "Xã Vô Điếm",
        "path": "Vô Điếm, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Vô Điếm, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01195",
        "parent_code": "034"
    },
    "01198": {
        "name": "Việt Hồng",
        "type": "xa",
        "slug": "viet-hong",
        "name_with_type": "Xã Việt Hồng",
        "path": "Việt Hồng, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Việt Hồng, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01198",
        "parent_code": "034"
    },
    "01201": {
        "name": "Hùng An",
        "type": "xa",
        "slug": "hung-an",
        "name_with_type": "Xã Hùng An",
        "path": "Hùng An, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Hùng An, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01201",
        "parent_code": "034"
    },
    "01204": {
        "name": "Đức Xuân",
        "type": "xa",
        "slug": "duc-xuan",
        "name_with_type": "Xã Đức Xuân",
        "path": "Đức Xuân, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Đức Xuân, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01204",
        "parent_code": "034"
    },
    "01207": {
        "name": "Tiên Kiều",
        "type": "xa",
        "slug": "tien-kieu",
        "name_with_type": "Xã Tiên Kiều",
        "path": "Tiên Kiều, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Tiên Kiều, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01207",
        "parent_code": "034"
    },
    "01210": {
        "name": "Vĩnh Hảo",
        "type": "xa",
        "slug": "vinh-hao",
        "name_with_type": "Xã Vĩnh Hảo",
        "path": "Vĩnh Hảo, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Vĩnh Hảo, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01210",
        "parent_code": "034"
    },
    "01213": {
        "name": "Vĩnh Phúc",
        "type": "xa",
        "slug": "vinh-phuc",
        "name_with_type": "Xã Vĩnh Phúc",
        "path": "Vĩnh Phúc, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Vĩnh Phúc, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01213",
        "parent_code": "034"
    },
    "01216": {
        "name": "Đồng Yên",
        "type": "xa",
        "slug": "dong-yen",
        "name_with_type": "Xã Đồng Yên",
        "path": "Đồng Yên, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Đồng Yên, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01216",
        "parent_code": "034"
    },
    "01219": {
        "name": "Đông Thành",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thành",
        "path": "Đông Thành, Bắc Quang, Hà Giang",
        "path_with_type": "Xã Đông Thành, Huyện Bắc Quang, Tỉnh Hà Giang",
        "code": "01219",
        "parent_code": "034"
    },
    "01222": {
        "name": "Xuân Minh",
        "type": "xa",
        "slug": "xuan-minh",
        "name_with_type": "Xã Xuân Minh",
        "path": "Xuân Minh, Quang Bình, Hà Giang",
        "path_with_type": "Xã Xuân Minh, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01222",
        "parent_code": "035"
    },
    "01225": {
        "name": "Tiên Nguyên",
        "type": "xa",
        "slug": "tien-nguyen",
        "name_with_type": "Xã Tiên Nguyên",
        "path": "Tiên Nguyên, Quang Bình, Hà Giang",
        "path_with_type": "Xã Tiên Nguyên, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01225",
        "parent_code": "035"
    },
    "01228": {
        "name": "Tân Nam",
        "type": "xa",
        "slug": "tan-nam",
        "name_with_type": "Xã Tân Nam",
        "path": "Tân Nam, Quang Bình, Hà Giang",
        "path_with_type": "Xã Tân Nam, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01228",
        "parent_code": "035"
    },
    "01231": {
        "name": "Bản Rịa",
        "type": "xa",
        "slug": "ban-ria",
        "name_with_type": "Xã Bản Rịa",
        "path": "Bản Rịa, Quang Bình, Hà Giang",
        "path_with_type": "Xã Bản Rịa, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01231",
        "parent_code": "035"
    },
    "01234": {
        "name": "Yên Thành",
        "type": "xa",
        "slug": "yen-thanh",
        "name_with_type": "Xã Yên Thành",
        "path": "Yên Thành, Quang Bình, Hà Giang",
        "path_with_type": "Xã Yên Thành, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01234",
        "parent_code": "035"
    },
    "01237": {
        "name": "Yên Bình",
        "type": "thi-tran",
        "slug": "yen-binh",
        "name_with_type": "Thị trấn Yên Bình",
        "path": "Yên Bình, Quang Bình, Hà Giang",
        "path_with_type": "Thị trấn Yên Bình, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01237",
        "parent_code": "035"
    },
    "01240": {
        "name": "Tân Trịnh",
        "type": "xa",
        "slug": "tan-trinh",
        "name_with_type": "Xã Tân Trịnh",
        "path": "Tân Trịnh, Quang Bình, Hà Giang",
        "path_with_type": "Xã Tân Trịnh, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01240",
        "parent_code": "035"
    },
    "01243": {
        "name": "Tân Bắc",
        "type": "xa",
        "slug": "tan-bac",
        "name_with_type": "Xã Tân Bắc",
        "path": "Tân Bắc, Quang Bình, Hà Giang",
        "path_with_type": "Xã Tân Bắc, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01243",
        "parent_code": "035"
    },
    "01246": {
        "name": "Bằng Lang",
        "type": "xa",
        "slug": "bang-lang",
        "name_with_type": "Xã Bằng Lang",
        "path": "Bằng Lang, Quang Bình, Hà Giang",
        "path_with_type": "Xã Bằng Lang, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01246",
        "parent_code": "035"
    },
    "01249": {
        "name": "Yên Hà",
        "type": "xa",
        "slug": "yen-ha",
        "name_with_type": "Xã Yên Hà",
        "path": "Yên Hà, Quang Bình, Hà Giang",
        "path_with_type": "Xã Yên Hà, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01249",
        "parent_code": "035"
    },
    "01252": {
        "name": "Hương Sơn",
        "type": "xa",
        "slug": "huong-son",
        "name_with_type": "Xã Hương Sơn",
        "path": "Hương Sơn, Quang Bình, Hà Giang",
        "path_with_type": "Xã Hương Sơn, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01252",
        "parent_code": "035"
    },
    "01255": {
        "name": "Xuân Giang",
        "type": "xa",
        "slug": "xuan-giang",
        "name_with_type": "Xã Xuân Giang",
        "path": "Xuân Giang, Quang Bình, Hà Giang",
        "path_with_type": "Xã Xuân Giang, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01255",
        "parent_code": "035"
    },
    "01258": {
        "name": "Nà Khương",
        "type": "xa",
        "slug": "na-khuong",
        "name_with_type": "Xã Nà Khương",
        "path": "Nà Khương, Quang Bình, Hà Giang",
        "path_with_type": "Xã Nà Khương, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01258",
        "parent_code": "035"
    },
    "01261": {
        "name": "Tiên Yên",
        "type": "xa",
        "slug": "tien-yen",
        "name_with_type": "Xã Tiên Yên",
        "path": "Tiên Yên, Quang Bình, Hà Giang",
        "path_with_type": "Xã Tiên Yên, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01261",
        "parent_code": "035"
    },
    "01264": {
        "name": "Vĩ Thượng",
        "type": "xa",
        "slug": "vi-thuong",
        "name_with_type": "Xã Vĩ Thượng",
        "path": "Vĩ Thượng, Quang Bình, Hà Giang",
        "path_with_type": "Xã Vĩ Thượng, Huyện Quang Bình, Tỉnh Hà Giang",
        "code": "01264",
        "parent_code": "035"
    },
    "22015": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường 1, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22015",
        "parent_code": "555"
    },
    "22018": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường 8, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22018",
        "parent_code": "555"
    },
    "22021": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường 2, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22021",
        "parent_code": "555"
    },
    "22024": {
        "name": "9",
        "type": "phuong",
        "slug": "9",
        "name_with_type": "Phường 9",
        "path": "9, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường 9, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22024",
        "parent_code": "555"
    },
    "22027": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường 3, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22027",
        "parent_code": "555"
    },
    "22030": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường 4, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22030",
        "parent_code": "555"
    },
    "22033": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường 5, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22033",
        "parent_code": "555"
    },
    "22036": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường 7, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22036",
        "parent_code": "555"
    },
    "22039": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường 6, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22039",
        "parent_code": "555"
    },
    "22040": {
        "name": "Phú Thạnh",
        "type": "phuong",
        "slug": "phu-thanh",
        "name_with_type": "Phường Phú Thạnh",
        "path": "Phú Thạnh, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường Phú Thạnh, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22040",
        "parent_code": "555"
    },
    "22041": {
        "name": "Phú Đông",
        "type": "phuong",
        "slug": "phu-dong",
        "name_with_type": "Phường Phú Đông",
        "path": "Phú Đông, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường Phú Đông, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22041",
        "parent_code": "555"
    },
    "22042": {
        "name": "Hòa Kiến",
        "type": "xa",
        "slug": "hoa-kien",
        "name_with_type": "Xã Hòa Kiến",
        "path": "Hòa Kiến, Tuy Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Kiến, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22042",
        "parent_code": "555"
    },
    "22045": {
        "name": "Bình Kiến",
        "type": "xa",
        "slug": "binh-kien",
        "name_with_type": "Xã Bình Kiến",
        "path": "Bình Kiến, Tuy Hoà, Phú Yên",
        "path_with_type": "Xã Bình Kiến, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22045",
        "parent_code": "555"
    },
    "22048": {
        "name": "Bình Ngọc",
        "type": "xa",
        "slug": "binh-ngoc",
        "name_with_type": "Xã Bình Ngọc",
        "path": "Bình Ngọc, Tuy Hoà, Phú Yên",
        "path_with_type": "Xã Bình Ngọc, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22048",
        "parent_code": "555"
    },
    "22162": {
        "name": "An Phú",
        "type": "xa",
        "slug": "an-phu",
        "name_with_type": "Xã An Phú",
        "path": "An Phú, Tuy Hoà, Phú Yên",
        "path_with_type": "Xã An Phú, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22162",
        "parent_code": "555"
    },
    "22240": {
        "name": "Phú Lâm",
        "type": "phuong",
        "slug": "phu-lam",
        "name_with_type": "Phường Phú Lâm",
        "path": "Phú Lâm, Tuy Hoà, Phú Yên",
        "path_with_type": "Phường Phú Lâm, Thành phố Tuy Hoà, Tỉnh Phú Yên",
        "code": "22240",
        "parent_code": "555"
    },
    "22051": {
        "name": "Xuân Phú",
        "type": "phuong",
        "slug": "xuan-phu",
        "name_with_type": "Phường Xuân Phú",
        "path": "Xuân Phú, Sông Cầu, Phú Yên",
        "path_with_type": "Phường Xuân Phú, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22051",
        "parent_code": "557"
    },
    "22052": {
        "name": "Xuân Lâm",
        "type": "xa",
        "slug": "xuan-lam",
        "name_with_type": "Xã Xuân Lâm",
        "path": "Xuân Lâm, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Lâm, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22052",
        "parent_code": "557"
    },
    "22053": {
        "name": "Xuân Thành",
        "type": "phuong",
        "slug": "xuan-thanh",
        "name_with_type": "Phường Xuân Thành",
        "path": "Xuân Thành, Sông Cầu, Phú Yên",
        "path_with_type": "Phường Xuân Thành, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22053",
        "parent_code": "557"
    },
    "22054": {
        "name": "Xuân Hải",
        "type": "xa",
        "slug": "xuan-hai",
        "name_with_type": "Xã Xuân Hải",
        "path": "Xuân Hải, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Hải, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22054",
        "parent_code": "557"
    },
    "22057": {
        "name": "Xuân Lộc",
        "type": "xa",
        "slug": "xuan-loc",
        "name_with_type": "Xã Xuân Lộc",
        "path": "Xuân Lộc, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Lộc, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22057",
        "parent_code": "557"
    },
    "22060": {
        "name": "Xuân Bình",
        "type": "xa",
        "slug": "xuan-binh",
        "name_with_type": "Xã Xuân Bình",
        "path": "Xuân Bình, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Bình, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22060",
        "parent_code": "557"
    },
    "22063": {
        "name": "Xuân Hòa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hòa",
        "path": "Xuân Hòa, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Hòa, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22063",
        "parent_code": "557"
    },
    "22066": {
        "name": "Xuân Cảnh",
        "type": "xa",
        "slug": "xuan-canh",
        "name_with_type": "Xã Xuân Cảnh",
        "path": "Xuân Cảnh, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Cảnh, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22066",
        "parent_code": "557"
    },
    "22069": {
        "name": "Xuân Thịnh",
        "type": "xa",
        "slug": "xuan-thinh",
        "name_with_type": "Xã Xuân Thịnh",
        "path": "Xuân Thịnh, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Thịnh, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22069",
        "parent_code": "557"
    },
    "22072": {
        "name": "Xuân Phương",
        "type": "xa",
        "slug": "xuan-phuong",
        "name_with_type": "Xã Xuân Phương",
        "path": "Xuân Phương, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Phương, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22072",
        "parent_code": "557"
    },
    "22073": {
        "name": "Xuân Yên",
        "type": "phuong",
        "slug": "xuan-yen",
        "name_with_type": "Phường Xuân Yên",
        "path": "Xuân Yên, Sông Cầu, Phú Yên",
        "path_with_type": "Phường Xuân Yên, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22073",
        "parent_code": "557"
    },
    "22075": {
        "name": "Xuân Thọ 1",
        "type": "xa",
        "slug": "xuan-tho-1",
        "name_with_type": "Xã Xuân Thọ 1",
        "path": "Xuân Thọ 1, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Thọ 1, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22075",
        "parent_code": "557"
    },
    "22076": {
        "name": "Xuân Đài",
        "type": "phuong",
        "slug": "xuan-dai",
        "name_with_type": "Phường Xuân Đài",
        "path": "Xuân Đài, Sông Cầu, Phú Yên",
        "path_with_type": "Phường Xuân Đài, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22076",
        "parent_code": "557"
    },
    "22078": {
        "name": "Xuân Thọ 2",
        "type": "xa",
        "slug": "xuan-tho-2",
        "name_with_type": "Xã Xuân Thọ 2",
        "path": "Xuân Thọ 2, Sông Cầu, Phú Yên",
        "path_with_type": "Xã Xuân Thọ 2, Thị xã Sông Cầu, Tỉnh Phú Yên",
        "code": "22078",
        "parent_code": "557"
    },
    "22081": {
        "name": "La Hai",
        "type": "thi-tran",
        "slug": "la-hai",
        "name_with_type": "Thị trấn La Hai",
        "path": "La Hai, Đồng Xuân, Phú Yên",
        "path_with_type": "Thị trấn La Hai, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22081",
        "parent_code": "558"
    },
    "22084": {
        "name": "Đa Lộc",
        "type": "xa",
        "slug": "da-loc",
        "name_with_type": "Xã Đa Lộc",
        "path": "Đa Lộc, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Đa Lộc, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22084",
        "parent_code": "558"
    },
    "22087": {
        "name": "Phú Mỡ",
        "type": "xa",
        "slug": "phu-mo",
        "name_with_type": "Xã Phú Mỡ",
        "path": "Phú Mỡ, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Phú Mỡ, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22087",
        "parent_code": "558"
    },
    "22090": {
        "name": "Xuân Lãnh",
        "type": "xa",
        "slug": "xuan-lanh",
        "name_with_type": "Xã Xuân Lãnh",
        "path": "Xuân Lãnh, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Xuân Lãnh, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22090",
        "parent_code": "558"
    },
    "22093": {
        "name": "Xuân Long",
        "type": "xa",
        "slug": "xuan-long",
        "name_with_type": "Xã Xuân Long",
        "path": "Xuân Long, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Xuân Long, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22093",
        "parent_code": "558"
    },
    "22096": {
        "name": "Xuân Quang 1",
        "type": "xa",
        "slug": "xuan-quang-1",
        "name_with_type": "Xã Xuân Quang 1",
        "path": "Xuân Quang 1, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Xuân Quang 1, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22096",
        "parent_code": "558"
    },
    "22099": {
        "name": "Xuân Sơn Bắc",
        "type": "xa",
        "slug": "xuan-son-bac",
        "name_with_type": "Xã Xuân Sơn Bắc",
        "path": "Xuân Sơn Bắc, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Xuân Sơn Bắc, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22099",
        "parent_code": "558"
    },
    "22102": {
        "name": "Xuân Quang 2",
        "type": "xa",
        "slug": "xuan-quang-2",
        "name_with_type": "Xã Xuân Quang 2",
        "path": "Xuân Quang 2, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Xuân Quang 2, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22102",
        "parent_code": "558"
    },
    "22105": {
        "name": "Xuân Sơn Nam",
        "type": "xa",
        "slug": "xuan-son-nam",
        "name_with_type": "Xã Xuân Sơn Nam",
        "path": "Xuân Sơn Nam, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Xuân Sơn Nam, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22105",
        "parent_code": "558"
    },
    "22108": {
        "name": "Xuân Quang 3",
        "type": "xa",
        "slug": "xuan-quang-3",
        "name_with_type": "Xã Xuân Quang 3",
        "path": "Xuân Quang 3, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Xuân Quang 3, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22108",
        "parent_code": "558"
    },
    "22111": {
        "name": "Xuân Phước",
        "type": "xa",
        "slug": "xuan-phuoc",
        "name_with_type": "Xã Xuân Phước",
        "path": "Xuân Phước, Đồng Xuân, Phú Yên",
        "path_with_type": "Xã Xuân Phước, Huyện Đồng Xuân, Tỉnh Phú Yên",
        "code": "22111",
        "parent_code": "558"
    },
    "22114": {
        "name": "Chí Thạnh",
        "type": "thi-tran",
        "slug": "chi-thanh",
        "name_with_type": "Thị trấn Chí Thạnh",
        "path": "Chí Thạnh, Tuy An, Phú Yên",
        "path_with_type": "Thị trấn Chí Thạnh, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22114",
        "parent_code": "559"
    },
    "22117": {
        "name": "An Dân",
        "type": "xa",
        "slug": "an-dan",
        "name_with_type": "Xã An Dân",
        "path": "An Dân, Tuy An, Phú Yên",
        "path_with_type": "Xã An Dân, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22117",
        "parent_code": "559"
    },
    "22120": {
        "name": "An Ninh Tây",
        "type": "xa",
        "slug": "an-ninh-tay",
        "name_with_type": "Xã An Ninh Tây",
        "path": "An Ninh Tây, Tuy An, Phú Yên",
        "path_with_type": "Xã An Ninh Tây, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22120",
        "parent_code": "559"
    },
    "22123": {
        "name": "An Ninh Đông",
        "type": "xa",
        "slug": "an-ninh-dong",
        "name_with_type": "Xã An Ninh Đông",
        "path": "An Ninh Đông, Tuy An, Phú Yên",
        "path_with_type": "Xã An Ninh Đông, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22123",
        "parent_code": "559"
    },
    "22126": {
        "name": "An Thạch",
        "type": "xa",
        "slug": "an-thach",
        "name_with_type": "Xã An Thạch",
        "path": "An Thạch, Tuy An, Phú Yên",
        "path_with_type": "Xã An Thạch, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22126",
        "parent_code": "559"
    },
    "22129": {
        "name": "An Định",
        "type": "xa",
        "slug": "an-dinh",
        "name_with_type": "Xã An Định",
        "path": "An Định, Tuy An, Phú Yên",
        "path_with_type": "Xã An Định, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22129",
        "parent_code": "559"
    },
    "22132": {
        "name": "An Nghiệp",
        "type": "xa",
        "slug": "an-nghiep",
        "name_with_type": "Xã An Nghiệp",
        "path": "An Nghiệp, Tuy An, Phú Yên",
        "path_with_type": "Xã An Nghiệp, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22132",
        "parent_code": "559"
    },
    "22135": {
        "name": "An Hải",
        "type": "xa",
        "slug": "an-hai",
        "name_with_type": "Xã An Hải",
        "path": "An Hải, Tuy An, Phú Yên",
        "path_with_type": "Xã An Hải, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22135",
        "parent_code": "559"
    },
    "22138": {
        "name": "An Cư",
        "type": "xa",
        "slug": "an-cu",
        "name_with_type": "Xã An Cư",
        "path": "An Cư, Tuy An, Phú Yên",
        "path_with_type": "Xã An Cư, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22138",
        "parent_code": "559"
    },
    "22141": {
        "name": "An Xuân",
        "type": "xa",
        "slug": "an-xuan",
        "name_with_type": "Xã An Xuân",
        "path": "An Xuân, Tuy An, Phú Yên",
        "path_with_type": "Xã An Xuân, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22141",
        "parent_code": "559"
    },
    "22144": {
        "name": "An Lĩnh",
        "type": "xa",
        "slug": "an-linh",
        "name_with_type": "Xã An Lĩnh",
        "path": "An Lĩnh, Tuy An, Phú Yên",
        "path_with_type": "Xã An Lĩnh, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22144",
        "parent_code": "559"
    },
    "22147": {
        "name": "An Hòa",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hòa",
        "path": "An Hòa, Tuy An, Phú Yên",
        "path_with_type": "Xã An Hòa, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22147",
        "parent_code": "559"
    },
    "22150": {
        "name": "An Hiệp",
        "type": "xa",
        "slug": "an-hiep",
        "name_with_type": "Xã An Hiệp",
        "path": "An Hiệp, Tuy An, Phú Yên",
        "path_with_type": "Xã An Hiệp, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22150",
        "parent_code": "559"
    },
    "22153": {
        "name": "An Mỹ",
        "type": "xa",
        "slug": "an-my",
        "name_with_type": "Xã An Mỹ",
        "path": "An Mỹ, Tuy An, Phú Yên",
        "path_with_type": "Xã An Mỹ, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22153",
        "parent_code": "559"
    },
    "22156": {
        "name": "An Chấn",
        "type": "xa",
        "slug": "an-chan",
        "name_with_type": "Xã An Chấn",
        "path": "An Chấn, Tuy An, Phú Yên",
        "path_with_type": "Xã An Chấn, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22156",
        "parent_code": "559"
    },
    "22159": {
        "name": "An Thọ",
        "type": "xa",
        "slug": "an-tho",
        "name_with_type": "Xã An Thọ",
        "path": "An Thọ, Tuy An, Phú Yên",
        "path_with_type": "Xã An Thọ, Huyện Tuy An, Tỉnh Phú Yên",
        "code": "22159",
        "parent_code": "559"
    },
    "22165": {
        "name": "Củng Sơn",
        "type": "thi-tran",
        "slug": "cung-son",
        "name_with_type": "Thị trấn Củng Sơn",
        "path": "Củng Sơn, Sơn Hòa, Phú Yên",
        "path_with_type": "Thị trấn Củng Sơn, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22165",
        "parent_code": "560"
    },
    "22168": {
        "name": "Phước Tân",
        "type": "xa",
        "slug": "phuoc-tan",
        "name_with_type": "Xã Phước Tân",
        "path": "Phước Tân, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Phước Tân, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22168",
        "parent_code": "560"
    },
    "22171": {
        "name": "Sơn Hội",
        "type": "xa",
        "slug": "son-hoi",
        "name_with_type": "Xã Sơn Hội",
        "path": "Sơn Hội, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Sơn Hội, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22171",
        "parent_code": "560"
    },
    "22174": {
        "name": "Sơn Định",
        "type": "xa",
        "slug": "son-dinh",
        "name_with_type": "Xã Sơn Định",
        "path": "Sơn Định, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Sơn Định, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22174",
        "parent_code": "560"
    },
    "22177": {
        "name": "Sơn Long",
        "type": "xa",
        "slug": "son-long",
        "name_with_type": "Xã Sơn Long",
        "path": "Sơn Long, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Sơn Long, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22177",
        "parent_code": "560"
    },
    "22180": {
        "name": "Cà Lúi",
        "type": "xa",
        "slug": "ca-lui",
        "name_with_type": "Xã Cà Lúi",
        "path": "Cà Lúi, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Cà Lúi, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22180",
        "parent_code": "560"
    },
    "22183": {
        "name": "Sơn Phước",
        "type": "xa",
        "slug": "son-phuoc",
        "name_with_type": "Xã Sơn Phước",
        "path": "Sơn Phước, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Sơn Phước, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22183",
        "parent_code": "560"
    },
    "22186": {
        "name": "Sơn Xuân",
        "type": "xa",
        "slug": "son-xuan",
        "name_with_type": "Xã Sơn Xuân",
        "path": "Sơn Xuân, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Sơn Xuân, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22186",
        "parent_code": "560"
    },
    "22189": {
        "name": "Sơn Nguyên",
        "type": "xa",
        "slug": "son-nguyen",
        "name_with_type": "Xã Sơn Nguyên",
        "path": "Sơn Nguyên, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Sơn Nguyên, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22189",
        "parent_code": "560"
    },
    "22192": {
        "name": "Eachà Rang",
        "type": "xa",
        "slug": "eacha-rang",
        "name_with_type": "Xã Eachà Rang",
        "path": "Eachà Rang, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Eachà Rang, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22192",
        "parent_code": "560"
    },
    "22195": {
        "name": "Krông Pa",
        "type": "xa",
        "slug": "krong-pa",
        "name_with_type": "Xã Krông Pa",
        "path": "Krông Pa, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Krông Pa, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22195",
        "parent_code": "560"
    },
    "22198": {
        "name": "Suối Bạc",
        "type": "xa",
        "slug": "suoi-bac",
        "name_with_type": "Xã Suối Bạc",
        "path": "Suối Bạc, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Suối Bạc, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22198",
        "parent_code": "560"
    },
    "22201": {
        "name": "Sơn Hà",
        "type": "xa",
        "slug": "son-ha",
        "name_with_type": "Xã Sơn Hà",
        "path": "Sơn Hà, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Sơn Hà, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22201",
        "parent_code": "560"
    },
    "22204": {
        "name": "Suối Trai",
        "type": "xa",
        "slug": "suoi-trai",
        "name_with_type": "Xã Suối Trai",
        "path": "Suối Trai, Sơn Hòa, Phú Yên",
        "path_with_type": "Xã Suối Trai, Huyện Sơn Hòa, Tỉnh Phú Yên",
        "code": "22204",
        "parent_code": "560"
    },
    "22207": {
        "name": "Hai Riêng",
        "type": "thi-tran",
        "slug": "hai-rieng",
        "name_with_type": "Thị trấn Hai Riêng",
        "path": "Hai Riêng, Sông Hinh, Phú Yên",
        "path_with_type": "Thị trấn Hai Riêng, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22207",
        "parent_code": "561"
    },
    "22210": {
        "name": "Ea Lâm",
        "type": "xa",
        "slug": "ea-lam",
        "name_with_type": "Xã Ea Lâm",
        "path": "Ea Lâm, Sông Hinh, Phú Yên",
        "path_with_type": "Xã Ea Lâm, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22210",
        "parent_code": "561"
    },
    "22213": {
        "name": "Đức Bình Tây",
        "type": "xa",
        "slug": "duc-binh-tay",
        "name_with_type": "Xã Đức Bình Tây",
        "path": "Đức Bình Tây, Sông Hinh, Phú Yên",
        "path_with_type": "Xã Đức Bình Tây, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22213",
        "parent_code": "561"
    },
    "22216": {
        "name": "Ea Bá",
        "type": "xa",
        "slug": "ea-ba",
        "name_with_type": "Xã Ea Bá",
        "path": "Ea Bá, Sông Hinh, Phú Yên",
        "path_with_type": "Xã Ea Bá, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22216",
        "parent_code": "561"
    },
    "22219": {
        "name": "Sơn Giang",
        "type": "xa",
        "slug": "son-giang",
        "name_with_type": "Xã Sơn Giang",
        "path": "Sơn Giang, Sông Hinh, Phú Yên",
        "path_with_type": "Xã Sơn Giang, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22219",
        "parent_code": "561"
    },
    "22222": {
        "name": "Đức Bình Đông",
        "type": "xa",
        "slug": "duc-binh-dong",
        "name_with_type": "Xã Đức Bình Đông",
        "path": "Đức Bình Đông, Sông Hinh, Phú Yên",
        "path_with_type": "Xã Đức Bình Đông, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22222",
        "parent_code": "561"
    },
    "22225": {
        "name": "EaBar",
        "type": "xa",
        "slug": "eabar",
        "name_with_type": "Xã EaBar",
        "path": "EaBar, Sông Hinh, Phú Yên",
        "path_with_type": "Xã EaBar, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22225",
        "parent_code": "561"
    },
    "22228": {
        "name": "EaBia",
        "type": "xa",
        "slug": "eabia",
        "name_with_type": "Xã EaBia",
        "path": "EaBia, Sông Hinh, Phú Yên",
        "path_with_type": "Xã EaBia, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22228",
        "parent_code": "561"
    },
    "22231": {
        "name": "EaTrol",
        "type": "xa",
        "slug": "eatrol",
        "name_with_type": "Xã EaTrol",
        "path": "EaTrol, Sông Hinh, Phú Yên",
        "path_with_type": "Xã EaTrol, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22231",
        "parent_code": "561"
    },
    "22234": {
        "name": "Sông Hinh",
        "type": "xa",
        "slug": "song-hinh",
        "name_with_type": "Xã Sông Hinh",
        "path": "Sông Hinh, Sông Hinh, Phú Yên",
        "path_with_type": "Xã Sông Hinh, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22234",
        "parent_code": "561"
    },
    "22237": {
        "name": "Ealy",
        "type": "xa",
        "slug": "ealy",
        "name_with_type": "Xã Ealy",
        "path": "Ealy, Sông Hinh, Phú Yên",
        "path_with_type": "Xã Ealy, Huyện Sông Hinh, Tỉnh Phú Yên",
        "code": "22237",
        "parent_code": "561"
    },
    "22249": {
        "name": "Sơn Thành Tây",
        "type": "xa",
        "slug": "son-thanh-tay",
        "name_with_type": "Xã Sơn Thành Tây",
        "path": "Sơn Thành Tây, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Sơn Thành Tây, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22249",
        "parent_code": "562"
    },
    "22250": {
        "name": "Sơn Thành Đông",
        "type": "xa",
        "slug": "son-thanh-dong",
        "name_with_type": "Xã Sơn Thành Đông",
        "path": "Sơn Thành Đông, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Sơn Thành Đông, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22250",
        "parent_code": "562"
    },
    "22252": {
        "name": "Hòa Bình 1",
        "type": "xa",
        "slug": "hoa-binh-1",
        "name_with_type": "Xã Hòa Bình 1",
        "path": "Hòa Bình 1, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Bình 1, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22252",
        "parent_code": "562"
    },
    "22255": {
        "name": "Phú Thứ",
        "type": "thi-tran",
        "slug": "phu-thu",
        "name_with_type": "Thị trấn Phú Thứ",
        "path": "Phú Thứ, Tây Hoà, Phú Yên",
        "path_with_type": "Thị trấn Phú Thứ, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22255",
        "parent_code": "562"
    },
    "22264": {
        "name": "Hòa Phong",
        "type": "xa",
        "slug": "hoa-phong",
        "name_with_type": "Xã Hòa Phong",
        "path": "Hòa Phong, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Phong, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22264",
        "parent_code": "562"
    },
    "22270": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Phú, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22270",
        "parent_code": "562"
    },
    "22273": {
        "name": "Hòa Tân Tây",
        "type": "xa",
        "slug": "hoa-tan-tay",
        "name_with_type": "Xã Hòa Tân Tây",
        "path": "Hòa Tân Tây, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Tân Tây, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22273",
        "parent_code": "562"
    },
    "22276": {
        "name": "Hòa Đồng",
        "type": "xa",
        "slug": "hoa-dong",
        "name_with_type": "Xã Hòa Đồng",
        "path": "Hòa Đồng, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Đồng, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22276",
        "parent_code": "562"
    },
    "22285": {
        "name": "Hòa Mỹ Đông",
        "type": "xa",
        "slug": "hoa-my-dong",
        "name_with_type": "Xã Hòa Mỹ Đông",
        "path": "Hòa Mỹ Đông, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Mỹ Đông, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22285",
        "parent_code": "562"
    },
    "22288": {
        "name": "Hòa Mỹ Tây",
        "type": "xa",
        "slug": "hoa-my-tay",
        "name_with_type": "Xã Hòa Mỹ Tây",
        "path": "Hòa Mỹ Tây, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Mỹ Tây, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22288",
        "parent_code": "562"
    },
    "22294": {
        "name": "Hòa Thịnh",
        "type": "xa",
        "slug": "hoa-thinh",
        "name_with_type": "Xã Hòa Thịnh",
        "path": "Hòa Thịnh, Tây Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Thịnh, Huyện Tây Hoà, Tỉnh Phú Yên",
        "code": "22294",
        "parent_code": "562"
    },
    "22303": {
        "name": "Hòa Quang Bắc",
        "type": "xa",
        "slug": "hoa-quang-bac",
        "name_with_type": "Xã Hòa Quang Bắc",
        "path": "Hòa Quang Bắc, Phú Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Quang Bắc, Huyện Phú Hoà, Tỉnh Phú Yên",
        "code": "22303",
        "parent_code": "563"
    },
    "22306": {
        "name": "Hòa Quang Nam",
        "type": "xa",
        "slug": "hoa-quang-nam",
        "name_with_type": "Xã Hòa Quang Nam",
        "path": "Hòa Quang Nam, Phú Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Quang Nam, Huyện Phú Hoà, Tỉnh Phú Yên",
        "code": "22306",
        "parent_code": "563"
    },
    "22309": {
        "name": "Hòa Hội",
        "type": "xa",
        "slug": "hoa-hoi",
        "name_with_type": "Xã Hòa Hội",
        "path": "Hòa Hội, Phú Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Hội, Huyện Phú Hoà, Tỉnh Phú Yên",
        "code": "22309",
        "parent_code": "563"
    },
    "22312": {
        "name": "Hòa Trị",
        "type": "xa",
        "slug": "hoa-tri",
        "name_with_type": "Xã Hòa Trị",
        "path": "Hòa Trị, Phú Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Trị, Huyện Phú Hoà, Tỉnh Phú Yên",
        "code": "22312",
        "parent_code": "563"
    },
    "22315": {
        "name": "Hòa An",
        "type": "xa",
        "slug": "hoa-an",
        "name_with_type": "Xã Hòa An",
        "path": "Hòa An, Phú Hoà, Phú Yên",
        "path_with_type": "Xã Hòa An, Huyện Phú Hoà, Tỉnh Phú Yên",
        "code": "22315",
        "parent_code": "563"
    },
    "22318": {
        "name": "Hòa Định Đông",
        "type": "xa",
        "slug": "hoa-dinh-dong",
        "name_with_type": "Xã Hòa Định Đông",
        "path": "Hòa Định Đông, Phú Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Định Đông, Huyện Phú Hoà, Tỉnh Phú Yên",
        "code": "22318",
        "parent_code": "563"
    },
    "22319": {
        "name": "Phú Hoà",
        "type": "thi-tran",
        "slug": "phu-hoa",
        "name_with_type": "Thị Trấn Phú Hoà",
        "path": "Phú Hoà, Phú Hoà, Phú Yên",
        "path_with_type": "Thị Trấn Phú Hoà, Huyện Phú Hoà, Tỉnh Phú Yên",
        "code": "22319",
        "parent_code": "563"
    },
    "22321": {
        "name": "Hòa Định Tây",
        "type": "xa",
        "slug": "hoa-dinh-tay",
        "name_with_type": "Xã Hòa Định Tây",
        "path": "Hòa Định Tây, Phú Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Định Tây, Huyện Phú Hoà, Tỉnh Phú Yên",
        "code": "22321",
        "parent_code": "563"
    },
    "22324": {
        "name": "Hòa Thắng",
        "type": "xa",
        "slug": "hoa-thang",
        "name_with_type": "Xã Hòa Thắng",
        "path": "Hòa Thắng, Phú Hoà, Phú Yên",
        "path_with_type": "Xã Hòa Thắng, Huyện Phú Hoà, Tỉnh Phú Yên",
        "code": "22324",
        "parent_code": "563"
    },
    "22243": {
        "name": "Hòa Thành",
        "type": "xa",
        "slug": "hoa-thanh",
        "name_with_type": "Xã Hòa Thành",
        "path": "Hòa Thành, Đông Hòa, Phú Yên",
        "path_with_type": "Xã Hòa Thành, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22243",
        "parent_code": "564"
    },
    "22246": {
        "name": "Hòa Hiệp Bắc",
        "type": "xa",
        "slug": "hoa-hiep-bac",
        "name_with_type": "Xã Hòa Hiệp Bắc",
        "path": "Hòa Hiệp Bắc, Đông Hòa, Phú Yên",
        "path_with_type": "Xã Hòa Hiệp Bắc, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22246",
        "parent_code": "564"
    },
    "22258": {
        "name": "Hoà Vinh",
        "type": "thi-tran",
        "slug": "hoa-vinh",
        "name_with_type": "Thị trấn Hoà Vinh",
        "path": "Hoà Vinh, Đông Hòa, Phú Yên",
        "path_with_type": "Thị trấn Hoà Vinh, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22258",
        "parent_code": "564"
    },
    "22261": {
        "name": "Hoà Hiệp Trung",
        "type": "thi-tran",
        "slug": "hoa-hiep-trung",
        "name_with_type": "Thị trấn Hoà Hiệp Trung",
        "path": "Hoà Hiệp Trung, Đông Hòa, Phú Yên",
        "path_with_type": "Thị trấn Hoà Hiệp Trung, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22261",
        "parent_code": "564"
    },
    "22267": {
        "name": "Hòa Tân Đông",
        "type": "xa",
        "slug": "hoa-tan-dong",
        "name_with_type": "Xã Hòa Tân Đông",
        "path": "Hòa Tân Đông, Đông Hòa, Phú Yên",
        "path_with_type": "Xã Hòa Tân Đông, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22267",
        "parent_code": "564"
    },
    "22279": {
        "name": "Hòa Xuân Tây",
        "type": "xa",
        "slug": "hoa-xuan-tay",
        "name_with_type": "Xã Hòa Xuân Tây",
        "path": "Hòa Xuân Tây, Đông Hòa, Phú Yên",
        "path_with_type": "Xã Hòa Xuân Tây, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22279",
        "parent_code": "564"
    },
    "22282": {
        "name": "Hòa Hiệp Nam",
        "type": "xa",
        "slug": "hoa-hiep-nam",
        "name_with_type": "Xã Hòa Hiệp Nam",
        "path": "Hòa Hiệp Nam, Đông Hòa, Phú Yên",
        "path_with_type": "Xã Hòa Hiệp Nam, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22282",
        "parent_code": "564"
    },
    "22291": {
        "name": "Hòa Xuân Đông",
        "type": "xa",
        "slug": "hoa-xuan-dong",
        "name_with_type": "Xã Hòa Xuân Đông",
        "path": "Hòa Xuân Đông, Đông Hòa, Phú Yên",
        "path_with_type": "Xã Hòa Xuân Đông, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22291",
        "parent_code": "564"
    },
    "22297": {
        "name": "Hòa Tâm",
        "type": "xa",
        "slug": "hoa-tam",
        "name_with_type": "Xã Hòa Tâm",
        "path": "Hòa Tâm, Đông Hòa, Phú Yên",
        "path_with_type": "Xã Hòa Tâm, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22297",
        "parent_code": "564"
    },
    "22300": {
        "name": "Hòa Xuân Nam",
        "type": "xa",
        "slug": "hoa-xuan-nam",
        "name_with_type": "Xã Hòa Xuân Nam",
        "path": "Hòa Xuân Nam, Đông Hòa, Phú Yên",
        "path_with_type": "Xã Hòa Xuân Nam, Huyện Đông Hòa, Tỉnh Phú Yên",
        "code": "22300",
        "parent_code": "564"
    },
    "05971": {
        "name": "Hoàng Văn Thụ",
        "type": "phuong",
        "slug": "hoang-van-thu",
        "name_with_type": "Phường Hoàng Văn Thụ",
        "path": "Hoàng Văn Thụ, Lạng Sơn, Lạng Sơn",
        "path_with_type": "Phường Hoàng Văn Thụ, Thành phố Lạng Sơn, Tỉnh Lạng Sơn",
        "code": "05971",
        "parent_code": "178"
    },
    "05974": {
        "name": "Tam Thanh",
        "type": "phuong",
        "slug": "tam-thanh",
        "name_with_type": "Phường Tam Thanh",
        "path": "Tam Thanh, Lạng Sơn, Lạng Sơn",
        "path_with_type": "Phường Tam Thanh, Thành phố Lạng Sơn, Tỉnh Lạng Sơn",
        "code": "05974",
        "parent_code": "178"
    },
    "05977": {
        "name": "Vĩnh Trại",
        "type": "phuong",
        "slug": "vinh-trai",
        "name_with_type": "Phường Vĩnh Trại",
        "path": "Vĩnh Trại, Lạng Sơn, Lạng Sơn",
        "path_with_type": "Phường Vĩnh Trại, Thành phố Lạng Sơn, Tỉnh Lạng Sơn",
        "code": "05977",
        "parent_code": "178"
    },
    "05980": {
        "name": "Đông Kinh",
        "type": "phuong",
        "slug": "dong-kinh",
        "name_with_type": "Phường Đông Kinh",
        "path": "Đông Kinh, Lạng Sơn, Lạng Sơn",
        "path_with_type": "Phường Đông Kinh, Thành phố Lạng Sơn, Tỉnh Lạng Sơn",
        "code": "05980",
        "parent_code": "178"
    },
    "05983": {
        "name": "Chi Lăng",
        "type": "phuong",
        "slug": "chi-lang",
        "name_with_type": "Phường Chi Lăng",
        "path": "Chi Lăng, Lạng Sơn, Lạng Sơn",
        "path_with_type": "Phường Chi Lăng, Thành phố Lạng Sơn, Tỉnh Lạng Sơn",
        "code": "05983",
        "parent_code": "178"
    },
    "05986": {
        "name": "Hoàng Đồng",
        "type": "xa",
        "slug": "hoang-dong",
        "name_with_type": "Xã Hoàng Đồng",
        "path": "Hoàng Đồng, Lạng Sơn, Lạng Sơn",
        "path_with_type": "Xã Hoàng Đồng, Thành phố Lạng Sơn, Tỉnh Lạng Sơn",
        "code": "05986",
        "parent_code": "178"
    },
    "05989": {
        "name": "Quảng Lạc",
        "type": "xa",
        "slug": "quang-lac",
        "name_with_type": "Xã Quảng Lạc",
        "path": "Quảng Lạc, Lạng Sơn, Lạng Sơn",
        "path_with_type": "Xã Quảng Lạc, Thành phố Lạng Sơn, Tỉnh Lạng Sơn",
        "code": "05989",
        "parent_code": "178"
    },
    "05992": {
        "name": "Mai Pha",
        "type": "xa",
        "slug": "mai-pha",
        "name_with_type": "Xã Mai Pha",
        "path": "Mai Pha, Lạng Sơn, Lạng Sơn",
        "path_with_type": "Xã Mai Pha, Thành phố Lạng Sơn, Tỉnh Lạng Sơn",
        "code": "05992",
        "parent_code": "178"
    },
    "05995": {
        "name": "Thất Khê",
        "type": "thi-tran",
        "slug": "that-khe",
        "name_with_type": "Thị trấn Thất Khê",
        "path": "Thất Khê, Tràng Định, Lạng Sơn",
        "path_with_type": "Thị trấn Thất Khê, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "05995",
        "parent_code": "180"
    },
    "05998": {
        "name": "Khánh Long",
        "type": "xa",
        "slug": "khanh-long",
        "name_with_type": "Xã Khánh Long",
        "path": "Khánh Long, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Khánh Long, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "05998",
        "parent_code": "180"
    },
    "06001": {
        "name": "Đoàn Kết",
        "type": "xa",
        "slug": "doan-ket",
        "name_with_type": "Xã Đoàn Kết",
        "path": "Đoàn Kết, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Đoàn Kết, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06001",
        "parent_code": "180"
    },
    "06004": {
        "name": "Quốc Khánh",
        "type": "xa",
        "slug": "quoc-khanh",
        "name_with_type": "Xã Quốc Khánh",
        "path": "Quốc Khánh, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Quốc Khánh, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06004",
        "parent_code": "180"
    },
    "06007": {
        "name": "Vĩnh Tiến",
        "type": "xa",
        "slug": "vinh-tien",
        "name_with_type": "Xã Vĩnh Tiến",
        "path": "Vĩnh Tiến, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Vĩnh Tiến, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06007",
        "parent_code": "180"
    },
    "06010": {
        "name": "Cao Minh",
        "type": "xa",
        "slug": "cao-minh",
        "name_with_type": "Xã Cao Minh",
        "path": "Cao Minh, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Cao Minh, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06010",
        "parent_code": "180"
    },
    "06013": {
        "name": "Chí Minh",
        "type": "xa",
        "slug": "chi-minh",
        "name_with_type": "Xã Chí Minh",
        "path": "Chí Minh, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Chí Minh, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06013",
        "parent_code": "180"
    },
    "06016": {
        "name": "Tri Phương",
        "type": "xa",
        "slug": "tri-phuong",
        "name_with_type": "Xã Tri Phương",
        "path": "Tri Phương, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Tri Phương, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06016",
        "parent_code": "180"
    },
    "06019": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Tân Tiến, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06019",
        "parent_code": "180"
    },
    "06022": {
        "name": "Tân Yên",
        "type": "xa",
        "slug": "tan-yen",
        "name_with_type": "Xã Tân Yên",
        "path": "Tân Yên, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Tân Yên, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06022",
        "parent_code": "180"
    },
    "06025": {
        "name": "Đội Cấn",
        "type": "xa",
        "slug": "doi-can",
        "name_with_type": "Xã Đội Cấn",
        "path": "Đội Cấn, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Đội Cấn, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06025",
        "parent_code": "180"
    },
    "06028": {
        "name": "Tân Minh",
        "type": "xa",
        "slug": "tan-minh",
        "name_with_type": "Xã Tân Minh",
        "path": "Tân Minh, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Tân Minh, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06028",
        "parent_code": "180"
    },
    "06031": {
        "name": "Kim Đồng",
        "type": "xa",
        "slug": "kim-dong",
        "name_with_type": "Xã Kim Đồng",
        "path": "Kim Đồng, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Kim Đồng, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06031",
        "parent_code": "180"
    },
    "06034": {
        "name": "Chi Lăng",
        "type": "xa",
        "slug": "chi-lang",
        "name_with_type": "Xã Chi Lăng",
        "path": "Chi Lăng, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Chi Lăng, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06034",
        "parent_code": "180"
    },
    "06037": {
        "name": "Trung Thành",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thành",
        "path": "Trung Thành, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Trung Thành, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06037",
        "parent_code": "180"
    },
    "06040": {
        "name": "Đại Đồng",
        "type": "xa",
        "slug": "dai-dong",
        "name_with_type": "Xã Đại Đồng",
        "path": "Đại Đồng, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Đại Đồng, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06040",
        "parent_code": "180"
    },
    "06043": {
        "name": "Đào Viên",
        "type": "xa",
        "slug": "dao-vien",
        "name_with_type": "Xã Đào Viên",
        "path": "Đào Viên, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Đào Viên, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06043",
        "parent_code": "180"
    },
    "06046": {
        "name": "Đề Thám",
        "type": "xa",
        "slug": "de-tham",
        "name_with_type": "Xã Đề Thám",
        "path": "Đề Thám, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Đề Thám, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06046",
        "parent_code": "180"
    },
    "06049": {
        "name": "Kháng Chiến",
        "type": "xa",
        "slug": "khang-chien",
        "name_with_type": "Xã Kháng Chiến",
        "path": "Kháng Chiến, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Kháng Chiến, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06049",
        "parent_code": "180"
    },
    "06052": {
        "name": "Bắc Ái",
        "type": "xa",
        "slug": "bac-ai",
        "name_with_type": "Xã Bắc Ái",
        "path": "Bắc Ái, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Bắc Ái, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06052",
        "parent_code": "180"
    },
    "06055": {
        "name": "Hùng Sơn",
        "type": "xa",
        "slug": "hung-son",
        "name_with_type": "Xã Hùng Sơn",
        "path": "Hùng Sơn, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Hùng Sơn, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06055",
        "parent_code": "180"
    },
    "06058": {
        "name": "Quốc Việt",
        "type": "xa",
        "slug": "quoc-viet",
        "name_with_type": "Xã Quốc Việt",
        "path": "Quốc Việt, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Quốc Việt, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06058",
        "parent_code": "180"
    },
    "06061": {
        "name": "Hùng Việt",
        "type": "xa",
        "slug": "hung-viet",
        "name_with_type": "Xã Hùng Việt",
        "path": "Hùng Việt, Tràng Định, Lạng Sơn",
        "path_with_type": "Xã Hùng Việt, Huyện Tràng Định, Tỉnh Lạng Sơn",
        "code": "06061",
        "parent_code": "180"
    },
    "06064": {
        "name": "Bình Gia",
        "type": "thi-tran",
        "slug": "binh-gia",
        "name_with_type": "Thị trấn Bình Gia",
        "path": "Bình Gia, Bình Gia, Lạng Sơn",
        "path_with_type": "Thị trấn Bình Gia, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06064",
        "parent_code": "181"
    },
    "06067": {
        "name": "Hưng Đạo",
        "type": "xa",
        "slug": "hung-dao",
        "name_with_type": "Xã Hưng Đạo",
        "path": "Hưng Đạo, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Hưng Đạo, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06067",
        "parent_code": "181"
    },
    "06070": {
        "name": "Vĩnh Yên",
        "type": "xa",
        "slug": "vinh-yen",
        "name_with_type": "Xã Vĩnh Yên",
        "path": "Vĩnh Yên, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Vĩnh Yên, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06070",
        "parent_code": "181"
    },
    "06073": {
        "name": "Hoa Thám",
        "type": "xa",
        "slug": "hoa-tham",
        "name_with_type": "Xã Hoa Thám",
        "path": "Hoa Thám, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Hoa Thám, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06073",
        "parent_code": "181"
    },
    "06076": {
        "name": "Quý Hòa",
        "type": "xa",
        "slug": "quy-hoa",
        "name_with_type": "Xã Quý Hòa",
        "path": "Quý Hòa, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Quý Hòa, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06076",
        "parent_code": "181"
    },
    "06079": {
        "name": "Hồng Phong",
        "type": "xa",
        "slug": "hong-phong",
        "name_with_type": "Xã Hồng Phong",
        "path": "Hồng Phong, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Hồng Phong, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06079",
        "parent_code": "181"
    },
    "06082": {
        "name": "Yên Lỗ",
        "type": "xa",
        "slug": "yen-lo",
        "name_with_type": "Xã Yên Lỗ",
        "path": "Yên Lỗ, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Yên Lỗ, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06082",
        "parent_code": "181"
    },
    "06085": {
        "name": "Thiện Hòa",
        "type": "xa",
        "slug": "thien-hoa",
        "name_with_type": "Xã Thiện Hòa",
        "path": "Thiện Hòa, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Thiện Hòa, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06085",
        "parent_code": "181"
    },
    "06088": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Quang Trung, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06088",
        "parent_code": "181"
    },
    "06091": {
        "name": "Thiện Thuật",
        "type": "xa",
        "slug": "thien-thuat",
        "name_with_type": "Xã Thiện Thuật",
        "path": "Thiện Thuật, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Thiện Thuật, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06091",
        "parent_code": "181"
    },
    "06094": {
        "name": "Minh Khai",
        "type": "xa",
        "slug": "minh-khai",
        "name_with_type": "Xã Minh Khai",
        "path": "Minh Khai, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Minh Khai, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06094",
        "parent_code": "181"
    },
    "06097": {
        "name": "Thiện Long",
        "type": "xa",
        "slug": "thien-long",
        "name_with_type": "Xã Thiện Long",
        "path": "Thiện Long, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Thiện Long, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06097",
        "parent_code": "181"
    },
    "06100": {
        "name": "Hoàng Văn Thụ",
        "type": "xa",
        "slug": "hoang-van-thu",
        "name_with_type": "Xã Hoàng Văn Thụ",
        "path": "Hoàng Văn Thụ, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Hoàng Văn Thụ, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06100",
        "parent_code": "181"
    },
    "06103": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Hòa Bình, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06103",
        "parent_code": "181"
    },
    "06106": {
        "name": "Mông Ân",
        "type": "xa",
        "slug": "mong-an",
        "name_with_type": "Xã Mông Ân",
        "path": "Mông Ân, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Mông Ân, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06106",
        "parent_code": "181"
    },
    "06109": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Tân Hòa, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06109",
        "parent_code": "181"
    },
    "06112": {
        "name": "Tô Hiệu",
        "type": "xa",
        "slug": "to-hieu",
        "name_with_type": "Xã Tô Hiệu",
        "path": "Tô Hiệu, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Tô Hiệu, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06112",
        "parent_code": "181"
    },
    "06115": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Hồng Thái, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06115",
        "parent_code": "181"
    },
    "06118": {
        "name": "Bình La",
        "type": "xa",
        "slug": "binh-la",
        "name_with_type": "Xã Bình La",
        "path": "Bình La, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Bình La, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06118",
        "parent_code": "181"
    },
    "06121": {
        "name": "Tân Văn",
        "type": "xa",
        "slug": "tan-van",
        "name_with_type": "Xã Tân Văn",
        "path": "Tân Văn, Bình Gia, Lạng Sơn",
        "path_with_type": "Xã Tân Văn, Huyện Bình Gia, Tỉnh Lạng Sơn",
        "code": "06121",
        "parent_code": "181"
    },
    "06124": {
        "name": "Na Sầm",
        "type": "thi-tran",
        "slug": "na-sam",
        "name_with_type": "Thị trấn Na Sầm",
        "path": "Na Sầm, Văn Lãng, Lạng Sơn",
        "path_with_type": "Thị trấn Na Sầm, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06124",
        "parent_code": "182"
    },
    "06127": {
        "name": "Trùng Khánh",
        "type": "xa",
        "slug": "trung-khanh",
        "name_with_type": "Xã Trùng Khánh",
        "path": "Trùng Khánh, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Trùng Khánh, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06127",
        "parent_code": "182"
    },
    "06130": {
        "name": "Tân Việt",
        "type": "xa",
        "slug": "tan-viet",
        "name_with_type": "Xã Tân Việt",
        "path": "Tân Việt, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Tân Việt, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06130",
        "parent_code": "182"
    },
    "06133": {
        "name": "Bắc La",
        "type": "xa",
        "slug": "bac-la",
        "name_with_type": "Xã Bắc La",
        "path": "Bắc La, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Bắc La, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06133",
        "parent_code": "182"
    },
    "06136": {
        "name": "Thụy Hùng",
        "type": "xa",
        "slug": "thuy-hung",
        "name_with_type": "Xã Thụy Hùng",
        "path": "Thụy Hùng, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Thụy Hùng, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06136",
        "parent_code": "182"
    },
    "06139": {
        "name": "Trùng Quán",
        "type": "xa",
        "slug": "trung-quan",
        "name_with_type": "Xã Trùng Quán",
        "path": "Trùng Quán, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Trùng Quán, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06139",
        "parent_code": "182"
    },
    "06142": {
        "name": "Tân Tác",
        "type": "xa",
        "slug": "tan-tac",
        "name_with_type": "Xã Tân Tác",
        "path": "Tân Tác, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Tân Tác, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06142",
        "parent_code": "182"
    },
    "06145": {
        "name": "An Hùng",
        "type": "xa",
        "slug": "an-hung",
        "name_with_type": "Xã An Hùng",
        "path": "An Hùng, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã An Hùng, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06145",
        "parent_code": "182"
    },
    "06148": {
        "name": "Thanh Long",
        "type": "xa",
        "slug": "thanh-long",
        "name_with_type": "Xã Thanh Long",
        "path": "Thanh Long, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Thanh Long, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06148",
        "parent_code": "182"
    },
    "06151": {
        "name": "Hội Hoan",
        "type": "xa",
        "slug": "hoi-hoan",
        "name_with_type": "Xã Hội Hoan",
        "path": "Hội Hoan, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Hội Hoan, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06151",
        "parent_code": "182"
    },
    "06154": {
        "name": "Tân Lang",
        "type": "xa",
        "slug": "tan-lang",
        "name_with_type": "Xã Tân Lang",
        "path": "Tân Lang, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Tân Lang, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06154",
        "parent_code": "182"
    },
    "06157": {
        "name": "Hoàng Việt",
        "type": "xa",
        "slug": "hoang-viet",
        "name_with_type": "Xã Hoàng Việt",
        "path": "Hoàng Việt, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Hoàng Việt, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06157",
        "parent_code": "182"
    },
    "06160": {
        "name": "Gia Miễn",
        "type": "xa",
        "slug": "gia-mien",
        "name_with_type": "Xã Gia Miễn",
        "path": "Gia Miễn, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Gia Miễn, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06160",
        "parent_code": "182"
    },
    "06163": {
        "name": "Thành Hòa",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thành Hòa",
        "path": "Thành Hòa, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Thành Hòa, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06163",
        "parent_code": "182"
    },
    "06166": {
        "name": "Tân Thanh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thanh",
        "path": "Tân Thanh, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Tân Thanh, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06166",
        "parent_code": "182"
    },
    "06169": {
        "name": "Nam La",
        "type": "xa",
        "slug": "nam-la",
        "name_with_type": "Xã Nam La",
        "path": "Nam La, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Nam La, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06169",
        "parent_code": "182"
    },
    "06172": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Tân Mỹ, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06172",
        "parent_code": "182"
    },
    "06175": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Hồng Thái, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06175",
        "parent_code": "182"
    },
    "06178": {
        "name": "Hoàng Văn Thụ",
        "type": "xa",
        "slug": "hoang-van-thu",
        "name_with_type": "Xã  Hoàng Văn Thụ",
        "path": "Hoàng Văn Thụ, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã  Hoàng Văn Thụ, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06178",
        "parent_code": "182"
    },
    "06181": {
        "name": "Nhạc Kỳ",
        "type": "xa",
        "slug": "nhac-ky",
        "name_with_type": "Xã Nhạc Kỳ",
        "path": "Nhạc Kỳ, Văn Lãng, Lạng Sơn",
        "path_with_type": "Xã Nhạc Kỳ, Huyện Văn Lãng, Tỉnh Lạng Sơn",
        "code": "06181",
        "parent_code": "182"
    },
    "06184": {
        "name": "Đồng Đăng",
        "type": "thi-tran",
        "slug": "dong-dang",
        "name_with_type": "Thị trấn Đồng Đăng",
        "path": "Đồng Đăng, Cao Lộc, Lạng Sơn",
        "path_with_type": "Thị trấn Đồng Đăng, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06184",
        "parent_code": "183"
    },
    "06187": {
        "name": "Cao Lộc",
        "type": "thi-tran",
        "slug": "cao-loc",
        "name_with_type": "Thị trấn Cao Lộc",
        "path": "Cao Lộc, Cao Lộc, Lạng Sơn",
        "path_with_type": "Thị trấn Cao Lộc, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06187",
        "parent_code": "183"
    },
    "06190": {
        "name": "Bảo Lâm",
        "type": "xa",
        "slug": "bao-lam",
        "name_with_type": "Xã Bảo Lâm",
        "path": "Bảo Lâm, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Bảo Lâm, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06190",
        "parent_code": "183"
    },
    "06193": {
        "name": "Thanh Lòa",
        "type": "xa",
        "slug": "thanh-loa",
        "name_with_type": "Xã Thanh Lòa",
        "path": "Thanh Lòa, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Thanh Lòa, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06193",
        "parent_code": "183"
    },
    "06196": {
        "name": "Cao Lâu",
        "type": "xa",
        "slug": "cao-lau",
        "name_with_type": "Xã Cao Lâu",
        "path": "Cao Lâu, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Cao Lâu, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06196",
        "parent_code": "183"
    },
    "06199": {
        "name": "Thạch Đạn",
        "type": "xa",
        "slug": "thach-dan",
        "name_with_type": "Xã Thạch Đạn",
        "path": "Thạch Đạn, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Thạch Đạn, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06199",
        "parent_code": "183"
    },
    "06202": {
        "name": "Xuất Lễ",
        "type": "xa",
        "slug": "xuat-le",
        "name_with_type": "Xã Xuất Lễ",
        "path": "Xuất Lễ, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Xuất Lễ, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06202",
        "parent_code": "183"
    },
    "06205": {
        "name": "Hồng Phong",
        "type": "xa",
        "slug": "hong-phong",
        "name_with_type": "Xã Hồng Phong",
        "path": "Hồng Phong, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Hồng Phong, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06205",
        "parent_code": "183"
    },
    "06208": {
        "name": "Thụy Hùng",
        "type": "xa",
        "slug": "thuy-hung",
        "name_with_type": "Xã Thụy Hùng",
        "path": "Thụy Hùng, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Thụy Hùng, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06208",
        "parent_code": "183"
    },
    "06211": {
        "name": "Lộc Yên",
        "type": "xa",
        "slug": "loc-yen",
        "name_with_type": "Xã Lộc Yên",
        "path": "Lộc Yên, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Lộc Yên, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06211",
        "parent_code": "183"
    },
    "06214": {
        "name": "Phú Xá",
        "type": "xa",
        "slug": "phu-xa",
        "name_with_type": "Xã Phú Xá",
        "path": "Phú Xá, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Phú Xá, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06214",
        "parent_code": "183"
    },
    "06217": {
        "name": "Bình Trung",
        "type": "xa",
        "slug": "binh-trung",
        "name_with_type": "Xã Bình Trung",
        "path": "Bình Trung, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Bình Trung, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06217",
        "parent_code": "183"
    },
    "06220": {
        "name": "Hải Yến",
        "type": "xa",
        "slug": "hai-yen",
        "name_with_type": "Xã Hải Yến",
        "path": "Hải Yến, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Hải Yến, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06220",
        "parent_code": "183"
    },
    "06223": {
        "name": "Hòa Cư",
        "type": "xa",
        "slug": "hoa-cu",
        "name_with_type": "Xã Hòa Cư",
        "path": "Hòa Cư, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Hòa Cư, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06223",
        "parent_code": "183"
    },
    "06226": {
        "name": "Hợp Thành",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thành",
        "path": "Hợp Thành, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Hợp Thành, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06226",
        "parent_code": "183"
    },
    "06229": {
        "name": "Song Giáp",
        "type": "xa",
        "slug": "song-giap",
        "name_with_type": "Xã Song Giáp",
        "path": "Song Giáp, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Song Giáp, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06229",
        "parent_code": "183"
    },
    "06232": {
        "name": "Công Sơn",
        "type": "xa",
        "slug": "cong-son",
        "name_with_type": "Xã Công Sơn",
        "path": "Công Sơn, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Công Sơn, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06232",
        "parent_code": "183"
    },
    "06235": {
        "name": "Gia Cát",
        "type": "xa",
        "slug": "gia-cat",
        "name_with_type": "Xã Gia Cát",
        "path": "Gia Cát, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Gia Cát, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06235",
        "parent_code": "183"
    },
    "06238": {
        "name": "Mẫu Sơn",
        "type": "xa",
        "slug": "mau-son",
        "name_with_type": "Xã Mẫu Sơn",
        "path": "Mẫu Sơn, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Mẫu Sơn, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06238",
        "parent_code": "183"
    },
    "06241": {
        "name": "Xuân Long",
        "type": "xa",
        "slug": "xuan-long",
        "name_with_type": "Xã Xuân Long",
        "path": "Xuân Long, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Xuân Long, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06241",
        "parent_code": "183"
    },
    "06244": {
        "name": "Tân Liên",
        "type": "xa",
        "slug": "tan-lien",
        "name_with_type": "Xã Tân Liên",
        "path": "Tân Liên, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Tân Liên, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06244",
        "parent_code": "183"
    },
    "06247": {
        "name": "Yên Trạch",
        "type": "xa",
        "slug": "yen-trach",
        "name_with_type": "Xã Yên Trạch",
        "path": "Yên Trạch, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Yên Trạch, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06247",
        "parent_code": "183"
    },
    "06250": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Cao Lộc, Lạng Sơn",
        "path_with_type": "Xã Tân Thành, Huyện Cao Lộc, Tỉnh Lạng Sơn",
        "code": "06250",
        "parent_code": "183"
    },
    "06253": {
        "name": "Văn Quan",
        "type": "thi-tran",
        "slug": "van-quan",
        "name_with_type": "Thị trấn Văn Quan",
        "path": "Văn Quan, Văn Quan, Lạng Sơn",
        "path_with_type": "Thị trấn Văn Quan, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06253",
        "parent_code": "184"
    },
    "06256": {
        "name": "Trấn Ninh",
        "type": "xa",
        "slug": "tran-ninh",
        "name_with_type": "Xã Trấn Ninh",
        "path": "Trấn Ninh, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Trấn Ninh, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06256",
        "parent_code": "184"
    },
    "06259": {
        "name": "Phú Mỹ",
        "type": "xa",
        "slug": "phu-my",
        "name_with_type": "Xã Phú Mỹ",
        "path": "Phú Mỹ, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Phú Mỹ, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06259",
        "parent_code": "184"
    },
    "06262": {
        "name": "Việt Yên",
        "type": "xa",
        "slug": "viet-yen",
        "name_with_type": "Xã Việt Yên",
        "path": "Việt Yên, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Việt Yên, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06262",
        "parent_code": "184"
    },
    "06265": {
        "name": "Song Giang",
        "type": "xa",
        "slug": "song-giang",
        "name_with_type": "Xã Song Giang",
        "path": "Song Giang, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Song Giang, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06265",
        "parent_code": "184"
    },
    "06268": {
        "name": "Vân Mộng",
        "type": "xa",
        "slug": "van-mong",
        "name_with_type": "Xã Vân Mộng",
        "path": "Vân Mộng, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Vân Mộng, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06268",
        "parent_code": "184"
    },
    "06271": {
        "name": "Vĩnh Lại",
        "type": "xa",
        "slug": "vinh-lai",
        "name_with_type": "Xã Vĩnh Lại",
        "path": "Vĩnh Lại, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Vĩnh Lại, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06271",
        "parent_code": "184"
    },
    "06274": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Hòa Bình, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06274",
        "parent_code": "184"
    },
    "06277": {
        "name": "Tú Xuyên",
        "type": "xa",
        "slug": "tu-xuyen",
        "name_with_type": "Xã Tú Xuyên",
        "path": "Tú Xuyên, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Tú Xuyên, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06277",
        "parent_code": "184"
    },
    "06280": {
        "name": "Văn An",
        "type": "xa",
        "slug": "van-an",
        "name_with_type": "Xã Văn An",
        "path": "Văn An, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Văn An, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06280",
        "parent_code": "184"
    },
    "06283": {
        "name": "Đại An",
        "type": "xa",
        "slug": "dai-an",
        "name_with_type": "Xã Đại An",
        "path": "Đại An, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Đại An, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06283",
        "parent_code": "184"
    },
    "06286": {
        "name": "Khánh Khê",
        "type": "xa",
        "slug": "khanh-khe",
        "name_with_type": "Xã Khánh Khê",
        "path": "Khánh Khê, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Khánh Khê, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06286",
        "parent_code": "184"
    },
    "06289": {
        "name": "Chu Túc",
        "type": "xa",
        "slug": "chu-tuc",
        "name_with_type": "Xã Chu Túc",
        "path": "Chu Túc, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Chu Túc, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06289",
        "parent_code": "184"
    },
    "06292": {
        "name": "Lương Năng",
        "type": "xa",
        "slug": "luong-nang",
        "name_with_type": "Xã Lương Năng",
        "path": "Lương Năng, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Lương Năng, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06292",
        "parent_code": "184"
    },
    "06295": {
        "name": "Đồng Giáp",
        "type": "xa",
        "slug": "dong-giap",
        "name_with_type": "Xã Đồng Giáp",
        "path": "Đồng Giáp, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Đồng Giáp, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06295",
        "parent_code": "184"
    },
    "06298": {
        "name": "Xuân Mai",
        "type": "xa",
        "slug": "xuan-mai",
        "name_with_type": "Xã Xuân Mai",
        "path": "Xuân Mai, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Xuân Mai, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06298",
        "parent_code": "184"
    },
    "06301": {
        "name": "Tràng Các",
        "type": "xa",
        "slug": "trang-cac",
        "name_with_type": "Xã Tràng Các",
        "path": "Tràng Các, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Tràng Các, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06301",
        "parent_code": "184"
    },
    "06304": {
        "name": "Tràng Sơn",
        "type": "xa",
        "slug": "trang-son",
        "name_with_type": "Xã Tràng Sơn",
        "path": "Tràng Sơn, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Tràng Sơn, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06304",
        "parent_code": "184"
    },
    "06307": {
        "name": "Tân Đoàn",
        "type": "xa",
        "slug": "tan-doan",
        "name_with_type": "Xã Tân Đoàn",
        "path": "Tân Đoàn, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Tân Đoàn, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06307",
        "parent_code": "184"
    },
    "06310": {
        "name": "Bình Phúc",
        "type": "xa",
        "slug": "binh-phuc",
        "name_with_type": "Xã Bình Phúc",
        "path": "Bình Phúc, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Bình Phúc, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06310",
        "parent_code": "184"
    },
    "06313": {
        "name": "Tri Lễ",
        "type": "xa",
        "slug": "tri-le",
        "name_with_type": "Xã Tri Lễ",
        "path": "Tri Lễ, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Tri Lễ, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06313",
        "parent_code": "184"
    },
    "06316": {
        "name": "Tràng Phái",
        "type": "xa",
        "slug": "trang-phai",
        "name_with_type": "Xã Tràng Phái",
        "path": "Tràng Phái, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Tràng Phái, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06316",
        "parent_code": "184"
    },
    "06319": {
        "name": "Yên Phúc",
        "type": "xa",
        "slug": "yen-phuc",
        "name_with_type": "Xã Yên Phúc",
        "path": "Yên Phúc, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Yên Phúc, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06319",
        "parent_code": "184"
    },
    "06322": {
        "name": "Hữu Lễ",
        "type": "xa",
        "slug": "huu-le",
        "name_with_type": "Xã Hữu Lễ",
        "path": "Hữu Lễ, Văn Quan, Lạng Sơn",
        "path_with_type": "Xã Hữu Lễ, Huyện Văn Quan, Tỉnh Lạng Sơn",
        "code": "06322",
        "parent_code": "184"
    },
    "06325": {
        "name": "Bắc Sơn",
        "type": "thi-tran",
        "slug": "bac-son",
        "name_with_type": "Thị trấn Bắc Sơn",
        "path": "Bắc Sơn, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Thị trấn Bắc Sơn, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06325",
        "parent_code": "185"
    },
    "06328": {
        "name": "Long Đống",
        "type": "xa",
        "slug": "long-dong",
        "name_with_type": "Xã Long Đống",
        "path": "Long Đống, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Long Đống, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06328",
        "parent_code": "185"
    },
    "06331": {
        "name": "Vạn Thủy",
        "type": "xa",
        "slug": "van-thuy",
        "name_with_type": "Xã Vạn Thủy",
        "path": "Vạn Thủy, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Vạn Thủy, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06331",
        "parent_code": "185"
    },
    "06334": {
        "name": "Quỳnh Sơn",
        "type": "xa",
        "slug": "quynh-son",
        "name_with_type": "Xã Quỳnh Sơn",
        "path": "Quỳnh Sơn, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Quỳnh Sơn, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06334",
        "parent_code": "185"
    },
    "06337": {
        "name": "Đồng ý",
        "type": "xa",
        "slug": "dong-y",
        "name_with_type": "Xã Đồng ý",
        "path": "Đồng ý, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Đồng ý, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06337",
        "parent_code": "185"
    },
    "06340": {
        "name": "Tân Tri",
        "type": "xa",
        "slug": "tan-tri",
        "name_with_type": "Xã Tân Tri",
        "path": "Tân Tri, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Tân Tri, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06340",
        "parent_code": "185"
    },
    "06343": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Bắc Sơn, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06343",
        "parent_code": "185"
    },
    "06346": {
        "name": "Hữu Vĩnh",
        "type": "xa",
        "slug": "huu-vinh",
        "name_with_type": "Xã Hữu Vĩnh",
        "path": "Hữu Vĩnh, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Hữu Vĩnh, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06346",
        "parent_code": "185"
    },
    "06349": {
        "name": "Hưng Vũ",
        "type": "xa",
        "slug": "hung-vu",
        "name_with_type": "Xã Hưng Vũ",
        "path": "Hưng Vũ, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Hưng Vũ, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06349",
        "parent_code": "185"
    },
    "06352": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Tân Lập, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06352",
        "parent_code": "185"
    },
    "06355": {
        "name": "Vũ Sơn",
        "type": "xa",
        "slug": "vu-son",
        "name_with_type": "Xã Vũ Sơn",
        "path": "Vũ Sơn, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Vũ Sơn, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06355",
        "parent_code": "185"
    },
    "06358": {
        "name": "Chiêu Vũ",
        "type": "xa",
        "slug": "chieu-vu",
        "name_with_type": "Xã Chiêu Vũ",
        "path": "Chiêu Vũ, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Chiêu Vũ, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06358",
        "parent_code": "185"
    },
    "06361": {
        "name": "Tân Hương",
        "type": "xa",
        "slug": "tan-huong",
        "name_with_type": "Xã Tân Hương",
        "path": "Tân Hương, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Tân Hương, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06361",
        "parent_code": "185"
    },
    "06364": {
        "name": "Chiến Thắng",
        "type": "xa",
        "slug": "chien-thang",
        "name_with_type": "Xã Chiến Thắng",
        "path": "Chiến Thắng, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Chiến Thắng, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06364",
        "parent_code": "185"
    },
    "06367": {
        "name": "Vũ Lăng",
        "type": "xa",
        "slug": "vu-lang",
        "name_with_type": "Xã Vũ Lăng",
        "path": "Vũ Lăng, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Vũ Lăng, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06367",
        "parent_code": "185"
    },
    "06370": {
        "name": "Trấn Yên",
        "type": "xa",
        "slug": "tran-yen",
        "name_with_type": "Xã Trấn Yên",
        "path": "Trấn Yên, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Trấn Yên, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06370",
        "parent_code": "185"
    },
    "06373": {
        "name": "Vũ Lễ",
        "type": "xa",
        "slug": "vu-le",
        "name_with_type": "Xã Vũ Lễ",
        "path": "Vũ Lễ, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Vũ Lễ, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06373",
        "parent_code": "185"
    },
    "06376": {
        "name": "Nhất Hòa",
        "type": "xa",
        "slug": "nhat-hoa",
        "name_with_type": "Xã Nhất Hòa",
        "path": "Nhất Hòa, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Nhất Hòa, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06376",
        "parent_code": "185"
    },
    "06379": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Tân Thành, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06379",
        "parent_code": "185"
    },
    "06382": {
        "name": "Nhất Tiến",
        "type": "xa",
        "slug": "nhat-tien",
        "name_with_type": "Xã Nhất Tiến",
        "path": "Nhất Tiến, Bắc Sơn, Lạng Sơn",
        "path_with_type": "Xã Nhất Tiến, Huyện Bắc Sơn, Tỉnh Lạng Sơn",
        "code": "06382",
        "parent_code": "185"
    },
    "06385": {
        "name": "Hữu Lũng",
        "type": "thi-tran",
        "slug": "huu-lung",
        "name_with_type": "Thị trấn Hữu Lũng",
        "path": "Hữu Lũng, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Thị trấn Hữu Lũng, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06385",
        "parent_code": "186"
    },
    "06388": {
        "name": "Hữu Liên",
        "type": "xa",
        "slug": "huu-lien",
        "name_with_type": "Xã Hữu Liên",
        "path": "Hữu Liên, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Hữu Liên, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06388",
        "parent_code": "186"
    },
    "06391": {
        "name": "Yên Bình",
        "type": "xa",
        "slug": "yen-binh",
        "name_with_type": "Xã Yên Bình",
        "path": "Yên Bình, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Yên Bình, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06391",
        "parent_code": "186"
    },
    "06394": {
        "name": "Quyết Thắng",
        "type": "xa",
        "slug": "quyet-thang",
        "name_with_type": "Xã Quyết Thắng",
        "path": "Quyết Thắng, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Quyết Thắng, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06394",
        "parent_code": "186"
    },
    "06397": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Hòa Bình, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06397",
        "parent_code": "186"
    },
    "06400": {
        "name": "Yên Thịnh",
        "type": "xa",
        "slug": "yen-thinh",
        "name_with_type": "Xã Yên Thịnh",
        "path": "Yên Thịnh, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Yên Thịnh, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06400",
        "parent_code": "186"
    },
    "06403": {
        "name": "Yên Sơn",
        "type": "xa",
        "slug": "yen-son",
        "name_with_type": "Xã Yên Sơn",
        "path": "Yên Sơn, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Yên Sơn, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06403",
        "parent_code": "186"
    },
    "06406": {
        "name": "Thiện Kỵ",
        "type": "xa",
        "slug": "thien-ky",
        "name_with_type": "Xã Thiện Kỵ",
        "path": "Thiện Kỵ, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Thiện Kỵ, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06406",
        "parent_code": "186"
    },
    "06409": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Tân Lập, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06409",
        "parent_code": "186"
    },
    "06412": {
        "name": "Yên Vượng",
        "type": "xa",
        "slug": "yen-vuong",
        "name_with_type": "Xã Yên Vượng",
        "path": "Yên Vượng, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Yên Vượng, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06412",
        "parent_code": "186"
    },
    "06415": {
        "name": "Minh Tiến",
        "type": "xa",
        "slug": "minh-tien",
        "name_with_type": "Xã Minh Tiến",
        "path": "Minh Tiến, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Minh Tiến, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06415",
        "parent_code": "186"
    },
    "06418": {
        "name": "Nhật Tiến",
        "type": "xa",
        "slug": "nhat-tien",
        "name_with_type": "Xã Nhật Tiến",
        "path": "Nhật Tiến, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Nhật Tiến, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06418",
        "parent_code": "186"
    },
    "06421": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Thanh Sơn, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06421",
        "parent_code": "186"
    },
    "06424": {
        "name": "Đồng Tân",
        "type": "xa",
        "slug": "dong-tan",
        "name_with_type": "Xã Đồng Tân",
        "path": "Đồng Tân, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Đồng Tân, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06424",
        "parent_code": "186"
    },
    "06427": {
        "name": "Cai Kinh",
        "type": "xa",
        "slug": "cai-kinh",
        "name_with_type": "Xã Cai Kinh",
        "path": "Cai Kinh, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Cai Kinh, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06427",
        "parent_code": "186"
    },
    "06430": {
        "name": "Hòa Lạc",
        "type": "xa",
        "slug": "hoa-lac",
        "name_with_type": "Xã Hòa Lạc",
        "path": "Hòa Lạc, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Hòa Lạc, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06430",
        "parent_code": "186"
    },
    "06433": {
        "name": "Vân Nham",
        "type": "xa",
        "slug": "van-nham",
        "name_with_type": "Xã Vân Nham",
        "path": "Vân Nham, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Vân Nham, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06433",
        "parent_code": "186"
    },
    "06436": {
        "name": "Đồng Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đồng Tiến",
        "path": "Đồng Tiến, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Đồng Tiến, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06436",
        "parent_code": "186"
    },
    "06439": {
        "name": "Đô Lương",
        "type": "xa",
        "slug": "do-luong",
        "name_with_type": "Xã Đô Lương",
        "path": "Đô Lương, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Đô Lương, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06439",
        "parent_code": "186"
    },
    "06442": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Tân Thành, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06442",
        "parent_code": "186"
    },
    "06445": {
        "name": "Hòa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hòa Sơn",
        "path": "Hòa Sơn, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Hòa Sơn, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06445",
        "parent_code": "186"
    },
    "06448": {
        "name": "Minh Sơn",
        "type": "xa",
        "slug": "minh-son",
        "name_with_type": "Xã Minh Sơn",
        "path": "Minh Sơn, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Minh Sơn, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06448",
        "parent_code": "186"
    },
    "06451": {
        "name": "Hồ Sơn",
        "type": "xa",
        "slug": "ho-son",
        "name_with_type": "Xã Hồ Sơn",
        "path": "Hồ Sơn, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Hồ Sơn, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06451",
        "parent_code": "186"
    },
    "06454": {
        "name": "Sơn Hà",
        "type": "xa",
        "slug": "son-ha",
        "name_with_type": "Xã Sơn Hà",
        "path": "Sơn Hà, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Sơn Hà, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06454",
        "parent_code": "186"
    },
    "06457": {
        "name": "Minh Hòa",
        "type": "xa",
        "slug": "minh-hoa",
        "name_with_type": "Xã Minh Hòa",
        "path": "Minh Hòa, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Minh Hòa, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06457",
        "parent_code": "186"
    },
    "06460": {
        "name": "Hòa Thắng",
        "type": "xa",
        "slug": "hoa-thang",
        "name_with_type": "Xã Hòa Thắng",
        "path": "Hòa Thắng, Hữu Lũng, Lạng Sơn",
        "path_with_type": "Xã Hòa Thắng, Huyện Hữu Lũng, Tỉnh Lạng Sơn",
        "code": "06460",
        "parent_code": "186"
    },
    "06463": {
        "name": "Đồng Mỏ",
        "type": "thi-tran",
        "slug": "dong-mo",
        "name_with_type": "Thị trấn Đồng Mỏ",
        "path": "Đồng Mỏ, Chi Lăng, Lạng Sơn",
        "path_with_type": "Thị trấn Đồng Mỏ, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06463",
        "parent_code": "187"
    },
    "06466": {
        "name": "Chi Lăng",
        "type": "thi-tran",
        "slug": "chi-lang",
        "name_with_type": "Thị trấn Chi Lăng",
        "path": "Chi Lăng, Chi Lăng, Lạng Sơn",
        "path_with_type": "Thị trấn Chi Lăng, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06466",
        "parent_code": "187"
    },
    "06469": {
        "name": "Vân An",
        "type": "xa",
        "slug": "van-an",
        "name_with_type": "Xã Vân An",
        "path": "Vân An, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Vân An, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06469",
        "parent_code": "187"
    },
    "06472": {
        "name": "Vân Thủy",
        "type": "xa",
        "slug": "van-thuy",
        "name_with_type": "Xã Vân Thủy",
        "path": "Vân Thủy, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Vân Thủy, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06472",
        "parent_code": "187"
    },
    "06475": {
        "name": "Gia Lộc",
        "type": "xa",
        "slug": "gia-loc",
        "name_with_type": "Xã Gia Lộc",
        "path": "Gia Lộc, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Gia Lộc, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06475",
        "parent_code": "187"
    },
    "06478": {
        "name": "Bắc Thủy",
        "type": "xa",
        "slug": "bac-thuy",
        "name_with_type": "Xã Bắc Thủy",
        "path": "Bắc Thủy, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Bắc Thủy, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06478",
        "parent_code": "187"
    },
    "06481": {
        "name": "Chiến Thắng",
        "type": "xa",
        "slug": "chien-thang",
        "name_with_type": "Xã Chiến Thắng",
        "path": "Chiến Thắng, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Chiến Thắng, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06481",
        "parent_code": "187"
    },
    "06484": {
        "name": "Mai Sao",
        "type": "xa",
        "slug": "mai-sao",
        "name_with_type": "Xã Mai Sao",
        "path": "Mai Sao, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Mai Sao, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06484",
        "parent_code": "187"
    },
    "06487": {
        "name": "Bằng Hữu",
        "type": "xa",
        "slug": "bang-huu",
        "name_with_type": "Xã Bằng Hữu",
        "path": "Bằng Hữu, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Bằng Hữu, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06487",
        "parent_code": "187"
    },
    "06490": {
        "name": "Thượng Cường",
        "type": "xa",
        "slug": "thuong-cuong",
        "name_with_type": "Xã Thượng Cường",
        "path": "Thượng Cường, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Thượng Cường, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06490",
        "parent_code": "187"
    },
    "06493": {
        "name": "Bằng Mạc",
        "type": "xa",
        "slug": "bang-mac",
        "name_with_type": "Xã Bằng Mạc",
        "path": "Bằng Mạc, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Bằng Mạc, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06493",
        "parent_code": "187"
    },
    "06496": {
        "name": "Nhân Lý",
        "type": "xa",
        "slug": "nhan-ly",
        "name_with_type": "Xã Nhân Lý",
        "path": "Nhân Lý, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Nhân Lý, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06496",
        "parent_code": "187"
    },
    "06499": {
        "name": "Lâm Sơn",
        "type": "xa",
        "slug": "lam-son",
        "name_with_type": "Xã Lâm Sơn",
        "path": "Lâm Sơn, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Lâm Sơn, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06499",
        "parent_code": "187"
    },
    "06502": {
        "name": "Liên Sơn",
        "type": "xa",
        "slug": "lien-son",
        "name_with_type": "Xã Liên Sơn",
        "path": "Liên Sơn, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Liên Sơn, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06502",
        "parent_code": "187"
    },
    "06505": {
        "name": "Vạn Linh",
        "type": "xa",
        "slug": "van-linh",
        "name_with_type": "Xã Vạn Linh",
        "path": "Vạn Linh, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Vạn Linh, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06505",
        "parent_code": "187"
    },
    "06508": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Hòa Bình, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06508",
        "parent_code": "187"
    },
    "06511": {
        "name": "Quang Lang",
        "type": "xa",
        "slug": "quang-lang",
        "name_with_type": "Xã Quang Lang",
        "path": "Quang Lang, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Quang Lang, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06511",
        "parent_code": "187"
    },
    "06514": {
        "name": "Hữu Kiên",
        "type": "xa",
        "slug": "huu-kien",
        "name_with_type": "Xã Hữu Kiên",
        "path": "Hữu Kiên, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Hữu Kiên, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06514",
        "parent_code": "187"
    },
    "06517": {
        "name": "Quan Sơn",
        "type": "xa",
        "slug": "quan-son",
        "name_with_type": "Xã Quan Sơn",
        "path": "Quan Sơn, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Quan Sơn, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06517",
        "parent_code": "187"
    },
    "06520": {
        "name": "Y Tịch",
        "type": "xa",
        "slug": "y-tich",
        "name_with_type": "Xã Y Tịch",
        "path": "Y Tịch, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Y Tịch, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06520",
        "parent_code": "187"
    },
    "06523": {
        "name": "Chi Lăng",
        "type": "xa",
        "slug": "chi-lang",
        "name_with_type": "Xã Chi Lăng",
        "path": "Chi Lăng, Chi Lăng, Lạng Sơn",
        "path_with_type": "Xã Chi Lăng, Huyện Chi Lăng, Tỉnh Lạng Sơn",
        "code": "06523",
        "parent_code": "187"
    },
    "06526": {
        "name": "Na Dương",
        "type": "thi-tran",
        "slug": "na-duong",
        "name_with_type": "Thị trấn Na Dương",
        "path": "Na Dương, Lộc Bình, Lạng Sơn",
        "path_with_type": "Thị trấn Na Dương, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06526",
        "parent_code": "188"
    },
    "06529": {
        "name": "Lộc Bình",
        "type": "thi-tran",
        "slug": "loc-binh",
        "name_with_type": "Thị trấn Lộc Bình",
        "path": "Lộc Bình, Lộc Bình, Lạng Sơn",
        "path_with_type": "Thị trấn Lộc Bình, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06529",
        "parent_code": "188"
    },
    "06532": {
        "name": "Mẫu Sơn",
        "type": "xa",
        "slug": "mau-son",
        "name_with_type": "Xã Mẫu Sơn",
        "path": "Mẫu Sơn, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Mẫu Sơn, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06532",
        "parent_code": "188"
    },
    "06535": {
        "name": "Bằng Khánh",
        "type": "xa",
        "slug": "bang-khanh",
        "name_with_type": "Xã Bằng Khánh",
        "path": "Bằng Khánh, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Bằng Khánh, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06535",
        "parent_code": "188"
    },
    "06538": {
        "name": "Xuân Lễ",
        "type": "xa",
        "slug": "xuan-le",
        "name_with_type": "Xã Xuân Lễ",
        "path": "Xuân Lễ, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Xuân Lễ, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06538",
        "parent_code": "188"
    },
    "06541": {
        "name": "Yên Khoái",
        "type": "xa",
        "slug": "yen-khoai",
        "name_with_type": "Xã Yên Khoái",
        "path": "Yên Khoái, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Yên Khoái, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06541",
        "parent_code": "188"
    },
    "06544": {
        "name": "Xuân Mãn",
        "type": "xa",
        "slug": "xuan-man",
        "name_with_type": "Xã Xuân Mãn",
        "path": "Xuân Mãn, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Xuân Mãn, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06544",
        "parent_code": "188"
    },
    "06547": {
        "name": "Tú Mịch",
        "type": "xa",
        "slug": "tu-mich",
        "name_with_type": "Xã Tú Mịch",
        "path": "Tú Mịch, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Tú Mịch, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06547",
        "parent_code": "188"
    },
    "06550": {
        "name": "Hữu Khánh",
        "type": "xa",
        "slug": "huu-khanh",
        "name_with_type": "Xã Hữu Khánh",
        "path": "Hữu Khánh, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Hữu Khánh, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06550",
        "parent_code": "188"
    },
    "06553": {
        "name": "Đồng Bục",
        "type": "xa",
        "slug": "dong-buc",
        "name_with_type": "Xã Đồng Bục",
        "path": "Đồng Bục, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Đồng Bục, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06553",
        "parent_code": "188"
    },
    "06556": {
        "name": "Vân Mộng",
        "type": "xa",
        "slug": "van-mong",
        "name_with_type": "Xã Vân Mộng",
        "path": "Vân Mộng, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Vân Mộng, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06556",
        "parent_code": "188"
    },
    "06559": {
        "name": "Tam Gia",
        "type": "xa",
        "slug": "tam-gia",
        "name_with_type": "Xã Tam Gia",
        "path": "Tam Gia, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Tam Gia, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06559",
        "parent_code": "188"
    },
    "06562": {
        "name": "Tú Đoạn",
        "type": "xa",
        "slug": "tu-doan",
        "name_with_type": "Xã Tú Đoạn",
        "path": "Tú Đoạn, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Tú Đoạn, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06562",
        "parent_code": "188"
    },
    "06565": {
        "name": "Khuất Xá",
        "type": "xa",
        "slug": "khuat-xa",
        "name_with_type": "Xã Khuất Xá",
        "path": "Khuất Xá, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Khuất Xá, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06565",
        "parent_code": "188"
    },
    "06568": {
        "name": "Như Khuê",
        "type": "xa",
        "slug": "nhu-khue",
        "name_with_type": "Xã Như Khuê",
        "path": "Như Khuê, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Như Khuê, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06568",
        "parent_code": "188"
    },
    "06571": {
        "name": "Lục Thôn",
        "type": "xa",
        "slug": "luc-thon",
        "name_with_type": "Xã Lục Thôn",
        "path": "Lục Thôn, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Lục Thôn, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06571",
        "parent_code": "188"
    },
    "06574": {
        "name": "Tĩnh Bắc",
        "type": "xa",
        "slug": "tinh-bac",
        "name_with_type": "Xã Tĩnh Bắc",
        "path": "Tĩnh Bắc, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Tĩnh Bắc, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06574",
        "parent_code": "188"
    },
    "06577": {
        "name": "Xuân Tình",
        "type": "xa",
        "slug": "xuan-tinh",
        "name_with_type": "Xã Xuân Tình",
        "path": "Xuân Tình, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Xuân Tình, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06577",
        "parent_code": "188"
    },
    "06580": {
        "name": "Hiệp Hạ",
        "type": "xa",
        "slug": "hiep-ha",
        "name_with_type": "Xã Hiệp Hạ",
        "path": "Hiệp Hạ, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Hiệp Hạ, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06580",
        "parent_code": "188"
    },
    "06583": {
        "name": "Nhượng Bạn",
        "type": "xa",
        "slug": "nhuong-ban",
        "name_with_type": "Xã Nhượng Bạn",
        "path": "Nhượng Bạn, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Nhượng Bạn, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06583",
        "parent_code": "188"
    },
    "06586": {
        "name": "Quan Bản",
        "type": "xa",
        "slug": "quan-ban",
        "name_with_type": "Xã Quan Bản",
        "path": "Quan Bản, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Quan Bản, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06586",
        "parent_code": "188"
    },
    "06589": {
        "name": "Sàn Viên",
        "type": "xa",
        "slug": "san-vien",
        "name_with_type": "Xã Sàn Viên",
        "path": "Sàn Viên, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Sàn Viên, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06589",
        "parent_code": "188"
    },
    "06592": {
        "name": "Đông Quan",
        "type": "xa",
        "slug": "dong-quan",
        "name_with_type": "Xã Đông Quan",
        "path": "Đông Quan, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Đông Quan, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06592",
        "parent_code": "188"
    },
    "06595": {
        "name": "Minh Phát",
        "type": "xa",
        "slug": "minh-phat",
        "name_with_type": "Xã Minh Phát",
        "path": "Minh Phát, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Minh Phát, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06595",
        "parent_code": "188"
    },
    "06598": {
        "name": "Hữu Lân",
        "type": "xa",
        "slug": "huu-lan",
        "name_with_type": "Xã Hữu Lân",
        "path": "Hữu Lân, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Hữu Lân, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06598",
        "parent_code": "188"
    },
    "06601": {
        "name": "Lợi Bác",
        "type": "xa",
        "slug": "loi-bac",
        "name_with_type": "Xã Lợi Bác",
        "path": "Lợi Bác, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Lợi Bác, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06601",
        "parent_code": "188"
    },
    "06604": {
        "name": "Nam Quan",
        "type": "xa",
        "slug": "nam-quan",
        "name_with_type": "Xã Nam Quan",
        "path": "Nam Quan, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Nam Quan, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06604",
        "parent_code": "188"
    },
    "06607": {
        "name": "Xuân Dương",
        "type": "xa",
        "slug": "xuan-duong",
        "name_with_type": "Xã Xuân Dương",
        "path": "Xuân Dương, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Xuân Dương, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06607",
        "parent_code": "188"
    },
    "06610": {
        "name": "Ái Quốc",
        "type": "xa",
        "slug": "ai-quoc",
        "name_with_type": "Xã Ái Quốc",
        "path": "Ái Quốc, Lộc Bình, Lạng Sơn",
        "path_with_type": "Xã Ái Quốc, Huyện Lộc Bình, Tỉnh Lạng Sơn",
        "code": "06610",
        "parent_code": "188"
    },
    "06613": {
        "name": "Đình Lập",
        "type": "thi-tran",
        "slug": "dinh-lap",
        "name_with_type": "Thị trấn Đình Lập",
        "path": "Đình Lập, Đình Lập, Lạng Sơn",
        "path_with_type": "Thị trấn Đình Lập, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06613",
        "parent_code": "189"
    },
    "06616": {
        "name": "NT Thái Bình",
        "type": "thi-tran",
        "slug": "nt-thai-binh",
        "name_with_type": "Thị trấn NT Thái Bình",
        "path": "NT Thái Bình, Đình Lập, Lạng Sơn",
        "path_with_type": "Thị trấn NT Thái Bình, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06616",
        "parent_code": "189"
    },
    "06619": {
        "name": "Bắc Xa",
        "type": "xa",
        "slug": "bac-xa",
        "name_with_type": "Xã Bắc Xa",
        "path": "Bắc Xa, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Bắc Xa, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06619",
        "parent_code": "189"
    },
    "06622": {
        "name": "Bính Xá",
        "type": "xa",
        "slug": "binh-xa",
        "name_with_type": "Xã Bính Xá",
        "path": "Bính Xá, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Bính Xá, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06622",
        "parent_code": "189"
    },
    "06625": {
        "name": "Kiên Mộc",
        "type": "xa",
        "slug": "kien-moc",
        "name_with_type": "Xã Kiên Mộc",
        "path": "Kiên Mộc, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Kiên Mộc, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06625",
        "parent_code": "189"
    },
    "06628": {
        "name": "Đình Lập",
        "type": "xa",
        "slug": "dinh-lap",
        "name_with_type": "Xã Đình Lập",
        "path": "Đình Lập, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Đình Lập, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06628",
        "parent_code": "189"
    },
    "06631": {
        "name": "Thái Bình",
        "type": "xa",
        "slug": "thai-binh",
        "name_with_type": "Xã Thái Bình",
        "path": "Thái Bình, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Thái Bình, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06631",
        "parent_code": "189"
    },
    "06634": {
        "name": "Cường Lợi",
        "type": "xa",
        "slug": "cuong-loi",
        "name_with_type": "Xã Cường Lợi",
        "path": "Cường Lợi, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Cường Lợi, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06634",
        "parent_code": "189"
    },
    "06637": {
        "name": "Châu Sơn",
        "type": "xa",
        "slug": "chau-son",
        "name_with_type": "Xã Châu Sơn",
        "path": "Châu Sơn, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Châu Sơn, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06637",
        "parent_code": "189"
    },
    "06640": {
        "name": "Lâm Ca",
        "type": "xa",
        "slug": "lam-ca",
        "name_with_type": "Xã Lâm Ca",
        "path": "Lâm Ca, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Lâm Ca, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06640",
        "parent_code": "189"
    },
    "06643": {
        "name": "Đồng Thắng",
        "type": "xa",
        "slug": "dong-thang",
        "name_with_type": "Xã Đồng Thắng",
        "path": "Đồng Thắng, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Đồng Thắng, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06643",
        "parent_code": "189"
    },
    "06646": {
        "name": "Bắc Lãng",
        "type": "xa",
        "slug": "bac-lang",
        "name_with_type": "Xã Bắc Lãng",
        "path": "Bắc Lãng, Đình Lập, Lạng Sơn",
        "path_with_type": "Xã Bắc Lãng, Huyện Đình Lập, Tỉnh Lạng Sơn",
        "code": "06646",
        "parent_code": "189"
    },
    "20194": {
        "name": "Hòa Hiệp Bắc",
        "type": "phuong",
        "slug": "hoa-hiep-bac",
        "name_with_type": "Phường Hòa Hiệp Bắc",
        "path": "Hòa Hiệp Bắc, Liên Chiểu, Đà Nẵng",
        "path_with_type": "Phường Hòa Hiệp Bắc, Quận Liên Chiểu, Thành phố Đà Nẵng",
        "code": "20194",
        "parent_code": "490"
    },
    "20195": {
        "name": "Hòa Hiệp Nam",
        "type": "phuong",
        "slug": "hoa-hiep-nam",
        "name_with_type": "Phường Hòa Hiệp Nam",
        "path": "Hòa Hiệp Nam, Liên Chiểu, Đà Nẵng",
        "path_with_type": "Phường Hòa Hiệp Nam, Quận Liên Chiểu, Thành phố Đà Nẵng",
        "code": "20195",
        "parent_code": "490"
    },
    "20197": {
        "name": "Hòa Khánh Bắc",
        "type": "phuong",
        "slug": "hoa-khanh-bac",
        "name_with_type": "Phường Hòa Khánh Bắc",
        "path": "Hòa Khánh Bắc, Liên Chiểu, Đà Nẵng",
        "path_with_type": "Phường Hòa Khánh Bắc, Quận Liên Chiểu, Thành phố Đà Nẵng",
        "code": "20197",
        "parent_code": "490"
    },
    "20198": {
        "name": "Hòa Khánh Nam",
        "type": "phuong",
        "slug": "hoa-khanh-nam",
        "name_with_type": "Phường Hòa Khánh Nam",
        "path": "Hòa Khánh Nam, Liên Chiểu, Đà Nẵng",
        "path_with_type": "Phường Hòa Khánh Nam, Quận Liên Chiểu, Thành phố Đà Nẵng",
        "code": "20198",
        "parent_code": "490"
    },
    "20200": {
        "name": "Hòa Minh",
        "type": "phuong",
        "slug": "hoa-minh",
        "name_with_type": "Phường Hòa Minh",
        "path": "Hòa Minh, Liên Chiểu, Đà Nẵng",
        "path_with_type": "Phường Hòa Minh, Quận Liên Chiểu, Thành phố Đà Nẵng",
        "code": "20200",
        "parent_code": "490"
    },
    "20203": {
        "name": "Tam Thuận",
        "type": "phuong",
        "slug": "tam-thuan",
        "name_with_type": "Phường Tam Thuận",
        "path": "Tam Thuận, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường Tam Thuận, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20203",
        "parent_code": "491"
    },
    "20206": {
        "name": "Thanh Khê Tây",
        "type": "phuong",
        "slug": "thanh-khe-tay",
        "name_with_type": "Phường Thanh Khê Tây",
        "path": "Thanh Khê Tây, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường Thanh Khê Tây, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20206",
        "parent_code": "491"
    },
    "20207": {
        "name": "Thanh Khê Đông",
        "type": "phuong",
        "slug": "thanh-khe-dong",
        "name_with_type": "Phường Thanh Khê Đông",
        "path": "Thanh Khê Đông, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường Thanh Khê Đông, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20207",
        "parent_code": "491"
    },
    "20209": {
        "name": "Xuân Hà",
        "type": "phuong",
        "slug": "xuan-ha",
        "name_with_type": "Phường Xuân Hà",
        "path": "Xuân Hà, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường Xuân Hà, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20209",
        "parent_code": "491"
    },
    "20212": {
        "name": "Tân Chính",
        "type": "phuong",
        "slug": "tan-chinh",
        "name_with_type": "Phường Tân Chính",
        "path": "Tân Chính, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường Tân Chính, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20212",
        "parent_code": "491"
    },
    "20215": {
        "name": "Chính Gián",
        "type": "phuong",
        "slug": "chinh-gian",
        "name_with_type": "Phường Chính Gián",
        "path": "Chính Gián, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường Chính Gián, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20215",
        "parent_code": "491"
    },
    "20218": {
        "name": "Vĩnh Trung",
        "type": "phuong",
        "slug": "vinh-trung",
        "name_with_type": "Phường Vĩnh Trung",
        "path": "Vĩnh Trung, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường Vĩnh Trung, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20218",
        "parent_code": "491"
    },
    "20221": {
        "name": "Thạc Gián",
        "type": "phuong",
        "slug": "thac-gian",
        "name_with_type": "Phường Thạc Gián",
        "path": "Thạc Gián, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường Thạc Gián, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20221",
        "parent_code": "491"
    },
    "20224": {
        "name": "An Khê",
        "type": "phuong",
        "slug": "an-khe",
        "name_with_type": "Phường An Khê",
        "path": "An Khê, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường An Khê, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20224",
        "parent_code": "491"
    },
    "20225": {
        "name": "Hòa Khê",
        "type": "phuong",
        "slug": "hoa-khe",
        "name_with_type": "Phường Hòa Khê",
        "path": "Hòa Khê, Thanh Khê, Đà Nẵng",
        "path_with_type": "Phường Hòa Khê, Quận Thanh Khê, Thành phố Đà Nẵng",
        "code": "20225",
        "parent_code": "491"
    },
    "20227": {
        "name": "Thanh Bình",
        "type": "phuong",
        "slug": "thanh-binh",
        "name_with_type": "Phường Thanh Bình",
        "path": "Thanh Bình, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Thanh Bình, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20227",
        "parent_code": "492"
    },
    "20230": {
        "name": "Thuận Phước",
        "type": "phuong",
        "slug": "thuan-phuoc",
        "name_with_type": "Phường Thuận Phước",
        "path": "Thuận Phước, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Thuận Phước, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20230",
        "parent_code": "492"
    },
    "20233": {
        "name": "Thạch Thang",
        "type": "phuong",
        "slug": "thach-thang",
        "name_with_type": "Phường Thạch Thang",
        "path": "Thạch Thang, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Thạch Thang, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20233",
        "parent_code": "492"
    },
    "20236": {
        "name": "Hải Châu  I",
        "type": "phuong",
        "slug": "hai-chau-i",
        "name_with_type": "Phường Hải Châu  I",
        "path": "Hải Châu  I, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Hải Châu  I, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20236",
        "parent_code": "492"
    },
    "20239": {
        "name": "Hải Châu II",
        "type": "phuong",
        "slug": "hai-chau-ii",
        "name_with_type": "Phường Hải Châu II",
        "path": "Hải Châu II, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Hải Châu II, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20239",
        "parent_code": "492"
    },
    "20242": {
        "name": "Phước Ninh",
        "type": "phuong",
        "slug": "phuoc-ninh",
        "name_with_type": "Phường Phước Ninh",
        "path": "Phước Ninh, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Phước Ninh, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20242",
        "parent_code": "492"
    },
    "20245": {
        "name": "Hòa Thuận Tây",
        "type": "phuong",
        "slug": "hoa-thuan-tay",
        "name_with_type": "Phường Hòa Thuận Tây",
        "path": "Hòa Thuận Tây, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Hòa Thuận Tây, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20245",
        "parent_code": "492"
    },
    "20246": {
        "name": "Hòa Thuận Đông",
        "type": "phuong",
        "slug": "hoa-thuan-dong",
        "name_with_type": "Phường Hòa Thuận Đông",
        "path": "Hòa Thuận Đông, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Hòa Thuận Đông, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20246",
        "parent_code": "492"
    },
    "20248": {
        "name": "Nam Dương",
        "type": "phuong",
        "slug": "nam-duong",
        "name_with_type": "Phường Nam Dương",
        "path": "Nam Dương, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Nam Dương, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20248",
        "parent_code": "492"
    },
    "20251": {
        "name": "Bình Hiên",
        "type": "phuong",
        "slug": "binh-hien",
        "name_with_type": "Phường Bình Hiên",
        "path": "Bình Hiên, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Bình Hiên, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20251",
        "parent_code": "492"
    },
    "20254": {
        "name": "Bình Thuận",
        "type": "phuong",
        "slug": "binh-thuan",
        "name_with_type": "Phường Bình Thuận",
        "path": "Bình Thuận, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Bình Thuận, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20254",
        "parent_code": "492"
    },
    "20257": {
        "name": "Hòa Cường Bắc",
        "type": "phuong",
        "slug": "hoa-cuong-bac",
        "name_with_type": "Phường Hòa Cường Bắc",
        "path": "Hòa Cường Bắc, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Hòa Cường Bắc, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20257",
        "parent_code": "492"
    },
    "20258": {
        "name": "Hòa Cường Nam",
        "type": "phuong",
        "slug": "hoa-cuong-nam",
        "name_with_type": "Phường Hòa Cường Nam",
        "path": "Hòa Cường Nam, Hải Châu, Đà Nẵng",
        "path_with_type": "Phường Hòa Cường Nam, Quận Hải Châu, Thành phố Đà Nẵng",
        "code": "20258",
        "parent_code": "492"
    },
    "20263": {
        "name": "Thọ Quang",
        "type": "phuong",
        "slug": "tho-quang",
        "name_with_type": "Phường Thọ Quang",
        "path": "Thọ Quang, Sơn Trà, Đà Nẵng",
        "path_with_type": "Phường Thọ Quang, Quận Sơn Trà, Thành phố Đà Nẵng",
        "code": "20263",
        "parent_code": "493"
    },
    "20266": {
        "name": "Nại Hiên Đông",
        "type": "phuong",
        "slug": "nai-hien-dong",
        "name_with_type": "Phường Nại Hiên Đông",
        "path": "Nại Hiên Đông, Sơn Trà, Đà Nẵng",
        "path_with_type": "Phường Nại Hiên Đông, Quận Sơn Trà, Thành phố Đà Nẵng",
        "code": "20266",
        "parent_code": "493"
    },
    "20269": {
        "name": "Mân Thái",
        "type": "phuong",
        "slug": "man-thai",
        "name_with_type": "Phường Mân Thái",
        "path": "Mân Thái, Sơn Trà, Đà Nẵng",
        "path_with_type": "Phường Mân Thái, Quận Sơn Trà, Thành phố Đà Nẵng",
        "code": "20269",
        "parent_code": "493"
    },
    "20272": {
        "name": "An Hải Bắc",
        "type": "phuong",
        "slug": "an-hai-bac",
        "name_with_type": "Phường An Hải Bắc",
        "path": "An Hải Bắc, Sơn Trà, Đà Nẵng",
        "path_with_type": "Phường An Hải Bắc, Quận Sơn Trà, Thành phố Đà Nẵng",
        "code": "20272",
        "parent_code": "493"
    },
    "20275": {
        "name": "Phước Mỹ",
        "type": "phuong",
        "slug": "phuoc-my",
        "name_with_type": "Phường Phước Mỹ",
        "path": "Phước Mỹ, Sơn Trà, Đà Nẵng",
        "path_with_type": "Phường Phước Mỹ, Quận Sơn Trà, Thành phố Đà Nẵng",
        "code": "20275",
        "parent_code": "493"
    },
    "20278": {
        "name": "An Hải Tây",
        "type": "phuong",
        "slug": "an-hai-tay",
        "name_with_type": "Phường An Hải Tây",
        "path": "An Hải Tây, Sơn Trà, Đà Nẵng",
        "path_with_type": "Phường An Hải Tây, Quận Sơn Trà, Thành phố Đà Nẵng",
        "code": "20278",
        "parent_code": "493"
    },
    "20281": {
        "name": "An Hải Đông",
        "type": "phuong",
        "slug": "an-hai-dong",
        "name_with_type": "Phường An Hải Đông",
        "path": "An Hải Đông, Sơn Trà, Đà Nẵng",
        "path_with_type": "Phường An Hải Đông, Quận Sơn Trà, Thành phố Đà Nẵng",
        "code": "20281",
        "parent_code": "493"
    },
    "20284": {
        "name": "Mỹ An",
        "type": "phuong",
        "slug": "my-an",
        "name_with_type": "Phường Mỹ An",
        "path": "Mỹ An, Ngũ Hành Sơn, Đà Nẵng",
        "path_with_type": "Phường Mỹ An, Quận Ngũ Hành Sơn, Thành phố Đà Nẵng",
        "code": "20284",
        "parent_code": "494"
    },
    "20285": {
        "name": "Khuê Mỹ",
        "type": "phuong",
        "slug": "khue-my",
        "name_with_type": "Phường Khuê Mỹ",
        "path": "Khuê Mỹ, Ngũ Hành Sơn, Đà Nẵng",
        "path_with_type": "Phường Khuê Mỹ, Quận Ngũ Hành Sơn, Thành phố Đà Nẵng",
        "code": "20285",
        "parent_code": "494"
    },
    "20287": {
        "name": "Hoà Quý",
        "type": "phuong",
        "slug": "hoa-quy",
        "name_with_type": "Phường Hoà Quý",
        "path": "Hoà Quý, Ngũ Hành Sơn, Đà Nẵng",
        "path_with_type": "Phường Hoà Quý, Quận Ngũ Hành Sơn, Thành phố Đà Nẵng",
        "code": "20287",
        "parent_code": "494"
    },
    "20290": {
        "name": "Hoà Hải",
        "type": "phuong",
        "slug": "hoa-hai",
        "name_with_type": "Phường Hoà Hải",
        "path": "Hoà Hải, Ngũ Hành Sơn, Đà Nẵng",
        "path_with_type": "Phường Hoà Hải, Quận Ngũ Hành Sơn, Thành phố Đà Nẵng",
        "code": "20290",
        "parent_code": "494"
    },
    "20260": {
        "name": "Khuê Trung",
        "type": "phuong",
        "slug": "khue-trung",
        "name_with_type": "Phường Khuê Trung",
        "path": "Khuê Trung, Cẩm Lệ, Đà Nẵng",
        "path_with_type": "Phường Khuê Trung, Quận Cẩm Lệ, Thành phố Đà Nẵng",
        "code": "20260",
        "parent_code": "495"
    },
    "20305": {
        "name": "Hòa Phát",
        "type": "phuong",
        "slug": "hoa-phat",
        "name_with_type": "Phường Hòa Phát",
        "path": "Hòa Phát, Cẩm Lệ, Đà Nẵng",
        "path_with_type": "Phường Hòa Phát, Quận Cẩm Lệ, Thành phố Đà Nẵng",
        "code": "20305",
        "parent_code": "495"
    },
    "20306": {
        "name": "Hòa An",
        "type": "phuong",
        "slug": "hoa-an",
        "name_with_type": "Phường Hòa An",
        "path": "Hòa An, Cẩm Lệ, Đà Nẵng",
        "path_with_type": "Phường Hòa An, Quận Cẩm Lệ, Thành phố Đà Nẵng",
        "code": "20306",
        "parent_code": "495"
    },
    "20311": {
        "name": "Hòa Thọ Tây",
        "type": "phuong",
        "slug": "hoa-tho-tay",
        "name_with_type": "Phường Hòa Thọ Tây",
        "path": "Hòa Thọ Tây, Cẩm Lệ, Đà Nẵng",
        "path_with_type": "Phường Hòa Thọ Tây, Quận Cẩm Lệ, Thành phố Đà Nẵng",
        "code": "20311",
        "parent_code": "495"
    },
    "20312": {
        "name": "Hòa Thọ Đông",
        "type": "phuong",
        "slug": "hoa-tho-dong",
        "name_with_type": "Phường Hòa Thọ Đông",
        "path": "Hòa Thọ Đông, Cẩm Lệ, Đà Nẵng",
        "path_with_type": "Phường Hòa Thọ Đông, Quận Cẩm Lệ, Thành phố Đà Nẵng",
        "code": "20312",
        "parent_code": "495"
    },
    "20314": {
        "name": "Hòa Xuân",
        "type": "phuong",
        "slug": "hoa-xuan",
        "name_with_type": "Phường Hòa Xuân",
        "path": "Hòa Xuân, Cẩm Lệ, Đà Nẵng",
        "path_with_type": "Phường Hòa Xuân, Quận Cẩm Lệ, Thành phố Đà Nẵng",
        "code": "20314",
        "parent_code": "495"
    },
    "20293": {
        "name": "Hòa Bắc",
        "type": "xa",
        "slug": "hoa-bac",
        "name_with_type": "Xã Hòa Bắc",
        "path": "Hòa Bắc, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Bắc, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20293",
        "parent_code": "497"
    },
    "20296": {
        "name": "Hòa Liên",
        "type": "xa",
        "slug": "hoa-lien",
        "name_with_type": "Xã Hòa Liên",
        "path": "Hòa Liên, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Liên, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20296",
        "parent_code": "497"
    },
    "20299": {
        "name": "Hòa Ninh",
        "type": "xa",
        "slug": "hoa-ninh",
        "name_with_type": "Xã Hòa Ninh",
        "path": "Hòa Ninh, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Ninh, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20299",
        "parent_code": "497"
    },
    "20302": {
        "name": "Hòa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hòa Sơn",
        "path": "Hòa Sơn, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Sơn, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20302",
        "parent_code": "497"
    },
    "20308": {
        "name": "Hòa Nhơn",
        "type": "xa",
        "slug": "hoa-nhon",
        "name_with_type": "Xã Hòa Nhơn",
        "path": "Hòa Nhơn, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Nhơn, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20308",
        "parent_code": "497"
    },
    "20317": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Phú, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20317",
        "parent_code": "497"
    },
    "20320": {
        "name": "Hòa Phong",
        "type": "xa",
        "slug": "hoa-phong",
        "name_with_type": "Xã Hòa Phong",
        "path": "Hòa Phong, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Phong, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20320",
        "parent_code": "497"
    },
    "20323": {
        "name": "Hòa Châu",
        "type": "xa",
        "slug": "hoa-chau",
        "name_with_type": "Xã Hòa Châu",
        "path": "Hòa Châu, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Châu, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20323",
        "parent_code": "497"
    },
    "20326": {
        "name": "Hòa Tiến",
        "type": "xa",
        "slug": "hoa-tien",
        "name_with_type": "Xã Hòa Tiến",
        "path": "Hòa Tiến, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Tiến, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20326",
        "parent_code": "497"
    },
    "20329": {
        "name": "Hòa Phước",
        "type": "xa",
        "slug": "hoa-phuoc",
        "name_with_type": "Xã Hòa Phước",
        "path": "Hòa Phước, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Phước, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20329",
        "parent_code": "497"
    },
    "20332": {
        "name": "Hòa Khương",
        "type": "xa",
        "slug": "hoa-khuong",
        "name_with_type": "Xã Hòa Khương",
        "path": "Hòa Khương, Hòa Vang, Đà Nẵng",
        "path_with_type": "Xã Hòa Khương, Huyện Hòa Vang, Thành phố Đà Nẵng",
        "code": "20332",
        "parent_code": "497"
    },
    "03646": {
        "name": "Chiềng Lề",
        "type": "phuong",
        "slug": "chieng-le",
        "name_with_type": "Phường Chiềng Lề",
        "path": "Chiềng Lề, Sơn La, Sơn La",
        "path_with_type": "Phường Chiềng Lề, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03646",
        "parent_code": "116"
    },
    "03649": {
        "name": "Tô Hiệu",
        "type": "phuong",
        "slug": "to-hieu",
        "name_with_type": "Phường Tô Hiệu",
        "path": "Tô Hiệu, Sơn La, Sơn La",
        "path_with_type": "Phường Tô Hiệu, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03649",
        "parent_code": "116"
    },
    "03652": {
        "name": "Quyết Thắng",
        "type": "phuong",
        "slug": "quyet-thang",
        "name_with_type": "Phường Quyết Thắng",
        "path": "Quyết Thắng, Sơn La, Sơn La",
        "path_with_type": "Phường Quyết Thắng, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03652",
        "parent_code": "116"
    },
    "03655": {
        "name": "Quyết Tâm",
        "type": "phuong",
        "slug": "quyet-tam",
        "name_with_type": "Phường Quyết Tâm",
        "path": "Quyết Tâm, Sơn La, Sơn La",
        "path_with_type": "Phường Quyết Tâm, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03655",
        "parent_code": "116"
    },
    "03658": {
        "name": "Chiềng Cọ",
        "type": "xa",
        "slug": "chieng-co",
        "name_with_type": "Xã Chiềng Cọ",
        "path": "Chiềng Cọ, Sơn La, Sơn La",
        "path_with_type": "Xã Chiềng Cọ, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03658",
        "parent_code": "116"
    },
    "03661": {
        "name": "Chiềng Đen",
        "type": "xa",
        "slug": "chieng-den",
        "name_with_type": "Xã Chiềng Đen",
        "path": "Chiềng Đen, Sơn La, Sơn La",
        "path_with_type": "Xã Chiềng Đen, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03661",
        "parent_code": "116"
    },
    "03664": {
        "name": "Chiềng Xôm",
        "type": "xa",
        "slug": "chieng-xom",
        "name_with_type": "Xã Chiềng Xôm",
        "path": "Chiềng Xôm, Sơn La, Sơn La",
        "path_with_type": "Xã Chiềng Xôm, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03664",
        "parent_code": "116"
    },
    "03667": {
        "name": "Chiềng An",
        "type": "phuong",
        "slug": "chieng-an",
        "name_with_type": "Phường Chiềng An",
        "path": "Chiềng An, Sơn La, Sơn La",
        "path_with_type": "Phường Chiềng An, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03667",
        "parent_code": "116"
    },
    "03670": {
        "name": "Chiềng Cơi",
        "type": "phuong",
        "slug": "chieng-coi",
        "name_with_type": "Phường Chiềng Cơi",
        "path": "Chiềng Cơi, Sơn La, Sơn La",
        "path_with_type": "Phường Chiềng Cơi, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03670",
        "parent_code": "116"
    },
    "03673": {
        "name": "Chiềng Ngần",
        "type": "xa",
        "slug": "chieng-ngan",
        "name_with_type": "Xã Chiềng Ngần",
        "path": "Chiềng Ngần, Sơn La, Sơn La",
        "path_with_type": "Xã Chiềng Ngần, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03673",
        "parent_code": "116"
    },
    "03676": {
        "name": "Hua La",
        "type": "xa",
        "slug": "hua-la",
        "name_with_type": "Xã Hua La",
        "path": "Hua La, Sơn La, Sơn La",
        "path_with_type": "Xã Hua La, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03676",
        "parent_code": "116"
    },
    "03679": {
        "name": "Chiềng Sinh",
        "type": "phuong",
        "slug": "chieng-sinh",
        "name_with_type": "Phường Chiềng Sinh",
        "path": "Chiềng Sinh, Sơn La, Sơn La",
        "path_with_type": "Phường Chiềng Sinh, Thành phố Sơn La, Tỉnh Sơn La",
        "code": "03679",
        "parent_code": "116"
    },
    "03682": {
        "name": "Mường Chiên",
        "type": "xa",
        "slug": "muong-chien",
        "name_with_type": "Xã Mường Chiên",
        "path": "Mường Chiên, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Mường Chiên, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03682",
        "parent_code": "118"
    },
    "03685": {
        "name": "Cà Nàng",
        "type": "xa",
        "slug": "ca-nang",
        "name_with_type": "Xã Cà Nàng",
        "path": "Cà Nàng, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Cà Nàng, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03685",
        "parent_code": "118"
    },
    "03688": {
        "name": "Chiềng Khay",
        "type": "xa",
        "slug": "chieng-khay",
        "name_with_type": "Xã Chiềng Khay",
        "path": "Chiềng Khay, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Chiềng Khay, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03688",
        "parent_code": "118"
    },
    "03694": {
        "name": "Mường Giôn",
        "type": "xa",
        "slug": "muong-gion",
        "name_with_type": "Xã Mường Giôn",
        "path": "Mường Giôn, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Mường Giôn, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03694",
        "parent_code": "118"
    },
    "03697": {
        "name": "Pá Ma Pha Khinh",
        "type": "xa",
        "slug": "pa-ma-pha-khinh",
        "name_with_type": "Xã Pá Ma Pha Khinh",
        "path": "Pá Ma Pha Khinh, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Pá Ma Pha Khinh, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03697",
        "parent_code": "118"
    },
    "03700": {
        "name": "Chiềng Ơn",
        "type": "xa",
        "slug": "chieng-on",
        "name_with_type": "Xã Chiềng Ơn",
        "path": "Chiềng Ơn, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Chiềng Ơn, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03700",
        "parent_code": "118"
    },
    "03703": {
        "name": "Mường Giàng",
        "type": "xa",
        "slug": "muong-giang",
        "name_with_type": "Xã Mường Giàng",
        "path": "Mường Giàng, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Mường Giàng, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03703",
        "parent_code": "118"
    },
    "03706": {
        "name": "Chiềng Bằng",
        "type": "xa",
        "slug": "chieng-bang",
        "name_with_type": "Xã Chiềng Bằng",
        "path": "Chiềng Bằng, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Chiềng Bằng, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03706",
        "parent_code": "118"
    },
    "03709": {
        "name": "Mường Sại",
        "type": "xa",
        "slug": "muong-sai",
        "name_with_type": "Xã Mường Sại",
        "path": "Mường Sại, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Mường Sại, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03709",
        "parent_code": "118"
    },
    "03712": {
        "name": "Nậm ét",
        "type": "xa",
        "slug": "nam-et",
        "name_with_type": "Xã Nậm ét",
        "path": "Nậm ét, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Nậm ét, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03712",
        "parent_code": "118"
    },
    "03718": {
        "name": "Chiềng Khoang",
        "type": "xa",
        "slug": "chieng-khoang",
        "name_with_type": "Xã Chiềng Khoang",
        "path": "Chiềng Khoang, Quỳnh Nhai, Sơn La",
        "path_with_type": "Xã Chiềng Khoang, Huyện Quỳnh Nhai, Tỉnh Sơn La",
        "code": "03718",
        "parent_code": "118"
    },
    "03721": {
        "name": "Thuận Châu",
        "type": "thi-tran",
        "slug": "thuan-chau",
        "name_with_type": "Thị trấn Thuận Châu",
        "path": "Thuận Châu, Thuận Châu, Sơn La",
        "path_with_type": "Thị trấn Thuận Châu, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03721",
        "parent_code": "119"
    },
    "03724": {
        "name": "Phỏng Lái",
        "type": "xa",
        "slug": "phong-lai",
        "name_with_type": "Xã Phỏng Lái",
        "path": "Phỏng Lái, Thuận Châu, Sơn La",
        "path_with_type": "Xã Phỏng Lái, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03724",
        "parent_code": "119"
    },
    "03727": {
        "name": "Mường é",
        "type": "xa",
        "slug": "muong-e",
        "name_with_type": "Xã Mường é",
        "path": "Mường é, Thuận Châu, Sơn La",
        "path_with_type": "Xã Mường é, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03727",
        "parent_code": "119"
    },
    "03730": {
        "name": "Chiềng Pha",
        "type": "xa",
        "slug": "chieng-pha",
        "name_with_type": "Xã Chiềng Pha",
        "path": "Chiềng Pha, Thuận Châu, Sơn La",
        "path_with_type": "Xã Chiềng Pha, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03730",
        "parent_code": "119"
    },
    "03733": {
        "name": "Chiềng La",
        "type": "xa",
        "slug": "chieng-la",
        "name_with_type": "Xã Chiềng La",
        "path": "Chiềng La, Thuận Châu, Sơn La",
        "path_with_type": "Xã Chiềng La, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03733",
        "parent_code": "119"
    },
    "03736": {
        "name": "Chiềng Ngàm",
        "type": "xa",
        "slug": "chieng-ngam",
        "name_with_type": "Xã Chiềng Ngàm",
        "path": "Chiềng Ngàm, Thuận Châu, Sơn La",
        "path_with_type": "Xã Chiềng Ngàm, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03736",
        "parent_code": "119"
    },
    "03739": {
        "name": "Liệp Tè",
        "type": "xa",
        "slug": "liep-te",
        "name_with_type": "Xã Liệp Tè",
        "path": "Liệp Tè, Thuận Châu, Sơn La",
        "path_with_type": "Xã Liệp Tè, Huyện Thuận Châu, T���nh Sơn La",
        "code": "03739",
        "parent_code": "119"
    },
    "03742": {
        "name": "é Tòng",
        "type": "xa",
        "slug": "e-tong",
        "name_with_type": "Xã é Tòng",
        "path": "é Tòng, Thuận Châu, Sơn La",
        "path_with_type": "Xã é Tòng, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03742",
        "parent_code": "119"
    },
    "03745": {
        "name": "Phỏng Lập",
        "type": "xa",
        "slug": "phong-lap",
        "name_with_type": "Xã Phỏng Lập",
        "path": "Phỏng Lập, Thuận Châu, Sơn La",
        "path_with_type": "Xã Phỏng Lập, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03745",
        "parent_code": "119"
    },
    "03748": {
        "name": "Phổng Lăng",
        "type": "xa",
        "slug": "phong-lang",
        "name_with_type": "Xã Phổng Lăng",
        "path": "Phổng Lăng, Thuận Châu, Sơn La",
        "path_with_type": "Xã Phổng Lăng, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03748",
        "parent_code": "119"
    },
    "03751": {
        "name": "Chiềng Ly",
        "type": "xa",
        "slug": "chieng-ly",
        "name_with_type": "Xã Chiềng Ly",
        "path": "Chiềng Ly, Thuận Châu, Sơn La",
        "path_with_type": "Xã Chiềng Ly, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03751",
        "parent_code": "119"
    },
    "03754": {
        "name": "Nong Lay",
        "type": "xa",
        "slug": "nong-lay",
        "name_with_type": "Xã Nong Lay",
        "path": "Nong Lay, Thuận Châu, Sơn La",
        "path_with_type": "Xã Nong Lay, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03754",
        "parent_code": "119"
    },
    "03757": {
        "name": "Mường Khiêng",
        "type": "xa",
        "slug": "muong-khieng",
        "name_with_type": "Xã Mường Khiêng",
        "path": "Mường Khiêng, Thuận Châu, Sơn La",
        "path_with_type": "Xã Mường Khiêng, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03757",
        "parent_code": "119"
    },
    "03760": {
        "name": "Mường Bám",
        "type": "xa",
        "slug": "muong-bam",
        "name_with_type": "Xã Mường Bám",
        "path": "Mường Bám, Thuận Châu, Sơn La",
        "path_with_type": "Xã Mường Bám, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03760",
        "parent_code": "119"
    },
    "03763": {
        "name": "Long Hẹ",
        "type": "xa",
        "slug": "long-he",
        "name_with_type": "Xã Long Hẹ",
        "path": "Long Hẹ, Thuận Châu, Sơn La",
        "path_with_type": "Xã Long Hẹ, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03763",
        "parent_code": "119"
    },
    "03766": {
        "name": "Chiềng Bôm",
        "type": "xa",
        "slug": "chieng-bom",
        "name_with_type": "Xã Chiềng Bôm",
        "path": "Chiềng Bôm, Thuận Châu, Sơn La",
        "path_with_type": "Xã Chiềng Bôm, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03766",
        "parent_code": "119"
    },
    "03769": {
        "name": "Thôn Mòn",
        "type": "xa",
        "slug": "thon-mon",
        "name_with_type": "Xã Thôn Mòn",
        "path": "Thôn Mòn, Thuận Châu, Sơn La",
        "path_with_type": "Xã Thôn Mòn, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03769",
        "parent_code": "119"
    },
    "03772": {
        "name": "Tòng Lệnh",
        "type": "xa",
        "slug": "tong-lenh",
        "name_with_type": "Xã Tòng Lệnh",
        "path": "Tòng Lệnh, Thuận Châu, Sơn La",
        "path_with_type": "Xã Tòng Lệnh, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03772",
        "parent_code": "119"
    },
    "03775": {
        "name": "Tòng Cọ",
        "type": "xa",
        "slug": "tong-co",
        "name_with_type": "Xã Tòng Cọ",
        "path": "Tòng Cọ, Thuận Châu, Sơn La",
        "path_with_type": "Xã Tòng Cọ, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03775",
        "parent_code": "119"
    },
    "03778": {
        "name": "Bó Mười",
        "type": "xa",
        "slug": "bo-muoi",
        "name_with_type": "Xã Bó Mười",
        "path": "Bó Mười, Thuận Châu, Sơn La",
        "path_with_type": "Xã Bó Mười, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03778",
        "parent_code": "119"
    },
    "03781": {
        "name": "Co Mạ",
        "type": "xa",
        "slug": "co-ma",
        "name_with_type": "Xã Co Mạ",
        "path": "Co Mạ, Thuận Châu, Sơn La",
        "path_with_type": "Xã Co Mạ, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03781",
        "parent_code": "119"
    },
    "03784": {
        "name": "Púng Tra",
        "type": "xa",
        "slug": "pung-tra",
        "name_with_type": "Xã Púng Tra",
        "path": "Púng Tra, Thuận Châu, Sơn La",
        "path_with_type": "Xã Púng Tra, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03784",
        "parent_code": "119"
    },
    "03787": {
        "name": "Chiềng Pấc",
        "type": "xa",
        "slug": "chieng-pac",
        "name_with_type": "Xã Chiềng Pấc",
        "path": "Chiềng Pấc, Thuận Châu, Sơn La",
        "path_with_type": "Xã Chiềng Pấc, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03787",
        "parent_code": "119"
    },
    "03790": {
        "name": "Nậm Lầu",
        "type": "xa",
        "slug": "nam-lau",
        "name_with_type": "Xã Nậm Lầu",
        "path": "Nậm Lầu, Thuận Châu, Sơn La",
        "path_with_type": "Xã Nậm Lầu, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03790",
        "parent_code": "119"
    },
    "03793": {
        "name": "Bon Phặng",
        "type": "xa",
        "slug": "bon-phang",
        "name_with_type": "Xã Bon Phặng",
        "path": "Bon Phặng, Thuận Châu, Sơn La",
        "path_with_type": "Xã Bon Phặng, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03793",
        "parent_code": "119"
    },
    "03796": {
        "name": "Co Tòng",
        "type": "xa",
        "slug": "co-tong",
        "name_with_type": "Xã Co Tòng",
        "path": "Co Tòng, Thuận Châu, Sơn La",
        "path_with_type": "Xã Co Tòng, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03796",
        "parent_code": "119"
    },
    "03799": {
        "name": "Muội Nọi",
        "type": "xa",
        "slug": "muoi-noi",
        "name_with_type": "Xã Muội Nọi",
        "path": "Muội Nọi, Thuận Châu, Sơn La",
        "path_with_type": "Xã Muội Nọi, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03799",
        "parent_code": "119"
    },
    "03802": {
        "name": "Pá Lông",
        "type": "xa",
        "slug": "pa-long",
        "name_with_type": "Xã Pá Lông",
        "path": "Pá Lông, Thuận Châu, Sơn La",
        "path_with_type": "Xã Pá Lông, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03802",
        "parent_code": "119"
    },
    "03805": {
        "name": "Bản Lầm",
        "type": "xa",
        "slug": "ban-lam",
        "name_with_type": "Xã Bản Lầm",
        "path": "Bản Lầm, Thuận Châu, Sơn La",
        "path_with_type": "Xã Bản Lầm, Huyện Thuận Châu, Tỉnh Sơn La",
        "code": "03805",
        "parent_code": "119"
    },
    "03808": {
        "name": "Ít Ong",
        "type": "thi-tran",
        "slug": "it-ong",
        "name_with_type": "Thị trấn Ít Ong",
        "path": "Ít Ong, Mường La, Sơn La",
        "path_with_type": "Thị trấn Ít Ong, Huyện Mường La, Tỉnh Sơn La",
        "code": "03808",
        "parent_code": "120"
    },
    "03811": {
        "name": "Nậm Giôn",
        "type": "xa",
        "slug": "nam-gion",
        "name_with_type": "Xã Nậm Giôn",
        "path": "Nậm Giôn, Mường La, Sơn La",
        "path_with_type": "Xã Nậm Giôn, Huyện Mường La, Tỉnh Sơn La",
        "code": "03811",
        "parent_code": "120"
    },
    "03814": {
        "name": "Chiềng Lao",
        "type": "xa",
        "slug": "chieng-lao",
        "name_with_type": "Xã Chiềng Lao",
        "path": "Chiềng Lao, Mường La, Sơn La",
        "path_with_type": "Xã Chiềng Lao, Huyện Mường La, Tỉnh Sơn La",
        "code": "03814",
        "parent_code": "120"
    },
    "03817": {
        "name": "Hua Trai",
        "type": "xa",
        "slug": "hua-trai",
        "name_with_type": "Xã Hua Trai",
        "path": "Hua Trai, Mường La, Sơn La",
        "path_with_type": "Xã Hua Trai, Huyện Mường La, Tỉnh Sơn La",
        "code": "03817",
        "parent_code": "120"
    },
    "03820": {
        "name": "Ngọc Chiến",
        "type": "xa",
        "slug": "ngoc-chien",
        "name_with_type": "Xã Ngọc Chiến",
        "path": "Ngọc Chiến, Mường La, Sơn La",
        "path_with_type": "Xã Ngọc Chiến, Huyện Mường La, Tỉnh Sơn La",
        "code": "03820",
        "parent_code": "120"
    },
    "03823": {
        "name": "Mường Trai",
        "type": "xa",
        "slug": "muong-trai",
        "name_with_type": "Xã Mường Trai",
        "path": "Mường Trai, Mường La, Sơn La",
        "path_with_type": "Xã Mường Trai, Huyện Mường La, Tỉnh Sơn La",
        "code": "03823",
        "parent_code": "120"
    },
    "03826": {
        "name": "Nậm Păm",
        "type": "xa",
        "slug": "nam-pam",
        "name_with_type": "Xã Nậm Păm",
        "path": "Nậm Păm, Mường La, Sơn La",
        "path_with_type": "Xã Nậm Păm, Huyện Mường La, Tỉnh Sơn La",
        "code": "03826",
        "parent_code": "120"
    },
    "03829": {
        "name": "Chiềng Muôn",
        "type": "xa",
        "slug": "chieng-muon",
        "name_with_type": "Xã Chiềng Muôn",
        "path": "Chiềng Muôn, Mường La, Sơn La",
        "path_with_type": "Xã Chiềng Muôn, Huyện Mường La, Tỉnh Sơn La",
        "code": "03829",
        "parent_code": "120"
    },
    "03832": {
        "name": "Chiềng Ân",
        "type": "xa",
        "slug": "chieng-an",
        "name_with_type": "Xã Chiềng Ân",
        "path": "Chiềng Ân, Mường La, Sơn La",
        "path_with_type": "Xã Chiềng Ân, Huyện Mường La, Tỉnh Sơn La",
        "code": "03832",
        "parent_code": "120"
    },
    "03835": {
        "name": "Pi Toong",
        "type": "xa",
        "slug": "pi-toong",
        "name_with_type": "Xã Pi Toong",
        "path": "Pi Toong, Mường La, Sơn La",
        "path_with_type": "Xã Pi Toong, Huyện Mường La, Tỉnh Sơn La",
        "code": "03835",
        "parent_code": "120"
    },
    "03838": {
        "name": "Chiềng Công",
        "type": "xa",
        "slug": "chieng-cong",
        "name_with_type": "Xã Chiềng Công",
        "path": "Chiềng Công, Mường La, Sơn La",
        "path_with_type": "Xã Chiềng Công, Huyện Mường La, Tỉnh Sơn La",
        "code": "03838",
        "parent_code": "120"
    },
    "03841": {
        "name": "Tạ Bú",
        "type": "xa",
        "slug": "ta-bu",
        "name_with_type": "Xã Tạ Bú",
        "path": "Tạ Bú, Mường La, Sơn La",
        "path_with_type": "Xã Tạ Bú, Huyện Mường La, Tỉnh Sơn La",
        "code": "03841",
        "parent_code": "120"
    },
    "03844": {
        "name": "Chiềng San",
        "type": "xa",
        "slug": "chieng-san",
        "name_with_type": "Xã Chiềng San",
        "path": "Chiềng San, Mường La, Sơn La",
        "path_with_type": "Xã Chiềng San, Huyện Mường La, Tỉnh Sơn La",
        "code": "03844",
        "parent_code": "120"
    },
    "03847": {
        "name": "Mường Bú",
        "type": "xa",
        "slug": "muong-bu",
        "name_with_type": "Xã Mường Bú",
        "path": "Mường Bú, Mường La, Sơn La",
        "path_with_type": "Xã Mường Bú, Huyện Mường La, Tỉnh Sơn La",
        "code": "03847",
        "parent_code": "120"
    },
    "03850": {
        "name": "Chiềng Hoa",
        "type": "xa",
        "slug": "chieng-hoa",
        "name_with_type": "Xã Chiềng Hoa",
        "path": "Chiềng Hoa, Mường La, Sơn La",
        "path_with_type": "Xã Chiềng Hoa, Huyện Mường La, Tỉnh Sơn La",
        "code": "03850",
        "parent_code": "120"
    },
    "03853": {
        "name": "Mường Chùm",
        "type": "xa",
        "slug": "muong-chum",
        "name_with_type": "Xã Mường Chùm",
        "path": "Mường Chùm, Mường La, Sơn La",
        "path_with_type": "Xã Mường Chùm, Huyện Mường La, Tỉnh Sơn La",
        "code": "03853",
        "parent_code": "120"
    },
    "03856": {
        "name": "Bắc Yên",
        "type": "thi-tran",
        "slug": "bac-yen",
        "name_with_type": "Thị trấn Bắc Yên",
        "path": "Bắc Yên, Bắc Yên, Sơn La",
        "path_with_type": "Thị trấn Bắc Yên, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03856",
        "parent_code": "121"
    },
    "03859": {
        "name": "Phiêng Ban",
        "type": "xa",
        "slug": "phieng-ban",
        "name_with_type": "Xã Phiêng Ban",
        "path": "Phiêng Ban, Bắc Yên, Sơn La",
        "path_with_type": "Xã Phiêng Ban, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03859",
        "parent_code": "121"
    },
    "03862": {
        "name": "Hang Chú",
        "type": "xa",
        "slug": "hang-chu",
        "name_with_type": "Xã Hang Chú",
        "path": "Hang Chú, Bắc Yên, Sơn La",
        "path_with_type": "Xã Hang Chú, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03862",
        "parent_code": "121"
    },
    "03865": {
        "name": "Xín Vàng",
        "type": "xa",
        "slug": "xin-vang",
        "name_with_type": "Xã Xín Vàng",
        "path": "Xín Vàng, Bắc Yên, Sơn La",
        "path_with_type": "Xã Xín Vàng, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03865",
        "parent_code": "121"
    },
    "03868": {
        "name": "Tà Xùa",
        "type": "xa",
        "slug": "ta-xua",
        "name_with_type": "Xã Tà Xùa",
        "path": "Tà Xùa, Bắc Yên, Sơn La",
        "path_with_type": "Xã Tà Xùa, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03868",
        "parent_code": "121"
    },
    "03869": {
        "name": "Háng Đồng",
        "type": "xa",
        "slug": "hang-dong",
        "name_with_type": "Xã Háng Đồng",
        "path": "Háng Đồng, Bắc Yên, Sơn La",
        "path_with_type": "Xã Háng Đồng, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03869",
        "parent_code": "121"
    },
    "03871": {
        "name": "Bắc Ngà",
        "type": "xa",
        "slug": "bac-nga",
        "name_with_type": "Xã Bắc Ngà",
        "path": "Bắc Ngà, Bắc Yên, Sơn La",
        "path_with_type": "Xã Bắc Ngà, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03871",
        "parent_code": "121"
    },
    "03874": {
        "name": "Làng Chếu",
        "type": "xa",
        "slug": "lang-cheu",
        "name_with_type": "Xã Làng Chếu",
        "path": "Làng Chếu, Bắc Yên, Sơn La",
        "path_with_type": "Xã Làng Chếu, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03874",
        "parent_code": "121"
    },
    "03877": {
        "name": "Chim Vàn",
        "type": "xa",
        "slug": "chim-van",
        "name_with_type": "Xã Chim Vàn",
        "path": "Chim Vàn, Bắc Yên, Sơn La",
        "path_with_type": "Xã Chim Vàn, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03877",
        "parent_code": "121"
    },
    "03880": {
        "name": "Mường Khoa",
        "type": "xa",
        "slug": "muong-khoa",
        "name_with_type": "Xã Mường Khoa",
        "path": "Mường Khoa, Bắc Yên, Sơn La",
        "path_with_type": "Xã Mường Khoa, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03880",
        "parent_code": "121"
    },
    "03883": {
        "name": "Song Pe",
        "type": "xa",
        "slug": "song-pe",
        "name_with_type": "Xã Song Pe",
        "path": "Song Pe, Bắc Yên, Sơn La",
        "path_with_type": "Xã Song Pe, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03883",
        "parent_code": "121"
    },
    "03886": {
        "name": "Hồng Ngài",
        "type": "xa",
        "slug": "hong-ngai",
        "name_with_type": "Xã Hồng Ngài",
        "path": "Hồng Ngài, Bắc Yên, Sơn La",
        "path_with_type": "Xã Hồng Ngài, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03886",
        "parent_code": "121"
    },
    "03889": {
        "name": "Tạ Khoa",
        "type": "xa",
        "slug": "ta-khoa",
        "name_with_type": "Xã Tạ Khoa",
        "path": "Tạ Khoa, Bắc Yên, Sơn La",
        "path_with_type": "Xã Tạ Khoa, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03889",
        "parent_code": "121"
    },
    "03890": {
        "name": "Hua Nhàn",
        "type": "xa",
        "slug": "hua-nhan",
        "name_with_type": "Xã Hua Nhàn",
        "path": "Hua Nhàn, Bắc Yên, Sơn La",
        "path_with_type": "Xã Hua Nhàn, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03890",
        "parent_code": "121"
    },
    "03892": {
        "name": "Phiêng Kôn",
        "type": "xa",
        "slug": "phieng-kon",
        "name_with_type": "Xã Phiêng Kôn",
        "path": "Phiêng Kôn, Bắc Yên, Sơn La",
        "path_with_type": "Xã Phiêng Kôn, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03892",
        "parent_code": "121"
    },
    "03895": {
        "name": "Chiềng Sại",
        "type": "xa",
        "slug": "chieng-sai",
        "name_with_type": "Xã Chiềng Sại",
        "path": "Chiềng Sại, Bắc Yên, Sơn La",
        "path_with_type": "Xã Chiềng Sại, Huyện Bắc Yên, Tỉnh Sơn La",
        "code": "03895",
        "parent_code": "121"
    },
    "03898": {
        "name": "Phù Yên",
        "type": "thi-tran",
        "slug": "phu-yen",
        "name_with_type": "Thị trấn Phù Yên",
        "path": "Phù Yên, Phù Yên, Sơn La",
        "path_with_type": "Thị trấn Phù Yên, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03898",
        "parent_code": "122"
    },
    "03901": {
        "name": "Suối Tọ",
        "type": "xa",
        "slug": "suoi-to",
        "name_with_type": "Xã Suối Tọ",
        "path": "Suối Tọ, Phù Yên, Sơn La",
        "path_with_type": "Xã Suối Tọ, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03901",
        "parent_code": "122"
    },
    "03904": {
        "name": "Mường Thải",
        "type": "xa",
        "slug": "muong-thai",
        "name_with_type": "Xã Mường Thải",
        "path": "Mường Thải, Phù Yên, Sơn La",
        "path_with_type": "Xã Mường Thải, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03904",
        "parent_code": "122"
    },
    "03907": {
        "name": "Mường Cơi",
        "type": "xa",
        "slug": "muong-coi",
        "name_with_type": "Xã Mường Cơi",
        "path": "Mường Cơi, Phù Yên, Sơn La",
        "path_with_type": "Xã Mường Cơi, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03907",
        "parent_code": "122"
    },
    "03910": {
        "name": "Quang Huy",
        "type": "xa",
        "slug": "quang-huy",
        "name_with_type": "Xã Quang Huy",
        "path": "Quang Huy, Phù Yên, Sơn La",
        "path_with_type": "Xã Quang Huy, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03910",
        "parent_code": "122"
    },
    "03913": {
        "name": "Huy Bắc",
        "type": "xa",
        "slug": "huy-bac",
        "name_with_type": "Xã Huy Bắc",
        "path": "Huy Bắc, Phù Yên, Sơn La",
        "path_with_type": "Xã Huy Bắc, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03913",
        "parent_code": "122"
    },
    "03916": {
        "name": "Huy Thượng",
        "type": "xa",
        "slug": "huy-thuong",
        "name_with_type": "Xã Huy Thượng",
        "path": "Huy Thượng, Phù Yên, Sơn La",
        "path_with_type": "Xã Huy Thượng, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03916",
        "parent_code": "122"
    },
    "03919": {
        "name": "Tân Lang",
        "type": "xa",
        "slug": "tan-lang",
        "name_with_type": "Xã Tân Lang",
        "path": "Tân Lang, Phù Yên, Sơn La",
        "path_with_type": "Xã Tân Lang, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03919",
        "parent_code": "122"
    },
    "03922": {
        "name": "Gia Phù",
        "type": "xa",
        "slug": "gia-phu",
        "name_with_type": "Xã Gia Phù",
        "path": "Gia Phù, Phù Yên, Sơn La",
        "path_with_type": "Xã Gia Phù, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03922",
        "parent_code": "122"
    },
    "03925": {
        "name": "Tường Phù",
        "type": "xa",
        "slug": "tuong-phu",
        "name_with_type": "Xã Tường Phù",
        "path": "Tường Phù, Phù Yên, Sơn La",
        "path_with_type": "Xã Tường Phù, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03925",
        "parent_code": "122"
    },
    "03928": {
        "name": "Huy Hạ",
        "type": "xa",
        "slug": "huy-ha",
        "name_with_type": "Xã Huy Hạ",
        "path": "Huy Hạ, Phù Yên, Sơn La",
        "path_with_type": "Xã Huy Hạ, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03928",
        "parent_code": "122"
    },
    "03931": {
        "name": "Huy Tân",
        "type": "xa",
        "slug": "huy-tan",
        "name_with_type": "Xã Huy Tân",
        "path": "Huy Tân, Phù Yên, Sơn La",
        "path_with_type": "Xã Huy Tân, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03931",
        "parent_code": "122"
    },
    "03934": {
        "name": "Mường Lang",
        "type": "xa",
        "slug": "muong-lang",
        "name_with_type": "Xã Mường Lang",
        "path": "Mường Lang, Phù Yên, Sơn La",
        "path_with_type": "Xã Mường Lang, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03934",
        "parent_code": "122"
    },
    "03937": {
        "name": "Suối Bau",
        "type": "xa",
        "slug": "suoi-bau",
        "name_with_type": "Xã Suối Bau",
        "path": "Suối Bau, Phù Yên, Sơn La",
        "path_with_type": "Xã Suối Bau, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03937",
        "parent_code": "122"
    },
    "03940": {
        "name": "Huy Tường",
        "type": "xa",
        "slug": "huy-tuong",
        "name_with_type": "Xã Huy Tường",
        "path": "Huy Tường, Phù Yên, Sơn La",
        "path_with_type": "Xã Huy Tường, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03940",
        "parent_code": "122"
    },
    "03943": {
        "name": "Mường Do",
        "type": "xa",
        "slug": "muong-do",
        "name_with_type": "Xã Mường Do",
        "path": "Mường Do, Phù Yên, Sơn La",
        "path_with_type": "Xã Mường Do, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03943",
        "parent_code": "122"
    },
    "03946": {
        "name": "Sập Xa",
        "type": "xa",
        "slug": "sap-xa",
        "name_with_type": "Xã Sập Xa",
        "path": "Sập Xa, Phù Yên, Sơn La",
        "path_with_type": "Xã Sập Xa, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03946",
        "parent_code": "122"
    },
    "03949": {
        "name": "Tường Thượng",
        "type": "xa",
        "slug": "tuong-thuong",
        "name_with_type": "Xã Tường Thượng",
        "path": "Tường Thượng, Phù Yên, Sơn La",
        "path_with_type": "Xã Tường Thượng, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03949",
        "parent_code": "122"
    },
    "03952": {
        "name": "Tường Tiến",
        "type": "xa",
        "slug": "tuong-tien",
        "name_with_type": "Xã Tường Tiến",
        "path": "Tường Tiến, Phù Yên, Sơn La",
        "path_with_type": "Xã Tường Tiến, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03952",
        "parent_code": "122"
    },
    "03955": {
        "name": "Tường Phong",
        "type": "xa",
        "slug": "tuong-phong",
        "name_with_type": "Xã Tường Phong",
        "path": "Tường Phong, Phù Yên, Sơn La",
        "path_with_type": "Xã Tường Phong, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03955",
        "parent_code": "122"
    },
    "03958": {
        "name": "Tường Hạ",
        "type": "xa",
        "slug": "tuong-ha",
        "name_with_type": "Xã Tường Hạ",
        "path": "Tường Hạ, Phù Yên, Sơn La",
        "path_with_type": "Xã Tường Hạ, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03958",
        "parent_code": "122"
    },
    "03961": {
        "name": "Kim Bon",
        "type": "xa",
        "slug": "kim-bon",
        "name_with_type": "Xã Kim Bon",
        "path": "Kim Bon, Phù Yên, Sơn La",
        "path_with_type": "Xã Kim Bon, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03961",
        "parent_code": "122"
    },
    "03964": {
        "name": "Mường Bang",
        "type": "xa",
        "slug": "muong-bang",
        "name_with_type": "Xã Mường Bang",
        "path": "Mường Bang, Phù Yên, Sơn La",
        "path_with_type": "Xã Mường Bang, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03964",
        "parent_code": "122"
    },
    "03967": {
        "name": "Đá Đỏ",
        "type": "xa",
        "slug": "da-do",
        "name_with_type": "Xã Đá Đỏ",
        "path": "Đá Đỏ, Phù Yên, Sơn La",
        "path_with_type": "Xã Đá Đỏ, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03967",
        "parent_code": "122"
    },
    "03970": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Phù Yên, Sơn La",
        "path_with_type": "Xã Tân Phong, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03970",
        "parent_code": "122"
    },
    "03973": {
        "name": "Nam Phong",
        "type": "xa",
        "slug": "nam-phong",
        "name_with_type": "Xã Nam Phong",
        "path": "Nam Phong, Phù Yên, Sơn La",
        "path_with_type": "Xã Nam Phong, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03973",
        "parent_code": "122"
    },
    "03976": {
        "name": "Bắc Phong",
        "type": "xa",
        "slug": "bac-phong",
        "name_with_type": "Xã Bắc Phong",
        "path": "Bắc Phong, Phù Yên, Sơn La",
        "path_with_type": "Xã Bắc Phong, Huyện Phù Yên, Tỉnh Sơn La",
        "code": "03976",
        "parent_code": "122"
    },
    "03979": {
        "name": "Mộc Châu",
        "type": "thi-tran",
        "slug": "moc-chau",
        "name_with_type": "Thị trấn Mộc Châu",
        "path": "Mộc Châu, Mộc Châu, Sơn La",
        "path_with_type": "Thị trấn Mộc Châu, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "03979",
        "parent_code": "123"
    },
    "03982": {
        "name": "NT Mộc Châu",
        "type": "thi-tran",
        "slug": "nt-moc-chau",
        "name_with_type": "Thị trấn NT Mộc Châu",
        "path": "NT Mộc Châu, Mộc Châu, Sơn La",
        "path_with_type": "Thị trấn NT Mộc Châu, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "03982",
        "parent_code": "123"
    },
    "03985": {
        "name": "Chiềng Sơn",
        "type": "xa",
        "slug": "chieng-son",
        "name_with_type": "Xã Chiềng Sơn",
        "path": "Chiềng Sơn, Mộc Châu, Sơn La",
        "path_with_type": "Xã Chiềng Sơn, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "03985",
        "parent_code": "123"
    },
    "03988": {
        "name": "Tân Hợp",
        "type": "xa",
        "slug": "tan-hop",
        "name_with_type": "Xã Tân Hợp",
        "path": "Tân Hợp, Mộc Châu, Sơn La",
        "path_with_type": "Xã Tân Hợp, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "03988",
        "parent_code": "123"
    },
    "03991": {
        "name": "Qui Hướng",
        "type": "xa",
        "slug": "qui-huong",
        "name_with_type": "Xã Qui Hướng",
        "path": "Qui Hướng, Mộc Châu, Sơn La",
        "path_with_type": "Xã Qui Hướng, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "03991",
        "parent_code": "123"
    },
    "03997": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Mộc Châu, Sơn La",
        "path_with_type": "Xã Tân Lập, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "03997",
        "parent_code": "123"
    },
    "04000": {
        "name": "Nà Mường",
        "type": "xa",
        "slug": "na-muong",
        "name_with_type": "Xã Nà Mường",
        "path": "Nà Mường, Mộc Châu, Sơn La",
        "path_with_type": "Xã Nà Mường, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "04000",
        "parent_code": "123"
    },
    "04003": {
        "name": "Tà Lai",
        "type": "xa",
        "slug": "ta-lai",
        "name_with_type": "Xã Tà Lai",
        "path": "Tà Lai, Mộc Châu, Sơn La",
        "path_with_type": "Xã Tà Lai, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "04003",
        "parent_code": "123"
    },
    "04012": {
        "name": "Chiềng Hắc",
        "type": "xa",
        "slug": "chieng-hac",
        "name_with_type": "Xã Chiềng Hắc",
        "path": "Chiềng Hắc, Mộc Châu, Sơn La",
        "path_with_type": "Xã Chiềng Hắc, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "04012",
        "parent_code": "123"
    },
    "04015": {
        "name": "Hua Păng",
        "type": "xa",
        "slug": "hua-pang",
        "name_with_type": "Xã Hua Păng",
        "path": "Hua Păng, Mộc Châu, Sơn La",
        "path_with_type": "Xã Hua Păng, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "04015",
        "parent_code": "123"
    },
    "04024": {
        "name": "Chiềng Khừa",
        "type": "xa",
        "slug": "chieng-khua",
        "name_with_type": "Xã Chiềng Khừa",
        "path": "Chiềng Khừa, Mộc Châu, Sơn La",
        "path_with_type": "Xã Chiềng Khừa, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "04024",
        "parent_code": "123"
    },
    "04027": {
        "name": "Mường Sang",
        "type": "xa",
        "slug": "muong-sang",
        "name_with_type": "Xã Mường Sang",
        "path": "Mường Sang, Mộc Châu, Sơn La",
        "path_with_type": "Xã Mường Sang, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "04027",
        "parent_code": "123"
    },
    "04030": {
        "name": "Đông Sang",
        "type": "xa",
        "slug": "dong-sang",
        "name_with_type": "Xã Đông Sang",
        "path": "Đông Sang, Mộc Châu, Sơn La",
        "path_with_type": "Xã Đông Sang, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "04030",
        "parent_code": "123"
    },
    "04033": {
        "name": "Phiêng Luông",
        "type": "xa",
        "slug": "phieng-luong",
        "name_with_type": "Xã Phiêng Luông",
        "path": "Phiêng Luông, Mộc Châu, Sơn La",
        "path_with_type": "Xã Phiêng Luông, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "04033",
        "parent_code": "123"
    },
    "04045": {
        "name": "Lóng Sập",
        "type": "xa",
        "slug": "long-sap",
        "name_with_type": "Xã Lóng Sập",
        "path": "Lóng Sập, Mộc Châu, Sơn La",
        "path_with_type": "Xã Lóng Sập, Huyện Mộc Châu, Tỉnh Sơn La",
        "code": "04045",
        "parent_code": "123"
    },
    "04060": {
        "name": "Yên Châu",
        "type": "thi-tran",
        "slug": "yen-chau",
        "name_with_type": "Thị trấn Yên Châu",
        "path": "Yên Châu, Yên Châu, Sơn La",
        "path_with_type": "Thị trấn Yên Châu, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04060",
        "parent_code": "124"
    },
    "04063": {
        "name": "Chiềng Đông",
        "type": "xa",
        "slug": "chieng-dong",
        "name_with_type": "Xã Chiềng Đông",
        "path": "Chiềng Đông, Yên Châu, Sơn La",
        "path_with_type": "Xã Chiềng Đông, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04063",
        "parent_code": "124"
    },
    "04066": {
        "name": "Sập Vạt",
        "type": "xa",
        "slug": "sap-vat",
        "name_with_type": "Xã Sập Vạt",
        "path": "Sập Vạt, Yên Châu, Sơn La",
        "path_with_type": "Xã Sập Vạt, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04066",
        "parent_code": "124"
    },
    "04069": {
        "name": "Chiềng Sàng",
        "type": "xa",
        "slug": "chieng-sang",
        "name_with_type": "Xã Chiềng Sàng",
        "path": "Chiềng Sàng, Yên Châu, Sơn La",
        "path_with_type": "Xã Chiềng Sàng, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04069",
        "parent_code": "124"
    },
    "04072": {
        "name": "Chiềng Pằn",
        "type": "xa",
        "slug": "chieng-pan",
        "name_with_type": "Xã Chiềng Pằn",
        "path": "Chiềng Pằn, Yên Châu, Sơn La",
        "path_with_type": "Xã Chiềng Pằn, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04072",
        "parent_code": "124"
    },
    "04075": {
        "name": "Viêng Lán",
        "type": "xa",
        "slug": "vieng-lan",
        "name_with_type": "Xã Viêng Lán",
        "path": "Viêng Lán, Yên Châu, Sơn La",
        "path_with_type": "Xã Viêng Lán, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04075",
        "parent_code": "124"
    },
    "04078": {
        "name": "Chiềng Hặc",
        "type": "xa",
        "slug": "chieng-hac",
        "name_with_type": "Xã Chiềng Hặc",
        "path": "Chiềng Hặc, Yên Châu, Sơn La",
        "path_with_type": "Xã Chiềng Hặc, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04078",
        "parent_code": "124"
    },
    "04081": {
        "name": "Mường Lựm",
        "type": "xa",
        "slug": "muong-lum",
        "name_with_type": "Xã Mường Lựm",
        "path": "Mường Lựm, Yên Châu, Sơn La",
        "path_with_type": "Xã Mường Lựm, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04081",
        "parent_code": "124"
    },
    "04084": {
        "name": "Chiềng On",
        "type": "xa",
        "slug": "chieng-on",
        "name_with_type": "Xã Chiềng On",
        "path": "Chiềng On, Yên Châu, Sơn La",
        "path_with_type": "Xã Chiềng On, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04084",
        "parent_code": "124"
    },
    "04087": {
        "name": "Yên Sơn",
        "type": "xa",
        "slug": "yen-son",
        "name_with_type": "Xã Yên Sơn",
        "path": "Yên Sơn, Yên Châu, Sơn La",
        "path_with_type": "Xã Yên Sơn, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04087",
        "parent_code": "124"
    },
    "04090": {
        "name": "Chiềng Khoi",
        "type": "xa",
        "slug": "chieng-khoi",
        "name_with_type": "Xã Chiềng Khoi",
        "path": "Chiềng Khoi, Yên Châu, Sơn La",
        "path_with_type": "Xã Chiềng Khoi, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04090",
        "parent_code": "124"
    },
    "04093": {
        "name": "Tú Nang",
        "type": "xa",
        "slug": "tu-nang",
        "name_with_type": "Xã Tú Nang",
        "path": "Tú Nang, Yên Châu, Sơn La",
        "path_with_type": "Xã Tú Nang, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04093",
        "parent_code": "124"
    },
    "04096": {
        "name": "Lóng Phiêng",
        "type": "xa",
        "slug": "long-phieng",
        "name_with_type": "Xã Lóng Phiêng",
        "path": "Lóng Phiêng, Yên Châu, Sơn La",
        "path_with_type": "Xã Lóng Phiêng, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04096",
        "parent_code": "124"
    },
    "04099": {
        "name": "Phiêng Khoài",
        "type": "xa",
        "slug": "phieng-khoai",
        "name_with_type": "Xã Phiêng Khoài",
        "path": "Phiêng Khoài, Yên Châu, Sơn La",
        "path_with_type": "Xã Phiêng Khoài, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04099",
        "parent_code": "124"
    },
    "04102": {
        "name": "Chiềng Tương",
        "type": "xa",
        "slug": "chieng-tuong",
        "name_with_type": "Xã Chiềng Tương",
        "path": "Chiềng Tương, Yên Châu, Sơn La",
        "path_with_type": "Xã Chiềng Tương, Huyện Yên Châu, Tỉnh Sơn La",
        "code": "04102",
        "parent_code": "124"
    },
    "04105": {
        "name": "Hát Lót",
        "type": "thi-tran",
        "slug": "hat-lot",
        "name_with_type": "Thị trấn Hát Lót",
        "path": "Hát Lót, Mai Sơn, Sơn La",
        "path_with_type": "Thị trấn Hát Lót, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04105",
        "parent_code": "125"
    },
    "04108": {
        "name": "Chiềng Sung",
        "type": "xa",
        "slug": "chieng-sung",
        "name_with_type": "Xã Chiềng Sung",
        "path": "Chiềng Sung, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Sung, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04108",
        "parent_code": "125"
    },
    "04111": {
        "name": "Mường Bằng",
        "type": "xa",
        "slug": "muong-bang",
        "name_with_type": "Xã Mường Bằng",
        "path": "Mường Bằng, Mai Sơn, Sơn La",
        "path_with_type": "Xã Mường Bằng, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04111",
        "parent_code": "125"
    },
    "04114": {
        "name": "Chiềng Chăn",
        "type": "xa",
        "slug": "chieng-chan",
        "name_with_type": "Xã Chiềng Chăn",
        "path": "Chiềng Chăn, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Chăn, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04114",
        "parent_code": "125"
    },
    "04117": {
        "name": "Mương Tranh",
        "type": "xa",
        "slug": "muong-tranh",
        "name_with_type": "Xã Mương Tranh",
        "path": "Mương Tranh, Mai Sơn, Sơn La",
        "path_with_type": "Xã Mương Tranh, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04117",
        "parent_code": "125"
    },
    "04120": {
        "name": "Chiềng Ban",
        "type": "xa",
        "slug": "chieng-ban",
        "name_with_type": "Xã Chiềng Ban",
        "path": "Chiềng Ban, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Ban, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04120",
        "parent_code": "125"
    },
    "04123": {
        "name": "Chiềng Mung",
        "type": "xa",
        "slug": "chieng-mung",
        "name_with_type": "Xã Chiềng Mung",
        "path": "Chiềng Mung, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Mung, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04123",
        "parent_code": "125"
    },
    "04126": {
        "name": "Mường Bon",
        "type": "xa",
        "slug": "muong-bon",
        "name_with_type": "Xã Mường Bon",
        "path": "Mường Bon, Mai Sơn, Sơn La",
        "path_with_type": "Xã Mường Bon, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04126",
        "parent_code": "125"
    },
    "04129": {
        "name": "Chiềng Chung",
        "type": "xa",
        "slug": "chieng-chung",
        "name_with_type": "Xã Chiềng Chung",
        "path": "Chiềng Chung, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Chung, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04129",
        "parent_code": "125"
    },
    "04132": {
        "name": "Chiềng Mai",
        "type": "xa",
        "slug": "chieng-mai",
        "name_with_type": "Xã Chiềng Mai",
        "path": "Chiềng Mai, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Mai, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04132",
        "parent_code": "125"
    },
    "04135": {
        "name": "Hát Lót",
        "type": "xa",
        "slug": "hat-lot",
        "name_with_type": "Xã Hát Lót",
        "path": "Hát Lót, Mai Sơn, Sơn La",
        "path_with_type": "Xã Hát Lót, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04135",
        "parent_code": "125"
    },
    "04136": {
        "name": "Nà Pó",
        "type": "xa",
        "slug": "na-po",
        "name_with_type": "Xã Nà Pó",
        "path": "Nà Pó, Mai Sơn, Sơn La",
        "path_with_type": "Xã Nà Pó, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04136",
        "parent_code": "125"
    },
    "04138": {
        "name": "Cò  Nòi",
        "type": "xa",
        "slug": "co-noi",
        "name_with_type": "Xã Cò  Nòi",
        "path": "Cò  Nòi, Mai Sơn, Sơn La",
        "path_with_type": "Xã Cò  Nòi, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04138",
        "parent_code": "125"
    },
    "04141": {
        "name": "Chiềng Nơi",
        "type": "xa",
        "slug": "chieng-noi",
        "name_with_type": "Xã Chiềng Nơi",
        "path": "Chiềng Nơi, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Nơi, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04141",
        "parent_code": "125"
    },
    "04144": {
        "name": "Phiêng Cằm",
        "type": "xa",
        "slug": "phieng-cam",
        "name_with_type": "Xã Phiêng Cằm",
        "path": "Phiêng Cằm, Mai Sơn, Sơn La",
        "path_with_type": "Xã Phiêng Cằm, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04144",
        "parent_code": "125"
    },
    "04147": {
        "name": "Chiềng Dong",
        "type": "xa",
        "slug": "chieng-dong",
        "name_with_type": "Xã Chiềng Dong",
        "path": "Chiềng Dong, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Dong, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04147",
        "parent_code": "125"
    },
    "04150": {
        "name": "Chiềng Kheo",
        "type": "xa",
        "slug": "chieng-kheo",
        "name_with_type": "Xã Chiềng Kheo",
        "path": "Chiềng Kheo, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Kheo, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04150",
        "parent_code": "125"
    },
    "04153": {
        "name": "Chiềng Ve",
        "type": "xa",
        "slug": "chieng-ve",
        "name_with_type": "Xã Chiềng Ve",
        "path": "Chiềng Ve, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Ve, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04153",
        "parent_code": "125"
    },
    "04156": {
        "name": "Chiềng Lương",
        "type": "xa",
        "slug": "chieng-luong",
        "name_with_type": "Xã Chiềng Lương",
        "path": "Chiềng Lương, Mai Sơn, Sơn La",
        "path_with_type": "Xã Chiềng Lương, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04156",
        "parent_code": "125"
    },
    "04159": {
        "name": "Phiêng Pằn",
        "type": "xa",
        "slug": "phieng-pan",
        "name_with_type": "Xã Phiêng Pằn",
        "path": "Phiêng Pằn, Mai Sơn, Sơn La",
        "path_with_type": "Xã Phiêng Pằn, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04159",
        "parent_code": "125"
    },
    "04162": {
        "name": "Nà Ơt",
        "type": "xa",
        "slug": "na-ot",
        "name_with_type": "Xã Nà Ơt",
        "path": "Nà Ơt, Mai Sơn, Sơn La",
        "path_with_type": "Xã Nà Ơt, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04162",
        "parent_code": "125"
    },
    "04165": {
        "name": "Tà Hộc",
        "type": "xa",
        "slug": "ta-hoc",
        "name_with_type": "Xã Tà Hộc",
        "path": "Tà Hộc, Mai Sơn, Sơn La",
        "path_with_type": "Xã Tà Hộc, Huyện Mai Sơn, Tỉnh Sơn La",
        "code": "04165",
        "parent_code": "125"
    },
    "04168": {
        "name": "Sông Mã",
        "type": "thi-tran",
        "slug": "song-ma",
        "name_with_type": "Thị trấn Sông Mã",
        "path": "Sông Mã, Sông Mã, Sơn La",
        "path_with_type": "Thị trấn Sông Mã, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04168",
        "parent_code": "126"
    },
    "04171": {
        "name": "Bó Sinh",
        "type": "xa",
        "slug": "bo-sinh",
        "name_with_type": "Xã Bó Sinh",
        "path": "Bó Sinh, Sông Mã, Sơn La",
        "path_with_type": "Xã Bó Sinh, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04171",
        "parent_code": "126"
    },
    "04174": {
        "name": "Pú Pẩu",
        "type": "xa",
        "slug": "pu-pau",
        "name_with_type": "Xã Pú Pẩu",
        "path": "Pú Pẩu, Sông Mã, Sơn La",
        "path_with_type": "Xã Pú Pẩu, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04174",
        "parent_code": "126"
    },
    "04177": {
        "name": "Chiềng Phung",
        "type": "xa",
        "slug": "chieng-phung",
        "name_with_type": "Xã Chiềng Phung",
        "path": "Chiềng Phung, Sông Mã, Sơn La",
        "path_with_type": "Xã Chiềng Phung, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04177",
        "parent_code": "126"
    },
    "04180": {
        "name": "Chiềng En",
        "type": "xa",
        "slug": "chieng-en",
        "name_with_type": "Xã Chiềng En",
        "path": "Chiềng En, Sông Mã, Sơn La",
        "path_with_type": "Xã Chiềng En, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04180",
        "parent_code": "126"
    },
    "04183": {
        "name": "Mường Lầm",
        "type": "xa",
        "slug": "muong-lam",
        "name_with_type": "Xã Mường Lầm",
        "path": "Mường Lầm, Sông Mã, Sơn La",
        "path_with_type": "Xã Mường Lầm, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04183",
        "parent_code": "126"
    },
    "04186": {
        "name": "Nậm Ty",
        "type": "xa",
        "slug": "nam-ty",
        "name_with_type": "Xã Nậm Ty",
        "path": "Nậm Ty, Sông Mã, Sơn La",
        "path_with_type": "Xã Nậm Ty, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04186",
        "parent_code": "126"
    },
    "04189": {
        "name": "Đứa Mòn",
        "type": "xa",
        "slug": "dua-mon",
        "name_with_type": "Xã Đứa Mòn",
        "path": "Đứa Mòn, Sông Mã, Sơn La",
        "path_with_type": "Xã Đứa Mòn, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04189",
        "parent_code": "126"
    },
    "04192": {
        "name": "Yên Hưng",
        "type": "xa",
        "slug": "yen-hung",
        "name_with_type": "Xã Yên Hưng",
        "path": "Yên Hưng, Sông Mã, Sơn La",
        "path_with_type": "Xã Yên Hưng, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04192",
        "parent_code": "126"
    },
    "04195": {
        "name": "Chiềng Sơ",
        "type": "xa",
        "slug": "chieng-so",
        "name_with_type": "Xã Chiềng Sơ",
        "path": "Chiềng Sơ, Sông Mã, Sơn La",
        "path_with_type": "Xã Chiềng Sơ, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04195",
        "parent_code": "126"
    },
    "04198": {
        "name": "Nà Ngựu",
        "type": "xa",
        "slug": "na-nguu",
        "name_with_type": "Xã Nà Ngựu",
        "path": "Nà Ngựu, Sông Mã, Sơn La",
        "path_with_type": "Xã Nà Ngựu, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04198",
        "parent_code": "126"
    },
    "04201": {
        "name": "Nậm Mằn",
        "type": "xa",
        "slug": "nam-man",
        "name_with_type": "Xã Nậm Mằn",
        "path": "Nậm Mằn, Sông Mã, Sơn La",
        "path_with_type": "Xã Nậm Mằn, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04201",
        "parent_code": "126"
    },
    "04204": {
        "name": "Chiềng Khoong",
        "type": "xa",
        "slug": "chieng-khoong",
        "name_with_type": "Xã Chiềng Khoong",
        "path": "Chiềng Khoong, Sông Mã, Sơn La",
        "path_with_type": "Xã Chiềng Khoong, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04204",
        "parent_code": "126"
    },
    "04207": {
        "name": "Chiềng Cang",
        "type": "xa",
        "slug": "chieng-cang",
        "name_with_type": "Xã Chiềng Cang",
        "path": "Chiềng Cang, Sông Mã, Sơn La",
        "path_with_type": "Xã Chiềng Cang, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04207",
        "parent_code": "126"
    },
    "04210": {
        "name": "Huổi Một",
        "type": "xa",
        "slug": "huoi-mot",
        "name_with_type": "Xã Huổi Một",
        "path": "Huổi Một, Sông Mã, Sơn La",
        "path_with_type": "Xã Huổi Một, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04210",
        "parent_code": "126"
    },
    "04213": {
        "name": "Mường Sai",
        "type": "xa",
        "slug": "muong-sai",
        "name_with_type": "Xã Mường Sai",
        "path": "Mường Sai, Sông Mã, Sơn La",
        "path_with_type": "Xã Mường Sai, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04213",
        "parent_code": "126"
    },
    "04216": {
        "name": "Mường Cai",
        "type": "xa",
        "slug": "muong-cai",
        "name_with_type": "Xã Mường Cai",
        "path": "Mường Cai, Sông Mã, Sơn La",
        "path_with_type": "Xã Mường Cai, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04216",
        "parent_code": "126"
    },
    "04219": {
        "name": "Mường Hung",
        "type": "xa",
        "slug": "muong-hung",
        "name_with_type": "Xã Mường Hung",
        "path": "Mường Hung, Sông Mã, Sơn La",
        "path_with_type": "Xã Mường Hung, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04219",
        "parent_code": "126"
    },
    "04222": {
        "name": "Chiềng Khương",
        "type": "xa",
        "slug": "chieng-khuong",
        "name_with_type": "Xã Chiềng Khương",
        "path": "Chiềng Khương, Sông Mã, Sơn La",
        "path_with_type": "Xã Chiềng Khương, Huyện Sông Mã, Tỉnh Sơn La",
        "code": "04222",
        "parent_code": "126"
    },
    "04225": {
        "name": "Sam Kha",
        "type": "xa",
        "slug": "sam-kha",
        "name_with_type": "Xã Sam Kha",
        "path": "Sam Kha, Sốp Cộp, Sơn La",
        "path_with_type": "Xã Sam Kha, Huyện Sốp Cộp, Tỉnh Sơn La",
        "code": "04225",
        "parent_code": "127"
    },
    "04228": {
        "name": "Púng Bánh",
        "type": "xa",
        "slug": "pung-banh",
        "name_with_type": "Xã Púng Bánh",
        "path": "Púng Bánh, Sốp Cộp, Sơn La",
        "path_with_type": "Xã Púng Bánh, Huyện Sốp Cộp, Tỉnh Sơn La",
        "code": "04228",
        "parent_code": "127"
    },
    "04231": {
        "name": "Xốp Cộp",
        "type": "xa",
        "slug": "xop-cop",
        "name_with_type": "Xã Xốp Cộp",
        "path": "Xốp Cộp, Sốp Cộp, Sơn La",
        "path_with_type": "Xã Xốp Cộp, Huyện Sốp Cộp, Tỉnh Sơn La",
        "code": "04231",
        "parent_code": "127"
    },
    "04234": {
        "name": "Dồm Cang",
        "type": "xa",
        "slug": "dom-cang",
        "name_with_type": "Xã Dồm Cang",
        "path": "Dồm Cang, Sốp Cộp, Sơn La",
        "path_with_type": "Xã Dồm Cang, Huyện Sốp Cộp, Tỉnh Sơn La",
        "code": "04234",
        "parent_code": "127"
    },
    "04237": {
        "name": "Nậm Lạnh",
        "type": "xa",
        "slug": "nam-lanh",
        "name_with_type": "Xã Nậm Lạnh",
        "path": "Nậm Lạnh, Sốp Cộp, Sơn La",
        "path_with_type": "Xã Nậm Lạnh, Huyện Sốp Cộp, Tỉnh Sơn La",
        "code": "04237",
        "parent_code": "127"
    },
    "04240": {
        "name": "Mường Lèo",
        "type": "xa",
        "slug": "muong-leo",
        "name_with_type": "Xã Mường Lèo",
        "path": "Mường Lèo, Sốp Cộp, Sơn La",
        "path_with_type": "Xã Mường Lèo, Huyện Sốp Cộp, Tỉnh Sơn La",
        "code": "04240",
        "parent_code": "127"
    },
    "04243": {
        "name": "Mường Và",
        "type": "xa",
        "slug": "muong-va",
        "name_with_type": "Xã Mường Và",
        "path": "Mường Và, Sốp Cộp, Sơn La",
        "path_with_type": "Xã Mường Và, Huyện Sốp Cộp, Tỉnh Sơn La",
        "code": "04243",
        "parent_code": "127"
    },
    "04246": {
        "name": "Mường Lạn",
        "type": "xa",
        "slug": "muong-lan",
        "name_with_type": "Xã Mường Lạn",
        "path": "Mường Lạn, Sốp Cộp, Sơn La",
        "path_with_type": "Xã Mường Lạn, Huyện Sốp Cộp, Tỉnh Sơn La",
        "code": "04246",
        "parent_code": "127"
    },
    "03994": {
        "name": "Suối Bàng",
        "type": "xa",
        "slug": "suoi-bang",
        "name_with_type": "Xã Suối Bàng",
        "path": "Suối Bàng, Vân Hồ, Sơn La",
        "path_with_type": "Xã Suối Bàng, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "03994",
        "parent_code": "128"
    },
    "04006": {
        "name": "Song Khủa",
        "type": "xa",
        "slug": "song-khua",
        "name_with_type": "Xã Song Khủa",
        "path": "Song Khủa, Vân Hồ, Sơn La",
        "path_with_type": "Xã Song Khủa, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04006",
        "parent_code": "128"
    },
    "04009": {
        "name": "Liên Hoà",
        "type": "xa",
        "slug": "lien-hoa",
        "name_with_type": "Xã Liên Hoà",
        "path": "Liên Hoà, Vân Hồ, Sơn La",
        "path_with_type": "Xã Liên Hoà, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04009",
        "parent_code": "128"
    },
    "04018": {
        "name": "Tô Múa",
        "type": "xa",
        "slug": "to-mua",
        "name_with_type": "Xã Tô Múa",
        "path": "Tô Múa, Vân Hồ, Sơn La",
        "path_with_type": "Xã Tô Múa, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04018",
        "parent_code": "128"
    },
    "04021": {
        "name": "Mường Tè",
        "type": "xa",
        "slug": "muong-te",
        "name_with_type": "Xã Mường Tè",
        "path": "Mường Tè, Vân Hồ, Sơn La",
        "path_with_type": "Xã Mường Tè, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04021",
        "parent_code": "128"
    },
    "04036": {
        "name": "Chiềng Khoa",
        "type": "xa",
        "slug": "chieng-khoa",
        "name_with_type": "Xã Chiềng Khoa",
        "path": "Chiềng Khoa, Vân Hồ, Sơn La",
        "path_with_type": "Xã Chiềng Khoa, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04036",
        "parent_code": "128"
    },
    "04039": {
        "name": "Mường Men",
        "type": "xa",
        "slug": "muong-men",
        "name_with_type": "Xã Mường Men",
        "path": "Mường Men, Vân Hồ, Sơn La",
        "path_with_type": "Xã Mường Men, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04039",
        "parent_code": "128"
    },
    "04042": {
        "name": "Quang Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quang Minh",
        "path": "Quang Minh, Vân Hồ, Sơn La",
        "path_with_type": "Xã Quang Minh, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04042",
        "parent_code": "128"
    },
    "04048": {
        "name": "Vân Hồ",
        "type": "xa",
        "slug": "van-ho",
        "name_with_type": "Xã Vân Hồ",
        "path": "Vân Hồ, Vân Hồ, Sơn La",
        "path_with_type": "Xã Vân Hồ, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04048",
        "parent_code": "128"
    },
    "04051": {
        "name": "Lóng Luông",
        "type": "xa",
        "slug": "long-luong",
        "name_with_type": "Xã Lóng Luông",
        "path": "Lóng Luông, Vân Hồ, Sơn La",
        "path_with_type": "Xã Lóng Luông, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04051",
        "parent_code": "128"
    },
    "04054": {
        "name": "Chiềng Yên",
        "type": "xa",
        "slug": "chieng-yen",
        "name_with_type": "Xã Chiềng Yên",
        "path": "Chiềng Yên, Vân Hồ, Sơn La",
        "path_with_type": "Xã Chiềng Yên, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04054",
        "parent_code": "128"
    },
    "04056": {
        "name": "Chiềng Xuân",
        "type": "xa",
        "slug": "chieng-xuan",
        "name_with_type": "Xã Chiềng Xuân",
        "path": "Chiềng Xuân, Vân Hồ, Sơn La",
        "path_with_type": "Xã Chiềng Xuân, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04056",
        "parent_code": "128"
    },
    "04057": {
        "name": "Xuân Nha",
        "type": "xa",
        "slug": "xuan-nha",
        "name_with_type": "Xã Xuân Nha",
        "path": "Xuân Nha, Vân Hồ, Sơn La",
        "path_with_type": "Xã Xuân Nha, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04057",
        "parent_code": "128"
    },
    "04058": {
        "name": "Tân Xuân",
        "type": "xa",
        "slug": "tan-xuan",
        "name_with_type": "Xã Tân Xuân",
        "path": "Tân Xuân, Vân Hồ, Sơn La",
        "path_with_type": "Xã Tân Xuân, Huyện Vân Hồ, Tỉnh Sơn La",
        "code": "04058",
        "parent_code": "128"
    },
    "25456": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Tây Ninh, Tây Ninh",
        "path_with_type": "Phường 1, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25456",
        "parent_code": "703"
    },
    "25459": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Tây Ninh, Tây Ninh",
        "path_with_type": "Phường 3, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25459",
        "parent_code": "703"
    },
    "25462": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Tây Ninh, Tây Ninh",
        "path_with_type": "Phường 4, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25462",
        "parent_code": "703"
    },
    "25465": {
        "name": "Hiệp Ninh",
        "type": "phuong",
        "slug": "hiep-ninh",
        "name_with_type": "Phường Hiệp Ninh",
        "path": "Hiệp Ninh, Tây Ninh, Tây Ninh",
        "path_with_type": "Phường Hiệp Ninh, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25465",
        "parent_code": "703"
    },
    "25468": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Tây Ninh, Tây Ninh",
        "path_with_type": "Phường 2, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25468",
        "parent_code": "703"
    },
    "25471": {
        "name": "Thạnh Tân",
        "type": "xa",
        "slug": "thanh-tan",
        "name_with_type": "Xã Thạnh Tân",
        "path": "Thạnh Tân, Tây Ninh, Tây Ninh",
        "path_with_type": "Xã Thạnh Tân, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25471",
        "parent_code": "703"
    },
    "25474": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Tây Ninh, Tây Ninh",
        "path_with_type": "Xã Tân Bình, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25474",
        "parent_code": "703"
    },
    "25477": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Tây Ninh, Tây Ninh",
        "path_with_type": "Xã Bình Minh, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25477",
        "parent_code": "703"
    },
    "25480": {
        "name": "Ninh Sơn",
        "type": "phuong",
        "slug": "ninh-son",
        "name_with_type": "Phường Ninh Sơn",
        "path": "Ninh Sơn, Tây Ninh, Tây Ninh",
        "path_with_type": "Phường Ninh Sơn, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25480",
        "parent_code": "703"
    },
    "25483": {
        "name": "Ninh Thạnh",
        "type": "phuong",
        "slug": "ninh-thanh",
        "name_with_type": "Phường Ninh Thạnh",
        "path": "Ninh Thạnh, Tây Ninh, Tây Ninh",
        "path_with_type": "Phường Ninh Thạnh, Thành phố Tây Ninh, Tỉnh Tây Ninh",
        "code": "25483",
        "parent_code": "703"
    },
    "25486": {
        "name": "Tân Biên",
        "type": "thi-tran",
        "slug": "tan-bien",
        "name_with_type": "Thị trấn Tân Biên",
        "path": "Tân Biên, Tân Biên, Tây Ninh",
        "path_with_type": "Thị trấn Tân Biên, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25486",
        "parent_code": "705"
    },
    "25489": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Tân Biên, Tây Ninh",
        "path_with_type": "Xã Tân Lập, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25489",
        "parent_code": "705"
    },
    "25492": {
        "name": "Thạnh Bắc",
        "type": "xa",
        "slug": "thanh-bac",
        "name_with_type": "Xã Thạnh Bắc",
        "path": "Thạnh Bắc, Tân Biên, Tây Ninh",
        "path_with_type": "Xã Thạnh Bắc, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25492",
        "parent_code": "705"
    },
    "25495": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Tân Biên, Tây Ninh",
        "path_with_type": "Xã Tân Bình, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25495",
        "parent_code": "705"
    },
    "25498": {
        "name": "Thạnh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thạnh Bình",
        "path": "Thạnh Bình, Tân Biên, Tây Ninh",
        "path_with_type": "Xã Thạnh Bình, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25498",
        "parent_code": "705"
    },
    "25501": {
        "name": "Thạnh Tây",
        "type": "xa",
        "slug": "thanh-tay",
        "name_with_type": "Xã Thạnh Tây",
        "path": "Thạnh Tây, Tân Biên, Tây Ninh",
        "path_with_type": "Xã Thạnh Tây, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25501",
        "parent_code": "705"
    },
    "25504": {
        "name": "Hòa Hiệp",
        "type": "xa",
        "slug": "hoa-hiep",
        "name_with_type": "Xã Hòa Hiệp",
        "path": "Hòa Hiệp, Tân Biên, Tây Ninh",
        "path_with_type": "Xã Hòa Hiệp, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25504",
        "parent_code": "705"
    },
    "25507": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Tân Biên, Tây Ninh",
        "path_with_type": "Xã Tân Phong, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25507",
        "parent_code": "705"
    },
    "25510": {
        "name": "Mỏ Công",
        "type": "xa",
        "slug": "mo-cong",
        "name_with_type": "Xã Mỏ Công",
        "path": "Mỏ Công, Tân Biên, Tây Ninh",
        "path_with_type": "Xã Mỏ Công, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25510",
        "parent_code": "705"
    },
    "25513": {
        "name": "Trà Vong",
        "type": "xa",
        "slug": "tra-vong",
        "name_with_type": "Xã Trà Vong",
        "path": "Trà Vong, Tân Biên, Tây Ninh",
        "path_with_type": "Xã Trà Vong, Huyện Tân Biên, Tỉnh Tây Ninh",
        "code": "25513",
        "parent_code": "705"
    },
    "25516": {
        "name": "Tân Châu",
        "type": "thi-tran",
        "slug": "tan-chau",
        "name_with_type": "Thị trấn Tân Châu",
        "path": "Tân Châu, Tân Châu, Tây Ninh",
        "path_with_type": "Thị trấn Tân Châu, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25516",
        "parent_code": "706"
    },
    "25519": {
        "name": "Tân Hà",
        "type": "xa",
        "slug": "tan-ha",
        "name_with_type": "Xã Tân Hà",
        "path": "Tân Hà, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Tân Hà, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25519",
        "parent_code": "706"
    },
    "25522": {
        "name": "Tân Đông",
        "type": "xa",
        "slug": "tan-dong",
        "name_with_type": "Xã Tân Đông",
        "path": "Tân Đông, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Tân Đông, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25522",
        "parent_code": "706"
    },
    "25525": {
        "name": "Tân Hội",
        "type": "xa",
        "slug": "tan-hoi",
        "name_with_type": "Xã Tân Hội",
        "path": "Tân Hội, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Tân Hội, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25525",
        "parent_code": "706"
    },
    "25528": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Tân Hòa, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25528",
        "parent_code": "706"
    },
    "25531": {
        "name": "Suối Ngô",
        "type": "xa",
        "slug": "suoi-ngo",
        "name_with_type": "Xã Suối Ngô",
        "path": "Suối Ngô, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Suối Ngô, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25531",
        "parent_code": "706"
    },
    "25534": {
        "name": "Suối Dây",
        "type": "xa",
        "slug": "suoi-day",
        "name_with_type": "Xã Suối Dây",
        "path": "Suối Dây, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Suối Dây, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25534",
        "parent_code": "706"
    },
    "25537": {
        "name": "Tân Hiệp",
        "type": "xa",
        "slug": "tan-hiep",
        "name_with_type": "Xã Tân Hiệp",
        "path": "Tân Hiệp, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Tân Hiệp, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25537",
        "parent_code": "706"
    },
    "25540": {
        "name": "Thạnh Đông",
        "type": "xa",
        "slug": "thanh-dong",
        "name_with_type": "Xã Thạnh Đông",
        "path": "Thạnh Đông, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Thạnh Đông, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25540",
        "parent_code": "706"
    },
    "25543": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Tân Thành, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25543",
        "parent_code": "706"
    },
    "25546": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Tân Phú, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25546",
        "parent_code": "706"
    },
    "25549": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Tân Châu, Tây Ninh",
        "path_with_type": "Xã Tân Hưng, Huyện Tân Châu, Tỉnh Tây Ninh",
        "code": "25549",
        "parent_code": "706"
    },
    "25552": {
        "name": "Dương Minh Châu",
        "type": "thi-tran",
        "slug": "duong-minh-chau",
        "name_with_type": "Thị trấn Dương Minh Châu",
        "path": "Dương Minh Châu, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Thị trấn Dương Minh Châu, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25552",
        "parent_code": "707"
    },
    "25555": {
        "name": "Suối Đá",
        "type": "xa",
        "slug": "suoi-da",
        "name_with_type": "Xã Suối Đá",
        "path": "Suối Đá, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Suối Đá, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25555",
        "parent_code": "707"
    },
    "25558": {
        "name": "Phan",
        "type": "xa",
        "slug": "phan",
        "name_with_type": "Xã Phan",
        "path": "Phan, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Phan, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25558",
        "parent_code": "707"
    },
    "25561": {
        "name": "Phước Ninh",
        "type": "xa",
        "slug": "phuoc-ninh",
        "name_with_type": "Xã Phước Ninh",
        "path": "Phước Ninh, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Phước Ninh, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25561",
        "parent_code": "707"
    },
    "25564": {
        "name": "Phước Minh",
        "type": "xa",
        "slug": "phuoc-minh",
        "name_with_type": "Xã Phước Minh",
        "path": "Phước Minh, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Phước Minh, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25564",
        "parent_code": "707"
    },
    "25567": {
        "name": "Bàu Năng",
        "type": "xa",
        "slug": "bau-nang",
        "name_with_type": "Xã Bàu Năng",
        "path": "Bàu Năng, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Bàu Năng, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25567",
        "parent_code": "707"
    },
    "25570": {
        "name": "Chà Là",
        "type": "xa",
        "slug": "cha-la",
        "name_with_type": "Xã Chà Là",
        "path": "Chà Là, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Chà Là, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25570",
        "parent_code": "707"
    },
    "25573": {
        "name": "Cầu Khởi",
        "type": "xa",
        "slug": "cau-khoi",
        "name_with_type": "Xã Cầu Khởi",
        "path": "Cầu Khởi, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Cầu Khởi, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25573",
        "parent_code": "707"
    },
    "25576": {
        "name": "Bến Củi",
        "type": "xa",
        "slug": "ben-cui",
        "name_with_type": "Xã Bến Củi",
        "path": "Bến Củi, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Bến Củi, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25576",
        "parent_code": "707"
    },
    "25579": {
        "name": "Lộc Ninh",
        "type": "xa",
        "slug": "loc-ninh",
        "name_with_type": "Xã Lộc Ninh",
        "path": "Lộc Ninh, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Lộc Ninh, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25579",
        "parent_code": "707"
    },
    "25582": {
        "name": "Truông Mít",
        "type": "xa",
        "slug": "truong-mit",
        "name_with_type": "Xã Truông Mít",
        "path": "Truông Mít, Dương Minh Châu, Tây Ninh",
        "path_with_type": "Xã Truông Mít, Huyện Dương Minh Châu, Tỉnh Tây Ninh",
        "code": "25582",
        "parent_code": "707"
    },
    "25585": {
        "name": "Châu Thành",
        "type": "thi-tran",
        "slug": "chau-thanh",
        "name_with_type": "Thị trấn Châu Thành",
        "path": "Châu Thành, Châu Thành, Tây Ninh",
        "path_with_type": "Thị trấn Châu Thành, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25585",
        "parent_code": "708"
    },
    "25588": {
        "name": "Hảo Đước",
        "type": "xa",
        "slug": "hao-duoc",
        "name_with_type": "Xã Hảo Đước",
        "path": "Hảo Đước, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Hảo Đước, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25588",
        "parent_code": "708"
    },
    "25591": {
        "name": "Phước Vinh",
        "type": "xa",
        "slug": "phuoc-vinh",
        "name_with_type": "Xã Phước Vinh",
        "path": "Phước Vinh, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Phước Vinh, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25591",
        "parent_code": "708"
    },
    "25594": {
        "name": "Đồng Khởi",
        "type": "xa",
        "slug": "dong-khoi",
        "name_with_type": "Xã Đồng Khởi",
        "path": "Đồng Khởi, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Đồng Khởi, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25594",
        "parent_code": "708"
    },
    "25597": {
        "name": "Thái Bình",
        "type": "xa",
        "slug": "thai-binh",
        "name_with_type": "Xã Thái Bình",
        "path": "Thái Bình, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Thái Bình, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25597",
        "parent_code": "708"
    },
    "25600": {
        "name": "An Cơ",
        "type": "xa",
        "slug": "an-co",
        "name_with_type": "Xã An Cơ",
        "path": "An Cơ, Châu Thành, Tây Ninh",
        "path_with_type": "Xã An Cơ, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25600",
        "parent_code": "708"
    },
    "25603": {
        "name": "Biên Giới",
        "type": "xa",
        "slug": "bien-gioi",
        "name_with_type": "Xã Biên Giới",
        "path": "Biên Giới, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Biên Giới, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25603",
        "parent_code": "708"
    },
    "25606": {
        "name": "Hòa Thạnh",
        "type": "xa",
        "slug": "hoa-thanh",
        "name_with_type": "Xã Hòa Thạnh",
        "path": "Hòa Thạnh, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Hòa Thạnh, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25606",
        "parent_code": "708"
    },
    "25609": {
        "name": "Trí Bình",
        "type": "xa",
        "slug": "tri-binh",
        "name_with_type": "Xã Trí Bình",
        "path": "Trí Bình, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Trí Bình, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25609",
        "parent_code": "708"
    },
    "25612": {
        "name": "Hòa Hội",
        "type": "xa",
        "slug": "hoa-hoi",
        "name_with_type": "Xã Hòa Hội",
        "path": "Hòa Hội, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Hòa Hội, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25612",
        "parent_code": "708"
    },
    "25615": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Châu Thành, Tây Ninh",
        "path_with_type": "Xã An Bình, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25615",
        "parent_code": "708"
    },
    "25618": {
        "name": "Thanh Điền",
        "type": "xa",
        "slug": "thanh-dien",
        "name_with_type": "Xã Thanh Điền",
        "path": "Thanh Điền, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Thanh Điền, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25618",
        "parent_code": "708"
    },
    "25621": {
        "name": "Thành Long",
        "type": "xa",
        "slug": "thanh-long",
        "name_with_type": "Xã Thành Long",
        "path": "Thành Long, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Thành Long, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25621",
        "parent_code": "708"
    },
    "25624": {
        "name": "Ninh Điền",
        "type": "xa",
        "slug": "ninh-dien",
        "name_with_type": "Xã Ninh Điền",
        "path": "Ninh Điền, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Ninh Điền, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25624",
        "parent_code": "708"
    },
    "25627": {
        "name": "Long Vĩnh",
        "type": "xa",
        "slug": "long-vinh",
        "name_with_type": "Xã Long Vĩnh",
        "path": "Long Vĩnh, Châu Thành, Tây Ninh",
        "path_with_type": "Xã Long Vĩnh, Huyện Châu Thành, Tỉnh Tây Ninh",
        "code": "25627",
        "parent_code": "708"
    },
    "25630": {
        "name": "Hòa Thành",
        "type": "thi-tran",
        "slug": "hoa-thanh",
        "name_with_type": "Thị trấn Hòa Thành",
        "path": "Hòa Thành, Hòa Thành, Tây Ninh",
        "path_with_type": "Thị trấn Hòa Thành, Huyện Hòa Thành, Tỉnh Tây Ninh",
        "code": "25630",
        "parent_code": "709"
    },
    "25633": {
        "name": "Hiệp Tân",
        "type": "xa",
        "slug": "hiep-tan",
        "name_with_type": "Xã Hiệp Tân",
        "path": "Hiệp Tân, Hòa Thành, Tây Ninh",
        "path_with_type": "Xã Hiệp Tân, Huyện Hòa Thành, Tỉnh Tây Ninh",
        "code": "25633",
        "parent_code": "709"
    },
    "25636": {
        "name": "Long Thành Bắc",
        "type": "xa",
        "slug": "long-thanh-bac",
        "name_with_type": "Xã Long Thành Bắc",
        "path": "Long Thành Bắc, Hòa Thành, Tây Ninh",
        "path_with_type": "Xã Long Thành Bắc, Huyện Hòa Thành, Tỉnh Tây Ninh",
        "code": "25636",
        "parent_code": "709"
    },
    "25639": {
        "name": "Trường Hòa",
        "type": "xa",
        "slug": "truong-hoa",
        "name_with_type": "Xã Trường Hòa",
        "path": "Trường Hòa, Hòa Thành, Tây Ninh",
        "path_with_type": "Xã Trường Hòa, Huyện Hòa Thành, Tỉnh Tây Ninh",
        "code": "25639",
        "parent_code": "709"
    },
    "25642": {
        "name": "Trường Đông",
        "type": "xa",
        "slug": "truong-dong",
        "name_with_type": "Xã Trường Đông",
        "path": "Trường Đông, Hòa Thành, Tây Ninh",
        "path_with_type": "Xã Trường Đông, Huyện Hòa Thành, Tỉnh Tây Ninh",
        "code": "25642",
        "parent_code": "709"
    },
    "25645": {
        "name": "Long Thành Trung",
        "type": "xa",
        "slug": "long-thanh-trung",
        "name_with_type": "Xã Long Thành Trung",
        "path": "Long Thành Trung, Hòa Thành, Tây Ninh",
        "path_with_type": "Xã Long Thành Trung, Huyện Hòa Thành, Tỉnh Tây Ninh",
        "code": "25645",
        "parent_code": "709"
    },
    "25648": {
        "name": "Trường Tây",
        "type": "xa",
        "slug": "truong-tay",
        "name_with_type": "Xã Trường Tây",
        "path": "Trường Tây, Hòa Thành, Tây Ninh",
        "path_with_type": "Xã Trường Tây, Huyện Hòa Thành, Tỉnh Tây Ninh",
        "code": "25648",
        "parent_code": "709"
    },
    "25651": {
        "name": "Long Thành Nam",
        "type": "xa",
        "slug": "long-thanh-nam",
        "name_with_type": "Xã Long Thành Nam",
        "path": "Long Thành Nam, Hòa Thành, Tây Ninh",
        "path_with_type": "Xã Long Thành Nam, Huyện Hòa Thành, Tỉnh Tây Ninh",
        "code": "25651",
        "parent_code": "709"
    },
    "25654": {
        "name": "Gò Dầu",
        "type": "thi-tran",
        "slug": "go-dau",
        "name_with_type": "Thị trấn Gò Dầu",
        "path": "Gò Dầu, Gò Dầu, Tây Ninh",
        "path_with_type": "Thị trấn Gò Dầu, Huyện Gò Dầu, Tỉnh Tây Ninh",
        "code": "25654",
        "parent_code": "710"
    },
    "25657": {
        "name": "Thạnh Đức",
        "type": "xa",
        "slug": "thanh-duc",
        "name_with_type": "Xã Thạnh Đức",
        "path": "Thạnh Đức, Gò Dầu, Tây Ninh",
        "path_with_type": "Xã Thạnh Đức, Huyện Gò Dầu, Tỉnh Tây Ninh",
        "code": "25657",
        "parent_code": "710"
    },
    "25660": {
        "name": "Cẩm Giang",
        "type": "xa",
        "slug": "cam-giang",
        "name_with_type": "Xã Cẩm Giang",
        "path": "Cẩm Giang, Gò Dầu, Tây Ninh",
        "path_with_type": "Xã Cẩm Giang, Huyện Gò Dầu, Tỉnh Tây Ninh",
        "code": "25660",
        "parent_code": "710"
    },
    "25663": {
        "name": "Hiệp Thạnh",
        "type": "xa",
        "slug": "hiep-thanh",
        "name_with_type": "Xã Hiệp Thạnh",
        "path": "Hiệp Thạnh, Gò Dầu, Tây Ninh",
        "path_with_type": "Xã Hiệp Thạnh, Huyện Gò Dầu, Tỉnh Tây Ninh",
        "code": "25663",
        "parent_code": "710"
    },
    "25666": {
        "name": "Bàu Đồn",
        "type": "xa",
        "slug": "bau-don",
        "name_with_type": "Xã Bàu Đồn",
        "path": "Bàu Đồn, Gò Dầu, Tây Ninh",
        "path_with_type": "Xã Bàu Đồn, Huyện Gò Dầu, Tỉnh Tây Ninh",
        "code": "25666",
        "parent_code": "710"
    },
    "25669": {
        "name": "Phước Thạnh",
        "type": "xa",
        "slug": "phuoc-thanh",
        "name_with_type": "Xã Phước Thạnh",
        "path": "Phước Thạnh, Gò Dầu, Tây Ninh",
        "path_with_type": "Xã Phước Thạnh, Huyện Gò Dầu, Tỉnh Tây Ninh",
        "code": "25669",
        "parent_code": "710"
    },
    "25672": {
        "name": "Phước Đông",
        "type": "xa",
        "slug": "phuoc-dong",
        "name_with_type": "Xã Phước Đông",
        "path": "Phước Đông, Gò Dầu, Tây Ninh",
        "path_with_type": "Xã Phước Đông, Huyện Gò Dầu, Tỉnh Tây Ninh",
        "code": "25672",
        "parent_code": "710"
    },
    "25675": {
        "name": "Phước Trạch",
        "type": "xa",
        "slug": "phuoc-trach",
        "name_with_type": "Xã Phước Trạch",
        "path": "Phước Trạch, Gò Dầu, Tây Ninh",
        "path_with_type": "Xã Phước Trạch, Huyện Gò Dầu, Tỉnh Tây Ninh",
        "code": "25675",
        "parent_code": "710"
    },
    "25678": {
        "name": "Thanh Phước",
        "type": "xa",
        "slug": "thanh-phuoc",
        "name_with_type": "Xã Thanh Phước",
        "path": "Thanh Phước, Gò Dầu, Tây Ninh",
        "path_with_type": "Xã Thanh Phước, Huyện Gò Dầu, Tỉnh Tây Ninh",
        "code": "25678",
        "parent_code": "710"
    },
    "25681": {
        "name": "Bến Cầu",
        "type": "thi-tran",
        "slug": "ben-cau",
        "name_with_type": "Thị trấn Bến Cầu",
        "path": "Bến Cầu, Bến Cầu, Tây Ninh",
        "path_with_type": "Thị trấn Bến Cầu, Huyện Bến Cầu, Tỉnh Tây Ninh",
        "code": "25681",
        "parent_code": "711"
    },
    "25684": {
        "name": "Long Chữ",
        "type": "xa",
        "slug": "long-chu",
        "name_with_type": "Xã Long Chữ",
        "path": "Long Chữ, Bến Cầu, Tây Ninh",
        "path_with_type": "Xã Long Chữ, Huyện Bến Cầu, Tỉnh Tây Ninh",
        "code": "25684",
        "parent_code": "711"
    },
    "25687": {
        "name": "Long Phước",
        "type": "xa",
        "slug": "long-phuoc",
        "name_with_type": "Xã Long Phước",
        "path": "Long Phước, Bến Cầu, Tây Ninh",
        "path_with_type": "Xã Long Phước, Huyện Bến Cầu, Tỉnh Tây Ninh",
        "code": "25687",
        "parent_code": "711"
    },
    "25690": {
        "name": "Long Giang",
        "type": "xa",
        "slug": "long-giang",
        "name_with_type": "Xã Long Giang",
        "path": "Long Giang, Bến Cầu, Tây Ninh",
        "path_with_type": "Xã Long Giang, Huyện Bến Cầu, Tỉnh Tây Ninh",
        "code": "25690",
        "parent_code": "711"
    },
    "25693": {
        "name": "Tiên Thuận",
        "type": "xa",
        "slug": "tien-thuan",
        "name_with_type": "Xã Tiên Thuận",
        "path": "Tiên Thuận, Bến Cầu, Tây Ninh",
        "path_with_type": "Xã Tiên Thuận, Huyện Bến Cầu, Tỉnh Tây Ninh",
        "code": "25693",
        "parent_code": "711"
    },
    "25696": {
        "name": "Long Khánh",
        "type": "xa",
        "slug": "long-khanh",
        "name_with_type": "Xã Long Khánh",
        "path": "Long Khánh, Bến Cầu, Tây Ninh",
        "path_with_type": "Xã Long Khánh, Huyện Bến Cầu, Tỉnh Tây Ninh",
        "code": "25696",
        "parent_code": "711"
    },
    "25699": {
        "name": "Lợi Thuận",
        "type": "xa",
        "slug": "loi-thuan",
        "name_with_type": "Xã Lợi Thuận",
        "path": "Lợi Thuận, Bến Cầu, Tây Ninh",
        "path_with_type": "Xã Lợi Thuận, Huyện Bến Cầu, Tỉnh Tây Ninh",
        "code": "25699",
        "parent_code": "711"
    },
    "25702": {
        "name": "Long Thuận",
        "type": "xa",
        "slug": "long-thuan",
        "name_with_type": "Xã Long Thuận",
        "path": "Long Thuận, Bến Cầu, Tây Ninh",
        "path_with_type": "Xã Long Thuận, Huyện Bến Cầu, Tỉnh Tây Ninh",
        "code": "25702",
        "parent_code": "711"
    },
    "25705": {
        "name": "An Thạnh",
        "type": "xa",
        "slug": "an-thanh",
        "name_with_type": "Xã An Thạnh",
        "path": "An Thạnh, Bến Cầu, Tây Ninh",
        "path_with_type": "Xã An Thạnh, Huyện Bến Cầu, Tỉnh Tây Ninh",
        "code": "25705",
        "parent_code": "711"
    },
    "25708": {
        "name": "Trảng Bàng",
        "type": "thi-tran",
        "slug": "trang-bang",
        "name_with_type": "Thị trấn Trảng Bàng",
        "path": "Trảng Bàng, Trảng Bàng, Tây Ninh",
        "path_with_type": "Thị trấn Trảng Bàng, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25708",
        "parent_code": "712"
    },
    "25711": {
        "name": "Đôn Thuận",
        "type": "xa",
        "slug": "don-thuan",
        "name_with_type": "Xã Đôn Thuận",
        "path": "Đôn Thuận, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã Đôn Thuận, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25711",
        "parent_code": "712"
    },
    "25714": {
        "name": "Hưng Thuận",
        "type": "xa",
        "slug": "hung-thuan",
        "name_with_type": "Xã Hưng Thuận",
        "path": "Hưng Thuận, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã Hưng Thuận, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25714",
        "parent_code": "712"
    },
    "25717": {
        "name": "Lộc Hưng",
        "type": "xa",
        "slug": "loc-hung",
        "name_with_type": "Xã Lộc Hưng",
        "path": "Lộc Hưng, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã Lộc Hưng, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25717",
        "parent_code": "712"
    },
    "25720": {
        "name": "Gia Lộc",
        "type": "xa",
        "slug": "gia-loc",
        "name_with_type": "Xã Gia Lộc",
        "path": "Gia Lộc, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã Gia Lộc, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25720",
        "parent_code": "712"
    },
    "25723": {
        "name": "Gia Bình",
        "type": "xa",
        "slug": "gia-binh",
        "name_with_type": "Xã Gia Bình",
        "path": "Gia Bình, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã Gia Bình, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25723",
        "parent_code": "712"
    },
    "25726": {
        "name": "Phước Lưu",
        "type": "xa",
        "slug": "phuoc-luu",
        "name_with_type": "Xã Phước Lưu",
        "path": "Phước Lưu, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã Phước Lưu, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25726",
        "parent_code": "712"
    },
    "25729": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã Bình Thạnh, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25729",
        "parent_code": "712"
    },
    "25732": {
        "name": "An Tịnh",
        "type": "xa",
        "slug": "an-tinh",
        "name_with_type": "Xã An Tịnh",
        "path": "An Tịnh, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã An Tịnh, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25732",
        "parent_code": "712"
    },
    "25735": {
        "name": "An Hòa",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hòa",
        "path": "An Hòa, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã An Hòa, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25735",
        "parent_code": "712"
    },
    "25738": {
        "name": "Phước Chỉ",
        "type": "xa",
        "slug": "phuoc-chi",
        "name_with_type": "Xã Phước Chỉ",
        "path": "Phước Chỉ, Trảng Bàng, Tây Ninh",
        "path_with_type": "Xã Phước Chỉ, Huyện Trảng Bàng, Tỉnh Tây Ninh",
        "code": "25738",
        "parent_code": "712"
    },
    "13633": {
        "name": "Hạ Long",
        "type": "phuong",
        "slug": "ha-long",
        "name_with_type": "Phường Hạ Long",
        "path": "Hạ Long, Nam Định, Nam Định",
        "path_with_type": "Phường Hạ Long, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13633",
        "parent_code": "356"
    },
    "13636": {
        "name": "Trần Tế Xương",
        "type": "phuong",
        "slug": "tran-te-xuong",
        "name_with_type": "Phường Trần Tế Xương",
        "path": "Trần Tế Xương, Nam Định, Nam Định",
        "path_with_type": "Phường Trần Tế Xương, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13636",
        "parent_code": "356"
    },
    "13639": {
        "name": "Vị Hoàng",
        "type": "phuong",
        "slug": "vi-hoang",
        "name_with_type": "Phường Vị Hoàng",
        "path": "Vị Hoàng, Nam Định, Nam Định",
        "path_with_type": "Phường Vị Hoàng, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13639",
        "parent_code": "356"
    },
    "13642": {
        "name": "Vị Xuyên",
        "type": "phuong",
        "slug": "vi-xuyen",
        "name_with_type": "Phường Vị Xuyên",
        "path": "Vị Xuyên, Nam Định, Nam Định",
        "path_with_type": "Phường Vị Xuyên, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13642",
        "parent_code": "356"
    },
    "13645": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Nam Định, Nam Định",
        "path_with_type": "Phường Quang Trung, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13645",
        "parent_code": "356"
    },
    "13648": {
        "name": "Cửa Bắc",
        "type": "phuong",
        "slug": "cua-bac",
        "name_with_type": "Phường Cửa Bắc",
        "path": "Cửa Bắc, Nam Định, Nam Định",
        "path_with_type": "Phường Cửa Bắc, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13648",
        "parent_code": "356"
    },
    "13651": {
        "name": "Nguyễn Du",
        "type": "phuong",
        "slug": "nguyen-du",
        "name_with_type": "Phường Nguyễn Du",
        "path": "Nguyễn Du, Nam Định, Nam Định",
        "path_with_type": "Phường Nguyễn Du, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13651",
        "parent_code": "356"
    },
    "13654": {
        "name": "Bà Triệu",
        "type": "phuong",
        "slug": "ba-trieu",
        "name_with_type": "Phường Bà Triệu",
        "path": "Bà Triệu, Nam Định, Nam Định",
        "path_with_type": "Phường Bà Triệu, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13654",
        "parent_code": "356"
    },
    "13657": {
        "name": "Trường Thi",
        "type": "phuong",
        "slug": "truong-thi",
        "name_with_type": "Phường Trường Thi",
        "path": "Trường Thi, Nam Định, Nam Định",
        "path_with_type": "Phường Trường Thi, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13657",
        "parent_code": "356"
    },
    "13660": {
        "name": "Phan Đình Phùng",
        "type": "phuong",
        "slug": "phan-dinh-phung",
        "name_with_type": "Phường Phan Đình Phùng",
        "path": "Phan Đình Phùng, Nam Định, Nam Định",
        "path_with_type": "Phường Phan Đình Phùng, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13660",
        "parent_code": "356"
    },
    "13663": {
        "name": "Ngô Quyền",
        "type": "phuong",
        "slug": "ngo-quyen",
        "name_with_type": "Phường Ngô Quyền",
        "path": "Ngô Quyền, Nam Định, Nam Định",
        "path_with_type": "Phường Ngô Quyền, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13663",
        "parent_code": "356"
    },
    "13666": {
        "name": "Trần Hưng Đạo",
        "type": "phuong",
        "slug": "tran-hung-dao",
        "name_with_type": "Phường Trần Hưng Đạo",
        "path": "Trần Hưng Đạo, Nam Định, Nam Định",
        "path_with_type": "Phường Trần Hưng Đạo, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13666",
        "parent_code": "356"
    },
    "13669": {
        "name": "Trần Đăng Ninh",
        "type": "phuong",
        "slug": "tran-dang-ninh",
        "name_with_type": "Phường Trần Đăng Ninh",
        "path": "Trần Đăng Ninh, Nam Định, Nam Định",
        "path_with_type": "Phường Trần Đăng Ninh, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13669",
        "parent_code": "356"
    },
    "13672": {
        "name": "Năng Tĩnh",
        "type": "phuong",
        "slug": "nang-tinh",
        "name_with_type": "Phường Năng Tĩnh",
        "path": "Năng Tĩnh, Nam Định, Nam Định",
        "path_with_type": "Phường Năng Tĩnh, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13672",
        "parent_code": "356"
    },
    "13675": {
        "name": "Văn Miếu",
        "type": "phuong",
        "slug": "van-mieu",
        "name_with_type": "Phường Văn Miếu",
        "path": "Văn Miếu, Nam Định, Nam Định",
        "path_with_type": "Phường Văn Miếu, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13675",
        "parent_code": "356"
    },
    "13678": {
        "name": "Trần Quang Khải",
        "type": "phuong",
        "slug": "tran-quang-khai",
        "name_with_type": "Phường Trần Quang Khải",
        "path": "Trần Quang Khải, Nam Định, Nam Định",
        "path_with_type": "Phường Trần Quang Khải, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13678",
        "parent_code": "356"
    },
    "13681": {
        "name": "Thống Nhất",
        "type": "phuong",
        "slug": "thong-nhat",
        "name_with_type": "Phường Thống Nhất",
        "path": "Thống Nhất, Nam Định, Nam Định",
        "path_with_type": "Phường Thống Nhất, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13681",
        "parent_code": "356"
    },
    "13684": {
        "name": "Lộc Hạ",
        "type": "phuong",
        "slug": "loc-ha",
        "name_with_type": "Phường Lộc Hạ",
        "path": "Lộc Hạ, Nam Định, Nam Định",
        "path_with_type": "Phường Lộc Hạ, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13684",
        "parent_code": "356"
    },
    "13687": {
        "name": "Lộc Vượng",
        "type": "phuong",
        "slug": "loc-vuong",
        "name_with_type": "Phường Lộc Vượng",
        "path": "Lộc Vượng, Nam Định, Nam Định",
        "path_with_type": "Phường Lộc Vượng, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13687",
        "parent_code": "356"
    },
    "13690": {
        "name": "Cửa Nam",
        "type": "phuong",
        "slug": "cua-nam",
        "name_with_type": "Phường Cửa Nam",
        "path": "Cửa Nam, Nam Định, Nam Định",
        "path_with_type": "Phường Cửa Nam, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13690",
        "parent_code": "356"
    },
    "13693": {
        "name": "Lộc Hòa",
        "type": "xa",
        "slug": "loc-hoa",
        "name_with_type": "Xã Lộc Hòa",
        "path": "Lộc Hòa, Nam Định, Nam Định",
        "path_with_type": "Xã Lộc Hòa, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13693",
        "parent_code": "356"
    },
    "13696": {
        "name": "Nam Phong",
        "type": "xa",
        "slug": "nam-phong",
        "name_with_type": "Xã Nam Phong",
        "path": "Nam Phong, Nam Định, Nam Định",
        "path_with_type": "Xã Nam Phong, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13696",
        "parent_code": "356"
    },
    "13699": {
        "name": "Mỹ Xá",
        "type": "xa",
        "slug": "my-xa",
        "name_with_type": "Xã Mỹ Xá",
        "path": "Mỹ Xá, Nam Định, Nam Định",
        "path_with_type": "Xã Mỹ Xá, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13699",
        "parent_code": "356"
    },
    "13702": {
        "name": "Lộc An",
        "type": "xa",
        "slug": "loc-an",
        "name_with_type": "Xã Lộc An",
        "path": "Lộc An, Nam Định, Nam Định",
        "path_with_type": "Xã Lộc An, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13702",
        "parent_code": "356"
    },
    "13705": {
        "name": "Nam Vân",
        "type": "xa",
        "slug": "nam-van",
        "name_with_type": "Xã Nam Vân",
        "path": "Nam Vân, Nam Định, Nam Định",
        "path_with_type": "Xã Nam Vân, Thành phố Nam Định, Tỉnh Nam Định",
        "code": "13705",
        "parent_code": "356"
    },
    "13708": {
        "name": "Mỹ Lộc",
        "type": "thi-tran",
        "slug": "my-loc",
        "name_with_type": "Thị trấn Mỹ Lộc",
        "path": "Mỹ Lộc, Mỹ Lộc, Nam Định",
        "path_with_type": "Thị trấn Mỹ Lộc, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13708",
        "parent_code": "358"
    },
    "13711": {
        "name": "Mỹ Hà",
        "type": "xa",
        "slug": "my-ha",
        "name_with_type": "Xã Mỹ Hà",
        "path": "Mỹ Hà, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Hà, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13711",
        "parent_code": "358"
    },
    "13714": {
        "name": "Mỹ Tiến",
        "type": "xa",
        "slug": "my-tien",
        "name_with_type": "Xã Mỹ Tiến",
        "path": "Mỹ Tiến, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Tiến, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13714",
        "parent_code": "358"
    },
    "13717": {
        "name": "Mỹ Thắng",
        "type": "xa",
        "slug": "my-thang",
        "name_with_type": "Xã Mỹ Thắng",
        "path": "Mỹ Thắng, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Thắng, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13717",
        "parent_code": "358"
    },
    "13720": {
        "name": "Mỹ Trung",
        "type": "xa",
        "slug": "my-trung",
        "name_with_type": "Xã Mỹ Trung",
        "path": "Mỹ Trung, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Trung, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13720",
        "parent_code": "358"
    },
    "13723": {
        "name": "Mỹ Tân",
        "type": "xa",
        "slug": "my-tan",
        "name_with_type": "Xã Mỹ Tân",
        "path": "Mỹ Tân, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Tân, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13723",
        "parent_code": "358"
    },
    "13726": {
        "name": "Mỹ Phúc",
        "type": "xa",
        "slug": "my-phuc",
        "name_with_type": "Xã Mỹ Phúc",
        "path": "Mỹ Phúc, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Phúc, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13726",
        "parent_code": "358"
    },
    "13729": {
        "name": "Mỹ Hưng",
        "type": "xa",
        "slug": "my-hung",
        "name_with_type": "Xã Mỹ Hưng",
        "path": "Mỹ Hưng, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Hưng, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13729",
        "parent_code": "358"
    },
    "13732": {
        "name": "Mỹ Thuận",
        "type": "xa",
        "slug": "my-thuan",
        "name_with_type": "Xã Mỹ Thuận",
        "path": "Mỹ Thuận, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Thuận, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13732",
        "parent_code": "358"
    },
    "13735": {
        "name": "Mỹ Thịnh",
        "type": "xa",
        "slug": "my-thinh",
        "name_with_type": "Xã Mỹ Thịnh",
        "path": "Mỹ Thịnh, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Thịnh, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13735",
        "parent_code": "358"
    },
    "13738": {
        "name": "Mỹ Thành",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thành",
        "path": "Mỹ Thành, Mỹ Lộc, Nam Định",
        "path_with_type": "Xã Mỹ Thành, Huyện Mỹ Lộc, Tỉnh Nam Định",
        "code": "13738",
        "parent_code": "358"
    },
    "13741": {
        "name": "Gôi",
        "type": "thi-tran",
        "slug": "goi",
        "name_with_type": "Thị trấn Gôi",
        "path": "Gôi, Vụ Bản, Nam Định",
        "path_with_type": "Thị trấn Gôi, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13741",
        "parent_code": "359"
    },
    "13744": {
        "name": "Minh Thuận",
        "type": "xa",
        "slug": "minh-thuan",
        "name_with_type": "Xã Minh Thuận",
        "path": "Minh Thuận, Vụ Bản, Nam Định",
        "path_with_type": "Xã Minh Thuận, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13744",
        "parent_code": "359"
    },
    "13747": {
        "name": "Hiển Khánh",
        "type": "xa",
        "slug": "hien-khanh",
        "name_with_type": "Xã Hiển Khánh",
        "path": "Hiển Khánh, Vụ Bản, Nam Định",
        "path_with_type": "Xã Hiển Khánh, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13747",
        "parent_code": "359"
    },
    "13750": {
        "name": "Tân Khánh",
        "type": "xa",
        "slug": "tan-khanh",
        "name_with_type": "Xã Tân Khánh",
        "path": "Tân Khánh, Vụ Bản, Nam Định",
        "path_with_type": "Xã Tân Khánh, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13750",
        "parent_code": "359"
    },
    "13753": {
        "name": "Hợp Hưng",
        "type": "xa",
        "slug": "hop-hung",
        "name_with_type": "Xã Hợp Hưng",
        "path": "Hợp Hưng, Vụ Bản, Nam Định",
        "path_with_type": "Xã Hợp Hưng, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13753",
        "parent_code": "359"
    },
    "13756": {
        "name": "Đại An",
        "type": "xa",
        "slug": "dai-an",
        "name_with_type": "Xã Đại An",
        "path": "Đại An, Vụ Bản, Nam Định",
        "path_with_type": "Xã Đại An, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13756",
        "parent_code": "359"
    },
    "13759": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Vụ Bản, Nam Định",
        "path_with_type": "Xã Tân Thành, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13759",
        "parent_code": "359"
    },
    "13762": {
        "name": "Cộng Hòa",
        "type": "xa",
        "slug": "cong-hoa",
        "name_with_type": "Xã Cộng Hòa",
        "path": "Cộng Hòa, Vụ Bản, Nam Định",
        "path_with_type": "Xã Cộng Hòa, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13762",
        "parent_code": "359"
    },
    "13765": {
        "name": "Trung Thành",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thành",
        "path": "Trung Thành, Vụ Bản, Nam Định",
        "path_with_type": "Xã Trung Thành, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13765",
        "parent_code": "359"
    },
    "13768": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Vụ Bản, Nam Định",
        "path_with_type": "Xã Quang Trung, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13768",
        "parent_code": "359"
    },
    "13771": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Vụ Bản, Nam Định",
        "path_with_type": "Xã Minh Tân, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13771",
        "parent_code": "359"
    },
    "13774": {
        "name": "Liên Bảo",
        "type": "xa",
        "slug": "lien-bao",
        "name_with_type": "Xã Liên Bảo",
        "path": "Liên Bảo, Vụ Bản, Nam Định",
        "path_with_type": "Xã Liên Bảo, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13774",
        "parent_code": "359"
    },
    "13777": {
        "name": "Thành Lợi",
        "type": "xa",
        "slug": "thanh-loi",
        "name_with_type": "Xã Thành Lợi",
        "path": "Thành Lợi, Vụ Bản, Nam Định",
        "path_with_type": "Xã Thành Lợi, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13777",
        "parent_code": "359"
    },
    "13780": {
        "name": "Kim Thái",
        "type": "xa",
        "slug": "kim-thai",
        "name_with_type": "Xã Kim Thái",
        "path": "Kim Thái, Vụ Bản, Nam Định",
        "path_with_type": "Xã Kim Thái, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13780",
        "parent_code": "359"
    },
    "13783": {
        "name": "Liên Minh",
        "type": "xa",
        "slug": "lien-minh",
        "name_with_type": "Xã Liên Minh",
        "path": "Liên Minh, Vụ Bản, Nam Định",
        "path_with_type": "Xã Liên Minh, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13783",
        "parent_code": "359"
    },
    "13786": {
        "name": "Đại Thắng",
        "type": "xa",
        "slug": "dai-thang",
        "name_with_type": "Xã Đại Thắng",
        "path": "Đại Thắng, Vụ Bản, Nam Định",
        "path_with_type": "Xã Đại Thắng, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13786",
        "parent_code": "359"
    },
    "13789": {
        "name": "Tam Thanh",
        "type": "xa",
        "slug": "tam-thanh",
        "name_with_type": "Xã Tam Thanh",
        "path": "Tam Thanh, Vụ Bản, Nam Định",
        "path_with_type": "Xã Tam Thanh, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13789",
        "parent_code": "359"
    },
    "13792": {
        "name": "Vĩnh Hào",
        "type": "xa",
        "slug": "vinh-hao",
        "name_with_type": "Xã Vĩnh Hào",
        "path": "Vĩnh Hào, Vụ Bản, Nam Định",
        "path_with_type": "Xã Vĩnh Hào, Huyện Vụ Bản, Tỉnh Nam Định",
        "code": "13792",
        "parent_code": "359"
    },
    "13795": {
        "name": "Lâm",
        "type": "thi-tran",
        "slug": "lam",
        "name_with_type": "Thị trấn Lâm",
        "path": "Lâm, Ý Yên, Nam Định",
        "path_with_type": "Thị trấn Lâm, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13795",
        "parent_code": "360"
    },
    "13798": {
        "name": "Yên Trung",
        "type": "xa",
        "slug": "yen-trung",
        "name_with_type": "Xã Yên Trung",
        "path": "Yên Trung, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Trung, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13798",
        "parent_code": "360"
    },
    "13801": {
        "name": "Yên Thành",
        "type": "xa",
        "slug": "yen-thanh",
        "name_with_type": "Xã Yên Thành",
        "path": "Yên Thành, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Thành, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13801",
        "parent_code": "360"
    },
    "13804": {
        "name": "Yên Tân",
        "type": "xa",
        "slug": "yen-tan",
        "name_with_type": "Xã Yên Tân",
        "path": "Yên Tân, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Tân, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13804",
        "parent_code": "360"
    },
    "13807": {
        "name": "Yên Lợi",
        "type": "xa",
        "slug": "yen-loi",
        "name_with_type": "Xã Yên Lợi",
        "path": "Yên Lợi, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Lợi, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13807",
        "parent_code": "360"
    },
    "13810": {
        "name": "Yên Thọ",
        "type": "xa",
        "slug": "yen-tho",
        "name_with_type": "Xã Yên Thọ",
        "path": "Yên Thọ, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Thọ, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13810",
        "parent_code": "360"
    },
    "13813": {
        "name": "Yên Nghĩa",
        "type": "xa",
        "slug": "yen-nghia",
        "name_with_type": "Xã Yên Nghĩa",
        "path": "Yên Nghĩa, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Nghĩa, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13813",
        "parent_code": "360"
    },
    "13816": {
        "name": "Yên Minh",
        "type": "xa",
        "slug": "yen-minh",
        "name_with_type": "Xã Yên Minh",
        "path": "Yên Minh, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Minh, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13816",
        "parent_code": "360"
    },
    "13819": {
        "name": "Yên Phương",
        "type": "xa",
        "slug": "yen-phuong",
        "name_with_type": "Xã Yên Phương",
        "path": "Yên Phương, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Phương, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13819",
        "parent_code": "360"
    },
    "13822": {
        "name": "Yên Chính",
        "type": "xa",
        "slug": "yen-chinh",
        "name_with_type": "Xã Yên Chính",
        "path": "Yên Chính, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Chính, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13822",
        "parent_code": "360"
    },
    "13825": {
        "name": "Yên Bình",
        "type": "xa",
        "slug": "yen-binh",
        "name_with_type": "Xã Yên Bình",
        "path": "Yên Bình, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Bình, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13825",
        "parent_code": "360"
    },
    "13828": {
        "name": "Yên Phú",
        "type": "xa",
        "slug": "yen-phu",
        "name_with_type": "Xã Yên Phú",
        "path": "Yên Phú, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Phú, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13828",
        "parent_code": "360"
    },
    "13831": {
        "name": "Yên Mỹ",
        "type": "xa",
        "slug": "yen-my",
        "name_with_type": "Xã Yên Mỹ",
        "path": "Yên Mỹ, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Mỹ, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13831",
        "parent_code": "360"
    },
    "13834": {
        "name": "Yên Dương",
        "type": "xa",
        "slug": "yen-duong",
        "name_with_type": "Xã Yên Dương",
        "path": "Yên Dương, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Dương, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13834",
        "parent_code": "360"
    },
    "13837": {
        "name": "Yên Xá",
        "type": "xa",
        "slug": "yen-xa",
        "name_with_type": "Xã Yên Xá",
        "path": "Yên Xá, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Xá, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13837",
        "parent_code": "360"
    },
    "13840": {
        "name": "Yên Hưng",
        "type": "xa",
        "slug": "yen-hung",
        "name_with_type": "Xã Yên Hưng",
        "path": "Yên Hưng, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Hưng, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13840",
        "parent_code": "360"
    },
    "13843": {
        "name": "Yên Khánh",
        "type": "xa",
        "slug": "yen-khanh",
        "name_with_type": "Xã Yên Khánh",
        "path": "Yên Khánh, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Khánh, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13843",
        "parent_code": "360"
    },
    "13846": {
        "name": "Yên Phong",
        "type": "xa",
        "slug": "yen-phong",
        "name_with_type": "Xã Yên Phong",
        "path": "Yên Phong, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Phong, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13846",
        "parent_code": "360"
    },
    "13849": {
        "name": "Yên Ninh",
        "type": "xa",
        "slug": "yen-ninh",
        "name_with_type": "Xã Yên Ninh",
        "path": "Yên Ninh, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Ninh, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13849",
        "parent_code": "360"
    },
    "13852": {
        "name": "Yên Lương",
        "type": "xa",
        "slug": "yen-luong",
        "name_with_type": "Xã Yên Lương",
        "path": "Yên Lương, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Lương, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13852",
        "parent_code": "360"
    },
    "13855": {
        "name": "Yên Hồng",
        "type": "xa",
        "slug": "yen-hong",
        "name_with_type": "Xã Yên Hồng",
        "path": "Yên Hồng, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Hồng, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13855",
        "parent_code": "360"
    },
    "13858": {
        "name": "Yên Quang",
        "type": "xa",
        "slug": "yen-quang",
        "name_with_type": "Xã Yên Quang",
        "path": "Yên Quang, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Quang, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13858",
        "parent_code": "360"
    },
    "13861": {
        "name": "Yên Tiến",
        "type": "xa",
        "slug": "yen-tien",
        "name_with_type": "Xã Yên Tiến",
        "path": "Yên Tiến, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Tiến, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13861",
        "parent_code": "360"
    },
    "13864": {
        "name": "Yên Thắng",
        "type": "xa",
        "slug": "yen-thang",
        "name_with_type": "Xã Yên Thắng",
        "path": "Yên Thắng, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Thắng, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13864",
        "parent_code": "360"
    },
    "13867": {
        "name": "Yên Phúc",
        "type": "xa",
        "slug": "yen-phuc",
        "name_with_type": "Xã Yên Phúc",
        "path": "Yên Phúc, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Phúc, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13867",
        "parent_code": "360"
    },
    "13870": {
        "name": "Yên Cường",
        "type": "xa",
        "slug": "yen-cuong",
        "name_with_type": "Xã Yên Cường",
        "path": "Yên Cường, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Cường, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13870",
        "parent_code": "360"
    },
    "13873": {
        "name": "Yên Lộc",
        "type": "xa",
        "slug": "yen-loc",
        "name_with_type": "Xã Yên Lộc",
        "path": "Yên Lộc, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Lộc, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13873",
        "parent_code": "360"
    },
    "13876": {
        "name": "Yên Bằng",
        "type": "xa",
        "slug": "yen-bang",
        "name_with_type": "Xã Yên Bằng",
        "path": "Yên Bằng, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Bằng, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13876",
        "parent_code": "360"
    },
    "13879": {
        "name": "Yên Đồng",
        "type": "xa",
        "slug": "yen-dong",
        "name_with_type": "Xã Yên Đồng",
        "path": "Yên Đồng, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Đồng, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13879",
        "parent_code": "360"
    },
    "13882": {
        "name": "Yên Khang",
        "type": "xa",
        "slug": "yen-khang",
        "name_with_type": "Xã Yên Khang",
        "path": "Yên Khang, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Khang, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13882",
        "parent_code": "360"
    },
    "13885": {
        "name": "Yên Nhân",
        "type": "xa",
        "slug": "yen-nhan",
        "name_with_type": "Xã Yên Nhân",
        "path": "Yên Nhân, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Nhân, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13885",
        "parent_code": "360"
    },
    "13888": {
        "name": "Yên Trị",
        "type": "xa",
        "slug": "yen-tri",
        "name_with_type": "Xã Yên Trị",
        "path": "Yên Trị, Ý Yên, Nam Định",
        "path_with_type": "Xã Yên Trị, Huyện Ý Yên, Tỉnh Nam Định",
        "code": "13888",
        "parent_code": "360"
    },
    "13891": {
        "name": "Liễu Đề",
        "type": "thi-tran",
        "slug": "lieu-de",
        "name_with_type": "Thị trấn Liễu Đề",
        "path": "Liễu Đề, Nghĩa Hưng, Nam Định",
        "path_with_type": "Thị trấn Liễu Đề, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13891",
        "parent_code": "361"
    },
    "13894": {
        "name": "Rạng Đông",
        "type": "thi-tran",
        "slug": "rang-dong",
        "name_with_type": "Thị trấn Rạng Đông",
        "path": "Rạng Đông, Nghĩa Hưng, Nam Định",
        "path_with_type": "Thị trấn Rạng Đông, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13894",
        "parent_code": "361"
    },
    "13897": {
        "name": "Nghĩa Đồng",
        "type": "xa",
        "slug": "nghia-dong",
        "name_with_type": "Xã Nghĩa Đồng",
        "path": "Nghĩa Đồng, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Đồng, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13897",
        "parent_code": "361"
    },
    "13900": {
        "name": "Nghĩa Thịnh",
        "type": "xa",
        "slug": "nghia-thinh",
        "name_with_type": "Xã Nghĩa Thịnh",
        "path": "Nghĩa Thịnh, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Thịnh, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13900",
        "parent_code": "361"
    },
    "13903": {
        "name": "Nghĩa Minh",
        "type": "xa",
        "slug": "nghia-minh",
        "name_with_type": "Xã Nghĩa Minh",
        "path": "Nghĩa Minh, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Minh, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13903",
        "parent_code": "361"
    },
    "13906": {
        "name": "Nghĩa Thái",
        "type": "xa",
        "slug": "nghia-thai",
        "name_with_type": "Xã Nghĩa Thái",
        "path": "Nghĩa Thái, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Thái, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13906",
        "parent_code": "361"
    },
    "13909": {
        "name": "Hoàng Nam",
        "type": "xa",
        "slug": "hoang-nam",
        "name_with_type": "Xã Hoàng Nam",
        "path": "Hoàng Nam, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Hoàng Nam, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13909",
        "parent_code": "361"
    },
    "13912": {
        "name": "Nghĩa Châu",
        "type": "xa",
        "slug": "nghia-chau",
        "name_with_type": "Xã Nghĩa Châu",
        "path": "Nghĩa Châu, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Châu, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13912",
        "parent_code": "361"
    },
    "13915": {
        "name": "Nghĩa Trung",
        "type": "xa",
        "slug": "nghia-trung",
        "name_with_type": "Xã Nghĩa Trung",
        "path": "Nghĩa Trung, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Trung, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13915",
        "parent_code": "361"
    },
    "13918": {
        "name": "Nghĩa Sơn",
        "type": "xa",
        "slug": "nghia-son",
        "name_with_type": "Xã Nghĩa Sơn",
        "path": "Nghĩa Sơn, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Sơn, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13918",
        "parent_code": "361"
    },
    "13921": {
        "name": "Nghĩa Lạc",
        "type": "xa",
        "slug": "nghia-lac",
        "name_with_type": "Xã Nghĩa Lạc",
        "path": "Nghĩa Lạc, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Lạc, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13921",
        "parent_code": "361"
    },
    "13924": {
        "name": "Nghĩa Hồng",
        "type": "xa",
        "slug": "nghia-hong",
        "name_with_type": "Xã Nghĩa Hồng",
        "path": "Nghĩa Hồng, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Hồng, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13924",
        "parent_code": "361"
    },
    "13927": {
        "name": "Nghĩa Phong",
        "type": "xa",
        "slug": "nghia-phong",
        "name_with_type": "Xã Nghĩa Phong",
        "path": "Nghĩa Phong, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Phong, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13927",
        "parent_code": "361"
    },
    "13930": {
        "name": "Nghĩa Phú",
        "type": "xa",
        "slug": "nghia-phu",
        "name_with_type": "Xã Nghĩa Phú",
        "path": "Nghĩa Phú, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Phú, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13930",
        "parent_code": "361"
    },
    "13933": {
        "name": "Nghĩa Bình",
        "type": "xa",
        "slug": "nghia-binh",
        "name_with_type": "Xã Nghĩa Bình",
        "path": "Nghĩa Bình, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Bình, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13933",
        "parent_code": "361"
    },
    "13936": {
        "name": "Quỹ Nhất",
        "type": "thi-tran",
        "slug": "quy-nhat",
        "name_with_type": "Thị trấn Quỹ Nhất",
        "path": "Quỹ Nhất, Nghĩa Hưng, Nam Định",
        "path_with_type": "Thị trấn Quỹ Nhất, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13936",
        "parent_code": "361"
    },
    "13939": {
        "name": "Nghĩa Tân",
        "type": "xa",
        "slug": "nghia-tan",
        "name_with_type": "Xã Nghĩa Tân",
        "path": "Nghĩa Tân, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Tân, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13939",
        "parent_code": "361"
    },
    "13942": {
        "name": "Nghĩa Hùng",
        "type": "xa",
        "slug": "nghia-hung",
        "name_with_type": "Xã Nghĩa Hùng",
        "path": "Nghĩa Hùng, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Hùng, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13942",
        "parent_code": "361"
    },
    "13945": {
        "name": "Nghĩa Lâm",
        "type": "xa",
        "slug": "nghia-lam",
        "name_with_type": "Xã Nghĩa Lâm",
        "path": "Nghĩa Lâm, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Lâm, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13945",
        "parent_code": "361"
    },
    "13948": {
        "name": "Nghĩa Thành",
        "type": "xa",
        "slug": "nghia-thanh",
        "name_with_type": "Xã Nghĩa Thành",
        "path": "Nghĩa Thành, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Thành, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13948",
        "parent_code": "361"
    },
    "13951": {
        "name": "Nghĩa Thắng",
        "type": "xa",
        "slug": "nghia-thang",
        "name_with_type": "Xã Nghĩa Thắng",
        "path": "Nghĩa Thắng, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Thắng, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13951",
        "parent_code": "361"
    },
    "13954": {
        "name": "Nghĩa Lợi",
        "type": "xa",
        "slug": "nghia-loi",
        "name_with_type": "Xã Nghĩa Lợi",
        "path": "Nghĩa Lợi, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Lợi, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13954",
        "parent_code": "361"
    },
    "13957": {
        "name": "Nghĩa Hải",
        "type": "xa",
        "slug": "nghia-hai",
        "name_with_type": "Xã Nghĩa Hải",
        "path": "Nghĩa Hải, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Hải, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13957",
        "parent_code": "361"
    },
    "13960": {
        "name": "Nghĩa Phúc",
        "type": "xa",
        "slug": "nghia-phuc",
        "name_with_type": "Xã Nghĩa Phúc",
        "path": "Nghĩa Phúc, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nghĩa Phúc, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13960",
        "parent_code": "361"
    },
    "13963": {
        "name": "Nam Điền",
        "type": "xa",
        "slug": "nam-dien",
        "name_with_type": "Xã Nam Điền",
        "path": "Nam Điền, Nghĩa Hưng, Nam Định",
        "path_with_type": "Xã Nam Điền, Huyện Nghĩa Hưng, Tỉnh Nam Định",
        "code": "13963",
        "parent_code": "361"
    },
    "13966": {
        "name": "Nam Giang",
        "type": "thi-tran",
        "slug": "nam-giang",
        "name_with_type": "Thị trấn Nam Giang",
        "path": "Nam Giang, Nam Trực, Nam Định",
        "path_with_type": "Thị trấn Nam Giang, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13966",
        "parent_code": "362"
    },
    "13969": {
        "name": "Nam Mỹ",
        "type": "xa",
        "slug": "nam-my",
        "name_with_type": "Xã Nam Mỹ",
        "path": "Nam Mỹ, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Mỹ, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13969",
        "parent_code": "362"
    },
    "13972": {
        "name": "Điền Xá",
        "type": "xa",
        "slug": "dien-xa",
        "name_with_type": "Xã Điền Xá",
        "path": "Điền Xá, Nam Trực, Nam Định",
        "path_with_type": "Xã Điền Xá, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13972",
        "parent_code": "362"
    },
    "13975": {
        "name": "Nghĩa An",
        "type": "xa",
        "slug": "nghia-an",
        "name_with_type": "Xã Nghĩa An",
        "path": "Nghĩa An, Nam Trực, Nam Định",
        "path_with_type": "Xã Nghĩa An, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13975",
        "parent_code": "362"
    },
    "13978": {
        "name": "Nam Thắng",
        "type": "xa",
        "slug": "nam-thang",
        "name_with_type": "Xã Nam Thắng",
        "path": "Nam Thắng, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Thắng, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13978",
        "parent_code": "362"
    },
    "13981": {
        "name": "Nam Toàn",
        "type": "xa",
        "slug": "nam-toan",
        "name_with_type": "Xã Nam Toàn",
        "path": "Nam Toàn, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Toàn, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13981",
        "parent_code": "362"
    },
    "13984": {
        "name": "Hồng Quang",
        "type": "xa",
        "slug": "hong-quang",
        "name_with_type": "Xã Hồng Quang",
        "path": "Hồng Quang, Nam Trực, Nam Định",
        "path_with_type": "Xã Hồng Quang, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13984",
        "parent_code": "362"
    },
    "13987": {
        "name": "Tân Thịnh",
        "type": "xa",
        "slug": "tan-thinh",
        "name_with_type": "Xã Tân Thịnh",
        "path": "Tân Thịnh, Nam Trực, Nam Định",
        "path_with_type": "Xã Tân Thịnh, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13987",
        "parent_code": "362"
    },
    "13990": {
        "name": "Nam Cường",
        "type": "xa",
        "slug": "nam-cuong",
        "name_with_type": "Xã Nam Cường",
        "path": "Nam Cường, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Cường, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13990",
        "parent_code": "362"
    },
    "13993": {
        "name": "Nam Hồng",
        "type": "xa",
        "slug": "nam-hong",
        "name_with_type": "Xã Nam Hồng",
        "path": "Nam Hồng, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Hồng, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13993",
        "parent_code": "362"
    },
    "13996": {
        "name": "Nam Hùng",
        "type": "xa",
        "slug": "nam-hung",
        "name_with_type": "Xã Nam Hùng",
        "path": "Nam Hùng, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Hùng, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13996",
        "parent_code": "362"
    },
    "13999": {
        "name": "Nam Hoa",
        "type": "xa",
        "slug": "nam-hoa",
        "name_with_type": "Xã Nam Hoa",
        "path": "Nam Hoa, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Hoa, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "13999",
        "parent_code": "362"
    },
    "14002": {
        "name": "Nam Dương",
        "type": "xa",
        "slug": "nam-duong",
        "name_with_type": "Xã Nam Dương",
        "path": "Nam Dương, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Dương, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "14002",
        "parent_code": "362"
    },
    "14005": {
        "name": "Nam Thanh",
        "type": "xa",
        "slug": "nam-thanh",
        "name_with_type": "Xã Nam Thanh",
        "path": "Nam Thanh, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Thanh, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "14005",
        "parent_code": "362"
    },
    "14008": {
        "name": "Nam Lợi",
        "type": "xa",
        "slug": "nam-loi",
        "name_with_type": "Xã Nam Lợi",
        "path": "Nam Lợi, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Lợi, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "14008",
        "parent_code": "362"
    },
    "14011": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Nam Trực, Nam Định",
        "path_with_type": "Xã Bình Minh, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "14011",
        "parent_code": "362"
    },
    "14014": {
        "name": "Đồng Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đồng Sơn",
        "path": "Đồng Sơn, Nam Trực, Nam Định",
        "path_with_type": "Xã Đồng Sơn, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "14014",
        "parent_code": "362"
    },
    "14017": {
        "name": "Nam Tiến",
        "type": "xa",
        "slug": "nam-tien",
        "name_with_type": "Xã Nam Tiến",
        "path": "Nam Tiến, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Tiến, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "14017",
        "parent_code": "362"
    },
    "14020": {
        "name": "Nam Hải",
        "type": "xa",
        "slug": "nam-hai",
        "name_with_type": "Xã Nam Hải",
        "path": "Nam Hải, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Hải, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "14020",
        "parent_code": "362"
    },
    "14023": {
        "name": "Nam Thái",
        "type": "xa",
        "slug": "nam-thai",
        "name_with_type": "Xã Nam Thái",
        "path": "Nam Thái, Nam Trực, Nam Định",
        "path_with_type": "Xã Nam Thái, Huyện Nam Trực, Tỉnh Nam Định",
        "code": "14023",
        "parent_code": "362"
    },
    "14026": {
        "name": "Cổ Lễ",
        "type": "thi-tran",
        "slug": "co-le",
        "name_with_type": "Thị trấn Cổ Lễ",
        "path": "Cổ Lễ, Trực Ninh, Nam Định",
        "path_with_type": "Thị trấn Cổ Lễ, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14026",
        "parent_code": "363"
    },
    "14029": {
        "name": "Phương Định",
        "type": "xa",
        "slug": "phuong-dinh",
        "name_with_type": "Xã Phương Định",
        "path": "Phương Định, Trực Ninh, Nam Định",
        "path_with_type": "Xã Phương Định, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14029",
        "parent_code": "363"
    },
    "14032": {
        "name": "Trực Chính",
        "type": "xa",
        "slug": "truc-chinh",
        "name_with_type": "Xã Trực Chính",
        "path": "Trực Chính, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Chính, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14032",
        "parent_code": "363"
    },
    "14035": {
        "name": "Trung Đông",
        "type": "xa",
        "slug": "trung-dong",
        "name_with_type": "Xã Trung Đông",
        "path": "Trung Đông, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trung Đông, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14035",
        "parent_code": "363"
    },
    "14038": {
        "name": "Liêm Hải",
        "type": "xa",
        "slug": "liem-hai",
        "name_with_type": "Xã Liêm Hải",
        "path": "Liêm Hải, Trực Ninh, Nam Định",
        "path_with_type": "Xã Liêm Hải, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14038",
        "parent_code": "363"
    },
    "14041": {
        "name": "Trực Tuấn",
        "type": "xa",
        "slug": "truc-tuan",
        "name_with_type": "Xã Trực Tuấn",
        "path": "Trực Tuấn, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Tuấn, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14041",
        "parent_code": "363"
    },
    "14044": {
        "name": "Việt Hùng",
        "type": "xa",
        "slug": "viet-hung",
        "name_with_type": "Xã Việt Hùng",
        "path": "Việt Hùng, Trực Ninh, Nam Định",
        "path_with_type": "Xã Việt Hùng, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14044",
        "parent_code": "363"
    },
    "14047": {
        "name": "Trực Đạo",
        "type": "xa",
        "slug": "truc-dao",
        "name_with_type": "Xã Trực Đạo",
        "path": "Trực Đạo, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Đạo, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14047",
        "parent_code": "363"
    },
    "14050": {
        "name": "Trực Hưng",
        "type": "xa",
        "slug": "truc-hung",
        "name_with_type": "Xã Trực Hưng",
        "path": "Trực Hưng, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Hưng, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14050",
        "parent_code": "363"
    },
    "14053": {
        "name": "Trực Nội",
        "type": "xa",
        "slug": "truc-noi",
        "name_with_type": "Xã Trực Nội",
        "path": "Trực Nội, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Nội, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14053",
        "parent_code": "363"
    },
    "14056": {
        "name": "Cát Thành",
        "type": "thi-tran",
        "slug": "cat-thanh",
        "name_with_type": "Thị trấn Cát Thành",
        "path": "Cát Thành, Trực Ninh, Nam Định",
        "path_with_type": "Thị trấn Cát Thành, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14056",
        "parent_code": "363"
    },
    "14059": {
        "name": "Trực Thanh",
        "type": "xa",
        "slug": "truc-thanh",
        "name_with_type": "Xã Trực Thanh",
        "path": "Trực Thanh, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Thanh, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14059",
        "parent_code": "363"
    },
    "14062": {
        "name": "Trực Khang",
        "type": "xa",
        "slug": "truc-khang",
        "name_with_type": "Xã Trực Khang",
        "path": "Trực Khang, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Khang, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14062",
        "parent_code": "363"
    },
    "14065": {
        "name": "Trực Thuận",
        "type": "xa",
        "slug": "truc-thuan",
        "name_with_type": "Xã Trực Thuận",
        "path": "Trực Thuận, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Thuận, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14065",
        "parent_code": "363"
    },
    "14068": {
        "name": "Trực Mỹ",
        "type": "xa",
        "slug": "truc-my",
        "name_with_type": "Xã Trực Mỹ",
        "path": "Trực Mỹ, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Mỹ, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14068",
        "parent_code": "363"
    },
    "14071": {
        "name": "Trực Đại",
        "type": "xa",
        "slug": "truc-dai",
        "name_with_type": "Xã Trực Đại",
        "path": "Trực Đại, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Đại, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14071",
        "parent_code": "363"
    },
    "14074": {
        "name": "Trực Cường",
        "type": "xa",
        "slug": "truc-cuong",
        "name_with_type": "Xã Trực Cường",
        "path": "Trực Cường, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Cường, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14074",
        "parent_code": "363"
    },
    "14077": {
        "name": "Trực Phú",
        "type": "xa",
        "slug": "truc-phu",
        "name_with_type": "Xã Trực Phú",
        "path": "Trực Phú, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Phú, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14077",
        "parent_code": "363"
    },
    "14080": {
        "name": "Trực Thái",
        "type": "xa",
        "slug": "truc-thai",
        "name_with_type": "Xã Trực Thái",
        "path": "Trực Thái, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Thái, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14080",
        "parent_code": "363"
    },
    "14083": {
        "name": "Trực Hùng",
        "type": "xa",
        "slug": "truc-hung",
        "name_with_type": "Xã Trực Hùng",
        "path": "Trực Hùng, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Hùng, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14083",
        "parent_code": "363"
    },
    "14086": {
        "name": "Trực Thắng",
        "type": "xa",
        "slug": "truc-thang",
        "name_with_type": "Xã Trực Thắng",
        "path": "Trực Thắng, Trực Ninh, Nam Định",
        "path_with_type": "Xã Trực Thắng, Huyện Trực Ninh, Tỉnh Nam Định",
        "code": "14086",
        "parent_code": "363"
    },
    "14089": {
        "name": "Xuân Trường",
        "type": "thi-tran",
        "slug": "xuan-truong",
        "name_with_type": "Thị trấn Xuân Trường",
        "path": "Xuân Trường, Xuân Trường, Nam Định",
        "path_with_type": "Thị trấn Xuân Trường, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14089",
        "parent_code": "364"
    },
    "14092": {
        "name": "Xuân Châu",
        "type": "xa",
        "slug": "xuan-chau",
        "name_with_type": "Xã Xuân Châu",
        "path": "Xuân Châu, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Châu, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14092",
        "parent_code": "364"
    },
    "14095": {
        "name": "Xuân Hồng",
        "type": "xa",
        "slug": "xuan-hong",
        "name_with_type": "Xã Xuân Hồng",
        "path": "Xuân Hồng, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Hồng, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14095",
        "parent_code": "364"
    },
    "14098": {
        "name": "Xuân Thành",
        "type": "xa",
        "slug": "xuan-thanh",
        "name_with_type": "Xã Xuân Thành",
        "path": "Xuân Thành, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Thành, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14098",
        "parent_code": "364"
    },
    "14101": {
        "name": "Xuân Thượng",
        "type": "xa",
        "slug": "xuan-thuong",
        "name_with_type": "Xã Xuân Thượng",
        "path": "Xuân Thượng, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Thượng, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14101",
        "parent_code": "364"
    },
    "14104": {
        "name": "Xuân Phong",
        "type": "xa",
        "slug": "xuan-phong",
        "name_with_type": "Xã Xuân Phong",
        "path": "Xuân Phong, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Phong, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14104",
        "parent_code": "364"
    },
    "14107": {
        "name": "Xuân Đài",
        "type": "xa",
        "slug": "xuan-dai",
        "name_with_type": "Xã Xuân Đài",
        "path": "Xuân Đài, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Đài, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14107",
        "parent_code": "364"
    },
    "14110": {
        "name": "Xuân Tân",
        "type": "xa",
        "slug": "xuan-tan",
        "name_with_type": "Xã Xuân Tân",
        "path": "Xuân Tân, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Tân, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14110",
        "parent_code": "364"
    },
    "14113": {
        "name": "Xuân Thủy",
        "type": "xa",
        "slug": "xuan-thuy",
        "name_with_type": "Xã Xuân Thủy",
        "path": "Xuân Thủy, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Thủy, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14113",
        "parent_code": "364"
    },
    "14116": {
        "name": "Xuân Ngọc",
        "type": "xa",
        "slug": "xuan-ngoc",
        "name_with_type": "Xã Xuân Ngọc",
        "path": "Xuân Ngọc, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Ngọc, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14116",
        "parent_code": "364"
    },
    "14119": {
        "name": "Xuân Bắc",
        "type": "xa",
        "slug": "xuan-bac",
        "name_with_type": "Xã Xuân Bắc",
        "path": "Xuân Bắc, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Bắc, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14119",
        "parent_code": "364"
    },
    "14122": {
        "name": "Xuân Phương",
        "type": "xa",
        "slug": "xuan-phuong",
        "name_with_type": "Xã Xuân Phương",
        "path": "Xuân Phương, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Phương, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14122",
        "parent_code": "364"
    },
    "14125": {
        "name": "Thọ Nghiệp",
        "type": "xa",
        "slug": "tho-nghiep",
        "name_with_type": "Xã Thọ Nghiệp",
        "path": "Thọ Nghiệp, Xuân Trường, Nam Định",
        "path_with_type": "Xã Thọ Nghiệp, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14125",
        "parent_code": "364"
    },
    "14128": {
        "name": "Xuân Phú",
        "type": "xa",
        "slug": "xuan-phu",
        "name_with_type": "Xã Xuân Phú",
        "path": "Xuân Phú, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Phú, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14128",
        "parent_code": "364"
    },
    "14131": {
        "name": "Xuân Trung",
        "type": "xa",
        "slug": "xuan-trung",
        "name_with_type": "Xã Xuân Trung",
        "path": "Xuân Trung, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Trung, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14131",
        "parent_code": "364"
    },
    "14134": {
        "name": "Xuân Vinh",
        "type": "xa",
        "slug": "xuan-vinh",
        "name_with_type": "Xã Xuân Vinh",
        "path": "Xuân Vinh, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Vinh, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14134",
        "parent_code": "364"
    },
    "14137": {
        "name": "Xuân Kiên",
        "type": "xa",
        "slug": "xuan-kien",
        "name_with_type": "Xã Xuân Kiên",
        "path": "Xuân Kiên, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Kiên, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14137",
        "parent_code": "364"
    },
    "14140": {
        "name": "Xuân Tiến",
        "type": "xa",
        "slug": "xuan-tien",
        "name_with_type": "Xã Xuân Tiến",
        "path": "Xuân Tiến, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Tiến, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14140",
        "parent_code": "364"
    },
    "14143": {
        "name": "Xuân Ninh",
        "type": "xa",
        "slug": "xuan-ninh",
        "name_with_type": "Xã Xuân Ninh",
        "path": "Xuân Ninh, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Ninh, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14143",
        "parent_code": "364"
    },
    "14146": {
        "name": "Xuân Hòa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hòa",
        "path": "Xuân Hòa, Xuân Trường, Nam Định",
        "path_with_type": "Xã Xuân Hòa, Huyện Xuân Trường, Tỉnh Nam Định",
        "code": "14146",
        "parent_code": "364"
    },
    "14149": {
        "name": "Ngô Đồng",
        "type": "thi-tran",
        "slug": "ngo-dong",
        "name_with_type": "Thị trấn Ngô Đồng",
        "path": "Ngô Đồng, Giao Thủy, Nam Định",
        "path_with_type": "Thị trấn Ngô Đồng, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14149",
        "parent_code": "365"
    },
    "14152": {
        "name": "Quất Lâm",
        "type": "thi-tran",
        "slug": "quat-lam",
        "name_with_type": "Thị trấn Quất Lâm",
        "path": "Quất Lâm, Giao Thủy, Nam Định",
        "path_with_type": "Thị trấn Quất Lâm, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14152",
        "parent_code": "365"
    },
    "14155": {
        "name": "Giao Hương",
        "type": "xa",
        "slug": "giao-huong",
        "name_with_type": "Xã Giao Hương",
        "path": "Giao Hương, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Hương, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14155",
        "parent_code": "365"
    },
    "14158": {
        "name": "Hồng Thuận",
        "type": "xa",
        "slug": "hong-thuan",
        "name_with_type": "Xã Hồng Thuận",
        "path": "Hồng Thuận, Giao Thủy, Nam Định",
        "path_with_type": "Xã Hồng Thuận, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14158",
        "parent_code": "365"
    },
    "14161": {
        "name": "Giao Thiện",
        "type": "xa",
        "slug": "giao-thien",
        "name_with_type": "Xã Giao Thiện",
        "path": "Giao Thiện, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Thiện, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14161",
        "parent_code": "365"
    },
    "14164": {
        "name": "Giao Thanh",
        "type": "xa",
        "slug": "giao-thanh",
        "name_with_type": "Xã Giao Thanh",
        "path": "Giao Thanh, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Thanh, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14164",
        "parent_code": "365"
    },
    "14167": {
        "name": "Hoành Sơn",
        "type": "xa",
        "slug": "hoanh-son",
        "name_with_type": "Xã Hoành Sơn",
        "path": "Hoành Sơn, Giao Thủy, Nam Định",
        "path_with_type": "Xã Hoành Sơn, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14167",
        "parent_code": "365"
    },
    "14170": {
        "name": "Bình Hòa",
        "type": "xa",
        "slug": "binh-hoa",
        "name_with_type": "Xã Bình Hòa",
        "path": "Bình Hòa, Giao Thủy, Nam Định",
        "path_with_type": "Xã Bình Hòa, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14170",
        "parent_code": "365"
    },
    "14173": {
        "name": "Giao Tiến",
        "type": "xa",
        "slug": "giao-tien",
        "name_with_type": "Xã Giao Tiến",
        "path": "Giao Tiến, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Tiến, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14173",
        "parent_code": "365"
    },
    "14176": {
        "name": "Giao Hà",
        "type": "xa",
        "slug": "giao-ha",
        "name_with_type": "Xã Giao Hà",
        "path": "Giao Hà, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Hà, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14176",
        "parent_code": "365"
    },
    "14179": {
        "name": "Giao Nhân",
        "type": "xa",
        "slug": "giao-nhan",
        "name_with_type": "Xã Giao Nhân",
        "path": "Giao Nhân, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Nhân, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14179",
        "parent_code": "365"
    },
    "14182": {
        "name": "Giao An",
        "type": "xa",
        "slug": "giao-an",
        "name_with_type": "Xã Giao An",
        "path": "Giao An, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao An, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14182",
        "parent_code": "365"
    },
    "14185": {
        "name": "Giao Lạc",
        "type": "xa",
        "slug": "giao-lac",
        "name_with_type": "Xã Giao Lạc",
        "path": "Giao Lạc, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Lạc, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14185",
        "parent_code": "365"
    },
    "14188": {
        "name": "Giao Châu",
        "type": "xa",
        "slug": "giao-chau",
        "name_with_type": "Xã Giao Châu",
        "path": "Giao Châu, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Châu, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14188",
        "parent_code": "365"
    },
    "14191": {
        "name": "Giao Tân",
        "type": "xa",
        "slug": "giao-tan",
        "name_with_type": "Xã Giao Tân",
        "path": "Giao Tân, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Tân, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14191",
        "parent_code": "365"
    },
    "14194": {
        "name": "Giao Yến",
        "type": "xa",
        "slug": "giao-yen",
        "name_with_type": "Xã Giao Yến",
        "path": "Giao Yến, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Yến, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14194",
        "parent_code": "365"
    },
    "14197": {
        "name": "Giao Xuân",
        "type": "xa",
        "slug": "giao-xuan",
        "name_with_type": "Xã Giao Xuân",
        "path": "Giao Xuân, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Xuân, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14197",
        "parent_code": "365"
    },
    "14200": {
        "name": "Giao Thịnh",
        "type": "xa",
        "slug": "giao-thinh",
        "name_with_type": "Xã Giao Thịnh",
        "path": "Giao Thịnh, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Thịnh, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14200",
        "parent_code": "365"
    },
    "14203": {
        "name": "Giao Hải",
        "type": "xa",
        "slug": "giao-hai",
        "name_with_type": "Xã Giao Hải",
        "path": "Giao Hải, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Hải, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14203",
        "parent_code": "365"
    },
    "14206": {
        "name": "Bạch Long",
        "type": "xa",
        "slug": "bach-long",
        "name_with_type": "Xã Bạch Long",
        "path": "Bạch Long, Giao Thủy, Nam Định",
        "path_with_type": "Xã Bạch Long, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14206",
        "parent_code": "365"
    },
    "14209": {
        "name": "Giao Long",
        "type": "xa",
        "slug": "giao-long",
        "name_with_type": "Xã Giao Long",
        "path": "Giao Long, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Long, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14209",
        "parent_code": "365"
    },
    "14212": {
        "name": "Giao Phong",
        "type": "xa",
        "slug": "giao-phong",
        "name_with_type": "Xã Giao Phong",
        "path": "Giao Phong, Giao Thủy, Nam Định",
        "path_with_type": "Xã Giao Phong, Huyện Giao Thủy, Tỉnh Nam Định",
        "code": "14212",
        "parent_code": "365"
    },
    "14215": {
        "name": "Yên Định",
        "type": "thi-tran",
        "slug": "yen-dinh",
        "name_with_type": "Thị trấn Yên Định",
        "path": "Yên Định, Hải Hậu, Nam Định",
        "path_with_type": "Thị trấn Yên Định, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14215",
        "parent_code": "366"
    },
    "14218": {
        "name": "Cồn",
        "type": "thi-tran",
        "slug": "con",
        "name_with_type": "Thị trấn Cồn",
        "path": "Cồn, Hải Hậu, Nam Định",
        "path_with_type": "Thị trấn Cồn, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14218",
        "parent_code": "366"
    },
    "14221": {
        "name": "Thịnh Long",
        "type": "thi-tran",
        "slug": "thinh-long",
        "name_with_type": "Thị trấn Thịnh Long",
        "path": "Thịnh Long, Hải Hậu, Nam Định",
        "path_with_type": "Thị trấn Thịnh Long, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14221",
        "parent_code": "366"
    },
    "14224": {
        "name": "Hải Nam",
        "type": "xa",
        "slug": "hai-nam",
        "name_with_type": "Xã Hải Nam",
        "path": "Hải Nam, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Nam, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14224",
        "parent_code": "366"
    },
    "14227": {
        "name": "Hải Trung",
        "type": "xa",
        "slug": "hai-trung",
        "name_with_type": "Xã Hải Trung",
        "path": "Hải Trung, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Trung, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14227",
        "parent_code": "366"
    },
    "14230": {
        "name": "Hải Vân",
        "type": "xa",
        "slug": "hai-van",
        "name_with_type": "Xã Hải Vân",
        "path": "Hải Vân, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Vân, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14230",
        "parent_code": "366"
    },
    "14233": {
        "name": "Hải Minh",
        "type": "xa",
        "slug": "hai-minh",
        "name_with_type": "Xã Hải Minh",
        "path": "Hải Minh, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Minh, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14233",
        "parent_code": "366"
    },
    "14236": {
        "name": "Hải Anh",
        "type": "xa",
        "slug": "hai-anh",
        "name_with_type": "Xã Hải Anh",
        "path": "Hải Anh, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Anh, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14236",
        "parent_code": "366"
    },
    "14239": {
        "name": "Hải Hưng",
        "type": "xa",
        "slug": "hai-hung",
        "name_with_type": "Xã Hải Hưng",
        "path": "Hải Hưng, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Hưng, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14239",
        "parent_code": "366"
    },
    "14242": {
        "name": "Hải Bắc",
        "type": "xa",
        "slug": "hai-bac",
        "name_with_type": "Xã Hải Bắc",
        "path": "Hải Bắc, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Bắc, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14242",
        "parent_code": "366"
    },
    "14245": {
        "name": "Hải Phúc",
        "type": "xa",
        "slug": "hai-phuc",
        "name_with_type": "Xã Hải Phúc",
        "path": "Hải Phúc, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Phúc, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14245",
        "parent_code": "366"
    },
    "14248": {
        "name": "Hải Thanh",
        "type": "xa",
        "slug": "hai-thanh",
        "name_with_type": "Xã Hải Thanh",
        "path": "Hải Thanh, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Thanh, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14248",
        "parent_code": "366"
    },
    "14251": {
        "name": "Hải Hà",
        "type": "xa",
        "slug": "hai-ha",
        "name_with_type": "Xã Hải Hà",
        "path": "Hải Hà, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Hà, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14251",
        "parent_code": "366"
    },
    "14254": {
        "name": "Hải Long",
        "type": "xa",
        "slug": "hai-long",
        "name_with_type": "Xã Hải Long",
        "path": "Hải Long, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Long, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14254",
        "parent_code": "366"
    },
    "14257": {
        "name": "Hải Phương",
        "type": "xa",
        "slug": "hai-phuong",
        "name_with_type": "Xã Hải Phương",
        "path": "Hải Phương, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Phương, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14257",
        "parent_code": "366"
    },
    "14260": {
        "name": "Hải Đường",
        "type": "xa",
        "slug": "hai-duong",
        "name_with_type": "Xã Hải Đường",
        "path": "Hải Đường, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Đường, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14260",
        "parent_code": "366"
    },
    "14263": {
        "name": "Hải Lộc",
        "type": "xa",
        "slug": "hai-loc",
        "name_with_type": "Xã Hải Lộc",
        "path": "Hải Lộc, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Lộc, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14263",
        "parent_code": "366"
    },
    "14266": {
        "name": "Hải Quang",
        "type": "xa",
        "slug": "hai-quang",
        "name_with_type": "Xã Hải Quang",
        "path": "Hải Quang, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Quang, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14266",
        "parent_code": "366"
    },
    "14269": {
        "name": "Hải Đông",
        "type": "xa",
        "slug": "hai-dong",
        "name_with_type": "Xã Hải Đông",
        "path": "Hải Đông, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Đông, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14269",
        "parent_code": "366"
    },
    "14272": {
        "name": "Hải Sơn",
        "type": "xa",
        "slug": "hai-son",
        "name_with_type": "Xã Hải Sơn",
        "path": "Hải Sơn, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Sơn, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14272",
        "parent_code": "366"
    },
    "14275": {
        "name": "Hải Tân",
        "type": "xa",
        "slug": "hai-tan",
        "name_with_type": "Xã Hải Tân",
        "path": "Hải Tân, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Tân, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14275",
        "parent_code": "366"
    },
    "14278": {
        "name": "Hải Toàn",
        "type": "xa",
        "slug": "hai-toan",
        "name_with_type": "Xã Hải Toàn",
        "path": "Hải Toàn, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Toàn, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14278",
        "parent_code": "366"
    },
    "14281": {
        "name": "Hải Phong",
        "type": "xa",
        "slug": "hai-phong",
        "name_with_type": "Xã Hải Phong",
        "path": "Hải Phong, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Phong, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14281",
        "parent_code": "366"
    },
    "14284": {
        "name": "Hải An",
        "type": "xa",
        "slug": "hai-an",
        "name_with_type": "Xã Hải An",
        "path": "Hải An, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải An, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14284",
        "parent_code": "366"
    },
    "14287": {
        "name": "Hải Tây",
        "type": "xa",
        "slug": "hai-tay",
        "name_with_type": "Xã Hải Tây",
        "path": "Hải Tây, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Tây, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14287",
        "parent_code": "366"
    },
    "14290": {
        "name": "Hải Lý",
        "type": "xa",
        "slug": "hai-ly",
        "name_with_type": "Xã Hải Lý",
        "path": "Hải Lý, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Lý, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14290",
        "parent_code": "366"
    },
    "14293": {
        "name": "Hải Phú",
        "type": "xa",
        "slug": "hai-phu",
        "name_with_type": "Xã Hải Phú",
        "path": "Hải Phú, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Phú, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14293",
        "parent_code": "366"
    },
    "14296": {
        "name": "Hải Giang",
        "type": "xa",
        "slug": "hai-giang",
        "name_with_type": "Xã Hải Giang",
        "path": "Hải Giang, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Giang, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14296",
        "parent_code": "366"
    },
    "14299": {
        "name": "Hải Cường",
        "type": "xa",
        "slug": "hai-cuong",
        "name_with_type": "Xã Hải Cường",
        "path": "Hải Cường, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Cường, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14299",
        "parent_code": "366"
    },
    "14302": {
        "name": "Hải Ninh",
        "type": "xa",
        "slug": "hai-ninh",
        "name_with_type": "Xã Hải Ninh",
        "path": "Hải Ninh, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Ninh, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14302",
        "parent_code": "366"
    },
    "14305": {
        "name": "Hải Chính",
        "type": "xa",
        "slug": "hai-chinh",
        "name_with_type": "Xã Hải Chính",
        "path": "Hải Chính, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Chính, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14305",
        "parent_code": "366"
    },
    "14308": {
        "name": "Hải Xuân",
        "type": "xa",
        "slug": "hai-xuan",
        "name_with_type": "Xã Hải Xuân",
        "path": "Hải Xuân, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Xuân, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14308",
        "parent_code": "366"
    },
    "14311": {
        "name": "Hải Châu",
        "type": "xa",
        "slug": "hai-chau",
        "name_with_type": "Xã Hải Châu",
        "path": "Hải Châu, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Châu, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14311",
        "parent_code": "366"
    },
    "14314": {
        "name": "Hải Triều",
        "type": "xa",
        "slug": "hai-trieu",
        "name_with_type": "Xã Hải Triều",
        "path": "Hải Triều, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Triều, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14314",
        "parent_code": "366"
    },
    "14317": {
        "name": "Hải Hòa",
        "type": "xa",
        "slug": "hai-hoa",
        "name_with_type": "Xã Hải Hòa",
        "path": "Hải Hòa, Hải Hậu, Nam Định",
        "path_with_type": "Xã Hải Hòa, Huyện Hải Hậu, Tỉnh Nam Định",
        "code": "14317",
        "parent_code": "366"
    },
    "03386": {
        "name": "Quyết Thắng",
        "type": "phuong",
        "slug": "quyet-thang",
        "name_with_type": "Phường Quyết Thắng",
        "path": "Quyết Thắng, Lai Châu, Lai Châu",
        "path_with_type": "Phường Quyết Thắng, Thành phố Lai Châu, Tỉnh Lai Châu",
        "code": "03386",
        "parent_code": "105"
    },
    "03387": {
        "name": "Tân Phong",
        "type": "phuong",
        "slug": "tan-phong",
        "name_with_type": "Phường Tân Phong",
        "path": "Tân Phong, Lai Châu, Lai Châu",
        "path_with_type": "Phường Tân Phong, Thành phố Lai Châu, Tỉnh Lai Châu",
        "code": "03387",
        "parent_code": "105"
    },
    "03388": {
        "name": "Quyết Tiến",
        "type": "phuong",
        "slug": "quyet-tien",
        "name_with_type": "Phường Quyết Tiến",
        "path": "Quyết Tiến, Lai Châu, Lai Châu",
        "path_with_type": "Phường Quyết Tiến, Thành phố Lai Châu, Tỉnh Lai Châu",
        "code": "03388",
        "parent_code": "105"
    },
    "03389": {
        "name": "Đoàn Kết",
        "type": "phuong",
        "slug": "doan-ket",
        "name_with_type": "Phường Đoàn Kết",
        "path": "Đoàn Kết, Lai Châu, Lai Châu",
        "path_with_type": "Phường Đoàn Kết, Thành phố Lai Châu, Tỉnh Lai Châu",
        "code": "03389",
        "parent_code": "105"
    },
    "03403": {
        "name": "Nậm Loỏng",
        "type": "xa",
        "slug": "nam-loong",
        "name_with_type": "Xã Nậm Loỏng",
        "path": "Nậm Loỏng, Lai Châu, Lai Châu",
        "path_with_type": "Xã Nậm Loỏng, Thành phố Lai Châu, Tỉnh Lai Châu",
        "code": "03403",
        "parent_code": "105"
    },
    "03408": {
        "name": "Đông Phong",
        "type": "phuong",
        "slug": "dong-phong",
        "name_with_type": "Phường Đông Phong",
        "path": "Đông Phong, Lai Châu, Lai Châu",
        "path_with_type": "Phường Đông Phong, Thành phố Lai Châu, Tỉnh Lai Châu",
        "code": "03408",
        "parent_code": "105"
    },
    "03409": {
        "name": "San Thàng",
        "type": "xa",
        "slug": "san-thang",
        "name_with_type": "Xã San Thàng",
        "path": "San Thàng, Lai Châu, Lai Châu",
        "path_with_type": "Xã San Thàng, Thành phố Lai Châu, Tỉnh Lai Châu",
        "code": "03409",
        "parent_code": "105"
    },
    "03390": {
        "name": "Tam Đường",
        "type": "thi-tran",
        "slug": "tam-duong",
        "name_with_type": "Thị trấn Tam Đường",
        "path": "Tam Đường, Tam Đường, Lai Châu",
        "path_with_type": "Thị trấn Tam Đường, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03390",
        "parent_code": "106"
    },
    "03394": {
        "name": "Thèn Sin",
        "type": "xa",
        "slug": "then-sin",
        "name_with_type": "Xã Thèn Sin",
        "path": "Thèn Sin, Tam Đường, Lai Châu",
        "path_with_type": "Xã Thèn Sin, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03394",
        "parent_code": "106"
    },
    "03397": {
        "name": "Sùng Phài",
        "type": "xa",
        "slug": "sung-phai",
        "name_with_type": "Xã Sùng Phài",
        "path": "Sùng Phài, Tam Đường, Lai Châu",
        "path_with_type": "Xã Sùng Phài, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03397",
        "parent_code": "106"
    },
    "03400": {
        "name": "Tả Lèng",
        "type": "xa",
        "slug": "ta-leng",
        "name_with_type": "Xã Tả Lèng",
        "path": "Tả Lèng, Tam Đường, Lai Châu",
        "path_with_type": "Xã Tả Lèng, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03400",
        "parent_code": "106"
    },
    "03405": {
        "name": "Giang Ma",
        "type": "xa",
        "slug": "giang-ma",
        "name_with_type": "Xã Giang Ma",
        "path": "Giang Ma, Tam Đường, Lai Châu",
        "path_with_type": "Xã Giang Ma, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03405",
        "parent_code": "106"
    },
    "03406": {
        "name": "Hồ Thầu",
        "type": "xa",
        "slug": "ho-thau",
        "name_with_type": "Xã Hồ Thầu",
        "path": "Hồ Thầu, Tam Đường, Lai Châu",
        "path_with_type": "Xã Hồ Thầu, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03406",
        "parent_code": "106"
    },
    "03412": {
        "name": "Bình Lư",
        "type": "xa",
        "slug": "binh-lu",
        "name_with_type": "Xã Bình Lư",
        "path": "Bình Lư, Tam Đường, Lai Châu",
        "path_with_type": "Xã Bình Lư, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03412",
        "parent_code": "106"
    },
    "03413": {
        "name": "Sơn Bình",
        "type": "xa",
        "slug": "son-binh",
        "name_with_type": "Xã Sơn Bình",
        "path": "Sơn Bình, Tam Đường, Lai Châu",
        "path_with_type": "Xã Sơn Bình, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03413",
        "parent_code": "106"
    },
    "03415": {
        "name": "Nùng Nàng",
        "type": "xa",
        "slug": "nung-nang",
        "name_with_type": "Xã Nùng Nàng",
        "path": "Nùng Nàng, Tam Đường, Lai Châu",
        "path_with_type": "Xã Nùng Nàng, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03415",
        "parent_code": "106"
    },
    "03418": {
        "name": "Bản Giang",
        "type": "xa",
        "slug": "ban-giang",
        "name_with_type": "Xã Bản Giang",
        "path": "Bản Giang, Tam Đường, Lai Châu",
        "path_with_type": "Xã Bản Giang, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03418",
        "parent_code": "106"
    },
    "03421": {
        "name": "Bản Hon",
        "type": "xa",
        "slug": "ban-hon",
        "name_with_type": "Xã Bản Hon",
        "path": "Bản Hon, Tam Đường, Lai Châu",
        "path_with_type": "Xã Bản Hon, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03421",
        "parent_code": "106"
    },
    "03424": {
        "name": "Bản Bo",
        "type": "xa",
        "slug": "ban-bo",
        "name_with_type": "Xã Bản Bo",
        "path": "Bản Bo, Tam Đường, Lai Châu",
        "path_with_type": "Xã Bản Bo, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03424",
        "parent_code": "106"
    },
    "03427": {
        "name": "Nà Tăm",
        "type": "xa",
        "slug": "na-tam",
        "name_with_type": "Xã Nà Tăm",
        "path": "Nà Tăm, Tam Đường, Lai Châu",
        "path_with_type": "Xã Nà Tăm, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03427",
        "parent_code": "106"
    },
    "03430": {
        "name": "Khun Há",
        "type": "xa",
        "slug": "khun-ha",
        "name_with_type": "Xã Khun Há",
        "path": "Khun Há, Tam Đường, Lai Châu",
        "path_with_type": "Xã Khun Há, Huyện Tam Đường, Tỉnh Lai Châu",
        "code": "03430",
        "parent_code": "106"
    },
    "03433": {
        "name": "Mường Tè",
        "type": "thi-tran",
        "slug": "muong-te",
        "name_with_type": "Thị trấn Mường Tè",
        "path": "Mường Tè, Mường Tè, Lai Châu",
        "path_with_type": "Thị trấn Mường Tè, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03433",
        "parent_code": "107"
    },
    "03436": {
        "name": "Thu Lũm",
        "type": "xa",
        "slug": "thu-lum",
        "name_with_type": "Xã Thu Lũm",
        "path": "Thu Lũm, Mường Tè, Lai Châu",
        "path_with_type": "Xã Thu Lũm, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03436",
        "parent_code": "107"
    },
    "03439": {
        "name": "Ka Lăng",
        "type": "xa",
        "slug": "ka-lang",
        "name_with_type": "Xã Ka Lăng",
        "path": "Ka Lăng, Mường Tè, Lai Châu",
        "path_with_type": "Xã Ka Lăng, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03439",
        "parent_code": "107"
    },
    "03440": {
        "name": "Tá Bạ",
        "type": "xa",
        "slug": "ta-ba",
        "name_with_type": "Xã Tá Bạ",
        "path": "Tá Bạ, Mường Tè, Lai Châu",
        "path_with_type": "Xã Tá Bạ, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03440",
        "parent_code": "107"
    },
    "03442": {
        "name": "Pa ủ",
        "type": "xa",
        "slug": "pa-u",
        "name_with_type": "Xã Pa ủ",
        "path": "Pa ủ, Mường Tè, Lai Châu",
        "path_with_type": "Xã Pa ủ, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03442",
        "parent_code": "107"
    },
    "03445": {
        "name": "Mường Tè",
        "type": "xa",
        "slug": "muong-te",
        "name_with_type": "Xã Mường Tè",
        "path": "Mường Tè, Mường Tè, Lai Châu",
        "path_with_type": "Xã Mường Tè, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03445",
        "parent_code": "107"
    },
    "03448": {
        "name": "Pa Vệ Sử",
        "type": "xa",
        "slug": "pa-ve-su",
        "name_with_type": "Xã Pa Vệ Sử",
        "path": "Pa Vệ Sử, Mường Tè, Lai Châu",
        "path_with_type": "Xã Pa Vệ Sử, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03448",
        "parent_code": "107"
    },
    "03451": {
        "name": "Mù Cả",
        "type": "xa",
        "slug": "mu-ca",
        "name_with_type": "Xã Mù Cả",
        "path": "Mù Cả, Mường Tè, Lai Châu",
        "path_with_type": "Xã Mù Cả, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03451",
        "parent_code": "107"
    },
    "03454": {
        "name": "Bun Tở",
        "type": "xa",
        "slug": "bun-to",
        "name_with_type": "Xã Bun Tở",
        "path": "Bun Tở, Mường Tè, Lai Châu",
        "path_with_type": "Xã Bun Tở, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03454",
        "parent_code": "107"
    },
    "03457": {
        "name": "Nậm Khao",
        "type": "xa",
        "slug": "nam-khao",
        "name_with_type": "Xã Nậm Khao",
        "path": "Nậm Khao, Mường Tè, Lai Châu",
        "path_with_type": "Xã Nậm Khao, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03457",
        "parent_code": "107"
    },
    "03463": {
        "name": "Tà Tổng",
        "type": "xa",
        "slug": "ta-tong",
        "name_with_type": "Xã Tà Tổng",
        "path": "Tà Tổng, Mường Tè, Lai Châu",
        "path_with_type": "Xã Tà Tổng, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03463",
        "parent_code": "107"
    },
    "03466": {
        "name": "Bun Nưa",
        "type": "xa",
        "slug": "bun-nua",
        "name_with_type": "Xã Bun Nưa",
        "path": "Bun Nưa, Mường Tè, Lai Châu",
        "path_with_type": "Xã Bun Nưa, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03466",
        "parent_code": "107"
    },
    "03467": {
        "name": "Vàng San",
        "type": "xa",
        "slug": "vang-san",
        "name_with_type": "Xã Vàng San",
        "path": "Vàng San, Mường Tè, Lai Châu",
        "path_with_type": "Xã Vàng San, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03467",
        "parent_code": "107"
    },
    "03469": {
        "name": "Kan Hồ",
        "type": "xa",
        "slug": "kan-ho",
        "name_with_type": "Xã Kan Hồ",
        "path": "Kan Hồ, Mường Tè, Lai Châu",
        "path_with_type": "Xã Kan Hồ, Huyện Mường Tè, Tỉnh Lai Châu",
        "code": "03469",
        "parent_code": "107"
    },
    "03478": {
        "name": "Sìn Hồ",
        "type": "thi-tran",
        "slug": "sin-ho",
        "name_with_type": "Thị trấn Sìn Hồ",
        "path": "Sìn Hồ, Sìn Hồ, Lai Châu",
        "path_with_type": "Thị trấn Sìn Hồ, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03478",
        "parent_code": "108"
    },
    "03487": {
        "name": "Chăn Nưa",
        "type": "xa",
        "slug": "chan-nua",
        "name_with_type": "Xã Chăn Nưa",
        "path": "Chăn Nưa, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Chăn Nưa, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03487",
        "parent_code": "108"
    },
    "03493": {
        "name": "Pa Tần",
        "type": "xa",
        "slug": "pa-tan",
        "name_with_type": "Xã Pa Tần",
        "path": "Pa Tần, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Pa Tần, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03493",
        "parent_code": "108"
    },
    "03496": {
        "name": "Phìn Hồ",
        "type": "xa",
        "slug": "phin-ho",
        "name_with_type": "Xã Phìn Hồ",
        "path": "Phìn Hồ, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Phìn Hồ, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03496",
        "parent_code": "108"
    },
    "03499": {
        "name": "Hồng Thu",
        "type": "xa",
        "slug": "hong-thu",
        "name_with_type": "Xã Hồng Thu",
        "path": "Hồng Thu, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Hồng Thu, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03499",
        "parent_code": "108"
    },
    "03505": {
        "name": "Phăng Sô Lin",
        "type": "xa",
        "slug": "phang-so-lin",
        "name_with_type": "Xã Phăng Sô Lin",
        "path": "Phăng Sô Lin, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Phăng Sô Lin, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03505",
        "parent_code": "108"
    },
    "03508": {
        "name": "Ma Quai",
        "type": "xa",
        "slug": "ma-quai",
        "name_with_type": "Xã Ma Quai",
        "path": "Ma Quai, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Ma Quai, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03508",
        "parent_code": "108"
    },
    "03509": {
        "name": "Lùng Thàng",
        "type": "xa",
        "slug": "lung-thang",
        "name_with_type": "Xã Lùng Thàng",
        "path": "Lùng Thàng, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Lùng Thàng, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03509",
        "parent_code": "108"
    },
    "03511": {
        "name": "Tả Phìn",
        "type": "xa",
        "slug": "ta-phin",
        "name_with_type": "Xã Tả Phìn",
        "path": "Tả Phìn, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Tả Phìn, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03511",
        "parent_code": "108"
    },
    "03514": {
        "name": "Sà Dề Phìn",
        "type": "xa",
        "slug": "sa-de-phin",
        "name_with_type": "Xã Sà Dề Phìn",
        "path": "Sà Dề Phìn, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Sà Dề Phìn, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03514",
        "parent_code": "108"
    },
    "03517": {
        "name": "Nậm Tăm",
        "type": "xa",
        "slug": "nam-tam",
        "name_with_type": "Xã Nậm Tăm",
        "path": "Nậm Tăm, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Nậm Tăm, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03517",
        "parent_code": "108"
    },
    "03520": {
        "name": "Tả Ngảo",
        "type": "xa",
        "slug": "ta-ngao",
        "name_with_type": "Xã Tả Ngảo",
        "path": "Tả Ngảo, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Tả Ngảo, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03520",
        "parent_code": "108"
    },
    "03523": {
        "name": "Pu Sam Cáp",
        "type": "xa",
        "slug": "pu-sam-cap",
        "name_with_type": "Xã Pu Sam Cáp",
        "path": "Pu Sam Cáp, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Pu Sam Cáp, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03523",
        "parent_code": "108"
    },
    "03526": {
        "name": "Nậm Cha",
        "type": "xa",
        "slug": "nam-cha",
        "name_with_type": "Xã Nậm Cha",
        "path": "Nậm Cha, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Nậm Cha, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03526",
        "parent_code": "108"
    },
    "03527": {
        "name": "Pa Khoá",
        "type": "xa",
        "slug": "pa-khoa",
        "name_with_type": "Xã Pa Khoá",
        "path": "Pa Khoá, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Pa Khoá, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03527",
        "parent_code": "108"
    },
    "03529": {
        "name": "Làng Mô",
        "type": "xa",
        "slug": "lang-mo",
        "name_with_type": "Xã Làng Mô",
        "path": "Làng Mô, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Làng Mô, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03529",
        "parent_code": "108"
    },
    "03532": {
        "name": "Noong Hẻo",
        "type": "xa",
        "slug": "noong-heo",
        "name_with_type": "Xã Noong Hẻo",
        "path": "Noong Hẻo, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Noong Hẻo, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03532",
        "parent_code": "108"
    },
    "03535": {
        "name": "Nậm Mạ",
        "type": "xa",
        "slug": "nam-ma",
        "name_with_type": "Xã Nậm Mạ",
        "path": "Nậm Mạ, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Nậm Mạ, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03535",
        "parent_code": "108"
    },
    "03538": {
        "name": "Căn Co",
        "type": "xa",
        "slug": "can-co",
        "name_with_type": "Xã Căn Co",
        "path": "Căn Co, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Căn Co, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03538",
        "parent_code": "108"
    },
    "03541": {
        "name": "Tủa Sín Chải",
        "type": "xa",
        "slug": "tua-sin-chai",
        "name_with_type": "Xã Tủa Sín Chải",
        "path": "Tủa Sín Chải, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Tủa Sín Chải, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03541",
        "parent_code": "108"
    },
    "03544": {
        "name": "Nậm Cuổi",
        "type": "xa",
        "slug": "nam-cuoi",
        "name_with_type": "Xã Nậm Cuổi",
        "path": "Nậm Cuổi, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Nậm Cuổi, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03544",
        "parent_code": "108"
    },
    "03547": {
        "name": "Nậm Hăn",
        "type": "xa",
        "slug": "nam-han",
        "name_with_type": "Xã Nậm Hăn",
        "path": "Nậm Hăn, Sìn Hồ, Lai Châu",
        "path_with_type": "Xã Nậm Hăn, Huyện Sìn Hồ, Tỉnh Lai Châu",
        "code": "03547",
        "parent_code": "108"
    },
    "03391": {
        "name": "Lả Nhì Thàng",
        "type": "xa",
        "slug": "la-nhi-thang",
        "name_with_type": "Xã Lả Nhì Thàng",
        "path": "Lả Nhì Thàng, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Lả Nhì Thàng, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03391",
        "parent_code": "109"
    },
    "03490": {
        "name": "Huổi Luông",
        "type": "xa",
        "slug": "huoi-luong",
        "name_with_type": "Xã Huổi Luông",
        "path": "Huổi Luông, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Huổi Luông, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03490",
        "parent_code": "109"
    },
    "03549": {
        "name": "Phong Thổ",
        "type": "thi-tran",
        "slug": "phong-tho",
        "name_with_type": "Thị trấn Phong Thổ",
        "path": "Phong Thổ, Phong Thổ, Lai Châu",
        "path_with_type": "Thị trấn Phong Thổ, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03549",
        "parent_code": "109"
    },
    "03550": {
        "name": "Sì Lờ Lầu",
        "type": "xa",
        "slug": "si-lo-lau",
        "name_with_type": "Xã Sì Lờ Lầu",
        "path": "Sì Lờ Lầu, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Sì Lờ Lầu, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03550",
        "parent_code": "109"
    },
    "03553": {
        "name": "Mồ Sì San",
        "type": "xa",
        "slug": "mo-si-san",
        "name_with_type": "Xã Mồ Sì San",
        "path": "Mồ Sì San, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Mồ Sì San, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03553",
        "parent_code": "109"
    },
    "03556": {
        "name": "Ma Li Chải",
        "type": "xa",
        "slug": "ma-li-chai",
        "name_with_type": "Xã Ma Li Chải",
        "path": "Ma Li Chải, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Ma Li Chải, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03556",
        "parent_code": "109"
    },
    "03559": {
        "name": "Pa Vây Sử",
        "type": "xa",
        "slug": "pa-vay-su",
        "name_with_type": "Xã Pa Vây Sử",
        "path": "Pa Vây Sử, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Pa Vây Sử, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03559",
        "parent_code": "109"
    },
    "03562": {
        "name": "Vàng Ma Chải",
        "type": "xa",
        "slug": "vang-ma-chai",
        "name_with_type": "Xã Vàng Ma Chải",
        "path": "Vàng Ma Chải, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Vàng Ma Chải, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03562",
        "parent_code": "109"
    },
    "03565": {
        "name": "Tông Qua Lìn",
        "type": "xa",
        "slug": "tong-qua-lin",
        "name_with_type": "Xã Tông Qua Lìn",
        "path": "Tông Qua Lìn, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Tông Qua Lìn, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03565",
        "parent_code": "109"
    },
    "03568": {
        "name": "Mù Sang",
        "type": "xa",
        "slug": "mu-sang",
        "name_with_type": "Xã Mù Sang",
        "path": "Mù Sang, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Mù Sang, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03568",
        "parent_code": "109"
    },
    "03571": {
        "name": "Dào San",
        "type": "xa",
        "slug": "dao-san",
        "name_with_type": "Xã Dào San",
        "path": "Dào San, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Dào San, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03571",
        "parent_code": "109"
    },
    "03574": {
        "name": "Ma Ly Pho",
        "type": "xa",
        "slug": "ma-ly-pho",
        "name_with_type": "Xã Ma Ly Pho",
        "path": "Ma Ly Pho, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Ma Ly Pho, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03574",
        "parent_code": "109"
    },
    "03577": {
        "name": "Bản Lang",
        "type": "xa",
        "slug": "ban-lang",
        "name_with_type": "Xã Bản Lang",
        "path": "Bản Lang, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Bản Lang, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03577",
        "parent_code": "109"
    },
    "03580": {
        "name": "Hoang Thèn",
        "type": "xa",
        "slug": "hoang-then",
        "name_with_type": "Xã Hoang Thèn",
        "path": "Hoang Thèn, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Hoang Thèn, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03580",
        "parent_code": "109"
    },
    "03583": {
        "name": "Khổng Lào",
        "type": "xa",
        "slug": "khong-lao",
        "name_with_type": "Xã Khổng Lào",
        "path": "Khổng Lào, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Khổng Lào, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03583",
        "parent_code": "109"
    },
    "03586": {
        "name": "Nậm Xe",
        "type": "xa",
        "slug": "nam-xe",
        "name_with_type": "Xã Nậm Xe",
        "path": "Nậm Xe, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Nậm Xe, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03586",
        "parent_code": "109"
    },
    "03589": {
        "name": "Mường So",
        "type": "xa",
        "slug": "muong-so",
        "name_with_type": "Xã Mường So",
        "path": "Mường So, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Mường So, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03589",
        "parent_code": "109"
    },
    "03592": {
        "name": "Sin Suối Hồ",
        "type": "xa",
        "slug": "sin-suoi-ho",
        "name_with_type": "Xã Sin Suối Hồ",
        "path": "Sin Suối Hồ, Phong Thổ, Lai Châu",
        "path_with_type": "Xã Sin Suối Hồ, Huyện Phong Thổ, Tỉnh Lai Châu",
        "code": "03592",
        "parent_code": "109"
    },
    "03595": {
        "name": "Than Uyên",
        "type": "thi-tran",
        "slug": "than-uyen",
        "name_with_type": "Thị trấn Than Uyên",
        "path": "Than Uyên, Than Uyên, Lai Châu",
        "path_with_type": "Thị trấn Than Uyên, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03595",
        "parent_code": "110"
    },
    "03618": {
        "name": "Phúc Than",
        "type": "xa",
        "slug": "phuc-than",
        "name_with_type": "Xã Phúc Than",
        "path": "Phúc Than, Than Uyên, Lai Châu",
        "path_with_type": "Xã Phúc Than, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03618",
        "parent_code": "110"
    },
    "03619": {
        "name": "Mường Than",
        "type": "xa",
        "slug": "muong-than",
        "name_with_type": "Xã Mường Than",
        "path": "Mường Than, Than Uyên, Lai Châu",
        "path_with_type": "Xã Mường Than, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03619",
        "parent_code": "110"
    },
    "03625": {
        "name": "Mường Mít",
        "type": "xa",
        "slug": "muong-mit",
        "name_with_type": "Xã Mường Mít",
        "path": "Mường Mít, Than Uyên, Lai Châu",
        "path_with_type": "Xã Mường Mít, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03625",
        "parent_code": "110"
    },
    "03628": {
        "name": "Pha Mu",
        "type": "xa",
        "slug": "pha-mu",
        "name_with_type": "Xã Pha Mu",
        "path": "Pha Mu, Than Uyên, Lai Châu",
        "path_with_type": "Xã Pha Mu, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03628",
        "parent_code": "110"
    },
    "03631": {
        "name": "Mường Cang",
        "type": "xa",
        "slug": "muong-cang",
        "name_with_type": "Xã Mường Cang",
        "path": "Mường Cang, Than Uyên, Lai Châu",
        "path_with_type": "Xã Mường Cang, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03631",
        "parent_code": "110"
    },
    "03632": {
        "name": "Hua Nà",
        "type": "xa",
        "slug": "hua-na",
        "name_with_type": "Xã Hua Nà",
        "path": "Hua Nà, Than Uyên, Lai Châu",
        "path_with_type": "Xã Hua Nà, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03632",
        "parent_code": "110"
    },
    "03634": {
        "name": "Tà Hừa",
        "type": "xa",
        "slug": "ta-hua",
        "name_with_type": "Xã Tà Hừa",
        "path": "Tà Hừa, Than Uyên, Lai Châu",
        "path_with_type": "Xã Tà Hừa, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03634",
        "parent_code": "110"
    },
    "03637": {
        "name": "Mường Kim",
        "type": "xa",
        "slug": "muong-kim",
        "name_with_type": "Xã Mường Kim",
        "path": "Mường Kim, Than Uyên, Lai Châu",
        "path_with_type": "Xã Mường Kim, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03637",
        "parent_code": "110"
    },
    "03638": {
        "name": "Tà Mung",
        "type": "xa",
        "slug": "ta-mung",
        "name_with_type": "Xã Tà Mung",
        "path": "Tà Mung, Than Uyên, Lai Châu",
        "path_with_type": "Xã Tà Mung, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03638",
        "parent_code": "110"
    },
    "03640": {
        "name": "Tà Gia",
        "type": "xa",
        "slug": "ta-gia",
        "name_with_type": "Xã Tà Gia",
        "path": "Tà Gia, Than Uyên, Lai Châu",
        "path_with_type": "Xã Tà Gia, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03640",
        "parent_code": "110"
    },
    "03643": {
        "name": "Khoen On",
        "type": "xa",
        "slug": "khoen-on",
        "name_with_type": "Xã Khoen On",
        "path": "Khoen On, Than Uyên, Lai Châu",
        "path_with_type": "Xã Khoen On, Huyện Than Uyên, Tỉnh Lai Châu",
        "code": "03643",
        "parent_code": "110"
    },
    "03598": {
        "name": "Tân Uyên",
        "type": "thi-tran",
        "slug": "tan-uyen",
        "name_with_type": "Thị trấn Tân Uyên",
        "path": "Tân Uyên, Tân Uyên, Lai Châu",
        "path_with_type": "Thị trấn Tân Uyên, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03598",
        "parent_code": "111"
    },
    "03601": {
        "name": "Mường Khoa",
        "type": "xa",
        "slug": "muong-khoa",
        "name_with_type": "Xã Mường Khoa",
        "path": "Mường Khoa, Tân Uyên, Lai Châu",
        "path_with_type": "Xã Mường Khoa, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03601",
        "parent_code": "111"
    },
    "03602": {
        "name": "Phúc Khoa",
        "type": "xa",
        "slug": "phuc-khoa",
        "name_with_type": "Xã Phúc Khoa",
        "path": "Phúc Khoa, Tân Uyên, Lai Châu",
        "path_with_type": "Xã Phúc Khoa, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03602",
        "parent_code": "111"
    },
    "03604": {
        "name": "Thân Thuộc",
        "type": "xa",
        "slug": "than-thuoc",
        "name_with_type": "Xã Thân Thuộc",
        "path": "Thân Thuộc, Tân Uyên, Lai Châu",
        "path_with_type": "Xã Thân Thuộc, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03604",
        "parent_code": "111"
    },
    "03605": {
        "name": "Trung Đồng",
        "type": "xa",
        "slug": "trung-dong",
        "name_with_type": "Xã Trung Đồng",
        "path": "Trung Đồng, Tân Uyên, Lai Châu",
        "path_with_type": "Xã Trung Đồng, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03605",
        "parent_code": "111"
    },
    "03607": {
        "name": "Hố Mít",
        "type": "xa",
        "slug": "ho-mit",
        "name_with_type": "Xã Hố Mít",
        "path": "Hố Mít, Tân Uyên, Lai Châu",
        "path_with_type": "Xã Hố Mít, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03607",
        "parent_code": "111"
    },
    "03610": {
        "name": "Nậm Cần",
        "type": "xa",
        "slug": "nam-can",
        "name_with_type": "Xã Nậm Cần",
        "path": "Nậm Cần, Tân Uyên, Lai Châu",
        "path_with_type": "Xã Nậm Cần, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03610",
        "parent_code": "111"
    },
    "03613": {
        "name": "Nậm Sỏ",
        "type": "xa",
        "slug": "nam-so",
        "name_with_type": "Xã Nậm Sỏ",
        "path": "Nậm Sỏ, Tân Uyên, Lai Châu",
        "path_with_type": "Xã Nậm Sỏ, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03613",
        "parent_code": "111"
    },
    "03616": {
        "name": "Pắc Ta",
        "type": "xa",
        "slug": "pac-ta",
        "name_with_type": "Xã Pắc Ta",
        "path": "Pắc Ta, Tân Uyên, Lai Châu",
        "path_with_type": "Xã Pắc Ta, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03616",
        "parent_code": "111"
    },
    "03622": {
        "name": "Tà Mít",
        "type": "xa",
        "slug": "ta-mit",
        "name_with_type": "Xã Tà Mít",
        "path": "Tà Mít, Tân Uyên, Lai Châu",
        "path_with_type": "Xã Tà Mít, Huyện Tân Uyên, Tỉnh Lai Châu",
        "code": "03622",
        "parent_code": "111"
    },
    "03434": {
        "name": "Nậm Nhùn",
        "type": "thi-tran",
        "slug": "nam-nhun",
        "name_with_type": "Thị trấn Nậm Nhùn",
        "path": "Nậm Nhùn, Nậm Nhùn, Lai Châu",
        "path_with_type": "Thị trấn Nậm Nhùn, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03434",
        "parent_code": "112"
    },
    "03460": {
        "name": "Hua Bun",
        "type": "xa",
        "slug": "hua-bun",
        "name_with_type": "Xã Hua Bun",
        "path": "Hua Bun, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Hua Bun, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03460",
        "parent_code": "112"
    },
    "03472": {
        "name": "Mường Mô",
        "type": "xa",
        "slug": "muong-mo",
        "name_with_type": "Xã Mường Mô",
        "path": "Mường Mô, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Mường Mô, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03472",
        "parent_code": "112"
    },
    "03473": {
        "name": "Nậm Chà",
        "type": "xa",
        "slug": "nam-cha",
        "name_with_type": "Xã Nậm Chà",
        "path": "Nậm Chà, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Nậm Chà, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03473",
        "parent_code": "112"
    },
    "03474": {
        "name": "Nậm Manh",
        "type": "xa",
        "slug": "nam-manh",
        "name_with_type": "Xã Nậm Manh",
        "path": "Nậm Manh, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Nậm Manh, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03474",
        "parent_code": "112"
    },
    "03475": {
        "name": "Nậm Hàng",
        "type": "xa",
        "slug": "nam-hang",
        "name_with_type": "Xã Nậm Hàng",
        "path": "Nậm Hàng, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Nậm Hàng, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03475",
        "parent_code": "112"
    },
    "03481": {
        "name": "Lê Lợi",
        "type": "xa",
        "slug": "le-loi",
        "name_with_type": "Xã Lê Lợi",
        "path": "Lê Lợi, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Lê Lợi, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03481",
        "parent_code": "112"
    },
    "03484": {
        "name": "Pú Đao",
        "type": "xa",
        "slug": "pu-dao",
        "name_with_type": "Xã Pú Đao",
        "path": "Pú Đao, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Pú Đao, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03484",
        "parent_code": "112"
    },
    "03488": {
        "name": "Nậm Pì",
        "type": "xa",
        "slug": "nam-pi",
        "name_with_type": "Xã Nậm Pì",
        "path": "Nậm Pì, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Nậm Pì, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03488",
        "parent_code": "112"
    },
    "03502": {
        "name": "Nậm Ban",
        "type": "xa",
        "slug": "nam-ban",
        "name_with_type": "Xã Nậm Ban",
        "path": "Nậm Ban, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Nậm Ban, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03502",
        "parent_code": "112"
    },
    "03503": {
        "name": "Trung Chải",
        "type": "xa",
        "slug": "trung-chai",
        "name_with_type": "Xã Trung Chải",
        "path": "Trung Chải, Nậm Nhùn, Lai Châu",
        "path_with_type": "Xã Trung Chải, Huyện Nậm Nhùn, Tỉnh Lai Châu",
        "code": "03503",
        "parent_code": "112"
    },
    "28756": {
        "name": "Phú Khương",
        "type": "phuong",
        "slug": "phu-khuong",
        "name_with_type": "Phường Phú Khương",
        "path": "Phú Khương, Bến Tre, Bến Tre",
        "path_with_type": "Phường Phú Khương, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28756",
        "parent_code": "829"
    },
    "28757": {
        "name": "Phú Tân",
        "type": "phuong",
        "slug": "phu-tan",
        "name_with_type": "Phường Phú Tân",
        "path": "Phú Tân, Bến Tre, Bến Tre",
        "path_with_type": "Phường Phú Tân, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28757",
        "parent_code": "829"
    },
    "28759": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Bến Tre, Bến Tre",
        "path_with_type": "Phường 8, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28759",
        "parent_code": "829"
    },
    "28762": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Bến Tre, Bến Tre",
        "path_with_type": "Phường 6, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28762",
        "parent_code": "829"
    },
    "28765": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Bến Tre, Bến Tre",
        "path_with_type": "Phường 4, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28765",
        "parent_code": "829"
    },
    "28768": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Bến Tre, Bến Tre",
        "path_with_type": "Phường 5, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28768",
        "parent_code": "829"
    },
    "28771": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Bến Tre, Bến Tre",
        "path_with_type": "Phường 1, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28771",
        "parent_code": "829"
    },
    "28774": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Bến Tre, Bến Tre",
        "path_with_type": "Phường 3, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28774",
        "parent_code": "829"
    },
    "28777": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Bến Tre, Bến Tre",
        "path_with_type": "Phường 2, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28777",
        "parent_code": "829"
    },
    "28780": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Bến Tre, Bến Tre",
        "path_with_type": "Phường 7, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28780",
        "parent_code": "829"
    },
    "28783": {
        "name": "Sơn Đông",
        "type": "xa",
        "slug": "son-dong",
        "name_with_type": "Xã Sơn Đông",
        "path": "Sơn Đông, Bến Tre, Bến Tre",
        "path_with_type": "Xã Sơn Đông, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28783",
        "parent_code": "829"
    },
    "28786": {
        "name": "Phú Hưng",
        "type": "xa",
        "slug": "phu-hung",
        "name_with_type": "Xã Phú Hưng",
        "path": "Phú Hưng, Bến Tre, Bến Tre",
        "path_with_type": "Xã Phú Hưng, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28786",
        "parent_code": "829"
    },
    "28789": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Bến Tre, Bến Tre",
        "path_with_type": "Xã Bình Phú, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28789",
        "parent_code": "829"
    },
    "28792": {
        "name": "Mỹ Thạnh An",
        "type": "xa",
        "slug": "my-thanh-an",
        "name_with_type": "Xã Mỹ Thạnh An",
        "path": "Mỹ Thạnh An, Bến Tre, Bến Tre",
        "path_with_type": "Xã Mỹ Thạnh An, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28792",
        "parent_code": "829"
    },
    "28795": {
        "name": "Nhơn Thạnh",
        "type": "xa",
        "slug": "nhon-thanh",
        "name_with_type": "Xã Nhơn Thạnh",
        "path": "Nhơn Thạnh, Bến Tre, Bến Tre",
        "path_with_type": "Xã Nhơn Thạnh, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28795",
        "parent_code": "829"
    },
    "28798": {
        "name": "Phú Nhuận",
        "type": "xa",
        "slug": "phu-nhuan",
        "name_with_type": "Xã Phú Nhuận",
        "path": "Phú Nhuận, Bến Tre, Bến Tre",
        "path_with_type": "Xã Phú Nhuận, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28798",
        "parent_code": "829"
    },
    "28867": {
        "name": "Mỹ Thành",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thành",
        "path": "Mỹ Thành, Bến Tre, Bến Tre",
        "path_with_type": "Xã Mỹ Thành, Thành phố Bến Tre, Tỉnh Bến Tre",
        "code": "28867",
        "parent_code": "829"
    },
    "28801": {
        "name": "Châu Thành",
        "type": "thi-tran",
        "slug": "chau-thanh",
        "name_with_type": "Thị trấn Châu Thành",
        "path": "Châu Thành, Châu Thành, Bến Tre",
        "path_with_type": "Thị trấn Châu Thành, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28801",
        "parent_code": "831"
    },
    "28804": {
        "name": "Tân Thạch",
        "type": "xa",
        "slug": "tan-thach",
        "name_with_type": "Xã Tân Thạch",
        "path": "Tân Thạch, Châu Thành, Bến Tre",
        "path_with_type": "Xã Tân Thạch, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28804",
        "parent_code": "831"
    },
    "28807": {
        "name": "Qưới Sơn",
        "type": "xa",
        "slug": "quoi-son",
        "name_with_type": "Xã Qưới Sơn",
        "path": "Qưới Sơn, Châu Thành, Bến Tre",
        "path_with_type": "Xã Qưới Sơn, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28807",
        "parent_code": "831"
    },
    "28810": {
        "name": "An Khánh",
        "type": "xa",
        "slug": "an-khanh",
        "name_with_type": "Xã An Khánh",
        "path": "An Khánh, Châu Thành, Bến Tre",
        "path_with_type": "Xã An Khánh, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28810",
        "parent_code": "831"
    },
    "28813": {
        "name": "Giao Long",
        "type": "xa",
        "slug": "giao-long",
        "name_with_type": "Xã Giao Long",
        "path": "Giao Long, Châu Thành, Bến Tre",
        "path_with_type": "Xã Giao Long, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28813",
        "parent_code": "831"
    },
    "28816": {
        "name": "Giao Hòa",
        "type": "xa",
        "slug": "giao-hoa",
        "name_with_type": "Xã Giao Hòa",
        "path": "Giao Hòa, Châu Thành, Bến Tre",
        "path_with_type": "Xã Giao Hòa, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28816",
        "parent_code": "831"
    },
    "28819": {
        "name": "Phú Túc",
        "type": "xa",
        "slug": "phu-tuc",
        "name_with_type": "Xã Phú Túc",
        "path": "Phú Túc, Châu Thành, Bến Tre",
        "path_with_type": "Xã Phú Túc, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28819",
        "parent_code": "831"
    },
    "28822": {
        "name": "Phú Đức",
        "type": "xa",
        "slug": "phu-duc",
        "name_with_type": "Xã Phú Đức",
        "path": "Phú Đức, Châu Thành, Bến Tre",
        "path_with_type": "Xã Phú Đức, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28822",
        "parent_code": "831"
    },
    "28825": {
        "name": "Phú An Hòa",
        "type": "xa",
        "slug": "phu-an-hoa",
        "name_with_type": "Xã Phú An Hòa",
        "path": "Phú An Hòa, Châu Thành, Bến Tre",
        "path_with_type": "Xã Phú An Hòa, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28825",
        "parent_code": "831"
    },
    "28828": {
        "name": "An Phước",
        "type": "xa",
        "slug": "an-phuoc",
        "name_with_type": "Xã An Phước",
        "path": "An Phước, Châu Thành, Bến Tre",
        "path_with_type": "Xã An Phước, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28828",
        "parent_code": "831"
    },
    "28831": {
        "name": "Tam Phước",
        "type": "xa",
        "slug": "tam-phuoc",
        "name_with_type": "Xã Tam Phước",
        "path": "Tam Phước, Châu Thành, Bến Tre",
        "path_with_type": "Xã Tam Phước, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28831",
        "parent_code": "831"
    },
    "28834": {
        "name": "Thành Triệu",
        "type": "xa",
        "slug": "thanh-trieu",
        "name_with_type": "Xã Thành Triệu",
        "path": "Thành Triệu, Châu Thành, Bến Tre",
        "path_with_type": "Xã Thành Triệu, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28834",
        "parent_code": "831"
    },
    "28837": {
        "name": "Tường Đa",
        "type": "xa",
        "slug": "tuong-da",
        "name_with_type": "Xã Tường Đa",
        "path": "Tường Đa, Châu Thành, Bến Tre",
        "path_with_type": "Xã Tường Đa, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28837",
        "parent_code": "831"
    },
    "28840": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Châu Thành, Bến Tre",
        "path_with_type": "Xã Tân Phú, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28840",
        "parent_code": "831"
    },
    "28843": {
        "name": "Quới Thành",
        "type": "xa",
        "slug": "quoi-thanh",
        "name_with_type": "Xã Quới Thành",
        "path": "Quới Thành, Châu Thành, Bến Tre",
        "path_with_type": "Xã Quới Thành, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28843",
        "parent_code": "831"
    },
    "28846": {
        "name": "Phước Thạnh",
        "type": "xa",
        "slug": "phuoc-thanh",
        "name_with_type": "Xã Phước Thạnh",
        "path": "Phước Thạnh, Châu Thành, Bến Tre",
        "path_with_type": "Xã Phước Thạnh, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28846",
        "parent_code": "831"
    },
    "28849": {
        "name": "An Hóa",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hóa",
        "path": "An Hóa, Châu Thành, Bến Tre",
        "path_with_type": "Xã An Hóa, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28849",
        "parent_code": "831"
    },
    "28852": {
        "name": "Tiên Long",
        "type": "xa",
        "slug": "tien-long",
        "name_with_type": "Xã Tiên Long",
        "path": "Tiên Long, Châu Thành, Bến Tre",
        "path_with_type": "Xã Tiên Long, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28852",
        "parent_code": "831"
    },
    "28855": {
        "name": "An Hiệp",
        "type": "xa",
        "slug": "an-hiep",
        "name_with_type": "Xã An Hiệp",
        "path": "An Hiệp, Châu Thành, Bến Tre",
        "path_with_type": "Xã An Hiệp, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28855",
        "parent_code": "831"
    },
    "28858": {
        "name": "Hữu Định",
        "type": "xa",
        "slug": "huu-dinh",
        "name_with_type": "Xã Hữu Định",
        "path": "Hữu Định, Châu Thành, Bến Tre",
        "path_with_type": "Xã Hữu Định, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28858",
        "parent_code": "831"
    },
    "28861": {
        "name": "Tiên Thủy",
        "type": "xa",
        "slug": "tien-thuy",
        "name_with_type": "Xã Tiên Thủy",
        "path": "Tiên Thủy, Châu Thành, Bến Tre",
        "path_with_type": "Xã Tiên Thủy, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28861",
        "parent_code": "831"
    },
    "28864": {
        "name": "Sơn Hòa",
        "type": "xa",
        "slug": "son-hoa",
        "name_with_type": "Xã Sơn Hòa",
        "path": "Sơn Hòa, Châu Thành, Bến Tre",
        "path_with_type": "Xã Sơn Hòa, Huyện Châu Thành, Tỉnh Bến Tre",
        "code": "28864",
        "parent_code": "831"
    },
    "28870": {
        "name": "Chợ Lách",
        "type": "thi-tran",
        "slug": "cho-lach",
        "name_with_type": "Thị trấn Chợ Lách",
        "path": "Chợ Lách, Chợ Lách, Bến Tre",
        "path_with_type": "Thị trấn Chợ Lách, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28870",
        "parent_code": "832"
    },
    "28873": {
        "name": "Phú Phụng",
        "type": "xa",
        "slug": "phu-phung",
        "name_with_type": "Xã Phú Phụng",
        "path": "Phú Phụng, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Phú Phụng, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28873",
        "parent_code": "832"
    },
    "28876": {
        "name": "Sơn Định",
        "type": "xa",
        "slug": "son-dinh",
        "name_with_type": "Xã Sơn Định",
        "path": "Sơn Định, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Sơn Định, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28876",
        "parent_code": "832"
    },
    "28879": {
        "name": "Vĩnh Bình",
        "type": "xa",
        "slug": "vinh-binh",
        "name_with_type": "Xã Vĩnh Bình",
        "path": "Vĩnh Bình, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Vĩnh Bình, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28879",
        "parent_code": "832"
    },
    "28882": {
        "name": "Hòa Nghĩa",
        "type": "xa",
        "slug": "hoa-nghia",
        "name_with_type": "Xã Hòa Nghĩa",
        "path": "Hòa Nghĩa, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Hòa Nghĩa, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28882",
        "parent_code": "832"
    },
    "28885": {
        "name": "Long Thới",
        "type": "xa",
        "slug": "long-thoi",
        "name_with_type": "Xã Long Thới",
        "path": "Long Thới, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Long Thới, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28885",
        "parent_code": "832"
    },
    "28888": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Phú Sơn, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28888",
        "parent_code": "832"
    },
    "28891": {
        "name": "Tân Thiềng",
        "type": "xa",
        "slug": "tan-thieng",
        "name_with_type": "Xã Tân Thiềng",
        "path": "Tân Thiềng, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Tân Thiềng, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28891",
        "parent_code": "832"
    },
    "28894": {
        "name": "Vĩnh Thành",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thành",
        "path": "Vĩnh Thành, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Vĩnh Thành, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28894",
        "parent_code": "832"
    },
    "28897": {
        "name": "Vĩnh Hòa",
        "type": "xa",
        "slug": "vinh-hoa",
        "name_with_type": "Xã Vĩnh Hòa",
        "path": "Vĩnh Hòa, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Vĩnh Hòa, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28897",
        "parent_code": "832"
    },
    "28900": {
        "name": "Hưng Khánh Trung B",
        "type": "xa",
        "slug": "hung-khanh-trung-b",
        "name_with_type": "Xã Hưng Khánh Trung B",
        "path": "Hưng Khánh Trung B, Chợ Lách, Bến Tre",
        "path_with_type": "Xã Hưng Khánh Trung B, Huyện Chợ Lách, Tỉnh Bến Tre",
        "code": "28900",
        "parent_code": "832"
    },
    "28903": {
        "name": "Mỏ Cày",
        "type": "thi-tran",
        "slug": "mo-cay",
        "name_with_type": "Thị trấn Mỏ Cày",
        "path": "Mỏ Cày, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Thị trấn Mỏ Cày, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28903",
        "parent_code": "833"
    },
    "28930": {
        "name": "Định Thủy",
        "type": "xa",
        "slug": "dinh-thuy",
        "name_with_type": "Xã Định Thủy",
        "path": "Định Thủy, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Định Thủy, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28930",
        "parent_code": "833"
    },
    "28939": {
        "name": "Đa Phước Hội",
        "type": "xa",
        "slug": "da-phuoc-hoi",
        "name_with_type": "Xã Đa Phước Hội",
        "path": "Đa Phước Hội, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Đa Phước Hội, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28939",
        "parent_code": "833"
    },
    "28940": {
        "name": "Tân Hội",
        "type": "xa",
        "slug": "tan-hoi",
        "name_with_type": "Xã Tân Hội",
        "path": "Tân Hội, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Tân Hội, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28940",
        "parent_code": "833"
    },
    "28942": {
        "name": "Phước Hiệp",
        "type": "xa",
        "slug": "phuoc-hiep",
        "name_with_type": "Xã Phước Hiệp",
        "path": "Phước Hiệp, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Phước Hiệp, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28942",
        "parent_code": "833"
    },
    "28945": {
        "name": "Bình Khánh Đông",
        "type": "xa",
        "slug": "binh-khanh-dong",
        "name_with_type": "Xã Bình Khánh Đông",
        "path": "Bình Khánh Đông, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Bình Khánh Đông, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28945",
        "parent_code": "833"
    },
    "28951": {
        "name": "An Thạnh",
        "type": "xa",
        "slug": "an-thanh",
        "name_with_type": "Xã An Thạnh",
        "path": "An Thạnh, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã An Thạnh, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28951",
        "parent_code": "833"
    },
    "28954": {
        "name": "Bình Khánh Tây",
        "type": "xa",
        "slug": "binh-khanh-tay",
        "name_with_type": "Xã Bình Khánh Tây",
        "path": "Bình Khánh Tây, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Bình Khánh Tây, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28954",
        "parent_code": "833"
    },
    "28957": {
        "name": "An Định",
        "type": "xa",
        "slug": "an-dinh",
        "name_with_type": "Xã An Định",
        "path": "An Định, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã An Định, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28957",
        "parent_code": "833"
    },
    "28960": {
        "name": "Thành Thới B",
        "type": "xa",
        "slug": "thanh-thoi-b",
        "name_with_type": "Xã Thành Thới B",
        "path": "Thành Thới B, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Thành Thới B, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28960",
        "parent_code": "833"
    },
    "28963": {
        "name": "Tân Trung",
        "type": "xa",
        "slug": "tan-trung",
        "name_with_type": "Xã Tân Trung",
        "path": "Tân Trung, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Tân Trung, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28963",
        "parent_code": "833"
    },
    "28966": {
        "name": "An Thới",
        "type": "xa",
        "slug": "an-thoi",
        "name_with_type": "Xã An Thới",
        "path": "An Thới, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã An Thới, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28966",
        "parent_code": "833"
    },
    "28969": {
        "name": "Thành Thới A",
        "type": "xa",
        "slug": "thanh-thoi-a",
        "name_with_type": "Xã Thành Thới A",
        "path": "Thành Thới A, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Thành Thới A, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28969",
        "parent_code": "833"
    },
    "28972": {
        "name": "Minh Đức",
        "type": "xa",
        "slug": "minh-duc",
        "name_with_type": "Xã Minh Đức",
        "path": "Minh Đức, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Minh Đức, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28972",
        "parent_code": "833"
    },
    "28975": {
        "name": "Ngãi Đăng",
        "type": "xa",
        "slug": "ngai-dang",
        "name_with_type": "Xã Ngãi Đăng",
        "path": "Ngãi Đăng, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Ngãi Đăng, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28975",
        "parent_code": "833"
    },
    "28978": {
        "name": "Cẩm Sơn",
        "type": "xa",
        "slug": "cam-son",
        "name_with_type": "Xã Cẩm Sơn",
        "path": "Cẩm Sơn, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Cẩm Sơn, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28978",
        "parent_code": "833"
    },
    "28981": {
        "name": "Hương Mỹ",
        "type": "xa",
        "slug": "huong-my",
        "name_with_type": "Xã Hương Mỹ",
        "path": "Hương Mỹ, Mỏ Cày Nam, Bến Tre",
        "path_with_type": "Xã Hương Mỹ, Huyện Mỏ Cày Nam, Tỉnh Bến Tre",
        "code": "28981",
        "parent_code": "833"
    },
    "28984": {
        "name": "Giồng Trôm",
        "type": "thi-tran",
        "slug": "giong-trom",
        "name_with_type": "Thị trấn Giồng Trôm",
        "path": "Giồng Trôm, Giồng Trôm, Bến Tre",
        "path_with_type": "Thị trấn Giồng Trôm, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "28984",
        "parent_code": "834"
    },
    "28987": {
        "name": "Phong Nẫm",
        "type": "xa",
        "slug": "phong-nam",
        "name_with_type": "Xã Phong Nẫm",
        "path": "Phong Nẫm, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Phong Nẫm, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "28987",
        "parent_code": "834"
    },
    "28990": {
        "name": "Phong Mỹ",
        "type": "xa",
        "slug": "phong-my",
        "name_with_type": "Xã Phong Mỹ",
        "path": "Phong Mỹ, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Phong Mỹ, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "28990",
        "parent_code": "834"
    },
    "28993": {
        "name": "Mỹ Thạnh",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thạnh",
        "path": "Mỹ Thạnh, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Mỹ Thạnh, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "28993",
        "parent_code": "834"
    },
    "28996": {
        "name": "Châu Hòa",
        "type": "xa",
        "slug": "chau-hoa",
        "name_with_type": "Xã Châu Hòa",
        "path": "Châu Hòa, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Châu Hòa, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "28996",
        "parent_code": "834"
    },
    "28999": {
        "name": "Lương Hòa",
        "type": "xa",
        "slug": "luong-hoa",
        "name_with_type": "Xã Lương Hòa",
        "path": "Lương Hòa, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Lương Hòa, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "28999",
        "parent_code": "834"
    },
    "29002": {
        "name": "Lương Quới",
        "type": "xa",
        "slug": "luong-quoi",
        "name_with_type": "Xã Lương Quới",
        "path": "Lương Quới, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Lương Quới, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29002",
        "parent_code": "834"
    },
    "29005": {
        "name": "Lương Phú",
        "type": "xa",
        "slug": "luong-phu",
        "name_with_type": "Xã Lương Phú",
        "path": "Lương Phú, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Lương Phú, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29005",
        "parent_code": "834"
    },
    "29008": {
        "name": "Châu Bình",
        "type": "xa",
        "slug": "chau-binh",
        "name_with_type": "Xã Châu Bình",
        "path": "Châu Bình, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Châu Bình, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29008",
        "parent_code": "834"
    },
    "29011": {
        "name": "Thuận Điền",
        "type": "xa",
        "slug": "thuan-dien",
        "name_with_type": "Xã Thuận Điền",
        "path": "Thuận Điền, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Thuận Điền, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29011",
        "parent_code": "834"
    },
    "29014": {
        "name": "Sơn Phú",
        "type": "xa",
        "slug": "son-phu",
        "name_with_type": "Xã Sơn Phú",
        "path": "Sơn Phú, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Sơn Phú, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29014",
        "parent_code": "834"
    },
    "29017": {
        "name": "Bình Hoà",
        "type": "xa",
        "slug": "binh-hoa",
        "name_with_type": "Xã Bình Hoà",
        "path": "Bình Hoà, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Bình Hoà, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29017",
        "parent_code": "834"
    },
    "29020": {
        "name": "Phước Long",
        "type": "xa",
        "slug": "phuoc-long",
        "name_with_type": "Xã Phước Long",
        "path": "Phước Long, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Phước Long, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29020",
        "parent_code": "834"
    },
    "29023": {
        "name": "Hưng Phong",
        "type": "xa",
        "slug": "hung-phong",
        "name_with_type": "Xã Hưng Phong",
        "path": "Hưng Phong, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Hưng Phong, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29023",
        "parent_code": "834"
    },
    "29026": {
        "name": "Long Mỹ",
        "type": "xa",
        "slug": "long-my",
        "name_with_type": "Xã Long Mỹ",
        "path": "Long Mỹ, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Long Mỹ, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29026",
        "parent_code": "834"
    },
    "29029": {
        "name": "Tân Hào",
        "type": "xa",
        "slug": "tan-hao",
        "name_with_type": "Xã Tân Hào",
        "path": "Tân Hào, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Tân Hào, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29029",
        "parent_code": "834"
    },
    "29032": {
        "name": "Bình Thành",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thành",
        "path": "Bình Thành, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Bình Thành, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29032",
        "parent_code": "834"
    },
    "29035": {
        "name": "Tân Thanh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thanh",
        "path": "Tân Thanh, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Tân Thanh, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29035",
        "parent_code": "834"
    },
    "29038": {
        "name": "Tân Lợi Thạnh",
        "type": "xa",
        "slug": "tan-loi-thanh",
        "name_with_type": "Xã Tân Lợi Thạnh",
        "path": "Tân Lợi Thạnh, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Tân Lợi Thạnh, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29038",
        "parent_code": "834"
    },
    "29041": {
        "name": "Thạnh Phú Đông",
        "type": "xa",
        "slug": "thanh-phu-dong",
        "name_with_type": "Xã Thạnh Phú Đông",
        "path": "Thạnh Phú Đông, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Thạnh Phú Đông, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29041",
        "parent_code": "834"
    },
    "29044": {
        "name": "Hưng Nhượng",
        "type": "xa",
        "slug": "hung-nhuong",
        "name_with_type": "Xã Hưng Nhượng",
        "path": "Hưng Nhượng, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Hưng Nhượng, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29044",
        "parent_code": "834"
    },
    "29047": {
        "name": "Hưng Lễ",
        "type": "xa",
        "slug": "hung-le",
        "name_with_type": "Xã Hưng Lễ",
        "path": "Hưng Lễ, Giồng Trôm, Bến Tre",
        "path_with_type": "Xã Hưng Lễ, Huyện Giồng Trôm, Tỉnh Bến Tre",
        "code": "29047",
        "parent_code": "834"
    },
    "29050": {
        "name": "Bình Đại",
        "type": "thi-tran",
        "slug": "binh-dai",
        "name_with_type": "Thị trấn Bình Đại",
        "path": "Bình Đại, Bình Đại, Bến Tre",
        "path_with_type": "Thị trấn Bình Đại, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29050",
        "parent_code": "835"
    },
    "29053": {
        "name": "Tam Hiệp",
        "type": "xa",
        "slug": "tam-hiep",
        "name_with_type": "Xã Tam Hiệp",
        "path": "Tam Hiệp, Bình Đại, Bến Tre",
        "path_with_type": "Xã Tam Hiệp, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29053",
        "parent_code": "835"
    },
    "29056": {
        "name": "Long Định",
        "type": "xa",
        "slug": "long-dinh",
        "name_with_type": "Xã Long Định",
        "path": "Long Định, Bình Đại, Bến Tre",
        "path_with_type": "Xã Long Định, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29056",
        "parent_code": "835"
    },
    "29059": {
        "name": "Long Hòa",
        "type": "xa",
        "slug": "long-hoa",
        "name_with_type": "Xã Long Hòa",
        "path": "Long Hòa, Bình Đại, Bến Tre",
        "path_with_type": "Xã Long Hòa, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29059",
        "parent_code": "835"
    },
    "29062": {
        "name": "Phú Thuận",
        "type": "xa",
        "slug": "phu-thuan",
        "name_with_type": "Xã Phú Thuận",
        "path": "Phú Thuận, Bình Đại, Bến Tre",
        "path_with_type": "Xã Phú Thuận, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29062",
        "parent_code": "835"
    },
    "29065": {
        "name": "Vang Quới Tây",
        "type": "xa",
        "slug": "vang-quoi-tay",
        "name_with_type": "Xã Vang Quới Tây",
        "path": "Vang Quới Tây, Bình Đại, Bến Tre",
        "path_with_type": "Xã Vang Quới Tây, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29065",
        "parent_code": "835"
    },
    "29068": {
        "name": "Vang Quới Đông",
        "type": "xa",
        "slug": "vang-quoi-dong",
        "name_with_type": "Xã Vang Quới Đông",
        "path": "Vang Quới Đông, Bình Đại, Bến Tre",
        "path_with_type": "Xã Vang Quới Đông, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29068",
        "parent_code": "835"
    },
    "29071": {
        "name": "Châu Hưng",
        "type": "xa",
        "slug": "chau-hung",
        "name_with_type": "Xã Châu Hưng",
        "path": "Châu Hưng, Bình Đại, Bến Tre",
        "path_with_type": "Xã Châu Hưng, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29071",
        "parent_code": "835"
    },
    "29074": {
        "name": "Phú Vang",
        "type": "xa",
        "slug": "phu-vang",
        "name_with_type": "Xã Phú Vang",
        "path": "Phú Vang, Bình Đại, Bến Tre",
        "path_with_type": "Xã Phú Vang, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29074",
        "parent_code": "835"
    },
    "29077": {
        "name": "Lộc Thuận",
        "type": "xa",
        "slug": "loc-thuan",
        "name_with_type": "Xã Lộc Thuận",
        "path": "Lộc Thuận, Bình Đại, Bến Tre",
        "path_with_type": "Xã Lộc Thuận, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29077",
        "parent_code": "835"
    },
    "29080": {
        "name": "Định Trung",
        "type": "xa",
        "slug": "dinh-trung",
        "name_with_type": "Xã Định Trung",
        "path": "Định Trung, Bình Đại, Bến Tre",
        "path_with_type": "Xã Định Trung, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29080",
        "parent_code": "835"
    },
    "29083": {
        "name": "Thới Lai",
        "type": "xa",
        "slug": "thoi-lai",
        "name_with_type": "Xã Thới Lai",
        "path": "Thới Lai, Bình Đại, Bến Tre",
        "path_with_type": "Xã Thới Lai, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29083",
        "parent_code": "835"
    },
    "29086": {
        "name": "Bình Thới",
        "type": "xa",
        "slug": "binh-thoi",
        "name_with_type": "Xã Bình Thới",
        "path": "Bình Thới, Bình Đại, Bến Tre",
        "path_with_type": "Xã Bình Thới, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29086",
        "parent_code": "835"
    },
    "29089": {
        "name": "Phú Long",
        "type": "xa",
        "slug": "phu-long",
        "name_with_type": "Xã Phú Long",
        "path": "Phú Long, Bình Đại, Bến Tre",
        "path_with_type": "Xã Phú Long, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29089",
        "parent_code": "835"
    },
    "29092": {
        "name": "Bình Thắng",
        "type": "xa",
        "slug": "binh-thang",
        "name_with_type": "Xã Bình Thắng",
        "path": "Bình Thắng, Bình Đại, Bến Tre",
        "path_with_type": "Xã Bình Thắng, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29092",
        "parent_code": "835"
    },
    "29095": {
        "name": "Thạnh Trị",
        "type": "xa",
        "slug": "thanh-tri",
        "name_with_type": "Xã Thạnh Trị",
        "path": "Thạnh Trị, Bình Đại, Bến Tre",
        "path_with_type": "Xã Thạnh Trị, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29095",
        "parent_code": "835"
    },
    "29098": {
        "name": "Đại Hòa Lộc",
        "type": "xa",
        "slug": "dai-hoa-loc",
        "name_with_type": "Xã Đại Hòa Lộc",
        "path": "Đại Hòa Lộc, Bình Đại, Bến Tre",
        "path_with_type": "Xã Đại Hòa Lộc, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29098",
        "parent_code": "835"
    },
    "29101": {
        "name": "Thừa Đức",
        "type": "xa",
        "slug": "thua-duc",
        "name_with_type": "Xã Thừa Đức",
        "path": "Thừa Đức, Bình Đại, Bến Tre",
        "path_with_type": "Xã Thừa Đức, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29101",
        "parent_code": "835"
    },
    "29104": {
        "name": "Thạnh Phước",
        "type": "xa",
        "slug": "thanh-phuoc",
        "name_with_type": "Xã Thạnh Phước",
        "path": "Thạnh Phước, Bình Đại, Bến Tre",
        "path_with_type": "Xã Thạnh Phước, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29104",
        "parent_code": "835"
    },
    "29107": {
        "name": "Thới Thuận",
        "type": "xa",
        "slug": "thoi-thuan",
        "name_with_type": "Xã Thới Thuận",
        "path": "Thới Thuận, Bình Đại, Bến Tre",
        "path_with_type": "Xã Thới Thuận, Huyện Bình Đại, Tỉnh Bến Tre",
        "code": "29107",
        "parent_code": "835"
    },
    "29110": {
        "name": "Ba Tri",
        "type": "thi-tran",
        "slug": "ba-tri",
        "name_with_type": "Thị trấn Ba Tri",
        "path": "Ba Tri, Ba Tri, Bến Tre",
        "path_with_type": "Thị trấn Ba Tri, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29110",
        "parent_code": "836"
    },
    "29113": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Ba Tri, Bến Tre",
        "path_with_type": "Xã Tân Mỹ, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29113",
        "parent_code": "836"
    },
    "29116": {
        "name": "Mỹ Hòa",
        "type": "xa",
        "slug": "my-hoa",
        "name_with_type": "Xã Mỹ Hòa",
        "path": "Mỹ Hòa, Ba Tri, Bến Tre",
        "path_with_type": "Xã Mỹ Hòa, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29116",
        "parent_code": "836"
    },
    "29119": {
        "name": "Tân Xuân",
        "type": "xa",
        "slug": "tan-xuan",
        "name_with_type": "Xã Tân Xuân",
        "path": "Tân Xuân, Ba Tri, Bến Tre",
        "path_with_type": "Xã Tân Xuân, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29119",
        "parent_code": "836"
    },
    "29122": {
        "name": "Mỹ Chánh",
        "type": "xa",
        "slug": "my-chanh",
        "name_with_type": "Xã Mỹ Chánh",
        "path": "Mỹ Chánh, Ba Tri, Bến Tre",
        "path_with_type": "Xã Mỹ Chánh, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29122",
        "parent_code": "836"
    },
    "29125": {
        "name": "Bảo Thạnh",
        "type": "xa",
        "slug": "bao-thanh",
        "name_with_type": "Xã Bảo Thạnh",
        "path": "Bảo Thạnh, Ba Tri, Bến Tre",
        "path_with_type": "Xã Bảo Thạnh, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29125",
        "parent_code": "836"
    },
    "29128": {
        "name": "An Phú Trung",
        "type": "xa",
        "slug": "an-phu-trung",
        "name_with_type": "Xã An Phú Trung",
        "path": "An Phú Trung, Ba Tri, Bến Tre",
        "path_with_type": "Xã An Phú Trung, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29128",
        "parent_code": "836"
    },
    "29131": {
        "name": "Mỹ Thạnh",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thạnh",
        "path": "Mỹ Thạnh, Ba Tri, Bến Tre",
        "path_with_type": "Xã Mỹ Thạnh, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29131",
        "parent_code": "836"
    },
    "29134": {
        "name": "Mỹ Nhơn",
        "type": "xa",
        "slug": "my-nhon",
        "name_with_type": "Xã Mỹ Nhơn",
        "path": "Mỹ Nhơn, Ba Tri, Bến Tre",
        "path_with_type": "Xã Mỹ Nhơn, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29134",
        "parent_code": "836"
    },
    "29137": {
        "name": "Phước Tuy",
        "type": "xa",
        "slug": "phuoc-tuy",
        "name_with_type": "Xã Phước Tuy",
        "path": "Phước Tuy, Ba Tri, Bến Tre",
        "path_with_type": "Xã Phước Tuy, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29137",
        "parent_code": "836"
    },
    "29140": {
        "name": "Phú Ngãi",
        "type": "xa",
        "slug": "phu-ngai",
        "name_with_type": "Xã Phú Ngãi",
        "path": "Phú Ngãi, Ba Tri, Bến Tre",
        "path_with_type": "Xã Phú Ngãi, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29140",
        "parent_code": "836"
    },
    "29143": {
        "name": "An Ngãi Trung",
        "type": "xa",
        "slug": "an-ngai-trung",
        "name_with_type": "Xã An Ngãi Trung",
        "path": "An Ngãi Trung, Ba Tri, Bến Tre",
        "path_with_type": "Xã An Ngãi Trung, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29143",
        "parent_code": "836"
    },
    "29146": {
        "name": "Phú Lễ",
        "type": "xa",
        "slug": "phu-le",
        "name_with_type": "Xã Phú Lễ",
        "path": "Phú Lễ, Ba Tri, Bến Tre",
        "path_with_type": "Xã Phú Lễ, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29146",
        "parent_code": "836"
    },
    "29149": {
        "name": "An Bình Tây",
        "type": "xa",
        "slug": "an-binh-tay",
        "name_with_type": "Xã An Bình Tây",
        "path": "An Bình Tây, Ba Tri, Bến Tre",
        "path_with_type": "Xã An Bình Tây, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29149",
        "parent_code": "836"
    },
    "29152": {
        "name": "Bảo Thuận",
        "type": "xa",
        "slug": "bao-thuan",
        "name_with_type": "Xã Bảo Thuận",
        "path": "Bảo Thuận, Ba Tri, Bến Tre",
        "path_with_type": "Xã Bảo Thuận, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29152",
        "parent_code": "836"
    },
    "29155": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Ba Tri, Bến Tre",
        "path_with_type": "Xã Tân Hưng, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29155",
        "parent_code": "836"
    },
    "29158": {
        "name": "An Ngãi Tây",
        "type": "xa",
        "slug": "an-ngai-tay",
        "name_with_type": "Xã An Ngãi Tây",
        "path": "An Ngãi Tây, Ba Tri, Bến Tre",
        "path_with_type": "Xã An Ngãi Tây, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29158",
        "parent_code": "836"
    },
    "29161": {
        "name": "An Hiệp",
        "type": "xa",
        "slug": "an-hiep",
        "name_with_type": "Xã An Hiệp",
        "path": "An Hiệp, Ba Tri, Bến Tre",
        "path_with_type": "Xã An Hiệp, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29161",
        "parent_code": "836"
    },
    "29164": {
        "name": "Vĩnh Hòa",
        "type": "xa",
        "slug": "vinh-hoa",
        "name_with_type": "Xã Vĩnh Hòa",
        "path": "Vĩnh Hòa, Ba Tri, Bến Tre",
        "path_with_type": "Xã Vĩnh Hòa, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29164",
        "parent_code": "836"
    },
    "29167": {
        "name": "Tân Thủy",
        "type": "xa",
        "slug": "tan-thuy",
        "name_with_type": "Xã Tân Thủy",
        "path": "Tân Thủy, Ba Tri, Bến Tre",
        "path_with_type": "Xã Tân Thủy, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29167",
        "parent_code": "836"
    },
    "29170": {
        "name": "Vĩnh An",
        "type": "xa",
        "slug": "vinh-an",
        "name_with_type": "Xã Vĩnh An",
        "path": "Vĩnh An, Ba Tri, Bến Tre",
        "path_with_type": "Xã Vĩnh An, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29170",
        "parent_code": "836"
    },
    "29173": {
        "name": "An Đức",
        "type": "xa",
        "slug": "an-duc",
        "name_with_type": "Xã An Đức",
        "path": "An Đức, Ba Tri, Bến Tre",
        "path_with_type": "Xã An Đức, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29173",
        "parent_code": "836"
    },
    "29176": {
        "name": "An Hòa Tây",
        "type": "xa",
        "slug": "an-hoa-tay",
        "name_with_type": "Xã An Hòa Tây",
        "path": "An Hòa Tây, Ba Tri, Bến Tre",
        "path_with_type": "Xã An Hòa Tây, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29176",
        "parent_code": "836"
    },
    "29179": {
        "name": "An Thủy",
        "type": "xa",
        "slug": "an-thuy",
        "name_with_type": "Xã An Thủy",
        "path": "An Thủy, Ba Tri, Bến Tre",
        "path_with_type": "Xã An Thủy, Huyện Ba Tri, Tỉnh Bến Tre",
        "code": "29179",
        "parent_code": "836"
    },
    "29182": {
        "name": "Thạnh Phú",
        "type": "thi-tran",
        "slug": "thanh-phu",
        "name_with_type": "Thị trấn Thạnh Phú",
        "path": "Thạnh Phú, Thạnh Phú, Bến Tre",
        "path_with_type": "Thị trấn Thạnh Phú, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29182",
        "parent_code": "837"
    },
    "29185": {
        "name": "Phú Khánh",
        "type": "xa",
        "slug": "phu-khanh",
        "name_with_type": "Xã Phú Khánh",
        "path": "Phú Khánh, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Phú Khánh, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29185",
        "parent_code": "837"
    },
    "29188": {
        "name": "Đại Điền",
        "type": "xa",
        "slug": "dai-dien",
        "name_with_type": "Xã Đại Điền",
        "path": "Đại Điền, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Đại Điền, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29188",
        "parent_code": "837"
    },
    "29191": {
        "name": "Quới Điền",
        "type": "xa",
        "slug": "quoi-dien",
        "name_with_type": "Xã Quới Điền",
        "path": "Quới Điền, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Quới Điền, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29191",
        "parent_code": "837"
    },
    "29194": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Tân Phong, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29194",
        "parent_code": "837"
    },
    "29197": {
        "name": "Mỹ Hưng",
        "type": "xa",
        "slug": "my-hung",
        "name_with_type": "Xã Mỹ Hưng",
        "path": "Mỹ Hưng, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Mỹ Hưng, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29197",
        "parent_code": "837"
    },
    "29200": {
        "name": "An Thạnh",
        "type": "xa",
        "slug": "an-thanh",
        "name_with_type": "Xã An Thạnh",
        "path": "An Thạnh, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã An Thạnh, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29200",
        "parent_code": "837"
    },
    "29203": {
        "name": "Thới Thạnh",
        "type": "xa",
        "slug": "thoi-thanh",
        "name_with_type": "Xã Thới Thạnh",
        "path": "Thới Thạnh, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Thới Thạnh, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29203",
        "parent_code": "837"
    },
    "29206": {
        "name": "Hòa Lợi",
        "type": "xa",
        "slug": "hoa-loi",
        "name_with_type": "Xã Hòa Lợi",
        "path": "Hòa Lợi, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Hòa Lợi, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29206",
        "parent_code": "837"
    },
    "29209": {
        "name": "An Điền",
        "type": "xa",
        "slug": "an-dien",
        "name_with_type": "Xã An Điền",
        "path": "An Điền, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã An Điền, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29209",
        "parent_code": "837"
    },
    "29212": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Bình Thạnh, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29212",
        "parent_code": "837"
    },
    "29215": {
        "name": "An Thuận",
        "type": "xa",
        "slug": "an-thuan",
        "name_with_type": "Xã An Thuận",
        "path": "An Thuận, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã An Thuận, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29215",
        "parent_code": "837"
    },
    "29218": {
        "name": "An Quy",
        "type": "xa",
        "slug": "an-quy",
        "name_with_type": "Xã An Quy",
        "path": "An Quy, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã An Quy, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29218",
        "parent_code": "837"
    },
    "29221": {
        "name": "Thạnh Hải",
        "type": "xa",
        "slug": "thanh-hai",
        "name_with_type": "Xã Thạnh Hải",
        "path": "Thạnh Hải, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Thạnh Hải, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29221",
        "parent_code": "837"
    },
    "29224": {
        "name": "An Nhơn",
        "type": "xa",
        "slug": "an-nhon",
        "name_with_type": "Xã An Nhơn",
        "path": "An Nhơn, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã An Nhơn, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29224",
        "parent_code": "837"
    },
    "29227": {
        "name": "Giao Thạnh",
        "type": "xa",
        "slug": "giao-thanh",
        "name_with_type": "Xã Giao Thạnh",
        "path": "Giao Thạnh, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Giao Thạnh, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29227",
        "parent_code": "837"
    },
    "29230": {
        "name": "Thạnh Phong",
        "type": "xa",
        "slug": "thanh-phong",
        "name_with_type": "Xã Thạnh Phong",
        "path": "Thạnh Phong, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Thạnh Phong, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29230",
        "parent_code": "837"
    },
    "29233": {
        "name": "Mỹ An",
        "type": "xa",
        "slug": "my-an",
        "name_with_type": "Xã Mỹ An",
        "path": "Mỹ An, Thạnh Phú, Bến Tre",
        "path_with_type": "Xã Mỹ An, Huyện Thạnh Phú, Tỉnh Bến Tre",
        "code": "29233",
        "parent_code": "837"
    },
    "28889": {
        "name": "Phú Mỹ",
        "type": "xa",
        "slug": "phu-my",
        "name_with_type": "Xã Phú Mỹ",
        "path": "Phú Mỹ, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Phú Mỹ, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28889",
        "parent_code": "838"
    },
    "28901": {
        "name": "Hưng Khánh Trung A",
        "type": "xa",
        "slug": "hung-khanh-trung-a",
        "name_with_type": "Xã Hưng Khánh Trung A",
        "path": "Hưng Khánh Trung A, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Hưng Khánh Trung A, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28901",
        "parent_code": "838"
    },
    "28906": {
        "name": "Thanh Tân",
        "type": "xa",
        "slug": "thanh-tan",
        "name_with_type": "Xã Thanh Tân",
        "path": "Thanh Tân, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Thanh Tân, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28906",
        "parent_code": "838"
    },
    "28909": {
        "name": "Thạnh Ngãi",
        "type": "xa",
        "slug": "thanh-ngai",
        "name_with_type": "Xã Thạnh Ngãi",
        "path": "Thạnh Ngãi, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Thạnh Ngãi, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28909",
        "parent_code": "838"
    },
    "28912": {
        "name": "Tân Phú Tây",
        "type": "xa",
        "slug": "tan-phu-tay",
        "name_with_type": "Xã Tân Phú Tây",
        "path": "Tân Phú Tây, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Tân Phú Tây, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28912",
        "parent_code": "838"
    },
    "28915": {
        "name": "Phước Mỹ Trung",
        "type": "xa",
        "slug": "phuoc-my-trung",
        "name_with_type": "Xã Phước Mỹ Trung",
        "path": "Phước Mỹ Trung, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Phước Mỹ Trung, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28915",
        "parent_code": "838"
    },
    "28918": {
        "name": "Tân Thành Bình",
        "type": "xa",
        "slug": "tan-thanh-binh",
        "name_with_type": "Xã Tân Thành Bình",
        "path": "Tân Thành Bình, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Tân Thành Bình, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28918",
        "parent_code": "838"
    },
    "28921": {
        "name": "Thành An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thành An",
        "path": "Thành An, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Thành An, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28921",
        "parent_code": "838"
    },
    "28924": {
        "name": "Hòa Lộc",
        "type": "xa",
        "slug": "hoa-loc",
        "name_with_type": "Xã Hòa Lộc",
        "path": "Hòa Lộc, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Hòa Lộc, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28924",
        "parent_code": "838"
    },
    "28927": {
        "name": "Tân Thanh Tây",
        "type": "xa",
        "slug": "tan-thanh-tay",
        "name_with_type": "Xã Tân Thanh Tây",
        "path": "Tân Thanh Tây, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Tân Thanh Tây, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28927",
        "parent_code": "838"
    },
    "28933": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Tân Bình, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28933",
        "parent_code": "838"
    },
    "28936": {
        "name": "Nhuận Phú Tân",
        "type": "xa",
        "slug": "nhuan-phu-tan",
        "name_with_type": "Xã Nhuận Phú Tân",
        "path": "Nhuận Phú Tân, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Nhuận Phú Tân, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28936",
        "parent_code": "838"
    },
    "28948": {
        "name": "Khánh Thạnh Tân",
        "type": "xa",
        "slug": "khanh-thanh-tan",
        "name_with_type": "Xã Khánh Thạnh Tân",
        "path": "Khánh Thạnh Tân, Mỏ Cày Bắc, Bến Tre",
        "path_with_type": "Xã Khánh Thạnh Tân, Huyện Mỏ Cày Bắc, Tỉnh Bến Tre",
        "code": "28948",
        "parent_code": "838"
    },
    "22327": {
        "name": "Vĩnh Hòa",
        "type": "phuong",
        "slug": "vinh-hoa",
        "name_with_type": "Phường Vĩnh Hòa",
        "path": "Vĩnh Hòa, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Vĩnh Hòa, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22327",
        "parent_code": "568"
    },
    "22330": {
        "name": "Vĩnh Hải",
        "type": "phuong",
        "slug": "vinh-hai",
        "name_with_type": "Phường Vĩnh Hải",
        "path": "Vĩnh Hải, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Vĩnh Hải, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22330",
        "parent_code": "568"
    },
    "22333": {
        "name": "Vĩnh Phước",
        "type": "phuong",
        "slug": "vinh-phuoc",
        "name_with_type": "Phường Vĩnh Phước",
        "path": "Vĩnh Phước, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Vĩnh Phước, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22333",
        "parent_code": "568"
    },
    "22336": {
        "name": "Ngọc Hiệp",
        "type": "phuong",
        "slug": "ngoc-hiep",
        "name_with_type": "Phường Ngọc Hiệp",
        "path": "Ngọc Hiệp, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Ngọc Hiệp, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22336",
        "parent_code": "568"
    },
    "22339": {
        "name": "Vĩnh Thọ",
        "type": "phuong",
        "slug": "vinh-tho",
        "name_with_type": "Phường Vĩnh Thọ",
        "path": "Vĩnh Thọ, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Vĩnh Thọ, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22339",
        "parent_code": "568"
    },
    "22342": {
        "name": "Xương Huân",
        "type": "phuong",
        "slug": "xuong-huan",
        "name_with_type": "Phường Xương Huân",
        "path": "Xương Huân, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Xương Huân, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22342",
        "parent_code": "568"
    },
    "22345": {
        "name": "Vạn Thắng",
        "type": "phuong",
        "slug": "van-thang",
        "name_with_type": "Phường Vạn Thắng",
        "path": "Vạn Thắng, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Vạn Thắng, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22345",
        "parent_code": "568"
    },
    "22348": {
        "name": "Vạn Thạnh",
        "type": "phuong",
        "slug": "van-thanh",
        "name_with_type": "Phường Vạn Thạnh",
        "path": "Vạn Thạnh, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Vạn Thạnh, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22348",
        "parent_code": "568"
    },
    "22351": {
        "name": "Phương Sài",
        "type": "phuong",
        "slug": "phuong-sai",
        "name_with_type": "Phường Phương Sài",
        "path": "Phương Sài, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Phương Sài, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22351",
        "parent_code": "568"
    },
    "22354": {
        "name": "Phương Sơn",
        "type": "phuong",
        "slug": "phuong-son",
        "name_with_type": "Phường Phương Sơn",
        "path": "Phương Sơn, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Phương Sơn, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22354",
        "parent_code": "568"
    },
    "22357": {
        "name": "Phước Hải",
        "type": "phuong",
        "slug": "phuoc-hai",
        "name_with_type": "Phường Phước Hải",
        "path": "Phước Hải, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Phước Hải, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22357",
        "parent_code": "568"
    },
    "22360": {
        "name": "Phước Tân",
        "type": "phuong",
        "slug": "phuoc-tan",
        "name_with_type": "Phường Phước Tân",
        "path": "Phước Tân, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Phước Tân, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22360",
        "parent_code": "568"
    },
    "22363": {
        "name": "Lộc Thọ",
        "type": "phuong",
        "slug": "loc-tho",
        "name_with_type": "Phường Lộc Thọ",
        "path": "Lộc Thọ, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Lộc Thọ, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22363",
        "parent_code": "568"
    },
    "22366": {
        "name": "Phước Tiến",
        "type": "phuong",
        "slug": "phuoc-tien",
        "name_with_type": "Phường Phước Tiến",
        "path": "Phước Tiến, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Phước Tiến, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22366",
        "parent_code": "568"
    },
    "22369": {
        "name": "Tân Lập",
        "type": "phuong",
        "slug": "tan-lap",
        "name_with_type": "Phường Tân Lập",
        "path": "Tân Lập, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Tân Lập, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22369",
        "parent_code": "568"
    },
    "22372": {
        "name": "Phước Hòa",
        "type": "phuong",
        "slug": "phuoc-hoa",
        "name_with_type": "Phường Phước Hòa",
        "path": "Phước Hòa, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Phước Hòa, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22372",
        "parent_code": "568"
    },
    "22375": {
        "name": "Vĩnh Nguyên",
        "type": "phuong",
        "slug": "vinh-nguyen",
        "name_with_type": "Phường Vĩnh Nguyên",
        "path": "Vĩnh Nguyên, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Vĩnh Nguyên, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22375",
        "parent_code": "568"
    },
    "22378": {
        "name": "Phước Long",
        "type": "phuong",
        "slug": "phuoc-long",
        "name_with_type": "Phường Phước Long",
        "path": "Phước Long, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Phước Long, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22378",
        "parent_code": "568"
    },
    "22381": {
        "name": "Vĩnh Trường",
        "type": "phuong",
        "slug": "vinh-truong",
        "name_with_type": "Phường Vĩnh Trường",
        "path": "Vĩnh Trường, Nha Trang, Khánh Hòa",
        "path_with_type": "Phường Vĩnh Trường, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22381",
        "parent_code": "568"
    },
    "22384": {
        "name": "Vĩnh Lương",
        "type": "xa",
        "slug": "vinh-luong",
        "name_with_type": "Xã Vĩnh Lương",
        "path": "Vĩnh Lương, Nha Trang, Khánh Hòa",
        "path_with_type": "Xã Vĩnh Lương, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22384",
        "parent_code": "568"
    },
    "22387": {
        "name": "Vĩnh Phương",
        "type": "xa",
        "slug": "vinh-phuong",
        "name_with_type": "Xã Vĩnh Phương",
        "path": "Vĩnh Phương, Nha Trang, Khánh Hòa",
        "path_with_type": "Xã Vĩnh Phương, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22387",
        "parent_code": "568"
    },
    "22390": {
        "name": "Vĩnh Ngọc",
        "type": "xa",
        "slug": "vinh-ngoc",
        "name_with_type": "Xã Vĩnh Ngọc",
        "path": "Vĩnh Ngọc, Nha Trang, Khánh Hòa",
        "path_with_type": "Xã Vĩnh Ngọc, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22390",
        "parent_code": "568"
    },
    "22393": {
        "name": "Vĩnh Thạnh",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thạnh",
        "path": "Vĩnh Thạnh, Nha Trang, Khánh Hòa",
        "path_with_type": "Xã Vĩnh Thạnh, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22393",
        "parent_code": "568"
    },
    "22396": {
        "name": "Vĩnh Trung",
        "type": "xa",
        "slug": "vinh-trung",
        "name_with_type": "Xã Vĩnh Trung",
        "path": "Vĩnh Trung, Nha Trang, Khánh Hòa",
        "path_with_type": "Xã Vĩnh Trung, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22396",
        "parent_code": "568"
    },
    "22399": {
        "name": "Vĩnh Hiệp",
        "type": "xa",
        "slug": "vinh-hiep",
        "name_with_type": "Xã Vĩnh Hiệp",
        "path": "Vĩnh Hiệp, Nha Trang, Khánh Hòa",
        "path_with_type": "Xã Vĩnh Hiệp, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22399",
        "parent_code": "568"
    },
    "22402": {
        "name": "Vĩnh Thái",
        "type": "xa",
        "slug": "vinh-thai",
        "name_with_type": "Xã Vĩnh Thái",
        "path": "Vĩnh Thái, Nha Trang, Khánh Hòa",
        "path_with_type": "Xã Vĩnh Thái, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22402",
        "parent_code": "568"
    },
    "22405": {
        "name": "Phước Đồng",
        "type": "xa",
        "slug": "phuoc-dong",
        "name_with_type": "Xã Phước Đồng",
        "path": "Phước Đồng, Nha Trang, Khánh Hòa",
        "path_with_type": "Xã Phước Đồng, Thành phố Nha Trang, Tỉnh Khánh Hòa",
        "code": "22405",
        "parent_code": "568"
    },
    "22408": {
        "name": "Cam Nghĩa",
        "type": "phuong",
        "slug": "cam-nghia",
        "name_with_type": "Phường Cam Nghĩa",
        "path": "Cam Nghĩa, Cam Ranh, Khánh Hòa",
        "path_with_type": "Phường Cam Nghĩa, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22408",
        "parent_code": "569"
    },
    "22411": {
        "name": "Cam Phúc Bắc",
        "type": "phuong",
        "slug": "cam-phuc-bac",
        "name_with_type": "Phường Cam Phúc Bắc",
        "path": "Cam Phúc Bắc, Cam Ranh, Khánh Hòa",
        "path_with_type": "Phường Cam Phúc Bắc, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22411",
        "parent_code": "569"
    },
    "22414": {
        "name": "Cam Phúc Nam",
        "type": "phuong",
        "slug": "cam-phuc-nam",
        "name_with_type": "Phường Cam Phúc Nam",
        "path": "Cam Phúc Nam, Cam Ranh, Khánh Hòa",
        "path_with_type": "Phường Cam Phúc Nam, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22414",
        "parent_code": "569"
    },
    "22417": {
        "name": "Cam Lộc",
        "type": "phuong",
        "slug": "cam-loc",
        "name_with_type": "Phường Cam Lộc",
        "path": "Cam Lộc, Cam Ranh, Khánh Hòa",
        "path_with_type": "Phường Cam Lộc, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22417",
        "parent_code": "569"
    },
    "22420": {
        "name": "Cam Phú",
        "type": "phuong",
        "slug": "cam-phu",
        "name_with_type": "Phường Cam Phú",
        "path": "Cam Phú, Cam Ranh, Khánh Hòa",
        "path_with_type": "Phường Cam Phú, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22420",
        "parent_code": "569"
    },
    "22423": {
        "name": "Ba Ngòi",
        "type": "phuong",
        "slug": "ba-ngoi",
        "name_with_type": "Phường Ba Ngòi",
        "path": "Ba Ngòi, Cam Ranh, Khánh Hòa",
        "path_with_type": "Phường Ba Ngòi, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22423",
        "parent_code": "569"
    },
    "22426": {
        "name": "Cam Thuận",
        "type": "phuong",
        "slug": "cam-thuan",
        "name_with_type": "Phường Cam Thuận",
        "path": "Cam Thuận, Cam Ranh, Khánh Hòa",
        "path_with_type": "Phường Cam Thuận, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22426",
        "parent_code": "569"
    },
    "22429": {
        "name": "Cam Lợi",
        "type": "phuong",
        "slug": "cam-loi",
        "name_with_type": "Phường Cam Lợi",
        "path": "Cam Lợi, Cam Ranh, Khánh Hòa",
        "path_with_type": "Phường Cam Lợi, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22429",
        "parent_code": "569"
    },
    "22432": {
        "name": "Cam Linh",
        "type": "phuong",
        "slug": "cam-linh",
        "name_with_type": "Phường Cam Linh",
        "path": "Cam Linh, Cam Ranh, Khánh Hòa",
        "path_with_type": "Phường Cam Linh, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22432",
        "parent_code": "569"
    },
    "22468": {
        "name": "Cam Thành Nam",
        "type": "xa",
        "slug": "cam-thanh-nam",
        "name_with_type": "Xã Cam Thành Nam",
        "path": "Cam Thành Nam, Cam Ranh, Khánh Hòa",
        "path_with_type": "Xã Cam Thành Nam, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22468",
        "parent_code": "569"
    },
    "22474": {
        "name": "Cam Phước Đông",
        "type": "xa",
        "slug": "cam-phuoc-dong",
        "name_with_type": "Xã Cam Phước Đông",
        "path": "Cam Phước Đông, Cam Ranh, Khánh Hòa",
        "path_with_type": "Xã Cam Phước Đông, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22474",
        "parent_code": "569"
    },
    "22477": {
        "name": "Cam Thịnh Tây",
        "type": "xa",
        "slug": "cam-thinh-tay",
        "name_with_type": "Xã Cam Thịnh Tây",
        "path": "Cam Thịnh Tây, Cam Ranh, Khánh Hòa",
        "path_with_type": "Xã Cam Thịnh Tây, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22477",
        "parent_code": "569"
    },
    "22480": {
        "name": "Cam Thịnh Đông",
        "type": "xa",
        "slug": "cam-thinh-dong",
        "name_with_type": "Xã Cam Thịnh Đông",
        "path": "Cam Thịnh Đông, Cam Ranh, Khánh Hòa",
        "path_with_type": "Xã Cam Thịnh Đông, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22480",
        "parent_code": "569"
    },
    "22483": {
        "name": "Cam Lập",
        "type": "xa",
        "slug": "cam-lap",
        "name_with_type": "Xã Cam Lập",
        "path": "Cam Lập, Cam Ranh, Khánh Hòa",
        "path_with_type": "Xã Cam Lập, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22483",
        "parent_code": "569"
    },
    "22486": {
        "name": "Cam Bình",
        "type": "xa",
        "slug": "cam-binh",
        "name_with_type": "Xã Cam Bình",
        "path": "Cam Bình, Cam Ranh, Khánh Hòa",
        "path_with_type": "Xã Cam Bình, Thành phố Cam Ranh, Tỉnh Khánh Hòa",
        "code": "22486",
        "parent_code": "569"
    },
    "22435": {
        "name": "Cam Tân",
        "type": "xa",
        "slug": "cam-tan",
        "name_with_type": "Xã Cam Tân",
        "path": "Cam Tân, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam Tân, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22435",
        "parent_code": "570"
    },
    "22438": {
        "name": "Cam Hòa",
        "type": "xa",
        "slug": "cam-hoa",
        "name_with_type": "Xã Cam Hòa",
        "path": "Cam Hòa, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam Hòa, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22438",
        "parent_code": "570"
    },
    "22441": {
        "name": "Cam Hải Đông",
        "type": "xa",
        "slug": "cam-hai-dong",
        "name_with_type": "Xã Cam Hải Đông",
        "path": "Cam Hải Đông, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam Hải Đông, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22441",
        "parent_code": "570"
    },
    "22444": {
        "name": "Cam Hải Tây",
        "type": "xa",
        "slug": "cam-hai-tay",
        "name_with_type": "Xã Cam Hải Tây",
        "path": "Cam Hải Tây, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam Hải Tây, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22444",
        "parent_code": "570"
    },
    "22447": {
        "name": "Sơn Tân",
        "type": "xa",
        "slug": "son-tan",
        "name_with_type": "Xã Sơn Tân",
        "path": "Sơn Tân, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Sơn Tân, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22447",
        "parent_code": "570"
    },
    "22450": {
        "name": "Cam Hiệp Bắc",
        "type": "xa",
        "slug": "cam-hiep-bac",
        "name_with_type": "Xã Cam Hiệp Bắc",
        "path": "Cam Hiệp Bắc, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam Hiệp Bắc, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22450",
        "parent_code": "570"
    },
    "22453": {
        "name": "Cam Đức",
        "type": "thi-tran",
        "slug": "cam-duc",
        "name_with_type": "Thị trấn Cam Đức",
        "path": "Cam Đức, Cam Lâm, Khánh Hòa",
        "path_with_type": "Thị trấn Cam Đức, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22453",
        "parent_code": "570"
    },
    "22456": {
        "name": "Cam Hiệp Nam",
        "type": "xa",
        "slug": "cam-hiep-nam",
        "name_with_type": "Xã Cam Hiệp Nam",
        "path": "Cam Hiệp Nam, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam Hiệp Nam, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22456",
        "parent_code": "570"
    },
    "22459": {
        "name": "Cam Phước Tây",
        "type": "xa",
        "slug": "cam-phuoc-tay",
        "name_with_type": "Xã Cam Phước Tây",
        "path": "Cam Phước Tây, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam Phước Tây, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22459",
        "parent_code": "570"
    },
    "22462": {
        "name": "Cam Thành Bắc",
        "type": "xa",
        "slug": "cam-thanh-bac",
        "name_with_type": "Xã Cam Thành Bắc",
        "path": "Cam Thành Bắc, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam Thành Bắc, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22462",
        "parent_code": "570"
    },
    "22465": {
        "name": "Cam An Bắc",
        "type": "xa",
        "slug": "cam-an-bac",
        "name_with_type": "Xã Cam An Bắc",
        "path": "Cam An Bắc, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam An Bắc, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22465",
        "parent_code": "570"
    },
    "22471": {
        "name": "Cam An Nam",
        "type": "xa",
        "slug": "cam-an-nam",
        "name_with_type": "Xã Cam An Nam",
        "path": "Cam An Nam, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Cam An Nam, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22471",
        "parent_code": "570"
    },
    "22708": {
        "name": "Suối Cát",
        "type": "xa",
        "slug": "suoi-cat",
        "name_with_type": "Xã Suối Cát",
        "path": "Suối Cát, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Suối Cát, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22708",
        "parent_code": "570"
    },
    "22711": {
        "name": "Suối Tân",
        "type": "xa",
        "slug": "suoi-tan",
        "name_with_type": "Xã Suối Tân",
        "path": "Suối Tân, Cam Lâm, Khánh Hòa",
        "path_with_type": "Xã Suối Tân, Huyện Cam Lâm, Tỉnh Khánh Hòa",
        "code": "22711",
        "parent_code": "570"
    },
    "22489": {
        "name": "Vạn Giã",
        "type": "thi-tran",
        "slug": "van-gia",
        "name_with_type": "Thị trấn Vạn Giã",
        "path": "Vạn Giã, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Thị trấn Vạn Giã, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22489",
        "parent_code": "571"
    },
    "22492": {
        "name": "Đại Lãnh",
        "type": "xa",
        "slug": "dai-lanh",
        "name_with_type": "Xã Đại Lãnh",
        "path": "Đại Lãnh, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Đại Lãnh, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22492",
        "parent_code": "571"
    },
    "22495": {
        "name": "Vạn Phước",
        "type": "xa",
        "slug": "van-phuoc",
        "name_with_type": "Xã Vạn Phước",
        "path": "Vạn Phước, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Phước, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22495",
        "parent_code": "571"
    },
    "22498": {
        "name": "Vạn Long",
        "type": "xa",
        "slug": "van-long",
        "name_with_type": "Xã Vạn Long",
        "path": "Vạn Long, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Long, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22498",
        "parent_code": "571"
    },
    "22501": {
        "name": "Vạn Bình",
        "type": "xa",
        "slug": "van-binh",
        "name_with_type": "Xã Vạn Bình",
        "path": "Vạn Bình, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Bình, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22501",
        "parent_code": "571"
    },
    "22504": {
        "name": "Vạn Thọ",
        "type": "xa",
        "slug": "van-tho",
        "name_with_type": "Xã Vạn Thọ",
        "path": "Vạn Thọ, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Thọ, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22504",
        "parent_code": "571"
    },
    "22507": {
        "name": "Vạn Khánh",
        "type": "xa",
        "slug": "van-khanh",
        "name_with_type": "Xã Vạn Khánh",
        "path": "Vạn Khánh, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Khánh, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22507",
        "parent_code": "571"
    },
    "22510": {
        "name": "Vạn Phú",
        "type": "xa",
        "slug": "van-phu",
        "name_with_type": "Xã Vạn Phú",
        "path": "Vạn Phú, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Phú, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22510",
        "parent_code": "571"
    },
    "22513": {
        "name": "Vạn Lương",
        "type": "xa",
        "slug": "van-luong",
        "name_with_type": "Xã Vạn Lương",
        "path": "Vạn Lương, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Lương, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22513",
        "parent_code": "571"
    },
    "22516": {
        "name": "Vạn Thắng",
        "type": "xa",
        "slug": "van-thang",
        "name_with_type": "Xã Vạn Thắng",
        "path": "Vạn Thắng, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Thắng, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22516",
        "parent_code": "571"
    },
    "22519": {
        "name": "Vạn Thạnh",
        "type": "xa",
        "slug": "van-thanh",
        "name_with_type": "Xã Vạn Thạnh",
        "path": "Vạn Thạnh, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Thạnh, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22519",
        "parent_code": "571"
    },
    "22522": {
        "name": "Xuân Sơn",
        "type": "xa",
        "slug": "xuan-son",
        "name_with_type": "Xã Xuân Sơn",
        "path": "Xuân Sơn, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Xuân Sơn, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22522",
        "parent_code": "571"
    },
    "22525": {
        "name": "Vạn Hưng",
        "type": "xa",
        "slug": "van-hung",
        "name_with_type": "Xã Vạn Hưng",
        "path": "Vạn Hưng, Vạn Ninh, Khánh Hòa",
        "path_with_type": "Xã Vạn Hưng, Huyện Vạn Ninh, Tỉnh Khánh Hòa",
        "code": "22525",
        "parent_code": "571"
    },
    "22528": {
        "name": "Ninh Hiệp",
        "type": "phuong",
        "slug": "ninh-hiep",
        "name_with_type": "Phường Ninh Hiệp",
        "path": "Ninh Hiệp, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Phường Ninh Hiệp, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22528",
        "parent_code": "572"
    },
    "22531": {
        "name": "Ninh Sơn",
        "type": "xa",
        "slug": "ninh-son",
        "name_with_type": "Xã Ninh Sơn",
        "path": "Ninh Sơn, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Sơn, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22531",
        "parent_code": "572"
    },
    "22534": {
        "name": "Ninh Tây",
        "type": "xa",
        "slug": "ninh-tay",
        "name_with_type": "Xã Ninh Tây",
        "path": "Ninh Tây, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Tây, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22534",
        "parent_code": "572"
    },
    "22537": {
        "name": "Ninh Thượng",
        "type": "xa",
        "slug": "ninh-thuong",
        "name_with_type": "Xã Ninh Thượng",
        "path": "Ninh Thượng, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Thượng, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22537",
        "parent_code": "572"
    },
    "22540": {
        "name": "Ninh An",
        "type": "xa",
        "slug": "ninh-an",
        "name_with_type": "Xã Ninh An",
        "path": "Ninh An, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh An, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22540",
        "parent_code": "572"
    },
    "22543": {
        "name": "Ninh Hải",
        "type": "phuong",
        "slug": "ninh-hai",
        "name_with_type": "Phường Ninh Hải",
        "path": "Ninh Hải, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Phường Ninh Hải, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22543",
        "parent_code": "572"
    },
    "22546": {
        "name": "Ninh Thọ",
        "type": "xa",
        "slug": "ninh-tho",
        "name_with_type": "Xã Ninh Thọ",
        "path": "Ninh Thọ, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Thọ, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22546",
        "parent_code": "572"
    },
    "22549": {
        "name": "Ninh Trung",
        "type": "xa",
        "slug": "ninh-trung",
        "name_with_type": "Xã Ninh Trung",
        "path": "Ninh Trung, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Trung, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22549",
        "parent_code": "572"
    },
    "22552": {
        "name": "Ninh Sim",
        "type": "xa",
        "slug": "ninh-sim",
        "name_with_type": "Xã Ninh Sim",
        "path": "Ninh Sim, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Sim, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22552",
        "parent_code": "572"
    },
    "22555": {
        "name": "Ninh Xuân",
        "type": "xa",
        "slug": "ninh-xuan",
        "name_with_type": "Xã Ninh Xuân",
        "path": "Ninh Xuân, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Xuân, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22555",
        "parent_code": "572"
    },
    "22558": {
        "name": "Ninh Thân",
        "type": "xa",
        "slug": "ninh-than",
        "name_with_type": "Xã Ninh Thân",
        "path": "Ninh Thân, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Thân, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22558",
        "parent_code": "572"
    },
    "22561": {
        "name": "Ninh Diêm",
        "type": "phuong",
        "slug": "ninh-diem",
        "name_with_type": "Phường Ninh Diêm",
        "path": "Ninh Diêm, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Phường Ninh Diêm, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22561",
        "parent_code": "572"
    },
    "22564": {
        "name": "Ninh Đông",
        "type": "xa",
        "slug": "ninh-dong",
        "name_with_type": "Xã Ninh Đông",
        "path": "Ninh Đông, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Đông, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22564",
        "parent_code": "572"
    },
    "22567": {
        "name": "Ninh Thủy",
        "type": "phuong",
        "slug": "ninh-thuy",
        "name_with_type": "Phường Ninh Thủy",
        "path": "Ninh Thủy, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Phường Ninh Thủy, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22567",
        "parent_code": "572"
    },
    "22570": {
        "name": "Ninh Đa",
        "type": "phuong",
        "slug": "ninh-da",
        "name_with_type": "Phường Ninh Đa",
        "path": "Ninh Đa, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Phường Ninh Đa, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22570",
        "parent_code": "572"
    },
    "22573": {
        "name": "Ninh Phụng",
        "type": "xa",
        "slug": "ninh-phung",
        "name_with_type": "Xã Ninh Phụng",
        "path": "Ninh Phụng, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Phụng, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22573",
        "parent_code": "572"
    },
    "22576": {
        "name": "Ninh Bình",
        "type": "xa",
        "slug": "ninh-binh",
        "name_with_type": "Xã Ninh Bình",
        "path": "Ninh Bình, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Bình, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22576",
        "parent_code": "572"
    },
    "22579": {
        "name": "Ninh Phước",
        "type": "xa",
        "slug": "ninh-phuoc",
        "name_with_type": "Xã Ninh Phước",
        "path": "Ninh Phước, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Phước, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22579",
        "parent_code": "572"
    },
    "22582": {
        "name": "Ninh Phú",
        "type": "xa",
        "slug": "ninh-phu",
        "name_with_type": "Xã Ninh Phú",
        "path": "Ninh Phú, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Phú, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22582",
        "parent_code": "572"
    },
    "22585": {
        "name": "Ninh Tân",
        "type": "xa",
        "slug": "ninh-tan",
        "name_with_type": "Xã Ninh Tân",
        "path": "Ninh Tân, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Tân, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22585",
        "parent_code": "572"
    },
    "22588": {
        "name": "Ninh Quang",
        "type": "xa",
        "slug": "ninh-quang",
        "name_with_type": "Xã Ninh Quang",
        "path": "Ninh Quang, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Quang, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22588",
        "parent_code": "572"
    },
    "22591": {
        "name": "Ninh Giang",
        "type": "phuong",
        "slug": "ninh-giang",
        "name_with_type": "Phường Ninh Giang",
        "path": "Ninh Giang, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Phường Ninh Giang, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22591",
        "parent_code": "572"
    },
    "22594": {
        "name": "Ninh Hà",
        "type": "phuong",
        "slug": "ninh-ha",
        "name_with_type": "Phường Ninh Hà",
        "path": "Ninh Hà, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Phường Ninh Hà, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22594",
        "parent_code": "572"
    },
    "22597": {
        "name": "Ninh Hưng",
        "type": "xa",
        "slug": "ninh-hung",
        "name_with_type": "Xã Ninh Hưng",
        "path": "Ninh Hưng, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Hưng, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22597",
        "parent_code": "572"
    },
    "22600": {
        "name": "Ninh Lộc",
        "type": "xa",
        "slug": "ninh-loc",
        "name_with_type": "Xã Ninh Lộc",
        "path": "Ninh Lộc, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Lộc, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22600",
        "parent_code": "572"
    },
    "22603": {
        "name": "Ninh Ích",
        "type": "xa",
        "slug": "ninh-ich",
        "name_with_type": "Xã Ninh Ích",
        "path": "Ninh Ích, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Ích, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22603",
        "parent_code": "572"
    },
    "22606": {
        "name": "Ninh Vân",
        "type": "xa",
        "slug": "ninh-van",
        "name_with_type": "Xã Ninh Vân",
        "path": "Ninh Vân, Ninh Hòa, Khánh Hòa",
        "path_with_type": "Xã Ninh Vân, Thị xã Ninh Hòa, Tỉnh Khánh Hòa",
        "code": "22606",
        "parent_code": "572"
    },
    "22609": {
        "name": "Khánh Vĩnh",
        "type": "thi-tran",
        "slug": "khanh-vinh",
        "name_with_type": "Thị trấn Khánh Vĩnh",
        "path": "Khánh Vĩnh, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Thị trấn Khánh Vĩnh, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22609",
        "parent_code": "573"
    },
    "22612": {
        "name": "Khánh Hiệp",
        "type": "xa",
        "slug": "khanh-hiep",
        "name_with_type": "Xã Khánh Hiệp",
        "path": "Khánh Hiệp, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Khánh Hiệp, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22612",
        "parent_code": "573"
    },
    "22615": {
        "name": "Khánh Bình",
        "type": "xa",
        "slug": "khanh-binh",
        "name_with_type": "Xã Khánh Bình",
        "path": "Khánh Bình, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Khánh Bình, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22615",
        "parent_code": "573"
    },
    "22618": {
        "name": "Khánh Trung",
        "type": "xa",
        "slug": "khanh-trung",
        "name_with_type": "Xã Khánh Trung",
        "path": "Khánh Trung, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Khánh Trung, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22618",
        "parent_code": "573"
    },
    "22621": {
        "name": "Khánh Đông",
        "type": "xa",
        "slug": "khanh-dong",
        "name_with_type": "Xã Khánh Đông",
        "path": "Khánh Đông, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Khánh Đông, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22621",
        "parent_code": "573"
    },
    "22624": {
        "name": "Khánh Thượng",
        "type": "xa",
        "slug": "khanh-thuong",
        "name_with_type": "Xã Khánh Thượng",
        "path": "Khánh Thượng, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Khánh Thượng, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22624",
        "parent_code": "573"
    },
    "22627": {
        "name": "Khánh Nam",
        "type": "xa",
        "slug": "khanh-nam",
        "name_with_type": "Xã Khánh Nam",
        "path": "Khánh Nam, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Khánh Nam, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22627",
        "parent_code": "573"
    },
    "22630": {
        "name": "Sông Cầu",
        "type": "xa",
        "slug": "song-cau",
        "name_with_type": "Xã Sông Cầu",
        "path": "Sông Cầu, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Sông Cầu, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22630",
        "parent_code": "573"
    },
    "22633": {
        "name": "Giang Ly",
        "type": "xa",
        "slug": "giang-ly",
        "name_with_type": "Xã Giang Ly",
        "path": "Giang Ly, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Giang Ly, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22633",
        "parent_code": "573"
    },
    "22636": {
        "name": "Cầu Bà",
        "type": "xa",
        "slug": "cau-ba",
        "name_with_type": "Xã Cầu Bà",
        "path": "Cầu Bà, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Cầu Bà, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22636",
        "parent_code": "573"
    },
    "22639": {
        "name": "Liên Sang",
        "type": "xa",
        "slug": "lien-sang",
        "name_with_type": "Xã Liên Sang",
        "path": "Liên Sang, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Liên Sang, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22639",
        "parent_code": "573"
    },
    "22642": {
        "name": "Khánh Thành",
        "type": "xa",
        "slug": "khanh-thanh",
        "name_with_type": "Xã Khánh Thành",
        "path": "Khánh Thành, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Khánh Thành, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22642",
        "parent_code": "573"
    },
    "22645": {
        "name": "Khánh Phú",
        "type": "xa",
        "slug": "khanh-phu",
        "name_with_type": "Xã Khánh Phú",
        "path": "Khánh Phú, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Khánh Phú, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22645",
        "parent_code": "573"
    },
    "22648": {
        "name": "Sơn Thái",
        "type": "xa",
        "slug": "son-thai",
        "name_with_type": "Xã Sơn Thái",
        "path": "Sơn Thái, Khánh Vĩnh, Khánh Hòa",
        "path_with_type": "Xã Sơn Thái, Huyện Khánh Vĩnh, Tỉnh Khánh Hòa",
        "code": "22648",
        "parent_code": "573"
    },
    "22651": {
        "name": "Diên Khánh",
        "type": "thi-tran",
        "slug": "dien-khanh",
        "name_with_type": "Thị trấn Diên Khánh",
        "path": "Diên Khánh, Diên Khánh, Khánh Hòa",
        "path_with_type": "Thị trấn Diên Khánh, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22651",
        "parent_code": "574"
    },
    "22654": {
        "name": "Diên Lâm",
        "type": "xa",
        "slug": "dien-lam",
        "name_with_type": "Xã Diên Lâm",
        "path": "Diên Lâm, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Lâm, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22654",
        "parent_code": "574"
    },
    "22657": {
        "name": "Diên Điền",
        "type": "xa",
        "slug": "dien-dien",
        "name_with_type": "Xã Diên Điền",
        "path": "Diên Điền, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Điền, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22657",
        "parent_code": "574"
    },
    "22660": {
        "name": "Diên Xuân",
        "type": "xa",
        "slug": "dien-xuan",
        "name_with_type": "Xã Diên Xuân",
        "path": "Diên Xuân, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Xuân, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22660",
        "parent_code": "574"
    },
    "22663": {
        "name": "Diên Sơn",
        "type": "xa",
        "slug": "dien-son",
        "name_with_type": "Xã Diên Sơn",
        "path": "Diên Sơn, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Sơn, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22663",
        "parent_code": "574"
    },
    "22666": {
        "name": "Diên Đồng",
        "type": "xa",
        "slug": "dien-dong",
        "name_with_type": "Xã Diên Đồng",
        "path": "Diên Đồng, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Đồng, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22666",
        "parent_code": "574"
    },
    "22669": {
        "name": "Diên Phú",
        "type": "xa",
        "slug": "dien-phu",
        "name_with_type": "Xã Diên Phú",
        "path": "Diên Phú, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Phú, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22669",
        "parent_code": "574"
    },
    "22672": {
        "name": "Diên Thọ",
        "type": "xa",
        "slug": "dien-tho",
        "name_with_type": "Xã Diên Thọ",
        "path": "Diên Thọ, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Thọ, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22672",
        "parent_code": "574"
    },
    "22675": {
        "name": "Diên Phước",
        "type": "xa",
        "slug": "dien-phuoc",
        "name_with_type": "Xã Diên Phước",
        "path": "Diên Phước, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Phước, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22675",
        "parent_code": "574"
    },
    "22678": {
        "name": "Diên Lạc",
        "type": "xa",
        "slug": "dien-lac",
        "name_with_type": "Xã Diên Lạc",
        "path": "Diên Lạc, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Lạc, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22678",
        "parent_code": "574"
    },
    "22681": {
        "name": "Diên Tân",
        "type": "xa",
        "slug": "dien-tan",
        "name_with_type": "Xã Diên Tân",
        "path": "Diên Tân, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Tân, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22681",
        "parent_code": "574"
    },
    "22684": {
        "name": "Diên Hòa",
        "type": "xa",
        "slug": "dien-hoa",
        "name_with_type": "Xã Diên Hòa",
        "path": "Diên Hòa, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Hòa, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22684",
        "parent_code": "574"
    },
    "22687": {
        "name": "Diên Thạnh",
        "type": "xa",
        "slug": "dien-thanh",
        "name_with_type": "Xã Diên Thạnh",
        "path": "Diên Thạnh, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Thạnh, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22687",
        "parent_code": "574"
    },
    "22690": {
        "name": "Diên Toàn",
        "type": "xa",
        "slug": "dien-toan",
        "name_with_type": "Xã Diên Toàn",
        "path": "Diên Toàn, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Toàn, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22690",
        "parent_code": "574"
    },
    "22693": {
        "name": "Diên An",
        "type": "xa",
        "slug": "dien-an",
        "name_with_type": "Xã Diên An",
        "path": "Diên An, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên An, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22693",
        "parent_code": "574"
    },
    "22696": {
        "name": "Diên Bình",
        "type": "xa",
        "slug": "dien-binh",
        "name_with_type": "Xã Diên Bình",
        "path": "Diên Bình, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Bình, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22696",
        "parent_code": "574"
    },
    "22699": {
        "name": "Diên Lộc",
        "type": "xa",
        "slug": "dien-loc",
        "name_with_type": "Xã Diên Lộc",
        "path": "Diên Lộc, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Diên Lộc, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22699",
        "parent_code": "574"
    },
    "22702": {
        "name": "Suối Hiệp",
        "type": "xa",
        "slug": "suoi-hiep",
        "name_with_type": "Xã Suối Hiệp",
        "path": "Suối Hiệp, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Suối Hiệp, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22702",
        "parent_code": "574"
    },
    "22705": {
        "name": "Suối Tiên",
        "type": "xa",
        "slug": "suoi-tien",
        "name_with_type": "Xã Suối Tiên",
        "path": "Suối Tiên, Diên Khánh, Khánh Hòa",
        "path_with_type": "Xã Suối Tiên, Huyện Diên Khánh, Tỉnh Khánh Hòa",
        "code": "22705",
        "parent_code": "574"
    },
    "22714": {
        "name": "Tô Hạp",
        "type": "thi-tran",
        "slug": "to-hap",
        "name_with_type": "Thị trấn Tô Hạp",
        "path": "Tô Hạp, Khánh Sơn, Khánh Hòa",
        "path_with_type": "Thị trấn Tô Hạp, Huyện Khánh Sơn, Tỉnh Khánh Hòa",
        "code": "22714",
        "parent_code": "575"
    },
    "22717": {
        "name": "Thành Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thành Sơn",
        "path": "Thành Sơn, Khánh Sơn, Khánh Hòa",
        "path_with_type": "Xã Thành Sơn, Huyện Khánh Sơn, Tỉnh Khánh Hòa",
        "code": "22717",
        "parent_code": "575"
    },
    "22720": {
        "name": "Sơn Lâm",
        "type": "xa",
        "slug": "son-lam",
        "name_with_type": "Xã Sơn Lâm",
        "path": "Sơn Lâm, Khánh Sơn, Khánh Hòa",
        "path_with_type": "Xã Sơn Lâm, Huyện Khánh Sơn, Tỉnh Khánh Hòa",
        "code": "22720",
        "parent_code": "575"
    },
    "22723": {
        "name": "Sơn Hiệp",
        "type": "xa",
        "slug": "son-hiep",
        "name_with_type": "Xã Sơn Hiệp",
        "path": "Sơn Hiệp, Khánh Sơn, Khánh Hòa",
        "path_with_type": "Xã Sơn Hiệp, Huyện Khánh Sơn, Tỉnh Khánh Hòa",
        "code": "22723",
        "parent_code": "575"
    },
    "22726": {
        "name": "Sơn Bình",
        "type": "xa",
        "slug": "son-binh",
        "name_with_type": "Xã Sơn Bình",
        "path": "Sơn Bình, Khánh Sơn, Khánh Hòa",
        "path_with_type": "Xã Sơn Bình, Huyện Khánh Sơn, Tỉnh Khánh Hòa",
        "code": "22726",
        "parent_code": "575"
    },
    "22729": {
        "name": "Sơn Trung",
        "type": "xa",
        "slug": "son-trung",
        "name_with_type": "Xã Sơn Trung",
        "path": "Sơn Trung, Khánh Sơn, Khánh Hòa",
        "path_with_type": "Xã Sơn Trung, Huyện Khánh Sơn, Tỉnh Khánh Hòa",
        "code": "22729",
        "parent_code": "575"
    },
    "22732": {
        "name": "Ba Cụm Bắc",
        "type": "xa",
        "slug": "ba-cum-bac",
        "name_with_type": "Xã Ba Cụm Bắc",
        "path": "Ba Cụm Bắc, Khánh Sơn, Khánh Hòa",
        "path_with_type": "Xã Ba Cụm Bắc, Huyện Khánh Sơn, Tỉnh Khánh Hòa",
        "code": "22732",
        "parent_code": "575"
    },
    "22735": {
        "name": "Ba Cụm Nam",
        "type": "xa",
        "slug": "ba-cum-nam",
        "name_with_type": "Xã Ba Cụm Nam",
        "path": "Ba Cụm Nam, Khánh Sơn, Khánh Hòa",
        "path_with_type": "Xã Ba Cụm Nam, Huyện Khánh Sơn, Tỉnh Khánh Hòa",
        "code": "22735",
        "parent_code": "575"
    },
    "22736": {
        "name": "Trường Sa",
        "type": "thi-tran",
        "slug": "truong-sa",
        "name_with_type": "Thị trấn Trường Sa",
        "path": "Trường Sa, Trường Sa, Khánh Hòa",
        "path_with_type": "Thị trấn Trường Sa, Huyện Trường Sa, Tỉnh Khánh Hòa",
        "code": "22736",
        "parent_code": "576"
    },
    "22737": {
        "name": "Song Tử Tây",
        "type": "xa",
        "slug": "song-tu-tay",
        "name_with_type": "Xã Song Tử Tây",
        "path": "Song Tử Tây, Trường Sa, Khánh Hòa",
        "path_with_type": "Xã Song Tử Tây, Huyện Trường Sa, Tỉnh Khánh Hòa",
        "code": "22737",
        "parent_code": "576"
    },
    "22739": {
        "name": "Sinh Tồn",
        "type": "xa",
        "slug": "sinh-ton",
        "name_with_type": "Xã Sinh Tồn",
        "path": "Sinh Tồn, Trường Sa, Khánh Hòa",
        "path_with_type": "Xã Sinh Tồn, Huyện Trường Sa, Tỉnh Khánh Hòa",
        "code": "22739",
        "parent_code": "576"
    },
    "22915": {
        "name": "Mũi Né",
        "type": "phuong",
        "slug": "mui-ne",
        "name_with_type": "Phường Mũi Né",
        "path": "Mũi Né, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Mũi Né, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22915",
        "parent_code": "593"
    },
    "22918": {
        "name": "Hàm Tiến",
        "type": "phuong",
        "slug": "ham-tien",
        "name_with_type": "Phường Hàm Tiến",
        "path": "Hàm Tiến, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Hàm Tiến, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22918",
        "parent_code": "593"
    },
    "22921": {
        "name": "Phú Hài",
        "type": "phuong",
        "slug": "phu-hai",
        "name_with_type": "Phường Phú Hài",
        "path": "Phú Hài, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Phú Hài, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22921",
        "parent_code": "593"
    },
    "22924": {
        "name": "Phú Thủy",
        "type": "phuong",
        "slug": "phu-thuy",
        "name_with_type": "Phường Phú Thủy",
        "path": "Phú Thủy, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Phú Thủy, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22924",
        "parent_code": "593"
    },
    "22927": {
        "name": "Phú Tài",
        "type": "phuong",
        "slug": "phu-tai",
        "name_with_type": "Phường Phú Tài",
        "path": "Phú Tài, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Phú Tài, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22927",
        "parent_code": "593"
    },
    "22930": {
        "name": "Phú Trinh",
        "type": "phuong",
        "slug": "phu-trinh",
        "name_with_type": "Phường Phú Trinh",
        "path": "Phú Trinh, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Phú Trinh, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22930",
        "parent_code": "593"
    },
    "22933": {
        "name": "Xuân An",
        "type": "phuong",
        "slug": "xuan-an",
        "name_with_type": "Phường Xuân An",
        "path": "Xuân An, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Xuân An, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22933",
        "parent_code": "593"
    },
    "22936": {
        "name": "Thanh Hải",
        "type": "phuong",
        "slug": "thanh-hai",
        "name_with_type": "Phường Thanh Hải",
        "path": "Thanh Hải, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Thanh Hải, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22936",
        "parent_code": "593"
    },
    "22939": {
        "name": "Bình Hưng",
        "type": "phuong",
        "slug": "binh-hung",
        "name_with_type": "Phường Bình Hưng",
        "path": "Bình Hưng, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Bình Hưng, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22939",
        "parent_code": "593"
    },
    "22942": {
        "name": "Đức Nghĩa",
        "type": "phuong",
        "slug": "duc-nghia",
        "name_with_type": "Phường Đức Nghĩa",
        "path": "Đức Nghĩa, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Đức Nghĩa, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22942",
        "parent_code": "593"
    },
    "22945": {
        "name": "Lạc Đạo",
        "type": "phuong",
        "slug": "lac-dao",
        "name_with_type": "Phường Lạc Đạo",
        "path": "Lạc Đạo, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Lạc Đạo, Thành ph��� Phan Thiết, Tỉnh Bình Thuận",
        "code": "22945",
        "parent_code": "593"
    },
    "22948": {
        "name": "Đức Thắng",
        "type": "phuong",
        "slug": "duc-thang",
        "name_with_type": "Phường Đức Thắng",
        "path": "Đức Thắng, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Đức Thắng, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22948",
        "parent_code": "593"
    },
    "22951": {
        "name": "Hưng Long",
        "type": "phuong",
        "slug": "hung-long",
        "name_with_type": "Phường Hưng Long",
        "path": "Hưng Long, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Hưng Long, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22951",
        "parent_code": "593"
    },
    "22954": {
        "name": "Đức Long",
        "type": "phuong",
        "slug": "duc-long",
        "name_with_type": "Phường Đức Long",
        "path": "Đức Long, Phan Thiết, Bình Thuận",
        "path_with_type": "Phường Đức Long, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22954",
        "parent_code": "593"
    },
    "22957": {
        "name": "Thiện Nghiệp",
        "type": "xa",
        "slug": "thien-nghiep",
        "name_with_type": "Xã Thiện Nghiệp",
        "path": "Thiện Nghiệp, Phan Thiết, Bình Thuận",
        "path_with_type": "Xã Thiện Nghiệp, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22957",
        "parent_code": "593"
    },
    "22960": {
        "name": "Phong Nẫm",
        "type": "xa",
        "slug": "phong-nam",
        "name_with_type": "Xã Phong Nẫm",
        "path": "Phong Nẫm, Phan Thiết, Bình Thuận",
        "path_with_type": "Xã Phong Nẫm, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22960",
        "parent_code": "593"
    },
    "22963": {
        "name": "Tiến Lợi",
        "type": "xa",
        "slug": "tien-loi",
        "name_with_type": "Xã Tiến Lợi",
        "path": "Tiến Lợi, Phan Thiết, Bình Thuận",
        "path_with_type": "Xã Tiến Lợi, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22963",
        "parent_code": "593"
    },
    "22966": {
        "name": "Tiến Thành",
        "type": "xa",
        "slug": "tien-thanh",
        "name_with_type": "Xã Tiến Thành",
        "path": "Tiến Thành, Phan Thiết, Bình Thuận",
        "path_with_type": "Xã Tiến Thành, Thành phố Phan Thiết, Tỉnh Bình Thuận",
        "code": "22966",
        "parent_code": "593"
    },
    "23231": {
        "name": "Phước Hội",
        "type": "phuong",
        "slug": "phuoc-hoi",
        "name_with_type": "Phường Phước Hội",
        "path": "Phước Hội, La Gi, Bình Thuận",
        "path_with_type": "Phường Phước Hội, Thị xã La Gi, Tỉnh Bình Thuận",
        "code": "23231",
        "parent_code": "594"
    },
    "23232": {
        "name": "Phước Lộc",
        "type": "phuong",
        "slug": "phuoc-loc",
        "name_with_type": "Phường Phước Lộc",
        "path": "Phước Lộc, La Gi, Bình Thuận",
        "path_with_type": "Phường Phước Lộc, Thị xã La Gi, Tỉnh Bình Thuận",
        "code": "23232",
        "parent_code": "594"
    },
    "23234": {
        "name": "Tân Thiện",
        "type": "phuong",
        "slug": "tan-thien",
        "name_with_type": "Phường Tân Thiện",
        "path": "Tân Thiện, La Gi, Bình Thuận",
        "path_with_type": "Phường Tân Thiện, Thị xã La Gi, Tỉnh Bình Thuận",
        "code": "23234",
        "parent_code": "594"
    },
    "23235": {
        "name": "Tân An",
        "type": "phuong",
        "slug": "tan-an",
        "name_with_type": "Phường Tân An",
        "path": "Tân An, La Gi, Bình Thuận",
        "path_with_type": "Phường Tân An, Thị xã La Gi, Tỉnh Bình Thuận",
        "code": "23235",
        "parent_code": "594"
    },
    "23237": {
        "name": "Bình Tân",
        "type": "phuong",
        "slug": "binh-tan",
        "name_with_type": "Phường Bình Tân",
        "path": "Bình Tân, La Gi, Bình Thuận",
        "path_with_type": "Phường Bình Tân, Thị xã La Gi, Tỉnh Bình Thuận",
        "code": "23237",
        "parent_code": "594"
    },
    "23245": {
        "name": "Tân Hải",
        "type": "xa",
        "slug": "tan-hai",
        "name_with_type": "Xã Tân Hải",
        "path": "Tân Hải, La Gi, Bình Thuận",
        "path_with_type": "Xã Tân Hải, Thị xã La Gi, Tỉnh Bình Thuận",
        "code": "23245",
        "parent_code": "594"
    },
    "23246": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, La Gi, Bình Thuận",
        "path_with_type": "Xã Tân Tiến, Thị xã La Gi, Tỉnh Bình Thuận",
        "code": "23246",
        "parent_code": "594"
    },
    "23248": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, La Gi, Bình Thuận",
        "path_with_type": "Xã Tân Bình, Thị xã La Gi, Tỉnh Bình Thuận",
        "code": "23248",
        "parent_code": "594"
    },
    "23268": {
        "name": "Tân Phước",
        "type": "xa",
        "slug": "tan-phuoc",
        "name_with_type": "Xã Tân Phước",
        "path": "Tân Phước, La Gi, Bình Thuận",
        "path_with_type": "Xã Tân Phước, Thị xã La Gi, Tỉnh Bình Thuận",
        "code": "23268",
        "parent_code": "594"
    },
    "22969": {
        "name": "Liên Hương",
        "type": "thi-tran",
        "slug": "lien-huong",
        "name_with_type": "Thị trấn Liên Hương",
        "path": "Liên Hương, Tuy Phong, Bình Thuận",
        "path_with_type": "Thị trấn Liên Hương, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22969",
        "parent_code": "595"
    },
    "22972": {
        "name": "Phan Rí Cửa",
        "type": "thi-tran",
        "slug": "phan-ri-cua",
        "name_with_type": "Thị trấn Phan Rí Cửa",
        "path": "Phan Rí Cửa, Tuy Phong, Bình Thuận",
        "path_with_type": "Thị trấn Phan Rí Cửa, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22972",
        "parent_code": "595"
    },
    "22975": {
        "name": "Phan Dũng",
        "type": "xa",
        "slug": "phan-dung",
        "name_with_type": "Xã Phan Dũng",
        "path": "Phan Dũng, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Phan Dũng, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22975",
        "parent_code": "595"
    },
    "22978": {
        "name": "Phong Phú",
        "type": "xa",
        "slug": "phong-phu",
        "name_with_type": "Xã Phong Phú",
        "path": "Phong Phú, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Phong Phú, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22978",
        "parent_code": "595"
    },
    "22981": {
        "name": "Vĩnh Hảo",
        "type": "xa",
        "slug": "vinh-hao",
        "name_with_type": "Xã Vĩnh Hảo",
        "path": "Vĩnh Hảo, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Vĩnh Hảo, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22981",
        "parent_code": "595"
    },
    "22984": {
        "name": "Vĩnh Tân",
        "type": "xa",
        "slug": "vinh-tan",
        "name_with_type": "Xã Vĩnh Tân",
        "path": "Vĩnh Tân, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Vĩnh Tân, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22984",
        "parent_code": "595"
    },
    "22987": {
        "name": "Phú Lạc",
        "type": "xa",
        "slug": "phu-lac",
        "name_with_type": "Xã Phú Lạc",
        "path": "Phú Lạc, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Phú Lạc, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22987",
        "parent_code": "595"
    },
    "22990": {
        "name": "Phước Thể",
        "type": "xa",
        "slug": "phuoc-the",
        "name_with_type": "Xã Phước Thể",
        "path": "Phước Thể, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Phước Thể, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22990",
        "parent_code": "595"
    },
    "22993": {
        "name": "Hòa Minh",
        "type": "xa",
        "slug": "hoa-minh",
        "name_with_type": "Xã Hòa Minh",
        "path": "Hòa Minh, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Hòa Minh, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22993",
        "parent_code": "595"
    },
    "22996": {
        "name": "Chí Công",
        "type": "xa",
        "slug": "chi-cong",
        "name_with_type": "Xã Chí Công",
        "path": "Chí Công, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Chí Công, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22996",
        "parent_code": "595"
    },
    "22999": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Bình Thạnh, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "22999",
        "parent_code": "595"
    },
    "23002": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Tuy Phong, Bình Thuận",
        "path_with_type": "Xã Hòa Phú, Huyện Tuy Phong, Tỉnh Bình Thuận",
        "code": "23002",
        "parent_code": "595"
    },
    "23005": {
        "name": "Chợ Lầu",
        "type": "thi-tran",
        "slug": "cho-lau",
        "name_with_type": "Thị trấn Chợ Lầu",
        "path": "Chợ Lầu, Bắc Bình, Bình Thuận",
        "path_with_type": "Thị trấn Chợ Lầu, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23005",
        "parent_code": "596"
    },
    "23008": {
        "name": "Phan Sơn",
        "type": "xa",
        "slug": "phan-son",
        "name_with_type": "Xã Phan Sơn",
        "path": "Phan Sơn, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Phan Sơn, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23008",
        "parent_code": "596"
    },
    "23011": {
        "name": "Phan Lâm",
        "type": "xa",
        "slug": "phan-lam",
        "name_with_type": "Xã Phan Lâm",
        "path": "Phan Lâm, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Phan Lâm, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23011",
        "parent_code": "596"
    },
    "23014": {
        "name": "Bình An",
        "type": "xa",
        "slug": "binh-an",
        "name_with_type": "Xã Bình An",
        "path": "Bình An, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Bình An, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23014",
        "parent_code": "596"
    },
    "23017": {
        "name": "Phan Điền",
        "type": "xa",
        "slug": "phan-dien",
        "name_with_type": "Xã Phan Điền",
        "path": "Phan Điền, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Phan Điền, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23017",
        "parent_code": "596"
    },
    "23020": {
        "name": "Hải Ninh",
        "type": "xa",
        "slug": "hai-ninh",
        "name_with_type": "Xã Hải Ninh",
        "path": "Hải Ninh, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Hải Ninh, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23020",
        "parent_code": "596"
    },
    "23023": {
        "name": "Sông Lũy",
        "type": "xa",
        "slug": "song-luy",
        "name_with_type": "Xã Sông Lũy",
        "path": "Sông Lũy, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Sông Lũy, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23023",
        "parent_code": "596"
    },
    "23026": {
        "name": "Phan Tiến",
        "type": "xa",
        "slug": "phan-tien",
        "name_with_type": "Xã Phan Tiến",
        "path": "Phan Tiến, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Phan Tiến, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23026",
        "parent_code": "596"
    },
    "23029": {
        "name": "Sông Bình",
        "type": "xa",
        "slug": "song-binh",
        "name_with_type": "Xã Sông Bình",
        "path": "Sông Bình, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Sông Bình, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23029",
        "parent_code": "596"
    },
    "23032": {
        "name": "Lương Sơn",
        "type": "thi-tran",
        "slug": "luong-son",
        "name_with_type": "Thị trấn Lương Sơn",
        "path": "Lương Sơn, Bắc Bình, Bình Thuận",
        "path_with_type": "Thị trấn Lương Sơn, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23032",
        "parent_code": "596"
    },
    "23035": {
        "name": "Phan Hòa",
        "type": "xa",
        "slug": "phan-hoa",
        "name_with_type": "Xã Phan Hòa",
        "path": "Phan Hòa, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Phan Hòa, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23035",
        "parent_code": "596"
    },
    "23038": {
        "name": "Phan Thanh",
        "type": "xa",
        "slug": "phan-thanh",
        "name_with_type": "Xã Phan Thanh",
        "path": "Phan Thanh, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Phan Thanh, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23038",
        "parent_code": "596"
    },
    "23041": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Hồng Thái, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23041",
        "parent_code": "596"
    },
    "23044": {
        "name": "Phan Hiệp",
        "type": "xa",
        "slug": "phan-hiep",
        "name_with_type": "Xã Phan Hiệp",
        "path": "Phan Hiệp, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Phan Hiệp, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23044",
        "parent_code": "596"
    },
    "23047": {
        "name": "Bình Tân",
        "type": "xa",
        "slug": "binh-tan",
        "name_with_type": "Xã Bình Tân",
        "path": "Bình Tân, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Bình Tân, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23047",
        "parent_code": "596"
    },
    "23050": {
        "name": "Phan Rí Thành",
        "type": "xa",
        "slug": "phan-ri-thanh",
        "name_with_type": "Xã Phan Rí Thành",
        "path": "Phan Rí Thành, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Phan Rí Thành, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23050",
        "parent_code": "596"
    },
    "23053": {
        "name": "Hòa Thắng",
        "type": "xa",
        "slug": "hoa-thang",
        "name_with_type": "Xã Hòa Thắng",
        "path": "Hòa Thắng, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Hòa Thắng, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23053",
        "parent_code": "596"
    },
    "23056": {
        "name": "Hồng Phong",
        "type": "xa",
        "slug": "hong-phong",
        "name_with_type": "Xã Hồng Phong",
        "path": "Hồng Phong, Bắc Bình, Bình Thuận",
        "path_with_type": "Xã Hồng Phong, Huyện Bắc Bình, Tỉnh Bình Thuận",
        "code": "23056",
        "parent_code": "596"
    },
    "23059": {
        "name": "Ma Lâm",
        "type": "thi-tran",
        "slug": "ma-lam",
        "name_with_type": "Thị trấn Ma Lâm",
        "path": "Ma Lâm, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Thị trấn Ma Lâm, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23059",
        "parent_code": "597"
    },
    "23062": {
        "name": "Phú Long",
        "type": "thi-tran",
        "slug": "phu-long",
        "name_with_type": "Thị trấn Phú Long",
        "path": "Phú Long, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Thị trấn Phú Long, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23062",
        "parent_code": "597"
    },
    "23065": {
        "name": "La Dạ",
        "type": "xa",
        "slug": "la-da",
        "name_with_type": "Xã La Dạ",
        "path": "La Dạ, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã La Dạ, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23065",
        "parent_code": "597"
    },
    "23068": {
        "name": "Đông Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đông Tiến",
        "path": "Đông Tiến, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Đông Tiến, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23068",
        "parent_code": "597"
    },
    "23071": {
        "name": "Thuận Hòa",
        "type": "xa",
        "slug": "thuan-hoa",
        "name_with_type": "Xã Thuận Hòa",
        "path": "Thuận Hòa, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Thuận Hòa, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23071",
        "parent_code": "597"
    },
    "23074": {
        "name": "Đông Giang",
        "type": "xa",
        "slug": "dong-giang",
        "name_with_type": "Xã Đông Giang",
        "path": "Đông Giang, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Đông Giang, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23074",
        "parent_code": "597"
    },
    "23077": {
        "name": "Hàm Phú",
        "type": "xa",
        "slug": "ham-phu",
        "name_with_type": "Xã Hàm Phú",
        "path": "Hàm Phú, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Hàm Phú, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23077",
        "parent_code": "597"
    },
    "23080": {
        "name": "Hồng Liêm",
        "type": "xa",
        "slug": "hong-liem",
        "name_with_type": "Xã Hồng Liêm",
        "path": "Hồng Liêm, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Hồng Liêm, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23080",
        "parent_code": "597"
    },
    "23083": {
        "name": "Thuận Minh",
        "type": "xa",
        "slug": "thuan-minh",
        "name_with_type": "Xã Thuận Minh",
        "path": "Thuận Minh, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Thuận Minh, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23083",
        "parent_code": "597"
    },
    "23086": {
        "name": "Hồng Sơn",
        "type": "xa",
        "slug": "hong-son",
        "name_with_type": "Xã Hồng Sơn",
        "path": "Hồng Sơn, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Hồng Sơn, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23086",
        "parent_code": "597"
    },
    "23089": {
        "name": "Hàm Trí",
        "type": "xa",
        "slug": "ham-tri",
        "name_with_type": "Xã Hàm Trí",
        "path": "Hàm Trí, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Hàm Trí, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23089",
        "parent_code": "597"
    },
    "23092": {
        "name": "Hàm Đức",
        "type": "xa",
        "slug": "ham-duc",
        "name_with_type": "Xã Hàm Đức",
        "path": "Hàm Đức, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Hàm Đức, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23092",
        "parent_code": "597"
    },
    "23095": {
        "name": "Hàm Liêm",
        "type": "xa",
        "slug": "ham-liem",
        "name_with_type": "Xã Hàm Liêm",
        "path": "Hàm Liêm, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Hàm Liêm, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23095",
        "parent_code": "597"
    },
    "23098": {
        "name": "Hàm Chính",
        "type": "xa",
        "slug": "ham-chinh",
        "name_with_type": "Xã Hàm Chính",
        "path": "Hàm Chính, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Hàm Chính, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23098",
        "parent_code": "597"
    },
    "23101": {
        "name": "Hàm Hiệp",
        "type": "xa",
        "slug": "ham-hiep",
        "name_with_type": "Xã Hàm Hiệp",
        "path": "Hàm Hiệp, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Hàm Hiệp, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23101",
        "parent_code": "597"
    },
    "23104": {
        "name": "Hàm Thắng",
        "type": "xa",
        "slug": "ham-thang",
        "name_with_type": "Xã Hàm Thắng",
        "path": "Hàm Thắng, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Hàm Thắng, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23104",
        "parent_code": "597"
    },
    "23107": {
        "name": "Đa Mi",
        "type": "xa",
        "slug": "da-mi",
        "name_with_type": "Xã Đa Mi",
        "path": "Đa Mi, Hàm Thuận Bắc, Bình Thuận",
        "path_with_type": "Xã Đa Mi, Huyện Hàm Thuận Bắc, Tỉnh Bình Thuận",
        "code": "23107",
        "parent_code": "597"
    },
    "23110": {
        "name": "Thuận Nam",
        "type": "thi-tran",
        "slug": "thuan-nam",
        "name_with_type": "Thị trấn Thuận Nam",
        "path": "Thuận Nam, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Thị trấn Thuận Nam, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23110",
        "parent_code": "598"
    },
    "23113": {
        "name": "Mỹ Thạnh",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thạnh",
        "path": "Mỹ Thạnh, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Mỹ Thạnh, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23113",
        "parent_code": "598"
    },
    "23116": {
        "name": "Hàm Cần",
        "type": "xa",
        "slug": "ham-can",
        "name_with_type": "Xã Hàm Cần",
        "path": "Hàm Cần, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Hàm Cần, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23116",
        "parent_code": "598"
    },
    "23119": {
        "name": "Mương Mán",
        "type": "xa",
        "slug": "muong-man",
        "name_with_type": "Xã Mương Mán",
        "path": "Mương Mán, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Mương Mán, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23119",
        "parent_code": "598"
    },
    "23122": {
        "name": "Hàm Thạnh",
        "type": "xa",
        "slug": "ham-thanh",
        "name_with_type": "Xã Hàm Thạnh",
        "path": "Hàm Thạnh, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Hàm Thạnh, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23122",
        "parent_code": "598"
    },
    "23125": {
        "name": "Hàm Kiệm",
        "type": "xa",
        "slug": "ham-kiem",
        "name_with_type": "Xã Hàm Kiệm",
        "path": "Hàm Kiệm, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Hàm Kiệm, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23125",
        "parent_code": "598"
    },
    "23128": {
        "name": "Hàm Cường",
        "type": "xa",
        "slug": "ham-cuong",
        "name_with_type": "Xã Hàm Cường",
        "path": "Hàm Cường, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Hàm Cường, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23128",
        "parent_code": "598"
    },
    "23131": {
        "name": "Hàm Mỹ",
        "type": "xa",
        "slug": "ham-my",
        "name_with_type": "Xã Hàm Mỹ",
        "path": "Hàm Mỹ, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Hàm Mỹ, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23131",
        "parent_code": "598"
    },
    "23134": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Tân Lập, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23134",
        "parent_code": "598"
    },
    "23137": {
        "name": "Hàm Minh",
        "type": "xa",
        "slug": "ham-minh",
        "name_with_type": "Xã Hàm Minh",
        "path": "Hàm Minh, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Hàm Minh, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23137",
        "parent_code": "598"
    },
    "23140": {
        "name": "Thuận Quí",
        "type": "xa",
        "slug": "thuan-qui",
        "name_with_type": "Xã Thuận Quí",
        "path": "Thuận Quí, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Thuận Quí, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23140",
        "parent_code": "598"
    },
    "23143": {
        "name": "Tân Thuận",
        "type": "xa",
        "slug": "tan-thuan",
        "name_with_type": "Xã Tân Thuận",
        "path": "Tân Thuận, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Tân Thuận, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23143",
        "parent_code": "598"
    },
    "23146": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Hàm Thuận Nam, Bình Thuận",
        "path_with_type": "Xã Tân Thành, Huyện Hàm Thuận Nam, Tỉnh Bình Thuận",
        "code": "23146",
        "parent_code": "598"
    },
    "23149": {
        "name": "Lạc Tánh",
        "type": "thi-tran",
        "slug": "lac-tanh",
        "name_with_type": "Thị trấn Lạc Tánh",
        "path": "Lạc Tánh, Tánh Linh, Bình Thuận",
        "path_with_type": "Thị trấn Lạc Tánh, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23149",
        "parent_code": "599"
    },
    "23152": {
        "name": "Bắc Ruộng",
        "type": "xa",
        "slug": "bac-ruong",
        "name_with_type": "Xã Bắc Ruộng",
        "path": "Bắc Ruộng, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Bắc Ruộng, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23152",
        "parent_code": "599"
    },
    "23155": {
        "name": "Măng Tố",
        "type": "xa",
        "slug": "mang-to",
        "name_with_type": "Xã Măng Tố",
        "path": "Măng Tố, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Măng Tố, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23155",
        "parent_code": "599"
    },
    "23158": {
        "name": "Nghị Đức",
        "type": "xa",
        "slug": "nghi-duc",
        "name_with_type": "Xã Nghị Đức",
        "path": "Nghị Đức, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Nghị Đức, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23158",
        "parent_code": "599"
    },
    "23161": {
        "name": "La Ngâu",
        "type": "xa",
        "slug": "la-ngau",
        "name_with_type": "Xã La Ngâu",
        "path": "La Ngâu, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã La Ngâu, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23161",
        "parent_code": "599"
    },
    "23164": {
        "name": "Huy Khiêm",
        "type": "xa",
        "slug": "huy-khiem",
        "name_with_type": "Xã Huy Khiêm",
        "path": "Huy Khiêm, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Huy Khiêm, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23164",
        "parent_code": "599"
    },
    "23167": {
        "name": "Đức Tân",
        "type": "xa",
        "slug": "duc-tan",
        "name_with_type": "Xã Đức Tân",
        "path": "Đức Tân, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Đức Tân, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23167",
        "parent_code": "599"
    },
    "23170": {
        "name": "Đức Phú",
        "type": "xa",
        "slug": "duc-phu",
        "name_with_type": "Xã Đức Phú",
        "path": "Đức Phú, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Đức Phú, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23170",
        "parent_code": "599"
    },
    "23173": {
        "name": "Đồng Kho",
        "type": "xa",
        "slug": "dong-kho",
        "name_with_type": "Xã Đồng Kho",
        "path": "Đồng Kho, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Đồng Kho, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23173",
        "parent_code": "599"
    },
    "23176": {
        "name": "Gia An",
        "type": "xa",
        "slug": "gia-an",
        "name_with_type": "Xã Gia An",
        "path": "Gia An, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Gia An, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23176",
        "parent_code": "599"
    },
    "23179": {
        "name": "Đức Bình",
        "type": "xa",
        "slug": "duc-binh",
        "name_with_type": "Xã Đức Bình",
        "path": "Đức Bình, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Đức Bình, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23179",
        "parent_code": "599"
    },
    "23182": {
        "name": "Gia Huynh",
        "type": "xa",
        "slug": "gia-huynh",
        "name_with_type": "Xã Gia Huynh",
        "path": "Gia Huynh, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Gia Huynh, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23182",
        "parent_code": "599"
    },
    "23185": {
        "name": "Đức Thuận",
        "type": "xa",
        "slug": "duc-thuan",
        "name_with_type": "Xã Đức Thuận",
        "path": "Đức Thuận, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Đức Thuận, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23185",
        "parent_code": "599"
    },
    "23188": {
        "name": "Suối Kiết",
        "type": "xa",
        "slug": "suoi-kiet",
        "name_with_type": "Xã Suối Kiết",
        "path": "Suối Kiết, Tánh Linh, Bình Thuận",
        "path_with_type": "Xã Suối Kiết, Huyện Tánh Linh, Tỉnh Bình Thuận",
        "code": "23188",
        "parent_code": "599"
    },
    "23191": {
        "name": "Võ Xu",
        "type": "thi-tran",
        "slug": "vo-xu",
        "name_with_type": "Thị trấn Võ Xu",
        "path": "Võ Xu, Đức Linh, Bình Thuận",
        "path_with_type": "Thị trấn Võ Xu, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23191",
        "parent_code": "600"
    },
    "23194": {
        "name": "Đức Tài",
        "type": "thi-tran",
        "slug": "duc-tai",
        "name_with_type": "Thị trấn Đức Tài",
        "path": "Đức Tài, Đức Linh, Bình Thuận",
        "path_with_type": "Thị trấn Đức Tài, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23194",
        "parent_code": "600"
    },
    "23197": {
        "name": "Đa Kai",
        "type": "xa",
        "slug": "da-kai",
        "name_with_type": "Xã Đa Kai",
        "path": "Đa Kai, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Đa Kai, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23197",
        "parent_code": "600"
    },
    "23200": {
        "name": "Sùng Nhơn",
        "type": "xa",
        "slug": "sung-nhon",
        "name_with_type": "Xã Sùng Nhơn",
        "path": "Sùng Nhơn, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Sùng Nhơn, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23200",
        "parent_code": "600"
    },
    "23203": {
        "name": "Mê Pu",
        "type": "xa",
        "slug": "me-pu",
        "name_with_type": "Xã Mê Pu",
        "path": "Mê Pu, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Mê Pu, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23203",
        "parent_code": "600"
    },
    "23206": {
        "name": "Nam Chính",
        "type": "xa",
        "slug": "nam-chinh",
        "name_with_type": "Xã Nam Chính",
        "path": "Nam Chính, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Nam Chính, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23206",
        "parent_code": "600"
    },
    "23209": {
        "name": "Đức Chính",
        "type": "xa",
        "slug": "duc-chinh",
        "name_with_type": "Xã Đức Chính",
        "path": "Đức Chính, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Đức Chính, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23209",
        "parent_code": "600"
    },
    "23212": {
        "name": "Đức Hạnh",
        "type": "xa",
        "slug": "duc-hanh",
        "name_with_type": "Xã Đức Hạnh",
        "path": "Đức Hạnh, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Đức Hạnh, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23212",
        "parent_code": "600"
    },
    "23215": {
        "name": "Đức Tín",
        "type": "xa",
        "slug": "duc-tin",
        "name_with_type": "Xã Đức Tín",
        "path": "Đức Tín, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Đức Tín, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23215",
        "parent_code": "600"
    },
    "23218": {
        "name": "Vũ Hoà",
        "type": "xa",
        "slug": "vu-hoa",
        "name_with_type": "Xã Vũ Hoà",
        "path": "Vũ Hoà, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Vũ Hoà, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23218",
        "parent_code": "600"
    },
    "23221": {
        "name": "Tân Hà",
        "type": "xa",
        "slug": "tan-ha",
        "name_with_type": "Xã Tân Hà",
        "path": "Tân Hà, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Tân Hà, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23221",
        "parent_code": "600"
    },
    "23224": {
        "name": "Đông Hà",
        "type": "xa",
        "slug": "dong-ha",
        "name_with_type": "Xã Đông Hà",
        "path": "Đông Hà, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Đông Hà, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23224",
        "parent_code": "600"
    },
    "23227": {
        "name": "Trà Tân",
        "type": "xa",
        "slug": "tra-tan",
        "name_with_type": "Xã Trà Tân",
        "path": "Trà Tân, Đức Linh, Bình Thuận",
        "path_with_type": "Xã Trà Tân, Huyện Đức Linh, Tỉnh Bình Thuận",
        "code": "23227",
        "parent_code": "600"
    },
    "23230": {
        "name": "Tân Minh",
        "type": "thi-tran",
        "slug": "tan-minh",
        "name_with_type": "Thị trấn Tân Minh",
        "path": "Tân Minh, Hàm Tân, Bình Thuận",
        "path_with_type": "Thị trấn Tân Minh, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23230",
        "parent_code": "601"
    },
    "23236": {
        "name": "Tân Nghĩa",
        "type": "thi-tran",
        "slug": "tan-nghia",
        "name_with_type": "Thị trấn Tân Nghĩa",
        "path": "Tân Nghĩa, Hàm Tân, Bình Thuận",
        "path_with_type": "Thị trấn Tân Nghĩa, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23236",
        "parent_code": "601"
    },
    "23239": {
        "name": "Sông Phan",
        "type": "xa",
        "slug": "song-phan",
        "name_with_type": "Xã Sông Phan",
        "path": "Sông Phan, Hàm Tân, Bình Thuận",
        "path_with_type": "Xã Sông Phan, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23239",
        "parent_code": "601"
    },
    "23242": {
        "name": "Tân Phúc",
        "type": "xa",
        "slug": "tan-phuc",
        "name_with_type": "Xã Tân Phúc",
        "path": "Tân Phúc, Hàm Tân, Bình Thuận",
        "path_with_type": "Xã Tân Phúc, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23242",
        "parent_code": "601"
    },
    "23251": {
        "name": "Tân Đức",
        "type": "xa",
        "slug": "tan-duc",
        "name_with_type": "Xã Tân Đức",
        "path": "Tân Đức, Hàm Tân, Bình Thuận",
        "path_with_type": "Xã Tân Đức, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23251",
        "parent_code": "601"
    },
    "23254": {
        "name": "Tân Thắng",
        "type": "xa",
        "slug": "tan-thang",
        "name_with_type": "Xã Tân Thắng",
        "path": "Tân Thắng, Hàm Tân, Bình Thuận",
        "path_with_type": "Xã Tân Thắng, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23254",
        "parent_code": "601"
    },
    "23255": {
        "name": "Thắng Hải",
        "type": "xa",
        "slug": "thang-hai",
        "name_with_type": "Xã Thắng Hải",
        "path": "Thắng Hải, Hàm Tân, Bình Thuận",
        "path_with_type": "Xã Thắng Hải, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23255",
        "parent_code": "601"
    },
    "23257": {
        "name": "Tân Hà",
        "type": "xa",
        "slug": "tan-ha",
        "name_with_type": "Xã Tân Hà",
        "path": "Tân Hà, Hàm Tân, Bình Thuận",
        "path_with_type": "Xã Tân Hà, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23257",
        "parent_code": "601"
    },
    "23260": {
        "name": "Tân Xuân",
        "type": "xa",
        "slug": "tan-xuan",
        "name_with_type": "Xã Tân Xuân",
        "path": "Tân Xuân, Hàm Tân, Bình Thuận",
        "path_with_type": "Xã Tân Xuân, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23260",
        "parent_code": "601"
    },
    "23266": {
        "name": "Sơn Mỹ",
        "type": "xa",
        "slug": "son-my",
        "name_with_type": "Xã Sơn Mỹ",
        "path": "Sơn Mỹ, Hàm Tân, Bình Thuận",
        "path_with_type": "Xã Sơn Mỹ, Huyện Hàm Tân, Tỉnh Bình Thuận",
        "code": "23266",
        "parent_code": "601"
    },
    "23272": {
        "name": "Ngũ Phụng",
        "type": "xa",
        "slug": "ngu-phung",
        "name_with_type": "Xã Ngũ Phụng",
        "path": "Ngũ Phụng, Phú Quí, Bình Thuận",
        "path_with_type": "Xã Ngũ Phụng, Huyện Phú Quí, Tỉnh Bình Thuận",
        "code": "23272",
        "parent_code": "602"
    },
    "23275": {
        "name": "Long Hải",
        "type": "xa",
        "slug": "long-hai",
        "name_with_type": "Xã Long Hải",
        "path": "Long Hải, Phú Quí, Bình Thuận",
        "path_with_type": "Xã Long Hải, Huyện Phú Quí, Tỉnh Bình Thuận",
        "code": "23275",
        "parent_code": "602"
    },
    "23278": {
        "name": "Tam Thanh",
        "type": "xa",
        "slug": "tam-thanh",
        "name_with_type": "Xã Tam Thanh",
        "path": "Tam Thanh, Phú Quí, Bình Thuận",
        "path_with_type": "Xã Tam Thanh, Huyện Phú Quí, Tỉnh Bình Thuận",
        "code": "23278",
        "parent_code": "602"
    },
    "01267": {
        "name": "Sông Hiến",
        "type": "phuong",
        "slug": "song-hien",
        "name_with_type": "Phường Sông Hiến",
        "path": "Sông Hiến, Cao Bằng, Cao Bằng",
        "path_with_type": "Phường Sông Hiến, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01267",
        "parent_code": "040"
    },
    "01270": {
        "name": "Sông Bằng",
        "type": "phuong",
        "slug": "song-bang",
        "name_with_type": "Phường Sông Bằng",
        "path": "Sông Bằng, Cao Bằng, Cao Bằng",
        "path_with_type": "Phường Sông Bằng, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01270",
        "parent_code": "040"
    },
    "01273": {
        "name": "Hợp Giang",
        "type": "phuong",
        "slug": "hop-giang",
        "name_with_type": "Phường Hợp Giang",
        "path": "Hợp Giang, Cao Bằng, Cao Bằng",
        "path_with_type": "Phường Hợp Giang, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01273",
        "parent_code": "040"
    },
    "01276": {
        "name": "Tân Giang",
        "type": "phuong",
        "slug": "tan-giang",
        "name_with_type": "Phường Tân Giang",
        "path": "Tân Giang, Cao Bằng, Cao Bằng",
        "path_with_type": "Phường Tân Giang, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01276",
        "parent_code": "040"
    },
    "01279": {
        "name": "Ngọc Xuân",
        "type": "phuong",
        "slug": "ngoc-xuan",
        "name_with_type": "Phường Ngọc Xuân",
        "path": "Ngọc Xuân, Cao Bằng, Cao Bằng",
        "path_with_type": "Phường Ngọc Xuân, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01279",
        "parent_code": "040"
    },
    "01282": {
        "name": "Đề Thám",
        "type": "phuong",
        "slug": "de-tham",
        "name_with_type": "Phường Đề Thám",
        "path": "Đề Thám, Cao Bằng, Cao Bằng",
        "path_with_type": "Phường Đề Thám, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01282",
        "parent_code": "040"
    },
    "01285": {
        "name": "Hoà Chung",
        "type": "phuong",
        "slug": "hoa-chung",
        "name_with_type": "Phường Hoà Chung",
        "path": "Hoà Chung, Cao Bằng, Cao Bằng",
        "path_with_type": "Phường Hoà Chung, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01285",
        "parent_code": "040"
    },
    "01288": {
        "name": "Duyệt Trung",
        "type": "phuong",
        "slug": "duyet-trung",
        "name_with_type": "Phường Duyệt Trung",
        "path": "Duyệt Trung, Cao Bằng, Cao Bằng",
        "path_with_type": "Phường Duyệt Trung, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01288",
        "parent_code": "040"
    },
    "01693": {
        "name": "Vĩnh Quang",
        "type": "xa",
        "slug": "vinh-quang",
        "name_with_type": "Xã Vĩnh Quang",
        "path": "Vĩnh Quang, Cao Bằng, Cao Bằng",
        "path_with_type": "Xã Vĩnh Quang, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01693",
        "parent_code": "040"
    },
    "01705": {
        "name": "Hưng Đạo",
        "type": "xa",
        "slug": "hung-dao",
        "name_with_type": "Xã Hưng Đạo",
        "path": "Hưng Đạo, Cao Bằng, Cao Bằng",
        "path_with_type": "Xã Hưng Đạo, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01705",
        "parent_code": "040"
    },
    "01720": {
        "name": "Chu Trinh",
        "type": "xa",
        "slug": "chu-trinh",
        "name_with_type": "Xã Chu Trinh",
        "path": "Chu Trinh, Cao Bằng, Cao Bằng",
        "path_with_type": "Xã Chu Trinh, Thành phố Cao Bằng, Tỉnh Cao Bằng",
        "code": "01720",
        "parent_code": "040"
    },
    "01290": {
        "name": "Pác Miầu",
        "type": "thi-tran",
        "slug": "pac-miau",
        "name_with_type": "Thị trấn Pác Miầu",
        "path": "Pác Miầu, Bảo Lâm, Cao Bằng",
        "path_with_type": "Thị trấn Pác Miầu, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01290",
        "parent_code": "042"
    },
    "01291": {
        "name": "Đức Hạnh",
        "type": "xa",
        "slug": "duc-hanh",
        "name_with_type": "Xã Đức Hạnh",
        "path": "Đức Hạnh, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Đức Hạnh, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01291",
        "parent_code": "042"
    },
    "01294": {
        "name": "Lý Bôn",
        "type": "xa",
        "slug": "ly-bon",
        "name_with_type": "Xã Lý Bôn",
        "path": "Lý Bôn, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Lý Bôn, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01294",
        "parent_code": "042"
    },
    "01296": {
        "name": "Nam Cao",
        "type": "xa",
        "slug": "nam-cao",
        "name_with_type": "Xã Nam Cao",
        "path": "Nam Cao, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Nam Cao, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01296",
        "parent_code": "042"
    },
    "01297": {
        "name": "Nam Quang",
        "type": "xa",
        "slug": "nam-quang",
        "name_with_type": "Xã Nam Quang",
        "path": "Nam Quang, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Nam Quang, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01297",
        "parent_code": "042"
    },
    "01300": {
        "name": "Vĩnh Quang",
        "type": "xa",
        "slug": "vinh-quang",
        "name_with_type": "Xã Vĩnh Quang",
        "path": "Vĩnh Quang, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Vĩnh Quang, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01300",
        "parent_code": "042"
    },
    "01303": {
        "name": "Quảng Lâm",
        "type": "xa",
        "slug": "quang-lam",
        "name_with_type": "Xã Quảng Lâm",
        "path": "Quảng Lâm, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Quảng Lâm, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01303",
        "parent_code": "042"
    },
    "01304": {
        "name": "Thạch Lâm",
        "type": "xa",
        "slug": "thach-lam",
        "name_with_type": "Xã Thạch Lâm",
        "path": "Thạch Lâm, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Thạch Lâm, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01304",
        "parent_code": "042"
    },
    "01306": {
        "name": "Tân Việt",
        "type": "xa",
        "slug": "tan-viet",
        "name_with_type": "Xã Tân Việt",
        "path": "Tân Việt, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Tân Việt, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01306",
        "parent_code": "042"
    },
    "01309": {
        "name": "Vĩnh Phong",
        "type": "xa",
        "slug": "vinh-phong",
        "name_with_type": "Xã Vĩnh Phong",
        "path": "Vĩnh Phong, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Vĩnh Phong, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01309",
        "parent_code": "042"
    },
    "01312": {
        "name": "Mông Ân",
        "type": "xa",
        "slug": "mong-an",
        "name_with_type": "Xã Mông Ân",
        "path": "Mông Ân, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Mông Ân, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01312",
        "parent_code": "042"
    },
    "01315": {
        "name": "Thái Học",
        "type": "xa",
        "slug": "thai-hoc",
        "name_with_type": "Xã Thái Học",
        "path": "Thái Học, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Thái Học, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01315",
        "parent_code": "042"
    },
    "01316": {
        "name": "Thái Sơn",
        "type": "xa",
        "slug": "thai-son",
        "name_with_type": "Xã Thái Sơn",
        "path": "Thái Sơn, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Thái Sơn, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01316",
        "parent_code": "042"
    },
    "01318": {
        "name": "Yên Thổ",
        "type": "xa",
        "slug": "yen-tho",
        "name_with_type": "Xã Yên Thổ",
        "path": "Yên Thổ, Bảo Lâm, Cao Bằng",
        "path_with_type": "Xã Yên Thổ, Huyện Bảo Lâm, Tỉnh Cao Bằng",
        "code": "01318",
        "parent_code": "042"
    },
    "01321": {
        "name": "Bảo Lạc",
        "type": "thi-tran",
        "slug": "bao-lac",
        "name_with_type": "Thị trấn Bảo Lạc",
        "path": "Bảo Lạc, Bảo Lạc, Cao Bằng",
        "path_with_type": "Thị trấn Bảo Lạc, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01321",
        "parent_code": "043"
    },
    "01324": {
        "name": "Cốc Pàng",
        "type": "xa",
        "slug": "coc-pang",
        "name_with_type": "Xã Cốc Pàng",
        "path": "Cốc Pàng, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Cốc Pàng, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01324",
        "parent_code": "043"
    },
    "01327": {
        "name": "Thượng Hà",
        "type": "xa",
        "slug": "thuong-ha",
        "name_with_type": "Xã Thượng Hà",
        "path": "Thượng Hà, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Thượng Hà, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01327",
        "parent_code": "043"
    },
    "01330": {
        "name": "Cô Ba",
        "type": "xa",
        "slug": "co-ba",
        "name_with_type": "Xã Cô Ba",
        "path": "Cô Ba, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Cô Ba, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01330",
        "parent_code": "043"
    },
    "01333": {
        "name": "Bảo Toàn",
        "type": "xa",
        "slug": "bao-toan",
        "name_with_type": "Xã Bảo Toàn",
        "path": "Bảo Toàn, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Bảo Toàn, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01333",
        "parent_code": "043"
    },
    "01336": {
        "name": "Khánh Xuân",
        "type": "xa",
        "slug": "khanh-xuan",
        "name_with_type": "Xã Khánh Xuân",
        "path": "Khánh Xuân, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Khánh Xuân, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01336",
        "parent_code": "043"
    },
    "01339": {
        "name": "Xuân Trường",
        "type": "xa",
        "slug": "xuan-truong",
        "name_with_type": "Xã Xuân Trường",
        "path": "Xuân Trường, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Xuân Trường, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01339",
        "parent_code": "043"
    },
    "01342": {
        "name": "Hồng Trị",
        "type": "xa",
        "slug": "hong-tri",
        "name_with_type": "Xã Hồng Trị",
        "path": "Hồng Trị, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Hồng Trị, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01342",
        "parent_code": "043"
    },
    "01343": {
        "name": "Kim Cúc",
        "type": "xa",
        "slug": "kim-cuc",
        "name_with_type": "Xã Kim Cúc",
        "path": "Kim Cúc, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Kim Cúc, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01343",
        "parent_code": "043"
    },
    "01345": {
        "name": "Phan Thanh",
        "type": "xa",
        "slug": "phan-thanh",
        "name_with_type": "Xã Phan Thanh",
        "path": "Phan Thanh, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Phan Thanh, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01345",
        "parent_code": "043"
    },
    "01348": {
        "name": "Hồng An",
        "type": "xa",
        "slug": "hong-an",
        "name_with_type": "Xã Hồng An",
        "path": "Hồng An, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Hồng An, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01348",
        "parent_code": "043"
    },
    "01351": {
        "name": "Hưng Đạo",
        "type": "xa",
        "slug": "hung-dao",
        "name_with_type": "Xã Hưng Đạo",
        "path": "Hưng Đạo, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Hưng Đạo, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01351",
        "parent_code": "043"
    },
    "01352": {
        "name": "Hưng Thịnh",
        "type": "xa",
        "slug": "hung-thinh",
        "name_with_type": "Xã Hưng Thịnh",
        "path": "Hưng Thịnh, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Hưng Thịnh, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01352",
        "parent_code": "043"
    },
    "01354": {
        "name": "Huy Giáp",
        "type": "xa",
        "slug": "huy-giap",
        "name_with_type": "Xã Huy Giáp",
        "path": "Huy Giáp, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Huy Giáp, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01354",
        "parent_code": "043"
    },
    "01357": {
        "name": "Đình Phùng",
        "type": "xa",
        "slug": "dinh-phung",
        "name_with_type": "Xã Đình Phùng",
        "path": "Đình Phùng, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Đình Phùng, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01357",
        "parent_code": "043"
    },
    "01359": {
        "name": "Sơn Lập",
        "type": "xa",
        "slug": "son-lap",
        "name_with_type": "Xã Sơn Lập",
        "path": "Sơn Lập, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Sơn Lập, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01359",
        "parent_code": "043"
    },
    "01360": {
        "name": "Sơn Lộ",
        "type": "xa",
        "slug": "son-lo",
        "name_with_type": "Xã Sơn Lộ",
        "path": "Sơn Lộ, Bảo Lạc, Cao Bằng",
        "path_with_type": "Xã Sơn Lộ, Huyện Bảo Lạc, Tỉnh Cao Bằng",
        "code": "01360",
        "parent_code": "043"
    },
    "01363": {
        "name": "Thông Nông",
        "type": "thi-tran",
        "slug": "thong-nong",
        "name_with_type": "Thị trấn Thông Nông",
        "path": "Thông Nông, Thông Nông, Cao Bằng",
        "path_with_type": "Thị trấn Thông Nông, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01363",
        "parent_code": "044"
    },
    "01366": {
        "name": "Cần Yên",
        "type": "xa",
        "slug": "can-yen",
        "name_with_type": "Xã Cần Yên",
        "path": "Cần Yên, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Cần Yên, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01366",
        "parent_code": "044"
    },
    "01367": {
        "name": "Cần Nông",
        "type": "xa",
        "slug": "can-nong",
        "name_with_type": "Xã Cần Nông",
        "path": "Cần Nông, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Cần Nông, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01367",
        "parent_code": "044"
    },
    "01369": {
        "name": "Vị Quang",
        "type": "xa",
        "slug": "vi-quang",
        "name_with_type": "Xã Vị Quang",
        "path": "Vị Quang, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Vị Quang, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01369",
        "parent_code": "044"
    },
    "01372": {
        "name": "Lương Thông",
        "type": "xa",
        "slug": "luong-thong",
        "name_with_type": "Xã Lương Thông",
        "path": "Lương Thông, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Lương Thông, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01372",
        "parent_code": "044"
    },
    "01375": {
        "name": "Đa Thông",
        "type": "xa",
        "slug": "da-thong",
        "name_with_type": "Xã Đa Thông",
        "path": "Đa Thông, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Đa Thông, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01375",
        "parent_code": "044"
    },
    "01378": {
        "name": "Ngọc Động",
        "type": "xa",
        "slug": "ngoc-dong",
        "name_with_type": "Xã Ngọc Động",
        "path": "Ngọc Động, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Ngọc Động, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01378",
        "parent_code": "044"
    },
    "01381": {
        "name": "Yên Sơn",
        "type": "xa",
        "slug": "yen-son",
        "name_with_type": "Xã Yên Sơn",
        "path": "Yên Sơn, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Yên Sơn, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01381",
        "parent_code": "044"
    },
    "01384": {
        "name": "Lương Can",
        "type": "xa",
        "slug": "luong-can",
        "name_with_type": "Xã Lương Can",
        "path": "Lương Can, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Lương Can, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01384",
        "parent_code": "044"
    },
    "01387": {
        "name": "Thanh Long",
        "type": "xa",
        "slug": "thanh-long",
        "name_with_type": "Xã Thanh Long",
        "path": "Thanh Long, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Thanh Long, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01387",
        "parent_code": "044"
    },
    "01390": {
        "name": "Bình Lãng",
        "type": "xa",
        "slug": "binh-lang",
        "name_with_type": "Xã Bình Lãng",
        "path": "Bình Lãng, Thông Nông, Cao Bằng",
        "path_with_type": "Xã Bình Lãng, Huyện Thông Nông, Tỉnh Cao Bằng",
        "code": "01390",
        "parent_code": "044"
    },
    "01392": {
        "name": "Xuân Hòa",
        "type": "thi-tran",
        "slug": "xuan-hoa",
        "name_with_type": "Thị trấn Xuân Hòa",
        "path": "Xuân Hòa, Hà Quảng, Cao Bằng",
        "path_with_type": "Thị trấn Xuân Hòa, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01392",
        "parent_code": "045"
    },
    "01393": {
        "name": "Lũng Nặm",
        "type": "xa",
        "slug": "lung-nam",
        "name_with_type": "Xã Lũng Nặm",
        "path": "Lũng Nặm, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Lũng Nặm, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01393",
        "parent_code": "045"
    },
    "01396": {
        "name": "Kéo Yên",
        "type": "xa",
        "slug": "keo-yen",
        "name_with_type": "Xã Kéo Yên",
        "path": "Kéo Yên, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Kéo Yên, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01396",
        "parent_code": "045"
    },
    "01399": {
        "name": "Trường Hà",
        "type": "xa",
        "slug": "truong-ha",
        "name_with_type": "Xã Trường Hà",
        "path": "Trường Hà, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Trường Hà, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01399",
        "parent_code": "045"
    },
    "01402": {
        "name": "Vân An",
        "type": "xa",
        "slug": "van-an",
        "name_with_type": "Xã Vân An",
        "path": "Vân An, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Vân An, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01402",
        "parent_code": "045"
    },
    "01405": {
        "name": "Cải Viên",
        "type": "xa",
        "slug": "cai-vien",
        "name_with_type": "Xã Cải Viên",
        "path": "Cải Viên, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Cải Viên, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01405",
        "parent_code": "045"
    },
    "01408": {
        "name": "Nà Sác",
        "type": "xa",
        "slug": "na-sac",
        "name_with_type": "Xã Nà Sác",
        "path": "Nà Sác, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Nà Sác, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01408",
        "parent_code": "045"
    },
    "01411": {
        "name": "Nội Thôn",
        "type": "xa",
        "slug": "noi-thon",
        "name_with_type": "Xã Nội Thôn",
        "path": "Nội Thôn, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Nội Thôn, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01411",
        "parent_code": "045"
    },
    "01414": {
        "name": "Tổng Cọt",
        "type": "xa",
        "slug": "tong-cot",
        "name_with_type": "Xã Tổng Cọt",
        "path": "Tổng Cọt, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Tổng Cọt, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01414",
        "parent_code": "045"
    },
    "01417": {
        "name": "Sóc Hà",
        "type": "xa",
        "slug": "soc-ha",
        "name_with_type": "Xã Sóc Hà",
        "path": "Sóc Hà, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Sóc Hà, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01417",
        "parent_code": "045"
    },
    "01420": {
        "name": "Thượng Thôn",
        "type": "xa",
        "slug": "thuong-thon",
        "name_with_type": "Xã Thượng Thôn",
        "path": "Thượng Thôn, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Thượng Thôn, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01420",
        "parent_code": "045"
    },
    "01423": {
        "name": "Vần Dính",
        "type": "xa",
        "slug": "van-dinh",
        "name_with_type": "Xã Vần Dính",
        "path": "Vần Dính, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Vần Dính, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01423",
        "parent_code": "045"
    },
    "01426": {
        "name": "Hồng Sĩ",
        "type": "xa",
        "slug": "hong-si",
        "name_with_type": "Xã H���ng Sĩ",
        "path": "Hồng Sĩ, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Hồng Sĩ, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01426",
        "parent_code": "045"
    },
    "01429": {
        "name": "Sĩ Hai",
        "type": "xa",
        "slug": "si-hai",
        "name_with_type": "Xã Sĩ Hai",
        "path": "Sĩ Hai, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Sĩ Hai, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01429",
        "parent_code": "045"
    },
    "01432": {
        "name": "Quý Quân",
        "type": "xa",
        "slug": "quy-quan",
        "name_with_type": "Xã Quý Quân",
        "path": "Quý Quân, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Quý Quân, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01432",
        "parent_code": "045"
    },
    "01435": {
        "name": "Mã Ba",
        "type": "xa",
        "slug": "ma-ba",
        "name_with_type": "Xã Mã Ba",
        "path": "Mã Ba, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Mã Ba, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01435",
        "parent_code": "045"
    },
    "01438": {
        "name": "Phù Ngọc",
        "type": "xa",
        "slug": "phu-ngoc",
        "name_with_type": "Xã Phù Ngọc",
        "path": "Phù Ngọc, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Phù Ngọc, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01438",
        "parent_code": "045"
    },
    "01441": {
        "name": "Đào Ngạn",
        "type": "xa",
        "slug": "dao-ngan",
        "name_with_type": "Xã Đào Ngạn",
        "path": "Đào Ngạn, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Đào Ngạn, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01441",
        "parent_code": "045"
    },
    "01444": {
        "name": "Hạ Thôn",
        "type": "xa",
        "slug": "ha-thon",
        "name_with_type": "Xã Hạ Thôn",
        "path": "Hạ Thôn, Hà Quảng, Cao Bằng",
        "path_with_type": "Xã Hạ Thôn, Huyện Hà Quảng, Tỉnh Cao Bằng",
        "code": "01444",
        "parent_code": "045"
    },
    "01447": {
        "name": "Hùng Quốc",
        "type": "thi-tran",
        "slug": "hung-quoc",
        "name_with_type": "Thị trấn Hùng Quốc",
        "path": "Hùng Quốc, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Thị trấn Hùng Quốc, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01447",
        "parent_code": "046"
    },
    "01450": {
        "name": "Cô Mười",
        "type": "xa",
        "slug": "co-muoi",
        "name_with_type": "Xã Cô Mười",
        "path": "Cô Mười, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Xã Cô Mười, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01450",
        "parent_code": "046"
    },
    "01453": {
        "name": "Tri Phương",
        "type": "xa",
        "slug": "tri-phuong",
        "name_with_type": "Xã Tri Phương",
        "path": "Tri Phương, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Xã Tri Phương, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01453",
        "parent_code": "046"
    },
    "01456": {
        "name": "Quang Hán",
        "type": "xa",
        "slug": "quang-han",
        "name_with_type": "Xã Quang Hán",
        "path": "Quang Hán, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Xã Quang Hán, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01456",
        "parent_code": "046"
    },
    "01459": {
        "name": "Quang Vinh",
        "type": "xa",
        "slug": "quang-vinh",
        "name_with_type": "Xã Quang Vinh",
        "path": "Quang Vinh, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Xã Quang Vinh, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01459",
        "parent_code": "046"
    },
    "01462": {
        "name": "Xuân Nội",
        "type": "xa",
        "slug": "xuan-noi",
        "name_with_type": "Xã Xuân Nội",
        "path": "Xuân Nội, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Xã Xuân Nội, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01462",
        "parent_code": "046"
    },
    "01465": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Xã Quang Trung, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01465",
        "parent_code": "046"
    },
    "01468": {
        "name": "Lưu Ngọc",
        "type": "xa",
        "slug": "luu-ngoc",
        "name_with_type": "Xã Lưu Ngọc",
        "path": "Lưu Ngọc, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Xã Lưu Ngọc, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01468",
        "parent_code": "046"
    },
    "01471": {
        "name": "Cao Chương",
        "type": "xa",
        "slug": "cao-chuong",
        "name_with_type": "Xã Cao Chương",
        "path": "Cao Chương, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Xã Cao Chương, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01471",
        "parent_code": "046"
    },
    "01474": {
        "name": "Quốc Toản",
        "type": "xa",
        "slug": "quoc-toan",
        "name_with_type": "Xã Quốc Toản",
        "path": "Quốc Toản, Trà Lĩnh, Cao Bằng",
        "path_with_type": "Xã Quốc Toản, Huyện Trà Lĩnh, Tỉnh Cao Bằng",
        "code": "01474",
        "parent_code": "046"
    },
    "01477": {
        "name": "Trùng Khánh",
        "type": "thi-tran",
        "slug": "trung-khanh",
        "name_with_type": "Thị trấn Trùng Khánh",
        "path": "Trùng Khánh, Trùng Khánh, Cao Bằng",
        "path_with_type": "Thị trấn Trùng Khánh, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01477",
        "parent_code": "047"
    },
    "01480": {
        "name": "Ngọc Khê",
        "type": "xa",
        "slug": "ngoc-khe",
        "name_with_type": "Xã Ngọc Khê",
        "path": "Ngọc Khê, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Ngọc Khê, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01480",
        "parent_code": "047"
    },
    "01481": {
        "name": "Ngọc Côn",
        "type": "xa",
        "slug": "ngoc-con",
        "name_with_type": "Xã Ngọc Côn",
        "path": "Ngọc Côn, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Ngọc Côn, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01481",
        "parent_code": "047"
    },
    "01483": {
        "name": "Phong Nậm",
        "type": "xa",
        "slug": "phong-nam",
        "name_with_type": "Xã Phong Nậm",
        "path": "Phong Nậm, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Phong Nậm, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01483",
        "parent_code": "047"
    },
    "01486": {
        "name": "Ngọc Chung",
        "type": "xa",
        "slug": "ngoc-chung",
        "name_with_type": "Xã Ngọc Chung",
        "path": "Ngọc Chung, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Ngọc Chung, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01486",
        "parent_code": "047"
    },
    "01489": {
        "name": "Đình Phong",
        "type": "xa",
        "slug": "dinh-phong",
        "name_with_type": "Xã Đình Phong",
        "path": "Đình Phong, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Đình Phong, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01489",
        "parent_code": "047"
    },
    "01492": {
        "name": "Lăng Yên",
        "type": "xa",
        "slug": "lang-yen",
        "name_with_type": "Xã Lăng Yên",
        "path": "Lăng Yên, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Lăng Yên, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01492",
        "parent_code": "047"
    },
    "01495": {
        "name": "Đàm Thuỷ",
        "type": "xa",
        "slug": "dam-thuy",
        "name_with_type": "Xã Đàm Thuỷ",
        "path": "Đàm Thuỷ, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Đàm Thuỷ, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01495",
        "parent_code": "047"
    },
    "01498": {
        "name": "Khâm Thành",
        "type": "xa",
        "slug": "kham-thanh",
        "name_with_type": "Xã Khâm Thành",
        "path": "Khâm Thành, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Khâm Thành, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01498",
        "parent_code": "047"
    },
    "01501": {
        "name": "Chí Viễn",
        "type": "xa",
        "slug": "chi-vien",
        "name_with_type": "Xã Chí Viễn",
        "path": "Chí Viễn, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Chí Viễn, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01501",
        "parent_code": "047"
    },
    "01504": {
        "name": "Lăng Hiếu",
        "type": "xa",
        "slug": "lang-hieu",
        "name_with_type": "Xã Lăng Hiếu",
        "path": "Lăng Hiếu, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Lăng Hiếu, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01504",
        "parent_code": "047"
    },
    "01507": {
        "name": "Phong Châu",
        "type": "xa",
        "slug": "phong-chau",
        "name_with_type": "Xã Phong Châu",
        "path": "Phong Châu, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Phong Châu, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01507",
        "parent_code": "047"
    },
    "01510": {
        "name": "Đình Minh",
        "type": "xa",
        "slug": "dinh-minh",
        "name_with_type": "Xã Đình Minh",
        "path": "Đình Minh, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Đình Minh, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01510",
        "parent_code": "047"
    },
    "01513": {
        "name": "Cảnh Tiên",
        "type": "xa",
        "slug": "canh-tien",
        "name_with_type": "Xã Cảnh Tiên",
        "path": "Cảnh Tiên, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Cảnh Tiên, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01513",
        "parent_code": "047"
    },
    "01516": {
        "name": "Trung Phúc",
        "type": "xa",
        "slug": "trung-phuc",
        "name_with_type": "Xã Trung Phúc",
        "path": "Trung Phúc, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Trung Phúc, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01516",
        "parent_code": "047"
    },
    "01519": {
        "name": "Cao Thăng",
        "type": "xa",
        "slug": "cao-thang",
        "name_with_type": "Xã Cao Thăng",
        "path": "Cao Thăng, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Cao Thăng, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01519",
        "parent_code": "047"
    },
    "01522": {
        "name": "Đức Hồng",
        "type": "xa",
        "slug": "duc-hong",
        "name_with_type": "Xã Đức Hồng",
        "path": "Đức Hồng, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Đức Hồng, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01522",
        "parent_code": "047"
    },
    "01525": {
        "name": "Thông Hoè",
        "type": "xa",
        "slug": "thong-hoe",
        "name_with_type": "Xã Thông Hoè",
        "path": "Thông Hoè, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Thông Hoè, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01525",
        "parent_code": "047"
    },
    "01528": {
        "name": "Thân Giáp",
        "type": "xa",
        "slug": "than-giap",
        "name_with_type": "Xã Thân Giáp",
        "path": "Thân Giáp, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Thân Giáp, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01528",
        "parent_code": "047"
    },
    "01531": {
        "name": "Đoài Côn",
        "type": "xa",
        "slug": "doai-con",
        "name_with_type": "Xã Đoài Côn",
        "path": "Đoài Côn, Trùng Khánh, Cao Bằng",
        "path_with_type": "Xã Đoài Côn, Huyện Trùng Khánh, Tỉnh Cao Bằng",
        "code": "01531",
        "parent_code": "047"
    },
    "01534": {
        "name": "Minh Long",
        "type": "xa",
        "slug": "minh-long",
        "name_with_type": "Xã Minh Long",
        "path": "Minh Long, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Minh Long, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01534",
        "parent_code": "048"
    },
    "01537": {
        "name": "Lý Quốc",
        "type": "xa",
        "slug": "ly-quoc",
        "name_with_type": "Xã Lý Quốc",
        "path": "Lý Quốc, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Lý Quốc, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01537",
        "parent_code": "048"
    },
    "01540": {
        "name": "Thắng Lợi",
        "type": "xa",
        "slug": "thang-loi",
        "name_with_type": "Xã Thắng Lợi",
        "path": "Thắng Lợi, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Thắng Lợi, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01540",
        "parent_code": "048"
    },
    "01543": {
        "name": "Đồng Loan",
        "type": "xa",
        "slug": "dong-loan",
        "name_with_type": "Xã Đồng Loan",
        "path": "Đồng Loan, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Đồng Loan, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01543",
        "parent_code": "048"
    },
    "01546": {
        "name": "Đức Quang",
        "type": "xa",
        "slug": "duc-quang",
        "name_with_type": "Xã Đức Quang",
        "path": "Đức Quang, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Đức Quang, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01546",
        "parent_code": "048"
    },
    "01549": {
        "name": "Kim Loan",
        "type": "xa",
        "slug": "kim-loan",
        "name_with_type": "Xã Kim Loan",
        "path": "Kim Loan, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Kim Loan, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01549",
        "parent_code": "048"
    },
    "01552": {
        "name": "Quang Long",
        "type": "xa",
        "slug": "quang-long",
        "name_with_type": "Xã Quang Long",
        "path": "Quang Long, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Quang Long, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01552",
        "parent_code": "048"
    },
    "01555": {
        "name": "An Lạc",
        "type": "xa",
        "slug": "an-lac",
        "name_with_type": "Xã An Lạc",
        "path": "An Lạc, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã An Lạc, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01555",
        "parent_code": "048"
    },
    "01558": {
        "name": "Thanh Nhật",
        "type": "thi-tran",
        "slug": "thanh-nhat",
        "name_with_type": "Thị trấn Thanh Nhật",
        "path": "Thanh Nhật, Hạ Lang, Cao Bằng",
        "path_with_type": "Thị trấn Thanh Nhật, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01558",
        "parent_code": "048"
    },
    "01561": {
        "name": "Vinh Quý",
        "type": "xa",
        "slug": "vinh-quy",
        "name_with_type": "Xã Vinh Quý",
        "path": "Vinh Quý, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Vinh Quý, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01561",
        "parent_code": "048"
    },
    "01564": {
        "name": "Việt Chu",
        "type": "xa",
        "slug": "viet-chu",
        "name_with_type": "Xã Việt Chu",
        "path": "Việt Chu, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Việt Chu, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01564",
        "parent_code": "048"
    },
    "01567": {
        "name": "Cô Ngân",
        "type": "xa",
        "slug": "co-ngan",
        "name_with_type": "Xã Cô Ngân",
        "path": "Cô Ngân, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Cô Ngân, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01567",
        "parent_code": "048"
    },
    "01570": {
        "name": "Thái Đức",
        "type": "xa",
        "slug": "thai-duc",
        "name_with_type": "Xã Thái Đức",
        "path": "Thái Đức, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Thái Đức, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01570",
        "parent_code": "048"
    },
    "01573": {
        "name": "Thị Hoa",
        "type": "xa",
        "slug": "thi-hoa",
        "name_with_type": "Xã Thị Hoa",
        "path": "Thị Hoa, Hạ Lang, Cao Bằng",
        "path_with_type": "Xã Thị Hoa, Huyện Hạ Lang, Tỉnh Cao Bằng",
        "code": "01573",
        "parent_code": "048"
    },
    "01576": {
        "name": "Quảng Uyên",
        "type": "thi-tran",
        "slug": "quang-uyen",
        "name_with_type": "Thị trấn Quảng Uyên",
        "path": "Quảng Uyên, Quảng Uyên, Cao Bằng",
        "path_with_type": "Thị trấn Quảng Uyên, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01576",
        "parent_code": "049"
    },
    "01579": {
        "name": "Phi Hải",
        "type": "xa",
        "slug": "phi-hai",
        "name_with_type": "Xã Phi Hải",
        "path": "Phi Hải, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Phi Hải, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01579",
        "parent_code": "049"
    },
    "01582": {
        "name": "Quảng Hưng",
        "type": "xa",
        "slug": "quang-hung",
        "name_with_type": "Xã Quảng Hưng",
        "path": "Quảng Hưng, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Quảng Hưng, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01582",
        "parent_code": "049"
    },
    "01585": {
        "name": "Bình Lăng",
        "type": "xa",
        "slug": "binh-lang",
        "name_with_type": "Xã Bình Lăng",
        "path": "Bình Lăng, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Bình Lăng, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01585",
        "parent_code": "049"
    },
    "01588": {
        "name": "Quốc Dân",
        "type": "xa",
        "slug": "quoc-dan",
        "name_with_type": "Xã Quốc Dân",
        "path": "Quốc Dân, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Quốc Dân, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01588",
        "parent_code": "049"
    },
    "01591": {
        "name": "Quốc Phong",
        "type": "xa",
        "slug": "quoc-phong",
        "name_with_type": "Xã Quốc Phong",
        "path": "Quốc Phong, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Quốc Phong, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01591",
        "parent_code": "049"
    },
    "01594": {
        "name": "Độc Lập",
        "type": "xa",
        "slug": "doc-lap",
        "name_with_type": "Xã Độc Lập",
        "path": "Độc Lập, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Độc Lập, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01594",
        "parent_code": "049"
    },
    "01597": {
        "name": "Cai Bộ",
        "type": "xa",
        "slug": "cai-bo",
        "name_with_type": "Xã Cai Bộ",
        "path": "Cai Bộ, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Cai Bộ, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01597",
        "parent_code": "049"
    },
    "01600": {
        "name": "Đoài Khôn",
        "type": "xa",
        "slug": "doai-khon",
        "name_with_type": "Xã Đoài Khôn",
        "path": "Đoài Khôn, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Đoài Khôn, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01600",
        "parent_code": "049"
    },
    "01603": {
        "name": "Phúc Sen",
        "type": "xa",
        "slug": "phuc-sen",
        "name_with_type": "Xã Phúc Sen",
        "path": "Phúc Sen, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Phúc Sen, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01603",
        "parent_code": "049"
    },
    "01606": {
        "name": "Chí Thảo",
        "type": "xa",
        "slug": "chi-thao",
        "name_with_type": "Xã Chí Thảo",
        "path": "Chí Thảo, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Chí Thảo, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01606",
        "parent_code": "049"
    },
    "01609": {
        "name": "Tự Do",
        "type": "xa",
        "slug": "tu-do",
        "name_with_type": "Xã Tự Do",
        "path": "Tự Do, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Tự Do, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01609",
        "parent_code": "049"
    },
    "01612": {
        "name": "Hồng Định",
        "type": "xa",
        "slug": "hong-dinh",
        "name_with_type": "Xã Hồng Định",
        "path": "Hồng Định, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Hồng Định, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01612",
        "parent_code": "049"
    },
    "01615": {
        "name": "Hồng Quang",
        "type": "xa",
        "slug": "hong-quang",
        "name_with_type": "Xã Hồng Quang",
        "path": "Hồng Quang, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Hồng Quang, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01615",
        "parent_code": "049"
    },
    "01618": {
        "name": "Ngọc Động",
        "type": "xa",
        "slug": "ngoc-dong",
        "name_with_type": "Xã Ngọc Động",
        "path": "Ngọc Động, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Ngọc Động, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01618",
        "parent_code": "049"
    },
    "01621": {
        "name": "Hoàng Hải",
        "type": "xa",
        "slug": "hoang-hai",
        "name_with_type": "Xã Hoàng Hải",
        "path": "Hoàng Hải, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Hoàng Hải, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01621",
        "parent_code": "049"
    },
    "01624": {
        "name": "Hạnh Phúc",
        "type": "xa",
        "slug": "hanh-phuc",
        "name_with_type": "Xã Hạnh Phúc",
        "path": "Hạnh Phúc, Quảng Uyên, Cao Bằng",
        "path_with_type": "Xã Hạnh Phúc, Huyện Quảng Uyên, Tỉnh Cao Bằng",
        "code": "01624",
        "parent_code": "049"
    },
    "01627": {
        "name": "Tà Lùng",
        "type": "thi-tran",
        "slug": "ta-lung",
        "name_with_type": "Thị trấn Tà Lùng",
        "path": "Tà Lùng, Phục Hoà, Cao Bằng",
        "path_with_type": "Thị trấn Tà Lùng, Huyện Phục Hoà, Tỉnh Cao Bằng",
        "code": "01627",
        "parent_code": "050"
    },
    "01630": {
        "name": "Triệu ẩu",
        "type": "xa",
        "slug": "trieu-au",
        "name_with_type": "Xã Triệu ẩu",
        "path": "Triệu ẩu, Phục Hoà, Cao Bằng",
        "path_with_type": "Xã Triệu ẩu, Huyện Phục Hoà, Tỉnh Cao Bằng",
        "code": "01630",
        "parent_code": "050"
    },
    "01633": {
        "name": "Hồng Đại",
        "type": "xa",
        "slug": "hong-dai",
        "name_with_type": "Xã Hồng Đại",
        "path": "Hồng Đại, Phục Hoà, Cao Bằng",
        "path_with_type": "Xã Hồng Đại, Huyện Phục Hoà, Tỉnh Cao Bằng",
        "code": "01633",
        "parent_code": "050"
    },
    "01636": {
        "name": "Cách Linh",
        "type": "xa",
        "slug": "cach-linh",
        "name_with_type": "Xã Cách Linh",
        "path": "Cách Linh, Phục Hoà, Cao Bằng",
        "path_with_type": "Xã Cách Linh, Huyện Phục Hoà, Tỉnh Cao Bằng",
        "code": "01636",
        "parent_code": "050"
    },
    "01639": {
        "name": "Đại Sơn",
        "type": "xa",
        "slug": "dai-son",
        "name_with_type": "Xã Đại Sơn",
        "path": "Đại Sơn, Phục Hoà, Cao Bằng",
        "path_with_type": "Xã Đại Sơn, Huyện Phục Hoà, Tỉnh Cao Bằng",
        "code": "01639",
        "parent_code": "050"
    },
    "01642": {
        "name": "Lương Thiện",
        "type": "xa",
        "slug": "luong-thien",
        "name_with_type": "Xã Lương Thiện",
        "path": "Lương Thiện, Phục Hoà, Cao Bằng",
        "path_with_type": "Xã Lương Thiện, Huyện Phục Hoà, Tỉnh Cao Bằng",
        "code": "01642",
        "parent_code": "050"
    },
    "01645": {
        "name": "Tiên Thành",
        "type": "xa",
        "slug": "tien-thanh",
        "name_with_type": "Xã Tiên Thành",
        "path": "Tiên Thành, Phục Hoà, Cao Bằng",
        "path_with_type": "Xã Tiên Thành, Huyện Phục Hoà, Tỉnh Cao Bằng",
        "code": "01645",
        "parent_code": "050"
    },
    "01648": {
        "name": "Hoà Thuận",
        "type": "thi-tran",
        "slug": "hoa-thuan",
        "name_with_type": "Thị trấn Hoà Thuận",
        "path": "Hoà Thuận, Phục Hoà, Cao Bằng",
        "path_with_type": "Thị trấn Hoà Thuận, Huyện Phục Hoà, Tỉnh Cao Bằng",
        "code": "01648",
        "parent_code": "050"
    },
    "01651": {
        "name": "Mỹ Hưng",
        "type": "xa",
        "slug": "my-hung",
        "name_with_type": "Xã Mỹ Hưng",
        "path": "Mỹ Hưng, Phục Hoà, Cao Bằng",
        "path_with_type": "Xã Mỹ Hưng, Huyện Phục Hoà, Tỉnh Cao Bằng",
        "code": "01651",
        "parent_code": "050"
    },
    "01654": {
        "name": "Nước Hai",
        "type": "thi-tran",
        "slug": "nuoc-hai",
        "name_with_type": "Thị trấn Nước Hai",
        "path": "Nước Hai, Hoà An, Cao Bằng",
        "path_with_type": "Thị trấn Nước Hai, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01654",
        "parent_code": "051"
    },
    "01657": {
        "name": "Dân Chủ",
        "type": "xa",
        "slug": "dan-chu",
        "name_with_type": "Xã Dân Chủ",
        "path": "Dân Chủ, Hoà An, Cao Bằng",
        "path_with_type": "Xã Dân Chủ, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01657",
        "parent_code": "051"
    },
    "01660": {
        "name": "Nam Tuấn",
        "type": "xa",
        "slug": "nam-tuan",
        "name_with_type": "Xã Nam Tuấn",
        "path": "Nam Tuấn, Hoà An, Cao Bằng",
        "path_with_type": "Xã Nam Tuấn, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01660",
        "parent_code": "051"
    },
    "01663": {
        "name": "Đức Xuân",
        "type": "xa",
        "slug": "duc-xuan",
        "name_with_type": "Xã Đức Xuân",
        "path": "Đức Xuân, Hoà An, Cao Bằng",
        "path_with_type": "Xã Đức Xuân, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01663",
        "parent_code": "051"
    },
    "01666": {
        "name": "Đại Tiến",
        "type": "xa",
        "slug": "dai-tien",
        "name_with_type": "Xã Đại Tiến",
        "path": "Đại Tiến, Hoà An, Cao Bằng",
        "path_with_type": "Xã Đại Tiến, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01666",
        "parent_code": "051"
    },
    "01669": {
        "name": "Đức Long",
        "type": "xa",
        "slug": "duc-long",
        "name_with_type": "Xã Đức Long",
        "path": "Đức Long, Hoà An, Cao Bằng",
        "path_with_type": "Xã Đức Long, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01669",
        "parent_code": "051"
    },
    "01672": {
        "name": "Ngũ Lão",
        "type": "xa",
        "slug": "ngu-lao",
        "name_with_type": "Xã Ngũ Lão",
        "path": "Ngũ Lão, Hoà An, Cao Bằng",
        "path_with_type": "Xã Ngũ Lão, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01672",
        "parent_code": "051"
    },
    "01675": {
        "name": "Trương Lương",
        "type": "xa",
        "slug": "truong-luong",
        "name_with_type": "Xã Trương Lương",
        "path": "Trương Lương, Hoà An, Cao Bằng",
        "path_with_type": "Xã Trương Lương, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01675",
        "parent_code": "051"
    },
    "01678": {
        "name": "Bình Long",
        "type": "xa",
        "slug": "binh-long",
        "name_with_type": "Xã Bình Long",
        "path": "Bình Long, Hoà An, Cao Bằng",
        "path_with_type": "Xã Bình Long, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01678",
        "parent_code": "051"
    },
    "01681": {
        "name": "Nguyễn Huệ",
        "type": "xa",
        "slug": "nguyen-hue",
        "name_with_type": "Xã Nguyễn Huệ",
        "path": "Nguyễn Huệ, Hoà An, Cao Bằng",
        "path_with_type": "Xã Nguyễn Huệ, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01681",
        "parent_code": "051"
    },
    "01684": {
        "name": "Công Trừng",
        "type": "xa",
        "slug": "cong-trung",
        "name_with_type": "Xã Công Trừng",
        "path": "Công Trừng, Hoà An, Cao Bằng",
        "path_with_type": "Xã Công Trừng, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01684",
        "parent_code": "051"
    },
    "01687": {
        "name": "Hồng Việt",
        "type": "xa",
        "slug": "hong-viet",
        "name_with_type": "Xã Hồng Việt",
        "path": "Hồng Việt, Hoà An, Cao Bằng",
        "path_with_type": "Xã Hồng Việt, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01687",
        "parent_code": "051"
    },
    "01690": {
        "name": "Bế Triều",
        "type": "xa",
        "slug": "be-trieu",
        "name_with_type": "Xã Bế Triều",
        "path": "Bế Triều, Hoà An, Cao Bằng",
        "path_with_type": "Xã Bế Triều, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01690",
        "parent_code": "051"
    },
    "01696": {
        "name": "Hoàng Tung",
        "type": "xa",
        "slug": "hoang-tung",
        "name_with_type": "Xã Hoàng Tung",
        "path": "Hoàng Tung, Hoà An, Cao Bằng",
        "path_with_type": "Xã Hoàng Tung, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01696",
        "parent_code": "051"
    },
    "01699": {
        "name": "Trương Vương",
        "type": "xa",
        "slug": "truong-vuong",
        "name_with_type": "Xã Trương Vương",
        "path": "Trương Vương, Hoà An, Cao Bằng",
        "path_with_type": "Xã Trương Vương, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01699",
        "parent_code": "051"
    },
    "01702": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Hoà An, Cao Bằng",
        "path_with_type": "Xã Quang Trung, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01702",
        "parent_code": "051"
    },
    "01708": {
        "name": "Bạch Đằng",
        "type": "xa",
        "slug": "bach-dang",
        "name_with_type": "Xã Bạch Đằng",
        "path": "Bạch Đằng, Hoà An, Cao Bằng",
        "path_with_type": "Xã Bạch Đằng, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01708",
        "parent_code": "051"
    },
    "01711": {
        "name": "Bình Dương",
        "type": "xa",
        "slug": "binh-duong",
        "name_with_type": "Xã Bình Dương",
        "path": "Bình Dương, Hoà An, Cao Bằng",
        "path_with_type": "Xã Bình Dương, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01711",
        "parent_code": "051"
    },
    "01714": {
        "name": "Lê Chung",
        "type": "xa",
        "slug": "le-chung",
        "name_with_type": "Xã Lê Chung",
        "path": "Lê Chung, Hoà An, Cao Bằng",
        "path_with_type": "Xã Lê Chung, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01714",
        "parent_code": "051"
    },
    "01717": {
        "name": "Hà Trì",
        "type": "xa",
        "slug": "ha-tri",
        "name_with_type": "Xã Hà Trì",
        "path": "Hà Trì, Hoà An, Cao Bằng",
        "path_with_type": "Xã Hà Trì, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01717",
        "parent_code": "051"
    },
    "01723": {
        "name": "Hồng Nam",
        "type": "xa",
        "slug": "hong-nam",
        "name_with_type": "Xã Hồng Nam",
        "path": "Hồng Nam, Hoà An, Cao Bằng",
        "path_with_type": "Xã Hồng Nam, Huyện Hoà An, Tỉnh Cao Bằng",
        "code": "01723",
        "parent_code": "051"
    },
    "01726": {
        "name": "Nguyên Bình",
        "type": "thi-tran",
        "slug": "nguyen-binh",
        "name_with_type": "Thị trấn Nguyên Bình",
        "path": "Nguyên Bình, Nguyên Bình, Cao Bằng",
        "path_with_type": "Thị trấn Nguyên Bình, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01726",
        "parent_code": "052"
    },
    "01729": {
        "name": "Tĩnh Túc",
        "type": "thi-tran",
        "slug": "tinh-tuc",
        "name_with_type": "Thị trấn Tĩnh Túc",
        "path": "Tĩnh Túc, Nguyên Bình, Cao Bằng",
        "path_with_type": "Thị trấn Tĩnh Túc, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01729",
        "parent_code": "052"
    },
    "01732": {
        "name": "Yên Lạc",
        "type": "xa",
        "slug": "yen-lac",
        "name_with_type": "Xã Yên Lạc",
        "path": "Yên Lạc, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Yên Lạc, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01732",
        "parent_code": "052"
    },
    "01735": {
        "name": "Triệu Nguyên",
        "type": "xa",
        "slug": "trieu-nguyen",
        "name_with_type": "Xã Triệu Nguyên",
        "path": "Triệu Nguyên, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Triệu Nguyên, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01735",
        "parent_code": "052"
    },
    "01738": {
        "name": "Ca Thành",
        "type": "xa",
        "slug": "ca-thanh",
        "name_with_type": "Xã Ca Thành",
        "path": "Ca Thành, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Ca Thành, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01738",
        "parent_code": "052"
    },
    "01741": {
        "name": "Thái Học",
        "type": "xa",
        "slug": "thai-hoc",
        "name_with_type": "Xã Thái Học",
        "path": "Thái Học, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Thái Học, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01741",
        "parent_code": "052"
    },
    "01744": {
        "name": "Vũ Nông",
        "type": "xa",
        "slug": "vu-nong",
        "name_with_type": "Xã Vũ Nông",
        "path": "Vũ Nông, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Vũ Nông, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01744",
        "parent_code": "052"
    },
    "01747": {
        "name": "Minh Tâm",
        "type": "xa",
        "slug": "minh-tam",
        "name_with_type": "Xã Minh Tâm",
        "path": "Minh Tâm, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Minh Tâm, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01747",
        "parent_code": "052"
    },
    "01750": {
        "name": "Thể Dục",
        "type": "xa",
        "slug": "the-duc",
        "name_with_type": "Xã Thể Dục",
        "path": "Thể Dục, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Thể Dục, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01750",
        "parent_code": "052"
    },
    "01753": {
        "name": "Bắc Hợp",
        "type": "xa",
        "slug": "bac-hop",
        "name_with_type": "Xã Bắc Hợp",
        "path": "Bắc Hợp, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Bắc Hợp, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01753",
        "parent_code": "052"
    },
    "01756": {
        "name": "Mai Long",
        "type": "xa",
        "slug": "mai-long",
        "name_with_type": "Xã Mai Long",
        "path": "Mai Long, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Mai Long, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01756",
        "parent_code": "052"
    },
    "01759": {
        "name": "Lang Môn",
        "type": "xa",
        "slug": "lang-mon",
        "name_with_type": "Xã Lang Môn",
        "path": "Lang Môn, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Lang Môn, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01759",
        "parent_code": "052"
    },
    "01762": {
        "name": "Minh Thanh",
        "type": "xa",
        "slug": "minh-thanh",
        "name_with_type": "Xã Minh Thanh",
        "path": "Minh Thanh, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Minh Thanh, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01762",
        "parent_code": "052"
    },
    "01765": {
        "name": "Hoa Thám",
        "type": "xa",
        "slug": "hoa-tham",
        "name_with_type": "Xã Hoa Thám",
        "path": "Hoa Thám, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Hoa Thám, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01765",
        "parent_code": "052"
    },
    "01768": {
        "name": "Phan Thanh",
        "type": "xa",
        "slug": "phan-thanh",
        "name_with_type": "Xã Phan Thanh",
        "path": "Phan Thanh, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Phan Thanh, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01768",
        "parent_code": "052"
    },
    "01771": {
        "name": "Quang Thành",
        "type": "xa",
        "slug": "quang-thanh",
        "name_with_type": "Xã Quang Thành",
        "path": "Quang Thành, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Quang Thành, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01771",
        "parent_code": "052"
    },
    "01774": {
        "name": "Tam Kim",
        "type": "xa",
        "slug": "tam-kim",
        "name_with_type": "Xã Tam Kim",
        "path": "Tam Kim, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Tam Kim, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01774",
        "parent_code": "052"
    },
    "01777": {
        "name": "Thành Công",
        "type": "xa",
        "slug": "thanh-cong",
        "name_with_type": "Xã Thành Công",
        "path": "Thành Công, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Thành Công, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01777",
        "parent_code": "052"
    },
    "01780": {
        "name": "Thịnh Vượng",
        "type": "xa",
        "slug": "thinh-vuong",
        "name_with_type": "Xã Thịnh Vượng",
        "path": "Thịnh Vượng, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Thịnh Vượng, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01780",
        "parent_code": "052"
    },
    "01783": {
        "name": "Hưng Đạo",
        "type": "xa",
        "slug": "hung-dao",
        "name_with_type": "Xã Hưng Đạo",
        "path": "Hưng Đạo, Nguyên Bình, Cao Bằng",
        "path_with_type": "Xã Hưng Đạo, Huyện Nguyên Bình, Tỉnh Cao Bằng",
        "code": "01783",
        "parent_code": "052"
    },
    "01786": {
        "name": "Đông Khê",
        "type": "thi-tran",
        "slug": "dong-khe",
        "name_with_type": "Thị trấn Đông Khê",
        "path": "Đông Khê, Thạch An, Cao Bằng",
        "path_with_type": "Thị trấn Đông Khê, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01786",
        "parent_code": "053"
    },
    "01789": {
        "name": "Canh Tân",
        "type": "xa",
        "slug": "canh-tan",
        "name_with_type": "Xã Canh Tân",
        "path": "Canh Tân, Thạch An, Cao Bằng",
        "path_with_type": "Xã Canh Tân, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01789",
        "parent_code": "053"
    },
    "01792": {
        "name": "Kim Đồng",
        "type": "xa",
        "slug": "kim-dong",
        "name_with_type": "Xã Kim Đồng",
        "path": "Kim Đồng, Thạch An, Cao Bằng",
        "path_with_type": "Xã Kim Đồng, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01792",
        "parent_code": "053"
    },
    "01795": {
        "name": "Minh Khai",
        "type": "xa",
        "slug": "minh-khai",
        "name_with_type": "Xã Minh Khai",
        "path": "Minh Khai, Thạch An, Cao Bằng",
        "path_with_type": "Xã Minh Khai, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01795",
        "parent_code": "053"
    },
    "01798": {
        "name": "Thị Ngân",
        "type": "xa",
        "slug": "thi-ngan",
        "name_with_type": "Xã Thị Ngân",
        "path": "Thị Ngân, Thạch An, Cao Bằng",
        "path_with_type": "Xã Thị Ngân, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01798",
        "parent_code": "053"
    },
    "01801": {
        "name": "Đức Thông",
        "type": "xa",
        "slug": "duc-thong",
        "name_with_type": "Xã Đức Thông",
        "path": "Đức Thông, Thạch An, Cao Bằng",
        "path_with_type": "Xã Đức Thông, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01801",
        "parent_code": "053"
    },
    "01804": {
        "name": "Thái Cường",
        "type": "xa",
        "slug": "thai-cuong",
        "name_with_type": "Xã Thái Cường",
        "path": "Thái Cường, Thạch An, Cao Bằng",
        "path_with_type": "Xã Thái Cường, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01804",
        "parent_code": "053"
    },
    "01807": {
        "name": "Vân Trình",
        "type": "xa",
        "slug": "van-trinh",
        "name_with_type": "Xã Vân Trình",
        "path": "Vân Trình, Thạch An, Cao Bằng",
        "path_with_type": "Xã Vân Trình, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01807",
        "parent_code": "053"
    },
    "01810": {
        "name": "Thụy Hùng",
        "type": "xa",
        "slug": "thuy-hung",
        "name_with_type": "Xã Thụy Hùng",
        "path": "Thụy Hùng, Thạch An, Cao Bằng",
        "path_with_type": "Xã Thụy Hùng, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01810",
        "parent_code": "053"
    },
    "01813": {
        "name": "Quang Trọng",
        "type": "xa",
        "slug": "quang-trong",
        "name_with_type": "Xã Quang Trọng",
        "path": "Quang Trọng, Thạch An, Cao Bằng",
        "path_with_type": "Xã Quang Trọng, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01813",
        "parent_code": "053"
    },
    "01816": {
        "name": "Trọng Con",
        "type": "xa",
        "slug": "trong-con",
        "name_with_type": "Xã Trọng Con",
        "path": "Trọng Con, Thạch An, Cao Bằng",
        "path_with_type": "Xã Trọng Con, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01816",
        "parent_code": "053"
    },
    "01819": {
        "name": "Lê Lai",
        "type": "xa",
        "slug": "le-lai",
        "name_with_type": "Xã Lê Lai",
        "path": "Lê Lai, Thạch An, Cao Bằng",
        "path_with_type": "Xã Lê Lai, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01819",
        "parent_code": "053"
    },
    "01822": {
        "name": "Đức Long",
        "type": "xa",
        "slug": "duc-long",
        "name_with_type": "Xã Đức Long",
        "path": "Đức Long, Thạch An, Cao Bằng",
        "path_with_type": "Xã Đức Long, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01822",
        "parent_code": "053"
    },
    "01825": {
        "name": "Danh Sỹ",
        "type": "xa",
        "slug": "danh-sy",
        "name_with_type": "Xã Danh Sỹ",
        "path": "Danh Sỹ, Thạch An, Cao Bằng",
        "path_with_type": "Xã Danh Sỹ, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01825",
        "parent_code": "053"
    },
    "01828": {
        "name": "Lê Lợi",
        "type": "xa",
        "slug": "le-loi",
        "name_with_type": "Xã Lê Lợi",
        "path": "Lê Lợi, Thạch An, Cao Bằng",
        "path_with_type": "Xã Lê Lợi, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01828",
        "parent_code": "053"
    },
    "01831": {
        "name": "Đức Xuân",
        "type": "xa",
        "slug": "duc-xuan",
        "name_with_type": "Xã Đức Xuân",
        "path": "Đức Xuân, Thạch An, Cao Bằng",
        "path_with_type": "Xã Đức Xuân, Huyện Thạch An, Tỉnh Cao Bằng",
        "code": "01831",
        "parent_code": "053"
    },
    "11296": {
        "name": "Quán Toan",
        "type": "phuong",
        "slug": "quan-toan",
        "name_with_type": "Phường Quán Toan",
        "path": "Quán Toan, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Quán Toan, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11296",
        "parent_code": "303"
    },
    "11299": {
        "name": "Hùng Vương",
        "type": "phuong",
        "slug": "hung-vuong",
        "name_with_type": "Phường Hùng Vương",
        "path": "Hùng Vương, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Hùng Vương, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11299",
        "parent_code": "303"
    },
    "11302": {
        "name": "Sở Dầu",
        "type": "phuong",
        "slug": "so-dau",
        "name_with_type": "Phường Sở Dầu",
        "path": "Sở Dầu, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Sở Dầu, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11302",
        "parent_code": "303"
    },
    "11305": {
        "name": "Thượng Lý",
        "type": "phuong",
        "slug": "thuong-ly",
        "name_with_type": "Phường Thượng Lý",
        "path": "Thượng Lý, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Thượng Lý, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11305",
        "parent_code": "303"
    },
    "11308": {
        "name": "Hạ Lý",
        "type": "phuong",
        "slug": "ha-ly",
        "name_with_type": "Phường Hạ Lý",
        "path": "Hạ Lý, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Hạ Lý, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11308",
        "parent_code": "303"
    },
    "11311": {
        "name": "Minh Khai",
        "type": "phuong",
        "slug": "minh-khai",
        "name_with_type": "Phường Minh Khai",
        "path": "Minh Khai, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Minh Khai, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11311",
        "parent_code": "303"
    },
    "11314": {
        "name": "Trại Chuối",
        "type": "phuong",
        "slug": "trai-chuoi",
        "name_with_type": "Phường Trại Chuối",
        "path": "Trại Chuối, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Trại Chuối, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11314",
        "parent_code": "303"
    },
    "11317": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Quang Trung, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11317",
        "parent_code": "303"
    },
    "11320": {
        "name": "Hoàng Văn Thụ",
        "type": "phuong",
        "slug": "hoang-van-thu",
        "name_with_type": "Phường Hoàng Văn Thụ",
        "path": "Hoàng Văn Thụ, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Hoàng Văn Thụ, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11320",
        "parent_code": "303"
    },
    "11323": {
        "name": "Phan Bội Châu",
        "type": "phuong",
        "slug": "phan-boi-chau",
        "name_with_type": "Phường Phan Bội Châu",
        "path": "Phan Bội Châu, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Phan Bội Châu, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11323",
        "parent_code": "303"
    },
    "11326": {
        "name": "Phạm Hồng Thái",
        "type": "phuong",
        "slug": "pham-hong-thai",
        "name_with_type": "Phường Phạm Hồng Thái",
        "path": "Phạm Hồng Thái, Hồng Bàng, Hải Phòng",
        "path_with_type": "Phường Phạm Hồng Thái, Quận Hồng Bàng, Thành phố Hải Phòng",
        "code": "11326",
        "parent_code": "303"
    },
    "11329": {
        "name": "Máy Chai",
        "type": "phuong",
        "slug": "may-chai",
        "name_with_type": "Phường Máy Chai",
        "path": "Máy Chai, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Máy Chai, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11329",
        "parent_code": "304"
    },
    "11332": {
        "name": "Máy Tơ",
        "type": "phuong",
        "slug": "may-to",
        "name_with_type": "Phường Máy Tơ",
        "path": "Máy Tơ, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Máy Tơ, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11332",
        "parent_code": "304"
    },
    "11335": {
        "name": "Vạn Mỹ",
        "type": "phuong",
        "slug": "van-my",
        "name_with_type": "Phường Vạn Mỹ",
        "path": "Vạn Mỹ, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Vạn Mỹ, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11335",
        "parent_code": "304"
    },
    "11338": {
        "name": "Cầu Tre",
        "type": "phuong",
        "slug": "cau-tre",
        "name_with_type": "Phường Cầu Tre",
        "path": "Cầu Tre, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Cầu Tre, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11338",
        "parent_code": "304"
    },
    "11341": {
        "name": "Lạc Viên",
        "type": "phuong",
        "slug": "lac-vien",
        "name_with_type": "Phường Lạc Viên",
        "path": "Lạc Viên, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Lạc Viên, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11341",
        "parent_code": "304"
    },
    "11344": {
        "name": "Lương Khánh Thiện",
        "type": "phuong",
        "slug": "luong-khanh-thien",
        "name_with_type": "Phường Lương Khánh Thiện",
        "path": "Lương Khánh Thiện, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Lương Khánh Thiện, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11344",
        "parent_code": "304"
    },
    "11347": {
        "name": "Gia Viên",
        "type": "phuong",
        "slug": "gia-vien",
        "name_with_type": "Phường Gia Viên",
        "path": "Gia Viên, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Gia Viên, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11347",
        "parent_code": "304"
    },
    "11350": {
        "name": "Đông Khê",
        "type": "phuong",
        "slug": "dong-khe",
        "name_with_type": "Phường Đông Khê",
        "path": "Đông Khê, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Đông Khê, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11350",
        "parent_code": "304"
    },
    "11353": {
        "name": "Cầu Đất",
        "type": "phuong",
        "slug": "cau-dat",
        "name_with_type": "Phường Cầu Đất",
        "path": "Cầu Đất, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Cầu Đất, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11353",
        "parent_code": "304"
    },
    "11356": {
        "name": "Lê Lợi",
        "type": "phuong",
        "slug": "le-loi",
        "name_with_type": "Phường Lê Lợi",
        "path": "Lê Lợi, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Lê Lợi, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11356",
        "parent_code": "304"
    },
    "11359": {
        "name": "Đằng Giang",
        "type": "phuong",
        "slug": "dang-giang",
        "name_with_type": "Phường Đằng Giang",
        "path": "Đằng Giang, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Đằng Giang, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11359",
        "parent_code": "304"
    },
    "11362": {
        "name": "Lạch Tray",
        "type": "phuong",
        "slug": "lach-tray",
        "name_with_type": "Phường Lạch Tray",
        "path": "Lạch Tray, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Lạch Tray, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11362",
        "parent_code": "304"
    },
    "11365": {
        "name": "Đổng Quốc Bình",
        "type": "phuong",
        "slug": "dong-quoc-binh",
        "name_with_type": "Phường Đổng Quốc Bình",
        "path": "Đổng Quốc Bình, Ngô Quyền, Hải Phòng",
        "path_with_type": "Phường Đổng Quốc Bình, Quận Ngô Quyền, Thành phố Hải Phòng",
        "code": "11365",
        "parent_code": "304"
    },
    "11368": {
        "name": "Cát Dài",
        "type": "phuong",
        "slug": "cat-dai",
        "name_with_type": "Phường Cát Dài",
        "path": "Cát Dài, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Cát Dài, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11368",
        "parent_code": "305"
    },
    "11371": {
        "name": "An Biên",
        "type": "phuong",
        "slug": "an-bien",
        "name_with_type": "Phường An Biên",
        "path": "An Biên, Lê Chân, Hải Phòng",
        "path_with_type": "Phường An Biên, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11371",
        "parent_code": "305"
    },
    "11374": {
        "name": "Lam Sơn",
        "type": "phuong",
        "slug": "lam-son",
        "name_with_type": "Phường Lam Sơn",
        "path": "Lam Sơn, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Lam Sơn, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11374",
        "parent_code": "305"
    },
    "11377": {
        "name": "An Dương",
        "type": "phuong",
        "slug": "an-duong",
        "name_with_type": "Phường An Dương",
        "path": "An Dương, Lê Chân, Hải Phòng",
        "path_with_type": "Phường An Dương, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11377",
        "parent_code": "305"
    },
    "11380": {
        "name": "Trần Nguyên Hãn",
        "type": "phuong",
        "slug": "tran-nguyen-han",
        "name_with_type": "Phường Trần Nguyên Hãn",
        "path": "Trần Nguyên Hãn, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Trần Nguyên Hãn, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11380",
        "parent_code": "305"
    },
    "11383": {
        "name": "Hồ Nam",
        "type": "phuong",
        "slug": "ho-nam",
        "name_with_type": "Phường Hồ Nam",
        "path": "Hồ Nam, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Hồ Nam, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11383",
        "parent_code": "305"
    },
    "11386": {
        "name": "Trại Cau",
        "type": "phuong",
        "slug": "trai-cau",
        "name_with_type": "Phường Trại Cau",
        "path": "Trại Cau, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Trại Cau, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11386",
        "parent_code": "305"
    },
    "11389": {
        "name": "Dư Hàng",
        "type": "phuong",
        "slug": "du-hang",
        "name_with_type": "Phường Dư Hàng",
        "path": "Dư Hàng, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Dư Hàng, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11389",
        "parent_code": "305"
    },
    "11392": {
        "name": "Hàng Kênh",
        "type": "phuong",
        "slug": "hang-kenh",
        "name_with_type": "Phường Hàng Kênh",
        "path": "Hàng Kênh, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Hàng Kênh, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11392",
        "parent_code": "305"
    },
    "11395": {
        "name": "Đông Hải",
        "type": "phuong",
        "slug": "dong-hai",
        "name_with_type": "Phường Đông Hải",
        "path": "Đông Hải, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Đông Hải, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11395",
        "parent_code": "305"
    },
    "11398": {
        "name": "Niệm Nghĩa",
        "type": "phuong",
        "slug": "niem-nghia",
        "name_with_type": "Phường Niệm Nghĩa",
        "path": "Niệm Nghĩa, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Niệm Nghĩa, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11398",
        "parent_code": "305"
    },
    "11401": {
        "name": "Nghĩa Xá",
        "type": "phuong",
        "slug": "nghia-xa",
        "name_with_type": "Phường Nghĩa Xá",
        "path": "Nghĩa Xá, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Nghĩa Xá, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11401",
        "parent_code": "305"
    },
    "11404": {
        "name": "Dư Hàng Kênh",
        "type": "phuong",
        "slug": "du-hang-kenh",
        "name_with_type": "Phường Dư Hàng Kênh",
        "path": "Dư Hàng Kênh, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Dư Hàng Kênh, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11404",
        "parent_code": "305"
    },
    "11405": {
        "name": "Kênh Dương",
        "type": "phuong",
        "slug": "kenh-duong",
        "name_with_type": "Phường Kênh Dương",
        "path": "Kênh Dương, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Kênh Dương, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11405",
        "parent_code": "305"
    },
    "11407": {
        "name": "Vĩnh Niệm",
        "type": "phuong",
        "slug": "vinh-niem",
        "name_with_type": "Phường Vĩnh Niệm",
        "path": "Vĩnh Niệm, Lê Chân, Hải Phòng",
        "path_with_type": "Phường Vĩnh Niệm, Quận Lê Chân, Thành phố Hải Phòng",
        "code": "11407",
        "parent_code": "305"
    },
    "11410": {
        "name": "Đông Hải 1",
        "type": "phuong",
        "slug": "dong-hai-1",
        "name_with_type": "Phường Đông Hải 1",
        "path": "Đông Hải 1, Hải An, Hải Phòng",
        "path_with_type": "Phường Đông Hải 1, Quận Hải An, Thành phố Hải Phòng",
        "code": "11410",
        "parent_code": "306"
    },
    "11411": {
        "name": "Đông Hải 2",
        "type": "phuong",
        "slug": "dong-hai-2",
        "name_with_type": "Phường Đông Hải 2",
        "path": "Đông Hải 2, Hải An, Hải Phòng",
        "path_with_type": "Phường Đông Hải 2, Quận Hải An, Thành phố Hải Phòng",
        "code": "11411",
        "parent_code": "306"
    },
    "11413": {
        "name": "Đằng Lâm",
        "type": "phuong",
        "slug": "dang-lam",
        "name_with_type": "Phường Đằng Lâm",
        "path": "Đằng Lâm, Hải An, Hải Phòng",
        "path_with_type": "Phường Đằng Lâm, Quận Hải An, Thành phố Hải Phòng",
        "code": "11413",
        "parent_code": "306"
    },
    "11414": {
        "name": "Thành Tô",
        "type": "phuong",
        "slug": "thanh-to",
        "name_with_type": "Phường Thành Tô",
        "path": "Thành Tô, Hải An, Hải Phòng",
        "path_with_type": "Phường Thành Tô, Quận Hải An, Thành phố Hải Phòng",
        "code": "11414",
        "parent_code": "306"
    },
    "11416": {
        "name": "Đằng Hải",
        "type": "phuong",
        "slug": "dang-hai",
        "name_with_type": "Phường Đằng Hải",
        "path": "Đằng Hải, Hải An, Hải Phòng",
        "path_with_type": "Phường Đằng Hải, Quận Hải An, Thành phố Hải Phòng",
        "code": "11416",
        "parent_code": "306"
    },
    "11419": {
        "name": "Nam Hải",
        "type": "phuong",
        "slug": "nam-hai",
        "name_with_type": "Phường Nam Hải",
        "path": "Nam Hải, Hải An, Hải Phòng",
        "path_with_type": "Phường Nam Hải, Quận Hải An, Thành phố Hải Phòng",
        "code": "11419",
        "parent_code": "306"
    },
    "11422": {
        "name": "Cát Bi",
        "type": "phuong",
        "slug": "cat-bi",
        "name_with_type": "Phường Cát Bi",
        "path": "Cát Bi, Hải An, Hải Phòng",
        "path_with_type": "Phường Cát Bi, Quận Hải An, Thành phố Hải Phòng",
        "code": "11422",
        "parent_code": "306"
    },
    "11425": {
        "name": "Tràng Cát",
        "type": "phuong",
        "slug": "trang-cat",
        "name_with_type": "Phường Tràng Cát",
        "path": "Tràng Cát, Hải An, Hải Phòng",
        "path_with_type": "Phường Tràng Cát, Quận Hải An, Thành phố Hải Phòng",
        "code": "11425",
        "parent_code": "306"
    },
    "11428": {
        "name": "Quán Trữ",
        "type": "phuong",
        "slug": "quan-tru",
        "name_with_type": "Phường Quán Trữ",
        "path": "Quán Trữ, Kiến An, Hải Phòng",
        "path_with_type": "Phường Quán Trữ, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11428",
        "parent_code": "307"
    },
    "11429": {
        "name": "Lãm Hà",
        "type": "phuong",
        "slug": "lam-ha",
        "name_with_type": "Phường Lãm Hà",
        "path": "Lãm Hà, Kiến An, Hải Phòng",
        "path_with_type": "Phường Lãm Hà, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11429",
        "parent_code": "307"
    },
    "11431": {
        "name": "Đồng Hoà",
        "type": "phuong",
        "slug": "dong-hoa",
        "name_with_type": "Phường Đồng Hoà",
        "path": "Đồng Hoà, Kiến An, Hải Phòng",
        "path_with_type": "Phường Đồng Hoà, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11431",
        "parent_code": "307"
    },
    "11434": {
        "name": "Bắc Sơn",
        "type": "phuong",
        "slug": "bac-son",
        "name_with_type": "Phường Bắc Sơn",
        "path": "Bắc Sơn, Kiến An, Hải Phòng",
        "path_with_type": "Phường Bắc Sơn, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11434",
        "parent_code": "307"
    },
    "11437": {
        "name": "Nam Sơn",
        "type": "phuong",
        "slug": "nam-son",
        "name_with_type": "Phường Nam Sơn",
        "path": "Nam Sơn, Kiến An, Hải Phòng",
        "path_with_type": "Phường Nam Sơn, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11437",
        "parent_code": "307"
    },
    "11440": {
        "name": "Ngọc Sơn",
        "type": "phuong",
        "slug": "ngoc-son",
        "name_with_type": "Phường Ngọc Sơn",
        "path": "Ngọc Sơn, Kiến An, Hải Phòng",
        "path_with_type": "Phường Ngọc Sơn, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11440",
        "parent_code": "307"
    },
    "11443": {
        "name": "Trần Thành Ngọ",
        "type": "phuong",
        "slug": "tran-thanh-ngo",
        "name_with_type": "Phường Trần Thành Ngọ",
        "path": "Trần Thành Ngọ, Kiến An, Hải Phòng",
        "path_with_type": "Phường Trần Thành Ngọ, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11443",
        "parent_code": "307"
    },
    "11446": {
        "name": "Văn Đẩu",
        "type": "phuong",
        "slug": "van-dau",
        "name_with_type": "Phường Văn Đẩu",
        "path": "Văn Đẩu, Kiến An, Hải Phòng",
        "path_with_type": "Phường Văn Đẩu, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11446",
        "parent_code": "307"
    },
    "11449": {
        "name": "Phù Liễn",
        "type": "phuong",
        "slug": "phu-lien",
        "name_with_type": "Phường Phù Liễn",
        "path": "Phù Liễn, Kiến An, Hải Phòng",
        "path_with_type": "Phường Phù Liễn, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11449",
        "parent_code": "307"
    },
    "11452": {
        "name": "Tràng Minh",
        "type": "phuong",
        "slug": "trang-minh",
        "name_with_type": "Phường Tràng Minh",
        "path": "Tràng Minh, Kiến An, Hải Phòng",
        "path_with_type": "Phường Tràng Minh, Quận Kiến An, Thành phố Hải Phòng",
        "code": "11452",
        "parent_code": "307"
    },
    "11455": {
        "name": "Ngọc Xuyên",
        "type": "phuong",
        "slug": "ngoc-xuyen",
        "name_with_type": "Phường Ngọc Xuyên",
        "path": "Ngọc Xuyên, Đồ Sơn, Hải Phòng",
        "path_with_type": "Phường Ngọc Xuyên, Quận Đồ Sơn, Thành phố Hải Phòng",
        "code": "11455",
        "parent_code": "308"
    },
    "11458": {
        "name": "Ngọc Hải",
        "type": "phuong",
        "slug": "ngoc-hai",
        "name_with_type": "Phường Ngọc Hải",
        "path": "Ngọc Hải, Đồ Sơn, Hải Phòng",
        "path_with_type": "Phường Ngọc Hải, Quận Đồ Sơn, Thành phố Hải Phòng",
        "code": "11458",
        "parent_code": "308"
    },
    "11461": {
        "name": "Vạn Hương",
        "type": "phuong",
        "slug": "van-huong",
        "name_with_type": "Phường Vạn Hương",
        "path": "Vạn Hương, Đồ Sơn, Hải Phòng",
        "path_with_type": "Phường Vạn Hương, Quận Đồ Sơn, Thành phố Hải Phòng",
        "code": "11461",
        "parent_code": "308"
    },
    "11464": {
        "name": "Vạn Sơn",
        "type": "phuong",
        "slug": "van-son",
        "name_with_type": "Phường Vạn Sơn",
        "path": "Vạn Sơn, Đồ Sơn, Hải Phòng",
        "path_with_type": "Phường Vạn Sơn, Quận Đồ Sơn, Thành phố Hải Phòng",
        "code": "11464",
        "parent_code": "308"
    },
    "11465": {
        "name": "Minh Đức",
        "type": "phuong",
        "slug": "minh-duc",
        "name_with_type": "Phường Minh Đức",
        "path": "Minh Đức, Đồ Sơn, Hải Phòng",
        "path_with_type": "Phường Minh Đức, Quận Đồ Sơn, Thành phố Hải Phòng",
        "code": "11465",
        "parent_code": "308"
    },
    "11467": {
        "name": "Bàng La",
        "type": "phuong",
        "slug": "bang-la",
        "name_with_type": "Phường Bàng La",
        "path": "Bàng La, Đồ Sơn, Hải Phòng",
        "path_with_type": "Phường Bàng La, Quận Đồ Sơn, Thành phố Hải Phòng",
        "code": "11467",
        "parent_code": "308"
    },
    "11737": {
        "name": "Hợp Đức",
        "type": "phuong",
        "slug": "hop-duc",
        "name_with_type": "Phường Hợp Đức",
        "path": "Hợp Đức, Đồ Sơn, Hải Phòng",
        "path_with_type": "Phường Hợp Đức, Quận Đồ Sơn, Thành phố Hải Phòng",
        "code": "11737",
        "parent_code": "308"
    },
    "11683": {
        "name": "Đa Phúc",
        "type": "phuong",
        "slug": "da-phuc",
        "name_with_type": "Phường Đa Phúc",
        "path": "Đa Phúc, Dương Kinh, Hải Phòng",
        "path_with_type": "Phường Đa Phúc, Quận Dương Kinh, Thành phố Hải Phòng",
        "code": "11683",
        "parent_code": "309"
    },
    "11686": {
        "name": "Hưng Đạo",
        "type": "phuong",
        "slug": "hung-dao",
        "name_with_type": "Phường Hưng Đạo",
        "path": "Hưng Đạo, Dương Kinh, Hải Phòng",
        "path_with_type": "Phường Hưng Đạo, Quận Dương Kinh, Thành phố Hải Phòng",
        "code": "11686",
        "parent_code": "309"
    },
    "11689": {
        "name": "Anh Dũng",
        "type": "phuong",
        "slug": "anh-dung",
        "name_with_type": "Phường Anh Dũng",
        "path": "Anh Dũng, Dương Kinh, Hải Phòng",
        "path_with_type": "Phường Anh Dũng, Quận Dương Kinh, Thành phố Hải Phòng",
        "code": "11689",
        "parent_code": "309"
    },
    "11692": {
        "name": "Hải Thành",
        "type": "phuong",
        "slug": "hai-thanh",
        "name_with_type": "Phường Hải Thành",
        "path": "Hải Thành, Dương Kinh, Hải Phòng",
        "path_with_type": "Phường Hải Thành, Quận Dương Kinh, Thành phố Hải Phòng",
        "code": "11692",
        "parent_code": "309"
    },
    "11707": {
        "name": "Hoà Nghĩa",
        "type": "phuong",
        "slug": "hoa-nghia",
        "name_with_type": "Phường Hoà Nghĩa",
        "path": "Hoà Nghĩa, Dương Kinh, Hải Phòng",
        "path_with_type": "Phường Hoà Nghĩa, Quận Dương Kinh, Thành phố Hải Phòng",
        "code": "11707",
        "parent_code": "309"
    },
    "11740": {
        "name": "Tân Thành",
        "type": "phuong",
        "slug": "tan-thanh",
        "name_with_type": "Phường Tân Thành",
        "path": "Tân Thành, Dương Kinh, Hải Phòng",
        "path_with_type": "Phường Tân Thành, Quận Dương Kinh, Thành phố Hải Phòng",
        "code": "11740",
        "parent_code": "309"
    },
    "11470": {
        "name": "Núi Đèo",
        "type": "thi-tran",
        "slug": "nui-deo",
        "name_with_type": "Thị trấn Núi Đèo",
        "path": "Núi Đèo, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Thị trấn Núi Đèo, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11470",
        "parent_code": "311"
    },
    "11473": {
        "name": "Minh Đức",
        "type": "thi-tran",
        "slug": "minh-duc",
        "name_with_type": "Thị trấn Minh Đức",
        "path": "Minh Đức, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Thị trấn Minh Đức, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11473",
        "parent_code": "311"
    },
    "11476": {
        "name": "Lại Xuân",
        "type": "xa",
        "slug": "lai-xuan",
        "name_with_type": "Xã Lại Xuân",
        "path": "Lại Xuân, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Lại Xuân, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11476",
        "parent_code": "311"
    },
    "11479": {
        "name": "An Sơn",
        "type": "xa",
        "slug": "an-son",
        "name_with_type": "Xã An Sơn",
        "path": "An Sơn, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã An Sơn, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11479",
        "parent_code": "311"
    },
    "11482": {
        "name": "Kỳ Sơn",
        "type": "xa",
        "slug": "ky-son",
        "name_with_type": "Xã Kỳ Sơn",
        "path": "Kỳ Sơn, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Kỳ Sơn, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11482",
        "parent_code": "311"
    },
    "11485": {
        "name": "Liên Khê",
        "type": "xa",
        "slug": "lien-khe",
        "name_with_type": "Xã Liên Khê",
        "path": "Liên Khê, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Liên Khê, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11485",
        "parent_code": "311"
    },
    "11488": {
        "name": "Lưu Kiếm",
        "type": "xa",
        "slug": "luu-kiem",
        "name_with_type": "Xã Lưu Kiếm",
        "path": "Lưu Kiếm, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Lưu Kiếm, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11488",
        "parent_code": "311"
    },
    "11491": {
        "name": "Lưu Kỳ",
        "type": "xa",
        "slug": "luu-ky",
        "name_with_type": "Xã Lưu Kỳ",
        "path": "Lưu Kỳ, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Lưu Kỳ, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11491",
        "parent_code": "311"
    },
    "11494": {
        "name": "Gia Minh",
        "type": "xa",
        "slug": "gia-minh",
        "name_with_type": "Xã Gia Minh",
        "path": "Gia Minh, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Gia Minh, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11494",
        "parent_code": "311"
    },
    "11497": {
        "name": "Gia Đức",
        "type": "xa",
        "slug": "gia-duc",
        "name_with_type": "Xã Gia Đức",
        "path": "Gia Đức, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Gia Đức, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11497",
        "parent_code": "311"
    },
    "11500": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Minh Tân, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11500",
        "parent_code": "311"
    },
    "11503": {
        "name": "Phù Ninh",
        "type": "xa",
        "slug": "phu-ninh",
        "name_with_type": "Xã Phù Ninh",
        "path": "Phù Ninh, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Phù Ninh, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11503",
        "parent_code": "311"
    },
    "11506": {
        "name": "Quảng Thanh",
        "type": "xa",
        "slug": "quang-thanh",
        "name_with_type": "Xã Quảng Thanh",
        "path": "Quảng Thanh, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Quảng Thanh, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11506",
        "parent_code": "311"
    },
    "11509": {
        "name": "Chính Mỹ",
        "type": "xa",
        "slug": "chinh-my",
        "name_with_type": "Xã Chính Mỹ",
        "path": "Chính Mỹ, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Chính Mỹ, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11509",
        "parent_code": "311"
    },
    "11512": {
        "name": "Kênh Giang",
        "type": "xa",
        "slug": "kenh-giang",
        "name_with_type": "Xã Kênh Giang",
        "path": "Kênh Giang, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Kênh Giang, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11512",
        "parent_code": "311"
    },
    "11515": {
        "name": "Hợp Thành",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thành",
        "path": "Hợp Thành, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Hợp Thành, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11515",
        "parent_code": "311"
    },
    "11518": {
        "name": "Cao Nhân",
        "type": "xa",
        "slug": "cao-nhan",
        "name_with_type": "Xã Cao Nhân",
        "path": "Cao Nhân, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Cao Nhân, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11518",
        "parent_code": "311"
    },
    "11521": {
        "name": "Mỹ Đồng",
        "type": "xa",
        "slug": "my-dong",
        "name_with_type": "Xã Mỹ Đồng",
        "path": "Mỹ Đồng, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Mỹ Đồng, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11521",
        "parent_code": "311"
    },
    "11524": {
        "name": "Đông Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đông Sơn",
        "path": "Đông Sơn, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Đông Sơn, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11524",
        "parent_code": "311"
    },
    "11527": {
        "name": "Hoà Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hoà Bình",
        "path": "Hoà Bình, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Hoà Bình, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11527",
        "parent_code": "311"
    },
    "11530": {
        "name": "Trung Hà",
        "type": "xa",
        "slug": "trung-ha",
        "name_with_type": "Xã Trung Hà",
        "path": "Trung Hà, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Trung Hà, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11530",
        "parent_code": "311"
    },
    "11533": {
        "name": "An Lư",
        "type": "xa",
        "slug": "an-lu",
        "name_with_type": "Xã An Lư",
        "path": "An Lư, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã An Lư, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11533",
        "parent_code": "311"
    },
    "11536": {
        "name": "Thuỷ Triều",
        "type": "xa",
        "slug": "thuy-trieu",
        "name_with_type": "Xã Thuỷ Triều",
        "path": "Thuỷ Triều, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Thuỷ Triều, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11536",
        "parent_code": "311"
    },
    "11539": {
        "name": "Ngũ Lão",
        "type": "xa",
        "slug": "ngu-lao",
        "name_with_type": "Xã Ngũ Lão",
        "path": "Ngũ Lão, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Ngũ Lão, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11539",
        "parent_code": "311"
    },
    "11542": {
        "name": "Phục Lễ",
        "type": "xa",
        "slug": "phuc-le",
        "name_with_type": "Xã Phục Lễ",
        "path": "Phục Lễ, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Phục Lễ, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11542",
        "parent_code": "311"
    },
    "11545": {
        "name": "Tam Hưng",
        "type": "xa",
        "slug": "tam-hung",
        "name_with_type": "Xã Tam Hưng",
        "path": "Tam Hưng, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Tam Hưng, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11545",
        "parent_code": "311"
    },
    "11548": {
        "name": "Phả Lễ",
        "type": "xa",
        "slug": "pha-le",
        "name_with_type": "Xã Phả Lễ",
        "path": "Phả Lễ, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Phả Lễ, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11548",
        "parent_code": "311"
    },
    "11551": {
        "name": "Lập Lễ",
        "type": "xa",
        "slug": "lap-le",
        "name_with_type": "Xã Lập Lễ",
        "path": "Lập Lễ, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Lập Lễ, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11551",
        "parent_code": "311"
    },
    "11554": {
        "name": "Kiền Bái",
        "type": "xa",
        "slug": "kien-bai",
        "name_with_type": "Xã Kiền Bái",
        "path": "Kiền Bái, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Kiền Bái, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11554",
        "parent_code": "311"
    },
    "11557": {
        "name": "Thiên Hương",
        "type": "xa",
        "slug": "thien-huong",
        "name_with_type": "Xã Thiên Hương",
        "path": "Thiên Hương, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Thiên Hương, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11557",
        "parent_code": "311"
    },
    "11560": {
        "name": "Thuỷ Sơn",
        "type": "xa",
        "slug": "thuy-son",
        "name_with_type": "Xã Thuỷ Sơn",
        "path": "Thuỷ Sơn, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Thuỷ Sơn, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11560",
        "parent_code": "311"
    },
    "11563": {
        "name": "Thuỷ Đường",
        "type": "xa",
        "slug": "thuy-duong",
        "name_with_type": "Xã Thuỷ Đường",
        "path": "Thuỷ Đường, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Thuỷ Đường, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11563",
        "parent_code": "311"
    },
    "11566": {
        "name": "Hoàng Động",
        "type": "xa",
        "slug": "hoang-dong",
        "name_with_type": "Xã Hoàng Động",
        "path": "Hoàng Động, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Hoàng Động, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11566",
        "parent_code": "311"
    },
    "11569": {
        "name": "Lâm Động",
        "type": "xa",
        "slug": "lam-dong",
        "name_with_type": "Xã Lâm Động",
        "path": "Lâm Động, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Lâm Động, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11569",
        "parent_code": "311"
    },
    "11572": {
        "name": "Hoa Động",
        "type": "xa",
        "slug": "hoa-dong",
        "name_with_type": "Xã Hoa Động",
        "path": "Hoa Động, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Hoa Động, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11572",
        "parent_code": "311"
    },
    "11575": {
        "name": "Tân Dương",
        "type": "xa",
        "slug": "tan-duong",
        "name_with_type": "Xã Tân Dương",
        "path": "Tân Dương, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Tân Dương, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11575",
        "parent_code": "311"
    },
    "11578": {
        "name": "Dương Quan",
        "type": "xa",
        "slug": "duong-quan",
        "name_with_type": "Xã Dương Quan",
        "path": "Dương Quan, Thuỷ Nguyên, Hải Phòng",
        "path_with_type": "Xã Dương Quan, Huyện Thuỷ Nguyên, Thành phố Hải Phòng",
        "code": "11578",
        "parent_code": "311"
    },
    "11581": {
        "name": "An Dương",
        "type": "thi-tran",
        "slug": "an-duong",
        "name_with_type": "Thị trấn An Dương",
        "path": "An Dương, An Dương, Hải Phòng",
        "path_with_type": "Thị trấn An Dương, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11581",
        "parent_code": "312"
    },
    "11584": {
        "name": "Lê Thiện",
        "type": "xa",
        "slug": "le-thien",
        "name_with_type": "Xã Lê Thiện",
        "path": "Lê Thiện, An Dương, Hải Phòng",
        "path_with_type": "Xã Lê Thiện, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11584",
        "parent_code": "312"
    },
    "11587": {
        "name": "Đại Bản",
        "type": "xa",
        "slug": "dai-ban",
        "name_with_type": "Xã Đại Bản",
        "path": "Đại Bản, An Dương, Hải Phòng",
        "path_with_type": "Xã Đại Bản, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11587",
        "parent_code": "312"
    },
    "11590": {
        "name": "An Hoà",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hoà",
        "path": "An Hoà, An Dương, Hải Phòng",
        "path_with_type": "Xã An Hoà, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11590",
        "parent_code": "312"
    },
    "11593": {
        "name": "Hồng Phong",
        "type": "xa",
        "slug": "hong-phong",
        "name_with_type": "Xã Hồng Phong",
        "path": "Hồng Phong, An Dương, Hải Phòng",
        "path_with_type": "Xã Hồng Phong, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11593",
        "parent_code": "312"
    },
    "11596": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, An Dương, Hải Phòng",
        "path_with_type": "Xã Tân Tiến, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11596",
        "parent_code": "312"
    },
    "11599": {
        "name": "An Hưng",
        "type": "xa",
        "slug": "an-hung",
        "name_with_type": "Xã An Hưng",
        "path": "An Hưng, An Dương, Hải Phòng",
        "path_with_type": "Xã An Hưng, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11599",
        "parent_code": "312"
    },
    "11602": {
        "name": "An Hồng",
        "type": "xa",
        "slug": "an-hong",
        "name_with_type": "Xã An Hồng",
        "path": "An Hồng, An Dương, Hải Phòng",
        "path_with_type": "Xã An Hồng, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11602",
        "parent_code": "312"
    },
    "11605": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, An Dương, Hải Phòng",
        "path_with_type": "Xã Bắc Sơn, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11605",
        "parent_code": "312"
    },
    "11608": {
        "name": "Nam Sơn",
        "type": "xa",
        "slug": "nam-son",
        "name_with_type": "Xã Nam Sơn",
        "path": "Nam Sơn, An Dương, Hải Phòng",
        "path_with_type": "Xã Nam Sơn, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11608",
        "parent_code": "312"
    },
    "11611": {
        "name": "Lê Lợi",
        "type": "xa",
        "slug": "le-loi",
        "name_with_type": "Xã Lê Lợi",
        "path": "Lê Lợi, An Dương, Hải Phòng",
        "path_with_type": "Xã Lê Lợi, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11611",
        "parent_code": "312"
    },
    "11614": {
        "name": "Đặng Cương",
        "type": "xa",
        "slug": "dang-cuong",
        "name_with_type": "Xã Đặng Cương",
        "path": "Đặng Cương, An Dương, Hải Phòng",
        "path_with_type": "Xã Đặng Cương, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11614",
        "parent_code": "312"
    },
    "11617": {
        "name": "Đồng Thái",
        "type": "xa",
        "slug": "dong-thai",
        "name_with_type": "Xã Đồng Thái",
        "path": "Đồng Thái, An Dương, Hải Phòng",
        "path_with_type": "Xã Đồng Thái, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11617",
        "parent_code": "312"
    },
    "11620": {
        "name": "Quốc Tuấn",
        "type": "xa",
        "slug": "quoc-tuan",
        "name_with_type": "Xã Quốc Tuấn",
        "path": "Quốc Tuấn, An Dương, Hải Phòng",
        "path_with_type": "Xã Quốc Tuấn, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11620",
        "parent_code": "312"
    },
    "11623": {
        "name": "An Đồng",
        "type": "xa",
        "slug": "an-dong",
        "name_with_type": "Xã An Đồng",
        "path": "An Đồng, An Dương, Hải Phòng",
        "path_with_type": "Xã An Đồng, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11623",
        "parent_code": "312"
    },
    "11626": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, An Dương, Hải Phòng",
        "path_with_type": "Xã Hồng Thái, Huyện An Dương, Thành phố Hải Phòng",
        "code": "11626",
        "parent_code": "312"
    },
    "11629": {
        "name": "An Lão",
        "type": "thi-tran",
        "slug": "an-lao",
        "name_with_type": "Thị trấn An Lão",
        "path": "An Lão, An Lão, Hải Phòng",
        "path_with_type": "Thị trấn An Lão, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11629",
        "parent_code": "313"
    },
    "11632": {
        "name": "Bát Trang",
        "type": "xa",
        "slug": "bat-trang",
        "name_with_type": "Xã Bát Trang",
        "path": "Bát Trang, An Lão, Hải Phòng",
        "path_with_type": "Xã Bát Trang, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11632",
        "parent_code": "313"
    },
    "11635": {
        "name": "Trường Thọ",
        "type": "xa",
        "slug": "truong-tho",
        "name_with_type": "Xã Trường Thọ",
        "path": "Trường Thọ, An Lão, Hải Phòng",
        "path_with_type": "Xã Trường Thọ, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11635",
        "parent_code": "313"
    },
    "11638": {
        "name": "Trường Thành",
        "type": "xa",
        "slug": "truong-thanh",
        "name_with_type": "Xã Trường Thành",
        "path": "Trường Thành, An Lão, Hải Phòng",
        "path_with_type": "Xã Trường Thành, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11638",
        "parent_code": "313"
    },
    "11641": {
        "name": "An Tiến",
        "type": "xa",
        "slug": "an-tien",
        "name_with_type": "Xã An Tiến",
        "path": "An Tiến, An Lão, Hải Phòng",
        "path_with_type": "Xã An Tiến, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11641",
        "parent_code": "313"
    },
    "11644": {
        "name": "Quang Hưng",
        "type": "xa",
        "slug": "quang-hung",
        "name_with_type": "Xã Quang Hưng",
        "path": "Quang Hưng, An Lão, Hải Phòng",
        "path_with_type": "Xã Quang Hưng, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11644",
        "parent_code": "313"
    },
    "11647": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, An Lão, Hải Phòng",
        "path_with_type": "Xã Quang Trung, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11647",
        "parent_code": "313"
    },
    "11650": {
        "name": "Quốc Tuấn",
        "type": "xa",
        "slug": "quoc-tuan",
        "name_with_type": "Xã Quốc Tuấn",
        "path": "Quốc Tuấn, An Lão, Hải Phòng",
        "path_with_type": "Xã Quốc Tuấn, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11650",
        "parent_code": "313"
    },
    "11653": {
        "name": "An Thắng",
        "type": "xa",
        "slug": "an-thang",
        "name_with_type": "Xã An Thắng",
        "path": "An Thắng, An Lão, Hải Phòng",
        "path_with_type": "Xã An Thắng, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11653",
        "parent_code": "313"
    },
    "11656": {
        "name": "Trường Sơn",
        "type": "thi-tran",
        "slug": "truong-son",
        "name_with_type": "Thị trấn Trường Sơn",
        "path": "Trường Sơn, An Lão, Hải Phòng",
        "path_with_type": "Thị trấn Trường Sơn, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11656",
        "parent_code": "313"
    },
    "11659": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, An Lão, Hải Phòng",
        "path_with_type": "Xã Tân Dân, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11659",
        "parent_code": "313"
    },
    "11662": {
        "name": "Thái Sơn",
        "type": "xa",
        "slug": "thai-son",
        "name_with_type": "Xã Thái Sơn",
        "path": "Thái Sơn, An Lão, Hải Phòng",
        "path_with_type": "Xã Thái Sơn, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11662",
        "parent_code": "313"
    },
    "11665": {
        "name": "Tân Viên",
        "type": "xa",
        "slug": "tan-vien",
        "name_with_type": "Xã Tân Viên",
        "path": "Tân Viên, An Lão, Hải Phòng",
        "path_with_type": "Xã Tân Viên, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11665",
        "parent_code": "313"
    },
    "11668": {
        "name": "Mỹ Đức",
        "type": "xa",
        "slug": "my-duc",
        "name_with_type": "Xã Mỹ Đức",
        "path": "Mỹ Đức, An Lão, Hải Phòng",
        "path_with_type": "Xã Mỹ Đức, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11668",
        "parent_code": "313"
    },
    "11671": {
        "name": "Chiến Thắng",
        "type": "xa",
        "slug": "chien-thang",
        "name_with_type": "Xã Chiến Thắng",
        "path": "Chiến Thắng, An Lão, Hải Phòng",
        "path_with_type": "Xã Chiến Thắng, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11671",
        "parent_code": "313"
    },
    "11674": {
        "name": "An Thọ",
        "type": "xa",
        "slug": "an-tho",
        "name_with_type": "Xã An Thọ",
        "path": "An Thọ, An Lão, Hải Phòng",
        "path_with_type": "Xã An Thọ, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11674",
        "parent_code": "313"
    },
    "11677": {
        "name": "An Thái",
        "type": "xa",
        "slug": "an-thai",
        "name_with_type": "Xã An Thái",
        "path": "An Thái, An Lão, Hải Phòng",
        "path_with_type": "Xã An Thái, Huyện An Lão, Thành phố Hải Phòng",
        "code": "11677",
        "parent_code": "313"
    },
    "11680": {
        "name": "Núi Đối",
        "type": "thi-tran",
        "slug": "nui-doi",
        "name_with_type": "Thị trấn Núi Đối",
        "path": "Núi Đối, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Thị trấn Núi Đối, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11680",
        "parent_code": "314"
    },
    "11695": {
        "name": "Đông Phương",
        "type": "xa",
        "slug": "dong-phuong",
        "name_with_type": "Xã Đông Phương",
        "path": "Đông Phương, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Đông Phương, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11695",
        "parent_code": "314"
    },
    "11698": {
        "name": "Thuận Thiên",
        "type": "xa",
        "slug": "thuan-thien",
        "name_with_type": "Xã Thuận Thiên",
        "path": "Thuận Thiên, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Thuận Thiên, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11698",
        "parent_code": "314"
    },
    "11701": {
        "name": "Hữu Bằng",
        "type": "xa",
        "slug": "huu-bang",
        "name_with_type": "Xã Hữu Bằng",
        "path": "Hữu Bằng, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Hữu Bằng, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11701",
        "parent_code": "314"
    },
    "11704": {
        "name": "Đại Đồng",
        "type": "xa",
        "slug": "dai-dong",
        "name_with_type": "Xã Đại Đồng",
        "path": "Đại Đồng, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Đại Đồng, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11704",
        "parent_code": "314"
    },
    "11710": {
        "name": "Ngũ Phúc",
        "type": "xa",
        "slug": "ngu-phuc",
        "name_with_type": "Xã Ngũ Phúc",
        "path": "Ngũ Phúc, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Ngũ Phúc, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11710",
        "parent_code": "314"
    },
    "11713": {
        "name": "Kiến Quốc",
        "type": "xa",
        "slug": "kien-quoc",
        "name_with_type": "Xã Kiến Quốc",
        "path": "Kiến Quốc, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Kiến Quốc, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11713",
        "parent_code": "314"
    },
    "11716": {
        "name": "Du Lễ",
        "type": "xa",
        "slug": "du-le",
        "name_with_type": "Xã Du Lễ",
        "path": "Du Lễ, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Du Lễ, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11716",
        "parent_code": "314"
    },
    "11719": {
        "name": "Thuỵ Hương",
        "type": "xa",
        "slug": "thuy-huong",
        "name_with_type": "Xã Thuỵ Hương",
        "path": "Thuỵ Hương, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Thuỵ Hương, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11719",
        "parent_code": "314"
    },
    "11722": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Thanh Sơn, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11722",
        "parent_code": "314"
    },
    "11725": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Minh Tân, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11725",
        "parent_code": "314"
    },
    "11728": {
        "name": "Đại Hà",
        "type": "xa",
        "slug": "dai-ha",
        "name_with_type": "Xã Đại Hà",
        "path": "Đại Hà, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Đại Hà, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11728",
        "parent_code": "314"
    },
    "11731": {
        "name": "Ngũ Đoan",
        "type": "xa",
        "slug": "ngu-doan",
        "name_with_type": "Xã Ngũ Đoan",
        "path": "Ngũ Đoan, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Ngũ Đoan, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11731",
        "parent_code": "314"
    },
    "11734": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Tân Phong, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11734",
        "parent_code": "314"
    },
    "11743": {
        "name": "Tân Trào",
        "type": "xa",
        "slug": "tan-trao",
        "name_with_type": "Xã Tân Trào",
        "path": "Tân Trào, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Tân Trào, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11743",
        "parent_code": "314"
    },
    "11746": {
        "name": "Đoàn Xá",
        "type": "xa",
        "slug": "doan-xa",
        "name_with_type": "Xã Đoàn Xá",
        "path": "Đoàn Xá, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Đoàn Xá, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11746",
        "parent_code": "314"
    },
    "11749": {
        "name": "Tú Sơn",
        "type": "xa",
        "slug": "tu-son",
        "name_with_type": "Xã Tú Sơn",
        "path": "Tú Sơn, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Tú Sơn, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11749",
        "parent_code": "314"
    },
    "11752": {
        "name": "Đại Hợp",
        "type": "xa",
        "slug": "dai-hop",
        "name_with_type": "Xã Đại Hợp",
        "path": "Đại Hợp, Kiến Thuỵ, Hải Phòng",
        "path_with_type": "Xã Đại Hợp, Huyện Kiến Thuỵ, Thành phố Hải Phòng",
        "code": "11752",
        "parent_code": "314"
    },
    "11755": {
        "name": "Tiên Lãng",
        "type": "thi-tran",
        "slug": "tien-lang",
        "name_with_type": "Thị trấn Tiên Lãng",
        "path": "Tiên Lãng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Thị trấn Tiên Lãng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11755",
        "parent_code": "315"
    },
    "11758": {
        "name": "Đại Thắng",
        "type": "xa",
        "slug": "dai-thang",
        "name_with_type": "Xã Đại Thắng",
        "path": "Đại Thắng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Đại Thắng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11758",
        "parent_code": "315"
    },
    "11761": {
        "name": "Tiên Cường",
        "type": "xa",
        "slug": "tien-cuong",
        "name_with_type": "Xã Tiên Cường",
        "path": "Tiên Cường, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Tiên Cường, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11761",
        "parent_code": "315"
    },
    "11764": {
        "name": "Tự Cường",
        "type": "xa",
        "slug": "tu-cuong",
        "name_with_type": "Xã Tự Cường",
        "path": "Tự Cường, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Tự Cường, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11764",
        "parent_code": "315"
    },
    "11767": {
        "name": "Tiên Tiến",
        "type": "xa",
        "slug": "tien-tien",
        "name_with_type": "Xã Tiên Tiến",
        "path": "Tiên Tiến, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Tiên Tiến, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11767",
        "parent_code": "315"
    },
    "11770": {
        "name": "Quyết Tiến",
        "type": "xa",
        "slug": "quyet-tien",
        "name_with_type": "Xã Quyết Tiến",
        "path": "Quyết Tiến, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Quyết Tiến, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11770",
        "parent_code": "315"
    },
    "11773": {
        "name": "Khởi Nghĩa",
        "type": "xa",
        "slug": "khoi-nghia",
        "name_with_type": "Xã Khởi Nghĩa",
        "path": "Khởi Nghĩa, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Khởi Nghĩa, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11773",
        "parent_code": "315"
    },
    "11776": {
        "name": "Tiên Thanh",
        "type": "xa",
        "slug": "tien-thanh",
        "name_with_type": "Xã Tiên Thanh",
        "path": "Tiên Thanh, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Tiên Thanh, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11776",
        "parent_code": "315"
    },
    "11779": {
        "name": "Cấp Tiến",
        "type": "xa",
        "slug": "cap-tien",
        "name_with_type": "Xã Cấp Tiến",
        "path": "Cấp Tiến, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Cấp Tiến, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11779",
        "parent_code": "315"
    },
    "11782": {
        "name": "Kiến Thiết",
        "type": "xa",
        "slug": "kien-thiet",
        "name_with_type": "Xã Kiến Thiết",
        "path": "Kiến Thiết, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Kiến Thiết, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11782",
        "parent_code": "315"
    },
    "11785": {
        "name": "Đoàn Lập",
        "type": "xa",
        "slug": "doan-lap",
        "name_with_type": "Xã Đoàn Lập",
        "path": "Đoàn Lập, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Đoàn Lập, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11785",
        "parent_code": "315"
    },
    "11788": {
        "name": "Bạch Đằng",
        "type": "xa",
        "slug": "bach-dang",
        "name_with_type": "Xã Bạch Đằng",
        "path": "Bạch Đằng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Bạch Đằng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11788",
        "parent_code": "315"
    },
    "11791": {
        "name": "Quang Phục",
        "type": "xa",
        "slug": "quang-phuc",
        "name_with_type": "Xã Quang Phục",
        "path": "Quang Phục, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Quang Phục, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11791",
        "parent_code": "315"
    },
    "11794": {
        "name": "Toàn Thắng",
        "type": "xa",
        "slug": "toan-thang",
        "name_with_type": "Xã Toàn Thắng",
        "path": "Toàn Thắng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Toàn Thắng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11794",
        "parent_code": "315"
    },
    "11797": {
        "name": "Tiên Thắng",
        "type": "xa",
        "slug": "tien-thang",
        "name_with_type": "Xã Tiên Thắng",
        "path": "Tiên Thắng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Tiên Thắng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11797",
        "parent_code": "315"
    },
    "11800": {
        "name": "Tiên Minh",
        "type": "xa",
        "slug": "tien-minh",
        "name_with_type": "Xã Tiên Minh",
        "path": "Tiên Minh, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Tiên Minh, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11800",
        "parent_code": "315"
    },
    "11803": {
        "name": "Bắc Hưng",
        "type": "xa",
        "slug": "bac-hung",
        "name_with_type": "Xã Bắc Hưng",
        "path": "Bắc Hưng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Bắc Hưng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11803",
        "parent_code": "315"
    },
    "11806": {
        "name": "Nam Hưng",
        "type": "xa",
        "slug": "nam-hung",
        "name_with_type": "Xã Nam Hưng",
        "path": "Nam Hưng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Nam Hưng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11806",
        "parent_code": "315"
    },
    "11809": {
        "name": "Hùng Thắng",
        "type": "xa",
        "slug": "hung-thang",
        "name_with_type": "Xã Hùng Thắng",
        "path": "Hùng Thắng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Hùng Thắng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11809",
        "parent_code": "315"
    },
    "11812": {
        "name": "Tây Hưng",
        "type": "xa",
        "slug": "tay-hung",
        "name_with_type": "Xã Tây Hưng",
        "path": "Tây Hưng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Tây Hưng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11812",
        "parent_code": "315"
    },
    "11815": {
        "name": "Đông Hưng",
        "type": "xa",
        "slug": "dong-hung",
        "name_with_type": "Xã Đông Hưng",
        "path": "Đông Hưng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Đông Hưng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11815",
        "parent_code": "315"
    },
    "11818": {
        "name": "Tiên Hưng",
        "type": "xa",
        "slug": "tien-hung",
        "name_with_type": "Xã Tiên Hưng",
        "path": "Tiên Hưng, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Tiên Hưng, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11818",
        "parent_code": "315"
    },
    "11821": {
        "name": "Vinh Quang",
        "type": "xa",
        "slug": "vinh-quang",
        "name_with_type": "Xã Vinh Quang",
        "path": "Vinh Quang, Tiên Lãng, Hải Phòng",
        "path_with_type": "Xã Vinh Quang, Huyện Tiên Lãng, Thành phố Hải Phòng",
        "code": "11821",
        "parent_code": "315"
    },
    "11824": {
        "name": "Vĩnh Bảo",
        "type": "thi-tran",
        "slug": "vinh-bao",
        "name_with_type": "Thị trấn Vĩnh Bảo",
        "path": "Vĩnh Bảo, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Thị trấn Vĩnh Bảo, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11824",
        "parent_code": "316"
    },
    "11827": {
        "name": "Dũng Tiến",
        "type": "xa",
        "slug": "dung-tien",
        "name_with_type": "Xã Dũng Tiến",
        "path": "Dũng Tiến, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Dũng Tiến, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11827",
        "parent_code": "316"
    },
    "11830": {
        "name": "Giang Biên",
        "type": "xa",
        "slug": "giang-bien",
        "name_with_type": "Xã Giang Biên",
        "path": "Giang Biên, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Giang Biên, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11830",
        "parent_code": "316"
    },
    "11833": {
        "name": "Thắng Thuỷ",
        "type": "xa",
        "slug": "thang-thuy",
        "name_with_type": "Xã Thắng Thuỷ",
        "path": "Thắng Thuỷ, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Thắng Thuỷ, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11833",
        "parent_code": "316"
    },
    "11836": {
        "name": "Trung Lập",
        "type": "xa",
        "slug": "trung-lap",
        "name_with_type": "Xã Trung Lập",
        "path": "Trung Lập, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Trung Lập, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11836",
        "parent_code": "316"
    },
    "11839": {
        "name": "Việt Tiến",
        "type": "xa",
        "slug": "viet-tien",
        "name_with_type": "Xã Việt Tiến",
        "path": "Việt Tiến, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Việt Tiến, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11839",
        "parent_code": "316"
    },
    "11842": {
        "name": "Vĩnh An",
        "type": "xa",
        "slug": "vinh-an",
        "name_with_type": "Xã Vĩnh An",
        "path": "Vĩnh An, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Vĩnh An, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11842",
        "parent_code": "316"
    },
    "11845": {
        "name": "Vĩnh Long",
        "type": "xa",
        "slug": "vinh-long",
        "name_with_type": "Xã Vĩnh Long",
        "path": "Vĩnh Long, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Vĩnh Long, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11845",
        "parent_code": "316"
    },
    "11848": {
        "name": "Hiệp Hoà",
        "type": "xa",
        "slug": "hiep-hoa",
        "name_with_type": "Xã Hiệp Hoà",
        "path": "Hiệp Hoà, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Hiệp Hoà, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11848",
        "parent_code": "316"
    },
    "11851": {
        "name": "Hùng Tiến",
        "type": "xa",
        "slug": "hung-tien",
        "name_with_type": "Xã Hùng Tiến",
        "path": "Hùng Tiến, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Hùng Tiến, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11851",
        "parent_code": "316"
    },
    "11854": {
        "name": "An Hoà",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hoà",
        "path": "An Hoà, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã An Hoà, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11854",
        "parent_code": "316"
    },
    "11857": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Tân Hưng, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11857",
        "parent_code": "316"
    },
    "11860": {
        "name": "Tân Liên",
        "type": "xa",
        "slug": "tan-lien",
        "name_with_type": "Xã Tân Liên",
        "path": "Tân Liên, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Tân Liên, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11860",
        "parent_code": "316"
    },
    "11863": {
        "name": "Nhân Hoà",
        "type": "xa",
        "slug": "nhan-hoa",
        "name_with_type": "Xã Nhân Hoà",
        "path": "Nhân Hoà, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Nhân Hoà, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11863",
        "parent_code": "316"
    },
    "11866": {
        "name": "Tam Đa",
        "type": "xa",
        "slug": "tam-da",
        "name_with_type": "Xã Tam Đa",
        "path": "Tam Đa, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Tam Đa, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11866",
        "parent_code": "316"
    },
    "11869": {
        "name": "Hưng Nhân",
        "type": "xa",
        "slug": "hung-nhan",
        "name_with_type": "Xã Hưng Nhân",
        "path": "Hưng Nhân, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Hưng Nhân, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11869",
        "parent_code": "316"
    },
    "11872": {
        "name": "Vinh Quang",
        "type": "xa",
        "slug": "vinh-quang",
        "name_with_type": "Xã Vinh Quang",
        "path": "Vinh Quang, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Vinh Quang, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11872",
        "parent_code": "316"
    },
    "11875": {
        "name": "Đồng Minh",
        "type": "xa",
        "slug": "dong-minh",
        "name_with_type": "Xã Đồng Minh",
        "path": "Đồng Minh, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Đồng Minh, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11875",
        "parent_code": "316"
    },
    "11878": {
        "name": "Thanh Lương",
        "type": "xa",
        "slug": "thanh-luong",
        "name_with_type": "Xã Thanh Lương",
        "path": "Thanh Lương, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Thanh Lương, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11878",
        "parent_code": "316"
    },
    "11881": {
        "name": "Liên Am",
        "type": "xa",
        "slug": "lien-am",
        "name_with_type": "Xã Liên Am",
        "path": "Liên Am, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Liên Am, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11881",
        "parent_code": "316"
    },
    "11884": {
        "name": "Lý Học",
        "type": "xa",
        "slug": "ly-hoc",
        "name_with_type": "Xã Lý Học",
        "path": "Lý Học, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Lý Học, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11884",
        "parent_code": "316"
    },
    "11887": {
        "name": "Tam Cường",
        "type": "xa",
        "slug": "tam-cuong",
        "name_with_type": "Xã Tam Cường",
        "path": "Tam Cường, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Tam Cường, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11887",
        "parent_code": "316"
    },
    "11890": {
        "name": "Hoà Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hoà Bình",
        "path": "Hoà Bình, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Hoà Bình, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11890",
        "parent_code": "316"
    },
    "11893": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Tiền Phong, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11893",
        "parent_code": "316"
    },
    "11896": {
        "name": "Vĩnh Phong",
        "type": "xa",
        "slug": "vinh-phong",
        "name_with_type": "Xã Vĩnh Phong",
        "path": "Vĩnh Phong, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Vĩnh Phong, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11896",
        "parent_code": "316"
    },
    "11899": {
        "name": "Cộng Hiền",
        "type": "xa",
        "slug": "cong-hien",
        "name_with_type": "Xã Cộng Hiền",
        "path": "Cộng Hiền, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Cộng Hiền, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11899",
        "parent_code": "316"
    },
    "11902": {
        "name": "Cao Minh",
        "type": "xa",
        "slug": "cao-minh",
        "name_with_type": "Xã Cao Minh",
        "path": "Cao Minh, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Cao Minh, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11902",
        "parent_code": "316"
    },
    "11905": {
        "name": "Cổ Am",
        "type": "xa",
        "slug": "co-am",
        "name_with_type": "Xã Cổ Am",
        "path": "Cổ Am, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Cổ Am, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11905",
        "parent_code": "316"
    },
    "11908": {
        "name": "Vĩnh Tiến",
        "type": "xa",
        "slug": "vinh-tien",
        "name_with_type": "Xã Vĩnh Tiến",
        "path": "Vĩnh Tiến, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Vĩnh Tiến, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11908",
        "parent_code": "316"
    },
    "11911": {
        "name": "Trấn Dương",
        "type": "xa",
        "slug": "tran-duong",
        "name_with_type": "Xã Trấn Dương",
        "path": "Trấn Dương, Vĩnh Bảo, Hải Phòng",
        "path_with_type": "Xã Trấn Dương, Huyện Vĩnh Bảo, Thành phố Hải Phòng",
        "code": "11911",
        "parent_code": "316"
    },
    "11914": {
        "name": "Cát Bà",
        "type": "thi-tran",
        "slug": "cat-ba",
        "name_with_type": "Thị trấn Cát Bà",
        "path": "Cát Bà, Cát Hải, Hải Phòng",
        "path_with_type": "Thị trấn Cát Bà, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11914",
        "parent_code": "317"
    },
    "11917": {
        "name": "Cát Hải",
        "type": "thi-tran",
        "slug": "cat-hai",
        "name_with_type": "Thị trấn Cát Hải",
        "path": "Cát Hải, Cát Hải, Hải Phòng",
        "path_with_type": "Thị trấn Cát Hải, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11917",
        "parent_code": "317"
    },
    "11920": {
        "name": "Nghĩa Lộ",
        "type": "xa",
        "slug": "nghia-lo",
        "name_with_type": "Xã Nghĩa Lộ",
        "path": "Nghĩa Lộ, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Nghĩa Lộ, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11920",
        "parent_code": "317"
    },
    "11923": {
        "name": "Đồng Bài",
        "type": "xa",
        "slug": "dong-bai",
        "name_with_type": "Xã Đồng Bài",
        "path": "Đồng Bài, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Đồng Bài, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11923",
        "parent_code": "317"
    },
    "11926": {
        "name": "Hoàng Châu",
        "type": "xa",
        "slug": "hoang-chau",
        "name_with_type": "Xã Hoàng Châu",
        "path": "Hoàng Châu, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Hoàng Châu, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11926",
        "parent_code": "317"
    },
    "11929": {
        "name": "Văn Phong",
        "type": "xa",
        "slug": "van-phong",
        "name_with_type": "Xã Văn Phong",
        "path": "Văn Phong, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Văn Phong, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11929",
        "parent_code": "317"
    },
    "11932": {
        "name": "Phù Long",
        "type": "xa",
        "slug": "phu-long",
        "name_with_type": "Xã Phù Long",
        "path": "Phù Long, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Phù Long, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11932",
        "parent_code": "317"
    },
    "11935": {
        "name": "Gia Luận",
        "type": "xa",
        "slug": "gia-luan",
        "name_with_type": "Xã Gia Luận",
        "path": "Gia Luận, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Gia Luận, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11935",
        "parent_code": "317"
    },
    "11938": {
        "name": "Hiền Hào",
        "type": "xa",
        "slug": "hien-hao",
        "name_with_type": "Xã Hiền Hào",
        "path": "Hiền Hào, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Hiền Hào, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11938",
        "parent_code": "317"
    },
    "11941": {
        "name": "Trân Châu",
        "type": "xa",
        "slug": "tran-chau",
        "name_with_type": "Xã Trân Châu",
        "path": "Trân Châu, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Trân Châu, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11941",
        "parent_code": "317"
    },
    "11944": {
        "name": "Việt Hải",
        "type": "xa",
        "slug": "viet-hai",
        "name_with_type": "Xã Việt Hải",
        "path": "Việt Hải, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Việt Hải, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11944",
        "parent_code": "317"
    },
    "11947": {
        "name": "Xuân Đám",
        "type": "xa",
        "slug": "xuan-dam",
        "name_with_type": "Xã Xuân Đám",
        "path": "Xuân Đám, Cát Hải, Hải Phòng",
        "path_with_type": "Xã Xuân Đám, Huyện Cát Hải, Thành phố Hải Phòng",
        "code": "11947",
        "parent_code": "317"
    },
    "14320": {
        "name": "Đông Thành",
        "type": "phuong",
        "slug": "dong-thanh",
        "name_with_type": "Phường Đông Thành",
        "path": "Đông Thành, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Đông Thành, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14320",
        "parent_code": "369"
    },
    "14323": {
        "name": "Tân Thành",
        "type": "phuong",
        "slug": "tan-thanh",
        "name_with_type": "Phường Tân Thành",
        "path": "Tân Thành, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Tân Thành, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14323",
        "parent_code": "369"
    },
    "14326": {
        "name": "Thanh Bình",
        "type": "phuong",
        "slug": "thanh-binh",
        "name_with_type": "Phường Thanh Bình",
        "path": "Thanh Bình, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Thanh Bình, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14326",
        "parent_code": "369"
    },
    "14329": {
        "name": "Vân Giang",
        "type": "phuong",
        "slug": "van-giang",
        "name_with_type": "Phường Vân Giang",
        "path": "Vân Giang, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Vân Giang, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14329",
        "parent_code": "369"
    },
    "14332": {
        "name": "Bích Đào",
        "type": "phuong",
        "slug": "bich-dao",
        "name_with_type": "Phường Bích Đào",
        "path": "Bích Đào, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Bích Đào, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14332",
        "parent_code": "369"
    },
    "14335": {
        "name": "Phúc Thành",
        "type": "phuong",
        "slug": "phuc-thanh",
        "name_with_type": "Phường Phúc Thành",
        "path": "Phúc Thành, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Phúc Thành, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14335",
        "parent_code": "369"
    },
    "14338": {
        "name": "Nam Bình",
        "type": "phuong",
        "slug": "nam-binh",
        "name_with_type": "Phường Nam Bình",
        "path": "Nam Bình, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Nam Bình, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14338",
        "parent_code": "369"
    },
    "14341": {
        "name": "Nam Thành",
        "type": "phuong",
        "slug": "nam-thanh",
        "name_with_type": "Phường Nam Thành",
        "path": "Nam Thành, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Nam Thành, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14341",
        "parent_code": "369"
    },
    "14344": {
        "name": "Ninh Khánh",
        "type": "phuong",
        "slug": "ninh-khanh",
        "name_with_type": "Phường Ninh Khánh",
        "path": "Ninh Khánh, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Ninh Khánh, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14344",
        "parent_code": "369"
    },
    "14347": {
        "name": "Ninh Nhất",
        "type": "xa",
        "slug": "ninh-nhat",
        "name_with_type": "Xã Ninh Nhất",
        "path": "Ninh Nhất, Ninh Bình, Ninh Bình",
        "path_with_type": "Xã Ninh Nhất, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14347",
        "parent_code": "369"
    },
    "14350": {
        "name": "Ninh Tiến",
        "type": "xa",
        "slug": "ninh-tien",
        "name_with_type": "Xã Ninh Tiến",
        "path": "Ninh Tiến, Ninh Bình, Ninh Bình",
        "path_with_type": "Xã Ninh Tiến, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14350",
        "parent_code": "369"
    },
    "14353": {
        "name": "Ninh Phúc",
        "type": "xa",
        "slug": "ninh-phuc",
        "name_with_type": "Xã Ninh Phúc",
        "path": "Ninh Phúc, Ninh Bình, Ninh Bình",
        "path_with_type": "Xã Ninh Phúc, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14353",
        "parent_code": "369"
    },
    "14356": {
        "name": "Ninh Sơn",
        "type": "phuong",
        "slug": "ninh-son",
        "name_with_type": "Phường Ninh Sơn",
        "path": "Ninh Sơn, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Ninh Sơn, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14356",
        "parent_code": "369"
    },
    "14359": {
        "name": "Ninh Phong",
        "type": "phuong",
        "slug": "ninh-phong",
        "name_with_type": "Phường Ninh Phong",
        "path": "Ninh Phong, Ninh Bình, Ninh Bình",
        "path_with_type": "Phường Ninh Phong, Thành phố Ninh Bình, Tỉnh Ninh Bình",
        "code": "14359",
        "parent_code": "369"
    },
    "14362": {
        "name": "Bắc Sơn",
        "type": "phuong",
        "slug": "bac-son",
        "name_with_type": "Phường Bắc Sơn",
        "path": "Bắc Sơn, Tam Điệp, Ninh Bình",
        "path_with_type": "Phường Bắc Sơn, Thành phố Tam Điệp, Tỉnh Ninh Bình",
        "code": "14362",
        "parent_code": "370"
    },
    "14365": {
        "name": "Trung Sơn",
        "type": "phuong",
        "slug": "trung-son",
        "name_with_type": "Phường Trung Sơn",
        "path": "Trung Sơn, Tam Điệp, Ninh Bình",
        "path_with_type": "Phường Trung Sơn, Thành phố Tam Điệp, Tỉnh Ninh Bình",
        "code": "14365",
        "parent_code": "370"
    },
    "14368": {
        "name": "Nam Sơn",
        "type": "phuong",
        "slug": "nam-son",
        "name_with_type": "Phường Nam Sơn",
        "path": "Nam Sơn, Tam Điệp, Ninh Bình",
        "path_with_type": "Phường Nam Sơn, Thành phố Tam Điệp, Tỉnh Ninh Bình",
        "code": "14368",
        "parent_code": "370"
    },
    "14369": {
        "name": "Tây Sơn",
        "type": "phuong",
        "slug": "tay-son",
        "name_with_type": "Phường Tây Sơn",
        "path": "Tây Sơn, Tam Điệp, Ninh Bình",
        "path_with_type": "Phường Tây Sơn, Thành phố Tam Điệp, Tỉnh Ninh Bình",
        "code": "14369",
        "parent_code": "370"
    },
    "14371": {
        "name": "Yên Sơn",
        "type": "xa",
        "slug": "yen-son",
        "name_with_type": "Xã Yên Sơn",
        "path": "Yên Sơn, Tam Điệp, Ninh Bình",
        "path_with_type": "Xã Yên Sơn, Thành phố Tam Điệp, Tỉnh Ninh Bình",
        "code": "14371",
        "parent_code": "370"
    },
    "14374": {
        "name": "Yên Bình",
        "type": "phuong",
        "slug": "yen-binh",
        "name_with_type": "Phường Yên Bình",
        "path": "Yên Bình, Tam Điệp, Ninh Bình",
        "path_with_type": "Phường Yên Bình, Thành phố Tam Điệp, Tỉnh Ninh Bình",
        "code": "14374",
        "parent_code": "370"
    },
    "14375": {
        "name": "Tân Bình",
        "type": "phuong",
        "slug": "tan-binh",
        "name_with_type": "Phường Tân Bình",
        "path": "Tân Bình, Tam Điệp, Ninh Bình",
        "path_with_type": "Phường Tân Bình, Thành phố Tam Điệp, Tỉnh Ninh Bình",
        "code": "14375",
        "parent_code": "370"
    },
    "14377": {
        "name": "Quang Sơn",
        "type": "xa",
        "slug": "quang-son",
        "name_with_type": "Xã Quang Sơn",
        "path": "Quang Sơn, Tam Điệp, Ninh Bình",
        "path_with_type": "Xã Quang Sơn, Thành phố Tam Điệp, Tỉnh Ninh Bình",
        "code": "14377",
        "parent_code": "370"
    },
    "14380": {
        "name": "Đông Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đông Sơn",
        "path": "Đông Sơn, Tam Điệp, Ninh Bình",
        "path_with_type": "Xã Đông Sơn, Thành phố Tam Điệp, Tỉnh Ninh Bình",
        "code": "14380",
        "parent_code": "370"
    },
    "14383": {
        "name": "Nho Quan",
        "type": "thi-tran",
        "slug": "nho-quan",
        "name_with_type": "Thị trấn Nho Quan",
        "path": "Nho Quan, Nho Quan, Ninh Bình",
        "path_with_type": "Thị trấn Nho Quan, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14383",
        "parent_code": "372"
    },
    "14386": {
        "name": "Xích Thổ",
        "type": "xa",
        "slug": "xich-tho",
        "name_with_type": "Xã Xích Thổ",
        "path": "Xích Thổ, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Xích Thổ, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14386",
        "parent_code": "372"
    },
    "14389": {
        "name": "Gia Lâm",
        "type": "xa",
        "slug": "gia-lam",
        "name_with_type": "Xã Gia Lâm",
        "path": "Gia Lâm, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Gia Lâm, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14389",
        "parent_code": "372"
    },
    "14392": {
        "name": "Gia Sơn",
        "type": "xa",
        "slug": "gia-son",
        "name_with_type": "Xã Gia Sơn",
        "path": "Gia Sơn, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Gia Sơn, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14392",
        "parent_code": "372"
    },
    "14395": {
        "name": "Thạch Bình",
        "type": "xa",
        "slug": "thach-binh",
        "name_with_type": "Xã Thạch Bình",
        "path": "Thạch Bình, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Thạch Bình, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14395",
        "parent_code": "372"
    },
    "14398": {
        "name": "Gia Thủy",
        "type": "xa",
        "slug": "gia-thuy",
        "name_with_type": "Xã Gia Thủy",
        "path": "Gia Thủy, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Gia Thủy, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14398",
        "parent_code": "372"
    },
    "14401": {
        "name": "Gia Tường",
        "type": "xa",
        "slug": "gia-tuong",
        "name_with_type": "Xã Gia Tường",
        "path": "Gia Tường, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Gia Tường, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14401",
        "parent_code": "372"
    },
    "14404": {
        "name": "Cúc Phương",
        "type": "xa",
        "slug": "cuc-phuong",
        "name_with_type": "Xã Cúc Phương",
        "path": "Cúc Phương, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Cúc Phương, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14404",
        "parent_code": "372"
    },
    "14407": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Phú Sơn, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14407",
        "parent_code": "372"
    },
    "14410": {
        "name": "Đức Long",
        "type": "xa",
        "slug": "duc-long",
        "name_with_type": "Xã Đức Long",
        "path": "Đức Long, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Đức Long, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14410",
        "parent_code": "372"
    },
    "14413": {
        "name": "Lạc Vân",
        "type": "xa",
        "slug": "lac-van",
        "name_with_type": "Xã Lạc Vân",
        "path": "Lạc Vân, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Lạc Vân, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14413",
        "parent_code": "372"
    },
    "14416": {
        "name": "Đồng Phong",
        "type": "xa",
        "slug": "dong-phong",
        "name_with_type": "Xã Đồng Phong",
        "path": "Đồng Phong, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Đồng Phong, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14416",
        "parent_code": "372"
    },
    "14419": {
        "name": "Yên Quang",
        "type": "xa",
        "slug": "yen-quang",
        "name_with_type": "Xã Yên Quang",
        "path": "Yên Quang, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Yên Quang, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14419",
        "parent_code": "372"
    },
    "14422": {
        "name": "Lạng Phong",
        "type": "xa",
        "slug": "lang-phong",
        "name_with_type": "Xã Lạng Phong",
        "path": "Lạng Phong, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Lạng Phong, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14422",
        "parent_code": "372"
    },
    "14425": {
        "name": "Thượng Hòa",
        "type": "xa",
        "slug": "thuong-hoa",
        "name_with_type": "Xã Thượng Hòa",
        "path": "Thượng Hòa, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Thượng Hòa, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14425",
        "parent_code": "372"
    },
    "14428": {
        "name": "Văn Phong",
        "type": "xa",
        "slug": "van-phong",
        "name_with_type": "Xã Văn Phong",
        "path": "Văn Phong, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Văn Phong, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14428",
        "parent_code": "372"
    },
    "14431": {
        "name": "Văn Phương",
        "type": "xa",
        "slug": "van-phuong",
        "name_with_type": "Xã Văn Phương",
        "path": "Văn Phương, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Văn Phương, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14431",
        "parent_code": "372"
    },
    "14434": {
        "name": "Thanh Lạc",
        "type": "xa",
        "slug": "thanh-lac",
        "name_with_type": "Xã Thanh Lạc",
        "path": "Thanh Lạc, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Thanh Lạc, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14434",
        "parent_code": "372"
    },
    "14437": {
        "name": "Sơn Lai",
        "type": "xa",
        "slug": "son-lai",
        "name_with_type": "Xã Sơn Lai",
        "path": "Sơn Lai, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Sơn Lai, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14437",
        "parent_code": "372"
    },
    "14440": {
        "name": "Sơn Thành",
        "type": "xa",
        "slug": "son-thanh",
        "name_with_type": "Xã Sơn Thành",
        "path": "Sơn Thành, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Sơn Thành, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14440",
        "parent_code": "372"
    },
    "14443": {
        "name": "Văn Phú",
        "type": "xa",
        "slug": "van-phu",
        "name_with_type": "Xã Văn Phú",
        "path": "Văn Phú, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Văn Phú, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14443",
        "parent_code": "372"
    },
    "14446": {
        "name": "Phú Lộc",
        "type": "xa",
        "slug": "phu-loc",
        "name_with_type": "Xã Phú Lộc",
        "path": "Phú Lộc, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Phú Lộc, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14446",
        "parent_code": "372"
    },
    "14449": {
        "name": "Kỳ Phú",
        "type": "xa",
        "slug": "ky-phu",
        "name_with_type": "Xã Kỳ Phú",
        "path": "Kỳ Phú, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Kỳ Phú, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14449",
        "parent_code": "372"
    },
    "14452": {
        "name": "Quỳnh Lưu",
        "type": "xa",
        "slug": "quynh-luu",
        "name_with_type": "Xã Quỳnh Lưu",
        "path": "Quỳnh Lưu, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Quỳnh Lưu, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14452",
        "parent_code": "372"
    },
    "14455": {
        "name": "Sơn Hà",
        "type": "xa",
        "slug": "son-ha",
        "name_with_type": "Xã Sơn Hà",
        "path": "Sơn Hà, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Sơn Hà, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14455",
        "parent_code": "372"
    },
    "14458": {
        "name": "Phú Long",
        "type": "xa",
        "slug": "phu-long",
        "name_with_type": "Xã Phú Long",
        "path": "Phú Long, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Phú Long, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14458",
        "parent_code": "372"
    },
    "14461": {
        "name": "Quảng Lạc",
        "type": "xa",
        "slug": "quang-lac",
        "name_with_type": "Xã Quảng Lạc",
        "path": "Quảng Lạc, Nho Quan, Ninh Bình",
        "path_with_type": "Xã Quảng Lạc, Huyện Nho Quan, Tỉnh Ninh Bình",
        "code": "14461",
        "parent_code": "372"
    },
    "14464": {
        "name": "Me",
        "type": "thi-tran",
        "slug": "me",
        "name_with_type": "Thị trấn Me",
        "path": "Me, Gia Viễn, Ninh Bình",
        "path_with_type": "Thị trấn Me, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14464",
        "parent_code": "373"
    },
    "14467": {
        "name": "Gia Hòa",
        "type": "xa",
        "slug": "gia-hoa",
        "name_with_type": "Xã Gia Hòa",
        "path": "Gia Hòa, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Hòa, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14467",
        "parent_code": "373"
    },
    "14470": {
        "name": "Gia Hưng",
        "type": "xa",
        "slug": "gia-hung",
        "name_with_type": "Xã Gia Hưng",
        "path": "Gia Hưng, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Hưng, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14470",
        "parent_code": "373"
    },
    "14473": {
        "name": "Liên Sơn",
        "type": "xa",
        "slug": "lien-son",
        "name_with_type": "Xã Liên Sơn",
        "path": "Liên Sơn, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Liên Sơn, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14473",
        "parent_code": "373"
    },
    "14476": {
        "name": "Gia Thanh",
        "type": "xa",
        "slug": "gia-thanh",
        "name_with_type": "Xã Gia Thanh",
        "path": "Gia Thanh, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Thanh, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14476",
        "parent_code": "373"
    },
    "14479": {
        "name": "Gia Vân",
        "type": "xa",
        "slug": "gia-van",
        "name_with_type": "Xã Gia Vân",
        "path": "Gia Vân, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Vân, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14479",
        "parent_code": "373"
    },
    "14482": {
        "name": "Gia Phú",
        "type": "xa",
        "slug": "gia-phu",
        "name_with_type": "Xã Gia Phú",
        "path": "Gia Phú, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Phú, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14482",
        "parent_code": "373"
    },
    "14485": {
        "name": "Gia Xuân",
        "type": "xa",
        "slug": "gia-xuan",
        "name_with_type": "Xã Gia Xuân",
        "path": "Gia Xuân, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Xuân, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14485",
        "parent_code": "373"
    },
    "14488": {
        "name": "Gia Lập",
        "type": "xa",
        "slug": "gia-lap",
        "name_with_type": "Xã Gia Lập",
        "path": "Gia Lập, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Lập, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14488",
        "parent_code": "373"
    },
    "14491": {
        "name": "Gia Vượng",
        "type": "xa",
        "slug": "gia-vuong",
        "name_with_type": "Xã Gia Vượng",
        "path": "Gia Vượng, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Vượng, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14491",
        "parent_code": "373"
    },
    "14494": {
        "name": "Gia Trấn",
        "type": "xa",
        "slug": "gia-tran",
        "name_with_type": "Xã Gia Trấn",
        "path": "Gia Trấn, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Trấn, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14494",
        "parent_code": "373"
    },
    "14497": {
        "name": "Gia Thịnh",
        "type": "xa",
        "slug": "gia-thinh",
        "name_with_type": "Xã Gia Thịnh",
        "path": "Gia Thịnh, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Thịnh, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14497",
        "parent_code": "373"
    },
    "14500": {
        "name": "Gia Phương",
        "type": "xa",
        "slug": "gia-phuong",
        "name_with_type": "Xã Gia Phương",
        "path": "Gia Phương, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Phương, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14500",
        "parent_code": "373"
    },
    "14503": {
        "name": "Gia Tân",
        "type": "xa",
        "slug": "gia-tan",
        "name_with_type": "Xã Gia Tân",
        "path": "Gia Tân, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Tân, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14503",
        "parent_code": "373"
    },
    "14506": {
        "name": "Gia Thắng",
        "type": "xa",
        "slug": "gia-thang",
        "name_with_type": "Xã Gia Thắng",
        "path": "Gia Thắng, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Thắng, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14506",
        "parent_code": "373"
    },
    "14509": {
        "name": "Gia Trung",
        "type": "xa",
        "slug": "gia-trung",
        "name_with_type": "Xã Gia Trung",
        "path": "Gia Trung, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Trung, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14509",
        "parent_code": "373"
    },
    "14512": {
        "name": "Gia Minh",
        "type": "xa",
        "slug": "gia-minh",
        "name_with_type": "Xã Gia Minh",
        "path": "Gia Minh, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Minh, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14512",
        "parent_code": "373"
    },
    "14515": {
        "name": "Gia Lạc",
        "type": "xa",
        "slug": "gia-lac",
        "name_with_type": "Xã Gia Lạc",
        "path": "Gia Lạc, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Lạc, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14515",
        "parent_code": "373"
    },
    "14518": {
        "name": "Gia Tiến",
        "type": "xa",
        "slug": "gia-tien",
        "name_with_type": "Xã Gia Tiến",
        "path": "Gia Tiến, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Tiến, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14518",
        "parent_code": "373"
    },
    "14521": {
        "name": "Gia Sinh",
        "type": "xa",
        "slug": "gia-sinh",
        "name_with_type": "Xã Gia Sinh",
        "path": "Gia Sinh, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Sinh, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14521",
        "parent_code": "373"
    },
    "14524": {
        "name": "Gia Phong",
        "type": "xa",
        "slug": "gia-phong",
        "name_with_type": "Xã Gia Phong",
        "path": "Gia Phong, Gia Viễn, Ninh Bình",
        "path_with_type": "Xã Gia Phong, Huyện Gia Viễn, Tỉnh Ninh Bình",
        "code": "14524",
        "parent_code": "373"
    },
    "14527": {
        "name": "Thiên Tôn",
        "type": "thi-tran",
        "slug": "thien-ton",
        "name_with_type": "Thị trấn Thiên Tôn",
        "path": "Thiên Tôn, Hoa Lư, Ninh Bình",
        "path_with_type": "Thị trấn Thiên Tôn, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14527",
        "parent_code": "374"
    },
    "14530": {
        "name": "Ninh Giang",
        "type": "xa",
        "slug": "ninh-giang",
        "name_with_type": "Xã Ninh Giang",
        "path": "Ninh Giang, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Ninh Giang, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14530",
        "parent_code": "374"
    },
    "14533": {
        "name": "Trường Yên",
        "type": "xa",
        "slug": "truong-yen",
        "name_with_type": "Xã Trường Yên",
        "path": "Trường Yên, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Trường Yên, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14533",
        "parent_code": "374"
    },
    "14536": {
        "name": "Ninh Khang",
        "type": "xa",
        "slug": "ninh-khang",
        "name_with_type": "Xã Ninh Khang",
        "path": "Ninh Khang, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Ninh Khang, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14536",
        "parent_code": "374"
    },
    "14539": {
        "name": "Ninh Mỹ",
        "type": "xa",
        "slug": "ninh-my",
        "name_with_type": "Xã Ninh Mỹ",
        "path": "Ninh Mỹ, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Ninh Mỹ, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14539",
        "parent_code": "374"
    },
    "14542": {
        "name": "Ninh Hòa",
        "type": "xa",
        "slug": "ninh-hoa",
        "name_with_type": "Xã Ninh Hòa",
        "path": "Ninh Hòa, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Ninh Hòa, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14542",
        "parent_code": "374"
    },
    "14545": {
        "name": "Ninh Xuân",
        "type": "xa",
        "slug": "ninh-xuan",
        "name_with_type": "Xã Ninh Xuân",
        "path": "Ninh Xuân, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Ninh Xuân, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14545",
        "parent_code": "374"
    },
    "14548": {
        "name": "Ninh Hải",
        "type": "xa",
        "slug": "ninh-hai",
        "name_with_type": "Xã Ninh Hải",
        "path": "Ninh Hải, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Ninh Hải, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14548",
        "parent_code": "374"
    },
    "14551": {
        "name": "Ninh Thắng",
        "type": "xa",
        "slug": "ninh-thang",
        "name_with_type": "Xã Ninh Thắng",
        "path": "Ninh Thắng, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Ninh Thắng, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14551",
        "parent_code": "374"
    },
    "14554": {
        "name": "Ninh Vân",
        "type": "xa",
        "slug": "ninh-van",
        "name_with_type": "Xã Ninh Vân",
        "path": "Ninh Vân, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Ninh Vân, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14554",
        "parent_code": "374"
    },
    "14557": {
        "name": "Ninh An",
        "type": "xa",
        "slug": "ninh-an",
        "name_with_type": "Xã Ninh An",
        "path": "Ninh An, Hoa Lư, Ninh Bình",
        "path_with_type": "Xã Ninh An, Huyện Hoa Lư, Tỉnh Ninh Bình",
        "code": "14557",
        "parent_code": "374"
    },
    "14560": {
        "name": "Yên Ninh",
        "type": "thi-tran",
        "slug": "yen-ninh",
        "name_with_type": "Thị trấn Yên Ninh",
        "path": "Yên Ninh, Yên Khánh, Ninh Bình",
        "path_with_type": "Thị trấn Yên Ninh, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14560",
        "parent_code": "375"
    },
    "14563": {
        "name": "Khánh Tiên",
        "type": "xa",
        "slug": "khanh-tien",
        "name_with_type": "Xã Khánh Tiên",
        "path": "Khánh Tiên, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Tiên, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14563",
        "parent_code": "375"
    },
    "14566": {
        "name": "Khánh Phú",
        "type": "xa",
        "slug": "khanh-phu",
        "name_with_type": "Xã Khánh Phú",
        "path": "Khánh Phú, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Phú, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14566",
        "parent_code": "375"
    },
    "14569": {
        "name": "Khánh Hòa",
        "type": "xa",
        "slug": "khanh-hoa",
        "name_with_type": "Xã Khánh Hòa",
        "path": "Khánh Hòa, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Hòa, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14569",
        "parent_code": "375"
    },
    "14572": {
        "name": "Khánh Lợi",
        "type": "xa",
        "slug": "khanh-loi",
        "name_with_type": "Xã Khánh Lợi",
        "path": "Khánh Lợi, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Lợi, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14572",
        "parent_code": "375"
    },
    "14575": {
        "name": "Khánh An",
        "type": "xa",
        "slug": "khanh-an",
        "name_with_type": "Xã Khánh An",
        "path": "Khánh An, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh An, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14575",
        "parent_code": "375"
    },
    "14578": {
        "name": "Khánh Cường",
        "type": "xa",
        "slug": "khanh-cuong",
        "name_with_type": "Xã Khánh Cường",
        "path": "Khánh Cường, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Cường, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14578",
        "parent_code": "375"
    },
    "14581": {
        "name": "Khánh Cư",
        "type": "xa",
        "slug": "khanh-cu",
        "name_with_type": "Xã Khánh Cư",
        "path": "Khánh Cư, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Cư, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14581",
        "parent_code": "375"
    },
    "14584": {
        "name": "Khánh Thiện",
        "type": "xa",
        "slug": "khanh-thien",
        "name_with_type": "Xã Khánh Thiện",
        "path": "Khánh Thiện, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Thiện, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14584",
        "parent_code": "375"
    },
    "14587": {
        "name": "Khánh Hải",
        "type": "xa",
        "slug": "khanh-hai",
        "name_with_type": "Xã Khánh Hải",
        "path": "Khánh Hải, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Hải, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14587",
        "parent_code": "375"
    },
    "14590": {
        "name": "Khánh Trung",
        "type": "xa",
        "slug": "khanh-trung",
        "name_with_type": "Xã Khánh Trung",
        "path": "Khánh Trung, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Trung, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14590",
        "parent_code": "375"
    },
    "14593": {
        "name": "Khánh Mậu",
        "type": "xa",
        "slug": "khanh-mau",
        "name_with_type": "Xã Khánh Mậu",
        "path": "Khánh Mậu, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Mậu, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14593",
        "parent_code": "375"
    },
    "14596": {
        "name": "Khánh Vân",
        "type": "xa",
        "slug": "khanh-van",
        "name_with_type": "Xã Khánh Vân",
        "path": "Khánh Vân, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Vân, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14596",
        "parent_code": "375"
    },
    "14599": {
        "name": "Khánh Hội",
        "type": "xa",
        "slug": "khanh-hoi",
        "name_with_type": "Xã Khánh Hội",
        "path": "Khánh Hội, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Hội, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14599",
        "parent_code": "375"
    },
    "14602": {
        "name": "Khánh Công",
        "type": "xa",
        "slug": "khanh-cong",
        "name_with_type": "Xã Khánh Công",
        "path": "Khánh Công, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Công, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14602",
        "parent_code": "375"
    },
    "14608": {
        "name": "Khánh Thành",
        "type": "xa",
        "slug": "khanh-thanh",
        "name_with_type": "Xã Khánh Thành",
        "path": "Khánh Thành, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Thành, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14608",
        "parent_code": "375"
    },
    "14611": {
        "name": "Khánh Nhạc",
        "type": "xa",
        "slug": "khanh-nhac",
        "name_with_type": "Xã Khánh Nhạc",
        "path": "Khánh Nhạc, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Nhạc, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14611",
        "parent_code": "375"
    },
    "14614": {
        "name": "Khánh Thủy",
        "type": "xa",
        "slug": "khanh-thuy",
        "name_with_type": "Xã Khánh Thủy",
        "path": "Khánh Thủy, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Thủy, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14614",
        "parent_code": "375"
    },
    "14617": {
        "name": "Khánh Hồng",
        "type": "xa",
        "slug": "khanh-hong",
        "name_with_type": "Xã Khánh Hồng",
        "path": "Khánh Hồng, Yên Khánh, Ninh Bình",
        "path_with_type": "Xã Khánh Hồng, Huyện Yên Khánh, Tỉnh Ninh Bình",
        "code": "14617",
        "parent_code": "375"
    },
    "14620": {
        "name": "Phát Diệm",
        "type": "thi-tran",
        "slug": "phat-diem",
        "name_with_type": "Thị trấn Phát Diệm",
        "path": "Phát Diệm, Kim Sơn, Ninh Bình",
        "path_with_type": "Thị trấn Phát Diệm, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14620",
        "parent_code": "376"
    },
    "14623": {
        "name": "Bình Minh",
        "type": "thi-tran",
        "slug": "binh-minh",
        "name_with_type": "Thị trấn Bình Minh",
        "path": "Bình Minh, Kim Sơn, Ninh Bình",
        "path_with_type": "Thị trấn Bình Minh, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14623",
        "parent_code": "376"
    },
    "14626": {
        "name": "Xuân Thiện",
        "type": "xa",
        "slug": "xuan-thien",
        "name_with_type": "Xã Xuân Thiện",
        "path": "Xuân Thiện, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Xuân Thiện, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14626",
        "parent_code": "376"
    },
    "14629": {
        "name": "Hồi Ninh",
        "type": "xa",
        "slug": "hoi-ninh",
        "name_with_type": "Xã Hồi Ninh",
        "path": "Hồi Ninh, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Hồi Ninh, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14629",
        "parent_code": "376"
    },
    "14632": {
        "name": "Chính Tâm",
        "type": "xa",
        "slug": "chinh-tam",
        "name_with_type": "Xã Chính Tâm",
        "path": "Chính Tâm, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Chính Tâm, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14632",
        "parent_code": "376"
    },
    "14635": {
        "name": "Kim Định",
        "type": "xa",
        "slug": "kim-dinh",
        "name_with_type": "Xã Kim Định",
        "path": "Kim Định, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Kim Định, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14635",
        "parent_code": "376"
    },
    "14638": {
        "name": "Ân Hòa",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã Ân Hòa",
        "path": "Ân Hòa, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Ân Hòa, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14638",
        "parent_code": "376"
    },
    "14641": {
        "name": "Hùng Tiến",
        "type": "xa",
        "slug": "hung-tien",
        "name_with_type": "Xã Hùng Tiến",
        "path": "Hùng Tiến, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Hùng Tiến, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14641",
        "parent_code": "376"
    },
    "14644": {
        "name": "Yên Mật",
        "type": "xa",
        "slug": "yen-mat",
        "name_with_type": "Xã Yên Mật",
        "path": "Yên Mật, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Yên Mật, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14644",
        "parent_code": "376"
    },
    "14647": {
        "name": "Quang Thiện",
        "type": "xa",
        "slug": "quang-thien",
        "name_with_type": "Xã Quang Thiện",
        "path": "Quang Thiện, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Quang Thiện, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14647",
        "parent_code": "376"
    },
    "14650": {
        "name": "Như Hòa",
        "type": "xa",
        "slug": "nhu-hoa",
        "name_with_type": "Xã Như Hòa",
        "path": "Như Hòa, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Như Hòa, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14650",
        "parent_code": "376"
    },
    "14653": {
        "name": "Chất Bình",
        "type": "xa",
        "slug": "chat-binh",
        "name_with_type": "Xã Chất Bình",
        "path": "Chất Bình, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Chất Bình, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14653",
        "parent_code": "376"
    },
    "14656": {
        "name": "Đồng Hướng",
        "type": "xa",
        "slug": "dong-huong",
        "name_with_type": "Xã Đồng Hướng",
        "path": "Đồng Hướng, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Đồng Hướng, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14656",
        "parent_code": "376"
    },
    "14659": {
        "name": "Kim Chính",
        "type": "xa",
        "slug": "kim-chinh",
        "name_with_type": "Xã Kim Chính",
        "path": "Kim Chính, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Kim Chính, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14659",
        "parent_code": "376"
    },
    "14662": {
        "name": "Thượng Kiệm",
        "type": "xa",
        "slug": "thuong-kiem",
        "name_with_type": "Xã Thượng Kiệm",
        "path": "Thượng Kiệm, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Thượng Kiệm, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14662",
        "parent_code": "376"
    },
    "14665": {
        "name": "Lưu Phương",
        "type": "xa",
        "slug": "luu-phuong",
        "name_with_type": "Xã Lưu Phương",
        "path": "Lưu Phương, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Lưu Phương, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14665",
        "parent_code": "376"
    },
    "14668": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Tân Thành, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14668",
        "parent_code": "376"
    },
    "14671": {
        "name": "Yên Lộc",
        "type": "xa",
        "slug": "yen-loc",
        "name_with_type": "Xã Yên Lộc",
        "path": "Yên Lộc, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Yên Lộc, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14671",
        "parent_code": "376"
    },
    "14674": {
        "name": "Lai Thành",
        "type": "xa",
        "slug": "lai-thanh",
        "name_with_type": "Xã Lai Thành",
        "path": "Lai Thành, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Lai Thành, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14674",
        "parent_code": "376"
    },
    "14677": {
        "name": "Định Hóa",
        "type": "xa",
        "slug": "dinh-hoa",
        "name_with_type": "Xã Định Hóa",
        "path": "Định Hóa, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Định Hóa, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14677",
        "parent_code": "376"
    },
    "14680": {
        "name": "Văn Hải",
        "type": "xa",
        "slug": "van-hai",
        "name_with_type": "Xã Văn Hải",
        "path": "Văn Hải, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Văn Hải, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14680",
        "parent_code": "376"
    },
    "14683": {
        "name": "Kim Tân",
        "type": "xa",
        "slug": "kim-tan",
        "name_with_type": "Xã Kim Tân",
        "path": "Kim Tân, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Kim Tân, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14683",
        "parent_code": "376"
    },
    "14686": {
        "name": "Kim Mỹ",
        "type": "xa",
        "slug": "kim-my",
        "name_with_type": "Xã Kim Mỹ",
        "path": "Kim Mỹ, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Kim Mỹ, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14686",
        "parent_code": "376"
    },
    "14689": {
        "name": "Cồn Thoi",
        "type": "xa",
        "slug": "con-thoi",
        "name_with_type": "Xã Cồn Thoi",
        "path": "Cồn Thoi, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Cồn Thoi, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14689",
        "parent_code": "376"
    },
    "14692": {
        "name": "Kim Hải",
        "type": "xa",
        "slug": "kim-hai",
        "name_with_type": "Xã Kim Hải",
        "path": "Kim Hải, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Kim Hải, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14692",
        "parent_code": "376"
    },
    "14695": {
        "name": "Kim Trung",
        "type": "xa",
        "slug": "kim-trung",
        "name_with_type": "Xã Kim Trung",
        "path": "Kim Trung, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Kim Trung, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14695",
        "parent_code": "376"
    },
    "14698": {
        "name": "Kim Đông",
        "type": "xa",
        "slug": "kim-dong",
        "name_with_type": "Xã Kim Đông",
        "path": "Kim Đông, Kim Sơn, Ninh Bình",
        "path_with_type": "Xã Kim Đông, Huyện Kim Sơn, Tỉnh Ninh Bình",
        "code": "14698",
        "parent_code": "376"
    },
    "14701": {
        "name": "Yên Thịnh",
        "type": "thi-tran",
        "slug": "yen-thinh",
        "name_with_type": "Thị trấn Yên Thịnh",
        "path": "Yên Thịnh, Yên Mô, Ninh Bình",
        "path_with_type": "Thị trấn Yên Thịnh, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14701",
        "parent_code": "377"
    },
    "14704": {
        "name": "Khánh Thượng",
        "type": "xa",
        "slug": "khanh-thuong",
        "name_with_type": "Xã Khánh Thượng",
        "path": "Khánh Thượng, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Khánh Thượng, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14704",
        "parent_code": "377"
    },
    "14707": {
        "name": "Khánh Dương",
        "type": "xa",
        "slug": "khanh-duong",
        "name_with_type": "Xã Khánh Dương",
        "path": "Khánh Dương, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Khánh Dương, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14707",
        "parent_code": "377"
    },
    "14710": {
        "name": "Mai Sơn",
        "type": "xa",
        "slug": "mai-son",
        "name_with_type": "Xã Mai Sơn",
        "path": "Mai Sơn, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Mai Sơn, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14710",
        "parent_code": "377"
    },
    "14713": {
        "name": "Khánh Thịnh",
        "type": "xa",
        "slug": "khanh-thinh",
        "name_with_type": "Xã Khánh Thịnh",
        "path": "Khánh Thịnh, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Khánh Thịnh, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14713",
        "parent_code": "377"
    },
    "14719": {
        "name": "Yên Phong",
        "type": "xa",
        "slug": "yen-phong",
        "name_with_type": "Xã Yên Phong",
        "path": "Yên Phong, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Phong, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14719",
        "parent_code": "377"
    },
    "14722": {
        "name": "Yên Hòa",
        "type": "xa",
        "slug": "yen-hoa",
        "name_with_type": "Xã Yên Hòa",
        "path": "Yên Hòa, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Hòa, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14722",
        "parent_code": "377"
    },
    "14725": {
        "name": "Yên Thắng",
        "type": "xa",
        "slug": "yen-thang",
        "name_with_type": "Xã Yên Thắng",
        "path": "Yên Thắng, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Thắng, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14725",
        "parent_code": "377"
    },
    "14728": {
        "name": "Yên Từ",
        "type": "xa",
        "slug": "yen-tu",
        "name_with_type": "Xã Yên Từ",
        "path": "Yên Từ, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Từ, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14728",
        "parent_code": "377"
    },
    "14731": {
        "name": "Yên Hưng",
        "type": "xa",
        "slug": "yen-hung",
        "name_with_type": "Xã Yên Hưng",
        "path": "Yên Hưng, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Hưng, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14731",
        "parent_code": "377"
    },
    "14734": {
        "name": "Yên Thành",
        "type": "xa",
        "slug": "yen-thanh",
        "name_with_type": "Xã Yên Thành",
        "path": "Yên Thành, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Thành, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14734",
        "parent_code": "377"
    },
    "14737": {
        "name": "Yên Nhân",
        "type": "xa",
        "slug": "yen-nhan",
        "name_with_type": "Xã Yên Nhân",
        "path": "Yên Nhân, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Nhân, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14737",
        "parent_code": "377"
    },
    "14740": {
        "name": "Yên Mỹ",
        "type": "xa",
        "slug": "yen-my",
        "name_with_type": "Xã Yên Mỹ",
        "path": "Yên Mỹ, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Mỹ, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14740",
        "parent_code": "377"
    },
    "14743": {
        "name": "Yên Mạc",
        "type": "xa",
        "slug": "yen-mac",
        "name_with_type": "Xã Yên Mạc",
        "path": "Yên Mạc, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Mạc, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14743",
        "parent_code": "377"
    },
    "14746": {
        "name": "Yên Đồng",
        "type": "xa",
        "slug": "yen-dong",
        "name_with_type": "Xã Yên Đồng",
        "path": "Yên Đồng, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Đồng, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14746",
        "parent_code": "377"
    },
    "14749": {
        "name": "Yên Thái",
        "type": "xa",
        "slug": "yen-thai",
        "name_with_type": "Xã Yên Thái",
        "path": "Yên Thái, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Thái, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14749",
        "parent_code": "377"
    },
    "14752": {
        "name": "Yên Lâm",
        "type": "xa",
        "slug": "yen-lam",
        "name_with_type": "Xã Yên Lâm",
        "path": "Yên Lâm, Yên Mô, Ninh Bình",
        "path_with_type": "Xã Yên Lâm, Huyện Yên Mô, Tỉnh Ninh Bình",
        "code": "14752",
        "parent_code": "377"
    },
    "04249": {
        "name": "Yên Thịnh",
        "type": "phuong",
        "slug": "yen-thinh",
        "name_with_type": "Phường Yên Thịnh",
        "path": "Yên Thịnh, Yên Bái, Yên Bái",
        "path_with_type": "Phường Yên Thịnh, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04249",
        "parent_code": "132"
    },
    "04252": {
        "name": "Yên Ninh",
        "type": "phuong",
        "slug": "yen-ninh",
        "name_with_type": "Phường Yên Ninh",
        "path": "Yên Ninh, Yên Bái, Yên Bái",
        "path_with_type": "Phường Yên Ninh, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04252",
        "parent_code": "132"
    },
    "04255": {
        "name": "Minh Tân",
        "type": "phuong",
        "slug": "minh-tan",
        "name_with_type": "Phường Minh Tân",
        "path": "Minh Tân, Yên Bái, Yên Bái",
        "path_with_type": "Phường Minh Tân, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04255",
        "parent_code": "132"
    },
    "04258": {
        "name": "Nguyễn Thái Học",
        "type": "phuong",
        "slug": "nguyen-thai-hoc",
        "name_with_type": "Phường Nguyễn Thái Học",
        "path": "Nguyễn Thái Học, Yên Bái, Yên Bái",
        "path_with_type": "Phường Nguyễn Thái Học, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04258",
        "parent_code": "132"
    },
    "04261": {
        "name": "Đồng Tâm",
        "type": "phuong",
        "slug": "dong-tam",
        "name_with_type": "Phường Đồng Tâm",
        "path": "Đồng Tâm, Yên Bái, Yên Bái",
        "path_with_type": "Phường Đồng Tâm, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04261",
        "parent_code": "132"
    },
    "04264": {
        "name": "Nguyễn Phúc",
        "type": "phuong",
        "slug": "nguyen-phuc",
        "name_with_type": "Phường Nguyễn Phúc",
        "path": "Nguyễn Phúc, Yên Bái, Yên Bái",
        "path_with_type": "Phường Nguyễn Phúc, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04264",
        "parent_code": "132"
    },
    "04267": {
        "name": "Hồng Hà",
        "type": "phuong",
        "slug": "hong-ha",
        "name_with_type": "Phường Hồng Hà",
        "path": "Hồng Hà, Yên Bái, Yên Bái",
        "path_with_type": "Phường Hồng Hà, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04267",
        "parent_code": "132"
    },
    "04270": {
        "name": "Minh Bảo",
        "type": "xa",
        "slug": "minh-bao",
        "name_with_type": "Xã Minh Bảo",
        "path": "Minh Bảo, Yên Bái, Yên Bái",
        "path_with_type": "Xã Minh Bảo, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04270",
        "parent_code": "132"
    },
    "04273": {
        "name": "Nam Cường",
        "type": "phuong",
        "slug": "nam-cuong",
        "name_with_type": "Phường Nam Cường",
        "path": "Nam Cường, Yên Bái, Yên Bái",
        "path_with_type": "Phường Nam Cường, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04273",
        "parent_code": "132"
    },
    "04276": {
        "name": "Tuy Lộc",
        "type": "xa",
        "slug": "tuy-loc",
        "name_with_type": "Xã Tuy Lộc",
        "path": "Tuy Lộc, Yên Bái, Yên Bái",
        "path_with_type": "Xã Tuy Lộc, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04276",
        "parent_code": "132"
    },
    "04279": {
        "name": "Tân Thịnh",
        "type": "xa",
        "slug": "tan-thinh",
        "name_with_type": "Xã Tân Thịnh",
        "path": "Tân Thịnh, Yên Bái, Yên Bái",
        "path_with_type": "Xã Tân Thịnh, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04279",
        "parent_code": "132"
    },
    "04540": {
        "name": "Âu Lâu",
        "type": "xa",
        "slug": "au-lau",
        "name_with_type": "Xã Âu Lâu",
        "path": "Âu Lâu, Yên Bái, Yên Bái",
        "path_with_type": "Xã Âu Lâu, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04540",
        "parent_code": "132"
    },
    "04543": {
        "name": "Giới Phiên",
        "type": "xa",
        "slug": "gioi-phien",
        "name_with_type": "Xã Giới Phiên",
        "path": "Giới Phiên, Yên Bái, Yên Bái",
        "path_with_type": "Xã Giới Phiên, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04543",
        "parent_code": "132"
    },
    "04546": {
        "name": "Hợp Minh",
        "type": "phuong",
        "slug": "hop-minh",
        "name_with_type": "Phường Hợp Minh",
        "path": "Hợp Minh, Yên Bái, Yên Bái",
        "path_with_type": "Phường Hợp Minh, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04546",
        "parent_code": "132"
    },
    "04549": {
        "name": "Văn Tiến",
        "type": "xa",
        "slug": "van-tien",
        "name_with_type": "Xã Văn Tiến",
        "path": "Văn Tiến, Yên Bái, Yên Bái",
        "path_with_type": "Xã Văn Tiến, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04549",
        "parent_code": "132"
    },
    "04552": {
        "name": "Phúc Lộc",
        "type": "xa",
        "slug": "phuc-loc",
        "name_with_type": "Xã Phúc Lộc",
        "path": "Phúc Lộc, Yên Bái, Yên Bái",
        "path_with_type": "Xã Phúc Lộc, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04552",
        "parent_code": "132"
    },
    "04558": {
        "name": "Văn Phú",
        "type": "xa",
        "slug": "van-phu",
        "name_with_type": "Xã Văn Phú",
        "path": "Văn Phú, Yên Bái, Yên Bái",
        "path_with_type": "Xã Văn Phú, Thành phố Yên Bái, Tỉnh Yên Bái",
        "code": "04558",
        "parent_code": "132"
    },
    "04282": {
        "name": "Pú Trạng",
        "type": "phuong",
        "slug": "pu-trang",
        "name_with_type": "Phường Pú Trạng",
        "path": "Pú Trạng, Nghĩa Lộ, Yên Bái",
        "path_with_type": "Phường Pú Trạng, Thị xã Nghĩa Lộ, Tỉnh Yên Bái",
        "code": "04282",
        "parent_code": "133"
    },
    "04285": {
        "name": "Trung Tâm",
        "type": "phuong",
        "slug": "trung-tam",
        "name_with_type": "Phường Trung Tâm",
        "path": "Trung Tâm, Nghĩa Lộ, Yên Bái",
        "path_with_type": "Phường Trung Tâm, Thị xã Nghĩa Lộ, Tỉnh Yên Bái",
        "code": "04285",
        "parent_code": "133"
    },
    "04288": {
        "name": "Tân An",
        "type": "phuong",
        "slug": "tan-an",
        "name_with_type": "Phường Tân An",
        "path": "Tân An, Nghĩa Lộ, Yên Bái",
        "path_with_type": "Phường Tân An, Thị xã Nghĩa Lộ, Tỉnh Yên Bái",
        "code": "04288",
        "parent_code": "133"
    },
    "04291": {
        "name": "Cầu Thia",
        "type": "phuong",
        "slug": "cau-thia",
        "name_with_type": "Phường Cầu Thia",
        "path": "Cầu Thia, Nghĩa Lộ, Yên Bái",
        "path_with_type": "Phường Cầu Thia, Thị xã Nghĩa Lộ, Tỉnh Yên Bái",
        "code": "04291",
        "parent_code": "133"
    },
    "04294": {
        "name": "Nghĩa Lợi",
        "type": "xa",
        "slug": "nghia-loi",
        "name_with_type": "Xã Nghĩa Lợi",
        "path": "Nghĩa Lợi, Nghĩa Lộ, Yên Bái",
        "path_with_type": "Xã Nghĩa Lợi, Thị xã Nghĩa Lộ, Tỉnh Yên Bái",
        "code": "04294",
        "parent_code": "133"
    },
    "04297": {
        "name": "Nghĩa Phúc",
        "type": "xa",
        "slug": "nghia-phuc",
        "name_with_type": "Xã Nghĩa Phúc",
        "path": "Nghĩa Phúc, Nghĩa Lộ, Yên Bái",
        "path_with_type": "Xã Nghĩa Phúc, Thị xã Nghĩa Lộ, Tỉnh Yên Bái",
        "code": "04297",
        "parent_code": "133"
    },
    "04300": {
        "name": "Nghĩa An",
        "type": "xa",
        "slug": "nghia-an",
        "name_with_type": "Xã Nghĩa An",
        "path": "Nghĩa An, Nghĩa Lộ, Yên Bái",
        "path_with_type": "Xã Nghĩa An, Thị xã Nghĩa Lộ, Tỉnh Yên Bái",
        "code": "04300",
        "parent_code": "133"
    },
    "04303": {
        "name": "Yên Thế",
        "type": "thi-tran",
        "slug": "yen-the",
        "name_with_type": "Thị trấn Yên Thế",
        "path": "Yên Thế, Lục Yên, Yên Bái",
        "path_with_type": "Thị trấn Yên Thế, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04303",
        "parent_code": "135"
    },
    "04306": {
        "name": "Tân Phượng",
        "type": "xa",
        "slug": "tan-phuong",
        "name_with_type": "Xã Tân Phượng",
        "path": "Tân Phượng, Lục Yên, Yên Bái",
        "path_with_type": "Xã Tân Phượng, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04306",
        "parent_code": "135"
    },
    "04309": {
        "name": "Lâm Thượng",
        "type": "xa",
        "slug": "lam-thuong",
        "name_with_type": "Xã Lâm Thượng",
        "path": "Lâm Thượng, Lục Yên, Yên Bái",
        "path_with_type": "Xã Lâm Thượng, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04309",
        "parent_code": "135"
    },
    "04312": {
        "name": "Khánh Thiện",
        "type": "xa",
        "slug": "khanh-thien",
        "name_with_type": "Xã Khánh Thiện",
        "path": "Khánh Thiện, Lục Yên, Yên Bái",
        "path_with_type": "Xã Khánh Thiện, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04312",
        "parent_code": "135"
    },
    "04315": {
        "name": "Minh Chuẩn",
        "type": "xa",
        "slug": "minh-chuan",
        "name_with_type": "Xã Minh Chuẩn",
        "path": "Minh Chuẩn, Lục Yên, Yên Bái",
        "path_with_type": "Xã Minh Chuẩn, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04315",
        "parent_code": "135"
    },
    "04318": {
        "name": "Mai Sơn",
        "type": "xa",
        "slug": "mai-son",
        "name_with_type": "Xã Mai Sơn",
        "path": "Mai Sơn, Lục Yên, Yên Bái",
        "path_with_type": "Xã Mai Sơn, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04318",
        "parent_code": "135"
    },
    "04321": {
        "name": "Khai Trung",
        "type": "xa",
        "slug": "khai-trung",
        "name_with_type": "Xã Khai Trung",
        "path": "Khai Trung, Lục Yên, Yên Bái",
        "path_with_type": "Xã Khai Trung, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04321",
        "parent_code": "135"
    },
    "04324": {
        "name": "Mường Lai",
        "type": "xa",
        "slug": "muong-lai",
        "name_with_type": "Xã Mường Lai",
        "path": "Mường Lai, Lục Yên, Yên Bái",
        "path_with_type": "Xã Mường Lai, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04324",
        "parent_code": "135"
    },
    "04327": {
        "name": "An Lạc",
        "type": "xa",
        "slug": "an-lac",
        "name_with_type": "Xã An Lạc",
        "path": "An Lạc, Lục Yên, Yên Bái",
        "path_with_type": "Xã An Lạc, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04327",
        "parent_code": "135"
    },
    "04330": {
        "name": "Minh Xuân",
        "type": "xa",
        "slug": "minh-xuan",
        "name_with_type": "Xã Minh Xuân",
        "path": "Minh Xuân, Lục Yên, Yên Bái",
        "path_with_type": "Xã Minh Xuân, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04330",
        "parent_code": "135"
    },
    "04333": {
        "name": "Tô Mậu",
        "type": "xa",
        "slug": "to-mau",
        "name_with_type": "Xã Tô Mậu",
        "path": "Tô Mậu, Lục Yên, Yên Bái",
        "path_with_type": "Xã Tô Mậu, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04333",
        "parent_code": "135"
    },
    "04336": {
        "name": "Tân Lĩnh",
        "type": "xa",
        "slug": "tan-linh",
        "name_with_type": "Xã Tân Lĩnh",
        "path": "Tân Lĩnh, Lục Yên, Yên Bái",
        "path_with_type": "Xã Tân Lĩnh, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04336",
        "parent_code": "135"
    },
    "04339": {
        "name": "Yên Thắng",
        "type": "xa",
        "slug": "yen-thang",
        "name_with_type": "Xã Yên Thắng",
        "path": "Yên Thắng, Lục Yên, Yên Bái",
        "path_with_type": "Xã Yên Thắng, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04339",
        "parent_code": "135"
    },
    "04342": {
        "name": "Khánh Hoà",
        "type": "xa",
        "slug": "khanh-hoa",
        "name_with_type": "Xã Khánh Hoà",
        "path": "Khánh Hoà, Lục Yên, Yên Bái",
        "path_with_type": "Xã Khánh Hoà, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04342",
        "parent_code": "135"
    },
    "04345": {
        "name": "Vĩnh Lạc",
        "type": "xa",
        "slug": "vinh-lac",
        "name_with_type": "Xã Vĩnh Lạc",
        "path": "Vĩnh Lạc, Lục Yên, Yên Bái",
        "path_with_type": "Xã Vĩnh Lạc, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04345",
        "parent_code": "135"
    },
    "04348": {
        "name": "Liễu Đô",
        "type": "xa",
        "slug": "lieu-do",
        "name_with_type": "Xã Liễu Đô",
        "path": "Liễu Đô, Lục Yên, Yên Bái",
        "path_with_type": "Xã Liễu Đô, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04348",
        "parent_code": "135"
    },
    "04351": {
        "name": "Động Quan",
        "type": "xa",
        "slug": "dong-quan",
        "name_with_type": "Xã Động Quan",
        "path": "Động Quan, Lục Yên, Yên Bái",
        "path_with_type": "Xã Động Quan, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04351",
        "parent_code": "135"
    },
    "04354": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Lục Yên, Yên Bái",
        "path_with_type": "Xã Tân Lập, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04354",
        "parent_code": "135"
    },
    "04357": {
        "name": "Minh Tiến",
        "type": "xa",
        "slug": "minh-tien",
        "name_with_type": "Xã Minh Tiến",
        "path": "Minh Tiến, Lục Yên, Yên Bái",
        "path_with_type": "Xã Minh Tiến, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04357",
        "parent_code": "135"
    },
    "04360": {
        "name": "Trúc Lâu",
        "type": "xa",
        "slug": "truc-lau",
        "name_with_type": "Xã Trúc Lâu",
        "path": "Trúc Lâu, Lục Yên, Yên Bái",
        "path_with_type": "Xã Trúc Lâu, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04360",
        "parent_code": "135"
    },
    "04363": {
        "name": "Phúc Lợi",
        "type": "xa",
        "slug": "phuc-loi",
        "name_with_type": "Xã Phúc Lợi",
        "path": "Phúc Lợi, Lục Yên, Yên Bái",
        "path_with_type": "Xã Phúc Lợi, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04363",
        "parent_code": "135"
    },
    "04366": {
        "name": "Phan Thanh",
        "type": "xa",
        "slug": "phan-thanh",
        "name_with_type": "Xã Phan Thanh",
        "path": "Phan Thanh, Lục Yên, Yên Bái",
        "path_with_type": "Xã Phan Thanh, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04366",
        "parent_code": "135"
    },
    "04369": {
        "name": "An Phú",
        "type": "xa",
        "slug": "an-phu",
        "name_with_type": "Xã An Phú",
        "path": "An Phú, Lục Yên, Yên Bái",
        "path_with_type": "Xã An Phú, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04369",
        "parent_code": "135"
    },
    "04372": {
        "name": "Trung Tâm",
        "type": "xa",
        "slug": "trung-tam",
        "name_with_type": "Xã Trung Tâm",
        "path": "Trung Tâm, Lục Yên, Yên Bái",
        "path_with_type": "Xã Trung Tâm, Huyện Lục Yên, Tỉnh Yên Bái",
        "code": "04372",
        "parent_code": "135"
    },
    "04375": {
        "name": "Mậu A",
        "type": "thi-tran",
        "slug": "mau-a",
        "name_with_type": "Thị trấn Mậu A",
        "path": "Mậu A, Văn Yên, Yên Bái",
        "path_with_type": "Thị trấn Mậu A, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04375",
        "parent_code": "136"
    },
    "04378": {
        "name": "Lang Thíp",
        "type": "xa",
        "slug": "lang-thip",
        "name_with_type": "Xã Lang Thíp",
        "path": "Lang Thíp, Văn Yên, Yên Bái",
        "path_with_type": "Xã Lang Thíp, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04378",
        "parent_code": "136"
    },
    "04381": {
        "name": "Lâm Giang",
        "type": "xa",
        "slug": "lam-giang",
        "name_with_type": "Xã Lâm Giang",
        "path": "Lâm Giang, Văn Yên, Yên Bái",
        "path_with_type": "Xã Lâm Giang, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04381",
        "parent_code": "136"
    },
    "04384": {
        "name": "Châu Quế Thượng",
        "type": "xa",
        "slug": "chau-que-thuong",
        "name_with_type": "Xã Châu Quế Thượng",
        "path": "Châu Quế Thượng, Văn Yên, Yên Bái",
        "path_with_type": "Xã Châu Quế Thượng, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04384",
        "parent_code": "136"
    },
    "04387": {
        "name": "Châu Quế Hạ",
        "type": "xa",
        "slug": "chau-que-ha",
        "name_with_type": "Xã Châu Quế Hạ",
        "path": "Châu Quế Hạ, Văn Yên, Yên Bái",
        "path_with_type": "Xã Châu Quế Hạ, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04387",
        "parent_code": "136"
    },
    "04390": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Văn Yên, Yên Bái",
        "path_with_type": "Xã An Bình, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04390",
        "parent_code": "136"
    },
    "04393": {
        "name": "Quang Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quang Minh",
        "path": "Quang Minh, Văn Yên, Yên Bái",
        "path_with_type": "Xã Quang Minh, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04393",
        "parent_code": "136"
    },
    "04396": {
        "name": "Đông An",
        "type": "xa",
        "slug": "dong-an",
        "name_with_type": "Xã Đông An",
        "path": "Đông An, Văn Yên, Yên Bái",
        "path_with_type": "Xã Đông An, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04396",
        "parent_code": "136"
    },
    "04399": {
        "name": "Đông Cuông",
        "type": "xa",
        "slug": "dong-cuong",
        "name_with_type": "Xã Đông Cuông",
        "path": "Đông Cuông, Văn Yên, Yên Bái",
        "path_with_type": "Xã Đông Cuông, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04399",
        "parent_code": "136"
    },
    "04402": {
        "name": "Phong Dụ Hạ",
        "type": "xa",
        "slug": "phong-du-ha",
        "name_with_type": "Xã Phong Dụ Hạ",
        "path": "Phong Dụ Hạ, Văn Yên, Yên Bái",
        "path_with_type": "Xã Phong Dụ Hạ, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04402",
        "parent_code": "136"
    },
    "04405": {
        "name": "Mậu Đông",
        "type": "xa",
        "slug": "mau-dong",
        "name_with_type": "Xã Mậu Đông",
        "path": "Mậu Đông, Văn Yên, Yên Bái",
        "path_with_type": "Xã Mậu Đông, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04405",
        "parent_code": "136"
    },
    "04408": {
        "name": "Ngòi A",
        "type": "xa",
        "slug": "ngoi-a",
        "name_with_type": "Xã Ngòi A",
        "path": "Ngòi A, Văn Yên, Yên Bái",
        "path_with_type": "Xã Ngòi A, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04408",
        "parent_code": "136"
    },
    "04411": {
        "name": "Xuân Tầm",
        "type": "xa",
        "slug": "xuan-tam",
        "name_with_type": "Xã Xuân Tầm",
        "path": "Xuân Tầm, Văn Yên, Yên Bái",
        "path_with_type": "Xã Xuân Tầm, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04411",
        "parent_code": "136"
    },
    "04414": {
        "name": "Tân Hợp",
        "type": "xa",
        "slug": "tan-hop",
        "name_with_type": "Xã Tân Hợp",
        "path": "Tân Hợp, Văn Yên, Yên Bái",
        "path_with_type": "Xã Tân Hợp, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04414",
        "parent_code": "136"
    },
    "04417": {
        "name": "An Thịnh",
        "type": "xa",
        "slug": "an-thinh",
        "name_with_type": "Xã An Thịnh",
        "path": "An Thịnh, Văn Yên, Yên Bái",
        "path_with_type": "Xã An Thịnh, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04417",
        "parent_code": "136"
    },
    "04420": {
        "name": "Yên Thái",
        "type": "xa",
        "slug": "yen-thai",
        "name_with_type": "Xã Yên Thái",
        "path": "Yên Thái, Văn Yên, Yên Bái",
        "path_with_type": "Xã Yên Thái, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04420",
        "parent_code": "136"
    },
    "04423": {
        "name": "Phong Dụ Thượng",
        "type": "xa",
        "slug": "phong-du-thuong",
        "name_with_type": "Xã Phong Dụ Thượng",
        "path": "Phong Dụ Thượng, Văn Yên, Yên Bái",
        "path_with_type": "Xã Phong Dụ Thượng, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04423",
        "parent_code": "136"
    },
    "04426": {
        "name": "Yên Hợp",
        "type": "xa",
        "slug": "yen-hop",
        "name_with_type": "Xã Yên Hợp",
        "path": "Yên Hợp, Văn Yên, Yên Bái",
        "path_with_type": "Xã Yên Hợp, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04426",
        "parent_code": "136"
    },
    "04429": {
        "name": "Đại Sơn",
        "type": "xa",
        "slug": "dai-son",
        "name_with_type": "Xã Đại Sơn",
        "path": "Đại Sơn, Văn Yên, Yên Bái",
        "path_with_type": "Xã Đại Sơn, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04429",
        "parent_code": "136"
    },
    "04432": {
        "name": "Yên Hưng",
        "type": "xa",
        "slug": "yen-hung",
        "name_with_type": "Xã Yên Hưng",
        "path": "Yên Hưng, Văn Yên, Yên Bái",
        "path_with_type": "Xã Yên Hưng, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04432",
        "parent_code": "136"
    },
    "04435": {
        "name": "Đại Phác",
        "type": "xa",
        "slug": "dai-phac",
        "name_with_type": "Xã Đại Phác",
        "path": "Đại Phác, Văn Yên, Yên Bái",
        "path_with_type": "Xã Đại Phác, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04435",
        "parent_code": "136"
    },
    "04438": {
        "name": "Yên Phú",
        "type": "xa",
        "slug": "yen-phu",
        "name_with_type": "Xã Yên Phú",
        "path": "Yên Phú, Văn Yên, Yên Bái",
        "path_with_type": "Xã Yên Phú, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04438",
        "parent_code": "136"
    },
    "04441": {
        "name": "Xuân Ái",
        "type": "xa",
        "slug": "xuan-ai",
        "name_with_type": "Xã Xuân Ái",
        "path": "Xuân Ái, Văn Yên, Yên Bái",
        "path_with_type": "Xã Xuân Ái, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04441",
        "parent_code": "136"
    },
    "04444": {
        "name": "Hoàng Thắng",
        "type": "xa",
        "slug": "hoang-thang",
        "name_with_type": "Xã Hoàng Thắng",
        "path": "Hoàng Thắng, Văn Yên, Yên Bái",
        "path_with_type": "Xã Hoàng Thắng, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04444",
        "parent_code": "136"
    },
    "04447": {
        "name": "Viễn Sơn",
        "type": "xa",
        "slug": "vien-son",
        "name_with_type": "Xã Viễn Sơn",
        "path": "Viễn Sơn, Văn Yên, Yên Bái",
        "path_with_type": "Xã Viễn Sơn, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04447",
        "parent_code": "136"
    },
    "04450": {
        "name": "Mỏ Vàng",
        "type": "xa",
        "slug": "mo-vang",
        "name_with_type": "Xã Mỏ Vàng",
        "path": "Mỏ Vàng, Văn Yên, Yên Bái",
        "path_with_type": "Xã Mỏ Vàng, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04450",
        "parent_code": "136"
    },
    "04453": {
        "name": "Nà Hẩu",
        "type": "xa",
        "slug": "na-hau",
        "name_with_type": "Xã Nà Hẩu",
        "path": "Nà Hẩu, Văn Yên, Yên Bái",
        "path_with_type": "Xã Nà Hẩu, Huyện Văn Yên, Tỉnh Yên Bái",
        "code": "04453",
        "parent_code": "136"
    },
    "04456": {
        "name": "Mù Căng Chải",
        "type": "thi-tran",
        "slug": "mu-cang-chai",
        "name_with_type": "Thị trấn Mù Căng Chải",
        "path": "Mù Căng Chải, Mù Căng Chải, Yên Bái",
        "path_with_type": "Thị trấn Mù Căng Chải, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04456",
        "parent_code": "137"
    },
    "04459": {
        "name": "Hồ Bốn",
        "type": "xa",
        "slug": "ho-bon",
        "name_with_type": "Xã Hồ Bốn",
        "path": "Hồ Bốn, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Hồ Bốn, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04459",
        "parent_code": "137"
    },
    "04462": {
        "name": "Nậm Có",
        "type": "xa",
        "slug": "nam-co",
        "name_with_type": "Xã Nậm Có",
        "path": "Nậm Có, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Nậm Có, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04462",
        "parent_code": "137"
    },
    "04465": {
        "name": "Khao Mang",
        "type": "xa",
        "slug": "khao-mang",
        "name_with_type": "Xã Khao Mang",
        "path": "Khao Mang, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Khao Mang, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04465",
        "parent_code": "137"
    },
    "04468": {
        "name": "Mồ Dề",
        "type": "xa",
        "slug": "mo-de",
        "name_with_type": "Xã Mồ Dề",
        "path": "Mồ Dề, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Mồ Dề, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04468",
        "parent_code": "137"
    },
    "04471": {
        "name": "Chế Cu Nha",
        "type": "xa",
        "slug": "che-cu-nha",
        "name_with_type": "Xã Chế Cu Nha",
        "path": "Chế Cu Nha, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Chế Cu Nha, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04471",
        "parent_code": "137"
    },
    "04474": {
        "name": "Lao Chải",
        "type": "xa",
        "slug": "lao-chai",
        "name_with_type": "Xã Lao Chải",
        "path": "Lao Chải, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Lao Chải, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04474",
        "parent_code": "137"
    },
    "04477": {
        "name": "Kim Nọi",
        "type": "xa",
        "slug": "kim-noi",
        "name_with_type": "Xã Kim Nọi",
        "path": "Kim Nọi, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Kim Nọi, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04477",
        "parent_code": "137"
    },
    "04480": {
        "name": "Cao Phạ",
        "type": "xa",
        "slug": "cao-pha",
        "name_with_type": "Xã Cao Phạ",
        "path": "Cao Phạ, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Cao Phạ, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04480",
        "parent_code": "137"
    },
    "04483": {
        "name": "La Pán Tẩn",
        "type": "xa",
        "slug": "la-pan-tan",
        "name_with_type": "Xã La Pán Tẩn",
        "path": "La Pán Tẩn, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã La Pán Tẩn, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04483",
        "parent_code": "137"
    },
    "04486": {
        "name": "Dế Su Phình",
        "type": "xa",
        "slug": "de-su-phinh",
        "name_with_type": "Xã Dế Su Phình",
        "path": "Dế Su Phình, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Dế Su Phình, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04486",
        "parent_code": "137"
    },
    "04489": {
        "name": "Chế Tạo",
        "type": "xa",
        "slug": "che-tao",
        "name_with_type": "Xã Chế Tạo",
        "path": "Chế Tạo, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Chế Tạo, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04489",
        "parent_code": "137"
    },
    "04492": {
        "name": "Púng Luông",
        "type": "xa",
        "slug": "pung-luong",
        "name_with_type": "Xã Púng Luông",
        "path": "Púng Luông, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Púng Luông, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04492",
        "parent_code": "137"
    },
    "04495": {
        "name": "Nậm Khắt",
        "type": "xa",
        "slug": "nam-khat",
        "name_with_type": "Xã Nậm Khắt",
        "path": "Nậm Khắt, Mù Căng Chải, Yên Bái",
        "path_with_type": "Xã Nậm Khắt, Huyện Mù Căng Chải, Tỉnh Yên Bái",
        "code": "04495",
        "parent_code": "137"
    },
    "04498": {
        "name": "Cổ Phúc",
        "type": "thi-tran",
        "slug": "co-phuc",
        "name_with_type": "Thị trấn Cổ Phúc",
        "path": "Cổ Phúc, Trấn Yên, Yên Bái",
        "path_with_type": "Thị trấn Cổ Phúc, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04498",
        "parent_code": "138"
    },
    "04501": {
        "name": "Tân Đồng",
        "type": "xa",
        "slug": "tan-dong",
        "name_with_type": "Xã Tân Đồng",
        "path": "Tân Đồng, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Tân Đồng, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04501",
        "parent_code": "138"
    },
    "04504": {
        "name": "Báo Đáp",
        "type": "xa",
        "slug": "bao-dap",
        "name_with_type": "Xã Báo Đáp",
        "path": "Báo Đáp, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Báo Đáp, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04504",
        "parent_code": "138"
    },
    "04507": {
        "name": "Đào Thịnh",
        "type": "xa",
        "slug": "dao-thinh",
        "name_with_type": "Xã Đào Thịnh",
        "path": "Đào Thịnh, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Đào Thịnh, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04507",
        "parent_code": "138"
    },
    "04510": {
        "name": "Việt Thành",
        "type": "xa",
        "slug": "viet-thanh",
        "name_with_type": "Xã Việt Thành",
        "path": "Việt Thành, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Việt Thành, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04510",
        "parent_code": "138"
    },
    "04513": {
        "name": "Hòa Cuông",
        "type": "xa",
        "slug": "hoa-cuong",
        "name_with_type": "Xã Hòa Cuông",
        "path": "Hòa Cuông, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Hòa Cuông, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04513",
        "parent_code": "138"
    },
    "04516": {
        "name": "Minh Quán",
        "type": "xa",
        "slug": "minh-quan",
        "name_with_type": "Xã Minh Quán",
        "path": "Minh Quán, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Minh Quán, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04516",
        "parent_code": "138"
    },
    "04519": {
        "name": "Quy Mông",
        "type": "xa",
        "slug": "quy-mong",
        "name_with_type": "Xã Quy Mông",
        "path": "Quy Mông, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Quy Mông, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04519",
        "parent_code": "138"
    },
    "04522": {
        "name": "Cường Thịnh",
        "type": "xa",
        "slug": "cuong-thinh",
        "name_with_type": "Xã Cường Thịnh",
        "path": "Cường Thịnh, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Cường Thịnh, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04522",
        "parent_code": "138"
    },
    "04525": {
        "name": "Kiên Thành",
        "type": "xa",
        "slug": "kien-thanh",
        "name_with_type": "Xã Kiên Thành",
        "path": "Kiên Thành, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Kiên Thành, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04525",
        "parent_code": "138"
    },
    "04528": {
        "name": "Nga Quán",
        "type": "xa",
        "slug": "nga-quan",
        "name_with_type": "Xã Nga Quán",
        "path": "Nga Quán, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Nga Quán, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04528",
        "parent_code": "138"
    },
    "04531": {
        "name": "Y Can",
        "type": "xa",
        "slug": "y-can",
        "name_with_type": "Xã Y Can",
        "path": "Y Can, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Y Can, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04531",
        "parent_code": "138"
    },
    "04534": {
        "name": "Minh Tiến",
        "type": "xa",
        "slug": "minh-tien",
        "name_with_type": "Xã Minh Tiến",
        "path": "Minh Tiến, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Minh Tiến, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04534",
        "parent_code": "138"
    },
    "04537": {
        "name": "Lương Thịnh",
        "type": "xa",
        "slug": "luong-thinh",
        "name_with_type": "Xã Lương Thịnh",
        "path": "Lương Thịnh, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Lương Thịnh, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04537",
        "parent_code": "138"
    },
    "04561": {
        "name": "Bảo Hưng",
        "type": "xa",
        "slug": "bao-hung",
        "name_with_type": "Xã Bảo Hưng",
        "path": "Bảo Hưng, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Bảo Hưng, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04561",
        "parent_code": "138"
    },
    "04564": {
        "name": "Việt Cường",
        "type": "xa",
        "slug": "viet-cuong",
        "name_with_type": "Xã Việt Cường",
        "path": "Việt Cường, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Việt Cường, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04564",
        "parent_code": "138"
    },
    "04567": {
        "name": "Minh Quân",
        "type": "xa",
        "slug": "minh-quan",
        "name_with_type": "Xã Minh Quân",
        "path": "Minh Quân, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Minh Quân, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04567",
        "parent_code": "138"
    },
    "04570": {
        "name": "Hồng Ca",
        "type": "xa",
        "slug": "hong-ca",
        "name_with_type": "Xã Hồng Ca",
        "path": "Hồng Ca, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Hồng Ca, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04570",
        "parent_code": "138"
    },
    "04573": {
        "name": "Hưng Thịnh",
        "type": "xa",
        "slug": "hung-thinh",
        "name_with_type": "Xã Hưng Thịnh",
        "path": "Hưng Thịnh, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Hưng Thịnh, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04573",
        "parent_code": "138"
    },
    "04576": {
        "name": "Hưng Khánh",
        "type": "xa",
        "slug": "hung-khanh",
        "name_with_type": "Xã Hưng Khánh",
        "path": "Hưng Khánh, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Hưng Khánh, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04576",
        "parent_code": "138"
    },
    "04579": {
        "name": "Việt Hồng",
        "type": "xa",
        "slug": "viet-hong",
        "name_with_type": "Xã Việt Hồng",
        "path": "Việt Hồng, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Việt Hồng, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04579",
        "parent_code": "138"
    },
    "04582": {
        "name": "Vân Hội",
        "type": "xa",
        "slug": "van-hoi",
        "name_with_type": "Xã Vân Hội",
        "path": "Vân Hội, Trấn Yên, Yên Bái",
        "path_with_type": "Xã Vân Hội, Huyện Trấn Yên, Tỉnh Yên Bái",
        "code": "04582",
        "parent_code": "138"
    },
    "04585": {
        "name": "Trạm Tấu",
        "type": "thi-tran",
        "slug": "tram-tau",
        "name_with_type": "Thị trấn Trạm Tấu",
        "path": "Trạm Tấu, Trạm Tấu, Yên Bái",
        "path_with_type": "Thị trấn Trạm Tấu, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04585",
        "parent_code": "139"
    },
    "04588": {
        "name": "Túc Đán",
        "type": "xa",
        "slug": "tuc-dan",
        "name_with_type": "Xã Túc Đán",
        "path": "Túc Đán, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Túc Đán, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04588",
        "parent_code": "139"
    },
    "04591": {
        "name": "Pá Lau",
        "type": "xa",
        "slug": "pa-lau",
        "name_with_type": "Xã Pá Lau",
        "path": "Pá Lau, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Pá Lau, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04591",
        "parent_code": "139"
    },
    "04594": {
        "name": "Xà Hồ",
        "type": "xa",
        "slug": "xa-ho",
        "name_with_type": "Xã Xà Hồ",
        "path": "Xà Hồ, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Xà Hồ, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04594",
        "parent_code": "139"
    },
    "04597": {
        "name": "Phình Hồ",
        "type": "xa",
        "slug": "phinh-ho",
        "name_with_type": "Xã Phình Hồ",
        "path": "Phình Hồ, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Phình Hồ, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04597",
        "parent_code": "139"
    },
    "04600": {
        "name": "Trạm Tấu",
        "type": "xa",
        "slug": "tram-tau",
        "name_with_type": "Xã Trạm Tấu",
        "path": "Trạm Tấu, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Trạm Tấu, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04600",
        "parent_code": "139"
    },
    "04603": {
        "name": "Tà Si Láng",
        "type": "xa",
        "slug": "ta-si-lang",
        "name_with_type": "Xã Tà Si Láng",
        "path": "Tà Si Láng, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Tà Si Láng, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04603",
        "parent_code": "139"
    },
    "04606": {
        "name": "Pá Hu",
        "type": "xa",
        "slug": "pa-hu",
        "name_with_type": "Xã Pá Hu",
        "path": "Pá Hu, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Pá Hu, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04606",
        "parent_code": "139"
    },
    "04609": {
        "name": "Làng Nhì",
        "type": "xa",
        "slug": "lang-nhi",
        "name_with_type": "Xã Làng Nhì",
        "path": "Làng Nhì, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Làng Nhì, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04609",
        "parent_code": "139"
    },
    "04612": {
        "name": "Bản Công",
        "type": "xa",
        "slug": "ban-cong",
        "name_with_type": "Xã Bản Công",
        "path": "Bản Công, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Bản Công, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04612",
        "parent_code": "139"
    },
    "04615": {
        "name": "Bản Mù",
        "type": "xa",
        "slug": "ban-mu",
        "name_with_type": "Xã Bản Mù",
        "path": "Bản Mù, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Bản Mù, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04615",
        "parent_code": "139"
    },
    "04618": {
        "name": "Hát Lìu",
        "type": "xa",
        "slug": "hat-liu",
        "name_with_type": "Xã Hát Lìu",
        "path": "Hát Lìu, Trạm Tấu, Yên Bái",
        "path_with_type": "Xã Hát Lìu, Huyện Trạm Tấu, Tỉnh Yên Bái",
        "code": "04618",
        "parent_code": "139"
    },
    "04621": {
        "name": "NT Liên Sơn",
        "type": "thi-tran",
        "slug": "nt-lien-son",
        "name_with_type": "Thị trấn NT Liên Sơn",
        "path": "NT Liên Sơn, Văn Chấn, Yên Bái",
        "path_with_type": "Thị trấn NT Liên Sơn, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04621",
        "parent_code": "140"
    },
    "04624": {
        "name": "NT Nghĩa Lộ",
        "type": "thi-tran",
        "slug": "nt-nghia-lo",
        "name_with_type": "Thị trấn NT Nghĩa Lộ",
        "path": "NT Nghĩa Lộ, Văn Chấn, Yên Bái",
        "path_with_type": "Thị trấn NT Nghĩa Lộ, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04624",
        "parent_code": "140"
    },
    "04627": {
        "name": "NT Trần Phú",
        "type": "thi-tran",
        "slug": "nt-tran-phu",
        "name_with_type": "Thị trấn NT Trần Phú",
        "path": "NT Trần Phú, Văn Chấn, Yên Bái",
        "path_with_type": "Thị trấn NT Trần Phú, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04627",
        "parent_code": "140"
    },
    "04630": {
        "name": "Tú Lệ",
        "type": "xa",
        "slug": "tu-le",
        "name_with_type": "Xã Tú Lệ",
        "path": "Tú Lệ, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Tú Lệ, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04630",
        "parent_code": "140"
    },
    "04633": {
        "name": "Nậm Búng",
        "type": "xa",
        "slug": "nam-bung",
        "name_with_type": "Xã Nậm Búng",
        "path": "Nậm Búng, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Nậm Búng, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04633",
        "parent_code": "140"
    },
    "04636": {
        "name": "Gia Hội",
        "type": "xa",
        "slug": "gia-hoi",
        "name_with_type": "Xã Gia Hội",
        "path": "Gia Hội, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Gia Hội, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04636",
        "parent_code": "140"
    },
    "04639": {
        "name": "Sùng Đô",
        "type": "xa",
        "slug": "sung-do",
        "name_with_type": "Xã Sùng Đô",
        "path": "Sùng Đô, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Sùng Đô, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04639",
        "parent_code": "140"
    },
    "04642": {
        "name": "Nậm Mười",
        "type": "xa",
        "slug": "nam-muoi",
        "name_with_type": "Xã Nậm Mười",
        "path": "Nậm Mười, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Nậm Mười, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04642",
        "parent_code": "140"
    },
    "04645": {
        "name": "An Lương",
        "type": "xa",
        "slug": "an-luong",
        "name_with_type": "Xã An Lương",
        "path": "An Lương, Văn Chấn, Yên Bái",
        "path_with_type": "Xã An Lương, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04645",
        "parent_code": "140"
    },
    "04648": {
        "name": "Nậm Lành",
        "type": "xa",
        "slug": "nam-lanh",
        "name_with_type": "Xã Nậm Lành",
        "path": "Nậm Lành, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Nậm Lành, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04648",
        "parent_code": "140"
    },
    "04651": {
        "name": "Sơn Lương",
        "type": "xa",
        "slug": "son-luong",
        "name_with_type": "Xã Sơn Lương",
        "path": "Sơn Lương, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Sơn Lương, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04651",
        "parent_code": "140"
    },
    "04654": {
        "name": "Suối Quyền",
        "type": "xa",
        "slug": "suoi-quyen",
        "name_with_type": "Xã Suối Quyền",
        "path": "Suối Quyền, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Suối Quyền, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04654",
        "parent_code": "140"
    },
    "04657": {
        "name": "Suối Giàng",
        "type": "xa",
        "slug": "suoi-giang",
        "name_with_type": "Xã Suối Giàng",
        "path": "Suối Giàng, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Suối Giàng, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04657",
        "parent_code": "140"
    },
    "04660": {
        "name": "Sơn A",
        "type": "xa",
        "slug": "son-a",
        "name_with_type": "Xã Sơn A",
        "path": "Sơn A, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Sơn A, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04660",
        "parent_code": "140"
    },
    "04663": {
        "name": "Phù Nham",
        "type": "xa",
        "slug": "phu-nham",
        "name_with_type": "Xã Phù Nham",
        "path": "Phù Nham, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Phù Nham, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04663",
        "parent_code": "140"
    },
    "04666": {
        "name": "Nghĩa Sơn",
        "type": "xa",
        "slug": "nghia-son",
        "name_with_type": "Xã Nghĩa Sơn",
        "path": "Nghĩa Sơn, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Nghĩa Sơn, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04666",
        "parent_code": "140"
    },
    "04669": {
        "name": "Suối Bu",
        "type": "xa",
        "slug": "suoi-bu",
        "name_with_type": "Xã Suối Bu",
        "path": "Suối Bu, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Suối Bu, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04669",
        "parent_code": "140"
    },
    "04672": {
        "name": "Sơn Thịnh",
        "type": "xa",
        "slug": "son-thinh",
        "name_with_type": "Xã Sơn Thịnh",
        "path": "Sơn Thịnh, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Sơn Thịnh, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04672",
        "parent_code": "140"
    },
    "04675": {
        "name": "Thanh Lương",
        "type": "xa",
        "slug": "thanh-luong",
        "name_with_type": "Xã Thanh Lương",
        "path": "Thanh Lương, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Thanh Lương, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04675",
        "parent_code": "140"
    },
    "04678": {
        "name": "Hạnh Sơn",
        "type": "xa",
        "slug": "hanh-son",
        "name_with_type": "Xã Hạnh Sơn",
        "path": "Hạnh Sơn, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Hạnh Sơn, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04678",
        "parent_code": "140"
    },
    "04681": {
        "name": "Phúc Sơn",
        "type": "xa",
        "slug": "phuc-son",
        "name_with_type": "Xã Phúc Sơn",
        "path": "Phúc Sơn, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Phúc Sơn, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04681",
        "parent_code": "140"
    },
    "04684": {
        "name": "Thạch Lương",
        "type": "xa",
        "slug": "thach-luong",
        "name_with_type": "Xã Thạch Lương",
        "path": "Thạch Lương, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Thạch Lương, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04684",
        "parent_code": "140"
    },
    "04687": {
        "name": "Đại Lịch",
        "type": "xa",
        "slug": "dai-lich",
        "name_with_type": "Xã Đại Lịch",
        "path": "Đại Lịch, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Đại Lịch, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04687",
        "parent_code": "140"
    },
    "04690": {
        "name": "Đồng Khê",
        "type": "xa",
        "slug": "dong-khe",
        "name_with_type": "Xã Đồng Khê",
        "path": "Đồng Khê, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Đồng Khê, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04690",
        "parent_code": "140"
    },
    "04693": {
        "name": "Cát Thịnh",
        "type": "xa",
        "slug": "cat-thinh",
        "name_with_type": "Xã Cát Thịnh",
        "path": "Cát Thịnh, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Cát Thịnh, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04693",
        "parent_code": "140"
    },
    "04696": {
        "name": "Tân Thịnh",
        "type": "xa",
        "slug": "tan-thinh",
        "name_with_type": "Xã Tân Thịnh",
        "path": "Tân Thịnh, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Tân Thịnh, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04696",
        "parent_code": "140"
    },
    "04699": {
        "name": "Chấn Thịnh",
        "type": "xa",
        "slug": "chan-thinh",
        "name_with_type": "Xã Chấn Thịnh",
        "path": "Chấn Thịnh, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Chấn Thịnh, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04699",
        "parent_code": "140"
    },
    "04702": {
        "name": "Bình Thuận",
        "type": "xa",
        "slug": "binh-thuan",
        "name_with_type": "Xã Bình Thuận",
        "path": "Bình Thuận, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Bình Thuận, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04702",
        "parent_code": "140"
    },
    "04705": {
        "name": "Thượng Bằng La",
        "type": "xa",
        "slug": "thuong-bang-la",
        "name_with_type": "Xã Thượng Bằng La",
        "path": "Thượng Bằng La, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Thượng Bằng La, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04705",
        "parent_code": "140"
    },
    "04708": {
        "name": "Minh An",
        "type": "xa",
        "slug": "minh-an",
        "name_with_type": "Xã Minh An",
        "path": "Minh An, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Minh An, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04708",
        "parent_code": "140"
    },
    "04711": {
        "name": "Nghĩa Tâm",
        "type": "xa",
        "slug": "nghia-tam",
        "name_with_type": "Xã Nghĩa Tâm",
        "path": "Nghĩa Tâm, Văn Chấn, Yên Bái",
        "path_with_type": "Xã Nghĩa Tâm, Huyện Văn Chấn, Tỉnh Yên Bái",
        "code": "04711",
        "parent_code": "140"
    },
    "04555": {
        "name": "Văn Lãng",
        "type": "xa",
        "slug": "van-lang",
        "name_with_type": "Xã Văn Lãng",
        "path": "Văn Lãng, Yên Bình, Yên Bái",
        "path_with_type": "Xã Văn Lãng, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04555",
        "parent_code": "141"
    },
    "04714": {
        "name": "Yên Bình",
        "type": "thi-tran",
        "slug": "yen-binh",
        "name_with_type": "Thị trấn Yên Bình",
        "path": "Yên Bình, Yên Bình, Yên Bái",
        "path_with_type": "Thị trấn Yên Bình, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04714",
        "parent_code": "141"
    },
    "04717": {
        "name": "Thác Bà",
        "type": "thi-tran",
        "slug": "thac-ba",
        "name_with_type": "Thị trấn Thác Bà",
        "path": "Thác Bà, Yên Bình, Yên Bái",
        "path_with_type": "Thị trấn Thác Bà, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04717",
        "parent_code": "141"
    },
    "04720": {
        "name": "Xuân Long",
        "type": "xa",
        "slug": "xuan-long",
        "name_with_type": "Xã Xuân Long",
        "path": "Xuân Long, Yên Bình, Yên Bái",
        "path_with_type": "Xã Xuân Long, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04720",
        "parent_code": "141"
    },
    "04723": {
        "name": "Tích Cốc",
        "type": "xa",
        "slug": "tich-coc",
        "name_with_type": "Xã Tích Cốc",
        "path": "Tích Cốc, Yên Bình, Yên Bái",
        "path_with_type": "Xã Tích Cốc, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04723",
        "parent_code": "141"
    },
    "04726": {
        "name": "Cảm Nhân",
        "type": "xa",
        "slug": "cam-nhan",
        "name_with_type": "Xã Cảm Nhân",
        "path": "Cảm Nhân, Yên Bình, Yên Bái",
        "path_with_type": "Xã Cảm Nhân, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04726",
        "parent_code": "141"
    },
    "04729": {
        "name": "Ngọc Chấn",
        "type": "xa",
        "slug": "ngoc-chan",
        "name_with_type": "Xã Ngọc Chấn",
        "path": "Ngọc Chấn, Yên Bình, Yên Bái",
        "path_with_type": "Xã Ngọc Chấn, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04729",
        "parent_code": "141"
    },
    "04732": {
        "name": "Tân Nguyên",
        "type": "xa",
        "slug": "tan-nguyen",
        "name_with_type": "Xã Tân Nguyên",
        "path": "Tân Nguyên, Yên Bình, Yên Bái",
        "path_with_type": "Xã Tân Nguyên, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04732",
        "parent_code": "141"
    },
    "04735": {
        "name": "Phúc Ninh",
        "type": "xa",
        "slug": "phuc-ninh",
        "name_with_type": "Xã Phúc Ninh",
        "path": "Phúc Ninh, Yên Bình, Yên Bái",
        "path_with_type": "Xã Phúc Ninh, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04735",
        "parent_code": "141"
    },
    "04738": {
        "name": "Bảo Ái",
        "type": "xa",
        "slug": "bao-ai",
        "name_with_type": "Xã Bảo Ái",
        "path": "Bảo Ái, Yên Bình, Yên Bái",
        "path_with_type": "Xã Bảo Ái, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04738",
        "parent_code": "141"
    },
    "04741": {
        "name": "Mỹ Gia",
        "type": "xa",
        "slug": "my-gia",
        "name_with_type": "Xã Mỹ Gia",
        "path": "Mỹ Gia, Yên Bình, Yên Bái",
        "path_with_type": "Xã Mỹ Gia, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04741",
        "parent_code": "141"
    },
    "04744": {
        "name": "Xuân Lai",
        "type": "xa",
        "slug": "xuan-lai",
        "name_with_type": "Xã Xuân Lai",
        "path": "Xuân Lai, Yên Bình, Yên Bái",
        "path_with_type": "Xã Xuân Lai, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04744",
        "parent_code": "141"
    },
    "04747": {
        "name": "Mông Sơn",
        "type": "xa",
        "slug": "mong-son",
        "name_with_type": "Xã Mông Sơn",
        "path": "Mông Sơn, Yên Bình, Yên Bái",
        "path_with_type": "Xã Mông Sơn, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04747",
        "parent_code": "141"
    },
    "04750": {
        "name": "Cảm Ân",
        "type": "xa",
        "slug": "cam-an",
        "name_with_type": "Xã Cảm Ân",
        "path": "Cảm Ân, Yên Bình, Yên Bái",
        "path_with_type": "Xã Cảm Ân, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04750",
        "parent_code": "141"
    },
    "04753": {
        "name": "Yên Thành",
        "type": "xa",
        "slug": "yen-thanh",
        "name_with_type": "Xã Yên Thành",
        "path": "Yên Thành, Yên Bình, Yên Bái",
        "path_with_type": "Xã Yên Thành, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04753",
        "parent_code": "141"
    },
    "04756": {
        "name": "Tân Hương",
        "type": "xa",
        "slug": "tan-huong",
        "name_with_type": "Xã Tân Hương",
        "path": "Tân Hương, Yên Bình, Yên Bái",
        "path_with_type": "Xã Tân Hương, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04756",
        "parent_code": "141"
    },
    "04759": {
        "name": "Phúc An",
        "type": "xa",
        "slug": "phuc-an",
        "name_with_type": "Xã Phúc An",
        "path": "Phúc An, Yên Bình, Yên Bái",
        "path_with_type": "Xã Phúc An, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04759",
        "parent_code": "141"
    },
    "04762": {
        "name": "Bạch Hà",
        "type": "xa",
        "slug": "bach-ha",
        "name_with_type": "Xã Bạch Hà",
        "path": "Bạch Hà, Yên Bình, Yên Bái",
        "path_with_type": "Xã Bạch Hà, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04762",
        "parent_code": "141"
    },
    "04765": {
        "name": "Vũ Linh",
        "type": "xa",
        "slug": "vu-linh",
        "name_with_type": "Xã Vũ Linh",
        "path": "Vũ Linh, Yên Bình, Yên Bái",
        "path_with_type": "Xã Vũ Linh, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04765",
        "parent_code": "141"
    },
    "04768": {
        "name": "Đại Đồng",
        "type": "xa",
        "slug": "dai-dong",
        "name_with_type": "Xã Đại Đồng",
        "path": "Đại Đồng, Yên Bình, Yên Bái",
        "path_with_type": "Xã Đại Đồng, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04768",
        "parent_code": "141"
    },
    "04771": {
        "name": "Vĩnh Kiên",
        "type": "xa",
        "slug": "vinh-kien",
        "name_with_type": "Xã Vĩnh Kiên",
        "path": "Vĩnh Kiên, Yên Bình, Yên Bái",
        "path_with_type": "Xã Vĩnh Kiên, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04771",
        "parent_code": "141"
    },
    "04774": {
        "name": "Yên Bình",
        "type": "xa",
        "slug": "yen-binh",
        "name_with_type": "Xã Yên Bình",
        "path": "Yên Bình, Yên Bình, Yên Bái",
        "path_with_type": "Xã Yên Bình, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04774",
        "parent_code": "141"
    },
    "04777": {
        "name": "Thịnh Hưng",
        "type": "xa",
        "slug": "thinh-hung",
        "name_with_type": "Xã Thịnh Hưng",
        "path": "Thịnh Hưng, Yên Bình, Yên Bái",
        "path_with_type": "Xã Thịnh Hưng, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04777",
        "parent_code": "141"
    },
    "04780": {
        "name": "Hán Đà",
        "type": "xa",
        "slug": "han-da",
        "name_with_type": "Xã Hán Đà",
        "path": "Hán Đà, Yên Bình, Yên Bái",
        "path_with_type": "Xã Hán Đà, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04780",
        "parent_code": "141"
    },
    "04783": {
        "name": "Phú Thịnh",
        "type": "xa",
        "slug": "phu-thinh",
        "name_with_type": "Xã Phú Thịnh",
        "path": "Phú Thịnh, Yên Bình, Yên Bái",
        "path_with_type": "Xã Phú Thịnh, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04783",
        "parent_code": "141"
    },
    "04786": {
        "name": "Đại Minh",
        "type": "xa",
        "slug": "dai-minh",
        "name_with_type": "Xã Đại Minh",
        "path": "Đại Minh, Yên Bình, Yên Bái",
        "path_with_type": "Xã Đại Minh, Huyện Yên Bình, Tỉnh Yên Bái",
        "code": "04786",
        "parent_code": "141"
    },
    "23557": {
        "name": "Yên Đỗ",
        "type": "phuong",
        "slug": "yen-do",
        "name_with_type": "Phường Yên Đỗ",
        "path": "Yên Đỗ, Pleiku, Gia Lai",
        "path_with_type": "Phường Yên Đỗ, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23557",
        "parent_code": "622"
    },
    "23560": {
        "name": "Diên Hồng",
        "type": "phuong",
        "slug": "dien-hong",
        "name_with_type": "Phường Diên Hồng",
        "path": "Diên Hồng, Pleiku, Gia Lai",
        "path_with_type": "Phường Diên Hồng, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23560",
        "parent_code": "622"
    },
    "23563": {
        "name": "Ia Kring",
        "type": "phuong",
        "slug": "ia-kring",
        "name_with_type": "Phường Ia Kring",
        "path": "Ia Kring, Pleiku, Gia Lai",
        "path_with_type": "Phường Ia Kring, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23563",
        "parent_code": "622"
    },
    "23566": {
        "name": "Hội Thương",
        "type": "phuong",
        "slug": "hoi-thuong",
        "name_with_type": "Phường Hội Thương",
        "path": "Hội Thương, Pleiku, Gia Lai",
        "path_with_type": "Phường Hội Thương, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23566",
        "parent_code": "622"
    },
    "23569": {
        "name": "Hội Phú",
        "type": "phuong",
        "slug": "hoi-phu",
        "name_with_type": "Phường Hội Phú",
        "path": "Hội Phú, Pleiku, Gia Lai",
        "path_with_type": "Phường Hội Phú, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23569",
        "parent_code": "622"
    },
    "23570": {
        "name": "Phù Đổng",
        "type": "phuong",
        "slug": "phu-dong",
        "name_with_type": "Phường Phù Đổng",
        "path": "Phù Đổng, Pleiku, Gia Lai",
        "path_with_type": "Phường Phù Đổng, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23570",
        "parent_code": "622"
    },
    "23572": {
        "name": "Hoa Lư",
        "type": "phuong",
        "slug": "hoa-lu",
        "name_with_type": "Phường Hoa Lư",
        "path": "Hoa Lư, Pleiku, Gia Lai",
        "path_with_type": "Phường Hoa Lư, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23572",
        "parent_code": "622"
    },
    "23575": {
        "name": "Tây Sơn",
        "type": "phuong",
        "slug": "tay-son",
        "name_with_type": "Phường Tây Sơn",
        "path": "Tây Sơn, Pleiku, Gia Lai",
        "path_with_type": "Phường Tây Sơn, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23575",
        "parent_code": "622"
    },
    "23578": {
        "name": "Thống Nhất",
        "type": "phuong",
        "slug": "thong-nhat",
        "name_with_type": "Phường Thống Nhất",
        "path": "Thống Nhất, Pleiku, Gia Lai",
        "path_with_type": "Phường Thống Nhất, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23578",
        "parent_code": "622"
    },
    "23579": {
        "name": "Đống Đa",
        "type": "phuong",
        "slug": "dong-da",
        "name_with_type": "Phường Đống Đa",
        "path": "Đống Đa, Pleiku, Gia Lai",
        "path_with_type": "Phường Đống Đa, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23579",
        "parent_code": "622"
    },
    "23581": {
        "name": "Trà Bá",
        "type": "phuong",
        "slug": "tra-ba",
        "name_with_type": "Phường Trà Bá",
        "path": "Trà Bá, Pleiku, Gia Lai",
        "path_with_type": "Phường Trà Bá, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23581",
        "parent_code": "622"
    },
    "23582": {
        "name": "Thắng Lợi",
        "type": "phuong",
        "slug": "thang-loi",
        "name_with_type": "Phường Thắng Lợi",
        "path": "Thắng Lợi, Pleiku, Gia Lai",
        "path_with_type": "Phường Thắng Lợi, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23582",
        "parent_code": "622"
    },
    "23584": {
        "name": "Yên Thế",
        "type": "phuong",
        "slug": "yen-the",
        "name_with_type": "Phường Yên Thế",
        "path": "Yên Thế, Pleiku, Gia Lai",
        "path_with_type": "Phường Yên Thế, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23584",
        "parent_code": "622"
    },
    "23586": {
        "name": "Chi Lăng",
        "type": "phuong",
        "slug": "chi-lang",
        "name_with_type": "Phường Chi Lăng",
        "path": "Chi Lăng, Pleiku, Gia Lai",
        "path_with_type": "Phường Chi Lăng, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23586",
        "parent_code": "622"
    },
    "23587": {
        "name": "Chư HDrông",
        "type": "xa",
        "slug": "chu-hdrong",
        "name_with_type": "Xã Chư HDrông",
        "path": "Chư HDrông, Pleiku, Gia Lai",
        "path_with_type": "Xã Chư HDrông, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23587",
        "parent_code": "622"
    },
    "23590": {
        "name": "Biển Hồ",
        "type": "xa",
        "slug": "bien-ho",
        "name_with_type": "Xã Biển Hồ",
        "path": "Biển Hồ, Pleiku, Gia Lai",
        "path_with_type": "Xã Biển Hồ, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23590",
        "parent_code": "622"
    },
    "23593": {
        "name": "Tân Sơn",
        "type": "xa",
        "slug": "tan-son",
        "name_with_type": "Xã Tân Sơn",
        "path": "Tân Sơn, Pleiku, Gia Lai",
        "path_with_type": "Xã Tân Sơn, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23593",
        "parent_code": "622"
    },
    "23596": {
        "name": "Trà Đa",
        "type": "xa",
        "slug": "tra-da",
        "name_with_type": "Xã Trà Đa",
        "path": "Trà Đa, Pleiku, Gia Lai",
        "path_with_type": "Xã Trà Đa, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23596",
        "parent_code": "622"
    },
    "23599": {
        "name": "Chư Á",
        "type": "xa",
        "slug": "chu-a",
        "name_with_type": "Xã Chư Á",
        "path": "Chư Á, Pleiku, Gia Lai",
        "path_with_type": "Xã Chư Á, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23599",
        "parent_code": "622"
    },
    "23602": {
        "name": "An Phú",
        "type": "xa",
        "slug": "an-phu",
        "name_with_type": "Xã An Phú",
        "path": "An Phú, Pleiku, Gia Lai",
        "path_with_type": "Xã An Phú, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23602",
        "parent_code": "622"
    },
    "23605": {
        "name": "Diên Phú",
        "type": "xa",
        "slug": "dien-phu",
        "name_with_type": "Xã Diên Phú",
        "path": "Diên Phú, Pleiku, Gia Lai",
        "path_with_type": "Xã Diên Phú, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23605",
        "parent_code": "622"
    },
    "23608": {
        "name": "Ia Kênh",
        "type": "xa",
        "slug": "ia-kenh",
        "name_with_type": "Xã Ia Kênh",
        "path": "Ia Kênh, Pleiku, Gia Lai",
        "path_with_type": "Xã Ia Kênh, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23608",
        "parent_code": "622"
    },
    "23611": {
        "name": "Gào",
        "type": "xa",
        "slug": "gao",
        "name_with_type": "Xã Gào",
        "path": "Gào, Pleiku, Gia Lai",
        "path_with_type": "Xã Gào, Thành phố Pleiku, Tỉnh Gia Lai",
        "code": "23611",
        "parent_code": "622"
    },
    "23614": {
        "name": "An Bình",
        "type": "phuong",
        "slug": "an-binh",
        "name_with_type": "Phường An Bình",
        "path": "An Bình, An Khê, Gia Lai",
        "path_with_type": "Phường An Bình, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23614",
        "parent_code": "623"
    },
    "23617": {
        "name": "Tây Sơn",
        "type": "phuong",
        "slug": "tay-son",
        "name_with_type": "Phường Tây Sơn",
        "path": "Tây Sơn, An Khê, Gia Lai",
        "path_with_type": "Phường Tây Sơn, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23617",
        "parent_code": "623"
    },
    "23620": {
        "name": "An Phú",
        "type": "phuong",
        "slug": "an-phu",
        "name_with_type": "Phường An Phú",
        "path": "An Phú, An Khê, Gia Lai",
        "path_with_type": "Phường An Phú, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23620",
        "parent_code": "623"
    },
    "23623": {
        "name": "An Tân",
        "type": "phuong",
        "slug": "an-tan",
        "name_with_type": "Phường An Tân",
        "path": "An Tân, An Khê, Gia Lai",
        "path_with_type": "Phường An Tân, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23623",
        "parent_code": "623"
    },
    "23626": {
        "name": "Tú An",
        "type": "xa",
        "slug": "tu-an",
        "name_with_type": "Xã Tú An",
        "path": "Tú An, An Khê, Gia Lai",
        "path_with_type": "Xã Tú An, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23626",
        "parent_code": "623"
    },
    "23627": {
        "name": "Xuân An",
        "type": "xa",
        "slug": "xuan-an",
        "name_with_type": "Xã Xuân An",
        "path": "Xuân An, An Khê, Gia Lai",
        "path_with_type": "Xã Xuân An, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23627",
        "parent_code": "623"
    },
    "23629": {
        "name": "Cửu An",
        "type": "xa",
        "slug": "cuu-an",
        "name_with_type": "Xã Cửu An",
        "path": "Cửu An, An Khê, Gia Lai",
        "path_with_type": "Xã Cửu An, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23629",
        "parent_code": "623"
    },
    "23630": {
        "name": "An Phước",
        "type": "phuong",
        "slug": "an-phuoc",
        "name_with_type": "Phường An Phước",
        "path": "An Phước, An Khê, Gia Lai",
        "path_with_type": "Phường An Phước, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23630",
        "parent_code": "623"
    },
    "23632": {
        "name": "Song An",
        "type": "xa",
        "slug": "song-an",
        "name_with_type": "Xã Song An",
        "path": "Song An, An Khê, Gia Lai",
        "path_with_type": "Xã Song An, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23632",
        "parent_code": "623"
    },
    "23633": {
        "name": "Ngô Mây",
        "type": "phuong",
        "slug": "ngo-may",
        "name_with_type": "Phường Ngô Mây",
        "path": "Ngô Mây, An Khê, Gia Lai",
        "path_with_type": "Phường Ngô Mây, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23633",
        "parent_code": "623"
    },
    "23635": {
        "name": "Thành An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thành An",
        "path": "Thành An, An Khê, Gia Lai",
        "path_with_type": "Xã Thành An, Thị xã An Khê, Tỉnh Gia Lai",
        "code": "23635",
        "parent_code": "623"
    },
    "24041": {
        "name": "Cheo Reo",
        "type": "phuong",
        "slug": "cheo-reo",
        "name_with_type": "Phường Cheo Reo",
        "path": "Cheo Reo, Ayun Pa, Gia Lai",
        "path_with_type": "Phường Cheo Reo, Thị xã Ayun Pa, Tỉnh Gia Lai",
        "code": "24041",
        "parent_code": "624"
    },
    "24042": {
        "name": "Hòa Bình",
        "type": "phuong",
        "slug": "hoa-binh",
        "name_with_type": "Phường Hòa Bình",
        "path": "Hòa Bình, Ayun Pa, Gia Lai",
        "path_with_type": "Phường Hòa Bình, Thị xã Ayun Pa, Tỉnh Gia Lai",
        "code": "24042",
        "parent_code": "624"
    },
    "24044": {
        "name": "Đoàn Kết",
        "type": "phuong",
        "slug": "doan-ket",
        "name_with_type": "Phường Đoàn Kết",
        "path": "Đoàn Kết, Ayun Pa, Gia Lai",
        "path_with_type": "Phường Đoàn Kết, Thị xã Ayun Pa, Tỉnh Gia Lai",
        "code": "24044",
        "parent_code": "624"
    },
    "24045": {
        "name": "Sông Bờ",
        "type": "phuong",
        "slug": "song-bo",
        "name_with_type": "Phường Sông Bờ",
        "path": "Sông Bờ, Ayun Pa, Gia Lai",
        "path_with_type": "Phường Sông Bờ, Thị xã Ayun Pa, Tỉnh Gia Lai",
        "code": "24045",
        "parent_code": "624"
    },
    "24064": {
        "name": "Ia RBol",
        "type": "xa",
        "slug": "ia-rbol",
        "name_with_type": "Xã Ia RBol",
        "path": "Ia RBol, Ayun Pa, Gia Lai",
        "path_with_type": "Xã Ia RBol, Thị xã Ayun Pa, Tỉnh Gia Lai",
        "code": "24064",
        "parent_code": "624"
    },
    "24065": {
        "name": "Chư Băh",
        "type": "xa",
        "slug": "chu-bah",
        "name_with_type": "Xã Chư Băh",
        "path": "Chư Băh, Ayun Pa, Gia Lai",
        "path_with_type": "Xã Chư Băh, Thị xã Ayun Pa, Tỉnh Gia Lai",
        "code": "24065",
        "parent_code": "624"
    },
    "24070": {
        "name": "Ia RTô",
        "type": "xa",
        "slug": "ia-rto",
        "name_with_type": "Xã Ia RTô",
        "path": "Ia RTô, Ayun Pa, Gia Lai",
        "path_with_type": "Xã Ia RTô, Thị xã Ayun Pa, Tỉnh Gia Lai",
        "code": "24070",
        "parent_code": "624"
    },
    "24073": {
        "name": "Ia Sao",
        "type": "xa",
        "slug": "ia-sao",
        "name_with_type": "Xã Ia Sao",
        "path": "Ia Sao, Ayun Pa, Gia Lai",
        "path_with_type": "Xã Ia Sao, Thị xã Ayun Pa, Tỉnh Gia Lai",
        "code": "24073",
        "parent_code": "624"
    },
    "23638": {
        "name": "KBang",
        "type": "thi-tran",
        "slug": "kbang",
        "name_with_type": "Thị trấn KBang",
        "path": "KBang, KBang, Gia Lai",
        "path_with_type": "Thị trấn KBang, Huyện KBang, Tỉnh Gia Lai",
        "code": "23638",
        "parent_code": "625"
    },
    "23641": {
        "name": "Kon Pne",
        "type": "xa",
        "slug": "kon-pne",
        "name_with_type": "Xã Kon Pne",
        "path": "Kon Pne, KBang, Gia Lai",
        "path_with_type": "Xã Kon Pne, Huyện KBang, Tỉnh Gia Lai",
        "code": "23641",
        "parent_code": "625"
    },
    "23644": {
        "name": "Đăk Roong",
        "type": "xa",
        "slug": "dak-roong",
        "name_with_type": "Xã Đăk Roong",
        "path": "Đăk Roong, KBang, Gia Lai",
        "path_with_type": "Xã Đăk Roong, Huyện KBang, Tỉnh Gia Lai",
        "code": "23644",
        "parent_code": "625"
    },
    "23647": {
        "name": "Sơn Lang",
        "type": "xa",
        "slug": "son-lang",
        "name_with_type": "Xã Sơn Lang",
        "path": "Sơn Lang, KBang, Gia Lai",
        "path_with_type": "Xã Sơn Lang, Huyện KBang, Tỉnh Gia Lai",
        "code": "23647",
        "parent_code": "625"
    },
    "23650": {
        "name": "KRong",
        "type": "xa",
        "slug": "krong",
        "name_with_type": "Xã KRong",
        "path": "KRong, KBang, Gia Lai",
        "path_with_type": "Xã KRong, Huyện KBang, Tỉnh Gia Lai",
        "code": "23650",
        "parent_code": "625"
    },
    "23653": {
        "name": "Sơ Pai",
        "type": "xa",
        "slug": "so-pai",
        "name_with_type": "Xã Sơ Pai",
        "path": "Sơ Pai, KBang, Gia Lai",
        "path_with_type": "Xã Sơ Pai, Huyện KBang, Tỉnh Gia Lai",
        "code": "23653",
        "parent_code": "625"
    },
    "23656": {
        "name": "Lơ Ku",
        "type": "xa",
        "slug": "lo-ku",
        "name_with_type": "Xã Lơ Ku",
        "path": "Lơ Ku, KBang, Gia Lai",
        "path_with_type": "Xã Lơ Ku, Huyện KBang, Tỉnh Gia Lai",
        "code": "23656",
        "parent_code": "625"
    },
    "23659": {
        "name": "Đông",
        "type": "xa",
        "slug": "dong",
        "name_with_type": "Xã Đông",
        "path": "Đông, KBang, Gia Lai",
        "path_with_type": "Xã Đông, Huyện KBang, Tỉnh Gia Lai",
        "code": "23659",
        "parent_code": "625"
    },
    "23660": {
        "name": "Đak SMar",
        "type": "xa",
        "slug": "dak-smar",
        "name_with_type": "Xã Đak SMar",
        "path": "Đak SMar, KBang, Gia Lai",
        "path_with_type": "Xã Đak SMar, Huyện KBang, Tỉnh Gia Lai",
        "code": "23660",
        "parent_code": "625"
    },
    "23662": {
        "name": "Nghĩa An",
        "type": "xa",
        "slug": "nghia-an",
        "name_with_type": "Xã Nghĩa An",
        "path": "Nghĩa An, KBang, Gia Lai",
        "path_with_type": "Xã Nghĩa An, Huyện KBang, Tỉnh Gia Lai",
        "code": "23662",
        "parent_code": "625"
    },
    "23665": {
        "name": "Tơ Tung",
        "type": "xa",
        "slug": "to-tung",
        "name_with_type": "Xã Tơ Tung",
        "path": "Tơ Tung, KBang, Gia Lai",
        "path_with_type": "Xã Tơ Tung, Huyện KBang, Tỉnh Gia Lai",
        "code": "23665",
        "parent_code": "625"
    },
    "23668": {
        "name": "Kông Lơng Khơng",
        "type": "xa",
        "slug": "kong-long-khong",
        "name_with_type": "Xã Kông Lơng Khơng",
        "path": "Kông Lơng Khơng, KBang, Gia Lai",
        "path_with_type": "Xã Kông Lơng Khơng, Huyện KBang, Tỉnh Gia Lai",
        "code": "23668",
        "parent_code": "625"
    },
    "23671": {
        "name": "Kông Pla",
        "type": "xa",
        "slug": "kong-pla",
        "name_with_type": "Xã Kông Pla",
        "path": "Kông Pla, KBang, Gia Lai",
        "path_with_type": "Xã Kông Pla, Huyện KBang, Tỉnh Gia Lai",
        "code": "23671",
        "parent_code": "625"
    },
    "23674": {
        "name": "Đăk HLơ",
        "type": "xa",
        "slug": "dak-hlo",
        "name_with_type": "Xã Đăk HLơ",
        "path": "Đăk HLơ, KBang, Gia Lai",
        "path_with_type": "Xã Đăk HLơ, Huyện KBang, Tỉnh Gia Lai",
        "code": "23674",
        "parent_code": "625"
    },
    "23677": {
        "name": "Đăk Đoa",
        "type": "thi-tran",
        "slug": "dak-doa",
        "name_with_type": "Thị trấn Đăk Đoa",
        "path": "Đăk Đoa, Đăk Đoa, Gia Lai",
        "path_with_type": "Thị trấn Đăk Đoa, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23677",
        "parent_code": "626"
    },
    "23680": {
        "name": "Hà Đông",
        "type": "xa",
        "slug": "ha-dong",
        "name_with_type": "Xã Hà Đông",
        "path": "Hà Đông, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Hà Đông, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23680",
        "parent_code": "626"
    },
    "23683": {
        "name": "Đăk Sơmei",
        "type": "xa",
        "slug": "dak-somei",
        "name_with_type": "Xã Đăk Sơmei",
        "path": "Đăk Sơmei, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Đăk Sơmei, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23683",
        "parent_code": "626"
    },
    "23684": {
        "name": "Đăk Krong",
        "type": "xa",
        "slug": "dak-krong",
        "name_with_type": "Xã Đăk Krong",
        "path": "Đăk Krong, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Đăk Krong, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23684",
        "parent_code": "626"
    },
    "23686": {
        "name": "Hải Yang",
        "type": "xa",
        "slug": "hai-yang",
        "name_with_type": "Xã Hải Yang",
        "path": "Hải Yang, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Hải Yang, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23686",
        "parent_code": "626"
    },
    "23689": {
        "name": "Kon Gang",
        "type": "xa",
        "slug": "kon-gang",
        "name_with_type": "Xã Kon Gang",
        "path": "Kon Gang, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Kon Gang, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23689",
        "parent_code": "626"
    },
    "23692": {
        "name": "Hà Bầu",
        "type": "xa",
        "slug": "ha-bau",
        "name_with_type": "Xã Hà Bầu",
        "path": "Hà Bầu, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Hà Bầu, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23692",
        "parent_code": "626"
    },
    "23695": {
        "name": "Nam Yang",
        "type": "xa",
        "slug": "nam-yang",
        "name_with_type": "Xã Nam Yang",
        "path": "Nam Yang, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Nam Yang, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23695",
        "parent_code": "626"
    },
    "23698": {
        "name": "K' Dang",
        "type": "xa",
        "slug": "k-dang",
        "name_with_type": "Xã K' Dang",
        "path": "K' Dang, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã K' Dang, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23698",
        "parent_code": "626"
    },
    "23701": {
        "name": "H' Neng",
        "type": "xa",
        "slug": "h-neng",
        "name_with_type": "Xã H' Neng",
        "path": "H' Neng, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã H' Neng, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23701",
        "parent_code": "626"
    },
    "23704": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Tân Bình, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23704",
        "parent_code": "626"
    },
    "23707": {
        "name": "Glar",
        "type": "xa",
        "slug": "glar",
        "name_with_type": "Xã Glar",
        "path": "Glar, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Glar, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23707",
        "parent_code": "626"
    },
    "23710": {
        "name": "A Dơk",
        "type": "xa",
        "slug": "a-dok",
        "name_with_type": "Xã A Dơk",
        "path": "A Dơk, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã A Dơk, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23710",
        "parent_code": "626"
    },
    "23713": {
        "name": "Trang",
        "type": "xa",
        "slug": "trang",
        "name_with_type": "Xã Trang",
        "path": "Trang, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Trang, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23713",
        "parent_code": "626"
    },
    "23714": {
        "name": "HNol",
        "type": "xa",
        "slug": "hnol",
        "name_with_type": "Xã HNol",
        "path": "HNol, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã HNol, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23714",
        "parent_code": "626"
    },
    "23716": {
        "name": "Ia Pết",
        "type": "xa",
        "slug": "ia-pet",
        "name_with_type": "Xã Ia Pết",
        "path": "Ia Pết, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Ia Pết, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23716",
        "parent_code": "626"
    },
    "23719": {
        "name": "Ia Băng",
        "type": "xa",
        "slug": "ia-bang",
        "name_with_type": "Xã Ia Băng",
        "path": "Ia Băng, Đăk Đoa, Gia Lai",
        "path_with_type": "Xã Ia Băng, Huyện Đăk Đoa, Tỉnh Gia Lai",
        "code": "23719",
        "parent_code": "626"
    },
    "23722": {
        "name": "Phú Hòa",
        "type": "thi-tran",
        "slug": "phu-hoa",
        "name_with_type": "Thị trấn Phú Hòa",
        "path": "Phú Hòa, Chư Păh, Gia Lai",
        "path_with_type": "Thị trấn Phú Hòa, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23722",
        "parent_code": "627"
    },
    "23725": {
        "name": "Hà Tây",
        "type": "xa",
        "slug": "ha-tay",
        "name_with_type": "Xã Hà Tây",
        "path": "Hà Tây, Chư Păh, Gia Lai",
        "path_with_type": "Xã Hà Tây, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23725",
        "parent_code": "627"
    },
    "23728": {
        "name": "Ia Khươl",
        "type": "xa",
        "slug": "ia-khuol",
        "name_with_type": "Xã Ia Khươl",
        "path": "Ia Khươl, Chư Păh, Gia Lai",
        "path_with_type": "Xã Ia Khươl, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23728",
        "parent_code": "627"
    },
    "23731": {
        "name": "Ia Phí",
        "type": "xa",
        "slug": "ia-phi",
        "name_with_type": "Xã Ia Phí",
        "path": "Ia Phí, Chư Păh, Gia Lai",
        "path_with_type": "Xã Ia Phí, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23731",
        "parent_code": "627"
    },
    "23734": {
        "name": "Ia Ly",
        "type": "thi-tran",
        "slug": "ia-ly",
        "name_with_type": "Thị trấn Ia Ly",
        "path": "Ia Ly, Chư Păh, Gia Lai",
        "path_with_type": "Thị trấn Ia Ly, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23734",
        "parent_code": "627"
    },
    "23737": {
        "name": "Ia Mơ Nông",
        "type": "xa",
        "slug": "ia-mo-nong",
        "name_with_type": "Xã Ia Mơ Nông",
        "path": "Ia Mơ Nông, Chư Păh, Gia Lai",
        "path_with_type": "Xã Ia Mơ Nông, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23737",
        "parent_code": "627"
    },
    "23738": {
        "name": "Ia Kreng",
        "type": "xa",
        "slug": "ia-kreng",
        "name_with_type": "Xã Ia Kreng",
        "path": "Ia Kreng, Chư Păh, Gia Lai",
        "path_with_type": "Xã Ia Kreng, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23738",
        "parent_code": "627"
    },
    "23740": {
        "name": "Đăk Tơ Ver",
        "type": "xa",
        "slug": "dak-to-ver",
        "name_with_type": "Xã Đăk Tơ Ver",
        "path": "Đăk Tơ Ver, Chư Păh, Gia Lai",
        "path_with_type": "Xã Đăk Tơ Ver, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23740",
        "parent_code": "627"
    },
    "23743": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Chư Păh, Gia Lai",
        "path_with_type": "Xã Hòa Phú, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23743",
        "parent_code": "627"
    },
    "23746": {
        "name": "Chư Đăng Ya",
        "type": "xa",
        "slug": "chu-dang-ya",
        "name_with_type": "Xã Chư Đăng Ya",
        "path": "Chư Đăng Ya, Chư Păh, Gia Lai",
        "path_with_type": "Xã Chư Đăng Ya, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23746",
        "parent_code": "627"
    },
    "23749": {
        "name": "Ia Ka",
        "type": "xa",
        "slug": "ia-ka",
        "name_with_type": "Xã Ia Ka",
        "path": "Ia Ka, Chư Păh, Gia Lai",
        "path_with_type": "Xã Ia Ka, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23749",
        "parent_code": "627"
    },
    "23752": {
        "name": "Ia Nhin",
        "type": "xa",
        "slug": "ia-nhin",
        "name_with_type": "Xã Ia Nhin",
        "path": "Ia Nhin, Chư Păh, Gia Lai",
        "path_with_type": "Xã Ia Nhin, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23752",
        "parent_code": "627"
    },
    "23755": {
        "name": "Nghĩa Hòa",
        "type": "xa",
        "slug": "nghia-hoa",
        "name_with_type": "Xã Nghĩa Hòa",
        "path": "Nghĩa Hòa, Chư Păh, Gia Lai",
        "path_with_type": "Xã Nghĩa Hòa, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23755",
        "parent_code": "627"
    },
    "23758": {
        "name": "Chư Jôr",
        "type": "xa",
        "slug": "chu-jor",
        "name_with_type": "Xã Chư Jôr",
        "path": "Chư Jôr, Chư Păh, Gia Lai",
        "path_with_type": "Xã Chư Jôr, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23758",
        "parent_code": "627"
    },
    "23761": {
        "name": "Nghĩa Hưng",
        "type": "xa",
        "slug": "nghia-hung",
        "name_with_type": "Xã Nghĩa Hưng",
        "path": "Nghĩa Hưng, Chư Păh, Gia Lai",
        "path_with_type": "Xã Nghĩa Hưng, Huyện Chư Păh, Tỉnh Gia Lai",
        "code": "23761",
        "parent_code": "627"
    },
    "23764": {
        "name": "Ia Kha",
        "type": "thi-tran",
        "slug": "ia-kha",
        "name_with_type": "Thị trấn Ia Kha",
        "path": "Ia Kha, Ia Grai, Gia Lai",
        "path_with_type": "Thị trấn Ia Kha, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23764",
        "parent_code": "628"
    },
    "23767": {
        "name": "Ia Sao",
        "type": "xa",
        "slug": "ia-sao",
        "name_with_type": "Xã Ia Sao",
        "path": "Ia Sao, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Sao, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23767",
        "parent_code": "628"
    },
    "23768": {
        "name": "Ia Yok",
        "type": "xa",
        "slug": "ia-yok",
        "name_with_type": "Xã Ia Yok",
        "path": "Ia Yok, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Yok, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23768",
        "parent_code": "628"
    },
    "23770": {
        "name": "Ia Hrung",
        "type": "xa",
        "slug": "ia-hrung",
        "name_with_type": "Xã Ia Hrung",
        "path": "Ia Hrung, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Hrung, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23770",
        "parent_code": "628"
    },
    "23771": {
        "name": "Ia Bă",
        "type": "xa",
        "slug": "ia-ba",
        "name_with_type": "Xã Ia Bă",
        "path": "Ia Bă, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Bă, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23771",
        "parent_code": "628"
    },
    "23773": {
        "name": "Ia Khai",
        "type": "xa",
        "slug": "ia-khai",
        "name_with_type": "Xã Ia Khai",
        "path": "Ia Khai, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Khai, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23773",
        "parent_code": "628"
    },
    "23776": {
        "name": "Ia KRai",
        "type": "xa",
        "slug": "ia-krai",
        "name_with_type": "Xã Ia KRai",
        "path": "Ia KRai, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia KRai, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23776",
        "parent_code": "628"
    },
    "23778": {
        "name": "Ia Grăng",
        "type": "xa",
        "slug": "ia-grang",
        "name_with_type": "Xã Ia Grăng",
        "path": "Ia Grăng, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Grăng, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23778",
        "parent_code": "628"
    },
    "23779": {
        "name": "Ia Tô",
        "type": "xa",
        "slug": "ia-to",
        "name_with_type": "Xã Ia Tô",
        "path": "Ia Tô, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Tô, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23779",
        "parent_code": "628"
    },
    "23782": {
        "name": "Ia O",
        "type": "xa",
        "slug": "ia-o",
        "name_with_type": "Xã Ia O",
        "path": "Ia O, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia O, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23782",
        "parent_code": "628"
    },
    "23785": {
        "name": "Ia Dêr",
        "type": "xa",
        "slug": "ia-der",
        "name_with_type": "Xã Ia Dêr",
        "path": "Ia Dêr, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Dêr, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23785",
        "parent_code": "628"
    },
    "23788": {
        "name": "Ia Chia",
        "type": "xa",
        "slug": "ia-chia",
        "name_with_type": "Xã Ia Chia",
        "path": "Ia Chia, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Chia, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23788",
        "parent_code": "628"
    },
    "23791": {
        "name": "Ia Pếch",
        "type": "xa",
        "slug": "ia-pech",
        "name_with_type": "Xã Ia Pếch",
        "path": "Ia Pếch, Ia Grai, Gia Lai",
        "path_with_type": "Xã Ia Pếch, Huyện Ia Grai, Tỉnh Gia Lai",
        "code": "23791",
        "parent_code": "628"
    },
    "23794": {
        "name": "Kon Dơng",
        "type": "thi-tran",
        "slug": "kon-dong",
        "name_with_type": "Thị trấn Kon Dơng",
        "path": "Kon Dơng, Mang Yang, Gia Lai",
        "path_with_type": "Thị trấn Kon Dơng, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23794",
        "parent_code": "629"
    },
    "23797": {
        "name": "Ayun",
        "type": "xa",
        "slug": "ayun",
        "name_with_type": "Xã Ayun",
        "path": "Ayun, Mang Yang, Gia Lai",
        "path_with_type": "Xã Ayun, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23797",
        "parent_code": "629"
    },
    "23798": {
        "name": "Đak Jơ Ta",
        "type": "xa",
        "slug": "dak-jo-ta",
        "name_with_type": "Xã Đak Jơ Ta",
        "path": "Đak Jơ Ta, Mang Yang, Gia Lai",
        "path_with_type": "Xã Đak Jơ Ta, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23798",
        "parent_code": "629"
    },
    "23799": {
        "name": "Đak Ta Ley",
        "type": "xa",
        "slug": "dak-ta-ley",
        "name_with_type": "Xã Đak Ta Ley",
        "path": "Đak Ta Ley, Mang Yang, Gia Lai",
        "path_with_type": "Xã Đak Ta Ley, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23799",
        "parent_code": "629"
    },
    "23800": {
        "name": "Hà Ra",
        "type": "xa",
        "slug": "ha-ra",
        "name_with_type": "Xã Hà Ra",
        "path": "Hà Ra, Mang Yang, Gia Lai",
        "path_with_type": "Xã Hà Ra, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23800",
        "parent_code": "629"
    },
    "23803": {
        "name": "Đăk Yă",
        "type": "xa",
        "slug": "dak-ya",
        "name_with_type": "Xã Đăk Yă",
        "path": "Đăk Yă, Mang Yang, Gia Lai",
        "path_with_type": "Xã Đăk Yă, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23803",
        "parent_code": "629"
    },
    "23806": {
        "name": "Đăk Djrăng",
        "type": "xa",
        "slug": "dak-djrang",
        "name_with_type": "Xã Đăk Djrăng",
        "path": "Đăk Djrăng, Mang Yang, Gia Lai",
        "path_with_type": "Xã Đăk Djrăng, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23806",
        "parent_code": "629"
    },
    "23809": {
        "name": "Lơ Pang",
        "type": "xa",
        "slug": "lo-pang",
        "name_with_type": "Xã Lơ Pang",
        "path": "Lơ Pang, Mang Yang, Gia Lai",
        "path_with_type": "Xã Lơ Pang, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23809",
        "parent_code": "629"
    },
    "23812": {
        "name": "Kon Thụp",
        "type": "xa",
        "slug": "kon-thup",
        "name_with_type": "Xã Kon Thụp",
        "path": "Kon Thụp, Mang Yang, Gia Lai",
        "path_with_type": "Xã Kon Thụp, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23812",
        "parent_code": "629"
    },
    "23815": {
        "name": "Đê Ar",
        "type": "xa",
        "slug": "de-ar",
        "name_with_type": "Xã Đê Ar",
        "path": "Đê Ar, Mang Yang, Gia Lai",
        "path_with_type": "Xã Đê Ar, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23815",
        "parent_code": "629"
    },
    "23818": {
        "name": "Kon Chiêng",
        "type": "xa",
        "slug": "kon-chieng",
        "name_with_type": "Xã Kon Chiêng",
        "path": "Kon Chiêng, Mang Yang, Gia Lai",
        "path_with_type": "Xã Kon Chiêng, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23818",
        "parent_code": "629"
    },
    "23821": {
        "name": "Đăk Trôi",
        "type": "xa",
        "slug": "dak-troi",
        "name_with_type": "Xã Đăk Trôi",
        "path": "Đăk Trôi, Mang Yang, Gia Lai",
        "path_with_type": "Xã Đăk Trôi, Huyện Mang Yang, Tỉnh Gia Lai",
        "code": "23821",
        "parent_code": "629"
    },
    "23824": {
        "name": "Kông Chro",
        "type": "thi-tran",
        "slug": "kong-chro",
        "name_with_type": "Thị trấn Kông Chro",
        "path": "Kông Chro, Kông Chro, Gia Lai",
        "path_with_type": "Thị trấn Kông Chro, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23824",
        "parent_code": "630"
    },
    "23827": {
        "name": "Chư Krêy",
        "type": "xa",
        "slug": "chu-krey",
        "name_with_type": "Xã Chư Krêy",
        "path": "Chư Krêy, Kông Chro, Gia Lai",
        "path_with_type": "Xã Chư Krêy, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23827",
        "parent_code": "630"
    },
    "23830": {
        "name": "An Trung",
        "type": "xa",
        "slug": "an-trung",
        "name_with_type": "Xã An Trung",
        "path": "An Trung, Kông Chro, Gia Lai",
        "path_with_type": "Xã An Trung, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23830",
        "parent_code": "630"
    },
    "23833": {
        "name": "Kông Yang",
        "type": "xa",
        "slug": "kong-yang",
        "name_with_type": "Xã Kông Yang",
        "path": "Kông Yang, Kông Chro, Gia Lai",
        "path_with_type": "Xã Kông Yang, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23833",
        "parent_code": "630"
    },
    "23836": {
        "name": "Đăk Tơ Pang",
        "type": "xa",
        "slug": "dak-to-pang",
        "name_with_type": "Xã Đăk Tơ Pang",
        "path": "Đăk Tơ Pang, Kông Chro, Gia Lai",
        "path_with_type": "Xã Đăk Tơ Pang, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23836",
        "parent_code": "630"
    },
    "23839": {
        "name": "SRó",
        "type": "xa",
        "slug": "sro",
        "name_with_type": "Xã SRó",
        "path": "SRó, Kông Chro, Gia Lai",
        "path_with_type": "Xã SRó, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23839",
        "parent_code": "630"
    },
    "23840": {
        "name": "Đắk Kơ Ning",
        "type": "xa",
        "slug": "dak-ko-ning",
        "name_with_type": "Xã Đắk Kơ Ning",
        "path": "Đắk Kơ Ning, Kông Chro, Gia Lai",
        "path_with_type": "Xã Đắk Kơ Ning, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23840",
        "parent_code": "630"
    },
    "23842": {
        "name": "Đăk Song",
        "type": "xa",
        "slug": "dak-song",
        "name_with_type": "Xã Đăk Song",
        "path": "Đăk Song, Kông Chro, Gia Lai",
        "path_with_type": "Xã Đăk Song, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23842",
        "parent_code": "630"
    },
    "23843": {
        "name": "Đăk Pling",
        "type": "xa",
        "slug": "dak-pling",
        "name_with_type": "Xã Đăk Pling",
        "path": "Đăk Pling, Kông Chro, Gia Lai",
        "path_with_type": "Xã Đăk Pling, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23843",
        "parent_code": "630"
    },
    "23845": {
        "name": "Yang Trung",
        "type": "xa",
        "slug": "yang-trung",
        "name_with_type": "Xã Yang Trung",
        "path": "Yang Trung, Kông Chro, Gia Lai",
        "path_with_type": "Xã Yang Trung, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23845",
        "parent_code": "630"
    },
    "23846": {
        "name": "Đăk Pơ Pho",
        "type": "xa",
        "slug": "dak-po-pho",
        "name_with_type": "Xã Đăk Pơ Pho",
        "path": "Đăk Pơ Pho, Kông Chro, Gia Lai",
        "path_with_type": "Xã Đăk Pơ Pho, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23846",
        "parent_code": "630"
    },
    "23848": {
        "name": "Ya Ma",
        "type": "xa",
        "slug": "ya-ma",
        "name_with_type": "Xã Ya Ma",
        "path": "Ya Ma, Kông Chro, Gia Lai",
        "path_with_type": "Xã Ya Ma, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23848",
        "parent_code": "630"
    },
    "23851": {
        "name": "Chơ Long",
        "type": "xa",
        "slug": "cho-long",
        "name_with_type": "Xã Chơ Long",
        "path": "Chơ Long, Kông Chro, Gia Lai",
        "path_with_type": "Xã Chơ Long, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23851",
        "parent_code": "630"
    },
    "23854": {
        "name": "Yang Nam",
        "type": "xa",
        "slug": "yang-nam",
        "name_with_type": "Xã Yang Nam",
        "path": "Yang Nam, Kông Chro, Gia Lai",
        "path_with_type": "Xã Yang Nam, Huyện Kông Chro, Tỉnh Gia Lai",
        "code": "23854",
        "parent_code": "630"
    },
    "23857": {
        "name": "Chư Ty",
        "type": "thi-tran",
        "slug": "chu-ty",
        "name_with_type": "Thị trấn Chư Ty",
        "path": "Chư Ty, Đức Cơ, Gia Lai",
        "path_with_type": "Thị trấn Chư Ty, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23857",
        "parent_code": "631"
    },
    "23860": {
        "name": "Ia Dơk",
        "type": "xa",
        "slug": "ia-dok",
        "name_with_type": "Xã Ia Dơk",
        "path": "Ia Dơk, Đức Cơ, Gia Lai",
        "path_with_type": "Xã Ia Dơk, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23860",
        "parent_code": "631"
    },
    "23863": {
        "name": "Ia Krêl",
        "type": "xa",
        "slug": "ia-krel",
        "name_with_type": "Xã Ia Krêl",
        "path": "Ia Krêl, Đức Cơ, Gia Lai",
        "path_with_type": "Xã Ia Krêl, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23863",
        "parent_code": "631"
    },
    "23866": {
        "name": "Ia Din",
        "type": "xa",
        "slug": "ia-din",
        "name_with_type": "Xã Ia Din",
        "path": "Ia Din, Đức Cơ, Gia Lai",
        "path_with_type": "Xã Ia Din, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23866",
        "parent_code": "631"
    },
    "23869": {
        "name": "Ia Kla",
        "type": "xa",
        "slug": "ia-kla",
        "name_with_type": "Xã Ia Kla",
        "path": "Ia Kla, Đức Cơ, Gia Lai",
        "path_with_type": "Xã Ia Kla, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23869",
        "parent_code": "631"
    },
    "23872": {
        "name": "Ia Dom",
        "type": "xa",
        "slug": "ia-dom",
        "name_with_type": "Xã Ia Dom",
        "path": "Ia Dom, Đức Cơ, Gia Lai",
        "path_with_type": "Xã Ia Dom, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23872",
        "parent_code": "631"
    },
    "23875": {
        "name": "Ia Lang",
        "type": "xa",
        "slug": "ia-lang",
        "name_with_type": "Xã Ia Lang",
        "path": "Ia Lang, Đức Cơ, Gia Lai",
        "path_with_type": "Xã Ia Lang, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23875",
        "parent_code": "631"
    },
    "23878": {
        "name": "Ia Kriêng",
        "type": "xa",
        "slug": "ia-krieng",
        "name_with_type": "Xã Ia Kriêng",
        "path": "Ia Kriêng, Đức Cơ, Gia Lai",
        "path_with_type": "Xã Ia Kriêng, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23878",
        "parent_code": "631"
    },
    "23881": {
        "name": "Ia Pnôn",
        "type": "xa",
        "slug": "ia-pnon",
        "name_with_type": "Xã Ia Pnôn",
        "path": "Ia Pnôn, Đức Cơ, Gia Lai",
        "path_with_type": "Xã Ia Pnôn, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23881",
        "parent_code": "631"
    },
    "23884": {
        "name": "Ia Nan",
        "type": "xa",
        "slug": "ia-nan",
        "name_with_type": "Xã Ia Nan",
        "path": "Ia Nan, Đức Cơ, Gia Lai",
        "path_with_type": "Xã Ia Nan, Huyện Đức Cơ, Tỉnh Gia Lai",
        "code": "23884",
        "parent_code": "631"
    },
    "23887": {
        "name": "Chư Prông",
        "type": "thi-tran",
        "slug": "chu-prong",
        "name_with_type": "Thị trấn Chư Prông",
        "path": "Chư Prông, Chư Prông, Gia Lai",
        "path_with_type": "Thị trấn Chư Prông, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23887",
        "parent_code": "632"
    },
    "23888": {
        "name": "Ia Kly",
        "type": "xa",
        "slug": "ia-kly",
        "name_with_type": "Xã Ia Kly",
        "path": "Ia Kly, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Kly, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23888",
        "parent_code": "632"
    },
    "23890": {
        "name": "Bình Giáo",
        "type": "xa",
        "slug": "binh-giao",
        "name_with_type": "Xã Bình Giáo",
        "path": "Bình Giáo, Chư Prông, Gia Lai",
        "path_with_type": "Xã Bình Giáo, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23890",
        "parent_code": "632"
    },
    "23893": {
        "name": "Ia Drăng",
        "type": "xa",
        "slug": "ia-drang",
        "name_with_type": "Xã Ia Drăng",
        "path": "Ia Drăng, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Drăng, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23893",
        "parent_code": "632"
    },
    "23896": {
        "name": "Thăng Hưng",
        "type": "xa",
        "slug": "thang-hung",
        "name_with_type": "Xã Thăng Hưng",
        "path": "Thăng Hưng, Chư Prông, Gia Lai",
        "path_with_type": "Xã Thăng Hưng, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23896",
        "parent_code": "632"
    },
    "23899": {
        "name": "Bàu Cạn",
        "type": "xa",
        "slug": "bau-can",
        "name_with_type": "Xã Bàu Cạn",
        "path": "Bàu Cạn, Chư Prông, Gia Lai",
        "path_with_type": "Xã Bàu Cạn, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23899",
        "parent_code": "632"
    },
    "23902": {
        "name": "Ia Phìn",
        "type": "xa",
        "slug": "ia-phin",
        "name_with_type": "Xã Ia Phìn",
        "path": "Ia Phìn, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Phìn, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23902",
        "parent_code": "632"
    },
    "23905": {
        "name": "Ia Băng",
        "type": "xa",
        "slug": "ia-bang",
        "name_with_type": "Xã Ia Băng",
        "path": "Ia Băng, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Băng, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23905",
        "parent_code": "632"
    },
    "23908": {
        "name": "Ia Tôr",
        "type": "xa",
        "slug": "ia-tor",
        "name_with_type": "Xã Ia Tôr",
        "path": "Ia Tôr, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Tôr, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23908",
        "parent_code": "632"
    },
    "23911": {
        "name": "Ia Boòng",
        "type": "xa",
        "slug": "ia-boong",
        "name_with_type": "Xã Ia Boòng",
        "path": "Ia Boòng, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Boòng, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23911",
        "parent_code": "632"
    },
    "23914": {
        "name": "Ia O",
        "type": "xa",
        "slug": "ia-o",
        "name_with_type": "Xã Ia O",
        "path": "Ia O, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia O, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23914",
        "parent_code": "632"
    },
    "23917": {
        "name": "Ia Púch",
        "type": "xa",
        "slug": "ia-puch",
        "name_with_type": "Xã Ia Púch",
        "path": "Ia Púch, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Púch, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23917",
        "parent_code": "632"
    },
    "23920": {
        "name": "Ia Me",
        "type": "xa",
        "slug": "ia-me",
        "name_with_type": "Xã Ia Me",
        "path": "Ia Me, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Me, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23920",
        "parent_code": "632"
    },
    "23923": {
        "name": "Ia Vê",
        "type": "xa",
        "slug": "ia-ve",
        "name_with_type": "Xã Ia Vê",
        "path": "Ia Vê, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Vê, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23923",
        "parent_code": "632"
    },
    "23924": {
        "name": "Ia Bang",
        "type": "xa",
        "slug": "ia-bang",
        "name_with_type": "Xã Ia Bang",
        "path": "Ia Bang, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Bang, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23924",
        "parent_code": "632"
    },
    "23926": {
        "name": "Ia Pia",
        "type": "xa",
        "slug": "ia-pia",
        "name_with_type": "Xã Ia Pia",
        "path": "Ia Pia, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Pia, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23926",
        "parent_code": "632"
    },
    "23929": {
        "name": "Ia Ga",
        "type": "xa",
        "slug": "ia-ga",
        "name_with_type": "Xã Ia Ga",
        "path": "Ia Ga, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Ga, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23929",
        "parent_code": "632"
    },
    "23932": {
        "name": "Ia Lâu",
        "type": "xa",
        "slug": "ia-lau",
        "name_with_type": "Xã Ia Lâu",
        "path": "Ia Lâu, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Lâu, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23932",
        "parent_code": "632"
    },
    "23935": {
        "name": "Ia Piơr",
        "type": "xa",
        "slug": "ia-pior",
        "name_with_type": "Xã Ia Piơr",
        "path": "Ia Piơr, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Piơr, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23935",
        "parent_code": "632"
    },
    "23938": {
        "name": "Ia Mơ",
        "type": "xa",
        "slug": "ia-mo",
        "name_with_type": "Xã Ia Mơ",
        "path": "Ia Mơ, Chư Prông, Gia Lai",
        "path_with_type": "Xã Ia Mơ, Huyện Chư Prông, Tỉnh Gia Lai",
        "code": "23938",
        "parent_code": "632"
    },
    "23941": {
        "name": "Chư Sê",
        "type": "thi-tran",
        "slug": "chu-se",
        "name_with_type": "Thị trấn Chư Sê",
        "path": "Chư Sê, Chư Sê, Gia Lai",
        "path_with_type": "Thị trấn Chư Sê, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23941",
        "parent_code": "633"
    },
    "23944": {
        "name": "Ia Tiêm",
        "type": "xa",
        "slug": "ia-tiem",
        "name_with_type": "Xã Ia Tiêm",
        "path": "Ia Tiêm, Chư Sê, Gia Lai",
        "path_with_type": "Xã Ia Tiêm, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23944",
        "parent_code": "633"
    },
    "23945": {
        "name": "Chư Pơng",
        "type": "xa",
        "slug": "chu-pong",
        "name_with_type": "Xã Chư Pơng",
        "path": "Chư Pơng, Chư Sê, Gia Lai",
        "path_with_type": "Xã Chư Pơng, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23945",
        "parent_code": "633"
    },
    "23946": {
        "name": "Bar Măih",
        "type": "xa",
        "slug": "bar-maih",
        "name_with_type": "Xã Bar Măih",
        "path": "Bar Măih, Chư Sê, Gia Lai",
        "path_with_type": "Xã Bar Măih, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23946",
        "parent_code": "633"
    },
    "23947": {
        "name": "Bờ Ngoong",
        "type": "xa",
        "slug": "bo-ngoong",
        "name_with_type": "Xã Bờ Ngoong",
        "path": "Bờ Ngoong, Chư Sê, Gia Lai",
        "path_with_type": "Xã Bờ Ngoong, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23947",
        "parent_code": "633"
    },
    "23950": {
        "name": "Ia Glai",
        "type": "xa",
        "slug": "ia-glai",
        "name_with_type": "Xã Ia Glai",
        "path": "Ia Glai, Chư Sê, Gia Lai",
        "path_with_type": "Xã Ia Glai, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23950",
        "parent_code": "633"
    },
    "23953": {
        "name": "AL Bá",
        "type": "xa",
        "slug": "al-ba",
        "name_with_type": "Xã AL Bá",
        "path": "AL Bá, Chư Sê, Gia Lai",
        "path_with_type": "Xã AL Bá, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23953",
        "parent_code": "633"
    },
    "23954": {
        "name": "Kông HTok",
        "type": "xa",
        "slug": "kong-htok",
        "name_with_type": "Xã Kông HTok",
        "path": "Kông HTok, Chư Sê, Gia Lai",
        "path_with_type": "Xã Kông HTok, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23954",
        "parent_code": "633"
    },
    "23956": {
        "name": "AYun",
        "type": "xa",
        "slug": "ayun",
        "name_with_type": "Xã AYun",
        "path": "AYun, Chư Sê, Gia Lai",
        "path_with_type": "Xã AYun, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23956",
        "parent_code": "633"
    },
    "23959": {
        "name": "Ia HLốp",
        "type": "xa",
        "slug": "ia-hlop",
        "name_with_type": "Xã Ia HLốp",
        "path": "Ia HLốp, Chư Sê, Gia Lai",
        "path_with_type": "Xã Ia HLốp, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23959",
        "parent_code": "633"
    },
    "23962": {
        "name": "Ia Blang",
        "type": "xa",
        "slug": "ia-blang",
        "name_with_type": "Xã Ia Blang",
        "path": "Ia Blang, Chư Sê, Gia Lai",
        "path_with_type": "Xã Ia Blang, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23962",
        "parent_code": "633"
    },
    "23965": {
        "name": "Dun",
        "type": "xa",
        "slug": "dun",
        "name_with_type": "Xã Dun",
        "path": "Dun, Chư Sê, Gia Lai",
        "path_with_type": "Xã Dun, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23965",
        "parent_code": "633"
    },
    "23966": {
        "name": "Ia Pal",
        "type": "xa",
        "slug": "ia-pal",
        "name_with_type": "Xã Ia Pal",
        "path": "Ia Pal, Chư Sê, Gia Lai",
        "path_with_type": "Xã Ia Pal, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23966",
        "parent_code": "633"
    },
    "23968": {
        "name": "H Bông",
        "type": "xa",
        "slug": "h-bong",
        "name_with_type": "Xã H Bông",
        "path": "H Bông, Chư Sê, Gia Lai",
        "path_with_type": "Xã H Bông, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23968",
        "parent_code": "633"
    },
    "23977": {
        "name": "Ia Ko",
        "type": "xa",
        "slug": "ia-ko",
        "name_with_type": "Xã Ia Ko",
        "path": "Ia Ko, Chư Sê, Gia Lai",
        "path_with_type": "Xã Ia Ko, Huyện Chư Sê, Tỉnh Gia Lai",
        "code": "23977",
        "parent_code": "633"
    },
    "23989": {
        "name": "Hà Tam",
        "type": "xa",
        "slug": "ha-tam",
        "name_with_type": "Xã Hà Tam",
        "path": "Hà Tam, Đăk Pơ, Gia Lai",
        "path_with_type": "Xã Hà Tam, Huyện Đăk Pơ, Tỉnh Gia Lai",
        "code": "23989",
        "parent_code": "634"
    },
    "23992": {
        "name": "An Thành",
        "type": "xa",
        "slug": "an-thanh",
        "name_with_type": "Xã An Thành",
        "path": "An Thành, Đăk Pơ, Gia Lai",
        "path_with_type": "Xã An Thành, Huyện Đăk Pơ, Tỉnh Gia Lai",
        "code": "23992",
        "parent_code": "634"
    },
    "23995": {
        "name": "Đak Pơ",
        "type": "thi-tran",
        "slug": "dak-po",
        "name_with_type": "Thị trấn Đak Pơ",
        "path": "Đak Pơ, Đăk Pơ, Gia Lai",
        "path_with_type": "Thị trấn Đak Pơ, Huyện Đăk Pơ, Tỉnh Gia Lai",
        "code": "23995",
        "parent_code": "634"
    },
    "23998": {
        "name": "Yang Bắc",
        "type": "xa",
        "slug": "yang-bac",
        "name_with_type": "Xã Yang Bắc",
        "path": "Yang Bắc, Đăk Pơ, Gia Lai",
        "path_with_type": "Xã Yang Bắc, Huyện Đăk Pơ, Tỉnh Gia Lai",
        "code": "23998",
        "parent_code": "634"
    },
    "24001": {
        "name": "Cư An",
        "type": "xa",
        "slug": "cu-an",
        "name_with_type": "Xã Cư An",
        "path": "Cư An, Đăk Pơ, Gia Lai",
        "path_with_type": "Xã Cư An, Huyện Đăk Pơ, Tỉnh Gia Lai",
        "code": "24001",
        "parent_code": "634"
    },
    "24004": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Đăk Pơ, Gia Lai",
        "path_with_type": "Xã Tân An, Huyện Đăk Pơ, Tỉnh Gia Lai",
        "code": "24004",
        "parent_code": "634"
    },
    "24007": {
        "name": "Phú An",
        "type": "xa",
        "slug": "phu-an",
        "name_with_type": "Xã Phú An",
        "path": "Phú An, Đăk Pơ, Gia Lai",
        "path_with_type": "Xã Phú An, Huyện Đăk Pơ, Tỉnh Gia Lai",
        "code": "24007",
        "parent_code": "634"
    },
    "24010": {
        "name": "Ya Hội",
        "type": "xa",
        "slug": "ya-hoi",
        "name_with_type": "Xã Ya Hội",
        "path": "Ya Hội, Đăk Pơ, Gia Lai",
        "path_with_type": "Xã Ya Hội, Huyện Đăk Pơ, Tỉnh Gia Lai",
        "code": "24010",
        "parent_code": "634"
    },
    "24013": {
        "name": "Pờ Tó",
        "type": "xa",
        "slug": "po-to",
        "name_with_type": "Xã Pờ Tó",
        "path": "Pờ Tó, Ia Pa, Gia Lai",
        "path_with_type": "Xã Pờ Tó, Huyện Ia Pa, Tỉnh Gia Lai",
        "code": "24013",
        "parent_code": "635"
    },
    "24016": {
        "name": "Chư Răng",
        "type": "xa",
        "slug": "chu-rang",
        "name_with_type": "Xã Chư Răng",
        "path": "Chư Răng, Ia Pa, Gia Lai",
        "path_with_type": "Xã Chư Răng, Huyện Ia Pa, Tỉnh Gia Lai",
        "code": "24016",
        "parent_code": "635"
    },
    "24019": {
        "name": "Ia KDăm",
        "type": "xa",
        "slug": "ia-kdam",
        "name_with_type": "Xã Ia KDăm",
        "path": "Ia KDăm, Ia Pa, Gia Lai",
        "path_with_type": "Xã Ia KDăm, Huyện Ia Pa, Tỉnh Gia Lai",
        "code": "24019",
        "parent_code": "635"
    },
    "24022": {
        "name": "Kim Tân",
        "type": "xa",
        "slug": "kim-tan",
        "name_with_type": "Xã Kim Tân",
        "path": "Kim Tân, Ia Pa, Gia Lai",
        "path_with_type": "Xã Kim Tân, Huyện Ia Pa, Tỉnh Gia Lai",
        "code": "24022",
        "parent_code": "635"
    },
    "24025": {
        "name": "Chư Mố",
        "type": "xa",
        "slug": "chu-mo",
        "name_with_type": "Xã Chư Mố",
        "path": "Chư Mố, Ia Pa, Gia Lai",
        "path_with_type": "Xã Chư Mố, Huyện Ia Pa, Tỉnh Gia Lai",
        "code": "24025",
        "parent_code": "635"
    },
    "24028": {
        "name": "Ia Tul",
        "type": "xa",
        "slug": "ia-tul",
        "name_with_type": "Xã Ia Tul",
        "path": "Ia Tul, Ia Pa, Gia Lai",
        "path_with_type": "Xã Ia Tul, Huyện Ia Pa, Tỉnh Gia Lai",
        "code": "24028",
        "parent_code": "635"
    },
    "24031": {
        "name": "Ia Ma Rơn",
        "type": "xa",
        "slug": "ia-ma-ron",
        "name_with_type": "Xã Ia Ma Rơn",
        "path": "Ia Ma Rơn, Ia Pa, Gia Lai",
        "path_with_type": "Xã Ia Ma Rơn, Huyện Ia Pa, Tỉnh Gia Lai",
        "code": "24031",
        "parent_code": "635"
    },
    "24034": {
        "name": "Ia Broăi",
        "type": "xa",
        "slug": "ia-broai",
        "name_with_type": "Xã Ia Broăi",
        "path": "Ia Broăi, Ia Pa, Gia Lai",
        "path_with_type": "Xã Ia Broăi, Huyện Ia Pa, Tỉnh Gia Lai",
        "code": "24034",
        "parent_code": "635"
    },
    "24037": {
        "name": "Ia Trok",
        "type": "xa",
        "slug": "ia-trok",
        "name_with_type": "Xã Ia Trok",
        "path": "Ia Trok, Ia Pa, Gia Lai",
        "path_with_type": "Xã Ia Trok, Huyện Ia Pa, Tỉnh Gia Lai",
        "code": "24037",
        "parent_code": "635"
    },
    "24076": {
        "name": "Phú Túc",
        "type": "thi-tran",
        "slug": "phu-tuc",
        "name_with_type": "Thị trấn Phú Túc",
        "path": "Phú Túc, Krông Pa, Gia Lai",
        "path_with_type": "Thị trấn Phú Túc, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24076",
        "parent_code": "637"
    },
    "24079": {
        "name": "Ia RSai",
        "type": "xa",
        "slug": "ia-rsai",
        "name_with_type": "Xã Ia RSai",
        "path": "Ia RSai, Krông Pa, Gia Lai",
        "path_with_type": "Xã Ia RSai, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24079",
        "parent_code": "637"
    },
    "24082": {
        "name": "Ia RSươm",
        "type": "xa",
        "slug": "ia-rsuom",
        "name_with_type": "Xã Ia RSươm",
        "path": "Ia RSươm, Krông Pa, Gia Lai",
        "path_with_type": "Xã Ia RSươm, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24082",
        "parent_code": "637"
    },
    "24085": {
        "name": "Chư Gu",
        "type": "xa",
        "slug": "chu-gu",
        "name_with_type": "Xã Chư Gu",
        "path": "Chư Gu, Krông Pa, Gia Lai",
        "path_with_type": "Xã Chư Gu, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24085",
        "parent_code": "637"
    },
    "24088": {
        "name": "Đất Bằng",
        "type": "xa",
        "slug": "dat-bang",
        "name_with_type": "Xã Đất Bằng",
        "path": "Đất Bằng, Krông Pa, Gia Lai",
        "path_with_type": "Xã Đất Bằng, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24088",
        "parent_code": "637"
    },
    "24091": {
        "name": "Ia Mláh",
        "type": "xa",
        "slug": "ia-mlah",
        "name_with_type": "Xã Ia Mláh",
        "path": "Ia Mláh, Krông Pa, Gia Lai",
        "path_with_type": "Xã Ia Mláh, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24091",
        "parent_code": "637"
    },
    "24094": {
        "name": "Chư Drăng",
        "type": "xa",
        "slug": "chu-drang",
        "name_with_type": "Xã Chư Drăng",
        "path": "Chư Drăng, Krông Pa, Gia Lai",
        "path_with_type": "Xã Chư Drăng, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24094",
        "parent_code": "637"
    },
    "24097": {
        "name": "Phú Cần",
        "type": "xa",
        "slug": "phu-can",
        "name_with_type": "Xã Phú Cần",
        "path": "Phú Cần, Krông Pa, Gia Lai",
        "path_with_type": "Xã Phú Cần, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24097",
        "parent_code": "637"
    },
    "24100": {
        "name": "Ia HDreh",
        "type": "xa",
        "slug": "ia-hdreh",
        "name_with_type": "Xã Ia HDreh",
        "path": "Ia HDreh, Krông Pa, Gia Lai",
        "path_with_type": "Xã Ia HDreh, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24100",
        "parent_code": "637"
    },
    "24103": {
        "name": "Ia RMok",
        "type": "xa",
        "slug": "ia-rmok",
        "name_with_type": "Xã Ia RMok",
        "path": "Ia RMok, Krông Pa, Gia Lai",
        "path_with_type": "Xã Ia RMok, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24103",
        "parent_code": "637"
    },
    "24106": {
        "name": "Chư Ngọc",
        "type": "xa",
        "slug": "chu-ngoc",
        "name_with_type": "Xã Chư Ngọc",
        "path": "Chư Ngọc, Krông Pa, Gia Lai",
        "path_with_type": "Xã Chư Ngọc, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24106",
        "parent_code": "637"
    },
    "24109": {
        "name": "Uar",
        "type": "xa",
        "slug": "uar",
        "name_with_type": "Xã Uar",
        "path": "Uar, Krông Pa, Gia Lai",
        "path_with_type": "Xã Uar, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24109",
        "parent_code": "637"
    },
    "24112": {
        "name": "Chư Rcăm",
        "type": "xa",
        "slug": "chu-rcam",
        "name_with_type": "Xã Chư Rcăm",
        "path": "Chư Rcăm, Krông Pa, Gia Lai",
        "path_with_type": "Xã Chư Rcăm, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24112",
        "parent_code": "637"
    },
    "24115": {
        "name": "Krông Năng",
        "type": "xa",
        "slug": "krong-nang",
        "name_with_type": "Xã Krông Năng",
        "path": "Krông Năng, Krông Pa, Gia Lai",
        "path_with_type": "Xã Krông Năng, Huyện Krông Pa, Tỉnh Gia Lai",
        "code": "24115",
        "parent_code": "637"
    },
    "24043": {
        "name": "Phú Thiện",
        "type": "thi-tran",
        "slug": "phu-thien",
        "name_with_type": "Thị trấn Phú Thiện",
        "path": "Phú Thiện, Phú Thiện, Gia Lai",
        "path_with_type": "Thị trấn Phú Thiện, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24043",
        "parent_code": "638"
    },
    "24046": {
        "name": "Chư A Thai",
        "type": "xa",
        "slug": "chu-a-thai",
        "name_with_type": "Xã Chư A Thai",
        "path": "Chư A Thai, Phú Thiện, Gia Lai",
        "path_with_type": "Xã Chư A Thai, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24046",
        "parent_code": "638"
    },
    "24048": {
        "name": "Ayun Hạ",
        "type": "xa",
        "slug": "ayun-ha",
        "name_with_type": "Xã Ayun Hạ",
        "path": "Ayun Hạ, Phú Thiện, Gia Lai",
        "path_with_type": "Xã Ayun Hạ, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24048",
        "parent_code": "638"
    },
    "24049": {
        "name": "Ia Ake",
        "type": "xa",
        "slug": "ia-ake",
        "name_with_type": "Xã Ia Ake",
        "path": "Ia Ake, Phú Thiện, Gia Lai",
        "path_with_type": "Xã Ia Ake, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24049",
        "parent_code": "638"
    },
    "24052": {
        "name": "Ia Sol",
        "type": "xa",
        "slug": "ia-sol",
        "name_with_type": "Xã Ia Sol",
        "path": "Ia Sol, Phú Thiện, Gia Lai",
        "path_with_type": "Xã Ia Sol, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24052",
        "parent_code": "638"
    },
    "24055": {
        "name": "Ia Piar",
        "type": "xa",
        "slug": "ia-piar",
        "name_with_type": "Xã Ia Piar",
        "path": "Ia Piar, Phú Thiện, Gia Lai",
        "path_with_type": "Xã Ia Piar, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24055",
        "parent_code": "638"
    },
    "24058": {
        "name": "Ia Peng",
        "type": "xa",
        "slug": "ia-peng",
        "name_with_type": "Xã Ia Peng",
        "path": "Ia Peng, Phú Thiện, Gia Lai",
        "path_with_type": "Xã Ia Peng, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24058",
        "parent_code": "638"
    },
    "24060": {
        "name": "Chrôh Pơnan",
        "type": "xa",
        "slug": "chroh-ponan",
        "name_with_type": "Xã Chrôh Pơnan",
        "path": "Chrôh Pơnan, Phú Thiện, Gia Lai",
        "path_with_type": "Xã Chrôh Pơnan, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24060",
        "parent_code": "638"
    },
    "24061": {
        "name": "Ia Hiao",
        "type": "xa",
        "slug": "ia-hiao",
        "name_with_type": "Xã Ia Hiao",
        "path": "Ia Hiao, Phú Thiện, Gia Lai",
        "path_with_type": "Xã Ia Hiao, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24061",
        "parent_code": "638"
    },
    "24067": {
        "name": "Ia Yeng",
        "type": "xa",
        "slug": "ia-yeng",
        "name_with_type": "Xã Ia Yeng",
        "path": "Ia Yeng, Phú Thiện, Gia Lai",
        "path_with_type": "Xã Ia Yeng, Huyện Phú Thiện, Tỉnh Gia Lai",
        "code": "24067",
        "parent_code": "638"
    },
    "23942": {
        "name": "Nhơn Hoà",
        "type": "thi-tran",
        "slug": "nhon-hoa",
        "name_with_type": "Thị trấn Nhơn Hoà",
        "path": "Nhơn Hoà, Chư Pưh, Gia Lai",
        "path_with_type": "Thị trấn Nhơn Hoà, Huyện Chư Pưh, Tỉnh Gia Lai",
        "code": "23942",
        "parent_code": "639"
    },
    "23971": {
        "name": "Ia Hrú",
        "type": "xa",
        "slug": "ia-hru",
        "name_with_type": "Xã Ia Hrú",
        "path": "Ia Hrú, Chư Pưh, Gia Lai",
        "path_with_type": "Xã Ia Hrú, Huyện Chư Pưh, Tỉnh Gia Lai",
        "code": "23971",
        "parent_code": "639"
    },
    "23972": {
        "name": "Ia Rong",
        "type": "xa",
        "slug": "ia-rong",
        "name_with_type": "Xã Ia Rong",
        "path": "Ia Rong, Chư Pưh, Gia Lai",
        "path_with_type": "Xã Ia Rong, Huyện Chư Pưh, Tỉnh Gia Lai",
        "code": "23972",
        "parent_code": "639"
    },
    "23974": {
        "name": "Ia Dreng",
        "type": "xa",
        "slug": "ia-dreng",
        "name_with_type": "Xã Ia Dreng",
        "path": "Ia Dreng, Chư Pưh, Gia Lai",
        "path_with_type": "Xã Ia Dreng, Huyện Chư Pưh, Tỉnh Gia Lai",
        "code": "23974",
        "parent_code": "639"
    },
    "23978": {
        "name": "Ia Hla",
        "type": "xa",
        "slug": "ia-hla",
        "name_with_type": "Xã Ia Hla",
        "path": "Ia Hla, Chư Pưh, Gia Lai",
        "path_with_type": "Xã Ia Hla, Huyện Chư Pưh, Tỉnh Gia Lai",
        "code": "23978",
        "parent_code": "639"
    },
    "23980": {
        "name": "Chư Don",
        "type": "xa",
        "slug": "chu-don",
        "name_with_type": "Xã Chư Don",
        "path": "Chư Don, Chư Pưh, Gia Lai",
        "path_with_type": "Xã Chư Don, Huyện Chư Pưh, Tỉnh Gia Lai",
        "code": "23980",
        "parent_code": "639"
    },
    "23983": {
        "name": "Ia Phang",
        "type": "xa",
        "slug": "ia-phang",
        "name_with_type": "Xã Ia Phang",
        "path": "Ia Phang, Chư Pưh, Gia Lai",
        "path_with_type": "Xã Ia Phang, Huyện Chư Pưh, Tỉnh Gia Lai",
        "code": "23983",
        "parent_code": "639"
    },
    "23986": {
        "name": "Ia Le",
        "type": "xa",
        "slug": "ia-le",
        "name_with_type": "Xã Ia Le",
        "path": "Ia Le, Chư Pưh, Gia Lai",
        "path_with_type": "Xã Ia Le, Huyện Chư Pưh, Tỉnh Gia Lai",
        "code": "23986",
        "parent_code": "639"
    },
    "23987": {
        "name": "Ia BLứ",
        "type": "xa",
        "slug": "ia-blu",
        "name_with_type": "Xã Ia BLứ",
        "path": "Ia BLứ, Chư Pưh, Gia Lai",
        "path_with_type": "Xã Ia BLứ, Huyện Chư Pưh, Tỉnh Gia Lai",
        "code": "23987",
        "parent_code": "639"
    },
    "04789": {
        "name": "Thái Bình",
        "type": "phuong",
        "slug": "thai-binh",
        "name_with_type": "Phường Thái Bình",
        "path": "Thái Bình, Hòa Bình, Hoà Bình",
        "path_with_type": "Phường Thái Bình, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04789",
        "parent_code": "148"
    },
    "04792": {
        "name": "Tân Hòa",
        "type": "phuong",
        "slug": "tan-hoa",
        "name_with_type": "Phường Tân Hòa",
        "path": "Tân Hòa, Hòa Bình, Hoà Bình",
        "path_with_type": "Phường Tân Hòa, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04792",
        "parent_code": "148"
    },
    "04795": {
        "name": "Thịnh Lang",
        "type": "phuong",
        "slug": "thinh-lang",
        "name_with_type": "Phường Thịnh Lang",
        "path": "Thịnh Lang, Hòa Bình, Hoà Bình",
        "path_with_type": "Phường Thịnh Lang, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04795",
        "parent_code": "148"
    },
    "04798": {
        "name": "Hữu Nghị",
        "type": "phuong",
        "slug": "huu-nghi",
        "name_with_type": "Phường Hữu Nghị",
        "path": "Hữu Nghị, Hòa Bình, Hoà Bình",
        "path_with_type": "Phường Hữu Nghị, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04798",
        "parent_code": "148"
    },
    "04801": {
        "name": "Tân Thịnh",
        "type": "phuong",
        "slug": "tan-thinh",
        "name_with_type": "Phường Tân Thịnh",
        "path": "Tân Thịnh, Hòa Bình, Hoà Bình",
        "path_with_type": "Phường Tân Thịnh, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04801",
        "parent_code": "148"
    },
    "04804": {
        "name": "Đồng Tiến",
        "type": "phuong",
        "slug": "dong-tien",
        "name_with_type": "Phường Đồng Tiến",
        "path": "Đồng Tiến, Hòa Bình, Hoà Bình",
        "path_with_type": "Phường Đồng Tiến, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04804",
        "parent_code": "148"
    },
    "04807": {
        "name": "Phương Lâm",
        "type": "phuong",
        "slug": "phuong-lam",
        "name_with_type": "Phường Phương Lâm",
        "path": "Phương Lâm, Hòa Bình, Hoà Bình",
        "path_with_type": "Phường Phương Lâm, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04807",
        "parent_code": "148"
    },
    "04810": {
        "name": "Chăm Mát",
        "type": "phuong",
        "slug": "cham-mat",
        "name_with_type": "Phường Chăm Mát",
        "path": "Chăm Mát, Hòa Bình, Hoà Bình",
        "path_with_type": "Phường Chăm Mát, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04810",
        "parent_code": "148"
    },
    "04813": {
        "name": "Yên Mông",
        "type": "xa",
        "slug": "yen-mong",
        "name_with_type": "Xã Yên Mông",
        "path": "Yên Mông, Hòa Bình, Hoà Bình",
        "path_with_type": "Xã Yên Mông, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04813",
        "parent_code": "148"
    },
    "04816": {
        "name": "Sủ Ngòi",
        "type": "xa",
        "slug": "su-ngoi",
        "name_with_type": "Xã Sủ Ngòi",
        "path": "Sủ Ngòi, Hòa Bình, Hoà Bình",
        "path_with_type": "Xã Sủ Ngòi, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04816",
        "parent_code": "148"
    },
    "04819": {
        "name": "Dân Chủ",
        "type": "xa",
        "slug": "dan-chu",
        "name_with_type": "Xã Dân Chủ",
        "path": "Dân Chủ, Hòa Bình, Hoà Bình",
        "path_with_type": "Xã Dân Chủ, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04819",
        "parent_code": "148"
    },
    "04822": {
        "name": "Thái Thịnh",
        "type": "xa",
        "slug": "thai-thinh",
        "name_with_type": "Xã Thái Thịnh",
        "path": "Thái Thịnh, Hòa Bình, Hoà Bình",
        "path_with_type": "Xã Thái Thịnh, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04822",
        "parent_code": "148"
    },
    "04825": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Hòa Bình, Hoà Bình",
        "path_with_type": "Xã Hòa Bình, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04825",
        "parent_code": "148"
    },
    "04828": {
        "name": "Thống Nhất",
        "type": "xa",
        "slug": "thong-nhat",
        "name_with_type": "Xã Thống Nhất",
        "path": "Thống Nhất, Hòa Bình, Hoà Bình",
        "path_with_type": "Xã Thống Nhất, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04828",
        "parent_code": "148"
    },
    "04918": {
        "name": "Trung Minh",
        "type": "xa",
        "slug": "trung-minh",
        "name_with_type": "Xã Trung Minh",
        "path": "Trung Minh, Hòa Bình, Hoà Bình",
        "path_with_type": "Xã Trung Minh, Thành phố Hòa Bình, Tỉnh Hoà Bình",
        "code": "04918",
        "parent_code": "148"
    },
    "04831": {
        "name": "Đà Bắc",
        "type": "thi-tran",
        "slug": "da-bac",
        "name_with_type": "Thị trấn Đà Bắc",
        "path": "Đà Bắc, Đà Bắc, Hoà Bình",
        "path_with_type": "Thị trấn Đà Bắc, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04831",
        "parent_code": "150"
    },
    "04834": {
        "name": "Đồng Nghê",
        "type": "xa",
        "slug": "dong-nghe",
        "name_with_type": "Xã Đồng Nghê",
        "path": "Đồng Nghê, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Đồng Nghê, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04834",
        "parent_code": "150"
    },
    "04837": {
        "name": "Suối Nánh",
        "type": "xa",
        "slug": "suoi-nanh",
        "name_with_type": "Xã Suối Nánh",
        "path": "Suối Nánh, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Suối Nánh, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04837",
        "parent_code": "150"
    },
    "04840": {
        "name": "Giáp Đắt",
        "type": "xa",
        "slug": "giap-dat",
        "name_with_type": "Xã Giáp Đắt",
        "path": "Giáp Đắt, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Giáp Đắt, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04840",
        "parent_code": "150"
    },
    "04843": {
        "name": "Mường Tuổng",
        "type": "xa",
        "slug": "muong-tuong",
        "name_with_type": "Xã Mường Tuổng",
        "path": "Mường Tuổng, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Mường Tuổng, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04843",
        "parent_code": "150"
    },
    "04846": {
        "name": "Mường Chiềng",
        "type": "xa",
        "slug": "muong-chieng",
        "name_with_type": "Xã Mường Chiềng",
        "path": "Mường Chiềng, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Mường Chiềng, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04846",
        "parent_code": "150"
    },
    "04849": {
        "name": "Tân Pheo",
        "type": "xa",
        "slug": "tan-pheo",
        "name_with_type": "Xã Tân Pheo",
        "path": "Tân Pheo, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Tân Pheo, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04849",
        "parent_code": "150"
    },
    "04852": {
        "name": "Đồng Chum",
        "type": "xa",
        "slug": "dong-chum",
        "name_with_type": "Xã Đồng Chum",
        "path": "Đồng Chum, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Đồng Chum, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04852",
        "parent_code": "150"
    },
    "04855": {
        "name": "Tân Minh",
        "type": "xa",
        "slug": "tan-minh",
        "name_with_type": "Xã Tân Minh",
        "path": "Tân Minh, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Tân Minh, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04855",
        "parent_code": "150"
    },
    "04858": {
        "name": "Đoàn Kết",
        "type": "xa",
        "slug": "doan-ket",
        "name_with_type": "Xã Đoàn Kết",
        "path": "Đoàn Kết, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Đoàn Kết, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04858",
        "parent_code": "150"
    },
    "04861": {
        "name": "Đồng Ruộng",
        "type": "xa",
        "slug": "dong-ruong",
        "name_with_type": "Xã Đồng Ruộng",
        "path": "Đồng Ruộng, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Đồng Ruộng, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04861",
        "parent_code": "150"
    },
    "04864": {
        "name": "Hào Lý",
        "type": "xa",
        "slug": "hao-ly",
        "name_with_type": "Xã Hào Lý",
        "path": "Hào Lý, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Hào Lý, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04864",
        "parent_code": "150"
    },
    "04867": {
        "name": "Tu Lý",
        "type": "xa",
        "slug": "tu-ly",
        "name_with_type": "Xã Tu Lý",
        "path": "Tu Lý, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Tu Lý, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04867",
        "parent_code": "150"
    },
    "04870": {
        "name": "Trung Thành",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thành",
        "path": "Trung Thành, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Trung Thành, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04870",
        "parent_code": "150"
    },
    "04873": {
        "name": "Yên Hòa",
        "type": "xa",
        "slug": "yen-hoa",
        "name_with_type": "Xã Yên Hòa",
        "path": "Yên Hòa, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Yên Hòa, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04873",
        "parent_code": "150"
    },
    "04876": {
        "name": "Cao Sơn",
        "type": "xa",
        "slug": "cao-son",
        "name_with_type": "Xã Cao Sơn",
        "path": "Cao Sơn, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Cao Sơn, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04876",
        "parent_code": "150"
    },
    "04879": {
        "name": "Toàn Sơn",
        "type": "xa",
        "slug": "toan-son",
        "name_with_type": "Xã Toàn Sơn",
        "path": "Toàn Sơn, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Toàn Sơn, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04879",
        "parent_code": "150"
    },
    "04885": {
        "name": "Hiền Lương",
        "type": "xa",
        "slug": "hien-luong",
        "name_with_type": "Xã Hiền Lương",
        "path": "Hiền Lương, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Hiền Lương, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04885",
        "parent_code": "150"
    },
    "04888": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Tiền Phong, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04888",
        "parent_code": "150"
    },
    "04891": {
        "name": "Vầy Nưa",
        "type": "xa",
        "slug": "vay-nua",
        "name_with_type": "Xã Vầy Nưa",
        "path": "Vầy Nưa, Đà Bắc, Hoà Bình",
        "path_with_type": "Xã Vầy Nưa, Huyện Đà Bắc, Tỉnh Hoà Bình",
        "code": "04891",
        "parent_code": "150"
    },
    "04894": {
        "name": "Kỳ Sơn",
        "type": "thi-tran",
        "slug": "ky-son",
        "name_with_type": "Thị trấn Kỳ Sơn",
        "path": "Kỳ Sơn, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Thị trấn Kỳ Sơn, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04894",
        "parent_code": "151"
    },
    "04897": {
        "name": "Hợp Thịnh",
        "type": "xa",
        "slug": "hop-thinh",
        "name_with_type": "Xã Hợp Thịnh",
        "path": "Hợp Thịnh, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Xã Hợp Thịnh, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04897",
        "parent_code": "151"
    },
    "04900": {
        "name": "Phú Minh",
        "type": "xa",
        "slug": "phu-minh",
        "name_with_type": "Xã Phú Minh",
        "path": "Phú Minh, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Xã Phú Minh, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04900",
        "parent_code": "151"
    },
    "04903": {
        "name": "Hợp Thành",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thành",
        "path": "Hợp Thành, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Xã Hợp Thành, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04903",
        "parent_code": "151"
    },
    "04906": {
        "name": "Phúc Tiến",
        "type": "xa",
        "slug": "phuc-tien",
        "name_with_type": "Xã Phúc Tiến",
        "path": "Phúc Tiến, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Xã Phúc Tiến, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04906",
        "parent_code": "151"
    },
    "04909": {
        "name": "Dân Hòa",
        "type": "xa",
        "slug": "dan-hoa",
        "name_with_type": "Xã Dân Hòa",
        "path": "Dân Hòa, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Xã Dân Hòa, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04909",
        "parent_code": "151"
    },
    "04912": {
        "name": "Mông Hóa",
        "type": "xa",
        "slug": "mong-hoa",
        "name_with_type": "Xã Mông Hóa",
        "path": "Mông Hóa, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Xã Mông Hóa, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04912",
        "parent_code": "151"
    },
    "04915": {
        "name": "Dân Hạ",
        "type": "xa",
        "slug": "dan-ha",
        "name_with_type": "Xã Dân Hạ",
        "path": "Dân Hạ, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Xã Dân Hạ, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04915",
        "parent_code": "151"
    },
    "04921": {
        "name": "Độc Lập",
        "type": "xa",
        "slug": "doc-lap",
        "name_with_type": "Xã Độc Lập",
        "path": "Độc Lập, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Xã Độc Lập, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04921",
        "parent_code": "151"
    },
    "04933": {
        "name": "Yên Quang",
        "type": "xa",
        "slug": "yen-quang",
        "name_with_type": "Xã Yên Quang",
        "path": "Yên Quang, Kỳ Sơn, Hoà Bình",
        "path_with_type": "Xã Yên Quang, Huyện Kỳ Sơn, Tỉnh Hoà Bình",
        "code": "04933",
        "parent_code": "151"
    },
    "04924": {
        "name": "Lương Sơn",
        "type": "thi-tran",
        "slug": "luong-son",
        "name_with_type": "Thị trấn Lương Sơn",
        "path": "Lương Sơn, Lương Sơn, Hoà Bình",
        "path_with_type": "Thị trấn Lương Sơn, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04924",
        "parent_code": "152"
    },
    "04942": {
        "name": "Lâm Sơn",
        "type": "xa",
        "slug": "lam-son",
        "name_with_type": "Xã Lâm Sơn",
        "path": "Lâm Sơn, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Lâm Sơn, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04942",
        "parent_code": "152"
    },
    "04945": {
        "name": "Hòa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hòa Sơn",
        "path": "Hòa Sơn, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Hòa Sơn, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04945",
        "parent_code": "152"
    },
    "04948": {
        "name": "Trường Sơn",
        "type": "xa",
        "slug": "truong-son",
        "name_with_type": "Xã Trường Sơn",
        "path": "Trường Sơn, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Trường Sơn, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04948",
        "parent_code": "152"
    },
    "04951": {
        "name": "Tân Vinh",
        "type": "xa",
        "slug": "tan-vinh",
        "name_with_type": "Xã Tân Vinh",
        "path": "Tân Vinh, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Tân Vinh, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04951",
        "parent_code": "152"
    },
    "04954": {
        "name": "Nhuận Trạch",
        "type": "xa",
        "slug": "nhuan-trach",
        "name_with_type": "Xã Nhuận Trạch",
        "path": "Nhuận Trạch, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Nhuận Trạch, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04954",
        "parent_code": "152"
    },
    "04957": {
        "name": "Cao Răm",
        "type": "xa",
        "slug": "cao-ram",
        "name_with_type": "Xã Cao Răm",
        "path": "Cao Răm, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Cao Răm, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04957",
        "parent_code": "152"
    },
    "04960": {
        "name": "Cư Yên",
        "type": "xa",
        "slug": "cu-yen",
        "name_with_type": "Xã Cư Yên",
        "path": "Cư Yên, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Cư Yên, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04960",
        "parent_code": "152"
    },
    "04963": {
        "name": "Hợp Hòa",
        "type": "xa",
        "slug": "hop-hoa",
        "name_with_type": "Xã Hợp Hòa",
        "path": "Hợp Hòa, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Hợp Hòa, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04963",
        "parent_code": "152"
    },
    "04966": {
        "name": "Liên Sơn",
        "type": "xa",
        "slug": "lien-son",
        "name_with_type": "Xã Liên Sơn",
        "path": "Liên Sơn, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Liên Sơn, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04966",
        "parent_code": "152"
    },
    "04969": {
        "name": "Thành Lập",
        "type": "xa",
        "slug": "thanh-lap",
        "name_with_type": "Xã Thành Lập",
        "path": "Thành Lập, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Thành Lập, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04969",
        "parent_code": "152"
    },
    "04972": {
        "name": "Tiến Sơn",
        "type": "xa",
        "slug": "tien-son",
        "name_with_type": "Xã Tiến Sơn",
        "path": "Tiến Sơn, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Tiến Sơn, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04972",
        "parent_code": "152"
    },
    "04975": {
        "name": "Trung Sơn",
        "type": "xa",
        "slug": "trung-son",
        "name_with_type": "Xã Trung Sơn",
        "path": "Trung Sơn, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Trung Sơn, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04975",
        "parent_code": "152"
    },
    "04996": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Tân Thành, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "04996",
        "parent_code": "152"
    },
    "05008": {
        "name": "Cao Dương",
        "type": "xa",
        "slug": "cao-duong",
        "name_with_type": "Xã Cao Dương",
        "path": "Cao Dương, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Cao Dương, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "05008",
        "parent_code": "152"
    },
    "05023": {
        "name": "Hợp Châu",
        "type": "xa",
        "slug": "hop-chau",
        "name_with_type": "Xã Hợp Châu",
        "path": "Hợp Châu, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Hợp Châu, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "05023",
        "parent_code": "152"
    },
    "05041": {
        "name": "Long Sơn",
        "type": "xa",
        "slug": "long-son",
        "name_with_type": "Xã Long Sơn",
        "path": "Long Sơn, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Long Sơn, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "05041",
        "parent_code": "152"
    },
    "05047": {
        "name": "Cao Thắng",
        "type": "xa",
        "slug": "cao-thang",
        "name_with_type": "Xã Cao Thắng",
        "path": "Cao Thắng, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Cao Thắng, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "05047",
        "parent_code": "152"
    },
    "05059": {
        "name": "Thanh Lương",
        "type": "xa",
        "slug": "thanh-luong",
        "name_with_type": "Xã Thanh Lương",
        "path": "Thanh Lương, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Thanh Lương, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "05059",
        "parent_code": "152"
    },
    "05062": {
        "name": "Hợp Thanh",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thanh",
        "path": "Hợp Thanh, Lương Sơn, Hoà Bình",
        "path_with_type": "Xã Hợp Thanh, Huyện Lương Sơn, Tỉnh Hoà Bình",
        "code": "05062",
        "parent_code": "152"
    },
    "04978": {
        "name": "Bo",
        "type": "thi-tran",
        "slug": "bo",
        "name_with_type": "Thị trấn Bo",
        "path": "Bo, Kim Bôi, Hoà Bình",
        "path_with_type": "Thị trấn Bo, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "04978",
        "parent_code": "153"
    },
    "04984": {
        "name": "Đú Sáng",
        "type": "xa",
        "slug": "du-sang",
        "name_with_type": "Xã Đú Sáng",
        "path": "Đú Sáng, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Đú Sáng, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "04984",
        "parent_code": "153"
    },
    "04987": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã  Bắc Sơn",
        "path": "Bắc Sơn, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã  Bắc Sơn, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "04987",
        "parent_code": "153"
    },
    "04990": {
        "name": "Bình Sơn",
        "type": "xa",
        "slug": "binh-son",
        "name_with_type": "Xã Bình Sơn",
        "path": "Bình Sơn, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Bình Sơn, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "04990",
        "parent_code": "153"
    },
    "04993": {
        "name": "Hùng Tiến",
        "type": "xa",
        "slug": "hung-tien",
        "name_with_type": "Xã Hùng Tiến",
        "path": "Hùng Tiến, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Hùng Tiến, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "04993",
        "parent_code": "153"
    },
    "04999": {
        "name": "Tú Sơn",
        "type": "xa",
        "slug": "tu-son",
        "name_with_type": "Xã Tú Sơn",
        "path": "Tú Sơn, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Tú Sơn, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "04999",
        "parent_code": "153"
    },
    "05002": {
        "name": "Nật Sơn",
        "type": "xa",
        "slug": "nat-son",
        "name_with_type": "Xã Nật Sơn",
        "path": "Nật Sơn, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Nật Sơn, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05002",
        "parent_code": "153"
    },
    "05005": {
        "name": "Vĩnh Tiến",
        "type": "xa",
        "slug": "vinh-tien",
        "name_with_type": "Xã Vĩnh Tiến",
        "path": "Vĩnh Tiến, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Vĩnh Tiến, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05005",
        "parent_code": "153"
    },
    "05011": {
        "name": "Sơn Thủy",
        "type": "xa",
        "slug": "son-thuy",
        "name_with_type": "Xã Sơn Thủy",
        "path": "Sơn Thủy, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Sơn Thủy, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05011",
        "parent_code": "153"
    },
    "05014": {
        "name": "Đông Bắc",
        "type": "xa",
        "slug": "dong-bac",
        "name_with_type": "Xã Đông Bắc",
        "path": "Đông Bắc, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Đông Bắc, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05014",
        "parent_code": "153"
    },
    "05017": {
        "name": "Thượng Bì",
        "type": "xa",
        "slug": "thuong-bi",
        "name_with_type": "Xã Thượng Bì",
        "path": "Thượng Bì, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Thượng Bì, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05017",
        "parent_code": "153"
    },
    "05020": {
        "name": "Lập Chiệng",
        "type": "xa",
        "slug": "lap-chieng",
        "name_with_type": "Xã Lập Chiệng",
        "path": "Lập Chiệng, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Lập Chiệng, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05020",
        "parent_code": "153"
    },
    "05026": {
        "name": "Vĩnh Đồng",
        "type": "xa",
        "slug": "vinh-dong",
        "name_with_type": "Xã Vĩnh Đồng",
        "path": "Vĩnh Đồng, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Vĩnh Đồng, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05026",
        "parent_code": "153"
    },
    "05029": {
        "name": "Hạ Bì",
        "type": "xa",
        "slug": "ha-bi",
        "name_with_type": "Xã Hạ Bì",
        "path": "Hạ Bì, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Hạ Bì, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05029",
        "parent_code": "153"
    },
    "05032": {
        "name": "Trung Bì",
        "type": "xa",
        "slug": "trung-bi",
        "name_with_type": "Xã Trung Bì",
        "path": "Trung Bì, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Trung Bì, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05032",
        "parent_code": "153"
    },
    "05035": {
        "name": "Kim Sơn",
        "type": "xa",
        "slug": "kim-son",
        "name_with_type": "Xã Kim Sơn",
        "path": "Kim Sơn, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Kim Sơn, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05035",
        "parent_code": "153"
    },
    "05038": {
        "name": "Hợp Đồng",
        "type": "xa",
        "slug": "hop-dong",
        "name_with_type": "Xã Hợp Đồng",
        "path": "Hợp Đồng, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Hợp Đồng, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05038",
        "parent_code": "153"
    },
    "05044": {
        "name": "Thượng Tiến",
        "type": "xa",
        "slug": "thuong-tien",
        "name_with_type": "Xã Thượng Tiến",
        "path": "Thượng Tiến, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Thượng Tiến, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05044",
        "parent_code": "153"
    },
    "05050": {
        "name": "Kim Tiến",
        "type": "xa",
        "slug": "kim-tien",
        "name_with_type": "Xã Kim Tiến",
        "path": "Kim Tiến, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Kim Tiến, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05050",
        "parent_code": "153"
    },
    "05053": {
        "name": "Kim Bình",
        "type": "xa",
        "slug": "kim-binh",
        "name_with_type": "Xã Kim Bình",
        "path": "Kim Bình, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Kim Bình, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05053",
        "parent_code": "153"
    },
    "05056": {
        "name": "Hợp Kim",
        "type": "xa",
        "slug": "hop-kim",
        "name_with_type": "Xã Hợp Kim",
        "path": "Hợp Kim, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Hợp Kim, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05056",
        "parent_code": "153"
    },
    "05065": {
        "name": "Kim Bôi",
        "type": "xa",
        "slug": "kim-boi",
        "name_with_type": "Xã Kim Bôi",
        "path": "Kim Bôi, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Kim Bôi, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05065",
        "parent_code": "153"
    },
    "05068": {
        "name": "Nam Thượng",
        "type": "xa",
        "slug": "nam-thuong",
        "name_with_type": "Xã Nam Thượng",
        "path": "Nam Thượng, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Nam Thượng, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05068",
        "parent_code": "153"
    },
    "05071": {
        "name": "Kim Truy",
        "type": "xa",
        "slug": "kim-truy",
        "name_with_type": "Xã Kim Truy",
        "path": "Kim Truy, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Kim Truy, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05071",
        "parent_code": "153"
    },
    "05077": {
        "name": "Cuối Hạ",
        "type": "xa",
        "slug": "cuoi-ha",
        "name_with_type": "Xã Cuối Hạ",
        "path": "Cuối Hạ, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Cuối Hạ, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05077",
        "parent_code": "153"
    },
    "05080": {
        "name": "Sào Báy",
        "type": "xa",
        "slug": "sao-bay",
        "name_with_type": "Xã Sào Báy",
        "path": "Sào Báy, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Sào Báy, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05080",
        "parent_code": "153"
    },
    "05083": {
        "name": "Mi Hòa",
        "type": "xa",
        "slug": "mi-hoa",
        "name_with_type": "Xã Mi Hòa",
        "path": "Mi Hòa, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Mi Hòa, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05083",
        "parent_code": "153"
    },
    "05086": {
        "name": "Nuông Dăm",
        "type": "xa",
        "slug": "nuong-dam",
        "name_with_type": "Xã Nuông Dăm",
        "path": "Nuông Dăm, Kim Bôi, Hoà Bình",
        "path_with_type": "Xã Nuông Dăm, Huyện Kim Bôi, Tỉnh Hoà Bình",
        "code": "05086",
        "parent_code": "153"
    },
    "05089": {
        "name": "Cao Phong",
        "type": "thi-tran",
        "slug": "cao-phong",
        "name_with_type": "Thị trấn Cao Phong",
        "path": "Cao Phong, Cao Phong, Hoà Bình",
        "path_with_type": "Thị trấn Cao Phong, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05089",
        "parent_code": "154"
    },
    "05092": {
        "name": "Bình Thanh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thanh",
        "path": "Bình Thanh, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Bình Thanh, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05092",
        "parent_code": "154"
    },
    "05095": {
        "name": "Thung Nai",
        "type": "xa",
        "slug": "thung-nai",
        "name_with_type": "Xã Thung Nai",
        "path": "Thung Nai, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Thung Nai, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05095",
        "parent_code": "154"
    },
    "05098": {
        "name": "Bắc Phong",
        "type": "xa",
        "slug": "bac-phong",
        "name_with_type": "Xã Bắc Phong",
        "path": "Bắc Phong, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Bắc Phong, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05098",
        "parent_code": "154"
    },
    "05101": {
        "name": "Thu Phong",
        "type": "xa",
        "slug": "thu-phong",
        "name_with_type": "Xã Thu Phong",
        "path": "Thu Phong, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Thu Phong, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05101",
        "parent_code": "154"
    },
    "05104": {
        "name": "Đông Phong",
        "type": "xa",
        "slug": "dong-phong",
        "name_with_type": "Xã Đông Phong",
        "path": "Đông Phong, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Đông Phong, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05104",
        "parent_code": "154"
    },
    "05107": {
        "name": "Xuân Phong",
        "type": "xa",
        "slug": "xuan-phong",
        "name_with_type": "Xã Xuân Phong",
        "path": "Xuân Phong, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Xuân Phong, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05107",
        "parent_code": "154"
    },
    "05110": {
        "name": "Tây Phong",
        "type": "xa",
        "slug": "tay-phong",
        "name_with_type": "Xã Tây Phong",
        "path": "Tây Phong, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Tây Phong, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05110",
        "parent_code": "154"
    },
    "05113": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Tân Phong, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05113",
        "parent_code": "154"
    },
    "05116": {
        "name": "Dũng Phong",
        "type": "xa",
        "slug": "dung-phong",
        "name_with_type": "Xã Dũng Phong",
        "path": "Dũng Phong, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Dũng Phong, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05116",
        "parent_code": "154"
    },
    "05119": {
        "name": "Nam Phong",
        "type": "xa",
        "slug": "nam-phong",
        "name_with_type": "Xã Nam Phong",
        "path": "Nam Phong, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Nam Phong, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05119",
        "parent_code": "154"
    },
    "05122": {
        "name": "Yên Lập",
        "type": "xa",
        "slug": "yen-lap",
        "name_with_type": "Xã Yên Lập",
        "path": "Yên Lập, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Yên Lập, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05122",
        "parent_code": "154"
    },
    "05125": {
        "name": "Yên Thượng",
        "type": "xa",
        "slug": "yen-thuong",
        "name_with_type": "Xã Yên Thượng",
        "path": "Yên Thượng, Cao Phong, Hoà Bình",
        "path_with_type": "Xã Yên Thượng, Huyện Cao Phong, Tỉnh Hoà Bình",
        "code": "05125",
        "parent_code": "154"
    },
    "05128": {
        "name": "Mường Khến",
        "type": "thi-tran",
        "slug": "muong-khen",
        "name_with_type": "Thị trấn Mường Khến",
        "path": "Mường Khến, Tân Lạc, Hoà Bình",
        "path_with_type": "Thị trấn Mường Khến, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05128",
        "parent_code": "155"
    },
    "05131": {
        "name": "Ngòi Hoa",
        "type": "xa",
        "slug": "ngoi-hoa",
        "name_with_type": "Xã Ngòi Hoa",
        "path": "Ngòi Hoa, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Ngòi Hoa, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05131",
        "parent_code": "155"
    },
    "05134": {
        "name": "Trung Hòa",
        "type": "xa",
        "slug": "trung-hoa",
        "name_with_type": "Xã Trung Hòa",
        "path": "Trung Hòa, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Trung Hòa, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05134",
        "parent_code": "155"
    },
    "05137": {
        "name": "Phú Vinh",
        "type": "xa",
        "slug": "phu-vinh",
        "name_with_type": "Xã Phú Vinh",
        "path": "Phú Vinh, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Phú Vinh, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05137",
        "parent_code": "155"
    },
    "05140": {
        "name": "Phú Cường",
        "type": "xa",
        "slug": "phu-cuong",
        "name_with_type": "Xã Phú Cường",
        "path": "Phú Cường, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Phú Cường, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05140",
        "parent_code": "155"
    },
    "05143": {
        "name": "Mỹ Hòa",
        "type": "xa",
        "slug": "my-hoa",
        "name_with_type": "Xã Mỹ Hòa",
        "path": "Mỹ Hòa, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Mỹ Hòa, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05143",
        "parent_code": "155"
    },
    "05146": {
        "name": "Quy Hậu",
        "type": "xa",
        "slug": "quy-hau",
        "name_with_type": "Xã Quy Hậu",
        "path": "Quy Hậu, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Quy Hậu, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05146",
        "parent_code": "155"
    },
    "05149": {
        "name": "Phong Phú",
        "type": "xa",
        "slug": "phong-phu",
        "name_with_type": "Xã Phong Phú",
        "path": "Phong Phú, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Phong Phú, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05149",
        "parent_code": "155"
    },
    "05152": {
        "name": "Quyết Chiến",
        "type": "xa",
        "slug": "quyet-chien",
        "name_with_type": "Xã Quyết Chiến",
        "path": "Quyết Chiến, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Quyết Chiến, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05152",
        "parent_code": "155"
    },
    "05155": {
        "name": "Mãn Đức",
        "type": "xa",
        "slug": "man-duc",
        "name_with_type": "Xã Mãn Đức",
        "path": "Mãn Đức, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Mãn Đức, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05155",
        "parent_code": "155"
    },
    "05158": {
        "name": "Địch Giáo",
        "type": "xa",
        "slug": "dich-giao",
        "name_with_type": "Xã Địch Giáo",
        "path": "Địch Giáo, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Địch Giáo, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05158",
        "parent_code": "155"
    },
    "05161": {
        "name": "Tuân Lộ",
        "type": "xa",
        "slug": "tuan-lo",
        "name_with_type": "Xã Tuân Lộ",
        "path": "Tuân Lộ, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Tuân Lộ, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05161",
        "parent_code": "155"
    },
    "05164": {
        "name": "Tử Nê",
        "type": "xa",
        "slug": "tu-ne",
        "name_with_type": "Xã Tử Nê",
        "path": "Tử Nê, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Tử Nê, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05164",
        "parent_code": "155"
    },
    "05167": {
        "name": "Thanh Hối",
        "type": "xa",
        "slug": "thanh-hoi",
        "name_with_type": "Xã Thanh Hối",
        "path": "Thanh Hối, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Thanh Hối, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05167",
        "parent_code": "155"
    },
    "05170": {
        "name": "Ngọc Mỹ",
        "type": "xa",
        "slug": "ngoc-my",
        "name_with_type": "Xã Ngọc Mỹ",
        "path": "Ngọc Mỹ, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Ngọc Mỹ, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05170",
        "parent_code": "155"
    },
    "05173": {
        "name": "Đông Lai",
        "type": "xa",
        "slug": "dong-lai",
        "name_with_type": "Xã Đông Lai",
        "path": "Đông Lai, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Đông Lai, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05173",
        "parent_code": "155"
    },
    "05176": {
        "name": "Lũng Vân",
        "type": "xa",
        "slug": "lung-van",
        "name_with_type": "Xã Lũng Vân",
        "path": "Lũng Vân, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Lũng Vân, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05176",
        "parent_code": "155"
    },
    "05179": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Bắc Sơn, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05179",
        "parent_code": "155"
    },
    "05182": {
        "name": "Quy Mỹ",
        "type": "xa",
        "slug": "quy-my",
        "name_with_type": "Xã Quy Mỹ",
        "path": "Quy Mỹ, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Quy Mỹ, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05182",
        "parent_code": "155"
    },
    "05185": {
        "name": "Do Nhân",
        "type": "xa",
        "slug": "do-nhan",
        "name_with_type": "Xã Do Nhân",
        "path": "Do Nhân, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Do Nhân, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05185",
        "parent_code": "155"
    },
    "05188": {
        "name": "Nam Sơn",
        "type": "xa",
        "slug": "nam-son",
        "name_with_type": "Xã Nam Sơn",
        "path": "Nam Sơn, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Nam Sơn, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05188",
        "parent_code": "155"
    },
    "05191": {
        "name": "Lỗ Sơn",
        "type": "xa",
        "slug": "lo-son",
        "name_with_type": "Xã Lỗ Sơn",
        "path": "Lỗ Sơn, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Lỗ Sơn, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05191",
        "parent_code": "155"
    },
    "05194": {
        "name": "Ngổ Luông",
        "type": "xa",
        "slug": "ngo-luong",
        "name_with_type": "Xã Ngổ Luông",
        "path": "Ngổ Luông, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Ngổ Luông, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05194",
        "parent_code": "155"
    },
    "05197": {
        "name": "Gia Mô",
        "type": "xa",
        "slug": "gia-mo",
        "name_with_type": "Xã Gia Mô",
        "path": "Gia Mô, Tân Lạc, Hoà Bình",
        "path_with_type": "Xã Gia Mô, Huyện Tân Lạc, Tỉnh Hoà Bình",
        "code": "05197",
        "parent_code": "155"
    },
    "04882": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Tân Dân, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "04882",
        "parent_code": "156"
    },
    "05200": {
        "name": "Mai Châu",
        "type": "thi-tran",
        "slug": "mai-chau",
        "name_with_type": "Thị trấn Mai Châu",
        "path": "Mai Châu, Mai Châu, Hoà Bình",
        "path_with_type": "Thị trấn Mai Châu, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05200",
        "parent_code": "156"
    },
    "05203": {
        "name": "Tân Mai",
        "type": "xa",
        "slug": "tan-mai",
        "name_with_type": "Xã Tân Mai",
        "path": "Tân Mai, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Tân Mai, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05203",
        "parent_code": "156"
    },
    "05206": {
        "name": "Phúc Sạn",
        "type": "xa",
        "slug": "phuc-san",
        "name_with_type": "Xã Phúc Sạn",
        "path": "Phúc Sạn, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Phúc Sạn, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05206",
        "parent_code": "156"
    },
    "05209": {
        "name": "Pà Cò",
        "type": "xa",
        "slug": "pa-co",
        "name_with_type": "Xã Pà Cò",
        "path": "Pà Cò, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Pà Cò, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05209",
        "parent_code": "156"
    },
    "05212": {
        "name": "Hang Kia",
        "type": "xa",
        "slug": "hang-kia",
        "name_with_type": "Xã Hang Kia",
        "path": "Hang Kia, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Hang Kia, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05212",
        "parent_code": "156"
    },
    "05215": {
        "name": "Ba Khan",
        "type": "xa",
        "slug": "ba-khan",
        "name_with_type": "Xã Ba Khan",
        "path": "Ba Khan, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Ba Khan, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05215",
        "parent_code": "156"
    },
    "05218": {
        "name": "Tân Sơn",
        "type": "xa",
        "slug": "tan-son",
        "name_with_type": "Xã Tân Sơn",
        "path": "Tân Sơn, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Tân Sơn, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05218",
        "parent_code": "156"
    },
    "05221": {
        "name": "Đồng Bảng",
        "type": "xa",
        "slug": "dong-bang",
        "name_with_type": "Xã Đồng Bảng",
        "path": "Đồng Bảng, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Đồng Bảng, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05221",
        "parent_code": "156"
    },
    "05224": {
        "name": "Cun Pheo",
        "type": "xa",
        "slug": "cun-pheo",
        "name_with_type": "Xã Cun Pheo",
        "path": "Cun Pheo, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Cun Pheo, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05224",
        "parent_code": "156"
    },
    "05227": {
        "name": "Bao La",
        "type": "xa",
        "slug": "bao-la",
        "name_with_type": "Xã Bao La",
        "path": "Bao La, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Bao La, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05227",
        "parent_code": "156"
    },
    "05230": {
        "name": "Piềng Vế",
        "type": "xa",
        "slug": "pieng-ve",
        "name_with_type": "Xã Piềng Vế",
        "path": "Piềng Vế, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Piềng Vế, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05230",
        "parent_code": "156"
    },
    "05233": {
        "name": "Tòng Đậu",
        "type": "xa",
        "slug": "tong-dau",
        "name_with_type": "Xã Tòng Đậu",
        "path": "Tòng Đậu, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Tòng Đậu, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05233",
        "parent_code": "156"
    },
    "05236": {
        "name": "Nà Mèo",
        "type": "xa",
        "slug": "na-meo",
        "name_with_type": "Xã Nà Mèo",
        "path": "Nà Mèo, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Nà Mèo, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05236",
        "parent_code": "156"
    },
    "05239": {
        "name": "Thung Khe",
        "type": "xa",
        "slug": "thung-khe",
        "name_with_type": "Xã Thung Khe",
        "path": "Thung Khe, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Thung Khe, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05239",
        "parent_code": "156"
    },
    "05242": {
        "name": "Nà Phòn",
        "type": "xa",
        "slug": "na-phon",
        "name_with_type": "Xã Nà Phòn",
        "path": "Nà Phòn, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Nà Phòn, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05242",
        "parent_code": "156"
    },
    "05245": {
        "name": "Săm Khóe",
        "type": "xa",
        "slug": "sam-khoe",
        "name_with_type": "Xã Săm Khóe",
        "path": "Săm Khóe, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Săm Khóe, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05245",
        "parent_code": "156"
    },
    "05248": {
        "name": "Chiềng Châu",
        "type": "xa",
        "slug": "chieng-chau",
        "name_with_type": "Xã Chiềng Châu",
        "path": "Chiềng Châu, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Chiềng Châu, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05248",
        "parent_code": "156"
    },
    "05251": {
        "name": "Mai Hạ",
        "type": "xa",
        "slug": "mai-ha",
        "name_with_type": "Xã Mai Hạ",
        "path": "Mai Hạ, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Mai Hạ, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05251",
        "parent_code": "156"
    },
    "05254": {
        "name": "Nong Luông",
        "type": "xa",
        "slug": "nong-luong",
        "name_with_type": "Xã Nong Luông",
        "path": "Nong Luông, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Nong Luông, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05254",
        "parent_code": "156"
    },
    "05257": {
        "name": "Mai Hịch",
        "type": "xa",
        "slug": "mai-hich",
        "name_with_type": "Xã Mai Hịch",
        "path": "Mai Hịch, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Mai Hịch, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05257",
        "parent_code": "156"
    },
    "05260": {
        "name": "Pù Pin",
        "type": "xa",
        "slug": "pu-pin",
        "name_with_type": "Xã Pù Pin",
        "path": "Pù Pin, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Pù Pin, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05260",
        "parent_code": "156"
    },
    "05263": {
        "name": "Vạn Mai",
        "type": "xa",
        "slug": "van-mai",
        "name_with_type": "Xã Vạn Mai",
        "path": "Vạn Mai, Mai Châu, Hoà Bình",
        "path_with_type": "Xã Vạn Mai, Huyện Mai Châu, Tỉnh Hoà Bình",
        "code": "05263",
        "parent_code": "156"
    },
    "05266": {
        "name": "Vụ Bản",
        "type": "thi-tran",
        "slug": "vu-ban",
        "name_with_type": "Thị trấn Vụ Bản",
        "path": "Vụ Bản, Lạc Sơn, Hoà Bình",
        "path_with_type": "Thị trấn Vụ Bản, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05266",
        "parent_code": "157"
    },
    "05269": {
        "name": "Quý Hòa",
        "type": "xa",
        "slug": "quy-hoa",
        "name_with_type": "Xã Quý Hòa",
        "path": "Quý Hòa, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Quý Hòa, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05269",
        "parent_code": "157"
    },
    "05272": {
        "name": "Miền Đồi",
        "type": "xa",
        "slug": "mien-doi",
        "name_with_type": "Xã Miền Đồi",
        "path": "Miền Đồi, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Miền Đồi, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05272",
        "parent_code": "157"
    },
    "05275": {
        "name": "Mỹ Thành",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thành",
        "path": "Mỹ Thành, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Mỹ Thành, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05275",
        "parent_code": "157"
    },
    "05278": {
        "name": "Tuân Đạo",
        "type": "xa",
        "slug": "tuan-dao",
        "name_with_type": "Xã Tuân Đạo",
        "path": "Tuân Đạo, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Tuân Đạo, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05278",
        "parent_code": "157"
    },
    "05281": {
        "name": "Văn Nghĩa",
        "type": "xa",
        "slug": "van-nghia",
        "name_with_type": "Xã Văn Nghĩa",
        "path": "Văn Nghĩa, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Văn Nghĩa, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05281",
        "parent_code": "157"
    },
    "05284": {
        "name": "Văn Sơn",
        "type": "xa",
        "slug": "van-son",
        "name_with_type": "Xã Văn Sơn",
        "path": "Văn Sơn, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Văn Sơn, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05284",
        "parent_code": "157"
    },
    "05287": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Tân Lập, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05287",
        "parent_code": "157"
    },
    "05290": {
        "name": "Nhân Nghĩa",
        "type": "xa",
        "slug": "nhan-nghia",
        "name_with_type": "Xã Nhân Nghĩa",
        "path": "Nhân Nghĩa, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Nhân Nghĩa, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05290",
        "parent_code": "157"
    },
    "05293": {
        "name": "Thượng Cốc",
        "type": "xa",
        "slug": "thuong-coc",
        "name_with_type": "Xã Thượng Cốc",
        "path": "Thượng Cốc, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Thượng Cốc, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05293",
        "parent_code": "157"
    },
    "05296": {
        "name": "Phú Lương",
        "type": "xa",
        "slug": "phu-luong",
        "name_with_type": "Xã Phú Lương",
        "path": "Phú Lương, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Phú Lương, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05296",
        "parent_code": "157"
    },
    "05299": {
        "name": "Phúc Tuy",
        "type": "xa",
        "slug": "phuc-tuy",
        "name_with_type": "Xã Phúc Tuy",
        "path": "Phúc Tuy, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Phúc Tuy, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05299",
        "parent_code": "157"
    },
    "05302": {
        "name": "Xuất Hóa",
        "type": "xa",
        "slug": "xuat-hoa",
        "name_with_type": "Xã Xuất Hóa",
        "path": "Xuất Hóa, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Xuất Hóa, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05302",
        "parent_code": "157"
    },
    "05305": {
        "name": "Yên Phú",
        "type": "xa",
        "slug": "yen-phu",
        "name_with_type": "Xã Yên Phú",
        "path": "Yên Phú, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Yên Phú, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05305",
        "parent_code": "157"
    },
    "05308": {
        "name": "Bình Hẻm",
        "type": "xa",
        "slug": "binh-hem",
        "name_with_type": "Xã Bình Hẻm",
        "path": "Bình Hẻm, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Bình Hẻm, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05308",
        "parent_code": "157"
    },
    "05311": {
        "name": "Chí Thiện",
        "type": "xa",
        "slug": "chi-thien",
        "name_with_type": "Xã Chí Thiện",
        "path": "Chí Thiện, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Chí Thiện, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05311",
        "parent_code": "157"
    },
    "05314": {
        "name": "Bình Cảng",
        "type": "xa",
        "slug": "binh-cang",
        "name_with_type": "Xã Bình Cảng",
        "path": "Bình Cảng, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Bình Cảng, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05314",
        "parent_code": "157"
    },
    "05317": {
        "name": "Bình Chân",
        "type": "xa",
        "slug": "binh-chan",
        "name_with_type": "Xã Bình Chân",
        "path": "Bình Chân, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Bình Chân, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05317",
        "parent_code": "157"
    },
    "05320": {
        "name": "Định Cư",
        "type": "xa",
        "slug": "dinh-cu",
        "name_with_type": "Xã Định Cư",
        "path": "Định Cư, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Định Cư, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05320",
        "parent_code": "157"
    },
    "05323": {
        "name": "Chí Đạo",
        "type": "xa",
        "slug": "chi-dao",
        "name_with_type": "Xã Chí Đạo",
        "path": "Chí Đạo, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Chí Đạo, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05323",
        "parent_code": "157"
    },
    "05326": {
        "name": "Liên Vũ",
        "type": "xa",
        "slug": "lien-vu",
        "name_with_type": "Xã Liên Vũ",
        "path": "Liên Vũ, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Liên Vũ, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05326",
        "parent_code": "157"
    },
    "05329": {
        "name": "Ngọc Sơn",
        "type": "xa",
        "slug": "ngoc-son",
        "name_with_type": "Xã Ngọc Sơn",
        "path": "Ngọc Sơn, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Ngọc Sơn, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05329",
        "parent_code": "157"
    },
    "05332": {
        "name": "Hương Nhượng",
        "type": "xa",
        "slug": "huong-nhuong",
        "name_with_type": "Xã Hương Nhượng",
        "path": "Hương Nhượng, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Hương Nhượng, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05332",
        "parent_code": "157"
    },
    "05335": {
        "name": "Vũ Lâm",
        "type": "xa",
        "slug": "vu-lam",
        "name_with_type": "Xã Vũ Lâm",
        "path": "Vũ Lâm, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Vũ Lâm, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05335",
        "parent_code": "157"
    },
    "05338": {
        "name": "Tự Do",
        "type": "xa",
        "slug": "tu-do",
        "name_with_type": "Xã Tự Do",
        "path": "Tự Do, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Tự Do, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05338",
        "parent_code": "157"
    },
    "05341": {
        "name": "Yên Nghiệp",
        "type": "xa",
        "slug": "yen-nghiep",
        "name_with_type": "Xã Yên Nghiệp",
        "path": "Yên Nghiệp, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Yên Nghiệp, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05341",
        "parent_code": "157"
    },
    "05344": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Tân Mỹ, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05344",
        "parent_code": "157"
    },
    "05347": {
        "name": "Ân Nghĩa",
        "type": "xa",
        "slug": "an-nghia",
        "name_with_type": "Xã Ân Nghĩa",
        "path": "Ân Nghĩa, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Ân Nghĩa, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05347",
        "parent_code": "157"
    },
    "05350": {
        "name": "Ngọc Lâu",
        "type": "xa",
        "slug": "ngoc-lau",
        "name_with_type": "Xã Ngọc Lâu",
        "path": "Ngọc Lâu, Lạc Sơn, Hoà Bình",
        "path_with_type": "Xã Ngọc Lâu, Huyện Lạc Sơn, Tỉnh Hoà Bình",
        "code": "05350",
        "parent_code": "157"
    },
    "05353": {
        "name": "Hàng Trạm",
        "type": "thi-tran",
        "slug": "hang-tram",
        "name_with_type": "Thị trấn Hàng Trạm",
        "path": "Hàng Trạm, Yên Thủy, Hoà Bình",
        "path_with_type": "Thị trấn Hàng Trạm, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05353",
        "parent_code": "158"
    },
    "05356": {
        "name": "Lạc Sỹ",
        "type": "xa",
        "slug": "lac-sy",
        "name_with_type": "Xã Lạc Sỹ",
        "path": "Lạc Sỹ, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Lạc Sỹ, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05356",
        "parent_code": "158"
    },
    "05359": {
        "name": "Lạc Hưng",
        "type": "xa",
        "slug": "lac-hung",
        "name_with_type": "Xã Lạc Hưng",
        "path": "Lạc Hưng, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Lạc Hưng, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05359",
        "parent_code": "158"
    },
    "05362": {
        "name": "Lạc Lương",
        "type": "xa",
        "slug": "lac-luong",
        "name_with_type": "Xã Lạc Lương",
        "path": "Lạc Lương, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Lạc Lương, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05362",
        "parent_code": "158"
    },
    "05365": {
        "name": "Bảo Hiệu",
        "type": "xa",
        "slug": "bao-hieu",
        "name_with_type": "Xã Bảo Hiệu",
        "path": "Bảo Hiệu, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Bảo Hiệu, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05365",
        "parent_code": "158"
    },
    "05368": {
        "name": "Đa Phúc",
        "type": "xa",
        "slug": "da-phuc",
        "name_with_type": "Xã Đa Phúc",
        "path": "Đa Phúc, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Đa Phúc, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05368",
        "parent_code": "158"
    },
    "05371": {
        "name": "Hữu Lợi",
        "type": "xa",
        "slug": "huu-loi",
        "name_with_type": "Xã Hữu Lợi",
        "path": "Hữu Lợi, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Hữu Lợi, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05371",
        "parent_code": "158"
    },
    "05374": {
        "name": "Lạc Thịnh",
        "type": "xa",
        "slug": "lac-thinh",
        "name_with_type": "Xã Lạc Thịnh",
        "path": "Lạc Thịnh, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Lạc Thịnh, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05374",
        "parent_code": "158"
    },
    "05377": {
        "name": "Yên Lạc",
        "type": "xa",
        "slug": "yen-lac",
        "name_with_type": "Xã Yên Lạc",
        "path": "Yên Lạc, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Yên Lạc, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05377",
        "parent_code": "158"
    },
    "05380": {
        "name": "Đoàn Kết",
        "type": "xa",
        "slug": "doan-ket",
        "name_with_type": "Xã Đoàn Kết",
        "path": "Đoàn Kết, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Đoàn Kết, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05380",
        "parent_code": "158"
    },
    "05383": {
        "name": "Phú Lai",
        "type": "xa",
        "slug": "phu-lai",
        "name_with_type": "Xã Phú Lai",
        "path": "Phú Lai, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Phú Lai, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05383",
        "parent_code": "158"
    },
    "05386": {
        "name": "Yên Trị",
        "type": "xa",
        "slug": "yen-tri",
        "name_with_type": "Xã Yên Trị",
        "path": "Yên Trị, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Yên Trị, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05386",
        "parent_code": "158"
    },
    "05389": {
        "name": "Ngọc Lương",
        "type": "xa",
        "slug": "ngoc-luong",
        "name_with_type": "Xã Ngọc Lương",
        "path": "Ngọc Lương, Yên Thủy, Hoà Bình",
        "path_with_type": "Xã Ngọc Lương, Huyện Yên Thủy, Tỉnh Hoà Bình",
        "code": "05389",
        "parent_code": "158"
    },
    "04981": {
        "name": "Thanh Hà",
        "type": "thi-tran",
        "slug": "thanh-ha",
        "name_with_type": "Thị trấn Thanh Hà",
        "path": "Thanh Hà, Lạc Thủy, Hoà Bình",
        "path_with_type": "Thị trấn Thanh Hà, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "04981",
        "parent_code": "159"
    },
    "05074": {
        "name": "Thanh Nông",
        "type": "xa",
        "slug": "thanh-nong",
        "name_with_type": "Xã Thanh Nông",
        "path": "Thanh Nông, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Thanh Nông, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05074",
        "parent_code": "159"
    },
    "05392": {
        "name": "Chi Nê",
        "type": "thi-tran",
        "slug": "chi-ne",
        "name_with_type": "Thị trấn Chi Nê",
        "path": "Chi Nê, Lạc Thủy, Hoà Bình",
        "path_with_type": "Thị trấn Chi Nê, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05392",
        "parent_code": "159"
    },
    "05395": {
        "name": "Phú Lão",
        "type": "xa",
        "slug": "phu-lao",
        "name_with_type": "Xã Phú Lão",
        "path": "Phú Lão, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Phú Lão, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05395",
        "parent_code": "159"
    },
    "05398": {
        "name": "Phú Thành",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thành",
        "path": "Phú Thành, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Phú Thành, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05398",
        "parent_code": "159"
    },
    "05401": {
        "name": "Cố Nghĩa",
        "type": "xa",
        "slug": "co-nghia",
        "name_with_type": "Xã Cố Nghĩa",
        "path": "Cố Nghĩa, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Cố Nghĩa, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05401",
        "parent_code": "159"
    },
    "05404": {
        "name": "Hưng Thi",
        "type": "xa",
        "slug": "hung-thi",
        "name_with_type": "Xã Hưng Thi",
        "path": "Hưng Thi, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Hưng Thi, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05404",
        "parent_code": "159"
    },
    "05407": {
        "name": "Lạc Long",
        "type": "xa",
        "slug": "lac-long",
        "name_with_type": "Xã Lạc Long",
        "path": "Lạc Long, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Lạc Long, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05407",
        "parent_code": "159"
    },
    "05410": {
        "name": "Liên Hòa",
        "type": "xa",
        "slug": "lien-hoa",
        "name_with_type": "Xã Liên Hòa",
        "path": "Liên Hòa, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Liên Hòa, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05410",
        "parent_code": "159"
    },
    "05413": {
        "name": "Khoan Dụ",
        "type": "xa",
        "slug": "khoan-du",
        "name_with_type": "Xã Khoan Dụ",
        "path": "Khoan Dụ, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Khoan Dụ, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05413",
        "parent_code": "159"
    },
    "05416": {
        "name": "Đồng Môn",
        "type": "xa",
        "slug": "dong-mon",
        "name_with_type": "Xã Đồng Môn",
        "path": "Đồng Môn, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Đồng Môn, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05416",
        "parent_code": "159"
    },
    "05419": {
        "name": "Đồng Tâm",
        "type": "xa",
        "slug": "dong-tam",
        "name_with_type": "Xã Đồng Tâm",
        "path": "Đồng Tâm, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Đồng Tâm, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05419",
        "parent_code": "159"
    },
    "05422": {
        "name": "Yên Bồng",
        "type": "xa",
        "slug": "yen-bong",
        "name_with_type": "Xã Yên Bồng",
        "path": "Yên Bồng, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã Yên Bồng, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05422",
        "parent_code": "159"
    },
    "05425": {
        "name": "An Lạc",
        "type": "xa",
        "slug": "an-lac",
        "name_with_type": "Xã An Lạc",
        "path": "An Lạc, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã An Lạc, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05425",
        "parent_code": "159"
    },
    "05428": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Lạc Thủy, Hoà Bình",
        "path_with_type": "Xã An Bình, Huyện Lạc Thủy, Tỉnh Hoà Bình",
        "code": "05428",
        "parent_code": "159"
    },
    "26506": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 1, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26506",
        "parent_code": "747"
    },
    "26508": {
        "name": "Thắng Tam",
        "type": "phuong",
        "slug": "thang-tam",
        "name_with_type": "Phường Thắng Tam",
        "path": "Thắng Tam, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Thắng Tam, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26508",
        "parent_code": "747"
    },
    "26509": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 2, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26509",
        "parent_code": "747"
    },
    "26512": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 3, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26512",
        "parent_code": "747"
    },
    "26515": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 4, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26515",
        "parent_code": "747"
    },
    "26518": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 5, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26518",
        "parent_code": "747"
    },
    "26521": {
        "name": "Thắng Nhì",
        "type": "phuong",
        "slug": "thang-nhi",
        "name_with_type": "Phường Thắng Nhì",
        "path": "Thắng Nhì, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Thắng Nhì, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26521",
        "parent_code": "747"
    },
    "26524": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 7, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26524",
        "parent_code": "747"
    },
    "26526": {
        "name": "Nguyễn An Ninh",
        "type": "phuong",
        "slug": "nguyen-an-ninh",
        "name_with_type": "Phường Nguyễn An Ninh",
        "path": "Nguyễn An Ninh, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Nguyễn An Ninh, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26526",
        "parent_code": "747"
    },
    "26527": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 8, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26527",
        "parent_code": "747"
    },
    "26530": {
        "name": "9",
        "type": "phuong",
        "slug": "9",
        "name_with_type": "Phường 9",
        "path": "9, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 9, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26530",
        "parent_code": "747"
    },
    "26533": {
        "name": "Thắng Nhất",
        "type": "phuong",
        "slug": "thang-nhat",
        "name_with_type": "Phường Thắng Nhất",
        "path": "Thắng Nhất, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Thắng Nhất, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26533",
        "parent_code": "747"
    },
    "26535": {
        "name": "Rạch Dừa",
        "type": "phuong",
        "slug": "rach-dua",
        "name_with_type": "Phường Rạch Dừa",
        "path": "Rạch Dừa, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Rạch Dừa, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26535",
        "parent_code": "747"
    },
    "26536": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 10, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26536",
        "parent_code": "747"
    },
    "26539": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 11, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26539",
        "parent_code": "747"
    },
    "26542": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường 12, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26542",
        "parent_code": "747"
    },
    "26545": {
        "name": "Long Sơn",
        "type": "xa",
        "slug": "long-son",
        "name_with_type": "Xã Long Sơn",
        "path": "Long Sơn, Vũng Tàu, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Long Sơn, Thành phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26545",
        "parent_code": "747"
    },
    "26548": {
        "name": "Phước Hưng",
        "type": "phuong",
        "slug": "phuoc-hung",
        "name_with_type": "Phường Phước Hưng",
        "path": "Phước Hưng, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Phước Hưng, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26548",
        "parent_code": "748"
    },
    "26551": {
        "name": "Phước Hiệp",
        "type": "phuong",
        "slug": "phuoc-hiep",
        "name_with_type": "Phường Phước Hiệp",
        "path": "Phước Hiệp, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Phước Hiệp, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26551",
        "parent_code": "748"
    },
    "26554": {
        "name": "Phước Nguyên",
        "type": "phuong",
        "slug": "phuoc-nguyen",
        "name_with_type": "Phường Phước Nguyên",
        "path": "Phước Nguyên, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Phước Nguyên, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26554",
        "parent_code": "748"
    },
    "26557": {
        "name": "Long Toàn",
        "type": "phuong",
        "slug": "long-toan",
        "name_with_type": "Phường Long Toàn",
        "path": "Long Toàn, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Long Toàn, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26557",
        "parent_code": "748"
    },
    "26558": {
        "name": "Long Tâm",
        "type": "phuong",
        "slug": "long-tam",
        "name_with_type": "Phường Long Tâm",
        "path": "Long Tâm, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Long Tâm, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26558",
        "parent_code": "748"
    },
    "26560": {
        "name": "Phước Trung",
        "type": "phuong",
        "slug": "phuoc-trung",
        "name_with_type": "Phường Phước Trung",
        "path": "Phước Trung, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Phước Trung, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26560",
        "parent_code": "748"
    },
    "26563": {
        "name": "Long Hương",
        "type": "phuong",
        "slug": "long-huong",
        "name_with_type": "Phường Long Hương",
        "path": "Long Hương, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Long Hương, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26563",
        "parent_code": "748"
    },
    "26566": {
        "name": "Kim Dinh",
        "type": "phuong",
        "slug": "kim-dinh",
        "name_with_type": "Phường Kim Dinh",
        "path": "Kim Dinh, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Phường Kim Dinh, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26566",
        "parent_code": "748"
    },
    "26567": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Tân Hưng, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26567",
        "parent_code": "748"
    },
    "26569": {
        "name": "Long Phước",
        "type": "xa",
        "slug": "long-phuoc",
        "name_with_type": "Xã Long Phước",
        "path": "Long Phước, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Long Phước, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26569",
        "parent_code": "748"
    },
    "26572": {
        "name": "Hoà Long",
        "type": "xa",
        "slug": "hoa-long",
        "name_with_type": "Xã Hoà Long",
        "path": "Hoà Long, Bà Rịa, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Hoà Long, Thành phố Bà Rịa, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26572",
        "parent_code": "748"
    },
    "26574": {
        "name": "Bàu Chinh",
        "type": "xa",
        "slug": "bau-chinh",
        "name_with_type": "Xã Bàu Chinh",
        "path": "Bàu Chinh, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Bàu Chinh, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26574",
        "parent_code": "750"
    },
    "26575": {
        "name": "Ngãi Giao",
        "type": "thi-tran",
        "slug": "ngai-giao",
        "name_with_type": "Thị trấn Ngãi Giao",
        "path": "Ngãi Giao, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Thị trấn Ngãi Giao, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26575",
        "parent_code": "750"
    },
    "26578": {
        "name": "Bình Ba",
        "type": "xa",
        "slug": "binh-ba",
        "name_with_type": "Xã Bình Ba",
        "path": "Bình Ba, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Bình Ba, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26578",
        "parent_code": "750"
    },
    "26581": {
        "name": "Suối Nghệ",
        "type": "xa",
        "slug": "suoi-nghe",
        "name_with_type": "Xã Suối Nghệ",
        "path": "Suối Nghệ, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Suối Nghệ, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26581",
        "parent_code": "750"
    },
    "26584": {
        "name": "Xuân Sơn",
        "type": "xa",
        "slug": "xuan-son",
        "name_with_type": "Xã Xuân Sơn",
        "path": "Xuân Sơn, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Xuân Sơn, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26584",
        "parent_code": "750"
    },
    "26587": {
        "name": "Sơn Bình",
        "type": "xa",
        "slug": "son-binh",
        "name_with_type": "Xã Sơn Bình",
        "path": "Sơn Bình, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Sơn Bình, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26587",
        "parent_code": "750"
    },
    "26590": {
        "name": "Bình Giã",
        "type": "xa",
        "slug": "binh-gia",
        "name_with_type": "Xã Bình Giã",
        "path": "Bình Giã, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Bình Giã, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26590",
        "parent_code": "750"
    },
    "26593": {
        "name": "Bình Trung",
        "type": "xa",
        "slug": "binh-trung",
        "name_with_type": "Xã Bình Trung",
        "path": "Bình Trung, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Bình Trung, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26593",
        "parent_code": "750"
    },
    "26596": {
        "name": "Xà Bang",
        "type": "xa",
        "slug": "xa-bang",
        "name_with_type": "Xã Xà Bang",
        "path": "Xà Bang, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Xà Bang, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26596",
        "parent_code": "750"
    },
    "26599": {
        "name": "Cù Bị",
        "type": "xa",
        "slug": "cu-bi",
        "name_with_type": "Xã Cù Bị",
        "path": "Cù Bị, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Cù Bị, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26599",
        "parent_code": "750"
    },
    "26602": {
        "name": "Láng Lớn",
        "type": "xa",
        "slug": "lang-lon",
        "name_with_type": "Xã Láng Lớn",
        "path": "Láng Lớn, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Láng Lớn, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26602",
        "parent_code": "750"
    },
    "26605": {
        "name": "Quảng Thành",
        "type": "xa",
        "slug": "quang-thanh",
        "name_with_type": "Xã Quảng Thành",
        "path": "Quảng Thành, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Quảng Thành, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26605",
        "parent_code": "750"
    },
    "26608": {
        "name": "Kim Long",
        "type": "xa",
        "slug": "kim-long",
        "name_with_type": "Xã Kim Long",
        "path": "Kim Long, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Kim Long, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26608",
        "parent_code": "750"
    },
    "26611": {
        "name": "Suối Rao",
        "type": "xa",
        "slug": "suoi-rao",
        "name_with_type": "Xã Suối Rao",
        "path": "Suối Rao, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Suối Rao, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26611",
        "parent_code": "750"
    },
    "26614": {
        "name": "Đá Bạc",
        "type": "xa",
        "slug": "da-bac",
        "name_with_type": "Xã Đá Bạc",
        "path": "Đá Bạc, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Đá Bạc, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26614",
        "parent_code": "750"
    },
    "26617": {
        "name": "Nghĩa Thành",
        "type": "xa",
        "slug": "nghia-thanh",
        "name_with_type": "Xã Nghĩa Thành",
        "path": "Nghĩa Thành, Châu Đức, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Nghĩa Thành, Huyện Châu Đức, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26617",
        "parent_code": "750"
    },
    "26620": {
        "name": "Phước Bửu",
        "type": "thi-tran",
        "slug": "phuoc-buu",
        "name_with_type": "Thị trấn Phước Bửu",
        "path": "Phước Bửu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Thị trấn Phước Bửu, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26620",
        "parent_code": "751"
    },
    "26623": {
        "name": "Phước Thuận",
        "type": "xa",
        "slug": "phuoc-thuan",
        "name_with_type": "Xã Phước Thuận",
        "path": "Phước Thuận, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Phước Thuận, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26623",
        "parent_code": "751"
    },
    "26626": {
        "name": "Phước Tân",
        "type": "xa",
        "slug": "phuoc-tan",
        "name_with_type": "Xã Phước Tân",
        "path": "Phước Tân, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Phước Tân, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26626",
        "parent_code": "751"
    },
    "26629": {
        "name": "Xuyên Mộc",
        "type": "xa",
        "slug": "xuyen-moc",
        "name_with_type": "Xã Xuyên Mộc",
        "path": "Xuyên Mộc, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Xuyên Mộc, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26629",
        "parent_code": "751"
    },
    "26632": {
        "name": "Bông Trang",
        "type": "xa",
        "slug": "bong-trang",
        "name_with_type": "Xã Bông Trang",
        "path": "Bông Trang, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Bông Trang, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26632",
        "parent_code": "751"
    },
    "26635": {
        "name": "Tân Lâm",
        "type": "xa",
        "slug": "tan-lam",
        "name_with_type": "Xã Tân Lâm",
        "path": "Tân Lâm, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Tân Lâm, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26635",
        "parent_code": "751"
    },
    "26638": {
        "name": "Bàu Lâm",
        "type": "xa",
        "slug": "bau-lam",
        "name_with_type": "Xã Bàu Lâm",
        "path": "Bàu Lâm, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Bàu Lâm, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26638",
        "parent_code": "751"
    },
    "26641": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Hòa Bình, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26641",
        "parent_code": "751"
    },
    "26644": {
        "name": "Hòa Hưng",
        "type": "xa",
        "slug": "hoa-hung",
        "name_with_type": "Xã Hòa Hưng",
        "path": "Hòa Hưng, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Hòa Hưng, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26644",
        "parent_code": "751"
    },
    "26647": {
        "name": "Hòa Hiệp",
        "type": "xa",
        "slug": "hoa-hiep",
        "name_with_type": "Xã Hòa Hiệp",
        "path": "Hòa Hiệp, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Hòa Hiệp, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26647",
        "parent_code": "751"
    },
    "26650": {
        "name": "Hòa Hội",
        "type": "xa",
        "slug": "hoa-hoi",
        "name_with_type": "Xã Hòa Hội",
        "path": "Hòa Hội, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Hòa Hội, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26650",
        "parent_code": "751"
    },
    "26653": {
        "name": "Bưng Riềng",
        "type": "xa",
        "slug": "bung-rieng",
        "name_with_type": "Xã Bưng Riềng",
        "path": "Bưng Riềng, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Bưng Riềng, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26653",
        "parent_code": "751"
    },
    "26656": {
        "name": "Bình Châu",
        "type": "xa",
        "slug": "binh-chau",
        "name_with_type": "Xã Bình Châu",
        "path": "Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Bình Châu, Huyện Xuyên Mộc, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26656",
        "parent_code": "751"
    },
    "26659": {
        "name": "Long Điền",
        "type": "thi-tran",
        "slug": "long-dien",
        "name_with_type": "Thị trấn Long Điền",
        "path": "Long Điền, Long Điền, Bà Rịa - Vũng Tàu",
        "path_with_type": "Thị trấn Long Điền, Huyện Long Điền, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26659",
        "parent_code": "752"
    },
    "26662": {
        "name": "Long Hải",
        "type": "thi-tran",
        "slug": "long-hai",
        "name_with_type": "Thị trấn Long Hải",
        "path": "Long Hải, Long Điền, Bà Rịa - Vũng Tàu",
        "path_with_type": "Thị trấn Long Hải, Huyện Long Điền, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26662",
        "parent_code": "752"
    },
    "26665": {
        "name": "An Ngãi",
        "type": "xa",
        "slug": "an-ngai",
        "name_with_type": "Xã An Ngãi",
        "path": "An Ngãi, Long Điền, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã An Ngãi, Huyện Long Điền, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26665",
        "parent_code": "752"
    },
    "26668": {
        "name": "Tam Phước",
        "type": "xa",
        "slug": "tam-phuoc",
        "name_with_type": "Xã Tam Phước",
        "path": "Tam Phước, Long Điền, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Tam Phước, Huyện Long Điền, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26668",
        "parent_code": "752"
    },
    "26671": {
        "name": "An Nhứt",
        "type": "xa",
        "slug": "an-nhut",
        "name_with_type": "Xã An Nhứt",
        "path": "An Nhứt, Long Điền, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã An Nhứt, Huyện Long Điền, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26671",
        "parent_code": "752"
    },
    "26674": {
        "name": "Phước Tỉnh",
        "type": "xa",
        "slug": "phuoc-tinh",
        "name_with_type": "Xã Phước Tỉnh",
        "path": "Phước Tỉnh, Long Điền, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Phước Tỉnh, Huyện Long Điền, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26674",
        "parent_code": "752"
    },
    "26677": {
        "name": "Phước Hưng",
        "type": "xa",
        "slug": "phuoc-hung",
        "name_with_type": "Xã Phước Hưng",
        "path": "Phước Hưng, Long Điền, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Phước Hưng, Huyện Long Điền, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26677",
        "parent_code": "752"
    },
    "26680": {
        "name": "Đất Đỏ",
        "type": "thi-tran",
        "slug": "dat-do",
        "name_with_type": "Thị trấn Đất Đỏ",
        "path": "Đất Đỏ, Đất Đỏ, Bà Rịa - Vũng Tàu",
        "path_with_type": "Thị trấn Đất Đỏ, Huyện Đất Đỏ, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26680",
        "parent_code": "753"
    },
    "26683": {
        "name": "Phước Long Thọ",
        "type": "xa",
        "slug": "phuoc-long-tho",
        "name_with_type": "Xã Phước Long Thọ",
        "path": "Phước Long Thọ, Đất Đỏ, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Phước Long Thọ, Huyện Đất Đỏ, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26683",
        "parent_code": "753"
    },
    "26686": {
        "name": "Phước Hội",
        "type": "xa",
        "slug": "phuoc-hoi",
        "name_with_type": "Xã Phước Hội",
        "path": "Phước Hội, Đất Đỏ, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Phước Hội, Huyện Đất Đỏ, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26686",
        "parent_code": "753"
    },
    "26689": {
        "name": "Long Mỹ",
        "type": "xa",
        "slug": "long-my",
        "name_with_type": "Xã Long Mỹ",
        "path": "Long Mỹ, Đất Đỏ, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Long Mỹ, Huyện Đất Đỏ, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26689",
        "parent_code": "753"
    },
    "26692": {
        "name": "Phước Hải",
        "type": "thi-tran",
        "slug": "phuoc-hai",
        "name_with_type": "Thị trấn Phước Hải",
        "path": "Phước Hải, Đất Đỏ, Bà Rịa - Vũng Tàu",
        "path_with_type": "Thị trấn Phước Hải, Huyện Đất Đỏ, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26692",
        "parent_code": "753"
    },
    "26695": {
        "name": "Long Tân",
        "type": "xa",
        "slug": "long-tan",
        "name_with_type": "Xã Long Tân",
        "path": "Long Tân, Đất Đỏ, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Long Tân, Huyện Đất Đỏ, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26695",
        "parent_code": "753"
    },
    "26698": {
        "name": "Láng Dài",
        "type": "xa",
        "slug": "lang-dai",
        "name_with_type": "Xã Láng Dài",
        "path": "Láng Dài, Đất Đỏ, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Láng Dài, Huyện Đất Đỏ, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26698",
        "parent_code": "753"
    },
    "26701": {
        "name": "Lộc An",
        "type": "xa",
        "slug": "loc-an",
        "name_with_type": "Xã Lộc An",
        "path": "Lộc An, Đất Đỏ, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Lộc An, Huyện Đất Đỏ, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26701",
        "parent_code": "753"
    },
    "26704": {
        "name": "Phú Mỹ",
        "type": "thi-tran",
        "slug": "phu-my",
        "name_with_type": "Thị trấn Phú Mỹ",
        "path": "Phú Mỹ, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Thị trấn Phú Mỹ, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26704",
        "parent_code": "754"
    },
    "26707": {
        "name": "Tân Hoà",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hoà",
        "path": "Tân Hoà, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Tân Hoà, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26707",
        "parent_code": "754"
    },
    "26710": {
        "name": "Tân Hải",
        "type": "xa",
        "slug": "tan-hai",
        "name_with_type": "Xã Tân Hải",
        "path": "Tân Hải, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Tân Hải, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26710",
        "parent_code": "754"
    },
    "26713": {
        "name": "Phước Hoà",
        "type": "xa",
        "slug": "phuoc-hoa",
        "name_with_type": "Xã Phước Hoà",
        "path": "Phước Hoà, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Phước Hoà, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26713",
        "parent_code": "754"
    },
    "26716": {
        "name": "Tân Phước",
        "type": "xa",
        "slug": "tan-phuoc",
        "name_with_type": "Xã Tân Phước",
        "path": "Tân Phước, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Tân Phước, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26716",
        "parent_code": "754"
    },
    "26719": {
        "name": "Mỹ Xuân",
        "type": "xa",
        "slug": "my-xuan",
        "name_with_type": "Xã Mỹ Xuân",
        "path": "Mỹ Xuân, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Mỹ Xuân, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26719",
        "parent_code": "754"
    },
    "26722": {
        "name": "Sông Xoài",
        "type": "xa",
        "slug": "song-xoai",
        "name_with_type": "Xã Sông Xoài",
        "path": "Sông Xoài, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Sông Xoài, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26722",
        "parent_code": "754"
    },
    "26725": {
        "name": "Hắc Dịch",
        "type": "xa",
        "slug": "hac-dich",
        "name_with_type": "Xã Hắc Dịch",
        "path": "Hắc Dịch, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Hắc Dịch, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26725",
        "parent_code": "754"
    },
    "26728": {
        "name": "Châu Pha",
        "type": "xa",
        "slug": "chau-pha",
        "name_with_type": "Xã Châu Pha",
        "path": "Châu Pha, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Châu Pha, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26728",
        "parent_code": "754"
    },
    "26731": {
        "name": "Tóc Tiên",
        "type": "xa",
        "slug": "toc-tien",
        "name_with_type": "Xã Tóc Tiên",
        "path": "Tóc Tiên, Tân Thành, Bà Rịa - Vũng Tàu",
        "path_with_type": "Xã Tóc Tiên, Huyện Tân Thành, Tỉnh Bà Rịa - Vũng Tàu",
        "code": "26731",
        "parent_code": "754"
    },
    "31999": {
        "name": "9",
        "type": "phuong",
        "slug": "9",
        "name_with_type": "Phường 9",
        "path": "9, Cà Mau, Cà Mau",
        "path_with_type": "Phường 9, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "31999",
        "parent_code": "964"
    },
    "32002": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Cà Mau, Cà Mau",
        "path_with_type": "Phường 4, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32002",
        "parent_code": "964"
    },
    "32005": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Cà Mau, Cà Mau",
        "path_with_type": "Phường 1, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32005",
        "parent_code": "964"
    },
    "32008": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Cà Mau, Cà Mau",
        "path_with_type": "Phường 5, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32008",
        "parent_code": "964"
    },
    "32011": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Cà Mau, Cà Mau",
        "path_with_type": "Phường 2, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32011",
        "parent_code": "964"
    },
    "32014": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Cà Mau, Cà Mau",
        "path_with_type": "Phường 8, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32014",
        "parent_code": "964"
    },
    "32017": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Cà Mau, Cà Mau",
        "path_with_type": "Phường 6, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32017",
        "parent_code": "964"
    },
    "32020": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Cà Mau, Cà Mau",
        "path_with_type": "Phường 7, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32020",
        "parent_code": "964"
    },
    "32022": {
        "name": "Tân Xuyên",
        "type": "phuong",
        "slug": "tan-xuyen",
        "name_with_type": "Phường Tân Xuyên",
        "path": "Tân Xuyên, Cà Mau, Cà Mau",
        "path_with_type": "Phường Tân Xuyên, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32022",
        "parent_code": "964"
    },
    "32023": {
        "name": "An Xuyên",
        "type": "xa",
        "slug": "an-xuyen",
        "name_with_type": "Xã An Xuyên",
        "path": "An Xuyên, Cà Mau, Cà Mau",
        "path_with_type": "Xã An Xuyên, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32023",
        "parent_code": "964"
    },
    "32025": {
        "name": "Tân Thành",
        "type": "phuong",
        "slug": "tan-thanh",
        "name_with_type": "Phường Tân Thành",
        "path": "Tân Thành, Cà Mau, Cà Mau",
        "path_with_type": "Phường Tân Thành, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32025",
        "parent_code": "964"
    },
    "32026": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Cà Mau, Cà Mau",
        "path_with_type": "Xã Tân Thành, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32026",
        "parent_code": "964"
    },
    "32029": {
        "name": "Tắc Vân",
        "type": "xa",
        "slug": "tac-van",
        "name_with_type": "Xã Tắc Vân",
        "path": "Tắc Vân, Cà Mau, Cà Mau",
        "path_with_type": "Xã Tắc Vân, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32029",
        "parent_code": "964"
    },
    "32032": {
        "name": "Lý Văn Lâm",
        "type": "xa",
        "slug": "ly-van-lam",
        "name_with_type": "Xã Lý Văn Lâm",
        "path": "Lý Văn Lâm, Cà Mau, Cà Mau",
        "path_with_type": "Xã Lý Văn Lâm, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32032",
        "parent_code": "964"
    },
    "32035": {
        "name": "Định Bình",
        "type": "xa",
        "slug": "dinh-binh",
        "name_with_type": "Xã Định Bình",
        "path": "Định Bình, Cà Mau, Cà Mau",
        "path_with_type": "Xã Định Bình, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32035",
        "parent_code": "964"
    },
    "32038": {
        "name": "Hòa Thành",
        "type": "xa",
        "slug": "hoa-thanh",
        "name_with_type": "Xã Hòa Thành",
        "path": "Hòa Thành, Cà Mau, Cà Mau",
        "path_with_type": "Xã Hòa Thành, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32038",
        "parent_code": "964"
    },
    "32041": {
        "name": "Hòa Tân",
        "type": "xa",
        "slug": "hoa-tan",
        "name_with_type": "Xã Hòa Tân",
        "path": "Hòa Tân, Cà Mau, Cà Mau",
        "path_with_type": "Xã Hòa Tân, Thành phố Cà Mau, Tỉnh Cà Mau",
        "code": "32041",
        "parent_code": "964"
    },
    "32044": {
        "name": "U Minh",
        "type": "thi-tran",
        "slug": "u-minh",
        "name_with_type": "Thị trấn U Minh",
        "path": "U Minh, U Minh, Cà Mau",
        "path_with_type": "Thị trấn U Minh, Huyện U Minh, Tỉnh Cà Mau",
        "code": "32044",
        "parent_code": "966"
    },
    "32047": {
        "name": "Khánh Hòa",
        "type": "xa",
        "slug": "khanh-hoa",
        "name_with_type": "Xã Khánh Hòa",
        "path": "Khánh Hòa, U Minh, Cà Mau",
        "path_with_type": "Xã Khánh Hòa, Huyện U Minh, Tỉnh Cà Mau",
        "code": "32047",
        "parent_code": "966"
    },
    "32048": {
        "name": "Khánh Thuận",
        "type": "xa",
        "slug": "khanh-thuan",
        "name_with_type": "Xã Khánh Thuận",
        "path": "Khánh Thuận, U Minh, Cà Mau",
        "path_with_type": "Xã Khánh Thuận, Huyện U Minh, Tỉnh Cà Mau",
        "code": "32048",
        "parent_code": "966"
    },
    "32050": {
        "name": "Khánh Tiến",
        "type": "xa",
        "slug": "khanh-tien",
        "name_with_type": "Xã Khánh Tiến",
        "path": "Khánh Tiến, U Minh, Cà Mau",
        "path_with_type": "Xã Khánh Tiến, Huyện U Minh, Tỉnh Cà Mau",
        "code": "32050",
        "parent_code": "966"
    },
    "32053": {
        "name": "Nguyễn Phích",
        "type": "xa",
        "slug": "nguyen-phich",
        "name_with_type": "Xã Nguyễn Phích",
        "path": "Nguyễn Phích, U Minh, Cà Mau",
        "path_with_type": "Xã Nguyễn Phích, Huyện U Minh, Tỉnh Cà Mau",
        "code": "32053",
        "parent_code": "966"
    },
    "32056": {
        "name": "Khánh Lâm",
        "type": "xa",
        "slug": "khanh-lam",
        "name_with_type": "Xã Khánh Lâm",
        "path": "Khánh Lâm, U Minh, Cà Mau",
        "path_with_type": "Xã Khánh Lâm, Huyện U Minh, Tỉnh Cà Mau",
        "code": "32056",
        "parent_code": "966"
    },
    "32059": {
        "name": "Khánh An",
        "type": "xa",
        "slug": "khanh-an",
        "name_with_type": "Xã Khánh An",
        "path": "Khánh An, U Minh, Cà Mau",
        "path_with_type": "Xã Khánh An, Huyện U Minh, Tỉnh Cà Mau",
        "code": "32059",
        "parent_code": "966"
    },
    "32062": {
        "name": "Khánh Hội",
        "type": "xa",
        "slug": "khanh-hoi",
        "name_with_type": "Xã Khánh Hội",
        "path": "Khánh Hội, U Minh, Cà Mau",
        "path_with_type": "Xã Khánh Hội, Huyện U Minh, Tỉnh Cà Mau",
        "code": "32062",
        "parent_code": "966"
    },
    "32065": {
        "name": "Thới Bình",
        "type": "thi-tran",
        "slug": "thoi-binh",
        "name_with_type": "Thị trấn Thới Bình",
        "path": "Thới Bình, Thới Bình, Cà Mau",
        "path_with_type": "Thị trấn Thới Bình, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32065",
        "parent_code": "967"
    },
    "32068": {
        "name": "Biển Bạch",
        "type": "xa",
        "slug": "bien-bach",
        "name_with_type": "Xã Biển Bạch",
        "path": "Biển Bạch, Thới Bình, Cà Mau",
        "path_with_type": "Xã Biển Bạch, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32068",
        "parent_code": "967"
    },
    "32069": {
        "name": "Tân Bằng",
        "type": "xa",
        "slug": "tan-bang",
        "name_with_type": "Xã Tân Bằng",
        "path": "Tân Bằng, Thới Bình, Cà Mau",
        "path_with_type": "Xã Tân Bằng, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32069",
        "parent_code": "967"
    },
    "32071": {
        "name": "Trí Phải",
        "type": "xa",
        "slug": "tri-phai",
        "name_with_type": "Xã Trí Phải",
        "path": "Trí Phải, Thới Bình, Cà Mau",
        "path_with_type": "Xã Trí Phải, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32071",
        "parent_code": "967"
    },
    "32072": {
        "name": "Trí Lực",
        "type": "xa",
        "slug": "tri-luc",
        "name_with_type": "Xã Trí Lực",
        "path": "Trí Lực, Thới Bình, Cà Mau",
        "path_with_type": "Xã Trí Lực, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32072",
        "parent_code": "967"
    },
    "32074": {
        "name": "Biển Bạch Đông",
        "type": "xa",
        "slug": "bien-bach-dong",
        "name_with_type": "Xã Biển Bạch Đông",
        "path": "Biển Bạch Đông, Thới Bình, Cà Mau",
        "path_with_type": "Xã Biển Bạch Đông, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32074",
        "parent_code": "967"
    },
    "32077": {
        "name": "Thới Bình",
        "type": "xa",
        "slug": "thoi-binh",
        "name_with_type": "Xã Thới Bình",
        "path": "Thới Bình, Thới Bình, Cà Mau",
        "path_with_type": "Xã Thới Bình, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32077",
        "parent_code": "967"
    },
    "32080": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Thới Bình, Cà Mau",
        "path_with_type": "Xã Tân Phú, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32080",
        "parent_code": "967"
    },
    "32083": {
        "name": "Tân Lộc Bắc",
        "type": "xa",
        "slug": "tan-loc-bac",
        "name_with_type": "Xã Tân Lộc Bắc",
        "path": "Tân Lộc Bắc, Thới Bình, Cà Mau",
        "path_with_type": "Xã Tân Lộc Bắc, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32083",
        "parent_code": "967"
    },
    "32086": {
        "name": "Tân Lộc",
        "type": "xa",
        "slug": "tan-loc",
        "name_with_type": "Xã Tân Lộc",
        "path": "Tân Lộc, Thới Bình, Cà Mau",
        "path_with_type": "Xã Tân Lộc, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32086",
        "parent_code": "967"
    },
    "32089": {
        "name": "Tân Lộc Đông",
        "type": "xa",
        "slug": "tan-loc-dong",
        "name_with_type": "Xã Tân Lộc Đông",
        "path": "Tân Lộc Đông, Thới Bình, Cà Mau",
        "path_with_type": "Xã Tân Lộc Đông, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32089",
        "parent_code": "967"
    },
    "32092": {
        "name": "Hồ Thị Kỷ",
        "type": "xa",
        "slug": "ho-thi-ky",
        "name_with_type": "Xã Hồ Thị Kỷ",
        "path": "Hồ Thị Kỷ, Thới Bình, Cà Mau",
        "path_with_type": "Xã Hồ Thị Kỷ, Huyện Thới Bình, Tỉnh Cà Mau",
        "code": "32092",
        "parent_code": "967"
    },
    "32095": {
        "name": "Trần Văn Thời",
        "type": "thi-tran",
        "slug": "tran-van-thoi",
        "name_with_type": "Thị trấn Trần Văn Thời",
        "path": "Trần Văn Thời, Trần Văn Thời, Cà Mau",
        "path_with_type": "Thị trấn Trần Văn Thời, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32095",
        "parent_code": "968"
    },
    "32098": {
        "name": "Sông Đốc",
        "type": "thi-tran",
        "slug": "song-doc",
        "name_with_type": "Thị trấn Sông Đốc",
        "path": "Sông Đốc, Trần Văn Thời, Cà Mau",
        "path_with_type": "Thị trấn Sông Đốc, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32098",
        "parent_code": "968"
    },
    "32101": {
        "name": "Khánh Bình Tây Bắc",
        "type": "xa",
        "slug": "khanh-binh-tay-bac",
        "name_with_type": "Xã Khánh Bình Tây Bắc",
        "path": "Khánh Bình Tây Bắc, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Khánh Bình Tây Bắc, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32101",
        "parent_code": "968"
    },
    "32104": {
        "name": "Khánh Bình Tây",
        "type": "xa",
        "slug": "khanh-binh-tay",
        "name_with_type": "Xã Khánh Bình Tây",
        "path": "Khánh Bình Tây, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Khánh Bình Tây, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32104",
        "parent_code": "968"
    },
    "32107": {
        "name": "Trần Hợi",
        "type": "xa",
        "slug": "tran-hoi",
        "name_with_type": "Xã Trần Hợi",
        "path": "Trần Hợi, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Trần Hợi, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32107",
        "parent_code": "968"
    },
    "32108": {
        "name": "Khánh Lộc",
        "type": "xa",
        "slug": "khanh-loc",
        "name_with_type": "Xã Khánh Lộc",
        "path": "Khánh Lộc, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Khánh Lộc, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32108",
        "parent_code": "968"
    },
    "32110": {
        "name": "Khánh Bình",
        "type": "xa",
        "slug": "khanh-binh",
        "name_with_type": "Xã Khánh Bình",
        "path": "Khánh Bình, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Khánh Bình, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32110",
        "parent_code": "968"
    },
    "32113": {
        "name": "Khánh Hưng",
        "type": "xa",
        "slug": "khanh-hung",
        "name_with_type": "Xã Khánh Hưng",
        "path": "Khánh Hưng, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Khánh Hưng, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32113",
        "parent_code": "968"
    },
    "32116": {
        "name": "Khánh Bình Đông",
        "type": "xa",
        "slug": "khanh-binh-dong",
        "name_with_type": "Xã Khánh Bình Đông",
        "path": "Khánh Bình Đông, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Khánh Bình Đông, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32116",
        "parent_code": "968"
    },
    "32119": {
        "name": "Khánh Hải",
        "type": "xa",
        "slug": "khanh-hai",
        "name_with_type": "Xã Khánh Hải",
        "path": "Khánh Hải, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Khánh Hải, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32119",
        "parent_code": "968"
    },
    "32122": {
        "name": "Lợi An",
        "type": "xa",
        "slug": "loi-an",
        "name_with_type": "Xã Lợi An",
        "path": "Lợi An, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Lợi An, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32122",
        "parent_code": "968"
    },
    "32124": {
        "name": "Phong Điền",
        "type": "xa",
        "slug": "phong-dien",
        "name_with_type": "Xã Phong Điền",
        "path": "Phong Điền, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Phong Điền, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32124",
        "parent_code": "968"
    },
    "32125": {
        "name": "Phong Lạc",
        "type": "xa",
        "slug": "phong-lac",
        "name_with_type": "Xã Phong Lạc",
        "path": "Phong Lạc, Trần Văn Thời, Cà Mau",
        "path_with_type": "Xã Phong Lạc, Huyện Trần Văn Thời, Tỉnh Cà Mau",
        "code": "32125",
        "parent_code": "968"
    },
    "32128": {
        "name": "Cái Nước",
        "type": "thi-tran",
        "slug": "cai-nuoc",
        "name_with_type": "Thị trấn Cái Nước",
        "path": "Cái Nước, Cái Nước, Cà Mau",
        "path_with_type": "Thị trấn Cái Nước, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32128",
        "parent_code": "969"
    },
    "32130": {
        "name": "Thạnh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thạnh Phú",
        "path": "Thạnh Phú, Cái Nước, Cà Mau",
        "path_with_type": "Xã Thạnh Phú, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32130",
        "parent_code": "969"
    },
    "32131": {
        "name": "Lương Thế Trân",
        "type": "xa",
        "slug": "luong-the-tran",
        "name_with_type": "Xã Lương Thế Trân",
        "path": "Lương Thế Trân, Cái Nước, Cà Mau",
        "path_with_type": "Xã Lương Thế Trân, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32131",
        "parent_code": "969"
    },
    "32134": {
        "name": "Phú Hưng",
        "type": "xa",
        "slug": "phu-hung",
        "name_with_type": "Xã Phú Hưng",
        "path": "Phú Hưng, Cái Nước, Cà Mau",
        "path_with_type": "Xã Phú Hưng, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32134",
        "parent_code": "969"
    },
    "32137": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Cái Nước, Cà Mau",
        "path_with_type": "Xã Tân Hưng, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32137",
        "parent_code": "969"
    },
    "32140": {
        "name": "Hưng Mỹ",
        "type": "xa",
        "slug": "hung-my",
        "name_with_type": "Xã Hưng Mỹ",
        "path": "Hưng Mỹ, Cái Nước, Cà Mau",
        "path_with_type": "Xã Hưng Mỹ, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32140",
        "parent_code": "969"
    },
    "32141": {
        "name": "Hoà Mỹ",
        "type": "xa",
        "slug": "hoa-my",
        "name_with_type": "Xã Hoà Mỹ",
        "path": "Hoà Mỹ, Cái Nước, Cà Mau",
        "path_with_type": "Xã Hoà Mỹ, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32141",
        "parent_code": "969"
    },
    "32142": {
        "name": "Đông Hưng",
        "type": "xa",
        "slug": "dong-hung",
        "name_with_type": "Xã Đông Hưng",
        "path": "Đông Hưng, Cái Nước, Cà Mau",
        "path_with_type": "Xã Đông Hưng, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32142",
        "parent_code": "969"
    },
    "32143": {
        "name": "Đông Thới",
        "type": "xa",
        "slug": "dong-thoi",
        "name_with_type": "Xã Đông Thới",
        "path": "Đông Thới, Cái Nước, Cà Mau",
        "path_with_type": "Xã Đông Thới, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32143",
        "parent_code": "969"
    },
    "32146": {
        "name": "Tân Hưng Đông",
        "type": "xa",
        "slug": "tan-hung-dong",
        "name_with_type": "Xã Tân Hưng Đông",
        "path": "Tân Hưng Đông, Cái Nước, Cà Mau",
        "path_with_type": "Xã Tân Hưng Đông, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32146",
        "parent_code": "969"
    },
    "32149": {
        "name": "Trần Thới",
        "type": "xa",
        "slug": "tran-thoi",
        "name_with_type": "Xã Trần Thới",
        "path": "Trần Thới, Cái Nước, Cà Mau",
        "path_with_type": "Xã Trần Thới, Huyện Cái Nước, Tỉnh Cà Mau",
        "code": "32149",
        "parent_code": "969"
    },
    "32152": {
        "name": "Đầm Dơi",
        "type": "thi-tran",
        "slug": "dam-doi",
        "name_with_type": "Thị trấn Đầm Dơi",
        "path": "Đầm Dơi, Đầm Dơi, Cà Mau",
        "path_with_type": "Thị trấn Đầm Dơi, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32152",
        "parent_code": "970"
    },
    "32155": {
        "name": "Tạ An Khương",
        "type": "xa",
        "slug": "ta-an-khuong",
        "name_with_type": "Xã Tạ An Khương",
        "path": "Tạ An Khương, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Tạ An Khương, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32155",
        "parent_code": "970"
    },
    "32158": {
        "name": "Tạ An Khương  Đông",
        "type": "xa",
        "slug": "ta-an-khuong-dong",
        "name_with_type": "Xã Tạ An Khương  Đông",
        "path": "Tạ An Khương  Đông, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Tạ An Khương  Đông, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32158",
        "parent_code": "970"
    },
    "32161": {
        "name": "Trần Phán",
        "type": "xa",
        "slug": "tran-phan",
        "name_with_type": "Xã Trần Phán",
        "path": "Trần Phán, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Trần Phán, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32161",
        "parent_code": "970"
    },
    "32162": {
        "name": "Tân Trung",
        "type": "xa",
        "slug": "tan-trung",
        "name_with_type": "Xã Tân Trung",
        "path": "Tân Trung, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Tân Trung, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32162",
        "parent_code": "970"
    },
    "32164": {
        "name": "Tân Đức",
        "type": "xa",
        "slug": "tan-duc",
        "name_with_type": "Xã Tân Đức",
        "path": "Tân Đức, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Tân Đức, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32164",
        "parent_code": "970"
    },
    "32167": {
        "name": "Tân Thuận",
        "type": "xa",
        "slug": "tan-thuan",
        "name_with_type": "Xã Tân Thuận",
        "path": "Tân Thuận, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Tân Thuận, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32167",
        "parent_code": "970"
    },
    "32170": {
        "name": "Tạ An Khương  Nam",
        "type": "xa",
        "slug": "ta-an-khuong-nam",
        "name_with_type": "Xã Tạ An Khương  Nam",
        "path": "Tạ An Khương  Nam, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Tạ An Khương  Nam, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32170",
        "parent_code": "970"
    },
    "32173": {
        "name": "Tân Duyệt",
        "type": "xa",
        "slug": "tan-duyet",
        "name_with_type": "Xã Tân Duyệt",
        "path": "Tân Duyệt, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Tân Duyệt, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32173",
        "parent_code": "970"
    },
    "32174": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Tân Dân, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32174",
        "parent_code": "970"
    },
    "32176": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Tân Tiến, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32176",
        "parent_code": "970"
    },
    "32179": {
        "name": "Quách Phẩm Bắc",
        "type": "xa",
        "slug": "quach-pham-bac",
        "name_with_type": "Xã Quách Phẩm Bắc",
        "path": "Quách Phẩm Bắc, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Quách Phẩm Bắc, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32179",
        "parent_code": "970"
    },
    "32182": {
        "name": "Quách Phẩm",
        "type": "xa",
        "slug": "quach-pham",
        "name_with_type": "Xã Quách Phẩm",
        "path": "Quách Phẩm, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Quách Phẩm, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32182",
        "parent_code": "970"
    },
    "32185": {
        "name": "Thanh Tùng",
        "type": "xa",
        "slug": "thanh-tung",
        "name_with_type": "Xã Thanh Tùng",
        "path": "Thanh Tùng, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Thanh Tùng, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32185",
        "parent_code": "970"
    },
    "32186": {
        "name": "Ngọc Chánh",
        "type": "xa",
        "slug": "ngoc-chanh",
        "name_with_type": "Xã Ngọc Chánh",
        "path": "Ngọc Chánh, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Ngọc Chánh, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32186",
        "parent_code": "970"
    },
    "32188": {
        "name": "Nguyễn Huân",
        "type": "xa",
        "slug": "nguyen-huan",
        "name_with_type": "Xã Nguyễn Huân",
        "path": "Nguyễn Huân, Đầm Dơi, Cà Mau",
        "path_with_type": "Xã Nguyễn Huân, Huyện Đầm Dơi, Tỉnh Cà Mau",
        "code": "32188",
        "parent_code": "970"
    },
    "32191": {
        "name": "Năm Căn",
        "type": "thi-tran",
        "slug": "nam-can",
        "name_with_type": "Thị Trấn Năm Căn",
        "path": "Năm Căn, Năm Căn, Cà Mau",
        "path_with_type": "Thị Trấn Năm Căn, Huyện Năm Căn, Tỉnh Cà Mau",
        "code": "32191",
        "parent_code": "971"
    },
    "32194": {
        "name": "Hàm Rồng",
        "type": "xa",
        "slug": "ham-rong",
        "name_with_type": "Xã Hàm Rồng",
        "path": "Hàm Rồng, Năm Căn, Cà Mau",
        "path_with_type": "Xã Hàm Rồng, Huyện Năm Căn, Tỉnh Cà Mau",
        "code": "32194",
        "parent_code": "971"
    },
    "32197": {
        "name": "Hiệp Tùng",
        "type": "xa",
        "slug": "hiep-tung",
        "name_with_type": "Xã Hiệp Tùng",
        "path": "Hiệp Tùng, Năm Căn, Cà Mau",
        "path_with_type": "Xã Hiệp Tùng, Huyện Năm Căn, Tỉnh Cà Mau",
        "code": "32197",
        "parent_code": "971"
    },
    "32200": {
        "name": "Đất Mới",
        "type": "xa",
        "slug": "dat-moi",
        "name_with_type": "Xã Đất Mới",
        "path": "Đất Mới, Năm Căn, Cà Mau",
        "path_with_type": "Xã Đất Mới, Huyện Năm Căn, Tỉnh Cà Mau",
        "code": "32200",
        "parent_code": "971"
    },
    "32201": {
        "name": "Lâm Hải",
        "type": "xa",
        "slug": "lam-hai",
        "name_with_type": "Xã Lâm Hải",
        "path": "Lâm Hải, Năm Căn, Cà Mau",
        "path_with_type": "Xã Lâm Hải, Huyện Năm Căn, Tỉnh Cà Mau",
        "code": "32201",
        "parent_code": "971"
    },
    "32203": {
        "name": "Hàng Vịnh",
        "type": "xa",
        "slug": "hang-vinh",
        "name_with_type": "Xã Hàng Vịnh",
        "path": "Hàng Vịnh, Năm Căn, Cà Mau",
        "path_with_type": "Xã Hàng Vịnh, Huyện Năm Căn, Tỉnh Cà Mau",
        "code": "32203",
        "parent_code": "971"
    },
    "32206": {
        "name": "Tam Giang",
        "type": "xa",
        "slug": "tam-giang",
        "name_with_type": "Xã Tam Giang",
        "path": "Tam Giang, Năm Căn, Cà Mau",
        "path_with_type": "Xã Tam Giang, Huyện Năm Căn, Tỉnh Cà Mau",
        "code": "32206",
        "parent_code": "971"
    },
    "32209": {
        "name": "Tam Giang Đông",
        "type": "xa",
        "slug": "tam-giang-dong",
        "name_with_type": "Xã Tam Giang Đông",
        "path": "Tam Giang Đông, Năm Căn, Cà Mau",
        "path_with_type": "Xã Tam Giang Đông, Huyện Năm Căn, Tỉnh Cà Mau",
        "code": "32209",
        "parent_code": "971"
    },
    "32212": {
        "name": "Cái Đôi Vàm",
        "type": "thi-tran",
        "slug": "cai-doi-vam",
        "name_with_type": "Thị trấn Cái Đôi Vàm",
        "path": "Cái Đôi Vàm, Phú Tân, Cà Mau",
        "path_with_type": "Thị trấn Cái Đôi Vàm, Huyện Phú Tân, Tỉnh Cà Mau",
        "code": "32212",
        "parent_code": "972"
    },
    "32214": {
        "name": "Phú Thuận",
        "type": "xa",
        "slug": "phu-thuan",
        "name_with_type": "Xã Phú Thuận",
        "path": "Phú Thuận, Phú Tân, Cà Mau",
        "path_with_type": "Xã Phú Thuận, Huyện Phú Tân, Tỉnh Cà Mau",
        "code": "32214",
        "parent_code": "972"
    },
    "32215": {
        "name": "Phú Mỹ",
        "type": "xa",
        "slug": "phu-my",
        "name_with_type": "Xã Phú Mỹ",
        "path": "Phú Mỹ, Phú Tân, Cà Mau",
        "path_with_type": "Xã Phú Mỹ, Huyện Phú Tân, Tỉnh Cà Mau",
        "code": "32215",
        "parent_code": "972"
    },
    "32218": {
        "name": "Phú Tân",
        "type": "xa",
        "slug": "phu-tan",
        "name_with_type": "Xã Phú Tân",
        "path": "Phú Tân, Phú Tân, Cà Mau",
        "path_with_type": "Xã Phú Tân, Huyện Phú Tân, Tỉnh Cà Mau",
        "code": "32218",
        "parent_code": "972"
    },
    "32221": {
        "name": "Tân Hải",
        "type": "xa",
        "slug": "tan-hai",
        "name_with_type": "Xã Tân Hải",
        "path": "Tân Hải, Phú Tân, Cà Mau",
        "path_with_type": "Xã Tân Hải, Huyện Phú Tân, Tỉnh Cà Mau",
        "code": "32221",
        "parent_code": "972"
    },
    "32224": {
        "name": "Việt Thắng",
        "type": "xa",
        "slug": "viet-thang",
        "name_with_type": "Xã Việt Thắng",
        "path": "Việt Thắng, Phú Tân, Cà Mau",
        "path_with_type": "Xã Việt Thắng, Huyện Phú Tân, Tỉnh Cà Mau",
        "code": "32224",
        "parent_code": "972"
    },
    "32227": {
        "name": "Tân Hưng Tây",
        "type": "xa",
        "slug": "tan-hung-tay",
        "name_with_type": "Xã Tân Hưng Tây",
        "path": "Tân Hưng Tây, Phú Tân, Cà Mau",
        "path_with_type": "Xã Tân Hưng Tây, Huyện Phú Tân, Tỉnh Cà Mau",
        "code": "32227",
        "parent_code": "972"
    },
    "32228": {
        "name": "Rạch Chèo",
        "type": "xa",
        "slug": "rach-cheo",
        "name_with_type": "Xã Rạch Chèo",
        "path": "Rạch Chèo, Phú Tân, Cà Mau",
        "path_with_type": "Xã Rạch Chèo, Huyện Phú Tân, Tỉnh Cà Mau",
        "code": "32228",
        "parent_code": "972"
    },
    "32230": {
        "name": "Việt Khái",
        "type": "xa",
        "slug": "viet-khai",
        "name_with_type": "Xã Việt Khái",
        "path": "Việt Khái, Phú Tân, Cà Mau",
        "path_with_type": "Xã Việt Khái, Huyện Phú Tân, Tỉnh Cà Mau",
        "code": "32230",
        "parent_code": "972"
    },
    "32233": {
        "name": "Tam Giang Tây",
        "type": "xa",
        "slug": "tam-giang-tay",
        "name_with_type": "Xã Tam Giang Tây",
        "path": "Tam Giang Tây, Ngọc Hiển, Cà Mau",
        "path_with_type": "Xã Tam Giang Tây, Huyện Ngọc Hiển, Tỉnh Cà Mau",
        "code": "32233",
        "parent_code": "973"
    },
    "32236": {
        "name": "Tân Ân Tây",
        "type": "xa",
        "slug": "tan-an-tay",
        "name_with_type": "Xã Tân Ân Tây",
        "path": "Tân Ân Tây, Ngọc Hiển, Cà Mau",
        "path_with_type": "Xã Tân Ân Tây, Huyện Ngọc Hiển, Tỉnh Cà Mau",
        "code": "32236",
        "parent_code": "973"
    },
    "32239": {
        "name": "Viên An Đông",
        "type": "xa",
        "slug": "vien-an-dong",
        "name_with_type": "Xã Viên An Đông",
        "path": "Viên An Đông, Ngọc Hiển, Cà Mau",
        "path_with_type": "Xã Viên An Đông, Huyện Ngọc Hiển, Tỉnh Cà Mau",
        "code": "32239",
        "parent_code": "973"
    },
    "32242": {
        "name": "Viên An",
        "type": "xa",
        "slug": "vien-an",
        "name_with_type": "Xã Viên An",
        "path": "Viên An, Ngọc Hiển, Cà Mau",
        "path_with_type": "Xã Viên An, Huyện Ngọc Hiển, Tỉnh Cà Mau",
        "code": "32242",
        "parent_code": "973"
    },
    "32244": {
        "name": "Rạch Gốc",
        "type": "thi-tran",
        "slug": "rach-goc",
        "name_with_type": "Thị trấn Rạch Gốc",
        "path": "Rạch Gốc, Ngọc Hiển, Cà Mau",
        "path_with_type": "Thị trấn Rạch Gốc, Huyện Ngọc Hiển, Tỉnh Cà Mau",
        "code": "32244",
        "parent_code": "973"
    },
    "32245": {
        "name": "Tân Ân",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân Ân",
        "path": "Tân Ân, Ngọc Hiển, Cà Mau",
        "path_with_type": "Xã Tân Ân, Huyện Ngọc Hiển, Tỉnh Cà Mau",
        "code": "32245",
        "parent_code": "973"
    },
    "32248": {
        "name": "Đất Mũi",
        "type": "xa",
        "slug": "dat-mui",
        "name_with_type": "Xã Đất Mũi",
        "path": "Đất Mũi, Ngọc Hiển, Cà Mau",
        "path_with_type": "Xã Đất Mũi, Huyện Ngọc Hiển, Tỉnh Cà Mau",
        "code": "32248",
        "parent_code": "973"
    },
    "25741": {
        "name": "Hiệp Thành",
        "type": "phuong",
        "slug": "hiep-thanh",
        "name_with_type": "Phường Hiệp Thành",
        "path": "Hiệp Thành, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Hiệp Thành, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25741",
        "parent_code": "718"
    },
    "25744": {
        "name": "Phú Lợi",
        "type": "phuong",
        "slug": "phu-loi",
        "name_with_type": "Phường Phú Lợi",
        "path": "Phú Lợi, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Phú Lợi, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25744",
        "parent_code": "718"
    },
    "25747": {
        "name": "Phú Cường",
        "type": "phuong",
        "slug": "phu-cuong",
        "name_with_type": "Phường Phú Cường",
        "path": "Phú Cường, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Phú Cường, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25747",
        "parent_code": "718"
    },
    "25750": {
        "name": "Phú Hòa",
        "type": "phuong",
        "slug": "phu-hoa",
        "name_with_type": "Phường Phú Hòa",
        "path": "Phú Hòa, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Phú Hòa, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25750",
        "parent_code": "718"
    },
    "25753": {
        "name": "Phú Thọ",
        "type": "phuong",
        "slug": "phu-tho",
        "name_with_type": "Phường Phú Thọ",
        "path": "Phú Thọ, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Phú Thọ, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25753",
        "parent_code": "718"
    },
    "25756": {
        "name": "Chánh Nghĩa",
        "type": "phuong",
        "slug": "chanh-nghia",
        "name_with_type": "Phường Chánh Nghĩa",
        "path": "Chánh Nghĩa, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Chánh Nghĩa, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25756",
        "parent_code": "718"
    },
    "25759": {
        "name": "Định Hoà",
        "type": "phuong",
        "slug": "dinh-hoa",
        "name_with_type": "Phường Định Hoà",
        "path": "Định Hoà, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Định Hoà, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25759",
        "parent_code": "718"
    },
    "25760": {
        "name": "Hoà Phú",
        "type": "phuong",
        "slug": "hoa-phu",
        "name_with_type": "Phường Hoà Phú",
        "path": "Hoà Phú, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Hoà Phú, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25760",
        "parent_code": "718"
    },
    "25762": {
        "name": "Phú Mỹ",
        "type": "phuong",
        "slug": "phu-my",
        "name_with_type": "Phường Phú Mỹ",
        "path": "Phú Mỹ, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Phú Mỹ, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25762",
        "parent_code": "718"
    },
    "25763": {
        "name": "Phú Tân",
        "type": "phuong",
        "slug": "phu-tan",
        "name_with_type": "Phường Phú Tân",
        "path": "Phú Tân, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Phú Tân, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25763",
        "parent_code": "718"
    },
    "25765": {
        "name": "Tân An",
        "type": "phuong",
        "slug": "tan-an",
        "name_with_type": "Phường Tân An",
        "path": "Tân An, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Tân An, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25765",
        "parent_code": "718"
    },
    "25768": {
        "name": "Hiệp An",
        "type": "phuong",
        "slug": "hiep-an",
        "name_with_type": "Phường Hiệp An",
        "path": "Hiệp An, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Hiệp An, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25768",
        "parent_code": "718"
    },
    "25771": {
        "name": "Tương Bình Hiệp",
        "type": "phuong",
        "slug": "tuong-binh-hiep",
        "name_with_type": "Phường Tương Bình Hiệp",
        "path": "Tương Bình Hiệp, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Tương Bình Hiệp, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25771",
        "parent_code": "718"
    },
    "25774": {
        "name": "Chánh Mỹ",
        "type": "phuong",
        "slug": "chanh-my",
        "name_with_type": "Phường Chánh Mỹ",
        "path": "Chánh Mỹ, Thủ Dầu Một, Bình Dương",
        "path_with_type": "Phường Chánh Mỹ, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
        "code": "25774",
        "parent_code": "718"
    },
    "25816": {
        "name": "Trừ Văn Thố",
        "type": "xa",
        "slug": "tru-van-tho",
        "name_with_type": "Xã Trừ Văn Thố",
        "path": "Trừ Văn Thố, Bàu Bàng, Bình Dương",
        "path_with_type": "Xã Trừ Văn Thố, Huyện Bàu Bàng, Tỉnh Bình Dương",
        "code": "25816",
        "parent_code": "719"
    },
    "25819": {
        "name": "Cây Trường II",
        "type": "xa",
        "slug": "cay-truong-ii",
        "name_with_type": "Xã Cây Trường II",
        "path": "Cây Trường II, Bàu Bàng, Bình Dương",
        "path_with_type": "Xã Cây Trường II, Huyện Bàu Bàng, Tỉnh Bình Dương",
        "code": "25819",
        "parent_code": "719"
    },
    "25822": {
        "name": "Lai Uyên",
        "type": "xa",
        "slug": "lai-uyen",
        "name_with_type": "Xã Lai Uyên",
        "path": "Lai Uyên, Bàu Bàng, Bình Dương",
        "path_with_type": "Xã Lai Uyên, Huyện Bàu Bàng, Tỉnh Bình Dương",
        "code": "25822",
        "parent_code": "719"
    },
    "25825": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Bàu Bàng, Bình Dương",
        "path_with_type": "Xã Tân Hưng, Huyện Bàu Bàng, Tỉnh Bình Dương",
        "code": "25825",
        "parent_code": "719"
    },
    "25828": {
        "name": "Long Nguyên",
        "type": "xa",
        "slug": "long-nguyen",
        "name_with_type": "Xã Long Nguyên",
        "path": "Long Nguyên, Bàu Bàng, Bình Dương",
        "path_with_type": "Xã Long Nguyên, Huyện Bàu Bàng, Tỉnh Bình Dương",
        "code": "25828",
        "parent_code": "719"
    },
    "25831": {
        "name": "Hưng Hòa",
        "type": "xa",
        "slug": "hung-hoa",
        "name_with_type": "Xã Hưng Hòa",
        "path": "Hưng Hòa, Bàu Bàng, Bình Dương",
        "path_with_type": "Xã Hưng Hòa, Huyện Bàu Bàng, Tỉnh Bình Dương",
        "code": "25831",
        "parent_code": "719"
    },
    "25834": {
        "name": "Lai Hưng",
        "type": "xa",
        "slug": "lai-hung",
        "name_with_type": "Xã Lai Hưng",
        "path": "Lai Hưng, Bàu Bàng, Bình Dương",
        "path_with_type": "Xã Lai Hưng, Huyện Bàu Bàng, Tỉnh Bình Dương",
        "code": "25834",
        "parent_code": "719"
    },
    "25777": {
        "name": "Dầu Tiếng",
        "type": "thi-tran",
        "slug": "dau-tieng",
        "name_with_type": "Thị trấn Dầu Tiếng",
        "path": "Dầu Tiếng, Dầu Tiếng, Bình Dương",
        "path_with_type": "Thị trấn Dầu Tiếng, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25777",
        "parent_code": "720"
    },
    "25780": {
        "name": "Minh Hoà",
        "type": "xa",
        "slug": "minh-hoa",
        "name_with_type": "Xã Minh Hoà",
        "path": "Minh Hoà, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Minh Hoà, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25780",
        "parent_code": "720"
    },
    "25783": {
        "name": "Minh Thạnh",
        "type": "xa",
        "slug": "minh-thanh",
        "name_with_type": "Xã Minh Thạnh",
        "path": "Minh Thạnh, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Minh Thạnh, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25783",
        "parent_code": "720"
    },
    "25786": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Minh Tân, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25786",
        "parent_code": "720"
    },
    "25789": {
        "name": "Định An",
        "type": "xa",
        "slug": "dinh-an",
        "name_with_type": "Xã Định An",
        "path": "Định An, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Định An, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25789",
        "parent_code": "720"
    },
    "25792": {
        "name": "Long Hoà",
        "type": "xa",
        "slug": "long-hoa",
        "name_with_type": "Xã Long Hoà",
        "path": "Long Hoà, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Long Hoà, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25792",
        "parent_code": "720"
    },
    "25795": {
        "name": "Định Thành",
        "type": "xa",
        "slug": "dinh-thanh",
        "name_with_type": "Xã Định Thành",
        "path": "Định Thành, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Định Thành, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25795",
        "parent_code": "720"
    },
    "25798": {
        "name": "Định Hiệp",
        "type": "xa",
        "slug": "dinh-hiep",
        "name_with_type": "Xã Định Hiệp",
        "path": "Định Hiệp, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Định Hiệp, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25798",
        "parent_code": "720"
    },
    "25801": {
        "name": "An Lập",
        "type": "xa",
        "slug": "an-lap",
        "name_with_type": "Xã An Lập",
        "path": "An Lập, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã An Lập, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25801",
        "parent_code": "720"
    },
    "25804": {
        "name": "Long Tân",
        "type": "xa",
        "slug": "long-tan",
        "name_with_type": "Xã Long Tân",
        "path": "Long Tân, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Long Tân, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25804",
        "parent_code": "720"
    },
    "25807": {
        "name": "Thanh An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thanh An",
        "path": "Thanh An, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Thanh An, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25807",
        "parent_code": "720"
    },
    "25810": {
        "name": "Thanh Tuyền",
        "type": "xa",
        "slug": "thanh-tuyen",
        "name_with_type": "Xã Thanh Tuyền",
        "path": "Thanh Tuyền, Dầu Tiếng, Bình Dương",
        "path_with_type": "Xã Thanh Tuyền, Huyện Dầu Tiếng, Tỉnh Bình Dương",
        "code": "25810",
        "parent_code": "720"
    },
    "25813": {
        "name": "Mỹ Phước",
        "type": "phuong",
        "slug": "my-phuoc",
        "name_with_type": "Phường Mỹ Phước",
        "path": "Mỹ Phước, Bến Cát, Bình Dương",
        "path_with_type": "Phường Mỹ Phước, Thị xã Bến Cát, Tỉnh Bình Dương",
        "code": "25813",
        "parent_code": "721"
    },
    "25837": {
        "name": "Chánh Phú Hòa",
        "type": "phuong",
        "slug": "chanh-phu-hoa",
        "name_with_type": "Phường Chánh Phú Hòa",
        "path": "Chánh Phú Hòa, Bến Cát, Bình Dương",
        "path_with_type": "Phường Chánh Phú Hòa, Thị xã Bến Cát, Tỉnh Bình Dương",
        "code": "25837",
        "parent_code": "721"
    },
    "25840": {
        "name": "An Điền",
        "type": "xa",
        "slug": "an-dien",
        "name_with_type": "Xã An Điền",
        "path": "An Điền, Bến Cát, Bình Dương",
        "path_with_type": "Xã An Điền, Thị xã Bến Cát, Tỉnh Bình Dương",
        "code": "25840",
        "parent_code": "721"
    },
    "25843": {
        "name": "An Tây",
        "type": "xa",
        "slug": "an-tay",
        "name_with_type": "Xã An Tây",
        "path": "An Tây, Bến Cát, Bình Dương",
        "path_with_type": "Xã An Tây, Thị xã Bến Cát, Tỉnh Bình Dương",
        "code": "25843",
        "parent_code": "721"
    },
    "25846": {
        "name": "Thới Hòa",
        "type": "phuong",
        "slug": "thoi-hoa",
        "name_with_type": "Phường Thới Hòa",
        "path": "Thới Hòa, Bến Cát, Bình Dương",
        "path_with_type": "Phường Thới Hòa, Thị xã Bến Cát, Tỉnh Bình Dương",
        "code": "25846",
        "parent_code": "721"
    },
    "25849": {
        "name": "Hòa Lợi",
        "type": "phuong",
        "slug": "hoa-loi",
        "name_with_type": "Phường Hòa Lợi",
        "path": "Hòa Lợi, Bến Cát, Bình Dương",
        "path_with_type": "Phường Hòa Lợi, Thị xã Bến Cát, Tỉnh Bình Dương",
        "code": "25849",
        "parent_code": "721"
    },
    "25852": {
        "name": "Tân Định",
        "type": "phuong",
        "slug": "tan-dinh",
        "name_with_type": "Phường Tân Định",
        "path": "Tân Định, Bến Cát, Bình Dương",
        "path_with_type": "Phường Tân Định, Thị xã Bến Cát, Tỉnh Bình Dương",
        "code": "25852",
        "parent_code": "721"
    },
    "25855": {
        "name": "Phú An",
        "type": "xa",
        "slug": "phu-an",
        "name_with_type": "Xã Phú An",
        "path": "Phú An, Bến Cát, Bình Dương",
        "path_with_type": "Xã Phú An, Thị xã Bến Cát, Tỉnh Bình Dương",
        "code": "25855",
        "parent_code": "721"
    },
    "25858": {
        "name": "Phước Vĩnh",
        "type": "thi-tran",
        "slug": "phuoc-vinh",
        "name_with_type": "Thị trấn Phước Vĩnh",
        "path": "Phước Vĩnh, Phú Giáo, Bình Dương",
        "path_with_type": "Thị trấn Phước Vĩnh, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25858",
        "parent_code": "722"
    },
    "25861": {
        "name": "An Linh",
        "type": "xa",
        "slug": "an-linh",
        "name_with_type": "Xã An Linh",
        "path": "An Linh, Phú Giáo, Bình Dương",
        "path_with_type": "Xã An Linh, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25861",
        "parent_code": "722"
    },
    "25864": {
        "name": "Phước Sang",
        "type": "xa",
        "slug": "phuoc-sang",
        "name_with_type": "Xã Phước Sang",
        "path": "Phước Sang, Phú Giáo, Bình Dương",
        "path_with_type": "Xã Phước Sang, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25864",
        "parent_code": "722"
    },
    "25865": {
        "name": "An Thái",
        "type": "xa",
        "slug": "an-thai",
        "name_with_type": "Xã An Thái",
        "path": "An Thái, Phú Giáo, Bình Dương",
        "path_with_type": "Xã An Thái, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25865",
        "parent_code": "722"
    },
    "25867": {
        "name": "An Long",
        "type": "xa",
        "slug": "an-long",
        "name_with_type": "Xã An Long",
        "path": "An Long, Phú Giáo, Bình Dương",
        "path_with_type": "Xã An Long, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25867",
        "parent_code": "722"
    },
    "25870": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Phú Giáo, Bình Dương",
        "path_with_type": "Xã An Bình, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25870",
        "parent_code": "722"
    },
    "25873": {
        "name": "Tân Hiệp",
        "type": "xa",
        "slug": "tan-hiep",
        "name_with_type": "Xã Tân Hiệp",
        "path": "Tân Hiệp, Phú Giáo, Bình Dương",
        "path_with_type": "Xã Tân Hiệp, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25873",
        "parent_code": "722"
    },
    "25876": {
        "name": "Tam Lập",
        "type": "xa",
        "slug": "tam-lap",
        "name_with_type": "Xã Tam Lập",
        "path": "Tam Lập, Phú Giáo, Bình Dương",
        "path_with_type": "Xã Tam Lập, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25876",
        "parent_code": "722"
    },
    "25879": {
        "name": "Tân Long",
        "type": "xa",
        "slug": "tan-long",
        "name_with_type": "Xã Tân Long",
        "path": "Tân Long, Phú Giáo, Bình Dương",
        "path_with_type": "Xã Tân Long, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25879",
        "parent_code": "722"
    },
    "25882": {
        "name": "Vĩnh Hoà",
        "type": "xa",
        "slug": "vinh-hoa",
        "name_with_type": "Xã Vĩnh Hoà",
        "path": "Vĩnh Hoà, Phú Giáo, Bình Dương",
        "path_with_type": "Xã Vĩnh Hoà, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25882",
        "parent_code": "722"
    },
    "25885": {
        "name": "Phước Hoà",
        "type": "xa",
        "slug": "phuoc-hoa",
        "name_with_type": "Xã Phước Hoà",
        "path": "Phước Hoà, Phú Giáo, Bình Dương",
        "path_with_type": "Xã Phước Hoà, Huyện Phú Giáo, Tỉnh Bình Dương",
        "code": "25885",
        "parent_code": "722"
    },
    "25888": {
        "name": "Uyên Hưng",
        "type": "phuong",
        "slug": "uyen-hung",
        "name_with_type": "Phường Uyên Hưng",
        "path": "Uyên Hưng, Tân Uyên, Bình Dương",
        "path_with_type": "Phường Uyên Hưng, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25888",
        "parent_code": "723"
    },
    "25891": {
        "name": "Tân Phước Khánh",
        "type": "phuong",
        "slug": "tan-phuoc-khanh",
        "name_with_type": "Phường Tân Phước Khánh",
        "path": "Tân Phước Khánh, Tân Uyên, Bình Dương",
        "path_with_type": "Phường Tân Phước Khánh, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25891",
        "parent_code": "723"
    },
    "25912": {
        "name": "Vĩnh Tân",
        "type": "xa",
        "slug": "vinh-tan",
        "name_with_type": "Xã Vĩnh Tân",
        "path": "Vĩnh Tân, Tân Uyên, Bình Dương",
        "path_with_type": "Xã Vĩnh Tân, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25912",
        "parent_code": "723"
    },
    "25915": {
        "name": "Hội Nghĩa",
        "type": "xa",
        "slug": "hoi-nghia",
        "name_with_type": "Xã Hội Nghĩa",
        "path": "Hội Nghĩa, Tân Uyên, Bình Dương",
        "path_with_type": "Xã Hội Nghĩa, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25915",
        "parent_code": "723"
    },
    "25920": {
        "name": "Tân Hiệp",
        "type": "phuong",
        "slug": "tan-hiep",
        "name_with_type": "Phường Tân Hiệp",
        "path": "Tân Hiệp, Tân Uyên, Bình Dương",
        "path_with_type": "Phường Tân Hiệp, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25920",
        "parent_code": "723"
    },
    "25921": {
        "name": "Khánh Bình",
        "type": "phuong",
        "slug": "khanh-binh",
        "name_with_type": "Phường Khánh Bình",
        "path": "Khánh Bình, Tân Uyên, Bình Dương",
        "path_with_type": "Phường Khánh Bình, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25921",
        "parent_code": "723"
    },
    "25924": {
        "name": "Phú Chánh",
        "type": "xa",
        "slug": "phu-chanh",
        "name_with_type": "Xã Phú Chánh",
        "path": "Phú Chánh, Tân Uyên, Bình Dương",
        "path_with_type": "Xã Phú Chánh, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25924",
        "parent_code": "723"
    },
    "25930": {
        "name": "Bạch Đằng",
        "type": "xa",
        "slug": "bach-dang",
        "name_with_type": "Xã Bạch Đằng",
        "path": "Bạch Đằng, Tân Uyên, Bình Dương",
        "path_with_type": "Xã Bạch Đằng, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25930",
        "parent_code": "723"
    },
    "25933": {
        "name": "Tân Vĩnh Hiệp",
        "type": "xa",
        "slug": "tan-vinh-hiep",
        "name_with_type": "Xã Tân Vĩnh Hiệp",
        "path": "Tân Vĩnh Hiệp, Tân Uyên, Bình Dương",
        "path_with_type": "Xã Tân Vĩnh Hiệp, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25933",
        "parent_code": "723"
    },
    "25936": {
        "name": "Thạnh Phước",
        "type": "phuong",
        "slug": "thanh-phuoc",
        "name_with_type": "Phường Thạnh Phước",
        "path": "Thạnh Phước, Tân Uyên, Bình Dương",
        "path_with_type": "Phường Thạnh Phước, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25936",
        "parent_code": "723"
    },
    "25937": {
        "name": "Thạnh Hội",
        "type": "xa",
        "slug": "thanh-hoi",
        "name_with_type": "Xã Thạnh Hội",
        "path": "Thạnh Hội, Tân Uyên, Bình Dương",
        "path_with_type": "Xã Thạnh Hội, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25937",
        "parent_code": "723"
    },
    "25939": {
        "name": "Thái Hòa",
        "type": "phuong",
        "slug": "thai-hoa",
        "name_with_type": "Phường Thái Hòa",
        "path": "Thái Hòa, Tân Uyên, Bình Dương",
        "path_with_type": "Phường Thái Hòa, Thị xã Tân Uyên, Tỉnh Bình Dương",
        "code": "25939",
        "parent_code": "723"
    },
    "25942": {
        "name": "Dĩ An",
        "type": "phuong",
        "slug": "di-an",
        "name_with_type": "Phường Dĩ An",
        "path": "Dĩ An, Dĩ An, Bình Dương",
        "path_with_type": "Phường Dĩ An, Thị xã Dĩ An, Tỉnh Bình Dương",
        "code": "25942",
        "parent_code": "724"
    },
    "25945": {
        "name": "Tân Bình",
        "type": "phuong",
        "slug": "tan-binh",
        "name_with_type": "Phường Tân Bình",
        "path": "Tân Bình, Dĩ An, Bình Dương",
        "path_with_type": "Phường Tân Bình, Thị xã Dĩ An, Tỉnh Bình Dương",
        "code": "25945",
        "parent_code": "724"
    },
    "25948": {
        "name": "Tân Đông Hiệp",
        "type": "phuong",
        "slug": "tan-dong-hiep",
        "name_with_type": "Phường Tân Đông Hiệp",
        "path": "Tân Đông Hiệp, Dĩ An, Bình Dương",
        "path_with_type": "Phường Tân Đông Hiệp, Thị xã Dĩ An, Tỉnh Bình Dương",
        "code": "25948",
        "parent_code": "724"
    },
    "25951": {
        "name": "Bình An",
        "type": "phuong",
        "slug": "binh-an",
        "name_with_type": "Phường Bình An",
        "path": "Bình An, Dĩ An, Bình Dương",
        "path_with_type": "Phường Bình An, Thị xã Dĩ An, Tỉnh Bình Dương",
        "code": "25951",
        "parent_code": "724"
    },
    "25954": {
        "name": "Bình Thắng",
        "type": "phuong",
        "slug": "binh-thang",
        "name_with_type": "Phường Bình Thắng",
        "path": "Bình Thắng, Dĩ An, Bình Dương",
        "path_with_type": "Phường Bình Thắng, Thị xã Dĩ An, Tỉnh Bình Dương",
        "code": "25954",
        "parent_code": "724"
    },
    "25957": {
        "name": "Đông Hòa",
        "type": "phuong",
        "slug": "dong-hoa",
        "name_with_type": "Phường Đông Hòa",
        "path": "Đông Hòa, Dĩ An, Bình Dương",
        "path_with_type": "Phường Đông Hòa, Thị xã Dĩ An, Tỉnh Bình Dương",
        "code": "25957",
        "parent_code": "724"
    },
    "25960": {
        "name": "An Bình",
        "type": "phuong",
        "slug": "an-binh",
        "name_with_type": "Phường An Bình",
        "path": "An Bình, Dĩ An, Bình Dương",
        "path_with_type": "Phường An Bình, Thị xã Dĩ An, Tỉnh Bình Dương",
        "code": "25960",
        "parent_code": "724"
    },
    "25963": {
        "name": "An Thạnh",
        "type": "phuong",
        "slug": "an-thanh",
        "name_with_type": "Phường An Thạnh",
        "path": "An Thạnh, Thuận An, Bình Dương",
        "path_with_type": "Phường An Thạnh, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25963",
        "parent_code": "725"
    },
    "25966": {
        "name": "Lái Thiêu",
        "type": "phuong",
        "slug": "lai-thieu",
        "name_with_type": "Phường Lái Thiêu",
        "path": "Lái Thiêu, Thuận An, Bình Dương",
        "path_with_type": "Phường Lái Thiêu, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25966",
        "parent_code": "725"
    },
    "25969": {
        "name": "Bình Chuẩn",
        "type": "phuong",
        "slug": "binh-chuan",
        "name_with_type": "Phường Bình Chuẩn",
        "path": "Bình Chuẩn, Thuận An, Bình Dương",
        "path_with_type": "Phường Bình Chuẩn, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25969",
        "parent_code": "725"
    },
    "25972": {
        "name": "Thuận Giao",
        "type": "phuong",
        "slug": "thuan-giao",
        "name_with_type": "Phường Thuận Giao",
        "path": "Thuận Giao, Thuận An, Bình Dương",
        "path_with_type": "Phường Thuận Giao, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25972",
        "parent_code": "725"
    },
    "25975": {
        "name": "An Phú",
        "type": "phuong",
        "slug": "an-phu",
        "name_with_type": "Phường An Phú",
        "path": "An Phú, Thuận An, Bình Dương",
        "path_with_type": "Phường An Phú, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25975",
        "parent_code": "725"
    },
    "25978": {
        "name": "Hưng Định",
        "type": "phuong",
        "slug": "hung-dinh",
        "name_with_type": "Phường Hưng Định",
        "path": "Hưng Định, Thuận An, Bình Dương",
        "path_with_type": "Phường Hưng Định, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25978",
        "parent_code": "725"
    },
    "25981": {
        "name": "An Sơn",
        "type": "xa",
        "slug": "an-son",
        "name_with_type": "Xã An Sơn",
        "path": "An Sơn, Thuận An, Bình Dương",
        "path_with_type": "Xã An Sơn, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25981",
        "parent_code": "725"
    },
    "25984": {
        "name": "Bình Nhâm",
        "type": "phuong",
        "slug": "binh-nham",
        "name_with_type": "Phường Bình Nhâm",
        "path": "Bình Nhâm, Thuận An, Bình Dương",
        "path_with_type": "Phường Bình Nhâm, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25984",
        "parent_code": "725"
    },
    "25987": {
        "name": "Bình Hòa",
        "type": "phuong",
        "slug": "binh-hoa",
        "name_with_type": "Phường Bình Hòa",
        "path": "Bình Hòa, Thuận An, Bình Dương",
        "path_with_type": "Phường Bình Hòa, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25987",
        "parent_code": "725"
    },
    "25990": {
        "name": "Vĩnh Phú",
        "type": "phuong",
        "slug": "vinh-phu",
        "name_with_type": "Phường Vĩnh Phú",
        "path": "Vĩnh Phú, Thuận An, Bình Dương",
        "path_with_type": "Phường Vĩnh Phú, Thị xã Thuận An, Tỉnh Bình Dương",
        "code": "25990",
        "parent_code": "725"
    },
    "25894": {
        "name": "Tân Định",
        "type": "xa",
        "slug": "tan-dinh",
        "name_with_type": "Xã Tân Định",
        "path": "Tân Định, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Tân Định, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25894",
        "parent_code": "726"
    },
    "25897": {
        "name": "Bình Mỹ",
        "type": "xa",
        "slug": "binh-my",
        "name_with_type": "Xã Bình Mỹ",
        "path": "Bình Mỹ, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Bình Mỹ, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25897",
        "parent_code": "726"
    },
    "25900": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Tân Bình, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25900",
        "parent_code": "726"
    },
    "25903": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Tân Lập, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25903",
        "parent_code": "726"
    },
    "25906": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Tân Thành, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25906",
        "parent_code": "726"
    },
    "25907": {
        "name": "Đất Cuốc",
        "type": "xa",
        "slug": "dat-cuoc",
        "name_with_type": "Xã Đất Cuốc",
        "path": "Đất Cuốc, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Đất Cuốc, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25907",
        "parent_code": "726"
    },
    "25908": {
        "name": "Hiếu Liêm",
        "type": "xa",
        "slug": "hieu-liem",
        "name_with_type": "Xã Hiếu Liêm",
        "path": "Hiếu Liêm, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Hiếu Liêm, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25908",
        "parent_code": "726"
    },
    "25909": {
        "name": "Lạc An",
        "type": "xa",
        "slug": "lac-an",
        "name_with_type": "Xã Lạc An",
        "path": "Lạc An, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Lạc An, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25909",
        "parent_code": "726"
    },
    "25918": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Tân Mỹ, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25918",
        "parent_code": "726"
    },
    "25927": {
        "name": "Thường Tân",
        "type": "xa",
        "slug": "thuong-tan",
        "name_with_type": "Xã Thường Tân",
        "path": "Thường Tân, Bắc Tân Uyên, Bình Dương",
        "path_with_type": "Xã Thường Tân, Huyện Bắc Tân Uyên, Tỉnh Bình Dương",
        "code": "25927",
        "parent_code": "726"
    },
    "31117": {
        "name": "Cái Khế",
        "type": "phuong",
        "slug": "cai-khe",
        "name_with_type": "Phường Cái Khế",
        "path": "Cái Khế, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường Cái Khế, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31117",
        "parent_code": "916"
    },
    "31120": {
        "name": "An Hòa",
        "type": "phuong",
        "slug": "an-hoa",
        "name_with_type": "Phường An Hòa",
        "path": "An Hòa, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường An Hòa, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31120",
        "parent_code": "916"
    },
    "31123": {
        "name": "Thới Bình",
        "type": "phuong",
        "slug": "thoi-binh",
        "name_with_type": "Phường Thới Bình",
        "path": "Thới Bình, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường Thới Bình, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31123",
        "parent_code": "916"
    },
    "31126": {
        "name": "An Nghiệp",
        "type": "phuong",
        "slug": "an-nghiep",
        "name_with_type": "Phường An Nghiệp",
        "path": "An Nghiệp, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường An Nghiệp, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31126",
        "parent_code": "916"
    },
    "31129": {
        "name": "An Cư",
        "type": "phuong",
        "slug": "an-cu",
        "name_with_type": "Phường An Cư",
        "path": "An Cư, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường An Cư, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31129",
        "parent_code": "916"
    },
    "31132": {
        "name": "An Hội",
        "type": "phuong",
        "slug": "an-hoi",
        "name_with_type": "Phường An Hội",
        "path": "An Hội, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường An Hội, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31132",
        "parent_code": "916"
    },
    "31135": {
        "name": "Tân An",
        "type": "phuong",
        "slug": "tan-an",
        "name_with_type": "Phường Tân An",
        "path": "Tân An, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường Tân An, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31135",
        "parent_code": "916"
    },
    "31138": {
        "name": "An Lạc",
        "type": "phuong",
        "slug": "an-lac",
        "name_with_type": "Phường An Lạc",
        "path": "An Lạc, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường An Lạc, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31138",
        "parent_code": "916"
    },
    "31141": {
        "name": "An Phú",
        "type": "phuong",
        "slug": "an-phu",
        "name_with_type": "Phường An Phú",
        "path": "An Phú, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường An Phú, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31141",
        "parent_code": "916"
    },
    "31144": {
        "name": "Xuân Khánh",
        "type": "phuong",
        "slug": "xuan-khanh",
        "name_with_type": "Phường Xuân Khánh",
        "path": "Xuân Khánh, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường Xuân Khánh, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31144",
        "parent_code": "916"
    },
    "31147": {
        "name": "Hưng Lợi",
        "type": "phuong",
        "slug": "hung-loi",
        "name_with_type": "Phường Hưng Lợi",
        "path": "Hưng Lợi, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường Hưng Lợi, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31147",
        "parent_code": "916"
    },
    "31149": {
        "name": "An Khánh",
        "type": "phuong",
        "slug": "an-khanh",
        "name_with_type": "Phường An Khánh",
        "path": "An Khánh, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường An Khánh, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31149",
        "parent_code": "916"
    },
    "31150": {
        "name": "An Bình",
        "type": "phuong",
        "slug": "an-binh",
        "name_with_type": "Phường An Bình",
        "path": "An Bình, Ninh Kiều, Cần Thơ",
        "path_with_type": "Phường An Bình, Quận Ninh Kiều, Thành phố Cần Thơ",
        "code": "31150",
        "parent_code": "916"
    },
    "31153": {
        "name": "Châu Văn Liêm",
        "type": "phuong",
        "slug": "chau-van-liem",
        "name_with_type": "Phường Châu Văn Liêm",
        "path": "Châu Văn Liêm, Ô Môn, Cần Thơ",
        "path_with_type": "Phường Châu Văn Liêm, Quận Ô Môn, Thành phố Cần Thơ",
        "code": "31153",
        "parent_code": "917"
    },
    "31154": {
        "name": "Thới Hòa",
        "type": "phuong",
        "slug": "thoi-hoa",
        "name_with_type": "Phường Thới Hòa",
        "path": "Thới Hòa, Ô Môn, Cần Thơ",
        "path_with_type": "Phường Thới Hòa, Quận Ô Môn, Thành phố Cần Thơ",
        "code": "31154",
        "parent_code": "917"
    },
    "31156": {
        "name": "Thới Long",
        "type": "phuong",
        "slug": "thoi-long",
        "name_with_type": "Phường Thới Long",
        "path": "Thới Long, Ô Môn, Cần Thơ",
        "path_with_type": "Phường Thới Long, Quận Ô Môn, Thành phố Cần Thơ",
        "code": "31156",
        "parent_code": "917"
    },
    "31157": {
        "name": "Long Hưng",
        "type": "phuong",
        "slug": "long-hung",
        "name_with_type": "Phường Long Hưng",
        "path": "Long Hưng, Ô Môn, Cần Thơ",
        "path_with_type": "Phường Long Hưng, Quận Ô Môn, Thành phố Cần Thơ",
        "code": "31157",
        "parent_code": "917"
    },
    "31159": {
        "name": "Thới An",
        "type": "phuong",
        "slug": "thoi-an",
        "name_with_type": "Phường Thới An",
        "path": "Thới An, Ô Môn, Cần Thơ",
        "path_with_type": "Phường Thới An, Quận Ô Môn, Thành phố Cần Thơ",
        "code": "31159",
        "parent_code": "917"
    },
    "31162": {
        "name": "Phước Thới",
        "type": "phuong",
        "slug": "phuoc-thoi",
        "name_with_type": "Phường Phước Thới",
        "path": "Phước Thới, Ô Môn, Cần Thơ",
        "path_with_type": "Phường Phước Thới, Quận Ô Môn, Thành phố Cần Thơ",
        "code": "31162",
        "parent_code": "917"
    },
    "31165": {
        "name": "Trường Lạc",
        "type": "phuong",
        "slug": "truong-lac",
        "name_with_type": "Phường Trường Lạc",
        "path": "Trường Lạc, Ô Môn, Cần Thơ",
        "path_with_type": "Phường Trường Lạc, Quận Ô Môn, Thành phố Cần Thơ",
        "code": "31165",
        "parent_code": "917"
    },
    "31168": {
        "name": "Bình Thủy",
        "type": "phuong",
        "slug": "binh-thuy",
        "name_with_type": "Phường Bình Thủy",
        "path": "Bình Thủy, Bình Thuỷ, Cần Thơ",
        "path_with_type": "Phường Bình Thủy, Quận Bình Thuỷ, Thành phố Cần Thơ",
        "code": "31168",
        "parent_code": "918"
    },
    "31169": {
        "name": "Trà An",
        "type": "phuong",
        "slug": "tra-an",
        "name_with_type": "Phường Trà An",
        "path": "Trà An, Bình Thuỷ, Cần Thơ",
        "path_with_type": "Phường Trà An, Quận Bình Thuỷ, Thành phố Cần Thơ",
        "code": "31169",
        "parent_code": "918"
    },
    "31171": {
        "name": "Trà Nóc",
        "type": "phuong",
        "slug": "tra-noc",
        "name_with_type": "Phường Trà Nóc",
        "path": "Trà Nóc, Bình Thuỷ, Cần Thơ",
        "path_with_type": "Phường Trà Nóc, Quận Bình Thuỷ, Thành phố Cần Thơ",
        "code": "31171",
        "parent_code": "918"
    },
    "31174": {
        "name": "Thới An Đông",
        "type": "phuong",
        "slug": "thoi-an-dong",
        "name_with_type": "Phường Thới An Đông",
        "path": "Thới An Đông, Bình Thuỷ, Cần Thơ",
        "path_with_type": "Phường Thới An Đông, Quận Bình Thuỷ, Thành phố Cần Thơ",
        "code": "31174",
        "parent_code": "918"
    },
    "31177": {
        "name": "An Thới",
        "type": "phuong",
        "slug": "an-thoi",
        "name_with_type": "Phường An Thới",
        "path": "An Thới, Bình Thuỷ, Cần Thơ",
        "path_with_type": "Phường An Thới, Quận Bình Thuỷ, Thành phố Cần Thơ",
        "code": "31177",
        "parent_code": "918"
    },
    "31178": {
        "name": "Bùi Hữu Nghĩa",
        "type": "phuong",
        "slug": "bui-huu-nghia",
        "name_with_type": "Phường Bùi Hữu Nghĩa",
        "path": "Bùi Hữu Nghĩa, Bình Thuỷ, Cần Thơ",
        "path_with_type": "Phường Bùi Hữu Nghĩa, Quận Bình Thuỷ, Thành phố Cần Thơ",
        "code": "31178",
        "parent_code": "918"
    },
    "31180": {
        "name": "Long Hòa",
        "type": "phuong",
        "slug": "long-hoa",
        "name_with_type": "Phường Long Hòa",
        "path": "Long Hòa, Bình Thuỷ, Cần Thơ",
        "path_with_type": "Phường Long Hòa, Quận Bình Thuỷ, Thành phố Cần Thơ",
        "code": "31180",
        "parent_code": "918"
    },
    "31183": {
        "name": "Long Tuyền",
        "type": "phuong",
        "slug": "long-tuyen",
        "name_with_type": "Phường Long Tuyền",
        "path": "Long Tuyền, Bình Thuỷ, Cần Thơ",
        "path_with_type": "Phường Long Tuyền, Quận Bình Thuỷ, Thành phố Cần Thơ",
        "code": "31183",
        "parent_code": "918"
    },
    "31186": {
        "name": "Lê Bình",
        "type": "phuong",
        "slug": "le-binh",
        "name_with_type": "Phường Lê Bình",
        "path": "Lê Bình, Cái Răng, Cần Thơ",
        "path_with_type": "Phường Lê Bình, Quận Cái Răng, Thành phố Cần Thơ",
        "code": "31186",
        "parent_code": "919"
    },
    "31189": {
        "name": "Hưng Phú",
        "type": "phuong",
        "slug": "hung-phu",
        "name_with_type": "Phường Hưng Phú",
        "path": "Hưng Phú, Cái Răng, Cần Thơ",
        "path_with_type": "Phường Hưng Phú, Quận Cái Răng, Thành phố Cần Thơ",
        "code": "31189",
        "parent_code": "919"
    },
    "31192": {
        "name": "Hưng Thạnh",
        "type": "phuong",
        "slug": "hung-thanh",
        "name_with_type": "Phường Hưng Thạnh",
        "path": "Hưng Thạnh, Cái Răng, Cần Thơ",
        "path_with_type": "Phường Hưng Thạnh, Quận Cái Răng, Thành phố Cần Thơ",
        "code": "31192",
        "parent_code": "919"
    },
    "31195": {
        "name": "Ba Láng",
        "type": "phuong",
        "slug": "ba-lang",
        "name_with_type": "Phường Ba Láng",
        "path": "Ba Láng, Cái Răng, Cần Thơ",
        "path_with_type": "Phường Ba Láng, Quận Cái Răng, Thành phố Cần Thơ",
        "code": "31195",
        "parent_code": "919"
    },
    "31198": {
        "name": "Thường Thạnh",
        "type": "phuong",
        "slug": "thuong-thanh",
        "name_with_type": "Phường Thường Thạnh",
        "path": "Thường Thạnh, Cái Răng, Cần Thơ",
        "path_with_type": "Phường Thường Thạnh, Quận Cái Răng, Thành phố Cần Thơ",
        "code": "31198",
        "parent_code": "919"
    },
    "31201": {
        "name": "Phú Thứ",
        "type": "phuong",
        "slug": "phu-thu",
        "name_with_type": "Phường Phú Thứ",
        "path": "Phú Thứ, Cái Răng, Cần Thơ",
        "path_with_type": "Phường Phú Thứ, Quận Cái Răng, Thành phố Cần Thơ",
        "code": "31201",
        "parent_code": "919"
    },
    "31204": {
        "name": "Tân Phú",
        "type": "phuong",
        "slug": "tan-phu",
        "name_with_type": "Phường Tân Phú",
        "path": "Tân Phú, Cái Răng, Cần Thơ",
        "path_with_type": "Phường Tân Phú, Quận Cái Răng, Thành phố Cần Thơ",
        "code": "31204",
        "parent_code": "919"
    },
    "31207": {
        "name": "Thốt Nốt",
        "type": "phuong",
        "slug": "thot-not",
        "name_with_type": "Phường Thốt Nốt",
        "path": "Thốt Nốt, Thốt Nốt, Cần Thơ",
        "path_with_type": "Phường Thốt Nốt, Quận Thốt Nốt, Thành phố Cần Thơ",
        "code": "31207",
        "parent_code": "923"
    },
    "31210": {
        "name": "Thới Thuận",
        "type": "phuong",
        "slug": "thoi-thuan",
        "name_with_type": "Phường Thới Thuận",
        "path": "Thới Thuận, Thốt Nốt, Cần Thơ",
        "path_with_type": "Phường Thới Thuận, Quận Thốt Nốt, Thành phố Cần Thơ",
        "code": "31210",
        "parent_code": "923"
    },
    "31212": {
        "name": "Thuận An",
        "type": "phuong",
        "slug": "thuan-an",
        "name_with_type": "Phường Thuận An",
        "path": "Thuận An, Thốt Nốt, Cần Thơ",
        "path_with_type": "Phường Thuận An, Quận Thốt Nốt, Thành phố Cần Thơ",
        "code": "31212",
        "parent_code": "923"
    },
    "31213": {
        "name": "Tân Lộc",
        "type": "phuong",
        "slug": "tan-loc",
        "name_with_type": "Phường Tân Lộc",
        "path": "Tân Lộc, Thốt Nốt, Cần Thơ",
        "path_with_type": "Phường Tân Lộc, Quận Thốt Nốt, Thành phố Cần Thơ",
        "code": "31213",
        "parent_code": "923"
    },
    "31216": {
        "name": "Trung Nhứt",
        "type": "phuong",
        "slug": "trung-nhut",
        "name_with_type": "Phường Trung Nhứt",
        "path": "Trung Nhứt, Thốt Nốt, Cần Thơ",
        "path_with_type": "Phường Trung Nhứt, Quận Thốt Nốt, Thành phố Cần Thơ",
        "code": "31216",
        "parent_code": "923"
    },
    "31217": {
        "name": "Thạnh Hoà",
        "type": "phuong",
        "slug": "thanh-hoa",
        "name_with_type": "Phường Thạnh Hoà",
        "path": "Thạnh Hoà, Thốt Nốt, Cần Thơ",
        "path_with_type": "Phường Thạnh Hoà, Quận Thốt Nốt, Thành phố Cần Thơ",
        "code": "31217",
        "parent_code": "923"
    },
    "31219": {
        "name": "Trung Kiên",
        "type": "phuong",
        "slug": "trung-kien",
        "name_with_type": "Phường Trung Kiên",
        "path": "Trung Kiên, Thốt Nốt, Cần Thơ",
        "path_with_type": "Phường Trung Kiên, Quận Thốt Nốt, Thành phố Cần Thơ",
        "code": "31219",
        "parent_code": "923"
    },
    "31227": {
        "name": "Tân Hưng",
        "type": "phuong",
        "slug": "tan-hung",
        "name_with_type": "Phường Tân Hưng",
        "path": "Tân Hưng, Thốt Nốt, Cần Thơ",
        "path_with_type": "Phường Tân Hưng, Quận Thốt Nốt, Thành phố Cần Thơ",
        "code": "31227",
        "parent_code": "923"
    },
    "31228": {
        "name": "Thuận Hưng",
        "type": "phuong",
        "slug": "thuan-hung",
        "name_with_type": "Phường Thuận Hưng",
        "path": "Thuận Hưng, Thốt Nốt, Cần Thơ",
        "path_with_type": "Phường Thuận Hưng, Quận Thốt Nốt, Thành phố Cần Thơ",
        "code": "31228",
        "parent_code": "923"
    },
    "31211": {
        "name": "Vĩnh Bình",
        "type": "xa",
        "slug": "vinh-binh",
        "name_with_type": "Xã Vĩnh Bình",
        "path": "Vĩnh Bình, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Xã Vĩnh Bình, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31211",
        "parent_code": "924"
    },
    "31231": {
        "name": "Thanh An",
        "type": "thi-tran",
        "slug": "thanh-an",
        "name_with_type": "Thị trấn Thanh An",
        "path": "Thanh An, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Thị trấn Thanh An, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31231",
        "parent_code": "924"
    },
    "31232": {
        "name": "Vĩnh Thạnh",
        "type": "thi-tran",
        "slug": "vinh-thanh",
        "name_with_type": "Thị trấn Vĩnh Thạnh",
        "path": "Vĩnh Thạnh, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Thị trấn Vĩnh Thạnh, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31232",
        "parent_code": "924"
    },
    "31234": {
        "name": "Thạnh Mỹ",
        "type": "xa",
        "slug": "thanh-my",
        "name_with_type": "Xã Thạnh Mỹ",
        "path": "Thạnh Mỹ, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Xã Thạnh Mỹ, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31234",
        "parent_code": "924"
    },
    "31237": {
        "name": "Vĩnh Trinh",
        "type": "xa",
        "slug": "vinh-trinh",
        "name_with_type": "Xã Vĩnh Trinh",
        "path": "Vĩnh Trinh, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Xã Vĩnh Trinh, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31237",
        "parent_code": "924"
    },
    "31240": {
        "name": "Thạnh An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thạnh An",
        "path": "Thạnh An, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Xã Thạnh An, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31240",
        "parent_code": "924"
    },
    "31241": {
        "name": "Thạnh Tiến",
        "type": "xa",
        "slug": "thanh-tien",
        "name_with_type": "Xã Thạnh Tiến",
        "path": "Thạnh Tiến, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Xã Thạnh Tiến, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31241",
        "parent_code": "924"
    },
    "31243": {
        "name": "Thạnh Thắng",
        "type": "xa",
        "slug": "thanh-thang",
        "name_with_type": "Xã Thạnh Thắng",
        "path": "Thạnh Thắng, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Xã Thạnh Thắng, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31243",
        "parent_code": "924"
    },
    "31244": {
        "name": "Thạnh Lợi",
        "type": "xa",
        "slug": "thanh-loi",
        "name_with_type": "Xã Thạnh Lợi",
        "path": "Thạnh Lợi, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Xã Thạnh Lợi, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31244",
        "parent_code": "924"
    },
    "31246": {
        "name": "Thạnh Qưới",
        "type": "xa",
        "slug": "thanh-quoi",
        "name_with_type": "Xã Thạnh Qưới",
        "path": "Thạnh Qưới, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Xã Thạnh Qưới, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31246",
        "parent_code": "924"
    },
    "31252": {
        "name": "Thạnh Lộc",
        "type": "xa",
        "slug": "thanh-loc",
        "name_with_type": "Xã Thạnh Lộc",
        "path": "Thạnh Lộc, Vĩnh Thạnh, Cần Thơ",
        "path_with_type": "Xã Thạnh Lộc, Huyện Vĩnh Thạnh, Thành phố Cần Thơ",
        "code": "31252",
        "parent_code": "924"
    },
    "31222": {
        "name": "Trung An",
        "type": "xa",
        "slug": "trung-an",
        "name_with_type": "Xã Trung An",
        "path": "Trung An, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Xã Trung An, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31222",
        "parent_code": "925"
    },
    "31225": {
        "name": "Trung Thạnh",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thạnh",
        "path": "Trung Thạnh, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Xã Trung Thạnh, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31225",
        "parent_code": "925"
    },
    "31249": {
        "name": "Thạnh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thạnh Phú",
        "path": "Thạnh Phú, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Xã Thạnh Phú, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31249",
        "parent_code": "925"
    },
    "31255": {
        "name": "Trung Hưng",
        "type": "xa",
        "slug": "trung-hung",
        "name_with_type": "Xã Trung Hưng",
        "path": "Trung Hưng, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Xã Trung Hưng, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31255",
        "parent_code": "925"
    },
    "31261": {
        "name": "Cờ Đỏ",
        "type": "thi-tran",
        "slug": "co-do",
        "name_with_type": "Thị trấn Cờ Đỏ",
        "path": "Cờ Đỏ, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Thị trấn Cờ Đỏ, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31261",
        "parent_code": "925"
    },
    "31264": {
        "name": "Thới Hưng",
        "type": "xa",
        "slug": "thoi-hung",
        "name_with_type": "Xã Thới Hưng",
        "path": "Thới Hưng, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Xã Thới Hưng, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31264",
        "parent_code": "925"
    },
    "31273": {
        "name": "Đông Hiệp",
        "type": "xa",
        "slug": "dong-hiep",
        "name_with_type": "Xã Đông Hiệp",
        "path": "Đông Hiệp, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Xã Đông Hiệp, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31273",
        "parent_code": "925"
    },
    "31274": {
        "name": "Đông Thắng",
        "type": "xa",
        "slug": "dong-thang",
        "name_with_type": "Xã Đông Thắng",
        "path": "Đông Thắng, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Xã Đông Thắng, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31274",
        "parent_code": "925"
    },
    "31276": {
        "name": "Thới Đông",
        "type": "xa",
        "slug": "thoi-dong",
        "name_with_type": "Xã Thới Đông",
        "path": "Thới Đông, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Xã Thới Đông, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31276",
        "parent_code": "925"
    },
    "31277": {
        "name": "Thới Xuân",
        "type": "xa",
        "slug": "thoi-xuan",
        "name_with_type": "Xã Thới Xuân",
        "path": "Thới Xuân, Cờ Đỏ, Cần Thơ",
        "path_with_type": "Xã Thới Xuân, Huyện Cờ Đỏ, Thành phố Cần Thơ",
        "code": "31277",
        "parent_code": "925"
    },
    "31299": {
        "name": "Phong Điền",
        "type": "thi-tran",
        "slug": "phong-dien",
        "name_with_type": "Thị trấn Phong Điền",
        "path": "Phong Điền, Phong Điền, Cần Thơ",
        "path_with_type": "Thị trấn Phong Điền, Huyện Phong Điền, Thành phố Cần Thơ",
        "code": "31299",
        "parent_code": "926"
    },
    "31300": {
        "name": "Nhơn Ái",
        "type": "xa",
        "slug": "nhon-ai",
        "name_with_type": "Xã Nhơn Ái",
        "path": "Nhơn Ái, Phong Điền, Cần Thơ",
        "path_with_type": "Xã Nhơn Ái, Huyện Phong Điền, Thành phố Cần Thơ",
        "code": "31300",
        "parent_code": "926"
    },
    "31303": {
        "name": "Giai Xuân",
        "type": "xa",
        "slug": "giai-xuan",
        "name_with_type": "Xã Giai Xuân",
        "path": "Giai Xuân, Phong Điền, Cần Thơ",
        "path_with_type": "Xã Giai Xuân, Huyện Phong Điền, Thành phố Cần Thơ",
        "code": "31303",
        "parent_code": "926"
    },
    "31306": {
        "name": "Tân Thới",
        "type": "xa",
        "slug": "tan-thoi",
        "name_with_type": "Xã Tân Thới",
        "path": "Tân Thới, Phong Điền, Cần Thơ",
        "path_with_type": "Xã Tân Thới, Huyện Phong Điền, Thành phố Cần Thơ",
        "code": "31306",
        "parent_code": "926"
    },
    "31309": {
        "name": "Trường Long",
        "type": "xa",
        "slug": "truong-long",
        "name_with_type": "Xã Trường Long",
        "path": "Trường Long, Phong Điền, Cần Thơ",
        "path_with_type": "Xã Trường Long, Huyện Phong Điền, Thành phố Cần Thơ",
        "code": "31309",
        "parent_code": "926"
    },
    "31312": {
        "name": "Mỹ Khánh",
        "type": "xa",
        "slug": "my-khanh",
        "name_with_type": "Xã Mỹ Khánh",
        "path": "Mỹ Khánh, Phong Điền, Cần Thơ",
        "path_with_type": "Xã Mỹ Khánh, Huyện Phong Điền, Thành phố Cần Thơ",
        "code": "31312",
        "parent_code": "926"
    },
    "31315": {
        "name": "Nhơn Nghĩa",
        "type": "xa",
        "slug": "nhon-nghia",
        "name_with_type": "Xã Nhơn Nghĩa",
        "path": "Nhơn Nghĩa, Phong Điền, Cần Thơ",
        "path_with_type": "Xã Nhơn Nghĩa, Huyện Phong Điền, Thành phố Cần Thơ",
        "code": "31315",
        "parent_code": "926"
    },
    "31258": {
        "name": "Thới Lai",
        "type": "thi-tran",
        "slug": "thoi-lai",
        "name_with_type": "Thị trấn Thới Lai",
        "path": "Thới Lai, Thới Lai, Cần Thơ",
        "path_with_type": "Thị trấn Thới Lai, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31258",
        "parent_code": "927"
    },
    "31267": {
        "name": "Thới Thạnh",
        "type": "xa",
        "slug": "thoi-thanh",
        "name_with_type": "Xã Thới Thạnh",
        "path": "Thới Thạnh, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Thới Thạnh, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31267",
        "parent_code": "927"
    },
    "31268": {
        "name": "Tân Thạnh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thạnh",
        "path": "Tân Thạnh, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Tân Thạnh, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31268",
        "parent_code": "927"
    },
    "31270": {
        "name": "Xuân Thắng",
        "type": "xa",
        "slug": "xuan-thang",
        "name_with_type": "Xã Xuân Thắng",
        "path": "Xuân Thắng, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Xuân Thắng, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31270",
        "parent_code": "927"
    },
    "31279": {
        "name": "Đông Bình",
        "type": "xa",
        "slug": "dong-binh",
        "name_with_type": "Xã Đông Bình",
        "path": "Đông Bình, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Đông Bình, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31279",
        "parent_code": "927"
    },
    "31282": {
        "name": "Đông Thuận",
        "type": "xa",
        "slug": "dong-thuan",
        "name_with_type": "Xã Đông Thuận",
        "path": "Đông Thuận, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Đông Thuận, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31282",
        "parent_code": "927"
    },
    "31285": {
        "name": "Thới Tân",
        "type": "xa",
        "slug": "thoi-tan",
        "name_with_type": "Xã Thới Tân",
        "path": "Thới Tân, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Thới Tân, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31285",
        "parent_code": "927"
    },
    "31286": {
        "name": "Trường Thắng",
        "type": "xa",
        "slug": "truong-thang",
        "name_with_type": "Xã Trường Thắng",
        "path": "Trường Thắng, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Trường Thắng, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31286",
        "parent_code": "927"
    },
    "31288": {
        "name": "Định Môn",
        "type": "xa",
        "slug": "dinh-mon",
        "name_with_type": "Xã Định Môn",
        "path": "Định Môn, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Định Môn, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31288",
        "parent_code": "927"
    },
    "31291": {
        "name": "Trường Thành",
        "type": "xa",
        "slug": "truong-thanh",
        "name_with_type": "Xã Trường Thành",
        "path": "Trường Thành, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Trường Thành, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31291",
        "parent_code": "927"
    },
    "31294": {
        "name": "Trường Xuân",
        "type": "xa",
        "slug": "truong-xuan",
        "name_with_type": "Xã Trường Xuân",
        "path": "Trường Xuân, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Trường Xuân, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31294",
        "parent_code": "927"
    },
    "31297": {
        "name": "Trường Xuân A",
        "type": "xa",
        "slug": "truong-xuan-a",
        "name_with_type": "Xã Trường Xuân A",
        "path": "Trường Xuân A, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Trường Xuân A, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31297",
        "parent_code": "927"
    },
    "31298": {
        "name": "Trường Xuân B",
        "type": "xa",
        "slug": "truong-xuan-b",
        "name_with_type": "Xã Trường Xuân B",
        "path": "Trường Xuân B, Thới Lai, Cần Thơ",
        "path_with_type": "Xã Trường Xuân B, Huyện Thới Lai, Thành phố Cần Thơ",
        "code": "31298",
        "parent_code": "927"
    },
    "19744": {
        "name": "Phú Thuận",
        "type": "phuong",
        "slug": "phu-thuan",
        "name_with_type": "Phường Phú Thuận",
        "path": "Phú Thuận, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phú Thuận, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19744",
        "parent_code": "474"
    },
    "19747": {
        "name": "Phú Bình",
        "type": "phuong",
        "slug": "phu-binh",
        "name_with_type": "Phường Phú Bình",
        "path": "Phú Bình, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phú Bình, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19747",
        "parent_code": "474"
    },
    "19750": {
        "name": "Tây Lộc",
        "type": "phuong",
        "slug": "tay-loc",
        "name_with_type": "Phường Tây Lộc",
        "path": "Tây Lộc, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Tây Lộc, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19750",
        "parent_code": "474"
    },
    "19753": {
        "name": "Thuận Lộc",
        "type": "phuong",
        "slug": "thuan-loc",
        "name_with_type": "Phường Thuận Lộc",
        "path": "Thuận Lộc, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Thuận Lộc, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19753",
        "parent_code": "474"
    },
    "19756": {
        "name": "Phú Hiệp",
        "type": "phuong",
        "slug": "phu-hiep",
        "name_with_type": "Phường Phú Hiệp",
        "path": "Phú Hiệp, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phú Hiệp, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19756",
        "parent_code": "474"
    },
    "19759": {
        "name": "Phú Hậu",
        "type": "phuong",
        "slug": "phu-hau",
        "name_with_type": "Phường Phú Hậu",
        "path": "Phú Hậu, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phú Hậu, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19759",
        "parent_code": "474"
    },
    "19762": {
        "name": "Thuận Hòa",
        "type": "phuong",
        "slug": "thuan-hoa",
        "name_with_type": "Phường Thuận Hòa",
        "path": "Thuận Hòa, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Thuận Hòa, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19762",
        "parent_code": "474"
    },
    "19765": {
        "name": "Thuận Thành",
        "type": "phuong",
        "slug": "thuan-thanh",
        "name_with_type": "Phường Thuận Thành",
        "path": "Thuận Thành, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Thuận Thành, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19765",
        "parent_code": "474"
    },
    "19768": {
        "name": "Phú Hòa",
        "type": "phuong",
        "slug": "phu-hoa",
        "name_with_type": "Phường Phú Hòa",
        "path": "Phú Hòa, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phú Hòa, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19768",
        "parent_code": "474"
    },
    "19771": {
        "name": "Phú Cát",
        "type": "phuong",
        "slug": "phu-cat",
        "name_with_type": "Phường Phú Cát",
        "path": "Phú Cát, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phú Cát, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19771",
        "parent_code": "474"
    },
    "19774": {
        "name": "Kim Long",
        "type": "phuong",
        "slug": "kim-long",
        "name_with_type": "Phường Kim Long",
        "path": "Kim Long, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Kim Long, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19774",
        "parent_code": "474"
    },
    "19777": {
        "name": "Vĩ Dạ",
        "type": "phuong",
        "slug": "vi-da",
        "name_with_type": "Phường Vĩ Dạ",
        "path": "Vĩ Dạ, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Vĩ Dạ, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19777",
        "parent_code": "474"
    },
    "19780": {
        "name": "Phường Đúc",
        "type": "phuong",
        "slug": "phuong-duc",
        "name_with_type": "Phường Phường Đúc",
        "path": "Phường Đúc, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phường Đúc, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19780",
        "parent_code": "474"
    },
    "19783": {
        "name": "Vĩnh Ninh",
        "type": "phuong",
        "slug": "vinh-ninh",
        "name_with_type": "Phường Vĩnh Ninh",
        "path": "Vĩnh Ninh, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Vĩnh Ninh, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19783",
        "parent_code": "474"
    },
    "19786": {
        "name": "Phú Hội",
        "type": "phuong",
        "slug": "phu-hoi",
        "name_with_type": "Phường Phú Hội",
        "path": "Phú Hội, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phú Hội, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19786",
        "parent_code": "474"
    },
    "19789": {
        "name": "Phú Nhuận",
        "type": "phuong",
        "slug": "phu-nhuan",
        "name_with_type": "Phường Phú Nhuận",
        "path": "Phú Nhuận, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phú Nhuận, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19789",
        "parent_code": "474"
    },
    "19792": {
        "name": "Xuân Phú",
        "type": "phuong",
        "slug": "xuan-phu",
        "name_with_type": "Phường Xuân Phú",
        "path": "Xuân Phú, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Xuân Phú, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19792",
        "parent_code": "474"
    },
    "19795": {
        "name": "Trường An",
        "type": "phuong",
        "slug": "truong-an",
        "name_with_type": "Phường Trường An",
        "path": "Trường An, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Trường An, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19795",
        "parent_code": "474"
    },
    "19798": {
        "name": "Phước Vĩnh",
        "type": "phuong",
        "slug": "phuoc-vinh",
        "name_with_type": "Phường Phước Vĩnh",
        "path": "Phước Vĩnh, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Phước Vĩnh, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19798",
        "parent_code": "474"
    },
    "19801": {
        "name": "An Cựu",
        "type": "phuong",
        "slug": "an-cuu",
        "name_with_type": "Phường An Cựu",
        "path": "An Cựu, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường An Cựu, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19801",
        "parent_code": "474"
    },
    "19803": {
        "name": "An Hòa",
        "type": "phuong",
        "slug": "an-hoa",
        "name_with_type": "Phường An Hòa",
        "path": "An Hòa, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường An Hòa, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19803",
        "parent_code": "474"
    },
    "19804": {
        "name": "Hương Sơ",
        "type": "phuong",
        "slug": "huong-so",
        "name_with_type": "Phường Hương Sơ",
        "path": "Hương Sơ, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Hương Sơ, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19804",
        "parent_code": "474"
    },
    "19807": {
        "name": "Thuỷ Biều",
        "type": "phuong",
        "slug": "thuy-bieu",
        "name_with_type": "Phường Thuỷ Biều",
        "path": "Thuỷ Biều, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Thuỷ Biều, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19807",
        "parent_code": "474"
    },
    "19810": {
        "name": "Hương Long",
        "type": "phuong",
        "slug": "huong-long",
        "name_with_type": "Phường Hương Long",
        "path": "Hương Long, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Hương Long, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19810",
        "parent_code": "474"
    },
    "19813": {
        "name": "Thuỷ Xuân",
        "type": "phuong",
        "slug": "thuy-xuan",
        "name_with_type": "Phường Thuỷ Xuân",
        "path": "Thuỷ Xuân, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường Thuỷ Xuân, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19813",
        "parent_code": "474"
    },
    "19815": {
        "name": "An Đông",
        "type": "phuong",
        "slug": "an-dong",
        "name_with_type": "Phường An Đông",
        "path": "An Đông, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường An Đông, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19815",
        "parent_code": "474"
    },
    "19816": {
        "name": "An Tây",
        "type": "phuong",
        "slug": "an-tay",
        "name_with_type": "Phường An Tây",
        "path": "An Tây, Huế, Thừa Thiên Huế",
        "path_with_type": "Phường An Tây, Thành phố Huế, Tỉnh Thừa Thiên Huế",
        "code": "19816",
        "parent_code": "474"
    },
    "19819": {
        "name": "Phong Điền",
        "type": "thi-tran",
        "slug": "phong-dien",
        "name_with_type": "Thị trấn Phong Điền",
        "path": "Phong Điền, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Thị trấn Phong Điền, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19819",
        "parent_code": "476"
    },
    "19822": {
        "name": "Điền Hương",
        "type": "xa",
        "slug": "dien-huong",
        "name_with_type": "Xã Điền Hương",
        "path": "Điền Hương, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Điền Hương, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19822",
        "parent_code": "476"
    },
    "19825": {
        "name": "Điền Môn",
        "type": "xa",
        "slug": "dien-mon",
        "name_with_type": "Xã Điền Môn",
        "path": "Điền Môn, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Điền Môn, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19825",
        "parent_code": "476"
    },
    "19828": {
        "name": "Điền Lộc",
        "type": "xa",
        "slug": "dien-loc",
        "name_with_type": "Xã Điền Lộc",
        "path": "Điền Lộc, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Điền Lộc, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19828",
        "parent_code": "476"
    },
    "19831": {
        "name": "Phong Bình",
        "type": "xa",
        "slug": "phong-binh",
        "name_with_type": "Xã Phong Bình",
        "path": "Phong Bình, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong Bình, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19831",
        "parent_code": "476"
    },
    "19834": {
        "name": "Điền Hòa",
        "type": "xa",
        "slug": "dien-hoa",
        "name_with_type": "Xã Điền Hòa",
        "path": "Điền Hòa, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Điền Hòa, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19834",
        "parent_code": "476"
    },
    "19837": {
        "name": "Phong Chương",
        "type": "xa",
        "slug": "phong-chuong",
        "name_with_type": "Xã Phong Chương",
        "path": "Phong Chương, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong Chương, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19837",
        "parent_code": "476"
    },
    "19840": {
        "name": "Phong Hải",
        "type": "xa",
        "slug": "phong-hai",
        "name_with_type": "Xã Phong Hải",
        "path": "Phong Hải, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong Hải, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19840",
        "parent_code": "476"
    },
    "19843": {
        "name": "Điền Hải",
        "type": "xa",
        "slug": "dien-hai",
        "name_with_type": "Xã Điền Hải",
        "path": "Điền Hải, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Điền Hải, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19843",
        "parent_code": "476"
    },
    "19846": {
        "name": "Phong Hòa",
        "type": "xa",
        "slug": "phong-hoa",
        "name_with_type": "Xã Phong Hòa",
        "path": "Phong Hòa, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong Hòa, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19846",
        "parent_code": "476"
    },
    "19849": {
        "name": "Phong Thu",
        "type": "xa",
        "slug": "phong-thu",
        "name_with_type": "Xã Phong Thu",
        "path": "Phong Thu, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong Thu, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19849",
        "parent_code": "476"
    },
    "19852": {
        "name": "Phong Hiền",
        "type": "xa",
        "slug": "phong-hien",
        "name_with_type": "Xã Phong Hiền",
        "path": "Phong Hiền, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong Hiền, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19852",
        "parent_code": "476"
    },
    "19855": {
        "name": "Phong Mỹ",
        "type": "xa",
        "slug": "phong-my",
        "name_with_type": "Xã Phong Mỹ",
        "path": "Phong Mỹ, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong Mỹ, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19855",
        "parent_code": "476"
    },
    "19858": {
        "name": "Phong An",
        "type": "xa",
        "slug": "phong-an",
        "name_with_type": "Xã Phong An",
        "path": "Phong An, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong An, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19858",
        "parent_code": "476"
    },
    "19861": {
        "name": "Phong Xuân",
        "type": "xa",
        "slug": "phong-xuan",
        "name_with_type": "Xã Phong Xuân",
        "path": "Phong Xuân, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong Xuân, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19861",
        "parent_code": "476"
    },
    "19864": {
        "name": "Phong Sơn",
        "type": "xa",
        "slug": "phong-son",
        "name_with_type": "Xã Phong Sơn",
        "path": "Phong Sơn, Phong Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Phong Sơn, Huyện Phong Điền, Tỉnh Thừa Thiên Huế",
        "code": "19864",
        "parent_code": "476"
    },
    "19867": {
        "name": "Sịa",
        "type": "thi-tran",
        "slug": "sia",
        "name_with_type": "Thị trấn Sịa",
        "path": "Sịa, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Thị trấn Sịa, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19867",
        "parent_code": "477"
    },
    "19870": {
        "name": "Quảng Thái",
        "type": "xa",
        "slug": "quang-thai",
        "name_with_type": "Xã Quảng Thái",
        "path": "Quảng Thái, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng Thái, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19870",
        "parent_code": "477"
    },
    "19873": {
        "name": "Quảng Ngạn",
        "type": "xa",
        "slug": "quang-ngan",
        "name_with_type": "Xã Quảng Ngạn",
        "path": "Quảng Ngạn, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng Ngạn, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19873",
        "parent_code": "477"
    },
    "19876": {
        "name": "Quảng Lợi",
        "type": "xa",
        "slug": "quang-loi",
        "name_with_type": "Xã Quảng Lợi",
        "path": "Quảng Lợi, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng Lợi, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19876",
        "parent_code": "477"
    },
    "19879": {
        "name": "Quảng Công",
        "type": "xa",
        "slug": "quang-cong",
        "name_with_type": "Xã Quảng Công",
        "path": "Quảng Công, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng Công, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19879",
        "parent_code": "477"
    },
    "19882": {
        "name": "Quảng Phước",
        "type": "xa",
        "slug": "quang-phuoc",
        "name_with_type": "Xã Quảng Phước",
        "path": "Quảng Phước, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng Phước, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19882",
        "parent_code": "477"
    },
    "19885": {
        "name": "Quảng Vinh",
        "type": "xa",
        "slug": "quang-vinh",
        "name_with_type": "Xã Quảng Vinh",
        "path": "Quảng Vinh, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng Vinh, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19885",
        "parent_code": "477"
    },
    "19888": {
        "name": "Quảng An",
        "type": "xa",
        "slug": "quang-an",
        "name_with_type": "Xã Quảng An",
        "path": "Quảng An, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng An, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19888",
        "parent_code": "477"
    },
    "19891": {
        "name": "Quảng Thành",
        "type": "xa",
        "slug": "quang-thanh",
        "name_with_type": "Xã Quảng Thành",
        "path": "Quảng Thành, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng Thành, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19891",
        "parent_code": "477"
    },
    "19894": {
        "name": "Quảng Thọ",
        "type": "xa",
        "slug": "quang-tho",
        "name_with_type": "Xã Quảng Thọ",
        "path": "Quảng Thọ, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng Thọ, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19894",
        "parent_code": "477"
    },
    "19897": {
        "name": "Quảng Phú",
        "type": "xa",
        "slug": "quang-phu",
        "name_with_type": "Xã Quảng Phú",
        "path": "Quảng Phú, Quảng Điền, Thừa Thiên Huế",
        "path_with_type": "Xã Quảng Phú, Huyện Quảng Điền, Tỉnh Thừa Thiên Huế",
        "code": "19897",
        "parent_code": "477"
    },
    "19900": {
        "name": "Thuận An",
        "type": "thi-tran",
        "slug": "thuan-an",
        "name_with_type": "Thị trấn Thuận An",
        "path": "Thuận An, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Thị trấn Thuận An, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19900",
        "parent_code": "478"
    },
    "19903": {
        "name": "Phú Thuận",
        "type": "xa",
        "slug": "phu-thuan",
        "name_with_type": "Xã Phú Thuận",
        "path": "Phú Thuận, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Thuận, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19903",
        "parent_code": "478"
    },
    "19906": {
        "name": "Phú Dương",
        "type": "xa",
        "slug": "phu-duong",
        "name_with_type": "Xã Phú Dương",
        "path": "Phú Dương, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Dương, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19906",
        "parent_code": "478"
    },
    "19909": {
        "name": "Phú Mậu",
        "type": "xa",
        "slug": "phu-mau",
        "name_with_type": "Xã Phú Mậu",
        "path": "Phú Mậu, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Mậu, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19909",
        "parent_code": "478"
    },
    "19912": {
        "name": "Phú An",
        "type": "xa",
        "slug": "phu-an",
        "name_with_type": "Xã Phú An",
        "path": "Phú An, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú An, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19912",
        "parent_code": "478"
    },
    "19915": {
        "name": "Phú Hải",
        "type": "xa",
        "slug": "phu-hai",
        "name_with_type": "Xã Phú Hải",
        "path": "Phú Hải, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Hải, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19915",
        "parent_code": "478"
    },
    "19918": {
        "name": "Phú Xuân",
        "type": "xa",
        "slug": "phu-xuan",
        "name_with_type": "Xã Phú Xuân",
        "path": "Phú Xuân, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Xuân, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19918",
        "parent_code": "478"
    },
    "19921": {
        "name": "Phú Diên",
        "type": "xa",
        "slug": "phu-dien",
        "name_with_type": "Xã Phú Diên",
        "path": "Phú Diên, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Diên, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19921",
        "parent_code": "478"
    },
    "19924": {
        "name": "Phú Thanh",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thanh",
        "path": "Phú Thanh, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Thanh, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19924",
        "parent_code": "478"
    },
    "19927": {
        "name": "Phú Mỹ",
        "type": "xa",
        "slug": "phu-my",
        "name_with_type": "Xã Phú Mỹ",
        "path": "Phú Mỹ, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Mỹ, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19927",
        "parent_code": "478"
    },
    "19930": {
        "name": "Phú Thượng",
        "type": "xa",
        "slug": "phu-thuong",
        "name_with_type": "Xã Phú Thượng",
        "path": "Phú Thượng, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Thượng, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19930",
        "parent_code": "478"
    },
    "19933": {
        "name": "Phú Hồ",
        "type": "xa",
        "slug": "phu-ho",
        "name_with_type": "Xã Phú Hồ",
        "path": "Phú Hồ, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Hồ, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19933",
        "parent_code": "478"
    },
    "19936": {
        "name": "Vinh Xuân",
        "type": "xa",
        "slug": "vinh-xuan",
        "name_with_type": "Xã Vinh Xuân",
        "path": "Vinh Xuân, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Xuân, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19936",
        "parent_code": "478"
    },
    "19939": {
        "name": "Phú Lương",
        "type": "xa",
        "slug": "phu-luong",
        "name_with_type": "Xã Phú Lương",
        "path": "Phú Lương, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Lương, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19939",
        "parent_code": "478"
    },
    "19942": {
        "name": "Phú Đa",
        "type": "thi-tran",
        "slug": "phu-da",
        "name_with_type": "Thị trấn Phú Đa",
        "path": "Phú Đa, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Thị trấn Phú Đa, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19942",
        "parent_code": "478"
    },
    "19945": {
        "name": "Vinh Thanh",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vinh Thanh",
        "path": "Vinh Thanh, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Thanh, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19945",
        "parent_code": "478"
    },
    "19948": {
        "name": "Vinh An",
        "type": "xa",
        "slug": "vinh-an",
        "name_with_type": "Xã Vinh An",
        "path": "Vinh An, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh An, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19948",
        "parent_code": "478"
    },
    "19951": {
        "name": "Vinh Phú",
        "type": "xa",
        "slug": "vinh-phu",
        "name_with_type": "Xã Vinh Phú",
        "path": "Vinh Phú, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Phú, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19951",
        "parent_code": "478"
    },
    "19954": {
        "name": "Vinh Thái",
        "type": "xa",
        "slug": "vinh-thai",
        "name_with_type": "Xã Vinh Thái",
        "path": "Vinh Thái, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Thái, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19954",
        "parent_code": "478"
    },
    "19957": {
        "name": "Vinh Hà",
        "type": "xa",
        "slug": "vinh-ha",
        "name_with_type": "Xã Vinh Hà",
        "path": "Vinh Hà, Phú Vang, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Hà, Huyện Phú Vang, Tỉnh Thừa Thiên Huế",
        "code": "19957",
        "parent_code": "478"
    },
    "19960": {
        "name": "Phú Bài",
        "type": "phuong",
        "slug": "phu-bai",
        "name_with_type": "Phường Phú Bài",
        "path": "Phú Bài, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Phường Phú Bài, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19960",
        "parent_code": "479"
    },
    "19963": {
        "name": "Thủy Vân",
        "type": "xa",
        "slug": "thuy-van",
        "name_with_type": "Xã Thủy Vân",
        "path": "Thủy Vân, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Xã Thủy Vân, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19963",
        "parent_code": "479"
    },
    "19966": {
        "name": "Thủy Thanh",
        "type": "xa",
        "slug": "thuy-thanh",
        "name_with_type": "Xã Thủy Thanh",
        "path": "Thủy Thanh, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Xã Thủy Thanh, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19966",
        "parent_code": "479"
    },
    "19969": {
        "name": "Thủy Dương",
        "type": "phuong",
        "slug": "thuy-duong",
        "name_with_type": "Phường Thủy Dương",
        "path": "Thủy Dương, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Phường Thủy Dương, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19969",
        "parent_code": "479"
    },
    "19972": {
        "name": "Thủy Phương",
        "type": "phuong",
        "slug": "thuy-phuong",
        "name_with_type": "Phường Thủy Phương",
        "path": "Thủy Phương, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Phường Thủy Phương, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19972",
        "parent_code": "479"
    },
    "19975": {
        "name": "Thủy Châu",
        "type": "phuong",
        "slug": "thuy-chau",
        "name_with_type": "Phường Thủy Châu",
        "path": "Thủy Châu, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Phường Thủy Châu, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19975",
        "parent_code": "479"
    },
    "19978": {
        "name": "Thủy Lương",
        "type": "phuong",
        "slug": "thuy-luong",
        "name_with_type": "Phường Thủy Lương",
        "path": "Thủy Lương, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Phường Thủy Lương, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19978",
        "parent_code": "479"
    },
    "19981": {
        "name": "Thủy Bằng",
        "type": "xa",
        "slug": "thuy-bang",
        "name_with_type": "Xã Thủy Bằng",
        "path": "Thủy Bằng, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Xã Thủy Bằng, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19981",
        "parent_code": "479"
    },
    "19984": {
        "name": "Thủy Tân",
        "type": "xa",
        "slug": "thuy-tan",
        "name_with_type": "Xã Thủy Tân",
        "path": "Thủy Tân, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Xã Thủy Tân, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19984",
        "parent_code": "479"
    },
    "19987": {
        "name": "Thủy Phù",
        "type": "xa",
        "slug": "thuy-phu",
        "name_with_type": "Xã Thủy Phù",
        "path": "Thủy Phù, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Xã Thủy Phù, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19987",
        "parent_code": "479"
    },
    "19990": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Sơn, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19990",
        "parent_code": "479"
    },
    "19993": {
        "name": "Dương Hòa",
        "type": "xa",
        "slug": "duong-hoa",
        "name_with_type": "Xã Dương Hòa",
        "path": "Dương Hòa, Hương Thủy, Thừa Thiên Huế",
        "path_with_type": "Xã Dương Hòa, Thị xã Hương Thủy, Tỉnh Thừa Thiên Huế",
        "code": "19993",
        "parent_code": "479"
    },
    "19996": {
        "name": "Tứ Hạ",
        "type": "phuong",
        "slug": "tu-ha",
        "name_with_type": "Phường Tứ Hạ",
        "path": "Tứ Hạ, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Phường Tứ Hạ, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "19996",
        "parent_code": "480"
    },
    "19999": {
        "name": "Hải Dương",
        "type": "xa",
        "slug": "hai-duong",
        "name_with_type": "Xã Hải Dương",
        "path": "Hải Dương, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Xã Hải Dương, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "19999",
        "parent_code": "480"
    },
    "20002": {
        "name": "Hương Phong",
        "type": "xa",
        "slug": "huong-phong",
        "name_with_type": "Xã Hương Phong",
        "path": "Hương Phong, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Phong, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20002",
        "parent_code": "480"
    },
    "20005": {
        "name": "Hương Toàn",
        "type": "xa",
        "slug": "huong-toan",
        "name_with_type": "Xã Hương Toàn",
        "path": "Hương Toàn, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Toàn, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20005",
        "parent_code": "480"
    },
    "20008": {
        "name": "Hương Vân",
        "type": "phuong",
        "slug": "huong-van",
        "name_with_type": "Phường Hương Vân",
        "path": "Hương Vân, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Phường Hương Vân, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20008",
        "parent_code": "480"
    },
    "20011": {
        "name": "Hương Văn",
        "type": "phuong",
        "slug": "huong-van",
        "name_with_type": "Phường Hương Văn",
        "path": "Hương Văn, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Phường Hương Văn, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20011",
        "parent_code": "480"
    },
    "20014": {
        "name": "Hương Vinh",
        "type": "xa",
        "slug": "huong-vinh",
        "name_with_type": "Xã Hương Vinh",
        "path": "Hương Vinh, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Vinh, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20014",
        "parent_code": "480"
    },
    "20017": {
        "name": "Hương Xuân",
        "type": "phuong",
        "slug": "huong-xuan",
        "name_with_type": "Phường Hương Xuân",
        "path": "Hương Xuân, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Phường Hương Xuân, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20017",
        "parent_code": "480"
    },
    "20020": {
        "name": "Hương Chữ",
        "type": "phuong",
        "slug": "huong-chu",
        "name_with_type": "Phường Hương Chữ",
        "path": "Hương Chữ, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Phường Hương Chữ, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20020",
        "parent_code": "480"
    },
    "20023": {
        "name": "Hương An",
        "type": "phuong",
        "slug": "huong-an",
        "name_with_type": "Phường Hương An",
        "path": "Hương An, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Phường Hương An, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20023",
        "parent_code": "480"
    },
    "20026": {
        "name": "Hương Bình",
        "type": "xa",
        "slug": "huong-binh",
        "name_with_type": "Xã Hương Bình",
        "path": "Hương Bình, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Bình, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20026",
        "parent_code": "480"
    },
    "20029": {
        "name": "Hương Hồ",
        "type": "phuong",
        "slug": "huong-ho",
        "name_with_type": "Phường Hương Hồ",
        "path": "Hương Hồ, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Phường Hương Hồ, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20029",
        "parent_code": "480"
    },
    "20032": {
        "name": "Hương Thọ",
        "type": "xa",
        "slug": "huong-tho",
        "name_with_type": "Xã Hương Thọ",
        "path": "Hương Thọ, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Thọ, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20032",
        "parent_code": "480"
    },
    "20035": {
        "name": "Bình Điền",
        "type": "xa",
        "slug": "binh-dien",
        "name_with_type": "Xã Bình Điền",
        "path": "Bình Điền, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Xã Bình Điền, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20035",
        "parent_code": "480"
    },
    "20038": {
        "name": "Hồng Tiến",
        "type": "xa",
        "slug": "hong-tien",
        "name_with_type": "Xã Hồng Tiến",
        "path": "Hồng Tiến, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Tiến, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20038",
        "parent_code": "480"
    },
    "20041": {
        "name": "Bình Thành",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thành",
        "path": "Bình Thành, Hương Trà, Thừa Thiên Huế",
        "path_with_type": "Xã Bình Thành, Thị xã Hương Trà, Tỉnh Thừa Thiên Huế",
        "code": "20041",
        "parent_code": "480"
    },
    "20044": {
        "name": "A Lưới",
        "type": "thi-tran",
        "slug": "a-luoi",
        "name_with_type": "Thị trấn A Lưới",
        "path": "A Lưới, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Thị trấn A Lưới, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20044",
        "parent_code": "481"
    },
    "20047": {
        "name": "Hồng Vân",
        "type": "xa",
        "slug": "hong-van",
        "name_with_type": "Xã Hồng Vân",
        "path": "Hồng Vân, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Vân, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20047",
        "parent_code": "481"
    },
    "20050": {
        "name": "Hồng Hạ",
        "type": "xa",
        "slug": "hong-ha",
        "name_with_type": "Xã Hồng Hạ",
        "path": "Hồng Hạ, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Hạ, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20050",
        "parent_code": "481"
    },
    "20053": {
        "name": "Hồng Kim",
        "type": "xa",
        "slug": "hong-kim",
        "name_with_type": "Xã Hồng Kim",
        "path": "Hồng Kim, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Kim, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20053",
        "parent_code": "481"
    },
    "20056": {
        "name": "Hồng Trung",
        "type": "xa",
        "slug": "hong-trung",
        "name_with_type": "Xã Hồng Trung",
        "path": "Hồng Trung, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Trung, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20056",
        "parent_code": "481"
    },
    "20059": {
        "name": "Hương Nguyên",
        "type": "xa",
        "slug": "huong-nguyen",
        "name_with_type": "Xã Hương Nguyên",
        "path": "Hương Nguyên, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Nguyên, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20059",
        "parent_code": "481"
    },
    "20062": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Bắc Sơn, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20062",
        "parent_code": "481"
    },
    "20065": {
        "name": "Hồng Bắc",
        "type": "xa",
        "slug": "hong-bac",
        "name_with_type": "Xã Hồng Bắc",
        "path": "Hồng Bắc, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Bắc, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20065",
        "parent_code": "481"
    },
    "20068": {
        "name": "A Ngo",
        "type": "xa",
        "slug": "a-ngo",
        "name_with_type": "Xã A Ngo",
        "path": "A Ngo, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã A Ngo, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20068",
        "parent_code": "481"
    },
    "20071": {
        "name": "Sơn Thủy",
        "type": "xa",
        "slug": "son-thuy",
        "name_with_type": "Xã Sơn Thủy",
        "path": "Sơn Thủy, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Sơn Thủy, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20071",
        "parent_code": "481"
    },
    "20074": {
        "name": "Phú Vinh",
        "type": "xa",
        "slug": "phu-vinh",
        "name_with_type": "Xã Phú Vinh",
        "path": "Phú Vinh, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Phú Vinh, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20074",
        "parent_code": "481"
    },
    "20077": {
        "name": "Hồng Quảng",
        "type": "xa",
        "slug": "hong-quang",
        "name_with_type": "Xã Hồng Quảng",
        "path": "Hồng Quảng, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Quảng, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20077",
        "parent_code": "481"
    },
    "20080": {
        "name": "Hương Phong",
        "type": "xa",
        "slug": "huong-phong",
        "name_with_type": "Xã Hương Phong",
        "path": "Hương Phong, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Phong, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20080",
        "parent_code": "481"
    },
    "20083": {
        "name": "Nhâm",
        "type": "xa",
        "slug": "nham",
        "name_with_type": "Xã Nhâm",
        "path": "Nhâm, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Nhâm, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20083",
        "parent_code": "481"
    },
    "20086": {
        "name": "Hồng Thượng",
        "type": "xa",
        "slug": "hong-thuong",
        "name_with_type": "Xã Hồng Thượng",
        "path": "Hồng Thượng, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Thượng, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20086",
        "parent_code": "481"
    },
    "20089": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Thái, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20089",
        "parent_code": "481"
    },
    "20092": {
        "name": "Hương Lâm",
        "type": "xa",
        "slug": "huong-lam",
        "name_with_type": "Xã Hương Lâm",
        "path": "Hương Lâm, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Lâm, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20092",
        "parent_code": "481"
    },
    "20095": {
        "name": "A Roằng",
        "type": "xa",
        "slug": "a-roang",
        "name_with_type": "Xã A Roằng",
        "path": "A Roằng, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã A Roằng, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20095",
        "parent_code": "481"
    },
    "20098": {
        "name": "Đông Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đông Sơn",
        "path": "Đông Sơn, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Đông Sơn, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20098",
        "parent_code": "481"
    },
    "20101": {
        "name": "A Đớt",
        "type": "xa",
        "slug": "a-dot",
        "name_with_type": "Xã A Đớt",
        "path": "A Đớt, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã A Đớt, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20101",
        "parent_code": "481"
    },
    "20104": {
        "name": "Hồng Thủy",
        "type": "xa",
        "slug": "hong-thuy",
        "name_with_type": "Xã Hồng Thủy",
        "path": "Hồng Thủy, A Lưới, Thừa Thiên Huế",
        "path_with_type": "Xã Hồng Thủy, Huyện A Lưới, Tỉnh Thừa Thiên Huế",
        "code": "20104",
        "parent_code": "481"
    },
    "20107": {
        "name": "Phú Lộc",
        "type": "thi-tran",
        "slug": "phu-loc",
        "name_with_type": "Thị trấn Phú Lộc",
        "path": "Phú Lộc, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Thị trấn Phú Lộc, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20107",
        "parent_code": "482"
    },
    "20110": {
        "name": "Lăng Cô",
        "type": "thi-tran",
        "slug": "lang-co",
        "name_with_type": "Thị trấn Lăng Cô",
        "path": "Lăng Cô, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Thị trấn Lăng Cô, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20110",
        "parent_code": "482"
    },
    "20113": {
        "name": "Vinh Mỹ",
        "type": "xa",
        "slug": "vinh-my",
        "name_with_type": "Xã Vinh Mỹ",
        "path": "Vinh Mỹ, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Mỹ, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20113",
        "parent_code": "482"
    },
    "20116": {
        "name": "Vinh Hưng",
        "type": "xa",
        "slug": "vinh-hung",
        "name_with_type": "Xã Vinh Hưng",
        "path": "Vinh Hưng, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Hưng, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20116",
        "parent_code": "482"
    },
    "20119": {
        "name": "Vinh Hải",
        "type": "xa",
        "slug": "vinh-hai",
        "name_with_type": "Xã Vinh Hải",
        "path": "Vinh Hải, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Hải, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20119",
        "parent_code": "482"
    },
    "20122": {
        "name": "Vinh Giang",
        "type": "xa",
        "slug": "vinh-giang",
        "name_with_type": "Xã Vinh Giang",
        "path": "Vinh Giang, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Giang, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20122",
        "parent_code": "482"
    },
    "20125": {
        "name": "Vinh Hiền",
        "type": "xa",
        "slug": "vinh-hien",
        "name_with_type": "Xã Vinh Hiền",
        "path": "Vinh Hiền, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Vinh Hiền, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20125",
        "parent_code": "482"
    },
    "20128": {
        "name": "Lộc Bổn",
        "type": "xa",
        "slug": "loc-bon",
        "name_with_type": "Xã Lộc Bổn",
        "path": "Lộc Bổn, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc Bổn, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20128",
        "parent_code": "482"
    },
    "20131": {
        "name": "Lộc Sơn",
        "type": "xa",
        "slug": "loc-son",
        "name_with_type": "Xã Lộc Sơn",
        "path": "Lộc Sơn, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc Sơn, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20131",
        "parent_code": "482"
    },
    "20134": {
        "name": "Lộc Bình",
        "type": "xa",
        "slug": "loc-binh",
        "name_with_type": "Xã Lộc Bình",
        "path": "Lộc Bình, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc Bình, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20134",
        "parent_code": "482"
    },
    "20137": {
        "name": "Lộc Vĩnh",
        "type": "xa",
        "slug": "loc-vinh",
        "name_with_type": "Xã Lộc Vĩnh",
        "path": "Lộc Vĩnh, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc Vĩnh, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20137",
        "parent_code": "482"
    },
    "20140": {
        "name": "Lộc An",
        "type": "xa",
        "slug": "loc-an",
        "name_with_type": "Xã Lộc An",
        "path": "Lộc An, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc An, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20140",
        "parent_code": "482"
    },
    "20143": {
        "name": "Lộc Điền",
        "type": "xa",
        "slug": "loc-dien",
        "name_with_type": "Xã Lộc Điền",
        "path": "Lộc Điền, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc Điền, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20143",
        "parent_code": "482"
    },
    "20146": {
        "name": "Lộc Thủy",
        "type": "xa",
        "slug": "loc-thuy",
        "name_with_type": "Xã Lộc Thủy",
        "path": "Lộc Thủy, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc Thủy, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20146",
        "parent_code": "482"
    },
    "20149": {
        "name": "Lộc Trì",
        "type": "xa",
        "slug": "loc-tri",
        "name_with_type": "Xã Lộc Trì",
        "path": "Lộc Trì, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc Trì, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20149",
        "parent_code": "482"
    },
    "20152": {
        "name": "Lộc Tiến",
        "type": "xa",
        "slug": "loc-tien",
        "name_with_type": "Xã Lộc Tiến",
        "path": "Lộc Tiến, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc Tiến, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20152",
        "parent_code": "482"
    },
    "20155": {
        "name": "Lộc Hòa",
        "type": "xa",
        "slug": "loc-hoa",
        "name_with_type": "Xã Lộc Hòa",
        "path": "Lộc Hòa, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Lộc Hòa, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20155",
        "parent_code": "482"
    },
    "20158": {
        "name": "Xuân Lộc",
        "type": "xa",
        "slug": "xuan-loc",
        "name_with_type": "Xã Xuân Lộc",
        "path": "Xuân Lộc, Phú Lộc, Thừa Thiên Huế",
        "path_with_type": "Xã Xuân Lộc, Huyện Phú Lộc, Tỉnh Thừa Thiên Huế",
        "code": "20158",
        "parent_code": "482"
    },
    "20161": {
        "name": "Khe Tre",
        "type": "thi-tran",
        "slug": "khe-tre",
        "name_with_type": "Thị trấn Khe Tre",
        "path": "Khe Tre, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Thị trấn Khe Tre, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20161",
        "parent_code": "483"
    },
    "20164": {
        "name": "Hương Phú",
        "type": "xa",
        "slug": "huong-phu",
        "name_with_type": "Xã Hương Phú",
        "path": "Hương Phú, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Phú, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20164",
        "parent_code": "483"
    },
    "20167": {
        "name": "Hương Sơn",
        "type": "xa",
        "slug": "huong-son",
        "name_with_type": "Xã Hương Sơn",
        "path": "Hương Sơn, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Sơn, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20167",
        "parent_code": "483"
    },
    "20170": {
        "name": "Hương Lộc",
        "type": "xa",
        "slug": "huong-loc",
        "name_with_type": "Xã Hương Lộc",
        "path": "Hương Lộc, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Lộc, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20170",
        "parent_code": "483"
    },
    "20173": {
        "name": "Thượng Quảng",
        "type": "xa",
        "slug": "thuong-quang",
        "name_with_type": "Xã Thượng Quảng",
        "path": "Thượng Quảng, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Thượng Quảng, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20173",
        "parent_code": "483"
    },
    "20176": {
        "name": "Hương Hòa",
        "type": "xa",
        "slug": "huong-hoa",
        "name_with_type": "Xã Hương Hòa",
        "path": "Hương Hòa, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Hòa, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20176",
        "parent_code": "483"
    },
    "20179": {
        "name": "Hương Giang",
        "type": "xa",
        "slug": "huong-giang",
        "name_with_type": "Xã Hương Giang",
        "path": "Hương Giang, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Giang, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20179",
        "parent_code": "483"
    },
    "20182": {
        "name": "Hương Hữu",
        "type": "xa",
        "slug": "huong-huu",
        "name_with_type": "Xã Hương Hữu",
        "path": "Hương Hữu, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Hương Hữu, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20182",
        "parent_code": "483"
    },
    "20185": {
        "name": "Thượng Lộ",
        "type": "xa",
        "slug": "thuong-lo",
        "name_with_type": "Xã Thượng Lộ",
        "path": "Thượng Lộ, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Thượng Lộ, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20185",
        "parent_code": "483"
    },
    "20188": {
        "name": "Thượng Long",
        "type": "xa",
        "slug": "thuong-long",
        "name_with_type": "Xã Thượng Long",
        "path": "Thượng Long, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Thượng Long, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20188",
        "parent_code": "483"
    },
    "20191": {
        "name": "Thượng Nhật",
        "type": "xa",
        "slug": "thuong-nhat",
        "name_with_type": "Xã Thượng Nhật",
        "path": "Thượng Nhật, Nam Đông, Thừa Thiên Huế",
        "path_with_type": "Xã Thượng Nhật, Huyện Nam Đông, Tỉnh Thừa Thiên Huế",
        "code": "20191",
        "parent_code": "483"
    },
    "25993": {
        "name": "Trảng Dài",
        "type": "phuong",
        "slug": "trang-dai",
        "name_with_type": "Phường Trảng Dài",
        "path": "Trảng Dài, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Trảng Dài, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "25993",
        "parent_code": "731"
    },
    "25996": {
        "name": "Tân Phong",
        "type": "phuong",
        "slug": "tan-phong",
        "name_with_type": "Phường Tân Phong",
        "path": "Tân Phong, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Tân Phong, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "25996",
        "parent_code": "731"
    },
    "25999": {
        "name": "Tân Biên",
        "type": "phuong",
        "slug": "tan-bien",
        "name_with_type": "Phường Tân Biên",
        "path": "Tân Biên, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Tân Biên, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "25999",
        "parent_code": "731"
    },
    "26002": {
        "name": "Hố Nai",
        "type": "phuong",
        "slug": "ho-nai",
        "name_with_type": "Phường Hố Nai",
        "path": "Hố Nai, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Hố Nai, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26002",
        "parent_code": "731"
    },
    "26005": {
        "name": "Tân Hòa",
        "type": "phuong",
        "slug": "tan-hoa",
        "name_with_type": "Phường Tân Hòa",
        "path": "Tân Hòa, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Tân Hòa, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26005",
        "parent_code": "731"
    },
    "26008": {
        "name": "Tân Hiệp",
        "type": "phuong",
        "slug": "tan-hiep",
        "name_with_type": "Phường Tân Hiệp",
        "path": "Tân Hiệp, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Tân Hiệp, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26008",
        "parent_code": "731"
    },
    "26011": {
        "name": "Bửu Long",
        "type": "phuong",
        "slug": "buu-long",
        "name_with_type": "Phường Bửu Long",
        "path": "Bửu Long, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Bửu Long, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26011",
        "parent_code": "731"
    },
    "26014": {
        "name": "Tân Tiến",
        "type": "phuong",
        "slug": "tan-tien",
        "name_with_type": "Phường Tân Tiến",
        "path": "Tân Tiến, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Tân Tiến, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26014",
        "parent_code": "731"
    },
    "26017": {
        "name": "Tam Hiệp",
        "type": "phuong",
        "slug": "tam-hiep",
        "name_with_type": "Phường Tam Hiệp",
        "path": "Tam Hiệp, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Tam Hiệp, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26017",
        "parent_code": "731"
    },
    "26020": {
        "name": "Long Bình",
        "type": "phuong",
        "slug": "long-binh",
        "name_with_type": "Phường Long Bình",
        "path": "Long Bình, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Long Bình, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26020",
        "parent_code": "731"
    },
    "26023": {
        "name": "Quang Vinh",
        "type": "phuong",
        "slug": "quang-vinh",
        "name_with_type": "Phường Quang Vinh",
        "path": "Quang Vinh, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Quang Vinh, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26023",
        "parent_code": "731"
    },
    "26026": {
        "name": "Tân Mai",
        "type": "phuong",
        "slug": "tan-mai",
        "name_with_type": "Phường Tân Mai",
        "path": "Tân Mai, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Tân Mai, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26026",
        "parent_code": "731"
    },
    "26029": {
        "name": "Thống Nhất",
        "type": "phuong",
        "slug": "thong-nhat",
        "name_with_type": "Phường Thống Nhất",
        "path": "Thống Nhất, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Thống Nhất, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26029",
        "parent_code": "731"
    },
    "26032": {
        "name": "Trung Dũng",
        "type": "phuong",
        "slug": "trung-dung",
        "name_with_type": "Phường Trung Dũng",
        "path": "Trung Dũng, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Trung Dũng, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26032",
        "parent_code": "731"
    },
    "26035": {
        "name": "Tam Hòa",
        "type": "phuong",
        "slug": "tam-hoa",
        "name_with_type": "Phường Tam Hòa",
        "path": "Tam Hòa, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Tam Hòa, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26035",
        "parent_code": "731"
    },
    "26038": {
        "name": "Hòa Bình",
        "type": "phuong",
        "slug": "hoa-binh",
        "name_with_type": "Phường Hòa Bình",
        "path": "Hòa Bình, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Hòa Bình, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26038",
        "parent_code": "731"
    },
    "26041": {
        "name": "Quyết Thắng",
        "type": "phuong",
        "slug": "quyet-thang",
        "name_with_type": "Phường Quyết Thắng",
        "path": "Quyết Thắng, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Quyết Thắng, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26041",
        "parent_code": "731"
    },
    "26044": {
        "name": "Thanh Bình",
        "type": "phuong",
        "slug": "thanh-binh",
        "name_with_type": "Phường Thanh Bình",
        "path": "Thanh Bình, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Thanh Bình, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26044",
        "parent_code": "731"
    },
    "26047": {
        "name": "Bình Đa",
        "type": "phuong",
        "slug": "binh-da",
        "name_with_type": "Phường Bình Đa",
        "path": "Bình Đa, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Bình Đa, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26047",
        "parent_code": "731"
    },
    "26050": {
        "name": "An Bình",
        "type": "phuong",
        "slug": "an-binh",
        "name_with_type": "Phường An Bình",
        "path": "An Bình, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường An Bình, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26050",
        "parent_code": "731"
    },
    "26053": {
        "name": "Bửu Hòa",
        "type": "phuong",
        "slug": "buu-hoa",
        "name_with_type": "Phường Bửu Hòa",
        "path": "Bửu Hòa, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Bửu Hòa, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26053",
        "parent_code": "731"
    },
    "26056": {
        "name": "Long Bình Tân",
        "type": "phuong",
        "slug": "long-binh-tan",
        "name_with_type": "Phường Long Bình Tân",
        "path": "Long Bình Tân, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Long Bình Tân, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26056",
        "parent_code": "731"
    },
    "26059": {
        "name": "Tân Vạn",
        "type": "phuong",
        "slug": "tan-van",
        "name_with_type": "Phường Tân Vạn",
        "path": "Tân Vạn, Biên Hòa, Đồng Nai",
        "path_with_type": "Phường Tân Vạn, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26059",
        "parent_code": "731"
    },
    "26062": {
        "name": "Tân Hạnh",
        "type": "xa",
        "slug": "tan-hanh",
        "name_with_type": "Xã Tân Hạnh",
        "path": "Tân Hạnh, Biên Hòa, Đồng Nai",
        "path_with_type": "Xã Tân Hạnh, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26062",
        "parent_code": "731"
    },
    "26065": {
        "name": "Hiệp Hòa",
        "type": "xa",
        "slug": "hiep-hoa",
        "name_with_type": "Xã Hiệp Hòa",
        "path": "Hiệp Hòa, Biên Hòa, Đồng Nai",
        "path_with_type": "Xã Hiệp Hòa, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26065",
        "parent_code": "731"
    },
    "26068": {
        "name": "Hóa An",
        "type": "xa",
        "slug": "hoa-an",
        "name_with_type": "Xã Hóa An",
        "path": "Hóa An, Biên Hòa, Đồng Nai",
        "path_with_type": "Xã Hóa An, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26068",
        "parent_code": "731"
    },
    "26371": {
        "name": "An Hoà",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hoà",
        "path": "An Hoà, Biên Hòa, Đồng Nai",
        "path_with_type": "Xã An Hoà, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26371",
        "parent_code": "731"
    },
    "26374": {
        "name": "Tam Phước",
        "type": "xa",
        "slug": "tam-phuoc",
        "name_with_type": "Xã Tam Phước",
        "path": "Tam Phước, Biên Hòa, Đồng Nai",
        "path_with_type": "Xã Tam Phước, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26374",
        "parent_code": "731"
    },
    "26377": {
        "name": "Phước Tân",
        "type": "xa",
        "slug": "phuoc-tan",
        "name_with_type": "Xã Phước Tân",
        "path": "Phước Tân, Biên Hòa, Đồng Nai",
        "path_with_type": "Xã Phước Tân, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26377",
        "parent_code": "731"
    },
    "26380": {
        "name": "Long Hưng",
        "type": "xa",
        "slug": "long-hung",
        "name_with_type": "Xã Long Hưng",
        "path": "Long Hưng, Biên Hòa, Đồng Nai",
        "path_with_type": "Xã Long Hưng, Thành phố Biên Hòa, Tỉnh Đồng Nai",
        "code": "26380",
        "parent_code": "731"
    },
    "26071": {
        "name": "Xuân Trung",
        "type": "phuong",
        "slug": "xuan-trung",
        "name_with_type": "Phường Xuân Trung",
        "path": "Xuân Trung, Long Khánh, Đồng Nai",
        "path_with_type": "Phường Xuân Trung, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26071",
        "parent_code": "732"
    },
    "26074": {
        "name": "Xuân Thanh",
        "type": "phuong",
        "slug": "xuan-thanh",
        "name_with_type": "Phường Xuân Thanh",
        "path": "Xuân Thanh, Long Khánh, Đồng Nai",
        "path_with_type": "Phường Xuân Thanh, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26074",
        "parent_code": "732"
    },
    "26077": {
        "name": "Xuân Bình",
        "type": "phuong",
        "slug": "xuan-binh",
        "name_with_type": "Phường Xuân Bình",
        "path": "Xuân Bình, Long Khánh, Đồng Nai",
        "path_with_type": "Phường Xuân Bình, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26077",
        "parent_code": "732"
    },
    "26080": {
        "name": "Xuân An",
        "type": "phuong",
        "slug": "xuan-an",
        "name_with_type": "Phường Xuân An",
        "path": "Xuân An, Long Khánh, Đồng Nai",
        "path_with_type": "Phường Xuân An, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26080",
        "parent_code": "732"
    },
    "26083": {
        "name": "Xuân Hoà",
        "type": "phuong",
        "slug": "xuan-hoa",
        "name_with_type": "Phường Xuân Hoà",
        "path": "Xuân Hoà, Long Khánh, Đồng Nai",
        "path_with_type": "Phường Xuân Hoà, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26083",
        "parent_code": "732"
    },
    "26086": {
        "name": "Phú Bình",
        "type": "phuong",
        "slug": "phu-binh",
        "name_with_type": "Phường Phú Bình",
        "path": "Phú Bình, Long Khánh, Đồng Nai",
        "path_with_type": "Phường Phú Bình, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26086",
        "parent_code": "732"
    },
    "26089": {
        "name": "Bình Lộc",
        "type": "xa",
        "slug": "binh-loc",
        "name_with_type": "Xã Bình Lộc",
        "path": "Bình Lộc, Long Khánh, Đồng Nai",
        "path_with_type": "Xã Bình Lộc, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26089",
        "parent_code": "732"
    },
    "26092": {
        "name": "Bảo Quang",
        "type": "xa",
        "slug": "bao-quang",
        "name_with_type": "Xã Bảo Quang",
        "path": "Bảo Quang, Long Khánh, Đồng Nai",
        "path_with_type": "Xã Bảo Quang, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26092",
        "parent_code": "732"
    },
    "26095": {
        "name": "Suối Tre",
        "type": "xa",
        "slug": "suoi-tre",
        "name_with_type": "Xã Suối Tre",
        "path": "Suối Tre, Long Khánh, Đồng Nai",
        "path_with_type": "Xã Suối Tre, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26095",
        "parent_code": "732"
    },
    "26098": {
        "name": "Bảo Vinh",
        "type": "xa",
        "slug": "bao-vinh",
        "name_with_type": "Xã Bảo Vinh",
        "path": "Bảo Vinh, Long Khánh, Đồng Nai",
        "path_with_type": "Xã Bảo Vinh, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26098",
        "parent_code": "732"
    },
    "26101": {
        "name": "Xuân Lập",
        "type": "xa",
        "slug": "xuan-lap",
        "name_with_type": "Xã Xuân Lập",
        "path": "Xuân Lập, Long Khánh, Đồng Nai",
        "path_with_type": "Xã Xuân Lập, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26101",
        "parent_code": "732"
    },
    "26104": {
        "name": "Bàu Sen",
        "type": "xa",
        "slug": "bau-sen",
        "name_with_type": "Xã Bàu Sen",
        "path": "Bàu Sen, Long Khánh, Đồng Nai",
        "path_with_type": "Xã Bàu Sen, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26104",
        "parent_code": "732"
    },
    "26107": {
        "name": "Bàu Trâm",
        "type": "xa",
        "slug": "bau-tram",
        "name_with_type": "Xã Bàu Trâm",
        "path": "Bàu Trâm, Long Khánh, Đồng Nai",
        "path_with_type": "Xã Bàu Trâm, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26107",
        "parent_code": "732"
    },
    "26110": {
        "name": "Xuân Tân",
        "type": "xa",
        "slug": "xuan-tan",
        "name_with_type": "Xã Xuân Tân",
        "path": "Xuân Tân, Long Khánh, Đồng Nai",
        "path_with_type": "Xã Xuân Tân, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26110",
        "parent_code": "732"
    },
    "26113": {
        "name": "Hàng Gòn",
        "type": "xa",
        "slug": "hang-gon",
        "name_with_type": "Xã Hàng Gòn",
        "path": "Hàng Gòn, Long Khánh, Đồng Nai",
        "path_with_type": "Xã Hàng Gòn, Thị xã Long Khánh, Tỉnh Đồng Nai",
        "code": "26113",
        "parent_code": "732"
    },
    "26116": {
        "name": "Tân Phú",
        "type": "thi-tran",
        "slug": "tan-phu",
        "name_with_type": "Thị trấn Tân Phú",
        "path": "Tân Phú, Tân Phú, Đồng Nai",
        "path_with_type": "Thị trấn Tân Phú, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26116",
        "parent_code": "734"
    },
    "26119": {
        "name": "Dak Lua",
        "type": "xa",
        "slug": "dak-lua",
        "name_with_type": "Xã Dak Lua",
        "path": "Dak Lua, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Dak Lua, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26119",
        "parent_code": "734"
    },
    "26122": {
        "name": "Nam Cát Tiên",
        "type": "xa",
        "slug": "nam-cat-tien",
        "name_with_type": "Xã Nam Cát Tiên",
        "path": "Nam Cát Tiên, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Nam Cát Tiên, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26122",
        "parent_code": "734"
    },
    "26125": {
        "name": "Phú An",
        "type": "xa",
        "slug": "phu-an",
        "name_with_type": "Xã Phú An",
        "path": "Phú An, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú An, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26125",
        "parent_code": "734"
    },
    "26128": {
        "name": "Núi Tượng",
        "type": "xa",
        "slug": "nui-tuong",
        "name_with_type": "Xã Núi Tượng",
        "path": "Núi Tượng, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Núi Tượng, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26128",
        "parent_code": "734"
    },
    "26131": {
        "name": "Tà Lài",
        "type": "xa",
        "slug": "ta-lai",
        "name_with_type": "Xã Tà Lài",
        "path": "Tà Lài, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Tà Lài, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26131",
        "parent_code": "734"
    },
    "26134": {
        "name": "Phú Lập",
        "type": "xa",
        "slug": "phu-lap",
        "name_with_type": "Xã Phú Lập",
        "path": "Phú Lập, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Lập, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26134",
        "parent_code": "734"
    },
    "26137": {
        "name": "Phú Sơn",
        "type": "xa",
        "slug": "phu-son",
        "name_with_type": "Xã Phú Sơn",
        "path": "Phú Sơn, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Sơn, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26137",
        "parent_code": "734"
    },
    "26140": {
        "name": "Phú Thịnh",
        "type": "xa",
        "slug": "phu-thinh",
        "name_with_type": "Xã Phú Thịnh",
        "path": "Phú Thịnh, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Thịnh, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26140",
        "parent_code": "734"
    },
    "26143": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Thanh Sơn, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26143",
        "parent_code": "734"
    },
    "26146": {
        "name": "Phú Trung",
        "type": "xa",
        "slug": "phu-trung",
        "name_with_type": "Xã Phú Trung",
        "path": "Phú Trung, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Trung, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26146",
        "parent_code": "734"
    },
    "26149": {
        "name": "Phú Xuân",
        "type": "xa",
        "slug": "phu-xuan",
        "name_with_type": "Xã Phú Xuân",
        "path": "Phú Xuân, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Xuân, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26149",
        "parent_code": "734"
    },
    "26152": {
        "name": "Phú Lộc",
        "type": "xa",
        "slug": "phu-loc",
        "name_with_type": "Xã Phú Lộc",
        "path": "Phú Lộc, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Lộc, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26152",
        "parent_code": "734"
    },
    "26155": {
        "name": "Phú Lâm",
        "type": "xa",
        "slug": "phu-lam",
        "name_with_type": "Xã Phú Lâm",
        "path": "Phú Lâm, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Lâm, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26155",
        "parent_code": "734"
    },
    "26158": {
        "name": "Phú Bình",
        "type": "xa",
        "slug": "phu-binh",
        "name_with_type": "Xã Phú Bình",
        "path": "Phú Bình, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Bình, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26158",
        "parent_code": "734"
    },
    "26161": {
        "name": "Phú Thanh",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thanh",
        "path": "Phú Thanh, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Thanh, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26161",
        "parent_code": "734"
    },
    "26164": {
        "name": "Trà Cổ",
        "type": "xa",
        "slug": "tra-co",
        "name_with_type": "Xã Trà Cổ",
        "path": "Trà Cổ, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Trà Cổ, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26164",
        "parent_code": "734"
    },
    "26167": {
        "name": "Phú Điền",
        "type": "xa",
        "slug": "phu-dien",
        "name_with_type": "Xã Phú Điền",
        "path": "Phú Điền, Tân Phú, Đồng Nai",
        "path_with_type": "Xã Phú Điền, Huyện Tân Phú, Tỉnh Đồng Nai",
        "code": "26167",
        "parent_code": "734"
    },
    "26170": {
        "name": "Vĩnh An",
        "type": "thi-tran",
        "slug": "vinh-an",
        "name_with_type": "Thị trấn Vĩnh An",
        "path": "Vĩnh An, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Thị trấn Vĩnh An, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26170",
        "parent_code": "735"
    },
    "26173": {
        "name": "Phú Lý",
        "type": "xa",
        "slug": "phu-ly",
        "name_with_type": "Xã Phú Lý",
        "path": "Phú Lý, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Phú Lý, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26173",
        "parent_code": "735"
    },
    "26176": {
        "name": "Trị An",
        "type": "xa",
        "slug": "tri-an",
        "name_with_type": "Xã Trị An",
        "path": "Trị An, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Trị An, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26176",
        "parent_code": "735"
    },
    "26179": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Tân An, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26179",
        "parent_code": "735"
    },
    "26182": {
        "name": "Vĩnh Tân",
        "type": "xa",
        "slug": "vinh-tan",
        "name_with_type": "Xã Vĩnh Tân",
        "path": "Vĩnh Tân, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Vĩnh Tân, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26182",
        "parent_code": "735"
    },
    "26185": {
        "name": "Bình Lợi",
        "type": "xa",
        "slug": "binh-loi",
        "name_with_type": "Xã Bình Lợi",
        "path": "Bình Lợi, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Bình Lợi, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26185",
        "parent_code": "735"
    },
    "26188": {
        "name": "Thạnh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thạnh Phú",
        "path": "Thạnh Phú, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Thạnh Phú, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26188",
        "parent_code": "735"
    },
    "26191": {
        "name": "Thiện Tân",
        "type": "xa",
        "slug": "thien-tan",
        "name_with_type": "Xã Thiện Tân",
        "path": "Thiện Tân, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Thiện Tân, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26191",
        "parent_code": "735"
    },
    "26194": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Tân Bình, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26194",
        "parent_code": "735"
    },
    "26197": {
        "name": "Bình Hòa",
        "type": "xa",
        "slug": "binh-hoa",
        "name_with_type": "Xã Bình Hòa",
        "path": "Bình Hòa, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Bình Hòa, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26197",
        "parent_code": "735"
    },
    "26200": {
        "name": "Mã Đà",
        "type": "xa",
        "slug": "ma-da",
        "name_with_type": "Xã Mã Đà",
        "path": "Mã Đà, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Mã Đà, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26200",
        "parent_code": "735"
    },
    "26203": {
        "name": "Hiếu Liêm",
        "type": "xa",
        "slug": "hieu-liem",
        "name_with_type": "Xã Hiếu Liêm",
        "path": "Hiếu Liêm, Vĩnh Cửu, Đồng Nai",
        "path_with_type": "Xã Hiếu Liêm, Huyện Vĩnh Cửu, Tỉnh Đồng Nai",
        "code": "26203",
        "parent_code": "735"
    },
    "26206": {
        "name": "Định Quán",
        "type": "thi-tran",
        "slug": "dinh-quan",
        "name_with_type": "Thị trấn Định Quán",
        "path": "Định Quán, Định Quán, Đồng Nai",
        "path_with_type": "Thị trấn Định Quán, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26206",
        "parent_code": "736"
    },
    "26209": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Định Quán, Đồng Nai",
        "path_with_type": "Xã Thanh Sơn, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26209",
        "parent_code": "736"
    },
    "26212": {
        "name": "Phú Tân",
        "type": "xa",
        "slug": "phu-tan",
        "name_with_type": "Xã Phú Tân",
        "path": "Phú Tân, Định Quán, Đồng Nai",
        "path_with_type": "Xã Phú Tân, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26212",
        "parent_code": "736"
    },
    "26215": {
        "name": "Phú Vinh",
        "type": "xa",
        "slug": "phu-vinh",
        "name_with_type": "Xã Phú Vinh",
        "path": "Phú Vinh, Định Quán, Đồng Nai",
        "path_with_type": "Xã Phú Vinh, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26215",
        "parent_code": "736"
    },
    "26218": {
        "name": "Phú Lợi",
        "type": "xa",
        "slug": "phu-loi",
        "name_with_type": "Xã Phú Lợi",
        "path": "Phú Lợi, Định Quán, Đồng Nai",
        "path_with_type": "Xã Phú Lợi, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26218",
        "parent_code": "736"
    },
    "26221": {
        "name": "Phú Hòa",
        "type": "xa",
        "slug": "phu-hoa",
        "name_with_type": "Xã Phú Hòa",
        "path": "Phú Hòa, Định Quán, Đồng Nai",
        "path_with_type": "Xã Phú Hòa, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26221",
        "parent_code": "736"
    },
    "26224": {
        "name": "Ngọc Định",
        "type": "xa",
        "slug": "ngoc-dinh",
        "name_with_type": "Xã Ngọc Định",
        "path": "Ngọc Định, Định Quán, Đồng Nai",
        "path_with_type": "Xã Ngọc Định, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26224",
        "parent_code": "736"
    },
    "26227": {
        "name": "La Ngà",
        "type": "xa",
        "slug": "la-nga",
        "name_with_type": "Xã La Ngà",
        "path": "La Ngà, Định Quán, Đồng Nai",
        "path_with_type": "Xã La Ngà, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26227",
        "parent_code": "736"
    },
    "26230": {
        "name": "Gia Canh",
        "type": "xa",
        "slug": "gia-canh",
        "name_with_type": "Xã Gia Canh",
        "path": "Gia Canh, Định Quán, Đồng Nai",
        "path_with_type": "Xã Gia Canh, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26230",
        "parent_code": "736"
    },
    "26233": {
        "name": "Phú Ngọc",
        "type": "xa",
        "slug": "phu-ngoc",
        "name_with_type": "Xã Phú Ngọc",
        "path": "Phú Ngọc, Định Quán, Đồng Nai",
        "path_with_type": "Xã Phú Ngọc, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26233",
        "parent_code": "736"
    },
    "26236": {
        "name": "Phú Cường",
        "type": "xa",
        "slug": "phu-cuong",
        "name_with_type": "Xã Phú Cường",
        "path": "Phú Cường, Định Quán, Đồng Nai",
        "path_with_type": "Xã Phú Cường, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26236",
        "parent_code": "736"
    },
    "26239": {
        "name": "Túc Trưng",
        "type": "xa",
        "slug": "tuc-trung",
        "name_with_type": "Xã Túc Trưng",
        "path": "Túc Trưng, Định Quán, Đồng Nai",
        "path_with_type": "Xã Túc Trưng, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26239",
        "parent_code": "736"
    },
    "26242": {
        "name": "Phú Túc",
        "type": "xa",
        "slug": "phu-tuc",
        "name_with_type": "Xã Phú Túc",
        "path": "Phú Túc, Định Quán, Đồng Nai",
        "path_with_type": "Xã Phú Túc, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26242",
        "parent_code": "736"
    },
    "26245": {
        "name": "Suối Nho",
        "type": "xa",
        "slug": "suoi-nho",
        "name_with_type": "Xã Suối Nho",
        "path": "Suối Nho, Định Quán, Đồng Nai",
        "path_with_type": "Xã Suối Nho, Huyện Định Quán, Tỉnh Đồng Nai",
        "code": "26245",
        "parent_code": "736"
    },
    "26248": {
        "name": "Trảng Bom",
        "type": "thi-tran",
        "slug": "trang-bom",
        "name_with_type": "Thị trấn Trảng Bom",
        "path": "Trảng Bom, Trảng Bom, Đồng Nai",
        "path_with_type": "Thị trấn Trảng Bom, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26248",
        "parent_code": "737"
    },
    "26251": {
        "name": "Thanh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thanh Bình",
        "path": "Thanh Bình, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Thanh Bình, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26251",
        "parent_code": "737"
    },
    "26254": {
        "name": "Cây Gáo",
        "type": "xa",
        "slug": "cay-gao",
        "name_with_type": "Xã Cây Gáo",
        "path": "Cây Gáo, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Cây Gáo, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26254",
        "parent_code": "737"
    },
    "26257": {
        "name": "Bàu Hàm",
        "type": "xa",
        "slug": "bau-ham",
        "name_with_type": "Xã Bàu Hàm",
        "path": "Bàu Hàm, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Bàu Hàm, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26257",
        "parent_code": "737"
    },
    "26260": {
        "name": "Sông Thao",
        "type": "xa",
        "slug": "song-thao",
        "name_with_type": "Xã Sông Thao",
        "path": "Sông Thao, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Sông Thao, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26260",
        "parent_code": "737"
    },
    "26263": {
        "name": "Sông Trầu",
        "type": "xa",
        "slug": "song-trau",
        "name_with_type": "Xã Sông Trầu",
        "path": "Sông Trầu, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Sông Trầu, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26263",
        "parent_code": "737"
    },
    "26266": {
        "name": "Đông Hoà",
        "type": "xa",
        "slug": "dong-hoa",
        "name_with_type": "Xã Đông Hoà",
        "path": "Đông Hoà, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Đông Hoà, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26266",
        "parent_code": "737"
    },
    "26269": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Bắc Sơn, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26269",
        "parent_code": "737"
    },
    "26272": {
        "name": "Hố Nai 3",
        "type": "xa",
        "slug": "ho-nai-3",
        "name_with_type": "Xã Hố Nai 3",
        "path": "Hố Nai 3, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Hố Nai 3, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26272",
        "parent_code": "737"
    },
    "26275": {
        "name": "Tây Hoà",
        "type": "xa",
        "slug": "tay-hoa",
        "name_with_type": "Xã Tây Hoà",
        "path": "Tây Hoà, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Tây Hoà, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26275",
        "parent_code": "737"
    },
    "26278": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Bình Minh, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26278",
        "parent_code": "737"
    },
    "26281": {
        "name": "Trung Hoà",
        "type": "xa",
        "slug": "trung-hoa",
        "name_with_type": "Xã Trung Hoà",
        "path": "Trung Hoà, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Trung Hoà, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26281",
        "parent_code": "737"
    },
    "26284": {
        "name": "Đồi 61",
        "type": "xa",
        "slug": "doi-61",
        "name_with_type": "Xã Đồi 61",
        "path": "Đồi 61, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Đồi 61, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26284",
        "parent_code": "737"
    },
    "26287": {
        "name": "Hưng Thịnh",
        "type": "xa",
        "slug": "hung-thinh",
        "name_with_type": "Xã Hưng Thịnh",
        "path": "Hưng Thịnh, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Hưng Thịnh, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26287",
        "parent_code": "737"
    },
    "26290": {
        "name": "Quảng Tiến",
        "type": "xa",
        "slug": "quang-tien",
        "name_with_type": "Xã Quảng Tiến",
        "path": "Quảng Tiến, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Quảng Tiến, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26290",
        "parent_code": "737"
    },
    "26293": {
        "name": "Giang Điền",
        "type": "xa",
        "slug": "giang-dien",
        "name_with_type": "Xã Giang Điền",
        "path": "Giang Điền, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã Giang Điền, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26293",
        "parent_code": "737"
    },
    "26296": {
        "name": "An Viễn",
        "type": "xa",
        "slug": "an-vien",
        "name_with_type": "Xã An Viễn",
        "path": "An Viễn, Trảng Bom, Đồng Nai",
        "path_with_type": "Xã An Viễn, Huyện Trảng Bom, Tỉnh Đồng Nai",
        "code": "26296",
        "parent_code": "737"
    },
    "26299": {
        "name": "Gia Tân 1",
        "type": "xa",
        "slug": "gia-tan-1",
        "name_with_type": "Xã Gia Tân 1",
        "path": "Gia Tân 1, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Gia Tân 1, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26299",
        "parent_code": "738"
    },
    "26302": {
        "name": "Gia Tân 2",
        "type": "xa",
        "slug": "gia-tan-2",
        "name_with_type": "Xã Gia Tân 2",
        "path": "Gia Tân 2, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Gia Tân 2, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26302",
        "parent_code": "738"
    },
    "26305": {
        "name": "Gia Tân 3",
        "type": "xa",
        "slug": "gia-tan-3",
        "name_with_type": "Xã Gia Tân 3",
        "path": "Gia Tân 3, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Gia Tân 3, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26305",
        "parent_code": "738"
    },
    "26308": {
        "name": "Gia Kiệm",
        "type": "xa",
        "slug": "gia-kiem",
        "name_with_type": "Xã Gia Kiệm",
        "path": "Gia Kiệm, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Gia Kiệm, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26308",
        "parent_code": "738"
    },
    "26311": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Quang Trung, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26311",
        "parent_code": "738"
    },
    "26314": {
        "name": "Bàu Hàm 2",
        "type": "xa",
        "slug": "bau-ham-2",
        "name_with_type": "Xã Bàu Hàm 2",
        "path": "Bàu Hàm 2, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Bàu Hàm 2, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26314",
        "parent_code": "738"
    },
    "26317": {
        "name": "Hưng Lộc",
        "type": "xa",
        "slug": "hung-loc",
        "name_with_type": "Xã Hưng Lộc",
        "path": "Hưng Lộc, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Hưng Lộc, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26317",
        "parent_code": "738"
    },
    "26320": {
        "name": "Lộ 25",
        "type": "xa",
        "slug": "lo-25",
        "name_with_type": "Xã Lộ 25",
        "path": "Lộ 25, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Lộ 25, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26320",
        "parent_code": "738"
    },
    "26323": {
        "name": "Xuân Thiện",
        "type": "xa",
        "slug": "xuan-thien",
        "name_with_type": "Xã Xuân Thiện",
        "path": "Xuân Thiện, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Xuân Thiện, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26323",
        "parent_code": "738"
    },
    "26326": {
        "name": "Xuân Thạnh",
        "type": "xa",
        "slug": "xuan-thanh",
        "name_with_type": "Xã Xuân Thạnh",
        "path": "Xuân Thạnh, Thống Nhất, Đồng Nai",
        "path_with_type": "Xã Xuân Thạnh, Huyện Thống Nhất, Tỉnh Đồng Nai",
        "code": "26326",
        "parent_code": "738"
    },
    "26329": {
        "name": "Sông Nhạn",
        "type": "xa",
        "slug": "song-nhan",
        "name_with_type": "Xã Sông Nhạn",
        "path": "Sông Nhạn, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Sông Nhạn, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26329",
        "parent_code": "739"
    },
    "26332": {
        "name": "Xuân Quế",
        "type": "xa",
        "slug": "xuan-que",
        "name_with_type": "Xã Xuân Quế",
        "path": "Xuân Quế, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Xuân Quế, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26332",
        "parent_code": "739"
    },
    "26335": {
        "name": "Nhân Nghĩa",
        "type": "xa",
        "slug": "nhan-nghia",
        "name_with_type": "Xã Nhân Nghĩa",
        "path": "Nhân Nghĩa, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Nhân Nghĩa, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26335",
        "parent_code": "739"
    },
    "26338": {
        "name": "Xuân Đường",
        "type": "xa",
        "slug": "xuan-duong",
        "name_with_type": "Xã Xuân Đường",
        "path": "Xuân Đường, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Xuân Đường, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26338",
        "parent_code": "739"
    },
    "26341": {
        "name": "Long Giao",
        "type": "xa",
        "slug": "long-giao",
        "name_with_type": "Xã Long Giao",
        "path": "Long Giao, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Long Giao, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26341",
        "parent_code": "739"
    },
    "26344": {
        "name": "Xuân Mỹ",
        "type": "xa",
        "slug": "xuan-my",
        "name_with_type": "Xã Xuân Mỹ",
        "path": "Xuân Mỹ, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Xuân Mỹ, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26344",
        "parent_code": "739"
    },
    "26347": {
        "name": "Thừa Đức",
        "type": "xa",
        "slug": "thua-duc",
        "name_with_type": "Xã Thừa Đức",
        "path": "Thừa Đức, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Thừa Đức, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26347",
        "parent_code": "739"
    },
    "26350": {
        "name": "Bảo Bình",
        "type": "xa",
        "slug": "bao-binh",
        "name_with_type": "Xã Bảo Bình",
        "path": "Bảo Bình, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Bảo Bình, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26350",
        "parent_code": "739"
    },
    "26353": {
        "name": "Xuân Bảo",
        "type": "xa",
        "slug": "xuan-bao",
        "name_with_type": "Xã Xuân Bảo",
        "path": "Xuân Bảo, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Xuân Bảo, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26353",
        "parent_code": "739"
    },
    "26356": {
        "name": "Xuân Tây",
        "type": "xa",
        "slug": "xuan-tay",
        "name_with_type": "Xã Xuân Tây",
        "path": "Xuân Tây, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Xuân Tây, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26356",
        "parent_code": "739"
    },
    "26359": {
        "name": "Xuân Đông",
        "type": "xa",
        "slug": "xuan-dong",
        "name_with_type": "Xã Xuân Đông",
        "path": "Xuân Đông, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Xuân Đông, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26359",
        "parent_code": "739"
    },
    "26362": {
        "name": "Sông Ray",
        "type": "xa",
        "slug": "song-ray",
        "name_with_type": "Xã Sông Ray",
        "path": "Sông Ray, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Sông Ray, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26362",
        "parent_code": "739"
    },
    "26365": {
        "name": "Lâm San",
        "type": "xa",
        "slug": "lam-san",
        "name_with_type": "Xã Lâm San",
        "path": "Lâm San, Cẩm Mỹ, Đồng Nai",
        "path_with_type": "Xã Lâm San, Huyện Cẩm Mỹ, Tỉnh Đồng Nai",
        "code": "26365",
        "parent_code": "739"
    },
    "26368": {
        "name": "Long Thành",
        "type": "thi-tran",
        "slug": "long-thanh",
        "name_with_type": "Thị trấn Long Thành",
        "path": "Long Thành, Long Thành, Đồng Nai",
        "path_with_type": "Thị trấn Long Thành, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26368",
        "parent_code": "740"
    },
    "26383": {
        "name": "An Phước",
        "type": "xa",
        "slug": "an-phuoc",
        "name_with_type": "Xã An Phước",
        "path": "An Phước, Long Thành, Đồng Nai",
        "path_with_type": "Xã An Phước, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26383",
        "parent_code": "740"
    },
    "26386": {
        "name": "Bình An",
        "type": "xa",
        "slug": "binh-an",
        "name_with_type": "Xã Bình An",
        "path": "Bình An, Long Thành, Đồng Nai",
        "path_with_type": "Xã Bình An, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26386",
        "parent_code": "740"
    },
    "26389": {
        "name": "Long Đức",
        "type": "xa",
        "slug": "long-duc",
        "name_with_type": "Xã Long Đức",
        "path": "Long Đức, Long Thành, Đồng Nai",
        "path_with_type": "Xã Long Đức, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26389",
        "parent_code": "740"
    },
    "26392": {
        "name": "Lộc An",
        "type": "xa",
        "slug": "loc-an",
        "name_with_type": "Xã Lộc An",
        "path": "Lộc An, Long Thành, Đồng Nai",
        "path_with_type": "Xã Lộc An, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26392",
        "parent_code": "740"
    },
    "26395": {
        "name": "Bình Sơn",
        "type": "xa",
        "slug": "binh-son",
        "name_with_type": "Xã Bình Sơn",
        "path": "Bình Sơn, Long Thành, Đồng Nai",
        "path_with_type": "Xã Bình Sơn, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26395",
        "parent_code": "740"
    },
    "26398": {
        "name": "Tam An",
        "type": "xa",
        "slug": "tam-an",
        "name_with_type": "Xã Tam An",
        "path": "Tam An, Long Thành, Đồng Nai",
        "path_with_type": "Xã Tam An, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26398",
        "parent_code": "740"
    },
    "26401": {
        "name": "Cẩm Đường",
        "type": "xa",
        "slug": "cam-duong",
        "name_with_type": "Xã Cẩm Đường",
        "path": "Cẩm Đường, Long Thành, Đồng Nai",
        "path_with_type": "Xã Cẩm Đường, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26401",
        "parent_code": "740"
    },
    "26404": {
        "name": "Long An",
        "type": "xa",
        "slug": "long-an",
        "name_with_type": "Xã Long An",
        "path": "Long An, Long Thành, Đồng Nai",
        "path_with_type": "Xã Long An, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26404",
        "parent_code": "740"
    },
    "26407": {
        "name": "Suối Trầu",
        "type": "xa",
        "slug": "suoi-trau",
        "name_with_type": "Xã Suối Trầu",
        "path": "Suối Trầu, Long Thành, Đồng Nai",
        "path_with_type": "Xã Suối Trầu, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26407",
        "parent_code": "740"
    },
    "26410": {
        "name": "Bàu Cạn",
        "type": "xa",
        "slug": "bau-can",
        "name_with_type": "Xã Bàu Cạn",
        "path": "Bàu Cạn, Long Thành, Đồng Nai",
        "path_with_type": "Xã Bàu Cạn, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26410",
        "parent_code": "740"
    },
    "26413": {
        "name": "Long Phước",
        "type": "xa",
        "slug": "long-phuoc",
        "name_with_type": "Xã Long Phước",
        "path": "Long Phước, Long Thành, Đồng Nai",
        "path_with_type": "Xã Long Phước, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26413",
        "parent_code": "740"
    },
    "26416": {
        "name": "Phước Bình",
        "type": "xa",
        "slug": "phuoc-binh",
        "name_with_type": "Xã Phước Bình",
        "path": "Phước Bình, Long Thành, Đồng Nai",
        "path_with_type": "Xã Phước Bình, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26416",
        "parent_code": "740"
    },
    "26419": {
        "name": "Tân Hiệp",
        "type": "xa",
        "slug": "tan-hiep",
        "name_with_type": "Xã Tân Hiệp",
        "path": "Tân Hiệp, Long Thành, Đồng Nai",
        "path_with_type": "Xã Tân Hiệp, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26419",
        "parent_code": "740"
    },
    "26422": {
        "name": "Phước Thái",
        "type": "xa",
        "slug": "phuoc-thai",
        "name_with_type": "Xã Phước Thái",
        "path": "Phước Thái, Long Thành, Đồng Nai",
        "path_with_type": "Xã Phước Thái, Huyện Long Thành, Tỉnh Đồng Nai",
        "code": "26422",
        "parent_code": "740"
    },
    "26425": {
        "name": "Gia Ray",
        "type": "thi-tran",
        "slug": "gia-ray",
        "name_with_type": "Thị trấn Gia Ray",
        "path": "Gia Ray, Xuân Lộc, Đồng Nai",
        "path_with_type": "Thị trấn Gia Ray, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26425",
        "parent_code": "741"
    },
    "26428": {
        "name": "Xuân Bắc",
        "type": "xa",
        "slug": "xuan-bac",
        "name_with_type": "Xã Xuân Bắc",
        "path": "Xuân Bắc, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Bắc, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26428",
        "parent_code": "741"
    },
    "26431": {
        "name": "Suối Cao",
        "type": "xa",
        "slug": "suoi-cao",
        "name_with_type": "Xã Suối Cao",
        "path": "Suối Cao, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Suối Cao, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26431",
        "parent_code": "741"
    },
    "26434": {
        "name": "Xuân Thành",
        "type": "xa",
        "slug": "xuan-thanh",
        "name_with_type": "Xã Xuân Thành",
        "path": "Xuân Thành, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Thành, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26434",
        "parent_code": "741"
    },
    "26437": {
        "name": "Xuân Thọ",
        "type": "xa",
        "slug": "xuan-tho",
        "name_with_type": "Xã Xuân Thọ",
        "path": "Xuân Thọ, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Thọ, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26437",
        "parent_code": "741"
    },
    "26440": {
        "name": "Xuân Trường",
        "type": "xa",
        "slug": "xuan-truong",
        "name_with_type": "Xã Xuân Trường",
        "path": "Xuân Trường, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Trường, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26440",
        "parent_code": "741"
    },
    "26443": {
        "name": "Xuân Hòa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hòa",
        "path": "Xuân Hòa, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Hòa, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26443",
        "parent_code": "741"
    },
    "26446": {
        "name": "Xuân Hưng",
        "type": "xa",
        "slug": "xuan-hung",
        "name_with_type": "Xã Xuân Hưng",
        "path": "Xuân Hưng, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Hưng, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26446",
        "parent_code": "741"
    },
    "26449": {
        "name": "Xuân Tâm",
        "type": "xa",
        "slug": "xuan-tam",
        "name_with_type": "Xã Xuân Tâm",
        "path": "Xuân Tâm, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Tâm, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26449",
        "parent_code": "741"
    },
    "26452": {
        "name": "Suối Cát",
        "type": "xa",
        "slug": "suoi-cat",
        "name_with_type": "Xã Suối Cát",
        "path": "Suối Cát, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Suối Cát, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26452",
        "parent_code": "741"
    },
    "26455": {
        "name": "Xuân Hiệp",
        "type": "xa",
        "slug": "xuan-hiep",
        "name_with_type": "Xã Xuân Hiệp",
        "path": "Xuân Hiệp, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Hiệp, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26455",
        "parent_code": "741"
    },
    "26458": {
        "name": "Xuân Phú",
        "type": "xa",
        "slug": "xuan-phu",
        "name_with_type": "Xã Xuân Phú",
        "path": "Xuân Phú, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Phú, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26458",
        "parent_code": "741"
    },
    "26461": {
        "name": "Xuân Định",
        "type": "xa",
        "slug": "xuan-dinh",
        "name_with_type": "Xã Xuân Định",
        "path": "Xuân Định, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Xuân Định, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26461",
        "parent_code": "741"
    },
    "26464": {
        "name": "Bảo Hoà",
        "type": "xa",
        "slug": "bao-hoa",
        "name_with_type": "Xã Bảo Hoà",
        "path": "Bảo Hoà, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Bảo Hoà, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26464",
        "parent_code": "741"
    },
    "26467": {
        "name": "Lang Minh",
        "type": "xa",
        "slug": "lang-minh",
        "name_with_type": "Xã Lang Minh",
        "path": "Lang Minh, Xuân Lộc, Đồng Nai",
        "path_with_type": "Xã Lang Minh, Huyện Xuân Lộc, Tỉnh Đồng Nai",
        "code": "26467",
        "parent_code": "741"
    },
    "26470": {
        "name": "Phước Thiền",
        "type": "xa",
        "slug": "phuoc-thien",
        "name_with_type": "Xã Phước Thiền",
        "path": "Phước Thiền, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Phước Thiền, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26470",
        "parent_code": "742"
    },
    "26473": {
        "name": "Long Tân",
        "type": "xa",
        "slug": "long-tan",
        "name_with_type": "Xã Long Tân",
        "path": "Long Tân, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Long Tân, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26473",
        "parent_code": "742"
    },
    "26476": {
        "name": "Đại Phước",
        "type": "xa",
        "slug": "dai-phuoc",
        "name_with_type": "Xã Đại Phước",
        "path": "Đại Phước, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Đại Phước, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26476",
        "parent_code": "742"
    },
    "26479": {
        "name": "Hiệp Phước",
        "type": "xa",
        "slug": "hiep-phuoc",
        "name_with_type": "Xã Hiệp Phước",
        "path": "Hiệp Phước, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Hiệp Phước, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26479",
        "parent_code": "742"
    },
    "26482": {
        "name": "Phú Hữu",
        "type": "xa",
        "slug": "phu-huu",
        "name_with_type": "Xã Phú Hữu",
        "path": "Phú Hữu, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Phú Hữu, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26482",
        "parent_code": "742"
    },
    "26485": {
        "name": "Phú Hội",
        "type": "xa",
        "slug": "phu-hoi",
        "name_with_type": "Xã Phú Hội",
        "path": "Phú Hội, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Phú Hội, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26485",
        "parent_code": "742"
    },
    "26488": {
        "name": "Phú Thạnh",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thạnh",
        "path": "Phú Thạnh, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Phú Thạnh, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26488",
        "parent_code": "742"
    },
    "26491": {
        "name": "Phú Đông",
        "type": "xa",
        "slug": "phu-dong",
        "name_with_type": "Xã Phú Đông",
        "path": "Phú Đông, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Phú Đông, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26491",
        "parent_code": "742"
    },
    "26494": {
        "name": "Long Thọ",
        "type": "xa",
        "slug": "long-tho",
        "name_with_type": "Xã Long Thọ",
        "path": "Long Thọ, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Long Thọ, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26494",
        "parent_code": "742"
    },
    "26497": {
        "name": "Vĩnh Thanh",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thanh",
        "path": "Vĩnh Thanh, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Vĩnh Thanh, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26497",
        "parent_code": "742"
    },
    "26500": {
        "name": "Phước Khánh",
        "type": "xa",
        "slug": "phuoc-khanh",
        "name_with_type": "Xã Phước Khánh",
        "path": "Phước Khánh, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Phước Khánh, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26500",
        "parent_code": "742"
    },
    "26503": {
        "name": "Phước An",
        "type": "xa",
        "slug": "phuoc-an",
        "name_with_type": "Xã Phước An",
        "path": "Phước An, Nhơn Trạch, Đồng Nai",
        "path_with_type": "Xã Phước An, Huyện Nhơn Trạch, Tỉnh Đồng Nai",
        "code": "26503",
        "parent_code": "742"
    },
    "28249": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 5, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28249",
        "parent_code": "815"
    },
    "28252": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 4, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28252",
        "parent_code": "815"
    },
    "28255": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 7, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28255",
        "parent_code": "815"
    },
    "28258": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 3, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28258",
        "parent_code": "815"
    },
    "28261": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 1, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28261",
        "parent_code": "815"
    },
    "28264": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 2, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28264",
        "parent_code": "815"
    },
    "28267": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 8, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28267",
        "parent_code": "815"
    },
    "28270": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 6, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28270",
        "parent_code": "815"
    },
    "28273": {
        "name": "9",
        "type": "phuong",
        "slug": "9",
        "name_with_type": "Phường 9",
        "path": "9, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 9, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28273",
        "parent_code": "815"
    },
    "28276": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường 10, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28276",
        "parent_code": "815"
    },
    "28279": {
        "name": "Tân Long",
        "type": "phuong",
        "slug": "tan-long",
        "name_with_type": "Phường Tân Long",
        "path": "Tân Long, Mỹ Tho, Tiền Giang",
        "path_with_type": "Phường Tân Long, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28279",
        "parent_code": "815"
    },
    "28282": {
        "name": "Đạo Thạnh",
        "type": "xa",
        "slug": "dao-thanh",
        "name_with_type": "Xã Đạo Thạnh",
        "path": "Đạo Thạnh, Mỹ Tho, Tiền Giang",
        "path_with_type": "Xã Đạo Thạnh, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28282",
        "parent_code": "815"
    },
    "28285": {
        "name": "Trung An",
        "type": "xa",
        "slug": "trung-an",
        "name_with_type": "Xã Trung An",
        "path": "Trung An, Mỹ Tho, Tiền Giang",
        "path_with_type": "Xã Trung An, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28285",
        "parent_code": "815"
    },
    "28288": {
        "name": "Mỹ Phong",
        "type": "xa",
        "slug": "my-phong",
        "name_with_type": "Xã Mỹ Phong",
        "path": "Mỹ Phong, Mỹ Tho, Tiền Giang",
        "path_with_type": "Xã Mỹ Phong, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28288",
        "parent_code": "815"
    },
    "28291": {
        "name": "Tân Mỹ Chánh",
        "type": "xa",
        "slug": "tan-my-chanh",
        "name_with_type": "Xã Tân Mỹ Chánh",
        "path": "Tân Mỹ Chánh, Mỹ Tho, Tiền Giang",
        "path_with_type": "Xã Tân Mỹ Chánh, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28291",
        "parent_code": "815"
    },
    "28567": {
        "name": "Phước Thạnh",
        "type": "xa",
        "slug": "phuoc-thanh",
        "name_with_type": "Xã Phước Thạnh",
        "path": "Phước Thạnh, Mỹ Tho, Tiền Giang",
        "path_with_type": "Xã Phước Thạnh, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28567",
        "parent_code": "815"
    },
    "28591": {
        "name": "Thới Sơn",
        "type": "xa",
        "slug": "thoi-son",
        "name_with_type": "Xã Thới Sơn",
        "path": "Thới Sơn, Mỹ Tho, Tiền Giang",
        "path_with_type": "Xã Thới Sơn, Thành phố Mỹ Tho, Tỉnh Tiền Giang",
        "code": "28591",
        "parent_code": "815"
    },
    "28294": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Gò Công, Tiền Giang",
        "path_with_type": "Phường 3, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28294",
        "parent_code": "816"
    },
    "28297": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Gò Công, Tiền Giang",
        "path_with_type": "Phường 2, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28297",
        "parent_code": "816"
    },
    "28300": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Gò Công, Tiền Giang",
        "path_with_type": "Phường 4, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28300",
        "parent_code": "816"
    },
    "28303": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Gò Công, Tiền Giang",
        "path_with_type": "Phường 1, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28303",
        "parent_code": "816"
    },
    "28306": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Gò Công, Tiền Giang",
        "path_with_type": "Phường 5, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28306",
        "parent_code": "816"
    },
    "28309": {
        "name": "Long Hưng",
        "type": "xa",
        "slug": "long-hung",
        "name_with_type": "Xã Long Hưng",
        "path": "Long Hưng, Gò Công, Tiền Giang",
        "path_with_type": "Xã Long Hưng, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28309",
        "parent_code": "816"
    },
    "28312": {
        "name": "Long Thuận",
        "type": "xa",
        "slug": "long-thuan",
        "name_with_type": "Xã Long Thuận",
        "path": "Long Thuận, Gò Công, Tiền Giang",
        "path_with_type": "Xã Long Thuận, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28312",
        "parent_code": "816"
    },
    "28315": {
        "name": "Long Chánh",
        "type": "xa",
        "slug": "long-chanh",
        "name_with_type": "Xã Long Chánh",
        "path": "Long Chánh, Gò Công, Tiền Giang",
        "path_with_type": "Xã Long Chánh, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28315",
        "parent_code": "816"
    },
    "28318": {
        "name": "Long Hòa",
        "type": "xa",
        "slug": "long-hoa",
        "name_with_type": "Xã Long Hòa",
        "path": "Long Hòa, Gò Công, Tiền Giang",
        "path_with_type": "Xã Long Hòa, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28318",
        "parent_code": "816"
    },
    "28708": {
        "name": "Bình Đông",
        "type": "xa",
        "slug": "binh-dong",
        "name_with_type": "Xã Bình Đông",
        "path": "Bình Đông, Gò Công, Tiền Giang",
        "path_with_type": "Xã Bình Đông, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28708",
        "parent_code": "816"
    },
    "28717": {
        "name": "Bình Xuân",
        "type": "xa",
        "slug": "binh-xuan",
        "name_with_type": "Xã Bình Xuân",
        "path": "Bình Xuân, Gò Công, Tiền Giang",
        "path_with_type": "Xã Bình Xuân, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28717",
        "parent_code": "816"
    },
    "28729": {
        "name": "Tân Trung",
        "type": "xa",
        "slug": "tan-trung",
        "name_with_type": "Xã Tân Trung",
        "path": "Tân Trung, Gò Công, Tiền Giang",
        "path_with_type": "Xã Tân Trung, Thị xã Gò Công, Tỉnh Tiền Giang",
        "code": "28729",
        "parent_code": "816"
    },
    "28435": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Cai Lậy, Tiền Giang",
        "path_with_type": "Phường 1, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28435",
        "parent_code": "817"
    },
    "28436": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Cai Lậy, Tiền Giang",
        "path_with_type": "Phường 2, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28436",
        "parent_code": "817"
    },
    "28437": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Cai Lậy, Tiền Giang",
        "path_with_type": "Phường 3, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28437",
        "parent_code": "817"
    },
    "28439": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Cai Lậy, Tiền Giang",
        "path_with_type": "Phường 4, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28439",
        "parent_code": "817"
    },
    "28440": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Cai Lậy, Tiền Giang",
        "path_with_type": "Phường 5, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28440",
        "parent_code": "817"
    },
    "28447": {
        "name": "Mỹ Phước Tây",
        "type": "xa",
        "slug": "my-phuoc-tay",
        "name_with_type": "Xã Mỹ Phước Tây",
        "path": "Mỹ Phước Tây, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Mỹ Phước Tây, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28447",
        "parent_code": "817"
    },
    "28450": {
        "name": "Mỹ Hạnh Đông",
        "type": "xa",
        "slug": "my-hanh-dong",
        "name_with_type": "Xã Mỹ Hạnh Đông",
        "path": "Mỹ Hạnh Đông, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Mỹ Hạnh Đông, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28450",
        "parent_code": "817"
    },
    "28453": {
        "name": "Mỹ Hạnh Trung",
        "type": "xa",
        "slug": "my-hanh-trung",
        "name_with_type": "Xã Mỹ Hạnh Trung",
        "path": "Mỹ Hạnh Trung, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Mỹ Hạnh Trung, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28453",
        "parent_code": "817"
    },
    "28459": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Tân Phú, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28459",
        "parent_code": "817"
    },
    "28462": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Tân Bình, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28462",
        "parent_code": "817"
    },
    "28468": {
        "name": "Tân Hội",
        "type": "xa",
        "slug": "tan-hoi",
        "name_with_type": "Xã Tân Hội",
        "path": "Tân Hội, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Tân Hội, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28468",
        "parent_code": "817"
    },
    "28474": {
        "name": "Nhị Mỹ",
        "type": "phuong",
        "slug": "nhi-my",
        "name_with_type": "Phường Nhị Mỹ",
        "path": "Nhị Mỹ, Cai Lậy, Tiền Giang",
        "path_with_type": "Phường Nhị Mỹ, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28474",
        "parent_code": "817"
    },
    "28477": {
        "name": "Nhị Quý",
        "type": "xa",
        "slug": "nhi-quy",
        "name_with_type": "Xã Nhị Quý",
        "path": "Nhị Quý, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Nhị Quý, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28477",
        "parent_code": "817"
    },
    "28480": {
        "name": "Thanh Hòa",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thanh Hòa",
        "path": "Thanh Hòa, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Thanh Hòa, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28480",
        "parent_code": "817"
    },
    "28483": {
        "name": "Phú Quý",
        "type": "xa",
        "slug": "phu-quy",
        "name_with_type": "Xã Phú Quý",
        "path": "Phú Quý, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Phú Quý, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28483",
        "parent_code": "817"
    },
    "28486": {
        "name": "Long Khánh",
        "type": "xa",
        "slug": "long-khanh",
        "name_with_type": "Xã Long Khánh",
        "path": "Long Khánh, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Long Khánh, Thị xã Cai Lậy, Tỉnh Tiền Giang",
        "code": "28486",
        "parent_code": "817"
    },
    "28321": {
        "name": "Mỹ Phước",
        "type": "thi-tran",
        "slug": "my-phuoc",
        "name_with_type": "Thị trấn Mỹ Phước",
        "path": "Mỹ Phước, Tân Phước, Tiền Giang",
        "path_with_type": "Thị trấn Mỹ Phước, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28321",
        "parent_code": "818"
    },
    "28324": {
        "name": "Tân Hòa Đông",
        "type": "xa",
        "slug": "tan-hoa-dong",
        "name_with_type": "Xã Tân Hòa Đông",
        "path": "Tân Hòa Đông, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Tân Hòa Đông, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28324",
        "parent_code": "818"
    },
    "28327": {
        "name": "Thạnh Tân",
        "type": "xa",
        "slug": "thanh-tan",
        "name_with_type": "Xã Thạnh Tân",
        "path": "Thạnh Tân, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Thạnh Tân, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28327",
        "parent_code": "818"
    },
    "28330": {
        "name": "Thạnh Mỹ",
        "type": "xa",
        "slug": "thanh-my",
        "name_with_type": "Xã Thạnh Mỹ",
        "path": "Thạnh Mỹ, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Thạnh Mỹ, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28330",
        "parent_code": "818"
    },
    "28333": {
        "name": "Thạnh Hoà",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thạnh Hoà",
        "path": "Thạnh Hoà, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Thạnh Hoà, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28333",
        "parent_code": "818"
    },
    "28336": {
        "name": "Phú Mỹ",
        "type": "xa",
        "slug": "phu-my",
        "name_with_type": "Xã Phú Mỹ",
        "path": "Phú Mỹ, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Phú Mỹ, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28336",
        "parent_code": "818"
    },
    "28339": {
        "name": "Tân Hòa Thành",
        "type": "xa",
        "slug": "tan-hoa-thanh",
        "name_with_type": "Xã Tân Hòa Thành",
        "path": "Tân Hòa Thành, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Tân Hòa Thành, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28339",
        "parent_code": "818"
    },
    "28342": {
        "name": "Hưng Thạnh",
        "type": "xa",
        "slug": "hung-thanh",
        "name_with_type": "Xã Hưng Thạnh",
        "path": "Hưng Thạnh, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Hưng Thạnh, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28342",
        "parent_code": "818"
    },
    "28345": {
        "name": "Tân Lập 1",
        "type": "xa",
        "slug": "tan-lap-1",
        "name_with_type": "Xã Tân Lập 1",
        "path": "Tân Lập 1, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Tân Lập 1, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28345",
        "parent_code": "818"
    },
    "28348": {
        "name": "Tân Hòa Tây",
        "type": "xa",
        "slug": "tan-hoa-tay",
        "name_with_type": "Xã Tân Hòa Tây",
        "path": "Tân Hòa Tây, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Tân Hòa Tây, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28348",
        "parent_code": "818"
    },
    "28351": {
        "name": "Mỹ Phước",
        "type": "xa",
        "slug": "my-phuoc",
        "name_with_type": "Xã Mỹ Phước",
        "path": "Mỹ Phước, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Mỹ Phước, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28351",
        "parent_code": "818"
    },
    "28354": {
        "name": "Tân Lập 2",
        "type": "xa",
        "slug": "tan-lap-2",
        "name_with_type": "Xã Tân Lập 2",
        "path": "Tân Lập 2, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Tân Lập 2, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28354",
        "parent_code": "818"
    },
    "28357": {
        "name": "Phước Lập",
        "type": "xa",
        "slug": "phuoc-lap",
        "name_with_type": "Xã Phước Lập",
        "path": "Phước Lập, Tân Phước, Tiền Giang",
        "path_with_type": "Xã Phước Lập, Huyện Tân Phước, Tỉnh Tiền Giang",
        "code": "28357",
        "parent_code": "818"
    },
    "28360": {
        "name": "Cái Bè",
        "type": "thi-tran",
        "slug": "cai-be",
        "name_with_type": "Thị trấn Cái Bè",
        "path": "Cái Bè, Cái Bè, Tiền Giang",
        "path_with_type": "Thị trấn Cái Bè, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28360",
        "parent_code": "819"
    },
    "28363": {
        "name": "Hậu Mỹ Bắc B",
        "type": "xa",
        "slug": "hau-my-bac-b",
        "name_with_type": "Xã Hậu Mỹ Bắc B",
        "path": "Hậu Mỹ Bắc B, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Hậu Mỹ Bắc B, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28363",
        "parent_code": "819"
    },
    "28366": {
        "name": "Hậu Mỹ Bắc A",
        "type": "xa",
        "slug": "hau-my-bac-a",
        "name_with_type": "Xã Hậu Mỹ Bắc A",
        "path": "Hậu Mỹ Bắc A, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Hậu Mỹ Bắc A, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28366",
        "parent_code": "819"
    },
    "28369": {
        "name": "Mỹ Trung",
        "type": "xa",
        "slug": "my-trung",
        "name_with_type": "Xã Mỹ Trung",
        "path": "Mỹ Trung, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Mỹ Trung, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28369",
        "parent_code": "819"
    },
    "28372": {
        "name": "Hậu Mỹ Trinh",
        "type": "xa",
        "slug": "hau-my-trinh",
        "name_with_type": "Xã Hậu Mỹ Trinh",
        "path": "Hậu Mỹ Trinh, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Hậu Mỹ Trinh, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28372",
        "parent_code": "819"
    },
    "28375": {
        "name": "Hậu Mỹ Phú",
        "type": "xa",
        "slug": "hau-my-phu",
        "name_with_type": "Xã Hậu Mỹ Phú",
        "path": "Hậu Mỹ Phú, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Hậu Mỹ Phú, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28375",
        "parent_code": "819"
    },
    "28378": {
        "name": "Mỹ Tân",
        "type": "xa",
        "slug": "my-tan",
        "name_with_type": "Xã Mỹ Tân",
        "path": "Mỹ Tân, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Mỹ Tân, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28378",
        "parent_code": "819"
    },
    "28381": {
        "name": "Mỹ Lợi B",
        "type": "xa",
        "slug": "my-loi-b",
        "name_with_type": "Xã Mỹ Lợi B",
        "path": "Mỹ Lợi B, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Mỹ Lợi B, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28381",
        "parent_code": "819"
    },
    "28384": {
        "name": "Thiện Trung",
        "type": "xa",
        "slug": "thien-trung",
        "name_with_type": "Xã Thiện Trung",
        "path": "Thiện Trung, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Thiện Trung, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28384",
        "parent_code": "819"
    },
    "28387": {
        "name": "Mỹ Hội",
        "type": "xa",
        "slug": "my-hoi",
        "name_with_type": "Xã Mỹ Hội",
        "path": "Mỹ Hội, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Mỹ Hội, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28387",
        "parent_code": "819"
    },
    "28390": {
        "name": "An Cư",
        "type": "xa",
        "slug": "an-cu",
        "name_with_type": "Xã An Cư",
        "path": "An Cư, Cái Bè, Tiền Giang",
        "path_with_type": "Xã An Cư, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28390",
        "parent_code": "819"
    },
    "28393": {
        "name": "Hậu Thành",
        "type": "xa",
        "slug": "hau-thanh",
        "name_with_type": "Xã Hậu Thành",
        "path": "Hậu Thành, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Hậu Thành, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28393",
        "parent_code": "819"
    },
    "28396": {
        "name": "Mỹ Lợi A",
        "type": "xa",
        "slug": "my-loi-a",
        "name_with_type": "Xã Mỹ Lợi A",
        "path": "Mỹ Lợi A, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Mỹ Lợi A, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28396",
        "parent_code": "819"
    },
    "28399": {
        "name": "Hòa Khánh",
        "type": "xa",
        "slug": "hoa-khanh",
        "name_with_type": "Xã Hòa Khánh",
        "path": "Hòa Khánh, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Hòa Khánh, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28399",
        "parent_code": "819"
    },
    "28402": {
        "name": "Thiện Trí",
        "type": "xa",
        "slug": "thien-tri",
        "name_with_type": "Xã Thiện Trí",
        "path": "Thiện Trí, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Thiện Trí, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28402",
        "parent_code": "819"
    },
    "28405": {
        "name": "Mỹ Đức Đông",
        "type": "xa",
        "slug": "my-duc-dong",
        "name_with_type": "Xã Mỹ Đức Đông",
        "path": "Mỹ Đức Đông, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Mỹ Đức Đông, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28405",
        "parent_code": "819"
    },
    "28408": {
        "name": "Mỹ Đức Tây",
        "type": "xa",
        "slug": "my-duc-tay",
        "name_with_type": "Xã Mỹ Đức Tây",
        "path": "Mỹ Đức Tây, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Mỹ Đức Tây, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28408",
        "parent_code": "819"
    },
    "28411": {
        "name": "Đông Hòa Hiệp",
        "type": "xa",
        "slug": "dong-hoa-hiep",
        "name_with_type": "Xã Đông Hòa Hiệp",
        "path": "Đông Hòa Hiệp, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Đông Hòa Hiệp, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28411",
        "parent_code": "819"
    },
    "28414": {
        "name": "An Thái Đông",
        "type": "xa",
        "slug": "an-thai-dong",
        "name_with_type": "Xã An Thái Đông",
        "path": "An Thái Đông, Cái Bè, Tiền Giang",
        "path_with_type": "Xã An Thái Đông, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28414",
        "parent_code": "819"
    },
    "28417": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Tân Hưng, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28417",
        "parent_code": "819"
    },
    "28420": {
        "name": "Mỹ Lương",
        "type": "xa",
        "slug": "my-luong",
        "name_with_type": "Xã Mỹ Lương",
        "path": "Mỹ Lương, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Mỹ Lương, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28420",
        "parent_code": "819"
    },
    "28423": {
        "name": "Tân Thanh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thanh",
        "path": "Tân Thanh, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Tân Thanh, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28423",
        "parent_code": "819"
    },
    "28426": {
        "name": "An Thái Trung",
        "type": "xa",
        "slug": "an-thai-trung",
        "name_with_type": "Xã An Thái Trung",
        "path": "An Thái Trung, Cái Bè, Tiền Giang",
        "path_with_type": "Xã An Thái Trung, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28426",
        "parent_code": "819"
    },
    "28429": {
        "name": "An Hữu",
        "type": "xa",
        "slug": "an-huu",
        "name_with_type": "Xã An Hữu",
        "path": "An Hữu, Cái Bè, Tiền Giang",
        "path_with_type": "Xã An Hữu, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28429",
        "parent_code": "819"
    },
    "28432": {
        "name": "Hòa Hưng",
        "type": "xa",
        "slug": "hoa-hung",
        "name_with_type": "Xã Hòa Hưng",
        "path": "Hòa Hưng, Cái Bè, Tiền Giang",
        "path_with_type": "Xã Hòa Hưng, Huyện Cái Bè, Tỉnh Tiền Giang",
        "code": "28432",
        "parent_code": "819"
    },
    "28438": {
        "name": "Thạnh Lộc",
        "type": "xa",
        "slug": "thanh-loc",
        "name_with_type": "Xã Thạnh Lộc",
        "path": "Thạnh Lộc, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Thạnh Lộc, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28438",
        "parent_code": "820"
    },
    "28441": {
        "name": "Mỹ Thành Bắc",
        "type": "xa",
        "slug": "my-thanh-bac",
        "name_with_type": "Xã Mỹ Thành Bắc",
        "path": "Mỹ Thành Bắc, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Mỹ Thành Bắc, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28441",
        "parent_code": "820"
    },
    "28444": {
        "name": "Phú Cường",
        "type": "xa",
        "slug": "phu-cuong",
        "name_with_type": "Xã Phú Cường",
        "path": "Phú Cường, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Phú Cường, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28444",
        "parent_code": "820"
    },
    "28456": {
        "name": "Mỹ Thành Nam",
        "type": "xa",
        "slug": "my-thanh-nam",
        "name_with_type": "Xã Mỹ Thành Nam",
        "path": "Mỹ Thành Nam, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Mỹ Thành Nam, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28456",
        "parent_code": "820"
    },
    "28465": {
        "name": "Phú Nhuận",
        "type": "xa",
        "slug": "phu-nhuan",
        "name_with_type": "Xã Phú Nhuận",
        "path": "Phú Nhuận, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Phú Nhuận, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28465",
        "parent_code": "820"
    },
    "28471": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Bình Phú, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28471",
        "parent_code": "820"
    },
    "28489": {
        "name": "Cẩm Sơn",
        "type": "xa",
        "slug": "cam-son",
        "name_with_type": "Xã Cẩm Sơn",
        "path": "Cẩm Sơn, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Cẩm Sơn, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28489",
        "parent_code": "820"
    },
    "28492": {
        "name": "Phú An",
        "type": "xa",
        "slug": "phu-an",
        "name_with_type": "Xã Phú An",
        "path": "Phú An, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Phú An, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28492",
        "parent_code": "820"
    },
    "28495": {
        "name": "Mỹ Long",
        "type": "xa",
        "slug": "my-long",
        "name_with_type": "Xã Mỹ Long",
        "path": "Mỹ Long, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Mỹ Long, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28495",
        "parent_code": "820"
    },
    "28498": {
        "name": "Long Tiên",
        "type": "xa",
        "slug": "long-tien",
        "name_with_type": "Xã Long Tiên",
        "path": "Long Tiên, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Long Tiên, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28498",
        "parent_code": "820"
    },
    "28501": {
        "name": "Hiệp Đức",
        "type": "xa",
        "slug": "hiep-duc",
        "name_with_type": "Xã Hiệp Đức",
        "path": "Hiệp Đức, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Hiệp Đức, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28501",
        "parent_code": "820"
    },
    "28504": {
        "name": "Long Trung",
        "type": "xa",
        "slug": "long-trung",
        "name_with_type": "Xã Long Trung",
        "path": "Long Trung, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Long Trung, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28504",
        "parent_code": "820"
    },
    "28507": {
        "name": "Hội Xuân",
        "type": "xa",
        "slug": "hoi-xuan",
        "name_with_type": "Xã Hội Xuân",
        "path": "Hội Xuân, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Hội Xuân, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28507",
        "parent_code": "820"
    },
    "28510": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Tân Phong, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28510",
        "parent_code": "820"
    },
    "28513": {
        "name": "Tam Bình",
        "type": "xa",
        "slug": "tam-binh",
        "name_with_type": "Xã Tam Bình",
        "path": "Tam Bình, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Tam Bình, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28513",
        "parent_code": "820"
    },
    "28516": {
        "name": "Ngũ Hiệp",
        "type": "xa",
        "slug": "ngu-hiep",
        "name_with_type": "Xã Ngũ Hiệp",
        "path": "Ngũ Hiệp, Cai Lậy, Tiền Giang",
        "path_with_type": "Xã Ngũ Hiệp, Huyện Cai Lậy, Tỉnh Tiền Giang",
        "code": "28516",
        "parent_code": "820"
    },
    "28519": {
        "name": "Tân Hiệp",
        "type": "thi-tran",
        "slug": "tan-hiep",
        "name_with_type": "Thị trấn Tân Hiệp",
        "path": "Tân Hiệp, Châu Thành, Tiền Giang",
        "path_with_type": "Thị trấn Tân Hiệp, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28519",
        "parent_code": "821"
    },
    "28522": {
        "name": "Tân Hội Đông",
        "type": "xa",
        "slug": "tan-hoi-dong",
        "name_with_type": "Xã Tân Hội Đông",
        "path": "Tân Hội Đông, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Tân Hội Đông, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28522",
        "parent_code": "821"
    },
    "28525": {
        "name": "Tân Hương",
        "type": "xa",
        "slug": "tan-huong",
        "name_with_type": "Xã Tân Hương",
        "path": "Tân Hương, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Tân Hương, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28525",
        "parent_code": "821"
    },
    "28528": {
        "name": "Tân Lý Đông",
        "type": "xa",
        "slug": "tan-ly-dong",
        "name_with_type": "Xã Tân Lý Đông",
        "path": "Tân Lý Đông, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Tân Lý Đông, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28528",
        "parent_code": "821"
    },
    "28531": {
        "name": "Tân Lý Tây",
        "type": "xa",
        "slug": "tan-ly-tay",
        "name_with_type": "Xã Tân Lý Tây",
        "path": "Tân Lý Tây, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Tân Lý Tây, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28531",
        "parent_code": "821"
    },
    "28534": {
        "name": "Thân Cửu Nghĩa",
        "type": "xa",
        "slug": "than-cuu-nghia",
        "name_with_type": "Xã Thân Cửu Nghĩa",
        "path": "Thân Cửu Nghĩa, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Thân Cửu Nghĩa, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28534",
        "parent_code": "821"
    },
    "28537": {
        "name": "Tam Hiệp",
        "type": "xa",
        "slug": "tam-hiep",
        "name_with_type": "Xã Tam Hiệp",
        "path": "Tam Hiệp, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Tam Hiệp, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28537",
        "parent_code": "821"
    },
    "28540": {
        "name": "Điềm Hy",
        "type": "xa",
        "slug": "diem-hy",
        "name_with_type": "Xã Điềm Hy",
        "path": "Điềm Hy, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Điềm Hy, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28540",
        "parent_code": "821"
    },
    "28543": {
        "name": "Nhị Bình",
        "type": "xa",
        "slug": "nhi-binh",
        "name_with_type": "Xã Nhị Bình",
        "path": "Nhị Bình, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Nhị Bình, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28543",
        "parent_code": "821"
    },
    "28546": {
        "name": "Dưỡng Điềm",
        "type": "xa",
        "slug": "duong-diem",
        "name_with_type": "Xã Dưỡng Điềm",
        "path": "Dưỡng Điềm, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Dưỡng Điềm, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28546",
        "parent_code": "821"
    },
    "28549": {
        "name": "Đông Hòa",
        "type": "xa",
        "slug": "dong-hoa",
        "name_with_type": "Xã Đông Hòa",
        "path": "Đông Hòa, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Đông Hòa, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28549",
        "parent_code": "821"
    },
    "28552": {
        "name": "Long Định",
        "type": "xa",
        "slug": "long-dinh",
        "name_with_type": "Xã Long Định",
        "path": "Long Định, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Long Định, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28552",
        "parent_code": "821"
    },
    "28555": {
        "name": "Hữu Đạo",
        "type": "xa",
        "slug": "huu-dao",
        "name_with_type": "Xã Hữu Đạo",
        "path": "Hữu Đạo, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Hữu Đạo, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28555",
        "parent_code": "821"
    },
    "28558": {
        "name": "Long An",
        "type": "xa",
        "slug": "long-an",
        "name_with_type": "Xã Long An",
        "path": "Long An, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Long An, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28558",
        "parent_code": "821"
    },
    "28561": {
        "name": "Long Hưng",
        "type": "xa",
        "slug": "long-hung",
        "name_with_type": "Xã Long Hưng",
        "path": "Long Hưng, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Long Hưng, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28561",
        "parent_code": "821"
    },
    "28564": {
        "name": "Bình Trưng",
        "type": "xa",
        "slug": "binh-trung",
        "name_with_type": "Xã Bình Trưng",
        "path": "Bình Trưng, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Bình Trưng, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28564",
        "parent_code": "821"
    },
    "28570": {
        "name": "Thạnh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thạnh Phú",
        "path": "Thạnh Phú, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Thạnh Phú, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28570",
        "parent_code": "821"
    },
    "28573": {
        "name": "Bàn Long",
        "type": "xa",
        "slug": "ban-long",
        "name_with_type": "Xã Bàn Long",
        "path": "Bàn Long, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Bàn Long, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28573",
        "parent_code": "821"
    },
    "28576": {
        "name": "Vĩnh Kim",
        "type": "xa",
        "slug": "vinh-kim",
        "name_with_type": "Xã Vĩnh Kim",
        "path": "Vĩnh Kim, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Vĩnh Kim, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28576",
        "parent_code": "821"
    },
    "28579": {
        "name": "Bình Đức",
        "type": "xa",
        "slug": "binh-duc",
        "name_with_type": "Xã Bình Đức",
        "path": "Bình Đức, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Bình Đức, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28579",
        "parent_code": "821"
    },
    "28582": {
        "name": "Song Thuận",
        "type": "xa",
        "slug": "song-thuan",
        "name_with_type": "Xã Song Thuận",
        "path": "Song Thuận, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Song Thuận, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28582",
        "parent_code": "821"
    },
    "28585": {
        "name": "Kim Sơn",
        "type": "xa",
        "slug": "kim-son",
        "name_with_type": "Xã Kim Sơn",
        "path": "Kim Sơn, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Kim Sơn, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28585",
        "parent_code": "821"
    },
    "28588": {
        "name": "Phú Phong",
        "type": "xa",
        "slug": "phu-phong",
        "name_with_type": "Xã Phú Phong",
        "path": "Phú Phong, Châu Thành, Tiền Giang",
        "path_with_type": "Xã Phú Phong, Huyện Châu Thành, Tỉnh Tiền Giang",
        "code": "28588",
        "parent_code": "821"
    },
    "28594": {
        "name": "Chợ Gạo",
        "type": "thi-tran",
        "slug": "cho-gao",
        "name_with_type": "Thị trấn Chợ Gạo",
        "path": "Chợ Gạo, Chợ Gạo, Tiền Giang",
        "path_with_type": "Thị trấn Chợ Gạo, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28594",
        "parent_code": "822"
    },
    "28597": {
        "name": "Trung Hòa",
        "type": "xa",
        "slug": "trung-hoa",
        "name_with_type": "Xã Trung Hòa",
        "path": "Trung Hòa, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Trung Hòa, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28597",
        "parent_code": "822"
    },
    "28600": {
        "name": "Hòa Tịnh",
        "type": "xa",
        "slug": "hoa-tinh",
        "name_with_type": "Xã Hòa Tịnh",
        "path": "Hòa Tịnh, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Hòa Tịnh, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28600",
        "parent_code": "822"
    },
    "28603": {
        "name": "Mỹ Tịnh An",
        "type": "xa",
        "slug": "my-tinh-an",
        "name_with_type": "Xã Mỹ Tịnh An",
        "path": "Mỹ Tịnh An, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Mỹ Tịnh An, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28603",
        "parent_code": "822"
    },
    "28606": {
        "name": "Tân Bình Thạnh",
        "type": "xa",
        "slug": "tan-binh-thanh",
        "name_with_type": "Xã Tân Bình Thạnh",
        "path": "Tân Bình Thạnh, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Tân Bình Thạnh, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28606",
        "parent_code": "822"
    },
    "28609": {
        "name": "Phú Kiết",
        "type": "xa",
        "slug": "phu-kiet",
        "name_with_type": "Xã Phú Kiết",
        "path": "Phú Kiết, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Phú Kiết, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28609",
        "parent_code": "822"
    },
    "28612": {
        "name": "Lương Hòa Lạc",
        "type": "xa",
        "slug": "luong-hoa-lac",
        "name_with_type": "Xã Lương Hòa Lạc",
        "path": "Lương Hòa Lạc, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Lương Hòa Lạc, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28612",
        "parent_code": "822"
    },
    "28615": {
        "name": "Thanh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thanh Bình",
        "path": "Thanh Bình, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Thanh Bình, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28615",
        "parent_code": "822"
    },
    "28618": {
        "name": "Quơn Long",
        "type": "xa",
        "slug": "quon-long",
        "name_with_type": "Xã Quơn Long",
        "path": "Quơn Long, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Quơn Long, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28618",
        "parent_code": "822"
    },
    "28621": {
        "name": "Bình Phục Nhứt",
        "type": "xa",
        "slug": "binh-phuc-nhut",
        "name_with_type": "Xã Bình Phục Nhứt",
        "path": "Bình Phục Nhứt, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Bình Phục Nhứt, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28621",
        "parent_code": "822"
    },
    "28624": {
        "name": "Đăng Hưng Phước",
        "type": "xa",
        "slug": "dang-hung-phuoc",
        "name_with_type": "Xã Đăng Hưng Phước",
        "path": "Đăng Hưng Phước, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Đăng Hưng Phước, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28624",
        "parent_code": "822"
    },
    "28627": {
        "name": "Tân Thuận Bình",
        "type": "xa",
        "slug": "tan-thuan-binh",
        "name_with_type": "Xã Tân Thuận Bình",
        "path": "Tân Thuận Bình, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Tân Thuận Bình, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28627",
        "parent_code": "822"
    },
    "28630": {
        "name": "Song Bình",
        "type": "xa",
        "slug": "song-binh",
        "name_with_type": "Xã Song Bình",
        "path": "Song Bình, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Song Bình, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28630",
        "parent_code": "822"
    },
    "28633": {
        "name": "Bình Phan",
        "type": "xa",
        "slug": "binh-phan",
        "name_with_type": "Xã Bình Phan",
        "path": "Bình Phan, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Bình Phan, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28633",
        "parent_code": "822"
    },
    "28636": {
        "name": "Long Bình Điền",
        "type": "xa",
        "slug": "long-binh-dien",
        "name_with_type": "Xã Long Bình Điền",
        "path": "Long Bình Điền, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Long Bình Điền, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28636",
        "parent_code": "822"
    },
    "28639": {
        "name": "An Thạnh Thủy",
        "type": "xa",
        "slug": "an-thanh-thuy",
        "name_with_type": "Xã An Thạnh Thủy",
        "path": "An Thạnh Thủy, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã An Thạnh Thủy, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28639",
        "parent_code": "822"
    },
    "28642": {
        "name": "Xuân Đông",
        "type": "xa",
        "slug": "xuan-dong",
        "name_with_type": "Xã Xuân Đông",
        "path": "Xuân Đông, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Xuân Đông, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28642",
        "parent_code": "822"
    },
    "28645": {
        "name": "Hòa Định",
        "type": "xa",
        "slug": "hoa-dinh",
        "name_with_type": "Xã Hòa Định",
        "path": "Hòa Định, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Hòa Định, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28645",
        "parent_code": "822"
    },
    "28648": {
        "name": "Bình Ninh",
        "type": "xa",
        "slug": "binh-ninh",
        "name_with_type": "Xã Bình Ninh",
        "path": "Bình Ninh, Chợ Gạo, Tiền Giang",
        "path_with_type": "Xã Bình Ninh, Huyện Chợ Gạo, Tỉnh Tiền Giang",
        "code": "28648",
        "parent_code": "822"
    },
    "28651": {
        "name": "Vĩnh Bình",
        "type": "thi-tran",
        "slug": "vinh-binh",
        "name_with_type": "Thị trấn Vĩnh Bình",
        "path": "Vĩnh Bình, Gò Công Tây, Tiền Giang",
        "path_with_type": "Thị trấn Vĩnh Bình, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28651",
        "parent_code": "823"
    },
    "28654": {
        "name": "Đồng Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đồng Sơn",
        "path": "Đồng Sơn, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Đồng Sơn, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28654",
        "parent_code": "823"
    },
    "28657": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Bình Phú, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28657",
        "parent_code": "823"
    },
    "28660": {
        "name": "Đồng Thạnh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đồng Thạnh",
        "path": "Đồng Thạnh, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Đồng Thạnh, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28660",
        "parent_code": "823"
    },
    "28663": {
        "name": "Thành Công",
        "type": "xa",
        "slug": "thanh-cong",
        "name_with_type": "Xã Thành Công",
        "path": "Thành Công, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Thành Công, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28663",
        "parent_code": "823"
    },
    "28666": {
        "name": "Bình Nhì",
        "type": "xa",
        "slug": "binh-nhi",
        "name_with_type": "Xã Bình Nhì",
        "path": "Bình Nhì, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Bình Nhì, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28666",
        "parent_code": "823"
    },
    "28669": {
        "name": "Yên Luông",
        "type": "xa",
        "slug": "yen-luong",
        "name_with_type": "Xã Yên Luông",
        "path": "Yên Luông, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Yên Luông, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28669",
        "parent_code": "823"
    },
    "28672": {
        "name": "Thạnh Trị",
        "type": "xa",
        "slug": "thanh-tri",
        "name_with_type": "Xã Thạnh Trị",
        "path": "Thạnh Trị, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Thạnh Trị, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28672",
        "parent_code": "823"
    },
    "28675": {
        "name": "Thạnh Nhựt",
        "type": "xa",
        "slug": "thanh-nhut",
        "name_with_type": "Xã Thạnh Nhựt",
        "path": "Thạnh Nhựt, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Thạnh Nhựt, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28675",
        "parent_code": "823"
    },
    "28678": {
        "name": "Long Vĩnh",
        "type": "xa",
        "slug": "long-vinh",
        "name_with_type": "Xã Long Vĩnh",
        "path": "Long Vĩnh, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Long Vĩnh, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28678",
        "parent_code": "823"
    },
    "28681": {
        "name": "Bình Tân",
        "type": "xa",
        "slug": "binh-tan",
        "name_with_type": "Xã Bình Tân",
        "path": "Bình Tân, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Bình Tân, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28681",
        "parent_code": "823"
    },
    "28684": {
        "name": "Vĩnh Hựu",
        "type": "xa",
        "slug": "vinh-huu",
        "name_with_type": "Xã Vĩnh Hựu",
        "path": "Vĩnh Hựu, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Vĩnh Hựu, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28684",
        "parent_code": "823"
    },
    "28687": {
        "name": "Long Bình",
        "type": "xa",
        "slug": "long-binh",
        "name_with_type": "Xã Long Bình",
        "path": "Long Bình, Gò Công Tây, Tiền Giang",
        "path_with_type": "Xã Long Bình, Huyện Gò Công Tây, Tỉnh Tiền Giang",
        "code": "28687",
        "parent_code": "823"
    },
    "28702": {
        "name": "Tân Hòa",
        "type": "thi-tran",
        "slug": "tan-hoa",
        "name_with_type": "Thị trấn Tân Hòa",
        "path": "Tân Hòa, Gò Công Đông, Tiền Giang",
        "path_with_type": "Thị trấn Tân Hòa, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28702",
        "parent_code": "824"
    },
    "28705": {
        "name": "Tăng Hoà",
        "type": "xa",
        "slug": "tang-hoa",
        "name_with_type": "Xã Tăng Hoà",
        "path": "Tăng Hoà, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Tăng Hoà, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28705",
        "parent_code": "824"
    },
    "28711": {
        "name": "Tân Phước",
        "type": "xa",
        "slug": "tan-phuoc",
        "name_with_type": "Xã Tân Phước",
        "path": "Tân Phước, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Tân Phước, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28711",
        "parent_code": "824"
    },
    "28714": {
        "name": "Gia Thuận",
        "type": "xa",
        "slug": "gia-thuan",
        "name_with_type": "Xã Gia Thuận",
        "path": "Gia Thuận, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Gia Thuận, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28714",
        "parent_code": "824"
    },
    "28720": {
        "name": "Vàm Láng",
        "type": "thi-tran",
        "slug": "vam-lang",
        "name_with_type": "Thị trấn Vàm Láng",
        "path": "Vàm Láng, Gò Công Đông, Tiền Giang",
        "path_with_type": "Thị trấn Vàm Láng, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28720",
        "parent_code": "824"
    },
    "28723": {
        "name": "Tân Tây",
        "type": "xa",
        "slug": "tan-tay",
        "name_with_type": "Xã Tân Tây",
        "path": "Tân Tây, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Tân Tây, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28723",
        "parent_code": "824"
    },
    "28726": {
        "name": "Kiểng Phước",
        "type": "xa",
        "slug": "kieng-phuoc",
        "name_with_type": "Xã Kiểng Phước",
        "path": "Kiểng Phước, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Kiểng Phước, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28726",
        "parent_code": "824"
    },
    "28732": {
        "name": "Tân Đông",
        "type": "xa",
        "slug": "tan-dong",
        "name_with_type": "Xã Tân Đông",
        "path": "Tân Đông, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Tân Đông, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28732",
        "parent_code": "824"
    },
    "28735": {
        "name": "Bình Ân",
        "type": "xa",
        "slug": "binh-an",
        "name_with_type": "Xã Bình Ân",
        "path": "Bình Ân, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Bình Ân, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28735",
        "parent_code": "824"
    },
    "28738": {
        "name": "Tân Điền",
        "type": "xa",
        "slug": "tan-dien",
        "name_with_type": "Xã Tân Điền",
        "path": "Tân Điền, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Tân Điền, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28738",
        "parent_code": "824"
    },
    "28741": {
        "name": "Bình Nghị",
        "type": "xa",
        "slug": "binh-nghi",
        "name_with_type": "Xã Bình Nghị",
        "path": "Bình Nghị, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Bình Nghị, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28741",
        "parent_code": "824"
    },
    "28744": {
        "name": "Phước Trung",
        "type": "xa",
        "slug": "phuoc-trung",
        "name_with_type": "Xã Phước Trung",
        "path": "Phước Trung, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Phước Trung, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28744",
        "parent_code": "824"
    },
    "28747": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Gò Công Đông, Tiền Giang",
        "path_with_type": "Xã Tân Thành, Huyện Gò Công Đông, Tỉnh Tiền Giang",
        "code": "28747",
        "parent_code": "824"
    },
    "28690": {
        "name": "Tân Thới",
        "type": "xa",
        "slug": "tan-thoi",
        "name_with_type": "Xã Tân Thới",
        "path": "Tân Thới, Tân Phú Đông, Tiền Giang",
        "path_with_type": "Xã Tân Thới, Huyện Tân Phú Đông, Tỉnh Tiền Giang",
        "code": "28690",
        "parent_code": "825"
    },
    "28693": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Tân Phú Đông, Tiền Giang",
        "path_with_type": "Xã Tân Phú, Huyện Tân Phú Đông, Tỉnh Tiền Giang",
        "code": "28693",
        "parent_code": "825"
    },
    "28696": {
        "name": "Phú Thạnh",
        "type": "xa",
        "slug": "phu-thanh",
        "name_with_type": "Xã Phú Thạnh",
        "path": "Phú Thạnh, Tân Phú Đông, Tiền Giang",
        "path_with_type": "Xã Phú Thạnh, Huyện Tân Phú Đông, Tỉnh Tiền Giang",
        "code": "28696",
        "parent_code": "825"
    },
    "28699": {
        "name": "Tân Thạnh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thạnh",
        "path": "Tân Thạnh, Tân Phú Đông, Tiền Giang",
        "path_with_type": "Xã Tân Thạnh, Huyện Tân Phú Đông, Tỉnh Tiền Giang",
        "code": "28699",
        "parent_code": "825"
    },
    "28750": {
        "name": "Phú Đông",
        "type": "xa",
        "slug": "phu-dong",
        "name_with_type": "Xã Phú Đông",
        "path": "Phú Đông, Tân Phú Đông, Tiền Giang",
        "path_with_type": "Xã Phú Đông, Huyện Tân Phú Đông, Tỉnh Tiền Giang",
        "code": "28750",
        "parent_code": "825"
    },
    "28753": {
        "name": "Phú Tân",
        "type": "xa",
        "slug": "phu-tan",
        "name_with_type": "Xã Phú Tân",
        "path": "Phú Tân, Tân Phú Đông, Tiền Giang",
        "path_with_type": "Xã Phú Tân, Huyện Tân Phú Đông, Tỉnh Tiền Giang",
        "code": "28753",
        "parent_code": "825"
    },
    "03124": {
        "name": "Noong Bua",
        "type": "phuong",
        "slug": "noong-bua",
        "name_with_type": "Phường Noong Bua",
        "path": "Noong Bua, Điện Biên Phủ, Điện Biên",
        "path_with_type": "Phường Noong Bua, Thành phố Điện Biên Phủ, Tỉnh Điện Biên",
        "code": "03124",
        "parent_code": "094"
    },
    "03127": {
        "name": "Him Lam",
        "type": "phuong",
        "slug": "him-lam",
        "name_with_type": "Phường Him Lam",
        "path": "Him Lam, Điện Biên Phủ, Điện Biên",
        "path_with_type": "Phường Him Lam, Thành phố Điện Biên Phủ, Tỉnh Điện Biên",
        "code": "03127",
        "parent_code": "094"
    },
    "03130": {
        "name": "Thanh Bình",
        "type": "phuong",
        "slug": "thanh-binh",
        "name_with_type": "Phường Thanh Bình",
        "path": "Thanh Bình, Điện Biên Phủ, Điện Biên",
        "path_with_type": "Phường Thanh Bình, Thành phố Điện Biên Phủ, Tỉnh Điện Biên",
        "code": "03130",
        "parent_code": "094"
    },
    "03133": {
        "name": "Tân Thanh",
        "type": "phuong",
        "slug": "tan-thanh",
        "name_with_type": "Phường Tân Thanh",
        "path": "Tân Thanh, Điện Biên Phủ, Điện Biên",
        "path_with_type": "Phường Tân Thanh, Thành phố Điện Biên Phủ, Tỉnh Điện Biên",
        "code": "03133",
        "parent_code": "094"
    },
    "03136": {
        "name": "Mường Thanh",
        "type": "phuong",
        "slug": "muong-thanh",
        "name_with_type": "Phường Mường Thanh",
        "path": "Mường Thanh, Điện Biên Phủ, Điện Biên",
        "path_with_type": "Phường Mường Thanh, Thành phố Điện Biên Phủ, Tỉnh Điện Biên",
        "code": "03136",
        "parent_code": "094"
    },
    "03139": {
        "name": "Nam Thanh",
        "type": "phuong",
        "slug": "nam-thanh",
        "name_with_type": "Phường Nam Thanh",
        "path": "Nam Thanh, Điện Biên Phủ, Điện Biên",
        "path_with_type": "Phường Nam Thanh, Thành phố Điện Biên Phủ, Tỉnh Điện Biên",
        "code": "03139",
        "parent_code": "094"
    },
    "03142": {
        "name": "Thanh Trường",
        "type": "phuong",
        "slug": "thanh-truong",
        "name_with_type": "Phường Thanh Trường",
        "path": "Thanh Trường, Điện Biên Phủ, Điện Biên",
        "path_with_type": "Phường Thanh Trường, Thành phố Điện Biên Phủ, Tỉnh Điện Biên",
        "code": "03142",
        "parent_code": "094"
    },
    "03144": {
        "name": "Tà Lèng",
        "type": "xa",
        "slug": "ta-leng",
        "name_with_type": "Xã Tà Lèng",
        "path": "Tà Lèng, Điện Biên Phủ, Điện Biên",
        "path_with_type": "Xã Tà Lèng, Thành phố Điện Biên Phủ, Tỉnh Điện Biên",
        "code": "03144",
        "parent_code": "094"
    },
    "03145": {
        "name": "Thanh Minh",
        "type": "xa",
        "slug": "thanh-minh",
        "name_with_type": "Xã Thanh Minh",
        "path": "Thanh Minh, Điện Biên Phủ, Điện Biên",
        "path_with_type": "Xã Thanh Minh, Thành phố Điện Biên Phủ, Tỉnh Điện Biên",
        "code": "03145",
        "parent_code": "094"
    },
    "03148": {
        "name": "Sông Đà",
        "type": "phuong",
        "slug": "song-da",
        "name_with_type": "Phường Sông Đà",
        "path": "Sông Đà, Mường Lay, Điện Biên",
        "path_with_type": "Phường Sông Đà, Thị xã Mường Lay, Tỉnh Điện Biên",
        "code": "03148",
        "parent_code": "095"
    },
    "03151": {
        "name": "Na Lay",
        "type": "phuong",
        "slug": "na-lay",
        "name_with_type": "Phường Na Lay",
        "path": "Na Lay, Mường Lay, Điện Biên",
        "path_with_type": "Phường Na Lay, Thị xã Mường Lay, Tỉnh Điện Biên",
        "code": "03151",
        "parent_code": "095"
    },
    "03184": {
        "name": "Lay Nưa",
        "type": "xa",
        "slug": "lay-nua",
        "name_with_type": "Xã Lay Nưa",
        "path": "Lay Nưa, Mường Lay, Điện Biên",
        "path_with_type": "Xã Lay Nưa, Thị xã Mường Lay, Tỉnh Điện Biên",
        "code": "03184",
        "parent_code": "095"
    },
    "03154": {
        "name": "Sín Thầu",
        "type": "xa",
        "slug": "sin-thau",
        "name_with_type": "Xã Sín Thầu",
        "path": "Sín Thầu, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Sín Thầu, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03154",
        "parent_code": "096"
    },
    "03155": {
        "name": "Sen Thượng",
        "type": "xa",
        "slug": "sen-thuong",
        "name_with_type": "Xã Sen Thượng",
        "path": "Sen Thượng, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Sen Thượng, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03155",
        "parent_code": "096"
    },
    "03157": {
        "name": "Chung Chải",
        "type": "xa",
        "slug": "chung-chai",
        "name_with_type": "Xã Chung Chải",
        "path": "Chung Chải, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Chung Chải, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03157",
        "parent_code": "096"
    },
    "03158": {
        "name": "Leng Su Sìn",
        "type": "xa",
        "slug": "leng-su-sin",
        "name_with_type": "Xã Leng Su Sìn",
        "path": "Leng Su Sìn, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Leng Su Sìn, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03158",
        "parent_code": "096"
    },
    "03159": {
        "name": "Pá Mỳ",
        "type": "xa",
        "slug": "pa-my",
        "name_with_type": "Xã Pá Mỳ",
        "path": "Pá Mỳ, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Pá Mỳ, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03159",
        "parent_code": "096"
    },
    "03160": {
        "name": "Mường Nhé",
        "type": "xa",
        "slug": "muong-nhe",
        "name_with_type": "Xã Mường Nhé",
        "path": "Mường Nhé, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Mường Nhé, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03160",
        "parent_code": "096"
    },
    "03161": {
        "name": "Nậm Vì",
        "type": "xa",
        "slug": "nam-vi",
        "name_with_type": "Xã Nậm Vì",
        "path": "Nậm Vì, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Nậm Vì, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03161",
        "parent_code": "096"
    },
    "03162": {
        "name": "Nậm Kè",
        "type": "xa",
        "slug": "nam-ke",
        "name_with_type": "Xã Nậm Kè",
        "path": "Nậm Kè, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Nậm Kè, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03162",
        "parent_code": "096"
    },
    "03163": {
        "name": "Mường Toong",
        "type": "xa",
        "slug": "muong-toong",
        "name_with_type": "Xã Mường Toong",
        "path": "Mường Toong, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Mường Toong, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03163",
        "parent_code": "096"
    },
    "03164": {
        "name": "Quảng Lâm",
        "type": "xa",
        "slug": "quang-lam",
        "name_with_type": "Xã Quảng Lâm",
        "path": "Quảng Lâm, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Quảng Lâm, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03164",
        "parent_code": "096"
    },
    "03177": {
        "name": "Huổi Lếnh",
        "type": "xa",
        "slug": "huoi-lenh",
        "name_with_type": "Xã Huổi Lếnh",
        "path": "Huổi Lếnh, Mường Nhé, Điện Biên",
        "path_with_type": "Xã Huổi Lếnh, Huyện Mường Nhé, Tỉnh Điện Biên",
        "code": "03177",
        "parent_code": "096"
    },
    "03172": {
        "name": "Mường Chà",
        "type": "thi-tran",
        "slug": "muong-cha",
        "name_with_type": "Thị Trấn Mường Chà",
        "path": "Mường Chà, Mường Chà, Điện Biên",
        "path_with_type": "Thị Trấn Mường Chà, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03172",
        "parent_code": "097"
    },
    "03178": {
        "name": "Xá Tổng",
        "type": "xa",
        "slug": "xa-tong",
        "name_with_type": "Xã Xá Tổng",
        "path": "Xá Tổng, Mường Chà, Điện Biên",
        "path_with_type": "Xã Xá Tổng, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03178",
        "parent_code": "097"
    },
    "03181": {
        "name": "Mường Tùng",
        "type": "xa",
        "slug": "muong-tung",
        "name_with_type": "Xã Mường Tùng",
        "path": "Mường Tùng, Mường Chà, Điện Biên",
        "path_with_type": "Xã Mường Tùng, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03181",
        "parent_code": "097"
    },
    "03190": {
        "name": "Hừa Ngài",
        "type": "xa",
        "slug": "hua-ngai",
        "name_with_type": "Xã Hừa Ngài",
        "path": "Hừa Ngài, Mường Chà, Điện Biên",
        "path_with_type": "Xã Hừa Ngài, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03190",
        "parent_code": "097"
    },
    "03191": {
        "name": "Huổi Mí",
        "type": "xa",
        "slug": "huoi-mi",
        "name_with_type": "Xã Huổi Mí",
        "path": "Huổi Mí, Mường Chà, Điện Biên",
        "path_with_type": "Xã Huổi Mí, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03191",
        "parent_code": "097"
    },
    "03193": {
        "name": "Pa Ham",
        "type": "xa",
        "slug": "pa-ham",
        "name_with_type": "Xã Pa Ham",
        "path": "Pa Ham, Mường Chà, Điện Biên",
        "path_with_type": "Xã Pa Ham, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03193",
        "parent_code": "097"
    },
    "03194": {
        "name": "Nậm Nèn",
        "type": "xa",
        "slug": "nam-nen",
        "name_with_type": "Xã Nậm Nèn",
        "path": "Nậm Nèn, Mường Chà, Điện Biên",
        "path_with_type": "Xã Nậm Nèn, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03194",
        "parent_code": "097"
    },
    "03196": {
        "name": "Huổi Lèng",
        "type": "xa",
        "slug": "huoi-leng",
        "name_with_type": "Xã Huổi Lèng",
        "path": "Huổi Lèng, Mường Chà, Điện Biên",
        "path_with_type": "Xã Huổi Lèng, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03196",
        "parent_code": "097"
    },
    "03197": {
        "name": "Sa Lông",
        "type": "xa",
        "slug": "sa-long",
        "name_with_type": "Xã Sa Lông",
        "path": "Sa Lông, Mường Chà, Điện Biên",
        "path_with_type": "Xã Sa Lông, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03197",
        "parent_code": "097"
    },
    "03200": {
        "name": "Ma Thì Hồ",
        "type": "xa",
        "slug": "ma-thi-ho",
        "name_with_type": "Xã Ma Thì Hồ",
        "path": "Ma Thì Hồ, Mường Chà, Điện Biên",
        "path_with_type": "Xã Ma Thì Hồ, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03200",
        "parent_code": "097"
    },
    "03201": {
        "name": "Na Sang",
        "type": "xa",
        "slug": "na-sang",
        "name_with_type": "Xã Na Sang",
        "path": "Na Sang, Mường Chà, Điện Biên",
        "path_with_type": "Xã Na Sang, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03201",
        "parent_code": "097"
    },
    "03202": {
        "name": "Mường Mươn",
        "type": "xa",
        "slug": "muong-muon",
        "name_with_type": "Xã Mường Mươn",
        "path": "Mường Mươn, Mường Chà, Điện Biên",
        "path_with_type": "Xã Mường Mươn, Huyện Mường Chà, Tỉnh Điện Biên",
        "code": "03202",
        "parent_code": "097"
    },
    "03217": {
        "name": "Tủa Chùa",
        "type": "thi-tran",
        "slug": "tua-chua",
        "name_with_type": "Thị trấn Tủa Chùa",
        "path": "Tủa Chùa, Tủa Chùa, Điện Biên",
        "path_with_type": "Thị trấn Tủa Chùa, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03217",
        "parent_code": "098"
    },
    "03220": {
        "name": "Huổi Só",
        "type": "xa",
        "slug": "huoi-so",
        "name_with_type": "Xã Huổi Só",
        "path": "Huổi Só, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Huổi Só, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03220",
        "parent_code": "098"
    },
    "03223": {
        "name": "Xín Chải",
        "type": "xa",
        "slug": "xin-chai",
        "name_with_type": "Xã Xín Chải",
        "path": "Xín Chải, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Xín Chải, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03223",
        "parent_code": "098"
    },
    "03226": {
        "name": "Tả Sìn Thàng",
        "type": "xa",
        "slug": "ta-sin-thang",
        "name_with_type": "Xã Tả Sìn Thàng",
        "path": "Tả Sìn Thàng, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Tả Sìn Thàng, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03226",
        "parent_code": "098"
    },
    "03229": {
        "name": "Lao Xả Phình",
        "type": "xa",
        "slug": "lao-xa-phinh",
        "name_with_type": "Xã Lao Xả Phình",
        "path": "Lao Xả Phình, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Lao Xả Phình, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03229",
        "parent_code": "098"
    },
    "03232": {
        "name": "Tả Phìn",
        "type": "xa",
        "slug": "ta-phin",
        "name_with_type": "Xã Tả Phìn",
        "path": "Tả Phìn, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Tả Phìn, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03232",
        "parent_code": "098"
    },
    "03235": {
        "name": "Tủa Thàng",
        "type": "xa",
        "slug": "tua-thang",
        "name_with_type": "Xã Tủa Thàng",
        "path": "Tủa Thàng, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Tủa Thàng, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03235",
        "parent_code": "098"
    },
    "03238": {
        "name": "Trung Thu",
        "type": "xa",
        "slug": "trung-thu",
        "name_with_type": "Xã Trung Thu",
        "path": "Trung Thu, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Trung Thu, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03238",
        "parent_code": "098"
    },
    "03241": {
        "name": "Sính Phình",
        "type": "xa",
        "slug": "sinh-phinh",
        "name_with_type": "Xã Sính Phình",
        "path": "Sính Phình, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Sính Phình, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03241",
        "parent_code": "098"
    },
    "03244": {
        "name": "Sáng Nhè",
        "type": "xa",
        "slug": "sang-nhe",
        "name_with_type": "Xã Sáng Nhè",
        "path": "Sáng Nhè, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Sáng Nhè, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03244",
        "parent_code": "098"
    },
    "03247": {
        "name": "Mường Đun",
        "type": "xa",
        "slug": "muong-dun",
        "name_with_type": "Xã Mường Đun",
        "path": "Mường Đun, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Mường Đun, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03247",
        "parent_code": "098"
    },
    "03250": {
        "name": "Mường Báng",
        "type": "xa",
        "slug": "muong-bang",
        "name_with_type": "Xã Mường Báng",
        "path": "Mường Báng, Tủa Chùa, Điện Biên",
        "path_with_type": "Xã Mường Báng, Huyện Tủa Chùa, Tỉnh Điện Biên",
        "code": "03250",
        "parent_code": "098"
    },
    "03253": {
        "name": "Tuần Giáo",
        "type": "thi-tran",
        "slug": "tuan-giao",
        "name_with_type": "Thị trấn Tuần Giáo",
        "path": "Tuần Giáo, Tuần Giáo, Điện Biên",
        "path_with_type": "Thị trấn Tuần Giáo, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03253",
        "parent_code": "099"
    },
    "03259": {
        "name": "Phình Sáng",
        "type": "xa",
        "slug": "phinh-sang",
        "name_with_type": "Xã Phình Sáng",
        "path": "Phình Sáng, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Phình Sáng, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03259",
        "parent_code": "099"
    },
    "03260": {
        "name": "Rạng Đông",
        "type": "xa",
        "slug": "rang-dong",
        "name_with_type": "Xã Rạng Đông",
        "path": "Rạng Đông, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Rạng Đông, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03260",
        "parent_code": "099"
    },
    "03262": {
        "name": "Mùn Chung",
        "type": "xa",
        "slug": "mun-chung",
        "name_with_type": "Xã Mùn Chung",
        "path": "Mùn Chung, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Mùn Chung, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03262",
        "parent_code": "099"
    },
    "03263": {
        "name": "Nà Tòng",
        "type": "xa",
        "slug": "na-tong",
        "name_with_type": "Xã Nà Tòng",
        "path": "Nà Tòng, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Nà Tòng, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03263",
        "parent_code": "099"
    },
    "03265": {
        "name": "Ta Ma",
        "type": "xa",
        "slug": "ta-ma",
        "name_with_type": "Xã Ta Ma",
        "path": "Ta Ma, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Ta Ma, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03265",
        "parent_code": "099"
    },
    "03268": {
        "name": "Mường Mùn",
        "type": "xa",
        "slug": "muong-mun",
        "name_with_type": "Xã Mường Mùn",
        "path": "Mường Mùn, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Mường Mùn, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03268",
        "parent_code": "099"
    },
    "03269": {
        "name": "Pú Xi",
        "type": "xa",
        "slug": "pu-xi",
        "name_with_type": "Xã Pú Xi",
        "path": "Pú Xi, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Pú Xi, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03269",
        "parent_code": "099"
    },
    "03271": {
        "name": "Pú Nhung",
        "type": "xa",
        "slug": "pu-nhung",
        "name_with_type": "Xã Pú Nhung",
        "path": "Pú Nhung, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Pú Nhung, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03271",
        "parent_code": "099"
    },
    "03274": {
        "name": "Quài Nưa",
        "type": "xa",
        "slug": "quai-nua",
        "name_with_type": "Xã Quài Nưa",
        "path": "Quài Nưa, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Quài Nưa, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03274",
        "parent_code": "099"
    },
    "03277": {
        "name": "Mường Thín",
        "type": "xa",
        "slug": "muong-thin",
        "name_with_type": "Xã Mường Thín",
        "path": "Mường Thín, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Mường Thín, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03277",
        "parent_code": "099"
    },
    "03280": {
        "name": "Tỏa Tình",
        "type": "xa",
        "slug": "toa-tinh",
        "name_with_type": "Xã Tỏa Tình",
        "path": "Tỏa Tình, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Tỏa Tình, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03280",
        "parent_code": "099"
    },
    "03283": {
        "name": "Nà Sáy",
        "type": "xa",
        "slug": "na-say",
        "name_with_type": "Xã Nà Sáy",
        "path": "Nà Sáy, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Nà Sáy, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03283",
        "parent_code": "099"
    },
    "03284": {
        "name": "Mường Khong",
        "type": "xa",
        "slug": "muong-khong",
        "name_with_type": "Xã Mường Khong",
        "path": "Mường Khong, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Mường Khong, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03284",
        "parent_code": "099"
    },
    "03289": {
        "name": "Quài Cang",
        "type": "xa",
        "slug": "quai-cang",
        "name_with_type": "Xã Quài Cang",
        "path": "Quài Cang, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Quài Cang, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03289",
        "parent_code": "099"
    },
    "03295": {
        "name": "Quài Tở",
        "type": "xa",
        "slug": "quai-to",
        "name_with_type": "Xã Quài Tở",
        "path": "Quài Tở, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Quài Tở, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03295",
        "parent_code": "099"
    },
    "03298": {
        "name": "Chiềng Sinh",
        "type": "xa",
        "slug": "chieng-sinh",
        "name_with_type": "Xã Chiềng Sinh",
        "path": "Chiềng Sinh, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Chiềng Sinh, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03298",
        "parent_code": "099"
    },
    "03299": {
        "name": "Chiềng Đông",
        "type": "xa",
        "slug": "chieng-dong",
        "name_with_type": "Xã Chiềng Đông",
        "path": "Chiềng Đông, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Chiềng Đông, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03299",
        "parent_code": "099"
    },
    "03304": {
        "name": "Tênh Phông",
        "type": "xa",
        "slug": "tenh-phong",
        "name_with_type": "Xã Tênh Phông",
        "path": "Tênh Phông, Tuần Giáo, Điện Biên",
        "path_with_type": "Xã Tênh Phông, Huyện Tuần Giáo, Tỉnh Điện Biên",
        "code": "03304",
        "parent_code": "099"
    },
    "03316": {
        "name": "Nà Tấu",
        "type": "xa",
        "slug": "na-tau",
        "name_with_type": "Xã Nà Tấu",
        "path": "Nà Tấu, Điện Biên, Điện Biên",
        "path_with_type": "Xã Nà Tấu, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03316",
        "parent_code": "100"
    },
    "03317": {
        "name": "Nà Nhạn",
        "type": "xa",
        "slug": "na-nhan",
        "name_with_type": "Xã Nà Nhạn",
        "path": "Nà Nhạn, Điện Biên, Điện Biên",
        "path_with_type": "Xã Nà Nhạn, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03317",
        "parent_code": "100"
    },
    "03319": {
        "name": "Mường Pồn",
        "type": "xa",
        "slug": "muong-pon",
        "name_with_type": "Xã Mường Pồn",
        "path": "Mường Pồn, Điện Biên, Điện Biên",
        "path_with_type": "Xã Mường Pồn, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03319",
        "parent_code": "100"
    },
    "03322": {
        "name": "Thanh Nưa",
        "type": "xa",
        "slug": "thanh-nua",
        "name_with_type": "Xã Thanh Nưa",
        "path": "Thanh Nưa, Điện Biên, Điện Biên",
        "path_with_type": "Xã Thanh Nưa, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03322",
        "parent_code": "100"
    },
    "03323": {
        "name": "Hua Thanh",
        "type": "xa",
        "slug": "hua-thanh",
        "name_with_type": "Xã Hua Thanh",
        "path": "Hua Thanh, Điện Biên, Điện Biên",
        "path_with_type": "Xã Hua Thanh, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03323",
        "parent_code": "100"
    },
    "03325": {
        "name": "Mường Phăng",
        "type": "xa",
        "slug": "muong-phang",
        "name_with_type": "Xã Mường Phăng",
        "path": "Mường Phăng, Điện Biên, Điện Biên",
        "path_with_type": "Xã Mường Phăng, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03325",
        "parent_code": "100"
    },
    "03326": {
        "name": "Pá Khoang",
        "type": "xa",
        "slug": "pa-khoang",
        "name_with_type": "Xã Pá Khoang",
        "path": "Pá Khoang, Điện Biên, Điện Biên",
        "path_with_type": "Xã Pá Khoang, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03326",
        "parent_code": "100"
    },
    "03328": {
        "name": "Thanh Luông",
        "type": "xa",
        "slug": "thanh-luong",
        "name_with_type": "Xã Thanh Luông",
        "path": "Thanh Luông, Điện Biên, Điện Biên",
        "path_with_type": "Xã Thanh Luông, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03328",
        "parent_code": "100"
    },
    "03331": {
        "name": "Thanh Hưng",
        "type": "xa",
        "slug": "thanh-hung",
        "name_with_type": "Xã Thanh Hưng",
        "path": "Thanh Hưng, Điện Biên, Điện Biên",
        "path_with_type": "Xã Thanh Hưng, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03331",
        "parent_code": "100"
    },
    "03334": {
        "name": "Thanh Xương",
        "type": "xa",
        "slug": "thanh-xuong",
        "name_with_type": "Xã Thanh Xương",
        "path": "Thanh Xương, Điện Biên, Điện Biên",
        "path_with_type": "Xã Thanh Xương, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03334",
        "parent_code": "100"
    },
    "03337": {
        "name": "Thanh Chăn",
        "type": "xa",
        "slug": "thanh-chan",
        "name_with_type": "Xã Thanh Chăn",
        "path": "Thanh Chăn, Điện Biên, Điện Biên",
        "path_with_type": "Xã Thanh Chăn, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03337",
        "parent_code": "100"
    },
    "03340": {
        "name": "Pa Thơm",
        "type": "xa",
        "slug": "pa-thom",
        "name_with_type": "Xã Pa Thơm",
        "path": "Pa Thơm, Điện Biên, Điện Biên",
        "path_with_type": "Xã Pa Thơm, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03340",
        "parent_code": "100"
    },
    "03343": {
        "name": "Thanh An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thanh An",
        "path": "Thanh An, Điện Biên, Điện Biên",
        "path_with_type": "Xã Thanh An, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03343",
        "parent_code": "100"
    },
    "03346": {
        "name": "Thanh Yên",
        "type": "xa",
        "slug": "thanh-yen",
        "name_with_type": "Xã Thanh Yên",
        "path": "Thanh Yên, Điện Biên, Điện Biên",
        "path_with_type": "Xã Thanh Yên, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03346",
        "parent_code": "100"
    },
    "03349": {
        "name": "Noong Luống",
        "type": "xa",
        "slug": "noong-luong",
        "name_with_type": "Xã Noong Luống",
        "path": "Noong Luống, Điện Biên, Điện Biên",
        "path_with_type": "Xã Noong Luống, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03349",
        "parent_code": "100"
    },
    "03352": {
        "name": "Noọng Hẹt",
        "type": "xa",
        "slug": "noong-het",
        "name_with_type": "Xã Noọng Hẹt",
        "path": "Noọng Hẹt, Điện Biên, Điện Biên",
        "path_with_type": "Xã Noọng Hẹt, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03352",
        "parent_code": "100"
    },
    "03355": {
        "name": "Sam Mứn",
        "type": "xa",
        "slug": "sam-mun",
        "name_with_type": "Xã Sam Mứn",
        "path": "Sam Mứn, Điện Biên, Điện Biên",
        "path_with_type": "Xã Sam Mứn, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03355",
        "parent_code": "100"
    },
    "03356": {
        "name": "Pom Lót",
        "type": "xa",
        "slug": "pom-lot",
        "name_with_type": "Xã Pom Lót",
        "path": "Pom Lót, Điện Biên, Điện Biên",
        "path_with_type": "Xã Pom Lót, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03356",
        "parent_code": "100"
    },
    "03358": {
        "name": "Núa Ngam",
        "type": "xa",
        "slug": "nua-ngam",
        "name_with_type": "Xã Núa Ngam",
        "path": "Núa Ngam, Điện Biên, Điện Biên",
        "path_with_type": "Xã Núa Ngam, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03358",
        "parent_code": "100"
    },
    "03359": {
        "name": "Hẹ Muông",
        "type": "xa",
        "slug": "he-muong",
        "name_with_type": "Xã Hẹ Muông",
        "path": "Hẹ Muông, Điện Biên, Điện Biên",
        "path_with_type": "Xã Hẹ Muông, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03359",
        "parent_code": "100"
    },
    "03361": {
        "name": "Na Ư",
        "type": "xa",
        "slug": "na-u",
        "name_with_type": "Xã Na Ư",
        "path": "Na Ư, Điện Biên, Điện Biên",
        "path_with_type": "Xã Na Ư, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03361",
        "parent_code": "100"
    },
    "03364": {
        "name": "Mường Nhà",
        "type": "xa",
        "slug": "muong-nha",
        "name_with_type": "Xã Mường Nhà",
        "path": "Mường Nhà, Điện Biên, Điện Biên",
        "path_with_type": "Xã Mường Nhà, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03364",
        "parent_code": "100"
    },
    "03365": {
        "name": "Na Tông",
        "type": "xa",
        "slug": "na-tong",
        "name_with_type": "Xã Na Tông",
        "path": "Na Tông, Điện Biên, Điện Biên",
        "path_with_type": "Xã Na Tông, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03365",
        "parent_code": "100"
    },
    "03367": {
        "name": "Mường Lói",
        "type": "xa",
        "slug": "muong-loi",
        "name_with_type": "Xã Mường Lói",
        "path": "Mường Lói, Điện Biên, Điện Biên",
        "path_with_type": "Xã Mường Lói, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03367",
        "parent_code": "100"
    },
    "03368": {
        "name": "Phu Luông",
        "type": "xa",
        "slug": "phu-luong",
        "name_with_type": "Xã Phu Luông",
        "path": "Phu Luông, Điện Biên, Điện Biên",
        "path_with_type": "Xã Phu Luông, Huyện Điện Biên, Tỉnh Điện Biên",
        "code": "03368",
        "parent_code": "100"
    },
    "03203": {
        "name": "Điện Biên Đông",
        "type": "thi-tran",
        "slug": "dien-bien-dong",
        "name_with_type": "Thị trấn Điện Biên Đông",
        "path": "Điện Biên Đông, Điện Biên Đông, Điện Biên",
        "path_with_type": "Thị trấn Điện Biên Đông, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03203",
        "parent_code": "101"
    },
    "03205": {
        "name": "Na Son",
        "type": "xa",
        "slug": "na-son",
        "name_with_type": "Xã Na Son",
        "path": "Na Son, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Na Son, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03205",
        "parent_code": "101"
    },
    "03208": {
        "name": "Phì Nhừ",
        "type": "xa",
        "slug": "phi-nhu",
        "name_with_type": "Xã Phì Nhừ",
        "path": "Phì Nhừ, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Phì Nhừ, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03208",
        "parent_code": "101"
    },
    "03211": {
        "name": "Chiềng Sơ",
        "type": "xa",
        "slug": "chieng-so",
        "name_with_type": "Xã Chiềng Sơ",
        "path": "Chiềng Sơ, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Chiềng Sơ, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03211",
        "parent_code": "101"
    },
    "03214": {
        "name": "Mường Luân",
        "type": "xa",
        "slug": "muong-luan",
        "name_with_type": "Xã Mường Luân",
        "path": "Mường Luân, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Mường Luân, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03214",
        "parent_code": "101"
    },
    "03370": {
        "name": "Pú Nhi",
        "type": "xa",
        "slug": "pu-nhi",
        "name_with_type": "Xã Pú Nhi",
        "path": "Pú Nhi, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Pú Nhi, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03370",
        "parent_code": "101"
    },
    "03371": {
        "name": "Nong U",
        "type": "xa",
        "slug": "nong-u",
        "name_with_type": "Xã Nong U",
        "path": "Nong U, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Nong U, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03371",
        "parent_code": "101"
    },
    "03373": {
        "name": "Xa Dung",
        "type": "xa",
        "slug": "xa-dung",
        "name_with_type": "Xã Xa Dung",
        "path": "Xa Dung, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Xa Dung, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03373",
        "parent_code": "101"
    },
    "03376": {
        "name": "Keo Lôm",
        "type": "xa",
        "slug": "keo-lom",
        "name_with_type": "Xã Keo Lôm",
        "path": "Keo Lôm, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Keo Lôm, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03376",
        "parent_code": "101"
    },
    "03379": {
        "name": "Luân Giới",
        "type": "xa",
        "slug": "luan-gioi",
        "name_with_type": "Xã Luân Giới",
        "path": "Luân Giới, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Luân Giới, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03379",
        "parent_code": "101"
    },
    "03382": {
        "name": "Phình Giàng",
        "type": "xa",
        "slug": "phinh-giang",
        "name_with_type": "Xã Phình Giàng",
        "path": "Phình Giàng, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Phình Giàng, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03382",
        "parent_code": "101"
    },
    "03383": {
        "name": "Pú Hồng",
        "type": "xa",
        "slug": "pu-hong",
        "name_with_type": "Xã Pú Hồng",
        "path": "Pú Hồng, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Pú Hồng, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03383",
        "parent_code": "101"
    },
    "03384": {
        "name": "Tìa Dình",
        "type": "xa",
        "slug": "tia-dinh",
        "name_with_type": "Xã Tìa Dình",
        "path": "Tìa Dình, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Tìa Dình, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03384",
        "parent_code": "101"
    },
    "03385": {
        "name": "Háng Lìa",
        "type": "xa",
        "slug": "hang-lia",
        "name_with_type": "Xã Háng Lìa",
        "path": "Háng Lìa, Điện Biên Đông, Điện Biên",
        "path_with_type": "Xã Háng Lìa, Huyện Điện Biên Đông, Tỉnh Điện Biên",
        "code": "03385",
        "parent_code": "101"
    },
    "03256": {
        "name": "Mường Ảng",
        "type": "thi-tran",
        "slug": "muong-ang",
        "name_with_type": "Thị trấn Mường Ảng",
        "path": "Mường Ảng, Mường Ảng, Điện Biên",
        "path_with_type": "Thị trấn Mường Ảng, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03256",
        "parent_code": "102"
    },
    "03286": {
        "name": "Mường Đăng",
        "type": "xa",
        "slug": "muong-dang",
        "name_with_type": "Xã Mường Đăng",
        "path": "Mường Đăng, Mường Ảng, Điện Biên",
        "path_with_type": "Xã Mường Đăng, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03286",
        "parent_code": "102"
    },
    "03287": {
        "name": "Ngối Cáy",
        "type": "xa",
        "slug": "ngoi-cay",
        "name_with_type": "Xã Ngối Cáy",
        "path": "Ngối Cáy, Mường Ảng, Điện Biên",
        "path_with_type": "Xã Ngối Cáy, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03287",
        "parent_code": "102"
    },
    "03292": {
        "name": "Ẳng Tở",
        "type": "xa",
        "slug": "ang-to",
        "name_with_type": "Xã Ẳng Tở",
        "path": "Ẳng Tở, Mường Ảng, Điện Biên",
        "path_with_type": "Xã Ẳng Tở, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03292",
        "parent_code": "102"
    },
    "03301": {
        "name": "Búng Lao",
        "type": "xa",
        "slug": "bung-lao",
        "name_with_type": "Xã Búng Lao",
        "path": "Búng Lao, Mường Ảng, Điện Biên",
        "path_with_type": "Xã Búng Lao, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03301",
        "parent_code": "102"
    },
    "03302": {
        "name": "Xuân Lao",
        "type": "xa",
        "slug": "xuan-lao",
        "name_with_type": "Xã Xuân Lao",
        "path": "Xuân Lao, Mường Ảng, Điện Biên",
        "path_with_type": "Xã Xuân Lao, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03302",
        "parent_code": "102"
    },
    "03307": {
        "name": "Ẳng Nưa",
        "type": "xa",
        "slug": "ang-nua",
        "name_with_type": "Xã Ẳng Nưa",
        "path": "Ẳng Nưa, Mường Ảng, Điện Biên",
        "path_with_type": "Xã Ẳng Nưa, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03307",
        "parent_code": "102"
    },
    "03310": {
        "name": "Ẳng Cang",
        "type": "xa",
        "slug": "ang-cang",
        "name_with_type": "Xã Ẳng Cang",
        "path": "Ẳng Cang, Mường Ảng, Điện Biên",
        "path_with_type": "Xã Ẳng Cang, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03310",
        "parent_code": "102"
    },
    "03312": {
        "name": "Nặm Lịch",
        "type": "xa",
        "slug": "nam-lich",
        "name_with_type": "Xã Nặm Lịch",
        "path": "Nặm Lịch, Mường Ảng, Điện Biên",
        "path_with_type": "Xã Nặm Lịch, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03312",
        "parent_code": "102"
    },
    "03313": {
        "name": "Mường Lạn",
        "type": "xa",
        "slug": "muong-lan",
        "name_with_type": "Xã Mường Lạn",
        "path": "Mường Lạn, Mường Ảng, Điện Biên",
        "path_with_type": "Xã Mường Lạn, Huyện Mường Ảng, Tỉnh Điện Biên",
        "code": "03313",
        "parent_code": "102"
    },
    "03156": {
        "name": "Nậm Tin",
        "type": "xa",
        "slug": "nam-tin",
        "name_with_type": "Xã Nậm Tin",
        "path": "Nậm Tin, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Nậm Tin, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03156",
        "parent_code": "103"
    },
    "03165": {
        "name": "Pa Tần",
        "type": "xa",
        "slug": "pa-tan",
        "name_with_type": "Xã Pa Tần",
        "path": "Pa Tần, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Pa Tần, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03165",
        "parent_code": "103"
    },
    "03166": {
        "name": "Chà Cang",
        "type": "xa",
        "slug": "cha-cang",
        "name_with_type": "Xã Chà Cang",
        "path": "Chà Cang, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Chà Cang, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03166",
        "parent_code": "103"
    },
    "03167": {
        "name": "Na Cô Sa",
        "type": "xa",
        "slug": "na-co-sa",
        "name_with_type": "Xã Na Cô Sa",
        "path": "Na Cô Sa, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Na Cô Sa, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03167",
        "parent_code": "103"
    },
    "03168": {
        "name": "Nà Khoa",
        "type": "xa",
        "slug": "na-khoa",
        "name_with_type": "Xã Nà Khoa",
        "path": "Nà Khoa, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Nà Khoa, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03168",
        "parent_code": "103"
    },
    "03169": {
        "name": "Nà Hỳ",
        "type": "xa",
        "slug": "na-hy",
        "name_with_type": "Xã Nà Hỳ",
        "path": "Nà Hỳ, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Nà Hỳ, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03169",
        "parent_code": "103"
    },
    "03170": {
        "name": "Nà Bủng",
        "type": "xa",
        "slug": "na-bung",
        "name_with_type": "Xã Nà Bủng",
        "path": "Nà Bủng, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Nà Bủng, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03170",
        "parent_code": "103"
    },
    "03171": {
        "name": "Nậm Nhừ",
        "type": "xa",
        "slug": "nam-nhu",
        "name_with_type": "Xã Nậm Nhừ",
        "path": "Nậm Nhừ, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Nậm Nhừ, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03171",
        "parent_code": "103"
    },
    "03173": {
        "name": "Nậm Chua",
        "type": "xa",
        "slug": "nam-chua",
        "name_with_type": "Xã Nậm Chua",
        "path": "Nậm Chua, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Nậm Chua, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03173",
        "parent_code": "103"
    },
    "03174": {
        "name": "Nậm Khăn",
        "type": "xa",
        "slug": "nam-khan",
        "name_with_type": "Xã Nậm Khăn",
        "path": "Nậm Khăn, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Nậm Khăn, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03174",
        "parent_code": "103"
    },
    "03175": {
        "name": "Chà Tở",
        "type": "xa",
        "slug": "cha-to",
        "name_with_type": "Xã Chà Tở",
        "path": "Chà Tở, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Chà Tở, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03175",
        "parent_code": "103"
    },
    "03176": {
        "name": "Vàng Đán",
        "type": "xa",
        "slug": "vang-dan",
        "name_with_type": "Xã Vàng Đán",
        "path": "Vàng Đán, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Vàng Đán, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03176",
        "parent_code": "103"
    },
    "03187": {
        "name": "Chà Nưa",
        "type": "xa",
        "slug": "cha-nua",
        "name_with_type": "Xã Chà Nưa",
        "path": "Chà Nưa, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Chà Nưa, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03187",
        "parent_code": "103"
    },
    "03198": {
        "name": "Phìn Hồ",
        "type": "xa",
        "slug": "phin-ho",
        "name_with_type": "Xã Phìn Hồ",
        "path": "Phìn Hồ, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Phìn Hồ, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03198",
        "parent_code": "103"
    },
    "03199": {
        "name": "Si Pa Phìn",
        "type": "xa",
        "slug": "si-pa-phin",
        "name_with_type": "Xã Si Pa Phìn",
        "path": "Si Pa Phìn, Nậm Pồ, Điện Biên",
        "path_with_type": "Xã Si Pa Phìn, Huyện Nậm Pồ, Tỉnh Điện Biên",
        "code": "03199",
        "parent_code": "103"
    },
    "08707": {
        "name": "Tích Sơn",
        "type": "phuong",
        "slug": "tich-son",
        "name_with_type": "Phường Tích Sơn",
        "path": "Tích Sơn, Vĩnh Yên, Vĩnh Phúc",
        "path_with_type": "Phường Tích Sơn, Thành phố Vĩnh Yên, Tỉnh Vĩnh Phúc",
        "code": "08707",
        "parent_code": "243"
    },
    "08710": {
        "name": "Liên Bảo",
        "type": "phuong",
        "slug": "lien-bao",
        "name_with_type": "Phường Liên Bảo",
        "path": "Liên Bảo, Vĩnh Yên, Vĩnh Phúc",
        "path_with_type": "Phường Liên Bảo, Thành phố Vĩnh Yên, Tỉnh Vĩnh Phúc",
        "code": "08710",
        "parent_code": "243"
    },
    "08713": {
        "name": "Hội Hợp",
        "type": "phuong",
        "slug": "hoi-hop",
        "name_with_type": "Phường Hội Hợp",
        "path": "Hội Hợp, Vĩnh Yên, Vĩnh Phúc",
        "path_with_type": "Phường Hội Hợp, Thành phố Vĩnh Yên, Tỉnh Vĩnh Phúc",
        "code": "08713",
        "parent_code": "243"
    },
    "08716": {
        "name": "Đống Đa",
        "type": "phuong",
        "slug": "dong-da",
        "name_with_type": "Phường Đống Đa",
        "path": "Đống Đa, Vĩnh Yên, Vĩnh Phúc",
        "path_with_type": "Phường Đống Đa, Thành phố Vĩnh Yên, Tỉnh Vĩnh Phúc",
        "code": "08716",
        "parent_code": "243"
    },
    "08719": {
        "name": "Ngô Quyền",
        "type": "phuong",
        "slug": "ngo-quyen",
        "name_with_type": "Phường Ngô Quyền",
        "path": "Ngô Quyền, Vĩnh Yên, Vĩnh Phúc",
        "path_with_type": "Phường Ngô Quyền, Thành phố Vĩnh Yên, Tỉnh Vĩnh Phúc",
        "code": "08719",
        "parent_code": "243"
    },
    "08722": {
        "name": "Đồng Tâm",
        "type": "phuong",
        "slug": "dong-tam",
        "name_with_type": "Phường Đồng Tâm",
        "path": "Đồng Tâm, Vĩnh Yên, Vĩnh Phúc",
        "path_with_type": "Phường Đồng Tâm, Thành phố Vĩnh Yên, Tỉnh Vĩnh Phúc",
        "code": "08722",
        "parent_code": "243"
    },
    "08725": {
        "name": "Định Trung",
        "type": "xa",
        "slug": "dinh-trung",
        "name_with_type": "Xã Định Trung",
        "path": "Định Trung, Vĩnh Yên, Vĩnh Phúc",
        "path_with_type": "Xã Định Trung, Thành phố Vĩnh Yên, Tỉnh Vĩnh Phúc",
        "code": "08725",
        "parent_code": "243"
    },
    "08728": {
        "name": "Khai Quang",
        "type": "phuong",
        "slug": "khai-quang",
        "name_with_type": "Phường Khai Quang",
        "path": "Khai Quang, Vĩnh Yên, Vĩnh Phúc",
        "path_with_type": "Phường Khai Quang, Thành phố Vĩnh Yên, Tỉnh Vĩnh Phúc",
        "code": "08728",
        "parent_code": "243"
    },
    "08731": {
        "name": "Thanh Trù",
        "type": "xa",
        "slug": "thanh-tru",
        "name_with_type": "Xã Thanh Trù",
        "path": "Thanh Trù, Vĩnh Yên, Vĩnh Phúc",
        "path_with_type": "Xã Thanh Trù, Thành phố Vĩnh Yên, Tỉnh Vĩnh Phúc",
        "code": "08731",
        "parent_code": "243"
    },
    "08734": {
        "name": "Trưng Trắc",
        "type": "phuong",
        "slug": "trung-trac",
        "name_with_type": "Phường Trưng Trắc",
        "path": "Trưng Trắc, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Phường Trưng Trắc, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08734",
        "parent_code": "244"
    },
    "08737": {
        "name": "Hùng Vương",
        "type": "phuong",
        "slug": "hung-vuong",
        "name_with_type": "Phường Hùng Vương",
        "path": "Hùng Vương, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Phường Hùng Vương, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08737",
        "parent_code": "244"
    },
    "08740": {
        "name": "Trưng Nhị",
        "type": "phuong",
        "slug": "trung-nhi",
        "name_with_type": "Phường Trưng Nhị",
        "path": "Trưng Nhị, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Phường Trưng Nhị, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08740",
        "parent_code": "244"
    },
    "08743": {
        "name": "Phúc Thắng",
        "type": "phuong",
        "slug": "phuc-thang",
        "name_with_type": "Phường Phúc Thắng",
        "path": "Phúc Thắng, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Phường Phúc Thắng, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08743",
        "parent_code": "244"
    },
    "08746": {
        "name": "Xuân Hoà",
        "type": "phuong",
        "slug": "xuan-hoa",
        "name_with_type": "Phường Xuân Hoà",
        "path": "Xuân Hoà, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Phường Xuân Hoà, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08746",
        "parent_code": "244"
    },
    "08747": {
        "name": "Đồng Xuân",
        "type": "phuong",
        "slug": "dong-xuan",
        "name_with_type": "Phường Đồng Xuân",
        "path": "Đồng Xuân, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Phường Đồng Xuân, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08747",
        "parent_code": "244"
    },
    "08749": {
        "name": "Ngọc Thanh",
        "type": "xa",
        "slug": "ngoc-thanh",
        "name_with_type": "Xã Ngọc Thanh",
        "path": "Ngọc Thanh, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Xã Ngọc Thanh, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08749",
        "parent_code": "244"
    },
    "08752": {
        "name": "Cao Minh",
        "type": "xa",
        "slug": "cao-minh",
        "name_with_type": "Xã Cao Minh",
        "path": "Cao Minh, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Xã Cao Minh, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08752",
        "parent_code": "244"
    },
    "08755": {
        "name": "Nam Viêm",
        "type": "xa",
        "slug": "nam-viem",
        "name_with_type": "Xã Nam Viêm",
        "path": "Nam Viêm, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Xã Nam Viêm, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08755",
        "parent_code": "244"
    },
    "08758": {
        "name": "Tiền Châu",
        "type": "xa",
        "slug": "tien-chau",
        "name_with_type": "Xã Tiền Châu",
        "path": "Tiền Châu, Phúc Yên, Vĩnh Phúc",
        "path_with_type": "Xã Tiền Châu, Thị xã Phúc Yên, Tỉnh Vĩnh Phúc",
        "code": "08758",
        "parent_code": "244"
    },
    "08761": {
        "name": "Lập Thạch",
        "type": "thi-tran",
        "slug": "lap-thach",
        "name_with_type": "Thị trấn Lập Thạch",
        "path": "Lập Thạch, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Thị trấn Lập Thạch, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08761",
        "parent_code": "246"
    },
    "08764": {
        "name": "Quang Sơn",
        "type": "xa",
        "slug": "quang-son",
        "name_with_type": "Xã Quang Sơn",
        "path": "Quang Sơn, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Quang Sơn, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08764",
        "parent_code": "246"
    },
    "08767": {
        "name": "Ngọc Mỹ",
        "type": "xa",
        "slug": "ngoc-my",
        "name_with_type": "Xã Ngọc Mỹ",
        "path": "Ngọc Mỹ, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Ngọc Mỹ, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08767",
        "parent_code": "246"
    },
    "08770": {
        "name": "Hợp Lý",
        "type": "xa",
        "slug": "hop-ly",
        "name_with_type": "Xã Hợp Lý",
        "path": "Hợp Lý, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Hợp Lý, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08770",
        "parent_code": "246"
    },
    "08785": {
        "name": "Bắc Bình",
        "type": "xa",
        "slug": "bac-binh",
        "name_with_type": "Xã Bắc Bình",
        "path": "Bắc Bình, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Bắc Bình, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08785",
        "parent_code": "246"
    },
    "08788": {
        "name": "Thái Hòa",
        "type": "xa",
        "slug": "thai-hoa",
        "name_with_type": "Xã Thái Hòa",
        "path": "Thái Hòa, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Thái Hòa, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08788",
        "parent_code": "246"
    },
    "08789": {
        "name": "Hoa Sơn",
        "type": "thi-tran",
        "slug": "hoa-son",
        "name_with_type": "Thị trấn Hoa Sơn",
        "path": "Hoa Sơn, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Thị trấn Hoa Sơn, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08789",
        "parent_code": "246"
    },
    "08791": {
        "name": "Liễn Sơn",
        "type": "xa",
        "slug": "lien-son",
        "name_with_type": "Xã Liễn Sơn",
        "path": "Liễn Sơn, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Liễn Sơn, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08791",
        "parent_code": "246"
    },
    "08794": {
        "name": "Xuân Hòa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hòa",
        "path": "Xuân Hòa, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Xuân Hòa, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08794",
        "parent_code": "246"
    },
    "08797": {
        "name": "Vân Trục",
        "type": "xa",
        "slug": "van-truc",
        "name_with_type": "Xã Vân Trục",
        "path": "Vân Trục, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Vân Trục, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08797",
        "parent_code": "246"
    },
    "08812": {
        "name": "Liên Hòa",
        "type": "xa",
        "slug": "lien-hoa",
        "name_with_type": "Xã Liên Hòa",
        "path": "Liên Hòa, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Liên Hòa, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08812",
        "parent_code": "246"
    },
    "08815": {
        "name": "Tử Du",
        "type": "xa",
        "slug": "tu-du",
        "name_with_type": "Xã Tử Du",
        "path": "Tử Du, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Tử Du, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08815",
        "parent_code": "246"
    },
    "08833": {
        "name": "Bàn Giản",
        "type": "xa",
        "slug": "ban-gian",
        "name_with_type": "Xã Bàn Giản",
        "path": "Bàn Giản, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Bàn Giản, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08833",
        "parent_code": "246"
    },
    "08836": {
        "name": "Xuân Lôi",
        "type": "xa",
        "slug": "xuan-loi",
        "name_with_type": "Xã Xuân Lôi",
        "path": "Xuân Lôi, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Xuân Lôi, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08836",
        "parent_code": "246"
    },
    "08839": {
        "name": "Đồng Ích",
        "type": "xa",
        "slug": "dong-ich",
        "name_with_type": "Xã Đồng Ích",
        "path": "Đồng Ích, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Đồng Ích, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08839",
        "parent_code": "246"
    },
    "08842": {
        "name": "Tiên Lữ",
        "type": "xa",
        "slug": "tien-lu",
        "name_with_type": "Xã Tiên Lữ",
        "path": "Tiên Lữ, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Tiên Lữ, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08842",
        "parent_code": "246"
    },
    "08845": {
        "name": "Văn Quán",
        "type": "xa",
        "slug": "van-quan",
        "name_with_type": "Xã Văn Quán",
        "path": "Văn Quán, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Văn Quán, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08845",
        "parent_code": "246"
    },
    "08857": {
        "name": "Đình Chu",
        "type": "xa",
        "slug": "dinh-chu",
        "name_with_type": "Xã Đình Chu",
        "path": "Đình Chu, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Đình Chu, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08857",
        "parent_code": "246"
    },
    "08863": {
        "name": "Triệu Đề",
        "type": "xa",
        "slug": "trieu-de",
        "name_with_type": "Xã Triệu Đề",
        "path": "Triệu Đề, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Triệu Đề, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08863",
        "parent_code": "246"
    },
    "08866": {
        "name": "Sơn Đông",
        "type": "xa",
        "slug": "son-dong",
        "name_with_type": "Xã Sơn Đông",
        "path": "Sơn Đông, Lập Thạch, Vĩnh Phúc",
        "path_with_type": "Xã Sơn Đông, Huyện Lập Thạch, Tỉnh Vĩnh Phúc",
        "code": "08866",
        "parent_code": "246"
    },
    "08869": {
        "name": "Hợp Hòa",
        "type": "thi-tran",
        "slug": "hop-hoa",
        "name_with_type": "Thị trấn Hợp Hòa",
        "path": "Hợp Hòa, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Thị trấn Hợp Hòa, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08869",
        "parent_code": "247"
    },
    "08872": {
        "name": "Hoàng Hoa",
        "type": "xa",
        "slug": "hoang-hoa",
        "name_with_type": "Xã Hoàng Hoa",
        "path": "Hoàng Hoa, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Hoàng Hoa, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08872",
        "parent_code": "247"
    },
    "08875": {
        "name": "Đồng Tĩnh",
        "type": "xa",
        "slug": "dong-tinh",
        "name_with_type": "Xã Đồng Tĩnh",
        "path": "Đồng Tĩnh, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Đồng Tĩnh, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08875",
        "parent_code": "247"
    },
    "08878": {
        "name": "Kim Long",
        "type": "xa",
        "slug": "kim-long",
        "name_with_type": "Xã Kim Long",
        "path": "Kim Long, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Kim Long, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08878",
        "parent_code": "247"
    },
    "08881": {
        "name": "Hướng Đạo",
        "type": "xa",
        "slug": "huong-dao",
        "name_with_type": "Xã Hướng Đạo",
        "path": "Hướng Đạo, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Hướng Đạo, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08881",
        "parent_code": "247"
    },
    "08884": {
        "name": "Đạo Tú",
        "type": "xa",
        "slug": "dao-tu",
        "name_with_type": "Xã Đạo Tú",
        "path": "Đạo Tú, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Đạo Tú, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08884",
        "parent_code": "247"
    },
    "08887": {
        "name": "An Hòa",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hòa",
        "path": "An Hòa, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã An Hòa, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08887",
        "parent_code": "247"
    },
    "08890": {
        "name": "Thanh Vân",
        "type": "xa",
        "slug": "thanh-van",
        "name_with_type": "Xã Thanh Vân",
        "path": "Thanh Vân, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Thanh Vân, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08890",
        "parent_code": "247"
    },
    "08893": {
        "name": "Duy Phiên",
        "type": "xa",
        "slug": "duy-phien",
        "name_with_type": "Xã Duy Phiên",
        "path": "Duy Phiên, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Duy Phiên, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08893",
        "parent_code": "247"
    },
    "08896": {
        "name": "Hoàng Đan",
        "type": "xa",
        "slug": "hoang-dan",
        "name_with_type": "Xã Hoàng Đan",
        "path": "Hoàng Đan, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Hoàng Đan, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08896",
        "parent_code": "247"
    },
    "08899": {
        "name": "Hoàng Lâu",
        "type": "xa",
        "slug": "hoang-lau",
        "name_with_type": "Xã Hoàng Lâu",
        "path": "Hoàng Lâu, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Hoàng Lâu, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08899",
        "parent_code": "247"
    },
    "08902": {
        "name": "Vân Hội",
        "type": "xa",
        "slug": "van-hoi",
        "name_with_type": "Xã Vân Hội",
        "path": "Vân Hội, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Vân Hội, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08902",
        "parent_code": "247"
    },
    "08905": {
        "name": "Hợp Thịnh",
        "type": "xa",
        "slug": "hop-thinh",
        "name_with_type": "Xã Hợp Thịnh",
        "path": "Hợp Thịnh, Tam Dương, Vĩnh Phúc",
        "path_with_type": "Xã Hợp Thịnh, Huyện Tam Dương, Tỉnh Vĩnh Phúc",
        "code": "08905",
        "parent_code": "247"
    },
    "08908": {
        "name": "Tam Đảo",
        "type": "thi-tran",
        "slug": "tam-dao",
        "name_with_type": "Thị trấn Tam Đảo",
        "path": "Tam Đảo, Tam Đảo, Vĩnh Phúc",
        "path_with_type": "Thị trấn Tam Đảo, Huyện Tam Đảo, Tỉnh Vĩnh Phúc",
        "code": "08908",
        "parent_code": "248"
    },
    "08911": {
        "name": "Hợp Châu",
        "type": "xa",
        "slug": "hop-chau",
        "name_with_type": "Xã Hợp Châu",
        "path": "Hợp Châu, Tam Đảo, Vĩnh Phúc",
        "path_with_type": "Xã Hợp Châu, Huyện Tam Đảo, Tỉnh Vĩnh Phúc",
        "code": "08911",
        "parent_code": "248"
    },
    "08914": {
        "name": "Đạo Trù",
        "type": "xa",
        "slug": "dao-tru",
        "name_with_type": "Xã Đạo Trù",
        "path": "Đạo Trù, Tam Đảo, Vĩnh Phúc",
        "path_with_type": "Xã Đạo Trù, Huyện Tam Đảo, Tỉnh Vĩnh Phúc",
        "code": "08914",
        "parent_code": "248"
    },
    "08917": {
        "name": "Yên Dương",
        "type": "xa",
        "slug": "yen-duong",
        "name_with_type": "Xã Yên Dương",
        "path": "Yên Dương, Tam Đảo, Vĩnh Phúc",
        "path_with_type": "Xã Yên Dương, Huyện Tam Đảo, Tỉnh Vĩnh Phúc",
        "code": "08917",
        "parent_code": "248"
    },
    "08920": {
        "name": "Bồ Lý",
        "type": "xa",
        "slug": "bo-ly",
        "name_with_type": "Xã Bồ Lý",
        "path": "Bồ Lý, Tam Đảo, Vĩnh Phúc",
        "path_with_type": "Xã Bồ Lý, Huyện Tam Đảo, Tỉnh Vĩnh Phúc",
        "code": "08920",
        "parent_code": "248"
    },
    "08923": {
        "name": "Đại Đình",
        "type": "xa",
        "slug": "dai-dinh",
        "name_with_type": "Xã Đại Đình",
        "path": "Đại Đình, Tam Đảo, Vĩnh Phúc",
        "path_with_type": "Xã Đại Đình, Huyện Tam Đảo, Tỉnh Vĩnh Phúc",
        "code": "08923",
        "parent_code": "248"
    },
    "08926": {
        "name": "Tam Quan",
        "type": "xa",
        "slug": "tam-quan",
        "name_with_type": "Xã Tam Quan",
        "path": "Tam Quan, Tam Đảo, Vĩnh Phúc",
        "path_with_type": "Xã Tam Quan, Huyện Tam Đảo, Tỉnh Vĩnh Phúc",
        "code": "08926",
        "parent_code": "248"
    },
    "08929": {
        "name": "Hồ Sơn",
        "type": "xa",
        "slug": "ho-son",
        "name_with_type": "Xã Hồ Sơn",
        "path": "Hồ Sơn, Tam Đảo, Vĩnh Phúc",
        "path_with_type": "Xã Hồ Sơn, Huyện Tam Đảo, Tỉnh Vĩnh Phúc",
        "code": "08929",
        "parent_code": "248"
    },
    "08932": {
        "name": "Minh Quang",
        "type": "xa",
        "slug": "minh-quang",
        "name_with_type": "Xã Minh Quang",
        "path": "Minh Quang, Tam Đảo, Vĩnh Phúc",
        "path_with_type": "Xã Minh Quang, Huyện Tam Đảo, Tỉnh Vĩnh Phúc",
        "code": "08932",
        "parent_code": "248"
    },
    "08935": {
        "name": "Hương Canh",
        "type": "thi-tran",
        "slug": "huong-canh",
        "name_with_type": "Thị trấn Hương Canh",
        "path": "Hương Canh, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Thị trấn Hương Canh, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08935",
        "parent_code": "249"
    },
    "08936": {
        "name": "Gia Khánh",
        "type": "thi-tran",
        "slug": "gia-khanh",
        "name_with_type": "Thị trấn Gia Khánh",
        "path": "Gia Khánh, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Thị trấn Gia Khánh, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08936",
        "parent_code": "249"
    },
    "08938": {
        "name": "Trung Mỹ",
        "type": "xa",
        "slug": "trung-my",
        "name_with_type": "Xã Trung Mỹ",
        "path": "Trung Mỹ, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Trung Mỹ, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08938",
        "parent_code": "249"
    },
    "08944": {
        "name": "Bá Hiến",
        "type": "xa",
        "slug": "ba-hien",
        "name_with_type": "Xã Bá Hiến",
        "path": "Bá Hiến, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Bá Hiến, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08944",
        "parent_code": "249"
    },
    "08947": {
        "name": "Thiện Kế",
        "type": "xa",
        "slug": "thien-ke",
        "name_with_type": "Xã Thiện Kế",
        "path": "Thiện Kế, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Thiện Kế, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08947",
        "parent_code": "249"
    },
    "08950": {
        "name": "Hương Sơn",
        "type": "xa",
        "slug": "huong-son",
        "name_with_type": "Xã Hương Sơn",
        "path": "Hương Sơn, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Hương Sơn, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08950",
        "parent_code": "249"
    },
    "08953": {
        "name": "Tam Hợp",
        "type": "xa",
        "slug": "tam-hop",
        "name_with_type": "Xã Tam Hợp",
        "path": "Tam Hợp, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Tam Hợp, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08953",
        "parent_code": "249"
    },
    "08956": {
        "name": "Quất Lưu",
        "type": "xa",
        "slug": "quat-luu",
        "name_with_type": "Xã Quất Lưu",
        "path": "Quất Lưu, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Quất Lưu, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08956",
        "parent_code": "249"
    },
    "08959": {
        "name": "Sơn Lôi",
        "type": "xa",
        "slug": "son-loi",
        "name_with_type": "Xã Sơn Lôi",
        "path": "Sơn Lôi, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Sơn Lôi, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08959",
        "parent_code": "249"
    },
    "08962": {
        "name": "Đạo Đức",
        "type": "xa",
        "slug": "dao-duc",
        "name_with_type": "Xã Đạo Đức",
        "path": "Đạo Đức, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Đạo Đức, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08962",
        "parent_code": "249"
    },
    "08965": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Tân Phong, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08965",
        "parent_code": "249"
    },
    "08968": {
        "name": "Thanh Lãng",
        "type": "thi-tran",
        "slug": "thanh-lang",
        "name_with_type": "Thị trấn Thanh Lãng",
        "path": "Thanh Lãng, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Thị trấn Thanh Lãng, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08968",
        "parent_code": "249"
    },
    "08971": {
        "name": "Phú Xuân",
        "type": "xa",
        "slug": "phu-xuan",
        "name_with_type": "Xã Phú Xuân",
        "path": "Phú Xuân, Bình Xuyên, Vĩnh Phúc",
        "path_with_type": "Xã Phú Xuân, Huyện Bình Xuyên, Tỉnh Vĩnh Phúc",
        "code": "08971",
        "parent_code": "249"
    },
    "09025": {
        "name": "Yên Lạc",
        "type": "thi-tran",
        "slug": "yen-lac",
        "name_with_type": "Thị trấn Yên Lạc",
        "path": "Yên Lạc, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Thị trấn Yên Lạc, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09025",
        "parent_code": "251"
    },
    "09028": {
        "name": "Đồng Cương",
        "type": "xa",
        "slug": "dong-cuong",
        "name_with_type": "Xã Đồng Cương",
        "path": "Đồng Cương, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Đồng Cương, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09028",
        "parent_code": "251"
    },
    "09031": {
        "name": "Đồng Văn",
        "type": "xa",
        "slug": "dong-van",
        "name_with_type": "Xã Đồng Văn",
        "path": "Đồng Văn, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Đồng Văn, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09031",
        "parent_code": "251"
    },
    "09034": {
        "name": "Bình Định",
        "type": "xa",
        "slug": "binh-dinh",
        "name_with_type": "Xã Bình Định",
        "path": "Bình Định, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Bình Định, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09034",
        "parent_code": "251"
    },
    "09037": {
        "name": "Trung Nguyên",
        "type": "xa",
        "slug": "trung-nguyen",
        "name_with_type": "Xã Trung Nguyên",
        "path": "Trung Nguyên, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Trung Nguyên, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09037",
        "parent_code": "251"
    },
    "09040": {
        "name": "Tề Lỗ",
        "type": "xa",
        "slug": "te-lo",
        "name_with_type": "Xã Tề Lỗ",
        "path": "Tề Lỗ, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Tề Lỗ, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09040",
        "parent_code": "251"
    },
    "09043": {
        "name": "Tam Hồng",
        "type": "xa",
        "slug": "tam-hong",
        "name_with_type": "Xã Tam Hồng",
        "path": "Tam Hồng, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Tam Hồng, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09043",
        "parent_code": "251"
    },
    "09046": {
        "name": "Yên Đồng",
        "type": "xa",
        "slug": "yen-dong",
        "name_with_type": "Xã Yên Đồng",
        "path": "Yên Đồng, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Yên Đồng, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09046",
        "parent_code": "251"
    },
    "09049": {
        "name": "Văn Tiến",
        "type": "xa",
        "slug": "van-tien",
        "name_with_type": "Xã Văn Tiến",
        "path": "Văn Tiến, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Văn Tiến, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09049",
        "parent_code": "251"
    },
    "09052": {
        "name": "Nguyệt Đức",
        "type": "xa",
        "slug": "nguyet-duc",
        "name_with_type": "Xã Nguyệt Đức",
        "path": "Nguyệt Đức, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Nguyệt Đức, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09052",
        "parent_code": "251"
    },
    "09055": {
        "name": "Yên Phương",
        "type": "xa",
        "slug": "yen-phuong",
        "name_with_type": "Xã Yên Phương",
        "path": "Yên Phương, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Yên Phương, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09055",
        "parent_code": "251"
    },
    "09058": {
        "name": "Hồng Phương",
        "type": "xa",
        "slug": "hong-phuong",
        "name_with_type": "Xã Hồng Phương",
        "path": "Hồng Phương, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Hồng Phương, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09058",
        "parent_code": "251"
    },
    "09061": {
        "name": "Trung Kiên",
        "type": "xa",
        "slug": "trung-kien",
        "name_with_type": "Xã Trung Kiên",
        "path": "Trung Kiên, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Trung Kiên, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09061",
        "parent_code": "251"
    },
    "09064": {
        "name": "Liên Châu",
        "type": "xa",
        "slug": "lien-chau",
        "name_with_type": "Xã Liên Châu",
        "path": "Liên Châu, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Liên Châu, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09064",
        "parent_code": "251"
    },
    "09067": {
        "name": "Đại Tự",
        "type": "xa",
        "slug": "dai-tu",
        "name_with_type": "Xã Đại Tự",
        "path": "Đại Tự, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Đại Tự, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09067",
        "parent_code": "251"
    },
    "09070": {
        "name": "Hồng Châu",
        "type": "xa",
        "slug": "hong-chau",
        "name_with_type": "Xã Hồng Châu",
        "path": "Hồng Châu, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Hồng Châu, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09070",
        "parent_code": "251"
    },
    "09073": {
        "name": "Trung Hà",
        "type": "xa",
        "slug": "trung-ha",
        "name_with_type": "Xã Trung Hà",
        "path": "Trung Hà, Yên Lạc, Vĩnh Phúc",
        "path_with_type": "Xã Trung Hà, Huyện Yên Lạc, Tỉnh Vĩnh Phúc",
        "code": "09073",
        "parent_code": "251"
    },
    "09076": {
        "name": "Vĩnh Tường",
        "type": "thi-tran",
        "slug": "vinh-tuong",
        "name_with_type": "Thị trấn Vĩnh Tường",
        "path": "Vĩnh Tường, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Thị trấn Vĩnh Tường, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09076",
        "parent_code": "252"
    },
    "09079": {
        "name": "Kim Xá",
        "type": "xa",
        "slug": "kim-xa",
        "name_with_type": "Xã Kim Xá",
        "path": "Kim Xá, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Kim Xá, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09079",
        "parent_code": "252"
    },
    "09082": {
        "name": "Yên Bình",
        "type": "xa",
        "slug": "yen-binh",
        "name_with_type": "Xã Yên Bình",
        "path": "Yên Bình, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Yên Bình, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09082",
        "parent_code": "252"
    },
    "09085": {
        "name": "Chấn Hưng",
        "type": "xa",
        "slug": "chan-hung",
        "name_with_type": "Xã Chấn Hưng",
        "path": "Chấn Hưng, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Chấn Hưng, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09085",
        "parent_code": "252"
    },
    "09088": {
        "name": "Nghĩa Hưng",
        "type": "xa",
        "slug": "nghia-hung",
        "name_with_type": "Xã Nghĩa Hưng",
        "path": "Nghĩa Hưng, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Nghĩa Hưng, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09088",
        "parent_code": "252"
    },
    "09091": {
        "name": "Yên Lập",
        "type": "xa",
        "slug": "yen-lap",
        "name_with_type": "Xã Yên Lập",
        "path": "Yên Lập, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Yên Lập, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09091",
        "parent_code": "252"
    },
    "09094": {
        "name": "Việt Xuân",
        "type": "xa",
        "slug": "viet-xuan",
        "name_with_type": "Xã Việt Xuân",
        "path": "Việt Xuân, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Việt Xuân, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09094",
        "parent_code": "252"
    },
    "09097": {
        "name": "Bồ Sao",
        "type": "xa",
        "slug": "bo-sao",
        "name_with_type": "Xã Bồ Sao",
        "path": "Bồ Sao, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Bồ Sao, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09097",
        "parent_code": "252"
    },
    "09100": {
        "name": "Đại Đồng",
        "type": "xa",
        "slug": "dai-dong",
        "name_with_type": "Xã Đại Đồng",
        "path": "Đại Đồng, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Đại Đồng, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09100",
        "parent_code": "252"
    },
    "09103": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Tân Tiến, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09103",
        "parent_code": "252"
    },
    "09106": {
        "name": "Lũng Hoà",
        "type": "xa",
        "slug": "lung-hoa",
        "name_with_type": "Xã Lũng Hoà",
        "path": "Lũng Hoà, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Lũng Hoà, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09106",
        "parent_code": "252"
    },
    "09109": {
        "name": "Cao Đại",
        "type": "xa",
        "slug": "cao-dai",
        "name_with_type": "Xã Cao Đại",
        "path": "Cao Đại, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Cao Đại, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09109",
        "parent_code": "252"
    },
    "09112": {
        "name": "Thổ Tang",
        "type": "thi-tran",
        "slug": "tho-tang",
        "name_with_type": "Thị Trấn Thổ Tang",
        "path": "Thổ Tang, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Thị Trấn Thổ Tang, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09112",
        "parent_code": "252"
    },
    "09115": {
        "name": "Vĩnh Sơn",
        "type": "xa",
        "slug": "vinh-son",
        "name_with_type": "Xã Vĩnh Sơn",
        "path": "Vĩnh Sơn, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Vĩnh Sơn, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09115",
        "parent_code": "252"
    },
    "09118": {
        "name": "Bình Dương",
        "type": "xa",
        "slug": "binh-duong",
        "name_with_type": "Xã Bình Dương",
        "path": "Bình Dương, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Bình Dương, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09118",
        "parent_code": "252"
    },
    "09121": {
        "name": "Tân Cương",
        "type": "xa",
        "slug": "tan-cuong",
        "name_with_type": "Xã Tân Cương",
        "path": "Tân Cương, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Tân Cương, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09121",
        "parent_code": "252"
    },
    "09124": {
        "name": "Phú Thịnh",
        "type": "xa",
        "slug": "phu-thinh",
        "name_with_type": "Xã Phú Thịnh",
        "path": "Phú Thịnh, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Phú Thịnh, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09124",
        "parent_code": "252"
    },
    "09127": {
        "name": "Thượng Trưng",
        "type": "xa",
        "slug": "thuong-trung",
        "name_with_type": "Xã Thượng Trưng",
        "path": "Thượng Trưng, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Thượng Trưng, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09127",
        "parent_code": "252"
    },
    "09130": {
        "name": "Vũ Di",
        "type": "xa",
        "slug": "vu-di",
        "name_with_type": "Xã Vũ Di",
        "path": "Vũ Di, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Vũ Di, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09130",
        "parent_code": "252"
    },
    "09133": {
        "name": "Lý Nhân",
        "type": "xa",
        "slug": "ly-nhan",
        "name_with_type": "Xã Lý Nhân",
        "path": "Lý Nhân, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Lý Nhân, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09133",
        "parent_code": "252"
    },
    "09136": {
        "name": "Tuân Chính",
        "type": "xa",
        "slug": "tuan-chinh",
        "name_with_type": "Xã Tuân Chính",
        "path": "Tuân Chính, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Tuân Chính, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09136",
        "parent_code": "252"
    },
    "09139": {
        "name": "Vân Xuân",
        "type": "xa",
        "slug": "van-xuan",
        "name_with_type": "Xã Vân Xuân",
        "path": "Vân Xuân, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Vân Xuân, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09139",
        "parent_code": "252"
    },
    "09142": {
        "name": "Tam Phúc",
        "type": "xa",
        "slug": "tam-phuc",
        "name_with_type": "Xã Tam Phúc",
        "path": "Tam Phúc, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Tam Phúc, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09142",
        "parent_code": "252"
    },
    "09145": {
        "name": "Tứ Trưng",
        "type": "thi-tran",
        "slug": "tu-trung",
        "name_with_type": "Thị trấn Tứ Trưng",
        "path": "Tứ Trưng, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Thị trấn Tứ Trưng, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09145",
        "parent_code": "252"
    },
    "09148": {
        "name": "Ngũ Kiên",
        "type": "xa",
        "slug": "ngu-kien",
        "name_with_type": "Xã Ngũ Kiên",
        "path": "Ngũ Kiên, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Ngũ Kiên, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09148",
        "parent_code": "252"
    },
    "09151": {
        "name": "An Tường",
        "type": "xa",
        "slug": "an-tuong",
        "name_with_type": "Xã An Tường",
        "path": "An Tường, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã An Tường, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09151",
        "parent_code": "252"
    },
    "09154": {
        "name": "Vĩnh Thịnh",
        "type": "xa",
        "slug": "vinh-thinh",
        "name_with_type": "Xã Vĩnh Thịnh",
        "path": "Vĩnh Thịnh, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Vĩnh Thịnh, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09154",
        "parent_code": "252"
    },
    "09157": {
        "name": "Phú Đa",
        "type": "xa",
        "slug": "phu-da",
        "name_with_type": "Xã Phú Đa",
        "path": "Phú Đa, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Phú Đa, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09157",
        "parent_code": "252"
    },
    "09160": {
        "name": "Vĩnh Ninh",
        "type": "xa",
        "slug": "vinh-ninh",
        "name_with_type": "Xã Vĩnh Ninh",
        "path": "Vĩnh Ninh, Vĩnh Tường, Vĩnh Phúc",
        "path_with_type": "Xã Vĩnh Ninh, Huyện Vĩnh Tường, Tỉnh Vĩnh Phúc",
        "code": "09160",
        "parent_code": "252"
    },
    "08773": {
        "name": "Lãng Công",
        "type": "xa",
        "slug": "lang-cong",
        "name_with_type": "Xã Lãng Công",
        "path": "Lãng Công, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Lãng Công, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08773",
        "parent_code": "253"
    },
    "08776": {
        "name": "Quang Yên",
        "type": "xa",
        "slug": "quang-yen",
        "name_with_type": "Xã Quang Yên",
        "path": "Quang Yên, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Quang Yên, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08776",
        "parent_code": "253"
    },
    "08779": {
        "name": "Bạch Lưu",
        "type": "xa",
        "slug": "bach-luu",
        "name_with_type": "Xã Bạch Lưu",
        "path": "Bạch Lưu, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Bạch Lưu, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08779",
        "parent_code": "253"
    },
    "08782": {
        "name": "Hải Lựu",
        "type": "xa",
        "slug": "hai-luu",
        "name_with_type": "Xã Hải Lựu",
        "path": "Hải Lựu, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Hải Lựu, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08782",
        "parent_code": "253"
    },
    "08800": {
        "name": "Đồng Quế",
        "type": "xa",
        "slug": "dong-que",
        "name_with_type": "Xã Đồng Quế",
        "path": "Đồng Quế, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Đồng Quế, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08800",
        "parent_code": "253"
    },
    "08803": {
        "name": "Nhân Đạo",
        "type": "xa",
        "slug": "nhan-dao",
        "name_with_type": "Xã Nhân Đạo",
        "path": "Nhân Đạo, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Nhân Đạo, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08803",
        "parent_code": "253"
    },
    "08806": {
        "name": "Đôn Nhân",
        "type": "xa",
        "slug": "don-nhan",
        "name_with_type": "Xã Đôn Nhân",
        "path": "Đôn Nhân, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Đôn Nhân, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08806",
        "parent_code": "253"
    },
    "08809": {
        "name": "Phương Khoan",
        "type": "xa",
        "slug": "phuong-khoan",
        "name_with_type": "Xã Phương Khoan",
        "path": "Phương Khoan, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Phương Khoan, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08809",
        "parent_code": "253"
    },
    "08818": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Tân Lập, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08818",
        "parent_code": "253"
    },
    "08821": {
        "name": "Nhạo Sơn",
        "type": "xa",
        "slug": "nhao-son",
        "name_with_type": "Xã Nhạo Sơn",
        "path": "Nhạo Sơn, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Nhạo Sơn, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08821",
        "parent_code": "253"
    },
    "08824": {
        "name": "Tam Sơn",
        "type": "thi-tran",
        "slug": "tam-son",
        "name_with_type": "Thị trấn Tam Sơn",
        "path": "Tam Sơn, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Thị trấn Tam Sơn, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08824",
        "parent_code": "253"
    },
    "08827": {
        "name": "Như Thụy",
        "type": "xa",
        "slug": "nhu-thuy",
        "name_with_type": "Xã Như Thụy",
        "path": "Như Thụy, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Như Thụy, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08827",
        "parent_code": "253"
    },
    "08830": {
        "name": "Yên Thạch",
        "type": "xa",
        "slug": "yen-thach",
        "name_with_type": "Xã Yên Thạch",
        "path": "Yên Thạch, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Yên Thạch, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08830",
        "parent_code": "253"
    },
    "08848": {
        "name": "Đồng Thịnh",
        "type": "xa",
        "slug": "dong-thinh",
        "name_with_type": "Xã Đồng Thịnh",
        "path": "Đồng Thịnh, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Đồng Thịnh, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08848",
        "parent_code": "253"
    },
    "08851": {
        "name": "Tứ Yên",
        "type": "xa",
        "slug": "tu-yen",
        "name_with_type": "Xã Tứ Yên",
        "path": "Tứ Yên, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Tứ Yên, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08851",
        "parent_code": "253"
    },
    "08854": {
        "name": "Đức Bác",
        "type": "xa",
        "slug": "duc-bac",
        "name_with_type": "Xã Đức Bác",
        "path": "Đức Bác, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Đức Bác, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08854",
        "parent_code": "253"
    },
    "08860": {
        "name": "Cao Phong",
        "type": "xa",
        "slug": "cao-phong",
        "name_with_type": "Xã Cao Phong",
        "path": "Cao Phong, Sông Lô, Vĩnh Phúc",
        "path_with_type": "Xã Cao Phong, Huyện Sông Lô, Tỉnh Vĩnh Phúc",
        "code": "08860",
        "parent_code": "253"
    },
    "20335": {
        "name": "Tân Thạnh",
        "type": "phuong",
        "slug": "tan-thanh",
        "name_with_type": "Phường Tân Thạnh",
        "path": "Tân Thạnh, Tam Kỳ, Quảng Nam",
        "path_with_type": "Phường Tân Thạnh, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20335",
        "parent_code": "502"
    },
    "20338": {
        "name": "Phước Hòa",
        "type": "phuong",
        "slug": "phuoc-hoa",
        "name_with_type": "Phường Phước Hòa",
        "path": "Phước Hòa, Tam Kỳ, Quảng Nam",
        "path_with_type": "Phường Phước Hòa, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20338",
        "parent_code": "502"
    },
    "20341": {
        "name": "An Mỹ",
        "type": "phuong",
        "slug": "an-my",
        "name_with_type": "Phường An Mỹ",
        "path": "An Mỹ, Tam Kỳ, Quảng Nam",
        "path_with_type": "Phường An Mỹ, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20341",
        "parent_code": "502"
    },
    "20344": {
        "name": "Hòa Hương",
        "type": "phuong",
        "slug": "hoa-huong",
        "name_with_type": "Phường Hòa Hương",
        "path": "Hòa Hương, Tam Kỳ, Quảng Nam",
        "path_with_type": "Phường Hòa Hương, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20344",
        "parent_code": "502"
    },
    "20347": {
        "name": "An Xuân",
        "type": "phuong",
        "slug": "an-xuan",
        "name_with_type": "Phường An Xuân",
        "path": "An Xuân, Tam Kỳ, Quảng Nam",
        "path_with_type": "Phường An Xuân, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20347",
        "parent_code": "502"
    },
    "20350": {
        "name": "An Sơn",
        "type": "phuong",
        "slug": "an-son",
        "name_with_type": "Phường An Sơn",
        "path": "An Sơn, Tam Kỳ, Quảng Nam",
        "path_with_type": "Phường An Sơn, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20350",
        "parent_code": "502"
    },
    "20353": {
        "name": "Trường Xuân",
        "type": "phuong",
        "slug": "truong-xuan",
        "name_with_type": "Phường Trường Xuân",
        "path": "Trường Xuân, Tam Kỳ, Quảng Nam",
        "path_with_type": "Phường Trường Xuân, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20353",
        "parent_code": "502"
    },
    "20356": {
        "name": "An Phú",
        "type": "phuong",
        "slug": "an-phu",
        "name_with_type": "Phường An Phú",
        "path": "An Phú, Tam Kỳ, Quảng Nam",
        "path_with_type": "Phường An Phú, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20356",
        "parent_code": "502"
    },
    "20359": {
        "name": "Tam Thanh",
        "type": "xa",
        "slug": "tam-thanh",
        "name_with_type": "Xã Tam Thanh",
        "path": "Tam Thanh, Tam Kỳ, Quảng Nam",
        "path_with_type": "Xã Tam Thanh, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20359",
        "parent_code": "502"
    },
    "20362": {
        "name": "Tam Thăng",
        "type": "xa",
        "slug": "tam-thang",
        "name_with_type": "Xã Tam Thăng",
        "path": "Tam Thăng, Tam Kỳ, Quảng Nam",
        "path_with_type": "Xã Tam Thăng, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20362",
        "parent_code": "502"
    },
    "20371": {
        "name": "Tam Phú",
        "type": "xa",
        "slug": "tam-phu",
        "name_with_type": "Xã Tam Phú",
        "path": "Tam Phú, Tam Kỳ, Quảng Nam",
        "path_with_type": "Xã Tam Phú, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20371",
        "parent_code": "502"
    },
    "20375": {
        "name": "Hoà Thuận",
        "type": "phuong",
        "slug": "hoa-thuan",
        "name_with_type": "Phường Hoà Thuận",
        "path": "Hoà Thuận, Tam Kỳ, Quảng Nam",
        "path_with_type": "Phường Hoà Thuận, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20375",
        "parent_code": "502"
    },
    "20389": {
        "name": "Tam Ngọc",
        "type": "xa",
        "slug": "tam-ngoc",
        "name_with_type": "Xã Tam Ngọc",
        "path": "Tam Ngọc, Tam Kỳ, Quảng Nam",
        "path_with_type": "Xã Tam Ngọc, Thành phố Tam Kỳ, Tỉnh Quảng Nam",
        "code": "20389",
        "parent_code": "502"
    },
    "20398": {
        "name": "Minh An",
        "type": "phuong",
        "slug": "minh-an",
        "name_with_type": "Phường Minh An",
        "path": "Minh An, Hội An, Quảng Nam",
        "path_with_type": "Phường Minh An, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20398",
        "parent_code": "503"
    },
    "20401": {
        "name": "Tân An",
        "type": "phuong",
        "slug": "tan-an",
        "name_with_type": "Phường Tân An",
        "path": "Tân An, Hội An, Quảng Nam",
        "path_with_type": "Phường Tân An, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20401",
        "parent_code": "503"
    },
    "20404": {
        "name": "Cẩm Phô",
        "type": "phuong",
        "slug": "cam-pho",
        "name_with_type": "Phường Cẩm Phô",
        "path": "Cẩm Phô, Hội An, Quảng Nam",
        "path_with_type": "Phường Cẩm Phô, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20404",
        "parent_code": "503"
    },
    "20407": {
        "name": "Thanh Hà",
        "type": "phuong",
        "slug": "thanh-ha",
        "name_with_type": "Phường Thanh Hà",
        "path": "Thanh Hà, Hội An, Quảng Nam",
        "path_with_type": "Phường Thanh Hà, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20407",
        "parent_code": "503"
    },
    "20410": {
        "name": "Sơn Phong",
        "type": "phuong",
        "slug": "son-phong",
        "name_with_type": "Phường Sơn Phong",
        "path": "Sơn Phong, Hội An, Quảng Nam",
        "path_with_type": "Phường Sơn Phong, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20410",
        "parent_code": "503"
    },
    "20413": {
        "name": "Cẩm Châu",
        "type": "phuong",
        "slug": "cam-chau",
        "name_with_type": "Phường Cẩm Châu",
        "path": "Cẩm Châu, Hội An, Quảng Nam",
        "path_with_type": "Phường Cẩm Châu, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20413",
        "parent_code": "503"
    },
    "20416": {
        "name": "Cửa Đại",
        "type": "phuong",
        "slug": "cua-dai",
        "name_with_type": "Phường Cửa Đại",
        "path": "Cửa Đại, Hội An, Quảng Nam",
        "path_with_type": "Phường Cửa Đại, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20416",
        "parent_code": "503"
    },
    "20419": {
        "name": "Cẩm An",
        "type": "phuong",
        "slug": "cam-an",
        "name_with_type": "Phường Cẩm An",
        "path": "Cẩm An, Hội An, Quảng Nam",
        "path_with_type": "Phường Cẩm An, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20419",
        "parent_code": "503"
    },
    "20422": {
        "name": "Cẩm Hà",
        "type": "xa",
        "slug": "cam-ha",
        "name_with_type": "Xã Cẩm Hà",
        "path": "Cẩm Hà, Hội An, Quảng Nam",
        "path_with_type": "Xã Cẩm Hà, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20422",
        "parent_code": "503"
    },
    "20425": {
        "name": "Cẩm Kim",
        "type": "xa",
        "slug": "cam-kim",
        "name_with_type": "Xã Cẩm Kim",
        "path": "Cẩm Kim, Hội An, Quảng Nam",
        "path_with_type": "Xã Cẩm Kim, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20425",
        "parent_code": "503"
    },
    "20428": {
        "name": "Cẩm Nam",
        "type": "phuong",
        "slug": "cam-nam",
        "name_with_type": "Phường Cẩm Nam",
        "path": "Cẩm Nam, Hội An, Quảng Nam",
        "path_with_type": "Phường Cẩm Nam, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20428",
        "parent_code": "503"
    },
    "20431": {
        "name": "Cẩm Thanh",
        "type": "xa",
        "slug": "cam-thanh",
        "name_with_type": "Xã Cẩm Thanh",
        "path": "Cẩm Thanh, Hội An, Quảng Nam",
        "path_with_type": "Xã Cẩm Thanh, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20431",
        "parent_code": "503"
    },
    "20434": {
        "name": "Tân Hiệp",
        "type": "xa",
        "slug": "tan-hiep",
        "name_with_type": "Xã Tân Hiệp",
        "path": "Tân Hiệp, Hội An, Quảng Nam",
        "path_with_type": "Xã Tân Hiệp, Thành phố Hội An, Tỉnh Quảng Nam",
        "code": "20434",
        "parent_code": "503"
    },
    "20437": {
        "name": "Ch'ơm",
        "type": "xa",
        "slug": "ch-om",
        "name_with_type": "Xã Ch'ơm",
        "path": "Ch'ơm, Tây Giang, Quảng Nam",
        "path_with_type": "Xã Ch'ơm, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20437",
        "parent_code": "504"
    },
    "20440": {
        "name": "Ga Ri",
        "type": "xa",
        "slug": "ga-ri",
        "name_with_type": "Xã Ga Ri",
        "path": "Ga Ri, Tây Giang, Quảng Nam",
        "path_with_type": "Xã Ga Ri, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20440",
        "parent_code": "504"
    },
    "20443": {
        "name": "A Xan",
        "type": "xa",
        "slug": "a-xan",
        "name_with_type": "Xã A Xan",
        "path": "A Xan, Tây Giang, Quảng Nam",
        "path_with_type": "Xã A Xan, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20443",
        "parent_code": "504"
    },
    "20446": {
        "name": "Tr'Hy",
        "type": "xa",
        "slug": "tr-hy",
        "name_with_type": "Xã Tr'Hy",
        "path": "Tr'Hy, Tây Giang, Quảng Nam",
        "path_with_type": "Xã Tr'Hy, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20446",
        "parent_code": "504"
    },
    "20449": {
        "name": "Lăng",
        "type": "xa",
        "slug": "lang",
        "name_with_type": "Xã Lăng",
        "path": "Lăng, Tây Giang, Quảng Nam",
        "path_with_type": "Xã Lăng, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20449",
        "parent_code": "504"
    },
    "20452": {
        "name": "A Nông",
        "type": "xa",
        "slug": "a-nong",
        "name_with_type": "Xã A Nông",
        "path": "A Nông, Tây Giang, Quảng Nam",
        "path_with_type": "Xã A Nông, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20452",
        "parent_code": "504"
    },
    "20455": {
        "name": "A Tiêng",
        "type": "xa",
        "slug": "a-tieng",
        "name_with_type": "Xã A Tiêng",
        "path": "A Tiêng, Tây Giang, Quảng Nam",
        "path_with_type": "Xã A Tiêng, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20455",
        "parent_code": "504"
    },
    "20458": {
        "name": "Bha Lê",
        "type": "xa",
        "slug": "bha-le",
        "name_with_type": "Xã Bha Lê",
        "path": "Bha Lê, Tây Giang, Quảng Nam",
        "path_with_type": "Xã Bha Lê, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20458",
        "parent_code": "504"
    },
    "20461": {
        "name": "A Vương",
        "type": "xa",
        "slug": "a-vuong",
        "name_with_type": "Xã A Vương",
        "path": "A Vương, Tây Giang, Quảng Nam",
        "path_with_type": "Xã A Vương, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20461",
        "parent_code": "504"
    },
    "20464": {
        "name": "Dang",
        "type": "xa",
        "slug": "dang",
        "name_with_type": "Xã Dang",
        "path": "Dang, Tây Giang, Quảng Nam",
        "path_with_type": "Xã Dang, Huyện Tây Giang, Tỉnh Quảng Nam",
        "code": "20464",
        "parent_code": "504"
    },
    "20467": {
        "name": "P Rao",
        "type": "thi-tran",
        "slug": "p-rao",
        "name_with_type": "Thị trấn P Rao",
        "path": "P Rao, Đông Giang, Quảng Nam",
        "path_with_type": "Thị trấn P Rao, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20467",
        "parent_code": "505"
    },
    "20470": {
        "name": "Tà Lu",
        "type": "xa",
        "slug": "ta-lu",
        "name_with_type": "Xã Tà Lu",
        "path": "Tà Lu, Đông Giang, Quảng Nam",
        "path_with_type": "Xã Tà Lu, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20470",
        "parent_code": "505"
    },
    "20473": {
        "name": "Sông Kôn",
        "type": "xa",
        "slug": "song-kon",
        "name_with_type": "Xã Sông Kôn",
        "path": "Sông Kôn, Đông Giang, Quảng Nam",
        "path_with_type": "Xã Sông Kôn, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20473",
        "parent_code": "505"
    },
    "20476": {
        "name": "Jơ Ngây",
        "type": "xa",
        "slug": "jo-ngay",
        "name_with_type": "Xã Jơ Ngây",
        "path": "Jơ Ngây, Đông Giang, Quảng Nam",
        "path_with_type": "Xã Jơ Ngây, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20476",
        "parent_code": "505"
    },
    "20479": {
        "name": "A Ting",
        "type": "xa",
        "slug": "a-ting",
        "name_with_type": "Xã A Ting",
        "path": "A Ting, Đông Giang, Quảng Nam",
        "path_with_type": "Xã A Ting, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20479",
        "parent_code": "505"
    },
    "20482": {
        "name": "Tư",
        "type": "xa",
        "slug": "tu",
        "name_with_type": "Xã  Tư",
        "path": "Tư, Đông Giang, Quảng Nam",
        "path_with_type": "Xã  Tư, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20482",
        "parent_code": "505"
    },
    "20485": {
        "name": "Ba",
        "type": "xa",
        "slug": "ba",
        "name_with_type": "Xã Ba",
        "path": "Ba, Đông Giang, Quảng Nam",
        "path_with_type": "Xã Ba, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20485",
        "parent_code": "505"
    },
    "20488": {
        "name": "A Rooi",
        "type": "xa",
        "slug": "a-rooi",
        "name_with_type": "Xã A Rooi",
        "path": "A Rooi, Đông Giang, Quảng Nam",
        "path_with_type": "Xã A Rooi, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20488",
        "parent_code": "505"
    },
    "20491": {
        "name": "Za Hung",
        "type": "xa",
        "slug": "za-hung",
        "name_with_type": "Xã Za Hung",
        "path": "Za Hung, Đông Giang, Quảng Nam",
        "path_with_type": "Xã Za Hung, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20491",
        "parent_code": "505"
    },
    "20494": {
        "name": "Mà Cooi",
        "type": "xa",
        "slug": "ma-cooi",
        "name_with_type": "Xã Mà Cooi",
        "path": "Mà Cooi, Đông Giang, Quảng Nam",
        "path_with_type": "Xã Mà Cooi, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20494",
        "parent_code": "505"
    },
    "20497": {
        "name": "Ka Dăng",
        "type": "xa",
        "slug": "ka-dang",
        "name_with_type": "Xã Ka Dăng",
        "path": "Ka Dăng, Đông Giang, Quảng Nam",
        "path_with_type": "Xã Ka Dăng, Huyện Đông Giang, Tỉnh Quảng Nam",
        "code": "20497",
        "parent_code": "505"
    },
    "20500": {
        "name": "Ái Nghĩa",
        "type": "thi-tran",
        "slug": "ai-nghia",
        "name_with_type": "Thị Trấn Ái Nghĩa",
        "path": "Ái Nghĩa, Đại Lộc, Quảng Nam",
        "path_with_type": "Thị Trấn Ái Nghĩa, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20500",
        "parent_code": "506"
    },
    "20503": {
        "name": "Đại Sơn",
        "type": "xa",
        "slug": "dai-son",
        "name_with_type": "Xã Đại Sơn",
        "path": "Đại Sơn, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Sơn, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20503",
        "parent_code": "506"
    },
    "20506": {
        "name": "Đại Lãnh",
        "type": "xa",
        "slug": "dai-lanh",
        "name_with_type": "Xã Đại Lãnh",
        "path": "Đại Lãnh, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Lãnh, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20506",
        "parent_code": "506"
    },
    "20509": {
        "name": "Đại Hưng",
        "type": "xa",
        "slug": "dai-hung",
        "name_with_type": "Xã Đại Hưng",
        "path": "Đại Hưng, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Hưng, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20509",
        "parent_code": "506"
    },
    "20512": {
        "name": "Đại Hồng",
        "type": "xa",
        "slug": "dai-hong",
        "name_with_type": "Xã Đại Hồng",
        "path": "Đại Hồng, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Hồng, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20512",
        "parent_code": "506"
    },
    "20515": {
        "name": "Đại Đồng",
        "type": "xa",
        "slug": "dai-dong",
        "name_with_type": "Xã Đại Đồng",
        "path": "Đại Đồng, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Đồng, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20515",
        "parent_code": "506"
    },
    "20518": {
        "name": "Đại Quang",
        "type": "xa",
        "slug": "dai-quang",
        "name_with_type": "Xã Đại Quang",
        "path": "Đại Quang, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Quang, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20518",
        "parent_code": "506"
    },
    "20521": {
        "name": "Đại Nghĩa",
        "type": "xa",
        "slug": "dai-nghia",
        "name_with_type": "Xã Đại Nghĩa",
        "path": "Đại Nghĩa, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Nghĩa, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20521",
        "parent_code": "506"
    },
    "20524": {
        "name": "Đại Hiệp",
        "type": "xa",
        "slug": "dai-hiep",
        "name_with_type": "Xã Đại Hiệp",
        "path": "Đại Hiệp, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Hiệp, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20524",
        "parent_code": "506"
    },
    "20527": {
        "name": "Đại Thạnh",
        "type": "xa",
        "slug": "dai-thanh",
        "name_with_type": "Xã Đại Thạnh",
        "path": "Đại Thạnh, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Thạnh, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20527",
        "parent_code": "506"
    },
    "20530": {
        "name": "Đại Chánh",
        "type": "xa",
        "slug": "dai-chanh",
        "name_with_type": "Xã Đại Chánh",
        "path": "Đại Chánh, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Chánh, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20530",
        "parent_code": "506"
    },
    "20533": {
        "name": "Đại Tân",
        "type": "xa",
        "slug": "dai-tan",
        "name_with_type": "Xã Đại Tân",
        "path": "Đại Tân, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Tân, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20533",
        "parent_code": "506"
    },
    "20536": {
        "name": "Đại Phong",
        "type": "xa",
        "slug": "dai-phong",
        "name_with_type": "Xã Đại Phong",
        "path": "Đại Phong, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Phong, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20536",
        "parent_code": "506"
    },
    "20539": {
        "name": "Đại Minh",
        "type": "xa",
        "slug": "dai-minh",
        "name_with_type": "Xã Đại Minh",
        "path": "Đại Minh, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Minh, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20539",
        "parent_code": "506"
    },
    "20542": {
        "name": "Đại Thắng",
        "type": "xa",
        "slug": "dai-thang",
        "name_with_type": "Xã Đại Thắng",
        "path": "Đại Thắng, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Thắng, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20542",
        "parent_code": "506"
    },
    "20545": {
        "name": "Đại Cường",
        "type": "xa",
        "slug": "dai-cuong",
        "name_with_type": "Xã Đại Cường",
        "path": "Đại Cường, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Cường, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20545",
        "parent_code": "506"
    },
    "20547": {
        "name": "Đại An",
        "type": "xa",
        "slug": "dai-an",
        "name_with_type": "Xã Đại An",
        "path": "Đại An, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại An, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20547",
        "parent_code": "506"
    },
    "20548": {
        "name": "Đại Hòa",
        "type": "xa",
        "slug": "dai-hoa",
        "name_with_type": "Xã Đại Hòa",
        "path": "Đại Hòa, Đại Lộc, Quảng Nam",
        "path_with_type": "Xã Đại Hòa, Huyện Đại Lộc, Tỉnh Quảng Nam",
        "code": "20548",
        "parent_code": "506"
    },
    "20551": {
        "name": "Vĩnh Điện",
        "type": "phuong",
        "slug": "vinh-dien",
        "name_with_type": "Phường Vĩnh Điện",
        "path": "Vĩnh Điện, Điện Bàn, Quảng Nam",
        "path_with_type": "Phường Vĩnh Điện, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20551",
        "parent_code": "507"
    },
    "20554": {
        "name": "Điện Tiến",
        "type": "xa",
        "slug": "dien-tien",
        "name_with_type": "Xã Điện Tiến",
        "path": "Điện Tiến, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Tiến, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20554",
        "parent_code": "507"
    },
    "20557": {
        "name": "Điện Hòa",
        "type": "xa",
        "slug": "dien-hoa",
        "name_with_type": "Xã Điện Hòa",
        "path": "Điện Hòa, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Hòa, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20557",
        "parent_code": "507"
    },
    "20560": {
        "name": "Điện Thắng Bắc",
        "type": "xa",
        "slug": "dien-thang-bac",
        "name_with_type": "Xã Điện Thắng Bắc",
        "path": "Điện Thắng Bắc, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Thắng Bắc, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20560",
        "parent_code": "507"
    },
    "20561": {
        "name": "Điện Thắng Trung",
        "type": "xa",
        "slug": "dien-thang-trung",
        "name_with_type": "Xã Điện Thắng Trung",
        "path": "Điện Thắng Trung, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Thắng Trung, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20561",
        "parent_code": "507"
    },
    "20562": {
        "name": "Điện Thắng Nam",
        "type": "xa",
        "slug": "dien-thang-nam",
        "name_with_type": "Xã Điện Thắng Nam",
        "path": "Điện Thắng Nam, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Thắng Nam, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20562",
        "parent_code": "507"
    },
    "20563": {
        "name": "Điện Ngọc",
        "type": "phuong",
        "slug": "dien-ngoc",
        "name_with_type": "Phường Điện Ngọc",
        "path": "Điện Ngọc, Điện Bàn, Quảng Nam",
        "path_with_type": "Phường Điện Ngọc, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20563",
        "parent_code": "507"
    },
    "20566": {
        "name": "Điện Hồng",
        "type": "xa",
        "slug": "dien-hong",
        "name_with_type": "Xã Điện Hồng",
        "path": "Điện Hồng, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Hồng, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20566",
        "parent_code": "507"
    },
    "20569": {
        "name": "Điện Thọ",
        "type": "xa",
        "slug": "dien-tho",
        "name_with_type": "Xã Điện Thọ",
        "path": "Điện Thọ, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Thọ, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20569",
        "parent_code": "507"
    },
    "20572": {
        "name": "Điện Phước",
        "type": "xa",
        "slug": "dien-phuoc",
        "name_with_type": "Xã Điện Phước",
        "path": "Điện Phước, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Phước, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20572",
        "parent_code": "507"
    },
    "20575": {
        "name": "Điện An",
        "type": "phuong",
        "slug": "dien-an",
        "name_with_type": "Phường Điện An",
        "path": "Điện An, Điện Bàn, Quảng Nam",
        "path_with_type": "Phường Điện An, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20575",
        "parent_code": "507"
    },
    "20578": {
        "name": "Điện Nam Bắc",
        "type": "phuong",
        "slug": "dien-nam-bac",
        "name_with_type": "Phường Điện Nam Bắc",
        "path": "Điện Nam Bắc, Điện Bàn, Quảng Nam",
        "path_with_type": "Phường Điện Nam Bắc, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20578",
        "parent_code": "507"
    },
    "20579": {
        "name": "Điện Nam Trung",
        "type": "phuong",
        "slug": "dien-nam-trung",
        "name_with_type": "Phường Điện Nam Trung",
        "path": "Điện Nam Trung, Điện Bàn, Quảng Nam",
        "path_with_type": "Phường Điện Nam Trung, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20579",
        "parent_code": "507"
    },
    "20580": {
        "name": "Điện Nam Đông",
        "type": "phuong",
        "slug": "dien-nam-dong",
        "name_with_type": "Phường Điện Nam Đông",
        "path": "Điện Nam Đông, Điện Bàn, Quảng Nam",
        "path_with_type": "Phường Điện Nam Đông, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20580",
        "parent_code": "507"
    },
    "20581": {
        "name": "Điện Dương",
        "type": "phuong",
        "slug": "dien-duong",
        "name_with_type": "Phường Điện Dương",
        "path": "Điện Dương, Điện Bàn, Quảng Nam",
        "path_with_type": "Phường Điện Dương, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20581",
        "parent_code": "507"
    },
    "20584": {
        "name": "Điện Quang",
        "type": "xa",
        "slug": "dien-quang",
        "name_with_type": "Xã Điện Quang",
        "path": "Điện Quang, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Quang, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20584",
        "parent_code": "507"
    },
    "20587": {
        "name": "Điện Trung",
        "type": "xa",
        "slug": "dien-trung",
        "name_with_type": "Xã Điện Trung",
        "path": "Điện Trung, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Trung, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20587",
        "parent_code": "507"
    },
    "20590": {
        "name": "Điện Phong",
        "type": "xa",
        "slug": "dien-phong",
        "name_with_type": "Xã Điện Phong",
        "path": "Điện Phong, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Phong, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20590",
        "parent_code": "507"
    },
    "20593": {
        "name": "Điện Minh",
        "type": "xa",
        "slug": "dien-minh",
        "name_with_type": "Xã Điện Minh",
        "path": "Điện Minh, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Minh, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20593",
        "parent_code": "507"
    },
    "20596": {
        "name": "Điện Phương",
        "type": "xa",
        "slug": "dien-phuong",
        "name_with_type": "Xã Điện Phương",
        "path": "Điện Phương, Điện Bàn, Quảng Nam",
        "path_with_type": "Xã Điện Phương, Thị xã Điện Bàn, Tỉnh Quảng Nam",
        "code": "20596",
        "parent_code": "507"
    },
    "20599": {
        "name": "Nam Phước",
        "type": "thi-tran",
        "slug": "nam-phuoc",
        "name_with_type": "Thị trấn Nam Phước",
        "path": "Nam Phước, Duy Xuyên, Quảng Nam",
        "path_with_type": "Thị trấn Nam Phước, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20599",
        "parent_code": "508"
    },
    "20602": {
        "name": "Duy Thu",
        "type": "xa",
        "slug": "duy-thu",
        "name_with_type": "Xã Duy Thu",
        "path": "Duy Thu, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Thu, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20602",
        "parent_code": "508"
    },
    "20605": {
        "name": "Duy Phú",
        "type": "xa",
        "slug": "duy-phu",
        "name_with_type": "Xã Duy Phú",
        "path": "Duy Phú, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Phú, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20605",
        "parent_code": "508"
    },
    "20608": {
        "name": "Duy Tân",
        "type": "xa",
        "slug": "duy-tan",
        "name_with_type": "Xã Duy Tân",
        "path": "Duy Tân, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Tân, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20608",
        "parent_code": "508"
    },
    "20611": {
        "name": "Duy Hòa",
        "type": "xa",
        "slug": "duy-hoa",
        "name_with_type": "Xã Duy Hòa",
        "path": "Duy Hòa, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Hòa, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20611",
        "parent_code": "508"
    },
    "20614": {
        "name": "Duy Châu",
        "type": "xa",
        "slug": "duy-chau",
        "name_with_type": "Xã Duy Châu",
        "path": "Duy Châu, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Châu, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20614",
        "parent_code": "508"
    },
    "20617": {
        "name": "Duy Trinh",
        "type": "xa",
        "slug": "duy-trinh",
        "name_with_type": "Xã Duy Trinh",
        "path": "Duy Trinh, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Trinh, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20617",
        "parent_code": "508"
    },
    "20620": {
        "name": "Duy Sơn",
        "type": "xa",
        "slug": "duy-son",
        "name_with_type": "Xã Duy Sơn",
        "path": "Duy Sơn, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Sơn, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20620",
        "parent_code": "508"
    },
    "20623": {
        "name": "Duy Trung",
        "type": "xa",
        "slug": "duy-trung",
        "name_with_type": "Xã Duy Trung",
        "path": "Duy Trung, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Trung, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20623",
        "parent_code": "508"
    },
    "20626": {
        "name": "Duy Phước",
        "type": "xa",
        "slug": "duy-phuoc",
        "name_with_type": "Xã Duy Phước",
        "path": "Duy Phước, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Phước, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20626",
        "parent_code": "508"
    },
    "20629": {
        "name": "Duy Thành",
        "type": "xa",
        "slug": "duy-thanh",
        "name_with_type": "Xã Duy Thành",
        "path": "Duy Thành, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Thành, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20629",
        "parent_code": "508"
    },
    "20632": {
        "name": "Duy Vinh",
        "type": "xa",
        "slug": "duy-vinh",
        "name_with_type": "Xã Duy Vinh",
        "path": "Duy Vinh, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Vinh, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20632",
        "parent_code": "508"
    },
    "20635": {
        "name": "Duy Nghĩa",
        "type": "xa",
        "slug": "duy-nghia",
        "name_with_type": "Xã Duy Nghĩa",
        "path": "Duy Nghĩa, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Nghĩa, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20635",
        "parent_code": "508"
    },
    "20638": {
        "name": "Duy Hải",
        "type": "xa",
        "slug": "duy-hai",
        "name_with_type": "Xã Duy Hải",
        "path": "Duy Hải, Duy Xuyên, Quảng Nam",
        "path_with_type": "Xã Duy Hải, Huyện Duy Xuyên, Tỉnh Quảng Nam",
        "code": "20638",
        "parent_code": "508"
    },
    "20641": {
        "name": "Đông Phú",
        "type": "thi-tran",
        "slug": "dong-phu",
        "name_with_type": "Thị trấn Đông Phú",
        "path": "Đông Phú, Quế Sơn, Quảng Nam",
        "path_with_type": "Thị trấn Đông Phú, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20641",
        "parent_code": "509"
    },
    "20644": {
        "name": "Quế Xuân 1",
        "type": "xa",
        "slug": "que-xuan-1",
        "name_with_type": "Xã Quế Xuân 1",
        "path": "Quế Xuân 1, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Xuân 1, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20644",
        "parent_code": "509"
    },
    "20647": {
        "name": "Quế Xuân 2",
        "type": "xa",
        "slug": "que-xuan-2",
        "name_with_type": "Xã Quế Xuân 2",
        "path": "Quế Xuân 2, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Xuân 2, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20647",
        "parent_code": "509"
    },
    "20650": {
        "name": "Quế Phú",
        "type": "xa",
        "slug": "que-phu",
        "name_with_type": "Xã Quế Phú",
        "path": "Quế Phú, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Phú, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20650",
        "parent_code": "509"
    },
    "20651": {
        "name": "Hương An",
        "type": "xa",
        "slug": "huong-an",
        "name_with_type": "Xã Hương An",
        "path": "Hương An, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Hương An, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20651",
        "parent_code": "509"
    },
    "20653": {
        "name": "Quế Cường",
        "type": "xa",
        "slug": "que-cuong",
        "name_with_type": "Xã Quế Cường",
        "path": "Quế Cường, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Cường, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20653",
        "parent_code": "509"
    },
    "20659": {
        "name": "Quế Hiệp",
        "type": "xa",
        "slug": "que-hiep",
        "name_with_type": "Xã Quế Hiệp",
        "path": "Quế Hiệp, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Hiệp, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20659",
        "parent_code": "509"
    },
    "20662": {
        "name": "Quế Thuận",
        "type": "xa",
        "slug": "que-thuan",
        "name_with_type": "Xã Quế Thuận",
        "path": "Quế Thuận, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Thuận, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20662",
        "parent_code": "509"
    },
    "20665": {
        "name": "Phú Thọ",
        "type": "xa",
        "slug": "phu-tho",
        "name_with_type": "Xã Phú Thọ",
        "path": "Phú Thọ, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Phú Thọ, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20665",
        "parent_code": "509"
    },
    "20677": {
        "name": "Quế Long",
        "type": "xa",
        "slug": "que-long",
        "name_with_type": "Xã Quế Long",
        "path": "Quế Long, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Long, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20677",
        "parent_code": "509"
    },
    "20680": {
        "name": "Quế Châu",
        "type": "xa",
        "slug": "que-chau",
        "name_with_type": "Xã Quế Châu",
        "path": "Quế Châu, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Châu, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20680",
        "parent_code": "509"
    },
    "20683": {
        "name": "Quế Phong",
        "type": "xa",
        "slug": "que-phong",
        "name_with_type": "Xã Quế Phong",
        "path": "Quế Phong, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Phong, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20683",
        "parent_code": "509"
    },
    "20686": {
        "name": "Quế An",
        "type": "xa",
        "slug": "que-an",
        "name_with_type": "Xã Quế An",
        "path": "Quế An, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế An, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20686",
        "parent_code": "509"
    },
    "20689": {
        "name": "Quế Minh",
        "type": "xa",
        "slug": "que-minh",
        "name_with_type": "Xã Quế Minh",
        "path": "Quế Minh, Quế Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Minh, Huyện Quế Sơn, Tỉnh Quảng Nam",
        "code": "20689",
        "parent_code": "509"
    },
    "20695": {
        "name": "Thạnh Mỹ",
        "type": "thi-tran",
        "slug": "thanh-my",
        "name_with_type": "Thị trấn Thạnh Mỹ",
        "path": "Thạnh Mỹ, Nam Giang, Quảng Nam",
        "path_with_type": "Thị trấn Thạnh Mỹ, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20695",
        "parent_code": "510"
    },
    "20698": {
        "name": "Laêê",
        "type": "xa",
        "slug": "laee",
        "name_with_type": "Xã Laêê",
        "path": "Laêê, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Laêê, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20698",
        "parent_code": "510"
    },
    "20699": {
        "name": "Chơ Chun",
        "type": "xa",
        "slug": "cho-chun",
        "name_with_type": "Xã Chơ Chun",
        "path": "Chơ Chun, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Chơ Chun, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20699",
        "parent_code": "510"
    },
    "20701": {
        "name": "Zuôich",
        "type": "xa",
        "slug": "zuoich",
        "name_with_type": "Xã Zuôich",
        "path": "Zuôich, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Zuôich, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20701",
        "parent_code": "510"
    },
    "20702": {
        "name": "Tà Pơơ",
        "type": "xa",
        "slug": "ta-poo",
        "name_with_type": "Xã Tà Pơơ",
        "path": "Tà Pơơ, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Tà Pơơ, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20702",
        "parent_code": "510"
    },
    "20704": {
        "name": "La Dêê",
        "type": "xa",
        "slug": "la-dee",
        "name_with_type": "Xã La Dêê",
        "path": "La Dêê, Nam Giang, Quảng Nam",
        "path_with_type": "Xã La Dêê, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20704",
        "parent_code": "510"
    },
    "20705": {
        "name": "Đắc Tôi",
        "type": "xa",
        "slug": "dac-toi",
        "name_with_type": "Xã Đắc Tôi",
        "path": "Đắc Tôi, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Đắc Tôi, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20705",
        "parent_code": "510"
    },
    "20707": {
        "name": "Chà Vàl",
        "type": "xa",
        "slug": "cha-val",
        "name_with_type": "Xã Chà Vàl",
        "path": "Chà Vàl, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Chà Vàl, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20707",
        "parent_code": "510"
    },
    "20710": {
        "name": "Tà Bhinh",
        "type": "xa",
        "slug": "ta-bhinh",
        "name_with_type": "Xã Tà Bhinh",
        "path": "Tà Bhinh, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Tà Bhinh, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20710",
        "parent_code": "510"
    },
    "20713": {
        "name": "Cà Dy",
        "type": "xa",
        "slug": "ca-dy",
        "name_with_type": "Xã Cà Dy",
        "path": "Cà Dy, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Cà Dy, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20713",
        "parent_code": "510"
    },
    "20716": {
        "name": "Đắc Pre",
        "type": "xa",
        "slug": "dac-pre",
        "name_with_type": "Xã Đắc Pre",
        "path": "Đắc Pre, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Đắc Pre, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20716",
        "parent_code": "510"
    },
    "20719": {
        "name": "Đắc Pring",
        "type": "xa",
        "slug": "dac-pring",
        "name_with_type": "Xã Đắc Pring",
        "path": "Đắc Pring, Nam Giang, Quảng Nam",
        "path_with_type": "Xã Đắc Pring, Huyện Nam Giang, Tỉnh Quảng Nam",
        "code": "20719",
        "parent_code": "510"
    },
    "20722": {
        "name": "Khâm Đức",
        "type": "thi-tran",
        "slug": "kham-duc",
        "name_with_type": "Thị trấn Khâm Đức",
        "path": "Khâm Đức, Phước Sơn, Quảng Nam",
        "path_with_type": "Thị trấn Khâm Đức, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20722",
        "parent_code": "511"
    },
    "20725": {
        "name": "Phước Xuân",
        "type": "xa",
        "slug": "phuoc-xuan",
        "name_with_type": "Xã Phước Xuân",
        "path": "Phước Xuân, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Xuân, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20725",
        "parent_code": "511"
    },
    "20728": {
        "name": "Phước Hiệp",
        "type": "xa",
        "slug": "phuoc-hiep",
        "name_with_type": "Xã Phước Hiệp",
        "path": "Phước Hiệp, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Hiệp, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20728",
        "parent_code": "511"
    },
    "20729": {
        "name": "Phước Hoà",
        "type": "xa",
        "slug": "phuoc-hoa",
        "name_with_type": "Xã Phước Hoà",
        "path": "Phước Hoà, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Hoà, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20729",
        "parent_code": "511"
    },
    "20731": {
        "name": "Phước Đức",
        "type": "xa",
        "slug": "phuoc-duc",
        "name_with_type": "Xã Phước Đức",
        "path": "Phước Đức, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Đức, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20731",
        "parent_code": "511"
    },
    "20734": {
        "name": "Phước Năng",
        "type": "xa",
        "slug": "phuoc-nang",
        "name_with_type": "Xã Phước Năng",
        "path": "Phước Năng, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Năng, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20734",
        "parent_code": "511"
    },
    "20737": {
        "name": "Phước Mỹ",
        "type": "xa",
        "slug": "phuoc-my",
        "name_with_type": "Xã Phước Mỹ",
        "path": "Phước Mỹ, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Mỹ, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20737",
        "parent_code": "511"
    },
    "20740": {
        "name": "Phước Chánh",
        "type": "xa",
        "slug": "phuoc-chanh",
        "name_with_type": "Xã Phước Chánh",
        "path": "Phước Chánh, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Chánh, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20740",
        "parent_code": "511"
    },
    "20743": {
        "name": "Phước Công",
        "type": "xa",
        "slug": "phuoc-cong",
        "name_with_type": "Xã Phước Công",
        "path": "Phước Công, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Công, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20743",
        "parent_code": "511"
    },
    "20746": {
        "name": "Phước Kim",
        "type": "xa",
        "slug": "phuoc-kim",
        "name_with_type": "Xã Phước Kim",
        "path": "Phước Kim, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Kim, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20746",
        "parent_code": "511"
    },
    "20749": {
        "name": "Phước Lộc",
        "type": "xa",
        "slug": "phuoc-loc",
        "name_with_type": "Xã Phước Lộc",
        "path": "Phước Lộc, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Lộc, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20749",
        "parent_code": "511"
    },
    "20752": {
        "name": "Phước Thành",
        "type": "xa",
        "slug": "phuoc-thanh",
        "name_with_type": "Xã Phước Thành",
        "path": "Phước Thành, Phước Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Thành, Huyện Phước Sơn, Tỉnh Quảng Nam",
        "code": "20752",
        "parent_code": "511"
    },
    "20755": {
        "name": "Tân An",
        "type": "thi-tran",
        "slug": "tan-an",
        "name_with_type": "Thị trấn Tân An",
        "path": "Tân An, Hiệp Đức, Quảng Nam",
        "path_with_type": "Thị trấn Tân An, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20755",
        "parent_code": "512"
    },
    "20758": {
        "name": "Hiệp Hòa",
        "type": "xa",
        "slug": "hiep-hoa",
        "name_with_type": "Xã Hiệp Hòa",
        "path": "Hiệp Hòa, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Hiệp Hòa, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20758",
        "parent_code": "512"
    },
    "20761": {
        "name": "Hiệp Thuận",
        "type": "xa",
        "slug": "hiep-thuan",
        "name_with_type": "Xã Hiệp Thuận",
        "path": "Hiệp Thuận, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Hiệp Thuận, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20761",
        "parent_code": "512"
    },
    "20764": {
        "name": "Quế Thọ",
        "type": "xa",
        "slug": "que-tho",
        "name_with_type": "Xã Quế Thọ",
        "path": "Quế Thọ, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Quế Thọ, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20764",
        "parent_code": "512"
    },
    "20767": {
        "name": "Bình Lâm",
        "type": "xa",
        "slug": "binh-lam",
        "name_with_type": "Xã Bình Lâm",
        "path": "Bình Lâm, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Bình Lâm, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20767",
        "parent_code": "512"
    },
    "20770": {
        "name": "Sông Trà",
        "type": "xa",
        "slug": "song-tra",
        "name_with_type": "Xã Sông Trà",
        "path": "Sông Trà, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Sông Trà, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20770",
        "parent_code": "512"
    },
    "20773": {
        "name": "Phước Trà",
        "type": "xa",
        "slug": "phuoc-tra",
        "name_with_type": "Xã Phước Trà",
        "path": "Phước Trà, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Phước Trà, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20773",
        "parent_code": "512"
    },
    "20776": {
        "name": "Phước Gia",
        "type": "xa",
        "slug": "phuoc-gia",
        "name_with_type": "Xã Phước Gia",
        "path": "Phước Gia, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Phước Gia, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20776",
        "parent_code": "512"
    },
    "20779": {
        "name": "Quế Bình",
        "type": "xa",
        "slug": "que-binh",
        "name_with_type": "Xã Quế Bình",
        "path": "Quế Bình, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Quế Bình, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20779",
        "parent_code": "512"
    },
    "20782": {
        "name": "Quế Lưu",
        "type": "xa",
        "slug": "que-luu",
        "name_with_type": "Xã Quế Lưu",
        "path": "Quế Lưu, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Quế Lưu, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20782",
        "parent_code": "512"
    },
    "20785": {
        "name": "Thăng Phước",
        "type": "xa",
        "slug": "thang-phuoc",
        "name_with_type": "Xã Thăng Phước",
        "path": "Thăng Phước, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Thăng Phước, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20785",
        "parent_code": "512"
    },
    "20788": {
        "name": "Bình Sơn",
        "type": "xa",
        "slug": "binh-son",
        "name_with_type": "Xã Bình Sơn",
        "path": "Bình Sơn, Hiệp Đức, Quảng Nam",
        "path_with_type": "Xã Bình Sơn, Huyện Hiệp Đức, Tỉnh Quảng Nam",
        "code": "20788",
        "parent_code": "512"
    },
    "20791": {
        "name": "Hà Lam",
        "type": "thi-tran",
        "slug": "ha-lam",
        "name_with_type": "Thị trấn Hà Lam",
        "path": "Hà Lam, Thăng Bình, Quảng Nam",
        "path_with_type": "Thị trấn Hà Lam, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20791",
        "parent_code": "513"
    },
    "20794": {
        "name": "Bình Dương",
        "type": "xa",
        "slug": "binh-duong",
        "name_with_type": "Xã Bình Dương",
        "path": "Bình Dương, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Dương, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20794",
        "parent_code": "513"
    },
    "20797": {
        "name": "Bình Giang",
        "type": "xa",
        "slug": "binh-giang",
        "name_with_type": "Xã Bình Giang",
        "path": "Bình Giang, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Giang, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20797",
        "parent_code": "513"
    },
    "20800": {
        "name": "Bình Nguyên",
        "type": "xa",
        "slug": "binh-nguyen",
        "name_with_type": "Xã Bình Nguyên",
        "path": "Bình Nguyên, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Nguyên, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20800",
        "parent_code": "513"
    },
    "20803": {
        "name": "Bình Phục",
        "type": "xa",
        "slug": "binh-phuc",
        "name_with_type": "Xã Bình Phục",
        "path": "Bình Phục, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Phục, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20803",
        "parent_code": "513"
    },
    "20806": {
        "name": "Bình Triều",
        "type": "xa",
        "slug": "binh-trieu",
        "name_with_type": "Xã Bình Triều",
        "path": "Bình Triều, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Triều, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20806",
        "parent_code": "513"
    },
    "20809": {
        "name": "Bình Đào",
        "type": "xa",
        "slug": "binh-dao",
        "name_with_type": "Xã Bình Đào",
        "path": "Bình Đào, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Đào, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20809",
        "parent_code": "513"
    },
    "20812": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Minh, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20812",
        "parent_code": "513"
    },
    "20815": {
        "name": "Bình Lãnh",
        "type": "xa",
        "slug": "binh-lanh",
        "name_with_type": "Xã Bình Lãnh",
        "path": "Bình Lãnh, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Lãnh, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20815",
        "parent_code": "513"
    },
    "20818": {
        "name": "Bình Trị",
        "type": "xa",
        "slug": "binh-tri",
        "name_with_type": "Xã Bình Trị",
        "path": "Bình Trị, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Trị, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20818",
        "parent_code": "513"
    },
    "20821": {
        "name": "Bình Định Bắc",
        "type": "xa",
        "slug": "binh-dinh-bac",
        "name_with_type": "Xã Bình Định Bắc",
        "path": "Bình Định Bắc, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Định Bắc, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20821",
        "parent_code": "513"
    },
    "20822": {
        "name": "Bình Định Nam",
        "type": "xa",
        "slug": "binh-dinh-nam",
        "name_with_type": "Xã Bình Định Nam",
        "path": "Bình Định Nam, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Định Nam, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20822",
        "parent_code": "513"
    },
    "20824": {
        "name": "Bình Quý",
        "type": "xa",
        "slug": "binh-quy",
        "name_with_type": "Xã Bình Quý",
        "path": "Bình Quý, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Quý, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20824",
        "parent_code": "513"
    },
    "20827": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Phú, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20827",
        "parent_code": "513"
    },
    "20830": {
        "name": "Bình Chánh",
        "type": "xa",
        "slug": "binh-chanh",
        "name_with_type": "Xã Bình Chánh",
        "path": "Bình Chánh, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Chánh, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20830",
        "parent_code": "513"
    },
    "20833": {
        "name": "Bình Tú",
        "type": "xa",
        "slug": "binh-tu",
        "name_with_type": "Xã Bình Tú",
        "path": "Bình Tú, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Tú, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20833",
        "parent_code": "513"
    },
    "20836": {
        "name": "Bình Sa",
        "type": "xa",
        "slug": "binh-sa",
        "name_with_type": "Xã Bình Sa",
        "path": "Bình Sa, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Sa, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20836",
        "parent_code": "513"
    },
    "20839": {
        "name": "Bình Hải",
        "type": "xa",
        "slug": "binh-hai",
        "name_with_type": "Xã Bình Hải",
        "path": "Bình Hải, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Hải, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20839",
        "parent_code": "513"
    },
    "20842": {
        "name": "Bình Quế",
        "type": "xa",
        "slug": "binh-que",
        "name_with_type": "Xã Bình Quế",
        "path": "Bình Quế, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Quế, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20842",
        "parent_code": "513"
    },
    "20845": {
        "name": "Bình An",
        "type": "xa",
        "slug": "binh-an",
        "name_with_type": "Xã Bình An",
        "path": "Bình An, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình An, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20845",
        "parent_code": "513"
    },
    "20848": {
        "name": "Bình Trung",
        "type": "xa",
        "slug": "binh-trung",
        "name_with_type": "Xã Bình Trung",
        "path": "Bình Trung, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Trung, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20848",
        "parent_code": "513"
    },
    "20851": {
        "name": "Bình Nam",
        "type": "xa",
        "slug": "binh-nam",
        "name_with_type": "Xã Bình Nam",
        "path": "Bình Nam, Thăng Bình, Quảng Nam",
        "path_with_type": "Xã Bình Nam, Huyện Thăng Bình, Tỉnh Quảng Nam",
        "code": "20851",
        "parent_code": "513"
    },
    "20854": {
        "name": "Tiên Kỳ",
        "type": "thi-tran",
        "slug": "tien-ky",
        "name_with_type": "Thị trấn Tiên Kỳ",
        "path": "Tiên Kỳ, Tiên Phước, Quảng Nam",
        "path_with_type": "Thị trấn Tiên Kỳ, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20854",
        "parent_code": "514"
    },
    "20857": {
        "name": "Tiên Sơn",
        "type": "xa",
        "slug": "tien-son",
        "name_with_type": "Xã Tiên Sơn",
        "path": "Tiên Sơn, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Sơn, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20857",
        "parent_code": "514"
    },
    "20860": {
        "name": "Tiên Hà",
        "type": "xa",
        "slug": "tien-ha",
        "name_with_type": "Xã Tiên Hà",
        "path": "Tiên Hà, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Hà, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20860",
        "parent_code": "514"
    },
    "20863": {
        "name": "Tiên Cẩm",
        "type": "xa",
        "slug": "tien-cam",
        "name_with_type": "Xã Tiên Cẩm",
        "path": "Tiên Cẩm, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Cẩm, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20863",
        "parent_code": "514"
    },
    "20866": {
        "name": "Tiên Châu",
        "type": "xa",
        "slug": "tien-chau",
        "name_with_type": "Xã Tiên Châu",
        "path": "Tiên Châu, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Châu, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20866",
        "parent_code": "514"
    },
    "20869": {
        "name": "Tiên Lãnh",
        "type": "xa",
        "slug": "tien-lanh",
        "name_with_type": "Xã Tiên Lãnh",
        "path": "Tiên Lãnh, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Lãnh, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20869",
        "parent_code": "514"
    },
    "20872": {
        "name": "Tiên Ngọc",
        "type": "xa",
        "slug": "tien-ngoc",
        "name_with_type": "Xã Tiên Ngọc",
        "path": "Tiên Ngọc, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Ngọc, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20872",
        "parent_code": "514"
    },
    "20875": {
        "name": "Tiên Hiệp",
        "type": "xa",
        "slug": "tien-hiep",
        "name_with_type": "Xã Tiên Hiệp",
        "path": "Tiên Hiệp, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Hiệp, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20875",
        "parent_code": "514"
    },
    "20878": {
        "name": "Tiên Cảnh",
        "type": "xa",
        "slug": "tien-canh",
        "name_with_type": "Xã Tiên Cảnh",
        "path": "Tiên Cảnh, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Cảnh, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20878",
        "parent_code": "514"
    },
    "20881": {
        "name": "Tiên Mỹ",
        "type": "xa",
        "slug": "tien-my",
        "name_with_type": "Xã Tiên Mỹ",
        "path": "Tiên Mỹ, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Mỹ, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20881",
        "parent_code": "514"
    },
    "20884": {
        "name": "Tiên Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiên Phong",
        "path": "Tiên Phong, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Phong, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20884",
        "parent_code": "514"
    },
    "20887": {
        "name": "Tiên Thọ",
        "type": "xa",
        "slug": "tien-tho",
        "name_with_type": "Xã Tiên Thọ",
        "path": "Tiên Thọ, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Thọ, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20887",
        "parent_code": "514"
    },
    "20890": {
        "name": "Tiên An",
        "type": "xa",
        "slug": "tien-an",
        "name_with_type": "Xã Tiên An",
        "path": "Tiên An, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên An, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20890",
        "parent_code": "514"
    },
    "20893": {
        "name": "Tiên Lộc",
        "type": "xa",
        "slug": "tien-loc",
        "name_with_type": "Xã Tiên Lộc",
        "path": "Tiên Lộc, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Lộc, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20893",
        "parent_code": "514"
    },
    "20896": {
        "name": "Tiên Lập",
        "type": "xa",
        "slug": "tien-lap",
        "name_with_type": "Xã Tiên Lập",
        "path": "Tiên Lập, Tiên Phước, Quảng Nam",
        "path_with_type": "Xã Tiên Lập, Huyện Tiên Phước, Tỉnh Quảng Nam",
        "code": "20896",
        "parent_code": "514"
    },
    "20899": {
        "name": "Trà My",
        "type": "thi-tran",
        "slug": "tra-my",
        "name_with_type": "Thị trấn Trà My",
        "path": "Trà My, Bắc Trà My, Quảng Nam",
        "path_with_type": "Thị trấn Trà My, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20899",
        "parent_code": "515"
    },
    "20900": {
        "name": "Trà Sơn",
        "type": "xa",
        "slug": "tra-son",
        "name_with_type": "Xã Trà Sơn",
        "path": "Trà Sơn, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Sơn, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20900",
        "parent_code": "515"
    },
    "20902": {
        "name": "Trà Kót",
        "type": "xa",
        "slug": "tra-kot",
        "name_with_type": "Xã Trà Kót",
        "path": "Trà Kót, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Kót, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20902",
        "parent_code": "515"
    },
    "20905": {
        "name": "Trà Nú",
        "type": "xa",
        "slug": "tra-nu",
        "name_with_type": "Xã Trà Nú",
        "path": "Trà Nú, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Nú, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20905",
        "parent_code": "515"
    },
    "20908": {
        "name": "Trà Đông",
        "type": "xa",
        "slug": "tra-dong",
        "name_with_type": "Xã Trà Đông",
        "path": "Trà Đông, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Đông, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20908",
        "parent_code": "515"
    },
    "20911": {
        "name": "Trà Dương",
        "type": "xa",
        "slug": "tra-duong",
        "name_with_type": "Xã Trà Dương",
        "path": "Trà Dương, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Dương, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20911",
        "parent_code": "515"
    },
    "20914": {
        "name": "Trà Giang",
        "type": "xa",
        "slug": "tra-giang",
        "name_with_type": "Xã Trà Giang",
        "path": "Trà Giang, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Giang, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20914",
        "parent_code": "515"
    },
    "20917": {
        "name": "Trà Bui",
        "type": "xa",
        "slug": "tra-bui",
        "name_with_type": "Xã Trà Bui",
        "path": "Trà Bui, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Bui, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20917",
        "parent_code": "515"
    },
    "20920": {
        "name": "Trà Đốc",
        "type": "xa",
        "slug": "tra-doc",
        "name_with_type": "Xã Trà Đốc",
        "path": "Trà Đốc, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Đốc, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20920",
        "parent_code": "515"
    },
    "20923": {
        "name": "Trà Tân",
        "type": "xa",
        "slug": "tra-tan",
        "name_with_type": "Xã Trà Tân",
        "path": "Trà Tân, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Tân, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20923",
        "parent_code": "515"
    },
    "20926": {
        "name": "Trà Giác",
        "type": "xa",
        "slug": "tra-giac",
        "name_with_type": "Xã Trà Giác",
        "path": "Trà Giác, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Giác, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20926",
        "parent_code": "515"
    },
    "20929": {
        "name": "Trà Giáp",
        "type": "xa",
        "slug": "tra-giap",
        "name_with_type": "Xã Trà Giáp",
        "path": "Trà Giáp, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Giáp, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20929",
        "parent_code": "515"
    },
    "20932": {
        "name": "Trà Ka",
        "type": "xa",
        "slug": "tra-ka",
        "name_with_type": "Xã Trà Ka",
        "path": "Trà Ka, Bắc Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Ka, Huyện Bắc Trà My, Tỉnh Quảng Nam",
        "code": "20932",
        "parent_code": "515"
    },
    "20935": {
        "name": "Trà Leng",
        "type": "xa",
        "slug": "tra-leng",
        "name_with_type": "Xã Trà Leng",
        "path": "Trà Leng, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Leng, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20935",
        "parent_code": "516"
    },
    "20938": {
        "name": "Trà Dơn",
        "type": "xa",
        "slug": "tra-don",
        "name_with_type": "Xã Trà Dơn",
        "path": "Trà Dơn, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Dơn, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20938",
        "parent_code": "516"
    },
    "20941": {
        "name": "Trà Tập",
        "type": "xa",
        "slug": "tra-tap",
        "name_with_type": "Xã Trà Tập",
        "path": "Trà Tập, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Tập, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20941",
        "parent_code": "516"
    },
    "20944": {
        "name": "Trà Mai",
        "type": "xa",
        "slug": "tra-mai",
        "name_with_type": "Xã Trà Mai",
        "path": "Trà Mai, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Mai, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20944",
        "parent_code": "516"
    },
    "20947": {
        "name": "Trà Cang",
        "type": "xa",
        "slug": "tra-cang",
        "name_with_type": "Xã Trà Cang",
        "path": "Trà Cang, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Cang, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20947",
        "parent_code": "516"
    },
    "20950": {
        "name": "Trà Linh",
        "type": "xa",
        "slug": "tra-linh",
        "name_with_type": "Xã Trà Linh",
        "path": "Trà Linh, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Linh, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20950",
        "parent_code": "516"
    },
    "20953": {
        "name": "Trà Nam",
        "type": "xa",
        "slug": "tra-nam",
        "name_with_type": "Xã Trà Nam",
        "path": "Trà Nam, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Nam, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20953",
        "parent_code": "516"
    },
    "20956": {
        "name": "Trà Don",
        "type": "xa",
        "slug": "tra-don",
        "name_with_type": "Xã Trà Don",
        "path": "Trà Don, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Don, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20956",
        "parent_code": "516"
    },
    "20959": {
        "name": "Trà Vân",
        "type": "xa",
        "slug": "tra-van",
        "name_with_type": "Xã Trà Vân",
        "path": "Trà Vân, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Vân, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20959",
        "parent_code": "516"
    },
    "20962": {
        "name": "Trà Vinh",
        "type": "xa",
        "slug": "tra-vinh",
        "name_with_type": "Xã Trà Vinh",
        "path": "Trà Vinh, Nam Trà My, Quảng Nam",
        "path_with_type": "Xã Trà Vinh, Huyện Nam Trà My, Tỉnh Quảng Nam",
        "code": "20962",
        "parent_code": "516"
    },
    "20965": {
        "name": "Núi Thành",
        "type": "thi-tran",
        "slug": "nui-thanh",
        "name_with_type": "Thị trấn Núi Thành",
        "path": "Núi Thành, Núi Thành, Quảng Nam",
        "path_with_type": "Thị trấn Núi Thành, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20965",
        "parent_code": "517"
    },
    "20968": {
        "name": "Tam Xuân I",
        "type": "xa",
        "slug": "tam-xuan-i",
        "name_with_type": "Xã Tam Xuân I",
        "path": "Tam Xuân I, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Xuân I, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20968",
        "parent_code": "517"
    },
    "20971": {
        "name": "Tam Xuân II",
        "type": "xa",
        "slug": "tam-xuan-ii",
        "name_with_type": "Xã Tam Xuân II",
        "path": "Tam Xuân II, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Xuân II, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20971",
        "parent_code": "517"
    },
    "20974": {
        "name": "Tam Tiến",
        "type": "xa",
        "slug": "tam-tien",
        "name_with_type": "Xã Tam Tiến",
        "path": "Tam Tiến, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Tiến, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20974",
        "parent_code": "517"
    },
    "20977": {
        "name": "Tam Sơn",
        "type": "xa",
        "slug": "tam-son",
        "name_with_type": "Xã Tam Sơn",
        "path": "Tam Sơn, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Sơn, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20977",
        "parent_code": "517"
    },
    "20980": {
        "name": "Tam Thạnh",
        "type": "xa",
        "slug": "tam-thanh",
        "name_with_type": "Xã Tam Thạnh",
        "path": "Tam Thạnh, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Thạnh, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20980",
        "parent_code": "517"
    },
    "20983": {
        "name": "Tam Anh Bắc",
        "type": "xa",
        "slug": "tam-anh-bac",
        "name_with_type": "Xã Tam Anh Bắc",
        "path": "Tam Anh Bắc, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Anh Bắc, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20983",
        "parent_code": "517"
    },
    "20984": {
        "name": "Tam Anh Nam",
        "type": "xa",
        "slug": "tam-anh-nam",
        "name_with_type": "Xã Tam Anh Nam",
        "path": "Tam Anh Nam, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Anh Nam, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20984",
        "parent_code": "517"
    },
    "20986": {
        "name": "Tam Hòa",
        "type": "xa",
        "slug": "tam-hoa",
        "name_with_type": "Xã Tam Hòa",
        "path": "Tam Hòa, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Hòa, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20986",
        "parent_code": "517"
    },
    "20989": {
        "name": "Tam Hiệp",
        "type": "xa",
        "slug": "tam-hiep",
        "name_with_type": "Xã Tam Hiệp",
        "path": "Tam Hiệp, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Hiệp, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20989",
        "parent_code": "517"
    },
    "20992": {
        "name": "Tam Hải",
        "type": "xa",
        "slug": "tam-hai",
        "name_with_type": "Xã Tam Hải",
        "path": "Tam Hải, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Hải, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20992",
        "parent_code": "517"
    },
    "20995": {
        "name": "Tam Giang",
        "type": "xa",
        "slug": "tam-giang",
        "name_with_type": "Xã Tam Giang",
        "path": "Tam Giang, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Giang, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20995",
        "parent_code": "517"
    },
    "20998": {
        "name": "Tam Quang",
        "type": "xa",
        "slug": "tam-quang",
        "name_with_type": "Xã Tam Quang",
        "path": "Tam Quang, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Quang, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "20998",
        "parent_code": "517"
    },
    "21001": {
        "name": "Tam Nghĩa",
        "type": "xa",
        "slug": "tam-nghia",
        "name_with_type": "Xã Tam Nghĩa",
        "path": "Tam Nghĩa, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Nghĩa, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "21001",
        "parent_code": "517"
    },
    "21004": {
        "name": "Tam Mỹ Tây",
        "type": "xa",
        "slug": "tam-my-tay",
        "name_with_type": "Xã Tam Mỹ Tây",
        "path": "Tam Mỹ Tây, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Mỹ Tây, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "21004",
        "parent_code": "517"
    },
    "21005": {
        "name": "Tam Mỹ Đông",
        "type": "xa",
        "slug": "tam-my-dong",
        "name_with_type": "Xã Tam Mỹ Đông",
        "path": "Tam Mỹ Đông, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Mỹ Đông, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "21005",
        "parent_code": "517"
    },
    "21007": {
        "name": "Tam Trà",
        "type": "xa",
        "slug": "tam-tra",
        "name_with_type": "Xã Tam Trà",
        "path": "Tam Trà, Núi Thành, Quảng Nam",
        "path_with_type": "Xã Tam Trà, Huyện Núi Thành, Tỉnh Quảng Nam",
        "code": "21007",
        "parent_code": "517"
    },
    "20364": {
        "name": "Phú Thịnh",
        "type": "thi-tran",
        "slug": "phu-thinh",
        "name_with_type": "Thị trấn Phú Thịnh",
        "path": "Phú Thịnh, Phú Ninh, Quảng Nam",
        "path_with_type": "Thị trấn Phú Thịnh, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20364",
        "parent_code": "518"
    },
    "20365": {
        "name": "Tam Thành",
        "type": "xa",
        "slug": "tam-thanh",
        "name_with_type": "Xã Tam Thành",
        "path": "Tam Thành, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam Thành, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20365",
        "parent_code": "518"
    },
    "20368": {
        "name": "Tam An",
        "type": "xa",
        "slug": "tam-an",
        "name_with_type": "Xã Tam An",
        "path": "Tam An, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam An, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20368",
        "parent_code": "518"
    },
    "20374": {
        "name": "Tam Đàn",
        "type": "xa",
        "slug": "tam-dan",
        "name_with_type": "Xã Tam Đàn",
        "path": "Tam Đàn, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam Đàn, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20374",
        "parent_code": "518"
    },
    "20377": {
        "name": "Tam Lộc",
        "type": "xa",
        "slug": "tam-loc",
        "name_with_type": "Xã Tam Lộc",
        "path": "Tam Lộc, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam Lộc, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20377",
        "parent_code": "518"
    },
    "20380": {
        "name": "Tam Phước",
        "type": "xa",
        "slug": "tam-phuoc",
        "name_with_type": "Xã Tam Phước",
        "path": "Tam Phước, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam Phước, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20380",
        "parent_code": "518"
    },
    "20383": {
        "name": "Tam Vinh",
        "type": "xa",
        "slug": "tam-vinh",
        "name_with_type": "Xã Tam Vinh",
        "path": "Tam Vinh, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam Vinh, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20383",
        "parent_code": "518"
    },
    "20386": {
        "name": "Tam Thái",
        "type": "xa",
        "slug": "tam-thai",
        "name_with_type": "Xã Tam Thái",
        "path": "Tam Thái, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam Thái, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20386",
        "parent_code": "518"
    },
    "20387": {
        "name": "Tam Đại",
        "type": "xa",
        "slug": "tam-dai",
        "name_with_type": "Xã Tam Đại",
        "path": "Tam Đại, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam Đại, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20387",
        "parent_code": "518"
    },
    "20392": {
        "name": "Tam Dân",
        "type": "xa",
        "slug": "tam-dan",
        "name_with_type": "Xã Tam Dân",
        "path": "Tam Dân, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam Dân, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20392",
        "parent_code": "518"
    },
    "20395": {
        "name": "Tam Lãnh",
        "type": "xa",
        "slug": "tam-lanh",
        "name_with_type": "Xã Tam Lãnh",
        "path": "Tam Lãnh, Phú Ninh, Quảng Nam",
        "path_with_type": "Xã Tam Lãnh, Huyện Phú Ninh, Tỉnh Quảng Nam",
        "code": "20395",
        "parent_code": "518"
    },
    "20656": {
        "name": "Quế Trung",
        "type": "xa",
        "slug": "que-trung",
        "name_with_type": "Xã Quế Trung",
        "path": "Quế Trung, Nông Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Trung, Huyện Nông Sơn, Tỉnh Quảng Nam",
        "code": "20656",
        "parent_code": "519"
    },
    "20668": {
        "name": "Quế Ninh",
        "type": "xa",
        "slug": "que-ninh",
        "name_with_type": "Xã Quế Ninh",
        "path": "Quế Ninh, Nông Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Ninh, Huyện Nông Sơn, Tỉnh Quảng Nam",
        "code": "20668",
        "parent_code": "519"
    },
    "20669": {
        "name": "Phước Ninh",
        "type": "xa",
        "slug": "phuoc-ninh",
        "name_with_type": "Xã Phước Ninh",
        "path": "Phước Ninh, Nông Sơn, Quảng Nam",
        "path_with_type": "Xã Phước Ninh, Huyện Nông Sơn, Tỉnh Quảng Nam",
        "code": "20669",
        "parent_code": "519"
    },
    "20671": {
        "name": "Quế Lộc",
        "type": "xa",
        "slug": "que-loc",
        "name_with_type": "Xã Quế Lộc",
        "path": "Quế Lộc, Nông Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Lộc, Huyện Nông Sơn, Tỉnh Quảng Nam",
        "code": "20671",
        "parent_code": "519"
    },
    "20672": {
        "name": "Sơn Viên",
        "type": "xa",
        "slug": "son-vien",
        "name_with_type": "Xã Sơn Viên",
        "path": "Sơn Viên, Nông Sơn, Quảng Nam",
        "path_with_type": "Xã Sơn Viên, Huyện Nông Sơn, Tỉnh Quảng Nam",
        "code": "20672",
        "parent_code": "519"
    },
    "20674": {
        "name": "Quế Phước",
        "type": "xa",
        "slug": "que-phuoc",
        "name_with_type": "Xã Quế Phước",
        "path": "Quế Phước, Nông Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Phước, Huyện Nông Sơn, Tỉnh Quảng Nam",
        "code": "20674",
        "parent_code": "519"
    },
    "20692": {
        "name": "Quế Lâm",
        "type": "xa",
        "slug": "que-lam",
        "name_with_type": "Xã Quế Lâm",
        "path": "Quế Lâm, Nông Sơn, Quảng Nam",
        "path_with_type": "Xã Quế Lâm, Huyện Nông Sơn, Tỉnh Quảng Nam",
        "code": "20692",
        "parent_code": "519"
    },
    "24118": {
        "name": "Tân Lập",
        "type": "phuong",
        "slug": "tan-lap",
        "name_with_type": "Phường Tân Lập",
        "path": "Tân Lập, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Tân Lập, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24118",
        "parent_code": "643"
    },
    "24121": {
        "name": "Tân Hòa",
        "type": "phuong",
        "slug": "tan-hoa",
        "name_with_type": "Phường Tân Hòa",
        "path": "Tân Hòa, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Tân Hòa, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24121",
        "parent_code": "643"
    },
    "24124": {
        "name": "Tân An",
        "type": "phuong",
        "slug": "tan-an",
        "name_with_type": "Phường Tân An",
        "path": "Tân An, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Tân An, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24124",
        "parent_code": "643"
    },
    "24127": {
        "name": "Thống Nhất",
        "type": "phuong",
        "slug": "thong-nhat",
        "name_with_type": "Phường Thống Nhất",
        "path": "Thống Nhất, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Thống Nhất, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24127",
        "parent_code": "643"
    },
    "24130": {
        "name": "Thành Nhất",
        "type": "phuong",
        "slug": "thanh-nhat",
        "name_with_type": "Phường Thành Nhất",
        "path": "Thành Nhất, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Thành Nhất, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24130",
        "parent_code": "643"
    },
    "24133": {
        "name": "Thắng Lợi",
        "type": "phuong",
        "slug": "thang-loi",
        "name_with_type": "Phường Thắng Lợi",
        "path": "Thắng Lợi, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Thắng Lợi, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24133",
        "parent_code": "643"
    },
    "24136": {
        "name": "Tân Lợi",
        "type": "phuong",
        "slug": "tan-loi",
        "name_with_type": "Phường Tân Lợi",
        "path": "Tân Lợi, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Tân Lợi, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24136",
        "parent_code": "643"
    },
    "24139": {
        "name": "Thành Công",
        "type": "phuong",
        "slug": "thanh-cong",
        "name_with_type": "Phường Thành Công",
        "path": "Thành Công, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Thành Công, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24139",
        "parent_code": "643"
    },
    "24142": {
        "name": "Tân Thành",
        "type": "phuong",
        "slug": "tan-thanh",
        "name_with_type": "Phường Tân Thành",
        "path": "Tân Thành, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Tân Thành, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24142",
        "parent_code": "643"
    },
    "24145": {
        "name": "Tân Tiến",
        "type": "phuong",
        "slug": "tan-tien",
        "name_with_type": "Phường Tân Tiến",
        "path": "Tân Tiến, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Tân Tiến, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24145",
        "parent_code": "643"
    },
    "24148": {
        "name": "Tự An",
        "type": "phuong",
        "slug": "tu-an",
        "name_with_type": "Phường Tự An",
        "path": "Tự An, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Tự An, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24148",
        "parent_code": "643"
    },
    "24151": {
        "name": "Ea Tam",
        "type": "phuong",
        "slug": "ea-tam",
        "name_with_type": "Phường Ea Tam",
        "path": "Ea Tam, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Ea Tam, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24151",
        "parent_code": "643"
    },
    "24154": {
        "name": "Khánh Xuân",
        "type": "phuong",
        "slug": "khanh-xuan",
        "name_with_type": "Phường Khánh Xuân",
        "path": "Khánh Xuân, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Phường Khánh Xuân, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24154",
        "parent_code": "643"
    },
    "24157": {
        "name": "Hòa Thuận",
        "type": "xa",
        "slug": "hoa-thuan",
        "name_with_type": "Xã Hòa Thuận",
        "path": "Hòa Thuận, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Xã Hòa Thuận, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24157",
        "parent_code": "643"
    },
    "24160": {
        "name": "Cư ÊBur",
        "type": "xa",
        "slug": "cu-ebur",
        "name_with_type": "Xã Cư ÊBur",
        "path": "Cư ÊBur, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Xã Cư ÊBur, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24160",
        "parent_code": "643"
    },
    "24163": {
        "name": "Ea Tu",
        "type": "xa",
        "slug": "ea-tu",
        "name_with_type": "Xã Ea Tu",
        "path": "Ea Tu, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Xã Ea Tu, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24163",
        "parent_code": "643"
    },
    "24166": {
        "name": "Hòa Thắng",
        "type": "xa",
        "slug": "hoa-thang",
        "name_with_type": "Xã Hòa Thắng",
        "path": "Hòa Thắng, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Xã Hòa Thắng, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24166",
        "parent_code": "643"
    },
    "24169": {
        "name": "Ea Kao",
        "type": "xa",
        "slug": "ea-kao",
        "name_with_type": "Xã Ea Kao",
        "path": "Ea Kao, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Xã Ea Kao, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24169",
        "parent_code": "643"
    },
    "24172": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Xã Hòa Phú, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24172",
        "parent_code": "643"
    },
    "24175": {
        "name": "Hòa Khánh",
        "type": "xa",
        "slug": "hoa-khanh",
        "name_with_type": "Xã Hòa Khánh",
        "path": "Hòa Khánh, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Xã Hòa Khánh, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24175",
        "parent_code": "643"
    },
    "24178": {
        "name": "Hòa Xuân",
        "type": "xa",
        "slug": "hoa-xuan",
        "name_with_type": "Xã Hòa Xuân",
        "path": "Hòa Xuân, Buôn Ma Thuột, Đắk Lắk",
        "path_with_type": "Xã Hòa Xuân, Thành phố Buôn Ma Thuột, Tỉnh Đắk Lắk",
        "code": "24178",
        "parent_code": "643"
    },
    "24305": {
        "name": "An Lạc",
        "type": "phuong",
        "slug": "an-lac",
        "name_with_type": "Phường An Lạc",
        "path": "An Lạc, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Phường An Lạc, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24305",
        "parent_code": "644"
    },
    "24308": {
        "name": "An Bình",
        "type": "phuong",
        "slug": "an-binh",
        "name_with_type": "Phường An Bình",
        "path": "An Bình, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Phường An Bình, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24308",
        "parent_code": "644"
    },
    "24311": {
        "name": "Thiện An",
        "type": "phuong",
        "slug": "thien-an",
        "name_with_type": "Phường Thiện An",
        "path": "Thiện An, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Phường Thiện An, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24311",
        "parent_code": "644"
    },
    "24318": {
        "name": "Đạt Hiếu",
        "type": "phuong",
        "slug": "dat-hieu",
        "name_with_type": "Phường Đạt Hiếu",
        "path": "Đạt Hiếu, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Phường Đạt Hiếu, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24318",
        "parent_code": "644"
    },
    "24322": {
        "name": "Đoàn Kết",
        "type": "phuong",
        "slug": "doan-ket",
        "name_with_type": "Phường Đoàn Kết",
        "path": "Đoàn Kết, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Phường Đoàn Kết, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24322",
        "parent_code": "644"
    },
    "24325": {
        "name": "Ea Blang",
        "type": "xa",
        "slug": "ea-blang",
        "name_with_type": "Xã Ea Blang",
        "path": "Ea Blang, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Xã Ea Blang, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24325",
        "parent_code": "644"
    },
    "24328": {
        "name": "Ea Drông",
        "type": "xa",
        "slug": "ea-drong",
        "name_with_type": "Xã Ea Drông",
        "path": "Ea Drông, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Xã Ea Drông, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24328",
        "parent_code": "644"
    },
    "24331": {
        "name": "Thống Nhất",
        "type": "phuong",
        "slug": "thong-nhat",
        "name_with_type": "Phường Thống Nhất",
        "path": "Thống Nhất, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Phường Thống Nhất, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24331",
        "parent_code": "644"
    },
    "24332": {
        "name": "Bình Tân",
        "type": "phuong",
        "slug": "binh-tan",
        "name_with_type": "Phường Bình Tân",
        "path": "Bình Tân, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Phường Bình Tân, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24332",
        "parent_code": "644"
    },
    "24334": {
        "name": "Ea Siên",
        "type": "xa",
        "slug": "ea-sien",
        "name_with_type": "Xã Ea Siên",
        "path": "Ea Siên, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Xã Ea Siên, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24334",
        "parent_code": "644"
    },
    "24337": {
        "name": "Bình Thuận",
        "type": "xa",
        "slug": "binh-thuan",
        "name_with_type": "Xã Bình Thuận",
        "path": "Bình Thuận, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Xã Bình Thuận, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24337",
        "parent_code": "644"
    },
    "24340": {
        "name": "Cư Bao",
        "type": "xa",
        "slug": "cu-bao",
        "name_with_type": "Xã Cư Bao",
        "path": "Cư Bao, Buôn Hồ, Đắk Lắk",
        "path_with_type": "Xã Cư Bao, Thị xã Buôn Hồ, Tỉnh Đắk Lắk",
        "code": "24340",
        "parent_code": "644"
    },
    "24181": {
        "name": "Ea Drăng",
        "type": "thi-tran",
        "slug": "ea-drang",
        "name_with_type": "Thị trấn Ea Drăng",
        "path": "Ea Drăng, Ea H'leo, Đắk Lắk",
        "path_with_type": "Thị trấn Ea Drăng, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24181",
        "parent_code": "645"
    },
    "24184": {
        "name": "Ea H'leo",
        "type": "xa",
        "slug": "ea-h-leo",
        "name_with_type": "Xã Ea H'leo",
        "path": "Ea H'leo, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Ea H'leo, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24184",
        "parent_code": "645"
    },
    "24187": {
        "name": "Ea Sol",
        "type": "xa",
        "slug": "ea-sol",
        "name_with_type": "Xã Ea Sol",
        "path": "Ea Sol, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Ea Sol, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24187",
        "parent_code": "645"
    },
    "24190": {
        "name": "Ea Ral",
        "type": "xa",
        "slug": "ea-ral",
        "name_with_type": "Xã Ea Ral",
        "path": "Ea Ral, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Ea Ral, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24190",
        "parent_code": "645"
    },
    "24193": {
        "name": "Ea Wy",
        "type": "xa",
        "slug": "ea-wy",
        "name_with_type": "Xã Ea Wy",
        "path": "Ea Wy, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Ea Wy, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24193",
        "parent_code": "645"
    },
    "24194": {
        "name": "Cư A Mung",
        "type": "xa",
        "slug": "cu-a-mung",
        "name_with_type": "Xã Cư A Mung",
        "path": "Cư A Mung, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Cư A Mung, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24194",
        "parent_code": "645"
    },
    "24196": {
        "name": "Cư Mốt",
        "type": "xa",
        "slug": "cu-mot",
        "name_with_type": "Xã Cư Mốt",
        "path": "Cư Mốt, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Cư Mốt, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24196",
        "parent_code": "645"
    },
    "24199": {
        "name": "Ea Hiao",
        "type": "xa",
        "slug": "ea-hiao",
        "name_with_type": "Xã Ea Hiao",
        "path": "Ea Hiao, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Ea Hiao, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24199",
        "parent_code": "645"
    },
    "24202": {
        "name": "Ea Khal",
        "type": "xa",
        "slug": "ea-khal",
        "name_with_type": "Xã Ea Khal",
        "path": "Ea Khal, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Ea Khal, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24202",
        "parent_code": "645"
    },
    "24205": {
        "name": "Dlê Yang",
        "type": "xa",
        "slug": "dle-yang",
        "name_with_type": "Xã Dlê Yang",
        "path": "Dlê Yang, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Dlê Yang, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24205",
        "parent_code": "645"
    },
    "24207": {
        "name": "Ea Tir",
        "type": "xa",
        "slug": "ea-tir",
        "name_with_type": "Xã Ea Tir",
        "path": "Ea Tir, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Ea Tir, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24207",
        "parent_code": "645"
    },
    "24208": {
        "name": "Ea Nam",
        "type": "xa",
        "slug": "ea-nam",
        "name_with_type": "Xã Ea Nam",
        "path": "Ea Nam, Ea H'leo, Đắk Lắk",
        "path_with_type": "Xã Ea Nam, Huyện Ea H'leo, Tỉnh Đắk Lắk",
        "code": "24208",
        "parent_code": "645"
    },
    "24211": {
        "name": "Ea Súp",
        "type": "thi-tran",
        "slug": "ea-sup",
        "name_with_type": "Thị trấn Ea Súp",
        "path": "Ea Súp, Ea Súp, Đắk Lắk",
        "path_with_type": "Thị trấn Ea Súp, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24211",
        "parent_code": "646"
    },
    "24214": {
        "name": "Ia Lốp",
        "type": "xa",
        "slug": "ia-lop",
        "name_with_type": "Xã Ia Lốp",
        "path": "Ia Lốp, Ea Súp, Đắk Lắk",
        "path_with_type": "Xã Ia Lốp, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24214",
        "parent_code": "646"
    },
    "24215": {
        "name": "Ia JLơi",
        "type": "xa",
        "slug": "ia-jloi",
        "name_with_type": "Xã Ia JLơi",
        "path": "Ia JLơi, Ea Súp, Đắk Lắk",
        "path_with_type": "Xã Ia JLơi, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24215",
        "parent_code": "646"
    },
    "24217": {
        "name": "Ea Rốk",
        "type": "xa",
        "slug": "ea-rok",
        "name_with_type": "Xã Ea Rốk",
        "path": "Ea Rốk, Ea Súp, Đắk Lắk",
        "path_with_type": "Xã Ea Rốk, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24217",
        "parent_code": "646"
    },
    "24220": {
        "name": "Ya Tờ Mốt",
        "type": "xa",
        "slug": "ya-to-mot",
        "name_with_type": "Xã Ya Tờ Mốt",
        "path": "Ya Tờ Mốt, Ea Súp, Đắk Lắk",
        "path_with_type": "Xã Ya Tờ Mốt, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24220",
        "parent_code": "646"
    },
    "24221": {
        "name": "Ia RVê",
        "type": "xa",
        "slug": "ia-rve",
        "name_with_type": "Xã Ia RVê",
        "path": "Ia RVê, Ea Súp, Đắk Lắk",
        "path_with_type": "Xã Ia RVê, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24221",
        "parent_code": "646"
    },
    "24223": {
        "name": "Ea Lê",
        "type": "xa",
        "slug": "ea-le",
        "name_with_type": "Xã Ea Lê",
        "path": "Ea Lê, Ea Súp, Đắk Lắk",
        "path_with_type": "Xã Ea Lê, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24223",
        "parent_code": "646"
    },
    "24226": {
        "name": "Cư KBang",
        "type": "xa",
        "slug": "cu-kbang",
        "name_with_type": "Xã Cư KBang",
        "path": "Cư KBang, Ea Súp, Đắk Lắk",
        "path_with_type": "Xã Cư KBang, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24226",
        "parent_code": "646"
    },
    "24229": {
        "name": "Ea Bung",
        "type": "xa",
        "slug": "ea-bung",
        "name_with_type": "Xã Ea Bung",
        "path": "Ea Bung, Ea Súp, Đắk Lắk",
        "path_with_type": "Xã Ea Bung, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24229",
        "parent_code": "646"
    },
    "24232": {
        "name": "Cư M'Lan",
        "type": "xa",
        "slug": "cu-m-lan",
        "name_with_type": "Xã Cư M'Lan",
        "path": "Cư M'Lan, Ea Súp, Đắk Lắk",
        "path_with_type": "Xã Cư M'Lan, Huyện Ea Súp, Tỉnh Đắk Lắk",
        "code": "24232",
        "parent_code": "646"
    },
    "24235": {
        "name": "Krông Na",
        "type": "xa",
        "slug": "krong-na",
        "name_with_type": "Xã Krông Na",
        "path": "Krông Na, Buôn Đôn, Đắk Lắk",
        "path_with_type": "Xã Krông Na, Huyện Buôn Đôn, Tỉnh Đắk Lắk",
        "code": "24235",
        "parent_code": "647"
    },
    "24238": {
        "name": "Ea Huar",
        "type": "xa",
        "slug": "ea-huar",
        "name_with_type": "Xã Ea Huar",
        "path": "Ea Huar, Buôn Đôn, Đắk Lắk",
        "path_with_type": "Xã Ea Huar, Huyện Buôn Đôn, Tỉnh Đắk Lắk",
        "code": "24238",
        "parent_code": "647"
    },
    "24241": {
        "name": "Ea Wer",
        "type": "xa",
        "slug": "ea-wer",
        "name_with_type": "Xã Ea Wer",
        "path": "Ea Wer, Buôn Đôn, Đắk Lắk",
        "path_with_type": "Xã Ea Wer, Huyện Buôn Đôn, Tỉnh Đắk Lắk",
        "code": "24241",
        "parent_code": "647"
    },
    "24244": {
        "name": "Tân Hoà",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hoà",
        "path": "Tân Hoà, Buôn Đôn, Đắk Lắk",
        "path_with_type": "Xã Tân Hoà, Huyện Buôn Đôn, Tỉnh Đắk Lắk",
        "code": "24244",
        "parent_code": "647"
    },
    "24247": {
        "name": "Cuôr KNia",
        "type": "xa",
        "slug": "cuor-knia",
        "name_with_type": "Xã Cuôr KNia",
        "path": "Cuôr KNia, Buôn Đôn, Đắk Lắk",
        "path_with_type": "Xã Cuôr KNia, Huyện Buôn Đôn, Tỉnh Đắk Lắk",
        "code": "24247",
        "parent_code": "647"
    },
    "24250": {
        "name": "Ea Bar",
        "type": "xa",
        "slug": "ea-bar",
        "name_with_type": "Xã Ea Bar",
        "path": "Ea Bar, Buôn Đôn, Đắk Lắk",
        "path_with_type": "Xã Ea Bar, Huyện Buôn Đôn, Tỉnh Đắk Lắk",
        "code": "24250",
        "parent_code": "647"
    },
    "24253": {
        "name": "Ea Nuôl",
        "type": "xa",
        "slug": "ea-nuol",
        "name_with_type": "Xã Ea Nuôl",
        "path": "Ea Nuôl, Buôn Đôn, Đắk Lắk",
        "path_with_type": "Xã Ea Nuôl, Huyện Buôn Đôn, Tỉnh Đắk Lắk",
        "code": "24253",
        "parent_code": "647"
    },
    "24256": {
        "name": "Ea Pốk",
        "type": "thi-tran",
        "slug": "ea-pok",
        "name_with_type": "Thị trấn Ea Pốk",
        "path": "Ea Pốk, Cư M'gar, Đắk Lắk",
        "path_with_type": "Thị trấn Ea Pốk, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24256",
        "parent_code": "648"
    },
    "24259": {
        "name": "Quảng Phú",
        "type": "thi-tran",
        "slug": "quang-phu",
        "name_with_type": "Thị trấn Quảng Phú",
        "path": "Quảng Phú, Cư M'gar, Đắk Lắk",
        "path_with_type": "Thị trấn Quảng Phú, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24259",
        "parent_code": "648"
    },
    "24262": {
        "name": "Quảng Tiến",
        "type": "xa",
        "slug": "quang-tien",
        "name_with_type": "Xã Quảng Tiến",
        "path": "Quảng Tiến, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Quảng Tiến, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24262",
        "parent_code": "648"
    },
    "24264": {
        "name": "Ea Kuêh",
        "type": "xa",
        "slug": "ea-kueh",
        "name_with_type": "Xã Ea Kuêh",
        "path": "Ea Kuêh, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Ea Kuêh, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24264",
        "parent_code": "648"
    },
    "24265": {
        "name": "Ea Kiết",
        "type": "xa",
        "slug": "ea-kiet",
        "name_with_type": "Xã Ea Kiết",
        "path": "Ea Kiết, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Ea Kiết, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24265",
        "parent_code": "648"
    },
    "24268": {
        "name": "Ea Tar",
        "type": "xa",
        "slug": "ea-tar",
        "name_with_type": "Xã Ea Tar",
        "path": "Ea Tar, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Ea Tar, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24268",
        "parent_code": "648"
    },
    "24271": {
        "name": "Cư Dliê M'nông",
        "type": "xa",
        "slug": "cu-dlie-m-nong",
        "name_with_type": "Xã Cư Dliê M'nông",
        "path": "Cư Dliê M'nông, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Cư Dliê M'nông, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24271",
        "parent_code": "648"
    },
    "24274": {
        "name": "Ea H'đinh",
        "type": "xa",
        "slug": "ea-h-dinh",
        "name_with_type": "Xã Ea H'đinh",
        "path": "Ea H'đinh, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Ea H'đinh, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24274",
        "parent_code": "648"
    },
    "24277": {
        "name": "Ea Tul",
        "type": "xa",
        "slug": "ea-tul",
        "name_with_type": "Xã Ea Tul",
        "path": "Ea Tul, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Ea Tul, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24277",
        "parent_code": "648"
    },
    "24280": {
        "name": "Ea KPam",
        "type": "xa",
        "slug": "ea-kpam",
        "name_with_type": "Xã Ea KPam",
        "path": "Ea KPam, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Ea KPam, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24280",
        "parent_code": "648"
    },
    "24283": {
        "name": "Ea M'DRóh",
        "type": "xa",
        "slug": "ea-m-droh",
        "name_with_type": "Xã Ea M'DRóh",
        "path": "Ea M'DRóh, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Ea M'DRóh, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24283",
        "parent_code": "648"
    },
    "24286": {
        "name": "Quảng Hiệp",
        "type": "xa",
        "slug": "quang-hiep",
        "name_with_type": "Xã Quảng Hiệp",
        "path": "Quảng Hiệp, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Quảng Hiệp, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24286",
        "parent_code": "648"
    },
    "24289": {
        "name": "Cư M'gar",
        "type": "xa",
        "slug": "cu-m-gar",
        "name_with_type": "Xã Cư M'gar",
        "path": "Cư M'gar, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Cư M'gar, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24289",
        "parent_code": "648"
    },
    "24292": {
        "name": "Ea D'Rơng",
        "type": "xa",
        "slug": "ea-d-rong",
        "name_with_type": "Xã Ea D'Rơng",
        "path": "Ea D'Rơng, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Ea D'Rơng, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24292",
        "parent_code": "648"
    },
    "24295": {
        "name": "Ea M'nang",
        "type": "xa",
        "slug": "ea-m-nang",
        "name_with_type": "Xã Ea M'nang",
        "path": "Ea M'nang, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Ea M'nang, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24295",
        "parent_code": "648"
    },
    "24298": {
        "name": "Cư Suê",
        "type": "xa",
        "slug": "cu-sue",
        "name_with_type": "Xã Cư Suê",
        "path": "Cư Suê, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Cư Suê, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24298",
        "parent_code": "648"
    },
    "24301": {
        "name": "Cuor Đăng",
        "type": "xa",
        "slug": "cuor-dang",
        "name_with_type": "Xã Cuor Đăng",
        "path": "Cuor Đăng, Cư M'gar, Đắk Lắk",
        "path_with_type": "Xã Cuor Đăng, Huyện Cư M'gar, Tỉnh Đắk Lắk",
        "code": "24301",
        "parent_code": "648"
    },
    "24307": {
        "name": "Cư Né",
        "type": "xa",
        "slug": "cu-ne",
        "name_with_type": "Xã Cư Né",
        "path": "Cư Né, Krông Búk, Đắk Lắk",
        "path_with_type": "Xã Cư Né, Huyện Krông Búk, Tỉnh Đắk Lắk",
        "code": "24307",
        "parent_code": "649"
    },
    "24310": {
        "name": "Chư KBô",
        "type": "xa",
        "slug": "chu-kbo",
        "name_with_type": "Xã Chư KBô",
        "path": "Chư KBô, Krông Búk, Đắk Lắk",
        "path_with_type": "Xã Chư KBô, Huyện Krông Búk, Tỉnh Đắk Lắk",
        "code": "24310",
        "parent_code": "649"
    },
    "24313": {
        "name": "Cư Pơng",
        "type": "xa",
        "slug": "cu-pong",
        "name_with_type": "Xã Cư Pơng",
        "path": "Cư Pơng, Krông Búk, Đắk Lắk",
        "path_with_type": "Xã Cư Pơng, Huyện Krông Búk, Tỉnh Đắk Lắk",
        "code": "24313",
        "parent_code": "649"
    },
    "24314": {
        "name": "Ea Sin",
        "type": "xa",
        "slug": "ea-sin",
        "name_with_type": "Xã Ea Sin",
        "path": "Ea Sin, Krông Búk, Đắk Lắk",
        "path_with_type": "Xã Ea Sin, Huyện Krông Búk, Tỉnh Đắk Lắk",
        "code": "24314",
        "parent_code": "649"
    },
    "24316": {
        "name": "Pơng Drang",
        "type": "xa",
        "slug": "pong-drang",
        "name_with_type": "Xã Pơng Drang",
        "path": "Pơng Drang, Krông Búk, Đắk Lắk",
        "path_with_type": "Xã Pơng Drang, Huyện Krông Búk, Tỉnh Đắk Lắk",
        "code": "24316",
        "parent_code": "649"
    },
    "24317": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Krông Búk, Đắk Lắk",
        "path_with_type": "Xã Tân Lập, Huyện Krông Búk, Tỉnh Đắk Lắk",
        "code": "24317",
        "parent_code": "649"
    },
    "24319": {
        "name": "Ea Ngai",
        "type": "xa",
        "slug": "ea-ngai",
        "name_with_type": "Xã Ea Ngai",
        "path": "Ea Ngai, Krông Búk, Đắk Lắk",
        "path_with_type": "Xã Ea Ngai, Huyện Krông Búk, Tỉnh Đắk Lắk",
        "code": "24319",
        "parent_code": "649"
    },
    "24343": {
        "name": "Krông Năng",
        "type": "thi-tran",
        "slug": "krong-nang",
        "name_with_type": "Thị trấn Krông Năng",
        "path": "Krông Năng, Krông Năng, Đắk Lắk",
        "path_with_type": "Thị trấn Krông Năng, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24343",
        "parent_code": "650"
    },
    "24346": {
        "name": "ĐLiê Ya",
        "type": "xa",
        "slug": "dlie-ya",
        "name_with_type": "Xã ĐLiê Ya",
        "path": "ĐLiê Ya, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã ĐLiê Ya, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24346",
        "parent_code": "650"
    },
    "24349": {
        "name": "Ea Tóh",
        "type": "xa",
        "slug": "ea-toh",
        "name_with_type": "Xã Ea Tóh",
        "path": "Ea Tóh, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Ea Tóh, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24349",
        "parent_code": "650"
    },
    "24352": {
        "name": "Ea Tam",
        "type": "xa",
        "slug": "ea-tam",
        "name_with_type": "Xã Ea Tam",
        "path": "Ea Tam, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Ea Tam, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24352",
        "parent_code": "650"
    },
    "24355": {
        "name": "Phú Lộc",
        "type": "xa",
        "slug": "phu-loc",
        "name_with_type": "Xã Phú Lộc",
        "path": "Phú Lộc, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Phú Lộc, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24355",
        "parent_code": "650"
    },
    "24358": {
        "name": "Tam Giang",
        "type": "xa",
        "slug": "tam-giang",
        "name_with_type": "Xã Tam Giang",
        "path": "Tam Giang, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Tam Giang, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24358",
        "parent_code": "650"
    },
    "24359": {
        "name": "Ea Puk",
        "type": "xa",
        "slug": "ea-puk",
        "name_with_type": "Xã Ea Puk",
        "path": "Ea Puk, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Ea Puk, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24359",
        "parent_code": "650"
    },
    "24360": {
        "name": "Ea Dăh",
        "type": "xa",
        "slug": "ea-dah",
        "name_with_type": "Xã Ea Dăh",
        "path": "Ea Dăh, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Ea Dăh, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24360",
        "parent_code": "650"
    },
    "24361": {
        "name": "Ea Hồ",
        "type": "xa",
        "slug": "ea-ho",
        "name_with_type": "Xã Ea Hồ",
        "path": "Ea Hồ, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Ea Hồ, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24361",
        "parent_code": "650"
    },
    "24364": {
        "name": "Phú Xuân",
        "type": "xa",
        "slug": "phu-xuan",
        "name_with_type": "Xã Phú Xuân",
        "path": "Phú Xuân, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Phú Xuân, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24364",
        "parent_code": "650"
    },
    "24367": {
        "name": "Cư Klông",
        "type": "xa",
        "slug": "cu-klong",
        "name_with_type": "Xã Cư Klông",
        "path": "Cư Klông, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Cư Klông, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24367",
        "parent_code": "650"
    },
    "24370": {
        "name": "Ea Tân",
        "type": "xa",
        "slug": "ea-tan",
        "name_with_type": "Xã Ea Tân",
        "path": "Ea Tân, Krông Năng, Đắk Lắk",
        "path_with_type": "Xã Ea Tân, Huyện Krông Năng, Tỉnh Đắk Lắk",
        "code": "24370",
        "parent_code": "650"
    },
    "24373": {
        "name": "Ea Kar",
        "type": "thi-tran",
        "slug": "ea-kar",
        "name_with_type": "Thị trấn Ea Kar",
        "path": "Ea Kar, Ea Kar, Đắk Lắk",
        "path_with_type": "Thị trấn Ea Kar, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24373",
        "parent_code": "651"
    },
    "24376": {
        "name": "Ea Knốp",
        "type": "thi-tran",
        "slug": "ea-knop",
        "name_with_type": "Thị trấn Ea Knốp",
        "path": "Ea Knốp, Ea Kar, Đắk Lắk",
        "path_with_type": "Thị trấn Ea Knốp, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24376",
        "parent_code": "651"
    },
    "24379": {
        "name": "Ea Sô",
        "type": "xa",
        "slug": "ea-so",
        "name_with_type": "Xã Ea Sô",
        "path": "Ea Sô, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Ea Sô, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24379",
        "parent_code": "651"
    },
    "24380": {
        "name": "Ea Sar",
        "type": "xa",
        "slug": "ea-sar",
        "name_with_type": "Xã Ea Sar",
        "path": "Ea Sar, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Ea Sar, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24380",
        "parent_code": "651"
    },
    "24382": {
        "name": "Xuân Phú",
        "type": "xa",
        "slug": "xuan-phu",
        "name_with_type": "Xã Xuân Phú",
        "path": "Xuân Phú, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Xuân Phú, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24382",
        "parent_code": "651"
    },
    "24385": {
        "name": "Cư Huê",
        "type": "xa",
        "slug": "cu-hue",
        "name_with_type": "Xã Cư Huê",
        "path": "Cư Huê, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Cư Huê, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24385",
        "parent_code": "651"
    },
    "24388": {
        "name": "Ea Tih",
        "type": "xa",
        "slug": "ea-tih",
        "name_with_type": "Xã Ea Tih",
        "path": "Ea Tih, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Ea Tih, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24388",
        "parent_code": "651"
    },
    "24391": {
        "name": "Ea Đar",
        "type": "xa",
        "slug": "ea-dar",
        "name_with_type": "Xã Ea Đar",
        "path": "Ea Đar, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Ea Đar, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24391",
        "parent_code": "651"
    },
    "24394": {
        "name": "Ea Kmút",
        "type": "xa",
        "slug": "ea-kmut",
        "name_with_type": "Xã Ea Kmút",
        "path": "Ea Kmút, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Ea Kmút, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24394",
        "parent_code": "651"
    },
    "24397": {
        "name": "Cư Ni",
        "type": "xa",
        "slug": "cu-ni",
        "name_with_type": "Xã Cư Ni",
        "path": "Cư Ni, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Cư Ni, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24397",
        "parent_code": "651"
    },
    "24400": {
        "name": "Ea Păl",
        "type": "xa",
        "slug": "ea-pal",
        "name_with_type": "Xã Ea Păl",
        "path": "Ea Păl, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Ea Păl, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24400",
        "parent_code": "651"
    },
    "24401": {
        "name": "Cư Prông",
        "type": "xa",
        "slug": "cu-prong",
        "name_with_type": "Xã Cư Prông",
        "path": "Cư Prông, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Cư Prông, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24401",
        "parent_code": "651"
    },
    "24403": {
        "name": "Ea Ô",
        "type": "xa",
        "slug": "ea-o",
        "name_with_type": "Xã Ea Ô",
        "path": "Ea Ô, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Ea Ô, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24403",
        "parent_code": "651"
    },
    "24404": {
        "name": "Cư ELang",
        "type": "xa",
        "slug": "cu-elang",
        "name_with_type": "Xã Cư ELang",
        "path": "Cư ELang, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Cư ELang, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24404",
        "parent_code": "651"
    },
    "24406": {
        "name": "Cư Bông",
        "type": "xa",
        "slug": "cu-bong",
        "name_with_type": "Xã Cư Bông",
        "path": "Cư Bông, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Cư Bông, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24406",
        "parent_code": "651"
    },
    "24409": {
        "name": "Cư Jang",
        "type": "xa",
        "slug": "cu-jang",
        "name_with_type": "Xã Cư Jang",
        "path": "Cư Jang, Ea Kar, Đắk Lắk",
        "path_with_type": "Xã Cư Jang, Huyện Ea Kar, Tỉnh Đắk Lắk",
        "code": "24409",
        "parent_code": "651"
    },
    "24412": {
        "name": "M'Đrắk",
        "type": "thi-tran",
        "slug": "m-drak",
        "name_with_type": "Thị trấn M'Đrắk",
        "path": "M'Đrắk, M'Đrắk, Đắk Lắk",
        "path_with_type": "Thị trấn M'Đrắk, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24412",
        "parent_code": "652"
    },
    "24415": {
        "name": "Cư Prao",
        "type": "xa",
        "slug": "cu-prao",
        "name_with_type": "Xã Cư Prao",
        "path": "Cư Prao, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Cư Prao, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24415",
        "parent_code": "652"
    },
    "24418": {
        "name": "Ea Pil",
        "type": "xa",
        "slug": "ea-pil",
        "name_with_type": "Xã Ea Pil",
        "path": "Ea Pil, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Ea Pil, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24418",
        "parent_code": "652"
    },
    "24421": {
        "name": "Ea Lai",
        "type": "xa",
        "slug": "ea-lai",
        "name_with_type": "Xã Ea Lai",
        "path": "Ea Lai, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Ea Lai, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24421",
        "parent_code": "652"
    },
    "24424": {
        "name": "Ea H'MLay",
        "type": "xa",
        "slug": "ea-h-mlay",
        "name_with_type": "Xã Ea H'MLay",
        "path": "Ea H'MLay, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Ea H'MLay, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24424",
        "parent_code": "652"
    },
    "24427": {
        "name": "Krông Jing",
        "type": "xa",
        "slug": "krong-jing",
        "name_with_type": "Xã Krông Jing",
        "path": "Krông Jing, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Krông Jing, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24427",
        "parent_code": "652"
    },
    "24430": {
        "name": "Ea M' Doal",
        "type": "xa",
        "slug": "ea-m-doal",
        "name_with_type": "Xã Ea M' Doal",
        "path": "Ea M' Doal, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Ea M' Doal, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24430",
        "parent_code": "652"
    },
    "24433": {
        "name": "Ea Riêng",
        "type": "xa",
        "slug": "ea-rieng",
        "name_with_type": "Xã Ea Riêng",
        "path": "Ea Riêng, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Ea Riêng, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24433",
        "parent_code": "652"
    },
    "24436": {
        "name": "Cư M'ta",
        "type": "xa",
        "slug": "cu-m-ta",
        "name_with_type": "Xã Cư M'ta",
        "path": "Cư M'ta, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Cư M'ta, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24436",
        "parent_code": "652"
    },
    "24439": {
        "name": "Cư K Róa",
        "type": "xa",
        "slug": "cu-k-roa",
        "name_with_type": "Xã Cư K Róa",
        "path": "Cư K Róa, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Cư K Róa, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24439",
        "parent_code": "652"
    },
    "24442": {
        "name": "Krông Á",
        "type": "xa",
        "slug": "krong-a",
        "name_with_type": "Xã Krông Á",
        "path": "Krông Á, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Krông Á, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24442",
        "parent_code": "652"
    },
    "24444": {
        "name": "Cư San",
        "type": "xa",
        "slug": "cu-san",
        "name_with_type": "Xã Cư San",
        "path": "Cư San, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Cư San, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24444",
        "parent_code": "652"
    },
    "24445": {
        "name": "Ea Trang",
        "type": "xa",
        "slug": "ea-trang",
        "name_with_type": "Xã Ea Trang",
        "path": "Ea Trang, M'Đrắk, Đắk Lắk",
        "path_with_type": "Xã Ea Trang, Huyện M'Đrắk, Tỉnh Đắk Lắk",
        "code": "24445",
        "parent_code": "652"
    },
    "24448": {
        "name": "Krông Kmar",
        "type": "thi-tran",
        "slug": "krong-kmar",
        "name_with_type": "Thị trấn Krông Kmar",
        "path": "Krông Kmar, Krông Bông, Đắk Lắk",
        "path_with_type": "Thị trấn Krông Kmar, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24448",
        "parent_code": "653"
    },
    "24451": {
        "name": "Dang Kang",
        "type": "xa",
        "slug": "dang-kang",
        "name_with_type": "Xã Dang Kang",
        "path": "Dang Kang, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Dang Kang, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24451",
        "parent_code": "653"
    },
    "24454": {
        "name": "Cư KTy",
        "type": "xa",
        "slug": "cu-kty",
        "name_with_type": "Xã Cư KTy",
        "path": "Cư KTy, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Cư KTy, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24454",
        "parent_code": "653"
    },
    "24457": {
        "name": "Hòa Thành",
        "type": "xa",
        "slug": "hoa-thanh",
        "name_with_type": "Xã Hòa Thành",
        "path": "Hòa Thành, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Hòa Thành, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24457",
        "parent_code": "653"
    },
    "24460": {
        "name": "Hòa Tân",
        "type": "xa",
        "slug": "hoa-tan",
        "name_with_type": "Xã Hòa Tân",
        "path": "Hòa Tân, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Hòa Tân, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24460",
        "parent_code": "653"
    },
    "24463": {
        "name": "Hòa Phong",
        "type": "xa",
        "slug": "hoa-phong",
        "name_with_type": "Xã Hòa Phong",
        "path": "Hòa Phong, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Hòa Phong, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24463",
        "parent_code": "653"
    },
    "24466": {
        "name": "Hòa Lễ",
        "type": "xa",
        "slug": "hoa-le",
        "name_with_type": "Xã Hòa Lễ",
        "path": "Hòa Lễ, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Hòa Lễ, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24466",
        "parent_code": "653"
    },
    "24469": {
        "name": "Yang Reh",
        "type": "xa",
        "slug": "yang-reh",
        "name_with_type": "Xã Yang Reh",
        "path": "Yang Reh, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Yang Reh, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24469",
        "parent_code": "653"
    },
    "24472": {
        "name": "Ea Trul",
        "type": "xa",
        "slug": "ea-trul",
        "name_with_type": "Xã Ea Trul",
        "path": "Ea Trul, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Ea Trul, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24472",
        "parent_code": "653"
    },
    "24475": {
        "name": "Khuê Ngọc Điền",
        "type": "xa",
        "slug": "khue-ngoc-dien",
        "name_with_type": "Xã Khuê Ngọc Điền",
        "path": "Khuê Ngọc Điền, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Khuê Ngọc Điền, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24475",
        "parent_code": "653"
    },
    "24478": {
        "name": "Cư Pui",
        "type": "xa",
        "slug": "cu-pui",
        "name_with_type": "Xã Cư Pui",
        "path": "Cư Pui, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Cư Pui, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24478",
        "parent_code": "653"
    },
    "24481": {
        "name": "Hòa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hòa Sơn",
        "path": "Hòa Sơn, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Hòa Sơn, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24481",
        "parent_code": "653"
    },
    "24484": {
        "name": "Cư Drăm",
        "type": "xa",
        "slug": "cu-dram",
        "name_with_type": "Xã Cư Drăm",
        "path": "Cư Drăm, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Cư Drăm, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24484",
        "parent_code": "653"
    },
    "24487": {
        "name": "Yang Mao",
        "type": "xa",
        "slug": "yang-mao",
        "name_with_type": "Xã Yang Mao",
        "path": "Yang Mao, Krông Bông, Đắk Lắk",
        "path_with_type": "Xã Yang Mao, Huyện Krông Bông, Tỉnh Đắk Lắk",
        "code": "24487",
        "parent_code": "653"
    },
    "24490": {
        "name": "Phước An",
        "type": "thi-tran",
        "slug": "phuoc-an",
        "name_with_type": "Thị trấn Phước An",
        "path": "Phước An, Krông Pắc, Đắk Lắk",
        "path_with_type": "Thị trấn Phước An, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24490",
        "parent_code": "654"
    },
    "24493": {
        "name": "KRông Búk",
        "type": "xa",
        "slug": "krong-buk",
        "name_with_type": "Xã KRông Búk",
        "path": "KRông Búk, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã KRông Búk, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24493",
        "parent_code": "654"
    },
    "24496": {
        "name": "Ea Kly",
        "type": "xa",
        "slug": "ea-kly",
        "name_with_type": "Xã Ea Kly",
        "path": "Ea Kly, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Ea Kly, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24496",
        "parent_code": "654"
    },
    "24499": {
        "name": "Ea Kênh",
        "type": "xa",
        "slug": "ea-kenh",
        "name_with_type": "Xã Ea Kênh",
        "path": "Ea Kênh, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Ea Kênh, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24499",
        "parent_code": "654"
    },
    "24502": {
        "name": "Ea Phê",
        "type": "xa",
        "slug": "ea-phe",
        "name_with_type": "Xã Ea Phê",
        "path": "Ea Phê, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Ea Phê, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24502",
        "parent_code": "654"
    },
    "24505": {
        "name": "Ea KNuec",
        "type": "xa",
        "slug": "ea-knuec",
        "name_with_type": "Xã Ea KNuec",
        "path": "Ea KNuec, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Ea KNuec, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24505",
        "parent_code": "654"
    },
    "24508": {
        "name": "Ea Yông",
        "type": "xa",
        "slug": "ea-yong",
        "name_with_type": "Xã Ea Yông",
        "path": "Ea Yông, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Ea Yông, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24508",
        "parent_code": "654"
    },
    "24511": {
        "name": "Hòa An",
        "type": "xa",
        "slug": "hoa-an",
        "name_with_type": "Xã Hòa An",
        "path": "Hòa An, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Hòa An, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24511",
        "parent_code": "654"
    },
    "24514": {
        "name": "Ea Kuăng",
        "type": "xa",
        "slug": "ea-kuang",
        "name_with_type": "Xã Ea Kuăng",
        "path": "Ea Kuăng, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Ea Kuăng, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24514",
        "parent_code": "654"
    },
    "24517": {
        "name": "Hòa Đông",
        "type": "xa",
        "slug": "hoa-dong",
        "name_with_type": "Xã Hòa Đông",
        "path": "Hòa Đông, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Hòa Đông, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24517",
        "parent_code": "654"
    },
    "24520": {
        "name": "Ea Hiu",
        "type": "xa",
        "slug": "ea-hiu",
        "name_with_type": "Xã Ea Hiu",
        "path": "Ea Hiu, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Ea Hiu, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24520",
        "parent_code": "654"
    },
    "24523": {
        "name": "Hòa Tiến",
        "type": "xa",
        "slug": "hoa-tien",
        "name_with_type": "Xã Hòa Tiến",
        "path": "Hòa Tiến, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Hòa Tiến, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24523",
        "parent_code": "654"
    },
    "24526": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Tân Tiến, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24526",
        "parent_code": "654"
    },
    "24529": {
        "name": "Vụ Bổn",
        "type": "xa",
        "slug": "vu-bon",
        "name_with_type": "Xã Vụ Bổn",
        "path": "Vụ Bổn, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Vụ Bổn, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24529",
        "parent_code": "654"
    },
    "24532": {
        "name": "Ea Uy",
        "type": "xa",
        "slug": "ea-uy",
        "name_with_type": "Xã Ea Uy",
        "path": "Ea Uy, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Ea Uy, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24532",
        "parent_code": "654"
    },
    "24535": {
        "name": "Ea Yiêng",
        "type": "xa",
        "slug": "ea-yieng",
        "name_with_type": "Xã Ea Yiêng",
        "path": "Ea Yiêng, Krông Pắc, Đắk Lắk",
        "path_with_type": "Xã Ea Yiêng, Huyện Krông Pắc, Tỉnh Đắk Lắk",
        "code": "24535",
        "parent_code": "654"
    },
    "24538": {
        "name": "Buôn Trấp",
        "type": "thi-tran",
        "slug": "buon-trap",
        "name_with_type": "Thị trấn Buôn Trấp",
        "path": "Buôn Trấp, Krông A Na, Đắk Lắk",
        "path_with_type": "Thị trấn Buôn Trấp, Huyện Krông A Na, Tỉnh Đắk Lắk",
        "code": "24538",
        "parent_code": "655"
    },
    "24556": {
        "name": "Dray Sáp",
        "type": "xa",
        "slug": "dray-sap",
        "name_with_type": "Xã Dray Sáp",
        "path": "Dray Sáp, Krông A Na, Đắk Lắk",
        "path_with_type": "Xã Dray Sáp, Huyện Krông A Na, Tỉnh Đắk Lắk",
        "code": "24556",
        "parent_code": "655"
    },
    "24559": {
        "name": "Ea Na",
        "type": "xa",
        "slug": "ea-na",
        "name_with_type": "Xã Ea Na",
        "path": "Ea Na, Krông A Na, Đắk Lắk",
        "path_with_type": "Xã Ea Na, Huyện Krông A Na, Tỉnh Đắk Lắk",
        "code": "24559",
        "parent_code": "655"
    },
    "24565": {
        "name": "Ea Bông",
        "type": "xa",
        "slug": "ea-bong",
        "name_with_type": "Xã Ea Bông",
        "path": "Ea Bông, Krông A Na, Đắk Lắk",
        "path_with_type": "Xã Ea Bông, Huyện Krông A Na, Tỉnh Đắk Lắk",
        "code": "24565",
        "parent_code": "655"
    },
    "24568": {
        "name": "Băng A Drênh",
        "type": "xa",
        "slug": "bang-a-drenh",
        "name_with_type": "Xã Băng A Drênh",
        "path": "Băng A Drênh, Krông A Na, Đắk Lắk",
        "path_with_type": "Xã Băng A Drênh, Huyện Krông A Na, Tỉnh Đắk Lắk",
        "code": "24568",
        "parent_code": "655"
    },
    "24571": {
        "name": "Dur KMăl",
        "type": "xa",
        "slug": "dur-kmal",
        "name_with_type": "Xã Dur KMăl",
        "path": "Dur KMăl, Krông A Na, Đắk Lắk",
        "path_with_type": "Xã Dur KMăl, Huyện Krông A Na, Tỉnh Đắk Lắk",
        "code": "24571",
        "parent_code": "655"
    },
    "24574": {
        "name": "Bình Hòa",
        "type": "xa",
        "slug": "binh-hoa",
        "name_with_type": "Xã Bình Hòa",
        "path": "Bình Hòa, Krông A Na, Đắk Lắk",
        "path_with_type": "Xã Bình Hòa, Huyện Krông A Na, Tỉnh Đắk Lắk",
        "code": "24574",
        "parent_code": "655"
    },
    "24577": {
        "name": "Quảng Điền",
        "type": "xa",
        "slug": "quang-dien",
        "name_with_type": "Xã Quảng Điền",
        "path": "Quảng Điền, Krông A Na, Đắk Lắk",
        "path_with_type": "Xã Quảng Điền, Huyện Krông A Na, Tỉnh Đắk Lắk",
        "code": "24577",
        "parent_code": "655"
    },
    "24580": {
        "name": "Liên Sơn",
        "type": "thi-tran",
        "slug": "lien-son",
        "name_with_type": "Thị trấn Liên Sơn",
        "path": "Liên Sơn, Lắk, Đắk Lắk",
        "path_with_type": "Thị trấn Liên Sơn, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24580",
        "parent_code": "656"
    },
    "24583": {
        "name": "Yang Tao",
        "type": "xa",
        "slug": "yang-tao",
        "name_with_type": "Xã Yang Tao",
        "path": "Yang Tao, Lắk, Đắk Lắk",
        "path_with_type": "Xã Yang Tao, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24583",
        "parent_code": "656"
    },
    "24586": {
        "name": "Bông Krang",
        "type": "xa",
        "slug": "bong-krang",
        "name_with_type": "Xã Bông Krang",
        "path": "Bông Krang, Lắk, Đắk Lắk",
        "path_with_type": "Xã Bông Krang, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24586",
        "parent_code": "656"
    },
    "24589": {
        "name": "Đắk Liêng",
        "type": "xa",
        "slug": "dak-lieng",
        "name_with_type": "Xã Đắk Liêng",
        "path": "Đắk Liêng, Lắk, Đắk Lắk",
        "path_with_type": "Xã Đắk Liêng, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24589",
        "parent_code": "656"
    },
    "24592": {
        "name": "Buôn Triết",
        "type": "xa",
        "slug": "buon-triet",
        "name_with_type": "Xã Buôn Triết",
        "path": "Buôn Triết, Lắk, Đắk Lắk",
        "path_with_type": "Xã Buôn Triết, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24592",
        "parent_code": "656"
    },
    "24595": {
        "name": "Buôn Tría",
        "type": "xa",
        "slug": "buon-tria",
        "name_with_type": "Xã Buôn Tría",
        "path": "Buôn Tría, Lắk, Đắk Lắk",
        "path_with_type": "Xã Buôn Tría, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24595",
        "parent_code": "656"
    },
    "24598": {
        "name": "Đắk Phơi",
        "type": "xa",
        "slug": "dak-phoi",
        "name_with_type": "Xã Đắk Phơi",
        "path": "Đắk Phơi, Lắk, Đắk Lắk",
        "path_with_type": "Xã Đắk Phơi, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24598",
        "parent_code": "656"
    },
    "24601": {
        "name": "Đắk Nuê",
        "type": "xa",
        "slug": "dak-nue",
        "name_with_type": "Xã Đắk Nuê",
        "path": "Đắk Nuê, Lắk, Đắk Lắk",
        "path_with_type": "Xã Đắk Nuê, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24601",
        "parent_code": "656"
    },
    "24604": {
        "name": "Krông Nô",
        "type": "xa",
        "slug": "krong-no",
        "name_with_type": "Xã Krông Nô",
        "path": "Krông Nô, Lắk, Đắk Lắk",
        "path_with_type": "Xã Krông Nô, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24604",
        "parent_code": "656"
    },
    "24607": {
        "name": "Nam Ka",
        "type": "xa",
        "slug": "nam-ka",
        "name_with_type": "Xã Nam Ka",
        "path": "Nam Ka, Lắk, Đắk Lắk",
        "path_with_type": "Xã Nam Ka, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24607",
        "parent_code": "656"
    },
    "24610": {
        "name": "Ea R'Bin",
        "type": "xa",
        "slug": "ea-r-bin",
        "name_with_type": "Xã Ea R'Bin",
        "path": "Ea R'Bin, Lắk, Đắk Lắk",
        "path_with_type": "Xã Ea R'Bin, Huyện Lắk, Tỉnh Đắk Lắk",
        "code": "24610",
        "parent_code": "656"
    },
    "24540": {
        "name": "Ea Ning",
        "type": "xa",
        "slug": "ea-ning",
        "name_with_type": "Xã Ea Ning",
        "path": "Ea Ning, Cư Kuin, Đắk Lắk",
        "path_with_type": "Xã Ea Ning, Huyện Cư Kuin, Tỉnh Đắk Lắk",
        "code": "24540",
        "parent_code": "657"
    },
    "24541": {
        "name": "Cư Ê Wi",
        "type": "xa",
        "slug": "cu-e-wi",
        "name_with_type": "Xã Cư Ê Wi",
        "path": "Cư Ê Wi, Cư Kuin, Đắk Lắk",
        "path_with_type": "Xã Cư Ê Wi, Huyện Cư Kuin, Tỉnh Đắk Lắk",
        "code": "24541",
        "parent_code": "657"
    },
    "24544": {
        "name": "Ea Ktur",
        "type": "xa",
        "slug": "ea-ktur",
        "name_with_type": "Xã Ea Ktur",
        "path": "Ea Ktur, Cư Kuin, Đắk Lắk",
        "path_with_type": "Xã Ea Ktur, Huyện Cư Kuin, Tỉnh Đắk Lắk",
        "code": "24544",
        "parent_code": "657"
    },
    "24547": {
        "name": "Ea Tiêu",
        "type": "xa",
        "slug": "ea-tieu",
        "name_with_type": "Xã Ea Tiêu",
        "path": "Ea Tiêu, Cư Kuin, Đắk Lắk",
        "path_with_type": "Xã Ea Tiêu, Huyện Cư Kuin, Tỉnh Đắk Lắk",
        "code": "24547",
        "parent_code": "657"
    },
    "24550": {
        "name": "Ea BHốk",
        "type": "xa",
        "slug": "ea-bhok",
        "name_with_type": "Xã Ea BHốk",
        "path": "Ea BHốk, Cư Kuin, Đắk Lắk",
        "path_with_type": "Xã Ea BHốk, Huyện Cư Kuin, Tỉnh Đắk Lắk",
        "code": "24550",
        "parent_code": "657"
    },
    "24553": {
        "name": "Ea Hu",
        "type": "xa",
        "slug": "ea-hu",
        "name_with_type": "Xã Ea Hu",
        "path": "Ea Hu, Cư Kuin, Đắk Lắk",
        "path_with_type": "Xã Ea Hu, Huyện Cư Kuin, Tỉnh Đắk Lắk",
        "code": "24553",
        "parent_code": "657"
    },
    "24561": {
        "name": "Dray Bhăng",
        "type": "xa",
        "slug": "dray-bhang",
        "name_with_type": "Xã Dray Bhăng",
        "path": "Dray Bhăng, Cư Kuin, Đắk Lắk",
        "path_with_type": "Xã Dray Bhăng, Huyện Cư Kuin, Tỉnh Đắk Lắk",
        "code": "24561",
        "parent_code": "657"
    },
    "24562": {
        "name": "Hòa Hiệp",
        "type": "xa",
        "slug": "hoa-hiep",
        "name_with_type": "Xã Hòa Hiệp",
        "path": "Hòa Hiệp, Cư Kuin, Đắk Lắk",
        "path_with_type": "Xã Hòa Hiệp, Huyện Cư Kuin, Tỉnh Đắk Lắk",
        "code": "24562",
        "parent_code": "657"
    },
    "05431": {
        "name": "Quán Triều",
        "type": "phuong",
        "slug": "quan-trieu",
        "name_with_type": "Phường Quán Triều",
        "path": "Quán Triều, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Quán Triều, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05431",
        "parent_code": "164"
    },
    "05434": {
        "name": "Quang Vinh",
        "type": "phuong",
        "slug": "quang-vinh",
        "name_with_type": "Phường Quang Vinh",
        "path": "Quang Vinh, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Quang Vinh, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05434",
        "parent_code": "164"
    },
    "05437": {
        "name": "Túc Duyên",
        "type": "phuong",
        "slug": "tuc-duyen",
        "name_with_type": "Phường Túc Duyên",
        "path": "Túc Duyên, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Túc Duyên, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05437",
        "parent_code": "164"
    },
    "05440": {
        "name": "Hoàng Văn Thụ",
        "type": "phuong",
        "slug": "hoang-van-thu",
        "name_with_type": "Phường Hoàng Văn Thụ",
        "path": "Hoàng Văn Thụ, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Hoàng Văn Thụ, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05440",
        "parent_code": "164"
    },
    "05443": {
        "name": "Trưng Vương",
        "type": "phuong",
        "slug": "trung-vuong",
        "name_with_type": "Phường Trưng Vương",
        "path": "Trưng Vương, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Trưng Vương, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05443",
        "parent_code": "164"
    },
    "05446": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Quang Trung, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05446",
        "parent_code": "164"
    },
    "05449": {
        "name": "Phan Đình Phùng",
        "type": "phuong",
        "slug": "phan-dinh-phung",
        "name_with_type": "Phường Phan Đình Phùng",
        "path": "Phan Đình Phùng, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Phan Đình Phùng, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05449",
        "parent_code": "164"
    },
    "05452": {
        "name": "Tân Thịnh",
        "type": "phuong",
        "slug": "tan-thinh",
        "name_with_type": "Phường Tân Thịnh",
        "path": "Tân Thịnh, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Tân Thịnh, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05452",
        "parent_code": "164"
    },
    "05455": {
        "name": "Thịnh Đán",
        "type": "phuong",
        "slug": "thinh-dan",
        "name_with_type": "Phường Thịnh Đán",
        "path": "Thịnh Đán, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Thịnh Đán, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05455",
        "parent_code": "164"
    },
    "05458": {
        "name": "Đồng Quang",
        "type": "phuong",
        "slug": "dong-quang",
        "name_with_type": "Phường Đồng Quang",
        "path": "Đồng Quang, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Đồng Quang, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05458",
        "parent_code": "164"
    },
    "05461": {
        "name": "Gia Sàng",
        "type": "phuong",
        "slug": "gia-sang",
        "name_with_type": "Phường Gia Sàng",
        "path": "Gia Sàng, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Gia Sàng, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05461",
        "parent_code": "164"
    },
    "05464": {
        "name": "Tân Lập",
        "type": "phuong",
        "slug": "tan-lap",
        "name_with_type": "Phường Tân Lập",
        "path": "Tân Lập, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Tân Lập, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05464",
        "parent_code": "164"
    },
    "05467": {
        "name": "Cam Giá",
        "type": "phuong",
        "slug": "cam-gia",
        "name_with_type": "Phường Cam Giá",
        "path": "Cam Giá, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Cam Giá, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05467",
        "parent_code": "164"
    },
    "05470": {
        "name": "Phú Xá",
        "type": "phuong",
        "slug": "phu-xa",
        "name_with_type": "Phường Phú Xá",
        "path": "Phú Xá, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Phú Xá, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05470",
        "parent_code": "164"
    },
    "05473": {
        "name": "Hương Sơn",
        "type": "phuong",
        "slug": "huong-son",
        "name_with_type": "Phường Hương Sơn",
        "path": "Hương Sơn, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Hương Sơn, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05473",
        "parent_code": "164"
    },
    "05476": {
        "name": "Trung Thành",
        "type": "phuong",
        "slug": "trung-thanh",
        "name_with_type": "Phường Trung Thành",
        "path": "Trung Thành, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Trung Thành, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05476",
        "parent_code": "164"
    },
    "05479": {
        "name": "Tân Thành",
        "type": "phuong",
        "slug": "tan-thanh",
        "name_with_type": "Phường Tân Thành",
        "path": "Tân Thành, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Tân Thành, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05479",
        "parent_code": "164"
    },
    "05482": {
        "name": "Tân Long",
        "type": "phuong",
        "slug": "tan-long",
        "name_with_type": "Phường Tân Long",
        "path": "Tân Long, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Tân Long, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05482",
        "parent_code": "164"
    },
    "05485": {
        "name": "Phúc Hà",
        "type": "xa",
        "slug": "phuc-ha",
        "name_with_type": "Xã Phúc Hà",
        "path": "Phúc Hà, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Phúc Hà, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05485",
        "parent_code": "164"
    },
    "05488": {
        "name": "Phúc Xuân",
        "type": "xa",
        "slug": "phuc-xuan",
        "name_with_type": "Xã Phúc Xuân",
        "path": "Phúc Xuân, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Phúc Xuân, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05488",
        "parent_code": "164"
    },
    "05491": {
        "name": "Quyết Thắng",
        "type": "xa",
        "slug": "quyet-thang",
        "name_with_type": "Xã Quyết Thắng",
        "path": "Quyết Thắng, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Quyết Thắng, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05491",
        "parent_code": "164"
    },
    "05494": {
        "name": "Phúc Trìu",
        "type": "xa",
        "slug": "phuc-triu",
        "name_with_type": "Xã Phúc Trìu",
        "path": "Phúc Trìu, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Phúc Trìu, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05494",
        "parent_code": "164"
    },
    "05497": {
        "name": "Thịnh Đức",
        "type": "xa",
        "slug": "thinh-duc",
        "name_with_type": "Xã Thịnh Đức",
        "path": "Thịnh Đức, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Thịnh Đức, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05497",
        "parent_code": "164"
    },
    "05500": {
        "name": "Tích Lương",
        "type": "phuong",
        "slug": "tich-luong",
        "name_with_type": "Phường Tích Lương",
        "path": "Tích Lương, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Tích Lương, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05500",
        "parent_code": "164"
    },
    "05503": {
        "name": "Tân Cương",
        "type": "xa",
        "slug": "tan-cuong",
        "name_with_type": "Xã Tân Cương",
        "path": "Tân Cương, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Tân Cương, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05503",
        "parent_code": "164"
    },
    "05653": {
        "name": "Sơn Cẩm",
        "type": "xa",
        "slug": "son-cam",
        "name_with_type": "Xã Sơn Cẩm",
        "path": "Sơn Cẩm, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Sơn Cẩm, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05653",
        "parent_code": "164"
    },
    "05659": {
        "name": "Chùa Hang",
        "type": "phuong",
        "slug": "chua-hang",
        "name_with_type": "Phường Chùa Hang",
        "path": "Chùa Hang, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Chùa Hang, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05659",
        "parent_code": "164"
    },
    "05695": {
        "name": "Cao Ngạn",
        "type": "xa",
        "slug": "cao-ngan",
        "name_with_type": "Xã Cao Ngạn",
        "path": "Cao Ngạn, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Cao Ngạn, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05695",
        "parent_code": "164"
    },
    "05701": {
        "name": "Linh Sơn",
        "type": "xa",
        "slug": "linh-son",
        "name_with_type": "Xã Linh Sơn",
        "path": "Linh Sơn, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Linh Sơn, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05701",
        "parent_code": "164"
    },
    "05710": {
        "name": "Đồng Bẩm",
        "type": "phuong",
        "slug": "dong-bam",
        "name_with_type": "Phường Đồng Bẩm",
        "path": "Đồng Bẩm, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Phường Đồng Bẩm, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05710",
        "parent_code": "164"
    },
    "05713": {
        "name": "Huống Thượng",
        "type": "xa",
        "slug": "huong-thuong",
        "name_with_type": "Xã Huống Thượng",
        "path": "Huống Thượng, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Huống Thượng, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05713",
        "parent_code": "164"
    },
    "05914": {
        "name": "Đồng Liên",
        "type": "xa",
        "slug": "dong-lien",
        "name_with_type": "Xã Đồng Liên",
        "path": "Đồng Liên, Thái Nguyên, Thái Nguyên",
        "path_with_type": "Xã Đồng Liên, Thành phố Thái Nguyên, Tỉnh Thái Nguyên",
        "code": "05914",
        "parent_code": "164"
    },
    "05506": {
        "name": "Lương Sơn",
        "type": "phuong",
        "slug": "luong-son",
        "name_with_type": "Phường Lương Sơn",
        "path": "Lương Sơn, Sông Công, Thái Nguyên",
        "path_with_type": "Phường Lương Sơn, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05506",
        "parent_code": "165"
    },
    "05509": {
        "name": "Lương Châu",
        "type": "phuong",
        "slug": "luong-chau",
        "name_with_type": "Phường Lương Châu",
        "path": "Lương Châu, Sông Công, Thái Nguyên",
        "path_with_type": "Phường Lương Châu, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05509",
        "parent_code": "165"
    },
    "05512": {
        "name": "Mỏ Chè",
        "type": "phuong",
        "slug": "mo-che",
        "name_with_type": "Phường Mỏ Chè",
        "path": "Mỏ Chè, Sông Công, Thái Nguyên",
        "path_with_type": "Phường Mỏ Chè, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05512",
        "parent_code": "165"
    },
    "05515": {
        "name": "Cải Đan",
        "type": "phuong",
        "slug": "cai-dan",
        "name_with_type": "Phường Cải Đan",
        "path": "Cải Đan, Sông Công, Thái Nguyên",
        "path_with_type": "Phường Cải Đan, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05515",
        "parent_code": "165"
    },
    "05518": {
        "name": "Thắng Lợi",
        "type": "phuong",
        "slug": "thang-loi",
        "name_with_type": "Phường Thắng Lợi",
        "path": "Thắng Lợi, Sông Công, Thái Nguyên",
        "path_with_type": "Phường Thắng Lợi, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05518",
        "parent_code": "165"
    },
    "05521": {
        "name": "Phố Cò",
        "type": "phuong",
        "slug": "pho-co",
        "name_with_type": "Phường Phố Cò",
        "path": "Phố Cò, Sông Công, Thái Nguyên",
        "path_with_type": "Phường Phố Cò, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05521",
        "parent_code": "165"
    },
    "05524": {
        "name": "Vinh Sơn",
        "type": "xa",
        "slug": "vinh-son",
        "name_with_type": "Xã Vinh Sơn",
        "path": "Vinh Sơn, Sông Công, Thái Nguyên",
        "path_with_type": "Xã Vinh Sơn, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05524",
        "parent_code": "165"
    },
    "05527": {
        "name": "Tân Quang",
        "type": "xa",
        "slug": "tan-quang",
        "name_with_type": "Xã Tân Quang",
        "path": "Tân Quang, Sông Công, Thái Nguyên",
        "path_with_type": "Xã Tân Quang, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05527",
        "parent_code": "165"
    },
    "05528": {
        "name": "Bách Quang",
        "type": "phuong",
        "slug": "bach-quang",
        "name_with_type": "Phường Bách Quang",
        "path": "Bách Quang, Sông Công, Thái Nguyên",
        "path_with_type": "Phường Bách Quang, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05528",
        "parent_code": "165"
    },
    "05530": {
        "name": "Bình Sơn",
        "type": "xa",
        "slug": "binh-son",
        "name_with_type": "Xã Bình Sơn",
        "path": "Bình Sơn, Sông Công, Thái Nguyên",
        "path_with_type": "Xã Bình Sơn, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05530",
        "parent_code": "165"
    },
    "05533": {
        "name": "Bá Xuyên",
        "type": "xa",
        "slug": "ba-xuyen",
        "name_with_type": "Xã Bá Xuyên",
        "path": "Bá Xuyên, Sông Công, Thái Nguyên",
        "path_with_type": "Xã Bá Xuyên, Thành phố Sông Công, Tỉnh Thái Nguyên",
        "code": "05533",
        "parent_code": "165"
    },
    "05536": {
        "name": "Chợ Chu",
        "type": "thi-tran",
        "slug": "cho-chu",
        "name_with_type": "Thị trấn Chợ Chu",
        "path": "Chợ Chu, Định Hóa, Thái Nguyên",
        "path_with_type": "Thị trấn Chợ Chu, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05536",
        "parent_code": "167"
    },
    "05539": {
        "name": "Linh Thông",
        "type": "xa",
        "slug": "linh-thong",
        "name_with_type": "Xã Linh Thông",
        "path": "Linh Thông, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Linh Thông, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05539",
        "parent_code": "167"
    },
    "05542": {
        "name": "Lam Vỹ",
        "type": "xa",
        "slug": "lam-vy",
        "name_with_type": "Xã Lam Vỹ",
        "path": "Lam Vỹ, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Lam Vỹ, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05542",
        "parent_code": "167"
    },
    "05545": {
        "name": "Quy Kỳ",
        "type": "xa",
        "slug": "quy-ky",
        "name_with_type": "Xã Quy Kỳ",
        "path": "Quy Kỳ, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Quy Kỳ, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05545",
        "parent_code": "167"
    },
    "05548": {
        "name": "Tân Thịnh",
        "type": "xa",
        "slug": "tan-thinh",
        "name_with_type": "Xã Tân Thịnh",
        "path": "Tân Thịnh, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Tân Thịnh, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05548",
        "parent_code": "167"
    },
    "05551": {
        "name": "Kim Phượng",
        "type": "xa",
        "slug": "kim-phuong",
        "name_with_type": "Xã Kim Phượng",
        "path": "Kim Phượng, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Kim Phượng, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05551",
        "parent_code": "167"
    },
    "05554": {
        "name": "Bảo Linh",
        "type": "xa",
        "slug": "bao-linh",
        "name_with_type": "Xã Bảo Linh",
        "path": "Bảo Linh, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Bảo Linh, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05554",
        "parent_code": "167"
    },
    "05557": {
        "name": "Kim Sơn",
        "type": "xa",
        "slug": "kim-son",
        "name_with_type": "Xã Kim Sơn",
        "path": "Kim Sơn, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Kim Sơn, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05557",
        "parent_code": "167"
    },
    "05560": {
        "name": "Phúc Chu",
        "type": "xa",
        "slug": "phuc-chu",
        "name_with_type": "Xã Phúc Chu",
        "path": "Phúc Chu, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Phúc Chu, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05560",
        "parent_code": "167"
    },
    "05563": {
        "name": "Tân Dương",
        "type": "xa",
        "slug": "tan-duong",
        "name_with_type": "Xã Tân Dương",
        "path": "Tân Dương, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Tân Dương, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05563",
        "parent_code": "167"
    },
    "05566": {
        "name": "Phượng Tiến",
        "type": "xa",
        "slug": "phuong-tien",
        "name_with_type": "Xã Phượng Tiến",
        "path": "Phượng Tiến, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Phượng Tiến, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05566",
        "parent_code": "167"
    },
    "05569": {
        "name": "Bảo Cường",
        "type": "xa",
        "slug": "bao-cuong",
        "name_with_type": "Xã Bảo Cường",
        "path": "Bảo Cường, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Bảo Cường, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05569",
        "parent_code": "167"
    },
    "05572": {
        "name": "Đồng Thịnh",
        "type": "xa",
        "slug": "dong-thinh",
        "name_with_type": "Xã Đồng Thịnh",
        "path": "Đồng Thịnh, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Đồng Thịnh, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05572",
        "parent_code": "167"
    },
    "05575": {
        "name": "Định Biên",
        "type": "xa",
        "slug": "dinh-bien",
        "name_with_type": "Xã Định Biên",
        "path": "Định Biên, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Định Biên, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05575",
        "parent_code": "167"
    },
    "05578": {
        "name": "Thanh Định",
        "type": "xa",
        "slug": "thanh-dinh",
        "name_with_type": "Xã Thanh Định",
        "path": "Thanh Định, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Thanh Định, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05578",
        "parent_code": "167"
    },
    "05581": {
        "name": "Trung Hội",
        "type": "xa",
        "slug": "trung-hoi",
        "name_with_type": "Xã Trung Hội",
        "path": "Trung Hội, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Trung Hội, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05581",
        "parent_code": "167"
    },
    "05584": {
        "name": "Trung Lương",
        "type": "xa",
        "slug": "trung-luong",
        "name_with_type": "Xã Trung Lương",
        "path": "Trung Lương, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Trung Lương, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05584",
        "parent_code": "167"
    },
    "05587": {
        "name": "Bình Yên",
        "type": "xa",
        "slug": "binh-yen",
        "name_with_type": "Xã Bình Yên",
        "path": "Bình Yên, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Bình Yên, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05587",
        "parent_code": "167"
    },
    "05590": {
        "name": "Điềm Mặc",
        "type": "xa",
        "slug": "diem-mac",
        "name_with_type": "Xã Điềm Mặc",
        "path": "Điềm Mặc, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Điềm Mặc, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05590",
        "parent_code": "167"
    },
    "05593": {
        "name": "Phú Tiến",
        "type": "xa",
        "slug": "phu-tien",
        "name_with_type": "Xã Phú Tiến",
        "path": "Phú Tiến, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Phú Tiến, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05593",
        "parent_code": "167"
    },
    "05596": {
        "name": "Bộc Nhiêu",
        "type": "xa",
        "slug": "boc-nhieu",
        "name_with_type": "Xã Bộc Nhiêu",
        "path": "Bộc Nhiêu, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Bộc Nhiêu, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05596",
        "parent_code": "167"
    },
    "05599": {
        "name": "Sơn Phú",
        "type": "xa",
        "slug": "son-phu",
        "name_with_type": "Xã Sơn Phú",
        "path": "Sơn Phú, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Sơn Phú, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05599",
        "parent_code": "167"
    },
    "05602": {
        "name": "Phú Đình",
        "type": "xa",
        "slug": "phu-dinh",
        "name_with_type": "Xã Phú Đình",
        "path": "Phú Đình, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Phú Đình, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05602",
        "parent_code": "167"
    },
    "05605": {
        "name": "Bình Thành",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thành",
        "path": "Bình Thành, Định Hóa, Thái Nguyên",
        "path_with_type": "Xã Bình Thành, Huyện Định Hóa, Tỉnh Thái Nguyên",
        "code": "05605",
        "parent_code": "167"
    },
    "05608": {
        "name": "Giang Tiên",
        "type": "thi-tran",
        "slug": "giang-tien",
        "name_with_type": "Thị trấn Giang Tiên",
        "path": "Giang Tiên, Phú Lương, Thái Nguyên",
        "path_with_type": "Thị trấn Giang Tiên, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05608",
        "parent_code": "168"
    },
    "05611": {
        "name": "Đu",
        "type": "thi-tran",
        "slug": "du",
        "name_with_type": "Thị trấn Đu",
        "path": "Đu, Phú Lương, Thái Nguyên",
        "path_with_type": "Thị trấn Đu, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05611",
        "parent_code": "168"
    },
    "05614": {
        "name": "Yên Ninh",
        "type": "xa",
        "slug": "yen-ninh",
        "name_with_type": "Xã Yên Ninh",
        "path": "Yên Ninh, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Yên Ninh, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05614",
        "parent_code": "168"
    },
    "05617": {
        "name": "Yên Trạch",
        "type": "xa",
        "slug": "yen-trach",
        "name_with_type": "Xã Yên Trạch",
        "path": "Yên Trạch, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Yên Trạch, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05617",
        "parent_code": "168"
    },
    "05620": {
        "name": "Yên Đổ",
        "type": "xa",
        "slug": "yen-do",
        "name_with_type": "Xã Yên Đổ",
        "path": "Yên Đổ, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Yên Đổ, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05620",
        "parent_code": "168"
    },
    "05623": {
        "name": "Yên Lạc",
        "type": "xa",
        "slug": "yen-lac",
        "name_with_type": "Xã Yên Lạc",
        "path": "Yên Lạc, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Yên Lạc, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05623",
        "parent_code": "168"
    },
    "05626": {
        "name": "Ôn Lương",
        "type": "xa",
        "slug": "on-luong",
        "name_with_type": "Xã Ôn Lương",
        "path": "Ôn Lương, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Ôn Lương, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05626",
        "parent_code": "168"
    },
    "05629": {
        "name": "Động Đạt",
        "type": "xa",
        "slug": "dong-dat",
        "name_with_type": "Xã Động Đạt",
        "path": "Động Đạt, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Động Đạt, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05629",
        "parent_code": "168"
    },
    "05632": {
        "name": "Phủ Lý",
        "type": "xa",
        "slug": "phu-ly",
        "name_with_type": "Xã Phủ Lý",
        "path": "Phủ Lý, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Phủ Lý, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05632",
        "parent_code": "168"
    },
    "05635": {
        "name": "Phú Đô",
        "type": "xa",
        "slug": "phu-do",
        "name_with_type": "Xã Phú Đô",
        "path": "Phú Đô, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Phú Đô, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05635",
        "parent_code": "168"
    },
    "05638": {
        "name": "Hợp Thành",
        "type": "xa",
        "slug": "hop-thanh",
        "name_with_type": "Xã Hợp Thành",
        "path": "Hợp Thành, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Hợp Thành, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05638",
        "parent_code": "168"
    },
    "05641": {
        "name": "Tức Tranh",
        "type": "xa",
        "slug": "tuc-tranh",
        "name_with_type": "Xã Tức Tranh",
        "path": "Tức Tranh, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Tức Tranh, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05641",
        "parent_code": "168"
    },
    "05644": {
        "name": "Phấn Mễ",
        "type": "xa",
        "slug": "phan-me",
        "name_with_type": "Xã Phấn Mễ",
        "path": "Phấn Mễ, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Phấn Mễ, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05644",
        "parent_code": "168"
    },
    "05647": {
        "name": "Vô Tranh",
        "type": "xa",
        "slug": "vo-tranh",
        "name_with_type": "Xã Vô Tranh",
        "path": "Vô Tranh, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Vô Tranh, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05647",
        "parent_code": "168"
    },
    "05650": {
        "name": "Cổ Lũng",
        "type": "xa",
        "slug": "co-lung",
        "name_with_type": "Xã Cổ Lũng",
        "path": "Cổ Lũng, Phú Lương, Thái Nguyên",
        "path_with_type": "Xã Cổ Lũng, Huyện Phú Lương, Tỉnh Thái Nguyên",
        "code": "05650",
        "parent_code": "168"
    },
    "05656": {
        "name": "Sông Cầu",
        "type": "thi-tran",
        "slug": "song-cau",
        "name_with_type": "Thị trấn Sông Cầu",
        "path": "Sông Cầu, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Thị trấn Sông Cầu, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05656",
        "parent_code": "169"
    },
    "05662": {
        "name": "Trại Cau",
        "type": "thi-tran",
        "slug": "trai-cau",
        "name_with_type": "Thị trấn Trại Cau",
        "path": "Trại Cau, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Thị trấn Trại Cau, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05662",
        "parent_code": "169"
    },
    "05665": {
        "name": "Văn Lăng",
        "type": "xa",
        "slug": "van-lang",
        "name_with_type": "Xã Văn Lăng",
        "path": "Văn Lăng, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Văn Lăng, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05665",
        "parent_code": "169"
    },
    "05668": {
        "name": "Tân Long",
        "type": "xa",
        "slug": "tan-long",
        "name_with_type": "Xã Tân Long",
        "path": "Tân Long, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Tân Long, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05668",
        "parent_code": "169"
    },
    "05671": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Hòa Bình, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05671",
        "parent_code": "169"
    },
    "05674": {
        "name": "Quang Sơn",
        "type": "xa",
        "slug": "quang-son",
        "name_with_type": "Xã Quang Sơn",
        "path": "Quang Sơn, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Quang Sơn, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05674",
        "parent_code": "169"
    },
    "05677": {
        "name": "Minh Lập",
        "type": "xa",
        "slug": "minh-lap",
        "name_with_type": "Xã Minh Lập",
        "path": "Minh Lập, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Minh Lập, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05677",
        "parent_code": "169"
    },
    "05680": {
        "name": "Văn Hán",
        "type": "xa",
        "slug": "van-han",
        "name_with_type": "Xã Văn Hán",
        "path": "Văn Hán, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Văn Hán, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05680",
        "parent_code": "169"
    },
    "05683": {
        "name": "Hóa Trung",
        "type": "xa",
        "slug": "hoa-trung",
        "name_with_type": "Xã Hóa Trung",
        "path": "Hóa Trung, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Hóa Trung, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05683",
        "parent_code": "169"
    },
    "05686": {
        "name": "Khe Mo",
        "type": "xa",
        "slug": "khe-mo",
        "name_with_type": "Xã Khe Mo",
        "path": "Khe Mo, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Khe Mo, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05686",
        "parent_code": "169"
    },
    "05689": {
        "name": "Cây Thị",
        "type": "xa",
        "slug": "cay-thi",
        "name_with_type": "Xã Cây Thị",
        "path": "Cây Thị, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Cây Thị, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05689",
        "parent_code": "169"
    },
    "05692": {
        "name": "Hóa Thượng",
        "type": "xa",
        "slug": "hoa-thuong",
        "name_with_type": "Xã Hóa Thượng",
        "path": "Hóa Thượng, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Hóa Thượng, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05692",
        "parent_code": "169"
    },
    "05698": {
        "name": "Hợp Tiến",
        "type": "xa",
        "slug": "hop-tien",
        "name_with_type": "Xã Hợp Tiến",
        "path": "Hợp Tiến, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Hợp Tiến, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05698",
        "parent_code": "169"
    },
    "05704": {
        "name": "Tân Lợi",
        "type": "xa",
        "slug": "tan-loi",
        "name_with_type": "Xã Tân Lợi",
        "path": "Tân Lợi, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Tân Lợi, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05704",
        "parent_code": "169"
    },
    "05707": {
        "name": "Nam Hòa",
        "type": "xa",
        "slug": "nam-hoa",
        "name_with_type": "Xã Nam Hòa",
        "path": "Nam Hòa, Đồng Hỷ, Thái Nguyên",
        "path_with_type": "Xã Nam Hòa, Huyện Đồng Hỷ, Tỉnh Thái Nguyên",
        "code": "05707",
        "parent_code": "169"
    },
    "05716": {
        "name": "Đình Cả",
        "type": "thi-tran",
        "slug": "dinh-ca",
        "name_with_type": "Thị trấn Đình Cả",
        "path": "Đình Cả, Võ Nhai, Thái Nguyên",
        "path_with_type": "Thị trấn Đình Cả, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05716",
        "parent_code": "170"
    },
    "05719": {
        "name": "Sảng Mộc",
        "type": "xa",
        "slug": "sang-moc",
        "name_with_type": "Xã Sảng Mộc",
        "path": "Sảng Mộc, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Sảng Mộc, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05719",
        "parent_code": "170"
    },
    "05722": {
        "name": "Nghinh Tường",
        "type": "xa",
        "slug": "nghinh-tuong",
        "name_with_type": "Xã Nghinh Tường",
        "path": "Nghinh Tường, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Nghinh Tường, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05722",
        "parent_code": "170"
    },
    "05725": {
        "name": "Thần Xa",
        "type": "xa",
        "slug": "than-xa",
        "name_with_type": "Xã Thần Xa",
        "path": "Thần Xa, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Thần Xa, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05725",
        "parent_code": "170"
    },
    "05728": {
        "name": "Vũ Chấn",
        "type": "xa",
        "slug": "vu-chan",
        "name_with_type": "Xã Vũ Chấn",
        "path": "Vũ Chấn, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Vũ Chấn, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05728",
        "parent_code": "170"
    },
    "05731": {
        "name": "Thượng Nung",
        "type": "xa",
        "slug": "thuong-nung",
        "name_with_type": "Xã Thượng Nung",
        "path": "Thượng Nung, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Thượng Nung, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05731",
        "parent_code": "170"
    },
    "05734": {
        "name": "Phú Thượng",
        "type": "xa",
        "slug": "phu-thuong",
        "name_with_type": "Xã Phú Thượng",
        "path": "Phú Thượng, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Phú Thượng, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05734",
        "parent_code": "170"
    },
    "05737": {
        "name": "Cúc Đường",
        "type": "xa",
        "slug": "cuc-duong",
        "name_with_type": "Xã Cúc Đường",
        "path": "Cúc Đường, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Cúc Đường, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05737",
        "parent_code": "170"
    },
    "05740": {
        "name": "La Hiên",
        "type": "xa",
        "slug": "la-hien",
        "name_with_type": "Xã La Hiên",
        "path": "La Hiên, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã La Hiên, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05740",
        "parent_code": "170"
    },
    "05743": {
        "name": "Lâu Thượng",
        "type": "xa",
        "slug": "lau-thuong",
        "name_with_type": "Xã Lâu Thượng",
        "path": "Lâu Thượng, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Lâu Thượng, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05743",
        "parent_code": "170"
    },
    "05746": {
        "name": "Tràng Xá",
        "type": "xa",
        "slug": "trang-xa",
        "name_with_type": "Xã Tràng Xá",
        "path": "Tràng Xá, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Tràng Xá, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05746",
        "parent_code": "170"
    },
    "05749": {
        "name": "Phương Giao",
        "type": "xa",
        "slug": "phuong-giao",
        "name_with_type": "Xã Phương Giao",
        "path": "Phương Giao, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Phương Giao, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05749",
        "parent_code": "170"
    },
    "05752": {
        "name": "Liên Minh",
        "type": "xa",
        "slug": "lien-minh",
        "name_with_type": "Xã Liên Minh",
        "path": "Liên Minh, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Liên Minh, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05752",
        "parent_code": "170"
    },
    "05755": {
        "name": "Dân Tiến",
        "type": "xa",
        "slug": "dan-tien",
        "name_with_type": "Xã Dân Tiến",
        "path": "Dân Tiến, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Dân Tiến, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05755",
        "parent_code": "170"
    },
    "05758": {
        "name": "Bình Long",
        "type": "xa",
        "slug": "binh-long",
        "name_with_type": "Xã Bình Long",
        "path": "Bình Long, Võ Nhai, Thái Nguyên",
        "path_with_type": "Xã Bình Long, Huyện Võ Nhai, Tỉnh Thái Nguyên",
        "code": "05758",
        "parent_code": "170"
    },
    "05761": {
        "name": "Hùng Sơn",
        "type": "thi-tran",
        "slug": "hung-son",
        "name_with_type": "Thị trấn Hùng Sơn",
        "path": "Hùng Sơn, Đại Từ, Thái Nguyên",
        "path_with_type": "Thị trấn Hùng Sơn, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05761",
        "parent_code": "171"
    },
    "05764": {
        "name": "Quân Chu",
        "type": "thi-tran",
        "slug": "quan-chu",
        "name_with_type": "Thị trấn Quân Chu",
        "path": "Quân Chu, Đại Từ, Thái Nguyên",
        "path_with_type": "Thị trấn Quân Chu, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05764",
        "parent_code": "171"
    },
    "05767": {
        "name": "Phúc Lương",
        "type": "xa",
        "slug": "phuc-luong",
        "name_with_type": "Xã Phúc Lương",
        "path": "Phúc Lương, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Phúc Lương, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05767",
        "parent_code": "171"
    },
    "05770": {
        "name": "Minh Tiến",
        "type": "xa",
        "slug": "minh-tien",
        "name_with_type": "Xã Minh Tiến",
        "path": "Minh Tiến, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Minh Tiến, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05770",
        "parent_code": "171"
    },
    "05773": {
        "name": "Yên Lãng",
        "type": "xa",
        "slug": "yen-lang",
        "name_with_type": "Xã Yên Lãng",
        "path": "Yên Lãng, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Yên Lãng, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05773",
        "parent_code": "171"
    },
    "05776": {
        "name": "Đức Lương",
        "type": "xa",
        "slug": "duc-luong",
        "name_with_type": "Xã Đức Lương",
        "path": "Đức Lương, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Đức Lương, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05776",
        "parent_code": "171"
    },
    "05779": {
        "name": "Phú Cường",
        "type": "xa",
        "slug": "phu-cuong",
        "name_with_type": "Xã Phú Cường",
        "path": "Phú Cường, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Phú Cường, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05779",
        "parent_code": "171"
    },
    "05782": {
        "name": "Na Mao",
        "type": "xa",
        "slug": "na-mao",
        "name_with_type": "Xã Na Mao",
        "path": "Na Mao, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Na Mao, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05782",
        "parent_code": "171"
    },
    "05785": {
        "name": "Phú Lạc",
        "type": "xa",
        "slug": "phu-lac",
        "name_with_type": "Xã Phú Lạc",
        "path": "Phú Lạc, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Phú Lạc, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05785",
        "parent_code": "171"
    },
    "05788": {
        "name": "Tân Linh",
        "type": "xa",
        "slug": "tan-linh",
        "name_with_type": "Xã Tân Linh",
        "path": "Tân Linh, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Tân Linh, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05788",
        "parent_code": "171"
    },
    "05791": {
        "name": "Phú Thịnh",
        "type": "xa",
        "slug": "phu-thinh",
        "name_with_type": "Xã Phú Thịnh",
        "path": "Phú Thịnh, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Phú Thịnh, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05791",
        "parent_code": "171"
    },
    "05794": {
        "name": "Phục Linh",
        "type": "xa",
        "slug": "phuc-linh",
        "name_with_type": "Xã Phục Linh",
        "path": "Phục Linh, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Phục Linh, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05794",
        "parent_code": "171"
    },
    "05797": {
        "name": "Phú Xuyên",
        "type": "xa",
        "slug": "phu-xuyen",
        "name_with_type": "Xã Phú Xuyên",
        "path": "Phú Xuyên, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Phú Xuyên, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05797",
        "parent_code": "171"
    },
    "05800": {
        "name": "Bản Ngoại",
        "type": "xa",
        "slug": "ban-ngoai",
        "name_with_type": "Xã Bản Ngoại",
        "path": "Bản Ngoại, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Bản Ngoại, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05800",
        "parent_code": "171"
    },
    "05803": {
        "name": "Tiên Hội",
        "type": "xa",
        "slug": "tien-hoi",
        "name_with_type": "Xã Tiên Hội",
        "path": "Tiên Hội, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Tiên Hội, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05803",
        "parent_code": "171"
    },
    "05809": {
        "name": "Cù Vân",
        "type": "xa",
        "slug": "cu-van",
        "name_with_type": "Xã Cù Vân",
        "path": "Cù Vân, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Cù Vân, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05809",
        "parent_code": "171"
    },
    "05812": {
        "name": "Hà Thượng",
        "type": "xa",
        "slug": "ha-thuong",
        "name_with_type": "Xã Hà Thượng",
        "path": "Hà Thượng, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Hà Thượng, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05812",
        "parent_code": "171"
    },
    "05815": {
        "name": "La Bằng",
        "type": "xa",
        "slug": "la-bang",
        "name_with_type": "Xã La Bằng",
        "path": "La Bằng, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã La Bằng, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05815",
        "parent_code": "171"
    },
    "05818": {
        "name": "Hoàng Nông",
        "type": "xa",
        "slug": "hoang-nong",
        "name_with_type": "Xã Hoàng Nông",
        "path": "Hoàng Nông, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Hoàng Nông, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05818",
        "parent_code": "171"
    },
    "05821": {
        "name": "Khôi Kỳ",
        "type": "xa",
        "slug": "khoi-ky",
        "name_with_type": "Xã Khôi Kỳ",
        "path": "Khôi Kỳ, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Khôi Kỳ, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05821",
        "parent_code": "171"
    },
    "05824": {
        "name": "An Khánh",
        "type": "xa",
        "slug": "an-khanh",
        "name_with_type": "Xã An Khánh",
        "path": "An Khánh, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã An Khánh, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05824",
        "parent_code": "171"
    },
    "05827": {
        "name": "Tân Thái",
        "type": "xa",
        "slug": "tan-thai",
        "name_with_type": "Xã Tân Thái",
        "path": "Tân Thái, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Tân Thái, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05827",
        "parent_code": "171"
    },
    "05830": {
        "name": "Bình Thuận",
        "type": "xa",
        "slug": "binh-thuan",
        "name_with_type": "Xã Bình Thuận",
        "path": "Bình Thuận, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Bình Thuận, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05830",
        "parent_code": "171"
    },
    "05833": {
        "name": "Lục Ba",
        "type": "xa",
        "slug": "luc-ba",
        "name_with_type": "Xã Lục Ba",
        "path": "Lục Ba, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Lục Ba, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05833",
        "parent_code": "171"
    },
    "05836": {
        "name": "Mỹ Yên",
        "type": "xa",
        "slug": "my-yen",
        "name_with_type": "Xã Mỹ Yên",
        "path": "Mỹ Yên, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Mỹ Yên, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05836",
        "parent_code": "171"
    },
    "05839": {
        "name": "Vạn Thọ",
        "type": "xa",
        "slug": "van-tho",
        "name_with_type": "Xã Vạn Thọ",
        "path": "Vạn Thọ, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Vạn Thọ, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05839",
        "parent_code": "171"
    },
    "05842": {
        "name": "Văn Yên",
        "type": "xa",
        "slug": "van-yen",
        "name_with_type": "Xã Văn Yên",
        "path": "Văn Yên, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Văn Yên, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05842",
        "parent_code": "171"
    },
    "05845": {
        "name": "Ký Phú",
        "type": "xa",
        "slug": "ky-phu",
        "name_with_type": "Xã Ký Phú",
        "path": "Ký Phú, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Ký Phú, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05845",
        "parent_code": "171"
    },
    "05848": {
        "name": "Cát Nê",
        "type": "xa",
        "slug": "cat-ne",
        "name_with_type": "Xã Cát Nê",
        "path": "Cát Nê, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Cát Nê, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05848",
        "parent_code": "171"
    },
    "05851": {
        "name": "Quân Chu",
        "type": "xa",
        "slug": "quan-chu",
        "name_with_type": "Xã Quân Chu",
        "path": "Quân Chu, Đại Từ, Thái Nguyên",
        "path_with_type": "Xã Quân Chu, Huyện Đại Từ, Tỉnh Thái Nguyên",
        "code": "05851",
        "parent_code": "171"
    },
    "05854": {
        "name": "Bãi Bông",
        "type": "phuong",
        "slug": "bai-bong",
        "name_with_type": "Phường Bãi Bông",
        "path": "Bãi Bông, Phổ Yên, Thái Nguyên",
        "path_with_type": "Phường Bãi Bông, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05854",
        "parent_code": "172"
    },
    "05857": {
        "name": "Bắc Sơn",
        "type": "phuong",
        "slug": "bac-son",
        "name_with_type": "Phường Bắc Sơn",
        "path": "Bắc Sơn, Phổ Yên, Thái Nguyên",
        "path_with_type": "Phường Bắc Sơn, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05857",
        "parent_code": "172"
    },
    "05860": {
        "name": "Ba Hàng",
        "type": "phuong",
        "slug": "ba-hang",
        "name_with_type": "Phường Ba Hàng",
        "path": "Ba Hàng, Phổ Yên, Thái Nguyên",
        "path_with_type": "Phường Ba Hàng, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05860",
        "parent_code": "172"
    },
    "05863": {
        "name": "Phúc Tân",
        "type": "xa",
        "slug": "phuc-tan",
        "name_with_type": "Xã Phúc Tân",
        "path": "Phúc Tân, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Phúc Tân, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05863",
        "parent_code": "172"
    },
    "05866": {
        "name": "Phúc Thuận",
        "type": "xa",
        "slug": "phuc-thuan",
        "name_with_type": "Xã Phúc Thuận",
        "path": "Phúc Thuận, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Phúc Thuận, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05866",
        "parent_code": "172"
    },
    "05869": {
        "name": "Hồng Tiến",
        "type": "xa",
        "slug": "hong-tien",
        "name_with_type": "Xã Hồng Tiến",
        "path": "Hồng Tiến, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Hồng Tiến, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05869",
        "parent_code": "172"
    },
    "05872": {
        "name": "Minh Đức",
        "type": "xa",
        "slug": "minh-duc",
        "name_with_type": "Xã Minh Đức",
        "path": "Minh Đức, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Minh Đức, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05872",
        "parent_code": "172"
    },
    "05875": {
        "name": "Đắc Sơn",
        "type": "xa",
        "slug": "dac-son",
        "name_with_type": "Xã Đắc Sơn",
        "path": "Đắc Sơn, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Đắc Sơn, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05875",
        "parent_code": "172"
    },
    "05878": {
        "name": "Đồng Tiến",
        "type": "phuong",
        "slug": "dong-tien",
        "name_with_type": "Phường Đồng Tiến",
        "path": "Đồng Tiến, Phổ Yên, Thái Nguyên",
        "path_with_type": "Phường Đồng Tiến, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05878",
        "parent_code": "172"
    },
    "05881": {
        "name": "Thành Công",
        "type": "xa",
        "slug": "thanh-cong",
        "name_with_type": "Xã Thành Công",
        "path": "Thành Công, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Thành Công, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05881",
        "parent_code": "172"
    },
    "05884": {
        "name": "Tiên Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiên Phong",
        "path": "Tiên Phong, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Tiên Phong, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05884",
        "parent_code": "172"
    },
    "05887": {
        "name": "Vạn Phái",
        "type": "xa",
        "slug": "van-phai",
        "name_with_type": "Xã Vạn Phái",
        "path": "Vạn Phái, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Vạn Phái, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05887",
        "parent_code": "172"
    },
    "05890": {
        "name": "Nam Tiến",
        "type": "xa",
        "slug": "nam-tien",
        "name_with_type": "Xã Nam Tiến",
        "path": "Nam Tiến, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Nam Tiến, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05890",
        "parent_code": "172"
    },
    "05893": {
        "name": "Tân Hương",
        "type": "xa",
        "slug": "tan-huong",
        "name_with_type": "Xã Tân Hương",
        "path": "Tân Hương, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Tân Hương, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05893",
        "parent_code": "172"
    },
    "05896": {
        "name": "Đông Cao",
        "type": "xa",
        "slug": "dong-cao",
        "name_with_type": "Xã Đông Cao",
        "path": "Đông Cao, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Đông Cao, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05896",
        "parent_code": "172"
    },
    "05899": {
        "name": "Trung Thành",
        "type": "xa",
        "slug": "trung-thanh",
        "name_with_type": "Xã Trung Thành",
        "path": "Trung Thành, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Trung Thành, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05899",
        "parent_code": "172"
    },
    "05902": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Tân Phú, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05902",
        "parent_code": "172"
    },
    "05905": {
        "name": "Thuận Thành",
        "type": "xa",
        "slug": "thuan-thanh",
        "name_with_type": "Xã Thuận Thành",
        "path": "Thuận Thành, Phổ Yên, Thái Nguyên",
        "path_with_type": "Xã Thuận Thành, Thị xã Phổ Yên, Tỉnh Thái Nguyên",
        "code": "05905",
        "parent_code": "172"
    },
    "05908": {
        "name": "Hương Sơn",
        "type": "thi-tran",
        "slug": "huong-son",
        "name_with_type": "Thị trấn Hương Sơn",
        "path": "Hương Sơn, Phú Bình, Thái Nguyên",
        "path_with_type": "Thị trấn Hương Sơn, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05908",
        "parent_code": "173"
    },
    "05911": {
        "name": "Bàn Đạt",
        "type": "xa",
        "slug": "ban-dat",
        "name_with_type": "Xã Bàn Đạt",
        "path": "Bàn Đạt, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Bàn Đạt, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05911",
        "parent_code": "173"
    },
    "05917": {
        "name": "Tân Khánh",
        "type": "xa",
        "slug": "tan-khanh",
        "name_with_type": "Xã Tân Khánh",
        "path": "Tân Khánh, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Tân Khánh, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05917",
        "parent_code": "173"
    },
    "05920": {
        "name": "Tân Kim",
        "type": "xa",
        "slug": "tan-kim",
        "name_with_type": "Xã Tân Kim",
        "path": "Tân Kim, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Tân Kim, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05920",
        "parent_code": "173"
    },
    "05923": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Tân Thành, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05923",
        "parent_code": "173"
    },
    "05926": {
        "name": "Đào Xá",
        "type": "xa",
        "slug": "dao-xa",
        "name_with_type": "Xã Đào Xá",
        "path": "Đào Xá, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Đào Xá, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05926",
        "parent_code": "173"
    },
    "05929": {
        "name": "Bảo Lý",
        "type": "xa",
        "slug": "bao-ly",
        "name_with_type": "Xã Bảo Lý",
        "path": "Bảo Lý, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Bảo Lý, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05929",
        "parent_code": "173"
    },
    "05932": {
        "name": "Thượng Đình",
        "type": "xa",
        "slug": "thuong-dinh",
        "name_with_type": "Xã Thượng Đình",
        "path": "Thượng Đình, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Thượng Đình, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05932",
        "parent_code": "173"
    },
    "05935": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Tân Hòa, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05935",
        "parent_code": "173"
    },
    "05938": {
        "name": "Nhã Lộng",
        "type": "xa",
        "slug": "nha-long",
        "name_with_type": "Xã Nhã Lộng",
        "path": "Nhã Lộng, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Nhã Lộng, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05938",
        "parent_code": "173"
    },
    "05941": {
        "name": "Điềm Thụy",
        "type": "xa",
        "slug": "diem-thuy",
        "name_with_type": "Xã Điềm Thụy",
        "path": "Điềm Thụy, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Điềm Thụy, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05941",
        "parent_code": "173"
    },
    "05944": {
        "name": "Xuân Phương",
        "type": "xa",
        "slug": "xuan-phuong",
        "name_with_type": "Xã Xuân Phương",
        "path": "Xuân Phương, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Xuân Phương, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05944",
        "parent_code": "173"
    },
    "05947": {
        "name": "Tân Đức",
        "type": "xa",
        "slug": "tan-duc",
        "name_with_type": "Xã Tân Đức",
        "path": "Tân Đức, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Tân Đức, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05947",
        "parent_code": "173"
    },
    "05950": {
        "name": "Úc Kỳ",
        "type": "xa",
        "slug": "uc-ky",
        "name_with_type": "Xã Úc Kỳ",
        "path": "Úc Kỳ, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Úc Kỳ, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05950",
        "parent_code": "173"
    },
    "05953": {
        "name": "Lương Phú",
        "type": "xa",
        "slug": "luong-phu",
        "name_with_type": "Xã Lương Phú",
        "path": "Lương Phú, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Lương Phú, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05953",
        "parent_code": "173"
    },
    "05956": {
        "name": "Nga My",
        "type": "xa",
        "slug": "nga-my",
        "name_with_type": "Xã Nga My",
        "path": "Nga My, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Nga My, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05956",
        "parent_code": "173"
    },
    "05959": {
        "name": "Kha Sơn",
        "type": "xa",
        "slug": "kha-son",
        "name_with_type": "Xã Kha Sơn",
        "path": "Kha Sơn, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Kha Sơn, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05959",
        "parent_code": "173"
    },
    "05962": {
        "name": "Thanh Ninh",
        "type": "xa",
        "slug": "thanh-ninh",
        "name_with_type": "Xã Thanh Ninh",
        "path": "Thanh Ninh, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Thanh Ninh, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05962",
        "parent_code": "173"
    },
    "05965": {
        "name": "Dương Thành",
        "type": "xa",
        "slug": "duong-thanh",
        "name_with_type": "Xã Dương Thành",
        "path": "Dương Thành, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Dương Thành, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05965",
        "parent_code": "173"
    },
    "05968": {
        "name": "Hà Châu",
        "type": "xa",
        "slug": "ha-chau",
        "name_with_type": "Xã Hà Châu",
        "path": "Hà Châu, Phú Bình, Thái Nguyên",
        "path_with_type": "Xã Hà Châu, Huyện Phú Bình, Tỉnh Thái Nguyên",
        "code": "05968",
        "parent_code": "173"
    },
    "10507": {
        "name": "Cẩm Thượng",
        "type": "phuong",
        "slug": "cam-thuong",
        "name_with_type": "Phường Cẩm Thượng",
        "path": "Cẩm Thượng, Hải Dương, Hải Dương",
        "path_with_type": "Phường Cẩm Thượng, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10507",
        "parent_code": "288"
    },
    "10510": {
        "name": "Bình Hàn",
        "type": "phuong",
        "slug": "binh-han",
        "name_with_type": "Phường Bình Hàn",
        "path": "Bình Hàn, Hải Dương, Hải Dương",
        "path_with_type": "Phường Bình Hàn, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10510",
        "parent_code": "288"
    },
    "10513": {
        "name": "Ngọc Châu",
        "type": "phuong",
        "slug": "ngoc-chau",
        "name_with_type": "Phường Ngọc Châu",
        "path": "Ngọc Châu, Hải Dương, Hải Dương",
        "path_with_type": "Phường Ngọc Châu, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10513",
        "parent_code": "288"
    },
    "10514": {
        "name": "Nhị Châu",
        "type": "phuong",
        "slug": "nhi-chau",
        "name_with_type": "Phường Nhị Châu",
        "path": "Nhị Châu, Hải Dương, Hải Dương",
        "path_with_type": "Phường Nhị Châu, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10514",
        "parent_code": "288"
    },
    "10516": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Hải Dương, Hải Dương",
        "path_with_type": "Phường Quang Trung, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10516",
        "parent_code": "288"
    },
    "10519": {
        "name": "Nguyễn Trãi",
        "type": "phuong",
        "slug": "nguyen-trai",
        "name_with_type": "Phường Nguyễn Trãi",
        "path": "Nguyễn Trãi, Hải Dương, Hải Dương",
        "path_with_type": "Phường Nguyễn Trãi, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10519",
        "parent_code": "288"
    },
    "10522": {
        "name": "Phạm Ngũ Lão",
        "type": "phuong",
        "slug": "pham-ngu-lao",
        "name_with_type": "Phường Phạm Ngũ Lão",
        "path": "Phạm Ngũ Lão, Hải Dương, Hải Dương",
        "path_with_type": "Phường Phạm Ngũ Lão, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10522",
        "parent_code": "288"
    },
    "10525": {
        "name": "Trần Hưng Đạo",
        "type": "phuong",
        "slug": "tran-hung-dao",
        "name_with_type": "Phường Trần Hưng Đạo",
        "path": "Trần Hưng Đạo, Hải Dương, Hải Dương",
        "path_with_type": "Phường Trần Hưng Đạo, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10525",
        "parent_code": "288"
    },
    "10528": {
        "name": "Trần Phú",
        "type": "phuong",
        "slug": "tran-phu",
        "name_with_type": "Phường Trần Phú",
        "path": "Trần Phú, Hải Dương, Hải Dương",
        "path_with_type": "Phường Trần Phú, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10528",
        "parent_code": "288"
    },
    "10531": {
        "name": "Thanh Bình",
        "type": "phuong",
        "slug": "thanh-binh",
        "name_with_type": "Phường Thanh Bình",
        "path": "Thanh Bình, Hải Dương, Hải Dương",
        "path_with_type": "Phường Thanh Bình, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10531",
        "parent_code": "288"
    },
    "10532": {
        "name": "Tân Bình",
        "type": "phuong",
        "slug": "tan-binh",
        "name_with_type": "Phường Tân Bình",
        "path": "Tân Bình, Hải Dương, Hải Dương",
        "path_with_type": "Phường Tân Bình, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10532",
        "parent_code": "288"
    },
    "10534": {
        "name": "Lê Thanh Nghị",
        "type": "phuong",
        "slug": "le-thanh-nghi",
        "name_with_type": "Phường Lê Thanh Nghị",
        "path": "Lê Thanh Nghị, Hải Dương, Hải Dương",
        "path_with_type": "Phường Lê Thanh Nghị, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10534",
        "parent_code": "288"
    },
    "10537": {
        "name": "Hải Tân",
        "type": "phuong",
        "slug": "hai-tan",
        "name_with_type": "Phường Hải Tân",
        "path": "Hải Tân, Hải Dương, Hải Dương",
        "path_with_type": "Phường Hải Tân, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10537",
        "parent_code": "288"
    },
    "10540": {
        "name": "Tứ Minh",
        "type": "phuong",
        "slug": "tu-minh",
        "name_with_type": "Phường Tứ Minh",
        "path": "Tứ Minh, Hải Dương, Hải Dương",
        "path_with_type": "Phường Tứ Minh, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10540",
        "parent_code": "288"
    },
    "10543": {
        "name": "Việt Hoà",
        "type": "phuong",
        "slug": "viet-hoa",
        "name_with_type": "Phường Việt Hoà",
        "path": "Việt Hoà, Hải Dương, Hải Dương",
        "path_with_type": "Phường Việt Hoà, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10543",
        "parent_code": "288"
    },
    "10660": {
        "name": "Ái Quốc",
        "type": "phuong",
        "slug": "ai-quoc",
        "name_with_type": "Phường Ái Quốc",
        "path": "Ái Quốc, Hải Dương, Hải Dương",
        "path_with_type": "Phường Ái Quốc, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10660",
        "parent_code": "288"
    },
    "10663": {
        "name": "An Châu",
        "type": "xa",
        "slug": "an-chau",
        "name_with_type": "Xã An Châu",
        "path": "An Châu, Hải Dương, Hải Dương",
        "path_with_type": "Xã An Châu, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10663",
        "parent_code": "288"
    },
    "10669": {
        "name": "Thượng Đạt",
        "type": "xa",
        "slug": "thuong-dat",
        "name_with_type": "Xã Thượng Đạt",
        "path": "Thượng Đạt, Hải Dương, Hải Dương",
        "path_with_type": "Xã Thượng Đạt, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10669",
        "parent_code": "288"
    },
    "10672": {
        "name": "Nam Đồng",
        "type": "xa",
        "slug": "nam-dong",
        "name_with_type": "Xã Nam Đồng",
        "path": "Nam Đồng, Hải Dương, Hải Dương",
        "path_with_type": "Xã Nam Đồng, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "10672",
        "parent_code": "288"
    },
    "11002": {
        "name": "Thạch Khôi",
        "type": "phuong",
        "slug": "thach-khoi",
        "name_with_type": "Phường Thạch Khôi",
        "path": "Thạch Khôi, Hải Dương, Hải Dương",
        "path_with_type": "Phường Thạch Khôi, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "11002",
        "parent_code": "288"
    },
    "11011": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Hải Dương, Hải Dương",
        "path_with_type": "Xã Tân Hưng, Thành phố Hải Dương, Tỉnh Hải Dương",
        "code": "11011",
        "parent_code": "288"
    },
    "10546": {
        "name": "Phả Lại",
        "type": "phuong",
        "slug": "pha-lai",
        "name_with_type": "Phường Phả Lại",
        "path": "Phả Lại, Chí Linh, Hải Dương",
        "path_with_type": "Phường Phả Lại, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10546",
        "parent_code": "290"
    },
    "10549": {
        "name": "Sao Đỏ",
        "type": "phuong",
        "slug": "sao-do",
        "name_with_type": "Phường Sao Đỏ",
        "path": "Sao Đỏ, Chí Linh, Hải Dương",
        "path_with_type": "Phường Sao Đỏ, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10549",
        "parent_code": "290"
    },
    "10552": {
        "name": "Bến Tắm",
        "type": "phuong",
        "slug": "ben-tam",
        "name_with_type": "Phường Bến Tắm",
        "path": "Bến Tắm, Chí Linh, Hải Dương",
        "path_with_type": "Phường Bến Tắm, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10552",
        "parent_code": "290"
    },
    "10555": {
        "name": "Hoàng Hoa Thám",
        "type": "xa",
        "slug": "hoang-hoa-tham",
        "name_with_type": "Xã Hoàng Hoa Thám",
        "path": "Hoàng Hoa Thám, Chí Linh, Hải Dương",
        "path_with_type": "Xã Hoàng Hoa Thám, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10555",
        "parent_code": "290"
    },
    "10558": {
        "name": "Bắc An",
        "type": "xa",
        "slug": "bac-an",
        "name_with_type": "Xã Bắc An",
        "path": "Bắc An, Chí Linh, Hải Dương",
        "path_with_type": "Xã Bắc An, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10558",
        "parent_code": "290"
    },
    "10561": {
        "name": "Hưng Đạo",
        "type": "xa",
        "slug": "hung-dao",
        "name_with_type": "Xã Hưng Đạo",
        "path": "Hưng Đạo, Chí Linh, Hải Dương",
        "path_with_type": "Xã Hưng Đạo, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10561",
        "parent_code": "290"
    },
    "10564": {
        "name": "Lê Lợi",
        "type": "xa",
        "slug": "le-loi",
        "name_with_type": "Xã Lê Lợi",
        "path": "Lê Lợi, Chí Linh, Hải Dương",
        "path_with_type": "Xã Lê Lợi, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10564",
        "parent_code": "290"
    },
    "10567": {
        "name": "Hoàng Tiến",
        "type": "xa",
        "slug": "hoang-tien",
        "name_with_type": "Xã Hoàng Tiến",
        "path": "Hoàng Tiến, Chí Linh, Hải Dương",
        "path_with_type": "Xã Hoàng Tiến, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10567",
        "parent_code": "290"
    },
    "10570": {
        "name": "Cộng Hoà",
        "type": "phuong",
        "slug": "cong-hoa",
        "name_with_type": "Phường Cộng Hoà",
        "path": "Cộng Hoà, Chí Linh, Hải Dương",
        "path_with_type": "Phường Cộng Hoà, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10570",
        "parent_code": "290"
    },
    "10573": {
        "name": "Hoàng Tân",
        "type": "phuong",
        "slug": "hoang-tan",
        "name_with_type": "Phường Hoàng Tân",
        "path": "Hoàng Tân, Chí Linh, Hải Dương",
        "path_with_type": "Phường Hoàng Tân, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10573",
        "parent_code": "290"
    },
    "10576": {
        "name": "Cổ Thành",
        "type": "xa",
        "slug": "co-thanh",
        "name_with_type": "Xã Cổ Thành",
        "path": "Cổ Thành, Chí Linh, Hải Dương",
        "path_with_type": "Xã Cổ Thành, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10576",
        "parent_code": "290"
    },
    "10579": {
        "name": "Văn An",
        "type": "phuong",
        "slug": "van-an",
        "name_with_type": "Phường Văn An",
        "path": "Văn An, Chí Linh, Hải Dương",
        "path_with_type": "Phường Văn An, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10579",
        "parent_code": "290"
    },
    "10582": {
        "name": "Chí Minh",
        "type": "phuong",
        "slug": "chi-minh",
        "name_with_type": "Phường Chí Minh",
        "path": "Chí Minh, Chí Linh, Hải Dương",
        "path_with_type": "Phường Chí Minh, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10582",
        "parent_code": "290"
    },
    "10585": {
        "name": "Văn Đức",
        "type": "xa",
        "slug": "van-duc",
        "name_with_type": "Xã Văn Đức",
        "path": "Văn Đức, Chí Linh, Hải Dương",
        "path_with_type": "Xã Văn Đức, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10585",
        "parent_code": "290"
    },
    "10588": {
        "name": "Thái Học",
        "type": "phuong",
        "slug": "thai-hoc",
        "name_with_type": "Phường Thái Học",
        "path": "Thái Học, Chí Linh, Hải Dương",
        "path_with_type": "Phường Thái Học, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10588",
        "parent_code": "290"
    },
    "10591": {
        "name": "Nhân Huệ",
        "type": "xa",
        "slug": "nhan-hue",
        "name_with_type": "Xã Nhân Huệ",
        "path": "Nhân Huệ, Chí Linh, Hải Dương",
        "path_with_type": "Xã Nhân Huệ, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10591",
        "parent_code": "290"
    },
    "10594": {
        "name": "An Lạc",
        "type": "xa",
        "slug": "an-lac",
        "name_with_type": "Xã An Lạc",
        "path": "An Lạc, Chí Linh, Hải Dương",
        "path_with_type": "Xã An Lạc, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10594",
        "parent_code": "290"
    },
    "10597": {
        "name": "Kênh Giang",
        "type": "xa",
        "slug": "kenh-giang",
        "name_with_type": "Xã Kênh Giang",
        "path": "Kênh Giang, Chí Linh, Hải Dương",
        "path_with_type": "Xã Kênh Giang, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10597",
        "parent_code": "290"
    },
    "10600": {
        "name": "Đồng Lạc",
        "type": "xa",
        "slug": "dong-lac",
        "name_with_type": "Xã Đồng Lạc",
        "path": "Đồng Lạc, Chí Linh, Hải Dương",
        "path_with_type": "Xã Đồng Lạc, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10600",
        "parent_code": "290"
    },
    "10603": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, Chí Linh, Hải Dương",
        "path_with_type": "Xã Tân Dân, Thị xã Chí Linh, Tỉnh Hải Dương",
        "code": "10603",
        "parent_code": "290"
    },
    "10606": {
        "name": "Nam Sách",
        "type": "thi-tran",
        "slug": "nam-sach",
        "name_with_type": "Thị trấn Nam Sách",
        "path": "Nam Sách, Nam Sách, Hải Dương",
        "path_with_type": "Thị trấn Nam Sách, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10606",
        "parent_code": "291"
    },
    "10609": {
        "name": "Nam Hưng",
        "type": "xa",
        "slug": "nam-hung",
        "name_with_type": "Xã Nam Hưng",
        "path": "Nam Hưng, Nam Sách, Hải Dương",
        "path_with_type": "Xã Nam Hưng, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10609",
        "parent_code": "291"
    },
    "10612": {
        "name": "Nam Tân",
        "type": "xa",
        "slug": "nam-tan",
        "name_with_type": "Xã Nam Tân",
        "path": "Nam Tân, Nam Sách, Hải Dương",
        "path_with_type": "Xã Nam Tân, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10612",
        "parent_code": "291"
    },
    "10615": {
        "name": "Hợp Tiến",
        "type": "xa",
        "slug": "hop-tien",
        "name_with_type": "Xã Hợp Tiến",
        "path": "Hợp Tiến, Nam Sách, Hải Dương",
        "path_with_type": "Xã Hợp Tiến, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10615",
        "parent_code": "291"
    },
    "10618": {
        "name": "Hiệp Cát",
        "type": "xa",
        "slug": "hiep-cat",
        "name_with_type": "Xã Hiệp Cát",
        "path": "Hiệp Cát, Nam Sách, Hải Dương",
        "path_with_type": "Xã Hiệp Cát, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10618",
        "parent_code": "291"
    },
    "10621": {
        "name": "Thanh Quang",
        "type": "xa",
        "slug": "thanh-quang",
        "name_with_type": "Xã Thanh Quang",
        "path": "Thanh Quang, Nam Sách, Hải Dương",
        "path_with_type": "Xã Thanh Quang, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10621",
        "parent_code": "291"
    },
    "10624": {
        "name": "Quốc Tuấn",
        "type": "xa",
        "slug": "quoc-tuan",
        "name_with_type": "Xã Quốc Tuấn",
        "path": "Quốc Tuấn, Nam Sách, Hải Dương",
        "path_with_type": "Xã Quốc Tuấn, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10624",
        "parent_code": "291"
    },
    "10627": {
        "name": "Nam Chính",
        "type": "xa",
        "slug": "nam-chinh",
        "name_with_type": "Xã Nam Chính",
        "path": "Nam Chính, Nam Sách, Hải Dương",
        "path_with_type": "Xã Nam Chính, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10627",
        "parent_code": "291"
    },
    "10630": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Nam Sách, Hải Dương",
        "path_with_type": "Xã An Bình, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10630",
        "parent_code": "291"
    },
    "10633": {
        "name": "Nam Trung",
        "type": "xa",
        "slug": "nam-trung",
        "name_with_type": "Xã Nam Trung",
        "path": "Nam Trung, Nam Sách, Hải Dương",
        "path_with_type": "Xã Nam Trung, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10633",
        "parent_code": "291"
    },
    "10636": {
        "name": "An Sơn",
        "type": "xa",
        "slug": "an-son",
        "name_with_type": "Xã An Sơn",
        "path": "An Sơn, Nam Sách, Hải Dương",
        "path_with_type": "Xã An Sơn, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10636",
        "parent_code": "291"
    },
    "10639": {
        "name": "Cộng Hòa",
        "type": "xa",
        "slug": "cong-hoa",
        "name_with_type": "Xã Cộng Hòa",
        "path": "Cộng Hòa, Nam Sách, Hải Dương",
        "path_with_type": "Xã Cộng Hòa, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10639",
        "parent_code": "291"
    },
    "10642": {
        "name": "Thái Tân",
        "type": "xa",
        "slug": "thai-tan",
        "name_with_type": "Xã Thái Tân",
        "path": "Thái Tân, Nam Sách, Hải Dương",
        "path_with_type": "Xã Thái Tân, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10642",
        "parent_code": "291"
    },
    "10645": {
        "name": "An Lâm",
        "type": "xa",
        "slug": "an-lam",
        "name_with_type": "Xã An Lâm",
        "path": "An Lâm, Nam Sách, Hải Dương",
        "path_with_type": "Xã An Lâm, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10645",
        "parent_code": "291"
    },
    "10648": {
        "name": "Phú Điền",
        "type": "xa",
        "slug": "phu-dien",
        "name_with_type": "Xã Phú Điền",
        "path": "Phú Điền, Nam Sách, Hải Dương",
        "path_with_type": "Xã Phú Điền, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10648",
        "parent_code": "291"
    },
    "10651": {
        "name": "Nam Hồng",
        "type": "xa",
        "slug": "nam-hong",
        "name_with_type": "Xã Nam Hồng",
        "path": "Nam Hồng, Nam Sách, Hải Dương",
        "path_with_type": "Xã Nam Hồng, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10651",
        "parent_code": "291"
    },
    "10654": {
        "name": "Hồng Phong",
        "type": "xa",
        "slug": "hong-phong",
        "name_with_type": "Xã Hồng Phong",
        "path": "Hồng Phong, Nam Sách, Hải Dương",
        "path_with_type": "Xã Hồng Phong, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10654",
        "parent_code": "291"
    },
    "10657": {
        "name": "Đồng Lạc",
        "type": "xa",
        "slug": "dong-lac",
        "name_with_type": "Xã Đồng Lạc",
        "path": "Đồng Lạc, Nam Sách, Hải Dương",
        "path_with_type": "Xã Đồng Lạc, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10657",
        "parent_code": "291"
    },
    "10666": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Nam Sách, Hải Dương",
        "path_with_type": "Xã Minh Tân, Huyện Nam Sách, Tỉnh Hải Dương",
        "code": "10666",
        "parent_code": "291"
    },
    "10675": {
        "name": "Kinh Môn",
        "type": "thi-tran",
        "slug": "kinh-mon",
        "name_with_type": "Thị trấn Kinh Môn",
        "path": "Kinh Môn, Kinh Môn, Hải Dương",
        "path_with_type": "Thị trấn Kinh Môn, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10675",
        "parent_code": "292"
    },
    "10678": {
        "name": "Bạch Đằng",
        "type": "xa",
        "slug": "bach-dang",
        "name_with_type": "Xã Bạch Đằng",
        "path": "Bạch Đằng, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Bạch Đằng, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10678",
        "parent_code": "292"
    },
    "10681": {
        "name": "Thất Hùng",
        "type": "xa",
        "slug": "that-hung",
        "name_with_type": "Xã Thất Hùng",
        "path": "Thất Hùng, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Thất Hùng, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10681",
        "parent_code": "292"
    },
    "10684": {
        "name": "Lê Ninh",
        "type": "xa",
        "slug": "le-ninh",
        "name_with_type": "Xã Lê Ninh",
        "path": "Lê Ninh, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Lê Ninh, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10684",
        "parent_code": "292"
    },
    "10687": {
        "name": "Hoành Sơn",
        "type": "xa",
        "slug": "hoanh-son",
        "name_with_type": "Xã Hoành Sơn",
        "path": "Hoành Sơn, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Hoành Sơn, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10687",
        "parent_code": "292"
    },
    "10690": {
        "name": "Phúc Thành B",
        "type": "xa",
        "slug": "phuc-thanh-b",
        "name_with_type": "Xã Phúc Thành B",
        "path": "Phúc Thành B, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Phúc Thành B, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10690",
        "parent_code": "292"
    },
    "10693": {
        "name": "Thái Sơn",
        "type": "xa",
        "slug": "thai-son",
        "name_with_type": "Xã Thái Sơn",
        "path": "Thái Sơn, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Thái Sơn, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10693",
        "parent_code": "292"
    },
    "10696": {
        "name": "Duy Tân",
        "type": "xa",
        "slug": "duy-tan",
        "name_with_type": "Xã Duy Tân",
        "path": "Duy Tân, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Duy Tân, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10696",
        "parent_code": "292"
    },
    "10699": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Tân Dân, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10699",
        "parent_code": "292"
    },
    "10702": {
        "name": "Minh Tân",
        "type": "thi-tran",
        "slug": "minh-tan",
        "name_with_type": "Thị trấn Minh Tân",
        "path": "Minh Tân, Kinh Môn, Hải Dương",
        "path_with_type": "Thị trấn Minh Tân, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10702",
        "parent_code": "292"
    },
    "10705": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Quang Trung, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10705",
        "parent_code": "292"
    },
    "10708": {
        "name": "Hiệp Hòa",
        "type": "xa",
        "slug": "hiep-hoa",
        "name_with_type": "Xã Hiệp Hòa",
        "path": "Hiệp Hòa, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Hiệp Hòa, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10708",
        "parent_code": "292"
    },
    "10711": {
        "name": "Phạm Mệnh",
        "type": "xa",
        "slug": "pham-menh",
        "name_with_type": "Xã Phạm Mệnh",
        "path": "Phạm Mệnh, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Phạm Mệnh, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10711",
        "parent_code": "292"
    },
    "10714": {
        "name": "Phú Thứ",
        "type": "thi-tran",
        "slug": "phu-thu",
        "name_with_type": "Thị trấn Phú Thứ",
        "path": "Phú Thứ, Kinh Môn, Hải Dương",
        "path_with_type": "Thị trấn Phú Thứ, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10714",
        "parent_code": "292"
    },
    "10717": {
        "name": "Thăng Long",
        "type": "xa",
        "slug": "thang-long",
        "name_with_type": "Xã Thăng Long",
        "path": "Thăng Long, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Thăng Long, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10717",
        "parent_code": "292"
    },
    "10720": {
        "name": "Lạc Long",
        "type": "xa",
        "slug": "lac-long",
        "name_with_type": "Xã Lạc Long",
        "path": "Lạc Long, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Lạc Long, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10720",
        "parent_code": "292"
    },
    "10723": {
        "name": "An Sinh",
        "type": "xa",
        "slug": "an-sinh",
        "name_with_type": "Xã An Sinh",
        "path": "An Sinh, Kinh Môn, Hải Dương",
        "path_with_type": "Xã An Sinh, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10723",
        "parent_code": "292"
    },
    "10726": {
        "name": "Hiệp Sơn",
        "type": "xa",
        "slug": "hiep-son",
        "name_with_type": "Xã Hiệp Sơn",
        "path": "Hiệp Sơn, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Hiệp Sơn, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10726",
        "parent_code": "292"
    },
    "10729": {
        "name": "Thượng Quận",
        "type": "xa",
        "slug": "thuong-quan",
        "name_with_type": "Xã Thượng Quận",
        "path": "Thượng Quận, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Thượng Quận, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10729",
        "parent_code": "292"
    },
    "10732": {
        "name": "An Phụ",
        "type": "xa",
        "slug": "an-phu",
        "name_with_type": "Xã An Phụ",
        "path": "An Phụ, Kinh Môn, Hải Dương",
        "path_with_type": "Xã An Phụ, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10732",
        "parent_code": "292"
    },
    "10735": {
        "name": "Hiệp An",
        "type": "xa",
        "slug": "hiep-an",
        "name_with_type": "Xã Hiệp An",
        "path": "Hiệp An, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Hiệp An, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10735",
        "parent_code": "292"
    },
    "10738": {
        "name": "Long Xuyên",
        "type": "xa",
        "slug": "long-xuyen",
        "name_with_type": "Xã Long Xuyên",
        "path": "Long Xuyên, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Long Xuyên, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10738",
        "parent_code": "292"
    },
    "10741": {
        "name": "Thái Thịnh",
        "type": "xa",
        "slug": "thai-thinh",
        "name_with_type": "Xã Thái Thịnh",
        "path": "Thái Thịnh, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Thái Thịnh, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10741",
        "parent_code": "292"
    },
    "10744": {
        "name": "Hiến Thành",
        "type": "xa",
        "slug": "hien-thanh",
        "name_with_type": "Xã Hiến Thành",
        "path": "Hiến Thành, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Hiến Thành, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10744",
        "parent_code": "292"
    },
    "10747": {
        "name": "Minh Hòa",
        "type": "xa",
        "slug": "minh-hoa",
        "name_with_type": "Xã Minh Hòa",
        "path": "Minh Hòa, Kinh Môn, Hải Dương",
        "path_with_type": "Xã Minh Hòa, Huyện Kinh Môn, Tỉnh Hải Dương",
        "code": "10747",
        "parent_code": "292"
    },
    "10750": {
        "name": "Phú Thái",
        "type": "thi-tran",
        "slug": "phu-thai",
        "name_with_type": "Thị trấn Phú Thái",
        "path": "Phú Thái, Kim Thành, Hải Dương",
        "path_with_type": "Thị trấn Phú Thái, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10750",
        "parent_code": "293"
    },
    "10753": {
        "name": "Lai Vu",
        "type": "xa",
        "slug": "lai-vu",
        "name_with_type": "Xã Lai Vu",
        "path": "Lai Vu, Kim Thành, Hải Dương",
        "path_with_type": "Xã Lai Vu, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10753",
        "parent_code": "293"
    },
    "10756": {
        "name": "Cộng Hòa",
        "type": "xa",
        "slug": "cong-hoa",
        "name_with_type": "Xã Cộng Hòa",
        "path": "Cộng Hòa, Kim Thành, Hải Dương",
        "path_with_type": "Xã Cộng Hòa, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10756",
        "parent_code": "293"
    },
    "10759": {
        "name": "Thượng Vũ",
        "type": "xa",
        "slug": "thuong-vu",
        "name_with_type": "Xã Thượng Vũ",
        "path": "Thượng Vũ, Kim Thành, Hải Dương",
        "path_with_type": "Xã Thượng Vũ, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10759",
        "parent_code": "293"
    },
    "10762": {
        "name": "Cổ Dũng",
        "type": "xa",
        "slug": "co-dung",
        "name_with_type": "Xã Cổ Dũng",
        "path": "Cổ Dũng, Kim Thành, Hải Dương",
        "path_with_type": "Xã Cổ Dũng, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10762",
        "parent_code": "293"
    },
    "10765": {
        "name": "Việt Hưng",
        "type": "xa",
        "slug": "viet-hung",
        "name_with_type": "Xã Việt Hưng",
        "path": "Việt Hưng, Kim Thành, Hải Dương",
        "path_with_type": "Xã Việt Hưng, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10765",
        "parent_code": "293"
    },
    "10768": {
        "name": "Tuấn Hưng",
        "type": "xa",
        "slug": "tuan-hung",
        "name_with_type": "Xã Tuấn Hưng",
        "path": "Tuấn Hưng, Kim Thành, Hải Dương",
        "path_with_type": "Xã Tuấn Hưng, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10768",
        "parent_code": "293"
    },
    "10771": {
        "name": "Kim Xuyên",
        "type": "xa",
        "slug": "kim-xuyen",
        "name_with_type": "Xã Kim Xuyên",
        "path": "Kim Xuyên, Kim Thành, Hải Dương",
        "path_with_type": "Xã Kim Xuyên, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10771",
        "parent_code": "293"
    },
    "10774": {
        "name": "Phúc Thành A",
        "type": "xa",
        "slug": "phuc-thanh-a",
        "name_with_type": "Xã Phúc Thành A",
        "path": "Phúc Thành A, Kim Thành, Hải Dương",
        "path_with_type": "Xã Phúc Thành A, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10774",
        "parent_code": "293"
    },
    "10777": {
        "name": "Ngũ Phúc",
        "type": "xa",
        "slug": "ngu-phuc",
        "name_with_type": "Xã Ngũ Phúc",
        "path": "Ngũ Phúc, Kim Thành, Hải Dương",
        "path_with_type": "Xã Ngũ Phúc, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10777",
        "parent_code": "293"
    },
    "10780": {
        "name": "Kim Anh",
        "type": "xa",
        "slug": "kim-anh",
        "name_with_type": "Xã Kim Anh",
        "path": "Kim Anh, Kim Thành, Hải Dương",
        "path_with_type": "Xã Kim Anh, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10780",
        "parent_code": "293"
    },
    "10783": {
        "name": "Kim Lương",
        "type": "xa",
        "slug": "kim-luong",
        "name_with_type": "Xã Kim Lương",
        "path": "Kim Lương, Kim Thành, Hải Dương",
        "path_with_type": "Xã Kim Lương, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10783",
        "parent_code": "293"
    },
    "10786": {
        "name": "Kim Tân",
        "type": "xa",
        "slug": "kim-tan",
        "name_with_type": "Xã Kim Tân",
        "path": "Kim Tân, Kim Thành, Hải Dương",
        "path_with_type": "Xã Kim Tân, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10786",
        "parent_code": "293"
    },
    "10789": {
        "name": "Kim Khê",
        "type": "xa",
        "slug": "kim-khe",
        "name_with_type": "Xã Kim Khê",
        "path": "Kim Khê, Kim Thành, Hải Dương",
        "path_with_type": "Xã Kim Khê, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10789",
        "parent_code": "293"
    },
    "10792": {
        "name": "Kim Đính",
        "type": "xa",
        "slug": "kim-dinh",
        "name_with_type": "Xã Kim Đính",
        "path": "Kim Đính, Kim Thành, Hải Dương",
        "path_with_type": "Xã Kim Đính, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10792",
        "parent_code": "293"
    },
    "10795": {
        "name": "Cẩm La",
        "type": "xa",
        "slug": "cam-la",
        "name_with_type": "Xã Cẩm La",
        "path": "Cẩm La, Kim Thành, Hải Dương",
        "path_with_type": "Xã Cẩm La, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10795",
        "parent_code": "293"
    },
    "10798": {
        "name": "Bình Dân",
        "type": "xa",
        "slug": "binh-dan",
        "name_with_type": "Xã Bình Dân",
        "path": "Bình Dân, Kim Thành, Hải Dương",
        "path_with_type": "Xã Bình Dân, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10798",
        "parent_code": "293"
    },
    "10801": {
        "name": "Tam Kỳ",
        "type": "xa",
        "slug": "tam-ky",
        "name_with_type": "Xã Tam Kỳ",
        "path": "Tam Kỳ, Kim Thành, Hải Dương",
        "path_with_type": "Xã Tam Kỳ, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10801",
        "parent_code": "293"
    },
    "10804": {
        "name": "Đồng Gia",
        "type": "xa",
        "slug": "dong-gia",
        "name_with_type": "Xã Đồng Gia",
        "path": "Đồng Gia, Kim Thành, Hải Dương",
        "path_with_type": "Xã Đồng Gia, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10804",
        "parent_code": "293"
    },
    "10807": {
        "name": "Liên Hòa",
        "type": "xa",
        "slug": "lien-hoa",
        "name_with_type": "Xã Liên Hòa",
        "path": "Liên Hòa, Kim Thành, Hải Dương",
        "path_with_type": "Xã Liên Hòa, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10807",
        "parent_code": "293"
    },
    "10810": {
        "name": "Đại Đức",
        "type": "xa",
        "slug": "dai-duc",
        "name_with_type": "Xã Đại Đức",
        "path": "Đại Đức, Kim Thành, Hải Dương",
        "path_with_type": "Xã Đại Đức, Huyện Kim Thành, Tỉnh Hải Dương",
        "code": "10810",
        "parent_code": "293"
    },
    "10813": {
        "name": "Thanh Hà",
        "type": "thi-tran",
        "slug": "thanh-ha",
        "name_with_type": "Thị trấn Thanh Hà",
        "path": "Thanh Hà, Thanh Hà, Hải Dương",
        "path_with_type": "Thị trấn Thanh Hà, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10813",
        "parent_code": "294"
    },
    "10816": {
        "name": "Hồng Lạc",
        "type": "xa",
        "slug": "hong-lac",
        "name_with_type": "Xã Hồng Lạc",
        "path": "Hồng Lạc, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Hồng Lạc, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10816",
        "parent_code": "294"
    },
    "10819": {
        "name": "Việt Hồng",
        "type": "xa",
        "slug": "viet-hong",
        "name_with_type": "Xã Việt Hồng",
        "path": "Việt Hồng, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Việt Hồng, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10819",
        "parent_code": "294"
    },
    "10822": {
        "name": "Quyết Thắng",
        "type": "xa",
        "slug": "quyet-thang",
        "name_with_type": "Xã Quyết Thắng",
        "path": "Quyết Thắng, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Quyết Thắng, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10822",
        "parent_code": "294"
    },
    "10825": {
        "name": "Tân Việt",
        "type": "xa",
        "slug": "tan-viet",
        "name_with_type": "Xã Tân Việt",
        "path": "Tân Việt, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Tân Việt, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10825",
        "parent_code": "294"
    },
    "10828": {
        "name": "Cẩm Chế",
        "type": "xa",
        "slug": "cam-che",
        "name_with_type": "Xã Cẩm Chế",
        "path": "Cẩm Chế, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Cẩm Chế, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10828",
        "parent_code": "294"
    },
    "10831": {
        "name": "Thanh An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thanh An",
        "path": "Thanh An, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh An, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10831",
        "parent_code": "294"
    },
    "10834": {
        "name": "Thanh Lang",
        "type": "xa",
        "slug": "thanh-lang",
        "name_with_type": "Xã Thanh Lang",
        "path": "Thanh Lang, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Lang, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10834",
        "parent_code": "294"
    },
    "10837": {
        "name": "Tiền Tiến",
        "type": "xa",
        "slug": "tien-tien",
        "name_with_type": "Xã Tiền Tiến",
        "path": "Tiền Tiến, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Tiền Tiến, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10837",
        "parent_code": "294"
    },
    "10840": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Tân An, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10840",
        "parent_code": "294"
    },
    "10843": {
        "name": "Liên Mạc",
        "type": "xa",
        "slug": "lien-mac",
        "name_with_type": "Xã Liên Mạc",
        "path": "Liên Mạc, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Liên Mạc, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10843",
        "parent_code": "294"
    },
    "10846": {
        "name": "Thanh Hải",
        "type": "xa",
        "slug": "thanh-hai",
        "name_with_type": "Xã Thanh Hải",
        "path": "Thanh Hải, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Hải, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10846",
        "parent_code": "294"
    },
    "10849": {
        "name": "Thanh Khê",
        "type": "xa",
        "slug": "thanh-khe",
        "name_with_type": "Xã Thanh Khê",
        "path": "Thanh Khê, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Khê, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10849",
        "parent_code": "294"
    },
    "10852": {
        "name": "Thanh Xá",
        "type": "xa",
        "slug": "thanh-xa",
        "name_with_type": "Xã Thanh Xá",
        "path": "Thanh Xá, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Xá, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10852",
        "parent_code": "294"
    },
    "10855": {
        "name": "Thanh Xuân",
        "type": "xa",
        "slug": "thanh-xuan",
        "name_with_type": "Xã Thanh Xuân",
        "path": "Thanh Xuân, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Xuân, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10855",
        "parent_code": "294"
    },
    "10858": {
        "name": "An Lương",
        "type": "xa",
        "slug": "an-luong",
        "name_with_type": "Xã An Lương",
        "path": "An Lương, Thanh Hà, Hải Dương",
        "path_with_type": "Xã An Lương, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10858",
        "parent_code": "294"
    },
    "10861": {
        "name": "Thanh Thủy",
        "type": "xa",
        "slug": "thanh-thuy",
        "name_with_type": "Xã Thanh Thủy",
        "path": "Thanh Thủy, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Thủy, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10861",
        "parent_code": "294"
    },
    "10864": {
        "name": "Phượng Hoàng",
        "type": "xa",
        "slug": "phuong-hoang",
        "name_with_type": "Xã Phượng Hoàng",
        "path": "Phượng Hoàng, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Phượng Hoàng, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10864",
        "parent_code": "294"
    },
    "10867": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Sơn, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10867",
        "parent_code": "294"
    },
    "10870": {
        "name": "Hợp Đức",
        "type": "xa",
        "slug": "hop-duc",
        "name_with_type": "Xã Hợp Đức",
        "path": "Hợp Đức, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Hợp Đức, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10870",
        "parent_code": "294"
    },
    "10873": {
        "name": "Trường Thành",
        "type": "xa",
        "slug": "truong-thanh",
        "name_with_type": "Xã Trường Thành",
        "path": "Trường Thành, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Trường Thành, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10873",
        "parent_code": "294"
    },
    "10876": {
        "name": "Thanh Bính",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thanh Bính",
        "path": "Thanh Bính, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Bính, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10876",
        "parent_code": "294"
    },
    "10879": {
        "name": "Thanh Hồng",
        "type": "xa",
        "slug": "thanh-hong",
        "name_with_type": "Xã Thanh Hồng",
        "path": "Thanh Hồng, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Hồng, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10879",
        "parent_code": "294"
    },
    "10882": {
        "name": "Thanh Cường",
        "type": "xa",
        "slug": "thanh-cuong",
        "name_with_type": "Xã Thanh Cường",
        "path": "Thanh Cường, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Thanh Cường, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10882",
        "parent_code": "294"
    },
    "10885": {
        "name": "Vĩnh Lập",
        "type": "xa",
        "slug": "vinh-lap",
        "name_with_type": "Xã Vĩnh Lập",
        "path": "Vĩnh Lập, Thanh Hà, Hải Dương",
        "path_with_type": "Xã Vĩnh Lập, Huyện Thanh Hà, Tỉnh Hải Dương",
        "code": "10885",
        "parent_code": "294"
    },
    "10888": {
        "name": "Cẩm Giàng",
        "type": "thi-tran",
        "slug": "cam-giang",
        "name_with_type": "Thị trấn Cẩm Giàng",
        "path": "Cẩm Giàng, Cẩm Giàng, Hải Dương",
        "path_with_type": "Thị trấn Cẩm Giàng, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10888",
        "parent_code": "295"
    },
    "10891": {
        "name": "Lai Cách",
        "type": "thi-tran",
        "slug": "lai-cach",
        "name_with_type": "Thị trấn Lai Cách",
        "path": "Lai Cách, Cẩm Giàng, Hải Dương",
        "path_with_type": "Thị trấn Lai Cách, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10891",
        "parent_code": "295"
    },
    "10894": {
        "name": "Cẩm Hưng",
        "type": "xa",
        "slug": "cam-hung",
        "name_with_type": "Xã Cẩm Hưng",
        "path": "Cẩm Hưng, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Hưng, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10894",
        "parent_code": "295"
    },
    "10897": {
        "name": "Cẩm Hoàng",
        "type": "xa",
        "slug": "cam-hoang",
        "name_with_type": "Xã Cẩm Hoàng",
        "path": "Cẩm Hoàng, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Hoàng, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10897",
        "parent_code": "295"
    },
    "10900": {
        "name": "Cẩm Văn",
        "type": "xa",
        "slug": "cam-van",
        "name_with_type": "Xã Cẩm Văn",
        "path": "Cẩm Văn, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Văn, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10900",
        "parent_code": "295"
    },
    "10903": {
        "name": "Ngọc Liên",
        "type": "xa",
        "slug": "ngoc-lien",
        "name_with_type": "Xã Ngọc Liên",
        "path": "Ngọc Liên, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Ngọc Liên, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10903",
        "parent_code": "295"
    },
    "10906": {
        "name": "Thạch Lỗi",
        "type": "xa",
        "slug": "thach-loi",
        "name_with_type": "Xã Thạch Lỗi",
        "path": "Thạch Lỗi, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Thạch Lỗi, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10906",
        "parent_code": "295"
    },
    "10909": {
        "name": "Cẩm Vũ",
        "type": "xa",
        "slug": "cam-vu",
        "name_with_type": "Xã Cẩm Vũ",
        "path": "Cẩm Vũ, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Vũ, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10909",
        "parent_code": "295"
    },
    "10912": {
        "name": "Đức Chính",
        "type": "xa",
        "slug": "duc-chinh",
        "name_with_type": "Xã Đức Chính",
        "path": "Đức Chính, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Đức Chính, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10912",
        "parent_code": "295"
    },
    "10915": {
        "name": "Cẩm Sơn",
        "type": "xa",
        "slug": "cam-son",
        "name_with_type": "Xã Cẩm Sơn",
        "path": "Cẩm Sơn, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Sơn, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10915",
        "parent_code": "295"
    },
    "10918": {
        "name": "Cẩm Định",
        "type": "xa",
        "slug": "cam-dinh",
        "name_with_type": "Xã Cẩm Định",
        "path": "Cẩm Định, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Định, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10918",
        "parent_code": "295"
    },
    "10921": {
        "name": "Kim Giang",
        "type": "xa",
        "slug": "kim-giang",
        "name_with_type": "Xã Kim Giang",
        "path": "Kim Giang, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Kim Giang, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10921",
        "parent_code": "295"
    },
    "10924": {
        "name": "Lương Điền",
        "type": "xa",
        "slug": "luong-dien",
        "name_with_type": "Xã Lương Điền",
        "path": "Lương Điền, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Lương Điền, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10924",
        "parent_code": "295"
    },
    "10927": {
        "name": "Cao An",
        "type": "xa",
        "slug": "cao-an",
        "name_with_type": "Xã Cao An",
        "path": "Cao An, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cao An, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10927",
        "parent_code": "295"
    },
    "10930": {
        "name": "Tân Trường",
        "type": "xa",
        "slug": "tan-truong",
        "name_with_type": "Xã Tân Trường",
        "path": "Tân Trường, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Tân Trường, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10930",
        "parent_code": "295"
    },
    "10933": {
        "name": "Cẩm Phúc",
        "type": "xa",
        "slug": "cam-phuc",
        "name_with_type": "Xã Cẩm Phúc",
        "path": "Cẩm Phúc, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Phúc, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10933",
        "parent_code": "295"
    },
    "10936": {
        "name": "Cẩm Điền",
        "type": "xa",
        "slug": "cam-dien",
        "name_with_type": "Xã Cẩm Điền",
        "path": "Cẩm Điền, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Điền, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10936",
        "parent_code": "295"
    },
    "10939": {
        "name": "Cẩm Đông",
        "type": "xa",
        "slug": "cam-dong",
        "name_with_type": "Xã Cẩm Đông",
        "path": "Cẩm Đông, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Đông, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10939",
        "parent_code": "295"
    },
    "10942": {
        "name": "Cẩm Đoài",
        "type": "xa",
        "slug": "cam-doai",
        "name_with_type": "Xã Cẩm Đoài",
        "path": "Cẩm Đoài, Cẩm Giàng, Hải Dương",
        "path_with_type": "Xã Cẩm Đoài, Huyện Cẩm Giàng, Tỉnh Hải Dương",
        "code": "10942",
        "parent_code": "295"
    },
    "10945": {
        "name": "Kẻ Sặt",
        "type": "thi-tran",
        "slug": "ke-sat",
        "name_with_type": "Thị trấn Kẻ Sặt",
        "path": "Kẻ Sặt, Bình Giang, Hải Dương",
        "path_with_type": "Thị trấn Kẻ Sặt, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10945",
        "parent_code": "296"
    },
    "10948": {
        "name": "Hưng Thịnh",
        "type": "xa",
        "slug": "hung-thinh",
        "name_with_type": "Xã Hưng Thịnh",
        "path": "Hưng Thịnh, Bình Giang, Hải Dương",
        "path_with_type": "Xã Hưng Thịnh, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10948",
        "parent_code": "296"
    },
    "10951": {
        "name": "Vĩnh Tuy",
        "type": "xa",
        "slug": "vinh-tuy",
        "name_with_type": "Xã Vĩnh Tuy",
        "path": "Vĩnh Tuy, Bình Giang, Hải Dương",
        "path_with_type": "Xã Vĩnh Tuy, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10951",
        "parent_code": "296"
    },
    "10954": {
        "name": "Hùng Thắng",
        "type": "xa",
        "slug": "hung-thang",
        "name_with_type": "Xã Hùng Thắng",
        "path": "Hùng Thắng, Bình Giang, Hải Dương",
        "path_with_type": "Xã Hùng Thắng, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10954",
        "parent_code": "296"
    },
    "10957": {
        "name": "Tráng Liệt",
        "type": "xa",
        "slug": "trang-liet",
        "name_with_type": "Xã Tráng Liệt",
        "path": "Tráng Liệt, Bình Giang, Hải Dương",
        "path_with_type": "Xã Tráng Liệt, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10957",
        "parent_code": "296"
    },
    "10960": {
        "name": "Vĩnh Hồng",
        "type": "xa",
        "slug": "vinh-hong",
        "name_with_type": "Xã Vĩnh Hồng",
        "path": "Vĩnh Hồng, Bình Giang, Hải Dương",
        "path_with_type": "Xã Vĩnh Hồng, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10960",
        "parent_code": "296"
    },
    "10963": {
        "name": "Long Xuyên",
        "type": "xa",
        "slug": "long-xuyen",
        "name_with_type": "Xã Long Xuyên",
        "path": "Long Xuyên, Bình Giang, Hải Dương",
        "path_with_type": "Xã Long Xuyên, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10963",
        "parent_code": "296"
    },
    "10966": {
        "name": "Tân Việt",
        "type": "xa",
        "slug": "tan-viet",
        "name_with_type": "Xã Tân Việt",
        "path": "Tân Việt, Bình Giang, Hải Dương",
        "path_with_type": "Xã Tân Việt, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10966",
        "parent_code": "296"
    },
    "10969": {
        "name": "Thúc Kháng",
        "type": "xa",
        "slug": "thuc-khang",
        "name_with_type": "Xã Thúc Kháng",
        "path": "Thúc Kháng, Bình Giang, Hải Dương",
        "path_with_type": "Xã Thúc Kháng, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10969",
        "parent_code": "296"
    },
    "10972": {
        "name": "Tân Hồng",
        "type": "xa",
        "slug": "tan-hong",
        "name_with_type": "Xã Tân Hồng",
        "path": "Tân Hồng, Bình Giang, Hải Dương",
        "path_with_type": "Xã Tân Hồng, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10972",
        "parent_code": "296"
    },
    "10975": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Bình Giang, Hải Dương",
        "path_with_type": "Xã Bình Minh, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10975",
        "parent_code": "296"
    },
    "10978": {
        "name": "Hồng Khê",
        "type": "xa",
        "slug": "hong-khe",
        "name_with_type": "Xã Hồng Khê",
        "path": "Hồng Khê, Bình Giang, Hải Dương",
        "path_with_type": "Xã Hồng Khê, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10978",
        "parent_code": "296"
    },
    "10981": {
        "name": "Thái Học",
        "type": "xa",
        "slug": "thai-hoc",
        "name_with_type": "Xã Thái Học",
        "path": "Thái Học, Bình Giang, Hải Dương",
        "path_with_type": "Xã Thái Học, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10981",
        "parent_code": "296"
    },
    "10984": {
        "name": "Cổ Bi",
        "type": "xa",
        "slug": "co-bi",
        "name_with_type": "Xã Cổ Bi",
        "path": "Cổ Bi, Bình Giang, Hải Dương",
        "path_with_type": "Xã Cổ Bi, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10984",
        "parent_code": "296"
    },
    "10987": {
        "name": "Nhân Quyền",
        "type": "xa",
        "slug": "nhan-quyen",
        "name_with_type": "Xã Nhân Quyền",
        "path": "Nhân Quyền, Bình Giang, Hải Dương",
        "path_with_type": "Xã Nhân Quyền, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10987",
        "parent_code": "296"
    },
    "10990": {
        "name": "Thái Dương",
        "type": "xa",
        "slug": "thai-duong",
        "name_with_type": "Xã Thái Dương",
        "path": "Thái Dương, Bình Giang, Hải Dương",
        "path_with_type": "Xã Thái Dương, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10990",
        "parent_code": "296"
    },
    "10993": {
        "name": "Thái Hòa",
        "type": "xa",
        "slug": "thai-hoa",
        "name_with_type": "Xã Thái Hòa",
        "path": "Thái Hòa, Bình Giang, Hải Dương",
        "path_with_type": "Xã Thái Hòa, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10993",
        "parent_code": "296"
    },
    "10996": {
        "name": "Bình Xuyên",
        "type": "xa",
        "slug": "binh-xuyen",
        "name_with_type": "Xã Bình Xuyên",
        "path": "Bình Xuyên, Bình Giang, Hải Dương",
        "path_with_type": "Xã Bình Xuyên, Huyện Bình Giang, Tỉnh Hải Dương",
        "code": "10996",
        "parent_code": "296"
    },
    "10999": {
        "name": "Gia Lộc",
        "type": "thi-tran",
        "slug": "gia-loc",
        "name_with_type": "Thị trấn Gia Lộc",
        "path": "Gia Lộc, Gia Lộc, Hải Dương",
        "path_with_type": "Thị trấn Gia Lộc, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "10999",
        "parent_code": "297"
    },
    "11005": {
        "name": "Liên Hồng",
        "type": "xa",
        "slug": "lien-hong",
        "name_with_type": "Xã Liên Hồng",
        "path": "Liên Hồng, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Liên Hồng, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11005",
        "parent_code": "297"
    },
    "11008": {
        "name": "Thống Nhất",
        "type": "xa",
        "slug": "thong-nhat",
        "name_with_type": "Xã Thống Nhất",
        "path": "Thống Nhất, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Thống Nhất, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11008",
        "parent_code": "297"
    },
    "11014": {
        "name": "Trùng Khánh",
        "type": "xa",
        "slug": "trung-khanh",
        "name_with_type": "Xã Trùng Khánh",
        "path": "Trùng Khánh, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Trùng Khánh, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11014",
        "parent_code": "297"
    },
    "11017": {
        "name": "Gia Xuyên",
        "type": "xa",
        "slug": "gia-xuyen",
        "name_with_type": "Xã Gia Xuyên",
        "path": "Gia Xuyên, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Gia Xuyên, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11017",
        "parent_code": "297"
    },
    "11020": {
        "name": "Yết Kiêu",
        "type": "xa",
        "slug": "yet-kieu",
        "name_with_type": "Xã Yết Kiêu",
        "path": "Yết Kiêu, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Yết Kiêu, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11020",
        "parent_code": "297"
    },
    "11023": {
        "name": "Gia Hòa",
        "type": "xa",
        "slug": "gia-hoa",
        "name_with_type": "Xã Gia Hòa",
        "path": "Gia Hòa, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Gia Hòa, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11023",
        "parent_code": "297"
    },
    "11026": {
        "name": "Phương Hưng",
        "type": "xa",
        "slug": "phuong-hung",
        "name_with_type": "Xã Phương Hưng",
        "path": "Phương Hưng, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Phương Hưng, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11026",
        "parent_code": "297"
    },
    "11029": {
        "name": "Gia Tân",
        "type": "xa",
        "slug": "gia-tan",
        "name_with_type": "Xã Gia Tân",
        "path": "Gia Tân, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Gia Tân, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11029",
        "parent_code": "297"
    },
    "11032": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Tân Tiến, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11032",
        "parent_code": "297"
    },
    "11035": {
        "name": "Gia Khánh",
        "type": "xa",
        "slug": "gia-khanh",
        "name_with_type": "Xã Gia Khánh",
        "path": "Gia Khánh, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Gia Khánh, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11035",
        "parent_code": "297"
    },
    "11038": {
        "name": "Gia Lương",
        "type": "xa",
        "slug": "gia-luong",
        "name_with_type": "Xã Gia Lương",
        "path": "Gia Lương, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Gia Lương, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11038",
        "parent_code": "297"
    },
    "11041": {
        "name": "Lê Lợi",
        "type": "xa",
        "slug": "le-loi",
        "name_with_type": "Xã Lê Lợi",
        "path": "Lê Lợi, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Lê Lợi, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11041",
        "parent_code": "297"
    },
    "11044": {
        "name": "Toàn Thắng",
        "type": "xa",
        "slug": "toan-thang",
        "name_with_type": "Xã Toàn Thắng",
        "path": "Toàn Thắng, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Toàn Thắng, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11044",
        "parent_code": "297"
    },
    "11047": {
        "name": "Hoàng Diệu",
        "type": "xa",
        "slug": "hoang-dieu",
        "name_with_type": "Xã Hoàng Diệu",
        "path": "Hoàng Diệu, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Hoàng Diệu, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11047",
        "parent_code": "297"
    },
    "11050": {
        "name": "Hồng Hưng",
        "type": "xa",
        "slug": "hong-hung",
        "name_with_type": "Xã Hồng Hưng",
        "path": "Hồng Hưng, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Hồng Hưng, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11050",
        "parent_code": "297"
    },
    "11053": {
        "name": "Phạm Trấn",
        "type": "xa",
        "slug": "pham-tran",
        "name_with_type": "Xã Phạm Trấn",
        "path": "Phạm Trấn, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Phạm Trấn, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11053",
        "parent_code": "297"
    },
    "11056": {
        "name": "Đoàn Thượng",
        "type": "xa",
        "slug": "doan-thuong",
        "name_with_type": "Xã Đoàn Thượng",
        "path": "Đoàn Thượng, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Đoàn Thượng, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11056",
        "parent_code": "297"
    },
    "11059": {
        "name": "Thống Kênh",
        "type": "xa",
        "slug": "thong-kenh",
        "name_with_type": "Xã Thống Kênh",
        "path": "Thống Kênh, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Thống Kênh, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11059",
        "parent_code": "297"
    },
    "11062": {
        "name": "Quang Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quang Minh",
        "path": "Quang Minh, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Quang Minh, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11062",
        "parent_code": "297"
    },
    "11065": {
        "name": "Đồng Quang",
        "type": "xa",
        "slug": "dong-quang",
        "name_with_type": "Xã Đồng Quang",
        "path": "Đồng Quang, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Đồng Quang, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11065",
        "parent_code": "297"
    },
    "11068": {
        "name": "Nhật Tân",
        "type": "xa",
        "slug": "nhat-tan",
        "name_with_type": "Xã Nhật Tân",
        "path": "Nhật Tân, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Nhật Tân, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11068",
        "parent_code": "297"
    },
    "11071": {
        "name": "Đức Xương",
        "type": "xa",
        "slug": "duc-xuong",
        "name_with_type": "Xã Đức Xương",
        "path": "Đức Xương, Gia Lộc, Hải Dương",
        "path_with_type": "Xã Đức Xương, Huyện Gia Lộc, Tỉnh Hải Dương",
        "code": "11071",
        "parent_code": "297"
    },
    "11074": {
        "name": "Tứ Kỳ",
        "type": "thi-tran",
        "slug": "tu-ky",
        "name_with_type": "Thị trấn Tứ Kỳ",
        "path": "Tứ Kỳ, Tứ Kỳ, Hải Dương",
        "path_with_type": "Thị trấn Tứ Kỳ, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11074",
        "parent_code": "298"
    },
    "11077": {
        "name": "Ngọc Sơn",
        "type": "xa",
        "slug": "ngoc-son",
        "name_with_type": "Xã Ngọc Sơn",
        "path": "Ngọc Sơn, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Ngọc Sơn, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11077",
        "parent_code": "298"
    },
    "11080": {
        "name": "Kỳ Sơn",
        "type": "xa",
        "slug": "ky-son",
        "name_with_type": "Xã Kỳ Sơn",
        "path": "Kỳ Sơn, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Kỳ Sơn, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11080",
        "parent_code": "298"
    },
    "11083": {
        "name": "Đại Đồng",
        "type": "xa",
        "slug": "dai-dong",
        "name_with_type": "Xã Đại Đồng",
        "path": "Đại Đồng, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Đại Đồng, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11083",
        "parent_code": "298"
    },
    "11086": {
        "name": "Hưng Đạo",
        "type": "xa",
        "slug": "hung-dao",
        "name_with_type": "Xã Hưng Đạo",
        "path": "Hưng Đạo, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Hưng Đạo, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11086",
        "parent_code": "298"
    },
    "11089": {
        "name": "Ngọc Kỳ",
        "type": "xa",
        "slug": "ngoc-ky",
        "name_with_type": "Xã Ngọc Kỳ",
        "path": "Ngọc Kỳ, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Ngọc Kỳ, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11089",
        "parent_code": "298"
    },
    "11092": {
        "name": "Bình Lăng",
        "type": "xa",
        "slug": "binh-lang",
        "name_with_type": "Xã Bình Lăng",
        "path": "Bình Lăng, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Bình Lăng, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11092",
        "parent_code": "298"
    },
    "11095": {
        "name": "Tứ Xuyên",
        "type": "xa",
        "slug": "tu-xuyen",
        "name_with_type": "Xã Tứ Xuyên",
        "path": "Tứ Xuyên, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Tứ Xuyên, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11095",
        "parent_code": "298"
    },
    "11098": {
        "name": "Tái Sơn",
        "type": "xa",
        "slug": "tai-son",
        "name_with_type": "Xã Tái Sơn",
        "path": "Tái Sơn, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Tái Sơn, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11098",
        "parent_code": "298"
    },
    "11101": {
        "name": "Quang Phục",
        "type": "xa",
        "slug": "quang-phuc",
        "name_with_type": "Xã Quang Phục",
        "path": "Quang Phục, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Quang Phục, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11101",
        "parent_code": "298"
    },
    "11104": {
        "name": "Đông Kỳ",
        "type": "xa",
        "slug": "dong-ky",
        "name_with_type": "Xã Đông Kỳ",
        "path": "Đông Kỳ, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Đông Kỳ, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11104",
        "parent_code": "298"
    },
    "11107": {
        "name": "Tây Kỳ",
        "type": "xa",
        "slug": "tay-ky",
        "name_with_type": "Xã Tây Kỳ",
        "path": "Tây Kỳ, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Tây Kỳ, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11107",
        "parent_code": "298"
    },
    "11110": {
        "name": "Dân Chủ",
        "type": "xa",
        "slug": "dan-chu",
        "name_with_type": "Xã Dân Chủ",
        "path": "Dân Chủ, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Dân Chủ, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11110",
        "parent_code": "298"
    },
    "11113": {
        "name": "Tân Kỳ",
        "type": "xa",
        "slug": "tan-ky",
        "name_with_type": "Xã Tân Kỳ",
        "path": "Tân Kỳ, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Tân Kỳ, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11113",
        "parent_code": "298"
    },
    "11116": {
        "name": "Quang Khải",
        "type": "xa",
        "slug": "quang-khai",
        "name_with_type": "Xã Quang Khải",
        "path": "Quang Khải, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Quang Khải, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11116",
        "parent_code": "298"
    },
    "11119": {
        "name": "Đại Hợp",
        "type": "xa",
        "slug": "dai-hop",
        "name_with_type": "Xã Đại Hợp",
        "path": "Đại Hợp, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Đại Hợp, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11119",
        "parent_code": "298"
    },
    "11122": {
        "name": "Quảng Nghiệp",
        "type": "xa",
        "slug": "quang-nghiep",
        "name_with_type": "Xã Quảng Nghiệp",
        "path": "Quảng Nghiệp, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Quảng Nghiệp, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11122",
        "parent_code": "298"
    },
    "11125": {
        "name": "An Thanh",
        "type": "xa",
        "slug": "an-thanh",
        "name_with_type": "Xã An Thanh",
        "path": "An Thanh, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã An Thanh, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11125",
        "parent_code": "298"
    },
    "11128": {
        "name": "Minh Đức",
        "type": "xa",
        "slug": "minh-duc",
        "name_with_type": "Xã Minh Đức",
        "path": "Minh Đức, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Minh Đức, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11128",
        "parent_code": "298"
    },
    "11131": {
        "name": "Văn Tố",
        "type": "xa",
        "slug": "van-to",
        "name_with_type": "Xã Văn Tố",
        "path": "Văn Tố, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Văn Tố, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11131",
        "parent_code": "298"
    },
    "11134": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Quang Trung, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11134",
        "parent_code": "298"
    },
    "11137": {
        "name": "Phượng Kỳ",
        "type": "xa",
        "slug": "phuong-ky",
        "name_with_type": "Xã Phượng Kỳ",
        "path": "Phượng Kỳ, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Phượng Kỳ, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11137",
        "parent_code": "298"
    },
    "11140": {
        "name": "Cộng Lạc",
        "type": "xa",
        "slug": "cong-lac",
        "name_with_type": "Xã Cộng Lạc",
        "path": "Cộng Lạc, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Cộng Lạc, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11140",
        "parent_code": "298"
    },
    "11143": {
        "name": "Tiên Động",
        "type": "xa",
        "slug": "tien-dong",
        "name_with_type": "Xã Tiên Động",
        "path": "Tiên Động, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Tiên Động, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11143",
        "parent_code": "298"
    },
    "11146": {
        "name": "Nguyên Giáp",
        "type": "xa",
        "slug": "nguyen-giap",
        "name_with_type": "Xã Nguyên Giáp",
        "path": "Nguyên Giáp, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Nguyên Giáp, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11146",
        "parent_code": "298"
    },
    "11149": {
        "name": "Hà Kỳ",
        "type": "xa",
        "slug": "ha-ky",
        "name_with_type": "Xã Hà Kỳ",
        "path": "Hà Kỳ, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Hà Kỳ, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11149",
        "parent_code": "298"
    },
    "11152": {
        "name": "Hà Thanh",
        "type": "xa",
        "slug": "ha-thanh",
        "name_with_type": "Xã Hà Thanh",
        "path": "Hà Thanh, Tứ Kỳ, Hải Dương",
        "path_with_type": "Xã Hà Thanh, Huyện Tứ Kỳ, Tỉnh Hải Dương",
        "code": "11152",
        "parent_code": "298"
    },
    "11155": {
        "name": "Ninh Giang",
        "type": "thi-tran",
        "slug": "ninh-giang",
        "name_with_type": "Thị trấn Ninh Giang",
        "path": "Ninh Giang, Ninh Giang, Hải Dương",
        "path_with_type": "Thị trấn Ninh Giang, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11155",
        "parent_code": "299"
    },
    "11158": {
        "name": "Quyết Thắng",
        "type": "xa",
        "slug": "quyet-thang",
        "name_with_type": "Xã Quyết Thắng",
        "path": "Quyết Thắng, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Quyết Thắng, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11158",
        "parent_code": "299"
    },
    "11161": {
        "name": "Ứng Hoè",
        "type": "xa",
        "slug": "ung-hoe",
        "name_with_type": "Xã Ứng Hoè",
        "path": "Ứng Hoè, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Ứng Hoè, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11161",
        "parent_code": "299"
    },
    "11164": {
        "name": "Nghĩa An",
        "type": "xa",
        "slug": "nghia-an",
        "name_with_type": "Xã Nghĩa An",
        "path": "Nghĩa An, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Nghĩa An, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11164",
        "parent_code": "299"
    },
    "11167": {
        "name": "Hồng Đức",
        "type": "xa",
        "slug": "hong-duc",
        "name_with_type": "Xã Hồng Đức",
        "path": "Hồng Đức, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Hồng Đức, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11167",
        "parent_code": "299"
    },
    "11170": {
        "name": "Ninh Hòa",
        "type": "xa",
        "slug": "ninh-hoa",
        "name_with_type": "Xã Ninh Hòa",
        "path": "Ninh Hòa, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Ninh Hòa, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11170",
        "parent_code": "299"
    },
    "11173": {
        "name": "An Đức",
        "type": "xa",
        "slug": "an-duc",
        "name_with_type": "Xã An Đức",
        "path": "An Đức, Ninh Giang, Hải Dương",
        "path_with_type": "Xã An Đức, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11173",
        "parent_code": "299"
    },
    "11176": {
        "name": "Vạn Phúc",
        "type": "xa",
        "slug": "van-phuc",
        "name_with_type": "Xã Vạn Phúc",
        "path": "Vạn Phúc, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Vạn Phúc, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11176",
        "parent_code": "299"
    },
    "11179": {
        "name": "Tân Hương",
        "type": "xa",
        "slug": "tan-huong",
        "name_with_type": "Xã Tân Hương",
        "path": "Tân Hương, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Tân Hương, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11179",
        "parent_code": "299"
    },
    "11182": {
        "name": "Ninh Thành",
        "type": "xa",
        "slug": "ninh-thanh",
        "name_with_type": "Xã Ninh Thành",
        "path": "Ninh Thành, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Ninh Thành, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11182",
        "parent_code": "299"
    },
    "11185": {
        "name": "Vĩnh Hòa",
        "type": "xa",
        "slug": "vinh-hoa",
        "name_with_type": "Xã Vĩnh Hòa",
        "path": "Vĩnh Hòa, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Vĩnh Hòa, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11185",
        "parent_code": "299"
    },
    "11188": {
        "name": "Đông Xuyên",
        "type": "xa",
        "slug": "dong-xuyen",
        "name_with_type": "Xã Đông Xuyên",
        "path": "Đông Xuyên, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Đông Xuyên, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11188",
        "parent_code": "299"
    },
    "11191": {
        "name": "Hoàng Hanh",
        "type": "xa",
        "slug": "hoang-hanh",
        "name_with_type": "Xã Hoàng Hanh",
        "path": "Hoàng Hanh, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Hoàng Hanh, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11191",
        "parent_code": "299"
    },
    "11194": {
        "name": "Quang Hưng",
        "type": "xa",
        "slug": "quang-hung",
        "name_with_type": "Xã Quang Hưng",
        "path": "Quang Hưng, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Quang Hưng, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11194",
        "parent_code": "299"
    },
    "11197": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Tân Phong, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11197",
        "parent_code": "299"
    },
    "11200": {
        "name": "Ninh Hải",
        "type": "xa",
        "slug": "ninh-hai",
        "name_with_type": "Xã Ninh Hải",
        "path": "Ninh Hải, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Ninh Hải, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11200",
        "parent_code": "299"
    },
    "11203": {
        "name": "Đồng Tâm",
        "type": "xa",
        "slug": "dong-tam",
        "name_with_type": "Xã Đồng Tâm",
        "path": "Đồng Tâm, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Đồng Tâm, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11203",
        "parent_code": "299"
    },
    "11206": {
        "name": "Tân Quang",
        "type": "xa",
        "slug": "tan-quang",
        "name_with_type": "Xã Tân Quang",
        "path": "Tân Quang, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Tân Quang, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11206",
        "parent_code": "299"
    },
    "11209": {
        "name": "Kiến Quốc",
        "type": "xa",
        "slug": "kien-quoc",
        "name_with_type": "Xã Kiến Quốc",
        "path": "Kiến Quốc, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Kiến Quốc, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11209",
        "parent_code": "299"
    },
    "11212": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Hồng Thái, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11212",
        "parent_code": "299"
    },
    "11215": {
        "name": "Hồng Dụ",
        "type": "xa",
        "slug": "hong-du",
        "name_with_type": "Xã Hồng Dụ",
        "path": "Hồng Dụ, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Hồng Dụ, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11215",
        "parent_code": "299"
    },
    "11218": {
        "name": "Văn Hội",
        "type": "xa",
        "slug": "van-hoi",
        "name_with_type": "Xã Văn Hội",
        "path": "Văn Hội, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Văn Hội, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11218",
        "parent_code": "299"
    },
    "11221": {
        "name": "Hưng Thái",
        "type": "xa",
        "slug": "hung-thai",
        "name_with_type": "Xã Hưng Thái",
        "path": "Hưng Thái, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Hưng Thái, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11221",
        "parent_code": "299"
    },
    "11224": {
        "name": "Hồng Phong",
        "type": "xa",
        "slug": "hong-phong",
        "name_with_type": "Xã Hồng Phong",
        "path": "Hồng Phong, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Hồng Phong, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11224",
        "parent_code": "299"
    },
    "11227": {
        "name": "Hiệp Lực",
        "type": "xa",
        "slug": "hiep-luc",
        "name_with_type": "Xã Hiệp Lực",
        "path": "Hiệp Lực, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Hiệp Lực, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11227",
        "parent_code": "299"
    },
    "11230": {
        "name": "Hồng Phúc",
        "type": "xa",
        "slug": "hong-phuc",
        "name_with_type": "Xã Hồng Phúc",
        "path": "Hồng Phúc, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Hồng Phúc, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11230",
        "parent_code": "299"
    },
    "11233": {
        "name": "Hưng Long",
        "type": "xa",
        "slug": "hung-long",
        "name_with_type": "Xã Hưng Long",
        "path": "Hưng Long, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Hưng Long, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11233",
        "parent_code": "299"
    },
    "11236": {
        "name": "Văn Giang",
        "type": "xa",
        "slug": "van-giang",
        "name_with_type": "Xã Văn Giang",
        "path": "Văn Giang, Ninh Giang, Hải Dương",
        "path_with_type": "Xã Văn Giang, Huyện Ninh Giang, Tỉnh Hải Dương",
        "code": "11236",
        "parent_code": "299"
    },
    "11239": {
        "name": "Thanh Miện",
        "type": "thi-tran",
        "slug": "thanh-mien",
        "name_with_type": "Thị trấn Thanh Miện",
        "path": "Thanh Miện, Thanh Miện, Hải Dương",
        "path_with_type": "Thị trấn Thanh Miện, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11239",
        "parent_code": "300"
    },
    "11242": {
        "name": "Thanh Tùng",
        "type": "xa",
        "slug": "thanh-tung",
        "name_with_type": "Xã Thanh Tùng",
        "path": "Thanh Tùng, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Thanh Tùng, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11242",
        "parent_code": "300"
    },
    "11245": {
        "name": "Phạm Kha",
        "type": "xa",
        "slug": "pham-kha",
        "name_with_type": "Xã Phạm Kha",
        "path": "Phạm Kha, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Phạm Kha, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11245",
        "parent_code": "300"
    },
    "11248": {
        "name": "Ngô Quyền",
        "type": "xa",
        "slug": "ngo-quyen",
        "name_with_type": "Xã Ngô Quyền",
        "path": "Ngô Quyền, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Ngô Quyền, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11248",
        "parent_code": "300"
    },
    "11251": {
        "name": "Đoàn Tùng",
        "type": "xa",
        "slug": "doan-tung",
        "name_with_type": "Xã Đoàn Tùng",
        "path": "Đoàn Tùng, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Đoàn Tùng, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11251",
        "parent_code": "300"
    },
    "11254": {
        "name": "Hồng Quang",
        "type": "xa",
        "slug": "hong-quang",
        "name_with_type": "Xã Hồng Quang",
        "path": "Hồng Quang, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Hồng Quang, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11254",
        "parent_code": "300"
    },
    "11257": {
        "name": "Tân Trào",
        "type": "xa",
        "slug": "tan-trao",
        "name_with_type": "Xã Tân Trào",
        "path": "Tân Trào, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Tân Trào, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11257",
        "parent_code": "300"
    },
    "11260": {
        "name": "Lam Sơn",
        "type": "xa",
        "slug": "lam-son",
        "name_with_type": "Xã Lam Sơn",
        "path": "Lam Sơn, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Lam Sơn, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11260",
        "parent_code": "300"
    },
    "11263": {
        "name": "Đoàn Kết",
        "type": "xa",
        "slug": "doan-ket",
        "name_with_type": "Xã Đoàn Kết",
        "path": "Đoàn Kết, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Đoàn Kết, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11263",
        "parent_code": "300"
    },
    "11266": {
        "name": "Lê Hồng",
        "type": "xa",
        "slug": "le-hong",
        "name_with_type": "Xã Lê Hồng",
        "path": "Lê Hồng, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Lê Hồng, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11266",
        "parent_code": "300"
    },
    "11269": {
        "name": "Tứ Cường",
        "type": "xa",
        "slug": "tu-cuong",
        "name_with_type": "Xã Tứ Cường",
        "path": "Tứ Cường, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Tứ Cường, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11269",
        "parent_code": "300"
    },
    "11272": {
        "name": "Hùng Sơn",
        "type": "xa",
        "slug": "hung-son",
        "name_with_type": "Xã Hùng Sơn",
        "path": "Hùng Sơn, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Hùng Sơn, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11272",
        "parent_code": "300"
    },
    "11275": {
        "name": "Ngũ Hùng",
        "type": "xa",
        "slug": "ngu-hung",
        "name_with_type": "Xã Ngũ Hùng",
        "path": "Ngũ Hùng, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Ngũ Hùng, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11275",
        "parent_code": "300"
    },
    "11278": {
        "name": "Cao Thắng",
        "type": "xa",
        "slug": "cao-thang",
        "name_with_type": "Xã Cao Thắng",
        "path": "Cao Thắng, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Cao Thắng, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11278",
        "parent_code": "300"
    },
    "11281": {
        "name": "Chi Lăng Bắc",
        "type": "xa",
        "slug": "chi-lang-bac",
        "name_with_type": "Xã Chi Lăng Bắc",
        "path": "Chi Lăng Bắc, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Chi Lăng Bắc, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11281",
        "parent_code": "300"
    },
    "11284": {
        "name": "Chi Lăng Nam",
        "type": "xa",
        "slug": "chi-lang-nam",
        "name_with_type": "Xã Chi Lăng Nam",
        "path": "Chi Lăng Nam, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Chi Lăng Nam, Huyện Thanh Miện, Tỉnh Hải D��ơng",
        "code": "11284",
        "parent_code": "300"
    },
    "11287": {
        "name": "Thanh Giang",
        "type": "xa",
        "slug": "thanh-giang",
        "name_with_type": "Xã Thanh Giang",
        "path": "Thanh Giang, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Thanh Giang, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11287",
        "parent_code": "300"
    },
    "11290": {
        "name": "Diên Hồng",
        "type": "xa",
        "slug": "dien-hong",
        "name_with_type": "Xã Diên Hồng",
        "path": "Diên Hồng, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Diên Hồng, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11290",
        "parent_code": "300"
    },
    "11293": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Thanh Miện, Hải Dương",
        "path_with_type": "Xã Tiền Phong, Huyện Thanh Miện, Tỉnh Hải Dương",
        "code": "11293",
        "parent_code": "300"
    },
    "31813": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Phường 2, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31813",
        "parent_code": "954"
    },
    "31816": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Phường 3, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31816",
        "parent_code": "954"
    },
    "31819": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Phường 5, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31819",
        "parent_code": "954"
    },
    "31822": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Phường 7, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31822",
        "parent_code": "954"
    },
    "31825": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Phường 1, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31825",
        "parent_code": "954"
    },
    "31828": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Phường 8, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31828",
        "parent_code": "954"
    },
    "31831": {
        "name": "Nhà Mát",
        "type": "phuong",
        "slug": "nha-mat",
        "name_with_type": "Phường Nhà Mát",
        "path": "Nhà Mát, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Phường Nhà Mát, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31831",
        "parent_code": "954"
    },
    "31834": {
        "name": "Vĩnh Trạch",
        "type": "xa",
        "slug": "vinh-trach",
        "name_with_type": "Xã Vĩnh Trạch",
        "path": "Vĩnh Trạch, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Trạch, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31834",
        "parent_code": "954"
    },
    "31837": {
        "name": "Vĩnh Trạch Đông",
        "type": "xa",
        "slug": "vinh-trach-dong",
        "name_with_type": "Xã Vĩnh Trạch Đông",
        "path": "Vĩnh Trạch Đông, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Trạch Đông, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31837",
        "parent_code": "954"
    },
    "31840": {
        "name": "Hiệp Thành",
        "type": "xa",
        "slug": "hiep-thanh",
        "name_with_type": "Xã Hiệp Thành",
        "path": "Hiệp Thành, Bạc Liêu, Bạc Liêu",
        "path_with_type": "Xã Hiệp Thành, Thành phố Bạc Liêu, Tỉnh Bạc Liêu",
        "code": "31840",
        "parent_code": "954"
    },
    "31843": {
        "name": "Ngan Dừa",
        "type": "thi-tran",
        "slug": "ngan-dua",
        "name_with_type": "Thị trấn Ngan Dừa",
        "path": "Ngan Dừa, Hồng Dân, Bạc Liêu",
        "path_with_type": "Thị trấn Ngan Dừa, Huyện Hồng Dân, Tỉnh Bạc Liêu",
        "code": "31843",
        "parent_code": "956"
    },
    "31846": {
        "name": "Ninh Quới",
        "type": "xa",
        "slug": "ninh-quoi",
        "name_with_type": "Xã Ninh Quới",
        "path": "Ninh Quới, Hồng Dân, Bạc Liêu",
        "path_with_type": "Xã Ninh Quới, Huyện Hồng Dân, Tỉnh Bạc Liêu",
        "code": "31846",
        "parent_code": "956"
    },
    "31849": {
        "name": "Ninh Quới A",
        "type": "xa",
        "slug": "ninh-quoi-a",
        "name_with_type": "Xã Ninh Quới A",
        "path": "Ninh Quới A, Hồng Dân, Bạc Liêu",
        "path_with_type": "Xã Ninh Quới A, Huyện Hồng Dân, Tỉnh Bạc Liêu",
        "code": "31849",
        "parent_code": "956"
    },
    "31852": {
        "name": "Ninh Hòa",
        "type": "xa",
        "slug": "ninh-hoa",
        "name_with_type": "Xã Ninh Hòa",
        "path": "Ninh Hòa, Hồng Dân, Bạc Liêu",
        "path_with_type": "Xã Ninh Hòa, Huyện Hồng Dân, Tỉnh Bạc Liêu",
        "code": "31852",
        "parent_code": "956"
    },
    "31855": {
        "name": "Lộc Ninh",
        "type": "xa",
        "slug": "loc-ninh",
        "name_with_type": "Xã Lộc Ninh",
        "path": "Lộc Ninh, Hồng Dân, Bạc Liêu",
        "path_with_type": "Xã Lộc Ninh, Huyện Hồng Dân, Tỉnh Bạc Liêu",
        "code": "31855",
        "parent_code": "956"
    },
    "31858": {
        "name": "Vĩnh Lộc",
        "type": "xa",
        "slug": "vinh-loc",
        "name_with_type": "Xã Vĩnh Lộc",
        "path": "Vĩnh Lộc, Hồng Dân, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Lộc, Huyện Hồng Dân, Tỉnh Bạc Liêu",
        "code": "31858",
        "parent_code": "956"
    },
    "31861": {
        "name": "Vĩnh Lộc A",
        "type": "xa",
        "slug": "vinh-loc-a",
        "name_with_type": "Xã Vĩnh Lộc A",
        "path": "Vĩnh Lộc A, Hồng Dân, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Lộc A, Huyện Hồng Dân, Tỉnh Bạc Liêu",
        "code": "31861",
        "parent_code": "956"
    },
    "31863": {
        "name": "Ninh Thạnh Lợi A",
        "type": "xa",
        "slug": "ninh-thanh-loi-a",
        "name_with_type": "Xã Ninh Thạnh Lợi A",
        "path": "Ninh Thạnh Lợi A, Hồng Dân, Bạc Liêu",
        "path_with_type": "Xã Ninh Thạnh Lợi A, Huyện Hồng Dân, Tỉnh Bạc Liêu",
        "code": "31863",
        "parent_code": "956"
    },
    "31864": {
        "name": "Ninh Thạnh Lợi",
        "type": "xa",
        "slug": "ninh-thanh-loi",
        "name_with_type": "Xã Ninh Thạnh Lợi",
        "path": "Ninh Thạnh Lợi, Hồng Dân, Bạc Liêu",
        "path_with_type": "Xã Ninh Thạnh Lợi, Huyện Hồng Dân, Tỉnh Bạc Liêu",
        "code": "31864",
        "parent_code": "956"
    },
    "31867": {
        "name": "Phước Long",
        "type": "thi-tran",
        "slug": "phuoc-long",
        "name_with_type": "Thị trấn Phước Long",
        "path": "Phước Long, Phước Long, Bạc Liêu",
        "path_with_type": "Thị trấn Phước Long, Huyện Phước Long, Tỉnh Bạc Liêu",
        "code": "31867",
        "parent_code": "957"
    },
    "31870": {
        "name": "Vĩnh Phú Đông",
        "type": "xa",
        "slug": "vinh-phu-dong",
        "name_with_type": "Xã Vĩnh Phú Đông",
        "path": "Vĩnh Phú Đông, Phước Long, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Phú Đông, Huyện Phước Long, Tỉnh Bạc Liêu",
        "code": "31870",
        "parent_code": "957"
    },
    "31873": {
        "name": "Vĩnh Phú Tây",
        "type": "xa",
        "slug": "vinh-phu-tay",
        "name_with_type": "Xã Vĩnh Phú Tây",
        "path": "Vĩnh Phú Tây, Phước Long, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Phú Tây, Huyện Phước Long, Tỉnh Bạc Liêu",
        "code": "31873",
        "parent_code": "957"
    },
    "31876": {
        "name": "Phước Long",
        "type": "xa",
        "slug": "phuoc-long",
        "name_with_type": "Xã Phước Long",
        "path": "Phước Long, Phước Long, Bạc Liêu",
        "path_with_type": "Xã Phước Long, Huyện Phước Long, Tỉnh Bạc Liêu",
        "code": "31876",
        "parent_code": "957"
    },
    "31879": {
        "name": "Hưng Phú",
        "type": "xa",
        "slug": "hung-phu",
        "name_with_type": "Xã Hưng Phú",
        "path": "Hưng Phú, Phước Long, Bạc Liêu",
        "path_with_type": "Xã Hưng Phú, Huyện Phước Long, Tỉnh Bạc Liêu",
        "code": "31879",
        "parent_code": "957"
    },
    "31882": {
        "name": "Vĩnh Thanh",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thanh",
        "path": "Vĩnh Thanh, Phước Long, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Thanh, Huyện Phước Long, Tỉnh Bạc Liêu",
        "code": "31882",
        "parent_code": "957"
    },
    "31885": {
        "name": "Phong Thạnh Tây A",
        "type": "xa",
        "slug": "phong-thanh-tay-a",
        "name_with_type": "Xã Phong Thạnh Tây A",
        "path": "Phong Thạnh Tây A, Phước Long, Bạc Liêu",
        "path_with_type": "Xã Phong Thạnh Tây A, Huyện Phước Long, Tỉnh Bạc Liêu",
        "code": "31885",
        "parent_code": "957"
    },
    "31888": {
        "name": "Phong Thạnh Tây B",
        "type": "xa",
        "slug": "phong-thanh-tay-b",
        "name_with_type": "Xã Phong Thạnh Tây B",
        "path": "Phong Thạnh Tây B, Phước Long, Bạc Liêu",
        "path_with_type": "Xã Phong Thạnh Tây B, Huyện Phước Long, Tỉnh Bạc Liêu",
        "code": "31888",
        "parent_code": "957"
    },
    "31894": {
        "name": "Vĩnh Hưng",
        "type": "xa",
        "slug": "vinh-hung",
        "name_with_type": "Xã Vĩnh Hưng",
        "path": "Vĩnh Hưng, Vĩnh Lợi, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Hưng, Huyện Vĩnh Lợi, Tỉnh Bạc Liêu",
        "code": "31894",
        "parent_code": "958"
    },
    "31897": {
        "name": "Vĩnh Hưng A",
        "type": "xa",
        "slug": "vinh-hung-a",
        "name_with_type": "Xã Vĩnh Hưng A",
        "path": "Vĩnh Hưng A, Vĩnh Lợi, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Hưng A, Huyện Vĩnh Lợi, Tỉnh Bạc Liêu",
        "code": "31897",
        "parent_code": "958"
    },
    "31900": {
        "name": "Châu Hưng",
        "type": "thi-tran",
        "slug": "chau-hung",
        "name_with_type": "Thị trấn Châu Hưng",
        "path": "Châu Hưng, Vĩnh Lợi, Bạc Liêu",
        "path_with_type": "Thị trấn Châu Hưng, Huyện Vĩnh Lợi, Tỉnh Bạc Liêu",
        "code": "31900",
        "parent_code": "958"
    },
    "31903": {
        "name": "Châu Hưng A",
        "type": "xa",
        "slug": "chau-hung-a",
        "name_with_type": "Xã Châu Hưng A",
        "path": "Châu Hưng A, Vĩnh Lợi, Bạc Liêu",
        "path_with_type": "Xã Châu Hưng A, Huyện Vĩnh Lợi, Tỉnh Bạc Liêu",
        "code": "31903",
        "parent_code": "958"
    },
    "31906": {
        "name": "Hưng Thành",
        "type": "xa",
        "slug": "hung-thanh",
        "name_with_type": "Xã Hưng Thành",
        "path": "Hưng Thành, Vĩnh Lợi, Bạc Liêu",
        "path_with_type": "Xã Hưng Thành, Huyện Vĩnh Lợi, Tỉnh Bạc Liêu",
        "code": "31906",
        "parent_code": "958"
    },
    "31909": {
        "name": "Hưng Hội",
        "type": "xa",
        "slug": "hung-hoi",
        "name_with_type": "Xã Hưng Hội",
        "path": "Hưng Hội, Vĩnh Lợi, Bạc Liêu",
        "path_with_type": "Xã Hưng Hội, Huyện Vĩnh Lợi, Tỉnh Bạc Liêu",
        "code": "31909",
        "parent_code": "958"
    },
    "31912": {
        "name": "Châu Thới",
        "type": "xa",
        "slug": "chau-thoi",
        "name_with_type": "Xã Châu Thới",
        "path": "Châu Thới, Vĩnh Lợi, Bạc Liêu",
        "path_with_type": "Xã Châu Thới, Huyện Vĩnh Lợi, Tỉnh Bạc Liêu",
        "code": "31912",
        "parent_code": "958"
    },
    "31921": {
        "name": "Long Thạnh",
        "type": "xa",
        "slug": "long-thanh",
        "name_with_type": "Xã Long Thạnh",
        "path": "Long Thạnh, Vĩnh Lợi, Bạc Liêu",
        "path_with_type": "Xã Long Thạnh, Huyện Vĩnh Lợi, Tỉnh Bạc Liêu",
        "code": "31921",
        "parent_code": "958"
    },
    "31942": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Giá Rai, Bạc Liêu",
        "path_with_type": "Phường 1, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31942",
        "parent_code": "959"
    },
    "31945": {
        "name": "Hộ Phòng",
        "type": "phuong",
        "slug": "ho-phong",
        "name_with_type": "Phường Hộ Phòng",
        "path": "Hộ Phòng, Giá Rai, Bạc Liêu",
        "path_with_type": "Phường Hộ Phòng, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31945",
        "parent_code": "959"
    },
    "31948": {
        "name": "Phong Thạnh Đông",
        "type": "xa",
        "slug": "phong-thanh-dong",
        "name_with_type": "Xã Phong Thạnh Đông",
        "path": "Phong Thạnh Đông, Giá Rai, Bạc Liêu",
        "path_with_type": "Xã Phong Thạnh Đông, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31948",
        "parent_code": "959"
    },
    "31951": {
        "name": "Láng Tròn",
        "type": "phuong",
        "slug": "lang-tron",
        "name_with_type": "Phường Láng Tròn",
        "path": "Láng Tròn, Giá Rai, Bạc Liêu",
        "path_with_type": "Phường Láng Tròn, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31951",
        "parent_code": "959"
    },
    "31954": {
        "name": "Phong Tân",
        "type": "xa",
        "slug": "phong-tan",
        "name_with_type": "Xã Phong Tân",
        "path": "Phong Tân, Giá Rai, Bạc Liêu",
        "path_with_type": "Xã Phong Tân, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31954",
        "parent_code": "959"
    },
    "31957": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Giá Rai, Bạc Liêu",
        "path_with_type": "Xã Tân Phong, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31957",
        "parent_code": "959"
    },
    "31960": {
        "name": "Phong Thạnh",
        "type": "xa",
        "slug": "phong-thanh",
        "name_with_type": "Xã Phong Thạnh",
        "path": "Phong Thạnh, Giá Rai, Bạc Liêu",
        "path_with_type": "Xã Phong Thạnh, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31960",
        "parent_code": "959"
    },
    "31963": {
        "name": "Phong Thạnh A",
        "type": "xa",
        "slug": "phong-thanh-a",
        "name_with_type": "Xã Phong Thạnh A",
        "path": "Phong Thạnh A, Giá Rai, Bạc Liêu",
        "path_with_type": "Xã Phong Thạnh A, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31963",
        "parent_code": "959"
    },
    "31966": {
        "name": "Phong Thạnh Tây",
        "type": "xa",
        "slug": "phong-thanh-tay",
        "name_with_type": "Xã Phong Thạnh Tây",
        "path": "Phong Thạnh Tây, Giá Rai, Bạc Liêu",
        "path_with_type": "Xã Phong Thạnh Tây, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31966",
        "parent_code": "959"
    },
    "31969": {
        "name": "Tân Thạnh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thạnh",
        "path": "Tân Thạnh, Giá Rai, Bạc Liêu",
        "path_with_type": "Xã Tân Thạnh, Thị xã Giá Rai, Tỉnh Bạc Liêu",
        "code": "31969",
        "parent_code": "959"
    },
    "31972": {
        "name": "Gành Hào",
        "type": "thi-tran",
        "slug": "ganh-hao",
        "name_with_type": "Thị trấn Gành Hào",
        "path": "Gành Hào, Đông Hải, Bạc Liêu",
        "path_with_type": "Thị trấn Gành Hào, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31972",
        "parent_code": "960"
    },
    "31975": {
        "name": "Long Điền Đông",
        "type": "xa",
        "slug": "long-dien-dong",
        "name_with_type": "Xã Long Điền Đông",
        "path": "Long Điền Đông, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã Long Điền Đông, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31975",
        "parent_code": "960"
    },
    "31978": {
        "name": "Long Điền Đông A",
        "type": "xa",
        "slug": "long-dien-dong-a",
        "name_with_type": "Xã Long Điền Đông A",
        "path": "Long Điền Đông A, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã Long Điền Đông A, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31978",
        "parent_code": "960"
    },
    "31981": {
        "name": "Long Điền",
        "type": "xa",
        "slug": "long-dien",
        "name_with_type": "Xã Long Điền",
        "path": "Long Điền, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã Long Điền, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31981",
        "parent_code": "960"
    },
    "31984": {
        "name": "Long Điền Tây",
        "type": "xa",
        "slug": "long-dien-tay",
        "name_with_type": "Xã Long Điền Tây",
        "path": "Long Điền Tây, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã Long Điền Tây, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31984",
        "parent_code": "960"
    },
    "31985": {
        "name": "Điền Hải",
        "type": "xa",
        "slug": "dien-hai",
        "name_with_type": "Xã Điền Hải",
        "path": "Điền Hải, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã Điền Hải, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31985",
        "parent_code": "960"
    },
    "31987": {
        "name": "An Trạch",
        "type": "xa",
        "slug": "an-trach",
        "name_with_type": "Xã An Trạch",
        "path": "An Trạch, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã An Trạch, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31987",
        "parent_code": "960"
    },
    "31988": {
        "name": "An Trạch A",
        "type": "xa",
        "slug": "an-trach-a",
        "name_with_type": "Xã An Trạch A",
        "path": "An Trạch A, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã An Trạch A, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31988",
        "parent_code": "960"
    },
    "31990": {
        "name": "An Phúc",
        "type": "xa",
        "slug": "an-phuc",
        "name_with_type": "Xã An Phúc",
        "path": "An Phúc, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã An Phúc, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31990",
        "parent_code": "960"
    },
    "31993": {
        "name": "Định Thành",
        "type": "xa",
        "slug": "dinh-thanh",
        "name_with_type": "Xã Định Thành",
        "path": "Định Thành, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã Định Thành, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31993",
        "parent_code": "960"
    },
    "31996": {
        "name": "Định Thành A",
        "type": "xa",
        "slug": "dinh-thanh-a",
        "name_with_type": "Xã Định Thành A",
        "path": "Định Thành A, Đông Hải, Bạc Liêu",
        "path_with_type": "Xã Định Thành A, Huyện Đông Hải, Tỉnh Bạc Liêu",
        "code": "31996",
        "parent_code": "960"
    },
    "31891": {
        "name": "Hòa Bình",
        "type": "thi-tran",
        "slug": "hoa-binh",
        "name_with_type": "Thị trấn Hòa Bình",
        "path": "Hòa Bình, Hoà Bình, Bạc Liêu",
        "path_with_type": "Thị trấn Hòa Bình, Huyện Hoà Bình, Tỉnh Bạc Liêu",
        "code": "31891",
        "parent_code": "961"
    },
    "31915": {
        "name": "Minh Diệu",
        "type": "xa",
        "slug": "minh-dieu",
        "name_with_type": "Xã Minh Diệu",
        "path": "Minh Diệu, Hoà Bình, Bạc Liêu",
        "path_with_type": "Xã Minh Diệu, Huyện Hoà Bình, Tỉnh Bạc Liêu",
        "code": "31915",
        "parent_code": "961"
    },
    "31918": {
        "name": "Vĩnh Bình",
        "type": "xa",
        "slug": "vinh-binh",
        "name_with_type": "Xã Vĩnh Bình",
        "path": "Vĩnh Bình, Hoà Bình, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Bình, Huyện Hoà Bình, Tỉnh Bạc Liêu",
        "code": "31918",
        "parent_code": "961"
    },
    "31924": {
        "name": "Vĩnh Mỹ B",
        "type": "xa",
        "slug": "vinh-my-b",
        "name_with_type": "Xã Vĩnh Mỹ B",
        "path": "Vĩnh Mỹ B, Hoà Bình, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Mỹ B, Huyện Hoà Bình, Tỉnh Bạc Liêu",
        "code": "31924",
        "parent_code": "961"
    },
    "31927": {
        "name": "Vĩnh Hậu",
        "type": "xa",
        "slug": "vinh-hau",
        "name_with_type": "Xã Vĩnh Hậu",
        "path": "Vĩnh Hậu, Hoà Bình, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Hậu, Huyện Hoà Bình, Tỉnh Bạc Liêu",
        "code": "31927",
        "parent_code": "961"
    },
    "31930": {
        "name": "Vĩnh Hậu A",
        "type": "xa",
        "slug": "vinh-hau-a",
        "name_with_type": "Xã Vĩnh Hậu A",
        "path": "Vĩnh Hậu A, Hoà Bình, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Hậu A, Huyện Hoà Bình, Tỉnh Bạc Liêu",
        "code": "31930",
        "parent_code": "961"
    },
    "31933": {
        "name": "Vĩnh Mỹ A",
        "type": "xa",
        "slug": "vinh-my-a",
        "name_with_type": "Xã Vĩnh Mỹ A",
        "path": "Vĩnh Mỹ A, Hoà Bình, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Mỹ A, Huyện Hoà Bình, Tỉnh Bạc Liêu",
        "code": "31933",
        "parent_code": "961"
    },
    "31936": {
        "name": "Vĩnh Thịnh",
        "type": "xa",
        "slug": "vinh-thinh",
        "name_with_type": "Xã Vĩnh Thịnh",
        "path": "Vĩnh Thịnh, Hoà Bình, Bạc Liêu",
        "path_with_type": "Xã Vĩnh Thịnh, Huyện Hoà Bình, Tỉnh Bạc Liêu",
        "code": "31936",
        "parent_code": "961"
    },
    "29236": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Trà Vinh, Trà Vinh",
        "path_with_type": "Phường 4, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29236",
        "parent_code": "842"
    },
    "29239": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Trà Vinh, Trà Vinh",
        "path_with_type": "Phường 1, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29239",
        "parent_code": "842"
    },
    "29242": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Trà Vinh, Trà Vinh",
        "path_with_type": "Phường 3, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29242",
        "parent_code": "842"
    },
    "29245": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Trà Vinh, Trà Vinh",
        "path_with_type": "Phường 2, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29245",
        "parent_code": "842"
    },
    "29248": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Trà Vinh, Trà Vinh",
        "path_with_type": "Phường 5, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29248",
        "parent_code": "842"
    },
    "29251": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Trà Vinh, Trà Vinh",
        "path_with_type": "Phường 6, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29251",
        "parent_code": "842"
    },
    "29254": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Trà Vinh, Trà Vinh",
        "path_with_type": "Phường 7, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29254",
        "parent_code": "842"
    },
    "29257": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Trà Vinh, Trà Vinh",
        "path_with_type": "Phường 8, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29257",
        "parent_code": "842"
    },
    "29260": {
        "name": "9",
        "type": "phuong",
        "slug": "9",
        "name_with_type": "Phường 9",
        "path": "9, Trà Vinh, Trà Vinh",
        "path_with_type": "Phường 9, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29260",
        "parent_code": "842"
    },
    "29263": {
        "name": "Long Đức",
        "type": "xa",
        "slug": "long-duc",
        "name_with_type": "Xã Long Đức",
        "path": "Long Đức, Trà Vinh, Trà Vinh",
        "path_with_type": "Xã Long Đức, Thành phố Trà Vinh, Tỉnh Trà Vinh",
        "code": "29263",
        "parent_code": "842"
    },
    "29266": {
        "name": "Càng Long",
        "type": "thi-tran",
        "slug": "cang-long",
        "name_with_type": "Thị trấn Càng Long",
        "path": "Càng Long, Càng Long, Trà Vinh",
        "path_with_type": "Thị trấn Càng Long, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29266",
        "parent_code": "844"
    },
    "29269": {
        "name": "Mỹ Cẩm",
        "type": "xa",
        "slug": "my-cam",
        "name_with_type": "Xã Mỹ Cẩm",
        "path": "Mỹ Cẩm, Càng Long, Trà Vinh",
        "path_with_type": "Xã Mỹ Cẩm, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29269",
        "parent_code": "844"
    },
    "29272": {
        "name": "An Trường A",
        "type": "xa",
        "slug": "an-truong-a",
        "name_with_type": "Xã An Trường A",
        "path": "An Trường A, Càng Long, Trà Vinh",
        "path_with_type": "Xã An Trường A, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29272",
        "parent_code": "844"
    },
    "29275": {
        "name": "An Trường",
        "type": "xa",
        "slug": "an-truong",
        "name_with_type": "Xã An Trường",
        "path": "An Trường, Càng Long, Trà Vinh",
        "path_with_type": "Xã An Trường, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29275",
        "parent_code": "844"
    },
    "29278": {
        "name": "Huyền Hội",
        "type": "xa",
        "slug": "huyen-hoi",
        "name_with_type": "Xã Huyền Hội",
        "path": "Huyền Hội, Càng Long, Trà Vinh",
        "path_with_type": "Xã Huyền Hội, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29278",
        "parent_code": "844"
    },
    "29281": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Càng Long, Trà Vinh",
        "path_with_type": "Xã Tân An, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29281",
        "parent_code": "844"
    },
    "29284": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Càng Long, Trà Vinh",
        "path_with_type": "Xã Tân Bình, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29284",
        "parent_code": "844"
    },
    "29287": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Càng Long, Trà Vinh",
        "path_with_type": "Xã Bình Phú, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29287",
        "parent_code": "844"
    },
    "29290": {
        "name": "Phương Thạnh",
        "type": "xa",
        "slug": "phuong-thanh",
        "name_with_type": "Xã Phương Thạnh",
        "path": "Phương Thạnh, Càng Long, Trà Vinh",
        "path_with_type": "Xã Phương Thạnh, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29290",
        "parent_code": "844"
    },
    "29293": {
        "name": "Đại Phúc",
        "type": "xa",
        "slug": "dai-phuc",
        "name_with_type": "Xã Đại Phúc",
        "path": "Đại Phúc, Càng Long, Trà Vinh",
        "path_with_type": "Xã Đại Phúc, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29293",
        "parent_code": "844"
    },
    "29296": {
        "name": "Đại Phước",
        "type": "xa",
        "slug": "dai-phuoc",
        "name_with_type": "Xã Đại Phước",
        "path": "Đại Phước, Càng Long, Trà Vinh",
        "path_with_type": "Xã Đại Phước, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29296",
        "parent_code": "844"
    },
    "29299": {
        "name": "Nhị Long Phú",
        "type": "xa",
        "slug": "nhi-long-phu",
        "name_with_type": "Xã Nhị Long Phú",
        "path": "Nhị Long Phú, Càng Long, Trà Vinh",
        "path_with_type": "Xã Nhị Long Phú, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29299",
        "parent_code": "844"
    },
    "29302": {
        "name": "Nhị Long",
        "type": "xa",
        "slug": "nhi-long",
        "name_with_type": "Xã Nhị Long",
        "path": "Nhị Long, Càng Long, Trà Vinh",
        "path_with_type": "Xã Nhị Long, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29302",
        "parent_code": "844"
    },
    "29305": {
        "name": "Đức Mỹ",
        "type": "xa",
        "slug": "duc-my",
        "name_with_type": "Xã Đức Mỹ",
        "path": "Đức Mỹ, Càng Long, Trà Vinh",
        "path_with_type": "Xã Đức Mỹ, Huyện Càng Long, Tỉnh Trà Vinh",
        "code": "29305",
        "parent_code": "844"
    },
    "29308": {
        "name": "Cầu Kè",
        "type": "thi-tran",
        "slug": "cau-ke",
        "name_with_type": "Thị trấn Cầu Kè",
        "path": "Cầu Kè, Cầu Kè, Trà Vinh",
        "path_with_type": "Thị trấn Cầu Kè, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29308",
        "parent_code": "845"
    },
    "29311": {
        "name": "Hòa Ân",
        "type": "xa",
        "slug": "hoa-an",
        "name_with_type": "Xã Hòa Ân",
        "path": "Hòa Ân, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã Hòa Ân, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29311",
        "parent_code": "845"
    },
    "29314": {
        "name": "Châu Điền",
        "type": "xa",
        "slug": "chau-dien",
        "name_with_type": "Xã Châu Điền",
        "path": "Châu Điền, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã Châu Điền, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29314",
        "parent_code": "845"
    },
    "29317": {
        "name": "An Phú Tân",
        "type": "xa",
        "slug": "an-phu-tan",
        "name_with_type": "Xã An Phú Tân",
        "path": "An Phú Tân, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã An Phú Tân, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29317",
        "parent_code": "845"
    },
    "29320": {
        "name": "Hoà Tân",
        "type": "xa",
        "slug": "hoa-tan",
        "name_with_type": "Xã Hoà Tân",
        "path": "Hoà Tân, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã Hoà Tân, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29320",
        "parent_code": "845"
    },
    "29323": {
        "name": "Ninh Thới",
        "type": "xa",
        "slug": "ninh-thoi",
        "name_with_type": "Xã Ninh Thới",
        "path": "Ninh Thới, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã Ninh Thới, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29323",
        "parent_code": "845"
    },
    "29326": {
        "name": "Phong Phú",
        "type": "xa",
        "slug": "phong-phu",
        "name_with_type": "Xã Phong Phú",
        "path": "Phong Phú, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã Phong Phú, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29326",
        "parent_code": "845"
    },
    "29329": {
        "name": "Phong Thạnh",
        "type": "xa",
        "slug": "phong-thanh",
        "name_with_type": "Xã Phong Thạnh",
        "path": "Phong Thạnh, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã Phong Thạnh, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29329",
        "parent_code": "845"
    },
    "29332": {
        "name": "Tam Ngãi",
        "type": "xa",
        "slug": "tam-ngai",
        "name_with_type": "Xã Tam Ngãi",
        "path": "Tam Ngãi, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã Tam Ngãi, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29332",
        "parent_code": "845"
    },
    "29335": {
        "name": "Thông Hòa",
        "type": "xa",
        "slug": "thong-hoa",
        "name_with_type": "Xã Thông Hòa",
        "path": "Thông Hòa, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã Thông Hòa, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29335",
        "parent_code": "845"
    },
    "29338": {
        "name": "Thạnh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thạnh Phú",
        "path": "Thạnh Phú, Cầu Kè, Trà Vinh",
        "path_with_type": "Xã Thạnh Phú, Huyện Cầu Kè, Tỉnh Trà Vinh",
        "code": "29338",
        "parent_code": "845"
    },
    "29341": {
        "name": "Tiểu Cần",
        "type": "thi-tran",
        "slug": "tieu-can",
        "name_with_type": "Thị trấn Tiểu Cần",
        "path": "Tiểu Cần, Tiểu Cần, Trà Vinh",
        "path_with_type": "Thị trấn Tiểu Cần, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29341",
        "parent_code": "846"
    },
    "29344": {
        "name": "Cầu Quan",
        "type": "thi-tran",
        "slug": "cau-quan",
        "name_with_type": "Thị trấn Cầu Quan",
        "path": "Cầu Quan, Tiểu Cần, Trà Vinh",
        "path_with_type": "Thị trấn Cầu Quan, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29344",
        "parent_code": "846"
    },
    "29347": {
        "name": "Phú Cần",
        "type": "xa",
        "slug": "phu-can",
        "name_with_type": "Xã Phú Cần",
        "path": "Phú Cần, Tiểu Cần, Trà Vinh",
        "path_with_type": "Xã Phú Cần, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29347",
        "parent_code": "846"
    },
    "29350": {
        "name": "Hiếu Tử",
        "type": "xa",
        "slug": "hieu-tu",
        "name_with_type": "Xã Hiếu Tử",
        "path": "Hiếu Tử, Tiểu Cần, Trà Vinh",
        "path_with_type": "Xã Hiếu Tử, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29350",
        "parent_code": "846"
    },
    "29353": {
        "name": "Hiếu Trung",
        "type": "xa",
        "slug": "hieu-trung",
        "name_with_type": "Xã Hiếu Trung",
        "path": "Hiếu Trung, Tiểu Cần, Trà Vinh",
        "path_with_type": "Xã Hiếu Trung, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29353",
        "parent_code": "846"
    },
    "29356": {
        "name": "Long Thới",
        "type": "xa",
        "slug": "long-thoi",
        "name_with_type": "Xã Long Thới",
        "path": "Long Thới, Tiểu Cần, Trà Vinh",
        "path_with_type": "Xã Long Thới, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29356",
        "parent_code": "846"
    },
    "29359": {
        "name": "Hùng Hòa",
        "type": "xa",
        "slug": "hung-hoa",
        "name_with_type": "Xã Hùng Hòa",
        "path": "Hùng Hòa, Tiểu Cần, Trà Vinh",
        "path_with_type": "Xã Hùng Hòa, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29359",
        "parent_code": "846"
    },
    "29362": {
        "name": "Tân Hùng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hùng",
        "path": "Tân Hùng, Tiểu Cần, Trà Vinh",
        "path_with_type": "Xã Tân Hùng, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29362",
        "parent_code": "846"
    },
    "29365": {
        "name": "Tập Ngãi",
        "type": "xa",
        "slug": "tap-ngai",
        "name_with_type": "Xã Tập Ngãi",
        "path": "Tập Ngãi, Tiểu Cần, Trà Vinh",
        "path_with_type": "Xã Tập Ngãi, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29365",
        "parent_code": "846"
    },
    "29368": {
        "name": "Ngãi Hùng",
        "type": "xa",
        "slug": "ngai-hung",
        "name_with_type": "Xã Ngãi Hùng",
        "path": "Ngãi Hùng, Tiểu Cần, Trà Vinh",
        "path_with_type": "Xã Ngãi Hùng, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29368",
        "parent_code": "846"
    },
    "29371": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Tiểu Cần, Trà Vinh",
        "path_with_type": "Xã Tân Hòa, Huyện Tiểu Cần, Tỉnh Trà Vinh",
        "code": "29371",
        "parent_code": "846"
    },
    "29374": {
        "name": "Châu Thành",
        "type": "thi-tran",
        "slug": "chau-thanh",
        "name_with_type": "Thị trấn Châu Thành",
        "path": "Châu Thành, Châu Thành, Trà Vinh",
        "path_with_type": "Thị trấn Châu Thành, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29374",
        "parent_code": "847"
    },
    "29377": {
        "name": "Đa Lộc",
        "type": "xa",
        "slug": "da-loc",
        "name_with_type": "Xã Đa Lộc",
        "path": "Đa Lộc, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Đa Lộc, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29377",
        "parent_code": "847"
    },
    "29380": {
        "name": "Mỹ Chánh",
        "type": "xa",
        "slug": "my-chanh",
        "name_with_type": "Xã Mỹ Chánh",
        "path": "Mỹ Chánh, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Mỹ Chánh, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29380",
        "parent_code": "847"
    },
    "29383": {
        "name": "Thanh Mỹ",
        "type": "xa",
        "slug": "thanh-my",
        "name_with_type": "Xã Thanh Mỹ",
        "path": "Thanh Mỹ, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Thanh Mỹ, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29383",
        "parent_code": "847"
    },
    "29386": {
        "name": "Lương Hoà A",
        "type": "xa",
        "slug": "luong-hoa-a",
        "name_with_type": "Xã Lương Hoà A",
        "path": "Lương Hoà A, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Lương Hoà A, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29386",
        "parent_code": "847"
    },
    "29389": {
        "name": "Lương Hòa",
        "type": "xa",
        "slug": "luong-hoa",
        "name_with_type": "Xã Lương Hòa",
        "path": "Lương Hòa, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Lương Hòa, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29389",
        "parent_code": "847"
    },
    "29392": {
        "name": "Song Lộc",
        "type": "xa",
        "slug": "song-loc",
        "name_with_type": "Xã Song Lộc",
        "path": "Song Lộc, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Song Lộc, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29392",
        "parent_code": "847"
    },
    "29395": {
        "name": "Nguyệt Hóa",
        "type": "xa",
        "slug": "nguyet-hoa",
        "name_with_type": "Xã Nguyệt Hóa",
        "path": "Nguyệt Hóa, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Nguyệt Hóa, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29395",
        "parent_code": "847"
    },
    "29398": {
        "name": "Hòa Thuận",
        "type": "xa",
        "slug": "hoa-thuan",
        "name_with_type": "Xã Hòa Thuận",
        "path": "Hòa Thuận, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Hòa Thuận, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29398",
        "parent_code": "847"
    },
    "29401": {
        "name": "Hòa Lợi",
        "type": "xa",
        "slug": "hoa-loi",
        "name_with_type": "Xã Hòa Lợi",
        "path": "Hòa Lợi, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Hòa Lợi, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29401",
        "parent_code": "847"
    },
    "29404": {
        "name": "Phước Hảo",
        "type": "xa",
        "slug": "phuoc-hao",
        "name_with_type": "Xã Phước Hảo",
        "path": "Phước Hảo, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Phước Hảo, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29404",
        "parent_code": "847"
    },
    "29407": {
        "name": "Hưng Mỹ",
        "type": "xa",
        "slug": "hung-my",
        "name_with_type": "Xã Hưng Mỹ",
        "path": "Hưng Mỹ, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Hưng Mỹ, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29407",
        "parent_code": "847"
    },
    "29410": {
        "name": "Hòa Minh",
        "type": "xa",
        "slug": "hoa-minh",
        "name_with_type": "Xã Hòa Minh",
        "path": "Hòa Minh, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Hòa Minh, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29410",
        "parent_code": "847"
    },
    "29413": {
        "name": "Long Hòa",
        "type": "xa",
        "slug": "long-hoa",
        "name_with_type": "Xã Long Hòa",
        "path": "Long Hòa, Châu Thành, Trà Vinh",
        "path_with_type": "Xã Long Hòa, Huyện Châu Thành, Tỉnh Trà Vinh",
        "code": "29413",
        "parent_code": "847"
    },
    "29416": {
        "name": "Cầu Ngang",
        "type": "thi-tran",
        "slug": "cau-ngang",
        "name_with_type": "Thị trấn Cầu Ngang",
        "path": "Cầu Ngang, Cầu Ngang, Trà Vinh",
        "path_with_type": "Thị trấn Cầu Ngang, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29416",
        "parent_code": "848"
    },
    "29419": {
        "name": "Mỹ Long",
        "type": "thi-tran",
        "slug": "my-long",
        "name_with_type": "Thị trấn Mỹ Long",
        "path": "Mỹ Long, Cầu Ngang, Trà Vinh",
        "path_with_type": "Thị trấn Mỹ Long, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29419",
        "parent_code": "848"
    },
    "29422": {
        "name": "Mỹ Long Bắc",
        "type": "xa",
        "slug": "my-long-bac",
        "name_with_type": "Xã Mỹ Long Bắc",
        "path": "Mỹ Long Bắc, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Mỹ Long Bắc, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29422",
        "parent_code": "848"
    },
    "29425": {
        "name": "Mỹ Long Nam",
        "type": "xa",
        "slug": "my-long-nam",
        "name_with_type": "Xã Mỹ Long Nam",
        "path": "Mỹ Long Nam, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Mỹ Long Nam, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29425",
        "parent_code": "848"
    },
    "29428": {
        "name": "Mỹ Hòa",
        "type": "xa",
        "slug": "my-hoa",
        "name_with_type": "Xã Mỹ Hòa",
        "path": "Mỹ Hòa, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Mỹ Hòa, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29428",
        "parent_code": "848"
    },
    "29431": {
        "name": "Vĩnh Kim",
        "type": "xa",
        "slug": "vinh-kim",
        "name_with_type": "Xã Vĩnh Kim",
        "path": "Vĩnh Kim, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Vĩnh Kim, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29431",
        "parent_code": "848"
    },
    "29434": {
        "name": "Kim Hòa",
        "type": "xa",
        "slug": "kim-hoa",
        "name_with_type": "Xã Kim Hòa",
        "path": "Kim Hòa, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Kim Hòa, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29434",
        "parent_code": "848"
    },
    "29437": {
        "name": "Hiệp Hòa",
        "type": "xa",
        "slug": "hiep-hoa",
        "name_with_type": "Xã Hiệp Hòa",
        "path": "Hiệp Hòa, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Hiệp Hòa, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29437",
        "parent_code": "848"
    },
    "29440": {
        "name": "Thuận Hòa",
        "type": "xa",
        "slug": "thuan-hoa",
        "name_with_type": "Xã Thuận Hòa",
        "path": "Thuận Hòa, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Thuận Hòa, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29440",
        "parent_code": "848"
    },
    "29443": {
        "name": "Long Sơn",
        "type": "xa",
        "slug": "long-son",
        "name_with_type": "Xã Long Sơn",
        "path": "Long Sơn, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Long Sơn, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29443",
        "parent_code": "848"
    },
    "29446": {
        "name": "Nhị Trường",
        "type": "xa",
        "slug": "nhi-truong",
        "name_with_type": "Xã Nhị Trường",
        "path": "Nhị Trường, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Nhị Trường, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29446",
        "parent_code": "848"
    },
    "29449": {
        "name": "Trường Thọ",
        "type": "xa",
        "slug": "truong-tho",
        "name_with_type": "Xã Trường Thọ",
        "path": "Trường Thọ, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Trường Thọ, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29449",
        "parent_code": "848"
    },
    "29452": {
        "name": "Hiệp Mỹ Đông",
        "type": "xa",
        "slug": "hiep-my-dong",
        "name_with_type": "Xã Hiệp Mỹ Đông",
        "path": "Hiệp Mỹ Đông, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Hiệp Mỹ Đông, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29452",
        "parent_code": "848"
    },
    "29455": {
        "name": "Hiệp Mỹ Tây",
        "type": "xa",
        "slug": "hiep-my-tay",
        "name_with_type": "Xã Hiệp Mỹ Tây",
        "path": "Hiệp Mỹ Tây, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Hiệp Mỹ Tây, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29455",
        "parent_code": "848"
    },
    "29458": {
        "name": "Thạnh Hòa Sơn",
        "type": "xa",
        "slug": "thanh-hoa-son",
        "name_with_type": "Xã Thạnh Hòa Sơn",
        "path": "Thạnh Hòa Sơn, Cầu Ngang, Trà Vinh",
        "path_with_type": "Xã Thạnh Hòa Sơn, Huyện Cầu Ngang, Tỉnh Trà Vinh",
        "code": "29458",
        "parent_code": "848"
    },
    "29461": {
        "name": "Trà Cú",
        "type": "thi-tran",
        "slug": "tra-cu",
        "name_with_type": "Thị trấn Trà Cú",
        "path": "Trà Cú, Trà Cú, Trà Vinh",
        "path_with_type": "Thị trấn Trà Cú, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29461",
        "parent_code": "849"
    },
    "29462": {
        "name": "Định An",
        "type": "thi-tran",
        "slug": "dinh-an",
        "name_with_type": "Thị trấn Định An",
        "path": "Định An, Trà Cú, Trà Vinh",
        "path_with_type": "Thị trấn Định An, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29462",
        "parent_code": "849"
    },
    "29464": {
        "name": "Phước Hưng",
        "type": "xa",
        "slug": "phuoc-hung",
        "name_with_type": "Xã Phước Hưng",
        "path": "Phước Hưng, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Phước Hưng, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29464",
        "parent_code": "849"
    },
    "29467": {
        "name": "Tập Sơn",
        "type": "xa",
        "slug": "tap-son",
        "name_with_type": "Xã Tập Sơn",
        "path": "Tập Sơn, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Tập Sơn, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29467",
        "parent_code": "849"
    },
    "29470": {
        "name": "Tân Sơn",
        "type": "xa",
        "slug": "tan-son",
        "name_with_type": "Xã Tân Sơn",
        "path": "Tân Sơn, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Tân Sơn, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29470",
        "parent_code": "849"
    },
    "29473": {
        "name": "An Quảng Hữu",
        "type": "xa",
        "slug": "an-quang-huu",
        "name_with_type": "Xã An Quảng Hữu",
        "path": "An Quảng Hữu, Trà Cú, Trà Vinh",
        "path_with_type": "Xã An Quảng Hữu, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29473",
        "parent_code": "849"
    },
    "29476": {
        "name": "Lưu Nghiệp Anh",
        "type": "xa",
        "slug": "luu-nghiep-anh",
        "name_with_type": "Xã Lưu Nghiệp Anh",
        "path": "Lưu Nghiệp Anh, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Lưu Nghiệp Anh, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29476",
        "parent_code": "849"
    },
    "29479": {
        "name": "Ngãi Xuyên",
        "type": "xa",
        "slug": "ngai-xuyen",
        "name_with_type": "Xã Ngãi Xuyên",
        "path": "Ngãi Xuyên, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Ngãi Xuyên, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29479",
        "parent_code": "849"
    },
    "29482": {
        "name": "Kim Sơn",
        "type": "xa",
        "slug": "kim-son",
        "name_with_type": "Xã Kim Sơn",
        "path": "Kim Sơn, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Kim Sơn, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29482",
        "parent_code": "849"
    },
    "29485": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Thanh Sơn, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29485",
        "parent_code": "849"
    },
    "29488": {
        "name": "Hàm Giang",
        "type": "xa",
        "slug": "ham-giang",
        "name_with_type": "Xã Hàm Giang",
        "path": "Hàm Giang, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Hàm Giang, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29488",
        "parent_code": "849"
    },
    "29489": {
        "name": "Hàm Tân",
        "type": "xa",
        "slug": "ham-tan",
        "name_with_type": "Xã Hàm Tân",
        "path": "Hàm Tân, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Hàm Tân, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29489",
        "parent_code": "849"
    },
    "29491": {
        "name": "Đại An",
        "type": "xa",
        "slug": "dai-an",
        "name_with_type": "Xã Đại An",
        "path": "Đại An, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Đại An, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29491",
        "parent_code": "849"
    },
    "29494": {
        "name": "Định An",
        "type": "xa",
        "slug": "dinh-an",
        "name_with_type": "Xã Định An",
        "path": "Định An, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Định An, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29494",
        "parent_code": "849"
    },
    "29503": {
        "name": "Ngọc Biên",
        "type": "xa",
        "slug": "ngoc-bien",
        "name_with_type": "Xã Ngọc Biên",
        "path": "Ngọc Biên, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Ngọc Biên, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29503",
        "parent_code": "849"
    },
    "29506": {
        "name": "Long Hiệp",
        "type": "xa",
        "slug": "long-hiep",
        "name_with_type": "Xã Long Hiệp",
        "path": "Long Hiệp, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Long Hiệp, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29506",
        "parent_code": "849"
    },
    "29509": {
        "name": "Tân Hiệp",
        "type": "xa",
        "slug": "tan-hiep",
        "name_with_type": "Xã Tân Hiệp",
        "path": "Tân Hiệp, Trà Cú, Trà Vinh",
        "path_with_type": "Xã Tân Hiệp, Huyện Trà Cú, Tỉnh Trà Vinh",
        "code": "29509",
        "parent_code": "849"
    },
    "29497": {
        "name": "Đôn Xuân",
        "type": "xa",
        "slug": "don-xuan",
        "name_with_type": "Xã Đôn Xuân",
        "path": "Đôn Xuân, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Đôn Xuân, Huyện Duyên Hải, Tỉnh Trà Vinh",
        "code": "29497",
        "parent_code": "850"
    },
    "29500": {
        "name": "Đôn Châu",
        "type": "xa",
        "slug": "don-chau",
        "name_with_type": "Xã Đôn Châu",
        "path": "Đôn Châu, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Đôn Châu, Huyện Duyên Hải, Tỉnh Trà Vinh",
        "code": "29500",
        "parent_code": "850"
    },
    "29513": {
        "name": "Long Thành",
        "type": "thi-tran",
        "slug": "long-thanh",
        "name_with_type": "Thị trấn Long Thành",
        "path": "Long Thành, Duyên Hải, Trà Vinh",
        "path_with_type": "Thị trấn Long Thành, Huyện Duyên Hải, Tỉnh Trà Vinh",
        "code": "29513",
        "parent_code": "850"
    },
    "29521": {
        "name": "Long Khánh",
        "type": "xa",
        "slug": "long-khanh",
        "name_with_type": "Xã Long Khánh",
        "path": "Long Khánh, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Long Khánh, Huyện Duyên Hải, Tỉnh Trà Vinh",
        "code": "29521",
        "parent_code": "850"
    },
    "29530": {
        "name": "Ngũ Lạc",
        "type": "xa",
        "slug": "ngu-lac",
        "name_with_type": "Xã Ngũ Lạc",
        "path": "Ngũ Lạc, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Ngũ Lạc, Huyện Duyên Hải, Tỉnh Trà Vinh",
        "code": "29530",
        "parent_code": "850"
    },
    "29533": {
        "name": "Long Vĩnh",
        "type": "xa",
        "slug": "long-vinh",
        "name_with_type": "Xã Long Vĩnh",
        "path": "Long Vĩnh, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Long Vĩnh, Huyện Duyên Hải, Tỉnh Trà Vinh",
        "code": "29533",
        "parent_code": "850"
    },
    "29536": {
        "name": "Đông Hải",
        "type": "xa",
        "slug": "dong-hai",
        "name_with_type": "Xã Đông Hải",
        "path": "Đông Hải, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Đông Hải, Huyện Duyên Hải, Tỉnh Trà Vinh",
        "code": "29536",
        "parent_code": "850"
    },
    "29512": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Duyên Hải, Trà Vinh",
        "path_with_type": "Phường 1, Thị xã Duyên Hải, Tỉnh Trà Vinh",
        "code": "29512",
        "parent_code": "851"
    },
    "29515": {
        "name": "Long Toàn",
        "type": "xa",
        "slug": "long-toan",
        "name_with_type": "Xã Long Toàn",
        "path": "Long Toàn, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Long Toàn, Thị xã Duyên Hải, Tỉnh Trà Vinh",
        "code": "29515",
        "parent_code": "851"
    },
    "29516": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Duyên Hải, Trà Vinh",
        "path_with_type": "Phường 2, Thị xã Duyên Hải, Tỉnh Trà Vinh",
        "code": "29516",
        "parent_code": "851"
    },
    "29518": {
        "name": "Long Hữu",
        "type": "xa",
        "slug": "long-huu",
        "name_with_type": "Xã Long Hữu",
        "path": "Long Hữu, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Long Hữu, Thị xã Duyên Hải, Tỉnh Trà Vinh",
        "code": "29518",
        "parent_code": "851"
    },
    "29524": {
        "name": "Dân Thành",
        "type": "xa",
        "slug": "dan-thanh",
        "name_with_type": "Xã Dân Thành",
        "path": "Dân Thành, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Dân Thành, Thị xã Duyên Hải, Tỉnh Trà Vinh",
        "code": "29524",
        "parent_code": "851"
    },
    "29527": {
        "name": "Trường Long Hòa",
        "type": "xa",
        "slug": "truong-long-hoa",
        "name_with_type": "Xã Trường Long Hòa",
        "path": "Trường Long Hòa, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Trường Long Hòa, Thị xã Duyên Hải, Tỉnh Trà Vinh",
        "code": "29527",
        "parent_code": "851"
    },
    "29539": {
        "name": "Hiệp Thạnh",
        "type": "xa",
        "slug": "hiep-thanh",
        "name_with_type": "Xã Hiệp Thạnh",
        "path": "Hiệp Thạnh, Duyên Hải, Trà Vinh",
        "path_with_type": "Xã Hiệp Thạnh, Thị xã Duyên Hải, Tỉnh Trà Vinh",
        "code": "29539",
        "parent_code": "851"
    },
    "12433": {
        "name": "Lê Hồng Phong",
        "type": "phuong",
        "slug": "le-hong-phong",
        "name_with_type": "Phường Lê Hồng Phong",
        "path": "Lê Hồng Phong, Thái Bình, Thái Bình",
        "path_with_type": "Phường Lê Hồng Phong, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12433",
        "parent_code": "336"
    },
    "12436": {
        "name": "Bồ Xuyên",
        "type": "phuong",
        "slug": "bo-xuyen",
        "name_with_type": "Phường Bồ Xuyên",
        "path": "Bồ Xuyên, Thái Bình, Thái Bình",
        "path_with_type": "Phường Bồ Xuyên, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12436",
        "parent_code": "336"
    },
    "12439": {
        "name": "Đề Thám",
        "type": "phuong",
        "slug": "de-tham",
        "name_with_type": "Phường Đề Thám",
        "path": "Đề Thám, Thái Bình, Thái Bình",
        "path_with_type": "Phường Đề Thám, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12439",
        "parent_code": "336"
    },
    "12442": {
        "name": "Kỳ Bá",
        "type": "phuong",
        "slug": "ky-ba",
        "name_with_type": "Phường Kỳ Bá",
        "path": "Kỳ Bá, Thái Bình, Thái Bình",
        "path_with_type": "Phường Kỳ Bá, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12442",
        "parent_code": "336"
    },
    "12445": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Thái Bình, Thái Bình",
        "path_with_type": "Phường Quang Trung, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12445",
        "parent_code": "336"
    },
    "12448": {
        "name": "Phú Khánh",
        "type": "phuong",
        "slug": "phu-khanh",
        "name_with_type": "Phường Phú Khánh",
        "path": "Phú Khánh, Thái Bình, Thái Bình",
        "path_with_type": "Phường Phú Khánh, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12448",
        "parent_code": "336"
    },
    "12451": {
        "name": "Tiền Phong",
        "type": "phuong",
        "slug": "tien-phong",
        "name_with_type": "Phường Tiền Phong",
        "path": "Tiền Phong, Thái Bình, Thái Bình",
        "path_with_type": "Phường Tiền Phong, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12451",
        "parent_code": "336"
    },
    "12452": {
        "name": "Trần Hưng Đạo",
        "type": "phuong",
        "slug": "tran-hung-dao",
        "name_with_type": "Phường Trần Hưng Đạo",
        "path": "Trần Hưng Đạo, Thái Bình, Thái Bình",
        "path_with_type": "Phường Trần Hưng Đạo, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12452",
        "parent_code": "336"
    },
    "12454": {
        "name": "Trần Lãm",
        "type": "phuong",
        "slug": "tran-lam",
        "name_with_type": "Phường Trần Lãm",
        "path": "Trần Lãm, Thái Bình, Thái Bình",
        "path_with_type": "Phường Trần Lãm, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12454",
        "parent_code": "336"
    },
    "12457": {
        "name": "Đông Hòa",
        "type": "xa",
        "slug": "dong-hoa",
        "name_with_type": "Xã Đông Hòa",
        "path": "Đông Hòa, Thái Bình, Thái Bình",
        "path_with_type": "Xã Đông Hòa, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12457",
        "parent_code": "336"
    },
    "12460": {
        "name": "Hoàng Diệu",
        "type": "phuong",
        "slug": "hoang-dieu",
        "name_with_type": "Phường Hoàng Diệu",
        "path": "Hoàng Diệu, Thái Bình, Thái Bình",
        "path_with_type": "Phường Hoàng Diệu, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12460",
        "parent_code": "336"
    },
    "12463": {
        "name": "Phú Xuân",
        "type": "xa",
        "slug": "phu-xuan",
        "name_with_type": "Xã Phú Xuân",
        "path": "Phú Xuân, Thái Bình, Thái Bình",
        "path_with_type": "Xã Phú Xuân, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12463",
        "parent_code": "336"
    },
    "12466": {
        "name": "Vũ Phúc",
        "type": "xa",
        "slug": "vu-phuc",
        "name_with_type": "Xã Vũ Phúc",
        "path": "Vũ Phúc, Thái Bình, Thái Bình",
        "path_with_type": "Xã Vũ Phúc, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12466",
        "parent_code": "336"
    },
    "12469": {
        "name": "Vũ Chính",
        "type": "xa",
        "slug": "vu-chinh",
        "name_with_type": "Xã Vũ Chính",
        "path": "Vũ Chính, Thái Bình, Thái Bình",
        "path_with_type": "Xã Vũ Chính, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12469",
        "parent_code": "336"
    },
    "12817": {
        "name": "Đông Mỹ",
        "type": "xa",
        "slug": "dong-my",
        "name_with_type": "Xã Đông Mỹ",
        "path": "Đông Mỹ, Thái Bình, Thái Bình",
        "path_with_type": "Xã Đông Mỹ, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12817",
        "parent_code": "336"
    },
    "12820": {
        "name": "Đông Thọ",
        "type": "xa",
        "slug": "dong-tho",
        "name_with_type": "Xã Đông Thọ",
        "path": "Đông Thọ, Thái Bình, Thái Bình",
        "path_with_type": "Xã Đông Thọ, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "12820",
        "parent_code": "336"
    },
    "13084": {
        "name": "Vũ Đông",
        "type": "xa",
        "slug": "vu-dong",
        "name_with_type": "Xã Vũ Đông",
        "path": "Vũ Đông, Thái Bình, Thái Bình",
        "path_with_type": "Xã Vũ Đông, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "13084",
        "parent_code": "336"
    },
    "13108": {
        "name": "Vũ Lạc",
        "type": "xa",
        "slug": "vu-lac",
        "name_with_type": "Xã Vũ Lạc",
        "path": "Vũ Lạc, Thái Bình, Thái Bình",
        "path_with_type": "Xã Vũ Lạc, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "13108",
        "parent_code": "336"
    },
    "13225": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Thái Bình, Thái Bình",
        "path_with_type": "Xã Tân Bình, Thành phố Thái Bình, Tỉnh Thái Bình",
        "code": "13225",
        "parent_code": "336"
    },
    "12472": {
        "name": "Quỳnh Côi",
        "type": "thi-tran",
        "slug": "quynh-coi",
        "name_with_type": "Thị trấn Quỳnh Côi",
        "path": "Quỳnh Côi, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Thị trấn Quỳnh Côi, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12472",
        "parent_code": "338"
    },
    "12475": {
        "name": "An Khê",
        "type": "xa",
        "slug": "an-khe",
        "name_with_type": "Xã An Khê",
        "path": "An Khê, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Khê, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12475",
        "parent_code": "338"
    },
    "12478": {
        "name": "An Đồng",
        "type": "xa",
        "slug": "an-dong",
        "name_with_type": "Xã An Đồng",
        "path": "An Đồng, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Đồng, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12478",
        "parent_code": "338"
    },
    "12481": {
        "name": "Quỳnh Hoa",
        "type": "xa",
        "slug": "quynh-hoa",
        "name_with_type": "Xã Quỳnh Hoa",
        "path": "Quỳnh Hoa, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Hoa, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12481",
        "parent_code": "338"
    },
    "12484": {
        "name": "Quỳnh Lâm",
        "type": "xa",
        "slug": "quynh-lam",
        "name_with_type": "Xã Quỳnh Lâm",
        "path": "Quỳnh Lâm, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Lâm, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12484",
        "parent_code": "338"
    },
    "12487": {
        "name": "Quỳnh Thọ",
        "type": "xa",
        "slug": "quynh-tho",
        "name_with_type": "Xã Quỳnh Thọ",
        "path": "Quỳnh Thọ, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Thọ, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12487",
        "parent_code": "338"
    },
    "12490": {
        "name": "An Hiệp",
        "type": "xa",
        "slug": "an-hiep",
        "name_with_type": "Xã An Hiệp",
        "path": "An Hiệp, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Hiệp, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12490",
        "parent_code": "338"
    },
    "12493": {
        "name": "Quỳnh Hoàng",
        "type": "xa",
        "slug": "quynh-hoang",
        "name_with_type": "Xã Quỳnh Hoàng",
        "path": "Quỳnh Hoàng, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Hoàng, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12493",
        "parent_code": "338"
    },
    "12496": {
        "name": "Quỳnh Giao",
        "type": "xa",
        "slug": "quynh-giao",
        "name_with_type": "Xã Quỳnh Giao",
        "path": "Quỳnh Giao, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Giao, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12496",
        "parent_code": "338"
    },
    "12499": {
        "name": "An Thái",
        "type": "xa",
        "slug": "an-thai",
        "name_with_type": "Xã An Thái",
        "path": "An Thái, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Thái, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12499",
        "parent_code": "338"
    },
    "12502": {
        "name": "An Cầu",
        "type": "xa",
        "slug": "an-cau",
        "name_with_type": "Xã An Cầu",
        "path": "An Cầu, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Cầu, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12502",
        "parent_code": "338"
    },
    "12505": {
        "name": "Quỳnh Hồng",
        "type": "xa",
        "slug": "quynh-hong",
        "name_with_type": "Xã Quỳnh Hồng",
        "path": "Quỳnh Hồng, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Hồng, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12505",
        "parent_code": "338"
    },
    "12508": {
        "name": "Quỳnh Khê",
        "type": "xa",
        "slug": "quynh-khe",
        "name_with_type": "Xã Quỳnh Khê",
        "path": "Quỳnh Khê, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Khê, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12508",
        "parent_code": "338"
    },
    "12511": {
        "name": "Quỳnh Minh",
        "type": "xa",
        "slug": "quynh-minh",
        "name_with_type": "Xã Quỳnh Minh",
        "path": "Quỳnh Minh, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Minh, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12511",
        "parent_code": "338"
    },
    "12514": {
        "name": "An Ninh",
        "type": "xa",
        "slug": "an-ninh",
        "name_with_type": "Xã An Ninh",
        "path": "An Ninh, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Ninh, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12514",
        "parent_code": "338"
    },
    "12517": {
        "name": "Quỳnh Ngọc",
        "type": "xa",
        "slug": "quynh-ngoc",
        "name_with_type": "Xã Quỳnh Ngọc",
        "path": "Quỳnh Ngọc, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Ngọc, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12517",
        "parent_code": "338"
    },
    "12520": {
        "name": "Quỳnh Hải",
        "type": "xa",
        "slug": "quynh-hai",
        "name_with_type": "Xã Quỳnh Hải",
        "path": "Quỳnh Hải, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Hải, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12520",
        "parent_code": "338"
    },
    "12523": {
        "name": "An Bài",
        "type": "thi-tran",
        "slug": "an-bai",
        "name_with_type": "Thị trấn An Bài",
        "path": "An Bài, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Thị trấn An Bài, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12523",
        "parent_code": "338"
    },
    "12526": {
        "name": "An Ấp",
        "type": "xa",
        "slug": "an-ap",
        "name_with_type": "Xã An Ấp",
        "path": "An Ấp, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Ấp, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12526",
        "parent_code": "338"
    },
    "12529": {
        "name": "Quỳnh Hội",
        "type": "xa",
        "slug": "quynh-hoi",
        "name_with_type": "Xã Quỳnh Hội",
        "path": "Quỳnh Hội, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Hội, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12529",
        "parent_code": "338"
    },
    "12532": {
        "name": "Quỳnh Sơn",
        "type": "xa",
        "slug": "quynh-son",
        "name_with_type": "Xã Quỳnh Sơn",
        "path": "Quỳnh Sơn, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Sơn, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12532",
        "parent_code": "338"
    },
    "12535": {
        "name": "Quỳnh Mỹ",
        "type": "xa",
        "slug": "quynh-my",
        "name_with_type": "Xã Quỳnh Mỹ",
        "path": "Quỳnh Mỹ, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Mỹ, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12535",
        "parent_code": "338"
    },
    "12538": {
        "name": "An Quí",
        "type": "xa",
        "slug": "an-qui",
        "name_with_type": "Xã An Quí",
        "path": "An Quí, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Quí, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12538",
        "parent_code": "338"
    },
    "12541": {
        "name": "An Thanh",
        "type": "xa",
        "slug": "an-thanh",
        "name_with_type": "Xã An Thanh",
        "path": "An Thanh, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Thanh, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12541",
        "parent_code": "338"
    },
    "12544": {
        "name": "Quỳnh Châu",
        "type": "xa",
        "slug": "quynh-chau",
        "name_with_type": "Xã Quỳnh Châu",
        "path": "Quỳnh Châu, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Châu, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12544",
        "parent_code": "338"
    },
    "12547": {
        "name": "An Vũ",
        "type": "xa",
        "slug": "an-vu",
        "name_with_type": "Xã An Vũ",
        "path": "An Vũ, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Vũ, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12547",
        "parent_code": "338"
    },
    "12550": {
        "name": "An Lễ",
        "type": "xa",
        "slug": "an-le",
        "name_with_type": "Xã An Lễ",
        "path": "An Lễ, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Lễ, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12550",
        "parent_code": "338"
    },
    "12553": {
        "name": "Quỳnh Hưng",
        "type": "xa",
        "slug": "quynh-hung",
        "name_with_type": "Xã Quỳnh Hưng",
        "path": "Quỳnh Hưng, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Hưng, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12553",
        "parent_code": "338"
    },
    "12556": {
        "name": "Quỳnh Bảo",
        "type": "xa",
        "slug": "quynh-bao",
        "name_with_type": "Xã Quỳnh Bảo",
        "path": "Quỳnh Bảo, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Bảo, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12556",
        "parent_code": "338"
    },
    "12559": {
        "name": "An Mỹ",
        "type": "xa",
        "slug": "an-my",
        "name_with_type": "Xã An Mỹ",
        "path": "An Mỹ, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Mỹ, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12559",
        "parent_code": "338"
    },
    "12562": {
        "name": "Quỳnh Nguyên",
        "type": "xa",
        "slug": "quynh-nguyen",
        "name_with_type": "Xã Quỳnh Nguyên",
        "path": "Quỳnh Nguyên, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Nguyên, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12562",
        "parent_code": "338"
    },
    "12565": {
        "name": "An Vinh",
        "type": "xa",
        "slug": "an-vinh",
        "name_with_type": "Xã An Vinh",
        "path": "An Vinh, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Vinh, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12565",
        "parent_code": "338"
    },
    "12568": {
        "name": "Quỳnh Xá",
        "type": "xa",
        "slug": "quynh-xa",
        "name_with_type": "Xã Quỳnh Xá",
        "path": "Quỳnh Xá, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Xá, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12568",
        "parent_code": "338"
    },
    "12571": {
        "name": "An Dục",
        "type": "xa",
        "slug": "an-duc",
        "name_with_type": "Xã An Dục",
        "path": "An Dục, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Dục, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12571",
        "parent_code": "338"
    },
    "12574": {
        "name": "Đông Hải",
        "type": "xa",
        "slug": "dong-hai",
        "name_with_type": "Xã Đông Hải",
        "path": "Đông Hải, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Đông Hải, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12574",
        "parent_code": "338"
    },
    "12577": {
        "name": "Quỳnh Trang",
        "type": "xa",
        "slug": "quynh-trang",
        "name_with_type": "Xã Quỳnh Trang",
        "path": "Quỳnh Trang, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Quỳnh Trang, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12577",
        "parent_code": "338"
    },
    "12580": {
        "name": "An Tràng",
        "type": "xa",
        "slug": "an-trang",
        "name_with_type": "Xã An Tràng",
        "path": "An Tràng, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã An Tràng, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12580",
        "parent_code": "338"
    },
    "12583": {
        "name": "Đồng Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đồng Tiến",
        "path": "Đồng Tiến, Quỳnh Phụ, Thái Bình",
        "path_with_type": "Xã Đồng Tiến, Huyện Quỳnh Phụ, Tỉnh Thái Bình",
        "code": "12583",
        "parent_code": "338"
    },
    "12586": {
        "name": "Hưng Hà",
        "type": "thi-tran",
        "slug": "hung-ha",
        "name_with_type": "Thị trấn Hưng Hà",
        "path": "Hưng Hà, Hưng Hà, Thái Bình",
        "path_with_type": "Thị trấn Hưng Hà, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12586",
        "parent_code": "339"
    },
    "12589": {
        "name": "Điệp Nông",
        "type": "xa",
        "slug": "diep-nong",
        "name_with_type": "Xã Điệp Nông",
        "path": "Điệp Nông, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Điệp Nông, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12589",
        "parent_code": "339"
    },
    "12592": {
        "name": "Tân Lễ",
        "type": "xa",
        "slug": "tan-le",
        "name_with_type": "Xã Tân Lễ",
        "path": "Tân Lễ, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Tân Lễ, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12592",
        "parent_code": "339"
    },
    "12595": {
        "name": "Cộng Hòa",
        "type": "xa",
        "slug": "cong-hoa",
        "name_with_type": "Xã Cộng Hòa",
        "path": "Cộng Hòa, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Cộng Hòa, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12595",
        "parent_code": "339"
    },
    "12598": {
        "name": "Dân Chủ",
        "type": "xa",
        "slug": "dan-chu",
        "name_with_type": "Xã Dân Chủ",
        "path": "Dân Chủ, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Dân Chủ, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12598",
        "parent_code": "339"
    },
    "12601": {
        "name": "Canh Tân",
        "type": "xa",
        "slug": "canh-tan",
        "name_with_type": "Xã Canh Tân",
        "path": "Canh Tân, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Canh Tân, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12601",
        "parent_code": "339"
    },
    "12604": {
        "name": "Hòa Tiến",
        "type": "xa",
        "slug": "hoa-tien",
        "name_with_type": "Xã Hòa Tiến",
        "path": "Hòa Tiến, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Hòa Tiến, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12604",
        "parent_code": "339"
    },
    "12607": {
        "name": "Hùng Dũng",
        "type": "xa",
        "slug": "hung-dung",
        "name_with_type": "Xã Hùng Dũng",
        "path": "Hùng Dũng, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Hùng Dũng, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12607",
        "parent_code": "339"
    },
    "12610": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Tân Tiến, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12610",
        "parent_code": "339"
    },
    "12613": {
        "name": "Hưng Nhân",
        "type": "thi-tran",
        "slug": "hung-nhan",
        "name_with_type": "Thị trấn Hưng Nhân",
        "path": "Hưng Nhân, Hưng Hà, Thái Bình",
        "path_with_type": "Thị trấn Hưng Nhân, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12613",
        "parent_code": "339"
    },
    "12616": {
        "name": "Đoan Hùng",
        "type": "xa",
        "slug": "doan-hung",
        "name_with_type": "Xã Đoan Hùng",
        "path": "Đoan Hùng, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Đoan Hùng, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12616",
        "parent_code": "339"
    },
    "12619": {
        "name": "Duyên Hải",
        "type": "xa",
        "slug": "duyen-hai",
        "name_with_type": "Xã Duyên Hải",
        "path": "Duyên Hải, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Duyên Hải, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12619",
        "parent_code": "339"
    },
    "12622": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Tân Hòa, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12622",
        "parent_code": "339"
    },
    "12625": {
        "name": "Văn Cẩm",
        "type": "xa",
        "slug": "van-cam",
        "name_with_type": "Xã Văn Cẩm",
        "path": "Văn Cẩm, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Văn Cẩm, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12625",
        "parent_code": "339"
    },
    "12628": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Bắc Sơn, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12628",
        "parent_code": "339"
    },
    "12631": {
        "name": "Đông Đô",
        "type": "xa",
        "slug": "dong-do",
        "name_with_type": "Xã Đông Đô",
        "path": "Đông Đô, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Đông Đô, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12631",
        "parent_code": "339"
    },
    "12634": {
        "name": "Phúc Khánh",
        "type": "xa",
        "slug": "phuc-khanh",
        "name_with_type": "Xã Phúc Khánh",
        "path": "Phúc Khánh, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Phúc Khánh, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12634",
        "parent_code": "339"
    },
    "12637": {
        "name": "Liên Hiệp",
        "type": "xa",
        "slug": "lien-hiep",
        "name_with_type": "Xã Liên Hiệp",
        "path": "Liên Hiệp, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Liên Hiệp, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12637",
        "parent_code": "339"
    },
    "12640": {
        "name": "Tây Đô",
        "type": "xa",
        "slug": "tay-do",
        "name_with_type": "Xã Tây Đô",
        "path": "Tây Đô, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Tây Đô, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12640",
        "parent_code": "339"
    },
    "12643": {
        "name": "Thống Nhất",
        "type": "xa",
        "slug": "thong-nhat",
        "name_with_type": "Xã Thống Nhất",
        "path": "Thống Nhất, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Thống Nhất, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12643",
        "parent_code": "339"
    },
    "12646": {
        "name": "Tiến Đức",
        "type": "xa",
        "slug": "tien-duc",
        "name_with_type": "Xã Tiến Đức",
        "path": "Tiến Đức, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Tiến Đức, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12646",
        "parent_code": "339"
    },
    "12649": {
        "name": "Thái Hưng",
        "type": "xa",
        "slug": "thai-hung",
        "name_with_type": "Xã Thái Hưng",
        "path": "Thái Hưng, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Thái Hưng, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12649",
        "parent_code": "339"
    },
    "12652": {
        "name": "Thái Phương",
        "type": "xa",
        "slug": "thai-phuong",
        "name_with_type": "Xã Thái Phương",
        "path": "Thái Phương, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Thái Phương, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12652",
        "parent_code": "339"
    },
    "12655": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Hòa Bình, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12655",
        "parent_code": "339"
    },
    "12656": {
        "name": "Chi Lăng",
        "type": "xa",
        "slug": "chi-lang",
        "name_with_type": "Xã Chi Lăng",
        "path": "Chi Lăng, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Chi Lăng, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12656",
        "parent_code": "339"
    },
    "12658": {
        "name": "Minh Khai",
        "type": "xa",
        "slug": "minh-khai",
        "name_with_type": "Xã Minh Khai",
        "path": "Minh Khai, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Minh Khai, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12658",
        "parent_code": "339"
    },
    "12661": {
        "name": "Hồng An",
        "type": "xa",
        "slug": "hong-an",
        "name_with_type": "Xã Hồng An",
        "path": "Hồng An, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Hồng An, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12661",
        "parent_code": "339"
    },
    "12664": {
        "name": "Kim Chung",
        "type": "xa",
        "slug": "kim-chung",
        "name_with_type": "Xã Kim Chung",
        "path": "Kim Chung, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Kim Chung, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12664",
        "parent_code": "339"
    },
    "12667": {
        "name": "Hồng Lĩnh",
        "type": "xa",
        "slug": "hong-linh",
        "name_with_type": "Xã Hồng Lĩnh",
        "path": "Hồng Lĩnh, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Hồng Lĩnh, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12667",
        "parent_code": "339"
    },
    "12670": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Minh Tân, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12670",
        "parent_code": "339"
    },
    "12673": {
        "name": "Văn Lang",
        "type": "xa",
        "slug": "van-lang",
        "name_with_type": "Xã Văn Lang",
        "path": "Văn Lang, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Văn Lang, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12673",
        "parent_code": "339"
    },
    "12676": {
        "name": "Độc Lập",
        "type": "xa",
        "slug": "doc-lap",
        "name_with_type": "Xã Độc Lập",
        "path": "Độc Lập, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Độc Lập, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12676",
        "parent_code": "339"
    },
    "12679": {
        "name": "Chí Hòa",
        "type": "xa",
        "slug": "chi-hoa",
        "name_with_type": "Xã Chí Hòa",
        "path": "Chí Hòa, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Chí Hòa, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12679",
        "parent_code": "339"
    },
    "12682": {
        "name": "Minh Hòa",
        "type": "xa",
        "slug": "minh-hoa",
        "name_with_type": "Xã Minh Hòa",
        "path": "Minh Hòa, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Minh Hòa, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12682",
        "parent_code": "339"
    },
    "12685": {
        "name": "Hồng Minh",
        "type": "xa",
        "slug": "hong-minh",
        "name_with_type": "Xã Hồng Minh",
        "path": "Hồng Minh, Hưng Hà, Thái Bình",
        "path_with_type": "Xã Hồng Minh, Huyện Hưng Hà, Tỉnh Thái Bình",
        "code": "12685",
        "parent_code": "339"
    },
    "12688": {
        "name": "Đông Hưng",
        "type": "thi-tran",
        "slug": "dong-hung",
        "name_with_type": "Thị trấn Đông Hưng",
        "path": "Đông Hưng, Đông Hưng, Thái Bình",
        "path_with_type": "Thị trấn Đông Hưng, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12688",
        "parent_code": "340"
    },
    "12691": {
        "name": "Đô Lương",
        "type": "xa",
        "slug": "do-luong",
        "name_with_type": "Xã Đô Lương",
        "path": "Đô Lương, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đô Lương, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12691",
        "parent_code": "340"
    },
    "12694": {
        "name": "Đông Phương",
        "type": "xa",
        "slug": "dong-phuong",
        "name_with_type": "Xã Đông Phương",
        "path": "Đông Phương, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Phương, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12694",
        "parent_code": "340"
    },
    "12697": {
        "name": "Liên Giang",
        "type": "xa",
        "slug": "lien-giang",
        "name_with_type": "Xã Liên Giang",
        "path": "Liên Giang, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Liên Giang, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12697",
        "parent_code": "340"
    },
    "12700": {
        "name": "An Châu",
        "type": "xa",
        "slug": "an-chau",
        "name_with_type": "Xã An Châu",
        "path": "An Châu, Đông Hưng, Thái Bình",
        "path_with_type": "Xã An Châu, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12700",
        "parent_code": "340"
    },
    "12703": {
        "name": "Đông Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đông Sơn",
        "path": "Đông Sơn, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Sơn, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12703",
        "parent_code": "340"
    },
    "12706": {
        "name": "Đông Cường",
        "type": "xa",
        "slug": "dong-cuong",
        "name_with_type": "Xã Đông Cường",
        "path": "Đông Cường, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Cường, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12706",
        "parent_code": "340"
    },
    "12709": {
        "name": "Phú Lương",
        "type": "xa",
        "slug": "phu-luong",
        "name_with_type": "Xã Phú Lương",
        "path": "Phú Lương, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Phú Lương, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12709",
        "parent_code": "340"
    },
    "12712": {
        "name": "Mê Linh",
        "type": "xa",
        "slug": "me-linh",
        "name_with_type": "Xã Mê Linh",
        "path": "Mê Linh, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Mê Linh, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12712",
        "parent_code": "340"
    },
    "12715": {
        "name": "Lô Giang",
        "type": "xa",
        "slug": "lo-giang",
        "name_with_type": "Xã Lô Giang",
        "path": "Lô Giang, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Lô Giang, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12715",
        "parent_code": "340"
    },
    "12718": {
        "name": "Đông La",
        "type": "xa",
        "slug": "dong-la",
        "name_with_type": "Xã Đông La",
        "path": "Đông La, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông La, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12718",
        "parent_code": "340"
    },
    "12721": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Minh Tân, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12721",
        "parent_code": "340"
    },
    "12724": {
        "name": "Đông Xá",
        "type": "xa",
        "slug": "dong-xa",
        "name_with_type": "Xã Đông Xá",
        "path": "Đông Xá, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Xá, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12724",
        "parent_code": "340"
    },
    "12727": {
        "name": "Chương Dương",
        "type": "xa",
        "slug": "chuong-duong",
        "name_with_type": "Xã Chương Dương",
        "path": "Chương Dương, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Chương Dương, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12727",
        "parent_code": "340"
    },
    "12730": {
        "name": "Nguyên Xá",
        "type": "xa",
        "slug": "nguyen-xa",
        "name_with_type": "Xã Nguyên Xá",
        "path": "Nguyên Xá, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Nguyên Xá, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12730",
        "parent_code": "340"
    },
    "12733": {
        "name": "Phong Châu",
        "type": "xa",
        "slug": "phong-chau",
        "name_with_type": "Xã Phong Châu",
        "path": "Phong Châu, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Phong Châu, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12733",
        "parent_code": "340"
    },
    "12736": {
        "name": "Hợp Tiến",
        "type": "xa",
        "slug": "hop-tien",
        "name_with_type": "Xã Hợp Tiến",
        "path": "Hợp Tiến, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Hợp Tiến, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12736",
        "parent_code": "340"
    },
    "12739": {
        "name": "Hồng Việt",
        "type": "xa",
        "slug": "hong-viet",
        "name_with_type": "Xã Hồng Việt",
        "path": "Hồng Việt, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Hồng Việt, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12739",
        "parent_code": "340"
    },
    "12742": {
        "name": "Đông Hà",
        "type": "xa",
        "slug": "dong-ha",
        "name_with_type": "Xã Đông Hà",
        "path": "Đông Hà, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Hà, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12742",
        "parent_code": "340"
    },
    "12745": {
        "name": "Đông Giang",
        "type": "xa",
        "slug": "dong-giang",
        "name_with_type": "Xã Đông Giang",
        "path": "Đông Giang, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Giang, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12745",
        "parent_code": "340"
    },
    "12748": {
        "name": "Đông Kinh",
        "type": "xa",
        "slug": "dong-kinh",
        "name_with_type": "Xã Đông Kinh",
        "path": "Đông Kinh, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Kinh, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12748",
        "parent_code": "340"
    },
    "12751": {
        "name": "Đông Hợp",
        "type": "xa",
        "slug": "dong-hop",
        "name_with_type": "Xã Đông Hợp",
        "path": "Đông Hợp, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Hợp, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12751",
        "parent_code": "340"
    },
    "12754": {
        "name": "Thăng Long",
        "type": "xa",
        "slug": "thang-long",
        "name_with_type": "Xã Thăng Long",
        "path": "Thăng Long, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Thăng Long, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12754",
        "parent_code": "340"
    },
    "12757": {
        "name": "Đông Các",
        "type": "xa",
        "slug": "dong-cac",
        "name_with_type": "Xã Đông Các",
        "path": "Đông Các, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Các, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12757",
        "parent_code": "340"
    },
    "12760": {
        "name": "Phú Châu",
        "type": "xa",
        "slug": "phu-chau",
        "name_with_type": "Xã Phú Châu",
        "path": "Phú Châu, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Phú Châu, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12760",
        "parent_code": "340"
    },
    "12763": {
        "name": "Hoa Lư",
        "type": "xa",
        "slug": "hoa-lu",
        "name_with_type": "Xã Hoa Lư",
        "path": "Hoa Lư, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Hoa Lư, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12763",
        "parent_code": "340"
    },
    "12766": {
        "name": "Minh Châu",
        "type": "xa",
        "slug": "minh-chau",
        "name_with_type": "Xã Minh Châu",
        "path": "Minh Châu, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Minh Châu, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12766",
        "parent_code": "340"
    },
    "12769": {
        "name": "Đông Tân",
        "type": "xa",
        "slug": "dong-tan",
        "name_with_type": "Xã Đông Tân",
        "path": "Đông Tân, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Tân, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12769",
        "parent_code": "340"
    },
    "12772": {
        "name": "Đông Vinh",
        "type": "xa",
        "slug": "dong-vinh",
        "name_with_type": "Xã Đông Vinh",
        "path": "Đông Vinh, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Vinh, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12772",
        "parent_code": "340"
    },
    "12775": {
        "name": "Đông Động",
        "type": "xa",
        "slug": "dong-dong",
        "name_with_type": "Xã Đông Động",
        "path": "Đông Động, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Động, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12775",
        "parent_code": "340"
    },
    "12778": {
        "name": "Hồng Châu",
        "type": "xa",
        "slug": "hong-chau",
        "name_with_type": "Xã Hồng Châu",
        "path": "Hồng Châu, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Hồng Châu, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12778",
        "parent_code": "340"
    },
    "12781": {
        "name": "Bạch Đằng",
        "type": "xa",
        "slug": "bach-dang",
        "name_with_type": "Xã Bạch Đằng",
        "path": "Bạch Đằng, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Bạch Đằng, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12781",
        "parent_code": "340"
    },
    "12784": {
        "name": "Trọng Quan",
        "type": "xa",
        "slug": "trong-quan",
        "name_with_type": "Xã Trọng Quan",
        "path": "Trọng Quan, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Trọng Quan, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12784",
        "parent_code": "340"
    },
    "12787": {
        "name": "Hoa Nam",
        "type": "xa",
        "slug": "hoa-nam",
        "name_with_type": "Xã Hoa Nam",
        "path": "Hoa Nam, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Hoa Nam, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12787",
        "parent_code": "340"
    },
    "12790": {
        "name": "Hồng Giang",
        "type": "xa",
        "slug": "hong-giang",
        "name_with_type": "Xã Hồng Giang",
        "path": "Hồng Giang, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Hồng Giang, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12790",
        "parent_code": "340"
    },
    "12793": {
        "name": "Đông Phong",
        "type": "xa",
        "slug": "dong-phong",
        "name_with_type": "Xã Đông Phong",
        "path": "Đông Phong, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Phong, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12793",
        "parent_code": "340"
    },
    "12796": {
        "name": "Đông Quang",
        "type": "xa",
        "slug": "dong-quang",
        "name_with_type": "Xã Đông Quang",
        "path": "Đông Quang, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Quang, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12796",
        "parent_code": "340"
    },
    "12799": {
        "name": "Đông Xuân",
        "type": "xa",
        "slug": "dong-xuan",
        "name_with_type": "Xã Đông Xuân",
        "path": "Đông Xuân, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Xuân, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12799",
        "parent_code": "340"
    },
    "12802": {
        "name": "Đông Á",
        "type": "xa",
        "slug": "dong-a",
        "name_with_type": "Xã Đông Á",
        "path": "Đông Á, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Á, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12802",
        "parent_code": "340"
    },
    "12805": {
        "name": "Đông Lĩnh",
        "type": "xa",
        "slug": "dong-linh",
        "name_with_type": "Xã Đông Lĩnh",
        "path": "Đông Lĩnh, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Lĩnh, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12805",
        "parent_code": "340"
    },
    "12808": {
        "name": "Đông Hoàng",
        "type": "xa",
        "slug": "dong-hoang",
        "name_with_type": "Xã Đông Hoàng",
        "path": "Đông Hoàng, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Hoàng, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12808",
        "parent_code": "340"
    },
    "12811": {
        "name": "Đông Dương",
        "type": "xa",
        "slug": "dong-duong",
        "name_with_type": "Xã Đông Dương",
        "path": "Đông Dương, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Dương, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12811",
        "parent_code": "340"
    },
    "12814": {
        "name": "Đông Huy",
        "type": "xa",
        "slug": "dong-huy",
        "name_with_type": "Xã Đông Huy",
        "path": "Đông Huy, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đông Huy, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12814",
        "parent_code": "340"
    },
    "12823": {
        "name": "Đồng Phú",
        "type": "xa",
        "slug": "dong-phu",
        "name_with_type": "Xã Đồng Phú",
        "path": "Đồng Phú, Đông Hưng, Thái Bình",
        "path_with_type": "Xã Đồng Phú, Huyện Đông Hưng, Tỉnh Thái Bình",
        "code": "12823",
        "parent_code": "340"
    },
    "12826": {
        "name": "Diêm Điền",
        "type": "thi-tran",
        "slug": "diem-dien",
        "name_with_type": "Thị trấn Diêm Điền",
        "path": "Diêm Điền, Thái Thụy, Thái Bình",
        "path_with_type": "Thị trấn Diêm Điền, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12826",
        "parent_code": "341"
    },
    "12829": {
        "name": "Thụy Tân",
        "type": "xa",
        "slug": "thuy-tan",
        "name_with_type": "Xã Thụy Tân",
        "path": "Thụy Tân, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Tân, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12829",
        "parent_code": "341"
    },
    "12832": {
        "name": "Thụy Trường",
        "type": "xa",
        "slug": "thuy-truong",
        "name_with_type": "Xã Thụy Trường",
        "path": "Thụy Trường, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Trường, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12832",
        "parent_code": "341"
    },
    "12835": {
        "name": "Hồng Quỳnh",
        "type": "xa",
        "slug": "hong-quynh",
        "name_with_type": "Xã Hồng Quỳnh",
        "path": "Hồng Quỳnh, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Hồng Quỳnh, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12835",
        "parent_code": "341"
    },
    "12838": {
        "name": "Thụy Dũng",
        "type": "xa",
        "slug": "thuy-dung",
        "name_with_type": "Xã Thụy Dũng",
        "path": "Thụy Dũng, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Dũng, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12838",
        "parent_code": "341"
    },
    "12841": {
        "name": "Thụy Hồng",
        "type": "xa",
        "slug": "thuy-hong",
        "name_with_type": "Xã Thụy Hồng",
        "path": "Thụy Hồng, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Hồng, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12841",
        "parent_code": "341"
    },
    "12844": {
        "name": "Thụy Quỳnh",
        "type": "xa",
        "slug": "thuy-quynh",
        "name_with_type": "Xã Thụy Quỳnh",
        "path": "Thụy Quỳnh, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Quỳnh, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12844",
        "parent_code": "341"
    },
    "12847": {
        "name": "Thụy An",
        "type": "xa",
        "slug": "thuy-an",
        "name_with_type": "Xã Thụy An",
        "path": "Thụy An, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy An, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12847",
        "parent_code": "341"
    },
    "12850": {
        "name": "Thụy Ninh",
        "type": "xa",
        "slug": "thuy-ninh",
        "name_with_type": "Xã Thụy Ninh",
        "path": "Thụy Ninh, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Ninh, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12850",
        "parent_code": "341"
    },
    "12853": {
        "name": "Thụy Hưng",
        "type": "xa",
        "slug": "thuy-hung",
        "name_with_type": "Xã Thụy Hưng",
        "path": "Thụy Hưng, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Hưng, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12853",
        "parent_code": "341"
    },
    "12856": {
        "name": "Thụy Việt",
        "type": "xa",
        "slug": "thuy-viet",
        "name_with_type": "Xã Thụy Việt",
        "path": "Thụy Việt, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Việt, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12856",
        "parent_code": "341"
    },
    "12859": {
        "name": "Thụy Văn",
        "type": "xa",
        "slug": "thuy-van",
        "name_with_type": "Xã Thụy Văn",
        "path": "Thụy Văn, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Văn, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12859",
        "parent_code": "341"
    },
    "12862": {
        "name": "Thụy Xuân",
        "type": "xa",
        "slug": "thuy-xuan",
        "name_with_type": "Xã Thụy Xuân",
        "path": "Thụy Xuân, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Xuân, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12862",
        "parent_code": "341"
    },
    "12865": {
        "name": "Thụy Dương",
        "type": "xa",
        "slug": "thuy-duong",
        "name_with_type": "Xã Thụy Dương",
        "path": "Thụy Dương, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Dương, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12865",
        "parent_code": "341"
    },
    "12868": {
        "name": "Thụy Trình",
        "type": "xa",
        "slug": "thuy-trinh",
        "name_with_type": "Xã Thụy Trình",
        "path": "Thụy Trình, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Trình, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12868",
        "parent_code": "341"
    },
    "12871": {
        "name": "Thụy Bình",
        "type": "xa",
        "slug": "thuy-binh",
        "name_with_type": "Xã Thụy Bình",
        "path": "Thụy Bình, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Bình, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12871",
        "parent_code": "341"
    },
    "12874": {
        "name": "Thụy Chính",
        "type": "xa",
        "slug": "thuy-chinh",
        "name_with_type": "Xã Thụy Chính",
        "path": "Thụy Chính, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Chính, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12874",
        "parent_code": "341"
    },
    "12877": {
        "name": "Thụy Dân",
        "type": "xa",
        "slug": "thuy-dan",
        "name_with_type": "Xã Thụy Dân",
        "path": "Thụy Dân, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Dân, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12877",
        "parent_code": "341"
    },
    "12880": {
        "name": "Thụy Hải",
        "type": "xa",
        "slug": "thuy-hai",
        "name_with_type": "Xã Thụy Hải",
        "path": "Thụy Hải, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Hải, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12880",
        "parent_code": "341"
    },
    "12883": {
        "name": "Thụy Phúc",
        "type": "xa",
        "slug": "thuy-phuc",
        "name_with_type": "Xã Thụy Phúc",
        "path": "Thụy Phúc, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Phúc, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12883",
        "parent_code": "341"
    },
    "12886": {
        "name": "Thụy Lương",
        "type": "xa",
        "slug": "thuy-luong",
        "name_with_type": "Xã Thụy Lương",
        "path": "Thụy Lương, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Lương, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12886",
        "parent_code": "341"
    },
    "12889": {
        "name": "Thụy Liên",
        "type": "xa",
        "slug": "thuy-lien",
        "name_with_type": "Xã Thụy Liên",
        "path": "Thụy Liên, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Liên, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12889",
        "parent_code": "341"
    },
    "12892": {
        "name": "Thụy Duyên",
        "type": "xa",
        "slug": "thuy-duyen",
        "name_with_type": "Xã Thụy Duyên",
        "path": "Thụy Duyên, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Duyên, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12892",
        "parent_code": "341"
    },
    "12895": {
        "name": "Thụy Hà",
        "type": "xa",
        "slug": "thuy-ha",
        "name_with_type": "Xã Thụy Hà",
        "path": "Thụy Hà, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Hà, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12895",
        "parent_code": "341"
    },
    "12898": {
        "name": "Thụy Thanh",
        "type": "xa",
        "slug": "thuy-thanh",
        "name_with_type": "Xã Thụy Thanh",
        "path": "Thụy Thanh, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Thanh, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12898",
        "parent_code": "341"
    },
    "12901": {
        "name": "Thụy Sơn",
        "type": "xa",
        "slug": "thuy-son",
        "name_with_type": "Xã Thụy Sơn",
        "path": "Thụy Sơn, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Sơn, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12901",
        "parent_code": "341"
    },
    "12904": {
        "name": "Thụy Phong",
        "type": "xa",
        "slug": "thuy-phong",
        "name_with_type": "Xã Thụy Phong",
        "path": "Thụy Phong, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thụy Phong, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12904",
        "parent_code": "341"
    },
    "12907": {
        "name": "Thái Thượng",
        "type": "xa",
        "slug": "thai-thuong",
        "name_with_type": "Xã Thái Thượng",
        "path": "Thái Thượng, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Thượng, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12907",
        "parent_code": "341"
    },
    "12910": {
        "name": "Thái Nguyên",
        "type": "xa",
        "slug": "thai-nguyen",
        "name_with_type": "Xã Thái Nguyên",
        "path": "Thái Nguyên, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Nguyên, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12910",
        "parent_code": "341"
    },
    "12913": {
        "name": "Thái Thủy",
        "type": "xa",
        "slug": "thai-thuy",
        "name_with_type": "Xã Thái Thủy",
        "path": "Thái Thủy, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Thủy, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12913",
        "parent_code": "341"
    },
    "12916": {
        "name": "Thái Dương",
        "type": "xa",
        "slug": "thai-duong",
        "name_with_type": "Xã Thái Dương",
        "path": "Thái Dương, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Dương, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12916",
        "parent_code": "341"
    },
    "12919": {
        "name": "Thái Giang",
        "type": "xa",
        "slug": "thai-giang",
        "name_with_type": "Xã Thái Giang",
        "path": "Thái Giang, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Giang, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12919",
        "parent_code": "341"
    },
    "12922": {
        "name": "Thái Hòa",
        "type": "xa",
        "slug": "thai-hoa",
        "name_with_type": "Xã Thái Hòa",
        "path": "Thái Hòa, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Hòa, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12922",
        "parent_code": "341"
    },
    "12925": {
        "name": "Thái Sơn",
        "type": "xa",
        "slug": "thai-son",
        "name_with_type": "Xã Thái Sơn",
        "path": "Thái Sơn, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Sơn, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12925",
        "parent_code": "341"
    },
    "12928": {
        "name": "Thái Hồng",
        "type": "xa",
        "slug": "thai-hong",
        "name_with_type": "Xã Thái Hồng",
        "path": "Thái Hồng, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Hồng, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12928",
        "parent_code": "341"
    },
    "12931": {
        "name": "Thái An",
        "type": "xa",
        "slug": "thai-an",
        "name_with_type": "Xã Thái An",
        "path": "Thái An, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái An, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12931",
        "parent_code": "341"
    },
    "12934": {
        "name": "Thái Phúc",
        "type": "xa",
        "slug": "thai-phuc",
        "name_with_type": "Xã Thái Phúc",
        "path": "Thái Phúc, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Phúc, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12934",
        "parent_code": "341"
    },
    "12937": {
        "name": "Thái Hưng",
        "type": "xa",
        "slug": "thai-hung",
        "name_with_type": "Xã Thái Hưng",
        "path": "Thái Hưng, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Hưng, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12937",
        "parent_code": "341"
    },
    "12940": {
        "name": "Thái Đô",
        "type": "xa",
        "slug": "thai-do",
        "name_with_type": "Xã Thái Đô",
        "path": "Thái Đô, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Đô, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12940",
        "parent_code": "341"
    },
    "12943": {
        "name": "Thái Xuyên",
        "type": "xa",
        "slug": "thai-xuyen",
        "name_with_type": "Xã Thái Xuyên",
        "path": "Thái Xuyên, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Xuyên, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12943",
        "parent_code": "341"
    },
    "12946": {
        "name": "Thái Hà",
        "type": "xa",
        "slug": "thai-ha",
        "name_with_type": "Xã Thái Hà",
        "path": "Thái Hà, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Hà, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12946",
        "parent_code": "341"
    },
    "12949": {
        "name": "Mỹ Lộc",
        "type": "xa",
        "slug": "my-loc",
        "name_with_type": "Xã  Mỹ Lộc",
        "path": "Mỹ Lộc, Thái Thụy, Thái Bình",
        "path_with_type": "Xã  Mỹ Lộc, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12949",
        "parent_code": "341"
    },
    "12952": {
        "name": "Thái Tân",
        "type": "xa",
        "slug": "thai-tan",
        "name_with_type": "Xã Thái Tân",
        "path": "Thái Tân, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Tân, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12952",
        "parent_code": "341"
    },
    "12955": {
        "name": "Thái Thuần",
        "type": "xa",
        "slug": "thai-thuan",
        "name_with_type": "Xã Thái Thuần",
        "path": "Thái Thuần, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Thuần, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12955",
        "parent_code": "341"
    },
    "12958": {
        "name": "Thái Học",
        "type": "xa",
        "slug": "thai-hoc",
        "name_with_type": "Xã Thái Học",
        "path": "Thái Học, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Học, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12958",
        "parent_code": "341"
    },
    "12961": {
        "name": "Thái Thịnh",
        "type": "xa",
        "slug": "thai-thinh",
        "name_with_type": "Xã Thái Thịnh",
        "path": "Thái Thịnh, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Thịnh, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12961",
        "parent_code": "341"
    },
    "12964": {
        "name": "Thái Thành",
        "type": "xa",
        "slug": "thai-thanh",
        "name_with_type": "Xã Thái Thành",
        "path": "Thái Thành, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Thành, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12964",
        "parent_code": "341"
    },
    "12967": {
        "name": "Thái Thọ",
        "type": "xa",
        "slug": "thai-tho",
        "name_with_type": "Xã Thái Thọ",
        "path": "Thái Thọ, Thái Thụy, Thái Bình",
        "path_with_type": "Xã Thái Thọ, Huyện Thái Thụy, Tỉnh Thái Bình",
        "code": "12967",
        "parent_code": "341"
    },
    "12970": {
        "name": "Tiền Hải",
        "type": "thi-tran",
        "slug": "tien-hai",
        "name_with_type": "Thị trấn Tiền Hải",
        "path": "Tiền Hải, Tiền Hải, Thái Bình",
        "path_with_type": "Thị trấn Tiền Hải, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12970",
        "parent_code": "342"
    },
    "12973": {
        "name": "Đông Hải",
        "type": "xa",
        "slug": "dong-hai",
        "name_with_type": "Xã Đông Hải",
        "path": "Đông Hải, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Hải, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12973",
        "parent_code": "342"
    },
    "12976": {
        "name": "Đông Trà",
        "type": "xa",
        "slug": "dong-tra",
        "name_with_type": "Xã Đông Trà",
        "path": "Đông Trà, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Trà, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12976",
        "parent_code": "342"
    },
    "12979": {
        "name": "Đông Long",
        "type": "xa",
        "slug": "dong-long",
        "name_with_type": "Xã Đông Long",
        "path": "Đông Long, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Long, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12979",
        "parent_code": "342"
    },
    "12982": {
        "name": "Đông Quí",
        "type": "xa",
        "slug": "dong-qui",
        "name_with_type": "Xã Đông Quí",
        "path": "Đông Quí, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Quí, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12982",
        "parent_code": "342"
    },
    "12985": {
        "name": "Vũ Lăng",
        "type": "xa",
        "slug": "vu-lang",
        "name_with_type": "Xã Vũ Lăng",
        "path": "Vũ Lăng, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Vũ Lăng, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12985",
        "parent_code": "342"
    },
    "12988": {
        "name": "Đông Xuyên",
        "type": "xa",
        "slug": "dong-xuyen",
        "name_with_type": "Xã Đông Xuyên",
        "path": "Đông Xuyên, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Xuyên, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12988",
        "parent_code": "342"
    },
    "12991": {
        "name": "Tây Lương",
        "type": "xa",
        "slug": "tay-luong",
        "name_with_type": "Xã Tây Lương",
        "path": "Tây Lương, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Tây Lương, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12991",
        "parent_code": "342"
    },
    "12994": {
        "name": "Tây Ninh",
        "type": "xa",
        "slug": "tay-ninh",
        "name_with_type": "Xã Tây Ninh",
        "path": "Tây Ninh, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Tây Ninh, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12994",
        "parent_code": "342"
    },
    "12997": {
        "name": "Đông Trung",
        "type": "xa",
        "slug": "dong-trung",
        "name_with_type": "Xã Đông Trung",
        "path": "Đông Trung, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Trung, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "12997",
        "parent_code": "342"
    },
    "13000": {
        "name": "Đông Hoàng",
        "type": "xa",
        "slug": "dong-hoang",
        "name_with_type": "Xã Đông Hoàng",
        "path": "Đông Hoàng, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Hoàng, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13000",
        "parent_code": "342"
    },
    "13003": {
        "name": "Đông Minh",
        "type": "xa",
        "slug": "dong-minh",
        "name_with_type": "Xã Đông Minh",
        "path": "Đông Minh, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Minh, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13003",
        "parent_code": "342"
    },
    "13006": {
        "name": "Tây An",
        "type": "xa",
        "slug": "tay-an",
        "name_with_type": "Xã Tây An",
        "path": "Tây An, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Tây An, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13006",
        "parent_code": "342"
    },
    "13009": {
        "name": "Đông Phong",
        "type": "xa",
        "slug": "dong-phong",
        "name_with_type": "Xã Đông Phong",
        "path": "Đông Phong, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Phong, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13009",
        "parent_code": "342"
    },
    "13012": {
        "name": "An Ninh",
        "type": "xa",
        "slug": "an-ninh",
        "name_with_type": "Xã An Ninh",
        "path": "An Ninh, Tiền Hải, Thái Bình",
        "path_with_type": "Xã An Ninh, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13012",
        "parent_code": "342"
    },
    "13015": {
        "name": "Tây Sơn",
        "type": "xa",
        "slug": "tay-son",
        "name_with_type": "Xã Tây Sơn",
        "path": "Tây Sơn, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Tây Sơn, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13015",
        "parent_code": "342"
    },
    "13018": {
        "name": "Đông Cơ",
        "type": "xa",
        "slug": "dong-co",
        "name_with_type": "Xã Đông Cơ",
        "path": "Đông Cơ, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Cơ, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13018",
        "parent_code": "342"
    },
    "13021": {
        "name": "Tây Giang",
        "type": "xa",
        "slug": "tay-giang",
        "name_with_type": "Xã Tây Giang",
        "path": "Tây Giang, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Tây Giang, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13021",
        "parent_code": "342"
    },
    "13024": {
        "name": "Đông Lâm",
        "type": "xa",
        "slug": "dong-lam",
        "name_with_type": "Xã Đông Lâm",
        "path": "Đông Lâm, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Đông Lâm, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13024",
        "parent_code": "342"
    },
    "13027": {
        "name": "Phương Công",
        "type": "xa",
        "slug": "phuong-cong",
        "name_with_type": "Xã Phương Công",
        "path": "Phương Công, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Phương Công, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13027",
        "parent_code": "342"
    },
    "13030": {
        "name": "Tây Phong",
        "type": "xa",
        "slug": "tay-phong",
        "name_with_type": "Xã Tây Phong",
        "path": "Tây Phong, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Tây Phong, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13030",
        "parent_code": "342"
    },
    "13033": {
        "name": "Tây Tiến",
        "type": "xa",
        "slug": "tay-tien",
        "name_with_type": "Xã Tây Tiến",
        "path": "Tây Tiến, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Tây Tiến, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13033",
        "parent_code": "342"
    },
    "13036": {
        "name": "Nam Cường",
        "type": "xa",
        "slug": "nam-cuong",
        "name_with_type": "Xã Nam Cường",
        "path": "Nam Cường, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Cường, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13036",
        "parent_code": "342"
    },
    "13039": {
        "name": "Vân Trường",
        "type": "xa",
        "slug": "van-truong",
        "name_with_type": "Xã Vân Trường",
        "path": "Vân Trường, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Vân Trường, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13039",
        "parent_code": "342"
    },
    "13042": {
        "name": "Nam Thắng",
        "type": "xa",
        "slug": "nam-thang",
        "name_with_type": "Xã Nam Thắng",
        "path": "Nam Thắng, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Thắng, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13042",
        "parent_code": "342"
    },
    "13045": {
        "name": "Nam Chính",
        "type": "xa",
        "slug": "nam-chinh",
        "name_with_type": "Xã Nam Chính",
        "path": "Nam Chính, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Chính, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13045",
        "parent_code": "342"
    },
    "13048": {
        "name": "Bắc Hải",
        "type": "xa",
        "slug": "bac-hai",
        "name_with_type": "Xã Bắc Hải",
        "path": "Bắc Hải, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Bắc Hải, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13048",
        "parent_code": "342"
    },
    "13051": {
        "name": "Nam Thịnh",
        "type": "xa",
        "slug": "nam-thinh",
        "name_with_type": "Xã Nam Thịnh",
        "path": "Nam Thịnh, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Thịnh, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13051",
        "parent_code": "342"
    },
    "13054": {
        "name": "Nam Hà",
        "type": "xa",
        "slug": "nam-ha",
        "name_with_type": "Xã Nam Hà",
        "path": "Nam Hà, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Hà, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13054",
        "parent_code": "342"
    },
    "13057": {
        "name": "Nam Thanh",
        "type": "xa",
        "slug": "nam-thanh",
        "name_with_type": "Xã Nam Thanh",
        "path": "Nam Thanh, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Thanh, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13057",
        "parent_code": "342"
    },
    "13060": {
        "name": "Nam Trung",
        "type": "xa",
        "slug": "nam-trung",
        "name_with_type": "Xã Nam Trung",
        "path": "Nam Trung, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Trung, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13060",
        "parent_code": "342"
    },
    "13063": {
        "name": "Nam Hồng",
        "type": "xa",
        "slug": "nam-hong",
        "name_with_type": "Xã Nam Hồng",
        "path": "Nam Hồng, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Hồng, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13063",
        "parent_code": "342"
    },
    "13066": {
        "name": "Nam Hưng",
        "type": "xa",
        "slug": "nam-hung",
        "name_with_type": "Xã Nam Hưng",
        "path": "Nam Hưng, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Hưng, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13066",
        "parent_code": "342"
    },
    "13069": {
        "name": "Nam Hải",
        "type": "xa",
        "slug": "nam-hai",
        "name_with_type": "Xã Nam Hải",
        "path": "Nam Hải, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Hải, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13069",
        "parent_code": "342"
    },
    "13072": {
        "name": "Nam Phú",
        "type": "xa",
        "slug": "nam-phu",
        "name_with_type": "Xã Nam Phú",
        "path": "Nam Phú, Tiền Hải, Thái Bình",
        "path_with_type": "Xã Nam Phú, Huyện Tiền Hải, Tỉnh Thái Bình",
        "code": "13072",
        "parent_code": "342"
    },
    "13075": {
        "name": "Thanh Nê",
        "type": "thi-tran",
        "slug": "thanh-ne",
        "name_with_type": "Thị trấn Thanh Nê",
        "path": "Thanh Nê, Kiến Xương, Thái Bình",
        "path_with_type": "Thị trấn Thanh Nê, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13075",
        "parent_code": "343"
    },
    "13078": {
        "name": "Trà Giang",
        "type": "xa",
        "slug": "tra-giang",
        "name_with_type": "Xã Trà Giang",
        "path": "Trà Giang, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Trà Giang, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13078",
        "parent_code": "343"
    },
    "13081": {
        "name": "Quốc Tuấn",
        "type": "xa",
        "slug": "quoc-tuan",
        "name_with_type": "Xã Quốc Tuấn",
        "path": "Quốc Tuấn, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Quốc Tuấn, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13081",
        "parent_code": "343"
    },
    "13087": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Kiến Xương, Thái Bình",
        "path_with_type": "Xã An Bình, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13087",
        "parent_code": "343"
    },
    "13090": {
        "name": "Vũ Tây",
        "type": "xa",
        "slug": "vu-tay",
        "name_with_type": "Xã Vũ Tây",
        "path": "Vũ Tây, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Tây, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13090",
        "parent_code": "343"
    },
    "13093": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Hồng Thái, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13093",
        "parent_code": "343"
    },
    "13096": {
        "name": "Bình Nguyên",
        "type": "xa",
        "slug": "binh-nguyen",
        "name_with_type": "Xã Bình Nguyên",
        "path": "Bình Nguyên, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Bình Nguyên, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13096",
        "parent_code": "343"
    },
    "13099": {
        "name": "Vũ Sơn",
        "type": "xa",
        "slug": "vu-son",
        "name_with_type": "Xã Vũ Sơn",
        "path": "Vũ Sơn, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Sơn, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13099",
        "parent_code": "343"
    },
    "13102": {
        "name": "Lê Lợi",
        "type": "xa",
        "slug": "le-loi",
        "name_with_type": "Xã Lê Lợi",
        "path": "Lê Lợi, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Lê Lợi, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13102",
        "parent_code": "343"
    },
    "13105": {
        "name": "Quyết Tiến",
        "type": "xa",
        "slug": "quyet-tien",
        "name_with_type": "Xã Quyết Tiến",
        "path": "Quyết Tiến, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Quyết Tiến, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13105",
        "parent_code": "343"
    },
    "13111": {
        "name": "Vũ Lễ",
        "type": "xa",
        "slug": "vu-le",
        "name_with_type": "Xã Vũ Lễ",
        "path": "Vũ Lễ, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Lễ, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13111",
        "parent_code": "343"
    },
    "13114": {
        "name": "Thanh Tân",
        "type": "xa",
        "slug": "thanh-tan",
        "name_with_type": "Xã Thanh Tân",
        "path": "Thanh Tân, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Thanh Tân, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13114",
        "parent_code": "343"
    },
    "13117": {
        "name": "Thượng Hiền",
        "type": "xa",
        "slug": "thuong-hien",
        "name_with_type": "Xã Thượng Hiền",
        "path": "Thượng Hiền, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Thượng Hiền, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13117",
        "parent_code": "343"
    },
    "13120": {
        "name": "Nam Cao",
        "type": "xa",
        "slug": "nam-cao",
        "name_with_type": "Xã Nam Cao",
        "path": "Nam Cao, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Nam Cao, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13120",
        "parent_code": "343"
    },
    "13123": {
        "name": "Đình Phùng",
        "type": "xa",
        "slug": "dinh-phung",
        "name_with_type": "Xã Đình Phùng",
        "path": "Đình Phùng, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Đình Phùng, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13123",
        "parent_code": "343"
    },
    "13126": {
        "name": "Vũ Ninh",
        "type": "xa",
        "slug": "vu-ninh",
        "name_with_type": "Xã Vũ Ninh",
        "path": "Vũ Ninh, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Ninh, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13126",
        "parent_code": "343"
    },
    "13129": {
        "name": "Vũ An",
        "type": "xa",
        "slug": "vu-an",
        "name_with_type": "Xã Vũ An",
        "path": "Vũ An, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ An, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13129",
        "parent_code": "343"
    },
    "13132": {
        "name": "Quang Lịch",
        "type": "xa",
        "slug": "quang-lich",
        "name_with_type": "Xã Quang Lịch",
        "path": "Quang Lịch, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Quang Lịch, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13132",
        "parent_code": "343"
    },
    "13135": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Hòa Bình, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13135",
        "parent_code": "343"
    },
    "13138": {
        "name": "Bình Minh",
        "type": "xa",
        "slug": "binh-minh",
        "name_with_type": "Xã Bình Minh",
        "path": "Bình Minh, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Bình Minh, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13138",
        "parent_code": "343"
    },
    "13141": {
        "name": "Vũ Quí",
        "type": "xa",
        "slug": "vu-qui",
        "name_with_type": "Xã Vũ Quí",
        "path": "Vũ Quí, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Quí, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13141",
        "parent_code": "343"
    },
    "13144": {
        "name": "Quang Bình",
        "type": "xa",
        "slug": "quang-binh",
        "name_with_type": "Xã Quang Bình",
        "path": "Quang Bình, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Quang Bình, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13144",
        "parent_code": "343"
    },
    "13147": {
        "name": "An Bồi",
        "type": "xa",
        "slug": "an-boi",
        "name_with_type": "Xã An Bồi",
        "path": "An Bồi, Kiến Xương, Thái Bình",
        "path_with_type": "Xã An Bồi, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13147",
        "parent_code": "343"
    },
    "13150": {
        "name": "Vũ Trung",
        "type": "xa",
        "slug": "vu-trung",
        "name_with_type": "Xã Vũ Trung",
        "path": "Vũ Trung, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Trung, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13150",
        "parent_code": "343"
    },
    "13153": {
        "name": "Vũ Thắng",
        "type": "xa",
        "slug": "vu-thang",
        "name_with_type": "Xã Vũ Thắng",
        "path": "Vũ Thắng, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Thắng, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13153",
        "parent_code": "343"
    },
    "13156": {
        "name": "Vũ Công",
        "type": "xa",
        "slug": "vu-cong",
        "name_with_type": "Xã Vũ Công",
        "path": "Vũ Công, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Công, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13156",
        "parent_code": "343"
    },
    "13159": {
        "name": "Vũ Hòa",
        "type": "xa",
        "slug": "vu-hoa",
        "name_with_type": "Xã Vũ Hòa",
        "path": "Vũ Hòa, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Hòa, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13159",
        "parent_code": "343"
    },
    "13162": {
        "name": "Quang Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quang Minh",
        "path": "Quang Minh, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Quang Minh, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13162",
        "parent_code": "343"
    },
    "13165": {
        "name": "Quang Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quang Trung",
        "path": "Quang Trung, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Quang Trung, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13165",
        "parent_code": "343"
    },
    "13168": {
        "name": "Minh Hưng",
        "type": "xa",
        "slug": "minh-hung",
        "name_with_type": "Xã Minh Hưng",
        "path": "Minh Hưng, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Minh Hưng, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13168",
        "parent_code": "343"
    },
    "13171": {
        "name": "Quang Hưng",
        "type": "xa",
        "slug": "quang-hung",
        "name_with_type": "Xã Quang Hưng",
        "path": "Quang Hưng, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Quang Hưng, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13171",
        "parent_code": "343"
    },
    "13174": {
        "name": "Vũ Bình",
        "type": "xa",
        "slug": "vu-binh",
        "name_with_type": "Xã Vũ Bình",
        "path": "Vũ Bình, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Vũ Bình, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13174",
        "parent_code": "343"
    },
    "13177": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Minh Tân, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13177",
        "parent_code": "343"
    },
    "13180": {
        "name": "Nam Bình",
        "type": "xa",
        "slug": "nam-binh",
        "name_with_type": "Xã Nam Bình",
        "path": "Nam Bình, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Nam Bình, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13180",
        "parent_code": "343"
    },
    "13183": {
        "name": "Bình Thanh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thanh",
        "path": "Bình Thanh, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Bình Thanh, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13183",
        "parent_code": "343"
    },
    "13186": {
        "name": "Bình Định",
        "type": "xa",
        "slug": "binh-dinh",
        "name_with_type": "Xã Bình Định",
        "path": "Bình Định, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Bình Định, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13186",
        "parent_code": "343"
    },
    "13189": {
        "name": "Hồng Tiến",
        "type": "xa",
        "slug": "hong-tien",
        "name_with_type": "Xã Hồng Tiến",
        "path": "Hồng Tiến, Kiến Xương, Thái Bình",
        "path_with_type": "Xã Hồng Tiến, Huyện Kiến Xương, Tỉnh Thái Bình",
        "code": "13189",
        "parent_code": "343"
    },
    "13192": {
        "name": "Vũ Thư",
        "type": "thi-tran",
        "slug": "vu-thu",
        "name_with_type": "Thị trấn Vũ Thư",
        "path": "Vũ Thư, Vũ Thư, Thái Bình",
        "path_with_type": "Thị trấn Vũ Thư, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13192",
        "parent_code": "344"
    },
    "13195": {
        "name": "Hồng Lý",
        "type": "xa",
        "slug": "hong-ly",
        "name_with_type": "Xã Hồng Lý",
        "path": "Hồng Lý, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Hồng Lý, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13195",
        "parent_code": "344"
    },
    "13198": {
        "name": "Đồng Thanh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đồng Thanh",
        "path": "Đồng Thanh, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Đồng Thanh, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13198",
        "parent_code": "344"
    },
    "13201": {
        "name": "Xuân Hòa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hòa",
        "path": "Xuân Hòa, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Xuân Hòa, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13201",
        "parent_code": "344"
    },
    "13204": {
        "name": "Hiệp Hòa",
        "type": "xa",
        "slug": "hiep-hoa",
        "name_with_type": "Xã Hiệp Hòa",
        "path": "Hiệp Hòa, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Hiệp Hòa, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13204",
        "parent_code": "344"
    },
    "13207": {
        "name": "Phúc Thành",
        "type": "xa",
        "slug": "phuc-thanh",
        "name_with_type": "Xã Phúc Thành",
        "path": "Phúc Thành, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Phúc Thành, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13207",
        "parent_code": "344"
    },
    "13210": {
        "name": "Tân Phong",
        "type": "xa",
        "slug": "tan-phong",
        "name_with_type": "Xã Tân Phong",
        "path": "Tân Phong, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Tân Phong, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13210",
        "parent_code": "344"
    },
    "13213": {
        "name": "Song Lãng",
        "type": "xa",
        "slug": "song-lang",
        "name_with_type": "Xã Song Lãng",
        "path": "Song Lãng, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Song Lãng, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13213",
        "parent_code": "344"
    },
    "13216": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Tân Hòa, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13216",
        "parent_code": "344"
    },
    "13219": {
        "name": "Việt Hùng",
        "type": "xa",
        "slug": "viet-hung",
        "name_with_type": "Xã Việt Hùng",
        "path": "Việt Hùng, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Việt Hùng, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13219",
        "parent_code": "344"
    },
    "13222": {
        "name": "Minh Lãng",
        "type": "xa",
        "slug": "minh-lang",
        "name_with_type": "Xã Minh Lãng",
        "path": "Minh Lãng, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Minh Lãng, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13222",
        "parent_code": "344"
    },
    "13228": {
        "name": "Minh Khai",
        "type": "xa",
        "slug": "minh-khai",
        "name_with_type": "Xã Minh Khai",
        "path": "Minh Khai, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Minh Khai, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13228",
        "parent_code": "344"
    },
    "13231": {
        "name": "Dũng Nghĩa",
        "type": "xa",
        "slug": "dung-nghia",
        "name_with_type": "Xã Dũng Nghĩa",
        "path": "Dũng Nghĩa, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Dũng Nghĩa, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13231",
        "parent_code": "344"
    },
    "13234": {
        "name": "Minh Quang",
        "type": "xa",
        "slug": "minh-quang",
        "name_with_type": "Xã Minh Quang",
        "path": "Minh Quang, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Minh Quang, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13234",
        "parent_code": "344"
    },
    "13237": {
        "name": "Tam Quang",
        "type": "xa",
        "slug": "tam-quang",
        "name_with_type": "Xã Tam Quang",
        "path": "Tam Quang, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Tam Quang, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13237",
        "parent_code": "344"
    },
    "13240": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Tân Lập, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13240",
        "parent_code": "344"
    },
    "13243": {
        "name": "Bách Thuận",
        "type": "xa",
        "slug": "bach-thuan",
        "name_with_type": "Xã Bách Thuận",
        "path": "Bách Thuận, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Bách Thuận, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13243",
        "parent_code": "344"
    },
    "13246": {
        "name": "Tự Tân",
        "type": "xa",
        "slug": "tu-tan",
        "name_with_type": "Xã Tự Tân",
        "path": "Tự Tân, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Tự Tân, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13246",
        "parent_code": "344"
    },
    "13249": {
        "name": "Song An",
        "type": "xa",
        "slug": "song-an",
        "name_with_type": "Xã Song An",
        "path": "Song An, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Song An, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13249",
        "parent_code": "344"
    },
    "13252": {
        "name": "Trung An",
        "type": "xa",
        "slug": "trung-an",
        "name_with_type": "Xã Trung An",
        "path": "Trung An, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Trung An, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13252",
        "parent_code": "344"
    },
    "13255": {
        "name": "Vũ Hội",
        "type": "xa",
        "slug": "vu-hoi",
        "name_with_type": "Xã Vũ Hội",
        "path": "Vũ Hội, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Vũ Hội, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13255",
        "parent_code": "344"
    },
    "13258": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Hòa Bình, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13258",
        "parent_code": "344"
    },
    "13261": {
        "name": "Nguyên Xá",
        "type": "xa",
        "slug": "nguyen-xa",
        "name_with_type": "Xã Nguyên Xá",
        "path": "Nguyên Xá, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Nguyên Xá, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13261",
        "parent_code": "344"
    },
    "13264": {
        "name": "Việt Thuận",
        "type": "xa",
        "slug": "viet-thuan",
        "name_with_type": "Xã Việt Thuận",
        "path": "Việt Thuận, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Việt Thuận, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13264",
        "parent_code": "344"
    },
    "13267": {
        "name": "Vũ Vinh",
        "type": "xa",
        "slug": "vu-vinh",
        "name_with_type": "Xã Vũ Vinh",
        "path": "Vũ Vinh, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Vũ Vinh, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13267",
        "parent_code": "344"
    },
    "13270": {
        "name": "Vũ Đoài",
        "type": "xa",
        "slug": "vu-doai",
        "name_with_type": "Xã Vũ Đoài",
        "path": "Vũ Đoài, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Vũ Đoài, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13270",
        "parent_code": "344"
    },
    "13273": {
        "name": "Vũ Tiến",
        "type": "xa",
        "slug": "vu-tien",
        "name_with_type": "Xã Vũ Tiến",
        "path": "Vũ Tiến, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Vũ Tiến, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13273",
        "parent_code": "344"
    },
    "13276": {
        "name": "Vũ Vân",
        "type": "xa",
        "slug": "vu-van",
        "name_with_type": "Xã Vũ Vân",
        "path": "Vũ Vân, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Vũ Vân, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13276",
        "parent_code": "344"
    },
    "13279": {
        "name": "Duy Nhất",
        "type": "xa",
        "slug": "duy-nhat",
        "name_with_type": "Xã Duy Nhất",
        "path": "Duy Nhất, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Duy Nhất, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13279",
        "parent_code": "344"
    },
    "13282": {
        "name": "Hồng Phong",
        "type": "xa",
        "slug": "hong-phong",
        "name_with_type": "Xã Hồng Phong",
        "path": "Hồng Phong, Vũ Thư, Thái Bình",
        "path_with_type": "Xã Hồng Phong, Huyện Vũ Thư, Tỉnh Thái Bình",
        "code": "13282",
        "parent_code": "344"
    },
    "18070": {
        "name": "Trần Phú",
        "type": "phuong",
        "slug": "tran-phu",
        "name_with_type": "Phường Trần Phú",
        "path": "Trần Phú, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Trần Phú, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18070",
        "parent_code": "436"
    },
    "18073": {
        "name": "Nam Hà",
        "type": "phuong",
        "slug": "nam-ha",
        "name_with_type": "Phường Nam Hà",
        "path": "Nam Hà, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Nam Hà, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18073",
        "parent_code": "436"
    },
    "18076": {
        "name": "Bắc Hà",
        "type": "phuong",
        "slug": "bac-ha",
        "name_with_type": "Phường Bắc Hà",
        "path": "Bắc Hà, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Bắc Hà, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18076",
        "parent_code": "436"
    },
    "18077": {
        "name": "Nguyễn Du",
        "type": "phuong",
        "slug": "nguyen-du",
        "name_with_type": "Phường Nguyễn Du",
        "path": "Nguyễn Du, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Nguyễn Du, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18077",
        "parent_code": "436"
    },
    "18079": {
        "name": "Tân Giang",
        "type": "phuong",
        "slug": "tan-giang",
        "name_with_type": "Phường Tân Giang",
        "path": "Tân Giang, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Tân Giang, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18079",
        "parent_code": "436"
    },
    "18082": {
        "name": "Đại Nài",
        "type": "phuong",
        "slug": "dai-nai",
        "name_with_type": "Phường Đại Nài",
        "path": "Đại Nài, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Đại Nài, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18082",
        "parent_code": "436"
    },
    "18085": {
        "name": "Hà Huy Tập",
        "type": "phuong",
        "slug": "ha-huy-tap",
        "name_with_type": "Phường Hà Huy Tập",
        "path": "Hà Huy Tập, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Hà Huy Tập, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18085",
        "parent_code": "436"
    },
    "18088": {
        "name": "Thạch Trung",
        "type": "xa",
        "slug": "thach-trung",
        "name_with_type": "Xã Thạch Trung",
        "path": "Thạch Trung, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Xã Thạch Trung, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18088",
        "parent_code": "436"
    },
    "18091": {
        "name": "Thạch Quý",
        "type": "phuong",
        "slug": "thach-quy",
        "name_with_type": "Phường Thạch Quý",
        "path": "Thạch Quý, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Thạch Quý, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18091",
        "parent_code": "436"
    },
    "18094": {
        "name": "Thạch Linh",
        "type": "phuong",
        "slug": "thach-linh",
        "name_with_type": "Phường Thạch Linh",
        "path": "Thạch Linh, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Thạch Linh, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18094",
        "parent_code": "436"
    },
    "18097": {
        "name": "Văn Yên",
        "type": "phuong",
        "slug": "van-yen",
        "name_with_type": "Phường Văn Yên",
        "path": "Văn Yên, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Phường Văn Yên, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18097",
        "parent_code": "436"
    },
    "18100": {
        "name": "Thạch Hạ",
        "type": "xa",
        "slug": "thach-ha",
        "name_with_type": "Xã Thạch Hạ",
        "path": "Thạch Hạ, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Xã Thạch Hạ, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18100",
        "parent_code": "436"
    },
    "18103": {
        "name": "Thạch Môn",
        "type": "xa",
        "slug": "thach-mon",
        "name_with_type": "Xã Thạch Môn",
        "path": "Thạch Môn, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Xã Thạch Môn, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18103",
        "parent_code": "436"
    },
    "18106": {
        "name": "Thạch Đồng",
        "type": "xa",
        "slug": "thach-dong",
        "name_with_type": "Xã Thạch Đồng",
        "path": "Thạch Đồng, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Xã Thạch Đồng, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18106",
        "parent_code": "436"
    },
    "18109": {
        "name": "Thạch Hưng",
        "type": "xa",
        "slug": "thach-hung",
        "name_with_type": "Xã Thạch Hưng",
        "path": "Thạch Hưng, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Xã Thạch Hưng, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18109",
        "parent_code": "436"
    },
    "18112": {
        "name": "Thạch Bình",
        "type": "xa",
        "slug": "thach-binh",
        "name_with_type": "Xã Thạch Bình",
        "path": "Thạch Bình, Hà Tĩnh, Hà Tĩnh",
        "path_with_type": "Xã Thạch Bình, Thành phố Hà Tĩnh, Tỉnh Hà Tĩnh",
        "code": "18112",
        "parent_code": "436"
    },
    "18115": {
        "name": "Bắc Hồng",
        "type": "phuong",
        "slug": "bac-hong",
        "name_with_type": "Phường Bắc Hồng",
        "path": "Bắc Hồng, Hồng Lĩnh, Hà Tĩnh",
        "path_with_type": "Phường Bắc Hồng, Thị xã Hồng Lĩnh, Tỉnh Hà Tĩnh",
        "code": "18115",
        "parent_code": "437"
    },
    "18118": {
        "name": "Nam Hồng",
        "type": "phuong",
        "slug": "nam-hong",
        "name_with_type": "Phường Nam Hồng",
        "path": "Nam Hồng, Hồng Lĩnh, Hà Tĩnh",
        "path_with_type": "Phường Nam Hồng, Thị xã Hồng Lĩnh, Tỉnh Hà Tĩnh",
        "code": "18118",
        "parent_code": "437"
    },
    "18121": {
        "name": "Trung Lương",
        "type": "phuong",
        "slug": "trung-luong",
        "name_with_type": "Phường Trung Lương",
        "path": "Trung Lương, Hồng Lĩnh, Hà Tĩnh",
        "path_with_type": "Phường Trung Lương, Thị xã Hồng Lĩnh, Tỉnh Hà Tĩnh",
        "code": "18121",
        "parent_code": "437"
    },
    "18124": {
        "name": "Đức Thuận",
        "type": "phuong",
        "slug": "duc-thuan",
        "name_with_type": "Phường Đức Thuận",
        "path": "Đức Thuận, Hồng Lĩnh, Hà Tĩnh",
        "path_with_type": "Phường Đức Thuận, Thị xã Hồng Lĩnh, Tỉnh Hà Tĩnh",
        "code": "18124",
        "parent_code": "437"
    },
    "18127": {
        "name": "Đậu Liêu",
        "type": "phuong",
        "slug": "dau-lieu",
        "name_with_type": "Phường Đậu Liêu",
        "path": "Đậu Liêu, Hồng Lĩnh, Hà Tĩnh",
        "path_with_type": "Phường Đậu Liêu, Thị xã Hồng Lĩnh, Tỉnh Hà Tĩnh",
        "code": "18127",
        "parent_code": "437"
    },
    "18130": {
        "name": "Thuận Lộc",
        "type": "xa",
        "slug": "thuan-loc",
        "name_with_type": "Xã Thuận Lộc",
        "path": "Thuận Lộc, Hồng Lĩnh, Hà Tĩnh",
        "path_with_type": "Xã Thuận Lộc, Thị xã Hồng Lĩnh, Tỉnh Hà Tĩnh",
        "code": "18130",
        "parent_code": "437"
    },
    "18133": {
        "name": "Phố Châu",
        "type": "thi-tran",
        "slug": "pho-chau",
        "name_with_type": "Thị trấn Phố Châu",
        "path": "Phố Châu, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Thị trấn Phố Châu, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18133",
        "parent_code": "439"
    },
    "18136": {
        "name": "Tây Sơn",
        "type": "thi-tran",
        "slug": "tay-son",
        "name_with_type": "Thị trấn  Tây Sơn",
        "path": "Tây Sơn, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Thị trấn  Tây Sơn, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18136",
        "parent_code": "439"
    },
    "18139": {
        "name": "Sơn Hồng",
        "type": "xa",
        "slug": "son-hong",
        "name_with_type": "Xã Sơn Hồng",
        "path": "Sơn Hồng, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Hồng, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18139",
        "parent_code": "439"
    },
    "18142": {
        "name": "Sơn Tiến",
        "type": "xa",
        "slug": "son-tien",
        "name_with_type": "Xã Sơn Tiến",
        "path": "Sơn Tiến, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Tiến, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18142",
        "parent_code": "439"
    },
    "18145": {
        "name": "Sơn Lâm",
        "type": "xa",
        "slug": "son-lam",
        "name_with_type": "Xã Sơn Lâm",
        "path": "Sơn Lâm, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Lâm, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18145",
        "parent_code": "439"
    },
    "18148": {
        "name": "Sơn Lễ",
        "type": "xa",
        "slug": "son-le",
        "name_with_type": "Xã Sơn Lễ",
        "path": "Sơn Lễ, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Lễ, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18148",
        "parent_code": "439"
    },
    "18151": {
        "name": "Sơn Thịnh",
        "type": "xa",
        "slug": "son-thinh",
        "name_with_type": "Xã Sơn Thịnh",
        "path": "Sơn Thịnh, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Thịnh, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18151",
        "parent_code": "439"
    },
    "18154": {
        "name": "Sơn An",
        "type": "xa",
        "slug": "son-an",
        "name_with_type": "Xã Sơn An",
        "path": "Sơn An, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn An, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18154",
        "parent_code": "439"
    },
    "18157": {
        "name": "Sơn Giang",
        "type": "xa",
        "slug": "son-giang",
        "name_with_type": "Xã Sơn Giang",
        "path": "Sơn Giang, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Giang, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18157",
        "parent_code": "439"
    },
    "18160": {
        "name": "Sơn Lĩnh",
        "type": "xa",
        "slug": "son-linh",
        "name_with_type": "Xã Sơn Lĩnh",
        "path": "Sơn Lĩnh, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Lĩnh, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18160",
        "parent_code": "439"
    },
    "18163": {
        "name": "Sơn Hòa",
        "type": "xa",
        "slug": "son-hoa",
        "name_with_type": "Xã Sơn Hòa",
        "path": "Sơn Hòa, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Hòa, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18163",
        "parent_code": "439"
    },
    "18166": {
        "name": "Sơn Tân",
        "type": "xa",
        "slug": "son-tan",
        "name_with_type": "Xã Sơn Tân",
        "path": "Sơn Tân, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Tân, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18166",
        "parent_code": "439"
    },
    "18169": {
        "name": "Sơn Mỹ",
        "type": "xa",
        "slug": "son-my",
        "name_with_type": "Xã Sơn Mỹ",
        "path": "Sơn Mỹ, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Mỹ, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18169",
        "parent_code": "439"
    },
    "18172": {
        "name": "Sơn Tây",
        "type": "xa",
        "slug": "son-tay",
        "name_with_type": "Xã Sơn Tây",
        "path": "Sơn Tây, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Tây, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18172",
        "parent_code": "439"
    },
    "18175": {
        "name": "Sơn Ninh",
        "type": "xa",
        "slug": "son-ninh",
        "name_with_type": "Xã Sơn Ninh",
        "path": "Sơn Ninh, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Ninh, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18175",
        "parent_code": "439"
    },
    "18178": {
        "name": "Sơn Châu",
        "type": "xa",
        "slug": "son-chau",
        "name_with_type": "Xã Sơn Châu",
        "path": "Sơn Châu, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Châu, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18178",
        "parent_code": "439"
    },
    "18181": {
        "name": "Sơn Hà",
        "type": "xa",
        "slug": "son-ha",
        "name_with_type": "Xã Sơn Hà",
        "path": "Sơn Hà, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Hà, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18181",
        "parent_code": "439"
    },
    "18184": {
        "name": "Sơn Quang",
        "type": "xa",
        "slug": "son-quang",
        "name_with_type": "Xã Sơn Quang",
        "path": "Sơn Quang, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Quang, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18184",
        "parent_code": "439"
    },
    "18187": {
        "name": "Sơn Trung",
        "type": "xa",
        "slug": "son-trung",
        "name_with_type": "Xã Sơn Trung",
        "path": "Sơn Trung, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Trung, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18187",
        "parent_code": "439"
    },
    "18190": {
        "name": "Sơn Bằng",
        "type": "xa",
        "slug": "son-bang",
        "name_with_type": "Xã Sơn Bằng",
        "path": "Sơn Bằng, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Bằng, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18190",
        "parent_code": "439"
    },
    "18193": {
        "name": "Sơn Bình",
        "type": "xa",
        "slug": "son-binh",
        "name_with_type": "Xã Sơn Bình",
        "path": "Sơn Bình, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Bình, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18193",
        "parent_code": "439"
    },
    "18196": {
        "name": "Sơn Kim 1",
        "type": "xa",
        "slug": "son-kim-1",
        "name_with_type": "Xã Sơn Kim 1",
        "path": "Sơn Kim 1, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Kim 1, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18196",
        "parent_code": "439"
    },
    "18199": {
        "name": "Sơn Kim 2",
        "type": "xa",
        "slug": "son-kim-2",
        "name_with_type": "Xã Sơn Kim 2",
        "path": "Sơn Kim 2, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Kim 2, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18199",
        "parent_code": "439"
    },
    "18202": {
        "name": "Sơn Trà",
        "type": "xa",
        "slug": "son-tra",
        "name_with_type": "Xã Sơn Trà",
        "path": "Sơn Trà, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Trà, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18202",
        "parent_code": "439"
    },
    "18205": {
        "name": "Sơn Long",
        "type": "xa",
        "slug": "son-long",
        "name_with_type": "Xã Sơn Long",
        "path": "Sơn Long, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Long, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18205",
        "parent_code": "439"
    },
    "18208": {
        "name": "Sơn Diệm",
        "type": "xa",
        "slug": "son-diem",
        "name_with_type": "Xã Sơn Diệm",
        "path": "Sơn Diệm, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Diệm, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18208",
        "parent_code": "439"
    },
    "18211": {
        "name": "Sơn Thủy",
        "type": "xa",
        "slug": "son-thuy",
        "name_with_type": "Xã Sơn Thủy",
        "path": "Sơn Thủy, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Thủy, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18211",
        "parent_code": "439"
    },
    "18214": {
        "name": "Sơn Hàm",
        "type": "xa",
        "slug": "son-ham",
        "name_with_type": "Xã Sơn Hàm",
        "path": "Sơn Hàm, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Hàm, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18214",
        "parent_code": "439"
    },
    "18217": {
        "name": "Sơn Phú",
        "type": "xa",
        "slug": "son-phu",
        "name_with_type": "Xã Sơn Phú",
        "path": "Sơn Phú, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Phú, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18217",
        "parent_code": "439"
    },
    "18220": {
        "name": "Sơn Phúc",
        "type": "xa",
        "slug": "son-phuc",
        "name_with_type": "Xã Sơn Phúc",
        "path": "Sơn Phúc, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Phúc, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18220",
        "parent_code": "439"
    },
    "18223": {
        "name": "Sơn Trường",
        "type": "xa",
        "slug": "son-truong",
        "name_with_type": "Xã Sơn Trường",
        "path": "Sơn Trường, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Trường, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18223",
        "parent_code": "439"
    },
    "18226": {
        "name": "Sơn Mai",
        "type": "xa",
        "slug": "son-mai",
        "name_with_type": "Xã Sơn Mai",
        "path": "Sơn Mai, Hương Sơn, Hà Tĩnh",
        "path_with_type": "Xã Sơn Mai, Huyện Hương Sơn, Tỉnh Hà Tĩnh",
        "code": "18226",
        "parent_code": "439"
    },
    "18229": {
        "name": "Đức Thọ",
        "type": "thi-tran",
        "slug": "duc-tho",
        "name_with_type": "Thị trấn Đức Thọ",
        "path": "Đức Thọ, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Thị trấn Đức Thọ, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18229",
        "parent_code": "440"
    },
    "18232": {
        "name": "Đức Quang",
        "type": "xa",
        "slug": "duc-quang",
        "name_with_type": "Xã Đức Quang",
        "path": "Đức Quang, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Quang, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18232",
        "parent_code": "440"
    },
    "18235": {
        "name": "Đức Vĩnh",
        "type": "xa",
        "slug": "duc-vinh",
        "name_with_type": "Xã Đức Vĩnh",
        "path": "Đức Vĩnh, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Vĩnh, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18235",
        "parent_code": "440"
    },
    "18238": {
        "name": "Đức Châu",
        "type": "xa",
        "slug": "duc-chau",
        "name_with_type": "Xã Đức Châu",
        "path": "Đức Châu, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Châu, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18238",
        "parent_code": "440"
    },
    "18241": {
        "name": "Đức Tùng",
        "type": "xa",
        "slug": "duc-tung",
        "name_with_type": "Xã Đức Tùng",
        "path": "Đức Tùng, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Tùng, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18241",
        "parent_code": "440"
    },
    "18244": {
        "name": "Trường Sơn",
        "type": "xa",
        "slug": "truong-son",
        "name_with_type": "Xã Trường Sơn",
        "path": "Trường Sơn, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Trường Sơn, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18244",
        "parent_code": "440"
    },
    "18247": {
        "name": "Liên Minh",
        "type": "xa",
        "slug": "lien-minh",
        "name_with_type": "Xã Liên Minh",
        "path": "Liên Minh, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Liên Minh, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18247",
        "parent_code": "440"
    },
    "18250": {
        "name": "Đức La",
        "type": "xa",
        "slug": "duc-la",
        "name_with_type": "Xã Đức La",
        "path": "Đức La, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức La, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18250",
        "parent_code": "440"
    },
    "18253": {
        "name": "Yên Hồ",
        "type": "xa",
        "slug": "yen-ho",
        "name_with_type": "Xã Yên Hồ",
        "path": "Yên Hồ, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Yên Hồ, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18253",
        "parent_code": "440"
    },
    "18256": {
        "name": "Đức Nhân",
        "type": "xa",
        "slug": "duc-nhan",
        "name_with_type": "Xã Đức Nhân",
        "path": "Đức Nhân, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Nhân, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18256",
        "parent_code": "440"
    },
    "18259": {
        "name": "Tùng Ảnh",
        "type": "xa",
        "slug": "tung-anh",
        "name_with_type": "Xã Tùng Ảnh",
        "path": "Tùng Ảnh, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Tùng Ảnh, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18259",
        "parent_code": "440"
    },
    "18262": {
        "name": "Bùi Xá",
        "type": "xa",
        "slug": "bui-xa",
        "name_with_type": "Xã Bùi Xá",
        "path": "Bùi Xá, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Bùi Xá, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18262",
        "parent_code": "440"
    },
    "18265": {
        "name": "Đức Thịnh",
        "type": "xa",
        "slug": "duc-thinh",
        "name_with_type": "Xã Đức Thịnh",
        "path": "Đức Thịnh, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Thịnh, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18265",
        "parent_code": "440"
    },
    "18268": {
        "name": "Đức Yên",
        "type": "xa",
        "slug": "duc-yen",
        "name_with_type": "Xã Đức Yên",
        "path": "Đức Yên, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Yên, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18268",
        "parent_code": "440"
    },
    "18271": {
        "name": "Đức Thủy",
        "type": "xa",
        "slug": "duc-thuy",
        "name_with_type": "Xã Đức Thủy",
        "path": "Đức Thủy, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Thủy, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18271",
        "parent_code": "440"
    },
    "18274": {
        "name": "Thái Yên",
        "type": "xa",
        "slug": "thai-yen",
        "name_with_type": "Xã Thái Yên",
        "path": "Thái Yên, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Thái Yên, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18274",
        "parent_code": "440"
    },
    "18277": {
        "name": "Trung Lễ",
        "type": "xa",
        "slug": "trung-le",
        "name_with_type": "Xã Trung Lễ",
        "path": "Trung Lễ, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Trung Lễ, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18277",
        "parent_code": "440"
    },
    "18280": {
        "name": "Đức Hòa",
        "type": "xa",
        "slug": "duc-hoa",
        "name_with_type": "Xã Đức Hòa",
        "path": "Đức Hòa, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Hòa, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18280",
        "parent_code": "440"
    },
    "18283": {
        "name": "Đức Long",
        "type": "xa",
        "slug": "duc-long",
        "name_with_type": "Xã Đức Long",
        "path": "Đức Long, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Long, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18283",
        "parent_code": "440"
    },
    "18286": {
        "name": "Đức Lâm",
        "type": "xa",
        "slug": "duc-lam",
        "name_with_type": "Xã Đức Lâm",
        "path": "Đức Lâm, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Lâm, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18286",
        "parent_code": "440"
    },
    "18289": {
        "name": "Đức Thanh",
        "type": "xa",
        "slug": "duc-thanh",
        "name_with_type": "Xã Đức Thanh",
        "path": "Đức Thanh, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Thanh, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18289",
        "parent_code": "440"
    },
    "18292": {
        "name": "Đức Dũng",
        "type": "xa",
        "slug": "duc-dung",
        "name_with_type": "Xã Đức Dũng",
        "path": "Đức Dũng, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Dũng, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18292",
        "parent_code": "440"
    },
    "18295": {
        "name": "Đức Lập",
        "type": "xa",
        "slug": "duc-lap",
        "name_with_type": "Xã Đức Lập",
        "path": "Đức Lập, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Lập, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18295",
        "parent_code": "440"
    },
    "18298": {
        "name": "Đức An",
        "type": "xa",
        "slug": "duc-an",
        "name_with_type": "Xã Đức An",
        "path": "Đức An, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức An, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18298",
        "parent_code": "440"
    },
    "18301": {
        "name": "Đức Lạc",
        "type": "xa",
        "slug": "duc-lac",
        "name_with_type": "Xã Đức Lạc",
        "path": "Đức Lạc, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Lạc, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18301",
        "parent_code": "440"
    },
    "18304": {
        "name": "Đức Đồng",
        "type": "xa",
        "slug": "duc-dong",
        "name_with_type": "Xã Đức Đồng",
        "path": "Đức Đồng, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Đồng, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18304",
        "parent_code": "440"
    },
    "18307": {
        "name": "Đức Lạng",
        "type": "xa",
        "slug": "duc-lang",
        "name_with_type": "Xã Đức Lạng",
        "path": "Đức Lạng, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Đức Lạng, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18307",
        "parent_code": "440"
    },
    "18310": {
        "name": "Tân Hương",
        "type": "xa",
        "slug": "tan-huong",
        "name_with_type": "Xã Tân Hương",
        "path": "Tân Hương, Đức Thọ, Hà Tĩnh",
        "path_with_type": "Xã Tân Hương, Huyện Đức Thọ, Tỉnh Hà Tĩnh",
        "code": "18310",
        "parent_code": "440"
    },
    "18313": {
        "name": "Vũ Quang",
        "type": "thi-tran",
        "slug": "vu-quang",
        "name_with_type": "Thị trấn Vũ Quang",
        "path": "Vũ Quang, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Thị trấn Vũ Quang, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18313",
        "parent_code": "441"
    },
    "18316": {
        "name": "Ân Phú",
        "type": "xa",
        "slug": "an-phu",
        "name_with_type": "Xã Ân Phú",
        "path": "Ân Phú, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Ân Phú, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18316",
        "parent_code": "441"
    },
    "18319": {
        "name": "Đức Giang",
        "type": "xa",
        "slug": "duc-giang",
        "name_with_type": "Xã Đức Giang",
        "path": "Đức Giang, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Đức Giang, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18319",
        "parent_code": "441"
    },
    "18322": {
        "name": "Đức Lĩnh",
        "type": "xa",
        "slug": "duc-linh",
        "name_with_type": "Xã Đức Lĩnh",
        "path": "Đức Lĩnh, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Đức Lĩnh, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18322",
        "parent_code": "441"
    },
    "18325": {
        "name": "Sơn Thọ",
        "type": "xa",
        "slug": "son-tho",
        "name_with_type": "Xã Sơn Thọ",
        "path": "Sơn Thọ, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Sơn Thọ, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18325",
        "parent_code": "441"
    },
    "18328": {
        "name": "Đức Hương",
        "type": "xa",
        "slug": "duc-huong",
        "name_with_type": "Xã Đức Hương",
        "path": "Đức Hương, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Đức Hương, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18328",
        "parent_code": "441"
    },
    "18331": {
        "name": "Đức Bồng",
        "type": "xa",
        "slug": "duc-bong",
        "name_with_type": "Xã Đức Bồng",
        "path": "Đức Bồng, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Đức Bồng, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18331",
        "parent_code": "441"
    },
    "18334": {
        "name": "Đức Liên",
        "type": "xa",
        "slug": "duc-lien",
        "name_with_type": "Xã Đức Liên",
        "path": "Đức Liên, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Đức Liên, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18334",
        "parent_code": "441"
    },
    "18337": {
        "name": "Hương Điền",
        "type": "xa",
        "slug": "huong-dien",
        "name_with_type": "Xã Hương Điền",
        "path": "Hương Điền, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Hương Điền, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18337",
        "parent_code": "441"
    },
    "18340": {
        "name": "Hương Minh",
        "type": "xa",
        "slug": "huong-minh",
        "name_with_type": "Xã Hương Minh",
        "path": "Hương Minh, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Hương Minh, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18340",
        "parent_code": "441"
    },
    "18343": {
        "name": "Hương Thọ",
        "type": "xa",
        "slug": "huong-tho",
        "name_with_type": "Xã Hương Thọ",
        "path": "Hương Thọ, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Hương Thọ, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18343",
        "parent_code": "441"
    },
    "18346": {
        "name": "Hương Quang",
        "type": "xa",
        "slug": "huong-quang",
        "name_with_type": "Xã Hương Quang",
        "path": "Hương Quang, Vũ Quang, Hà Tĩnh",
        "path_with_type": "Xã Hương Quang, Huyện Vũ Quang, Tỉnh Hà Tĩnh",
        "code": "18346",
        "parent_code": "441"
    },
    "18349": {
        "name": "Nghi Xuân",
        "type": "thi-tran",
        "slug": "nghi-xuan",
        "name_with_type": "Thị trấn Nghi Xuân",
        "path": "Nghi Xuân, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Thị trấn Nghi Xuân, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18349",
        "parent_code": "442"
    },
    "18352": {
        "name": "Xuân An",
        "type": "thi-tran",
        "slug": "xuan-an",
        "name_with_type": "Thị trấn Xuân An",
        "path": "Xuân An, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Thị trấn Xuân An, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18352",
        "parent_code": "442"
    },
    "18355": {
        "name": "Xuân Hội",
        "type": "xa",
        "slug": "xuan-hoi",
        "name_with_type": "Xã Xuân Hội",
        "path": "Xuân Hội, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Hội, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18355",
        "parent_code": "442"
    },
    "18358": {
        "name": "Xuân Trường",
        "type": "xa",
        "slug": "xuan-truong",
        "name_with_type": "Xã Xuân Trường",
        "path": "Xuân Trường, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Trường, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18358",
        "parent_code": "442"
    },
    "18361": {
        "name": "Xuân Đan",
        "type": "xa",
        "slug": "xuan-dan",
        "name_with_type": "Xã Xuân Đan",
        "path": "Xuân Đan, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Đan, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18361",
        "parent_code": "442"
    },
    "18364": {
        "name": "Xuân Phổ",
        "type": "xa",
        "slug": "xuan-pho",
        "name_with_type": "Xã Xuân Phổ",
        "path": "Xuân Phổ, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Phổ, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18364",
        "parent_code": "442"
    },
    "18367": {
        "name": "Xuân Hải",
        "type": "xa",
        "slug": "xuan-hai",
        "name_with_type": "Xã Xuân Hải",
        "path": "Xuân Hải, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Hải, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18367",
        "parent_code": "442"
    },
    "18370": {
        "name": "Xuân Giang",
        "type": "xa",
        "slug": "xuan-giang",
        "name_with_type": "Xã Xuân Giang",
        "path": "Xuân Giang, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Giang, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18370",
        "parent_code": "442"
    },
    "18373": {
        "name": "Tiên Điền",
        "type": "xa",
        "slug": "tien-dien",
        "name_with_type": "Xã Tiên Điền",
        "path": "Tiên Điền, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Tiên Điền, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18373",
        "parent_code": "442"
    },
    "18376": {
        "name": "Xuân Yên",
        "type": "xa",
        "slug": "xuan-yen",
        "name_with_type": "Xã Xuân Yên",
        "path": "Xuân Yên, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Yên, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18376",
        "parent_code": "442"
    },
    "18379": {
        "name": "Xuân Mỹ",
        "type": "xa",
        "slug": "xuan-my",
        "name_with_type": "Xã Xuân Mỹ",
        "path": "Xuân Mỹ, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Mỹ, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18379",
        "parent_code": "442"
    },
    "18382": {
        "name": "Xuân Thành",
        "type": "xa",
        "slug": "xuan-thanh",
        "name_with_type": "Xã Xuân Thành",
        "path": "Xuân Thành, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Thành, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18382",
        "parent_code": "442"
    },
    "18385": {
        "name": "Xuân Viên",
        "type": "xa",
        "slug": "xuan-vien",
        "name_with_type": "Xã Xuân Viên",
        "path": "Xuân Viên, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Viên, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18385",
        "parent_code": "442"
    },
    "18388": {
        "name": "Xuân Hồng",
        "type": "xa",
        "slug": "xuan-hong",
        "name_with_type": "Xã Xuân Hồng",
        "path": "Xuân Hồng, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Hồng, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18388",
        "parent_code": "442"
    },
    "18391": {
        "name": "Cỗ Đạm",
        "type": "xa",
        "slug": "co-dam",
        "name_with_type": "Xã Cỗ Đạm",
        "path": "Cỗ Đạm, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Cỗ Đạm, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18391",
        "parent_code": "442"
    },
    "18394": {
        "name": "Xuân Liên",
        "type": "xa",
        "slug": "xuan-lien",
        "name_with_type": "Xã Xuân Liên",
        "path": "Xuân Liên, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Liên, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18394",
        "parent_code": "442"
    },
    "18397": {
        "name": "Xuân Lĩnh",
        "type": "xa",
        "slug": "xuan-linh",
        "name_with_type": "Xã Xuân Lĩnh",
        "path": "Xuân Lĩnh, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Lĩnh, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18397",
        "parent_code": "442"
    },
    "18400": {
        "name": "Xuân Lam",
        "type": "xa",
        "slug": "xuan-lam",
        "name_with_type": "Xã Xuân Lam",
        "path": "Xuân Lam, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Xuân Lam, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18400",
        "parent_code": "442"
    },
    "18403": {
        "name": "Cương Gián",
        "type": "xa",
        "slug": "cuong-gian",
        "name_with_type": "Xã Cương Gián",
        "path": "Cương Gián, Nghi Xuân, Hà Tĩnh",
        "path_with_type": "Xã Cương Gián, Huyện Nghi Xuân, Tỉnh Hà Tĩnh",
        "code": "18403",
        "parent_code": "442"
    },
    "18406": {
        "name": "Nghèn",
        "type": "thi-tran",
        "slug": "nghen",
        "name_with_type": "Thị trấn Nghèn",
        "path": "Nghèn, Can Lộc, Hà Tĩnh",
        "path_with_type": "Thị trấn Nghèn, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18406",
        "parent_code": "443"
    },
    "18415": {
        "name": "Thiên Lộc",
        "type": "xa",
        "slug": "thien-loc",
        "name_with_type": "Xã Thiên Lộc",
        "path": "Thiên Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Thiên Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18415",
        "parent_code": "443"
    },
    "18418": {
        "name": "Thuần Thiện",
        "type": "xa",
        "slug": "thuan-thien",
        "name_with_type": "Xã Thuần Thiện",
        "path": "Thuần Thiện, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Thuần Thiện, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18418",
        "parent_code": "443"
    },
    "18424": {
        "name": "Kim Lộc",
        "type": "xa",
        "slug": "kim-loc",
        "name_with_type": "Xã Kim Lộc",
        "path": "Kim Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Kim Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18424",
        "parent_code": "443"
    },
    "18427": {
        "name": "Vượng Lộc",
        "type": "xa",
        "slug": "vuong-loc",
        "name_with_type": "Xã Vượng Lộc",
        "path": "Vượng Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Vượng Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18427",
        "parent_code": "443"
    },
    "18433": {
        "name": "Thanh Lộc",
        "type": "xa",
        "slug": "thanh-loc",
        "name_with_type": "Xã Thanh Lộc",
        "path": "Thanh Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Thanh Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18433",
        "parent_code": "443"
    },
    "18436": {
        "name": "Song Lộc",
        "type": "xa",
        "slug": "song-loc",
        "name_with_type": "Xã Song Lộc",
        "path": "Song Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Song Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18436",
        "parent_code": "443"
    },
    "18439": {
        "name": "Thường Nga",
        "type": "xa",
        "slug": "thuong-nga",
        "name_with_type": "Xã Thường Nga",
        "path": "Thường Nga, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Thường Nga, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18439",
        "parent_code": "443"
    },
    "18442": {
        "name": "Trường Lộc",
        "type": "xa",
        "slug": "truong-loc",
        "name_with_type": "Xã Trường Lộc",
        "path": "Trường Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Trường Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18442",
        "parent_code": "443"
    },
    "18445": {
        "name": "Tùng Lộc",
        "type": "xa",
        "slug": "tung-loc",
        "name_with_type": "Xã Tùng Lộc",
        "path": "Tùng Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Tùng Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18445",
        "parent_code": "443"
    },
    "18451": {
        "name": "Yên Lộc",
        "type": "xa",
        "slug": "yen-loc",
        "name_with_type": "Xã Yên Lộc",
        "path": "Yên Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Yên Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18451",
        "parent_code": "443"
    },
    "18454": {
        "name": "Phú Lộc",
        "type": "xa",
        "slug": "phu-loc",
        "name_with_type": "Xã Phú Lộc",
        "path": "Phú Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Phú Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18454",
        "parent_code": "443"
    },
    "18460": {
        "name": "Khánh Lộc",
        "type": "xa",
        "slug": "khanh-loc",
        "name_with_type": "Xã Khánh Lộc",
        "path": "Khánh Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Khánh Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18460",
        "parent_code": "443"
    },
    "18463": {
        "name": "Gia Hanh",
        "type": "xa",
        "slug": "gia-hanh",
        "name_with_type": "Xã Gia Hanh",
        "path": "Gia Hanh, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Gia Hanh, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18463",
        "parent_code": "443"
    },
    "18466": {
        "name": "Vĩnh Lộc",
        "type": "xa",
        "slug": "vinh-loc",
        "name_with_type": "Xã Vĩnh Lộc",
        "path": "Vĩnh Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Vĩnh Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18466",
        "parent_code": "443"
    },
    "18469": {
        "name": "Tiến Lộc",
        "type": "xa",
        "slug": "tien-loc",
        "name_with_type": "Xã Tiến Lộc",
        "path": "Tiến Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Tiến Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18469",
        "parent_code": "443"
    },
    "18472": {
        "name": "Trung Lộc",
        "type": "xa",
        "slug": "trung-loc",
        "name_with_type": "Xã Trung Lộc",
        "path": "Trung Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Trung Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18472",
        "parent_code": "443"
    },
    "18475": {
        "name": "Xuân Lộc",
        "type": "xa",
        "slug": "xuan-loc",
        "name_with_type": "Xã Xuân Lộc",
        "path": "Xuân Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Xuân Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18475",
        "parent_code": "443"
    },
    "18478": {
        "name": "Thượng Lộc",
        "type": "xa",
        "slug": "thuong-loc",
        "name_with_type": "Xã Thượng Lộc",
        "path": "Thượng Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Thượng Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18478",
        "parent_code": "443"
    },
    "18481": {
        "name": "Quang Lộc",
        "type": "xa",
        "slug": "quang-loc",
        "name_with_type": "Xã Quang Lộc",
        "path": "Quang Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Quang Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18481",
        "parent_code": "443"
    },
    "18484": {
        "name": "Đồng Lộc",
        "type": "xa",
        "slug": "dong-loc",
        "name_with_type": "Xã Đồng Lộc",
        "path": "Đồng Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Đồng Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18484",
        "parent_code": "443"
    },
    "18487": {
        "name": "Mỹ Lộc",
        "type": "xa",
        "slug": "my-loc",
        "name_with_type": "Xã Mỹ Lộc",
        "path": "Mỹ Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Mỹ Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18487",
        "parent_code": "443"
    },
    "18490": {
        "name": "Sơn Lộc",
        "type": "xa",
        "slug": "son-loc",
        "name_with_type": "Xã Sơn Lộc",
        "path": "Sơn Lộc, Can Lộc, Hà Tĩnh",
        "path_with_type": "Xã Sơn Lộc, Huyện Can Lộc, Tỉnh Hà Tĩnh",
        "code": "18490",
        "parent_code": "443"
    },
    "18496": {
        "name": "Hương Khê",
        "type": "thi-tran",
        "slug": "huong-khe",
        "name_with_type": "Thị trấn Hương Khê",
        "path": "Hương Khê, Hương Khê, Hà Tĩnh",
        "path_with_type": "Thị trấn Hương Khê, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18496",
        "parent_code": "444"
    },
    "18499": {
        "name": "Phương Mỹ",
        "type": "xa",
        "slug": "phuong-my",
        "name_with_type": "Xã Phương Mỹ",
        "path": "Phương Mỹ, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Phương Mỹ, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18499",
        "parent_code": "444"
    },
    "18502": {
        "name": "Hà Linh",
        "type": "xa",
        "slug": "ha-linh",
        "name_with_type": "Xã Hà Linh",
        "path": "Hà Linh, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hà Linh, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18502",
        "parent_code": "444"
    },
    "18505": {
        "name": "Hương Thủy",
        "type": "xa",
        "slug": "huong-thuy",
        "name_with_type": "Xã Hương Thủy",
        "path": "Hương Thủy, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Thủy, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18505",
        "parent_code": "444"
    },
    "18508": {
        "name": "Hòa Hải",
        "type": "xa",
        "slug": "hoa-hai",
        "name_with_type": "Xã Hòa Hải",
        "path": "Hòa Hải, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hòa Hải, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18508",
        "parent_code": "444"
    },
    "18511": {
        "name": "Phương Điền",
        "type": "xa",
        "slug": "phuong-dien",
        "name_with_type": "Xã Phương Điền",
        "path": "Phương Điền, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Phương Điền, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18511",
        "parent_code": "444"
    },
    "18514": {
        "name": "Phúc Đồng",
        "type": "xa",
        "slug": "phuc-dong",
        "name_with_type": "Xã Phúc Đồng",
        "path": "Phúc Đồng, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Phúc Đồng, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18514",
        "parent_code": "444"
    },
    "18517": {
        "name": "Hương Giang",
        "type": "xa",
        "slug": "huong-giang",
        "name_with_type": "Xã Hương Giang",
        "path": "Hương Giang, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Giang, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18517",
        "parent_code": "444"
    },
    "18520": {
        "name": "Lộc Yên",
        "type": "xa",
        "slug": "loc-yen",
        "name_with_type": "Xã Lộc Yên",
        "path": "Lộc Yên, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Lộc Yên, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18520",
        "parent_code": "444"
    },
    "18523": {
        "name": "Hương Bình",
        "type": "xa",
        "slug": "huong-binh",
        "name_with_type": "Xã Hương Bình",
        "path": "Hương Bình, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Bình, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18523",
        "parent_code": "444"
    },
    "18526": {
        "name": "Hương Long",
        "type": "xa",
        "slug": "huong-long",
        "name_with_type": "Xã Hương Long",
        "path": "Hương Long, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Long, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18526",
        "parent_code": "444"
    },
    "18529": {
        "name": "Phú Gia",
        "type": "xa",
        "slug": "phu-gia",
        "name_with_type": "Xã Phú Gia",
        "path": "Phú Gia, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Phú Gia, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18529",
        "parent_code": "444"
    },
    "18532": {
        "name": "Gia Phố",
        "type": "xa",
        "slug": "gia-pho",
        "name_with_type": "Xã Gia Phố",
        "path": "Gia Phố, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Gia Phố, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18532",
        "parent_code": "444"
    },
    "18535": {
        "name": "Phú Phong",
        "type": "xa",
        "slug": "phu-phong",
        "name_with_type": "Xã Phú Phong",
        "path": "Phú Phong, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Phú Phong, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18535",
        "parent_code": "444"
    },
    "18538": {
        "name": "Hương Đô",
        "type": "xa",
        "slug": "huong-do",
        "name_with_type": "Xã Hương Đô",
        "path": "Hương Đô, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Đô, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18538",
        "parent_code": "444"
    },
    "18541": {
        "name": "Hương Vĩnh",
        "type": "xa",
        "slug": "huong-vinh",
        "name_with_type": "Xã Hương Vĩnh",
        "path": "Hương Vĩnh, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Vĩnh, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18541",
        "parent_code": "444"
    },
    "18544": {
        "name": "Hương Xuân",
        "type": "xa",
        "slug": "huong-xuan",
        "name_with_type": "Xã Hương Xuân",
        "path": "Hương Xuân, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Xuân, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18544",
        "parent_code": "444"
    },
    "18547": {
        "name": "Phúc Trạch",
        "type": "xa",
        "slug": "phuc-trach",
        "name_with_type": "Xã Phúc Trạch",
        "path": "Phúc Trạch, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Phúc Trạch, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18547",
        "parent_code": "444"
    },
    "18550": {
        "name": "Hương Trà",
        "type": "xa",
        "slug": "huong-tra",
        "name_with_type": "Xã Hương Trà",
        "path": "Hương Trà, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Trà, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18550",
        "parent_code": "444"
    },
    "18553": {
        "name": "Hương Trạch",
        "type": "xa",
        "slug": "huong-trach",
        "name_with_type": "Xã Hương Trạch",
        "path": "Hương Trạch, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Trạch, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18553",
        "parent_code": "444"
    },
    "18556": {
        "name": "Hương Lâm",
        "type": "xa",
        "slug": "huong-lam",
        "name_with_type": "Xã Hương Lâm",
        "path": "Hương Lâm, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Lâm, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18556",
        "parent_code": "444"
    },
    "18559": {
        "name": "Hương Liên",
        "type": "xa",
        "slug": "huong-lien",
        "name_with_type": "Xã Hương Liên",
        "path": "Hương Liên, Hương Khê, Hà Tĩnh",
        "path_with_type": "Xã Hương Liên, Huyện Hương Khê, Tỉnh Hà Tĩnh",
        "code": "18559",
        "parent_code": "444"
    },
    "18562": {
        "name": "Thạch Hà",
        "type": "thi-tran",
        "slug": "thach-ha",
        "name_with_type": "Thị trấn Thạch Hà",
        "path": "Thạch Hà, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Thị trấn Thạch Hà, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18562",
        "parent_code": "445"
    },
    "18565": {
        "name": "Ngọc Sơn",
        "type": "xa",
        "slug": "ngoc-son",
        "name_with_type": "Xã Ngọc Sơn",
        "path": "Ngọc Sơn, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Ngọc Sơn, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18565",
        "parent_code": "445"
    },
    "18571": {
        "name": "Thạch Hải",
        "type": "xa",
        "slug": "thach-hai",
        "name_with_type": "Xã Thạch Hải",
        "path": "Thạch Hải, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Hải, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18571",
        "parent_code": "445"
    },
    "18574": {
        "name": "Thạch Bàn",
        "type": "xa",
        "slug": "thach-ban",
        "name_with_type": "Xã Thạch Bàn",
        "path": "Thạch Bàn, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Bàn, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18574",
        "parent_code": "445"
    },
    "18586": {
        "name": "Thạch Kênh",
        "type": "xa",
        "slug": "thach-kenh",
        "name_with_type": "Xã Thạch Kênh",
        "path": "Thạch Kênh, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Kênh, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18586",
        "parent_code": "445"
    },
    "18589": {
        "name": "Thạch Sơn",
        "type": "xa",
        "slug": "thach-son",
        "name_with_type": "Xã Thạch Sơn",
        "path": "Thạch Sơn, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Sơn, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18589",
        "parent_code": "445"
    },
    "18592": {
        "name": "Thạch Liên",
        "type": "xa",
        "slug": "thach-lien",
        "name_with_type": "Xã Thạch Liên",
        "path": "Thạch Liên, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Liên, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18592",
        "parent_code": "445"
    },
    "18595": {
        "name": "Thạch Đỉnh",
        "type": "xa",
        "slug": "thach-dinh",
        "name_with_type": "Xã Thạch Đỉnh",
        "path": "Thạch Đỉnh, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Đỉnh, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18595",
        "parent_code": "445"
    },
    "18601": {
        "name": "Phù Việt",
        "type": "xa",
        "slug": "phu-viet",
        "name_with_type": "Xã Phù Việt",
        "path": "Phù Việt, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Phù Việt, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18601",
        "parent_code": "445"
    },
    "18604": {
        "name": "Thạch Khê",
        "type": "xa",
        "slug": "thach-khe",
        "name_with_type": "Xã Thạch Khê",
        "path": "Thạch Khê, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Khê, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18604",
        "parent_code": "445"
    },
    "18607": {
        "name": "Thạch Long",
        "type": "xa",
        "slug": "thach-long",
        "name_with_type": "Xã Thạch Long",
        "path": "Thạch Long, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Long, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18607",
        "parent_code": "445"
    },
    "18610": {
        "name": "Việt Xuyên",
        "type": "xa",
        "slug": "viet-xuyen",
        "name_with_type": "Xã Việt Xuyên",
        "path": "Việt Xuyên, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Việt Xuyên, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18610",
        "parent_code": "445"
    },
    "18613": {
        "name": "Thạch Tiến",
        "type": "xa",
        "slug": "thach-tien",
        "name_with_type": "Xã Thạch Tiến",
        "path": "Thạch Tiến, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Tiến, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18613",
        "parent_code": "445"
    },
    "18616": {
        "name": "Thạch Thanh",
        "type": "xa",
        "slug": "thach-thanh",
        "name_with_type": "Xã Thạch Thanh",
        "path": "Thạch Thanh, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Thanh, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18616",
        "parent_code": "445"
    },
    "18619": {
        "name": "Thạch Trị",
        "type": "xa",
        "slug": "thach-tri",
        "name_with_type": "Xã Thạch Trị",
        "path": "Thạch Trị, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Trị, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18619",
        "parent_code": "445"
    },
    "18622": {
        "name": "Thạch Lạc",
        "type": "xa",
        "slug": "thach-lac",
        "name_with_type": "Xã Thạch Lạc",
        "path": "Thạch Lạc, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Lạc, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18622",
        "parent_code": "445"
    },
    "18625": {
        "name": "Thạch Ngọc",
        "type": "xa",
        "slug": "thach-ngoc",
        "name_with_type": "Xã Thạch Ngọc",
        "path": "Thạch Ngọc, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Ngọc, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18625",
        "parent_code": "445"
    },
    "18628": {
        "name": "Tượng Sơn",
        "type": "xa",
        "slug": "tuong-son",
        "name_with_type": "Xã Tượng Sơn",
        "path": "Tượng Sơn, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Tượng Sơn, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18628",
        "parent_code": "445"
    },
    "18631": {
        "name": "Thạch Văn",
        "type": "xa",
        "slug": "thach-van",
        "name_with_type": "Xã Thạch Văn",
        "path": "Thạch Văn, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Văn, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18631",
        "parent_code": "445"
    },
    "18634": {
        "name": "Thạch Vĩnh",
        "type": "xa",
        "slug": "thach-vinh",
        "name_with_type": "Xã Thạch Vĩnh",
        "path": "Thạch Vĩnh, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Vĩnh, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18634",
        "parent_code": "445"
    },
    "18637": {
        "name": "Thạch Thắng",
        "type": "xa",
        "slug": "thach-thang",
        "name_with_type": "Xã Thạch Thắng",
        "path": "Thạch Thắng, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Thắng, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18637",
        "parent_code": "445"
    },
    "18640": {
        "name": "Thạch Lưu",
        "type": "xa",
        "slug": "thach-luu",
        "name_with_type": "Xã Thạch Lưu",
        "path": "Thạch Lưu, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Lưu, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18640",
        "parent_code": "445"
    },
    "18643": {
        "name": "Thạch Đài",
        "type": "xa",
        "slug": "thach-dai",
        "name_with_type": "Xã Thạch Đài",
        "path": "Thạch Đài, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Đài, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18643",
        "parent_code": "445"
    },
    "18646": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Bắc Sơn, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18646",
        "parent_code": "445"
    },
    "18649": {
        "name": "Thạch Hội",
        "type": "xa",
        "slug": "thach-hoi",
        "name_with_type": "Xã Thạch Hội",
        "path": "Thạch Hội, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Hội, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18649",
        "parent_code": "445"
    },
    "18652": {
        "name": "Thạch Tân",
        "type": "xa",
        "slug": "thach-tan",
        "name_with_type": "Xã Thạch Tân",
        "path": "Thạch Tân, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Tân, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18652",
        "parent_code": "445"
    },
    "18655": {
        "name": "Thạch Lâm",
        "type": "xa",
        "slug": "thach-lam",
        "name_with_type": "Xã Thạch Lâm",
        "path": "Thạch Lâm, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Lâm, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18655",
        "parent_code": "445"
    },
    "18658": {
        "name": "Thạch Xuân",
        "type": "xa",
        "slug": "thach-xuan",
        "name_with_type": "Xã Thạch Xuân",
        "path": "Thạch Xuân, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Xuân, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18658",
        "parent_code": "445"
    },
    "18661": {
        "name": "Thạch Hương",
        "type": "xa",
        "slug": "thach-huong",
        "name_with_type": "Xã Thạch Hương",
        "path": "Thạch Hương, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Hương, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18661",
        "parent_code": "445"
    },
    "18664": {
        "name": "Nam Hương",
        "type": "xa",
        "slug": "nam-huong",
        "name_with_type": "Xã Nam Hương",
        "path": "Nam Hương, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Nam Hương, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18664",
        "parent_code": "445"
    },
    "18667": {
        "name": "Thạch Điền",
        "type": "xa",
        "slug": "thach-dien",
        "name_with_type": "Xã Thạch Điền",
        "path": "Thạch Điền, Thạch Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Điền, Huyện Thạch Hà, Tỉnh Hà Tĩnh",
        "code": "18667",
        "parent_code": "445"
    },
    "18673": {
        "name": "Cẩm Xuyên",
        "type": "thi-tran",
        "slug": "cam-xuyen",
        "name_with_type": "Thị trấn Cẩm Xuyên",
        "path": "Cẩm Xuyên, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Thị trấn Cẩm Xuyên, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18673",
        "parent_code": "446"
    },
    "18676": {
        "name": "Thiên Cầm",
        "type": "thi-tran",
        "slug": "thien-cam",
        "name_with_type": "Thị trấn Thiên Cầm",
        "path": "Thiên Cầm, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Thị trấn Thiên Cầm, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18676",
        "parent_code": "446"
    },
    "18679": {
        "name": "Cẩm Hòa",
        "type": "xa",
        "slug": "cam-hoa",
        "name_with_type": "Xã Cẩm Hòa",
        "path": "Cẩm Hòa, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Hòa, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18679",
        "parent_code": "446"
    },
    "18682": {
        "name": "Cẩm Dương",
        "type": "xa",
        "slug": "cam-duong",
        "name_with_type": "Xã Cẩm Dương",
        "path": "Cẩm Dương, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Dương, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18682",
        "parent_code": "446"
    },
    "18685": {
        "name": "Cẩm Bình",
        "type": "xa",
        "slug": "cam-binh",
        "name_with_type": "Xã Cẩm Bình",
        "path": "Cẩm Bình, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Bình, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18685",
        "parent_code": "446"
    },
    "18688": {
        "name": "Cẩm Yên",
        "type": "xa",
        "slug": "cam-yen",
        "name_with_type": "Xã Cẩm Yên",
        "path": "Cẩm Yên, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Yên, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18688",
        "parent_code": "446"
    },
    "18691": {
        "name": "Cẩm Vĩnh",
        "type": "xa",
        "slug": "cam-vinh",
        "name_with_type": "Xã Cẩm Vĩnh",
        "path": "Cẩm Vĩnh, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Vĩnh, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18691",
        "parent_code": "446"
    },
    "18694": {
        "name": "Cẩm Thành",
        "type": "xa",
        "slug": "cam-thanh",
        "name_with_type": "Xã Cẩm Thành",
        "path": "Cẩm Thành, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Thành, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18694",
        "parent_code": "446"
    },
    "18697": {
        "name": "Cẩm Quang",
        "type": "xa",
        "slug": "cam-quang",
        "name_with_type": "Xã Cẩm Quang",
        "path": "Cẩm Quang, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Quang, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18697",
        "parent_code": "446"
    },
    "18700": {
        "name": "Cẩm Nam",
        "type": "xa",
        "slug": "cam-nam",
        "name_with_type": "Xã Cẩm Nam",
        "path": "Cẩm Nam, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Nam, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18700",
        "parent_code": "446"
    },
    "18703": {
        "name": "Cẩm Huy",
        "type": "xa",
        "slug": "cam-huy",
        "name_with_type": "Xã Cẩm Huy",
        "path": "Cẩm Huy, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Huy, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18703",
        "parent_code": "446"
    },
    "18706": {
        "name": "Cẩm Thạch",
        "type": "xa",
        "slug": "cam-thach",
        "name_with_type": "Xã Cẩm Thạch",
        "path": "Cẩm Thạch, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Thạch, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18706",
        "parent_code": "446"
    },
    "18709": {
        "name": "Cẩm Nhượng",
        "type": "xa",
        "slug": "cam-nhuong",
        "name_with_type": "Xã Cẩm Nhượng",
        "path": "Cẩm Nhượng, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Nhượng, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18709",
        "parent_code": "446"
    },
    "18712": {
        "name": "Cẩm Thăng",
        "type": "xa",
        "slug": "cam-thang",
        "name_with_type": "Xã Cẩm Thăng",
        "path": "Cẩm Thăng, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Thăng, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18712",
        "parent_code": "446"
    },
    "18715": {
        "name": "Cẩm Duệ",
        "type": "xa",
        "slug": "cam-due",
        "name_with_type": "Xã Cẩm Duệ",
        "path": "Cẩm Duệ, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Duệ, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18715",
        "parent_code": "446"
    },
    "18718": {
        "name": "Cẩm Phúc",
        "type": "xa",
        "slug": "cam-phuc",
        "name_with_type": "Xã Cẩm Phúc",
        "path": "Cẩm Phúc, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Phúc, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18718",
        "parent_code": "446"
    },
    "18721": {
        "name": "Cẩm Lĩnh",
        "type": "xa",
        "slug": "cam-linh",
        "name_with_type": "Xã Cẩm Lĩnh",
        "path": "Cẩm Lĩnh, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Lĩnh, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18721",
        "parent_code": "446"
    },
    "18724": {
        "name": "Cẩm Quan",
        "type": "xa",
        "slug": "cam-quan",
        "name_with_type": "Xã Cẩm Quan",
        "path": "Cẩm Quan, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Quan, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18724",
        "parent_code": "446"
    },
    "18727": {
        "name": "Cẩm Hà",
        "type": "xa",
        "slug": "cam-ha",
        "name_with_type": "Xã Cẩm Hà",
        "path": "Cẩm Hà, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Hà, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18727",
        "parent_code": "446"
    },
    "18730": {
        "name": "Cẩm Lộc",
        "type": "xa",
        "slug": "cam-loc",
        "name_with_type": "Xã Cẩm Lộc",
        "path": "Cẩm Lộc, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Lộc, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18730",
        "parent_code": "446"
    },
    "18733": {
        "name": "Cẩm Hưng",
        "type": "xa",
        "slug": "cam-hung",
        "name_with_type": "Xã Cẩm Hưng",
        "path": "Cẩm Hưng, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Hưng, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18733",
        "parent_code": "446"
    },
    "18736": {
        "name": "Cẩm Thịnh",
        "type": "xa",
        "slug": "cam-thinh",
        "name_with_type": "Xã Cẩm Thịnh",
        "path": "Cẩm Thịnh, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Thịnh, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18736",
        "parent_code": "446"
    },
    "18739": {
        "name": "Cẩm Mỹ",
        "type": "xa",
        "slug": "cam-my",
        "name_with_type": "Xã Cẩm Mỹ",
        "path": "Cẩm Mỹ, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Mỹ, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18739",
        "parent_code": "446"
    },
    "18742": {
        "name": "Cẩm Trung",
        "type": "xa",
        "slug": "cam-trung",
        "name_with_type": "Xã Cẩm Trung",
        "path": "Cẩm Trung, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Trung, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18742",
        "parent_code": "446"
    },
    "18745": {
        "name": "Cẩm Sơn",
        "type": "xa",
        "slug": "cam-son",
        "name_with_type": "Xã Cẩm Sơn",
        "path": "Cẩm Sơn, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Sơn, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18745",
        "parent_code": "446"
    },
    "18748": {
        "name": "Cẩm Lạc",
        "type": "xa",
        "slug": "cam-lac",
        "name_with_type": "Xã Cẩm Lạc",
        "path": "Cẩm Lạc, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Lạc, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18748",
        "parent_code": "446"
    },
    "18751": {
        "name": "Cẩm Minh",
        "type": "xa",
        "slug": "cam-minh",
        "name_with_type": "Xã Cẩm Minh",
        "path": "Cẩm Minh, Cẩm Xuyên, Hà Tĩnh",
        "path_with_type": "Xã Cẩm Minh, Huyện Cẩm Xuyên, Tỉnh Hà Tĩnh",
        "code": "18751",
        "parent_code": "446"
    },
    "18757": {
        "name": "Kỳ Xuân",
        "type": "xa",
        "slug": "ky-xuan",
        "name_with_type": "Xã Kỳ Xuân",
        "path": "Kỳ Xuân, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Xuân, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18757",
        "parent_code": "447"
    },
    "18760": {
        "name": "Kỳ Bắc",
        "type": "xa",
        "slug": "ky-bac",
        "name_with_type": "Xã Kỳ Bắc",
        "path": "Kỳ Bắc, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Bắc, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18760",
        "parent_code": "447"
    },
    "18763": {
        "name": "Kỳ Phú",
        "type": "xa",
        "slug": "ky-phu",
        "name_with_type": "Xã Kỳ Phú",
        "path": "Kỳ Phú, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Phú, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18763",
        "parent_code": "447"
    },
    "18766": {
        "name": "Kỳ Phong",
        "type": "xa",
        "slug": "ky-phong",
        "name_with_type": "Xã Kỳ Phong",
        "path": "Kỳ Phong, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Phong, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18766",
        "parent_code": "447"
    },
    "18769": {
        "name": "Kỳ Tiến",
        "type": "xa",
        "slug": "ky-tien",
        "name_with_type": "Xã Kỳ Tiến",
        "path": "Kỳ Tiến, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Tiến, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18769",
        "parent_code": "447"
    },
    "18772": {
        "name": "Kỳ Giang",
        "type": "xa",
        "slug": "ky-giang",
        "name_with_type": "Xã Kỳ Giang",
        "path": "Kỳ Giang, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Giang, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18772",
        "parent_code": "447"
    },
    "18775": {
        "name": "Kỳ Đồng",
        "type": "xa",
        "slug": "ky-dong",
        "name_with_type": "Xã Kỳ Đồng",
        "path": "Kỳ Đồng, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Đồng, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18775",
        "parent_code": "447"
    },
    "18778": {
        "name": "Kỳ Khang",
        "type": "xa",
        "slug": "ky-khang",
        "name_with_type": "Xã Kỳ Khang",
        "path": "Kỳ Khang, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Khang, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18778",
        "parent_code": "447"
    },
    "18784": {
        "name": "Kỳ Văn",
        "type": "xa",
        "slug": "ky-van",
        "name_with_type": "Xã Kỳ Văn",
        "path": "Kỳ Văn, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Văn, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18784",
        "parent_code": "447"
    },
    "18787": {
        "name": "Kỳ Trung",
        "type": "xa",
        "slug": "ky-trung",
        "name_with_type": "Xã Kỳ Trung",
        "path": "Kỳ Trung, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Trung, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18787",
        "parent_code": "447"
    },
    "18790": {
        "name": "Kỳ Thọ",
        "type": "xa",
        "slug": "ky-tho",
        "name_with_type": "Xã Kỳ Thọ",
        "path": "Kỳ Thọ, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Thọ, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18790",
        "parent_code": "447"
    },
    "18793": {
        "name": "Kỳ Tây",
        "type": "xa",
        "slug": "ky-tay",
        "name_with_type": "Xã Kỳ Tây",
        "path": "Kỳ Tây, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Tây, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18793",
        "parent_code": "447"
    },
    "18799": {
        "name": "Kỳ Thượng",
        "type": "xa",
        "slug": "ky-thuong",
        "name_with_type": "Xã Kỳ Thượng",
        "path": "Kỳ Thượng, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Thượng, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18799",
        "parent_code": "447"
    },
    "18802": {
        "name": "Kỳ Hải",
        "type": "xa",
        "slug": "ky-hai",
        "name_with_type": "Xã Kỳ Hải",
        "path": "Kỳ Hải, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Hải, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18802",
        "parent_code": "447"
    },
    "18805": {
        "name": "Kỳ Thư",
        "type": "xa",
        "slug": "ky-thu",
        "name_with_type": "Xã Kỳ Thư",
        "path": "Kỳ Thư, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Thư, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18805",
        "parent_code": "447"
    },
    "18811": {
        "name": "Kỳ Châu",
        "type": "xa",
        "slug": "ky-chau",
        "name_with_type": "Xã Kỳ Châu",
        "path": "Kỳ Châu, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Châu, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18811",
        "parent_code": "447"
    },
    "18814": {
        "name": "Kỳ Tân",
        "type": "xa",
        "slug": "ky-tan",
        "name_with_type": "Xã Kỳ Tân",
        "path": "Kỳ Tân, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Tân, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18814",
        "parent_code": "447"
    },
    "18826": {
        "name": "Kỳ Hợp",
        "type": "xa",
        "slug": "ky-hop",
        "name_with_type": "Xã Kỳ Hợp",
        "path": "Kỳ Hợp, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Hợp, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18826",
        "parent_code": "447"
    },
    "18838": {
        "name": "Kỳ Lâm",
        "type": "xa",
        "slug": "ky-lam",
        "name_with_type": "Xã Kỳ Lâm",
        "path": "Kỳ Lâm, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Lâm, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18838",
        "parent_code": "447"
    },
    "18844": {
        "name": "Kỳ Sơn",
        "type": "xa",
        "slug": "ky-son",
        "name_with_type": "Xã Kỳ Sơn",
        "path": "Kỳ Sơn, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Sơn, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18844",
        "parent_code": "447"
    },
    "18850": {
        "name": "Kỳ Lạc",
        "type": "xa",
        "slug": "ky-lac",
        "name_with_type": "Xã Kỳ Lạc",
        "path": "Kỳ Lạc, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Lạc, Huyện Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18850",
        "parent_code": "447"
    },
    "18409": {
        "name": "Tân Lộc",
        "type": "xa",
        "slug": "tan-loc",
        "name_with_type": "Xã Tân Lộc",
        "path": "Tân Lộc, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Tân Lộc, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18409",
        "parent_code": "448"
    },
    "18412": {
        "name": "Hồng Lộc",
        "type": "xa",
        "slug": "hong-loc",
        "name_with_type": "Xã Hồng Lộc",
        "path": "Hồng Lộc, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Hồng Lộc, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18412",
        "parent_code": "448"
    },
    "18421": {
        "name": "Thịnh Lộc",
        "type": "xa",
        "slug": "thinh-loc",
        "name_with_type": "Xã Thịnh Lộc",
        "path": "Thịnh Lộc, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Thịnh Lộc, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18421",
        "parent_code": "448"
    },
    "18430": {
        "name": "An Lộc",
        "type": "xa",
        "slug": "an-loc",
        "name_with_type": "Xã An Lộc",
        "path": "An Lộc, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã An Lộc, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18430",
        "parent_code": "448"
    },
    "18448": {
        "name": "Bình Lộc",
        "type": "xa",
        "slug": "binh-loc",
        "name_with_type": "Xã Bình Lộc",
        "path": "Bình Lộc, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Bình Lộc, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18448",
        "parent_code": "448"
    },
    "18457": {
        "name": "Ích Hậu",
        "type": "xa",
        "slug": "ich-hau",
        "name_with_type": "Xã Ích Hậu",
        "path": "Ích Hậu, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Ích Hậu, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18457",
        "parent_code": "448"
    },
    "18493": {
        "name": "Phù Lưu",
        "type": "xa",
        "slug": "phu-luu",
        "name_with_type": "Xã Phù Lưu",
        "path": "Phù Lưu, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Phù Lưu, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18493",
        "parent_code": "448"
    },
    "18568": {
        "name": "Thạch Bằng",
        "type": "xa",
        "slug": "thach-bang",
        "name_with_type": "Xã Thạch Bằng",
        "path": "Thạch Bằng, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Bằng, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18568",
        "parent_code": "448"
    },
    "18577": {
        "name": "Thạch Mỹ",
        "type": "xa",
        "slug": "thach-my",
        "name_with_type": "Xã Thạch Mỹ",
        "path": "Thạch Mỹ, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Mỹ, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18577",
        "parent_code": "448"
    },
    "18580": {
        "name": "Thạch Kim",
        "type": "xa",
        "slug": "thach-kim",
        "name_with_type": "Xã Thạch Kim",
        "path": "Thạch Kim, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Kim, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18580",
        "parent_code": "448"
    },
    "18583": {
        "name": "Thạch Châu",
        "type": "xa",
        "slug": "thach-chau",
        "name_with_type": "Xã Thạch Châu",
        "path": "Thạch Châu, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Thạch Châu, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18583",
        "parent_code": "448"
    },
    "18598": {
        "name": "Hộ Độ",
        "type": "xa",
        "slug": "ho-do",
        "name_with_type": "Xã Hộ Độ",
        "path": "Hộ Độ, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Hộ Độ, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18598",
        "parent_code": "448"
    },
    "18670": {
        "name": "Mai Phụ",
        "type": "xa",
        "slug": "mai-phu",
        "name_with_type": "Xã Mai Phụ",
        "path": "Mai Phụ, Lộc Hà, Hà Tĩnh",
        "path_with_type": "Xã Mai Phụ, Huyện Lộc Hà, Tỉnh Hà Tĩnh",
        "code": "18670",
        "parent_code": "448"
    },
    "18754": {
        "name": "Sông Trí",
        "type": "phuong",
        "slug": "song-tri",
        "name_with_type": "Phường Sông Trí",
        "path": "Sông Trí, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Phường Sông Trí, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18754",
        "parent_code": "449"
    },
    "18781": {
        "name": "Kỳ Ninh",
        "type": "xa",
        "slug": "ky-ninh",
        "name_with_type": "Xã Kỳ Ninh",
        "path": "Kỳ Ninh, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Ninh, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18781",
        "parent_code": "449"
    },
    "18796": {
        "name": "Kỳ Lợi",
        "type": "xa",
        "slug": "ky-loi",
        "name_with_type": "Xã Kỳ Lợi",
        "path": "Kỳ Lợi, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Lợi, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18796",
        "parent_code": "449"
    },
    "18808": {
        "name": "Kỳ Hà",
        "type": "xa",
        "slug": "ky-ha",
        "name_with_type": "Xã Kỳ Hà",
        "path": "Kỳ Hà, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Hà, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18808",
        "parent_code": "449"
    },
    "18817": {
        "name": "Kỳ Hưng",
        "type": "xa",
        "slug": "ky-hung",
        "name_with_type": "Xã Kỳ Hưng",
        "path": "Kỳ Hưng, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Hưng, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18817",
        "parent_code": "449"
    },
    "18820": {
        "name": "Kỳ Trinh",
        "type": "phuong",
        "slug": "ky-trinh",
        "name_with_type": "Phường Kỳ Trinh",
        "path": "Kỳ Trinh, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Phường Kỳ Trinh, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18820",
        "parent_code": "449"
    },
    "18823": {
        "name": "Kỳ Thịnh",
        "type": "phuong",
        "slug": "ky-thinh",
        "name_with_type": "Phường Kỳ Thịnh",
        "path": "Kỳ Thịnh, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Phường Kỳ Thịnh, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18823",
        "parent_code": "449"
    },
    "18829": {
        "name": "Kỳ Hoa",
        "type": "xa",
        "slug": "ky-hoa",
        "name_with_type": "Xã Kỳ Hoa",
        "path": "Kỳ Hoa, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Hoa, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18829",
        "parent_code": "449"
    },
    "18832": {
        "name": "Kỳ Phương",
        "type": "phuong",
        "slug": "ky-phuong",
        "name_with_type": "Phường Kỳ Phương",
        "path": "Kỳ Phương, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Phường Kỳ Phương, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18832",
        "parent_code": "449"
    },
    "18835": {
        "name": "Kỳ Long",
        "type": "phuong",
        "slug": "ky-long",
        "name_with_type": "Phường Kỳ Long",
        "path": "Kỳ Long, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Phường Kỳ Long, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18835",
        "parent_code": "449"
    },
    "18841": {
        "name": "Kỳ Liên",
        "type": "phuong",
        "slug": "ky-lien",
        "name_with_type": "Phường Kỳ Liên",
        "path": "Kỳ Liên, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Phường Kỳ Liên, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18841",
        "parent_code": "449"
    },
    "18847": {
        "name": "Kỳ Nam",
        "type": "xa",
        "slug": "ky-nam",
        "name_with_type": "Xã Kỳ Nam",
        "path": "Kỳ Nam, Kỳ Anh, Hà Tĩnh",
        "path_with_type": "Xã Kỳ Nam, Thị xã Kỳ Anh, Tỉnh Hà Tĩnh",
        "code": "18847",
        "parent_code": "449"
    },
    "22738": {
        "name": "Đô Vinh",
        "type": "phuong",
        "slug": "do-vinh",
        "name_with_type": "Phường Đô Vinh",
        "path": "Đô Vinh, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Đô Vinh, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22738",
        "parent_code": "582"
    },
    "22741": {
        "name": "Phước Mỹ",
        "type": "phuong",
        "slug": "phuoc-my",
        "name_with_type": "Phường Phước Mỹ",
        "path": "Phước Mỹ, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Phước Mỹ, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22741",
        "parent_code": "582"
    },
    "22744": {
        "name": "Bảo An",
        "type": "phuong",
        "slug": "bao-an",
        "name_with_type": "Phường Bảo An",
        "path": "Bảo An, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Bảo An, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22744",
        "parent_code": "582"
    },
    "22747": {
        "name": "Phủ Hà",
        "type": "phuong",
        "slug": "phu-ha",
        "name_with_type": "Phường Phủ Hà",
        "path": "Phủ Hà, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Phủ Hà, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22747",
        "parent_code": "582"
    },
    "22750": {
        "name": "Thanh Sơn",
        "type": "phuong",
        "slug": "thanh-son",
        "name_with_type": "Phường Thanh Sơn",
        "path": "Thanh Sơn, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Thanh Sơn, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22750",
        "parent_code": "582"
    },
    "22753": {
        "name": "Mỹ Hương",
        "type": "phuong",
        "slug": "my-huong",
        "name_with_type": "Phường Mỹ Hương",
        "path": "Mỹ Hương, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Mỹ Hương, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22753",
        "parent_code": "582"
    },
    "22756": {
        "name": "Tấn Tài",
        "type": "phuong",
        "slug": "tan-tai",
        "name_with_type": "Phường Tấn Tài",
        "path": "Tấn Tài, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Tấn Tài, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22756",
        "parent_code": "582"
    },
    "22759": {
        "name": "Kinh Dinh",
        "type": "phuong",
        "slug": "kinh-dinh",
        "name_with_type": "Phường Kinh Dinh",
        "path": "Kinh Dinh, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Kinh Dinh, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22759",
        "parent_code": "582"
    },
    "22762": {
        "name": "Đạo Long",
        "type": "phuong",
        "slug": "dao-long",
        "name_with_type": "Phường Đạo Long",
        "path": "Đạo Long, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Đạo Long, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22762",
        "parent_code": "582"
    },
    "22765": {
        "name": "Đài Sơn",
        "type": "phuong",
        "slug": "dai-son",
        "name_with_type": "Phường Đài Sơn",
        "path": "Đài Sơn, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Đài Sơn, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22765",
        "parent_code": "582"
    },
    "22768": {
        "name": "Đông Hải",
        "type": "phuong",
        "slug": "dong-hai",
        "name_with_type": "Phường Đông Hải",
        "path": "Đông Hải, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Đông Hải, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22768",
        "parent_code": "582"
    },
    "22771": {
        "name": "Mỹ Đông",
        "type": "phuong",
        "slug": "my-dong",
        "name_with_type": "Phường Mỹ Đông",
        "path": "Mỹ Đông, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Mỹ Đông, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22771",
        "parent_code": "582"
    },
    "22774": {
        "name": "Thành Hải",
        "type": "xa",
        "slug": "thanh-hai",
        "name_with_type": "Xã Thành Hải",
        "path": "Thành Hải, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Xã Thành Hải, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22774",
        "parent_code": "582"
    },
    "22777": {
        "name": "Văn Hải",
        "type": "phuong",
        "slug": "van-hai",
        "name_with_type": "Phường Văn Hải",
        "path": "Văn Hải, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Văn Hải, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22777",
        "parent_code": "582"
    },
    "22779": {
        "name": "Mỹ Bình",
        "type": "phuong",
        "slug": "my-binh",
        "name_with_type": "Phường Mỹ Bình",
        "path": "Mỹ Bình, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Mỹ Bình, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22779",
        "parent_code": "582"
    },
    "22780": {
        "name": "Mỹ Hải",
        "type": "phuong",
        "slug": "my-hai",
        "name_with_type": "Phường Mỹ Hải",
        "path": "Mỹ Hải, Phan Rang-Tháp Chàm, Ninh Thuận",
        "path_with_type": "Phường Mỹ Hải, Thành phố Phan Rang-Tháp Chàm, Tỉnh Ninh Thuận",
        "code": "22780",
        "parent_code": "582"
    },
    "22783": {
        "name": "Phước Bình",
        "type": "xa",
        "slug": "phuoc-binh",
        "name_with_type": "Xã Phước Bình",
        "path": "Phước Bình, Bác Ái, Ninh Thuận",
        "path_with_type": "Xã Phước Bình, Huyện Bác Ái, Tỉnh Ninh Thuận",
        "code": "22783",
        "parent_code": "584"
    },
    "22786": {
        "name": "Phước Hòa",
        "type": "xa",
        "slug": "phuoc-hoa",
        "name_with_type": "Xã Phước Hòa",
        "path": "Phước Hòa, Bác Ái, Ninh Thuận",
        "path_with_type": "Xã Phước Hòa, Huyện Bác Ái, Tỉnh Ninh Thuận",
        "code": "22786",
        "parent_code": "584"
    },
    "22789": {
        "name": "Phước Tân",
        "type": "xa",
        "slug": "phuoc-tan",
        "name_with_type": "Xã Phước Tân",
        "path": "Phước Tân, Bác Ái, Ninh Thuận",
        "path_with_type": "Xã Phước Tân, Huyện Bác Ái, Tỉnh Ninh Thuận",
        "code": "22789",
        "parent_code": "584"
    },
    "22792": {
        "name": "Phước Tiến",
        "type": "xa",
        "slug": "phuoc-tien",
        "name_with_type": "Xã Phước Tiến",
        "path": "Phước Tiến, Bác Ái, Ninh Thuận",
        "path_with_type": "Xã Phước Tiến, Huyện Bác Ái, Tỉnh Ninh Thuận",
        "code": "22792",
        "parent_code": "584"
    },
    "22795": {
        "name": "Phước Thắng",
        "type": "xa",
        "slug": "phuoc-thang",
        "name_with_type": "Xã Phước Thắng",
        "path": "Phước Thắng, Bác Ái, Ninh Thuận",
        "path_with_type": "Xã Phước Thắng, Huyện Bác Ái, Tỉnh Ninh Thuận",
        "code": "22795",
        "parent_code": "584"
    },
    "22798": {
        "name": "Phước Thành",
        "type": "xa",
        "slug": "phuoc-thanh",
        "name_with_type": "Xã Phước Thành",
        "path": "Phước Thành, Bác Ái, Ninh Thuận",
        "path_with_type": "Xã Phước Thành, Huyện Bác Ái, Tỉnh Ninh Thuận",
        "code": "22798",
        "parent_code": "584"
    },
    "22801": {
        "name": "Phước Đại",
        "type": "xa",
        "slug": "phuoc-dai",
        "name_with_type": "Xã Phước Đại",
        "path": "Phước Đại, Bác Ái, Ninh Thuận",
        "path_with_type": "Xã Phước Đại, Huyện Bác Ái, Tỉnh Ninh Thuận",
        "code": "22801",
        "parent_code": "584"
    },
    "22804": {
        "name": "Phước Chính",
        "type": "xa",
        "slug": "phuoc-chinh",
        "name_with_type": "Xã Phước Chính",
        "path": "Phước Chính, Bác Ái, Ninh Thuận",
        "path_with_type": "Xã Phước Chính, Huyện Bác Ái, Tỉnh Ninh Thuận",
        "code": "22804",
        "parent_code": "584"
    },
    "22807": {
        "name": "Phước Trung",
        "type": "xa",
        "slug": "phuoc-trung",
        "name_with_type": "Xã Phước Trung",
        "path": "Phước Trung, Bác Ái, Ninh Thuận",
        "path_with_type": "Xã Phước Trung, Huyện Bác Ái, Tỉnh Ninh Thuận",
        "code": "22807",
        "parent_code": "584"
    },
    "22810": {
        "name": "Tân Sơn",
        "type": "thi-tran",
        "slug": "tan-son",
        "name_with_type": "Thị trấn Tân Sơn",
        "path": "Tân Sơn, Ninh Sơn, Ninh Thuận",
        "path_with_type": "Thị trấn Tân Sơn, Huyện Ninh Sơn, Tỉnh Ninh Thuận",
        "code": "22810",
        "parent_code": "585"
    },
    "22813": {
        "name": "Lâm Sơn",
        "type": "xa",
        "slug": "lam-son",
        "name_with_type": "Xã Lâm Sơn",
        "path": "Lâm Sơn, Ninh Sơn, Ninh Thuận",
        "path_with_type": "Xã Lâm Sơn, Huyện Ninh Sơn, Tỉnh Ninh Thuận",
        "code": "22813",
        "parent_code": "585"
    },
    "22816": {
        "name": "Lương Sơn",
        "type": "xa",
        "slug": "luong-son",
        "name_with_type": "Xã Lương Sơn",
        "path": "Lương Sơn, Ninh Sơn, Ninh Thuận",
        "path_with_type": "Xã Lương Sơn, Huyện Ninh Sơn, Tỉnh Ninh Thuận",
        "code": "22816",
        "parent_code": "585"
    },
    "22819": {
        "name": "Quảng Sơn",
        "type": "xa",
        "slug": "quang-son",
        "name_with_type": "Xã Quảng Sơn",
        "path": "Quảng Sơn, Ninh Sơn, Ninh Thuận",
        "path_with_type": "Xã Quảng Sơn, Huyện Ninh Sơn, Tỉnh Ninh Thuận",
        "code": "22819",
        "parent_code": "585"
    },
    "22822": {
        "name": "Mỹ Sơn",
        "type": "xa",
        "slug": "my-son",
        "name_with_type": "Xã Mỹ Sơn",
        "path": "Mỹ Sơn, Ninh Sơn, Ninh Thuận",
        "path_with_type": "Xã Mỹ Sơn, Huyện Ninh Sơn, Tỉnh Ninh Thuận",
        "code": "22822",
        "parent_code": "585"
    },
    "22825": {
        "name": "Hòa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hòa Sơn",
        "path": "Hòa Sơn, Ninh Sơn, Ninh Thuận",
        "path_with_type": "Xã Hòa Sơn, Huyện Ninh Sơn, Tỉnh Ninh Thuận",
        "code": "22825",
        "parent_code": "585"
    },
    "22828": {
        "name": "Ma Nới",
        "type": "xa",
        "slug": "ma-noi",
        "name_with_type": "Xã Ma Nới",
        "path": "Ma Nới, Ninh Sơn, Ninh Thuận",
        "path_with_type": "Xã Ma Nới, Huyện Ninh Sơn, Tỉnh Ninh Thuận",
        "code": "22828",
        "parent_code": "585"
    },
    "22831": {
        "name": "Nhơn Sơn",
        "type": "xa",
        "slug": "nhon-son",
        "name_with_type": "Xã Nhơn Sơn",
        "path": "Nhơn Sơn, Ninh Sơn, Ninh Thuận",
        "path_with_type": "Xã Nhơn Sơn, Huyện Ninh Sơn, Tỉnh Ninh Thuận",
        "code": "22831",
        "parent_code": "585"
    },
    "22834": {
        "name": "Khánh Hải",
        "type": "thi-tran",
        "slug": "khanh-hai",
        "name_with_type": "Thị trấn Khánh Hải",
        "path": "Khánh Hải, Ninh Hải, Ninh Thuận",
        "path_with_type": "Thị trấn Khánh Hải, Huyện Ninh Hải, Tỉnh Ninh Thuận",
        "code": "22834",
        "parent_code": "586"
    },
    "22846": {
        "name": "Vĩnh Hải",
        "type": "xa",
        "slug": "vinh-hai",
        "name_with_type": "Xã Vĩnh Hải",
        "path": "Vĩnh Hải, Ninh Hải, Ninh Thuận",
        "path_with_type": "Xã Vĩnh Hải, Huyện Ninh Hải, Tỉnh Ninh Thuận",
        "code": "22846",
        "parent_code": "586"
    },
    "22852": {
        "name": "Phương Hải",
        "type": "xa",
        "slug": "phuong-hai",
        "name_with_type": "Xã Phương Hải",
        "path": "Phương Hải, Ninh Hải, Ninh Thuận",
        "path_with_type": "Xã Phương Hải, Huyện Ninh Hải, Tỉnh Ninh Thuận",
        "code": "22852",
        "parent_code": "586"
    },
    "22855": {
        "name": "Tân Hải",
        "type": "xa",
        "slug": "tan-hai",
        "name_with_type": "Xã Tân Hải",
        "path": "Tân Hải, Ninh Hải, Ninh Thuận",
        "path_with_type": "Xã Tân Hải, Huyện Ninh Hải, Tỉnh Ninh Thuận",
        "code": "22855",
        "parent_code": "586"
    },
    "22858": {
        "name": "Xuân Hải",
        "type": "xa",
        "slug": "xuan-hai",
        "name_with_type": "Xã Xuân Hải",
        "path": "Xuân Hải, Ninh Hải, Ninh Thuận",
        "path_with_type": "Xã Xuân Hải, Huyện Ninh Hải, Tỉnh Ninh Thuận",
        "code": "22858",
        "parent_code": "586"
    },
    "22861": {
        "name": "Hộ Hải",
        "type": "xa",
        "slug": "ho-hai",
        "name_with_type": "Xã Hộ Hải",
        "path": "Hộ Hải, Ninh Hải, Ninh Thuận",
        "path_with_type": "Xã Hộ Hải, Huyện Ninh Hải, Tỉnh Ninh Thuận",
        "code": "22861",
        "parent_code": "586"
    },
    "22864": {
        "name": "Tri Hải",
        "type": "xa",
        "slug": "tri-hai",
        "name_with_type": "Xã Tri Hải",
        "path": "Tri Hải, Ninh Hải, Ninh Thuận",
        "path_with_type": "Xã Tri Hải, Huyện Ninh Hải, Tỉnh Ninh Thuận",
        "code": "22864",
        "parent_code": "586"
    },
    "22867": {
        "name": "Nhơn Hải",
        "type": "xa",
        "slug": "nhon-hai",
        "name_with_type": "Xã Nhơn Hải",
        "path": "Nhơn Hải, Ninh Hải, Ninh Thuận",
        "path_with_type": "Xã Nhơn Hải, Huyện Ninh Hải, Tỉnh Ninh Thuận",
        "code": "22867",
        "parent_code": "586"
    },
    "22868": {
        "name": "Thanh Hải",
        "type": "xa",
        "slug": "thanh-hai",
        "name_with_type": "Xã Thanh Hải",
        "path": "Thanh Hải, Ninh Hải, Ninh Thuận",
        "path_with_type": "Xã Thanh Hải, Huyện Ninh Hải, Tỉnh Ninh Thuận",
        "code": "22868",
        "parent_code": "586"
    },
    "22870": {
        "name": "Phước Dân",
        "type": "thi-tran",
        "slug": "phuoc-dan",
        "name_with_type": "Thị trấn Phước Dân",
        "path": "Phước Dân, Ninh Phước, Ninh Thuận",
        "path_with_type": "Thị trấn Phước Dân, Huyện Ninh Phước, Tỉnh Ninh Thuận",
        "code": "22870",
        "parent_code": "587"
    },
    "22873": {
        "name": "Phước Sơn",
        "type": "xa",
        "slug": "phuoc-son",
        "name_with_type": "Xã Phước Sơn",
        "path": "Phước Sơn, Ninh Phước, Ninh Thuận",
        "path_with_type": "Xã Phước Sơn, Huyện Ninh Phước, Tỉnh Ninh Thuận",
        "code": "22873",
        "parent_code": "587"
    },
    "22876": {
        "name": "Phước Thái",
        "type": "xa",
        "slug": "phuoc-thai",
        "name_with_type": "Xã Phước Thái",
        "path": "Phước Thái, Ninh Phước, Ninh Thuận",
        "path_with_type": "Xã Phước Thái, Huyện Ninh Phước, Tỉnh Ninh Thuận",
        "code": "22876",
        "parent_code": "587"
    },
    "22879": {
        "name": "Phước Hậu",
        "type": "xa",
        "slug": "phuoc-hau",
        "name_with_type": "Xã Phước Hậu",
        "path": "Phước Hậu, Ninh Phước, Ninh Thuận",
        "path_with_type": "Xã Phước Hậu, Huyện Ninh Phước, Tỉnh Ninh Thuận",
        "code": "22879",
        "parent_code": "587"
    },
    "22882": {
        "name": "Phước Thuận",
        "type": "xa",
        "slug": "phuoc-thuan",
        "name_with_type": "Xã Phước Thuận",
        "path": "Phước Thuận, Ninh Phước, Ninh Thuận",
        "path_with_type": "Xã Phước Thuận, Huyện Ninh Phước, Tỉnh Ninh Thuận",
        "code": "22882",
        "parent_code": "587"
    },
    "22888": {
        "name": "An Hải",
        "type": "xa",
        "slug": "an-hai",
        "name_with_type": "Xã An Hải",
        "path": "An Hải, Ninh Phước, Ninh Thuận",
        "path_with_type": "Xã An Hải, Huyện Ninh Phước, Tỉnh Ninh Thuận",
        "code": "22888",
        "parent_code": "587"
    },
    "22891": {
        "name": "Phước Hữu",
        "type": "xa",
        "slug": "phuoc-huu",
        "name_with_type": "Xã Phước Hữu",
        "path": "Phước Hữu, Ninh Phước, Ninh Thuận",
        "path_with_type": "Xã Phước Hữu, Huyện Ninh Phước, Tỉnh Ninh Thuận",
        "code": "22891",
        "parent_code": "587"
    },
    "22894": {
        "name": "Phước Hải",
        "type": "xa",
        "slug": "phuoc-hai",
        "name_with_type": "Xã Phước Hải",
        "path": "Phước Hải, Ninh Phước, Ninh Thuận",
        "path_with_type": "Xã Phước Hải, Huyện Ninh Phước, Tỉnh Ninh Thuận",
        "code": "22894",
        "parent_code": "587"
    },
    "22912": {
        "name": "Phước Vinh",
        "type": "xa",
        "slug": "phuoc-vinh",
        "name_with_type": "Xã Phước Vinh",
        "path": "Phước Vinh, Ninh Phước, Ninh Thuận",
        "path_with_type": "Xã Phước Vinh, Huyện Ninh Phước, Tỉnh Ninh Thuận",
        "code": "22912",
        "parent_code": "587"
    },
    "22837": {
        "name": "Phước Chiến",
        "type": "xa",
        "slug": "phuoc-chien",
        "name_with_type": "Xã Phước Chiến",
        "path": "Phước Chiến, Thuận Bắc, Ninh Thuận",
        "path_with_type": "Xã Phước Chiến, Huyện Thuận Bắc, Tỉnh Ninh Thuận",
        "code": "22837",
        "parent_code": "588"
    },
    "22840": {
        "name": "Công Hải",
        "type": "xa",
        "slug": "cong-hai",
        "name_with_type": "Xã Công Hải",
        "path": "Công Hải, Thuận Bắc, Ninh Thuận",
        "path_with_type": "Xã Công Hải, Huyện Thuận Bắc, Tỉnh Ninh Thuận",
        "code": "22840",
        "parent_code": "588"
    },
    "22843": {
        "name": "Phước Kháng",
        "type": "xa",
        "slug": "phuoc-khang",
        "name_with_type": "Xã Phước Kháng",
        "path": "Phước Kháng, Thuận Bắc, Ninh Thuận",
        "path_with_type": "Xã Phước Kháng, Huyện Thuận Bắc, Tỉnh Ninh Thuận",
        "code": "22843",
        "parent_code": "588"
    },
    "22849": {
        "name": "Lợi Hải",
        "type": "xa",
        "slug": "loi-hai",
        "name_with_type": "Xã Lợi Hải",
        "path": "Lợi Hải, Thuận Bắc, Ninh Thuận",
        "path_with_type": "Xã Lợi Hải, Huyện Thuận Bắc, Tỉnh Ninh Thuận",
        "code": "22849",
        "parent_code": "588"
    },
    "22853": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Thuận Bắc, Ninh Thuận",
        "path_with_type": "Xã Bắc Sơn, Huyện Thuận Bắc, Tỉnh Ninh Thuận",
        "code": "22853",
        "parent_code": "588"
    },
    "22856": {
        "name": "Bắc Phong",
        "type": "xa",
        "slug": "bac-phong",
        "name_with_type": "Xã Bắc Phong",
        "path": "Bắc Phong, Thuận Bắc, Ninh Thuận",
        "path_with_type": "Xã Bắc Phong, Huyện Thuận Bắc, Tỉnh Ninh Thuận",
        "code": "22856",
        "parent_code": "588"
    },
    "22885": {
        "name": "Phước Hà",
        "type": "xa",
        "slug": "phuoc-ha",
        "name_with_type": "Xã Phước Hà",
        "path": "Phước Hà, Thuận Nam, Ninh Thuận",
        "path_with_type": "Xã Phước Hà, Huyện Thuận Nam, Tỉnh Ninh Thuận",
        "code": "22885",
        "parent_code": "589"
    },
    "22897": {
        "name": "Phước Nam",
        "type": "xa",
        "slug": "phuoc-nam",
        "name_with_type": "Xã Phước Nam",
        "path": "Phước Nam, Thuận Nam, Ninh Thuận",
        "path_with_type": "Xã Phước Nam, Huyện Thuận Nam, Tỉnh Ninh Thuận",
        "code": "22897",
        "parent_code": "589"
    },
    "22898": {
        "name": "Phước Ninh",
        "type": "xa",
        "slug": "phuoc-ninh",
        "name_with_type": "Xã Phước Ninh",
        "path": "Phước Ninh, Thuận Nam, Ninh Thuận",
        "path_with_type": "Xã Phước Ninh, Huyện Thuận Nam, Tỉnh Ninh Thuận",
        "code": "22898",
        "parent_code": "589"
    },
    "22900": {
        "name": "Nhị Hà",
        "type": "xa",
        "slug": "nhi-ha",
        "name_with_type": "Xã Nhị Hà",
        "path": "Nhị Hà, Thuận Nam, Ninh Thuận",
        "path_with_type": "Xã Nhị Hà, Huyện Thuận Nam, Tỉnh Ninh Thuận",
        "code": "22900",
        "parent_code": "589"
    },
    "22903": {
        "name": "Phước Dinh",
        "type": "xa",
        "slug": "phuoc-dinh",
        "name_with_type": "Xã Phước Dinh",
        "path": "Phước Dinh, Thuận Nam, Ninh Thuận",
        "path_with_type": "Xã Phước Dinh, Huyện Thuận Nam, Tỉnh Ninh Thuận",
        "code": "22903",
        "parent_code": "589"
    },
    "22906": {
        "name": "Phước Minh",
        "type": "xa",
        "slug": "phuoc-minh",
        "name_with_type": "Xã Phước Minh",
        "path": "Phước Minh, Thuận Nam, Ninh Thuận",
        "path_with_type": "Xã Phước Minh, Huyện Thuận Nam, Tỉnh Ninh Thuận",
        "code": "22906",
        "parent_code": "589"
    },
    "22909": {
        "name": "Phước Diêm",
        "type": "xa",
        "slug": "phuoc-diem",
        "name_with_type": "Xã Phước Diêm",
        "path": "Phước Diêm, Thuận Nam, Ninh Thuận",
        "path_with_type": "Xã Phước Diêm, Huyện Thuận Nam, Tỉnh Ninh Thuận",
        "code": "22909",
        "parent_code": "589"
    },
    "22910": {
        "name": "Cà Ná",
        "type": "xa",
        "slug": "ca-na",
        "name_with_type": "Xã Cà Ná",
        "path": "Cà Ná, Thuận Nam, Ninh Thuận",
        "path_with_type": "Xã Cà Ná, Huyện Thuận Nam, Tỉnh Ninh Thuận",
        "code": "22910",
        "parent_code": "589"
    },
    "29863": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Phường 11, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29863",
        "parent_code": "866"
    },
    "29866": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Phường 1, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29866",
        "parent_code": "866"
    },
    "29869": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Phường 2, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29869",
        "parent_code": "866"
    },
    "29872": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Phường 4, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29872",
        "parent_code": "866"
    },
    "29875": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Phường 3, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29875",
        "parent_code": "866"
    },
    "29878": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Phường 6, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29878",
        "parent_code": "866"
    },
    "29881": {
        "name": "Mỹ Ngãi",
        "type": "xa",
        "slug": "my-ngai",
        "name_with_type": "Xã Mỹ Ngãi",
        "path": "Mỹ Ngãi, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Mỹ Ngãi, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29881",
        "parent_code": "866"
    },
    "29884": {
        "name": "Mỹ Tân",
        "type": "xa",
        "slug": "my-tan",
        "name_with_type": "Xã Mỹ Tân",
        "path": "Mỹ Tân, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Mỹ Tân, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29884",
        "parent_code": "866"
    },
    "29887": {
        "name": "Mỹ Trà",
        "type": "xa",
        "slug": "my-tra",
        "name_with_type": "Xã Mỹ Trà",
        "path": "Mỹ Trà, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Mỹ Trà, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29887",
        "parent_code": "866"
    },
    "29888": {
        "name": "Mỹ Phú",
        "type": "phuong",
        "slug": "my-phu",
        "name_with_type": "Phường Mỹ Phú",
        "path": "Mỹ Phú, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Phường Mỹ Phú, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29888",
        "parent_code": "866"
    },
    "29890": {
        "name": "Tân Thuận Tây",
        "type": "xa",
        "slug": "tan-thuan-tay",
        "name_with_type": "Xã Tân Thuận Tây",
        "path": "Tân Thuận Tây, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Tân Thuận Tây, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29890",
        "parent_code": "866"
    },
    "29892": {
        "name": "Hoà Thuận",
        "type": "phuong",
        "slug": "hoa-thuan",
        "name_with_type": "Phường Hoà Thuận",
        "path": "Hoà Thuận, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Phường Hoà Thuận, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29892",
        "parent_code": "866"
    },
    "29893": {
        "name": "Hòa An",
        "type": "xa",
        "slug": "hoa-an",
        "name_with_type": "Xã Hòa An",
        "path": "Hòa An, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Hòa An, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29893",
        "parent_code": "866"
    },
    "29896": {
        "name": "Tân Thuận Đông",
        "type": "xa",
        "slug": "tan-thuan-dong",
        "name_with_type": "Xã Tân Thuận Đông",
        "path": "Tân Thuận Đông, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Tân Thuận Đông, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29896",
        "parent_code": "866"
    },
    "29899": {
        "name": "Tịnh Thới",
        "type": "xa",
        "slug": "tinh-thoi",
        "name_with_type": "Xã Tịnh Thới",
        "path": "Tịnh Thới, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Tịnh Thới, Thành phố Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "29899",
        "parent_code": "866"
    },
    "29902": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Sa Đéc, Đồng Tháp",
        "path_with_type": "Phường 3, Thành phố Sa Đéc, Tỉnh Đồng Tháp",
        "code": "29902",
        "parent_code": "867"
    },
    "29905": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Sa Đéc, Đồng Tháp",
        "path_with_type": "Phường 1, Thành phố Sa Đéc, Tỉnh Đồng Tháp",
        "code": "29905",
        "parent_code": "867"
    },
    "29908": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Sa Đéc, Đồng Tháp",
        "path_with_type": "Phường 4, Thành phố Sa Đéc, Tỉnh Đồng Tháp",
        "code": "29908",
        "parent_code": "867"
    },
    "29911": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Sa Đéc, Đồng Tháp",
        "path_with_type": "Phường 2, Thành phố Sa Đéc, Tỉnh Đồng Tháp",
        "code": "29911",
        "parent_code": "867"
    },
    "29914": {
        "name": "Tân Khánh Đông",
        "type": "xa",
        "slug": "tan-khanh-dong",
        "name_with_type": "Xã Tân Khánh Đông",
        "path": "Tân Khánh Đông, Sa Đéc, Đồng Tháp",
        "path_with_type": "Xã Tân Khánh Đông, Thành phố Sa Đéc, Tỉnh Đồng Tháp",
        "code": "29914",
        "parent_code": "867"
    },
    "29917": {
        "name": "Tân Quy Đông",
        "type": "phuong",
        "slug": "tan-quy-dong",
        "name_with_type": "Phường Tân Quy Đông",
        "path": "Tân Quy Đông, Sa Đéc, Đồng Tháp",
        "path_with_type": "Phường Tân Quy Đông, Thành phố Sa Đéc, Tỉnh Đồng Tháp",
        "code": "29917",
        "parent_code": "867"
    },
    "29919": {
        "name": "An Hoà",
        "type": "phuong",
        "slug": "an-hoa",
        "name_with_type": "Phường An Hoà",
        "path": "An Hoà, Sa Đéc, Đồng Tháp",
        "path_with_type": "Phường An Hoà, Thành phố Sa Đéc, Tỉnh Đồng Tháp",
        "code": "29919",
        "parent_code": "867"
    },
    "29920": {
        "name": "Tân Quy Tây",
        "type": "xa",
        "slug": "tan-quy-tay",
        "name_with_type": "Xã Tân Quy Tây",
        "path": "Tân Quy Tây, Sa Đéc, Đồng Tháp",
        "path_with_type": "Xã Tân Quy Tây, Thành phố Sa Đéc, Tỉnh Đồng Tháp",
        "code": "29920",
        "parent_code": "867"
    },
    "29923": {
        "name": "Tân Phú Đông",
        "type": "xa",
        "slug": "tan-phu-dong",
        "name_with_type": "Xã Tân Phú Đông",
        "path": "Tân Phú Đông, Sa Đéc, Đồng Tháp",
        "path_with_type": "Xã Tân Phú Đông, Thành phố Sa Đéc, Tỉnh Đồng Tháp",
        "code": "29923",
        "parent_code": "867"
    },
    "29954": {
        "name": "An Lộc",
        "type": "phuong",
        "slug": "an-loc",
        "name_with_type": "Phường An Lộc",
        "path": "An Lộc, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Phường An Lộc, Thị xã Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29954",
        "parent_code": "868"
    },
    "29955": {
        "name": "An Thạnh",
        "type": "phuong",
        "slug": "an-thanh",
        "name_with_type": "Phường An Thạnh",
        "path": "An Thạnh, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Phường An Thạnh, Thị xã Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29955",
        "parent_code": "868"
    },
    "29959": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Bình Thạnh, Thị xã Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29959",
        "parent_code": "868"
    },
    "29965": {
        "name": "Tân Hội",
        "type": "xa",
        "slug": "tan-hoi",
        "name_with_type": "Xã Tân Hội",
        "path": "Tân Hội, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Tân Hội, Thị xã Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29965",
        "parent_code": "868"
    },
    "29978": {
        "name": "An Lạc",
        "type": "phuong",
        "slug": "an-lac",
        "name_with_type": "Phường An Lạc",
        "path": "An Lạc, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Phường An Lạc, Thị xã Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29978",
        "parent_code": "868"
    },
    "29986": {
        "name": "An Bình B",
        "type": "xa",
        "slug": "an-binh-b",
        "name_with_type": "Xã An Bình B",
        "path": "An Bình B, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã An Bình B, Thị xã Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29986",
        "parent_code": "868"
    },
    "29989": {
        "name": "An Bình A",
        "type": "xa",
        "slug": "an-binh-a",
        "name_with_type": "Xã An Bình A",
        "path": "An Bình A, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã An Bình A, Thị xã Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29989",
        "parent_code": "868"
    },
    "29926": {
        "name": "Sa Rài",
        "type": "thi-tran",
        "slug": "sa-rai",
        "name_with_type": "Thị trấn Sa Rài",
        "path": "Sa Rài, Tân Hồng, Đồng Tháp",
        "path_with_type": "Thị trấn Sa Rài, Huyện Tân Hồng, Tỉnh Đồng Tháp",
        "code": "29926",
        "parent_code": "869"
    },
    "29929": {
        "name": "Tân Hộ Cơ",
        "type": "xa",
        "slug": "tan-ho-co",
        "name_with_type": "Xã Tân Hộ Cơ",
        "path": "Tân Hộ Cơ, Tân Hồng, Đồng Tháp",
        "path_with_type": "Xã Tân Hộ Cơ, Huyện Tân Hồng, Tỉnh Đồng Tháp",
        "code": "29929",
        "parent_code": "869"
    },
    "29932": {
        "name": "Thông Bình",
        "type": "xa",
        "slug": "thong-binh",
        "name_with_type": "Xã Thông Bình",
        "path": "Thông Bình, Tân Hồng, Đồng Tháp",
        "path_with_type": "Xã Thông Bình, Huyện Tân Hồng, Tỉnh Đồng Tháp",
        "code": "29932",
        "parent_code": "869"
    },
    "29935": {
        "name": "Bình Phú",
        "type": "xa",
        "slug": "binh-phu",
        "name_with_type": "Xã Bình Phú",
        "path": "Bình Phú, Tân Hồng, Đồng Tháp",
        "path_with_type": "Xã Bình Phú, Huyện Tân Hồng, Tỉnh Đồng Tháp",
        "code": "29935",
        "parent_code": "869"
    },
    "29938": {
        "name": "Tân Thành A",
        "type": "xa",
        "slug": "tan-thanh-a",
        "name_with_type": "Xã Tân Thành A",
        "path": "Tân Thành A, Tân Hồng, Đồng Tháp",
        "path_with_type": "Xã Tân Thành A, Huyện Tân Hồng, Tỉnh Đồng Tháp",
        "code": "29938",
        "parent_code": "869"
    },
    "29941": {
        "name": "Tân Thành B",
        "type": "xa",
        "slug": "tan-thanh-b",
        "name_with_type": "Xã Tân Thành B",
        "path": "Tân Thành B, Tân Hồng, Đồng Tháp",
        "path_with_type": "Xã Tân Thành B, Huyện Tân Hồng, Tỉnh Đồng Tháp",
        "code": "29941",
        "parent_code": "869"
    },
    "29944": {
        "name": "Tân Phước",
        "type": "xa",
        "slug": "tan-phuoc",
        "name_with_type": "Xã Tân Phước",
        "path": "Tân Phước, Tân Hồng, Đồng Tháp",
        "path_with_type": "Xã Tân Phước, Huyện Tân Hồng, Tỉnh Đồng Tháp",
        "code": "29944",
        "parent_code": "869"
    },
    "29947": {
        "name": "Tân Công Chí",
        "type": "xa",
        "slug": "tan-cong-chi",
        "name_with_type": "Xã Tân Công Chí",
        "path": "Tân Công Chí, Tân Hồng, Đồng Tháp",
        "path_with_type": "Xã Tân Công Chí, Huyện Tân Hồng, Tỉnh Đồng Tháp",
        "code": "29947",
        "parent_code": "869"
    },
    "29950": {
        "name": "An Phước",
        "type": "xa",
        "slug": "an-phuoc",
        "name_with_type": "Xã An Phước",
        "path": "An Phước, Tân Hồng, Đồng Tháp",
        "path_with_type": "Xã An Phước, Huyện Tân Hồng, Tỉnh Đồng Tháp",
        "code": "29950",
        "parent_code": "869"
    },
    "29956": {
        "name": "Thường Phước 1",
        "type": "xa",
        "slug": "thuong-phuoc-1",
        "name_with_type": "Xã Thường Phước 1",
        "path": "Thường Phước 1, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Thường Phước 1, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29956",
        "parent_code": "870"
    },
    "29962": {
        "name": "Thường Thới Hậu A",
        "type": "xa",
        "slug": "thuong-thoi-hau-a",
        "name_with_type": "Xã Thường Thới Hậu A",
        "path": "Thường Thới Hậu A, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Thường Thới Hậu A, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29962",
        "parent_code": "870"
    },
    "29968": {
        "name": "Thường Thới Hậu B",
        "type": "xa",
        "slug": "thuong-thoi-hau-b",
        "name_with_type": "Xã Thường Thới Hậu B",
        "path": "Thường Thới Hậu B, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Thường Thới Hậu B, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29968",
        "parent_code": "870"
    },
    "29971": {
        "name": "Thường Thới Tiền",
        "type": "xa",
        "slug": "thuong-thoi-tien",
        "name_with_type": "Xã Thường Thới Tiền",
        "path": "Thường Thới Tiền, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Thường Thới Tiền, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29971",
        "parent_code": "870"
    },
    "29974": {
        "name": "Thường Phước 2",
        "type": "xa",
        "slug": "thuong-phuoc-2",
        "name_with_type": "Xã Thường Phước 2",
        "path": "Thường Phước 2, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Thường Phước 2, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29974",
        "parent_code": "870"
    },
    "29977": {
        "name": "Thường Lạc",
        "type": "xa",
        "slug": "thuong-lac",
        "name_with_type": "Xã Thường Lạc",
        "path": "Thường Lạc, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Thường Lạc, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29977",
        "parent_code": "870"
    },
    "29980": {
        "name": "Long Khánh A",
        "type": "xa",
        "slug": "long-khanh-a",
        "name_with_type": "Xã Long Khánh A",
        "path": "Long Khánh A, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Long Khánh A, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29980",
        "parent_code": "870"
    },
    "29983": {
        "name": "Long Khánh B",
        "type": "xa",
        "slug": "long-khanh-b",
        "name_with_type": "Xã Long Khánh B",
        "path": "Long Khánh B, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Long Khánh B, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29983",
        "parent_code": "870"
    },
    "29992": {
        "name": "Long Thuận",
        "type": "xa",
        "slug": "long-thuan",
        "name_with_type": "Xã Long Thuận",
        "path": "Long Thuận, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Long Thuận, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29992",
        "parent_code": "870"
    },
    "29995": {
        "name": "Phú Thuận B",
        "type": "xa",
        "slug": "phu-thuan-b",
        "name_with_type": "Xã Phú Thuận B",
        "path": "Phú Thuận B, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Phú Thuận B, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29995",
        "parent_code": "870"
    },
    "29998": {
        "name": "Phú Thuận A",
        "type": "xa",
        "slug": "phu-thuan-a",
        "name_with_type": "Xã Phú Thuận A",
        "path": "Phú Thuận A, Hồng Ngự, Đồng Tháp",
        "path_with_type": "Xã Phú Thuận A, Huyện Hồng Ngự, Tỉnh Đồng Tháp",
        "code": "29998",
        "parent_code": "870"
    },
    "30001": {
        "name": "Tràm Chim",
        "type": "thi-tran",
        "slug": "tram-chim",
        "name_with_type": "Thị trấn Tràm Chim",
        "path": "Tràm Chim, Tam Nông, Đồng Tháp",
        "path_with_type": "Thị trấn Tràm Chim, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30001",
        "parent_code": "871"
    },
    "30004": {
        "name": "Hoà Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hoà Bình",
        "path": "Hoà Bình, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã Hoà Bình, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30004",
        "parent_code": "871"
    },
    "30007": {
        "name": "Tân Công Sính",
        "type": "xa",
        "slug": "tan-cong-sinh",
        "name_with_type": "Xã Tân Công Sính",
        "path": "Tân Công Sính, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã Tân Công Sính, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30007",
        "parent_code": "871"
    },
    "30010": {
        "name": "Phú Hiệp",
        "type": "xa",
        "slug": "phu-hiep",
        "name_with_type": "Xã Phú Hiệp",
        "path": "Phú Hiệp, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã Phú Hiệp, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30010",
        "parent_code": "871"
    },
    "30013": {
        "name": "Phú Đức",
        "type": "xa",
        "slug": "phu-duc",
        "name_with_type": "Xã Phú Đức",
        "path": "Phú Đức, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã Phú Đức, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30013",
        "parent_code": "871"
    },
    "30016": {
        "name": "Phú Thành B",
        "type": "xa",
        "slug": "phu-thanh-b",
        "name_with_type": "Xã Phú Thành B",
        "path": "Phú Thành B, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã Phú Thành B, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30016",
        "parent_code": "871"
    },
    "30019": {
        "name": "An Hòa",
        "type": "xa",
        "slug": "an-hoa",
        "name_with_type": "Xã An Hòa",
        "path": "An Hòa, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã An Hòa, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30019",
        "parent_code": "871"
    },
    "30022": {
        "name": "An Long",
        "type": "xa",
        "slug": "an-long",
        "name_with_type": "Xã An Long",
        "path": "An Long, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã An Long, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30022",
        "parent_code": "871"
    },
    "30025": {
        "name": "Phú Cường",
        "type": "xa",
        "slug": "phu-cuong",
        "name_with_type": "Xã Phú Cường",
        "path": "Phú Cường, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã Phú Cường, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30025",
        "parent_code": "871"
    },
    "30028": {
        "name": "Phú Ninh",
        "type": "xa",
        "slug": "phu-ninh",
        "name_with_type": "Xã Phú Ninh",
        "path": "Phú Ninh, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã Phú Ninh, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30028",
        "parent_code": "871"
    },
    "30031": {
        "name": "Phú Thọ",
        "type": "xa",
        "slug": "phu-tho",
        "name_with_type": "Xã Phú Thọ",
        "path": "Phú Thọ, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã Phú Thọ, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30031",
        "parent_code": "871"
    },
    "30034": {
        "name": "Phú Thành A",
        "type": "xa",
        "slug": "phu-thanh-a",
        "name_with_type": "Xã Phú Thành A",
        "path": "Phú Thành A, Tam Nông, Đồng Tháp",
        "path_with_type": "Xã Phú Thành A, Huyện Tam Nông, Tỉnh Đồng Tháp",
        "code": "30034",
        "parent_code": "871"
    },
    "30037": {
        "name": "Mỹ An",
        "type": "thi-tran",
        "slug": "my-an",
        "name_with_type": "Thị trấn Mỹ An",
        "path": "Mỹ An, Tháp Mười, Đồng Tháp",
        "path_with_type": "Thị trấn Mỹ An, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30037",
        "parent_code": "872"
    },
    "30040": {
        "name": "Thạnh Lợi",
        "type": "xa",
        "slug": "thanh-loi",
        "name_with_type": "Xã Thạnh Lợi",
        "path": "Thạnh Lợi, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Thạnh Lợi, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30040",
        "parent_code": "872"
    },
    "30043": {
        "name": "Hưng Thạnh",
        "type": "xa",
        "slug": "hung-thanh",
        "name_with_type": "Xã Hưng Thạnh",
        "path": "Hưng Thạnh, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Hưng Thạnh, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30043",
        "parent_code": "872"
    },
    "30046": {
        "name": "Trường Xuân",
        "type": "xa",
        "slug": "truong-xuan",
        "name_with_type": "Xã Trường Xuân",
        "path": "Trường Xuân, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Trường Xuân, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30046",
        "parent_code": "872"
    },
    "30049": {
        "name": "Tân Kiều",
        "type": "xa",
        "slug": "tan-kieu",
        "name_with_type": "Xã Tân Kiều",
        "path": "Tân Kiều, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Tân Kiều, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30049",
        "parent_code": "872"
    },
    "30052": {
        "name": "Mỹ Hòa",
        "type": "xa",
        "slug": "my-hoa",
        "name_with_type": "Xã Mỹ Hòa",
        "path": "Mỹ Hòa, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Mỹ Hòa, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30052",
        "parent_code": "872"
    },
    "30055": {
        "name": "Mỹ Quý",
        "type": "xa",
        "slug": "my-quy",
        "name_with_type": "Xã Mỹ Quý",
        "path": "Mỹ Quý, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Mỹ Quý, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30055",
        "parent_code": "872"
    },
    "30058": {
        "name": "Mỹ Đông",
        "type": "xa",
        "slug": "my-dong",
        "name_with_type": "Xã Mỹ Đông",
        "path": "Mỹ Đông, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Mỹ Đông, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30058",
        "parent_code": "872"
    },
    "30061": {
        "name": "Đốc Binh Kiều",
        "type": "xa",
        "slug": "doc-binh-kieu",
        "name_with_type": "Xã Đốc Binh Kiều",
        "path": "Đốc Binh Kiều, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Đốc Binh Kiều, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30061",
        "parent_code": "872"
    },
    "30064": {
        "name": "Mỹ An",
        "type": "xa",
        "slug": "my-an",
        "name_with_type": "Xã Mỹ An",
        "path": "Mỹ An, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Mỹ An, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30064",
        "parent_code": "872"
    },
    "30067": {
        "name": "Phú Điền",
        "type": "xa",
        "slug": "phu-dien",
        "name_with_type": "Xã Phú Điền",
        "path": "Phú Điền, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Phú Điền, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30067",
        "parent_code": "872"
    },
    "30070": {
        "name": "Láng Biển",
        "type": "xa",
        "slug": "lang-bien",
        "name_with_type": "Xã Láng Biển",
        "path": "Láng Biển, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Láng Biển, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30070",
        "parent_code": "872"
    },
    "30073": {
        "name": "Thanh Mỹ",
        "type": "xa",
        "slug": "thanh-my",
        "name_with_type": "Xã Thanh Mỹ",
        "path": "Thanh Mỹ, Tháp Mười, Đồng Tháp",
        "path_with_type": "Xã Thanh Mỹ, Huyện Tháp Mười, Tỉnh Đồng Tháp",
        "code": "30073",
        "parent_code": "872"
    },
    "30076": {
        "name": "Mỹ Thọ",
        "type": "thi-tran",
        "slug": "my-tho",
        "name_with_type": "Thị trấn Mỹ Thọ",
        "path": "Mỹ Thọ, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Thị trấn Mỹ Thọ, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30076",
        "parent_code": "873"
    },
    "30079": {
        "name": "Gáo Giồng",
        "type": "xa",
        "slug": "gao-giong",
        "name_with_type": "Xã Gáo Giồng",
        "path": "Gáo Giồng, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Gáo Giồng, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30079",
        "parent_code": "873"
    },
    "30082": {
        "name": "Phương Thịnh",
        "type": "xa",
        "slug": "phuong-thinh",
        "name_with_type": "Xã Phương Thịnh",
        "path": "Phương Thịnh, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Phương Thịnh, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30082",
        "parent_code": "873"
    },
    "30085": {
        "name": "Ba Sao",
        "type": "xa",
        "slug": "ba-sao",
        "name_with_type": "Xã Ba Sao",
        "path": "Ba Sao, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Ba Sao, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30085",
        "parent_code": "873"
    },
    "30088": {
        "name": "Phong Mỹ",
        "type": "xa",
        "slug": "phong-my",
        "name_with_type": "Xã Phong Mỹ",
        "path": "Phong Mỹ, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Phong Mỹ, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30088",
        "parent_code": "873"
    },
    "30091": {
        "name": "Tân Nghĩa",
        "type": "xa",
        "slug": "tan-nghia",
        "name_with_type": "Xã Tân Nghĩa",
        "path": "Tân Nghĩa, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Tân Nghĩa, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30091",
        "parent_code": "873"
    },
    "30094": {
        "name": "Phương Trà",
        "type": "xa",
        "slug": "phuong-tra",
        "name_with_type": "Xã Phương Trà",
        "path": "Phương Trà, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Phương Trà, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30094",
        "parent_code": "873"
    },
    "30097": {
        "name": "Nhị Mỹ",
        "type": "xa",
        "slug": "nhi-my",
        "name_with_type": "Xã Nhị Mỹ",
        "path": "Nhị Mỹ, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Nhị Mỹ, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30097",
        "parent_code": "873"
    },
    "30100": {
        "name": "Mỹ Thọ",
        "type": "xa",
        "slug": "my-tho",
        "name_with_type": "Xã Mỹ Thọ",
        "path": "Mỹ Thọ, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Mỹ Thọ, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30100",
        "parent_code": "873"
    },
    "30103": {
        "name": "Tân Hội Trung",
        "type": "xa",
        "slug": "tan-hoi-trung",
        "name_with_type": "Xã Tân Hội Trung",
        "path": "Tân Hội Trung, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Tân Hội Trung, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30103",
        "parent_code": "873"
    },
    "30106": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã An Bình, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30106",
        "parent_code": "873"
    },
    "30109": {
        "name": "Mỹ Hội",
        "type": "xa",
        "slug": "my-hoi",
        "name_with_type": "Xã Mỹ Hội",
        "path": "Mỹ Hội, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Mỹ Hội, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30109",
        "parent_code": "873"
    },
    "30112": {
        "name": "Mỹ Hiệp",
        "type": "xa",
        "slug": "my-hiep",
        "name_with_type": "Xã Mỹ Hiệp",
        "path": "Mỹ Hiệp, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Mỹ Hiệp, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30112",
        "parent_code": "873"
    },
    "30115": {
        "name": "Mỹ Long",
        "type": "xa",
        "slug": "my-long",
        "name_with_type": "Xã Mỹ Long",
        "path": "Mỹ Long, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Mỹ Long, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30115",
        "parent_code": "873"
    },
    "30118": {
        "name": "Bình Hàng Trung",
        "type": "xa",
        "slug": "binh-hang-trung",
        "name_with_type": "Xã Bình Hàng Trung",
        "path": "Bình Hàng Trung, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Bình Hàng Trung, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30118",
        "parent_code": "873"
    },
    "30121": {
        "name": "Mỹ Xương",
        "type": "xa",
        "slug": "my-xuong",
        "name_with_type": "Xã Mỹ Xương",
        "path": "Mỹ Xương, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Mỹ Xương, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30121",
        "parent_code": "873"
    },
    "30124": {
        "name": "Bình Hàng Tây",
        "type": "xa",
        "slug": "binh-hang-tay",
        "name_with_type": "Xã Bình Hàng Tây",
        "path": "Bình Hàng Tây, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Bình Hàng Tây, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30124",
        "parent_code": "873"
    },
    "30127": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Cao Lãnh, Đồng Tháp",
        "path_with_type": "Xã Bình Thạnh, Huyện Cao Lãnh, Tỉnh Đồng Tháp",
        "code": "30127",
        "parent_code": "873"
    },
    "30130": {
        "name": "Thanh Bình",
        "type": "thi-tran",
        "slug": "thanh-binh",
        "name_with_type": "Thị trấn Thanh Bình",
        "path": "Thanh Bình, Thanh Bình, Đồng Tháp",
        "path_with_type": "Thị trấn Thanh Bình, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30130",
        "parent_code": "874"
    },
    "30133": {
        "name": "Tân Quới",
        "type": "xa",
        "slug": "tan-quoi",
        "name_with_type": "Xã Tân Quới",
        "path": "Tân Quới, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Tân Quới, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30133",
        "parent_code": "874"
    },
    "30136": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Tân Hòa, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30136",
        "parent_code": "874"
    },
    "30139": {
        "name": "An Phong",
        "type": "xa",
        "slug": "an-phong",
        "name_with_type": "Xã An Phong",
        "path": "An Phong, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã An Phong, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30139",
        "parent_code": "874"
    },
    "30142": {
        "name": "Phú Lợi",
        "type": "xa",
        "slug": "phu-loi",
        "name_with_type": "Xã Phú Lợi",
        "path": "Phú Lợi, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Phú Lợi, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30142",
        "parent_code": "874"
    },
    "30145": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Tân Mỹ, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30145",
        "parent_code": "874"
    },
    "30148": {
        "name": "Bình Tấn",
        "type": "xa",
        "slug": "binh-tan",
        "name_with_type": "Xã Bình Tấn",
        "path": "Bình Tấn, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Bình Tấn, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30148",
        "parent_code": "874"
    },
    "30151": {
        "name": "Tân Huề",
        "type": "xa",
        "slug": "tan-hue",
        "name_with_type": "Xã Tân Huề",
        "path": "Tân Huề, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Tân Huề, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30151",
        "parent_code": "874"
    },
    "30154": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Tân Bình, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30154",
        "parent_code": "874"
    },
    "30157": {
        "name": "Tân Thạnh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thạnh",
        "path": "Tân Thạnh, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Tân Thạnh, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30157",
        "parent_code": "874"
    },
    "30160": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Tân Phú, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30160",
        "parent_code": "874"
    },
    "30163": {
        "name": "Bình Thành",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thành",
        "path": "Bình Thành, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Bình Thành, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30163",
        "parent_code": "874"
    },
    "30166": {
        "name": "Tân Long",
        "type": "xa",
        "slug": "tan-long",
        "name_with_type": "Xã Tân Long",
        "path": "Tân Long, Thanh Bình, Đồng Tháp",
        "path_with_type": "Xã Tân Long, Huyện Thanh Bình, Tỉnh Đồng Tháp",
        "code": "30166",
        "parent_code": "874"
    },
    "30169": {
        "name": "Lấp Vò",
        "type": "thi-tran",
        "slug": "lap-vo",
        "name_with_type": "Thị trấn Lấp Vò",
        "path": "Lấp Vò, Lấp Vò, Đồng Tháp",
        "path_with_type": "Thị trấn Lấp Vò, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30169",
        "parent_code": "875"
    },
    "30172": {
        "name": "Mỹ An Hưng A",
        "type": "xa",
        "slug": "my-an-hung-a",
        "name_with_type": "Xã Mỹ An Hưng A",
        "path": "Mỹ An Hưng A, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Mỹ An Hưng A, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30172",
        "parent_code": "875"
    },
    "30175": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Tân Mỹ, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30175",
        "parent_code": "875"
    },
    "30178": {
        "name": "Mỹ An Hưng B",
        "type": "xa",
        "slug": "my-an-hung-b",
        "name_with_type": "Xã Mỹ An Hưng B",
        "path": "Mỹ An Hưng B, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Mỹ An Hưng B, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30178",
        "parent_code": "875"
    },
    "30181": {
        "name": "Tân  Khánh Trung",
        "type": "xa",
        "slug": "tan-khanh-trung",
        "name_with_type": "Xã Tân  Khánh Trung",
        "path": "Tân  Khánh Trung, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Tân  Khánh Trung, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30181",
        "parent_code": "875"
    },
    "30184": {
        "name": "Long Hưng A",
        "type": "xa",
        "slug": "long-hung-a",
        "name_with_type": "Xã Long Hưng A",
        "path": "Long Hưng A, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Long Hưng A, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30184",
        "parent_code": "875"
    },
    "30187": {
        "name": "Vĩnh Thạnh",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thạnh",
        "path": "Vĩnh Thạnh, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Vĩnh Thạnh, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30187",
        "parent_code": "875"
    },
    "30190": {
        "name": "Long Hưng B",
        "type": "xa",
        "slug": "long-hung-b",
        "name_with_type": "Xã Long Hưng B",
        "path": "Long Hưng B, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Long Hưng B, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30190",
        "parent_code": "875"
    },
    "30193": {
        "name": "Bình Thành",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thành",
        "path": "Bình Thành, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Bình Thành, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30193",
        "parent_code": "875"
    },
    "30196": {
        "name": "Định An",
        "type": "xa",
        "slug": "dinh-an",
        "name_with_type": "Xã Định An",
        "path": "Định An, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Định An, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30196",
        "parent_code": "875"
    },
    "30199": {
        "name": "Định Yên",
        "type": "xa",
        "slug": "dinh-yen",
        "name_with_type": "Xã Định Yên",
        "path": "Định Yên, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Định Yên, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30199",
        "parent_code": "875"
    },
    "30202": {
        "name": "Hội An Đông",
        "type": "xa",
        "slug": "hoi-an-dong",
        "name_with_type": "Xã Hội An Đông",
        "path": "Hội An Đông, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Hội An Đông, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30202",
        "parent_code": "875"
    },
    "30205": {
        "name": "Bình Thạnh Trung",
        "type": "xa",
        "slug": "binh-thanh-trung",
        "name_with_type": "Xã Bình Thạnh Trung",
        "path": "Bình Thạnh Trung, Lấp Vò, Đồng Tháp",
        "path_with_type": "Xã Bình Thạnh Trung, Huyện Lấp Vò, Tỉnh Đồng Tháp",
        "code": "30205",
        "parent_code": "875"
    },
    "30208": {
        "name": "Lai Vung",
        "type": "thi-tran",
        "slug": "lai-vung",
        "name_with_type": "Thị trấn Lai Vung",
        "path": "Lai Vung, Lai Vung, Đồng Tháp",
        "path_with_type": "Thị trấn Lai Vung, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30208",
        "parent_code": "876"
    },
    "30211": {
        "name": "Tân Dương",
        "type": "xa",
        "slug": "tan-duong",
        "name_with_type": "Xã Tân Dương",
        "path": "Tân Dương, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Tân Dương, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30211",
        "parent_code": "876"
    },
    "30214": {
        "name": "Hòa Thành",
        "type": "xa",
        "slug": "hoa-thanh",
        "name_with_type": "Xã Hòa Thành",
        "path": "Hòa Thành, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Hòa Thành, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30214",
        "parent_code": "876"
    },
    "30217": {
        "name": "Long Hậu",
        "type": "xa",
        "slug": "long-hau",
        "name_with_type": "Xã Long Hậu",
        "path": "Long Hậu, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Long Hậu, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30217",
        "parent_code": "876"
    },
    "30220": {
        "name": "Tân Phước",
        "type": "xa",
        "slug": "tan-phuoc",
        "name_with_type": "Xã Tân Phước",
        "path": "Tân Phước, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Tân Phước, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30220",
        "parent_code": "876"
    },
    "30223": {
        "name": "Hòa Long",
        "type": "xa",
        "slug": "hoa-long",
        "name_with_type": "Xã Hòa Long",
        "path": "Hòa Long, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Hòa Long, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30223",
        "parent_code": "876"
    },
    "30226": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Tân Thành, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30226",
        "parent_code": "876"
    },
    "30229": {
        "name": "Long Thắng",
        "type": "xa",
        "slug": "long-thang",
        "name_with_type": "Xã Long Thắng",
        "path": "Long Thắng, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Long Thắng, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30229",
        "parent_code": "876"
    },
    "30232": {
        "name": "Vĩnh Thới",
        "type": "xa",
        "slug": "vinh-thoi",
        "name_with_type": "Xã Vĩnh Thới",
        "path": "Vĩnh Thới, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Vĩnh Thới, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30232",
        "parent_code": "876"
    },
    "30235": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Tân Hòa, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30235",
        "parent_code": "876"
    },
    "30238": {
        "name": "Định Hòa",
        "type": "xa",
        "slug": "dinh-hoa",
        "name_with_type": "Xã Định Hòa",
        "path": "Định Hòa, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Định Hòa, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30238",
        "parent_code": "876"
    },
    "30241": {
        "name": "Phong Hòa",
        "type": "xa",
        "slug": "phong-hoa",
        "name_with_type": "Xã Phong Hòa",
        "path": "Phong Hòa, Lai Vung, Đồng Tháp",
        "path_with_type": "Xã Phong Hòa, Huyện Lai Vung, Tỉnh Đồng Tháp",
        "code": "30241",
        "parent_code": "876"
    },
    "30244": {
        "name": "Cái Tàu Hạ",
        "type": "thi-tran",
        "slug": "cai-tau-ha",
        "name_with_type": "Thị trấn Cái Tàu Hạ",
        "path": "Cái Tàu Hạ, Châu Thành, Đồng Tháp",
        "path_with_type": "Thị trấn Cái Tàu Hạ, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30244",
        "parent_code": "877"
    },
    "30247": {
        "name": "An Hiệp",
        "type": "xa",
        "slug": "an-hiep",
        "name_with_type": "Xã An Hiệp",
        "path": "An Hiệp, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã An Hiệp, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30247",
        "parent_code": "877"
    },
    "30250": {
        "name": "An Nhơn",
        "type": "xa",
        "slug": "an-nhon",
        "name_with_type": "Xã An Nhơn",
        "path": "An Nhơn, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã An Nhơn, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30250",
        "parent_code": "877"
    },
    "30253": {
        "name": "Tân Nhuận Đông",
        "type": "xa",
        "slug": "tan-nhuan-dong",
        "name_with_type": "Xã Tân Nhuận Đông",
        "path": "Tân Nhuận Đông, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã Tân Nhuận Đông, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30253",
        "parent_code": "877"
    },
    "30256": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã Tân Bình, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30256",
        "parent_code": "877"
    },
    "30259": {
        "name": "Tân Phú Trung",
        "type": "xa",
        "slug": "tan-phu-trung",
        "name_with_type": "Xã Tân Phú Trung",
        "path": "Tân Phú Trung, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã Tân Phú Trung, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30259",
        "parent_code": "877"
    },
    "30262": {
        "name": "Phú Long",
        "type": "xa",
        "slug": "phu-long",
        "name_with_type": "Xã Phú Long",
        "path": "Phú Long, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã Phú Long, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30262",
        "parent_code": "877"
    },
    "30265": {
        "name": "An Phú Thuận",
        "type": "xa",
        "slug": "an-phu-thuan",
        "name_with_type": "Xã An Phú Thuận",
        "path": "An Phú Thuận, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã An Phú Thuận, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30265",
        "parent_code": "877"
    },
    "30268": {
        "name": "Phú Hựu",
        "type": "xa",
        "slug": "phu-huu",
        "name_with_type": "Xã Phú Hựu",
        "path": "Phú Hựu, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã Phú Hựu, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30268",
        "parent_code": "877"
    },
    "30271": {
        "name": "An Khánh",
        "type": "xa",
        "slug": "an-khanh",
        "name_with_type": "Xã An Khánh",
        "path": "An Khánh, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã An Khánh, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30271",
        "parent_code": "877"
    },
    "30274": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã Tân Phú, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30274",
        "parent_code": "877"
    },
    "30277": {
        "name": "Hòa Tân",
        "type": "xa",
        "slug": "hoa-tan",
        "name_with_type": "Xã Hòa Tân",
        "path": "Hòa Tân, Châu Thành, Đồng Tháp",
        "path_with_type": "Xã Hòa Tân, Huyện Châu Thành, Tỉnh Đồng Tháp",
        "code": "30277",
        "parent_code": "877"
    },
    "27685": {
        "name": "5",
        "type": "phuong",
        "slug": "5",
        "name_with_type": "Phường 5",
        "path": "5, Tân An, Long An",
        "path_with_type": "Phường 5, Thành phố Tân An, Tỉnh Long An",
        "code": "27685",
        "parent_code": "794"
    },
    "27688": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Tân An, Long An",
        "path_with_type": "Phường 2, Thành phố Tân An, Tỉnh Long An",
        "code": "27688",
        "parent_code": "794"
    },
    "27691": {
        "name": "4",
        "type": "phuong",
        "slug": "4",
        "name_with_type": "Phường 4",
        "path": "4, Tân An, Long An",
        "path_with_type": "Phường 4, Thành phố Tân An, Tỉnh Long An",
        "code": "27691",
        "parent_code": "794"
    },
    "27692": {
        "name": "Tân Khánh",
        "type": "phuong",
        "slug": "tan-khanh",
        "name_with_type": "Phường Tân Khánh",
        "path": "Tân Khánh, Tân An, Long An",
        "path_with_type": "Phường Tân Khánh, Thành phố Tân An, Tỉnh Long An",
        "code": "27692",
        "parent_code": "794"
    },
    "27694": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Tân An, Long An",
        "path_with_type": "Phường 1, Thành phố Tân An, Tỉnh Long An",
        "code": "27694",
        "parent_code": "794"
    },
    "27697": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Tân An, Long An",
        "path_with_type": "Phường 3, Thành phố Tân An, Tỉnh Long An",
        "code": "27697",
        "parent_code": "794"
    },
    "27698": {
        "name": "7",
        "type": "phuong",
        "slug": "7",
        "name_with_type": "Phường 7",
        "path": "7, Tân An, Long An",
        "path_with_type": "Phường 7, Thành phố Tân An, Tỉnh Long An",
        "code": "27698",
        "parent_code": "794"
    },
    "27700": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Tân An, Long An",
        "path_with_type": "Phường 6, Thành phố Tân An, Tỉnh Long An",
        "code": "27700",
        "parent_code": "794"
    },
    "27703": {
        "name": "Hướng Thọ Phú",
        "type": "xa",
        "slug": "huong-tho-phu",
        "name_with_type": "Xã Hướng Thọ Phú",
        "path": "Hướng Thọ Phú, Tân An, Long An",
        "path_with_type": "Xã Hướng Thọ Phú, Thành phố Tân An, Tỉnh Long An",
        "code": "27703",
        "parent_code": "794"
    },
    "27706": {
        "name": "Nhơn Thạnh Trung",
        "type": "xa",
        "slug": "nhon-thanh-trung",
        "name_with_type": "Xã Nhơn Thạnh Trung",
        "path": "Nhơn Thạnh Trung, Tân An, Long An",
        "path_with_type": "Xã Nhơn Thạnh Trung, Thành phố Tân An, Tỉnh Long An",
        "code": "27706",
        "parent_code": "794"
    },
    "27709": {
        "name": "Lợi Bình Nhơn",
        "type": "xa",
        "slug": "loi-binh-nhon",
        "name_with_type": "Xã Lợi Bình Nhơn",
        "path": "Lợi Bình Nhơn, Tân An, Long An",
        "path_with_type": "Xã Lợi Bình Nhơn, Thành phố Tân An, Tỉnh Long An",
        "code": "27709",
        "parent_code": "794"
    },
    "27712": {
        "name": "Bình Tâm",
        "type": "xa",
        "slug": "binh-tam",
        "name_with_type": "Xã Bình Tâm",
        "path": "Bình Tâm, Tân An, Long An",
        "path_with_type": "Xã Bình Tâm, Thành phố Tân An, Tỉnh Long An",
        "code": "27712",
        "parent_code": "794"
    },
    "27715": {
        "name": "Khánh Hậu",
        "type": "phuong",
        "slug": "khanh-hau",
        "name_with_type": "Phường Khánh Hậu",
        "path": "Khánh Hậu, Tân An, Long An",
        "path_with_type": "Phường Khánh Hậu, Thành phố Tân An, Tỉnh Long An",
        "code": "27715",
        "parent_code": "794"
    },
    "27718": {
        "name": "An Vĩnh Ngãi",
        "type": "xa",
        "slug": "an-vinh-ngai",
        "name_with_type": "Xã An Vĩnh Ngãi",
        "path": "An Vĩnh Ngãi, Tân An, Long An",
        "path_with_type": "Xã An Vĩnh Ngãi, Thành phố Tân An, Tỉnh Long An",
        "code": "27718",
        "parent_code": "794"
    },
    "27787": {
        "name": "1",
        "type": "phuong",
        "slug": "1",
        "name_with_type": "Phường 1",
        "path": "1, Kiến Tường, Long An",
        "path_with_type": "Phường 1, Thị xã Kiến Tường, Tỉnh Long An",
        "code": "27787",
        "parent_code": "795"
    },
    "27788": {
        "name": "2",
        "type": "phuong",
        "slug": "2",
        "name_with_type": "Phường 2",
        "path": "2, Kiến Tường, Long An",
        "path_with_type": "Phường 2, Thị xã Kiến Tường, Tỉnh Long An",
        "code": "27788",
        "parent_code": "795"
    },
    "27790": {
        "name": "Thạnh Trị",
        "type": "xa",
        "slug": "thanh-tri",
        "name_with_type": "Xã Thạnh Trị",
        "path": "Thạnh Trị, Kiến Tường, Long An",
        "path_with_type": "Xã Thạnh Trị, Thị xã Kiến Tường, Tỉnh Long An",
        "code": "27790",
        "parent_code": "795"
    },
    "27793": {
        "name": "Bình Hiệp",
        "type": "xa",
        "slug": "binh-hiep",
        "name_with_type": "Xã Bình Hiệp",
        "path": "Bình Hiệp, Kiến Tường, Long An",
        "path_with_type": "Xã Bình Hiệp, Thị xã Kiến Tường, Tỉnh Long An",
        "code": "27793",
        "parent_code": "795"
    },
    "27799": {
        "name": "Bình Tân",
        "type": "xa",
        "slug": "binh-tan",
        "name_with_type": "Xã Bình Tân",
        "path": "Bình Tân, Kiến Tường, Long An",
        "path_with_type": "Xã Bình Tân, Thị xã Kiến Tường, Tỉnh Long An",
        "code": "27799",
        "parent_code": "795"
    },
    "27805": {
        "name": "Tuyên Thạnh",
        "type": "xa",
        "slug": "tuyen-thanh",
        "name_with_type": "Xã Tuyên Thạnh",
        "path": "Tuyên Thạnh, Kiến Tường, Long An",
        "path_with_type": "Xã Tuyên Thạnh, Thị xã Kiến Tường, Tỉnh Long An",
        "code": "27805",
        "parent_code": "795"
    },
    "27806": {
        "name": "3",
        "type": "phuong",
        "slug": "3",
        "name_with_type": "Phường 3",
        "path": "3, Kiến Tường, Long An",
        "path_with_type": "Phường 3, Thị xã Kiến Tường, Tỉnh Long An",
        "code": "27806",
        "parent_code": "795"
    },
    "27817": {
        "name": "Thạnh Hưng",
        "type": "xa",
        "slug": "thanh-hung",
        "name_with_type": "Xã Thạnh Hưng",
        "path": "Thạnh Hưng, Kiến Tường, Long An",
        "path_with_type": "Xã Thạnh Hưng, Thị xã Kiến Tường, Tỉnh Long An",
        "code": "27817",
        "parent_code": "795"
    },
    "27721": {
        "name": "Tân Hưng",
        "type": "thi-tran",
        "slug": "tan-hung",
        "name_with_type": "Thị trấn Tân Hưng",
        "path": "Tân Hưng, Tân Hưng, Long An",
        "path_with_type": "Thị trấn Tân Hưng, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27721",
        "parent_code": "796"
    },
    "27724": {
        "name": "Hưng Hà",
        "type": "xa",
        "slug": "hung-ha",
        "name_with_type": "Xã Hưng Hà",
        "path": "Hưng Hà, Tân Hưng, Long An",
        "path_with_type": "Xã Hưng Hà, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27724",
        "parent_code": "796"
    },
    "27727": {
        "name": "Hưng Điền B",
        "type": "xa",
        "slug": "hung-dien-b",
        "name_with_type": "Xã Hưng Điền B",
        "path": "Hưng Điền B, Tân Hưng, Long An",
        "path_with_type": "Xã Hưng Điền B, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27727",
        "parent_code": "796"
    },
    "27730": {
        "name": "Hưng Điền",
        "type": "xa",
        "slug": "hung-dien",
        "name_with_type": "Xã Hưng Điền",
        "path": "Hưng Điền, Tân Hưng, Long An",
        "path_with_type": "Xã Hưng Điền, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27730",
        "parent_code": "796"
    },
    "27733": {
        "name": "Thạnh Hưng",
        "type": "xa",
        "slug": "thanh-hung",
        "name_with_type": "Xã Thạnh Hưng",
        "path": "Thạnh Hưng, Tân Hưng, Long An",
        "path_with_type": "Xã Thạnh Hưng, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27733",
        "parent_code": "796"
    },
    "27736": {
        "name": "Hưng Thạnh",
        "type": "xa",
        "slug": "hung-thanh",
        "name_with_type": "Xã Hưng Thạnh",
        "path": "Hưng Thạnh, Tân Hưng, Long An",
        "path_with_type": "Xã Hưng Thạnh, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27736",
        "parent_code": "796"
    },
    "27739": {
        "name": "Vĩnh Thạnh",
        "type": "xa",
        "slug": "vinh-thanh",
        "name_with_type": "Xã Vĩnh Thạnh",
        "path": "Vĩnh Thạnh, Tân Hưng, Long An",
        "path_with_type": "Xã Vĩnh Thạnh, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27739",
        "parent_code": "796"
    },
    "27742": {
        "name": "Vĩnh Châu B",
        "type": "xa",
        "slug": "vinh-chau-b",
        "name_with_type": "Xã Vĩnh Châu B",
        "path": "Vĩnh Châu B, Tân Hưng, Long An",
        "path_with_type": "Xã Vĩnh Châu B, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27742",
        "parent_code": "796"
    },
    "27745": {
        "name": "Vĩnh Lợi",
        "type": "xa",
        "slug": "vinh-loi",
        "name_with_type": "Xã Vĩnh Lợi",
        "path": "Vĩnh Lợi, Tân Hưng, Long An",
        "path_with_type": "Xã Vĩnh Lợi, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27745",
        "parent_code": "796"
    },
    "27748": {
        "name": "Vĩnh Đại",
        "type": "xa",
        "slug": "vinh-dai",
        "name_with_type": "Xã Vĩnh Đại",
        "path": "Vĩnh Đại, Tân Hưng, Long An",
        "path_with_type": "Xã Vĩnh Đại, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27748",
        "parent_code": "796"
    },
    "27751": {
        "name": "Vĩnh Châu A",
        "type": "xa",
        "slug": "vinh-chau-a",
        "name_with_type": "Xã Vĩnh Châu A",
        "path": "Vĩnh Châu A, Tân Hưng, Long An",
        "path_with_type": "Xã Vĩnh Châu A, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27751",
        "parent_code": "796"
    },
    "27754": {
        "name": "Vĩnh Bửu",
        "type": "xa",
        "slug": "vinh-buu",
        "name_with_type": "Xã Vĩnh Bửu",
        "path": "Vĩnh Bửu, Tân Hưng, Long An",
        "path_with_type": "Xã Vĩnh Bửu, Huyện Tân Hưng, Tỉnh Long An",
        "code": "27754",
        "parent_code": "796"
    },
    "27757": {
        "name": "Vĩnh Hưng",
        "type": "thi-tran",
        "slug": "vinh-hung",
        "name_with_type": "Thị trấn Vĩnh Hưng",
        "path": "Vĩnh Hưng, Vĩnh Hưng, Long An",
        "path_with_type": "Thị trấn Vĩnh Hưng, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27757",
        "parent_code": "797"
    },
    "27760": {
        "name": "Hưng Điền A",
        "type": "xa",
        "slug": "hung-dien-a",
        "name_with_type": "Xã Hưng Điền A",
        "path": "Hưng Điền A, Vĩnh Hưng, Long An",
        "path_with_type": "Xã Hưng Điền A, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27760",
        "parent_code": "797"
    },
    "27763": {
        "name": "Khánh Hưng",
        "type": "xa",
        "slug": "khanh-hung",
        "name_with_type": "Xã Khánh Hưng",
        "path": "Khánh Hưng, Vĩnh Hưng, Long An",
        "path_with_type": "Xã Khánh Hưng, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27763",
        "parent_code": "797"
    },
    "27766": {
        "name": "Thái Trị",
        "type": "xa",
        "slug": "thai-tri",
        "name_with_type": "Xã Thái Trị",
        "path": "Thái Trị, Vĩnh Hưng, Long An",
        "path_with_type": "Xã Thái Trị, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27766",
        "parent_code": "797"
    },
    "27769": {
        "name": "Vĩnh Trị",
        "type": "xa",
        "slug": "vinh-tri",
        "name_with_type": "Xã Vĩnh Trị",
        "path": "Vĩnh Trị, Vĩnh Hưng, Long An",
        "path_with_type": "Xã Vĩnh Trị, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27769",
        "parent_code": "797"
    },
    "27772": {
        "name": "Thái Bình Trung",
        "type": "xa",
        "slug": "thai-binh-trung",
        "name_with_type": "Xã Thái Bình Trung",
        "path": "Thái Bình Trung, Vĩnh Hưng, Long An",
        "path_with_type": "Xã Thái Bình Trung, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27772",
        "parent_code": "797"
    },
    "27775": {
        "name": "Vĩnh Bình",
        "type": "xa",
        "slug": "vinh-binh",
        "name_with_type": "Xã Vĩnh Bình",
        "path": "Vĩnh Bình, Vĩnh Hưng, Long An",
        "path_with_type": "Xã Vĩnh Bình, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27775",
        "parent_code": "797"
    },
    "27778": {
        "name": "Vĩnh Thuận",
        "type": "xa",
        "slug": "vinh-thuan",
        "name_with_type": "Xã Vĩnh Thuận",
        "path": "Vĩnh Thuận, Vĩnh Hưng, Long An",
        "path_with_type": "Xã Vĩnh Thuận, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27778",
        "parent_code": "797"
    },
    "27781": {
        "name": "Tuyên Bình",
        "type": "xa",
        "slug": "tuyen-binh",
        "name_with_type": "Xã Tuyên Bình",
        "path": "Tuyên Bình, Vĩnh Hưng, Long An",
        "path_with_type": "Xã Tuyên Bình, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27781",
        "parent_code": "797"
    },
    "27784": {
        "name": "Tuyên Bình Tây",
        "type": "xa",
        "slug": "tuyen-binh-tay",
        "name_with_type": "Xã Tuyên Bình Tây",
        "path": "Tuyên Bình Tây, Vĩnh Hưng, Long An",
        "path_with_type": "Xã Tuyên Bình Tây, Huyện Vĩnh Hưng, Tỉnh Long An",
        "code": "27784",
        "parent_code": "797"
    },
    "27796": {
        "name": "Bình Hòa Tây",
        "type": "xa",
        "slug": "binh-hoa-tay",
        "name_with_type": "Xã Bình Hòa Tây",
        "path": "Bình Hòa Tây, Mộc Hóa, Long An",
        "path_with_type": "Xã Bình Hòa Tây, Huyện Mộc Hóa, Tỉnh Long An",
        "code": "27796",
        "parent_code": "798"
    },
    "27802": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Mộc Hóa, Long An",
        "path_with_type": "Xã Bình Thạnh, Huyện Mộc Hóa, Tỉnh Long An",
        "code": "27802",
        "parent_code": "798"
    },
    "27808": {
        "name": "Bình Hòa Trung",
        "type": "xa",
        "slug": "binh-hoa-trung",
        "name_with_type": "Xã Bình Hòa Trung",
        "path": "Bình Hòa Trung, Mộc Hóa, Long An",
        "path_with_type": "Xã Bình Hòa Trung, Huyện Mộc Hóa, Tỉnh Long An",
        "code": "27808",
        "parent_code": "798"
    },
    "27811": {
        "name": "Bình Hòa Đông",
        "type": "xa",
        "slug": "binh-hoa-dong",
        "name_with_type": "Xã Bình Hòa Đông",
        "path": "Bình Hòa Đông, Mộc Hóa, Long An",
        "path_with_type": "Xã Bình Hòa Đông, Huyện Mộc Hóa, Tỉnh Long An",
        "code": "27811",
        "parent_code": "798"
    },
    "27814": {
        "name": "Bình Phong Thạnh",
        "type": "xa",
        "slug": "binh-phong-thanh",
        "name_with_type": "Xã Bình Phong Thạnh",
        "path": "Bình Phong Thạnh, Mộc Hóa, Long An",
        "path_with_type": "Xã Bình Phong Thạnh, Huyện Mộc Hóa, Tỉnh Long An",
        "code": "27814",
        "parent_code": "798"
    },
    "27820": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Mộc Hóa, Long An",
        "path_with_type": "Xã Tân Lập, Huyện Mộc Hóa, Tỉnh Long An",
        "code": "27820",
        "parent_code": "798"
    },
    "27823": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Mộc Hóa, Long An",
        "path_with_type": "Xã Tân Thành, Huyện Mộc Hóa, Tỉnh Long An",
        "code": "27823",
        "parent_code": "798"
    },
    "27826": {
        "name": "Tân Thạnh",
        "type": "thi-tran",
        "slug": "tan-thanh",
        "name_with_type": "Thị trấn Tân Thạnh",
        "path": "Tân Thạnh, Tân Thạnh, Long An",
        "path_with_type": "Thị trấn Tân Thạnh, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27826",
        "parent_code": "799"
    },
    "27829": {
        "name": "Bắc Hòa",
        "type": "xa",
        "slug": "bac-hoa",
        "name_with_type": "Xã Bắc Hòa",
        "path": "Bắc Hòa, Tân Thạnh, Long An",
        "path_with_type": "Xã Bắc Hòa, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27829",
        "parent_code": "799"
    },
    "27832": {
        "name": "Hậu Thạnh Tây",
        "type": "xa",
        "slug": "hau-thanh-tay",
        "name_with_type": "Xã Hậu Thạnh Tây",
        "path": "Hậu Thạnh Tây, Tân Thạnh, Long An",
        "path_with_type": "Xã Hậu Thạnh Tây, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27832",
        "parent_code": "799"
    },
    "27835": {
        "name": "Nhơn Hòa Lập",
        "type": "xa",
        "slug": "nhon-hoa-lap",
        "name_with_type": "Xã Nhơn Hòa Lập",
        "path": "Nhơn Hòa Lập, Tân Thạnh, Long An",
        "path_with_type": "Xã Nhơn Hòa Lập, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27835",
        "parent_code": "799"
    },
    "27838": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Tân Thạnh, Long An",
        "path_with_type": "Xã Tân Lập, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27838",
        "parent_code": "799"
    },
    "27841": {
        "name": "Hậu Thạnh Đông",
        "type": "xa",
        "slug": "hau-thanh-dong",
        "name_with_type": "Xã Hậu Thạnh Đông",
        "path": "Hậu Thạnh Đông, Tân Thạnh, Long An",
        "path_with_type": "Xã Hậu Thạnh Đông, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27841",
        "parent_code": "799"
    },
    "27844": {
        "name": "Nhơn Hoà",
        "type": "xa",
        "slug": "nhon-hoa",
        "name_with_type": "Xã Nhơn Hoà",
        "path": "Nhơn Hoà, Tân Thạnh, Long An",
        "path_with_type": "Xã Nhơn Hoà, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27844",
        "parent_code": "799"
    },
    "27847": {
        "name": "Kiến Bình",
        "type": "xa",
        "slug": "kien-binh",
        "name_with_type": "Xã Kiến Bình",
        "path": "Kiến Bình, Tân Thạnh, Long An",
        "path_with_type": "Xã Kiến Bình, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27847",
        "parent_code": "799"
    },
    "27850": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Tân Thạnh, Long An",
        "path_with_type": "Xã Tân Thành, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27850",
        "parent_code": "799"
    },
    "27853": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Tân Thạnh, Long An",
        "path_with_type": "Xã Tân Bình, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27853",
        "parent_code": "799"
    },
    "27856": {
        "name": "Tân Ninh",
        "type": "xa",
        "slug": "tan-ninh",
        "name_with_type": "Xã Tân Ninh",
        "path": "Tân Ninh, Tân Thạnh, Long An",
        "path_with_type": "Xã Tân Ninh, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27856",
        "parent_code": "799"
    },
    "27859": {
        "name": "Nhơn Ninh",
        "type": "xa",
        "slug": "nhon-ninh",
        "name_with_type": "Xã Nhơn Ninh",
        "path": "Nhơn Ninh, Tân Thạnh, Long An",
        "path_with_type": "Xã Nhơn Ninh, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27859",
        "parent_code": "799"
    },
    "27862": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Tân Thạnh, Long An",
        "path_with_type": "Xã Tân Hòa, Huyện Tân Thạnh, Tỉnh Long An",
        "code": "27862",
        "parent_code": "799"
    },
    "27865": {
        "name": "Thạnh Hóa",
        "type": "thi-tran",
        "slug": "thanh-hoa",
        "name_with_type": "Thị trấn Thạnh Hóa",
        "path": "Thạnh Hóa, Thạnh Hóa, Long An",
        "path_with_type": "Thị trấn Thạnh Hóa, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27865",
        "parent_code": "800"
    },
    "27868": {
        "name": "Tân Hiệp",
        "type": "xa",
        "slug": "tan-hiep",
        "name_with_type": "Xã Tân Hiệp",
        "path": "Tân Hiệp, Thạnh Hóa, Long An",
        "path_with_type": "Xã Tân Hiệp, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27868",
        "parent_code": "800"
    },
    "27871": {
        "name": "Thuận Bình",
        "type": "xa",
        "slug": "thuan-binh",
        "name_with_type": "Xã Thuận Bình",
        "path": "Thuận Bình, Thạnh Hóa, Long An",
        "path_with_type": "Xã Thuận Bình, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27871",
        "parent_code": "800"
    },
    "27874": {
        "name": "Thạnh Phước",
        "type": "xa",
        "slug": "thanh-phuoc",
        "name_with_type": "Xã Thạnh Phước",
        "path": "Thạnh Phước, Thạnh Hóa, Long An",
        "path_with_type": "Xã Thạnh Phước, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27874",
        "parent_code": "800"
    },
    "27877": {
        "name": "Thạnh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thạnh Phú",
        "path": "Thạnh Phú, Thạnh Hóa, Long An",
        "path_with_type": "Xã Thạnh Phú, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27877",
        "parent_code": "800"
    },
    "27880": {
        "name": "Thuận Nghĩa Hòa",
        "type": "xa",
        "slug": "thuan-nghia-hoa",
        "name_with_type": "Xã Thuận Nghĩa Hòa",
        "path": "Thuận Nghĩa Hòa, Thạnh Hóa, Long An",
        "path_with_type": "Xã Thuận Nghĩa Hòa, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27880",
        "parent_code": "800"
    },
    "27883": {
        "name": "Thủy Đông",
        "type": "xa",
        "slug": "thuy-dong",
        "name_with_type": "Xã Thủy Đông",
        "path": "Thủy Đông, Thạnh Hóa, Long An",
        "path_with_type": "Xã Thủy Đông, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27883",
        "parent_code": "800"
    },
    "27886": {
        "name": "Thủy Tây",
        "type": "xa",
        "slug": "thuy-tay",
        "name_with_type": "Xã Thủy Tây",
        "path": "Thủy Tây, Thạnh Hóa, Long An",
        "path_with_type": "Xã Thủy Tây, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27886",
        "parent_code": "800"
    },
    "27889": {
        "name": "Tân Tây",
        "type": "xa",
        "slug": "tan-tay",
        "name_with_type": "Xã Tân Tây",
        "path": "Tân Tây, Thạnh Hóa, Long An",
        "path_with_type": "Xã Tân Tây, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27889",
        "parent_code": "800"
    },
    "27892": {
        "name": "Tân Đông",
        "type": "xa",
        "slug": "tan-dong",
        "name_with_type": "Xã Tân Đông",
        "path": "Tân Đông, Thạnh Hóa, Long An",
        "path_with_type": "Xã Tân Đông, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27892",
        "parent_code": "800"
    },
    "27895": {
        "name": "Thạnh An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thạnh An",
        "path": "Thạnh An, Thạnh Hóa, Long An",
        "path_with_type": "Xã Thạnh An, Huyện Thạnh Hóa, Tỉnh Long An",
        "code": "27895",
        "parent_code": "800"
    },
    "27898": {
        "name": "Đông Thành",
        "type": "thi-tran",
        "slug": "dong-thanh",
        "name_with_type": "Thị trấn Đông Thành",
        "path": "Đông Thành, Đức Huệ, Long An",
        "path_with_type": "Thị trấn Đông Thành, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27898",
        "parent_code": "801"
    },
    "27901": {
        "name": "Mỹ Quý Đông",
        "type": "xa",
        "slug": "my-quy-dong",
        "name_with_type": "Xã Mỹ Quý Đông",
        "path": "Mỹ Quý Đông, Đức Huệ, Long An",
        "path_with_type": "Xã Mỹ Quý Đông, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27901",
        "parent_code": "801"
    },
    "27904": {
        "name": "Mỹ Thạnh Bắc",
        "type": "xa",
        "slug": "my-thanh-bac",
        "name_with_type": "Xã Mỹ Thạnh Bắc",
        "path": "Mỹ Thạnh Bắc, Đức Huệ, Long An",
        "path_with_type": "Xã Mỹ Thạnh Bắc, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27904",
        "parent_code": "801"
    },
    "27907": {
        "name": "Mỹ Quý Tây",
        "type": "xa",
        "slug": "my-quy-tay",
        "name_with_type": "Xã Mỹ Quý Tây",
        "path": "Mỹ Quý Tây, Đức Huệ, Long An",
        "path_with_type": "Xã Mỹ Quý Tây, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27907",
        "parent_code": "801"
    },
    "27910": {
        "name": "Mỹ Thạnh Tây",
        "type": "xa",
        "slug": "my-thanh-tay",
        "name_with_type": "Xã Mỹ Thạnh Tây",
        "path": "Mỹ Thạnh Tây, Đức Huệ, Long An",
        "path_with_type": "Xã Mỹ Thạnh Tây, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27910",
        "parent_code": "801"
    },
    "27913": {
        "name": "Mỹ Thạnh Đông",
        "type": "xa",
        "slug": "my-thanh-dong",
        "name_with_type": "Xã Mỹ Thạnh Đông",
        "path": "Mỹ Thạnh Đông, Đức Huệ, Long An",
        "path_with_type": "Xã Mỹ Thạnh Đông, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27913",
        "parent_code": "801"
    },
    "27916": {
        "name": "Bình Thành",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thành",
        "path": "Bình Thành, Đức Huệ, Long An",
        "path_with_type": "Xã Bình Thành, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27916",
        "parent_code": "801"
    },
    "27919": {
        "name": "Bình Hòa Bắc",
        "type": "xa",
        "slug": "binh-hoa-bac",
        "name_with_type": "Xã Bình Hòa Bắc",
        "path": "Bình Hòa Bắc, Đức Huệ, Long An",
        "path_with_type": "Xã Bình Hòa Bắc, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27919",
        "parent_code": "801"
    },
    "27922": {
        "name": "Bình Hòa Hưng",
        "type": "xa",
        "slug": "binh-hoa-hung",
        "name_with_type": "Xã Bình Hòa Hưng",
        "path": "Bình Hòa Hưng, Đức Huệ, Long An",
        "path_with_type": "Xã Bình Hòa Hưng, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27922",
        "parent_code": "801"
    },
    "27925": {
        "name": "Bình Hòa Nam",
        "type": "xa",
        "slug": "binh-hoa-nam",
        "name_with_type": "Xã Bình Hòa Nam",
        "path": "Bình Hòa Nam, Đức Huệ, Long An",
        "path_with_type": "Xã Bình Hòa Nam, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27925",
        "parent_code": "801"
    },
    "27928": {
        "name": "Mỹ Bình",
        "type": "xa",
        "slug": "my-binh",
        "name_with_type": "Xã Mỹ Bình",
        "path": "Mỹ Bình, Đức Huệ, Long An",
        "path_with_type": "Xã Mỹ Bình, Huyện Đức Huệ, Tỉnh Long An",
        "code": "27928",
        "parent_code": "801"
    },
    "27931": {
        "name": "Hậu Nghĩa",
        "type": "thi-tran",
        "slug": "hau-nghia",
        "name_with_type": "Thị trấn Hậu Nghĩa",
        "path": "Hậu Nghĩa, Đức Hòa, Long An",
        "path_with_type": "Thị trấn Hậu Nghĩa, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27931",
        "parent_code": "802"
    },
    "27934": {
        "name": "Hiệp Hòa",
        "type": "thi-tran",
        "slug": "hiep-hoa",
        "name_with_type": "Thị trấn Hiệp Hòa",
        "path": "Hiệp Hòa, Đức Hòa, Long An",
        "path_with_type": "Thị trấn Hiệp Hòa, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27934",
        "parent_code": "802"
    },
    "27937": {
        "name": "Đức Hòa",
        "type": "thi-tran",
        "slug": "duc-hoa",
        "name_with_type": "Thị trấn Đức Hòa",
        "path": "Đức Hòa, Đức Hòa, Long An",
        "path_with_type": "Thị trấn Đức Hòa, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27937",
        "parent_code": "802"
    },
    "27940": {
        "name": "Lộc Giang",
        "type": "xa",
        "slug": "loc-giang",
        "name_with_type": "Xã Lộc Giang",
        "path": "Lộc Giang, Đức Hòa, Long An",
        "path_with_type": "Xã Lộc Giang, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27940",
        "parent_code": "802"
    },
    "27943": {
        "name": "An Ninh Đông",
        "type": "xa",
        "slug": "an-ninh-dong",
        "name_with_type": "Xã An Ninh Đông",
        "path": "An Ninh Đông, Đức Hòa, Long An",
        "path_with_type": "Xã An Ninh Đông, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27943",
        "parent_code": "802"
    },
    "27946": {
        "name": "An Ninh Tây",
        "type": "xa",
        "slug": "an-ninh-tay",
        "name_with_type": "Xã An Ninh Tây",
        "path": "An Ninh Tây, Đức Hòa, Long An",
        "path_with_type": "Xã An Ninh Tây, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27946",
        "parent_code": "802"
    },
    "27949": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Đức Hòa, Long An",
        "path_with_type": "Xã Tân Mỹ, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27949",
        "parent_code": "802"
    },
    "27952": {
        "name": "Hiệp Hòa",
        "type": "xa",
        "slug": "hiep-hoa",
        "name_with_type": "Xã Hiệp Hòa",
        "path": "Hiệp Hòa, Đức Hòa, Long An",
        "path_with_type": "Xã Hiệp Hòa, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27952",
        "parent_code": "802"
    },
    "27955": {
        "name": "Đức Lập Thượng",
        "type": "xa",
        "slug": "duc-lap-thuong",
        "name_with_type": "Xã Đức Lập Thượng",
        "path": "Đức Lập Thượng, Đức Hòa, Long An",
        "path_with_type": "Xã Đức Lập Thượng, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27955",
        "parent_code": "802"
    },
    "27958": {
        "name": "Đức Lập Hạ",
        "type": "xa",
        "slug": "duc-lap-ha",
        "name_with_type": "Xã Đức Lập Hạ",
        "path": "Đức Lập Hạ, Đức Hòa, Long An",
        "path_with_type": "Xã Đức Lập Hạ, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27958",
        "parent_code": "802"
    },
    "27961": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Đức Hòa, Long An",
        "path_with_type": "Xã Tân Phú, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27961",
        "parent_code": "802"
    },
    "27964": {
        "name": "Mỹ Hạnh Bắc",
        "type": "xa",
        "slug": "my-hanh-bac",
        "name_with_type": "Xã Mỹ Hạnh Bắc",
        "path": "Mỹ Hạnh Bắc, Đức Hòa, Long An",
        "path_with_type": "Xã Mỹ Hạnh Bắc, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27964",
        "parent_code": "802"
    },
    "27967": {
        "name": "Đức Hòa Thượng",
        "type": "xa",
        "slug": "duc-hoa-thuong",
        "name_with_type": "Xã Đức Hòa Thượng",
        "path": "Đức Hòa Thượng, Đức Hòa, Long An",
        "path_with_type": "Xã Đức Hòa Thượng, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27967",
        "parent_code": "802"
    },
    "27970": {
        "name": "Hòa Khánh Tây",
        "type": "xa",
        "slug": "hoa-khanh-tay",
        "name_with_type": "Xã Hòa Khánh Tây",
        "path": "Hòa Khánh Tây, Đức Hòa, Long An",
        "path_with_type": "Xã Hòa Khánh Tây, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27970",
        "parent_code": "802"
    },
    "27973": {
        "name": "Hòa Khánh Đông",
        "type": "xa",
        "slug": "hoa-khanh-dong",
        "name_with_type": "Xã Hòa Khánh Đông",
        "path": "Hòa Khánh Đông, Đức Hòa, Long An",
        "path_with_type": "Xã Hòa Khánh Đông, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27973",
        "parent_code": "802"
    },
    "27976": {
        "name": "Mỹ Hạnh Nam",
        "type": "xa",
        "slug": "my-hanh-nam",
        "name_with_type": "Xã Mỹ Hạnh Nam",
        "path": "Mỹ Hạnh Nam, Đức Hòa, Long An",
        "path_with_type": "Xã Mỹ Hạnh Nam, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27976",
        "parent_code": "802"
    },
    "27979": {
        "name": "Hòa Khánh Nam",
        "type": "xa",
        "slug": "hoa-khanh-nam",
        "name_with_type": "Xã Hòa Khánh Nam",
        "path": "Hòa Khánh Nam, Đức Hòa, Long An",
        "path_with_type": "Xã Hòa Khánh Nam, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27979",
        "parent_code": "802"
    },
    "27982": {
        "name": "Đức Hòa Đông",
        "type": "xa",
        "slug": "duc-hoa-dong",
        "name_with_type": "Xã Đức Hòa Đông",
        "path": "Đức Hòa Đông, Đức Hòa, Long An",
        "path_with_type": "Xã Đức Hòa Đông, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27982",
        "parent_code": "802"
    },
    "27985": {
        "name": "Đức Hòa Hạ",
        "type": "xa",
        "slug": "duc-hoa-ha",
        "name_with_type": "Xã Đức Hòa Hạ",
        "path": "Đức Hòa Hạ, Đức Hòa, Long An",
        "path_with_type": "Xã Đức Hòa Hạ, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27985",
        "parent_code": "802"
    },
    "27988": {
        "name": "Hựu Thạnh",
        "type": "xa",
        "slug": "huu-thanh",
        "name_with_type": "Xã Hựu Thạnh",
        "path": "Hựu Thạnh, Đức Hòa, Long An",
        "path_with_type": "Xã Hựu Thạnh, Huyện Đức Hòa, Tỉnh Long An",
        "code": "27988",
        "parent_code": "802"
    },
    "27991": {
        "name": "Bến Lức",
        "type": "thi-tran",
        "slug": "ben-luc",
        "name_with_type": "Thị trấn Bến Lức",
        "path": "Bến Lức, Bến Lức, Long An",
        "path_with_type": "Thị trấn Bến Lức, Huyện Bến Lức, Tỉnh Long An",
        "code": "27991",
        "parent_code": "803"
    },
    "27994": {
        "name": "Thạnh Lợi",
        "type": "xa",
        "slug": "thanh-loi",
        "name_with_type": "Xã Thạnh Lợi",
        "path": "Thạnh Lợi, Bến Lức, Long An",
        "path_with_type": "Xã Thạnh Lợi, Huyện Bến Lức, Tỉnh Long An",
        "code": "27994",
        "parent_code": "803"
    },
    "27997": {
        "name": "Lương Bình",
        "type": "xa",
        "slug": "luong-binh",
        "name_with_type": "Xã Lương Bình",
        "path": "Lương Bình, Bến Lức, Long An",
        "path_with_type": "Xã Lương Bình, Huyện Bến Lức, Tỉnh Long An",
        "code": "27997",
        "parent_code": "803"
    },
    "28000": {
        "name": "Thạnh Hòa",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thạnh Hòa",
        "path": "Thạnh Hòa, Bến Lức, Long An",
        "path_with_type": "Xã Thạnh Hòa, Huyện Bến Lức, Tỉnh Long An",
        "code": "28000",
        "parent_code": "803"
    },
    "28003": {
        "name": "Lương Hòa",
        "type": "xa",
        "slug": "luong-hoa",
        "name_with_type": "Xã Lương Hòa",
        "path": "Lương Hòa, Bến Lức, Long An",
        "path_with_type": "Xã Lương Hòa, Huyện Bến Lức, Tỉnh Long An",
        "code": "28003",
        "parent_code": "803"
    },
    "28006": {
        "name": "Tân Hòa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hòa",
        "path": "Tân Hòa, Bến Lức, Long An",
        "path_with_type": "Xã Tân Hòa, Huyện Bến Lức, Tỉnh Long An",
        "code": "28006",
        "parent_code": "803"
    },
    "28009": {
        "name": "Tân Bửu",
        "type": "xa",
        "slug": "tan-buu",
        "name_with_type": "Xã Tân Bửu",
        "path": "Tân Bửu, Bến Lức, Long An",
        "path_with_type": "Xã Tân Bửu, Huyện Bến Lức, Tỉnh Long An",
        "code": "28009",
        "parent_code": "803"
    },
    "28012": {
        "name": "An Thạnh",
        "type": "xa",
        "slug": "an-thanh",
        "name_with_type": "Xã An Thạnh",
        "path": "An Thạnh, Bến Lức, Long An",
        "path_with_type": "Xã An Thạnh, Huyện Bến Lức, Tỉnh Long An",
        "code": "28012",
        "parent_code": "803"
    },
    "28015": {
        "name": "Bình Đức",
        "type": "xa",
        "slug": "binh-duc",
        "name_with_type": "Xã Bình Đức",
        "path": "Bình Đức, Bến Lức, Long An",
        "path_with_type": "Xã Bình Đức, Huyện Bến Lức, Tỉnh Long An",
        "code": "28015",
        "parent_code": "803"
    },
    "28018": {
        "name": "Mỹ Yên",
        "type": "xa",
        "slug": "my-yen",
        "name_with_type": "Xã Mỹ Yên",
        "path": "Mỹ Yên, Bến Lức, Long An",
        "path_with_type": "Xã Mỹ Yên, Huyện Bến Lức, Tỉnh Long An",
        "code": "28018",
        "parent_code": "803"
    },
    "28021": {
        "name": "Thanh Phú",
        "type": "xa",
        "slug": "thanh-phu",
        "name_with_type": "Xã Thanh Phú",
        "path": "Thanh Phú, Bến Lức, Long An",
        "path_with_type": "Xã Thanh Phú, Huyện Bến Lức, Tỉnh Long An",
        "code": "28021",
        "parent_code": "803"
    },
    "28024": {
        "name": "Long Hiệp",
        "type": "xa",
        "slug": "long-hiep",
        "name_with_type": "Xã Long Hiệp",
        "path": "Long Hiệp, Bến Lức, Long An",
        "path_with_type": "Xã Long Hiệp, Huyện Bến Lức, Tỉnh Long An",
        "code": "28024",
        "parent_code": "803"
    },
    "28027": {
        "name": "Thạnh Đức",
        "type": "xa",
        "slug": "thanh-duc",
        "name_with_type": "Xã Thạnh Đức",
        "path": "Thạnh Đức, Bến Lức, Long An",
        "path_with_type": "Xã Thạnh Đức, Huyện Bến Lức, Tỉnh Long An",
        "code": "28027",
        "parent_code": "803"
    },
    "28030": {
        "name": "Phước Lợi",
        "type": "xa",
        "slug": "phuoc-loi",
        "name_with_type": "Xã Phước Lợi",
        "path": "Phước Lợi, Bến Lức, Long An",
        "path_with_type": "Xã Phước Lợi, Huyện Bến Lức, Tỉnh Long An",
        "code": "28030",
        "parent_code": "803"
    },
    "28033": {
        "name": "Nhựt Chánh",
        "type": "xa",
        "slug": "nhut-chanh",
        "name_with_type": "Xã Nhựt Chánh",
        "path": "Nhựt Chánh, Bến Lức, Long An",
        "path_with_type": "Xã Nhựt Chánh, Huyện Bến Lức, Tỉnh Long An",
        "code": "28033",
        "parent_code": "803"
    },
    "28036": {
        "name": "Thủ Thừa",
        "type": "thi-tran",
        "slug": "thu-thua",
        "name_with_type": "Thị trấn Thủ Thừa",
        "path": "Thủ Thừa, Thủ Thừa, Long An",
        "path_with_type": "Thị trấn Thủ Thừa, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28036",
        "parent_code": "804"
    },
    "28039": {
        "name": "Long Thạnh",
        "type": "xa",
        "slug": "long-thanh",
        "name_with_type": "Xã Long Thạnh",
        "path": "Long Thạnh, Thủ Thừa, Long An",
        "path_with_type": "Xã Long Thạnh, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28039",
        "parent_code": "804"
    },
    "28042": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Thủ Thừa, Long An",
        "path_with_type": "Xã Tân Thành, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28042",
        "parent_code": "804"
    },
    "28045": {
        "name": "Long Thuận",
        "type": "xa",
        "slug": "long-thuan",
        "name_with_type": "Xã Long Thuận",
        "path": "Long Thuận, Thủ Thừa, Long An",
        "path_with_type": "Xã Long Thuận, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28045",
        "parent_code": "804"
    },
    "28048": {
        "name": "Mỹ Lạc",
        "type": "xa",
        "slug": "my-lac",
        "name_with_type": "Xã Mỹ Lạc",
        "path": "Mỹ Lạc, Thủ Thừa, Long An",
        "path_with_type": "Xã Mỹ Lạc, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28048",
        "parent_code": "804"
    },
    "28051": {
        "name": "Mỹ Thạnh",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thạnh",
        "path": "Mỹ Thạnh, Thủ Thừa, Long An",
        "path_with_type": "Xã Mỹ Thạnh, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28051",
        "parent_code": "804"
    },
    "28054": {
        "name": "Bình An",
        "type": "xa",
        "slug": "binh-an",
        "name_with_type": "Xã Bình An",
        "path": "Bình An, Thủ Thừa, Long An",
        "path_with_type": "Xã Bình An, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28054",
        "parent_code": "804"
    },
    "28057": {
        "name": "Nhị Thành",
        "type": "xa",
        "slug": "nhi-thanh",
        "name_with_type": "Xã Nhị Thành",
        "path": "Nhị Thành, Thủ Thừa, Long An",
        "path_with_type": "Xã Nhị Thành, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28057",
        "parent_code": "804"
    },
    "28060": {
        "name": "Mỹ An",
        "type": "xa",
        "slug": "my-an",
        "name_with_type": "Xã Mỹ An",
        "path": "Mỹ An, Thủ Thừa, Long An",
        "path_with_type": "Xã Mỹ An, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28060",
        "parent_code": "804"
    },
    "28063": {
        "name": "Bình Thạnh",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thạnh",
        "path": "Bình Thạnh, Thủ Thừa, Long An",
        "path_with_type": "Xã Bình Thạnh, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28063",
        "parent_code": "804"
    },
    "28066": {
        "name": "Mỹ Phú",
        "type": "xa",
        "slug": "my-phu",
        "name_with_type": "Xã Mỹ Phú",
        "path": "Mỹ Phú, Thủ Thừa, Long An",
        "path_with_type": "Xã Mỹ Phú, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28066",
        "parent_code": "804"
    },
    "28069": {
        "name": "Long Thành",
        "type": "xa",
        "slug": "long-thanh",
        "name_with_type": "Xã Long Thành",
        "path": "Long Thành, Thủ Thừa, Long An",
        "path_with_type": "Xã Long Thành, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28069",
        "parent_code": "804"
    },
    "28072": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Thủ Thừa, Long An",
        "path_with_type": "Xã Tân Lập, Huyện Thủ Thừa, Tỉnh Long An",
        "code": "28072",
        "parent_code": "804"
    },
    "28075": {
        "name": "Tân Trụ",
        "type": "thi-tran",
        "slug": "tan-tru",
        "name_with_type": "Thị trấn Tân Trụ",
        "path": "Tân Trụ, Tân Trụ, Long An",
        "path_with_type": "Thị trấn Tân Trụ, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28075",
        "parent_code": "805"
    },
    "28078": {
        "name": "Mỹ Bình",
        "type": "xa",
        "slug": "my-binh",
        "name_with_type": "Xã Mỹ Bình",
        "path": "Mỹ Bình, Tân Trụ, Long An",
        "path_with_type": "Xã Mỹ Bình, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28078",
        "parent_code": "805"
    },
    "28081": {
        "name": "An Nhựt Tân",
        "type": "xa",
        "slug": "an-nhut-tan",
        "name_with_type": "Xã An Nhựt Tân",
        "path": "An Nhựt Tân, Tân Trụ, Long An",
        "path_with_type": "Xã An Nhựt Tân, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28081",
        "parent_code": "805"
    },
    "28084": {
        "name": "Quê Mỹ Thạnh",
        "type": "xa",
        "slug": "que-my-thanh",
        "name_with_type": "Xã Quê Mỹ Thạnh",
        "path": "Quê Mỹ Thạnh, Tân Trụ, Long An",
        "path_with_type": "Xã Quê Mỹ Thạnh, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28084",
        "parent_code": "805"
    },
    "28087": {
        "name": "Lạc Tấn",
        "type": "xa",
        "slug": "lac-tan",
        "name_with_type": "Xã Lạc Tấn",
        "path": "Lạc Tấn, Tân Trụ, Long An",
        "path_with_type": "Xã Lạc Tấn, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28087",
        "parent_code": "805"
    },
    "28090": {
        "name": "Bình Trinh Đông",
        "type": "xa",
        "slug": "binh-trinh-dong",
        "name_with_type": "Xã Bình Trinh Đông",
        "path": "Bình Trinh Đông, Tân Trụ, Long An",
        "path_with_type": "Xã Bình Trinh Đông, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28090",
        "parent_code": "805"
    },
    "28093": {
        "name": "Tân Phước Tây",
        "type": "xa",
        "slug": "tan-phuoc-tay",
        "name_with_type": "Xã Tân Phước Tây",
        "path": "Tân Phước Tây, Tân Trụ, Long An",
        "path_with_type": "Xã Tân Phước Tây, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28093",
        "parent_code": "805"
    },
    "28096": {
        "name": "Bình Lãng",
        "type": "xa",
        "slug": "binh-lang",
        "name_with_type": "Xã Bình Lãng",
        "path": "Bình Lãng, Tân Trụ, Long An",
        "path_with_type": "Xã Bình Lãng, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28096",
        "parent_code": "805"
    },
    "28099": {
        "name": "Bình Tịnh",
        "type": "xa",
        "slug": "binh-tinh",
        "name_with_type": "Xã Bình Tịnh",
        "path": "Bình Tịnh, Tân Trụ, Long An",
        "path_with_type": "Xã Bình Tịnh, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28099",
        "parent_code": "805"
    },
    "28102": {
        "name": "Đức Tân",
        "type": "xa",
        "slug": "duc-tan",
        "name_with_type": "Xã Đức Tân",
        "path": "Đức Tân, Tân Trụ, Long An",
        "path_with_type": "Xã Đức Tân, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28102",
        "parent_code": "805"
    },
    "28105": {
        "name": "Nhựt Ninh",
        "type": "xa",
        "slug": "nhut-ninh",
        "name_with_type": "Xã Nhựt Ninh",
        "path": "Nhựt Ninh, Tân Trụ, Long An",
        "path_with_type": "Xã Nhựt Ninh, Huyện Tân Trụ, Tỉnh Long An",
        "code": "28105",
        "parent_code": "805"
    },
    "28108": {
        "name": "Cần Đước",
        "type": "thi-tran",
        "slug": "can-duoc",
        "name_with_type": "Thị trấn Cần Đước",
        "path": "Cần Đước, Cần Đước, Long An",
        "path_with_type": "Thị trấn Cần Đước, Huyện Cần Đước, Tỉnh Long An",
        "code": "28108",
        "parent_code": "806"
    },
    "28111": {
        "name": "Long Trạch",
        "type": "xa",
        "slug": "long-trach",
        "name_with_type": "Xã Long Trạch",
        "path": "Long Trạch, Cần Đước, Long An",
        "path_with_type": "Xã Long Trạch, Huyện Cần Đước, Tỉnh Long An",
        "code": "28111",
        "parent_code": "806"
    },
    "28114": {
        "name": "Long Khê",
        "type": "xa",
        "slug": "long-khe",
        "name_with_type": "Xã Long Khê",
        "path": "Long Khê, Cần Đước, Long An",
        "path_with_type": "Xã Long Khê, Huyện Cần Đước, Tỉnh Long An",
        "code": "28114",
        "parent_code": "806"
    },
    "28117": {
        "name": "Long Định",
        "type": "xa",
        "slug": "long-dinh",
        "name_with_type": "Xã Long Định",
        "path": "Long Định, Cần Đước, Long An",
        "path_with_type": "Xã Long Định, Huyện Cần Đước, Tỉnh Long An",
        "code": "28117",
        "parent_code": "806"
    },
    "28120": {
        "name": "Phước Vân",
        "type": "xa",
        "slug": "phuoc-van",
        "name_with_type": "Xã Phước Vân",
        "path": "Phước Vân, Cần Đước, Long An",
        "path_with_type": "Xã Phước Vân, Huyện Cần Đước, Tỉnh Long An",
        "code": "28120",
        "parent_code": "806"
    },
    "28123": {
        "name": "Long Hòa",
        "type": "xa",
        "slug": "long-hoa",
        "name_with_type": "Xã Long Hòa",
        "path": "Long Hòa, Cần Đước, Long An",
        "path_with_type": "Xã Long Hòa, Huyện Cần Đước, Tỉnh Long An",
        "code": "28123",
        "parent_code": "806"
    },
    "28126": {
        "name": "Long Cang",
        "type": "xa",
        "slug": "long-cang",
        "name_with_type": "Xã Long Cang",
        "path": "Long Cang, Cần Đước, Long An",
        "path_with_type": "Xã Long Cang, Huyện Cần Đước, Tỉnh Long An",
        "code": "28126",
        "parent_code": "806"
    },
    "28129": {
        "name": "Long Sơn",
        "type": "xa",
        "slug": "long-son",
        "name_with_type": "Xã Long Sơn",
        "path": "Long Sơn, Cần Đước, Long An",
        "path_with_type": "Xã Long Sơn, Huyện Cần Đước, Tỉnh Long An",
        "code": "28129",
        "parent_code": "806"
    },
    "28132": {
        "name": "Tân Trạch",
        "type": "xa",
        "slug": "tan-trach",
        "name_with_type": "Xã Tân Trạch",
        "path": "Tân Trạch, Cần Đước, Long An",
        "path_with_type": "Xã Tân Trạch, Huyện Cần Đước, Tỉnh Long An",
        "code": "28132",
        "parent_code": "806"
    },
    "28135": {
        "name": "Mỹ Lệ",
        "type": "xa",
        "slug": "my-le",
        "name_with_type": "Xã Mỹ Lệ",
        "path": "Mỹ Lệ, Cần Đước, Long An",
        "path_with_type": "Xã Mỹ Lệ, Huyện Cần Đước, Tỉnh Long An",
        "code": "28135",
        "parent_code": "806"
    },
    "28138": {
        "name": "Tân Lân",
        "type": "xa",
        "slug": "tan-lan",
        "name_with_type": "Xã Tân Lân",
        "path": "Tân Lân, Cần Đước, Long An",
        "path_with_type": "Xã Tân Lân, Huyện Cần Đước, Tỉnh Long An",
        "code": "28138",
        "parent_code": "806"
    },
    "28141": {
        "name": "Phước Tuy",
        "type": "xa",
        "slug": "phuoc-tuy",
        "name_with_type": "Xã Phước Tuy",
        "path": "Phước Tuy, Cần Đước, Long An",
        "path_with_type": "Xã Phước Tuy, Huyện Cần Đước, Tỉnh Long An",
        "code": "28141",
        "parent_code": "806"
    },
    "28144": {
        "name": "Long Hựu Đông",
        "type": "xa",
        "slug": "long-huu-dong",
        "name_with_type": "Xã Long Hựu Đông",
        "path": "Long Hựu Đông, Cần Đước, Long An",
        "path_with_type": "Xã Long Hựu Đông, Huyện Cần Đước, Tỉnh Long An",
        "code": "28144",
        "parent_code": "806"
    },
    "28147": {
        "name": "Tân Ân",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân Ân",
        "path": "Tân Ân, Cần Đước, Long An",
        "path_with_type": "Xã Tân Ân, Huyện Cần Đước, Tỉnh Long An",
        "code": "28147",
        "parent_code": "806"
    },
    "28150": {
        "name": "Phước Đông",
        "type": "xa",
        "slug": "phuoc-dong",
        "name_with_type": "Xã Phước Đông",
        "path": "Phước Đông, Cần Đước, Long An",
        "path_with_type": "Xã Phước Đông, Huyện Cần Đước, Tỉnh Long An",
        "code": "28150",
        "parent_code": "806"
    },
    "28153": {
        "name": "Long Hựu Tây",
        "type": "xa",
        "slug": "long-huu-tay",
        "name_with_type": "Xã Long Hựu Tây",
        "path": "Long Hựu Tây, Cần Đước, Long An",
        "path_with_type": "Xã Long Hựu Tây, Huyện Cần Đước, Tỉnh Long An",
        "code": "28153",
        "parent_code": "806"
    },
    "28156": {
        "name": "Tân Chánh",
        "type": "xa",
        "slug": "tan-chanh",
        "name_with_type": "Xã Tân Chánh",
        "path": "Tân Chánh, Cần Đước, Long An",
        "path_with_type": "Xã Tân Chánh, Huyện Cần Đước, Tỉnh Long An",
        "code": "28156",
        "parent_code": "806"
    },
    "28159": {
        "name": "Cần Giuộc",
        "type": "thi-tran",
        "slug": "can-giuoc",
        "name_with_type": "Thị trấn Cần Giuộc",
        "path": "Cần Giuộc, Cần Giuộc, Long An",
        "path_with_type": "Thị trấn Cần Giuộc, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28159",
        "parent_code": "807"
    },
    "28162": {
        "name": "Phước Lý",
        "type": "xa",
        "slug": "phuoc-ly",
        "name_with_type": "Xã Phước Lý",
        "path": "Phước Lý, Cần Giuộc, Long An",
        "path_with_type": "Xã Phước Lý, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28162",
        "parent_code": "807"
    },
    "28165": {
        "name": "Long Thượng",
        "type": "xa",
        "slug": "long-thuong",
        "name_with_type": "Xã Long Thượng",
        "path": "Long Thượng, Cần Giuộc, Long An",
        "path_with_type": "Xã Long Thượng, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28165",
        "parent_code": "807"
    },
    "28168": {
        "name": "Long Hậu",
        "type": "xa",
        "slug": "long-hau",
        "name_with_type": "Xã Long Hậu",
        "path": "Long Hậu, Cần Giuộc, Long An",
        "path_with_type": "Xã Long Hậu, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28168",
        "parent_code": "807"
    },
    "28171": {
        "name": "Tân Kim",
        "type": "xa",
        "slug": "tan-kim",
        "name_with_type": "Xã Tân Kim",
        "path": "Tân Kim, Cần Giuộc, Long An",
        "path_with_type": "Xã Tân Kim, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28171",
        "parent_code": "807"
    },
    "28174": {
        "name": "Phước Hậu",
        "type": "xa",
        "slug": "phuoc-hau",
        "name_with_type": "Xã Phước Hậu",
        "path": "Phước Hậu, Cần Giuộc, Long An",
        "path_with_type": "Xã Phước Hậu, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28174",
        "parent_code": "807"
    },
    "28177": {
        "name": "Mỹ Lộc",
        "type": "xa",
        "slug": "my-loc",
        "name_with_type": "Xã Mỹ Lộc",
        "path": "Mỹ Lộc, Cần Giuộc, Long An",
        "path_with_type": "Xã Mỹ Lộc, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28177",
        "parent_code": "807"
    },
    "28180": {
        "name": "Phước Lại",
        "type": "xa",
        "slug": "phuoc-lai",
        "name_with_type": "Xã Phước Lại",
        "path": "Phước Lại, Cần Giuộc, Long An",
        "path_with_type": "Xã Phước Lại, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28180",
        "parent_code": "807"
    },
    "28183": {
        "name": "Phước Lâm",
        "type": "xa",
        "slug": "phuoc-lam",
        "name_with_type": "Xã Phước Lâm",
        "path": "Phước Lâm, Cần Giuộc, Long An",
        "path_with_type": "Xã Phước Lâm, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28183",
        "parent_code": "807"
    },
    "28186": {
        "name": "Trường Bình",
        "type": "xa",
        "slug": "truong-binh",
        "name_with_type": "Xã Trường Bình",
        "path": "Trường Bình, Cần Giuộc, Long An",
        "path_with_type": "Xã Trường Bình, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28186",
        "parent_code": "807"
    },
    "28189": {
        "name": "Thuận Thành",
        "type": "xa",
        "slug": "thuan-thanh",
        "name_with_type": "Xã Thuận Thành",
        "path": "Thuận Thành, Cần Giuộc, Long An",
        "path_with_type": "Xã Thuận Thành, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28189",
        "parent_code": "807"
    },
    "28192": {
        "name": "Phước Vĩnh Tây",
        "type": "xa",
        "slug": "phuoc-vinh-tay",
        "name_with_type": "Xã Phước Vĩnh Tây",
        "path": "Phước Vĩnh Tây, Cần Giuộc, Long An",
        "path_with_type": "Xã Phước Vĩnh Tây, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28192",
        "parent_code": "807"
    },
    "28195": {
        "name": "Phước Vĩnh Đông",
        "type": "xa",
        "slug": "phuoc-vinh-dong",
        "name_with_type": "Xã Phước Vĩnh Đông",
        "path": "Phước Vĩnh Đông, Cần Giuộc, Long An",
        "path_with_type": "Xã Phước Vĩnh Đông, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28195",
        "parent_code": "807"
    },
    "28198": {
        "name": "Long An",
        "type": "xa",
        "slug": "long-an",
        "name_with_type": "Xã Long An",
        "path": "Long An, Cần Giuộc, Long An",
        "path_with_type": "Xã Long An, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28198",
        "parent_code": "807"
    },
    "28201": {
        "name": "Long Phụng",
        "type": "xa",
        "slug": "long-phung",
        "name_with_type": "Xã Long Phụng",
        "path": "Long Phụng, Cần Giuộc, Long An",
        "path_with_type": "Xã Long Phụng, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28201",
        "parent_code": "807"
    },
    "28204": {
        "name": "Đông Thạnh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thạnh",
        "path": "Đông Thạnh, Cần Giuộc, Long An",
        "path_with_type": "Xã Đông Thạnh, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28204",
        "parent_code": "807"
    },
    "28207": {
        "name": "Tân Tập",
        "type": "xa",
        "slug": "tan-tap",
        "name_with_type": "Xã Tân Tập",
        "path": "Tân Tập, Cần Giuộc, Long An",
        "path_with_type": "Xã Tân Tập, Huyện Cần Giuộc, Tỉnh Long An",
        "code": "28207",
        "parent_code": "807"
    },
    "28210": {
        "name": "Tầm Vu",
        "type": "thi-tran",
        "slug": "tam-vu",
        "name_with_type": "Thị trấn Tầm Vu",
        "path": "Tầm Vu, Châu Thành, Long An",
        "path_with_type": "Thị trấn Tầm Vu, Huyện Châu Thành, Tỉnh Long An",
        "code": "28210",
        "parent_code": "808"
    },
    "28213": {
        "name": "Bình Quới",
        "type": "xa",
        "slug": "binh-quoi",
        "name_with_type": "Xã Bình Quới",
        "path": "Bình Quới, Châu Thành, Long An",
        "path_with_type": "Xã Bình Quới, Huyện Châu Thành, Tỉnh Long An",
        "code": "28213",
        "parent_code": "808"
    },
    "28216": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Châu Thành, Long An",
        "path_with_type": "Xã Hòa Phú, Huyện Châu Thành, Tỉnh Long An",
        "code": "28216",
        "parent_code": "808"
    },
    "28219": {
        "name": "Phú Ngãi Trị",
        "type": "xa",
        "slug": "phu-ngai-tri",
        "name_with_type": "Xã Phú Ngãi Trị",
        "path": "Phú Ngãi Trị, Châu Thành, Long An",
        "path_with_type": "Xã Phú Ngãi Trị, Huyện Châu Thành, Tỉnh Long An",
        "code": "28219",
        "parent_code": "808"
    },
    "28222": {
        "name": "Vĩnh Công",
        "type": "xa",
        "slug": "vinh-cong",
        "name_with_type": "Xã Vĩnh Công",
        "path": "Vĩnh Công, Châu Thành, Long An",
        "path_with_type": "Xã Vĩnh Công, Huyện Châu Thành, Tỉnh Long An",
        "code": "28222",
        "parent_code": "808"
    },
    "28225": {
        "name": "Thuận Mỹ",
        "type": "xa",
        "slug": "thuan-my",
        "name_with_type": "Xã Thuận Mỹ",
        "path": "Thuận Mỹ, Châu Thành, Long An",
        "path_with_type": "Xã Thuận Mỹ, Huyện Châu Thành, Tỉnh Long An",
        "code": "28225",
        "parent_code": "808"
    },
    "28228": {
        "name": "Hiệp Thạnh",
        "type": "xa",
        "slug": "hiep-thanh",
        "name_with_type": "Xã Hiệp Thạnh",
        "path": "Hiệp Thạnh, Châu Thành, Long An",
        "path_with_type": "Xã Hiệp Thạnh, Huyện Châu Thành, Tỉnh Long An",
        "code": "28228",
        "parent_code": "808"
    },
    "28231": {
        "name": "Phước Tân Hưng",
        "type": "xa",
        "slug": "phuoc-tan-hung",
        "name_with_type": "Xã Phước Tân Hưng",
        "path": "Phước Tân Hưng, Châu Thành, Long An",
        "path_with_type": "Xã Phước Tân Hưng, Huyện Châu Thành, Tỉnh Long An",
        "code": "28231",
        "parent_code": "808"
    },
    "28234": {
        "name": "Thanh Phú Long",
        "type": "xa",
        "slug": "thanh-phu-long",
        "name_with_type": "Xã Thanh Phú Long",
        "path": "Thanh Phú Long, Châu Thành, Long An",
        "path_with_type": "Xã Thanh Phú Long, Huyện Châu Thành, Tỉnh Long An",
        "code": "28234",
        "parent_code": "808"
    },
    "28237": {
        "name": "Dương Xuân Hội",
        "type": "xa",
        "slug": "duong-xuan-hoi",
        "name_with_type": "Xã Dương Xuân Hội",
        "path": "Dương Xuân Hội, Châu Thành, Long An",
        "path_with_type": "Xã Dương Xuân Hội, Huyện Châu Thành, Tỉnh Long An",
        "code": "28237",
        "parent_code": "808"
    },
    "28240": {
        "name": "An Lục Long",
        "type": "xa",
        "slug": "an-luc-long",
        "name_with_type": "Xã An Lục Long",
        "path": "An Lục Long, Châu Thành, Long An",
        "path_with_type": "Xã An Lục Long, Huyện Châu Thành, Tỉnh Long An",
        "code": "28240",
        "parent_code": "808"
    },
    "28243": {
        "name": "Long Trì",
        "type": "xa",
        "slug": "long-tri",
        "name_with_type": "Xã Long Trì",
        "path": "Long Trì, Châu Thành, Long An",
        "path_with_type": "Xã Long Trì, Huyện Châu Thành, Tỉnh Long An",
        "code": "28243",
        "parent_code": "808"
    },
    "28246": {
        "name": "Thanh Vĩnh Đông",
        "type": "xa",
        "slug": "thanh-vinh-dong",
        "name_with_type": "Xã Thanh Vĩnh Đông",
        "path": "Thanh Vĩnh Đông, Châu Thành, Long An",
        "path_with_type": "Xã Thanh Vĩnh Đông, Huyện Châu Thành, Tỉnh Long An",
        "code": "28246",
        "parent_code": "808"
    },
    "31318": {
        "name": "I",
        "type": "phuong",
        "slug": "i",
        "name_with_type": "Phường I",
        "path": "I, Vị Thanh, Hậu Giang",
        "path_with_type": "Phường I, Thành phố Vị Thanh, Tỉnh Hậu Giang",
        "code": "31318",
        "parent_code": "930"
    },
    "31321": {
        "name": "III",
        "type": "phuong",
        "slug": "iii",
        "name_with_type": "Phường III",
        "path": "III, Vị Thanh, Hậu Giang",
        "path_with_type": "Phường III, Thành phố Vị Thanh, Tỉnh Hậu Giang",
        "code": "31321",
        "parent_code": "930"
    },
    "31324": {
        "name": "IV",
        "type": "phuong",
        "slug": "iv",
        "name_with_type": "Phường IV",
        "path": "IV, Vị Thanh, Hậu Giang",
        "path_with_type": "Phường IV, Thành phố Vị Thanh, Tỉnh Hậu Giang",
        "code": "31324",
        "parent_code": "930"
    },
    "31327": {
        "name": "V",
        "type": "phuong",
        "slug": "v",
        "name_with_type": "Phường V",
        "path": "V, Vị Thanh, Hậu Giang",
        "path_with_type": "Phường V, Thành phố Vị Thanh, Tỉnh Hậu Giang",
        "code": "31327",
        "parent_code": "930"
    },
    "31330": {
        "name": "VII",
        "type": "phuong",
        "slug": "vii",
        "name_with_type": "Phường VII",
        "path": "VII, Vị Thanh, Hậu Giang",
        "path_with_type": "Phường VII, Thành phố Vị Thanh, Tỉnh Hậu Giang",
        "code": "31330",
        "parent_code": "930"
    },
    "31333": {
        "name": "Vị Tân",
        "type": "xa",
        "slug": "vi-tan",
        "name_with_type": "Xã Vị Tân",
        "path": "Vị Tân, Vị Thanh, Hậu Giang",
        "path_with_type": "Xã Vị Tân, Thành phố Vị Thanh, Tỉnh Hậu Giang",
        "code": "31333",
        "parent_code": "930"
    },
    "31336": {
        "name": "Hoả Lựu",
        "type": "xa",
        "slug": "hoa-luu",
        "name_with_type": "Xã Hoả Lựu",
        "path": "Hoả Lựu, Vị Thanh, Hậu Giang",
        "path_with_type": "Xã Hoả Lựu, Thành phố Vị Thanh, Tỉnh Hậu Giang",
        "code": "31336",
        "parent_code": "930"
    },
    "31338": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Vị Thanh, Hậu Giang",
        "path_with_type": "Xã Tân Tiến, Thành phố Vị Thanh, Tỉnh Hậu Giang",
        "code": "31338",
        "parent_code": "930"
    },
    "31339": {
        "name": "Hoả Tiến",
        "type": "xa",
        "slug": "hoa-tien",
        "name_with_type": "Xã Hoả Tiến",
        "path": "Hoả Tiến, Vị Thanh, Hậu Giang",
        "path_with_type": "Xã Hoả Tiến, Thành phố Vị Thanh, Tỉnh Hậu Giang",
        "code": "31339",
        "parent_code": "930"
    },
    "31340": {
        "name": "Ngã Bảy",
        "type": "phuong",
        "slug": "nga-bay",
        "name_with_type": "Phường Ngã Bảy",
        "path": "Ngã Bảy, Ngã Bảy, Hậu Giang",
        "path_with_type": "Phường Ngã Bảy, Thị xã Ngã Bảy, Tỉnh Hậu Giang",
        "code": "31340",
        "parent_code": "931"
    },
    "31341": {
        "name": "Lái Hiếu",
        "type": "phuong",
        "slug": "lai-hieu",
        "name_with_type": "Phường Lái Hiếu",
        "path": "Lái Hiếu, Ngã Bảy, Hậu Giang",
        "path_with_type": "Phường Lái Hiếu, Thị xã Ngã Bảy, Tỉnh Hậu Giang",
        "code": "31341",
        "parent_code": "931"
    },
    "31343": {
        "name": "Hiệp Thành",
        "type": "phuong",
        "slug": "hiep-thanh",
        "name_with_type": "Phường Hiệp Thành",
        "path": "Hiệp Thành, Ngã Bảy, Hậu Giang",
        "path_with_type": "Phường Hiệp Thành, Thị xã Ngã Bảy, Tỉnh Hậu Giang",
        "code": "31343",
        "parent_code": "931"
    },
    "31344": {
        "name": "Hiệp Lợi",
        "type": "xa",
        "slug": "hiep-loi",
        "name_with_type": "Xã Hiệp Lợi",
        "path": "Hiệp Lợi, Ngã Bảy, Hậu Giang",
        "path_with_type": "Xã Hiệp Lợi, Thị xã Ngã Bảy, Tỉnh Hậu Giang",
        "code": "31344",
        "parent_code": "931"
    },
    "31411": {
        "name": "Đại Thành",
        "type": "xa",
        "slug": "dai-thanh",
        "name_with_type": "Xã Đại Thành",
        "path": "Đại Thành, Ngã Bảy, Hậu Giang",
        "path_with_type": "Xã Đại Thành, Thị xã Ngã Bảy, Tỉnh Hậu Giang",
        "code": "31411",
        "parent_code": "931"
    },
    "31414": {
        "name": "Tân Thành",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thành",
        "path": "Tân Thành, Ngã Bảy, Hậu Giang",
        "path_with_type": "Xã Tân Thành, Thị xã Ngã Bảy, Tỉnh Hậu Giang",
        "code": "31414",
        "parent_code": "931"
    },
    "31342": {
        "name": "Một Ngàn",
        "type": "thi-tran",
        "slug": "mot-ngan",
        "name_with_type": "Thị trấn Một Ngàn",
        "path": "Một Ngàn, Châu Thành A, Hậu Giang",
        "path_with_type": "Thị trấn Một Ngàn, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31342",
        "parent_code": "932"
    },
    "31345": {
        "name": "Tân Hoà",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hoà",
        "path": "Tân Hoà, Châu Thành A, Hậu Giang",
        "path_with_type": "Xã Tân Hoà, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31345",
        "parent_code": "932"
    },
    "31346": {
        "name": "Bảy Ngàn",
        "type": "thi-tran",
        "slug": "bay-ngan",
        "name_with_type": "Thị trấn Bảy Ngàn",
        "path": "Bảy Ngàn, Châu Thành A, Hậu Giang",
        "path_with_type": "Thị trấn Bảy Ngàn, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31346",
        "parent_code": "932"
    },
    "31348": {
        "name": "Trường Long Tây",
        "type": "xa",
        "slug": "truong-long-tay",
        "name_with_type": "Xã Trường Long Tây",
        "path": "Trường Long Tây, Châu Thành A, Hậu Giang",
        "path_with_type": "Xã Trường Long Tây, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31348",
        "parent_code": "932"
    },
    "31351": {
        "name": "Trường Long A",
        "type": "xa",
        "slug": "truong-long-a",
        "name_with_type": "Xã Trường Long A",
        "path": "Trường Long A, Châu Thành A, Hậu Giang",
        "path_with_type": "Xã Trường Long A, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31351",
        "parent_code": "932"
    },
    "31357": {
        "name": "Nhơn Nghĩa A",
        "type": "xa",
        "slug": "nhon-nghia-a",
        "name_with_type": "Xã Nhơn Nghĩa A",
        "path": "Nhơn Nghĩa A, Châu Thành A, Hậu Giang",
        "path_with_type": "Xã Nhơn Nghĩa A, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31357",
        "parent_code": "932"
    },
    "31359": {
        "name": "Rạch Gòi",
        "type": "thi-tran",
        "slug": "rach-goi",
        "name_with_type": "Thị trấn Rạch Gòi",
        "path": "Rạch Gòi, Châu Thành A, Hậu Giang",
        "path_with_type": "Thị trấn Rạch Gòi, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31359",
        "parent_code": "932"
    },
    "31360": {
        "name": "Thạnh Xuân",
        "type": "xa",
        "slug": "thanh-xuan",
        "name_with_type": "Xã Thạnh Xuân",
        "path": "Thạnh Xuân, Châu Thành A, Hậu Giang",
        "path_with_type": "Xã Thạnh Xuân, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31360",
        "parent_code": "932"
    },
    "31362": {
        "name": "Cái Tắc",
        "type": "thi-tran",
        "slug": "cai-tac",
        "name_with_type": "Thị trấn Cái Tắc",
        "path": "Cái Tắc, Châu Thành A, Hậu Giang",
        "path_with_type": "Thị trấn Cái Tắc, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31362",
        "parent_code": "932"
    },
    "31363": {
        "name": "Tân Phú Thạnh",
        "type": "xa",
        "slug": "tan-phu-thanh",
        "name_with_type": "Xã Tân Phú Thạnh",
        "path": "Tân Phú Thạnh, Châu Thành A, Hậu Giang",
        "path_with_type": "Xã Tân Phú Thạnh, Huyện Châu Thành A, Tỉnh Hậu Giang",
        "code": "31363",
        "parent_code": "932"
    },
    "31366": {
        "name": "Ngã Sáu",
        "type": "thi-tran",
        "slug": "nga-sau",
        "name_with_type": "Thị Trấn Ngã Sáu",
        "path": "Ngã Sáu, Châu Thành, Hậu Giang",
        "path_with_type": "Thị Trấn Ngã Sáu, Huyện Châu Thành, Tỉnh Hậu Giang",
        "code": "31366",
        "parent_code": "933"
    },
    "31369": {
        "name": "Đông Thạnh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thạnh",
        "path": "Đông Thạnh, Châu Thành, Hậu Giang",
        "path_with_type": "Xã Đông Thạnh, Huyện Châu Thành, Tỉnh Hậu Giang",
        "code": "31369",
        "parent_code": "933"
    },
    "31372": {
        "name": "Phú An",
        "type": "xa",
        "slug": "phu-an",
        "name_with_type": "Xã Phú An",
        "path": "Phú An, Châu Thành, Hậu Giang",
        "path_with_type": "Xã Phú An, Huyện Châu Thành, Tỉnh Hậu Giang",
        "code": "31372",
        "parent_code": "933"
    },
    "31375": {
        "name": "Đông Phú",
        "type": "xa",
        "slug": "dong-phu",
        "name_with_type": "Xã Đông Phú",
        "path": "Đông Phú, Châu Thành, Hậu Giang",
        "path_with_type": "Xã Đông Phú, Huyện Châu Thành, Tỉnh Hậu Giang",
        "code": "31375",
        "parent_code": "933"
    },
    "31378": {
        "name": "Phú Hữu",
        "type": "xa",
        "slug": "phu-huu",
        "name_with_type": "Xã Phú Hữu",
        "path": "Phú Hữu, Châu Thành, Hậu Giang",
        "path_with_type": "Xã Phú Hữu, Huyện Châu Thành, Tỉnh Hậu Giang",
        "code": "31378",
        "parent_code": "933"
    },
    "31379": {
        "name": "Phú Tân",
        "type": "xa",
        "slug": "phu-tan",
        "name_with_type": "Xã Phú Tân",
        "path": "Phú Tân, Châu Thành, Hậu Giang",
        "path_with_type": "Xã Phú Tân, Huyện Châu Thành, Tỉnh Hậu Giang",
        "code": "31379",
        "parent_code": "933"
    },
    "31381": {
        "name": "Mái Dầm",
        "type": "thi-tran",
        "slug": "mai-dam",
        "name_with_type": "Thị trấn Mái Dầm",
        "path": "Mái Dầm, Châu Thành, Hậu Giang",
        "path_with_type": "Thị trấn Mái Dầm, Huyện Châu Thành, Tỉnh Hậu Giang",
        "code": "31381",
        "parent_code": "933"
    },
    "31384": {
        "name": "Đông Phước",
        "type": "xa",
        "slug": "dong-phuoc",
        "name_with_type": "Xã Đông Phước",
        "path": "Đông Phước, Châu Thành, Hậu Giang",
        "path_with_type": "Xã Đông Phước, Huyện Châu Thành, Tỉnh Hậu Giang",
        "code": "31384",
        "parent_code": "933"
    },
    "31387": {
        "name": "Đông Phước A",
        "type": "xa",
        "slug": "dong-phuoc-a",
        "name_with_type": "Xã Đông Phước A",
        "path": "Đông Phước A, Châu Thành, Hậu Giang",
        "path_with_type": "Xã Đông Phước A, Huyện Châu Thành, Tỉnh Hậu Giang",
        "code": "31387",
        "parent_code": "933"
    },
    "31393": {
        "name": "Kinh Cùng",
        "type": "thi-tran",
        "slug": "kinh-cung",
        "name_with_type": "Thị trấn Kinh Cùng",
        "path": "Kinh Cùng, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Thị trấn Kinh Cùng, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31393",
        "parent_code": "934"
    },
    "31396": {
        "name": "Cây Dương",
        "type": "thi-tran",
        "slug": "cay-duong",
        "name_with_type": "Thị trấn Cây Dương",
        "path": "Cây Dương, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Thị trấn Cây Dương, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31396",
        "parent_code": "934"
    },
    "31399": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Tân Bình, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31399",
        "parent_code": "934"
    },
    "31402": {
        "name": "Bình Thành",
        "type": "xa",
        "slug": "binh-thanh",
        "name_with_type": "Xã Bình Thành",
        "path": "Bình Thành, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Bình Thành, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31402",
        "parent_code": "934"
    },
    "31405": {
        "name": "Thạnh Hòa",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thạnh Hòa",
        "path": "Thạnh Hòa, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Thạnh Hòa, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31405",
        "parent_code": "934"
    },
    "31408": {
        "name": "Long Thạnh",
        "type": "xa",
        "slug": "long-thanh",
        "name_with_type": "Xã Long Thạnh",
        "path": "Long Thạnh, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Long Thạnh, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31408",
        "parent_code": "934"
    },
    "31417": {
        "name": "Phụng Hiệp",
        "type": "xa",
        "slug": "phung-hiep",
        "name_with_type": "Xã Phụng Hiệp",
        "path": "Phụng Hiệp, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Phụng Hiệp, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31417",
        "parent_code": "934"
    },
    "31420": {
        "name": "Hòa Mỹ",
        "type": "xa",
        "slug": "hoa-my",
        "name_with_type": "Xã Hòa Mỹ",
        "path": "Hòa Mỹ, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Hòa Mỹ, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31420",
        "parent_code": "934"
    },
    "31423": {
        "name": "Hòa An",
        "type": "xa",
        "slug": "hoa-an",
        "name_with_type": "Xã Hòa An",
        "path": "Hòa An, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Hòa An, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31423",
        "parent_code": "934"
    },
    "31426": {
        "name": "Phương Bình",
        "type": "xa",
        "slug": "phuong-binh",
        "name_with_type": "Xã Phương Bình",
        "path": "Phương Bình, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Phương Bình, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31426",
        "parent_code": "934"
    },
    "31429": {
        "name": "Hiệp Hưng",
        "type": "xa",
        "slug": "hiep-hung",
        "name_with_type": "Xã Hiệp Hưng",
        "path": "Hiệp Hưng, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Hiệp Hưng, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31429",
        "parent_code": "934"
    },
    "31432": {
        "name": "Tân Phước Hưng",
        "type": "xa",
        "slug": "tan-phuoc-hung",
        "name_with_type": "Xã Tân Phước Hưng",
        "path": "Tân Phước Hưng, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Tân Phước Hưng, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31432",
        "parent_code": "934"
    },
    "31433": {
        "name": "Búng Tàu",
        "type": "thi-tran",
        "slug": "bung-tau",
        "name_with_type": "Thị trấn Búng Tàu",
        "path": "Búng Tàu, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Thị trấn Búng Tàu, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31433",
        "parent_code": "934"
    },
    "31435": {
        "name": "Phương Phú",
        "type": "xa",
        "slug": "phuong-phu",
        "name_with_type": "Xã Phương Phú",
        "path": "Phương Phú, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Phương Phú, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31435",
        "parent_code": "934"
    },
    "31438": {
        "name": "Tân Long",
        "type": "xa",
        "slug": "tan-long",
        "name_with_type": "Xã Tân Long",
        "path": "Tân Long, Phụng Hiệp, Hậu Giang",
        "path_with_type": "Xã Tân Long, Huyện Phụng Hiệp, Tỉnh Hậu Giang",
        "code": "31438",
        "parent_code": "934"
    },
    "31441": {
        "name": "Nàng Mau",
        "type": "thi-tran",
        "slug": "nang-mau",
        "name_with_type": "Thị trấn Nàng Mau",
        "path": "Nàng Mau, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Thị trấn Nàng Mau, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31441",
        "parent_code": "935"
    },
    "31444": {
        "name": "Vị Trung",
        "type": "xa",
        "slug": "vi-trung",
        "name_with_type": "Xã Vị Trung",
        "path": "Vị Trung, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Xã Vị Trung, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31444",
        "parent_code": "935"
    },
    "31447": {
        "name": "Vị Thuỷ",
        "type": "xa",
        "slug": "vi-thuy",
        "name_with_type": "Xã Vị Thuỷ",
        "path": "Vị Thuỷ, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Xã Vị Thuỷ, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31447",
        "parent_code": "935"
    },
    "31450": {
        "name": "Vị Thắng",
        "type": "xa",
        "slug": "vi-thang",
        "name_with_type": "Xã Vị Thắng",
        "path": "Vị Thắng, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Xã Vị Thắng, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31450",
        "parent_code": "935"
    },
    "31453": {
        "name": "Vĩnh Thuận Tây",
        "type": "xa",
        "slug": "vinh-thuan-tay",
        "name_with_type": "Xã Vĩnh Thuận Tây",
        "path": "Vĩnh Thuận Tây, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Xã Vĩnh Thuận Tây, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31453",
        "parent_code": "935"
    },
    "31456": {
        "name": "Vĩnh Trung",
        "type": "xa",
        "slug": "vinh-trung",
        "name_with_type": "Xã Vĩnh Trung",
        "path": "Vĩnh Trung, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Xã Vĩnh Trung, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31456",
        "parent_code": "935"
    },
    "31459": {
        "name": "Vĩnh Tường",
        "type": "xa",
        "slug": "vinh-tuong",
        "name_with_type": "Xã Vĩnh Tường",
        "path": "Vĩnh Tường, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Xã Vĩnh Tường, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31459",
        "parent_code": "935"
    },
    "31462": {
        "name": "Vị Đông",
        "type": "xa",
        "slug": "vi-dong",
        "name_with_type": "Xã Vị Đông",
        "path": "Vị Đông, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Xã Vị Đông, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31462",
        "parent_code": "935"
    },
    "31465": {
        "name": "Vị Thanh",
        "type": "xa",
        "slug": "vi-thanh",
        "name_with_type": "Xã Vị Thanh",
        "path": "Vị Thanh, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Xã Vị Thanh, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31465",
        "parent_code": "935"
    },
    "31468": {
        "name": "Vị Bình",
        "type": "xa",
        "slug": "vi-binh",
        "name_with_type": "Xã Vị Bình",
        "path": "Vị Bình, Vị Thuỷ, Hậu Giang",
        "path_with_type": "Xã Vị Bình, Huyện Vị Thuỷ, Tỉnh Hậu Giang",
        "code": "31468",
        "parent_code": "935"
    },
    "31483": {
        "name": "Thuận Hưng",
        "type": "xa",
        "slug": "thuan-hung",
        "name_with_type": "Xã Thuận Hưng",
        "path": "Thuận Hưng, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Thuận Hưng, Huyện Long Mỹ, Tỉnh Hậu Giang",
        "code": "31483",
        "parent_code": "936"
    },
    "31484": {
        "name": "Thuận Hòa",
        "type": "xa",
        "slug": "thuan-hoa",
        "name_with_type": "Xã Thuận Hòa",
        "path": "Thuận Hòa, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Thuận Hòa, Huyện Long Mỹ, Tỉnh Hậu Giang",
        "code": "31484",
        "parent_code": "936"
    },
    "31486": {
        "name": "Vĩnh Thuận Đông",
        "type": "xa",
        "slug": "vinh-thuan-dong",
        "name_with_type": "Xã Vĩnh Thuận Đông",
        "path": "Vĩnh Thuận Đông, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Vĩnh Thuận Đông, Huyện Long Mỹ, Tỉnh Hậu Giang",
        "code": "31486",
        "parent_code": "936"
    },
    "31489": {
        "name": "Vĩnh Viễn",
        "type": "xa",
        "slug": "vinh-vien",
        "name_with_type": "Xã Vĩnh Viễn",
        "path": "Vĩnh Viễn, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Vĩnh Viễn, Huyện Long Mỹ, Tỉnh Hậu Giang",
        "code": "31489",
        "parent_code": "936"
    },
    "31490": {
        "name": "Vĩnh Viễn A",
        "type": "xa",
        "slug": "vinh-vien-a",
        "name_with_type": "Xã Vĩnh Viễn A",
        "path": "Vĩnh Viễn A, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Vĩnh Viễn A, Huyện Long Mỹ, Tỉnh Hậu Giang",
        "code": "31490",
        "parent_code": "936"
    },
    "31492": {
        "name": "Lương Tâm",
        "type": "xa",
        "slug": "luong-tam",
        "name_with_type": "Xã Lương Tâm",
        "path": "Lương Tâm, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Lương Tâm, Huyện Long Mỹ, Tỉnh Hậu Giang",
        "code": "31492",
        "parent_code": "936"
    },
    "31493": {
        "name": "Lương Nghĩa",
        "type": "xa",
        "slug": "luong-nghia",
        "name_with_type": "Xã Lương Nghĩa",
        "path": "Lương Nghĩa, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Lương Nghĩa, Huyện Long Mỹ, Tỉnh Hậu Giang",
        "code": "31493",
        "parent_code": "936"
    },
    "31495": {
        "name": "Xà Phiên",
        "type": "xa",
        "slug": "xa-phien",
        "name_with_type": "Xã Xà Phiên",
        "path": "Xà Phiên, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Xà Phiên, Huyện Long Mỹ, Tỉnh Hậu Giang",
        "code": "31495",
        "parent_code": "936"
    },
    "31471": {
        "name": "Thuận An",
        "type": "phuong",
        "slug": "thuan-an",
        "name_with_type": "Phường Thuận An",
        "path": "Thuận An, Long Mỹ, Hậu Giang",
        "path_with_type": "Phường Thuận An, Thị xã Long Mỹ, Tỉnh Hậu Giang",
        "code": "31471",
        "parent_code": "937"
    },
    "31472": {
        "name": "Trà Lồng",
        "type": "phuong",
        "slug": "tra-long",
        "name_with_type": "Phường Trà Lồng",
        "path": "Trà Lồng, Long Mỹ, Hậu Giang",
        "path_with_type": "Phường Trà Lồng, Thị xã Long Mỹ, Tỉnh Hậu Giang",
        "code": "31472",
        "parent_code": "937"
    },
    "31473": {
        "name": "Bình Thạnh",
        "type": "phuong",
        "slug": "binh-thanh",
        "name_with_type": "Phường Bình Thạnh",
        "path": "Bình Thạnh, Long Mỹ, Hậu Giang",
        "path_with_type": "Phường Bình Thạnh, Thị xã Long Mỹ, Tỉnh Hậu Giang",
        "code": "31473",
        "parent_code": "937"
    },
    "31474": {
        "name": "Long Bình",
        "type": "xa",
        "slug": "long-binh",
        "name_with_type": "Xã Long Bình",
        "path": "Long Bình, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Long Bình, Thị xã Long Mỹ, Tỉnh Hậu Giang",
        "code": "31474",
        "parent_code": "937"
    },
    "31475": {
        "name": "Vĩnh Tường",
        "type": "phuong",
        "slug": "vinh-tuong",
        "name_with_type": "Phường Vĩnh Tường",
        "path": "Vĩnh Tường, Long Mỹ, Hậu Giang",
        "path_with_type": "Phường Vĩnh Tường, Thị xã Long Mỹ, Tỉnh Hậu Giang",
        "code": "31475",
        "parent_code": "937"
    },
    "31477": {
        "name": "Long Trị",
        "type": "xa",
        "slug": "long-tri",
        "name_with_type": "Xã Long Trị",
        "path": "Long Trị, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Long Trị, Thị xã Long Mỹ, Tỉnh Hậu Giang",
        "code": "31477",
        "parent_code": "937"
    },
    "31478": {
        "name": "Long Trị A",
        "type": "xa",
        "slug": "long-tri-a",
        "name_with_type": "Xã Long Trị A",
        "path": "Long Trị A, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Long Trị A, Thị xã Long Mỹ, Tỉnh Hậu Giang",
        "code": "31478",
        "parent_code": "937"
    },
    "31480": {
        "name": "Long Phú",
        "type": "xa",
        "slug": "long-phu",
        "name_with_type": "Xã Long Phú",
        "path": "Long Phú, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Long Phú, Thị xã Long Mỹ, Tỉnh Hậu Giang",
        "code": "31480",
        "parent_code": "937"
    },
    "31481": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Long Mỹ, Hậu Giang",
        "path_with_type": "Xã Tân Phú, Thị xã Long Mỹ, Tỉnh Hậu Giang",
        "code": "31481",
        "parent_code": "937"
    },
    "06649": {
        "name": "Hà Khánh",
        "type": "phuong",
        "slug": "ha-khanh",
        "name_with_type": "Phường Hà Khánh",
        "path": "Hà Khánh, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hà Khánh, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06649",
        "parent_code": "193"
    },
    "06652": {
        "name": "Hà Phong",
        "type": "phuong",
        "slug": "ha-phong",
        "name_with_type": "Phường Hà Phong",
        "path": "Hà Phong, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hà Phong, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06652",
        "parent_code": "193"
    },
    "06655": {
        "name": "Hà Khẩu",
        "type": "phuong",
        "slug": "ha-khau",
        "name_with_type": "Phường Hà Khẩu",
        "path": "Hà Khẩu, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hà Khẩu, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06655",
        "parent_code": "193"
    },
    "06658": {
        "name": "Cao Xanh",
        "type": "phuong",
        "slug": "cao-xanh",
        "name_with_type": "Phường Cao Xanh",
        "path": "Cao Xanh, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Cao Xanh, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06658",
        "parent_code": "193"
    },
    "06661": {
        "name": "Giếng Đáy",
        "type": "phuong",
        "slug": "gieng-day",
        "name_with_type": "Phường Giếng Đáy",
        "path": "Giếng Đáy, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Giếng Đáy, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06661",
        "parent_code": "193"
    },
    "06664": {
        "name": "Hà Tu",
        "type": "phuong",
        "slug": "ha-tu",
        "name_with_type": "Phường Hà Tu",
        "path": "Hà Tu, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hà Tu, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06664",
        "parent_code": "193"
    },
    "06667": {
        "name": "Hà Trung",
        "type": "phuong",
        "slug": "ha-trung",
        "name_with_type": "Phường Hà Trung",
        "path": "Hà Trung, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hà Trung, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06667",
        "parent_code": "193"
    },
    "06670": {
        "name": "Hà Lầm",
        "type": "phuong",
        "slug": "ha-lam",
        "name_with_type": "Phường Hà Lầm",
        "path": "Hà Lầm, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hà Lầm, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06670",
        "parent_code": "193"
    },
    "06673": {
        "name": "Bãi Cháy",
        "type": "phuong",
        "slug": "bai-chay",
        "name_with_type": "Phường Bãi Cháy",
        "path": "Bãi Cháy, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Bãi Cháy, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06673",
        "parent_code": "193"
    },
    "06676": {
        "name": "Cao Thắng",
        "type": "phuong",
        "slug": "cao-thang",
        "name_with_type": "Phường Cao Thắng",
        "path": "Cao Thắng, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Cao Thắng, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06676",
        "parent_code": "193"
    },
    "06679": {
        "name": "Hùng Thắng",
        "type": "phuong",
        "slug": "hung-thang",
        "name_with_type": "Phường Hùng Thắng",
        "path": "Hùng Thắng, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hùng Thắng, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06679",
        "parent_code": "193"
    },
    "06682": {
        "name": "Yết Kiêu",
        "type": "phuong",
        "slug": "yet-kieu",
        "name_with_type": "Phường Yết Kiêu",
        "path": "Yết Kiêu, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Yết Kiêu, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06682",
        "parent_code": "193"
    },
    "06685": {
        "name": "Trần Hưng Đạo",
        "type": "phuong",
        "slug": "tran-hung-dao",
        "name_with_type": "Phường Trần Hưng Đạo",
        "path": "Trần Hưng Đạo, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Trần Hưng Đạo, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06685",
        "parent_code": "193"
    },
    "06688": {
        "name": "Hồng Hải",
        "type": "phuong",
        "slug": "hong-hai",
        "name_with_type": "Phường Hồng Hải",
        "path": "Hồng Hải, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hồng Hải, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06688",
        "parent_code": "193"
    },
    "06691": {
        "name": "Hồng Gai",
        "type": "phuong",
        "slug": "hong-gai",
        "name_with_type": "Phường Hồng Gai",
        "path": "Hồng Gai, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hồng Gai, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06691",
        "parent_code": "193"
    },
    "06694": {
        "name": "Bạch Đằng",
        "type": "phuong",
        "slug": "bach-dang",
        "name_with_type": "Phường Bạch Đằng",
        "path": "Bạch Đằng, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Bạch Đằng, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06694",
        "parent_code": "193"
    },
    "06697": {
        "name": "Hồng Hà",
        "type": "phuong",
        "slug": "hong-ha",
        "name_with_type": "Phường Hồng Hà",
        "path": "Hồng Hà, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Hồng Hà, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06697",
        "parent_code": "193"
    },
    "06700": {
        "name": "Tuần Châu",
        "type": "phuong",
        "slug": "tuan-chau",
        "name_with_type": "Phường Tuần Châu",
        "path": "Tuần Châu, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Tuần Châu, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06700",
        "parent_code": "193"
    },
    "06703": {
        "name": "Việt Hưng",
        "type": "phuong",
        "slug": "viet-hung",
        "name_with_type": "Phường Việt Hưng",
        "path": "Việt Hưng, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Việt Hưng, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06703",
        "parent_code": "193"
    },
    "06706": {
        "name": "Đại Yên",
        "type": "phuong",
        "slug": "dai-yen",
        "name_with_type": "Phường Đại Yên",
        "path": "Đại Yên, Hạ Long, Quảng Ninh",
        "path_with_type": "Phường Đại Yên, Thành phố Hạ Long, Tỉnh Quảng Ninh",
        "code": "06706",
        "parent_code": "193"
    },
    "06709": {
        "name": "Ka Long",
        "type": "phuong",
        "slug": "ka-long",
        "name_with_type": "Phường Ka Long",
        "path": "Ka Long, Móng Cái, Quảng Ninh",
        "path_with_type": "Phường Ka Long, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06709",
        "parent_code": "194"
    },
    "06712": {
        "name": "Trần Phú",
        "type": "phuong",
        "slug": "tran-phu",
        "name_with_type": "Phường Trần Phú",
        "path": "Trần Phú, Móng Cái, Quảng Ninh",
        "path_with_type": "Phường Trần Phú, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06712",
        "parent_code": "194"
    },
    "06715": {
        "name": "Ninh Dương",
        "type": "phuong",
        "slug": "ninh-duong",
        "name_with_type": "Phường Ninh Dương",
        "path": "Ninh Dương, Móng Cái, Quảng Ninh",
        "path_with_type": "Phường Ninh Dương, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06715",
        "parent_code": "194"
    },
    "06718": {
        "name": "Hoà Lạc",
        "type": "phuong",
        "slug": "hoa-lac",
        "name_with_type": "Phường Hoà Lạc",
        "path": "Hoà Lạc, Móng Cái, Quảng Ninh",
        "path_with_type": "Phường Hoà Lạc, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06718",
        "parent_code": "194"
    },
    "06721": {
        "name": "Trà Cổ",
        "type": "phuong",
        "slug": "tra-co",
        "name_with_type": "Phường Trà Cổ",
        "path": "Trà Cổ, Móng Cái, Quảng Ninh",
        "path_with_type": "Phường Trà Cổ, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06721",
        "parent_code": "194"
    },
    "06724": {
        "name": "Hải Sơn",
        "type": "xa",
        "slug": "hai-son",
        "name_with_type": "Xã Hải Sơn",
        "path": "Hải Sơn, Móng Cái, Quảng Ninh",
        "path_with_type": "Xã Hải Sơn, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06724",
        "parent_code": "194"
    },
    "06727": {
        "name": "Bắc Sơn",
        "type": "xa",
        "slug": "bac-son",
        "name_with_type": "Xã Bắc Sơn",
        "path": "Bắc Sơn, Móng Cái, Quảng Ninh",
        "path_with_type": "Xã Bắc Sơn, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06727",
        "parent_code": "194"
    },
    "06730": {
        "name": "Hải Đông",
        "type": "xa",
        "slug": "hai-dong",
        "name_with_type": "Xã Hải Đông",
        "path": "Hải Đông, Móng Cái, Quảng Ninh",
        "path_with_type": "Xã Hải Đông, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06730",
        "parent_code": "194"
    },
    "06733": {
        "name": "Hải Tiến",
        "type": "xa",
        "slug": "hai-tien",
        "name_with_type": "Xã Hải Tiến",
        "path": "Hải Tiến, Móng Cái, Quảng Ninh",
        "path_with_type": "Xã Hải Tiến, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06733",
        "parent_code": "194"
    },
    "06736": {
        "name": "Hải Yên",
        "type": "phuong",
        "slug": "hai-yen",
        "name_with_type": "Phường Hải Yên",
        "path": "Hải Yên, Móng Cái, Quảng Ninh",
        "path_with_type": "Phường Hải Yên, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06736",
        "parent_code": "194"
    },
    "06739": {
        "name": "Quảng Nghĩa",
        "type": "xa",
        "slug": "quang-nghia",
        "name_with_type": "Xã Quảng Nghĩa",
        "path": "Quảng Nghĩa, Móng Cái, Quảng Ninh",
        "path_with_type": "Xã Quảng Nghĩa, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06739",
        "parent_code": "194"
    },
    "06742": {
        "name": "Hải Hoà",
        "type": "phuong",
        "slug": "hai-hoa",
        "name_with_type": "Phường Hải Hoà",
        "path": "Hải Hoà, Móng Cái, Quảng Ninh",
        "path_with_type": "Phường Hải Hoà, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06742",
        "parent_code": "194"
    },
    "06745": {
        "name": "Hải Xuân",
        "type": "xa",
        "slug": "hai-xuan",
        "name_with_type": "Xã Hải Xuân",
        "path": "Hải Xuân, Móng Cái, Quảng Ninh",
        "path_with_type": "Xã Hải Xuân, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06745",
        "parent_code": "194"
    },
    "06748": {
        "name": "Vạn Ninh",
        "type": "xa",
        "slug": "van-ninh",
        "name_with_type": "Xã Vạn Ninh",
        "path": "Vạn Ninh, Móng Cái, Quảng Ninh",
        "path_with_type": "Xã Vạn Ninh, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06748",
        "parent_code": "194"
    },
    "06751": {
        "name": "Bình Ngọc",
        "type": "phuong",
        "slug": "binh-ngoc",
        "name_with_type": "Phường Bình Ngọc",
        "path": "Bình Ngọc, Móng Cái, Quảng Ninh",
        "path_with_type": "Phường Bình Ngọc, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06751",
        "parent_code": "194"
    },
    "06754": {
        "name": "Vĩnh Trung",
        "type": "xa",
        "slug": "vinh-trung",
        "name_with_type": "Xã Vĩnh Trung",
        "path": "Vĩnh Trung, Móng Cái, Quảng Ninh",
        "path_with_type": "Xã Vĩnh Trung, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06754",
        "parent_code": "194"
    },
    "06757": {
        "name": "Vĩnh Thực",
        "type": "xa",
        "slug": "vinh-thuc",
        "name_with_type": "Xã Vĩnh Thực",
        "path": "Vĩnh Thực, Móng Cái, Quảng Ninh",
        "path_with_type": "Xã Vĩnh Thực, Thành phố Móng Cái, Tỉnh Quảng Ninh",
        "code": "06757",
        "parent_code": "194"
    },
    "06760": {
        "name": "Mông Dương",
        "type": "phuong",
        "slug": "mong-duong",
        "name_with_type": "Phường Mông Dương",
        "path": "Mông Dương, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Mông Dương, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06760",
        "parent_code": "195"
    },
    "06763": {
        "name": "Cửa Ông",
        "type": "phuong",
        "slug": "cua-ong",
        "name_with_type": "Phường Cửa Ông",
        "path": "Cửa Ông, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cửa Ông, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06763",
        "parent_code": "195"
    },
    "06766": {
        "name": "Cẩm Sơn",
        "type": "phuong",
        "slug": "cam-son",
        "name_with_type": "Phường Cẩm Sơn",
        "path": "Cẩm Sơn, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Sơn, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06766",
        "parent_code": "195"
    },
    "06769": {
        "name": "Cẩm Đông",
        "type": "phuong",
        "slug": "cam-dong",
        "name_with_type": "Phường Cẩm Đông",
        "path": "Cẩm Đông, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Đông, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06769",
        "parent_code": "195"
    },
    "06772": {
        "name": "Cẩm Phú",
        "type": "phuong",
        "slug": "cam-phu",
        "name_with_type": "Phường Cẩm Phú",
        "path": "Cẩm Phú, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Phú, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06772",
        "parent_code": "195"
    },
    "06775": {
        "name": "Cẩm Tây",
        "type": "phuong",
        "slug": "cam-tay",
        "name_with_type": "Phường Cẩm Tây",
        "path": "Cẩm Tây, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Tây, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06775",
        "parent_code": "195"
    },
    "06778": {
        "name": "Quang Hanh",
        "type": "phuong",
        "slug": "quang-hanh",
        "name_with_type": "Phường Quang Hanh",
        "path": "Quang Hanh, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Quang Hanh, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06778",
        "parent_code": "195"
    },
    "06781": {
        "name": "Cẩm Thịnh",
        "type": "phuong",
        "slug": "cam-thinh",
        "name_with_type": "Phường Cẩm Thịnh",
        "path": "Cẩm Thịnh, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Thịnh, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06781",
        "parent_code": "195"
    },
    "06784": {
        "name": "Cẩm Thủy",
        "type": "phuong",
        "slug": "cam-thuy",
        "name_with_type": "Phường Cẩm Thủy",
        "path": "Cẩm Thủy, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Thủy, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06784",
        "parent_code": "195"
    },
    "06787": {
        "name": "Cẩm Thạch",
        "type": "phuong",
        "slug": "cam-thach",
        "name_with_type": "Phường Cẩm Thạch",
        "path": "Cẩm Thạch, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Thạch, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06787",
        "parent_code": "195"
    },
    "06790": {
        "name": "Cẩm Thành",
        "type": "phuong",
        "slug": "cam-thanh",
        "name_with_type": "Phường Cẩm Thành",
        "path": "Cẩm Thành, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Thành, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06790",
        "parent_code": "195"
    },
    "06793": {
        "name": "Cẩm Trung",
        "type": "phuong",
        "slug": "cam-trung",
        "name_with_type": "Phường Cẩm Trung",
        "path": "Cẩm Trung, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Trung, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06793",
        "parent_code": "195"
    },
    "06796": {
        "name": "Cẩm Bình",
        "type": "phuong",
        "slug": "cam-binh",
        "name_with_type": "Phường Cẩm Bình",
        "path": "Cẩm Bình, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Phường Cẩm Bình, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06796",
        "parent_code": "195"
    },
    "06799": {
        "name": "Cộng Hòa",
        "type": "xa",
        "slug": "cong-hoa",
        "name_with_type": "Xã Cộng Hòa",
        "path": "Cộng Hòa, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Xã Cộng Hòa, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06799",
        "parent_code": "195"
    },
    "06802": {
        "name": "Cẩm Hải",
        "type": "xa",
        "slug": "cam-hai",
        "name_with_type": "Xã Cẩm Hải",
        "path": "Cẩm Hải, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Xã Cẩm Hải, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06802",
        "parent_code": "195"
    },
    "06805": {
        "name": "Dương Huy",
        "type": "xa",
        "slug": "duong-huy",
        "name_with_type": "Xã Dương Huy",
        "path": "Dương Huy, Cẩm Phả, Quảng Ninh",
        "path_with_type": "Xã Dương Huy, Thành phố Cẩm Phả, Tỉnh Quảng Ninh",
        "code": "06805",
        "parent_code": "195"
    },
    "06808": {
        "name": "Vàng Danh",
        "type": "phuong",
        "slug": "vang-danh",
        "name_with_type": "Phường Vàng Danh",
        "path": "Vàng Danh, Uông Bí, Quảng Ninh",
        "path_with_type": "Phường Vàng Danh, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06808",
        "parent_code": "196"
    },
    "06811": {
        "name": "Thanh Sơn",
        "type": "phuong",
        "slug": "thanh-son",
        "name_with_type": "Phường Thanh Sơn",
        "path": "Thanh Sơn, Uông Bí, Quảng Ninh",
        "path_with_type": "Phường Thanh Sơn, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06811",
        "parent_code": "196"
    },
    "06814": {
        "name": "Bắc Sơn",
        "type": "phuong",
        "slug": "bac-son",
        "name_with_type": "Phường Bắc Sơn",
        "path": "Bắc Sơn, Uông Bí, Quảng Ninh",
        "path_with_type": "Phường Bắc Sơn, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06814",
        "parent_code": "196"
    },
    "06817": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Uông Bí, Quảng Ninh",
        "path_with_type": "Phường Quang Trung, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06817",
        "parent_code": "196"
    },
    "06820": {
        "name": "Trưng Vương",
        "type": "phuong",
        "slug": "trung-vuong",
        "name_with_type": "Phường Trưng Vương",
        "path": "Trưng Vương, Uông Bí, Quảng Ninh",
        "path_with_type": "Phường Trưng Vương, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06820",
        "parent_code": "196"
    },
    "06823": {
        "name": "Nam Khê",
        "type": "phuong",
        "slug": "nam-khe",
        "name_with_type": "Phường Nam Khê",
        "path": "Nam Khê, Uông Bí, Quảng Ninh",
        "path_with_type": "Phường Nam Khê, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06823",
        "parent_code": "196"
    },
    "06826": {
        "name": "Yên Thanh",
        "type": "phuong",
        "slug": "yen-thanh",
        "name_with_type": "Phường Yên Thanh",
        "path": "Yên Thanh, Uông Bí, Quảng Ninh",
        "path_with_type": "Phường Yên Thanh, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06826",
        "parent_code": "196"
    },
    "06829": {
        "name": "Thượng Yên Công",
        "type": "xa",
        "slug": "thuong-yen-cong",
        "name_with_type": "Xã Thượng Yên Công",
        "path": "Thượng Yên Công, Uông Bí, Quảng Ninh",
        "path_with_type": "Xã Thượng Yên Công, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06829",
        "parent_code": "196"
    },
    "06832": {
        "name": "Phương Đông",
        "type": "phuong",
        "slug": "phuong-dong",
        "name_with_type": "Phường Phương Đông",
        "path": "Phương Đông, Uông Bí, Quảng Ninh",
        "path_with_type": "Phường Phương Đông, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06832",
        "parent_code": "196"
    },
    "06835": {
        "name": "Phương Nam",
        "type": "phuong",
        "slug": "phuong-nam",
        "name_with_type": "Phường Phương Nam",
        "path": "Phương Nam, Uông Bí, Quảng Ninh",
        "path_with_type": "Phường Phương Nam, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "06835",
        "parent_code": "196"
    },
    "07141": {
        "name": "Điền Công",
        "type": "xa",
        "slug": "dien-cong",
        "name_with_type": "Xã Điền Công",
        "path": "Điền Công, Uông Bí, Quảng Ninh",
        "path_with_type": "Xã Điền Công, Thành phố Uông Bí, Tỉnh Quảng Ninh",
        "code": "07141",
        "parent_code": "196"
    },
    "06838": {
        "name": "Bình Liêu",
        "type": "thi-tran",
        "slug": "binh-lieu",
        "name_with_type": "Thị trấn Bình Liêu",
        "path": "Bình Liêu, Bình Liêu, Quảng Ninh",
        "path_with_type": "Thị trấn Bình Liêu, Huyện Bình Liêu, Tỉnh Quảng Ninh",
        "code": "06838",
        "parent_code": "198"
    },
    "06841": {
        "name": "Hoành Mô",
        "type": "xa",
        "slug": "hoanh-mo",
        "name_with_type": "Xã Hoành Mô",
        "path": "Hoành Mô, Bình Liêu, Quảng Ninh",
        "path_with_type": "Xã Hoành Mô, Huyện Bình Liêu, Tỉnh Quảng Ninh",
        "code": "06841",
        "parent_code": "198"
    },
    "06844": {
        "name": "Đồng Tâm",
        "type": "xa",
        "slug": "dong-tam",
        "name_with_type": "Xã Đồng Tâm",
        "path": "Đồng Tâm, Bình Liêu, Quảng Ninh",
        "path_with_type": "Xã Đồng Tâm, Huyện Bình Liêu, Tỉnh Quảng Ninh",
        "code": "06844",
        "parent_code": "198"
    },
    "06847": {
        "name": "Đồng Văn",
        "type": "xa",
        "slug": "dong-van",
        "name_with_type": "Xã Đồng Văn",
        "path": "Đồng Văn, Bình Liêu, Quảng Ninh",
        "path_with_type": "Xã Đồng Văn, Huyện Bình Liêu, Tỉnh Quảng Ninh",
        "code": "06847",
        "parent_code": "198"
    },
    "06850": {
        "name": "Tình Húc",
        "type": "xa",
        "slug": "tinh-huc",
        "name_with_type": "Xã Tình Húc",
        "path": "Tình Húc, Bình Liêu, Quảng Ninh",
        "path_with_type": "Xã Tình Húc, Huyện Bình Liêu, Tỉnh Quảng Ninh",
        "code": "06850",
        "parent_code": "198"
    },
    "06853": {
        "name": "Vô Ngại",
        "type": "xa",
        "slug": "vo-ngai",
        "name_with_type": "Xã Vô Ngại",
        "path": "Vô Ngại, Bình Liêu, Quảng Ninh",
        "path_with_type": "Xã Vô Ngại, Huyện Bình Liêu, Tỉnh Quảng Ninh",
        "code": "06853",
        "parent_code": "198"
    },
    "06856": {
        "name": "Lục Hồn",
        "type": "xa",
        "slug": "luc-hon",
        "name_with_type": "Xã Lục Hồn",
        "path": "Lục Hồn, Bình Liêu, Quảng Ninh",
        "path_with_type": "Xã Lục Hồn, Huyện Bình Liêu, Tỉnh Quảng Ninh",
        "code": "06856",
        "parent_code": "198"
    },
    "06859": {
        "name": "Húc Động",
        "type": "xa",
        "slug": "huc-dong",
        "name_with_type": "Xã Húc Động",
        "path": "Húc Động, Bình Liêu, Quảng Ninh",
        "path_with_type": "Xã Húc Động, Huyện Bình Liêu, Tỉnh Quảng Ninh",
        "code": "06859",
        "parent_code": "198"
    },
    "06862": {
        "name": "Tiên Yên",
        "type": "thi-tran",
        "slug": "tien-yen",
        "name_with_type": "Thị trấn Tiên Yên",
        "path": "Tiên Yên, Tiên Yên, Quảng Ninh",
        "path_with_type": "Thị trấn Tiên Yên, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06862",
        "parent_code": "199"
    },
    "06865": {
        "name": "Hà Lâu",
        "type": "xa",
        "slug": "ha-lau",
        "name_with_type": "Xã Hà Lâu",
        "path": "Hà Lâu, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Hà Lâu, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06865",
        "parent_code": "199"
    },
    "06868": {
        "name": "Đại Dực",
        "type": "xa",
        "slug": "dai-duc",
        "name_with_type": "Xã Đại Dực",
        "path": "Đại Dực, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Đại Dực, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06868",
        "parent_code": "199"
    },
    "06869": {
        "name": "Đại Thành",
        "type": "xa",
        "slug": "dai-thanh",
        "name_with_type": "Xã Đại Thành",
        "path": "Đại Thành, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Đại Thành, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06869",
        "parent_code": "199"
    },
    "06871": {
        "name": "Phong Dụ",
        "type": "xa",
        "slug": "phong-du",
        "name_with_type": "Xã Phong Dụ",
        "path": "Phong Dụ, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Phong Dụ, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06871",
        "parent_code": "199"
    },
    "06874": {
        "name": "Điền Xá",
        "type": "xa",
        "slug": "dien-xa",
        "name_with_type": "Xã Điền Xá",
        "path": "Điền Xá, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Điền Xá, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06874",
        "parent_code": "199"
    },
    "06877": {
        "name": "Đông Ngũ",
        "type": "xa",
        "slug": "dong-ngu",
        "name_with_type": "Xã Đông Ngũ",
        "path": "Đông Ngũ, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Đông Ngũ, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06877",
        "parent_code": "199"
    },
    "06880": {
        "name": "Yên Than",
        "type": "xa",
        "slug": "yen-than",
        "name_with_type": "Xã Yên Than",
        "path": "Yên Than, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Yên Than, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06880",
        "parent_code": "199"
    },
    "06883": {
        "name": "Đông Hải",
        "type": "xa",
        "slug": "dong-hai",
        "name_with_type": "Xã Đông Hải",
        "path": "Đông Hải, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Đông Hải, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06883",
        "parent_code": "199"
    },
    "06886": {
        "name": "Hải Lạng",
        "type": "xa",
        "slug": "hai-lang",
        "name_with_type": "Xã Hải Lạng",
        "path": "Hải Lạng, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Hải Lạng, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06886",
        "parent_code": "199"
    },
    "06889": {
        "name": "Tiên Lãng",
        "type": "xa",
        "slug": "tien-lang",
        "name_with_type": "Xã Tiên Lãng",
        "path": "Tiên Lãng, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Tiên Lãng, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06889",
        "parent_code": "199"
    },
    "06892": {
        "name": "Đồng Rui",
        "type": "xa",
        "slug": "dong-rui",
        "name_with_type": "Xã Đồng Rui",
        "path": "Đồng Rui, Tiên Yên, Quảng Ninh",
        "path_with_type": "Xã Đồng Rui, Huyện Tiên Yên, Tỉnh Quảng Ninh",
        "code": "06892",
        "parent_code": "199"
    },
    "06895": {
        "name": "Đầm Hà",
        "type": "thi-tran",
        "slug": "dam-ha",
        "name_with_type": "Thị trấn Đầm Hà",
        "path": "Đầm Hà, Đầm Hà, Quảng Ninh",
        "path_with_type": "Thị trấn Đầm Hà, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06895",
        "parent_code": "200"
    },
    "06898": {
        "name": "Quảng Lâm",
        "type": "xa",
        "slug": "quang-lam",
        "name_with_type": "Xã Quảng Lâm",
        "path": "Quảng Lâm, Đầm Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Lâm, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06898",
        "parent_code": "200"
    },
    "06901": {
        "name": "Quảng An",
        "type": "xa",
        "slug": "quang-an",
        "name_with_type": "Xã Quảng An",
        "path": "Quảng An, Đầm Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng An, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06901",
        "parent_code": "200"
    },
    "06904": {
        "name": "Tân Bình",
        "type": "xa",
        "slug": "tan-binh",
        "name_with_type": "Xã Tân Bình",
        "path": "Tân Bình, Đầm Hà, Quảng Ninh",
        "path_with_type": "Xã Tân Bình, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06904",
        "parent_code": "200"
    },
    "06907": {
        "name": "Quảng Lợi",
        "type": "xa",
        "slug": "quang-loi",
        "name_with_type": "Xã Quảng Lợi",
        "path": "Quảng Lợi, Đầm Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Lợi, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06907",
        "parent_code": "200"
    },
    "06910": {
        "name": "Dực Yên",
        "type": "xa",
        "slug": "duc-yen",
        "name_with_type": "Xã Dực Yên",
        "path": "Dực Yên, Đầm Hà, Quảng Ninh",
        "path_with_type": "Xã Dực Yên, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06910",
        "parent_code": "200"
    },
    "06913": {
        "name": "Quảng Tân",
        "type": "xa",
        "slug": "quang-tan",
        "name_with_type": "Xã Quảng Tân",
        "path": "Quảng Tân, Đầm Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Tân, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06913",
        "parent_code": "200"
    },
    "06916": {
        "name": "Đầm Hà",
        "type": "xa",
        "slug": "dam-ha",
        "name_with_type": "Xã Đầm Hà",
        "path": "Đầm Hà, Đầm Hà, Quảng Ninh",
        "path_with_type": "Xã Đầm Hà, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06916",
        "parent_code": "200"
    },
    "06917": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Đầm Hà, Quảng Ninh",
        "path_with_type": "Xã Tân Lập, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06917",
        "parent_code": "200"
    },
    "06919": {
        "name": "Đại Bình",
        "type": "xa",
        "slug": "dai-binh",
        "name_with_type": "Xã Đại Bình",
        "path": "Đại Bình, Đầm Hà, Quảng Ninh",
        "path_with_type": "Xã Đại Bình, Huyện Đầm Hà, Tỉnh Quảng Ninh",
        "code": "06919",
        "parent_code": "200"
    },
    "06922": {
        "name": "Quảng Hà",
        "type": "thi-tran",
        "slug": "quang-ha",
        "name_with_type": "Thị trấn Quảng Hà",
        "path": "Quảng Hà, Hải Hà, Quảng Ninh",
        "path_with_type": "Thị trấn Quảng Hà, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06922",
        "parent_code": "201"
    },
    "06925": {
        "name": "Quảng Đức",
        "type": "xa",
        "slug": "quang-duc",
        "name_with_type": "Xã Quảng Đức",
        "path": "Quảng Đức, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Đức, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06925",
        "parent_code": "201"
    },
    "06928": {
        "name": "Quảng Sơn",
        "type": "xa",
        "slug": "quang-son",
        "name_with_type": "Xã Quảng Sơn",
        "path": "Quảng Sơn, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Sơn, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06928",
        "parent_code": "201"
    },
    "06931": {
        "name": "Quảng Thành",
        "type": "xa",
        "slug": "quang-thanh",
        "name_with_type": "Xã Quảng Thành",
        "path": "Quảng Thành, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Thành, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06931",
        "parent_code": "201"
    },
    "06934": {
        "name": "Quảng Thắng",
        "type": "xa",
        "slug": "quang-thang",
        "name_with_type": "Xã Quảng Thắng",
        "path": "Quảng Thắng, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Thắng, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06934",
        "parent_code": "201"
    },
    "06937": {
        "name": "Quảng Thịnh",
        "type": "xa",
        "slug": "quang-thinh",
        "name_with_type": "Xã Quảng Thịnh",
        "path": "Quảng Thịnh, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Thịnh, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06937",
        "parent_code": "201"
    },
    "06940": {
        "name": "Quảng Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quảng Minh",
        "path": "Quảng Minh, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Minh, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06940",
        "parent_code": "201"
    },
    "06943": {
        "name": "Quảng Chính",
        "type": "xa",
        "slug": "quang-chinh",
        "name_with_type": "Xã Quảng Chính",
        "path": "Quảng Chính, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Chính, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06943",
        "parent_code": "201"
    },
    "06946": {
        "name": "Quảng Long",
        "type": "xa",
        "slug": "quang-long",
        "name_with_type": "Xã Quảng Long",
        "path": "Quảng Long, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Long, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06946",
        "parent_code": "201"
    },
    "06949": {
        "name": "Đường Hoa",
        "type": "xa",
        "slug": "duong-hoa",
        "name_with_type": "Xã Đường Hoa",
        "path": "Đường Hoa, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Đường Hoa, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06949",
        "parent_code": "201"
    },
    "06952": {
        "name": "Quảng Phong",
        "type": "xa",
        "slug": "quang-phong",
        "name_with_type": "Xã Quảng Phong",
        "path": "Quảng Phong, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Phong, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06952",
        "parent_code": "201"
    },
    "06955": {
        "name": "Quảng Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quảng Trung",
        "path": "Quảng Trung, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Trung, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06955",
        "parent_code": "201"
    },
    "06958": {
        "name": "Phú Hải",
        "type": "xa",
        "slug": "phu-hai",
        "name_with_type": "Xã Phú Hải",
        "path": "Phú Hải, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Phú Hải, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06958",
        "parent_code": "201"
    },
    "06961": {
        "name": "Quảng Điền",
        "type": "xa",
        "slug": "quang-dien",
        "name_with_type": "Xã Quảng Điền",
        "path": "Quảng Điền, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Quảng Điền, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06961",
        "parent_code": "201"
    },
    "06964": {
        "name": "Tiến Tới",
        "type": "xa",
        "slug": "tien-toi",
        "name_with_type": "Xã Tiến Tới",
        "path": "Tiến Tới, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Tiến Tới, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06964",
        "parent_code": "201"
    },
    "06967": {
        "name": "Cái Chiên",
        "type": "xa",
        "slug": "cai-chien",
        "name_with_type": "Xã Cái Chiên",
        "path": "Cái Chiên, Hải Hà, Quảng Ninh",
        "path_with_type": "Xã Cái Chiên, Huyện Hải Hà, Tỉnh Quảng Ninh",
        "code": "06967",
        "parent_code": "201"
    },
    "06970": {
        "name": "Ba Chẽ",
        "type": "thi-tran",
        "slug": "ba-che",
        "name_with_type": "Thị trấn Ba Chẽ",
        "path": "Ba Chẽ, Ba Chẽ, Quảng Ninh",
        "path_with_type": "Thị trấn Ba Chẽ, Huyện Ba Chẽ, Tỉnh Quảng Ninh",
        "code": "06970",
        "parent_code": "202"
    },
    "06973": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Ba Chẽ, Quảng Ninh",
        "path_with_type": "Xã Thanh Sơn, Huyện Ba Chẽ, Tỉnh Quảng Ninh",
        "code": "06973",
        "parent_code": "202"
    },
    "06976": {
        "name": "Thanh Lâm",
        "type": "xa",
        "slug": "thanh-lam",
        "name_with_type": "Xã Thanh Lâm",
        "path": "Thanh Lâm, Ba Chẽ, Quảng Ninh",
        "path_with_type": "Xã Thanh Lâm, Huyện Ba Chẽ, Tỉnh Quảng Ninh",
        "code": "06976",
        "parent_code": "202"
    },
    "06979": {
        "name": "Đạp Thanh",
        "type": "xa",
        "slug": "dap-thanh",
        "name_with_type": "Xã Đạp Thanh",
        "path": "Đạp Thanh, Ba Chẽ, Quảng Ninh",
        "path_with_type": "Xã Đạp Thanh, Huyện Ba Chẽ, Tỉnh Quảng Ninh",
        "code": "06979",
        "parent_code": "202"
    },
    "06982": {
        "name": "Nam Sơn",
        "type": "xa",
        "slug": "nam-son",
        "name_with_type": "Xã Nam Sơn",
        "path": "Nam Sơn, Ba Chẽ, Quảng Ninh",
        "path_with_type": "Xã Nam Sơn, Huyện Ba Chẽ, Tỉnh Quảng Ninh",
        "code": "06982",
        "parent_code": "202"
    },
    "06985": {
        "name": "Lương Mông",
        "type": "xa",
        "slug": "luong-mong",
        "name_with_type": "Xã Lương Mông",
        "path": "Lương Mông, Ba Chẽ, Quảng Ninh",
        "path_with_type": "Xã Lương Mông, Huyện Ba Chẽ, Tỉnh Quảng Ninh",
        "code": "06985",
        "parent_code": "202"
    },
    "06988": {
        "name": "Đồn Đạc",
        "type": "xa",
        "slug": "don-dac",
        "name_with_type": "Xã Đồn Đạc",
        "path": "Đồn Đạc, Ba Chẽ, Quảng Ninh",
        "path_with_type": "Xã Đồn Đạc, Huyện Ba Chẽ, Tỉnh Quảng Ninh",
        "code": "06988",
        "parent_code": "202"
    },
    "06991": {
        "name": "Minh Cầm",
        "type": "xa",
        "slug": "minh-cam",
        "name_with_type": "Xã Minh Cầm",
        "path": "Minh Cầm, Ba Chẽ, Quảng Ninh",
        "path_with_type": "Xã Minh Cầm, Huyện Ba Chẽ, Tỉnh Quảng Ninh",
        "code": "06991",
        "parent_code": "202"
    },
    "06994": {
        "name": "Cái Rồng",
        "type": "thi-tran",
        "slug": "cai-rong",
        "name_with_type": "Thị trấn Cái Rồng",
        "path": "Cái Rồng, Vân Đồn, Quảng Ninh",
        "path_with_type": "Thị trấn Cái Rồng, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "06994",
        "parent_code": "203"
    },
    "06997": {
        "name": "Đài Xuyên",
        "type": "xa",
        "slug": "dai-xuyen",
        "name_with_type": "Xã Đài Xuyên",
        "path": "Đài Xuyên, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Đài Xuyên, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "06997",
        "parent_code": "203"
    },
    "07000": {
        "name": "Bình Dân",
        "type": "xa",
        "slug": "binh-dan",
        "name_with_type": "Xã Bình Dân",
        "path": "Bình Dân, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Bình Dân, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07000",
        "parent_code": "203"
    },
    "07003": {
        "name": "Vạn Yên",
        "type": "xa",
        "slug": "van-yen",
        "name_with_type": "Xã Vạn Yên",
        "path": "Vạn Yên, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Vạn Yên, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07003",
        "parent_code": "203"
    },
    "07006": {
        "name": "Minh Châu",
        "type": "xa",
        "slug": "minh-chau",
        "name_with_type": "Xã Minh Châu",
        "path": "Minh Châu, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Minh Châu, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07006",
        "parent_code": "203"
    },
    "07009": {
        "name": "Đoàn Kết",
        "type": "xa",
        "slug": "doan-ket",
        "name_with_type": "Xã Đoàn Kết",
        "path": "Đoàn Kết, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Đoàn Kết, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07009",
        "parent_code": "203"
    },
    "07012": {
        "name": "Hạ Long",
        "type": "xa",
        "slug": "ha-long",
        "name_with_type": "Xã Hạ Long",
        "path": "Hạ Long, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Hạ Long, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07012",
        "parent_code": "203"
    },
    "07015": {
        "name": "Đông Xá",
        "type": "xa",
        "slug": "dong-xa",
        "name_with_type": "Xã Đông Xá",
        "path": "Đông Xá, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Đông Xá, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07015",
        "parent_code": "203"
    },
    "07018": {
        "name": "Bản Sen",
        "type": "xa",
        "slug": "ban-sen",
        "name_with_type": "Xã Bản Sen",
        "path": "Bản Sen, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Bản Sen, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07018",
        "parent_code": "203"
    },
    "07021": {
        "name": "Thắng Lợi",
        "type": "xa",
        "slug": "thang-loi",
        "name_with_type": "Xã Thắng Lợi",
        "path": "Thắng Lợi, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Thắng Lợi, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07021",
        "parent_code": "203"
    },
    "07024": {
        "name": "Quan Lạn",
        "type": "xa",
        "slug": "quan-lan",
        "name_with_type": "Xã Quan Lạn",
        "path": "Quan Lạn, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Quan Lạn, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07024",
        "parent_code": "203"
    },
    "07027": {
        "name": "Ngọc Vừng",
        "type": "xa",
        "slug": "ngoc-vung",
        "name_with_type": "Xã Ngọc Vừng",
        "path": "Ngọc Vừng, Vân Đồn, Quảng Ninh",
        "path_with_type": "Xã Ngọc Vừng, Huyện Vân Đồn, Tỉnh Quảng Ninh",
        "code": "07027",
        "parent_code": "203"
    },
    "07030": {
        "name": "Trới",
        "type": "thi-tran",
        "slug": "troi",
        "name_with_type": "Thị trấn Trới",
        "path": "Trới, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Thị trấn Trới, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07030",
        "parent_code": "204"
    },
    "07033": {
        "name": "Kỳ Thượng",
        "type": "xa",
        "slug": "ky-thuong",
        "name_with_type": "Xã Kỳ Thượng",
        "path": "Kỳ Thượng, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Kỳ Thượng, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07033",
        "parent_code": "204"
    },
    "07036": {
        "name": "Đồng Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đồng Sơn",
        "path": "Đồng Sơn, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Đồng Sơn, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07036",
        "parent_code": "204"
    },
    "07039": {
        "name": "Tân Dân",
        "type": "xa",
        "slug": "tan-dan",
        "name_with_type": "Xã Tân Dân",
        "path": "Tân Dân, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Tân Dân, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07039",
        "parent_code": "204"
    },
    "07042": {
        "name": "Đồng Lâm",
        "type": "xa",
        "slug": "dong-lam",
        "name_with_type": "Xã Đồng Lâm",
        "path": "Đồng Lâm, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Đồng Lâm, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07042",
        "parent_code": "204"
    },
    "07045": {
        "name": "Hòa Bình",
        "type": "xa",
        "slug": "hoa-binh",
        "name_with_type": "Xã Hòa Bình",
        "path": "Hòa Bình, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Hòa Bình, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07045",
        "parent_code": "204"
    },
    "07048": {
        "name": "Vũ Oai",
        "type": "xa",
        "slug": "vu-oai",
        "name_with_type": "Xã Vũ Oai",
        "path": "Vũ Oai, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Vũ Oai, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07048",
        "parent_code": "204"
    },
    "07051": {
        "name": "Dân Chủ",
        "type": "xa",
        "slug": "dan-chu",
        "name_with_type": "Xã Dân Chủ",
        "path": "Dân Chủ, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Dân Chủ, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07051",
        "parent_code": "204"
    },
    "07054": {
        "name": "Quảng La",
        "type": "xa",
        "slug": "quang-la",
        "name_with_type": "Xã Quảng La",
        "path": "Quảng La, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Quảng La, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07054",
        "parent_code": "204"
    },
    "07057": {
        "name": "Bằng Cả",
        "type": "xa",
        "slug": "bang-ca",
        "name_with_type": "Xã Bằng Cả",
        "path": "Bằng Cả, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Bằng Cả, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07057",
        "parent_code": "204"
    },
    "07060": {
        "name": "Thống Nhất",
        "type": "xa",
        "slug": "thong-nhat",
        "name_with_type": "Xã Thống Nhất",
        "path": "Thống Nhất, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Thống Nhất, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07060",
        "parent_code": "204"
    },
    "07063": {
        "name": "Sơn Dương",
        "type": "xa",
        "slug": "son-duong",
        "name_with_type": "Xã Sơn Dương",
        "path": "Sơn Dương, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Sơn Dương, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07063",
        "parent_code": "204"
    },
    "07066": {
        "name": "Lê Lợi",
        "type": "xa",
        "slug": "le-loi",
        "name_with_type": "Xã Lê Lợi",
        "path": "Lê Lợi, Hoành Bồ, Quảng Ninh",
        "path_with_type": "Xã Lê Lợi, Huyện Hoành Bồ, Tỉnh Quảng Ninh",
        "code": "07066",
        "parent_code": "204"
    },
    "07069": {
        "name": "Mạo Khê",
        "type": "phuong",
        "slug": "mao-khe",
        "name_with_type": "Phường Mạo Khê",
        "path": "Mạo Khê, Đông Triều, Quảng Ninh",
        "path_with_type": "Phường Mạo Khê, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07069",
        "parent_code": "205"
    },
    "07072": {
        "name": "Đông Triều",
        "type": "phuong",
        "slug": "dong-trieu",
        "name_with_type": "Phường Đông Triều",
        "path": "Đông Triều, Đông Triều, Quảng Ninh",
        "path_with_type": "Phường Đông Triều, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07072",
        "parent_code": "205"
    },
    "07075": {
        "name": "An Sinh",
        "type": "xa",
        "slug": "an-sinh",
        "name_with_type": "Xã An Sinh",
        "path": "An Sinh, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã An Sinh, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07075",
        "parent_code": "205"
    },
    "07078": {
        "name": "Tràng Lương",
        "type": "xa",
        "slug": "trang-luong",
        "name_with_type": "Xã Tràng Lương",
        "path": "Tràng Lương, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Tràng Lương, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07078",
        "parent_code": "205"
    },
    "07081": {
        "name": "Bình Khê",
        "type": "xa",
        "slug": "binh-khe",
        "name_with_type": "Xã Bình Khê",
        "path": "Bình Khê, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Bình Khê, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07081",
        "parent_code": "205"
    },
    "07084": {
        "name": "Việt Dân",
        "type": "xa",
        "slug": "viet-dan",
        "name_with_type": "Xã Việt Dân",
        "path": "Việt Dân, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Việt Dân, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07084",
        "parent_code": "205"
    },
    "07087": {
        "name": "Tân Việt",
        "type": "xa",
        "slug": "tan-viet",
        "name_with_type": "Xã Tân Việt",
        "path": "Tân Việt, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Tân Việt, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07087",
        "parent_code": "205"
    },
    "07090": {
        "name": "Bình Dương",
        "type": "xa",
        "slug": "binh-duong",
        "name_with_type": "Xã Bình Dương",
        "path": "Bình Dương, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Bình Dương, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07090",
        "parent_code": "205"
    },
    "07093": {
        "name": "Đức Chính",
        "type": "phuong",
        "slug": "duc-chinh",
        "name_with_type": "Phường Đức Chính",
        "path": "Đức Chính, Đông Triều, Quảng Ninh",
        "path_with_type": "Phường Đức Chính, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07093",
        "parent_code": "205"
    },
    "07096": {
        "name": "Tràng An",
        "type": "xa",
        "slug": "trang-an",
        "name_with_type": "Xã Tràng An",
        "path": "Tràng An, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Tràng An, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07096",
        "parent_code": "205"
    },
    "07099": {
        "name": "Nguyễn Huệ",
        "type": "xa",
        "slug": "nguyen-hue",
        "name_with_type": "Xã Nguyễn Huệ",
        "path": "Nguyễn Huệ, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Nguyễn Huệ, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07099",
        "parent_code": "205"
    },
    "07102": {
        "name": "Thủy An",
        "type": "xa",
        "slug": "thuy-an",
        "name_with_type": "Xã Thủy An",
        "path": "Thủy An, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Thủy An, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07102",
        "parent_code": "205"
    },
    "07105": {
        "name": "Xuân Sơn",
        "type": "phuong",
        "slug": "xuan-son",
        "name_with_type": "Phường Xuân Sơn",
        "path": "Xuân Sơn, Đông Triều, Quảng Ninh",
        "path_with_type": "Phường Xuân Sơn, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07105",
        "parent_code": "205"
    },
    "07108": {
        "name": "Hồng Thái Tây",
        "type": "xa",
        "slug": "hong-thai-tay",
        "name_with_type": "Xã Hồng Thái Tây",
        "path": "Hồng Thái Tây, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Hồng Thái Tây, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07108",
        "parent_code": "205"
    },
    "07111": {
        "name": "Hồng Thái Đông",
        "type": "xa",
        "slug": "hong-thai-dong",
        "name_with_type": "Xã Hồng Thái Đông",
        "path": "Hồng Thái Đông, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Hồng Thái Đông, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07111",
        "parent_code": "205"
    },
    "07114": {
        "name": "Hoàng Quế",
        "type": "xa",
        "slug": "hoang-que",
        "name_with_type": "Xã Hoàng Quế",
        "path": "Hoàng Quế, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Hoàng Quế, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07114",
        "parent_code": "205"
    },
    "07117": {
        "name": "Yên Thọ",
        "type": "xa",
        "slug": "yen-tho",
        "name_with_type": "Xã Yên Thọ",
        "path": "Yên Thọ, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Yên Thọ, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07117",
        "parent_code": "205"
    },
    "07120": {
        "name": "Hồng Phong",
        "type": "xa",
        "slug": "hong-phong",
        "name_with_type": "Xã Hồng Phong",
        "path": "Hồng Phong, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Hồng Phong, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07120",
        "parent_code": "205"
    },
    "07123": {
        "name": "Kim Sơn",
        "type": "phuong",
        "slug": "kim-son",
        "name_with_type": "Phường Kim Sơn",
        "path": "Kim Sơn, Đông Triều, Quảng Ninh",
        "path_with_type": "Phường Kim Sơn, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07123",
        "parent_code": "205"
    },
    "07126": {
        "name": "Hưng Đạo",
        "type": "phuong",
        "slug": "hung-dao",
        "name_with_type": "Phường Hưng Đạo",
        "path": "Hưng Đạo, Đông Triều, Quảng Ninh",
        "path_with_type": "Phường Hưng Đạo, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07126",
        "parent_code": "205"
    },
    "07129": {
        "name": "Yên Đức",
        "type": "xa",
        "slug": "yen-duc",
        "name_with_type": "Xã Yên Đức",
        "path": "Yên Đức, Đông Triều, Quảng Ninh",
        "path_with_type": "Xã Yên Đức, Thị xã Đông Triều, Tỉnh Quảng Ninh",
        "code": "07129",
        "parent_code": "205"
    },
    "07132": {
        "name": "Quảng Yên",
        "type": "phuong",
        "slug": "quang-yen",
        "name_with_type": "Phường Quảng Yên",
        "path": "Quảng Yên, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Quảng Yên, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07132",
        "parent_code": "206"
    },
    "07135": {
        "name": "Đông Mai",
        "type": "phuong",
        "slug": "dong-mai",
        "name_with_type": "Phường Đông Mai",
        "path": "Đông Mai, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Đông Mai, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07135",
        "parent_code": "206"
    },
    "07138": {
        "name": "Minh Thành",
        "type": "phuong",
        "slug": "minh-thanh",
        "name_with_type": "Phường Minh Thành",
        "path": "Minh Thành, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Minh Thành, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07138",
        "parent_code": "206"
    },
    "07144": {
        "name": "Sông Khoai",
        "type": "xa",
        "slug": "song-khoai",
        "name_with_type": "Xã Sông Khoai",
        "path": "Sông Khoai, Quảng Yên, Quảng Ninh",
        "path_with_type": "Xã Sông Khoai, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07144",
        "parent_code": "206"
    },
    "07147": {
        "name": "Hiệp Hòa",
        "type": "xa",
        "slug": "hiep-hoa",
        "name_with_type": "Xã Hiệp Hòa",
        "path": "Hiệp Hòa, Quảng Yên, Quảng Ninh",
        "path_with_type": "Xã Hiệp Hòa, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07147",
        "parent_code": "206"
    },
    "07150": {
        "name": "Cộng Hòa",
        "type": "phuong",
        "slug": "cong-hoa",
        "name_with_type": "Phường Cộng Hòa",
        "path": "Cộng Hòa, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Cộng Hòa, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07150",
        "parent_code": "206"
    },
    "07153": {
        "name": "Tiền An",
        "type": "xa",
        "slug": "tien-an",
        "name_with_type": "Xã Tiền An",
        "path": "Tiền An, Quảng Yên, Quảng Ninh",
        "path_with_type": "Xã Tiền An, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07153",
        "parent_code": "206"
    },
    "07156": {
        "name": "Hoàng Tân",
        "type": "xa",
        "slug": "hoang-tan",
        "name_with_type": "Xã Hoàng Tân",
        "path": "Hoàng Tân, Quảng Yên, Quảng Ninh",
        "path_with_type": "Xã Hoàng Tân, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07156",
        "parent_code": "206"
    },
    "07159": {
        "name": "Tân An",
        "type": "phuong",
        "slug": "tan-an",
        "name_with_type": "Phường Tân An",
        "path": "Tân An, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Tân An, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07159",
        "parent_code": "206"
    },
    "07162": {
        "name": "Yên Giang",
        "type": "phuong",
        "slug": "yen-giang",
        "name_with_type": "Phường Yên Giang",
        "path": "Yên Giang, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Yên Giang, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07162",
        "parent_code": "206"
    },
    "07165": {
        "name": "Nam Hoà",
        "type": "phuong",
        "slug": "nam-hoa",
        "name_with_type": "Phường Nam Hoà",
        "path": "Nam Hoà, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Nam Hoà, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07165",
        "parent_code": "206"
    },
    "07168": {
        "name": "Hà An",
        "type": "phuong",
        "slug": "ha-an",
        "name_with_type": "Phường Hà An",
        "path": "Hà An, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Hà An, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07168",
        "parent_code": "206"
    },
    "07171": {
        "name": "Cẩm La",
        "type": "xa",
        "slug": "cam-la",
        "name_with_type": "Xã Cẩm La",
        "path": "Cẩm La, Quảng Yên, Quảng Ninh",
        "path_with_type": "Xã Cẩm La, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07171",
        "parent_code": "206"
    },
    "07174": {
        "name": "Phong Hải",
        "type": "phuong",
        "slug": "phong-hai",
        "name_with_type": "Phường Phong Hải",
        "path": "Phong Hải, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Phong Hải, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07174",
        "parent_code": "206"
    },
    "07177": {
        "name": "Yên Hải",
        "type": "phuong",
        "slug": "yen-hai",
        "name_with_type": "Phường Yên Hải",
        "path": "Yên Hải, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Yên Hải, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07177",
        "parent_code": "206"
    },
    "07180": {
        "name": "Liên Hòa",
        "type": "xa",
        "slug": "lien-hoa",
        "name_with_type": "Xã Liên Hòa",
        "path": "Liên Hòa, Quảng Yên, Quảng Ninh",
        "path_with_type": "Xã Liên Hòa, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07180",
        "parent_code": "206"
    },
    "07183": {
        "name": "Phong Cốc",
        "type": "phuong",
        "slug": "phong-coc",
        "name_with_type": "Phường Phong Cốc",
        "path": "Phong Cốc, Quảng Yên, Quảng Ninh",
        "path_with_type": "Phường Phong Cốc, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07183",
        "parent_code": "206"
    },
    "07186": {
        "name": "Liên Vị",
        "type": "xa",
        "slug": "lien-vi",
        "name_with_type": "Xã Liên Vị",
        "path": "Liên Vị, Quảng Yên, Quảng Ninh",
        "path_with_type": "Xã Liên Vị, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07186",
        "parent_code": "206"
    },
    "07189": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Quảng Yên, Quảng Ninh",
        "path_with_type": "Xã Tiền Phong, Thị xã Quảng Yên, Tỉnh Quảng Ninh",
        "code": "07189",
        "parent_code": "206"
    },
    "07192": {
        "name": "Cô Tô",
        "type": "thi-tran",
        "slug": "co-to",
        "name_with_type": "Thị trấn Cô Tô",
        "path": "Cô Tô, Cô Tô, Quảng Ninh",
        "path_with_type": "Thị trấn Cô Tô, Huyện Cô Tô, Tỉnh Quảng Ninh",
        "code": "07192",
        "parent_code": "207"
    },
    "07195": {
        "name": "Đồng Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đồng Tiến",
        "path": "Đồng Tiến, Cô Tô, Quảng Ninh",
        "path_with_type": "Xã Đồng Tiến, Huyện Cô Tô, Tỉnh Quảng Ninh",
        "code": "07195",
        "parent_code": "207"
    },
    "07198": {
        "name": "Thanh Lân",
        "type": "xa",
        "slug": "thanh-lan",
        "name_with_type": "Xã Thanh Lân",
        "path": "Thanh Lân, Cô Tô, Quảng Ninh",
        "path_with_type": "Xã Thanh Lân, Huyện Cô Tô, Tỉnh Quảng Ninh",
        "code": "07198",
        "parent_code": "207"
    },
    "07888": {
        "name": "Dữu Lâu",
        "type": "phuong",
        "slug": "duu-lau",
        "name_with_type": "Phường Dữu Lâu",
        "path": "Dữu Lâu, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Dữu Lâu, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07888",
        "parent_code": "227"
    },
    "07891": {
        "name": "Vân Cơ",
        "type": "phuong",
        "slug": "van-co",
        "name_with_type": "Phường Vân Cơ",
        "path": "Vân Cơ, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Vân Cơ, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07891",
        "parent_code": "227"
    },
    "07894": {
        "name": "Nông Trang",
        "type": "phuong",
        "slug": "nong-trang",
        "name_with_type": "Phường Nông Trang",
        "path": "Nông Trang, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Nông Trang, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07894",
        "parent_code": "227"
    },
    "07897": {
        "name": "Tân Dân",
        "type": "phuong",
        "slug": "tan-dan",
        "name_with_type": "Phường Tân Dân",
        "path": "Tân Dân, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Tân Dân, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07897",
        "parent_code": "227"
    },
    "07900": {
        "name": "Gia Cẩm",
        "type": "phuong",
        "slug": "gia-cam",
        "name_with_type": "Phường Gia Cẩm",
        "path": "Gia Cẩm, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Gia Cẩm, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07900",
        "parent_code": "227"
    },
    "07903": {
        "name": "Tiên Cát",
        "type": "phuong",
        "slug": "tien-cat",
        "name_with_type": "Phường Tiên Cát",
        "path": "Tiên Cát, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Tiên Cát, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07903",
        "parent_code": "227"
    },
    "07906": {
        "name": "Thọ Sơn",
        "type": "phuong",
        "slug": "tho-son",
        "name_with_type": "Phường Thọ Sơn",
        "path": "Thọ Sơn, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Thọ Sơn, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07906",
        "parent_code": "227"
    },
    "07909": {
        "name": "Thanh Miếu",
        "type": "phuong",
        "slug": "thanh-mieu",
        "name_with_type": "Phường Thanh Miếu",
        "path": "Thanh Miếu, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Thanh Miếu, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07909",
        "parent_code": "227"
    },
    "07912": {
        "name": "Bạch Hạc",
        "type": "phuong",
        "slug": "bach-hac",
        "name_with_type": "Phường Bạch Hạc",
        "path": "Bạch Hạc, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Bạch Hạc, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07912",
        "parent_code": "227"
    },
    "07915": {
        "name": "Bến Gót",
        "type": "phuong",
        "slug": "ben-got",
        "name_with_type": "Phường Bến Gót",
        "path": "Bến Gót, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Bến Gót, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07915",
        "parent_code": "227"
    },
    "07918": {
        "name": "Vân Phú",
        "type": "phuong",
        "slug": "van-phu",
        "name_with_type": "Phường Vân Phú",
        "path": "Vân Phú, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Vân Phú, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07918",
        "parent_code": "227"
    },
    "07921": {
        "name": "Phượng Lâu",
        "type": "xa",
        "slug": "phuong-lau",
        "name_with_type": "Xã Phượng Lâu",
        "path": "Phượng Lâu, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Phượng Lâu, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07921",
        "parent_code": "227"
    },
    "07924": {
        "name": "Thụy Vân",
        "type": "xa",
        "slug": "thuy-van",
        "name_with_type": "Xã Thụy Vân",
        "path": "Thụy Vân, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Thụy Vân, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07924",
        "parent_code": "227"
    },
    "07927": {
        "name": "Minh Phương",
        "type": "phuong",
        "slug": "minh-phuong",
        "name_with_type": "Phường Minh Phương",
        "path": "Minh Phương, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Minh Phương, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07927",
        "parent_code": "227"
    },
    "07930": {
        "name": "Trưng Vương",
        "type": "xa",
        "slug": "trung-vuong",
        "name_with_type": "Xã Trưng Vương",
        "path": "Trưng Vương, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Trưng Vương, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07930",
        "parent_code": "227"
    },
    "07933": {
        "name": "Minh Nông",
        "type": "phuong",
        "slug": "minh-nong",
        "name_with_type": "Phường Minh Nông",
        "path": "Minh Nông, Việt Trì, Phú Thọ",
        "path_with_type": "Phường Minh Nông, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07933",
        "parent_code": "227"
    },
    "07936": {
        "name": "Sông Lô",
        "type": "xa",
        "slug": "song-lo",
        "name_with_type": "Xã Sông Lô",
        "path": "Sông Lô, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Sông Lô, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "07936",
        "parent_code": "227"
    },
    "08281": {
        "name": "Kim Đức",
        "type": "xa",
        "slug": "kim-duc",
        "name_with_type": "Xã Kim Đức",
        "path": "Kim Đức, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Kim Đức, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "08281",
        "parent_code": "227"
    },
    "08287": {
        "name": "Hùng Lô",
        "type": "xa",
        "slug": "hung-lo",
        "name_with_type": "Xã Hùng Lô",
        "path": "Hùng Lô, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Hùng Lô, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "08287",
        "parent_code": "227"
    },
    "08503": {
        "name": "Hy Cương",
        "type": "xa",
        "slug": "hy-cuong",
        "name_with_type": "Xã Hy Cương",
        "path": "Hy Cương, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Hy Cương, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "08503",
        "parent_code": "227"
    },
    "08506": {
        "name": "Chu Hóa",
        "type": "xa",
        "slug": "chu-hoa",
        "name_with_type": "Xã Chu Hóa",
        "path": "Chu Hóa, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Chu Hóa, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "08506",
        "parent_code": "227"
    },
    "08515": {
        "name": "Thanh Đình",
        "type": "xa",
        "slug": "thanh-dinh",
        "name_with_type": "Xã Thanh Đình",
        "path": "Thanh Đình, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Thanh Đình, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "08515",
        "parent_code": "227"
    },
    "09622": {
        "name": "Tân Đức",
        "type": "xa",
        "slug": "tan-duc",
        "name_with_type": "Xã Tân Đức",
        "path": "Tân Đức, Việt Trì, Phú Thọ",
        "path_with_type": "Xã Tân Đức, Thành phố Việt Trì, Tỉnh Phú Thọ",
        "code": "09622",
        "parent_code": "227"
    },
    "07939": {
        "name": "Trường Thịnh",
        "type": "phuong",
        "slug": "truong-thinh",
        "name_with_type": "Phường Trường Thịnh",
        "path": "Trường Thịnh, Phú Thọ, Phú Thọ",
        "path_with_type": "Phường Trường Thịnh, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07939",
        "parent_code": "228"
    },
    "07942": {
        "name": "Hùng Vương",
        "type": "phuong",
        "slug": "hung-vuong",
        "name_with_type": "Phường Hùng Vương",
        "path": "Hùng Vương, Phú Thọ, Phú Thọ",
        "path_with_type": "Phường Hùng Vương, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07942",
        "parent_code": "228"
    },
    "07945": {
        "name": "Phong Châu",
        "type": "phuong",
        "slug": "phong-chau",
        "name_with_type": "Phường Phong Châu",
        "path": "Phong Châu, Phú Thọ, Phú Thọ",
        "path_with_type": "Phường Phong Châu, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07945",
        "parent_code": "228"
    },
    "07948": {
        "name": "Âu Cơ",
        "type": "phuong",
        "slug": "au-co",
        "name_with_type": "Phường Âu Cơ",
        "path": "Âu Cơ, Phú Thọ, Phú Thọ",
        "path_with_type": "Phường Âu Cơ, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07948",
        "parent_code": "228"
    },
    "07951": {
        "name": "Hà Lộc",
        "type": "xa",
        "slug": "ha-loc",
        "name_with_type": "Xã Hà Lộc",
        "path": "Hà Lộc, Phú Thọ, Phú Thọ",
        "path_with_type": "Xã Hà Lộc, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07951",
        "parent_code": "228"
    },
    "07954": {
        "name": "Phú Hộ",
        "type": "xa",
        "slug": "phu-ho",
        "name_with_type": "Xã Phú Hộ",
        "path": "Phú Hộ, Phú Thọ, Phú Thọ",
        "path_with_type": "Xã Phú Hộ, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07954",
        "parent_code": "228"
    },
    "07957": {
        "name": "Văn Lung",
        "type": "xa",
        "slug": "van-lung",
        "name_with_type": "Xã Văn Lung",
        "path": "Văn Lung, Phú Thọ, Phú Thọ",
        "path_with_type": "Xã Văn Lung, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07957",
        "parent_code": "228"
    },
    "07960": {
        "name": "Thanh Minh",
        "type": "xa",
        "slug": "thanh-minh",
        "name_with_type": "Xã Thanh Minh",
        "path": "Thanh Minh, Phú Thọ, Phú Thọ",
        "path_with_type": "Xã Thanh Minh, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07960",
        "parent_code": "228"
    },
    "07963": {
        "name": "Hà Thạch",
        "type": "xa",
        "slug": "ha-thach",
        "name_with_type": "Xã Hà Thạch",
        "path": "Hà Thạch, Phú Thọ, Phú Thọ",
        "path_with_type": "Xã Hà Thạch, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07963",
        "parent_code": "228"
    },
    "07966": {
        "name": "Thanh Vinh",
        "type": "phuong",
        "slug": "thanh-vinh",
        "name_with_type": "Phường Thanh Vinh",
        "path": "Thanh Vinh, Phú Thọ, Phú Thọ",
        "path_with_type": "Phường Thanh Vinh, Thị xã Phú Thọ, Tỉnh Phú Thọ",
        "code": "07966",
        "parent_code": "228"
    },
    "07969": {
        "name": "Đoan Hùng",
        "type": "thi-tran",
        "slug": "doan-hung",
        "name_with_type": "Thị trấn Đoan Hùng",
        "path": "Đoan Hùng, Đoan Hùng, Phú Thọ",
        "path_with_type": "Thị trấn Đoan Hùng, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07969",
        "parent_code": "230"
    },
    "07972": {
        "name": "Đông Khê",
        "type": "xa",
        "slug": "dong-khe",
        "name_with_type": "Xã Đông Khê",
        "path": "Đông Khê, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Đông Khê, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07972",
        "parent_code": "230"
    },
    "07975": {
        "name": "Nghinh Xuyên",
        "type": "xa",
        "slug": "nghinh-xuyen",
        "name_with_type": "Xã Nghinh Xuyên",
        "path": "Nghinh Xuyên, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Nghinh Xuyên, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07975",
        "parent_code": "230"
    },
    "07978": {
        "name": "Hùng Quan",
        "type": "xa",
        "slug": "hung-quan",
        "name_with_type": "Xã Hùng Quan",
        "path": "Hùng Quan, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Hùng Quan, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07978",
        "parent_code": "230"
    },
    "07981": {
        "name": "Bằng Luân",
        "type": "xa",
        "slug": "bang-luan",
        "name_with_type": "Xã Bằng Luân",
        "path": "Bằng Luân, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Bằng Luân, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07981",
        "parent_code": "230"
    },
    "07984": {
        "name": "Vân Du",
        "type": "xa",
        "slug": "van-du",
        "name_with_type": "Xã Vân Du",
        "path": "Vân Du, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Vân Du, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07984",
        "parent_code": "230"
    },
    "07987": {
        "name": "Phương Trung",
        "type": "xa",
        "slug": "phuong-trung",
        "name_with_type": "Xã Phương Trung",
        "path": "Phương Trung, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Phương Trung, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07987",
        "parent_code": "230"
    },
    "07990": {
        "name": "Quế Lâm",
        "type": "xa",
        "slug": "que-lam",
        "name_with_type": "Xã Quế Lâm",
        "path": "Quế Lâm, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Quế Lâm, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07990",
        "parent_code": "230"
    },
    "07993": {
        "name": "Minh Lương",
        "type": "xa",
        "slug": "minh-luong",
        "name_with_type": "Xã Minh Lương",
        "path": "Minh Lương, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Minh Lương, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07993",
        "parent_code": "230"
    },
    "07996": {
        "name": "Bằng Doãn",
        "type": "xa",
        "slug": "bang-doan",
        "name_with_type": "Xã Bằng Doãn",
        "path": "Bằng Doãn, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Bằng Doãn, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07996",
        "parent_code": "230"
    },
    "07999": {
        "name": "Chí Đám",
        "type": "xa",
        "slug": "chi-dam",
        "name_with_type": "Xã Chí Đám",
        "path": "Chí Đám, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Chí Đám, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "07999",
        "parent_code": "230"
    },
    "08002": {
        "name": "Phong Phú",
        "type": "xa",
        "slug": "phong-phu",
        "name_with_type": "Xã Phong Phú",
        "path": "Phong Phú, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Phong Phú, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08002",
        "parent_code": "230"
    },
    "08005": {
        "name": "Phúc Lai",
        "type": "xa",
        "slug": "phuc-lai",
        "name_with_type": "Xã Phúc Lai",
        "path": "Phúc Lai, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Phúc Lai, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08005",
        "parent_code": "230"
    },
    "08008": {
        "name": "Ngọc Quan",
        "type": "xa",
        "slug": "ngoc-quan",
        "name_with_type": "Xã Ngọc Quan",
        "path": "Ngọc Quan, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Ngọc Quan, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08008",
        "parent_code": "230"
    },
    "08011": {
        "name": "Hữu Đô",
        "type": "xa",
        "slug": "huu-do",
        "name_with_type": "Xã Hữu Đô",
        "path": "Hữu Đô, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Hữu Đô, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08011",
        "parent_code": "230"
    },
    "08014": {
        "name": "Đại Nghĩa",
        "type": "xa",
        "slug": "dai-nghia",
        "name_with_type": "Xã Đại Nghĩa",
        "path": "Đại Nghĩa, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Đại Nghĩa, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08014",
        "parent_code": "230"
    },
    "08017": {
        "name": "Sóc Đăng",
        "type": "xa",
        "slug": "soc-dang",
        "name_with_type": "Xã Sóc Đăng",
        "path": "Sóc Đăng, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Sóc Đăng, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08017",
        "parent_code": "230"
    },
    "08020": {
        "name": "Phú Thứ",
        "type": "xa",
        "slug": "phu-thu",
        "name_with_type": "Xã Phú Thứ",
        "path": "Phú Thứ, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Phú Thứ, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08020",
        "parent_code": "230"
    },
    "08023": {
        "name": "Tây Cốc",
        "type": "xa",
        "slug": "tay-coc",
        "name_with_type": "Xã Tây Cốc",
        "path": "Tây Cốc, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Tây Cốc, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08023",
        "parent_code": "230"
    },
    "08026": {
        "name": "Yên Kiện",
        "type": "xa",
        "slug": "yen-kien",
        "name_with_type": "Xã Yên Kiện",
        "path": "Yên Kiện, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Yên Kiện, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08026",
        "parent_code": "230"
    },
    "08029": {
        "name": "Hùng Long",
        "type": "xa",
        "slug": "hung-long",
        "name_with_type": "Xã Hùng Long",
        "path": "Hùng Long, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Hùng Long, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08029",
        "parent_code": "230"
    },
    "08032": {
        "name": "Vụ Quang",
        "type": "xa",
        "slug": "vu-quang",
        "name_with_type": "Xã Vụ Quang",
        "path": "Vụ Quang, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Vụ Quang, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08032",
        "parent_code": "230"
    },
    "08035": {
        "name": "Vân Đồn",
        "type": "xa",
        "slug": "van-don",
        "name_with_type": "Xã Vân Đồn",
        "path": "Vân Đồn, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Vân Đồn, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08035",
        "parent_code": "230"
    },
    "08038": {
        "name": "Tiêu Sơn",
        "type": "xa",
        "slug": "tieu-son",
        "name_with_type": "Xã Tiêu Sơn",
        "path": "Tiêu Sơn, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Tiêu Sơn, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08038",
        "parent_code": "230"
    },
    "08041": {
        "name": "Minh Tiến",
        "type": "xa",
        "slug": "minh-tien",
        "name_with_type": "Xã Minh Tiến",
        "path": "Minh Tiến, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Minh Tiến, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08041",
        "parent_code": "230"
    },
    "08044": {
        "name": "Minh Phú",
        "type": "xa",
        "slug": "minh-phu",
        "name_with_type": "Xã Minh Phú",
        "path": "Minh Phú, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Minh Phú, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08044",
        "parent_code": "230"
    },
    "08047": {
        "name": "Chân Mộng",
        "type": "xa",
        "slug": "chan-mong",
        "name_with_type": "Xã Chân Mộng",
        "path": "Chân Mộng, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Chân Mộng, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08047",
        "parent_code": "230"
    },
    "08050": {
        "name": "Ca Đình",
        "type": "xa",
        "slug": "ca-dinh",
        "name_with_type": "Xã Ca Đình",
        "path": "Ca Đình, Đoan Hùng, Phú Thọ",
        "path_with_type": "Xã Ca Đình, Huyện Đoan Hùng, Tỉnh Phú Thọ",
        "code": "08050",
        "parent_code": "230"
    },
    "08053": {
        "name": "Hạ Hoà",
        "type": "thi-tran",
        "slug": "ha-hoa",
        "name_with_type": "Thị trấn Hạ Hoà",
        "path": "Hạ Hoà, Hạ Hoà, Phú Thọ",
        "path_with_type": "Thị trấn Hạ Hoà, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08053",
        "parent_code": "231"
    },
    "08056": {
        "name": "Đại Phạm",
        "type": "xa",
        "slug": "dai-pham",
        "name_with_type": "Xã Đại Phạm",
        "path": "Đại Phạm, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Đại Phạm, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08056",
        "parent_code": "231"
    },
    "08059": {
        "name": "Hậu Bổng",
        "type": "xa",
        "slug": "hau-bong",
        "name_with_type": "Xã Hậu Bổng",
        "path": "Hậu Bổng, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Hậu Bổng, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08059",
        "parent_code": "231"
    },
    "08062": {
        "name": "Đan Hà",
        "type": "xa",
        "slug": "dan-ha",
        "name_with_type": "Xã Đan Hà",
        "path": "Đan Hà, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Đan Hà, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08062",
        "parent_code": "231"
    },
    "08065": {
        "name": "Hà Lương",
        "type": "xa",
        "slug": "ha-luong",
        "name_with_type": "Xã Hà Lương",
        "path": "Hà Lương, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Hà Lương, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08065",
        "parent_code": "231"
    },
    "08068": {
        "name": "Lệnh Khanh",
        "type": "xa",
        "slug": "lenh-khanh",
        "name_with_type": "Xã Lệnh Khanh",
        "path": "Lệnh Khanh, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Lệnh Khanh, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08068",
        "parent_code": "231"
    },
    "08071": {
        "name": "Phụ Khánh",
        "type": "xa",
        "slug": "phu-khanh",
        "name_with_type": "Xã Phụ Khánh",
        "path": "Phụ Khánh, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Phụ Khánh, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08071",
        "parent_code": "231"
    },
    "08074": {
        "name": "Liên Phương",
        "type": "xa",
        "slug": "lien-phuong",
        "name_with_type": "Xã Liên Phương",
        "path": "Liên Phương, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Liên Phương, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08074",
        "parent_code": "231"
    },
    "08077": {
        "name": "Đan Thượng",
        "type": "xa",
        "slug": "dan-thuong",
        "name_with_type": "Xã Đan Thượng",
        "path": "Đan Thượng, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Đan Thượng, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08077",
        "parent_code": "231"
    },
    "08080": {
        "name": "Hiền Lương",
        "type": "xa",
        "slug": "hien-luong",
        "name_with_type": "Xã Hiền Lương",
        "path": "Hiền Lương, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Hiền Lương, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08080",
        "parent_code": "231"
    },
    "08083": {
        "name": "Động Lâm",
        "type": "xa",
        "slug": "dong-lam",
        "name_with_type": "Xã Động Lâm",
        "path": "Động Lâm, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Động Lâm, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08083",
        "parent_code": "231"
    },
    "08086": {
        "name": "Lâm Lợi",
        "type": "xa",
        "slug": "lam-loi",
        "name_with_type": "Xã Lâm Lợi",
        "path": "Lâm Lợi, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Lâm Lợi, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08086",
        "parent_code": "231"
    },
    "08089": {
        "name": "Phương Viên",
        "type": "xa",
        "slug": "phuong-vien",
        "name_with_type": "Xã Phương Viên",
        "path": "Phương Viên, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Phương Viên, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08089",
        "parent_code": "231"
    },
    "08092": {
        "name": "Gia Điền",
        "type": "xa",
        "slug": "gia-dien",
        "name_with_type": "Xã Gia Điền",
        "path": "Gia Điền, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Gia Điền, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08092",
        "parent_code": "231"
    },
    "08095": {
        "name": "Ấm Hạ",
        "type": "xa",
        "slug": "am-ha",
        "name_with_type": "Xã Ấm Hạ",
        "path": "Ấm Hạ, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Ấm Hạ, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08095",
        "parent_code": "231"
    },
    "08098": {
        "name": "Quân Khê",
        "type": "xa",
        "slug": "quan-khe",
        "name_with_type": "Xã Quân Khê",
        "path": "Quân Khê, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Quân Khê, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08098",
        "parent_code": "231"
    },
    "08101": {
        "name": "Y Sơn",
        "type": "xa",
        "slug": "y-son",
        "name_with_type": "Xã Y Sơn",
        "path": "Y Sơn, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Y Sơn, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08101",
        "parent_code": "231"
    },
    "08104": {
        "name": "Hương Xạ",
        "type": "xa",
        "slug": "huong-xa",
        "name_with_type": "Xã Hương Xạ",
        "path": "Hương Xạ, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Hương Xạ, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08104",
        "parent_code": "231"
    },
    "08107": {
        "name": "Cáo Điền",
        "type": "xa",
        "slug": "cao-dien",
        "name_with_type": "Xã Cáo Điền",
        "path": "Cáo Điền, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Cáo Điền, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08107",
        "parent_code": "231"
    },
    "08110": {
        "name": "Xuân Áng",
        "type": "xa",
        "slug": "xuan-ang",
        "name_with_type": "Xã Xuân Áng",
        "path": "Xuân Áng, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Xuân Áng, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08110",
        "parent_code": "231"
    },
    "08113": {
        "name": "Yên Kỳ",
        "type": "xa",
        "slug": "yen-ky",
        "name_with_type": "Xã Yên Kỳ",
        "path": "Yên Kỳ, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Yên Kỳ, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08113",
        "parent_code": "231"
    },
    "08116": {
        "name": "Chuế Lưu",
        "type": "xa",
        "slug": "chue-luu",
        "name_with_type": "Xã Chuế Lưu",
        "path": "Chuế Lưu, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Chuế Lưu, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08116",
        "parent_code": "231"
    },
    "08119": {
        "name": "Minh Hạc",
        "type": "xa",
        "slug": "minh-hac",
        "name_with_type": "Xã Minh Hạc",
        "path": "Minh Hạc, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Minh Hạc, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08119",
        "parent_code": "231"
    },
    "08122": {
        "name": "Lang Sơn",
        "type": "xa",
        "slug": "lang-son",
        "name_with_type": "Xã Lang Sơn",
        "path": "Lang Sơn, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Lang Sơn, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08122",
        "parent_code": "231"
    },
    "08125": {
        "name": "Bằng Giã",
        "type": "xa",
        "slug": "bang-gia",
        "name_with_type": "Xã Bằng Giã",
        "path": "Bằng Giã, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Bằng Giã, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08125",
        "parent_code": "231"
    },
    "08128": {
        "name": "Yên Luật",
        "type": "xa",
        "slug": "yen-luat",
        "name_with_type": "Xã Yên Luật",
        "path": "Yên Luật, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Yên Luật, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08128",
        "parent_code": "231"
    },
    "08131": {
        "name": "Vô Tranh",
        "type": "xa",
        "slug": "vo-tranh",
        "name_with_type": "Xã Vô Tranh",
        "path": "Vô Tranh, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Vô Tranh, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08131",
        "parent_code": "231"
    },
    "08134": {
        "name": "Văn Lang",
        "type": "xa",
        "slug": "van-lang",
        "name_with_type": "Xã Văn Lang",
        "path": "Văn Lang, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Văn Lang, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08134",
        "parent_code": "231"
    },
    "08137": {
        "name": "Chính Công",
        "type": "xa",
        "slug": "chinh-cong",
        "name_with_type": "Xã Chính Công",
        "path": "Chính Công, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Chính Công, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08137",
        "parent_code": "231"
    },
    "08140": {
        "name": "Minh Côi",
        "type": "xa",
        "slug": "minh-coi",
        "name_with_type": "Xã Minh Côi",
        "path": "Minh Côi, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Minh Côi, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08140",
        "parent_code": "231"
    },
    "08143": {
        "name": "Vĩnh Chân",
        "type": "xa",
        "slug": "vinh-chan",
        "name_with_type": "Xã Vĩnh Chân",
        "path": "Vĩnh Chân, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Vĩnh Chân, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08143",
        "parent_code": "231"
    },
    "08146": {
        "name": "Mai Tùng",
        "type": "xa",
        "slug": "mai-tung",
        "name_with_type": "Xã Mai Tùng",
        "path": "Mai Tùng, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Mai Tùng, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08146",
        "parent_code": "231"
    },
    "08149": {
        "name": "Vụ Cầu",
        "type": "xa",
        "slug": "vu-cau",
        "name_with_type": "Xã Vụ Cầu",
        "path": "Vụ Cầu, Hạ Hoà, Phú Thọ",
        "path_with_type": "Xã Vụ Cầu, Huyện Hạ Hoà, Tỉnh Phú Thọ",
        "code": "08149",
        "parent_code": "231"
    },
    "08152": {
        "name": "Thanh Ba",
        "type": "thi-tran",
        "slug": "thanh-ba",
        "name_with_type": "Thị trấn Thanh Ba",
        "path": "Thanh Ba, Thanh Ba, Phú Thọ",
        "path_with_type": "Thị trấn Thanh Ba, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08152",
        "parent_code": "232"
    },
    "08155": {
        "name": "Thanh Vân",
        "type": "xa",
        "slug": "thanh-van",
        "name_with_type": "Xã Thanh Vân",
        "path": "Thanh Vân, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Thanh Vân, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08155",
        "parent_code": "232"
    },
    "08156": {
        "name": "Vân Lĩnh",
        "type": "xa",
        "slug": "van-linh",
        "name_with_type": "Xã Vân Lĩnh",
        "path": "Vân Lĩnh, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Vân Lĩnh, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08156",
        "parent_code": "232"
    },
    "08158": {
        "name": "Đông Lĩnh",
        "type": "xa",
        "slug": "dong-linh",
        "name_with_type": "Xã Đông Lĩnh",
        "path": "Đông Lĩnh, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Đông Lĩnh, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08158",
        "parent_code": "232"
    },
    "08161": {
        "name": "Đại An",
        "type": "xa",
        "slug": "dai-an",
        "name_with_type": "Xã Đại An",
        "path": "Đại An, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Đại An, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08161",
        "parent_code": "232"
    },
    "08164": {
        "name": "Hanh Cù",
        "type": "xa",
        "slug": "hanh-cu",
        "name_with_type": "Xã Hanh Cù",
        "path": "Hanh Cù, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Hanh Cù, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08164",
        "parent_code": "232"
    },
    "08167": {
        "name": "Thái Ninh",
        "type": "xa",
        "slug": "thai-ninh",
        "name_with_type": "Xã Thái Ninh",
        "path": "Thái Ninh, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Thái Ninh, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08167",
        "parent_code": "232"
    },
    "08170": {
        "name": "Đồng Xuân",
        "type": "xa",
        "slug": "dong-xuan",
        "name_with_type": "Xã Đồng Xuân",
        "path": "Đồng Xuân, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Đồng Xuân, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08170",
        "parent_code": "232"
    },
    "08173": {
        "name": "Năng Yên",
        "type": "xa",
        "slug": "nang-yen",
        "name_with_type": "Xã Năng Yên",
        "path": "Năng Yên, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Năng Yên, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08173",
        "parent_code": "232"
    },
    "08176": {
        "name": "Yển Khê",
        "type": "xa",
        "slug": "yen-khe",
        "name_with_type": "Xã Yển Khê",
        "path": "Yển Khê, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Yển Khê, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08176",
        "parent_code": "232"
    },
    "08179": {
        "name": "Ninh Dân",
        "type": "xa",
        "slug": "ninh-dan",
        "name_with_type": "Xã Ninh Dân",
        "path": "Ninh Dân, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Ninh Dân, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08179",
        "parent_code": "232"
    },
    "08182": {
        "name": "Quảng Nạp",
        "type": "xa",
        "slug": "quang-nap",
        "name_with_type": "Xã Quảng Nạp",
        "path": "Quảng Nạp, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Quảng Nạp, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08182",
        "parent_code": "232"
    },
    "08185": {
        "name": "Vũ Yển",
        "type": "xa",
        "slug": "vu-yen",
        "name_with_type": "Xã Vũ Yển",
        "path": "Vũ Yển, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Vũ Yển, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08185",
        "parent_code": "232"
    },
    "08188": {
        "name": "Yên Nội",
        "type": "xa",
        "slug": "yen-noi",
        "name_with_type": "Xã Yên Nội",
        "path": "Yên Nội, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Yên Nội, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08188",
        "parent_code": "232"
    },
    "08191": {
        "name": "Phương Lĩnh",
        "type": "xa",
        "slug": "phuong-linh",
        "name_with_type": "Xã Phương Lĩnh",
        "path": "Phương Lĩnh, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Phương Lĩnh, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08191",
        "parent_code": "232"
    },
    "08194": {
        "name": "Võ Lao",
        "type": "xa",
        "slug": "vo-lao",
        "name_with_type": "Xã Võ Lao",
        "path": "Võ Lao, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Võ Lao, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08194",
        "parent_code": "232"
    },
    "08197": {
        "name": "Khải Xuân",
        "type": "xa",
        "slug": "khai-xuan",
        "name_with_type": "Xã Khải Xuân",
        "path": "Khải Xuân, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Khải Xuân, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08197",
        "parent_code": "232"
    },
    "08200": {
        "name": "Mạn Lạn",
        "type": "xa",
        "slug": "man-lan",
        "name_with_type": "Xã Mạn Lạn",
        "path": "Mạn Lạn, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Mạn Lạn, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08200",
        "parent_code": "232"
    },
    "08203": {
        "name": "Thanh Xá",
        "type": "xa",
        "slug": "thanh-xa",
        "name_with_type": "Xã Thanh Xá",
        "path": "Thanh Xá, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Thanh Xá, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08203",
        "parent_code": "232"
    },
    "08206": {
        "name": "Chí Tiên",
        "type": "xa",
        "slug": "chi-tien",
        "name_with_type": "Xã Chí Tiên",
        "path": "Chí Tiên, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Chí Tiên, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08206",
        "parent_code": "232"
    },
    "08209": {
        "name": "Đông Thành",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thành",
        "path": "Đông Thành, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Đông Thành, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08209",
        "parent_code": "232"
    },
    "08212": {
        "name": "Hoàng Cương",
        "type": "xa",
        "slug": "hoang-cuong",
        "name_with_type": "Xã Hoàng Cương",
        "path": "Hoàng Cương, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Hoàng Cương, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08212",
        "parent_code": "232"
    },
    "08215": {
        "name": "Sơn Cương",
        "type": "xa",
        "slug": "son-cuong",
        "name_with_type": "Xã Sơn Cương",
        "path": "Sơn Cương, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Sơn Cương, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08215",
        "parent_code": "232"
    },
    "08218": {
        "name": "Thanh Hà",
        "type": "xa",
        "slug": "thanh-ha",
        "name_with_type": "Xã Thanh Hà",
        "path": "Thanh Hà, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Thanh Hà, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08218",
        "parent_code": "232"
    },
    "08221": {
        "name": "Đỗ Sơn",
        "type": "xa",
        "slug": "do-son",
        "name_with_type": "Xã Đỗ Sơn",
        "path": "Đỗ Sơn, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Đỗ Sơn, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08221",
        "parent_code": "232"
    },
    "08224": {
        "name": "Đỗ Xuyên",
        "type": "xa",
        "slug": "do-xuyen",
        "name_with_type": "Xã Đỗ Xuyên",
        "path": "Đỗ Xuyên, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Đỗ Xuyên, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08224",
        "parent_code": "232"
    },
    "08227": {
        "name": "Lương Lỗ",
        "type": "xa",
        "slug": "luong-lo",
        "name_with_type": "Xã Lương Lỗ",
        "path": "Lương Lỗ, Thanh Ba, Phú Thọ",
        "path_with_type": "Xã Lương Lỗ, Huyện Thanh Ba, Tỉnh Phú Thọ",
        "code": "08227",
        "parent_code": "232"
    },
    "08230": {
        "name": "Phong Châu",
        "type": "thi-tran",
        "slug": "phong-chau",
        "name_with_type": "Thị trấn Phong Châu",
        "path": "Phong Châu, Phù Ninh, Phú Thọ",
        "path_with_type": "Thị trấn Phong Châu, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08230",
        "parent_code": "233"
    },
    "08233": {
        "name": "Phú Mỹ",
        "type": "xa",
        "slug": "phu-my",
        "name_with_type": "Xã Phú Mỹ",
        "path": "Phú Mỹ, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Phú Mỹ, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08233",
        "parent_code": "233"
    },
    "08234": {
        "name": "Lệ Mỹ",
        "type": "xa",
        "slug": "le-my",
        "name_with_type": "Xã Lệ Mỹ",
        "path": "Lệ Mỹ, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Lệ Mỹ, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08234",
        "parent_code": "233"
    },
    "08236": {
        "name": "Liên Hoa",
        "type": "xa",
        "slug": "lien-hoa",
        "name_with_type": "Xã Liên Hoa",
        "path": "Liên Hoa, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Liên Hoa, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08236",
        "parent_code": "233"
    },
    "08239": {
        "name": "Trạm Thản",
        "type": "xa",
        "slug": "tram-than",
        "name_with_type": "Xã Trạm Thản",
        "path": "Trạm Thản, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Trạm Thản, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08239",
        "parent_code": "233"
    },
    "08242": {
        "name": "Trị Quận",
        "type": "xa",
        "slug": "tri-quan",
        "name_with_type": "Xã Trị Quận",
        "path": "Trị Quận, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Trị Quận, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08242",
        "parent_code": "233"
    },
    "08245": {
        "name": "Trung Giáp",
        "type": "xa",
        "slug": "trung-giap",
        "name_with_type": "Xã Trung Giáp",
        "path": "Trung Giáp, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Trung Giáp, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08245",
        "parent_code": "233"
    },
    "08248": {
        "name": "Tiên Phú",
        "type": "xa",
        "slug": "tien-phu",
        "name_with_type": "Xã Tiên Phú",
        "path": "Tiên Phú, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Tiên Phú, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08248",
        "parent_code": "233"
    },
    "08251": {
        "name": "Hạ Giáp",
        "type": "xa",
        "slug": "ha-giap",
        "name_with_type": "Xã Hạ Giáp",
        "path": "Hạ Giáp, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Hạ Giáp, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08251",
        "parent_code": "233"
    },
    "08254": {
        "name": "Bảo Thanh",
        "type": "xa",
        "slug": "bao-thanh",
        "name_with_type": "Xã Bảo Thanh",
        "path": "Bảo Thanh, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Bảo Thanh, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08254",
        "parent_code": "233"
    },
    "08257": {
        "name": "Phú Lộc",
        "type": "xa",
        "slug": "phu-loc",
        "name_with_type": "Xã Phú Lộc",
        "path": "Phú Lộc, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Phú Lộc, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08257",
        "parent_code": "233"
    },
    "08260": {
        "name": "Gia Thanh",
        "type": "xa",
        "slug": "gia-thanh",
        "name_with_type": "Xã Gia Thanh",
        "path": "Gia Thanh, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Gia Thanh, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08260",
        "parent_code": "233"
    },
    "08263": {
        "name": "Tiên Du",
        "type": "xa",
        "slug": "tien-du",
        "name_with_type": "Xã Tiên Du",
        "path": "Tiên Du, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Tiên Du, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08263",
        "parent_code": "233"
    },
    "08266": {
        "name": "Phú Nham",
        "type": "xa",
        "slug": "phu-nham",
        "name_with_type": "Xã Phú Nham",
        "path": "Phú Nham, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Phú Nham, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08266",
        "parent_code": "233"
    },
    "08269": {
        "name": "Bình Bộ",
        "type": "xa",
        "slug": "binh-bo",
        "name_with_type": "Xã Bình Bộ",
        "path": "Bình Bộ, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Bình Bộ, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08269",
        "parent_code": "233"
    },
    "08272": {
        "name": "An Đạo",
        "type": "xa",
        "slug": "an-dao",
        "name_with_type": "Xã An Đạo",
        "path": "An Đạo, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã An Đạo, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08272",
        "parent_code": "233"
    },
    "08275": {
        "name": "Tử Đà",
        "type": "xa",
        "slug": "tu-da",
        "name_with_type": "Xã Tử Đà",
        "path": "Tử Đà, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Tử Đà, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08275",
        "parent_code": "233"
    },
    "08278": {
        "name": "Phù Ninh",
        "type": "xa",
        "slug": "phu-ninh",
        "name_with_type": "Xã Phù Ninh",
        "path": "Phù Ninh, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Phù Ninh, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08278",
        "parent_code": "233"
    },
    "08284": {
        "name": "Vĩnh Phú",
        "type": "xa",
        "slug": "vinh-phu",
        "name_with_type": "Xã Vĩnh Phú",
        "path": "Vĩnh Phú, Phù Ninh, Phú Thọ",
        "path_with_type": "Xã Vĩnh Phú, Huyện Phù Ninh, Tỉnh Phú Thọ",
        "code": "08284",
        "parent_code": "233"
    },
    "08290": {
        "name": "Yên Lập",
        "type": "thi-tran",
        "slug": "yen-lap",
        "name_with_type": "Thị trấn Yên Lập",
        "path": "Yên Lập, Yên Lập, Phú Thọ",
        "path_with_type": "Thị trấn Yên Lập, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08290",
        "parent_code": "234"
    },
    "08293": {
        "name": "Mỹ Lung",
        "type": "xa",
        "slug": "my-lung",
        "name_with_type": "Xã Mỹ Lung",
        "path": "Mỹ Lung, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Mỹ Lung, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08293",
        "parent_code": "234"
    },
    "08296": {
        "name": "Mỹ Lương",
        "type": "xa",
        "slug": "my-luong",
        "name_with_type": "Xã Mỹ Lương",
        "path": "Mỹ Lương, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Mỹ Lương, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08296",
        "parent_code": "234"
    },
    "08299": {
        "name": "Lương Sơn",
        "type": "xa",
        "slug": "luong-son",
        "name_with_type": "Xã Lương Sơn",
        "path": "Lương Sơn, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Lương Sơn, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08299",
        "parent_code": "234"
    },
    "08302": {
        "name": "Xuân An",
        "type": "xa",
        "slug": "xuan-an",
        "name_with_type": "Xã Xuân An",
        "path": "Xuân An, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Xuân An, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08302",
        "parent_code": "234"
    },
    "08305": {
        "name": "Xuân Viên",
        "type": "xa",
        "slug": "xuan-vien",
        "name_with_type": "Xã Xuân Viên",
        "path": "Xuân Viên, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Xuân Viên, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08305",
        "parent_code": "234"
    },
    "08308": {
        "name": "Xuân Thủy",
        "type": "xa",
        "slug": "xuan-thuy",
        "name_with_type": "Xã Xuân Thủy",
        "path": "Xuân Thủy, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Xuân Thủy, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08308",
        "parent_code": "234"
    },
    "08311": {
        "name": "Trung Sơn",
        "type": "xa",
        "slug": "trung-son",
        "name_with_type": "Xã Trung Sơn",
        "path": "Trung Sơn, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Trung Sơn, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08311",
        "parent_code": "234"
    },
    "08314": {
        "name": "Hưng Long",
        "type": "xa",
        "slug": "hung-long",
        "name_with_type": "Xã Hưng Long",
        "path": "Hưng Long, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Hưng Long, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08314",
        "parent_code": "234"
    },
    "08317": {
        "name": "Nga Hoàng",
        "type": "xa",
        "slug": "nga-hoang",
        "name_with_type": "Xã Nga Hoàng",
        "path": "Nga Hoàng, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Nga Hoàng, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08317",
        "parent_code": "234"
    },
    "08320": {
        "name": "Đồng Lạc",
        "type": "xa",
        "slug": "dong-lac",
        "name_with_type": "Xã Đồng Lạc",
        "path": "Đồng Lạc, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Đồng Lạc, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08320",
        "parent_code": "234"
    },
    "08323": {
        "name": "Thượng Long",
        "type": "xa",
        "slug": "thuong-long",
        "name_with_type": "Xã Thượng Long",
        "path": "Thượng Long, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Thượng Long, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08323",
        "parent_code": "234"
    },
    "08326": {
        "name": "Đồng Thịnh",
        "type": "xa",
        "slug": "dong-thinh",
        "name_with_type": "Xã Đồng Thịnh",
        "path": "Đồng Thịnh, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Đồng Thịnh, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08326",
        "parent_code": "234"
    },
    "08329": {
        "name": "Phúc Khánh",
        "type": "xa",
        "slug": "phuc-khanh",
        "name_with_type": "Xã Phúc Khánh",
        "path": "Phúc Khánh, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Phúc Khánh, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08329",
        "parent_code": "234"
    },
    "08332": {
        "name": "Minh Hòa",
        "type": "xa",
        "slug": "minh-hoa",
        "name_with_type": "Xã Minh Hòa",
        "path": "Minh Hòa, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Minh Hòa, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08332",
        "parent_code": "234"
    },
    "08335": {
        "name": "Ngọc Lập",
        "type": "xa",
        "slug": "ngoc-lap",
        "name_with_type": "Xã Ngọc Lập",
        "path": "Ngọc Lập, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Ngọc Lập, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08335",
        "parent_code": "234"
    },
    "08338": {
        "name": "Ngọc Đồng",
        "type": "xa",
        "slug": "ngoc-dong",
        "name_with_type": "Xã Ngọc Đồng",
        "path": "Ngọc Đồng, Yên Lập, Phú Thọ",
        "path_with_type": "Xã Ngọc Đồng, Huyện Yên Lập, Tỉnh Phú Thọ",
        "code": "08338",
        "parent_code": "234"
    },
    "08341": {
        "name": "Sông Thao",
        "type": "thi-tran",
        "slug": "song-thao",
        "name_with_type": "Thị trấn Sông Thao",
        "path": "Sông Thao, Cẩm Khê, Phú Thọ",
        "path_with_type": "Thị trấn Sông Thao, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08341",
        "parent_code": "235"
    },
    "08344": {
        "name": "Tiên Lương",
        "type": "xa",
        "slug": "tien-luong",
        "name_with_type": "Xã Tiên Lương",
        "path": "Tiên Lương, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Tiên Lương, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08344",
        "parent_code": "235"
    },
    "08347": {
        "name": "Tuy Lộc",
        "type": "xa",
        "slug": "tuy-loc",
        "name_with_type": "Xã Tuy Lộc",
        "path": "Tuy Lộc, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Tuy Lộc, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08347",
        "parent_code": "235"
    },
    "08350": {
        "name": "Ngô Xá",
        "type": "xa",
        "slug": "ngo-xa",
        "name_with_type": "Xã Ngô Xá",
        "path": "Ngô Xá, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Ngô Xá, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08350",
        "parent_code": "235"
    },
    "08353": {
        "name": "Phương Xá",
        "type": "xa",
        "slug": "phuong-xa",
        "name_with_type": "Xã Phương Xá",
        "path": "Phương Xá, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Phương Xá, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08353",
        "parent_code": "235"
    },
    "08356": {
        "name": "Phượng Vĩ",
        "type": "xa",
        "slug": "phuong-vi",
        "name_with_type": "Xã Phượng Vĩ",
        "path": "Phượng Vĩ, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Phượng Vĩ, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08356",
        "parent_code": "235"
    },
    "08359": {
        "name": "Đồng Cam",
        "type": "xa",
        "slug": "dong-cam",
        "name_with_type": "Xã Đồng Cam",
        "path": "Đồng Cam, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Đồng Cam, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08359",
        "parent_code": "235"
    },
    "08362": {
        "name": "Thụy Liễu",
        "type": "xa",
        "slug": "thuy-lieu",
        "name_with_type": "Xã Thụy Liễu",
        "path": "Thụy Liễu, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Thụy Liễu, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08362",
        "parent_code": "235"
    },
    "08365": {
        "name": "Phùng Xá",
        "type": "xa",
        "slug": "phung-xa",
        "name_with_type": "Xã Phùng Xá",
        "path": "Phùng Xá, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Phùng Xá, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08365",
        "parent_code": "235"
    },
    "08368": {
        "name": "Sơn Nga",
        "type": "xa",
        "slug": "son-nga",
        "name_with_type": "Xã Sơn Nga",
        "path": "Sơn Nga, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Sơn Nga, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08368",
        "parent_code": "235"
    },
    "08371": {
        "name": "Sai Nga",
        "type": "xa",
        "slug": "sai-nga",
        "name_with_type": "Xã Sai Nga",
        "path": "Sai Nga, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Sai Nga, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08371",
        "parent_code": "235"
    },
    "08374": {
        "name": "Tùng Khê",
        "type": "xa",
        "slug": "tung-khe",
        "name_with_type": "Xã Tùng Khê",
        "path": "Tùng Khê, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Tùng Khê, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08374",
        "parent_code": "235"
    },
    "08377": {
        "name": "Tam Sơn",
        "type": "xa",
        "slug": "tam-son",
        "name_with_type": "Xã Tam Sơn",
        "path": "Tam Sơn, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Tam Sơn, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08377",
        "parent_code": "235"
    },
    "08380": {
        "name": "Văn Bán",
        "type": "xa",
        "slug": "van-ban",
        "name_with_type": "Xã Văn Bán",
        "path": "Văn Bán, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Văn Bán, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08380",
        "parent_code": "235"
    },
    "08383": {
        "name": "Cấp Dẫn",
        "type": "xa",
        "slug": "cap-dan",
        "name_with_type": "Xã Cấp Dẫn",
        "path": "Cấp Dẫn, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Cấp Dẫn, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08383",
        "parent_code": "235"
    },
    "08386": {
        "name": "Thanh Nga",
        "type": "xa",
        "slug": "thanh-nga",
        "name_with_type": "Xã Thanh Nga",
        "path": "Thanh Nga, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Thanh Nga, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08386",
        "parent_code": "235"
    },
    "08389": {
        "name": "Xương Thịnh",
        "type": "xa",
        "slug": "xuong-thinh",
        "name_with_type": "Xã Xương Thịnh",
        "path": "Xương Thịnh, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Xương Thịnh, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08389",
        "parent_code": "235"
    },
    "08392": {
        "name": "Phú Khê",
        "type": "xa",
        "slug": "phu-khe",
        "name_with_type": "Xã Phú Khê",
        "path": "Phú Khê, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Phú Khê, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08392",
        "parent_code": "235"
    },
    "08395": {
        "name": "Sơn Tình",
        "type": "xa",
        "slug": "son-tinh",
        "name_with_type": "Xã Sơn Tình",
        "path": "Sơn Tình, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Sơn Tình, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08395",
        "parent_code": "235"
    },
    "08398": {
        "name": "Yên Tập",
        "type": "xa",
        "slug": "yen-tap",
        "name_with_type": "Xã Yên Tập",
        "path": "Yên Tập, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Yên Tập, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08398",
        "parent_code": "235"
    },
    "08401": {
        "name": "Hương Lung",
        "type": "xa",
        "slug": "huong-lung",
        "name_with_type": "Xã Hương Lung",
        "path": "Hương Lung, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Hương Lung, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08401",
        "parent_code": "235"
    },
    "08404": {
        "name": "Tạ Xá",
        "type": "xa",
        "slug": "ta-xa",
        "name_with_type": "Xã Tạ Xá",
        "path": "Tạ Xá, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Tạ Xá, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08404",
        "parent_code": "235"
    },
    "08407": {
        "name": "Phú Lạc",
        "type": "xa",
        "slug": "phu-lac",
        "name_with_type": "Xã Phú Lạc",
        "path": "Phú Lạc, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Phú Lạc, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08407",
        "parent_code": "235"
    },
    "08410": {
        "name": "Tình Cương",
        "type": "xa",
        "slug": "tinh-cuong",
        "name_with_type": "Xã Tình Cương",
        "path": "Tình Cương, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Tình Cương, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08410",
        "parent_code": "235"
    },
    "08413": {
        "name": "Chương Xá",
        "type": "xa",
        "slug": "chuong-xa",
        "name_with_type": "Xã Chương Xá",
        "path": "Chương Xá, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Chương Xá, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08413",
        "parent_code": "235"
    },
    "08416": {
        "name": "Hiền Đa",
        "type": "xa",
        "slug": "hien-da",
        "name_with_type": "Xã Hiền Đa",
        "path": "Hiền Đa, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Hiền Đa, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08416",
        "parent_code": "235"
    },
    "08419": {
        "name": "Văn Khúc",
        "type": "xa",
        "slug": "van-khuc",
        "name_with_type": "Xã Văn Khúc",
        "path": "Văn Khúc, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Văn Khúc, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08419",
        "parent_code": "235"
    },
    "08422": {
        "name": "Yên Dưỡng",
        "type": "xa",
        "slug": "yen-duong",
        "name_with_type": "Xã Yên Dưỡng",
        "path": "Yên Dưỡng, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Yên Dưỡng, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08422",
        "parent_code": "235"
    },
    "08425": {
        "name": "Cát Trù",
        "type": "xa",
        "slug": "cat-tru",
        "name_with_type": "Xã Cát Trù",
        "path": "Cát Trù, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Cát Trù, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08425",
        "parent_code": "235"
    },
    "08428": {
        "name": "Điêu Lương",
        "type": "xa",
        "slug": "dieu-luong",
        "name_with_type": "Xã Điêu Lương",
        "path": "Điêu Lương, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Điêu Lương, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08428",
        "parent_code": "235"
    },
    "08431": {
        "name": "Đồng Lương",
        "type": "xa",
        "slug": "dong-luong",
        "name_with_type": "Xã Đồng Lương",
        "path": "Đồng Lương, Cẩm Khê, Phú Thọ",
        "path_with_type": "Xã Đồng Lương, Huyện Cẩm Khê, Tỉnh Phú Thọ",
        "code": "08431",
        "parent_code": "235"
    },
    "08434": {
        "name": "Hưng Hoá",
        "type": "thi-tran",
        "slug": "hung-hoa",
        "name_with_type": "Thị trấn Hưng Hoá",
        "path": "Hưng Hoá, Tam Nông, Phú Thọ",
        "path_with_type": "Thị trấn Hưng Hoá, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08434",
        "parent_code": "236"
    },
    "08437": {
        "name": "Vực Trường",
        "type": "xa",
        "slug": "vuc-truong",
        "name_with_type": "Xã Vực Trường",
        "path": "Vực Trường, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Vực Trường, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08437",
        "parent_code": "236"
    },
    "08440": {
        "name": "Hiền Quan",
        "type": "xa",
        "slug": "hien-quan",
        "name_with_type": "Xã Hiền Quan",
        "path": "Hiền Quan, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Hiền Quan, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08440",
        "parent_code": "236"
    },
    "08443": {
        "name": "Hương Nha",
        "type": "xa",
        "slug": "huong-nha",
        "name_with_type": "Xã Hương Nha",
        "path": "Hương Nha, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Hương Nha, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08443",
        "parent_code": "236"
    },
    "08446": {
        "name": "Thanh Uyên",
        "type": "xa",
        "slug": "thanh-uyen",
        "name_with_type": "Xã Thanh Uyên",
        "path": "Thanh Uyên, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Thanh Uyên, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08446",
        "parent_code": "236"
    },
    "08449": {
        "name": "Xuân Quang",
        "type": "xa",
        "slug": "xuan-quang",
        "name_with_type": "Xã Xuân Quang",
        "path": "Xuân Quang, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Xuân Quang, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08449",
        "parent_code": "236"
    },
    "08452": {
        "name": "Tứ Mỹ",
        "type": "xa",
        "slug": "tu-my",
        "name_with_type": "Xã Tứ Mỹ",
        "path": "Tứ Mỹ, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Tứ Mỹ, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08452",
        "parent_code": "236"
    },
    "08455": {
        "name": "Văn Lương",
        "type": "xa",
        "slug": "van-luong",
        "name_with_type": "Xã Văn Lương",
        "path": "Văn Lương, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Văn Lương, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08455",
        "parent_code": "236"
    },
    "08458": {
        "name": "Hùng Đô",
        "type": "xa",
        "slug": "hung-do",
        "name_with_type": "Xã Hùng Đô",
        "path": "Hùng Đô, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Hùng Đô, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08458",
        "parent_code": "236"
    },
    "08461": {
        "name": "Phương Thịnh",
        "type": "xa",
        "slug": "phuong-thinh",
        "name_with_type": "Xã Phương Thịnh",
        "path": "Phương Thịnh, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Phương Thịnh, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08461",
        "parent_code": "236"
    },
    "08464": {
        "name": "Tam Cường",
        "type": "xa",
        "slug": "tam-cuong",
        "name_with_type": "Xã Tam Cường",
        "path": "Tam Cường, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Tam Cường, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08464",
        "parent_code": "236"
    },
    "08467": {
        "name": "Cổ Tiết",
        "type": "xa",
        "slug": "co-tiet",
        "name_with_type": "Xã Cổ Tiết",
        "path": "Cổ Tiết, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Cổ Tiết, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08467",
        "parent_code": "236"
    },
    "08470": {
        "name": "Quang Húc",
        "type": "xa",
        "slug": "quang-huc",
        "name_with_type": "Xã Quang Húc",
        "path": "Quang Húc, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Quang Húc, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08470",
        "parent_code": "236"
    },
    "08473": {
        "name": "Hương Nộn",
        "type": "xa",
        "slug": "huong-non",
        "name_with_type": "Xã Hương Nộn",
        "path": "Hương Nộn, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Hương Nộn, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08473",
        "parent_code": "236"
    },
    "08476": {
        "name": "Tề Lễ",
        "type": "xa",
        "slug": "te-le",
        "name_with_type": "Xã Tề Lễ",
        "path": "Tề Lễ, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Tề Lễ, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08476",
        "parent_code": "236"
    },
    "08479": {
        "name": "Thọ Văn",
        "type": "xa",
        "slug": "tho-van",
        "name_with_type": "Xã Thọ Văn",
        "path": "Thọ Văn, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Thọ Văn, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08479",
        "parent_code": "236"
    },
    "08482": {
        "name": "Dị Nậu",
        "type": "xa",
        "slug": "di-nau",
        "name_with_type": "Xã Dị Nậu",
        "path": "Dị Nậu, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Dị Nậu, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08482",
        "parent_code": "236"
    },
    "08485": {
        "name": "Hồng Đà",
        "type": "xa",
        "slug": "hong-da",
        "name_with_type": "Xã Hồng Đà",
        "path": "Hồng Đà, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Hồng Đà, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08485",
        "parent_code": "236"
    },
    "08488": {
        "name": "Dậu Dương",
        "type": "xa",
        "slug": "dau-duong",
        "name_with_type": "Xã Dậu Dương",
        "path": "Dậu Dương, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Dậu Dương, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08488",
        "parent_code": "236"
    },
    "08491": {
        "name": "Thượng Nông",
        "type": "xa",
        "slug": "thuong-nong",
        "name_with_type": "Xã Thượng Nông",
        "path": "Thượng Nông, Tam Nông, Phú Thọ",
        "path_with_type": "Xã Thượng Nông, Huyện Tam Nông, Tỉnh Phú Thọ",
        "code": "08491",
        "parent_code": "236"
    },
    "08494": {
        "name": "Lâm Thao",
        "type": "thi-tran",
        "slug": "lam-thao",
        "name_with_type": "Thị trấn Lâm Thao",
        "path": "Lâm Thao, Lâm Thao, Phú Thọ",
        "path_with_type": "Thị trấn Lâm Thao, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08494",
        "parent_code": "237"
    },
    "08497": {
        "name": "Tiên Kiên",
        "type": "xa",
        "slug": "tien-kien",
        "name_with_type": "Xã Tiên Kiên",
        "path": "Tiên Kiên, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Tiên Kiên, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08497",
        "parent_code": "237"
    },
    "08498": {
        "name": "Hùng Sơn",
        "type": "thi-tran",
        "slug": "hung-son",
        "name_with_type": "Thị trấn Hùng Sơn",
        "path": "Hùng Sơn, Lâm Thao, Phú Thọ",
        "path_with_type": "Thị trấn Hùng Sơn, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08498",
        "parent_code": "237"
    },
    "08500": {
        "name": "Xuân Lũng",
        "type": "xa",
        "slug": "xuan-lung",
        "name_with_type": "Xã Xuân Lũng",
        "path": "Xuân Lũng, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Xuân Lũng, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08500",
        "parent_code": "237"
    },
    "08509": {
        "name": "Xuân Huy",
        "type": "xa",
        "slug": "xuan-huy",
        "name_with_type": "Xã Xuân Huy",
        "path": "Xuân Huy, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Xuân Huy, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08509",
        "parent_code": "237"
    },
    "08512": {
        "name": "Thạch Sơn",
        "type": "xa",
        "slug": "thach-son",
        "name_with_type": "Xã Thạch Sơn",
        "path": "Thạch Sơn, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Thạch Sơn, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08512",
        "parent_code": "237"
    },
    "08518": {
        "name": "Sơn Vi",
        "type": "xa",
        "slug": "son-vi",
        "name_with_type": "Xã Sơn Vi",
        "path": "Sơn Vi, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Sơn Vi, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08518",
        "parent_code": "237"
    },
    "08521": {
        "name": "Hợp Hải",
        "type": "xa",
        "slug": "hop-hai",
        "name_with_type": "Xã Hợp Hải",
        "path": "Hợp Hải, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Hợp Hải, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08521",
        "parent_code": "237"
    },
    "08524": {
        "name": "Sơn Dương",
        "type": "xa",
        "slug": "son-duong",
        "name_with_type": "Xã Sơn Dương",
        "path": "Sơn Dương, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Sơn Dương, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08524",
        "parent_code": "237"
    },
    "08527": {
        "name": "Cao Xá",
        "type": "xa",
        "slug": "cao-xa",
        "name_with_type": "Xã Cao Xá",
        "path": "Cao Xá, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Cao Xá, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08527",
        "parent_code": "237"
    },
    "08530": {
        "name": "Kinh Kệ",
        "type": "xa",
        "slug": "kinh-ke",
        "name_with_type": "Xã Kinh Kệ",
        "path": "Kinh Kệ, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Kinh Kệ, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08530",
        "parent_code": "237"
    },
    "08533": {
        "name": "Vĩnh Lại",
        "type": "xa",
        "slug": "vinh-lai",
        "name_with_type": "Xã Vĩnh Lại",
        "path": "Vĩnh Lại, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Vĩnh Lại, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08533",
        "parent_code": "237"
    },
    "08536": {
        "name": "Tứ Xã",
        "type": "xa",
        "slug": "tu-xa",
        "name_with_type": "Xã Tứ Xã",
        "path": "Tứ Xã, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Tứ Xã, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08536",
        "parent_code": "237"
    },
    "08539": {
        "name": "Bản Nguyên",
        "type": "xa",
        "slug": "ban-nguyen",
        "name_with_type": "Xã Bản Nguyên",
        "path": "Bản Nguyên, Lâm Thao, Phú Thọ",
        "path_with_type": "Xã Bản Nguyên, Huyện Lâm Thao, Tỉnh Phú Thọ",
        "code": "08539",
        "parent_code": "237"
    },
    "08542": {
        "name": "Thanh Sơn",
        "type": "thi-tran",
        "slug": "thanh-son",
        "name_with_type": "Thị trấn Thanh Sơn",
        "path": "Thanh Sơn, Thanh Sơn, Phú Thọ",
        "path_with_type": "Thị trấn Thanh Sơn, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08542",
        "parent_code": "238"
    },
    "08563": {
        "name": "Sơn Hùng",
        "type": "xa",
        "slug": "son-hung",
        "name_with_type": "Xã Sơn Hùng",
        "path": "Sơn Hùng, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Sơn Hùng, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08563",
        "parent_code": "238"
    },
    "08572": {
        "name": "Địch Quả",
        "type": "xa",
        "slug": "dich-qua",
        "name_with_type": "Xã Địch Quả",
        "path": "Địch Quả, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Địch Quả, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08572",
        "parent_code": "238"
    },
    "08575": {
        "name": "Giáp Lai",
        "type": "xa",
        "slug": "giap-lai",
        "name_with_type": "Xã Giáp Lai",
        "path": "Giáp Lai, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Giáp Lai, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08575",
        "parent_code": "238"
    },
    "08581": {
        "name": "Thục Luyện",
        "type": "xa",
        "slug": "thuc-luyen",
        "name_with_type": "Xã Thục Luyện",
        "path": "Thục Luyện, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Thục Luyện, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08581",
        "parent_code": "238"
    },
    "08584": {
        "name": "Võ Miếu",
        "type": "xa",
        "slug": "vo-mieu",
        "name_with_type": "Xã Võ Miếu",
        "path": "Võ Miếu, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Võ Miếu, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08584",
        "parent_code": "238"
    },
    "08587": {
        "name": "Thạch Khoán",
        "type": "xa",
        "slug": "thach-khoan",
        "name_with_type": "Xã Thạch Khoán",
        "path": "Thạch Khoán, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Thạch Khoán, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08587",
        "parent_code": "238"
    },
    "08602": {
        "name": "Cự Thắng",
        "type": "xa",
        "slug": "cu-thang",
        "name_with_type": "Xã Cự Thắng",
        "path": "Cự Thắng, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Cự Thắng, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08602",
        "parent_code": "238"
    },
    "08605": {
        "name": "Tất Thắng",
        "type": "xa",
        "slug": "tat-thang",
        "name_with_type": "Xã Tất Thắng",
        "path": "Tất Thắng, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Tất Thắng, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08605",
        "parent_code": "238"
    },
    "08611": {
        "name": "Văn Miếu",
        "type": "xa",
        "slug": "van-mieu",
        "name_with_type": "Xã Văn Miếu",
        "path": "Văn Miếu, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Văn Miếu, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08611",
        "parent_code": "238"
    },
    "08614": {
        "name": "Cự Đồng",
        "type": "xa",
        "slug": "cu-dong",
        "name_with_type": "Xã Cự Đồng",
        "path": "Cự Đồng, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Cự Đồng, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08614",
        "parent_code": "238"
    },
    "08623": {
        "name": "Thắng Sơn",
        "type": "xa",
        "slug": "thang-son",
        "name_with_type": "Xã Thắng Sơn",
        "path": "Thắng Sơn, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Thắng Sơn, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08623",
        "parent_code": "238"
    },
    "08629": {
        "name": "Tân Minh",
        "type": "xa",
        "slug": "tan-minh",
        "name_with_type": "Xã Tân Minh",
        "path": "Tân Minh, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Tân Minh, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08629",
        "parent_code": "238"
    },
    "08632": {
        "name": "Hương Cần",
        "type": "xa",
        "slug": "huong-can",
        "name_with_type": "Xã Hương Cần",
        "path": "Hương Cần, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Hương Cần, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08632",
        "parent_code": "238"
    },
    "08635": {
        "name": "Khả Cửu",
        "type": "xa",
        "slug": "kha-cuu",
        "name_with_type": "Xã Khả Cửu",
        "path": "Khả Cửu, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Khả Cửu, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08635",
        "parent_code": "238"
    },
    "08638": {
        "name": "Đông Cửu",
        "type": "xa",
        "slug": "dong-cuu",
        "name_with_type": "Xã Đông Cửu",
        "path": "Đông Cửu, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Đông Cửu, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08638",
        "parent_code": "238"
    },
    "08641": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Tân Lập, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08641",
        "parent_code": "238"
    },
    "08644": {
        "name": "Yên Lãng",
        "type": "xa",
        "slug": "yen-lang",
        "name_with_type": "Xã Yên Lãng",
        "path": "Yên Lãng, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Yên Lãng, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08644",
        "parent_code": "238"
    },
    "08647": {
        "name": "Yên Lương",
        "type": "xa",
        "slug": "yen-luong",
        "name_with_type": "Xã Yên Lương",
        "path": "Yên Lương, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Yên Lương, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08647",
        "parent_code": "238"
    },
    "08650": {
        "name": "Thượng Cửu",
        "type": "xa",
        "slug": "thuong-cuu",
        "name_with_type": "Xã Thượng Cửu",
        "path": "Thượng Cửu, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Thượng Cửu, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08650",
        "parent_code": "238"
    },
    "08653": {
        "name": "Lương Nha",
        "type": "xa",
        "slug": "luong-nha",
        "name_with_type": "Xã Lương Nha",
        "path": "Lương Nha, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Lương Nha, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08653",
        "parent_code": "238"
    },
    "08656": {
        "name": "Yên Sơn",
        "type": "xa",
        "slug": "yen-son",
        "name_with_type": "Xã Yên Sơn",
        "path": "Yên Sơn, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Yên Sơn, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08656",
        "parent_code": "238"
    },
    "08659": {
        "name": "Tinh Nhuệ",
        "type": "xa",
        "slug": "tinh-nhue",
        "name_with_type": "Xã Tinh Nhuệ",
        "path": "Tinh Nhuệ, Thanh Sơn, Phú Thọ",
        "path_with_type": "Xã Tinh Nhuệ, Huyện Thanh Sơn, Tỉnh Phú Thọ",
        "code": "08659",
        "parent_code": "238"
    },
    "08662": {
        "name": "Đào Xá",
        "type": "xa",
        "slug": "dao-xa",
        "name_with_type": "Xã Đào Xá",
        "path": "Đào Xá, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Đào Xá, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08662",
        "parent_code": "239"
    },
    "08665": {
        "name": "Thạch Đồng",
        "type": "xa",
        "slug": "thach-dong",
        "name_with_type": "Xã Thạch Đồng",
        "path": "Thạch Đồng, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Thạch Đồng, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08665",
        "parent_code": "239"
    },
    "08668": {
        "name": "Xuân Lộc",
        "type": "xa",
        "slug": "xuan-loc",
        "name_with_type": "Xã Xuân Lộc",
        "path": "Xuân Lộc, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Xuân Lộc, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08668",
        "parent_code": "239"
    },
    "08671": {
        "name": "Tân Phương",
        "type": "xa",
        "slug": "tan-phuong",
        "name_with_type": "Xã Tân Phương",
        "path": "Tân Phương, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Tân Phương, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08671",
        "parent_code": "239"
    },
    "08674": {
        "name": "Thanh Thủy",
        "type": "thi-tran",
        "slug": "thanh-thuy",
        "name_with_type": "Thị trấn Thanh Thủy",
        "path": "Thanh Thủy, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Thị trấn Thanh Thủy, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08674",
        "parent_code": "239"
    },
    "08677": {
        "name": "Sơn Thủy",
        "type": "xa",
        "slug": "son-thuy",
        "name_with_type": "Xã Sơn Thủy",
        "path": "Sơn Thủy, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Sơn Thủy, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08677",
        "parent_code": "239"
    },
    "08680": {
        "name": "Bảo Yên",
        "type": "xa",
        "slug": "bao-yen",
        "name_with_type": "Xã Bảo Yên",
        "path": "Bảo Yên, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Bảo Yên, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08680",
        "parent_code": "239"
    },
    "08683": {
        "name": "Đoan Hạ",
        "type": "xa",
        "slug": "doan-ha",
        "name_with_type": "Xã Đoan Hạ",
        "path": "Đoan Hạ, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Đoan Hạ, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08683",
        "parent_code": "239"
    },
    "08686": {
        "name": "Đồng Luận",
        "type": "xa",
        "slug": "dong-luan",
        "name_with_type": "Xã Đồng Luận",
        "path": "Đồng Luận, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Đồng Luận, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08686",
        "parent_code": "239"
    },
    "08689": {
        "name": "Hoàng Xá",
        "type": "xa",
        "slug": "hoang-xa",
        "name_with_type": "Xã Hoàng Xá",
        "path": "Hoàng Xá, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Hoàng Xá, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08689",
        "parent_code": "239"
    },
    "08692": {
        "name": "Trung Thịnh",
        "type": "xa",
        "slug": "trung-thinh",
        "name_with_type": "Xã Trung Thịnh",
        "path": "Trung Thịnh, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Trung Thịnh, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08692",
        "parent_code": "239"
    },
    "08695": {
        "name": "Trung Nghĩa",
        "type": "xa",
        "slug": "trung-nghia",
        "name_with_type": "Xã Trung Nghĩa",
        "path": "Trung Nghĩa, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Trung Nghĩa, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08695",
        "parent_code": "239"
    },
    "08698": {
        "name": "Phượng Mao",
        "type": "xa",
        "slug": "phuong-mao",
        "name_with_type": "Xã Phượng Mao",
        "path": "Phượng Mao, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Phượng Mao, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08698",
        "parent_code": "239"
    },
    "08701": {
        "name": "Yến Mao",
        "type": "xa",
        "slug": "yen-mao",
        "name_with_type": "Xã Yến Mao",
        "path": "Yến Mao, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Yến Mao, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08701",
        "parent_code": "239"
    },
    "08704": {
        "name": "Tu Vũ",
        "type": "xa",
        "slug": "tu-vu",
        "name_with_type": "Xã Tu Vũ",
        "path": "Tu Vũ, Thanh Thuỷ, Phú Thọ",
        "path_with_type": "Xã Tu Vũ, Huyện Thanh Thuỷ, Tỉnh Phú Thọ",
        "code": "08704",
        "parent_code": "239"
    },
    "08545": {
        "name": "Thu Cúc",
        "type": "xa",
        "slug": "thu-cuc",
        "name_with_type": "Xã Thu Cúc",
        "path": "Thu Cúc, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Thu Cúc, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08545",
        "parent_code": "240"
    },
    "08548": {
        "name": "Thạch Kiệt",
        "type": "xa",
        "slug": "thach-kiet",
        "name_with_type": "Xã Thạch Kiệt",
        "path": "Thạch Kiệt, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Thạch Kiệt, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08548",
        "parent_code": "240"
    },
    "08551": {
        "name": "Thu Ngạc",
        "type": "xa",
        "slug": "thu-ngac",
        "name_with_type": "Xã Thu Ngạc",
        "path": "Thu Ngạc, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Thu Ngạc, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08551",
        "parent_code": "240"
    },
    "08554": {
        "name": "Kiệt Sơn",
        "type": "xa",
        "slug": "kiet-son",
        "name_with_type": "Xã Kiệt Sơn",
        "path": "Kiệt Sơn, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Kiệt Sơn, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08554",
        "parent_code": "240"
    },
    "08557": {
        "name": "Đồng Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đồng Sơn",
        "path": "Đồng Sơn, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Đồng Sơn, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08557",
        "parent_code": "240"
    },
    "08560": {
        "name": "Lai Đồng",
        "type": "xa",
        "slug": "lai-dong",
        "name_with_type": "Xã Lai Đồng",
        "path": "Lai Đồng, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Lai Đồng, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08560",
        "parent_code": "240"
    },
    "08566": {
        "name": "Tân Phú",
        "type": "xa",
        "slug": "tan-phu",
        "name_with_type": "Xã Tân Phú",
        "path": "Tân Phú, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Tân Phú, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08566",
        "parent_code": "240"
    },
    "08569": {
        "name": "Mỹ Thuận",
        "type": "xa",
        "slug": "my-thuan",
        "name_with_type": "Xã Mỹ Thuận",
        "path": "Mỹ Thuận, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Mỹ Thuận, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08569",
        "parent_code": "240"
    },
    "08578": {
        "name": "Tân Sơn",
        "type": "xa",
        "slug": "tan-son",
        "name_with_type": "Xã Tân Sơn",
        "path": "Tân Sơn, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Tân Sơn, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08578",
        "parent_code": "240"
    },
    "08590": {
        "name": "Xuân Đài",
        "type": "xa",
        "slug": "xuan-dai",
        "name_with_type": "Xã Xuân Đài",
        "path": "Xuân Đài, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Xuân Đài, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08590",
        "parent_code": "240"
    },
    "08593": {
        "name": "Minh Đài",
        "type": "xa",
        "slug": "minh-dai",
        "name_with_type": "Xã Minh Đài",
        "path": "Minh Đài, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Minh Đài, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08593",
        "parent_code": "240"
    },
    "08596": {
        "name": "Văn Luông",
        "type": "xa",
        "slug": "van-luong",
        "name_with_type": "Xã Văn Luông",
        "path": "Văn Luông, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Văn Luông, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08596",
        "parent_code": "240"
    },
    "08599": {
        "name": "Xuân Sơn",
        "type": "xa",
        "slug": "xuan-son",
        "name_with_type": "Xã Xuân Sơn",
        "path": "Xuân Sơn, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Xuân Sơn, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08599",
        "parent_code": "240"
    },
    "08608": {
        "name": "Long Cốc",
        "type": "xa",
        "slug": "long-coc",
        "name_with_type": "Xã Long Cốc",
        "path": "Long Cốc, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Long Cốc, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08608",
        "parent_code": "240"
    },
    "08617": {
        "name": "Kim Thượng",
        "type": "xa",
        "slug": "kim-thuong",
        "name_with_type": "Xã Kim Thượng",
        "path": "Kim Thượng, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Kim Thượng, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08617",
        "parent_code": "240"
    },
    "08620": {
        "name": "Tam Thanh",
        "type": "xa",
        "slug": "tam-thanh",
        "name_with_type": "Xã Tam Thanh",
        "path": "Tam Thanh, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Tam Thanh, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08620",
        "parent_code": "240"
    },
    "08626": {
        "name": "Vinh Tiền",
        "type": "xa",
        "slug": "vinh-tien",
        "name_with_type": "Xã Vinh Tiền",
        "path": "Vinh Tiền, Tân Sơn, Phú Thọ",
        "path_with_type": "Xã Vinh Tiền, Huyện Tân Sơn, Tỉnh Phú Thọ",
        "code": "08626",
        "parent_code": "240"
    },
    "18853": {
        "name": "Hải Thành",
        "type": "phuong",
        "slug": "hai-thanh",
        "name_with_type": "Phường Hải Thành",
        "path": "Hải Thành, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Hải Thành, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18853",
        "parent_code": "450"
    },
    "18856": {
        "name": "Đồng Phú",
        "type": "phuong",
        "slug": "dong-phu",
        "name_with_type": "Phường Đồng Phú",
        "path": "Đồng Phú, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Đồng Phú, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18856",
        "parent_code": "450"
    },
    "18859": {
        "name": "Bắc Lý",
        "type": "phuong",
        "slug": "bac-ly",
        "name_with_type": "Phường Bắc Lý",
        "path": "Bắc Lý, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Bắc Lý, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18859",
        "parent_code": "450"
    },
    "18862": {
        "name": "Đồng Mỹ",
        "type": "phuong",
        "slug": "dong-my",
        "name_with_type": "Phường Đồng Mỹ",
        "path": "Đồng Mỹ, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Đồng Mỹ, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18862",
        "parent_code": "450"
    },
    "18865": {
        "name": "Nam Lý",
        "type": "phuong",
        "slug": "nam-ly",
        "name_with_type": "Phường Nam Lý",
        "path": "Nam Lý, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Nam Lý, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18865",
        "parent_code": "450"
    },
    "18868": {
        "name": "Hải Đình",
        "type": "phuong",
        "slug": "hai-dinh",
        "name_with_type": "Phường Hải Đình",
        "path": "Hải Đình, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Hải Đình, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18868",
        "parent_code": "450"
    },
    "18871": {
        "name": "Đồng Sơn",
        "type": "phuong",
        "slug": "dong-son",
        "name_with_type": "Phường Đồng Sơn",
        "path": "Đồng Sơn, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Đồng Sơn, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18871",
        "parent_code": "450"
    },
    "18874": {
        "name": "Phú Hải",
        "type": "phuong",
        "slug": "phu-hai",
        "name_with_type": "Phường Phú Hải",
        "path": "Phú Hải, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Phú Hải, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18874",
        "parent_code": "450"
    },
    "18877": {
        "name": "Bắc Nghĩa",
        "type": "phuong",
        "slug": "bac-nghia",
        "name_with_type": "Phường Bắc Nghĩa",
        "path": "Bắc Nghĩa, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Bắc Nghĩa, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18877",
        "parent_code": "450"
    },
    "18880": {
        "name": "Đức Ninh Đông",
        "type": "phuong",
        "slug": "duc-ninh-dong",
        "name_with_type": "Phường Đức Ninh Đông",
        "path": "Đức Ninh Đông, Đồng Hới, Quảng Bình",
        "path_with_type": "Phường Đức Ninh Đông, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18880",
        "parent_code": "450"
    },
    "18883": {
        "name": "Quang Phú",
        "type": "xa",
        "slug": "quang-phu",
        "name_with_type": "Xã Quang Phú",
        "path": "Quang Phú, Đồng Hới, Quảng Bình",
        "path_with_type": "Xã Quang Phú, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18883",
        "parent_code": "450"
    },
    "18886": {
        "name": "Lộc Ninh",
        "type": "xa",
        "slug": "loc-ninh",
        "name_with_type": "Xã Lộc Ninh",
        "path": "Lộc Ninh, Đồng Hới, Quảng Bình",
        "path_with_type": "Xã Lộc Ninh, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18886",
        "parent_code": "450"
    },
    "18889": {
        "name": "Bảo Ninh",
        "type": "xa",
        "slug": "bao-ninh",
        "name_with_type": "Xã Bảo Ninh",
        "path": "Bảo Ninh, Đồng Hới, Quảng Bình",
        "path_with_type": "Xã Bảo Ninh, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18889",
        "parent_code": "450"
    },
    "18892": {
        "name": "Nghĩa Ninh",
        "type": "xa",
        "slug": "nghia-ninh",
        "name_with_type": "Xã Nghĩa Ninh",
        "path": "Nghĩa Ninh, Đồng Hới, Quảng Bình",
        "path_with_type": "Xã Nghĩa Ninh, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18892",
        "parent_code": "450"
    },
    "18895": {
        "name": "Thuận Đức",
        "type": "xa",
        "slug": "thuan-duc",
        "name_with_type": "Xã Thuận Đức",
        "path": "Thuận Đức, Đồng Hới, Quảng Bình",
        "path_with_type": "Xã Thuận Đức, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18895",
        "parent_code": "450"
    },
    "18898": {
        "name": "Đức Ninh",
        "type": "xa",
        "slug": "duc-ninh",
        "name_with_type": "Xã Đức Ninh",
        "path": "Đức Ninh, Đồng Hới, Quảng Bình",
        "path_with_type": "Xã Đức Ninh, Thành Phố Đồng Hới, Tỉnh Quảng Bình",
        "code": "18898",
        "parent_code": "450"
    },
    "18901": {
        "name": "Quy Đạt",
        "type": "thi-tran",
        "slug": "quy-dat",
        "name_with_type": "Thị trấn Quy Đạt",
        "path": "Quy Đạt, Minh Hóa, Quảng Bình",
        "path_with_type": "Thị trấn Quy Đạt, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18901",
        "parent_code": "452"
    },
    "18904": {
        "name": "Dân Hóa",
        "type": "xa",
        "slug": "dan-hoa",
        "name_with_type": "Xã Dân Hóa",
        "path": "Dân Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Dân Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18904",
        "parent_code": "452"
    },
    "18907": {
        "name": "Trọng Hóa",
        "type": "xa",
        "slug": "trong-hoa",
        "name_with_type": "Xã Trọng Hóa",
        "path": "Trọng Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Trọng Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18907",
        "parent_code": "452"
    },
    "18910": {
        "name": "Hóa Phúc",
        "type": "xa",
        "slug": "hoa-phuc",
        "name_with_type": "Xã Hóa Phúc",
        "path": "Hóa Phúc, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Hóa Phúc, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18910",
        "parent_code": "452"
    },
    "18913": {
        "name": "Hồng Hóa",
        "type": "xa",
        "slug": "hong-hoa",
        "name_with_type": "Xã Hồng Hóa",
        "path": "Hồng Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Hồng Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18913",
        "parent_code": "452"
    },
    "18916": {
        "name": "Hóa Thanh",
        "type": "xa",
        "slug": "hoa-thanh",
        "name_with_type": "Xã Hóa Thanh",
        "path": "Hóa Thanh, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Hóa Thanh, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18916",
        "parent_code": "452"
    },
    "18919": {
        "name": "Hóa Tiến",
        "type": "xa",
        "slug": "hoa-tien",
        "name_with_type": "Xã Hóa Tiến",
        "path": "Hóa Tiến, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Hóa Tiến, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18919",
        "parent_code": "452"
    },
    "18922": {
        "name": "Hóa Hợp",
        "type": "xa",
        "slug": "hoa-hop",
        "name_with_type": "Xã Hóa Hợp",
        "path": "Hóa Hợp, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Hóa Hợp, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18922",
        "parent_code": "452"
    },
    "18925": {
        "name": "Xuân Hóa",
        "type": "xa",
        "slug": "xuan-hoa",
        "name_with_type": "Xã Xuân Hóa",
        "path": "Xuân Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Xuân Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18925",
        "parent_code": "452"
    },
    "18928": {
        "name": "Yên Hóa",
        "type": "xa",
        "slug": "yen-hoa",
        "name_with_type": "Xã Yên Hóa",
        "path": "Yên Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Yên Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18928",
        "parent_code": "452"
    },
    "18931": {
        "name": "Minh Hóa",
        "type": "xa",
        "slug": "minh-hoa",
        "name_with_type": "Xã Minh Hóa",
        "path": "Minh Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Minh Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18931",
        "parent_code": "452"
    },
    "18934": {
        "name": "Tân Hóa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hóa",
        "path": "Tân Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Tân Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18934",
        "parent_code": "452"
    },
    "18937": {
        "name": "Hóa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hóa Sơn",
        "path": "Hóa Sơn, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Hóa Sơn, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18937",
        "parent_code": "452"
    },
    "18940": {
        "name": "Quy Hóa",
        "type": "xa",
        "slug": "quy-hoa",
        "name_with_type": "Xã Quy Hóa",
        "path": "Quy Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Quy Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18940",
        "parent_code": "452"
    },
    "18943": {
        "name": "Trung Hóa",
        "type": "xa",
        "slug": "trung-hoa",
        "name_with_type": "Xã Trung Hóa",
        "path": "Trung Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Trung Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18943",
        "parent_code": "452"
    },
    "18946": {
        "name": "Thượng Hóa",
        "type": "xa",
        "slug": "thuong-hoa",
        "name_with_type": "Xã Thượng Hóa",
        "path": "Thượng Hóa, Minh Hóa, Quảng Bình",
        "path_with_type": "Xã Thượng Hóa, Huyện Minh Hóa, Tỉnh Quảng Bình",
        "code": "18946",
        "parent_code": "452"
    },
    "18949": {
        "name": "Đồng Lê",
        "type": "thi-tran",
        "slug": "dong-le",
        "name_with_type": "Thị trấn Đồng Lê",
        "path": "Đồng Lê, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Thị trấn Đồng Lê, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18949",
        "parent_code": "453"
    },
    "18952": {
        "name": "Hương Hóa",
        "type": "xa",
        "slug": "huong-hoa",
        "name_with_type": "Xã Hương Hóa",
        "path": "Hương Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Hương Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18952",
        "parent_code": "453"
    },
    "18955": {
        "name": "Kim Hóa",
        "type": "xa",
        "slug": "kim-hoa",
        "name_with_type": "Xã Kim Hóa",
        "path": "Kim Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Kim Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18955",
        "parent_code": "453"
    },
    "18958": {
        "name": "Thanh Hóa",
        "type": "xa",
        "slug": "thanh-hoa",
        "name_with_type": "Xã Thanh Hóa",
        "path": "Thanh Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Thanh Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18958",
        "parent_code": "453"
    },
    "18961": {
        "name": "Thanh Thạch",
        "type": "xa",
        "slug": "thanh-thach",
        "name_with_type": "Xã Thanh Thạch",
        "path": "Thanh Thạch, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Thanh Thạch, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18961",
        "parent_code": "453"
    },
    "18964": {
        "name": "Thuận Hóa",
        "type": "xa",
        "slug": "thuan-hoa",
        "name_with_type": "Xã Thuận Hóa",
        "path": "Thuận Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Thuận Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18964",
        "parent_code": "453"
    },
    "18967": {
        "name": "Lâm Hóa",
        "type": "xa",
        "slug": "lam-hoa",
        "name_with_type": "Xã Lâm Hóa",
        "path": "Lâm Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Lâm Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18967",
        "parent_code": "453"
    },
    "18970": {
        "name": "Lê Hóa",
        "type": "xa",
        "slug": "le-hoa",
        "name_with_type": "Xã Lê Hóa",
        "path": "Lê Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Lê Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18970",
        "parent_code": "453"
    },
    "18973": {
        "name": "Sơn Hóa",
        "type": "xa",
        "slug": "son-hoa",
        "name_with_type": "Xã Sơn Hóa",
        "path": "Sơn Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Sơn Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18973",
        "parent_code": "453"
    },
    "18976": {
        "name": "Đồng Hóa",
        "type": "xa",
        "slug": "dong-hoa",
        "name_with_type": "Xã Đồng Hóa",
        "path": "Đồng Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Đồng Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18976",
        "parent_code": "453"
    },
    "18979": {
        "name": "Ngư Hóa",
        "type": "xa",
        "slug": "ngu-hoa",
        "name_with_type": "Xã Ngư Hóa",
        "path": "Ngư Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Ngư Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18979",
        "parent_code": "453"
    },
    "18982": {
        "name": "Nam Hóa",
        "type": "xa",
        "slug": "nam-hoa",
        "name_with_type": "Xã Nam Hóa",
        "path": "Nam Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Nam Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18982",
        "parent_code": "453"
    },
    "18985": {
        "name": "Thạch Hóa",
        "type": "xa",
        "slug": "thach-hoa",
        "name_with_type": "Xã Thạch Hóa",
        "path": "Thạch Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Thạch Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18985",
        "parent_code": "453"
    },
    "18988": {
        "name": "Đức Hóa",
        "type": "xa",
        "slug": "duc-hoa",
        "name_with_type": "Xã Đức Hóa",
        "path": "Đức Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Đức Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18988",
        "parent_code": "453"
    },
    "18991": {
        "name": "Phong Hóa",
        "type": "xa",
        "slug": "phong-hoa",
        "name_with_type": "Xã Phong Hóa",
        "path": "Phong Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Phong Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18991",
        "parent_code": "453"
    },
    "18994": {
        "name": "Mai Hóa",
        "type": "xa",
        "slug": "mai-hoa",
        "name_with_type": "Xã Mai Hóa",
        "path": "Mai Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Mai Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18994",
        "parent_code": "453"
    },
    "18997": {
        "name": "Tiến Hóa",
        "type": "xa",
        "slug": "tien-hoa",
        "name_with_type": "Xã Tiến Hóa",
        "path": "Tiến Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Tiến Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "18997",
        "parent_code": "453"
    },
    "19000": {
        "name": "Châu Hóa",
        "type": "xa",
        "slug": "chau-hoa",
        "name_with_type": "Xã Châu Hóa",
        "path": "Châu Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Châu Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "19000",
        "parent_code": "453"
    },
    "19003": {
        "name": "Cao Quảng",
        "type": "xa",
        "slug": "cao-quang",
        "name_with_type": "Xã Cao Quảng",
        "path": "Cao Quảng, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Cao Quảng, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "19003",
        "parent_code": "453"
    },
    "19006": {
        "name": "Văn Hóa",
        "type": "xa",
        "slug": "van-hoa",
        "name_with_type": "Xã Văn Hóa",
        "path": "Văn Hóa, Tuyên Hóa, Quảng Bình",
        "path_with_type": "Xã Văn Hóa, Huyện Tuyên Hóa, Tỉnh Quảng Bình",
        "code": "19006",
        "parent_code": "453"
    },
    "19012": {
        "name": "Quảng Hợp",
        "type": "xa",
        "slug": "quang-hop",
        "name_with_type": "Xã Quảng Hợp",
        "path": "Quảng Hợp, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Hợp, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19012",
        "parent_code": "454"
    },
    "19015": {
        "name": "Quảng Kim",
        "type": "xa",
        "slug": "quang-kim",
        "name_with_type": "Xã Quảng Kim",
        "path": "Quảng Kim, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Kim, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19015",
        "parent_code": "454"
    },
    "19018": {
        "name": "Quảng Đông",
        "type": "xa",
        "slug": "quang-dong",
        "name_with_type": "Xã Quảng Đông",
        "path": "Quảng Đông, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Đông, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19018",
        "parent_code": "454"
    },
    "19021": {
        "name": "Quảng Phú",
        "type": "xa",
        "slug": "quang-phu",
        "name_with_type": "Xã Quảng Phú",
        "path": "Quảng Phú, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Phú, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19021",
        "parent_code": "454"
    },
    "19024": {
        "name": "Quảng Châu",
        "type": "xa",
        "slug": "quang-chau",
        "name_with_type": "Xã Quảng Châu",
        "path": "Quảng Châu, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Châu, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19024",
        "parent_code": "454"
    },
    "19027": {
        "name": "Quảng Thạch",
        "type": "xa",
        "slug": "quang-thach",
        "name_with_type": "Xã Quảng Thạch",
        "path": "Quảng Thạch, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Thạch, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19027",
        "parent_code": "454"
    },
    "19030": {
        "name": "Quảng Lưu",
        "type": "xa",
        "slug": "quang-luu",
        "name_with_type": "Xã Quảng Lưu",
        "path": "Quảng Lưu, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Lưu, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19030",
        "parent_code": "454"
    },
    "19033": {
        "name": "Quảng Tùng",
        "type": "xa",
        "slug": "quang-tung",
        "name_with_type": "Xã Quảng Tùng",
        "path": "Quảng Tùng, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Tùng, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19033",
        "parent_code": "454"
    },
    "19036": {
        "name": "Cảnh Dương",
        "type": "xa",
        "slug": "canh-duong",
        "name_with_type": "Xã Cảnh Dương",
        "path": "Cảnh Dương, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Cảnh Dương, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19036",
        "parent_code": "454"
    },
    "19039": {
        "name": "Quảng Tiến",
        "type": "xa",
        "slug": "quang-tien",
        "name_with_type": "Xã Quảng Tiến",
        "path": "Quảng Tiến, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Tiến, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19039",
        "parent_code": "454"
    },
    "19042": {
        "name": "Quảng Hưng",
        "type": "xa",
        "slug": "quang-hung",
        "name_with_type": "Xã Quảng Hưng",
        "path": "Quảng Hưng, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Hưng, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19042",
        "parent_code": "454"
    },
    "19045": {
        "name": "Quảng Xuân",
        "type": "xa",
        "slug": "quang-xuan",
        "name_with_type": "Xã Quảng Xuân",
        "path": "Quảng Xuân, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Xuân, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19045",
        "parent_code": "454"
    },
    "19048": {
        "name": "Cảnh Hóa",
        "type": "xa",
        "slug": "canh-hoa",
        "name_with_type": "Xã Cảnh Hóa",
        "path": "Cảnh Hóa, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Cảnh Hóa, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19048",
        "parent_code": "454"
    },
    "19051": {
        "name": "Quảng Liên",
        "type": "xa",
        "slug": "quang-lien",
        "name_with_type": "Xã Quảng Liên",
        "path": "Quảng Liên, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Liên, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19051",
        "parent_code": "454"
    },
    "19054": {
        "name": "Quảng Trường",
        "type": "xa",
        "slug": "quang-truong",
        "name_with_type": "Xã Quảng Trường",
        "path": "Quảng Trường, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Trường, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19054",
        "parent_code": "454"
    },
    "19057": {
        "name": "Quảng Phương",
        "type": "xa",
        "slug": "quang-phuong",
        "name_with_type": "Xã Quảng Phương",
        "path": "Quảng Phương, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Phương, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19057",
        "parent_code": "454"
    },
    "19063": {
        "name": "Phù Hóa",
        "type": "xa",
        "slug": "phu-hoa",
        "name_with_type": "Xã Phù Hóa",
        "path": "Phù Hóa, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Phù Hóa, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19063",
        "parent_code": "454"
    },
    "19072": {
        "name": "Quảng Thanh",
        "type": "xa",
        "slug": "quang-thanh",
        "name_with_type": "Xã Quảng Thanh",
        "path": "Quảng Thanh, Quảng Trạch, Quảng Bình",
        "path_with_type": "Xã Quảng Thanh, Huyện Quảng Trạch, Tỉnh Quảng Bình",
        "code": "19072",
        "parent_code": "454"
    },
    "19111": {
        "name": "Hoàn Lão",
        "type": "thi-tran",
        "slug": "hoan-lao",
        "name_with_type": "Thị trấn Hoàn Lão",
        "path": "Hoàn Lão, Bố Trạch, Quảng Bình",
        "path_with_type": "Thị trấn Hoàn Lão, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19111",
        "parent_code": "455"
    },
    "19114": {
        "name": "NT Việt Trung",
        "type": "thi-tran",
        "slug": "nt-viet-trung",
        "name_with_type": "Thị trấn NT Việt Trung",
        "path": "NT Việt Trung, Bố Trạch, Quảng Bình",
        "path_with_type": "Thị trấn NT Việt Trung, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19114",
        "parent_code": "455"
    },
    "19117": {
        "name": "Xuân Trạch",
        "type": "xa",
        "slug": "xuan-trach",
        "name_with_type": "Xã Xuân Trạch",
        "path": "Xuân Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Xuân Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19117",
        "parent_code": "455"
    },
    "19120": {
        "name": "Mỹ Trạch",
        "type": "xa",
        "slug": "my-trach",
        "name_with_type": "Xã Mỹ Trạch",
        "path": "Mỹ Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Mỹ Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19120",
        "parent_code": "455"
    },
    "19123": {
        "name": "Hạ Trạch",
        "type": "xa",
        "slug": "ha-trach",
        "name_with_type": "Xã Hạ Trạch",
        "path": "Hạ Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Hạ Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19123",
        "parent_code": "455"
    },
    "19126": {
        "name": "Bắc Trạch",
        "type": "xa",
        "slug": "bac-trach",
        "name_with_type": "Xã Bắc Trạch",
        "path": "Bắc Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Bắc Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19126",
        "parent_code": "455"
    },
    "19129": {
        "name": "Lâm Trạch",
        "type": "xa",
        "slug": "lam-trach",
        "name_with_type": "Xã Lâm Trạch",
        "path": "Lâm Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Lâm Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19129",
        "parent_code": "455"
    },
    "19132": {
        "name": "Thanh Trạch",
        "type": "xa",
        "slug": "thanh-trach",
        "name_with_type": "Xã Thanh Trạch",
        "path": "Thanh Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Thanh Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19132",
        "parent_code": "455"
    },
    "19135": {
        "name": "Liên Trạch",
        "type": "xa",
        "slug": "lien-trach",
        "name_with_type": "Xã Liên Trạch",
        "path": "Liên Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Liên Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19135",
        "parent_code": "455"
    },
    "19138": {
        "name": "Phúc Trạch",
        "type": "xa",
        "slug": "phuc-trach",
        "name_with_type": "Xã Phúc Trạch",
        "path": "Phúc Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Phúc Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19138",
        "parent_code": "455"
    },
    "19141": {
        "name": "Cự Nẫm",
        "type": "xa",
        "slug": "cu-nam",
        "name_with_type": "Xã Cự Nẫm",
        "path": "Cự Nẫm, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Cự Nẫm, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19141",
        "parent_code": "455"
    },
    "19144": {
        "name": "Hải Trạch",
        "type": "xa",
        "slug": "hai-trach",
        "name_with_type": "Xã Hải Trạch",
        "path": "Hải Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Hải Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19144",
        "parent_code": "455"
    },
    "19147": {
        "name": "Thượng Trạch",
        "type": "xa",
        "slug": "thuong-trach",
        "name_with_type": "Xã Thượng Trạch",
        "path": "Thượng Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Thượng Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19147",
        "parent_code": "455"
    },
    "19150": {
        "name": "Sơn Lộc",
        "type": "xa",
        "slug": "son-loc",
        "name_with_type": "Xã Sơn Lộc",
        "path": "Sơn Lộc, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Sơn Lộc, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19150",
        "parent_code": "455"
    },
    "19153": {
        "name": "Phú Trạch",
        "type": "xa",
        "slug": "phu-trach",
        "name_with_type": "Xã Phú Trạch",
        "path": "Phú Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Phú Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19153",
        "parent_code": "455"
    },
    "19156": {
        "name": "Hưng Trạch",
        "type": "xa",
        "slug": "hung-trach",
        "name_with_type": "Xã Hưng Trạch",
        "path": "Hưng Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Hưng Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19156",
        "parent_code": "455"
    },
    "19159": {
        "name": "Đồng Trạch",
        "type": "xa",
        "slug": "dong-trach",
        "name_with_type": "Xã Đồng Trạch",
        "path": "Đồng Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Đồng Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19159",
        "parent_code": "455"
    },
    "19162": {
        "name": "Đức Trạch",
        "type": "xa",
        "slug": "duc-trach",
        "name_with_type": "Xã Đức Trạch",
        "path": "Đức Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Đức Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19162",
        "parent_code": "455"
    },
    "19165": {
        "name": "Sơn Trạch",
        "type": "xa",
        "slug": "son-trach",
        "name_with_type": "Xã Sơn Trạch",
        "path": "Sơn Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Sơn Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19165",
        "parent_code": "455"
    },
    "19168": {
        "name": "Vạn Trạch",
        "type": "xa",
        "slug": "van-trach",
        "name_with_type": "Xã Vạn Trạch",
        "path": "Vạn Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Vạn Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19168",
        "parent_code": "455"
    },
    "19171": {
        "name": "Hoàn Trạch",
        "type": "xa",
        "slug": "hoan-trach",
        "name_with_type": "Xã Hoàn Trạch",
        "path": "Hoàn Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Hoàn Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19171",
        "parent_code": "455"
    },
    "19174": {
        "name": "Phú Định",
        "type": "xa",
        "slug": "phu-dinh",
        "name_with_type": "Xã Phú Định",
        "path": "Phú Định, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Phú Định, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19174",
        "parent_code": "455"
    },
    "19177": {
        "name": "Trung Trạch",
        "type": "xa",
        "slug": "trung-trach",
        "name_with_type": "Xã Trung Trạch",
        "path": "Trung Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Trung Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19177",
        "parent_code": "455"
    },
    "19180": {
        "name": "Tây Trạch",
        "type": "xa",
        "slug": "tay-trach",
        "name_with_type": "Xã Tây Trạch",
        "path": "Tây Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Tây Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19180",
        "parent_code": "455"
    },
    "19183": {
        "name": "Hòa Trạch",
        "type": "xa",
        "slug": "hoa-trach",
        "name_with_type": "Xã Hòa Trạch",
        "path": "Hòa Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Hòa Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19183",
        "parent_code": "455"
    },
    "19186": {
        "name": "Đại Trạch",
        "type": "xa",
        "slug": "dai-trach",
        "name_with_type": "Xã Đại Trạch",
        "path": "Đại Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Đại Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19186",
        "parent_code": "455"
    },
    "19189": {
        "name": "Nhân Trạch",
        "type": "xa",
        "slug": "nhan-trach",
        "name_with_type": "Xã Nhân Trạch",
        "path": "Nhân Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Nhân Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19189",
        "parent_code": "455"
    },
    "19192": {
        "name": "Tân Trạch",
        "type": "xa",
        "slug": "tan-trach",
        "name_with_type": "Xã Tân Trạch",
        "path": "Tân Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Tân Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19192",
        "parent_code": "455"
    },
    "19195": {
        "name": "Nam Trạch",
        "type": "xa",
        "slug": "nam-trach",
        "name_with_type": "Xã Nam Trạch",
        "path": "Nam Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Nam Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19195",
        "parent_code": "455"
    },
    "19198": {
        "name": "Lý Trạch",
        "type": "xa",
        "slug": "ly-trach",
        "name_with_type": "Xã Lý Trạch",
        "path": "Lý Trạch, Bố Trạch, Quảng Bình",
        "path_with_type": "Xã Lý Trạch, Huyện Bố Trạch, Tỉnh Quảng Bình",
        "code": "19198",
        "parent_code": "455"
    },
    "19201": {
        "name": "Quán Hàu",
        "type": "thi-tran",
        "slug": "quan-hau",
        "name_with_type": "Thị trấn Quán Hàu",
        "path": "Quán Hàu, Quảng Ninh, Quảng Bình",
        "path_with_type": "Thị trấn Quán Hàu, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19201",
        "parent_code": "456"
    },
    "19204": {
        "name": "Trường Sơn",
        "type": "xa",
        "slug": "truong-son",
        "name_with_type": "Xã Trường Sơn",
        "path": "Trường Sơn, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Trường Sơn, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19204",
        "parent_code": "456"
    },
    "19207": {
        "name": "Lương Ninh",
        "type": "xa",
        "slug": "luong-ninh",
        "name_with_type": "Xã Lương Ninh",
        "path": "Lương Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Lương Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19207",
        "parent_code": "456"
    },
    "19210": {
        "name": "Vĩnh Ninh",
        "type": "xa",
        "slug": "vinh-ninh",
        "name_with_type": "Xã Vĩnh Ninh",
        "path": "Vĩnh Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Vĩnh Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19210",
        "parent_code": "456"
    },
    "19213": {
        "name": "Võ Ninh",
        "type": "xa",
        "slug": "vo-ninh",
        "name_with_type": "Xã Võ Ninh",
        "path": "Võ Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Võ Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19213",
        "parent_code": "456"
    },
    "19216": {
        "name": "Hải Ninh",
        "type": "xa",
        "slug": "hai-ninh",
        "name_with_type": "Xã Hải Ninh",
        "path": "Hải Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Hải Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19216",
        "parent_code": "456"
    },
    "19219": {
        "name": "Hàm Ninh",
        "type": "xa",
        "slug": "ham-ninh",
        "name_with_type": "Xã Hàm Ninh",
        "path": "Hàm Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Hàm Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19219",
        "parent_code": "456"
    },
    "19222": {
        "name": "Duy Ninh",
        "type": "xa",
        "slug": "duy-ninh",
        "name_with_type": "Xã Duy Ninh",
        "path": "Duy Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Duy Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19222",
        "parent_code": "456"
    },
    "19225": {
        "name": "Gia Ninh",
        "type": "xa",
        "slug": "gia-ninh",
        "name_with_type": "Xã Gia Ninh",
        "path": "Gia Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Gia Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19225",
        "parent_code": "456"
    },
    "19228": {
        "name": "Trường Xuân",
        "type": "xa",
        "slug": "truong-xuan",
        "name_with_type": "Xã Trường Xuân",
        "path": "Trường Xuân, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Trường Xuân, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19228",
        "parent_code": "456"
    },
    "19231": {
        "name": "Hiền Ninh",
        "type": "xa",
        "slug": "hien-ninh",
        "name_with_type": "Xã Hiền Ninh",
        "path": "Hiền Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Hiền Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19231",
        "parent_code": "456"
    },
    "19234": {
        "name": "Tân Ninh",
        "type": "xa",
        "slug": "tan-ninh",
        "name_with_type": "Xã Tân Ninh",
        "path": "Tân Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Tân Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19234",
        "parent_code": "456"
    },
    "19237": {
        "name": "Xuân Ninh",
        "type": "xa",
        "slug": "xuan-ninh",
        "name_with_type": "Xã Xuân Ninh",
        "path": "Xuân Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Xuân Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19237",
        "parent_code": "456"
    },
    "19240": {
        "name": "An Ninh",
        "type": "xa",
        "slug": "an-ninh",
        "name_with_type": "Xã An Ninh",
        "path": "An Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã An Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19240",
        "parent_code": "456"
    },
    "19243": {
        "name": "Vạn Ninh",
        "type": "xa",
        "slug": "van-ninh",
        "name_with_type": "Xã Vạn Ninh",
        "path": "Vạn Ninh, Quảng Ninh, Quảng Bình",
        "path_with_type": "Xã Vạn Ninh, Huyện Quảng Ninh, Tỉnh Quảng Bình",
        "code": "19243",
        "parent_code": "456"
    },
    "19246": {
        "name": "NT Lệ Ninh",
        "type": "thi-tran",
        "slug": "nt-le-ninh",
        "name_with_type": "Thị trấn NT Lệ Ninh",
        "path": "NT Lệ Ninh, Lệ Thủy, Quảng Bình",
        "path_with_type": "Thị trấn NT Lệ Ninh, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19246",
        "parent_code": "457"
    },
    "19249": {
        "name": "Kiến Giang",
        "type": "thi-tran",
        "slug": "kien-giang",
        "name_with_type": "Thị trấn Kiến Giang",
        "path": "Kiến Giang, Lệ Thủy, Quảng Bình",
        "path_with_type": "Thị trấn Kiến Giang, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19249",
        "parent_code": "457"
    },
    "19252": {
        "name": "Hồng Thủy",
        "type": "xa",
        "slug": "hong-thuy",
        "name_with_type": "Xã Hồng Thủy",
        "path": "Hồng Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Hồng Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19252",
        "parent_code": "457"
    },
    "19255": {
        "name": "Ngư Thủy Bắc",
        "type": "xa",
        "slug": "ngu-thuy-bac",
        "name_with_type": "Xã Ngư Thủy Bắc",
        "path": "Ngư Thủy Bắc, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Ngư Thủy Bắc, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19255",
        "parent_code": "457"
    },
    "19258": {
        "name": "Hoa Thủy",
        "type": "xa",
        "slug": "hoa-thuy",
        "name_with_type": "Xã Hoa Thủy",
        "path": "Hoa Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Hoa Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19258",
        "parent_code": "457"
    },
    "19261": {
        "name": "Thanh Thủy",
        "type": "xa",
        "slug": "thanh-thuy",
        "name_with_type": "Xã Thanh Thủy",
        "path": "Thanh Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Thanh Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19261",
        "parent_code": "457"
    },
    "19264": {
        "name": "An Thủy",
        "type": "xa",
        "slug": "an-thuy",
        "name_with_type": "Xã An Thủy",
        "path": "An Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã An Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19264",
        "parent_code": "457"
    },
    "19267": {
        "name": "Phong Thủy",
        "type": "xa",
        "slug": "phong-thuy",
        "name_with_type": "Xã Phong Thủy",
        "path": "Phong Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Phong Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19267",
        "parent_code": "457"
    },
    "19270": {
        "name": "Cam Thủy",
        "type": "xa",
        "slug": "cam-thuy",
        "name_with_type": "Xã Cam Thủy",
        "path": "Cam Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Cam Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19270",
        "parent_code": "457"
    },
    "19273": {
        "name": "Ngân Thủy",
        "type": "xa",
        "slug": "ngan-thuy",
        "name_with_type": "Xã Ngân Thủy",
        "path": "Ngân Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Ngân Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19273",
        "parent_code": "457"
    },
    "19276": {
        "name": "Sơn Thủy",
        "type": "xa",
        "slug": "son-thuy",
        "name_with_type": "Xã Sơn Thủy",
        "path": "Sơn Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Sơn Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19276",
        "parent_code": "457"
    },
    "19279": {
        "name": "Lộc Thủy",
        "type": "xa",
        "slug": "loc-thuy",
        "name_with_type": "Xã Lộc Thủy",
        "path": "Lộc Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Lộc Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19279",
        "parent_code": "457"
    },
    "19282": {
        "name": "Ngư Thủy Trung",
        "type": "xa",
        "slug": "ngu-thuy-trung",
        "name_with_type": "Xã Ngư Thủy Trung",
        "path": "Ngư Thủy Trung, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Ngư Thủy Trung, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19282",
        "parent_code": "457"
    },
    "19285": {
        "name": "Liên Thủy",
        "type": "xa",
        "slug": "lien-thuy",
        "name_with_type": "Xã Liên Thủy",
        "path": "Liên Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Liên Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19285",
        "parent_code": "457"
    },
    "19288": {
        "name": "Hưng Thủy",
        "type": "xa",
        "slug": "hung-thuy",
        "name_with_type": "Xã Hưng Thủy",
        "path": "Hưng Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Hưng Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19288",
        "parent_code": "457"
    },
    "19291": {
        "name": "Dương Thủy",
        "type": "xa",
        "slug": "duong-thuy",
        "name_with_type": "Xã Dương Thủy",
        "path": "Dương Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Dương Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19291",
        "parent_code": "457"
    },
    "19294": {
        "name": "Tân Thủy",
        "type": "xa",
        "slug": "tan-thuy",
        "name_with_type": "Xã Tân Thủy",
        "path": "Tân Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Tân Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19294",
        "parent_code": "457"
    },
    "19297": {
        "name": "Phú Thủy",
        "type": "xa",
        "slug": "phu-thuy",
        "name_with_type": "Xã Phú Thủy",
        "path": "Phú Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Phú Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19297",
        "parent_code": "457"
    },
    "19300": {
        "name": "Xuân Thủy",
        "type": "xa",
        "slug": "xuan-thuy",
        "name_with_type": "Xã Xuân Thủy",
        "path": "Xuân Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Xuân Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19300",
        "parent_code": "457"
    },
    "19303": {
        "name": "Mỹ Thủy",
        "type": "xa",
        "slug": "my-thuy",
        "name_with_type": "Xã Mỹ Thủy",
        "path": "Mỹ Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Mỹ Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19303",
        "parent_code": "457"
    },
    "19306": {
        "name": "Ngư Thủy Nam",
        "type": "xa",
        "slug": "ngu-thuy-nam",
        "name_with_type": "Xã Ngư Thủy Nam",
        "path": "Ngư Thủy Nam, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Ngư Thủy Nam, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19306",
        "parent_code": "457"
    },
    "19309": {
        "name": "Mai Thủy",
        "type": "xa",
        "slug": "mai-thuy",
        "name_with_type": "Xã Mai Thủy",
        "path": "Mai Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Mai Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19309",
        "parent_code": "457"
    },
    "19312": {
        "name": "Sen Thủy",
        "type": "xa",
        "slug": "sen-thuy",
        "name_with_type": "Xã Sen Thủy",
        "path": "Sen Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Sen Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19312",
        "parent_code": "457"
    },
    "19315": {
        "name": "Thái Thủy",
        "type": "xa",
        "slug": "thai-thuy",
        "name_with_type": "Xã Thái Thủy",
        "path": "Thái Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Thái Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19315",
        "parent_code": "457"
    },
    "19318": {
        "name": "Kim Thủy",
        "type": "xa",
        "slug": "kim-thuy",
        "name_with_type": "Xã Kim Thủy",
        "path": "Kim Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Kim Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19318",
        "parent_code": "457"
    },
    "19321": {
        "name": "Trường Thủy",
        "type": "xa",
        "slug": "truong-thuy",
        "name_with_type": "Xã Trường Thủy",
        "path": "Trường Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Trường Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19321",
        "parent_code": "457"
    },
    "19324": {
        "name": "Văn Thủy",
        "type": "xa",
        "slug": "van-thuy",
        "name_with_type": "Xã Văn Thủy",
        "path": "Văn Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Văn Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19324",
        "parent_code": "457"
    },
    "19327": {
        "name": "Lâm Thủy",
        "type": "xa",
        "slug": "lam-thuy",
        "name_with_type": "Xã Lâm Thủy",
        "path": "Lâm Thủy, Lệ Thủy, Quảng Bình",
        "path_with_type": "Xã Lâm Thủy, Huyện Lệ Thủy, Tỉnh Quảng Bình",
        "code": "19327",
        "parent_code": "457"
    },
    "19009": {
        "name": "Ba Đồn",
        "type": "phuong",
        "slug": "ba-don",
        "name_with_type": "Phường Ba Đồn",
        "path": "Ba Đồn, Ba Đồn, Quảng Bình",
        "path_with_type": "Phường Ba Đồn, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19009",
        "parent_code": "458"
    },
    "19060": {
        "name": "Quảng Long",
        "type": "phuong",
        "slug": "quang-long",
        "name_with_type": "Phường Quảng Long",
        "path": "Quảng Long, Ba Đồn, Quảng Bình",
        "path_with_type": "Phường Quảng Long, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19060",
        "parent_code": "458"
    },
    "19066": {
        "name": "Quảng Thọ",
        "type": "phuong",
        "slug": "quang-tho",
        "name_with_type": "Phường Quảng Thọ",
        "path": "Quảng Thọ, Ba Đồn, Quảng Bình",
        "path_with_type": "Phường Quảng Thọ, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19066",
        "parent_code": "458"
    },
    "19069": {
        "name": "Quảng Tiên",
        "type": "xa",
        "slug": "quang-tien",
        "name_with_type": "Xã Quảng Tiên",
        "path": "Quảng Tiên, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Tiên, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19069",
        "parent_code": "458"
    },
    "19075": {
        "name": "Quảng Trung",
        "type": "xa",
        "slug": "quang-trung",
        "name_with_type": "Xã Quảng Trung",
        "path": "Quảng Trung, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Trung, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19075",
        "parent_code": "458"
    },
    "19078": {
        "name": "Quảng Phong",
        "type": "phuong",
        "slug": "quang-phong",
        "name_with_type": "Phường Quảng Phong",
        "path": "Quảng Phong, Ba Đồn, Quảng Bình",
        "path_with_type": "Phường Quảng Phong, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19078",
        "parent_code": "458"
    },
    "19081": {
        "name": "Quảng Thuận",
        "type": "phuong",
        "slug": "quang-thuan",
        "name_with_type": "Phường Quảng Thuận",
        "path": "Quảng Thuận, Ba Đồn, Quảng Bình",
        "path_with_type": "Phường Quảng Thuận, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19081",
        "parent_code": "458"
    },
    "19084": {
        "name": "Quảng Tân",
        "type": "xa",
        "slug": "quang-tan",
        "name_with_type": "Xã Quảng Tân",
        "path": "Quảng Tân, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Tân, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19084",
        "parent_code": "458"
    },
    "19087": {
        "name": "Quảng Hải",
        "type": "xa",
        "slug": "quang-hai",
        "name_with_type": "Xã Quảng Hải",
        "path": "Quảng Hải, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Hải, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19087",
        "parent_code": "458"
    },
    "19090": {
        "name": "Quảng Sơn",
        "type": "xa",
        "slug": "quang-son",
        "name_with_type": "Xã Quảng Sơn",
        "path": "Quảng Sơn, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Sơn, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19090",
        "parent_code": "458"
    },
    "19093": {
        "name": "Quảng Lộc",
        "type": "xa",
        "slug": "quang-loc",
        "name_with_type": "Xã Quảng Lộc",
        "path": "Quảng Lộc, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Lộc, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19093",
        "parent_code": "458"
    },
    "19096": {
        "name": "Quảng Thủy",
        "type": "xa",
        "slug": "quang-thuy",
        "name_with_type": "Xã Quảng Thủy",
        "path": "Quảng Thủy, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Thủy, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19096",
        "parent_code": "458"
    },
    "19099": {
        "name": "Quảng Văn",
        "type": "xa",
        "slug": "quang-van",
        "name_with_type": "Xã Quảng Văn",
        "path": "Quảng Văn, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Văn, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19099",
        "parent_code": "458"
    },
    "19102": {
        "name": "Quảng Phúc",
        "type": "phuong",
        "slug": "quang-phuc",
        "name_with_type": "Phường Quảng Phúc",
        "path": "Quảng Phúc, Ba Đồn, Quảng Bình",
        "path_with_type": "Phường Quảng Phúc, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19102",
        "parent_code": "458"
    },
    "19105": {
        "name": "Quảng Hòa",
        "type": "xa",
        "slug": "quang-hoa",
        "name_with_type": "Xã Quảng Hòa",
        "path": "Quảng Hòa, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Hòa, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19105",
        "parent_code": "458"
    },
    "19108": {
        "name": "Quảng Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quảng Minh",
        "path": "Quảng Minh, Ba Đồn, Quảng Bình",
        "path_with_type": "Xã Quảng Minh, Thị xã Ba Đồn, Tỉnh Quảng Bình",
        "code": "19108",
        "parent_code": "458"
    },
    "26734": {
        "name": "Tân Định",
        "type": "phuong",
        "slug": "tan-dinh",
        "name_with_type": "Phường Tân Định",
        "path": "Tân Định, 1, Hồ Chí Minh",
        "path_with_type": "Phường Tân Định, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26734",
        "parent_code": "760"
    },
    "26737": {
        "name": "Đa Kao",
        "type": "phuong",
        "slug": "da-kao",
        "name_with_type": "Phường Đa Kao",
        "path": "Đa Kao, 1, Hồ Chí Minh",
        "path_with_type": "Phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26737",
        "parent_code": "760"
    },
    "26740": {
        "name": "Bến Nghé",
        "type": "phuong",
        "slug": "ben-nghe",
        "name_with_type": "Phường Bến Nghé",
        "path": "Bến Nghé, 1, Hồ Chí Minh",
        "path_with_type": "Phường Bến Nghé, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26740",
        "parent_code": "760"
    },
    "26743": {
        "name": "Bến Thành",
        "type": "phuong",
        "slug": "ben-thanh",
        "name_with_type": "Phường Bến Thành",
        "path": "Bến Thành, 1, Hồ Chí Minh",
        "path_with_type": "Phường Bến Thành, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26743",
        "parent_code": "760"
    },
    "26746": {
        "name": "Nguyễn Thái Bình",
        "type": "phuong",
        "slug": "nguyen-thai-binh",
        "name_with_type": "Phường Nguyễn Thái Bình",
        "path": "Nguyễn Thái Bình, 1, Hồ Chí Minh",
        "path_with_type": "Phường Nguyễn Thái Bình, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26746",
        "parent_code": "760"
    },
    "26749": {
        "name": "Phạm Ngũ Lão",
        "type": "phuong",
        "slug": "pham-ngu-lao",
        "name_with_type": "Phường Phạm Ngũ Lão",
        "path": "Phạm Ngũ Lão, 1, Hồ Chí Minh",
        "path_with_type": "Phường Phạm Ngũ Lão, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26749",
        "parent_code": "760"
    },
    "26752": {
        "name": "Cầu Ông Lãnh",
        "type": "phuong",
        "slug": "cau-ong-lanh",
        "name_with_type": "Phường Cầu Ông Lãnh",
        "path": "Cầu Ông Lãnh, 1, Hồ Chí Minh",
        "path_with_type": "Phường Cầu Ông Lãnh, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26752",
        "parent_code": "760"
    },
    "26755": {
        "name": "Cô Giang",
        "type": "phuong",
        "slug": "co-giang",
        "name_with_type": "Phường Cô Giang",
        "path": "Cô Giang, 1, Hồ Chí Minh",
        "path_with_type": "Phường Cô Giang, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26755",
        "parent_code": "760"
    },
    "26758": {
        "name": "Nguyễn Cư Trinh",
        "type": "phuong",
        "slug": "nguyen-cu-trinh",
        "name_with_type": "Phường Nguyễn Cư Trinh",
        "path": "Nguyễn Cư Trinh, 1, Hồ Chí Minh",
        "path_with_type": "Phường Nguyễn Cư Trinh, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26758",
        "parent_code": "760"
    },
    "26761": {
        "name": "Cầu Kho",
        "type": "phuong",
        "slug": "cau-kho",
        "name_with_type": "Phường Cầu Kho",
        "path": "Cầu Kho, 1, Hồ Chí Minh",
        "path_with_type": "Phường Cầu Kho, Quận 1, Thành phố Hồ Chí Minh",
        "code": "26761",
        "parent_code": "760"
    },
    "26764": {
        "name": "Thạnh Xuân",
        "type": "phuong",
        "slug": "thanh-xuan",
        "name_with_type": "Phường Thạnh Xuân",
        "path": "Thạnh Xuân, 12, Hồ Chí Minh",
        "path_with_type": "Phường Thạnh Xuân, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26764",
        "parent_code": "761"
    },
    "26767": {
        "name": "Thạnh Lộc",
        "type": "phuong",
        "slug": "thanh-loc",
        "name_with_type": "Phường Thạnh Lộc",
        "path": "Thạnh Lộc, 12, Hồ Chí Minh",
        "path_with_type": "Phường Thạnh Lộc, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26767",
        "parent_code": "761"
    },
    "26770": {
        "name": "Hiệp Thành",
        "type": "phuong",
        "slug": "hiep-thanh",
        "name_with_type": "Phường Hiệp Thành",
        "path": "Hiệp Thành, 12, Hồ Chí Minh",
        "path_with_type": "Phường Hiệp Thành, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26770",
        "parent_code": "761"
    },
    "26773": {
        "name": "Thới An",
        "type": "phuong",
        "slug": "thoi-an",
        "name_with_type": "Phường Thới An",
        "path": "Thới An, 12, Hồ Chí Minh",
        "path_with_type": "Phường Thới An, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26773",
        "parent_code": "761"
    },
    "26776": {
        "name": "Tân Chánh Hiệp",
        "type": "phuong",
        "slug": "tan-chanh-hiep",
        "name_with_type": "Phường Tân Chánh Hiệp",
        "path": "Tân Chánh Hiệp, 12, Hồ Chí Minh",
        "path_with_type": "Phường Tân Chánh Hiệp, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26776",
        "parent_code": "761"
    },
    "26779": {
        "name": "An Phú Đông",
        "type": "phuong",
        "slug": "an-phu-dong",
        "name_with_type": "Phường An Phú Đông",
        "path": "An Phú Đông, 12, Hồ Chí Minh",
        "path_with_type": "Phường An Phú Đông, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26779",
        "parent_code": "761"
    },
    "26782": {
        "name": "Tân Thới Hiệp",
        "type": "phuong",
        "slug": "tan-thoi-hiep",
        "name_with_type": "Phường Tân Thới Hiệp",
        "path": "Tân Thới Hiệp, 12, Hồ Chí Minh",
        "path_with_type": "Phường Tân Thới Hiệp, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26782",
        "parent_code": "761"
    },
    "26785": {
        "name": "Trung Mỹ Tây",
        "type": "phuong",
        "slug": "trung-my-tay",
        "name_with_type": "Phường Trung Mỹ Tây",
        "path": "Trung Mỹ Tây, 12, Hồ Chí Minh",
        "path_with_type": "Phường Trung Mỹ Tây, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26785",
        "parent_code": "761"
    },
    "26787": {
        "name": "Tân Hưng Thuận",
        "type": "phuong",
        "slug": "tan-hung-thuan",
        "name_with_type": "Phường Tân Hưng Thuận",
        "path": "Tân Hưng Thuận, 12, Hồ Chí Minh",
        "path_with_type": "Phường Tân Hưng Thuận, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26787",
        "parent_code": "761"
    },
    "26788": {
        "name": "Đông Hưng Thuận",
        "type": "phuong",
        "slug": "dong-hung-thuan",
        "name_with_type": "Phường Đông Hưng Thuận",
        "path": "Đông Hưng Thuận, 12, Hồ Chí Minh",
        "path_with_type": "Phường Đông Hưng Thuận, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26788",
        "parent_code": "761"
    },
    "26791": {
        "name": "Tân Thới Nhất",
        "type": "phuong",
        "slug": "tan-thoi-nhat",
        "name_with_type": "Phường Tân Thới Nhất",
        "path": "Tân Thới Nhất, 12, Hồ Chí Minh",
        "path_with_type": "Phường Tân Thới Nhất, Quận 12, Thành phố Hồ Chí Minh",
        "code": "26791",
        "parent_code": "761"
    },
    "26794": {
        "name": "Linh Xuân",
        "type": "phuong",
        "slug": "linh-xuan",
        "name_with_type": "Phường Linh Xuân",
        "path": "Linh Xuân, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Linh Xuân, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26794",
        "parent_code": "762"
    },
    "26797": {
        "name": "Bình Chiểu",
        "type": "phuong",
        "slug": "binh-chieu",
        "name_with_type": "Phường Bình Chiểu",
        "path": "Bình Chiểu, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Bình Chiểu, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26797",
        "parent_code": "762"
    },
    "26800": {
        "name": "Linh Trung",
        "type": "phuong",
        "slug": "linh-trung",
        "name_with_type": "Phường Linh Trung",
        "path": "Linh Trung, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Linh Trung, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26800",
        "parent_code": "762"
    },
    "26803": {
        "name": "Tam Bình",
        "type": "phuong",
        "slug": "tam-binh",
        "name_with_type": "Phường Tam Bình",
        "path": "Tam Bình, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Tam Bình, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26803",
        "parent_code": "762"
    },
    "26806": {
        "name": "Tam Phú",
        "type": "phuong",
        "slug": "tam-phu",
        "name_with_type": "Phường Tam Phú",
        "path": "Tam Phú, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Tam Phú, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26806",
        "parent_code": "762"
    },
    "26809": {
        "name": "Hiệp Bình Phước",
        "type": "phuong",
        "slug": "hiep-binh-phuoc",
        "name_with_type": "Phường Hiệp Bình Phước",
        "path": "Hiệp Bình Phước, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Hiệp Bình Phước, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26809",
        "parent_code": "762"
    },
    "26812": {
        "name": "Hiệp Bình Chánh",
        "type": "phuong",
        "slug": "hiep-binh-chanh",
        "name_with_type": "Phường Hiệp Bình Chánh",
        "path": "Hiệp Bình Chánh, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Hiệp Bình Chánh, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26812",
        "parent_code": "762"
    },
    "26815": {
        "name": "Linh Chiểu",
        "type": "phuong",
        "slug": "linh-chieu",
        "name_with_type": "Phường Linh Chiểu",
        "path": "Linh Chiểu, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Linh Chiểu, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26815",
        "parent_code": "762"
    },
    "26818": {
        "name": "Linh Tây",
        "type": "phuong",
        "slug": "linh-tay",
        "name_with_type": "Phường Linh Tây",
        "path": "Linh Tây, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Linh Tây, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26818",
        "parent_code": "762"
    },
    "26821": {
        "name": "Linh Đông",
        "type": "phuong",
        "slug": "linh-dong",
        "name_with_type": "Phường Linh Đông",
        "path": "Linh Đông, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Linh Đông, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26821",
        "parent_code": "762"
    },
    "26824": {
        "name": "Bình Thọ",
        "type": "phuong",
        "slug": "binh-tho",
        "name_with_type": "Phường Bình Thọ",
        "path": "Bình Thọ, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Bình Thọ, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26824",
        "parent_code": "762"
    },
    "26827": {
        "name": "Trường Thọ",
        "type": "phuong",
        "slug": "truong-tho",
        "name_with_type": "Phường Trường Thọ",
        "path": "Trường Thọ, Thủ Đức, Hồ Chí Minh",
        "path_with_type": "Phường Trường Thọ, Quận Thủ Đức, Thành phố Hồ Chí Minh",
        "code": "26827",
        "parent_code": "762"
    },
    "26830": {
        "name": "Long Bình",
        "type": "phuong",
        "slug": "long-binh",
        "name_with_type": "Phường Long Bình",
        "path": "Long Bình, 9, Hồ Chí Minh",
        "path_with_type": "Phường Long Bình, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26830",
        "parent_code": "763"
    },
    "26833": {
        "name": "Long Thạnh Mỹ",
        "type": "phuong",
        "slug": "long-thanh-my",
        "name_with_type": "Phường Long Thạnh Mỹ",
        "path": "Long Thạnh Mỹ, 9, Hồ Chí Minh",
        "path_with_type": "Phường Long Thạnh Mỹ, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26833",
        "parent_code": "763"
    },
    "26836": {
        "name": "Tân Phú",
        "type": "phuong",
        "slug": "tan-phu",
        "name_with_type": "Phường Tân Phú",
        "path": "Tân Phú, 9, Hồ Chí Minh",
        "path_with_type": "Phường Tân Phú, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26836",
        "parent_code": "763"
    },
    "26839": {
        "name": "Hiệp Phú",
        "type": "phuong",
        "slug": "hiep-phu",
        "name_with_type": "Phường Hiệp Phú",
        "path": "Hiệp Phú, 9, Hồ Chí Minh",
        "path_with_type": "Phường Hiệp Phú, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26839",
        "parent_code": "763"
    },
    "26842": {
        "name": "Tăng Nhơn Phú A",
        "type": "phuong",
        "slug": "tang-nhon-phu-a",
        "name_with_type": "Phường Tăng Nhơn Phú A",
        "path": "Tăng Nhơn Phú A, 9, Hồ Chí Minh",
        "path_with_type": "Phường Tăng Nhơn Phú A, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26842",
        "parent_code": "763"
    },
    "26845": {
        "name": "Tăng Nhơn Phú B",
        "type": "phuong",
        "slug": "tang-nhon-phu-b",
        "name_with_type": "Phường Tăng Nhơn Phú B",
        "path": "Tăng Nhơn Phú B, 9, Hồ Chí Minh",
        "path_with_type": "Phường Tăng Nhơn Phú B, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26845",
        "parent_code": "763"
    },
    "26848": {
        "name": "Phước Long B",
        "type": "phuong",
        "slug": "phuoc-long-b",
        "name_with_type": "Phường Phước Long B",
        "path": "Phước Long B, 9, Hồ Chí Minh",
        "path_with_type": "Phường Phước Long B, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26848",
        "parent_code": "763"
    },
    "26851": {
        "name": "Phước Long A",
        "type": "phuong",
        "slug": "phuoc-long-a",
        "name_with_type": "Phường Phước Long A",
        "path": "Phước Long A, 9, Hồ Chí Minh",
        "path_with_type": "Phường Phước Long A, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26851",
        "parent_code": "763"
    },
    "26854": {
        "name": "Trường Thạnh",
        "type": "phuong",
        "slug": "truong-thanh",
        "name_with_type": "Phường Trường Thạnh",
        "path": "Trường Thạnh, 9, Hồ Chí Minh",
        "path_with_type": "Phường Trường Thạnh, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26854",
        "parent_code": "763"
    },
    "26857": {
        "name": "Long Phước",
        "type": "phuong",
        "slug": "long-phuoc",
        "name_with_type": "Phường Long Phước",
        "path": "Long Phước, 9, Hồ Chí Minh",
        "path_with_type": "Phường Long Phước, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26857",
        "parent_code": "763"
    },
    "26860": {
        "name": "Long Trường",
        "type": "phuong",
        "slug": "long-truong",
        "name_with_type": "Phường Long Trường",
        "path": "Long Trường, 9, Hồ Chí Minh",
        "path_with_type": "Phường Long Trường, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26860",
        "parent_code": "763"
    },
    "26863": {
        "name": "Phước Bình",
        "type": "phuong",
        "slug": "phuoc-binh",
        "name_with_type": "Phường Phước Bình",
        "path": "Phước Bình, 9, Hồ Chí Minh",
        "path_with_type": "Phường Phước Bình, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26863",
        "parent_code": "763"
    },
    "26866": {
        "name": "Phú Hữu",
        "type": "phuong",
        "slug": "phu-huu",
        "name_with_type": "Phường Phú Hữu",
        "path": "Phú Hữu, 9, Hồ Chí Minh",
        "path_with_type": "Phường Phú Hữu, Quận 9, Thành phố Hồ Chí Minh",
        "code": "26866",
        "parent_code": "763"
    },
    "26869": {
        "name": "15",
        "type": "phuong",
        "slug": "15",
        "name_with_type": "Phường 15",
        "path": "15, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 15, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26869",
        "parent_code": "764"
    },
    "26872": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26872",
        "parent_code": "764"
    },
    "26875": {
        "name": "17",
        "type": "phuong",
        "slug": "17",
        "name_with_type": "Phường 17",
        "path": "17, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 17, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26875",
        "parent_code": "764"
    },
    "26876": {
        "name": "6",
        "type": "phuong",
        "slug": "6",
        "name_with_type": "Phường 6",
        "path": "6, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 6, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26876",
        "parent_code": "764"
    },
    "26878": {
        "name": "16",
        "type": "phuong",
        "slug": "16",
        "name_with_type": "Phường 16",
        "path": "16, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 16, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26878",
        "parent_code": "764"
    },
    "26881": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26881",
        "parent_code": "764"
    },
    "26882": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26882",
        "parent_code": "764"
    },
    "26884": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26884",
        "parent_code": "764"
    },
    "26887": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26887",
        "parent_code": "764"
    },
    "26890": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26890",
        "parent_code": "764"
    },
    "26893": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26893",
        "parent_code": "764"
    },
    "26896": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26896",
        "parent_code": "764"
    },
    "26897": {
        "name": "9",
        "type": "phuong",
        "slug": "9",
        "name_with_type": "Phường 9",
        "path": "9, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 9, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26897",
        "parent_code": "764"
    },
    "26898": {
        "name": "8",
        "type": "phuong",
        "slug": "8",
        "name_with_type": "Phường 8",
        "path": "8, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 8, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26898",
        "parent_code": "764"
    },
    "26899": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26899",
        "parent_code": "764"
    },
    "26902": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, Gò Vấp, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận Gò Vấp, Thành phố Hồ Chí Minh",
        "code": "26902",
        "parent_code": "764"
    },
    "26905": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26905",
        "parent_code": "765"
    },
    "26908": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26908",
        "parent_code": "765"
    },
    "26911": {
        "name": "27",
        "type": "phuong",
        "slug": "27",
        "name_with_type": "Phường 27",
        "path": "27, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 27, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26911",
        "parent_code": "765"
    },
    "26914": {
        "name": "26",
        "type": "phuong",
        "slug": "26",
        "name_with_type": "Phường 26",
        "path": "26, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 26, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26914",
        "parent_code": "765"
    },
    "26917": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26917",
        "parent_code": "765"
    },
    "26920": {
        "name": "25",
        "type": "phuong",
        "slug": "25",
        "name_with_type": "Phường 25",
        "path": "25, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 25, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26920",
        "parent_code": "765"
    },
    "26923": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26923",
        "parent_code": "765"
    },
    "26926": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26926",
        "parent_code": "765"
    },
    "26929": {
        "name": "24",
        "type": "phuong",
        "slug": "24",
        "name_with_type": "Phường 24",
        "path": "24, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 24, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26929",
        "parent_code": "765"
    },
    "26932": {
        "name": "06",
        "type": "phuong",
        "slug": "06",
        "name_with_type": "Phường 06",
        "path": "06, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 06, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26932",
        "parent_code": "765"
    },
    "26935": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26935",
        "parent_code": "765"
    },
    "26938": {
        "name": "15",
        "type": "phuong",
        "slug": "15",
        "name_with_type": "Phường 15",
        "path": "15, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 15, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26938",
        "parent_code": "765"
    },
    "26941": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26941",
        "parent_code": "765"
    },
    "26944": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26944",
        "parent_code": "765"
    },
    "26947": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26947",
        "parent_code": "765"
    },
    "26950": {
        "name": "17",
        "type": "phuong",
        "slug": "17",
        "name_with_type": "Phường 17",
        "path": "17, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 17, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26950",
        "parent_code": "765"
    },
    "26953": {
        "name": "21",
        "type": "phuong",
        "slug": "21",
        "name_with_type": "Phường 21",
        "path": "21, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 21, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26953",
        "parent_code": "765"
    },
    "26956": {
        "name": "22",
        "type": "phuong",
        "slug": "22",
        "name_with_type": "Phường 22",
        "path": "22, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 22, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26956",
        "parent_code": "765"
    },
    "26959": {
        "name": "19",
        "type": "phuong",
        "slug": "19",
        "name_with_type": "Phường 19",
        "path": "19, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 19, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26959",
        "parent_code": "765"
    },
    "26962": {
        "name": "28",
        "type": "phuong",
        "slug": "28",
        "name_with_type": "Phường 28",
        "path": "28, Bình Thạnh, Hồ Chí Minh",
        "path_with_type": "Phường 28, Quận Bình Thạnh, Thành phố Hồ Chí Minh",
        "code": "26962",
        "parent_code": "765"
    },
    "26965": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26965",
        "parent_code": "766"
    },
    "26968": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26968",
        "parent_code": "766"
    },
    "26971": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26971",
        "parent_code": "766"
    },
    "26974": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26974",
        "parent_code": "766"
    },
    "26977": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26977",
        "parent_code": "766"
    },
    "26980": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26980",
        "parent_code": "766"
    },
    "26983": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26983",
        "parent_code": "766"
    },
    "26986": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26986",
        "parent_code": "766"
    },
    "26989": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26989",
        "parent_code": "766"
    },
    "26992": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26992",
        "parent_code": "766"
    },
    "26995": {
        "name": "06",
        "type": "phuong",
        "slug": "06",
        "name_with_type": "Phường 06",
        "path": "06, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 06, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26995",
        "parent_code": "766"
    },
    "26998": {
        "name": "08",
        "type": "phuong",
        "slug": "08",
        "name_with_type": "Phường 08",
        "path": "08, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 08, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "26998",
        "parent_code": "766"
    },
    "27001": {
        "name": "09",
        "type": "phuong",
        "slug": "09",
        "name_with_type": "Phường 09",
        "path": "09, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 09, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "27001",
        "parent_code": "766"
    },
    "27004": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "27004",
        "parent_code": "766"
    },
    "27007": {
        "name": "15",
        "type": "phuong",
        "slug": "15",
        "name_with_type": "Phường 15",
        "path": "15, Tân Bình, Hồ Chí Minh",
        "path_with_type": "Phường 15, Quận Tân Bình, Thành phố Hồ Chí Minh",
        "code": "27007",
        "parent_code": "766"
    },
    "27010": {
        "name": "Tân Sơn Nhì",
        "type": "phuong",
        "slug": "tan-son-nhi",
        "name_with_type": "Phường Tân Sơn Nhì",
        "path": "Tân Sơn Nhì, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Tân Sơn Nhì, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27010",
        "parent_code": "767"
    },
    "27013": {
        "name": "Tây Thạnh",
        "type": "phuong",
        "slug": "tay-thanh",
        "name_with_type": "Phường Tây Thạnh",
        "path": "Tây Thạnh, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Tây Thạnh, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27013",
        "parent_code": "767"
    },
    "27016": {
        "name": "Sơn Kỳ",
        "type": "phuong",
        "slug": "son-ky",
        "name_with_type": "Phường Sơn Kỳ",
        "path": "Sơn Kỳ, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Sơn Kỳ, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27016",
        "parent_code": "767"
    },
    "27019": {
        "name": "Tân Quý",
        "type": "phuong",
        "slug": "tan-quy",
        "name_with_type": "Phường Tân Quý",
        "path": "Tân Quý, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Tân Quý, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27019",
        "parent_code": "767"
    },
    "27022": {
        "name": "Tân Thành",
        "type": "phuong",
        "slug": "tan-thanh",
        "name_with_type": "Phường Tân Thành",
        "path": "Tân Thành, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Tân Thành, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27022",
        "parent_code": "767"
    },
    "27025": {
        "name": "Phú Thọ Hòa",
        "type": "phuong",
        "slug": "phu-tho-hoa",
        "name_with_type": "Phường Phú Thọ Hòa",
        "path": "Phú Thọ Hòa, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Phú Thọ Hòa, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27025",
        "parent_code": "767"
    },
    "27028": {
        "name": "Phú Thạnh",
        "type": "phuong",
        "slug": "phu-thanh",
        "name_with_type": "Phường Phú Thạnh",
        "path": "Phú Thạnh, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Phú Thạnh, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27028",
        "parent_code": "767"
    },
    "27031": {
        "name": "Phú Trung",
        "type": "phuong",
        "slug": "phu-trung",
        "name_with_type": "Phường Phú Trung",
        "path": "Phú Trung, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Phú Trung, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27031",
        "parent_code": "767"
    },
    "27034": {
        "name": "Hòa Thạnh",
        "type": "phuong",
        "slug": "hoa-thanh",
        "name_with_type": "Phường Hòa Thạnh",
        "path": "Hòa Thạnh, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Hòa Thạnh, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27034",
        "parent_code": "767"
    },
    "27037": {
        "name": "Hiệp Tân",
        "type": "phuong",
        "slug": "hiep-tan",
        "name_with_type": "Phường Hiệp Tân",
        "path": "Hiệp Tân, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Hiệp Tân, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27037",
        "parent_code": "767"
    },
    "27040": {
        "name": "Tân Thới Hòa",
        "type": "phuong",
        "slug": "tan-thoi-hoa",
        "name_with_type": "Phường Tân Thới Hòa",
        "path": "Tân Thới Hòa, Tân Phú, Hồ Chí Minh",
        "path_with_type": "Phường Tân Thới Hòa, Quận Tân Phú, Thành phố Hồ Chí Minh",
        "code": "27040",
        "parent_code": "767"
    },
    "27043": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27043",
        "parent_code": "768"
    },
    "27046": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27046",
        "parent_code": "768"
    },
    "27049": {
        "name": "09",
        "type": "phuong",
        "slug": "09",
        "name_with_type": "Phường 09",
        "path": "09, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 09, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27049",
        "parent_code": "768"
    },
    "27052": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27052",
        "parent_code": "768"
    },
    "27055": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27055",
        "parent_code": "768"
    },
    "27058": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27058",
        "parent_code": "768"
    },
    "27061": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27061",
        "parent_code": "768"
    },
    "27064": {
        "name": "08",
        "type": "phuong",
        "slug": "08",
        "name_with_type": "Phường 08",
        "path": "08, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 08, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27064",
        "parent_code": "768"
    },
    "27067": {
        "name": "15",
        "type": "phuong",
        "slug": "15",
        "name_with_type": "Phường 15",
        "path": "15, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 15, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27067",
        "parent_code": "768"
    },
    "27070": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27070",
        "parent_code": "768"
    },
    "27073": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27073",
        "parent_code": "768"
    },
    "27076": {
        "name": "17",
        "type": "phuong",
        "slug": "17",
        "name_with_type": "Phường 17",
        "path": "17, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 17, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27076",
        "parent_code": "768"
    },
    "27079": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27079",
        "parent_code": "768"
    },
    "27082": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27082",
        "parent_code": "768"
    },
    "27085": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, Phú Nhuận, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận Phú Nhuận, Thành phố Hồ Chí Minh",
        "code": "27085",
        "parent_code": "768"
    },
    "27088": {
        "name": "Thảo Điền",
        "type": "phuong",
        "slug": "thao-dien",
        "name_with_type": "Phường Thảo Điền",
        "path": "Thảo Điền, 2, Hồ Chí Minh",
        "path_with_type": "Phường Thảo Điền, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27088",
        "parent_code": "769"
    },
    "27091": {
        "name": "An Phú",
        "type": "phuong",
        "slug": "an-phu",
        "name_with_type": "Phường An Phú",
        "path": "An Phú, 2, Hồ Chí Minh",
        "path_with_type": "Phường An Phú, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27091",
        "parent_code": "769"
    },
    "27094": {
        "name": "Bình An",
        "type": "phuong",
        "slug": "binh-an",
        "name_with_type": "Phường Bình An",
        "path": "Bình An, 2, Hồ Chí Minh",
        "path_with_type": "Phường Bình An, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27094",
        "parent_code": "769"
    },
    "27097": {
        "name": "Bình Trưng Đông",
        "type": "phuong",
        "slug": "binh-trung-dong",
        "name_with_type": "Phường Bình Trưng Đông",
        "path": "Bình Trưng Đông, 2, Hồ Chí Minh",
        "path_with_type": "Phường Bình Trưng Đông, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27097",
        "parent_code": "769"
    },
    "27100": {
        "name": "Bình Trưng Tây",
        "type": "phuong",
        "slug": "binh-trung-tay",
        "name_with_type": "Phường Bình Trưng Tây",
        "path": "Bình Trưng Tây, 2, Hồ Chí Minh",
        "path_with_type": "Phường Bình Trưng Tây, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27100",
        "parent_code": "769"
    },
    "27103": {
        "name": "Bình Khánh",
        "type": "phuong",
        "slug": "binh-khanh",
        "name_with_type": "Phường Bình Khánh",
        "path": "Bình Khánh, 2, Hồ Chí Minh",
        "path_with_type": "Phường Bình Khánh, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27103",
        "parent_code": "769"
    },
    "27106": {
        "name": "An Khánh",
        "type": "phuong",
        "slug": "an-khanh",
        "name_with_type": "Phường An Khánh",
        "path": "An Khánh, 2, Hồ Chí Minh",
        "path_with_type": "Phường An Khánh, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27106",
        "parent_code": "769"
    },
    "27109": {
        "name": "Cát Lái",
        "type": "phuong",
        "slug": "cat-lai",
        "name_with_type": "Phường Cát Lái",
        "path": "Cát Lái, 2, Hồ Chí Minh",
        "path_with_type": "Phường Cát Lái, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27109",
        "parent_code": "769"
    },
    "27112": {
        "name": "Thạnh Mỹ Lợi",
        "type": "phuong",
        "slug": "thanh-my-loi",
        "name_with_type": "Phường Thạnh Mỹ Lợi",
        "path": "Thạnh Mỹ Lợi, 2, Hồ Chí Minh",
        "path_with_type": "Phường Thạnh Mỹ Lợi, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27112",
        "parent_code": "769"
    },
    "27115": {
        "name": "An Lợi Đông",
        "type": "phuong",
        "slug": "an-loi-dong",
        "name_with_type": "Phường An Lợi Đông",
        "path": "An Lợi Đông, 2, Hồ Chí Minh",
        "path_with_type": "Phường An Lợi Đông, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27115",
        "parent_code": "769"
    },
    "27118": {
        "name": "Thủ Thiêm",
        "type": "phuong",
        "slug": "thu-thiem",
        "name_with_type": "Phường Thủ Thiêm",
        "path": "Thủ Thiêm, 2, Hồ Chí Minh",
        "path_with_type": "Phường Thủ Thiêm, Quận 2, Thành phố Hồ Chí Minh",
        "code": "27118",
        "parent_code": "769"
    },
    "27121": {
        "name": "08",
        "type": "phuong",
        "slug": "08",
        "name_with_type": "Phường 08",
        "path": "08, 3, Hồ Chí Minh",
        "path_with_type": "Phường 08, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27121",
        "parent_code": "770"
    },
    "27124": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, 3, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27124",
        "parent_code": "770"
    },
    "27127": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, 3, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27127",
        "parent_code": "770"
    },
    "27130": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, 3, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27130",
        "parent_code": "770"
    },
    "27133": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, 3, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27133",
        "parent_code": "770"
    },
    "27136": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, 3, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27136",
        "parent_code": "770"
    },
    "27139": {
        "name": "06",
        "type": "phuong",
        "slug": "06",
        "name_with_type": "Phường 06",
        "path": "06, 3, Hồ Chí Minh",
        "path_with_type": "Phường 06, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27139",
        "parent_code": "770"
    },
    "27142": {
        "name": "09",
        "type": "phuong",
        "slug": "09",
        "name_with_type": "Phường 09",
        "path": "09, 3, Hồ Chí Minh",
        "path_with_type": "Phường 09, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27142",
        "parent_code": "770"
    },
    "27145": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, 3, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27145",
        "parent_code": "770"
    },
    "27148": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, 3, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27148",
        "parent_code": "770"
    },
    "27151": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, 3, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27151",
        "parent_code": "770"
    },
    "27154": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, 3, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27154",
        "parent_code": "770"
    },
    "27157": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, 3, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27157",
        "parent_code": "770"
    },
    "27160": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, 3, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận 3, Thành phố Hồ Chí Minh",
        "code": "27160",
        "parent_code": "770"
    },
    "27163": {
        "name": "15",
        "type": "phuong",
        "slug": "15",
        "name_with_type": "Phường 15",
        "path": "15, 10, Hồ Chí Minh",
        "path_with_type": "Phường 15, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27163",
        "parent_code": "771"
    },
    "27166": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, 10, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27166",
        "parent_code": "771"
    },
    "27169": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, 10, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27169",
        "parent_code": "771"
    },
    "27172": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, 10, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27172",
        "parent_code": "771"
    },
    "27175": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, 10, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27175",
        "parent_code": "771"
    },
    "27178": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, 10, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27178",
        "parent_code": "771"
    },
    "27181": {
        "name": "09",
        "type": "phuong",
        "slug": "09",
        "name_with_type": "Phường 09",
        "path": "09, 10, Hồ Chí Minh",
        "path_with_type": "Phường 09, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27181",
        "parent_code": "771"
    },
    "27184": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, 10, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27184",
        "parent_code": "771"
    },
    "27187": {
        "name": "08",
        "type": "phuong",
        "slug": "08",
        "name_with_type": "Phường 08",
        "path": "08, 10, Hồ Chí Minh",
        "path_with_type": "Phường 08, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27187",
        "parent_code": "771"
    },
    "27190": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, 10, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27190",
        "parent_code": "771"
    },
    "27193": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, 10, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27193",
        "parent_code": "771"
    },
    "27196": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, 10, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27196",
        "parent_code": "771"
    },
    "27199": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, 10, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27199",
        "parent_code": "771"
    },
    "27202": {
        "name": "06",
        "type": "phuong",
        "slug": "06",
        "name_with_type": "Phường 06",
        "path": "06, 10, Hồ Chí Minh",
        "path_with_type": "Phường 06, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27202",
        "parent_code": "771"
    },
    "27205": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, 10, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận 10, Thành phố Hồ Chí Minh",
        "code": "27205",
        "parent_code": "771"
    },
    "27208": {
        "name": "15",
        "type": "phuong",
        "slug": "15",
        "name_with_type": "Phường 15",
        "path": "15, 11, Hồ Chí Minh",
        "path_with_type": "Phường 15, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27208",
        "parent_code": "772"
    },
    "27211": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, 11, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27211",
        "parent_code": "772"
    },
    "27214": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, 11, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27214",
        "parent_code": "772"
    },
    "27217": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, 11, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27217",
        "parent_code": "772"
    },
    "27220": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, 11, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27220",
        "parent_code": "772"
    },
    "27223": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, 11, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27223",
        "parent_code": "772"
    },
    "27226": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, 11, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27226",
        "parent_code": "772"
    },
    "27229": {
        "name": "08",
        "type": "phuong",
        "slug": "08",
        "name_with_type": "Phường 08",
        "path": "08, 11, Hồ Chí Minh",
        "path_with_type": "Phường 08, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27229",
        "parent_code": "772"
    },
    "27232": {
        "name": "09",
        "type": "phuong",
        "slug": "09",
        "name_with_type": "Phường 09",
        "path": "09, 11, Hồ Chí Minh",
        "path_with_type": "Phường 09, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27232",
        "parent_code": "772"
    },
    "27235": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, 11, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27235",
        "parent_code": "772"
    },
    "27238": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, 11, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27238",
        "parent_code": "772"
    },
    "27241": {
        "name": "06",
        "type": "phuong",
        "slug": "06",
        "name_with_type": "Phường 06",
        "path": "06, 11, Hồ Chí Minh",
        "path_with_type": "Phường 06, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27241",
        "parent_code": "772"
    },
    "27244": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, 11, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27244",
        "parent_code": "772"
    },
    "27247": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, 11, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27247",
        "parent_code": "772"
    },
    "27250": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, 11, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27250",
        "parent_code": "772"
    },
    "27253": {
        "name": "16",
        "type": "phuong",
        "slug": "16",
        "name_with_type": "Phường 16",
        "path": "16, 11, Hồ Chí Minh",
        "path_with_type": "Phường 16, Quận 11, Thành phố Hồ Chí Minh",
        "code": "27253",
        "parent_code": "772"
    },
    "27256": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, 4, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27256",
        "parent_code": "773"
    },
    "27259": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, 4, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27259",
        "parent_code": "773"
    },
    "27262": {
        "name": "09",
        "type": "phuong",
        "slug": "09",
        "name_with_type": "Phường 09",
        "path": "09, 4, Hồ Chí Minh",
        "path_with_type": "Phường 09, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27262",
        "parent_code": "773"
    },
    "27265": {
        "name": "06",
        "type": "phuong",
        "slug": "06",
        "name_with_type": "Phường 06",
        "path": "06, 4, Hồ Chí Minh",
        "path_with_type": "Phường 06, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27265",
        "parent_code": "773"
    },
    "27268": {
        "name": "08",
        "type": "phuong",
        "slug": "08",
        "name_with_type": "Phường 08",
        "path": "08, 4, Hồ Chí Minh",
        "path_with_type": "Phường 08, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27268",
        "parent_code": "773"
    },
    "27271": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, 4, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27271",
        "parent_code": "773"
    },
    "27274": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, 4, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27274",
        "parent_code": "773"
    },
    "27277": {
        "name": "18",
        "type": "phuong",
        "slug": "18",
        "name_with_type": "Phường 18",
        "path": "18, 4, Hồ Chí Minh",
        "path_with_type": "Phường 18, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27277",
        "parent_code": "773"
    },
    "27280": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, 4, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27280",
        "parent_code": "773"
    },
    "27283": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, 4, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27283",
        "parent_code": "773"
    },
    "27286": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, 4, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27286",
        "parent_code": "773"
    },
    "27289": {
        "name": "16",
        "type": "phuong",
        "slug": "16",
        "name_with_type": "Phường 16",
        "path": "16, 4, Hồ Chí Minh",
        "path_with_type": "Phường 16, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27289",
        "parent_code": "773"
    },
    "27292": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, 4, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27292",
        "parent_code": "773"
    },
    "27295": {
        "name": "15",
        "type": "phuong",
        "slug": "15",
        "name_with_type": "Phường 15",
        "path": "15, 4, Hồ Chí Minh",
        "path_with_type": "Phường 15, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27295",
        "parent_code": "773"
    },
    "27298": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, 4, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận 4, Thành phố Hồ Chí Minh",
        "code": "27298",
        "parent_code": "773"
    },
    "27301": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, 5, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27301",
        "parent_code": "774"
    },
    "27304": {
        "name": "09",
        "type": "phuong",
        "slug": "09",
        "name_with_type": "Phường 09",
        "path": "09, 5, Hồ Chí Minh",
        "path_with_type": "Phường 09, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27304",
        "parent_code": "774"
    },
    "27307": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, 5, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27307",
        "parent_code": "774"
    },
    "27310": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, 5, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27310",
        "parent_code": "774"
    },
    "27313": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, 5, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27313",
        "parent_code": "774"
    },
    "27316": {
        "name": "08",
        "type": "phuong",
        "slug": "08",
        "name_with_type": "Phường 08",
        "path": "08, 5, Hồ Chí Minh",
        "path_with_type": "Phường 08, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27316",
        "parent_code": "774"
    },
    "27319": {
        "name": "15",
        "type": "phuong",
        "slug": "15",
        "name_with_type": "Phường 15",
        "path": "15, 5, Hồ Chí Minh",
        "path_with_type": "Phường 15, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27319",
        "parent_code": "774"
    },
    "27322": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, 5, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27322",
        "parent_code": "774"
    },
    "27325": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, 5, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27325",
        "parent_code": "774"
    },
    "27328": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, 5, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27328",
        "parent_code": "774"
    },
    "27331": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, 5, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27331",
        "parent_code": "774"
    },
    "27334": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, 5, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27334",
        "parent_code": "774"
    },
    "27337": {
        "name": "06",
        "type": "phuong",
        "slug": "06",
        "name_with_type": "Phường 06",
        "path": "06, 5, Hồ Chí Minh",
        "path_with_type": "Phường 06, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27337",
        "parent_code": "774"
    },
    "27340": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, 5, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27340",
        "parent_code": "774"
    },
    "27343": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, 5, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận 5, Thành phố Hồ Chí Minh",
        "code": "27343",
        "parent_code": "774"
    },
    "27346": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, 6, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27346",
        "parent_code": "775"
    },
    "27349": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, 6, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27349",
        "parent_code": "775"
    },
    "27352": {
        "name": "09",
        "type": "phuong",
        "slug": "09",
        "name_with_type": "Phường 09",
        "path": "09, 6, Hồ Chí Minh",
        "path_with_type": "Phường 09, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27352",
        "parent_code": "775"
    },
    "27355": {
        "name": "06",
        "type": "phuong",
        "slug": "06",
        "name_with_type": "Phường 06",
        "path": "06, 6, Hồ Chí Minh",
        "path_with_type": "Phường 06, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27355",
        "parent_code": "775"
    },
    "27358": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, 6, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27358",
        "parent_code": "775"
    },
    "27361": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, 6, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27361",
        "parent_code": "775"
    },
    "27364": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, 6, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27364",
        "parent_code": "775"
    },
    "27367": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, 6, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27367",
        "parent_code": "775"
    },
    "27370": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, 6, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27370",
        "parent_code": "775"
    },
    "27373": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, 6, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27373",
        "parent_code": "775"
    },
    "27376": {
        "name": "08",
        "type": "phuong",
        "slug": "08",
        "name_with_type": "Phường 08",
        "path": "08, 6, Hồ Chí Minh",
        "path_with_type": "Phường 08, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27376",
        "parent_code": "775"
    },
    "27379": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, 6, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27379",
        "parent_code": "775"
    },
    "27382": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, 6, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27382",
        "parent_code": "775"
    },
    "27385": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, 6, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận 6, Thành phố Hồ Chí Minh",
        "code": "27385",
        "parent_code": "775"
    },
    "27388": {
        "name": "08",
        "type": "phuong",
        "slug": "08",
        "name_with_type": "Phường 08",
        "path": "08, 8, Hồ Chí Minh",
        "path_with_type": "Phường 08, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27388",
        "parent_code": "776"
    },
    "27391": {
        "name": "02",
        "type": "phuong",
        "slug": "02",
        "name_with_type": "Phường 02",
        "path": "02, 8, Hồ Chí Minh",
        "path_with_type": "Phường 02, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27391",
        "parent_code": "776"
    },
    "27394": {
        "name": "01",
        "type": "phuong",
        "slug": "01",
        "name_with_type": "Phường 01",
        "path": "01, 8, Hồ Chí Minh",
        "path_with_type": "Phường 01, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27394",
        "parent_code": "776"
    },
    "27397": {
        "name": "03",
        "type": "phuong",
        "slug": "03",
        "name_with_type": "Phường 03",
        "path": "03, 8, Hồ Chí Minh",
        "path_with_type": "Phường 03, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27397",
        "parent_code": "776"
    },
    "27400": {
        "name": "11",
        "type": "phuong",
        "slug": "11",
        "name_with_type": "Phường 11",
        "path": "11, 8, Hồ Chí Minh",
        "path_with_type": "Phường 11, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27400",
        "parent_code": "776"
    },
    "27403": {
        "name": "09",
        "type": "phuong",
        "slug": "09",
        "name_with_type": "Phường 09",
        "path": "09, 8, Hồ Chí Minh",
        "path_with_type": "Phường 09, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27403",
        "parent_code": "776"
    },
    "27406": {
        "name": "10",
        "type": "phuong",
        "slug": "10",
        "name_with_type": "Phường 10",
        "path": "10, 8, Hồ Chí Minh",
        "path_with_type": "Phường 10, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27406",
        "parent_code": "776"
    },
    "27409": {
        "name": "04",
        "type": "phuong",
        "slug": "04",
        "name_with_type": "Phường 04",
        "path": "04, 8, Hồ Chí Minh",
        "path_with_type": "Phường 04, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27409",
        "parent_code": "776"
    },
    "27412": {
        "name": "13",
        "type": "phuong",
        "slug": "13",
        "name_with_type": "Phường 13",
        "path": "13, 8, Hồ Chí Minh",
        "path_with_type": "Phường 13, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27412",
        "parent_code": "776"
    },
    "27415": {
        "name": "12",
        "type": "phuong",
        "slug": "12",
        "name_with_type": "Phường 12",
        "path": "12, 8, Hồ Chí Minh",
        "path_with_type": "Phường 12, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27415",
        "parent_code": "776"
    },
    "27418": {
        "name": "05",
        "type": "phuong",
        "slug": "05",
        "name_with_type": "Phường 05",
        "path": "05, 8, Hồ Chí Minh",
        "path_with_type": "Phường 05, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27418",
        "parent_code": "776"
    },
    "27421": {
        "name": "14",
        "type": "phuong",
        "slug": "14",
        "name_with_type": "Phường 14",
        "path": "14, 8, Hồ Chí Minh",
        "path_with_type": "Phường 14, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27421",
        "parent_code": "776"
    },
    "27424": {
        "name": "06",
        "type": "phuong",
        "slug": "06",
        "name_with_type": "Phường 06",
        "path": "06, 8, Hồ Chí Minh",
        "path_with_type": "Phường 06, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27424",
        "parent_code": "776"
    },
    "27427": {
        "name": "15",
        "type": "phuong",
        "slug": "15",
        "name_with_type": "Phường 15",
        "path": "15, 8, Hồ Chí Minh",
        "path_with_type": "Phường 15, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27427",
        "parent_code": "776"
    },
    "27430": {
        "name": "16",
        "type": "phuong",
        "slug": "16",
        "name_with_type": "Phường 16",
        "path": "16, 8, Hồ Chí Minh",
        "path_with_type": "Phường 16, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27430",
        "parent_code": "776"
    },
    "27433": {
        "name": "07",
        "type": "phuong",
        "slug": "07",
        "name_with_type": "Phường 07",
        "path": "07, 8, Hồ Chí Minh",
        "path_with_type": "Phường 07, Quận 8, Thành phố Hồ Chí Minh",
        "code": "27433",
        "parent_code": "776"
    },
    "27436": {
        "name": "Bình Hưng Hòa",
        "type": "phuong",
        "slug": "binh-hung-hoa",
        "name_with_type": "Phường Bình Hưng Hòa",
        "path": "Bình Hưng Hòa, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường Bình Hưng Hòa, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27436",
        "parent_code": "777"
    },
    "27439": {
        "name": "Bình Hưng Hoà A",
        "type": "phuong",
        "slug": "binh-hung-hoa-a",
        "name_with_type": "Phường Bình Hưng Hoà A",
        "path": "Bình Hưng Hoà A, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường Bình Hưng Hoà A, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27439",
        "parent_code": "777"
    },
    "27442": {
        "name": "Bình Hưng Hoà B",
        "type": "phuong",
        "slug": "binh-hung-hoa-b",
        "name_with_type": "Phường Bình Hưng Hoà B",
        "path": "Bình Hưng Hoà B, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường Bình Hưng Hoà B, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27442",
        "parent_code": "777"
    },
    "27445": {
        "name": "Bình Trị Đông",
        "type": "phuong",
        "slug": "binh-tri-dong",
        "name_with_type": "Phường Bình Trị Đông",
        "path": "Bình Trị Đông, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường Bình Trị Đông, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27445",
        "parent_code": "777"
    },
    "27448": {
        "name": "Bình Trị Đông A",
        "type": "phuong",
        "slug": "binh-tri-dong-a",
        "name_with_type": "Phường Bình Trị Đông A",
        "path": "Bình Trị Đông A, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường Bình Trị Đông A, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27448",
        "parent_code": "777"
    },
    "27451": {
        "name": "Bình Trị Đông B",
        "type": "phuong",
        "slug": "binh-tri-dong-b",
        "name_with_type": "Phường Bình Trị Đông B",
        "path": "Bình Trị Đông B, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường Bình Trị Đông B, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27451",
        "parent_code": "777"
    },
    "27454": {
        "name": "Tân Tạo",
        "type": "phuong",
        "slug": "tan-tao",
        "name_with_type": "Phường Tân Tạo",
        "path": "Tân Tạo, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường Tân Tạo, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27454",
        "parent_code": "777"
    },
    "27457": {
        "name": "Tân Tạo A",
        "type": "phuong",
        "slug": "tan-tao-a",
        "name_with_type": "Phường Tân Tạo A",
        "path": "Tân Tạo A, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường Tân Tạo A, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27457",
        "parent_code": "777"
    },
    "27460": {
        "name": "An Lạc",
        "type": "phuong",
        "slug": "an-lac",
        "name_with_type": "Phường  An Lạc",
        "path": "An Lạc, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường  An Lạc, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27460",
        "parent_code": "777"
    },
    "27463": {
        "name": "An Lạc A",
        "type": "phuong",
        "slug": "an-lac-a",
        "name_with_type": "Phường An Lạc A",
        "path": "An Lạc A, Bình Tân, Hồ Chí Minh",
        "path_with_type": "Phường An Lạc A, Quận Bình Tân, Thành phố Hồ Chí Minh",
        "code": "27463",
        "parent_code": "777"
    },
    "27466": {
        "name": "Tân Thuận Đông",
        "type": "phuong",
        "slug": "tan-thuan-dong",
        "name_with_type": "Phường Tân Thuận Đông",
        "path": "Tân Thuận Đông, 7, Hồ Chí Minh",
        "path_with_type": "Phường Tân Thuận Đông, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27466",
        "parent_code": "778"
    },
    "27469": {
        "name": "Tân Thuận Tây",
        "type": "phuong",
        "slug": "tan-thuan-tay",
        "name_with_type": "Phường Tân Thuận Tây",
        "path": "Tân Thuận Tây, 7, Hồ Chí Minh",
        "path_with_type": "Phường Tân Thuận Tây, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27469",
        "parent_code": "778"
    },
    "27472": {
        "name": "Tân Kiểng",
        "type": "phuong",
        "slug": "tan-kieng",
        "name_with_type": "Phường Tân Kiểng",
        "path": "Tân Kiểng, 7, Hồ Chí Minh",
        "path_with_type": "Phường Tân Kiểng, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27472",
        "parent_code": "778"
    },
    "27475": {
        "name": "Tân Hưng",
        "type": "phuong",
        "slug": "tan-hung",
        "name_with_type": "Phường Tân Hưng",
        "path": "Tân Hưng, 7, Hồ Chí Minh",
        "path_with_type": "Phường Tân Hưng, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27475",
        "parent_code": "778"
    },
    "27478": {
        "name": "Bình Thuận",
        "type": "phuong",
        "slug": "binh-thuan",
        "name_with_type": "Phường Bình Thuận",
        "path": "Bình Thuận, 7, Hồ Chí Minh",
        "path_with_type": "Phường Bình Thuận, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27478",
        "parent_code": "778"
    },
    "27481": {
        "name": "Tân Quy",
        "type": "phuong",
        "slug": "tan-quy",
        "name_with_type": "Phường Tân Quy",
        "path": "Tân Quy, 7, Hồ Chí Minh",
        "path_with_type": "Phường Tân Quy, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27481",
        "parent_code": "778"
    },
    "27484": {
        "name": "Phú Thuận",
        "type": "phuong",
        "slug": "phu-thuan",
        "name_with_type": "Phường Phú Thuận",
        "path": "Phú Thuận, 7, Hồ Chí Minh",
        "path_with_type": "Phường Phú Thuận, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27484",
        "parent_code": "778"
    },
    "27487": {
        "name": "Tân Phú",
        "type": "phuong",
        "slug": "tan-phu",
        "name_with_type": "Phường Tân Phú",
        "path": "Tân Phú, 7, Hồ Chí Minh",
        "path_with_type": "Phường Tân Phú, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27487",
        "parent_code": "778"
    },
    "27490": {
        "name": "Tân Phong",
        "type": "phuong",
        "slug": "tan-phong",
        "name_with_type": "Phường Tân Phong",
        "path": "Tân Phong, 7, Hồ Chí Minh",
        "path_with_type": "Phường Tân Phong, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27490",
        "parent_code": "778"
    },
    "27493": {
        "name": "Phú Mỹ",
        "type": "phuong",
        "slug": "phu-my",
        "name_with_type": "Phường Phú Mỹ",
        "path": "Phú Mỹ, 7, Hồ Chí Minh",
        "path_with_type": "Phường Phú Mỹ, Quận 7, Thành phố Hồ Chí Minh",
        "code": "27493",
        "parent_code": "778"
    },
    "27496": {
        "name": "Củ Chi",
        "type": "thi-tran",
        "slug": "cu-chi",
        "name_with_type": "Thị trấn Củ Chi",
        "path": "Củ Chi, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Thị trấn Củ Chi, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27496",
        "parent_code": "783"
    },
    "27499": {
        "name": "Phú Mỹ Hưng",
        "type": "xa",
        "slug": "phu-my-hung",
        "name_with_type": "Xã Phú Mỹ Hưng",
        "path": "Phú Mỹ Hưng, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Phú Mỹ Hưng, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27499",
        "parent_code": "783"
    },
    "27502": {
        "name": "An Phú",
        "type": "xa",
        "slug": "an-phu",
        "name_with_type": "Xã An Phú",
        "path": "An Phú, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã An Phú, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27502",
        "parent_code": "783"
    },
    "27505": {
        "name": "Trung Lập Thượng",
        "type": "xa",
        "slug": "trung-lap-thuong",
        "name_with_type": "Xã Trung Lập Thượng",
        "path": "Trung Lập Thượng, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Trung Lập Thượng, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27505",
        "parent_code": "783"
    },
    "27508": {
        "name": "An Nhơn Tây",
        "type": "xa",
        "slug": "an-nhon-tay",
        "name_with_type": "Xã An Nhơn Tây",
        "path": "An Nhơn Tây, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã An Nhơn Tây, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27508",
        "parent_code": "783"
    },
    "27511": {
        "name": "Nhuận Đức",
        "type": "xa",
        "slug": "nhuan-duc",
        "name_with_type": "Xã Nhuận Đức",
        "path": "Nhuận Đức, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Nhuận Đức, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27511",
        "parent_code": "783"
    },
    "27514": {
        "name": "Phạm Văn Cội",
        "type": "xa",
        "slug": "pham-van-coi",
        "name_with_type": "Xã Phạm Văn Cội",
        "path": "Phạm Văn Cội, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Phạm Văn Cội, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27514",
        "parent_code": "783"
    },
    "27517": {
        "name": "Phú Hòa Đông",
        "type": "xa",
        "slug": "phu-hoa-dong",
        "name_with_type": "Xã Phú Hòa Đông",
        "path": "Phú Hòa Đông, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Phú Hòa Đông, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27517",
        "parent_code": "783"
    },
    "27520": {
        "name": "Trung Lập Hạ",
        "type": "xa",
        "slug": "trung-lap-ha",
        "name_with_type": "Xã Trung Lập Hạ",
        "path": "Trung Lập Hạ, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Trung Lập Hạ, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27520",
        "parent_code": "783"
    },
    "27523": {
        "name": "Trung An",
        "type": "xa",
        "slug": "trung-an",
        "name_with_type": "Xã Trung An",
        "path": "Trung An, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Trung An, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27523",
        "parent_code": "783"
    },
    "27526": {
        "name": "Phước Thạnh",
        "type": "xa",
        "slug": "phuoc-thanh",
        "name_with_type": "Xã Phước Thạnh",
        "path": "Phước Thạnh, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Phước Thạnh, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27526",
        "parent_code": "783"
    },
    "27529": {
        "name": "Phước Hiệp",
        "type": "xa",
        "slug": "phuoc-hiep",
        "name_with_type": "Xã Phước Hiệp",
        "path": "Phước Hiệp, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Phước Hiệp, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27529",
        "parent_code": "783"
    },
    "27532": {
        "name": "Tân An Hội",
        "type": "xa",
        "slug": "tan-an-hoi",
        "name_with_type": "Xã Tân An Hội",
        "path": "Tân An Hội, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Tân An Hội, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27532",
        "parent_code": "783"
    },
    "27535": {
        "name": "Phước Vĩnh An",
        "type": "xa",
        "slug": "phuoc-vinh-an",
        "name_with_type": "Xã Phước Vĩnh An",
        "path": "Phước Vĩnh An, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Phước Vĩnh An, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27535",
        "parent_code": "783"
    },
    "27538": {
        "name": "Thái Mỹ",
        "type": "xa",
        "slug": "thai-my",
        "name_with_type": "Xã Thái Mỹ",
        "path": "Thái Mỹ, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Thái Mỹ, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27538",
        "parent_code": "783"
    },
    "27541": {
        "name": "Tân Thạnh Tây",
        "type": "xa",
        "slug": "tan-thanh-tay",
        "name_with_type": "Xã Tân Thạnh Tây",
        "path": "Tân Thạnh Tây, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Tân Thạnh Tây, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27541",
        "parent_code": "783"
    },
    "27544": {
        "name": "Hòa Phú",
        "type": "xa",
        "slug": "hoa-phu",
        "name_with_type": "Xã Hòa Phú",
        "path": "Hòa Phú, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Hòa Phú, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27544",
        "parent_code": "783"
    },
    "27547": {
        "name": "Tân Thạnh Đông",
        "type": "xa",
        "slug": "tan-thanh-dong",
        "name_with_type": "Xã Tân Thạnh Đông",
        "path": "Tân Thạnh Đông, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Tân Thạnh Đông, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27547",
        "parent_code": "783"
    },
    "27550": {
        "name": "Bình Mỹ",
        "type": "xa",
        "slug": "binh-my",
        "name_with_type": "Xã Bình Mỹ",
        "path": "Bình Mỹ, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Bình Mỹ, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27550",
        "parent_code": "783"
    },
    "27553": {
        "name": "Tân Phú Trung",
        "type": "xa",
        "slug": "tan-phu-trung",
        "name_with_type": "Xã Tân Phú Trung",
        "path": "Tân Phú Trung, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Tân Phú Trung, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27553",
        "parent_code": "783"
    },
    "27556": {
        "name": "Tân Thông Hội",
        "type": "xa",
        "slug": "tan-thong-hoi",
        "name_with_type": "Xã Tân Thông Hội",
        "path": "Tân Thông Hội, Củ Chi, Hồ Chí Minh",
        "path_with_type": "Xã Tân Thông Hội, Huyện Củ Chi, Thành phố Hồ Chí Minh",
        "code": "27556",
        "parent_code": "783"
    },
    "27559": {
        "name": "Hóc Môn",
        "type": "thi-tran",
        "slug": "hoc-mon",
        "name_with_type": "Thị trấn Hóc Môn",
        "path": "Hóc Môn, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Thị trấn Hóc Môn, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27559",
        "parent_code": "784"
    },
    "27562": {
        "name": "Tân Hiệp",
        "type": "xa",
        "slug": "tan-hiep",
        "name_with_type": "Xã Tân Hiệp",
        "path": "Tân Hiệp, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Tân Hiệp, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27562",
        "parent_code": "784"
    },
    "27565": {
        "name": "Nhị Bình",
        "type": "xa",
        "slug": "nhi-binh",
        "name_with_type": "Xã Nhị Bình",
        "path": "Nhị Bình, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Nhị Bình, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27565",
        "parent_code": "784"
    },
    "27568": {
        "name": "Đông Thạnh",
        "type": "xa",
        "slug": "dong-thanh",
        "name_with_type": "Xã Đông Thạnh",
        "path": "Đông Thạnh, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Đông Thạnh, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27568",
        "parent_code": "784"
    },
    "27571": {
        "name": "Tân Thới Nhì",
        "type": "xa",
        "slug": "tan-thoi-nhi",
        "name_with_type": "Xã Tân Thới Nhì",
        "path": "Tân Thới Nhì, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Tân Thới Nhì, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27571",
        "parent_code": "784"
    },
    "27574": {
        "name": "Thới Tam Thôn",
        "type": "xa",
        "slug": "thoi-tam-thon",
        "name_with_type": "Xã Thới Tam Thôn",
        "path": "Thới Tam Thôn, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Thới Tam Thôn, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27574",
        "parent_code": "784"
    },
    "27577": {
        "name": "Xuân Thới Sơn",
        "type": "xa",
        "slug": "xuan-thoi-son",
        "name_with_type": "Xã Xuân Thới Sơn",
        "path": "Xuân Thới Sơn, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Xuân Thới Sơn, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27577",
        "parent_code": "784"
    },
    "27580": {
        "name": "Tân Xuân",
        "type": "xa",
        "slug": "tan-xuan",
        "name_with_type": "Xã Tân Xuân",
        "path": "Tân Xuân, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Tân Xuân, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27580",
        "parent_code": "784"
    },
    "27583": {
        "name": "Xuân Thới Đông",
        "type": "xa",
        "slug": "xuan-thoi-dong",
        "name_with_type": "Xã Xuân Thới Đông",
        "path": "Xuân Thới Đông, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Xuân Thới Đông, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27583",
        "parent_code": "784"
    },
    "27586": {
        "name": "Trung Chánh",
        "type": "xa",
        "slug": "trung-chanh",
        "name_with_type": "Xã Trung Chánh",
        "path": "Trung Chánh, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Trung Chánh, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27586",
        "parent_code": "784"
    },
    "27589": {
        "name": "Xuân Thới Thượng",
        "type": "xa",
        "slug": "xuan-thoi-thuong",
        "name_with_type": "Xã Xuân Thới Thượng",
        "path": "Xuân Thới Thượng, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Xuân Thới Thượng, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27589",
        "parent_code": "784"
    },
    "27592": {
        "name": "Bà Điểm",
        "type": "xa",
        "slug": "ba-diem",
        "name_with_type": "Xã Bà Điểm",
        "path": "Bà Điểm, Hóc Môn, Hồ Chí Minh",
        "path_with_type": "Xã Bà Điểm, Huyện Hóc Môn, Thành phố Hồ Chí Minh",
        "code": "27592",
        "parent_code": "784"
    },
    "27595": {
        "name": "Tân Túc",
        "type": "thi-tran",
        "slug": "tan-tuc",
        "name_with_type": "Thị trấn Tân Túc",
        "path": "Tân Túc, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Thị trấn Tân Túc, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27595",
        "parent_code": "785"
    },
    "27598": {
        "name": "Phạm Văn Hai",
        "type": "xa",
        "slug": "pham-van-hai",
        "name_with_type": "Xã Phạm Văn Hai",
        "path": "Phạm Văn Hai, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Phạm Văn Hai, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27598",
        "parent_code": "785"
    },
    "27601": {
        "name": "Vĩnh Lộc A",
        "type": "xa",
        "slug": "vinh-loc-a",
        "name_with_type": "Xã Vĩnh Lộc A",
        "path": "Vĩnh Lộc A, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Vĩnh Lộc A, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27601",
        "parent_code": "785"
    },
    "27604": {
        "name": "Vĩnh Lộc B",
        "type": "xa",
        "slug": "vinh-loc-b",
        "name_with_type": "Xã Vĩnh Lộc B",
        "path": "Vĩnh Lộc B, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Vĩnh Lộc B, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27604",
        "parent_code": "785"
    },
    "27607": {
        "name": "Bình Lợi",
        "type": "xa",
        "slug": "binh-loi",
        "name_with_type": "Xã Bình Lợi",
        "path": "Bình Lợi, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Bình Lợi, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27607",
        "parent_code": "785"
    },
    "27610": {
        "name": "Lê Minh Xuân",
        "type": "xa",
        "slug": "le-minh-xuan",
        "name_with_type": "Xã Lê Minh Xuân",
        "path": "Lê Minh Xuân, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Lê Minh Xuân, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27610",
        "parent_code": "785"
    },
    "27613": {
        "name": "Tân Nhựt",
        "type": "xa",
        "slug": "tan-nhut",
        "name_with_type": "Xã Tân Nhựt",
        "path": "Tân Nhựt, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Tân Nhựt, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27613",
        "parent_code": "785"
    },
    "27616": {
        "name": "Tân Kiên",
        "type": "xa",
        "slug": "tan-kien",
        "name_with_type": "Xã Tân Kiên",
        "path": "Tân Kiên, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Tân Kiên, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27616",
        "parent_code": "785"
    },
    "27619": {
        "name": "Bình Hưng",
        "type": "xa",
        "slug": "binh-hung",
        "name_with_type": "Xã Bình Hưng",
        "path": "Bình Hưng, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Bình Hưng, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27619",
        "parent_code": "785"
    },
    "27622": {
        "name": "Phong Phú",
        "type": "xa",
        "slug": "phong-phu",
        "name_with_type": "Xã Phong Phú",
        "path": "Phong Phú, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Phong Phú, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27622",
        "parent_code": "785"
    },
    "27625": {
        "name": "An Phú Tây",
        "type": "xa",
        "slug": "an-phu-tay",
        "name_with_type": "Xã An Phú Tây",
        "path": "An Phú Tây, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã An Phú Tây, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27625",
        "parent_code": "785"
    },
    "27628": {
        "name": "Hưng Long",
        "type": "xa",
        "slug": "hung-long",
        "name_with_type": "Xã Hưng Long",
        "path": "Hưng Long, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Hưng Long, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27628",
        "parent_code": "785"
    },
    "27631": {
        "name": "Đa Phước",
        "type": "xa",
        "slug": "da-phuoc",
        "name_with_type": "Xã Đa Phước",
        "path": "Đa Phước, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Đa Phước, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27631",
        "parent_code": "785"
    },
    "27634": {
        "name": "Tân Quý Tây",
        "type": "xa",
        "slug": "tan-quy-tay",
        "name_with_type": "Xã Tân Quý Tây",
        "path": "Tân Quý Tây, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Tân Quý Tây, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27634",
        "parent_code": "785"
    },
    "27637": {
        "name": "Bình Chánh",
        "type": "xa",
        "slug": "binh-chanh",
        "name_with_type": "Xã Bình Chánh",
        "path": "Bình Chánh, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Bình Chánh, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27637",
        "parent_code": "785"
    },
    "27640": {
        "name": "Quy Đức",
        "type": "xa",
        "slug": "quy-duc",
        "name_with_type": "Xã Quy Đức",
        "path": "Quy Đức, Bình Chánh, Hồ Chí Minh",
        "path_with_type": "Xã Quy Đức, Huyện Bình Chánh, Thành phố Hồ Chí Minh",
        "code": "27640",
        "parent_code": "785"
    },
    "27643": {
        "name": "Nhà Bè",
        "type": "thi-tran",
        "slug": "nha-be",
        "name_with_type": "Thị trấn Nhà Bè",
        "path": "Nhà Bè, Nhà Bè, Hồ Chí Minh",
        "path_with_type": "Thị trấn Nhà Bè, Huyện Nhà Bè, Thành phố Hồ Chí Minh",
        "code": "27643",
        "parent_code": "786"
    },
    "27646": {
        "name": "Phước Kiển",
        "type": "xa",
        "slug": "phuoc-kien",
        "name_with_type": "Xã Phước Kiển",
        "path": "Phước Kiển, Nhà Bè, Hồ Chí Minh",
        "path_with_type": "Xã Phước Kiển, Huyện Nhà Bè, Thành phố Hồ Chí Minh",
        "code": "27646",
        "parent_code": "786"
    },
    "27649": {
        "name": "Phước Lộc",
        "type": "xa",
        "slug": "phuoc-loc",
        "name_with_type": "Xã Phước Lộc",
        "path": "Phước Lộc, Nhà Bè, Hồ Chí Minh",
        "path_with_type": "Xã Phước Lộc, Huyện Nhà Bè, Thành phố Hồ Chí Minh",
        "code": "27649",
        "parent_code": "786"
    },
    "27652": {
        "name": "Nhơn Đức",
        "type": "xa",
        "slug": "nhon-duc",
        "name_with_type": "Xã Nhơn Đức",
        "path": "Nhơn Đức, Nhà Bè, Hồ Chí Minh",
        "path_with_type": "Xã Nhơn Đức, Huyện Nhà Bè, Thành phố Hồ Chí Minh",
        "code": "27652",
        "parent_code": "786"
    },
    "27655": {
        "name": "Phú Xuân",
        "type": "xa",
        "slug": "phu-xuan",
        "name_with_type": "Xã Phú Xuân",
        "path": "Phú Xuân, Nhà Bè, Hồ Chí Minh",
        "path_with_type": "Xã Phú Xuân, Huyện Nhà Bè, Thành phố Hồ Chí Minh",
        "code": "27655",
        "parent_code": "786"
    },
    "27658": {
        "name": "Long Thới",
        "type": "xa",
        "slug": "long-thoi",
        "name_with_type": "Xã Long Thới",
        "path": "Long Thới, Nhà Bè, Hồ Chí Minh",
        "path_with_type": "Xã Long Thới, Huyện Nhà Bè, Thành phố Hồ Chí Minh",
        "code": "27658",
        "parent_code": "786"
    },
    "27661": {
        "name": "Hiệp Phước",
        "type": "xa",
        "slug": "hiep-phuoc",
        "name_with_type": "Xã Hiệp Phước",
        "path": "Hiệp Phước, Nhà Bè, Hồ Chí Minh",
        "path_with_type": "Xã Hiệp Phước, Huyện Nhà Bè, Thành phố Hồ Chí Minh",
        "code": "27661",
        "parent_code": "786"
    },
    "27664": {
        "name": "Cần Thạnh",
        "type": "thi-tran",
        "slug": "can-thanh",
        "name_with_type": "Thị trấn Cần Thạnh",
        "path": "Cần Thạnh, Cần Giờ, Hồ Chí Minh",
        "path_with_type": "Thị trấn Cần Thạnh, Huyện Cần Giờ, Thành phố Hồ Chí Minh",
        "code": "27664",
        "parent_code": "787"
    },
    "27667": {
        "name": "Bình Khánh",
        "type": "xa",
        "slug": "binh-khanh",
        "name_with_type": "Xã Bình Khánh",
        "path": "Bình Khánh, Cần Giờ, Hồ Chí Minh",
        "path_with_type": "Xã Bình Khánh, Huyện Cần Giờ, Thành phố Hồ Chí Minh",
        "code": "27667",
        "parent_code": "787"
    },
    "27670": {
        "name": "Tam Thôn Hiệp",
        "type": "xa",
        "slug": "tam-thon-hiep",
        "name_with_type": "Xã Tam Thôn Hiệp",
        "path": "Tam Thôn Hiệp, Cần Giờ, Hồ Chí Minh",
        "path_with_type": "Xã Tam Thôn Hiệp, Huyện Cần Giờ, Thành phố Hồ Chí Minh",
        "code": "27670",
        "parent_code": "787"
    },
    "27673": {
        "name": "An Thới Đông",
        "type": "xa",
        "slug": "an-thoi-dong",
        "name_with_type": "Xã An Thới Đông",
        "path": "An Thới Đông, Cần Giờ, Hồ Chí Minh",
        "path_with_type": "Xã An Thới Đông, Huyện Cần Giờ, Thành phố Hồ Chí Minh",
        "code": "27673",
        "parent_code": "787"
    },
    "27676": {
        "name": "Thạnh An",
        "type": "xa",
        "slug": "thanh-an",
        "name_with_type": "Xã Thạnh An",
        "path": "Thạnh An, Cần Giờ, Hồ Chí Minh",
        "path_with_type": "Xã Thạnh An, Huyện Cần Giờ, Thành phố Hồ Chí Minh",
        "code": "27676",
        "parent_code": "787"
    },
    "27679": {
        "name": "Long Hòa",
        "type": "xa",
        "slug": "long-hoa",
        "name_with_type": "Xã Long Hòa",
        "path": "Long Hòa, Cần Giờ, Hồ Chí Minh",
        "path_with_type": "Xã Long Hòa, Huyện Cần Giờ, Thành phố Hồ Chí Minh",
        "code": "27679",
        "parent_code": "787"
    },
    "27682": {
        "name": "Lý Nhơn",
        "type": "xa",
        "slug": "ly-nhon",
        "name_with_type": "Xã Lý Nhơn",
        "path": "Lý Nhơn, Cần Giờ, Hồ Chí Minh",
        "path_with_type": "Xã Lý Nhơn, Huyện Cần Giờ, Thành phố Hồ Chí Minh",
        "code": "27682",
        "parent_code": "787"
    },
    "13285": {
        "name": "Quang Trung",
        "type": "phuong",
        "slug": "quang-trung",
        "name_with_type": "Phường Quang Trung",
        "path": "Quang Trung, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Quang Trung, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13285",
        "parent_code": "347"
    },
    "13288": {
        "name": "Lương Khánh Thiện",
        "type": "phuong",
        "slug": "luong-khanh-thien",
        "name_with_type": "Phường Lương Khánh Thiện",
        "path": "Lương Khánh Thiện, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Lương Khánh Thiện, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13288",
        "parent_code": "347"
    },
    "13291": {
        "name": "Lê Hồng Phong",
        "type": "phuong",
        "slug": "le-hong-phong",
        "name_with_type": "Phường Lê Hồng Phong",
        "path": "Lê Hồng Phong, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Lê Hồng Phong, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13291",
        "parent_code": "347"
    },
    "13294": {
        "name": "Minh Khai",
        "type": "phuong",
        "slug": "minh-khai",
        "name_with_type": "Phường Minh Khai",
        "path": "Minh Khai, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Minh Khai, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13294",
        "parent_code": "347"
    },
    "13297": {
        "name": "Hai Bà Trưng",
        "type": "phuong",
        "slug": "hai-ba-trung",
        "name_with_type": "Phường Hai Bà Trưng",
        "path": "Hai Bà Trưng, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Hai Bà Trưng, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13297",
        "parent_code": "347"
    },
    "13300": {
        "name": "Trần Hưng Đạo",
        "type": "phuong",
        "slug": "tran-hung-dao",
        "name_with_type": "Phường Trần Hưng Đạo",
        "path": "Trần Hưng Đạo, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Trần Hưng Đạo, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13300",
        "parent_code": "347"
    },
    "13303": {
        "name": "Lam Hà",
        "type": "phuong",
        "slug": "lam-ha",
        "name_with_type": "Phường Lam Hà",
        "path": "Lam Hà, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Lam Hà, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13303",
        "parent_code": "347"
    },
    "13306": {
        "name": "Phù Vân",
        "type": "xa",
        "slug": "phu-van",
        "name_with_type": "Xã Phù Vân",
        "path": "Phù Vân, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Phù Vân, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13306",
        "parent_code": "347"
    },
    "13309": {
        "name": "Liêm Chính",
        "type": "phuong",
        "slug": "liem-chinh",
        "name_with_type": "Phường Liêm Chính",
        "path": "Liêm Chính, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Liêm Chính, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13309",
        "parent_code": "347"
    },
    "13312": {
        "name": "Liêm Chung",
        "type": "xa",
        "slug": "liem-chung",
        "name_with_type": "Xã Liêm Chung",
        "path": "Liêm Chung, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Liêm Chung, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13312",
        "parent_code": "347"
    },
    "13315": {
        "name": "Thanh Châu",
        "type": "phuong",
        "slug": "thanh-chau",
        "name_with_type": "Phường Thanh Châu",
        "path": "Thanh Châu, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Thanh Châu, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13315",
        "parent_code": "347"
    },
    "13318": {
        "name": "Châu Sơn",
        "type": "phuong",
        "slug": "chau-son",
        "name_with_type": "Phường Châu Sơn",
        "path": "Châu Sơn, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Châu Sơn, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13318",
        "parent_code": "347"
    },
    "13366": {
        "name": "Tiên Tân",
        "type": "xa",
        "slug": "tien-tan",
        "name_with_type": "Xã Tiên Tân",
        "path": "Tiên Tân, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Tiên Tân, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13366",
        "parent_code": "347"
    },
    "13372": {
        "name": "Tiên Hiệp",
        "type": "xa",
        "slug": "tien-hiep",
        "name_with_type": "Xã Tiên Hiệp",
        "path": "Tiên Hiệp, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Tiên Hiệp, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13372",
        "parent_code": "347"
    },
    "13381": {
        "name": "Tiên Hải",
        "type": "xa",
        "slug": "tien-hai",
        "name_with_type": "Xã Tiên Hải",
        "path": "Tiên Hải, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Tiên Hải, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13381",
        "parent_code": "347"
    },
    "13426": {
        "name": "Kim Bình",
        "type": "xa",
        "slug": "kim-binh",
        "name_with_type": "Xã Kim Bình",
        "path": "Kim Bình, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Kim Bình, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13426",
        "parent_code": "347"
    },
    "13444": {
        "name": "Liêm Tuyền",
        "type": "xa",
        "slug": "liem-tuyen",
        "name_with_type": "Xã Liêm Tuyền",
        "path": "Liêm Tuyền, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Liêm Tuyền, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13444",
        "parent_code": "347"
    },
    "13447": {
        "name": "Liêm Tiết",
        "type": "xa",
        "slug": "liem-tiet",
        "name_with_type": "Xã Liêm Tiết",
        "path": "Liêm Tiết, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Liêm Tiết, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13447",
        "parent_code": "347"
    },
    "13459": {
        "name": "Thanh Tuyền",
        "type": "phuong",
        "slug": "thanh-tuyen",
        "name_with_type": "Phường Thanh Tuyền",
        "path": "Thanh Tuyền, Phủ Lý, Hà Nam",
        "path_with_type": "Phường Thanh Tuyền, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13459",
        "parent_code": "347"
    },
    "13507": {
        "name": "Đinh Xá",
        "type": "xa",
        "slug": "dinh-xa",
        "name_with_type": "Xã Đinh Xá",
        "path": "Đinh Xá, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Đinh Xá, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13507",
        "parent_code": "347"
    },
    "13513": {
        "name": "Trịnh Xá",
        "type": "xa",
        "slug": "trinh-xa",
        "name_with_type": "Xã Trịnh Xá",
        "path": "Trịnh Xá, Phủ Lý, Hà Nam",
        "path_with_type": "Xã Trịnh Xá, Thành phố Phủ Lý, Tỉnh Hà Nam",
        "code": "13513",
        "parent_code": "347"
    },
    "13321": {
        "name": "Đồng Văn",
        "type": "thi-tran",
        "slug": "dong-van",
        "name_with_type": "Thị trấn Đồng Văn",
        "path": "Đồng Văn, Duy Tiên, Hà Nam",
        "path_with_type": "Thị trấn Đồng Văn, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13321",
        "parent_code": "349"
    },
    "13324": {
        "name": "Hòa Mạc",
        "type": "thi-tran",
        "slug": "hoa-mac",
        "name_with_type": "Thị trấn Hòa Mạc",
        "path": "Hòa Mạc, Duy Tiên, Hà Nam",
        "path_with_type": "Thị trấn Hòa Mạc, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13324",
        "parent_code": "349"
    },
    "13327": {
        "name": "Mộc Bắc",
        "type": "xa",
        "slug": "moc-bac",
        "name_with_type": "Xã Mộc Bắc",
        "path": "Mộc Bắc, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Mộc Bắc, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13327",
        "parent_code": "349"
    },
    "13330": {
        "name": "Châu Giang",
        "type": "xa",
        "slug": "chau-giang",
        "name_with_type": "Xã Châu Giang",
        "path": "Châu Giang, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Châu Giang, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13330",
        "parent_code": "349"
    },
    "13333": {
        "name": "Bạch Thượng",
        "type": "xa",
        "slug": "bach-thuong",
        "name_with_type": "Xã Bạch Thượng",
        "path": "Bạch Thượng, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Bạch Thượng, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13333",
        "parent_code": "349"
    },
    "13336": {
        "name": "Duy Minh",
        "type": "xa",
        "slug": "duy-minh",
        "name_with_type": "Xã Duy Minh",
        "path": "Duy Minh, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Duy Minh, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13336",
        "parent_code": "349"
    },
    "13339": {
        "name": "Mộc Nam",
        "type": "xa",
        "slug": "moc-nam",
        "name_with_type": "Xã Mộc Nam",
        "path": "Mộc Nam, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Mộc Nam, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13339",
        "parent_code": "349"
    },
    "13342": {
        "name": "Duy Hải",
        "type": "xa",
        "slug": "duy-hai",
        "name_with_type": "Xã Duy Hải",
        "path": "Duy Hải, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Duy Hải, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13342",
        "parent_code": "349"
    },
    "13345": {
        "name": "Chuyên Ngoại",
        "type": "xa",
        "slug": "chuyen-ngoai",
        "name_with_type": "Xã Chuyên Ngoại",
        "path": "Chuyên Ngoại, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Chuyên Ngoại, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13345",
        "parent_code": "349"
    },
    "13348": {
        "name": "Yên Bắc",
        "type": "xa",
        "slug": "yen-bac",
        "name_with_type": "Xã Yên Bắc",
        "path": "Yên Bắc, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Yên Bắc, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13348",
        "parent_code": "349"
    },
    "13351": {
        "name": "Trác Văn",
        "type": "xa",
        "slug": "trac-van",
        "name_with_type": "Xã Trác Văn",
        "path": "Trác Văn, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Trác Văn, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13351",
        "parent_code": "349"
    },
    "13354": {
        "name": "Tiên Nội",
        "type": "xa",
        "slug": "tien-noi",
        "name_with_type": "Xã Tiên Nội",
        "path": "Tiên Nội, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Tiên Nội, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13354",
        "parent_code": "349"
    },
    "13357": {
        "name": "Hoàng Đông",
        "type": "xa",
        "slug": "hoang-dong",
        "name_with_type": "Xã Hoàng Đông",
        "path": "Hoàng Đông, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Hoàng Đông, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13357",
        "parent_code": "349"
    },
    "13360": {
        "name": "Yên Nam",
        "type": "xa",
        "slug": "yen-nam",
        "name_with_type": "Xã Yên Nam",
        "path": "Yên Nam, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Yên Nam, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13360",
        "parent_code": "349"
    },
    "13363": {
        "name": "Tiên Ngoại",
        "type": "xa",
        "slug": "tien-ngoai",
        "name_with_type": "Xã Tiên Ngoại",
        "path": "Tiên Ngoại, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Tiên Ngoại, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13363",
        "parent_code": "349"
    },
    "13369": {
        "name": "Đọi Sơn",
        "type": "xa",
        "slug": "doi-son",
        "name_with_type": "Xã Đọi Sơn",
        "path": "Đọi Sơn, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Đọi Sơn, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13369",
        "parent_code": "349"
    },
    "13375": {
        "name": "Châu Sơn",
        "type": "xa",
        "slug": "chau-son",
        "name_with_type": "Xã Châu Sơn",
        "path": "Châu Sơn, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Châu Sơn, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13375",
        "parent_code": "349"
    },
    "13378": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Duy Tiên, Hà Nam",
        "path_with_type": "Xã Tiền Phong, Huyện Duy Tiên, Tỉnh Hà Nam",
        "code": "13378",
        "parent_code": "349"
    },
    "13384": {
        "name": "Quế",
        "type": "thi-tran",
        "slug": "que",
        "name_with_type": "Thị trấn Quế",
        "path": "Quế, Kim Bảng, Hà Nam",
        "path_with_type": "Thị trấn Quế, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13384",
        "parent_code": "350"
    },
    "13387": {
        "name": "Nguyễn Úy",
        "type": "xa",
        "slug": "nguyen-uy",
        "name_with_type": "Xã Nguyễn Úy",
        "path": "Nguyễn Úy, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Nguyễn Úy, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13387",
        "parent_code": "350"
    },
    "13390": {
        "name": "Đại Cương",
        "type": "xa",
        "slug": "dai-cuong",
        "name_with_type": "Xã Đại Cương",
        "path": "Đại Cương, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Đại Cương, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13390",
        "parent_code": "350"
    },
    "13393": {
        "name": "Lê Hồ",
        "type": "xa",
        "slug": "le-ho",
        "name_with_type": "Xã Lê Hồ",
        "path": "Lê Hồ, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Lê Hồ, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13393",
        "parent_code": "350"
    },
    "13396": {
        "name": "Tượng Lĩnh",
        "type": "xa",
        "slug": "tuong-linh",
        "name_with_type": "Xã Tượng Lĩnh",
        "path": "Tượng Lĩnh, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Tượng Lĩnh, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13396",
        "parent_code": "350"
    },
    "13399": {
        "name": "Nhật Tựu",
        "type": "xa",
        "slug": "nhat-tuu",
        "name_with_type": "Xã Nhật Tựu",
        "path": "Nhật Tựu, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Nhật Tựu, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13399",
        "parent_code": "350"
    },
    "13402": {
        "name": "Nhật Tân",
        "type": "xa",
        "slug": "nhat-tan",
        "name_with_type": "Xã Nhật Tân",
        "path": "Nhật Tân, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Nhật Tân, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13402",
        "parent_code": "350"
    },
    "13405": {
        "name": "Đồng Hóa",
        "type": "xa",
        "slug": "dong-hoa",
        "name_with_type": "Xã Đồng Hóa",
        "path": "Đồng Hóa, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Đồng Hóa, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13405",
        "parent_code": "350"
    },
    "13408": {
        "name": "Hoàng Tây",
        "type": "xa",
        "slug": "hoang-tay",
        "name_with_type": "Xã Hoàng Tây",
        "path": "Hoàng Tây, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Hoàng Tây, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13408",
        "parent_code": "350"
    },
    "13411": {
        "name": "Tân Sơn",
        "type": "xa",
        "slug": "tan-son",
        "name_with_type": "Xã Tân Sơn",
        "path": "Tân Sơn, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Tân Sơn, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13411",
        "parent_code": "350"
    },
    "13414": {
        "name": "Thụy Lôi",
        "type": "xa",
        "slug": "thuy-loi",
        "name_with_type": "Xã Thụy Lôi",
        "path": "Thụy Lôi, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Thụy Lôi, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13414",
        "parent_code": "350"
    },
    "13417": {
        "name": "Văn Xá",
        "type": "xa",
        "slug": "van-xa",
        "name_with_type": "Xã Văn Xá",
        "path": "Văn Xá, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Văn Xá, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13417",
        "parent_code": "350"
    },
    "13420": {
        "name": "Khả Phong",
        "type": "xa",
        "slug": "kha-phong",
        "name_with_type": "Xã Khả Phong",
        "path": "Khả Phong, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Khả Phong, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13420",
        "parent_code": "350"
    },
    "13423": {
        "name": "Ngọc Sơn",
        "type": "xa",
        "slug": "ngoc-son",
        "name_with_type": "Xã Ngọc Sơn",
        "path": "Ngọc Sơn, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Ngọc Sơn, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13423",
        "parent_code": "350"
    },
    "13429": {
        "name": "Ba Sao",
        "type": "thi-tran",
        "slug": "ba-sao",
        "name_with_type": "Thị trấn Ba Sao",
        "path": "Ba Sao, Kim Bảng, Hà Nam",
        "path_with_type": "Thị trấn Ba Sao, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13429",
        "parent_code": "350"
    },
    "13432": {
        "name": "Liên Sơn",
        "type": "xa",
        "slug": "lien-son",
        "name_with_type": "Xã Liên Sơn",
        "path": "Liên Sơn, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Liên Sơn, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13432",
        "parent_code": "350"
    },
    "13435": {
        "name": "Thi Sơn",
        "type": "xa",
        "slug": "thi-son",
        "name_with_type": "Xã Thi Sơn",
        "path": "Thi Sơn, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Thi Sơn, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13435",
        "parent_code": "350"
    },
    "13438": {
        "name": "Thanh Sơn",
        "type": "xa",
        "slug": "thanh-son",
        "name_with_type": "Xã Thanh Sơn",
        "path": "Thanh Sơn, Kim Bảng, Hà Nam",
        "path_with_type": "Xã Thanh Sơn, Huyện Kim Bảng, Tỉnh Hà Nam",
        "code": "13438",
        "parent_code": "350"
    },
    "13441": {
        "name": "Kiện Khê",
        "type": "thi-tran",
        "slug": "kien-khe",
        "name_with_type": "Thị trấn Kiện Khê",
        "path": "Kiện Khê, Thanh Liêm, Hà Nam",
        "path_with_type": "Thị trấn Kiện Khê, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13441",
        "parent_code": "351"
    },
    "13450": {
        "name": "Liêm Phong",
        "type": "xa",
        "slug": "liem-phong",
        "name_with_type": "Xã Liêm Phong",
        "path": "Liêm Phong, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Liêm Phong, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13450",
        "parent_code": "351"
    },
    "13453": {
        "name": "Thanh Hà",
        "type": "xa",
        "slug": "thanh-ha",
        "name_with_type": "Xã Thanh Hà",
        "path": "Thanh Hà, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Hà, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13453",
        "parent_code": "351"
    },
    "13456": {
        "name": "Liêm Cần",
        "type": "xa",
        "slug": "liem-can",
        "name_with_type": "Xã Liêm Cần",
        "path": "Liêm Cần, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Liêm Cần, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13456",
        "parent_code": "351"
    },
    "13462": {
        "name": "Thanh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thanh Bình",
        "path": "Thanh Bình, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Bình, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13462",
        "parent_code": "351"
    },
    "13465": {
        "name": "Liêm Thuận",
        "type": "xa",
        "slug": "liem-thuan",
        "name_with_type": "Xã Liêm Thuận",
        "path": "Liêm Thuận, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Liêm Thuận, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13465",
        "parent_code": "351"
    },
    "13468": {
        "name": "Thanh Thủy",
        "type": "xa",
        "slug": "thanh-thuy",
        "name_with_type": "Xã Thanh Thủy",
        "path": "Thanh Thủy, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Thủy, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13468",
        "parent_code": "351"
    },
    "13471": {
        "name": "Thanh Phong",
        "type": "xa",
        "slug": "thanh-phong",
        "name_with_type": "Xã Thanh Phong",
        "path": "Thanh Phong, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Phong, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13471",
        "parent_code": "351"
    },
    "13474": {
        "name": "Thanh Lưu",
        "type": "xa",
        "slug": "thanh-luu",
        "name_with_type": "Xã Thanh Lưu",
        "path": "Thanh Lưu, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Lưu, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13474",
        "parent_code": "351"
    },
    "13477": {
        "name": "Thanh Tân",
        "type": "xa",
        "slug": "thanh-tan",
        "name_with_type": "Xã Thanh Tân",
        "path": "Thanh Tân, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Tân, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13477",
        "parent_code": "351"
    },
    "13480": {
        "name": "Liêm Túc",
        "type": "xa",
        "slug": "liem-tuc",
        "name_with_type": "Xã Liêm Túc",
        "path": "Liêm Túc, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Liêm Túc, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13480",
        "parent_code": "351"
    },
    "13483": {
        "name": "Liêm Sơn",
        "type": "xa",
        "slug": "liem-son",
        "name_with_type": "Xã Liêm Sơn",
        "path": "Liêm Sơn, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Liêm Sơn, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13483",
        "parent_code": "351"
    },
    "13486": {
        "name": "Thanh Hương",
        "type": "xa",
        "slug": "thanh-huong",
        "name_with_type": "Xã Thanh Hương",
        "path": "Thanh Hương, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Hương, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13486",
        "parent_code": "351"
    },
    "13489": {
        "name": "Thanh Nghị",
        "type": "xa",
        "slug": "thanh-nghi",
        "name_with_type": "Xã Thanh Nghị",
        "path": "Thanh Nghị, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Nghị, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13489",
        "parent_code": "351"
    },
    "13492": {
        "name": "Thanh Tâm",
        "type": "xa",
        "slug": "thanh-tam",
        "name_with_type": "Xã Thanh Tâm",
        "path": "Thanh Tâm, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Tâm, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13492",
        "parent_code": "351"
    },
    "13495": {
        "name": "Thanh Nguyên",
        "type": "xa",
        "slug": "thanh-nguyen",
        "name_with_type": "Xã Thanh Nguyên",
        "path": "Thanh Nguyên, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Nguyên, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13495",
        "parent_code": "351"
    },
    "13498": {
        "name": "Thanh Hải",
        "type": "xa",
        "slug": "thanh-hai",
        "name_with_type": "Xã Thanh Hải",
        "path": "Thanh Hải, Thanh Liêm, Hà Nam",
        "path_with_type": "Xã Thanh Hải, Huyện Thanh Liêm, Tỉnh Hà Nam",
        "code": "13498",
        "parent_code": "351"
    },
    "13501": {
        "name": "Bình Mỹ",
        "type": "thi-tran",
        "slug": "binh-my",
        "name_with_type": "Thị trấn Bình Mỹ",
        "path": "Bình Mỹ, Bình Lục, Hà Nam",
        "path_with_type": "Thị trấn Bình Mỹ, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13501",
        "parent_code": "352"
    },
    "13504": {
        "name": "Bình Nghĩa",
        "type": "xa",
        "slug": "binh-nghia",
        "name_with_type": "Xã Bình Nghĩa",
        "path": "Bình Nghĩa, Bình Lục, Hà Nam",
        "path_with_type": "Xã Bình Nghĩa, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13504",
        "parent_code": "352"
    },
    "13510": {
        "name": "Tràng An",
        "type": "xa",
        "slug": "trang-an",
        "name_with_type": "Xã Tràng An",
        "path": "Tràng An, Bình Lục, Hà Nam",
        "path_with_type": "Xã Tràng An, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13510",
        "parent_code": "352"
    },
    "13516": {
        "name": "Đồng Du",
        "type": "xa",
        "slug": "dong-du",
        "name_with_type": "Xã Đồng Du",
        "path": "Đồng Du, Bình Lục, Hà Nam",
        "path_with_type": "Xã Đồng Du, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13516",
        "parent_code": "352"
    },
    "13519": {
        "name": "Ngọc Lũ",
        "type": "xa",
        "slug": "ngoc-lu",
        "name_with_type": "Xã Ngọc Lũ",
        "path": "Ngọc Lũ, Bình Lục, Hà Nam",
        "path_with_type": "Xã Ngọc Lũ, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13519",
        "parent_code": "352"
    },
    "13522": {
        "name": "Hưng Công",
        "type": "xa",
        "slug": "hung-cong",
        "name_with_type": "Xã Hưng Công",
        "path": "Hưng Công, Bình Lục, Hà Nam",
        "path_with_type": "Xã Hưng Công, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13522",
        "parent_code": "352"
    },
    "13525": {
        "name": "Đồn Xá",
        "type": "xa",
        "slug": "don-xa",
        "name_with_type": "Xã Đồn Xá",
        "path": "Đồn Xá, Bình Lục, Hà Nam",
        "path_with_type": "Xã Đồn Xá, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13525",
        "parent_code": "352"
    },
    "13528": {
        "name": "An Ninh",
        "type": "xa",
        "slug": "an-ninh",
        "name_with_type": "Xã An Ninh",
        "path": "An Ninh, Bình Lục, Hà Nam",
        "path_with_type": "Xã An Ninh, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13528",
        "parent_code": "352"
    },
    "13531": {
        "name": "Bồ Đề",
        "type": "xa",
        "slug": "bo-de",
        "name_with_type": "Xã Bồ Đề",
        "path": "Bồ Đề, Bình Lục, Hà Nam",
        "path_with_type": "Xã Bồ Đề, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13531",
        "parent_code": "352"
    },
    "13534": {
        "name": "Bối Cầu",
        "type": "xa",
        "slug": "boi-cau",
        "name_with_type": "Xã Bối Cầu",
        "path": "Bối Cầu, Bình Lục, Hà Nam",
        "path_with_type": "Xã Bối Cầu, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13534",
        "parent_code": "352"
    },
    "13537": {
        "name": "An Mỹ",
        "type": "xa",
        "slug": "an-my",
        "name_with_type": "Xã An Mỹ",
        "path": "An Mỹ, Bình Lục, Hà Nam",
        "path_with_type": "Xã An Mỹ, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13537",
        "parent_code": "352"
    },
    "13540": {
        "name": "An Nội",
        "type": "xa",
        "slug": "an-noi",
        "name_with_type": "Xã An Nội",
        "path": "An Nội, Bình Lục, Hà Nam",
        "path_with_type": "Xã An Nội, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13540",
        "parent_code": "352"
    },
    "13543": {
        "name": "Vũ Bản",
        "type": "xa",
        "slug": "vu-ban",
        "name_with_type": "Xã Vũ Bản",
        "path": "Vũ Bản, Bình Lục, Hà Nam",
        "path_with_type": "Xã Vũ Bản, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13543",
        "parent_code": "352"
    },
    "13546": {
        "name": "Trung Lương",
        "type": "xa",
        "slug": "trung-luong",
        "name_with_type": "Xã Trung Lương",
        "path": "Trung Lương, Bình Lục, Hà Nam",
        "path_with_type": "Xã Trung Lương, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13546",
        "parent_code": "352"
    },
    "13549": {
        "name": "Mỹ Thọ",
        "type": "xa",
        "slug": "my-tho",
        "name_with_type": "Xã Mỹ Thọ",
        "path": "Mỹ Thọ, Bình Lục, Hà Nam",
        "path_with_type": "Xã Mỹ Thọ, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13549",
        "parent_code": "352"
    },
    "13552": {
        "name": "An Đổ",
        "type": "xa",
        "slug": "an-do",
        "name_with_type": "Xã An Đổ",
        "path": "An Đổ, Bình Lục, Hà Nam",
        "path_with_type": "Xã An Đổ, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13552",
        "parent_code": "352"
    },
    "13555": {
        "name": "La Sơn",
        "type": "xa",
        "slug": "la-son",
        "name_with_type": "Xã La Sơn",
        "path": "La Sơn, Bình Lục, Hà Nam",
        "path_with_type": "Xã La Sơn, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13555",
        "parent_code": "352"
    },
    "13558": {
        "name": "Tiêu Động",
        "type": "xa",
        "slug": "tieu-dong",
        "name_with_type": "Xã Tiêu Động",
        "path": "Tiêu Động, Bình Lục, Hà Nam",
        "path_with_type": "Xã Tiêu Động, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13558",
        "parent_code": "352"
    },
    "13561": {
        "name": "An Lão",
        "type": "xa",
        "slug": "an-lao",
        "name_with_type": "Xã An Lão",
        "path": "An Lão, Bình Lục, Hà Nam",
        "path_with_type": "Xã An Lão, Huyện Bình Lục, Tỉnh Hà Nam",
        "code": "13561",
        "parent_code": "352"
    },
    "13564": {
        "name": "Vĩnh Trụ",
        "type": "thi-tran",
        "slug": "vinh-tru",
        "name_with_type": "Thị trấn Vĩnh Trụ",
        "path": "Vĩnh Trụ, Lý Nhân, Hà Nam",
        "path_with_type": "Thị trấn Vĩnh Trụ, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13564",
        "parent_code": "353"
    },
    "13567": {
        "name": "Hợp Lý",
        "type": "xa",
        "slug": "hop-ly",
        "name_with_type": "Xã Hợp Lý",
        "path": "Hợp Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Hợp Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13567",
        "parent_code": "353"
    },
    "13570": {
        "name": "Nguyên Lý",
        "type": "xa",
        "slug": "nguyen-ly",
        "name_with_type": "Xã Nguyên Lý",
        "path": "Nguyên Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Nguyên Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13570",
        "parent_code": "353"
    },
    "13573": {
        "name": "Chính Lý",
        "type": "xa",
        "slug": "chinh-ly",
        "name_with_type": "Xã Chính Lý",
        "path": "Chính Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Chính Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13573",
        "parent_code": "353"
    },
    "13576": {
        "name": "Chân Lý",
        "type": "xa",
        "slug": "chan-ly",
        "name_with_type": "Xã Chân Lý",
        "path": "Chân Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Chân Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13576",
        "parent_code": "353"
    },
    "13579": {
        "name": "Đạo Lý",
        "type": "xa",
        "slug": "dao-ly",
        "name_with_type": "Xã Đạo Lý",
        "path": "Đạo Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Đạo Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13579",
        "parent_code": "353"
    },
    "13582": {
        "name": "Công Lý",
        "type": "xa",
        "slug": "cong-ly",
        "name_with_type": "Xã Công Lý",
        "path": "Công Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Công Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13582",
        "parent_code": "353"
    },
    "13585": {
        "name": "Văn Lý",
        "type": "xa",
        "slug": "van-ly",
        "name_with_type": "Xã Văn Lý",
        "path": "Văn Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Văn Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13585",
        "parent_code": "353"
    },
    "13588": {
        "name": "Bắc Lý",
        "type": "xa",
        "slug": "bac-ly",
        "name_with_type": "Xã Bắc Lý",
        "path": "Bắc Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Bắc Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13588",
        "parent_code": "353"
    },
    "13591": {
        "name": "Đức Lý",
        "type": "xa",
        "slug": "duc-ly",
        "name_with_type": "Xã Đức Lý",
        "path": "Đức Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Đức Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13591",
        "parent_code": "353"
    },
    "13594": {
        "name": "Nhân Đạo",
        "type": "xa",
        "slug": "nhan-dao",
        "name_with_type": "Xã Nhân Đạo",
        "path": "Nhân Đạo, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Nhân Đạo, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13594",
        "parent_code": "353"
    },
    "13597": {
        "name": "Đồng Lý",
        "type": "xa",
        "slug": "dong-ly",
        "name_with_type": "Xã Đồng Lý",
        "path": "Đồng Lý, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Đồng Lý, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13597",
        "parent_code": "353"
    },
    "13600": {
        "name": "Nhân Thịnh",
        "type": "xa",
        "slug": "nhan-thinh",
        "name_with_type": "Xã Nhân Thịnh",
        "path": "Nhân Thịnh, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Nhân Thịnh, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13600",
        "parent_code": "353"
    },
    "13603": {
        "name": "Nhân Hưng",
        "type": "xa",
        "slug": "nhan-hung",
        "name_with_type": "Xã Nhân Hưng",
        "path": "Nhân Hưng, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Nhân Hưng, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13603",
        "parent_code": "353"
    },
    "13606": {
        "name": "Nhân Khang",
        "type": "xa",
        "slug": "nhan-khang",
        "name_with_type": "Xã Nhân Khang",
        "path": "Nhân Khang, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Nhân Khang, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13606",
        "parent_code": "353"
    },
    "13609": {
        "name": "Nhân Mỹ",
        "type": "xa",
        "slug": "nhan-my",
        "name_with_type": "Xã Nhân Mỹ",
        "path": "Nhân Mỹ, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Nhân Mỹ, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13609",
        "parent_code": "353"
    },
    "13612": {
        "name": "Nhân Nghĩa",
        "type": "xa",
        "slug": "nhan-nghia",
        "name_with_type": "Xã Nhân Nghĩa",
        "path": "Nhân Nghĩa, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Nhân Nghĩa, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13612",
        "parent_code": "353"
    },
    "13615": {
        "name": "Nhân Chính",
        "type": "xa",
        "slug": "nhan-chinh",
        "name_with_type": "Xã Nhân Chính",
        "path": "Nhân Chính, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Nhân Chính, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13615",
        "parent_code": "353"
    },
    "13618": {
        "name": "Nhân Bình",
        "type": "xa",
        "slug": "nhan-binh",
        "name_with_type": "Xã Nhân Bình",
        "path": "Nhân Bình, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Nhân Bình, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13618",
        "parent_code": "353"
    },
    "13621": {
        "name": "Phú Phúc",
        "type": "xa",
        "slug": "phu-phuc",
        "name_with_type": "Xã Phú Phúc",
        "path": "Phú Phúc, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Phú Phúc, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13621",
        "parent_code": "353"
    },
    "13624": {
        "name": "Xuân Khê",
        "type": "xa",
        "slug": "xuan-khe",
        "name_with_type": "Xã Xuân Khê",
        "path": "Xuân Khê, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Xuân Khê, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13624",
        "parent_code": "353"
    },
    "13627": {
        "name": "Tiến Thắng",
        "type": "xa",
        "slug": "tien-thang",
        "name_with_type": "Xã Tiến Thắng",
        "path": "Tiến Thắng, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Tiến Thắng, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13627",
        "parent_code": "353"
    },
    "13630": {
        "name": "Hòa Hậu",
        "type": "xa",
        "slug": "hoa-hau",
        "name_with_type": "Xã Hòa Hậu",
        "path": "Hòa Hậu, Lý Nhân, Hà Nam",
        "path_with_type": "Xã Hòa Hậu, Huyện Lý Nhân, Tỉnh Hà Nam",
        "code": "13630",
        "parent_code": "353"
    },
    "09163": {
        "name": "Vũ Ninh",
        "type": "phuong",
        "slug": "vu-ninh",
        "name_with_type": "Phường Vũ Ninh",
        "path": "Vũ Ninh, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Vũ Ninh, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09163",
        "parent_code": "256"
    },
    "09166": {
        "name": "Đáp Cầu",
        "type": "phuong",
        "slug": "dap-cau",
        "name_with_type": "Phường Đáp Cầu",
        "path": "Đáp Cầu, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Đáp Cầu, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09166",
        "parent_code": "256"
    },
    "09169": {
        "name": "Thị Cầu",
        "type": "phuong",
        "slug": "thi-cau",
        "name_with_type": "Phường Thị Cầu",
        "path": "Thị Cầu, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Thị Cầu, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09169",
        "parent_code": "256"
    },
    "09172": {
        "name": "Kinh Bắc",
        "type": "phuong",
        "slug": "kinh-bac",
        "name_with_type": "Phường Kinh Bắc",
        "path": "Kinh Bắc, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Kinh Bắc, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09172",
        "parent_code": "256"
    },
    "09175": {
        "name": "Vệ An",
        "type": "phuong",
        "slug": "ve-an",
        "name_with_type": "Phường Vệ An",
        "path": "Vệ An, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Vệ An, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09175",
        "parent_code": "256"
    },
    "09178": {
        "name": "Tiền An",
        "type": "phuong",
        "slug": "tien-an",
        "name_with_type": "Phường Tiền An",
        "path": "Tiền An, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Tiền An, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09178",
        "parent_code": "256"
    },
    "09181": {
        "name": "Đại Phúc",
        "type": "phuong",
        "slug": "dai-phuc",
        "name_with_type": "Phường Đại Phúc",
        "path": "Đại Phúc, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Đại Phúc, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09181",
        "parent_code": "256"
    },
    "09184": {
        "name": "Ninh Xá",
        "type": "phuong",
        "slug": "ninh-xa",
        "name_with_type": "Phường Ninh Xá",
        "path": "Ninh Xá, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Ninh Xá, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09184",
        "parent_code": "256"
    },
    "09187": {
        "name": "Suối Hoa",
        "type": "phuong",
        "slug": "suoi-hoa",
        "name_with_type": "Phường Suối Hoa",
        "path": "Suối Hoa, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Suối Hoa, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09187",
        "parent_code": "256"
    },
    "09190": {
        "name": "Võ Cường",
        "type": "phuong",
        "slug": "vo-cuong",
        "name_with_type": "Phường Võ Cường",
        "path": "Võ Cường, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Võ Cường, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09190",
        "parent_code": "256"
    },
    "09214": {
        "name": "Hòa Long",
        "type": "xa",
        "slug": "hoa-long",
        "name_with_type": "Xã Hòa Long",
        "path": "Hòa Long, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Xã Hòa Long, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09214",
        "parent_code": "256"
    },
    "09226": {
        "name": "Vạn An",
        "type": "phuong",
        "slug": "van-an",
        "name_with_type": "Phường Vạn An",
        "path": "Vạn An, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Vạn An, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09226",
        "parent_code": "256"
    },
    "09235": {
        "name": "Khúc Xuyên",
        "type": "phuong",
        "slug": "khuc-xuyen",
        "name_with_type": "Phường Khúc Xuyên",
        "path": "Khúc Xuyên, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Khúc Xuyên, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09235",
        "parent_code": "256"
    },
    "09244": {
        "name": "Phong Khê",
        "type": "phuong",
        "slug": "phong-khe",
        "name_with_type": "Phường Phong Khê",
        "path": "Phong Khê, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Phong Khê, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09244",
        "parent_code": "256"
    },
    "09256": {
        "name": "Kim Chân",
        "type": "xa",
        "slug": "kim-chan",
        "name_with_type": "Xã Kim Chân",
        "path": "Kim Chân, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Xã Kim Chân, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09256",
        "parent_code": "256"
    },
    "09271": {
        "name": "Vân Dương",
        "type": "phuong",
        "slug": "van-duong",
        "name_with_type": "Phường Vân Dương",
        "path": "Vân Dương, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Vân Dương, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09271",
        "parent_code": "256"
    },
    "09286": {
        "name": "Nam Sơn",
        "type": "xa",
        "slug": "nam-son",
        "name_with_type": "Xã Nam Sơn",
        "path": "Nam Sơn, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Xã Nam Sơn, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09286",
        "parent_code": "256"
    },
    "09325": {
        "name": "Khắc Niệm",
        "type": "phuong",
        "slug": "khac-niem",
        "name_with_type": "Phường Khắc Niệm",
        "path": "Khắc Niệm, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Khắc Niệm, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09325",
        "parent_code": "256"
    },
    "09331": {
        "name": "Hạp Lĩnh",
        "type": "phuong",
        "slug": "hap-linh",
        "name_with_type": "Phường Hạp Lĩnh",
        "path": "Hạp Lĩnh, Bắc Ninh, Bắc Ninh",
        "path_with_type": "Phường Hạp Lĩnh, Thành phố Bắc Ninh, Tỉnh Bắc Ninh",
        "code": "09331",
        "parent_code": "256"
    },
    "09193": {
        "name": "Chờ",
        "type": "thi-tran",
        "slug": "cho",
        "name_with_type": "Thị trấn Chờ",
        "path": "Chờ, Yên Phong, Bắc Ninh",
        "path_with_type": "Thị trấn Chờ, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09193",
        "parent_code": "258"
    },
    "09196": {
        "name": "Dũng Liệt",
        "type": "xa",
        "slug": "dung-liet",
        "name_with_type": "Xã Dũng Liệt",
        "path": "Dũng Liệt, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Dũng Liệt, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09196",
        "parent_code": "258"
    },
    "09199": {
        "name": "Tam Đa",
        "type": "xa",
        "slug": "tam-da",
        "name_with_type": "Xã Tam Đa",
        "path": "Tam Đa, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Tam Đa, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09199",
        "parent_code": "258"
    },
    "09202": {
        "name": "Tam Giang",
        "type": "xa",
        "slug": "tam-giang",
        "name_with_type": "Xã Tam Giang",
        "path": "Tam Giang, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Tam Giang, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09202",
        "parent_code": "258"
    },
    "09205": {
        "name": "Yên Trung",
        "type": "xa",
        "slug": "yen-trung",
        "name_with_type": "Xã Yên Trung",
        "path": "Yên Trung, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Yên Trung, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09205",
        "parent_code": "258"
    },
    "09208": {
        "name": "Thụy Hòa",
        "type": "xa",
        "slug": "thuy-hoa",
        "name_with_type": "Xã Thụy Hòa",
        "path": "Thụy Hòa, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Thụy Hòa, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09208",
        "parent_code": "258"
    },
    "09211": {
        "name": "Hòa Tiến",
        "type": "xa",
        "slug": "hoa-tien",
        "name_with_type": "Xã Hòa Tiến",
        "path": "Hòa Tiến, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Hòa Tiến, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09211",
        "parent_code": "258"
    },
    "09217": {
        "name": "Đông Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đông Tiến",
        "path": "Đông Tiến, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Đông Tiến, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09217",
        "parent_code": "258"
    },
    "09220": {
        "name": "Yên Phụ",
        "type": "xa",
        "slug": "yen-phu",
        "name_with_type": "Xã Yên Phụ",
        "path": "Yên Phụ, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Yên Phụ, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09220",
        "parent_code": "258"
    },
    "09223": {
        "name": "Trung Nghĩa",
        "type": "xa",
        "slug": "trung-nghia",
        "name_with_type": "Xã Trung Nghĩa",
        "path": "Trung Nghĩa, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Trung Nghĩa, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09223",
        "parent_code": "258"
    },
    "09229": {
        "name": "Đông Phong",
        "type": "xa",
        "slug": "dong-phong",
        "name_with_type": "Xã Đông Phong",
        "path": "Đông Phong, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Đông Phong, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09229",
        "parent_code": "258"
    },
    "09232": {
        "name": "Long Châu",
        "type": "xa",
        "slug": "long-chau",
        "name_with_type": "Xã Long Châu",
        "path": "Long Châu, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Long Châu, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09232",
        "parent_code": "258"
    },
    "09238": {
        "name": "Văn Môn",
        "type": "xa",
        "slug": "van-mon",
        "name_with_type": "Xã Văn Môn",
        "path": "Văn Môn, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Văn Môn, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09238",
        "parent_code": "258"
    },
    "09241": {
        "name": "Đông Thọ",
        "type": "xa",
        "slug": "dong-tho",
        "name_with_type": "Xã Đông Thọ",
        "path": "Đông Thọ, Yên Phong, Bắc Ninh",
        "path_with_type": "Xã Đông Thọ, Huyện Yên Phong, Tỉnh Bắc Ninh",
        "code": "09241",
        "parent_code": "258"
    },
    "09247": {
        "name": "Phố Mới",
        "type": "thi-tran",
        "slug": "pho-moi",
        "name_with_type": "Thị trấn Phố Mới",
        "path": "Phố Mới, Quế Võ, Bắc Ninh",
        "path_with_type": "Thị trấn Phố Mới, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09247",
        "parent_code": "259"
    },
    "09250": {
        "name": "Việt Thống",
        "type": "xa",
        "slug": "viet-thong",
        "name_with_type": "Xã Việt Thống",
        "path": "Việt Thống, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Việt Thống, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09250",
        "parent_code": "259"
    },
    "09253": {
        "name": "Đại Xuân",
        "type": "xa",
        "slug": "dai-xuan",
        "name_with_type": "Xã Đại Xuân",
        "path": "Đại Xuân, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Đại Xuân, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09253",
        "parent_code": "259"
    },
    "09259": {
        "name": "Nhân Hòa",
        "type": "xa",
        "slug": "nhan-hoa",
        "name_with_type": "Xã Nhân Hòa",
        "path": "Nhân Hòa, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Nhân Hòa, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09259",
        "parent_code": "259"
    },
    "09262": {
        "name": "Bằng An",
        "type": "xa",
        "slug": "bang-an",
        "name_with_type": "Xã Bằng An",
        "path": "Bằng An, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Bằng An, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09262",
        "parent_code": "259"
    },
    "09265": {
        "name": "Phương Liễu",
        "type": "xa",
        "slug": "phuong-lieu",
        "name_with_type": "Xã Phương Liễu",
        "path": "Phương Liễu, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Phương Liễu, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09265",
        "parent_code": "259"
    },
    "09268": {
        "name": "Quế Tân",
        "type": "xa",
        "slug": "que-tan",
        "name_with_type": "Xã Quế Tân",
        "path": "Quế Tân, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Quế Tân, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09268",
        "parent_code": "259"
    },
    "09274": {
        "name": "Phù Lương",
        "type": "xa",
        "slug": "phu-luong",
        "name_with_type": "Xã Phù Lương",
        "path": "Phù Lương, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Phù Lương, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09274",
        "parent_code": "259"
    },
    "09277": {
        "name": "Phù Lãng",
        "type": "xa",
        "slug": "phu-lang",
        "name_with_type": "Xã Phù Lãng",
        "path": "Phù Lãng, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Phù Lãng, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09277",
        "parent_code": "259"
    },
    "09280": {
        "name": "Phượng Mao",
        "type": "xa",
        "slug": "phuong-mao",
        "name_with_type": "Xã Phượng Mao",
        "path": "Phượng Mao, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Phượng Mao, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09280",
        "parent_code": "259"
    },
    "09283": {
        "name": "Việt Hùng",
        "type": "xa",
        "slug": "viet-hung",
        "name_with_type": "Xã Việt Hùng",
        "path": "Việt Hùng, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Việt Hùng, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09283",
        "parent_code": "259"
    },
    "09289": {
        "name": "Ngọc Xá",
        "type": "xa",
        "slug": "ngoc-xa",
        "name_with_type": "Xã Ngọc Xá",
        "path": "Ngọc Xá, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Ngọc Xá, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09289",
        "parent_code": "259"
    },
    "09292": {
        "name": "Châu Phong",
        "type": "xa",
        "slug": "chau-phong",
        "name_with_type": "Xã Châu Phong",
        "path": "Châu Phong, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Châu Phong, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09292",
        "parent_code": "259"
    },
    "09295": {
        "name": "Bồng Lai",
        "type": "xa",
        "slug": "bong-lai",
        "name_with_type": "Xã Bồng Lai",
        "path": "Bồng Lai, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Bồng Lai, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09295",
        "parent_code": "259"
    },
    "09298": {
        "name": "Cách Bi",
        "type": "xa",
        "slug": "cach-bi",
        "name_with_type": "Xã Cách Bi",
        "path": "Cách Bi, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Cách Bi, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09298",
        "parent_code": "259"
    },
    "09301": {
        "name": "Đào Viên",
        "type": "xa",
        "slug": "dao-vien",
        "name_with_type": "Xã Đào Viên",
        "path": "Đào Viên, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Đào Viên, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09301",
        "parent_code": "259"
    },
    "09304": {
        "name": "Yên Giả",
        "type": "xa",
        "slug": "yen-gia",
        "name_with_type": "Xã Yên Giả",
        "path": "Yên Giả, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Yên Giả, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09304",
        "parent_code": "259"
    },
    "09307": {
        "name": "Mộ Đạo",
        "type": "xa",
        "slug": "mo-dao",
        "name_with_type": "Xã Mộ Đạo",
        "path": "Mộ Đạo, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Mộ Đạo, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09307",
        "parent_code": "259"
    },
    "09310": {
        "name": "Đức Long",
        "type": "xa",
        "slug": "duc-long",
        "name_with_type": "Xã Đức Long",
        "path": "Đức Long, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Đức Long, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09310",
        "parent_code": "259"
    },
    "09313": {
        "name": "Chi Lăng",
        "type": "xa",
        "slug": "chi-lang",
        "name_with_type": "Xã Chi Lăng",
        "path": "Chi Lăng, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Chi Lăng, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09313",
        "parent_code": "259"
    },
    "09316": {
        "name": "Hán Quảng",
        "type": "xa",
        "slug": "han-quang",
        "name_with_type": "Xã Hán Quảng",
        "path": "Hán Quảng, Quế Võ, Bắc Ninh",
        "path_with_type": "Xã Hán Quảng, Huyện Quế Võ, Tỉnh Bắc Ninh",
        "code": "09316",
        "parent_code": "259"
    },
    "09319": {
        "name": "Lim",
        "type": "thi-tran",
        "slug": "lim",
        "name_with_type": "Thị trấn Lim",
        "path": "Lim, Tiên Du, Bắc Ninh",
        "path_with_type": "Thị trấn Lim, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09319",
        "parent_code": "260"
    },
    "09322": {
        "name": "Phú Lâm",
        "type": "xa",
        "slug": "phu-lam",
        "name_with_type": "Xã Phú Lâm",
        "path": "Phú Lâm, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Phú Lâm, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09322",
        "parent_code": "260"
    },
    "09328": {
        "name": "Nội Duệ",
        "type": "xa",
        "slug": "noi-due",
        "name_with_type": "Xã Nội Duệ",
        "path": "Nội Duệ, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Nội Duệ, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09328",
        "parent_code": "260"
    },
    "09334": {
        "name": "Liên Bão",
        "type": "xa",
        "slug": "lien-bao",
        "name_with_type": "Xã Liên Bão",
        "path": "Liên Bão, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Liên Bão, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09334",
        "parent_code": "260"
    },
    "09337": {
        "name": "Hiên Vân",
        "type": "xa",
        "slug": "hien-van",
        "name_with_type": "Xã Hiên Vân",
        "path": "Hiên Vân, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Hiên Vân, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09337",
        "parent_code": "260"
    },
    "09340": {
        "name": "Hoàn Sơn",
        "type": "xa",
        "slug": "hoan-son",
        "name_with_type": "Xã Hoàn Sơn",
        "path": "Hoàn Sơn, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Hoàn Sơn, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09340",
        "parent_code": "260"
    },
    "09343": {
        "name": "Lạc Vệ",
        "type": "xa",
        "slug": "lac-ve",
        "name_with_type": "Xã Lạc Vệ",
        "path": "Lạc Vệ, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Lạc Vệ, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09343",
        "parent_code": "260"
    },
    "09346": {
        "name": "Việt Đoàn",
        "type": "xa",
        "slug": "viet-doan",
        "name_with_type": "Xã Việt Đoàn",
        "path": "Việt Đoàn, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Việt Đoàn, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09346",
        "parent_code": "260"
    },
    "09349": {
        "name": "Phật Tích",
        "type": "xa",
        "slug": "phat-tich",
        "name_with_type": "Xã Phật Tích",
        "path": "Phật Tích, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Phật Tích, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09349",
        "parent_code": "260"
    },
    "09352": {
        "name": "Tân Chi",
        "type": "xa",
        "slug": "tan-chi",
        "name_with_type": "Xã Tân Chi",
        "path": "Tân Chi, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Tân Chi, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09352",
        "parent_code": "260"
    },
    "09355": {
        "name": "Đại Đồng",
        "type": "xa",
        "slug": "dai-dong",
        "name_with_type": "Xã Đại Đồng",
        "path": "Đại Đồng, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Đại Đồng, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09355",
        "parent_code": "260"
    },
    "09358": {
        "name": "Tri Phương",
        "type": "xa",
        "slug": "tri-phuong",
        "name_with_type": "Xã Tri Phương",
        "path": "Tri Phương, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Tri Phương, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09358",
        "parent_code": "260"
    },
    "09361": {
        "name": "Minh Đạo",
        "type": "xa",
        "slug": "minh-dao",
        "name_with_type": "Xã Minh Đạo",
        "path": "Minh Đạo, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Minh Đạo, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09361",
        "parent_code": "260"
    },
    "09364": {
        "name": "Cảnh Hưng",
        "type": "xa",
        "slug": "canh-hung",
        "name_with_type": "Xã Cảnh Hưng",
        "path": "Cảnh Hưng, Tiên Du, Bắc Ninh",
        "path_with_type": "Xã Cảnh Hưng, Huyện Tiên Du, Tỉnh Bắc Ninh",
        "code": "09364",
        "parent_code": "260"
    },
    "09367": {
        "name": "Đông Ngàn",
        "type": "phuong",
        "slug": "dong-ngan",
        "name_with_type": "Phường Đông Ngàn",
        "path": "Đông Ngàn, Từ Sơn, Bắc Ninh",
        "path_with_type": "Phường Đông Ngàn, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09367",
        "parent_code": "261"
    },
    "09370": {
        "name": "Tam Sơn",
        "type": "xa",
        "slug": "tam-son",
        "name_with_type": "Xã Tam Sơn",
        "path": "Tam Sơn, Từ Sơn, Bắc Ninh",
        "path_with_type": "Xã Tam Sơn, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09370",
        "parent_code": "261"
    },
    "09373": {
        "name": "Hương Mạc",
        "type": "xa",
        "slug": "huong-mac",
        "name_with_type": "Xã Hương Mạc",
        "path": "Hương Mạc, Từ Sơn, Bắc Ninh",
        "path_with_type": "Xã Hương Mạc, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09373",
        "parent_code": "261"
    },
    "09376": {
        "name": "Tương Giang",
        "type": "xa",
        "slug": "tuong-giang",
        "name_with_type": "Xã Tương Giang",
        "path": "Tương Giang, Từ Sơn, Bắc Ninh",
        "path_with_type": "Xã Tương Giang, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09376",
        "parent_code": "261"
    },
    "09379": {
        "name": "Phù Khê",
        "type": "xa",
        "slug": "phu-khe",
        "name_with_type": "Xã Phù Khê",
        "path": "Phù Khê, Từ Sơn, Bắc Ninh",
        "path_with_type": "Xã Phù Khê, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09379",
        "parent_code": "261"
    },
    "09382": {
        "name": "Đồng Kỵ",
        "type": "phuong",
        "slug": "dong-ky",
        "name_with_type": "Phường Đồng Kỵ",
        "path": "Đồng Kỵ, Từ Sơn, Bắc Ninh",
        "path_with_type": "Phường Đồng Kỵ, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09382",
        "parent_code": "261"
    },
    "09383": {
        "name": "Trang Hạ",
        "type": "phuong",
        "slug": "trang-ha",
        "name_with_type": "Phường Trang Hạ",
        "path": "Trang Hạ, Từ Sơn, Bắc Ninh",
        "path_with_type": "Phường Trang Hạ, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09383",
        "parent_code": "261"
    },
    "09385": {
        "name": "Đồng Nguyên",
        "type": "phuong",
        "slug": "dong-nguyen",
        "name_with_type": "Phường Đồng Nguyên",
        "path": "Đồng Nguyên, Từ Sơn, Bắc Ninh",
        "path_with_type": "Phường Đồng Nguyên, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09385",
        "parent_code": "261"
    },
    "09388": {
        "name": "Châu Khê",
        "type": "phuong",
        "slug": "chau-khe",
        "name_with_type": "Phường Châu Khê",
        "path": "Châu Khê, Từ Sơn, Bắc Ninh",
        "path_with_type": "Phường Châu Khê, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09388",
        "parent_code": "261"
    },
    "09391": {
        "name": "Tân Hồng",
        "type": "phuong",
        "slug": "tan-hong",
        "name_with_type": "Phường Tân Hồng",
        "path": "Tân Hồng, Từ Sơn, Bắc Ninh",
        "path_with_type": "Phường Tân Hồng, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09391",
        "parent_code": "261"
    },
    "09394": {
        "name": "Đình Bảng",
        "type": "phuong",
        "slug": "dinh-bang",
        "name_with_type": "Phường Đình Bảng",
        "path": "Đình Bảng, Từ Sơn, Bắc Ninh",
        "path_with_type": "Phường Đình Bảng, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09394",
        "parent_code": "261"
    },
    "09397": {
        "name": "Phù Chẩn",
        "type": "xa",
        "slug": "phu-chan",
        "name_with_type": "Xã Phù Chẩn",
        "path": "Phù Chẩn, Từ Sơn, Bắc Ninh",
        "path_with_type": "Xã Phù Chẩn, Thị xã Từ Sơn, Tỉnh Bắc Ninh",
        "code": "09397",
        "parent_code": "261"
    },
    "09400": {
        "name": "Hồ",
        "type": "thi-tran",
        "slug": "ho",
        "name_with_type": "Thị trấn Hồ",
        "path": "Hồ, Thuận Thành, Bắc Ninh",
        "path_with_type": "Thị trấn Hồ, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09400",
        "parent_code": "262"
    },
    "09403": {
        "name": "Hoài Thượng",
        "type": "xa",
        "slug": "hoai-thuong",
        "name_with_type": "Xã Hoài Thượng",
        "path": "Hoài Thượng, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Hoài Thượng, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09403",
        "parent_code": "262"
    },
    "09406": {
        "name": "Đại Đồng Thành",
        "type": "xa",
        "slug": "dai-dong-thanh",
        "name_with_type": "Xã Đại Đồng Thành",
        "path": "Đại Đồng Thành, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Đại Đồng Thành, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09406",
        "parent_code": "262"
    },
    "09409": {
        "name": "Mão Điền",
        "type": "xa",
        "slug": "mao-dien",
        "name_with_type": "Xã Mão Điền",
        "path": "Mão Điền, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Mão Điền, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09409",
        "parent_code": "262"
    },
    "09412": {
        "name": "Song Hồ",
        "type": "xa",
        "slug": "song-ho",
        "name_with_type": "Xã Song Hồ",
        "path": "Song Hồ, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Song Hồ, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09412",
        "parent_code": "262"
    },
    "09415": {
        "name": "Đình Tổ",
        "type": "xa",
        "slug": "dinh-to",
        "name_with_type": "Xã Đình Tổ",
        "path": "Đình Tổ, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Đình Tổ, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09415",
        "parent_code": "262"
    },
    "09418": {
        "name": "An Bình",
        "type": "xa",
        "slug": "an-binh",
        "name_with_type": "Xã An Bình",
        "path": "An Bình, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã An Bình, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09418",
        "parent_code": "262"
    },
    "09421": {
        "name": "Trí Quả",
        "type": "xa",
        "slug": "tri-qua",
        "name_with_type": "Xã Trí Quả",
        "path": "Trí Quả, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Trí Quả, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09421",
        "parent_code": "262"
    },
    "09424": {
        "name": "Gia Đông",
        "type": "xa",
        "slug": "gia-dong",
        "name_with_type": "Xã Gia Đông",
        "path": "Gia Đông, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Gia Đông, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09424",
        "parent_code": "262"
    },
    "09427": {
        "name": "Thanh Khương",
        "type": "xa",
        "slug": "thanh-khuong",
        "name_with_type": "Xã Thanh Khương",
        "path": "Thanh Khương, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Thanh Khương, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09427",
        "parent_code": "262"
    },
    "09430": {
        "name": "Trạm Lộ",
        "type": "xa",
        "slug": "tram-lo",
        "name_with_type": "Xã Trạm Lộ",
        "path": "Trạm Lộ, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Trạm Lộ, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09430",
        "parent_code": "262"
    },
    "09433": {
        "name": "Xuân Lâm",
        "type": "xa",
        "slug": "xuan-lam",
        "name_with_type": "Xã Xuân Lâm",
        "path": "Xuân Lâm, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Xuân Lâm, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09433",
        "parent_code": "262"
    },
    "09436": {
        "name": "Hà Mãn",
        "type": "xa",
        "slug": "ha-man",
        "name_with_type": "Xã Hà Mãn",
        "path": "Hà Mãn, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Hà Mãn, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09436",
        "parent_code": "262"
    },
    "09439": {
        "name": "Ngũ Thái",
        "type": "xa",
        "slug": "ngu-thai",
        "name_with_type": "Xã Ngũ Thái",
        "path": "Ngũ Thái, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Ngũ Thái, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09439",
        "parent_code": "262"
    },
    "09442": {
        "name": "Nguyệt Đức",
        "type": "xa",
        "slug": "nguyet-duc",
        "name_with_type": "Xã Nguyệt Đức",
        "path": "Nguyệt Đức, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Nguyệt Đức, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09442",
        "parent_code": "262"
    },
    "09445": {
        "name": "Ninh Xá",
        "type": "xa",
        "slug": "ninh-xa",
        "name_with_type": "Xã Ninh Xá",
        "path": "Ninh Xá, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Ninh Xá, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09445",
        "parent_code": "262"
    },
    "09448": {
        "name": "Nghĩa Đạo",
        "type": "xa",
        "slug": "nghia-dao",
        "name_with_type": "Xã Nghĩa Đạo",
        "path": "Nghĩa Đạo, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Nghĩa Đạo, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09448",
        "parent_code": "262"
    },
    "09451": {
        "name": "Song Liễu",
        "type": "xa",
        "slug": "song-lieu",
        "name_with_type": "Xã Song Liễu",
        "path": "Song Liễu, Thuận Thành, Bắc Ninh",
        "path_with_type": "Xã Song Liễu, Huyện Thuận Thành, Tỉnh Bắc Ninh",
        "code": "09451",
        "parent_code": "262"
    },
    "09454": {
        "name": "Gia Bình",
        "type": "thi-tran",
        "slug": "gia-binh",
        "name_with_type": "Thị trấn Gia Bình",
        "path": "Gia Bình, Gia Bình, Bắc Ninh",
        "path_with_type": "Thị trấn Gia Bình, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09454",
        "parent_code": "263"
    },
    "09457": {
        "name": "Vạn Ninh",
        "type": "xa",
        "slug": "van-ninh",
        "name_with_type": "Xã Vạn Ninh",
        "path": "Vạn Ninh, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Vạn Ninh, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09457",
        "parent_code": "263"
    },
    "09460": {
        "name": "Thái Bảo",
        "type": "xa",
        "slug": "thai-bao",
        "name_with_type": "Xã Thái Bảo",
        "path": "Thái Bảo, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Thái Bảo, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09460",
        "parent_code": "263"
    },
    "09463": {
        "name": "Giang Sơn",
        "type": "xa",
        "slug": "giang-son",
        "name_with_type": "Xã Giang Sơn",
        "path": "Giang Sơn, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Giang Sơn, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09463",
        "parent_code": "263"
    },
    "09466": {
        "name": "Cao Đức",
        "type": "xa",
        "slug": "cao-duc",
        "name_with_type": "Xã Cao Đức",
        "path": "Cao Đức, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Cao Đức, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09466",
        "parent_code": "263"
    },
    "09469": {
        "name": "Đại Lai",
        "type": "xa",
        "slug": "dai-lai",
        "name_with_type": "Xã Đại Lai",
        "path": "Đại Lai, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Đại Lai, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09469",
        "parent_code": "263"
    },
    "09472": {
        "name": "Song Giang",
        "type": "xa",
        "slug": "song-giang",
        "name_with_type": "Xã Song Giang",
        "path": "Song Giang, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Song Giang, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09472",
        "parent_code": "263"
    },
    "09475": {
        "name": "Bình Dương",
        "type": "xa",
        "slug": "binh-duong",
        "name_with_type": "Xã Bình Dương",
        "path": "Bình Dương, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Bình Dương, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09475",
        "parent_code": "263"
    },
    "09478": {
        "name": "Lãng Ngâm",
        "type": "xa",
        "slug": "lang-ngam",
        "name_with_type": "Xã Lãng Ngâm",
        "path": "Lãng Ngâm, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Lãng Ngâm, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09478",
        "parent_code": "263"
    },
    "09481": {
        "name": "Nhân Thắng",
        "type": "xa",
        "slug": "nhan-thang",
        "name_with_type": "Xã Nhân Thắng",
        "path": "Nhân Thắng, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Nhân Thắng, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09481",
        "parent_code": "263"
    },
    "09484": {
        "name": "Xuân Lai",
        "type": "xa",
        "slug": "xuan-lai",
        "name_with_type": "Xã Xuân Lai",
        "path": "Xuân Lai, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Xuân Lai, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09484",
        "parent_code": "263"
    },
    "09487": {
        "name": "Đông Cứu",
        "type": "xa",
        "slug": "dong-cuu",
        "name_with_type": "Xã Đông Cứu",
        "path": "Đông Cứu, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Đông Cứu, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09487",
        "parent_code": "263"
    },
    "09490": {
        "name": "Đại Bái",
        "type": "xa",
        "slug": "dai-bai",
        "name_with_type": "Xã Đại Bái",
        "path": "Đại Bái, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Đại Bái, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09490",
        "parent_code": "263"
    },
    "09493": {
        "name": "Quỳnh Phú",
        "type": "xa",
        "slug": "quynh-phu",
        "name_with_type": "Xã Quỳnh Phú",
        "path": "Quỳnh Phú, Gia Bình, Bắc Ninh",
        "path_with_type": "Xã Quỳnh Phú, Huyện Gia Bình, Tỉnh Bắc Ninh",
        "code": "09493",
        "parent_code": "263"
    },
    "09496": {
        "name": "Thứa",
        "type": "thi-tran",
        "slug": "thua",
        "name_with_type": "Thị trấn Thứa",
        "path": "Thứa, Lương Tài, Bắc Ninh",
        "path_with_type": "Thị trấn Thứa, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09496",
        "parent_code": "264"
    },
    "09499": {
        "name": "An Thịnh",
        "type": "xa",
        "slug": "an-thinh",
        "name_with_type": "Xã An Thịnh",
        "path": "An Thịnh, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã An Thịnh, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09499",
        "parent_code": "264"
    },
    "09502": {
        "name": "Trung Kênh",
        "type": "xa",
        "slug": "trung-kenh",
        "name_with_type": "Xã Trung Kênh",
        "path": "Trung Kênh, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Trung Kênh, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09502",
        "parent_code": "264"
    },
    "09505": {
        "name": "Phú Hòa",
        "type": "xa",
        "slug": "phu-hoa",
        "name_with_type": "Xã Phú Hòa",
        "path": "Phú Hòa, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Phú Hòa, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09505",
        "parent_code": "264"
    },
    "09508": {
        "name": "Mỹ Hương",
        "type": "xa",
        "slug": "my-huong",
        "name_with_type": "Xã Mỹ Hương",
        "path": "Mỹ Hương, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Mỹ Hương, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09508",
        "parent_code": "264"
    },
    "09511": {
        "name": "Tân Lãng",
        "type": "xa",
        "slug": "tan-lang",
        "name_with_type": "Xã Tân Lãng",
        "path": "Tân Lãng, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Tân Lãng, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09511",
        "parent_code": "264"
    },
    "09514": {
        "name": "Quảng Phú",
        "type": "xa",
        "slug": "quang-phu",
        "name_with_type": "Xã Quảng Phú",
        "path": "Quảng Phú, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Quảng Phú, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09514",
        "parent_code": "264"
    },
    "09517": {
        "name": "Trừng Xá",
        "type": "xa",
        "slug": "trung-xa",
        "name_with_type": "Xã Trừng Xá",
        "path": "Trừng Xá, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Trừng Xá, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09517",
        "parent_code": "264"
    },
    "09520": {
        "name": "Lai Hạ",
        "type": "xa",
        "slug": "lai-ha",
        "name_with_type": "Xã Lai Hạ",
        "path": "Lai Hạ, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Lai Hạ, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09520",
        "parent_code": "264"
    },
    "09523": {
        "name": "Trung Chính",
        "type": "xa",
        "slug": "trung-chinh",
        "name_with_type": "Xã Trung Chính",
        "path": "Trung Chính, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Trung Chính, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09523",
        "parent_code": "264"
    },
    "09526": {
        "name": "Minh Tân",
        "type": "xa",
        "slug": "minh-tan",
        "name_with_type": "Xã Minh Tân",
        "path": "Minh Tân, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Minh Tân, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09526",
        "parent_code": "264"
    },
    "09529": {
        "name": "Bình Định",
        "type": "xa",
        "slug": "binh-dinh",
        "name_with_type": "Xã Bình Định",
        "path": "Bình Định, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Bình Định, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09529",
        "parent_code": "264"
    },
    "09532": {
        "name": "Phú Lương",
        "type": "xa",
        "slug": "phu-luong",
        "name_with_type": "Xã Phú Lương",
        "path": "Phú Lương, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Phú Lương, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09532",
        "parent_code": "264"
    },
    "09535": {
        "name": "Lâm Thao",
        "type": "xa",
        "slug": "lam-thao",
        "name_with_type": "Xã Lâm Thao",
        "path": "Lâm Thao, Lương Tài, Bắc Ninh",
        "path_with_type": "Xã Lâm Thao, Huyện Lương Tài, Tỉnh Bắc Ninh",
        "code": "09535",
        "parent_code": "264"
    },
    "07201": {
        "name": "Thọ Xương",
        "type": "phuong",
        "slug": "tho-xuong",
        "name_with_type": "Phường Thọ Xương",
        "path": "Thọ Xương, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Thọ Xương, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07201",
        "parent_code": "213"
    },
    "07204": {
        "name": "Trần Nguyên Hãn",
        "type": "phuong",
        "slug": "tran-nguyen-han",
        "name_with_type": "Phư��ng Trần Nguyên Hãn",
        "path": "Trần Nguyên Hãn, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Trần Nguyên Hãn, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07204",
        "parent_code": "213"
    },
    "07207": {
        "name": "Ngô Quyền",
        "type": "phuong",
        "slug": "ngo-quyen",
        "name_with_type": "Phường Ngô Quyền",
        "path": "Ngô Quyền, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Ngô Quyền, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07207",
        "parent_code": "213"
    },
    "07210": {
        "name": "Hoàng Văn Thụ",
        "type": "phuong",
        "slug": "hoang-van-thu",
        "name_with_type": "Phường Hoàng Văn Thụ",
        "path": "Hoàng Văn Thụ, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Hoàng Văn Thụ, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07210",
        "parent_code": "213"
    },
    "07213": {
        "name": "Trần Phú",
        "type": "phuong",
        "slug": "tran-phu",
        "name_with_type": "Phường Trần Phú",
        "path": "Trần Phú, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Trần Phú, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07213",
        "parent_code": "213"
    },
    "07216": {
        "name": "Mỹ Độ",
        "type": "phuong",
        "slug": "my-do",
        "name_with_type": "Phường Mỹ Độ",
        "path": "Mỹ Độ, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Mỹ Độ, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07216",
        "parent_code": "213"
    },
    "07219": {
        "name": "Lê Lợi",
        "type": "phuong",
        "slug": "le-loi",
        "name_with_type": "Phường Lê Lợi",
        "path": "Lê Lợi, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Lê Lợi, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07219",
        "parent_code": "213"
    },
    "07222": {
        "name": "Song Mai",
        "type": "xa",
        "slug": "song-mai",
        "name_with_type": "Xã Song Mai",
        "path": "Song Mai, Bắc Giang, Bắc Giang",
        "path_with_type": "Xã Song Mai, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07222",
        "parent_code": "213"
    },
    "07225": {
        "name": "Xương Giang",
        "type": "phuong",
        "slug": "xuong-giang",
        "name_with_type": "Phường Xương Giang",
        "path": "Xương Giang, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Xương Giang, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07225",
        "parent_code": "213"
    },
    "07228": {
        "name": "Đa Mai",
        "type": "phuong",
        "slug": "da-mai",
        "name_with_type": "Phường Đa Mai",
        "path": "Đa Mai, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Đa Mai, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07228",
        "parent_code": "213"
    },
    "07231": {
        "name": "Dĩnh Kế",
        "type": "phuong",
        "slug": "dinh-ke",
        "name_with_type": "Phường Dĩnh Kế",
        "path": "Dĩnh Kế, Bắc Giang, Bắc Giang",
        "path_with_type": "Phường Dĩnh Kế, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07231",
        "parent_code": "213"
    },
    "07441": {
        "name": "Dĩnh Trì",
        "type": "xa",
        "slug": "dinh-tri",
        "name_with_type": "Xã Dĩnh Trì",
        "path": "Dĩnh Trì, Bắc Giang, Bắc Giang",
        "path_with_type": "Xã Dĩnh Trì, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07441",
        "parent_code": "213"
    },
    "07687": {
        "name": "Tân Mỹ",
        "type": "xa",
        "slug": "tan-my",
        "name_with_type": "Xã Tân Mỹ",
        "path": "Tân Mỹ, Bắc Giang, Bắc Giang",
        "path_with_type": "Xã Tân Mỹ, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07687",
        "parent_code": "213"
    },
    "07696": {
        "name": "Đồng Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đồng Sơn",
        "path": "Đồng Sơn, Bắc Giang, Bắc Giang",
        "path_with_type": "Xã Đồng Sơn, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07696",
        "parent_code": "213"
    },
    "07699": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Bắc Giang, Bắc Giang",
        "path_with_type": "Xã Tân Tiến, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07699",
        "parent_code": "213"
    },
    "07705": {
        "name": "Song Khê",
        "type": "xa",
        "slug": "song-khe",
        "name_with_type": "Xã Song Khê",
        "path": "Song Khê, Bắc Giang, Bắc Giang",
        "path_with_type": "Xã Song Khê, Thành phố Bắc Giang, Tỉnh Bắc Giang",
        "code": "07705",
        "parent_code": "213"
    },
    "07234": {
        "name": "Cầu Gồ",
        "type": "thi-tran",
        "slug": "cau-go",
        "name_with_type": "Thị trấn Cầu Gồ",
        "path": "Cầu Gồ, Yên Thế, Bắc Giang",
        "path_with_type": "Thị trấn Cầu Gồ, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07234",
        "parent_code": "215"
    },
    "07237": {
        "name": "Bố Hạ",
        "type": "thi-tran",
        "slug": "bo-ha",
        "name_with_type": "Thị trấn Bố Hạ",
        "path": "Bố Hạ, Yên Thế, Bắc Giang",
        "path_with_type": "Thị trấn Bố Hạ, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07237",
        "parent_code": "215"
    },
    "07243": {
        "name": "Đồng Tiến",
        "type": "xa",
        "slug": "dong-tien",
        "name_with_type": "Xã Đồng Tiến",
        "path": "Đồng Tiến, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Đồng Tiến, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07243",
        "parent_code": "215"
    },
    "07246": {
        "name": "Canh Nậu",
        "type": "xa",
        "slug": "canh-nau",
        "name_with_type": "Xã Canh Nậu",
        "path": "Canh Nậu, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Canh Nậu, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07246",
        "parent_code": "215"
    },
    "07249": {
        "name": "Xuân Lương",
        "type": "xa",
        "slug": "xuan-luong",
        "name_with_type": "Xã Xuân Lương",
        "path": "Xuân Lương, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Xuân Lương, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07249",
        "parent_code": "215"
    },
    "07252": {
        "name": "Tam Tiến",
        "type": "xa",
        "slug": "tam-tien",
        "name_with_type": "Xã Tam Tiến",
        "path": "Tam Tiến, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Tam Tiến, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07252",
        "parent_code": "215"
    },
    "07255": {
        "name": "Đồng Vương",
        "type": "xa",
        "slug": "dong-vuong",
        "name_with_type": "Xã Đồng Vương",
        "path": "Đồng Vương, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Đồng Vương, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07255",
        "parent_code": "215"
    },
    "07258": {
        "name": "Đồng Hưu",
        "type": "xa",
        "slug": "dong-huu",
        "name_with_type": "Xã Đồng Hưu",
        "path": "Đồng Hưu, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Đồng Hưu, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07258",
        "parent_code": "215"
    },
    "07260": {
        "name": "Đồng Tâm",
        "type": "xa",
        "slug": "dong-tam",
        "name_with_type": "Xã Đồng Tâm",
        "path": "Đồng Tâm, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Đồng Tâm, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07260",
        "parent_code": "215"
    },
    "07261": {
        "name": "Tam Hiệp",
        "type": "xa",
        "slug": "tam-hiep",
        "name_with_type": "Xã Tam Hiệp",
        "path": "Tam Hiệp, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Tam Hiệp, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07261",
        "parent_code": "215"
    },
    "07264": {
        "name": "Tiến Thắng",
        "type": "xa",
        "slug": "tien-thang",
        "name_with_type": "Xã Tiến Thắng",
        "path": "Tiến Thắng, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Tiến Thắng, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07264",
        "parent_code": "215"
    },
    "07267": {
        "name": "Hồng Kỳ",
        "type": "xa",
        "slug": "hong-ky",
        "name_with_type": "Xã Hồng Kỳ",
        "path": "Hồng Kỳ, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Hồng Kỳ, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07267",
        "parent_code": "215"
    },
    "07270": {
        "name": "Đồng Lạc",
        "type": "xa",
        "slug": "dong-lac",
        "name_with_type": "Xã Đồng Lạc",
        "path": "Đồng Lạc, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Đồng Lạc, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07270",
        "parent_code": "215"
    },
    "07273": {
        "name": "Đông Sơn",
        "type": "xa",
        "slug": "dong-son",
        "name_with_type": "Xã Đông Sơn",
        "path": "Đông Sơn, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Đông Sơn, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07273",
        "parent_code": "215"
    },
    "07276": {
        "name": "Tân Hiệp",
        "type": "xa",
        "slug": "tan-hiep",
        "name_with_type": "Xã Tân Hiệp",
        "path": "Tân Hiệp, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Tân Hiệp, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07276",
        "parent_code": "215"
    },
    "07279": {
        "name": "Hương Vĩ",
        "type": "xa",
        "slug": "huong-vi",
        "name_with_type": "Xã Hương Vĩ",
        "path": "Hương Vĩ, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Hương Vĩ, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07279",
        "parent_code": "215"
    },
    "07282": {
        "name": "Đồng Kỳ",
        "type": "xa",
        "slug": "dong-ky",
        "name_with_type": "Xã Đồng Kỳ",
        "path": "Đồng Kỳ, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Đồng Kỳ, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07282",
        "parent_code": "215"
    },
    "07285": {
        "name": "An Thượng",
        "type": "xa",
        "slug": "an-thuong",
        "name_with_type": "Xã An Thượng",
        "path": "An Thượng, Yên Thế, Bắc Giang",
        "path_with_type": "Xã An Thượng, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07285",
        "parent_code": "215"
    },
    "07288": {
        "name": "Phồn Xương",
        "type": "xa",
        "slug": "phon-xuong",
        "name_with_type": "Xã Phồn Xương",
        "path": "Phồn Xương, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Phồn Xương, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07288",
        "parent_code": "215"
    },
    "07291": {
        "name": "Tân Sỏi",
        "type": "xa",
        "slug": "tan-soi",
        "name_with_type": "Xã Tân Sỏi",
        "path": "Tân Sỏi, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Tân Sỏi, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07291",
        "parent_code": "215"
    },
    "07294": {
        "name": "Bố Hạ",
        "type": "xa",
        "slug": "bo-ha",
        "name_with_type": "Xã Bố Hạ",
        "path": "Bố Hạ, Yên Thế, Bắc Giang",
        "path_with_type": "Xã Bố Hạ, Huyện Yên Thế, Tỉnh Bắc Giang",
        "code": "07294",
        "parent_code": "215"
    },
    "07297": {
        "name": "Cao Thượng",
        "type": "thi-tran",
        "slug": "cao-thuong",
        "name_with_type": "Thị trấn Cao Thượng",
        "path": "Cao Thượng, Tân Yên, Bắc Giang",
        "path_with_type": "Thị trấn Cao Thượng, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07297",
        "parent_code": "216"
    },
    "07300": {
        "name": "Nhã Nam",
        "type": "thi-tran",
        "slug": "nha-nam",
        "name_with_type": "Thị trấn Nhã Nam",
        "path": "Nhã Nam, Tân Yên, Bắc Giang",
        "path_with_type": "Thị trấn Nhã Nam, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07300",
        "parent_code": "216"
    },
    "07303": {
        "name": "Lan Giới",
        "type": "xa",
        "slug": "lan-gioi",
        "name_with_type": "Xã Lan Giới",
        "path": "Lan Giới, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Lan Giới, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07303",
        "parent_code": "216"
    },
    "07306": {
        "name": "Nhã Nam",
        "type": "xa",
        "slug": "nha-nam",
        "name_with_type": "Xã Nhã Nam",
        "path": "Nhã Nam, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Nhã Nam, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07306",
        "parent_code": "216"
    },
    "07309": {
        "name": "Tân Trung",
        "type": "xa",
        "slug": "tan-trung",
        "name_with_type": "Xã Tân Trung",
        "path": "Tân Trung, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Tân Trung, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07309",
        "parent_code": "216"
    },
    "07312": {
        "name": "Đại Hóa",
        "type": "xa",
        "slug": "dai-hoa",
        "name_with_type": "Xã Đại Hóa",
        "path": "Đại Hóa, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Đại Hóa, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07312",
        "parent_code": "216"
    },
    "07315": {
        "name": "Quang Tiến",
        "type": "xa",
        "slug": "quang-tien",
        "name_with_type": "Xã Quang Tiến",
        "path": "Quang Tiến, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Quang Tiến, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07315",
        "parent_code": "216"
    },
    "07318": {
        "name": "Phúc Sơn",
        "type": "xa",
        "slug": "phuc-son",
        "name_with_type": "Xã Phúc Sơn",
        "path": "Phúc Sơn, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Phúc Sơn, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07318",
        "parent_code": "216"
    },
    "07321": {
        "name": "An Dương",
        "type": "xa",
        "slug": "an-duong",
        "name_with_type": "Xã An Dương",
        "path": "An Dương, Tân Yên, Bắc Giang",
        "path_with_type": "Xã An Dương, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07321",
        "parent_code": "216"
    },
    "07324": {
        "name": "Phúc Hòa",
        "type": "xa",
        "slug": "phuc-hoa",
        "name_with_type": "Xã Phúc Hòa",
        "path": "Phúc Hòa, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Phúc Hòa, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07324",
        "parent_code": "216"
    },
    "07327": {
        "name": "Liên Sơn",
        "type": "xa",
        "slug": "lien-son",
        "name_with_type": "Xã Liên Sơn",
        "path": "Liên Sơn, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Liên Sơn, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07327",
        "parent_code": "216"
    },
    "07330": {
        "name": "Hợp Đức",
        "type": "xa",
        "slug": "hop-duc",
        "name_with_type": "Xã Hợp Đức",
        "path": "Hợp Đức, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Hợp Đức, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07330",
        "parent_code": "216"
    },
    "07333": {
        "name": "Lam Cốt",
        "type": "xa",
        "slug": "lam-cot",
        "name_with_type": "Xã Lam Cốt",
        "path": "Lam Cốt, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Lam Cốt, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07333",
        "parent_code": "216"
    },
    "07336": {
        "name": "Cao Xá",
        "type": "xa",
        "slug": "cao-xa",
        "name_with_type": "Xã Cao Xá",
        "path": "Cao Xá, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Cao Xá, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07336",
        "parent_code": "216"
    },
    "07339": {
        "name": "Cao Thượng",
        "type": "xa",
        "slug": "cao-thuong",
        "name_with_type": "Xã Cao Thượng",
        "path": "Cao Thượng, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Cao Thượng, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07339",
        "parent_code": "216"
    },
    "07342": {
        "name": "Việt Ngọc",
        "type": "xa",
        "slug": "viet-ngoc",
        "name_with_type": "Xã Việt Ngọc",
        "path": "Việt Ngọc, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Việt Ngọc, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07342",
        "parent_code": "216"
    },
    "07345": {
        "name": "Song Vân",
        "type": "xa",
        "slug": "song-van",
        "name_with_type": "Xã Song Vân",
        "path": "Song Vân, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Song Vân, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07345",
        "parent_code": "216"
    },
    "07348": {
        "name": "Ngọc Châu",
        "type": "xa",
        "slug": "ngoc-chau",
        "name_with_type": "Xã Ngọc Châu",
        "path": "Ngọc Châu, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Ngọc Châu, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07348",
        "parent_code": "216"
    },
    "07351": {
        "name": "Ngọc Vân",
        "type": "xa",
        "slug": "ngoc-van",
        "name_with_type": "Xã Ngọc Vân",
        "path": "Ngọc Vân, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Ngọc Vân, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07351",
        "parent_code": "216"
    },
    "07354": {
        "name": "Việt Lập",
        "type": "xa",
        "slug": "viet-lap",
        "name_with_type": "Xã Việt Lập",
        "path": "Việt Lập, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Việt Lập, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07354",
        "parent_code": "216"
    },
    "07357": {
        "name": "Liên Chung",
        "type": "xa",
        "slug": "lien-chung",
        "name_with_type": "Xã Liên Chung",
        "path": "Liên Chung, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Liên Chung, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07357",
        "parent_code": "216"
    },
    "07360": {
        "name": "Ngọc Thiện",
        "type": "xa",
        "slug": "ngoc-thien",
        "name_with_type": "Xã Ngọc Thiện",
        "path": "Ngọc Thiện, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Ngọc Thiện, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07360",
        "parent_code": "216"
    },
    "07363": {
        "name": "Ngọc Lý",
        "type": "xa",
        "slug": "ngoc-ly",
        "name_with_type": "Xã Ngọc Lý",
        "path": "Ngọc Lý, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Ngọc Lý, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07363",
        "parent_code": "216"
    },
    "07366": {
        "name": "Quế Nham",
        "type": "xa",
        "slug": "que-nham",
        "name_with_type": "Xã Quế Nham",
        "path": "Quế Nham, Tân Yên, Bắc Giang",
        "path_with_type": "Xã Quế Nham, Huyện Tân Yên, Tỉnh Bắc Giang",
        "code": "07366",
        "parent_code": "216"
    },
    "07369": {
        "name": "Kép",
        "type": "thi-tran",
        "slug": "kep",
        "name_with_type": "Thị trấn Kép",
        "path": "Kép, Lạng Giang, Bắc Giang",
        "path_with_type": "Thị trấn Kép, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07369",
        "parent_code": "217"
    },
    "07375": {
        "name": "Vôi",
        "type": "thi-tran",
        "slug": "voi",
        "name_with_type": "Thị trấn Vôi",
        "path": "Vôi, Lạng Giang, Bắc Giang",
        "path_with_type": "Thị trấn Vôi, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07375",
        "parent_code": "217"
    },
    "07378": {
        "name": "Nghĩa Hòa",
        "type": "xa",
        "slug": "nghia-hoa",
        "name_with_type": "Xã Nghĩa Hòa",
        "path": "Nghĩa Hòa, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Nghĩa Hòa, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07378",
        "parent_code": "217"
    },
    "07381": {
        "name": "Nghĩa Hưng",
        "type": "xa",
        "slug": "nghia-hung",
        "name_with_type": "Xã Nghĩa Hưng",
        "path": "Nghĩa Hưng, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Nghĩa Hưng, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07381",
        "parent_code": "217"
    },
    "07384": {
        "name": "Quang Thịnh",
        "type": "xa",
        "slug": "quang-thinh",
        "name_with_type": "Xã Quang Thịnh",
        "path": "Quang Thịnh, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Quang Thịnh, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07384",
        "parent_code": "217"
    },
    "07387": {
        "name": "Hương Sơn",
        "type": "xa",
        "slug": "huong-son",
        "name_with_type": "Xã Hương Sơn",
        "path": "Hương Sơn, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Hương Sơn, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07387",
        "parent_code": "217"
    },
    "07390": {
        "name": "Đào Mỹ",
        "type": "xa",
        "slug": "dao-my",
        "name_with_type": "Xã Đào Mỹ",
        "path": "Đào Mỹ, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Đào Mỹ, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07390",
        "parent_code": "217"
    },
    "07393": {
        "name": "Tiên Lục",
        "type": "xa",
        "slug": "tien-luc",
        "name_with_type": "Xã Tiên Lục",
        "path": "Tiên Lục, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Tiên Lục, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07393",
        "parent_code": "217"
    },
    "07396": {
        "name": "An Hà",
        "type": "xa",
        "slug": "an-ha",
        "name_with_type": "Xã An Hà",
        "path": "An Hà, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã An Hà, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07396",
        "parent_code": "217"
    },
    "07399": {
        "name": "Tân Thịnh",
        "type": "xa",
        "slug": "tan-thinh",
        "name_with_type": "Xã Tân Thịnh",
        "path": "Tân Thịnh, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Tân Thịnh, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07399",
        "parent_code": "217"
    },
    "07402": {
        "name": "Mỹ Hà",
        "type": "xa",
        "slug": "my-ha",
        "name_with_type": "Xã Mỹ Hà",
        "path": "Mỹ Hà, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Mỹ Hà, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07402",
        "parent_code": "217"
    },
    "07405": {
        "name": "Hương Lạc",
        "type": "xa",
        "slug": "huong-lac",
        "name_with_type": "Xã Hương Lạc",
        "path": "Hương Lạc, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Hương Lạc, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07405",
        "parent_code": "217"
    },
    "07408": {
        "name": "Dương Đức",
        "type": "xa",
        "slug": "duong-duc",
        "name_with_type": "Xã Dương Đức",
        "path": "Dương Đức, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Dương Đức, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07408",
        "parent_code": "217"
    },
    "07411": {
        "name": "Tân Thanh",
        "type": "xa",
        "slug": "tan-thanh",
        "name_with_type": "Xã Tân Thanh",
        "path": "Tân Thanh, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Tân Thanh, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07411",
        "parent_code": "217"
    },
    "07414": {
        "name": "Yên Mỹ",
        "type": "xa",
        "slug": "yen-my",
        "name_with_type": "Xã Yên Mỹ",
        "path": "Yên Mỹ, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Yên Mỹ, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07414",
        "parent_code": "217"
    },
    "07417": {
        "name": "Tân Hưng",
        "type": "xa",
        "slug": "tan-hung",
        "name_with_type": "Xã Tân Hưng",
        "path": "Tân Hưng, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Tân Hưng, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07417",
        "parent_code": "217"
    },
    "07420": {
        "name": "Mỹ Thái",
        "type": "xa",
        "slug": "my-thai",
        "name_with_type": "Xã Mỹ Thái",
        "path": "Mỹ Thái, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Mỹ Thái, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07420",
        "parent_code": "217"
    },
    "07423": {
        "name": "Phi Mô",
        "type": "xa",
        "slug": "phi-mo",
        "name_with_type": "Xã Phi Mô",
        "path": "Phi Mô, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Phi Mô, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07423",
        "parent_code": "217"
    },
    "07426": {
        "name": "Xương Lâm",
        "type": "xa",
        "slug": "xuong-lam",
        "name_with_type": "Xã Xương Lâm",
        "path": "Xương Lâm, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Xương Lâm, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07426",
        "parent_code": "217"
    },
    "07429": {
        "name": "Xuân Hương",
        "type": "xa",
        "slug": "xuan-huong",
        "name_with_type": "Xã Xuân Hương",
        "path": "Xuân Hương, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Xuân Hương, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07429",
        "parent_code": "217"
    },
    "07432": {
        "name": "Tân Dĩnh",
        "type": "xa",
        "slug": "tan-dinh",
        "name_with_type": "Xã Tân Dĩnh",
        "path": "Tân Dĩnh, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Tân Dĩnh, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07432",
        "parent_code": "217"
    },
    "07435": {
        "name": "Đại Lâm",
        "type": "xa",
        "slug": "dai-lam",
        "name_with_type": "Xã Đại Lâm",
        "path": "Đại Lâm, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Đại Lâm, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07435",
        "parent_code": "217"
    },
    "07438": {
        "name": "Thái Đào",
        "type": "xa",
        "slug": "thai-dao",
        "name_with_type": "Xã Thái Đào",
        "path": "Thái Đào, Lạng Giang, Bắc Giang",
        "path_with_type": "Xã Thái Đào, Huyện Lạng Giang, Tỉnh Bắc Giang",
        "code": "07438",
        "parent_code": "217"
    },
    "07444": {
        "name": "Đồi Ngô",
        "type": "thi-tran",
        "slug": "doi-ngo",
        "name_with_type": "Thị trấn Đồi Ngô",
        "path": "Đồi Ngô, Lục Nam, Bắc Giang",
        "path_with_type": "Thị trấn Đồi Ngô, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07444",
        "parent_code": "218"
    },
    "07447": {
        "name": "Lục Nam",
        "type": "thi-tran",
        "slug": "luc-nam",
        "name_with_type": "Thị trấn Lục Nam",
        "path": "Lục Nam, Lục Nam, Bắc Giang",
        "path_with_type": "Thị trấn Lục Nam, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07447",
        "parent_code": "218"
    },
    "07450": {
        "name": "Đông Hưng",
        "type": "xa",
        "slug": "dong-hung",
        "name_with_type": "Xã Đông Hưng",
        "path": "Đông Hưng, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Đông Hưng, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07450",
        "parent_code": "218"
    },
    "07453": {
        "name": "Đông Phú",
        "type": "xa",
        "slug": "dong-phu",
        "name_with_type": "Xã Đông Phú",
        "path": "Đông Phú, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Đông Phú, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07453",
        "parent_code": "218"
    },
    "07456": {
        "name": "Tam Dị",
        "type": "xa",
        "slug": "tam-di",
        "name_with_type": "Xã Tam Dị",
        "path": "Tam Dị, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Tam Dị, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07456",
        "parent_code": "218"
    },
    "07459": {
        "name": "Bảo Sơn",
        "type": "xa",
        "slug": "bao-son",
        "name_with_type": "Xã Bảo Sơn",
        "path": "Bảo Sơn, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Bảo Sơn, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07459",
        "parent_code": "218"
    },
    "07462": {
        "name": "Bảo Đài",
        "type": "xa",
        "slug": "bao-dai",
        "name_with_type": "Xã Bảo Đài",
        "path": "Bảo Đài, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Bảo Đài, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07462",
        "parent_code": "218"
    },
    "07465": {
        "name": "Thanh Lâm",
        "type": "xa",
        "slug": "thanh-lam",
        "name_with_type": "Xã Thanh Lâm",
        "path": "Thanh Lâm, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Thanh Lâm, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07465",
        "parent_code": "218"
    },
    "07468": {
        "name": "Tiên Nha",
        "type": "xa",
        "slug": "tien-nha",
        "name_with_type": "Xã Tiên Nha",
        "path": "Tiên Nha, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Tiên Nha, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07468",
        "parent_code": "218"
    },
    "07471": {
        "name": "Trường Giang",
        "type": "xa",
        "slug": "truong-giang",
        "name_with_type": "Xã Trường Giang",
        "path": "Trường Giang, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Trường Giang, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07471",
        "parent_code": "218"
    },
    "07474": {
        "name": "Tiên Hưng",
        "type": "xa",
        "slug": "tien-hung",
        "name_with_type": "Xã Tiên Hưng",
        "path": "Tiên Hưng, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Tiên Hưng, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07474",
        "parent_code": "218"
    },
    "07477": {
        "name": "Phương Sơn",
        "type": "xa",
        "slug": "phuong-son",
        "name_with_type": "Xã Phương Sơn",
        "path": "Phương Sơn, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Phương Sơn, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07477",
        "parent_code": "218"
    },
    "07480": {
        "name": "Chu Điện",
        "type": "xa",
        "slug": "chu-dien",
        "name_with_type": "Xã Chu Điện",
        "path": "Chu Điện, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Chu Điện, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07480",
        "parent_code": "218"
    },
    "07483": {
        "name": "Cương Sơn",
        "type": "xa",
        "slug": "cuong-son",
        "name_with_type": "Xã Cương Sơn",
        "path": "Cương Sơn, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Cương Sơn, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07483",
        "parent_code": "218"
    },
    "07486": {
        "name": "Nghĩa Phương",
        "type": "xa",
        "slug": "nghia-phuong",
        "name_with_type": "Xã Nghĩa Phương",
        "path": "Nghĩa Phương, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Nghĩa Phương, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07486",
        "parent_code": "218"
    },
    "07489": {
        "name": "Vô Tranh",
        "type": "xa",
        "slug": "vo-tranh",
        "name_with_type": "Xã Vô Tranh",
        "path": "Vô Tranh, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Vô Tranh, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07489",
        "parent_code": "218"
    },
    "07492": {
        "name": "Bình Sơn",
        "type": "xa",
        "slug": "binh-son",
        "name_with_type": "Xã Bình Sơn",
        "path": "Bình Sơn, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Bình Sơn, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07492",
        "parent_code": "218"
    },
    "07495": {
        "name": "Lan Mẫu",
        "type": "xa",
        "slug": "lan-mau",
        "name_with_type": "Xã Lan Mẫu",
        "path": "Lan Mẫu, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Lan Mẫu, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07495",
        "parent_code": "218"
    },
    "07498": {
        "name": "Yên Sơn",
        "type": "xa",
        "slug": "yen-son",
        "name_with_type": "Xã Yên Sơn",
        "path": "Yên Sơn, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Yên Sơn, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07498",
        "parent_code": "218"
    },
    "07501": {
        "name": "Khám Lạng",
        "type": "xa",
        "slug": "kham-lang",
        "name_with_type": "Xã Khám Lạng",
        "path": "Khám Lạng, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Khám Lạng, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07501",
        "parent_code": "218"
    },
    "07504": {
        "name": "Huyền Sơn",
        "type": "xa",
        "slug": "huyen-son",
        "name_with_type": "Xã Huyền Sơn",
        "path": "Huyền Sơn, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Huyền Sơn, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07504",
        "parent_code": "218"
    },
    "07507": {
        "name": "Trường Sơn",
        "type": "xa",
        "slug": "truong-son",
        "name_with_type": "Xã Trường Sơn",
        "path": "Trường Sơn, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Trường Sơn, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07507",
        "parent_code": "218"
    },
    "07510": {
        "name": "Lục Sơn",
        "type": "xa",
        "slug": "luc-son",
        "name_with_type": "Xã Lục Sơn",
        "path": "Lục Sơn, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Lục Sơn, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07510",
        "parent_code": "218"
    },
    "07513": {
        "name": "Bắc Lũng",
        "type": "xa",
        "slug": "bac-lung",
        "name_with_type": "Xã Bắc Lũng",
        "path": "Bắc Lũng, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Bắc Lũng, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07513",
        "parent_code": "218"
    },
    "07516": {
        "name": "Vũ Xá",
        "type": "xa",
        "slug": "vu-xa",
        "name_with_type": "Xã Vũ Xá",
        "path": "Vũ Xá, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Vũ Xá, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07516",
        "parent_code": "218"
    },
    "07519": {
        "name": "Cẩm Lý",
        "type": "xa",
        "slug": "cam-ly",
        "name_with_type": "Xã Cẩm Lý",
        "path": "Cẩm Lý, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Cẩm Lý, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07519",
        "parent_code": "218"
    },
    "07522": {
        "name": "Đan Hội",
        "type": "xa",
        "slug": "dan-hoi",
        "name_with_type": "Xã Đan Hội",
        "path": "Đan Hội, Lục Nam, Bắc Giang",
        "path_with_type": "Xã Đan Hội, Huyện Lục Nam, Tỉnh Bắc Giang",
        "code": "07522",
        "parent_code": "218"
    },
    "07525": {
        "name": "Chũ",
        "type": "thi-tran",
        "slug": "chu",
        "name_with_type": "Thị trấn Chũ",
        "path": "Chũ, Lục Ngạn, Bắc Giang",
        "path_with_type": "Thị trấn Chũ, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07525",
        "parent_code": "219"
    },
    "07528": {
        "name": "Cấm Sơn",
        "type": "xa",
        "slug": "cam-son",
        "name_with_type": "Xã Cấm Sơn",
        "path": "Cấm Sơn, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Cấm Sơn, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07528",
        "parent_code": "219"
    },
    "07531": {
        "name": "Tân Sơn",
        "type": "xa",
        "slug": "tan-son",
        "name_with_type": "Xã Tân Sơn",
        "path": "Tân Sơn, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Tân Sơn, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07531",
        "parent_code": "219"
    },
    "07534": {
        "name": "Phong Minh",
        "type": "xa",
        "slug": "phong-minh",
        "name_with_type": "Xã Phong Minh",
        "path": "Phong Minh, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Phong Minh, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07534",
        "parent_code": "219"
    },
    "07537": {
        "name": "Phong Vân",
        "type": "xa",
        "slug": "phong-van",
        "name_with_type": "Xã Phong Vân",
        "path": "Phong Vân, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Phong Vân, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07537",
        "parent_code": "219"
    },
    "07540": {
        "name": "Xa Lý",
        "type": "xa",
        "slug": "xa-ly",
        "name_with_type": "Xã Xa Lý",
        "path": "Xa Lý, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Xa Lý, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07540",
        "parent_code": "219"
    },
    "07543": {
        "name": "Hộ Đáp",
        "type": "xa",
        "slug": "ho-dap",
        "name_with_type": "Xã Hộ Đáp",
        "path": "Hộ Đáp, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Hộ Đáp, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07543",
        "parent_code": "219"
    },
    "07546": {
        "name": "Sơn Hải",
        "type": "xa",
        "slug": "son-hai",
        "name_with_type": "Xã Sơn Hải",
        "path": "Sơn Hải, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Sơn Hải, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07546",
        "parent_code": "219"
    },
    "07549": {
        "name": "Thanh Hải",
        "type": "xa",
        "slug": "thanh-hai",
        "name_with_type": "Xã Thanh Hải",
        "path": "Thanh Hải, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Thanh Hải, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07549",
        "parent_code": "219"
    },
    "07552": {
        "name": "Kiên Lao",
        "type": "xa",
        "slug": "kien-lao",
        "name_with_type": "Xã Kiên Lao",
        "path": "Kiên Lao, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Kiên Lao, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07552",
        "parent_code": "219"
    },
    "07555": {
        "name": "Biên Sơn",
        "type": "xa",
        "slug": "bien-son",
        "name_with_type": "Xã Biên Sơn",
        "path": "Biên Sơn, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Biên Sơn, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07555",
        "parent_code": "219"
    },
    "07558": {
        "name": "Kiên Thành",
        "type": "xa",
        "slug": "kien-thanh",
        "name_with_type": "Xã Kiên Thành",
        "path": "Kiên Thành, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Kiên Thành, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07558",
        "parent_code": "219"
    },
    "07561": {
        "name": "Hồng Giang",
        "type": "xa",
        "slug": "hong-giang",
        "name_with_type": "Xã Hồng Giang",
        "path": "Hồng Giang, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Hồng Giang, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07561",
        "parent_code": "219"
    },
    "07564": {
        "name": "Kim Sơn",
        "type": "xa",
        "slug": "kim-son",
        "name_with_type": "Xã Kim Sơn",
        "path": "Kim Sơn, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Kim Sơn, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07564",
        "parent_code": "219"
    },
    "07567": {
        "name": "Tân Hoa",
        "type": "xa",
        "slug": "tan-hoa",
        "name_with_type": "Xã Tân Hoa",
        "path": "Tân Hoa, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Tân Hoa, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07567",
        "parent_code": "219"
    },
    "07570": {
        "name": "Giáp Sơn",
        "type": "xa",
        "slug": "giap-son",
        "name_with_type": "Xã Giáp Sơn",
        "path": "Giáp Sơn, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Giáp Sơn, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07570",
        "parent_code": "219"
    },
    "07573": {
        "name": "Biển Động",
        "type": "xa",
        "slug": "bien-dong",
        "name_with_type": "Xã Biển Động",
        "path": "Biển Động, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Biển Động, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07573",
        "parent_code": "219"
    },
    "07576": {
        "name": "Quý Sơn",
        "type": "xa",
        "slug": "quy-son",
        "name_with_type": "Xã Quý Sơn",
        "path": "Quý Sơn, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Quý Sơn, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07576",
        "parent_code": "219"
    },
    "07579": {
        "name": "Trù Hựu",
        "type": "xa",
        "slug": "tru-huu",
        "name_with_type": "Xã Trù Hựu",
        "path": "Trù Hựu, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Trù Hựu, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07579",
        "parent_code": "219"
    },
    "07582": {
        "name": "Phì Điền",
        "type": "xa",
        "slug": "phi-dien",
        "name_with_type": "Xã Phì Điền",
        "path": "Phì Điền, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Phì Điền, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07582",
        "parent_code": "219"
    },
    "07585": {
        "name": "Nghĩa Hồ",
        "type": "xa",
        "slug": "nghia-ho",
        "name_with_type": "Xã Nghĩa Hồ",
        "path": "Nghĩa Hồ, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Nghĩa Hồ, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07585",
        "parent_code": "219"
    },
    "07588": {
        "name": "Tân Quang",
        "type": "xa",
        "slug": "tan-quang",
        "name_with_type": "Xã Tân Quang",
        "path": "Tân Quang, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Tân Quang, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07588",
        "parent_code": "219"
    },
    "07591": {
        "name": "Đồng Cốc",
        "type": "xa",
        "slug": "dong-coc",
        "name_with_type": "Xã Đồng Cốc",
        "path": "Đồng Cốc, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Đồng Cốc, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07591",
        "parent_code": "219"
    },
    "07594": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Tân Lập, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07594",
        "parent_code": "219"
    },
    "07597": {
        "name": "Phú Nhuận",
        "type": "xa",
        "slug": "phu-nhuan",
        "name_with_type": "Xã Phú Nhuận",
        "path": "Phú Nhuận, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Phú Nhuận, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07597",
        "parent_code": "219"
    },
    "07600": {
        "name": "Mỹ An",
        "type": "xa",
        "slug": "my-an",
        "name_with_type": "Xã Mỹ An",
        "path": "Mỹ An, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Mỹ An, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07600",
        "parent_code": "219"
    },
    "07603": {
        "name": "Nam Dương",
        "type": "xa",
        "slug": "nam-duong",
        "name_with_type": "Xã Nam Dương",
        "path": "Nam Dương, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Nam Dương, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07603",
        "parent_code": "219"
    },
    "07606": {
        "name": "Tân Mộc",
        "type": "xa",
        "slug": "tan-moc",
        "name_with_type": "Xã Tân Mộc",
        "path": "Tân Mộc, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Tân Mộc, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07606",
        "parent_code": "219"
    },
    "07609": {
        "name": "Đèo Gia",
        "type": "xa",
        "slug": "deo-gia",
        "name_with_type": "Xã Đèo Gia",
        "path": "Đèo Gia, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Đèo Gia, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07609",
        "parent_code": "219"
    },
    "07612": {
        "name": "Phượng Sơn",
        "type": "xa",
        "slug": "phuong-son",
        "name_with_type": "Xã Phượng Sơn",
        "path": "Phượng Sơn, Lục Ngạn, Bắc Giang",
        "path_with_type": "Xã Phượng Sơn, Huyện Lục Ngạn, Tỉnh Bắc Giang",
        "code": "07612",
        "parent_code": "219"
    },
    "07615": {
        "name": "An Châu",
        "type": "thi-tran",
        "slug": "an-chau",
        "name_with_type": "Thị trấn An Châu",
        "path": "An Châu, Sơn Động, Bắc Giang",
        "path_with_type": "Thị trấn An Châu, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07615",
        "parent_code": "220"
    },
    "07616": {
        "name": "Thanh Sơn",
        "type": "thi-tran",
        "slug": "thanh-son",
        "name_with_type": "Thị trấn Thanh Sơn",
        "path": "Thanh Sơn, Sơn Động, Bắc Giang",
        "path_with_type": "Thị trấn Thanh Sơn, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07616",
        "parent_code": "220"
    },
    "07618": {
        "name": "Thạch Sơn",
        "type": "xa",
        "slug": "thach-son",
        "name_with_type": "Xã Thạch Sơn",
        "path": "Thạch Sơn, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Thạch Sơn, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07618",
        "parent_code": "220"
    },
    "07621": {
        "name": "Vân Sơn",
        "type": "xa",
        "slug": "van-son",
        "name_with_type": "Xã Vân Sơn",
        "path": "Vân Sơn, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Vân Sơn, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07621",
        "parent_code": "220"
    },
    "07624": {
        "name": "Hữu Sản",
        "type": "xa",
        "slug": "huu-san",
        "name_with_type": "Xã Hữu Sản",
        "path": "Hữu Sản, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Hữu Sản, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07624",
        "parent_code": "220"
    },
    "07627": {
        "name": "Quế Sơn",
        "type": "xa",
        "slug": "que-son",
        "name_with_type": "Xã Quế Sơn",
        "path": "Quế Sơn, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Quế Sơn, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07627",
        "parent_code": "220"
    },
    "07630": {
        "name": "Phúc Thắng",
        "type": "xa",
        "slug": "phuc-thang",
        "name_with_type": "Xã Phúc Thắng",
        "path": "Phúc Thắng, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Phúc Thắng, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07630",
        "parent_code": "220"
    },
    "07633": {
        "name": "Chiên Sơn",
        "type": "xa",
        "slug": "chien-son",
        "name_with_type": "Xã Chiên Sơn",
        "path": "Chiên Sơn, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Chiên Sơn, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07633",
        "parent_code": "220"
    },
    "07636": {
        "name": "Giáo Liêm",
        "type": "xa",
        "slug": "giao-liem",
        "name_with_type": "Xã Giáo Liêm",
        "path": "Giáo Liêm, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Giáo Liêm, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07636",
        "parent_code": "220"
    },
    "07639": {
        "name": "Vĩnh Khương",
        "type": "xa",
        "slug": "vinh-khuong",
        "name_with_type": "Xã Vĩnh Khương",
        "path": "Vĩnh Khương, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Vĩnh Khương, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07639",
        "parent_code": "220"
    },
    "07642": {
        "name": "Cẩm Đàn",
        "type": "xa",
        "slug": "cam-dan",
        "name_with_type": "Xã Cẩm Đàn",
        "path": "Cẩm Đàn, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Cẩm Đàn, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07642",
        "parent_code": "220"
    },
    "07645": {
        "name": "An Lạc",
        "type": "xa",
        "slug": "an-lac",
        "name_with_type": "Xã An Lạc",
        "path": "An Lạc, Sơn Động, Bắc Giang",
        "path_with_type": "Xã An Lạc, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07645",
        "parent_code": "220"
    },
    "07648": {
        "name": "An Lập",
        "type": "xa",
        "slug": "an-lap",
        "name_with_type": "Xã An Lập",
        "path": "An Lập, Sơn Động, Bắc Giang",
        "path_with_type": "Xã An Lập, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07648",
        "parent_code": "220"
    },
    "07651": {
        "name": "Yên Định",
        "type": "xa",
        "slug": "yen-dinh",
        "name_with_type": "Xã Yên Định",
        "path": "Yên Định, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Yên Định, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07651",
        "parent_code": "220"
    },
    "07654": {
        "name": "Lệ Viễn",
        "type": "xa",
        "slug": "le-vien",
        "name_with_type": "Xã Lệ Viễn",
        "path": "Lệ Viễn, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Lệ Viễn, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07654",
        "parent_code": "220"
    },
    "07657": {
        "name": "An Châu",
        "type": "xa",
        "slug": "an-chau",
        "name_with_type": "Xã An Châu",
        "path": "An Châu, Sơn Động, Bắc Giang",
        "path_with_type": "Xã An Châu, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07657",
        "parent_code": "220"
    },
    "07660": {
        "name": "An Bá",
        "type": "xa",
        "slug": "an-ba",
        "name_with_type": "Xã An Bá",
        "path": "An Bá, Sơn Động, Bắc Giang",
        "path_with_type": "Xã An Bá, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07660",
        "parent_code": "220"
    },
    "07663": {
        "name": "Tuấn Đạo",
        "type": "xa",
        "slug": "tuan-dao",
        "name_with_type": "Xã Tuấn Đạo",
        "path": "Tuấn Đạo, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Tuấn Đạo, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07663",
        "parent_code": "220"
    },
    "07666": {
        "name": "Dương Hưu",
        "type": "xa",
        "slug": "duong-huu",
        "name_with_type": "Xã Dương Hưu",
        "path": "Dương Hưu, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Dương Hưu, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07666",
        "parent_code": "220"
    },
    "07669": {
        "name": "Bồng Am",
        "type": "xa",
        "slug": "bong-am",
        "name_with_type": "Xã Bồng Am",
        "path": "Bồng Am, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Bồng Am, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07669",
        "parent_code": "220"
    },
    "07672": {
        "name": "Long Sơn",
        "type": "xa",
        "slug": "long-son",
        "name_with_type": "Xã Long Sơn",
        "path": "Long Sơn, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Long Sơn, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07672",
        "parent_code": "220"
    },
    "07675": {
        "name": "Tuấn Mậu",
        "type": "xa",
        "slug": "tuan-mau",
        "name_with_type": "Xã Tuấn Mậu",
        "path": "Tuấn Mậu, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Tuấn Mậu, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07675",
        "parent_code": "220"
    },
    "07678": {
        "name": "Thanh Luận",
        "type": "xa",
        "slug": "thanh-luan",
        "name_with_type": "Xã Thanh Luận",
        "path": "Thanh Luận, Sơn Động, Bắc Giang",
        "path_with_type": "Xã Thanh Luận, Huyện Sơn Động, Tỉnh Bắc Giang",
        "code": "07678",
        "parent_code": "220"
    },
    "07681": {
        "name": "Neo",
        "type": "thi-tran",
        "slug": "neo",
        "name_with_type": "Thị trấn Neo",
        "path": "Neo, Yên Dũng, Bắc Giang",
        "path_with_type": "Thị trấn Neo, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07681",
        "parent_code": "221"
    },
    "07682": {
        "name": "Tân Dân",
        "type": "thi-tran",
        "slug": "tan-dan",
        "name_with_type": "Thị trấn Tân Dân",
        "path": "Tân Dân, Yên Dũng, Bắc Giang",
        "path_with_type": "Thị trấn Tân Dân, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07682",
        "parent_code": "221"
    },
    "07684": {
        "name": "Lão Hộ",
        "type": "xa",
        "slug": "lao-ho",
        "name_with_type": "Xã Lão Hộ",
        "path": "Lão Hộ, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Lão Hộ, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07684",
        "parent_code": "221"
    },
    "07690": {
        "name": "Hương Gián",
        "type": "xa",
        "slug": "huong-gian",
        "name_with_type": "Xã Hương Gián",
        "path": "Hương Gián, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Hương Gián, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07690",
        "parent_code": "221"
    },
    "07693": {
        "name": "Tân An",
        "type": "xa",
        "slug": "tan-an",
        "name_with_type": "Xã Tân An",
        "path": "Tân An, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Tân An, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07693",
        "parent_code": "221"
    },
    "07702": {
        "name": "Quỳnh Sơn",
        "type": "xa",
        "slug": "quynh-son",
        "name_with_type": "Xã Quỳnh Sơn",
        "path": "Quỳnh Sơn, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Quỳnh Sơn, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07702",
        "parent_code": "221"
    },
    "07708": {
        "name": "Nội Hoàng",
        "type": "xa",
        "slug": "noi-hoang",
        "name_with_type": "Xã Nội Hoàng",
        "path": "Nội Hoàng, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Nội Hoàng, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07708",
        "parent_code": "221"
    },
    "07711": {
        "name": "Tiền Phong",
        "type": "xa",
        "slug": "tien-phong",
        "name_with_type": "Xã Tiền Phong",
        "path": "Tiền Phong, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Tiền Phong, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07711",
        "parent_code": "221"
    },
    "07714": {
        "name": "Xuân Phú",
        "type": "xa",
        "slug": "xuan-phu",
        "name_with_type": "Xã Xuân Phú",
        "path": "Xuân Phú, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Xuân Phú, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07714",
        "parent_code": "221"
    },
    "07717": {
        "name": "Tân Liễu",
        "type": "xa",
        "slug": "tan-lieu",
        "name_with_type": "Xã Tân Liễu",
        "path": "Tân Liễu, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Tân Liễu, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07717",
        "parent_code": "221"
    },
    "07720": {
        "name": "Trí Yên",
        "type": "xa",
        "slug": "tri-yen",
        "name_with_type": "Xã Trí Yên",
        "path": "Trí Yên, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Trí Yên, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07720",
        "parent_code": "221"
    },
    "07723": {
        "name": "Lãng Sơn",
        "type": "xa",
        "slug": "lang-son",
        "name_with_type": "Xã Lãng Sơn",
        "path": "Lãng Sơn, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Lãng Sơn, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07723",
        "parent_code": "221"
    },
    "07726": {
        "name": "Yên Lư",
        "type": "xa",
        "slug": "yen-lu",
        "name_with_type": "Xã Yên Lư",
        "path": "Yên Lư, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Yên Lư, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07726",
        "parent_code": "221"
    },
    "07729": {
        "name": "Tiến Dũng",
        "type": "xa",
        "slug": "tien-dung",
        "name_with_type": "Xã Tiến Dũng",
        "path": "Tiến Dũng, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Tiến Dũng, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07729",
        "parent_code": "221"
    },
    "07732": {
        "name": "Nham Sơn",
        "type": "xa",
        "slug": "nham-son",
        "name_with_type": "Xã Nham Sơn",
        "path": "Nham Sơn, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Nham Sơn, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07732",
        "parent_code": "221"
    },
    "07735": {
        "name": "Đức Giang",
        "type": "xa",
        "slug": "duc-giang",
        "name_with_type": "Xã Đức Giang",
        "path": "Đức Giang, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Đức Giang, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07735",
        "parent_code": "221"
    },
    "07738": {
        "name": "Cảnh Thụy",
        "type": "xa",
        "slug": "canh-thuy",
        "name_with_type": "Xã Cảnh Thụy",
        "path": "Cảnh Thụy, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Cảnh Thụy, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07738",
        "parent_code": "221"
    },
    "07741": {
        "name": "Tư Mại",
        "type": "xa",
        "slug": "tu-mai",
        "name_with_type": "Xã Tư Mại",
        "path": "Tư Mại, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Tư Mại, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07741",
        "parent_code": "221"
    },
    "07744": {
        "name": "Thắng Cương",
        "type": "xa",
        "slug": "thang-cuong",
        "name_with_type": "Xã Thắng Cương",
        "path": "Thắng Cương, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Thắng Cương, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07744",
        "parent_code": "221"
    },
    "07747": {
        "name": "Đồng Việt",
        "type": "xa",
        "slug": "dong-viet",
        "name_with_type": "Xã Đồng Việt",
        "path": "Đồng Việt, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Đồng Việt, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07747",
        "parent_code": "221"
    },
    "07750": {
        "name": "Đồng Phúc",
        "type": "xa",
        "slug": "dong-phuc",
        "name_with_type": "Xã Đồng Phúc",
        "path": "Đồng Phúc, Yên Dũng, Bắc Giang",
        "path_with_type": "Xã Đồng Phúc, Huyện Yên Dũng, Tỉnh Bắc Giang",
        "code": "07750",
        "parent_code": "221"
    },
    "07753": {
        "name": "Bích Động",
        "type": "thi-tran",
        "slug": "bich-dong",
        "name_with_type": "Thị trấn  Bích Động",
        "path": "Bích Động, Việt Yên, Bắc Giang",
        "path_with_type": "Thị trấn  Bích ��ộng, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07753",
        "parent_code": "222"
    },
    "07756": {
        "name": "Nếnh",
        "type": "thi-tran",
        "slug": "nenh",
        "name_with_type": "Thị trấn Nếnh",
        "path": "Nếnh, Việt Yên, Bắc Giang",
        "path_with_type": "Thị trấn Nếnh, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07756",
        "parent_code": "222"
    },
    "07759": {
        "name": "Thượng Lan",
        "type": "xa",
        "slug": "thuong-lan",
        "name_with_type": "Xã Thượng Lan",
        "path": "Thượng Lan, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Thượng Lan, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07759",
        "parent_code": "222"
    },
    "07762": {
        "name": "Việt Tiến",
        "type": "xa",
        "slug": "viet-tien",
        "name_with_type": "Xã Việt Tiến",
        "path": "Việt Tiến, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Việt Tiến, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07762",
        "parent_code": "222"
    },
    "07765": {
        "name": "Nghĩa Trung",
        "type": "xa",
        "slug": "nghia-trung",
        "name_with_type": "Xã Nghĩa Trung",
        "path": "Nghĩa Trung, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Nghĩa Trung, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07765",
        "parent_code": "222"
    },
    "07768": {
        "name": "Minh Đức",
        "type": "xa",
        "slug": "minh-duc",
        "name_with_type": "Xã Minh Đức",
        "path": "Minh Đức, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Minh Đức, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07768",
        "parent_code": "222"
    },
    "07771": {
        "name": "Hương Mai",
        "type": "xa",
        "slug": "huong-mai",
        "name_with_type": "Xã Hương Mai",
        "path": "Hương Mai, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Hương Mai, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07771",
        "parent_code": "222"
    },
    "07774": {
        "name": "Tự Lạn",
        "type": "xa",
        "slug": "tu-lan",
        "name_with_type": "Xã Tự Lạn",
        "path": "Tự Lạn, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Tự Lạn, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07774",
        "parent_code": "222"
    },
    "07777": {
        "name": "Bích Sơn",
        "type": "xa",
        "slug": "bich-son",
        "name_with_type": "Xã Bích Sơn",
        "path": "Bích Sơn, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Bích Sơn, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07777",
        "parent_code": "222"
    },
    "07780": {
        "name": "Trung Sơn",
        "type": "xa",
        "slug": "trung-son",
        "name_with_type": "Xã Trung Sơn",
        "path": "Trung Sơn, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Trung Sơn, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07780",
        "parent_code": "222"
    },
    "07783": {
        "name": "Hồng Thái",
        "type": "xa",
        "slug": "hong-thai",
        "name_with_type": "Xã Hồng Thái",
        "path": "Hồng Thái, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Hồng Thái, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07783",
        "parent_code": "222"
    },
    "07786": {
        "name": "Tiên Sơn",
        "type": "xa",
        "slug": "tien-son",
        "name_with_type": "Xã Tiên Sơn",
        "path": "Tiên Sơn, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Tiên Sơn, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07786",
        "parent_code": "222"
    },
    "07789": {
        "name": "Tăng Tiến",
        "type": "xa",
        "slug": "tang-tien",
        "name_with_type": "Xã Tăng Tiến",
        "path": "Tăng Tiến, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Tăng Tiến, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07789",
        "parent_code": "222"
    },
    "07792": {
        "name": "Quảng Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quảng Minh",
        "path": "Quảng Minh, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Quảng Minh, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07792",
        "parent_code": "222"
    },
    "07795": {
        "name": "Hoàng Ninh",
        "type": "xa",
        "slug": "hoang-ninh",
        "name_with_type": "Xã Hoàng Ninh",
        "path": "Hoàng Ninh, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Hoàng Ninh, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07795",
        "parent_code": "222"
    },
    "07798": {
        "name": "Ninh Sơn",
        "type": "xa",
        "slug": "ninh-son",
        "name_with_type": "Xã Ninh Sơn",
        "path": "Ninh Sơn, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Ninh Sơn, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07798",
        "parent_code": "222"
    },
    "07801": {
        "name": "Vân Trung",
        "type": "xa",
        "slug": "van-trung",
        "name_with_type": "Xã Vân Trung",
        "path": "Vân Trung, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Vân Trung, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07801",
        "parent_code": "222"
    },
    "07804": {
        "name": "Vân Hà",
        "type": "xa",
        "slug": "van-ha",
        "name_with_type": "Xã Vân Hà",
        "path": "Vân Hà, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Vân Hà, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07804",
        "parent_code": "222"
    },
    "07807": {
        "name": "Quang Châu",
        "type": "xa",
        "slug": "quang-chau",
        "name_with_type": "Xã Quang Châu",
        "path": "Quang Châu, Việt Yên, Bắc Giang",
        "path_with_type": "Xã Quang Châu, Huyện Việt Yên, Tỉnh Bắc Giang",
        "code": "07807",
        "parent_code": "222"
    },
    "07810": {
        "name": "Thắng",
        "type": "thi-tran",
        "slug": "thang",
        "name_with_type": "Thị trấn Thắng",
        "path": "Thắng, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Thị trấn Thắng, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07810",
        "parent_code": "223"
    },
    "07813": {
        "name": "Đồng Tân",
        "type": "xa",
        "slug": "dong-tan",
        "name_with_type": "Xã Đồng Tân",
        "path": "Đồng Tân, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Đồng Tân, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07813",
        "parent_code": "223"
    },
    "07816": {
        "name": "Thanh Vân",
        "type": "xa",
        "slug": "thanh-van",
        "name_with_type": "Xã Thanh Vân",
        "path": "Thanh Vân, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Thanh Vân, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07816",
        "parent_code": "223"
    },
    "07819": {
        "name": "Hoàng Lương",
        "type": "xa",
        "slug": "hoang-luong",
        "name_with_type": "Xã Hoàng Lương",
        "path": "Hoàng Lương, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Hoàng Lương, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07819",
        "parent_code": "223"
    },
    "07822": {
        "name": "Hoàng Vân",
        "type": "xa",
        "slug": "hoang-van",
        "name_with_type": "Xã Hoàng Vân",
        "path": "Hoàng Vân, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Hoàng Vân, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07822",
        "parent_code": "223"
    },
    "07825": {
        "name": "Hoàng Thanh",
        "type": "xa",
        "slug": "hoang-thanh",
        "name_with_type": "Xã Hoàng Thanh",
        "path": "Hoàng Thanh, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Hoàng Thanh, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07825",
        "parent_code": "223"
    },
    "07828": {
        "name": "Hoàng An",
        "type": "xa",
        "slug": "hoang-an",
        "name_with_type": "Xã Hoàng An",
        "path": "Hoàng An, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Hoàng An, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07828",
        "parent_code": "223"
    },
    "07831": {
        "name": "Ngọc Sơn",
        "type": "xa",
        "slug": "ngoc-son",
        "name_with_type": "Xã Ngọc Sơn",
        "path": "Ngọc Sơn, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Ngọc Sơn, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07831",
        "parent_code": "223"
    },
    "07834": {
        "name": "Thái Sơn",
        "type": "xa",
        "slug": "thai-son",
        "name_with_type": "Xã Thái Sơn",
        "path": "Thái Sơn, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Thái Sơn, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07834",
        "parent_code": "223"
    },
    "07837": {
        "name": "Hòa Sơn",
        "type": "xa",
        "slug": "hoa-son",
        "name_with_type": "Xã Hòa Sơn",
        "path": "Hòa Sơn, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Hòa Sơn, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07837",
        "parent_code": "223"
    },
    "07840": {
        "name": "Đức Thắng",
        "type": "xa",
        "slug": "duc-thang",
        "name_with_type": "Xã Đức Thắng",
        "path": "Đức Thắng, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Đức Thắng, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07840",
        "parent_code": "223"
    },
    "07843": {
        "name": "Quang Minh",
        "type": "xa",
        "slug": "quang-minh",
        "name_with_type": "Xã Quang Minh",
        "path": "Quang Minh, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Quang Minh, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07843",
        "parent_code": "223"
    },
    "07846": {
        "name": "Lương Phong",
        "type": "xa",
        "slug": "luong-phong",
        "name_with_type": "Xã Lương Phong",
        "path": "Lương Phong, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Lương Phong, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07846",
        "parent_code": "223"
    },
    "07849": {
        "name": "Hùng Sơn",
        "type": "xa",
        "slug": "hung-son",
        "name_with_type": "Xã Hùng Sơn",
        "path": "Hùng Sơn, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Hùng Sơn, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07849",
        "parent_code": "223"
    },
    "07852": {
        "name": "Đại Thành",
        "type": "xa",
        "slug": "dai-thanh",
        "name_with_type": "Xã Đại Thành",
        "path": "Đại Thành, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Đại Thành, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07852",
        "parent_code": "223"
    },
    "07855": {
        "name": "Thường Thắng",
        "type": "xa",
        "slug": "thuong-thang",
        "name_with_type": "Xã Thường Thắng",
        "path": "Thường Thắng, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Thường Thắng, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07855",
        "parent_code": "223"
    },
    "07858": {
        "name": "Hợp Thịnh",
        "type": "xa",
        "slug": "hop-thinh",
        "name_with_type": "Xã Hợp Thịnh",
        "path": "Hợp Thịnh, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Hợp Thịnh, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07858",
        "parent_code": "223"
    },
    "07861": {
        "name": "Danh Thắng",
        "type": "xa",
        "slug": "danh-thang",
        "name_with_type": "Xã Danh Thắng",
        "path": "Danh Thắng, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Danh Thắng, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07861",
        "parent_code": "223"
    },
    "07864": {
        "name": "Mai Trung",
        "type": "xa",
        "slug": "mai-trung",
        "name_with_type": "Xã Mai Trung",
        "path": "Mai Trung, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Mai Trung, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07864",
        "parent_code": "223"
    },
    "07867": {
        "name": "Đoan Bái",
        "type": "xa",
        "slug": "doan-bai",
        "name_with_type": "Xã Đoan Bái",
        "path": "Đoan Bái, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Đoan Bái, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07867",
        "parent_code": "223"
    },
    "07870": {
        "name": "Bắc Lý",
        "type": "xa",
        "slug": "bac-ly",
        "name_with_type": "Xã Bắc Lý",
        "path": "Bắc Lý, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Bắc Lý, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07870",
        "parent_code": "223"
    },
    "07873": {
        "name": "Xuân Cẩm",
        "type": "xa",
        "slug": "xuan-cam",
        "name_with_type": "Xã Xuân Cẩm",
        "path": "Xuân Cẩm, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Xuân Cẩm, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07873",
        "parent_code": "223"
    },
    "07876": {
        "name": "Hương Lâm",
        "type": "xa",
        "slug": "huong-lam",
        "name_with_type": "Xã Hương Lâm",
        "path": "Hương Lâm, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Hương Lâm, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07876",
        "parent_code": "223"
    },
    "07879": {
        "name": "Đông Lỗ",
        "type": "xa",
        "slug": "dong-lo",
        "name_with_type": "Xã Đông Lỗ",
        "path": "Đông Lỗ, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Đông Lỗ, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07879",
        "parent_code": "223"
    },
    "07882": {
        "name": "Châu Minh",
        "type": "xa",
        "slug": "chau-minh",
        "name_with_type": "Xã Châu Minh",
        "path": "Châu Minh, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Châu Minh, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07882",
        "parent_code": "223"
    },
    "07885": {
        "name": "Mai Đình",
        "type": "xa",
        "slug": "mai-dinh",
        "name_with_type": "Xã Mai Đình",
        "path": "Mai Đình, Hiệp Hòa, Bắc Giang",
        "path_with_type": "Xã Mai Đình, Huyện Hiệp Hòa, Tỉnh Bắc Giang",
        "code": "07885",
        "parent_code": "223"
    },
    "01834": {
        "name": "Nguyễn Thị Minh Khai",
        "type": "phuong",
        "slug": "nguyen-thi-minh-khai",
        "name_with_type": "Phường Nguyễn Thị Minh Khai",
        "path": "Nguyễn Thị Minh Khai, Bắc Kạn, Bắc Kạn",
        "path_with_type": "Phường Nguyễn Thị Minh Khai, Thành Phố Bắc Kạn, Tỉnh Bắc Kạn",
        "code": "01834",
        "parent_code": "058"
    },
    "01837": {
        "name": "Sông Cầu",
        "type": "phuong",
        "slug": "song-cau",
        "name_with_type": "Phường Sông Cầu",
        "path": "Sông Cầu, Bắc Kạn, Bắc Kạn",
        "path_with_type": "Phường Sông Cầu, Thành Phố Bắc Kạn, Tỉnh Bắc Kạn",
        "code": "01837",
        "parent_code": "058"
    },
    "01840": {
        "name": "Đức Xuân",
        "type": "phuong",
        "slug": "duc-xuan",
        "name_with_type": "Phường Đức Xuân",
        "path": "Đức Xuân, Bắc Kạn, Bắc Kạn",
        "path_with_type": "Phường Đức Xuân, Thành Phố Bắc Kạn, Tỉnh Bắc Kạn",
        "code": "01840",
        "parent_code": "058"
    },
    "01843": {
        "name": "Phùng Chí Kiên",
        "type": "phuong",
        "slug": "phung-chi-kien",
        "name_with_type": "Phường Phùng Chí Kiên",
        "path": "Phùng Chí Kiên, Bắc Kạn, Bắc Kạn",
        "path_with_type": "Phường Phùng Chí Kiên, Thành Phố Bắc Kạn, Tỉnh Bắc Kạn",
        "code": "01843",
        "parent_code": "058"
    },
    "01846": {
        "name": "Huyền Tụng",
        "type": "phuong",
        "slug": "huyen-tung",
        "name_with_type": "Phường Huyền Tụng",
        "path": "Huyền Tụng, Bắc Kạn, Bắc Kạn",
        "path_with_type": "Phường Huyền Tụng, Thành Phố Bắc Kạn, Tỉnh Bắc Kạn",
        "code": "01846",
        "parent_code": "058"
    },
    "01849": {
        "name": "Dương Quang",
        "type": "xa",
        "slug": "duong-quang",
        "name_with_type": "Xã Dương Quang",
        "path": "Dương Quang, Bắc Kạn, Bắc Kạn",
        "path_with_type": "Xã Dương Quang, Thành Phố Bắc Kạn, Tỉnh Bắc Kạn",
        "code": "01849",
        "parent_code": "058"
    },
    "01852": {
        "name": "Nông Thượng",
        "type": "xa",
        "slug": "nong-thuong",
        "name_with_type": "Xã Nông Thượng",
        "path": "Nông Thượng, Bắc Kạn, Bắc Kạn",
        "path_with_type": "Xã Nông Thượng, Thành Phố Bắc Kạn, Tỉnh Bắc Kạn",
        "code": "01852",
        "parent_code": "058"
    },
    "01855": {
        "name": "Xuất Hóa",
        "type": "phuong",
        "slug": "xuat-hoa",
        "name_with_type": "Phường Xuất Hóa",
        "path": "Xuất Hóa, Bắc Kạn, Bắc Kạn",
        "path_with_type": "Phường Xuất Hóa, Thành Phố Bắc Kạn, Tỉnh Bắc Kạn",
        "code": "01855",
        "parent_code": "058"
    },
    "01858": {
        "name": "Bằng Thành",
        "type": "xa",
        "slug": "bang-thanh",
        "name_with_type": "Xã Bằng Thành",
        "path": "Bằng Thành, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã Bằng Thành, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01858",
        "parent_code": "060"
    },
    "01861": {
        "name": "Nhạn Môn",
        "type": "xa",
        "slug": "nhan-mon",
        "name_with_type": "Xã Nhạn Môn",
        "path": "Nhạn Môn, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã Nhạn Môn, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01861",
        "parent_code": "060"
    },
    "01864": {
        "name": "Bộc Bố",
        "type": "xa",
        "slug": "boc-bo",
        "name_with_type": "Xã Bộc Bố",
        "path": "Bộc Bố, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã Bộc Bố, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01864",
        "parent_code": "060"
    },
    "01867": {
        "name": "Công Bằng",
        "type": "xa",
        "slug": "cong-bang",
        "name_with_type": "Xã Công Bằng",
        "path": "Công Bằng, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã Công Bằng, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01867",
        "parent_code": "060"
    },
    "01870": {
        "name": "Giáo Hiệu",
        "type": "xa",
        "slug": "giao-hieu",
        "name_with_type": "Xã Giáo Hiệu",
        "path": "Giáo Hiệu, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã Giáo Hiệu, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01870",
        "parent_code": "060"
    },
    "01873": {
        "name": "Xuân La",
        "type": "xa",
        "slug": "xuan-la",
        "name_with_type": "Xã Xuân La",
        "path": "Xuân La, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã Xuân La, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01873",
        "parent_code": "060"
    },
    "01876": {
        "name": "An Thắng",
        "type": "xa",
        "slug": "an-thang",
        "name_with_type": "Xã An Thắng",
        "path": "An Thắng, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã An Thắng, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01876",
        "parent_code": "060"
    },
    "01879": {
        "name": "Cổ Linh",
        "type": "xa",
        "slug": "co-linh",
        "name_with_type": "Xã Cổ Linh",
        "path": "Cổ Linh, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã Cổ Linh, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01879",
        "parent_code": "060"
    },
    "01882": {
        "name": "Nghiên Loan",
        "type": "xa",
        "slug": "nghien-loan",
        "name_with_type": "Xã Nghiên Loan",
        "path": "Nghiên Loan, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã Nghiên Loan, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01882",
        "parent_code": "060"
    },
    "01885": {
        "name": "Cao Tân",
        "type": "xa",
        "slug": "cao-tan",
        "name_with_type": "Xã Cao Tân",
        "path": "Cao Tân, Pác Nặm, Bắc Kạn",
        "path_with_type": "Xã Cao Tân, Huyện Pác Nặm, Tỉnh Bắc Kạn",
        "code": "01885",
        "parent_code": "060"
    },
    "01888": {
        "name": "Chợ Rã",
        "type": "thi-tran",
        "slug": "cho-ra",
        "name_with_type": "Thị trấn Chợ Rã",
        "path": "Chợ Rã, Ba Bể, Bắc Kạn",
        "path_with_type": "Thị trấn Chợ Rã, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01888",
        "parent_code": "061"
    },
    "01891": {
        "name": "Bành Trạch",
        "type": "xa",
        "slug": "banh-trach",
        "name_with_type": "Xã Bành Trạch",
        "path": "Bành Trạch, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Bành Trạch, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01891",
        "parent_code": "061"
    },
    "01894": {
        "name": "Phúc Lộc",
        "type": "xa",
        "slug": "phuc-loc",
        "name_with_type": "Xã Phúc Lộc",
        "path": "Phúc Lộc, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Phúc Lộc, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01894",
        "parent_code": "061"
    },
    "01897": {
        "name": "Hà Hiệu",
        "type": "xa",
        "slug": "ha-hieu",
        "name_with_type": "Xã Hà Hiệu",
        "path": "Hà Hiệu, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Hà Hiệu, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01897",
        "parent_code": "061"
    },
    "01900": {
        "name": "Cao Thượng",
        "type": "xa",
        "slug": "cao-thuong",
        "name_with_type": "Xã Cao Thượng",
        "path": "Cao Thượng, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Cao Thượng, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01900",
        "parent_code": "061"
    },
    "01903": {
        "name": "Cao Trĩ",
        "type": "xa",
        "slug": "cao-tri",
        "name_with_type": "Xã Cao Trĩ",
        "path": "Cao Trĩ, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Cao Trĩ, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01903",
        "parent_code": "061"
    },
    "01906": {
        "name": "Khang Ninh",
        "type": "xa",
        "slug": "khang-ninh",
        "name_with_type": "Xã Khang Ninh",
        "path": "Khang Ninh, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Khang Ninh, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01906",
        "parent_code": "061"
    },
    "01909": {
        "name": "Nam Mẫu",
        "type": "xa",
        "slug": "nam-mau",
        "name_with_type": "Xã Nam Mẫu",
        "path": "Nam Mẫu, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Nam Mẫu, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01909",
        "parent_code": "061"
    },
    "01912": {
        "name": "Thượng Giáo",
        "type": "xa",
        "slug": "thuong-giao",
        "name_with_type": "Xã Thượng Giáo",
        "path": "Thượng Giáo, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Thượng Giáo, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01912",
        "parent_code": "061"
    },
    "01915": {
        "name": "Địa Linh",
        "type": "xa",
        "slug": "dia-linh",
        "name_with_type": "Xã Địa Linh",
        "path": "Địa Linh, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Địa Linh, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01915",
        "parent_code": "061"
    },
    "01918": {
        "name": "Yến Dương",
        "type": "xa",
        "slug": "yen-duong",
        "name_with_type": "Xã Yến Dương",
        "path": "Yến Dương, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Yến Dương, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01918",
        "parent_code": "061"
    },
    "01921": {
        "name": "Chu Hương",
        "type": "xa",
        "slug": "chu-huong",
        "name_with_type": "Xã Chu Hương",
        "path": "Chu Hương, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Chu Hương, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01921",
        "parent_code": "061"
    },
    "01924": {
        "name": "Quảng Khê",
        "type": "xa",
        "slug": "quang-khe",
        "name_with_type": "Xã Quảng Khê",
        "path": "Quảng Khê, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Quảng Khê, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01924",
        "parent_code": "061"
    },
    "01927": {
        "name": "Mỹ Phương",
        "type": "xa",
        "slug": "my-phuong",
        "name_with_type": "Xã Mỹ Phương",
        "path": "Mỹ Phương, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Mỹ Phương, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01927",
        "parent_code": "061"
    },
    "01930": {
        "name": "Hoàng Trĩ",
        "type": "xa",
        "slug": "hoang-tri",
        "name_with_type": "Xã Hoàng Trĩ",
        "path": "Hoàng Trĩ, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Hoàng Trĩ, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01930",
        "parent_code": "061"
    },
    "01933": {
        "name": "Đồng Phúc",
        "type": "xa",
        "slug": "dong-phuc",
        "name_with_type": "Xã Đồng Phúc",
        "path": "Đồng Phúc, Ba Bể, Bắc Kạn",
        "path_with_type": "Xã Đồng Phúc, Huyện Ba Bể, Tỉnh Bắc Kạn",
        "code": "01933",
        "parent_code": "061"
    },
    "01936": {
        "name": "Nà Phặc",
        "type": "thi-tran",
        "slug": "na-phac",
        "name_with_type": "Thị trấn Nà Phặc",
        "path": "Nà Phặc, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Thị trấn Nà Phặc, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01936",
        "parent_code": "062"
    },
    "01939": {
        "name": "Thượng Ân",
        "type": "xa",
        "slug": "thuong-an",
        "name_with_type": "Xã Thượng Ân",
        "path": "Thượng Ân, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Thượng Ân, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01939",
        "parent_code": "062"
    },
    "01942": {
        "name": "Bằng Vân",
        "type": "xa",
        "slug": "bang-van",
        "name_with_type": "Xã Bằng Vân",
        "path": "Bằng Vân, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Bằng Vân, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01942",
        "parent_code": "062"
    },
    "01945": {
        "name": "Cốc Đán",
        "type": "xa",
        "slug": "coc-dan",
        "name_with_type": "Xã Cốc Đán",
        "path": "Cốc Đán, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Cốc Đán, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01945",
        "parent_code": "062"
    },
    "01948": {
        "name": "Trung Hoà",
        "type": "xa",
        "slug": "trung-hoa",
        "name_with_type": "Xã Trung Hoà",
        "path": "Trung Hoà, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Trung Hoà, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01948",
        "parent_code": "062"
    },
    "01951": {
        "name": "Đức Vân",
        "type": "xa",
        "slug": "duc-van",
        "name_with_type": "Xã Đức Vân",
        "path": "Đức Vân, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Đức Vân, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01951",
        "parent_code": "062"
    },
    "01954": {
        "name": "Vân Tùng",
        "type": "xa",
        "slug": "van-tung",
        "name_with_type": "Xã Vân Tùng",
        "path": "Vân Tùng, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Vân Tùng, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01954",
        "parent_code": "062"
    },
    "01957": {
        "name": "Thượng Quan",
        "type": "xa",
        "slug": "thuong-quan",
        "name_with_type": "Xã Thượng Quan",
        "path": "Thượng Quan, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Thượng Quan, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01957",
        "parent_code": "062"
    },
    "01960": {
        "name": "Lãng Ngâm",
        "type": "xa",
        "slug": "lang-ngam",
        "name_with_type": "Xã Lãng Ngâm",
        "path": "Lãng Ngâm, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Lãng Ngâm, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01960",
        "parent_code": "062"
    },
    "01963": {
        "name": "Thuần Mang",
        "type": "xa",
        "slug": "thuan-mang",
        "name_with_type": "Xã Thuần Mang",
        "path": "Thuần Mang, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Thuần Mang, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01963",
        "parent_code": "062"
    },
    "01966": {
        "name": "Hương Nê",
        "type": "xa",
        "slug": "huong-ne",
        "name_with_type": "Xã Hương Nê",
        "path": "Hương Nê, Ngân Sơn, Bắc Kạn",
        "path_with_type": "Xã Hương Nê, Huyện Ngân Sơn, Tỉnh Bắc Kạn",
        "code": "01966",
        "parent_code": "062"
    },
    "01969": {
        "name": "Phủ Thông",
        "type": "thi-tran",
        "slug": "phu-thong",
        "name_with_type": "Thị trấn Phủ Thông",
        "path": "Phủ Thông, Bạch Thông, Bắc Kạn",
        "path_with_type": "Thị trấn Phủ Thông, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01969",
        "parent_code": "063"
    },
    "01972": {
        "name": "Phương Linh",
        "type": "xa",
        "slug": "phuong-linh",
        "name_with_type": "Xã Phương Linh",
        "path": "Phương Linh, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Phương Linh, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01972",
        "parent_code": "063"
    },
    "01975": {
        "name": "Vi Hương",
        "type": "xa",
        "slug": "vi-huong",
        "name_with_type": "Xã Vi Hương",
        "path": "Vi Hương, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Vi Hương, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01975",
        "parent_code": "063"
    },
    "01978": {
        "name": "Sĩ Bình",
        "type": "xa",
        "slug": "si-binh",
        "name_with_type": "Xã Sĩ Bình",
        "path": "Sĩ Bình, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Sĩ Bình, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01978",
        "parent_code": "063"
    },
    "01981": {
        "name": "Vũ Muộn",
        "type": "xa",
        "slug": "vu-muon",
        "name_with_type": "Xã Vũ Muộn",
        "path": "Vũ Muộn, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Vũ Muộn, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01981",
        "parent_code": "063"
    },
    "01984": {
        "name": "Đôn Phong",
        "type": "xa",
        "slug": "don-phong",
        "name_with_type": "Xã Đôn Phong",
        "path": "Đôn Phong, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Đôn Phong, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01984",
        "parent_code": "063"
    },
    "01987": {
        "name": "Tú Trĩ",
        "type": "xa",
        "slug": "tu-tri",
        "name_with_type": "Xã Tú Trĩ",
        "path": "Tú Trĩ, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Tú Trĩ, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01987",
        "parent_code": "063"
    },
    "01990": {
        "name": "Lục Bình",
        "type": "xa",
        "slug": "luc-binh",
        "name_with_type": "Xã Lục Bình",
        "path": "Lục Bình, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Lục Bình, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01990",
        "parent_code": "063"
    },
    "01993": {
        "name": "Tân Tiến",
        "type": "xa",
        "slug": "tan-tien",
        "name_with_type": "Xã Tân Tiến",
        "path": "Tân Tiến, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Tân Tiến, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01993",
        "parent_code": "063"
    },
    "01996": {
        "name": "Quân Bình",
        "type": "xa",
        "slug": "quan-binh",
        "name_with_type": "Xã Quân Bình",
        "path": "Quân Bình, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Quân Bình, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01996",
        "parent_code": "063"
    },
    "01999": {
        "name": "Nguyên Phúc",
        "type": "xa",
        "slug": "nguyen-phuc",
        "name_with_type": "Xã Nguyên Phúc",
        "path": "Nguyên Phúc, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Nguyên Phúc, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "01999",
        "parent_code": "063"
    },
    "02002": {
        "name": "Cao Sơn",
        "type": "xa",
        "slug": "cao-son",
        "name_with_type": "Xã Cao Sơn",
        "path": "Cao Sơn, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Cao Sơn, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "02002",
        "parent_code": "063"
    },
    "02005": {
        "name": "Hà Vị",
        "type": "xa",
        "slug": "ha-vi",
        "name_with_type": "Xã Hà Vị",
        "path": "Hà Vị, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Hà Vị, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "02005",
        "parent_code": "063"
    },
    "02008": {
        "name": "Cẩm Giàng",
        "type": "xa",
        "slug": "cam-giang",
        "name_with_type": "Xã Cẩm Giàng",
        "path": "Cẩm Giàng, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Cẩm Giàng, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "02008",
        "parent_code": "063"
    },
    "02011": {
        "name": "Mỹ Thanh",
        "type": "xa",
        "slug": "my-thanh",
        "name_with_type": "Xã Mỹ Thanh",
        "path": "Mỹ Thanh, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Mỹ Thanh, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "02011",
        "parent_code": "063"
    },
    "02014": {
        "name": "Dương Phong",
        "type": "xa",
        "slug": "duong-phong",
        "name_with_type": "Xã Dương Phong",
        "path": "Dương Phong, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Dương Phong, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "02014",
        "parent_code": "063"
    },
    "02017": {
        "name": "Quang Thuận",
        "type": "xa",
        "slug": "quang-thuan",
        "name_with_type": "Xã Quang Thuận",
        "path": "Quang Thuận, Bạch Thông, Bắc Kạn",
        "path_with_type": "Xã Quang Thuận, Huyện Bạch Thông, Tỉnh Bắc Kạn",
        "code": "02017",
        "parent_code": "063"
    },
    "02020": {
        "name": "Bằng Lũng",
        "type": "thi-tran",
        "slug": "bang-lung",
        "name_with_type": "Thị trấn Bằng Lũng",
        "path": "Bằng Lũng, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Thị trấn Bằng Lũng, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02020",
        "parent_code": "064"
    },
    "02023": {
        "name": "Xuân Lạc",
        "type": "xa",
        "slug": "xuan-lac",
        "name_with_type": "Xã Xuân Lạc",
        "path": "Xuân Lạc, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Xuân Lạc, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02023",
        "parent_code": "064"
    },
    "02026": {
        "name": "Nam Cường",
        "type": "xa",
        "slug": "nam-cuong",
        "name_with_type": "Xã Nam Cường",
        "path": "Nam Cường, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Nam Cường, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02026",
        "parent_code": "064"
    },
    "02029": {
        "name": "Đồng Lạc",
        "type": "xa",
        "slug": "dong-lac",
        "name_with_type": "Xã Đồng Lạc",
        "path": "Đồng Lạc, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Đồng Lạc, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02029",
        "parent_code": "064"
    },
    "02032": {
        "name": "Tân Lập",
        "type": "xa",
        "slug": "tan-lap",
        "name_with_type": "Xã Tân Lập",
        "path": "Tân Lập, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Tân Lập, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02032",
        "parent_code": "064"
    },
    "02035": {
        "name": "Bản Thi",
        "type": "xa",
        "slug": "ban-thi",
        "name_with_type": "Xã Bản Thi",
        "path": "Bản Thi, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Bản Thi, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02035",
        "parent_code": "064"
    },
    "02038": {
        "name": "Quảng Bạch",
        "type": "xa",
        "slug": "quang-bach",
        "name_with_type": "Xã Quảng Bạch",
        "path": "Quảng Bạch, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Quảng Bạch, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02038",
        "parent_code": "064"
    },
    "02041": {
        "name": "Bằng Phúc",
        "type": "xa",
        "slug": "bang-phuc",
        "name_with_type": "Xã Bằng Phúc",
        "path": "Bằng Phúc, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Bằng Phúc, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02041",
        "parent_code": "064"
    },
    "02044": {
        "name": "Yên Thịnh",
        "type": "xa",
        "slug": "yen-thinh",
        "name_with_type": "Xã Yên Thịnh",
        "path": "Yên Thịnh, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Yên Thịnh, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02044",
        "parent_code": "064"
    },
    "02047": {
        "name": "Yên Thượng",
        "type": "xa",
        "slug": "yen-thuong",
        "name_with_type": "Xã Yên Thượng",
        "path": "Yên Thượng, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Yên Thượng, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02047",
        "parent_code": "064"
    },
    "02050": {
        "name": "Phương Viên",
        "type": "xa",
        "slug": "phuong-vien",
        "name_with_type": "Xã Phương Viên",
        "path": "Phương Viên, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Phương Viên, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02050",
        "parent_code": "064"
    },
    "02053": {
        "name": "Ngọc Phái",
        "type": "xa",
        "slug": "ngoc-phai",
        "name_with_type": "Xã Ngọc Phái",
        "path": "Ngọc Phái, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Ngọc Phái, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02053",
        "parent_code": "064"
    },
    "02056": {
        "name": "Rã Bản",
        "type": "xa",
        "slug": "ra-ban",
        "name_with_type": "Xã Rã Bản",
        "path": "Rã Bản, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Rã Bản, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02056",
        "parent_code": "064"
    },
    "02059": {
        "name": "Đông Viên",
        "type": "xa",
        "slug": "dong-vien",
        "name_with_type": "Xã Đông Viên",
        "path": "Đông Viên, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Đông Viên, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02059",
        "parent_code": "064"
    },
    "02062": {
        "name": "Lương Bằng",
        "type": "xa",
        "slug": "luong-bang",
        "name_with_type": "Xã Lương Bằng",
        "path": "Lương Bằng, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Lương Bằng, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02062",
        "parent_code": "064"
    },
    "02065": {
        "name": "Bằng Lãng",
        "type": "xa",
        "slug": "bang-lang",
        "name_with_type": "Xã Bằng Lãng",
        "path": "Bằng Lãng, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Bằng Lãng, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02065",
        "parent_code": "064"
    },
    "02068": {
        "name": "Đại Sảo",
        "type": "xa",
        "slug": "dai-sao",
        "name_with_type": "Xã Đại Sảo",
        "path": "Đại Sảo, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Đại Sảo, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02068",
        "parent_code": "064"
    },
    "02071": {
        "name": "Nghĩa Tá",
        "type": "xa",
        "slug": "nghia-ta",
        "name_with_type": "Xã Nghĩa Tá",
        "path": "Nghĩa Tá, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Nghĩa Tá, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02071",
        "parent_code": "064"
    },
    "02074": {
        "name": "Phong Huân",
        "type": "xa",
        "slug": "phong-huan",
        "name_with_type": "Xã Phong Huân",
        "path": "Phong Huân, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Phong Huân, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02074",
        "parent_code": "064"
    },
    "02077": {
        "name": "Yên Mỹ",
        "type": "xa",
        "slug": "yen-my",
        "name_with_type": "Xã Yên Mỹ",
        "path": "Yên Mỹ, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Yên Mỹ, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02077",
        "parent_code": "064"
    },
    "02080": {
        "name": "Bình Trung",
        "type": "xa",
        "slug": "binh-trung",
        "name_with_type": "Xã Bình Trung",
        "path": "Bình Trung, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Bình Trung, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02080",
        "parent_code": "064"
    },
    "02083": {
        "name": "Yên Nhuận",
        "type": "xa",
        "slug": "yen-nhuan",
        "name_with_type": "Xã Yên Nhuận",
        "path": "Yên Nhuận, Chợ Đồn, Bắc Kạn",
        "path_with_type": "Xã Yên Nhuận, Huyện Chợ Đồn, Tỉnh Bắc Kạn",
        "code": "02083",
        "parent_code": "064"
    },
    "02086": {
        "name": "Chợ Mới",
        "type": "thi-tran",
        "slug": "cho-moi",
        "name_with_type": "Thị trấn Chợ Mới",
        "path": "Chợ Mới, Chợ Mới, Bắc Kạn",
        "path_with_type": "Thị trấn Chợ Mới, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02086",
        "parent_code": "065"
    },
    "02089": {
        "name": "Tân Sơn",
        "type": "xa",
        "slug": "tan-son",
        "name_with_type": "Xã Tân Sơn",
        "path": "Tân Sơn, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Tân Sơn, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02089",
        "parent_code": "065"
    },
    "02092": {
        "name": "Thanh Vận",
        "type": "xa",
        "slug": "thanh-van",
        "name_with_type": "Xã Thanh Vận",
        "path": "Thanh Vận, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Thanh Vận, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02092",
        "parent_code": "065"
    },
    "02095": {
        "name": "Mai Lạp",
        "type": "xa",
        "slug": "mai-lap",
        "name_with_type": "Xã Mai Lạp",
        "path": "Mai Lạp, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Mai Lạp, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02095",
        "parent_code": "065"
    },
    "02098": {
        "name": "Hoà Mục",
        "type": "xa",
        "slug": "hoa-muc",
        "name_with_type": "Xã Hoà Mục",
        "path": "Hoà Mục, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Hoà Mục, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02098",
        "parent_code": "065"
    },
    "02101": {
        "name": "Thanh Mai",
        "type": "xa",
        "slug": "thanh-mai",
        "name_with_type": "Xã Thanh Mai",
        "path": "Thanh Mai, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Thanh Mai, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02101",
        "parent_code": "065"
    },
    "02104": {
        "name": "Cao Kỳ",
        "type": "xa",
        "slug": "cao-ky",
        "name_with_type": "Xã Cao Kỳ",
        "path": "Cao Kỳ, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Cao Kỳ, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02104",
        "parent_code": "065"
    },
    "02107": {
        "name": "Nông Hạ",
        "type": "xa",
        "slug": "nong-ha",
        "name_with_type": "Xã Nông Hạ",
        "path": "Nông Hạ, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Nông Hạ, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02107",
        "parent_code": "065"
    },
    "02110": {
        "name": "Yên Cư",
        "type": "xa",
        "slug": "yen-cu",
        "name_with_type": "Xã Yên Cư",
        "path": "Yên Cư, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Yên Cư, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02110",
        "parent_code": "065"
    },
    "02113": {
        "name": "Nông Thịnh",
        "type": "xa",
        "slug": "nong-thinh",
        "name_with_type": "Xã Nông Thịnh",
        "path": "Nông Thịnh, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Nông Thịnh, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02113",
        "parent_code": "065"
    },
    "02116": {
        "name": "Yên Hân",
        "type": "xa",
        "slug": "yen-han",
        "name_with_type": "Xã Yên Hân",
        "path": "Yên Hân, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Yên Hân, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02116",
        "parent_code": "065"
    },
    "02119": {
        "name": "Thanh Bình",
        "type": "xa",
        "slug": "thanh-binh",
        "name_with_type": "Xã Thanh Bình",
        "path": "Thanh Bình, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Thanh Bình, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02119",
        "parent_code": "065"
    },
    "02122": {
        "name": "Như Cố",
        "type": "xa",
        "slug": "nhu-co",
        "name_with_type": "Xã Như Cố",
        "path": "Như Cố, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Như Cố, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02122",
        "parent_code": "065"
    },
    "02125": {
        "name": "Bình Văn",
        "type": "xa",
        "slug": "binh-van",
        "name_with_type": "Xã Bình Văn",
        "path": "Bình Văn, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Bình Văn, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02125",
        "parent_code": "065"
    },
    "02128": {
        "name": "Yên Đĩnh",
        "type": "xa",
        "slug": "yen-dinh",
        "name_with_type": "Xã Yên Đĩnh",
        "path": "Yên Đĩnh, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Yên Đĩnh, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02128",
        "parent_code": "065"
    },
    "02131": {
        "name": "Quảng Chu",
        "type": "xa",
        "slug": "quang-chu",
        "name_with_type": "Xã Quảng Chu",
        "path": "Quảng Chu, Chợ Mới, Bắc Kạn",
        "path_with_type": "Xã Quảng Chu, Huyện Chợ Mới, Tỉnh Bắc Kạn",
        "code": "02131",
        "parent_code": "065"
    },
    "02134": {
        "name": "Yến Lạc",
        "type": "thi-tran",
        "slug": "yen-lac",
        "name_with_type": "Thị trấn Yến Lạc",
        "path": "Yến Lạc, Na Rì, Bắc Kạn",
        "path_with_type": "Thị trấn Yến Lạc, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02134",
        "parent_code": "066"
    },
    "02137": {
        "name": "Vũ Loan",
        "type": "xa",
        "slug": "vu-loan",
        "name_with_type": "Xã Vũ Loan",
        "path": "Vũ Loan, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Vũ Loan, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02137",
        "parent_code": "066"
    },
    "02140": {
        "name": "Lạng San",
        "type": "xa",
        "slug": "lang-san",
        "name_with_type": "Xã Lạng San",
        "path": "Lạng San, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Lạng San, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02140",
        "parent_code": "066"
    },
    "02143": {
        "name": "Lương Thượng",
        "type": "xa",
        "slug": "luong-thuong",
        "name_with_type": "Xã Lương Thượng",
        "path": "Lương Thượng, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Lương Thượng, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02143",
        "parent_code": "066"
    },
    "02146": {
        "name": "Kim Hỷ",
        "type": "xa",
        "slug": "kim-hy",
        "name_with_type": "Xã Kim Hỷ",
        "path": "Kim Hỷ, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Kim Hỷ, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02146",
        "parent_code": "066"
    },
    "02149": {
        "name": "Văn Học",
        "type": "xa",
        "slug": "van-hoc",
        "name_with_type": "Xã Văn Học",
        "path": "Văn Học, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Văn Học, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02149",
        "parent_code": "066"
    },
    "02152": {
        "name": "Cường Lợi",
        "type": "xa",
        "slug": "cuong-loi",
        "name_with_type": "Xã Cường Lợi",
        "path": "Cường Lợi, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Cường Lợi, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02152",
        "parent_code": "066"
    },
    "02155": {
        "name": "Lương Hạ",
        "type": "xa",
        "slug": "luong-ha",
        "name_with_type": "Xã Lương Hạ",
        "path": "Lương Hạ, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Lương Hạ, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02155",
        "parent_code": "066"
    },
    "02158": {
        "name": "Kim Lư",
        "type": "xa",
        "slug": "kim-lu",
        "name_with_type": "Xã Kim Lư",
        "path": "Kim Lư, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Kim Lư, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02158",
        "parent_code": "066"
    },
    "02161": {
        "name": "Lương Thành",
        "type": "xa",
        "slug": "luong-thanh",
        "name_with_type": "Xã Lương Thành",
        "path": "Lương Thành, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Lương Thành, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02161",
        "parent_code": "066"
    },
    "02164": {
        "name": "Ân Tình",
        "type": "xa",
        "slug": "an-tinh",
        "name_with_type": "Xã Ân Tình",
        "path": "Ân Tình, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Ân Tình, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02164",
        "parent_code": "066"
    },
    "02167": {
        "name": "Lam Sơn",
        "type": "xa",
        "slug": "lam-son",
        "name_with_type": "Xã Lam Sơn",
        "path": "Lam Sơn, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Lam Sơn, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02167",
        "parent_code": "066"
    },
    "02170": {
        "name": "Văn Minh",
        "type": "xa",
        "slug": "van-minh",
        "name_with_type": "Xã Văn Minh",
        "path": "Văn Minh, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Văn Minh, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02170",
        "parent_code": "066"
    },
    "02173": {
        "name": "Côn Minh",
        "type": "xa",
        "slug": "con-minh",
        "name_with_type": "Xã Côn Minh",
        "path": "Côn Minh, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Côn Minh, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02173",
        "parent_code": "066"
    },
    "02176": {
        "name": "Cư Lễ",
        "type": "xa",
        "slug": "cu-le",
        "name_with_type": "Xã Cư Lễ",
        "path": "Cư Lễ, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Cư Lễ, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02176",
        "parent_code": "066"
    },
    "02179": {
        "name": "Hữu Thác",
        "type": "xa",
        "slug": "huu-thac",
        "name_with_type": "Xã Hữu Thác",
        "path": "Hữu Thác, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Hữu Thác, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02179",
        "parent_code": "066"
    },
    "02182": {
        "name": "Hảo Nghĩa",
        "type": "xa",
        "slug": "hao-nghia",
        "name_with_type": "Xã Hảo Nghĩa",
        "path": "Hảo Nghĩa, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Hảo Nghĩa, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02182",
        "parent_code": "066"
    },
    "02185": {
        "name": "Quang Phong",
        "type": "xa",
        "slug": "quang-phong",
        "name_with_type": "Xã Quang Phong",
        "path": "Quang Phong, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Quang Phong, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02185",
        "parent_code": "066"
    },
    "02188": {
        "name": "Dương Sơn",
        "type": "xa",
        "slug": "duong-son",
        "name_with_type": "Xã Dương Sơn",
        "path": "Dương Sơn, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Dương Sơn, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02188",
        "parent_code": "066"
    },
    "02191": {
        "name": "Xuân Dương",
        "type": "xa",
        "slug": "xuan-duong",
        "name_with_type": "Xã Xuân Dương",
        "path": "Xuân Dương, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Xuân Dương, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02191",
        "parent_code": "066"
    },
    "02194": {
        "name": "Đổng Xá",
        "type": "xa",
        "slug": "dong-xa",
        "name_with_type": "Xã Đổng Xá",
        "path": "Đổng Xá, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Đổng Xá, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02194",
        "parent_code": "066"
    },
    "02197": {
        "name": "Liêm Thuỷ",
        "type": "xa",
        "slug": "liem-thuy",
        "name_with_type": "Xã Liêm Thuỷ",
        "path": "Liêm Thuỷ, Na Rì, Bắc Kạn",
        "path_with_type": "Xã Liêm Thuỷ, Huyện Na Rì, Tỉnh Bắc Kạn",
        "code": "02197",
        "parent_code": "066"
    }
}