import styled from 'styled-components';

const Bound = styled.div`
    display: flex;
    width: 100%;
    .biometric-face {
        flex: 1;
        padding: 24px 14px 12px 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            padding-top: 12px;
            font-family: SVN-Gilroy;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.3;
            color: #979797;
        }
        .error {
            display: flex;
            padding-top: 9px;
            cursor: pointer;
            span {
                font-family: SVN-Gilroy;
                font-size: 12px;
                line-height: 150%;
                text-decoration-line: underline;
                color: #d71249;
                padding-left: 4px;
            }
        }
        .update {
            cursor: pointer;
            span {
                text-decoration: none;
            }
        }
        .face-id {
            /* width: 140px; */
            width: fit-content;
            height: 140px;
            border-radius: 5px;
            border: solid 2px #ffffff;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: ${(props) => props.imageLoadError && 'pointer'};
            img {
                /* width: 100%; */
                height: 100%;
                object-fit: contain;
                border-radius: 5px;
            }
            img.image {
                display: block;
                /* height: auto; */
                /* width: 100%; */
            }

            &:hover .middle {
                opacity: 1;
            }

            .text {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 128%;
                color: #ffffff;
                border-radius: 5px;
                text-decoration: underline;
            }
            .middle {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: 0.5s ease;
                background-color: rgba(34, 34, 34, 0.5);
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            img.ic_zoom {
                width: 14px;
                height: 14px;
                position: absolute;
                bottom: 5px;
                left: 5px;
                cursor: pointer;
                z-index: 9;
            }
            p.text-imageload {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 130%;
                color: #979797;
            }
        }
        .update-last {
            position: absolute;
            bottom: 12px;
            font-family: SVN-Gilroy;
            font-size: 12px;
            font-weight: 500;
            p {
                color: #222222;
                font-size: 13px;
            }
            span {
                color: #979797;
            }
        }
    }
    .biometric-finger {
        font-family: SVN-Gilroy;
        flex: 4;
        position: relative;
        height: 352px;
        .notify {
            position: absolute;
            /* top: 10px; */
            bottom: -48px;
            left: 13px;
            display: flex;
            align-items: center;
            cursor: pointer;
            text-decoration: underline;
            &:last-child {
                bottom: -26px;
            }
            h4 {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 150%;
                text-decoration-line: underline;
                color: #f31919;
                margin: -2px 0 0 4px;
            }
        }
        .show-finger {
            .finger {
                position: absolute;
                bottom: 12px;
                left: 12px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                p {
                    font-family: SVN-Gilroy;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.3;
                    opacity: 0.5;
                    color: #ffffff;
                }
                p:nth-child(2) {
                    padding-right: 31px;
                }
            }

            .status {
                display: flex;
                position: absolute;
                top: 13px;
                right: 20px;
                .update {
                    display: flex;
                    align-items: center;
                    &:not(:nth-child(1)) {
                        padding-left: 10px;
                    }
                }
                .circle {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
                p {
                    padding-left: 4px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.3;
                    color: #ffffff;
                }
            }
        }
    }
`

export {
    Bound
}