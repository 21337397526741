import styled from 'styled-components'

const Styles = styled.div`
    font-family: 'Times New Roman';

    .page {
        position: relative;
        /* min-width: 8.5in; */
        /* min-width: 210mm; */
        /* height: 11in; */
        /* min-height: 297mm; */
        min-width: 9.27in;
        min-height: calc(100% - 0.19in);
        padding: 0.19in 0.44in 0 0.63in;
        clear: both;
        page-break-after: always;
        break-after: always;
    }

    .form-infos,
    .country-infos {
        width: 100%;
        /* font-size: 11pt; */
        /* font-size: 12.5pt; */
        font-size: 13.2pt;
        font-weight: bold;
    }

    .form-infos {
        display: flex;
        justify-content: space-between;
        font-style: italic;
    }

    .country-infos {
        text-align: center;

        .motto {
            position: relative;
            display: inline-block;

            ::before {
                content: '';
                position: absolute;
                bottom: 0;
                width: calc(100% + 40px);
                left: -20px;
                height: 1px;
                background: black;
            }
        }
    }

    .date-of-now {
        text-align: end;
        /* font-size: 11pt; */
        /* font-size: 12.5pt; */
        font-size: 13.2pt;
        font-style: italic;
    }

    .name-of-form {
        text-align: center;
        /* font-size: 12pt; */
        /* font-size: 13.5pt; */
        font-size: 14.2pt;
        font-weight: bold;
        padding: 10px 0;
    }

    .where-to-send {
        /* font-size: 11pt; */
        /* font-size: 12.5pt; */
        font-size: 13.2pt;
        font-weight: bold;
        padding-bottom: 5px;
    }

    .wrapper {
        border: 1px solid black;
        /* font-size: 11pt; */
        /* font-size: 12.5pt; */
        font-size: 13.2pt;

        & > *:not(:last-child) {
            border-bottom: 1px solid black;
        }

        & > *:not(.list-commitment) > * {
            padding: 5px;
        }

        .p-5 {
            padding: 5px;
        }

        .title {
            font-weight: bold;
            background: rgb(217, 217, 217);
        }

        .title-request {
            font-weight: bold;
        }

        .checkbox-field {
            display: flex;
            align-items: center;

            .checkbox {
                border: 1px solid black;
                display: inline-block;
                width: 10px;
                height: 10px;
                position: relative;
                margin-right: 5px;

                &.checked::before {
                    content: '\\2713';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .content {
                line-height: 150%;
                display: inline-block;
            }
        }
    }

    .list-commitment {
        /* font-size: 11pt; */
        /* font-size: 12.5pt; */
        font-size: 13.2pt;
        list-style: '\\002D\\0020\\0020';
        margin: 0;
        padding: 5px 5px 5px 20px;
        list-style-position: outside;

        & > li {
            text-align: justify;
        }
    }

    .grid-wrapper {
        display: grid;
        &.col-3 {
            grid-template-columns: 1.25fr 0.75fr 1fr;
        }

        &.col-2 {
            grid-template-columns: repeat(2, 1fr);

            &.border-split {
                & > *:first-child {
                    border-right: 1px solid black;
                }
            }
        }
    }

    .email {
        grid-column: 2/4;
    }

    .hand-title {
        font-weight: bold;
        font-style: italic;
        text-decoration: underline;
        text-align: center;
    }

    .hand-status {
        font-weight: bold;
        font-style: italic;
    }

    .details-num {
        vertical-align: super;
        font-size: 8pt;
    }

    .sign-range {
        text-align: center;
        height: 180px;
        /* font-size: 11pt; */
        /* font-size: 12.5pt; */
        font-size: 13.2pt;
        padding-top: 5px;

        & > div:first-child {
            font-weight: bold;
        }

        & > div:last-child {
            font-style: italic;
        }
    }

    .confirm-bank {
        /* font-size: 11pt; */
        /* font-size: 12.5pt; */
        font-size: 13.2pt;
        font-weight: bold;
        text-align: center;
    }

    .time-register {
        /* font-size: 11pt; */
        /* font-size: 12.5pt; */
        font-size: 13.2pt;
    }

    .footer-hr {
        width: 200px;
        margin: 0;
        position: absolute;
        bottom: 32pt;
    }

    .details-list {
        /* font-size: 8pt; */
        /* font-size: 9.5pt; */
        font-size: 10.2pt;
        position: absolute;
        bottom: 0;
    }

    @page {
        size: A4;
        margin: 0;
    }

    @media print {
        html,
        body {
            /* width: 210mm; */
            /* height: 297mm; */
            height: initial !important;
            /* width: 8.5in; */
            /* height: 11in; */
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
    }
`

export { Styles }
