import styled from 'styled-components';


const PopupPrintV2Styles = styled.div`
    background-color: #fafafa;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
    max-height: 100vh;
    font-family: 'Times New Roman';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;

    ::-webkit-scrollbar {
        /* width: 3px; */
        display: none;
    }

    /* ::-webkit-scrollbar-thumb {
        background: #c8c8c8; 
    } */

    .row_btn_confirm {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0 0 0;
        position: sticky;
        bottom: 0;
        #button {
            width: 60px;
            height: 23px;
        }
        .btn_exit {
            margin: 0 20px 0 0;
        }
        .titleConfirm {
            font-size: 10.5px;
        }
    }
`

export { PopupPrintV2Styles }