import React, { useEffect, useRef } from 'react'
import Axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapQueryResults } from './index.styles'
import { WrapPopupStyles } from '../../WrapPopupStyles'

// image
import icCloseGray from '../../../../../images/ic_close_gray.svg'

// api
import { apiGetCifs as apiGetCifsCounter } from '../../../../../actions/counterAction'
import { apiGetCifs as apiGetCifsCollector } from '../../../../../actions/biometricWithInBioCollectorAction'

// action
import { openPopup } from '../../../../../actions/popupBioAction'
import { clearDataUsersFromTablet } from '../../../../../actions/queriesAction'

// data
import { popupBioTypes } from '../../../../../data/PopupTypes'

// helper
import useIsRole from '../../../../../helper/hooks/useIsRole'
import getFromChannel from '../../../../../helper/getFromChannel'
import getChannelName from '../../../../../helper/getChannelName'

// constant
import { TELLER } from '../../../../../data/userRole'
import useMoveElement from '../../../../../helper/hooks/useMoveElement'


const MOCK_IMG = 'https://picsum.photos/200/300'

const CustomerTypes = {
    EXCUST: 'Thông thường',
    INCUST: 'CBNH',
}

const QueryResults = ({ title, handleClose, dataUsers }) => {
    const queryResultsRef = useRef(null)
    const dispatch = useDispatch()

    const { dataConnectDesktop } = useSelector(state => ({
        dataConnectDesktop: state.counterReducer.dataConnectDesktop
    }))

    useMoveElement(queryResultsRef, ".wrap-title")

    const isTeller = useIsRole(TELLER)
    const apiGetCifs = isTeller ? apiGetCifsCounter : apiGetCifsCollector

    const _handleShowDetailUser = async (e, user) => {
        e.preventDefault()

        const { CustomerNumber } = user
        const dataRequest = JSON.stringify({
            CounterID: dataConnectDesktop.CounterID,
            CifsNum: CustomerNumber,
            Type: 0
        })

        try {
            const res = await Axios.post(apiGetCifs, dataRequest)
            const { data } = res;
            const isSuccess = data.Code === 0 && data.Cifs;
            if (isSuccess) {
                console.log('data:', data)
                dispatch(openPopup(popupBioTypes.GTTTQueries, 3, data))
            }
            else {
                console.log('error:', data)
            }
        }
        catch (err) {
            console.dir(err)
        }
    }

    useEffect(() => {
        return () => {
            dispatch(clearDataUsersFromTablet())
        }
    }, []);

    return (
        <WrapPopupStyles>
            <WrapQueryResults ref={queryResultsRef}>
                <div className="wrap-title">
                    <div>
                        <div className="title">{title}</div>
                        <img className="ic-close" src={icCloseGray} alt="close" onClick={handleClose} />
                    </div>
                </div>
                <div className="wrap-list-customer">
                    {
                        dataUsers.map(user => {
                            const {
                                CustomerNumber,
                                Name,
                                FullNameVi,
                                CustomerType,
                                IDNumber,
                                IDIssueDate,
                                IDIssuePlace,
                                MobileNumber,
                                Address,
                                SegmentDesc,
                                Gender,
                                Email,
                                urlFace,
                                faceFromChannel,
                                history: {
                                    office,
                                    teller,
                                    timeRequest,
                                    supervisor,
                                    timeAccept
                                }
                            } = user
                            return (
                                <div className="wrap-customer" key={CustomerNumber}>
                                    <div className="side">
                                        <div className="field cif-num"><span>Số CIF: </span>{CustomerNumber}</div>
                                        <div className="wrap-bio-img">
                                            <img src={urlFace} alt="bio" />
                                        </div>
                                        <div className="field chanel"><span>Kênh thu thập: </span>{getChannelName(getFromChannel(faceFromChannel))}</div>
                                    </div>
                                    <div className="side">
                                        <div className="cust-name">{Name}</div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>Tên có dấu: </span>{FullNameVi}</div>
                                            <div className="field"><span>Loại khách hàng: </span>{CustomerTypes[CustomerType]}</div>
                                        </div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>Hạng khách hàng: </span>{SegmentDesc}</div>
                                            <div className="field"><span>Giới tính: </span>{Gender}</div>
                                        </div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>GTTT: </span>{IDNumber}</div>
                                            <div className="field"><span>Email: </span>{Email}</div>
                                        </div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>Ngày cấp: </span>{IDIssueDate}</div>
                                            <div className="field"><span>Số điện thoại: </span>{MobileNumber}</div>
                                        </div>
                                        <div className="grid grid-cust-info">
                                            <div className="field"><span>Nơi cấp: </span>{IDIssuePlace}</div>
                                            <div className="field"><span>Địa chỉ: </span>{Address}</div>
                                        </div>

                                        <div className="wrap-approved-infos">
                                            <div className="cust-name">Thông tin tác nghiệp STH khuôn mặt:</div>
                                            <div className="field"><span>CN/PGD: </span>{office}</div>
                                            <div className="field"><span>GDV/Điện toán: </span>{teller}</div>
                                            <div className="field"><span>Thời gian tác nghiệp: </span>{timeRequest}</div>
                                            <div className="field"><span>KSV/BGĐ: </span>{supervisor}</div>
                                            <div className="field"><span>Thời gian phê duyệt: </span>{timeAccept}</div>
                                        </div>
                                        <div className="wrap-btn-show-details">
                                            <a onClick={(e) => _handleShowDetailUser(e,user)}>Xem chi tiết</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="wrap-footer">
                    <button onClick={handleClose}>Xác nhận</button>
                </div>
            </WrapQueryResults>
        </WrapPopupStyles>
    )
}

export default QueryResults
