import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// styles
import { WrapFaceQuery } from './index.styles'

// helper
import fileToBase64 from '../../../../../helper/fileToBase64'
import useIsRole from '../../../../../helper/hooks/useIsRole'

// images
import icDefaultUser from '../../../../../images/default_user.svg'

// api
import { apiFindUserByFace, apiRequestFindUserByBio, apiGetCifs as apiGetCifsCounter } from '../../../../../actions/counterAction'
import { apiGetBioFaceImage } from '../../../../../actions/biometricAction'
import { apiGetBioFaceImageCollector, apiGetCifs as apiGetCifsCollector } from '../../../../../actions/biometricWithInBioCollectorAction'

// action
import { openPopup } from '../../../../../actions/popupBioAction'
import { clearDataUsersFromTablet, dispatchDataUsersFromTablet, setLoadingGetUserFace } from '../../../../../actions/queriesAction'

// data
import { popupBioTypes } from '../../../../../data/PopupTypes'
import { TELLER } from '../../../../../data/userRole'

// component
import LoadingControl from '../../../../Control/LoadingControl'
import { FACE } from '../../../../../data/infosType'

const STEP_INITIAL = "STEP_INITIAL"
const STEP_LOADING = "STEP_LOADING"
const STEP_SHOW_IMAGE = "STEP_SHOW_IMAGE"
const ERR_FACE_NOT_FOUND = 'Khuôn mặt đang tìm kiếm không tồn tại trong hệ thống'
const ERR_OVERLOAD_FILE = 'Hình ảnh tải lên vượt quá dung lượng cho phép'

const MAX_SIZE_OF_FILE = 1 * 1024 * 1024 //Bytes

const FaceQuery = ({ setDisabledQueryByFinger, loadingQueryByFinger, setLoadingQueryByFace, setError }) => {
    const dispatch = useDispatch()

    const { loadingGetByFace, resGetUserByImg, Roles, CounterID } = useSelector(state => ({
        loadingGetByFace: state.queriesReducer.queryByImg.loadingGetByFace,
        resGetUserByImg: state.queriesReducer.queryByImg.resGetUserByImg,
        Roles: state.counterReducer.dataLogin.Roles,
        CounterID: state.counterReducer.dataConnectDesktop.CounterID,
    }))

    const [step, setStep] = useState(STEP_INITIAL);
    const [lastStep, setLastStep] = useState(STEP_INITIAL);
    const [dataUsers, setDataUsers] = useState(null);
    const [urlImg, setUrlImg] = useState(null);
    const [imgBase64, setImgBase64] = useState('');
    const isTeller = useIsRole(TELLER)
    const apiGetFace = isTeller ? apiGetBioFaceImage : apiGetBioFaceImageCollector

    const _handleSetStep = (newStep) => {
        setLastStep(step)
        setStep(newStep)
    }

    const _handleShootPicture = async () => {
        setError('')
        dispatch(clearDataUsersFromTablet())

        const dataRequest = JSON.stringify({
            BioType: 'FACE'
        })

        try {
            const res = await Axios.post(apiRequestFindUserByBio, dataRequest)
            const { data } = res;
            const isSuccess = data.Code === 0;
            if (isSuccess) {
                // setStep(STEP_LOADING)
                // setLoadingQueryByFace(true)
                dispatch(setLoadingGetUserFace())
            }
            else {
                console.log(data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const _requestGetUserByFaceImage = async () => {
        setError('')
        dispatch(clearDataUsersFromTablet())

        // setStep(STEP_LOADING)
        dispatch(setLoadingGetUserFace())
        setLoadingQueryByFace(true)
        const dataRequest = JSON.stringify({
            Face: imgBase64
        })

        try {
            const res = await Axios.post(apiFindUserByFace, dataRequest)
            const { data } = res;
            // const isSuccess = data.Code === 0 && data.Cifs
            // if (isSuccess) {
            //     const uniqueCifs = data.Cifs.reduce((cifs, cif) =>
            //         cifs.some(item => item.Cifs?.CustomerNumber === cif.Cifs?.CustomerNumber)
            //             ? cifs
            //             : [...cifs, cif]
            //         , [])
            //     setDataUsers(uniqueCifs)
            // }
            // else {
            //     console.log(data)
            //     // not found user
            //     setError(ERR_FACE_NOT_FOUND)
            // }
            const { Code, Cifs } = data
            const isSuccess = Code === 0 && Cifs
            if(isSuccess) {
                const uniqueCifs = Cifs.reduce((cifs, cif) =>
                    cifs.some(item => item.Cifs?.CustomerNumber === cif.Cifs?.CustomerNumber)
                        ? cifs
                        : [...cifs, cif]
                , [])
                const isTeller = Roles.includes(TELLER)
                const apiGetCifs = isTeller ? apiGetCifsCounter : apiGetCifsCollector

                const promiseLstUserFromTablet = uniqueCifs.map(async user => {
                    const { Cifs, History } = user
                    const { CustomerNumber } = Cifs
                    const dataRequest = JSON.stringify({
                        CounterID: CounterID,
                        CifsNum: CustomerNumber,
                        Type: 0
                    })
                    try {
                        const res = await Axios.post(apiGetCifs, dataRequest)
                        const { data } = res;
                        const isSuccess = data.Code === 0 && data.Cifs;
                        if (isSuccess) {
                            const cif = data.Cifs[0]
                            const { BioInfos: { BioDatas } } = data
                            const face = BioDatas.find(item => item.BioType === FACE)
                            return {
                                Cifs: {
                                    CustomerNumber: cif.CustomerNumber,
                                    Name: cif.Name,
                                    FullNameVi: cif.FullNameVi,
                                    CustomerType: cif.CustomerType,
                                    IdNumber: cif.IdNumber,
                                    IdIssueDate: cif.IdIssueDate,
                                    IdIssuePlace: cif.IdIssuePlace,
                                    MobileNumber: cif.MobileNumber,
                                    Address: cif.Address,
                                    SegmentDesc: cif.SegmentDesc,
                                    Gender: cif.Gender,
                                    Email: cif.Email,
                                    FaceID: cif.FaceID,
                                    faceFromChannel: face?.FromChannel
                                },
                                History
                            }
                        }
                        else {
                            return user
                        }
                    }
                    catch (err) {
                        return user
                    }
                })

                const lstUserFromTablet = await Promise.all(promiseLstUserFromTablet)
                setDataUsers(lstUserFromTablet)
            }
            else {
                setError(ERR_FACE_NOT_FOUND)
            }
        }
        catch (err) {
            console.dir(err)
            const code = err?.response?.data?.Code
            if (code === 1010) {
                // not found user
                setError(ERR_FACE_NOT_FOUND)
            }
        }
        // setStep(STEP_SHOW_IMAGE)
        _handleSetStep(STEP_SHOW_IMAGE)
        dispatch(setLoadingGetUserFace(false))
        setLoadingQueryByFace(false)
    }

    const _handleUploadFace = () => {
        setError('')

        const input = document.createElement('input')
        input.type = "file"
        input.accept = '.png,.jpg,.jpeg'
        input.onchange = async () => {
            const files = input.files;
            const file = files[0]
            console.log(file)
            const { size } = file
            if(size > MAX_SIZE_OF_FILE) {
                setError(ERR_OVERLOAD_FILE)
                return;
            }
            const urlImg = URL.createObjectURL(file)
            setUrlImg(urlImg)
            try {
                const imgBase64 = await fileToBase64(file)
                console.log(imgBase64)
                //data:image/png;base64,
                const base64 = imgBase64.split(',')[1]
                setImgBase64(base64)
                // setStep(STEP_SHOW_IMAGE)
                _handleSetStep(STEP_SHOW_IMAGE)
                setDisabledQueryByFinger(true)
                // requestGetUserByFaceImage(base64)
            }
            catch (err) {
                console.log(err)
            }
        }
        input.click()
    }

    const _handleFallback = () => {
        setError('')
        URL.revokeObjectURL(urlImg)
        setUrlImg(null)
        setImgBase64('')
        // setStep(STEP_INITIAL)
        _handleSetStep(STEP_INITIAL)
        setDisabledQueryByFinger(false)
    }

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(urlImg)
        }
    }, []);

    useEffect(() => {
        if (!resGetUserByImg) return;
        const { Code, Cifs } = resGetUserByImg
        const isSuccess = Code === 0 && Cifs && Cifs.length
        if (isSuccess) {
            const uniqueCifs = Cifs.reduce((cifs, cif) =>
                cifs.some(item => item.Cifs?.CustomerNumber === cif.Cifs?.CustomerNumber)
                    ? cifs
                    : [...cifs, cif]
            , [])
            setDataUsers(uniqueCifs)
        }
        else {
            console.log(Code)
            // not found user
            setError(ERR_FACE_NOT_FOUND)
        }
        // setStep(STEP_INITIAL)
        _handleSetStep(STEP_INITIAL)
        setLoadingQueryByFace(false)
    }, [JSON.stringify(resGetUserByImg)]);

    useEffect(() => {
        if (!dataUsers) return
        dispatch(openPopup(popupBioTypes.PopupShowUserFoundByBioImage, 2, {
            title: 'Kết quả vấn tin hình ảnh khuôn mặt',
            dataUsers: dataUsers.map(({ Cifs: cif, History }) => ({
                CustomerNumber: cif.CustomerNumber,
                Name: cif.Name,
                FullNameVi: cif.FullNameVi,
                CustomerType: cif.CustomerType,
                IDNumber: cif.IdNumber || cif.IDNumber,
                IDIssueDate: cif.IdIssueDate || cif.IDIssueDate,
                IDIssuePlace: cif.IdIssuePlace || cif.IDIssuePlace,
                MobileNumber: cif.MobileNumber || cif.mobilenumber,
                Address: cif.Address,
                SegmentDesc: cif.SegmentDesc,
                Gender: cif.Gender,
                Email: cif.Email || cif.email,
                urlFace: apiGetFace(cif.FaceID),
                faceFromChannel: cif.faceFromChannel,
                history: (() => {
                    if(!History) return {}
                    const {
                        BranchCode,
                        OfficeName,
                        TellerInfos,
                        RequestAt,
                        SupervisorInfos,
                        AcceptAt
                    } = History
                    return {
                        office: `${BranchCode} - ${OfficeName}`,
                        teller: TellerInfos?.Name,
                        timeRequest: moment(RequestAt).format('DD/MM/YYYY HH:mm:ss'),
                        supervisor: SupervisorInfos?.Name,
                        timeAccept: moment(AcceptAt).format('DD/MM/YYYY HH:mm:ss')
                    }
                })() 
            }))
        }))
    }, [dataUsers]);

    useEffect(() => {
        if (loadingGetByFace) {
            _handleSetStep(STEP_LOADING)
            setLoadingQueryByFace(true)
            return;
        }
        setStep(lastStep)
        setLoadingQueryByFace(false)
    }, [loadingGetByFace]);

    return (
        <WrapFaceQuery>
            {
                step === STEP_INITIAL &&
                <>
                    <img className="ic-user-default" src={icDefaultUser} alt="user" />
                    <div className="desc">
                        <div>Chụp hình khuôn mặt</div>
                        <div>từ thiết bị hoặc upload ảnh</div>
                        <div>(dung lượng tối đa 1MB)</div>
                    </div>
                    <div className="wrap-btn">
                        <button onClick={_handleShootPicture} disabled={loadingQueryByFinger}>Chụp hình</button>
                        <button onClick={_handleUploadFace} disabled={loadingQueryByFinger}>Tải lên</button>
                    </div>
                </>
            }
            {
                step === STEP_LOADING &&
                <div className="wrap-loading">
                    <LoadingControl size="32px" loadingPage={false} />
                    <div className="desc-loading">Vui lòng chờ...</div>
                </div>
            }
            {
                step === STEP_SHOW_IMAGE &&
                <>
                    <div className="wrap-face">
                        <img src={urlImg} alt="face" />
                    </div>
                    <div className="wrap-btn">
                        <button onClick={_requestGetUserByFaceImage} disabled={loadingQueryByFinger}>Kiểm tra</button>
                        <button onClick={_handleFallback} disabled={loadingQueryByFinger}>Lấy lại</button>
                    </div>
                </>
            }

        </WrapFaceQuery>
    )
}

export default FaceQuery
