const PENDING = "PENDING";
const ACCEPT = "ACCEPT";
const REJECT = "REJECT";
const UNDEFINED = "undefined";

export const typeStatusBiometrics = {
    PENDING,
    ACCEPT,
    REJECT,
    UNDEFINED
}

const statusBiometrics = {
    [PENDING]: {
        text: "Chờ phê duyệt",
        color: "#ff7e00",
        textInTask: "Đang chờ KSV phê duyệt",
        textInQueueCollector: "Chờ phê duyệt",
        colorInTask: "rgba(255, 126, 0, 0.8)",
        colorInTaskCollector: "#ff7e00"
    },
    [ACCEPT]: {
        text: "Đã phê duyệt",
        color: "rgb(15, 177, 31)",
        textInTask: "Đã được KSV phê duyệt",
        textInQueueCollector: "Đồng ý",
        colorInTask: "rgba(15, 177, 31, 0.8)",
        colorInTaskCollector: "rgb(15, 177, 31)"
    },
    [REJECT]: {
        text: "Từ chối",
        color: "rgb(243, 25, 25)",
        textInTask: "Đã bị KSV từ chối",
        textInQueueCollector: "Từ chối",
        colorInTask: "rgba(243, 25, 25, 0.8)",
        colorInTaskCollector: "rgb(243, 25, 25)",
        nameLabel: "đã từ chối"
    },
    [UNDEFINED]: {
        text: "Chưa đăng ký",
        color: "rgba(34, 34, 34, 0.25)"
    }
}

export default statusBiometrics;