import Axios from 'axios'

import * as types from './types'
import { host } from '../../host'
import * as typeCodeResponses from '../typeCodeResponses'
import catchErrAction from '../../helper/catchErrAction'

const apiGetListUsername = host + 'inBioCollector/get/list-username'

export const getListUsername = () => async (dispatch) => {
    
    const dataRequest = JSON.stringify({
        OfficeIDs: []
    })
    try {
        const res = await Axios.post(apiGetListUsername, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.GET_LIST_USERNAME,
                payload: {
                    tellers: data.ListTeller || [],
                    inbioCollectors: data.ListInbioCollector || [],
                    inSupervisors: data.ListInSupervisor || [],
                    exSupervisors: data.ListExSupervisor || [],
                }
            })
        }
    }
    catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.GET_LIST_USERNAME_ERR,
            false
        )
    }
}