import React, { Component } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { connect } from 'react-redux'

// import data
import { typeNotice } from '../../data/data'

// import action
import { addNewNotify } from '../../actions/notifyAction'

const StyledCountDownTimeControl = styled.div`
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: 500;
    line-height: 1.68;
    color: #222222;
`

const MAX_HOURS_WAIT = 0
const MAX_MINUTES_WAIT = 10

class CountDownTimeControl extends Component {
    constructor(props) {
        super(props)

        this.state = {
            time: '',
            wasNotified: false,
        }

        this.objInterval = {}
        this._isMounted = false
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            clearInterval(this.objInterval)
            this.countTime.style.color = '#222222'
            this.getTimeCountDown(nextProps.data)
        }
    }

    setTwoNumFormat = (num) => ('0' + num).slice(-2)

    setTimeCount = (getStartTime) => {
        const currentTime = moment()

        const duration = moment.duration(currentTime.diff(getStartTime))
        const hrs = this.setTwoNumFormat(duration.hours())
        const mins = this.setTwoNumFormat(duration.minutes())
        let secs = this.setTwoNumFormat(duration.seconds())

        this.setState({ time: `${hrs}:${mins}:${secs}` }, () => {
            const time = this.state.time.split(':')
            const [hours, minutes] = time
            if (
                Number(hours) > MAX_HOURS_WAIT ||
                Number(minutes) >= MAX_MINUTES_WAIT
            ) {
                this.countTime.style.color = '#d71249'
                if (!this.state.wasNotified) {
                    const dataNotify = {
                        type: typeNotice.beyondTime,
                        ticketNum: this.props.ticketNumber,
                        time: moment().format('HH:mm, DD/MM/YYYY'),
                    }
                    this.props.addNewNotify(dataNotify)
                    this.setState({ wasNotified: true })
                }
            }
        })
    }

    getTimeCountDown = (startTime) => {
        const getStartTime = moment().isBefore(moment(startTime))
            ? moment()
            : moment(startTime)
        this.setTimeCount(getStartTime)

        this.objInterval = setInterval(() => {
            this.setTimeCount(getStartTime)
        }, 1000)
    }

    render() {
        return (
            <StyledCountDownTimeControl ref={(ref) => (this.countTime = ref)}>
                {this.state.time}
            </StyledCountDownTimeControl>
        )
    }

    componentDidMount() {
        this._isMounted = true
        this._isMounted && this.getTimeCountDown(this.props.data)
    }

    componentWillUnmount() {
        this._isMounted = false
        clearInterval(this.objInterval)
    }
}

const mapDispatchToProps = (dispatch) => ({
    addNewNotify: (data) => dispatch(addNewNotify(data)),
})

export default connect(null, mapDispatchToProps)(CountDownTimeControl)
