import React from 'react'

// constant
import statusBiometrics, { typeStatusBiometrics } from '../../../../../../data/statusBiometricWithTicket'

// helper
import useMounted from '../../../../../../helper/hooks/useMounted'

// component
import FilterComboBox from '../FilterComboBox'

const ALL_VAL = 'Tất cả'
const objAllVal = {
    key: ALL_VAL,
    value: ALL_VAL
}

const objLstStatusBio = Object.values(typeStatusBiometrics)
    .filter(item => item !== String(undefined))
    .map(item => ({
        key: item,
        value: statusBiometrics[item]?.textInQueueCollector
    }))

const FilterByStatus = ({ handleChange, defaultValue }) => {

    const mounted = useMounted()

    const _handleChange = (obj) => {
        if (!mounted.current) return;

        const { key } = obj
        if (key === ALL_VAL) {
            handleChange('')
            return;
        }
        handleChange(key)
    }

    const defaultVal = (() => {
        if (!defaultValue) return undefined;
        const status = statusBiometrics[defaultValue]

        if (!status) return undefined;
        return {
            key: defaultValue,
            value: status?.textInQueueCollector
        }
    })()

    return (
        <FilterComboBox
            title="Trạng thái"
            lstItemFixed={[objAllVal]}
            lstItems={objLstStatusBio}
            onChange={_handleChange}
            defaultValue={defaultVal || objAllVal}
            haveSearch={false}
        />
    )
}

export default FilterByStatus
