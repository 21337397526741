import React, { useState, useEffect } from 'react'
import { lstStatusSocket } from '../../../reducers/commonReducer'
import LoadingControl from '../LoadingControl'
import { Style } from './index.styles'
import { useSelector } from 'react-redux'

export const componentFollowStatusSocket = {
    [lstStatusSocket.CONNECTING]: (
        <>
            <LoadingControl size="25px" loadingPage={false} />
            <span>Đang kết nối socket...</span>
        </>
    ),
    [lstStatusSocket.CONNECT_FAILED]: (
        <>
            <LoadingControl size="25px" loadingPage={false} />
            <span className="error-connect">Lỗi socket. Đang kết nối lại...</span>
        </>
    ),
    [lstStatusSocket.CONNECT_SUCCESS]: <>
        <span className="connected">Đã kết nối socket</span>
    </>,
}

const StatusSocket = () => {
    const [status, setStatus] = useState(lstStatusSocket.CONNECTING)

    const { commonReducer } = useSelector((state) => ({
        commonReducer: state.commonReducer
    }))

    useEffect(() => {
        setStatus(commonReducer.statusSocket)
    }, [commonReducer.statusSocket])

    return <Style>{componentFollowStatusSocket[status]}</Style>
}

export default StatusSocket
