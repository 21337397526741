import { combineReducers } from "redux";

import listDataSentSupervisor, { initialState as listData } from './listDataSentSupervisor'
import listUsername, { initialState as listUsernameFilter } from './listUsername'

export const initialState = {
    listDataSentSupervisor: listData,
    listUsername: listUsernameFilter
}

const inBioCollector = combineReducers({
    listDataSentSupervisor,
    listUsername
})

export default inBioCollector;