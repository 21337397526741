import styled from 'styled-components';

const Style = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .error-connect {
        color: var(--lipstick);
    }

    .connected {
        ::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            background: green;
            margin-right: 5px;
            border-radius: 50%;
        }
    }
`

export { Style }