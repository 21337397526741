import Axios from "axios";

const setAuth = (isAuth) => {
    if (isAuth) {
        Axios.defaults.withCredentials = process.env.NODE_ENV === 'production' ? false : true;
        Axios.defaults.headers.common["CSRF-TOKEN"] = isAuth.CsrfToken;
        Axios.defaults.headers.common.office = isAuth.OfficeID || "";
        return;
    }
    Axios.defaults.withCredentials = false;
    delete Axios.defaults.headers.common["CSRF-TOKEN"];
    delete Axios.defaults.headers.office;
}

export default setAuth;