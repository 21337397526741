import styled from 'styled-components'

const Button = styled.button`
    margin-left: 70px;
    outline: none;
    border: none;
    border-radius: 18px;
    background-color: #005993;
    padding: 8px 12px;
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff;
    min-width: 80px;
    

    &:hover {
        cursor: pointer;
    }
`;

export {
    Button
}