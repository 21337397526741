import ic_close_noti from '../images/ic_counter_notification_close.svg'
import * as counterStates from '../data/counterStates'
import * as ticketStatuses from '../data/ticketStatuses'
import { INBIOCOLLECTOR } from '../data/userRole'
import { openPopupConfirmStopCounter } from './actionOpenPopup'

const handleStopCounter = (_this, counterProperties) => {
    const {
        listVipTicket,
        listCurrentTicket,
        waitingTicket,
        listMoveTo,
        counterState,
        dataLogin,
        enrollId,
    } = counterProperties

    if (
        dataLogin.Roles.includes(INBIOCOLLECTOR) &&
        Object.keys(enrollId).length
    ) {
        _this.props.openPopup(9, 1, {
            label:
                'Bạn còn khách hàng trong quầy. Vui lòng phục vụ khách hàng.',
            icon: ic_close_noti,
            width: '248px',
        })
        return false
    }

    if (counterState === counterStates.OPEN_COUNTER) {
        const isListCurrentTicketNotEmpty = listCurrentTicket.length > 0
        const isListMoveToNotEmpty = listMoveTo.length > 0
        const isListVipTicketHaveTicketMoveTo = listVipTicket.some(
            (item) => item.Status === ticketStatuses.MOVING
        )

        if (isListCurrentTicketNotEmpty) {
            _this.props.openPopup(9, 1, {
                label:
                    'Bạn còn khách hàng trong quầy. Vui lòng phục vụ khách hàng hoặc chuyển đến quầy khác.',
                icon: ic_close_noti,
                width: '248px',
            })
            return false
        }

        if (
            isListMoveToNotEmpty ||
            isListVipTicketHaveTicketMoveTo
        ) {
            _this.props.openPopup(9, 1, {
                label:
                    'Bạn còn khách hàng chuyển đến. Vui lòng phục vụ khách hàng hoặc chuyển đến quầy khác.',
                icon: ic_close_noti,
                width: '248px',
            })
            return false
        }

        const isListVipTicketNotEmpty = listVipTicket.length > 0
        const isListWaitingTicketNotEmpty = waitingTicket.length > 0
        const isHaveTicketInQueue =
            isListMoveToNotEmpty ||
            isListVipTicketNotEmpty ||
            isListWaitingTicketNotEmpty

        if (isHaveTicketInQueue) {
            openPopupConfirmStopCounter(_this, {}, 'handleStopCounter.js')
            return false
        }
    }
    return true
}

export default handleStopCounter
