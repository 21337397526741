import styled from 'styled-components';


const Styles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.4);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-size: 16px;

    .popup {
        text-align: center;
        background: #fafafa;
        border-radius: 10px;
    }

    img,
    div.title {
        margin-top: 20px;
    }

    div.title,
    div.title-flow,
    button {
        font-weight: bold;
    }

    div.title,
    div.title-flow {
        color: #222222;
        padding: 0 22px;
    }

    div.title-flow {
        margin-top: 5px;
    }

    button {
        margin: 24px auto 20px;
    }
`

export { Styles }