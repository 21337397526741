import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from './reducers'
import thunk from 'redux-thunk'

const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
    trace: true,
    // traceLimit: 25
});

export default function configureStore() {
    let store = createStore(
        rootReducer,
        composeEnhancers(
    	applyMiddleware(thunk)
        )
    )
    return store
}