import React, { useEffect } from 'react'

const useMoveElement = (refElement, querySelectEleDrop) => {
    useEffect(() => {
        const dragElement = (elmnt, headerClass) => {
            var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
            const header = elmnt.querySelector(headerClass)
            if (header) {
                /* if present, the header is where you move the DIV from:*/
                header.onmousedown = dragMouseDown;
            } else {
                /* otherwise, move the DIV from anywhere inside the DIV:*/
                elmnt.onmousedown = dragMouseDown;
            }

            function dragMouseDown(e) {
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }


            function elementDrag(e) {
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;

                // set the element's new position:
                const tmpTop = (elmnt.offsetTop - pos2);
                const tmpLeft = (elmnt.offsetLeft - pos1);
                const { scrollHeight, scrollWidth, clientHeight, clientWidth } = refElement.current;
                const { innerHeight, innerWidth } = window;
                if (tmpTop < scrollHeight / 2 && pos2 > 0) {
                    elmnt.style.top = scrollHeight / 2 + "px";
                }
                else if (tmpTop > scrollHeight / 2 + innerHeight - clientHeight) {
                    elmnt.style.top = scrollHeight / 2 + innerHeight - clientHeight + "px";
                }
                else {
                    elmnt.style.top = tmpTop + "px";
                }

                if (tmpLeft < scrollWidth / 2 && pos1 > 0) {
                    elmnt.style.left = scrollWidth / 2 + "px";
                }
                else if (tmpLeft > scrollWidth / 2 + innerWidth - clientWidth) {
                    elmnt.style.left = scrollWidth / 2 + innerWidth - clientWidth + "px";
                }
                else {
                    elmnt.style.left = tmpLeft + "px";
                }
            }

            function closeDragElement() {
                /* stop moving when mouse button is released:*/
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }
        dragElement(refElement.current, querySelectEleDrop)
    }, []);
}

export default useMoveElement
