import React, { useState } from 'react'

// image
import icCloseGray from '../../../../../images/ic_close_gray.svg'
import icHistory from '../../../../../images/ic_history.svg'

// styles
import { WrapPopupShowCustDetails } from './index.styles'
import { WrapPopupStyles } from '../../../../Popup/Queries/WrapPopupStyles'

// component
import CustInfos from './CustInfos'
import CustBioBl from './CustBios/indexBl'

const CIF_TAB = 'CIF_TAB'
const BIO_TAB = 'BIO_TAB'

const PopupShowCustDetails = ({
    data: {
        bioInfosType,
        status,
        // {
        //     CustomerNumber,
        //     Name,
        //     FullNameVi,
        //     Gender,
        //     SegmentDesc,
        //     IdNumber,
        //     MobileNumber,
        //     IdIssueDate,
        //     Email,
        //     IdIssuePlace,
        //     Address,
        //     CustomerType
        // }
        cifs,
        // {
        //     [bioType]: {
        //         bioType: bioType,
        //         current?: {},
        //         before?: {}
        //     }
        // }
        bios
    },
    handleClose,
    handleShowHistory
}) => {
    const { CustomerNumber } = cifs

    const [activeTab, setActiveTab] = useState(CIF_TAB);

    return (
        <WrapPopupStyles>
            <WrapPopupShowCustDetails>
                <div className="wrap-header">
                    <div className="title">Thông tin khách hàng</div>
                    <img
                        className="ic-close"
                        src={icCloseGray}
                        alt="close"
                        onClick={handleClose}
                    />
                    <div className="wrap-tab">
                        <div className="wrap-inner-tab">
                            <label htmlFor="cif-tab">
                                <input
                                    type="radio"
                                    id="cif-tab"
                                    value={CIF_TAB}
                                    name="tab"
                                    defaultChecked={true}
                                    onClick={() => setActiveTab(CIF_TAB)}
                                />
                                <span>Thông tin CIF</span>
                            </label>
                            <label htmlFor="bio-tab">
                                <input
                                    type="radio"
                                    id="bio-tab"
                                    value={BIO_TAB}
                                    name="tab"
                                    onClick={() => setActiveTab(BIO_TAB)}
                                />
                                <span>Thông tin STH</span>
                            </label>
                        </div>
                        <div className="query-code"><span>CIF:</span> {CustomerNumber}</div>
                        <div className="status-data" style={{ color: status?.color }}><span>Trạng thái:</span> {status?.text}</div>
                    </div>
                </div>
                {
                    activeTab === CIF_TAB &&
                    <CustInfos custInfos={cifs} />
                }
                {
                    activeTab === BIO_TAB &&
                    <CustBioBl bioInfosType={bioInfosType} bios={bios} />
                }
                <div className='footer'>
                    <img
                        className="ic-history"
                        src={icHistory}
                        alt="history"
                        onClick={handleShowHistory}
                    />
                </div>
            </WrapPopupShowCustDetails>
        </WrapPopupStyles>
    )
}

export default PopupShowCustDetails
