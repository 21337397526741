import React, { useEffect, useState } from 'react';
import Modal from '../../../../Control/Modal';
import ic_warning from '../../../../../images/ic_warning.svg'
import PopupMatchFace from './PopupMatchFace';
import { useDispatch } from 'react-redux';
import { findDuplFace } from '../../../../../actions/biometricAction';

const mockData = {
    DuplicateFaces: [
        {
            "CustomerNumber": "1600110312",
            "IdNumber": "1600110312",
            "IdIssueDate": "21/11/2019",
            "IdIssuePlace": "CA VINH LONG",
            "Name": "PHAN VU MY TRANG",
            "Address": "Address Line 1 Address Line 2   Hanoi, VIET NAM",
            "FaceID": "5eb2435f82f808fda07e921c",
            "PhoneNumber": "0908849565",
            "CounterID": "5ea9204a4f6b6047a37ba93e",
            "OfficeID": "5df757031a8ded6f590043b3",
            "OfficeName": "Äá»‘ng Äa",
            "TellerID": "5e0034784ec29f9952fa4b67",
            "TellerName": "SDK function test 03",
            "SupervisorID": "5df83eabb27f49a2eff6a6b0",
            "SupervisorName": "wsdkppe40",
            "CreateAt": "2020-05-06T04:55:59.972Z"
        },
        {
            "CustomerNumber": "1400000811",
            "IdNumber": "1400000811",
            "IdIssueDate": "14/11/2016",
            "IdIssuePlace": "CUC CS DKQLCTVDLQGVDC",
            "Name": "MA RU CO",
            "Address": "Address Line 1 Address Line 2   Hanoi, VIET NAM",
            "FaceID": "5ea928064f6b6047a37baa23",
            "PhoneNumber": "0912118008",
            "CounterID": "5ea9204a4f6b6047a37ba93e",
            "OfficeID": "5df757031a8ded6f590043b3",
            "OfficeName": "Äá»‘ng Äa",
            "TellerID": "5e0034784ec29f9952fa4b67",
            "TellerName": "SDK function test 03",
            "SupervisorID": "5df83eabb27f49a2eff6a6b0",
            "SupervisorName": "wsdkppe40",
            "CreateAt": "2020-04-29T07:08:54.556Z"
        },
        {
            "CustomerNumber": "2000235380",
            "IdNumber": "2000235380",
            "IdIssueDate": "14/02/2009",
            "IdIssuePlace": "CA KHANH HOA",
            "Name": "Customer Name",
            "Address": "Address Line 1 Address Line 2   Hanoi, VIET NAM",
            "FaceID": "5eafc3a382f808fda069e015",
            "PhoneNumber": "0913486117",
            "CounterID": "5ea9204a4f6b6047a37ba93e",
            "OfficeID": "5df757031a8ded6f590043b3",
            "OfficeName": "Äá»‘ng Äa",
            "TellerID": "5e0034784ec29f9952fa4b67",
            "TellerName": "SDK function test 03",
            "SupervisorID": "5df83eabb27f49a2eff6a6b0",
            "SupervisorName": "wsdkppe40",
            "CreateAt": "2020-05-04T07:26:27.888Z"
        }
    ], 
    CustomerCif: "1600110312"
}

const FaceDuplicate = ({ cusNum }) => {
    const dispatch = useDispatch()
    const [isShow, setIsShow] = useState(false);
    const [data, setData] = useState({
        DuplicateFaces: [],
        CustomerCif: ""
    });

    useEffect(() => {
        dispatch(findDuplFace(cusNum, (cifs) => {
            setData({
                DuplicateFaces: cifs,
                CustomerCif: cusNum
            })
        }))
    }, []);

    return (
        <>
            <div className="notice-duplicate-data" onClick={() => setIsShow(true)}>
                <img src={ic_warning} alt="ic-warning" />
                <span>Dữ liệu bị trùng trong hệ thống</span>
            </div>
            {
                isShow &&
                <Modal isShow={isShow}>
                    <PopupMatchFace
                        onClosePopup={() => setIsShow(false)}
                        data={data}
                    />
                </Modal>
            }
        </>
    )
}
export default FaceDuplicate