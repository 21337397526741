import styled from "styled-components";


const Bound = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    transition: 0.5s all ease-in-out;
    .tab-container {
        display: flex;
        position: relative;
        .customer {
            /* display: flex; */
            /* flex-basis: 240px; */
            /* justify-content: center; */
            /* align-items: center; */

            text-align: center;
            height: 30px;
            line-height: 30px;
            border-radius: 10px 10px 0 0;
            font-size: 16px;
            font-weight: bold;
            /* line-height: 1.19; */
            line-height: 1.8;
            padding: 0 20px;
            cursor: pointer;
            width: 240px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:nth-child(2) {
                margin-left: -20px;
            }
        }

        .socket-status {
            position: absolute;
            right: 20px;
            bottom: 0;
            /* height: 100%; */
            background: #fff;
            display: flex;
            align-items: center;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
            padding: 5px;
            border-radius: 5px 5px 0 0;
            font-size: 12px;
            color: #005993;
            font-weight: 500;
        }
    }
    .detail-inform-customer {
        border-radius: 0 20px 20px 20px;
        padding: 24px;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.18);
        background-color: #ffffff;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-gap: 5px;
        
        .avatar {
            height: 220px;
            position: relative;
            /* display: flex; */

            .task-control-button {
                /* display: flex; */
                /* flex-direction: column; */
                /* justify-content: space-between; */
                /* width: fit-content; */
                /* position: absolute;
                top: 40px;
                left: -7px; */
                position: absolute;
                z-index: 99;
                display: block;

                & > *:first-child {
                    margin-top: 20px;
                }
            }

            div.wrapper-img {
                position: relative;
                height: 100%;
                margin: 0 41px 0 41px;
                .img_user {
                    height: 100%;
                    object-fit: contain;
                    border-radius: 6px;
                }
                .div_ic_subAvatar {
                    position: absolute;
                    /* right: 10px; */
                    /* bottom: 8px; */
                    right: 0;
                    bottom: 1px;
                    z-index: 1;
                }
                .div_ic_finger {
                    position: absolute;
                    bottom: 8px;
                    right: 48px;
                }
                .status-biometric {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    font-size: 10px;
                    padding: 10px 0 10px 5px;
                    box-sizing: border-box;
                    text-align: left;
                    color: #fff;
                    border-radius: 0 0 6px 6px;
                }
            }

            .notice-duplicate-data {
                margin: 14px 41px 0 41px;
                display: flex;
                align-items: center;
                cursor: pointer;
                span {
                    padding-left: 7px;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 110%;
                    color: #F31919;
                }
            }
        }

        .task-information {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 5px;

            .grid-col {
                display: grid;
                grid-template-rows: repeat(4, 1fr);
                grid-gap: 5px;
                .task-info-item {
                    display: flex;
                    flex-direction: column;
                    max-width: 100%;
                    overflow: hidden;

                    &:not(:last-child){
                        margin-bottom: 20px;
                    }

                    .title {
                        text-transform: uppercase;
                        font-size: 11px;
                        font-weight: bold;
                        line-height: 1.7;
                        color: #005993;
                        margin-bottom: 5px;
                    }
                    .info {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.4;
                        color: #000000;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .time {
                        font-size: 28px;
                        font-weight: 500;
                        line-height: 0.93;
                        letter-spacing: 1px;
                        color: #000000;
                        width: 160px;
                    }
                }
            }
        }

        .inform-service {
            display: flex;
            flex-direction: column;
            grid-row: 1/-1;
            max-width: 100%;
            overflow: hidden;
            .title {
                text-transform: uppercase;
                font-size: 11px;
                font-weight: bold;
                line-height: 1.7;
                color: #005993;
                margin-bottom: 5px;
            }
            .inform-service-wrapper {
                overflow-y: scroll;
                max-height: calc(50px*4);

                ::-webkit-scrollbar {
                    display: none;
                }
                .transaction {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.68;
                    color: #222222;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &.show-tooltip {
                        position: relative;
                        z-index: 24;
                        .tooltip-trans-inprogress-ticket {
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            font-family: 'SVN-Gilroy';
                            visibility: visible;
                            box-sizing: border-box;
                            bottom: 100%;
                            background: #555;
                            padding: 5px;
                            border-radius: 7px;
                            color: #ffffff;
                            &::after {
                                content: ' ';
                                position: absolute;
                                top: 100%; 
                                left: 50%;
                                margin-left: -5px;
                                border-width: 5px;
                                border-style: solid;
                                border-color: #555 transparent transparent
                                    transparent;
                            }
                        }
                        &:hover {
                            z-index: 25;
                        }
                        &:hover .tooltip-trans-inprogress-ticket {
                            visibility: visible;
                        }
                    }
                }
            }
        }

        .button-contact {
            & > * {
                display: block;
            }
        }
    }
    .loading {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 15px;
        right: 25px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.25;
        font-weight: 500;
        color: #000000;
        .title_loading {
            margin: 0 0 0 7px;
        }
    }
`
export { Bound }