import React, { Component } from 'react'
import styled from "styled-components";

const StyledButtonControll = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 18px;
    white-space: nowrap; 
    /* overflow: hidden; */
    background: white;
    /* margin-left: 20px; */
    opacity: ${props => props.isDisabled?"0.5":"unset"};

    &:hover {
        cursor: pointer;

        /* ::before, ::after {
            visibility: visible;
        } */
    }

    /* &::before {
        content: '${props => props.title}';
        position: absolute;
        bottom: 46%;
        left: 50%;
        visibility: hidden;
        transform: translate(-50%, -50%);
        background: var(--warm-grey);
        padding: 5px;
        border-radius: 6px;
        font-family: 'SVN-Gilroy';
        font-size: 18px;
        color: white; 
    } */

    /* &::after {
        content: " ";
        position: absolute;
        visibility: hidden;
        left: 50%;
        top: 0;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: var(--warm-grey) transparent transparent transparent;
    } */

    img {
        position: absolute;
        z-index: 2;
        background: white;
        width: 24px;
        height: 24px;
        margin-right: 6px;
        padding-left: 5px;
        border-radius: 18px;
    }
`;

export default class BtnControlHaveTooltip extends Component {
    state = {
        widthBtn: "32px"
    }

    handleClick = (e) => {
        e.preventDefault();
        if(this.props.isDisabled) return;
        this.props.showPopupBiometric()
    }

    render() {
        return (
            <StyledButtonControll 
                onClick={this.handleClick}
                title={this.props.title}
                isDisabled={this.props.isDisabled}>
                <img 
                    src={this.props.source} 
                    alt={this.props.alt}
                />
            </StyledButtonControll>
        )
    }
}
