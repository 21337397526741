import styled from 'styled-components'

const WrapQueryFingerResults = styled.div`
    width: 776px;
    background: #FAFAFA;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    font-family: 'SVN-Gilroy';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .wrap-title {
        padding: 13px 20px;
        background: #FFFFFF;
        box-sizing: border-box;
        height: 80px;
        border-bottom: 3px solid #EFEFEF;
        border-radius: 10px 10px 0 0;
        & > div {
            display: flex;
            justify-content: space-between;
            .title {
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 22px;
                color: #000000;
            }

            .ic-close {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        :hover {
            cursor: move;
        }
    }

    .wrap-list-customer {
        /* max-height: calc(470px + 8px);
        overflow-y: auto;
        .wrap-customer {
            display: flex;
            margin-top: 8px;
            padding: 14px 20px 11px;
            background: #FFFFFF;
            box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.25);

            .side {

                &.left-side {
                    flex-shrink: 0;
                    .wrap-bio-img {
                        width: 124px;
                        &.face {
                            margin-top: 15px;
                        }
                        &.finger {
                            margin-top: 6px;

                            img {
                                box-sizing: border-box;
                                border: 0.5px solid #AAAAAA;
                            }
                        }

                        img {
                            width: 100%;
                            border-radius: 5px;
                        }
                    }

                    .finger-name {
                        margin-top: 5px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 130%;
                        color: #005993;
                    }
                }

                &.right-side {
                    flex-grow: 1;
                    margin-left: 46px;
                    .cust-name {
                        margin-bottom: 15px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 128%;
                        color: #222222;
                    }

                    & > .field:not(:first-of-type) {
                        margin-top: 9px;
                    }
                }

                .field {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 128%;
                    color: #222222;

                    &.chanel {
                        margin-top: 8px;
                        font-size: 11px;
                        line-height: 130%;
                    }

                    span {
                        color: #AAAAAA;
                    }
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color:transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#C4C4C4;;
            background-clip: padding-box;
            border-radius: 20px;
        } */
        max-height: calc(460px + 8px);
        overflow-y: auto;

        .field {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 128%;
            color: #222222;

            &.chanel {
                margin-top: 8px;
                font-size: 11px;
                line-height: 130%;
            }

            span {
                color: #AAAAAA;
            }
        }

        .wrap-customer {
            margin-top: 8px;
            display: flex;
            padding: 14px 20px 11px;
            background: #FFFFFF;
            box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.25);

            .side {

                &:last-child {
                    margin-left: 30px;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                }

                .wrap-bio-img {
                    width: 150px;
                    margin-top: 15px;

                    &.face-img {
                        img {
                            width: 100%;
                            border-radius: 5px;
                        }
                    }

                    &.finger-img {
                        display: flex;
                        justify-content: space-between;
                        img {
                            width: 70px;
                            border-radius: 5px;
                        }
                    }

                }

                .finger-name {
                    margin-top: 5px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 130%;
                    color: #005993;
                }

                .cust-name {
                    margin-bottom: 15px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 128%;
                    color: #222222;
                }

                .grid {
                    display: grid;
                    &.grid-cust-info {
                        grid-template-columns: repeat(2, 1fr);
                        &:not(:first-child) {
                            margin-top: 12px;
                        }
                    }
                }

                .wrap-approved-infos {
                    margin-top: 30px;
                    flex-grow: 1;
                    & > .field:not(:first-child) {
                        margin-top: 12px;
                    }
                }

                .wrap-btn-show-details {
                    text-align: right;
                    a {
                        color: var(--peacock-blue);
                        text-decoration: underline;
                        font-size: 14px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        &::-webkit-scrollbar {
            width: 15px;
            background-color:transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#C4C4C4;;
            background-clip: padding-box;
            border-radius: 20px;
        }
    }

    .wrap-footer {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border-radius: 0 0 10px 10px;
        button {
            outline: none;
            border: none;
            padding: 8px 12px;
            background: var(--peacock-blue);
            text-transform: uppercase;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 120%;
            color: #FFFFFF;
            border-radius: 18px;

            &:hover {
                cursor: pointer;
            }
        }
    }
`;

export {
    WrapQueryFingerResults
}