import React from 'react'

// styles
import { WrapCustBios } from './index.styles'

// images
import bg_finger from '../../../../../../images/bg_finger.svg'
import icDefaultUser from '../../../../../../images/default_user.svg'
import icZoom from '../../../../../../images/ic_zoom.svg'

// helper
import getChannelName from '../../../../../../helper/getChannelName'

const biometrictFingerData = [
    {
        typeFinger: 'THUMB_LEFT',
        bottom: '86',
        left: '24',
    },
    {
        typeFinger: 'INDEX_LEFT',
        bottom: '209',
        left: '57',
    },
    {
        typeFinger: 'MIDDLE_LEFT',
        bottom: '236',
        left: '91',
    },
    {
        typeFinger: 'RING_LEFT',
        bottom: '234',
        left: '145',
    },
    {
        typeFinger: 'PINKY_LEFT',
        bottom: '211',
        left: '200',
    },
    {
        typeFinger: 'THUMB_RIGHT',
        bottom: '86',
        right: '34',
    },
    {
        typeFinger: 'INDEX_RIGHT',
        bottom: '208',
        right: '64',
    },
    {
        typeFinger: 'MIDDLE_RIGHT',
        bottom: '235',
        right: '99',
    },
    {
        typeFinger: 'RING_RIGHT',
        bottom: '233',
        right: '152',
    },
    {
        typeFinger: 'PINKY_RIGHT',
        bottom: '210',
        right: '208',
    },
]

const CustBios = ({
    handleShowFace,
    urlFace,
    statusFace,
    faceChannel,
    statusFinger,
    handleShowFinger
}) => {
    return (
        <WrapCustBios>
            <div className="first-col">
                <div className="wrap-face">
                    <div className="wrap-inner-face" onClick={handleShowFace}>
                        <img
                            src={urlFace || icDefaultUser}
                            alt="face"
                            className="face"
                        />
                        {
                            !!urlFace &&
                            <img className="ic-zoom" src={icZoom} alt="zoom" />
                        }
                    </div>
                </div>
                {
                    !!statusFace &&
                    <div className="status">Trạng thái: <span className={statusFace.class}>{statusFace.text}</span></div>
                }
                <div className="channel">Kênh tác nghiệp: {getChannelName(faceChannel)}</div>
            </div>
            <div className="last-col">
                <div className="status-note">
                    <div className="status-note-item"><span className="delete"></span>Xóa</div>
                    <div className="status-note-item"><span className="active"></span>Hoạt động</div>
                    <div className="status-note-item"><span className="close"></span>Đóng</div>
                    <div className="status-note-item"><span className="hang"></span>Treo</div>
                </div>
                <img src={bg_finger} alt="hands" />
                <div className="hand-title left">Bàn tay trái</div>
                <div className="hand-title right">Bàn tay phải</div>
                {
                    biometrictFingerData.map(finger => {
                        const statusOfFingerItem = statusFinger[finger.typeFinger]
                        if (statusOfFingerItem)
                            return (
                                <img
                                    key={finger.typeFinger}
                                    onClick={() => handleShowFinger(finger.typeFinger)}
                                    src={statusOfFingerItem.imgUrl}
                                    alt="finger"
                                    style={{
                                        top: finger.top ? `${finger.top}px` : undefined,
                                        left: finger.left ? `${finger.left}px` : undefined,
                                        bottom: finger.bottom ? `${finger.bottom}px` : undefined,
                                        right: finger.right ? `${finger.right}px` : undefined,
                                    }}
                                    className="finger"
                                />
                            )
                        return null
                    })
                }
            </div>
        </WrapCustBios>
    )
}

export default CustBios
