import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const WrapModal = styled.div`
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const Modal = ({ isShow, children }) => isShow ?
    ReactDOM.createPortal(
        <WrapModal id="modal-wrapper">
            {children}
        </WrapModal>,
        document.querySelector('body'),
    )
    :
    null
export default Modal
