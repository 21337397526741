import { CORPCUST } from '../data/serviceTypes'

const isDisableEventWithCORPCust = (ticketId, counterReducer) => {
    const currentTicket = counterReducer.listCurrentTicket.find(
        (item) => item.TicketID === ticketId
    )
    if (!currentTicket) return false

    const { listTypeCounterServices } = counterReducer

    const typeCORPCust =
        listTypeCounterServices &&
        listTypeCounterServices.find((item) => item.ServiceType === CORPCUST)
    if (!typeCORPCust) return false

    const isTicketIncludeCORPService =
        currentTicket.ListService &&
        currentTicket.ListService.find((item) => typeCORPCust.ID === item)
    if (isTicketIncludeCORPService) return true

    const CustType = currentTicket?.CustomerInfos?.CustType
    const KH_DOANH_NGHIEP = "1"
    if (CustType === KH_DOANH_NGHIEP) return true

    return false
}

export default isDisableEventWithCORPCust
