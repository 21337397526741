import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'

// image
import ic_fail from '../../../images/ic_counter_notification_close.svg';

// component
import ButtonConfirmControl from '../../Control/ButtonConfirmControl';

// action
import {
    verifyFinger,
    reportVerifyFingerFailedToSupervisor,
    removeStatusWaitingVerifyFinger
} from "../../../actions/counterAction";
import { closePopup } from '../../../actions/popupBioAction'

// data
import { nameFingerPrint } from "../../../data/bioTypes";

const Bound = styled.div`
  .block_information{
    margin: 36px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    .block_img {
      .ic_fail{
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
    .content {
      margin: 20px 0 0 0;
      width: 226px;
      height: 40px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      color: #222222;
      text-align: center;
    }
    .block_info {
      margin: 16px 0 0 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #979797;
      text-align: center;
      .info_finger {
        color: #005993; 
        
      }
    }
  }
`

export const BoundReason = styled.div`
  margin: 30px 0 0 0;
  padding: 0 24px;
  .title_reason {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.26;
    color: #005993;
    text-transform:uppercase;
  }
  .text_reason {
    margin: 5px 0 0 0;
    padding:10px 10px;
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    border-radius: 4px;
    border: solid 0.5px #c8c8c8;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
    color: #222222;
    text-transform: 10px;
    resize: none;
    outline:none;
    &::placeholder {
      color: #C7C7C7;
    }
  }
`

export const BoundButton = styled.div`
  margin:28px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn {
    margin: 0 10px;
  }
`

class CheckCustomerFailFirst extends Component {

    // constructor(props) {
    //   super(props);
    //   this.state = {
    //     textReason: this.props.initialReason? this.props.initialReason : ""
    //   }
    // }

    contentReason = (e) => {
    // this.setState({
    //   [e.target.name]: e.target.value.trim()
    // })
        this.props.setReasonFailed(e.target.value)
    }

    changeNumber = (number) => {
        return number > 9
            ? "" + number
            : "0" + number;
    }

    handleRetryVerifyCustomer = () => {
        if (!this.props.numsOfTimeTryVerify) return;
        // reset state
        // call verify finger
        const { ticketId, verifyCustomer } = this.props;
        const customerNumber = verifyCustomer[ticketId].result.CustomerNumber;
        this.props.decreaseNumsOfTimeTryVerify();
        this.props.verifyFinger(ticketId, customerNumber);
    }

    handleSendSupervisor = () => {
        const { ticketId, verifyCustomer } = this.props;
        const customerNumber = verifyCustomer[ticketId].result.CustomerNumber;
        // debugger;
        // this.props.reportVerifyFingerFailedToSupervisor(ticketId, customerNumber, this.state.textReason)
        this.props.reportVerifyFingerFailedToSupervisor(ticketId, customerNumber, this.props.initialReason.trim())
        const POPUP_LEVEL_1 = 1;
        this.props.closePopup(POPUP_LEVEL_1);
        this.props.removeStatusWaitingVerifyFinger(ticketId, true)
    }

    render() {
        const { ticketId, verifyCustomer } = this.props;
        const { ValidFingerType } = verifyCustomer[ticketId].result;
        // const ValidFingerType = []
        return (
            <Bound>
                <div className="block_information">
                    <div className="block_img">
                        <img className='ic_fail' src={ic_fail} alt="ic_fail" />
                    </div>
                    <div className="content">
            Vân tay xác thực không trùng với hình ảnh lưu trữ
                    </div>
                    <div className="block_info">
                        <div className='number_finger'>
              Thông tin vân tay lưu trữ ({ValidFingerType && ("0" + ValidFingerType.length).slice(-2)}):
                        </div>
                        <p className='info_finger'>
                            {
                                ValidFingerType &&
                ValidFingerType
                    .map(item =>
                        nameFingerPrint[item].nameInVerify)
                    .join(", ")
                            }
                        </p>
                    </div>
                </div>
                <BoundReason>
                    <p className='title_reason'>
            Lý do xác thực không thành công(<span style={{ color: "var(--vermillion)" }}>*</span>)
                    </p>
                    <textarea
                        onChange={this.contentReason}
                        //value={this.state.textReason}
                        value={this.props.initialReason}
                        className='text_reason'
                        type="text"
                        name='textReason'
                        maxLength="200"
                        placeholder='Nội dung không quá 200 ký tự'
                    />
                </BoundReason>
                <BoundButton>
                    <div
                        className="btn"
                        style={{
                            opacity: this.props.numsOfTimeTryVerify ? 1 : 0.5
                        }}
                    >
                        <ButtonConfirmControl
                            bg_color='#d71249'
                            titleConfirm={`thử lại (${this.props.numsOfTimeTryVerify})`}

                            onSubmit={this.handleRetryVerifyCustomer}
                        />
                    </div>
                    <div className="btn">
                        <ButtonConfirmControl
                            bg_color='#005993'
                            titleConfirm='gửi ksv'
                            isDisabled={!this.props.initialReason.trim()}
                            onSubmit={this.handleSendSupervisor}
                        />
                    </div>
                </BoundButton>
            </Bound>
        )
    }
}

const mapStateToProps = state => ({
    verifyCustomer: state.counterReducer.verifyCustomer
})

const mapDispatchToProps = dispatch => ({
    verifyFinger: (ticketId, customerNumber) => dispatch(verifyFinger(ticketId, customerNumber)),
    reportVerifyFingerFailedToSupervisor: (tickedId, customerNumber, note) => dispatch(reportVerifyFingerFailedToSupervisor(tickedId, customerNumber, note)),
    closePopup: (levelPopup) => dispatch(closePopup(levelPopup)),
    removeStatusWaitingVerifyFinger: (ticketId, isNotCallApi = false) =>
        dispatch(removeStatusWaitingVerifyFinger(ticketId, isNotCallApi))
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckCustomerFailFirst);
