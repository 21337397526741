import React, { Component } from 'react'
import styled from 'styled-components'

//images
import ic_counter_notification_question from '../../images/ic_counter_notification_question.svg'
//control
import ButtonConfirmControl from '../Control/ButtonConfirmControl';

const Bound = styled.div`
    width:100%;
    height:100vh;
    background: rgb(0,0,0,0.6);
    position: absolute;
    z-index: 99;
    .main-bold{
        padding: 20px 50px 15px 50px;
        background-color: #fafafa;
        z-index: 9;
        box-shadow: 0 0 10px;
        width: ${props => props.widthPopup || "auto"};
        height: auto;
        border-radius: 5px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .row_question{
        display:flex;
        align-items: center;
        justify-content: center;
        .img_question{
            width: 52px;
            height: 52px;
            object-fit: contain;
        }
    }
    .row_title{
        display:flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 20px 0 0 0;
        .title{
            font-family: SVN-Gilroy;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.13;
            letter-spacing: normal;
            color: #222222;
            span.specific {
                color: var(--vermillion);
            }
        }

        /* .cusInfos {
            display: flex
        } */

        .cusName {
            max-width: 25ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            vertical-align: bottom;
        }
    }
    .row_btn_confirm{
        display:flex;
        align-items: center;
        justify-content: center;
        margin: 37px 0 0 0;
        .btn_exit{
            margin: 0 20px 0 0;
        }
    }
`;
export default class PopupAccept extends Component {
    render() {
        const { data } = this.props
        // console.log('dataaaaaaaa',data)
        return (
            <Bound widthPopup={data.widthPopup}>
                <div className='main-bold'>
                    <div className="row_question">
                        <span className='img_question'>
                            <img src={data.image_noti ? data.image_noti : ic_counter_notification_question} alt="notice" />
                        </span>
                    </div>
                    <div className="row_title">
                        <div className='title'>{data.title}</div>
                    </div>
                    <div className="row_btn_confirm">
                        <div className="btn_accept">
                            <ButtonConfirmControl
                                titleConfirm={data.titleConfirm}
                                width={data.btnWidth}
                                bg_color={data.bg_color}
                                onSubmit={() => { this.props.onClose() }}
                            />
                        </div>
                    </div>
                </div>

            </Bound>
        )
    }
}
