import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;

const Select = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 9px 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.5px solid #BEBEBE;
    border-radius: 4px;
    background: #FFFFFF;

    :hover {
        cursor: pointer;
    }

    div {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 128%;
        color: #222222;
    }

    ${props => props.extendStyles}
`;

const MAX_NUM_DROPDOWN = 5

const DropDown = styled.div`
    position: absolute;
    width: 100%;
    border: 0.5px solid #BEBEBE;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
    z-index: 1;

    .item {
        box-sizing: border-box;
        height: 38px;
        padding: 11px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #222222;

        div {
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        :hover {
            cursor: pointer;
        }
    }
    
    .lst-fixed-item,
    .lst-scroll-item {
        margin-right: 3px;
    }

    .lst-scroll-item {
        max-height: calc(${props => props.maxNumShowDropdown + 1 || MAX_NUM_DROPDOWN}*38px);
        overflow-y: scroll;
        width: 100%;

        ::-webkit-scrollbar {
            width: 3px;
        }
        ::-webkit-scrollbar-thumb {
            background: #D6D6D6;
            border-radius: 2px;
        }
    }

    ${props => props.extendStyles}
`;

const Search = styled.div`
    padding: 13px 14px;
    display: flex;
    align-items: center;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);

    img {
        :hover {
            cursor: pointer;
        }
    }

    input {
        outline: none;
        border: none;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 128%;
        color: #222222;
        width: 100%;

        ::placeholder {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 128%;
            color: #BEBEBE;
        }
    }
`;

export {
    Wrapper,
    Select,
    DropDown,
    Search
}