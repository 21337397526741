import React from 'react'

// styles
import { Styles } from './index.styles'

// data
import { updateTypes } from '../index'

const UpdateForm = (props, ref) => {
    const {
        currentTicket,
        bioFaceContentUpdate,
        arrFingerUpdate,
        city,
        nowTime,
        nowDay,
        nowMonth,
        nowYear,
        officeName,
    } = props

    const customerInfos = currentTicket.CustomerInfos;
    
    return (
        <Styles ref={ref}>
            <div className="page">
                <div className="form-infos">
                    <div>Biểu mẫu 02/TĐ</div>
                    <div>CIF: {customerInfos.CustomerNumber}</div>
                </div>
                <div className="country-infos">
                    <div className="name-of-country">
                        CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
                    </div>
                    <div className="motto">Độc lập - Tự do - Hạnh phúc</div>
                </div>
                <div className="date-of-now">
                    {city}, ngày {nowDay} tháng {nowMonth} năm {nowYear}
                </div>
                <div className="name-of-form">
                    GIẤY ĐỀ NGHỊ THAY ĐỔI THÔNG TIN SINH TRẮC HỌC
                </div>
                <div className="where-to-send">
                    Kính gửi: Ngân hàng TMCP Công thương Việt Nam (VietinBank) {officeName}
                </div>
                <div className="wrapper">
                    <div className="title p-5">A. THÔNG TIN KHÁCH HÀNG</div>
                    <div className="p-5">
                        Họ tên: {customerInfos.FullNameVi}
                    </div>
                    <div className="grid-wrapper col-3">
                        <div>
                            Số CMND/Hộ chiếu/Thẻ CCCD: {customerInfos.IdNumber}
                        </div>
                        <div>Ngày cấp: {customerInfos.IdIssueDate}</div>
                        <div>Nơi cấp: {customerInfos.IdIssuePlace}</div>
                    </div>
                    <div className="p-5">
                        Địa chỉ hiện tại: {customerInfos.Address}
                    </div>
                    <div className="grid-wrapper col-3">
                        <div>Số điện thoại: {customerInfos.MobileNumber}</div>
                        <div className="email">
                            Email: {customerInfos.Email}
                        </div>
                    </div>
                    <div className="title p-5">
                        B. NỘI DUNG ĐỀ NGHỊ: Đề nghị VietinBank thực hiện thay
                        đổi:
                    </div>
                    <div className="p-5 title-request">
                        1. Sinh trắc học khuôn mặt:
                    </div>
                    <div className="grid-wrapper col-2 border-split">
                        <div className="checkbox-field">
                            <span
                                className={
                                    bioFaceContentUpdate ===
                                    updateTypes.changeImgFace
                                        ? 'checkbox checked'
                                        : 'checkbox'
                                }
                            />
                            <span className="content">
                                Thay đổi hình ảnh sinh trắc học khuôn mặt
                            </span>
                        </div>
                        <div className="checkbox-field">
                            <span
                                className={
                                    bioFaceContentUpdate ===
                                    updateTypes.closedFace
                                        ? 'checkbox checked'
                                        : 'checkbox'
                                }
                            />
                            <span className="content">
                                Đóng hình ảnh sinh trắc học khuôn mặt
                            </span>
                        </div>
                    </div>
                    <div className="grid-wrapper col-2 border-split">
                        <div className="checkbox-field">
                            <span
                                className={
                                    bioFaceContentUpdate ===
                                    updateTypes.activedFace
                                        ? 'checkbox checked'
                                        : 'checkbox'
                                }
                            />
                            <span className="content">
                                Kích hoạt lại hình ảnh sinh trắc học khuôn mặt
                            </span>
                        </div>
                        <div></div>
                    </div>
                    <div className="p-5 title-request">
                        2. Sinh trắc học vân tay
                        <span className="details-num">1</span>:
                    </div>
                    <div className="grid-wrapper col-2 border-split">
                        <div className="hand-title">Bàn tay trái</div>
                        <div className="hand-title">Bàn tay phải</div>
                    </div>
                    <div className="grid-wrapper col-2 border-split">
                        <div className="grid-wrapper col-2">
                            <div className="hand-status">Ngón tay</div>
                            <div className="hand-status">Trạng thái</div>
                        </div>
                        <div className="grid-wrapper col-2">
                            <div className="hand-status">Ngón tay</div>
                            <div className="hand-status">Trạng thái</div>
                        </div>
                    </div>
                    {arrFingerUpdate.map((item, key) => (
                        <div
                            className="grid-wrapper col-2 border-split"
                            key={key}
                        >
                            <div className="grid-wrapper col-2">
                                <div className="checkbox-field">
                                    <span
                                        className={
                                            item[1] !== '.'.repeat(30)
                                                ? 'checkbox checked'
                                                : 'checkbox'
                                        }
                                    />
                                    <span className="content">{item[0]}</span>
                                </div>
                                <div>{item[1]}</div>
                            </div>
                            <div className="grid-wrapper col-2">
                                <div className="checkbox-field">
                                    <span
                                        className={
                                            item[3] !== '.'.repeat(30)
                                                ? 'checkbox checked'
                                                : 'checkbox'
                                        }
                                    />
                                    <span className="content">{item[2]}</span>
                                </div>
                                <div>{item[3]}</div>
                            </div>
                        </div>
                    ))}
                    <div className="title p-5">C. CAM KẾT</div>
                    <ul className="list-commitment">
                        <li>
                            Tôi đồng ý sử dụng số điện thoại nêu tại mục A để sử
                            dụng trong các giao dịch điện tử/giao dịch thẻ thanh
                            toán trực tuyến với VietinBank và sử dụng để nhận
                            các thông báo từ VietinBank (bao gồm các thông báo
                            liên quan đến thay đổi thông tin sinh trắc học).
                        </li>
                        <li>
                            Trường hợp đề nghị treo sinh trắc học vân tay: Tôi
                            đồng ý để VietinBank tự động kích hoạt lại ngón tay
                            đã treo để xác thực vào lần GD tiếp theo tại các
                            điểm giao dịch của VietinBank.
                        </li>
                        <li>
                            Giấy đề nghị này có hiệu lực từ ngày ký và được lập
                            thành 02 bản có nội dung như nhau, khách hàng giữ 01
                            bản, VietinBank - {officeName} giữ 01 bản làm căn cứ
                            thực hiện.
                        </li>
                    </ul>
                </div>
                <div className="grid-wrapper col-2">
                    <div></div>
                    <div className="sign-range">
                        <div>KHÁCH HÀNG</div>
                        <div>(Ký và ghi rõ họ tên)</div>
                    </div>
                </div>
                <div className="confirm-bank">
                    Xác nhận của Ngân hàng TMCP Công Thương Việt Nam
                </div>
                <div className="time-register">
                    Đã thực hiện thay đổi thông tin sinh trắc học theo đề nghị
                    của Khách hàng vào hồi {nowTime} ngày {nowDay} tháng{' '}
                    {nowMonth} năm {nowYear}.
                </div>
                <div className="grid-wrapper col-2">
                    <div className="sign-range">
                        <div>GDV/CBĐT</div>
                        <div>(Ký , ghi rõ họ tên)</div>
                    </div>
                    <div className="sign-range">
                        <div>
                            ĐẠI DIỆN VIETINBANK
                            <span className="details-num">2</span>
                        </div>
                        <div>(Ký, ghi rõ họ tên, đóng dấu)</div>
                    </div>
                </div>
                <br />
                <br />
                <hr className="footer-hr" />
                <div className="details-list">
                    <div>
                        <span className="details-num">1</span>Chỉ tích chọn ngón
                        tay khách hàng đề nghị thay đổi. Trạng thái vân tay trên
                        Giấy đề nghị này là trạng thái sau khi thực hiện thay
                        đổi.
                    </div>
                    <div>
                        <span className="details-num">2</span>Đại diện
                        VietinBank là lãnh đạo Chi nhánh/Phòng kế toán/Phòng
                        giao dịch/Phòng dịch vụ khách hàng.
                    </div>
                </div>
            </div>
        </Styles>
    )
}

export default React.forwardRef(UpdateForm)
