import * as types from "../actions/types";

export const initialState = {
    isNew: false,
    data: [],
}


export default function notifyReducer(state = initialState, action) {
    switch (action.type){
    case types.ADD_NEW_NOTIFY:
        return {
            isNew: true,
            data: [action.data, ...state.data]
        }
    case types.SET_STATUS_SEEN:
        return {
            ...state,
            isNew: false
        }
    default: 
        return state;
    }
}