import { popupBioTypes } from '../data/PopupTypes'
import typesKeyDetailPopup from '../data/typesKeyDetailPopup'

// image
import iconNotificationSuccess from '../images/ic_counter_notification_success.svg'
import ic_warning_big from '../images/ic_warning_big.svg'

const POPUP_LEVEL_1 = 1
const POPUP_LEVEL_2 = 2
const POPUP_LEVEL_3 = 3

// PopupFillReasonCusNotAuth.js
/**
 * open popup fill reason not auth
 * @param  {component} _this component
 * @param  {object} data  [description]
 * @param  {string} from  file name that call funtion
 * @return {[type]}       [description]
 */
export const openPopupFillReasonNotAuth = (_this, data, from) => {
    // console.log(from)
    _this.props.openPopup(
        popupBioTypes.PopupFillReasonCusNotAuth,
        POPUP_LEVEL_2,
        data
    )
}

// PopupSearchCifs.js
/**
 * open popup search cif
 * @param  {[type]} _this [description]
 * @param  {[type]} data  [description]
 * @param  {[type]} from  [description]
 * @return {[type]}       [description]
 */
export const openPopupSearchCif = (_this, data, from) => {
    // console.log(from);
    _this.props.openPopup(popupBioTypes.PopupSearchCifs, POPUP_LEVEL_1, data)
}

// PopupConfirm.js
/**
 * open popup to recall ticket
 * @param  {[type]} _this [description]
 * @param  {{
 *         callAt: Datetime,
 *         ticketId: string
 * }} data  [description]
 * @param  {[type]} from  [description]
 * @return {[type]}       [description]
 */
export const openPopupRecallTicket = (_this, data, from) => {
    // console.log(from)
    const dataTranferToPopup = {
        key: typesKeyDetailPopup.recallTicketInCounter,
        content: 'Gọi lại vé vào quầy giao dịch?',
        contentDelete: 'Thời gian gọi vé tiếp theo:',
        colorContent: 'var(--peacock-blue)',
        width: '236px',
        paddingPopup: '20px 46px 15px 46px',
        ...data,
    }
    _this.props.openPopup(
        popupBioTypes.PopupConfirm,
        POPUP_LEVEL_1,
        dataTranferToPopup
    )
}
export const openPopupDeleteBioRegistered = (_this, data, from) => {
    const dataTranferToPopup = {
        key: typesKeyDetailPopup.confirmRemoveBioData,
        content: 'Xác nhận xoá dữ liệu vừa đăng ký?',
        contentDelete: '(*) Dữ liệu vừa xử lý sẽ không được lưu lại',
        width: '288px',
        fontSizeNote: '12px',
        ...data,
    }
    _this.props.openPopup(
        popupBioTypes.PopupConfirm,
        POPUP_LEVEL_2,
        dataTranferToPopup
    )
}
export const openPopupConfirmSendSupervisor = (_this, data, from) => {
    const dataTranferToPopup = {
        key: typesKeyDetailPopup.confirmSendSupervisor,
        content:
            data.content ||
            'Bạn chưa đăng ký đủ 10 dấu vân tay. Bạn có muốn tiếp tục gửi KSV phê duyệt?',
        width: '288px',
        fontSizeNote: '16px',
        btnLeft: {
            title: 'Huỷ bỏ',
            bgColor: 'var(--lipstick)',
            fontSize: '13px',
        },
        btnRight: {
            title: 'Tiếp tục',
            bgColor: 'var(--peacock-blue)',
            fontSize: '13px',
        },
        dataTicket: data.dataTicket,
    }
    _this.props.openPopup(
        popupBioTypes.PopupConfirm,
        POPUP_LEVEL_2,
        dataTranferToPopup
    )
}
export const openPopupConfirmSendSupervisorTeller = (_this, data, from) => {
    const dataTranferToPopup = {
        key: typesKeyDetailPopup.confirmSendSupervisorTeller,
        content: data.content,
        width: '288px',
        fontSizeNote: '16px',
        btnLeft: {
            title: 'Huỷ',
            bgColor: 'var(--lipstick)',
            fontSize: '13px',
        },
        btnRight: {
            title: 'Tiếp tục',
            bgColor: 'var(--peacock-blue)',
            fontSize: '13px',
        },
        ticketId: data.ticketId,
    }
    _this.props.openPopup(
        popupBioTypes.PopupConfirm,
        POPUP_LEVEL_2,
        dataTranferToPopup
    )
}
export const openPopupConfirmStopCounter = (_this, data, from) => {
    // console.log(from)
    const dataTranferToPopup = {
        key: typesKeyDetailPopup.confirmStopCounter,
        content:
            'Quầy vẫn còn vé trong hàng đợi chờ phục vụ, bạn có muốn ngừng GD không?',
        colorContent: 'var(--peacock-blue)',
        width: '342px',
        paddingPopup: '20px 46px 15px 46px',
        btnLeft: {
            title: 'Huỷ bỏ',
            bgColor: 'var(--lipstick)',
            fontSize: '13px',
        },
        btnRight: {
            title: 'Tiếp tục',
            bgColor: 'var(--peacock-blue)',
            fontSize: '13px',
        },
    }
    _this.props.openPopup(
        popupBioTypes.PopupConfirm,
        POPUP_LEVEL_1,
        dataTranferToPopup
    )
}

// PopupAccept.js
export const openPopupNotifyErrorCompleteTicket = (_this, data, from) => {
    // console.log(from)
    _this.props.openPopup(popupBioTypes.PopupAccect, POPUP_LEVEL_1, data)
}
export const openPopupNotifySendSuccess = (_this, data, from) => {
    const dataTranferToPopup = {
        image_noti: iconNotificationSuccess,
        title: 'Gửi KSV phê duyệt thành công. Vui lòng chờ để được xử lý.',
        widthPopup: '236px',
        titleConfirm: 'Xác nhận',
        ...data,
    }
    _this.props.openPopup(
        popupBioTypes.PopupAccect,
        POPUP_LEVEL_1,
        dataTranferToPopup
    )
}
export const openPopupNotifyErrorLv3 = (_this, data, from) => {
    // console.log(from)
    _this.props.openPopup(popupBioTypes.PopupAccect, POPUP_LEVEL_3, data)
}

// PopupShowReasonRejectBio.js
export const openPopupShowReasonRejectBio = (_this, data, from) => {
    _this.props.openPopup(
        popupBioTypes.PopupShowReasonRejectBio,
        POPUP_LEVEL_2,
        data
    )
}
export const openPopupAddReasonNotFillFullData = (_this, data, from) => {
    const dataTranferToPopup = {
        key: typesKeyDetailPopup.addReasonNotFillFullData,
        ticketId: data.ticketId,
        type: 'addReasonNotFillFullData',
    }
    _this.props.openPopup(
        popupBioTypes.PopupShowReasonRejectBio,
        POPUP_LEVEL_2,
        dataTranferToPopup
    )
}
export const openPopupAddReasonDeleteBioData = (_this, data, from) => {
    const dataTranferToPopup = {
        key: typesKeyDetailPopup.addReasonDeleteBioData,
        ticketId: data.ticketId,
        bioType: data.bioType,
        type: 'addReasonDeleteBioData',
    }
    _this.props.openPopup(
        popupBioTypes.PopupShowReasonRejectBio,
        POPUP_LEVEL_3,
        dataTranferToPopup
    )
}

// PopupNotRegister.js
export const openPopupFillReasonWhyNotRegisterBio = (_this, ticket, from) => {
    _this.props.openPopup(popupBioTypes.PoupNotRegister, POPUP_LEVEL_2, {
        key: typesKeyDetailPopup.fillReasonWhyNotRegisterBioData,
        dataTicket: ticket,
    })
}

// PopupShowErrorExistedDataBiometric.js
export const openPopupShowErrorExistedDataBiometricFace = (
    _this,
    data,
    from
) => {
    // debugger;
    _this.props.openPopup(
        popupBioTypes.PopupShowErrorExistedDataBiometric,
        POPUP_LEVEL_2,
        data
    )
}
export const openPopupShowErrorExistedDataBiometric = (_this, data, from) => {
    // debugger;
    _this.props.openPopup(
        popupBioTypes.PopupShowErrorExistedDataBiometric,
        POPUP_LEVEL_3,
        data
    )
}

// PopupWaitingEvalueateQualityService.js
export const openPopupEvalueateQualityService = (_this, data, from) => {
    _this.props.openPopup(
        popupBioTypes.PopupWaitingEvalueateQualityService,
        POPUP_LEVEL_1,
        {
            ...data,
        }
    )
}

// PopupNotRegisterFingerButFace.js
export const openPopupNotRegisterFingerButFace = (_this, data, from) => {
    _this.props.openPopup(
        popupBioTypes.PopupNotRegisterFingerButFace,
        POPUP_LEVEL_2,
        {
            ...data,
        }
    )
}

export const showPopupPreventFinishTask = (_this, currentTicket, levelPopup=1) => {
    _this.props.openPopup(
        popupBioTypes.PopupAccect,
        levelPopup,
        {
            key: typesKeyDetailPopup.updateBioIpay,
            image_noti: ic_warning_big,
            title: "Khách hàng đăng ký STH khuôn mặt tại kênh ngoài quầy. Vui lòng đăng ký bổ sung vân tay hoặc nhập lý do không đăng ký",
            widthPopup: "367px",
            btnWidth: "129px",
            titleConfirm: "Xác nhận",
            dataTicket: { ...currentTicket }
        }
    )
}
