import styled from 'styled-components'

const WrapInputGTTT = styled.div`
    display: flex;
    align-items: center;

    .title-input {
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 128%;
        color: #000000;
        margin-right: 7px;
        white-space: nowrap;
    }

    .wrap-input {
        margin-left: 10px;
        width: 150px;
        height: 36px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border-radius: 4px;
        border: 0.3px solid #AAAAAA;
        padding: 0 8px;

        input {
            margin-left: 4px;
            flex-grow: 1;
            border: none;
            outline: none;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 128%;
            color: #000000;
            width: 100%;
        }
    }
`;

export {
    WrapInputGTTT
}