import React from 'react'
import styled from 'styled-components'
import { REQUEST_UPDATE_BIO_ERR, REQUEST_ENROLL_ERR, REQUEST_ENROLL_COLLECTOR_ERR } from "../actions/types";
import { popupBioTypes } from "../data/PopupTypes";
import iconNotificationClose from "../images/ic_counter_notification_close.svg"


const POPUP_LEVEL_3 = 3

const SpanRedColor = styled.span`
    color: var(--vermillion);
`;

const SpanLimited = styled.span`
    max-width: ${props => props.limit ? props.limit + 'ch' : 'unset'};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
    display: inline-block;
`;

const errorCheck = [
    REQUEST_ENROLL_ERR, 
    REQUEST_UPDATE_BIO_ERR, 
    REQUEST_ENROLL_COLLECTOR_ERR
]

const isErrorWhenGetEnroll = (counterReducer, openPopup, clearError) => {
    if (typeof (counterReducer.err) === 'object') {
        const errorType = Object.keys(counterReducer.err).find(item => errorCheck.includes(item))
        if(!errorType) return false;
        const error = counterReducer.err[errorType];
        const { 
            Code, 
            CustomerNumber,
            TellerName,
            OfficeName,
            CustomerName,
            TicketNumber
        } = error
        clearError(errorType)
        if(Code !== 30) return false;
        if (TicketNumber) { //teller
            const data = {
                image_noti: iconNotificationClose,
                title: <>
                    <div>Khách hàng <SpanRedColor>{CustomerNumber} - <SpanLimited limit={13} title={CustomerName}>{CustomerName}</SpanLimited></SpanRedColor></div>
                    <div>đang được phục vụ với số vé <SpanRedColor><SpanLimited limit={4} title={TicketNumber}>{TicketNumber}</SpanLimited></SpanRedColor></div>
                    <div>tại quầy của <SpanRedColor>GDV <SpanLimited limit={8} title={TellerName}>{TellerName}</SpanLimited> - PGD <SpanLimited limit={7} title={OfficeName}>{OfficeName}</SpanLimited></SpanRedColor></div>
                </>,
                widthPopup: "335px",
                titleConfirm: "Xác nhận",
            }
            openPopup(
                popupBioTypes.PopupAccect,
                POPUP_LEVEL_3,
                data
            )
            return true;
        }
        const data = {
            image_noti: iconNotificationClose,
            title: <>
                <div>Khách hàng <SpanRedColor>{CustomerNumber} - <SpanLimited limit={13} title={CustomerName}>{CustomerName}</SpanLimited></SpanRedColor></div>
                <div>đang được phục vụ bởi <SpanRedColor>cán bộ điện toán</SpanRedColor></div>
                <div><SpanRedColor><SpanLimited limit={17} title={TellerName}>{TellerName}</SpanLimited> - CN <SpanLimited limit={17} title={OfficeName}>{OfficeName}</SpanLimited></SpanRedColor></div>
            </>,
            widthPopup: "335px",
            titleConfirm: "Xác nhận",
        }
        openPopup(
            popupBioTypes.PopupAccect,
            POPUP_LEVEL_3,
            data
        )
        return true;
    }
    return false;
}

export default isErrorWhenGetEnroll;