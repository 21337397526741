import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import LoadingControl from '../../Control/LoadingControl'

// action
import { removeStatusWaitingVerifyFinger } from '../../../actions/counterAction'

// data
import { nameFingerPrint, FACE } from '../../../data/bioTypes'
// import { typeStatusBiometrics } from '../../../data/statusBiometricWithTicket'
// import {
//     // CONFIRM,
//     AUTH_TRANS,
//     ISHANG,
//     VALIDATE_DATA,
// } from '../../../actions/typeRequestBio'

const Bound = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .finger-auth {
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        margin-top: 10px;
    }
    .block_loading {
        margin: 102px 0 0 0;
        text-align: center;
        p {
            /* margin: 102px 0 42px 0; */
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #222222;
        }
    }
    .closing-auth,
    .remove-loading {
        margin: 102px 0 42px 0;
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.68;
        letter-spacing: normal;
    }

    .closing-auth {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .remove-loading {
        text-decoration: underline;
        color: var(--vermillion);
        cursor: pointer;
    }
`

class LoadingCheckCustomer extends Component {
    state = {
        authFinger: '',
    }

    // genFingerAuth = () => {
    //     const { ticketId, biometricReducer, counterReducer } = this.props
    //     const biometricOfTicket = biometricReducer[ticketId]
    //     if (!biometricOfTicket) return null
    //     const currentTicket = counterReducer.listCurrentTicket.find(
    //         (item) => item.TicketID === ticketId
    //     )
    //     if (!currentTicket) return null
    //     const { statusBiometric } = currentTicket

    //     const lstFingerAuth = []
    //     Object.keys(biometricOfTicket).forEach((item) => {
    //         const isBioFinger = item !== 'FACE'
    //         if (!isBioFinger) return
    //         const fingerBioOfTicketIsRemove =
    //             biometricOfTicket[item].bioActionType === 'REMOVE'
    //         if (fingerBioOfTicketIsRemove) return

    //         const bioAddWasAccept =
    //             statusBiometric === typeStatusBiometrics.ACCEPT
    //         const registerBioConfirmed = Object.keys(
    //             biometricOfTicket[item]
    //         ).includes(AUTH_TRANS)
    //         const bioUsedToAuth = biometricOfTicket[item][AUTH_TRANS]
    //         if (bioAddWasAccept && registerBioConfirmed && !bioUsedToAuth)
    //             return

    //         if (bioAddWasAccept && registerBioConfirmed && bioUsedToAuth) {
    //             lstFingerAuth.push(item)
    //             return
    //         }

    //         const isBioHaveValidatedData = Object.keys(
    //             biometricOfTicket[item]
    //         ).includes('validatedData')
    //         const isValidatedDataHaveConfirm =
    //             isBioHaveValidatedData &&
    //             Object.keys(biometricOfTicket[item].validatedData).includes(
    //                 'usedToAuthenticateTransaction'
    //             )
    //         const itemIsHang =
    //             Object.keys(biometricOfTicket[item]).includes(AUTH_TRANS) &&
    //             biometricOfTicket[item][AUTH_TRANS]
    //                 ? biometricOfTicket[item][ISHANG]
    //                 : biometricOfTicket[item][VALIDATE_DATA][ISHANG]
    //         if (
    //             isValidatedDataHaveConfirm &&
    //             biometricOfTicket[item].validatedData
    //                 .usedToAuthenticateTransaction &&
    //             !itemIsHang
    //         )
    //             lstFingerAuth.push(item)
    //     })
    //     // debugger;
    //     return (
    //         ' ' +
    //         lstFingerAuth.map((item) => nameFingerPrint[item].name).join(', ')
    //     )
    //     //nameFingerPrint[item].nameInVerify
    // }

    handleClosingAuth = () => {
        this.setState({
            closingGetBio: <>
                <LoadingControl size="25px" loadingPage={false} />
                Vui lòng chờ...
            </>
        }, () => {
            setTimeout(() => {
                this.props.removeStatusWaitingVerifyFinger(this.props.ticketId)
            }, 5000)
        })
    }

    render() {
        return (
            <Bound>
                {/* <div className="finger-auth"><strong>Vân tay đăng ký:</strong>{this.genFingerAuth()}</div> */}
                <div className="finger-auth">
                    <strong>Vân tay đăng ký: </strong>
                    {this.state.authFinger}
                </div>
                <div className="block_loading">
                    <LoadingControl size="52px" loadingPage={false} />
                    <p>Đang xác thực vân tay...</p>
                </div>

                {
                    this.state.closingGetBio?
                        <div className="closing-auth">{this.state.closingGetBio}</div>
                        :
                        this.state.isVisibleFinishBtn?
                            <div
                                className="remove-loading"
                                onClick={() => this.handleClosingAuth()}
                            >
                            Kết thúc
                            </div>
                            :
                            <div className="remove-loading" style={{height:'23px'}}></div>
                }
            </Bound>
        )
    }
	
    componentDidMount() {
        const { ticketId, /* biometricReducer, */ counterReducer } = this.props
        const dataVerify = counterReducer.verifyCustomer[ticketId];
        // console.log("counterReducer.verifyCustomer: ",JSON.stringify(counterReducer.verifyCustomer))
        // console.log("dataVerify.verifyFingers: ", JSON.stringify(dataVerify.verifyFingers))
        if(dataVerify && Array.isArray(dataVerify.verifyFingers)) {
            const authFinger = dataVerify.verifyFingers
                .filter(item => item !== FACE)
                .map((item) => nameFingerPrint[item].name)
                .join(', ')
            this.setState({ authFinger })
        }
        
        this.timerVisibleFinishBtn = setTimeout(() => {
            this.setState({ isVisibleFinishBtn: true})
        }, 1500)
    }
    
    componentWillUnmount() {
        clearTimeout(this.timerVisibleFinishBtn)
    }
    
	
}

const mapStateToProps = (state) => ({
    biometricReducer: state.biometricReducer,
    counterReducer: state.counterReducer,
})

export default connect(mapStateToProps, (dispatch) => ({
    removeStatusWaitingVerifyFinger: (ticketId) =>
        dispatch(removeStatusWaitingVerifyFinger(ticketId)),
}))(LoadingCheckCustomer)
