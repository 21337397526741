import { dataCity } from "../data/VietNamData/DataCity";
import { dataDistrict } from "../data/VietNamData/DataDistrict";
import { dataWard } from "../data/VietNamData/DataWard";

export const fieldAdministrativeUnits = {
    ward: "WARD",
    district: "DISTRICT",
    city: "CITY"
}

const getDataAdministrativeUnitsByCode = (administrativeUnits, code, fieldNeedGet=undefined) => {
    switch(administrativeUnits){
    case fieldAdministrativeUnits.ward:
        return fieldNeedGet ? dataWard[code][fieldNeedGet] : dataWard[code];
    case fieldAdministrativeUnits.district:
        return fieldNeedGet ? dataDistrict[code][fieldNeedGet] : dataDistrict[code];
    case fieldAdministrativeUnits.city:
        return fieldNeedGet ? dataCity[code][fieldNeedGet] : dataCity[code];
    default:
        return;
    }
}

export default getDataAdministrativeUnitsByCode;
	

