import * as types from "../actions/types";

export const initialState = {
    queryByImg: {
        resGetUserByImg: null,
        resGetUserByFingerImg: null,
        loadingGetByFace: false,
        loadingGetByFinger: false
    }
}


export default function queriesReducer(state = initialState, action) {
    switch (action.type) {
    case types.SET_LOADING_GET_USER_BY_FACE: {
        return {
            ...state,
            queryByImg: {
                ...state.queryByImg,
                loadingGetByFace: action.payload.loading,
            }
        }
    }
    case types.SET_LOADING_GET_USER_BY_FINGER: {
        return {
            ...state,
            queryByImg: {
                ...state.queryByImg,
                loadingGetByFinger: action.payload.loading,
            }
        }
    }
    case types.DISPATCH_USER_FROM_TABLET: {
        return {
            ...state,
            queryByImg: {
                ...state.queryByImg,
                loadingGetByFace: false,
                resGetUserByImg: action.payload.resGetUserByImg
            }
        }
    }
    case types.GET_USER_BY_FINGER_IMG: {
        return {
            ...state,
            queryByImg: {
                ...state.queryByImg,
                loadingGetByFinger: false,
                resGetUserByFingerImg: action.payload.resGetUserByFingerImg
            }
        }
    }
    case types.CLEAR_DATA_USER_FROM_TABLET: {
        return {
            ...state,
            queryByImg: {
                ...state.queryByImg,
                resGetUserByImg: null,
                resGetUserByFingerImg: null
            }
        }
    }
    default:
        return state;
    }
}