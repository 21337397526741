import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// helper
import useMounted from '../../../../../../helper/hooks/useMounted'

// component
import FilterComboBox from '../FilterComboBox'

const ALL_VAL = 'Tất cả'
const objAllVal = {
    key: ALL_VAL,
    value: ALL_VAL
}

const FilterBySupervisor = ({ handleChange, defaultValue }) => {

    const { inSupervisors } = useSelector(state => ({
        inSupervisors: state.inBioCollector.listUsername.inSupervisors
    }))
    const [objSupervisors, setObjSupervisors] = useState([]);

    const mounted = useMounted()

    const _handleChange = (obj) => {
        if (!mounted.current) return;

        const { key } = obj
        if (key === ALL_VAL) {
            handleChange('')
            return;
        }
        handleChange(key)
    }

    const _handleSearch = (keySearch) => {
        const lstSupervisorMatch = inSupervisors.filter(supervisor => supervisor.match(keySearch.trim()))
        const objSupervisors = lstSupervisorMatch.map(supervisor => ({ key: supervisor, value: supervisor }))
        setObjSupervisors(objSupervisors)
    }

    useEffect(() => {
        const objSupervisors = inSupervisors.map(supervisor => ({ key: supervisor, value: supervisor }))
        setObjSupervisors(objSupervisors)
    }, [JSON.stringify(inSupervisors)]);

    return (
        <FilterComboBox
            title="User phê duyệt"
            lstItemFixed={[objAllVal]}
            lstItems={objSupervisors}
            onChange={_handleChange}
            defaultValue={defaultValue || objAllVal}
            placeHolder="Nhập User"
            onSearch={_handleSearch}
        />
    )
}

export default FilterBySupervisor
