/*
 *	Create one ticket have data = {TicketID: string, CustomerInfos: null} when collector login.
 *	We will handling all action of collector same as teller.
 */

import Axios from 'axios'

import { host } from '../host'

import * as typeCodeResponses from './typeCodeResponses'
import * as typeRequestBios from './typeRequestBio'

import * as types from './types'

import catchErrAction from '../helper/catchErrAction'
import { addBiometricExistFromCustomerInfos } from './counterAction'
import { addLoading, removeLoading } from './commonAction'

const apiCompleteBio = host + 'inBioCollector/complete-bio-data'
const apiGetCurrentBioEnroll = host + 'inBioCollector/getCurrentBioEnroll'
const apiRequestStartEnroll = host + 'inBioCollector/request-enroll'
const apiRequestGetBioData = host + 'inBioCollector/get-bio-data'
const apiRemoveBioData = host + 'inBioCollector/remove-bio-data'
const apiRequestConfirmBioData = host + 'inBioCollector/confirm-bio'

export const apiGetCifs = host + 'inBioCollector/get-cifs'
const apiUpdateCusInfos = host + 'inBioCollector/update-bio-info'
const apiSetAuthenticateFinger = host + 'inBioCollector/update-confirm-finger'

const apiUpdateBiometricNote = host + 'inBioCollector/update-biometric-note'
const apiFinishRequestTablet = host + 'inBioCollector/closed-verify-finger'
const apiEditRejectBio = host + 'inBioCollector/edit-rejected-pending-bio'

const apiUpdateRemoveBio = host + 'inBioCollector/update-remove-bio'
const apiUpdateRevertRemoveBio =
    host + 'inBioCollector/update-revert-remove-bio'
const apiRequestUpdateBio = host + 'inBioCollector/request-update-bio'
const apiFinishTransInTask = host + 'inBioCollector/remove-biometric-infos'
const apiGetBioHistoryList = host + 'inBioCollector/get-bio-history-list'

export const apiGetBioFaceImageCollector = (bioID) =>
    `${host}inBioCollector/get/bio-face-image/${bioID}`
export const apiGetBioFingerImageCollector = (bioID) =>
    `${host}inBioCollector/get/bio-finger-image/${bioID}`
export const apiGetBioFileCollector = (idFile) =>
    `${host}inBioCollector/get/bio-file/${idFile}`

//////// TicketID InbioCollector ///////
// const ticketId = "0".repeat(24);
// const ticketId1 = "1".repeat(24);

export const getBioHistoryListCollector = (
    ticketId,
    cusNum,
    pageSize,
    pageNum,
    lestThanCreate
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CustomerNumber: cusNum,
        PageSize: pageSize,
        PageNumber: pageNum,
        LessthanCreateAt: lestThanCreate,
    })

    try {
        const res = await Axios.post(apiGetBioHistoryList, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.GET_LIST_HISTORY_CUST,
                ticketId,
                historyDetails: data,
            })
    } catch (err) {
        console.log(err)
        catchErrAction(err, dispatch, types.GET_LIST_HISTORY_CUST_ERR, false)
    }
}

export const finishTransInTask = (ticketId) => async (dispatch, getState) => {
    dispatch(addLoading())
    const store = getState()
    const counterId = store.counterReducer.dataConnectDesktop.CounterID
    const enrollId = store.counterReducer.enrollId[ticketId]

    if (!enrollId) {
        dispatch(removeLoading())
        dispatch(completeFakeTicket(ticketId))
        return
    }

    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        CounterID: counterId,
    })

    try {
        const res = await Axios.post(apiFinishTransInTask, queryDataFormat)
        dispatch(removeLoading())

        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS

        if (isSuccess) {
            dispatch(completeFakeTicket(ticketId))
        }
    } catch (err) {
        catchErrAction(err, dispatch, types.COMPLETE_FAKE_TICKET_ERR, false)
    }
}

/**
 *
 * @param {string*} ticketId id of ticket was called
 * @param {number*} cusNumber info of customer from api /counter/get-cifs
 */
export const requestUpDateBioCollector = (ticketId, cusNumber) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        TicketID: ticketId,
        CustomerNumber: cusNumber,
    })

    try {
        const res = await Axios.post(apiRequestUpdateBio, queryDataFormat)
        // console.log(res);
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess)
            dispatch({
                type: types.REQUEST_UPDATE_BIO,
                enrollId: data.EnrollID,
                ticketId,
            })
        else
            dispatch({
                type: types.REQUEST_UPDATE_BIO_ERR,
                err: data,
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.REQUEST_UPDATE_BIO_ERR, false)
    }
}

export const updateRemoveBioCollector = (
    enrollId,
    bioType,
    removeNote
) => async (dispatch, getState) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        BioType: bioType,
        RemoveNote: removeNote,
    })

    try {
        const res = await Axios.post(apiUpdateRemoveBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            const store = getState()
            const lstEnrollId = store.counterReducer.enrollId
            const ticketId = Object.keys(lstEnrollId).find(
                (item) => lstEnrollId[item] === enrollId
            )
            dispatch({
                type: types.REMOVE_BIO_IN_UPDATE_THREAD,
                ticketId,
                bioType,
                removeNote,
            })
        }
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.REMOVE_BIO_IN_UPDATE_THREAD_ERR,
            false
        )
    }
}

export const updateRevertRemoveBioCollector = (enrollId, bioType) => async (
    dispatch,
    getState
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        BioType: bioType,
    })
    try {
        const res = await Axios.post(apiUpdateRevertRemoveBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            const store = getState()
            const lstEnrollId = store.counterReducer.enrollId
            const ticketId = Object.keys(lstEnrollId).find(
                (item) => lstEnrollId[item] === enrollId
            )
            // debugger;
            dispatch({
                type: types.REVERT_REMOVE_BIO_IN_UPDATE_THREAD,
                ticketId,
                bioType,
            })
        }
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.REVERT_REMOVE_BIO_IN_UPDATE_THREAD_ERR,
            false
        )
    }
}

export const requestNewEnrollIdToUpdateRejectBioCollector = (
    counterId,
    enrollId,
    ticketId
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        EnrollID: enrollId,
    })

    try {
        const res = await Axios.post(apiEditRejectBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO,
                newEnrollId: data.EnrollID,
                ticketId,
            })
        } else {
            dispatch({
                type: types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO_ERR,
                err: {
                    [types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO_ERR]: data.Code,
                },
            })
        }
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO,
            false
        )
    }
}

export const removeStatusWaitingBioDataCollector = (
    ticketId,
    justDispatch = false
) => async (dispatch, getState) => {
    if (justDispatch) {
        dispatch({
            type: types.REMOVE_STATUS_WAITING_BIO,
            ticketId,
        })
        return
    }
    dispatch(addLoading())
    const currentState = getState()
    const counterId = currentState.counterReducer.dataConnectDesktop.CounterID
    const enrollId = currentState.counterReducer.enrollId[ticketId]
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        EnrollID: enrollId,
    })

    try {
        const res = await Axios.post(apiFinishRequestTablet, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.REMOVE_STATUS_WAITING_BIO,
                ticketId,
            })
        } else
            dispatch({
                type: types.REMOVE_STATUS_WAITING_BIO_ERR,
            })
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.REMOVE_STATUS_WAITING_BIO_ERR,
            false
        )
    }
}

export const updateBiometricNoteCollector = (
    enrollId,
    note,
    noteRegister = 'note register'
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        DuplicateFaceNote: note,
        RejectRegisterNote: noteRegister,
    })

    try {
        const res = await Axios.post(apiUpdateBiometricNote, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.UPDATE_BIOMETRIC_DUPLICATE_NOTE,
                enrollId,
                note,
            })
        } else {
            dispatch({
                type: types.UPDATE_BIOMETRIC_DUPLICATE_NOTE_ERR,
                err: {
                    [types.UPDATE_BIOMETRIC_DUPLICATE_NOTE_ERR]: data.Code,
                },
            })
        }
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.REMOVE_STATUS_WAITING_BIO_ERR,
            false
        )
    }
}

/////// Get FileBio ////////

/**
 * @param {string*} enrollId
 * @param {boolean*} forceComplete
 */
export const completeBioCollector = (enrollId, forceComplete = true) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        ForceComplete: forceComplete,
    })
    try {
        const res = await Axios.post(apiCompleteBio, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === 0
        if (isSuccess)
            dispatch({
                type: types.COMPLETE_BIO_COLLECTOR,
                ticketId: enrollId,
            })
        else
            dispatch({
                type: types.COMPLETE_BIO_COLLECTOR_ERR,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.COMPLETE_BIO_ERR)
    }
}

export const completeFakeTicket = (ticketId) => ({
    type: types.COMPLETE_FAKE_TICKET,
    ticketId,
})

/**
 *
 * @param {string*} counterId
 * @param {string*} enrollId
 */
export const getCurrentBioEnrollCollector = (counterId, enrollId) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
    })
    try {
        const res = await Axios.post(apiGetCurrentBioEnroll, queryDataFormat)
        const { data } = res
        const { BioInfos: bioInfos, PendingBio: pendingBio } = data
        const isSuccess = data.Code === 0
        dispatch(removeLoading())
        if (isSuccess)
            dispatch({
                type: types.GET_CURRENT_BIO_ENROLL_COLLECTOR,
                payload: {
                    bioInfos,
                    pendingBio,
                },
            })
    } catch (err) {
        console.log(err)
        catchErrAction(
            err,
            dispatch,
            types.GET_CURRENT_BIO_ENROLL_COLLECTOR_ERR,
            false
        )
    }
}

/**
 * [description]
 * @param {string*} cifs
 */
export const requestEnrollCollector = (cifs, currentTicketId) => async (
    dispatch
) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        Cifs: cifs,
    })

    try {
        const res = await Axios.post(apiRequestStartEnroll, queryDataFormat)
        const { data } = res
        dispatch(removeLoading())
        const isSuccess = data.Code === 0
        if (isSuccess) {
            // localStorage.setItem("enrollId", data.EnrollID)
            dispatch(setEnrollId(data.EnrollID, currentTicketId))
        } else
            dispatch({
                type: types.REQUEST_ENROLL_COLLECTOR_ERR,
                err: data,
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(err, dispatch, types.REQUEST_ENROLL_COLLECTOR_ERR, false)
    }
}

export const setEnrollId = (enrollId, ticketId) => ({
    type: types.REQUEST_ENROLL_COLLECTOR,
    enrollId,
    ticketId,
})

/**
 *
 * @param {{
 *  CounterID*: string,
 *  TicketID*: string, // newTicket folow with BioCollector
 *  EnrollID*: string, //from api /inBioCollector/request-enroll
 *  BioType*: was describe in "../data/bioTypes"
 * }} dataRequest
 */
export const requestGetBioDataCollector = (dataRequest, ticketId) => async (
    dispatch
) => {

    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: dataRequest.CounterID,
        EnrollID: dataRequest.EnrollID,
        BioType: dataRequest.BioType,
    })

    try {
        const res = await Axios.post(apiRequestGetBioData, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === 0
        if (isSuccess) {
            dispatch({
                type: types.REQUEST_GET_BIO_DATA_COLLECTOR,
                bioType: dataRequest.BioType,
                typeRequest: typeRequestBios.REGISTER,
                ticketId,
            })
        }
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(
            err,
            dispatch,
            types.REQUEST_GET_BIO_DATA_COLLECTOR_ERR,
            false
        )
    }
}

/**
 *
 * @param {string*} enrollId /// Enroll follow with ticketId
 * @param {string*} bioType //// type Bio to request
 * @param {string*} bioId ////  bioID when register success
 */
export const removeBioDataCollector = (
    enrollId,
    bioType,
    bioId,
    ticketId,
    index = undefined
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        BioType: bioType,
        BioID: bioId,
    })

    try {
        const res = await Axios.post(apiRemoveBioData, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === 0
        if (isSuccess) {
            dispatch({
                type: types.REQUEST_REMOVE_BIO_COLLECTOR,
                bioType,
                ticketId,
                index,
            })
        } else
            dispatch({
                type: types.REQUEST_REMOVE_BIO_COLLECTOR_ERR,
                err: data,
            })
    } catch (err) {
        dispatch(removeLoading())
        catchErrAction(
            err,
            dispatch,
            types.REQUEST_REMOVE_BIO_COLLECTOR_ERR,
            false
        )
    }
}

/**
 * @param {string*} enrollId
 * @param {string*} bioType
 */
export const requestConfirmBioDataCollector = (
    enrollId,
    bioType,
    ticketId
) => async (dispatch) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        EnrollID: enrollId,
        BioType: bioType,
    })

    try {
        const res = await Axios.post(apiRequestConfirmBioData, queryDataFormat)
        dispatch(removeLoading())
        const { data } = res
        const isSuccess = data.Code === 0
        if (isSuccess)
            dispatch({
                type: types.REQUEST_CONFIRM_BIO_DATA_COLLECTOR,
                bioType,
                typeRequest: typeRequestBios.CONFIRM,
                ticketId,
            })
        else
            dispatch({
                type: types.REQUEST_CONFIRM_BIO_DATA_COLLECTOR_ERR,
                err: data,
            })
    } catch (err) {
        dispatch(removeLoading())
        if (
            err.response &&
            err.response.status === 400 &&
            err.response.data.Code >= 100
        ) {
            const {
                OfficeID,
                OfficeName,
                SupervisorID,
                SupervisorName,
                TellerID,
                TellerName,
                AcceptAt,
            } = err.response.data
            const moreInfosError = {
                OfficeID,
                OfficeName,
                SupervisorID,
                SupervisorName,
                TellerID,
                TellerName,
                AcceptAt,
            }
            const cifs =
                (!!err.response.data.Cifs && {
                    ...err.response.data.Cifs,
                    ...moreInfosError,
                }) ||
                (!!err.response.data.Cif && {
                    ...err.response.data.Cif,
                    ...moreInfosError,
                }) ||
                {}
            dispatch({
                type: types.REQUEST_CONFIRM_BIO_DATA_ERR,
                payload: {
                    bioType,
                    typeRequest: typeRequestBios.CONFIRM,
                    ticketId,
                    cifs,
                    code: err.response.data.Code,
                    invalidFingerPrint:
                        err.response.data.InvalidFingerPrint || [],
                    bioTypeDuplicate: err.response.data.BioType || '',
                },
            })
            return
        }
        catchErrAction(err, dispatch, types.REQUEST_CONFIRM_BIO_DATA_ERR, false)
    }
}

/**
 * @param {string*} counterId
 * @param {string*} cifsNum
 */
export const getCustomerInfos = (
    counterId,
    cifsNum,
    typeSearch = 0,
    ticketId,
    isApplyBiometric = false
) => async (dispatch, getState) => {
    dispatch(addLoading())
    const queryDataFormat = JSON.stringify({
        CounterID: counterId,
        CifsNum: cifsNum,
        Type: typeSearch,
    })
    try {
        const res = await Axios.post(apiGetCifs, queryDataFormat)
        const { data } = res
        const isSuccess = data.Code === 0 && data.Cifs && data.Cifs.length > 0
        dispatch(removeLoading())
        if (isSuccess) {
            dispatch({
                type: types.GET_CUSTOMER_INFORMATION_COLLECTOR,
                data,
            })
            if (ticketId) {
                dispatch(
                    addBiometricExistFromCustomerInfos(data.BioInfos, ticketId)
                )
                dispatch({
                    type: types.ADD_VALIDATE_INFO,
                    ticketId,
                    data: data.Cifs,
                })
            }
        } else
            dispatch({
                type: types.GET_CUSTOMER_INFORMATION_ERR,
                err: {
                    [types.GET_CUSTOMER_INFORMATION_ERR]: 0,
                },
            })
    } catch (err) {
        dispatch(removeLoading())
        if (err.response && err.response.data && err.response.data.Code === 2) {
            dispatch({
                type: types.GET_CUSTOMER_INFORMATION_COLLECTOR_ERR,
                err: {
                    [types.GET_CUSTOMER_INFORMATION_COLLECTOR_ERR]:
                        err.response.data.Code,
                },
            })
        }
        catchErrAction(
            err,
            dispatch,
            types.GET_CUSTOMER_INFORMATION_COLLECTOR_ERR,
            false
        )
    }
}

/**
 * [description]
 * @param  {string*} enrollId   [description]
 * @param  {
 *         newFullNameVi: string,
 *         newMobileNunber: string
 * } dataUpdate [description]
 * @return {[type]}            [description]
 */
export const updateCusInfosCollector = (
    enrollId,
    ticketId,
    newFullNameVi,
    newMobile
) => async (dispatch) => {
    const data = {}
    if (!enrollId) {
        return
    }
    data.EnrollID = enrollId
    if (newFullNameVi && newFullNameVi.trim())
        data.newFullNameVi = newFullNameVi.trim()
    if (newMobile && newMobile.trim()) data.NewMobileNumber = newMobile.trim()

    const queryDataFormat = JSON.stringify(data)
    try {
        const res = await Axios.post(apiUpdateCusInfos, queryDataFormat)
        const { data } = res
        const isSuccess = data.Code === 0
        if (isSuccess)
            dispatch({
                type: types.UPDATE_BIO_INFO_COLLECTOR,
                newFullNameVi: newFullNameVi && newFullNameVi.trim(),
                newMobile: newMobile && newMobile.trim(),
                ticketId,
            })
        else
            dispatch({
                type: types.UPDATE_BIO_INFO_COLLECTOR_ERR,
            })
    } catch (err) {
        catchErrAction(err, dispatch, types.UPDATE_BIO_INFO_ERR, false)
    }
}

/**
 * @param {string*} bioType
 * @param {string*} enrollId
 * @param {boolean*} isAuthenticate
 */
export const setAuthenticateFingerCollector = (
    enrollId,
    ticketId,
    bioType,
    isAuthenticate
) => async (dispatch) => {
    const queryDataFormat = JSON.stringify({
        BioType: bioType,
        EnrollID: enrollId,
        IsConfirmFinger: isAuthenticate,
        IsActive: isAuthenticate ? true : false,
    })
    try {
        const res = await Axios.post(apiSetAuthenticateFinger, queryDataFormat)
        const { data } = res
        const isSuccess = data.Code === 0
        if (isSuccess)
            dispatch({
                type: types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR,
                ticketId,
                bioType,
                isAuthenticate,
            })
        else
            dispatch({
                type: types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR_ERR,
                err: {
                    [types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR_ERR]:
                        data.Code,
                },
            })
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR,
            false
        )
    }
}

export const setAuthenticateFingerCollectorUpdateThread = (
    enrollId,
    ticketId,
    bioType,
    isAuthenticate,
    isHang
) => async (dispatch) => {
    const queryDataFormat = JSON.stringify({
        BioType: bioType,
        EnrollID: enrollId,
        IsConfirmFinger: isAuthenticate,
        IsActive: !isHang,
    })
    try {
        const res = await Axios.post(apiSetAuthenticateFinger, queryDataFormat)
        const { data } = res
        const isSuccess = data.Code === 0
        if (isSuccess)
            dispatch({
                type: types.EDIT_FINGER_INFO_IN_UPDATE_THREAD,
                ticketId,
                bioType,
                isAuthenticate,
                isHang,
            })
        else
            dispatch({
                type: types.EDIT_FINGER_INFO_IN_UPDATE_THREAD_ERR,
                err: {
                    [types.EDIT_FINGER_INFO_IN_UPDATE_THREAD_ERR]: data.Code,
                },
            })
    } catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.EDIT_FINGER_INFO_IN_UPDATE_THREAD_ERR,
            false
        )
    }
}

// export const createFakeTicket = () => dispatch => {
// 	dispatch({
// 		type: types.GET_TICKET,
// 		data: {
// 			TicketData: {
// 				TicketID: ticketId,
// 				CustomerInfos: null
// 			}
// 		}
// 	})
// }

export const createFakeListCurrentTicket = () => (dispatch) => {
    const ticketId = '0'.repeat(24)
    const ticketId1 = '1'.repeat(24)
    return {
        type: types.GET_CURRENT_TICKETS,
        data: {
            ListTicket: [
                {
                    TicketID: ticketId,
                    CustomerInfos: null,
                },
                {
                    TicketID: ticketId1,
                    CustomerInfos: null,
                },
            ],
        },
    }
}
