import React from 'react'
import styled from 'styled-components'

const typeText = {
    CUST: 'CUST',
    TICKET: 'TICKET',
    INFO_BRANCH: 'INFO_BRANCH',
}

const WrapImportantText = styled.span`
    position: relative;
`

const ImportantText = styled.span`
    margin: 0 5px;
    color: rgb(243, 25, 25);
    max-width: ${(props) => {
        switch (props.type) {
        case typeText.TICKET:
            return '5ch'
        default:
            return '24ch'
        }
    }};
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover + span {
        visibility: visible;
    }
`

const Tooltip = styled.span`
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    visibility: hidden;
    white-space: nowrap;
    background: gray;
    padding: 5px;
    border-radius: 5px;
`

const texts = [
    'Khách hàng',
    'đang được phục vụ với số vé',
    'tại quầy của',
    'GDV',
    'PGD',
]

const ErrorGetTicket76 = ({
    CustomerNumber,
    CustomerName,
    TicketNumber,
    TellerName,
    OfficeName,
}) => {
    return (
        <>
            <div>
                {texts[0]}
                <WrapImportantText>
                    <ImportantText
                        type={typeText.CUST}
                    >{`${CustomerNumber} - ${CustomerName}`}</ImportantText>
                    <Tooltip>{`${CustomerNumber} - ${CustomerName}`}</Tooltip>
                </WrapImportantText>
            </div>
            <div>
                {texts[1]}
                <WrapImportantText>
                    <ImportantText type={typeText.TICKET}>
                        {TicketNumber}
                    </ImportantText>
                    <Tooltip>{TicketNumber}</Tooltip>
                </WrapImportantText>
            </div>
            <div>
                {texts[2]}
                <WrapImportantText>
                    <ImportantText
                        type={typeText.INFO_BRANCH}
                    >{`GDV ${TellerName} - PGD ${OfficeName}`}</ImportantText>
                    <Tooltip>{`GDV ${TellerName} - PGD ${OfficeName}`}</Tooltip>
                </WrapImportantText>
            </div>
        </>
    )
}

export default ErrorGetTicket76
