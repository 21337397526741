import React, { Component } from 'react'
import styled from "styled-components";

import icon_down from '../../images/ic_arrow_down_gray.svg'
import { Nzero } from '../../helper/tool';

const BoundStyle = styled.div`
  display:flex;
  flex-direction:column;
  width: ${props => props.width || "100%"};
  position: relative;
  .title-text {     
    font-family: 'SVN-Gilroy';   
    font-size: 11px;
    font-weight: bold;        
    color: #005993;
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1.26;
  }
  .dropdown-control {
    outline: none;        
    font-family: 'SVN-Gilroy';
    padding: 0 37px 0 10px;        
    height: 44px;
    background-color: #ffffff;
    border: solid 0.5px #bababa;
    box-sizing: border-box;        
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;  
    text-align: left; ;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: flex-start;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    /* overflow: hidden; */
    white-space: nowrap;
    text-overflow: ellipsis;
    background-image:url(${icon_down});
    background-repeat: no-repeat;
    background-position: top 20px right 17px;
    position:relative;
    .titleChoise{
        cursor: default;
    }
    .content {
      max-height: 160px;
      position: absolute;
      top: 100%;
      width: 100%;
      margin-top: 4px;
      border-radius: 4px;
      border: solid 0.5px #c8c8c8;
      left: 0;
      background-color: #ffffff;
      z-index:999;
      overflow-y: scroll;
      .item{
          padding: 10px;
          cursor: default;
          &:hover{
              background-color: #005993;
              color:#fff;
          }
      }

      ::-webkit-scrollbar {
        width: 3px;
      }

      ::-webkit-scrollbar-thumb {
        background: #c8c8c8; 
      }
    }
  }
  .dropdown-menu{  
    font-family: 'SVN-Gilroy';
    display: flex;  
    flex:1;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;  
    border-radius: 4px;
    background-color: #ffffff;
    width: 100%;
    padding: 10px 0;       
    .menu {
        font-family: 'SVN-Gilroy';
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;  
        cursor: pointer;          
    }
  }  
`;

export default class SingleChoiceSelectBox extends Component {
    state = {
        data: [],
        item: ""
    }

    isMouseEnter = false;

    choiseText = (item) => {
        this.setState({ item: `Quầy ${Nzero(item.CounterNum)} - ${item.TellerName}` });
        this
            .props
            .getText(item.CounterID)

    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data !== prevState.data) {
            return {
                data: nextProps.data
            }
        }
        return null;
    }


    checkShow = () => this.props.idShow === this.props.id

    onClick = () => {
        if (this.checkShow()) {
            this.props.setIdShow("")
        }
        else {
            this.props.setIdShow(this.props.id)
        }

    }
    render() {
        return (
            <BoundStyle width={this.props.width}>
                <p className='title-text'>{this.props.title}</p>
                <div
                    className="dropdown-control"
                    onClick={() => this.onClick()}
                    tabIndex="0"
                    onBlur={() => this.props.setIdShow("")}
                >
                    <div className="titleChoise">
                        {
                            this.state.data.length > 0 ?
                                <p>
                                    {this.state.item
                                        ? this.state.item
                                        : this.props.titleChoise}
                                </p>
                                :
                                <p>Không có quầy chuyển vé...</p>
                        }
                    </div>
                    {this.props.idShow === this.props.id && this.state.data.length > 0 && <div className="content">
                        {this.state.data && this.state.data.length > 0
                            ? this
                                .state
                                .data
                                .map((item, index) => {
                                    return (
                                        <div onClick={() => this.choiseText(item)} className='item' key={index}>
                                            {`Quầy ${Nzero(item.CounterNum)} - ${item.TellerName}`}</div>
                                    )
                                })
                            : null
                        }
                    </div>
                    }
                </div>
            </BoundStyle>
        )
    }
}
