export const GET_LIST_DATA_SENT_SUPERVISOR = 'GET_LIST_DATA_SENT_SUPERVISOR'
export const GET_LIST_DATA_SENT_SUPERVISOR_ERR = 'GET_LIST_DATA_SENT_SUPERVISOR_ERR'

export const LOAD_PENDING_BIO_TO_COUNTER = 'LOAD_PENDING_BIO_TO_COUNTER'
export const LOAD_PENDING_BIO_TO_COUNTER_ERR = 'LOAD_PENDING_BIO_TO_COUNTER_ERR'

export const MOVE_PENDING_BIO_TO_PENDING_STACK = 'MOVE_PENDING_BIO_TO_PENDING_STACK'
export const MOVE_PENDING_BIO_TO_PENDING_STACK_ERR = 'MOVE_PENDING_BIO_TO_PENDING_STACK_ERR'

export const GET_LIST_USERNAME = 'GET_LIST_USERNAME'
export const GET_LIST_USERNAME_ERR = 'GET_LIST_USERNAME_ERR'