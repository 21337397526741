import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// action
import { getListUsername } from '../../../../../actions/inBioCollector/listUsernameAction'
import { getListDataSentSupervisor } from '../../../../../actions/inBioCollector/listDataSentSupervisorAction'

// component
import FilterByStatus from './FilterByStatus'
import FilterBySupervisor from './FilterBySupervisor'
import FilterByTeller from './FilterByTeller'
import InputGTTT from './InputGTTT'

// styles
import { Button } from './index.styles'


const FilterCondition = () => {

    const dispatch = useDispatch()
    const { filters } = useSelector(state => ({
        filters: state.inBioCollector.listDataSentSupervisor.filters
    }))

    const [isReset, setIsReset] = useState(false);

    const _handleChangeInputGTTT = (idNumber) => {
        if (isReset) {
            setIsReset(false)
            return;
        }
        console.log(idNumber)
        dispatch(getListDataSentSupervisor({ ...filters, CustomerID: idNumber }))
    }

    const _handleChangeFilterTeller = (teller) => {
        console.log(teller)
        dispatch(getListDataSentSupervisor({ ...filters, TellerName: teller }))
    }

    const _handleChangeFilterSupervisor = (supervisor) => {
        console.log(supervisor)
        dispatch(getListDataSentSupervisor({ ...filters, SupervisorName: supervisor }))
    }

    const _handleChangeFilterStatus = (status) => {
        console.log(status)
        dispatch(getListDataSentSupervisor({ ...filters, Status: status }))
    }

    const _handleReset = () => {
        setIsReset(true)
        dispatch(getListDataSentSupervisor({}))
    }

    useEffect(() => {
        dispatch(getListUsername())
    }, []);

    const {
        CustomerID,
        TellerName,
        SupervisorName,
        Status
    } = filters

    return (
        <>
            <InputGTTT
                handleChange={_handleChangeInputGTTT}
                isReset={isReset}
            />
            <FilterByTeller
                handleChange={_handleChangeFilterTeller}
                defaultValue={TellerName && { key: TellerName, value: TellerName }}
            />
            <FilterBySupervisor
                handleChange={_handleChangeFilterSupervisor}
                defaultValue={SupervisorName && { key: SupervisorName, value: SupervisorName }}
            />
            <FilterByStatus
                handleChange={_handleChangeFilterStatus}
                defaultValue={Status}
            />
            <Button onClick={_handleReset}>Reset</Button>
        </>
    )
}

export default FilterCondition
