import styled from 'styled-components'

const WrapPopupRefuseRegFinger = styled.div`
    background: #fafafa;
    max-height: auto;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 10px 25px 20px;
    font-family: "SVN-Gilroy";
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    z-index: 999;
    box-shadow: 0 0 20px #00000070;

    & > figure {
        width: 100%;
        text-align: right;
        .ic_close {
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 14px;
            font-weight: bold;
            color: var(--black);
        }
    }

    .content-reason {
        margin-top: 16px;
        .title {
            font-size: 11px;
            font-weight: bold;
            line-height: 1.26;
            color: #005993;
            text-transform: uppercase;
            margin-bottom: 3px;
        }
        textarea {
            font-family: "SVN-Gilroy";
            margin-top: 1px;
            resize: none;
            outline: none;
            width: 375px;
            height: 46px;
            border: solid 0.5px #c8c8c8;
            border-radius: 7px;
            font-size: 14px;
            font-weight: 500;
            padding: 10px 10px;
            line-height: 1.2;
            color: #222222;
        }
        textarea:disabled {
            background: #efefef;
            color: #2222228a;
        }
        textarea::placeholder {
            color: #222222;
            opacity: 0.25;
        }
    }
    
    .btn-submit {
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .warning {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: var(--vermillion);
        }

        button {
            bottom: 24px;
            right: 40px;
            border: none;
            outline: none;
            width: 100px;
            height: 36px;
            border-radius: 18px;
            background-color: #005993;
            text-transform: uppercase;
            font-family: SVN-Gilroy;
            font-size: 13px;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
        }
    }
`;

export {
    WrapPopupRefuseRegFinger
}