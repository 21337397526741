import {randomDisplayName} from '../helper/tool'

export const user=[{
    id:'VT3001',
    username:'counter@vietinbank.vn',
    password:'12345',
    email:'counter@vietinbank.vn',
    fullname:'Counter',
    avatar:'https://picsum.photos/70/70',
}]

export const branch={
    name:'PGD Bến Thành',
    address:'79A Hàm Nghi, P.Nguyễn Thái Bình, Q.1, TP.HCM',
}

export const counter=[
    {
        id:'VT7815',
        pncode:'12345678',
        name:'Quầy số 1',
        transaction:[1,2,3,4,5,6,7,8,9,10,11],
        isActive:false,
        counter:'',
    },
    {
        id:'VT7816',
        pncode:'12345678',
        name:'Quầy số 2',
        transaction:[1,2,3,4,5,6,7,8],
        isActive:true,
        counter:'Nguyễn Thanh Hoa',

    },
    {
        id:'VT7817',
        pncode:'12345678',
        name:'Quầy số 3',
        transaction:[1,2,3,5,6,7,8,9,10,11],
        isActive:true,
        counter:'Trần Kim Cúc',
    },
    {
        id:'VT7818',
        pncode:'12345678',
        name:'Quầy số 4',
        transaction:[1,2,3,4,7,8,9,10,11],
        isActive:true,
        counter:'Võ Thái Thịnh',
    },
    {
        id:'VT7819',
        pncode:'12345678',
        name:'Quầy số 5',
        transaction:[1,2,3,4,5,6,7,11],
        isActive:true,
        counter:'Nguyễn Hoàng Nguyên',
    },
    {
        id:'VT7820',
        pncode:'12345678',
        name:'Quầy số 6',
        transaction:[1,2,3,4],
        isActive:true,
        counter:'Trần Thái Tuấn',
    },
    {
        id:'VT7821',
        pncode:'12345678',
        name:'Quầy số 7',
        transaction:[1,2,3,4,5,6,7,8,9,10,11],
        isActive:true,
        counter:'Nguyễn Quang Hiệu',
    },
    {
        id:'VT7822',
        pncode:'12345678',
        name:'Quầy số 8',
        transaction:[1,2,3,4,5,6,7,8,9,10,11],
        isActive:true,
        counter:'Mai Thuý Hương',
    },
]

export const mainTicket=[
    {
        id:'VT784624',
        avatar:'',
        ticketNumber: '100',
        username:randomDisplayName(),
        userType:2,
        transaction:[1,2,3],
        idNumber:'000000000001',
        timeCreate:'6/5/2019 15:30:00',
        CIF:'02457862',
        address:'376 Tân Phú, Q.Tân Phú, TP.HCM',
        phoneNumber:'094852621',
        gender:'Nam'
    },
    {
        id:'VT784625',
        avatar:'',
        ticketNumber: '101',
        username:randomDisplayName(),
        userType:0,
        transaction:[9,10,11],
        idNumber:'000000000002',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784626',
        avatar:'',
        ticketNumber: '100',
        username:randomDisplayName(),
        userType:2,
        transaction:[1,2,3],
        idNumber:'000000000001',
        timeCreate:'6/5/2019 15:30:00',
        CIF:'02457862',
        address:'376 Tân Phú, Q.Tân Phú, TP.HCM',
        phoneNumber:'094852621',
        gender:'Nam'
    },
    {
        id:'VT784627',
        avatar:'',
        ticketNumber: '103',
        username:randomDisplayName(),
        userType:3,
        transaction:[5,6,8,9],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'02457862',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784628',
        avatar:'',
        ticketNumber: '104',
        username:randomDisplayName(),
        userType:2,
        transaction:[2,6],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784629',
        avatar:'',
        ticketNumber: '105',
        username:randomDisplayName(),
        userType:4,
        transaction:[8,9,10],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'02457862',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784630',
        avatar:'',
        ticketNumber: '106',
        username:randomDisplayName(),
        userType:2,
        transaction:[3,4],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'02457862',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784631',
        avatar:'',
        ticketNumber: '107',
        username:randomDisplayName(),
        userType:1,
        transaction:[1],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'02457862',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784632',
        avatar:'',
        ticketNumber: '108',
        username:randomDisplayName(),
        userType:2,
        transaction:[5,6],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'02457862',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784633',
        avatar:'',
        ticketNumber: '109',
        username:randomDisplayName(),
        userType:0,
        transaction:[5,6,7,9],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'02457862',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784634',
        avatar:'',
        ticketNumber: '110',
        username:randomDisplayName(),
        userType:0,
        transaction:[5,6],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'02457862',
        address:'376 Tân Phú, P.10, Quận Tân Phú, TP.HCM',
        phoneNumber:'Số điện thoại: 0945845126',
        gender:'Nam'
    },
]

export const waittingTicket=[
    {
        id:'VT784635',
        avatar:'',
        ticketNumber: '056',
        username:randomDisplayName(),
        userType:2,
        transaction:[1,6],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784636',
        avatar:'',
        ticketNumber: '077',
        username:randomDisplayName(),
        userType:2,
        transaction:[2,3],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784637',
        avatar:'',
        ticketNumber: '090',
        username:randomDisplayName(),
        userType:1,
        transaction:[1,4,5,6],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'',
        address:'',
        phoneNumber:'',
        gender:''
    },
    {
        id:'VT784638',
        avatar:'',
        ticketNumber: '099',
        username:randomDisplayName(),
        userType:0,
        transaction:[8,9,10],
        idNumber:'000000000000',
        timeCreate:'5/5/2019 10:00:00',
        CIF:'',
        address:'',
        phoneNumber:'',
        gender:''
    },
]