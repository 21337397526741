import React, { Component } from 'react'
import styled from 'styled-components'

const Bound = styled.div`
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: SVN-Gilroy;
    margin-bottom: 8px;
    border-radius:16px;
    flex-direction:row;
    overflow:hidden;
    position:relative;
    transition:box-shadow 0.3s, width 0.7s;
    background-color:white;
    opacity: ${props => props.isActive===false? 0.5 : "unset"};
    &:last-child{
        margin-bottom:0;
    }
    .img{
        position:absolute;
        top:0;
        left:0;
        z-index:2;
        display:flex;
        width:36px;
        height:36px;
        /* border-radius:50% 0 0 50%; */
        background-color:white;
        background-image:url(${props=>props.image});
        background-size:32px 32px;
        background-position:center center;
        background-repeat:no-repeat;
        /* padding: 2px 0 2px 2px; */
    }
    p {
        position:absolute;
        top:2px;
        left:-${props=>props.widthTitle}px;
        z-index:1;
        width:${props=>props.widthTitle}px;
        height:32px;
        display:flex;
        font-size: 11px;
        font-weight: 500;
        line-height: 1.15;
        color: #222222;
        align-items:center;
        transition:left 0.5s;
    }
`

export default class ButtonTaskControl extends Component {
    button=null;
    pTitle=null;
    componentDidMount(){
        let {data} = this.props
        this.button=document.getElementById('button-control'+data.key)
        this.pTitle=document.getElementById('p-'+data.key)
    }
    handelMouseEnter=()=>{
        let {data} = this.props
        if(this.button&&this.pTitle){
            this.button.style.boxShadow='0 0 10px 0 rgba(0, 0, 0, 0.12)'
            setTimeout(() => {
                this.button.style.width=36+data.widthTitle+20+'px'
                this.pTitle.style.left='38px'
            }, 300);
        }
    }
    handelMouseLeave=()=>{
        let {data} = this.props
        setTimeout(() => {
            if(this.button&&this.pTitle){
                this.button.style.width='36px'
                this.pTitle.style.left=-data.widthTitle+'px'
                setTimeout(() => {
                    this.button.style.boxShadow='unset'
                }, 700);
            }
        }, 500);
        
    }
    render() {
        // console.log(this.props);
        let {data} = this.props
        return (
            <Bound 
                tooltip='bla bla' 
                isActive={this.props.isActive}
                image={data.icon} 
                widthTitle={data.widthTitle}
                onClick={this.props.onSubmit?(e)=>{this.props.onSubmit(e)}:()=>{}}                 
                id = {'button-control'+data.key}
            >
                <div className='img'
                    onMouseEnter={this.handelMouseEnter}
                    onMouseLeave={this.handelMouseLeave}/>
                <p id={'p-'+data.key}>{data.tooltip}</p>
            </Bound>
        )
    }
}
