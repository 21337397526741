import styled from 'styled-components'

const WrapPopupShowFinger = styled.div`
    max-width: 268px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    font-family: 'SVN-Gilroy';
    color: #000000;
    padding: 12px 20px;
    background: #FFFFFF;
    border-radius: 5px;

    .wrap-header {
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;

        img {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .channel {
        margin-top: 10px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 130%;
        color: #717171;
    }

    .wrap-content {
        margin-top: 17px;
        display: flex;
        justify-content: space-between;

        .wrap-finger {
            width: 104px;
            border-radius: 5px;
            box-sizing: border-box;
            
            img {
                border-radius: 5px;
                box-sizing: border-box;
                width: 100%;
                border: 0.5px solid #D6D6D6;
            }
        }
    }

    .wrap-footer {
        margin-top: 17px;
        display: flex;
        flex-wrap: wrap;

        label {
            flex-grow: 1;
            display: flex;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 128%;
            color: #222222;

            input[type="radio"] {
                outline: none;
                appearance: none;
                width: 16px;
                height: 16px;
                border: 0.75px solid #D6D6D6;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;

                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: #D71249;
                    border-radius: 50%;
                    display: none;
                }

                &:checked {
                    &::before {
                        display: block;
                    }
                }
            }

            &[for="hang"] {
                margin-top: 12px;
            }
        }
    }
`;

export {
    WrapPopupShowFinger
}