import styled from 'styled-components'

const WrapBioInfos = styled.div`
    display: flex;
    font-family: 'SVN-Gilroy';

    .first-col {
        padding: 18px 17px 20px 20px;
        text-align: center;

        .wrap-face {
            width: 140px;
            
            .wrap-inner-face {
                width: 100%;
                position: relative;
                display: inline-block;
                img {
                    &.face {
                        width: 100%;
                        border-radius: 5px;
                    }
                    &.ic-zoom {
                        position: absolute;
                        bottom: 6px;
                        right: 6px;
                        width: 16px;
                        height: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .wrap-popup-show-face {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .status {
            margin-top: 18px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 130%;
            color: #222222;
            span {
                &.active {
                    color: #0FB11F;
                }
                &.close {
                    color: #FF5815;
                }
                &.hang {
                    color: #0FB11F;
                }
            }
        }

        .channel {
            margin-top: 5px;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 110%;
            color: #222222;
        }
    }

    .last-col {
        position: relative;
        flex-grow: 1;
        .status-note {
            display: flex;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 96%;
            color: #FFFFFF;
            position: absolute;
            top: 12px;
            right: 20px;
            .status-note-item {
                display: flex;
                align-items: center;
                span {
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    margin-right: 3px;
                    &.delete {
                        background: #F31919;
                    }
                    &.active {
                        background: #0FB11F;
                    }
                    &.close {
                        background: #FF5815;
                    }
                    &.hang {
                        background: #FFA500;
                    }
                }

                &:not(:first-child) {
                    margin-left: 12px;
                }
            }
        }

        .hand-title {
            position: absolute;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 130%;
            color: #FFFFFF;

            &.left {
                left: 12px;
                bottom: 12px;
            }
            
            &.right {
                right: 12px;
                bottom: 12px;
            }
        }

        .finger {
            position: absolute;

            &:hover {
                cursor: pointer;
            }
        }
    }
`;

export {
    WrapBioInfos
}