import { TELLER } from '../data/userRole'

const checkApplyBiometricForTicket = async (_this, ticketId) => {
    const currentTicket = _this.props.counterReducer.listCurrentTicket.find(
        (item) => item.TicketID === ticketId
    )
    if (!currentTicket) return
    const customerInfos = currentTicket.CustomerInfos
    const { dataConnectDesktop, dataLogin } = _this.props.counterReducer
    const isTeller = dataLogin.Roles.includes(TELLER)

    if (!!customerInfos) {
        if (isTeller)
            await _this.props.getInfoCustomer(
                dataConnectDesktop.CounterID,
                customerInfos.CustomerNumber
            )
        else
            await _this.props.getCustomerInfosCollector(
                dataConnectDesktop.CounterID,
                customerInfos.CustomerNumber,
                0,
                currentTicket.TicketID
            )

        await _this.props.addBiometricExistFromCustomerInfos(
            _this.props.counterReducer.customerInfo.BioInfos,
            currentTicket.TicketID
        )
    }
    if (isTeller && currentTicket.CustomerInfos)
        await _this.props.getCurrentBioEnroll(
            dataConnectDesktop.CounterID,
            currentTicket.TicketID,
            currentTicket.CustomerInfos &&
            currentTicket.CustomerInfos.CustomerNumber
        )
    return;
}

export default checkApplyBiometricForTicket
