import React from 'react'
import { useSelector } from 'react-redux'

const useIsRole = (role) => {

    const { Roles } = useSelector(state => ({
        Roles: state.counterReducer.dataLogin?.Roles ?? []
    }))

    return Roles.includes(role)
}

export default useIsRole
