import React, { Component } from 'react'
import styled from "styled-components";
import { connect } from 'react-redux';


//Images
import icCloseWhite from "../../../images/ic_close_white.svg";
import face_err from '../../../images/face_err.svg';
import on_form from '../../../images/on_form.svg';

// import { apiGetBioFile, apiFaceHistory } from '../../../../actions/supervisorAction/biometricAction';
import { apiGetBioFile, apiGetBioFaceImage } from '../../../actions/biometricAction';
import { apiGetBioFaceImageCollector, apiGetBioFileCollector } from '../../../actions/biometricWithInBioCollectorAction';

import PopupNote from './PopupNote';

import { customType, ACTIVE, ISACTIVE } from '../../../data/infosType';
import { TELLER } from '../../../data/userRole';

// helper
import getFromChannel from '../../../helper/getFromChannel';
import getChannelName from '../../../helper/getChannelName';

const StylePopupShowFaceImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  min-width: 300px;
  background: #FFFFFF;
  border-radius: 0 0 5px 5px;
  .blockImage {
    position: relative;
    height: 500px;
    & > img {
      /* width: 300px; */
      /* max-height: calc(300px - 36px); */
      height: 100%;
      object-fit:fill;
      display: block;
      margin: 0 auto;
    }

    .channel {
      position: absolute;
      top: 0;
      font-family: 'SVN-Gilroy';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 130%;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      background: rgba(0,0,0,0.4);
      color: #FFFFFF;
    }
  }
  .content_bottom{
    padding: 11px 21.42px 17px 16px;
    p{
      font-family: SVN-Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      color: #979797;
    }
    .content_confirmFinger{
      display: flex;
      justify-content: space-between;
      margin-top: 6px;
      img{
        cursor: pointer;
      }
      h3{
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
      }
      .img_hover{
        width: 16px;
        height: 16px;
        position: absolute;
        right: 15px;
        cursor: pointer;
        &:hover {
        p{
          display: block;
          visibility: visible;
          opacity: 1;
          right: 16px;
          top: 19px;
        }
      }
      p{
        visibility: hidden;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        width: 60px;
        height: 20px;
        background-color: rgba(0,0,0,0.6);
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        margin-left: -60px;
        /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
        opacity: 0;
        transition: opacity 1s;
      }
      }
    }
  }
  .popup-header {
    :hover {
      cursor: move;
    }

    display: flex;
    justify-content: space-between;
    background: var(--black);
    color: #ffffff;
    padding: 11px 10px;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
    font-family: 'SVN-Gilroy';
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;

    img:hover {
      cursor: pointer;
    }
  }
  .block_radio{
    display: flex;
    justify-content:start;
    align-items: center;
    margin: 16px 0 13px 20px;
    position: relative;
    /* &:last-child{
      margin: 0 0 24px 20px;
      img{
        position: absolute;
        right: 13px;
        cursor: pointer;
      }
    } */
    label{
      .text_label{
        ${'' /* opacity: 0.25; */}
      }
    }
    .radio-field {
      display: flex;
      align-items: center;
      margin-right: 24px;
      font-family: SVN-Gilroy;
      font-size: 14px;
      line-height: 128%;
      color: #222222;
      ${'' /* opacity: 0.25; */}
      input[type="radio"]{
        appearance: none;
        outline: none;
        width: 16px;
        height: 16px;
        position:relative;
        background: #fff;
        border-radius: 50%;
        border: 0.75px solid #D6D6D6;
        margin-right: 5px;
        border-radius: 8px;

        ::before{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #fff;
          display: none;
        }

        &:checked::before {
          background: #D71249;
          border-radius: 8px;
          ${'' /* opacity: 0.25; */}
          display: block;
        }
      }
    }
  }
`;
// const BIO_INFO_REGISTER = typesKeyDetailPopup.faceBioInfoRegister;
class PopupShowFaceImage extends Component {
    state = {
    // typeSearch: true,
    //isActive: ACTIVE, CLOSE
        typeSearch: this.props.data.IsActive,
        typeFace: this.props.data.IsConfirmFinger,
        isShowNoteHistoryLv2: false,
    // imageLoadErr: true
    }
    onshowPopupNote = () => {
    // this.props.onshowPopupNote()
        this.setState({
            isShowNoteHistoryLv2: true
        })
    }

    onCloseNoteHistoryLv2 = () => {
        this.setState({
            isShowNoteHistoryLv2: false
        })
    }

    addDefaultSrc(ev) {
        if (this.state.imageLoadErr) {
            this.setState({
                imageLoadErr: false
            })
        }
        ev.target.src = face_err
    }

    render() {
        const { Face, IsTextFaceHistory, Title, TextStatus, TextFace, FromChannel } = this.props.data
        // console.log(Title)
        let elmConfirmFinger, clConfirmFinger
        switch (this.state.typeSearch) {
        case true:
            elmConfirmFinger = customType[ACTIVE].label
            clConfirmFinger = customType[ACTIVE].color
            break;
        case false:
            elmConfirmFinger = customType[ISACTIVE].label
            clConfirmFinger = customType[ISACTIVE].colors
            break;
        default:
            break;
        }
        const isTeller = this.props.counterReducer.dataLogin.Roles.includes(TELLER);
        const apiGetFace = isTeller ? apiGetBioFaceImage : apiGetBioFaceImageCollector
        const apiGetFile = isTeller ? apiGetBioFile : apiGetBioFileCollector
        return (
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 999999
                }}>
                <StylePopupShowFaceImage ref={ref => this.popupShowFaceImage = ref}>
                    <div className="popup-header">
                        <div className="title">{IsTextFaceHistory === 'FaceHistory' ? Title : (Title ? Title : 'Hình ảnh khuôn mặt')}</div>
                        {/* <div className="title">{IsTextFaceHistory === 'FaceHistory' ? Title :  'Hình ảnh khuôn mặt'}</div> */}
                        <img src={icCloseWhite} alt="close" onClick={this.props.type === 'CloseFaceHistory' ? () => { this.props.onCloseFaceHistory() } : () => { this.props.onClosePopup() }} />
                    </div>
                    <div className="blockImage">
                        {
                            Face !== undefined && <img src={IsTextFaceHistory === "FaceHistory" ? apiGetFace(Face) : apiGetFile(Face)} alt="face"
                                //  onError = {(e)=>this.addDefaultSrc(e)}
                            />
                        }
                        <div className="channel">Kênh tác nghiệp: {getChannelName(getFromChannel(FromChannel))}</div>
                    </div>

                    {
                        TextFace !== 'FaceZoom' ?
                            <div className='content_bottom'>
                                {/* <p>{TextStatus ? (Title === 'Hình ảnh khuôn mặt' ? 'Trạng thái khuôn mặt' : TextStatus) : 'Trạng thái khuôn mặt'}</p> */}
                                <p>{!!TextStatus ? TextStatus : 'Trạng thái khuôn mặt'}</p>
                                <div className='content_confirmFinger'>
                                    <h3 style={{ color: clConfirmFinger }}>{elmConfirmFinger}</h3>
                                    {
                                        this.props.data.RemoveNote &&
                    <div className='img_hover'>
                        <img src={on_form} alt='on_form' onClick={this.onshowPopupNote} />
                        <p>Lý do</p>
                    </div>
                                    }
                                </div>
                            </div> : null
                    }
                    {/* {
            this.props.type === 'CloseFaceHistory' && 
            <React.Fragment>
              <div className="block_radio">
                <label className="radio-field">
                  <input 
                    id="type-search"
                    type="radio" 
                    name="type-search" 
                    value= {true}
                    checked={this.state.typeSearch === true}
                    onChange={() => this.setState({typeSearch: true})}
                    disabled ={true}
                  />
                  <div className='text_label'>Hoạt động</div>
                </label>
                <label className="radio-field">
                  <input 
                    id="type-search"
                    type="radio" 
                    name="type-search" 
                    value= {false}
                    checked={this.state.typeSearch === false}
                    onChange={() => this.setState({typeSearch: false})}
                    disabled = {true}
                  />
                  <div className='text_label'>Đóng</div>
                </label>
              </div>
              <div className="block_radio">
                <label className="radio-field">
                  <input 
                    id="type-face"
                    type="radio" 
                    name="type-face" 
                    value= {false}
                    checked={this.state.typeFace === false}
                    onChange={() => this.setState({typeFace: false})}
                    disabled ={true}
                  />
                  <div className='text_label'>Treo hình ảnh khuôn mặt</div>
                </label>
                <img src = {on_form} alt = 'on_form' onClick = {this.onshowPopupNote}/>
              </div>
              
            </React.Fragment>
          } */}
                </StylePopupShowFaceImage>
                {
                    this.state.isShowNoteHistoryLv2 &&
          <PopupNote
              data={this.props.data}
              title='Nội dung xóa'
              placeholder='Nội dung xóa'
              onCloseNoteHistoryLv2={this.onCloseNoteHistoryLv2}
              type='noteHistoryBiometricInfo'
          />
                }
            </div>
        )
    }

    componentDidMount() {
        this.dragElement(this.popupShowFaceImage, ".popup-header")
    //////////////////////////////////////////////////////////
    }

    dragElement = (elmnt, headerClass) => {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        const header = elmnt.querySelector(headerClass)
        if (header) {
            /* if present, the header is where you move the DIV from:*/
            header.onmousedown = dragMouseDown;
        } else {
            /* otherwise, move the DIV from anywhere inside the DIV:*/
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        const that = this;
        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;

            // set the element's new position:
            const tmpTop = (elmnt.offsetTop - pos2);
            const tmpLeft = (elmnt.offsetLeft - pos1);
            const { scrollHeight, scrollWidth, clientHeight, clientWidth } = that.popupShowFaceImage;
            const { innerHeight, innerWidth } = window;
            if (tmpTop < scrollHeight / 2 && pos2 > 0) {
                elmnt.style.top = scrollHeight / 2 + "px";
            }
            else if (tmpTop > scrollHeight / 2 + innerHeight - clientHeight) {
                elmnt.style.top = scrollHeight / 2 + innerHeight - clientHeight + "px";
            }
            else {
                elmnt.style.top = tmpTop + "px";
            }

            if (tmpLeft < scrollWidth / 2 && pos1 > 0) {
                elmnt.style.left = scrollWidth / 2 + "px";
            }
            else if (tmpLeft > scrollWidth / 2 + innerWidth - clientWidth) {
                elmnt.style.left = scrollWidth / 2 + innerWidth - clientWidth + "px";
            }
            else {
                elmnt.style.left = tmpLeft + "px";
            }
        }

        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }
}


/**
 * props = {
 *  onClosePopup: function, // handle event close popup
 *  sourceImg: string, //link soure of image
 * }
 */
export default connect(state => ({ counterReducer: state.counterReducer }))(PopupShowFaceImage);