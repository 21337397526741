import styled from 'styled-components';


const Bound = styled.div`
    z-index: 99999;
    padding: 12px 14.34px 20px 20px;
    width: 760px;
    /* height: 540px; */
    border-radius: 10px;
    background-color: #fafafa;
    font-family: SVN-Gilroy;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px 0px #0000008a;
    .ic_close {
        text-align: right;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        img {
            cursor: pointer;
        }
        h3 {
            font-family: SVN-Gilroy;
            font-size: 16px;
            line-height: 21px;
            color: #000000;
        }
    }
    .content {
        .d-center {
            img {
                display: block;
                margin: 0 auto;
            }
        }

        p.title {
            text-align: center;
            font-weight: bold;
            margin: 12px 0;
        }
        p {
            font-family: SVN-Gilroy;
            /*font-size: 14px;
            padding-top: 14px;
            line-height: 128%;*/
            color: #222222;

            font-size: 16px;
            line-height: 21px;
            strong {
                color: #005993;
            }
        }
        .scrollbar {
            max-height: 40vh;
            overflow-y: scroll;
            overflow-x: hidden;

            ::-webkit-scrollbar {
                width: 3px;
            }

            ::-webkit-scrollbar-thumb {
                background: #c8c8c8;
            }
        }
        .inform-match {
            display: flex;
            /* margin-top: 12px; */
            background: #ffffff;
            border-radius: 4px;
            /* margin: 16px 14px 12px 12px; */
            .match-left {
                display: flex;
                flex-direction: column;
                padding-top: 15px;
                padding: 15px 0 0 16px;
                img {
                    width: 108px;
                    height: 108px;
                    object-fit: contain;
                    margin: 20px 0 0 0;
                    border-radius: 5px;
                }
                p {
                    font-family: SVN-Gilroy;
                    font-size: 10px;
                    line-height: 130%;
                    color: #005993;
                    padding: 0;
                }
                .content-verify {
                    font-family: SVN-Gilroy;
                    font-size: 15px;
                    line-height: 15px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    .type-inform-blue {
                        white-space: nowrap;
                        color: #005993;
                        font-weight: bold;
                    }
                }

                .channel {
                    margin-top: 10px;
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 110%;
                    color: #717171;
                }
            }
            .match-right {
                flex: 2;
                /* margin-left: 36px; */
                margin-left: 16px;
                font-family: SVN-Gilroy;
                /*font-size: 10px;*/
                font-weight: 500;
                font-size: 15px;
                line-height: 175%;
                .cover {
                    margin-top: 11px;
                }
                .cover-flex {
                    display: flex;
                    .cover-left {
                        /*flex-basis: 200px;*/
                        flex: 1;
                    }
                    .cover-right {
                        flex: 1;
                    }
                }
                .type-cif,
                .type-name {
                    line-height: 15px;
                    color: #222222;
                    font-weight: bold;
                }
                .type-name {
                    font-size: 15px;
                    text-transform: uppercase;
                }
                .content-verify {
                    line-height: 150%;
                    display: flex;
                    margin-top: 7px;
                    .type-inform-blue {
                        color: #005993;
                        white-space: nowrap;
                        /* width: 70px; */
                    }
                    .style-same {
                        color: #222222;
                    }
                    .type-address {
                        /* margin-left: 12px; */
                    }
                    &:last-child {
                        margin-bottom: 4px;
                    }
                }
            }
        }
        .pagning {
            float: right;
            margin: 15px 0 25px 0;
            .icon-left,
            .icon-right {
                background: none;
            }
            span.bold {
                font-weight: bold;
                font-family: SVN-Gilroy;
                font-size: 12px;
                line-height: 120%;
                color: #222222;
            }
        }
        /* .text-note {
            clear: both;
            font-family: SVN-Gilroy;
            .note {
                font-size: 11px;
                line-height: 126%;
                text-transform: uppercase;
                color: #005993;
                font-weight: bold;
                padding: 0;
            }
            .content-note {
                font-size: 14px;
                line-height: 128%;
                color: #222222;
                word-break: break-word;
                text-align: justify;
            }
        } */
    }
    .btn_ok {
        width: 80px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 16px auto 0 auto;
        cursor: pointer;
        text-transform: uppercase;
    }

    .text-ellipst {
        width: 210px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        /* position: relative; */
        pointer-events: none;
    }
    .text-ellipst:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 22px;
        z-index: 1;
        pointer-events: initial;
    }

    .cover-ellipst {
        position: relative;
        width: 100%;
        display: flex;
    }
    #tooltip_ {
        position: absolute;
        top: -66%;
        left: 50%;
        z-index: 999;
        width: fit-content;
        visibility: hidden;
        background-color: #fff;
        padding: 11px;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .text-ellipst:hover + #tooltip_, #tooltip_:hover {
        visibility: visible;
        transition: opacity 0.5s ease;
        opacity: 1;
    }
    .w-40 {
        width: 40%;
    }
    .d-center {
        flex-direction: column;
    }
`

export {
    Bound
}