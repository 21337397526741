import React, { Component } from 'react'
import styled from 'styled-components'

const Bound = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    cursor: pointer;
    border-radius:50%;
    /*background-color:white;*/
    background-image:url(${props=>props.image});
    background-size:32px 32px;
    background-position:center center;
    background-repeat:no-repeat;
`

export default class ButtonUserInfoControl extends Component {
    render() {
        let {image} = this.props
        return (
            <Bound image={image}
                onClick={this.props.onSubmit?()=>{this.props.onSubmit()}:()=>{}}>
            </Bound>
        )
    }
}
