import styled from 'styled-components';

const Bound = styled.div`
    z-index:999999;
    padding: 15px 10.2px 16px 20px;
    width: 250px;
    /* height: 192px; */
    border-radius: 10px;
    background-color: #fafafa;
    font-family: SVN-Gilroy;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px 0px #0000008a;
    .ic_close{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10.2px;
    }
    .content {
        h3{
            font-size: 11px;
            font-weight: bold;
            line-height: 1.26;
            color: #005993;
            text-transform: uppercase;
        }
        .addReason {
            border: solid 0.5px #c8c8c8;
            padding: 3px;
            margin-top: 4px;
            height: 84px;
            overflow: auto;
            overflow-x: hidden;
            ::-webkit-scrollbar {
                width: 3px;
            }

            ::-webkit-scrollbar-thumb {
                background: #c8c8c8; 
            }
            p{
                font-family: SVN-Gilroy;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.25;
                color: #222222;
                margin: 5px 0 0 0;
                opacity: 0.8;
                word-break: break-word;
            }
        }
        textarea{
            width: 222px;
            height: 84px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: #222222;
            border-radius: 4px;
            border: solid 0.5px #c8c8c8;
            background-color: #ffffff;
            resize: none;
            margin-top: 2px;
            padding: 9px 9px 0 9px;   
            outline:none;
            margin: 5px 0 0 0;
            font-family: SVN-Gilroy;
            &::placeholder {
                opacity: ${props=> (props.rejectRegisterNote || props.removeNote) ? '1' : '0.25'};
            }
            ::-webkit-scrollbar {
                width: 3px;
            }

            ::-webkit-scrollbar-thumb {
                background: #c8c8c8; 
            }
        }
    }
    .btn_ok {
        width: 80px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 16px auto 0 auto;
        cursor: pointer;
        text-transform: uppercase;
    }
`

export {
    Bound
}