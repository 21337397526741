import React from 'react'

// styles
import { WrapCustInfos } from './index.styles'

const CustInfos = ({
    custInfos: {
        CustomerType,
        Name,
        FullNameVi,
        Gender,
        SegmentDesc,
        IdNumber,
        MobileNumber,
        IdIssueDate,
        Email,
        IdIssuePlace,
        Address,
    }
}) => {
    return (
        <WrapCustInfos>
            <div className="title cust-type-title">Loại khách hàng</div>
            <div className="wrap-cust-type">
                <label htmlFor="employee">
                    <input
                        type='radio'
                        id="employee"
                        name="cust-type"
                        onClick={(e) => e.preventDefault()}
                        defaultChecked={CustomerType === "INCUST"}
                    />
                    <span>Cán bộ ngân hàng</span>
                </label>
                <label htmlFor="customer">
                    <input
                        type='radio'
                        id="customer"
                        name="cust-type"
                        onClick={(e) => e.preventDefault()}
                        defaultChecked={CustomerType === "EXCUST"}
                    />
                    <span>Khách hàng</span>
                </label>
            </div>
            <div className="title cust-infos-title">Thông tin khách hàng</div>
            <div className="field single-field"><span>Họ tên KH: </span><strong>{Name}</strong></div>
            <div className="grid-wrap">
                <div className="field"><span>Giới tính: </span>{Gender}</div>
                <div className="field"><span>Tên KH (*có dấu): </span>{FullNameVi}</div>
            </div>
            <div className="grid-wrap">
                <div className="field"><span>CMND/CC/HC: </span>{IdNumber}</div>
                <div className="field"><span>Hạng khách hàng: </span>{SegmentDesc}</div>
            </div>
            <div className="grid-wrap">
                <div className="field"><span>Ngày cấp: </span>{IdIssueDate}</div>
                <div className="field"><span>Số điện thoại di động: </span>{MobileNumber}</div>
            </div>
            <div className="grid-wrap">
                <div className="field"><span>Nơi cấp: </span>{IdIssuePlace}</div>
                <div className="field"><span>Email: </span>{Email}</div>
            </div>
            <div className="field single-field"><span>Địa chỉ: </span>{Address}</div>
        </WrapCustInfos>
    )
}

export default CustInfos
