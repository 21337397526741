import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import IpValidate from 'ip-validator'

// styles
import { FillClientNameWrapper } from './index.styles'

// action
import { getClientName } from '../../../actions/commonAction'

const PopupFillClientName = ({ closePopup }) => {
    const dispatch = useDispatch()

    const [clientName, setClientName] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        document.body.style.overflowX = 'hidden'
        return () => {
            document.body.style.overflowX = 'visible'
        }
    }, [])

    const _handleChangeInput = (e) => {
        setError('')
        const { value } = e.target
        setClientName(value.trim())
    }

    const _handleSaveClientName = useCallback(() => {
        if (!clientName){
            setError('PC NAME không được để trống.')
            return;
        }

        if (/\s+/g.test(clientName)) {
            setError('PC NAME không có khoảng trắng.')
            return
        }

        if (IpValidate.ipv4(clientName) || IpValidate.ipv6(clientName)) {
            setError('PC NAME không là địa chỉ IP.')
            return
        }

        dispatch(getClientName(clientName.toUpperCase()))
        closePopup()
    }, [clientName, dispatch, closePopup])

    return (
        <FillClientNameWrapper>
            <div className="web-title">VietinBank SDB Counter</div>
            <div className="form-title">Đăng nhập hệ thống</div>
            <div className="field-input">
                <div className="field-input__title">PC NAME</div>
                <input
                    type="text"
                    placeholder="Nhập PC NAME"
                    onChange={_handleChangeInput}
                />
            </div>
            <div className="error">{error}</div>
            <button className="btn-login" onClick={_handleSaveClientName}>
                đăng nhập
            </button>
        </FillClientNameWrapper>
    )
}

export default PopupFillClientName
