import styled, { css } from 'styled-components';

const Styles = styled.div`
    /* margin: 0 auto; */
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .popup {
        width: 338px;
        background: #ffffff;
        border-radius: 10px;

        .header {
            position: relative;
            display: flex;
            align-items: center;
            height: 64px;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 21px;
            box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.08);

            .title {
                margin-left: 20px;
            }

            img {
                position: absolute;
                right: 12px;
                top: 12px;
                :hover {
                    cursor: pointer;
                }
            }
        }

        .main {
            display: flex;
            flex-direction: column;
            padding: 51px 0 0;
            align-items: center;
        }
    }
`

const Footer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 500;
    color: var(--vermillion);
    min-height: 62px;

    .loading-text {
        color: #222222;
    }
`

const extendStyleButton = css`
    .btn-action {
        padding: 15px 12px 15px 8px;
        img {
            position: absolute;
        }
        p {
            flex-grow: 1;
            text-align: center;
        }
    }
`
export { Styles, extendStyleButton, Footer }