import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

// component
import LoginPage from "./LoginPage/LoginPage";
import MainPage from "./MainPage/MainPage";
import PopupContainerRedux from "./Popup/PopupManagementRedux/PopupContainerRedux";

//data
// import { popupType } from "../data/data";
import { ERROR_CONNECTION } from "../actions/types";
import { popupBioTypes } from "../data/PopupTypes";

// action
import { openPopup, closePopup } from "../actions/popupBioAction";
import { disconnectDesktop } from "../actions/counterAction";
import { clearError } from "../actions/commonAction";

// helper
import { buildErrorContent } from "../helper/tool";

// image
import icCloseNotification from "../images/ic_counter_notification_close.svg";

const BoundStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

class MainContainer extends Component {
    // dataCheck = null; ///// use for CheckBiometric
    popupObj = {};
    state = {
        isConnectCounter: false,
        isLogin: false,
        isShowPopup: false,
        user: null,
        counter: null,
        isShowPopupHistoryFinger: false, ////// popup third
    };

    showPopup(type, data) {
        this.popupObj.type = type;
        this.popupObj.data = data;

        this.setState({
            isShowPopup: true
        });
    }

    onClosePopup = () => {
        this.popupObj = {};
        this.setState({
            isShowPopup: false
        });
    };

    isLogin = (username, fullname, email, avatar, id, isLogin) => {
        let user = {
            id: id,
            username: username,
            fullname: fullname,
            email: email,
            avatar: avatar,
            isLogin: isLogin
        };
        localStorage.setItem("checkLoginCounter", JSON.stringify(user));
        this.setState({
            isLogin: true,
            user
        });
    };
    isLogout = () => {
        let isLogin = { isLogin: false };
        localStorage.setItem("checkLoginCounter", JSON.stringify(isLogin));
        this.setState({
            isLogin: false,
            user: null
        });
    };

    connectCounter = (
        id,
        pncode,
        name,
        transaction,
        isActive,
        counter,
        checkLogin
    ) => {
        this.onClosePopup();
        let counterConnect = {
            id,
            pncode,
            name,
            transaction,
            isActive,
            counter,
            checkLogin
        };
        localStorage.setItem("checkCounter", JSON.stringify(counterConnect));
        this.setState({
            isConnectCounter: true,
            counter: counterConnect
        });
    };

    disconnectCounter = () => {
        const { dataConnectDesktop } = this.props.counterReducer;
        this.props.disconnectDesktop(
            dataConnectDesktop.CounterID,
            dataConnectDesktop.OfficeID,
            dataConnectDesktop.DeviceID
        );
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.counterReducer.err !== prevProps.counterReducer.err) {
            const { err } = this.props.counterReducer;
            const isHaveErrConnection = err && typeof (err) === "object" && err[ERROR_CONNECTION];
            if (isHaveErrConnection) {
                const POPUP_LEVEL_1 = 1;
                const dataTransferToPopup = {
                    image_noti: icCloseNotification,
                    title: buildErrorContent(ERROR_CONNECTION, err[ERROR_CONNECTION]),
                    titleConfirm: "Xác nhận",
                    widthPopup: "204px"
                }
                this.props.clearError(ERROR_CONNECTION);
                this.props.openPopup(
                    popupBioTypes.PopupAccect,
                    POPUP_LEVEL_1,
                    dataTransferToPopup
                );
                this.props.closePopup(2);
                this.props.closePopup(3);
            }
        }
    }

    render() {
        const { dataConnectDesktop, dataLogin } = this.props.counterReducer;

        return (
            <BoundStyle>
                {Object.keys(dataConnectDesktop).length > 0 &&
          Object.keys(dataLogin).length > 0 ? (
                        <MainPage
                            //user={this.state.user}
                            //counter={this.state.counter}
                            //showNoticePopup={offsetObj => {
                            //this.showPopup(popupType.showNotice, offsetObj);
                            //}}
                            //showUserManagePopup={(dataGetTicket) =>
                            //this.showPopup(popupType.popupUserManage, { dataGetTicket })}
                            //showPassTicketPopup={(data) => {
                            // console.log('showPassTicketPopup',data)
                            //this.showPopup(popupType.passTicket, data);
                            //}}
                            //showFingerPrintInfoPopup={() =>
                            //this.showPopup(popupType.fingerPrintInfo, {})
                            //}
                            //showPopupCheckCustomer={() =>
                            //this.showPopup(popupType.configCheckCustomer, {})
                            //}
                            //counterReducer={this.props.counterReducer}
                        />
                    ) : (
                        <LoginPage
                            // dataGetTicket = {this.props.data}
                            //counter={this.state.counter}
                            //isLogin={this.isLogin}
                            //showCounterLoginPopup={() => {
                            //this.showPopup(popupType.configCouterLogin, {});
                            //}}
                            //showCounterSuccessPopup={() => {
                            //this.showPopup(popupType.configCouterSuccess, {});
                            //}}
                            //showCounterFailPopup={popupObj => {
                            //this.showPopup(popupType.configCouterFail, popupObj);
                            //}}
                        />
                    )}
                {/* //////// PopupContainerRedux ///////// */}
                <PopupContainerRedux />
                {/* //////// PopupContainerRedux ///////// */}
            </BoundStyle>
        );
    }
}

const mapStateFromProps = state => ({
    counterReducer: state.counterReducer,
    popupBioManagerReducer: state.popupBioManagerReducer
});

const mapDispatchFromProps = dispatch => ({
    openPopup: (type, levelPopup, data) => dispatch(openPopup(type, levelPopup, data)),
    closePopup: (levelPopup) => dispatch(closePopup(levelPopup)),
    clearError: (typeError) => dispatch(clearError(typeError)),
    disconnectDesktop: (counterId, officeId, deviceId) =>
        dispatch(disconnectDesktop(counterId, officeId, deviceId))
});

export default connect(
    mapStateFromProps,
    mapDispatchFromProps,
    null,
    { forwardRef: true }
)(MainContainer);
