import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

//Images
import ic_close from '../../../images/ic_close_gray.svg';
// import { updateBioInfosNote, getBioInfos } from '../../../../actions/supervisorAction/biometricAction';
// import { DELETE } from '../../../../data/infosType';

const Bound = styled.div`
    z-index:999999;
    padding: 10.1px 10.2px 16px 20px;
    width: 250px;
    /* height: 192px; */
    border-radius: 10px;
    background-color: #fafafa;
    font-family: SVN-Gilroy;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px 0px #0000008a;
    .ic_close{
        text-align: right;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 4px;
    }
    .content {
        h3{
            font-size: 11px;
            font-weight: bold;
            line-height: 1.26;
            color: #005993;
            text-transform: uppercase;
        }
        .addReason {
            border: solid 0.5px #c8c8c8;
            padding: 3px;
            margin-top: 4px;
            height: 84px;
            overflow: auto;
            overflow-x: hidden;
            ::-webkit-scrollbar {
                width: 3px;
            }

            ::-webkit-scrollbar-thumb {
                background: #c8c8c8; 
            }
            p{
                font-family: SVN-Gilroy;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.25;
                color: #222222;
                margin: 5px 0 0 0;
                opacity: 0.8;
                word-break: break-word;
            }
        }
        textarea{
            width: 222px;
            height: 84px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.25;
            color: #222222;
            border-radius: 4px;
            border: solid 0.5px #c8c8c8;
            background-color: #ffffff;
            resize: none;
            margin-top: 2px;
            padding: 9px 9px 0 9px;   
            outline:none;
            margin: 5px 0 0 0;
            font-family: SVN-Gilroy;
            &::placeholder {
                opacity: ${props=> (props.rejectRegisterNote || props.removeNote) ? '1' : '0.25'};
            }
            ::-webkit-scrollbar {
                width: 3px;
            }

            ::-webkit-scrollbar-thumb {
                background: #c8c8c8; 
            }
        }
    }
    .btn_ok {
        width: 80px;
        height: 36px;
        border-radius: 18px;
        background-color: #005993;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 16px auto 0 auto;
        cursor: pointer;
        text-transform: uppercase;
    }
`
class PopupNote extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notice: ''
        }
    }
    handleNotice = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    onSubmit = () => {
        const {/* data, */ type} = this.props
        const { notice } = this.state
        if(notice.trim()){
            // console.log(data)
            // this.props.updateBioInfosNote(data.ID, notice)
            // this.props.getBioInfos({
            //     OfficeID: this.props.userReducer.dataLogin.OfficeID,
            //     Status: 'PENDING',
            //     PageSize: 10, 
            //     PageNumber: 1
            // })
            if(this.props.type === 'noteVerify'){
                this.props.onshowPopupVerify()
                // this.props.onClosePopup()
            }else if(type === 'noteBiometricInfo'){
                this.props.onClosePopupNoteLv2()
            }else if(type === 'noteHistoryBiometricInfo'){
                this.props.onCloseNoteHistoryLv2()
            }else if(this.props.type === 'noteRemoveFace'){
                this.props.onCloseFaceNote()
            }else{
                this.props.onClosePopup()
                this.props.outPopup()
                // this.props.onCloseCheck()
            }
        }else if(type === 'noteMatchFace'){
            this.props.onClosePopupLv1()
        }else if(type === 'noteBiometricInfo'){
            this.props.onClosePopupNoteLv2()
        }else if(type === 'noteHistoryBiometricInfo'){
            this.props.onCloseNoteHistoryLv2()
        }else if(this.props.type === 'noteRemoveFace'){
            this.props.onCloseFaceNote()
        }
    }

    onClose = () => {
        if(this.props.type === 'noteBiometricInfo'){
            this.props.onClosePopupNoteLv2()
        }else if(this.props.type === 'noteHistoryBiometricInfo'){
            this.props.onCloseNoteHistoryLv2()
        }else if(this.props.type === 'noteMatchFace'){
            this.props.onClosePopupLv1()
        }else if(this.props.type === 'noteRemoveFace'){
            this.props.onCloseFaceNote()
        }else{
            this.props.onClosePopup()
            this.props.outPopup()
        }
    }

    render() {
        const {title, placeholder, rejectRegisterNote, type, data, isActive} = this.props
        let elmHolder, elmBoolean
        switch (type) {
        case 'noteMatchFace':
            elmHolder = rejectRegisterNote
            elmBoolean = true
            break;
        case 'noteBiometricInfo': 
            elmHolder = data.RemoveNote
            elmBoolean = true
            break
        case 'noteHistoryBiometricInfo': 
            elmHolder = data.RemoveNote
            elmBoolean = true
            break
        case 'noteRemoveFace': 
            elmHolder = data.RemoveNote
            elmBoolean = true
            break
        default:
            elmHolder = placeholder
            elmBoolean = false
            break;
        }

        return (
            <div
                style = {{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 999999
                }}>
                <Bound rejectRegisterNote = {rejectRegisterNote} removeNote = {data !== undefined ? data.RemoveNote : null}>
                    <div className="ic_close"
                        onClick = {this.onClose}
                    >
                        <img src={ic_close} alt="ic_close"/>
                    </div>
                    <div className="content">
                        <h3>{title}</h3>
                        {
                            isActive === 1 ?
                                <div className = 'addReason'>
                                    <p>{elmHolder[0]}</p>
                                    <p>{elmHolder[1]}</p>
                                </div>
                                :
                                <textarea
                                    // disabled = {type === 'noteMatchFace' ? true : false}
                                    disabled = {elmBoolean}
                                    name = 'notice'
                                    maxLength = '200'
                                    onChange = {this.handleNotice}
                                    // placeholder = {type === 'noteMatchFace' ? rejectRegisterNote : placeholder}>
                                    placeholder = {elmHolder}>
                                </textarea>
                        }
                    </div>
                    <div className="btn_ok"
                        onClick = {this.onSubmit}>
                        Xác nhận
                    </div>
                </Bound>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer,
        biometricReducer: state.biometricReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // updateBioInfosNote: (idBio, noteBio) => dispatch(updateBioInfosNote(idBio, noteBio)),
        // getBioInfos: (dataQuery) => { dispatch(getBioInfos(dataQuery))}
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(PopupNote)