import React, { Component } from 'react';
import styled from "styled-components";
import { connect } from 'react-redux'


// component
// import FaceUpdateFlow from './PopupUserManage/FaceUpdateFlow';
import PopupShowErrorExistedDataBiometric from './PopupShowErrorExistedDataBiometric';

// action
import { apiGetBioFile } from "../../actions/biometricAction"
import { TELLER } from '../../data/userRole';
import { apiGetBioFileCollector } from '../../actions/biometricWithInBioCollectorAction';


const Bound = styled.div`
    position: absolute;
    /* width: 100%; */
    /* height: 100%; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 100%; */
    display: flex;
    
    
    &, & > * {
        box-sizing: border-box;
    }

    .left-content, .right-content {
        position: relative;
    }

    .left-content {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
            /* width: 240px; */
            height: 240px;
            border: 10px solid #020202;
            border-top-left-radius: 11px;
            border-bottom-left-radius: 11px;
        }

        /* & > * {
            position: absolute;
            top: 0;
            right: 0;
        } */

        /* & > * {
            transform: translate(1%, -50%);
        } */
    }
    
    .right-content {
        flex-grow: 1;
        /* & > * {
            position: absolute;
            top: 0;
            left: 0;
        } */
        /* & > * {
            transform: translate(-101%, -50%);
        } */
    }

    
`;

class PopupMixResultConfirmFace extends Component {
    render() {
        const { 
            ticketId,
            closePopup 
        } = this.props;
        const currentTicket = 
            this.props.counterReducer.listCurrentTicket.find(item => 
                item.TicketID === ticketId
            )
        if(!currentTicket) return;
        const bioFace = this.props.biometricReducer[ticketId].FACE.REGISTER;
        const userIsTeller = this.props.counterReducer.dataLogin.Roles.includes(TELLER);
        const linkGetFace = userIsTeller?apiGetBioFile:apiGetBioFileCollector
        // debugger;
        return (
            <Bound>
                <div className="left-content">
                    <img src={linkGetFace(bioFace)} alt="face" />
                </div>
                <div className="right-content">
                    <PopupShowErrorExistedDataBiometric 
                        typeExistedData="FACE"
                        data={currentTicket.duplicateFaces || []}
                        ticketId={ticketId}
                        isDisabledCenter={true}
                        closePopup={closePopup}
                        acceptAction={() => {}}
                    />
                </div>
            </Bound>
        )
    }
}

const mapStateToProps = state => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer
})

export default connect(mapStateToProps)(PopupMixResultConfirmFace);
