import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// styles
import { WrapListWaitingCollector } from './index.styles'

// images
import ic_warning from '../../../../images/ic_warning.svg'
import iconNotificationClose from "../../../../images/ic_counter_notification_close.svg"

// data
import statusBiometrics, { typeStatusBiometrics } from '../../../../data/statusBiometricWithTicket'
import { popupBioTypes } from '../../../../data/PopupTypes'
import bioActionTypes from '../../../../data/bioActionTypes'
// action
import { getListDataSentSupervisor, loadPendingBioToCounter } from '../../../../actions/inBioCollector/listDataSentSupervisorAction'
import { openPopup } from '../../../../actions/popupBioAction'
import { getCurrentBioEnrollCollector } from '../../../../actions/biometricWithInBioCollectorAction'

// component
import CountTimeWaiting from '../../../Control/CountTimeWaiting'
import FilterCondition from './FilterCondition'

const ListWaitingCollector = () => {

    const dispatch = useDispatch()

    const { listDataSentSupervisor, CounterID, listCurrentTicket } = useSelector(state => ({
        listDataSentSupervisor: state.inBioCollector.listDataSentSupervisor,
        CounterID: state.counterReducer.dataConnectDesktop.CounterID,
        listCurrentTicket: state.counterReducer.listCurrentTicket
    }))

    const _handleShowCustDetails = (data) => {
        dispatch(openPopup(popupBioTypes.PopupShowCustDetailsBl, 1, {
            data
        }))
    }

    const _handleMoveSuccess = () => {
        dispatch(getCurrentBioEnrollCollector(CounterID))
    }

    const _handleMoveTicketUptoCounter = (id) => {
        const someTicketEmpty = listCurrentTicket.some(ticket => !ticket.CustomerInfos)
        if (!someTicketEmpty) {
            dispatch(openPopup(popupBioTypes.PopupAccect, 1, {
                image_noti: iconNotificationClose,
                title: "Không thể gọi khách hàng vào phiên vì đã đủ phiên giao dịch",
                widthPopup: "276px",
                titleConfirm: "Xác nhận",
            }))
            return
        }
        dispatch(loadPendingBioToCounter(id, _handleMoveSuccess))
    }

    useEffect(() => {
        dispatch(getListDataSentSupervisor())
    }, []);

    const { listData, err } = listDataSentSupervisor

    return (
        <WrapListWaitingCollector>
            <div className="title">Danh sách hàng chờ</div>
            <div className="notice">
                <img src={ic_warning} alt="warning" />
                <div>Dữ liệu trạng thái chờ/ từ chối sẽ bị xóa nếu không được phê duyệt trong ngày</div>
            </div>
            <div className="wrap-tools">
                <FilterCondition />
            </div>
            <div className="grid grid-title">
                <div>STT</div>
                <div>Họ tên KH</div>
                <div>CMND/ CCCD/ HC</div>
                {/* <div>Số CIF</div> */}
                <div>Thời gian chờ</div>
                <div>User tác nghiệp</div>
                <div>Thời gian tác nghiệp</div>
                <div>User phê duyệt</div>
                <div>Thời gian phê duyệt</div>
                <div>Trạng thái dữ liệu</div>
            </div>
            <div className="wrap-table">
                {
                    listData.map((data, index) => {
                        const {
                            ID,
                            // FullNameVi,
                            CustomerInfos: {
                                IdNumber,
                                CustomerNumber,
                                Name
                            } = {},
                            TellerInfos,
                            CreateAt,
                            SupervisorInfo,
                            CompleteAt,
                            Status,
                            BioInfosType
                        } = data
                        const telUserName = TellerInfos?.UserName || ''
                        const createAt = moment(CreateAt).format('HH:mm - DD/MM/YYYY')
                        const countTimeWaiting = Status === typeStatusBiometrics.PENDING
                            ? <CountTimeWaiting data={CreateAt} />
                            : null
                        const supUserName = Status === typeStatusBiometrics.PENDING
                            ? <div></div>
                            : BioInfosType === bioActionTypes.NOTREGISTER
                                ? <span className="auto-approved">Auto</span>
                                : <div title={SupervisorInfo?.UserName}>{SupervisorInfo?.UserName || ''}</div>
                        const completeAt = Status === typeStatusBiometrics.PENDING
                            ? ""
                            : moment(CompleteAt).format('HH:mm - DD/MM/YYYY')
                        const status = statusBiometrics[Status]
                        return (
                            <div className="grid grid-item" key={ID} onDoubleClick={() => _handleShowCustDetails(data)}>
                                <div title={index + 1}>{index + 1}</div>
                                <div title={Name}>{Name}</div>
                                <div title={IdNumber}>{IdNumber}</div>
                                {/* <div title={CustomerNumber}>{CustomerNumber}</div> */}
                                <div>{countTimeWaiting}</div>
                                <div title={telUserName}>{telUserName}</div>
                                <div title={createAt}>{createAt}</div>
                                {supUserName}
                                <div title={completeAt}>{completeAt}</div>
                                <div className="data-status">
                                    <div style={{ color: status?.colorInTaskCollector }}>{status?.textInQueueCollector}</div>
                                    <div className="wrap-action">
                                        <div className="btn-show-cust-infos" onClick={() => _handleShowCustDetails(data)}>Xem</div>
                                        <div className="call-arrow" onClick={() => _handleMoveTicketUptoCounter(ID)} ></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </WrapListWaitingCollector>
    )
}

export default ListWaitingCollector
