import React, { Component } from "react";
import { connect } from "react-redux";
import { openPopup, closePopup } from '../../../../actions/popupBioAction'
import { Nzero } from '../../../../helper/tool'
//component
import ButtonControl from "./ButtonControl";
import ButtonUserInfoControl from "./ButtonUserInfoControl";
// import CountTimeServe from "../../../Control/CountTimeServe";
import ButtonTaskControl from "./ButtonTaskControl";
import LoadingControl from "../../../Control/LoadingControl";
import CountTimeServeV2 from "../../../Control/CountTimeServeV2";
import ErrorGetTicket76 from "../../../../helper/ErrorGetTicket76";

// styles
import { Bound } from './InformTransactionContainerV2/index.styles'

//data
import {
    taskControlButton
} from "../../../../data/data";
import * as ticketStatuses from "../../../../data/ticketStatuses";
import {
    COMPLETE_TICKET_ERR,
    // UNPAUSE_TICKET_ERR,
    GET_TICKET_ERR,
    ERROR_CONNECTION
} from "../../../../actions/types";
import * as bioTypes from "../../../../data/bioTypes";
import { popupBioTypes } from "../../../../data/PopupTypes";
import typesKeyDetailPopup from "../../../../data/typesKeyDetailPopup";
import statusBiometrics, { typeStatusBiometrics } from "../../../../data/statusBiometricWithTicket";

//Image
import ic_subtract from "../../../../images/ic_subtract.svg";
import img_user from "../../../../images/img_user.svg";
import ic_ticket_success from "../../../../images/ic_ticket_success.svg";
import ic_KHVL from "../../../../images/ic_subAvatar.svg";
import ic_search_white from "../../../../images/ic_search_white.svg";
import iconNotificationClose from "../../../../images/ic_counter_notification_close.svg"
import device_management from '../../../../images/device_management.svg'
import ic_finger_query from '../../../../images/ic_finger_query.svg'
import ic_warning_big from '../../../../images/ic_warning_big.svg'
import ic_warning from '../../../../images/ic_warning.svg'

// import action
import {
    getCurrentTickets,
    completeTicket,
    getInfoCustomer,
    // requestEnroll,
    pauseTicket,
    unpauseTicket,
    addCustomerInfos,
    getTicket,
    recallTicket,
    addBiometricExistFromCustomerInfos,
    addInprogressBioExistFromCustomerInfo
} from "../../../../actions/counterAction";
import {
    getCurrentBioEnroll,
    requestRemoveBioData,
    apiGetBioFile,
    apiGetBioFaceImage
} from "../../../../actions/biometricAction";
import { clearError } from "../../../../actions/commonAction";
import * as counterStates from '../../../../data/counterStates';

// import helper
import { buildErrorContent, countDuplicated } from "../../../../helper/tool";
import {
    openPopupSearchCif,
    openPopupRecallTicket
} from "../../../../helper/actionOpenPopup"
// import { counter } from "../../../../data/mockData";
import autoOpenPopupWhenGetTicket from "../../../../helper/autoOpenPopupWhenGetTicket";
import checkApplyBiometricForTicket from "../../../../helper/checkApplyBiometricForTicket";
import isDisableEventWithCORPCust from "../../../../helper/isDisableEventWithCORPCust";
import { VALIDATE_DATA, AUTH_TRANS, CONFIRM, REGISTER, LIST_ID, REMOVE } from "../../../../actions/typeRequestBio";
import verifyFingerStatusTypes from "../../../../data/verifyFingerStatusTypes";
import StatusSocket from "../../../Control/StatusSocket";
import fromChannels from "../../../../data/fromChannels";
import FaceDuplicate from "./FaceDuplicate";


class InformTransactionContainerV2 extends Component {
    constructor(props) {
        super(props);
        const firstTicketInprocessing = this.props.counterReducer.listCurrentTicket.find(ticket => ticket.Status === ticketStatuses.INPROCESSING);
        const indexOfFirstTicketInprocessing = this.props.counterReducer.listCurrentTicket.findIndex(ticket => ticket.Status === ticketStatuses.INPROCESSING);
        this.state = {
            mainTaskArr: this.props.counterReducer.counterState === counterStates.OPEN_COUNTER &&
        this.props.counterReducer.listCurrentTicket,
            mainTask:
        this.props.counterReducer.counterState === counterStates.OPEN_COUNTER && this.props.counterReducer.listCurrentTicket && this.props.counterReducer.listCurrentTicket.length > 0 ?
            firstTicketInprocessing ?
                firstTicketInprocessing
                :
                this.props.counterReducer.listCurrentTicket[0]
            : null,
            // isActive: this.props.isActive,
            taskIndex: indexOfFirstTicketInprocessing !== -1 ? indexOfFirstTicketInprocessing + 1 : 1,
            ////get ticket
            listTypeCounterServices: this.props.listTypeServices,
            isLoading: false,
            avatarCustomer: img_user
        };
        this._isMounted = false;
    }


    UNSAFE_componentWillReceiveProps(n) {
        if (n) {
            const firstTicketInprocessing = n.counterReducer.listCurrentTicket.find(ticket => ticket.Status === ticketStatuses.INPROCESSING);
            const indexOfFirstTicketInprocessing = n.counterReducer.listCurrentTicket.findIndex(ticket => ticket.Status === ticketStatuses.INPROCESSING);
            this.setState({
                listTypeCounterServices: n.listTypeServices,
                // isActive: n.counterState,
                mainTaskArr: this.props.counterReducer.counterState === counterStates.OPEN_COUNTER && n.counterReducer.listCurrentTicket,
                mainTask:
          this.props.counterReducer.counterState === counterStates.OPEN_COUNTER && n.counterReducer.listCurrentTicket.length > 0 ?
              n.counterReducer.listCurrentTicket.length !== this.state.mainTaskArr.length ?
                  firstTicketInprocessing ?
                      firstTicketInprocessing
                      :
                      n.counterReducer.listCurrentTicket[0]
                  :
                  n.counterReducer.listCurrentTicket[this.state.taskIndex - 1]
              :
              null,
                taskIndex: n.counterReducer.listCurrentTicket.length !== this.state.mainTaskArr.length ?
                    indexOfFirstTicketInprocessing !== -1 ?
                        indexOfFirstTicketInprocessing + 1
                        :
                        n.counterReducer.listCurrentTicket.length
                    :
                    this.state.taskIndex
            });
        }
    }

    changeTask = async taskIndex => {
        let { mainTask, avatarCustomer } = this.state;
        // let { biometricReducer } = this.props;
        // debugger;
        if (this.state.taskIndex === taskIndex) return;

        if (this.state.mainTaskArr /*&& this.state.mainTaskArr[taskIndex - 1]*/) {

            // change task
            mainTask = this.state.mainTaskArr[taskIndex - 1];


        } else {
            mainTask = null;
            avatarCustomer = img_user;
        }
        await this.setState({
            taskIndex,
            mainTask,
            avatarCustomer
        });
    };

    renderService() {
        if (this.state.mainTask && this.state.mainTask.ListService) {
            let countDuplicatedServices = countDuplicated(this.state.mainTask.ListService);
            return countDuplicatedServices.map((data, i) => {
                let userType = this.state.listTypeCounterServices.find(
                    item => item.ID === data.value
                );
                let nameTypeServices = "";
                let countUserType = { ...userType, count: data.count };
                if (userType) {
                    nameTypeServices = `${userType.Name}` + (countUserType.count > 1 ? ` (${countUserType.count})` : "");
                }
                // debugger
                return (
                    <p
                        className="transaction show-tooltip"
                        style={{ textDecoration: "underline" }}
                        key={i}
                        title={nameTypeServices}
                    >
                        {nameTypeServices}
                        {/*<span className="tooltip-trans-inprogress-ticket">{nameTypeServices}</span>*/}
                    </p>
                );
            });
        } else {
            return <p className="transaction">Chưa có thông tin giao dịch</p>;
        }
    }


    getTicketBtn = async () => {
        const { counterReducer } = this.props;
        await this.setState({ isLoading: true });
        await this.props.getTicket(
            counterReducer.dataConnectDesktop.CounterID,
            counterReducer.dataConnectDesktop.DeviceID
        );
        await this.setState({ isLoading: false });
        const errorGetTicket = this.props.counterReducer.err[GET_TICKET_ERR];
        if (errorGetTicket && errorGetTicket.Code === 76) {
            const OPEN_POPUP_LEVEL_1 = 1;
            const { CustomerNumber, CustomerName, TicketNumber, TellerName, OfficeName } = errorGetTicket
            // data follow props in PopupAccept component
            const dataTranferFollowPopup = {
                image_noti: iconNotificationClose,
                title: <ErrorGetTicket76
                    CustomerNumber={CustomerNumber}
                    CustomerName={CustomerName}
                    TicketNumber={TicketNumber}
                    TellerName={TellerName}
                    OfficeName={OfficeName}
                />,
                widthPopup: "363px",
                titleConfirm: "Xác nhận"
            }

            this.props.clearError(GET_TICKET_ERR)

            this.props.openPopup(
                popupBioTypes.PopupAccect,
                OPEN_POPUP_LEVEL_1,
                dataTranferFollowPopup
            )
            return;
        }
        if (this.props.counterReducer.err[GET_TICKET_ERR] === 0) {
            const POPUP_LEVEL_1 = 1;
            const dataTranferToPopup = {
                image_noti: iconNotificationClose,
                title: buildErrorContent(GET_TICKET_ERR, this.props.counterReducer.err[GET_TICKET_ERR]),
                widthPopup: "190px",
                titleConfirm: "Xác nhận"
            }
            this.props.clearError(GET_TICKET_ERR)
            this.props.openPopup(
                popupBioTypes.PopupAccect,
                POPUP_LEVEL_1,
                dataTranferToPopup
            )
            return;
        }
        // debugger;
        if (this.props.counterReducer.err[ERROR_CONNECTION])
            return;

        if (
            this.state.mainTask &&
      this.state.mainTask.CustomerInfos &&
      Object.keys(this.state.mainTask.CustomerInfos).length > 0 &&
      !isDisableEventWithCORPCust(this.state.mainTask.TicketID, this.props.counterReducer)
        ) {
            autoOpenPopupWhenGetTicket(
                this,
                this.state.mainTask.TicketID
            );

        }
    };


    handleHaveBioEnrollNotConfirmYet = (dataTicket) => {
        const {
            data: content,
            note: contentDelete
        } = buildErrorContent(
            COMPLETE_TICKET_ERR,
            this.props.counterReducer.err[COMPLETE_TICKET_ERR]
        )

        // clear err
        this.props.clearError(COMPLETE_TICKET_ERR);

        // open popup notice
        const dataConfigPopup = {
            key: typesKeyDetailPopup.noticeHaveBioNotConfirmYet,
            content,
            contentDelete,
            width: "288px",
            fontSizeNote: "12px",
            btnLeft: {
                title: "Huỷ bỏ",
                bgColor: "var(--peacock-blue)",
                fontSize: "13px"
            },
            btnRight: {
                title: "Hoàn tất GD",
                bgColor: "var(--lipstick)",
                fontSize: "13px"
            },
            dataTicket
        }
        this.props.openPopup(
            popupBioTypes.PopupConfirm,
            1,
            dataConfigPopup
        )
    }

    handleNoneHaveBioInfo = (dataTicket) => {
        const dataConfigPopup = {
            key: typesKeyDetailPopup.noticeNoneHaveBioInfos,
            content: "Khách hàng chưa có thông tin sinh trắc học. Bạn có muốn thực hiện đăng ký?",
            width: "286px",
            btnLeft: {
                title: "Hoàn tất GD",
                fontSize: "13px"
            },
            btnRight: {
                title: "Đồng ý",
                fontSize: "13px"
            },
            dataTicket
        }
        this.props.openPopup(
            popupBioTypes.PopupConfirm,
            1,
            dataConfigPopup
        )
    }

    handleRecallTicket = (e) => {
        e.preventDefault();
        if (this.state.mainTask.Status !== ticketStatuses.PAUSED) {
            const { TicketID: ticketId } = this.state.mainTask;
            // const OPEN_POPUP_LEVEL_1 = 1;
            const dataTranferToPopup = {
                callAt: this.state.mainTask.CallAt,
                ticketId
            }
            // this.props.openPopup(
            //   popupBioTypes.PopupConfirm,
            //   OPEN_POPUP_LEVEL_1,
            //   dataTranferToPopup
            // )
            openPopupRecallTicket(
                this,
                dataTranferToPopup,
                "handleRecallTicket - InformTransactionContainer.js"
            )
            // this.props.recallTicket(ticketId);
        }
    }

    checkLoadSourceUserImg = () => {
        const { mainTask } = this.state;
        if (!mainTask) return this.state.avatarCustomer;

        const { CustomerInfos } = mainTask;
        if (!CustomerInfos) return this.state.avatarCustomer;

        const { biometricReducer } = this.props;
        const { statusBiometric } = mainTask;

        const biometricOfTicket = biometricReducer[mainTask.TicketID];
        if (!biometricOfTicket) return this.state.avatarCustomer;

        const faceOfTicket = biometricOfTicket[bioTypes.FACE];
        if (!faceOfTicket) return this.state.avatarCustomer;
        const faceStatus = faceOfTicket.bioActionType;
        const faceRegister = faceOfTicket[REGISTER];
        const faceConfirm = faceOfTicket[CONFIRM];
        const faceAlreadyAuth = faceOfTicket[VALIDATE_DATA];
        if (faceStatus === REMOVE && String(statusBiometric) === typeStatusBiometrics.ACCEPT)
            return this.state.avatarCustomer;
        if (!!faceRegister && faceConfirm === 0 && String(statusBiometric) === typeStatusBiometrics.ACCEPT)
            return apiGetBioFile(faceRegister);
        if (faceAlreadyAuth)
            return apiGetBioFaceImage(faceAlreadyAuth[LIST_ID]);

        return this.state.avatarCustomer;
    }

    checkLoadFaceFromChannel = () => {
        const { mainTask } = this.state;
        if (!mainTask) return '';

        const { CustomerInfos } = mainTask;
        if (!CustomerInfos) return '';

        const { biometricReducer } = this.props;
        const { statusBiometric } = mainTask;

        const biometricOfTicket = biometricReducer[mainTask.TicketID];
        if (!biometricOfTicket) return '';

        const faceOfTicket = biometricOfTicket[bioTypes.FACE];
        if (!faceOfTicket) return '';
        const faceStatus = faceOfTicket.bioActionType;
        const faceRegister = faceOfTicket[REGISTER];
        const faceConfirm = faceOfTicket[CONFIRM];
        const faceAlreadyAuth = faceOfTicket[VALIDATE_DATA];
        if (faceStatus === REMOVE && String(statusBiometric) === typeStatusBiometrics.ACCEPT)
            return '';
        if (!!faceRegister && faceConfirm === 0 && String(statusBiometric) === typeStatusBiometrics.ACCEPT)
            return faceOfTicket.fromChannel;
        if (faceAlreadyAuth)
            return faceAlreadyAuth.fromChannel;

        return '';
    }

    //// Check Flow REGISTER or UPDATE ////// 
    OpenPopupUserManager = async (mainTask) => {
        await checkApplyBiometricForTicket(this, mainTask.TicketID)

        const { biometricReducer } = this.props;
        const flowUpdate = true;
        let isHaveBio;
        let cifs;
        let validatedData;
        if (biometricReducer[mainTask.TicketID])
            isHaveBio = true;
        validatedData = /validatedData/.test(JSON.stringify(biometricReducer[mainTask.TicketID]));
        if (mainTask.CustomerInfos)
            cifs = true
        else cifs = false
        switch (true) {
        case cifs && !isHaveBio:
            this.props.openPopup(7, 1, mainTask);
            // this.props.openPopup(7, 1, { ...mainTask, flowUpdate })

            break;
        case cifs && isHaveBio && validatedData:
            this.props.openPopup(7, 1, { ...mainTask, flowUpdate })
            break;
        case cifs && isHaveBio && !validatedData:
            this.props.openPopup(7, 1, mainTask)
            break;
        case !cifs:
            this.props.openPopup(7, 1, mainTask)
            break;
        default:
            break;
        }

    }

    handleCompleteTransaction = async () => {
    // debugger
    // 
        const { mainTask } = this.state;
        //pause ticket
        if (mainTask.Status !== ticketStatuses.INPROCESSING) {
            return;
        }

        // if (!mainTask.CustomerInfos) {
        if (!mainTask.ListService || (!!mainTask.ListService && mainTask.ListService.length <= 1)) {
            this.props.openPopup(
                popupBioTypes.PopupConfirm,
                1,
                {
                    // content: `Khách hàng có ${Nzero(mainTask.ListService.length)} giao dịch cần thực hiện, bạn có muốn kết thúc giao dịch ?`,
                    content: `Bạn có muốn kết thúc giao dịch?`,
                    width: "264px",
                    dataTicket: mainTask,
                    key: 'completeTicket'
                }
            )
            return;
        }


        //have service uncomplete
        if (mainTask.ListService.length > 1) {
            this.props.openPopup(3, 1, {
                content: `Khách hàng có ${Nzero(mainTask.ListService.length)} giao dịch cần thực hiện, bạn có muốn kết thúc giao dịch ?`,
                // content: `Bạn có muốn kết thúc giao dịch?`,
                width: "264px",
                dataTicket: mainTask,
                key: 'completeTicket'
            })
            return;
        }

    }

    handleSearchCifWithoutTicket = () => {
        openPopupSearchCif(
            this,
            {},
            "handleSearchCifWithoutTicket - InformTransactionContainer.js"
        );
    }



    handleSearchAndApplyCifIntoTicket = () => {
        openPopupSearchCif(
            this,
            { ticketId: this.state.mainTask.TicketID },
            "handleSearchAndApplyCifIntoTicket - InformTransactionContainer.js"
        );
    }

    isEnableBtnUserInfos = () => {
        let { mainTask } = this.state;
        if (!mainTask) return false;
        const { counterReducer } = this.props;
        const isNotTicketPaused = mainTask.Status !== ticketStatuses.PAUSED;
        const ticketIsCORPCust = isDisableEventWithCORPCust(mainTask.TicketID, counterReducer)
        const isTicketHaveCustomer = !!mainTask.CustomerInfos;
        // debugger
        return isNotTicketPaused && (!ticketIsCORPCust || (ticketIsCORPCust && isTicketHaveCustomer))
    }

    genNameAndPhoneOfTask = (mainTask) => {
    // let { mainTask } = this.state;
        if (!mainTask)
            return {
                cusName: "",
                cusPhone: ""
            }
        const {
            statusBiometric,
            dataWasAuthen,
            CustomerInfos
        } = mainTask;
        // debugger;
        if (!CustomerInfos)
            return {
                cusName: "",
                cusPhone: ""
            }
        if (String(statusBiometric) === typeStatusBiometrics.ACCEPT)
            return {
                cusName: CustomerInfos.FullNameVi || CustomerInfos.Name || "",
                cusPhone: CustomerInfos.MobileNumber || ""
            }
        if (!!dataWasAuthen)
            return {
                cusName: dataWasAuthen.fullNameVi || CustomerInfos.Name || "",
                cusPhone: dataWasAuthen.phoneNumber || CustomerInfos.MobileNumber || ""
            }
        return {
            cusName: "",
            cusPhone: ""
        }
    }

    hanleOpenPopupControlTablet = () => {
        this.props.openPopup(popupBioTypes.PopupControlTablet, 3, {});
    }

    hanleOpenPopupQueries = () => {
        this.props.openPopup(popupBioTypes.PopupQueries, 1, {});
    }

    render() {
        let tabBgColor1 = "";
        let tabBgColor2 = "";
        let tabColor1 = "";
        let tabColor2 = "";
        let tabContent1 = "Chưa có khách hàng";
        let tabContent2 = "Chưa có khách hàng";
        let zIndex1 = 2;
        let zIndex2 = 1;
        const { cusName: cusName1 } = this.genNameAndPhoneOfTask(this.state.mainTaskArr[0]);
        const { cusName: cusName2 } = this.genNameAndPhoneOfTask(this.state.mainTaskArr[1]);
        if (this.state.taskIndex <= 1) {
            tabBgColor1 = "#ffffff";
            tabBgColor2 = "#e9e9e9";
            tabColor2 = "rgba(34,34,34,0.25)";
            zIndex1 = 2;
            zIndex2 = 1;
            if (this.state.mainTaskArr && this.state.mainTaskArr.length > 0) {
                if (this.state.mainTaskArr[0].CustomerInfos) {
                    if (
                        this.state.mainTaskArr[0] &&
            this.state.mainTaskArr[0].CustomerInfos.Name
                    ) {
                        tabColor1 = "#005993";
                        // tabContent1 = `${this.state.mainTaskArr[0].Number} - ${this.state.mainTaskArr[0].dataWasAuthen.fullNameVi ||this.state.mainTaskArr[0].CustomerInfos.Name}`;
                        tabContent1 = `${this.state.mainTaskArr[0].Number} - ${cusName1}`;
                    } else {
                        tabColor1 = "#C8C8C8";
                    }
                } else {
                    tabColor1 = "#005993";
                    tabContent1 = `${this.state.mainTaskArr[0].Number} - Khách hàng vãng lai`;
                }
                if (this.state.mainTaskArr[1] && this.state.mainTaskArr[1].CustomerInfos) {
                    if (
                        this.state.mainTaskArr[1] &&
            this.state.mainTaskArr[1].CustomerInfos.Name
                    ) {
                        // tabContent2 = `${this.state.mainTaskArr[1].Number} - ${this.state.mainTaskArr[1].dataWasAuthen.fullNameVi || this.state.mainTaskArr[1].CustomerInfos.Name}`;
                        tabContent2 = `${this.state.mainTaskArr[1].Number} - ${cusName2}`;
                    }
                } else if (!this.state.mainTaskArr[1]) {
                    tabColor2 = "#C8C8C8";
                    tabContent2 = "Chưa có khách hàng";
                }
                else {
                    tabColor2 = "#C8C8C8";
                    tabContent2 = `${this.state.mainTaskArr[1].Number} - Khách hàng vãng lai`;
                }
            }
        }
        else {
            tabBgColor1 = "#e9e9e9";
            tabBgColor2 = "#ffffff";
            tabColor1 = "rgba(34,34,34,0.25)";
            zIndex1 = 1;
            zIndex2 = 2;
            if (this.state.mainTaskArr && this.state.mainTaskArr.length > 0) {
                if (
                    this.state.mainTaskArr[1] &&
          this.state.mainTaskArr[1].CustomerInfos
                ) {
                    tabColor2 = "#005993";
                    tabContent2 = `${this.state.mainTaskArr[1].Number} - ${cusName2}`;
                } else if (!this.state.mainTaskArr[1]) {
                    tabColor2 = "#C8C8C8";
                    tabContent2 = "Chưa có khách hàng";
                }
                else {
                    tabColor2 = "#005993";
                    tabContent2 = `${this.state.mainTaskArr[1].Number} - Khách hàng vãng lai`;
                }
                if (
                    this.state.mainTaskArr[0] &&
          this.state.mainTaskArr[0].CustomerInfos
                ) {
                    tabContent1 = `${this.state.mainTaskArr[0].Number} - ${cusName1}`;
                    tabColor1 = "#C8C8C8";
                }
                else {
                    // tabColor2 = "#C8C8C8";
                    tabColor2 = "#005993";
                    tabContent1 = `${this.state.mainTaskArr[0].Number} - Khách hàng vãng lai`;
                }
            }
        }

        ////// UserType //////
        let ic_subType = ic_KHVL;
        // if (this.state.mainTask) {
        //   if (this.state.mainTask.CustomerInfos.CustType === "0")
        //     ic_subType = ic_KHVL
        //   else ic_subType = ic_KHUT
        // }

        let { mainTask, mainTaskArr } = this.state;
        const { cusPhone: cusPhone3 } = this.genNameAndPhoneOfTask(this.state.mainTask);

        const dataInfos = {
            nameCus: mainTask
                ? mainTask.CustomerInfos
                    ? mainTask.CustomerInfos.Name
                    : 'KH vãng lai'
                : '',
            segmentDesc: mainTask && mainTask.CustomerInfos
                ? mainTask.CustomerInfos.SegmentDesc
                : '',
            phoneCus: mainTask
                ? mainTask.dataWasAuthen && cusPhone3
                : '',
            idNumber: mainTask && mainTask.CustomerInfos
                ? mainTask.CustomerInfos.IdNumber
                : '',
            idIssueDate: mainTask && mainTask.CustomerInfos
                ? mainTask.CustomerInfos.IdIssueDate
                : '',
            idIssuePlace: mainTask && mainTask.CustomerInfos
                ? mainTask.CustomerInfos.IdIssuePlace
                : ''
        }
        const existCustomerNumbers = (() => {
            const mainTaskCustNum = mainTask?.CustomerInfos?.CustomerNumber
            const existCustomerNumbers = mainTask?.CustomerInfos?.ExistCustomerNumbers || []
            return existCustomerNumbers.filter(custNum => custNum !== mainTaskCustNum)
        })()

        // const fromChannel = this.checkLoadFaceFromChannel()

        return (
            <Bound>
                <div className="tab-container">
                    <div
                        className="customer"
                        style={{
                            backgroundColor: tabBgColor1,
                            color: tabColor1,
                            zIndex: zIndex1,
                        }}
                        onClick={() => {
                            this.changeTask(1)
                        }}
                        title={tabContent1}
                    >
                        {tabContent1}
                    </div>
                    <div
                        className="customer"
                        style={{
                            backgroundColor: tabBgColor2,
                            color: tabColor2,
                            zIndex: zIndex2,
                        }}
                        onClick={() => {
                            this.changeTask(2)
                        }}
                        title={tabContent2}
                    >
                        {tabContent2}
                    </div>
                    <div className="socket-status">
                        <StatusSocket />
                    </div>
                </div>
                <div className="detail-inform-customer">
                    <div className="grid-col avatar">
                        {mainTask && (
                            <div className="task-control-button">
                                {/* <ButtonTaskControl data={taskControlButton.fingerPrintBtn}
                onSubmit={() => { }} /> */}

                                <ButtonTaskControl
                                    data={taskControlButton.reCallBtn}
                                    isActive={mainTask.Status !== ticketStatuses.PAUSED}
                                    onSubmit={this.handleRecallTicket}
                                />
                                <ButtonTaskControl
                                    data={taskControlButton.missCallBtn}
                                    isActive={mainTask.Status !== ticketStatuses.PAUSED}
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        if (mainTask.Status !== ticketStatuses.PAUSED) {
                                            const { TicketID } = mainTask
                                            const {
                                                dataLogin,
                                            } = this.props.counterReducer
                                            let dataMissCall = {
                                                counterID: dataLogin.CounterID,
                                                ticketID: TicketID,
                                                content:
                          'Chuyển vé được chọn vào danh sách gọi nhỡ?',
                                                key: 'moveTomissCall',
                                            }
                                            this.props.openPopup(3, 1, dataMissCall)
                                        }
                                    }}
                                />
                                <ButtonTaskControl
                                    data={taskControlButton.tranferTicketBtn}
                                    isActive={mainTask.Status !== ticketStatuses.PAUSED}
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        if (mainTask.Status !== ticketStatuses.PAUSED) {
                                            const { TicketID } = mainTask
                                            this.props.openPopup(10, 1, { TicketID })
                                        }
                                    }}
                                />
                            </div>
                        )}
                        <div className='wrapper-img'>
                            <img
                                className="img_user"
                                src={this.checkLoadSourceUserImg()}
                                alt="img_user"
                                onClick={() => {
                                    if (mainTask && this.isEnableBtnUserInfos()) {
                                        this.OpenPopupUserManager(mainTask)
                                    }
                                }}
                            />
                            {mainTask && (
                                <>
                                    <div
                                        className="div_ic_subAvatar"
                                        style={{
                                            opacity: this.isEnableBtnUserInfos()
                                                ? 'unset'
                                                : 0.5,
                                        }}
                                        onClick={() => {
                                            if (this.isEnableBtnUserInfos()) {
                                                this.OpenPopupUserManager(mainTask)
                                                // this.props.openPopup(7, 1, mainTask);
                                            }
                                        }}
                                    >
                                        <ButtonUserInfoControl image={ic_subType} />
                                    </div>
                                    {/* <div 
                    className="status-biometric"
                    style={{
                      background: statusBiometrics.REJECT.colorInTask
                    }}
                  >{statusBiometrics.REJECT.textInTask}</div> */}
                                    {String(mainTask.statusBiometric) !==
                    typeStatusBiometrics.UNDEFINED ? (
                                            <div
                                                className="status-biometric"
                                                style={{
                                                    background:
                          statusBiometrics[
                              String(
                                  mainTask.statusBiometric
                              )
                          ].colorInTask,
                                                }}
                                            >
                                                {
                                                    statusBiometrics[
                                                        String(mainTask.statusBiometric)
                                                    ].textInTask
                                                }
                                            </div>
                                        ) : null}
                                </>
                            )}
                        </div>
                        {
                            !!existCustomerNumbers.length &&
              <FaceDuplicate cusNum={mainTask.CustomerInfos.CustomerNumber} />
                        }
                    </div>
                    <div className="grid-col task-information">
                        <div className="grid-col">
                            <div className="task-info-item">
                                <p className="title">Tên kH</p>
                                <p className="info" title={dataInfos.nameCus}>
                                    {dataInfos.nameCus}
                                </p>
                            </div>
                            <div className="task-info-item">
                                <p className="title">Hạng kH</p>
                                <p className="info" title={dataInfos.segmentDesc}>
                                    {dataInfos.segmentDesc}
                                </p>
                            </div>
                            <div className="task-info-item">
                                <p className="title">SĐT di động</p>
                                <p className="info" title={dataInfos.phoneCus}>
                                    {dataInfos.phoneCus}
                                </p>
                            </div>
                            <div className="task-info-item">
                                <p className="title" style={{ marginBottom: '11px' }}>
                  Thời gian phục vụ
                                </p>
                                {this._isMounted && this.state.mainTask ? (
                                    <>
                                        <CountTimeServeV2
                                            ticketId={this.state.mainTask.TicketID}
                                        />
                                    </>
                                ) : (
                                    <div className="time">00 : 00 : 00</div>
                                )}
                            </div>
                        </div>
                        <div className="grid-col">
                            <div className="task-info-item">
                                <p className="title">CMND/CCCD/HC</p>
                                <p className="info" title={dataInfos.idNumber}>
                                    {dataInfos.idNumber}
                                </p>
                            </div>
                            <div className="task-info-item">
                                <p className="title">Ngày cấp</p>
                                <p className="info" title={dataInfos.idIssueDate}>
                                    {dataInfos.idIssueDate}
                                </p>
                            </div>
                            <div className="task-info-item">
                                <p className="title">Nơi cấp</p>
                                <p className="info" title={dataInfos.idIssuePlace}>
                                    {dataInfos.idIssuePlace}
                                </p>
                            </div>
                            {/* <div className="task-info-item">
                <p className="title">Kênh tác nghiệp</p>
                <p className="info" title={fromChannel}>
                  {fromChannel}
                </p>
              </div> */}
                        </div>
                        <div className="grid-col">
                            <div className="inform-service">
                                <p className="title">Thông tin giao dịch</p>
                                <div className="inform-service-wrapper">
                                    {this.renderService()}
                                </div>
                            </div>
                        </div>
                    </div>
                    {(this.props.counterState === counterStates.OPEN_COUNTER ||
            mainTask) && (
                        <div className="grid-col button-contact">
                            {(() => {
                                switch (true) {
                                case mainTaskArr.length === 2:
                                case mainTaskArr.length === 1 &&
                      mainTask &&
                      mainTask.Status !== ticketStatuses.PAUSED:
                                case mainTaskArr.length === 1 &&
                      !mainTask &&
                      mainTaskArr[0].Status !==
                      ticketStatuses.PAUSED:
                                    return (
                                        <ButtonControl
                                            margin="0 0 10px 6px"
                                            icon={ic_subtract}
                                            bgColor="#005993"
                                            content="Gọi KH tiếp theo"
                                            onSubmit={() => { }}
                                            isDisable={true}
                                            // cursor="default"
                                        />
                                    )
                                default:
                                    return (
                                        <ButtonControl
                                            margin="0 0 10px 6px"
                                            icon={ic_subtract}
                                            bgColor="#005993"
                                            content="Gọi KH tiếp theo"
                                            onSubmit={
                                                !this.state.isLoading
                                                    ? () => {
                                                        this.getTicketBtn()
                                                    }
                                                    : () => { }
                                            }
                                        />
                                    )
                                }
                            })()}
                            {mainTask ? (
                                <React.Fragment>
                                    <ButtonControl
                                        margin="0 0 10px 6px"
                                        icon={ic_ticket_success}
                                        bgColor="#0fb11f"
                                        isDisable={
                                            mainTask.Status !==
                        ticketStatuses.INPROCESSING
                                        }
                                        // cursor={mainTask.Status !== ticketStatuses.INPROCESSING ? "default" : "pointer"}
                                        content="Hoàn tất gd"
                                        onSubmit={this.handleCompleteTransaction}
                                    />
                                    <ButtonControl
                                        margin="0 0 10px 6px"
                                        icon={ic_search_white}
                                        bgColor="#d71249"
                                        isDisable={
                                            mainTask.Status !==
                        ticketStatuses.INPROCESSING ||
                        String(mainTask.statusBiometric) !==
                        typeStatusBiometrics.UNDEFINED ||
                        (this.props.counterReducer
                            .verifyCustomer[
                                mainTask.TicketID
                            ] &&
                          this.props.counterReducer
                              .verifyCustomer[
                                  mainTask.TicketID
                              ].result &&
                          this.props.counterReducer
                              .verifyCustomer[
                                  mainTask.TicketID
                              ].result.Code !== 0) ||
                        Object.keys(
                            this.props.counterReducer.enrollId
                        ).includes(mainTask.TicketID) ||
                        // mainTask.IsVerifyFinger
                        mainTask.VerifyFingerStatus !==
                        verifyFingerStatusTypes.NV ||
                        isDisableEventWithCORPCust(
                            mainTask.TicketID,
                            this.props.counterReducer
                        )
                                        }
                                        content="tìm kiếm KH mới"
                                        onSubmit={
                                            this.handleSearchAndApplyCifIntoTicket
                                        }
                                    />
                                </React.Fragment>
                            ) : !mainTaskArr.length ||
                  (mainTaskArr.length === 1 &&
                    mainTaskArr[0].Status ===
                    ticketStatuses.PAUSED) ? (
                                    <ButtonControl
                                        margin="0 0 10px 6px"
                                        icon={ic_search_white}
                                        bgColor="#d71249"
                                        content="tìm kiếm KH"
                                        onSubmit={this.handleSearchCifWithoutTicket}
                                    />
                                ) : (
                                    <ButtonControl
                                        margin="0 0 10px 6px"
                                        icon={ic_search_white}
                                        bgColor="#d71249"
                                        content="tìm kiếm KH"
                                        onSubmit={() => { }}
                                        isDisable={true}
                                    />
                                )}
                            <ButtonControl
                                margin="0 0 10px 6px"
                                icon={device_management}
                                bgColor="#1477EC"
                                content="quản lý tablet"
                                onSubmit={this.hanleOpenPopupControlTablet}
                            />
                            <ButtonControl
                                margin="0 0 0 6px"
                                icon={ic_finger_query}
                                bgColor="#005993"
                                content="màn hình vấn tin"
                                onSubmit={this.hanleOpenPopupQueries}
                                width="138px"
                            />
                            {this.state.isLoading && (
                                <div className="loading">
                                    <LoadingControl
                                        loadingPage={false}
                                        size="30px"
                                    />
                                    <p className="title_loading">Đang tải...</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Bound>
        )
    }

    handleCheckIPAY = async () => {
        const { mainTask } = this.state;
        if (!mainTask) return;

        if (isDisableEventWithCORPCust(mainTask.TicketID, this.props.counterReducer)) return;

        await checkApplyBiometricForTicket(this, mainTask.TicketID);

        const currentTicket = this.props.counterReducer.listCurrentTicket.find(item =>
            item.TicketID === mainTask.TicketID
        )
        if (!currentTicket) return;
        const { CustomerInfos, statusBiometric } = currentTicket
        const isNotHaveStatusSupervisor =
      !statusBiometric ||
      String(statusBiometric) === typeStatusBiometrics.UNDEFINED
        if(!isNotHaveStatusSupervisor) return; // ko ở trạng thái gửi ksv
        if (currentTicket.LatestHistoryType !== REGISTER) return; // ko phải lần đầu vào quầy
        if (currentTicket.VerifyFingerStatus !== verifyFingerStatusTypes.NV) return;

        const biometricOfTicket = this.props.biometricReducer[mainTask.TicketID];
        const wrapConditionOpenPopup = CustomerInfos.BiometricFace + CustomerInfos.BiometricFinger;

        const isHaveBiometric = !!biometricOfTicket
        const isHaveBiometrictFaceAndNoneFinger = /10/.test(wrapConditionOpenPopup) ||
      (!!biometricOfTicket &&
        !!Object.keys(biometricOfTicket).find(item =>
            item === bioTypes.FACE && Object.keys(biometricOfTicket[item]).includes(VALIDATE_DATA)
        ));

        const bioFace = biometricOfTicket[bioTypes.FACE]
        const { fromChannel } = bioFace[VALIDATE_DATA]
        if (['', fromChannels.SDB].includes(fromChannel))
            return;
        if (isHaveBiometric && isHaveBiometrictFaceAndNoneFinger) {
            const POPUP_LEVEL_1 = 1;
            this.props.openPopup(
                popupBioTypes.PopupAccect,
                POPUP_LEVEL_1,
                {
                    key: typesKeyDetailPopup.updateBioIpay,
                    image_noti: ic_warning_big,
                    title: "Khách hàng đăng ký STH tại kênh ngoài quầy. Vui lòng đăng ký bổ sung sinh trắc học vân tay hoặc nhập lý do không đăng ký bổ sung tại quầy",
                    widthPopup: "367px",
                    btnWidth: "129px",
                    titleConfirm: "đăng ký ngay",
                    dataTicket: { ...mainTask }
                }
            )
        }
    }

    handleAuthCustomer = async () => {
    // const  { listCurrentTicket } = this.props.counterReducer;
        const { mainTask } = this.state;
        // const mainTask = listCurrentTicket.find(item => 
        //   !item.IsVerifyFinger
        // )
        if (!mainTask) return;
        if (isDisableEventWithCORPCust(mainTask.TicketID, this.props.counterReducer)) return;

        await checkApplyBiometricForTicket(this, mainTask.TicketID);

        const currentTicket = this.props.counterReducer.listCurrentTicket.find(item =>
            item.TicketID === mainTask.TicketID
        )
        if (!currentTicket) return;
        // if(currentTicket.IsVerifyFinger) return;
        if (currentTicket.VerifyFingerStatus !== verifyFingerStatusTypes.NV) return;

        const {
            statusBiometric,
            TicketID: ticketId
        } = currentTicket;

        const biometricOfTicket = this.props.biometricReducer[ticketId];
        if (!biometricOfTicket) return;

        const lstFingerAuth = [];
        Object.keys(biometricOfTicket).forEach(item => {
            const isBioFinger = item !== "FACE";
            if (!isBioFinger) return;

            const bioAddWasAccept = statusBiometric === typeStatusBiometrics.ACCEPT;
            const registerBioConfirmed = Object.keys(biometricOfTicket[item]).includes(AUTH_TRANS);
            const bioUsedToAuth = biometricOfTicket[item][AUTH_TRANS];
            if (bioAddWasAccept && registerBioConfirmed && !bioUsedToAuth)
                return;
            // debugger
            if (bioAddWasAccept && registerBioConfirmed && bioUsedToAuth) {
                lstFingerAuth.push(item)
                return;
            }

            const isBioHaveValidatedData = Object.keys(biometricOfTicket[item]).includes("validatedData");
            const isValidatedDataHaveConfirm = isBioHaveValidatedData &&
        Object.keys(biometricOfTicket[item].validatedData).includes("usedToAuthenticateTransaction");

            if ((isValidatedDataHaveConfirm &&
        biometricOfTicket[item].validatedData.usedToAuthenticateTransaction) &&
        !biometricOfTicket[item].validatedData.isHang)
                lstFingerAuth.push(item)
        })
        // debugger
        if (lstFingerAuth.length) {
            const POPUP_LEVEL_1 = 1;
            this.props.openPopup(
                popupBioTypes.CheckCustomerActions,
                POPUP_LEVEL_1,
                { ticketId }
            )
        }
    }

    async componentDidUpdate(prevProps, prevState) {
    // return;
    // if(this.state.mainTask !== prevState.mainTask){
    //   const currentTicketId = !!this.state.mainTask && 
    //     this.state.mainTask.TicketID;
    //   const prevTicketId = !!prevState.mainTask && 
    //     prevState.mainTask.TicketID;
    //   if(currentTicketId !== prevTicketId){
    //     this.handleAuthCustomer();
    //   }
    //   if(currentTicketId && prevTicketId && currentTicketId === prevTicketId){
    //     const prevStatusBiometric = prevState.mainTask.statusBiometric;
    //     const currentStatusBiometric = this.state.mainTask.statusBiometric;
    //     if(prevStatusBiometric === typeStatusBiometrics.PENDING &&
    //       currentStatusBiometric === typeStatusBiometrics.ACCEPT){
    //       this.handleAuthCustomer();
    //     }
    //   }
    // }

    }


    async componentDidMount() {
        this._isMounted = true;
        const { counterReducer } = this.props;
        await this.props.getCurrentTickets(counterReducer.dataConnectDesktop.CounterID, counterReducer.dataConnectDesktop.OfficeID)

        // return;
        await this.handleAuthCustomer();
        this.handleCheckIPAY();


        // check apply bioinfos if this customer have bioinfos
        // const customerInfos = this.props.counterReducer.dataGetTicket.TicketData.CustomerInfos
        // if(customerInfos && Number(customerInfos.BiometricActive)){
        //   await this.props.getInfoCustomer(
        //     this.props.counterReducer.dataConnectDesktop.CounterID, 
        //     this.props.counterReducer.dataGetTicket.TicketData.CustomerInfos.CustomerNumber
        //   )
        //   await this.props.addBiometricExistFromCustomerInfos(
        //     this.props.counterReducer.customerInfo.BioInfos,
        //     this.props.counterReducer.dataGetTicket.TicketData.TicketID
        //   )
        // }
        // ====================================
        // const { listCurrentTicket, dataConnectDesktop } = this.props.counterReducer;
        // // console.log(listCurrentTicket)
        // // debugger;
        // listCurrentTicket.map(async (ticket, i) => {
        //   const customerInfos = ticket.CustomerInfos;
        //   if(!!customerInfos && Number(customerInfos.BiometricActive)){
        //     await this.props.getInfoCustomer(
        //       this.props.counterReducer.dataConnectDesktop.CounterID, 
        //       customerInfos.CustomerNumber/* ,
        //       ticket.TicketID */
        //     )

    //     if(this.props.counterReducer.customerInfo.InprogressBio){
    //       await this.props.addInprogressBioExistFromCustomerInfo(
    //         this.props.counterReducer.customerInfo.InprogressBio,
    //         ticket.TicketID
    //       );
    //       // const {InprogressBio: inprogressBio} = this.props.counterReducer.customerInfo;
    //     }else
    //       await this.props.addBiometricExistFromCustomerInfos(
    //         this.props.counterReducer.customerInfo.BioInfos, 
    //         ticket.TicketID
    //       );
    //   }
    //   // // }
    //   if(!this.props.counterReducer.customerInfo.InprogressBio)
    //     await this.props.getCurrentBioEnroll(
    //       dataConnectDesktop.CounterID, 
    //       ticket.TicketID, 
    //       ticket.CustomerInfos && ticket.CustomerInfos.CustomerNumber
    //     )
    //   // await
    // })
    // 
    // 
    // 
    // ====================================
    // this.checkLoadSourceUserImg();
    // const POPUP_LEVEL_1 = 1;
    // this.props.openPopup(
    //   popupBioTypes.CheckCustomerActions,
    //   POPUP_LEVEL_1,
    //   { ticketId: this.state.mainTask.TicketID }
    // )
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


}

const mapStateFromProps = state => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer
});

const mapDispatchFromProps = dispatch => ({
    getTicket: (counterId, deviceId) => dispatch(getTicket(counterId, deviceId)),
    getCurrentTickets: (CounterID, OfficeID) => dispatch(getCurrentTickets(CounterID, OfficeID)),
    getCurrentBioEnroll: (counterId, ticketId, customerNumber = undefined) => dispatch(getCurrentBioEnroll(counterId, ticketId, customerNumber)),
    completeTicket: (counterId, ticketId, forceCompelete = false) =>
        dispatch(completeTicket(counterId, ticketId, forceCompelete)),
    requestRemoveBioData: (enrollId, bioType, bioId, ticketId, typeRequestBio) => dispatch(requestRemoveBioData(enrollId, bioType, bioId, ticketId, typeRequestBio)),
    clearError: typeErr => dispatch(clearError(typeErr)),
    getInfoCustomer: (counterId, customerNumber, typeSearch = 0) => dispatch(getInfoCustomer(counterId, customerNumber, typeSearch)),
    addInprogressBioExistFromCustomerInfo: (inprogressBio, ticketId) => dispatch(addInprogressBioExistFromCustomerInfo(inprogressBio, ticketId)),
    addBiometricExistFromCustomerInfos: (bioInfos, ticketId) => dispatch(addBiometricExistFromCustomerInfos(bioInfos, ticketId)),
    // requestEnroll: (ticketId, cusInfo) => dispatch(requestEnroll(ticketId, cusInfo)),
    ////////////////////////////////
    openPopup: (typePopup, levelPopup, data) => dispatch(openPopup(typePopup, levelPopup, data)),
    closePopup: (levelPopup) => dispatch(closePopup(levelPopup)),
    pauseTicket: (counterId, ticketId) => dispatch(pauseTicket(counterId, ticketId)),
    unpauseTicket: (counterId, ticketId) => dispatch(unpauseTicket(counterId, ticketId)),
    addCustomerInfos: (customerInfos, ticketID) => dispatch(addCustomerInfos(customerInfos, ticketID)),
    recallTicket: (ticketId) => dispatch(recallTicket(ticketId))
});

export default connect(
    mapStateFromProps,
    mapDispatchFromProps,
    null,
    { forwardRef: true }
)(InformTransactionContainerV2);
