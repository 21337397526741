import styled from 'styled-components'

const WrapFilterComboBox = styled.div`
    /* margin-left: 12px; */
    margin-left: 70px;
    display: flex;
    align-items: center;
    /* width: 25%; */

    .title-dropdown {
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 128%;
        color: #000000;
        margin-right: 7px;
        white-space: nowrap;
    }

    & > *:last-child {
        width: 150px;
    }
`;

export {
    WrapFilterComboBox
}