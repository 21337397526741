import React from 'react'
import { useDispatch } from 'react-redux'

// component
import CustBios from '.'

// constant
import { FACE } from '../../../../../../data/bioTypes'
import bioActionTypes from '../../../../../../data/bioActionTypes'
import { popupBioTypes } from '../../../../../../data/PopupTypes'

// image
import fingerAuthenTrans from '../../../../../../images/finger_Success.svg'
import fingerClosedUpdate from '../../../../../../images/finger_closed_update.svg'
import fingerHangUpdate from '../../../../../../images/finger_hang_update.svg'
import fingerAuthenRed from '../../../../../../images/finger_authen_red.svg'
import fingerRed from '../../../../../../images/finger_delete_red.svg'
// api vs action
import { apiGetBioFaceImageCollector, apiGetBioFileCollector } from '../../../../../../actions/biometricWithInBioCollectorAction'
import { openPopup } from '../../../../../../actions/popupBioAction'
import getFromChannel from '../../../../../../helper/getFromChannel'

const getStatusBio = (bioType, isActive, isConfirmFinger, bioActionType) => {
    if (bioType === FACE) {
        switch (true) {
        case bioActionType === bioActionTypes.REMOVE:
            return {
                class: 'remove',
                text: 'Xóa'
            }
        case isActive && isConfirmFinger:
            return {
                class: 'active',
                text: 'Hoạt động'
            }
        case !isActive && isConfirmFinger:
            return {
                class: 'hang',
                text: 'Đóng'
            }
        default:
            return {
                class: '',
                text: ''
            }
        }
    }
    switch (true) {
    case isActive && isConfirmFinger && bioActionType === bioActionTypes.REMOVE:
        return {
            class: 'remove-active',
            text: 'Xóa',
            imgUrl: fingerAuthenRed
        }
    case isActive && isConfirmFinger:
        return {
            class: 'active',
            text: 'Hoạt động',
            imgUrl: fingerAuthenTrans
        }
    case !isActive && !isConfirmFinger && bioActionType === bioActionTypes.REMOVE:
        return {
            class: 'remove-close',
            text: 'Xóa',
            imgUrl: fingerRed
        }
    case !isActive && !isConfirmFinger:
        return {
            class: 'close',
            text: 'Đóng',
            imgUrl: fingerClosedUpdate
        }
    case !isActive && isConfirmFinger && bioActionType === bioActionTypes.REMOVE:
        return {
            class: 'remove-hang',
            text: 'Xóa',
            imgUrl: fingerAuthenRed
        }
    case !isActive && isConfirmFinger:
        return {
            class: 'hang',
            text: 'Treo',
            imgUrl: fingerHangUpdate
        }
    default:
        return {
            class: '',
            text: ''
        }
    }
}

const mergeDataBio = (current, before) => {
    if (!current) return before
    const { BioActionType, EnrollData } = current
    switch (BioActionType) {
    case bioActionTypes.REGISTER: {
        return current
    }
    case bioActionTypes.UPDATE: {
        if (!EnrollData)
            return {
                ...current,
                EnrollData: before.EnrollData
            }
        return current;
    }
    case bioActionTypes.REMOVE: {
        return current
    }
    default:
        break;
    }
}

const CustBioBl = ({
    bioInfosType,
    bios
}) => {
    const dispatch = useDispatch()

    const faceBio = bios[FACE]
    const faceBioAfterMerge = faceBio
        ? mergeDataBio(faceBio.current, faceBio.before)
        : null
    const urlFace = (() => {
        if (bioInfosType === bioActionTypes.NOTREGISTER) return null
        if (!faceBioAfterMerge) return null
        const { BioActionType, EnrollData } = faceBioAfterMerge
        if (BioActionType === bioActionTypes.REGISTER)
            return apiGetBioFileCollector(EnrollData[0])
        return apiGetBioFaceImageCollector(EnrollData[0])
    })()

    const statusFace = !!faceBioAfterMerge
        ? getStatusBio(
            FACE,
            faceBioAfterMerge.IsActive,
            faceBioAfterMerge.IsConfirmFinger,
            faceBioAfterMerge.BioActionType,
        )
        : null

    const bioFinger = (() => {
        if (bioInfosType === bioActionTypes.NOTREGISTER) return []
        const valueBios = Object.values(bios)
        if (!valueBios.length) return [];
        const bioFinger = valueBios.filter(bio => bio.BioType !== FACE)
        if (!bioFinger.length) return [];
        const bioFingerAfterMerge = bioFinger.map(finger => mergeDataBio(finger.current, finger.before))
        return bioFingerAfterMerge
    })()

    const faceChannel = !!faceBioAfterMerge ? getFromChannel(faceBioAfterMerge.FromChannel) : null;

    const statusFinger = bioFinger
        .map((finger) => ({
            ...finger,
            ...getStatusBio(
                finger.BioType,
                finger.IsActive,
                finger.IsConfirmFinger,
                finger.BioActionType
            )
        }))
        .reduce((prev, curr) => ({ ...prev, [curr.BioType]: curr }), {})

    const _handleShowFace = () => {
        if (bioInfosType === bioActionTypes.NOTREGISTER) return null;

        dispatch(openPopup(popupBioTypes.PopupShowFaceQueries, 2, {
            url: urlFace,
            isActive: faceBioAfterMerge.IsActive,
            isConfirmFinger: faceBioAfterMerge.IsConfirmFinger,
        }))
    }

    const _handleShowFinger = (fingerType) => {

        dispatch(openPopup(popupBioTypes.PopupShowFingerQueries, 2, {
            dataFinger: statusFinger[fingerType]
        }))
    }

    return (
        <CustBios
            handleShowFace={_handleShowFace}
            urlFace={urlFace}
            statusFace={statusFace}
            faceChannel={faceChannel}
            statusFinger={statusFinger}
            handleShowFinger={_handleShowFinger}
        />
    )
}

export default CustBioBl
