// constant
import { CLEAR_DATA_USER_FROM_TABLET, DISPATCH_USER_FROM_TABLET, GET_USER_BY_FINGER_IMG, SET_LOADING_GET_USER_BY_FACE, SET_LOADING_GET_USER_BY_FINGER } from "./types";
import { TELLER } from "../data/userRole";

// api
import { apiGetCifs as apiGetCifsCounter } from './counterAction'
import { apiGetCifs as apiGetCifsCollector } from './biometricWithInBioCollectorAction'
import Axios from "axios";
import { FACE } from "../data/infosType";

export const setLoadingGetUserFace = (loading = true) => ({
    type: SET_LOADING_GET_USER_BY_FACE,
    payload: {
        loading
    }
})

export const setLoadingGetUserByFinger = (loading = true) => ({
    type: SET_LOADING_GET_USER_BY_FINGER,
    payload: {
        loading
    }
})

export const dispatchDataUsersFromTablet = (resultGetUser) => async (dispatch, getState) => {
    const { Code, Cifs } = resultGetUser

    if (Code !== 0 || !Cifs || !Cifs.length) {
        dispatch({
            type: DISPATCH_USER_FROM_TABLET,
            payload: {
                resGetUserByImg: resultGetUser
            }
        })
        return;
    }

    const store = getState()
    const {
        counterReducer: {
            dataLogin: { Roles },
            dataConnectDesktop: { CounterID }
        }
    } = store

    const isTeller = Roles.includes(TELLER)
    const apiGetCifs = isTeller ? apiGetCifsCounter : apiGetCifsCollector

    const promiseLstUserFromTablet = Cifs.map(async user => {
        const { Cifs, History } = user
        const { CustomerNumber } = Cifs
        const dataRequest = JSON.stringify({
            CounterID: CounterID,
            CifsNum: CustomerNumber,
            Type: 0
        })
        try {
            const res = await Axios.post(apiGetCifs, dataRequest)
            const { data } = res;
            const isSuccess = data.Code === 0 && data.Cifs;
            if (isSuccess) {
                const cif = data.Cifs[0]
                const { BioInfos: { BioDatas } } = data
                const face = BioDatas.find(item => item.BioType === FACE)
                return {
                    Cifs: {
                        CustomerNumber: cif.CustomerNumber,
                        Name: cif.Name,
                        FullNameVi: cif.FullNameVi,
                        CustomerType: cif.CustomerType,
                        IdNumber: cif.IdNumber,
                        IdIssueDate: cif.IdIssueDate,
                        IdIssuePlace: cif.IdIssuePlace,
                        MobileNumber: cif.MobileNumber,
                        Address: cif.Address,
                        SegmentDesc: cif.SegmentDesc,
                        Gender: cif.Gender,
                        Email: cif.Email,
                        FaceID: cif.FaceID,
                        faceFromChannel: face?.FromChannel
                    },
                    History
                }
            }
            else {
                return user
            }
        }
        catch (err) {
            return user
        }
    })

    const lstUserFromTablet = await Promise.all(promiseLstUserFromTablet)

    dispatch({
        type: DISPATCH_USER_FROM_TABLET,
        payload: {
            resGetUserByImg: {
                ...resultGetUser,
                Cifs: lstUserFromTablet
            }
        }
    })
}

export const clearDataUsersFromTablet = () => ({
    type: CLEAR_DATA_USER_FROM_TABLET
})

export const getUserByFingerImg = (resultGetUser) => async (dispatch, getState) => {
    const { Code, Customers } = resultGetUser
    if (Code !== 0 || !Customers || !Customers.length) {
        dispatch({
            type: GET_USER_BY_FINGER_IMG,
            payload: {
                resGetUserByFingerImg: resultGetUser
            }
        })
        return;
    }

    const store = getState()
    const {
        counterReducer: {
            dataLogin: { Roles },
            dataConnectDesktop: { CounterID }
        }
    } = store

    const isTeller = Roles.includes(TELLER)
    const apiGetCifs = isTeller ? apiGetCifsCounter : apiGetCifsCollector

    const promiseLstUserFromTablet = Customers.map(async user => {
        const { CustomerNumber } = user
        const dataRequest = JSON.stringify({
            CounterID: CounterID,
            CifsNum: CustomerNumber,
            Type: 0
        })
        try {
            const res = await Axios.post(apiGetCifs, dataRequest)
            const { data } = res;
            const isSuccess = data.Code === 0 && data.Cifs;
            if (isSuccess) {
                return {
                    ...user,
                    cifInfos: data
                }
            }
            else {
                return {
                    ...user,
                    cifInfos: null
                }
            }
        }
        catch (err) {
            return {
                ...user,
                cifInfos: null
            }
        }
    })

    const lstUserFromTablet = await Promise.all(promiseLstUserFromTablet)
    dispatch({
        type: GET_USER_BY_FINGER_IMG,
        payload: {
            resGetUserByFingerImg: {
                ...resultGetUser,
                Customers: lstUserFromTablet
            }
        }
    })
}