import React, { Component } from 'react'
import styled from "styled-components"

// images
import icCloseGray from "../../images/ic_close_gray.svg";
import icSuccessNotification from "../../images/ic_counter_notification_success.svg";
import icFailedNotification from "../../images/ic_counter_notification_close.svg";

// component
import ButtonConfirmControl from './ButtonConfirmControl';


const Bound = styled.div`
    background: #fff;
    width: 17em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    border-radius: 5px;
    .popup__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 21px;
            color: #000000;
            margin-right: 80px;
        }

        img {
            width: 24px;
            height: 24px;
            :hover {
                cursor: pointer;
            }
        }
    }

    .popup__content {
        margin-top: 19px;
        text-align: center;

        img {
            width: 48px;
            height: 48px;
        }

        div {
            display: inline-block;
            margin-top: 12px;
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 128%;
            text-align: center;
            width: 30ch;
        }
    }

    .popup__footer {
        margin-top: 34px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

class PopupNotify extends Component {
    render() {
        const { titlePopup, detailNotify, titleBtn, isSuccess } = this.props.data;
        return (
            <Bound>
                <div className="popup__header">
                    <div className="title">{ titlePopup }</div>
                    <img 
                        onClick={this.props.onClosePopup}
                        src={icCloseGray} 
                        alt="close-popup" 
                    />
                </div>
                <div className="popup__content">
                    <img 
                        src={
                            isSuccess ?
                                icSuccessNotification
                                :
                                icFailedNotification
                        } 
                        alt="notify" 
                    />
                    <div>{ detailNotify }</div>
                </div>
                <div className="popup__footer">
                    <ButtonConfirmControl 
                        onSubmit={this.props.onClosePopup}
                        bg_color="#0FB11F"
                        titleConfirm={titleBtn || "Hoàn tất"}
                    />
                </div>
            </Bound>
        )
    }
}

export default PopupNotify;
