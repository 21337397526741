import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

// helper
import useMounted from '../../../../../../helper/hooks/useMounted'

// component
import FilterComboBox from '../FilterComboBox'

const ALL_VAL = 'Tất cả'

const objAllVal = {
    key: ALL_VAL,
    value: ALL_VAL
}

const FilterByTeller = ({ handleChange, defaultValue }) => {

    const { inbioCollectors } = useSelector(state => ({
        inbioCollectors: state.inBioCollector.listUsername.inbioCollectors
    }))

    const [objTellers, setObjTellers] = useState([]);

    const mounted = useMounted()

    const _handleChange = (obj) => {
        if (!mounted.current) return;

        const { key } = obj
        if (key === ALL_VAL) {
            handleChange('')
            return;
        }
        handleChange(key)
    }

    const _handleSearch = (keySearch) => {
        const lstTellerMatch = inbioCollectors.filter(teller => teller.match(keySearch.trim()))
        const objTellers = lstTellerMatch.map(teller => ({ key: teller, value: teller }))
        setObjTellers(objTellers)
    }

    useEffect(() => {
        const objTellers = inbioCollectors.map(teller => ({ key: teller, value: teller }))
        setObjTellers(objTellers)
    }, [JSON.stringify(inbioCollectors)]);


    return (
        <FilterComboBox
            title="User tác nghiệp"
            lstItemFixed={[objAllVal]}
            lstItems={objTellers}
            onChange={_handleChange}
            defaultValue={defaultValue || objAllVal}
            placeHolder="Nhập User"
            onSearch={_handleSearch}
        />
    )
}

export default FilterByTeller
