import {
    CALL_TICKET_ERR,
    COUNTER_LOGIN_ERR,
    COUNTER_LOGOUT_ERR,
    CONNECT_DESKTOP_ERR,
    DISCONNECT_DESKTOP_ERR,
    MOVE_TICKET_TO_MISS_CALL_ERR,
    GET_LIST_TYPE_COUNTER_SERVICES_ERR,
    GET_CUSTOMER_INFORMATION_ERR,
    GET_TICKET_ERR,
    COMPLETE_TICKET_ERR,
    GET_ALL_TICKET_WAITING_ERR,
    REQUEST_ENROLL_ERR,
    REQUEST_GET_BIO_DATA_ERR,
    REQUEST_REMOVE_BIO_DATA_ERR,
    REQUEST_CONFIRM_BIO_DATA_ERR,
    GET_CURRENT_TICKETS_ERR,
    PAUSE_TICKET_ERR,
    UNPAUSE_TICKET_ERR,
    COMPLETE_BIO_ERR,
    GET_CURRENT_BIO_ENROLL_ERR,
    CHANGE_STATE_COUNTER_ERR,
    ERROR_CONNECTION,
    VERIFY_FINGER_ERR,
    EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR,
    EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR_ERR,
    CREATE_NEW_TICKET_ERR,
    ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR
} from '../actions/types'

export const errorData = {
    [CREATE_NEW_TICKET_ERR]: {
        76: ["Khách hàng", "đang được phục vụ với số vé", "bởi", "GDV", "PGD"]
    },
    [ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR]: {
        76: ["Khách hàng", "đang được phục vụ với số vé", "bởi", "GDV", "PGD"]
    },
    [MOVE_TICKET_TO_MISS_CALL_ERR]: {
        // 13: "Không thể chuyển gọi nhỡ vì đang chờ kiểm soát viên phê duyệt",
        13: "Bạn không được phép chuyển gọi nhỡ do thông tin sinh trắc học của KH đang chờ phê duyệt.",
    },
    [EDIT_FINGER_INFO_IN_REGISTER_THREAD_ERR]: {
        7: "Chưa hoàn thành cập nhật sinh trắc học khuôn mặt"
    },
    [EDIT_FINGER_INFO_IN_REGISTER_THREAD_COLLECTOR_ERR]:{
        7: "Chưa hoàn thành cập nhật sinh trắc học khuôn mặt"
    },
    [VERIFY_FINGER_ERR]: {
        1: "Lỗi xử lý dữ liệu xác thực vân tay - Code 1",
        2: "Vé không tồn tại trong hệ thống - Code 2",
        3: "Số vé này là vé khách vãng lai hoặc số CIF gửi lên không trùng với CIF của vé - Code 3",
        5: "Số vé không nằm trong quầy GDV đăng nhập, hoặc số vé không đang hoạt động - Code 5",
        7: "Quầy GDV này chưa được kết nối với thiết bị Tablet hỗ trợ GDV - Code 7",
        23: "Khách hàng hiện tại không có vân tay xác thực ở trạng thái hoạt động - Code 23"
    },
    [ERROR_CONNECTION]: {
        408: "Không thể kết nối được với hệ thống"
    },
    [CALL_TICKET_ERR]: {
        12: "Hệ thống chỉ cho phép tối đa 2 KH giao dịch tại một thời điểm",
        76: ["Khách hàng", "đang được phục vụ với số vé", "bởi", "GDV", "PGD"] 
    },
    [COUNTER_LOGIN_ERR]: {
        2: "Tài khoản đã bị xoá bởi quản trị viên phòng giao dịch, vui lòng đăng nhập bằng tài khoản khác.",
        22: "Quầy đã bị xoá bởi quản trị viên phòng giao dịch, vui lòng kết nối lại quầy trước khi đăng nhập.",
        6: "Tài khoản đăng nhập không đúng hoặc không tồn tại trong hệ thống.",
        7: "Tài khoản đăng nhập không đúng hoặc không tồn tại trong hệ thống.",
        10: "Tài khoản đăng nhập không phải GDV",
        32: "Thông tin đăng nhập không chính xác. Vui lòng kiểm tra lại",
        100: 'Thông tin đăng nhập không chính xác. Vui lòng kiểm tra lại'
    },
    [COUNTER_LOGOUT_ERR]: {
        100: 'something'
    },
    [CONNECT_DESKTOP_ERR]: {
        14: "Mã ID không tồn tại"
    },
    [DISCONNECT_DESKTOP_ERR]: {
        100: 'something'
    },
    [GET_LIST_TYPE_COUNTER_SERVICES_ERR]: {
        100: 'something'
    },
    [GET_CUSTOMER_INFORMATION_ERR]: {
        0: "Không tìm thấy thông tin tìm kiếm",
        100: 'something'
    },
    [GET_TICKET_ERR]: {
        0: 'Hết vé trong hàng đợi',
        76: ["Khách hàng", "đang được phục vụ tại quầy với số vé", "bởi", "GDV", "PGD"],
        100: 'something'
    },
    [COMPLETE_TICKET_ERR]: {
        6: {
            data: 'Khách hàng có thông tin sinh trắc học/CIF chưa được xác thực. Bạn có muốn kết thúc giao dịch',
            note: "(*) Dữ liệu chưa xác thực sẽ không được lưu trữ"
        },
        9: "Luồng xử lý sinh trắc đang mở",
        13: "Đang chờ kiểm soát viên phê duyệt thông tin sinh trắc học",
        15: "Bạn chưa thể kết thúc giao dịch do KSV chưa phê duyệt xác thực vân tay",
        16: "Chưa xác thực khách hàng vào quầy",
        17: "Vui lòng nhập lý do không đăng ký hoặc Tiếp tục đăng ký STH cho KH",
        100: 'Lỗi không xác định'
    },
    [GET_ALL_TICKET_WAITING_ERR]: {
        100: 'something'
    },
    [REQUEST_ENROLL_ERR]: {
        100: 'something'
    },
    [REQUEST_GET_BIO_DATA_ERR]: {
        100: 'something'
    },
    [REQUEST_REMOVE_BIO_DATA_ERR]: {
        100: 'something'
    },
    [REQUEST_CONFIRM_BIO_DATA_ERR]: {
        100: 'something'
    },
    [GET_CURRENT_TICKETS_ERR]: {
        100: 'something'
    },
    [PAUSE_TICKET_ERR]: {
        100: 'something'
    },
    [UNPAUSE_TICKET_ERR]: {
        100: 'something'
    },
    [COMPLETE_BIO_ERR]: {
        3: 'Không thể gửi KSV phê duyệt do thông tin KH không thay đổi',
        8: 'Khách hàng chưa có thông tin sinh trắc học, hoặc thông tin sinh trắc học chưa được xác thực.',
        16: 'Không thể gửi KSV phê duyệt do thông tin KH không thay đổi',
        18: 'Không thể gửi KSV phê duyệt do thông tin KH không thay đổi',
        100: 'something',
    },
    [GET_CURRENT_BIO_ENROLL_ERR]: {
        100: 'something'
    },
    [CHANGE_STATE_COUNTER_ERR]: {
        100: 'something',
        5: 'Thiết bị tablet chưa được kết nối với quầy'
    },
}