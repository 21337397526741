import React, { Component } from 'react'
import styled from "styled-components";
import { connect } from 'react-redux'

// import image
import icClose from "../../images/ic_close_gray.svg";

// component
import LoadingControl from '../Control/LoadingControl';

const Bound = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background: #fff;
    border-radius: 8px;
    max-width: 260px;
    min-width: 250px;
    box-shadow: 0 0 20px #00000070;

    .popup-close {
        width: 100%;
        text-align: right;
        img {
            cursor: pointer;
        }
    }

    .popup__head {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        &__title {
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            color: var(--peacock-blue);
            margin-right: 10px;
        }
        .tmp-close {
            width: 24px;
        }
        img {
            position: absolute;
            right: -6px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .popup__content {
        box-sizing: border-box;
        border: 0.5px solid #000;
        border-radius: 5px;
        min-height: 100px;
        /* min-width: 236px;
        max-width: 250px; */
        width: 100%;
        padding: 5px;
        margin: 7px 5px 0;
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        word-break: break-word;
        textarea {
            border: none;
            outline: none;
            border-radius: 4px;
            resize: none;
            font-family: 'SVN-Gilroy';
            font-size: 14px;
            line-height: 128%;
            padding: 9px;
            min-height: inherit;
            width: -webkit-fill-available;
            ::-webkit-scrollbar {
                display: none;
            }
            /*firefox*/
            scrollbar-width: none;
        }
    }

    .loading-remove {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;
        font-family: 'SVN-Gilroy';
    }

    & > button {
        border: none;
        outline: none;
        background: var(--peacock-blue);
        color: #fff;
        text-transform: uppercase;
        padding: 5px 7px;
        font-family: 'SVN-Gilroy';
        font-size: 14px;
        font-weight: bold;
        width: fit-content;
        border-radius: 18px;
        margin-top: 10px;

        &:hover {
            cursor: pointer;
        }
    }
`

class PopupShowReasonRejectBio extends Component {
    state = {
        note: "",
        loading: false
    }

    handleChangeValTextArea = (e) => {
        this.setState({note: e.target.value || ""})
    } 

    handleSubmit = () => {
        // debugger;
        if(this.isDisableBtn()) return;

        if(this.props.type === "addReasonDeleteBioData") {
            this.setState({ loading: true })
        }
        const data = {
            note: this.state.note.trim()
        }
        this.props.onClickBtnSubmit(data)
    }

    isDisableBtn = () => {
        const isFillReasonNotEnoughFinger = this.props.type && 
            ["addReasonNotFillFullData", "addReasonDeleteBioData"].includes(this.props.type);
        return isFillReasonNotEnoughFinger && (!this.state.note || (this.state.note &&!this.state.note.trim()))
    }

    render() {
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === this.props.ticketId);
        return (
            <Bound>
                <div className="popup-close">
                    <img 
                        src={icClose} 
                        alt="ic-close"
                        onClick={this.state.loading?null:this.props.onClosePopup}
                    />
                </div>
                <div className="popup__head">
                    <div className="popup__head__title">
                        {
                            this.props.type?
                                this.props.type === "addReasonNotFillFullData"?
                                    "Lý do không Đk đủ vân tay"
                                    :
                                    this.props.type === "addReasonDeleteBioData"?
                                        "Lý do xoá"
                                        :
                                        "Nội dung từ chối"
                                :
                                "Nội dung từ chối"
                        }
                    </div>
                    <div className="tmp-close"></div>
                </div>
                
                <div className="popup__content">
                    {
                        this.props.type ?
                            // this.props.type === "rejectRegisterFingerBio"?
                            //     <React.Fragment>
                            //         <div>{currentTicket.CustomerInfos.ReasonNRFinger}</div>
                            //         {/* <div>{currentTicket.CustomerInfos.ReasonNRFace}</div>
                            //         <div>{currentTicket.CustomerInfos.ReasonNRFinger}</div> */}
                            //     </React.Fragment>
                            //     :
                            this.props.type === "rejectRegisterBio"?
                                <React.Fragment>
                                    <div>{currentTicket.CustomerInfos.ReasonNR.split("<br>")[0]}</div>
                                    <div style={{wordBreak: "break-word"}}>{currentTicket.CustomerInfos.ReasonNR.split("<br>")[1]}</div>
                                </React.Fragment>
                                :
                                this.props.type === "addReasonNotFillFullData" ||
                                this.props.type === "addReasonDeleteBioData"?
                                    <textarea
                                        value={this.state.note}
                                        onChange={this.handleChangeValTextArea}
                                        placeholder={
                                            this.props.type === "addReasonNotFillFullData"?
                                                "Nội dung không đăng ký đủ 10 vân tay - Bắt buộc nhập"
                                                :
                                                this.props.type === "addReasonDeleteBioData"?
                                                    "Nhập lý do xoá - Bắt buộc nhập"
                                                    :
                                                    "Nội dung không quá 200 ký tự"
                                        }
                                        maxLength="200"
                                    ></textarea>
                                    :
                                    currentTicket.noteRejectBio
                            :
                            currentTicket.noteRejectBio
                    }
                </div>
                {
                    this.state.loading?
                        <div className="loading-remove">
                            <LoadingControl size="25px" loadingPage={false} />
                        Vui lòng chờ...
                        </div>
                        :
                        <button 
                            onClick={this.handleSubmit}
                            style={{
                                opacity: this.isDisableBtn()?"0.5":"unset"
                            }}
                        >
                        Xác nhận
                        </button>
                }
            </Bound>
        )
    }

    componentDidMount() {
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === this.props.ticketId);
        switch(true){
        case this.props.type === "addReasonNotFillFullData": {
            const { tellerNoteNotEnoughFinger } = currentTicket;
            this.setState({note: tellerNoteNotEnoughFinger || ""})
            break;
        }
        case this.props.type === "addReasonDeleteBioData": {
            const { bioDetailOptions } = currentTicket;
            const { bioType } = this.props.data;
            if(bioDetailOptions && bioType && bioDetailOptions[bioType]) {
                this.setState({note: bioDetailOptions[bioType].removeNote || ""});
                break;
            }
            this.setState({note: ""});
            break;
        }
        default:
            break;
        }
    }
}

const mapStateToProps = state => ({
    counterReducer: state.counterReducer
})

export default connect(mapStateToProps)(PopupShowReasonRejectBio);
