import styled from 'styled-components'

const WrapCustomerInfoDupFinger = styled.div`
    margin-top: 22px;
    font-family: 'SVN-Gilroy';
    background: #ffffff;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.25);
    padding: 14px 20px 12px;

    .field {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 128%;
        color: #222222;
        span {
            color: #AAAAAA;
        }

        &.chanel {
            font-size: 11px;
            line-height: 130%;
            margin-top: 6px;
        }

        &.single-field {
            margin-top: 14px;
        }
    }

    .title-content {
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 130%;
        color: #005993;
        text-transform: uppercase;
    }

    .wrap-face {
        width: 140px;
        margin-top: 6px;

        img {
            width: 100%;
            border-radius: 5px;
        }
    }

    .row {
        display: flex;
        flex-wrap: nowrap;
        &.main-row {
            margin-top: 11px;
            & > *:not(:first-child) {
                margin-left: 43px;
            }
        }

        &.row-finger {
            & > *:not(:first-child) {
                margin-left: 4px;
            }
        }
    }

    .wrap-finger {
        width: 68px;
        margin-top: 6px;
        img {
            width: 100%;
            border-radius: 5px;
            object-fit: contain;
        }
    }

    .finger-name {
        margin-top: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 130%;
        color: #005993;
    }
    
    .wrap-cif-infos {
        margin-top: 11px;
    }

`;

export {
    WrapCustomerInfoDupFinger
}