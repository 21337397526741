const NOTREGISTER = "NOTREGISTER";
const UPDATE = 'UPDATE';
const REGISTER = "REGISTER";
const REMOVE = "REMOVE";

const bioActionTypes = {
    NOTREGISTER,
    UPDATE,
    REGISTER,
    REMOVE
}

export const BioInfosType = {
    [REGISTER]: {
        color: '#0fb11f',
        label: 'Đăng ký mới',
        content: 'Đăng ký mới vân tay',
        contentF: 'Đăng ký mới khuôn mặt',
        ctLabel: 'Đăng ký',
        nameLabel: 'đã đăng ký',
        value: 'Đăng ký mới dữ liệu',
        vlType: 'Đăng ký mới STH'
    },
    [NOTREGISTER]: {
        color: '#F31919',
        label: 'Không đăng ký',
        nameLabel: 'không đăng ký',
        value: 'Không đăng ký thông tin STH'
    },
    [REMOVE]: {
        color: '#f31919',
        label: 'Xóa dữ liệu',
        content: 'Xóa hình ảnh vân tay',
        contentLFace: 'Xóa hình ảnh khuôn mặt',
        contentFace_Finger: 'Xóa hình ảnh khuôn mặt và vân tay',
        nameLabel: 'đã xóa',
        dlFinger: 'Xóa dữ liệu vân tay'
    },
    [UPDATE]: {
        color: '#0fb11f',
        label: 'Cập nhật dữ liệu',
        content: 'Cập nhật vân tay',
        nameLabel: 'đã cập nhật',
        contentLabel: 'Cập nhật',
        value: 'Cập nhật dữ liệu STH',
        colors: '#005993',
        uFace: 'Cập nhật khuôn mặt',
        cFace: '#1477EC',
        vlType: 'Cập nhật thông tin STH'
    }
}
export default bioActionTypes;