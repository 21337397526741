const min = Number("1".repeat(15));
const max = Number("9".repeat(15));

const createfakeTicket = () => {
    const randomIdTicket = Math.floor(Math.random() * (max - min + 1) + min)
    return ({
        TicketID: String(randomIdTicket),
        CustomerInfos: null
    })
}

export default createfakeTicket;