import React, { Component } from 'react';

//Component
import FingerPrint from './FingerPrint';
import PopupNote from './PopupNote';

//Images
import finger_white from '../../../../../../images/finger_white.svg';
import finger_delete_red from '../../../../../../images/finger_delete_red.svg';
import finger_register_green from '../../../../../../images/finger_register_green.svg';
import finger_update_blue from '../../../../../../images/finger_update_blue.svg';
import finger_authen_trans from '../../../../../../images/finger_authen_trans.svg';
import finger_authen_blue from '../../../.././../../images/finger_authen_blue.svg';
import finger_authen_red from '../../../.././../../images/finger_authen_red.svg';
import finger_authen_white from '../../../.././../../images/finger_authen_white.svg';
import ic_warning from '../../../../../../images/ic_warning.svg';
import bg_finger from '../../../../../../images/bg_finger.svg';
import ic_zoom from '../../../../../../images/ic_zoom.svg';
import ic_notify_face_update from '../../../../../../images/ic_notify_face_update.svg';
import ic_not_number from '../../../../../../images/ic_not_number.png';
import face_err from '../../../../../../images/face_err.svg';
import ic_biometric_delete from '../../../../../../images/ic_biometric_delete.svg';
import active_face from '../../../../../../images/active_face.svg';

//Data
import { dataFunction, biometrictFingerDataCollectorInQueue as biometrictFingerData } from '../../../../../../data/data';
import * as types from '../../../../../../data/infosType';
import { REGISTER, UPDATE, FACE, DELETE, ACTIVE, customType, ISACTIVE } from '../../../../../../data/infosType';
import { INBIOCOLLECTOR } from '../../../../../../data/userRole';

//Helper
import { formatDateTimeToSpecificFormat, formatDateTime } from '../../../../../../helper/formatTime';

//Action
import { apiGetBioFileCollector as apiGetBioFile } from '../../../../../../actions/biometricWithInBioCollectorAction'

// styles
import { Bound } from './index.styles';


var _ = require('lodash');
export default class UserBiometric extends Component {
    state = {
        isShowPopupLv1: false,
        imageLoadError: true,
    }

    onClosePopupLv1 = () => {
        this.setState({
            isShowPopupLv1: false
        })
    }

    onShowPopupNoteLv1 = () => {
        this.setState({
            isShowPopupLv1: true
        })
    }

    showFingerInfoCustomer = (biotype) => {
        this.props.showFingerInfoCustomer(biotype)
    }

    showMatchFace = () => {
        this.props.showMatchFace()
    }

    showFaceUpdateImage = (biotype) => {
        this.props.showFaceUpdateImage(biotype)
    }

    showFaceImage = (biotype) => {
        this.props.showFaceImage(biotype)
    }

    showFaceZoomImage = (biotype) => {
        this.props.showFaceZoomImage(biotype)
    }

    addDefaultSrc = (ev) => {
        if (this.state.imageLoadError) {
            this.setState({
                imageLoadError: false,
            })
        }
        ev.target.src = face_err
    }

    render() {
        let resultArrFinger = []
        let { BioDatas, CurrentBioData, BioInfosType, RejectRegisterNote, CustomerInfos } = this.props.dataUser
        BioDatas = JSON.parse(JSON.stringify(BioDatas))
        CurrentBioData = JSON.parse(JSON.stringify(CurrentBioData))
        let bioTypeFinger = BioDatas.filter(item => item.BioType !== FACE)
        const cloneBioDatas = _.cloneDeep(BioDatas)
        let bioUpdate
        let elmIsConfirmFinger, clIsConfirmFinger, icon, titleStatus, arrDiffCurrent = []
        const isUpdate = BioInfosType === UPDATE
        if (CurrentBioData) {

            bioUpdate = isUpdate && CurrentBioData !== null && CurrentBioData.map(item => {
                const indexBio = _.findIndex(cloneBioDatas, i => i.BioType === item.BioType);
                // item.matchCheck = true;
                if (indexBio < 0) {
                    arrDiffCurrent.push(item)
                    return item
                };
                const dataBio = cloneBioDatas[indexBio];
                // console.log(dataBio);
                if (dataBio.BioActionType !== "UPDATE" && dataBio.BioActionType !== "REMOVE") {
                    // item.matchCheck = true;
                    return item;
                }
                // Object.keys(JSON.parse(JSON.stringify(dataBio))).forEach(y => {
                let isSame = 0
                for (let y in dataBio) {
                    item = JSON.parse(JSON.stringify(item))
                    if ((typeof (dataBio[y]) && dataBio[y] !== null) || (dataBio[y] == null && !item[y])) {
                        item = JSON.parse(JSON.stringify(item))
                        if (dataBio.EnrollData !== null && dataBio.BioType === FACE && dataBio !== null && item.BioType === FACE) {
                            if (dataBio.BioActionType === UPDATE && (dataBio.IsActive !== item.IsActive || dataBio.IsActive === item.IsActive) && dataBio.EnrollData !== item.EnrollData) {
                                elmIsConfirmFinger = types.BioInfosType[UPDATE].uFace
                                clIsConfirmFinger = types.BioInfosType[UPDATE].cFace
                                icon = ic_notify_face_update
                                titleStatus = types.titleUFace
                            } else if (dataBio.BioActionType === DELETE) {
                                elmIsConfirmFinger = types.BioInfosType[DELETE].contentLFace
                                clIsConfirmFinger = types.BioInfosType[DELETE].color
                                icon = ic_biometric_delete
                                titleStatus = types.titleRemoveFace
                            }
                        } else if (dataBio.EnrollData === null && dataBio.BioType === FACE && dataBio !== null && item.BioType === FACE) {
                            if ((dataBio.IsActive !== item.IsActive) && (dataBio.EnrollData === null && item.EnrollData !== null)) {
                                // debugger
                                if (dataBio.IsActive) {
                                    // debugger
                                    elmIsConfirmFinger = customType[ACTIVE].value
                                    clIsConfirmFinger = customType[ACTIVE].color
                                    titleStatus = types.titleCloseActiveFace
                                    icon = active_face
                                } else {
                                    // debugger
                                    elmIsConfirmFinger = customType[ISACTIVE].value
                                    clIsConfirmFinger = customType[ISACTIVE].color
                                    icon = ic_notify_face_update
                                    titleStatus = types.titleCloseActiveFace
                                }
                            }
                        }
                        if (y === "IsConfirmFinger" && dataBio.IsConfirmFinger === item.IsConfirmFinger) {
                            isSame = 1;
                            // debugger
                        }
                        if (y === "IsActive" && dataBio.IsActive === item.IsActive && isSame === 1) {
                            isSame = 2;
                            // debugger
                        }
                        if (isSame === 2) {
                            if ((dataBio.BioActionType === DELETE || dataBio.BioActionType === UPDATE) && (item.BioActionType === REGISTER || item.BioActionType === UPDATE || item.BioActionType === '') &&
                                (dataBio.EnrollData !== item.EnrollData && dataBio.EnrollData !== null)) {
                                // debugger
                                isSame = 0;
                                item[y] = dataBio[y]
                                // debugger
                                continue
                            } else {
                                // debugger
                                isSame = 0;
                                dataBio[y] = item[y]
                                item.matchCheck = true;
                                // debugger
                                break
                            }
                        }
                        item[y] = dataBio[y]
                    }
                }
                cloneBioDatas.splice(indexBio, 1);
                return JSON.parse(JSON.stringify(item));
            })
        } else {
            bioUpdate = BioDatas
        }

        let dataBioUpdate = isUpdate && CurrentBioData !== null ? bioUpdate.concat(cloneBioDatas) : bioUpdate
        let informBiometric = isUpdate ? dataBioUpdate : cloneBioDatas

        const mergeBioCurrentDatasTypes = isUpdate && dataBioUpdate.map(item => item.BioType);
        const currentDiffDatasTypes = isUpdate && arrDiffCurrent.map(item => item.BioType);
        const sameArr = isUpdate && currentDiffDatasTypes.filter(item => mergeBioCurrentDatasTypes.includes(item));//["FACE"]
        // console.log('same', sameArr)
        const sameArrDiffGetData = isUpdate && sameArr.map(item => {
            const itemSameInBioDatas = bioUpdate.find(data => data.BioType === item)
            return itemSameInBioDatas
        })

        // console.log(sameArrDiffGetData)

        const diffArr = isUpdate && [
            ...mergeBioCurrentDatasTypes.filter(item => !currentDiffDatasTypes.includes(item))
        ];

        const diffArrGetData = isUpdate && diffArr.map(item => {
            const itemDiffInBioDatas = dataBioUpdate.find(data => data.BioType === item)
            return itemDiffInBioDatas
        })

        // console.log(sameArrDiffGetData, diffArrGetData)
        let diffCurrentArrGetData = isUpdate && diffArrGetData.map(item => {
            if (item.matchCheck && !item.matchdata) {
                if (item.BioActionType === UPDATE) {
                    if (item.IsConfirmFinger)
                        return { ...item, img_finger: finger_authen_blue }
                    return { ...item, img_finger: finger_update_blue }
                } else if (item.BioActionType === DELETE) {
                    if (item.IsConfirmFinger)
                        return { ...item, img_finger: finger_authen_red }
                    return { ...item, img_finger: finger_delete_red }
                }
            }
            if (item.matchCheck) {
                if (item.IsConfirmFinger)
                    return { ...item, img_finger: finger_authen_white }
                return { ...item, img_finger: finger_white }
            }
            if (item.BioActionType === REGISTER) {
                if (item.IsConfirmFinger)
                    return { ...item, img_finger: finger_authen_trans }
                return { ...item, img_finger: finger_register_green }
            } else if (item.BioActionType === UPDATE) {
                if (item.IsConfirmFinger)
                    return { ...item, img_finger: finger_authen_blue }
                return { ...item, img_finger: finger_update_blue }
            } else if (item.BioActionType === DELETE) {
                if (item.IsConfirmFinger)
                    return { ...item, img_finger: finger_authen_red }
                return { ...item, img_finger: finger_delete_red }
            } else {
                if (item.IsConfirmFinger)
                    /*return {...item, img_finger:finger_authen_white}
                  return {...item, img_finger: finger_white}*/
                    return { ...item, img_finger: finger_authen_trans }
                return { ...item, img_finger: finger_register_green }
            }
            // if (item.IsConfirmFinger)
            //     return { ...item, img_finger: finger_authen_white }
            //   return { ...item, img_finger: finger_white }
        })


        let sameBioCurrentGetData = isUpdate && sameArrDiffGetData.map(item => {
            // if(item.BioActionType === REGISTER){
            if (item.IsConfirmFinger)
                return { ...item, img_finger: finger_authen_white }
            return { ...item, img_finger: finger_white }
            // }
            // return item
        })

        bioTypeFinger = bioTypeFinger.map(item => {
            if (item.BioActionType === REGISTER) {
                return item.IsConfirmFinger ? { ...item, img_finger: finger_authen_trans } : { ...item, img_finger: finger_register_green }
            } else if (item.BioActionType === UPDATE) {
                return item.IsConfirmFinger ? { ...item, img_finger: finger_authen_blue } : { ...item, img_finger: finger_update_blue }
            } else if (item.BioActionType === DELETE) {
                return item.IsConfirmFinger ? { ...item, img_finger: finger_authen_red } : { ...item, img_finger: finger_delete_red }
            } else {
                return item.IsConfirmFinger ? { ...item, img_finger: finger_authen_trans } : { ...item, img_finger: finger_register_green }
            }
        })

        let dataCheckBioCurrent = isUpdate && diffCurrentArrGetData.concat(sameBioCurrentGetData)
        // console.log(dataCheckBioCurrent)
        let bioTypeFaceUpdate = isUpdate && dataCheckBioCurrent.find(item => item.BioType === FACE)
        let bioTypeFaceRegister = BioDatas.find(item => item.BioType === FACE)
        let faceBio = isUpdate && BioDatas ? bioTypeFaceUpdate : bioTypeFaceRegister


        // let biometrictFinger = biometrictFingerData.map(item => item)
        isUpdate && dataCheckBioCurrent.forEach((item) => {
            // biometrictFinger.forEach(typeFingers => {
            biometrictFingerData.forEach(typeFingers => {
                if (typeFingers.typeFinger.indexOf(item.BioType) > -1) {
                    resultArrFinger.push({ ...typeFingers, img_finger: item.img_finger })
                }
            })
        })

        let resultArr = []
        bioTypeFinger.forEach((item) => {
            // console.log('item', item)
            // biometrictFinger.forEach(typeFingers => {
            biometrictFingerData.forEach(typeFingers => {
                if (typeFingers.typeFinger.indexOf(item.BioType) > -1) {
                    resultArr.push({ ...typeFingers, img_finger: item.img_finger })
                }
            })
        })

        // let bioTypeFace = dataCheckBioCurrent.find(item => item.BioType === FACE)


        let fingerBio = isUpdate ? resultArrFinger : resultArr
        let dataInform = informBiometric.filter(item => item.BioType === FACE)

        let duplicateFace, duplicateFaceFilter
        dataInform.forEach(item => {
            duplicateFace = item.DuplicateFaces
            duplicateFaceFilter = duplicateFace && duplicateFace.filter(item => item.CustomerNumber !== CustomerInfos.CustomerNumber)
        })

        const { dataUser } = this.props
        let elmReason, textReason, isActive = 0

        let findFinger = BioInfosType === REGISTER && dataUser.BioDatas.find(item => item.BioType !== FACE)

        if (!!dataUser.ReasonNR) {
            elmReason = 'Lý do không đăng ký STH khuôn mặt '
            textReason = dataUser.ReasonNR
            isActive = 1
        }
        if (!!dataUser.ReasonNRFinger) {
            elmReason = 'Lý do không đăng ký STH vân tay'
            textReason = dataUser.ReasonNRFinger
            isActive = 1
        }
        textReason = textReason && textReason.split('<br>')
        // console.log(this.props.dataUser.CustomerInfos.CustomerNumber)
        return (
            <Bound imageLoadError={this.state.imageLoadError}>
                <div className='biometric-face'>
                    <div className='face-id'>
                        {
                            // faceBio !== undefined && 
                            faceBio?.BioType === FACE &&
                            <img
                                src={apiGetBioFile(faceBio.EnrollData)}
                                alt="face"
                                className='image'
                                onError={this.addDefaultSrc}
                            />
                        }
                        {
                            !this.state.imageLoadError && <p className='text-imageload'>Dữ liệu không tồn tại</p>
                        }
                        {
                            this.state.imageLoadError &&
                            <React.Fragment>
                                <img src={ic_zoom} alt='ic_zoom' className='ic_zoom' onClick={() => faceBio ? this.showFaceZoomImage(faceBio) : null} />
                                <div className="middle" onClick={() => faceBio && this.state.imageLoadError ? this.showFaceImage(faceBio) : null}>
                                    <div className="text" onClick={() => faceBio ? this.showFaceImage(faceBio) : null}>Xem chi tiết</div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <p>(*) Hình ảnh dùng để nhận diện khách hàng tại thiết bị Kiosk</p>
                    {
                        duplicateFaceFilter?.length > 0 &&
                        <div className='error'>
                            <img src={ic_warning} alt='ic_warning' />
                            <span onClick={() => this.showMatchFace(faceBio)}>Thông tin KH trùng khuôn mặt</span>
                        </div>
                    }
                    {/* -------- ERROR */}
                    {
                        isUpdate && !!faceBio &&
                        <div className='error update' onClick={() => this.showFaceUpdateImage(faceBio)}>
                            {
                                !!bioTypeFaceRegister &&
                                <React.Fragment>
                                    {/* <img src={bioTypeFaceRegister.BioActionType === UPDATE ? ic_notify_face_update : ic_biometric_delete} alt='ic_notify_biometric' />
                      <span style = {{color: bioTypeFaceRegister !== undefined && bioTypeFaceRegister.BioActionType === UPDATE ? '#1477EC' : '#F31919'}}>{faceBio.BioActionType === UPDATE ?  'Cập nhật khuôn mặt' : 'Xóa khuôn mặt'}</span> */}
                                    {
                                        icon && <img src={icon} alt='ic_notify_biometric' />
                                    }
                                    <span style={{ color: clIsConfirmFinger }}>{elmIsConfirmFinger}</span>
                                </React.Fragment>
                            }
                        </div>
                    }
                    <div className='update-last'>
                        <p>Cập nhật lần cuối:</p>
                        <span>{formatDateTimeToSpecificFormat(this.props.dataUser.CreateAt, formatDateTime.HHmmDDMMYY)}</span>
                    </div>
                </div>
                <div className='biometric-finger'>
                    <div className='show-finger'>
                        <img src={bg_finger} alt='bg_finger' className='bg_finger' />
                        <div className='finger'>
                            <p>Bàn tay trái</p>
                            <p>Bàn tay phải</p>
                        </div>
                        <div className='handle-finger'>
                            <div className='fingerprint'>
                                {
                                    fingerBio.map((item, i) => {
                                        return (
                                            <FingerPrint
                                                key={i}
                                                img_finger={item.img_finger}
                                                alt='finger'
                                                showFingerInfoCustomer={this.showFingerInfoCustomer}
                                                {...item}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='status'>
                            {
                                dataFunction.map((functon, i) => {
                                    return (
                                        <div className='update' key={i}>
                                            <div className='circle'
                                                style={
                                                    (() => {
                                                        if (functon.type === 0) {
                                                            return { backgroundColor: '#D6D6D6' }
                                                        } else if (functon.type === 1) {
                                                            return { backgroundColor: '#1477ec' }
                                                        } else if (functon.type === 2) {
                                                            return { backgroundColor: '#f31919' }
                                                        } else if (functon.type === 3) {
                                                            return { backgroundColor: '#0fb11f' }
                                                        }
                                                    })()
                                                }
                                            ></div>
                                            <p>{functon.content}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {
                        this.props.roles.includes(INBIOCOLLECTOR) && !!RejectRegisterNote /* && BioInfosType === REGISTER */ &&
                        <div className='notify' onClick={this.onShowPopupNoteLv1}>
                            <img src={ic_not_number} alt='ic_not_number' />
                            <h4>Lý do không đăng ký đủ 10 dấu vân tay</h4>
                        </div>
                    }
                    {
                        !findFinger &&
                        <div className='notify' onClick={this.onShowPopupNoteLv1}>
                            {textReason && <img src={ic_not_number} alt='ic_not_number' />}
                            <h4>{elmReason}</h4>
                        </div>
                    }
                </div>
                {
                    this.state.isShowPopupLv1 &&
                    <PopupNote
                        rejectRegisterNote={RejectRegisterNote ? this.props.dataUser.RejectRegisterNote : textReason}
                        title={isActive === 1 ? 'Lý do không đăng ký' : 'Nội dung ghi chú'}
                        placeholder='Nhập nội dung ghi chú'
                        onClosePopupLv1={this.onClosePopupLv1}
                        type='noteMatchFace'
                        isActive={isActive}
                    />
                }
            </Bound>
        )
    }
}
