import styled from 'styled-components'

// image
import line from '../../images/Pattern_VTB-03.png'

const LoginStyles = styled.div`
    background-color: #005993;
    width: 100%;
    height: 100vh;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    .loginContainer {
        .rowHeader {
            padding: 10px 0;
            width: 100%;
            height: 60px;
            background-color: #7ed3f7;
            display: flex;
            align-items: center;
            position: relative;
            justify-content: space-between;
            .logoVietinBank {
                margin: 0 25px 0 0;
            }
            .settings {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: center;
                margin: 0 0 0 60px;
                cursor: pointer;
                .title {
                    margin: 0 10px 0 0;
                    font-size: 14px;
                    font-weight: bold;
                    color: #222222;
                }

                img {
                    margin-right: 8px;
                }
            }
        }
        .rowMain {
            width: 100%;
            height: 100%;
            .col_login {
                border-radius: 10px;
                box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.06);
                padding: 32px 40px 24px 40px;
                width: 320px;
                box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
                background-color: #ffffff;
                position: absolute;
                top: 150px;
                left: 50%;
                transform: translate(-50%, 0);
                .title {
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1.08;
                    color: #005993;
                    display: flex;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }
                .content {
                    margin: 28px 0 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.13;
                    color: #222222;
                }
                .form_login {
                    margin: 25px 0 42px 0;
                    label {
                        font-size: 11px;
                        font-weight: bold;
                        line-height: 1.26;
                        color: #005993;
                    }
                    input {
                        margin: 5px 0 20px 0;
                        width: 300px;
                        height: 24px;
                        border-radius: 4px;
                        background-color: #fafafa;
                        border: none;
                        outline: none;
                        padding: 10px;
                        &::placeholder {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 1.68;
                            color: #222222;
                            opacity: 0.25;
                        }
                    }
                    .notify {
                        height: 46px;
                        margin-top: 20px;
                        font-family: 'SVN-Gilroy';
                        font-size: 14px;
                        font-weight: 500;
                        font-style: normal;
                        line-height: 1.25;
                        color: var(--vermillion);
                        text-align: center;
                        .loading {
                            color: #000000;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            line-height: 1.8;
                        }
                    }
                    .blockBtn {
                        margin: 20px 0 0 0;
                        justify-content: center;
                        display: flex;
                        .btn_login {
                            width: 120px;
                            height: 36px;
                            border-radius: 18px;
                            background-color: #7ed3f7;
                            border: none;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 14px;
                            font-weight: bold;
                            color: #005993;
                            outline: none;
                        }
                    }
                    .version {
                        margin: 40px 0 0 0;
                        display: flex;
                        justify-content: center;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1.42;
                        color: #222222;
                    }
                }
            }
        }
    }
    .rowLinear {
        width: 100%;
        height: 12px;
        background-image: url(${line});
        background-position: center;
        background-size: cover;
        &.lineTwo {
            position: absolute;
            bottom: 0;
        }
    }
`

export { LoginStyles }
