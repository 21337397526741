import styled from 'styled-components';

const Bound = styled.div`
  position: absolute;
  top: 50%;
  left:50%;
  transform:translate(-50%,-50%);
  width: 705px;
  ${'' /* height: 516px; */}
  height:fit-content;
  border-radius: 10px;
  background-color: #fafafa;
  font-family: 'SVN-Gilroy';
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  .header {
    padding: 16px 10px 0 20px;
    width: calc(100% - 30px);
    height: 68px;
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #e3e3e3c4;
    .block_header_title {
      display: flex;
      justify-content:space-between;
      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.08;
        color: #000000;
      }
      img {
        cursor: pointer;
      }
    }
  }
  .main_content{
    /* height: 352px; */
  }
  .footer_check {
    /* margin: 20px 0 0 0; */
    width: 705px;
    height: 80px;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    .block_action{
      width:17%;
      display: flex;
      margin: 0 0 0 30px;
      /* justify-content:space-between; */
      h4{
        text-decoration: underline;
      }
    }
     .block_btn {
      margin: 0 20px 0 0;
      width: 170px;
      display: flex;
      align-items: center;
      justify-content:space-between;
    }
    .block_load {
      margin: 0 0 0 15px;
    }
  }
`

const TabLayout = styled.div`
  display: flex;
  justify-content:space-between;
  margin: 10px 0 0 0;
  .title_content {
    display: flex;
    .tab_title_single {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #005993;
      margin: 0 0 7px 20px;
    }
    .tab_title {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.25;
      color: #c8c8c8;
      margin: 0 13px 0 0;
      cursor: pointer;
      padding: 0px 8px 15px 8px;
    }
  }
  .title_info {
    display: flex;
    .cif {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: #005993;
      margin: 0 25px 0 0;
      .text_cif{
        color: #222222;
      }
    }
    .status {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: #005993;
      .text_status {
        color:#979797;
        margin: 0 15px 0 0;
      }
    }
  }
`

export {
    Bound,
    TabLayout
}