import React, { Component } from 'react'
import styled from "styled-components";

import LoadingControl from '../../../Control/LoadingControl'


const TextDiv = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-family: "SVN-Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 110%;
    color: #222222;
`;

export default class LoadingGetImage extends Component {
    render() {
        return (
            <>
                <LoadingControl size="68px" loadingPage={false} />
                <TextDiv>Đang lấy hình ảnh...</TextDiv>
            </>
        )
    }
}
