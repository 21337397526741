import React, { useRef, useState, useEffect } from 'react'

// styles
import { WrapInputGTTT } from './index.styles'

// images
import ic_search_gray from '../../../../../../images/ic_search_gray.svg'

// helper
import useDebounce from '../../../../../../helper/hooks/useDebounce';

const InputGTTT = ({ handleChange, isReset }) => {

    const firstCall = useRef(true)
    const [value, setValue] = useState('');
    const valueDebounce = useDebounce(value, 1000)

    useEffect(() => {
        setValue('')
    }, [isReset]);

    useEffect(() => {
        if (firstCall.current) {
            firstCall.current = false
            return;
        }
        handleChange(valueDebounce)
    }, [valueDebounce]);

    return (
        <WrapInputGTTT>
            <div className="title-input">GTTT</div>
            <div className="wrap-input">
                <img src={ic_search_gray} alt="search" />
                <input
                    type="text"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </div>
        </WrapInputGTTT>
    )
}

export default InputGTTT
