import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
///// images
import fingerAuthenRed from '../../images/finger_authen_red.svg'
import fingerRed from '../../images/finger_delete_red.svg'
// import finger_green from '../../images/finger_green.svg';
import finger_confirm from '../../images/finger_Success.svg'
import fingerClosedUpdate from '../../images/finger_closed_update.svg'
import fingerHangUpdate from '../../images/finger_hang_update.svg'

import { AUTH_TRANS, VALIDATE_DATA, ISHANG } from '../../actions/typeRequestBio'

const Bound = styled.div`
    position: relative;
    .finger-status-text {
        position: absolute;
        left: ${(props) => (props.left ? props.left + 'px' : 'unset')};
        bottom: ${(props) => (props.bottom ? props.bottom + 'px' : 'unset')};
        right: ${(props) => (props.right ? props.right + 'px' : 'unset')};
    }

    img {
        cursor: pointer;
        position: absolute;
        width: 39px;
        height: 39px;
    }
`

class FingerPrint extends Component {
    state = {
        image_finger_status: this.props.img_finger,
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.biometricReducer !== this.props.biometricReducer) {
            this.handleShowColorFinger()
        }
    }

    componentDidMount() {
        this.handleShowColorFinger()
    }

    handleShowColorFinger = () => {
        let { dataGetTicket, typeFinger, biometricReducer } = this.props
        let checkWaitting = /waitingConfirm/g.test(
            JSON.stringify(biometricReducer)
        )
        const isHaveTypeBio = new RegExp(typeFinger).test(
            JSON.stringify(biometricReducer[dataGetTicket.TicketID])
        )

        if (dataGetTicket) {
            if (biometricReducer[dataGetTicket.TicketID]) {
                let fingerCheck =
                    biometricReducer[dataGetTicket.TicketID][typeFinger]
                const isUpdateFinger = /validatedData/.test(
                    JSON.stringify(fingerCheck)
                )
                if (fingerCheck) {
                    switch (true) {
                    case Object.keys(fingerCheck).includes(AUTH_TRANS) &&
                            fingerCheck[AUTH_TRANS]:
                        if (fingerCheck.bioActionType === 'REMOVE') {
                            this.setState({
                                image_finger_status: fingerAuthenRed,
                                fingerStatus: 'Xóa',
                            })
                            break
                        }
                        if (
                            isUpdateFinger &&
                                Object.keys(fingerCheck).includes(ISHANG) &&
                                fingerCheck[ISHANG]
                        ) {
                            this.setState({
                                image_finger_status: fingerHangUpdate,
                                fingerStatus: 'Treo',
                            })
                            break
                        }
                        this.setState({
                            image_finger_status: finger_confirm,
                            fingerStatus: 'Hoạt động',
                        })
                        break
                    case Object.keys(fingerCheck).includes(AUTH_TRANS) &&
                            !fingerCheck[AUTH_TRANS]:
                        if (fingerCheck.bioActionType === 'REMOVE') {
                            this.setState({
                                image_finger_status: fingerRed,
                                fingerStatus: 'Xóa',
                            })
                            break
                        }
                        if (isUpdateFinger) {
                            this.setState({
                                image_finger_status: fingerClosedUpdate,
                                fingerStatus: 'Đóng',
                            })
                            break
                        }

                        this.setState({
                            image_finger_status: fingerClosedUpdate,
                            fingerStatus: 'Đóng',
                        })
                        break

                    case isUpdateFinger &&
                            Object.keys(fingerCheck[VALIDATE_DATA]).includes(
                                AUTH_TRANS
                            ) &&
                            fingerCheck[VALIDATE_DATA][AUTH_TRANS]:
                        if (fingerCheck[VALIDATE_DATA][ISHANG]) {
                            this.setState({
                                image_finger_status: fingerHangUpdate,
                                fingerStatus: 'Treo',
                            })
                            break
                        }
                        this.setState({
                            image_finger_status: finger_confirm,
                            fingerStatus: 'Hoạt động',
                        })
                        break
                    case isUpdateFinger &&
                            Object.keys(fingerCheck[VALIDATE_DATA]).includes(
                                AUTH_TRANS
                            ) &&
                            !fingerCheck[VALIDATE_DATA][AUTH_TRANS]:
                        this.setState({
                            image_finger_status: fingerClosedUpdate,
                            fingerStatus: 'Đóng',
                        })
                        break
                    default:
                        this.setState({
                            image_finger_status: this.props.img_finger,
                        })
                        break
                    }
                    return
                }
            }
            if (!isHaveTypeBio && !checkWaitting)
                this.setState({
                    image_finger_status: this.props.img_finger,
                })
            return
        }
    }

    render() {
        return (
            <Bound
                className="finger-control"
                left={this.props.left}
                bottom={this.props.bottom}
                right={this.props.right}
            >
                {this.state.image_finger_status && (
                    <img
                        style={{
                            bottom: this.props.bottom + 'px',
                            left: this.props.left + 'px',
                            right: this.props.right + 'px',
                        }}
                        onClick={() => this.props.onShowRegisterFinger()}
                        src={this.state.image_finger_status}
                        alt="fingers"
                    />
                )}
            </Bound>
        )
    }
}

const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(FingerPrint)
