import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapFingerQuery } from './index.styles'

// image
import icFingerGray from '../../../../../images/ic_finger_gray.svg'

// api
import { apiRequestFindUserByBio } from '../../../../../actions/counterAction'

// component
import LoadingControl from '../../../../Control/LoadingControl'

// action
import { openPopup } from '../../../../../actions/popupBioAction'
import { clearDataUsersFromTablet, setLoadingGetUserByFinger } from '../../../../../actions/queriesAction'

// constant
import { popupBioTypes } from '../../../../../data/PopupTypes'

const STEP_INITIAL = "STEP_INITIAL"
const STEP_LOADING = "STEP_LOADING"
const ERR_NOT_FOUND_FINGER = 'Vân tay đang tìm kiếm không tồn tại trong hệ thống'

const FingerQuery = ({ disabledQueryByFinger, loadingQueryByFace, setLoadingQueryByFinger, setError }) => {
    const dispatch = useDispatch()

    const { loadingGetByFinger, resGetUserByFingerImg } = useSelector(state => ({
        loadingGetByFinger: state.queriesReducer.queryByImg.loadingGetByFinger,
        resGetUserByFingerImg: state.queriesReducer.queryByImg.resGetUserByFingerImg
    }))

    const [step, setStep] = useState(STEP_INITIAL);
    const [dataUsers, setDataUsers] = useState(null);


    const _handleShootPicture = async () => {
        setError('')
        dispatch(clearDataUsersFromTablet())

        const dataRequest = JSON.stringify({
            BioType: 'FINGER'
        })

        try {
            const res = await Axios.post(apiRequestFindUserByBio, dataRequest)
            const { data } = res;
            const isSuccess = data.Code === 0;
            if (isSuccess) {
                // setStep(STEP_LOADING)
                // setLoadingQueryByFinger(true)
                dispatch(setLoadingGetUserByFinger(true))
            }
            else {
                console.log(data)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (!resGetUserByFingerImg) return;
        const { Code, Customers } = resGetUserByFingerImg
        const isSuccess = Code === 0 && Customers && Customers.length
        if (isSuccess) {
            setDataUsers(Customers)
        }
        else {
            console.log(Code)
            // not found user
            setError(ERR_NOT_FOUND_FINGER)
        }
        setStep(STEP_INITIAL)
        // setLoadingQueryByFinger(false)
    }, [JSON.stringify(resGetUserByFingerImg)]);

    useEffect(() => {
        if (!dataUsers) return
        dispatch(openPopup(popupBioTypes.QueryFingerResults, 2, {
            dataUsers
        }))
    }, [dataUsers]);

    useEffect(() => {
        if (loadingGetByFinger) {
            setStep(STEP_LOADING)
            setLoadingQueryByFinger(true)
            return;
        }
        setStep(STEP_INITIAL)
        setLoadingQueryByFinger(false)
    }, [loadingGetByFinger]);

    return (
        <WrapFingerQuery>
            {
                step === STEP_INITIAL &&
                <>
                    <img className="ic-finger-default" src={icFingerGray} alt="finger" />
                    <div className="desc">
                        <div>Quét hình ảnh vân tay</div>
                        <div>từ thiêt bị</div>
                    </div>
                    <button onClick={_handleShootPicture} disabled={loadingQueryByFace || disabledQueryByFinger}>Bắt đầu</button>
                </>
            }
            {
                step === STEP_LOADING &&
                <div className="wrap-loading">
                    <LoadingControl size="32px" loadingPage={false} />
                    <div className="desc-loading">Vui lòng chờ...</div>
                </div>
            }
        </WrapFingerQuery>
    )
}

export default FingerQuery
