import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

//component
import TickyContainer from './TickyTable/TickyContainer'
import Header from './Header'
import InformTransactionContainerV2 from './Task/InformTransactionContainerV2'
import InformTransactionContainerForCollector from './Task/InformTransactionContainerForCollector'
import PopupALertLogout from '../../Popup/PopupAlertLogut'
import LoadingControl from '../../Control/LoadingControl'
import ListWaitingCollector from './ListWaitingCollector'

//data
import { mainTicket, waittingTicket } from '../../../data/mockData'

import {
    getListTypeCounterServices,
    getCurrentTickets,
} from '../../../actions/counterAction'
import { openPopup } from '../../../actions/popupBioAction'
import * as counterStates from '../../../data/counterStates'
import { TELLER } from '../../../data/userRole'
import { clearError } from '../../../actions/commonAction'

const BoundStyle = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fafafa;
    overflow: auto;
    scrollbar-width: none;
    @media only screen and (min-width: 1301px) {
        padding: 0 68px 20px 68px;
    }
    @media only screen and (min-width: 1251px) and (max-width: 1300px) {
        padding: 0 75px 20px 75px;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1250px) {
        padding: 0 60px 20px 60px;
    }
    @media only screen and (min-width: 1151px) and (max-width: 1200px) {
        padding: 0 45px 20px 45px;
    }
    @media only screen and (min-width: 1101px) and (max-width: 1150px) {
        padding: 0 30px 20px 30px;
    }
    @media only screen and (max-width: 1100px) {
        padding: 0 20px 20px 20px;
    }
`
class MainscreenContainer extends Component {
    mainTicket = mainTicket
    state = {
        mainTask: [],
        listTypeServices: [],
        getTicket: {},

        counterState: this.props.counterReducer.counterState,
    }
    toggleCounterStatus = async () => {
        let { counterState } = this.props.counterReducer
        let { counterReducer } = this.props

        if (counterState === counterStates.OPEN_COUNTER) {
            if (this.state.mainTask < 1) {
                await this.setState({
                    mainTask: [],
                })
            }
            if (this.state.mainTask && this.state.mainTask.length > 0) {
                return
            }
        }

        if (counterState === counterStates.STOP_COUNTER) {
            await this.props.getCurrentTickets(
                counterReducer.dataConnectDesktop.CounterID,
                counterReducer.dataConnectDesktop.OfficeID
            )
            if (counterReducer.listCurrentTicket.length > 0) {
                await this.setState({
                    mainTask: this.props.counterReducer.listCurrentTicket,
                })
            }
        }
    }

    UNSAFE_componentWillReceiveProps(n) {
        if (n) {
            let counterReducer = n.counterReducer
            this.setState({
                listTypeServices: counterReducer.listTypeCounterServices,
                getTicket: counterReducer.dataGetTicket.TicketData,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { counterReducer } = this.props
        if (
            prevProps.counterReducer.listCurrentTicket !==
            this.props.counterReducer.listCurrentTicket &&
            counterReducer.counterState !== counterStates.STOP_COUNTER
        ) {
            this.setState({
                mainTask: this.props.counterReducer.listCurrentTicket,
            })
        }
    }

    render() {
        const { dataLogin } = this.props.counterReducer
        return (
            <BoundStyle>
                <Header
                    toggleCounterStatus={this.toggleCounterStatus}
                    data={this.state.mainTask}
                />
                {dataLogin.Roles.includes(TELLER) ? (
                    <React.Fragment>
                        <InformTransactionContainerV2
                            counterState={
                                this.props.counterReducer.counterState
                            }
                            data={this.state.mainTask}
                            listTypeServices={this.state.listTypeServices}
                            onNextTask={this.onNextTask}
                        />
                        {(this.props.counterReducer.counterState ===
                            counterStates.OPEN_COUNTER ||
                            this.state.mainTask.length > 0) && (
                            <TickyContainer
                                dataWait={mainTicket}
                                dataMiss={waittingTicket}
                                showPassTicketPopup={() =>
                                    this.props.showPassTicketPopup()
                                }
                                counterReducer={this.props.counterReducer}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <>
                        <InformTransactionContainerForCollector />
                        <ListWaitingCollector />
                    </>
                )}
                {this.props.isLogout && <PopupALertLogout />}
                {this.props.isLoading && <LoadingControl size="100px" />}
            </BoundStyle>
        )
    }
    componentDidMount() {
        let { counterReducer } = this.props
        if (counterReducer.dataLogin.Roles.includes(TELLER))
            this.props.getListTypeCounterServices(counterReducer.dataLogin.UID)
    }
}

const mapStateFromProps = (state) => ({
    counterReducer: state.counterReducer,
    isLogout: state.commonReducer.isLogout,
    isLoading: state.commonReducer.isLoading,
})

const mapDispatchFromProps = (dispatch) => ({
    getListTypeCounterServices: (userId) =>
        dispatch(getListTypeCounterServices(userId)),
    getCurrentTickets: (CounterID, OfficeID) =>
        dispatch(getCurrentTickets(CounterID, OfficeID)),
    openPopup: (type, levelPopup, data) =>
        dispatch(openPopup(type, levelPopup, data)),
    clearError: (typeError) => dispatch(clearError(typeError)),
})

export default connect(mapStateFromProps, mapDispatchFromProps, null, {
    forwardRef: true,
})(MainscreenContainer)
