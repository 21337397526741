//receive new ticket
export const NEWTICKET = "NEWTICKET";

// one counter was received ticket
export const GETTICKET = "GETTICKET";

// one counter move ticket to current counter
export const CT_MOVE_TICKET = "CT_MOVE_TICKET";

// receive register data biometric
export const CT_BIO_DATA = "CT_BIO_DATA";

// receive confirm data biometric
export const CT_CONFIRM_BIO = "CT_CONFIRM_BIO";

// receive data verify customer go to counter
export const CT_VF = "CT_VF";

// check status biometric of ticket
export const CT_PENBIO = "CT_PENBIO";

// response finish waiting evalueate quality service
export const CT_COMPLETE_RS = "CT_COMPLETE_RS";

// notify supervisor seen customer auth
export const CT_VF_SEEN = "CT_VF_SEEN";

// reponse error code in tablet
export const TB_STATUS_CODE = "TB_STATUS_CODE";

// notify data was change from otherwhere
export const CT_BIO_CHANGED = "CT_BIO_CHANGED";

export const PONG = "PONG";

// notify have misscall ticket incomming
export const CT_MISS_TICKET = "CT_MISS_TICKET";

export const CT_INFOS_CHANGE = "CT_INFOS_CHANGE";

// result query by face image
export const CT_FIND_BIO = 'CT_FIND_BIO';

// result query by finger image
export const CT_FIND_FINGER = 'CT_FIND_FINGER'

// notify have data move to pending in inBioCollector
export const CT_BIOS_CHANGE = 'CT_BIOS_CHANGE';
// notify have data in pending queue in inBioCollector was approved
export const COMPLETED_REVIEW = 'COMPLETED_REVIEW';


