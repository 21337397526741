import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
// import moment from "moment";

// styles
import { Bound } from './TickyGridItemV2.styles'

// image
import moveto from '../../../../images/move-to.svg'

// component
import CountDownTimeControl from '../../../Control/CountDownTimeControl'
import { countDuplicated } from '../../../../helper/tool'

// action
import { openPopup } from '../../../../actions/popupBioAction'

class TickyGridItemV2 extends React.Component {
    state = {
        isChecked: this.props.isChecked,
    }
    onChange = (event) => {
        var target = event.target
        var name = target.name
        var value = target.checked
        this.setState({
            [name]: value,
        })
    }

    onCallTicket = (ticketID, status) => {
        this.props.onCallTicket(ticketID, status)
    }

    handleShowTooltipTransactionLst = (e, dataTrans) => {
        e.preventDefault()
        const getRectTooltipTrans = this.tooltipTrans.getBoundingClientRect()
        this.tooltipTrans.style.top =
            e.clientY - getRectTooltipTrans.height - 15 + 'px'
        this.tooltipTrans.style.left =
            e.clientX - getRectTooltipTrans.width / 2 + 'px'
        this.tooltipTrans.style.visibility = 'visible'
        const TransLst = ({ props }) => (
            <React.Fragment>
                {dataTrans.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </React.Fragment>
        )
        this.tooltipTrans.innerHTML = ReactDOMServer.renderToString(
            <TransLst />
        )
    }

    handleHideTooltipTransactionLst = (e) => {
        e.preventDefault()
        this.tooltipTrans.style.visibility = 'hidden'
    }

    handleMoveTicket = (e, ticketId) => {
        e.preventDefault()
        this.props.openPopup(10, 1, { TicketID: ticketId })
    }

    renderTable() {
        const { counterReducer, dataWaitingTicket } = this.props
        const someTicketInprocess = this.props.counterReducer.listCurrentTicket.some(
            (ticket) => ticket.Status !== 'PAUSED'
        )
        return dataWaitingTicket.map((e, index) => {
            let dataTransaction = []
            let nameTypeServices = ''

            const countService = countDuplicated(e.ListService)
            
            countService &&
                countService.forEach((item) => {
                    counterReducer.listTypeCounterServices.forEach((key) => {
                        if (key.ID === item.value) {
                            nameTypeServices =
                                `${key.Name}` +
                                (item.count > 1 ? ` (${item.count})` : '')
                            dataTransaction.push(nameTypeServices)
                        }
                    })
                })
            return (
                <React.Fragment key={index}>
                    <div className="item ticky-block">
                        <div
                            className="ticky-style"
                            style={{
                                color: e.moveTo ? 'rgb(215, 18, 73)' : 'unset',
                            }}
                        >
                            {e.Number}
                        </div>
                        {e.moveTo && (
                            <div className="icon-moveTo">
                                <img src={moveto} alt="moveto" />
                            </div>
                        )}
                    </div>
                    <div
                        className="item customer-name"
                        style={{
                            color: e.moveTo ? 'rgb(215, 18, 73)' : 'unset',
                        }}
                    >
                        <span
                            title={
                                e.CustomerInfos
                                    ? e.CustomerInfos.FullNameVi ||
                                      e.CustomerInfos.Name
                                    : 'KH vãng lai'
                            }
                        >
                            {e.CustomerInfos
                                ? e.CustomerInfos.FullNameVi ||
                                  e.CustomerInfos.Name
                                : 'KH vãng lai'}
                        </span>
                    </div>
                    <div
                        className="item customer-type"
                        style={{
                            color: e.moveTo ? 'rgb(215, 18, 73)' : 'unset',
                        }}
                    >
                        <span
                            title={
                                e.CustomerInfos
                                    ? e.CustomerInfos.SegmentDesc
                                    : e.CustomerInfos === null && null
                            }
                        >
                            {e.CustomerInfos
                                ? e.CustomerInfos.SegmentDesc
                                : e.CustomerInfos === null && null}
                        </span>
                    </div>
                    <div
                        className="item transaction"
                        ref={(ref) => (this.transaction = ref)}
                    >
                        <div
                            className="text"
                            style={{
                                color: e.moveTo ? 'rgb(215, 18, 73)' : 'unset',
                            }}
                            onMouseEnter={(e) =>
                                this.handleShowTooltipTransactionLst(
                                    e,
                                    dataTransaction
                                )
                            }
                            onMouseMove={(e) =>
                                this.handleShowTooltipTransactionLst(
                                    e,
                                    dataTransaction
                                )
                            }
                            onMouseLeave={this.handleHideTooltipTransactionLst}
                        >
                            {dataTransaction.join()}
                        </div>
                    </div>
                    <div className="item time">
                        <CountDownTimeControl
                            data={e.CreateAt}
                            ticketNumber={e.Number}
                        />
                    </div>
                    <div className="item button-icon">
                        <div
                            className="tooltip"
                            style={{
                                width: '20px',
                                height: '20px',
                                // gridColumnStart: 2,
                                display: 'flex',
                                alignItems: 'center',
                                opacity: someTicketInprocess ? 0.5 : 'unset',
                                cursor: someTicketInprocess
                                    ? 'default'
                                    : 'pointer',
                                marginRight: '28px',
                            }}
                            onClick={() =>
                                someTicketInprocess
                                    ? {}
                                    : this.onCallTicket(e.TicketID, e.Status)
                            }
                        >
                            <span className="tooltiptext">
                                Gọi KH vào phiên
                            </span>
                            <div className="move"></div>
                        </div>
                        <div
                            className="tooltip"
                            style={{
                                paddingRight: '15px',
                            }}
                        >
                            <span className="tooltiptext">Chuyển quầy</span>
                            <div
                                className="try"
                                onClick={(evt) =>
                                    this.handleMoveTicket(evt, e.TicketID)
                                }
                            ></div>
                        </div>
                    </div>
                </React.Fragment>
            )
        })
    }
    render() {
        return (
            <Bound
                showCheck={this.props.showCheck}
                names={this.props.name}
                width={this.props.width}
                ref={(ref) => (this.waitingTicketTable = ref)}
                type={this.props.type}
            >
                <div className="head-table">
                    <div className="font-head name-ticky">
                        <div className="ticky">{this.props.name}</div>
                    </div>
                    <div className="font-head customer-name">
                        TÊN KHÁCH HÀNG
                    </div>
                    <div className="font-head customer-type">
                        HẠNG KHÁCH HÀNG
                    </div>
                    <div className="font-head transaction special">
                        GIAO DỊCH
                        
                    </div>
                    <div className="font-head time">THỜI GIAN CHỜ</div>
                    <div className="font-head button-tranfer"></div>
                </div>
                <div
                    className="scroll"
                    style={{ height: this.props.height + 'px' }}
                    ref={(ref) => (this.scrollTable = ref)}
                >
                    <div className="body-table">{this.renderTable()}</div>
                </div>
                <div
                    className="transaction-tooltip"
                    ref={(ref) => (this.tooltipTrans = ref)}
                ></div>
            </Bound>
        )
    }
}

const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
})

const mapDispatchToProps = (dispatch) => ({
    openPopup: (typePopup, levelPopup, data) =>
        dispatch(openPopup(typePopup, levelPopup, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TickyGridItemV2)
