import React, { Component } from 'react'
import styled from 'styled-components'

export const Bound = styled.button`
    width: ${(props) => props.width || '108px'};
    height: 36px;
    border-radius: 18px;
    background-color: ${(props) => props.bg_color || '#005993'};
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.isDisabled ? 0.5 : 'unset')};
    outline: none;
    border: none;
    cursor: pointer;
    .titleConfirm {
        font-family: 'SVN-Gilroy';
        font-size: ${(props) => props.fontSizeBtn || '14px'};
        font-weight: bold;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export default class ButtonConfirmControl extends Component {
    render() {
        return (
            <Bound
                width={this.props.width}
                bg_color={this.props.bg_color}
                titleConfirm={this.props.titleConfirm}
                fontSizeBtn={this.props.fontSize}
                isDisabled={this.props.isDisabled}
                onClick={(e) => {
                    if (this.props.isDisabled) return
                    e.preventDefault()
                    this.props.onSubmit()
                }}
            >
                <p className="titleConfirm">{this.props.titleConfirm}</p>
            </Bound>
        )
    }
}
