import styled from 'styled-components'

const WrapPopupShowFace = styled.div`
    font-family: 'SVN-Gilroy';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 8px 8px -5px rgba(0,0,0,0.29);

    .wrap-header {
        background: #222222;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        line-height: 128%;
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        padding: 11px 10px;
        border-radius: 5px 5px 0 0;

        img {
            &:hover {
                cursor: pointer;
            }
        }

        :hover {
            cursor: move;
        }
    }

    .wrap-content {
        position: relative;
        width: 300px;

        img {
            width: 100%;
        }

        .channel {
            position: absolute;
            top: 0;
            padding: 10px;
            width: 100%;
            box-sizing: border-box;
            background: rgba(0,0,0,0.4);
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 130%;
            color: #FFFFFF;
        }
    }

    .footer {
        display: flex;
        border-radius: 0 0 5px 5px;
        padding: 16px 0;
        
        label {
            display: flex;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 128%;
            color: #222222;
            margin-left: 28px;

            input[type="radio"] {
                outline: none;
                appearance: none;
                width: 16px;
                height: 16px;
                border: 0.75px solid #D6D6D6;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;

                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: #D71249;
                    border-radius: 50%;
                    display: none;
                }

                &:checked {
                    &::before {
                        display: block;
                    }
                }
            }
        }
    }
`;

export {
    WrapPopupShowFace
}