import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import LoadingControl from '../Control/LoadingControl'

const Bound = styled.div`
    width: 105px;
    height: 105px;
    border-radius: 5px;
    border: solid 0.5px #c8c8c8;
    background-color: ${(props) => props.bgColor || '#ffffff'};
    margin: 0 10px;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    position: relative;
    &:hover {
        cursor: pointer;
        .overlay {
            visibility: visible;
        }
    }
    .cover-fingerID {
        .img-finger {
            display: flex;
            justify-content: center;
            overflow: hidden;
            .checkFinger {
                width: 42px;
                height: 42px;
                object-fit: contain;
                /* opacity: 0.75; */
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .fingerRegister_confirm {
                padding: 5px;
                width: 104px;
                height: 104px;
                object-fit: contain;
            }
        }
        .content {
            margin-top: 15px;
            text-align: center;
            font-size: 11px;
            font-weight: 500;
            line-height: 1.05;
            color: #979797;
        }
    }
    .confirm {
    }
    .overlay {
        width: 100%;
        height: 100%;
        visibility: hidden;
        background-color: rgba(34, 34, 34, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        .register,
        .delete {
            height: 24px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.68;
            color: #ffffff;
            position: absolute;
            top: 50%;
            left: 47%;
            transform: translate(-47%, -50%);
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 0.9px;
                background-color: rgba(255, 255, 255, 0.7);
                position: absolute;
                bottom: 3px;
            }
        }
    }
    .rotate {
        animation: spin 2s infinite linear;
        display: block;
    }
`

const REGISTER_FINGER = 1
const REGISTER_FINGER_LOADING = 2
const REGISTER_FINGER_SUCCESS = 3

class FingerControl extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: REGISTER_FINGER,
            img_fingerBio: this.props.dataMockup.img_finger, //// Default Finger
        }
    }

    ///////// Register FingerID
    registerFingerID = () => {
        if (this.props.registerFingerID) {
            this.props.registerFingerID()
            this.setState({
                step: REGISTER_FINGER_LOADING,
            })
        }
    }

    //////// Remove FingerID
    removeFingerID = () => {
        if (this.props.removeFingerID) this.props.removeFingerID()
        this.setState({
            step: REGISTER_FINGER,
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            prevState.img_fingerBio !== nextProps.img_fingerBio &&
            nextProps.img_fingerBio
        ) {
            return {
                step: REGISTER_FINGER_SUCCESS,
                img_fingerBio: nextProps.img_fingerBio,
            }
        }
        if (nextProps) {
            if (nextProps.img_fingerBio)
                return {
                    step: REGISTER_FINGER_SUCCESS,
                    img_fingerBio: nextProps.img_fingerBio,
                }

            if (!nextProps.img_fingerBio && nextProps.isRemoveFinger)
                return {
                    step: REGISTER_FINGER,
                    img_fingerBio: nextProps.dataMockup.img_finger,
                }

            if (nextProps.isLoading)
                return {
                    step: REGISTER_FINGER_LOADING,
                }
            else
                return {
                    step: REGISTER_FINGER,
                }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        let { biometricReducer } = this.props
        if (
            JSON.stringify(prevProps.biometricReducer) !==
            JSON.stringify(biometricReducer)
        ) {
            let checkWaitting = /waitingConfirm/g.test(
                JSON.stringify(biometricReducer)
            )
            if (!checkWaitting) {
                this.setState({
                    step: REGISTER_FINGER,
                })
            }
        }
    }

    render() {
        let { dataMockup } = this.props
        let { step, img_fingerBio } = this.state
        return (
            <Bound className="finger-print">
                {step === REGISTER_FINGER && (
                    <div
                        className="overlay"
                        onClick={() => this.registerFingerID()}
                    >
                        <div className="register">Đăng ký</div>
                    </div>
                )}
                {step === REGISTER_FINGER_SUCCESS && (
                    <div
                        className="overlay"
                        onClick={() => this.removeFingerID()}
                    >
                        <div className="delete">Xóa</div>
                    </div>
                )}
                {step === REGISTER_FINGER ? (
                    <div className="cover-fingerID">
                        <div className="img-finger">
                            <img
                                className="checkFinger"
                                src={img_fingerBio}
                                alt="fingerID"
                            />
                        </div>
                        <div className="content">{dataMockup.finger}</div>
                    </div>
                ) : (
                    step === REGISTER_FINGER_LOADING && (
                        <div className="cover-fingerID">
                            <div className="img-finger">
                                <div className="checkFinger">
                                    <LoadingControl
                                        size="42px"
                                        loadingPage={false}
                                    />
                                </div>
                            </div>
                            <div
                                className="content"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '12px',
                                }}
                            >
                                <p
                                    style={{ width: '50px', color: '#222222' }}
                                    className="text"
                                >
                                    {dataMockup.loading}
                                </p>
                            </div>
                        </div>
                    )
                )}
                {step === REGISTER_FINGER_SUCCESS && (
                    <div className="cover-fingerID confirm">
                        <div className="img-finger">
                            <img
                                className="fingerRegister_confirm"
                                src={img_fingerBio}
                                alt="fingerID"
                            />
                        </div>
                    </div>
                )}
            </Bound>
        )
    }
}

const mapStateToProps = (state) => ({
    biometricReducer: state.biometricReducer,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(FingerControl)
