import * as types from '../actions/types'
import * as counterStates from '../data/counterStates'
import * as ticketStatuses from '../data/ticketStatuses'
import * as ticketTypes from '../data/ticketTypes'

// helper
import createfakeTicket from '../helper/fakeTicket'
import { typeStatusBiometrics } from '../data/statusBiometricWithTicket'
import verifyFingerStatusTypes from '../data/verifyFingerStatusTypes'

export const initialState = {
    dataConnectDesktop: {},
    dataLogin: {},
    err: '',
    errConnectDesktop: '',
    listTypeCounterServices: [],
    dataGetTicket: {},
    waitingTicket: [],
    customerInfo: {},
    enrollId: {},
    listVipTicket: [],
    listCurrentTicket: [
        // {
        //   ...
        //   statusBiometric: string
        //   dataWasAuthen: {
        //    fullNameVi: string.
        //    phoneNumber: string
        //   },
        //   bioDetailOptions: {},
        //   historyDetails: {
        //   lstBioHistoryList: [],
        //   TotalHistoryList: 0
        // },
        // userWasPrinted: boolean
        //   ...
        // }
        // ...
    ],
    listMissCalled: [],
    listMoveTo: [],
    listOtherCounterInfos: [],
    verifyCustomer: {
        // [ticketId]: {
        //   waiting: false,
        //   result: ''
        // }
    },
    counterState: counterStates.STOP_COUNTER,
    queryHistory: {}
}

export default function counterReducer(state = initialState, action) {
    switch (action.type) {
    case types.GET_COUNTER_DETAILS_ERR: {
        if (action.code === 3)
            return {
                ...state,
                dataConnectDesktop: {},
            }
        return state
    }
    case types.CLEAR_STATUS_PRINTED: {
        const { ticketId } = action
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === ticketId
                    ? {
                        ...item,
                        userWasPrinted: false,
                    }
                    : item
            ),
        }
    }
    case types.CONFIRM_USER_PRINTED: {
        const { ticketId } = action
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === ticketId
                    ? {
                        ...item,
                        userWasPrinted: true,
                    }
                    : item
            ),
        }
    }
    case types.UPDATE_TICKET_INFOS: {
        const { ticketId, dataChanged } = action.payload
        const listCurrentTicket = state.listCurrentTicket.map((item) =>
            item.TicketID === ticketId
                ? {
                    ...item,
                    dataWasAuthen: {
                        ...dataChanged,
                    },
                }
                : item
        )
        return {
            ...state,
            listCurrentTicket,
        }
    }
    case types.CLEAR_LIST_HISTORY_CUST: {
        const { ticketId } = action
        const listCurrentTicket = state.listCurrentTicket.map((item) =>
            item.TicketID === ticketId
                ? {
                    ...item,
                    historyDetails: {
                        lstBioHistoryList: [],
                        TotalHistoryList: 0,
                    },
                }
                : item
        )
        return {
            ...state,
            listCurrentTicket,
        }
    }
    case types.ADD_VALIDATE_INFO: {
        const { ticketId, data } = action

        if (!!data && data.length === 0) return state

        const listCurrentTicket = state.listCurrentTicket.map((item) =>
            item.TicketID === ticketId
                ? {
                    ...item,
                    dataWasAuthen: {
                        fullNameVi: data[0].FullNameVi,
                        bioFaceId: data[0].FaceID,
                        custType: data[0].CustType,
                        phoneNumber: data[0].MobileNumber,
                        BiometricActive: data[0].BiometricActive,
                        BiometricFinger: data[0].BiometricFinger,
                        BiometricFace: data[0].BiometricFace,
                    },
                }
                : item
        )
        return {
            ...state,
            listCurrentTicket,
        }
    }
    case types.REASON_NOT_REGISTER_FINGER_BUT_FACE: {
        const {
            ticketId,
            notVerifyFingerReason,
            notVerifyFingerReason_otherReason,
        } = action.payload
        const listCurrentTicket = state.listCurrentTicket.map((item) =>
            item.TicketID === ticketId
                ? {
                    ...item,
                    notVerifyFingerReason,
                    notVerifyFingerReason_otherReason,
                }
                : item
        )
        return {
            ...state,
            listCurrentTicket,
        }
    }
    case types.REASON_REFUSE_REG_FINGER: {
        const { ticketId, reasonRefuseRegFinger } = action.payload
        const listCurrentTicket = state.listCurrentTicket.map((item) =>
            item.TicketID === ticketId
                ? {
                    ...item,
                    reasonRefuseRegFinger
                }
                : item
        )
        return {
            ...state,
            listCurrentTicket,
        }
    }
    case types.GET_LIST_HISTORY_CUST: {
        const { ticketId, historyDetails } = action
        if (!ticketId)
            return {
                ...state,
                queryHistory: {
                    lstBioHistoryList:
                            historyDetails.BioHistoryDetail,
                    TotalHistoryList: historyDetails.Total,
                }
            }
        const listCurrentTicket = state.listCurrentTicket.map((item) =>
            item.TicketID === ticketId
                ? {
                    ...item,
                    historyDetails: {
                        lstBioHistoryList:
                                historyDetails.BioHistoryDetail,
                        TotalHistoryList: historyDetails.Total,
                    },
                }
                : item
        )
        return {
            ...state,
            listCurrentTicket,
        }
    }
    case types.REVERT_REMOVE_BIO_IN_UPDATE_THREAD: {
        const { ticketId, bioType } = action
        const listCurrentTicket = state.listCurrentTicket.map((item) => {
            if (item.TicketID === ticketId) {
                const tmp = JSON.parse(JSON.stringify(item))
                delete tmp.bioDetailOptions[bioType]
                return tmp
            }
            return item
        })
        return {
            ...state,
            listCurrentTicket,
        }
    }
    case types.REMOVE_BIO_IN_UPDATE_THREAD: {
        const listCurrentTicket = JSON.parse(
            JSON.stringify(state.listCurrentTicket)
        )
        const { ticketId, bioType, removeNote } = action

        return {
            ...state,
            listCurrentTicket: listCurrentTicket.map((item) =>
                item.TicketID === ticketId
                    ? {
                        ...item,
                        bioDetailOptions: {
                            ...item.bioDetailOptions,
                            [bioType]: {
                                removeNote,
                            },
                        },
                    }
                    : item
            ),
        }
    }
    case types.CLEAR_CUST_INFOS_IN_STORE: {
        return {
            ...state,
            customerInfo: {},
        }
    }
    case types.COMPLETE_FAKE_TICKET: {
        // const newListCurrentTicket = state.listCurrentTicket.filter(
        //     (item) => item.TicketID !== action.ticketId
        // )

        const newEnrollId = JSON.parse(JSON.stringify(state.enrollId))
        delete newEnrollId[action.ticketId]
        return {
            ...state,
            // listCurrentTicket: [
            //     ...newListCurrentTicket,
            //     createfakeTicket()
            // ],
            listCurrentTicket: state.listCurrentTicket.map(ticket =>
                ticket.TicketID === action.ticketId ?
                    ({
                        ...createfakeTicket(),
                        TicketID: action.ticketId
                    })
                    :
                    ticket
            ),
            enrollId: newEnrollId,
        }
    }
    case types.UPDATE_BIOMETRIC_DUPLICATE_NOTE: {
        const currentTicketId = Object.keys(state.enrollId).find(
            (item) => state.enrollId[item] === action.enrollId
        )
        if (!currentTicketId) return state
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === currentTicketId
                    ? {
                        ...item,
                        tellerNoteDuplicateBio: action.note,
                    }
                    : item
            ),
        }
    }
    case types.REPORT_VERIFY_CUSTOMER_FAILED_TO_SUPERVISOR: {
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === action.ticketId
                    ? {
                        ...item,
                        VerifyFingerStatus: verifyFingerStatusTypes.PV,
                    }
                    : item
            ),
        }
    }
    case types.REMOVE_DUPLICATE_FACE: {
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === action.ticketId
                    ? {
                        ...item,
                        duplicateFaces: [],
                        tellerNoteDuplicateBio: '',
                    }
                    : item
            ),
        }
    }
    case types.SOCKET_SET_STATUS_BIOMETRIC_WITH_TICKET: {
        const {
            // ticketId,
            enrollId,
            bioInfosType,
            statusBiometric,
            noteRejectBio,
            reVerify,
        } = action.payload
        const ticketId = Object.keys(state.enrollId).find(
            (item) => state.enrollId[item] === enrollId
        )
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === ticketId
                    ? {
                        ...item,
                        bioInfosType,
                        statusBiometric,
                        noteRejectBio,
                        reVerify,
                    }
                    : item
            ),
        }
    }
    case types.REQUEST_CONFIRM_BIO_DATA_ERR: {
        const {
            cifs,
            invalidFingerPrint,
            ticketId,
            bioTypeDuplicate,
        } = action.payload
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === ticketId
                    ? {
                        ...item,
                        duplicateFingers: {
                            cifs,
                            invalidFingerPrint,
                            bioType: bioTypeDuplicate,
                        },
                    }
                    : item
            ),
        }
    }
    case types.SET_DUPLICATE_BIO_FACE: {
        // debugger;
        const currentTicket = state.listCurrentTicket.find(
            (item) => item.TicketID === action.ticketId
        )
        const { CustomerInfos } = currentTicket
        const { CustomerNumber } = CustomerInfos
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === action.ticketId
                    ? {
                        ...item,
                        duplicateFaces: action.duplicateFaces.filter(
                            (item) =>
                                item.CustomerNumber !== CustomerNumber
                        ),
                        tellerNoteDuplicateBio: action.tellerNote,
                    }
                    : item
            ),
        }
    }
    case types.ADD_BIOMETRIC_EXIST: {
        const { bioInfos, ticketId } = action

        if (!bioInfos) return state
        if (!Object.keys(bioInfos).includes('CustomerType')) return state

        const { CustomerType } = bioInfos

        const listCurrentTicket = JSON.parse(
            JSON.stringify(state.listCurrentTicket)
        )
        return {
            ...state,
            listCurrentTicket: listCurrentTicket.map((item) =>
                item.TicketID === ticketId
                    ? {
                        ...item,
                        CustomerType,
                    }
                    : item
            ),
        }
    }
    case types.CONTINUE_GET_BIO: {
        const oldListCurrentTicket = JSON.parse(
            JSON.stringify(state.listCurrentTicket)
        )
        return {
            ...state,
            listCurrentTicket: oldListCurrentTicket.map((item) =>
                item.TicketID === action.ticketId
                    ? {
                        ...item,
                        statusCustomer: '',
                    }
                    : item
            ),
        }
    }
    case types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO: {
        return {
            ...state,
            enrollId: {
                ...state.enrollId,
                [action.ticketId]: action.newEnrollId,
            },
            listCurrentTicket: state.listCurrentTicket.map((item) => {
                const tmp = item
                if (item.TicketID === action.ticketId) {
                    delete tmp.tellerNoteNotEnoughFinger
                    return tmp
                }
                return item
            }),
        }
    }
    case types.ADD_INPROGRESS_BIO_EXIST: {
        return {
            ...state,
            enrollId: {
                ...state.enrollId,
                [action.ticketId]: action.enrollId,
            },
        }
    }
    case types.CREATE_NEW_TICKET: {
        const oldListCurrentTicket = JSON.parse(
            JSON.stringify(state.listCurrentTicket)
        )
        return {
            ...state,
            dataGetTicket: {
                TicketData: action.ticketData,
            },
            listCurrentTicket: [
                ...oldListCurrentTicket,
                {
                    ...action.ticketData,
                    dataWasAuthen: {
                        fullNameVi:
                                action.ticketData.CustomerInfos.FullNameVi,
                        bioFaceId: action.ticketData.CustomerInfos.FaceID,
                        custType: action.ticketData.CustomerInfos.CustType,
                        phoneNumber:
                                action.ticketData.CustomerInfos.MobileNumber,
                        BiometricActive:
                                action.ticketData.CustomerInfos.BiometricActive,
                        BiometricFinger:
                                action.ticketData.CustomerInfos.BiometricFinger,
                        BiometricFace:
                                action.ticketData.CustomerInfos.BiometricFace,

                    },
                    historyDetails: {
                        lstBioHistoryList: [],
                        TotalHistoryList: 0,
                    },
                    ...action.extendData
                },
            ],
        }
    }

    case types.VERIFY_FINGER: {
        const tmpState = JSON.parse(JSON.stringify(state))
        tmpState.verifyCustomer[action.ticketId] = {
            waiting: true,
            verifyFingers: action.verifyFingers,
            result: '',
        }
        return tmpState
    }

    case types.REMOVE_STATUS_WAITING_VERIFY_FINGER: {
        const tmpState = JSON.parse(JSON.stringify(state))
        delete tmpState.verifyCustomer[action.ticketId]
        return tmpState
    }
    case types.VERIFY_CUSTOMER_RESULT: {
        const tmpState = JSON.parse(JSON.stringify(state))
        if (action.detail.Code === 0) {
            tmpState.listCurrentTicket = tmpState.listCurrentTicket.map(
                (item) =>
                    item.TicketID === action.detail.TicketID
                        ? {
                            ...item,
                            VerifyFingerStatus:
                                    verifyFingerStatusTypes.SUV,
                        }
                        : item
            )
        }
        tmpState.verifyCustomer[action.detail.TicketID] = {
            waiting: false,
            result: action.detail,
        }
        return tmpState
    }

    case types.SOCKET_VERIFY_FINGER_SEEN: {
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === action.ticketId
                    ? {
                        ...item,
                        VerifyFingerStatus: verifyFingerStatusTypes.SV,
                    }
                    : item
            ),
        }
    }

    case types.SOCKET_CONFIRM_FACE: {
        const {
            ticketId,
            code,
        } = action.payload

        if (code === 0) {
            const newListCurrentTicket = state.listCurrentTicket.map(
                (ticket) =>
                    ticket.TicketID === ticketId
                        ? {
                            ...ticket,
                            face: code,
                        }
                        : ticket
            )
            return {
                ...state,
                listCurrentTicket: newListCurrentTicket,
            }
        }

        return state
    }
    case types.CLEAR_ERR: {
        const err = JSON.parse(JSON.stringify(state.err))
        delete err[action.typeErr]
        return {
            ...state,
            err,
        }
    }
    case types.CONNECT_DESKTOP:
        return {
            ...state,
            dataConnectDesktop: action.data,
        }
    case types.GET_CUSTOMER_INFORMATION_ERR: {
        const err = JSON.parse(JSON.stringify(state.err))
        return {
            ...state,
            customerInfo: {},
            err: {
                ...err,
                ...action.err,
            },
        }
    }
    case types.CALL_TICKET_ERR:
    case types.GET_TICKET_ERR: {
        const err = JSON.parse(JSON.stringify(state.err))
        return {
            ...state,
            dataGetTicket: {},
            err: {
                ...err,
                ...action.err,
            },
        }
    }
    case types.CONNECT_DESKTOP_ERR:
    case types.COUNTER_LOGOUT_ERR:
    case types.COUNTER_LOGIN_ERR:
    case types.GET_LIST_TYPE_COUNTER_SERVICES_ERR:
    case types.GET_ALL_TICKET_WAITING_ERR:
    case types.GET_CUSTOMER_INFORMATION_COLLECTOR_ERR:
    case types.COMPLETE_TICKET_ERR:
    case types.REQUEST_ENROLL_ERR:
    case types.REQUEST_UPDATE_BIO_ERR:
    case types.REQUEST_ENROLL_COLLECTOR_ERR:
    case types.GET_CURRENT_TICKETS_ERR:
    case types.PAUSE_TICKET_ERR:
    case types.UNPAUSE_TICKET_ERR:
    case types.CHANGE_STATE_COUNTER_ERR:
    case types.MOVE_TICKET_TO_MISS_CALL_ERR:
    case types.GET_OTHER_COUNTER_INFOS_ERR:
    case types.MOVE_TICKET_TO_OTHER_COUNTER_ERR:
    case types.UPDATE_BIO_INFO_COLLECTOR_ERR:
    case types.UPDATE_BIO_INFO_ERR:
    case types.REASON_NOT_REGISTER_ERR:
    case types.ERROR_CONNECTION:
    case types.RECALL_TICKET_ERR:
    case types.VERIFY_FINGER_ERR:
    case types.COMPLETE_BIO_ERR:
    case types.COMPLETE_BIO_COLLECTOR_ERR:
    case types.GET_NEW_ENROLL_TO_UPDATE_REJECT_BIO_ERR:
    case types.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET_ERR:
    case types.CREATE_NEW_TICKET_ERR: {
        const err = JSON.parse(JSON.stringify(state.err))
        return {
            ...state,
            err: {
                ...err,
                ...action.err,
            },
        }
    }
    case types.DISCONNECT_DESKTOP:
        localStorage.removeItem('dataConnectDesktop')
        return {
            ...state,
            dataConnectDesktop: {},
        }
    case types.DISCONNECT_DESKTOP_ERR:
        return {
            ...state,
            errConnectDesktop: action.err,
        }
    case types.COUNTER_LOGIN:
        return {
            ...state,
            dataLogin: action.data,
            err: '',
        }
    case types.GET_LIST_TYPE_COUNTER_SERVICES:
        return {
            ...state,
            listTypeCounterServices: action.listService,
            err: '',
        }
    case types.SOCKET_ENQUEUE_TICKET: {
        const { dataLogin } = state
        // VIP counter
        if (dataLogin.IsVIP) {
            return {
                ...state,
                waitingTicket: [...state.waitingTicket, action.ticket],
            }
        }

        // normal counter
        if (action.ticket.TicketType === ticketTypes.VIP_TICKET) {
            return {
                ...state,
                listVipTicket: [...state.listVipTicket, action.ticket],
            }
        }
        return {
            ...state,
            waitingTicket: [...state.waitingTicket, action.ticket],
        }
    }
    case types.SOCKET_RECEIVE_TICKET_FROM_OTHER_COUNTER: {
        const { dataLogin } = state

        // VIP counter
        if (dataLogin.IsVIP) {
            if (action.ticket.TicketType === ticketTypes.VIP_TICKET) {
                const tmpVIPMove = []
                const tmpVIPWaiting = []
                state.waitingTicket.forEach((item) => {
                    if (item.Status === ticketStatuses.MOVING)
                        tmpVIPMove.push(item)
                    else tmpVIPWaiting.push(item)
                })

                return {
                    ...state,
                    waitingTicket: [
                        ...tmpVIPMove,
                        action.ticket,
                        ...tmpVIPWaiting,
                    ],
                }
            }

            return {
                ...state,
                listMoveTo: [...state.listMoveTo, action.ticket],
            }
        }

        // normal counter
        if (action.ticket.TicketType === ticketTypes.VIP_TICKET) {
            // if(action.ticket.Status === ticketStatuses.MOVING){
            const tmpVIPMove = []
            const tmpVIPWaiting = []
            state.listVipTicket.forEach((item) => {
                if (item.Status === ticketStatuses.MOVING)
                    tmpVIPMove.push(item)
                else tmpVIPWaiting.push(item)
            })

            return {
                ...state,
                listVipTicket: [
                    ...tmpVIPMove,
                    action.ticket,
                    ...tmpVIPWaiting,
                ],
            }
        }
        return {
            ...state,
            listMoveTo: [...state.listMoveTo, action.ticket],
        }
    }
    case types.SOCKET_DEQUEUE_TICKET: {
        const newListVipTicket = state.listVipTicket.filter(
            (ticket) => ticket.TicketID !== action.ticketId
        )
        if (newListVipTicket.length !== state.listVipTicket.length)
            return {
                ...state,
                listVipTicket: newListVipTicket,
            }

        const newListTicketMoveTo = state.listMoveTo.filter(
            (ticket) => ticket.TicketID !== action.ticketId
        )
        if (newListTicketMoveTo.length !== state.listMoveTo.length)
            return {
                ...state,
                listMoveTo: newListTicketMoveTo,
            }

        const newListWaitingTicket = state.waitingTicket.filter(
            (ticket) => ticket.TicketID !== action.ticketId
        )
        if (newListWaitingTicket.length !== state.waitingTicket.length)
            return {
                ...state,
                waitingTicket: newListWaitingTicket,
            }

        return state
    }
    case types.GET_ALL_TICKET_WAITING:
        return {
            ...state,
            waitingTicket: action.listTicket,
            listMissCalled: action.listMissCalled,
            listMoveTo: action.listMoveTo,
            listVipTicket: action.listVipTicket,
            err: '',
        }
    case types.GET_CUSTOMER_INFORMATION:
    case types.GET_CUSTOMER_INFORMATION_COLLECTOR: {
        const [cif = {}] = action.data?.Cifs || []
        if (cif.CustomerNumber) {
            const ticketHaveCustomer = state.listCurrentTicket.find(ticket =>
                ticket.CustomerInfos?.CustomerNumber === cif.CustomerNumber
            )
            if (ticketHaveCustomer) {
                return {
                    ...state,
                    customerInfo: action.data,
                    listCurrentTicket: state.listCurrentTicket.map(ticket =>
                        ticket.TicketID === ticketHaveCustomer.TicketID
                            ? {
                                ...ticket,
                                CustomerInfos: {
                                    ...ticket.CustomerInfos,
                                    ExistCustomerNumbers: cif.ExistCustomerNumbers || []
                                },
                                LatestHistoryType: action.data?.LatestHistoryType,
                                dataWasAuthen: {
                                    ...ticket.dataWasAuthen,
                                    vtbReasonRefuseRegFinger: cif.ReasonNRFinger
                                }
                                // reasonRefuseRegFinger: cif.ReasonNRFinger
                            }
                            : ticket
                    ),
                    err: '',
                }
            }
        }
        return {
            ...state,
            customerInfo: action.data,
            err: '',
        }
    }
    case types.GET_TICKET:
        return {
            ...state,
            dataGetTicket: action.data,
            listCurrentTicket: [
                ...state.listCurrentTicket,
                {
                    ...action.data.TicketData,
                    dataWasAuthen: {
                        fullNameVi: action.data.TicketData.CustomerInfos?.FullNameVi ?? '',
                        bioFaceId: action.data.TicketData.CustomerInfos?.FaceID ?? '',
                        custType: action.data.TicketData.CustomerInfos?.CustType ?? '',
                        phoneNumber: action.data.TicketData.CustomerInfos?.MobileNumber ?? '',
                        BiometricActive: action.data.TicketData.CustomerInfos?.BiometricActive ?? '',
                        BiometricFinger: action.data.TicketData.CustomerInfos?.BiometricFinger ?? '',
                        BiometricFace: action.data.TicketData.CustomerInfos?.BiometricFace ?? '',
                    },
                    historyDetails: {
                        lstBioHistoryList: [],
                        TotalHistoryList: 0,
                    },
                },
            ],
            err: '',
        }
    case types.COMPLETE_TICKET: {
        const enrollId = { ...state.enrollId }
        const verifyCustomer = JSON.parse(
            JSON.stringify(state.verifyCustomer)
        )
        const { ticketId } = action
        const listCurrentTicket = state.listCurrentTicket.filter(
            (item) => item.TicketID !== ticketId
        )
        if (Object.keys(enrollId).includes(ticketId))
            delete enrollId[ticketId]
        if (Object.keys(verifyCustomer).includes(ticketId))
            delete verifyCustomer[ticketId]
        return {
            ...state,
            dataGetTicket: {},
            enrollId,
            listCurrentTicket,
            verifyCustomer,
            err: '',
        }
    }
    case types.REQUEST_ENROLL:
    case types.REQUEST_UPDATE_BIO:
    case types.REQUEST_ENROLL_COLLECTOR:
        return {
            ...state,
            enrollId: {
                ...state.enrollId,
                [action.ticketId]: action.enrollId,
            },
            err: '',
        }
    case types.GET_CURRENT_TICKETS:
        return {
            ...state,
            listCurrentTicket: action.data.ListTicket.map((item) => ({
                ...item,
                dataWasAuthen: {
                    fullNameVi: item.CustomerInfos?.FullNameVi ?? '',
                    bioFaceId: item.CustomerInfos?.FaceID ?? '',
                    custType: item.CustomerInfos?.CustType ?? '',
                    phoneNumber: item.CustomerInfos?.MobileNumber ?? '',
                    BiometricActive: item.CustomerInfos?.BiometricActive ?? '',
                    BiometricFinger: item.CustomerInfos?.BiometricFinger ?? '',
                    BiometricFace: item.CustomerInfos?.BiometricFace ?? '',
                },
                historyDetails: {
                    lstBioHistoryList: [],
                    TotalHistoryList: 0,
                },
            })),
            err: '',
        }
    case types.PAUSE_TICKET: {
        const newListCurrentTicket = state.listCurrentTicket.map((ticket) =>
            ticket.TicketID === action.ticketId
                ? {
                    ...ticket,
                    TotalTime: action.totalTime,
                    Status: ticketStatuses.PAUSED,
                }
                : ticket
        )
        return {
            ...state,
            listCurrentTicket: newListCurrentTicket,
            err: '',
        }
    }
    case types.UNPAUSE_TICKET: {
        const newListCurrentTicket = state.listCurrentTicket.map((ticket) =>
            ticket.TicketID === action.ticketId
                ? {
                    ...ticket,
                    TotalTime: action.totalTime,
                    Status: ticketStatuses.INPROCESSING,
                }
                : ticket
        )
        return {
            ...state,
            listCurrentTicket: newListCurrentTicket,
            err: '',
        }
    }
    case types.GET_CURRENT_BIO_ENROLL_COLLECTOR: {
        const { bioInfos, pendingBio } = action.payload
        const listCurrentTicket = []
        const enrollId = {}
        bioInfos.forEach((item) => {
            enrollId[item.ID] = item.ID
            const bioDatas = item.BioDatas
            const tmpTicket = {
                TicketID: item.ID,
                CustomerInfos: item.CustomerInfos,
                tellerNoteDuplicateBio: item.DuplicateFaceNote,
                tellerNoteNotEnoughFinger: item.RejectRegisterNote,
                dataWasAuthen: {
                    fullNameVi: item.CustomerInfos?.FullNameVi ?? '',
                    bioFaceId: item.CustomerInfos?.FaceID ?? '',
                    custType: item.CustomerInfos?.CustType ?? '',
                    phoneNumber: item.CustomerInfos?.MobileNumber ?? '',
                    BiometricActive: item.CustomerInfos?.BiometricActive ?? '',
                    BiometricFinger: item.CustomerInfos?.BiometricFinger ?? '',
                    BiometricFace: item.CustomerInfos?.BiometricFace ?? '',
                },
            }
            if (!!bioDatas) {
                const faceBio = bioDatas.find(
                    (data) => data.BioType === 'FACE'
                )
                if (faceBio) {
                    const duplicateFaces = faceBio.DuplicateFaces || []
                    tmpTicket.duplicateFaces = duplicateFaces.filter(
                        (data) =>
                            data.CustomerNumber !==
                                item.CustomerInfos.CustomerNumber
                    )
                }

                tmpTicket.bioDetailOptions = bioDatas.reduce(
                    (obj, bioItem) => ({
                        ...obj,
                        [bioItem.BioType]: {
                            removeNote: bioItem.RemoveNote,
                        },
                    }),
                    {}
                )
            }
            listCurrentTicket.push(tmpTicket)
        })

        pendingBio.forEach((item) => {
            enrollId[item.ID] = item.ID
            const bioDatas = item.BioDatas
            const tmpTicket = {
                TicketID: item.ID,
                CustomerInfos: item.CustomerInfos,
                statusBiometric: item.Status || 'undefined',
                noteRejectBio: item.Note || '',
                tellerNoteDuplicateBio: item.DuplicateFaceNote,
                tellerNoteNotEnoughFinger: item.RejectRegisterNote,
                dataWasAuthen: {
                    fullNameVi: item.CustomerInfos?.FullNameVi ?? '',
                    bioFaceId: item.CustomerInfos?.FaceID ?? '',
                    custType: item.CustomerInfos?.CustType ?? '',
                    phoneNumber: item.CustomerInfos?.MobileNumber ?? '',
                    BiometricActive: item.CustomerInfos?.BiometricActive ?? '',
                    BiometricFinger: item.CustomerInfos?.BiometricFinger ?? '',
                    BiometricFace: item.CustomerInfos?.BiometricFace ?? '',
                },
            }

            if (!!bioDatas) {
                const faceBio = bioDatas.find(
                    (item) => item.BioType === 'FACE'
                )
                if (faceBio) {
                    const duplicateFaces = faceBio.DuplicateFaces || []
                    tmpTicket.duplicateFaces = duplicateFaces.filter(
                        (itemDup) =>
                            itemDup.CustomerNumber !==
                                item.CustomerInfos.CustomerNumber
                    )
                }
                tmpTicket.bioDetailOptions = bioDatas.reduce(
                    (obj, bioItem) => ({
                        ...obj,
                        [bioItem.BioType]: {
                            removeNote: bioItem.RemoveNote,
                        },
                    }),
                    {}
                )
            }

            listCurrentTicket.push(tmpTicket)
        })
        if (!listCurrentTicket.length && state.listCurrentTicket.length) {
            return state
        }
        if (
            listCurrentTicket.length === 1 &&
                state.listCurrentTicket.length === 2
        ) {
            const ticketCusInfosOut =
                    listCurrentTicket[0].CustomerInfos.CustomerNumber
            const ticketKeep = state.listCurrentTicket.find(
                (item) =>
                    item.CustomerInfos &&
                        item.CustomerInfos.CustomerNumber !== ticketCusInfosOut
            )
            if (!ticketKeep)
                return {
                    ...state,
                    listCurrentTicket: [
                        ...listCurrentTicket,
                        createfakeTicket(),
                    ],
                    enrollId,
                }
            return {
                ...state,
                listCurrentTicket: [...listCurrentTicket, ticketKeep],
                enrollId,
            }
        }
        while (listCurrentTicket.length < 2) {
            listCurrentTicket.push(createfakeTicket())
        }
        return {
            ...state,
            listCurrentTicket,
            enrollId,
        }
    }
    case types.GET_CURRENT_BIO_ENROLL: {
        const {
            ticketId,
            enrollId,
            customerInfo,
            bioDatas,
            bioInfosType,
            statusBiometric,
            noteRejectBio,
            statusCustomer,
            reasonRefuseRegFinger
        } = action.payload
        if (ticketId && customerInfo) {
            const newListCurrentTicket = state.listCurrentTicket.map(
                (ticket) =>
                    ticket.TicketID === ticketId
                        ? {
                            ...ticket,
                            statusBiometric,
                            noteRejectBio,
                            statusCustomer,
                            bioInfosType,
                            CustomerInfos: customerInfo,
                            bioDetailOptions: bioDatas.reduce(
                                (obj, item) => ({
                                    ...obj,
                                    [item.BioType]: {
                                        removeNote: item.RemoveNote,
                                    },
                                }),
                                {}
                            ),
                            reasonRefuseRegFinger
                        }
                        : ticket
            )
            return {
                ...state,
                enrollId: {
                    ...state.enrollId,
                    [ticketId]: enrollId || undefined,
                },
                listCurrentTicket: newListCurrentTicket,
                err: '',
            }
        }
        return {
            ...state,
            err: '',
        }
    }
    case types.CHANGE_STATE_COUNTER: {
        if (action.status) {
            return {
                ...state,
                counterState: action.status,
            }
        }
        const isStopCounter =
                state.counterState === counterStates.STOP_COUNTER
        if (isStopCounter)
            localStorage.setItem('counterState', counterStates.OPEN_COUNTER)
        else
            localStorage.setItem('counterState', counterStates.STOP_COUNTER)
        return {
            ...state,
            counterState: isStopCounter
                ? counterStates.OPEN_COUNTER
                : counterStates.STOP_COUNTER,
            err: '',
        }
    }
    case types.ADD_CUST_INFO_TO_LIST_CURRENT_TICKET: {
        if (action.isChangeTicket) {
            const oldListCurrentTicket = JSON.parse(
                JSON.stringify(state.listCurrentTicket)
            )
            const newListCurrentTicket = oldListCurrentTicket.filter(
                (ticket) => ticket.TicketID !== action.oldTicketId
            )
            const newTicket = {
                ...action.ticketData,
                dataWasAuthen: {
                    fullNameVi: action.ticketData.CustomerInfos?.FullNameVi ?? '',
                    bioFaceId: action.ticketData.CustomerInfos?.FaceID ?? '',
                    custType: action.ticketData.CustomerInfos?.CustType ?? '',
                    phoneNumber: action.ticketData.CustomerInfos?.MobileNumber ?? '',
                    BiometricActive: action.ticketData.CustomerInfos?.BiometricActive ?? '',
                    BiometricFinger: action.ticketData.CustomerInfos?.BiometricFinger ?? '',
                    BiometricFace: action.ticketData.CustomerInfos?.BiometricFace ?? '',
                },
                historyDetails: {
                    lstBioHistoryList: [],
                    TotalHistoryList: 0,
                },
            }
            newListCurrentTicket.push(newTicket)
            return {
                ...state,
                listCurrentTicket: newListCurrentTicket,
            }
        }
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === action.ticketID
                    ? {
                        ...item,
                        CustomerInfos: action.customerInfos,
                        dataWasAuthen: {
                            fullNameVi: action.customerInfos?.FullNameVi ?? '',
                            bioFaceId: action.customerInfos?.FaceID ?? '',
                            custType: action.customerInfos?.CustType ?? '',
                            phoneNumber: action.customerInfos?.MobileNumber ?? '',
                            BiometricActive: action.customerInfos?.BiometricActive ?? '',
                            BiometricFinger: action.customerInfos?.BiometricFinger ?? '',
                            BiometricFace: action.customerInfos?.BiometricFace ?? '',
                        },
                        historyDetails: {
                            lstBioHistoryList: [],
                            TotalHistoryList: 0,
                        },
                    }
                    : item
            ),
            err: '',
        }
    }
    case types.MOVE_TICKET_TO_MISS_CALL: {
        // debugger;
        const ticketWillMoveToMissCall = state.listCurrentTicket.find(
            (item) => item.TicketID === action.ticketId
        )
        const newListCurrentTicket = state.listCurrentTicket.filter(
            (item) => item.TicketID !== action.ticketId
        )

        const verifyCustomer = JSON.parse(
            JSON.stringify(state.verifyCustomer)
        )
        if (Object.keys(verifyCustomer).includes(action.ticketId))
            delete verifyCustomer[action.ticketId]

        ticketWillMoveToMissCall.Status = ticketStatuses.MISSEDCALL
        const isTicketHaveCustomerInfos = Object.keys(
            ticketWillMoveToMissCall
        ).includes('CustomerInfos')
        if (
            isTicketHaveCustomerInfos &&
                ticketWillMoveToMissCall.CustomerInfos
        ) {
            ticketWillMoveToMissCall.CustomerInfos.FullNameVi =
                    (!!ticketWillMoveToMissCall.dataWasAuthen &&
                        ticketWillMoveToMissCall.dataWasAuthen.fullNameVi) ||
                    ''
        }
        return {
            ...state,
            listCurrentTicket: newListCurrentTicket,
            listMissCalled: [
                ...state.listMissCalled,
                ticketWillMoveToMissCall,
            ],
            verifyCustomer,
        }
    }
    case types.GET_OTHER_COUNTER_INFOS:
        return {
            ...state,
            listOtherCounterInfos: action.listOtherCounterInfos,
        }
    case types.MOVE_TICKET_TO_OTHER_COUNTER: {
        const verifyCustomer = JSON.parse(
            JSON.stringify(state.verifyCustomer)
        )
        if (Object.keys(verifyCustomer).includes(action.ticketId))
            delete verifyCustomer[action.ticketId]
            // debugger;
        const newListCurrentTicket = state.listCurrentTicket.filter(
            (ticket) => ticket.TicketID !== action.ticketId
        )
        if (newListCurrentTicket.length !== state.listCurrentTicket.length)
            return {
                ...state,
                listCurrentTicket: newListCurrentTicket,
                verifyCustomer,
            }

        const newListMissCalled = state.listMissCalled.filter(
            (ticket) => ticket.TicketID !== action.ticketId
        )
        if (newListMissCalled.length !== state.listMissCalled.length)
            return {
                ...state,
                listMissCalled: newListMissCalled,
                verifyCustomer,
            }

        const newListVipTicket = state.listVipTicket.filter(
            (ticket) => ticket.TicketID !== action.ticketId
        )
        if (newListVipTicket.length !== state.listVipTicket.length)
            return {
                ...state,
                listVipTicket: newListVipTicket,
                verifyCustomer,
            }

        const newListTicketMoveTo = state.listMoveTo.filter(
            (ticket) => ticket.TicketID !== action.ticketId
        )
        if (newListTicketMoveTo.length !== state.listMoveTo.length)
            return {
                ...state,
                listMoveTo: newListTicketMoveTo,
                verifyCustomer,
            }

        const newListWaitingTicket = state.waitingTicket.filter(
            (ticket) => ticket.TicketID !== action.ticketId
        )
        if (newListWaitingTicket.length !== state.waitingTicket.length)
            return {
                ...state,
                waitingTicket: newListWaitingTicket,
                verifyCustomer,
            }
        return state
    }
    case types.CALL_TICKET: {
        const { data } = action
        const newListCurrentTicket = JSON.parse(
            JSON.stringify(state.listCurrentTicket)
        )

        let newState = JSON.parse(JSON.stringify(state))
        const newTicket = {
            ...data.TicketData,
            dataWasAuthen: {
                fullNameVi: data.TicketData.CustomerInfos?.FullNameVi ?? '',
                bioFaceId: data.TicketData.CustomerInfos?.FaceID ?? '',
                custType: data.TicketData.CustomerInfos?.CustType ?? '',
                phoneNumber: data.TicketData.CustomerInfos?.MobileNumber ?? '',
                BiometricActive: data.TicketData.CustomerInfos?.BiometricActive ?? '',
                BiometricFinger: data.TicketData.CustomerInfos?.BiometricFinger ?? '',
                BiometricFace: data.TicketData.CustomerInfos?.BiometricFace ?? '',
                LatestHistoryType: data.TicketData.CustomerInfos?.LatestHistoryType ?? '',
            },
        }
        newState.listCurrentTicket = newListCurrentTicket.concat(newTicket)
        newState.dataGetTicket = data
        const newListVipTicket = state.listVipTicket.filter(
            (item) => item.TicketID !== data.TicketData.TicketID
        )
        newState.listVipTicket = newListVipTicket

        const newListWaitingTicket = state.waitingTicket.filter(
            (item) => item.TicketID !== data.TicketData.TicketID
        )
        newState.waitingTicket = newListWaitingTicket

        const newListTicketMoveTo = state.listMoveTo.filter(
            (item) => item.TicketID !== data.TicketData.TicketID
        )
        newState.listMoveTo = newListTicketMoveTo

        const newListMissCalled = state.listMissCalled.filter(
            (item) => item.TicketID !== data.TicketData.TicketID
        )
        newState.listMissCalled = newListMissCalled
        return newState
    }
    case types.UPDATE_BIO_INFO_COLLECTOR:
    case types.UPDATE_BIO_INFO: {
        const {
            newFullNameVi: FullNameVi,
            newMobile: MobileNumber,
            ticketId,
        } = action
        const customerInfos = {}
        if (FullNameVi) customerInfos.FullNameVi = FullNameVi
        if (MobileNumber) customerInfos.MobileNumber = MobileNumber
        const newListCurrentTicket = state.listCurrentTicket.map((item) =>
            item.TicketID === ticketId
                ? {
                    ...item,
                    CustomerInfos: {
                        ...item.CustomerInfos,
                        ...customerInfos,
                    },
                }
                : item
        )
        return {
            ...state,
            listCurrentTicket: newListCurrentTicket,
        }
    }
    case types.REASON_NOT_REGISTER: {
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === action.ticketId
                    ? {
                        ...item,
                        duplicateFaces: [],
                        statusBiometric: typeStatusBiometrics.PENDING,
                    }
                    : item
            ),
        }
    }
    case types.COMPLETE_BIO: {
        return {
            ...state,
            listCurrentTicket: state.listCurrentTicket.map((item) =>
                item.TicketID === action.ticketId
                    ? {
                        ...item,
                        statusBiometric: typeStatusBiometrics.PENDING,
                    }
                    : item
            ),
        }
    }
    default:
        return state
    }
}
