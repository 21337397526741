import React, { Component } from 'react';
import styled from 'styled-components';
import LoadingControl from '../../Control/LoadingControl';

const Bound = styled.div`
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    .waitting {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 45px 0 0 0;
        .title {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.3;
            color: #222222;
            margin: 8px 0 0 0;
        }
        .closing-get-bio {
            margin: 50px 0 35px 0;
            font-size: 12px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .action {
            margin: 50px 0 35px 0;
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.68;
            color: #f31919;
            .nameAction {
                position: relative;
                &::after {
                    position: absolute;
                    bottom: 15%;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: #f31919;
                }
            }
        }
    }
`

export default class WaittingConfirmFinger extends Component {

    state = {
        closingGetBio: false,
        isVisibleFinishBtn: false
    }

    handleClick = () => {
        this.setState({
            closingGetBio: true
        }, () => {
            setTimeout(() => {
                this.props.handleAction()
            }, 5000)
        })
    }

    render() {
        return (
            <Bound>
                <div className="waitting">
                    <LoadingControl size="36px" loadingPage={false} />
                    <p className="title">{this.props.title}</p>
                    {this.state.closingGetBio ? (
                        <div className="closing-get-bio">
                            <LoadingControl size="25px" loadingPage={false} />
                            Vui lòng chờ...
                        </div>
                    ) : this.state.isVisibleFinishBtn ? (
                        <div className="action" onClick={this.handleClick}>
                            <p className="nameAction">
                                {this.props.actionName}
                            </p>
                        </div>
                    ) : (
                        <div className="action">
                            <p className="nameAction"></p>
                        </div>
                    )}
                </div>
            </Bound>
        )
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isVisibleFinishBtn: true })
        }, 1500)
    }
    
}
