import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

// image
import ic_close from "../../../images/ic_close_gray.svg";

// action
import { addReasonCusNotAuth, removeStatusWaitingVerifyFinger } from "../../../actions/counterAction";
import { closePopup } from "../../../actions/popupBioAction"

const Bound = styled.div`
	position: absolute;
	top: 37%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	border-radius: 10px;
	padding: 24px 20px;
	box-shadow: 0 0 20px #00000070;
	
	& * {
		font-family: "SVN-Gilroy";
	}

	& > img {
		position: absolute;
		right: 14px;
		top: 14px;

		&:hover {
			cursor: pointer;
		}
	}
	
	.title-popup {
		font-size: 14px;
		line-height: 15px;
		font-weight: bold;
	}

	.btn-submit {
	    margin-top: 23px;
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    .warning {
	      font-size: 14px;
	      font-weight: 500;
	      line-height: 1.25;
	      color: var(--vermillion);
	    }
	    button {
	      bottom: 24px;
	      right: 40px;
	      border: none;
	      outline: none;
	      width: 100px;
	      height: 36px;
	      border-radius: 18px;
	      background-color: #005993;
	      text-transform: uppercase;
	      font-family: SVN-Gilroy;
	      font-size: 13px;
	      font-weight: bold;
	      color: #ffffff;
	      cursor: pointer;
	    }
	}
`;

const ReasonNotAuth = styled.div`
  .select-reason {
    margin-top: 16px;
    .radio-field,
    .radio-field2 {
      display: flex;
      align-items: center;
      margin-top: 17px;
      position: relative;
      input[type="radio"] {
        position: absolute;
        height: 0;
        width: 0;
		display: none;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0px;
        height: 16px;
        width: 16px;
        border: solid 0.8px #c8c8c8;
        background: #ffffff;
		border-radius: 50%;
      }
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      input[type="radio"]:checked ~ .checkmark:after {
        display: block;
      }
      .checkmark::after {
        /* left: 3.9px;
        top: 0.3px;
        width: 6px;
        height: 9px;
        border: solid #d71249;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg); */
		top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: #d71249;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
      div {
        margin-left: 26px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        color: #222222;
      }
    }
  }
  .content-reason {
    margin-top: 16px;
    .title {
      font-size: 11px;
      font-weight: bold;
      line-height: 1.26;
      color: #005993;
      text-transform: uppercase;
      margin-bottom: 3px;
    }
    textarea {
      margin-top: 1px;
      resize: none;
      outline: none;
      width: 375px;
      height: 46px;
      border: solid 0.5px #c8c8c8;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 10px;
      line-height: 1.2;
      color: #222222;
    }
    textarea:disabled {
      background: #efefef;
      color: #222222;
    }
    textarea::placeholder {
      color: #222222;
      opacity: 0.25;
    }
  }
`;

const dataPopup = {
    title: "Lý do không quét vân tay",
    dataSelect: [
        "Khách hàng bị mất dấu vân tay",
        "Khách hàng không đồng ý quét vân tay",
        "Lý do khác"
    ],
    reportToKsv: "Báo cáo KSV"
}

class PopupFillReasonCusNotAuth extends Component {
    state = {
        cannotConfirm: false,
        enableTextArea: false,
        data: {
	        content: "",
	        otherReason: ""
      	},
    }
    render() {
        return (
            <Bound ref={ref => this.thatPopup = ref}>
                <img 
                    src={ic_close} 
                    alt="close-popup" 
                    onClick={this.props.onClosePopup}
                />
                <div className="title-popup">{dataPopup.title}</div>
                <ReasonNotAuth>
	              <div className="select-reason">
	                {dataPopup.dataSelect.map((reason, key) => (
	                  <label className="radio-field" key={key}>
	                    <input
	                      onClick={this.checkToOpenTextArea}
	                      type="radio"
	                      name="contentOtherReason"
	                      value={reason}
	                    />
	                    <span className="checkmark" />
	                    <div>{reason}</div>
	                  </label>
	                ))}
	                {/*
	                <label className="radio-field">
	                  <input
	                    onClick={this.checkToOpenTextArea}
	                    value="lý do khác"
	                    type="radio"
	                    name="contentOtherReason"
	                  />
	                  <span className="checkmark" />
	                  <div>Lý do khác</div>
	                </label>
	            	*/}
	              </div>
	              <div className="content-reason">
	                <div className="title">Nội dung</div>
	                <textarea
	                  onChange={e => this.otherReason(e)}
					  placeholder="Nhập nội dung...không quá 200 ký tự"
					  maxLength="200"
	                  //disabled={!this.state.enableTextArea}
	                />
	              </div>
	            </ReasonNotAuth>
	            <div className="btn-submit">
		          	<p className="warning">
			            {
			              this.state.cannotConfirm ?
			              	!this.state.data.content.trim()?
			              		"Vui lòng chọn lý do"
			                	:
			                	"Nội dung không được bỏ trống"
			                :
			                null
			            }
		          	</p>
		          	<button onClick={this.handleEventSubmit}>{dataPopup.reportToKsv}</button>
		        </div>
            </Bound>
        )
    }

    otherReason = e => {
	    let value = e.target.value;
	    this.setState({
	      	cannotConfirm: false,
	      	data: { 
	      		...this.state.data, 
	      		otherReason: value 
	      	}
	    });
    };
    checkToOpenTextArea = () => {
	    const lastRadio = this.thatPopup.querySelector(".radio-field:last-child input");
	    const content = this.thatPopup.querySelector(
	      'input[name="contentOtherReason"]:checked'
	    ).value;
	    this.setState({
	      	cannotConfirm: false,
	      	enableTextArea: lastRadio.checked,
	      	data: { 
		      	...this.state.data, 
		      	content, 
		      	// otherReason: "" 
	    	}
	    });
    };

    handleEventSubmit = () => {
        const {
	      	content,
	      	otherReason
	    } = this.state.data;

	    // const { enableTextArea } = this.state;

	    const isEmptyContent = !content.trim();
    	// const isEmptyContentOtherReason = enableTextArea && !otherReason.trim();
    	const isEmptyContentOtherReason = !otherReason.trim();

    	const cannotConfirm = isEmptyContent || isEmptyContentOtherReason;

    	if (cannotConfirm) {
	      this.setState({ 
	      	cannotConfirm
	      });
	      return;
	    }

	    const currentTicket = this.props.counterReducer.listCurrentTicket.find(item => item.TicketID === this.props.ticketId);


	    this.props.addReasonCusNotAuth(
	    	this.props.ticketId, 
	    	currentTicket.CustomerInfos && currentTicket.CustomerInfos.CustomerNumber, 
	    	content+"<br>Chi tiết: "+otherReason
	    )
	    this.props.onClosePopup();
        this.props.closePopup(1);
        this.props.removeStatusWaitingVerifyFinger(this.props.ticketId, true)
    }
}

const mapStateToProps = state => ({
    counterReducer: state.counterReducer
})

const mapDispatchToProps = dispatch => ({
    addReasonCusNotAuth: (ticketId, customerNumber, reasonText) => dispatch(addReasonCusNotAuth(ticketId, customerNumber, reasonText)),
    closePopup: (levelPopup) => dispatch(closePopup(levelPopup)),
    removeStatusWaitingVerifyFinger: (ticketId, isNotCallApi=false) => 
        dispatch(removeStatusWaitingVerifyFinger(ticketId, isNotCallApi))
})

export default connect(mapStateToProps, mapDispatchToProps)(PopupFillReasonCusNotAuth);