import React from 'react'
import { useDispatch } from 'react-redux'

// component
import PopupShowCustDetails from '.'

// action
import { openPopup } from '../../../../../actions/popupBioAction'

// data
import { listNameBiometric } from '../../../../../data/bioTypes'
import { popupBioTypes } from '../../../../../data/PopupTypes'
import statusBiometrics from '../../../../../data/statusBiometricWithTicket'

const PopupShowCustDetailsBl = ({
    data,
    handleClose
}) => {
    const dispatch = useDispatch()

    const {
        BioInfosType,
        CustomerType,
        CustomerInfos: {
            CustomerNumber,
            Name,
            FullNameVi,
            Gender,
            SegmentDesc,
            IdNumber,
            MobileNumber,
            IdIssueDate,
            Email,
            IdIssuePlace,
            Address
        },
        BioDatas,
        CurrentBioData,
        CompleteAt,
        Status
    } = data

    const cifs = {
        CustomerNumber,
        Name,
        FullNameVi,
        Gender,
        SegmentDesc,
        IdNumber,
        MobileNumber,
        IdIssueDate,
        Email,
        IdIssuePlace,
        Address,
        CustomerType
    }

    const bioDatas = (BioDatas || []).reduce((obj, bio) => ({
        ...obj,
        [bio.BioType]: {
            current: bio
        }
    }), {})
    const currentBioDatas = (CurrentBioData || []).reduce((obj, bio) => ({
        ...obj,
        [bio.BioType]: {
            before: bio
        }
    }), {})

    const bios = listNameBiometric
        .map(bioType => ({
            bioType,
            ...bioDatas[bioType],
            ...currentBioDatas[bioType]
        }))
        .filter(objBio => Object.values(objBio).length > 1) // remove bio not exist
        .reduce((obj, bio) => ({ ...obj, [bio.bioType]: bio }), {})

    const dataShowCustDetails = {
        bioInfosType: BioInfosType,
        status: statusBiometrics[Status],
        cifs,
        bios
    }

    const _handleShowHistory = () => {
        dispatch(openPopup(popupBioTypes.HistoryCustomer, 2, {
            custNum: CustomerNumber,
            lessThanCreateAt: CompleteAt
        }))
    }

    return (
        <PopupShowCustDetails
            data={dataShowCustDetails}
            handleClose={handleClose}
            handleShowHistory={_handleShowHistory}
        />
    )
}

export default PopupShowCustDetailsBl
