import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import moment from 'moment'
import addNotification, { Notifications } from 'react-push-notification'

import HeaderContainer from '../MainPage/Header/HeaderContainer'
// import MenuContainer from './Menu/MenuContainer';
import MainscreenContainer from './Mainscreen/MainscreenContainer'
import TimeoutContainer from './TimeoutContainer'
import ShowErrorConnectSocket from '../Popup/ShowErrorConnectSocket'

// socket
import { webSocketUrl } from '../../host'
import * as typeChannelSocket from '../../helper/socket/typeChannel'

// import action
import {
    enqueueWaitingTicket,
    dequeueWaitingTicket,
    getAllTicketWaiting,
    enqueueTicketMoveTo,
    verifyCustomerResult,
    finishWaitingEvalueate,
    setStatusSeenAuthOfTicket,
    removeStatusWaitingVerifyFinger,
    clearCustomerInfosInStore,
    updateTicketInfos,
    addBiometricExistFromCustomerInfos,
    getInfoCustomer,
} from '../../actions/counterAction'
import {
    setBiometricData,
    removeStatusWaitingBioData,
    setDuplicateBioFace,
    setStatusBiometricWithTicket,
    removeDataBioOfTicketInStore,
} from '../../actions/biometricAction'
import { addNewNotify } from '../../actions/notifyAction'
import { openPopup, closePopup } from '../../actions/popupBioAction'

// import data
import * as typeRequestBios from '../../actions/typeRequestBio'
import { typeNotice } from '../../data/data'
import { FACE } from '../../data/bioTypes'
import { popupBioTypes } from '../../data/PopupTypes'
import { TELLER } from '../../data/userRole'
import { typeStatusBiometrics } from '../../data/statusBiometricWithTicket'
import verifyFingerStatusTypes from '../../data/verifyFingerStatusTypes'
import { GET_ALL_TICKET_WAITING } from '../../actions/types'
import { lstStatusDevice } from '../../reducers/commonReducer'

// image
import iconNotificationFailed from '../../images/ic_counter_notification_close.svg'

// helper
import {
    openPopupNotifyErrorLv3,
    openPopupShowErrorExistedDataBiometricFace,
} from '../../helper/actionOpenPopup'

// action
import {
    alertConnectingSocket,
    alertFailedToConnectSocket,
    alertSuccessToConnectSocket,
    setStatusDevice,
} from '../../actions/commonAction'
import { dispatchDataUsersFromTablet, getUserByFingerImg, setLoadingGetUserByFinger, setLoadingGetUserFace } from '../../actions/queriesAction'
import { getListDataSentSupervisor } from '../../actions/inBioCollector/listDataSentSupervisorAction'

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
`

class MainPage extends Component {
    state = {
        errorSocket: false,
    }
    socket = {}

    mainPageRef = React.createRef(null)

    _handleCtBioChanged = async (data) => {
        // console.log(data)
        const currentTicket = this.props.counterReducer.listCurrentTicket.find(
            (item) =>
                item.CustomerInfos &&
                item.CustomerInfos.CustomerNumber === data.CustomerNumber
        )
        // debugger;
        if (!currentTicket) return

        const {
            TicketID,
            dataWasAuthen: { BiometricFinger },
        } = currentTicket

        // update ticket
        await this.props.updateTicketInfos(TicketID, data)
        // remove bio of ticket in store
        await this.props.removeDataBioOfTicketInStore(TicketID)
        // get cifs
        await this.props.getInfoCustomer(data.CounterID, data.CustomerNumber)
        // update biometric of ticket
        const { customerInfo } = this.props.counterReducer
        const { BioInfos } = customerInfo
        // console.log(BioInfos)
        await this.props.addBiometricExistFromCustomerInfos(BioInfos, TicketID)
        // clearCustomerInfos
        await this.props.clearCustomerInfosInStore()

        if (data.BiometricActive !== '1') return
        if (BiometricFinger === '2' && data.BiometricFinger === '1') {
            this.props.openPopup(popupBioTypes.CheckCustomerActions, 1, {
                ticketId: TicketID,
                isFingerHanging: false,
            })
            return
        }
        // if (data.BiometricFinger === '2') {
        //     this.props.openPopup(popupBioTypes.CheckCustomerActions, 1, {
        //         ticketId: TicketID,
        //         isFingerHanging: true,
        //     })
        //     return
        // }
    }

    render() {
        const { dataLogin } = this.props.counterReducer
        const { Roles } = dataLogin

        return (
            <>
                {Roles.includes(TELLER) && (
                    <TimeoutContainer target={this.mainPageRef.current} />
                )}
                <Notifications />
                <Bound ref={this.mainPageRef}>
                    <HeaderContainer
                    //isLogout={this.props.isLogout}
                    //user={this.props.user}
                    //showNoticePopup={this.props.showNoticePopup}
                    // closePopup={this.props.closePopup}
                    //counterReducer={this.props.counterReducer}
                    ////logout
                    //tellerLogoutCounter={this.props.tellerLogoutCounter}
                    //showPopupLogout={this.props.showPopupLogout}
                    />
                    {/* <MenuContainer /> */}
                    <MainscreenContainer
                    //counter={this.props.counter}
                    //showUserManagePopup={this.props.showUserManagePopup}
                    //showPassTicketPopup={this.props.showPassTicketPopup}
                    //showFingerPrintInfoPopup={() => this.props.showFingerPrintInfoPopup()}
                    //showPopupCheckCustomer={() => this.props.showPopupCheckCustomer()}
                    //counterReducer={this.props.counterReducer}
                    // showPopupMissCall={this.props.showPopupMissCall}
                    />
                    {this.state.errorSocket && <ShowErrorConnectSocket />}
                </Bound>
            </>
        )
    }

    componentDidMount() {
        // addNotification({
        //     title: 'Yêu cầu phê duyệt',
        //     message: 'Dữ liều sinh trắc học',
        //     duration: 10000,
        //     native: true // when using native, your OS will handle theming.
        // })
        // debugger;
        // this.props.getAllTicketWaiting(
        //     this.props.counterReducer.dataLogin.CounterID,
        //     this.props.counterReducer.dataConnectDesktop.OfficeID
        // )
        // if (this.props.counterReducer.dataLogin.Role === INBIOCOLLECTOR) {
        //     this.props.createFakeTicket();
        //     const enrollId = localStorage.getItem("enrollId");

        //     if (enrollId){
        //         this.props.setEnrollIdCollector(enrollId);
        //         this.props.getCurrentBioEnrollCollector(
        //             this.props.counterReducer.dataConnectDesktop.CounterID,
        //             enrollId
        //         )
        //     }

        // }
        // const isReConnect = this.isReConnect = false;
        this.isReConnect = false
        this.connectWebSocket()
        clearInterval(this.timoutReConnectSocket)
    }

    connectWebSocket = async () => {
        const that = this
        await this.props.alertConnectingSocket()
        if (!this.props.counterReducer.dataLogin.Token) return
        const token = this.props.counterReducer.dataLogin.Token
        let socket = {}
        try {
            socket = this.socket = new WebSocket(
                `${webSocketUrl}?token=${token}`
            )
            if (process.env.NODE_ENV === "development")
                window.counterSocketDev = socket
        } catch (err) {
            await this.props.alertFailedToConnectSocket()
            this.setState({ errorSocket: true })
            console.log('socket died before connect:')
            console.log(err)
        }
        socket.onopen = function (e) {
            this.isReConnect = false
            that.props.alertSuccessToConnectSocket()
            clearInterval(that.timoutReConnectSocket)
            console.log('[open] Connection established, send -> server')
            if (process.env.NODE_ENV === "development") return;
            heartbeat()
        }

        socket.onmessage = function (event) {
            console.log(`SOCKET: ${event.data} <- server`)
            // if(typeof(event.data) === 'string') return;
            const res = JSON.parse(event.data)
            // console.log("SOCKET:", res)
            const { Data: data } = res
            switch (res.Event) {
            case typeChannelSocket.NEWTICKET: {
                // that.props.enqueueWaitingTicket(data);
                const counterId =
                        that.props.counterReducer.dataConnectDesktop.CounterID
                const officeId =
                        that.props.counterReducer.dataConnectDesktop.OfficeID
                const isTeller = that.props.counterReducer.dataLogin.Roles.includes(
                    TELLER
                )
                isTeller &&
                        that.props.getAllTicketWaiting(counterId, officeId)
                break
            }
            case typeChannelSocket.GETTICKET: {
                // that.props.dequeueWaitingTicket(data.TicketID);
                const counterId =
                        that.props.counterReducer.dataConnectDesktop.CounterID
                const officeId =
                        that.props.counterReducer.dataConnectDesktop.OfficeID
                const isTeller = that.props.counterReducer.dataLogin.Roles.includes(
                    TELLER
                )
                isTeller &&
                        that.props.getAllTicketWaiting(counterId, officeId)
                break
            }
            case typeChannelSocket.CT_MOVE_TICKET: {
                // that.props.enqueueTicketMoveTo(data);
                const counterId =
                        that.props.counterReducer.dataConnectDesktop.CounterID
                const officeId =
                        that.props.counterReducer.dataConnectDesktop.OfficeID
                const isTeller = that.props.counterReducer.dataLogin.Roles.includes(
                    TELLER
                )
                isTeller &&
                        that.props.getAllTicketWaiting(counterId, officeId)
                that.props.addNewNotify({
                    type: typeNotice.passTicket,
                    name: data.TellerName,
                    counterNum: data.CounterNum,
                    content: data.MoveNote,
                    time: moment(data.CreateAt).format('HH:mm, DD/MM/YYYY'),
                    ticketNumber: data.Number,
                })
                break
            }
            case typeChannelSocket.CT_BIO_DATA: {
                const { enrollId: lstEnrollId } = that.props.counterReducer
                const { EnrollID: enrollId } = data
                const ticketId = Object.keys(lstEnrollId).find(
                    (key) => lstEnrollId[key] === enrollId
                )

                if (!ticketId) break

                that.props.removeStatusWaitingBioData(ticketId, true)
                that.props.setBiometricData(
                    data.BioID,
                    ticketId,
                    data.BioType,
                    typeRequestBios.REGISTER
                )
                break
            }
            case typeChannelSocket.CT_CONFIRM_BIO: {
                const { enrollId: lstEnrollId } = that.props.counterReducer
                const {
                    EnrollID: enrollId,
                    DuplicateFaces: duplicateFaces,
                } = data
                const ticketId = Object.keys(lstEnrollId).find(
                    (key) => lstEnrollId[key] === enrollId
                )
                if (!ticketId) break
                const currentTicket = that.props.counterReducer.listCurrentTicket.find(
                    (item) => item.TicketID === ticketId
                )
                if (!currentTicket) break
                const { CustomerInfos } = currentTicket
                if (!CustomerInfos) break
                const { CustomerNumber } = CustomerInfos

                that.props.setBiometricData(
                    data.Code,
                    ticketId,
                    data.BioType,
                    typeRequestBios.CONFIRM
                )
                if (data.BioType === 'FACE') {
                    that.props.setDuplicateBioFace(
                        ticketId,
                        duplicateFaces || []
                    )
                    const tmpDuplicateFaces =
                            (!!duplicateFaces &&
                                duplicateFaces.filter(
                                    (item) =>
                                        item.CustomerNumber !== CustomerNumber
                                )) ||
                            []
                    if (tmpDuplicateFaces.length) {
                        const bioOfTicket =
                                that.props.biometricReducer[ticketId]
                        const faceOfTicket =
                                !!bioOfTicket && bioOfTicket[FACE]
                        const faceExistBefore =
                                !!faceOfTicket &&
                                Object.keys(faceOfTicket).includes(
                                    typeRequestBios.VALIDATE_DATA
                                )
                        if (!faceExistBefore)
                            openPopupShowErrorExistedDataBiometricFace(
                                that,
                                {
                                    typeExistedData: 'FACE',
                                    data: duplicateFaces || [],
                                    ticketId,
                                },
                                'connectWebSocket - MainPage.js'
                            )
                    }
                }
                that.props.removeStatusWaitingBioData(ticketId, true)
                break
            }
            case typeChannelSocket.CT_VF: {
                that.props.verifyCustomerResult(data)
                break
            }
            case typeChannelSocket.CT_PENBIO: {
                that.props.setStatusBiometricWithTicket(data)

                if (data.PendingStatus === typeStatusBiometrics.ACCEPT)
                    addNotification({
                        title: 'Phê duyệt sinh trắc học',
                        message: 'KSV đã Đồng ý yêu cầu phê duyệt STH',
                        native: true,
                        icon: 'favicon.ico',
                    })
                if (data.PendingStatus === typeStatusBiometrics.REJECT)
                    addNotification({
                        title: 'Phê duyệt sinh trắc học',
                        message: 'KSV đã Từ chối yêu cầu phê duyệt STH',
                        native: true,
                        icon: 'favicon.ico',
                    })

                if (
                    that.props.counterReducer.dataLogin.Roles.includes(
                        TELLER
                    ) &&
                        data.PendingStatus === typeStatusBiometrics.ACCEPT &&
                        data.ReVerify
                ) {
                    // debugger;
                    const lstEnrollId = that.props.counterReducer.enrollId
                    const ticketId = Object.keys(lstEnrollId).find(
                        (item) => lstEnrollId[item] === data.PendingBioID
                    )
                    if (!ticketId) break
                    const currentTicket = that.props.counterReducer.listCurrentTicket.find(
                        (item) => item.TicketID === ticketId
                    )
                    if (!currentTicket) break
                    // if(currentTicket.IsVerifyFinger) break;
                    if (
                        currentTicket.VerifyFingerStatus !==
                            verifyFingerStatusTypes.NV
                    )
                        break
                    that.props.openPopup(
                        popupBioTypes.CheckCustomerActions,
                        1,
                        {
                            ticketId,
                            isFingerHanging: false,
                        }
                    )
                }
                // debugger;
                break
            }
            case typeChannelSocket.CT_COMPLETE_RS:
                // that.props.finishWaitingEvalueate(data.TicketID);
                that.props.closePopup(1)
                break
            case typeChannelSocket.CT_VF_SEEN:
                addNotification({
                    title: 'Xác thực khách hàng',
                    message: 'KSV Đã xem yêu cầu xác thực vân tay',
                    native: true,
                    icon: 'favicon.ico',
                })
                that.props.setStatusSeenAuthOfTicket(data.TicketID)
                break
            case typeChannelSocket.TB_STATUS_CODE: {
                // debugger;
                switch (data.StatusCode) {
                case 1: {
                    const ticketId = Object.keys(
                        that.props.biometricReducer
                    ).find(
                        (item) =>
                            !!that.props.biometricReducer[item]
                                .waitingConfirm
                    )
                    if (!ticketId) break
                    that.props.removeStatusWaitingBioData(
                        ticketId,
                        true
                    )
                    const dataTranferToPopup = {
                        image_noti: iconNotificationFailed,
                        title: 'Lấy mẫu thất bại',
                        widthPopup: '190px',
                        titleConfirm: 'Xác nhận',
                    }
                    openPopupNotifyErrorLv3(
                        that,
                        dataTranferToPopup,
                        'connectWebSocket - MainPage.js'
                    )
                    break
                }
                case 2: {
                    const ticketId = Object.keys(
                        that.props.biometricReducer
                    ).find(
                        (item) =>
                            !!that.props.biometricReducer[item]
                                .waitingConfirm
                    )
                    if (ticketId) {
                        that.props.removeStatusWaitingBioData(
                            ticketId,
                            true
                        )
                    }

                    const { verifyCustomer } = that.props.counterReducer
                    const ticketWaitingVerifyCus = Object.keys(
                        verifyCustomer
                    ).find((item) => verifyCustomer[item].waiting)

                    if (ticketWaitingVerifyCus) {
                        that.props.removeStatusWaitingVerifyFinger(
                            ticketWaitingVerifyCus,
                            true
                        )
                    }

                    const dataTranferToPopup = {
                        image_noti: iconNotificationFailed,
                        title: 'Xác thực thất bại',
                        widthPopup: '190px',
                        titleConfirm: 'Xác nhận',
                    }
                    openPopupNotifyErrorLv3(
                        that,
                        dataTranferToPopup,
                        'connectWebSocket - MainPage.js'
                    )
                    break
                }
                case 3: {
                    that.props.setLoadingGetUserFace(false)
                    that.props.setLoadingGetUserByFinger(false)
                    break;
                }
                case 100: {
                    that.props.setStatusDevice(lstStatusDevice.RUNNING)
                    that.props.closePopup(3)
                    break
                }
                default:
                    break
                }
                break
            }
            case typeChannelSocket.CT_BIO_CHANGED: {
                that._handleCtBioChanged(data)
                break
            }
            case typeChannelSocket.PONG: {
                console.log(res)
                that.setState({ isWatingSocketPong: false })
                break
            }
            case typeChannelSocket.CT_MISS_TICKET: {
                const counterId =
                        that.props.counterReducer.dataConnectDesktop.CounterID
                const officeId =
                        that.props.counterReducer.dataConnectDesktop.OfficeID
                const isTeller = that.props.counterReducer.dataLogin.Roles.includes(
                    TELLER
                )
                isTeller &&
                        that.props.getAllTicketWaiting(counterId, officeId)
                break
            }
            case typeChannelSocket.CT_INFOS_CHANGE: {
                const { CustomerNumber } = data
                const changeTicket = that.props.counterReducer.listCurrentTicket.find(
                    (item) =>
                        item.CustomerInfos &&
                            item.CustomerInfos.CustomerNumber === CustomerNumber
                )
                if (!changeTicket) return
                that.props.openPopup(popupBioTypes.confirmReLoadPage, 3, {
                    title: (
                        <>
                            <div>Thông tin của khách hàng</div>
                            <div className="cusInfos">
                                {`có số CIF ${CustomerNumber} - `}{' '}
                                <span
                                    title={changeTicket.CustomerInfos.Name}
                                    className="cusName"
                                >
                                    {changeTicket.CustomerInfos.Name}
                                </span>
                            </div>
                            <div>có sự thay đổi từ hệ thống,</div>
                            <div>
                                    nhấn nút Refresh bên dưới để cập nhật lại
                                    thông tin
                            </div>
                        </>
                    ),
                    titleConfirm: 'Refresh',
                })
                break
            }
            case typeChannelSocket.CT_FIND_BIO: {
                console.log(data)
                that.props.dispatchDataUsersFromTablet(data)
                break;
            }
            case typeChannelSocket.CT_FIND_FINGER: {
                that.props.getUserByFingerImg(data)
                break;
            }
            case typeChannelSocket.COMPLETED_REVIEW:
            case typeChannelSocket.CT_BIOS_CHANGE: {
                const { filters } = that.props.listDataSentSupervisor
                that.props.getListDataSentSupervisor(filters)
                break;
            }
            default:
                break
            }
        }

        socket.onclose = function (event) {
            console.log(event)
            if (event.wasClean && event.code !== 4000) {
                console.log(
                    `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
                )
            } else {
                // e.g. server process killed or network down
                // event.code is usually 1006 in this case
                // console.log('[close] Connection died');
                if (process.env.NODE_ENV === "development") return;

                if (!this.isReConnect) {
                    that.props.alertFailedToConnectSocket()
                    that.timoutReConnectSocket = setTimeout(function () {
                        // console.clear();
                        console.log('websocket was died. Retry connecting...')
                        that.connectWebSocket()
                    }, 5000)
                    this.isReConnect = true
                }
            }
            // that.connectWebSocket()
        }

        socket.onerror = function (error) {
            console.log(`[error] ${error.message}`)
            console.dir(error)
            socket.close()
        }

        function heartbeat() {
            if (that.state.isWatingSocketPong) {
                // console.log('errror')
                that.props.alertFailedToConnectSocket()
                that.setState({ isWatingSocketPong: false }, () =>
                    socket.close(4000)
                )
                return
            }

            that.setState(
                {
                    isWatingSocketPong: true,
                },
                () => {
                    if (!socket) return
                    if (socket.readyState !== 1) return
                    socket.send('ping')
                    setTimeout(heartbeat, 2000)
                }
            )
        }
        // heartbeat()
    }
    componentWillUnmount() {
        // console.log("unmount")
        this.socket.close()
    }
}

const mapStateToProps = (state) => ({
    counterReducer: state.counterReducer,
    biometricReducer: state.biometricReducer,
    listDataSentSupervisor: state.inBioCollector.listDataSentSupervisor
})

const mapDispatchToProps = (dispatch) => ({
    openPopup: (typePopup, levelPopup, data) =>
        dispatch(openPopup(typePopup, levelPopup, data)),
    closePopup: (levelPopup) => dispatch(closePopup(levelPopup)),
    enqueueWaitingTicket: (ticket) => dispatch(enqueueWaitingTicket(ticket)),
    dequeueWaitingTicket: (ticketId) =>
        dispatch(dequeueWaitingTicket(ticketId)),
    getAllTicketWaiting: (counterId, officeId) =>
        dispatch(getAllTicketWaiting(counterId, officeId)),
    removeStatusWaitingBioData: (ticketID, justDispatch = false) =>
        dispatch(removeStatusWaitingBioData(ticketID, justDispatch)),
    setBiometricData: (bioId, ticketId, bioType, typeRequestBio) =>
        dispatch(setBiometricData(bioId, ticketId, bioType, typeRequestBio)),
    setDuplicateBioFace: (ticketId, duplicateFaces) =>
        dispatch(setDuplicateBioFace(ticketId, duplicateFaces)),
    enqueueTicketMoveTo: (ticket) => dispatch(enqueueTicketMoveTo(ticket)),
    addNewNotify: (data) => dispatch(addNewNotify(data)),
    verifyCustomerResult: (data) => dispatch(verifyCustomerResult(data)),
    setStatusBiometricWithTicket: (data) =>
        dispatch(setStatusBiometricWithTicket(data)),
    finishWaitingEvalueate: (ticketId) =>
        dispatch(finishWaitingEvalueate(ticketId)),
    setStatusSeenAuthOfTicket: (ticketId) =>
        dispatch(setStatusSeenAuthOfTicket(ticketId)),
    removeStatusWaitingVerifyFinger: (ticketId, isNotCallApi = false) =>
        dispatch(removeStatusWaitingVerifyFinger(ticketId, isNotCallApi)),
    updateTicketInfos: (ticketId, dataChanged) =>
        dispatch(updateTicketInfos(ticketId, dataChanged)),
    removeDataBioOfTicketInStore: (ticketId) =>
        dispatch(removeDataBioOfTicketInStore(ticketId)),
    getInfoCustomer: (counterId, customerNumber, typeSearch = 0) =>
        dispatch(getInfoCustomer(counterId, customerNumber, typeSearch)),
    addBiometricExistFromCustomerInfos: (BioInfos, ticketId) =>
        dispatch(addBiometricExistFromCustomerInfos(BioInfos, ticketId)),
    clearCustomerInfosInStore: () => dispatch(clearCustomerInfosInStore()),
    getTicketWatingFake: (data) =>
        dispatch({
            type: GET_ALL_TICKET_WAITING,
            listTicket: data.ListTicket ? data.ListTicket : [],
            listMissCalled: data.ListMissCalled || [],
            listMoveTo: data.ListMoveTo || [],
            listVipTicket: data.ListVIPTicket || [],
        }),
    alertConnectingSocket: () => dispatch(alertConnectingSocket()),
    alertFailedToConnectSocket: () => dispatch(alertFailedToConnectSocket()),
    alertSuccessToConnectSocket: () => dispatch(alertSuccessToConnectSocket()),
    setStatusDevice: (status) => dispatch(setStatusDevice(status)),
    dispatchDataUsersFromTablet: img => dispatch(dispatchDataUsersFromTablet(img)),
    getListDataSentSupervisor: (dataFilter) => dispatch(getListDataSentSupervisor(dataFilter)),
    getUserByFingerImg: data => dispatch(getUserByFingerImg(data)),
    setLoadingGetUserFace: loading => dispatch(setLoadingGetUserFace(loading)),
    setLoadingGetUserByFinger: loading => dispatch(setLoadingGetUserByFinger(loading)),

    // createFakeTicket: () => dispatch(createFakeTicket()),
    // setEnrollIdCollector: (enrollId) => dispatch(setEnrollId(enrollId)),
    // getCurrentBioEnrollCollector: (counterId, enrollId) => dispatch(getCurrentBioEnrollCollector(counterId, enrollId))
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(MainPage)
