import Axios from 'axios'

import * as types from './types'
import { host } from '../../host'
import * as typeCodeResponses from '../typeCodeResponses'
import catchErrAction from '../../helper/catchErrAction'

// action
import { addLoading, removeLoading } from '../commonAction'

const apiGetListDataSentSupervisor = host + 'inBioCollector/get-current-bios'
const apiLoadPendingBioToCounter = host + 'inBioCollector/loadPendingBioToCounter'
const apiMovePendingBioToPendingStack = host + 'inBioCollector/removePendingBioFromCounter'

export const getListDataSentSupervisor = ({ TellerName, SupervisorName, CustomerID, Status } = {}) => async (dispatch, getState) => {
    dispatch(addLoading())
    const store = getState()
    const { CounterID } = store.counterReducer.dataConnectDesktop

    const dataFilters = {
        CounterID,
        TellerName,
        SupervisorName,
        CustomerID,
        Status
    }

    const dataRequest = JSON.stringify(dataFilters)

    try {
        const res = await Axios.post(apiGetListDataSentSupervisor, dataRequest)

        const { data } = res;

        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            dispatch({
                type: types.GET_LIST_DATA_SENT_SUPERVISOR,
                payload: {
                    listData: data.PendingBio || [],
                    filters: dataFilters
                }
            })
        }
    }
    catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.GET_LIST_DATA_SENT_SUPERVISOR_ERR,
            false
        )
    }
    dispatch(removeLoading())
}

export const loadPendingBioToCounter = (ID, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        ID
    })

    try {
        const res = await Axios.post(apiLoadPendingBioToCounter, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            callbackSuccess()
        }
    }
    catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.LOAD_PENDING_BIO_TO_COUNTER_ERR,
            false
        )
    }
}

export const movePendingBioToPendingStack = (ID, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        ID
    })

    try {
        const res = await Axios.post(apiMovePendingBioToPendingStack, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === typeCodeResponses.CODE_SUCCESS
        if (isSuccess) {
            callbackSuccess()
        }
    }
    catch (err) {
        catchErrAction(
            err,
            dispatch,
            types.MOVE_PENDING_BIO_TO_PENDING_STACK_ERR,
            false
        )
    }
}