import React, { Component } from 'react';

// component
import UserInformation from './UserInformation';
import UserBiometric from './UserBiometric';
// import ActionControlCheckBio from './Control/ActionControlCheckBio';

// image
import ic_close from '../../../../../images/ic_close_gray.svg'
import ic_history from '../../../../../images/ic_history.svg'

// constant
import {
    Status,
    ACCEPT,
    REJECT,
    PENDING,
    THUMB_LEFT,
    dataFingerPrint,
    THUMB_RIGHT,
    INDEX_LEFT,
    INDEX_RIGHT,
    MIDDLE_LEFT,
    MIDDLE_RIGHT,
    RING_LEFT,
    RING_RIGHT,
    PINKY_LEFT,
    PINKY_RIGHT,
    NR,
    FACE,
    DELETE,
    REGISTER,
    UPDATE,
    customType,
    ISACTIVE,
    ACTIVE,
    BioInfosType,
    HANG,
    titleUFace,
    titleRemoveFace,
    titleCloseActiveFace,
    statusS,
    statusU,
    statusR,
    statusN,
    titleDFace
} from '../../../../../data/infosType';

// styles
import { Bound, TabLayout } from './index.styles';




var _ = require('lodash');
const pageSize = 100000000000000000;
export default class PopupDetailBiometric extends Component {
    state = {
        changeTab: 1,
        dataUser: this.props.dataUser ? this.props.dataUser : [],
        dataCurrent: this.props.dataCurrent ? this.props.dataCurrent : [],
    }

    changeTab = (changeTab) => {
        this.setState({
            changeTab
        })
    }

    // showHistoryBio = () => {
    //     const { dataUser } = this.state;
    //     this.props.showPopupInBiometric(
    //         this.props.getBioHistoryList(dataUser.CustomerInfos.CustomerNumber, pageSize, 1, dataUser.CompleteAt)
    //         , 'BiometricHistory')
    // }

    showMatchFace = () => {
        const { BioDatas, CurrentBioData, BioInfosType } = this.state.dataUser
        let bioFace = BioDatas.find(item => item.BioType && item.BioType === FACE)
        let duplicateFace = bioFace?.DuplicateFaces;

        this.props.showPopupInBiometric({
            // BioType: informBiometric.filter(item => item.BioType),
            DuplicateFaces: duplicateFace,
            Name: this.state.dataUser.CustomerInfos.Name,
            DuplicateFaceNote: this.state.dataUser.DuplicateFaceNote,
            CustomerCif: this.state.dataUser.CustomerInfos.CustomerNumber
        }, 'MatchFace')
    }

    showFaceImage = (biotype) => {
        // debugger;
        let dataIsActive, dataIsConfirmFinger, titleStatus, bioUpdate, textFaceRegister, bioActionTpe, enrollFaceR, isActiveFaceR, textStatus, dataRemoveNote, RemoveNote
        let { BioDatas, CurrentBioData, BioInfosType } = this.state.dataUser
        BioDatas = JSON.parse(JSON.stringify(BioDatas))
        CurrentBioData = JSON.parse(JSON.stringify(CurrentBioData))

        let dataBioDatas = BioDatas.map(item => item)

        let dataCurrent = this.state.dataCurrent.map(item => item)

        const cloneBioDatas = _.cloneDeep(BioDatas);
        if (CurrentBioData) {
            bioUpdate = BioInfosType === UPDATE && CurrentBioData.map(item => {
                const indexBio = _.findIndex(cloneBioDatas, i => i.BioType === item.BioType);
                if (indexBio < 0) return item;
                const dataBio = cloneBioDatas[indexBio];
                // console.log(dataBio);
                if (dataBio.BioActionType !== "UPDATE" && dataBio.BioActionType !== "REMOVE") {
                    return item;
                }
                //  console.log(y)
                let isSame = 0
                for (let y in dataBio) {

                    if (dataBio.BioActionType === undefined && item.BioActionType === FACE) {
                        titleStatus = titleCloseActiveFace
                        textStatus = statusN
                    }
                    if ((dataBio[y] !== null && typeof (dataBio[y])) || (dataBio[y] == null && !item[y])) {
                        if (dataBio.BioActionType === undefined && item.BioActionType === FACE) {
                            titleStatus = titleCloseActiveFace
                            textStatus = statusN
                        }
                        if (y === "IsConfirmFinger" && dataBio.IsConfirmFinger === item.IsConfirmFinger) {
                            isSame = 1;
                            // debugger
                        }
                        if (y === "IsActive" && dataBio.IsActive === item.IsActive && isSame === 1) {
                            isSame = 2;
                            // debugger
                        }
                        if (isSame === 2) {
                            if ((dataBio.BioActionType === DELETE || dataBio.BioActionType === UPDATE) && (item.BioActionType === REGISTER || item.BioActionType === UPDATE || item.BioActionType === '') &&
                                (dataBio.EnrollData !== item.EnrollData && dataBio.EnrollData !== null)) {
                                // debugger
                                isSame = 0;
                                if (dataBio.BioActionType === REGISTER) {
                                    titleStatus = titleCloseActiveFace
                                    textStatus = statusN
                                } else if (dataBio.BioActionType === UPDATE) {
                                    if (dataBio.EnrollData) {
                                        titleStatus = titleUFace
                                        textStatus = statusU
                                    } else {
                                        titleStatus = titleCloseActiveFace
                                        textStatus = statusS
                                    }
                                } else if (dataBio.BioActionType === DELETE) {
                                    // titleStatus = titleUFace
                                    // textStatus = statusU
                                    titleStatus = titleDFace
                                    textStatus = statusS
                                }
                                item[y] = dataBio[y]
                                // debugger
                                // titleStatus = titleUFace
                                // textStatus = statusU
                                continue
                            } else {
                                // debugger
                                isSame = 0;
                                dataBio[y] = item[y]
                                item.matchCheck = true;
                                if (item.matchCheck) {
                                    titleStatus = titleCloseActiveFace
                                    textStatus = statusN
                                }
                                // titleStatus = titleCloseActiveFace
                                // textStatus = statusN
                                break
                            }
                        }

                        if (dataBio.BioType === FACE && item.BioType === FACE) {
                            if (dataBio.BioActionType === REGISTER) {
                                titleStatus = titleCloseActiveFace
                                textStatus = statusN
                            } else if (dataBio.BioActionType === UPDATE) {
                                if (dataBio.EnrollData) {
                                    titleStatus = titleUFace
                                    textStatus = statusU
                                } else {
                                    titleStatus = titleCloseActiveFace
                                    textStatus = statusS
                                }
                            } else if (dataBio.BioActionType === DELETE) {
                                // titleStatus = titleUFace
                                // textStatus = statusU
                                titleStatus = titleDFace
                                textStatus = statusS
                            }
                            item[y] = dataBio[y]
                            // titleStatus = titleUFace
                            // textStatus = statusU
                        } else {
                            titleStatus = titleCloseActiveFace
                            textStatus = statusN
                        }
                    }
                }
                cloneBioDatas.splice(indexBio, 1);
                return item;
            })
        } else {
            bioUpdate = BioDatas
            bioUpdate.forEach(item => {
                if (item.BioActionType === REGISTER && item.BioType === FACE) {
                    textFaceRegister = 'FaceRegister'
                    enrollFaceR = item.EnrollData
                    isActiveFaceR = item.IsActive
                    titleStatus = titleCloseActiveFace
                    textStatus = statusN
                    bioActionTpe = item.BioActionType
                }
            })
        }


        // let dataBioUpdate = dataUser.BioInfosType === UPDATE && bioUpdate.concat(cloneBioDatas)
        // let informFinger = dataUser.BioInfosType === UPDATE ? dataBioUpdate : dataBioDatas
        // console.log('as', dataCurrent)
        let informFinger = BioInfosType === UPDATE ? dataCurrent : dataBioDatas
        // let bioTypeFaceUpdate = BioInfosType === UPDATE && dataBioUpdate.find(item => item.BioType === FACE)
        let bioTypeFaceUpdate = BioInfosType === UPDATE && dataCurrent.find(item => item.BioType === FACE)
        let bioTypeFaceRegister = cloneBioDatas.find(item => item.BioType === FACE)

        let faceBio = BioInfosType === UPDATE ? bioTypeFaceUpdate : bioTypeFaceRegister
        informFinger.forEach(item => {
            if (item.BioType === biotype.BioType) {
                dataIsConfirmFinger = item.IsConfirmFinger
                dataIsActive = item.IsActive

            }
        })
        let checkIsActive
        if (BioInfosType !== REGISTER && CurrentBioData.length === 0) {
            checkIsActive = bioTypeFaceRegister.IsActive
        } else {
            checkIsActive = dataIsActive
        }
        BioDatas.forEach(item => {
            if (item.BioActionType === DELETE && item.BioType === FACE) {
                dataRemoveNote = item.RemoveNote
            }
        })

        this.props.showPopupInBiometric(
            {
                Face: textFaceRegister === 'FaceRegister' ? enrollFaceR && enrollFaceR : (faceBio ? faceBio.EnrollData : (bioTypeFaceRegister && bioTypeFaceRegister.EnrollData)),
                IsConfirmFinger: dataIsConfirmFinger,
                IsActive: textFaceRegister === 'FaceRegister' ? isActiveFaceR : checkIsActive,
                ID: this.state.dataUser.ID,
                Note: this.state.dataUser.Note,
                Title: titleStatus,
                StatusFace: 'FaceRegister',
                TextStatus: textStatus,
                RemoveNote: dataRemoveNote,
                BioActionType: textFaceRegister === 'FaceRegister' ? bioActionTpe && bioActionTpe : faceBio && faceBio.BioActionType
            }, 'ShowFaceImage', this.state.dataCurrent)
    }

    showFaceUpdateImage = (biotype) => {
        let dataIsActive, dataIsConfirmFinger, titleStatus, bioUpdate, textFaceRegister, enrollFaceR, isActiveFaceR, textStatus, dataRemoveNote, RemoveNote

        // let { dataUser } = this.state
        let { BioDatas, BioInfosType, CurrentBioData } = this.state.dataUser
        BioDatas = JSON.parse(JSON.stringify(BioDatas))
        CurrentBioData = JSON.parse(JSON.stringify(CurrentBioData))

        let dataBioDatas = BioDatas.map(item => item)
        let dataCurrent = this.state.dataCurrent.map(item => item)

        const cloneBioDatas = _.cloneDeep(BioDatas);
        if (CurrentBioData) {
            bioUpdate = BioInfosType === UPDATE && CurrentBioData.map(item => {
                const indexBio = _.findIndex(cloneBioDatas, i => i.BioType === item.BioType);
                if (indexBio < 0) return item;
                const dataBio = cloneBioDatas[indexBio];
                // console.log(dataBio);
                if (dataBio.BioActionType !== "UPDATE" && dataBio.BioActionType !== "REMOVE") {
                    return item;
                }
                let isSame = 0
                for (let y in dataBio) {
                    if (dataBio.BioActionType === undefined && item.BioActionType === FACE) {
                        titleStatus = titleCloseActiveFace
                        textStatus = statusN
                    }
                    if ((dataBio[y] !== null && typeof (dataBio[y])) || (dataBio[y] == null && !item[y])) {
                        if (dataBio.BioActionType === undefined && item.BioActionType === FACE) {
                            titleStatus = titleCloseActiveFace
                            textStatus = statusN
                        }
                        if (y === "IsConfirmFinger" && dataBio.IsConfirmFinger === item.IsConfirmFinger) {
                            isSame = 1;
                            // debugger
                        }
                        if (y === "IsActive" && dataBio.IsActive === item.IsActive && isSame === 1) {
                            isSame = 2;
                            // debugger
                        }
                        if (isSame === 2) {
                            if ((dataBio.BioActionType === DELETE || dataBio.BioActionType === UPDATE) && (item.BioActionType === REGISTER || item.BioActionType === UPDATE || item.BioActionType === '') &&
                                (dataBio.EnrollData !== item.EnrollData && dataBio.EnrollData !== null)) {
                                // debugger
                                isSame = 0;
                                if (dataBio.BioActionType === REGISTER) {
                                    titleStatus = titleCloseActiveFace
                                    textStatus = statusN
                                } else if (dataBio.BioActionType === UPDATE) {
                                    if (dataBio.EnrollData) {
                                        titleStatus = titleUFace
                                        textStatus = statusU
                                    } else {
                                        titleStatus = titleCloseActiveFace
                                        textStatus = statusS
                                    }
                                } else if (dataBio.BioActionType === DELETE) {
                                    // titleStatus = titleUFace
                                    // textStatus = statusU
                                    titleStatus = titleDFace
                                    textStatus = statusS
                                }
                                item[y] = dataBio[y]
                                // debugger
                                // titleStatus = titleUFace
                                // textStatus = statusU
                                continue
                            } else {
                                // debugger
                                isSame = 0;
                                dataBio[y] = item[y]
                                item.matchCheck = true;
                                if (item.matchCheck) {
                                    titleStatus = titleCloseActiveFace
                                    textStatus = statusN
                                }
                                // titleStatus = titleCloseActiveFace
                                // textStatus = statusN
                                break
                            }
                        }
                        if (dataBio.BioType === FACE && item.BioType === FACE) {
                            if (dataBio.BioActionType === REGISTER) {
                                titleStatus = titleCloseActiveFace
                                textStatus = statusN
                            } else if (dataBio.BioActionType === UPDATE) {
                                if (dataBio.EnrollData) {
                                    titleStatus = titleUFace
                                    textStatus = statusU
                                } else {
                                    titleStatus = titleCloseActiveFace
                                    textStatus = statusS
                                }
                            } else if (dataBio.BioActionType === DELETE) {
                                // titleStatus = titleUFace
                                // textStatus = statusU
                                titleStatus = titleDFace
                                textStatus = statusS
                            }
                            item[y] = dataBio[y]
                            // titleStatus = titleUFace
                            // textStatus = statusU
                        } else {
                            titleStatus = titleCloseActiveFace
                            textStatus = statusN
                        }
                    }
                }
                cloneBioDatas.splice(indexBio, 1);
                return item;
            })
        } else {
            bioUpdate = BioDatas
            bioUpdate.forEach(item => {
                if (item.BioActionType === REGISTER && item.BioType === FACE) {
                    textFaceRegister = 'FaceRegister'
                    enrollFaceR = item.EnrollData
                    isActiveFaceR = item.IsActive
                    titleStatus = titleCloseActiveFace
                    textStatus = statusN
                }
            })
        }

        let informFinger = BioInfosType === UPDATE ? dataCurrent : dataBioDatas
        let bioTypeFaceUpdate = BioInfosType === UPDATE && dataCurrent.find(item => item.BioType === FACE)
        let bioTypeFaceRegister = cloneBioDatas.find(item => item.BioType === FACE)

        let faceBio = BioInfosType === UPDATE ? bioTypeFaceUpdate : bioTypeFaceRegister
        BioDatas.forEach(item => {
            if (item.BioActionType === DELETE && item.BioType === FACE) {
                dataRemoveNote = item.RemoveNote
            }
        })

        informFinger.forEach(item => {
            if (item.BioType === biotype.BioType) {
                dataIsConfirmFinger = item.IsConfirmFinger
                dataIsActive = item.IsActive
            }
        })

        this.props.showPopupInBiometric(
            {
                Face: textFaceRegister === 'FaceRegister' ? enrollFaceR : faceBio.EnrollData,
                IsConfirmFinger: dataIsConfirmFinger,
                IsActive: textFaceRegister === 'FaceRegister' ? isActiveFaceR : dataIsActive,
                ID: this.state.dataUser.ID,
                Note: this.state.dataUser.Note,
                Title: titleStatus,
                StatusFace: 'FaceRegister',
                TextStatus: textStatus,
                RemoveNote: dataRemoveNote
            }, 'ShowFaceImage', this.state.dataCurrent)
    }

    showFaceZoomImage = (biotype) => {
        let titleStatus, bioUpdate, textFaceRegister

        const { dataUser } = this.state
        let { BioDatas, BioInfosType, CurrentBioData } = this.state.dataUser
        BioDatas = JSON.parse(JSON.stringify(BioDatas))
        CurrentBioData = JSON.parse(JSON.stringify(CurrentBioData))

        const cloneBioDatas = _.cloneDeep(BioDatas);
        if (CurrentBioData) {
            bioUpdate = BioInfosType === UPDATE && CurrentBioData.map(item => {
                const indexBio = _.findIndex(cloneBioDatas, i => i.BioType === item.BioType);
                if (indexBio < 0) return item;
                const dataBio = cloneBioDatas[indexBio];
                // console.log(dataBio);
                if (dataBio.BioActionType !== "UPDATE" && dataBio.BioActionType !== "REMOVE") {
                    return item;
                }
                Object.keys(dataBio).forEach(y => {
                    //  console.log(y)
                    if (dataBio.EnrollData !== null && dataBio.BioType === FACE && dataBio !== null) {
                    }
                    if ((dataBio[y] !== null && typeof (dataBio[y])) || (dataBio[y] == null && !item[y])) {
                        item[y] = dataBio[y];
                    }
                });
                cloneBioDatas.splice(indexBio, 1);
                return item;
            })
        } else {
            bioUpdate = BioDatas
        }

        let dataBioUpdate = dataUser.BioInfosType === UPDATE && bioUpdate.concat(cloneBioDatas)
        let bioTypeFaceUpdate = BioInfosType === UPDATE && dataBioUpdate.find(item => item.BioType === FACE)
        let bioTypeFaceRegister = cloneBioDatas.find(item => item.BioType === FACE)

        let faceBio = BioInfosType === UPDATE ? bioTypeFaceUpdate : bioTypeFaceRegister
        this.props.showPopupInBiometric(
            {
                Face: faceBio.EnrollData,
                Title: titleStatus,
                TextFace: 'FaceZoom'
            }, 'ShowFaceImage', this.state.dataCurrent)
    }

    showFingerInfoCustomer = (biotype) => {
        let MatchData, match, dataRemoveNote, RemoveNote, bioUpdate, BioType, CreateAt, ConfirmData, EnrollData, IsActive, IsConfirmFinger, BioActionType, dataEnroll, dataCreate, dataBioType, dataConfirm, dataBioActionType, dataIsActive, dataIsConfirmFinger
        let { dataUser, dataCurrent } = this.state
        let { BioDatas, CurrentBioData } = this.state.dataUser
        BioDatas = JSON.parse(JSON.stringify(BioDatas))
        CurrentBioData = JSON.parse(JSON.stringify(CurrentBioData))
        dataCurrent = JSON.parse(JSON.stringify(dataCurrent))


        let dataBioDatas = BioDatas.map(item => item)
        let findIndexDataCurrent = dataCurrent.find(finds => finds.BioType === biotype)
        const cloneBioDatas = _.cloneDeep(BioDatas);
        if (CurrentBioData) {
            bioUpdate = this.state.dataUser.BioInfosType === UPDATE && CurrentBioData.map(item => {
                const indexBio = _.findIndex(cloneBioDatas, i => i.BioType === item.BioType);
                item.matchData = true
                if (indexBio < 0) {
                    return item;
                }
                const dataBio = cloneBioDatas[indexBio];
                // console.log(dataBio);
                if (dataBio.BioActionType !== "UPDATE" && dataBio.BioActionType !== "REMOVE") {
                    // item.matchData = true
                    return item;
                }

                let isSame = 0;
                let isMatchData = 0
                let isMatchData1 = 0
                for (let y in dataBio) {
                    if ((dataBio[y] !== null && typeof (dataBio[y])) || (dataBio[y] == null && !item[y])) {

                        if (y === "IsConfirmFinger" && dataBio.IsConfirmFinger === item.IsConfirmFinger) {
                            isSame = 1;
                        }
                        if (y === "IsActive" && dataBio.IsActive === item.IsActive && isSame === 1) {
                            isSame = 2;
                        }
                        if (isSame === 2) {
                            if (dataBio.BioActionType === DELETE && (item.BioActionType === REGISTER || item.BioActionType === '')) {
                                isSame = 0;
                                item[y] = dataBio[y]
                                continue
                            } else {
                                isSame = 0;
                                dataBio[y] = item[y]
                                item.matchData = true;
                                break
                            }
                        }
                        item[y] = dataBio[y]
                        if (item.BioActionType === UPDATE || item.BioActionType === DELETE) {
                            // debugger
                            isMatchData1 = 1
                        }
                        if (isMatchData1 === 1) {
                            // debugger
                            isMatchData1 = 0
                            isMatchData = false
                        }
                    }
                }
                if (isMatchData === false) item.matchData = isMatchData
                cloneBioDatas.splice(indexBio, 1);
                return item;
            })
        } else {
            bioUpdate = BioDatas
        }

        let dataBioUpdate = dataUser.BioInfosType === UPDATE && bioUpdate.concat(cloneBioDatas)
        let informFinger = dataUser.BioInfosType === UPDATE ? dataBioUpdate : dataBioDatas

        // console.log('same', sameArrDiffGetData, diffArrGetData)
        dataBioType = informFinger.find(item => item.BioType === biotype)
        dataBioActionType = informFinger.find(item => item.BioType === biotype && item.BioActionType)
        match = informFinger.find(item => item.BioType === biotype && item.matchData)


        // console.log(biotype)
        informFinger.forEach(item => {
            if (item.BioType === biotype) {
                dataIsConfirmFinger = item.IsConfirmFinger
                dataIsActive = item.IsActive
                dataRemoveNote = item.RemoveNote
            }
        })

        let elmBioActionFinger
        let index = 0
        if (dataBioType.BioType !== FACE) {
            if (typeof dataBioActionType === 'undefined') {
                elmBioActionFinger = BioInfosType[REGISTER].content
            }
            if (dataBioActionType && dataBioActionType.BioActionType === REGISTER) {
                elmBioActionFinger = BioInfosType[REGISTER].content
            } else if (dataBioActionType && dataBioActionType.BioActionType === DELETE) {
                elmBioActionFinger = BioInfosType[DELETE].dlFinger
            }
            else if (dataBioActionType && dataBioActionType.BioActionType === UPDATE) {
                // check close, active finger
                if (dataIsActive) {
                    if (dataIsConfirmFinger) {
                        elmBioActionFinger = customType[ACTIVE].vlFinger
                    } else {
                        elmBioActionFinger = customType[ISACTIVE].vlFinger
                    }
                } else {
                    // check hang finger'
                    if (dataBioActionType && dataBioActionType.BioActionType === UPDATE) {
                        index = 1
                        if (index === 1 && dataIsConfirmFinger === false) {
                            elmBioActionFinger = customType[ISACTIVE].vlFinger
                        } else {
                            elmBioActionFinger = customType[HANG].vlFinger
                        }
                    }
                }
            }
        }


        dataConfirm = informFinger.find(item => item.BioType === biotype && item.ConfirmData)
        dataEnroll = informFinger.find(item => item.BioType === biotype && item.EnrollData)
        dataCreate = informFinger.find(item => item.BioType === biotype && item.CreateAt)

        BioType = dataBioType && dataBioType.BioType
        ConfirmData = dataConfirm && dataConfirm.ConfirmData
        EnrollData = dataEnroll && dataEnroll.EnrollData
        IsActive = dataIsActive
        IsConfirmFinger = dataIsConfirmFinger
        CreateAt = dataCreate && dataCreate.CreateAt
        BioActionType = dataBioActionType && dataBioActionType.BioActionType
        RemoveNote = dataRemoveNote && dataRemoveNote
        MatchData = match && match


        if (BioType === THUMB_LEFT) {
            BioType = dataFingerPrint[THUMB_LEFT].label
        } else if (BioType === THUMB_RIGHT) {
            BioType = dataFingerPrint[THUMB_RIGHT].label
        } else if (BioType === INDEX_LEFT) {
            BioType = dataFingerPrint[INDEX_LEFT].label
        } else if (BioType === INDEX_RIGHT) {
            BioType = dataFingerPrint[INDEX_RIGHT].label
        } else if (BioType === MIDDLE_LEFT) {
            BioType = dataFingerPrint[MIDDLE_LEFT].label
        } else if (BioType === MIDDLE_RIGHT) {
            BioType = dataFingerPrint[MIDDLE_RIGHT].label
        } else if (BioType === RING_LEFT) {
            BioType = dataFingerPrint[RING_LEFT].label
        } else if (BioType === RING_RIGHT) {
            BioType = dataFingerPrint[RING_RIGHT].label
        } else if (BioType === PINKY_LEFT) {
            BioType = dataFingerPrint[PINKY_LEFT].label
        } else if (BioType === PINKY_RIGHT) {
            BioType = dataFingerPrint[PINKY_RIGHT].label
        }

        this.props.showPopupInBiometric(
            {
                BioActionType: BioActionType,
                BioType: BioType,
                CreateAt: findIndexDataCurrent ? findIndexDataCurrent.CreateAt : CreateAt,
                ConfirmData: findIndexDataCurrent ? findIndexDataCurrent.ConfirmData : ConfirmData,
                EnrollData: findIndexDataCurrent ? findIndexDataCurrent.EnrollData : EnrollData,
                IsActive: IsActive,
                IsConfirmFinger: IsConfirmFinger,
                IsActiveCurrent: findIndexDataCurrent ? findIndexDataCurrent.IsActive : IsActive,
                IsConfirmFingerCurrent: findIndexDataCurrent ? findIndexDataCurrent.IsConfirmFinger : IsConfirmFinger,
                ID: this.state.dataUser.ID,
                Note: this.state.dataUser.Note,
                BioActionFinger: elmBioActionFinger,
                RemoveNote: dataRemoveNote,
                MatchData: match
            }
            , 'FingerInfoCustomer'
        )
    }


    render() {
        let status, clsStatus
        let { changeTab, dataUser } = this.state;
        if (dataUser.Status === ACCEPT) {
            status = Status[ACCEPT].label
            clsStatus = Status[ACCEPT].colorA
        } else if (dataUser.Status === PENDING) {
            status = Status[PENDING].value
            clsStatus = Status[PENDING].colorP
        } else {
            status = Status[REJECT].label
            clsStatus = Status[REJECT].colorR
        }

        let content_tab_layout = null;
        if (changeTab === 1) {
            content_tab_layout = <UserInformation
                typeInternal='gdv'
                dataUser={this.props.dataUser}
            />
        } else if (changeTab === 2) {
            content_tab_layout = <UserBiometric
                showFingerInfoCustomer={this.showFingerInfoCustomer}
                showMatchFace={this.showMatchFace}
                showFaceImage={this.showFaceImage}
                dataUser={JSON.parse(JSON.stringify(this.props.dataUser))}
                showFaceUpdateImage={this.showFaceUpdateImage}
                showFaceZoomImage={this.showFaceZoomImage}
                roles={this.props.roles}
            />
        }

        return (
            <Bound>
                <div className="header">
                    <div className="block_header_title">
                        <div className="title">
                            Thông tin dữ liệu
                        </div>
                        <img
                            onClick={this.props.outPopup}
                            src={ic_close} alt="ic_close" />
                    </div>
                    <TabLayout>
                        <div className="title_content">
                            {
                                dataUser ?
                                    <React.Fragment>
                                        <p
                                            onClick={() => this.changeTab(1)}
                                            style={changeTab === 1 ? { borderBottom: '2.5px solid #005993', color: '#222222' } : {}}
                                            className='tab_title'>Thông tin dữ liệu</p>
                                        {
                                            this.state.dataUser.BioInfosType !== NR &&
                                            <p
                                                onClick={() => this.changeTab(2)}
                                                style={changeTab === 2 ? { borderBottom: '2.5px solid #005993', color: '#222222' } : {}}
                                                className='tab_title'>Sinh trắc học</p>
                                        }
                                    </React.Fragment>
                                    :
                                    <p className='tab_title_single'>Thông tin dữ liệu</p>
                            }
                        </div>
                        {dataUser &&
                            <div className="title_info">
                                <div className="cif">
                                    CIF: <p className='text_cif'> &nbsp;{dataUser.CustomerInfos.CustomerNumber}</p>
                                </div>
                                <div className="status">
                                    Trạng thái: <p className='text_status' style={{ color: clsStatus }}> &nbsp; {status}</p>
                                </div>
                            </div>
                        }
                    </TabLayout>
                </div>
                <div className="main_content">
                    {content_tab_layout}
                </div>
                <div className="footer_check">
                    <div className="block_action">
                        {/* <ActionControlCheckBio
                            icon={ic_history}
                            onSubmit={this.showHistoryBio}
                            isTootltip={true}
                        /> */}
                    </div>

                    <div className="block_btn">

                    </div>
                </div>
            </Bound>

        )
    }
}
