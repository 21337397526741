// data
import { typeStatusBiometrics } from '../data/statusBiometricWithTicket'
import { TELLER } from '../data/userRole'
import verifyFingerStatusTypes from '../data/verifyFingerStatusTypes'

// helper
import isDisableEventWithCORPCust from './isDisableEventWithCORPCust'
import { openPopupNotifyErrorLv3 } from './actionOpenPopup'

// image
import iconNotificationFailed from '../images/ic_counter_notification_close.svg'

const isDisableEventClick = (ticketId, counterReducer, _this) => {
    const currentTicket = counterReducer.listCurrentTicket.find(
        (item) => item.TicketID === ticketId
    )

    const isTeller = counterReducer.dataLogin.Roles.includes(TELLER)

    const { statusBiometric, statusCustomer, CustomerType } = currentTicket

    switch (true) {
    case isTeller && CustomerType === 'INCUST':
        openPopupNotifyErrorLv3(
            _this,
            {
                image_noti: iconNotificationFailed,
                title: 'Không thể thao tác vì đây là khách hàng nội bộ',
                widthPopup: '266px',
                titleConfirm: 'Xác nhận',
            },
            'isDisableEventClick - isDisableEventClickWhenHaveSuperVisor.js'
        )
        return true
    case isTeller &&
            currentTicket.VerifyFingerStatus === verifyFingerStatusTypes.PV:
        openPopupNotifyErrorLv3(
            _this,
            {
                image_noti: iconNotificationFailed,
                title: 'Đang chờ KSV phê duyệt',
                widthPopup: '180px',
                titleConfirm: 'Xác nhận',
            },
            'isDisableEventClick - isDisableEventClickWhenHaveSuperVisor.js'
        )
        return true
    case String(statusBiometric) !== typeStatusBiometrics.UNDEFINED:
        switch (String(statusBiometric)) {
        case typeStatusBiometrics.ACCEPT:
            openPopupNotifyErrorLv3(
                _this,
                {
                    image_noti: iconNotificationFailed,
                    title: 'Dữ liệu STH đã được phê duyệt',
                    widthPopup: '180px',
                    titleConfirm: 'Xác nhận',
                },
                'isDisableEventClick - isDisableEventClickWhenHaveSuperVisor.js'
            )
            break
        case typeStatusBiometrics.PENDING:
            openPopupNotifyErrorLv3(
                _this,
                {
                    image_noti: iconNotificationFailed,
                    title: 'Đang chờ KSV phê duyệt',
                    widthPopup: '180px',
                    titleConfirm: 'Xác nhận',
                },
                'isDisableEventClick - isDisableEventClickWhenHaveSuperVisor.js'
            )
            break
        case typeStatusBiometrics.REJECT:
            openPopupNotifyErrorLv3(
                _this,
                {
                    image_noti: iconNotificationFailed,
                    title: 'Dữ liệu đã bị từ chối bởi KSV',
                    widthPopup: '180px',
                    titleConfirm: 'Xác nhận',
                },
                'isDisableEventClick - isDisableEventClickWhenHaveSuperVisor.js'
            )
            break
        default:
            break
        }
        return true
    case statusCustomer === 'InprogressBio':
        openPopupNotifyErrorLv3(
            _this,
            {
                image_noti: iconNotificationFailed,
                title: 'Dữ liệu đang được xử lý ở 1 vé khác',
                widthPopup: '180px',
                titleConfirm: 'Xác nhận',
            },
            'isDisableEventClick - isDisableEventClickWhenHaveSuperVisor.js'
        )
        return true
    case isDisableEventWithCORPCust(ticketId, counterReducer):
        openPopupNotifyErrorLv3(
            _this,
            {
                image_noti: iconNotificationFailed,
                title:
                        'Không thể thao tác vì đây là vé khách hàng doanh nghiệp',
                widthPopup: '180px',
                titleConfirm: 'Xác nhận',
            },
            'isDisableEventClick - isDisableEventClickWhenHaveSuperVisor.js'
        )
        return true
    default:
        break
    }

    return false
}

export default isDisableEventClick
