import React, { useState, useEffect, useRef } from 'react'

// styles
import { WrapPopupStyles } from '../WrapPopupStyles'
import { WrapMainQueries } from './index.styles'

// images
import icSearchWhiteOutline from '../../../../images/ic_search_white_outline.svg'
import icCloseGray from '../../../../images/ic_close_gray.svg'

// component
import LoadingControl from '../../../Control/LoadingControl'

// hooks
import useMoveElement from '../../../../helper/hooks/useMoveElement'

const QUERY_BY_GTTT = 1
const QUERY_BY_CIF = 0
const QUERY_BY_CODE_DUPL = 2
const QUERY_BY_IMG_BIO = 3

const initialDataQueryUser = {
    custKey: '',
    queryType: ''
}

const MainQueries = ({
    handleClose,
    handleQueryUser,
    handleQueryDuplicateBio,
    handleQueryByBioImage,
    error,
    loading,
    permissionQuery: {
        queryByGTTT,
        queryByCif,
        queryByCodeDupl,
        queryByBioImage
    } = {},
    defaultDataQueryUser,
    setDefaultDataQueryUser,
    defaultQueryCode,
    setDefaultQueryCode,
    defaultActiveQueryMethod,
    setDefaultActiveQueryMethod
}) => {

    const tmpActiveQueryMethod = useRef(null)
    const tmpDataQueryUser = useRef(null)
    const tmpQueryCode = useRef(null)
    const mainQueriesRef = useRef(null)
    const [dataQueryUser, setDataQueryUser] = useState(defaultDataQueryUser ?? { ...initialDataQueryUser });
    const [queryCodeSearchDuplBio, setQueryCodeSearchDuplBio] = useState(defaultQueryCode || "");
    const [activeQueryMethod, setActiveQueryMethod] = useState(null);
    useMoveElement(mainQueriesRef, ".title")

    const _handleChangeQueryUserData = (e, queryType) => {
        const value = e.target.value
        setDataQueryUser({
            custKey: value,
            queryType
        })
    }

    const _handleQueryUserData = () => {
        handleQueryUser(dataQueryUser.custKey, dataQueryUser.queryType)
    }

    const _handleSearchDuplBio = () => {
        handleQueryDuplicateBio(queryCodeSearchDuplBio)
    }

    const _handleChangeActiveQueryMethod = (newMethod) => {
        setActiveQueryMethod(newMethod)
        setQueryCodeSearchDuplBio('')
        setDataQueryUser({ ...initialDataQueryUser })
    }
    useEffect(() => {
        tmpActiveQueryMethod.current = activeQueryMethod
    }, [activeQueryMethod]);

    useEffect(() => {
        tmpDataQueryUser.current = dataQueryUser
    }, [dataQueryUser]);

    useEffect(() => {
        tmpQueryCode.current = queryCodeSearchDuplBio
    }, [queryCodeSearchDuplBio]);

    useEffect(() => {
        if (defaultActiveQueryMethod !== null) {
            setActiveQueryMethod(defaultActiveQueryMethod)
            return;
        }
        setActiveQueryMethod(QUERY_BY_GTTT)
    }, []);

    useEffect(() => {
        return () => {
            setDefaultActiveQueryMethod(tmpActiveQueryMethod.current)
        }
    }, []);

    useEffect(() => {
        return () => {
            setDefaultDataQueryUser(tmpDataQueryUser.current)
        }
    }, []);

    useEffect(() => {
        return () => {
            setDefaultQueryCode(tmpQueryCode.current)
        }
    }, []);

    const { custKey, queryType } = dataQueryUser

    return (
        <WrapPopupStyles>
            <WrapMainQueries ref={mainQueriesRef}>
                <div className="title">Màn hình vấn tin thông tin khách hàng</div>
                <img className="ic-close" src={icCloseGray} alt="close" onClick={handleClose} />
                <div className="content">
                    <div className="target-search">Tiêu chí tìm kiếm:</div>
                    <div className={`row${!queryByGTTT ? ' disabled' : ''}`}>
                        <label htmlFor="gttt">
                            <input
                                type="radio"
                                id="gttt"
                                name="target-search"
                                checked={activeQueryMethod === QUERY_BY_GTTT}
                                onChange={e => e.target.checked && _handleChangeActiveQueryMethod(QUERY_BY_GTTT)}
                            // onClick={() => _handleChangeActiveQueryMethod(QUERY_BY_GTTT)}
                            // defaultChecked={true}
                            />
                            Giấy tờ tùy thân
                        </label>
                        <div className={`wrap-search${activeQueryMethod !== QUERY_BY_GTTT ? ' disabled' : ''}`}>
                            <input
                                type="text"
                                placeholder="Nhập số GTTT"
                                value={queryType === QUERY_BY_GTTT ? custKey : ''}
                                onChange={(e) => _handleChangeQueryUserData(e, QUERY_BY_GTTT)}
                                disabled={activeQueryMethod !== QUERY_BY_GTTT}
                                onKeyPress={(e) => {
                                    if (e.charCode !== 13) return true
                                    _handleQueryUserData()
                                    return false
                                }}
                            />
                            <figure onClick={_handleQueryUserData} className={queryType === QUERY_BY_GTTT && custKey ? 'enable' : ''}>
                                <img src={icSearchWhiteOutline} alt="search" />
                            </figure>
                        </div>
                    </div>
                    <div className={`row${!queryByCif ? ' disabled' : ''}`}>
                        <label htmlFor="cif-info">
                            <input
                                type="radio"
                                id="cif-info"
                                name="target-search"
                                checked={activeQueryMethod === QUERY_BY_CIF}
                                onChange={e => e.target.checked && _handleChangeActiveQueryMethod(QUERY_BY_CIF)}
                            // onClick={() => _handleChangeActiveQueryMethod(QUERY_BY_CIF)}
                            />
                            Thông tin CIF
                        </label>
                        <div className={`wrap-search${activeQueryMethod !== QUERY_BY_CIF ? ' disabled' : ''}`}>
                            <input
                                type="text"
                                placeholder="Nhập số CIF"
                                value={queryType === QUERY_BY_CIF ? custKey : ''}
                                onChange={(e) => _handleChangeQueryUserData(e, QUERY_BY_CIF)}
                                disabled={activeQueryMethod !== QUERY_BY_CIF}
                                onKeyPress={(e) => {
                                    if (e.charCode !== 13) return true
                                    _handleQueryUserData()
                                    return false
                                }}
                            />
                            <figure onClick={_handleQueryUserData} className={queryType === QUERY_BY_CIF && custKey ? 'enable' : ''}>
                                <img src={icSearchWhiteOutline} alt="search" />
                            </figure>
                        </div>
                    </div>
                    <div className={`row${!queryByCodeDupl ? ' disabled' : ''}`}>
                        <label htmlFor="duplicate-bio">
                            <input
                                type="radio"
                                id="duplicate-bio"
                                name="target-search"
                                // onClick={() => _handleChangeActiveQueryMethod(QUERY_BY_CODE_DUPL)}
                                onChange={e => e.target.checked && _handleChangeActiveQueryMethod(QUERY_BY_CODE_DUPL)}
                                checked={activeQueryMethod === QUERY_BY_CODE_DUPL}
                            />
                            Mã truy vấn trùng STH
                        </label>
                        <div className={`wrap-search${activeQueryMethod !== QUERY_BY_CODE_DUPL ? ' disabled' : ''}`}>
                            <input
                                type="text"
                                placeholder="Nhập mã trùng STH"
                                value={queryCodeSearchDuplBio}
                                onChange={e => setQueryCodeSearchDuplBio(e.target.value)}
                                disabled={activeQueryMethod !== QUERY_BY_CODE_DUPL}
                                onKeyPress={(e) => {
                                    if (e.charCode !== 13) return true
                                    _handleSearchDuplBio()
                                    return false
                                }}
                            />
                            <figure onClick={_handleSearchDuplBio} className={queryCodeSearchDuplBio ? 'enable' : ''}>
                                <img className="ic-search" src={icSearchWhiteOutline} alt="search" />
                            </figure>
                        </div>
                    </div>
                    <div className="row">
                        <label htmlFor="bio-image">
                            <input
                                type="radio"
                                id="bio-image"
                                name="target-search"
                                // onClick={() => _handleChangeActiveQueryMethod(QUERY_BY_IMG_BIO)}
                                onChange={e => e.target.checked && _handleChangeActiveQueryMethod(QUERY_BY_IMG_BIO)}
                                checked={activeQueryMethod === QUERY_BY_IMG_BIO}
                            />
                            Hình ảnh khuôn mặt/ vân tay
                        </label>
                        <button disabled={activeQueryMethod !== QUERY_BY_IMG_BIO} onClick={handleQueryByBioImage}>bắt đầu tìm kiếm</button>
                    </div>
                    <div className="error">{error}</div>
                    {
                        loading &&
                        <div className="wrap-loading">
                            <LoadingControl size="100px" loadingPage={false} />
                        </div>
                    }
                </div>
            </WrapMainQueries>
        </WrapPopupStyles>
    )
}

export default MainQueries
